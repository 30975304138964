import React, { useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Platform,
  StatusBar,
} from "react-native";
import { md } from "../../../Responsive";
import {
  contactInfo,
  createCrmdealsCipCbiForAccentureClients,
  createCrmdealsCipForRegular,
  getCustomerDetails,
} from "../../../api";
import Header from "../header_after_login";
import Entypo from "@expo/vector-icons/Entypo";
import {
  completeMinDetails,
  DataURIToBlob,
  getUserDetails,
  getWindowSize,
  isMobile,
  isWeb,
  navigateToTransaction,
} from "../../../utils";
const { width } = Dimensions.get("window");
import Progressbar from "../progressbar";
import { useFilePicker } from "use-file-picker";
import { saveUserProfileData } from "../../../actions";
import { connect } from "react-redux";
import { ShowToast } from "../../../components/Common/Toast";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import { SafeAreaView } from "react-native-safe-area-context";
import { CustomModal } from "../../../components/Common/CustomModal";
import moment from "moment";
import Store from "../../../store";

const states = [
  {
    label: "Andaman & Nicobar Islands",
    value: "AN",
  },
  {
    label: "Andhra Pradesh",
    value: "AP",
  },
  {
    label: "Arunachal Pradesh",
    value: "AR",
  },
  {
    label: "Assam",
    value: "AS",
  },
  {
    label: "Bihar",
    value: "BH",
  },
  {
    label: "chattisgarh",
    value: "CG",
  },
  {
    label: "Chandigarh",
    value: "CH",
  },
  {
    label: "Daman & Diu",
    value: "DD",
  },
  {
    label: "Delhi",
    value: "DL",
  },
  {
    label: "Dadra & Nagar Haveli",
    value: "DN",
  },
  {
    label: "Goa",
    value: "GO",
  },
  {
    label: "Gujarat",
    value: "GU",
  },
  {
    label: "Himachal Pradesh",
    value: "HP",
  },
  {
    label: "Haryana",
    value: "HA",
  },
  {
    label: "Jharkhand",
    value: "JK",
  },
  {
    label: "Jammu & Kashmir",
    value: "JM",
  },
  {
    label: "Karnataka",
    value: "KA",
  },
  {
    label: "Kerala",
    value: "KE",
  },
  {
    label: "Lakshadweep",
    value: "LD",
  },
  {
    label: "Maharashtra",
    value: "MA",
  },
  {
    label: "Meghalaya",
    value: "ME",
  },
  {
    label: "Manipur",
    value: "MN",
  },
  {
    label: "Madhya Pradesh",
    value: "MP",
  },
  {
    label: "Mizoram",
    value: "MI",
  },
  {
    label: "Nagaland",
    value: "NA",
  },
  {
    label: "New Delhi",
    value: "ND",
  },
  {
    label: "Odisha",
    value: "OR",
  },
  {
    label: "Punjab",
    value: "PU",
  },
  {
    label: "Puducherry",
    value: "PO",
  },
  {
    label: "Rajastan",
    value: "RA",
  },
  {
    label: "Sikkim",
    value: "SI",
  },
  {
    label: "Tamil Nadu",
    value: "TN",
  },
  {
    label: "Telangana",
    value: "TG",
  },
  {
    label: "Tripura",
    value: "TR",
  },
  {
    label: "Uttrakhand",
    value: "UC",
  },
  {
    label: "Uttar Pradesh",
    value: "UP",
  },
  {
    label: "West Bengal",
    value: "WB",
  },
  {
    label: "Others",
    value: "OH",
  },
];

const RESIDENTIAL_TYPES = [
  // {
  //   key: 0,
  //   label: "Select Residential Type",
  //   value: null,
  // },
  {
    key: 1,
    label: "Residential",
    value: "RES",
  },
  {
    key: 2,
    label: "Business",
    value: "BIZ",
  },
  {
    key: 3,
    label: "Residential or Business",
    value: "ROB",
  },
  {
    key: 4,
    label: "Registered Office",
    value: "REG",
  },
];

const COUNTRIES = [
  // { value: null, label: "Select" },
  { value: "IN", label: "India" },
  { value: "001", label: "Afghanistan" },
  { value: "002", label: "Aland Islands" },
  { value: "003", label: "Albania" },
  { value: "004", label: "Algeria" },
  { value: "005", label: "American Samoa" },
  { value: "006", label: "Andorra" },
  { value: "007", label: "Angola" },
  { value: "008", label: "Anguilla" },
  { value: "009", label: "Antarctica" },
  { value: "010", label: "Antigua And Barbuda" },
  { value: "011", label: "Argentina" },
  { value: "012", label: "Armenia" },
  { value: "013", label: "Aruba" },
  { value: "014", label: "Australia" },
  { value: "015", label: "Austria" },
  { value: "016", label: "Azerbaijan" },
  { value: "017", label: "Bahamas" },
  { value: "018", label: "Bahrain" },
  { value: "019", label: "Bangladesh" },
  { value: "020", label: "Barbados" },
  { value: "021", label: "Belarus" },
  { value: "022", label: "Belgium" },
  { value: "023", label: "Belize" },
  { value: "024", label: "Benin" },
  { value: "025", label: "Bermuda" },
  { value: "026", label: "Bhutan" },
  { value: "027", label: "Bolivia" },
  { value: "BQ", label: "Bonaire, Sint Eustatius And Saba" },
  { value: "028", label: "Bosnia And Herzegovina" },
  { value: "029", label: "Botswana" },
  { value: "030", label: "Bouvet Island" },
  { value: "031", label: "Brazil" },
  { value: "032", label: "British Indian Ocean Territory" },
  { value: "033", label: "Brunei Darussalam" },
  { value: "034", label: "Bulgaria" },
  { value: "035", label: "Burkina Faso" },
  { value: "036", label: "Burundi" },
  { value: "037", label: "Cambodia" },
  { value: "038", label: "Cameroon" },
  { value: "039", label: "Canada" },
  { value: "040", label: "Cape Verde" },
  { value: "041", label: "Cayman Islands" },
  { value: "042", label: "Central African Republic" },
  { value: "043", label: "Chad" },
  { value: "044", label: "Chile" },
  { value: "045", label: "China" },
  { value: "046", label: "Christmas Island" },
  { value: "047", label: "Cocos (Keeling) Islands" },
  { value: "048", label: "Colombia" },
  { value: "049", label: "Comoros" },
  { value: "050", label: "Congo" },
  { value: "051", label: "Congo, The Democratic Republic Of The" },
  { value: "052", label: "Cook Islands" },
  { value: "053", label: "Costa Rica" },
  { value: "054", label: "Cote D'ivoire" },
  { value: "055", label: "Croatia" },
  { value: "056", label: "Cuba" },
  { value: "CW", label: "Curacao" },
  { value: "057", label: "Cyprus" },
  { value: "058", label: "Czech Republic" },
  { value: "059", label: "Denmark" },
  { value: "060", label: "Djibouti" },
  { value: "061", label: "Dominica" },
  { value: "062", label: "Dominican Republic" },
  { value: "063", label: "Ecuador" },
  { value: "064", label: "Egypt" },
  { value: "065", label: "El Salvador" },
  { value: "066", label: "Equatorial Guinea" },
  { value: "067", label: "Eritrea" },
  { value: "068", label: "Estonia" },
  { value: "069", label: "Ethiopia" },
  { value: "070", label: "Falkland Islands (Malvinas)" },
  { value: "071", label: "Faroe Islands" },
  { value: "072", label: "Fiji" },
  { value: "073", label: "Finland" },
  { value: "074", label: "France" },
  { value: "075", label: "French Guiana" },
  { value: "076", label: "French Polynesia" },
  { value: "077", label: "French Southern Territories" },
  { value: "078", label: "Gabon" },
  { value: "079", label: "Gambia" },
  { value: "078", label: "Georgia" },
  { value: "081", label: "Germany" },
  { value: "082", label: "Ghana" },
  { value: "083", label: "Gibraltar" },
  { value: "084", label: "Greece" },
  { value: "085", label: "Greenland" },
  { value: "086", label: "Grenada" },
  { value: "087", label: "Guadeloupe" },
  { value: "088", label: "Guam" },
  { value: "089", label: "Guatemala" },
  { value: "090", label: "Guernsey" },
  { value: "091", label: "Guinea" },
  { value: "092", label: "Guinea-Bissau" },
  { value: "093", label: "Guyana" },
  { value: "094", label: "Haiti" },
  { value: "095", label: "Heard Island And McDonald Islands" },
  { value: "097", label: "Honduras" },
  { value: "098", label: "Hong Kong" },
  { value: "099", label: "Hungary" },
  { value: "100", label: "Iceland" },
  { value: "102", label: "Indonesia" },
  { value: "103", label: "Iran, Islamic Republic Of" },
  { value: "104", label: "Iraq" },
  { value: "105", label: "Ireland" },
  { value: "106", label: "Isle Of Man" },
  { value: "107", label: "Israel" },
  { value: "108", label: "Italy" },
  { value: "109", label: "Jamaica" },
  { value: "110", label: "Japan" },
  { value: "111", label: "Jersey" },
  { value: "112", label: "Jordan" },
  { value: "113", label: "Kazakhstan" },
  { value: "114", label: "Kenya" },
  { value: "115", label: "Kiribati" },
  { value: "116", label: "Korea, Democratic People's Republic Of" },
  { value: "117", label: "Korea, Republic Of" },
  { value: "118", label: "Kuwait" },
  { value: "119", label: "Kyrgyzstan" },
  { value: "120", label: "Lao People's Democratic Republic" },
  { value: "121", label: "Latvia" },
  { value: "122", label: "Lebanon" },
  { value: "123", label: "Lesotho" },
  { value: "124", label: "Liberia" },
  { value: "125", label: "Libyan Arab Jamahiriya" },
  { value: "126", label: "Liechtenstein" },
  { value: "127", label: "Lithuania" },
  { value: "128", label: "Luxembourg" },
  { value: "129", label: "Macao" },
  { value: "130", label: "Macedonia, The Former Yugoslav Republic Of" },
  { value: "131", label: "Madagascar" },
  { value: "132", label: "Malawi" },
  { value: "133", label: "Malaysia" },
  { value: "134", label: "Maldives" },
  { value: "135", label: "Mali" },
  { value: "136", label: "Malta" },
  { value: "137", label: "Marshall Islands" },
  { value: "138", label: "Martinique" },
  { value: "139", label: "Mauritania" },
  { value: "140", label: "Mauritius" },
  { value: "141", label: "Mayotte" },
  { value: "142", label: "Mexico" },
  { value: "143", label: "Micronesia, Federated States Of" },
  { value: "144", label: "Moldova, Republic Of" },
  { value: "145", label: "Monaco" },
  { value: "146", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "147", label: "Montserrat" },
  { value: "148", label: "Morocco" },
  { value: "149", label: "Mozambique" },
  { value: "150", label: "Myanmar" },
  { value: "151", label: "Namibia" },
  { value: "152", label: "Nauru" },
  { value: "153", label: "Nepal" },
  { value: "154", label: "Netherlands" },
  { value: "155", label: "Netherlands Antilles" },
  { value: "156", label: "New Caledonia" },
  { value: "157", label: "New Zealand" },
  { value: "158", label: "Nicaragua" },
  { value: "159", label: "Niger" },
  { value: "160", label: "Nigeria" },
  { value: "161", label: "Niue" },
  { value: "162", label: "Norfolk Island" },
  { value: "163", label: "Northern Mariana Islands" },
  { value: "164", label: "Norway" },
  { value: "XX", label: "Not categorised" },
  { value: "164", label: "Oman" },
  { value: "ZZ", label: "Others" },
  { value: "166", label: "Pakistan" },
  { value: "167", label: "Palau" },
  { value: "168", label: "Palestinian Territory, Occupied" },
  { value: "169", label: "Panama" },
  { value: "170", label: "Papua New Guinea" },
  { value: "171", label: "Paraguay" },
  { value: "172", label: "Peru" },
  { value: "173", label: "Philippines" },
  { value: "174", label: "Pitcairn" },
  { value: "175", label: "Poland" },
  { value: "176", label: "Portugal" },
  { value: "177", label: "Puerto Rico" },
  { value: "178", label: "Qatar" },
  { value: "179", label: "Reunion Island" },
  { value: "180", label: "Romania" },
  { value: "181", label: "Russian Federation" },
  { value: "182", label: "Rwanda" },
  { value: "BL", label: "Saint Barthelemy" },
  { value: "183", label: "Saint Helena, Ascension And Tristanda Cunha" },
  { value: "184", label: "Saint Kitts And Nevis" },
  { value: "185", label: "Saint Lucia" },
  { value: "MF", label: "Saint Martin" },
  { value: "186", label: "Saint Pierre And Miquelon" },
  { value: "187", label: "Saint Vincent And The Grenadines" },
  { value: "188", label: "Samoa" },
  { value: "189", label: "San Marino" },
  { value: "190", label: "Sao Tome And Principe" },
  { value: "191", label: "Saudi Arabia" },
  { value: "192", label: "Senegal" },
  { value: "193", label: "Serbia" },
  { value: "194", label: "Seychelles" },
  { value: "195", label: "Sierra Leone" },
  { value: "196", label: "Singapore" },
  { value: "SX", label: "Sint Maarten (Dutch Part)" },
  { value: "197", label: "Slovakia" },
  { value: "198", label: "Slovenia" },
  { value: "199", label: "Solomon Islands" },
  { value: "200", label: "Somalia" },
  { value: "201", label: "South Africa" },
  { value: "202", label: "South Georgia And The South Sandwich Islands" },
  { value: "SS", label: "South Sudan" },
  { value: "203", label: "Spain" },
  { value: "204", label: "Sri Lanka" },
  { value: "205", label: "Sudan" },
  { value: "206", label: "Suriname" },
  { value: "207", label: "Svalbard And Jan Mayen Islands" },
  { value: "208", label: "Swaziland" },
  { value: "209", label: "Sweden" },
  { value: "210", label: "Switzerland" },
  { value: "211", label: "Syrian Arab Republic" },
  { value: "212", label: "Taiwan, Province Of China" },
  { value: "213", label: "Tajikistan" },
  { value: "214", label: "Tanzania, United Republic Of" },
  { value: "215", label: "Thailand" },
  { value: "216", label: "Timor-Leste" },
  { value: "217", label: "Togo" },
  { value: "218", label: "Tokelau" },
  { value: "219", label: "Tonga" },
  { value: "220", label: "Trinidad And Tobago" },
  { value: "221", label: "Tunisia" },
  { value: "222", label: "Turlabel" },
  { value: "223", label: "Turkmenistan" },
  { value: "224", label: "Turks And Caicos Islands" },
  { value: "225", label: "Tuvalu" },
  { value: "225", label: "Uganda" },
  { value: "227", label: "Ukraine" },
  { value: "228", label: "United Arab Emirates" },
  { value: "229", label: "United Kingdom" },
  { value: "230", label: "United States of America" },
  { value: "231", label: "United States Minor Outlying Islands" },
  { value: "232", label: "Uruguay" },
  { value: "233", label: "Uzbekistan" },
  { value: "234", label: "Vanuatu" },
  { value: "VA", label: "Vatican City State" },
  { value: "235", label: "Venezuela, Bolivarian Republic Of" },
  { value: "236", label: "Viet Nam" },
  { value: "237", label: "Virgin Islands, British" },
  { value: "238", label: "Virgin Islands, U.S." },
  { value: "239", label: "Wallis And Futuna" },
  { value: "240", label: "Western Sahara" },
  { value: "241", label: "Yemen" },
  { value: "242", label: "Zambia" },
  { value: "243", label: "Zimbabwe" },
];

const address_options = [
  // { value: null, label: "Select" },
  { value: "AAC", label: "Aadhar (card, front and back)" },
  { value: "ACP", label: "Aadhar ePDF single page" },
  { value: "VOT", label: "Voter ID" },
  { value: "DL", label: "Driving License" },
  { value: "PPT", label: "Passport" },
];

const is_minor = [
  {
    key: 1,
    label: "No",
    value: "N",
  },
  {
    key: 2,
    label: "Yes",
    value: "Y",
  },
];

const relations = [
  // {
  //   key: 1,
  //   label: "Select",
  //   value: null,
  // },
  {
    key: 2,
    label: "AUNT",
    value: "01",
  },
  {
    key: 3,
    label: "BROTHER-IN-LAW",
    value: "02",
  },
  {
    key: 4,
    label: "BROTHER",
    value: "03",
  },
  {
    key: 5,
    label: "DAUGHTER",
    value: "04",
  },
  {
    key: 6,
    label: "DAUGHTER-IN-LAW",
    value: "05",
  },
  {
    key: 7,
    label: "FATHER",
    value: "06",
  },
  {
    key: 8,
    label: "FATHER-IN-LAW",
    value: "07",
  },
  {
    key: 9,
    label: "GRAND DAUGHTER",
    value: "08",
  },
  {
    key: 22,
    label: "GRAND FATHER",
    value: "09",
  },
  {
    key: 10,
    label: "GRAND MOTHER",
    value: "10",
  },
  {
    key: 11,
    label: "GRAND SON",
    value: "11",
  },
  {
    key: 12,
    label: "MOTHER-IN-LAW",
    value: "12",
  },
  {
    key: 13,
    label: "MOTHER",
    value: "13",
  },
  {
    key: 14,
    label: "NEPHEW",
    value: "14"
  },
  {
    key: 15,
    label: "NIECE",
    value: "15",
  },
  {
    key: 16,
    label: "SISTER",
    value: "16",
  },
  {
    key: 17,
    label: "SISTER-IN-LAW",
    value: "17",
  },
  {
    key: 18,
    label: "SON",
    value: "18",
  },
  {
    key: 19,
    label: "SON-IN-LAW",
    value: "19",
  },
  {
    key: 20,
    label: "SPOUSE",
    value: "20",
  },
  {
    key: 21,
    label: "UNCLE",
    value: "21",
  },
  {
    key: 22,
    label: "OTHERS",
    value: "22",
  },
  // {
  //   key: 23,
  //   value: "Joint Account",
  //   label: "Joint Account",
  // },
];
const guardianrelations = [
  // {
  //   key: 1,
  //   label: "Select",
  //   value: null,
  // },
  {
    key: 2,
    label: "FATHER",
    value: "06",
  },
  {
    key: 3,
    label: "MOTHER",
    value: "13",
  },
  {
    key: 4,
    label: "COURT APPOINTED LEGAL GUARDIAN",
    value: "23",
  },

];

const SelectChequeLeaf = ({ onSelect, title, file, imageURL, showModal, isPdf = true }) => {
  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: "DataURL",
    accept: [".png", ".jpg", ".jpeg", isPdf ? ".pdf" : null],
    multiple: true,
    limitFilesConfig: { max: 1 },
    maxFileSize: 0.5,
  });

  const openFile = (file) => {
    if (!file) return;
    imageURL(`https://jamaappprod.s3.amazonaws.com/media/${file}`);
    showModal(true);
  };

  useEffect(() => {
    onSelect(filesContent, errors);
  }, [filesContent]);

  if (loading) {
    return <Text>Loading</Text>;
  }

  const isFileSelected = () => {
    return filesContent.length != 0;
  };

  const checkSizeError = () => {
    if (errors && errors.length) {
      const error = errors[0];
      const { fileSizeToolarge } = error;
      return {
        fileSizeToolarge: true,
        error: true,
      };
    }
    return null;
  };

  const selectedFile = isFileSelected();
  const errorData = checkSizeError();

  const fileName = file ? file.slice(0, 50) : "  ";
  return (
    <View style={{ flexDirection: "column", paddingTop: 15 }}>
      <Text style={styles.addressProof}>{title}</Text>
      <TouchableOpacity
        onPress={() => openFilePicker()}
        style={{
          alignItems: "center",
          paddingVertical: 28,
          backgroundColor: "#f6f6f6",
          borderRadius: 4,
          borderStyle: "dashed",
          borderWidth: 1,
          borderColor: "rgba(65, 13, 170, 0.5)",
        }}
      >
        <Text style={styles.drop}>
          <Entypo name="attachment" size={20} color="#13C37B" /> Click here to
          browse and attach files or replace files
        </Text>
      </TouchableOpacity>
      {selectedFile ? (
        filesContent.map((file) => (
          <Text style={styles.fileName}>{file.name}</Text>
        ))
      ) : fileName.length > 2 ? (
        <TouchableOpacity onPress={() => file && openFile(file)}>
          <Text style={styles.fileName}>
            View {title.replace("Upload", "")}
          </Text>
        </TouchableOpacity>
      ) : null}
      <Text
        style={[
          styles.support,
          errorData &&
          errorData.error &&
          !errorData.fileSizeToolarge &&
          styles.error,
        ]}
      >
        Supports PNG, JPG, JPEG{isPdf ? ", PDF" : ""}.{" "}
        <Text style={[errorData && errorData.fileSizeToolarge && styles.error]}>
          Max File Size 500KB
        </Text>
      </Text>
    </View>
  );
};

class contact_info extends React.Component {
  constructor(props) {
    super(props);
    let username = null;
    let cust_id = null;
    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      username = params.username;
      cust_id = params.customer_id;
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
    }
    const { first_name } = getUserDetails();
    this.state = {
      states,
      RESIDENTIAL_TYPES,
      COUNTRIES,
      data: null,
      username,
      cust_id,
      user_id,
      address1: null,
      address2: null,
      pincode: null,
      state: null,
      nationality: null,
      typeofaddressgivenatkyc: null,
      data: null,
      address1Error: null,
      pincodeError: null,
      stateError: null,
      nationalityError: null,
      typeofaddressgivenatkycError: null,
      isLoading: false,
      isLoadingData: true,
      customer_nominee_name: null,
      nominee1_applicable: "100",
      nominee1_guardian: first_name,
      nominee1_date_of_birth: null,
      customer_nominee_relation: null,
      nominee1_minor_flag: null,
      nomineenameError: null,
      nomineeApplicableError: null,
      nomineeGuardianError: null,
      nomineeDOBError: null,
      nomineerelationError: null,
      guardianrelationError: null,
      nomineeTypeError: null,
      showModal: false,
      imageURL: "",
    };
  }

  componentDidMount = async () => {
    try {
      this.setState({ isLoadingData: true });
      let userDetails = getUserDetails()
      const userIdToFetchDetails = Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
        ? Store.getState().dashboard.viewModeUserId.user_id
        : String(userDetails.customer_id);
      const response = await getCustomerDetails(userIdToFetchDetails);
      if (response && response.pk != null) {
        let {
          address1,
          address2,
          pin_code,
          state,
          country_of_birth,
          type_of_address_given_at_kyc,
          address_proof,
          hand_signature_pic,
          type_of_address_proof,
          address_proof_back_side,
          customer_nominee_name,
          nominee1_applicable,
          nominee1_guardian,
          nominee1_date_of_birth,
          customer_nominee_relation,
          nominee1_minor_flag,
          guardian_relation
        } = response;

        this.setState({
          address1,
          address2: address2 || "",
          pincode: pin_code,
          state,
          nationality: country_of_birth,
          typeofaddressgivenatkyc: type_of_address_given_at_kyc,
          address_proof,
          hand_signature_pic,
          type_of_address_proof,
          typeOfAddressProof: type_of_address_proof,
          address_proof_back_side,
          customer_nominee_name,
          nominee1_applicable:
            nominee1_applicable == 0 ? "100" : nominee1_applicable,
          nominee1_guardian,
          nominee1_date_of_birth: nominee1_date_of_birth
            ? moment(nominee1_date_of_birth).format("DD/MM/YYYY")
            : "",
          selectedPicker: customer_nominee_relation,
          nominee1_minor_flag,
          customer_nominee_relation,
          gaurdianRelationPicker: guardian_relation
        });

        if (this.state.user_id == null) {
          this.props.saveUserProfileData(response);
        }
      }
      this.setState({ isLoadingData: false });
    } catch (error) {
      this.setState({
        isLoadingData: true,
      });
    }
  };

  formatDoB = (dob) => {
    if (dob) {
      const dob_values = dob.split("/");
      dob = dob_values[2] + "-" + dob_values[1] + "-" + dob_values[0];
    }
    return dob;
  };

  handleContinue = async () => {
    try {
      if (this.state.user_id) {
        let options = {};
        const { params } = this.props.route;
        if (params) {
          options = { ...params };
        }
        options["first"] = false;
        // this.props.navigation.navigate("NomineeInfo", { ...options });
        this.props.navigation.navigate("FinancialInfo", { ...options });
        return;
      }
      this.setState({
        isLoading: true,
        address1Error: null,
        pincodeError: null,
        stateError: null,
        nationalityError: null,
        typeofaddressgivenatkycError: null,
        pincodeError: null,
        handSignatureImageError: null,
      });
      let {
        username,
        cust_id,
        address1,
        address2,
        pincode,
        state,
        nationality,
        typeofaddressgivenatkyc,
        addressProof,
        handSignatureImage,
        hand_signature_pic,
        addressBackImage,
        typeOfAddressProof,
        address_proof_back_side,
        customer_nominee_name,
        nominee1_applicable,
        nominee1_guardian,
        nominee1_date_of_birth,
        customer_nominee_relation,
        nominee1_minor_flag,
        guardian_relation
      } = this.state;

      if (!cust_id || !username) {
        const userData = getUserDetails();
        username = userData.username;
        cust_id = userData.customer_id;
      }

      if (!customer_nominee_name) {
        this.setState({
          nomineenameError: "Plese enter your nominee name",
          isLoading: false,
        });
        return;
      }
      if (!nominee1_applicable) {
        this.setState({
          nomineeApplicableError: "Plesae enter nominee applicable percentage",
          isLoading: false,
        });
        return;
      }
      if (!nominee1_date_of_birth && this.state.nominee1_minor_flag == "Y") {
        this.setState({
          nomineeDOBError: "Plesae enter nominee date of birth",
          isLoading: false,
        });
        return;
      }
      let currentYear = moment().format("YYYY");
      let dobYear = nominee1_date_of_birth.split("/")[2];
      if (currentYear - dobYear >= 18 && this.state.nominee1_minor_flag == "Y") {
        this.setState({
          nomineeDOBError: "Nominee bate of birth should be less than 18 years",
          isLoading: false,
        });
        return;
      }

      if (!nominee1_guardian && this.state.nominee1_minor_flag == "Y") {
        this.setState({
          nomineeGuardianError: "Plesae enter nominee's guardian name",
          isLoading: false,
        });
        return;
      }

      if (
        !customer_nominee_relation ||
        customer_nominee_relation == "Select" ||
        customer_nominee_relation == 0
      ) {
        this.setState({
          nomineerelationError: "Please select your nominee Relation",
          isLoading: false,
        });
        return;
      }

      if (
        !nominee1_minor_flag ||
        nominee1_minor_flag == "Select" ||
        nominee1_minor_flag == 0
      ) {
        this.setState({
          nomineeTypeError: "Please select your nominee type",
          isLoading: false,
        });
        return;
      }

      if (!address1) {
        this.setState({
          address1Error: "please enter your address",
          isLoading: false,
        });
        return;
      }

      let pinValid = !isNaN(pincode) && pincode.length == 6;
      if (!pinValid) {
        this.setState({
          pincodeError: "Please enter valid six digit pincode",
          isLoading: false,
        });
        return;
      }

      if (!state || state == "Select" || state == 0) {
        this.setState({
          stateError: "Please select your state",
          isLoading: false,
        });
        return;
      }

      if (!nationality || nationality == "Select" || nationality == 0) {
        this.setState({
          nationalityError: "Please select your nationality",
          isLoading: false,
        });
        return;
      }

      if (
        !typeofaddressgivenatkyc ||
        typeofaddressgivenatkyc == "Select" ||
        typeofaddressgivenatkyc == 0
      ) {
        this.setState({
          typeofaddressgivenatkycError: "Please select your residential status",
          isLoading: false,
        });
        return;
      }

      if (
        !typeOfAddressProof ||
        typeOfAddressProof == "Select" ||
        typeOfAddressProof == 0
      ) {
        this.setState({
          typeOfAddressProofError: "Please select address proof",
          isLoading: false,
        });
        return;
      }

      const document_name = this.getDocumentName(typeOfAddressProof);
      if (this.state.type_of_address_proof != typeOfAddressProof) {
        if (!(addressProof && addressProof.imageURI)) {
          this.setState({
            addressProofError:
              "Please upload " +
              document_name +
              (typeOfAddressProof == "AAC" || typeOfAddressProof == "DL"
                ? " front image"
                : ""),
            isLoading: false,
          });
          return;
        }
      }

      if (
        !this.state.address_proof &&
        !(addressProof && addressProof.imageURI)
      ) {
        this.setState({
          addressProofError:
            "Please upload " +
            document_name +
            (typeOfAddressProof == "AAC" || typeOfAddressProof == "DL"
              ? " front image"
              : ""),
          isLoading: false,
        });
        return;
      }

      if (
        !hand_signature_pic &&
        !(handSignatureImage && handSignatureImage.imageURI)
      ) {
        this.setState({
          handSignatureImageError: "Please upload hand signature",
          isLoading: false,
        });
        return;
      }

      if (typeOfAddressProof == "AAC" || typeOfAddressProof == "DL") {
        if (
          (this.state.type_of_address_proof != typeOfAddressProof &&
            !(addressBackImage && addressBackImage.imageURI)) ||
          (!address_proof_back_side &&
            !(addressBackImage && addressBackImage.imageURI))
        ) {
          this.setState({
            addressBackImageError:
              "Please upload " + document_name + " back image",
            isLoading: false,
          });
          return;
        }
      }

      let body = new FormData();
      body.append("username", username);
      body.append("address1", address1);
      body.append("address2", address2);
      body.append("pin_code", pincode);
      body.append("country_of_birth", nationality);
      body.append("state", state);
      body.append("type_of_address_proof", typeOfAddressProof);
      body.append("type_of_address_given_at_kyc", typeofaddressgivenatkyc);
      body.append("customer_nominee_name", customer_nominee_name);
      body.append("nominee1_applicable", nominee1_applicable);
      body.append("customer_nominee_relation", customer_nominee_relation);
      body.append("nominee1_minor_flag", nominee1_minor_flag);
      body.append("guardian_relation", guardian_relation);


      if (this.state.nominee1_minor_flag == "Y") {
        body.append(
          "nominee1_date_of_birth",
          this.formatDoB(nominee1_date_of_birth)
        );
        body.append("nominee1_guardian", nominee1_guardian);
      }

      if (addressProof && addressProof.imageURI) {
        const imageFile = DataURIToBlob(addressProof.imageURI);
        body.append("address_proof", imageFile, addressProof.imageName);
      }

      if (handSignatureImage && handSignatureImage.imageURI) {
        const imageFile = DataURIToBlob(handSignatureImage.imageURI);
        body.append(
          "hand_signature_pic",
          imageFile,
          handSignatureImage.imageName
        );
      }

      if (addressBackImage && addressBackImage.imageURI) {
        const imageFile = DataURIToBlob(addressBackImage.imageURI);
        body.append(
          "address_proof_back_side",
          imageFile,
          addressBackImage.imageName
        );
      }

      const response = await contactInfo(cust_id, body);
      if (response && response.pk != null) {
        let hostname =
          Platform.OS == "web" ? global.location.hostname : "mobile";
        let is_club = false;
        let is_app = false;
        let is_dapp = false;
        let is_preferred_circle = false;
        if (hostname.includes("club")) {
          is_club = true;
        }
        if (hostname.includes("app")) {
          is_app = true;
        }
        if (hostname.includes("dapp")) {
          is_dapp = true;
        }
        if (hostname.includes("myadvisor")) {
          is_preferred_circle = true;
        }
        if (is_app || is_dapp || is_preferred_circle) {
          await createCrmdealsCipCbiForAccentureClients();
        }
        if (is_club) {
          await createCrmdealsCipForRegular();
        }
        this.setState({ isLoading: false });
        const minDetailsResponse = await completeMinDetails();
        const { needCompletion, error } = minDetailsResponse;

        if (!error) {
          const { redirect_screen } = minDetailsResponse;
          let options = {};
          if (this.props.route) {
            const { params } = this.props.route;
            if (params) {
              options = { ...params };
            }
            if (!needCompletion) {
              if (options.transaction) {
                navigateToTransaction(
                  this.props.navigation,
                  options.transaction
                );
              } else if (options.action) {
                this.props.navigation.navigate("ComplianceSuccess", {
                  ...options,
                });
                return;
              } else {
                this.props.navigation.navigate("OnboardingSuccess", {
                  ...options,
                });
                return;
              }
            }
          }
          this.props.navigation.navigate(redirect_screen, { ...options });
        } else {
          this.setState({
            error: true,
            errorText: "Something went wrong! Please contact help@maxiom.in",
          });
        }
      } else {
        this.setState({
          error: true,
          errorText: response && response.msg ? response.msg : null,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    }
  };

  handlePickerChange = (field, value) => {
    this.setState({ state: value, stateError: null });
  };

  handlePickerChange1 = (field, value) => {
    this.setState({ nationality: value, nationalityError: null });
  };

  handlePickerChange2 = (field, value) => {
    this.setState({
      typeofaddressgivenatkyc: value,
      typeofaddressgivenatkycError: null,
    });
  };

  handlePickerChange3 = (field, value) => {
    this.setState({
      customer_nominee_relation: value,
      selectedPicker: value,
      nomineerelationError: null,
    });
  };

  handlePickerChange5 = (field, value) => {
    this.setState({
      guardian_relation: value,
      gaurdianRelationPicker: value,
      guardianrelationError: null,
    });
  };

  handlePickerChange4 = (field, value) => {
    this.setState({
      nominee1_minor_flag: value,
      nomineeTypeError: null,
    });
  };

  handleInputChange = (field, value) => {
    this.setState({
      [field]: value,
      [field + "Error"]: value == null,
      addressBackImageError: null,
      addressProofError: null,
      ["addressProof"]: {
        imageURI: null,
        imageName: null,
      },
      ["addressBackImage"]: {
        imageURI: null,
        imageName: null,
      },
    });
  };

  getDocumentName = (typeOfAddressProof) => {
    let document_name = "Address Proof";
    switch (typeOfAddressProof) {
      case "AAC":
        document_name = "Aadhar Card";
        break;
      case "DL":
        document_name = "Driving License";
        break;
      case "PPT":
        document_name = "Passport";
        break;
      case "VOT":
        document_name = "Voter ID";
        break;
      case "ACP":
        document_name = "Aadhar ePDF";
        break;
    }
    return document_name;
  };

  getTitle = (type) => {
    const { typeOfAddressProof } = this.state;
    const document_name = this.getDocumentName(typeOfAddressProof);
    if (type === "address_back_image") {
      return (
        (this.state.address_proof_back_side
          ? this.state.type_of_address_proof != typeOfAddressProof
            ? "Upload "
            : "Upload "
          : "Upload ") +
        document_name +
        " Back Image"
      );
    } else {
      if (type === "address_front_image") {
        return (
          (this.state.address_proof
            ? this.state.type_of_address_proof != typeOfAddressProof
              ? "Upload "
              : "Upload "
            : "Upload ") +
          document_name +
          (typeOfAddressProof == "AAC" || typeOfAddressProof == "DL"
            ? " Front Image"
            : " File")
        );
      }
    }
  };

  render() {
    const {
      address1Error,
      pincodeError,
      address1,
      address2,
      pincode,
      stateError,
      nationalityError,
      typeofaddressgivenatkycError,
      isLoading,
      addressProofError,
      addressBackImageError,
      typeOfAddressProof,
      handSignatureImageError,
      typeOfAddressProofError,
      nomineenameError,
      nomineeApplicableError,
      nomineeGuardianError,
      nomineeDOBError,
      nomineerelationError,
      guardianrelationError,
      nomineeTypeError,
    } = this.state;
    return (
      <SafeAreaView style={styles.container}>
       <Header navigation={this.props.navigation} route={this.props.route} onPressBack={() => {
          if (this.props.route.params.screen === "InvestForGoals") {
            // console.log(this.props.route.params)
            this.props.navigation.navigate("App", {
              screen: "Goals",
            });
          } else if (this.props.route.params.screen === "InvestLumpsum"){
            this.props.navigation.navigate("App", {
              screen: "Explore",
              params: {
                screen: "MFExplore",
                params: {
                  index: "1",
                  filter: "Equity",
                  type: "Index",
                  from: "dashboard"
                },
              }
            });
          } else {
            // console.log("ahbsdlja",this.props.route.params)
            this.props.navigation.goBack();
          }
        }} />
        <Progressbar
          color2="#410DAA"
          color3="#f6f6f6"
          color4="#f6f6f6"
          color5="#f6f6f6"
          color6="#f6f6f6"
          color7="#f6f6f6"
          color8="#f6f6f6"
          color9="#f6f6f6"
          color10="#f6f6f6"
          color11="#f6f6f6"
          color12="#f6f6f6"
          bcolor1="#410DAA"
          bcolor2="#f6f6f6"
          bcolor3="#f6f6f6"
          bcolor4="#f6f6f6"
          bcolor5="#f6f6f6"
          bagcolor1="#410DAA"
          bagcolor2="#ffffff"
          bagcolor3="#ffffff"
          bagcolor4="#ffffff"
          bagcolor5="#ffffff"
          markdisplay1="none"
          markdisplay2="none"
          markdisplay3="none"
          markdisplay4="none"
          markdisplay5="none"
          dotdisplay1="none"
          dotdisplay2="none"
          dotdisplay3="none"
          dotdisplay4="none"
          dotdisplay5="none"
        />
        <StatusBar backgroundColor="#000" barStyle="dark-content" />
        <ScrollView>
          <View style={styles.body}>
            {isWeb && (
              <View style={styles.left}>
                {/* <Text style={styles.terms}>Address and Nominee Information</Text> */}
                <Text style={styles.please}>
                  Address , Signature and Nominee Details are Required as per
                  regulations for advise and making investments
                </Text>
                <Image
                  source={
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3.png"
                  }
                  style={styles.left_img}
                />
                <View
                  style={{
                    flexDirection: "row",
                    width: 430,
                    borderWidth: 1,
                    borderColor: "rgba(65, 13, 170, 0.2)",
                    backgroundColor: "#ffffff",
                    paddingVertical: 21,
                    paddingLeft: 20,
                    paddingRight: 6,
                    marginTop: 33,
                    marginBottom: 22,
                  }}
                >
                  <Image
                    source={
                      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/fluent_lock-shield-20-regular.png"
                    }
                    style={styles.lock}
                  />
                  <Text style={styles.jam_we}>
                    Information is encrypted using 256-bit keys and stored
                    securely
                  </Text>
                </View>
                <Text style={styles.any}>
                  Any Queries?{" "}
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("App", {
                        screen: "Dashboard",
                        params: {
                          screen: "Dashboard",
                          params: {
                            screen: "GetHelp",
                            params: {},
                          },
                        },
                      });
                    }}
                  >
                    <Text style={{ color: "#410DAA", fontWeight: "bold" }}>
                      Get Help
                    </Text>
                  </TouchableOpacity>
                </Text>
              </View>
            )}
            <View style={styles.right}>
              <View style={styles.mob_styl}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    paddingTop: md(width) ? 41 : 20,
                  }}
                >
                  Address Details
                </Text>

                <View
                  style={{
                    flexDirection: md(width) ? "row" : "column",
                    marginTop: md(width) ? 20 : 20,
                  }}
                >
                  <Text style={styles.terms1}>Address and Nominee Info</Text>
                  <Text style={styles.please1}>
                    Required per regulation, for communication
                  </Text>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "column",
                      marginRight: md(width) ? 29 : 0,
                    }}
                  >
                    <Text style={[styles.add1]}>Address Line 1*</Text>
                    <TextInput
                      value={address1}
                      placeholder={""}
                      style={styles.inptxt}
                      onChangeText={(text) => {
                        this.setState({ address1: text });
                      }}
                    />

                    <Text style={[styles.max, address1Error && styles.error]}>
                      {address1 ? "" : address1Error}
                    </Text>
                  </View>
                  <View style={{ flex: 1, flexDirection: "column" }}>
                    <Text
                      style={[styles.add1, { paddingTop: md(width) ? 0 : 6 }]}
                    >
                      Address Line 2
                    </Text>
                    <TextInput
                      value={address2}
                      placeholder={""}
                      style={styles.inptxt}
                      onChangeText={(text) => {
                        this.setState({ address2: text });
                      }}
                    />
                    <Text style={styles.max}></Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: md(width) ? "row" : "column",
                    paddingTop: isMobile ? 6 : 27,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "column",
                      marginRight: md(width) ? 29 : 0,
                    }}
                  >
                    <Text style={styles.add1}>Pincode*</Text>
                    <TextInput
                      value={pincode}
                      placeholder={"Enter Pincode"}
                      //placeholderTextColor="#00000030"
                      style={styles.inptxt}
                      onChangeText={(text) => {
                        const pincodeError = !isNaN(text) && text.length == 6;
                        this.setState({ pincode: text, pincodeError });
                      }}
                      onBlur={() => {
                        const { pincode } = this.state;
                        const pinRegEx = /^([1-9]){6}?$/;
                        const pinValid = RegExp(pinRegEx).test(pincode);
                        let errors = {};
                        if (pinValid) {
                          errors["pincodeError"] = null;
                        }
                        this.setState({ ...errors });
                      }}
                    />
                    <Text style={[styles.max, pincodeError && styles.error]}>
                      {pincodeError || "  "}
                    </Text>
                  </View>
                  <View style={{ flex: 1, flexDirection: "column" }}>
                    <Text
                      style={[styles.add1, { paddingTop: md(width) ? 0 : 6 }]}
                    >
                      State*
                    </Text>
                    <View style={{ flexDirection: "row" }}>
                      <PickerFieldSelect
                        data={states}
                        value={this.state.state}
                        style={{
                          // backgroundColor: "#f6f6f6",
                          width: isMobile
                            ? getWindowSize().width * 0.89
                            : "100%",
                          height: "100%",
                          paddingVertical: Platform.OS == "ios" ? 12 : 0,
                          paddingHorizontal: Platform.OS == "ios" ? 12 : 0,
                        }}
                        onChange={(itemValue) => {
                          this.handlePickerChange("state", itemValue);
                        }}
                        pickerStyle={[
                          {
                            color: "#121212",
                          },
                        ]}
                        type="select"
                        placeholder={{
                          label: "Select",
                          value: null,
                          color: "#9EA0A4",
                        }}
                      />
                      <View
                        style={{
                          width: 10,
                          height: 40,
                          backgroundColor: "#f6f6f6",
                        }}
                      ></View>
                    </View>
                    <Text style={[styles.max, stateError && styles.error]}>
                      {stateError}
                    </Text>
                  </View>
                </View>
                <View style={[isMobile ? styles.rowContianer : styles.row]}>
                  <View style={[styles.rowItem, styles.marginRight]}>
                    <Text style={styles.add1}>Nationality*</Text>
                    <PickerFieldSelect
                      data={COUNTRIES}
                      value={this.state.nationality}
                      style={{
                        // backgroundColor: "#f6f6f6",
                        width: isMobile ? getWindowSize().width * 0.92 : 677,
                        paddingVertical: Platform.OS == "ios" ? 12 : 0,
                        paddingHorizontal: Platform.OS == "ios" ? 12 : 0,
                      }}
                      onChange={(itemValue) => {
                        this.handlePickerChange1("nationality", itemValue);
                      }}
                      pickerStyle={[
                        {
                          color: "#121212",
                        },
                      ]}
                      type="select"
                      placeholder={{
                        label: "Select",
                        value: null,
                        color: "#9EA0A4",
                      }}
                    />
                    <Text
                      style={[styles.max, nationalityError && styles.error]}
                    >
                      {nationalityError}
                    </Text>
                  </View>
                  <View style={styles.rowItem}>
                    <Text style={styles.add1}>Residential Status*</Text>
                    <PickerFieldSelect
                      data={RESIDENTIAL_TYPES}
                      value={this.state.typeofaddressgivenatkyc}
                      style={{
                        width: isMobile ? getWindowSize().width * 0.92 : 677,
                        paddingVertical: Platform.OS == "ios" ? 12 : 0,
                        paddingHorizontal: Platform.OS == "ios" ? 12 : 0,
                      }}
                      onChange={(itemValue) => {
                        this.handlePickerChange2(
                          "typeofaddressgivenatkyc",
                          itemValue
                        );
                      }}
                      pickerStyle={[
                        {
                          color: "#121212",
                        },
                      ]}
                      type="select"
                      placeholder={{
                        label: "Select",
                        value: null,
                        color: "#9EA0A4",
                      }}
                    />
                    <Text
                      style={[
                        styles.max,
                        typeofaddressgivenatkycError && styles.error,
                      ]}
                    >
                      {typeofaddressgivenatkycError}
                    </Text>
                  </View>
                </View>
                <View style={[isMobile ? styles.rowContianer : styles.row]}>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.add1}>Type Of Address Proof*</Text>
                    <PickerFieldSelect
                      data={address_options}
                      value={this.state.typeOfAddressProof}
                      style={{
                        borderColor: "none",
                        borderRadius: 4,
                        borderWidth: 0,
                        paddingHorizontal: 19,
                        color: "#121212",
                        fontFamily: "Roboto",
                        fontSize: md(width) ? 12 : 13,
                        lineHeight: md(width) ? 14 : 15,
                        width: isMobile ? getWindowSize().width * 0.88 : 677,
                        paddingVertical: Platform.OS == "ios" ? 12 : 0,
                        paddingHorizontal: Platform.OS == "ios" ? 12 : 0,
                      }}
                      onChange={(itemValue) => {
                        this.handleInputChange("typeOfAddressProof", itemValue);
                      }}
                      pickerStyle={[
                        {
                          color: "#121212",
                        },
                      ]}
                      type="select"
                      placeholder={{
                        label: "Select",
                        value: null,
                        color: "#9EA0A4",
                      }}
                    />
                    <Text
                      style={[
                        styles.max,
                        typeOfAddressProofError && styles.error,
                      ]}
                    >
                      {typeOfAddressProofError || "  "}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flex: 1,
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  {typeOfAddressProof ? (
                    <>
                      <SelectChequeLeaf
                        imageURL={(imageURL) => {
                          let image = imageURL;
                          this.setState({ imageURL: image });
                        }}
                        showModal={() => {
                          this.setState({ showModal: true });
                        }}
                        onSelect={(filesContent) => {
                          if (filesContent && filesContent.length > 0) {
                            const { content: imageURI, name: imageName } =
                              filesContent[0];
                            this.setState({
                              ["addressProof"]: {
                                imageURI,
                                imageName,
                              },
                              addressProofError: null,
                            });
                          }
                        }}
                        title={this.getTitle("address_front_image")}
                        file={
                          this.state.type_of_address_proof == typeOfAddressProof
                            ? this.state.address_proof
                            : null
                        }
                      />
                      <Text
                        style={[styles.max, addressProofError && styles.error]}
                      >
                        {addressProofError || "  "}
                      </Text>
                    </>
                  ) : null}
                  {typeOfAddressProof == "AAC" || typeOfAddressProof == "DL" ? (
                    <>
                      <View style={{ padding: 10 }}></View>
                      <SelectChequeLeaf
                        imageURL={(imageURL) => {
                          let image = imageURL;
                          this.setState({ imageURL: image });
                        }}
                        showModal={() => {
                          this.setState({ showModal: true });
                        }}
                        onSelect={(filesContent) => {
                          if (filesContent && filesContent.length > 0) {
                            const { content: imageURI, name: imageName } =
                              filesContent[0];
                            this.setState({
                              ["addressBackImage"]: {
                                imageURI,
                                imageName,
                              },
                              addressBackImageError: null,
                            });
                          }
                        }}
                        title={this.getTitle("address_back_image")}
                        file={
                          this.state.type_of_address_proof == typeOfAddressProof
                            ? this.state.address_proof_back_side
                            : null
                        }
                      />
                      <Text
                        style={[
                          styles.max,
                          addressBackImageError && styles.error,
                        ]}
                      >
                        {addressBackImageError || ""}
                      </Text>
                    </>
                  ) : null}
                </View>
                <>
                  <SelectChequeLeaf
                    imageURL={(imageURL) => {
                      let image = imageURL;
                      this.setState({ imageURL: image });
                    }}
                    showModal={() => {
                      this.setState({ showModal: true });
                    }}
                    isPdf={false}
                    onSelect={(filesContent) => {
                      if (filesContent && filesContent.length > 0) {
                        const { content: imageURI, name: imageName } =
                          filesContent[0];
                        this.setState({
                          ["handSignatureImage"]: {
                            imageURI,
                            imageName,
                          },
                          handSignatureImageError: null,
                        });
                      }
                    }}
                    title={"Hand Signature Image"}
                    file={
                      this.state.hand_signature_pic
                        ? this.state.hand_signature_pic
                        : null
                    }
                  />
                  <Text
                    style={[
                      styles.max,
                      handSignatureImageError && styles.error,
                    ]}
                  >
                    {handSignatureImageError || ""}
                  </Text>
                </>
                <View style={{ paddingTop: 10 }}>{/* <Divider /> */}</View>
                <View style={{ paddingTop: isMobile ? 6 : 20, paddingTop: 40 }}>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                    }}
                  >
                    Nominee Details
                  </Text>
                  <View
                    style={{
                      flexDirection: md(width) ? "row" : "column",
                      paddingTop: 12,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "column",
                        marginRight: md(width) ? 29 : 0,
                      }}
                    >
                      <Text style={styles.add1}>Nominee Name*</Text>
                      <TextInput
                        value={this.state.customer_nominee_name}
                        placeholder={"Enter Nominee Name"}
                        style={styles.inptxt}
                        onChangeText={(text) => {
                          this.setState({
                            customer_nominee_name: text,
                            nomineenameError: null,
                          });
                        }}
                      />
                      <Text
                        style={[styles.max, !!nomineenameError && styles.error]}
                      >
                        {nomineenameError}
                      </Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: "column" }}>
                      <Text style={styles.add1}>Nominee Relation*</Text>
                      <PickerFieldSelect
                        data={relations}
                        value={this.state.selectedPicker}
                        style={{
                          borderColor: "none",
                          borderRadius: 4,
                          borderWidth: 0,
                          // backgroundColor: "#f6f6f6",
                          paddingHorizontal: 19,
                          color: "#121212",
                          fontFamily: "Roboto",
                          fontSize: md(width) ? 12 : 13,
                          lineHeight: md(width) ? 14 : 15,
                          width: isMobile ? getWindowSize().width * 0.88 : 677,
                          paddingVertical: Platform.OS == "ios" ? 12 : 0,
                          paddingHorizontal: Platform.OS == "ios" ? 12 : 0,
                        }}
                        onChange={(itemValue) => {
                          this.handlePickerChange3("relationship", itemValue);
                        }}
                        pickerStyle={[
                          {
                            color: "#121212",
                          },
                        ]}
                        type="select"
                        placeholder={{
                          label: "Select Relation",
                          value: null,
                          color: "#9EA0A4",
                        }}
                      />
                      <Text
                        style={[
                          styles.max,
                          nomineerelationError && styles.error,
                        ]}
                      >
                        {nomineerelationError}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: md(width) ? "row" : "column",
                      paddingTop: 12,
                    }}
                  >
                    <View style={{ flex: 1, flexDirection: "column" }}>
                      <Text style={styles.add1}>Is Nominee Minor*</Text>
                      <PickerFieldSelect
                        data={is_minor}
                        value={this.state.nominee1_minor_flag}
                        style={{
                          borderColor: "none",
                          borderRadius: 4,
                          borderWidth: 0,
                          paddingHorizontal: 19,
                          color: "#121212",
                          fontFamily: "Roboto",
                          fontSize: md(width) ? 12 : 13,
                          lineHeight: md(width) ? 14 : 15,
                          width: isMobile ? getWindowSize().width * 0.88 : 677,
                          paddingVertical: Platform.OS == "ios" ? 12 : 0,
                        }}
                        onChange={(itemValue) => {
                          this.handlePickerChange4("isminor", itemValue);
                        }}
                        placeholder={{
                          label: "Select Nominee Type",
                          value: null,
                          color: "#9EA0A4",
                        }}
                      />
                      <Text style={[styles.max, nomineeTypeError && styles.error]}>
                        {nomineeTypeError}
                      </Text>

                      {this.state.nominee1_minor_flag === "Y" && (
                        <View style={{ paddingTop: 12 }}>
                          <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <View style={{ flex: 1, marginRight: 15 }}>
                              <Text style={styles.add1}>Nominee DOB (dd/mm/yyyy)*</Text>
                              <TextInput
                                value={this.state.nominee1_date_of_birth}
                                placeholder={"Nominee DOB (dd/mm/yyyy)*"}
                                style={styles.inptxt}
                                onChangeText={(text) => {
                                  this.setState({
                                    nominee1_date_of_birth: text,
                                    nomineeDOBError: null,
                                  });
                                }}
                              />
                              <Text style={[styles.max, !!nomineeDOBError && styles.error]}>
                                {nomineeDOBError}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text style={styles.add1}>Guardian Name*</Text>
                              <TextInput
                                value={this.state.nominee1_guardian}
                                placeholder={"Enter Nominee's Guardian Name"}
                                style={styles.inptxt}
                                onChangeText={(text) => {
                                  this.setState({
                                    nominee1_guardian: text,
                                    nomineeGuardianError: null,
                                  });
                                }}
                              />
                              <Text style={[styles.max, !!nomineeGuardianError && styles.error]}>
                                {nomineeGuardianError}
                              </Text>
                            </View>
                          </View>

                          {/* <View style={{ marginTop: 12 }}>
                            <Text style={styles.add1}>Guardian Relation*</Text>
                            <PickerFieldSelect
                              data={guardianrelations}
                              value={this.state.gaurdianRelationPicker}
                              style={{
                                borderColor: "none",
                                borderRadius: 4,
                                borderWidth: 0,
                                paddingHorizontal: 19,
                                color: "#121212",
                                fontFamily: "Roboto",
                                fontSize: md(width) ? 12 : 13,
                                lineHeight: md(width) ? 14 : 15,
                                width: isMobile ? getWindowSize().width * 0.88 : 677,
                                paddingVertical: Platform.OS == "ios" ? 12 : 0,
                              }}
                              onChange={(itemValue) => {
                                this.handlePickerChange5("guardian_relation", itemValue);
                              }}
                              placeholder={{
                                label: "Select Relation",
                                value: null,
                                color: "#9EA0A4",
                              }}
                            />
                            <Text style={[styles.max, guardianrelationError && styles.error]}>
                              {guardianrelationError}
                            </Text>
                          </View> */}
                        </View>
                      )}
                    </View>

                    {/* <View style={{ flex: 1, flexDirection: "column" }}>
                      <Text style={styles.add1}>Is Nominee Minor?*</Text>
                      <PickerFieldSelect
                        data={is_minor}
                        value={this.state.nominee1_minor_flag}
                        style={{
                          borderColor: "none",
                          borderRadius: 4,
                          borderWidth: 0,
                          paddingHorizontal: 19,
                          color: "#121212",
                          fontFamily: "Roboto",
                          fontSize: md(width) ? 12 : 13,
                          lineHeight: md(width) ? 14 : 15,
                          width: isMobile ? getWindowSize().width * 0.88 : 677,
                          paddingVertical: Platform.OS == "ios" ? 12 : 0,
                          paddingHorizontal: Platform.OS == "ios" ? 12 : 0,
                        }}
                        onChange={(itemValue) => {
                          this.handlePickerChange4("isminor", itemValue);
                        }}
                        pickerStyle={[
                          {
                            color: "#121212",
                          },
                        ]}
                        type="select"
                        placeholder={{
                          label: "Select Nominee Type",
                          value: null,
                          color: "#9EA0A4",
                        }}
                      />
                      <Text
                        style={[styles.max, nomineeTypeError && styles.error]}
                      >
                        {nomineeTypeError}
                      </Text>
                    </View>
                  </View>
                  {this.state.nominee1_minor_flag == "Y" && (
                    <View
                      style={{
                        flexDirection: md(width) ? "row" : "column",
                        paddingTop: 12,
                      }}
                    >
                      
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "column",
                          marginRight: md(width) ? 29 : 0,
                        }}
                      >
                        <Text style={styles.add1}>
                          Nominee DOB (dd/mm/yyyy)*
                        </Text>
                        <TextInput
                          value={this.state.nominee1_date_of_birth}
                          placeholder={"Nominee DOB (dd/mm/yyyy)*"}
                          style={styles.inptxt}
                          onChangeText={(text) => {
                            this.setState({
                              nominee1_date_of_birth: text,
                              nomineeDOBError: null,
                            });
                          }}
                        />
                        <Text
                          style={[
                            styles.max,
                            !!nomineeDOBError && styles.error,
                          ]}
                        >
                          {nomineeDOBError}
                        </Text>
                      </View>
                      
                      <View style={{ flex: 1, flexDirection: "column" }}>
                        <Text style={styles.add1}>Guardian Name*</Text>
                        <TextInput
                          value={this.state.nominee1_guardian}
                          placeholder={"Enter Nominee's Guardian Name"}
                          style={styles.inptxt}
                          onChangeText={(text) => {
                            this.setState({
                              nominee1_guardian: text,
                              nomineeGuardianError: null,
                            });
                          }}
                        />
                        <Text
                          style={[
                            styles.max,
                            !!nomineeGuardianError && styles.error,
                          ]}
                        >
                          {nomineeGuardianError}
                        </Text>
                      </View>
                       */}
                  </View>



                </View>

                {isLoading ? (
                  <ActivityIndicator
                    size="large"
                    color="#410DAA"
                    style={{ paddingTop: 40 }}
                  />
                ) : (Store.getState().dashboard &&
                  Store.getState().dashboard.viewModeUserId &&
                  Store.getState().dashboard.viewModeUserId.user_id) ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        this.handleContinue();
                      }}
                    >
                      <Text style={styles.viewModeText}>Next {">>>"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        let url = new URL(global.location.href);
                        location.replace(
                          url.origin +
                          "/dashboard/home?user_id=" +
                          this.state.user_id
                        );
                      }}
                    >
                      <Text style={styles.viewModeText}>
                        Go to Dashboard {">>>"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      this.handleContinue();
                    }}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 40.7,
                    }}
                  >
                    <Text style={styles.next}>Continue</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>
        </ScrollView>
        <CustomModal
          visible={this.state.showModal}
          showCloseButton={true}
          closeModal={() => {
            this.setState({ showModal: false });
          }}
        >
          <View style={{ padding: 10 }}>
            <Image
              source={{
                uri: this.state.imageURL,
              }}
              resizeMode="contain"
              style={{ height: 400, width: 400 }}
            />
          </View>
        </CustomModal>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1 },

  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: md(width) ? 110 : 17,
    paddingRight: md(width) ? 150 : 16,
    paddingBottom: 120,
  },
  left: {
    flex: 0.45,
    paddingTop: 55,
  },
  right: {
    flex: md(width) ? 0.55 : 1,
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },

  lock: {
    resizeMode: "contain",
    width: 30,
    height: 30,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 18 : 15,
    lineHeight: md(width) ? 21 : 17,
    color: "#2F3044",
  },
  terms1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 22 : 18,
    lineHeight: md(width) ? 25 : 20,
    color: "#2F3044",
    display: md(width) ? "none" : "flex",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 15 : 12,
    lineHeight: 17,
    color: "#464E5F",
    paddingTop: 12,
    paddingBottom: md(width) ? 32 : 26,
    paddingRight: md(width) ? 150 : 11,
    textAlign: "justify",
  },
  please1: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 16 : 12,
    lineHeight: 18,
    color: "#464E5F",
    paddingTop: isMobile ? 11 : 0,
    paddingBottom: md(width) ? 32 : 26,
    paddingRight: md(width) ? 170 : 11,
    display: md(width) ? "none" : "flex",
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  add1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 14 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    paddingBottom: 6.35,
  },
  inptxt: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 12 : 13,
    lineHeight: md(width) ? 14 : 15,
    color: "#121212",
    paddingVertical: isMobile ? 8 : 13.5,
    paddingHorizontal: 18.75,
    backgroundColor: "#f6f6f6",
    borderRadius: 4,
  },
  max: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  res: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 16 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    paddingBottom: 13.29,
  },
  drop: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#121212",
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 18,
    marginTop: 20,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  next: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    fontWeight: "bold",
    paddingVertical: 12,
    paddingHorizontal: 79,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  view_sample: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#8950FC",
    paddingBottom: md(width) ? 5 : 10,
  },
  addressProof: {
    fontSize: 11,
    color: "#121212",
    marginBottom: 14,
    fontWeight: "700",
  },
  fileName: {
    fontSize: 12,
    marginTop: 5,
    color: "#410DAA",
    fontWeight: "bold",
  },
  support: {
    fontSize: 10,
    color: "#121212",
    fontStyle: "italic",
    marginTop: 4,
  },
  error: { color: "red" },
  rowContianer: {
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 28,
  },
  marginRight: {
    marginRight: isMobile ? 0 : 29,
  },
  rowItem: {
    flex: 0.5,
  },
});

const mapStateToProps = (state) => {
  return {
    userProfileData: state.userData.userProfileData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveUserProfileData: (data) => dispatch(saveUserProfileData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(contact_info);
