import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  Dimensions,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import amplitude from "../../../utils/amplitude";
import { md } from "../../../Responsive";
import NavigationHeader from "../../Common/NavigationHeader";
import { getAssetsDetails } from "../../../api";
import { isMobile } from "../../../utils";
import { TouchableOpacity } from "react-native-web";
const { width } = Dimensions.get("window");
const isWeb = md(width);

const ToggleAssets = ({ tabs, activeIndex, onChangeValue, style = {} }) => {
  return (
    <ScrollView horizontal={true}>
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "#E5EAEE",
          borderBottomWidth: 1,
          marginBottom: 10,
          ...style,
        }}
      >
        {tabs.map((tab, index) => {
          return (
            <TouchableOpacity
              onPress={() => {
                onChangeValue && onChangeValue(index);
              }}
            >
              <Text
                style={{
                  color: index == activeIndex ? "#121212" : "#888C9F",
                  fontSize: 14,
                  paddingHorizontal: 30,
                  paddingVertical: 12,
                  borderBottomWidth: 3,
                  borderBottomColor:
                    activeIndex == index ? "#121212" : "transparent",
                }}
              >
                {tab}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    </ScrollView>
  );
};

export default class assetspage extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let userId = null;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }

    this.state = {
      userId,
      activeTabIndex: 0,
      isLoading: true,
      filters: [],
      mfDetails: [],
      mfDetailsMobileView: [],
      isSwitchOn: false,
      activeTabIndex: 0,
      assets: {},
      filteredAsset: [],
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("assetspage",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("assetspage", { path: this.props.navigation.getState().routeName });
      }
      const response = await getAssetsDetails({
        cust_id: this.state.userId,
      });

      if (
        response &&
        response.code &&
        response.code == "100" &&
        response.results
      ) {
        this.setState({
          assets: response.results,
          filteredAsset: response.results.all,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText:
            response && response.msg ? response.msg : "Something went wrong!",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  setModalMethod = (method) => {
    this.openOrCloseModal = method;
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  render() {
    const { error, errorText, isLoading } = this.state;
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View style={{ flex: 1, maxWidth: 1500 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Assets"]}
            onPressBack={() => {
              this.props.navigation.navigate("InvestmentOverview");
            }}
          />
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <ActivityIndicator size="large" color="#410DAA" />
            ) : error ? (
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText || "Something went wrong!"}
              </Text>
            ) : (
              <ScrollView style={[styles.mainContainer]}>
                <View
                  style={{ paddingBottom: 40, marginTop: isMobile ? -3 : -17 }}
                >
                  <ToggleAssets
                    tabs={[
                      "All",
                      "Stocks",
                      "Property",
                      "Bonds & Deposits",
                      "Gold",
                      "Insurance",
                    ]}
                    activeIndex={this.state.activeTabIndex}
                    onChangeValue={(selectedIndex) => {
                      if (selectedIndex != this.state.activeTabIndex) {
                        this.setState({ activeTabIndex: selectedIndex }, () => {
                          if (selectedIndex == 0) {
                            this.setState({
                              filteredAsset: this.state.assets.all,
                            });
                          }
                          if (selectedIndex == 1) {
                            this.setState({
                              filteredAsset: this.state.assets.stocks,
                            });
                          }
                          if (selectedIndex == 2) {
                            this.setState({
                              filteredAsset: this.state.assets.property,
                            });
                          }
                          if (selectedIndex == 3) {
                            this.setState({
                              filteredAsset: this.state.assets.debt,
                            });
                          }
                          if (selectedIndex == 4) {
                            this.setState({
                              filteredAsset: this.state.assets.gold,
                            });
                          }
                          if (selectedIndex == 5) {
                            this.setState({
                              filteredAsset: this.state.assets.insurance,
                            });
                          }
                        });
                      }
                    }}
                  />

                  {this.state.filteredAsset.length > 0 ? (
                    <>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: isMobile ? "90%" : "52%",
                          marginLeft: isMobile ? "5%" : "0%",
                          marginRight: isMobile ? "5%" : "48%",
                        }}
                      >
                        <View style={{ flex: 2 }}>
                          <Text
                            style={{
                              fontSize: 14,
                              color: "#464E5F",
                              fontWeight: "bold",
                              marginTop: 20,
                              marginBottom: 10,
                            }}
                          >
                            {"Available Assets (" +
                              this.state.filteredAsset.length +
                              ")"}
                          </Text>
                        </View>
                        <View
                          style={{
                            flex: 1.5,
                            marginTop: 3,
                            alignItems: "flex-end",
                          }}
                        >
                          <TouchableOpacity
                            style={{
                              width: 150,
                              height: 35,
                              marginTop: 10,
                              borderRadius: 20,
                              backgroundColor: "#410DAA1A",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onPress={() => {
                              this.props.navigation.navigate("App", {
                                screen: "Dashboard",
                                params: {
                                  screen: "Portfolio",
                                  params: {
                                    screen: "AddNewAsset",
                                  },
                                },
                              });
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 13,
                                fontWeight: "500",
                                color: "#410DAA",
                              }}
                            >
                              Add New Asset
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      {this.state.filteredAsset.map((data, key) => (
                        <View
                          key={key}
                          style={{
                            width: isMobile ? "90%" : "52%",
                            marginLeft: isMobile ? "5%" : "0%",
                            marginRight: isMobile ? "5%" : "48%",
                            marginTop: isMobile ? 15 : 20,
                            borderRadius: 10,
                            borderWidth: 1,
                            borderColor: "#410DAA1A",
                            padding: 10,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "flex-start",
                            }}
                          >
                            <View style={{ flex: 0.7 }}>
                              <Text
                                style={{
                                  color: "#121212",
                                  fontSize: 14,
                                  fontWeight: "600",
                                }}
                              >
                                {data.name}
                              </Text>
                            </View>
                            <View style={{ flex: 0.3, alignItems: "flex-end" }}>
                              <Text
                                style={{
                                  color: "#121212",
                                  fontSize: 14,
                                  fontWeight: "600",
                                }}
                              >
                                {data.amount_at_cost_text}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              paddingTop: 10,
                            }}
                          >
                            <View
                              style={{
                                flex: 0.7,
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              {data.asset_type_text ? (
                                <Text
                                  style={{
                                    color: "#121212",
                                    fontSize: 12,
                                    backgroundColor: "#F2F2F2",
                                    padding: 4,
                                    paddingHorizontal: 16,
                                    borderRadius: 10,
                                    fontWeight: "600",
                                  }}
                                >
                                  {data.asset_type_text}
                                </Text>
                              ) : (
                                <Text></Text>
                              )}
                            </View>
                            <View style={{ flex: 0.3, alignItems: "flex-end" }}>
                              <TouchableOpacity
                                onPress={() => {
                                  this.props.navigation.navigate(
                                    "ModifyAsset",
                                    {
                                      assetName: data.name,
                                      type: data.asset_type,
                                      value: data.amount_at_cost_of_purchase,
                                      id: data.pk,
                                    }
                                  );
                                }}
                                style={{
                                  borderColor: "#410DAA",
                                  borderRadius: 6,
                                  borderWidth: 1,
                                }}
                              >
                                <Text
                                  style={{
                                    color: "#410DAA",
                                    fontSize: 12,
                                    paddingVertical: 2,
                                    paddingHorizontal: 15,
                                    fontWeight: "600",
                                  }}
                                >
                                  Edit
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      ))}
                    </>
                  ) : (
                    <View
                      style={{
                        marginTop: 200,
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          marginBottom: 20,
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {this.state.activeTabIndex == 0
                          ? "Assets are outside investments bank deposits, bonds, gold or property. Add and tag to goals to track progress."
                          : "No asset available in this category, kindly add a new asset."}
                      </Text>
                      <TouchableOpacity
                        style={{
                          width: 150,
                          height: 35,
                          borderRadius: 20,
                          backgroundColor: "#410DAA1A",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onPress={() => {
                          this.props.navigation.navigate("App", {
                            screen: "Dashboard",
                            params: {
                              screen: "Portfolio",
                              params: {
                                screen: "AddNewAsset",
                              },
                            },
                          });
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 13,
                            fontWeight: "500",
                            color: "#410DAA",
                          }}
                        >
                          Add New Asset
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
                {isMobile && <View style={{ marginTop: 35 }}></View>}
              </ScrollView>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: isWeb ? 32 : 0,
    paddingRight: isWeb ? 32 : 0,
    paddingTop: isWeb ? 30 : 10,
    width: "100%",
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
  filterText: {
    fontSize: 15,
    color: "gray",
    fontWeight: "200",
  },
  filtersTitle: {
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 6,
    marginLeft: isMobile ? 16 : 0,
  },
});
