import { GET_PORTFOLIO, LOGOUT } from "../actions/types";

const initialState = {
  landingPageResponse: null,
};

const portfolioReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PORTFOLIO:
      return {
        ...state,
        landingPageResponse: {
          ...action.payload.landingPageResponse,
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default portfolioReducer;
