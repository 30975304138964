import React, { useState } from "react";
import { View, Text, StyleSheet, Platform } from "react-native";
import { isMobile, numberWithCommas } from "../../../utils";
import { getInvoicesPaymentLink } from "../../../api/billing";
import { Button } from "react-native-paper";
import { useNavigation } from "@react-navigation/core";

const TotalDue = React.forwardRef(({ data = {}, invoicesData = [] }, ref) => {
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();
  React.useImperativeHandle(ref, () => ({
    retryPayment() {
      handleMakePayment();
    },
  }));

  const handleMakePayment = async () => {
    try {
      if(data["total_amount_to_pay"] == 0){
        console.log("error")
        alert(
          "Please select some invoices before proceeding."
      );
        return;
      }
      const invoiceIds = invoicesData
        .map((invoice) => invoice.invoice_pk)
        .join(";");

      const payload = {
        invoices: invoiceIds,
        amount: data["total_amount_to_pay"],
        server_name: Platform.OS == "web" ? global.location.href.split("/")[2] : "mobile",
      };
      setLoading(true);
      const response = await getInvoicesPaymentLink(payload);
      if (response && response.code && response.code == 100) {
        const { results = {} } = response;
        const { payment_link } = results;
        if (Platform.OS == "web") {
          global.open(payment_link, "_self");
        } else {
          navigation.navigate("App", {
            screen: "Dashboard",
            params: {
              screen: "Portfolio",
              params: {
                screen: "ShowWebView",
                params: {
                  url: payment_link,
                  successURL: "billing/payment/status/",
                  onSuccess: function (navigation, _results = {}) {
                    navigation.push("App", {
                      screen: "Billing",
                      params: {
                        screen: "InvoicesPaymentRedirection",
                        params: {},
                      },
                    });
                  },
                  onError: function (_results = {}) {},
                },
              },
            },
          });
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <View style={styles.bottom} ref={ref}>
      <View>
        <View style={styles.payNowContainer}>
          <Text style={styles.grand}>Grand Total:</Text>
          <Text style={[styles.grandAmount, styles.grand]}>
            ₹{numberWithCommas(parseInt(data.total_amount_to_pay))}
          </Text>
          <Button
            uppercase={false}
            color={"#410DAA"}
            mode="contained"
            onPress={handleMakePayment}
            loading={loading}
            style={styles.btn}
          >
            Pay Now
          </Button>
        </View>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  direct: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sub_tot: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 15,
    color: "#464E5F",
    letterSpacing: 0.32,
    paddingVertical: 5,
  },
  grand: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 16,
    color: "#000000",
    letterSpacing: 0.32,
    fontWeight: "bold",
    paddingVertical: 7,
  },
  grandAmount: {
    marginHorizontal: 10,
  },
  bottom: {
    alignItems: isMobile ? "center" : "flex-end",
    justifyContent: isMobile ? "center" : "flex-end",
    borderTopWidth: 7,
    borderTopColor: "#E5EAEE",
    backgroundColor: "rgba(247, 244, 251, 0.7)",
    marginTop: 20,
    padding: isMobile ? 16 : 16,
  },
  btn: {
    width: isMobile ? "40%" : 100,
  },
  payNowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default TotalDue;
