import { Dimensions, Platform } from "react-native";
import { xs, md } from "../Responsive";
// import { getCustomerDetails, getOnboardingStatus, logErrorInSentry } from "../api";
import { getCustomerDetails, getOnboardingStatus} from "../api";
import { MAIN_URL, SECRET_KEY_HEX } from "../api/urls";
import { GATEWAY_ENVIRONMENT } from "../api/apiConfig";
import { getMFMinDetailsStatus } from "../api/finplan";
const DocumentPicker =
  Platform.OS == "web"
    ? require("expo-document-picker")
    : require("expo-document-picker").default;
// import { types } from "expo-document-picker";
import { IS_NEW_USER } from "../actions/types";
import CryptoJS from 'crypto-js';
import Store from "../store";

export const getWindowSize = () => {
  return { ...Dimensions.get("window") };
};
export const capitalizeFirstLetter = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const isJama = () => {
  if (Platform.OS == "web") {
    let url = window.location.href;
    return url.includes("jamawealth");
  } else {
    return false;
  }
}

export const CapitalizeWordsInSentence = (sentence) => {
  if (!sentence || typeof sentence !== "string") return "";
  let result = "";
  sentence.split(" ").forEach((word, index) => {
    const capitalizedWord = capitalizeFirstLetter(word);
    result += (index > 0 ? " " : "") + capitalizedWord;
  });
  return result;
};

export const month_names = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const monthFullNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const numberFormat = (x) => {
  let temp = x.toString();
  if (temp.includes("-")) {
    x = temp.substring(1);
    return x && x.toString().split(".")[0].length > 3
    ? '-' + x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        x.toString().substring(x.toString().split(".")[0].length - 3)
    : temp;
  } else {
    return x && x.toString().split(".")[0].length > 3
    ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        x.toString().substring(x.toString().split(".")[0].length - 3)
    : x.toString();
  }
};

export const dateOrdinal = (d) => {
  d = parseInt(d);
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const months_short_names = (index, full = false) => {
  if (!full) {
    return index <= 12 ? month_names[index - 1] : null;
  } else return index <= 12 ? monthFullNames[index - 1] : null;
};

export const formatDate = (date) => {
  if (!date) return null;
  const date_values = date.split("-");
  return (
    date_values[2] +
    " " +
    month_names[Number(date_values[1]) - 1] +
    " " +
    date_values[0]
  );
};

export const numericDate = (date) => {
  if (!date) return null;
  const date_values = date.split(" ");
  return (
    date_values[2] +
    "-" +
    Number(month_names.indexOf(date_values[1]) + 1) +
    "-" +
    date_values[0]
  );
};

export const reverseString = (string) => {
  if (!string) return string;
  return string.split("").reverse().join("");
};

export const roundTo = (num, no_of_digits) => {
  const multipliers = [10, 100, 1000, 10000];
  if (no_of_digits >= multipliers.length) return num;
  const multiplier = multipliers[no_of_digits - 1];
  return (num * multiplier) / multiplier;
};

export const nth = (d) => {
  d = Number(d);
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const toFixed = (num, to) => {
  if (!num) return 0;
  return Number(num.toFixed(to));
};

export const numberWithCommas = (x) => {
  if (isNaN(x)) return 0;
  if (!x) return 0;
  const sign = x < 0 ? "-" : "";
  x = Math.abs(x);
  return x.toString().split(".")[0].length > 3
    ? sign +
        (x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3))
    : sign + x.toString();
};

export const getProductName = (key) => {
  const productNames = {
    JWL: "JEWEL",
    SRK: "SPARK",
    GEM: "GEM",
    PRL: "PEARL",
    MF: "MF",
  };

  return productNames[key] || key;
};

const { width } = Dimensions.get("window");
export const isMobile = xs(width);
export const isWeb = md(width);
export const isMobileDevice = width <= 480;
export const isIpadOrTablet = width > 480 && width < 768;
export const isLaptopOrIpadPro = width >= 768 && width <= 1024;
export const isDesktop = width > 1024 && width <= 1200;
export const isTV = width > 1200;
export const isAboveIpad = width > 850;

export const getTodayDate = () => {
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1;
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  const current_date = year + "-" + month + "-" + day;
  const todayDate = formatDate(current_date);
  return todayDate;
};

const a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];

const b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

export const valueInWords = (num) => {
  if (!num) return " ";
  if ((num = num.toString()).length > 9) return "";
  const n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        ""
      : "";
  return str;
};

export const getUserDetails = () => {
  const state = Store.getState();
  return state && state.userData && state.userData.userProfileData
    ? state.userData.userProfileData
    : {};
};

export function calculateAgeFromDOB(dateString) {
  const birthDate = new Date(dateString);
  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthDate.getFullYear();
  const birthMonth = birthDate.getMonth();
  const currentMonth = currentDate.getMonth();

  if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const checkIfUserIsFirstTimeUser = () => {
  const {
    is_jewel_profile_completed,
    is_first_time_agreement_signed,
    is_cust_has_paid_bill,
  } = getUserDetails();
  const firstTimeUser = !(
    is_jewel_profile_completed &&
    is_first_time_agreement_signed &&
    is_cust_has_paid_bill
  );
  return firstTimeUser;
};

export const isFirstTimeUser = checkIfUserIsFirstTimeUser();

export const redirectToCompleteMinDetails = async ({
  redirect_screen,
  navigation,
  isOnboarding = true,
  navigateTo,
}) => {
  try {
    if (Platform.OS == "web") {
      let options = {};
      if (navigateTo) {
        options["nvg"] = navigateTo;
      }
      navigation.navigate(redirect_screen, { onb: isOnboarding, ...options });
      return true;
    } else {
      navigation.navigate(redirect_screen);
      return true;
    }
  } catch (error) {
    return false;
  }
};

export function checkIfValidIndianMobileNumber(str) {
  // Regular expression to check if string is a Indian mobile number
  const regexExp = /^[6-9]\d{9}$/gi;

  return regexExp.test(str);
}

export const completeMinDetails = async (
  returnResultsToo = false,
  customer_id = null,
  skipSubscription,
  needToCheck = true,
  ) => {
  const { is_new_user, is_goal } = Store.getState().userData; 
  let customerId = customer_id;
  try {
    const response = await getOnboardingStatus(customerId);
    console.log("Error542", response)
    const { bannerData, customer_id } = getUserDetails();
    let user_id = null;
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    } else {
      user_id = customer_id;
    }
    if (bannerData.name == "Sensage" && user_id > 1 && user_id < 140) {
      response.results.map((item) => {
        item.is_completed = true;
      });
    }

    if (response && response.code != null) {
      const { results, code, mini_onboarding_results } = response;
      if (code == 100) {
        const { redirect_screen, redirect_link } = results;
        return {
          needCompletion: false,
          redirect_screen,
          redirect_link,
          error: false,
        };
      } else {
        // console.log("mini ", mini_onboarding_results);
        if( mini_onboarding_results &&  mini_onboarding_results.length > 0 && !needToCheck) {
          let needCompletion = false,
            redirect_link = null,
            redirect_screen = null;

            if(is_goal) {
              mini_onboarding_results.reverse();
            }
            mini_onboarding_results.every((item) => {
              if (!item.is_completed) {
                needCompletion = true;
                redirect_link = item.redirect_link;
                redirect_screen = item.redirect_screen;
                return false;
              }
              return true;
            });
          let options = {};
          options["results"] = returnResultsToo == true ? results : [];
          return {
            needCompletion,
            redirect_screen,
            redirect_link,
            error: false,
            skipSubscription,
            ...options,
          };
        }
        if(is_new_user) {
          Store.dispatch({
            type: IS_NEW_USER,
            payload: {
              is_new_user: false
            }
          })
          return {
            needCompletion: false,
            redirect_link: "Dashboard",
            redirect_link: "/",
            error: false
          }
        }
        if (results && results.length > 0 && needToCheck) {
        // if (results && results.length > 0) {
          let needCompletion = false,
            redirect_link = null,
            redirect_screen = null;
          results.every((item) => {
            if (
              !item.is_completed &&
              skipSubscription &&
              item.redirect_screen == "Payment"
            ) {
              needCompletion = false;
              return true;
            }
            if (!item.is_completed) {
              needCompletion = true;
              redirect_link = item.redirect_link;
              redirect_screen = item.redirect_screen;
              return false;
            }
            return true;
          });
          let options = {};
          options["results"] = returnResultsToo == true ? results : [];
          return {
            needCompletion,
            redirect_screen,
            redirect_link,
            error: false,
            skipSubscription,
            ...options,
          };
        } else {
          return { needCompletion: true, error: true };
        }
      }
    } else {
      return { needCompletion: true, error: true };
    }
  } catch (error) {
    return { needCompletion: true, error: true };
  }
};

export const completeMFMinDetails = async (
  returnResultsToo = false,
  customer_id = null
) => {
  try {
    const response = await getMFMinDetailsStatus(customer_id);
    if (response && response.code != null) {
      let { results, code } = response;

      if (code == 100) {
        const { redirect_screen, redirect_link } = results;
        return {
          needCompletion: false,
          redirect_screen,
          redirect_link,
          error: false,
        };
      } else {
        results = results.filter(
          (item) => item.redirect_screen !== "MinDetailsEKYC"
        );

        if (results && results.length > 0) {
          let needCompletion = false,
            redirect_link = null,
            redirect_screen = null;
          results.every((item) => {
            if (!item.is_completed) {
              needCompletion = true;
              redirect_link = item.redirect_link;
              redirect_screen = item.redirect_screen;
              return false;
            }
            return true;
          });
          let options = {};
          options["results"] = returnResultsToo == true ? results : [];
          return {
            needCompletion,
            redirect_screen,
            redirect_link,
            error: false,
            ...options,
          };
        } else {
          return { needCompletion: true, error: true };
        }
      }
    } else {
      return { needCompletion: true, error: true };
    }
  } catch (error) {
    return { needCompletion: true, error: true };
  }
};

export const generateGatewayUrl = async (url, hash, userId = null) => {
  try {
    if (url) {
      let userToken;
      if (!userId) {
        const { token } = getUserDetails();
        userToken = token;
      } else {
        const response = await getCustomerDetails(userId);
        userToken = response.token;
      }
      if (userToken) {
        const reversedToken = reverseString(userToken);
        return (
          MAIN_URL +
          "/" +
          url +
          "/" +
          hash +
          reversedToken +
          "/" +
          GATEWAY_ENVIRONMENT +
          "/"
        );
      }
    }
    return MAIN_URL;
  } catch (error) {
    return MAIN_URL;
  }
};

export const getStatusColor = (modal_data, status) => {
  const index =
    modal_data && modal_data.findIndex((item) => item.persona_name == status);
  switch (index) {
    case 0:
      return "red";
    case 1:
      return "orange";
    case 2:
      return "green";
    case 3:
      return "green";
    default:
      return "black";
  }
};

export const inWords = (num) => {
  if (!num) return "";
  if ((num = num.toString()).length > 9) return "";
  let n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]])
      : "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const Currency = (money, isfloat) => {
  if (isfloat) {
    var x = money;
    x = x.toString();
    var afterPoint = "";
    if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
    x = Math.floor(x);
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res1 =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      lastThree +
      afterPoint;
    return res1;
  } else {
    var x = money;
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  }
};

export const removeString = (text, string, optionalString) => {
  text = text.replace(`${string}`, "");
  if (optionalString) {
    text = text.replace(`${optionalString}`, "");
  }
  return text;
};

export const redirectToPendingScreen = async (props) => {
  try {
    const minDetailsResponse = await completeMFMinDetails();
    const { needCompletion, error } = minDetailsResponse;
    if (!error) {
      const { redirect_screen } = minDetailsResponse;
      let options = {};
      if (props.route) {
        const { params } = props.route;
        if (params) {
          options = { ...params };
        }
        if (!needCompletion) {
          props.navigation.navigate("App", {
            screen: "Dashboard",
            params: {
              screen: "Explore",
              params: {
                screen: props.route.params.nvg[0],
                params: {
                  fund: props.route.params.nvg[1],
                },
              },
            },
          });
          return { error: false };
        }
      }
      props.navigation.navigate(redirect_screen, { ...options });
      return { error: false };
    } else {
      return { error: true };
    }
  } catch (error) {
    return { error: true };
  }
};

export const selectOneFile = async (fileType) => {
  try {
    if (!fileType) {
      fileType = "*/*";
    }
    let res;
    if (Platform.OS == "web") {
      res = DocumentPicker.getDocumentAsync({ type: fileType });
    } else {
      res = await DocumentPicker.pickSingle({
        allowMultiSelection: false,
        type: [types.pdf, types.docx, types.xlsx],
      });
    }
    return res;
  } catch (err) {
    throw err;
  }
};

export const mapRouteToScreen = (route) => {
  let data = {};
  switch (route) {
    case "TagGoal":
    case "CreateFinplan":
      data = {
        screen: "CreateFinplan",
        stack: "Goals",
      };
      break;
    case "CreateGoalsIntro":
      data = {
        screen: "CreateGoalsIntro",
        stack: "Goals",
      };
      break;

    case "Jars":
      data = {
        screen: "Jars",
        stack: "Portfolio",
      };
      break;

    case "Invest":
      data = {
        screen: "MFExplore",
        stack: "Explore",
      };
      break;
    default:
      break;
  }
  return data;
};

export const convertCurrencyToNumber = (text) => {
  text = text.replace("₹", "");
  text = text.replace(/,/g, "");
  return Number(text);
};

export const getDateEnd = (date) => {
  if (date) {
    const first_digit = date % 10;
    switch (first_digit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  } else {
    return "";
  }
};

export const encryptToken = (token) => {
  const reversedToken = reverseString(token);
  const first_part = reversedToken.substring(0, 10);
  const second_part = reversedToken.substring(10);
  const modified_token = second_part + first_part;
  return modified_token;
};

export const navigateToTransaction = (navigation, transaction) => {
  const { transactions: transactionsData = {} } = Store.getState();
  let screenToRedirect = null;
  let options = {};
  transaction = Number(transaction);
  if (transactionsData.transactions.hasOwnProperty(transaction)) {
    const {
      type,
      screen = null,
      navigationOptions = {},
    } = transactionsData.transactions[transaction];
    screenToRedirect = screen;
    if (type == "SIP") {
      screenToRedirect = screenToRedirect || "StartSIP";
      options["fund"] = transaction;
    } else if (type == "Lumpsum") {
      options["fund"] = transaction;
    }
    options = { ...options, ...navigationOptions };
  }
  navigation.navigate("App", {
    screen: "Dashboard",
    params: {
      screen: "Explore",
      params: {
        screen: screenToRedirect,
        params: {
          ...options,
        },
      },
    },
  });
};

export const logIntoWebAndRedirect = async (
  redirection_link_after_login = "/app/",
  userIDToFetchData = null
) => {
  try {
    let token;
    if (!userIDToFetchData) {
      const userDetails = getUserDetails();
      token = userDetails.token;
    } else {
      const customerDetails = await getCustomerDetails(userIDToFetchData);
      token = customerDetails.token;
    }
    const encryptedToken = encryptToken(token);
    if (encryptToken) {
      const url =
        MAIN_URL +
        "/app/rnw_to_web_login?token=" +
        encryptedToken +
        "&redirection_url=" +
        redirection_link_after_login;
      return url;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const updateParams = (currentParams = {}, current) => {
  const { screen = null, params } = currentParams;
  if (screen == null) {
    return current;
  } else {
    currentParams["params"] = updateParams(currentParams["params"], current);
    return currentParams;
  }
};

export const getNavigationData = (value) => {
  const [host, path] = value.split("://");
  const [screenData = "", queryParams = []] = path.split("?");
  let values = screenData.split("/");
  values = values.filter((value) => value);
  if (values.length) {
    const [stack, ...remaining] = values;
    let params = {};
    const length = remaining.length;
    for (let i = 0; i < length; i++) {
      const screen = remaining[i];
      let currentParams = {};
      if (i == length - 1 && queryParams.length) {
        let queryValues = queryParams.split("&");
        for (let query of queryValues) {
          const [key, value] = query.split("=");
          currentParams[key] = value;
        }
      }
      const current = {
        screen,
        params: { ...currentParams },
      };

      params = updateParams(params, current);
    }
    return { name: stack, params, error: false };
  }
  return { error: true };
};

export const getScreenConfigForCustomer = () => {
  try {
    const {
      show_goals_fin_plan = {},
      show_fin_education = {},
      show_fin_assessments = {},
      show_explore_mf = {},
      show_explore_stocks = {},
      show_imports = {},
      show_etfs = {},
      show_bonds = {},
    } = Store.getState().userData.screenConfig || {};
    return {
      show_fin_education: show_fin_education.show_screen === "Y",
      show_fin_assessments: show_fin_assessments.show_screen === "Y",
      show_goals_fin_plan: show_goals_fin_plan.show_screen === "Y",
      show_explore_stocks: show_explore_stocks.show_screen === "Y",
      show_explore_mf: show_explore_mf.show_screen === "Y",
      show_imports: show_imports.show_screen === "Y",
      show_etfs: show_etfs.show_screen === "Y",
      show_bonds: show_bonds.show_screen === "Y",
    };
  } catch (error) {
    return {
      show_goals_fin_plan: false,
      show_fin_education: false,
      show_fin_assessments: false,
      show_explore_mf: false,
      show_explore_stocks: false,
      show_imports: false,
      show_etfs: false,
      show_bonds: false,
    };
  }
};

export const holdingPeriodText = (holding_period) => {
  let holdingPeriod = holding_period / 30;
  if (holdingPeriod < 1) {
    holdingPeriod = holding_period.toFixed(0) + " days";
  } else if (holdingPeriod < 12) {
    holdingPeriod = holdingPeriod.toFixed(2) + " months";
  } else {
    holdingPeriod = (holdingPeriod / 12).toFixed(2) + " years";
  }
  return holdingPeriod;
};

export const goalDataToPipeCommaString = (data) => {
  return data.map(item => {
      return `${item.goal_id},${item.lumpsum_invt},${item.sip_invt}`;
  }).join('|');
}

export const pipeCommaStringToGoalData = (pipeCommaString) => {
  const items = pipeCommaString.split('|');
  const result = [];
  for (let i = 0; i < items.length; i++) {
      const values = items[i].split(',');
      if (values.length === 3) {
          result.push({
              "goal_id": parseInt(values[0]),
              "lumpsum_invt": parseFloat(values[1]),
              "sip_invt": parseFloat(values[2])
          });
      }
  }
  return result;
}

export const encryptString = (inputString) => {
  // Convert the input series to base-64 encoded string
  return inputString.split('|').map(series => 
      btoa(series)  // Base-64 encode
  ).join('|');
}

export const decryptString = (inputString) => {
  // Convert the base-64 encoded string back to the original series
  return inputString.split('|').map(encodedSeries => 
      atob(encodedSeries)  // Base-64 decode
  ).join('|');
}

export function formatNumberToAbbreviation(input, isSign) {
  let temp_val = input.replace(/₹/g, "");
  temp_val = temp_val.replace(/,/g, "");
  console.log(temp_val)
    let current_value = Math.abs(temp_val);
    if (current_value > 10000000) {
      current_value = (current_value / 10000000).toFixed(2) + " Cr";
    } else if (current_value > 100000) {
      current_value = (current_value / 100000).toFixed(2) + " L";
    } else if (current_value > 1000) {
      current_value = (current_value / 1000).toFixed(2) + ' K';
    }
    else {
      current_value = numberWithCommas(current_value);
    }
    return isSign ? "₹ "+current_value : current_value;
}

export function encryptPayload(payload) {
  const secret_key = CryptoJS.enc.Hex.parse(SECRET_KEY_HEX);
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(payload), secret_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const encryptedData = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  return encryptedData
}

// export const logSentryError = async (error) => {
//   let payload = {
//     error_msg: error
//   }
//   const response = await logErrorInSentry(payload)
// }