import React from "react";
import {
  View,
  StatusBar,
  SafeAreaView,
  ScrollView,
  Platform,
  Image,
  TurboModuleRegistry,
} from "react-native";
import { Text } from "react-native-paper";
import { getQuizDiagnostics } from "../../../api";
import Divider from "../../../components/Common/divider";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import NotFound from "../../../components/NotFound";
import { ActivityIndicator, Button } from "react-native-paper";
import { encryptToken, getUserDetails, isMobile } from "../../../utils";
import { MAIN_URL } from "../../../api/urls";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;

export default class QuizReport extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let quiz_id = null;
    let image = null;
    if (params) {
      quiz_id = params.quiz_id || null;
      image = params.image || null;
      quiz_id = isNaN(quiz_id) ? null : quiz_id;
    }
    this.state = {
      quiz_id,
      image,
      fetching: true,
      data: [],
    };
  }

  async componentDidMount() {
    this.getQuizDiag();
  }

  getQuizDiag = async () => {
    this.setState({
      fetching: true,
    });
    try {
      const { quiz_id = null } = this.state;
      if (quiz_id) {
        const payload = {
          quiz_id,
        };
        const response = await getQuizDiagnostics(payload);
        this.setState({
          fetching: false,
          data: { persona: response.persona, responses: response.responses },
        });
      }
    } catch (err) {
      this.setState({
        fetching: false,
        data: null,
      });
    }
  };

  openCallToActions = async (payload) => {
    const { token } = getUserDetails();
    const encryptedToken = encryptToken(token);
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    const url =
      MAIN_URL +
      "/app/rnw_to_web_login?token=" +
      encryptedToken +
      "&is_rnw=1&redirection_url=" +
      payload;

    if (Platform.OS === "web") {
      let popup_window = window.open(
        url,
        hostname,
        "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=yes,width=1200,height=700"
      );
      try {
        popup_window.focus();
      } catch (e) {
        alert(
          "Pop-up Blocker is enabled! Please add this site to your exception list."
        );
      }
    } else {
      this.props.navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Portfolio",
          params: {
            screen: "ShowWebView",
            params: {
              url,
              showInWeb: isMobile ? false : true,
            },
          },
        },
      });
    }
  };

  _render_qna() {
    const responses = this.state.data.responses;
    var items = [];
    var i = 0;
    const emptyPara1 = "<br><br>";

    for (i = 0; i < responses.length; i++) {
      const item = responses[i];
      const answer = item.detailed_reply_to_customer;
      answer = answer.replace(new RegExp(emptyPara1, "g"), "<br>");
      const sentences = answer.split("<br> ");

      items.push(
        <View
          style={{
            backgroundColor: "#F7F5FC",
            borderRadius: 10,
            marginTop: 16,
            marginBottom: 16,
            padding: 16,
            width: "97.2%",
            marginLeft: "1.4%",
          }}
        >
          <Text style={{ fontSize: 16, color: "#000", fontFamily: "Roboto" }}>
            {i + 1}
            {".  "}
            {item.question}
          </Text>
          <Divider width={1} color="#f2f2f2" style={{ marginVertical: 10 }} />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingBottom: 8,
              paddingLeft: 10,
            }}
          >
            <View
              style={{
                width: 4,
                height: 4,
                borderRadius: 2,
                backgroundColor: "#410DAA",
                marginTop: 5,
              }}
            ></View>
            <Text
              style={{
                fontSize: 14,
                paddingLeft: 10,
                fontWeight: "bold",
                color: "#410DAA",
              }}
            >
              {item.answer}
            </Text>
          </View>
          <View style={{ paddingLeft: 15, fontSize: 8 }}>
            {sentences.map((sentence, index) => {
              return (
                <Text key={index} style={{ lineHeight: 30 }}>
                  {sentence}
                </Text>
              );
            })}
          </View>
          {/* {item.call_to_action_text &&
            item.call_to_action_text[0].length > 0 &&
            item.call_to_action_text[0] != "None" && (
              <Button
                uppercase={false}
                mode="contained"
                labelStyle={{
                  color: "#410DAA",
                  fontWeight: "600",
                }}
                onPress={() => {
                  this.openCallToActions(item.call_to_action_link[0]);
                }}
                style={{
                  borderColor: "#410DAA",
                  borderWidth: 1,
                  backgroundColor: "white",
                  borderRadius: 6,
                  height: isMobile ? "auto" : 37,
                  marginTop: 10,
                  width: isMobile ? "auto" : 400,
                }}
              >
                {item.call_to_action_text[0].split("/")[0]}
              </Button>
            )} */}
        </View>
      );
    }
    return items;
  }

  _render_score_percentage = (percentage) => {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          marginTop: isMobile ? -20 : "auto",
        }}
      >
        <View>
          {this.state.image && (
            <Image
              source={{ uri: this.state.image }}
              style={{
                width: 60,
                height: 60,
                alignSelf: "flex-start",
                borderRadius: 4,
              }}
              resizeMode={"contain"}
            />
          )}
        </View>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          {Platform.OS == "web" ? (
            <Donut
              options={{
                dataLabels: {
                  enabled: false,
                },
                legend: {
                  show: false,
                },
                tooltip: { enabled: false },
                colors: ["#876CDD", "#BAC7F2"],
                plotOptions: {
                  pie: {
                    donut: {
                      size: "60%",
                    },
                  },
                },
                stroke: {
                  width: 1,
                  colors: ["#876CDD", "#BAC7F2"],
                },
              }}
              series={[Math.floor(percentage), 100 - Math.floor(percentage)]}
              sliceColor={["#876CDD", "#BAC7F2"]}
              type="donut"
              width="160"
            />
          ) : (
            <Donut
              widthAndHeight={100}
              series={[Math.floor(percentage), 100 - Math.floor(percentage)]}
              sliceColor={["#876CDD", "#BAC7F2"]}
              doughnut={true}
              coverRadius={0.6}
              coverFill={"#FFF"}
            />
          )}
          <Text
            style={{
              fontWeight: "600",
              color: "#121212",
              fontSize: 13,
            }}
          >
            Score {Math.floor(percentage)} / 100
          </Text>
        </View>
      </View>
    );
  };

  _render_screen() {
    return (
      <View
        style={{
          marginLeft: 16,
          marginRight: 16,
        }}
      >
        <Text
          style={{
            fontSize: 17,
            fontWeight: "bold",
            color: "#121212",
            marginVertical: 10,
            alignSelf: "center",
          }}
        ></Text>
        {this._render_score_percentage(this.state.data.persona.quiz_score)}
        {this._render_qna()}
      </View>
    );
  }

  _header() {
    return (
      <NavigationHeader
        navigation={this.props.navigation}
        navigationHeader={["Quiz Diagnostic Report"]}
        onPressBack={() => {
          this.props.navigation.goBack();
        }}
      />
    );
  }

  quiz_taken_msg = () => {
    const response = this.state.data;
    if (response && Object.keys(response).length != 0) {
      const { final_list } = response;
      if (final_list) {
        let taken = false;
        final_list.forEach((item) => {
          taken = taken || item.is_quiz_done;
        });
        return taken;
      }
    } else {
      return null;
    }
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {this._header()}
        <ScrollView
          showsVerticalScrollIndicator={true}
          style={{ backgroundColor: "#fff", marginBottom: isMobile ? 80 : 40 }}
        >
          {this.state.fetching ? (
            <ActivityIndicator size="large" color="#410DAA" />
          ) : this.state.data ? (
            this._render_screen()
          ) : (
            <NotFound />
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}
