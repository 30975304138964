import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Image,
  ActivityIndicator,
  Platform,
  Linking,
} from "react-native";
import { md } from "../../Responsive";
import amplitude from "../../utils/amplitude";
import NavigationHeader from "../Common/NavigationHeader";
import { Button } from "react-native-paper";
import { months_short_names } from "../../utils";
import { getCustomerReport, getDashboard } from "../../api";
import { getMySIPs } from "../../api/account";
import { EquityPlans } from "../../components/InvestmentOverview/NewDesign/components/EquityPlans";
let Image_url = "https://jamaappprod.s3.amazonaws.com/media/";
import { getProductName, isMobile } from "../../utils";
const { width, height } = Dimensions.get("window");
const isWeb = md(width);

import CountTrackerHome from "../AdviceCountTracker/countTrackerHome";
import ProgressBar from "../../utils/progressBar";
import Store from "../../store";

const EmptySIPS = ({ navigation, is_preferred_circle }) => {
  const handlePress = () => {
    if (is_preferred_circle) {
      navigation.navigate("App", {
        screen: "ViewMode",
        params: {
          screen: "InvestForGoals",
          params: {},
        },
      });
    } else {
      navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Explore",
          params: {
            screen: "Explore",
            params: {
              index: 1,
            },
          },
        },
      });
    }
  };

  return (
    <View
      style={{
        width: "100%",
        height: height - 100,
        paddingHorizontal: 16,
        justifyContent: "center",
      }}
    >
      <Text style={{ alignSelf: "center", textAlign: "center" }}>
        No SIPs to show! Why don't you try investing! We have SmartDeposit Funds
        - best for parking idle cash! Or choose TaxSavers, Or WealthBuilders!
      </Text>
      <Button
        uppercase={false}
        mode={"contained"}
        onPress={handlePress}
        color={"#410DAA"}
        style={{
          backgroundColor: "#410DAA",
          marginTop: 60,
          width: 200,
          alignSelf: "center",
          marginHorizontal: 16,
        }}
      >
        <Text style={{ fontSize: 15, color: "white", fontWeight: "bold" }}>
          INVEST NOW
        </Text>
      </Button>
    </View>
  );
};

const ToggleActiveCompletePlans = ({
  tabs,
  activeIndex,
  onChangeValue,
  style = {},
}) => {
  return (
    <View
      style={{
        flexDirection: "row",
        borderBottomColor: "#E5EAEE",
        borderBottomWidth: 1,
        marginBottom: 10,
        paddingLeft: 32,
        ...style,
      }}
    >
      <ScrollView horizontal={true}>
        {tabs.map((tab, index) => {
          return (
            <TouchableOpacity
              onPress={() => {
                onChangeValue && onChangeValue(index);
              }}
            >
              <Text
                style={{
                  color: index == activeIndex ? "#410DAA" : "#888C9F",
                  fontSize: 14,
                  paddingHorizontal: 30,
                  paddingVertical: 12,
                  borderBottomWidth: 3,
                  fontWeight: index == activeIndex ? "700" : "400",
                  borderBottomColor:
                    activeIndex == index ? "#410DAA" : "transparent",
                }}
              >
                {tab}
              </Text>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default class PlansHome extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let userId = null;
    let activeTab = null;
    let activeTabIndex = 0;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      activeTab = params.activeTab || null;
      activeTabIndex = params.activeTabIndex || 0;
    }

    let is_preferred_circle = false;
    let is_club = false;
    let is_elite = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    const { user_database } = Store.getState().userData.userProfileData;
    if (hostname === "mobile") {
      if (
        user_database != "" &&
        user_database != undefined &&
        user_database != null
      ) {
        hostname = user_database;
      } else {
        hostname = "dapp";
      }
    }
    // console.log("is_elite is ", user_database);
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    if (hostname.includes("club")) {
      is_club = true;
    }
    if (hostname.includes("app") || hostname.includes("dapp")) {
      is_elite = true;
    }
    this.state = {
      userId,
      data: null,
      activeTabIndex,
      activeTab,
      isLoading: true,
      filters: [],
      sipdata: [],
      tabsArray: [],
      activeTab,
      is_preferred_circle,
      is_club,
      is_elite,
      swpData: [],
      stpData: [],
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("PlansHome", { path: global.location.href });
      } else {
        amplitude.logEvent("PlansHome", { path: this.props.route.name });
      }
      const { params } = this.props.route;
      let activeTabIndex = 0;
      if (params) {
        activeTabIndex = params.activeTabIndex || 0;
      }
      const { userData } = Store.getState();
      let tabsArray = [];
      if (this.state.is_elite) {
        tabsArray.push("Stock Plan & SIPs");
      } else if (this.state.is_preferred_circle || this.state.is_club) {
        tabsArray.push("Advisory Plans");
      }
      tabsArray.push("MF SIPs");
      this.setState({
        tabsArray: tabsArray,
        activeTabIndex,
      });

      tabsArray.push("MF SWPs");
      tabsArray.push("MF STPs");
      // tabsArray.push("PMS SIPs");
      // if (userData.userProfileData.is_mfcustomer) {
      //   this.setState({
      //     tabsArray: tabsArray,
      //     activeTabIndex,
      //   });
      // } else {
      //   this.setState({
      //     tabsArray: tabsArray,
      //     activeTabIndex,
      //   });
      // }
      let { filters } = this.state;
      if (params && params.filter) {
        const value = params.filter;
        const title = getProductName(value);
        filters = [...filters, { filterBy: "product", value, title }];
      }

      const { userId } = this.state;

      let landingPageResponse = [];
      let getMySIPsResponse = [];
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        landingPageResponse = await getDashboard(
          Store.getState().dashboard.viewModeUserId.user_id
        );
        getMySIPsResponse = await getMySIPs(
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        if (
          Store.getState().plans &&
          Store.getState().plans.landingPageResponse &&
          Store.getState().plans.getMySIPsResponse &&
          Store.getState().plans.landingPageResponse.length > 0 &&
          Store.getState().plans.getMySIPsResponse.length > 0
        ) {
          landingPageResponse = Store.getState().plans.landingPageResponse;
          getMySIPsResponse = [
            ...Object.values(Store.getState().plans.getMySIPsResponse),
          ];
        } else {
          landingPageResponse = await getDashboard(userId);
          getMySIPsResponse = await getMySIPs();
          Store.dispatch({
            type: "GET_PLANS",
            payload: {
              landingPageResponse,
              getMySIPsResponse,
            },
          });
        }
      }
      let payload;
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        payload = {
          previous_key: "client_swp_stp_orders",
          customer_id: Store.getState().dashboard.viewModeUserId.user_id,
        };
      } else {
        payload = {
          previous_key: "client_swp_stp_orders",
          customer_id: Store.getState().userData.userProfileData.customer_id,
        };
      }
      const respswpandstp = await getCustomerReport(payload);
      let swpData = [];
      let stpData = [];
      if (respswpandstp && respswpandstp.code == 100) {
        let resp = respswpandstp.result;
        resp.map((item) => {
          if (item.order_type == "SWP") {
            swpData.push(item);
          } else {
            stpData.push(item);
          }
        });
      }
      // console.log("Simple ", swpData);
      this.setState({ swpData, stpData });
      if (
        landingPageResponse &&
        landingPageResponse.code &&
        landingPageResponse.code == 100 &&
        getMySIPsResponse &&
        Array.isArray(getMySIPsResponse)
      ) {
        const sipData = getMySIPsResponse.filter(
          (item) => item.bse_trans_code == "NEW" && item.bse_sip_reg_id != "0"
        );
        this.setState({ sipData });

        const { subscribed_products, equity_investment_plans } =
          landingPageResponse.result;
        this.setState({
          equity_investment_plans,
          subscribed_products,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Something went wrong!",
        });
      }
    } catch (error) {
      console.log("error " + error);
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  renderRow = (amc) => {
    let urlamc = "";
    urlamc = Image_url + amc.item.amc_logo;

    const sip_per_month = amc.item.amount;
    let next_due = amc.item.sip_next_due_date.split("-");
    const month = months_short_names(parseInt(next_due[1]));

    next_due = next_due[0] + "-" + month + "-" + next_due[2];

    const frequency =
      amc.item.bse_sip_frequency_type && amc.item.bse_sip_frequency_type != ""
        ? amc.item.bse_sip_frequency_type.split("ly")[0].toString()
        : "month";

    return (
      <TouchableOpacity
        onPress={() => {
          if (isMobile) {
            this.props.navigation.replace("App", {
              screen: "Portfolio",
              params: {
                screen: "SIPDetails",
                params: {
                  data: amc.item,
                  amcfundscheme: amc.item.amcfundscheme,
                  index: amc.index,
                  amcfund_id: amc.item.amcfundscheme__AMCFund__amcfund_id,
                  bse_sip_reg_id: amc.item.bse_sip_reg_id,
                  activeTab: this.state.activeTab,
                },
              },
            });
          } else {
            this.props.navigation.replace("App", {
              screen: "Portfolio",
              params: {
                screen: "SIPDetails",
                params: {
                  data: amc.item,
                  amcfundscheme: amc.item.amcfundscheme,
                  index: amc.index,
                  amcfund_id: amc.item.amcfundscheme__AMCFund__amcfund_id,
                  bse_sip_reg_id: amc.item.bse_sip_reg_id,
                  activeTab: this.state.activeTab,
                },
              },
            });
          }
        }}
        style={{
          borderRadius: 10,
          borderColor: "#410DAA33",
          borderWidth: 1,
          marginVertical: 7.5,
          marginHorizontal: 15,
        }}
      >
        <View
          style={{
            paddingVertical: 12,
            paddingHorizontal: 10,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View
            style={{
              flex: 0.1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: 30,
                height: 30,
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#410DAA1A",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={{ uri: urlamc }}
                resizeMode="contain"
                style={{ height: 20, width: 20 }}
              />
            </View>
          </View>
          <View style={{ flex: 0.9 }}>
            <Text style={{ fontSize: 12, color: "#121212", paddingLeft: 10 }}>
              {amc.item.scheme_name}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: 10,
            paddingBottom: 7.5,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: "#121212",
              paddingRight: 5,
              fontWeight: "bold",
            }}
          >
            {sip_per_month}
          </Text>
          <Text style={{ fontSize: 10, color: "#121212", fontWeight: "bold" }}>
            / {frequency}
          </Text>
          <Text style={{ fontSize: 10, color: "#465964", marginLeft: 10 }}>
            Next Due:{next_due}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    const { activeTabIndex, error, errorText, isLoading, activeTab } =
      this.state;

    if (error || isLoading) {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <View style={{ flex: 1, maxWidth: 1500 }}>
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={["Plans"]}
              onPressBack={() => {
                this.props.navigation.replace("App", {
                  screen: "Portfolio",
                  params: {
                    screen: "InvestmentOverview",
                    params: {
                      activeTab: this.state.activeTab,
                    },
                  },
                });
              }}
            />
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoading && <ProgressBar msg={"Loading Plans..."} />}
              {error && (
                <Text style={{ fontSize: 16, color: "#121212" }}>
                  {errorText || "Something went wrong!"}
                </Text>
              )}
            </View>
          </View>
        </View>
      );
    }
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View style={[{ backgroundColor: "#fff" }, styles.container]}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Plans"]}
            onPressBack={() => {
              this.props.navigation.replace("App", {
                screen: "Portfolio",
                params: {
                  screen: "InvestmentOverview",
                  params: {
                    activeTab: this.state.activeTab,
                  },
                },
              });
            }}
            style={{ backgroundColor: "#F6F6F6" }}
          />
          {/* {console.log("data to handle ", this.state.tabsArray)} */}
          <ToggleActiveCompletePlans
            tabs={this.state.tabsArray}
            activeIndex={activeTabIndex}
            onChangeValue={(selectedIndex) => {
              if (selectedIndex != activeTabIndex) {
                this.setState({ activeTabIndex: selectedIndex });
              }
            }}
          />
          {
            <ScrollView
              style={[styles.mainContainer]}
              showsVerticalScrollIndicator={false}
            >
              {this.state.activeTabIndex == 0 ? (
                <View
                  style={{
                    marginTop: -10,
                    width: isMobile ? "90%" : "100%",
                    marginBottom: 50,
                    marginLeft: isMobile ? "5%" : "0%",
                    marginRight: isMobile ? "5%" : "0%",
                  }}
                >
                  {this.state.is_elite ? (
                    <View>
                      {Store.getState().dashboard &&
                        Store.getState().dashboard.viewModeUserId &&
                        Store.getState().dashboard.viewModeUserId.user_id && (
                          <View
                            style={{
                              width: "95%",
                              alignItems: "flex-end",
                              marginBottom: 20,
                              marginTop: isMobile ? 20 : 0,
                            }}
                          >
                            <Text
                              style={{
                                color: "#410DAA",
                                fontWeight: "bold",
                                textDecorationLine: "underline",
                              }}
                              onPress={() => {
                                let userId =
                                  Store.getState().dashboard &&
                                  Store.getState().dashboard.viewModeUserId &&
                                  Store.getState().dashboard.viewModeUserId
                                    .user_id;

                                if (Platform.OS === "web") {
                                  global.open(
                                    `https://maxiom.co.in/app/investment_plan_detail/${userId}`,
                                    "_blank"
                                  );
                                } else {
                                  Linking.openURL(
                                    `https://maxiom.co.in/app/investment_plan_detail/${userId}`
                                  );
                                }
                              }}
                            >
                              Plan wise Billing Summary
                            </Text>
                          </View>
                        )}
                      <EquityPlans
                        style={{
                          flex: 1,
                        }}
                        navigation={this.props.navigation}
                        data={this.state.equity_investment_plans}
                        activePlans={this.state.subscribed_products}
                        userId={this.state.userId}
                        isNew={true}
                        showInVertical={isMobile}
                      />
                    </View>
                  ) : (
                    (this.state.is_preferred_circle || this.state.is_club) && (
                      <CountTrackerHome {...this.props} />
                    )
                  )}
                </View>
              ) : null}

              {this.state.activeTabIndex == 1 ? (
                <View
                  style={{
                    marginTop: -10,
                    width: isMobile ? "100%" : "60%",
                    marginLeft: isMobile ? "0%" : "20%",
                    marginRight: isMobile ? "0%" : "20%",
                    marginBottom: 86,
                  }}
                >
                  <FlatList
                    data={this.state.sipData}
                    ListEmptyComponent={
                      <EmptySIPS
                        navigation={this.props.navigation}
                        is_preferred_circle={this.state.is_preferred_circle}
                      />
                    }
                    renderItem={this.renderRow}
                    keyExtractor={(item, index) => index.toString()}
                    style={{
                      marginTop: 6,
                    }}
                  />
                </View>
              ) : null}

              {this.state.activeTabIndex == 2 ? (
                <View
                  style={{
                    marginTop: -10,
                    width: isMobile ? "90%" : "100%",
                    marginLeft: isMobile ? "5%" : "0%",
                    marginRight: isMobile ? "5%" : "0%",
                    marginTop:
                      isMobile && this.state.swpData.length <= 0 ? 80 : 20,
                  }}
                >
                  <ScrollView horizontal={isMobile}>
                    <View style={{ width: "100%" }}>
                      <View>
                        {this.state.swpData.length > 0 ? (
                          <View
                            style={{
                              width: isMobile ? "100%" : "95%",
                              padding: 10,
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <View
                              style={{
                                width: "40%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{ color: "#666666", fontWeight: "700" }}
                              >
                                Mutual Fund Name
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "15%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{ color: "#666666", fontWeight: "700" }}
                              >
                                Amount
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "25%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{ color: "#666666", fontWeight: "700" }}
                              >
                                No Of Installments
                              </Text>
                            </View>
                            <View
                              style={{
                                width: "20%",
                                justifyContent: isMobile
                                  ? "flex-end"
                                  : "center",
                                alignItems: isMobile ? "flex-end" : "center",
                              }}
                            >
                              <Text
                                style={{ color: "#666666", fontWeight: "700" }}
                              >
                                BSE Folio No.
                              </Text>
                            </View>
                          </View>
                        ) : (
                          <View
                            style={{
                              flex: 1,
                              width: isMobile ? "100%" : "95%",
                              padding: 50,
                              justifyContent: "center",
                              alignItems: "center",
                              borderWidth: 2,
                              borderColor: "#410DAA1A",
                              borderRadius: 5,
                            }}
                          >
                            <Text
                              style={{
                                color: "#6666666",
                                fontWeight: "700",
                                fontSize: 22,
                              }}
                            >
                              Systematic Withdrawal Plans
                            </Text>
                            <Text
                              style={{
                                color: "#6666666",
                                fontWeight: "500",
                                fontSize: 16,
                                marginTop: 20,
                              }}
                            >
                              Get monthly income. Pay lesser tax.
                            </Text>
                            <View style={{ marginTop: 80 }}>
                              <Text
                                style={{
                                  color: "#6666666",
                                  fontWeight: "300",
                                  fontSize: 12,
                                }}
                              >
                                You don’t seem to have any running SWPs
                              </Text>
                              <TouchableOpacity
                                style={{
                                  backgroundColor: "#410DAA",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  paddingHorizontal: 20,
                                  paddingVertical: 10,
                                  marginTop: 20,
                                  borderRadius: 5,
                                }}
                                onPress={() => {
                                  this.props.navigation.navigate("App", {
                                    screen: "Portfolio",
                                    params: {
                                      screen: "InvestmentOverview",
                                      params: {
                                        activeTab: "Mutual Funds",
                                      },
                                    },
                                  });
                                }}
                              >
                                <Text
                                  style={{ color: "white", fontWeight: "500" }}
                                >
                                  Start SWP
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        )}
                        {this.state.swpData.map((item, index) => {
                          return (
                            <View key={index} style={{ marginVertical: 10 }}>
                              <View
                                style={{
                                  width: isMobile ? "100%" : "95%",
                                  borderBottomWidth: 2,
                                  padding: 10,
                                  borderRadius: 5,
                                  borderColor: "#410DAA1A",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <View
                                  style={{
                                    width: "40%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text>{item.scheme_name}</Text>
                                </View>
                                <View
                                  style={{
                                    width: "15%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text>{item.amount}</Text>
                                </View>
                                <View
                                  style={{
                                    width: "25%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text>{item.no_of_installments}</Text>
                                </View>
                                <View
                                  style={{
                                    width: "20%",
                                    justifyContent: isMobile
                                      ? "flex-end"
                                      : "center",
                                    alignItems: isMobile
                                      ? "flex-end"
                                      : "center",
                                  }}
                                >
                                  <Text>{item.bse_folio_no}</Text>
                                </View>
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  </ScrollView>
                </View>
              ) : null}

              {this.state.activeTabIndex == 3 ? (
                <View
                  style={{
                    marginTop: -10,
                    width: isMobile ? "90%" : "100%",
                    marginLeft: isMobile ? "5%" : "0%",
                    marginRight: isMobile ? "5%" : "0%",
                    marginTop:
                      isMobile && this.state.swpData.length <= 0 ? 80 : 20,
                  }}
                >
                  {this.state.stpData.length > 0 ? (
                    <View
                      style={{
                        width: isMobile ? "100%" : "95%",
                        padding: 10,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <View
                        style={{
                          width: "40%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ color: "#666666", fontWeight: "700" }}>
                          Mutual Fund Name
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "15%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ color: "#666666", fontWeight: "700" }}>
                          Amount
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "25%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ color: "#666666", fontWeight: "700" }}>
                          No Of Installments
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          justifyContent: isMobile ? "flex-end" : "center",
                          alignItems: isMobile ? "flex-end" : "center",
                        }}
                      >
                        <Text style={{ color: "#666666", fontWeight: "700" }}>
                          bSE Folio No.
                        </Text>
                      </View>
                    </View>
                  ) : (
                    <View
                      style={{
                        flex: 1,
                        width: isMobile ? "100%" : "95%",
                        padding: 50,
                        justifyContent: "center",
                        alignItems: "center",
                        borderWidth: 2,
                        borderColor: "#410DAA1A",
                        borderRadius: 5,
                      }}
                    >
                      <Text
                        style={{
                          color: "#6666666",
                          fontWeight: "700",
                          fontSize: 22,
                        }}
                      >
                        Systematic Transfer Plans
                      </Text>
                      <Text
                        style={{
                          color: "#6666666",
                          fontWeight: "500",
                          fontSize: 16,
                          marginTop: 20,
                        }}
                      >
                        Keep your investments busy. Reduce investment risk over
                        time ..
                      </Text>
                      <View style={{ marginTop: 80 }}>
                        <Text
                          style={{
                            color: "#6666666",
                            fontWeight: "300",
                            fontSize: 12,
                          }}
                        >
                          You don’t seem to have any running STPs
                        </Text>
                        <TouchableOpacity
                          style={{
                            backgroundColor: "#410DAA",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingHorizontal: 20,
                            paddingVertical: 10,
                            marginTop: 20,
                            borderRadius: 5,
                          }}
                          onPress={() => {
                            this.props.navigation.navigate("App", {
                              screen: "Portfolio",
                              params: {
                                screen: "InvestmentOverview",
                                params: {
                                  activeTab: "Mutual Funds",
                                },
                              },
                            });
                          }}
                        >
                          <Text style={{ color: "white", fontWeight: "500" }}>
                            Start STP
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                  {this.state.stpData.map((item, index) => {
                    return (
                      <View key={index} style={{ marginVertical: 10 }}>
                        <View
                          style={{
                            width: isMobile ? "100%" : "95%",
                            borderBottomWidth: 2,
                            padding: 10,
                            borderRadius: 5,
                            borderColor: "#410DAA1A",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <View
                            style={{
                              width: "40%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text>{item.scheme_name}</Text>
                          </View>
                          <View
                            style={{
                              width: "15%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text>{item.amount}</Text>
                          </View>
                          <View
                            style={{
                              width: "25%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text>{item.no_of_installments}</Text>
                          </View>
                          <View
                            style={{
                              width: "20%",
                              justifyContent: isMobile ? "flex-end" : "center",
                              alignItems: isMobile ? "flex-end" : "center",
                            }}
                          >
                            <Text>{item.bse_folio_no}</Text>
                          </View>
                        </View>
                      </View>
                    );
                  })}
                </View>
              ) : null}
            </ScrollView>
          }
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: isWeb ? 32 : 0,
    paddingRight: isWeb ? 32 : 0,
    paddingTop: isWeb ? 30 : 0,
    maxWidth: 1400,
    marginBottom: isMobile ? 0 : 20,
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
});
