import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  Image,
  Switch,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from "react-native";
import amplitude from "../../../utils/amplitude";
import Slider from "@react-native-community/slider";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { md } from "../../../Responsive";
import NavigationHeader from "../../Common/NavigationHeader";
import {
  getAMCFundSchemestaFn,
  getCipsAboutToExpire,
  getFundDetails,
} from "../../../api";
const { width } = Dimensions.get("window");
import { StackActions } from "@react-navigation/native";
const isWeb = md(width);
import { isMobile, completeMinDetails, getUserDetails, isJama } from "../../../utils";
import ComplianceModal from "../../InvestmentOverview/ComplianceModal";
import { connect } from "react-redux";

import moment from "moment";
import { saveCustomerProfileData } from "../../../api/account";
import { ShowToast } from "../../Common/Toast";
import NoPlanModal from "../../InvestmentOverview/noPlanModal";
import Store from "../../../store";

const FundField = ({ style, name, value, tooltip }) => {
  return (
    <View style={{ ...style }}>
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: "#6C7293",
            fontWeight: "600",
          }}
        >
          {name}
        </Text>
        {tooltip && (
          <ANTIcon
            name="infocirlce"
            color={"#a8a8a8"}
            size={12}
            style={{
              paddingLeft: 10,
            }}
          />
        )}
      </View>
      <Text
        style={{
          fontSize: 12,
          color: "#464E5F",
          fontWeight: "700",
          paddingTop: 5,
        }}
      >
        {value}
      </Text>
    </View>
  );
};

class FundDescription extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;

    let userId = null;
    let fundPK = null;
    let from = null;
    let status = null;
    let fundType = null;
    let add_invt = null;
    let fund_amc_id = null;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      fundPK = params.fund;
      from = params.from;
      status = params.STATUS || null;
      fundType = params.fundType || null;
      add_invt = params.add_invt || null;
      fund_amc_id = params.fund_amc_id || null;
    }

    let is_club = false;
    let { user_database } = Store.getState().userData.userProfileData;
    let hostname =
      Platform.OS == "web" ? global.location.hostname : user_database;
    if (hostname.includes("club")) {
      is_club = true;
    }

    this.state = {
      userId,
      fundPK,
      calculatorChosen: 0,
      from,
      status,
      fundType,
      add_invt,
      fund_amc_id,
      fund_not_avaiable: false,
      is_club,
      activeTabIndex: 0,
      selected_fund: null,
      isLoading: true,
      filters: [],
      mfFundTxnDetails: [],
      mfAMCFundsSchemes: [],
      isSwitchOn: false,
      selectedAction: "Start_SIP",
      value: 500,
      year: 5,
      sipvalue: 500,
      sipyear: 5,
      showToast: null,
      showComplianceAlert: false,
      selectedOption: "",
      gettingMinDetails: true,
      mfMinDetailsResponse: {},
    };
  }

  async componentDidMount() {
    try {
      // if (this.state.status) {
      //   this.setState({ showToast: "true" });
      //   const { customer_id, customerprofile_id } = getUserDetails();
      //   const body = {
      //     customer: customer_id,
      //     bse_nomination_authentication_date: moment()
      //       .utcOffset("+05:30")
      //       .format(),
      //   };
      //   await saveCustomerProfileData(customerprofile_id, body);
      // }
      if (Platform.OS == "web") {
        amplitude.logEvent("investflow", { path: global.location.href });
      } else {
        amplitude.logEvent("investflow", {
          path: this.props.navigation.getState().routeName,
        });
      }
      const { fundPK } = this.state;
      const customer_id = this.state.userId || null;
      const mfMinDetailsResponse = await completeMinDetails(true, customer_id);
      if (mfMinDetailsResponse && mfMinDetailsResponse.needCompletion) {
        this.setState({
          mfMinDetailsResponse,
          gettingMinDetails: false,
        });
      } else {
        this.setState({
          mfMinDetailsResponse,
          gettingMinDetails: false,
        });
      }

      const fundTxnResponse = await getFundDetails({
        amcfund_id: fundPK,
      });
      const amcFundResponse = await getAMCFundSchemestaFn(fundPK);

      let plans_about_expire_resp = null;
      // console.log(Store.getState().dashboard);
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        plans_about_expire_resp = await getCipsAboutToExpire(
          312
          // Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        plans_about_expire_resp = await getCipsAboutToExpire();
      }

      let show_choose_your_plan = false;
      let result = [];
      if (plans_about_expire_resp && plans_about_expire_resp.code == 100) {
        result = plans_about_expire_resp.result;
        if (result.length > 0) {
          result.forEach((item) => {
            if (item["renewal_type"] == "no_plan_for_customer") {
              show_choose_your_plan = true;
            }
          });
        }
      }

      if (
        fundTxnResponse &&
        fundTxnResponse.results &&
        amcFundResponse &&
        amcFundResponse.results
      ) {
        this.setState({ mfFundTxnDetails: fundTxnResponse.results });
        this.setState({ mfAMCFundsSchemes: amcFundResponse.results });

        // mfAMCFundsSchemes
        // amcFundResponse.results.map((item) => {
        //   if (item.amcfundscheme_id == this.state.fund_amc_id) {
        //     this.setState({ selected_fund: item });
        //   }
        // });

        // if (this.state.selected_fund == null) {
        //   this.setState({
        //     isLoading: false,
        //     fund_not_avaiable: true,
        //   });
        //   return;
        // }

        let selectedFund = null;
        amcFundResponse.results.forEach((item) => {
          if (item.amcfundscheme_id == this.state.fund_amc_id) {
            selectedFund = item;
          }
        });

        if (selectedFund) {
          this.setState({ selected_fund: selectedFund });
        } else {
          this.setState({
            isLoading: false,
            fund_not_avaiable: true,
          });
        }

        const { is_purchase_allowed, is_sip_allowed } =
          this.state.mfFundTxnDetails;

        let calculatorChosen = 0;
        if (is_purchase_allowed && is_sip_allowed) {
          calculatorChosen = 0;
        } else if (is_purchase_allowed && !is_sip_allowed) {
          calculatorChosen = 1;
        } else {
          calculatorChosen = 0;
        }

        this.setState({
          is_purchase_allowed,
          is_sip_allowed,
          calculatorChosen,
          show_choose_your_plan: show_choose_your_plan,
        });

        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Something went wrong!",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  setModalMethod = (method) => {
    this.openOrCloseModal = method;
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  getNumIndianFormat = (num) => {
    if (!num) {
      return "-";
    }
    var str = num.toString();
    var final = "";
    if (str.length > 3) {
      final = "," + str.substring(str.length - 3, str.length);
      str = str.substring(0, str.length - 3);
      while (str.length > 2) {
        final = "," + str.substring(str.length - 2, str.length) + final;
        str = str.substring(0, str.length - 2);
      }
      final = str + final;
    } else {
      return num.toString();
    }
    return final;
  };

  convert_crore_to_lac(num) {
    var crore = parseInt(1000000);
    var lakh = parseInt(100000);
    if (num > crore) {
      var value = num / lakh;
      value = parseFloat(value).toFixed(2) + " Lac";
      return "\u20B9 " + value;
    } else {
      var final_val = this.formatNumber(num);
      return "\u20B9 " + final_val;
    }
  }

  formatNumber(num) {
    var str = num.toString();
    var final = "";
    if (str.length > 3) {
      final = "," + str.substring(str.length - 3, str.length);
      str = str.substring(0, str.length - 3);
      while (str.length > 2) {
        final = "," + str.substring(str.length - 2, str.length) + final;
        str = str.substring(0, str.length - 2);
      }
      final = str + final;
    } else {
      return num.toString();
    }
    return final;
  }

  calculate_sip_future_value(
    installment_amount,
    number_of_installments,
    yearly_returns
  ) {

    var returns_assumed = yearly_returns / 12;
    var total = 0;
    var installment_amount = parseInt(installment_amount) || 0;
    var number_of_installments = parseInt(number_of_installments) || 0;
    for (var i = 0; i < number_of_installments; i++) {
      total = total + installment_amount;
      total = total * (1 + returns_assumed);
    }
    return total;
  }

  calculate_future_value(principal, years) {
    const amount = principal * Math.pow((1.12), years);;
    return amount.toFixed(2);

  }

  transactionStatus = (transactionType) => {
    let type = "";
    switch (transactionType) {
      case "P":
        type = "Purchase";
        break;

      case "R":
        type = "Redemption";
        break;

      case "SI":
        type = "Switch In";
        break;

      case "SO":
        type = "Switch Out";
        break;

      default:
        break;
    }
    return type;
  };

  months_full = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  months_short_names = (index, full = false) => {
    if (!full) {
      return index <= 12 ? this.months[index - 1] : null;
    } else return index <= 12 ? this.months_full[index - 1] : null;
  };

  dateFormat = (date, delimeter = "-") => {
    let values = date.split(`${delimeter}`);
    return (
      values[2] +
      "-" +
      this.months_short_names(parseInt(values[1])) +
      "-" +
      values[0]
    );
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  handleStartTransaction = (type, key) => {
    Store.dispatch({
      type: "ADD_TRANSACTION",
      payload: {
        key: key,
        data: {
          type,
          screen: type == "SIP" ? "StartSIP" : "InvestLumpsum",
        },
      },
    });

    this.setState({
      showComplianceAlert: true,
    });
  };

  render() {
    const { error, errorText, isLoading } = this.state;
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        {/* {this.state.show_choose_your_plan ? (
          <NoPlanModal
            navigation={this.props.navigation}
            route={this.props.route}
          />
        ) : null} */}
        {this.state.showComplianceAlert && (
          <ComplianceModal
            forceLoad={true}
            navigation={this.props.navigation}
            route={this.props.route}
            isLoading={this.state.gettingMinDetails}
            minDetailsResponse={this.state.mfMinDetailsResponse}
            onClose={() => {
              this.setState({ showComplianceAlert: false });
            }}
            userId={this.state.userId}
            navigationOptions={{
              transaction: this.state.fundPK,
            }}
          />
        )}
        <View style={{ flex: 1, maxWidth: 1500 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={[
              "Mutual Funds Overview",
              "Invest",
              "Fund Details",
            ]}
            onPressBack={() => {
              if (this.props.route.params && this.props.route.params.from && this.props.route.params.from == "Plans") {

                this.props.navigation.navigate("App", {

                  screen: "Plans",
                  params: {
                    screen: "MyMFInvestmentHistory",
                    params: {
                      activeTabIndex: 1,
                    },
                  },
                });
              }

              if (this.props.route.params && this.props.route.params.from && this.props.route.params.from == "EQUITY") {

                this.props.navigation.navigate("MFExplore");
                this.props.navigation.navigate("App", {
                  screen: "MFExplore",
                  params: {
                    index: 1,
                    type: this.state.fundType,
                    from: this.state.from,
                  }
                });
              }
              else {

                this.props.navigation.navigate("Account",
                  {
                    screen: "PersonalisedFunds",
                    params: {}

                  });


              }

              // this.props.navigation.navigate("InvestmentOverview")

            }}
          />
          {/* {this.state.showToast == "true" ? (
            <ShowToast
              showToast={true}
              type="status"
              title="Your nominee authentication was successfully completed."
              duration="10"
            />
          ) : null} */}
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <ActivityIndicator size="large" color="#410DAA" />
            ) : error ? (
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText || "Something went wrong!"}
              </Text>
            ) : (
              <ScrollView style={[styles.mainContainer]}>
                {this.state.fund_not_avaiable ? (
                  <View
                    style={{
                      flex: 1,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 250,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      This Fund Scheme is not being sold anymore. Kindly select
                      different fund to invest
                    </Text>
                  </View>
                ) : (
                  <View
                    style={{
                      paddingBottom: 40,
                      marginTop: isMobile ? -3 : -17,
                    }}
                  >
                    {isMobile ? (
                      <>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            marginTop: 17,
                            width: "90%",
                            marginLeft: "5%",
                            marginRight: "5%",
                          }}
                        >
                          <View
                            style={{
                              flex: 10,
                              borderRadius: 10,
                              borderWidth: 1,
                              borderColor: "#410DAA1A",
                            }}
                          >
                            <View
                              style={{
                                padding: 10,
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <View style={{ flex: 0.05 }}>
                                <Image
                                  source={{
                                    uri: this.state.mfFundTxnDetails.amc_logo,
                                  }}
                                  resizeMode="contain"
                                  style={{
                                    height: 30,
                                    width: 30,
                                    alignSelf: "flex-start",
                                    borderWidth: 1,
                                    borderColor: "#f2f2f2",
                                    borderRadius: 5,
                                  }}
                                />
                              </View>
                              <View
                                style={{ flex: isMobile ? 0.05 : 0.01 }}
                              ></View>
                              <View style={{ flex: 0.84, paddingLeft: 35 }}>
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.state.selected_fund.name}
                                </Text>
                              </View>
                              <View style={{ flex: 0.1 }}></View>
                            </View>
                            <View
                              style={{
                                paddingHorizontal: 10,
                                paddingVertical: 4,
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingVertical: 9,
                                }}
                              >
                                <FundField
                                  style={{
                                    flex: 0.33,
                                    alignItems: "flex-start",
                                  }}
                                  name="Latest NAV"
                                  value={this.state.selected_fund.latest_nav.toFixed(
                                    2
                                  )}
                                />
                                <FundField
                                  style={{ flex: 0.33, alignItems: "center" }}
                                  name="3 Year Returns"
                                  value={
                                    this.state.selected_fund.returns_3_yr
                                      ? `${Number.parseFloat(
                                        this.state.selected_fund.returns_3_yr
                                      ).toPrecision(3)}%`
                                      : "-"
                                  }
                                />
                                <FundField
                                  style={{ flex: 0.34, alignItems: "flex-end" }}
                                  name="Fund Class"
                                  value={
                                    this.state.selected_fund.AMCFund__fund_class
                                      ? this.state.selected_fund.AMCFund__fund_class.trim()
                                      : "-"
                                  }
                                />
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingVertical: 9,
                                }}
                              >
                                <FundField
                                  style={{
                                    flex: 0.33,
                                    alignItems: "flex-start",
                                  }}
                                  name="Min. SIP"
                                  value={
                                    this.state.selected_fund.bse_sip_flag == "Y"
                                      ? this.state.selected_fund
                                        .bse_sip_minimum_installment_amount_monthly
                                        ? "\u20B9" +
                                        this.state.selected_fund
                                          .bse_sip_minimum_installment_amount_monthly
                                        : "-"
                                      : "-"
                                  }
                                />
                                <FundField
                                  style={{ flex: 0.33, alignItems: "center" }}
                                  name="Min. LUMPSUM"
                                  value={
                                    this.state.selected_fund
                                      .bse_purchase_allowed == "Y"
                                      ? this.state.selected_fund
                                        .bse_minimum_purchase_amount
                                        ? "\u20B9" +
                                        this.state.selected_fund
                                          .bse_minimum_purchase_amount
                                        : "-"
                                      : "-"
                                  }
                                />
                                <FundField
                                  style={{ flex: 0.34, alignItems: "flex-end" }}
                                  name="Funds Managed"
                                  value={
                                    this.getNumIndianFormat(
                                      (
                                        Number(
                                          this.state.selected_fund
                                            .AMCFund__assets_under_management
                                        ) / 100
                                      ).toFixed(0)
                                    ) + " Cr."
                                  }
                                />
                              </View>
                            </View>

                            <View
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              {this.state.selected_fund.bse_sip_flag == "Y" ? (
                                <TouchableOpacity
                                  style={{
                                    padding: 15,
                                    flex: 1,
                                    justifyContent: "flex-start",
                                  }}
                                  onPress={() => {
                                    if (
                                      this.state.selected_fund.bse_sip_flag ==
                                      "Y"
                                    ) {
                                      this.props.navigation.navigate(
                                        "StartSIP",
                                        {
                                          fund: this.state.fundPK,
                                          selected_fund: this.state.fund_amc_id,
                                        }
                                      );
                                    }
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "Roboto",
                                      fontWeight: "bold",
                                      fontSize: isWeb ? 15 : 13,
                                      color: "#410DAA",
                                      backgroundColor:
                                        this.state.selected_fund.bse_sip_flag ==
                                          "Y"
                                          ? "transparent"
                                          : "#707070",
                                      borderColor:
                                        this.state.selected_fund.bse_sip_flag ==
                                          "Y"
                                          ? "#410DAA"
                                          : "#707070",
                                      borderWidth: 1,
                                      borderRadius: 4,
                                      paddingVertical: 10,
                                      textAlign: "center",
                                      paddingHorizontal: 30,
                                      width: isMobile ? "95%" : "60%",
                                      marginLeft: isMobile ? "2.5%" : "20%",
                                      marginRight: isMobile ? "2.5%" : "20%",
                                    }}
                                  >
                                    Start SIP
                                  </Text>
                                </TouchableOpacity>
                              ) : (
                                <View
                                  style={{
                                    marginTop: 12,
                                    padding: 15,
                                    flex: 1,
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "Roboto",
                                      fontWeight: "bold",
                                      fontSize: isWeb ? 15 : 13,
                                      color: "#FFFFFF",
                                      backgroundColor:
                                        this.state.selected_fund.bse_sip_flag ==
                                          "Y"
                                          ? "#410DAA"
                                          : "#707070",
                                      borderColor:
                                        this.state.selected_fund.bse_sip_flag ==
                                          "Y"
                                          ? "#410DAA"
                                          : "#707070",
                                      borderWidth: 1,
                                      borderRadius: 4,
                                      paddingVertical: 10,
                                      textAlign: "center",
                                      paddingHorizontal: 30,
                                      width: isMobile ? "95%" : "60%",
                                      marginLeft: isMobile ? "2.5%" : "20%",
                                      marginRight: isMobile ? "2.5%" : "20%",
                                    }}
                                  >
                                    Start SIP
                                  </Text>
                                </View>
                              )}

                              {this.state.selected_fund.bse_purchase_allowed ==
                                "Y" ? (
                                <TouchableOpacity
                                  style={{
                                    padding: 15,
                                    flex: 1,
                                    justifyContent: "flex-end",
                                  }}
                                  onPress={() => {
                                    if (
                                      this.state.selected_fund
                                        .bse_purchase_allowed == "Y"
                                    ) {
                                      this.props.navigation.navigate(
                                        "InvestLumpsum",
                                        {
                                          fund: this.state.fundPK,
                                          add_invt: this.state.add_invt
                                            ? "Y"
                                            : "N",
                                          selected_fund: this.state.fund_amc_id,
                                          from: "InvestLumpsum"
                                        }
                                      );
                                    }
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "Roboto",
                                      fontWeight: "bold",
                                      fontSize: isWeb ? 15 : 13,
                                      color: "#FFFFFF",
                                      backgroundColor: "#410DAA",
                                      borderColor: "#410DAA",
                                      borderWidth: 1,
                                      borderRadius: 4,
                                      paddingVertical: 10,
                                      textAlign: "center",
                                      paddingHorizontal: 30,
                                      width: isMobile ? "95%" : "60%",
                                      marginLeft: isMobile ? "2.5%" : "20%",
                                      marginRight: isMobile ? "2.5%" : "20%",
                                    }}
                                  >
                                    Lumpsum
                                  </Text>
                                </TouchableOpacity>
                              ) : (
                                <View
                                  style={{
                                    marginTop: 12,
                                    padding: 15,
                                    flex: 1,
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "Roboto",
                                      fontWeight: "bold",
                                      fontSize: isWeb ? 15 : 13,
                                      color: "#FFFFFF",
                                      backgroundColor:
                                        this.state.selected_fund
                                          .bse_purchase_allowed == "Y"
                                          ? "#410DAA"
                                          : "#707070",
                                      borderColor:
                                        this.state.selected_fund
                                          .bse_purchase_allowed == "Y"
                                          ? "#410DAA"
                                          : "#707070",
                                      borderWidth: 1,
                                      borderRadius: 4,
                                      paddingVertical: 10,
                                      textAlign: "center",
                                      paddingHorizontal: 30,
                                      width: isMobile ? "95%" : "60%",
                                      marginLeft: isMobile ? "2.5%" : "20%",
                                      marginRight: isMobile ? "2.5%" : "20%",
                                    }}
                                  >
                                    Lumpsum
                                  </Text>
                                </View>
                              )}
                            </View>
                          </View>
                        </View>
                        <View
                          style={{
                            flex: 1,
                            marginTop: 17,
                            width: "90%",
                            marginLeft: "5%",
                            marginRight: "5%",
                          }}
                        >
                          {this.state.selected_fund.bse_purchase_allowed ==
                            "Y" ||
                            this.state.selected_fund.bse_sip_flag == "Y" ? (
                            <>
                              <Text
                                style={{
                                  flex: 1,
                                  fontSize: 17,
                                  marginTop: 15,
                                  color: "#464E5F",
                                  fontWeight: "bold",
                                }}
                              >
                                Returns Calculator
                              </Text>

                              {this.state.selected_fund.bse_purchase_allowed ==
                                "Y" &&
                                this.state.selected_fund.bse_sip_flag ==
                                "Y" && (
                                  <View
                                    style={{
                                      marginTop: 15,
                                      flexDirection: "row",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color:
                                          this.state.calculatorChosen == false
                                            ? "#410daa"
                                            : "#121212",
                                        fontWeight:
                                          this.state.calculatorChosen == false
                                            ? "bold"
                                            : "normal",
                                        marginRight: 16,
                                      }}
                                    >
                                      Monthly SIP
                                    </Text>
                                    <Switch
                                      trackColor={{
                                        false: "#BAC7F2",
                                        true: "#BAC7F2",
                                      }}
                                      thumbColor={"#410DAA"}
                                      activeThumbColor={"#410DAA"}
                                      onValueChange={() => {
                                        this.setState({
                                          calculatorChosen:
                                            !this.state.calculatorChosen,
                                        });
                                      }}
                                      value={this.state.calculatorChosen}
                                    />
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color:
                                          this.state.calculatorChosen == true
                                            ? "#410daa"
                                            : "#121212",
                                        fontWeight:
                                          this.state.calculatorChosen == true
                                            ? "bold"
                                            : "normal",
                                        marginLeft: 16,
                                      }}
                                    >
                                      LUMPSUM
                                    </Text>
                                  </View>
                                )}

                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <View
                                  style={{
                                    marginTop: 12,
                                    backgroundColor: "white",
                                    flex: 1,
                                  }}
                                >
                                  {this.state.selected_fund
                                    .bse_purchase_allowed == "Y" &&
                                    this.state.calculatorChosen == true && (
                                      <View>
                                        <View style={{ marginTop: 8 }}>
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <Text style={{ textAlign: "left" }}>
                                              One Time
                                            </Text>
                                            <View
                                              style={{
                                                flex: 1,
                                                alignItems: "flex-end",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontWeight: "bold",
                                                  color: "#410DAA",
                                                }}
                                              >
                                                &#8377;{" "}
                                                {this.getNumIndianFormat(
                                                  this.state.value
                                                )}
                                              </Text>
                                            </View>
                                          </View>

                                          <Slider
                                            value={500}
                                            thumbTintColor={"#410daa"}
                                            minimumValue={500}
                                            step={500}
                                            maximumValue={1000000}
                                            maximumTrackTintColor={"#D8D8D8"}
                                            minimumTrackTintColor={"#410daa"}
                                            onValueChange={(value) => {
                                              this.setState({ value: value });
                                            }}
                                          />
                                        </View>

                                        <View style={{ marginTop: 8 }}>
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <Text style={{ textAlign: "left" }}>
                                              Duration{" "}
                                            </Text>
                                            <View
                                              style={{
                                                flex: 1,
                                                alignItems: "flex-end",
                                              }}
                                            >
                                              {this.state.year > 1 ? (
                                                <Text
                                                  style={{
                                                    color: "#410DAA",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {this.state.year} years
                                                </Text>
                                              ) : (
                                                <Text
                                                  style={{
                                                    color: "#410DAA",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {this.state.year} year
                                                </Text>
                                              )}
                                            </View>
                                          </View>
                                          <Slider
                                            value={5}
                                            thumbTintColor={"#410daa"}
                                            minimumValue={5}
                                            step={5}
                                            maximumValue={30}
                                            maximumTrackTintColor={"#D8D8D8"}
                                            minimumTrackTintColor={"#410daa"}
                                            onValueChange={(value) => {
                                              this.setState({ year: value });
                                            }}
                                          />
                                        </View>

                                        <View
                                          style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            marginTop: 16,
                                          }}
                                        >
                                          {this.state.is_club ? null : (
                                            <View
                                              style={{
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  ...styles.detailHeading,
                                                }}
                                              >
                                                {isJama() ? "Jama" : "Maxiom"} Returns {"\n"}Direct Plan
                                              </Text>
                                              <Text style={styles.detailText}>
                                                {this.convert_crore_to_lac(
                                                  this.calculate_future_value(
                                                    parseInt(this.state.value),
                                                    parseInt(this.state.year),
                                                    this.state.selected_fund
                                                      .returns_3_yr_regular
                                                      ? parseFloat(
                                                        this.state
                                                          .selected_fund
                                                          .returns_3_yr_regular
                                                      )
                                                      : null
                                                  )
                                                )}
                                              </Text>
                                            </View>
                                          )}
                                          {this.state.is_club && (
                                            <View
                                              style={{ alignItems: "center" }}
                                            >
                                              <Text
                                                style={{
                                                  ...styles.detailHeading,
                                                  textAlign: "center",
                                                }}
                                              >
                                                Returns
                                              </Text>
                                              <Text style={styles.detailText}>
                                                {this.convert_crore_to_lac(
                                                  this.calculate_future_value(
                                                    parseInt(this.state.value),
                                                    parseInt(this.state.year),
                                                    this.state.selected_fund
                                                      .returns_3_yr_regular
                                                      ? parseFloat(
                                                        this.state
                                                          .selected_fund
                                                          .returns_3_yr_regular
                                                      )
                                                      : null
                                                  )
                                                )}
                                              </Text>
                                            </View>
                                          )}
                                          {!this.state.is_club && (
                                            <View
                                              style={{ alignItems: "flex-end" }}
                                            >
                                              <Text
                                                style={{
                                                  ...styles.detailHeading,
                                                  textAlign: "right",
                                                  opacity: 1,
                                                }}
                                              >
                                                Saving with{"\n"} {isJama() ? "Jama" : "Maxiom"}
                                              </Text>
                                              <Text
                                                style={{
                                                  ...styles.detailText,
                                                  fontWeight: "bold",
                                                  color: "#410DAA",
                                                }}
                                              >
                                                {this.convert_crore_to_lac(
                                                  this.calculate_future_value(
                                                    parseInt(this.state.value),
                                                    parseInt(this.state.year),
                                                    this.state.selected_fund
                                                      .returns_3_yr_regular
                                                      ? parseFloat(
                                                        this.state
                                                          .selected_fund
                                                          .returns_3_yr_regular
                                                      )
                                                      : null
                                                  ) -
                                                  this.calculate_future_value(
                                                    parseInt(
                                                      this.state.value
                                                    ),
                                                    parseInt(this.state.year),
                                                    this.state.selected_fund
                                                      .returns_3_yr_regular
                                                      ? parseFloat(
                                                        this.state
                                                          .selected_fund
                                                          .returns_3_yr_regular
                                                      )
                                                      : null
                                                  )
                                                )}
                                              </Text>
                                            </View>
                                          )}
                                        </View>
                                      </View>
                                    )}
                                  {this.state.selected_fund
                                    .bse_purchase_allowed == "Y" &&
                                    this.state.calculatorChosen == false && (
                                      <View>
                                        <View style={{ marginTop: 8 }}>
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <Text style={{ textAlign: "left" }}>
                                              Per Month
                                            </Text>
                                            <View
                                              style={{
                                                flex: 1,
                                                alignItems: "flex-end",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontWeight: "bold",
                                                  color: "#410DAA",
                                                }}
                                              >
                                                &#8377;{" "}
                                                {this.getNumIndianFormat(
                                                  this.state.sipvalue
                                                )}
                                              </Text>
                                            </View>
                                          </View>

                                          <Slider
                                            value={500}
                                            thumbTintColor={"#410daa"}
                                            minimumValue={500}
                                            step={500}
                                            maximumValue={99500}
                                            maximumTrackTintColor={"#D8D8D8"}
                                            minimumTrackTintColor={"#410daa"}
                                            onValueChange={(value) => {
                                              this.setState({
                                                sipvalue: value,
                                              });
                                            }}
                                          />
                                        </View>

                                        <View style={{ marginTop: 8 }}>
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <Text style={{ textAlign: "left" }}>
                                              Duration{" "}
                                            </Text>
                                            <View
                                              style={{
                                                flex: 1,
                                                alignItems: "flex-end",
                                              }}
                                            >
                                              {this.state.year > 1 ? (
                                                <Text
                                                  style={{
                                                    color: "#410DAA",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {this.state.sipyear} years
                                                </Text>
                                              ) : (
                                                <Text
                                                  style={{
                                                    color: "#410DAA",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {this.state.sipyear} year
                                                </Text>
                                              )}
                                            </View>
                                          </View>
                                          <Slider
                                            value={5}
                                            thumbTintColor={"#410daa"}
                                            minimumValue={5}
                                            step={5}
                                            maximumValue={30}
                                            maximumTrackTintColor={"#D8D8D8"}
                                            minimumTrackTintColor={"#410daa"}
                                            onValueChange={(value) => {
                                              this.setState({ sipyear: value });
                                            }}
                                          />
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            marginTop: 16,
                                          }}
                                        >
                                          {this.state.is_club ? null : (
                                            <View
                                              style={{
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  ...styles.detailHeading,
                                                }}
                                              >
                                                {isJama() ? "Jama" : "Maxiom"} Returns {"\n"}Direct Plan
                                              </Text>
                                              <Text style={styles.detailText}>
                                                {this.convert_crore_to_lac(
                                                  parseInt(
                                                    this.calculate_sip_future_value(
                                                      parseInt(
                                                        this.state.sipvalue
                                                      ),
                                                      parseInt(
                                                        this.state.sipyear * 12
                                                      ),
                                                      this.state.selected_fund
                                                        .returns_3_yr_regular
                                                        ? parseFloat(
                                                          this.state.selected_fund.returns_3_yr_regular.toFixed(
                                                            2
                                                          ) / 100
                                                        )
                                                        : null
                                                    )
                                                  )
                                                )}
                                              </Text>
                                            </View>
                                          )}
                                          {this.state.is_club && (
                                            <View
                                              style={{ alignItems: "center" }}
                                            >
                                              <Text
                                                style={{
                                                  ...styles.detailHeading,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {/* {this.state.is_club ? "Returns" : "Returns \nRegular Plan"} */}
                                                Returns
                                              </Text>
                                              <Text style={styles.detailText}>
                                                {this.convert_crore_to_lac(
                                                  parseInt(
                                                    this.calculate_sip_future_value(
                                                      parseInt(
                                                        this.state.sipvalue
                                                      ),
                                                      parseInt(
                                                        this.state.sipyear * 12
                                                      ),
                                                      this.state.selected_fund
                                                        .returns_3_yr_regular
                                                        ? parseFloat(
                                                          this.state
                                                            .selected_fund
                                                            .returns_3_yr_regular
                                                        ).toFixed(2) / 100
                                                        : null
                                                    )
                                                  )
                                                )}
                                              </Text>
                                            </View>
                                          )}
                                          {!this.state.is_club && (
                                            <View
                                              style={{ alignItems: "flex-end" }}
                                            >
                                              <Text
                                                style={{
                                                  ...styles.detailHeading,
                                                  textAlign: "right",
                                                  opacity: 1,
                                                }}
                                              >
                                                Saving with{"\n"} {isJama() ? "Jama" : "Maxiom"}
                                              </Text>
                                              <Text
                                                style={{
                                                  ...styles.detailText,
                                                  fontWeight: "bold",
                                                  color: "#410DAA",
                                                }}
                                              >
                                                {this.convert_crore_to_lac(
                                                  parseInt(
                                                    this.calculate_sip_future_value(
                                                      parseInt(
                                                        this.state.sipvalue
                                                      ),
                                                      parseInt(
                                                        this.state.sipyear * 12
                                                      ),
                                                      this.state.selected_fund
                                                        .returns_3_yr_regular
                                                        ? parseFloat(
                                                          this.state.selected_fund.returns_3_yr_regular.toFixed(
                                                            2
                                                          )
                                                        ) / 100
                                                        : null
                                                    )
                                                  ) -
                                                  parseInt(
                                                    this.calculate_sip_future_value(
                                                      parseInt(
                                                        this.state.sipvalue
                                                      ),
                                                      parseInt(
                                                        this.state.sipyear *
                                                        12
                                                      ),
                                                      this.state.selected_fund
                                                        .returns_3_yr_regular
                                                        ? parseFloat(
                                                          this.state.selected_fund.returns_3_yr_regular.toFixed(
                                                            2
                                                          )
                                                        ) / 100
                                                        : null
                                                    )
                                                  )
                                                )}
                                              </Text>
                                            </View>
                                          )}
                                        </View>
                                      </View>
                                    )}
                                </View>
                              </View>
                            </>
                          ) : null}
                        </View>
                      </>
                    ) : (
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            marginTop: 17,
                          }}
                        >
                          <View
                            style={{
                              flex: 10,
                              borderRadius: 10,
                              borderWidth: 1,
                              borderColor: "#410DAA1A",
                            }}
                          >
                            <View
                              style={{
                                padding: 10,
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <View style={{ flex: 0.05 }}>
                                <Image
                                  source={{
                                    uri: this.state.mfFundTxnDetails.amc_logo,
                                  }}
                                  resizeMode="contain"
                                  style={{
                                    height: 30,
                                    width: 30,
                                    alignSelf: "flex-start",
                                    borderWidth: 1,
                                    borderColor: "#f2f2f2",
                                    borderRadius: 5,
                                  }}
                                />
                              </View>
                              <View
                                style={{ flex: isMobile ? 0.05 : 0.01 }}
                              ></View>
                              <View style={{ flex: 0.84, paddingLeft: 35 }}>
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.state.selected_fund.name}
                                </Text>
                              </View>
                              <View style={{ flex: 0.1 }}></View>
                            </View>
                            <View
                              style={{
                                paddingHorizontal: 10,
                                paddingVertical: 4,
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingVertical: 9,
                                }}
                              >
                                <FundField
                                  style={{
                                    flex: 0.33,
                                    alignItems: "flex-start",
                                  }}
                                  name="Latest NAV"
                                  value={this.state.selected_fund.latest_nav.toFixed(
                                    2
                                  )}
                                />
                                {/* here we check for 3 year returns if not then we check for 1 year
                                else showing 3 months returns */}
                                {this.state.selected_fund.returns_3_yr ? <FundField
                                  style={{ flex: 0.33, alignItems: "center" }}
                                  name="3 Year Returns"
                                  value={
                                    this.state.selected_fund.returns_3_yr
                                      ? `${Number.parseFloat(
                                        this.state.selected_fund.returns_3_yr
                                      ).toPrecision(3)}%`
                                      : "-"
                                  }
                                /> : this.state.selected_fund.returns_1_yr ? <FundField
                                  style={{ flex: 0.33, alignItems: "center" }}
                                  name="1 Year Returns"
                                  value={
                                    this.state.selected_fund.returns_1_yr
                                      ? `${Number.parseFloat(
                                        this.state.selected_fund.returns_1_yr
                                      ).toPrecision(3)}%`
                                      : "-"
                                  }
                                /> : <FundField
                                  style={{ flex: 0.33, alignItems: "center" }}
                                  name="3 months Returns"
                                  value={
                                    this.state.selected_fund.returns_3_mth
                                      ? `${Number.parseFloat(
                                        this.state.selected_fund.returns_3_mth
                                      ).toPrecision(3)}%`
                                      : "-"
                                  }
                                />}
                                <FundField
                                  style={{ flex: 0.34, alignItems: "flex-end" }}
                                  name="Fund Class"
                                  value={
                                    this.state.selected_fund.AMCFund__fund_class
                                      ? this.state.selected_fund.AMCFund__fund_class.trim()
                                      : "-"
                                  }
                                />
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingVertical: 9,
                                }}
                              >
                                <FundField
                                  style={{
                                    flex: 0.33,
                                    alignItems: "flex-start",
                                  }}
                                  name="Min. SIP"
                                  value={
                                    this.state.selected_fund.bse_sip_flag == "Y"
                                      ? this.state.selected_fund
                                        .bse_sip_minimum_installment_amount_monthly
                                        ? "\u20B9" +
                                        this.state.selected_fund
                                          .bse_sip_minimum_installment_amount_monthly
                                        : "-"
                                      : "-"
                                  }
                                />
                                <FundField
                                  style={{ flex: 0.33, alignItems: "center" }}
                                  name="Min. LUMPSUM"
                                  value={
                                    this.state.selected_fund
                                      .bse_purchase_allowed == "Y"
                                      ? this.state.selected_fund
                                        .bse_minimum_purchase_amount
                                        ? "\u20B9" +
                                        this.state.selected_fund
                                          .bse_minimum_purchase_amount
                                        : "-"
                                      : "-"
                                  }
                                />
                                <FundField
                                  style={{ flex: 0.34, alignItems: "flex-end" }}
                                  name="Funds Managed"
                                  value={
                                    this.getNumIndianFormat(
                                      (
                                        Number(
                                          this.state.selected_fund
                                            .AMCFund__assets_under_management
                                        ) / 100
                                      ).toFixed(0)
                                    ) + " Cr."
                                  }
                                />
                              </View>
                            </View>

                            <View
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              {this.state.selected_fund.bse_sip_flag == "Y" ? (
                                <TouchableOpacity
                                  style={{
                                    marginTop: 12,
                                    backgroundColor: "white",
                                    padding: 15,
                                    flex: 1,
                                    justifyContent: "flex-start",
                                  }}
                                  onPress={() => {
                                    if (
                                      this.state.selected_fund.bse_sip_flag ==
                                      "Y"
                                    ) {
                                      this.props.navigation.navigate(
                                        "StartSIP",
                                        {
                                          fund: this.state.fundPK,
                                          selected_fund: this.state.fund_amc_id,
                                        }
                                      );
                                    }
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "Roboto",
                                      fontWeight: "bold",
                                      fontSize: isWeb ? 15 : 13,
                                      color: "#FFFFFF",
                                      backgroundColor:
                                        this.state.selected_fund.bse_sip_flag ==
                                          "Y"
                                          ? "#410DAA"
                                          : "#707070",
                                      borderColor:
                                        this.state.selected_fund.bse_sip_flag ==
                                          "Y"
                                          ? "#410DAA"
                                          : "#707070",
                                      borderWidth: 1,
                                      borderRadius: 4,
                                      paddingVertical: 10,
                                      textAlign: "center",
                                      paddingHorizontal: 30,
                                      width: isMobile ? "90%" : "60%",
                                      marginLeft: isMobile ? "5%" : "20%",
                                      marginRight: isMobile ? "5%" : "20%",
                                    }}
                                  >
                                    Start SIP
                                  </Text>
                                </TouchableOpacity>
                              ) : (
                                <View
                                  style={{
                                    marginTop: 12,
                                    padding: 15,
                                    flex: 1,
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "Roboto",
                                      fontWeight: "bold",
                                      fontSize: isWeb ? 15 : 13,
                                      color: "#FFFFFF",
                                      backgroundColor:
                                        this.state.selected_fund.bse_sip_flag ==
                                          "Y"
                                          ? "#410DAA"
                                          : "#707070",
                                      borderColor:
                                        this.state.selected_fund.bse_sip_flag ==
                                          "Y"
                                          ? "#410DAA"
                                          : "#707070",
                                      borderWidth: 1,
                                      borderRadius: 4,
                                      paddingVertical: 10,
                                      textAlign: "center",
                                      paddingHorizontal: 30,
                                      width: isMobile ? "90%" : "60%",
                                      marginLeft: isMobile ? "5%" : "20%",
                                      marginRight: isMobile ? "5%" : "20%",
                                    }}
                                  >
                                    Start SIP
                                  </Text>
                                </View>
                              )}

                              {this.state.selected_fund.bse_purchase_allowed ==
                                "Y" ? (
                                <TouchableOpacity
                                  style={{
                                    marginTop: 12,
                                    backgroundColor: "white",
                                    padding: 15,
                                    flex: 1,
                                    justifyContent: "flex-end",
                                  }}
                                  onPress={() => {
                                    if (
                                      this.state.selected_fund
                                        .bse_purchase_allowed == "Y"
                                    ) {
                                      this.props.navigation.navigate(
                                        "InvestLumpsum",
                                        {
                                          fund: this.state.fundPK,
                                          add_invt: this.state.add_invt
                                            ? "Y"
                                            : "N",
                                          selected_fund: this.state.fund_amc_id,
                                        }
                                      );
                                    }
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "Roboto",
                                      fontWeight: "bold",
                                      fontSize: isWeb ? 15 : 13,
                                      color: "#FFFFFF",
                                      backgroundColor: "#410DAA",
                                      borderColor: "#410DAA",
                                      borderWidth: 1,
                                      borderRadius: 4,
                                      paddingVertical: 10,
                                      textAlign: "center",
                                      paddingHorizontal: 30,
                                      width: isMobile ? "90%" : "60%",
                                      marginLeft: isMobile ? "5%" : "20%",
                                      marginRight: isMobile ? "5%" : "20%",
                                    }}
                                  >
                                    Lumpsum
                                  </Text>
                                </TouchableOpacity>
                              ) : (
                                <View
                                  style={{
                                    marginTop: 12,
                                    padding: 15,
                                    flex: 1,
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "Roboto",
                                      fontWeight: "bold",
                                      fontSize: isWeb ? 15 : 13,
                                      color: "#FFFFFF",
                                      backgroundColor:
                                        this.state.selected_fund
                                          .bse_purchase_allowed == "Y"
                                          ? "#410DAA"
                                          : "#707070",
                                      borderColor:
                                        this.state.selected_fund
                                          .bse_purchase_allowed == "Y"
                                          ? "#410DAA"
                                          : "#707070",
                                      borderWidth: 1,
                                      borderRadius: 4,
                                      paddingVertical: 10,
                                      textAlign: "center",
                                      paddingHorizontal: 30,
                                      width: isMobile ? "90%" : "60%",
                                      marginLeft: isMobile ? "5%" : "20%",
                                      marginRight: isMobile ? "5%" : "20%",
                                    }}
                                  >
                                    Lumpsum
                                  </Text>
                                </View>
                              )}
                            </View>
                          </View>
                        </View>

                        <View
                          style={{
                            flex: 0.8,
                            marginTop: 17,
                            marginLeft: 20,
                          }}
                        >
                          {this.state.selected_fund.bse_purchase_allowed ==
                            "Y" ||
                            this.state.selected_fund.bse_sip_flag == "Y" ? (
                            <>
                              <Text
                                style={{
                                  flex: 1,
                                  fontSize: 17,
                                  color: "#464E5F",
                                  fontWeight: "bold",
                                  marginLeft: isMobile ? 20 : 10,
                                }}
                              >
                                Returns Calculator
                              </Text>

                              {this.state.selected_fund.bse_purchase_allowed ==
                                "Y" &&
                                this.state.selected_fund.bse_sip_flag ==
                                "Y" && (
                                  <View
                                    style={{
                                      marginLeft: -5,
                                      marginTop: 30,
                                      flexDirection: "row",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color:
                                          this.state.calculatorChosen == false
                                            ? "#410daa"
                                            : "#121212",
                                        fontWeight:
                                          this.state.calculatorChosen == false
                                            ? "bold"
                                            : "normal",
                                        marginHorizontal: 16,
                                      }}
                                    >
                                      Monthly SIP
                                    </Text>
                                    <Switch
                                      trackColor={{
                                        false: "#BAC7F2",
                                        true: "#BAC7F2",
                                      }}
                                      thumbColor={"#410DAA"}
                                      activeThumbColor={"#410DAA"}
                                      onValueChange={() => {
                                        this.setState({
                                          calculatorChosen:
                                            !this.state.calculatorChosen,
                                        });
                                      }}
                                      value={this.state.calculatorChosen}
                                    />
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color:
                                          this.state.calculatorChosen == true
                                            ? "#410daa"
                                            : "#121212",
                                        fontWeight:
                                          this.state.calculatorChosen == true
                                            ? "bold"
                                            : "normal",
                                        marginHorizontal: 16,
                                      }}
                                    >
                                      LUMPSUM
                                    </Text>
                                  </View>
                                )}

                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <View
                                  style={{
                                    marginTop: 12,
                                    backgroundColor: "white",
                                    padding: 15,
                                    flex: 1,
                                  }}
                                >
                                  {this.state.selected_fund
                                    .bse_purchase_allowed == "Y" &&
                                    this.state.calculatorChosen == true && (
                                      <View>
                                        <View style={{ marginTop: 8 }}>
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <Text style={{ textAlign: "left" }}>
                                              One Time
                                            </Text>
                                            <View
                                              style={{
                                                flex: 1,
                                                alignItems: "flex-end",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontWeight: "bold",
                                                  color: "#410DAA",
                                                }}
                                              >
                                                &#8377;{" "}
                                                {this.getNumIndianFormat(
                                                  this.state.value
                                                )}
                                              </Text>
                                            </View>
                                          </View>

                                          <Slider
                                            value={500}
                                            thumbTintColor={"#410daa"}
                                            minimumValue={500}
                                            step={500}
                                            maximumValue={1000000}
                                            maximumTrackTintColor={"#D8D8D8"}
                                            minimumTrackTintColor={"#410daa"}
                                            onValueChange={(value) => {
                                              this.setState({ value: value });
                                            }}
                                          />
                                        </View>

                                        <View style={{ marginTop: 8 }}>
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <Text style={{ textAlign: "left" }}>
                                              Duration{" "}
                                            </Text>
                                            <View
                                              style={{
                                                flex: 1,
                                                alignItems: "flex-end",
                                              }}
                                            >
                                              {this.state.year > 1 ? (
                                                <Text
                                                  style={{
                                                    color: "#410DAA",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {this.state.year} years
                                                </Text>
                                              ) : (
                                                <Text
                                                  style={{
                                                    color: "#410DAA",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {this.state.year} year
                                                </Text>
                                              )}
                                            </View>
                                          </View>
                                          <Slider
                                            value={5}
                                            thumbTintColor={"#410daa"}
                                            minimumValue={5}
                                            step={5}
                                            maximumValue={30}
                                            maximumTrackTintColor={"#D8D8D8"}
                                            minimumTrackTintColor={"#410daa"}
                                            onValueChange={(value) => {
                                              this.setState({ year: value });
                                            }}
                                          />
                                        </View>

                                        <View
                                          style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            marginTop: 16,
                                          }}
                                        >
                                          {this.state.is_club ? null : (
                                            <View
                                              style={{
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  ...styles.detailHeading,
                                                }}
                                              >
                                                {isJama() ? "Jama" : "Maxiom"} Returns {"\n"}Direct Plan
                                              </Text>
                                              <Text style={styles.detailText}>
                                                {this.convert_crore_to_lac(
                                                  this.calculate_future_value(
                                                    parseInt(this.state.value),
                                                    parseInt(this.state.year),
                                                    this.state.selected_fund
                                                      .returns_3_yr_regular
                                                      ? parseFloat(
                                                        this.state
                                                          .selected_fund
                                                          .returns_3_yr_regular
                                                      )
                                                      : null
                                                  )
                                                )}
                                              </Text>
                                            </View>
                                          )}
                                          {this.state.is_club && (
                                            <View
                                              style={{ alignItems: "center" }}
                                            >
                                              <Text
                                                style={{
                                                  ...styles.detailHeading,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {/* {this.state.is_club ? "Returns" : "Returns \nRegular Plan"} */}
                                                Returns
                                              </Text>
                                              <Text style={styles.detailText}>
                                                {this.convert_crore_to_lac(
                                                  this.calculate_future_value(
                                                    parseInt(this.state.value),
                                                    parseInt(this.state.year),
                                                    this.state.selected_fund
                                                      .returns_3_yr_regular
                                                      ? parseFloat(
                                                        this.state
                                                          .selected_fund
                                                          .returns_3_yr_regular
                                                      )
                                                      : null
                                                  )
                                                )}
                                              </Text>
                                            </View>
                                          )}
                                          {/* {!this.state.is_club && (
                                            <View
                                              style={{ alignItems: "flex-end" }}
                                            >
                                              <Text
                                                style={{
                                                  ...styles.detailHeading,
                                                  textAlign: "right",
                                                  opacity: 1,
                                                }}
                                              >
                                                Saving with{"\n"} Maxiom
                                              </Text>
                                              <Text
                                                style={{
                                                  ...styles.detailText,
                                                  fontWeight: "bold",
                                                  color: "#410DAA",
                                                }}
                                              >
                                                {this.convert_crore_to_lac(
                                                  this.calculate_future_value(
                                                    parseInt(this.state.value),
                                                    parseInt(this.state.year),
                                                    this.state.selected_fund
                                                      .returns_3_yr_regular
                                                      ? parseFloat(
                                                          this.state
                                                            .selected_fund
                                                            .returns_3_yr_regular
                                                        )
                                                      : null
                                                  ) -
                                                    this.calculate_future_value(
                                                      parseInt(
                                                        this.state.value
                                                      ),
                                                      parseInt(this.state.year),
                                                      this.state.selected_fund
                                                        .returns_3_yr_regular
                                                        ? parseFloat(
                                                            this.state
                                                              .selected_fund
                                                              .returns_3_yr_regular
                                                          )
                                                        : null
                                                    )
                                                )}
                                              </Text>
                                            </View>
                                          )} */}
                                        </View>
                                      </View>
                                    )}
                                  {this.state.selected_fund
                                    .bse_purchase_allowed == "Y" &&
                                    this.state.calculatorChosen == false && (
                                      <View>
                                        <View style={{ marginTop: 8 }}>
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <Text style={{ textAlign: "left" }}>
                                              Per Month
                                            </Text>
                                            <View
                                              style={{
                                                flex: 1,
                                                alignItems: "flex-end",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontWeight: "bold",
                                                  color: "#410DAA",
                                                }}
                                              >
                                                &#8377;{" "}
                                                {this.getNumIndianFormat(
                                                  this.state.sipvalue
                                                )}
                                              </Text>
                                            </View>
                                          </View>

                                          <Slider
                                            value={500}
                                            thumbTintColor={"#410daa"}
                                            minimumValue={500}
                                            step={500}
                                            maximumValue={99500}
                                            maximumTrackTintColor={"#D8D8D8"}
                                            minimumTrackTintColor={"#410daa"}
                                            onValueChange={(value) => {
                                              this.setState({
                                                sipvalue: value,
                                              });
                                            }}
                                          />
                                        </View>

                                        <View style={{ marginTop: 8 }}>
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <Text style={{ textAlign: "left" }}>
                                              Duration{" "}
                                            </Text>
                                            <View
                                              style={{
                                                flex: 1,
                                                alignItems: "flex-end",
                                              }}
                                            >
                                              {this.state.year > 1 ? (
                                                <Text
                                                  style={{
                                                    color: "#410DAA",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {this.state.sipyear} years
                                                </Text>
                                              ) : (
                                                <Text
                                                  style={{
                                                    color: "#410DAA",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {this.state.sipyear} year
                                                </Text>
                                              )}
                                            </View>
                                          </View>
                                          <Slider
                                            value={5}
                                            thumbTintColor={"#410daa"}
                                            minimumValue={5}
                                            step={5}
                                            maximumValue={30}
                                            maximumTrackTintColor={"#D8D8D8"}
                                            minimumTrackTintColor={"#410daa"}
                                            onValueChange={(value) => {
                                              this.setState({ sipyear: value });
                                            }}
                                          />
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            marginTop: 16,
                                          }}
                                        >
                                          {this.state.is_club ? null : (
                                            <View
                                              style={{
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  ...styles.detailHeading,
                                                }}
                                              >
                                                {isJama() ? "Jama" : "Maxiom"} Returns {"\n"}Direct Plan
                                              </Text>
                                              <Text style={styles.detailText}>
                                                {this.convert_crore_to_lac(
                                                  parseInt(
                                                    this.calculate_sip_future_value(
                                                      parseInt(
                                                        this.state.sipvalue
                                                      ),
                                                      parseInt(
                                                        this.state.sipyear * 12
                                                      ),
                                                      this.state.selected_fund
                                                        .returns_3_yr_regular
                                                        ? parseFloat(
                                                          this.state.selected_fund.returns_3_yr_regular.toFixed(
                                                            2
                                                          ) / 100
                                                        )
                                                        : null
                                                    )
                                                  )
                                                )}
                                              </Text>
                                            </View>
                                          )}
                                          {this.state.is_club && (
                                            <View
                                              style={{ alignItems: "center" }}
                                            >
                                              <Text
                                                style={{
                                                  ...styles.detailHeading,
                                                  textAlign: "center",
                                                }}
                                              >
                                                {/* {this.state.is_club ? "Returns" : "Returns \nRegular Plan"} */}
                                                Returns
                                              </Text>
                                              <Text style={styles.detailText}>
                                                {this.convert_crore_to_lac(
                                                  parseInt(
                                                    this.calculate_sip_future_value(
                                                      parseInt(
                                                        this.state.sipvalue
                                                      ),
                                                      parseInt(
                                                        this.state.sipyear * 12
                                                      ),
                                                      this.state.selected_fund
                                                        .returns_3_yr_regular
                                                        ? parseFloat(
                                                          this.state
                                                            .selected_fund
                                                            .returns_3_yr_regular
                                                        ).toFixed(2) / 100
                                                        : null
                                                    )
                                                  )
                                                )}
                                              </Text>
                                            </View>
                                          )}
                                          {!this.state.is_club && (
                                            <View
                                              style={{ alignItems: "flex-end" }}
                                            >
                                              {/* <Text
                                                style={{
                                                  ...styles.detailHeading,
                                                  textAlign: "right",
                                                  opacity: 1,
                                                }}
                                              >
                                                Saving with{"\n"} Maxiom
                                              </Text>
                                              <Text
                                                style={{
                                                  ...styles.detailText,
                                                  fontWeight: "bold",
                                                  color: "#410DAA",
                                                }}
                                              >
                                                {this.convert_crore_to_lac(
                                                  parseInt(
                                                    this.calculate_sip_future_value(
                                                      parseInt(
                                                        this.state.sipvalue
                                                      ),
                                                      parseInt(
                                                        this.state.sipyear * 12
                                                      ),
                                                      this.state.selected_fund
                                                        .returns_3_yr_regular
                                                        ? parseFloat(
                                                            this.state.selected_fund.returns_3_yr_regular.toFixed(
                                                              2
                                                            )
                                                          ) / 100
                                                        : null
                                                    )
                                                  ) -
                                                    parseInt(
                                                      this.calculate_sip_future_value(
                                                        parseInt(
                                                          this.state.sipvalue
                                                        ),
                                                        parseInt(
                                                          this.state.sipyear *
                                                            12
                                                        ),
                                                        this.state.selected_fund
                                                          .returns_3_yr_regular
                                                          ? parseFloat(
                                                              this.state.selected_fund.returns_3_yr_regular.toFixed(
                                                                2
                                                              )
                                                            ) / 100
                                                          : null
                                                      )
                                                    )
                                                )}
                                              </Text> */}
                                            </View>
                                          )}
                                        </View>
                                      </View>
                                    )}
                                </View>
                              </View>
                            </>
                          ) : null}
                        </View>
                      </View>
                    )}
                  </View>
                )}
                {isMobile && <View style={{ marginTop: 35 }}></View>}
              </ScrollView>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: isWeb ? 32 : 0,
    paddingRight: isWeb ? 32 : 0,
    paddingTop: isWeb ? 30 : 10,
    width: "100%",
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  detailHeading: {
    color: "#121212",
    opacity: 0.5,
    fontStyle: "italic",
    fontSize: 11,
  },
  detailText: {
    color: "#121212",
    fontSize: 14,
    marginTop: 4,
    fontWeight: "bold",
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
  filterText: {
    fontSize: 15,
    color: "gray",
    fontWeight: "200",
  },
  filtersTitle: {
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 6,
    marginLeft: isMobile ? 16 : 0,
  },
});

const mapStateToProps = (state) => ({
  transactions: state.transactions,
});

export default connect(mapStateToProps)(FundDescription);
