import React, { Component } from "react";
import {
  View,
  FlatList,
  Text,
  Alert,
  Image,
  StatusBar,
  SafeAreaView,
  Platform,
  ActivityIndicator,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from "react-native";
const WebView =
  Platform.OS == "web"
    ? require("react-native-web-webview").WebView
    : require("react-native-webview").WebView;
import { getPendingMandates } from "../../../api/alerts";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { isMobile, months_short_names } from "../../../utils";
import amplitude from "../../../utils/amplitude";

class MandatesPending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mandates: [],
      showWebViewBool: false,
      showHTMLContent: "",
      loading: true,
    };
  }
  componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("MandatesPending",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("MandatesPending", { path: this.props.navigation.getState().routeName });
    }
    this.pendingMandates();
  }
  pendingMandates = () => {
    getPendingMandates().then((response) => {
      this.setState({
        mandates: response.results,
        loading: false,
      });
    });
  };
  handleBackButton() {
    const that = this;
    Alert.alert(
      "Going back",
      "Are you sure you want to go back",
      [
        {
          text: "Yes, Go back and cancel the setup",
          onPress: () => that.props.navigation.goBack(),
        },
        {
          text: "Dont Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
      ],
      { cancelable: false }
    );
  }

  getMandateTypeName = (type) => {
    switch (type) {
      case "I":
        return "ISIP";
      case "X":
        return "NACH Mandate";
      case "N":
        return "E-Mandate(Net Banking)";
      default:
        return "-";
    }
  };

  _renderView = (items) => {
    let account = items.item.bank_account;
    account =
      account.substring(0, 4) +
      " " +
      "XXXX" +
      " " +
      account.substring(account.length - 5, account.length);

    const dates = items.item.created.split("/");
    const day = dates[0];
    const year = dates[2];
    const month = months_short_names(parseInt(dates[1]));
    if (items.item.mandate_id == null) return null;

    return (
      <View
        style={{
          padding: 10,
          borderRadius: 5,
          borderWidth: 1,
          borderColor: "#410DAA1A",
          marginBottom: 20,
        }}
      >
        <View>
          <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
            <View style={{ flex: 0.1 }}>
              <Image
                source={{ uri: items.item.logo_url }}
                resizeMode="contain"
                style={{
                  height: 30,
                  width: 30,
                  alignSelf: "flex-start",
                  borderWidth: 1,
                  borderColor: "#f2f2f2",
                  borderRadius: 5,
                  padding: 5,
                }}
              />
            </View>
            <View style={{ flex: 0.6, paddingLeft: 15 }}>
              <Text
                style={{ fontSize: 14, color: "#121212", fontWeight: "bold" }}
              >
                {items.item.bank_name}
              </Text>
              <Text style={{ fontSize: 12, color: "#A8A8A8", paddingTop: 5 }}>
                AC/NO {account}
              </Text>
            </View>
            <View style={{ flex: 0.3, alignItems: "flex-end" }}>
              <Text
                style={{ fontWeight: "bold", fontSize: 12, color: "#121212" }}
              >
                {items.item.amount}
                {" / day"}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 5,
              marginTop: 15,
            }}
          >
            <Text style={{ fontSize: 13, color: "#A8A8A8" }}>
              Mandate ID :{" "}
            </Text>
            <Text style={{ fontSize: 13, color: "#410DAA" }}>
              {items.item.mandate_id}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 5,
            }}
          >
            <Text style={{ fontSize: 13, color: "#A8A8A8" }}>
              Mandate type :{" "}
            </Text>
            <Text style={{ fontSize: 13, color: "#121212" }}>
              {this.getMandateTypeName(items.item.mandate_type)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 5,
            }}
          >
            <Text style={{ fontSize: 13, color: "#A8A8A8" }}>Status : </Text>
            <Text style={{ fontSize: 13, color: "#121212" }}>
              {items.item.status}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 5,
            }}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 0.6 }}
            >
              <Text style={{ fontSize: 13, color: "#A8A8A8" }}>Date : </Text>
              <Text style={{ fontSize: 13, color: "#121212" }}>
                {day + "-" + month + "-" + year}
              </Text>
            </View>
            {
              items.item.mandate_approve_url ? (
                <View style={{ flex: 0.4, alignItems: "flex-end" }}>
                  <TouchableOpacity
                    onPress={() => {
                      if (items.item.status != "Rejected") {
                        if (!isMobile) {
                          // console.log("DEBUG ABHI: ",items.item);
                          global.open(items.item.mandate_approve_url, "_blank");
                        } else {
                          this.setState({
                            showHTMLContent: items.item.mandate_approve_url,
                            showWebViewBool: true,
                          });
                        }
                      }
                    }}
                    style={{
                      backgroundColor:
                        items.item.status == "Rejected" ? "gray" : "#410DAA",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 6,
                    }}
                  >
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 11,
                        paddingVertical: 6,
                        paddingHorizontal: 15,
                      }}
                    >
                      Approve Mandate
                    </Text>
                  </TouchableOpacity>
                </View>  
              ) : (null)
            }
          </View>
        </View>
      </View>
    );
  };

  handlePressBack = () => {
    this.props.navigation.navigate("Notifications");
  };

  render() {
    if (this.state.showWebViewBool) {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Complete Setup"]}
            onPressBack={() => this.setState({ showWebViewBool: false })}
          />
          <StatusBar
            backgroundColor={"#410DAA"}
            barStyle={
              Platform.OS == "android" ? "light-content" : "dark-content"
            }
          />

          <WebView source={{ uri: this.state.showHTMLContent }} />
        </SafeAreaView>
      );
    } else {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Attempted Mandates"]}
            onPressBack={this.handlePressBack}
          />
          <StatusBar
            backgroundColor={"#410DAA"}
            barStyle={
              Platform.OS == "android" ? "light-content" : "dark-content"
            }
          />
          <ScrollView
            style={{
              paddingLeft: isMobile ? "5%" : "15%",
              paddingRight: isMobile ? "5%" : "15%",
              marginTop: 20,
            }}
          >
            {this.state.loading ? (
              <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" color="#410DAA" />
              </View>
            ) : (
              <>
                <FlatList
                  data={this.state.mandates}
                  renderItem={this._renderView}
                  keyExtractor={(item, index) => index.toString()}
                  contentContainerStyle={{
                    paddingBottom: 50,
                    paddingLeft: isMobile ? "0%" : "20%",
                    paddingRight: isMobile ? "0%" : "20%",
                  }}
                />
              </>
            )}
          </ScrollView>
        </SafeAreaView>
      );
    }
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    padding: 16,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  flatlistLoader: { marginVertical: 5 },
});

export default MandatesPending;
