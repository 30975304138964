const INITIAL_STATE = {
    demoMode: false
};

const testConfig = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case 'UPDATE_TEST_CONFIG':
            const { demoMode } = action.payload;
            return { ...state, demoMode };
        default:
            return state;
    }
};

export default testConfig;