import React from "react";
import {
  View,
  StatusBar,
  ScrollView,
  StyleSheet,
  SafeAreaView,
  Platform,
  Image,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { Button, Text, RadioButton } from "react-native-paper";
import { connect } from "react-redux";
import amplitude from "../../../utils/amplitude";
import theme from "../../../static/theme";
import { updateLastSeenScreen } from "../../../actions/finplan";
import { getFinplanDetails, postMaritalStatus } from "../../../api/finplan";
import { isMobile } from "../../../utils";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import NavigationHeader from "../../../components/Common/NavigationHeader";
const { width } = Dimensions.get("window");

const RadioOption = ({ onSelect, selected, title }) => {
  return (
    <TouchableOpacity
      onPress={() => {
        onSelect();
      }}
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginVertical: 5,
      }}
    >
      <RadioButton.Android
        onPress={() => {
          onSelect();
        }}
        uncheckedColor={"gray"}
        color={theme.colors.NEW_BRAND_COLOR}
        status={selected ? "checked" : "unchecked"}
        style={{ alignSelf: "flex-start" }}
      />
      <Text
        style={{
          color: selected ? theme.colors.NEW_BRAND_COLOR : "black",
          paddingLeft: 20,
        }}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
};

class MaritalStatus extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let type = null;
    if (params) {
      type = params.type || null;
    }
    this.state = {
      type,
      isLoading: false,
      maritalStatusError: null,
      numberOfChildrenError: null,
      selectedItem: 2,
    };
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("maritalStatus",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("maritalStatus", { path: this.props.navigation.getState().routeName });
      }
      const response = await getFinplanDetails();
      if (response.code == 100) {
        const { results } = response;
        const { is_married } = results;
        this.setState({
          maritalStatus: is_married ? "M" : "S",
          numberOfChildren: "0",
        });
      }
      this.generateData(0, 10);
    } catch (error) {}
  };

  onPressNext = async () => {
    const { numberOfChildren = 0, maritalStatus, type } = this.state;
    let number = numberOfChildren ? parseInt(numberOfChildren) : 0;
    if (numberOfChildren == "zero") {
      number = 0;
    }
    const title = "";
    const from = "";

    if (maritalStatus == null) {
      this.setState({ maritalStatusError: "Select one option!" });
      return;
    }
    if (
      (numberOfChildren == null ||
        (numberOfChildren == "0" && numberOfChildren != "zero")) &&
      maritalStatus != "S"
    ) {
      this.setState({ numberOfChildrenError: "Select the number of children" });
      return;
    }

    this.setState({ isLoading: true });
    const payload = {
      is_single: maritalStatus == "S" ? "true" : "false",
      no_of_kids: number,
    };
    const { params = {} } = this.props.route;
    const { ...restParams } = params;
    const response = await postMaritalStatus(payload);
    if (response.code == 100) {
      if (number == 0 || maritalStatus == "S") {
        let screen = "Goals";
        if (type == "CHILDREN") {
          if (from) {
            screen = from;
          }
        } else if (type == "COMPLETE") {
          screen = "BuyHome";
        }
        this.props.navigation.navigate(`${screen}`, {
          type,
          ...restParams,
        });
      } else {
        this.props.navigation.navigate("ChildrenDetails", {
          no_of_children: number,
          type,
          ...restParams,
        });
      }
    }
    this.setState({ isLoading: false });
  };

  generateData = (min, max) => {
    let childrens = [];
    for (let i = min; i <= max; i++) {
      const num = i;
      if (i == "0") {
        childrens.push({
          label: num.toString(),
          value: "zero",
        });
      } else {
        childrens.push({
          label: num.toString(),
          value: num,
        });
      }
    }
    this.setState({ childrens });
  };

  render() {
    const {
      childrens = [],
      numberOfChildren,
      maritalStatus,
      maritalStatusError = null,
      numberOfChildrenError = null,
    } = this.state;
    return (
      <View style={[styles.page]}>
        <SafeAreaView style={{ flex: 1 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Marital Status"]}
            onPressBack={() => {
              this.props.navigation.goBack();
            }}
          />
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                paddingBottom: 100,
                alignItems: isMobile ? "center" : "flex-start",
                paddingLeft: 400
                
              }}
              alwaysBounceVertical={true}
              keyboardShouldPersistTaps="handled"
              // style={{ flex: 1 }}
            >
                        {/* <View style={isMobile ? {justifyContent: 'center', alignItems: 'center'} :{ marginLeft: "32%" }}> */}
              <View style={{ flex: 1, paddingHorizontal: 16 }}>
                <StatusBar backgroundColor="#410DAA" barStyle="light-content" />
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/marriage_finplan_image.png",
                    }}
                    style={{ width: 150, height: 150, alignSelf: "center" }}
                    resizeMode="contain"
                  />
                </View>
                <View style={{ alignItems: "center", marginTop: 40 }}>
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      marginBottom: 30,
                      color: "#121212",
                    }}
                  >
                    Your Marital Status ?
                  </Text>
                  <View
                    style={{
                      justifyContent: "center",
                      marginVertical: 10,
                    }}
                  >
                    <RadioOption
                      title="Single (as of now)"
                      onSelect={() => {
                        this.setState({
                          maritalStatusError: null,
                          maritalStatus: "S",
                        });
                      }}
                      selected={maritalStatus == "S"}
                    />
                    <RadioOption
                      title="Married"
                      onSelect={() => {
                        this.setState({
                          maritalStatusError: null,
                          maritalStatus: "M",
                        });
                      }}
                      selected={maritalStatus == "M"}
                    />
                  </View>
                </View>
                <Text style={[styles.errorMessage, { alignSelf: "center" }]}>
                  {maritalStatusError || ""}
                </Text>

                {this.state.maritalStatus == "M" && (
                  <View style={{ marginLeft: 10 }}>
                    <Text
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        marginBottom:10
                      }}
                    >
                      Number of children ?
                    </Text>
                    <View style={{ width: 80 }}>
                      <PickerFieldSelect
                        data={childrens}
                        value={
                          this.state.numberOfChildren &&
                          this.state.numberOfChildren.value
                        }
                        label={null}
                        onChange={(value) => {
                          this.setState({ numberOfChildren: value });
                          this.setState({ numberOfChildrenError: "" });
                        }}
                        pickerStyle={[{ width: 150 }]}
                        placeholder={{ value: "SEL", label: "Select" }}
                      />
                    </View>
                  </View>
                )}
                <Text style={[styles.errorMessage, { alignSelf: "center" }]}>
                  {numberOfChildrenError || ""}
                </Text>
              </View>
              <Button
                uppercase={false}
                mode={"contained"}
                loading={this.state.isLoading}
                onPress={this.onPressNext}
                style={styles.nextButton}
                color="#410DAA"
              >
                <Text style={styles.next}>Next</Text>
              </Button>
            </ScrollView>
          {/* </View> */}
        </SafeAreaView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingBottom: 66,
    width: width,
    height: "100%",
  },
  formItem: {},
  inputItem: {
    width: isMobile ? "100%" : "auto",
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  nextButton: {
    width: width < 768 ? "96%" : "15%",
    marginLeft: isMobile ? 5 : "7%",
    marginTop: 30,
    marginBottom: 0,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  errorMessage: {
    color: "red",
    fontSize: 14,
    paddingVertical: 5,
    fontFamily: "Roboto",
  },
});

const mapStateToProps = (state) => {
  return {
    retirementFlowCompleted: state.finPlan.retirementFlowCompleted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLastSeenScreen: (type, screen, basic, end) =>
      dispatch(updateLastSeenScreen(type, screen, basic, end)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaritalStatus);
