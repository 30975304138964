const { default: Modal } = require("./Modal");
import React from "react";
import {View,Text,TouchableOpacity, StyleSheet, Platform, Image} from "react-native";
import AntDesign from '@expo/vector-icons/AntDesign';
import { isMobile } from "../../utils";

const ViewSampleImage = ({ title, uri, style = {}, imageStyle={}, onclose })=>{
    return(
        <Modal style={[styles.container, { ...style }]} containerStyle={styles.containerStyle}>
            <View style={{minWidth: isMobile? 240: 617}}>
                <AntDesign name="close" size={16} color="#00000050" style={styles.close} onPress={()=>{onclose && onclose()}}/>
                {title && <Text style={styles.title}>{title}</Text>}
                <Image source={{uri: uri }} style={[styles.imageStyle,{...imageStyle}]}/>
            </View>
        </Modal>
    )
}

export default ViewSampleImage;

const styles = StyleSheet.create({
    container: {justifyContent: 'flex-start', paddingTop: 200, alignItems: 'center'},
    containerStyle: {marginHorizontal:8,paddingHorizontal:42,paddingRight:20,paddingVertical:15, borderRadius: 12},
    close: {position:"absolute",top:0,right:0},
    imageStyle:{
        width: isMobile? 200 : 303,
        height: isMobile ? 120 : 202,
        resizeMode: "contain",
        alignSelf:"center"
    },
    title: {
        fontSize: 15,
        fontFamily: "Roboto",
        fontWeight: "700",
        marginBottom: 16,
        color: "#000000",
        textAlign: "center"
    }
});