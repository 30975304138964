import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import Modal from "./Modal";
import { AntDesign } from "@expo/vector-icons";
import { isMobile } from "../../utils";
import Timeline from "react-native-timeline-flatlist";

export default class PlacePaymentAndOTP extends React.Component {
  constructor(porps) {
    super(porps);
    const { showPayment } = this.props;
    // console.log(showPayment);
    this.state = {
      showModel: false,
      imageName: "",
      showPayment:
        showPayment != undefined && showPayment != null ? showPayment : true,
      modalTabName: "",
    };
    this.dataOTP = [
      {
        title: "Choose Your Mobile or Email from dropdown",
        description: "👉🏻 Preview Screen",
      },
      { title: "Generate OTP", description: "👉🏻 Preview Screen" },
      { title: "Enter OTP and click submit", description: "👉🏻 Preview Screen" },
    ];
    this.dataPayment = [
      { title: "Choose Payment Mode", description: "👉🏻 Preview Screen" },
      { title: "Click on INITIATE PAYMENT", description: "👉🏻 Preview Screen" },
      {
        title: "In the new page, click  PAYMENT REQUEST",
        description: "👉🏻 Preview Screen",
      },
    ];
  }

  handleShowImage = (item) => {
    let imageName = "";
    let modalTabName = "";
    if (item === "Choose Your Mobile or Email from dropdown") {
      modalTabName = "1. Choose Your Mobile or Email from Dropdown";
      imageName =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/drop_down.png";
    }
    if (item === "Generate OTP") {
      modalTabName = "2. GENERATE OTP";
      imageName =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/genrate-otp.png";
    }
    if (item === "Enter OTP and click submit") {
      modalTabName = "3. Enter OTP and click Submit";
      imageName =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/enter-otp.png";
    }
    if (item === "Choose Payment Mode") {
      modalTabName = "1. Choose Payment Mode";
      imageName =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/payment_mode.png";
    }
    if (item === "Click on INITIATE PAYMENT") {
      modalTabName = "2. Click on INITIATE PAYMENT";
      imageName =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/initiate-payment.png";
    }
    if (item === "In the new page, click  PAYMENT REQUEST") {
      modalTabName = "3. In the new page, click  PAYMENT REQUEST";
      imageName =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/req-payment.png";
    }
    this.setState({
      showModel: true,
      imageName,
      modalTabName,
    });
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView style={styles.otpContainer}>
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 24,
                marginBottom: 50,
                fontWeight: "700",
              }}
            >
              Step 1 - OTP Approval
            </Text>
            <Timeline
              data={this.dataOTP}
              onEventPress={(event) => {
                // console.log(JSON.stringify(event));
                this.handleShowImage(event.title);
              }}
              showTime={false}
            />
            <TouchableOpacity
              style={styles.btn}
              onPress={this.props.handleOTPApproval}
            >
              {/* { this.props.otploading ? <ActivityIndicator size="large" color="#fff" /> : */}
              <Text style={{ color: "#fff" }}>Go To OTP Approval</Text>
            </TouchableOpacity>
            {this.props.otploading && (
              <View style={{ textAlign: "center", marginTop: 10 }}>
                <Text>Opened a new page for OTP approval.</Text>
                <Text>
                  <TouchableOpacity onPress={this.props.handleOTPPageNotOpen}>
                    <Text style={{ color: "blue" }}>Click this</Text>
                  </TouchableOpacity>{" "}
                  if page did not open. Make sure pop up blocker is disabled.
                </Text>
              </View>
            )}
            {this.state.showPayment ? (
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 24,
                    marginBottom: 50,
                    fontWeight: "700",
                  }}
                >
                  Step 2 - Payment
                </Text>
                <Timeline
                  data={this.dataPayment}
                  onEventPress={(event) => {
                    // console.log(JSON.stringify(event));
                    this.handleShowImage(event.title);
                  }}
                  showTime={false}
                />
                <TouchableOpacity
                  style={styles.btn}
                  onPress={this.props.handlePayment}
                >
                  {/* { this.props.paymentLoading ? <ActivityIndicator size="large" color="#fff" /> : */}
                  <Text style={{ color: "#fff" }}>Go To Payment Page</Text>
                </TouchableOpacity>
              </View>
            ) : (
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 50,
                }}
              >
                {this.props.error && (
                  <Text
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    you haven't authenticate any order you placed. kindly
                    authenticate the order before you click on continue
                  </Text>
                )}
                <TouchableOpacity
                  style={{
                    backgroundColor: "#410DAA",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 10,
                    paddingBottom: 8,
                    width: 150,
                    marginBottom: 20,
                  }}
                  onPress={this.props.handleContinue}
                >
                  <Text style={{ color: "white" }}>Continue</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </ScrollView>

        {this.state.showModel && (
          <Modal
            style={{
              top: 0,
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            containerStyle={{
              marginHorizontal: isMobile ? 16 : 0,
              borderRadius: 5,
            }}
          >
            <View
              style={{
                paddingTop: isMobile ? 16 : 24,
                paddingBottom: isMobile ? 17 : 23,
                paddingHorizontal: isMobile ? 5 : 33,
                minWidth: isMobile ? 200 : 600,
                minHeight: isMobile ? 200 : 400,
                marginHorizontal: isMobile ? 16 : 0,
              }}
            >
              <View
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <Text style={{ fontWeight: "700", fontSize: 15, width: "95%" }}>
                  {this.state.modalTabName}
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showModel: false });
                  }}
                >
                  <AntDesign
                    name="close"
                    size={24}
                    color="rgba(0, 0, 0, 0.5)"
                  />
                </TouchableOpacity>
              </View>
              <View style={{ flex: 1 }}>
                <Image
                  resizeMode="cover"
                  style={{
                    width: "100%",
                    height: 250,
                    resizeMode: "cover",
                    borderWidth: 2,
                  }}
                  source={{ uri: `${this.state.imageName}` }}
                  alt="Something went wrong"
                />
              </View>
            </View>
          </Modal>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  otpContainer: {
    width: "100%",
    height: "90%",
  },
  paymentContainer: {
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  btn: {
    backgroundColor: "#410DAA",
    width: "30%",
    height: 50,
    borderWidth: 2,
    borderColor: "#000",
    borderRadius: 50,
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: 50,
    marginBottom: 50,
  },
});
