import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Dimensions } from "react-native";
import { ComplianceGroups } from "../../components/InvestmentOverview/data";
import { md } from "../../Responsive";
import { isMobile } from "../../utils";
const { width } = Dimensions.get("window");
import { AntDesign } from "@expo/vector-icons";
import { MFComplianceGroups } from "../mutualfunds/mindetails/mfComplianceModal";

const Section = ({
  title,
  style = {},
  titleWidth = 0,
  done,
  current,
  completedPercentage,
}) => {
  return (
    <View style={{ width: `${titleWidth.toFixed(1)}%`, ...style }}>
      <Text
        style={{
          textAlign: "center",
          color: "#464E5F",
          fontSize: isMobile ? 13 : 16,
          fontWeight: "bold",
          marginBottom: 13,
        }}
      >
        {title}
      </Text>
      <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
        <View
          style={[
            styles.line2,
            {
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginVertical: 10,
            },
          ]}
        >
          <View
            style={{
              width: done ? "100%" : `${completedPercentage.toFixed(1)}%`,
              backgroundColor: "#410DAA",
              height: 4,
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            }}
          />
          <View
            style={[
              styles.circle1,
              {
                alignSelf: "center",
                borderColor: done ? "#16C3A9" : "gray",
                borderWidth: done ? 2 : 1,
              },
            ]}
          >
            {!current && (
              <AntDesign
                name={done ? "checkcircle" : "checkcircleo"}
                size={done ? 16 : 15}
                color={done ? "#16C3A9" : "gray"}
              />
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

const StockSections = [
  { redirect_link: "/onboarding/pan", redirect_screen: "PanDetails" },
  { redirect_link: "/onboarding/contactinfo", redirect_screen: "ContactInfo" },
  { redirect_link: "/onboarding/nomineeinfo", redirect_screen: "NomineeInfo" },
  {
    redirect_link: "/onboarding/financialinfo",
    redirect_screen: "FinancialInfo",
  },
  { redirect_link: "/onboarding/riskprf", redirect_screen: "RiskProfile" },
  { redirect_link: "/onboarding/assets", redirect_screen: "Assets" },
  { redirect_link: "/onboarding/esign", redirect_screen: "eSign" },
  { redirect_link: "/onboarding/payment", redirect_screen: "Payment" },
  { redirect_link: "/onboarding/bankaccinfo", redirect_screen: "BankAccount" },
];

const MFSections = [
  {
    is_completed: true,
    redirect_link: "mindetails/pan",
    redirect_screen: "MinDetailsPan",
  },
  {
    is_completed: false,
    redirect_link: "mindetails/ekyc",
    redirect_screen: "MinDetailsEKYC",
  },
  {
    is_completed: true,
    redirect_link: "mindetails/personal",
    redirect_screen: "MinDetailsPersonal",
  },
  {
    is_completed: false,
    redirect_link: "mindetails/address",
    redirect_screen: "MinDetailsAddress",
  },
  {
    is_completed: true,
    redirect_link: "mindetails/nominee",
    redirect_screen: "MinDetailsNominee",
  },
  {
    is_completed: true,
    redirect_link: "mindetails/professional",
    redirect_screen: "MinDetailsProfessional",
  },
  {
    is_completed: false,
    redirect_link: "mindetails/signature",
    redirect_screen: "MinDetailsSignature",
  },
  {
    is_completed: false,
    redirect_link: "mindetails/bank",
    redirect_screen: "MinDetailsBank",
  },
];

export const ProgressbarSTU = ({ currentScreen = null, flow = "STOCKS" }) => {
  const [pendingItems, setPendingItems] = useState({});
  const [sectionWidths, setSectionWidths] = useState({
    titleWidth: null,
    completedPercentage: null,
  });

  const getTabNames = async () => {
    try {
      const results = flow == "MF" ? MFSections : StockSections;
      const titlesData = flow == "MF" ? MFComplianceGroups : ComplianceGroups;
      results.forEach((item) => {
        const { redirect_screen } = item;
        const title = titlesData[redirect_screen] || "";
        if (!(title in pendingItems)) {
          pendingItems[title] = {};
          pendingItems[title]["no_of_screens"] = 1;
          pendingItems[title]["current_screen"] = 0;
          pendingItems[title]["done"] = false;
          pendingItems[title]["redirect_screen"] = redirect_screen;
        } else {
          pendingItems[title]["no_of_screens"] += 1;
        }

        pendingItems[title]["current_screen"] =
          redirect_screen == currentScreen
            ? pendingItems[title]["no_of_screens"]
            : pendingItems[title]["current_screen"];
      });

      const sectionsTitleKeys = Object.keys(pendingItems);

      sectionsTitleKeys.forEach((title) => {
        const titleIndex = sectionsTitleKeys.indexOf(
          titlesData[pendingItems[title]["redirect_screen"]] || ""
        );
        const currentTitleIndex = sectionsTitleKeys.indexOf(
          titlesData[currentScreen] || ""
        );
        pendingItems[title]["done"] = titleIndex < currentTitleIndex;
      });

      let sectionTitlesLengths = sectionsTitleKeys.map((s) => s.length);
      const totalLength = sectionTitlesLengths.reduce((a, b) => a + b);
      const sectionTitlesPercentages = sectionTitlesLengths.map(
        (l) => (l / totalLength) * 100
      );
      const sectionsBorderPercentage = sectionsTitleKeys.map((title) => {
        const { no_of_screens = 1, current_screen = 0 } = pendingItems[title];
        return (current_screen / no_of_screens) * 100;
      });

      setSectionWidths({
        titleWidth: sectionTitlesPercentages,
        completedPercentage: sectionsBorderPercentage,
      });
      setPendingItems(pendingItems);
    } catch (error) {}
  };

  useEffect(() => {
    getTabNames();
  }, []);

  return (
    <View style={{ backgroundColor: "#fff" }}>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          paddingTop: 27,
        }}
      >
        {Object.keys(pendingItems).map((item, index) => {
          return (
            <Section
              style={{}}
              title={item}
              titleWidth={sectionWidths["titleWidth"][index]}
              completedPercentage={sectionWidths["completedPercentage"][index]}
              index={index}
              done={pendingItems[item]["done"]}
            />
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  bod: {
    backgroundColor: "#ffffff",
    display: "flex",
    paddingTop: md(width) ? 27 : 0,
  },
  circle1: {
    width: 15,
    height: 15,
    backgroundColor: "#ffffff",
    borderRadius: 7.5,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderColor: "#410DAA",
    zIndex: 1,
  },
  circle2: {
    width: 7,
    height: 7,
    backgroundColor: "#410DAA",
    borderRadius: 3.5,
  },
  circleContainer: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    top: 0,
  },
  line1: {
    flex: 1,
    height: 4,
    backgroundColor: "#410DAA",
  },
  line2: {
    flex: 1,
    height: 4,
    backgroundColor: "#E7E1E1",
  },
  activeLineColor: {
    backgroundColor: "#410DAA",
  },
  notActiveLineColor: {
    backgroundColor: "#E7E1E1",
  },
  endLineColor: {
    backgroundColor: "#410DAA",
  },
  activeCircle: {
    borderColor: "#410DAA",
  },
  notActiveCircle: {
    borderColor: "#E7E1E1",
    borderWidth: 1,
  },
  notActiveCircle2: {
    backgroundColor: "#FFF",
  },
  activeCircle2: {
    backgroundColor: "#410DAA",
  },
  initial: {
    height: md(width) ? 4 : 3,
    flex: 1,
  },
  cont_info: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  outercircle: {
    width: md(width) ? 15 : 11,
    height: md(width) ? 15 : 11,
    backgroundColor: "#ffffff",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
  },
  innercircle: {
    width: md(width) ? 7 : 5,
    height: md(width) ? 7 : 5,
    borderRadius: 10,
  },
});
