import React from "react";
import {
  View,
  Image,
  StatusBar,
  Platform,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Linking,
} from "react-native";
const WebView =
  Platform.OS == "web"
    ? require("react-native-web-webview").WebView
    : require("react-native-webview").WebView;
import { Text, Button } from "react-native-paper";
import Checkbox from "expo-checkbox";
import { capitalizeFirstLetter, getUserDetails, isMobile } from "../../utils";
import Icon from "@expo/vector-icons/FontAwesome";
import SearchBar from "../../components/Common/SearchBar";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;
// import ReactExport from "react-export-excel";
import {
  createSendyActionForCustomer,
  getEmailTemplateData,
  getCustomerDetails,
  getEmpQuizData,
  getFinancialWellnessIndex,
} from "../../api";
import Store from "../../store";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import moment from "moment";
import TakenTable from "./TakenTable";
import NavigationHeader from "../../components/Common/NavigationHeader";
import Modal from "../../components/Common/Modal";
import { AntDesign } from "@expo/vector-icons";
import PickerFieldSelect from "../../components/Common/Pickers/PickerFieldSelect";
import { saveAs } from "file-saver";
import ProgressBar from "../../utils/progressBar";
import { Popable } from "react-native-popable";
import amplitude from "../../utils/amplitude";
import EmployeeTable from "./EmployeeTable";
import { ShowToast } from "../../components/Common/Toast";

class FinancialWellnessIndex extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    const { params } = this.props.route;
    if (params) {
      userId = params.user_id || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }

    this.state = {
      showModal: false,
      indexValue: 0,
      wellnessIndexResp: [],
      totalCustomer: 0,
      overAllScore: 0,
      weightedAvg: 0,
      isLoading: false,
      isNewAssessmentEnabled: false,
      isAssessmentDetailsEnabled: false,
      isAllCheckboxSelected: false,
      selectedTeam: 0,
      toastMsg: "Something Went Wrong",
      searchTeamList: [],
      showUserList: false,
      sorting_field: null,
      sorting_value: null,
      showToast: false,
      userId,
      htmlEmailTemplate: "<h1>Nothing to Show</h1>",
      activeTab: "Not Taken",
      userName: "",
      searchAssessmentTakenText: "",
      searchAssessmentNotTakenText: "",
      headerText: "",
      assessmentTakenDataValue: [],
      assessmentNotTakenDataValue: [],
      searchAssessmentTakenResults: [],
      searchAssessmentNotTakenResults: [],
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("FinancialWellnessIndex",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("FinancialWellnessIndex", { path: this.props.navigation.getState().routeName });
      }
      let userData = {};
      this.setState({isLoading: true})
      if (this.state.userId) {
        userData = await getCustomerDetails(this.state.userId);
      } else {
        userData = await getUserDetails();
      }
      let wellnessIndexResp = await getFinancialWellnessIndex(
        userData.corporate_id
      );
      this.setState({isLoading: false})
      let newArr = [];
      newArr = wellnessIndexResp.result.quizzes;

      let quiz_taken_perc = 0
      wellnessIndexResp.result.quizzes.forEach((quiz) => {
        quiz_taken_perc += quiz.quiz_taken_perc
      })
      this.setState({ weightedAvg: parseInt(quiz_taken_perc / wellnessIndexResp.result.quizzes.length) });
      this.setState({
        wellnessIndexResp: newArr,
        totalCustomer: wellnessIndexResp.result.total_customers,
        overAllScore: parseInt(wellnessIndexResp.result.awareness_score),
      });
      // console.log("DEBUG::ComponentDidMount is " + JSON.stringify(newArr))
      let { first_name } = userData;
      let names = first_name ? first_name.split(/\s+/) : [];
      let name = "";
      if (names.length >= 2 && names.length > 0) {
        name =
          capitalizeFirstLetter(names[0]) + " " + capitalizeFirstLetter(names[1]);
      } else {
        if (names.length == 0) {
          name = "User";
        } else {
          name = names[0];
        }
      }
      this.setState({ userName: name });
    } catch (e) {
      console.log("DEBUG::ERROR is " + JSON.stringify(e));
    }
  }

  SelectTabDetails = ({ item, onPress, backgroundColor, color }) => {
    return (
      <TouchableOpacity
        style={{
          borderWidth: 0.5,
          borderColor: "#BCBCBC",
          borderRadius: 18,
          backgroundColor,
          marginRight: 16,
          paddingLeft: 20,
          paddingRight: 20,
          height: 36,
          alignItems: "center",
          justifyContent: "center",
        }}
        onPress={onPress}
      >
        <Text style={{ fontSize: 14, color }}>{item}</Text>
      </TouchableOpacity>
    );
  };

  handleAssessmentTakenSort = (field, sort, start, end) => {
    const sorting_field = field;
    const sorting_value = !sort;

    const { searchAssessmentTakenResults } = this.state;
    const sortedData = [].concat(
      searchAssessmentTakenResults.slice(0, start),
      this.getSortedData(
        searchAssessmentTakenResults,
        sorting_field,
        sorting_value,
        start,
        end,
        "assessmenttaken"
      ),
      searchAssessmentTakenResults.slice(
        end,
        searchAssessmentTakenResults.length
      )
    );

    this.setState({
      sorting_field,
      sorting_value,
      searchAssessmentTakenResults: sortedData,
    });
  };

  handleAssessmentNotTakenSort = (field, sort, start, end) => {
    const sorting_field = field;
    const sorting_value = !sort;

    const { searchAssessmentNotTakenResults } = this.state;
    const sortedData = [].concat(
      searchAssessmentNotTakenResults.slice(0, start),
      this.getSortedData(
        searchAssessmentNotTakenResults,
        sorting_field,
        sorting_value,
        start,
        end,
        "assessmentnottaken"
      ),
      searchAssessmentNotTakenResults.slice(
        end,
        searchAssessmentNotTakenResults.length
      )
    );

    this.setState({
      sorting_field,
      sorting_value,
      searchAssessmentNotTakenResults: sortedData,
    });
  };

  handleAssessmentTakenSearch = (searchAssessmentTakenText) => {
    let searchAssessmentTakenResults = [];
    const searchData = this.state.assessmentTakenDataValue;

    if (searchAssessmentTakenText) {
      searchAssessmentTakenResults = searchData.filter((assessment) => {
        return assessment.name
          .toLowerCase()
          .startsWith(searchAssessmentTakenText.toLowerCase());
      });
    } else {
      searchAssessmentTakenResults = searchData;
    }
    if (searchAssessmentTakenResults.length) {
      this.setState({
        searchAssessmentTakenText,
        searchAssessmentTakenResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  handleAssessmentNotTakenSearch = (searchAssessmentNotTakenText) => {
    let searchAssessmentNotTakenResults = [];
    const searchData = this.state.assessmentNotTakenDataValue;

    if (searchAssessmentNotTakenText) {
      searchAssessmentNotTakenResults = searchData.filter((assessment) => {
        let searchText = assessment.name + assessment.teamName;
        return searchText
          .toLowerCase()
          .includes(searchAssessmentNotTakenText.toLowerCase());
      });
    } else {
      this.setState({ selectedTeam: 0 });
      searchAssessmentNotTakenResults = searchData;
    }
    if (searchAssessmentNotTakenResults.length) {
      let selectedCheckboxs = [];
      searchAssessmentNotTakenResults.forEach((item) => {
        selectedCheckboxs.push({ ...item, toShow: false });
      });
      this.setState({
        selectedCheckboxs,
        searchAssessmentNotTakenText,
        searchAssessmentNotTakenResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  // handleAssessmentNotTakenSearch = (searchAssessmentNotTakenText) => {
  //   let searchAssessmentNotTakenResults = [];
  //   const searchData = this.state.assessmentNotTakenDataValue;

  //   if (searchAssessmentNotTakenText) {
  //     searchAssessmentNotTakenResults = searchData.filter((assessment) => {
  //       return assessment.name
  //         .toLowerCase()
  //         .startsWith(searchAssessmentNotTakenText.toLowerCase());
  //     });
  //   } else {
  //     searchAssessmentNotTakenResults = searchData;
  //   }
  //   if (searchAssessmentNotTakenResults.length) {
  //     this.setState({
  //       searchAssessmentNotTakenText,
  //       searchAssessmentNotTakenResults,
  //       sorting_field: null,
  //       sorting_value: null,
  //     });
  //   }
  // };

  compareStrings = (a, b) => {
    const x = a.toLowerCase();
    const y = b.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  compareNumbers = (a, b) => {
    return a - b;
  };

  getSortedData = (data, sorting_field, sorting_value, start, end) => {
    if (data.length != 0) {
      const fieldValue = data[0][sorting_field];
      const sortingType = isNaN(fieldValue) ? "string" : "number";

      data = data.slice(start, end);
      if (sortingType == "string") {
        data.sort((a, b) =>
          this.compareStrings(a[sorting_field], b[sorting_field])
        );
      } else {
        data.sort((a, b) =>
          this.compareNumbers(a[sorting_field], b[sorting_field])
        );
      }

      return sorting_value ? data : data.reverse();
    } else {
      return data;
    }
  };

  getAssessmentTakenHeaderData = (data) => {
    const { sorting_field, sorting_value } = this.state;

    const keysData = [
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Mobile Number",
        key: "mobile",
      },
      {
        label: "Email",
        key: "email",
      },
      {
        label: "Quiz Score",
        key: "quizScore",
      },
      {
        label: "Persona Name",
        key: "personaName",
      },
    ];

    let headerAssessmentTakenData = [];
    keysData.forEach((headerItem) => {
      headerAssessmentTakenData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: true,
        onSort: this.handleAssessmentTakenSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    let valuesAssessmentTaken = [];
    data.forEach((value) => {
      valuesAssessmentTaken.push([
        {
          valueToDisplay: value.name,
          value: value.name,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.mobile,
          value: value.mobile,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.email,
          value: value.email,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: parseInt(value.quizScore),
          value: parseInt(value.quizScore),
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.personaName,
          value: value.personaName,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ]);
    });

    return { headerAssessmentTakenData, valuesAssessmentTaken };
  };

  handleSendReminder = async () => {
    let payload = { 
      "listname" : "FINANCIAL_WELLNESS_REMAINDER",
      "index_name": `${this.state.headerText}`,
      "action": "RPL",
      "recipients": ""
    }
    // console.log(JSON.stringify(this.state.selectedCheckboxs));
    this.state.selectedCheckboxs.forEach((data)=> {
      if (data.toShow) {
      payload["recipients"] += data.customerId+",";
      }
    })
    // console.log(payload)
    if (payload["recipients"]  === "") {
      alert("No user selected");
      return
    }
    let response = await createSendyActionForCustomer(payload);
    // console.log(response);
    if(response.code === "100"){
      this.setState({showModal: false, showToast: true, toastMsg: response.result})
    }
  }

  getAssessmentNotTakenHeaderData = (data) => {
    const { sorting_field, sorting_value } = this.state;
    // console.log(data);
    const keysData = [
      {
        component: true,
        label: (
          <View>
            <Checkbox
              status={
                this.state.isAllCheckboxSelected ? "checked" : "unchecked"
              }
              color={this.state.isAllCheckboxSelected ? "#410DAA" : "gray"}
              value={this.state.isAllCheckboxSelected}
              onValueChange={() => {
                let checkbox = this.state.selectedCheckboxs;
                if (this.state.isAllCheckboxSelected) {
                  checkbox.forEach((item) => {
                    item.toShow = false;
                  });
                } else {
                  checkbox.forEach((item) => {
                    item.toShow = true;
                  });
                }
                this.setState({ selectedCheckboxs: checkbox });
                this.setState({
                  isAllCheckboxSelected: !this.state.isAllCheckboxSelected,
                });
              }}
              style={{
                width: isMobile ? 13 : 14,
                height: isMobile ? 13 : 14,
                color: this.state.selectedCheckboxs ? "#410DAA" : "gray",
              }}
            />
          </View>
        ),
      },
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Mobile Number",
        key: "mobile",
      },
      {
        label: "Email",
        key: "email",
      },
      {
        label: "Employee Id",
        key: "employeeId",
      },
      {
        label: "Team Name",
        key: "teamName",
      },
    ];

    let headerAssessmentNotTakenData = [];
    keysData.forEach((headerItem, index) => {
      headerAssessmentNotTakenData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: index == 0 ? false : true,
        onSort: this.handleAssessmentNotTakenSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    let valuesAssessmentNotTaken = [];
    data.forEach((value, index) => {
      valuesAssessmentNotTaken.push([
        {
          component: true,
          value: (
            <Checkbox
              status={ 
                this.state.selectedCheckboxs[index].toShow
                  ? "checked"
                  : "unchecked"
              }
              color={
                this.state.selectedCheckboxs[index].toShow ? "#410DAA" : "gray"
              }
              value={this.state.selectedCheckboxs[index].toShow}
              onValueChange={() => {
                let checkbox = this.state.selectedCheckboxs;
                if (checkbox[index]) {
                  this.setState({ isAllCheckboxSelected: false });
                }

                checkbox[index].toShow = !checkbox[index].toShow;
                this.setState({ selectedCheckboxs: checkbox });
              }}
            />
          ),
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.name ? value.name : "User",
          value: value.name ? value.name : "User",
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.mobile,
          value: value.mobile,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.email,
          value: value.email,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.employeeId,
          value: value.employeeId,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.teamName,
          value: value.teamName,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ]);
    });

    return { headerAssessmentNotTakenData, valuesAssessmentNotTaken };
  };

  handleEmailTemplateData = async () => {
    let payload = {
      "stage_name": "FINANCIAL_WELLNESS_REMAINDER",
      "index_name": `${this.state.headerText}`
    }
    let response = await getEmailTemplateData(payload);
    // console.log(response)
    // console.log(JSON.stringify(response))
    this.setState({ 
      htmlEmailTemplate: `${response.result.email_message}`,
      showModal: true,
      showUserList: false
     });
  }

  render() {
    const { headerAssessmentTakenData, valuesAssessmentTaken } =
      this.getAssessmentTakenHeaderData(
        this.state.searchAssessmentTakenResults
      );

    const { headerAssessmentNotTakenData, valuesAssessmentNotTaken } =
      this.getAssessmentNotTakenHeaderData(
        this.state.searchAssessmentNotTakenResults
      );

    let excelAssessmentTakenData = [];
    this.state.searchAssessmentTakenResults.forEach((item) => {
      excelAssessmentTakenData.push({
        name: item.name,
        personaName: item.personaName,
        mobile: item.mobile,
        email: item.email,
        quizScore: item.quizScore,
      });
    });

    let excelAssessmentNotTakenData = [];
    this.state.searchAssessmentNotTakenResults.forEach((item) => {
      excelAssessmentNotTakenData.push({
        name: item.name,
        teamName: item.teamName,
        mobile: item.mobile,
        email: item.email,
        employeeId: item.employeeId,
      });
    });
    return this.state.isLoading ? <ProgressBar msg={"Loading wellness data..."} /> : (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {!this.state.isNewAssessmentEnabled &&
          !this.state.isAssessmentDetailsEnabled && (
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={["Financial Wellness Index"]}
              onPressBack={() => {
                this.props.navigation.goBack();
              }}
            />
          )}
        {this.state.isNewAssessmentEnabled && (
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Request for New Assessment"]}
            onPressBack={() => {
              this.setState({
                isNewAssessmentEnabled: false,
              });
            }}
          />
        )}
        {this.state.isAssessmentDetailsEnabled && (
          <NavigationHeader
            navigation={this.props.navigation}  
            navigationHeader={["Financial Wellness Index",this.state.headerText]}
            onPressBack={() => {
              this.setState({
                isAssessmentDetailsEnabled: false,
              });
            }}
          />
        )}
        {this.state.showToast == true ? (
          <ShowToast
            showToast={this.state.showToast}
            type="status"
            title={this.state.toastMsg}
            duration="10"
          />
        ) : null}
        {!this.state.isNewAssessmentEnabled &&
          !this.state.isAssessmentDetailsEnabled && (
            <ScrollView
              contentContainerStyle={{ flexGrow: 1 }}
              style={styles.cardContainer}
              showsVerticalScrollIndicator={false}
            >

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >

                <View
                  style={{
                    alignItems: "flex-start",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    width: '100%',
                    margin: 10,
                    marginBottom: 30
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'row', width: isMobile ? '100%' : '30%', marginLeft: isMobile ? 0 : 10 }} >
                    <View>
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Donut
                          options={{
                            dataLabels: {
                              enabled: false,
                            },
                            legend: {
                              show: false,
                            },
                            tooltip: { enabled: false },
                            colors: ["#5197ed", "#f5f3fb"],
                            plotOptions: {
                              pie: {
                                donut: {
                                  size: "70%",
                                },
                              },
                            },
                            stroke: {
                              width: 1,
                              colors: ["#5197ed", "#f5f3fb"],
                            },
                          }}
                          series={[this.state.weightedAvg, 100 - this.state.weightedAvg]}
                          sliceColor={["#5197ed", "#f5f3fb"]}
                          type="donut"
                          width="170"
                        />
                        <Text
                          style={{
                            position: 'absolute',
                            left: '45%'
                          }}
                        >
                          {this.state.weightedAvg}%
                        </Text>
                      </View>
                      <Popable
                        style={{
                          minWidth: 200,
                          marginBottom: 20
                        }}
                        position="right"
                        action={isMobile ? "press" : "hover"}
                        content={`Avg Completion is calculated by giving equal weights to all modules`}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              color: "#6C7293",
                              fontWeight: "600",
                              marginBottom: 2,
                            }}
                          >
                            Avg Completion{" "}
                          </Text>
                          <Icon name="info-circle" size={14} color={"black"} />
                        </View>
                      </Popable>
                    </View>

                    <View>
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Donut
                          options={{
                            dataLabels: {
                              enabled: false,
                            },
                            legend: {
                              show: false,
                            },
                            tooltip: { enabled: false },
                            colors: ["#f78411", "#f5f3fb"],
                            plotOptions: {
                              pie: {
                                donut: {
                                  size: "70%",
                                },
                              },
                            },
                            stroke: {
                              width: 1,
                              colors: ["#f78411", "#f5f3fb"],
                            },
                          }}
                          series={[this.state.overAllScore, 100 - this.state.overAllScore]}
                          sliceColor={["#f78411", "#f5f3fb"]}
                          type="donut"
                          width="170"
                        />
                        <Text
                          style={{
                            position: 'absolute',
                            left: "45%"
                          }}
                        >
                          {this.state.overAllScore}
                        </Text>
                      </View>
                      <Popable
                        style={{
                          minWidth: 200,
                          marginBottom: 20,
                        }}
                        position="right"
                        action={isMobile ? "press" : "hover"}
                        content={`Avg Index is calculated by giving equal weights to all modules`}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              color: "#6C7293",
                              fontWeight: "600",
                              marginBottom: 2,
                            }}
                          >
                            Fin Wellness Index{" "}
                          </Text>
                          <Icon name="info-circle" size={14} color={"black"} />
                        </View>
                      </Popable>
                    </View>
                  </View>
                  <View style={{ marginTop: isMobile ? 20 : 0, width: isMobile ? '100%' : '60%', display: 'flex', flexDirection: isMobile ? 'column' : 'row'}}>

                    <Text
                      style={{
                        fontSize: isMobile ? 16 : 20,
                        color: "#464E5F",
                        fontWeight: "700",
                        padding: 10,
                        width: isMobile ? '100%' : '50%'
                      }}
                    >
                      Achieving stability and growth through smart financial habits and goals.
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 100,
                }}
              >
                {this.state.wellnessIndexResp != [] &&
                  this.state.wellnessIndexResp.map((item) => {
                    return (
                      <View
                        style={{
                          marginBottom: 20,
                        }}
                      >
                        <View
                          style={{
                            marginTop: isMobile ? 0 : 15,
                            marginLeft: isMobile ? 0 : "3%",
                            margin: isMobile ? 5 : 0,
                            backgroundColor: "white",
                            width: isMobile ? "100%" : 800,
                            borderWidth: 1,
                            borderRadius: 12,
                            borderColor: "rgba(65, 13, 170, 0.3)",
                            padding: 10,
                          }}
                        >
                          <TouchableOpacity
                            onPress={async () => {
                              this.setState({
                                isLoading: true
                              })
                              let userData = {};
                              if (this.state.userId) {
                                userData = await getCustomerDetails(
                                  this.state.userId
                                );
                              } else {
                                userData = await getUserDetails();
                              }

                              let empQuizResp = await getEmpQuizData({
                                corp_id: userData.corporate_id,
                                quiz_id: item.quiz_id,
                                manager_id: "",
                              });

                              let notTakenData = [];
                              let takenData = [];

                              empQuizResp.result.forEach((item) => {
                                if (item.status === "Taken") {
                                  takenData.push({
                                    name: item.first_name,
                                    quizScore: item.quiz_score,
                                    mobile: item.mobilenumber,
                                    email: item.email,
                                    personaName: item.persona_name,
                                  });
                                }
                                if (item.status === "Not Taken") {
                                  notTakenData.push({
                                    name: item.first_name,
                                    employeeId: item.employee_id,
                                    customerId: item.customer_id,
                                    mobile: item.mobilenumber,
                                    email: item.email,
                                    teamName: item.team_or_department_name,
                                  });
                                }
                              });
                              let selectedCheckboxs = [];
                              notTakenData.forEach((item) => {
                                selectedCheckboxs.push({
                                  ...item,
                                  toShow: false,
                                });
                              });
                              let searchTeamList = [];
                              notTakenData.forEach((item, index) => {
                                if (item.teamName) {
                                  searchTeamList.push({
                                    key: index,
                                    label: item.teamName.toString(),
                                    value: item.teamName,
                                  });
                                }
                              });
                              searchTeamList = searchTeamList.filter(
                                (value, index, self) =>
                                  index ===
                                  self.findIndex((t) => t.value === value.value)
                              );

                              this.setState({
                                searchTeamList,
                                selectedCheckboxs,
                                assessmentTakenDataValue: takenData,
                                assessmentNotTakenDataValue: notTakenData,
                                searchAssessmentTakenResults: takenData,
                                searchAssessmentNotTakenResults: notTakenData,
                                isAssessmentDetailsEnabled: true,
                                headerText: [item.quiz_name],
                                isLoading: false
                              });
                            }}
                          >
                            <View style={{ display: 'flex', flexDirection: isMobile ? "column" : "row", width: '100%' }}>
                              <View
                                style={{
                                  display: "flex",
                                  marginLeft: "1.9%",
                                  marginRight: "1.9%",
                                  flexDirection: "row",
                                  marginTop: "1%",
                                  width: isMobile ? '100%' : '50%',
                                  justifyContent: "space-between",
                                }}
                              >
                                <View
                                  style={{
                                    flex: 1,
                                    alignItems: 'center', 
                                    display: 'flex',
                                    flexDirection: 'row'
                                  }}
                                >
                                  <Image
                                  source={{
                                    uri: `${item.quiz_image}`,
                                  }}
                                  style={{
                                    height: isMobile ? 50 : 75,
                                    width: isMobile ? 50 : 75,
                                    marginRight: 20,
                                    resizeMode: "contain",
                                    marginTop: "1%",
                                  }}
                                />
                                    <Text
                                      style={{
                                        fontSize: isMobile ? 16 : 20,
                                        color: "#464E5F",
                                        fontWeight: "700",
                                      }}
                                    >
                                      {item.quiz_name}
                                    </Text>
                                </View>
                              </View>
                              <View style={{ display: 'flex', flexDirection: "row" }}>
                                <View>
                                  <View
                                    style={{
                                      flex: 1,
                                      alignItems: "flex-end",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <View
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          position: 'absolute',
                                          left: '45%'
                                        }}
                                      >
                                        {parseInt(item.quiz_taken_perc)}%
                                      </Text>
                                      <Donut
                                        options={{
                                          dataLabels: {
                                            enabled: false,
                                          },
                                          legend: {
                                            show: false,
                                          },
                                          tooltip: { enabled: false },
                                          colors: [
                                            "#5197ed",
                                            parseInt(item.quiz_taken_perc) ==
                                              100
                                              ? "#5197ed"
                                              : "#f5f3fb",
                                          ],
                                          plotOptions: {
                                            pie: {
                                              donut: {
                                                size: "70%",
                                              },
                                            },
                                          },
                                          stroke: {
                                            width: 1,
                                            colors: [
                                              "#5197ed",
                                              parseInt(item.quiz_taken_perc) ==
                                                100
                                                ? "#5197ed"
                                                : "#f5f3fb",
                                            ],
                                          },
                                        }}
                                        series={[
                                          parseInt(item.quiz_taken_perc),
                                          100 - parseInt(item.quiz_taken_perc),
                                        ]}
                                        sliceColor={[
                                          "#5197ed",
                                          parseInt(item.quiz_taken_perc) == 100
                                            ? "#5197ed"
                                            : "#f5f3fb",
                                        ]}
                                        type="donut"
                                        width="170"
                                      />
                                    </View>

                                  </View>
                                  <Text style={{
                                    textAlign: 'center',
                                    fontWeight: '700'
                                  }}>{item.quiz_taken_count} out of {this.state.totalCustomer}</Text>
                                </View>
                                <View>
                                  <View
                                    style={{
                                      flex: 1,
                                      alignItems: "flex-end",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <View
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          position: 'absolute',
                                          left: '45%'
                                        }}
                                      >
                                        {parseInt(item.quiz_avg_score)}
                                      </Text>
                                      <Donut
                                        options={{
                                          dataLabels: {
                                            enabled: false,
                                          },
                                          legend: {
                                            show: false,
                                          },
                                          tooltip: { enabled: false },
                                          colors: [
                                            "#f78411",
                                            parseInt(item.quiz_avg_score) ==
                                              100
                                              ? "#f78411"
                                              : "#f5f3fb",
                                          ],
                                          plotOptions: {
                                            pie: {
                                              donut: {
                                                size: "70%",
                                              },
                                            },
                                          },
                                          stroke: {
                                            width: 1,
                                            colors: [
                                              "#f78411",
                                              parseInt(item.quiz_avg_score) ==
                                                100
                                                ? "#f78411"
                                                : "#f5f3fb",
                                            ],
                                          },
                                        }}
                                        series={[
                                          parseInt(item.quiz_avg_score),
                                          100 - parseInt(item.quiz_avg_score),
                                        ]}
                                        sliceColor={[
                                          "#f78411",
                                          parseInt(item.quiz_avg_score) == 100
                                            ? "#f78411"
                                            : "#f5f3fb",
                                        ]}
                                        type="donut"
                                        width="170"
                                      />
                                    </View>
                                  </View>
                                  <Text style={{
                                    textAlign: 'center'
                                  }}>Average Score</Text>
                                </View>

                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    );
                  })}
                <Text
                  onPress={() => {
                    this.setState({ isNewAssessmentEnabled: true });
                  }}
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    color: "blue",
                    width: '60%',
                  }}
                >
                  Request New Assessment
                </Text>
              </View>
            </ScrollView>
          )}
        {this.state.isNewAssessmentEnabled && (
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            style={styles.cardContainer}
            showsVerticalScrollIndicator={false}
          >
            <View style={{ display: "flex", flexDirection: "column" }}>
              <View
                style={{
                  marginTop: 15,
                  marginLeft: "3%",
                  marginRight: "3%",
                  backgroundColor: "white",
                  width: "94%",
                  borderWidth: 1,
                  borderRadius: 12,
                  borderColor: "rgba(65, 13, 170, 0.3)",
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1%",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        marginBottom: "4%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 17,
                          color: "#51514E",
                          fontWeight: "500",
                        }}
                      >
                        Please download xls and email to{" "}
                        <TouchableOpacity onPress={()=>{
                          if(Platform.OS === "web"){
                            global.open("mailto:help@maxiom.in")
                          }else{
                            Linking.openURL("mailto:help@maxiom.in")
                          }
                        }}>
                        <Text style={{ fontStyle: "italic", color: "#673dbb" }}>
                          help@maxiom.in
                        </Text>
                        </TouchableOpacity>
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    width: "60%",
                    marginBottom: 50,
                    marginTop: 10,
                    justifyContent: "center",
                    marginLeft: "4%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 17,
                      color: "#51514E",
                      fontWeight: "500",
                    }}
                  >
                    Our team will setup the New Assessment if your corporate is
                    eligible.
                  </Text>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 15 }}>
                  <Text
                    onPress={() => {
                      saveAs(
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/Request_Assessment.xlsx",
                        "Request_Assessment.xlsx"
                      );
                    }}
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      fontSize: 15,
                      color: "#410DAA",
                      backgroundColor: "white",
                      borderColor: "#410DAA",
                      borderWidth: 1,
                      borderRadius: 4,
                      paddingTop: 12,
                      textAlign: "center",
                      width: 150,
                      height: 45,
                    }}
                  >
                    Download Xls
                  </Text>
                </View>
              </View>
            </View>
          </ScrollView>
        )}
        {this.state.isAssessmentDetailsEnabled && (
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            style={styles.cardContainer}
            showsVerticalScrollIndicator={false}
          >
            <View
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                marginBottom: 20,
                marginLeft: 20,
              }}
            >
              <View style={{ flex: 2, marginTop: 5 }}>
                <FlatList
                  data={["Not Taken", "Taken"]}
                  extraData={this.state.activeTab}
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={{
                    alignItems: "flex-start",
                    paddingHorizontal: 18,
                  }}
                  renderItem={({ item }) => {
                    let backgroundColor;
                    let color;
                    if (item == this.state.activeTab) {
                      backgroundColor = "#410DAA";
                      color = "#fff";
                    } else {
                      backgroundColor = "#fff";
                      color = "#121212";
                    }
                    return (
                      <this.SelectTabDetails
                        item={item}
                        backgroundColor={backgroundColor}
                        color={color}
                        onPress={() => {
                          this.setState({
                            activeTab: item,
                          });
                        }}
                      />
                    );
                  }}
                  keyExtractor={(item, index) => index}
                />
              </View>
              {this.state.activeTab == "Not Taken" ? (
                <Text
                  onPress={this.handleEmailTemplateData}
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: 15,
                    color: "#410DAA",
                    backgroundColor: "white",
                    borderColor: "#410DAA",
                    borderWidth: 1,
                    borderRadius: 4,
                    padding: 12,
                    textAlign: "center",
                    height: 45,
                    marginRight: 40,
                    marginTop: isMobile ? 20 : 0
                  }}
                >
                  Preview & Send Reminders
                </Text>
              ) : (
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: 15,
                    color: "#410DAA",
                    backgroundColor: "white",
                    borderColor: "white",
                    borderWidth: 1,
                    borderRadius: 4,
                    paddingTop: 12,
                    textAlign: "center",
                    width: 150,
                    height: 45,
                    marginRight: 40,
                  }}
                ></Text>
              )}
            </View>
            {this.state.activeTab == "Taken" && (
              <View style={styles.detailsContainer}>
                <View style={styles.headingContainer}>
                  <SearchBar
                    value={this.state.searchAssessmentTakenText}
                    onChange={this.handleAssessmentTakenSearch}
                  />
                  <View
                    style={{
                      cursor: "pointer",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      marginRight: 20,
                      marginTop: isMobile ? 20 : 0,
                    }}
                  >
                    {/* <ExcelFile
                      filename={
                        this.state.userName +
                        " " +
                        "Assessment Taken Details " +
                        moment().format("DD-MM-YYYY hh-mm-ss A")
                      }
                      element={
                        <Text
                          style={{
                            color: "#410DAA",
                            fontWeight: "bold",
                            cursor: "pointer",
                            fontSize: 15,
                            textDecorationLine: "underline",
                          }}
                        >
                          Download As Excel
                        </Text>
                      }
                    >
                      <ExcelSheet
                        data={excelAssessmentTakenData}
                        name="Assessment Taken"
                      >
                        <ExcelColumn label="Name" value="name" />
                        <ExcelColumn label="Mobile Number" value="mobile" />
                        <ExcelColumn label="Email" value="email" />
                        <ExcelColumn label="Quiz Score" value="quizScore" />
                        <ExcelColumn label="Persona Name" value="personaName" />
                      </ExcelSheet>
                    </ExcelFile> */}
                  </View>
                </View>
                <ScrollView horizontal={ isMobile ? true : false} style={{width: '100%'}}>
                  <TakenTable
                    header={headerAssessmentTakenData}
                    gridConfig={[1, 1, 1, 1, 1]}
                    data={valuesAssessmentTaken}
                    navigation={this.props.navigation}
                    pagination={valuesAssessmentTaken.length > 10 ? true : false}
                  />
                </ScrollView>
              </View>
            )}
            {this.state.activeTab == "Not Taken" && (
              <View style={styles.detailsContainer}>
                <View style={styles.headingContainer}>
                  <SearchBar
                    placeHolder={`Search by Name or Team Name`}
                    value={this.state.searchAssessmentNotTakenText}
                    onChange={this.handleAssessmentNotTakenSearch}
                  />
                  <View style={{
                    display: 'flex',
                    flexDirection: isMobile ? "row" : "column",
                    marginTop: isMobile ? 30 : 0,
                    width: isMobile ? "100%" : "50%",
                  }}>
                  <PickerFieldSelect
                    data={this.state.searchTeamList}
                    placeholderValue={"Select Team"}
                    value={this.state.selectedTeam}
                    onChange={(text) => {
                      if (text != "0") {
                        this.handleAssessmentNotTakenSearch(text);
                      } else {
                        this.setState({
                          searchAssessmentNotTakenText: "",
                          searchAssessmentNotTakenResults:
                            this.state.assessmentNotTakenDataValue,
                          selectedCheckboxs: this.state.assessmentNotTakenDataValue,
                          sorting_field: null,
                          sorting_value: null,
                        });
                      }
                      this.setState({
                        selectedTeam: text,
                      });
                    }}
                    width={160}
                    type="select"
                  />
                  <View
                    style={{
                      cursor: "pointer",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      marginRight: isMobile ? 0 : 20,
                      margin: isMobile ? 20 : 0
                    }}
                  >
                    {/* <ExcelFile
                      filename={
                        this.state.userName +
                        " " +
                        "Assessment Not Taken Details " +
                        moment().format("DD-MM-YYYY hh-mm-ss A")
                      }
                      element={
                        <Text
                          style={{
                            color: "#410DAA",
                            fontWeight: "bold",
                            cursor: "pointer",
                            fontSize: 15,
                            textDecorationLine: "underline",
                          }}
                        >
                          Download As Excel
                        </Text>
                      }
                    >
                      <ExcelSheet
                        data={excelAssessmentNotTakenData}
                        name="Assessment Not Taken"
                      >
                        <ExcelColumn label="Name" value="name" />
                        <ExcelColumn label="Mobile Number" value="mobile" />
                        <ExcelColumn label="Email" value="email" />
                        <ExcelColumn label="Employee Id" value="employeeId" />
                        <ExcelColumn label="Team Name" value="teamName" />
                      </ExcelSheet>
                    </ExcelFile> */}
                  </View>
                  </View>
                </View>
                <ScrollView horizontal={ isMobile ? true : false} style={{width: '100%'}}>
                  <TakenTable
                    header={headerAssessmentNotTakenData}
                    gridConfig={[1, 1,1, 1, 1, 1, 1]}
                    data={valuesAssessmentNotTaken}
                    navigation={this.props.navigation}
                    pagination={
                      valuesAssessmentNotTaken.length > 10 ? true : false
                    }
                  />
                </ScrollView>
              </View>
            )}
          </ScrollView>
        )}
        {this.state.showModal && (
          <Modal
            style={{
              top: 0,
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              width : "100%"
            }}
            containerStyle={{
              marginHorizontal: isMobile ? 16 : 0,
              borderRadius: 5,
            }}
          >
            <View
              style={{
                paddingTop: isMobile ? 16 : 24,
                paddingBottom: isMobile ? 17 : 23,
                paddingHorizontal: isMobile ? 5 : 33,
                minWidth: isMobile ? 200 : 850,
                minHeight: isMobile ? 200 : 600,
                marginHorizontal: isMobile ? 16 : 0,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
              >
                <Text style={{ fontWeight: "700", fontSize: 15 }}>
                  Email Reminder
                </Text>
                <Text style={{color: 'blue', textAlign: 'right'}} onPress={()=>{this.setState({showUserList: !this.state.showUserList})}}>
                  {this.state.showUserList ? "Show Preview" : "Show Selected Customer List"}
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showModal: false });
                  }}
                >
                  <AntDesign
                    name="close"
                    size={24}
                    color="rgba(0, 0, 0, 0.5)"
                  />
                </TouchableOpacity>
              </View>
              { this.state.showUserList ? (<EmployeeTable arr={this.state.selectedCheckboxs} />) :
              (<WebView source={{html: `${this.state.htmlEmailTemplate}`}} />)}
              <Button
                uppercase={false}
                mode={"contained"}
                onPress={this.handleSendReminder}
                style={{
                  width: 154,
                  alignSelf: "center",
                  marginBottom: "2%",
                  marginTop: "5%",
                }}
                color={"#410DAA"}
              >
                <Text style={{ fontSize: 14, color: "white" }}>
                  Send Reminder
                </Text>
              </Button>
            </View>
          </Modal>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    padding: 5,
  },
  modalContentContainer: {
    backgroundColor: "white",
    padding: 8,
    paddingRight: 0,
    borderRadius: 4,
    width: "100%",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  headerItemContainerStyle: {
    justifyContent: "flex-start",
  },
  rowItem: {
    textAlign: "left",
  },
  rowItemContainerStyle: {
    justifyContent: "flex-start",
    paddingLeft: 10,
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginLeft: "3%",
    marginBottom: 66,
    width: "94%",
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 16 : 19,
    paddingVertical: 19,
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "space-between",
  },
});

export default FinancialWellnessIndex;
