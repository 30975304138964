import React, { Component } from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  Platform,
  SafeAreaView,
} from "react-native";
import { Text } from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import Toast from "react-native-toast-message";
import { StackActions } from "@react-navigation/native";

import {
  addGoal,
  deleteGoal,
  getAdvisorDashboardDetials,
  getGoalDetails,
} from "../../../api/goals";

import { connect } from "react-redux";
import { resetFinplan } from "../../../actions/finplan";
import amplitude from "../../../utils/amplitude";

var goals = require("../../../static/data/goals.json");

// import Loader from "../Loader/Loader";
// import { ScrollPickerContent } from "../common/ScrollPickerContent";

import { actionTypes as goalActionTypes } from "../../../reducers/goals";

import { Store as store } from "../../../store";
import AlertModal from "../../../components/Common/Modals/AlertModal";
import { inWords, isMobile, numberWithCommas } from "../../../utils";
import theme from "../../../static/theme";
import HeadingText from "../../../components/Common/Text/HeadingText";
import CustomButton from "../../../components/Common/Buttons/CustomButton";
import { ActivityIndicator } from "react-native-paper";
import SuccessOrFailureModal from "../../../components/Common/Modals/SuccessOrFailureModal";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import TextInputField from "../../../components/Common/Inputs/InputField";
import PickerField from "../../../components/Common/Pickers/PickerField";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import { ShowToast } from "../../../components/Common/Toast";

var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

class AddGoal extends Component {
  constructor(props) {
    super(props);
    let goal_name = "",
      goal_id;
    let from;
    const { params = null } = this.props.route;
    if (params) {
      goal_name = params.goal_name || "";
      goal_id = params.goal_id || null;
      from = params.from || null;
    }
    this.state = {
      goal_id,
      name: goal_name,
      target_year: null,
      amount_at_current_cost: "",
      type: "",
      loading: false,
      visibleModal: false,
      showToast: "false",
      from,
    };
  }

  static navigationOptions = ({ navigation }) => ({
    tabBarVisible: false,
  });

  componentDidMount = async () => {
    if (Platform.OS == "web") {
      amplitude.logEvent("AddGoal", { path: global.location.href });
    } else {
      amplitude.logEvent("AddGoal", {
        path: this.props.navigation.state.routeName,
      });
    }
    const { params = null } = this.props.route;
    let goal_id, goal_type;
    if (params) {
      goal_type = params.goal_type || "SEL";
      goal_id = params.goal_id || false;
    }
    if (goal_id) {
      const response = await getGoalDetails(goal_id);
      if (response && response.code == 100) {
        const { results = [] } = response;
        if (results.length) {
          const goal = results[0];
          const {
            name,
            target_year,
            amount_at_current_cost,
            type,
            inflation,
            target_text,
            expected_returns,
            target_amount_initially_calculated,
            calculated_monthly_installment,
          } = goal;
          this.setState({
            name,
            target_year: `${target_year}`,
            amount_at_current_cost: `${Math.round(amount_at_current_cost)}`,
            type,
            inflation: Number(String(inflation * 100).split(".")[0]).toString(),
            target_text,
            expected_returns: expected_returns * 100,
            target_amount_initially_calculated: String(
              target_amount_initially_calculated
            ).split(".")[0],
            calculated_monthly_installment: String(
              calculated_monthly_installment
            ).split(".")[0],
            goal: goal,
          });
          return;
        }
      }
    }
    const target_year = new Date().getFullYear() + 1;
    this.setState({
      target_year: target_year.toString(),
      type: goal_type,
      inflation: "7",
      goal: null,
    });
  };

  componentWillUnmount() {
    this.clearForm();
  }

  clearForm = () => {
    this.setState({
      name: "",
      target_year: null,
      amount_at_current_cost: "",
      type: null,
      loading: false,
      visibleModal: false,
    });
  };

  positiveUpdate = (val) => {
    const {
      target_amount_initially_calculated,
      expected_returns,
      target_year,
    } = this.state;
    const datedevice = new Date();
    var year = datedevice.getFullYear();

    if (this.state[val] + 0.5 <= 12) {
      this.setState(
        {
          [val]: this.state[val] + 0.5,
        },
        () => {
          if (val == "inflation") {
            this.updateTargetAmount();
            this.getSipAmount(
              target_amount_initially_calculated,
              expected_returns / 100.0
            );
          } else {
            this.getSipAmount(
              target_amount_initially_calculated,
              expected_returns / 100.0
            );
          }
        }
      );
    }
  };

  negativeUpdate = (val) => {
    const {
      target_amount_initially_calculated,
      expected_returns,
      target_year,
    } = this.state;
    const datedevice = new Date();
    var year = datedevice.getFullYear();
    if (this.state[val] - 0.5 >= 4)
      this.setState(
        {
          [val]: this.state[val] - 0.5,
        },
        () => {
          if (val == "inflation") {
            this.updateTargetAmount();
            this.getSipAmount(
              target_amount_initially_calculated,
              expected_returns / 100.0
            );
          } else {
            this.getSipAmount(
              target_amount_initially_calculated,
              expected_returns / 100.0
            );
          }
        }
      );
  };

  saveGoal = async () => {
    const {
      name,
      type,
      amount_at_current_cost,
      target_year,
      goal_id,
      goal = {},
    } = this.state;
    const datedevice = new Date();
    var year = datedevice.getFullYear();

    if (!(name.length > 0)) {
      Toast.show({
        text1: "Name cannot be empty.",
        visibilityTime: 2 * 1000,
        topOffset: 50,
        type: "error",
      });
      return;
    }

    if (!type || type == "SEL") {
      Toast.show({
        text1: "Please select a goal type.",
        visibilityTime: 2 * 1000,
        topOffset: 50,
        type: "error",
      });
      return;
    }

    if (!(amount_at_current_cost.length > 0)) {
      Toast.show({
        text1: "Cost of goal cannot be empty.",
        visibilityTime: 2 * 1000,
        topOffset: 50,
        type: "error",
      });
      return;
    }

    if (!target_year || target_year == "SEL") {
      Toast.show({
        text1: "Please select target year.",
        visibilityTime: 2 * 1000,
        topOffset: 50,
        type: "error",
      });
      return;
    } else {
      const valid_year = Number(target_year) > new Date().getFullYear();
      if (!valid_year) {
        Toast.show({
          text1: "Please select target year greater than current year",
          visibilityTime: 2 * 1000,
          topOffset: 50,
          type: "error",
        });
        return;
      }
    }

    const inflation = this.state.inflation ? this.state.inflation : "";
    const expected_returns = this.state.expected_returns
      ? this.state.expected_returns
      : "";

    try {
      this.setState({
        loading: true,
      });

      const payload = {
        goal_id: goal_id,
        name: name,
        amount_at_current_cost: amount_at_current_cost,
        type: type,
        years: Number(target_year) - year,
        due_date: "",
        expected_returns,
        target_amount_initially_calculated: "",
        calculated_monthly_installment: "",
        inflation,
      };

      const response = await addGoal(payload);
      if (response.code == 0) {
        this.setState({ loading: false });
        setTimeout(() => {
          Toast.show({
            text1: response.msg,
            visibilityTime: 2 * 1000,
            topOffset: 145,
            type: "error",
          });
          return;
        }, 1000);
        return;
      }

      if (response.code == 100) {
        const message = this.state.goal_id
          ? "Goal updated successfully!"
          : "Goal created successfully!";

        Toast.show({
          text1: message,
          visibilityTime: 2 * 1000,
          type: "success",
        });
        setTimeout (()=>{
        if (this.state.goal_id || response.new_goal_id) {
          if (response.new_goal_id) {
            this.setState({ showToast: "true" });
            const action = StackActions.replace("App", {
              screen: "Dashboard",
              params: {
                screen: "Goals",
                params: {},
              },
            });
            this.props.navigation.dispatch(action);
          } else {
            const action = StackActions.replace("App", {
              screen: "Dashboard",
              params: {
                screen: "Goals",
                params: {
                  screen: "GoalDetails",
                  params: {
                    goal_id: this.state.goal_id || response.new_goal_id,
                  },
                },
              },
            });
            this.props.navigation.dispatch(action);
          }
        } else {
          const action = StackActions.replace("App", {
            screen: "Dashboard",
            params: { screen: "Goals" },
          });
          this.props.navigation.dispatch(action);
        }

        this.clearForm();
      },1000);
      }
    } catch (error) {
      Toast.show({
        text1: "Something went wrong!",
        visibilityTime: 2 * 1000,
        topOffset: 145,
        type: "error",
      });
      return;
    }
  };

  updateTargetAmount = () => {
    const datediff = (first, second) => {
      return Math.floor((second - first) / (1000 * 60 * 60 * 24));
    };
    var _target_date = new Date();
    _target_date.setFullYear(this.state.target_year);
    var days_to_achieve_goal = datediff(new Date(), _target_date);

    calculated_target_amount =
      this.state.amount_at_current_cost *
      Math.pow(1 + this.state.inflation / 100.0, days_to_achieve_goal / 360.0);

    this.setState({
      target_amount_initially_calculated: String(
        calculated_target_amount
      ).split(".")[0],
    });
  };

  getSipAmount(amount, yearly_ret) {
    const datediff = (first, second) => {
      return Math.floor((second - first) / (1000 * 60 * 60 * 24));
    };
    var _target_date = new Date();
    _target_date.setFullYear(this.state.target_year);
    var days_to_achieve_goal = datediff(new Date(), _target_date);
    years = parseInt(days_to_achieve_goal / 360.0);
    var monthly_ret = yearly_ret / 12.0;
    var months = years * 12;
    var count = 0;
    var factor = 1 + monthly_ret;
    var denom = 1;
    while (count < months) {
      denom = denom * factor;
      count = count + 1;
    }
    var principal = denom - 1;
    principal = monthly_ret / principal;
    principal = principal * amount;
    this.setState({
      calculated_monthly_installment: String(principal).split(".")[0],
    });
    return principal;
  }

  _deleteGoalConfirm = () => {
    this.setState({ dialogVisible: true });
  };

  getGoalsData = async () => {
    const json = await getAdvisorDashboardDetials();
    if (json && json.goals) {
      store.dispatch({
        type: goalActionTypes.ADD_GOALS,
        payload: json.goals,
      });
    }
  };

  _deleteGoal = async () => {
    try {
      let goal_id;
      const { params = null } = this.props.route;
      if (params) {
        goal_id = params.goal_id || null;
      }
      if (!goal_id) return;
      this.setState({ loading: true });
      const payload = {
        goal_id: goal_id,
      };
      const response = await deleteGoal(payload);
      if (response && response.code == 100) {
        const action = StackActions.replace("Goals");
        this.props.navigation.dispatch(action);
      } else {
        Toast.show({
          text1: "Something went wrong!",
          visibilityTime: 2 * 1000,
          topOffset: 145,
          type: "error",
        });
      }
    } catch (error) {}
  };

  handleInputChange = (field, value) => {
    this.setState({ [field]: value });
  };

  render() {
    const {
      target_year,
      goal = null,
      type = null,
      amount_at_current_cost = 0,
      inflation,
    } = this.state;
    var datedevice = new Date();
    var year = datedevice.getFullYear();
    let date = [];
    date.push(<Picker.Item label={"Select Target Year "} value={"SEL"} />);
    for (var i = 1; i <= 100; i++) {
      date.push(
        <Picker.Item
          label={(year + i).toString()}
          value={(year + i).toString()}
        />
      );
    }

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={[goal ? "Update Goal" : "Create Goal"]}
          onPressBack={() => {
            if (this.state.from == "MainGoalScreen") {
              this.props.navigation.goBack();
            } else {
              this.props.navigation.goBack();
              // this.props.navigation.navigate("Account", {
              //   screen: "ResetFinplan",
              // });
            }
          }}
        />
        {/* {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title="Goal created successfully"
            duration="10"
          />
        ) : null} */}
        <ScrollView
          contentContainerStyle={styles.scrollView}
          showsVerticalScrollIndicator={true}
          keyboardShouldPersistTaps="handled"
        >
          <HeadingText style={styles.headingText}>
            {goal ? goal.name : "Add Goal"}
          </HeadingText>
          <View style={[styles.formItem, styles.itemMargin]}>
            <TextInputField
              label="Goal Name *"
              value={this.state.name}
              onChange={(value) => {
                this.setState({ name: value });
              }}
              style={[{}]}
              editable={true}
            />
          </View>
          <View style={[styles.formItem, styles.itemMargin]}>
            <PickerFieldSelect
              data={goals}
              value={type}
              label="Goal Type *"
              onChange={(value) => {
                this.handleInputChange("type", value);
              }}
              editable={true}
              placeholder={{ value: "SEL", label: "Select" }}
            />
          </View>

          <View style={[styles.formItem, styles.itemMargin]}>
            <TextInputField
              prefix="₹"
              label="Cost of Goal *"
              value={numberWithCommas(amount_at_current_cost)}
              onChange={(amount) => {
                amount = amount.replaceAll(",", "");
                if ((!isNaN(amount) && amount > 0) || amount == "") {
                  this.setState({ amount_at_current_cost: amount });
                }
              }}
              style={[{}]}
              editable={true}
            />
          </View>

          <Text style={styles.inWords}>{inWords(amount_at_current_cost)}</Text>

          <View style={[styles.formItem, styles.itemMargin]}>
            <TextInputField
              label="Target Year *"
              value={target_year}
              onChange={(target_year) => {
                if (!isNaN(target_year)) {
                  this.setState({ target_year });
                }
              }}
              style={[{}]}
              editable={true}
            />
          </View>

          <Text style={styles.targetYear}>
            {target_year && target_year != "SEL" && target_year !== "-"
              ? `${Number(target_year) - year} year${
                  Number(target_year) - year > 1 ? "s" : ""
                } to target`
              : ""}
          </Text>

          <View style={[styles.formItem, styles.itemMargin]}>
            <TextInputField
              label="Inflation Rate"
              value={inflation ? `${inflation}%` : "0%"}
              onChange={(inflation) => {
                inflation = inflation.replaceAll("%", "");
                if ((!isNaN(inflation) && inflation > 0) || inflation == "") {
                  this.setState({ inflation });
                } else {
                  this.setState({ inflation: "" });
                }
              }}
              editable={true}
            />
          </View>

          {this.state.loading && (
            <ActivityIndicator size="large" color="#410DAA" />
          )}
          {/* <Loader loading={true} /> */}
          {!this.state.hideNextButton && (
            <View style={styles.buttonsContainer}>
              <View style={styles.buttons}>
                {goal && (
                  <CustomButton
                    bgColor={"#fff"}
                    textColor={"red"}
                    style={{
                      marginTop: 30,
                      height: 45,
                      width: 140,
                    }}
                    isLoading={this.state.isImportingFunds}
                    borderColor={"red"}
                    buttonTextStyle={{
                      fontSize: 14,
                    }}
                    onPress={this._deleteGoalConfirm}
                  >
                    Delete
                  </CustomButton>
                )}
                <CustomButton
                  bgColor={"#410DAA"}
                  textColor={"#fff"}
                  style={{
                    marginTop: 30,
                    height: 45,
                    marginLeft: goal ? 20 : 0,
                    width: 140,
                  }}
                  isLoading={this.state.isImportingFunds}
                  borderColor={"transparent"}
                  buttonTextStyle={{
                    fontSize: 14,
                  }}
                  onPress={this.saveGoal}
                >
                  {goal ? "Update Goal" : "Create Goal"}
                </CustomButton>
              </View>
            </View>
          )}
        </ScrollView>

        <AlertModal
          visible={this.state.dialogVisible}
          close={false}
          positiveButtonText="No"
          negativeButtonText="Yes"
          positiveButtonPress={() => {
            this.setState({ dialogVisible: false });
          }}
          negativeButtonPress={() => {
            this.setState({ dialogVisible: false }, () => {
              this._deleteGoal();
            });
          }}
        >
          <Text
            style={{
              fontSize: 15,
              color: "#121212",
              textAlign: "left",
              marginBottom: 20,
            }}
          >
            Continue to delete the goal ?
          </Text>
        </AlertModal>

        <SuccessOrFailureModal
          visible={this.state.showSuccessOrErrorFailureModal}
          status={this.state.responseStatus}
          title={this.state.responseStatus ? "Success" : "Failed"}
          message={this.state.responseMessage}
          buttons={this.state.modal_buttons}
          messageStyle={{
            marginBottom: 50,
          }}
        />
        {/* {this.state.loading && <Loader loading={this.state.loading} />} */}
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    goals: state.goals.goals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetFinplan: () => dispatch(resetFinplan()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGoal);

const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    paddingHorizontal: isMobile ? 16 : 64,
    backgroundColor: "#fff",
    paddingBottom: 100,
  },
  activeTextStyle: {
    color: "white",
    fontWeight: "bold",
  },
  textStyle: {
    color: theme.COLORAPP.SILVER,
  },
  activeViewStyle: {
    backgroundColor: theme.COLORAPP.BLUE,
    borderRadius: 5,
    padding: 5,
    margin: 5,
  },
  viewStyle: {
    borderWidth: 0.7,
    padding: 5,
    margin: 5,
    borderRadius: 5,
    borderColor: theme.COLORAPP.SILVER,
  },
  headingText: {
    fontSize: theme.fontsizes.MEDIUM,
    marginTop: 20,
    marginBottom: 10,
  },
  formItem: {},
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
    margin: 0,
    padding: 0,
    alignItems: "flex-end",
    justifyContent: "flex-start",
    marginBottom: 0,
    paddingBottom: 0,
    alignSelf: "flex-start",
  },
  inputLabel: {
    color: "#BCBCBC",
    fontSize: 13,
    marginVertical: 0,
    paddingVertical: 0,
    // backgroundColor: "red"
  },
  errorText: {
    color: "red",
    fontSize: 10,
    height: 16,
  },
  bottomModal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  inWords: {
    color: "black",
    fontWeight: "bold",
    fontSize: 10,
    fontStyle: "italic",
    paddingLeft: 4,
    paddingTop: 5,
  },
  targetYear: {
    fontWeight: "bold",
    fontSize: 10,
    fontStyle: "italic",
    paddingLeft: 5,
    color: "black",
    marginBottom: 10,
  },
  buttonsContainer: {
    marginTop: 10,
    width: isMobile ? "60%" : "20%",
    left: 0,
    right: 0,
    marginBottom: 10,
    backgroundColor: "#fff",
  },
  buttons: {
    flexDirection: "row",
  },
});
