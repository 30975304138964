import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { ActivityIndicator, Button, Text } from 'react-native-paper';
import { getDigioKycStatus } from '../../../api/account';




const EKYCRedirection = (props) => {
    const [fetchingKYC, setFetchingKYC] = useState(true);
    const [error, setError] = useState(null);
    const [kycStatus, setKycStatus] = useState(null);
    
    useEffect(() => {
        const loadStatus = async () => {
            try {
                const response = await getDigioKycStatus()
                if(response){
                    if(response.code){
                        const { msg, results } = response;
                        setKycStatus(msg)
                        setFetchingKYC(false)
                        return;
                    }
                }
                setFetchingKYC(false)
                setError("Error Fetching KYC Status !")
            } catch (error) {
                console.log(error);
                setFetchingKYC(false)
                setError("Error Fetching KYC Status !")
            }
        }
        loadStatus();
    },[]);

    return (
        <View style={styles.container}>
            {
                fetchingKYC ? (
                    <View style={styles.loaderContainer}>
                        <ActivityIndicator size="large" color="#410DAA"/>
                        <Text style={styles.fetching}>Processing KYC status</Text>
                    </View>
                ) : (
                    <View style={styles.loaderContainer}>
                        <Text style={styles.fetching}>{kycStatus}</Text>
                    </View>
                )
            }
                    
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff"
    },
    loaderContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
    },
    fetching: {
        fontSize: 14,
        color: "#12121250",
        marginTop: 20
    },
});


export default EKYCRedirection;