import React from "react";
import { View, StyleSheet, Text, Platform,SafeAreaView } from "react-native";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { getBillDetails } from "../../../api/billing";
import { ActivityIndicator } from "react-native-paper";
import LP1 from "./AdvanceBill/LP1";
import LP2 from "./AdvanceBill/LP2";
import OLD from "./AdvanceBill/OLD";
import SP1 from "./AdvanceBill/SP1";
import ServiceBill from "./ServiceBill";
import PerformanceBill from "./PerformanceBill/index";
import Store from "../../../store";
import amplitude from "../../../utils/amplitude";

class ViewBill extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let bill_id = null;
    let bill_type = null;
    let userId = null;
    let invoiceId = null;
    if (params) {
      bill_id = isNaN(bill_id) ? null : params.bill_id;
      bill_type = params.bill_type || null;
      userId = params.user_id || null;
      invoiceId = params.invoice_id || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      bill_id: bill_id,
      userId,
      data: null,
      bill_type,
      isLoading: true,
      invoiceId,
      billToShow: null,
    };
  }

  componentDidMount = async () => {
    const { bill_id } = this.state;
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("ViewBill",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("ViewBill", { path: this.props.navigation.getState().routeName });
      }
      if (!bill_id) return;
      const response = await getBillDetails(bill_id);
      if (response && response.code && response.code == 100) {
        const { results = {} } = response;
        const data = { ...results };
        const { bill_view_type } = results;
        let path = null;
        switch (bill_view_type) {
          case "LP1_advance":
            this.setState({ billToShow: "LP1" });
            break;
          case "SP1_advance":
            this.setState({ billToShow: "SP1" });
            break;
          case "LP2_advance":
          case "SP2_advance":
            this.setState({ billToShow: "LP2" });
            break;
          case "prorated_service":
          case "daily_aum_service":
            this.setState({ billToShow: "ServiceBill" });
            break;
          case "old_advance":
            this.setState({ billToShow: "OLD" });
            break;
          case "performance":
            this.setState({ billToShow: "PerformanceBill" });
            break;
        }
        this.setState({ data: data, isLoading: false });
      } else {
        this.setState({
          isLoading: false,
          error: "Error loading bill details",
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  navigateBack = () => {
    const { navigation } = this.props;
    const canGoBack = navigation.canGoBack();
    if (canGoBack) {
      navigation.goBack();
    } else {
      navigation.navigate("Invoices");
    }
  };

  render() {
    const {
      data,
      isLoading,
      error,
      screen: Component = null,
      bill_id,
      userId,
      invoiceId,
    } = this.state;

    return (
      <SafeAreaView style={styles.container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Invoice #" + invoiceId, "View Bill #" + bill_id]}
          onPressBack={this.navigateBack}
        />
        {isLoading || error ? (
          <View style={styles.loadingContainer}>
            {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
            {error && <Text style={styles.error}>{error}</Text>}
          </View>
        ) : (
          <View style={styles.billContainer}>
            {this.state.billToShow == "LP1" && (
              <LP1
                data={data}
                navigation={this.props.navigation}
                billId={bill_id}
                userId={userId}
                invoiceId={invoiceId}
              />
            )}
            {this.state.billToShow == "LP2" && (
              <LP2
                data={data}
                navigation={this.props.navigation}
                billId={bill_id}
                userId={userId}
                invoiceId={invoiceId}
              />
            )}
            {this.state.billToShow == "OLD" && (
              <OLD
                data={data}
                navigation={this.props.navigation}
                billId={bill_id}
                userId={userId}
                invoiceId={invoiceId}
              />
            )}
            {this.state.billToShow == "SP1" && (
              <SP1
                data={data}
                navigation={this.props.navigation}
                billId={bill_id}
                userId={userId}
                invoiceId={invoiceId}
              />
            )}
            {this.state.billToShow == "ServiceBill" && (
              <ServiceBill
                data={data}
                navigation={this.props.navigation}
                billId={bill_id}
                userId={userId}
                invoiceId={invoiceId}
              />
            )}
            {this.state.billToShow == "PerformanceBill" && (
              <PerformanceBill
                data={data}
                navigation={this.props.navigation}
                billId={bill_id}
                userId={userId}
                invoiceId={invoiceId}
              />
            )}
          </View>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1, backgroundColor: "#fff" },
  billContainer: {
    flex: 1,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ViewBill;
