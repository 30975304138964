import axiosApiInstance from "./apiConfig";
import { URLS } from "./urls";

export const getInvestNowStrips = async () => {
  const result = await axiosApiInstance.get(URLS.INVEST_LANDING_PAGE);
  return result;
};

export const getPersonalisedFunds = async () => {
  const result = await axiosApiInstance.get(URLS.PERSONALISED_FUNDS);
  return result;
};

export const getSearchFunds = async (payload) => {
  const result = await axiosApiInstance.post(URLS.SEARCH_FUNDS, payload);
  return result;
};

export const getStocksPortfolioProducts = async () => {
  const result = await axiosApiInstance.get(URLS.STOCKS_PORTFOLIO_PRODUCTS);
  return result;
};

export const getMFRecommendataionsBasedOnAmount = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.MF_RECOMMENDATION_BASED_ON_AMOUNT,
    payload
  );
  return result;
};

export const getNetbankingPaymentHTML = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.NET_BANKING_PAYMENT_LINK,
    payload
  );
  return result;
};

export const getNetbankingPaymentLink = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.GET_BSE_PAYMENT_LINK,
    payload
  );
  return result;
};

export const getBSEOrdersPaymentStatus = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.GET_BSE_PAYMENT_STATUS,
    payload
  );
  return result;
};

export const deleteCartItem = async (payload) => {
  const url = URLS.FUNDS_IN_CART_WISHLIST + "" + payload + "/";
  const result = await axiosApiInstance.delete(url);
  return result;
};

export const updateCartItem = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.UPDATE_IN_CART_WISHLIST,
    payload
  );
  return result;
};

export const getcartItems = async () => {
  const url = URLS.FUNDS_IN_CART_WISHLIST;
  const result = await axiosApiInstance.get(url);
  return result;
};

export const placeOrdersFromCart = async (payload) => {
  const result = await axiosApiInstance.post(URLS.PLACE_ORDERS, payload);
  return result;
};
