import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    flex1:{
        flex: 1
    },
    flex10:{
        flex: 0.1
    },
    flex15: {
        flex: 0.15
    },
    flex20: {
        flex: 0.2
    },
    flex25: {
        flex: 0.25
    },
    flex30: {
        flex: 0.3
    },
    flex40: {
        flex: 0.4
    },
    flex50: {
        flex: 0.5
    },
    flex60: {
        flex: 0.6
    },
    flex70: {
        flex: 0.7
    },
    flex80: {
        flex: 0.8
    },
    flex90: {
        flex: 0.9
    },
    row: {
        flexDirection: 'row'
    },
    column: {
        flexDirection: "column"
    },
    center: {
        justifyContent: "center",
        alignItems: "center"
    },
    horizontalCenter: {
        alignItems: "center"
    },
    verticalCenter: {
        justifyContent: "center"
    }
})

export default styles;