import React from "react";
import { View, Text, StyleSheet, ScrollView, SafeAreaView,Platform } from "react-native";
import NavigationHeader from "../../components/Common/NavigationHeader";
import {
  numberWithCommas,
  formatDate,
  isMobile,
  getUserDetails,
  capitalizeFirstLetter,
} from "../../utils";
import { getHoldingsDetails } from "../../api/billing";
import HoldingsTable from "./HoldingsTable";
import Store from "../../store";
import SearchBar from "../../components/Common/SearchBar";
import moment from "moment";
// import ReactExport from "react-export-excel";
import { getCustomerDetails } from "../../api";
import amplitude from "../../utils/amplitude";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class HoldingsDetails extends React.Component {
  constructor(props) {
    super(props);
    let bill_id, user_id, date, invoiceId;
    const { params } = this.props.route;

    if (params) {
      bill_id = params.bill_id || null;
      date = params.date || null;
      user_id = params.user_id || null;
      invoiceId = params.invoice_id || null;
      if (params.invoie_id) {
        invoiceId = params.invoie_id || null;
      }
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      bill_id,
      date,
      user_id,
      isLoading: true,
      invoiceId,
      userName: "",
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("HoldingDetails",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("HoldingDetails", { path: this.props.navigation.getState().routeName });
      }
      const { bill_id, date } = this.state;
      const response = await getHoldingsDetails(bill_id, date);
      if (response && response.code == 100) {
        const { results = {} } = response;
        let {
          plan_value_date: holdings_as_on,
          latest_value: holdings_amount,
          stock_dict: holdings = {},
        } = results;
        holdings = [...Object.values(holdings)];
        holdings = holdings.filter((item) => item.qty > 0);
        const total_holdings_amount = holdings.reduce((c, p) => c + p.lv, 0);
        const data = {
          holdings_as_on,
          holdings_amount,
          holdings,
          total_holdings_amount,
        };
        this.setState({ data, dataBackup: data, isLoading: false });
        let userData = {};
        if (this.state.user_id) {
          userData = await getCustomerDetails(this.state.user_id);
        } else {
          userData = await getUserDetails();
        }
        let { first_name } = userData;
        let names = first_name ? first_name.split(/\s+/) : [];
        let name = "";
        if (names.length >= 2 && names.length > 0) {
          name =
            capitalizeFirstLetter(names[0]) +
            " " +
            capitalizeFirstLetter(names[1]);
        } else {
          if (names.length == 0) {
            name = "User";
          } else {
            name = names[0];
          }
        }
        this.setState({ userName: name });
      } else {
        this.setState({ error: "Error loading details", isLoading: false });
      }
    } catch (error) {
      this.setState({ error: "Error loading details", isLoading: false });
    }
  }

  navigateBack = () => {
    const { navigation } = this.props;
    if (navigation) {
      navigation.goBack();
    }
  };

  compare = (a, b, field, sort) => {
    if (a[field] < b[field]) {
      return sort ? -1 : 1;
    }
    if (a[field] > b[field]) {
      return sort ? 1 : -1;
    }
    return 0;
  };

  handleSort = (field, sort, start, end) => {
    this.setState(
      {
        sorting_field: field,
        sorting_value: !sort,
      },
      () => {
        const { data } = this.state;
        const holdings_data = [...data["holdings"]];
        const sortedData = [].concat(
          holdings_data.slice(0, start),
          holdings_data
            .slice(start, end)
            .sort((a, b) => this.compare(a, b, field, !sort)),
          holdings_data.slice(end, holdings_data.length)
        );
        this.setState({ data: { ...data, ["holdings"]: sortedData } });
      }
    );
  };

  getTableData = (data = null) => {
    if (!data || (data && !data.hasOwnProperty("holdings"))) return {};
    const { sorting_field, sorting_value } = this.state;
    const headerData = [
      {
        label: "Stock Name",
        style: [styles.tableHeader, styles.stockName],
        sortOption: true,
        onSort: this.handleSort,
        key: "stock_name",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Units",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "qty",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Price",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "price",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Value",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "lv",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
    ];

    let { values = [] } = this.generateDataForTable(data["holdings"]);
    return { headerData, values };
  };

  handleSearch = (searchText) => {
    let searchResults = [];
    let searchData = this.state.data.holdings; 
    if (searchText) {
      searchResults = searchData.filter((item) => {
        
        return item.stock_name.toLowerCase().startsWith(searchText);
      });
    } else {
      
      searchResults = this.state.dataBackup.holdings;
    }

    if (searchResults.length) {
      const total_holdings_amount = searchResults.reduce((c, p) => c + p.lv, 0);
      const data = {
        holdings: searchResults,
        total_holdings_amount,
      };
      this.setState({
        data,
        searchText,
        searchResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  generateDataForTable = (data) => {
    let values = [];
    data.forEach((item) => {
      let {
        stock_name,
        inv: invested_value,
        qty,
        price,
        lv: current_value,
      } = item;
      invested_value = numberWithCommas(parseInt(invested_value));
      price = numberWithCommas(parseInt(price));
      current_value = numberWithCommas(parseInt(current_value));
      values.push([
        {
          valueToDisplay: stock_name,
          value: stock_name,
          style: [
            styles.rowItem,
            { paddingLeft: isMobile ? 20 : 40, textAlign: "left" },
          ],
          rowItemContainerStyle: [{ justifyContent: "flex-start" }],
        },
        {
          valueToDisplay: parseInt(qty),
          value: qty,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + price,
          value: price,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + current_value,
          value: qty,
          style: [styles.rowItem],
        },
      ]);
    });
    return { values };
  };

  render() {
    const { navigation } = this.props;
    const {
      data = null,
      isLoading,
      error,
      bill_id,
      invoiceId,
      searchText,
    } = this.state;

    let excelBillingDetails = [];
    if (this.state.data) {
      this.state.data.holdings.forEach((item) => {
        let Price = "₹" + numberWithCommas(parseInt(item.price));
        let CurrentValue = "₹" + numberWithCommas(parseInt(item.lv));

        excelBillingDetails.push({
          stock_name: item.stock_name,
          qty: item.qty,
          price: Price,
          lv: CurrentValue,
        });
      });
    }

    const { headerData, values } = this.getTableData(data);
    return (
      <SafeAreaView style={styles.container}>
        <NavigationHeader
          navigation={navigation}
          navigationHeader={[
            "Invoices #" + invoiceId,
            "View Bills #" + bill_id,
            "View Details",
          ]}
          onPressBack={this.navigateBack}
        />
        {!isLoading && (
          <ScrollView
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={true}
            horizontal={isMobile ? true : false}
            style={{
              backgroundColor: "#FFFFFF",
              height: 500,
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#1212121A",
                borderRadius: 10,
                marginHorizontal: isMobile ? 16 : 20,
                width: isMobile ? 700 : 900,
                marginVertical: 20,
              }}
            >
              <View
                style={{
                  paddingHorizontal: isMobile ? 16 : 19,
                  paddingVertical: 19,
                  flexDirection: "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  justifyContent: "space-between",
                }}
              >
                <Text style={styles.header}>
                  Holdings as on {formatDate(data["holdings_as_on"])}
                </Text>
                <Text style={styles.header}>
                  Total:  ₹{numberWithCommas(data["total_holdings_amount"].toFixed(0))}
                </Text>
              </View>
              <View style={styles.headingContainer}>
                <SearchBar value={searchText} onChange={this.handleSearch} />
                <View
                  style={{
                    cursor: "pointer",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    marginRight: 20,
                  }}
                >
                  {/* <ExcelFile
                    filename={
                      this.state.userName +
                      " " +
                      "Holdings as on " +
                      formatDate(data["holdings_as_on"]) +
                      " " +
                      moment().format("DD-MM-YYYY hh-mm-ss A")
                    }
                    element={
                      <Text
                        style={{
                          color: "#410DAA",
                          fontWeight: "bold",
                          cursor: "pointer",
                          fontSize: 15,
                          textDecorationLine: "underline",
                        }}
                      >
                        Download As Excel
                      </Text>
                    }
                  >
                    <ExcelSheet data={excelBillingDetails} name="Invoices">
                      <ExcelColumn label="Stock Name" value="stock_name" />
                      <ExcelColumn label="Units" value="qty" />
                      <ExcelColumn label="Price" value="price" />
                      <ExcelColumn label="Value" value="lv" />
                    </ExcelSheet>
                  </ExcelFile> */}
                </View>
              </View>
              <HoldingsTable
                header={headerData}
                gridConfig={[1, 1, 1, 1]}
                data={values}
                navigation={this.props.navigation}
              />
            </View>
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1, backgroundColor: "#fff" },
  scrollView: {},
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: isMobile ? 16 : 36,
    marginVertical: 30,
    width: 800,
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 15 : 40,
    paddingVertical: 19,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "700",
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  tableHeaderStyle: {
    paddingHorizontal: 32,
  },
  stockName: {
    marginLeft: isMobile ? 10 : 31,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default HoldingsDetails;
