import React from "react";
import {
  View,
  StatusBar,
  Platform,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  Image,
} from "react-native";
import { Text, Button } from "react-native-paper";
import { getFinAwarenessData } from "../../../api";

import NavigationHeader from "../../../components/Common/NavigationHeader";
import { ActivityIndicator } from "react-native-paper";
import { getStatusColor, getWindowSize, isMobile } from "../../../utils/index";
import NotFound from "../../../components/NotFound";
import { CustomModal } from "../../../components/Common/CustomModal";
import Store from "../../../store";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;

class FaRowItem extends React.Component {
  render() {
    const { child, is_fetching } = this.props;

    return isMobile ? (
      <View
        style={{
          marginLeft: "8%",
          marginRight: "8%",
          backgroundColor: "white",
          paddingTop: 8,
          marginVertical: "1.5%",
          marginBottom: 20,
          width: "84%",
          height: 195,
          borderWidth: 1,
          borderRadius: 12,
          borderColor: "rgba(65, 13, 170, 0.3)",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            paddingHorizontal: 25,
            paddingVertical: 20,
            height: 90,
          }}
        >
          <View style={{ flex: 0.6, marginRight: 29 }}>
            <Image
              source={{ uri: child.quiz_img }}
              style={{
                width: 50,
                height: 50,
                alignSelf: "flex-start",
                borderRadius: 4,
              }}
              resizeMode={"contain"}
            />
          </View>
          <View
            style={{
              flex: 2,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                color: "#121212",
                fontWeight: "600",
              }}
            >
              {child.quiz_name}
            </Text>
          </View>
          <View style={{ flex: 3, alignItems: "flex-end" }}>
            {child.is_quiz_done ? (
              Platform.OS == "web" ? (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Donut
                    options={{
                      dataLabels: {
                        enabled: false,
                      },
                      legend: {
                        show: false,
                      },
                      tooltip: { enabled: false },
                      colors: ["#876CDD", "#BAC7F2"],
                      plotOptions: {
                        pie: {
                          donut: {
                            size: "60%",
                          },
                        },
                      },
                      stroke: {
                        width: 1,
                        colors: ["#876CDD", "#BAC7F2"],
                      },
                    }}
                    series={[
                      Math.floor(child.score),
                      100 - Math.floor(child.score),
                    ]}
                    sliceColor={["#876CDD", "#BAC7F2"]}
                    type="donut"
                    width="160"
                  />
                  <Text
                    style={{
                      fontWeight: "600",
                      color: "#121212",
                      fontSize: 12,
                    }}
                  >
                    Score {Math.floor(child.score)} / 100
                  </Text>
                  {child.is_quiz_done ? (
                    <Text
                      style={{
                        fontSize: 13,
                        color:
                          child.quiz_data.quiz_personas != "" &&
                          getStatusColor(
                            child.quiz_data.quiz_personas,
                            child.quiz_data.persona.persona_name
                          ),
                        fontWeight: "bold",
                        marginTop: 5,
                      }}
                    >
                      {child.quiz_data.persona.persona_name}
                    </Text>
                  ) : (
                    <Text
                      style={{
                        fontStyle: "italic",
                        fontSize: 16,
                        color: "#707070",
                        fontWeight: "bold",
                      }}
                    >
                      Incomplete
                    </Text>
                  )}
                </View>
              ) : (
                <View
                  style={{
                    marginTop: 25,
                    marginLeft: 30,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Donut
                    widthAndHeight={100}
                    series={[
                      Math.floor(child.score),
                      100 - Math.floor(child.score),
                    ]}
                    sliceColor={["#876CDD", "#BAC7F2"]}
                    doughnut={true}
                    coverRadius={0.6}
                    coverFill={"#FFF"}
                  />
                  <Text
                    style={{
                      fontWeight: "600",
                      color: "#121212",
                      fontSize: 12,
                    }}
                  >
                    Score {Math.floor(child.score)} / 100
                  </Text>
                  {child.is_quiz_done ? (
                    <Text
                      style={{
                        fontSize: 13,
                        color:
                          child.quiz_data.quiz_personas != "" &&
                          getStatusColor(
                            child.quiz_data.quiz_personas,
                            child.quiz_data.persona.persona_name
                          ),
                        fontWeight: "bold",
                        marginTop: 5,
                      }}
                    >
                      {child.quiz_data.persona.persona_name}
                    </Text>
                  ) : (
                    <Text
                      style={{
                        fontStyle: "italic",
                        fontSize: 16,
                        color: "#707070",
                        fontWeight: "bold",
                      }}
                    >
                      Incomplete
                    </Text>
                  )}
                </View>
              )
            ) : (
              <Text
                style={{ fontWeight: "500", color: "#121212", fontSize: 16 }}
              >
                {this.props.time}
                <Text style={{ fontSize: 12, fontWeight: "400" }}>
                  {this.props.time > 1 ? "mins" : "min"}
                </Text>
              </Text>
            )}
          </View>
        </View>
        <Button
          uppercase={false}
          mode="contained"
          labelStyle={{
            color: "#410DAA",
            fontWeight: "600",
          }}
          onPress={() => {
            this.props.navigation.push("Wellness", {
              quiz_id: child.quiz_id,
            });
            this.props.navigation.push("Wellness", {
              quiz_id: child.quiz_id,
            });
          }}
          style={{
            marginTop: 30,
            marginHorizontal: 24,
            borderColor: "#410DAA",
            borderWidth: 1,
            backgroundColor: "white",
            borderRadius: 6,
            width: "40%",
            marginLeft: "5%",
          }}
        >
          See Results
        </Button>
        {is_fetching && (
          <>
            <ActivityIndicator
              size="small"
              color="#410DAA"
              style={{ alignSelf: "center", marginVertical: 15 }}
            />
          </>
        )}
      </View>
    ) : (
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View
          style={{
            marginLeft: "3%",
            marginRight: "1.5%",
            backgroundColor: "white",
            paddingTop: 8,
            marginVertical: "1.5%",
            width: "45.5%",
            height: 210,
            borderWidth: 1,
            borderRadius: 12,
            borderColor: "rgba(65, 13, 170, 0.3)",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              paddingHorizontal: 25,
              paddingVertical: 20,
              height: 90,
            }}
          >
            <View style={{ flex: 1 }}>
              <Image
                source={{ uri: child[0].quiz_img }}
                style={{
                  width: 100,
                  height: 100,
                  alignSelf: "flex-start",
                  borderRadius: 4,
                }}
                resizeMode={"contain"}
              />
            </View>
            <View
              style={{
                flex: 1,
                marginTop: 8,
                marginLeft: 8,
              }}
            >
              <Text
                style={{
                  flex: 1,
                  fontSize: 16,
                  color: "#121212",
                  fontWeight: "600",
                  flexWrap: "wrap",
                }}
              >
                {child[0].quiz_name}
              </Text>
            </View>
            <View style={{ flex: 1, alignItems: "flex-end" }}>
              {child[0].is_quiz_done ? (
                Platform.OS == "web" ? (
                  <View
                    style={{
                      marginLeft: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Donut
                      options={{
                        dataLabels: {
                          enabled: false,
                        },
                        legend: {
                          show: false,
                        },
                        tooltip: { enabled: false },
                        colors: ["#876CDD", "#BAC7F2"],
                        plotOptions: {
                          pie: {
                            donut: {
                              size: "60%",
                            },
                          },
                        },
                        stroke: {
                          width: 1,
                          colors: ["#876CDD", "#BAC7F2"],
                        },
                      }}
                      series={[
                        Math.floor(child[0].score),
                        100 - Math.floor(child[0].score),
                      ]}
                      sliceColor={["#876CDD", "#BAC7F2"]}
                      type="donut"
                      width="160"
                    />
                    <Text
                      style={{
                        fontWeight: "600",
                        color: "#121212",
                        fontSize: 12,
                      }}
                    >
                      Score {Math.floor(child[0].score)} / 100
                    </Text>
                    {child[0].is_quiz_done ? (
                      <Text
                        style={{
                          fontSize: 13,
                          color:
                            child[0].quiz_data.quiz_personas != "" &&
                            getStatusColor(
                              child[0].quiz_data.quiz_personas,
                              child[0].quiz_data.persona.persona_name
                            ),
                          fontWeight: "bold",
                          marginTop: 5,
                        }}
                      >
                        {child[0].quiz_data.persona.persona_name}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          fontStyle: "italic",
                          fontSize: 16,
                          color: "#707070",
                          fontWeight: "bold",
                        }}
                      >
                        Incomplete
                      </Text>
                    )}
                  </View>
                ) : (
                  <Donut
                    widthAndHeight={100}
                    series={[
                      Math.floor(child[0].score),
                      100 - Math.floor(child[0].score),
                    ]}
                    sliceColor={["#876CDD", "#BAC7F2"]}
                    doughnut={true}
                    coverRadius={0.6}
                    coverFill={"#FFF"}
                  />
                )
              ) : (
                <Text
                  style={{ fontWeight: "500", color: "#121212", fontSize: 16 }}
                >
                  Quiz Time: {this.props.time}
                  <Text style={{ fontSize: 12, fontWeight: "400" }}>
                    {this.props.time > 1 ? " mins" : " min"}
                  </Text>
                </Text>
              )}
            </View>
          </View>
          {child[0].is_quiz_done ? (
            <Button
              uppercase={false}
              mode="contained"
              labelStyle={{
                color: "#410DAA",
                fontWeight: "600",
              }}
              onPress={() => {
                this.props.navigation.push("Wellness", {
                  quiz_id: child[0].quiz_id,
                });
              }}
              style={{
                marginTop: 40,
                marginHorizontal: 24,
                borderColor: "#410DAA",
                borderWidth: 1,
                backgroundColor: "white",
                borderRadius: 6,
                height: 37,
                width: "24%",
                marginLeft: "37%",
              }}
            >
              See Results
            </Button>
          ) : (
            <Button
              uppercase={false}
              mode="contained"
              labelStyle={{
                color: "#410DAA",
                fontWeight: "600",
              }}
              onPress={() => {
                this.props.navigation.push("NewQuizz", {
                  quiz_id: child[0].quiz_id,
                  retake: true,
                });
              }}
              style={{
                marginTop: 40,
                marginHorizontal: 24,
                borderColor: "#410DAA",
                borderWidth: 1,
                backgroundColor: "white",
                borderRadius: 6,
                height: 37,
                width: "24%",
                marginLeft: "37%",
              }}
            >
              Take Quiz
            </Button>
          )}
          {is_fetching && (
            <>
              <ActivityIndicator
                size="small"
                color="#410DAA"
                style={{ alignSelf: "center", marginVertical: 15 }}
              />
            </>
          )}
        </View>
        {child && child[1] && (
          <View
            style={{
              marginRight: "3%",
              marginLeft: "1.5%",
              backgroundColor: "white",
              paddingTop: 8,
              marginVertical: "1.5%",
              width: "45.5%",
              height: 210,
              borderWidth: 1,
              borderRadius: 12,
              borderColor: "rgba(65, 13, 170, 0.3)",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                paddingHorizontal: 25,
                paddingVertical: 20,
                height: 90,
              }}
            >
              <View style={{ flex: 1 }}>
                <Image
                  source={{ uri: child[1].quiz_img }}
                  style={{
                    width: 100,
                    height: 100,
                    alignSelf: "flex-start",
                    borderRadius: 4,
                  }}
                  resizeMode={"contain"}
                />
              </View>
              <View
                style={{
                  flex: 1,
                  marginTop: 8,
                  marginLeft: 8,
                }}
              >
                <Text
                  style={{
                    flex: 1,
                    fontSize: 16,
                    color: "#121212",
                    fontWeight: "600",
                    flexWrap: "wrap",
                  }}
                >
                  {child[1].quiz_name}
                </Text>
              </View>
              <View style={{ flex: 1, alignItems: "flex-end" }}>
                {child[1].is_quiz_done ? (
                  Platform.OS == "web" ? (
                    <View
                      style={{
                        marginLeft: 10,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Donut
                        options={{
                          dataLabels: {
                            enabled: false,
                          },
                          legend: {
                            show: false,
                          },
                          tooltip: { enabled: false },
                          colors: ["#876CDD", "#BAC7F2"],
                          plotOptions: {
                            pie: {
                              donut: {
                                size: "60%",
                              },
                            },
                          },
                          stroke: {
                            width: 1,
                            colors: ["#876CDD", "#BAC7F2"],
                          },
                        }}
                        series={[
                          Math.floor(child[1].score),
                          100 - Math.floor(child[1].score),
                        ]}
                        sliceColor={["#876CDD", "#BAC7F2"]}
                        type="donut"
                        width="160"
                      />
                      <Text
                        style={{
                          fontWeight: "600",
                          color: "#121212",
                          fontSize: 12,
                        }}
                      >
                        Score {Math.floor(child[1].score)} / 100
                      </Text>
                      {child[1].is_quiz_done ? (
                        <Text
                          style={{
                            fontSize: 13,
                            color:
                              child[1].quiz_data.quiz_personas != "" &&
                              getStatusColor(
                                child[1].quiz_data.quiz_personas,
                                child[1].quiz_data.persona.persona_name
                              ),
                            fontWeight: "bold",
                            textAlign: "center",
                            marginTop: 5,
                          }}
                        >
                          {child[1].quiz_data.persona.persona_name}
                        </Text>
                      ) : (
                        <Text
                          style={{
                            fontStyle: "italic",
                            fontSize: 16,
                            color: "#707070",
                            fontWeight: "bold",
                          }}
                        >
                          Incomplete
                        </Text>
                      )}
                    </View>
                  ) : (
                    <Donut
                      widthAndHeight={100}
                      series={[
                        Math.floor(child[1].score),
                        100 - Math.floor(child[1].score),
                      ]}
                      sliceColor={["#876CDD", "#BAC7F2"]}
                      doughnut={true}
                      coverRadius={0.6}
                      coverFill={"#FFF"}
                    />
                  )
                ) : (
                  <Text
                    style={{
                      fontWeight: "500",
                      color: "#121212",
                      fontSize: 16,
                    }}
                  >
                    Quiz Time: {this.props.time}
                    <Text style={{ fontSize: 12, fontWeight: "400" }}>
                      {this.props.time > 1 ? " mins" : " min"}
                    </Text>
                  </Text>
                )}
              </View>
            </View>
            {child[1].is_quiz_done ? (
              <Button
                uppercase={false}
                mode="contained"
                labelStyle={{
                  color: "#410DAA",
                  fontWeight: "600",
                }}
                onPress={() => {
                  this.props.navigation.push("Wellness", {
                    quiz_id: child[1].quiz_id,
                  });
                }}
                style={{
                  marginTop: 40,
                  marginHorizontal: 24,
                  borderColor: "#410DAA",
                  borderWidth: 1,
                  backgroundColor: "white",
                  borderRadius: 6,
                  height: 37,
                  width: "24%",
                  marginLeft: "37%",
                }}
              >
                See Results
              </Button>
            ) : (
              <Button
                uppercase={false}
                mode="contained"
                labelStyle={{
                  color: "#410DAA",
                  fontWeight: "600",
                }}
                onPress={() => {
                  this.props.navigation.push("NewQuizz", {
                    quiz_id: child[1].quiz_id,
                    retake: true,
                  });
                }}
                style={{
                  marginTop: 40,
                  marginHorizontal: 24,
                  borderColor: "#410DAA",
                  borderWidth: 1,
                  backgroundColor: "white",
                  borderRadius: 6,
                  height: 37,
                  width: "24%",
                  marginLeft: "37%",
                }}
              >
                Take Quiz
              </Button>
            )}
            {is_fetching && (
              <>
                <ActivityIndicator
                  size="small"
                  color="#410DAA"
                  style={{ alignSelf: "center", marginVertical: 15 }}
                />
              </>
            )}
          </View>
        )}
      </View>
    );
  }
}
class AssessmentHub extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let from = null;
    if (params) {
      from = params.from || null;
    }
    this.state = {
      from,
      fetching: true,
      data: [],
      fetching: false,
      showModal: false,
    };
  }

  async componentDidMount() {
    this.getFinAwareness();
  }

  static navigationOptions = {
    tabBarVisible: false,
  };

  closeItem = () => {
    this.setState({ activeItem: null });
  };

  setActiveTab = async (quiz_id) => {
    this.setState({
      activeItem: quiz_id,
    });
  };

  getFinAwareness = async () => {
    this.setState({
      fetching: true,
    });
    try {
      const response = await getFinAwarenessData();
      if (response && response.code == 100) {
        Store.dispatch({
          type: "UPDATE_FINAWARENESS_DATA",
          payload: response.financial_awareness,
        });
      }
      this.setState({
        fetching: false,
        data: response.financial_awareness,
      });
    } catch (err) {
      this.setState({
        fetching: false,
        response: null,
        activeItem: null,
      });
    }
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  _header() {
    return (
      <NavigationHeader
        navigation={this.props.navigation}
        navigationHeader={["Financial Wellness Assessment Hub"]}
        onPressBack={() => {
          this.props.navigation.goBack();
        }}
      />
    );
  }

  _render_rowItems = () => {
    let new_strips = [];
    const children = this.state.data.final_list;
    let newArr = [];
    if (isMobile) {
      newArr = this.state.data.final_list;
    } else {
      for (let i = 0; i < children.length; i += 2) {
        newArr.push(children.slice(i, i + 2));
      }
    }
    if (newArr) {
      for (let i = 0; i < newArr.length; i++) {
        const child = children[i];
        new_strips.push(
          <FaRowItem
            child={newArr[i]}
            navigationw={this.props.navigation}
            setActiveTab={(id) => this.setActiveTab(id)}
            is_fetching={this.state.fetchingQuizResults}
            activeItem={this.state.activeItem}
            closeItem={this.closeItem}
            toggleModal={this.toggleModal}
            navigation={this.props.navigation}
            time={child.read_time || 2}
          />
        );
      }
      return new_strips;
    }
  };

  _render_score_percentage = (percentage) => {
    return (
      this.any_quiz_taken() && (
        <View
          style={{
            marginHorizontal: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Donut
            options={{
              dataLabels: {
                enabled: false,
              },
              legend: {
                show: false,
              },
              tooltip: { enabled: false },
              colors: ["#876CDD", "#BAC7F2"],
              plotOptions: {
                pie: {
                  donut: {
                    size: "60%",
                  },
                },
              },
              stroke: {
                width: 1,
                colors: ["#876CDD", "#BAC7F2"],
              },
            }}
            series={[percentage, 100 - percentage]}
            sliceColor={["#876CDD", "#BAC7F2"]}
            type="donut"
            width="160"
          />
          <Text
            style={{
              fontWeight: "600",
              color: "#121212",
              fontSize: 14,
            }}
          >
            Overall Financial Wellness {Math.floor(percentage)} / 100
          </Text>
        </View>
      )
    );
  };

  _render_screen() {
    return (
      <View style={{ marginBottom: isMobile ? 80 : 20 }}>
        {this._render_score_percentage(this.state.data.fa_score)}
        {this._render_rowItems()}
      </View>
    );
  }

  any_quiz_taken = () => {
    const response = this.state.data;
    if (response && Object.keys(response).length != 0) {
      const { final_list } = response;
      if (final_list) {
        let taken = false;
        final_list.forEach((item) => {
          taken = taken || item.is_quiz_done;
        });
        return taken;
      }
    } else {
      return null;
    }
  };

  _render_not_completed_screen = () => {
    let new_strips = [];
    const children = this.state.data.final_list;
    let newArr = [];
    if (isMobile) {
      newArr = this.state.data.final_list;
    } else {
      for (let i = 0; i < children.length; i += 2) {
        newArr.push(children.slice(i, i + 2));
      }
    }
    if (newArr) {
      for (let i = 0; i < newArr.length; i++) {
        const child = children[i];
        new_strips.push(
          <FaRowItem
            child={newArr[i]}
            navigationw={this.props.navigation}
            setActiveTab={(id) => this.setActiveTab(id)}
            is_fetching={this.state.fetchingQuizResults}
            activeItem={this.state.activeItem}
            closeItem={this.closeItem}
            toggleModal={this.toggleModal}
            navigation={this.props.navigation}
            time={child.read_time || 2}
          />
        );
      }
      return new_strips;
    }
  };

  getBackgroundColor = (index, percentage, length) => {
    if (index == 0) {
      return "#EF1212";
    } else if (index == 1 && length == 3) {
      return "#FBBC29";
    } else if (index == 1 && length == 4) {
      return "#EF7212";
    } else if (index == 2 && length == 3) {
      return "#009105";
    } else if (index == 2 && length == 4) {
      return "#FBBC29";
    } else if (index == 3 && length == 4) {
      return "#009105";
    }
  };

  renderModalContent = () => {
    const { activeItem } = this.state;
    const fa_data = this.state.data;
    if (!fa_data || (fa_data && Object.keys(fa_data).length == 0)) {
      return;
    }
    const { final_list } = fa_data;
    let activeData =
      final_list && final_list.filter((item) => activeItem == item.quiz_id);
    if (!activeData.length) {
      return;
    }
    activeData = activeData[0].quiz_data;
    let number_of_quiz_personas;
    if (
      activeData &&
      activeData.quiz_personas &&
      activeData.quiz_personas != ""
    ) {
      number_of_quiz_personas = activeData.quiz_personas.length;
    } else {
      return null;
    }
    return (
      <View
        style={{
          width: isMobile ? getWindowSize().width * 0.8 : "auto",
          height: "auto",
        }}
      >
        {activeData &&
          activeData.quiz_personas &&
          activeData.quiz_personas.map((persona, index) => {
            return (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginVertical: 8,
                }}
              >
                <View style={{ flex: 0.15 }}>
                  <Image
                    source={{ uri: persona.persona_image_url }}
                    style={{ width: 35, height: 35, alignSelf: "center" }}
                    resizeMode={"contain"}
                  />
                </View>
                <View style={{ flex: 0.6, paddingLeft: 10 }}>
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      color: "#121212",
                      flexWrap: "wrap",
                    }}
                  >
                    {persona.persona_name}
                  </Text>
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#121212",
                      flexWrap: "wrap",
                    }}
                  >
                    {persona.short_description}
                  </Text>
                </View>
                <View style={{ flex: 0.25, alignItems: "flex-end" }}>
                  <View
                    style={{
                      width: 70,
                      height: 34,
                      backgroundColor: this.getBackgroundColor(
                        index,
                        persona.to_score,
                        number_of_quiz_personas
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: 6,
                      borderBottomLeftRadius: 6,
                    }}
                  >
                    <Text style={{ fontSize: 11, color: "#fff" }}>
                      {index == 0
                        ? "<"
                        : Math.ceil(persona.from_score.toFixed(1)) + "%-"}
                      {Math.ceil(persona.to_score.toFixed(1)) + "%"}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}
      </View>
    );
  };

  render() {
    const header = this._header();
    const quiz_taken = this.any_quiz_taken();
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {header}
        <ScrollView showsVerticalScrollIndicator={false}>
          {
            <ScrollView
              contentContainerStyle={{ flexGrow: 1 }}
              style={styles.cardContainer}
              showsVerticalScrollIndicator={false}
            >
              {this.state.fetching || quiz_taken == null ? (
                <View style={styles.loaderContainer}>
                  <ActivityIndicator size="large" color="#410DAA" />
                </View>
              ) : this.state.data && Object.keys(this.state.data).length ? (
                quiz_taken == true ? (
                  this._render_screen()
                ) : (
                  this._render_not_completed_screen()
                )
              ) : (
                <NotFound />
              )}
            </ScrollView>
          }
        </ScrollView>
        <CustomModal
          visible={this.state.showModal}
          showCloseButton={true}
          closeModal={() => {
            this.toggleModal();
          }}
        >
          {this.renderModalContent()}
        </CustomModal>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    padding: 5,
  },
  modalContentContainer: {
    backgroundColor: "white",
    padding: 8,
    paddingRight: 0,
    borderRadius: 4,
    width: "100%",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default AssessmentHub;
