import React from "react";
import {
	View,
	Text,
	StyleSheet,
	ScrollView,
	TouchableOpacity,
	Platform
} from 'react-native';
import { formatNumberToAbbreviation, getUserDetails, numberWithCommas, reverseString } from "../../../utils";
import { createCrmdealsCipCbiForAccentureClients, createCrmdealsCipForRegular, getCustomerReport, moveDataFromDirectToRegular } from "../../../api";
import Store from "../../../store";
import Header from "../header_after_login";
import ProgressBar from "../../../utils/progressBar";
const Donut =
	Platform.OS == "web"
		? require("react-apexcharts").default
		: require("react-native-pie-chart").default;
import { Entypo } from '@expo/vector-icons';
import PortfolioCardView from "../../../components/InvestmentOverview/NewDesign/PortfolioCardView";

class OnboardingComplete extends React.Component {
	constructor(props) {
		super(props);

		let userDetails = getUserDetails()

		this.state = {
			isLoading: false,
			userDetails,
			mfTypes: {},
			stockTypes: {},
			goalArray: [],
			stockArray: [],
			mfArray: [],
			goalsAmount: 0,
			error: false,
			errorMsg: ""
		}
	}

	componentDidMount = async () => {
		this.setState({ isLoading: true })
		try {
			const { userDetails } = this.state;
			let userId =
				Store.getState().dashboard &&
					Store.getState().dashboard.viewModeUserId &&
					Store.getState().dashboard.viewModeUserId.user_id
					? Store.getState().dashboard.viewModeUserId.user_id
					: String(userDetails.customer_id);
			let payload = {
				previous_key: "customer_imported_portfolio_analysis",
				customer_id: userId
			}
			let response = await getCustomerReport(payload);
			console.log("Response is ", response);

			if (response && response.code == "100") {

				let goalArray = [];
				let stockArray = [];
				let mfArray = [];
				let goalsAmount = 0;
				let resultData = response.result;

				resultData.forEach((item) => {
					if (item.type == "Goals") {
						goalsAmount += item.cost_price;
						goalArray.push(item);
					}
					if (item.type == "Imported Stocks") {
						stockArray.push(item);
					}
					if (item.type == "Imported Funds") {
						mfArray.push(item);
					}
				})
				console.log("Stock array ", goalsAmount)
				let stockTypes = {
					"May be at risk": {
						data: [],
						sum: 0,
						perc: 0,
						color: "#F24805"
					},
					"Watch": {
						data: [],
						sum: 0,
						perc: 0,
						color: "#F2EA05"
					},
					"Hold": {
						data: [],
						sum: 0,
						perc: 0,
						color: "#05F215"
					},
				}
				stockArray.forEach((item) => {
					let fieldName = item.VIEW
					// if (!stockTypes.hasOwnProperty(fieldName)) {
					// 	stockTypes[fieldName] = {
					// 		data: [item],
					// 		sum: item.imported_valuation != "" ? Number(item.imported_valuation) : 0,
					// 		perc: (1 / stockArray.length) * 100,
					// 		color: item.VIEW_COLOR
					// 	}
					// } else {
					// 	stockTypes[fieldName].data.push(item);
					// 	stockTypes[fieldName].sum += item.imported_valuation != "" ? Number(item.imported_valuation) : 0;
					// 	stockTypes[fieldName].perc = ((stockTypes[fieldName].data.length) / stockArray.length) * 100;
					// }
					if(item.VIEW.includes("May be at risk")) {
						stockTypes[fieldName].data.push(item);
						stockTypes[fieldName].sum += item.imported_valuation != "" ? Number(item.imported_valuation) : 0;
						stockTypes[fieldName].perc = ((stockTypes[fieldName].data.length) / stockArray.length) * 100;
						// stockTypes[fieldName].color = item.VIEW_COLOR	
					}
					if(item.VIEW.includes("Watch")) {
						stockTypes[fieldName].data.push(item);
						stockTypes[fieldName].sum += item.imported_valuation != "" ? Number(item.imported_valuation) : 0;
						stockTypes[fieldName].perc = ((stockTypes[fieldName].data.length) / stockArray.length) * 100;
						// stockTypes[fieldName].color = item.VIEW_COLOR
					}
					if(item.VIEW.includes("Hold")) {
						stockTypes[fieldName].data.push(item);
						stockTypes[fieldName].sum += item.imported_valuation != "" ? Number(item.imported_valuation) : 0;
						stockTypes[fieldName].perc = ((stockTypes[fieldName].data.length) / stockArray.length) * 100;
						// stockTypes[fieldName].color = item.VIEW_COLOR
					}
				})

				console.log("Value is ", stockTypes);
				let mfTypes = {
					"May be at risk": {
						data: [],
						sum: 0,
						perc: 0,
						color: "#F24805"
					},
					"Watch": {
						data: [],
						sum: 0,
						perc: 0,
						color: "#F2EA05"
					},
					"Hold": {
						data: [],
						sum: 0,
						perc: 0,
						color: "#05F215"
					},
				};
				mfArray.forEach((item) => {
					let fieldName = item.VIEW
					// if (!mfTypes.hasOwnProperty(fieldName)) {
					// 	mfTypes[fieldName] = {
					// 		data: [item],
					// 		sum: item.imported_valuation != "" ? Number(item.imported_valuation) : 0,
					// 		perc: (1 / mfArray.length) * 100,
					// 		color: item.VIEW_COLOR
					// 	}
					// } else {
					// 	mfTypes[fieldName].data.push(item);
					// 	mfTypes[fieldName].sum += item.imported_valuation != "" ? Number(item.imported_valuation) : 0;
					// 	mfTypes[fieldName].perc = ((mfTypes[fieldName].data.length) / mfArray.length) * 100;
					// }
					if(item.VIEW.includes("May be at risk")) {
						stockTypes[fieldName].data.push(item);
						stockTypes[fieldName].sum += item.imported_valuation != "" ? Number(item.imported_valuation) : 0;
						stockTypes[fieldName].perc = ((stockTypes[fieldName].data.length) / stockArray.length) * 100;
						// stockTypes[fieldName].color = item.VIEW_COLOR	
					}
					if(item.VIEW.includes("Watch")) {
						stockTypes[fieldName].data.push(item);
						stockTypes[fieldName].sum += item.imported_valuation != "" ? Number(item.imported_valuation) : 0;
						stockTypes[fieldName].perc = ((stockTypes[fieldName].data.length) / stockArray.length) * 100;
						// stockTypes[fieldName].color = item.VIEW_COLOR
					}
					if(item.VIEW.includes("Hold")) {
						stockTypes[fieldName].data.push(item);
						stockTypes[fieldName].sum += item.imported_valuation != "" ? Number(item.imported_valuation) : 0;
						stockTypes[fieldName].perc = ((stockTypes[fieldName].data.length) / stockArray.length) * 100;
						// stockTypes[fieldName].color = item.VIEW_COLOR
					}
				})
				console.log("Value is ", mfTypes);

				this.setState({
					isLoading: false,
					goalArray,
					stockArray,
					mfArray,
					goalsAmount,
					stockTypes,
					mfTypes,
					error: false,
					errorMsg: ""
				})
			} else {
				this.setState({
					isLoading: false,
					error: true,
					errorMsg: "Something went wrong"
				})
			}
		} catch (err) {
			console.log("Error in OnBoardingComplete", err);
			this.setState({
				isLoading: false,
				error: true,
				errorMsg: "Something went wrong"
			})
		}
	}

	handleScheduleMeeting = () => {
		this.props.navigation.navigate("ScheduleCall", {
			selected: "DE",
			from_onboarding: true
		});
	}

	handleContinueToLogout = async () => {
		try {
			localStorage.removeItem("isShowAppDownloadCard");
			localStorage.removeItem("showPlanRenewalCard");
			localStorage.removeItem("redirectAfterOnboardingSuccess");
			Store.dispatch({ type: LOGOUT });
		} catch (error) {
			Store.dispatch({ type: LOGOUT });
		}
	};

	returnPercentageForDonut = (stockTypes) => {

		let percen = [];
		for (let key in stockTypes) {
			if (stockTypes.hasOwnProperty(key)) {
				percen.push(Number(Number(stockTypes[key].perc).toFixed(0)))
			}
		}
		console.log("local check is ", percen);
		return percen;
	}

	returnColorForDonut = (stockTypes) => {

		let color = [];
		for (let key in stockTypes) {
			if (stockTypes.hasOwnProperty(key)) {
				color.push(stockTypes[key].color)
			}
		}
		return color;
	}

	handleContinue = async () => {
		this.setState({ isLoading: true })
		let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
		let userDetails = getUserDetails();
		if (hostname == "app.jamawealth.com" || hostname == "myadvisor.jamawealth.com" || hostname == "dapp.jamawealth.com" || hostname == "dmyadvisor.jamawealth.com" || hostname == "mobile" || hostname == "localhost") {
			// moveDataFromDirectToRegular
			const payload = {
				cust_pk: userDetails.pk
			}

			await createCrmdealsCipCbiForAccentureClients()
			// if (false) {
			// 	this.props.navigation.navigate("AdvisoryPlanHome")
			// } else {
			// 	let response = await moveDataFromDirectToRegular(payload)

			// 	if (response && response.code == 100) {
			// 		this.setState({
			// 			showToast: true,
			// 			toastMessage: response.result,
			// 			migrating_data: false,
			// 		})
			// 		let url = "https://dclub.jamawealth.com/login";
			// 		if (response.token) {
			// 			let reverseToken = reverseString(response.token);
			// 			url = url + "?unique=" + reverseToken
			// 		}
			// 		setTimeout(() => {
			// 			this.handleContinueToLogout();
			// 			global.open(url, "_blank");
			// 		}, 5000);
			// 	} else {
			// 		console.log(response.msg);
			// 		this.setState({
			// 			isLoading: false,
			// 			error: true,
			// 			migrating_data: false
			// 		})
			// 	}
			// }
			this.props.navigation.navigate("App", {
				screen: "Dashboard",
				params: {
					screen: "Dashbaord",
					params: {}
				}
			})
			this.setState({ isLoading: false })
		} else {
			const crmcippayload = {
				plancode: plan_code,
			};
			let responce = await createCrmdealsCipForRegular();
			// if(responce && responce.code == 100) {
			this.props.navigation.navigate("App", {
				screen: "Dashboard",
				params: {
					screen: "Dashbaord",
					params: {}
				}
			})
			// }
		}

	}

	render() {
		return this.state.isLoading ? (
			<View style={{ flex: 1 }}>
				<ProgressBar msg={"Loading Onboarding details..."} />
			</View>
		) : (
			<View style={styles.container}>
				<Header
					navigation={this.props.navigation}
					route={this.props.route}
					showbackBtn={false}
				/>
				<ScrollView>
					<View style={{ padding: 20 }}>
						<Text style={[styles.heading, { color: 'green' }]}>Your quick onboarding is complete!</Text>
					</View>

					<PortfolioCardView
						itemName={"Stocks"}
						itemCount={0}
						itemType={this.state.stockTypes}
						returnColorForDonut={this.returnColorForDonut(this.state.stockTypes)}
						returnPercentageForDonut={this.returnPercentageForDonut(this.state.stockTypes)}
					/>
					<View style={{ padding: 20 }}>
						<Text style={[styles.subHeading, { color: "#410DAA" }]}>Portfolio Analysis</Text>
						<View style={styles.rowDivision}>
							<View style={styles.halfContainer}>
								<View style={styles.rowDivision}>
									<Text style={[styles.subHeading, { width: '70%' }]}>Stocks</Text>
									{/* <Text style={[styles.subHeading, { color: "#410DAA" }]}>{this.state.stockArray.length}</Text> */}
								</View>
								{this.state.stockArray.length > 0 && (Platform.OS == "web" ? (
									<View
										style={{
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "row",
										}}
									>
										<Donut
											options={{
												dataLabels: {
													enabled: true,
													formatter: function (val) {
														return Number(val).toFixed(0).toString() + "%"
													},

													// style: {
													// 	colors: ["#1a1a1a","#1a1a1a","#1a1a1a"]
													// }
												},

												legend: {
													show: false,
												},
												tooltip: { enabled: false },
												colors: this.returnColorForDonut(this.state.stockTypes),
												plotOptions: {
													pie: {
														donut: {
															size: "40%",
														},
													},
												},
												stroke: {
													width: 1,
													colors: this.returnColorForDonut(this.state.stockTypes),
												},
											}}
											series={this.returnPercentageForDonut(this.state.stockTypes)}
											sliceColor={this.returnColorForDonut(this.state.stockTypes)}
											type="donut"
											width="170"
										/>
									</View>
								) : (
									<Donut
										widthAndHeight={100}
										series={this.returnPercentageForDonut(this.state.stockTypes)}
										sliceColor={this.returnColorForDonut(this.state.stockTypes)}
										doughnut={true}
										coverRadius={0.6}
										coverFill={"#FFF"}
									/>
								))}
								{this.state.stockArray.length <= 0 ? (
									<View style={{
										marginTop: 50,
										width: '100%',
										justifyContent: 'center',
										alignItems: 'center'
									}}>
										<TouchableOpacity style={{
											backgroundColor: '#410DAA',
											paddingVertical: 10,
											width: 150,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 5
										}}
											onPress={() => {
												this.props.navigation.navigate("SignUpImportPortfolio", {
													step: 2
												})
											}}>
											<Text style={{ color: '#fff', textAlign: 'center' }}>Import Now</Text>
										</TouchableOpacity>
									</View>
								) : (
									<View style={{ marginTop: 30 }}>
										<View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
											<View style={{ width: '20%' }}></View>
											{Object.entries(this.state.stockTypes).map(([key, value]) => {
												return (<View key={key} style={{ width: '20%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
													<Entypo name="dot-single" size={30} color={value.color} />
													<Text style={{ textAlign: 'center', color: "#1a1a1a", fontWeight: '600' }}>{key}</Text>
												</View>)
											})}
										</View>
										<View style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10 }}>
											<View style={{ width: '20%' }}><Text style={{ color: "#1a1a1a", fontWeight: '600' }}>Value</Text></View>
											{Object.entries(this.state.stockTypes).map(([key, value]) => {
												return (<View key={key} style={{ width: '20%', alignItems: 'center', justifyContent: 'center' }}>
													<Text style={{ textAlign: 'center' }}>₹ {numberWithCommas(value.sum.toFixed(0).toString())}</Text>
												</View>)
											})}
										</View>
										<View style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10 }}>
											<View style={{ width: '20%' }}><Text style={{ color: "#1a1a1a", fontWeight: '600' }}>Stocks</Text></View>
											{Object.entries(this.state.stockTypes).map(([key, value]) => {
												return (<View key={key} style={{ width: '20%', alignItems: 'center', justifyContent: 'center' }}>
													<Text style={{ textAlign: 'center' }}>{value.data.length}</Text>
												</View>)
											})}
										</View>
									</View>)}
							</View>
							<View style={styles.halfContainer}>
								<View style={styles.rowDivision}>
									<Text style={[styles.subHeading, { width: '70%' }]}>Mutual Funds</Text>
								</View>
								{this.state.mfArray.length > 0 && (Platform.OS == "web" ? (
									<View
										style={{
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "row",
										}}
									>
										<Donut
											options={{
												dataLabels: {
													enabled: true,
													formatter: function (val) {
														return Number(val).toFixed(0).toString() + "%"
													},

													// style: {
													// 	colors: ["#1a1a1a","#1a1a1a","#1a1a1a"]
													// }
												},
												legend: {
													show: false,
												},
												tooltip: { enabled: false },
												colors: this.returnColorForDonut(this.state.mfTypes),
												plotOptions: {
													pie: {
														donut: {
															size: "40%",
														},
													},
												},
												stroke: {
													width: 1,
													colors: this.returnColorForDonut(this.state.mfTypes),
												},
											}}
											series={this.returnPercentageForDonut(this.state.mfTypes)}
											sliceColor={this.returnColorForDonut(this.state.mfTypes)}
											type="donut"
											width="170"
										/>
									</View>
								) : (
									<Donut
										widthAndHeight={100}
										series={this.returnPercentageForDonut(this.state.mfTypes)}
										sliceColor={this.returnColorForDonut(this.state.mfTypes)}
										doughnut={true}
										coverRadius={0.6}
										coverFill={"#FFF"}
									/>
								))}
								{this.state.mfArray.length <= 0 ? (
									<View style={{
										marginTop: 50,
										width: '100%',
										justifyContent: 'center',
										alignItems: 'center'
									}}>
										<TouchableOpacity style={{
											backgroundColor: '#410DAA',
											paddingVertical: 10,
											width: 150,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 5
										}}
											onPress={() => {
												this.props.navigation.navigate("SignUpImportPortfolio", {
													step: 3
												})
											}}>
											<Text style={{ color: '#fff', textAlign: 'center' }}>Import Now</Text>
										</TouchableOpacity>
									</View>
								) : (<View style={{ marginTop: 30 }}>
									<View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
										<View style={{ width: '20%' }}></View>
										{Object.entries(this.state.mfTypes).map(([key, value]) => {
											return (<View key={key} style={{ width: '20%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
												<Entypo name="dot-single" size={30} color={value.color} />
												<Text style={{ textAlign: 'center', color: "#1a1a1a", fontWeight: '600' }}>{key}</Text>
											</View>)
										})}
									</View>
									<View style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10 }}>
										<View style={{ width: '20%' }}><Text style={{ color: "#1a1a1a", fontWeight: '600' }}>Value</Text></View>
										{Object.entries(this.state.mfTypes).map(([key, value]) => {
											return (<View key={key} style={{ width: '20%', alignItems: 'center', justifyContent: 'center' }}>
												<Text style={{ textAlign: 'center' }}>₹ {numberWithCommas(value.sum.toFixed(0).toString())}</Text>
											</View>)
										})}
									</View>
									<View style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10 }}>
										<View style={{ width: '20%' }}><Text style={{ color: "#1a1a1a", fontWeight: '600' }}>Funds</Text></View>
										{Object.entries(this.state.mfTypes).map(([key, value]) => {
											return (<View key={key} style={{ width: '20%', alignItems: 'center', justifyContent: 'center' }}>
												<Text style={{ textAlign: 'center' }}>{value.data.length}</Text>
											</View>)
										})}
									</View>
								</View>)}
							</View>
						</View>
					</View>
					<View style={{ padding: 20 }}>
						<Text style={[styles.subHeading, { color: "#410DAA" }]}>Financial Plan</Text>
						<View style={{ display: 'flex', flexDirection: 'row' }}>
							<View style={styles.card}>
								<Text style={styles.subHeading}>Goals Created</Text>
								<Text style={[styles.subHeading, { textAlign: 'center', marginTop: 10 }]}>{this.state.goalArray.length}</Text>
							</View>
							<View style={styles.card}>
								<Text style={styles.subHeading}>Goals Corpus*</Text>
								<Text style={[styles.subHeading, { textAlign: 'center', color: '#410DAA', marginTop: 10 }]}>₹ {formatNumberToAbbreviation(this.state.goalsAmount.toString())}</Text>
							</View>
						</View>
						<Text style={{
							color: '#1a1a1a',
							marginLeft: 10
						}}>*Corpus = amount required when all goals are due</Text>
					</View>
					<View style={{ display: 'flex', flexDirection: 'row', marginLeft: 30 }}>
						<Text>I am intersted in customised solutions / PMS - schedule a call for me {"  "}</Text>
						<TouchableOpacity onPress={this.handleScheduleMeeting}>
							<Text style={{ color: '#410DAA', textDecorationLine: 'underline', fontWeight: '500' }}>Schedule Call</Text>
						</TouchableOpacity>
					</View>
					<View style={{
						width: '100%',
						padding: 50,
						justifyContent: 'center',
						alignItems: 'center'
					}}>
						<TouchableOpacity style={{
							backgroundColor: '#410DAA',
							paddingVertical: 15,
							width: 150,
							justifyContent: 'center',
							alignItems: 'center',
							borderRadius: 5
						}}
							onPress={this.handleContinue}>
							<Text style={{ color: '#fff', textAlign: 'center' }}>Continue</Text>
						</TouchableOpacity>
					</View>
				</ScrollView>
			</View>
		)
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#ffffff'
	},
	heading: {
		color: "#1a1a1a",
		fontSize: 28,
		fontWeight: '700'
	},
	subHeading: {
		color: "#484848",
		fontSize: 22,
		fontWeight: '700'
	},
	rowDivision: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: 20
	},
	halfContainer: {
		width: '45%',
		marginRight: 50,
	},
	card: {
		backgroundColor: '#fff',
		borderRadius: 8,
		padding: 16,
		margin: 16,
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.3,
		shadowRadius: 4,
		elevation: 5,
	}
})

export default OnboardingComplete;