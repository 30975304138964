import React, { useRef } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Text } from "react-native-paper";
import { sm, md, lg, xl, twoxl } from "../../Responsive";
import ContactForm from "./contactForm";
import ClientReviews from "./clientReviews";
import BackToTop from "./backToTop";
import clients from "./clients";
import MyHeader from "./upgradePlanHeader";

export default function MySubscriptionContactPage(props) {
    const scrollRef = useRef(null);
    let forQuery = null;
    const params = props.route;
    // console.log(params)
    if (params) {
        forQuery = params.params.for_query || false;
    }
    // console.log(props)

    return (
        <>
            <View
            style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                zIndex: 1
            }}
            >
                <MyHeader navigation={props.navigation} />
            </View>
            <ScrollView style={styles.container} ref={scrollRef}>
                <Text style={styles.mainHeading}>
                    {!forQuery ? "Contact Our Sales" : "Contact Us"}
                </Text>
                <View style={styles.bottomBorder}></View>
                <ContactForm props={props}></ContactForm>
                <View style={styles.hr}></View>
                <ClientReviews
                    clients={clients}
                    title={"What they say?"}
                ></ClientReviews>
                <View style={styles.backToTopContainer}>
                    <BackToTop target={scrollRef}></BackToTop>
                </View>
            </ScrollView>
        </>        
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: "white",
        marginTop: 55
    },
    backToTopContainer: {
        backgroundColor: md(window.width) ? "#FDFDFDFD" : undefined,
        paddingTop: 40,
        paddingBottom: md(window.width) ? 40 : 80
    },
    hr: {
        height: md(window.width) ? undefined : 1,
        backgroundColor: "#CCC",
        width: md(window.width) ? 1 : undefined,
        marginTop: md(window.width) ? undefined : 30
        // marginBottom: Platform.OS === 'web' ? undefined: undefined
    },
    mainHeading: {
        marginTop: 30,
        marginBottom: 5,
        alignSelf: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 20,
        color: "#410DAA",
        paddingLeft: 16,
        paddingRight: 16
    },
    bottomBorder: {
        height: 2,
        alignSelf: "center",
        width: 100,
        backgroundColor: "#410DAA",
        marginBottom: 30
    }
});