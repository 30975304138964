import React from "react";
import { View, StyleSheet, Image, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";
import { isMobile } from "../../../utils";

const DetailsCard = ({ data = {}, ...props }) => {
  const handleHelp = () => {
    props.navigation.navigate("App", {
      screen: "Dashboard",
      params: {
        screen: "Dashboard",
        params: {
          screen: "GetHelp",
          params: {},
        },
      },
    });
  };

  return (
    <View style={styles.container}>
      <Text style={styles.terms}>{data.title}</Text>
      <Text style={styles.please}>{data.description}</Text>
      <Image source={{ uri: data.image }} style={styles.left_img} />
      <View style={styles.securityContainer}>
        <Image
          source={
            "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/fluent_lock-shield-20-regular.png"
          }
          resizeMode="contain"
          style={styles.lock}
        />
        <Text style={styles.jam_we}>
          Information is encrypted using 256-bit keys and stored securely
        </Text>
      </View>
      <Text style={styles.any}>
        Any Queries?{" "}
        <TouchableOpacity onPress={handleHelp}>
          <Text style={styles.getHelp}>Get Help</Text>
        </TouchableOpacity>
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },
  lock: {
    resizeMode: "contain",
    width: 30,
    height: 30,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: !isMobile ? 18 : 15,
    lineHeight: !isMobile ? 21 : 17,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: !isMobile ? 15 : 12,
    lineHeight: !isMobile ? 17 : 14,
    color: "#464E5F",
    paddingTop: !isMobile ? 12 : 6,
    paddingBottom: !isMobile ? 32 : 0,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  securityContainer: {
    width: "100%",
    flexDirection: "row",
    maxWidth: 430,
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.2)",
    backgroundColor: "#ffffff",
    paddingVertical: 21,
    paddingLeft: 20,
    paddingRight: 6,
    marginTop: 33,
    marginBottom: 22,
  },
  getHelp: { color: "#410DAA", fontWeight: "bold" },
});

export default DetailsCard;
