import React, { Component } from "react";
import {
  View,
  StatusBar,
  StyleSheet,
  Image,
  Text,
  Platform,
  ImageBackground,
  TouchableOpacity,
} from "react-native";
const Swiper =
  Platform.OS == "web" ? null : require("react-native-swiper").default;
import * as RootNavigation from "../../../RootNavigation";
import { SafeAreaView } from "react-native-safe-area-context";
import Store from "../../store";
import { isMobile } from "../../utils";

const ONBOARD_DATA = [
  {
    heading: "Personal Wealth Advisor",
    descriptions: ["Trusted   Family office"],
    tags: ["No indirect fees", "No commission", "No touch money"],
    description: [
      "Create a Financial Plan to see the big picture.",
      " It's easier to make financial decisions and stay on track to meet your goals.",
    ],
    image: require("../../assets/splash_images/personal_wealth_advisor.png"),
    image_dim: {
      width: 260,
      height: 160,
    },
  },

  {
    heading: "Manage All Investments",
    descriptions: ["Track & Transact for Free", "Manage Entire Family"],
    tags: ["Stocks", "Funds", "Bonds", "ETFs", "EPFs", "NPS", "Other Assets"],
    description: [
      "Top quality stocks and funds picked exclusively for your requirements by SEBI registered advisors and industry experts. Outperformed NIFTY TRI by 24%** CAGR.",
    ],
    image: require("../../assets/splash_images/manage_investments.png"),
    image_dim: {
      width: 230,
      height: 130,
    },
  },
  {
    heading: "Make Fresh Investments",
    descriptions: [
      "Curated Stocks & Mutual Funds",
      "Personalized Investment Plans",
    ],
    tags: ["Lumpsum", "SIP", "Switch", "Withdraw"],
    description: [
      "Track all your investments conviniently at one place. Get timely payment reminders so you never miss one when its due.",
    ],
    image: require("../../assets/splash_images/manage_fresh_inv.png"),
    image_dim: {
      width: 230,
      height: 130,
    },
  },
  {
    heading: "Plan Your Future",
    descriptions: ["Live Goal Progress", "Course Corrections"],
    tags: ["Complete Financial Plan", "Child Plan", "Retirement"],
    description: [
      "Track all your investments conviniently at one place. Get timely payment reminders so you never miss one when its due.",
    ],
    image: require("../../assets/splash_images/plan_future.png"),
    image_dim: {
      width: 230,
      height: 130,
    },
  },
  {
    heading: "Be a Better Investor",
    descriptions: [
      "Check & Improve Financial Wellness",
      "Portfolio Insights, Risk Alerts",
    ],
    tags: ["Learning Academy", "Wellness Center"],
    description: [
      "For all money matters, schedule a call with our SEBI registered advisors and get exclusive advice for your needs and requirements.",
    ],
    image: require("../../assets/splash_images/better_investor.png"),
    image_dim: {
      width: 230,
      height: 130,
    },
  },
];

const FeatureItem = ({
  heading,
  image,
  descriptions,
  tags,
  style,
  image_dim,
}) => {
  return (
    <View
      style={[
        {
          flexDirection: "column",
        },
        style ? { ...style } : {},
      ]}
    >
      <View style={{ flex: 0.7 }}>
        <View
          style={{
            flex: 1,
            marginHorizontal: 16,
            backgroundColor: "#F8F5FF",
            borderBottomLeftRadius: 30,
            borderBottomRightRadius: 30,
            justifyContent: "flex-end",
            zIndex: 1,
          }}
        >
          <Image
            source={image}
            style={{
              width: image_dim.width || 230,
              height: image_dim.height || 130,
              alignSelf: "center",
              marginBottom: 50,
            }}
            resizeMode={"contain"}
          />
        </View>
        <View
          style={{
            position: "absolute",
            bottom: -10,
            left: 0,
            right: 0,
            backgroundColor: "#F2ECFF",
            zIndex: -10,
            height: 100,
            marginHorizontal: 29,
            borderBottomLeftRadius: 19,
            borderBottomRightRadius: 19,
          }}
        ></View>
      </View>
      <View style={{ alignItems: "center" }}>
        <Text
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 20,
            marginVertical: 20,
            marginTop: 40,
            color: "#121212",
          }}
        >
          {heading}
        </Text>
        <View style={{ alignItems: "center", marginTop: 10 }}>
          {descriptions.map((text) => {
            return (
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 16,
                  color: "#410DAA",
                  width: isMobile ? "95%" : "100%",
                  marginTop: 8,
                }}
              >
                {text}
              </Text>
            );
          })}
        </View>
        <View
          style={{
            alignItems: "center",
            marginTop: 18,
            flexDirection: "row",
            flexWrap: "wrap",
            width: "80%",
            marginRight: -15,
            justifyContent: "center",
          }}
        >
          {tags.map((tag) => {
            return (
              <View
                style={{
                  borderColor: "#a8a8a8",
                  borderWidth: 1,
                  borderRadius: 5,
                  marginRight: 15,
                  alignSelf: "center",
                  marginTop: 15,
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    color: "#a8a8a8",
                    paddingHorizontal: 8,
                    paddingVertical: 6,
                  }}
                >
                  {tag}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};

export default class OnBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  handleSkip = async () => {
    Store.dispatch({
      type: "SHOW_GET_STARTED",
      payload: { shouldShow: "false" },
    });

    RootNavigation.navigate("Login");
  };

  handlePrevious = () => {
    const { activeIndex } = this.state;
    const previous = activeIndex;
    if (activeIndex > 0) {
      this.setState(
        {
          activeIndex: activeIndex - 1,
        },
        () => {
          this.swipeTab(this.state.activeIndex, previous);
        }
      );
    }
  };

  handleNext = () => {
    const { activeIndex } = this.state;
    const previous = activeIndex;
    if (activeIndex < 5) {
      this.setState(
        {
          activeIndex: activeIndex + 1,
        },
        () => {
          this.swipeTab(this.state.activeIndex, previous);
        }
      );
    }
  };

  tabChanged = (index) => {
    this.setState({
      activeIndex: index,
    });
  };

  swipeTab = (index, previous, callback) => {
    if (this.swiper && this.swiper.scrollView) {
      if (Platform.OS === "android") {
        this.swiper.scrollBy(index - previous, true);
        callback && callback();
      } else {
        this.swiper.scrollView.scrollTo({ x: this.swiper.state.width * index });
        callback && callback();
      }
    }
  };

  render() {
    const { activeIndex } = this.state;
    return (
      <SafeAreaView
        style={{ flex: 1, backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {Platform.OS != "web" ? (
          <Swiper
            scrollEnabled={false}
            ref={(c) => (this.swiper = c)}
            style={styles.wrapper}
            showsButtons={false}
            pagingEnabled={false}
            onIndexChanged={(index) => this.tabChanged(index)}
            showsPagination={false}
            loop={false}
          >
            {ONBOARD_DATA.map((item, index) => {
              return (
                <FeatureItem
                  index={index}
                  heading={item.heading}
                  image={item.image}
                  descriptions={item.descriptions}
                  tags={item.tags}
                  image_dim={item.image_dim}
                  activeIndex={this.state.activeIndex}
                  style={{ flex: 1 }}
                />
              );
            })}
          </Swiper>
        ) : (
          <View style={{ marginBottom: 50 }}>
            {ONBOARD_DATA.map((item, index) => {
              return (
                <FeatureItem
                  index={index}
                  heading={item.heading}
                  image={item.image}
                  descriptions={item.descriptions}
                  tags={item.tags}
                  image_dim={item.image_dim}
                  activeIndex={this.state.activeIndex}
                  style={{ flex: 1, marginTop: 80 }}
                />
              );
            })}
          </View>
        )}
        {Platform.OS != "web" ? (
          <View
            style={{
              flex: 1,
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            {activeIndex == 4 ? (
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#410DAA",
                }}
                onPress={() => {
                  this.handleSkip();
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: 14,
                    paddingVertical: 20,
                  }}
                >
                  Get Started
                </Text>
              </TouchableOpacity>
            ) : (
              <ImageBackground
                resizeMode="cover"
                source={require("../../assets/splash_images/bottom_button_shadow.png")}
                style={{
                  width: "100%",
                  height: 60,
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingHorizontal: 16,
                    marginVertical: 15,
                    marginTop: 20,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      flex: 0.3,
                    }}
                    onPress={() => {
                      if (this.state.activeIndex == 0) this.handleSkip();
                      else {
                        this.handlePrevious();
                      }
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#9E9E9E",
                        textAlign: "left",
                      }}
                    >
                      {this.state.activeIndex == 0 ? "SKIP" : "PREV"}
                    </Text>
                  </TouchableOpacity>
                  <View
                    style={{
                      flex: 0.4,
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <View
                        style={
                          activeIndex == 0
                            ? { ...styles.activeDotStyle }
                            : { ...styles.dotStyle }
                        }
                      ></View>
                      <View
                        style={
                          activeIndex == 1
                            ? { ...styles.activeDotStyle }
                            : { ...styles.dotStyle }
                        }
                      ></View>
                      <View
                        style={
                          activeIndex == 2
                            ? { ...styles.activeDotStyle }
                            : { ...styles.dotStyle }
                        }
                      ></View>
                      <View
                        style={
                          activeIndex == 3
                            ? { ...styles.activeDotStyle }
                            : { ...styles.dotStyle }
                        }
                      ></View>
                      <View
                        style={
                          activeIndex == 4
                            ? { ...styles.activeDotStyle }
                            : { ...styles.dotStyle }
                        }
                      ></View>
                    </View>
                  </View>
                  <TouchableOpacity
                    style={{
                      flex: 0.3,
                    }}
                    onPress={() => {
                      this.handleNext();
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#410DAA",
                        textAlign: "right",
                      }}
                    >
                      NEXT
                    </Text>
                  </TouchableOpacity>
                </View>
              </ImageBackground>
            )}
          </View>
        ) : (
          <View
            style={{
              flex: 1,
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <TouchableOpacity
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#410DAA",
              }}
              onPress={() => {
                this.handleSkip();
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontSize: 14,
                  paddingVertical: 20,
                }}
              >
                Get Started
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  activeDotStyle: {
    width: 20,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: "#410DAA",
    marginHorizontal: 3.5,
  },
  dotStyle: {
    width: 5,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: "#BCBCBC",
    marginHorizontal: 3.5,
  },
  wrapper: {},
  slide: {
    flex: 1,
  },
  slide1: {
    flex: 1,
  },
  slide2: {
    flex: 1,
  },
});
