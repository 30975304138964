import React from "react";
import { View } from "react-native";

export const ViewSeparator = ({ height, backgroundColor }) => {
  return (
    <View
      style={{
        backgroundColor: backgroundColor || "#F6F6F6",
        width: "100%",
        height: height || 20
      }}
    ></View>
  );
};