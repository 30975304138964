import React from "react";
import {
  Dimensions,
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Text } from "react-native-paper";
import { md } from "../../Responsive";
import global from "./globalStyles";

const rnWindow = Dimensions.get("window");

export default function PlanBanner({
  plan,
  displayName,
  callToAction,
  question,
  navigation,
}) {
  let color = "#EEE";
  switch (plan) {
    case "gold":
      color = "#F3A915";
      break;
    case "platinum":
      color = "#1CA098";
      break;
    case "jewel":
      color = "#410DAA";
      break;
    default:
      color = "#EEE";
      break;
  }

  return (
    <>
      <View style={[styles.container, { backgroundColor: color }]}>
        <Text style={[styles.title, global.arial]}>{question}</Text>
        <Text
          style={[styles.sub, global.arial]}
        >{`Get ahead with Jamā ${displayName}`}</Text>
        <TouchableOpacity
          onPress={() => {
            if (plan === "jewel") {
              navigation.navigate("MySubscriptionContactPage", {
                for_query: false,
              });
            } else {
              if (plan === "gold") {
                Linking.openURL("https://www.maxiom.co.in/app/jfp/PP/");
              } else if (plan === "platinum") {
                Linking.openURL("https://www.maxiom.co.in/app/jfp/PLA/");
              }
            }
          }}
        >
          <Text style={[styles.link, { color: color }, global.arial]}>
            {callToAction}
          </Text>
        </TouchableOpacity>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    color: "white",
    fontWeight: "bold",
    width: 211,
    alignSelf: "center",
  },
  sub: {
    fontSize: 20,
    textAlign: "center",
    color: "white",
    marginTop: 21,
  },
  link: {
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: "white",
    borderRadius: 5,
    alignSelf: "center",
    fontWeight: "bold",
    fontSize: 15,
    marginTop: 21,
    marginLeft: md(rnWindow.width) ? undefined : 16,
    marginRight: md(rnWindow.width) ? undefined : 16,
    alignSelf: Platform.OS === "web" ? "center" : "stretch",
    paddingLeft: Platform.OS === "web" ? 60 : undefined,
    paddingRight: Platform.OS === "web" ? 60 : undefined,
    textAlign: "center",
    maxWidth: Platform.OS === "web" ? 300 : undefined,
  },
});
