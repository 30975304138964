import React, { useRef, useState } from "react";
import { Dimensions, ScrollView, StyleSheet, View } from "react-native";
import StandsOut from "./standOut";
import ClientReviews from "./clientReviews";
import BackToTop from "./backToTop";
import PlanBanner from "./planBanner";
import AUA from "./aua";
import PlanAdvantages from "./planAdvantages";
import PlanHeader from "./planHeader";
import FeatureHeading from "./featureHeading";
import PlanFeatures from "./planFeatures";
import clients from "./clients";
import plansData from "./plans";
import { md } from "../../Responsive";
import NavigationHeader from "../../components/Common/NavigationHeader";

const window = Dimensions.get("window");

export default function MySubscriptionPlanPage(props) {
  let plan = null;
  const params = props.route;
  if (params) {
    plan = params.params.plan || "gold";
  }

  const currentPlanData = plansData.find((p) => p.name === plan);
  let i = plansData.findIndex((p) => p.name === plan) - 1;
  const previousPlanData = plansData[i];
  const scrollRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <NavigationHeader
        navigation={props.navigation}
        navigationHeader={[""]}
        onPressBack={() => {
          props.navigation.goBack();
        }}
      />
      <ScrollView style={styles.mainContainer} ref={scrollRef}>
        <View>
          <PlanHeader
            scrollRef={scrollRef}
            plan={currentPlanData}
            navigation={props.navigation}
            setIsModalOpen={setIsModalOpen}
          ></PlanHeader>
        </View>
        <FeatureHeading
          message={currentPlanData.featureHeading}
        ></FeatureHeading>
        <PlanFeatures
          features={currentPlanData.features}
          planName={currentPlanData.name}
        ></PlanFeatures>
        <View style={[styles.contactContainer]}>
          <PlanBanner
            scrollRef={scrollRef}
            navigation={props.navigation}
            plan={plan}
            displayName={currentPlanData.displayName}
            callToAction={currentPlanData.callToAction}
            question={currentPlanData.question}
            setIsModalOpen={setIsModalOpen}
          ></PlanBanner>
        </View>
        <View style={styles.flexContainer}>
          <PlanAdvantages
            plan={currentPlanData}
            previousPlanName={previousPlanData.displayName}
          ></PlanAdvantages>
          <View style={styles.hr}></View>
          <AUA
            currentPlan={currentPlanData}
            previousPlan={previousPlanData}
          ></AUA>
        </View>
        <View style={[styles.standsOutContainer]}>
          <StandsOut heading="You can rely on us"></StandsOut>
        </View>
        <View style={[styles.container, styles.clientsContainer]}>
          <ClientReviews clients={clients}></ClientReviews>
        </View>
        <View style={[styles.contactContainer]}>
          <PlanBanner
            scrollRef={scrollRef}
            navigation={props.navigation}
            plan={plan}
            displayName={currentPlanData.displayName}
            callToAction={currentPlanData.callToAction}
            question={currentPlanData.question}
            setIsModalOpen={setIsModalOpen}
          ></PlanBanner>
        </View>
        <View style={styles.backToTopContainer}>
          <BackToTop target={scrollRef}></BackToTop>
        </View>
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: md(window.width) ? "#f8f8f8" : "#fdfdfd",
  },
  container: {
    marginLeft: md(window.width) ? 120 : 16,
    marginRight: md(window.width) ? 120 : 16,
  },
  seperator: {
    alignSelf: "stretch",
    marginTop: 40,
    height: 15,
    backgroundColor: "#410DAA",
    marginLeft: md(window.width) ? 120 : 0,
    marginRight: md(window.width) ? 120 : 0,
    borderTopRightRadius: md(window.width) ? 5 : 0,
    borderTopLeftRadius: md(window.width) ? 5 : 0,
  },
  plansContainer: {
    flex: md(window.width) ? 1 : undefined,
    flexDirection: md(window.width) ? "row" : undefined,
    justifyContent: md(window.width) ? "space-around" : undefined,
    backgroundColor: md(window.width) ? "#fff" : undefined,
    borderBottomRightRadius: md(window.width) ? 5 : undefined,
    borderBottomLeftRadius: md(window.width) ? 5 : undefined,
    borderColor: md(window.width) ? "#000" : undefined,
    borderWidth: md(window.width) ? 1 : undefined,
    marginLeft: md(window.width) ? 120 : 16,
    marginRight: md(window.width) ? 120 : 16,
  },
  standsOutContainer: {
    marginLeft: md(window.width) ? 120 : undefined,
    marginRight: md(window.width) ? 120 : undefined,
    marginTop: 40,
    backgroundColor: md(window.width) ? undefined : "#eee",
    paddingBottom: md(window.width) ? 80 : 40,
  },
  clientsContainer: {
    backgroundColor: "#fdfdfdfd",
    marginLeft: md(window.width) ? 0 : undefined,
    marginRight: md(window.width) ? 0 : undefined,
    paddingLeft: md(window.width) ? 120 : undefined,
    paddingRight: md(window.width) ? 120 : undefined,
  },
  faqsContainer: {
    backgroundColor: "#fdfdfdfd",
    marginLeft: md(window.width) ? 0 : 16,
    marginRight: md(window.width) ? 0 : undefined,
    paddingLeft: md(window.width) ? 120 : undefined,
    paddingRight: md(window.width) ? 120 : undefined,
  },
  contactContainer: {
    backgroundColor: md(window.width) ? undefined : "#eee",
  },
  backToTopContainer: {
    backgroundColor: md(window.width) ? "#FDFDFDFD" : undefined,
    paddingTop: 40,
    paddingBottom: md(window.width) ? 40 : 80,
  },
  flexContainer: {
    display: "flex",
    flexDirection: md(window.width) ? "row" : "column",
    justifyContent: "space-around",
    paddingRight: md(window.width) ? 120 : undefined,
    paddingLeft: md(window.width) ? 120 : undefined,
  },
  hr: {
    height: md(window.width) ? undefined : 1,
    backgroundColor: "#CCC",
    width: md(window.width) ? 1 : undefined,
    marginTop: md(window.width) ? undefined : 30,
  },
});
