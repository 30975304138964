const { default: Modal } = require("./Modal");
import React from "react";
import {View,Text,TouchableOpacity, StyleSheet} from "react-native";

export const ShowAlert =({contentContainerStyle={}, title,description,yesText, noText,onPressNo,onPressYes})=>{
    return(
        <Modal style={styles.modalContainer}>
        <View style={[{...contentContainerStyle}]}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.description}>{description}</Text>

            <View style={styles.confirmButtonContainer}>
                <TouchableOpacity onPress={()=>onPressNo&&onPressNo()} underlayColor="white">
                            <View style={[styles.confirmButton,styles.confirmButtonNo]}>
                                <Text style={styles.noText}>{noText||"No"}</Text>
                            </View>
                </TouchableOpacity>

                <TouchableOpacity onPress={()=>{
                    onPressYes && onPressYes()
                }} underlayColor="white">
                            <View style={[styles.confirmButton,styles.confirmButtonYes]}>
                                <Text style={styles.yesText}>{yesText||"Yes"}</Text>
                            </View>
                </TouchableOpacity>
            </View>
        </View>
    </Modal>
    )
}

const styles = StyleSheet.create({
    modalContainer:{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'},
    title:{fontSize:17,fontWeight:"700",color:"#464E5F",marginBottom:14},
    description:{fontSize:14,fontWeight:"400",color:"#464E5F",marginBottom:35},
    confirmButtonContainer:{
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"center",
        justifyContent:"space-around"
    },
    confirmButton:{
        paddingHorizontal:20,
        paddingVertical:6,
        borderRadius:6
    },
    confirmButtonNo:{
        backgroundColor:"#fff",
        borderColor:"#410DAA",
        borderWidth:1
    },
    noText:{
        color:"#410DAA",
        fontSize:15
    },
    confirmButtonYes:{
        backgroundColor:"#410DAA",
    },
    yesText:{
        color:"#fff",
        fontSize:15
    },
})