import { StackActions } from "@react-navigation/routers";
import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { updateDigioAgreementStatus } from "../../../api";
import { completeMinDetails, isMobile } from "../../../utils";
import Header from "../login_header";

export default class DigioRedirection extends React.Component {
  constructor(props) {
    super(props);
    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
    }
    this.state = {
      isLoading: true,
      user_id,
    };
  }

  componentDidMount = async () => {
    try {
      const userIdToFetchDetails = this.state.user_id;
      const response = await updateDigioAgreementStatus(userIdToFetchDetails);
      if (response && response.code == "100") {
        this.setState({ isLoading: false }, () => {
          setTimeout(() => {
            this.moveToNextScreen();
          }, 1000);
        });
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText:
            response && response.msg ? response.msg : "Something went wrong!",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  moveToNextScreen = async () => {
    try {
      this.setState({ gettingOnboardingStatus: true });
      const minDetailsResponse = await completeMinDetails();
      const { needCompletion, error } = minDetailsResponse;

      if (!error) {
        const { redirect_screen } = minDetailsResponse;
        let options = {};
        if (this.props.route) {
          const { params } = this.props.route;
          if (params) {
            options = { ...params };
          }
          if (!needCompletion) {
            if (options.action) {
              this.props.navigation.dispatch(
                StackActions.replace("Onboarding", {
                  screen: "ComplianceSuccess",
                  params: { ...options },
                })
              );
              return;
            } else {
              this.props.navigation.dispatch(
                StackActions.replace("Onboarding", {
                  screen: "OnboardingSuccess",
                  params: { ...options },
                })
              );
              return;
            }
          } else {
            this.props.navigation.dispatch(
              StackActions.replace("Onboarding", {
                screen: redirect_screen,
                params: { ...options },
              })
            );
            return;
          }
        }
        this.props.navigation.dispatch(
          StackActions.replace("App", {
            screen: "Dashboard",
            params: {
              screen: "Dashboard",
              params: {
                screen: redirect_screen,
                params: { ...options },
              },
            },
          })
        );
      } else {
        this.setState({
          gettingOnboardingStatus: false,
          error: true,
          errorText: "Something went wrong! Please contact help@maxiom.in",
        });
      }
    } catch (error) {
      this.setState({
        gettingOnboardingStatus: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  goToEsignScreen = () => {
    this.props.navigation.navigate("eSign");
  };

  render() {
    const { isLoading, errorText, gettingOnboardingStatus } = this.state;
    return (
      <View style={styles.mainContainer}>
        <Header navigation={this.props.navigation} route={this.props.route} />
        <View style={styles.loadingContainer}>
          {isLoading ? (
            <ActivityIndicator size="large" color="#410DAA" />
          ) : (
            <View>
              <Text style={styles.errorText}>{errorText || ""}</Text>
              {!errorText && <Text style={styles.errorText}>Redirecting</Text>}
              {gettingOnboardingStatus ? (
                <ActivityIndicator
                  size="large"
                  color="#410DAA"
                  style={styles.statusLoader}
                />
              ) : (
                <TouchableOpacity
                  onPress={this.moveToNextScreen}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 40.7,
                  }}
                >
                  <Text style={styles.next}>Continue</Text>
                </TouchableOpacity>
              )}
            </View>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  errorText: {
    color: "#12121290",
    fontSize: 15,
    textAlign: "center",
    maxWidth: isMobile ? "90%" : "60%",
    alignSelf: "center",
  },
  next: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    fontWeight: "bold",
    paddingVertical: 12,
    paddingHorizontal: 63,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  statusLoader: {
    marginTop: 15,
  },
});
