import React, { Component } from "react";
import {
  View,
  SafeAreaView,
  Image,
  StyleSheet,
  Platform,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { Button, Text, Switch } from "react-native-paper";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { ActivityIndicator } from "react-native-paper";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import Divider from "../../../components/Common/Divider";
import theme from "../../../static/theme";
import amplitude from "../../../utils/amplitude";
import { getUserDetails, isMobile } from "../../../utils";
import { getOrderDetails } from "../../../api/alerts";
import { getInvoicesPaymentLink } from "../../../api/billing";
import moment from "moment";
import { getBsePaymentUrlForMfOrders } from "../../../api";

const decimalPlaces = (value, places) => {
  return parseFloat(value).toFixed(places);
};

const FundField = ({ style, name, value, value2, bold }) => {
  return (
    <View style={{ ...style }}>
      <Text
        style={{
          fontSize: 14,
          color: "#121212",
          paddingTop: 5,
          fontWeight: bold ? "bold" : "400",
        }}
      >
        {value}
      </Text>
      {value2 && (
        <Text
          style={{
            fontSize: 14,
            color: "#121212",
            paddingTop: 5,
            fontWeight: bold ? "bold" : "400",
          }}
        >
          {value2}
        </Text>
      )}
      <Text
        style={{
          fontSize: 11,
          fontStyle: "italic",
          color: "#707070",
        }}
      >
        {name}
      </Text>
    </View>
  );
};

const numberFormat = (x) => {
  const values = x ? x.split("-") : x;
  let isNegative = false;
  if (values.length > 1) {
    x = values[1];
    isNegative = true;
  }
  return x && x.toString().split(".")[0].length > 3
    ? `${isNegative ? "-" : ""}${
        x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        x.toString().substring(x.toString().split(".")[0].length - 3)
      }`
    : `${isNegative ? "-" : ""}${x.toString()}`;
};

const getAction = (type) => {
  const actions = [
    { action: "Switch In", text: "Switching In" },
    { action: "Switch Out", text: "Switching Out" },
    { action: "Redemption", text: "Redeeming" },
    { action: "Investment", text: "Investing" },
  ];
  let action = "";
  action = actions.find((obj) => {
    return obj.action == type;
  });
  return action ? action.text : null;
};

const FundCard = ({
  unitsBalance,
  NAV,
  folio_number,
  amount_investing,
  logo,
  name,
  order_type,
  style,
  bankName,
  mandateId,
}) => {
  return (
    <View
      rippleContainerBorderRadius={10}
      style={{
        borderWidth: 0.8,
        borderColor: "#00000014",

        ...style,
      }}
    >
      <View
        style={{
          padding: 10,
          flexDirection: "row",
          alignItems: "flex-start",
          paddingVertical: 20,
        }}
      >
        {logo && (
          <View style={{ flex: 0.1 }}>
            <Image
              source={{ uri: logo }}
              resizeMode="contain"
              style={{
                height: 40,
                width: 40,
                alignSelf: "flex-start",
                borderWidth: 1,
                borderColor: "#f2f2f2",
                borderRadius: 5,
              }}
            />
          </View>
        )}
        <View style={{ flex: 0.9, paddingLeft: 10 }}>
          <Text
            style={{
              fontSize: 14,
              color: "#121212",
              paddingTop: 0,
              marginTop: 0,
            }}
          >
            {name}
          </Text>
          <View style={{ flex: 1, marginTop: 5 }}>
            <Text
              style={{
                fontSize: 10,
                paddingVertical: 2,
                paddingHorizontal: 10,
                borderRadius: 3,
                alignSelf: "flex-start",
                borderWidth: 0.5,
                borderColor: "#E7E7E7",
                color: "#876CDD",
              }}
            >
              {order_type}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 16,
          paddingBottom: 10,
          paddingTop: 6,
        }}
      >
        <FundField
          style={{ flex: 0.33, alignItems: "flex-start" }}
          name="Unit Balance"
          value={
            unitsBalance == 0
              ? "N.A"
              : `${numberFormat(decimalPlaces(unitsBalance, 2))}`
          }
        />
        <FundField
          style={{ flex: 0.33, alignItems: "center" }}
          name="NAV"
          value={NAV == 0 ? "N.A" : decimalPlaces(NAV, 4)}
        />
        <FundField
          style={{ flex: 0.33, alignItems: "flex-end" }}
          name="Folio"
          value={folio_number ? folio_number : "N.A"}
        />
      </View>
      <Divider width={0.8} color={"#00000014"} style={{ marginVertical: 10 }} />
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 16,
          paddingBottom: 10,
          paddingTop: 6,
        }}
      >
        <FundField
          style={{ flex: 0.4, alignItems: "flex-start" }}
          name={`Amount ${getAction(order_type) || ""}`}
          value={`${theme.variables.RUPEE_SYMBOL} ${numberFormat(
            amount_investing.toFixed(0)
          )}`}
          bold={true}
        />
        {mandateId != "" && mandateId && (
          <FundField
            style={{ flex: 0.6, alignItems: "flex-end" }}
            name="Mandate ID"
            value={mandateId + "(" + bankName + ")"}
          />
        )}
      </View>
    </View>
  );
};

export default class OrderDetails extends Component {
  constructor(props) {
    super(props);
    let transaction_id;
    const { params } = this.props.route;
    if (params) {
      transaction_id = params.transaction_id || null;
    }

    this.state = {
      isLoading: true,
      transaction_id,
    };
  }

  showToggleOrMakePayment = (status) => {
    let toggle = false,
      makepaymentbutton = false;
    switch (status) {
      case "PIB":
        toggle = true;
        break;
      case "PCB":
        toggle = false;
        makepaymentbutton = false;
        break;
      case "AWP":
        toggle = false;
        makepaymentbutton = true;
        break;
      default:
        toggle = false;
        makepaymentbutton = false;
    }
    return { toggle, makepaymentbutton };
  };

  handleMakePayment = async () => {
    try {
      const key = moment().unix();
      const payload = {
        "redirect_url" : (Platform.OS == "web" ? global.location.origin : "https://app.jamawealth.com") + "/dashboard/process/" + key
      }
      const response = await getBsePaymentUrlForMfOrders(payload);
      if (response && response.code == 100) {
        const url = response.result;
        global.open(url, "_self");
      }
      // if (isMobile) {
      //   const payload = {
      //     invoices: "9192",
      //     amount: this.state.fund_details.amount,
      //     server_name: "mobile",
      //   };
      //   this.setState({ loading: true });
      //   const response = await getInvoicesPaymentLink(payload);
      //   if (response && response.code && response.code == 100) {
      //     const { results = {} } = response;
      //     const { payment_link } = results;
      //     this.props.navigation.navigate("NotificationWebView", {
      //       url: payment_link,
      //     });
      //     this.setState({ loading: false });
      //   }
      // } else {
      //   let server_name = global.location.href.split("/");

      //   const payload = {
      //     invoices: "9192",
      //     amount: this.state.fund_details.amount,
      //     server_name: Platform.OS == "web" ? server_name[2] : "mobile",
      //   };

      //   this.setState({ loading: true });
      //   const response = await getInvoicesPaymentLink(payload);
      //   if (response && response.code == 100) {
      //     const { results = {} } = response;
      //     const { payment_link } = results;
      //     if (Platform.OS == "web") {
      //       global.open(payment_link, "_self");
      //     } else {
      //       this.props.navigation.navigate("NotificationWebView", {
      //         url: payment_link,
      //       });
      //     }
      //     this.setState({ loading: false });
      //   }
      // }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("orderDetails",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("orderDetails", { path: this.props.navigation.getState().routeName });
      }
      const { transaction_id } = this.state;
      const response = await getOrderDetails(transaction_id);
      console.log(response)
      if (response && response.code == 100) {
        const { results = {} } = response;
        const {
          order_status_v2,
          fund_details,
          reg_mail,
          transaction_id,
          transaction_status,
        } = results;
        this.setState({
          isLoading: false,
          order_details: order_status_v2,
          fund_details,
          reg_mail,
          toggle_or_makepaymentbutton:
            this.showToggleOrMakePayment(transaction_status),
          transaction_id,
          Paidbool: true,
        });
      } else {
        this.setState({ isLoading: false, error: true });
      }
    } catch (error) {
      this.setState({ isLoading: false, error: true });
    }
  }

  getIconDetails = (status) => {
    let color, name, textColor;
    switch (status) {
      case "DONE":
        color = "#009105";
        name = "checkcircle";
        textColor = "#009105";
        break;
      case "FAILED":
        color = "red";
        textColor = "red";
        name = "closecircle";
        break;
      case "PENDING":
        color = "#939393";
        textColor = "#EF5D12";
        name = "checkcircle";
        break;
      default:
        name = "checkcircle";
        color = "#009105";
        textColor = "#009105";
        break;
    }
    return { name, color, textColor };
  };

  _handleAction = (bool) => {
    try {
      this.setState({ loadingAction: true });
      pendingTransactionAction(this.state.transaction_id, bool)
        .then((json) => {
          if (json.code == 100) {
            this.setState({
              Paidbool: !this.state.Paidbool,
              loadingAction: false,
            });
            return;
          } else {
            this.setState({ loadingAction: false });
            return;
          }
        })
        .catch(() => {
          this.setState({ loadingAction: false });
        });
    } catch (error) {
      this.setState({ loadingAction: false });
    }
  };

  handlePressBack = () => {
    this.props.navigation.navigate("TrackOrders");
  };

  handleOpenURL = () => {
    try {
      const { token } = getUserDetails();
      let theWindow = window.open(
        "http://127.0.0.1:8000/app/android_to_web_login",
        "_self"
      );
      let theDoc = theWindow.document;
      theScript = window.document.createElement("script");
      function injectThis() {
        alert("Alert");
      }
      theScript.innerHTML = "window.onload = " + injectThis.toString() + ";";
      theDoc.body.appendChild(theScript);
    } catch (error) {}
  };

  render() {
    const { error } = this.state;

    const DATA = this.state.order_details;

    const { fund_details } = this.state;

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Order Details"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        {this.state.isLoading ? (
          <View style={{ padding: 16, flex: 1 }}>
            <ActivityIndicator size={"large"} color={"#410DAA"} />
          </View>
        ) : (
          <ScrollView>
            <View
              style={{
                flex: 1,
                backgroundColor: "#fff",
                width: isMobile ? "90%" : "50%",
                marginLeft: isMobile ? "5%" : "25%",
                marginRight: isMobile ? "5%" : "25%",
              }}
            >
              <View
                style={{
                  paddingHorizontal: 18,
                  paddingTop: 16,
                  backgroundColor: "#fff",
                }}
              >
                {DATA &&
                  DATA.map((item) => {
                    const icon = this.getIconDetails(item.status);
                    return (
                      <View
                        style={{
                          flexDirection: "row",
                        }}
                      >
                        <View style={{ flex: 0.1 }}></View>
                        <View style={{ flex: 0.9, paddingBottom: 20 }}>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "flex-start",
                              paddingTop: 0,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 15,
                                color: "#121212",
                                flex: 0.6,
                              }}
                            >
                              {item.title}
                            </Text>
                            <Text
                              style={{
                                fontSize: 14,
                                color: icon.textColor,
                                flex: 0.4,
                                textAlign: "right",
                              }}
                            >
                              {item.status_text}
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "flex-start",
                            }}
                          >
                            <View
                              style={{
                                flex: item.retry_payment == true ? 1 : 1,
                                flexWrap: "wrap",
                              }}
                            >
                              {item.data &&
                                item.data.map((d, index) => {
                                  if (d.type == "FIELD") {
                                    return (
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          alignItems: "flex-start",
                                          paddingVertical: 2,
                                          marginTop: index == 0 ? 5 : 0,
                                        }}
                                      >
                                        {d.key && d.key != "" && (
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#a8a8a8",
                                            }}
                                          >
                                            {d.key}:{" "}
                                          </Text>
                                        )}
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: !d.key
                                              ? "#939393"
                                              : "#121212",
                                          }}
                                        >
                                          {d.value[0]}
                                        </Text>
                                      </View>
                                    );
                                  }
                                  if (d.type == "TEXT") {
                                    return (
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          alignItems: "flex-start",
                                          paddingVertical: 2,
                                          marginTop: index == 0 ? 5 : 0,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: "#a8a8a8",
                                            paddingLeft: 0,
                                            marginLeft: 0,
                                            flex: 0.7,
                                          }}
                                        >
                                          {" "}
                                          {d.value[0]}
                                        </Text>
                                      </View>
                                    );
                                  }
                                })}
                            </View>
                            {item.retry_payment == true && (
                              <Button
                                uppercase={false}
                                mode={"contained"}
                                style={styles.nextButton}
                                loading={false}
                                color={"#410DAA"}
                                onPress={() => {
                                  this.handleMakePayment();
                                }}
                              >
                                <Text style={styles.next}>Retry Payment</Text>
                              </Button>
                            )}
                          </View>
                        </View>
                        <View
                          style={{ position: "absolute", left: -7, top: 3 }}
                        >
                          <ANTIcon
                            size={16}
                            name={icon.name}
                            color={icon.color}
                            style={{ backgroundColor: "#fff", zIndex: 10 }}
                          />
                        </View>
                      </View>
                    );
                  })}
                <>
                  {this.state.toggle_or_makepaymentbutton &&
                  this.state.toggle_or_makepaymentbutton.toggle ? (
                    <>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 30,
                          marginTop: 10,
                          marginLeft: 8,
                          marginRight: 8,
                          marginBottom: 20,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 13,
                              color: "#121212",
                              marginRight: 10,
                            }}
                          >
                            Did you pay?
                          </Text>
                          <Text
                            style={{
                              fontSize: 11,
                              color: "#a8a8a8",
                              marginRight: 5,
                            }}
                          >
                            Yes
                          </Text>
                          <Switch
                            value={!this.state.Paidbool}
                            onValueChange={() => {
                              this.setState(
                                { Paidbool: !this.state.Paidbool },
                                () => {
                                  this._handleAction(!this.state.Paidbool);
                                }
                              );
                            }}
                            color={"#410DAA"}
                          />
                          <Text
                            style={{
                              fontSize: 11,
                              color: !this.state.Paidbool
                                ? "#410DAA"
                                : "#a8a8a8",
                              fontWeight: !this.state.Paidbool
                                ? "bold"
                                : "normal",
                              paddingHorizontal: 2,
                              marginLeft: 10,
                              marginRight: 15,
                            }}
                          >
                            No
                          </Text>
                        </View>
                      </View>
                      {!this.state.Paidbool && (
                        <Button
                          uppercase={false}
                          mode={"contained"}
                          onPress={() => {
                            this.handleMakePayment();
                          }}
                          style={{
                            width: isMobile ? "60%" : "40%",
                            marginLeft: isMobile ? "20%" : "30%",
                            marginRight: isMobile ? "20%" : "30%",
                          }}
                          loading={false}
                          color={"#410DAA"}
                        >
                          <Text style={styles.next}>Pay Now</Text>
                        </Button>
                      )}
                    </>
                  ) : this.state.toggle_or_makepaymentbutton &&
                    this.state.toggle_or_makepaymentbutton.makepaymentbutton ? (
                    <TouchableOpacity
                      rippleContainerBorderRadius={6}
                      style={{
                        borderRadius: 6,
                        alignItems: "center",
                        justifyContent: "center",
                        paddingVertical: 10,
                        paddingHorizontal: 10,
                        marginBottom: 5,
                        backgroundColor: theme.colors.NEW_BRAND_COLOR,
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          color: theme.colors.WHITE,
                        }}
                      >
                        Make Payment
                      </Text>
                    </TouchableOpacity>
                  ) : null}
                </>
              </View>
              <Divider width={2} color={"#00000014"} />
              {fund_details && (
                <FundCard
                  unitsBalance={fund_details.unit_balance}
                  NAV={fund_details.nav}
                  folio_number={fund_details.bse_folio_no}
                  amount_investing={fund_details.amount}
                  logo={fund_details.amc_logo}
                  name={fund_details.name}
                  order_type={fund_details.transaction_type}
                  mandateId={fund_details.mandate_id}
                  bankName={fund_details.bank_name}
                  style={{
                    backgroundColor: "#f6f6f6",
                  }}
                />
              )}
              {fund_details && (
                <View style={{ marginBottom: 50, marginTop: 20 }}>
                  <Text
                    style={{
                      fontSize: 10,
                      fontStyle: "italic",
                      color: "#939393",
                      paddingHorizontal: 16,
                      marginBottom: isMobile ? 100 : 50,
                    }}
                  >
                    You will receive receipt from fund house to your registered
                    email id{" "}
                    <Text
                      style={{
                        fontSize: 10,
                        fontWeight: "bold",
                        fontStyle: "italic",
                        color: "#121212",
                      }}
                    >
                      {this.state.reg_mail}
                    </Text>
                  </Text>
                </View>
              )}
            </View>
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  next: {
    color: "#fff",
    fontSize: 13,
  },
});
