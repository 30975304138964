import axiosApiInstance from "./apiConfig";
import { URLS } from "./urls";

export const addGoal = async (goalData) => {
  const result = await axiosApiInstance.post(URLS.GOALS_ADD, goalData);
  return result;
};

export const deleteGoal = async (payload) => {
  const url = URLS.GOALS_DELETEGOAL;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const tagGoal = async (payload) => {
  const url = URLS.GOALS_TAGGOAL;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const getFolioDetails = async (payload) => {
  const url = URLS.GOALS_GETFOLIOS;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const getOutsideFolioDetails = async (payload) => {
  const url = URLS.GOALS_GETOUTSIDEFOLIOS;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const getAdvisorDashboardDetials = async (userId) => {
  const url = URLS.ADVISORDASHBOARDDATA + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getGoalDetails = async (goal_id, userId) => {
  const url = URLS.GET_GOAL_DETAILS + goal_id + "/" + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const jamaStockTagUntag = async (data) => {
  const url = URLS.JAMA_STOCK_TAG_UNTAG;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const getSuggestedFunds = async (payload) => {
  const url = URLS.SUGGESTED_FUNDS;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const outsideStockTagUntag = async (data) => {
  const url = URLS.OUTSIDE_STOCK_TAG_UNTAG;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const goalSummary = async (data) => {
  const url = URLS.GOAL_SUMMARY;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const addOrdersToCartFromGoal = async (data) => {
  const url = URLS.ADD_ORDERS_TO_CART_FROM_GOAL;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const getTaggedAssets = async (payload) => {
  const url = URLS.GOALS_GETASSETS;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const addAsset = async (payload) => {
  const url = URLS.ADD_ASSET;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const getJamaEquityStocks = async (payload) => {
  const url = URLS.JAMA_EQUITY_STOCKS;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const getOutsideImportedStocks = async (payload) => {
  const url = URLS.OUTSIDE_IMPORTED_FUNDS;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const getRevisedGoalProjection = async (payload) => {
  const url = URLS.REVISED_GOAL_PROJECTION;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};
