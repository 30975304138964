import React from "react";
import { Platform, SafeAreaView } from "react-native";
const WebView =
  Platform.OS == "web"
    ? require("react-native-web-webview").WebView
    : require("react-native-webview").WebView;
import NavigationHeader from "./NavigationHeader";

class ShowWebView extends React.Component {
  constructor(props) {
    super(props);
  }

  onNavigationStateChange = (navState) => {
    try {
      const { nativeEvent = {} } = navState;
      const { data = null } = nativeEvent;
      if (data) {
        const responseData = JSON.parse(data);
        if (typeof responseData === "object") {
          const { code, msg, results } = responseData;
          if (code == 100) {
            this.props.route.params.onSuccess(this.props.navigation, results);
          } else {
            this.props.route.params.onError(this.props.navigation, results);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  onURLChange = (navState) => {
    if (navState) {
      const { url = "" } = navState;
      const { successURL = null } = this.props.route.params;
      if (url.includes(successURL)) {
        this.props.route.params.onSuccess &&
          this.props.route.params.onSuccess(this.props.navigation);
      }
    }
  };

  loadWebView = () => {
    global.open(
      this.props.route.params.url,
      this.props.route.params.loadSelf ? "_self" : "_blank"
    );
  };

  render() {
    const { userAgent = false } = this.props.route.params;
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "#fff",
        }}
      >
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={[""]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        {this.props.route.params.showInWeb ? (
          this.loadWebView()
        ) : (
          <WebView
            originWhitelist={["*"]}
            source={{ uri: this.props.route.params.url }}
            onNavigationStateChange={this.onURLChange}
            onMessage={this.onNavigationStateChange}
            userAgent={
              userAgent
                ? Platform.OS === "android"
                  ? "Chrome/18.0.1025.133 Mobile Safari/535.19"
                  : "AppleWebKit/602.1.50 (KHTML, like Gecko) CriOS/56.0.2924.75"
                : ""
            }
          />
        )}
      </SafeAreaView>
    );
  }
}

export default ShowWebView;
