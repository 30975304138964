import React from "react";
import { SafeAreaView, Platform } from "react-native";
import NavigationHeader from "../../../components/Common/NavigationHeader";
const WebView =
  Platform.OS == "web"
    ? require("react-native-web-webview").WebView
    : require("react-native-webview").WebView;

class ReportsHome extends React.Component {
  constructor(props) {
    super(props);
    let { url } = this.props;
    if (!url) {
      url = this.props.route.params.url;
    }
    this.state = {
      url,
    };
  }

  loadWebView = () => {
    window.open(this.state.url, "_blank");
    window.location.reload();
  };

  render() {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "#fff",
        }}
      >
        {/* {Platform.OS != "web" && (
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Reports"]}
          />
        )} */}
        {Platform.OS == "web" &&
        global.location.hostname == "demo.simplygrow.in" ? null : Platform.OS ==
          "web" ? (
          this.loadWebView()
        ) : (
          <WebView source={{ uri: this.state.url }} injectedJavaScript={"$('#download_pdf').css('display','none')"}/>
        )}
      </SafeAreaView>
    );
  }
}

export default ReportsHome;
