import React from "react";
import { StackActions } from "@react-navigation/native";
import { View, ActivityIndicator, StatusBar } from "react-native";
import { updateCamsEmailCustomer, updateEmailCustomer } from "../../api";
import NavigationHeader from "../../components/Common/NavigationHeader";
import Store from "../../store";
import moment from "moment";
import WebView from "react-native-webview";
import Toast from "react-native-toast-message";

export default class WebScreenImport extends React.Component {
  constructor(props) {
    super(props);
    let email = null;
    const params = this.props.route;
    if (params) {
      email = params.params.email;
    }
    this.state = {
      madalVisible: true,
      showWebView: false,
      loading: false,
      email,
    };
  }

  getImport() {
    let re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.state.email == "" || this.state.email == null) {
      Toast.show({
        text1: "Please enter email address",
        // topOffset: 7,
        visibilityTime: 2000,
        type: "success",
      });
      // Alert.alert("Please enter email address");
    } else if (!re.test(this.state.email)) {
      Toast.show({
        text1: "Please enter valid email address",
        // topOffset: 7,
        visibilityTime: 2000,
        type: "error",
      });
      // Alert.alert("Please enter valid email address");
    } else {
      let emailStore = Store.getState().user.customerData.email;
      if (emailStore == "" || emailStore == null) {
        this.updateemail();
      } else {
        if (this.state.email == emailStore) {
          this.setState({
            modalVisible: false,
            showWebView: true,
          });
        } else {
          let CamsemailStore = Store.getState().user.customerData.cams_mail;
          if (CamsemailStore == this.state.email) {
            this.setState({
              modalVisible: false,
              showWebView: true,
            });
          } else {
            this.updateCamsemail();
          }
        }
      }
    }
  }

  updateemail() {
    this.setState({
      loading: true,
    });
    updateEmailCustomer(this.state.email).then((json) => {
      if (json.hasOwnProperty("username")) {
        this.setState({
          loading: false,
          modalVisible: false,
          showWebView: true,
        });
      }
    });
  }

  updateCamsemail() {
    this.setState({
      loading: true,
    });
    updateCamsEmailCustomer(this.state.email).then((json) => {
      if (json.hasOwnProperty("username")) {
        this.setState({
          loading: false,
          modalVisible: false,
          showWebView: true,
        });
      }
    });
  }

  checkemailid = () => {
    let javascript = "";
    let d = new Date();
    let datestring =
      ("0" + d.getDate()).slice(-2) +
      "/" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      d.getFullYear();

    javascript =
      "$('#defaultChecked').prop('checked', true);Details();" +
      "$('#ddlSpecificYear').prop('checked', true);fnSpecificYear();" +
      "$('#Fromdate').val('01/01/2000');" +
      "$('#Todate').val(" +
      datestring +
      ");" +
      "$('#txtEmailId').val(" +
      this.state.email +
      ")";
    return javascript;
  };

  onNavigationStateChange = (navState) => {
    const { nativeEvent } = navState || {};
    if (nativeEvent) {
      const params = this.props.route;
      let from = null;
      if (params) {
        from = params.from || null;
      }
      const payload = {
        showHelp: true,
        from,
        email: this.state.email,
      };
      this.props.navigation.dispatch(
        StackActions.replace("App", {
          screen: "ViewMode",
          params: {
            screen: "ImportMF",
            params: payload,
          },
        })
      );
    }
  };

  renderLoading = () => {
    return (
      <ActivityIndicator
        style={{
          flex: 1,
          alignSelf: "center",
        }}
        size="large"
        color="#410DAA"
      />
    );
  };

  render() {
    const params = this.props.route;
    let url = null;
    if (params) {
      url = params.url || "Not Found";
    }
    let d = new Date();

    return (
      <View style={{ width: "100%", height: "100%", maxWidth: 800 }}>
        <StatusBar backgroundColor={"#410DAA"} />
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Import Funds"]}
          onPressBack={() => {
            this.props.navigation.navigate("InvestmentOverview");
          }}
        />
        <WebView
          source={{
            uri: "https://mfs.kfintech.com/investor/General/ConsolidatedAccountStatement",
          }}
          injectedJavaScript={
            "$('#defaultChecked').prop('checked', true);Details();$('#ddlSpecificYear').prop('checked', true);fnSpecificYear();$('#Fromdate').val('01/01/2000');$('#Todate').val('" +
            moment().format("DD/MM/YYYY") +
            "');$('#txtPassword').val('Profit40%');$('#rpsd').val('Profit40%');$('#txtEmailId').val('" +
            this.state.email +
            "');$('.g-recaptcha').css('display','none');$('.navbar').css('display','none');$('p.text-sz').css('display','none');$('label[for=rpsd],label[for=txtPassword],label[for=txtEmailId]').addClass('active');$('.container').css({'width':'100%','margin-left':'0px','margin-right':'0px'});$('.mt-5').css('margin-top','0px');$('.pt-5').css('padding-top','0px');$('.col').css('padding-left','0px');$('div').removeClass('form-row');$('.custom-control').css('padding-left','20px');$('#divdetail').css('margin-top','10px');$('.md-form').css('margin-top','0px');$('.card-body p').css('display','none');$('div').removeClass('pt-5 mt-5');document.getElementById('ModelDone').onclick = function() {window.ReactNativeWebView.postMessage('Hello World')}; true;"
          }
          onMessage={this.onNavigationStateChange}
        />
      </View>
    );
  }
}
