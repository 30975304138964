import React, { useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TextInput,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
} from "react-native";
import {
  addPanNumber,
  checkUserExistsBasedOnPan,
  getCustomerDetails,
} from "../../../api";
import { md } from "../../../Responsive";
import {
  completeMinDetails,
  DataURIToBlob,
  getUserDetails,
  isMobile,
  isWeb,
  navigateToTransaction,
} from "../../../utils";
import Progressbar from "../progressbar";
import Header from "../header_after_login";
const { width } = Dimensions.get("window");
import { saveUserProfileData } from "../../../actions";
import { connect } from "react-redux";
import { ShowToast } from "../../../components/Common/Toast";
import { useFilePicker } from "use-file-picker";
import Entypo from "@expo/vector-icons/Entypo";
import { useState } from "react";
import ViewSampleImage from "../../../components/Common/ViewSampleImage";
import { checkOrFetchPAN } from "../../../api/account";

import DocumentPicker, { types } from "expo-document-picker";
import { SafeAreaView } from "react-native-safe-area-context";
import Store from "../../../store";
import { StackActions } from "@react-navigation/native";


const SelectChequeLeaf = ({ onSelect, panImage, viewSampleImage }) => {
  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: "DataURL",
    accept: [".png", ".jpg", ".jpeg", ".pdf"],
    multiple: true,
    limitFilesConfig: { max: 1 },
    maxFileSize: 0.5,
  });

  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    onSelect(filesContent, errors);
  }, [filesContent]);

  if (loading) {
    return <Text>Loading</Text>;
  }

  const isFileSelected = () => {
    return filesContent.length != 0;
  };

  const checkSizeError = () => {
    if (errors && errors.length) {
      const error = errors[0];
      const { fileSizeToolarge } = error;
      return {
        fileSizeToolarge: true,
        error: true,
      };
    }
    return null;
  };

  const handleFileSelector = async () => {
    try {
      if (Platform.OS == "web") {
        openFilePicker();
      } else {
        const result = await DocumentPicker.pickSingle({
          allowMultiSelection: false,
          type: [types.images],
        });
        onSelect([{ content: result.uri, name: result.name, ...result }]);
      }
    } catch (error) { }
  };

  const selectedFile = isFileSelected();
  const errorData = checkSizeError();
  return (
    <View style={{ flexDirection: "column", paddingTop: 31.2 }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Text style={styles.res}>
          {panImage != null ? "Update" : "Upload"} Your PAN Photocopy*
        </Text>
      </View>
      <TouchableOpacity
        onPress={() => handleFileSelector()}
        style={{
          alignItems: "center",
          paddingVertical: 28,
          backgroundColor: "#f6f6f6",
          borderRadius: 4,
          borderStyle: "dashed",
          borderWidth: 1,
          borderColor: "rgba(65, 13, 170, 0.5)",
        }}
      >
        <Text style={styles.drop}>
          <Entypo name="attachment" size={20} color="#13C37B" /> Click here to
          browse and attach files or replace files
        </Text>
      </TouchableOpacity>
      {selectedFile ? (
        filesContent.map((file) => (
          <Text style={styles.fileName}>{file.name}</Text>
        ))
      ) : (
        <Text style={styles.fileName}></Text>
      )}
      <Text
        style={[
          styles.support,
          errorData &&
          errorData.error &&
          !errorData.fileSizeToolarge &&
          styles.error,
        ]}
      >
        Supports PNG, JPG, JPEG, PDF.{" "}
        <Text style={[errorData && errorData.fileSizeToolarge && styles.error]}>
          Max File Size 500KB
        </Text>
      </Text>
    </View>
  );
};

class pan_number extends React.Component {
  constructor(props) {
    super(props);
    let username = null,
      cust_id = null,
      user_id,
      transaction;
    const { params } = this.props.route;
    if (params) {
      username = params.username;
      cust_id = params.customer_id;
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
      transaction = params.transaction;
    }
    this.state = {
      data: null,
      username,
      cust_id,
      user_id,
      panNumber: null,
      panNumberError: null,
      dateOfBirth: null,
      dobError: null,
      showAlert: true,
      isLoadingData: true,
      isUploading: false,
      transaction,
      showToast: false,
      toastMsg: null,
      isFTU: true,
      enteredPan: null,
    };
  }

  componentDidMount = async () => {
    try {
      this.setState({ isLoadingData: true });
      let userDetails = getUserDetails();
      const userIdToFetchDetails =
        Store.getState().dashboard &&
          Store.getState().dashboard.viewModeUserId &&
          Store.getState().dashboard.viewModeUserId.user_id
          ? Store.getState().dashboard.viewModeUserId.user_id
          : String(userDetails.customer_id);
      const response = await getCustomerDetails(userIdToFetchDetails);
      if (response && response.pk != null) {
        let { pan_number, date_of_birth, pan_image } = response;
        let formattedDOB = null;
        if (date_of_birth) {
          formattedDOB = date_of_birth.split("-");
          formattedDOB =
            formattedDOB[2] + "/" + formattedDOB[1] + "/" + formattedDOB[0];
        }
        this.setState({
          pan_image,
          panNumber: pan_number,
          isFTU: pan_number ? false : true,
          enteredPan: pan_number,
          dateOfBirth: formattedDOB,
        });

        if (this.state.user_id == null) {
          this.props.saveUserProfileData(response);
        }
      }
      this.setState({ isLoadingData: false });
    } catch (error) {
      this.setState({
        isLoadingData: true,
      });
    }
  };

  handleContinue = async () => {
    try {
      if (this.state.user_id) {
        let options = {};
        const { params } = this.props.route;
        if (params) {
          options = { ...params };
        }
        options["first"] = false;
        this.props.navigation.navigate("ContactInfo", { ...options });
        return;
      }
      this.setState({ isUploading: true });
      let { username, cust_id, panNumber, dateOfBirth } = this.state;
      let name = "";
      if (!cust_id || !username) {
        const userData = getUserDetails();
        username = userData.username;
        cust_id = userData.customer_id;
        name = userData.first_name;
      }

      const panRegEx = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
      const panValid = RegExp(panRegEx).test(panNumber);

      const dateRegEx = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
      const dobValid = RegExp(dateRegEx).test(dateOfBirth);

      if (!panNumber || !panValid) {
        this.setState({
          panNumberError: "Please enter valid PAN Number",
          isUploading: false,
        });
        return;
      }
      if (!dateOfBirth || !dobValid) {
        this.setState({
          dobError: "Please enter valid date of birth",
          isUploading: false,
        });
        return;
      }

      const checkPanPayload = {
        pan: panNumber,
      };
      if (this.state.isFTU) {
        const checkPanResp = await checkUserExistsBasedOnPan(checkPanPayload);
        if (checkPanResp && checkPanResp.code == 100) {
          let panResult = checkPanResp.results[0];
          if (
            panResult.pan_exists_in_club == "FALSE" &&
            panResult.pan_exists_in_advisory == "FALSE"
          ) {
            let formattedDOB = dateOfBirth;
            formattedDOB = dateOfBirth.split("/");
            formattedDOB =
              formattedDOB[2] + "-" + formattedDOB[1] + "-" + formattedDOB[0];

            let body = new FormData();
            body.append("username", username);
            body.append("pan_number", panNumber);
            body.append("date_of_birth", formattedDOB);

            const response = await addPanNumber(cust_id, body);
            if (response && response.pk) {
              const dateOfBirthValues = dateOfBirth.split("/");
              const payload = {
                pan_number: panNumber,
                dob:
                  dateOfBirthValues[0] +
                  "/" +
                  dateOfBirthValues[1] +
                  "/" +
                  dateOfBirthValues[2],
                name_as_in_pan: name,
                search_with: "CKYC",
              };
              const fetchResponse = await checkOrFetchPAN(payload);
              if (fetchResponse && fetchResponse.code == 100) {
                const { searched_with = null } = fetchResponse.results.length
                  ? fetchResponse.results[0]
                  : {};
                if (searched_with == "CKYC") {
                  const minDetailsResponse = await completeMinDetails();
                  const { needCompletion, error } = minDetailsResponse;

                  if (!error) {
                    const { redirect_screen } = minDetailsResponse;
                    let options = {};
                    if (this.props.route) {
                      const { params } = this.props.route;
                      if (params) {
                        options = { ...params };
                      }

                      if (!needCompletion) {
                        if (options.transaction) {
                          navigateToTransaction(
                            this.props.navigation,
                            options.transaction
                          );
                        } else if (options.action) {
                          this.props.navigation.navigate("ComplianceSuccess", {
                            ...options,
                          });
                          return;
                        } else {
                          this.props.navigation.navigate("OnboardingSuccess", {
                            ...options,
                          });
                          return;
                        }
                      }
                    }
                    this.props.navigation.navigate(redirect_screen, {
                      ...options,
                    });
                  } else {
                    this.setState({
                      error: true,
                      errorText:
                        "Something went wrong! Please contact help@maxiom.in",
                    });
                  }
                } else {
                  let options = {};
                  this.props.navigation.navigate("KYCCreate", { ...options });
                }
              } else {
                this.setState({
                  isUploading: false,
                });
                let options = {};
                this.props.navigation.navigate("KYCCreate", { ...options });
              }
            } else {
              this.setState({
                error: true,
                errorText: response && response.msg ? response.msg : null,
                isUploading: false,
              });
            }
          } else {
            this.setState({
              showToast: true,
              toastMsg:
                "Given PAN number already exists in system. Please contact our customer care for further help.",
              isUploading: false,
            });
          }
        } else {
          this.setState({
            error: true,
            errorText: checkPanResp.msg,
            isUploading: false,
          });
        }
      } else {
        if (this.state.panNumber !== this.state.enteredPan) {
          const checkPanResp = await checkUserExistsBasedOnPan(checkPanPayload);
          if (checkPanResp && checkPanResp.code == 100) {
            let panResult = checkPanResp.results[0];
            if (
              panResult.pan_exists_in_club == "FALSE" &&
              panResult.pan_exists_in_advisory == "FALSE"
            ) {
              let formattedDOB = dateOfBirth;
              formattedDOB = dateOfBirth.split("/");
              formattedDOB =
                formattedDOB[2] + "-" + formattedDOB[1] + "-" + formattedDOB[0];

              let body = new FormData();
              body.append("username", username);
              body.append("pan_number", panNumber);
              body.append("date_of_birth", formattedDOB);

              const response = await addPanNumber(cust_id, body);
              if (response && response.pk) {
                const dateOfBirthValues = dateOfBirth.split("/");
                const payload = {
                  pan_number: panNumber,
                  dob:
                    dateOfBirthValues[0] +
                    "/" +
                    dateOfBirthValues[1] +
                    "/" +
                    dateOfBirthValues[2],
                  name_as_in_pan: name,
                  search_with: "CKYC",
                };
                const fetchResponse = await checkOrFetchPAN(payload);
                if (fetchResponse && fetchResponse.code == 100) {
                  const { searched_with = null } = fetchResponse.results.length
                    ? fetchResponse.results[0]
                    : {};
                  if (searched_with == "CKYC") {
                    const minDetailsResponse = await completeMinDetails();
                    const { needCompletion, error } = minDetailsResponse;

                    if (!error) {
                      const { redirect_screen } = minDetailsResponse;
                      let options = {};
                      if (this.props.route) {
                        const { params } = this.props.route;
                        if (params) {
                          options = { ...params };
                        }

                        if (!needCompletion) {
                          if (options.transaction) {
                            navigateToTransaction(
                              this.props.navigation,
                              options.transaction
                            );
                          } else if (options.action) {
                            this.props.navigation.navigate(
                              "ComplianceSuccess",
                              {
                                ...options,
                              }
                            );
                            return;
                          } else {
                            this.props.navigation.navigate(
                              "OnboardingSuccess",
                              {
                                ...options,
                              }
                            );
                            return;
                          }
                        }
                      }
                      this.props.navigation.navigate(redirect_screen, {
                        ...options,
                      });
                    } else {
                      this.setState({
                        error: true,
                        errorText:
                          "Something went wrong! Please contact help@maxiom.in",
                      });
                    }
                  } else {
                    this.props.navigation.navigate("KYCCreate");
                  }
                } else {
                  this.setState({
                    isUploading: false,
                  });
                  this.props.navigation.navigate("KYCCreate");
                }
              } else {
                this.setState({
                  error: true,
                  errorText: response && response.msg ? response.msg : null,
                  isUploading: false,
                });
              }
            } else {
              this.setState({
                showToast: true,
                toastMsg:
                  "Given PAN number already exists in system. Please contact our customer care for further help.",
                isUploading: false,
              });
            }
          } else {
            this.setState({
              error: true,
              errorText: checkPanResp.msg,
              isUploading: false,
            });
          }
        } else {
          let formattedDOB = dateOfBirth;
          formattedDOB = dateOfBirth.split("/");
          formattedDOB =
            formattedDOB[2] + "-" + formattedDOB[1] + "-" + formattedDOB[0];

          let body = new FormData();
          body.append("username", username);
          body.append("pan_number", panNumber);
          body.append("date_of_birth", formattedDOB);

          const response = await addPanNumber(cust_id, body);
          if (response && response.pk) {
            const dateOfBirthValues = dateOfBirth.split("/");
            const payload = {
              pan_number: panNumber,
              dob:
                dateOfBirthValues[0] +
                "/" +
                dateOfBirthValues[1] +
                "/" +
                dateOfBirthValues[2],
              name_as_in_pan: name,
              search_with: "CKYC",
            };
            const fetchResponse = await checkOrFetchPAN(payload);
            if (fetchResponse && fetchResponse.code == 100) {
              const { searched_with = null } = fetchResponse.results.length
                ? fetchResponse.results[0]
                : {};
              if (searched_with == "CKYC") {
                const minDetailsResponse = await completeMinDetails();
                const { needCompletion, error } = minDetailsResponse;

                if (!error) {
                  const { redirect_screen } = minDetailsResponse;
                  let options = {};
                  if (this.props.route) {
                    const { params } = this.props.route;
                    if (params) {
                      options = { ...params };
                    }

                    if (!needCompletion) {
                      if (options.transaction) {
                        navigateToTransaction(
                          this.props.navigation,
                          options.transaction
                        );
                      } else if (options.action) {
                        this.props.navigation.navigate("ComplianceSuccess", {
                          ...options,
                        });
                        return;
                      } else {
                        this.props.navigation.navigate("OnboardingSuccess", {
                          ...options,
                        });
                        return;
                      }
                    }
                  }
                  this.props.navigation.navigate(redirect_screen, {
                    ...options,
                  });
                } else {
                  this.setState({
                    error: true,
                    errorText:
                      "Something went wrong! Please contact help@maxiom.in",
                  });
                }
              } else {
                this.props.navigation.navigate("KYCCreate");
              }
            } else {
              this.setState({
                isUploading: false,
              });
              this.props.navigation.navigate("KYCCreate");
            }
          } else {
            this.setState({
              error: true,
              errorText: response && response.msg ? response.msg : null,
              isUploading: false,
            });
          }
        }
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isUploading: false,
      });
    }
  };

  render() {
    const {
      viewSampleImage,
      isLoadingData,
      panNumber,
      panNumberError,
      dobError,
      panImageError,
      dateOfBirth,
      isUploading,
      showToast,
      toastMsg,
    } = this.state;
    const userData = getUserDetails();
    const { first_name } = userData;
    return (
      <SafeAreaView style={styles.container}>
        <Header
          navigation={this.props.navigation}
          route={this.props.route}
          onPressBack={() => {
            const { params } = this.props.route; // Destructure params for easier access
            if (params && params.from && params.from === "InvestLumpsum") { // Check if params exists
              this.props.navigation.dispatch(StackActions.replace("App", {
                screen: "Explore"
              }))
            } else if (params && params.from && params.from === "Goals") { // Check if params exists
              this.props.navigation.dispatch(StackActions.replace("App", {
                screen: "Goals"
              }))
             } else {
              this.props.navigation.dispatch(StackActions.replace("App", {
                screen: "Dashboard"
              }))
            }
            // console.log("-----------route-----------",this.props.route)
            // const { params } = this.props.route;
            // const { nvg, stack, screen } = params;
            // this.props.navigation.navigate("App", {
            //   screen: "Explore",
              // params: {
              //   screen: "InvestmentOverview",
              //   params: {
              //     activeTab: "InvestLumpsum",
              //   },
              // },
            // });
            // this.props.navigation.navigate("App", {
            //   screen: stack,
            //   params: {}
            // });
          }}
        />
        {showToast ? (
          <ShowToast showToast={true} type="status" title={toastMsg} />
        ) : null}
        {viewSampleImage && (
          <ViewSampleImage
            uri="https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/PAN+Card+Sample.png"
            title="View Sample PAN image"
            onclose={() => {
              this.setState({ viewSampleImage: false });
            }}
          />
        )}
        {isLoadingData ? (
          <View style={styles.loaderContainer}>
            <ActivityIndicator
              size="large"
              color="#410DAA"
              style={{ paddingTop: 40 }}
            />
          </View>
        ) : (
          <ScrollView style={styles.mainContainer}>
            <View style={{ flexDirection: "column" }}>
              <Progressbar
                color2="#f6f6f6"
                color3="#f6f6f6"
                color4="#f6f6f6"
                color5="#f6f6f6"
                color6="#f6f6f6"
                color7="#f6f6f6"
                color8="#f6f6f6"
                color9="#f6f6f6"
                color10="#f6f6f6"
                color11="#f6f6f6"
                color12="#f6f6f6"
                bcolor1="#f6f6f6"
                bcolor2="#f6f6f6"
                bcolor3="#f6f6f6"
                bcolor4="#f6f6f6"
                bcolor5="#f6f6f6"
                bagcolor1="#ffffff"
                bagcolor2="#ffffff"
                bagcolor3="#ffffff"
                bagcolor4="#ffffff"
                bagcolor5="#ffffff"
              />
              <View style={styles.body}>
                {isWeb && (
                  <View style={styles.left}>
                    <Text style={styles.terms}>PAN Number</Text>
                    <Text style={styles.please}>
                      Required for Know-Your-Customer (KYC) check
                    </Text>
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3.png"
                      }
                      style={styles.left_img}
                    />
                    <View
                      style={{
                        flexDirection: "row",
                        width: 430,
                        borderWidth: 1,
                        borderColor: "rgba(65, 13, 170, 0.2)",
                        backgroundColor: "#ffffff",
                        paddingVertical: 21,
                        paddingLeft: 20,
                        paddingRight: 6,
                        marginTop: 33,
                        marginBottom: 22,
                      }}
                    >
                      <Image
                        source={
                          "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/fluent_lock-shield-20-regular.png"
                        }
                        style={styles.lock}
                      />
                      <Text style={styles.jam_we}>
                        Information is encrypted using 256-bit keys and stored
                        securely
                      </Text>
                    </View>
                    <Text style={styles.any}>
                      Any Queries?{" "}
                      <TouchableOpacity
                        onPress={() => {
                          this.props.navigation.navigate("App", {
                            screen: "Dashboard",
                            params: {
                              screen: "Dashboard",
                              params: {
                                screen: "GetHelp",
                                params: {},
                              },
                            },
                          });
                        }}
                      >
                        <Text style={{ color: "#410DAA", fontWeight: "bold" }}>
                          Get Help
                        </Text>
                      </TouchableOpacity>
                    </Text>
                  </View>
                )}
                <View style={[styles.right]}>
                  <View style={styles.mob_styl}>
                    {/* <View style={{}}>
                      <Text style={[styles.terms, { paddingTop: 50 }]}>
                        PAN Number 
                      </Text>
                      <Text style={styles.please}>
                        Required for Know-Your-Customer (KYC) check 22
                      </Text>
                    </View> */}
                    <Text style={styles.pan_num}>Enter PAN Number*</Text>
                    <TextInput
                      value={panNumber}
                      placeholder={"Eg: AAAA 1234A"}
                      //placeholderTextColor="#00000030"
                      style={styles.inpt}
                      onChangeText={(text) => {
                        const panRegEx =
                          /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
                        const panValid = RegExp(panRegEx).test(text);
                        let errors = {};
                        if (panValid) {
                          errors["panNumberError"] = null;
                        }
                        this.setState({
                          panNumber: text ? text.toUpperCase() : "",
                          ...errors,
                        });
                      }}
                    />
                    <Text
                      style={[styles.please1, panNumberError && styles.error]}
                    >
                      {panNumberError || "  "}
                    </Text>
                    <Text style={styles.pan_num}>
                      Date Of Birth(DD/MM/YYYY)*
                    </Text>
                    <TextInput
                      value={dateOfBirth}
                      placeholder={"Enter Date of Birth"}
                      //placeholderTextColor="#00000030"
                      style={styles.inpt}
                      onChangeText={(text) => {
                        const dateRegEx =
                          /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
                        const dobValid = RegExp(dateRegEx).test(text);
                        let dobError = !dobValid
                          ? "Please enter valid date of birth"
                          : null;
                        this.setState({ dateOfBirth: text, dobError });
                      }}
                    />
                    <Text style={[styles.please1, dobError && styles.error]}>
                      {dobError || "  "}
                    </Text>
                    <Text style={[styles.error]}>
                      {this.state.errorText || " "}
                    </Text>

                    {isUploading ? (
                      <ActivityIndicator
                        size="large"
                        color="#410DAA"
                        style={{ paddingTop: 40 }}
                      />
                    ) : Store.getState().dashboard &&
                      Store.getState().dashboard.viewModeUserId &&
                      Store.getState().dashboard.viewModeUserId.user_id ? (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            this.handleContinue();
                          }}
                        >
                          <Text style={styles.viewModeText}>Next {">>>"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            let url = new URL(global.location.href);
                            location.replace(
                              url.origin +
                              "/dashboard/home?user_id=" +
                              this.state.user_id
                            );
                          }}
                        >
                          <Text style={styles.viewModeText}>
                            Go to Dashboard {">>>"}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          this.handleContinue();
                        }}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text style={styles.continue}>Continue</Text>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1, backgroundColor: "#fff" },
  mainContainer: {},
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: md(width) ? 130 : 13,
    paddingRight: md(width) ? 150 : 13,
    paddingBottom: 60,
  },
  left: {
    flex: 0.45,
    paddingTop: 55,
  },
  right: {
    flex: md(width) ? 0.55 : 1,
  },

  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },
  lock: {
    resizeMode: "contain",
    width: 30,
    height: 30,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 18 : 17,
    lineHeight: md(width) ? 21 : 18,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 15 : 12,
    lineHeight: md(width) ? 17 : 14,
    color: "#464E5F",
    paddingTop: md(width) ? 12 : 6,
    paddingBottom: md(width) ? 32 : 0,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  inpt: {
    backgroundColor: "#f6f6f6",
    paddingVertical: 13.5,
    paddingHorizontal: 19.5,
    borderRadius: 4,
    fontFamily: "Roboto",
    fontSize: md(width) ? 14 : 13,
    lineHeight: md(width) ? 16 : 15,
    color: "#121212",
  },
  pan_num: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 16 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    paddingTop: md(width) ? 50 : 30,
    paddingBottom: 6.5,
  },
  please1: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3.3,
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 18,
    marginTop: 20,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  continue: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    paddingVertical: 11.5,
    paddingHorizontal: 63.5,
    backgroundColor: "#410DAA",
    borderRadius: 4,
    marginTop: 50,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.3,
  },
  loaderContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  res: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: !isMobile ? 16 : 14,
    lineHeight: !isMobile ? 18 : 16,
    color: "#222225",
    paddingBottom: 13.29,
  },
  view_sample: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#8950FC",
    paddingBottom: !isMobile ? 5 : 10,
  },
  fileName: {
    fontSize: 15,
    marginTop: 5,
    color: "#410DAA",
    fontWeight: "bold",
  },
  support: {
    fontSize: 10,
    color: "#121212",
    fontStyle: "italic",
    marginTop: 4,
  },
  error: { color: "red" },
});

const mapStateToProps = (state) => {
  return {
    userProfileData: state.userData.userProfileData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveUserProfileData: (data) => dispatch(saveUserProfileData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(pan_number);
