import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import { StackActions } from "@react-navigation/native";
import { isWeb, isMobile } from "../../../utils";
import { getServerName } from "../../../api/urls";
import amplitude from "../../../utils/amplitude";
import { makePaymentOrderSummary } from "../../../api";
import { getInvoicesPaymentStatus } from "../../../api/billing";

export default class SwitchToPreferredCircle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: true,
      showAlert: false,
      isLoading2: false,
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("SwitchToPreferredCicle",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("SwitchToPreferredCicle", { path: this.props.navigation.getState().routeName });
    }
    const paymentRespnse = await getInvoicesPaymentStatus();
    if (paymentRespnse && paymentRespnse.code == 100) {
      if (paymentRespnse.msg == "Payment success") {
        this.setState({
          isLoading: false,
          showSuccessResponse: true,
          isLoading2: true,
        });
        setTimeout(() => {
          localStorage.clear();
          global.open("https://dmyadvisor.jamawealth.com/login", "_self");
        }, 20000);
      } else {
        this.props.navigation.dispatch(
          StackActions.replace("App", {
            screen: "Dashboard",
            params: {
              screen: "Dashboard",
              params: {
                screen: "Home",
                params: {
                  showFailedToast: "true",
                },
              },
            },
          })
        );
        return;
      }
    }
  }

  handleContinue = async () => {
    try {
      this.setState({ gettingPaymentLink: true });
      const server_name = getServerName();
      const payload = {
        server_name,
      };
      const response = await makePaymentOrderSummary(payload);
      if (response && response.code == 100) {
        const { results } = response;
        if (results && results.length > 0) {
          const { paymentLink } = results[0];
          this.props.navigation.navigate("App", {
            screen: "Dashboard",
            params: {
              screen: "Portfolio",
              params: {
                screen: "ShowWebView",
                params: {
                  url: paymentLink,
                  successURL: "onboarding/order/paymentsuccess",
                  onSuccess: function (navigation, results = {}) {
                    navigation.push("Onboarding", {
                      screen: "PaymentSuccess",
                      params: {},
                    });
                  },
                  onError: function (results = {}) {},
                },
              },
            },
          });
        } else {
          this.setState({
            error: true,
            errorText: "Something went wrong!",
            gettingPaymentLink: false,
          });
        }
      } else {
        this.setState({
          error: true,
          errorText: response.msg || "Something went wrong!",
          gettingPaymentLink: false,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        gettingPaymentLink: false,
      });
    }
  };

  moveToNextScreen = () => {
    this.props.navigation.dispatch(
      StackActions.replace("Onboarding", {
        screen: "PanDetails",
        params: {},
      })
    );
  };

  render() {
    const { isLoading, error } = this.state;
    return (
      <View style={styles.container}>
        {isLoading || error ? (
          <View style={styles.loadingContainer}>
            {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
            {error && (
              <Text>Something went wrong ! Error getting payment status </Text>
            )}
          </View>
        ) : (
          <ScrollView style={styles.mainContainer}>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ffffff",
                paddingBottom: isWeb ? 90 : 200,
                paddingHorizontal: isWeb ? "undefined" : 19,
              }}
            >
              <Image
                source={{
                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Advisory+Club.png",
                }}
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 4,
                }}
                resizeMode={"contain"}
              />
              <Text style={styles.you_have}>
                Congratulations! You have successfully subscribed to our
                Advisory Circle plan.
              </Text>
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontSize: isWeb ? 16 : 15,
                  lineHeight: 21,
                  color: "#000000",
                  fontWeight: "bold",
                  paddingTop: 45,
                  paddingBottom: 10,
                  fontWeight: "bold",
                  textAlign: isWeb ? "undefined" : "center",
                }}
              >
                Please wait while we are migrating your data, once done, you
                will be automatically redirected to Advisory Circle portal
              </Text>
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontSize: isWeb ? 16 : 15,
                  lineHeight: 21,
                  color: "#000000",
                  fontWeight: "bold",
                  paddingTop: 10,
                  paddingBottom: 20,
                  fontWeight: "bold",
                  textAlign: isWeb ? "undefined" : "center",
                }}
              >
                NOTE: Your login credentials will remain the same as used in
                (https://club.jamawealth.com/)
              </Text>
              <ActivityIndicator size="large" color="#410DAA" />
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  img: {
    width: isWeb ? 400 : 252,
    height: isWeb ? 350 : 198,
  },
  you_have: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 20 : 15,
    lineHeight: 21,
    color: "#000000",
    fontWeight: "bold",
    paddingTop: isWeb ? 10 : 32,
    paddingBottom: 17,
    fontWeight: "bold",
    textAlign: isWeb ? "undefined" : "center",
  },
  completed: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 15 : 13,
    lineHeight: isWeb ? 17 : 21,
    color: "#121212",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: isWeb ? 38 : 32,
  },
  cont: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    paddingVertical: 15,
    paddingHorizontal: 20,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  errorText: {
    color: "red",
    fontSize: 15,
    marginVertical: 15,
  },
  container: { flex: 1 },
  mainContainer: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  noContent: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  nothingFound: {
    fontSize: 15,
    color: "#121212",
  },
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: !isMobile ? 102 : 13,
    paddingRight: !isMobile ? 88 : 13,
    paddingBottom: 60,
  },
  left: {
    flex: 0.45,
    paddingTop: 55,
    display: isWeb ? "undefined" : "none",
  },
  right: {
    flex: !isMobile ? 0.55 : 1,
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },
  lock: {
    resizeMode: "contain",
    width: 24.5,
    height: 34,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 22,
    lineHeight: 25,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
    paddingTop: 11,
    paddingBottom: 32,
    paddingRight: 160,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  investment: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    fontWeight: "bold",
    color: "#2F3044",
    textAlign: "center",
    paddingTop: 50,
    paddingBottom: 27,
    display: isWeb ? "undefined" : "none",
  },

  plan: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 14 : 13,
    lineHeight: isWeb ? 16 : 15,
    color: "#464E5F",
  },
  plan_item: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    color: "#4A4B68",
    letterSpacing: 0.5,
    paddingRight: 25,
  },
  invoice_number: {
    fontSize: 14,
    color: "#8950FC",
    fontWeight: "bold",
  },
  invoice_date: {
    fontSize: 14,
    color: "#464E5F",
    fontWeight: "bold",
  },
  inv_amt: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#4A4B68",
    fontWeight: "normal",
  },
  inv_amt1: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#4A4B68",
    paddingTop: 9,
  },
  date: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#737178",
    letterSpacing: 0.5,
    borderBottomWidth: 1,
    //borderBottomRadius:6,
    borderBottomColor: "#E5EAEE",
    paddingBottom: 10,
  },
  inv_num: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 14 : 13,
    lineHeight: isWeb ? 16 : 21,
    color: "#8950FC",
    letterSpacing: 0.5,
    fontWeight: "bold",
    paddingTop: 21,
    paddingBottom: 4,
  },
  tot_bot: {
    borderTopColor: "#E5EAEE",
    borderBottomColor: "#E5EAEE",
    borderRadius: 6,
    //borderBottomRadius:6,
    borderTopWidth: isWeb ? 8 : 3,
    borderBottomWidth: isWeb ? 8 : 3,
    paddingVertical: 15,
    paddingHorizontal: isWeb ? 70 : "undefined",
    paddingLeft: isWeb ? "undefined" : 15,
    paddingRight: isWeb ? "undefined" : 43,
    marginTop: isWeb ? 20 : "undefined",
  },
  tot_amt: {
    fontFamily: "Roboto",
    fontWeight: isWeb ? "bold" : "400",
    lineHeight: 15,
    fontSize: isWeb ? 14 : 13,
    letterSpacing: 0.32,
    color: "#464E5F",
  },
  tot_amt1: {
    fontFamily: "Roboto",
    lineHeight: isWeb ? 16 : 15,
    fontSize: isWeb ? 15 : 13,
    letterSpacing: 0.32,
    color: "#464E5F",
    paddingTop: 11,
  },
  gra_tot: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: isWeb ? 16 : 15,
    fontSize: isWeb ? 15 : 13,
    letterSpacing: 0.32,
    color: "#000000",
    paddingTop: 14,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingHorizontal: 44,
    paddingVertical: 12,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  mob_plan: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    fontWeight: "bold",
    color: "#4A4B68",
  },
  mob_year: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#4A4B68",
    paddingTop: 9,
  },
  mob_top_pybl: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    fontWeight: "bold",
    letterSpacing: 0.32,

    textAlign: "center",
    flex: 1,
  },
  tt_amt: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 16,
    color: "#121212",
  },
  modalMobile: {
    position: "absolute",
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  modalWeb: {
    //    position:"fixed",
    // position: "absolute",
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  retry: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingHorizontal: 44,
    paddingVertical: 11,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  payment: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    color: "#F64E60",
    fontWeight: "bold",
  },
  comp: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    color: "#121212",
    paddingTop: 17,
    paddingBottom: 26,
  },
});
