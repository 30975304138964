import React from 'react';
import { StyleSheet, Text, View, TextInput, TouchableOpacity, Platform, ActivityIndicator } from 'react-native';
import HowItWorks from '../HowItWorks';
import {  RadioButton } from 'react-native-paper';
import { AntDesign } from '@expo/vector-icons';
import { HorizontalLine } from '../../../components/Common/HorizontalLine';
import { capitalizeFirstLetter, completeMinDetails, formatDate, getProductName, getUserDetails, valueInWords } from '../../../utils';
import { getUpcomingInvestments } from '../../../api';
import amplitude from '../../../utils/amplitude';
import NavigationHeader from '../../../components/Common/NavigationHeader';
import { StackActions } from '@react-navigation/native';
import ComplianceModal from '../../../components/InvestmentOverview/ComplianceModal';

const productData =[
    {
        title: "JEWEL",
        value: "JWL",
    },
    {
        title: "SPARK",
        value: "SRK",
    }
];

export default class AddTopUp extends React.Component {
    constructor(props){
        super(props);

        const { params } = this.props.route;
        let strategy = null;
        let userId = null;

        if(params){
            strategy = params.plan || null;
            userId = params.user_id;;
            userId = isNaN(userId) ? null : userId;
        }

        strategy = strategy || "JWL";
        
        this.state={
            data: null,
            plan:null,
            topup_plan:null,
            strategy,
            opened: false,
            userId,
            activeIndex : 0,
            amount: 0,
        }
    }

    componentDidMount = async () => {
        try {
            if (Platform.OS == "web"){
                amplitude.logEvent("addTopUp",{ path: global.location.href })
              }
              else{
                amplitude.logEvent("addTopUp", { path: this.props.navigation.getState().routeName });
              }
            this.setState({ gettingComplianceData: true});
            const minDetailsResponse = await completeMinDetails(true,this.state.userId);
            if(true || !minDetailsResponse.needCompletion){
                await this.loadUpcomingInvestments();
            }else{
                this.setState({ minDetailsResponse, gettingComplianceData: false, showComplianceAlert: true})
            }
        } catch (error) {
            this.setState({
                gettingComplianceData: false,
                error: true,
                errorText: "Something went wrong!"
            }) 
        }
    }

    loadUpcomingInvestments = async () => {
        try {
            const { userId } = this.state;
            const { first_name } = getUserDetails();
            this.setState({ gettingComplianceData: false, loadingData : true });
            const response = await getUpcomingInvestments(userId);
            if(response.code==100){
                let {data} = response.result;
                let productwiseData = {};
                const investments = [...data.investments];
                investments.forEach((item)=>{
                    if(item.product in productwiseData){
                        const plans = productwiseData[item.product]["plans"].filter((plan,index)=> plan.cip_pk != item.cip_pk);
                        productwiseData[item.product]["plans"]=[...plans,item];
                    }else{
                        productwiseData[item.product]={plans:[item],topup_amount:0};
                    }
                });

                const products = Object.keys(productwiseData);

                this.setState({
                    productwiseData,
                    products,
                    activeIndex: 0,
                    loadingData: false
                })
            }else{
                this.setState({
                    error: true,
                    errorText:  response  && response.msg ? response.msg : null,
                    loadingData: false
                }) 
            }
        } catch (error) {
            this.setState({
                error: true,
                errorText: "Something went wrong!",
                loadingData: false
            }) 
        }
    }

    handleContinueToTopup = async (activeData) => {
        try {
            const { amount, strategy } = this.state;
            if(!activeData){
                this.props.navigation.navigate("ThankYou",{plan: strategy})
                return;
            }
            if( amount < 100000){
                this.setState({minimumError: true});
                return;
            }
            if(!activeData) return;
            const id = activeData.cip_pk;
            const data = {
                product: strategy,
                id,
                amount: parseInt(amount)
            };
        } catch (error) {
            this.setState({
                error: true,
                errorText: "Something went wrong!"
            }) 
        }
    }

    handleOpenPlansList = () => {
        this.setState({opened: !this.state.opened});
    }

    selectPlan = (id) => {
        const { productwiseData, strategy, activeIndex } = this.state;
        if(productwiseData && strategy && productwiseData[strategy]){
            let activeIndex = null;
            const {plans} = productwiseData[strategy];
            if(plans && plans.length > 0){
                activeIndex = plans.findIndex((item)=> item.cip_pk == id);
                if(activeIndex == -1) return;
                this.setState({ activeIndex });
            }

        }
    }  

    getSelectablePlans = () => {
        const { productwiseData, strategy, activeIndex } = this.state;
        let activeData = null, plansListToSelect = [];

        if(productwiseData && strategy && productwiseData[strategy]){
            const {plans} = productwiseData[strategy];
            if(plans && plans.length > 0){
                activeData = plans[activeIndex];
                plansListToSelect = plans.filter((item,index)=>{
                    return index != activeIndex;
                });
            }
        }

        return { activeData, plansListToSelect };
    }

    render(){
        const { strategy, opened, amount, minimumError, loadingData, gettingComplianceData, minDetailsResponse, showComplianceAlert } = this.state;
        const { activeData, plansListToSelect } = this.getSelectablePlans();

        if(gettingComplianceData || showComplianceAlert ){
            return(
                <View style={{flex:1,flexDirection:"row",justifyContent:"center",backgroundColor:"#fff"}}>
                    <View style={{ flex:1, maxWidth:1500 }}>
                        <NavigationHeader navigation={this.props.navigation} navigationHeader={["Equity Plan","Add Topup"]} onPressBack={()=>{this.props.navigation.navigate("InvestmentOverview")}}/> 
                            <ComplianceModal 
                                navigation={this.props.navigation}
                                route={this.props.route}
                                isLoading={gettingComplianceData}
                                minDetailsResponse={minDetailsResponse}
                                redirectBackTo={"UpcomingInvestments"}
                                onClose={()=>{
                                    this.props.navigation.dispatch(
                                        StackActions.replace("InvestmentOverview")
                                    );
                                }}
                            />
                    </View>
                </View>
            )
        }

        if(loadingData){
            return(
                <View style={styles.container}>
                    <NavigationHeader navigation={this.props.navigation} navigationHeader={["Equity Plan","Add Topup"]} onPressBack={()=>{
                        this.props.navigation.navigate("InvestmentOverview")
                    }}/>
                    <View style={styles.loadingContainer}>
                        <ActivityIndicator size="large" color="#410DAA"/>
                    </View>
                </View>
            )
        }

        return(
            <View style={styles.container}>
                <NavigationHeader navigation={this.props.navigation} navigationHeader={["Equity Plan","Add Topup"]} onPressBack={()=>{
                    this.props.navigation.navigate("InvestmentOverview")
                }}/>
                <View style={styles.body}>
                    <View style={styles.left}>
                        <Text style={styles.addtop}>Add Topup in</Text>
                        <Text style={styles.select}>Select Investment Strategy</Text>
                        <View style={styles.productsContainer}>
                            {productData.map((item,index)=>{
                                    return(
                                        <View style={styles.productItemContainer} key={index}>
                                            <RadioButton
                                                onPress={()=>{
                                                    this.setState({strategy: item.value, activeIndex: 0, opened: false, amount: 0, minimumError: false})
                                                }}
                                                value={strategy}
                                                status={strategy==item.value ? 'checked' : 'unchecked'}
                                                color="#410DAA"
                                                uncheckedColor="#464E5F"
                                            />
                                            <Text style={styles.productItemTitlte}>{item.title}</Text>
                                        </View>
                                    )
                                })
                            }
                        </View>

                        <Text style={styles.plan}>Select Plan to topup</Text>
                        {
                            activeData ? (
                                <View style={styles.pick}>
                                    <View style={styles.pickerContainer}>
                                        <View style={styles.pickerItemContainer}>
                                            <Text style={{fontSize: 15, color:"#464E5F", fontWeight:"bold", marginBottom:14}}>{activeData.investment_name} - {activeData.investment_type}</Text>
                                            <Text style={{fontSize: 12, color:"#464E5F"}}>Plan No: {activeData.cip_pk} ({formatDate(activeData.plan_start_date)} - {formatDate(activeData.plan_end_date)})</Text>
                                        </View>
                                        <View style={{alignItems:"center"}}>
                                            <AntDesign
                                                name={opened ? "caretup" : "caretdown" }
                                                size={18}
                                                color="#410DAA"
                                                style={[{marginRight: 28}, Platform.OS=="web"&&{cursor:"pointer"}]}
                                                onPress={this.handleOpenPlansList}
                                            />
                                        </View>
                                    </View>
                                </View>
                            ) : (
                                <Text style={styles.noExistingPlans}>You have no existing plans under {getProductName(strategy)}</Text>
                            )
                        }
                        {
                            opened && (
                                <View style={{
                                        borderWidth:1,
                                        borderColor:"#410DAA50",
                                        backgroundColor: "#f6f6f6", 
                                        zIndex: 10,
                                        overflow: "scroll",
                                        maxHeight: 150,
                                        paddingHorizontal:15,
                                    }}>
                                        {
                                            plansListToSelect.map((plan,index)=>{
                                                return(
                                                    <TouchableOpacity
                                                        key={index}
                                                        style={{backgroundColor:"#f6f6f6", zIndex: 100, paddingVertical: 5, paddingBottom: 10}}
                                                        onPress={()=> {
                                                            this.selectPlan(plan.cip_pk);
                                                        }}
                                                    >
                                                        <>
                                                    <Text style={{fontSize: 15, color:"#00000090", fontWeight:"bold", marginBottom:14}}>{plan.investment_name} -  {plan.investment_name}</Text>
                                                    <Text style={{fontSize: 12, color:"#00000090"}}>Plan No: {plan.cip_pk}  |  ({formatDate(plan.plan_start_date)} - {formatDate(plan.plan_end_date)})</Text>
                                                        </>
                                                    </TouchableOpacity>
                                                )
                                            })
                                        }
                                        <HorizontalLine />
                                </View>
                            )
                        }
                        {
                            activeData && (
                                <>
                                    <Text style={styles.enter}>Enter additional Investment Amount</Text>
                                    <View style={styles.inp_txt}>
                                        <View style={styles.amountContainer}>
                                            <Text style={{color:"#888C9F", fontSize: 14}}>₹</Text>
                                            <TextInput
                                                value={amount}
                                                onChangeText={(amount)=>{
                                                    if(isNaN(amount)) return;
                                                    this.setState({amount, minimumError: amount<100000})
                                                }}
                                                style={styles.inptxt}
                                                placeholder="0"
                                                //placeholderTextColor="#888C9F"
                                            />
                                        </View>
                                        <Text style={[styles.minimum, minimumError && { color: "red" }]}>Minimum  ₹1,00,000</Text>
                                    </View>
                                    <Text style={{color:"#464E5F", fontSize: 13, fontStyle:"italic"}}>{capitalizeFirstLetter(valueInWords(amount))}</Text>
                                </>
                            )
                        }
                        <TouchableOpacity style={styles.topupButton} onPress={() => this.handleContinueToTopup(activeData)}>
                            <Text style={styles.topnow}>{ !activeData ? "Connect with Us" :"Topup Now"}</Text>
                        </TouchableOpacity>

                    </View>

                    <HowItWorks/>

                    </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container:{
        flex: 1,
        backgroundColor:"#ffffff",
    },
    loadingContainer: {
        flex:1, 
        justifyContent: "center",
        alignItems: "center"
    },
    body:{
        flexDirection:"row",
        justifyContent:"space-between",
        paddingHorizontal: 31,
        paddingVertical: 39
    },
    left:{
        flex:0.8, 
    },
    addtop:{
        fontFamily:"Roboto",
        fontWeight:"bold",
        fontSize:16,
        lineHeight:18,
        color:"#121212"
    },
    select:{
        fontFamily:"Roboto",
        fontSize:15,
        lineHeight:20,
        color:"#121212",
       letterSpacing:0.2,
        paddingTop:35,
        paddingBottom:10
    },
    productsContainer: {flexDirection:"row", alignItems:"center", justifyContent:"flex-start",marginBottom:40},
    productItemContainer: {flexDirection: "row", alignItems:"center", marginRight:41},
    productItemTitlte: {fontSize: 13, color:"#464E5F"},
    plan:{
        fontFamily:"Roboto",
        fontSize: 15,
        lineHeight:17,
        paddingBottom:16,
        color:"#121212"
    },
    pick_item:{
        fontFamily:"Roboto",
        fontSize: 15,
        lineHeight:17,
        color:"#464E5F"
    },
    pick:{
        paddingHorizontal:15,
        paddingVertical:20,
        borderRadius:6,
        borderColor: "#410DAA20",
        borderWidth: 1,
        overflow:"visible"
    },
    pickerContainer:{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    enter:{
        fontFamily:"Roboto",
        fontSize: 15,
        lineHeight:17,
        color:"#121212",
        paddingTop:45,
        paddingBottom:21,
        zIndex: -1
    },
    inp_txt:{
        zIndex: -1,
        flexDirection:"row",
        borderBottomWidth:1,
        borderBottomColor:"#D8D8D8",
        width:513,
        justifyContent:"space-between",
        paddingBottom:5
    },
    amountContainer: {flexDirection: "row", alignItems:"center"},
    inptxt:{
        fontFamily:"Roboto",
        fontSize: 15,
        lineHeight:17,
        color:"#121212",
        //outlineWidth: 0
    },
    minimum:{
        color:"#6C7293",
        fontFamily:"Roboto",
        fontSize:12,
        lineHeight:14,

    },
    topupButton: {alignItems:"flex-start",marginTop: 53,},
    topnow:{
        fontFamily:"Roboto",
        fontWeight:"bold",
        color:"#FFFFFF",
        paddingHorizontal:116,
        paddingVertical:12,
        backgroundColor:"#410DAA",
        borderRadius:6
    },
    noExistingPlans: {
        fontSize: 15,
        color: "#202020"
    }

});