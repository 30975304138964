import React from "react";
import {
  View,
  Text,
  TextInput,
  Picker,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { StackActions } from "@react-navigation/native";
import { postCustomerDematAccount } from "../../api";
import { getUserDetails } from "../../utils";
import NavigationHeader from "../../components/Common/NavigationHeader";

const DepositoryParticipantsData = [
  {
    key: 0,
    label: "select",
    value: null,
  },
  {
    key: 1,
    label: "Upstox",
    value: "UPX",
  },
  {
    key: 2,
    label: "Zerodha",
    value: "ZRD",
  },
  {
    key: 3,
    label: "HDFC Securities",
    value: "HDF",
  },
  {
    key: 4,
    label: "ICICI Direct",
    value: "ICI",
  },
  {
    key: 5,
    label: "Prostocks",
    value: "PRS",
  },
  {
    key: 6,
    label: "Kotak Securities",
    value: "KSC",
  },
  {
    key: 7,
    label: "Motilal Oswal Securities",
    value: "MOS",
  },
  {
    key: 8,
    label: "Sharekhan",
    value: "SHK",
  },
  {
    key: 9,
    label: "Globe capital",
    value: "Globe capital",
  },
  {
    key: 10,
    label: "IIFL Securities",
    value: "IFL",
  },
  {
    key: 11,
    label: "JM Financial Services",
    value: "JMF",
  },
  {
    key: 12,
    label: "NJ India Invest",
    value: "NJI",
  },
  {
    key: 13,
    label: "SBI Cap Securities",
    value: "SBI",
  },
  {
    key: 14,
    label: "Way2wealth",
    value: "WAY",
  },
  {
    key: 15,
    label: "Axis Direct",
    value: "AXS",
  },
  {
    key: 16,
    label: "Angel Broking",
    value: "ANB",
  },
  {
    key: 17,
    label: "Yes Securities",
    value: "YES",
  },
  {
    key: 18,
    label: "Edelweiss",
    value: "EDL",
  },
  {
    key: 19,
    label: "Alice Blue",
    value: "ALB",
  },
  {
    key: 20,
    label: "5Paisa",
    value: "FPS",
  },
  {
    key: 21,
    label: "Groww",
    value: "GRW",
  },
  {
    key: 22,
    label: "Trustline",
    value: "TRT",
  },
  {
    key: 23,
    label: "Other",
    value: "OTH",
  },
];

export default class linkExistingDemat extends React.Component {
  constructor(props) {
    super(props);
    let username = null;
    let cust_id = null;
    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      username = params.username;
      cust_id = params.customer_id;
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
    }
    this.state = {
      depository_participant: null,
      ucc_code: null,
      demat_acc_num: null,
      depository_participant_error: null,
      ucc_code_error: null,
      isLoading: false,
    };
  }

  handleSubmit = async () => {
    try {
      let {
        cust_id,
        username,
        depository_participant,
        ucc_code,
        demat_acc_num,
      } = this.state;
      this.setState({ isLoading: true });
      if (!cust_id || !username) {
        const userData = getUserDetails();
        username = userData.username;
        cust_id = userData.customer_id;
      }

      if (!depository_participant || depository_participant == "select") {
        this.setState({
          depository_participant_error: "Please enter depository participant",
          isLoading: false,
        });
      }

      if (!ucc_code) {
        this.setState({ ucc_code_error: "Please enter your UCC code" });
        isLoading: false;
      }

      const data = {
        customer: cust_id,
        stock_broker: depository_participant,
        ucc_id: ucc_code,
        clientid: demat_acc_num,
      };
      if (
        depository_participant &&
        ucc_code &&
        depository_participant != "select"
      ) {
        this.setState({ isLoading: true });
        const response = await postCustomerDematAccount(data);
        if (response && response.customer) {
          this.setState({ isLoading: false });
          this.props.navigation.dispatch(StackActions.replace("Demat"));
        } else {
          this.setState({
            isLoading: false,
            mainError: "Something went wrong!",
          });
          // console.log("errer", this.state.mainError);
        }
      } else {
        this.setState({ isLoading: false });
        // console.log("errer1", this.state.mainError);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      this.setState({ mainError: "Something went wrong!" });
      // console.log("errer2", this.state.mainError);
    }
  };
  handlePickerChange = (field, value) => {
    this.setState({ depository_participant: value });
  };
  render() {
    const {
      depository_participant,
      ucc_code,
      demat_acc_num,
      depository_participant_error,
      ucc_code_error,
      isLoading,
      mainError,
    } = this.state;
    return (
      <View style={styles.container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Link Existing Demat Account"]}
          style={styles.headerStyle}
        />
        <View style={styles.innerContainer}>
          <View style={styles.horiz}>
            <View>
              <Text style={styles.depository}>Depository Participant*</Text>
              <Picker
                selectedValue={depository_participant}
                style={styles.picker}
                onValueChange={(itemValue, itemIndex) => {
                  this.handlePickerChange("depository_participant", itemValue);
                }}
              >
                {DepositoryParticipantsData.map((item, key) => (
                  <Picker.Item
                    key={key}
                    label={item.label}
                    value={item.value}
                    labelStyle={styles.pickerItem}
                  />
                ))}
              </Picker>
              {(!depository_participant ||
                depository_participant == "select") && (
                <Text style={styles.please}>
                  {depository_participant_error}
                </Text>
              )}
            </View>
            <View>
              <Text style={styles.depository}>Demat UCC Code*</Text>
              <TextInput
                placeholder="Enter Demat UCC Code"
                style={styles.inptxt}
                onChangeText={(text) => {
                  this.setState({ ucc_code: text });
                }}
              />
              {!ucc_code && <Text style={styles.please}>{ucc_code_error}</Text>}
            </View>
          </View>
          <View>
            <Text style={styles.depository}>
              Demat Account Number (optional)
            </Text>
            <TextInput
              placeholder="Enter Demat Account Number"
              style={styles.inptxt}
              onChangeText={(text) => {
                this.setState({ demat_acc_num: text });
              }}
            />
          </View>
          {mainError ? (
            <Text style={[styles.please, { textAlign: "center" }]}>
              {mainError}
            </Text>
          ) : (
            <View>
              {isLoading ? (
                <ActivityIndicator
                  size="large"
                  color="Black"
                  style={{ alignSelf: "center" }}
                />
              ) : (
                <View style={styles.botbot}>
                  <TouchableOpacity
                    style={styles.sub}
                    onPress={() => {
                      this.handleSubmit();
                    }}
                  >
                    <Text style={styles.submit}>Save {"&"} Link</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  headerStyle: { width: "100%", maxWidth: 1500 },
  innerContainer: {
    maxWidth: 800,
    paddingHorizontal: 35,
    paddingVertical: 12,
  },
  depository: {
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: 24,
    color: "#464E5F",
  },
  inptxt: {
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderRadius: 6,
    // outlineWidth: 0,
    paddingHorizontal: 16,
    paddingVertical: 9,
    width: 320,
    fontFamily: "Arial",
    fontSize: 14,
    lineHeight: 24,
    color: "#464E5F",
    // placeholderTextColor: "rgba(70, 78, 95, 0.6)",
  },
  picker: {
    borderWidth: 1,
    borderColor: "#E2E8F0",
    paddingHorizontal: 16,
    paddingVertical: 9,
    borderRadius: 6,
    width: 320,
  },
  horiz: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 20,
  },
  submit: {
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
  },
  sub: {
    backgroundColor: "#410DAA",
    borderRadius: 4,
    paddingVertical: 11,
    paddingHorizontal: 66,
    marginTop: 30,
    alignItems: "center",
    justifyContent: "center",
  },
  botbot: {
    alignItems: "center",
    justifyContent: "center",
  },
  please: {
    fontFamily: "Arial",
    fontSize: 10,
    lineHeight: 12,
    color: "red",
    paddingTop: 5,
    fontStyle: "italic",
  },
  pickerItem: {
    fontFamily: "Arial",
    fontSize: 12,
    lineHeight: 14,
    color: "#464E5F",
  },
});
