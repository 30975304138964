import axiosApiInstance from "./apiConfig";
import { URLS } from "./urls";

// Authentication Flow
export const loginUser = async (data) => {
  const result = await axiosApiInstance.post(URLS.LOGIN, data, {}, true);
  return result;
};

export const SignUpUser = async (data) => {
  const result = await axiosApiInstance.post(URLS.SIGNUP, data, {}, true);
  return result;
};

export const addNotificationDevice = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.ADD_NOTIFICATION_DEVICE,
    data
  );
  return result;
};

export const getScreenConfig = async (data, headers) => {
  const result = await axiosApiInstance.post(
    URLS.GET_SCREEN_CONFIG,
    data,
    headers
  );
  return result;
};

export const changePassword = async (data, headersData = {}) => {
  const result = await axiosApiInstance.post(
    URLS.CHANGE_PASSWORD,
    data,
    headersData
  );
  return result;
};

export const checkCustomerPlanRenewal = async (data) => {
  const result = await axiosApiInstance.post(URLS.PLAN_RENEW, data);
  return result;
};

export const getCipForRenewal = async (userId) => {
  const url = URLS.RENEWAL + (userId ? userId + "/" : "");
  // console.log("Calling on API " + url);
  const result = await axiosApiInstance.get(url);
  // console.log(`Value of the planData is ${JSON.stringify(result)}`);
  return result;
};

export const sendOTP = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.LOGIN_SEND_OTP,
    data,
    {},
    true
  );
  return result;
};

export const verifyOTP = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.LOGIN_SEND_OTP,
    data,
    {},
    true
  );
  return result;
};

// Investments Flow
export const getUpcomingInvestments = async (userId) => {
  const url = URLS.UPCOMING_INVESTMENTS + (userId ? "/" + userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getUpcomingInvestmentsNew = async (userId) => {
  const url =
    URLS.UPCOMING_INVESTMENTS_NEW + (userId ? "/" + userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getInvestmentTransactionStatus = async (userId) => {
  const url = URLS.INVESTMENT_TRANSACTION_STATUS + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getHashKey = async (data) => {
  const result = await axiosApiInstance.post(URLS.INVESTMENT_HASH_KEY, data);
  return result;
};

export const getDefaultTabToShowTransactionStatus = async (userId) => {
  const url =
    URLS.GET_DEFAULT_TAB_TRANSACTION_STATUS + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

// Rebalance Flow

export const getRebalanceStripsData = async (userId) => {
  const url = URLS.REBALANCE_STRIPS_DATA + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getRebalanceStatus = async (productId, userId) => {
  const url =
    URLS.REBALANCE_STATUS + productId + "/" + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getCurrentHoldings = async (productId, userId) => {
  const url =
    URLS.CURRENT_HOLDINGS + productId + "/" + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getHashFetchHoldings = async (productId) => {
  const url = URLS.GET_CAIHASH_FETCH_HOLDINGS + productId + "/";
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getHashFetchHoldingsForTopup = async (payload, product) => {
  const url = URLS.GET_CAIHASH_FETCH_HOLDINGS + product + "/";
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const getHashForShowTopupRecommendations = async (payload) => {
  const url = URLS.SHOW_TOPUP_RECOMMENDATION;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const getRebalanceRecommendationsDay1 = async (
  productId,
  units,
  payload,
  user_id
) => {
  let url = "";
  if (user_id) {
    url =
      URLS.REBALANCE_RECOMMENDATIONS_DAY1 +
      productId +
      "/" +
      units +
      "/" +
      user_id +
      "/";
  } else {
    url = URLS.REBALANCE_RECOMMENDATIONS_DAY1 + productId + "/" + units + "/";
  }
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const getRebalanceRecommendationsDay2 = async (
  productId,
  units,
  payload,
  user_id
) => {
  let url = "";
  if (user_id) {
    url =
      URLS.REBALANCE_RECOMMENDATIONS_DAY2 +
      productId +
      "/" +
      units +
      "/" +
      user_id +
      "/";
  } else {
    url = URLS.REBALANCE_RECOMMENDATIONS_DAY2 + productId + "/" + units + "/";
  }
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const getRebalanceTransactionStatus = async (userId) => {
  const url = URLS.REBLANCE_TRANSACTION_STATUS + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const retryOrder = async (hash_token) => {
  const result = await axiosApiInstance.get(
    URLS.RETRY_ORDER + hash_token + "/"
  );
  return result;
};

// Investment Overview - Landing page

export const getLandingPageDetails = async (userId) => {
  const url = URLS.LANDING_PAGE + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getDashboard = async (userId) => {
  const url = URLS.DASHBOARD + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getMFData = async (userId) => {
  const url = URLS.MUTUALFUND + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getMFDetails = async (payload) => {
  const result = await axiosApiInstance.post(URLS.MFDETAILS, payload);
  return result;
};

export const getSWPOrder = async (payload) => {
  const result = await axiosApiInstance.post(URLS.BSE_PLACE_SWP_ORDER, payload);
  return result;
};

export const getSTPOrder = async (payload) => {
  const result = await axiosApiInstance.post(URLS.BSE_PLACE_STP_ORDER, payload);
  return result;
};

export const getSwitchOrder = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.PLACE_DIRECT_SWITCH_ORDER,
    payload
  );
  return result;
};

export const getInvestorAccountFundDetails = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.GET_INVESTOR_ACCOUNT_FUND_DETAILS,
    payload
  );
  return result;
};

export const getInsightDetails = async (payload) => {
  const result = await axiosApiInstance.post(URLS.INSIGHTDETAILS, payload);
  return result;
};

export const getLeaderBoardDetails = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.GET_LEADERBOARD_DETAILS,
    payload
  );
  return result;
};

export const getLeaderBoardUsersDetails = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.GET_LEADERBOARD_DETAILS_USERS,
    payload
  );
  return result;
};

export const updateInsightDetails = async (payload) => {
  const result = await axiosApiInstance.post(URLS.UPDATEINSIGHT, payload);
  return result;
};

export const redeemAmountForFund = async (payload) => {
  const result = await axiosApiInstance.post(URLS.REDEEM_AMOUNT, payload);
  return result;
};

export const getPortfolioDetails = async (data) => {
  const result = await axiosApiInstance.post(URLS.MFDETAILS, data);
  return result;
};

export const insertKYC = async (data) => {
  const result = await axiosApiInstance.post(URLS.KYC_INSERT, data);
  return result;
};

export const getAssetsDetails = async (data) => {
  const result = await axiosApiInstance.post(URLS.ASSETS_DATA, data);
  return result;
};

export const addAsset = async (data) => {
  const result = await axiosApiInstance.post(URLS.ADD_ASSET, data);
  return result;
};

export const getRecentAssets = async (data) => {
  const url = URLS.GET_RECENT_ASSETS;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const deleteAsset = async (data) => {
  const result = await axiosApiInstance.post(URLS.DELETE_ASSET, data);
  return result;
};

export const addFamilyMember = async (data) => {
  const result = await axiosApiInstance.post(URLS.ADD_FAMILY_MEMBER, data);
  return result;
};

export const viewMonthlyReport = async (userId) => {
  let url = "";
  if (userId) {
    url = URLS.VIEW_MONTHLY_REPORT + userId + "/";
  } else {
    url = URLS.VIEW_MONTHLY_REPORT;
  }
  const result = await axiosApiInstance.post(url, {
    document_type: "SPR",
  });
  return result;
};

export const getUserFundTransaction = async (fundId) => {
  const result = await axiosApiInstance.get(
    URLS.USER_FUND_TRANSACTION + fundId + "/transactions/"
  );
  return result;
};

export const getAMCFundSchemestaFn = async (fundId) => {
  const result = await axiosApiInstance.get(
    URLS.AMC_FUND_SCHEMES + fundId + "/schemes/"
  );
  return result;
};

export const getFundDetails = async (data) => {
  const result = await axiosApiInstance.post(URLS.GET_FUND_DETAILS, data);
  return result;
};

export const getSipMandate = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.GETALL_MANDATES_FOR_SIP,
    data
  );
  return result;
};

export const createOrderWithFirstInstallment = async (data) => {
  const result = await axiosApiInstance.post(URLS.PLACE_ORDERS, data);
  return result;
};

export const createOrderWithFirstInstallmentV2 = async (data) => {
  const result = await axiosApiInstance.post(URLS.PLACE_ORDERS_V2, data);
  return result;
};

export const createMandate = async (data) => {
  const result = await axiosApiInstance.post(URLS.CREATE_BANK_MANDATE, data);
  return result;
};

export const getCustomerBankAccounts = async () => {
  const result = await axiosApiInstance.get(URLS.GET_CUSTOMER_BANK_ACCOUNTS);
  return result;
};

export const getExploreScreenFunds = async () => {
  let result;
  try {
    result = await axiosApiInstance.get(URLS.EXPLORE_FUNDS);
  } catch (error) {
    console.log(error);
    result = error;
  }
  return result;
};

export const getTransactionPopUp = async () => {
  const result = await axiosApiInstance.get(URLS.TRANSACTION_POP_UP);
  return result;
};

export const closeTransactionPopUp = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.CLOSE_TRANSACTION_POP_UP,
    data
  );
  return result;
};

// My Equity Investment Plans Flow

export const getMyEquityInvestmentPlans = async (userId) => {
  const url = URLS.MY_EQUITY_INVESTMENT_PLANS + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getStartNewPlanContern = async () => {
  const result = await axiosApiInstance.get(URLS.START_NEW_PLAN);
  return result;
};

export const getCIPInstallments = async (payload) => {
  const url = URLS.CIP_INSTALLMENTS;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const updateCIPPlannedDate = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.UPDATE_CIPS_PLANNED_DATE,
    data
  );
  return result;
};

export const updateSIPDateAndFrequency = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.UPDATE_SIP_DATE_FREQUENCY,
    data
  );
  return result;
};

export const getCIPDetailsInvestNow = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.GET_CIP_DETAILS_INVEST_NOW,
    data
  );
  return result;
};

export const addAnotherInstallment = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.ADD_ANOTHER_INSTALLMENT,
    data
  );
  return result;
};

//onboarding APIs

export const getCustomerDetails = async (cust_id) => {
  const url = URLS.GET_CUSTOMER_DETAILS_V2 + cust_id + "/";
  const result = await axiosApiInstance.get(url);
  return result;
};

export const storeTokenData = async (payload, headers) => {
  const result = await axiosApiInstance.post(
    URLS.STORE_TOKEN,
    payload,
    headers
  );
  return result;
};

export const addPanNumber = async (cust_id, data) => {
  const result = await axiosApiInstance.patchFormData(
    URLS.CUSTOMER_DETAILS + cust_id + "/",
    data
  );
  return result;
};

export const onboardingSendOTP = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.ONBOARDING_SEND_OTP,
    data,
    {},
    true
  );
  return result;
};

export const contactInfo = async (cust_id, data) => {
  const result = await axiosApiInstance.patchFormData(
    URLS.CUSTOMER_DETAILS + cust_id + "/",
    data
  );
  return result;
};

export const nomineeInfo = async (cust_id, data) => {
  const result = await axiosApiInstance.patch(
    URLS.CUSTOMER_DETAILS + cust_id + "/",
    data
  );
  return result;
};

export const getCustomerInvestmentPlans = async (cust_id, data) => {
  const result = await axiosApiInstance.get(URLS.AGREEMENT_E_SIGN, data);
  return result;
};

// ADMIN ACTION
export const getCustomerInvestmentPlansAdmin = async (userId) => {
  const url = URLS.CLIENT_INVESTMENT_PLANS_ADMIN + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const aadharbasedesign = async (data) => {
  const result = await axiosApiInstance.post(URLS.AADHAR_BASED_E_SIGN, data);
  return result;
};

export const financialInfo = async (cust_id, data) => {
  const result = await axiosApiInstance.patch(
    URLS.CUSTOMER_DETAILS + cust_id + "/",
    data
  );
  return result;
};

export const getRiskProfileQuestions = async (userId) => {
  const url = URLS.RISKPROFIE_QUESTIONS + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const saveRiskProfile = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.SAVE_RISKPROFILE_ANSWERS,
    data
  );
  return result;
};

export const getAssetsAndLiabilities = async (data) => {
  const result = await axiosApiInstance.post(URLS.ASSETS_AND_LIABILITIES, data);
  return result;
};

export const getOrderSummary = async (userId) => {
  const url = URLS.GET_ORDER_SUMMARY + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const verifyIFSC = async (data) => {
  const result = await axiosApiInstance.post(URLS.VERIFY_IFSC, data);
  return result;
};

export const addBankAccount = async (data) => {
  const result = await axiosApiInstance.postFormData(
    URLS.CUSTOMER_BANK_ACCOUNTS,
    data
  );
  return result;
};

export const getCustMandateAmt = async (data) => {
  const result = await axiosApiInstance.post(URLS.GET_CUST_MANDATE_AMT, data);
  return result;
};

export const createCustMandate = async (data) => {
  const result = await axiosApiInstance.post(URLS.CREATE_CUST_MANDATE, data);
  return result;
};

export const getAggrementESign = async () => {
  const result = await axiosApiInstance.get(URLS.AGGREMENT_E_SIGN);
  return result;
};

export const agreementEsign = async (data) => {
  const result = await axiosApiInstance.post(URLS.AGREEMENT_ESIGN, data);
  return result;
};

export const makePaymentOrderSummary = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.MAKE_PAYMENT_ORDER_SUMMARY,
    data
  );
  return result;
};

export const getOnboardingStatus = async (userId) => {
  const url = URLS.GET_ONBOARDING_STATUS + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const updateDigioAgreementStatus = async (userId) => {
  const url = URLS.UPDATE_DIGIO_AGREEMENT + (userId ? "/" + userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const updatePaymentStatusOrderSummary = async (userId) => {
  const url = URLS.UPDATE_PAYMENT_STATUS + (userId ? "/" + userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const Contactus = async (data) => {
  // action = stock-advisory / financial-planning / portfolio-advisory / pricing / start_switch / send_more_info / contact_customer /
  const result = await axiosApiInstance.post(URLS.CONTACT_US, data);
  return result;
};

export const getHashKeyValue = async () => {
  const result = await axiosApiInstance.get(URLS.GET_HASH_KEY_VALUE);
  return result;
};

export const showFamilyPopup = async () => {
  const result = await axiosApiInstance.get(URLS.SHOW_FAMILY_POPUP);
  return result;
};

// Portfolio

export const getStocksPortfolio = async (userId) => {
  const url = URLS.PORTFOLIO_STOCKS + userId + "/";
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getStocksPortfolioWithDate = async (userId, fromDate, toDate) => {
  const result = await axiosApiInstance.post(URLS.PORTFOLIO_STOCKS_WITH_DATE, {
    custpk: userId,
    from_date: fromDate,
    to_date: toDate,
  });
  return result;
};

// Passbook

export const getPassbookData = async (userId) => {
  const url = URLS.PASSBOOK + userId + "/";
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getPassbookForDate = async (data) => {
  const url = URLS.PASSBOOK_FOR_DATE + "/";
  const result = await axiosApiInstance.post(url, data);
  return result;
};

//Billing

export const getPendingPayments = async (cust_id) => {
  const url = URLS.PENDING_PAYMENTS + cust_id + "/";
  const result = await axiosApiInstance.get(url);
  return result;
};

// Demat account opening

export const getHashKeyToOpenDematAccount = async () => {
  const url = URLS.DEMAT_ACCOUNT_OPENING;
  const result = await axiosApiInstance.get(url);
  return result;
};

//Demat Screens
export const getCustomerDematAccount = async (userId) => {
  const url = URLS.CUSTOMER_DEMAT_ACCOUNT + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const postCustomerDematAccount = async (data) => {
  const result = await axiosApiInstance.post(URLS.CUSTOMER_DEMAT_ACCOUNT, data);
  return result;
};

export const getDematAccounts = async (userId) => {
  const url = URLS.OPEN_DEMAT_ACCOUNT + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

//ContractNote
export const getContractNote = async (userId) => {
  const url = URLS.VIEW_CONTRACTNOTE + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  // console.log("api result", result);
  return result;
};

export const getContractNoteDetails = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.VIEW_CONTRACTNOTE_DETAILS,
    data
  );
  return result;
};

export const uploadContrcatNote = async (data) => {
  const result = await axiosApiInstance.postFormData(
    URLS.UPLOAD_CONTRACT_NOTE,
    data
  );
  return result;
};

//Referral Screens
export const postReferralInvite = async (data) => {
  const result = await axiosApiInstance.post(URLS.SEND_REFERRAL_INVITE, data);
  return result;
};

export const getCustomerReferrals = async (userId) => {
  const url = URLS.CUSTOMER_REFERRALS + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const postRemainder = async (data) => {
  const result = await axiosApiInstance.post(URLS.SEND_REFERRAL_REMINDER, data);
  return result;
};

export const getFinAwarenessData = async () => {
  const url = URLS.GET_FIN_AWARENESS;
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getQuizDiagnostics = async (data) => {
  const url = URLS.QUIZ_DIAGNOSTICS;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const getQuizQnA = async (data) => {
  const url = URLS.QUIZ_QUESTIONS;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const submitQuizQnA = async (data) => {
  const url = URLS.SUBMIT_QUIZ_ANSWERS;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const getQuizResult = async (data) => {
  const url = URLS.QUIZ_RESULT;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const getEduModules = async () => {
  const url = URLS.EDU_MODULE_DATA;
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getModuleData = async (data) => {
  const url = URLS.GET_CHAPTERS_MODULE;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const trackUserActivityEdu = async (data) => {
  const url = URLS.TRACK_USER_ACTIVITY_EDU;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const getEduQuizResult = async (data) => {
  const url = URLS.EDU_QUIZ_RESULT;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const userFeedbackOnChapter = async (data) => {
  const url = URLS.USER_FEEDBACK_EDU;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const camsPdfUploadUsingEmail = async (data) => {
  const url = URLS.CAMS_PDF_USING_EMAIL;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const updateEmailCustomer = async (cust_id, data) => {
  const url = URLS.CUSTOMER_DETAILS + cust_id + "/";
  const result = await axiosApiInstance.patch(url, data);
  return result;
};

export const updateCamsEmailCustomer = async (cust_id, data) => {
  const url = URLS.CUSTOMER_DETAILS + cust_id + "/";
  const result = await axiosApiInstance.patch(url, data);
  return result;
};

export const fetchCurrentSubscriptionPlan = async (cust_id, data) => {
  const url = URLS.CUSTOMER_DETAILS + cust_id + "/";
  const result = await axiosApiInstance.get(url, data);
  return result;
};

export const uploadPDF = async (data) => {
  const url = URLS.UPLOAD_PDF;
  const result = await axiosApiInstance.patchFormData(url, data);
  return result;
};

export const getcartItems = async () => {
  const url = URLS.FUNDS_IN_CART_WISHLIST;
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getJamaSuggestedAmount = async (data) => {
  const url = URLS.JAMA_SUGGESTED_AMOUNT;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const getInvestmentPlans = async (userId) => {
  const url = URLS.GET_INVESTMENT_PLANS + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getCompleteDashboard = async () => {
  const url = URLS.COMPLETE_DASHBOARD;
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getCustomerPortfolioData = async (userId) => {
  const url = URLS.GET_CUSTOMER_PORTFOLIO_DATA + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const updateCustomerPortfolioData = async (userId) => {
  const url = URLS.UPDATE_CUSTOMER_PORTFOLIO_DATA + (userId ? userId + "" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getLoginBannerDetails = async () => {
  const url = URLS.LOGIN_BANNER_DETAILS;
  const result = await axiosApiInstance.get(url);
  return result;
};

export const forgotPasswordReset = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.FORGOT_PASSWORD,
    payload,
    {},
    true
  );
  return result;
};

export const getCustomerActionItems = async () => {
  const result = await axiosApiInstance.get(URLS.GET_CUSTOMER_ACTION_ITEMS);
  return result;
};

export const getUrlForSmallcaseOrders = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.GET_URL_TO_PLACE_SMALLCASE_ORDERS,
    data
  );
  return result;
};

export const rejectAdvisoryActionItems = async (data) => {
  const result = await axiosApiInstance.post(URLS.REJECT_ACTION_ITEMS, data);
  return result;
};

export const viewMFMonthlyReport = async (userId) => {
  let url = "";
  if (userId) {
    url = URLS.VIEW_MF_MONTHLY_REPORT + userId + "/";
  } else {
    url = URLS.VIEW_MF_MONTHLY_REPORT;
  }
  const result = await axiosApiInstance.post(url, {
    document_type: "MPR",
  });
  return result;
};

export const getJwlSrkReturns = async () => {
  const result = await axiosApiInstance.get(URLS.GET_JWL_SRK_RETURNS);
  return result;
};

export const getAdvisoryFeeDetails = async () => {
  const result = await axiosApiInstance.get(URLS.GET_ADVISORY_FEE_DETAILS);
  return result;
};

export const skipCustomerLogin = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.SKIP_CUSTOMER_LOGIN,
    data,
    {},
    true
  );
  return result;
};

export const checkCustomerSignup = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.CHECK_CUSTOMER_SIGNUP,
    data,
    {},
    true
  );
  return result;
};

export const runActionItemsToPlaceOrders = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.RUN_ACTION_ITEMS_TO_PLACE_ORDERS,
    data
  );
  return result;
};

export const getLeadData = async (data) => {
  const url = URLS.GET_LEAD_DATA;
  const result = await axiosApiInstance.post(url, data, {}, true);
  return result;
};

export const updateCamsWebhookResponseFromCai = async (data) => {
  const result = await axiosApiInstance.get(
    URLS.UPDATE_CAMS_WEBHOOK_RESPONSE_FROM_CAI
  );
  return result;
};

export const getDatabaseOfUser = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.GET_DATABASE_OF_USER,
    data,
    {},
    true
  );
  return result;
};

export const getCustomerReport = async (data) => {
  const result = await axiosApiInstance.post(URLS.CUSTOMER_REPORT, data);
  return result;
};

export const getFinancialHealthPlan = async (data) => {
  const result = await axiosApiInstance.post(URLS.FINANCIAL_HEALTH_PLAN, data);
  return result;
};

export const updateCustomerCorporateMapping = async (data) => {
  const result = await axiosApiInstance.postFormData(
    URLS.UPDATE_CUSTOMER_CORPORATE_MAPPTING,
    data
  );
  return result;
};

export const getFinancialLearningDataCorporate = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.FINANCIAL_LEARNING_DATA_CORPORATE,
    data
  );
  return result;
};

export const createSendyActionForCustomer = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.CREATE_SENDY_ACTION_FOR_CUSTOMER,
    data
  );
  return result;
};

export const getEmailTemplateData = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.GET_EMAIL_TEMPLATE_DATA,
    data
  );
  return result;
};

export const getCustomerActionItemDetails = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.GET_CUSTOMER_ACTION_ITEM_DETAILS,
    data
  );
  return result;
};

export const createCustomerReferralCode = async () => {
  const result = await axiosApiInstance.get(URLS.CREATE_CUSTOMER_REFERRAL_CODE);
  return result;
};

export const createCrmdealsCipCbiForAccentureClients = async () => {
  const result = await axiosApiInstance.get(
    URLS.CREATE_CRMDEALS_CIP_CBI_FOR_ACCENTURE
  );
  return result;
};

export const mobileOtpVerificationBeforeSignup = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.MOBILE_OTP_VERIFICATION_BEFORE_SIGNUP,
    data,
    {},
    true
  );
  return result;
};

export const markContractNoteAsNotTransacted = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.MARK_CONTRACT_NOTE_AS_NOT_TRANSACTED,
    data
  );
  return result;
};

export const markContractNoteAsAlreadySent = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.MARK_CONTRACT_NOTE_AS_ALREADY_SENT,
    data
  );
  return result;
};

export const unmarkContractNoteAsNotTransacted = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.UNMARK_CONTRACT_NOTE_AS_NOT_TRANSACTED,
    data
  );
  return result;
};

export const checkUserExistsBasedOnPan = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.CHECK_USER_EXISTS_BASED_ON_PAN,
    data
  );
  return result;
};

export const createCrmdealsCipForRegular = async () => {
  const result = await axiosApiInstance.get(
    URLS.CREATE_CRMDEALS_CIP_FOR_REGULAR
  );
  return result;
};

export const getAccountAggregatorConsentStatus = async () => {
  const result = await axiosApiInstance.get(
    URLS.GET_ACCOUNT_AGGREGATOR_CONSENT_STATUS
  );
  return result;
};

export const getAdvisoryTracker = async (userId) => {
  const result = await axiosApiInstance.get(URLS.GET_ADVISORY_TRACKER + (userId ? userId + "/" : ""));
  return result;
};

export const createCrmdealsCipForAdvisory = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.CREATE_CRMDEALS_CIP_CBI_FOR_ADVISORY,
    data
  );
  return result;
};

export const getStockRecommendations = async () => {
  const result = await axiosApiInstance.get(URLS.GET_STOCK_RECOMMENDEDATIONS);
  return result;
};

export const stocksPlaceOrder = async (data) => {
  const result = await axiosApiInstance.post(URLS.STOCK_PLACE_ORDER, data);
  return result;
};

export const sellStocks = async () => {
  const result = await axiosApiInstance.get(URLS.SELL_EXIT_STOCK);
  return result;
};

export const generateAgreementPdf = async () => {
  const result = await axiosApiInstance.get(URLS.GENERATE_AGREEMENT_PDF);
  return result;
};

export const sellMarkComplete = async (data) => {
  const result = await axiosApiInstance.post(URLS.SELL_MARK_COMPLETE, data);
  return result;
};

export const getCustomerIAFF = async (data) => {
  const result = await axiosApiInstance.post(URLS.GET_CUSTOMER_IAFF, data);
  return result;
};

export const mapInvestorAccountEntityToGoal = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.MAP_INVESTOR_ACC_TO_GOAL,
    data
  );
  return result;
};

export const GetPdfBytesObjectFromServer = async () => {
  const result = await axiosApiInstance.get(
    URLS.GET_PDF_BYTES_OBJECT_FROM_SERVER
  );
  return result;
};

export const uploadStockMF = async (data) => {
  const result = await axiosApiInstance.putFormData(URLS.UPLOAD_STOCK_MF, data);
  return result;
};

export const uccCodeGenerate = async (data) => {
  const result = await axiosApiInstance.post(URLS.UCC_CODE_GENERATE, data);
  return result;
};

export const getCorporateDashboard = async (userId) => {
  const url = URLS.GET_CORPORATE_DASHBOARD + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getFinancialWellnessIndex = async (corporateId) => {
  const url =
    URLS.GET_FINANCIAL_WELLNESS_INDEX + (corporateId ? corporateId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getFinancialLearningProgress = async (corporateId) => {
  const url =
    URLS.GET_FINANCIAL_LEARNING_PROGRESS +
    (corporateId ? corporateId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getFinancialPlanProgress = async (corporateId) => {
  const url =
    URLS.GET_FINANCIAL_PLAN_PROGRESS + (corporateId ? corporateId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getEmpQuizData = async (data) => {
  const result = await axiosApiInstance.post(URLS.GET_EMP_QUIZ_DATA, data);
  return result;
};

export const getRetirementBasicDetails = async () => {
  const result = await axiosApiInstance.get(
    URLS.STORE_RETIREMENT_BASIC_DETAILS
  );
  return result;
};

export const storeRetirementBasicDetails = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.STORE_RETIREMENT_BASIC_DETAILS,
    data
  );
  return result;
};

export const getCorpusOrExpenseDetails = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.GET_CORPUS_OR_EXPENSE_DETAILS,
    data
  );
  return result;
};

export const getNetCorpusAtRetirement = async () => {
  const url = URLS.GET_NET_CORPUS_AT_RETIREMENT;
  const result = await axiosApiInstance.get(url);
  return result;
};

export const saveRetirementReducingFactor = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.SAVE_RETIREMENT_REDUCING_FACTOR,
    data
  );
  return result;
};

export const saveExpenseOrIncomeRecords = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.SAVE_EXPENSE_OR_INCOME_RECORDS,
    data
  );
  return result;
};

export const generateCamsAggregatorLink = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.GENERATE_CAMS_AGGREGRATOR_LINK,
    data
  );
  return result;
};

export const getUrlForClient2FA = async () => {
  const url = URLS.GET_URL_FOR_CLIENT_2FA;
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getBsePaymentUrlForMfOrders = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.GET_BSE_PAYMENT_URL_FOR_MF_ORDERS,
    data
  );
  return result;
};

export const scheduleCronJobWithApi = async (data) => {
  const result = await axiosApiInstance.post(
    URLS.SCHEDULE_CRON_JOB_WITH_API,
    data
  );
  return result;
};

export const getCipsAboutToExpire = async (userId) => {
  const url = URLS.GET_CIPS_ABOUT_TO_EXPIRE + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getBookAdvisoryCountOfCorporates = async (data) => {
  const url = URLS.GET_BOOK_ADVISORY_COUNT_OF_CORPORATES;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const planRenewalForClients = async (data) => {
  const url = URLS.PLAN_RENEWAL_FOR_CLIENTS;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const sendMailUsingTemplate = async (data) => {
  const url = URLS.SEND_MAIL_USING_HTML;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const moveDataFromDirectToRegular = async (data) => {
  const url = URLS.MOVE_DATA_FROM_DIRECT_TO_REGULAR;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const deletePlansAndInvoicesForPaymentFailed = async (data) => {
  const url = URLS.DELETE_PLANS_AND_INVOICES_FOR_PAYMENT_FAILED;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const moveDataFromRegularToDirect = async (data) => {
  const url = URLS.MOVE_DATA_FROM_REGULAR_TO_DIRECT;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const cancelAllSipOrdersForACustomer = async () => {
  const url = URLS.CANCEL_ALL_SIP_ORDERS_FOR_A_CUSTOMER;
  const result = await axiosApiInstance.get(url);
  return result;
};

export const updateCustomerGoalValues = async (userId) => {
  const url = URLS.UPDATE_CUSTOMER_GOAL_VALUES + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const createPlanforPlatformSubscription = async () => {
  const result = await axiosApiInstance.post(
    URLS.CREATE_PLAN_FOR_PLATFORM_SUBSCRIPTION
  );
  return result;
};

export const updateLongDurationPlanData = async () => {
  const result = await axiosApiInstance.get(
    URLS.UPDATE_LONG_DURATION_PLAN_DATA
  );
  return result;
};

// export const logErrorInSentry = async (data) => {
//   const result = await axiosApiInstance.post(URLS.LOG_ERROR_IN_SENTRY, data);
//   return result;
// };

export const createActionItemForMFOrders = async (data) => {
  const result = await axiosApiInstance.post(URLS.CREATE_ACTION_ITEMS_FOR_MF_ORDERS, data);
  return result;
}
export const getClientMfOrdersPlacedToday = async() => {
  const result = await axiosApiInstance.get(URLS.GET_CLIENT_MF_ORDERS_PLACED_TODAY);
  return result;
}
export const otpAuthForMfOrders = async (data) => {
  const result = await axiosApiInstance.post(URLS.OTP_AUTH_FOR_MF_ORDERS, data);
  return result;
}