import React, { useState, useEffect} from "react";
import { StyleSheet, View, Dimensions, TextInput as NativeTextInput } from "react-native";
import { Text, TextInput } from "react-native-paper";

const window = Dimensions.get("window");

export default function FormInputRow({
    inModal,
    label,
    isMultiline,
    type,
    isValid,
    errorText,
    value,
    onChange,
    showAllErrors,
    hasError,
    setHasError
}) {
    const [isFocused, setIsFocused] = useState(false);
    useEffect(() => {
        if (showAllErrors) {
            if (isValid && !isValid(value)) {
                setHasError(true);
            }
        }
    }, [showAllErrors]);
    
    const onChangeText = text => {
        onChange(text);
    };
    
    const handleFocus = () => {
        if (setHasError) {
            setHasError(false);
        }
            setIsFocused(true);
    };
    
    const handleBlur = () => {
        if (isValid) {
            if (isValid(value)) {
                setHasError(false);
            } else {
                setHasError(true);
            }
        }
        setIsFocused(false);
    };

    return (
        <View
            style={
            {
                // marginBottom: 20
            }
            }
        >
            <TextInput
            placeholder={label}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
            multiline={isMultiline}
            onChangeText={onChangeText}
            label={label}
            mode="outlined"
            style={{
                fontSize: 15,
                // display: 'flex',
                // flexDirection: 'column',
                // textAlignVertical: isMultiline ? 'top': 'center',
                backgroundColor: "white"
            }}
            dense={true}
            numberOfLines={isMultiline ? 5 : 1}
            theme={{
                colors: {
                primary: "#B4BEC8",
                accent: "#B4BEC8",
                text: hasError ? "#EC3539" : "#0F001A"
                }
            }}
            keyboardAppearance="default"
            keyboardType={type === "number" ? "number-pad" : undefined}
            render={innerProps => (
                <NativeTextInput
                {...innerProps}
                style={[
                    innerProps.style,
                    isMultiline
                    ? {
                        paddingTop: 10,
                        // paddingBottom: 10,
                        height: 137,
                        width: inModal ? 422 : undefined
                        }
                    : {
                        paddingTop: 20,
                        // paddingBottom: 10,
                        height: 50,
                        display: "flex",
                        justifyContent: "center",
                        textAlignVertical: "center",
                        width: inModal ? 422 : undefined
                        }
                ]}
                />
            )}
            ></TextInput>
            <View
            style={{
                borderWidth: 1,
                height: 20,
                borderColor: "#FFF"
            }}
            >
            {hasError && (
                <Text
                style={{
                    color: "#EC3539",
                    fontStyle: "italic",
                    fontSize: 13
                }}
                >
                {errorText}
                </Text>
            )}
            </View>
        </View>
    )
}

