import React from "react";
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  StyleSheet,
  FlatList,
  ActivityIndicator,
  Platform,
  Image,
  TextInput,
  Picker,
} from "react-native";
import {
  getDematAccounts,
  getCustomerDematAccount,
  postCustomerDematAccount,
  getHashKeyToOpenDematAccount,
} from "../../api";
import NavigationHeader from "../../components/Common/NavigationHeader";
import PickerFieldSelect from "../../components/Common/Pickers/PickerFieldSelect";

import amplitude from "../../utils/amplitude";
import { generateGatewayUrl, getUserDetails, isMobile } from "../../utils";
import Store from "../../store";

const DepositoryParticipantsData = [
  {
    key: 0,
    label: "select",
    value: null,
  },
  {
    key: 1,
    label: "Upstox",
    value: "UPX",
  },
  {
    key: 2,
    label: "Zerodha",
    value: "ZRD",
  },
  {
    key: 3,
    label: "HDFC Securities",
    value: "HDF",
  },
  {
    key: 4,
    label: "ICICI Direct",
    value: "ICI",
  },
  {
    key: 5,
    label: "Prostocks",
    value: "PRS",
  },
  {
    key: 6,
    label: "Kotak Securities",
    value: "KSC",
  },
  {
    key: 7,
    label: "Motilal Oswal Securities",
    value: "MOS",
  },
  {
    key: 8,
    label: "Sharekhan",
    value: "SHK",
  },
  {
    key: 9,
    label: "Globe capital",
    value: "Globe capital",
  },
  {
    key: 10,
    label: "IIFL Securities",
    value: "IFL",
  },
  {
    key: 11,
    label: "JM Financial Services",
    value: "JMF",
  },
  {
    key: 12,
    label: "NJ India Invest",
    value: "NJI",
  },
  {
    key: 13,
    label: "SBI Cap Securities",
    value: "SBI",
  },
  {
    key: 14,
    label: "Way2wealth",
    value: "WAY",
  },
  {
    key: 15,
    label: "Axis Direct",
    value: "AXS",
  },
  {
    key: 16,
    label: "Angel Broking",
    value: "ANB",
  },
  {
    key: 17,
    label: "Yes Securities",
    value: "YES",
  },
  {
    key: 18,
    label: "Edelweiss",
    value: "EDL",
  },
  {
    key: 19,
    label: "Alice Blue",
    value: "ALB",
  },
  {
    key: 20,
    label: "5Paisa",
    value: "FPS",
  },
  {
    key: 21,
    label: "Groww",
    value: "GRW",
  },
  {
    key: 22,
    label: "Trustline",
    value: "TRT",
  },
  {
    key: 23,
    label: "Other",
    value: "OTH",
  },
];

export default class linkedDemat extends React.Component {
  constructor(props) {
    super(props);
    let username = null;
    let cust_id = null;
    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      username = params.username;
      cust_id = params.customer_id;
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      username,
      cust_id,
      user_id,
      showDematAccounts: false,
      showBrokerAccountDetails: false,
      isLoadingDemat: true,
      isLoadingBroker: true,
      redirecting: false,
      mainError: null,
      showLinkExistingDemat: false,
      depository_participant: null,
      ucc_code: null,
      demat_acc_num: null,
      depository_participant_error: null,
      ucc_code_error: null,
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("LinkedDemat", { path: global.location.href });
      } else {
        amplitude.logEvent("LinkedDemat", {
          path: this.props.navigation.getState().routeName,
        });
      }

      const { user_id } = this.state;
      const response = await getDematAccounts(user_id);
      let brokerAccountDetails = [];
      if (response && response.code == 100 && response.result.length) {
        const { result = [] } = response;
        brokerAccountDetails = [...result];
        this.setState({
          brokerAccountDetails,
          showBrokerAccountDetails: true,
          isLoadingDemat: false,
        });
      } else {
        this.setState({ isLoadingDemat: false });
      }
      const response1 = await getCustomerDematAccount(user_id);
      if (response1.length) {
        this.setState({
          dematAccountsData: response1,
          showDematAccounts: true,
          isLoadingBroker: false,
        });
      } else {
        this.setState({ isLoadingBroker: false });
      }
    } catch (error) {
      this.setState({ isLoadingBroker: false, isLoadingDemat: false });
    }
  };

  renderDematAccounts = ({ item }) => {
    const brokersList = {
      UPX: "Upstox",
      ZRD: "Zerodha",
      HDF: "HDFC Securities",
      ICI: "ICICI Direct",
      PRS: "Prostocks",
      KSC: "Kotak Securities",
      MOS: "Motilal Oswal Securities",
      SHK: "Sharekhan",
      GBC: "Globe capital",
      IFL: "IIFL Securities",
      JMF: "JM Financial Services",
      NJI: "NJ India Invest",
      SBI: "SBI Cap Securities",
      WAY: "Way2wealth",
      AXS: "Axis Direct",
      ANB: "Angel Broking",
      YES: "Yes Securities",
      EDL: "Edelweiss",
      ALB: "Alice Blue",
      FPS: "5Paisa",
      GRW: "Groww",
      TRT: "Trustline",
    };

    const brokerImagesList = {
      ZRD: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Combined-Shape.png",
      FPS: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/5Paisa+1.png",
      PRS: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/ProStocks+1.png",
      AXS: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Combined-Shape+(2).png",
      GBC: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Globe+Capital+1.png",
      ANB: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Group+22701.png",
      HDF: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/hdfc.png",
      YES: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/images+1.png",
      JMF: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/jm_financial+1.png",
      KSC: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/kotak.png",
      GRW: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Layer+2.png",
      EDL: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/logo-628x628+1.png",
      NJI: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/NJ+India+1.png",
      TRT: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Page-1.png",
      SHK: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/unnamed+1.png",
      UPX: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Vector+(7).png",
      WAY: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Way2Wealth+1.png",
      ICI: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/web.png",
      SBI: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/83-837808_sbi-logo-state-bank-of-india-group-png+1.png",
      MOS: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/motilal+1.png",
      IFL: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/iifl.png",
    };

    const brokerCode = item.stock_broker;

    return (
      item.ucc_id && (
        <View style={styles.accountBox1}>
          <View>
            <View style={styles.img_box}>
              <Image
                source={{ uri: brokerImagesList[brokerCode] }}
                style={styles.img}
              />
            </View>
          </View>
          <View style={{ paddingLeft: 15 }}>
            <View style={{ paddingBottom: 10 }}>
              <Text style={styles.brokerName}>{brokersList[brokerCode]}</Text>
            </View>
            <View style={styles.twotxt}>
              <Text style={[styles.timestamp, { paddingRight: 16 }]}>
                UCC Code:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {item.ucc_id ? item.ucc_id : "N/A"}
                </Text>
              </Text>
              <Text style={styles.timestamp}>
                Demat Account Number:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {item.clientid ? item.clientid : "N/A"}
                </Text>
              </Text>
            </View>
          </View>
        </View>
      )
    );
  };

  renderbrokerAccounts = ({ item }) => {
    return (
      <View style={styles.accountBox}>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.alin}>
            <Text style={styles.date}>
              Date: <Text style={styles.timestamp}>{item.timestamp}</Text>
            </Text>
            <Text style={styles.date}>
              Status:{" "}
              <Text
                style={[
                  styles.timestamp,
                  { color: item.status == "COMPLETED" ? "#009105" : "#FFA800" },
                ]}
              >
                {item.status}
              </Text>
            </Text>
          </View>
        </View>
        <Text style={styles.timestamp}>
          Contact broker for any additional queries
        </Text>
      </View>
    );
  };

  OpenNewDemat = async () => {
    try {
      this.setState({ redirecting: true });
      const response = await getHashKeyToOpenDematAccount();
      if (response && response.code == 100) {
        const { hash_key } = response.result;
        const url = await generateGatewayUrl(
          "app/gwsc_demat_opening",
          hash_key
        );
        if (Platform.OS == "web") {
          global.open(url, "_blank");
        } else {
          this.props.navigation.navigate("App", {
            screen: "Dashboard",
            params: {
              screen: "Portfolio",
              params: {
                screen: "ShowWebView",
                params: { url },
              },
            },
          });
        }
        this.setState({ redirecting: false });
      } else {
        this.setState({
          redirecting: false,
          mainError:
            "Something went wrong! Error while redirecting to gateway.",
        });
      }
    } catch (error) {
      this.setState({ redirecting: false, mainError: "Something went wrong!" });
    }
  };

  handleSubmit = async () => {
    try {
      let {
        cust_id,
        username,
        depository_participant,
        ucc_code,
        demat_acc_num,
      } = this.state;
      this.setState({ isLoading: true });
      if (!cust_id || !username) {
        const userData = getUserDetails();
        username = userData.username;
        cust_id = userData.customer_id;
      }

      if (!depository_participant || depository_participant == "select") {
        this.setState({
          depository_participant_error: "Please enter depository participant",
          isLoading: false,
        });
      }

      if (!ucc_code) {
        this.setState({ ucc_code_error: "Please enter your UCC code" });
        isLoading: false;
      }

      const data = {
        customer: cust_id,
        stock_broker: depository_participant,
        ucc_id: ucc_code,
        clientid: demat_acc_num,
      };
      if (
        depository_participant &&
        ucc_code &&
        depository_participant != "select"
      ) {
        this.setState({ isLoading: true });
        const response = await postCustomerDematAccount(data);
        if (response && response.customer) {
          this.setState({ isLoading: false });
          this.setState({ showLinkExistingDemat: false });
        } else {
          this.setState({
            isLoading: false,
            mainError: "Something went wrong!",
          });
        }
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      this.setState({ mainError: "Something went wrong!" });
    }
  };

  handlePickerChange = (field, value) => {
    this.setState({ depository_participant: value });
  };

  render() {
    const {
      dematAccountsData,
      showDematAccounts,
      showBrokerAccountDetails,
      brokerAccountDetails,
      isLoadingDemat,
      isLoadingBroker,
      redirecting,
      mainError,
      showLinkExistingDemat,
      depository_participant,
      ucc_code,
      depository_participant_error,
      ucc_code_error,
      isLoading,
    } = this.state;
    return !showLinkExistingDemat ? (
      <>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Demat Account"]}
          style={styles.headerStyle}
        />
        <ScrollView showsVerticalScrollIndicator={true}>
          <View style={styles.container}>
            <View style={styles.innerContainer}>
              {/* Open New Demat Account */}
              <View style={styles.firstHead}>
                <Text style={styles.linked}>New Demat Account</Text>

                {redirecting ? (
                  <ActivityIndicator
                    size="small"
                    color="#410DAA"
                    style={{ paddingRight: 15 }}
                  />
                ) : (
                  <View>
                    {mainError ? (
                      <Text style={styles.errtxt}>{mainError}</Text>
                    ) : (
                      // <TouchableOpacity style={styles.topBtn}>
                      //   <Text
                      //     style={[styles.link, { paddingHorizontal: 7 }]}
                      //     onPress={() => {
                      //       this.OpenNewDemat();
                      //     }}
                      //   >
                      //     Open New
                      //   </Text>
                      // </TouchableOpacity>
                      <TouchableOpacity
                        style={styles.topBtn}
                        onPress={() => {
                          this.OpenNewDemat();
                        }}
                      >
                        <Text style={styles.link}>Open New</Text>
                      </TouchableOpacity>
                    )}
                  </View>
                )}
              </View>

              {isLoadingBroker ? (
                <ActivityIndicator
                  size="large"
                  color="Black"
                  style={{ alignSelf: "center" }}
                />
              ) : (
                <View>
                  {showBrokerAccountDetails ? (
                    <View>
                      <FlatList
                        data={brokerAccountDetails}
                        renderItem={this.renderbrokerAccounts}
                      />
                    </View>
                  ) : (
                    <View style={styles.firstBox}>
                      <Text style={styles.noDemat}>
                        There are no new demat open requests under process
                      </Text>
                    </View>
                  )}
                </View>
              )}

              {/* Link Existing */}
              <View style={styles.firstHead}>
                <Text style={styles.linked}>Linked Demat Accounts</Text>
                <TouchableOpacity
                  style={styles.topBtn}
                  onPress={() => {
                    this.setState({ showLinkExistingDemat: true });
                  }}
                >
                  <Text style={styles.link}>Link Existing</Text>
                </TouchableOpacity>
              </View>
              {isLoadingDemat ? (
                <ActivityIndicator
                  size="large"
                  color="Black"
                  style={{ alignSelf: "center" }}
                />
              ) : (
                <View>
                  {showDematAccounts ? (
                    <View>
                      <FlatList
                        data={dematAccountsData}
                        renderItem={this.renderDematAccounts}
                      />
                    </View>
                  ) : (
                    <View style={styles.firstBox}>
                      <Text style={styles.noDemat}>
                        No Demat Account have been Linked
                      </Text>
                    </View>
                  )}
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      </>
    ) : (
      <>
        <View style={styles.container1}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Existing Demat Account"]}
            onPressBack={() => {
              this.setState({ showLinkExistingDemat: false });
            }}
            style={styles.headerStyle}
          />
          <View style={styles.innerContainer1}>
            <View style={styles.horiz}>
              <View>
                <Text style={styles.depository}>Depository Participant*</Text>
                <PickerFieldSelect
                  data={DepositoryParticipantsData}
                  value={depository_participant}
                  style={{}}
                  onChange={(itemValue) => {
                    this.handlePickerChange(
                      "depository_participant",
                      itemValue
                    );
                  }}
                  pickerStyle={[{}]}
                  type="select"
                  placeholder={{
                    label: "Select",
                    value: null,
                    color: "#9EA0A4",
                  }}
                />
                {/* <Picker
                  selectedValue={depository_participant}
                  style={styles.picker}
                  onValueChange={(itemValue) => {
                    this.handlePickerChange(
                      "depository_participant",
                      itemValue
                    );
                  }}
                >
                  {DepositoryParticipantsData.map((item, key) => (
                    <Picker.Item
                      key={key}
                      label={item.label}
                      value={item.value}
                      labelStyle={styles.pickerItem}
                    />
                  ))}
                </Picker> */}
                {(!depository_participant ||
                  depository_participant == "select") && (
                  <Text style={styles.please}>
                    {depository_participant_error}
                  </Text>
                )}
              </View>
              {!isMobile && (
                <View>
                  <Text style={styles.depository}>Demat UCC Code*</Text>
                  <TextInput
                    placeholder="Enter Demat UCC Code"
                    style={styles.inptxt}
                    onChangeText={(text) => {
                      this.setState({ ucc_code: text });
                    }}
                  />
                  {!ucc_code && (
                    <Text style={styles.please}>{ucc_code_error}</Text>
                  )}
                </View>
              )}
            </View>
            {isMobile && (
              <View>
                <Text style={styles.depository}>Demat UCC Code*</Text>
                <TextInput
                  placeholder="Enter Demat UCC Code"
                  style={[styles.inptxt, { marginBottom: 20 }]}
                  onChangeText={(text) => {
                    this.setState({ ucc_code: text });
                  }}
                />
                {!ucc_code && (
                  <Text style={styles.please}>{ucc_code_error}</Text>
                )}
              </View>
            )}
            <View>
              <Text style={styles.depository}>
                Demat Account Number (optional)
              </Text>
              <TextInput
                placeholder="Enter Demat Account Number"
                style={styles.inptxt}
                onChangeText={(text) => {
                  this.setState({ demat_acc_num: text });
                }}
              />
            </View>
            {mainError ? (
              <Text style={[styles.please, { textAlign: "center" }]}>
                {mainError}
              </Text>
            ) : (
              <View>
                {isLoading ? (
                  <ActivityIndicator
                    size="large"
                    color="Black"
                    style={{ alignSelf: "center" }}
                  />
                ) : (
                  <View style={styles.botbot}>
                    <TouchableOpacity
                      style={[styles.sub, { marginTop: 50 }]}
                      onPress={() => {
                        this.handleSubmit();
                      }}
                    >
                      <Text style={styles.submit}>Save {"&"} Link</Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            )}
          </View>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: "#ffffff",
  },
  headerStyle: { width: "100%", maxWidth: 1500 },
  innerContainer: {
    paddingHorizontal: 32,
    maxWidth: 900,
    paddingBottom: 40,
    flex: 1,
    backgroundColor: "#ffffff",
  },
  linked: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 21,
    color: "#2F3044",
  },
  link: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 12 : 15,
    lineHeight: 17,
    color: "#ffffff",
  },
  topBtn: {
    paddingHorizontal: 14,
    paddingVertical: 12,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  firstHead: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 25,
  },
  firstBox: {
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.6)",
    borderRadius: 4,
    paddingVertical: 30,
    paddingHorizontal: 10,
    alignItems: "center",
    marginVertical: 20,
  },
  noDemat: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#494B69",
  },
  accountBox: {
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.6)",
    borderRadius: 6,
    flexDirection: "column",
    paddingVertical: 15,
    paddingHorizontal: 10,
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginVertical: 10,
    paddingHorizontal: 30,
  },
  accountBox1: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.6)",
    borderRadius: 6,
    paddingVertical: 15,
    paddingHorizontal: 10,
    marginVertical: 10,
    paddingHorizontal: 30,
  },
  timestamp: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 11 : 13,
    lineHeight: 20,
    color: "#494B69",
  },
  date: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    fontWeight: "bold",
    color: "#494B69",
    paddingBottom: 7,
  },
  alin: {
    alignItems: "flex-start",
  },
  twotxt: {
    flexDirection: "column",
  },
  brokerName: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#22242C",
    fontWeight: "bold",
  },
  img: {
    width: 25,
    height: 25,
  },
  img_box: {
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.2)",
    borderRadius: 5,
    alignContent: "center",
    justifyContent: "center",
    paddingHorizontal: 7,
    paddingVertical: 7,
  },
  errtxt: {
    fontFamily: "Roboto",
    fontSize: 10,
    lineHeight: 12,
    color: "red",
    fontStyle: "italic",
  },
  container1: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  innerContainer1: {
    maxWidth: 800,
    paddingHorizontal: 35,
    paddingVertical: 12,
  },
  depository: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: 24,
    color: "#464E5F",
  },
  inptxt: {
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderRadius: 6,
    //outlineWidth: 0 0,
    paddingHorizontal: 16,
    paddingVertical: 9,
    width: 320,
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 24,
    color: "#464E5F",
    //placeholderTextColor: "rgba(70, 78, 95, 0.6)",
  },
  picker: {
    borderWidth: 1,
    borderColor: "#E2E8F0",
    paddingHorizontal: 16,
    paddingVertical: 9,
    borderRadius: 6,
    width: 320,
  },
  horiz: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 20,
  },
  submit: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
  },
  sub: {
    backgroundColor: "#410DAA",
    borderRadius: 4,
    paddingVertical: 11,
    paddingHorizontal: 66,
    marginTop: 30,
    alignItems: "center",
    justifyContent: "center",
  },
  botbot: {
    alignItems: "center",
    justifyContent: "center",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: 10,
    lineHeight: 12,
    color: "red",
    paddingTop: 5,
    fontStyle: "italic",
  },
  pickerItem: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#464E5F",
  },
});
