import React, { useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { isMobile, numberWithCommas } from "../../../../utils";
import { Switch } from "react-native-paper";

const ToggleSummaryView = (props) => {
  const { value, onValueChange } = props;
  const handleValueChange = () => {
    onValueChange();
  };
  return (
    <View style={toggleStyles.container}>
      {!isMobile && (
        <Text
          style={[
            toggleStyles.item,
            !value ? toggleStyles.active : toggleStyles.inActive,
            { fontSize: 14 },
          ]}
        >
          Since Inception{value}
        </Text>
      )}
      <Switch
        value={value}
        style={[StyleSheet.flatten([toggleStyles.switch])]}
        onValueChange={handleValueChange}
        color={"#410DAA"}
      />
      <Text
        style={[
          toggleStyles.item,
          value ? toggleStyles.active : toggleStyles.inActive,
          { fontSize: 14 },
        ]}
      >
        {isMobile
          ? value
            ? "Active Plans"
            : "Since Inception"
          : "Active Plans"}
        {value}
      </Text>
    </View>
  );
};

const SummaryTable = ({ data = {}, activeTab = "JEWEL" }) => {
  const { JWL = {}, SRK = {} } = data;

  let totalGainPerc;
  let totalGain;
  let xirrPerc;
  let currentValue;
  let originalInvested;
  let currentInvestment;
  let dividend;

  if (activeTab == "JEWEL") {
    currentValue = JWL.current_value;

    currentValue = currentValue ? parseInt(currentValue) : 0;
    currentValue = "₹ " + numberWithCommas(currentValue);

    dividend = JWL.dividend;

    dividend = dividend ? parseInt(dividend) : 0;
    dividend = "₹ " + numberWithCommas(dividend);

    originalInvested = JWL.current_investment;

    originalInvested = originalInvested ? parseInt(originalInvested) : 0;
    originalInvested = Math.abs(originalInvested);
    originalInvested = "₹ " + numberWithCommas(originalInvested);

    currentInvestment = JWL.cash_in_hand;

    currentInvestment = currentInvestment ? parseInt(currentInvestment) : 0;
    currentInvestment = "₹ " + numberWithCommas(currentInvestment);

    totalGainPerc = JWL.total_returns_perc;
    totalGainPerc = totalGainPerc ? Number(totalGainPerc.toFixed(2)) : 0;

    totalGain = JWL.total_returns_amount;
    totalGain = totalGain ? parseInt(totalGain) : 0;
    totalGain = "₹ " + numberWithCommas(totalGain);

    xirrPerc = JWL.product_xirr;
    xirrPerc = xirrPerc ? Number(xirrPerc.toFixed(2)) : 0;
  } else if (activeTab == "SPARK") {
    currentValue = SRK.current_value;

    currentValue = currentValue ? parseInt(currentValue) : 0;
    currentValue = "₹ " + numberWithCommas(currentValue);

    originalInvested = SRK.current_investment;

    originalInvested = originalInvested ? parseInt(originalInvested) : 0;
    originalInvested = Math.abs(originalInvested);
    originalInvested = "₹ " + numberWithCommas(originalInvested);

    currentInvestment = SRK.cash_in_hand;

    currentInvestment = currentInvestment ? parseInt(currentInvestment) : 0;
    currentInvestment = "₹ " + numberWithCommas(currentInvestment);

    totalGainPerc = SRK.total_returns_perc;
    totalGainPerc = totalGainPerc ? Number(totalGainPerc.toFixed(2)) : 0;

    totalGain = SRK.total_returns_amount;
    totalGain = totalGain ? parseInt(totalGain) : 0;
    totalGain = "₹ " + numberWithCommas(totalGain);

    xirrPerc = SRK.product_xirr;
    xirrPerc = xirrPerc ? Number(xirrPerc.toFixed(2)) : 0;
  }

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: isMobile ? 10 : 0,
      }}
    >
      <View style={{ flex: 1 }}>
        <View
          style={{ flexDirection: "column", paddingTop: isMobile ? 15 : 0 }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: "700",
              color: "#464E5F",
              alignSelf: "center",
            }}
          >
            {totalGainPerc.toFixed(1)}%
          </Text>
          <Text
            style={{
              paddingTop: 5,
              fontSize: 14,
              fontWeight: "400",
              color: "#878EA8",
              alignSelf: "center",
            }}
          >
            Gain/Loss %
          </Text>
        </View>
      </View>

      <View style={{ flex: 1 }}>
        <View
          style={{ flexDirection: "column", paddingTop: isMobile ? 15 : 0 }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: "700",
              color: "#464E5F",
              alignSelf: "center",
            }}
          >
            {totalGain}
          </Text>
          <Text
            style={{
              paddingTop: 5,
              fontSize: 14,
              fontWeight: "400",
              color: "#878EA8",
              alignSelf: "center",
            }}
          >
            Gain/Loss
          </Text>
        </View>
      </View>

      <View style={{ flex: 1 }}>
        <View
          style={{ flexDirection: "column", paddingTop: isMobile ? 15 : 0 }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: "700",
              color: "#464E5F",
              alignSelf: "center",
            }}
          >
            {xirrPerc.toFixed(1)}%
          </Text>
          <Text
            style={{
              paddingTop: 5,
              fontSize: 14,
              fontWeight: "400",
              color: "#878EA8",
              alignSelf: "center",
            }}
          >
            XIRR %
          </Text>
        </View>
      </View>
    </View>
  );
};

const toggleStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginTop: 10,
    marginRight: isMobile ? 10 : 30,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  item: {
    fontSize: isMobile ? 12 : 15,
    fontFamily: "Roboto",
  },
  active: {
    color: "#410DAA",
  },
  inActive: {
    color: "#6C7293",
  },
  switch: {
    marginLeft: isMobile ? 4 : 10,
    marginRight: isMobile ? 4 : 10,
    marginTop: isMobile ? -8 : 0,
  },
});

const PlanIndiCard = (props) => {
  const { onValueChange, activeTab } = props;
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [summaryData, setsummaryData] = useState(null);

  const onToggleSwitch = () => {
    setIsSwitchOn((isSwitchOn) => !isSwitchOn);
    onValueChange();
  };

  React.useEffect(() => {
    setPieChartData();
  }, [props.stocks_summary]);

  const setPieChartData = () => {
    let { stocks_summary = {} } = props;

    const { JWL = {}, SRK = {} } = stocks_summary;
    setsummaryData({
      JWL,
      SRK,
    });
  };

  let currentValue;
  let originalInvested;
  let currentInvestment;

  if (summaryData) {
    const { JWL = {}, SRK = {} } = summaryData;
    if (activeTab == "JEWEL") {
      currentValue = JWL.current_value;

      currentValue = currentValue ? parseInt(currentValue) : 0;
      currentValue = "₹ " + numberWithCommas(currentValue);

      originalInvested = JWL.current_investment;

      originalInvested = originalInvested ? parseInt(originalInvested) : 0;
      originalInvested = Math.abs(originalInvested);
      originalInvested = "₹ " + numberWithCommas(originalInvested);

      currentInvestment = JWL.cash_in_hand;

      currentInvestment = currentInvestment ? parseInt(currentInvestment) : 0;
      currentInvestment = "₹ " + numberWithCommas(currentInvestment);
    } else if (activeTab == "SPARK") {
      currentValue = SRK.current_value;

      currentValue = currentValue ? parseInt(currentValue) : 0;
      currentValue = "₹ " + numberWithCommas(currentValue);

      originalInvested = SRK.current_investment;

      originalInvested = originalInvested ? parseInt(originalInvested) : 0;
      originalInvested = Math.abs(originalInvested);
      originalInvested = "₹ " + numberWithCommas(originalInvested);

      currentInvestment = SRK.cash_in_hand;

      currentInvestment = currentInvestment ? parseInt(currentInvestment) : 0;
      currentInvestment = "₹ " + numberWithCommas(currentInvestment);
    }
  }

  return (
    <View>
      {summaryData && (
        <View
          style={{
            borderWidth: 0.5,
            borderColor: "#cef1e6",
            borderRadius: 20,
          }}
        >
          <View style={{ flexDirection: isMobile ? "column" : "row" }}>
            <View
              style={{
                color: "#494B69",
                fontSize: 16,
                backgroundColor: "#f3fbf9",
                borderRadius: 20,
                height: 190,
                alignSelf: "flex-start",
                width: isMobile ? "100%" : 310,
              }}
            >
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                  paddingHorizontal: 20,
                }}
              >
                Current Value
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 28,
                  fontWeight: "700",
                  color: "#464E5F",
                  paddingHorizontal: 20,
                }}
              >
                {currentValue}
              </Text>
              <View
                style={{
                  height: 0.5,
                  marginTop: 20,
                  width: "90%",
                  backgroundColor: "#92929250",
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              />
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 20,
                  paddingHorizontal: 20,
                }}
              >
                <View style={{ flexDirection: "column" }}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "700",
                      color: "#464E5F",
                    }}
                  >
                    {currentInvestment}
                  </Text>
                  <Text
                    style={{
                      paddingTop: 5,
                      fontSize: 14,
                      fontWeight: "400",
                      color: "#878EA8",
                      paddingRight: !isSwitchOn ? 0 : 33,
                    }}
                  >
                    {!isSwitchOn ? "Current Inv. Amount" : "Cash in Hand"}
                  </Text>
                </View>
                <View
                  style={{
                    width: 1,
                    height: "auto",
                    marginRight: 10,
                    marginLeft: 10,
                    backgroundColor: "#888C9F66",
                  }}
                />

                <View style={{ flexDirection: "column" }}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "700",
                      color: "#464E5F",
                      textAlign: "right",
                    }}
                  >
                    {originalInvested}
                  </Text>
                  <Text
                    style={{
                      paddingTop: 5,
                      fontSize: 14,
                      fontWeight: "400",
                      color: "#878EA8",
                      paddingLeft: !isSwitchOn ? 0 : 33,
                    }}
                  >
                    {!isSwitchOn ? "Original Inv. Amount" : "Invested Amount"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              {activeTab == "All" ? (
                <ToggleSummaryView
                  onValueChange={onToggleSwitch}
                  value={isSwitchOn}
                  color={"#410DAA"}
                />
              ) : null}
              <SummaryTable
                active={isSwitchOn}
                data={summaryData}
                activeTab={activeTab}
              />
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

export default PlanIndiCard;
