import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import { StackActions } from "@react-navigation/native";
import amplitude from "../../../utils/amplitude";
import Store from "../../../store";
import { insertKYC } from "../../../api";

export default class succesful_second extends React.Component {
  constructor(props) {
    super(props);
    const { params } = props.route;

    let showRedirection = true;
    if (params) {
      if (params.nvg) {
        showRedirection = params.nvg == "InvestmentOverview";
      }
    }
    this.state = {
      timer: 5,
      showRedirection,
      redirectTo: params && params.nvg ? params.nvg : "InvestmentOverview",
    };
  }

  componentDidMount = async() => {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("success_second",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("success_second", { path: this.props.navigation.getState().routeName });
      }
      await insertKYC({});
    } catch (error) {
    }
    this.interval = setInterval(
      () => this.setState((prevState) => ({ timer: prevState.timer - 1 })),
      1000
    );
  }

  componentDidUpdate() {
    if (this.state.timer === 0) {
      clearInterval(this.interval);
      const { actions = {} } = Store.getState().transactions;
      const { params = {} } = this.props.route;
      const { actionId = null } = params;
      if (actionId in actions) {
        const { stack = null, screen = null, ...rest } = actions[actionId];
        if (stack && screen) {
          this.props.navigation.dispatch(
            StackActions.replace("App", {
              screen: "Dashboard",
              params: {
                screen: stack,
                params: { screen: screen, params: { ...rest } },
              },
            })
          );
          return;
        }
      }
      if (this.state.showRedirection) {
        if (Platform.OS == "web") {
          global.location.replace("/portfolio", "_self");
        } else {
          this.props.navigation.dispatch(
            StackActions.replace("App", {
              screen: "Dashboard",
              params: { screen: "Dashboard", params: {} },
            })
          );
        }
      } else {
        this.props.navigation.dispatch(
          StackActions.replace("App", {
            screen: "Dashboard",
            params: {
              screen: "Portfolio",
              params: { screen: this.state.redirectTo },
            },
          })
        );
      }
    }
  }

  redirectToScreen = () => {
    if (this.state.showRedirection) {
        const { actions = {} } = Store.getState().transactions;
        const { params = {} } = this.props.route;
        const { actionId = null } = params;
        if (actionId in actions) {
          const { stack = null, screen = null, ...rest } = actions[actionId];
          if (stack && screen) {
            // console.log("sdf")
            this.props.navigation.dispatch(
              StackActions.replace("App", {
                screen: "ViewMode",
                params: {
                  screen: "InvestForGoals",
                  // params: { screen: screen, params: { ...rest } },
                },
              })
            );
            return;
          }
      }
    }
    this.props.navigation.dispatch(
      StackActions.replace("App", {
        screen: "Dashboard",
        params: {
          screen: "Portfolio",
          params: { screen: this.state.redirectTo },
        },
      })
    );
  };

  render() {
    const { timer, showRedirection } = this.state;
    return (
      <View style={styles.container}>
        <Text style={styles.you_hv}>
          You have Successfully completed the actions required for Compliance
        </Text>
        <Image
          source={{
            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Success+_Monochromatic+1.png",
          }}
          style={styles.img}
        />

        <Text style={styles.redirecting}>
          Redirecting {showRedirection ? "to further screen" : "back"} in{" "}
          <Text style={{ color: "lightblue", fontWeight: "bold" }}>
            {timer} sec
          </Text>
        </Text>

        <TouchableOpacity onPress={this.redirectToScreen}>
          <Text style={styles.continue}>
            Continue
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#ffffff",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 50,
  },
  you_hv: {
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: 29,
    fontWeight: "bold",
    color: "#000000",
    textAlign: "center",
    paddingTop: 50,
    paddingBottom: 65,
  },
  img: {
    width: 341,
    height: 257,
  },
  redirecting: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "rgba(18, 18, 18, 0.4)",
    paddingTop: 54,
  },
  continue: {
    marginTop: 30,
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    backgroundColor: "#410DAA",
    borderRadius: 4,
    paddingVertical: 16,
    paddingHorizontal: 22,
  },
});
