import React from "react";
import {
  View,
  Image,
  StatusBar,
  SafeAreaView,
  Platform,
  StyleSheet,
  ScrollView,
} from "react-native";
import { Button, HelperText, Text } from "react-native-paper";
import TextInputField from "../../../components/Common/Inputs/InputField";
import { calculateAgeFromDOB, getUserDetails, isMobile } from "../../../utils";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import {
  getRetirementBasicDetails,
  storeRetirementBasicDetails,
} from "../../../api";
import amplitude from "../../../utils/amplitude";

class AdvancedRetirementPlanner extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let goalId = null;
    if (params) {
      goalId = params.goalId || null;
    }
    this.state = {
      goalId,
      currentAge: "",
      selectedRetirementYear: "",
      retirementYearError: "",
      ageList: [],
      selectedLifeExpectancy: "",
      lifeExpectancyError: "",
    };
  }

  generateArrayOfYears = () => {
    let currentAge = new Date().getFullYear();
    let maxAge = currentAge + 80;
    let years = [];
    let index = 1;

    for (let i = currentAge; i <= maxAge; i++) {
      years.push({
        key: index,
        label: i.toString(),
        value: i,
      });
      index = index + 1;
    }
    return years;
  };

  generateLiftExpectancyArr = (min, max) => {
    let ages = [];
    for (let i = min; i <= max; i++) {
      const num = i;
      ages.push({
        label: `${num} years`,
        value: num,
      });
    }
    return ages;
  };

  calculateAge = (dob) => {
    var diffMonths = Date.now() - dob.getTime();
    var ageDate = new Date(diffMonths);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("AddPanCard",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("AddPanCard", { path: this.props.navigation.getState().routeName });
    }
    let resp = await getRetirementBasicDetails();
    let currentAge = (resp && resp.code === "100" && resp.result) ? resp.result.age : calculateAgeFromDOB(getUserDetails().date_of_birth);
    let selectedRetirementYear = (resp && resp.code === "100" && resp.result) ? resp.result.retirement_age: 50;
    let selectedLifeExpectancy = (resp && resp.code === "100" && resp.result) ? resp.result.life_expectancy: 70;
    let ageList = [];
    ageList = this.generateLiftExpectancyArr(currentAge, 100);
    // console.log(" currentAge ", currentAge);
    this.setState({
      ageList,
      currentAge,
      selectedRetirementYear,
      selectedLifeExpectancy,
    });
  }

  handleContinue = async () => {
    let { selectedRetirementYear, selectedLifeExpectancy } = this.state;
    if (selectedRetirementYear == "") {
      this.setState({ retirementYearError: "Please select retirement year." });
      return;
    }
    if (selectedLifeExpectancy == "") {
      this.setState({ lifeExpectancyError: "Please select life expectancy." });
      return;
    }
    const payload = {
      date_of_birth: getUserDetails().date_of_birth,
      retirement_age: selectedRetirementYear,
      life_expectancy: selectedLifeExpectancy,
    };
    const response = await storeRetirementBasicDetails(payload);
    if (response && response.code && response.code == 100) {
      this.props.navigation.push("RetirementCorpus", {
        goalId: this.state.goalId,
      });
    }
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Advance Retirement"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          style={styles.cardContainer}
          showsVerticalScrollIndicator={false}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                fontSize: 22,
                color: "#464E5F",
                fontWeight: "700",
                marginVertical: 10,
                marginLeft: 40,
              }}
            >
              Retirement Goal
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{ flexDirection: "column", marginRight: 50, width: "45%" }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 20,
                  marginLeft: 40,
                  width: "70%",
                }}
              >
                <View style={[{ flex: 1 }, styles.itemMargin]}>
                  <TextInputField
                    label="Current age*"
                    value={this.state.currentAge}
                    inputStyle={[{ paddingLeft: 20 }]}
                    editable={false}
                  />
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 30,
                  marginLeft: 40,
                  width: "70%",
                }}
              >
                <View style={[{ flex: 1 }, styles.itemMargin]}>
                  <PickerFieldSelect
                    data={this.state.ageList}
                    value={this.state.selectedRetirementYear}
                    label="When do you wish to retire (select age)?*"
                    onChange={(text) => {
                      this.setState({
                        selectedRetirementYear: text,
                        retirementYearError: "",
                      });
                    }}
                    type="select"
                  />
                  <HelperText
                    type="error"
                    visible={this.state.retirementYearError != ""}
                  >
                    {this.state.retirementYearError}
                  </HelperText>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 30,
                  marginLeft: 40,
                  width: "70%",
                }}
              >
                <View style={[{ flex: 1 }, styles.itemMargin]}>
                  <PickerFieldSelect
                    data={this.state.ageList}
                    value={this.state.selectedLifeExpectancy}
                    label="Life expectancy assumed (select age)?*"
                    onChange={(text) => {
                      this.setState({
                        selectedLifeExpectancy: text,
                        lifeExpectancyError: "",
                      });
                    }}
                    type="select"
                  />
                  <HelperText
                    type="error"
                    visible={this.state.lifeExpectancyError != ""}
                  >
                    {this.state.lifeExpectancyError}
                  </HelperText>
                </View>
              </View>
              <Button
                uppercase={false}
                mode={"contained"}
                onPress={this.handleContinue}
                style={styles.nextButton}
                color={"#410DAA"}
              >
                <Text style={styles.next}>Next</Text>
              </Button>
            </View>
            <Image
              source={{
                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/retirement_finplan_image.png",
              }}
              style={{ width: 300, height: 300, marginTop: 20 }}
              resizeMode="contain"
            />
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  itemMargin: {
    paddingHorizontal: isMobile ? 16 : 0,
  },
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  nextButton: {
    width: 120,
    marginLeft: "23%",
    marginTop: 50,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
});

export default AdvancedRetirementPlanner;
