import React from "react";
import { View, Image, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";
import EvilIcon from "@expo/vector-icons/EvilIcons";
import { isMobile } from "../../utils";
import Divider from "./divider";
import { SideHeading } from "./sideHeading";

export const CardStripNavigation = ({
  displayData,
  title,
  description,
  style,
  onPress,
  heading,
  navigatable = true,
  image,
  clickableContainerStyle
}) => {
  return (
    <View style={{ ...style }}>
      {heading && (
        <SideHeading
          title={heading}
          style={{ fontSize: 20, alignItems: "center" }}
          headingTitleContainerStyle={{
              marginLeft: 9
          }}
        />
      )}
      <TouchableOpacity
        style={[
          {
            marginHorizontal: 16,
            marginVertical: 5,
            paddingVertical: 15,
            borderWidth: 0.5,
            borderColor: "#410DAA33",
            borderRadius: 10
          },
          clickableContainerStyle ? { ...clickableContainerStyle } : {}
        ]}
        onPress={() => {
          onPress && onPress();
        }}
      >
        {displayData}
        {displayData && (
          <Divider
            color="#410DAA33"
            width={0.5}
            style={{ marginVertical: 5 }}
          />
        )}
        <View style={{ flexDirection: "row", paddingHorizontal: 10 }}>
          {image && (
              <Image
                style={{
                  width: isMobile  ? 16 : 30,
                  height: isMobile  ? 16 : 30,
                  alignSelf: "flex-start",
                  marginTop: 6,
                  marginRight: 20
                }}
                resizeMode="contain"
                source={image}
              />
          )}
          <View style={{ flex: 0.8 }}>
            <Text
              style={{
                fontSize: 13,
                color: "#121212",
                fontWeight: "bold",
                marginBottom: 10,
                marginTop: 4
              }}
            >
              {title}
            </Text>
            <Text style={{ fontSize: 12, color: "#707070" }}>
              {description}
            </Text>
          </View>
          <View
            style={{
              flex: 0.2,
              alignItems: "flex-end",
              justifyContent: "center"
            }}
          >
            {navigatable && (
              <EvilIcon
                // type="AntDesign"
                name="arrow-right"
                style={{ fontSize: 35, color: "#410DAA" }}
              />
            )}
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};
