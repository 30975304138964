import React from "react";
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  StatusBar,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
} from "react-native";
import {
  capitalizeFirstLetter,
  getUserDetails,
  isMobile,
  numberWithCommas,
} from "../../../utils";
import { RadioButton } from "react-native-paper";
import Checkbox from "expo-checkbox";
import GoalsTable from "./GoalsTable";
import SearchBar from "../../../components/Common/SearchBar";
import moment from "moment";
import { getAdvisorDashboardDetials } from "../../../api/goals";
import {
  createMandate,
  createOrderWithFirstInstallmentV2,
  getCustomerBankAccounts,
  getCustomerDetails,
  getCustomerReport,
  getSipMandate,
  getUrlForClient2FA,
} from "../../../api";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { Button } from "react-native-paper";
import Modal from "../../../components/Common/Modal";
import amplitude from "../../../utils/amplitude";
import { AntDesign } from "@expo/vector-icons";
import TextInputField from "../../../components/Common/Inputs/InputField";
import CommaTextField from "./commaTextField";
import { ShowToast } from "../../../components/Common/Toast";
import { SuggestedFundsCard } from "./suggestedFundsCard";
import { StackActions } from "@react-navigation/native";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import Toast from "react-native-toast-message";
import PlacePaymentAndOTP from "../../../../src/components/Common/PlacePaymentAndOTP";
import Store from "../../../store";
export default class SuggestedFunds extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    let goalList = null;
    const { params } = this.props.route;
    if (params) {
      userId = params.user_id || null;
      goalList = params.goalList || null;
    }

    let totalSum = 0;
    let totalFunds = [];
    let mf_recommend_set_no = null;
    goalList.forEach((item) => {
      item.schemeDetails.forEach((item2) => {
        totalFunds.push(item2);
        if (item2.sip_amount_for_goal) {
          totalSum = totalSum + item2.sip_amount_for_goal;
        }
        if (item2.lumpsum_amount_for_goal) {
          totalSum = totalSum + item2.lumpsum_amount_for_goal;
        }
      });
    });
    let uniqueFund = [];
    goalList.forEach((item) => {
      item.schemeDetails.forEach((item2) => {
        if (uniqueFund.length) {
          if (
            uniqueFund.find(
              (value) => value.amcfundscheme_id == item2.amcfundscheme_id
            )
          ) {
          } else {
            uniqueFund.push(item2);
          }
        } else {
          uniqueFund.push(item2);
        }
      });
    });
    goalList.forEach((item) => {
      item.schemeDetails.forEach((detail) => {
        detail.sip_start_date = null;
        let default_sip_dates =
          "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28";
        let arr = null;
        if (detail.bse_sip_monthly_dates) {
          arr = detail.bse_sip_monthly_dates.split(",");
        } else {
          arr = default_sip_dates.split(",");
          detail.bse_sip_monthly_dates = default_sip_dates;
        }
        let currDate = moment().format("DD");
        arr.forEach((item) => {
          if (parseInt(item) >= parseInt(currDate)) {
            if (!detail.sip_start_date) {
              detail.sip_start_date = moment().format("YYYY-MM-") + item;
            }
          }
        });
      });
    });
    goalList.forEach((item) => {
      item.schemeDetails.forEach((detail) => {
        mf_recommend_set_no = detail.reco_set_no;
      });
    });
    goalList.forEach((item) => {
      item.schemeDetails.forEach((detail) => {
        if (detail.folio_list.length) {
          detail.selectedFolio = detail.folio_list[0].bse_folio_no;
          detail.selectedFolioValue = detail.folio_list[0].iaffolio_pk;
        } else {
          detail.selectedFolio = "newfolio";
          detail.selectedFolioValue = "newfolio";
        }
      });
    });
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      userId,
      goalList,
      errorMsg: false,
      key: "",
      uniqueFund,
      showPayment: false,
      totalFunds,
      mf_recommend_set_no,
      totalSum: parseInt(totalSum),
      isSelected: false,
      sorting_field: null,
      sorting_value: null,
      searchResults: "",
      sorting_field: null,
      sorting_value: null,
      selectedCheckboxs: "",
      userName: "",
      isAllCheckboxSelected: false,
      json: "",
      showModal: false,
      goalAmount: "",
      goalYear: "",
      goalMonth: "",
      lumpsumArr: [],
      lumpsumArrToShow: [],
      sipArr: [],
      sipArrToShow: [],
      projectedArrToShow: [],
      showToast: "false",
      toastText: "",
      actionId: moment().unix(),
      isLoading: false,
      mandateList: [],
      showModal: false,
      options: [],
      selectedAccount: "",
      mandateAmount: "",
      mandateType: "",
      isMandateLoading: false,
      existingSipResp: [],
      isLoading2: true,
      isLoading3: false,
      isLoading4: false,
      btnText: "Open OTP Link",
      purchase_2FA_url: null,
      purchase_url: null,
      total_count: 0,
      recd_count: 0,
    };
  }

  createNewMandate = async () => {
    try {
      this.setState({ isMandateLoading: true });
      const { selectedAccount, mandateAmount, mandateType, options } =
        this.state;
      if (!selectedAccount || selectedAccount == "0") {
        this.setState({
          showToast: "true",
          toastText: "Please select a bank account",
          isMandateLoading: false,
        });
        return false;
      }
      if (!mandateAmount || mandateAmount == "" || mandateAmount == 0) {
        this.setState({
          showToast: "true",
          toastText: "Please enter a valid amount",
          isMandateLoading: false,
        });
        return false;
      }
      if (mandateAmount > 100000) {
        this.setState({
          showToast: "true",
          toastText: "Maximum mandate amount is ₹1,00,000",
          isMandateLoading: false,
        });
        return false;
      }
      if (mandateType == "") {
        this.setState({
          showToast: "true",
          toastText: "Please select mandate type",
          isMandateLoading: false,
        });
        return false;
      }
      const selectedBankAccountDetails = options.find(
        (account) => account.value == selectedAccount
      );
      let payload = {
        amount: mandateAmount,
        bank_account_no: selectedBankAccountDetails.account.account_number,
        micr_code: selectedBankAccountDetails.account.micr_code
          ? selectedBankAccountDetails.account.micr_code
          : "",
        mandate_type: mandateType,
      };
      const createMandateResp = await createMandate(payload);
      if (
        createMandateResp &&
        createMandateResp.code &&
        createMandateResp.code == "100"
      ) {
        const response = await getSipMandate({
          amount: "1",
          day_of_month: moment().format("DD"),
          is_aadhar_linked_to_mobile: "not_required",
        });
        if (response && response.code && response.code == "100") {
          this.setState({ mandateList: response.mandates });
        }
        this.setState({
          showToast: "true",
          toastText: "Mandate created successfully",
          showModal: false,
          isMandateLoading: false,
        });
      } else {
        this.setState({
          showToast: "true",
          toastText: "Mandate creation failed, please try again",
          isMandateLoading: false,
        });
      }
    } catch (e) {
      this.setState({
        showToast: "true",
        toastText: "Mandate creation failed, please try again",
        isMandateLoading: false,
      });
    }
  };

  componentDidMount = async () => {
    if (Platform.OS == "web") {
      amplitude.logEvent("suggestedFunds", { path: global.location.href });
    } else {
      amplitude.logEvent("suggestedFunds", {
        path: this.props.navigation.getState().routeName,
      });
    }
    let { customer_id } = await getUserDetails();
    const payload = {
      previous_key: "activemandates",
      rm_cust_id: customer_id,
    };
    let resp = await getCustomerReport(payload);
    if (resp && resp.code && resp.code == "100") {
      this.setState({ existingSipResp: resp.result });
    }
    const response = await getSipMandate({
      amount: "1",
      day_of_month: moment().format("DD"),
      is_aadhar_linked_to_mobile: "not_required",
    });
    if (response && response.code && response.code == "100") {
      this.setState({ mandateList: response.mandates });
    }

    let options = [];
    const customerBankAccounts = await getCustomerBankAccounts();
    if (customerBankAccounts && customerBankAccounts.results) {
      customerBankAccounts.results.forEach((account) => {
        if (account.account_number) {
          options.push({
            label: account.bank_name + " - " + account.account_number,
            value: account.account_number,
            account: account,
          });
        }
      });
      this.setState({
        options,
        selectedAccount: options[0].value,
      });
    }

    let json = [];
    json.goals = this.state.goalList;
    let selectedCheckboxs = [];
    let goalArr = [];
    let emergencyGoalsArr = [];
    let retirementGoalsArr = [];
    let otherGoalsArr = [];
    emergencyGoalsArr = json.goals.filter((goal) =>
      goal.name.includes("Emergency")
    );
    retirementGoalsArr = json.goals.filter((goal) =>
      goal.name.includes("Retire")
    );
    otherGoalsArr = json.goals.filter(
      (goal) =>
        !(goal.name.includes("Emergency") || goal.name.includes("Retire"))
    );
    let sortedOtherArr = otherGoalsArr.sort((a, b) =>
      this.compareNumbers(a.target_year, b.target_year)
    );

    goalArr = [...emergencyGoalsArr, ...retirementGoalsArr, ...sortedOtherArr];
    let deficitGoalsArr = goalArr.filter(
      (goal) => goal.target_amount_initially_calculated > goal.projected_value
    );
    let surplusGoalsArr = goalArr.filter(
      (goal) =>
        !(goal.target_amount_initially_calculated > goal.projected_value)
    );
    goalArr = [...deficitGoalsArr, ...surplusGoalsArr];

    json.goals = goalArr;
    json.goals.forEach((item) => {
      let isDeficit =
        item.target_amount_initially_calculated > item.projected_value;
      let deficitOrSurplus = isDeficit
        ? Math.round(
          item.target_amount_initially_calculated - item.projected_value
        )
        : "Surplus";
      selectedCheckboxs.push({ ...item, toShow: false, deficitOrSurplus });
    });
    let userData = {};
    if (this.state.userId) {
      userData = await getCustomerDetails(this.state.userId);
    } else {
      userData = await getUserDetails();
    }
    this.setState({ userData });
    let { first_name } = userData;
    let names = first_name ? first_name.split(/\s+/) : [];
    let name = "";
    if (names.length >= 2 && names.length > 0) {
      name =
        capitalizeFirstLetter(names[0]) + " " + capitalizeFirstLetter(names[1]);
    } else {
      if (names.length == 0) {
        name = "User";
      } else {
        name = names[0];
      }
    }
    selectedCheckboxs.forEach((value, index) => {
      let currentDate = moment();
      let targetDate = moment(value.target_date_text);
      let years = targetDate.diff(currentDate, "years");
      let lumpsumFutureValue =
        value.achieved_value * Math.pow((1 + 0.05) / (1 + 0), years);
      let requiredAmount =
        value.target_amount_initially_calculated - lumpsumFutureValue;
      let lumpsumArr = this.state.lumpsumArr;
      lumpsumArr[index] = parseInt(requiredAmount);

      let sipFutureValue =
        value.target_amount_initially_calculated -
        value.achieved_value * Math.pow((1 + 0.05) / (1 + 0), years);
      let monthlyInterestRate = 0.05 / 12;
      let numberOfPayments = years * 12;
      let monthlyPayment =
        (sipFutureValue * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

      if (!isFinite(monthlyPayment)) {
        monthlyPayment = 0;
      }
      let sipArr = this.state.sipArr;
      sipArr[index] = parseInt(monthlyPayment);
    });

    this.setState({
      selectedCheckboxs,
      json: selectedCheckboxs,
      searchResults: selectedCheckboxs,
      userName: name,
    });
  };

  async checkOTPStatus() {
    try {
      setTimeout(async () => {
        const payload = {
          previous_key: "purchase_2FA_check",
          param: this.state.mf_recommend_set_no,
          customer_id: this.state.userData.customer_id,
        };
        let total_count = 0;
        let recd_count = 0;
        let resp = await getCustomerReport(payload);
        if (resp && resp.code && resp.code == "100") {
          total_count = resp.result[0].total_count;
          recd_count = resp.result[0].recd_count;
          if (recd_count > 0) {
            this.setState({
              isLoading2: false,
              isLoading3: false,
              isLoading4: true,
              loaderText:
                total_count > recd_count
                  ? `Total Orders: ${total_count}, Approved Orders: ${recd_count}`
                  : "Congratulations! Orders approved via OTP!",
              btnText: "BSE Payment Link",
              purchase_2FA_url: this.state.purchase_2FA_url,
              purchase_url: this.state.purchase_url,
              total_count,
              recd_count,
            });
            this.checkOTPStatus();
          } else {
            this.checkOTPStatus();
          }
        }
      }, 5000);
    } catch (e) { }
  }

  handleSearch = (searchText) => {
    let searchResults = [];
    let searchData = this.state.json;
    if (searchText) {
      searchResults = searchData.filter((item) => {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
      });
    } else {
      searchResults = searchData;
    }

    if (searchResults.length) {
      this.setState({
        searchText,
        searchResults,
        selectedCheckboxs: searchResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  numberWithSymbols = (number, noSymbol = false) => {
    let UpdatedNumber = number;
    if (UpdatedNumber >= 10000000) {
      UpdatedNumber = (UpdatedNumber / 10000000).toFixed(1) + " Cr";
    }
    if (UpdatedNumber >= 100000) {
      UpdatedNumber = (UpdatedNumber / 100000).toFixed(1) + " L";
    }
    if (UpdatedNumber >= 1000) {
      UpdatedNumber = (UpdatedNumber / 1000).toFixed(1) + " K";
    }
    if (UpdatedNumber < 1000) {
      UpdatedNumber = parseInt(UpdatedNumber).toFixed(1);
    }
    return noSymbol ? UpdatedNumber : "₹ " + UpdatedNumber;
  };

  compareStrings = (a, b) => {
    if (a == "Surplus") {
      a = 0;
    }
    if (b == "Surplus") {
      b = 0;
    }
    const x = a.toLowerCase();
    const y = b.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  compareNumbers = (a, b) => {
    return a - b;
  };

  getSortedData = (data, sorting_field, sorting_value, start, end) => {
    if (data.length != 0) {
      let fieldValue = data[0][sorting_field];
      if (fieldValue == "Surplus") {
        fieldValue = 0;
      }
      const sortingType = isNaN(fieldValue) ? "string" : "number";
      data = data.slice(start, end);
      if (sortingType == "string") {
        data.sort((a, b) =>
          this.compareStrings(a[sorting_field], b[sorting_field])
        );
      } else {
        data.sort((a, b) =>
          this.compareNumbers(a[sorting_field], b[sorting_field])
        );
      }
      return sorting_value ? data : data.reverse();
    } else {
      return data;
    }
  };

  handleSort = (field, sort) => {
    const sorting_field = field;
    const sorting_value = !sort;
    const { searchResults } = this.state;
    const sortedData = this.getSortedData(
      searchResults,
      sorting_field,
      sorting_value
    );

    this.setState({
      sorting_field,
      sorting_value,
      searchResults: sortedData,
    });
  };

  calculateProjectedAmt = (
    lumpsum,
    SIP,
    growthRate,
    inflationRate,
    years,
    index
  ) => {
    let lumpsumVal = lumpsum * Math.pow(1 + growthRate - inflationRate, years);
    let sipValue =
      SIP *
      (((Math.pow(1 + growthRate - inflationRate, years) - 1) /
        (growthRate - inflationRate)) *
        (1 + growthRate));
    if (isNaN(lumpsumVal)) {
      lumpsumVal = 0;
    }
    if (isNaN(sipValue)) {
      sipValue = 0;
    }
    let projectedAmount = lumpsumVal + sipValue;
    let projectedArrToShow = this.state.projectedArrToShow;
    projectedArrToShow[index] = projectedAmount;
    this.setState({ projectedArrToShow });
  };

  getHeaderTableData = (data) => {
    const { sorting_field, sorting_value } = this.state;
    let headerData = [];
    let values = [];

    let tempHeaderData = [
      {
        component: true,
        label: (
          <View style={{ marginLeft: -20 }}>
            <Checkbox
              status={
                this.state.isAllCheckboxSelected ? "checked" : "unchecked"
              }
              color={this.state.isAllCheckboxSelected ? "#410DAA" : "gray"}
              value={this.state.isAllCheckboxSelected}
              onValueChange={() => {
                let checkbox = this.state.selectedCheckboxs;
                if (this.state.isAllCheckboxSelected) {
                  checkbox.forEach((item) => {
                    item.toShow = false;
                  });
                } else {
                  checkbox.forEach((item) => {
                    item.toShow = true;
                  });
                }
                this.setState({ selectedCheckboxs: checkbox });
                this.setState({
                  isAllCheckboxSelected: !this.state.isAllCheckboxSelected,
                });
              }}
              style={{
                width: isMobile ? 13 : 14,
                height: isMobile ? 13 : 14,
                color: this.state.selectedCheckboxs ? "#410DAA" : "gray",
              }}
            />
          </View>
        ),
      },
      { label: "Goal Name", value: "name" },
      { label: "Due Date", value: "target_year" },
      { label: "Amount When Due", value: "target_amount_initially_calculated" },
      { label: "Achieved", value: "achieved_value" },
      { label: "Projected", value: "projected_value" },
      { label: `Deficit On Due Date`, value: "deficitOrSurplus" },
      { label: `Lumpsum`, value: "lumpsum" },
      { label: `Monthly SIP`, value: "sip" },
      { label: `Projected`, value: "projected" },
    ];

    tempHeaderData.forEach((headerItem, index) => {
      headerData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption:
          index == 0 || index == 7 || index == 8 || index == 9 ? false : true,
        onSort: this.handleSort,
        key: headerItem.value,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });
    data &&
      data.forEach((value, index) => {
        let currentDate = moment();
        let targetDate = moment(value.target_date_text);
        let years = targetDate.diff(currentDate, "years");
        let data_value = [
          {
            component: true,
            value: (
              <Checkbox
                status={
                  this.state.selectedCheckboxs[index].toShow
                    ? "checked"
                    : "unchecked"
                }
                color={
                  this.state.selectedCheckboxs[index].toShow
                    ? "#410DAA"
                    : "gray"
                }
                value={this.state.selectedCheckboxs[index].toShow}
                onValueChange={() => {
                  let checkbox = this.state.selectedCheckboxs;
                  if (checkbox[index]) {
                    this.setState({ isAllCheckboxSelected: false });
                  }

                  checkbox[index].toShow = !checkbox[index].toShow;
                  this.setState({ selectedCheckboxs: checkbox });
                }}
              />
            ),
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.name,
            value: value.name || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.target_date_text,
            value: value.target_date_text || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: this.numberWithSymbols(
              value.target_amount_initially_calculated
            ),
            value: value.target_amount_initially_calculated || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: this.numberWithSymbols(
              value.achieved_value.toFixed(1)
            ),
            value: value.achieved_value_text || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: this.numberWithSymbols(
              value.projected_value.toFixed(1)
            ),
            value: value.projected_value_text || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay:
              value.deficitOrSurplus == "Surplus"
                ? "Surplus"
                : this.numberWithSymbols(value.deficitOrSurplus),
            value: value.deficitOrSurplus || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            component: true,
            value:
              value.deficitOrSurplus == "Surplus" ? (
                <Text>N.A.</Text>
              ) : (
                <>
                  {this.state.lumpsumArr[index] >= 0 ? (
                    <>
                      <CommaTextField
                        value={
                          this.state.lumpsumArrToShow[index]
                            ? this.state.lumpsumArrToShow[index]
                            : ""
                        }
                        onValueChange={(text) => {
                          if (!isNaN(text)) {
                            let lumpsumArrToShow = this.state.lumpsumArrToShow;
                            lumpsumArrToShow[index] = text;
                            let sipFutureValue =
                              value.target_amount_initially_calculated -
                              (value.achieved_value + text) *
                              Math.pow((1 + 0.05) / (1 + 0), years);
                            let monthlyInterestRate = 0.05 / 12;
                            let numberOfPayments = years * 12;
                            let monthlyPayment =
                              (sipFutureValue * monthlyInterestRate) /
                              (1 -
                                Math.pow(
                                  1 + monthlyInterestRate,
                                  -numberOfPayments
                                ));

                            if (!isFinite(monthlyPayment)) {
                              monthlyPayment = 0;
                            }
                            let sipArr = this.state.sipArr;
                            let sipArrToShow = this.state.sipArrToShow;
                            sipArr[index] = parseInt(monthlyPayment);
                            if (monthlyPayment < 0) {
                              sipArrToShow[index] = "";
                            }
                            this.calculateProjectedAmt(
                              lumpsumArrToShow[index],
                              this.state.sipArrToShow[index],
                              0.11,
                              0,
                              years,
                              index
                            );
                            let checkbox = this.state.selectedCheckboxs;
                            if (text !== "") {
                              checkbox[index].toShow = true;
                            } else {
                              checkbox[index].toShow = false;
                            }
                            this.setState({
                              lumpsumArrToShow,
                              selectedCheckboxs: checkbox,
                            });
                          }
                        }}
                        containerStyle={{
                          width: "90%",
                          marginLeft: "5%",
                          marginRight: "5%",
                        }}
                        textStyle={{
                          fontSize: 14,
                          paddingVertical: 10,
                          paddingHorizontal: 5,
                        }}
                      />
                      <TouchableOpacity
                        style={{
                          width: "80%",
                          marginLeft: "10%",
                          marginTop: "9%",
                          height: 40,
                        }}
                        onPress={() => {
                          this.setState(
                            {
                              showToast: "false",
                            },
                            () => {
                              let lumpsumArrToShow =
                                this.state.lumpsumArrToShow;
                              lumpsumArrToShow[index] =
                                this.state.lumpsumArr[index];
                              let sipFutureValue =
                                value.target_amount_initially_calculated -
                                (value.achieved_value +
                                  this.state.lumpsumArr[index]) *
                                Math.pow((1 + 0.05) / (1 + 0), years);
                              let monthlyInterestRate = 0.05 / 12;
                              let numberOfPayments = years * 12;
                              let monthlyPayment =
                                (sipFutureValue * monthlyInterestRate) /
                                (1 -
                                  Math.pow(
                                    1 + monthlyInterestRate,
                                    -numberOfPayments
                                  ));

                              if (!isFinite(monthlyPayment)) {
                                monthlyPayment = 0;
                              }
                              let sipArr = this.state.sipArr;
                              let sipArrToShow = this.state.sipArrToShow;
                              sipArr[index] = parseInt(monthlyPayment);
                              if (monthlyPayment < 0) {
                                sipArrToShow[index] = "";
                              }
                              this.calculateProjectedAmt(
                                lumpsumArrToShow[index],
                                this.state.sipArrToShow[index],
                                0.11,
                                0,
                                years,
                                index
                              );
                              let checkbox = this.state.selectedCheckboxs;
                              checkbox[index].toShow = true;
                              this.setState({
                                lumpsumArrToShow,
                                selectedCheckboxs: checkbox,
                                showToast: "true",
                                toastText: `Lumpsum Applied: ₹ ${numberWithCommas(
                                  lumpsumArrToShow[index]
                                )}, Revised Projection: ${revisedProjectionArr[index]
                                  }%`,
                              });
                            }
                          );
                        }}
                      >
                        <Text
                          style={{
                            color: "#121212",
                            cursor: "pointer",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          Suggested{" "}
                          {this.numberWithSymbols(this.state.lumpsumArr[index])}{" "}
                          lumpsum based on goal deficit
                        </Text>
                        <Text
                          style={{
                            color: "#410DAA",
                            cursor: "pointer",
                            fontSize: 14,
                            textAlign: "center",
                            textDecorationLine: "underline",
                            marginTop: 3,
                          }}
                        >
                          Apply
                        </Text>
                      </TouchableOpacity>
                    </>
                  ) : (
                    <View style={{ width: "80%", marginLeft: "10%" }}>
                      <Text
                        style={{
                          color: "#121212",
                          cursor: "pointer",
                          fontSize: 13,
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Goal target reached, no Lumpsum investment required.
                      </Text>
                    </View>
                  )}
                </>
              ),
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            component: true,
            value:
              value.deficitOrSurplus == "Surplus" ? (
                <Text>N.A.</Text>
              ) : (
                <>
                  {this.state.sipArr[index] >= 0 ? (
                    <>
                      <CommaTextField
                        value={
                          this.state.sipArrToShow[index]
                            ? this.state.sipArrToShow[index]
                            : ""
                        }
                        onValueChange={(text) => {
                          if (!isNaN(text)) {
                            let sipArrToShow = this.state.sipArrToShow;
                            sipArrToShow[index] = text;

                            this.calculateProjectedAmt(
                              this.state.lumpsumArrToShow[index],
                              sipArrToShow[index],
                              0.11,
                              0,
                              years,
                              index
                            );
                            let lumpsumFutureValue =
                              value.achieved_value *
                              Math.pow((1 + 0.05) / (1 + 0), years);
                            let requiredAmount =
                              value.target_amount_initially_calculated -
                              this.state.projectedArrToShow[index] -
                              lumpsumFutureValue;
                            let lumpsumArr = this.state.lumpsumArr;
                            let lumpsumArrToShow = this.state.lumpsumArrToShow;
                            lumpsumArr[index] = parseInt(requiredAmount);
                            if (requiredAmount < 0) {
                              lumpsumArrToShow[index] = "";
                            }
                            let checkbox = this.state.selectedCheckboxs;
                            if (text !== "") {
                              checkbox[index].toShow = true;
                            } else {
                              checkbox[index].toShow = false;
                            }
                            this.setState({
                              sipArrToShow,
                              selectedCheckboxs: checkbox,
                            });
                          }
                        }}
                        containerStyle={{
                          width: "90%",
                          marginLeft: "5%",
                          marginRight: "5%",
                        }}
                        textStyle={{
                          fontSize: 14,
                          paddingVertical: 10,
                          paddingHorizontal: 5,
                        }}
                      />
                      <TouchableOpacity
                        style={{
                          width: "80%",
                          marginLeft: "10%",
                          marginTop: "9%",
                          height: 40,
                        }}
                        onPress={() => {
                          this.setState(
                            {
                              showToast: "false",
                            },
                            () => {
                              let sipArrToShow = this.state.sipArrToShow;
                              sipArrToShow[index] = this.state.sipArr[index];
                              this.calculateProjectedAmt(
                                this.state.lumpsumArrToShow[index],
                                sipArrToShow[index],
                                0.11,
                                0,
                                years,
                                index
                              );
                              let lumpsumFutureValue =
                                value.achieved_value *
                                Math.pow((1 + 0.05) / (1 + 0), years);
                              let requiredAmount =
                                value.target_amount_initially_calculated -
                                this.state.projectedArrToShow[index] -
                                lumpsumFutureValue;
                              let lumpsumArr = this.state.lumpsumArr;
                              let lumpsumArrToShow =
                                this.state.lumpsumArrToShow;
                              lumpsumArr[index] = parseInt(requiredAmount);
                              if (requiredAmount < 0) {
                                lumpsumArrToShow[index] = "";
                              }
                              let checkbox = this.state.selectedCheckboxs;
                              checkbox[index].toShow = true;
                              this.setState({
                                sipArrToShow,
                                selectedCheckboxs: checkbox,
                                showToast: "true",
                                toastText: `SIP Applied: ₹ ${numberWithCommas(
                                  sipArrToShow[index]
                                )}, Revised Projection: ${revisedProjectionArr[index]
                                  }%`,
                              });
                            }
                          );
                        }}
                      >
                        <Text
                          style={{
                            color: "#121212",
                            cursor: "pointer",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          Suggested{" "}
                          {this.numberWithSymbols(this.state.sipArr[index])} per
                          month SIP for {years} yrs
                        </Text>
                        <Text
                          style={{
                            color: "#410DAA",
                            cursor: "pointer",
                            fontSize: 14,
                            textAlign: "center",
                            textDecorationLine: "underline",
                            marginTop: 3,
                          }}
                        >
                          Apply
                        </Text>
                      </TouchableOpacity>
                    </>
                  ) : (
                    <View style={{ width: "80%", marginLeft: "10%" }}>
                      <Text
                        style={{
                          color: "#121212",
                          cursor: "pointer",
                          fontSize: 13,
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Goal target reached, no SIP investment required.
                      </Text>
                    </View>
                  )}
                </>
              ),
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            component: true,
            value:
              value.deficitOrSurplus == "Surplus" ? (
                <Text>N.A.</Text>
              ) : this.state.projectedArrToShow[index] &&
                this.state.projectedArrToShow[index] > 0 ? (
                <View
                  style={[
                    { width: "90%", marginLeft: "5%", marginRight: "5%" },
                  ]}
                >
                  <Text
                    style={{
                      color: "#121212",
                      cursor: "pointer",
                      fontSize: 13,
                      textAlign: "center",
                    }}
                  >
                    {this.numberWithSymbols(
                      this.state.projectedArrToShow[index].toFixed(1)
                    )}{" "}
                    {"\n"}
                    {"\n"}
                    Projected for{" "}
                    {this.state.lumpsumArrToShow[index]
                      ? this.numberWithSymbols(
                        this.state.lumpsumArrToShow[index]
                      )
                      : "₹ 0"}{" "}
                    lumpsum and{" "}
                    {this.state.sipArrToShow[index]
                      ? this.numberWithSymbols(this.state.sipArrToShow[index])
                      : "₹ 0"}{" "}
                    SIP by {value.target_date_text}. You will be over/under
                    target.
                  </Text>
                </View>
              ) : null,

            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
        ];

        values.push(data_value);
      });

    let excelBillingData = [];
    data &&
      data.forEach((item) => {
        excelBillingData.push({
          name: item.name,
          target_date_text: item.target_date_text,
          target_amount_initially_calculated: this.numberWithSymbols(
            item.target_amount_initially_calculated
          ),
          achieved_value_text: item.achieved_value_text,
          projected_value_text: item.projected_value_text,
          deficitOrSurplus:
            item.deficitOrSurplus == "Surplus"
              ? "Surplus"
              : this.numberWithSymbols(item.deficitOrSurplus),
          current_sip_contibution_formatted:
            item.current_sip_contibution_formatted,
          calculated_monthly_text: item.calculated_monthly_text,
        });
      });
    return { headerData, values, excelBillingData };
  };

  handleOTPAndPayment = async () => {
    this.setState({ showToast: "false" }, async () => {
      this.setState({
        isLoading: true,
        isLoading2: true,
        loaderText: `Mutual Fund orders must be approved with OTP. Loading your SMS OTP approval page...`,
      });
      let allFunds = [];
      this.state.goalList.forEach((item) => {
        let tempValue = item.schemeDetails;
        tempValue.forEach((value) => {
          value.goal_id = item.customergoal_id;
          allFunds.push(value);
        });
      });

      const ordersData = [];
      let LumpsumList = allFunds.reduce((item, obj) => {
        const key = obj["amcfundscheme_id"];
        if (!item[key]) {
          item[key] = [];
        }
        item[key].push(obj);
        return item;
      }, {});
      LumpsumList = Object.values(LumpsumList);
      LumpsumList.forEach((item) => {
        let goal_activity_string = "";
        let cai_pk_string = "";
        let scheme = "";
        let amount = 0;
        let investmentType = "";
        item.forEach((item2, index) => {
          if (item2.lumpsum_amount_for_goal) {
            if (parseInt(item2.lumpsum_amount_for_goal) != 0) {
              goal_activity_string =
                goal_activity_string +
                (goal_activity_string.length ? ";" : "") +
                `${item2.goal_id}-${item2.selectedFolioValue == "newfolio"
                  ? "NA"
                  : item2.selectedFolioValue
                }-${parseInt(item2.lumpsum_amount_for_goal)}`;
              scheme = item2.amcfundscheme_id;
              cai_pk_string =
                cai_pk_string +
                (cai_pk_string.length ? "," : "") +
                `${item2.lumpsum_cai_pk.toString()}`;
              amount =
                parseInt(amount) + parseInt(item2.lumpsum_amount_for_goal);
              investmentType = "lumpsum";
            }
            ordersData.push({
              goal_activity_string,
              cai_pk_string,
              iaffolio_id:
                item2.selectedFolioValue == "newfolio"
                  ? null
                  : item2.selectedFolioValue,
              scheme,
              amount,
              investmentType,
            });
          }
        });
      });
      let sipMandateCount = 0;
      let sipStartDateCount = 0;
      let totalLength = 0;
      let newData = [];
      allFunds.forEach((item) => {
        if (item.sip_amount_for_goal) {
          if (parseInt(item.sip_amount_for_goal) != 0) {
            if (item.selected_mandate_id) {
              sipMandateCount = sipMandateCount + 1;
            }
            if (item.sip_start_date) {
              sipStartDateCount = sipStartDateCount + 1;
            }
            totalLength = totalLength + 1;
            newData.push({
              goal_activity_string: `${item.goal_id}-${item.selectedFolioValue == "newfolio"
                ? "NA"
                : item.selectedFolioValue
                }-${parseInt(item.sip_amount_for_goal)}`,
              scheme: item.amcfundscheme_id,
              amount: parseInt(item.sip_amount_for_goal),
              sip_frequency_type: "monthly",
              sip_start_date: parseInt(
                moment(item.sip_start_date).format("DD")
              ),
              sip_no_of_installments: Number(item.no_of_installments),
              mandate_id: item.selected_mandate_id,
              investmentType: "sip",
              sip_first_order_flag: "N",
              name: item.name,
            });
            ordersData.push({
              goal_activity_string: `${item.goal_id}-${item.selectedFolioValue == "newfolio"
                ? "NA"
                : item.selectedFolioValue
                }-${parseInt(item.sip_amount_for_goal)}`,
              scheme: item.amcfundscheme_id,
              iaffolio_id:
                item.selectedFolioValue == "newfolio"
                  ? null
                  : item.selectedFolioValue,
              amount: parseInt(item.sip_amount_for_goal),
              cai_pk_string: item.sip_cai_pk.toString(),
              sip_frequency_type: "monthly",
              sip_start_date: item.sip_start_date,
              sip_no_of_installments: Number(item.no_of_installments),
              mandate_id: item.selected_mandate_id,
              investmentType: "sip",
              sip_first_order_flag: "N",
            });
          }
        }
      });
      if (sipMandateCount < totalLength) {
        this.setState({
          showToast: "true",
          toastText: "Please select mandate for all SIPs.",
          isLoading: false,
        });
        // alert("Please select mandate for all SIPs.")
        return;
      } else {
        if (sipStartDateCount < totalLength) {
          this.setState({
            showToast: "true",
            toastText: "Please select start date for all SIPs.",
            isLoading: false,
          });
          // alert("Please select start date for all SIPs.")
          return;
        } else {
          Toast.hide();
        }
      }
      const existingSIPs = this.state.existingSipResp;
      const availableMandates = this.state.mandateList;
      const newSIPs = newData;

      const mandateAmountPerDay = new Map();
      existingSIPs.forEach((sip) => {
        const mandateId = sip.mandate_id;
        const dayOfMonth = sip["DAY"];
        const amount = sip["amount"];
        if (!mandateAmountPerDay.has(mandateId)) {
          mandateAmountPerDay.set(mandateId, {});
        }
        if (!mandateAmountPerDay.get(mandateId)[dayOfMonth]) {
          mandateAmountPerDay.get(mandateId)[dayOfMonth] = 0;
        }
        mandateAmountPerDay.get(mandateId)[dayOfMonth] += amount;
      });

      const maxDailyAmountPerMandate = new Map();
      availableMandates.forEach((mandate) => {
        maxDailyAmountPerMandate.set(mandate.mandate_id, mandate.amount);
      });

      let count = 0;
      const newSipAmountPerDay = new Map();
      newSIPs.forEach((newSip) => {
        const mandateId = newSip["mandate_id"];
        const dayOfMonth = newSip["sip_start_date"];
        const newSipAmount = newSip.amount;

        if (!newSipAmountPerDay.has(mandateId)) {
          newSipAmountPerDay.set(mandateId, {});
        }
        if (!newSipAmountPerDay.get(mandateId)[dayOfMonth]) {
          newSipAmountPerDay.get(mandateId)[dayOfMonth] = 0;
        }
        newSipAmountPerDay.get(mandateId)[dayOfMonth] += newSipAmount;
        const existingAmount =
          (mandateAmountPerDay.has(mandateId) &&
            mandateAmountPerDay.get(mandateId)[dayOfMonth]) ||
          0;

        const totalAmount =
          existingAmount + newSipAmountPerDay.get(mandateId)[dayOfMonth];
        const maxDailyAmount = maxDailyAmountPerMandate.get(mandateId);

        if (totalAmount > maxDailyAmount) {
          this.setState({
            isLoading: false,
            showToast: "true",
            toastText: `Mandate selected for SIP "${newSip.name}" on day "${dayOfMonth}" would exceed the mandate amount.`,
          });
          return;
        } else {
          count = count + 1;
        }
      });
      const key = moment().unix();
      this.setState({ key });
      let flag = 0;
      // console.log(ordersData);
      ordersData.forEach((val) => {
        // console.log("Value is ", val);
        if (val.investmentType === "lumpsum") {
          flag = 1;
        }
      });

      if (flag === 1) {
        this.setState({
          showPayment: true,
        });
      }

      const payload = {
        orders: ordersData,
        redirection_url:
          (Platform.OS == "web"
            ? global.location.origin
            : "https://app.jamawealth.com") +
          "/dashboard/process/" +
          key,
      };
      console.log(payload);
      const response = await createOrderWithFirstInstallmentV2(payload);
      this.props.navigation.navigate("App", {
        screen: "Explore",
        params: {
          screen: "MfOtpAuthentication",
          params: {}
        }
      })

      // if (this.state.userData.customer_id == 39762) {
      //   const payload = {
      //     orders: ordersData,
      //     redirection_url:
      //       (Platform.OS == "web"
      //         ? global.location.origin
      //         : "https://app.jamawealth.com") +
      //       "/dashboard/process/" +
      //       key,
      //   };
      //   console.log(payload);
      //   const response = await createOrderWithFirstInstallmentV2(payload);
      //   this.props.navigation.navigate("App", {
      //     screen: "Explore",
      //     params: {
      //       screen: "MfOtpAuthentication",
      //       params: {}
      //     }
      //   })
      // } else {
      //   if (count == newSIPs.length) {
      //     const payload = {
      //       orders: ordersData,
      //       redirection_url:
      //         (Platform.OS == "web"
      //           ? global.location.origin
      //           : "https://app.jamawealth.com") +
      //         "/dashboard/process/" +
      //         key,
      //     };
      //     const response = await createOrderWithFirstInstallmentV2(payload);
      //     if (response && response.code && response.code == 100) {
      //       const { navigate_to, code, nav_props, ...restData } = response;
      //       Store.dispatch({
      //         type: "ADD_ACTION",
      //         payload: {
      //           key: key,
      //           data: {
      //             ...restData,
      //             ...nav_props,
      //           },
      //         },
      //       });
      //       if (response.success > 0) {
      //         const { purchase_2FA_url } = response;
      //         const { url } = nav_props;
      //         this.setState({
      //           purchase_2FA_url,
      //           purchase_url: url,
      //           isLoading2: false,
      //           loaderText: `Mutual Fund orders must be approved with OTP. Continue to SMS OTP approval page.`,
      //         });
      //       } else {
      //         this.props.navigation.navigate("App", {
      //           screen: "Dashboard",
      //           params: {
      //             screen: "Dashboard",
      //             params: { screen: "ProcessOrder", params: { action_id: key } },
      //           },
      //         });
      //       }
      //     }
      //   }
      // }
    });
  };

  handleOTPApproval = async () => {
    global.open(this.state.purchase_2FA_url, "_blank");
    this.setState({
      isLoading3: true,
      isLoading4: false,
      loaderText: "Waiting for SMS OTP approval of your orders...",
    });
    this.checkOTPStatus();
  };

  handleOTPPageNotOpen = async () => {
    let url_2fa = this.state.purchase_2FA_url;
    if (url_2fa.includes("http")) {
      global.open(url_2fa, "_blank");
    } else {
      const response = await getUrlForClient2FA();
      if (response && response.code == 100) {
        const url = response.result;
        global.open(url, "_blank");
      }
    }
  };

  handlePayment = async () => {
    global.open(this.state.purchase_url, "_self");
  };

  handleContinue = () => {
    if (this.state.recd_count === 0) {
      this.setState({
        errorMsg: true,
      });
    } else {
      this.props.navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Dashboard",
          params: {
            screen: "ProcessOrder",
            params: { action_id: this.state.key },
          },
        },
      });
    }
  };

  render() {
    const {
      searchText,
      searchResults,
      options,
      selectedAccount,
      showModal,
      mandateAmount,
      mandateType,
    } = this.state;
    // console.log(this.state.showPayment);

    const { headerData, values, excelBillingData } =
      this.getHeaderTableData(searchResults);

    let grid_Config = [0.5, 2, 0.8, 0.8, 0.8, 0.8, 0.8, 1.5, 1.5, 1.5];

    if (this.state.uniqueFund.length == 0) {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
          <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Suggested Funds for Goals"]}
            onPressBack={() => {
              this.props.navigation.goBack();
            }}
          />
          <View
            style={{ alignItems: "center", justifyContent: "center", flex: 1 }}
          >
            <Text>
              No mutual funds are available for the selected amount. Please
              increase the amount to get MF Advice
            </Text>
          </View>
        </SafeAreaView>
      );
    } else {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
          <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Suggested Funds for Goals"]}
            onPressBack={() => {
              this.props.navigation.goBack();
            }}
          />
          {showModal && (
            <Modal
              style={{
                top: 0,
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
              }}
              containerStyle={{
                marginHorizontal: isMobile ? 16 : 0,
                borderRadius: 5,
              }}
            >
              <View
                style={{
                  paddingTop: isMobile ? 16 : 24,
                  paddingBottom: isMobile ? 17 : 23,
                  paddingHorizontal: isMobile ? 5 : 33,
                  minWidth: isMobile ? 200 : 633,
                  marginHorizontal: isMobile ? 16 : 0,
                  maxWidth: 850,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                >
                  <Text style={{ fontWeight: "700", fontSize: 15 }}>
                    Add New Bank Mandate
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ showModal: false });
                    }}
                  >
                    <AntDesign
                      name="close"
                      size={24}
                      color="rgba(0, 0, 0, 0.5)"
                    />
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 13,
                    fontWeight: "700",
                    color: "#464E5F",
                    marginBottom: 30,
                    marginTop: -10,
                  }}
                >
                  Mandate helps timely investment in your SIP.
                </Text>
                <PickerFieldSelect
                  data={options}
                  value={selectedAccount}
                  label="Select Bank"
                  onChange={(value) => {
                    this.setState({
                      selectedAccount: value,
                    });
                  }}
                  pickerStyle={[{ marginBottom: 20 }]}
                  type="select"
                  placeholder={{
                    value: "SEL",
                    label: "Select Bank Account",
                  }}
                />
                <TextInputField
                  label="Set Daily Transaction Amount Limit"
                  value={mandateAmount}
                  placeholder="Enter mandate amount"
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        mandateAmount: text,
                      });
                    }
                  }}
                  inputStyle={[
                    { borderColor: "#767676", borderRadius: 6, borderWidth: 1 },
                  ]}
                  editable={true}
                />
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 13,
                    fontWeight: "700",
                    color: "#464E5F",
                    marginTop: 20,
                  }}
                >
                  Mandate Type
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <RadioButton.Group
                    onValueChange={(value) =>
                      this.setState({
                        mandateType: value,
                      })
                    }
                    value={mandateType}
                  >
                    <RadioButton.Item
                      label="E-Mandate With Net Banking"
                      value="N"
                      labelStyle={{ fontSize: 13 }}
                      color="#410daa"
                      style={{
                        flexDirection: "row-reverse",
                        alignSelf: "flex-start",
                      }}
                    />
                    <RadioButton.Item
                      label="With Signed NACH Form"
                      value="X"
                      labelStyle={{ fontSize: 13 }}
                      color="#410daa"
                      style={{
                        flexDirection: "row-reverse",
                        alignSelf: "flex-start",
                        marginTop: -15,
                      }}
                    />
                  </RadioButton.Group>
                </View>
                {this.state.isMandateLoading ? (
                  <ActivityIndicator size="large" color="#410DAA" />
                ) : (
                  <Button
                    uppercase={false}
                    mode={"contained"}
                    onPress={async () => {
                      this.setState({ showToast: "false" }, () => {
                        this.createNewMandate();
                      });
                    }}
                    style={{
                      width: 214,
                      alignSelf: "center",
                      marginBottom: "2%",
                      marginTop: "5%",
                    }}
                    color={"#410DAA"}
                  >
                    <Text style={{ fontSize: 14 }}>Add Mandate</Text>
                  </Button>
                )}
              </View>
            </Modal>
          )}
          {this.state.showToast == "true" ? (
            <ShowToast
              showToast={true}
              type="status"
              title={this.state.toastText}
            />
          ) : null}
          {isMobile ? (
            <ScrollView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
            >
              <View style={styles.detailsContainer}>
                <View style={styles.headingContainer}>
                  <SearchBar value={searchText} onChange={this.handleSearch} />
                  <View
                    style={{
                      cursor: "pointer",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      marginRight: 20,
                    }}
                  ></View>
                </View>
                <GoalsTable
                  header={headerData}
                  gridConfig={grid_Config}
                  data={values}
                  navigation={this.props.navigation}
                  pagination={values.length > 10 ? true : false}
                />
              </View>
            </ScrollView>
          ) : this.state.isLoading ? (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.state.isLoading2 && (
                <>
                  <ActivityIndicator size="large" color="#410DAA" />
                  <Text
                    style={{ marginTop: 20, fontWeight: "bold", fontSize: 17 }}
                  >
                    {this.state.loaderText}
                  </Text>
                </>
              )}
              {!this.state.isLoading2 && (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {this.state.isLoading2 && (
                    <>
                      <ActivityIndicator size="large" color="#410DAA" />
                      <Text
                        style={{
                          marginTop: 20,
                          fontWeight: "bold",
                          fontSize: 17,
                        }}
                      >
                        {this.state.loaderText}
                      </Text>
                    </>
                  )}
                  {!this.state.isLoading2 && (
                    <View style={{ flex: 1, width: "100%" }}>
                      <PlacePaymentAndOTP
                        handleOTPApproval={this.handleOTPApproval}
                        otploading={this.state.isLoading3}
                        showPayment={this.state.showPayment}
                        error={this.state.errorMsg}
                        handleContinue={this.handleContinue}
                        handlePayment={this.handlePayment}
                        paymentLoading={this.state.isLoading4}
                        handleOTPPageNotOpen={this.handleOTPPageNotOpen}
                      />
                    </View>
                  )}
                </View>
              )}
            </View>
          ) : (
            <>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    fontWeight: "600",
                    marginTop: 30,
                    marginLeft: 20,
                    fontSize: 15,
                  }}
                >
                  Summary: ₹{numberWithCommas(this.state.totalSum)} |{" "}
                  {this.state.totalFunds.length} orders |{" "}
                  {this.state.uniqueFund.length} funds |{" "}
                  {this.state.json.length} goals.
                  {"\n"}Investments will be auto-tagged to respective goals.
                </Text>
                <Button
                  uppercase={false}
                  mode={"contained"}
                  onPress={this.handleOTPAndPayment}
                  style={{
                    width: 214,
                    alignSelf: "flex-end",
                    marginRight: "2%",
                    marginBottom: "2%",
                    marginTop: "2%",
                  }}
                  color={"#410DAA"}
                >
                  <Text style={{ fontSize: 14 }}>Place Order</Text>
                </Button>
              </View>
              <ScrollView showsVerticalScrollIndicator={false}>
                <View style={{ marginTop: 30 }}>
                  <View
                    style={{
                      marginHorizontal: isMobile ? 16 : 20,
                      marginBottom: 20,
                      width: isMobile ? 1200 : "96%",
                    }}
                  >
                    {this.state.json &&
                      this.state.json.map((items, index) => {
                        return (
                          <SuggestedFundsCard
                            data={items}
                            index={index}
                            mandateList={this.state.mandateList}
                            sipArr={this.state.sipArr}
                            sipArrToShow={this.state.sipArrToShow}
                            lumpsumArr={this.state.lumpsumArr}
                            lumpsumArrToShow={this.state.lumpsumArrToShow}
                            selectedCheckboxs={this.state.selectedCheckboxs}
                            projectedArrToShow={this.state.projectedArrToShow}
                            updateAllCheckboxs={(value) => {
                              this.setState({ isAllCheckboxSelected: value });
                            }}
                            updateSelectedCheckboxs={(value) => {
                              this.setState({ selectedCheckboxs: value });
                            }}
                            onSIPTextChange={(text, AMCFund__pk) => {
                              if (text > 0) {
                                let json = this.state.json;
                                let index1 = json[
                                  index
                                ].schemeDetails.findIndex(
                                  (item) => item.AMCFund__pk == AMCFund__pk
                                );
                                json[index].schemeDetails[
                                  index1
                                ].sip_amount_for_goal = text;
                                json[index].schemeDetails[
                                  index1
                                ].is_for_sip = true;
                                let totalSum = 0;
                                json.forEach((item) => {
                                  item.schemeDetails.forEach((item2) => {
                                    if (item2.sip_amount_for_goal) {
                                      totalSum =
                                        totalSum + item2.sip_amount_for_goal;
                                    }
                                    if (item2.lumpsum_amount_for_goal) {
                                      totalSum =
                                        totalSum +
                                        item2.lumpsum_amount_for_goal;
                                    }
                                  });
                                });
                                this.setState({
                                  json,
                                  totalSum: parseInt(totalSum),
                                });
                              } else {
                                let json = this.state.json;
                                let index1 = json[
                                  index
                                ].schemeDetails.findIndex(
                                  (item) => item.AMCFund__pk == AMCFund__pk
                                );
                                json[index].schemeDetails[
                                  index1
                                ].sip_amount_for_goal = "";
                                json[index].schemeDetails[
                                  index1
                                ].is_for_sip = false;
                                let totalSum = 0;
                                json.forEach((item) => {
                                  item.schemeDetails.forEach((item2) => {
                                    if (item2.sip_amount_for_goal) {
                                      totalSum =
                                        totalSum + item2.sip_amount_for_goal;
                                    }
                                    if (item2.lumpsum_amount_for_goal) {
                                      totalSum =
                                        totalSum +
                                        item2.lumpsum_amount_for_goal;
                                    }
                                  });
                                });
                                this.setState({
                                  json,
                                  totalSum: parseInt(totalSum),
                                });
                              }
                            }}
                            onLumpsumTextChange={(text, AMCFund__pk) => {
                              if (text > 0) {
                                let json = this.state.json;
                                let index1 = json[
                                  index
                                ].schemeDetails.findIndex(
                                  (item) => item.AMCFund__pk == AMCFund__pk
                                );
                                json[index].schemeDetails[
                                  index1
                                ].lumpsum_amount_for_goal = text;
                                let totalSum = 0;
                                json.forEach((item) => {
                                  item.schemeDetails.forEach((item2) => {
                                    if (item2.sip_amount_for_goal) {
                                      totalSum =
                                        totalSum + item2.sip_amount_for_goal;
                                    }
                                    if (item2.lumpsum_amount_for_goal) {
                                      totalSum =
                                        totalSum +
                                        item2.lumpsum_amount_for_goal;
                                    }
                                  });
                                });
                                this.setState({
                                  json,
                                  totalSum: parseInt(totalSum),
                                });
                              } else {
                                let json = this.state.json;
                                let index1 = json[
                                  index
                                ].schemeDetails.findIndex(
                                  (item) => item.AMCFund__pk == AMCFund__pk
                                );
                                json[index].schemeDetails[
                                  index1
                                ].lumpsum_amount_for_goal = "";
                                let totalSum = 0;
                                json.forEach((item) => {
                                  item.schemeDetails.forEach((item2) => {
                                    if (item2.sip_amount_for_goal) {
                                      totalSum =
                                        totalSum + item2.sip_amount_for_goal;
                                    }
                                    if (item2.lumpsum_amount_for_goal) {
                                      totalSum =
                                        totalSum +
                                        item2.lumpsum_amount_for_goal;
                                    }
                                  });
                                });
                                this.setState({
                                  json,
                                  totalSum: parseInt(totalSum),
                                });
                              }
                            }}
                            onSipDateChange={(text, AMCFund__pk) => {
                              let json = this.state.json;
                              let index1 = json[index].schemeDetails.findIndex(
                                (item) => item.AMCFund__pk == AMCFund__pk
                              );
                              json[index].schemeDetails[index1].sip_start_date =
                                text;
                              this.setState({ json });
                            }}
                            onFolioNumberChange={(text, AMCFund__pk) => {
                              let json = this.state.json;
                              let index1 = json[index].schemeDetails.findIndex(
                                (item) => item.AMCFund__pk == AMCFund__pk
                              );
                              json[index].schemeDetails[index1].selectedFolio =
                                text;
                              json[index].schemeDetails[
                                index1
                              ].selectedFolioValue = text;
                              this.setState({ json });
                            }}
                            onSelectMandate={(text, AMCFund__pk) => {
                              if (text == "create_new_mandate") {
                                this.setState({
                                  mandateAmount: "",
                                  mandateType: "",
                                  showModal: true,
                                });
                              } else {
                                let json = this.state.json;
                                let index1 = json[
                                  index
                                ].schemeDetails.findIndex(
                                  (item) => item.AMCFund__pk == AMCFund__pk
                                );
                                json[index].schemeDetails[
                                  index1
                                ].selected_mandate_id = text;
                                this.setState({ json });
                              }
                            }}
                            sipApply={(index, years) => {
                              this.setState(
                                {
                                  showToast: "false",
                                },
                                () => {
                                  let sipArrToShow = this.state.sipArrToShow;
                                  sipArrToShow[index] =
                                    this.state.sipArr[index];
                                  this.calculateProjectedAmt(
                                    this.state.lumpsumArrToShow[index],
                                    sipArrToShow[index],
                                    0.11,
                                    0,
                                    years,
                                    index
                                  );
                                  let lumpsumFutureValue =
                                    items.achieved_value *
                                    Math.pow((1 + 0.05) / (1 + 0), years);
                                  let requiredAmount =
                                    items.target_amount_initially_calculated -
                                    this.state.projectedArrToShow[index] -
                                    lumpsumFutureValue;
                                  let lumpsumArr = this.state.lumpsumArr;
                                  let lumpsumArrToShow =
                                    this.state.lumpsumArrToShow;
                                  lumpsumArr[index] = parseInt(requiredAmount);
                                  if (requiredAmount < 0) {
                                    lumpsumArrToShow[index] = "";
                                  }
                                  let checkbox = this.state.selectedCheckboxs;
                                  checkbox[index].toShow = true;
                                  this.setState({
                                    sipArrToShow,
                                    selectedCheckboxs: checkbox,
                                    showToast: "true",
                                    toastText: `SIP Applied: ₹ ${numberWithCommas(
                                      sipArrToShow[index]
                                    )}, Revised Projection: ${revisedProjectionArr[index]
                                      }%`,
                                  });
                                }
                              );
                            }}
                            lumpsumApply={(index, years) => {
                              this.setState(
                                {
                                  showToast: "false",
                                },
                                () => {
                                  let lumpsumArrToShow =
                                    this.state.lumpsumArrToShow;
                                  lumpsumArrToShow[index] =
                                    this.state.lumpsumArr[index];
                                  let sipFutureValue =
                                    items.target_amount_initially_calculated -
                                    (items.achieved_value +
                                      this.state.lumpsumArr[index]) *
                                    Math.pow((1 + 0.05) / (1 + 0), years);
                                  let monthlyInterestRate = 0.05 / 12;
                                  let numberOfPayments = years * 12;
                                  let monthlyPayment =
                                    (sipFutureValue * monthlyInterestRate) /
                                    (1 -
                                      Math.pow(
                                        1 + monthlyInterestRate,
                                        -numberOfPayments
                                      ));

                                  if (!isFinite(monthlyPayment)) {
                                    monthlyPayment = 0;
                                  }
                                  let sipArr = this.state.sipArr;
                                  let sipArrToShow = this.state.sipArrToShow;
                                  sipArr[index] = parseInt(monthlyPayment);
                                  if (monthlyPayment < 0) {
                                    sipArrToShow[index] = "";
                                  }
                                  this.calculateProjectedAmt(
                                    lumpsumArrToShow[index],
                                    this.state.sipArrToShow[index],
                                    0.11,
                                    0,
                                    years,
                                    index
                                  );
                                  let checkbox = this.state.selectedCheckboxs;
                                  checkbox[index].toShow = true;
                                  this.setState({
                                    lumpsumArrToShow,
                                    selectedCheckboxs: checkbox,
                                    showToast: "true",
                                    toastText: `Lumpsum Applied: ₹ ${numberWithCommas(
                                      lumpsumArrToShow[index]
                                    )}, Revised Projection: ${revisedProjectionArr[index]
                                      }%`,
                                  });
                                }
                              );
                            }}
                          />
                        );
                      })}
                  </View>
                  {/* <View style={styles.detailsContainer}>
                  <View style={styles.headingContainer}>
                    <SearchBar value={searchText} onChange={this.handleSearch} />
                    <View
                      style={{
                        cursor: "pointer",
                        justifyContent: "flex-end",
                        flexDirection: "row",
                        marginRight: 20,
                      }}
                    >
                      <ExcelFile
                        filename={
                          this.state.userName +
                          "'s Goals List " +
                          moment().format("DD-MM-YYYY hh-mm-ss A")
                        }
                        element={
                          <Text
                            style={{
                              color: "#410DAA",
                              fontWeight: "bold",
                              cursor: "pointer",
                              fontSize: 15,
                              textDecorationLine: "underline",
                            }}
                          >
                            Download As Excel
                          </Text>
                        }
                      >
                        <ExcelSheet data={excelBillingData} name="Goals List">
                          <ExcelColumn label="Goal Name" value="name" />
                          <ExcelColumn
                            label="Due Date"
                            value="target_date_text"
                          />
                          <ExcelColumn
                            label="Amount When Due"
                            value="target_amount_initially_calculated"
                          />
                          <ExcelColumn
                            label="Achieved"
                            value="achieved_value_text"
                          />
                          <ExcelColumn
                            label="Projected"
                            value="projected_value_text"
                          />
                          <ExcelColumn
                            label="Deficit On Due Date"
                            value="deficitOrSurplus"
                          />
                        </ExcelSheet>
                      </ExcelFile>
                    </View>
                  </View>
                  <GoalsTable
                    header={headerData}
                    gridConfig={grid_Config}
                    data={values}
                    navigation={this.props.navigation}
                    pagination={values.length > 10 ? true : false}
                  />
                </View> */}
                </View>
                {/* <Button
                  uppercase={false}
                  mode={"contained"}
                  onPress={this.handleOTPAndPayment}
                  style={{
                    width: 214,
                    alignSelf: "flex-end",
                    marginRight: "2%",
                    marginBottom: "8%",
                  }}
                  color={"#410DAA"}
                >
                  <Text style={{ fontSize: 14 }}>Place Order</Text>
                </Button> */}
              </ScrollView>
            </>
          )}
        </SafeAreaView>
      );
    }
  }
}

const styles = StyleSheet.create({
  rowItem: {
    textAlign: "left",
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  rowItemContainerStyle: {
    justifyContent: "flex-start",
    paddingLeft: 10,
  },
  headerItemContainerStyle: {
    justifyContent: "flex-start",
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: isMobile ? 16 : 20,
    marginBottom: 40,
    width: isMobile ? 1200 : "96%",
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 16 : 19,
    paddingVertical: 19,
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "space-between",
  },
});
