import React from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";

export const NewHeading = ({
  title,
  subTitle,
  style,
  sideTitle,
  headingTitleContainerStyle,
  headingTitleStyle,
  sideTitleContainerStyle,
  descriptions,
  children,
  tipHeading,
  tipDescriptions,
}) => {
  return (
    <View style={[styles.heading, style]}>
      <View
        style={{
          ...styles.headingTitle,
          ...headingTitleContainerStyle,
          flex: 0.9,
        }}
      >
        <Text
          style={{
            ...styles.headingTitleText,
            fontSize: style && style.fontSize ? style.fontSize : 13,
            marginLeft: 5,
            ...headingTitleStyle,
          }}
        >
          {title || children}
          <Text style={{ fontWeight: "normal" }}>
            {subTitle ? "  (" + subTitle + ")" : ""}
          </Text>
        </Text>
        {descriptions && (
          <View style={{}}>
            {descriptions.map((description) => {
              return (
                <Text style={{ fontSize: 13, color: "#A8A8A8" }}>
                  {description}
                </Text>
              );
            })}
          </View>
        )}
        {tipHeading && tipDescriptions && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <Text style={{ fontSize: 13, color: "#410DAA" }}>
              {tipHeading}:
            </Text>
            {
              <>
                {tipDescriptions.map((description) => {
                  return (
                    <Text style={{ fontSize: 13, color: "#A8A8A8" }}>
                      {description}
                    </Text>
                  );
                })}
              </>
            }
          </View>
        )}
      </View>
      {sideTitle && (
        <View
          style={{
            ...styles.sideTitle,
            ...sideTitleContainerStyle,
          }}
        >
          <Text style={styles.sideTitleText}>{sideTitle || ""}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  heading: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
  },
  headingLegend: {
    width: 15,
    height: 20,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  headingTitle: {
    paddingLeft: 10,
    flex: 0.8,
    flexDirection: "column",
  },
  headingTitleText: {
    fontSize: 13,
    fontWeight: "500",
    color: "#121212",
  },
  sideTitle: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flex: 0.2,
    paddingRight: 16,
  },
  sideTitleText: {
    color: "#707070",
    fontSize: 10,
  },
});
