import { StackActions } from "@react-navigation/routers";
import React from "react";
import {
  View,
  Image,
  FlatList,
  ScrollView,
  StatusBar,
  SafeAreaView,
  Text,
  BackHandler,
  TouchableOpacity,
} from "react-native";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { isMobile } from "../../../utils";

const GoalOption = ({ source, title, onPress }) => {
  return (
    <View
      style={{
        flexDirection: "column",
        alignItems: "center",
        margin: 10,
        marginRight: isMobile ? 10 : 18,
        alignSelf: "center",
      }}
    >
      <TouchableOpacity
        style={{
          borderColor: "#410DAA1A",
          borderRadius: 10,
          borderWidth: 1,
          padding: 25,
        }}
        onPress={() => {
          onPress();
        }}
      >
        <Image
          source={{
            uri: source,
          }}
          style={{
            width: isMobile ? 100 : 160,
            height: isMobile ? 100 : 160,
            alignSelf: "center",
          }}
          resizeMode="contain"
        />
      </TouchableOpacity>
      <Text
        style={{
          fontSize: 14,
          color: "#121212",
          fontWeight: "bold",
          paddingTop: 5,
        }}
      >
        {title}
      </Text>
    </View>
  );
};

// ('EMF', 'Emergency Fund'),
//    ('RET', 'Retirement'),
//    ('HME', 'Home'),
//    ('CED', 'Higher Education of Child'),
//    ('CMR', 'Marriage of Child'),
//    ('VAC', 'Vacation'),
//    ('CAR', 'Car'),
//    ('HI','Health Insurance'),
//    ('TI','Term Insurance'),
//    ('OTH', 'Others'),

export default class CreateGoalsIntro extends React.Component {
  constructor(props) {
    super(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.state = {};
  }

  componentWillMount() {
    BackHandler.addEventListener(
      "hardwareBackPress",
      this.handleBackButtonClick
    );
  }

  componentWillUnmount() {
    BackHandler.removeEventListener(
      "hardwareBackPress",
      this.handleBackButtonClick
    );
  }

  handleBackButtonClick() {
    let screen = "Goals";
    let stack = "Goals";
    let from = null;

    const { params: route_params } = this.props.route;
    let fromTitle = null;
    if (route_params) {
      fromTitle = route_params.fromTitle || null;
    }

    if (route_params) {
      from = route_params.from || null;
      stack = route_params.stack || null;
    }
   

    screen = from || screen;
    this.props.navigation.dispatch(
      StackActions.replace("App", {
        screen: "Dashboard",
        params: {
          screen: stack,
          params: {
            screen: screen,
          },
        },
      })
    );
    return true;
  }

  _renderItem = ({ item, index }) => {
    return (
      <GoalOption
        title={item.name}
        source={item.image}
        onPress={() => {
          this.props.navigation.navigate("AddGoal", {
            goal_type: item.type,
          });
        }}
      />
    );
  };

  render() {
    const data = [
      {
        name: "Marriage",
        image:
          "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/marriage_finplan_image.png",
        type: "CMR",
        //TODO - get type
      },
      {
        name: "Education",
        image:
          "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/child_grad_finplan_image.png",
        type: "HE",
        //TODO - get type
      },
      {
        name: "Car",
        image:
          "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/car_finplan_image.png",
        type: "CAR",
      },
      {
        name: "Bike",
        image:
          "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/bike_finplan_image.png",
        type: "BK",
      },
      {
        name: "Home Ownership",
        image:
          "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/buying_home_fin_plan.png",
        type: "HME",
      },
      {
        name: "Other",
        image:
          "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/other_goals2.png",
        type: "OTH",
      },
    ];

    const { params: route_params } = this.props.route;
    let fromTitle = null;
    if (route_params) {
      fromTitle = route_params.fromTitle || null;
    }

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={[fromTitle || "Goals"]}
          onPressBack={() => {
            // console.log("K")
            this.props.navigation.goBack();
            if(this.props.route.params.from === "InvestNowNew"){
            this.props.navigation.navigate("App",{
              screen:"Explore",
              // params:{
              //   screen:"mutualfunds",
              // },
            });
          } else {
            this.props.navigation.navigate("App",{
              screen:"Dashboard",
              params:{
                screen:"Goals",
              },
            });
          }
            // this.props.navigation.navigate("App",{
            //   screen:"Explore",
            //   params:{
            //     screen:"mutualfunds",
            //   },
            // });
            // let screen = "Goals";
            // let stack = "Goals";
            // let from = null;
            // if (route_params) {
            //   from = route_params.from || null;
            //   stack = route_params.stack || null;
            // }

            // screen = from || screen;

            // // if (from == "InvestNowNew"){
            // //   // alert("9876")
            // //   this.props.navigation.dispatch(
            // //     StackActions.replace("App", {
            // //       screen: "Explore",
            // //       params: {
            // //         screen: "MFExplore",
            // //         params: { },
            // //       },
            // //     })
            // //   );
            // // }
            // this.props.navigation.dispatch(
            //   StackActions.replace("App", {
            //     screen: "Dashboard",
            //     params: {
            //       screen: stack,
            //       params: {
            //         screen: screen,
            //       },
            //     },
            //   })
            // );
          }}
        />
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
          showsVerticalScrollIndicator={true}
        >
          <View
            style={{
              flexDirection: "row",
              marginVertical: 15,
              marginHorizontal: 16,
            }}
          >
            <FlatList
              data={data}
              keyExtractor={(item, index) => index} //has to be unique
              renderItem={this._renderItem} //method to render the data in the way you want using styling u need
              horizontal={false}
              numColumns={isMobile ? 2 : 3}
            />
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
