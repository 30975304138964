import React from "react";
import { View, Image } from "react-native";
import { Text, Button } from "react-native-paper";
import theme from "../../../static/theme";
import { isMobile } from "../../../utils";

export const YesNo = (props) => {
  return (
    <View
      style={[{ width: "100%", flex: 1, flexDirection: "column" }, props.style]}
    >
      <View
        style={{ flex: 0.3, justifyContent: "center", alignItems: "center" }}
      >
        <Image
          source={{
            uri: props.image,
          }}
          style={{
            width: !isMobile ? 200 : 150,
            height: !isMobile ? 200 : 150,
            alignSelf: "center",
          }}
          resizeMode="contain"
        />
      </View>
      <View
        style={{
          flex: 0.3,
          flexDirection: "column",
        }}
      >
        <Text
          style={[
            props.titleStyle,
            {
              alignSelf: "center",
              color: "#121212",
              fontSize: 16,
              fontWeight: "bold",
              marginTop: 30,
            },
          ]}
        >
          {props.title}
        </Text>
        <View
          style={{
            flexDirection: "row",
            position: "absolute",
            alignSelf: "center",
            bottom: 15,
          }}
        >
          <View style={{ flex: 0.5, paddingLeft: 0, marginLeft: 0 }}>
            <Button
              mode="outlined"
              onPress={() => props.onNo()}
              style={{
                alignSelf: "flex-start",
                width: 150,
                // height: 50,
                backgroundColor: "white",
                borderWidth: 1,
                borderColor: theme.colors.NEW_BRAND_COLOR,
              }}
            >
              <Text
                style={{ color: theme.colors.NEW_BRAND_COLOR, fontSize: 16 }}
              >
                No
              </Text>
            </Button>
          </View>
          <View
            style={{
              flex: 0.5,
              paddingRight: 0,
              marginRight: 0,
              paddingLeft: 10,
            }}
          >
            <Button
              mode="contained"
              onPress={() => props.onYes()}
              style={{
                alignSelf: "flex-end",
                width: 150,
                // height: 50,
                backgroundColor: theme.colors.NEW_BRAND_COLOR,
                borderWidth: 0,
              }}
            >
              <Text style={{ color: "white", fontSize: 16 }}>Yes</Text>
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};
