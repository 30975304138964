import React from "react";
import { StyleSheet, View, Platform, Image } from "react-native";
import { Text } from "react-native-paper";

export default function ReviewCard({ client, carousel, first }) {
    const { name, about, review, id } = client;
    let img = null;
    if (id === 1) {
        img = require("../../assets/images/subscription/gautham_khanna.png");
    } else if (id === 2) {
        img = require("../../assets/images/subscription/nageswar.jpg");
    }
    return (
        <>
            <View
                style={[
                    styles.container,
                    { marginTop: carousel ? 0 : first ? 47 : 75 }
                ]}
            >
                <Image source={img} style={styles.img}></Image>
                <Text style={styles.name}>{name}</Text>
                <Text style={styles.about}>{about}</Text>
                <Text style={styles.review}>{review}</Text>
            </View>
        </>
    );
}
  
const styles = StyleSheet.create({
    container: {
        maxWidth: Platform.OS === "web" ? 300 : undefined,
        minWidth: Platform.OS === "web" ? 200 : undefined,
        alignSelf: Platform.OS === "web" ? undefined : "center",
        paddingLeft: Platform.OS === "web" ? 0 : 45,
        paddingRight: Platform.OS === "web" ? 0 : 45
    },
    img: {
        width: 101,
        height: 101,
        alignSelf: "center",
        marginBottom: 8,
        resizeMode: "contain"
    },
    name: {
        textAlign: "center",
        fontSize: 13,
        fontWeight: "bold",
        color: "black"
    },
    about: {
        textAlign: "center",
        fontSize: 13,
        marginTop: 5,
        opacity: 0.5,
        color: "black"
    },
    review: {
        textAlign: "center",
        color: "black",
        marginTop: 20
    },
    hr: {
        height: Platform.OS === "web" ? undefined : 1,
        backgroundColor: "#CCC",
        width: Platform.OS === "web" ? 1 : undefined,
        marginTop: Platform.OS === "web" ? undefined : 30
    }
});
  