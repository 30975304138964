import React, { useEffect } from "react";
import {
  View,
  Image,
  StatusBar,
  Platform,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
} from "react-native";
import Icon from "@expo/vector-icons/FontAwesome";
import { Text } from "react-native-paper";
import { useFilePicker } from "use-file-picker";
import TextInputField from "../../components/Common/Inputs/InputField";
import {
  capitalizeFirstLetter,
  getUserDetails,
  DataURIToBlob,
  isMobile,
} from "../../utils";
import SearchBar from "../../components/Common/SearchBar";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;
// import ReactExport from "react-export-excel";
import {
  getCorporateDashboard,
  getCustomerDetails,
  getCustomerReport,
  updateCustomerCorporateMapping,
} from "../../api";
import Store from "../../store";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import moment from "moment";
import TeamsTable from "./TeamsTable";
import NavigationHeader from "../../components/Common/NavigationHeader";
import amplitude from "../../utils/amplitude";
import { saveAs } from "file-saver";
import { Popable } from "react-native-popable";
import { Feather } from "@expo/vector-icons";
import Modal from "../../components/Common/Modal";
import { AntDesign } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import ProgressBar from "../../utils/progressBar";
import { ShowToast } from "../../components/Common/Toast";

const SelectContractNote = ({ onSelect }) => {
  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: "DataURL",
    accept: [".xls", ".csv", ".xlsx"],
    multiple: true,
    // limitFilesConfig: { max: 3 },
    // maxFileSize: 0.5, // in MB
  });
  useEffect(() => {
    onSelect(filesContent, errors);
  }, [filesContent]);
  const isFileSelected = () => {
    return filesContent.length != 0;
  };
  if (loading) {
    return <Text>Loading</Text>;
  }
  const checkSizeError = () => {
    if (errors && errors.length) {
      const error = errors[0];
      const { fileSizeToolarge } = error;
      return {
        fileSizeToolarge: true,
        error: true,
      };
    }
    return null;
  };
  const selectedFile = isFileSelected();
  const errorData = checkSizeError();
  return (
    <View>
      <TouchableOpacity
        onPress={() => {
          openFilePicker();
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderColor: "#410DAA",
            borderWidth: 1,
            borderRadius: 4,
            textAlign: "center",
            backgroundColor: "#410DAA",
            padding: 10,
            height: 45,
          }}
        >
          <Feather name="upload" size={20} color="#fff" />
          <Text
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: 15,
              paddingLeft: 10,
              color: "white",
            }}
          >
            Upload Team List (xlsx)
          </Text>
        </View>
      </TouchableOpacity>
      {/* {selectedFile ? (
        filesContent.map((file, index) => (
          <Text style={styles.fileName}>{file.name}</Text>
        ))
      ) : (
        <Text style={styles.fileName}></Text>
      )} */}
    </View>
  );
};

class CorporateDashboard extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    let viewTeam = null;
    const { params } = this.props.route;
    if (params) {
      userId = params.user_id || null;
      viewTeam = params.viewTeam || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }

    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    let cPartnerBool = false;
    if (hostname.includes("cpartner")) {
      cPartnerBool = true;
    }
    this.state = {
      cPartnerBool,
      isAddTeamEnabled: false,
      isViewTeamEnabled: viewTeam ? true : false,
      sorting_field: null,
      activeTab: "Not Logged in Yet",
      sorting_value: null,
      filesData: [],
      getCorporateResp: [],
      userId,
      showModal: false,
      showToast: false,
      toastMsg: "",
      isLoading: false,
      total_avg_perc: 0,
      userName: "",
      teamsDataValue: [],
      searchTeamsResults: [],
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("CorporateDashboard", { path: global.location.href });
    } else {
      amplitude.logEvent("CorporateDashboard", {
        path: this.props.navigation.getState().routeName,
      });
    }
    let userData = {};
    let getCorporateResp = [];
    let viewTeamResp = [];
    this.setState({ isLoading: true });
    if (this.state.userId) {
      userData = await getCustomerDetails(this.state.userId);
    } else {
      let userData = await getUserDetails();
      getCorporateResp = await getCorporateDashboard(userData.corporate_id);
      userData = await getUserDetails();
      const payload = {
        previous_key: "view_corporate_team",
        param: userData.corporate_id,
        param1: userData.is_corporate_staff_admin ? "managercode" : "",
      };
      viewTeamResp = await getCustomerReport(payload);
    }
    // console.log("DEBUG: ViewTEAM " + JSON.stringify(getCorporateResp));
    let teamsDataValue = [];
    viewTeamResp.result.forEach((item) => {
      teamsDataValue.push({
        name: item.first_name,
        employeeId: item.employee_id,
        email: item.email,
        teamName: item.team_or_department_name,
        managerId: item.manager_id,
        last_login: item.last_login,
      });
    });
    this.setState({
      isLoading: false,
      getCorporateResp: getCorporateResp.result,
      teamsDataValue,
      searchTeamsResults: teamsDataValue,
      total_avg_perc: parseInt(getCorporateResp.total_avg_perc),
    });
    let { first_name } = userData;
    let names = first_name ? first_name.split(/\s+/) : [];
    let name = "";
    if (names.length >= 2 && names.length > 0) {
      name =
        capitalizeFirstLetter(names[0]) + " " + capitalizeFirstLetter(names[1]);
    } else {
      if (names.length == 0) {
        name = "User";
      } else {
        name = names[0];
      }
    }
    this.setState({ userName: name });
  }

  handleTeamsSort = (field, sort, start, end) => {
    const sorting_field = field;
    const sorting_value = !sort;

    const { searchTeamsResults } = this.state;
    const sortedData = [].concat(
      searchTeamsResults.slice(0, start),
      this.getSortedData(
        searchTeamsResults,
        sorting_field,
        sorting_value,
        start,
        end,
        "teams"
      ),
      searchTeamsResults.slice(end, searchTeamsResults.length)
    );

    this.setState({
      sorting_field,
      sorting_value,
      searchTeamsResults: sortedData,
    });
  };

  handleTeamsSearch = (searchTeamsText) => {
    let searchTeamsResults = [];
    const searchData = this.state.teamsDataValue;

    if (searchTeamsText) {
      searchTeamsResults = searchData.filter((teams) => {
        let searchText = teams.name + teams.teamName;
        return searchText.toLowerCase().includes(searchTeamsText.toLowerCase());
      });
    } else {
      searchTeamsResults = searchData;
    }
    if (searchTeamsResults.length) {
      this.setState({
        searchTeamsText,
        searchTeamsResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  compareStrings = (a, b) => {
    const x = a.toLowerCase();
    const y = b.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  compareNumbers = (a, b) => {
    return a - b;
  };

  getSortedData = (data, sorting_field, sorting_value, start, end) => {
    if (data.length != 0) {
      const fieldValue = data[0][sorting_field];
      const sortingType = isNaN(fieldValue) ? "string" : "number";

      data = data.slice(start, end);
      if (sortingType == "string") {
        data.sort((a, b) =>
          this.compareStrings(a[sorting_field], b[sorting_field])
        );
      } else {
        data.sort((a, b) =>
          this.compareNumbers(a[sorting_field], b[sorting_field])
        );
      }

      return sorting_value ? data : data.reverse();
    } else {
      return data;
    }
  };

  SelectTabDetails = ({ item, onPress, backgroundColor, color }) => {
    return (
      <TouchableOpacity
        style={{
          borderWidth: 0.5,
          borderColor: "#BCBCBC",
          borderRadius: 18,
          backgroundColor,
          marginRight: 16,
          paddingLeft: 20,
          paddingRight: 20,
          height: 36,
          alignItems: "center",
          justifyContent: "center",
        }}
        onPress={onPress}
      >
        <Text style={{ fontSize: 14, color }}>{item}</Text>
      </TouchableOpacity>
    );
  };

  getTeamsHeaderData = (data) => {
    const { sorting_field, sorting_value } = this.state;

    const keysData = [
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Email",
        key: "email",
      },
      {
        label: "Employee Id",
        key: "employeeId",
      },
      {
        label: "Team",
        key: "teamName",
      },
      {
        label: "Manager Id",
        key: "managerId",
      },
    ];

    let headerTeamsData = [];
    keysData.forEach((headerItem) => {
      headerTeamsData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: true,
        onSort: this.handleTeamsSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    let valuesTeams = [];
    let valuesTeamNotLoggedIn = [];
    data.forEach((value) => {
      if (value.last_login === null) {
        valuesTeamNotLoggedIn.push([
          {
            valueToDisplay: value.name,
            value: value.name,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.email,
            value: value.email,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.employeeId,
            value: value.employeeId,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.teamName,
            value: value.teamName,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.managerId,
            value: value.managerId,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
        ]);
      } else {
        valuesTeams.push([
          {
            valueToDisplay: value.name,
            value: value.name,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.email,
            value: value.email,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.employeeId,
            value: value.employeeId,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.teamName,
            value: value.teamName,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.managerId,
            value: value.managerId,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
        ]);
      }
    });

    return { headerTeamsData, valuesTeams, valuesTeamNotLoggedIn };
  };
  renderFilesUploaded = ({ item }) => {
    const removeFile = (imageName) => {
      const dataAfterFileRemoval = this.state.filesData.filter(
        (item) => item.imageName != imageName
      );
      this.setState({ filesData: dataAfterFileRemoval });
    };
    const fileTypes = ["xls", "pdf", "csv", "xlsx"];
    return (
      <View style={styles.flat}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingVertical: 3,
          }}
        >
          <AntDesign name="filetext1" size={16} color="#410DAA" />
          <Text style={styles.imageName}>{item.imageName}</Text>
        </View>
        {item.imageSize <= 1003000 && fileTypes.includes(item.fileType) ? (
          <Ionicons
            name="checkmark-circle-outline"
            size={20}
            color="#009105"
            style={{ marginRight: 10 }}
          />
        ) : (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginRight: 10,
            }}
          >
            {item.imageSize > 1003000 ? (
              <Text style={styles.filePlease}>Error: File Size Exceeded</Text>
            ) : (
              <Text style={styles.filePlease}>Error: Wrong Format</Text>
            )}
            <TouchableOpacity
              onPress={() => {
                removeFile(item.imageName);
              }}
            >
              <Ionicons name="close-circle-outline" size={20} color="#EF5D12" />
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  };
  handleFileUpload = async () => {
    const { filesData } = this.state;
    this.setState({ submitLoading: true });
    let { user_id } = this.state;
    let userData = {};
    if (user_id) {
      userData = await getCustomerDetails(this.state.userId);
    } else {
      userData = await getUserDetails();
    }
    let checkData = 0;
    const fileTypes = ["xls", "csv", "xlsx"];

    filesData.forEach((item) => {
      if (item.imageSize > 1003000 || !fileTypes.includes(item.fileType)) {
        checkData = checkData + 1;
      }
    });
    if (filesData.length > 0 && checkData === 0) {
      try {
        for (let i = 0; i < filesData.length; i++) {
          const item = filesData[i];
          const { imageUrl, imageName } = item;
          const imageFile = DataURIToBlob(imageUrl);

          const formData = new FormData();
          formData.append("uploaded_excelname", imageName);
          formData.append("uploaded_excel", imageFile, imageName);
          formData.append("jamacorporate_id", userData.corporate_id);
          // console.log(Object.fromEntries(formData));
          const response = await updateCustomerCorporateMapping(formData);
          // console.log(response);
          this.setState({
            showToast: true,
            showModal: false,
            toastMsg: response.msg,
          });
        }
        this.setState({ fileError: "", submitLoading: false });
      } catch (error) {
        console.error("Error updating customer corporate mapping:", error);
        this.setState({
          submitLoading: false,
          fileError: "Unable to connect to the server",
        });
      }
    } else {
      this.setState({
        fileError: "There is an error with your file",
        submitLoading: false,
      });
    }
  };

  handleCopyText = () => {
    navigator.clipboard
      .writeText("https://www.maxiom.in/app/accounts/signup/?ref=MRXM743")
      .then((v) => {
        this.setState({ showToast: true, toastMsg: "Copied to clipboard" });
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  };

  render() {
    const { headerTeamsData, valuesTeams, valuesTeamNotLoggedIn } =
      this.getTeamsHeaderData(this.state.searchTeamsResults);
    const { filesData, checkData, fileError, submitLoading } = this.state;

    let excelTeamsData = [];
    this.state.searchTeamsResults.forEach((item) => {
      excelTeamsData.push({
        name: item.name,
        managerId: item.managerId,
        teamName: item.teamName,
        email: item.email,
        employeeId: item.employeeId,
      });
    });
    return this.state.isLoading ? (
      <ProgressBar msg={"Loading data..."} />
    ) : (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {this.state.isAddTeamEnabled && (
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Add Team"]}
            onPressBack={() => {
              if (this.state.cPartnerBool) {
                this.setState({
                  isAddTeamEnabled: false,
                  isViewTeamEnabled: true,
                });
              } else {
                this.setState({
                  isAddTeamEnabled: false,
                });
              }
            }}
          />
        )}
        {this.state.isViewTeamEnabled && (
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["View Team"]}
            onPressBack={() => {
              this.props.navigation.goBack();
            }}
          />
        )}
        {this.state.isViewTeamEnabled && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 30,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "75%",
              }}
            >
              <View style={{ flex: 2, marginTop: 5 }}>
                <FlatList
                  data={["Not Logged in Yet", "Logged in Once"]}
                  extraData={this.state.activeTab}
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={{
                    alignItems: "flex-start",
                    paddingHorizontal: 18,
                  }}
                  renderItem={({ item }) => {
                    let backgroundColor;
                    let color;
                    if (item == this.state.activeTab) {
                      backgroundColor = "#410DAA";
                      color = "#fff";
                    } else {
                      backgroundColor = "#fff";
                      color = "#121212";
                    }
                    return (
                      <this.SelectTabDetails
                        item={item}
                        backgroundColor={backgroundColor}
                        color={color}
                        onPress={() => {
                          this.setState({
                            activeTab: item,
                          });
                        }}
                      />
                    );
                  }}
                  keyExtractor={(item, index) => index}
                />
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text
                  onPress={() => {
                    this.setState({
                      isAddTeamEnabled: true,
                      isViewTeamEnabled: false,
                    });
                  }}
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: 15,
                    backgroundColor: "#410DAA",
                    color: "white",
                    borderColor: "#410DAA",
                    borderWidth: 1,
                    borderRadius: 4,
                    paddingTop: 12,
                    textAlign: "center",
                    marginHorizontal: 40,
                    width: 150,
                    height: 45,
                  }}
                >
                  Add Team
                </Text>
              </View>
            </View>
          </View>
        )}
        {this.state.showToast == true ? (
          <ShowToast
            showToast={this.state.showToast}
            type="status"
            title={this.state.toastMsg}
            duration="10"
          />
        ) : null}
        {this.state.showModal && (
          <Modal style={[isMobile ? styles.modalMobile : styles.modalWeb]}>
            <View
              style={{
                flexDirection: "column",
                paddingTop: 37,
                paddingBottom: 23,
                paddingHorizontal: 33,
                width: 628,
              }}
            >
              <Text style={styles.uploadMod}>Upload Team (xlsx)</Text>
              <Text style={styles.select}>
                Select teams excel file for upload. Supported Formats: .xls,
                .xlsx, .csv
              </Text>
              <Text style={styles.select}>Max. file size 1 Mb.</Text>
              <View style={styles.border}>
                <FlatList
                  data={filesData}
                  keyExtractor={(item) => item.imageName}
                  renderItem={this.renderFilesUploaded}
                />
              </View>
              {checkData != 0 ? (
                <Text style={styles.filePlease}>{fileError}</Text>
              ) : (
                <Text></Text>
              )}
              <View style={styles.two_buttns}>
                <TouchableOpacity
                  style={styles.cancelTouch}
                  onPress={() => {
                    this.setState({
                      showModal: false,
                      filesData: [],
                      fileError: "",
                    });
                  }}
                >
                  <Text style={[styles.cancel, { color: "#410DAA" }]}>
                    Cancel
                  </Text>
                </TouchableOpacity>
                {submitLoading ? (
                  <ActivityIndicator
                    size="small"
                    color="Black"
                    style={{ alignSelf: "center", marginLeft: 20 }}
                  />
                ) : (
                  <TouchableOpacity
                    style={styles.submitTouch}
                    onPress={this.handleFileUpload}
                  >
                    <Text
                      style={[
                        styles.cancel,
                        { fontWeight: "bold", color: "#FFFFFF" },
                      ]}
                    >
                      Upload
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </Modal>
        )}
        {!this.state.isAddTeamEnabled && !this.state.isViewTeamEnabled && (
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            style={styles.cardContainer}
            showsVerticalScrollIndicator={false}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  alignItems: "flex-start",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: "center",
                  width: "100%",
                  margin: 10,
                }}
              >
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <View>
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Donut
                        options={{
                          dataLabels: {
                            enabled: false,
                          },
                          legend: {
                            show: false,
                          },
                          tooltip: { enabled: false },
                          colors: ["#f78411", "#f5f3fb"],
                          plotOptions: {
                            pie: {
                              donut: {
                                size: "70%",
                              },
                            },
                          },
                          stroke: {
                            width: 1,
                            colors: ["#f78411", "#f5f3fb"],
                          },
                        }}
                        series={[
                          this.state.total_avg_perc,
                          100 - this.state.total_avg_perc,
                        ]}
                        sliceColor={["#f78411", "#f5f3fb"]}
                        type="donut"
                        width="170"
                      />
                      <Text
                        style={{
                          position: "absolute",
                          left: "45%",
                        }}
                      >
                        {this.state.total_avg_perc}%
                      </Text>
                    </View>
                    <Popable
                      style={{
                        minWidth: 200,
                        marginBottom: 20,
                      }}
                      position="right"
                      action={isMobile ? "press" : "hover"}
                      content={`Composite Index is calculated by giving 60% weight to Financial Wellness Index, 20% weight to Financial Plan Health and 20% weight to Financial Literacy`}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 12,
                            color: "#6C7293",
                            fontWeight: "600",
                            marginBottom: 2,
                          }}
                        >
                          Composite Index{" "}
                        </Text>
                        <Icon name="info-circle" size={14} color={"black"} />
                      </View>
                    </Popable>
                  </View>
                </View>
                <View
                  style={{
                    marginTop: isMobile ? 20 : 0,
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: isMobile ? 16 : 20,
                      color: "#464E5F",
                      fontWeight: "700",
                      padding: 10,
                    }}
                  >
                    Corporate Dashboard On Financial Wellness.
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ display: "flex", flexDirection: "column" }}>
              {this.state.getCorporateResp.map((item) => {
                return (
                  <TouchableOpacity
                    style={{
                      marginTop: 15,
                      marginLeft: "3%",
                      marginRight: "3%",
                      backgroundColor: "white",
                      width: isMobile ? "90%" : "60%",
                      borderWidth: 1,
                      borderRadius: 12,
                      borderColor: "rgba(65, 13, 170, 0.3)",
                      padding: 10,
                      marginBottom: 20,
                    }}
                    onPress={() => {
                      if (item.name == "Financial Wellness Index") {
                        this.props.navigation.navigate(
                          "FinancialWellnessIndex"
                        );
                      }
                      if (item.name == "Financial Literacy") {
                        this.props.navigation.navigate("EducationActivity");
                      }
                      if (item.name == "Financial Plan Health") {
                        this.props.navigation.navigate("FinancialPlanHealth");
                      }
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        marginLeft: "1.9%",
                        marginRight: "1.9%",
                        flexDirection: "row",
                        marginTop: "1%",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "column",
                          marginTop: "3.3%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            marginBottom: "4%",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 20,
                              color: "#464E5F",
                              fontWeight: "700",
                            }}
                          >
                            {item.name}
                          </Text>
                        </View>
                        <Text
                          style={{
                            fontSize: 17,
                            color: "#51514E",
                            fontWeight: "500",
                          }}
                        >
                          {item.description}
                        </Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          alignItems: "flex-end",
                          flexDirection: "column",
                          marginRight: "-2.5%",
                        }}
                      >
                        {Platform.OS == "web" ? (
                          <View
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Donut
                              options={{
                                dataLabels: {
                                  enabled: false,
                                },
                                legend: {
                                  show: false,
                                },
                                tooltip: { enabled: false },
                                colors: [
                                  item.name == "Financial Wellness Index"
                                    ? "#5197ed"
                                    : item.name == "Financial Literacy"
                                    ? "#F49611"
                                    : "#ad97db",
                                  "#f5f3fb",
                                ],
                                plotOptions: {
                                  pie: {
                                    donut: {
                                      size: "70%",
                                    },
                                  },
                                },
                                stroke: {
                                  width: 1,
                                  colors: [
                                    item.name == "Financial Wellness Index"
                                      ? "#5197ed"
                                      : item.name == "Financial Literacy"
                                      ? "#F49611"
                                      : "#ad97db",
                                    "#f5f3fb",
                                  ],
                                },
                              }}
                              series={[
                                parseInt(item.score_perc),
                                100 - parseInt(item.score_perc),
                              ]}
                              sliceColor={[
                                item.name == "Financial Wellness Index"
                                  ? "#5197ed"
                                  : item.name == "Financial Literacy"
                                  ? "#F49611"
                                  : "#ad97db",
                                "#f5f3fb",
                              ]}
                              type="donut"
                              width="170"
                            />
                            <Text
                              style={{
                                fontSize: 20,
                                color: "#464E5F",
                                fontWeight: "bold",
                                marginLeft: "-49%",
                              }}
                            >
                              {parseInt(item.score_perc)}%
                            </Text>
                          </View>
                        ) : (
                          <Donut
                            widthAndHeight={100}
                            series={[
                              parseInt(item.score_perc),
                              100 - parseInt(item.score_perc),
                            ]}
                            sliceColor={[
                              item.name == "Financial Wellness Index"
                                ? "#5197ed"
                                : item.name == "Financial Literacy"
                                ? "#F49611"
                                : "#ad97db",
                              "#f5f3fb",
                            ]}
                            doughnut={true}
                            coverRadius={0.6}
                            coverFill={"#FFF"}
                          />
                        )}
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          </ScrollView>
        )}
        {this.state.isAddTeamEnabled && (
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            style={styles.cardContainer}
            showsVerticalScrollIndicator={false}
          >
            <View style={{ display: "flex", flexDirection: "column" }}>
              <View
                style={{
                  marginTop: 15,
                  marginLeft: "3%",
                  marginRight: "3%",
                  backgroundColor: "white",
                  width: "94%",
                  borderWidth: 1,
                  borderRadius: 12,
                  borderColor: "rgba(65, 13, 170, 0.3)",
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1%",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        marginBottom: "4%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 20,
                          color: "#464E5F",
                          fontWeight: "500",
                          marginBottom: 30,
                        }}
                      >
                        Add your Team Members
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    width: "60%",
                    marginBottom: 30,
                    marginTop: -10,
                    justifyContent: "center",
                    marginLeft: "4%",
                  }}
                >
                  <TextInputField
                    value={
                      "https://www.maxiom.in/app/accounts/signup/?ref=MRXM743"
                    }
                    inputStyle={[{ paddingLeft: 20 }]}
                    style={[{ width: 450 }]}
                    editable={false}
                  />
                  <TouchableOpacity onPress={this.handleCopyText}>
                    <Image
                      source={{
                        uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/copy.png",
                      }}
                      style={{
                        width: 60,
                        height: 60,
                        marginTop: -10,
                        marginLeft: 20,
                        resizeMode: "contain",
                        color: "#410DAA",
                      }}
                    />
                  </TouchableOpacity>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 15 }}>
                  <Text
                    onPress={() => {
                      saveAs(
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/media/sample_uploaders/sample_data_for_customer_corporate.xlsx",
                        "sample_data_for_customer_corporate.xlsx"
                      );
                    }}
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      fontSize: 15,
                      color: "blue",
                      backgroundColor: "white",
                      paddingTop: 12,
                      textAlign: "center",
                      width: 150,
                      height: 45,
                      textDecorationLine: "underline",
                    }}
                  >
                    Download Sample
                  </Text>
                  <SelectContractNote
                    onSelect={(filesContent, errors) => {
                      if (filesContent && filesContent.length > 0) {
                        filesContent.map((file, index) => {
                          let fileType = file.name.split(".");
                          filesData.push({
                            imageUrl: file.content,
                            imageName: file.name,
                            imageSize: DataURIToBlob(file.content).size,
                            fileType: fileType[fileType.length - 1],
                          });
                        });
                        this.setState({ filesData, showModal: true });
                      }
                    }}
                  />
                </View>
              </View>
            </View>
          </ScrollView>
        )}
        {this.state.isViewTeamEnabled && (
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            style={styles.cardContainer}
            showsVerticalScrollIndicator={false}
          >
            <View style={styles.detailsContainer}>
              <View style={styles.headingContainer}>
                <SearchBar
                  value={this.state.searchTeamsText}
                  onChange={this.handleTeamsSearch}
                />
                <View
                  style={{
                    cursor: "pointer",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    marginRight: 20,
                  }}
                >
                  {/* <ExcelFile
                    filename={
                      this.state.userName +
                      " " +
                      "Team List " +
                      moment().format("DD-MM-YYYY hh-mm-ss A")
                    }
                    element={
                      <Text
                        style={{
                          color: "#410DAA",
                          fontWeight: "bold",
                          cursor: "pointer",
                          fontSize: 15,
                          textDecorationLine: "underline",
                        }}
                      >
                        Download As Excel
                      </Text>
                    }
                  >
                    <ExcelSheet data={excelTeamsData} name="Teams">
                      <ExcelColumn label="Name" value="name" />
                      <ExcelColumn label="Email" value="email" />
                      <ExcelColumn label="Employee Id" value="employeeId" />
                      <ExcelColumn label="Team" value="teamName" />
                      <ExcelColumn label="Manager Id" value="managerId" />
                    </ExcelSheet>
                  </ExcelFile> */}
                </View>
              </View>
              {this.state.activeTab === "Logged in Once" ? (
                valuesTeams.length === 0 ? (
                  <Text
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No User Was Logged In
                  </Text>
                ) : (
                  <TeamsTable
                    header={headerTeamsData}
                    gridConfig={[1, 1, 1, 1, 1]}
                    data={valuesTeams}
                    navigation={this.props.navigation}
                    pagination={valuesTeams.length > 10 ? true : false}
                  />
                )
              ) : valuesTeamNotLoggedIn.length === 0 ? (
                <Text
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: 20,
                    fontWeight: "800",
                  }}
                >
                  All users have logged in atleast once
                </Text>
              ) : (
                <TeamsTable
                  header={headerTeamsData}
                  gridConfig={[1, 1, 1, 1, 1]}
                  data={valuesTeamNotLoggedIn}
                  navigation={this.props.navigation}
                  pagination={valuesTeams.length > 10 ? true : false}
                />
              )}
            </View>
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    padding: 5,
  },
  modalContentContainer: {
    backgroundColor: "white",
    padding: 8,
    paddingRight: 0,
    borderRadius: 4,
    width: "100%",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  headerItemContainerStyle: {
    justifyContent: "flex-start",
  },
  rowItem: {
    textAlign: "left",
  },
  rowItemContainerStyle: {
    justifyContent: "flex-start",
    paddingLeft: 10,
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginLeft: "3%",
    marginBottom: 66,
    marginTop: 20,
    width: "94%",
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 16 : 19,
    paddingVertical: 19,
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "space-between",
  },
  headerStyle: { width: "100%", maxWidth: 1500 },
  inner: {
    paddingHorizontal: isMobile ? 16 : 61,
    paddingVertical: 21,
  },
  strip: {
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  two_btn: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: isMobile ? 10 : 0,
  },
  contract: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 21,
    color: "#212121",
  },
  your: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 18,
    color: "#B5B5C3",
  },
  upload: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#410DAA",
    paddingLeft: 7,
  },
  bord: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    paddingHorizontal: 15,
    paddingVertical: 7,
    marginRight: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  headerItemContainerStyle: {
    justifyContent: "center",
  },
  rowItem: {
    textAlign: "left",
    fontSize: 13,
  },
  rowItemContainerStyle: {
    justifyContent: "center",
    paddingLeft: 10,
  },
  linkStyle: {
    color: "#000000",
  },
  subtext: {
    color: "#8950FC",
    letterSpacing: 0.4,
    fontSize: 13,
    lineHeight: 15,
    fontFamily: "Roboto",
    fontStyle: "italic",
  },
  inptxt: {
    //outlineWidth: 0,
    //placeholderTextColor: "#B5B5C3",
    fontFamily: "Roboto",
    fontSize: 16,
  },
  inptBorder: {
    marginBottom: 15,
    paddingVertical: 10,
    paddingHorizontal: 25,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
  },
  touch: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
    paddingVertical: 6,
    paddingHorizontal: 15,
    borderRadius: 4,
  },
  file: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 12 : 16,
    lineHeight: 18,
    color: "#410DAA",
    paddingLeft: 5,
  },
  support: {
    fontSize: 10,
    color: "#121212",
    fontStyle: "italic",
    marginTop: 4,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  viewDetails: {
    color: "#410DAA",
  },
  modalMobile: {
    position: "absolute",
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  modalWeb: {
    position: "absolute",
    // position: "absolute",
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  border: {
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.3)",
    width: 560,
    height: 200,
    paddingHorizontal: 14,
    paddingVertical: 10,
  },
  filePlease: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#EF5D12",
    paddingVertical: 5,
  },
  uploadMod: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    color: "#009105",
    fontWeight: "bold",
    paddingBottom: 10,
  },
  select: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 21,
    color: "#121212",
  },
  flat: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cancel: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
  },
  cancelTouch: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#410DAA",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    paddingHorizontal: 45,
  },
  submitTouch: {
    backgroundColor: "#410DAA",
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    paddingHorizontal: 45,
    borderRadius: 4,
    marginLeft: 22,
  },
  two_buttns: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 22,
  },
  imageName: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "rgba(18, 18, 18, 0.5)",
    letterSpacing: 0.16,
    paddingLeft: 5,
  },
  mainError: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#EF5D12",
    textAlign: "center",
  },
});

export default CorporateDashboard;
