import {SAVE_USER_PROFILE_DATA,LOGOUT,CONNECTION_STATUS_CHANGE, LOGIN, SAVE_SCREEN_CONFIG} from './types';

export const logOut = () => ({
  type: LOGOUT
})

export const logIn = (time) => ({
  type: LOGIN,
  payload:{
    lastLoggedInTime: time
  }
})
// action for saving user address
export const saveUserProfileData = data => ({
  type: SAVE_USER_PROFILE_DATA,
  payload: data,
});

export const saveScreenConfig = data => ({
  type: SAVE_SCREEN_CONFIG,
  payload: data,
});

//  action for handling connection status
export const handleConnectionStatus = (isConnected) => ({
  type: CONNECTION_STATUS_CHANGE,
  payload: {
    isConnected
  }
});