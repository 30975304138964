import React from "react";
import { View, Text, ActivityIndicator, Platform } from "react-native";
import DeviceInfo from "react-native-device-info";
import amplitude from "../../utils/amplitude";

export default class DownloadApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detectedOS: null,
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("DownloadApp",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("DownloadApp", { path: this.props.navigation.getState().routeName });
    }
    let os = await DeviceInfo.getBaseOs();
    this.setState({ detectedOS: os });
    setTimeout(() => {
      if (os == "Android") {
        location.replace(
          "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=in.jama.app",
          "_self"
        );
      } else {
        location.replace(
          "https://apps.apple.com/in/app/jama-wealth-advisory/id6443719443",
          "_self"
        );
      }
    }, 3000);
  }

  render() {
    return (
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "22%",
        }}
      >
        <ActivityIndicator size="large" color="#410DAA" />
        <Text style={{ marginTop: 30, fontWeight: "bold", fontSize: 17 }}>
          {this.state.detectedOS == "Android"
            ? "Redirecting to Play Store"
            : "Redirecting to App Store"}
        </Text>
      </View>
    );
  }
}
