import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Keyboard,
  Platform,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
} from "react-native";
import { md } from "../../../Responsive";
import {
  getLoginBannerDetails,
  onboardingSendOTP,
  mobileOtpVerificationBeforeSignup,
  SignUpUser,
} from "../../../api";
import { saveUserProfileData, logIn } from "../../../actions";
import { connect } from "react-redux";
import Header from "../login_header";
const { width } = Dimensions.get("window");
import { isMobile, isWeb } from "../../../utils";
import { ShowToast } from "../../../components/Common/Toast";
import amplitude from "../../../utils/amplitude";
import { Popable } from "react-native-popable";
import { AntDesign, Feather } from "@expo/vector-icons";

const PasswordPopable = () => {
  return (
    <View>
      <Popable
        action={isMobile ? "press" : "hover"}
        content={
          <View style={{ padding: 10 }}>
            <View style={styles.popableContainer}>
              <Text style={{ color: "#fff" }}>
                {"  "}&#x2022;{"  "}
              </Text>
              <Text style={{ color: "#fff" }}>At least 8 characters</Text>
            </View>
            <View style={styles.popableContainer}>
              <Text style={{ color: "#fff" }}>
                {"  "}&#x2022;{"  "}
              </Text>
              <Text style={{ color: "#fff" }}>
                At least one lowercase letter
              </Text>
            </View>
            <View style={styles.popableContainer}>
              <Text style={{ color: "#fff" }}>
                {"  "}&#x2022;{"  "}
              </Text>
              <Text style={{ color: "#fff" }}>
                At least one uppercase letter
              </Text>
            </View>
            <View style={styles.popableContainer}>
              <Text style={{ color: "#fff" }}>
                {"  "}&#x2022;{"  "}
              </Text>
              <Text style={{ color: "#fff" }}>At least one digit</Text>
            </View>
            <View style={styles.popableContainer}>
              <Text style={{ color: "#fff" }}>
                {"  "}&#x2022;{"  "}
              </Text>
              <Text style={{ color: "#fff" }}>
                At least one special character (@$!%*?&)
              </Text>
            </View>
          </View>
        }
        position="top"
        style={{ minWidth: 300 }}
      >
        <AntDesign
          name="infocirlce"
          size={12}
          color="#12121280"
          style={{ paddingLeft: 5, paddingTop: 0 }}
        />
      </Popable>
    </View>
  );
};

class LoginVerification extends React.Component {
  constructor(props) {
    super(props);

    let username = null;
    let signup_data = null;
    let from_signup = false;
    let is_club = false;
    let is_preferred_circle = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    const { params } = this.props.route;
    if (params) {
      username = params.username ? params.username : null;
      signup_data = params.data ? params.data : null;
      from_signup = params.from_signup ? params.from_signup : false;
      is_club = params.is_club ? params.is_club : false;
    }
    this.state = {
      data: null,
      isLoading: false,
      username,
      errorText: "",
      otpError: "",
      bannerData: {
        logo_image_url: null,
        name: null,
        ria_code: null,
        subscriber_type: null,
        support_email: null,
        support_person_name: null,
        support_phone_number: null,
        is_jamawealth: null,
      },
      is_ria: false,
      signup_data,
      from_signup,
      showConfirmPassword: false,
      showPassword: false,
      otp_verification_flag: false,
      password: "",
      repassword: "",
      passwordError: "",
      repasswordError: "",
      is_club,
      is_preferred_circle,
    };
  }

  componentDidMount = async () => {
    let { bannerData } = this.state;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    // const payload = {
    //   ria_name: hostname,
    // };
    let response = null;
    let is_ria = false;
    if (hostname.includes("simplygrow.in")) {
      is_ria = true;
    }
    try {
      response = await getLoginBannerDetails();
    } catch (err) {}
    if (response && response.code == 100) {
      bannerData = response.result;
      this.setState({ bannerData: bannerData, is_ria: is_ria });
    } else {
      bannerData.logo_image_url =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/website/jam%C4%81_header.png";
      bannerData.name = "Jama Wealth";
      bannerData.ria_code = "INA 200015583";
      bannerData.subscriber_type = "RIAC";
      bannerData.support_email = "advisor@jamawealth.com";
      bannerData.support_person_name = "Ram Kalyan Medury";
      bannerData.support_phone_number = "9870264643";
      bannerData.is_jamawealth = true;
      this.setState({
        bannerData: bannerData,
        errorText: response.msg,
        is_ria: is_ria,
      });
    }
  };

  handleContinue = async () => {
    const {
      username,
      otpNumber,
      bannerData,
      is_ria,
      from_signup,
      signup_data,
      password,
      repassword,
      is_club,
      is_preferred_circle,
    } = this.state;
    this.setState({ isLoading: true });
    try {
      if (!otpNumber) {
        this.setState({
          otpError: "Please enter a valid 6 digit otp",
          isLoading: false,
          errorText: "",
          passwordError: "",
          repasswordError: "",
        });
        return;
      } else {
        this.setState({
          otpError: "",
        });
      }

      if (from_signup) {
        let password_regEx =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (!password) {
          this.setState({
            passwordError: "Please enter a password",
            isLoading: false,
            repasswordError: "",
            errorText: "",
          });
          return;
        } else if (!password_regEx.test(password)) {
          this.setState({
            passwordError:
              "Entered Password does not match the given requirements",
            isLoading: false,
            repasswordError: "",
            errorText: "",
          });
          return;
        } else {
          this.setState({
            passwordError: "",
          });
        }

        if (!repassword || password != repassword) {
          this.setState({
            repasswordError: "Entered passwords did not match",
            isLoading: false,
            nameError: "",
            emailError: "",
            mobileError: "",
            passwordError: "",
            errorText: "",
          });
          return;
        } else if (!password_regEx.test(repassword)) {
          this.setState({
            passwordError:
              "Re-Entered Password does not match the given requirements",
            isLoading: false,
            nameError: "",
            emailError: "",
            mobileError: "",
            repasswordError: "",
            errorText: "",
          });
          return;
        } else {
          this.setState({
            repasswordError: "",
          });
        }
      }

      if (from_signup) {
        let getOtpPayload = {
          target_mobile_number: username.toString(),
          otp_value: otpNumber.toString(),
        };

        const mobileOtpResp = await mobileOtpVerificationBeforeSignup(
          getOtpPayload
        );

        if (mobileOtpResp && mobileOtpResp.result) {
          let signupPayload = {
            ...signup_data,
            password: password.toString(),
            otp: otpNumber.toString(),
            database_host: signup_data.corp_code
              ? "corporate_jama"
              : global.location.hostname.replace(/\./g, "_"),
          };
          const response = await SignUpUser(signupPayload);
          amplitude.setUserId(username);
          const eventProperties = {
            username: signup_data.first_name,
            mobile: username,
            email: signup_data.email,
          };
          amplitude.logEvent("Signup_Successful", eventProperties);
          if (response && response.pk != null) {
            let data = { ...response };
            data = Object.assign(data, {
              is_ria: is_ria,
              bannerData: bannerData,
              is_club: is_club,
              is_preferred_circle: is_preferred_circle,
            });
            const loggedInTime = new Date().getTime();
            this.props.logIn(loggedInTime);
            this.props.saveUserProfileData(data);
            if (is_preferred_circle) {
              let url = new URL(global.location.href);
              location.replace(url.origin + "/onboarding/handSignature");
            }
          } else {
            let error = null;
            if (response.length) {
              error = response[0];
            }
            this.setState({
              error: true,
              errorText:
                response && response.msg
                  ? response.msg
                  : error || "Please enter the correct otp",
              isLoading: false,
            });
          }
        } else {
          this.setState({
            error: true,
            errorText:
              mobileOtpResp && mobileOtpResp.message
                ? mobileOtpResp.message
                : "Please enter the correct otp",
            isLoading: false,
          });
        }
      } else {
        const response = await onboardingSendOTP({
          username: username.toString(),
          otp: otpNumber.toString(),
          source: "IOS",
        });

        if (response && response.pk != null) {
          let data = { ...response };
          data = Object.assign(data, {
            is_ria: is_ria,
            bannerData: bannerData,
          });
          const loggedInTime = new Date().getTime();
          this.props.logIn(loggedInTime);
          this.props.saveUserProfileData(data);
          this.setState({ isLoading: false }, () => {
            this.props.navigation.navigate("EquityPlans");
          });
        } else {
          let error = null;
          if (response.length) {
            error = response[0];
          }
          this.setState({
            error: true,
            errorText:
              response && response.msg
                ? response.msg
                : error || "Please enter the correct otp",
            isLoading: false,
          });
        }
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    }
  };

  handleResend = async () => {
    this.setState({ showOTP: false });
    const { username, from_signup } = this.state;

    if (from_signup) {
      let getOtpPayload = {
        target_mobile_number: username,
      };
      const mobileOtpResp = await mobileOtpVerificationBeforeSignup(
        getOtpPayload
      );
      if (
        mobileOtpResp &&
        mobileOtpResp.resp_code &&
        mobileOtpResp.resp_code == "100"
      ) {
        this.setState({ showOTP: true });
      }
    } else {
      const data = {
        username: username,
      };
      const response = await onboardingSendOTP(data);
      if (response && response.otp_generated_date) {
        this.setState({ showOTP: true });
      }
    }
  };

  render() {
    const {
      isLoading,
      errorText,
      otpNumber,
      otpError,
      username,
      bannerData,
      is_ria,
      from_signup,
      showPassword,
      showConfirmPassword,
      otp_verification_flag,
      password,
      repassword,
      passwordError,
      repasswordError,
    } = this.state;
    return (
      <View style={styles.container}>
        <Header
          navigation={this.props.navigation}
          route={this.props.route}
          bannerData={bannerData}
        />
        {this.state.showOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent successfully."
          />
        ) : null}
        <ScrollView keyboardShouldPersistTaps="always">
          <View style={styles.body}>
            {isWeb && (
              <View style={styles.left}>
                <Text style={styles.best}>
                  Invest like the <Text style={{ color: "#410DAA" }}>Best</Text>
                </Text>
                <Image
                  source={
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Invest+like+the+best.png"
                  }
                  style={styles.left_logo}
                ></Image>
                <View style={styles.certicationsContainer}>
                  {bannerData.is_jamawealth ? (
                    <View style={styles.certication}>
                      <Image
                        source={
                          "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+11.png"
                        }
                        style={{ width: 18, height: 30 }}
                      />
                      <Text style={[styles.sebi, { paddingLeft: 10 }]}>
                        Goi{" "}
                        <Text style={{ fontWeight: "bold", color: "black" }}>
                          DIPP
                        </Text>{" "}
                        Registered
                      </Text>
                    </View>
                  ) : null}
                  {bannerData.is_jamawealth ? (
                    <View style={styles.certication}>
                      <Image
                        source={
                          "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+7.png"
                        }
                        style={{ width: 29, height: 25 }}
                      />
                      <Text style={[styles.sebi, { paddingLeft: 10.17 }]}>
                        ISO{" "}
                        <Text style={{ fontWeight: "bold", color: "black" }}>
                          9001
                        </Text>{" "}
                        Certified
                      </Text>
                    </View>
                  ) : null}
                  {bannerData.is_jamawealth ? (
                    <View style={styles.certication}>
                      <Image
                        source={
                          "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+7.png"
                        }
                        style={{ width: 29, height: 25 }}
                      />
                      <Text style={[styles.sebi, { paddingLeft: 10.17 }]}>
                        ISO{" "}
                        <Text style={{ fontWeight: "bold", color: "black" }}>
                          27001
                        </Text>{" "}
                        Certified
                      </Text>
                    </View>
                  ) : null}
                  <View style={styles.sebiContainer}>
                    <View style={{ paddingLeft: 0.4 }}>
                      <Text style={[styles.sebi]}>
                        SEBI Regd Investment Advisor
                      </Text>
                      <Text style={styles.ina}>{bannerData.ria_code}</Text>
                      <Text style={[styles.sebi_1]}>
                        Simply Grow Technologies Pvt Ltd.
                      </Text>
                    </View>
                  </View>
                  <View style={styles.sebiContainer}>
                    <View style={{ paddingLeft: 10.4 }}>
                      <Text style={[styles.sebi]}>
                        SEBI Registered Portfolio Manager
                      </Text>
                      <Text style={styles.ina}>INP000007881</Text>
                      <Text style={[styles.sebi_1]}>
                        Jama Wealth Asset Management Pvt Ltd.{" "}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
            <View style={styles.right}>
              <View style={[isMobile ? styles.mob_styl : styles.web_styl]}>
                <View style={{ alignItems: "center", marginTop: 32 }}>
                  {bannerData.is_jamawealth ? (
                    <Image
                      source={require("../../../assets/jama_logo.png")}
                      style={{ width: 80 , height: 80 }}
                      resizeMode={"contain"}
                    />
                  ) : null}
                </View>
                <View style={styles.inputsContainer}>
                  <View style={styles.inputContainer}>
                    <Text style={styles.mob_num}>Enter OTP</Text>
                    <TextInput
                      value={otpNumber}
                      style={styles.dmart_body_md}
                      placeholder={`Enter OTP sent on ${username}`}
                      onFocus={() => {
                        this.setState({
                          otpError: "",
                          errorText: "",
                        });
                      }}
                      onChangeText={(text) => {
                        const pattern = /^[0-9\b]+$/;
                        if (text.length <= 6 && pattern.test(text)) {
                          this.setState({ otpNumber: text });
                        } else {
                          text = text.slice(0, -1);
                          this.setState({ otpNumber: text });
                        }
                      }}
                      maxLength={6}
                      onKeyPress={(e) => {
                        if (e.nativeEvent.key == "Enter") {
                          this.handleContinue();
                        }
                      }}
                    />
                    {!!otpError && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {otpError || " "}
                      </Text>
                    )}
                  </View>
                  {!!errorText && (
                    <Text style={[styles.please, { color: "red" }]}>
                      {errorText || " "}
                    </Text>
                  )}
                </View>
                {from_signup ? (
                  <>
                    <View style={styles.inputsContainer}>
                      <View style={styles.inputContainer}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Text style={styles.mob_num}>Password</Text>
                          <PasswordPopable />
                        </View>
                        <View>
                          <TextInput
                            value={password}
                            style={styles.dmart_body_md}
                            placeholder={"Enter your password"}
                            onFocus={() => {
                              this.setState({
                                nameError: "",
                                emailError: "",
                                mobileError: "",
                                passwordError: "",
                                repasswordError: "",
                                errorText: "",
                              });
                            }}
                            onChangeText={(text) => {
                              this.setState({ password: text });
                            }}
                            onKeyPress={(e) => {
                              if (e.nativeEvent.key == "Enter") {
                                this.handleContinue();
                              }
                            }}
                            secureTextEntry={!showPassword}
                          />
                          <Feather
                            name={!showPassword ? "eye-off" : "eye"}
                            color={!showPassword ? "gray" : "#410DAA"}
                            size={18}
                            style={{
                              position: "absolute",
                              right: 5,
                              bottom: 9,
                            }}
                            onPress={() => {
                              this.setState({
                                showPassword: !showPassword,
                              });
                            }}
                          />
                        </View>
                        {!!passwordError && (
                          <Text style={[styles.please, { color: "red" }]}>
                            {passwordError || " "}
                          </Text>
                        )}
                      </View>
                    </View>
                    <View style={styles.inputsContainer}>
                      <View style={styles.inputContainer}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Text style={styles.mob_num}>Confirm Password</Text>
                          <PasswordPopable />
                        </View>
                        <View>
                          <TextInput
                            value={repassword}
                            style={styles.dmart_body_md}
                            placeholder={"Reenter your password"}
                            onFocus={() => {
                              this.setState({
                                nameError: "",
                                emailError: "",
                                mobileError: "",
                                passwordError: "",
                                repasswordError: "",
                                errorText: "",
                              });
                            }}
                            onChangeText={(text) => {
                              this.setState({ repassword: text });
                            }}
                            onKeyPress={(e) => {
                              if (e.nativeEvent.key == "Enter") {
                                this.handleContinue();
                              }
                            }}
                            secureTextEntry={!showConfirmPassword}
                          />
                          <Feather
                            name={!showConfirmPassword ? "eye-off" : "eye"}
                            color={!showConfirmPassword ? "gray" : "#410DAA"}
                            size={18}
                            style={{
                              position: "absolute",
                              right: 5,
                              bottom: 9,
                            }}
                            onPress={() => {
                              this.setState({
                                showConfirmPassword: !showConfirmPassword,
                              });
                            }}
                          />
                        </View>
                        {!!repasswordError && (
                          <Text style={[styles.please, { color: "red" }]}>
                            {repasswordError || " "}
                          </Text>
                        )}
                      </View>
                    </View>
                  </>
                ) : null}
                {isLoading ? (
                  <ActivityIndicator
                    size="large"
                    color="#410DAA"
                    style={{ paddingTop: 40 }}
                  />
                ) : (
                  <>
                    <TouchableOpacity
                      onPress={() => {
                        Keyboard.dismiss();
                        this.handleContinue();
                      }}
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: isMobile ? 20 : 0,
                      }}
                    >
                      <Text style={styles.req_otp}>Verify OTP</Text>
                    </TouchableOpacity>
                    <Text style={[styles.forgotPassword]}>
                      Did not receive an OTP?{" "}
                      <Text
                        style={styles.clickHere}
                        onPress={() => {
                          this.handleResend();
                        }}
                      >
                        RESEND OTP
                      </Text>
                    </Text>
                  </>
                )}
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: "#fff",
  },
  body: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  left: {
    flex: md(width) ? 1 : 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#410DAA08",
    paddingVertical: md(width) ? 75 : 1,
  },
  right: {
    flex: 1,
  },
  mob_styl: {
    width: "80%",
    alignSelf: "center",
  },
  web_styl: {
    alignItems: "center",
  },
  best: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 33,
    lineHeight: 38,
    color: "#464E5F",
    paddingBottom: 100,
  },
  left_logo: {
    resizeMode: "contain",
    width: 403,
    height: 241,
    alignSelf: "center",
    marginBottom: 60,
  },
  mobile_verf: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 19 : 15,
    lineHeight: md(width) ? 22 : 17,
    textAlign: "center",
    color: "#121212",
    paddingTop: md(width) ? 0 : 50,
  },
  we_will: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 17 : 13,
    lineHeight: md(width) ? 20 : 18,
    textAlign: "justify",
    color: "#A7A8BB",
    paddingTop: md(width) ? 19 : 29,
  },
  mob_num: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 18,
    color: "#222225",
    paddingBottom: md(width) ? 23 : 10.5,
  },
  please: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3,
    marginBottom: 15,
    textAlign: "center",
  },
  req_otp: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 47,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  one: {
    color: "#121212",
  },
  dmart_body_md: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "bold",
    color: "#121212",
    backgroundColor: "#f6f6f6",
    height: 41.02,
    paddingLeft: 20,
    borderRadius: 4,
  },
  sebi: {
    fontFamily: "Roboto",
    fontWeight: "600",
    fontSize: 10,
    lineHeight: 14,
    color: "#6C7293",
    paddingRight: 29,
  },
  ina: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 10,
    lineHeight: 14,
    color: "#410DAA",
  },
  img1: {
    width: 30,
    height: 28.6,
  },
  welcomeTitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    color: "#202020",
    fontWeight: "bold",
    marginBottom: 45,
    marginTop: 10,
    textAlign: "center",
  },
  inputsContainer: {
    minWidth: "75%",
  },
  inputContainer: {
    marginBottom: 37,
  },
  forgotPassword: {
    color: "#232425",
    fontSize: 15,
    fontFamily: "Roboto",
    marginTop: 18,
    textAlign: "center",
  },
  clickHere: {
    color: "#410DAA",
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 6,
    textAlign: "center",
  },
  certication: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 25,
  },
  sebiContainer: {
    flexDirection: "row",
    paddingTop: 25,
  },
  certicationsContainer: {
    flexDirection: "row",
    paddingLeft: 30.5,
    paddingTop: 65,
    flexWrap: "wrap",
    justifyContent: "center",
  },
});

const mapStateToProps = (state) => {
  return {
    userProfileData: state.userData.userProfileData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveUserProfileData: (data) => dispatch(saveUserProfileData(data)),
  logIn: (time) => dispatch(logIn(time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginVerification);
