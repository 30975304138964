import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  TouchableOpacity,
  Image,
} from "react-native";
import Divider from "../../../components/Common/divider";
import { isMobile, numberWithCommas, removeString } from "../../../utils";
import CommaTextField from "./commaTextField";
import Checkbox from "expo-checkbox";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;
import moment from "moment";
import { Popable } from "react-native-popable";
import Icon from "@expo/vector-icons/FontAwesome";

export const InvestGoalCard = ({
  data,
  index,
  sipArr,
  lumpsumArr,
  sipApply,
  lumpsumApply,
  sipArrToShow,
  lumpsumArrToShow,
  revisedProjectionArr,
  selectedCheckboxs,
  onSIPTextChange,
  onLumpsumTextChange,
  updateAllCheckboxs,
  projectedArrToShow,
  updateSelectedCheckboxs,
}) => {
  let goalName = data["name"];
  let projected = data["projected_value"];
  let achieved = data["achieved_value"];
  let monthlyask = Math.round(data["calculated_monthly_installment"]);
  let current = Math.round(data["current_sip_contibution"]);
  let total = Math.round(data["target_amount_initially_calculated"]);
  let image = data["goal_avatar"];
  let dueDate = data["target_date_text"];
  let amountWhenDue = data["target_amount_initially_calculated"];

  let currentDate = moment();
  let targetDate = moment(data.target_date_text);
  let months =
    (targetDate.format("YYYY") - currentDate.format("YYYY")) * 12 +
    (targetDate.format("MM") - currentDate.format("MM"));
  let years = targetDate.diff(currentDate, "years");
  let totalNew = total;
  if (totalNew >= 10000000) {
    totalNew = (totalNew / 10000000).toFixed(1) + " Cr";
  }
  if (totalNew >= 100000) {
    totalNew = (totalNew / 100000).toFixed(1) + " L";
  }
  if (totalNew >= 1000) {
    totalNew = (totalNew / 1000).toFixed(1) + " K";
  }

  let monthlyAskNew = monthlyask;
  if (monthlyAskNew >= 10000000) {
    monthlyAskNew = (monthlyAskNew / 10000000).toFixed(1) + " Cr";
  }
  if (monthlyAskNew >= 100000) {
    monthlyAskNew = (monthlyAskNew / 100000).toFixed(1) + " L";
  }
  if (monthlyAskNew >= 1000) {
    monthlyAskNew = (monthlyAskNew / 1000).toFixed(1) + " K";
  }

  let currentNew = current;
  if (currentNew >= 10000000) {
    currentNew = (currentNew / 10000000).toFixed(1) + " Cr";
  }
  if (currentNew >= 100000) {
    currentNew = (currentNew / 100000).toFixed(1) + " L";
  }
  if (currentNew >= 1000) {
    currentNew = (currentNew / 1000).toFixed(1) + " K";
  }

  let achievedNew = achieved;
  if (achievedNew >= 10000000) {
    achievedNew = (achievedNew / 10000000).toFixed(1) + " Cr";
  }
  if (achievedNew >= 100000) {
    achievedNew = (achievedNew / 100000).toFixed(1) + " L";
  }
  if (achievedNew >= 1000) {
    achievedNew = (achievedNew / 1000).toFixed(1) + " K";
  }

  let projectedNew = projected;
  if (projectedNew >= 10000000) {
    projectedNew = (projectedNew / 10000000).toFixed(1) + " Cr";
  }
  if (projectedNew >= 100000) {
    projectedNew = (projectedNew / 100000).toFixed(1) + " L";
  }
  if (projectedNew >= 1000) {
    projectedNew = (projectedNew / 1000).toFixed(1) + " K";
  }
  const isDeficit = total > projected;
  const deficitOrSurplus = isDeficit ? total - projected : projected - total;
  let deficitOrSurplusNew = deficitOrSurplus;
  if (deficitOrSurplusNew >= 10000000) {
    deficitOrSurplusNew = (deficitOrSurplusNew / 10000000).toFixed(1) + " Cr";
  }
  if (deficitOrSurplusNew >= 100000) {
    deficitOrSurplusNew = (deficitOrSurplusNew / 100000).toFixed(1) + " L";
  }
  if (deficitOrSurplusNew >= 1000) {
    deficitOrSurplusNew = (deficitOrSurplusNew / 1000).toFixed(1) + " K";
  }
  let currentRatioPer = 0;
  let monthlyRatioPer = 0;
  if (current == 0 && monthlyask == 0) {
    currentRatioPer = 0.5;
    monthlyRatioPer = 0.5;
  } else {
    currentRatioPer = current / (current + monthlyask);
    monthlyRatioPer = monthlyask / (current + monthlyask);
  }
  currentRatioPer = parseFloat(currentRatioPer.toFixed(2));
  monthlyRatioPer = parseFloat(monthlyRatioPer.toFixed(2));

  return (
    <>
      <View
        style={{
          borderColor: "#410DAA33",
          borderWidth: 1,
          borderRadius: 6,
          paddingTop: 5,
          paddingBottom: 13,
          marginBottom: 20,
          ...styles.container,
        }}
      >
        <View style={styles.basicDetails}>
          <View style={styles.goalNameContainer}>
            <Checkbox
              status={selectedCheckboxs[index].toShow ? "checked" : "unchecked"}
              color={selectedCheckboxs[index].toShow ? "#410DAA" : "gray"}
              value={selectedCheckboxs[index].toShow}
              onValueChange={() => {
                let checkbox = selectedCheckboxs;
                if (checkbox[index]) {
                  updateAllCheckboxs(false);
                }
                checkbox[index].toShow = !checkbox[index].toShow;
                updateSelectedCheckboxs(checkbox);
              }}
            />
            <View style={styles.goalName}>
              <Image
                style={styles.goalIcon}
                source={{
                  uri: image,
                }}
              />
            </View>
          </View>
          <View style={styles.fundContainer}>
            <Text style={styles.fund}>
              {removeString(goalName, "Goal", "goal")} {"  "}
              <Popable
                position={"right"}
                action={isMobile ? "press" : "hover"}
                style={{ width: 300 }}
                content={
                  <View style={{ backgroundColor: "black" }}>
                    <Text
                      style={{
                        textAlign: "left",
                        color: "white",
                        padding: 10,
                        fontWeight: 400,
                        fontSize: 14,
                      }}
                    >
                      Jama calculates lump-sum or sip suggestions based on goal
                      requirement.{"\n\n"}Tap{" "}
                      <Text style={{ fontWeight: 700 }}>Apply</Text> to use
                      suggestions. Tap
                      <Text style={{ fontWeight: 700 }}> Edit</Text> to modify.
                      {"  "}
                    </Text>
                  </View>
                }
              >
                <Icon name="info-circle" size={16} color={"black"} />
              </Popable>
            </Text>
            <View style={{ flexDirection: "row", marginTop: 5 }}>
              <Text style={styles.date}>Due Date: </Text>
              <Text
                style={{
                  fontSize: 13,
                  color: "#121212",
                  fontWeight: "bold",
                }}
              >
                {dueDate}
              </Text>
            </View>
          </View>
        </View>
        <Divider width={1} color="#410DAA33" style={{ marginVertical: 7 }} />
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: 10,
            width: "100%",
            paddingHorizontal: 15,
            marginBottom: 2,
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "column",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "7%",
              }}
            >
              <View style={{ flexDirection: "column" }}>
                <Text style={{ color: "#464E5F" }}>
                  Required when due{"  "}
                </Text>
                <Text style={{ marginTop: 25, color: "#464E5F" }}>
                  Achieved
                </Text>
                <Text style={{ marginTop: 25, color: "#464E5F" }}>
                  Projected
                </Text>
              </View>
              <View style={{ flexDirection: "column" }}>
                <Text style={{ fontWeight: "700", color: "#464E5F" }}>
                  ₹ {numberWithCommas(parseInt(amountWhenDue))}
                </Text>
                <Text
                  style={{
                    fontWeight: "700",
                    color: "#464E5F",
                    marginTop: 25,
                    textAlign: "right",
                  }}
                >
                  {achieved ? `₹ ${numberWithCommas(parseInt(achieved))}` : "-"}
                </Text>
                <Text
                  style={{
                    fontWeight: "700",
                    color: "#464E5F",
                    marginTop: 25,
                    textAlign: "right",
                  }}
                >
                  {projected
                    ? `₹ ${numberWithCommas(parseInt(projected))}`
                    : "-"}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "right",
                }}
              >
                {Platform.OS == "web" ? (
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginTop: -5,
                    }}
                  >
                    <Donut
                      options={{
                        dataLabels: {
                          enabled: false,
                        },
                        legend: {
                          show: false,
                        },
                        tooltip: { enabled: false },
                        colors: ["#876CDD", "#BAC7F2"],
                        plotOptions: {
                          pie: {
                            donut: {
                              size: "60%",
                            },
                          },
                        },
                        stroke: {
                          width: 1,
                          colors: ["#876CDD", "#BAC7F2"],
                        },
                      }}
                      series={[
                        data.projected_percentage,
                        100 - data.projected_percentage,
                      ]}
                      sliceColor={["#876CDD", "#BAC7F2"]}
                      type="donut"
                      width="125"
                    />
                    <Text
                      style={{
                        marginTop: "1%",
                        fontWeight: "bold",
                        fontSize: 16,
                        color: "#464E5F",
                        marginLeft: 10,
                      }}
                    >
                      {data.projected_percentage}%
                    </Text>
                    <Text
                      style={{
                        marginTop: "1%",
                        fontWeight: 600,
                        color: "#464E5F",
                      }}
                    >
                      Projected
                    </Text>
                  </View>
                ) : (
                  <Donut
                    widthAndHeight={100}
                    series={[72, 28]}
                    sliceColor={["#876CDD", "#BAC7F2"]}
                    doughnut={true}
                    coverRadius={0.6}
                    coverFill={"#FFF"}
                  />
                )}
              </View>
            </View>
          </View>
          <View
            style={{
              width: 1,
              height: "auto",
              marginRight: 20,
              marginLeft: 20,
              backgroundColor: "#888C9F66",
            }}
          />
          <View
            style={{
              flex: 1.2,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data.deficitOrSurplus == "Surplus" ? (
              <Text
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                N.A.
              </Text>
            ) : (
              <View style={{ flexDirection: "column" }}>
                {lumpsumArr[index] >= 0 ? (
                  <>
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          fontWeight: "600",
                          color: "#464E5F",
                          textAlign: "left",
                        }}
                      >
                        Lumpsum Suggestion: ₹{" "}
                        {numberWithCommas(parseInt(lumpsumArr[index]))}
                        {"   "}
                      </Text>
                      <TouchableOpacity
                        style={{}}
                        onPress={() => {
                          lumpsumApply(index, years, months);
                        }}
                      >
                        <Text
                          style={{
                            color: "#410DAA",
                            cursor: "pointer",
                            fontSize: 14,
                            textAlign: "center",
                            textDecorationLine: "underline",
                          }}
                        >
                          Apply
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "600",
                          color: "#6b717f",
                          textAlign: "left",
                          fontSize: 13,
                        }}
                      >
                        My Lumpsum
                      </Text>
                      <CommaTextField
                        value={
                          lumpsumArrToShow[index] ? lumpsumArrToShow[index] : ""
                        }
                        onValueChange={(text) => {
                          if (!isNaN(text)) {
                            onLumpsumTextChange(text, years, months);
                          }
                        }}
                        containerStyle={{
                          width: "40%",
                          marginLeft: "5%",
                          marginRight: "5.5%",
                        }}
                        textStyle={{
                          fontSize: 14,
                          paddingVertical: 10,
                          paddingHorizontal: 5,
                        }}
                      />
                    </View>
                  </>
                ) : (
                  <View style={{ marginTop: "5%" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "600",
                          color: "#6b717f",
                          textAlign: "left",
                          fontSize: 13,
                        }}
                      >
                        My Lumpsum
                      </Text>
                      <CommaTextField
                        value={
                          lumpsumArrToShow[index] ? lumpsumArrToShow[index] : ""
                        }
                        noEdit={false}
                        editable={false}
                        onValueChange={(text) => {
                          if (!isNaN(text)) {
                            onLumpsumTextChange(text, years, months);
                          }
                        }}
                        containerStyle={{
                          width: "40%",
                          marginLeft: "5%",
                          marginRight: "5.5%",
                        }}
                        textStyle={{
                          fontSize: 14,
                          paddingVertical: 10,
                          paddingHorizontal: 5,
                        }}
                      />
                    </View>
                  </View>
                )}
                <View
                  style={{
                    width: "70%",
                    height: 1,
                    marginLeft: "10%",
                    marginTop: "5%",
                    backgroundColor: "#888C9F66",
                  }}
                />
                {sipArr[index] >= 0 ? (
                  <>
                    <View style={{ flexDirection: "row", marginTop: "5%" }}>
                      <Text
                        style={{
                          fontWeight: "600",
                          color: "#464E5F",
                          textAlign: "left",
                        }}
                      >
                        SIP Suggestion: ₹{" "}
                        {numberWithCommas(parseInt(sipArr[index]))}
                        {"   "}
                      </Text>
                      <TouchableOpacity
                        style={{}}
                        onPress={() => {
                          sipApply(index, years, months);
                        }}
                      >
                        <Text
                          style={{
                            color: "#410DAA",
                            cursor: "pointer",
                            fontSize: 14,
                            textAlign: "center",
                            textDecorationLine: "underline",
                          }}
                        >
                          Apply
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "600",
                          color: "#6b717f",
                          textAlign: "left",
                          fontSize: 13,
                        }}
                      >
                        My SIP
                      </Text>
                      {numberWithCommas(parseInt(sipArr[index])) != 0 ?
                        <CommaTextField
                          value={sipArrToShow[index] ? sipArrToShow[index] : ""}
                          onValueChange={(text) => {
                            if (!isNaN(text)) {
                              onSIPTextChange(text, years, months);
                            }
                          }}
                          onSubmitEditing={() => {
                            // console.log("Return key pressed");
                          }}
                          containerStyle={{
                            width: "40%",
                            marginLeft: "5%",
                            marginRight: "5.5%",
                          }}
                          textStyle={{
                            fontSize: 14,
                            paddingVertical: 10,
                            paddingHorizontal: 5,
                          }}
                        />
                        : <CommaTextField
                        editable={false}
                          value={sipArrToShow[index] ? sipArrToShow[index] : ""}
                          onValueChange={(text) => {
                            if (!isNaN(text)) {
                              onSIPTextChange(text, years, months);
                            }
                          }}
                          onSubmitEditing={() => {
                            // console.log("Return key pressed");
                          }}
                          containerStyle={{
                            width: "40%",
                            marginLeft: "5%",
                            marginRight: "5.5%",
                          }}
                          textStyle={{
                            fontSize: 14,
                            paddingVertical: 10,
                            paddingHorizontal: 5,
                            backgroundColor: "#FAF6F6",
                          }}

                        />}
                    </View>
                  </>
                ) : (
                  <View style={{ marginTop: "5%" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "600",
                          color: "#6b717f",
                          textAlign: "left",
                          fontSize: 13,
                        }}
                      >
                        My SIP
                      </Text>
                      <CommaTextField
                        value={sipArrToShow[index] ? sipArrToShow[index] : ""}
                        noEdit={false}
                        editable={false}
                        onValueChange={(text) => {
                          if (!isNaN(text)) {
                            onSIPTextChange(text, years, months);
                          }
                        }}
                        containerStyle={{
                          width: "40%",
                          marginLeft: "5%",
                          marginRight: "5.5%",
                        }}
                        textStyle={{
                          fontSize: 14,
                          paddingVertical: 10,
                          paddingHorizontal: 5,
                        }}
                      />
                    </View>
                  </View>
                )}
              </View>
            )}
          </View>
          <View
            style={{
              width: 1,
              height: "auto",
              marginRight: "1.5%",
              backgroundColor: "#888C9F66",
            }}
          />
          <View
            style={{
              flex: 1,
              flexDirection: "column",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: "6%",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "600",
                  color: "#6b717f",
                  fontSize: 13,
                  textAlign: "center",
                  marginTop: 10,
                  width: "80%",
                }}
              >
                Projected for{" "}
                {lumpsumArrToShow[index]
                  ? "₹" + numberWithCommas(parseInt(lumpsumArrToShow[index]))
                  : "₹ 0"}{" "}
                lumpsum and{" "}
                {sipArrToShow[index]
                  ? "₹" + numberWithCommas(parseInt(sipArrToShow[index]))
                  : "₹ 0"}{" "}
                SIP by {data.target_date_text}.{"  "}
              </Text>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: -5,
                }}
              >
                <Donut
                  options={{
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: false,
                    },
                    tooltip: { enabled: false },
                    colors: [
                      "#876CDD",
                      revisedProjectionArr[index] >= 100
                        ? "#876CDD"
                        : "#BAC7F2",
                    ],
                    plotOptions: {
                      pie: {
                        donut: {
                          size: "60%",
                        },
                      },
                    },
                    stroke: {
                      width: 1,
                      colors: [
                        "#876CDD",
                        revisedProjectionArr[index] >= 100
                          ? "#876CDD"
                          : "#BAC7F2",
                      ],
                    },
                  }}
                  series={[
                    revisedProjectionArr[index],
                    100 - revisedProjectionArr[index],
                  ]}
                  sliceColor={[
                    "#876CDD",
                    revisedProjectionArr[index] >= 100 ? "#876CDD" : "#BAC7F2",
                  ]}
                  type="donut"
                  width="125"
                  style={{ marginLeft: -10 }}
                />
                <Text
                  style={{
                    marginTop: "1%",
                    fontWeight: "bold",
                    fontSize: 16,
                    color: "#464E5F",
                  }}
                >
                  {revisedProjectionArr[index]}%
                </Text>
                <Text
                  style={{
                    marginTop: "1%",
                    fontWeight: 600,
                    color: "#464E5F",
                  }}
                >
                  Revised Projection
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  basicDetails: {
    flexDirection: "row",
    paddingLeft: isMobile ? 8 : 16,
    paddingRight: 13,
    alignItems: "center",
  },
  goalName: {
    width: 38,
    height: 38,
    borderRadius: 19,
    backgroundColor: "#f2f2f2",
    justifyContent: "center",
    alignItems: "center",
  },
  goalNameText: { alignSelf: "center" },
  goalNameContainer: {
    flex: 0.15,
    maxWidth: 80,
    paddingTop: isMobile ? 0 : 8,
    paddingBottom: isMobile ? 0 : 8,
    flexDirection: "row",
  },
  fundContainer: {
    flex: 0.4,
    flexDirection: "column",
    marginLeft: 20,
    alignItems: "left",
  },
  fund: { color: "#121212", fontWeight: "bold", fontSize: 15 },
  fundIcon: { paddingLeft: 10, color: "#EF5D12", fontSize: 14 },
  dateContainer: {
    flex: 2,
    flexDirection: "column",
    alignItems: "flex-end",
    paddingRight: isMobile ? 0 : 6,
  },
  date: { color: "black", fontSize: 13 },
  secondRowContainer: {
    flexDirection: "row",
    paddingHorizontal: 13,
    paddingBottom: 13,
  },
  projectedLabel: {
    width: 7,
    height: 7,
    backgroundColor: "#00C107",
    marginLeft: 10,
  },
  projectedText: { color: "#121212", fontSize: 14 },
  projected: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: isMobile ? 0 : 8,
  },
  projectedAmount: { color: "#121212", fontSize: 14 },
  projectedContainer: { flex: 0.5, flexDirection: "column" },

  deficitLabel: {
    width: 7,
    height: 7,
    backgroundColor: "#DB7657",
    marginLeft: 10,
  },
  deficitText: { color: "#121212", fontSize: 14 },
  deficit: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: isMobile ? 0 : 8,
  },
  deficitAmount: { color: "#121212", fontSize: 14 },
  deficitContainer: { flex: 0.5, flexDirection: "column" },

  thirdContainer: {
    flexDirection: "row",
    paddingHorizontal: 15,
    marginVertical: 10,
  },
  progressBar: {
    flex: 1,
    height: 5,
    flexDirection: "row",
    borderRadius: 6,
  },
  goalIcon: { width: 23, height: 23, resizeMode: "contain" },
});
