import React, {useRef, useState} from 'react';
import { StyleSheet, Text, View, Image, ScrollView, Platform, Dimensions, TouchableOpacity, Linking } from 'react-native';
import {sm, md, lg, xl, twoxl} from '../../Responsive'
import Header from '../Header/index'
import Modal from '../Common/Modal';
import { isMobile, isWeb, numberWithCommas } from '../../utils';
const window = Dimensions.get("window")


export default function InvestmentOrderStatusAlert({navigation,style={}, modalData= {}, onPressDone, investedAmount,plannedAmount}) {

    return (
        <Modal style={[{ position: 'absolute', top: 0, paddingTop: 220, alignItems: 'center'},style]}>     
                {/* <ScrollView 
                    style={styles.mainContainer}
                    contentContainerStyle={{
                        flex:1,
                        alignItems:"center",
                        paddingTop:100
                    }}
                > */}
                    <View style={styles.content}>
                        {
                            modalData.status == "SUCCESS" ? (
                                <Text style={styles.message}>You have successfully executed the buy order for 
                                    <Text style={styles.amount}>
                                        ₹ {numberWithCommas(Number(modalData.total_transaction_amount))} .
                                    </Text>
                                    Please forward contract notes to 
                                    {/* <Text style={styles.here}>
                                        here 
                                    </Text>
                                    or forward to */}
                                    <Text style={[styles.upload,Platform.OS=="web"&&{cursor:"pointer"}]} onPress={()=>{Linking.openURL('mailto:upload@maxiom.in')}}>
                                        upload@maxiom.in
                                    </Text>
                                </Text>
                            ) : (
                                <>
                                    <Text style={{fontSize:18,color:modalData.status=="ERROR"?"#F64E60":"#0BB783",fontWeight:"bold", marginBottom:20}}>{modalData.title}</Text>
                                    <View style={{marginBottom:27}}>
                                        {
                                            modalData.description &&modalData.description.length >0 &&modalData.description.map((desc)=>{
                                                return(
                                                    <Text style={{fontSize:15,fontWeight:"400",color:"#121212", maxWidth: 600, marginBottom:20 }}>
                                                        {desc}
                                                    </Text>
                                                )
                                            })
                                        } 
                                    </View>
                                </>
                            )
                        }
                        <View style={{alignItems:"center",marginBottom:50}}>
                            <View style={{flexDirection:"row",justifyContent:"space-between",marginTop:11}}>
                                <View style={{flexDirection:"row",alignItems:"center",paddingRight:11}}>
                                    <View style={{width: 9,height: 9,backgroundColor:"#FFA800",borderRadius:2}}/>
                                    <Text style={{fontSize:isMobile?11:14,color:"#121212",fontFamily:"Roboto",marginLeft:6}}>Planned Transaction</Text>
                            <Text style={{fontSize:isMobile?11:14,color:"#410DAA",fontFamily:"Roboto",marginLeft:6}}>₹{numberWithCommas(modalData.suggested_transaction_amount)}</Text>
                                </View>
                                <View style={{flexDirection:"row",alignItems:"center",marginLeft:14}}>
                                    <View style={{width: 9,height: 9,backgroundColor:"#009105",borderRadius:2}}/>
                                    <Text style={{fontSize:isMobile?11:14,color:"#121212",fontFamily:"Roboto",marginLeft:6}}>Actual Transaction</Text>
                                    <Text style={{fontSize:isMobile?11:14,color:"#410DAA",fontFamily:"Roboto",marginLeft:6}}>₹{numberWithCommas(modalData.total_transaction_amount)}</Text>
                                </View> 
                            </View>
                        </View>

                        {/* <Text style={styles.pleaseView}>
                            Please view below video to learn to 
                            <Text style={styles.download}>
                                download & auto forward contract notes
                            </Text>
                            from Zerodha                                    
                            </Text>
                        <Video
                    
                        style={styles.video}
                        source={{
                        uri: 'http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4',
                        }}
                        useNativeControls
                        resizeMode="contain"
                        isLooping
                        /> */}

                        <TouchableOpacity style={styles.doneButton} onPress={()=>{
                            onPressDone &&onPressDone()
                        }}><Text style={styles.doneText}>Done</Text></TouchableOpacity>   
                    </View>
                {/* </ScrollView> */}
       </Modal>
    )
}

const styles = StyleSheet.create({
    container:{width: '100%', flexGrow: 1},
    mainContainer: {
      backgroundColor:"#00000050",
      paddingLeft: md(window.width) ? 32:16,
      paddingRight: md(window.width) ? 32:16,
      
    },
    content:{
        paddingHorizontal: md(window.width) ? 72:18,
        paddingVertical:18,
        backgroundColor:"#fff",
        maxWidth:800,
        width: md(window.width) ? undefined : "90%",
    },
    message:{
        fontSize:16,
        marginBottom:31
    },
    amount:{
        fontSize:16,
        color:"#410DAA",
        fontStyle:"italic",
        paddingLeft:5
    },
    here:{
        fontWeight:"700",
        fontStyle:"italic",
        paddingHorizontal:5,
        textDecorationLine:"underline"
    },
    upload:{
        fontWeight:"700",
        fontStyle:"italic",
        paddingLeft:5,
        textDecorationLine:"underline",
    },
    download:{
        paddingHorizontal:5,
        fontWeight:"700"
    },
    pleaseView:{
        color:"#121212",
        fontFamily:"Roboto",
        fontSize:15,
        marginBottom:13
    },
    doneButton:{
        backgroundColor:"#410DAA",
        paddingHorizontal:77,
        paddingVertical:13,
        alignSelf:"center",
        marginTop: md(window.width) ? 0: 15
        
    },
    doneText:{
        color:"#fff",
        fontSize:15,
        fontFamily:"Roboto"
    },
    video:{
        padding:20,
        width: md(window.width) ? "80%" : 250,
        // maxHeight: 340,
        // height:250,
        alignSelf:"center"
    }
  });