import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { isMobile } from '../../../utils';

const FieldValue = ({ isComponent = false, label="", value=null, style = [], containerStyle=[], labelStyle = [], valueStyle = [], ...props}) => {
    return(
      <View style={[fieldStyle.mainContainer, ...style]}>
        {
          isComponent ? (
            <View>
              {props.children}
            </View>
          ) : (
            <View style={[fieldStyle.container, ...containerStyle]}>
              <Text style={[fieldStyle.label, ...labelStyle]}>{label}</Text>
              <Text style={[fieldStyle.value, ...valueStyle]}>{value}</Text>
            </View>
          )
        }
      </View>
    );
};

const fieldStyle = StyleSheet.create({
    mainContainer: {
        flexGrow: 1,
    },
    container: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingVertical: 11
    },
    label: {
        color: "#464E5F",
        fontSize: 13,
        fontWeight: "400",
        paddingLeft: 4
    },
    value: {
        color: "#464E5F",
        fontSize: 15,
    }
});

export default FieldValue;