import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  Dimensions,
  TouchableOpacity,
  Image,
} from "react-native";
import { md } from "../../../Responsive";
const { width } = Dimensions.get("window");
import AntDesign from '@expo/vector-icons/AntDesign';
import { capitalizeFirstLetter, getUserDetails } from "../../../utils";
const window = Dimensions.get("window");
import Entypo from "@expo/vector-icons/Entypo";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import Store from "../../../store";
import { StackActions } from "@react-navigation/native";
import { getCustomerDetails } from "../../../api";

export default function Header({
  navigation,
  style = {},
  showDetails = true,
  showbackBtn = true,
  route,
  onPressBack = null,
  bannerData = {},
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [name, setName] = useState("");
  const [initials, setInitials] = useState(null);
  const [stackValue, setStackValue] = useState(null);
  const [fundValue, setFundValue] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const handleLogoClick = () => {
    navigation.navigate("Login");
  };

  useEffect(() => {
    getUserData();
    if (Platform.OS == "web") {
      let url = global.location.href;
      let screen = null;
      let stack = null;
  
      if (url.includes("screen=") && url.split("&fund=") && !stackValue) {
        screen = url.split("screen=");
        if (screen && screen[1] && screen[1].split("&stack=")) {
          stack = screen[1].split("&stack=");
          let fund = url.split("&fund=");
          if (fund[1]) {
            let fundValue = fund[1].split("&");
            setFundValue(fundValue[0]);
            setStackValue(stack[1]);
          }
        }
      }
  
      if (url.includes("topup=true") && !stackValue) {
        setStackValue("Dashboard");
      }
    }
  }, [showDetails]);

  const getUserData = async () => {
    const { params } = route;
    let userData = {};
    if (params && params.user_id) {
      userData = await getCustomerDetails(params.user_id);
    } else {
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        userData = await getCustomerDetails(
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        userData = getUserDetails();
      }
    }
    const { first_name, email, mobilenumber, customer_id } = userData;
    let names = first_name ? first_name.split(/\s+/) : [];
    let name = "",
      initials = null;
    if (names.length >= 2 && names.length > 0) {
      name =
        capitalizeFirstLetter(names[0]) + " " + capitalizeFirstLetter(names[1]);
      initials = names[0][0].toUpperCase() + "" + names[1][0].toUpperCase();
    } else {
      if (names.length == 0) {
        name = "User";
      } else {
        name = names[0];
        initials = names[0][0].toUpperCase();
      }
    }

    setUserDetails({
      name: name,
      initials: initials,
      mobilenumber,
      customer_id,
      email,
    });
  };

  const getScreenToGoBack = () => {
    if (route) {
      const { params } = route;
      if (params && params.nvg) {
        return params.nvg;
      }
      return null;
    }
    return null;
  };

  return (
    <>
      <View style={[styles.bar, { ...style }]}>
        <View style={styles.headerLeft}>
          {showbackBtn && (
            <TouchableOpacity
              onPress={onPressBack ? onPressBack : () => {
                let canGoBack = navigation.canGoBack();
                if (canGoBack) {
                  if (stackValue && stackValue == "Explore") {
                    navigation.dispatch(
                      StackActions.replace("App", {
                        screen: "Dashboard",
                        params: {
                          screen: stackValue,
                          params: {
                            screen: "FundDescription",
                            params: {
                              fund: fundValue,
                            },
                          },
                        },
                      })
                    );
                  } else {
                    if (stackValue == "Dashboard") {
                      navigation.dispatch(
                        StackActions.replace("App", {
                          screen: "Dashboard",
                          params: {
                            screen: stackValue,
                            params: {},
                          },
                        })
                      );
                    } else {
                      navigation.goBack();
                    }
                  }
                } else {
                  const screenToBack = getScreenToGoBack();
                  if (screenToBack) {
                    if (stackValue && stackValue == "Explore") {
                      navigation.dispatch(
                        StackActions.replace("App", {
                          screen: "Dashboard",
                          params: {
                            screen: stackValue,
                            params: {
                              screen: "FundDescription",
                              params: {
                                fund: fundValue,
                              },
                            },
                          },
                        })
                      );
                    } else {
                      navigation.dispatch(
                        StackActions.replace("App", {
                          screen: "Dashboard",
                          params: {
                            screen: "Dashboard",
                            params: { screen: screenToBack },
                          },
                        })
                      );
                    }
                  } else {
                    if (stackValue && stackValue == "Explore") {
                      navigation.dispatch(
                        StackActions.replace("App", {
                          screen: "Dashboard",
                          params: {
                            screen: stackValue,
                            params: {
                              screen: "FundDescription",
                              params: {
                                fund: fundValue,
                              },
                            },
                          },
                        })
                      );
                    } else {
                      if (stackValue == "Dashboard") {
                        navigation.dispatch(
                          StackActions.replace("App", {
                            screen: "Dashboard",
                            params: {
                              screen: stackValue,
                              params: {},
                            },
                          })
                        );
                      } else {
                      }
                    }
                  }
                }
              }}
            >
              <AntDesign
                name="arrowleft"
                size={24}
                color="rgba(18, 18, 18, 0.3)"
                style={{ paddingRight: 20 }}
              />
            </TouchableOpacity>
          )}
          <TouchableOpacity onPress={() => {handleLogoClick()}}>
            <Image
              source={{
                uri: bannerData.logo_image_url,
              }}
              style={styles.logo}
            ></Image>
          </TouchableOpacity>
        </View>
        <View style={styles.headerRight}>
          {showDetails && (
            <>
              {userDetails.initials ? (
                <View style={styles.initialsContainer}>
                  <Text style={styles.initials}>{userDetails.initials}</Text>
                </View>
              ) : (
                <Image
                  source={{
                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/boy.png",
                  }}
                  style={styles.boy}
                />
              )}
              <TouchableOpacity
                onPress={() => {
                  setShowOptions(!showOptions);
                }}
              >
                <View style={styles.nameContainer}>
                  <Text style={styles.jane}>{userDetails.name || "User"}</Text>
                  <Entypo
                    name={!showOptions ? "triangle-down" : "triangle-up"}
                    size={20}
                    color="#410DAA"
                  />
                </View>
                {((Store.getState().dashboard &&
                  Store.getState().dashboard.viewModeUserId &&
                  Store.getState().dashboard.viewModeUserId.user_id) ||
                  (route.params && route.params.user_id)) && (
                  <TouchableOpacity
                    onPress={() => {
                      Store.dispatch({
                        type: "GET_DASHBOARD",
                        payload: {
                          viewModeUserId: {
                            user_id: null,
                          },
                        },
                      });
                      let url = new URL(global.location.href);
                      location.replace(url.origin + "/Dashboard/Home");
                    }}
                  >
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Text style={styles.viewMode}>
                        View mode ({userDetails.customer_id})
                      </Text>
                      <AntDesign
                        name="reload1"
                        size={15}
                        color="#410DAA"
                        style={{ marginLeft: 5 }}
                      />
                    </View>
                  </TouchableOpacity>
                )}
              </TouchableOpacity>
            </>
          )}
        </View>
      </View>
      {showOptions && (
        <View
          style={{
            zIndex: 100,
            position: "absolute",
            top: 60,
            right: 30,
            bottom: 0,
          }}
        >
          <View style={styles.log_mod}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={styles.my_acc}>My Account</Text>
              <Text
                style={[styles.logut, Platform.OS == "web" && styles.cursor]}
                onPress={() => {
                  Store.dispatch({
                    type: "LOGOUT",
                  });
                }}
              >
                Logout
              </Text>
            </View>
            <View style={{ flexDirection: "row", paddingTop: 22 }}>
              {" "}
              <MaterialIcons name="headset" size={18} color="#410DAA" />{" "}
              <Text style={styles.ph_num}> +91 {userDetails.mobilenumber}</Text>
            </View>
            <View style={{ flexDirection: "row", paddingTop: 22 }}>
              <MaterialIcons name="mail-outline" size={18} color="#410DAA" />{" "}
              <Text style={styles.ph_num}> {userDetails.email}</Text>
            </View>
          </View>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  bar: {
    backgroundColor: md(width) ? "#f6f6f6" : "#ffffff",
    width: "100%",
    height: Platform.OS === "web" ? 65 : 65,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: md(window.width) ? 50 : 16,
    paddingRight: md(window.width) ? 50 : 16,
    paddingBottom: md(window.width) ? 0 : 18,
    elevation: 1,
    position: Platform.OS === "web" ? "-webkit-sticky" : "relative",
    top: 0,
    left: 0,
  },
  logo: {
    resizeMode: "contain",
    width: md(window.width) ? 155 : 120,
    height: 27.41,
    alignSelf: "center",
    // marginTop: 4,
    paddingLeft: md(width) ? 256 : 20,
  },
  headerLeft: {
    display: "flex",
    alignItems: md(window.width) ? "center" : "flex-end",
    flexDirection: "row",
  },
  headerRight: {
    display: md(width) ? "flex" : "none",
    alignItems: md(window.width) ? "center" : "flex-end",
    flexDirection: "row",
  },
  boy: {
    width: 50.4,
    height: 50.4,
    backgroundColor: "rgba(65, 13, 170, 0.1)",
    borderRadius: 6,
    display: md(width) ? "none" : "none",
  },
  jane: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    color: "#464E5F",
    fontSize: 20,
    lineHeight: 23,
  },
  ph_num: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#465964",
  },
  initialsContainer: {
    backgroundColor: "#410DAA",
    borderRadius: 6,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 11,
    paddingVertical: 13,
  },
  initials: {
    color: "#ffffff",
    fontSize: 20,
    lineHeight: 23,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  separator: {
    color: "#00000030",
  },
  nameContainer: {
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 70,
    justifyContent: "space-between",
    marginVertical: 4,
  },
  log_mod: {
    backgroundColor: "#ffffff",

    shadowColor: "rgba(0, 0, 0, 0.1)",
    shadowRadius: 5,
    borderRadius: 6,
    paddingHorizontal: 20,
    paddingTop: 17,
    paddingBottom: 23,
    width: 355,
  },
  my_acc: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 21,
    color: "#464E5F",
  },
  logut: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#6C7293",
    alignItems: "center",
  },
  cursor: {},
  viewMode: {
    color: "red",
    fontSize: 14,
    paddingLeft: 20,
  },
});
