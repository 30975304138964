import React from "react";
import { StyleSheet, View, Platform, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";
import global from "./globalStyles";

export default function BackToTop ({target}) {
    function handlePress () {
        target.current?.scrollTo({
            y: 0,
            animated: true
        })
    }
    return (
        <View>
            <TouchableOpacity onPress={handlePress}>
                <Text style={[styles.button, global.arial]}>Back to Top</Text>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    button: {
        textAlign: 'center',
        alignSelf: 'center',
        backgroundColor: '#1C52A0',
        borderRadius: 13,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 18,
        paddingRight: 18,
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:'center'
    }
})
