import React from "react";
import { View, StyleSheet, Text, Platform } from "react-native";
import RNPickerSelect from "react-native-picker-select";
import { isMobile } from "../../../utils";
import PickerField from "./PickerField";

const PickerFieldSelect = (props) => {
  let {
    label,
    data,
    value,
    onChange,
    style = {},
    editable = true,
    required = false,
    pickerStyle = [],
    placeholder = { label: "Select", value: "SEL" },
  } = props;
  if (Platform.OS == "ios") {
    return (
      <View style={[{ ...style }]}>
        {!!label && (
          <Text style={pickerStyles.label}>
            {label}
            <Text style={pickerStyles.required}>{required ? "*" : ""}</Text>
          </Text>
        )}
        <View style={pickerStyles.pickerContainer}>
          <RNPickerSelect
            value={value}
            onValueChange={(itemValue, itemIndex) =>
              editable && onChange && onChange(itemValue)
            }
            items={[...data]}
            style={[
              pickerStyles.picker,
              !editable && pickerStyles.notEditable,
              { height: 44, color: "black" },
              ...pickerStyle,
            ]}
            placeholder={placeholder}
          />
        </View>
      </View>
    );
  } else {
    return <PickerField {...props} />;
  }
};

const pickerStyles = StyleSheet.create({
  label: {
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: "700",
    color: "#464E5F",
    marginBottom: 11,
  },
  pickerContainer: {},
  picker: {
    width: isMobile ? "100%" : 300,
    borderRadius: 5,
  },
  pickerItemStyle: { height: 44 },
  notEditable: {
    backgroundColor: "#EDF4F6",
  },
  required: {
    color: "red",
  },
});

export default PickerFieldSelect;
