import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import { isMobile } from '../../../utils';
import BillingLedger from './BillingLedger';

const BorderedField = ({ label, value }) => {
    return(
        <View style={bordered.container}>
            <View style={bordered.border}/>
            <View>
                <Text style={bordered.value}>{value}</Text>
                <Text style={bordered.label}>{label}</Text>
            </View>
        </View>
    )
}

const bordered = StyleSheet.create({
    container: {
        flexDirection: "row"
    },
    border: {
        width: 5,
        height: 48,
        backgroundColor: "#6D39F5",
        borderRadius: 4,
        marginRight: 10
    },

    value: {
        fontFamily: "Roboto",
        fontSize: 14,
        color: "#121212",
        marginBottom: 8
    },
    label: {
        color: "#888C9F",
        fontSize: 13,
        fontStyle: "italic",
        fontFamily: "Roboto"
    }
})

const InvestmentStatusIndicator = ({ style = {} }) => {
    return(
        <View style={[status.container,{...style}]}>
            <View style={{ maxWidth: 300}}>
                <View style={status.fieldNames}>
                    <Text style={[status.field, status.invested]}>₹ 25,50,000 (Invested)</Text>
                    <Text style={[status.field, status.planned]}>₹ 52,50,000 (Planned)</Text>
                </View>
                <View style={status.indicator}>
                    <View style={status.completed}></View>
                </View>
            </View>
        </View>
    )
}

const status = StyleSheet.create({
    container: {
        
    },
    fieldNames: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: 'space-between',
        marginBottom: 10,
        flex: 1
    },
    field: {
        fontSize: 12,
        fontFamily: "Roboto",
        fontWeight: 'bold', 
    },
    invested: {
        color: "#334D6E"
    },
    planned: {
        color: "#410DAA"
    },
    indicator: {
        width: "100%",
        height: 5,
        borderRadius: 5,
        backgroundColor: "#E1D3FF",
        overflow: 'hidden'
    },
    completed: {
        backgroundColor: "#00AB9A",
        borderRadius: 5,
        width: '50%',
        height: 5
    }
})

const Circles = () => {
    return(
        <View style={circles.outer}>
            <View style={circles.inner}></View>
        </View>
    )
}

const circles = StyleSheet.create({
    outer: {
        width: 13.8,
        height: 13.8,
        borderRadius: 6.8,
        borderWidth: 1,
        borderColor: "#410DAA",
        justifyContent: "center",
        alignItems: "center"
    },
    inner: {
        width: 10,
        height: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: "#410DAA"
    }
});

const PlanStrip = ({ data }) => {
    return(
        <View style={strip.container}>
            <View style={strip.body}>
                <View style={strip.planBasicDetails}>
                    <Circles />
                    <View style={strip.planNameContainer}>
                        <View style={strip.planTagContainer}>
                            <Text style={strip.planName}>LP1 Plan</Text>
                            <View>
                                <Image
                                    source={"https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/Rectangle+2755.png"} 
                                    style={strip.planTag}
                                />
                                <Text style={strip.planTagText}>JEWEL</Text>
                            </View>
                        </View>
                        <Text style={strip.planType}>Staggered Lumpsum</Text>
                    </View>
                </View>

                {/* Status Indicator */}
                <InvestmentStatusIndicator style={{flex: 0.3, marginTop: isMobile ? 30 : 0}}/>
                <View style={{flex: 0.2, paddingLeft: isMobile ? 0 : 30}}>
                    <BorderedField label="Curr. Value" value="₹ 31,00,000"/>
                </View>
                <View style={{flex: 0.2, marginTop: isMobile ? 20 : 0}}>
                    <BorderedField label="Dividend" value="₹ 31,00,000"/>
                </View>
                <View style={{flex: 0.1, marginTop: isMobile ? 20 : 0}}>
                    <BorderedField label="Valuation Date" value="25 Aug 2021"/>
                </View>

            </View>

            {/* Plan Number  */}
            <Text style={strip.planNumber}>
                Plan No: 122333 (15 Feb 2021 - 15 Feb 2023)
            </Text>
        </View>
    )
}

const strip = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: "#8950FC",
        borderRadius: 10,
        backgroundColor: "#FBF9FF"
    },
    body: {
        flexDirection: isMobile ? "column" : "row",
        paddingHorizontal: "3%",
        paddingTop: "2%",
        paddingBottom: 30,
        flexWrap: "wrap"
    },
    planBasicDetails: {
        flexDirection: "row",
        alignItems: "flex-start",
        flex: 0.2
    },
    planNameContainer: {
        marginLeft: 10
    },
    planName: {
        color: "#464E5F",
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 14,
        paddingRight: 6
    },
    planTag:{
        width: 86,
        height: 19
    },
    planTagText:{
        fontSize: 12,
        fontWeight: "bold",
        color: "#3D1310",
        fontFamily: "Roboto",
        position: "absolute",
        top: 3,
        left: 9
    },
    planType: {
        color: "#00AB9A",
        fontSize: 14,
        fontFamily: "Roboto",
        marginTop: 9
    },
    planTagContainer: {
        flexDirection: "row",
        alignItems: "flex-start"
    },
    indicator: {
        flex: 0.3,
        marginTop: isMobile ? 30 : 0
    },
    planNumber: {
        fontSize: 12,
        color: "#888C9F",
        fontFamily: "Roboto",
        marginLeft: 36,
        marginBottom: 5
    }
})

class PlanDetails extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <View style={styles.container}>
                <PlanStrip />
                <BillingLedger />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 31,
        paddingVertical: 26,
        backgroundColor: "#FFFFFF",
        flex: 1,
        overflow: "scroll"
    }
});

export default PlanDetails;