import React from 'react';
import { View, Text, ScrollView, ActivityIndicator, TextInput, StyleSheet, Dimensions } from 'react-native';
import { getClientMfOrdersPlacedToday, otpAuthForMfOrders, runActionItemsToPlaceOrders } from '../../../api';
import { ShowToast } from '../../Common/Toast';
import { CustomModal } from '../../Common/CustomModal';
import NavigationHeader from '../../Common/NavigationHeader';
import { TouchableOpacity } from 'react-native';
import { md } from '../../../Responsive';
import { isMobile } from '../../../utils';
import moment from 'moment';
import Store from '../../../store';
const { width } = Dimensions.get("window");
export default class MfOtpAuthentication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showToast: false,
            toastMessage: null,
            showModal: false,
            cai_pk_list : [],
            otpNumber: null,
            modalLoading:false
        };
    }
    componentDidMount = async() => {
        try{
            this.setState({
                isLoading: true
            })

            let client_order_response = await getClientMfOrdersPlacedToday();
            if (client_order_response && client_order_response.code == "100") {
                this.setState({
                    showToast: true,
                    toastMessage: client_order_response.result,
                    cai_pk_list: client_order_response.caipk_list
                })
                let otp_resp = await otpAuthForMfOrders();
                if (otp_resp && otp_resp.code == "100") {
                    this.setState({
                        isLoading: false,
                        showModal: true
                    })
                }
            } else if (client_order_response && client_order_response.code == "101" && client_order_response.msg=="success" && client_order_response.result.includes("No Mutual Fund Orders are created")) {
                this.setState({
                    showToast: true,
                    toastMessage: client_order_response.result,
                    showModal: false
                })
                let domain_url = "https://app.maxiomwealth.com/"
                let hostname = global.location.hostname;
                if (hostname.includes("club")) {
                    domain_url = "https://club.maxiomwealth.com/"
                } else if (hostname.includes("myadvisor")) {
                    domain_url = "https://myadvisor.maxiomwealth.com/"
                } else if (hostname.includes("dclub")) {
                    domain_url = "https://dclub.maxiomwealth.com/"
                } else if (hostname.includes("dmyadvisor")) {
                    domain_url = "https://dmyadvisor.maxiomwealth.com/"
                } else if (hostname.includes("dapp")) {
                    domain_url = "https://dapp.maxiomwealth.com/"
                } else if (hostname.includes("localhost")) {
                    domain_url = "http://localhost:19006/"
                } else {
                    domain_url = "https://app.maxiomwealth.com/"
                }
                setTimeout(() => {
                    global.open(domain_url, "_self");
                }, 5000);
            } else {
                this.setState({
                    showToast: true,
                    toastMessage: "Unable to connect to server. Please try again after sometime.",
                    showModal: false
                })
                let domain_url = "https://app.maxiomwealth.com/"
                let hostname = global.location.hostname;
                if (hostname.includes("club")) {
                    domain_url = "https://club.maxiomwealth.com/"
                } else if (hostname.includes("myadvisor")) {
                    domain_url = "https://myadvisor.maxiomwealth.com/"
                } else if (hostname.includes("dclub")) {
                    domain_url = "https://dclub.maxiomwealth.com/"
                } else if (hostname.includes("dmyadvisor")) {
                    domain_url = "https://dmyadvisor.maxiomwealth.com/"
                } else if (hostname.includes("dapp")) {
                    domain_url = "https://dapp.maxiomwealth.com/"
                } else if (hostname.includes("localhost")) {
                    domain_url = "http://localhost:19006/"
                } else {
                    domain_url = "https://app.maxiomwealth.com/"
                }
                setTimeout(() => {
                    global.open(domain_url, "_self");
                }, 5000);
            }
        } catch(err){
            console.log(err)
            this.setState({
                isLoading: false,
                showToast: true,
                toastMessage: "Unable to connect to server. Kindly reload the page..."
            })
        }
    }
    handleContinue = async () => {
        try {
            this.setState({
                modalLoading: true,
                showToast: false,
            });
            let auth_payload = {
                "otp": this.state.otpNumber,
                "cai_pk_list": this.state.cai_pk_list
            }
            let otp_resp = await otpAuthForMfOrders(auth_payload);
            if (otp_resp && otp_resp.code == "100") {
                this.setState({
                    showToast: true,
                    toastMessage: "OTP verified successfully. Redirecting to payment page." 
                })
                const key = moment().unix();
                let payload = {
                    cai_list: this.state.cai_pk_list,
                    redirect_url: global.location.origin + "/dashboard/process/" + key,
                };
                // console.log(payload);
                
                const order_resp_result = await runActionItemsToPlaceOrders(payload);
                if (order_resp_result) {
                    const { navigate_to, code, nav_props, ...restData } = order_resp_result;
                    Store.dispatch({
                    type: "ADD_ACTION",
                    payload: {
                        key: key,
                        data: {
                        ...restData,
                        ...nav_props,
                        },
                    },
                    });
                    if (order_resp_result.code && order_resp_result.code == 100) {
                        if (order_resp_result.nav_props.orderdata == "") {
                            this.props.navigation.navigate("App", {
                                screen: "Dashboard",
                                params: {
                                    screen: "Dashboard",
                                    params: { screen: "ProcessOrder", params: { action_id: key } },
                                },
                                });
                            return;
                        }
                        this.setState({
                            showToast: true,
                            toastMessage: "Redirecting to payment screen..."
                        })
                        if (order_resp_result.navigate_to == "ShowBankAccount") {
                            this.props.navigation.navigate("App", {
                                screen: "Dashboard",
                                params: {
                                screen: "Explore",
                                params: {
                                    screen: "ShowBankAccount",
                                    params: {
                                    action: key,
                                    },
                                },
                                },
                            });
                            return;
                        }
                    }
                    this.props.navigation.navigate("App", {
                    screen: "Dashboard",
                    params: {
                        screen: "Dashboard",
                        params: { screen: "ProcessOrder", params: { action_id: key } },
                    },
                    });
                } else {
                    this.setState({
                        showToast: true,
                        toastMessage: order_resp_result.msg
                    })
                    let domain_url = "https://app.maxiomwealth.com/"
                    let hostname = global.location.hostname;
                    if (hostname.includes("club")) {
                        domain_url = "https://club.maxiomwealth.com/"
                    } else if (hostname.includes("myadvisor")) {
                        domain_url = "https://myadvisor.maxiomwealth.com/"
                    } else if (hostname.includes("dclub")) {
                        domain_url = "https://dclub.maxiomwealth.com/"
                    } else if (hostname.includes("dmyadvisor")) {
                        domain_url = "https://dmyadvisor.maxiomwealth.com/"
                    } else if (hostname.includes("dapp")) {
                        domain_url = "https://dapp.maxiomwealth.com/"
                    } else if (hostname.includes("localhost")) {
                        domain_url = "http://localhost:19006/"
                    } else {
                        domain_url = "https://app.maxiomwealth.com/"
                    }
                    setTimeout(() => {
                        global.open(domain_url, "_self");
                    }, 5000);
                }
            } else {
                this.setState({
                    modalLoading: false,
                    otpError: otp_resp.result,
                    showToast: true,
                    toastMessage: otp_resp.result
                })
            }
        } catch (err) {
            console.log(err);
            this.setState({
                showToast: true,
                modalLoading: false,
                toastMessage: "Failed to verify OTP. Please try again.",
            });
        }
    }
    renderModalContent = () => {
        return (
            <>
            {
                this.state.modalLoading ? (
                    <ActivityIndicator size="small" color="#410DAA" />
                ) : (
                    <View style={{
                    }}>
                        <View style={styles.inputContainer}>
                            <Text style={styles.mob_num}>Enter OTP</Text>
                            <TextInput
                            autoFocus={this.state.showModal}
                            value={this.state.otpNumber}
                            style={styles.dmart_body_md}
                            placeholder={`Enter OTP sent on your registered mobile number`}
                            onFocus={() => {
                                this.setState({
                                otpError: "",
                                });
                            }}
                            onChangeText={(text) => {
                                const pattern = /^[0-9\b]+$/;
                                if (text.length <= 6 && pattern.test(text)) {
                                this.setState({ otpNumber: text });
                                } else {
                                text = text.slice(0, -1);
                                this.setState({ otpNumber: text });
                                }
                            }}
                            maxLength={6}
                            />
                            {this.state.otpError && (
                            <Text style={[styles.please, { color: "red" }]}>
                                {this.state.otpError || " "}
                            </Text>
                            )}
                        </View>
                        <View>
                            <TouchableOpacity
                                onPress={() => {
                                    this.handleContinue();
                                }}
                                style={{                          
                                    fontSize: 15,
                                    color: "#fff",
                                    backgroundColor: "#410DAA",
                                    borderColor: "#410DAA",
                                    borderWidth: 1,
                                    borderRadius: 4,
                                    paddingVertical: 10,
                                    textAlign: "center",
                                    width: "82%",
                                    marginLeft: "9%",
                                    marginRight: "9%",
                                    marginTop: "10%",
                                    marginBottom: "10%",}}
                            >
                                <Text style={{
                                    color: "#fff",
                                    textAlign: "center",
                                }}>Submit OTP</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                )
            }
            </>
        )
    }
    render() {
        const {
            isLoading
        } = this.state;
        return (
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    backgroundColor: "#fff",
                }}
            >
                <NavigationHeader
                    navigation={this.props.navigation}
                    navigationHeader={["Mutual Fund Orders Authentication"]}
                    onPressBack={() => {
                    this.props.navigation.goBack();
                    }}
                />
                {this.state.showToast ? (
                    <ShowToast
                    showToast={true}
                    type="success"
                    title={this.state.toastMessage}
                    />
                ) : null}
                <ScrollView>
                    {
                        isLoading ? (
                            <>
                                <ActivityIndicator size="large" color="#410DAA" />
                            </>
                        )  : (
                            <View style={{
                                marginTop: 300
                            }}>
                               <CustomModal
                                    visible={this.state.showModal}
                                    showCloseButton={false}
                                    closeModal={null}
                                    style={{
                                        width: isMobile ? "90%": "60%",
                                        height: 400
                                    }}
                                    >
                                    {this.renderModalContent()}
                                </CustomModal>
                            </View>
                        )
                    }
                </ScrollView>
            </View>
        )
    }
}
const styles = StyleSheet.create({
    inputContainer: {
        marginBottom: 40,
      },
      mob_num: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 14,
        color: "#222225",
        paddingBottom: 50
      },
      dmart_body_md: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: "bold",
        color: "#121212",
        backgroundColor: "#f6f6f6",
        height: 41.02,
        paddingLeft: 5,
        borderRadius: 4,
      },
      please: {
        fontFamily: "Roboto",
        fontStyle: "italic",
        fontWeight: "bold",
        fontSize: 11,
        color: "#6C6D72",
      },
})