import React from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import moment from "moment";
import NavigationHeader from "../../components/Common/NavigationHeader";
import amplitude from "../../utils/amplitude";
import Table from "../../components/Common/Table";
import SearchBar from "../../components/Common/SearchBar";
import { ShowToast } from "../../components/Common/Toast";
import {
  getUserDetails,
  isMobile,
  numberWithCommas,
  capitalizeFirstLetter,
} from "../../utils";
import {
  getStocksPortfolio,
  viewMonthlyReport,
  getCustomerDetails,
} from "../../api";
import { Picker } from "@react-native-picker/picker";
import { ActivityIndicator } from "react-native-paper";
import { Switch } from "react-native-paper";
import axios from "axios";

import { md } from "../../Responsive";
const { width } = Dimensions.get("window");
const isWeb = md(width);

const ToggleSummaryView = (props) => {
  const { value, onValueChange } = props;

  const handleValueChange = () => {
    onValueChange();
  };

  return (
    <View style={toggleStyles.container}>
      {!isMobile && (
        <Text
          style={[
            toggleStyles.item,
            !value ? toggleStyles.active : toggleStyles.inActive,
          ]}
        >
          Since Inception
        </Text>
      )}
      <Switch
        value={value}
        style={[StyleSheet.flatten([toggleStyles.switch])]}
        onValueChange={handleValueChange}
        color={"#410DAA"}
      />
      <Text
        style={[
          toggleStyles.item,
          value ? toggleStyles.active : toggleStyles.inActive,
        ]}
      >
        {isMobile
          ? value
            ? "Active Plans"
            : "Since Inception"
          : "Only Active Plans"}
      </Text>
    </View>
  );
};

const SelectProduct = ({
  excelText,
  excelData,
  value,
  onChange,
  isSwitchOn,
  reportLoading,
  viewReport,
  onValueChange,
}) => {
  return (
    <View style={select.container}>
      <View style={select.body}>
        <View style={{ flex: 8.6, flexDirection: "row" }}>
          <Text style={[select.product, { marginTop: 4 }]}>
            Show stock holdings of Product:
          </Text>
          <Picker
            selectedValue={value}
            onValueChange={(itemValue, itemIndex) => {
              onChange(itemValue);
            }}
            style={{
              borderColor: "black",
              paddingTop: 5,
              color: "#464E5F",
              backgroundColor: "#F9F6FF",
              borderRadius: 6,
              borderBottomWidth: 1,
              paddingBottom: 5,
            }}
          >
            <Picker.Item label={"JEWEL"} value={"JEWEL"} />
            <Picker.Item label={"SPARK"} value={"SPARK"} />
          </Picker>
          <ToggleSummaryView
            onValueChange={onValueChange}
            value={isSwitchOn}
            color={"#410DAA"}
          />
        </View>
        {!isMobile &&
          Dimensions.get("window").width / Dimensions.get("window").height >
            2.1 && (
            <>
              <View
                style={{
                  flex: 1.7,
                  marginTop: 1,
                  justifyContent: "flex-end",
                  flexDirection: "row",
                }}
              >
                {reportLoading ? (
                  <View style={styles.loadingConainer}>
                    <ActivityIndicator size={33} color="#410DAA" />
                  </View>
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      viewReport();
                    }}
                  >
                    <Text
                      style={{
                        color: "#410DAA",
                        fontWeight: "bold",
                        fontSize: 15,
                        textDecorationLine: "underline",
                      }}
                    >
                      View Monthly Report
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </>
          )}
      </View>
      {(isMobile ||
        Dimensions.get("window").width / Dimensions.get("window").height <=
          2.1) && (
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View
            style={{
              marginTop: 21,
              marginBottom: 10,
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            {reportLoading ? (
              <View style={styles.loadingConainer}>
                <ActivityIndicator size={33} color="#410DAA" />
              </View>
            ) : (
              <TouchableOpacity
                onPress={() => {
                  viewReport();
                }}
              >
                <Text
                  style={{
                    color: "#410DAA",
                    fontWeight: "bold",
                    fontSize: 15,
                    textDecorationLine: "underline",
                  }}
                >
                  View Monthly Report
                </Text>
              </TouchableOpacity>
            )}
          </View>
          <View
            style={{
              marginTop: 21,
              marginBottom: 10,
              marginLeft: 30,
              justifyContent: isMobile ? "flex-end" : "flex-start",
              flexDirection: "row",
            }}
          ></View>
        </View>
      )}
    </View>
  );
};

const select = StyleSheet.create({
  container: {
    marginTop: 30,
    marginBottom: 20,
    marginHorizontal: isMobile ? 16 : 36,
  },
  product: {
    color: "#464E5F",
    fontSize: 14,
    fontFamily: "Roboto",
    marginRight: 20,
  },
  body: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  button: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 14 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
});

class StocksPortfolio extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    const { params } = this.props.route;
    if (params) {
      userId = params.user_id ? params.user_id : null;
      userId = isNaN(userId) ? null : userId;
    }
    this.state = {
      data: {
        JEWEL: [],
        SPARK: [],
      },
      isSwitchOn: true,
      showStocksOf: "JEWEL",
      userId,
      userName: "",
      reportLoading: false,
    };
  }

  viewReport = async () => {
    this.setState({ reportLoading: true });
    const monthlyReportResp = await viewMonthlyReport(this.state.userId);
    if (monthlyReportResp && monthlyReportResp.code == 100) {
      this.setState({ reportLoading: false });
      axios({
        url: monthlyReportResp.results[0].document_s3_url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${this.state.userName} - Equity Portfolio (${moment().format(
            "DD-MM-YYYY hh-mm A"
          )}).pdf`
        );
        document.body.appendChild(link);
        link.click();
      });
    } else {
      this.setState({ reportLoading: false });
    }
  };

  onToggleSwitch = () => {
    this.setState({ isSwitchOn: !this.state.isSwitchOn });
  };

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("stocks",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("stocks", { path: this.props.navigation.getState().routeName });
      }
      let url = new URL(window.location.href);

      let { data, showStocksOf, userId } = this.state;
      let userData = {};

      if (userId) {
        userData = await getCustomerDetails(userId);
      } else {
        userData = getUserDetails();
      }

      let { customer_id, first_name } = userData;

      let names = first_name ? first_name.split(/\s+/) : [];
      let name = "";
      if (names.length >= 2 && names.length > 0) {
        name =
          capitalizeFirstLetter(names[0]) +
          " " +
          capitalizeFirstLetter(names[1]);
      } else {
        if (names.length == 0) {
          name = "User";
        } else {
          name = names[0];
        }
      }

      this.setState({ userName: name });

      customer_id = userId || customer_id;
      this.setState({ loadingData: true });
      const response = await getStocksPortfolio(customer_id);
      if (response && response.code == 100) {
        const { result } = response;
        const { JEWEL, SPARK } = result;

        if (JEWEL["has_stocks"]) {
          const { stocks } = JEWEL;
          data["JEWEL"] = stocks;
        }
        if (SPARK["has_stocks"]) {
          // Show spark stocks by default if jewel stocks are empty
          if (!JEWEL["has_stocks"]) {
            showStocksOf = "SPARK";
          }
          const { stocks } = SPARK;
          data["SPARK"] = stocks;
        }
      }
      const searchResults = data[showStocksOf];
      this.setState({ data, searchResults, loadingData: false });
    } catch (error) {
      this.setState({ loadingData: false });
    }
  };

  compareStrings = (a, b) => {
    const x = a.toLowerCase();
    const y = b.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  compareNumbers = (a, b) => {
    return a - b;
  };

  getSortedData = (data, sorting_field, sorting_value, start, end) => {
    if (data.length != 0) {
      const fieldValue = data[0][sorting_field];
      const sortingType = isNaN(fieldValue) ? "string" : "number";

      data = data.slice(start, end);
      if (sortingType == "string") {
        data.sort((a, b) =>
          this.compareStrings(a[sorting_field], b[sorting_field])
        );
      } else {
        data.sort((a, b) =>
          this.compareNumbers(a[sorting_field], b[sorting_field])
        );
      }

      return sorting_value ? data : data.reverse();
    } else {
      return data;
    }
  };

  handleSort = (field, sort, start, end) => {
    const sorting_field = field;
    const sorting_value = !sort;

    const { searchResults } = this.state;
    const sortedData = [].concat(
      searchResults.slice(0, start),
      this.getSortedData(
        searchResults,
        sorting_field,
        sorting_value,
        start,
        end
      ),
      searchResults.slice(end, searchResults.length)
    );

    this.setState({ sorting_field, sorting_value, searchResults: sortedData });
  };

  getHeaderData = (data, isSwitchOn) => {
    const { sorting_field, sorting_value, userName, showStocksOf } = this.state;
    const keysData = [
      {
        label: "Stock Name",
        key: "stock_name",
      },
      {
        label: "Quantity",
        key: "units",
      },
      {
        label: "Avg.Price",
        key: "avg_price",
      },
      {
        label: "Latest Price",
        key: "closing_price",
      },
      {
        label: "Invested*",
        key: "invested",
      },
      {
        label: "Latest Value",
        key: "latest_value",
      },
      {
        label: "Abs.Gain",
        key: "gain",
      },
      {
        label: "Gain %",
        key: "gain_percent",
      },
      {
        label: "Avg.Holding Period",
        key: "holding_period",
      },
    ];

    let headerData = [];
    keysData.forEach((headerItem) => {
      headerData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: true,
        onSort: this.handleSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    let values = [];
    let excelTextValue = "";
    let excelDataValue = [];

    if (isSwitchOn) {
      data.forEach((value) => {
        const {
          plan_avg_price,
          closing_price,
          plan_invested,
          latest_value,
          plan_gain,
          plan_gain_percent,
          plan_holding_period,
        } = value;
        let planAveragePrice =
          plan_avg_price != null
            ? "₹" + numberWithCommas(plan_avg_price.toFixed(2))
            : "-";
        let closingPrice =
          closing_price != null
            ? "₹" + numberWithCommas(closing_price.toFixed(2))
            : "-";
        let planInvestedValue =
          plan_invested != null
            ? "₹" + numberWithCommas(plan_invested.toFixed(2))
            : "-";
        let latestValue =
          latest_value != null
            ? "₹" + numberWithCommas(latest_value.toFixed(2))
            : "-";
        let planGainValue =
          plan_gain != null
            ? "₹" + numberWithCommas(plan_gain.toFixed(2))
            : "-";
        let planGainPercent =
          plan_gain_percent != null ? plan_gain_percent.toFixed(2) : "-";
        let planHoldingPeriod = plan_holding_period / 30;
        if (planHoldingPeriod < 1) {
          planHoldingPeriod = plan_holding_period.toFixed(0) + " days";
        } else if (planHoldingPeriod < 12) {
          planHoldingPeriod = planHoldingPeriod.toFixed(2) + " months";
        } else {
          planHoldingPeriod = (planHoldingPeriod / 12).toFixed(2) + " years";
        }

        excelTextValue =
          userName +
          " " +
          showStocksOf +
          " Stock Holdings (Active Plans) " +
          moment().format("DD-MM-YYYY hh-mm-ss A");

        excelDataValue.push({
          stock_name: value["stock_name"] || "",
          units: value["units"] || 0,
          avg_price: planAveragePrice,
          closing_price: closingPrice,
          invested: planInvestedValue,
          latest_value: latestValue,
          gain: planGainValue,
          gain_percent: planGainPercent,
          holding_period: planHoldingPeriod,
        });

        values.push([
          {
            valueToDisplay: value["stock_name"],
            value: value["stock_name"] || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value["units"],
            value: value["units"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: planAveragePrice,
            value: value["plan_avg_price"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: closingPrice,
            value: value["closing_price"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: planInvestedValue,
            value: value["plan_invested"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: latestValue,
            value: value["plan_latest_value"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: planGainValue,
            value: value["plan_gain"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: planGainPercent,
            value: value["plan_gain_percent"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: planHoldingPeriod,
            value: value["plan_holding_period"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
        ]);
      });
    } else {
      data.forEach((value) => {
        const {
          avg_price,
          closing_price,
          invested,
          latest_value,
          gain,
          gain_percent,
          holding_period,
        } = value;
        let averagePrice =
          avg_price != null
            ? "₹" + numberWithCommas(avg_price.toFixed(2))
            : "-";
        let closingPrice =
          closing_price != null
            ? "₹" + numberWithCommas(closing_price.toFixed(2))
            : "-";
        let investedValue =
          invested != null ? "₹" + numberWithCommas(invested.toFixed(2)) : "-";
        let latestValue =
          latest_value != null
            ? "₹" + numberWithCommas(latest_value.toFixed(2))
            : "-";
        let gainValue =
          gain != null ? "₹" + numberWithCommas(gain.toFixed(2)) : "-";
        let gainPercent = gain_percent != null ? gain_percent.toFixed(2) : "-";
        let holdingPeriod = holding_period / 30;
        if (holdingPeriod < 1) {
          holdingPeriod = holding_period.toFixed(0) + " days";
        } else if (holdingPeriod < 12) {
          holdingPeriod = holdingPeriod.toFixed(2) + " months";
        } else {
          holdingPeriod = (holdingPeriod / 12).toFixed(2) + " years";
        }

        excelTextValue =
          userName +
          " " +
          showStocksOf +
          " Stock Holdings (Since Inception) " +
          moment().format("DD-MM-YYYY hh-mm-ss A");

        excelDataValue.push({
          stock_name: value["stock_name"] || "",
          units: value["units"] || 0,
          avg_price: averagePrice,
          closing_price: closingPrice,
          invested: investedValue,
          latest_value: latestValue,
          gain: gainValue,
          gain_percent: gainPercent,
          holding_period: holdingPeriod,
        });

        values.push([
          {
            valueToDisplay: value["stock_name"],
            value: value["stock_name"] || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value["units"],
            value: value["units"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: averagePrice,
            value: value["avg_price"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: closingPrice,
            value: value["closing_price"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: investedValue,
            value: value["invested"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: latestValue,
            value: value["latest_value"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: gainValue,
            value: value["gain"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: gainPercent,
            value: value["gain_percent"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: holdingPeriod,
            value: value["holding_period"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
        ]);
      });
    }
    return { headerData, values, excelTextValue, excelDataValue };
  };

  handleSearch = (searchText) => {
    let searchResults = [];
    const { data, showStocksOf } = this.state;
    const searchData = data[showStocksOf];
    if (searchText) {
      searchResults = searchData.filter((stock) => {
        return stock.stock_name
          .toLowerCase()
          .startsWith(searchText.toLowerCase());
      });
    } else {
      searchResults = searchData;
    }
    this.setState({
      searchText,
      searchResults,
      sorting_field: null,
      sorting_value: null,
    });
  };

  handleProductChange = (value) => {
    const { data } = this.state;
    const searchResults = data[value];
    this.setState({
      showStocksOf: value,
      searchResults,
      sorting_field: null,
      sorting_value: null,
    });
  };

  handleGoBack = () => {
    this.props.navigation.navigate("Portfolio", {
      screen: "InvestmentOverview",
    });
  };

  render() {
    const {
      searchText,
      searchResults = [],
      showStocksOf,
      loadingData,
    } = this.state;
    const { headerData, values, excelTextValue, excelDataValue } =
      this.getHeaderData(
        searchResults,
        this.state.isSwitchOn,
        excelTextValue,
        excelDataValue
      );
    return (
      <View style={styles.container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["View Holdings"]}
          style={styles.headerStyle}
          onPressBack={this.handleGoBack}
        />
        {this.state.reportLoading ? (
          <ShowToast
            showToast={true}
            type="success"
            title="Generating Latest Report, Please Wait..."
          />
        ) : null}
        {loadingData ? (
          <View style={styles.loadingConainer}>
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        ) : (
          <ScrollView>
            <SelectProduct
              reportLoading={this.state.reportLoading}
              viewReport={this.viewReport}
              excelText={excelTextValue}
              excelData={excelDataValue}
              onChange={this.handleProductChange}
              value={showStocksOf}
              isSwitchOn={this.state.isSwitchOn}
              onValueChange={this.onToggleSwitch}
            />
            <ScrollView horizontal={true} style={styles.mainContainer}>
              <View style={styles.detailsContainer}>
                <View style={styles.headingContainer}>
                  <Text style={styles.header}>Stocks</Text>
                  <SearchBar value={searchText} onChange={this.handleSearch} />
                </View>

                <Table
                  header={headerData}
                  gridConfig={[0.2, 0.1, 0.1, 0.1, 0.125, 0.125, 0.1, 0.1, 0.1]}
                  data={values}
                  minWidth={1500}
                />
              </View>
            </ScrollView>
          </ScrollView>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: "column", backgroundColor: "#fff" },
  headerStyle: { width: "100%", maxWidth: 1500 },
  loadingConainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: isMobile ? 16 : 36,
    marginBottom: 50,
    minWidth: isMobile
      ? 800
      : Dimensions.get("window").width - Dimensions.get("window").width / 4.89,
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 16 : 40,
    paddingVertical: 19,
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "space-between",
  },
  header: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "700",
    marginBottom: isMobile ? 10 : 0,
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  rowItem: {
    textAlign: "left",
  },
  rowItemContainerStyle: {
    justifyContent: "flex-start",
    paddingLeft: 10,
  },
  headerItemContainerStyle: {
    justifyContent: "flex-start",
  },
});

const toggleStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginLeft: isMobile ? 10 : 30,
    marginRight: isMobile ? 10 : 30,
    alignItems: "center",
    marginTop: isMobile ? 7 : 0,
  },
  item: {
    fontSize: isMobile ? 12 : 15,
    marginTop: isMobile ? -6 : 0,
    fontFamily: "Roboto",
  },
  active: {
    color: "#410DAA",
  },
  inActive: {
    color: "#6C7293",
  },
  switch: {
    marginLeft: isMobile ? 4 : 10,
    marginRight: isMobile ? 4 : 10,
    marginTop: isMobile ? -8 : 0,
  },
});

export default StocksPortfolio;
