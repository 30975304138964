import React, { Component } from 'react';
import { View, StyleSheet, SafeAreaView, TouchableOpacity, ViewPagerAndroidComponent } from 'react-native';
import { Modal, Text } from 'react-native-paper';
import Icon from "@expo/vector-icons/FontAwesome";

export default class StatusModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    render() {
        const {
            status = true,
            title,
            visible,
            message,
            buttononPress,
            buttons,
            icon
        } = this.props;
        return(
            <SafeAreaView style={{ ...this.props.style }}>
                <Modal
                    visible={visible}
                    style={[styles.bottomModal, { zIndex: 5 }]}
                >   
                    <View style={styles.modalContent}>
                        {title && (
                        <Text
                            style={{
                            fontSize: 13,
                            color: "#121212",
                            fontWeight: "bold",
                            marginBottom: 10
                            }}
                        >
                            {title}
                        </Text>
                        )}
                        {message && (
                        <Text
                            style={{
                            fontSize: 11,
                            color: "#121212",
                            marginBottom: 25
                            }}
                        >
                            {message}
                        </Text>
                        )}
            
                        {buttons.map(button => {
                        if (!button) return;
                        return (
                            <TouchableOpacity
                            style={[
                                {
                                backgroundColor: button.backgroundColor || "#410DAA",
                                width: "100%",
                                height: 50,
                                borderRadius: 5,
                                borderWidth: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                marginVertical: 6
                                },
                                button.style
                            ]}
                            onPress={() => {
                                button.buttonOnPress && button.buttonOnPress();
                            }}
                            >
                            <Text style={{ fontSize: 17, color: button.color || "#fff" }}>
                                {button.title}
                            </Text>
                            {icon && (
                                <Icon
                                name={icon.name}
                                type={icon.type}
                                style={{
                                    fontSize: 14,
                                    color: "#410DAA",
                                    marginLeft: 20
                                }}
                                />
                            )}
                            </TouchableOpacity>
                        );
                        })}
                    </View>
                </Modal>
            </SafeAreaView>
        )
    }
}

const styles = StyleSheet.create({
    bottomModal: {
        justifyContent: "flex-end",
        margin: 0
    },
    modalContent: {
        backgroundColor: "#fff",
        borderRadius: 5,
        borderColor: "rgba(0, 0, 0, 0.1)",
        padding: 16
    }
});
