import React from "react";
import {
    View,
    Text,
	Image,
	StyleSheet,
	TouchableOpacity
} from 'react-native';

const DownloadMobileApp = () => {
  return(
    <View style={styles.container}>
      	<View style={styles.subContainer}>
			<View style={styles.leftContainer}>
				<Image
                    source={{
                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/jama_qr.png",
                  }}
                  style={{
                    width: 200,
                    height: 200,
				  }}
				/>
				<Text style={styles.header}>Invest Smartly. Track Portfolios & Goals. On the go.</Text>
				<Text
					style={styles.paragraph}
				>Track your portfolio on the go, make investments and many more power packed features on the Jama Wealth Mobile App. Download now.
				</Text>
				<View style={{display: 'flex', flexDirection: 'row'}}>
					<TouchableOpacity
					onPress={()=>global.open("https://play.google.com/store/apps/details?id=in.jama.app")}>
				<Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/play-store-image.jpg",
                    }}
                    style={{
                      width: 150,
                      height: 80,
					  margin: 25,
                    }}
                    resizeMode="contain"
                  />
				  </TouchableOpacity>
				  <TouchableOpacity
				  	onPress={()=>global.open("https://apps.apple.com/in/app/jama-wealth-advisory/id6443719443")}
				  >
				  <Image
                	    source={{
                	      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/app-store-icon.jpg",
                	    }}
                	    style={{
                	      width: 140,
                	      height: 80,
                	      margin: 25,
                	    }}
                	    resizeMode="contain"
                	  />
					</TouchableOpacity>
				</View>
			</View>
			<View style={styles.rightContainer}>
				<Image
					source={{uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/mobile-screen.png"}}
					style={styles.img}
				/>
			</View>
		</View>
    </View>
  )
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#fff'
	},
	subContainer:{
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		padding: 50,
	},
	header: {
		fontWeight: '700',
		fontSize: 22,
		fontFamily: 'System',
		marginTop: 50
	},
	paragraph: {
		width: '80%',
		textAlign: 'center',
		fontWeight: '300',
		color: "gray",
		fontSize: 18,
		fontFamily: 'System',
		marginTop: 25
	},
	leftContainer: {
		backgroundColor: '#ddd',
		width: '50%',
		height: 600,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 15
	},
	rightContainer: {
		backgroundColor: '#fff',
		width: '50%',
		height: 600,
		justifyContent: 'center',
		alignItems: 'center',
	},
	img: {
		width: 500,
		height: 600,
        resizeMode: "contain",
	}
})

export default DownloadMobileApp;