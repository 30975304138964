import React from "react";
import { View, Text, ScrollView, StyleSheet } from "react-native";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import Toast from "react-native-toast-message";
import { setPassword } from "../../../api/account";
import TextInputField from "../../../components/Common/Inputs/InputField";
import { Button, ActivityIndicator } from "react-native-paper";
import { validateInput } from "../../../utils/validateInput";
import { isMobile } from "../../../utils";

export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      loading: false,
    };
  }

  submitRequest = async () => {
    const { newPassword, currentPassword,confirmPassword } = this.state;

    let body = {
      old_password: currentPassword,
      password: newPassword,
    };

    try {
      this.setState({
        loading: true,
        error: null,
      });
      const response = await setPassword(body);
      if (response.code == 100) {
        Toast.show({
          text1: "Changed successfully",
          visibilityTime: 2 * 1000,
          topOffset: 145,
          type: "success",
        });
        this.setState({
          loading: false,
          currentPassword: "",
          newPassword: "",
          confirmPassword:"",
        });
      } else {
        this.setState({
          loading: false,
          error: response.message,
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        error: "Something went wrong. Please try again.",
      });
    }
  };

  onSubmitPressed = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;

    if (!currentPassword || currentPassword.length == 0) {
      this.setState({
        error: "Please enter your current password.",
      });
      return;
    }

    if (!newPassword) {
      this.setState({
        error: "Please enter your new password.",
      });
      return;
    }

    if (newPassword == currentPassword) {
      this.setState({
        error: "New password should not be same as current password.",
      });
      return;
    }

    if (!confirmPassword) {
      this.setState({
        error: "Please enter your new password again to confirm.",
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      this.setState({
        error: "New Passwords does not match.",
      });
      return;
    }

    this.submitRequest();
  };

  handleBack = () => {
    this.props.navigation.goBack();
  };

  handleChange = (value, type, inputType) => {
    this.setState({
      [type]: value,
      [type + "Error"]: validateInput(inputType, value),
    });
  };

  render() {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    return (
      <View style={styles.container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Change Password"]}
          onPressBack={this.handleBack}
        />
        <ScrollView contentContainerStyle={styles.scrollViewContainer}>
          {!isMobile ? (
            <View style={{ marginLeft: 30 }}>
              <Text style={{ color: "red", fontSize: 12 }}>
                {this.state.error}
              </Text>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Current Password*"
                  value={currentPassword}
                  style={[{ ...styles.inputItem, width: "50%" }]}
                  editable={true}
                  onChange={(currentPassword) =>
                    this.handleChange(
                      currentPassword,
                      "currentPassword",
                      "text"
                    )
                  }
                  secureTextEntry
                />
              </View>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="New Password*"
                  value={newPassword}
                  style={[{ ...styles.inputItem, width: "50%" }]}
                  editable={true}
                  onChange={(newPassword) =>
                    this.handleChange(newPassword, "newPassword", "text")
                  }
                  secureTextEntry
                />
              </View>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Confirm New Password*"
                  value={confirmPassword}
                  style={[{ ...styles.inputItem, width: "50%" }]}
                  editable={true}
                  onChange={(confirmPassword) =>
                    this.handleChange(
                      confirmPassword,
                      "confirmPassword",
                      "text"
                    )
                  }
                  secureTextEntry
                />
              </View>
            </View>
          ) : (
            <>
              <Text style={{ color: "red", fontSize: 12 }}>
                {this.state.error}
              </Text>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Current Password*"
                  value={currentPassword}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  onChange={(currentPassword) =>
                    this.handleChange(
                      currentPassword,
                      "currentPassword",
                      "text"
                    )
                  }
                  secureTextEntry
                />
              </View>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="New Password*"
                  value={newPassword}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  onChange={(newPassword) =>
                    this.handleChange(newPassword, "newPassword", "text")
                  }
                  secureTextEntry
                />
              </View>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Confirm New Password*"
                  value={confirmPassword}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  onChange={(confirmPassword) =>
                    this.handleChange(
                      confirmPassword,
                      "confirmPassword",
                      "text"
                    )
                  }
                  secureTextEntry
                />
              </View>
            </>
          )}

          {!this.state.loading ? (
            !isMobile ? (
              <Button
                uppercase={false}
                mode={"contained"}
                onPress={this.onSubmitPressed}
                style={{
                  marginTop: 50,
                  width: "20%",
                  left: "30%",
                  right: "50%",
                  marginLeft: 16,
                }}
                loading={this.state.isLoading}
                color={"#410DAA"}
              >
                <Text style={styles.next}>Submit</Text>
              </Button>
            ) : (
              <Button
                uppercase={false}
                mode={"contained"}
                onPress={this.onSubmitPressed}
                style={{
                  marginTop: 30,
                  width: "50%",
                  marginLeft: "25%",
                }}
                loading={this.state.isLoading}
                color={"#410DAA"}
              >
                <Text style={styles.next}>Submit</Text>
              </Button>
            )
          ) : (
            <ActivityIndicator size="large" color="#410DAA" />
          )}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  scrollViewContainer: { flexGrow: 1, paddingHorizontal: 16, paddingTop: 16 },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  saveButton: { marginHorizontal: 16, bottom: 10, maxWidth: 400 },
  saveText: { fontSize: 14, color: "#fff" },
});
