const SIDE_NAV_WIDTH = 220;
export const xs = (width) => {
   return width  < 640
} 
export const sm = (width) => {
    return width  >= 640
 } 
 export const md = (width) => {
    return width  > 768
 } 
 export const lg = (width) => {
    return width  >= 1024
 } 
 export const xl = (width) => {
    return width  >= 1200
 } 
 export const twoxl = (width) => {
    return width  >= 1536
 } 