import React, {Component} from "react";
import {View, Text,Platform, StyleSheet, TouchableOpacity} from "react-native";
import { AntDesign, Ionicons } from '@expo/vector-icons';
import { StackActions } from "@react-navigation/native";
import amplitude from "../../../utils/amplitude";
import Modal from "../../../components/Common/Modal";
import { isMobile } from "../../../utils";

export const MFComplianceGroups = {
    "MinDetailsPan": "Contact Info & KYC",
    "MinDetailsEKYC": "Contact Info & KYC",
    "MinDetailsPersonal": "Contact Info & KYC",
    "MinDetailsAddress": "Contact Info & KYC",
    "MinDetailsNominee": "Contact Info & KYC",
    "MinDetailsProfessional": "Tax & FATCA",
    "MinDetailsSignature": "Signature",
    "MinDetailsBank": "Bank Account"
}

const MFComplianceButtonGroups = {
    "PanDetails": "Update KYC & Upload Documents",
    "AddAddress": "Update KYC & Upload Documents",
    "ContactInfo": "Update KYC & Upload Documents",
    "NomineeInfo": "Update KYC & Upload Documents",
    "FinancialInfo": "Tax & FATCA",
    "RiskProfile": "Complete Risk Profile",
    "Assets": "Complete Risk Profile",
    "eSign": "e-Sign agreement",
    "Payment": "Pay Advisory Fees",
    "BankAccount": "Upload bank proof"
}

export default class MFComplianceModal extends Component {
    constructor(props){
        super(props);
        this.state={
            isLoading: this.props.isLoading,
            pendingItems: {},
            loaded: false
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(this.state.loaded == true ) {
            return false;
        }
        return true;
    }

    async componentDidMount(){
        if (Platform.OS == "web"){
            amplitude.logEvent("mfcomplianceModal",{ path: global.location.href })
          }
          else{
            amplitude.logEvent("mfComplianceModal", { path: this.props.navigation.getState().routeName });
          }
        const { forceLoad } = this.props;
        if(forceLoad){
            await this.getData();
        }
    }

    getData = async () => {
        try {
            const {minDetailsResponse} = this.props;
            let { needCompletion = "DONT", error } = minDetailsResponse;
            let pendingItems = {};
            if(!error && needCompletion ){
                const { results } = minDetailsResponse;
                // console.log(results, minDetailsResponse);
                results.forEach(item=>{
                    const { redirect_screen, is_completed } = item;
                    const title = MFComplianceGroups[redirect_screen] || "";
                    if(title in pendingItems){
                        pendingItems[title] = pendingItems[title] && is_completed;
                    } else{
                        pendingItems[title] = is_completed;
                    }
                } );
                needCompletion = "SHOW";
            }
            this.setState({ loaded: true, isLoading: false, minDetailsResponse, error, needCompletion, pendingItems })
        } catch (error) {
            this.setState({ error: true, loaded: true, isLoading: false });
        }
    }

    async componentDidUpdate(){
        if(!this.props.isLoading && !this.state.loaded){
            await this.getData();
        }
    }

    moveToNextScreen = async () => {
        try {
            const { minDetailsResponse } = this.state;
            const { needCompletion, error } = minDetailsResponse;
            if(!error){
                const { redirect_link, redirect_screen } = minDetailsResponse;
                if(needCompletion){
                    let options = {};
                    if(this.props.userId){
                        options["user_id"] = this.props.userId;
                    }
                    this.props.navigation.navigate("MinDetails", {
                        screen: redirect_screen,
                        params: {
                            nvg: this.props.redirectBackTo || "InvestmentOverview",
                            action: true ,
                            first: true,
                            ...options
                        }
                    })
                }
            } else {
                this.setState({
                    isLoading: false,
                    error: true,
                    errorText: "Something went wrong! Please contact help@maxiom.in"
                })
            }
        } catch (error) {
            this.setState({
                isLoading: false,
                error: true,
                errorText: "Something went wrong!"
            })
        }
    }

    goToDashboard = () => {
        if(this.props.onClose) {
            this.props.onClose();
        } else{
            const redirect_screen = "InvestmentOverview";
            this.props.navigation.dispatch(
                StackActions.replace(redirect_screen)
            );
        }
    }

    // isMobile? Platform.OS=="web" ? styles.modalMobile : modalWeb : styles.modalWeb
    render(){
        const {pendingItems, needCompletion, isLoading} = this.state;
        if( isLoading || needCompletion == false ) return null;
        return(
            <Modal
                style={[ styles.modalWeb ]}
                containerStyle={styles.containerStyle}
            >
                <View style={[styles.mainContaner]}>
                    <View style={{flexDirection:"row",justifyContent:"space-between"}}>
                    <Text style={styles.wel}>Action Required for Compliance</Text>
                    <TouchableOpacity onPress={this.goToDashboard} >
                        <AntDesign name="close" size={24} color="rgba(0, 0, 0, 0.5)" />
                    </TouchableOpacity>
                    </View>
                    <Text style={styles.comp}>SEBI regulation mandates the following to be in Place</Text>
                    <View style={{flexDirection:"row", flexWrap:"wrap"}}>
                    {
                            Object.keys(pendingItems).map((title, index)=> {
                                const is_completed = pendingItems[title];
                                const firstItem = index == 0;
                                if(!is_completed) {
                                    return(
                                        <Text style={[styles.kyc, styles.padding]} key={index}>
                                            {title}
                                            <Ionicons name="alert-circle" size={ isMobile ? 8 : 14} color="#F64E60" style={{paddingLeft: 3}} />
                                        </Text>     
                                    )
                                }
                                return(
                                    <Text style={[styles.item, styles.completed, styles.padding, firstItem && { paddingLeft: 0}]} key={index}>
                                        {title}
                                        <Ionicons name="checkmark-circle-sharp" size={ isMobile ? 8 : 10} color="#009105" style={{paddingLeft: 3}} />
                                    </Text>
                                )
                            })
                        }
                    </View>
                    <TouchableOpacity
                        style={{alignItems:"center", justifyContent:"center"}}
                        onPress={this.moveToNextScreen}
                    >
                        <Text style={styles.comp_now}>{ !this.props.userId ? "Continue to Comply" : "View Mode"}</Text>
                    </TouchableOpacity>
                    <Text style={styles.take}>Takes only a few minutes</Text>
                </View>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    mainContaner: {
        paddingTop: isMobile ? 16: 37,
        paddingBottom: isMobile ? 17: 23,
        paddingHorizontal: isMobile ? 5: 33,
        minWidth: isMobile ? 200 : 633,
        marginHorizontal: isMobile ? 16 : 0
    },
    containerStyle:{
        marginHorizontal: isMobile ? 16 : 0,
        borderRadius: 5
    },
    comp_now:{
        fontFamily:"Roboto",
        fontWeight:"bold",
        fontSize: isMobile ? 13 : 15,
        lineHeight:17,
        color:"#ffffff",
        paddingVertical:12,
        paddingHorizontal:84,
        backgroundColor:"#410DAA",
        borderRadius:4,
        marginTop:58
     },
     kyc:{
         fontFamily:"Roboto",
         fontSize: isMobile ? 11 : 13,
        //  lineHeight:27,
         color:"#F64E60",
         paddingHorizontal: 5,
     },
     take:{
        fontFamily:"Roboto",
        fontSize: isMobile ? 12 : 15,
        lineHeight:17,
        color:"rgba(18, 18, 18, 0.6)",
        textAlign:"center",
        paddingTop:10
     },
     modalMobile:{
         position: "absolute",
         top: 0,
        justifyContent: 'center', alignItems: 'center'
     },
     modalWeb: {
        // position:"fixed",
        // top:0,
        // position:"fixed",
        // position: "absolute",
        paddingTop: 150,
        justifyContent: 'center', alignItems: 'center'
     },
     wel:{
        fontFamily:"Roboto",
        fontWeight:"bold",
        fontSize:18,
        lineHeight:21,
        color:"#F64E60"
    },
    comp:{
        fontFamily: "Roboto",
        fontSize: 14,
        lineHeight:21,
        color:"#121212",
        paddingTop:12,
        paddingBottom:31
    },
    item: {
        paddingHorizontal: 5,
        fontSize: isMobile ? 11 : 13,
        // marginTop: 5
    },
    completed: {color:"#009105"},
    padding: {
        paddingVertical: isMobile ? 5 : 0
    }
})