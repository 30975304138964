import React, { Component, useEffect } from "react";
import {
  View,
  SafeAreaView,
  StyleSheet,
  Text,
  ScrollView,
  TouchableOpacity,
  Platform,
  Linking,
} from "react-native";
import { connect } from "react-redux";
import TextInputField from "../../../components/Common/Inputs/InputField";
import PickerField from "../../../components/Common/Pickers/PickerField";
import { Button } from "react-native-paper";

import { validateInput } from "../../../utils/validateInput";
import { SideHeading } from "../../../components/Common/sideHeading";
import amplitude from "../../../utils/amplitude";
import theme from "../../../static/theme";
import HorizontalRadio from "../../../components/Common/Radio/HorizontalRadio";
import {
  getUserDetails,
  isMobile,
  redirectToPendingScreen,
  DataURIToBlob,
} from "../../../utils";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { useFilePicker } from "use-file-picker";
import { addPanNumber, getCustomerDetails } from "../../../api";
import Entypo from "@expo/vector-icons/Entypo";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import DocumentPicker, { types } from "expo-document-picker";
import Toast from "react-native-toast-message";
import Store from "../../../store";
import FileViewer from 'react-native-file-viewer';

const taxstatuses = [
  {
    key: 2,
    label: "Individual",
    value: "01",
  },
  {
    key: 3,
    label: "NRE (NRI)",
    value: "21",
  },
  {
    key: 4,
    label: "NRO (NRI)",
    value: "24",
  },
];

const SOURCE_OF_WEALTH = [
  {
    key: 1,
    label: "Salary",
    value: "01",
  },
  {
    key: 2,
    label: "Business Income",
    value: "02",
  },
  {
    key: 3,
    label: "Gift",
    value: "03",
  },
  {
    key: 4,
    label: "Ancestral Property",
    value: "04",
  },
  {
    key: 5,
    label: "Rental Income",
    value: "05",
  },
  {
    key: 6,
    label: "Prize Money",
    value: "06",
  },
  {
    key: 7,
    label: "Royalty",
    value: "07",
  },
  {
    key: 8,
    label: "Others",
    value: "08",
  },
];

const APPLICANT_INCOME_SLAB = [
  {
    key: 1,
    label: "Below 1 Lakh",
    value: "31",
  },
  {
    key: 2,
    label: "> 1 <= 5 Lacs",
    value: "32",
  },
  {
    key: 3,
    label: ">5 <= 10 Lacs",
    value: "33",
  },
  {
    key: 4,
    label: ">10 <= 25 Lacs",
    value: "34",
  },
  {
    key: 5,
    label: ">25 <= 1 Crore",
    value: "35",
  },
  {
    key: 6,
    label: "Above 1 Crore",
    value: "36",
  },
];

const SelectPanImage = ({ onSelect, panImage, file = null }) => {
  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: "DataURL",
    accept: [".png", ".jpg", ".jpeg", ".pdf"],
    multiple: true,
    limitFilesConfig: { max: 1 },
    maxFileSize: 1.0,
  });

  useEffect(() => {
    onSelect(filesContent, errors);
  }, [filesContent]);

  if (loading) {
    return <Text>Loading</Text>;
  }

  const isFileSelected = () => {
    return filesContent.length != 0;
  };

  const viewPAN = () => {
    // Check if filesContent has the uploaded assets
    if (filesContent.length > 0 && filesContent[0].content) {
        const fileUri = filesContent[0].content; // Get the URI of the uploaded file
        // console.log("File URI:", fileUri);
  
        // Open the uploaded file in a new tab
        if (fileUri.startsWith("data:")) {
            // If it's a base64 string, create an image element to display it
            const imgElement = document.createElement('img');
            imgElement.src = fileUri;
            imgElement.alt = "Uploaded Image";
            imgElement.style.maxWidth = "100%"; // Adjust as needed
            const newWindow = window.open();
            newWindow.document.body.appendChild(imgElement); // Append to the new window
        } else {
            // If it's a URL, open it directly
            window.open(fileUri, '_blank');
        }
    } else if (panImage) {  // Handle the case when there's a specific image (like a PAN image)
        const url = `https://jamaappprod.s3.ap-south-1.amazonaws.com/media/${panImage}`;
        // console.log("Pan image URL:", url);
        window.open(url, '_blank');  // Open the pan image URL in a new tab
    } else {
        // Alert if no file has been uploaded yet
        // alert("No file uploaded yet!");
        Toast.show({
          text1: "No file uploaded yet!",
          visibilityTime: 2 * 1000,
          topOffset: 7,
          type: "error",
        });
    }
};
  
  

  const checkSizeError = () => {
    if (errors && errors.length) {
      return {
        fileSizeToolarge: true,
        error: true,
      };
    }
    return null;
  };

  const handleFileSelector = async () => {
    try {
      if (Platform.OS == "web") {
        openFilePicker();
      } else {
        const result = await DocumentPicker.pickSingle({
          allowMultiSelection: false,
          type: [types.images],
        });
        onSelect([{ content: result.uri, name: result.name, ...result }]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const errorData = checkSizeError();
  const fileName = file ? file.slice(0, 50) : "  ";
  return (
    <View
      style={{
        flexDirection: "column",
        paddingTop: 31.2,
        marginRight: isMobile ? 0 : 61,
        marginTop: -20,
        marginBottom: 10,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontFamily: "Roboto",
            fontSize: 13,
            fontWeight: "700",
            color: "#464E5F",
            marginBottom: 11,
          }}
        >
          {panImage != null ? "Update" : "Upload"} Your PAN Photocopy*
        </Text>
        {panImage && (
          <TouchableOpacity onPress={() => viewPAN(panImage)}>
            <Text
              style={{
                fontSize: 11,
                marginLeft: 10,
                textDecorationLine: "underline",
                color: "#410DAA",
                fontWeight: "bold",
                textAlign: "left",
                marginTop: -11,
              }}
            >
              View uploaded PAN
            </Text>
          </TouchableOpacity>
        )}
      </View>
      <TouchableOpacity
        onPress={handleFileSelector}
        style={{
          alignItems: "center",
          paddingVertical: 28,
          backgroundColor: "#f6f6f6",
          borderRadius: 4,
          borderWidth: 1,
          borderColor: "#E2E8F0",
        }}
      >
        <Text style={styles.drop}>
          <Entypo name="attachment" size={20} color="#13C37B" /> Click here to
          browse and attach file
        </Text>
      </TouchableOpacity>
      <Text style={styles.fileName}>{fileName}</Text>
      <Text
        style={[
          styles.support,
          errorData &&
            errorData.error &&
            !errorData.fileSizeToolarge &&
            styles.error,
        ]}
      >
        Supports PNG, JPG, JPEG, PDF.{" "}
        <Text style={[errorData && errorData.fileSizeToolarge && styles.error]}>
          Max File Size:1MB
        </Text>
      </Text>
    </View>
  );
};

class AddProfessionalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static navigationOptions = () => ({
    tabBarVisible: false,
  });

  componentDidMount = async () => {
    if (Platform.OS == "web") {
      amplitude.logEvent("AddProfessionalDetails", {
        path: global.location.href,
      });
    } else {
      amplitude.logEvent("AddProfessionalDetails", {
        path: this.props.navigation.getState().routeName,
      });
    }
    const {
      tax_status,
      source_of_wealth,
      annual_income_slab,
      is_politically_exposed_person,
      pan_number,
      customer_id,
    } = getUserDetails();
    let response = await getCustomerDetails(customer_id);
    let pan_image = response.pan_image;
    const taxstatusError = validateInput("select", tax_status);
    const sourceOfWealthError = validateInput("select", source_of_wealth);
    const incomeSlabError = validateInput("select", annual_income_slab);
    const politicallyExposedError = validateInput(
      "select",
      is_politically_exposed_person
    );

    this.setState({
      pan_number,
      pan_image,
      taxstatus: tax_status,
      taxstatusError,
      sourceOfWealth: source_of_wealth,
      sourceOfWealthError,
      incomeSlab: annual_income_slab,
      incomeSlabError,
      politicallyExposed: is_politically_exposed_person,
      politicallyExposedError,
    });
  };

  handleChange = (value, type, inputType) => {
    this.setState({
      [type]: value,
      [type + "Error"]: validateInput(inputType, value),
    });
  };

  addProfessionalDetails = async () => {
    try {
      const {
        taxstatus,
        sourceOfWealth,
        incomeSlab,
        politicallyExposed,
        isLoading,
      } = this.state;

      if (!this.validate() || isLoading) return;

      if (!this.state.pan_image && !this.state.imageURI) {
        this.setState({
          panImageError: "Please select PAN copy",
          isLoading: false,
        });
        return;
      }
      // console.log('console.log(this.state.imageURI)',this.state.imageURI)

      const { customer_id, username } = getUserDetails();


      let body = new FormData();
      body.append("username", username);
      body.append("tax_status", taxstatus);
      body.append("is_non_india_tax_resident", false);
      body.append("source_of_wealth", sourceOfWealth);
      body.append("is_politically_exposed_person", politicallyExposed);
      body.append("annual_income_slab", incomeSlab);

      if (this.state.imageURI) {
        const imageFile = DataURIToBlob(this.state.imageURI);
        const imageName = this.state.imageName
        // console.log('imageFile',imageFile)
        body.append("pan_image",
          imageFile,
          imageName);
      }
      if (sourceOfWealth === "0"){
        this.setState
        return;
      }
      this.setState({ isLoading: true });
      const response = await addPanNumber(customer_id, body);
      if (response && response.hasOwnProperty("username")) {
        // alert("Saved Successfully")
        Toast.show({
          text1: "Saved Successfully",
          visibilityTime: 2 * 1000,
          topOffset: 7,
          type: "success",
        });
        Store.dispatch({
          type: "SAVE_PROFILE_DATA",
          payload: response,
        });
        if (this.props.minDetailsFlow) {
          const redirect = await redirectToPendingScreen(this.props);
          if (redirect && redirect.error) {
          }
        }
      }

      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  validate = () => {
    const { taxstatus, politicallyExposed, incomeSlab, sourceOfWealth } =
      this.state;
    return (
      incomeSlab &&
      sourceOfWealth &&
      politicallyExposed != null &&
      this.state.taxstatus != "SEL" &&
      taxstatus
    );
  };

  render() {
    const {
      taxstatus,
      taxstatusError,
      sourceOfWealth,
      sourceOfWealthError,
      incomeSlab,
      incomeSlabError,
      politicallyExposed,
      politicallyExposedError,
      pan_number,
    } = this.state;
    const from = true;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        {!this.props.minDetailsFlow && (
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Professional Details"]}
          />
        )}
        {!from && (
          <SideHeading
            title={"Add Professional Details"}
            style={{ fontSize: 20, alignItems: "center" }}
          />
        )}
        <ScrollView
          showsVerticalScrollIndicator={true}
          style={{ marginHorizontal: 16 }}
          contentContainerStyle={{
            paddingBottom: 100,
          }}
        >
          {!isMobile ? (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="PAN Number"
                  value={pan_number ? pan_number.toUpperCase() : pan_number}
                  style={[{ ...styles.inputItem }]}
                  inputStyle={[{ paddingLeft: 24 }]}
                  editable={false}
                />
              </View>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={APPLICANT_INCOME_SLAB}
                  value={incomeSlab}
                  label="Salary Range*"
                  onChange={(incomeSlab) =>
                    this.handleChange(incomeSlab, "incomeSlab", "select")
                  }
                  pickerStyle={[{ ...styles.inputItem }]}
                  isValid={incomeSlabError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select" }}
                />
              </View>
            </View>
          ) : (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="PAN Number"
                  value={pan_number ? pan_number.toUpperCase() : pan_number}
                  style={[{ ...styles.inputItem }]}
                  inputStyle={[{ paddingLeft: 24 }]}
                  editable={false}
                />
              </View>
              <View style={[styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={APPLICANT_INCOME_SLAB}
                  value={incomeSlab}
                  label="Salary Range*"
                  onChange={(incomeSlab) =>
                    this.handleChange(incomeSlab, "incomeSlab", "select")
                  }
                  pickerStyle={[{ ...styles.inputItem }]}
                  isValid={incomeSlabError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select" }}
                />
              </View>
            </>
          )}
          {!isMobile ? (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={taxstatuses}
                  value={taxstatus}
                  label="Tax Status*"
                  onChange={(taxstatus) =>
                    this.handleChange(taxstatus, "taxstatus", "select")
                  }
                  pickerStyle={[{ ...styles.inputItem }]}
                  isValid={taxstatusError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select Tax Status" }}
                />
              </View>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={SOURCE_OF_WEALTH}
                  value={sourceOfWealth}
                  label="Source of Wealth*"
                  onChange={(sourceOfWealth) => {
                    this.handleChange(sourceOfWealth, "sourceOfWealth", "select");
                    if (sourceOfWealth === "0") {
                      this.setState({ sourceOfWealthError: "Please select a valid Source of Wealth" });
                    } else {
                      this.setState({ sourceOfWealthError: null });
                    }
                  }}
                  pickerStyle={[{ ...styles.inputItem }]}
                  isValid={sourceOfWealthError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select" }}
                />
                {sourceOfWealthError &&(
                  <Text style = {styles.error}>{sourceOfWealthError}</Text>
                )}
              </View>
            </View>
          ) : (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={taxstatuses}
                  value={taxstatus}
                  label="Tax Status*"
                  onChange={(taxstatus) =>
                    this.handleChange(taxstatus, "taxstatus", "select")
                  }
                  pickerStyle={[{ ...styles.inputItem }]}
                  isValid={taxstatusError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select" }}
                />
              </View>
              <View style={[styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={SOURCE_OF_WEALTH}
                  value={sourceOfWealth}
                  label="Source of Wealth"
                  onChange={(sourceOfWealth) =>
                    this.handleChange(
                      sourceOfWealth,
                      "sourceOfWealth",
                      "select"
                    )
                  }
                  pickerStyle={[{ ...styles.inputItem }]}
                  isValid={sourceOfWealthError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select" }}
                />
              </View>
            </>
          )}
          <SelectPanImage
            onSelect={(filesContent) => {
              if (filesContent && filesContent.length > 0) {
                // {console.log('filesContent',filesContent[0])}
                const {
                  content: imageURI,
                  name: imageName,
                  type: imageType,
                } = filesContent[0];
                this.setState({
                  imageURI,
                  imageName,
                  imageType,
                });
              }
            }}
            panImage={this.state.pan_image}
            file={
              this.state.imageName ? this.state.imageName : this.state.pan_image
            }
          />
          <Text
            style={[styles.panText, this.state.panImageError && styles.error]}
          >
            {this.state.pan_image ? " " : this.state.panImageError}
          </Text>
          {!isMobile ? (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <HorizontalRadio
                  title={"Are you politically exposed person?*"}
                  style={{
                    paddingVertical: 0,
                    marginVertical: 0,
                  }}
                  selected={politicallyExposed}
                  radioButtonValues={["No", "Yes"]}
                  values={[0, 1]}
                  onSelect={(index, value) => {
                    this.handleChange(value, "politicallyExposed", "select");
                  }}
                  isValid={politicallyExposedError}
                />
              </View>
            </View>
          ) : (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <HorizontalRadio
                  title={"Are you politically exposed person?*"}
                  style={{
                    paddingVertical: 0,
                    marginVertical: 0,
                  }}
                  selected={politicallyExposed}
                  radioButtonValues={["No", "Yes"]}
                  values={[0, 1]}
                  onSelect={(index, value) => {
                    this.handleChange(value, "politicallyExposed", "select");
                  }}
                  isValid={politicallyExposedError}
                />
              </View>
            </>
          )}
          {!isMobile ? (
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={this.addProfessionalDetails}
              style={{
                marginTop: 50,
                width: "20%",
                left: "40%",
                right: "40%",
              }}
              loading={this.state.isLoading}
              color={"#410DAA"}
            >
              <Text style={styles.next}>Save</Text>
            </Button>
          ) : (
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={this.addProfessionalDetails}
              style={{
                marginTop: 30,
                width: "40%",
                marginLeft: "30%",
              }}
              loading={this.state.isLoading}
              color={"#410DAA"}
            >
              <Text style={styles.next}>Save</Text>
            </Button>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.MAIN_BACKGROUND,
  },
  fileName: {
    fontSize: 15,
    marginTop: 5,
    color: "#410DAA",
    fontWeight: "bold",
  },
  support: {
    fontSize: 11,
    color: "#121212",
    fontStyle: "italic",
    marginTop: 4,
    fontWeight: "500",
  },
  error: { color: "red" },
  panText: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3.3,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export default connect(null)(AddProfessionalDetails);
