import React from "react";
import { View } from "react-native";

const Divider = ({ color, width, style }) => {
  return (
    <View
      style={[
        {
          borderBottomColor: color ? color : "black",
          borderBottomWidth: width ? width : 1
        },
        style
      ]}
    />
  );
};

export default Divider;
