import { SHOW_GET_STARTED } from "../actions/types";

const initialState = {
  shouldShow: "true",
};

const showGetStartedReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_GET_STARTED:
      return {
        ...state,
        shouldShow: action.payload.shouldShow,
      };
    default:
      return state;
  }
};

export default showGetStartedReducer;
