import React from "react";
import { SafeAreaView, Platform } from "react-native";
import NavigationHeader from "../../../components/Common/NavigationHeader";
const WebView =
  Platform.OS == "web"
    ? require("react-native-web-webview").WebView
    : require("react-native-webview").WebView;

class InvestorCharter extends React.Component {
  render() {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "#fff",
        }}
      >
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Investor Charter"]}
        />
        {Platform.OS == "web" &&
        global.location.hostname == "demo.simplygrow.in" ? null : (
          <WebView
            source={{ uri: "https://maxiomwealth.com/investor-charter/" }}
          />
        )}
      </SafeAreaView>
    );
  }
}

export default InvestorCharter;
