import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";

const _getColor = status => {
  let color = "#121212";
  switch (status) {
    case "Email Pending":
    case "Missing Fields":
      color = "#EF5D12";
      break;
    case "Under Process":
    case "Wrong Format":
      color = "#EFA512";
      break;
    case "Portfolio Imported":
    case "Portfolio Uploaded":
      color = "#009105";
      break;
  }
  return color;
};
export const ImportedPortfolioStatus = ({ type, status, file }) => {
  const color = _getColor(status);
  // const date = type.split(" ");
  // const date_values = date[0].split("-");
  // const month = months_short_names(parseInt(date_values[1]));
  return (
    <View
      style={{
        flexDirection: "row",
        paddingVertical: 5,
        alignItems: "flex-start"
      }}
    >
      <View
        style={{
          width: 6,
          height: 6,
          borderRadius: 3,
          backgroundColor: color,
          marginTop: 6
        }}
      ></View>
      <View style={{ flexDirection: "column", flex: 0.6, paddingLeft: 10 }}>
        <Text style={{ fontSize: 13, color: "#121212" }}>{file}</Text>
        <Text style={{ fontSize: 13, color: "#707070" }}>{type}</Text>
      </View>
      <Text
        style={{
          flex: 0.35,
          alignSelf: "flex-start",
          textAlign: "right",
          fontSize: 13,
          color
        }}
      >
        {status}
      </Text>
    </View>
  );
};
