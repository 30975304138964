import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Keyboard,
  Platform,
  Linking,
} from "react-native";
import {
  addPanNumber,
  checkCustomerSignup,
  checkUserExistsBasedOnPan,
  generateCamsAggregatorLink,
  getLeadData,
  getLoginBannerDetails,
  mobileOtpVerificationBeforeSignup,
  onboardingSendOTP,
  SignUpUser,
} from "../../../api";
import { FontAwesome5 } from '@expo/vector-icons';
import { md } from "../../../Responsive";
import { completeMinDetails, getUserDetails, isMobile, isWeb, navigateToTransaction } from "../../../utils";
import Header from "../header_after_login";
const { width } = Dimensions.get("window");
import { saveUserProfileData, logIn } from "../../../actions";
import { connect } from "react-redux";
import { StackActions } from "@react-navigation/routers";
import amplitude from "../../../utils/amplitude";
import { ShowToast } from "../../../components/Common/Toast";
import Toast from "react-native-toast-message";
import { Link } from "@react-navigation/native";
import Progressbar from "../progressbar";
import MiniOnBoardingProgressBar from "./MiniOnBoardingProgressBar";
import Store from "../../../store";
import { checkOrFetchPAN, saveCustomerProfileData } from "../../../api/account";
import ProgressBar from "../../../utils/progressBar";
import Checkbox from "expo-checkbox";

class Pan extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let corp_code = "";
    let subscription_plan_code = "";
    let lead_id = "";
    let from = null;
    let name = "";
    if (params) {
      corp_code = params.ccd || "";
      subscription_plan_code = params.pcd || "";
      lead_id = params.ldid || "";
      from = params.from || "";
    }
    const { username, customer_id } = Store.getState().userData.userProfileData;
    let isCorpCode = false;
    if (corp_code != "") {
      isCorpCode = true;
    }
    this.state = {
      data: null,
      name: username || "",
      isLoading: false,
      cust_id: customer_id,
      is_ria: false,
      showOTP: false,
      showResendOTP: false,
      mobileError: "",
      check_import_portfolio: true,
      corp_code,
      isCorpCode,
      panNumber: "",
      subscription_plan_code,
      show_corp_code_box: false,
      lead_id,
      is_correct_lead: false,
      error: false,
      errorText: "",
      nameError: "",
      emailError: "",
      mobileError: "",
      passwordError: "",
      repasswordError: "",
    }
  }

  handleCampsAPI = async () => {
    let userData = await getUserDetails();
    let payload = {
      mobilenumber: userData.mobilenumber,
      pannumber: this.state.panNumber,
      useCaseid: "74",
      fipid: "CAMC,Cams Depository",
      redirect_url: global.location.href,
    };
    this.setState({ isLoading: true });
    let response = await generateCamsAggregatorLink(payload);
    this.setState({ isLoading: false });
    if (Platform.OS === "web") {
      global.open(response.result.redirectionurl, "_self");
    } else {
      Linking.openURL(response.result.redirectionurl);
    }
  }


  handleSendOTP = async () => {
    if (this.state.panNumber.length < 10) {
      this.setState({ error: true, errorText: "Please enter valid PAN number" })
      return;
    }
    this.setState({ isLoading: true, error: false });
    const checkPanPayload = {
      pan: this.state.panNumber,
    };
    const checkPanResp = await checkUserExistsBasedOnPan(checkPanPayload);
    console.log("Check Pan Responce is ", checkPanResp);
    let res = checkPanResp.results[0];
    if (res) {
      console.log("The Response Data we get is ", res);
      if (res.pan_exists_in_club == "FALSE" && res.pan_exists_in_advisory == "FALSE") {
        // console.log("New User")
        let body = new FormData();
        body.append("username", this.state.name);
        body.append("pan_number", this.state.panNumber);
        console.log("Username and pan number is %s %s", this.state.name, this.state.panNumber);
        const response = await addPanNumber(this.state.cust_id, body);
        if (response && response.pk) {
          const payload = {
            pan_number: this.state.panNumber,
            name_as_in_pan: this.state.name,
            search_with: "CKYC",
          };
          const fetchResponse = await checkOrFetchPAN(payload);
          console.log("fetchResponse is ", fetchResponse);
          if (fetchResponse && fetchResponse.code == 100) {
            const { searched_with = null } = fetchResponse.results.length
              ? fetchResponse.results[0]
              : {};
            if (searched_with == "CKYC") {
              if (searched_with == "camps") {
                this.handleCampsAPI();
              }
              const minDetailsResponse = await completeMinDetails();
              const { needCompletion, error } = minDetailsResponse;
              if (!error) {
                this.props.navigation.navigate("SignUpImportPortfolio")
              } else {
                this.setState({
                  error: true,
                  errorText:
                    "Something went wrong! Please contact help@maxiom.in",
                });
              }
            } else {
              this.props.navigation.navigate("KYCCreate");
            }
          } else {
            this.setState({
              isUploading: false,
            });
            this.props.navigation.navigate("KYCCreate");
          }
        } else {
          this.setState({ errorText: "Something went wrong" });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState({
          isLoading: false,
          showToast: true,
          toastMsg:
            "Given PAN number already exists in system. Please contact our customer care for further help.",
          isUploading: false,
        });
      }
    }
    this.setState({ isLoading: false })
  }

  handleSkip = async () => {
    const { customer_id, customerprofile_id } = getUserDetails();
    const body = {
      customer: customer_id,
      skip_onboarding_import_portfolio: true
    };
    await saveCustomerProfileData(customerprofile_id, body);
    this.props.navigation.navigate("Onboarding",{
      screen: "SignUpImportPortfolio",
    });
  }


  render() {
    const {
      isLoading,
      panNumber,
      error,
      errorText,
      check_import_portfolio
    } = this.state;
    return isLoading ? (
      <View style={{ flex: 1 }}>
        <ProgressBar msg={"Loading details..."} />
      </View>
    ) : (
      <View style={styles.container}>
        <Header
          navigation={this.props.navigation}
          route={this.props.route}
        />

        {(this.state.is_preferred_circle || this.state.is_club) &&
          this.state.from ? (
          <ShowToast
            showToast={true}
            type="success"
            title={`Congratulations! You have successfully subscribed to our ${this.state.is_preferred_circle
              ? "Advisory Circle"
              : "Investor Club"
              } plan.`}
          />
        ) : null}
        {this.state.showOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent successfully."
          />
        ) : null}
        {this.state.showResendOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent successfully."
          />
        ) : null}
        <MiniOnBoardingProgressBar
          color2="#410DAA"
          color3="#f6f6f6"
          color4="#f6f6f6"
          color5="#f6f6f6"
          color6="#f6f6f6"
          color11="#f6f6f6"
          color12="#f6f6f6"
          bcolor1="#410DAA"
          bcolor2="#410DAA"
          bcolor3="#410DAA"
          bagcolor1="#410DAA"
          bagcolor2="#410DAA"
          bagcolor3="#410DAA"
          markdisplay1="none"
          markdisplay2="none"
          markdisplay3="none"
          dotdisplay1="none"
          dotdisplay2="none"
          dotdisplay3="none" />
        <ScrollView keyboardShouldPersistTaps="always">
          <View style={styles.body}>
            {isWeb && (
              <View style={styles.left}>
                <View style={{ paddingBottom: 50 }}>
                  <View style={{ opacity: 0 }}>
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-2.png"
                      }
                      style={styles.left_logo}
                    ></Image>
                  </View>
                  <View style={{ position: 'absolute', zIndex: 10 }}>
                    <View style={{ marginBottom: 25 }}>
                      <Text style={{ color: '#410DAA', fontSize: 32, fontWeight: '700' }}>Why check for KYC ?</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>1. Ensures smooth and uninterrupted transactions.</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>2. Keeps you compliant with financial regulations.</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>3. Verifies your identity for any interactions with advisor.</Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
            <View style={styles.right}>
              <View style={[isMobile ? styles.mob_styl : styles.web_styl]}>
                <View style={{ width: '95%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                  <TouchableOpacity onPress={this.handleSkip}>
                    <Text style={{
                      color: '#410DAA',
                      textDecorationLine: 'underline',
                      fontWeight: '500',
                    }}>Skip Pan for now</Text>
                  </TouchableOpacity>
                </View>
                {/* <View style={{ alignItems: "center", marginTop: 32, width: '100%' }}>
                  {bannerData.is_jamawealth ? (
                    <Image
                      source={require("../../../assets/jw-r-logo.png")}
                      style={{ width: '100%', height: 100 }}
                      resizeMode={"contain"}
                     />
                  ) : null}
                </View> */}
                <View style={styles.inputsContainer}>
                  <View style={styles.inputContainer}>
                    <Text style={styles.mob_num}>Quick KYC check in a few seconds</Text>

                    <TextInput
                      value={panNumber}
                      placeholder={"Eg: AAAA 1234A"}
                      //placeholderTextColor="#00000030"
                      style={styles.dmart_body_md}
                      onChangeText={(text) => {
                        const panRegEx =
                          /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
                        const panValid = RegExp(panRegEx).test(text);
                        let errors = {};
                        if (panValid) {
                          errors["panNumberError"] = null;
                        }
                        this.setState({
                          panNumber: text ? text.toUpperCase() : "",
                          ...errors,
                          error: false
                        });
                      }}
                    />
                    {error && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {errorText || " "}
                      </Text>
                    )}
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Checkbox
                      status={check_import_portfolio ? "checked" : "unchecked"}
                      color={"#C1C1C1"}
                      uncheckedColor={"#C1C1C1"}
                      value={check_import_portfolio}
                      onValueChange={() => {
                        if (check_import_portfolio) {
                          this.setState({
                            check_import_portfolio: false,
                          });
                        } else {
                          this.setState({
                            check_import_portfolio: true,
                          });
                        }
                      }}
                    />
                    <Text
                      style={{
                        paddingTop: 5,
                        fontSize: 14,
                        marginTop: 5,
                        fontWeight: "600",
                        color: "#878EA8",
                        textAlign: isMobile ? "right" : "left",
                      }}
                    >
                      Account Aggregator Fetch
                    </Text>
                  </View>
                </View>
                {isLoading ? (
                  <ActivityIndicator
                    size="large"
                    color="#410DAA"
                    style={{ paddingTop: 40 }}
                  />
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      Keyboard.dismiss();
                      this.handleSendOTP();
                    }}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: isMobile ? 20 : 0,
                      marginTop: 80
                    }}
                  >
                    <Text style={styles.req_otp}>Continue</Text>
                  </TouchableOpacity>
                )}
                {/* <Text style={[styles.forgotPassword, { marginBottom: 50 }]}>
                  By joining you agree with our{" "}
                  <Text
                    style={[
                      styles.clickHere,
                      { textDecorationLine: "underline" },
                    ]}
                    onPress={() => {
                      this.props.navigation.navigate("Terms", {
                        url: "https://www.jama.co.in/terms-conditions/",
                      });
                    }}
                  >
                    Terms
                  </Text>{" "}
                  and{" "}
                  <Text
                    style={[
                      styles.clickHere,
                      { textDecorationLine: "underline" },
                    ]}
                    onPress={() => {
                      this.props.navigation.navigate("PrivacyPolicy", {
                        url: "https://www.jama.co.in/privacy-policy/",
                      });
                    }}
                  >
                    Privacy Policy
                  </Text>
                </Text> */}
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexGrow: 1,
    height: "100%",
    backgroundColor: "#fff",
  },
  body: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  left: {
    flex: md(width) ? 1 : 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    paddingVertical: md(width) ? 75 : 1,
  },
  right: {
    flex: 1,
  },
  mob_styl: {
    width: "80%",
    alignSelf: "center",
    justifyContent: 'center'
  },
  web_styl: {
    justifyContent: 'center',
    alignItems: "center",
  },
  captchaContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: isMobile ? -10 : -20,
    marginBottom: 10,
  },
  best: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 33,
    lineHeight: 38,
    color: "#464E5F",
    paddingBottom: 100,
  },
  left_logo: {
    resizeMode: "contain",
    width: 403,
    height: 241,
    alignSelf: "center",
    marginBottom: 60,
  },
  mobile_verf: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 19 : 15,
    lineHeight: md(width) ? 22 : 17,
    textAlign: "center",
    color: "#121212",
    paddingTop: md(width) ? 0 : 50,
  },
  we_will: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 17 : 13,
    lineHeight: md(width) ? 20 : 18,
    textAlign: "justify",
    color: "#A7A8BB",
    paddingTop: md(width) ? 19 : 29,
  },
  mob_num: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 18,
    color: "#222225",
    paddingBottom: md(width) ? 23 : 10.5,
  },
  please: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3,
  },
  req_otp: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 47,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  one: {
    color: "#121212",
  },
  dmart_body_md: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "bold",
    color: "#121212",
    backgroundColor: "#f6f6f6",
    height: 41.02,
    paddingLeft: 20,
    borderRadius: 4,
  },
  sebi: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 14,
    color: "#000",
    paddingRight: 29,
  },
  sebi_1: {
    fontFamily: "Arial",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: 9,
    lineHeight: 14,
    color: "#51514E",
    paddingRight: 29,
  },
  ina: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 10,
    lineHeight: 14,
    color: "#410DAA",
  },
  img1: {
    width: 30,
    height: 28.6,
  },
  welcomeTitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    color: "#202020",
    fontWeight: "bold",
    marginBottom: 45,
    marginTop: 10,
    textAlign: "center",
  },
  inputsContainer: {
    marginTop: '25%',
    minWidth: "75%",
  },
  inputContainer: {
    marginBottom: 20,
  },
  forgotPassword: {
    color: "#232425",
    fontSize: 15,
    fontFamily: "Roboto",
    marginTop: 18,
    textAlign: "center",
  },
  clickHere: {
    color: "#410DAA",
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 6,
    textAlign: "center",
  },
  certication: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 25,
  },
  sebiContainer: {
    flexDirection: "row",
    paddingTop: 25,
  },
  certicationsContainer: {
    flexDirection: "row",
    paddingLeft: 30.5,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  popableContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default Pan;
