import React, { Component } from "react";
import { View, Text, StyleSheet, ActivityIndicator, TouchableOpacity } from "react-native";
import theme from "../../../static/theme";


export default class CustomButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { container, textStyle } = styles;
    const {
      bgColor,
      textColor,
      buttonText,
      style,
      onPress,
      borderColor,
      buttonTextStyle,
      loaderColor,
      isLoading
    } = this.props;
    return (
      <TouchableOpacity
        style={[
          container,
          {
            backgroundColor: bgColor,
            borderWidth: 1,
            borderColor: borderColor
          },
          style
        ]}
        onPress={() => {
          !isLoading && onPress && onPress();
        }}
      >
        {isLoading ? (
          <ActivityIndicator size="small" color={loaderColor || "#fff"} />
        ) : (
          <Text style={[textStyle, { color: textColor }, buttonTextStyle]}>
            {" "}
            {buttonText || this.props.children}{" "}
          </Text>
        )}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    padding: 12,
    borderRadius: 4
  },
  textStyle: {
    fontSize: theme.fontsizes.SMALL
  }
});

CustomButton.defaultProps = {
  bgColor: theme.colors.NEW_BRAND_COLOR,
  textColor: theme.textColors.SECONDARY_FONT,
  borderColor: theme.colors.NEW_BRAND_COLOR
};
