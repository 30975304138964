import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { md } from "../../../Responsive";
import Header from "../header_after_login";
import AntDesign from "@expo/vector-icons/AntDesign";
import Ionicons from "@expo/vector-icons/Ionicons";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import SimpleLineIcons from "@expo/vector-icons/SimpleLineIcons";
import Progressbar from "../progressbar";
import Modal from "../modal";
const { width } = Dimensions.get("window");
import {
  completeMinDetails,
  isMobile,
  navigateToTransaction,
} from "../../../utils";
import { ShowToast } from "../../../components/Common/Toast";
import { Button } from "react-native-paper";
import Toast from "react-native-toast-message";

export default class Risk_profile extends React.Component {
  constructor(props) {
    super(props);
    const { result } = this.props;
    this.state = {
      data: null,
      img_url: "null",
      showAlert: false,
      ...result,
      box_color: null,
      name: null,
      description: null,
    };
  }

  async componentDidMount() {
    let { risk_profile } = this.state;
    try {
      if (risk_profile == "VCN") {
        this.setState({
          img_url:
            "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23977.svg",
          box_color: "ECE7F7",
          name: "Very Conservative",
          description:
            "Highy conservative investors want to avoid loss of money.They do not wish to take any risk to earn higher returns.",
        });
      } else if (risk_profile == "CON") {
        this.setState({
          img_url:
            "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23968.svg",
          box_color: "#C6B6E6",
          name: "Conservative",
          description:
            "Consevative Investors wish to protect Investments.They have some concern on inflation and wish to make atleast inflation beating returns.",
        });
      } else if (risk_profile == "MOD") {
        this.setState({
          img_url:
            "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23969.svg",
          box_color: "#A086D5",
          name: "Moderate",
          description:
            "Moderate Investors seek to protect capital, while investing in suitable growth opportunities.",
        });
      } else if (risk_profile == "MAG") {
        this.setState({
          img_url:
            "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23970.svg",
          box_color: "#7A56C4",
          name: "Moderately aggressive",
          description:
            "Aggressive investors are willing to take some risk for additional growth in capital. they are willing to take some short term volatility if it gives higher growth.",
        });
      } else if (risk_profile == "VAG") {
        this.setState({
          img_url:
            "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23971.svg",
          box_color: "#410DAA",
          name: "Very Aggressive",
          description:
            "Very aggressive investors wants to achieve the maximum possible returns. They are not worried too much about losing capital in short term.",
        });
      }
    } catch (error) {}
  }

  handleContinue = async () => {
    try {
      Toast.hide();
      const minDetailsResponse = await completeMinDetails();
      const { needCompletion, error } = minDetailsResponse;

      if (!error) {
        const { redirect_screen } = minDetailsResponse;
        let options = {};
        if (this.props.route) {
          const { params } = this.props.route;
          if (params) {
            options = { ...params };
          }
          if (!needCompletion) {
            if (options.transaction) {
              navigateToTransaction(this.props.navigation, options.transaction);
            } else if (options.action) {
              this.props.navigation.navigate("ComplianceSuccess", {
                ...options,
              });
              return;
            } else {
              this.props.navigation.navigate("OnboardingSuccess", {
                ...options,
              });
              return;
            }
          }
        }
        this.props.navigation.navigate(redirect_screen, { ...options });
      } else {
        this.setState({
          error: true,
          errorText: "Something went wrong! Please contact help@maxiom.in",
        });
      }
    } catch (error) {}
  };

  getDescription = (key) => {
    if (key) key = key.trim();
    if (key == "Very Conservative") {
      return "Highy conservative investors want to avoid loss of money.They do not wish to take any risk to earn higher returns.";
    } else if (key == "Conservative") {
      return "Consevative Investors wish to protect Investments.They have some concern on inflation and wish to make atleast inflation beating returns.";
    } else if (key == "Moderate") {
      return "Moderate Investors seek to protect capital, while investing in suitable growth opportunities";
    } else if (key == "Moderately aggressive") {
      return "Aggressive investors are willing to take some risk for additional growth in capital. they are willing to take some short term volatility if it gives higher growth.";
    } else if (key == "Very Aggressive") {
      return "Very aggressive investors wants to achieve the maximum possible returns. They are not worried too much about losing capital in short term.";
    } else {
      return "";
    }
  };

  handleModalOpen = () => {
    this.setState({ showAlert: true });
  };
  render() {
    const {
      risk_profile,
      img_url,
      showAlert,
      modified,
      box_color,
      name,
      description,
    } = this.state;
    const { onRevise } = this.props;
    return (
      <View style={styles.container}>
        <Header navigation={this.props.navigation} route={this.props.route} />
        <Progressbar
          color2="#410DAA"
          color3="#410daa"
          color4="#410DAA"
          color5="#410DAA"
          color6="#410DAA"
          color7="#f6f6f6"
          color8="#f6f6f6"
          color9="#f6f6f6"
          color10="#f6f6f6"
          color11="#f6f6f6"
          color12="#f6f6f6"
          bcolor1="#410DAA"
          bcolor2="#410DAA"
          bcolor3="#410DAA"
          bcolor4="#f6f6f6"
          bcolor5="#f6f6f6"
          bagcolor1="#410DAA"
          bagcolor2="#410DAA"
          bagcolor3="#410DAA"
          bagcolor4="#ffffff"
          bagcolor5="#ffffff"
          markdisplay1="none"
          markdisplay2="none"
          markdisplay3="none"
          markdisplay4="none"
          markdisplay5="none"
          dotdisplay1="none"
          dotdisplay2="none"
          dotdisplay3="none"
          dotdisplay4="none"
          dotdisplay5="none"
        />
        {showAlert && (
          <Modal
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ paddingBottom: 3, width: md(width) ? 691 : "100%" }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingHorizontal: 10,
                  paddingVertical: 10,
                  backgroundColor: "rgba(65, 13, 170, 0.1)",
                }}
              >
                <Text style={styles.sig}>Risk Profile & Significance</Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showAlert: false });
                  }}
                >
                  <AntDesign name="close" size={24} color="black" />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  paddingHorizontal: 44,
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <View style={styles.infoBoxContainer}>
                  <Text style={styles.high}>
                    Highy conservative investors want to avoid loss of
                    money.They do not wish to take any risk to earn higher
                    returns.
                  </Text>
                  <TouchableOpacity style={styles.infoBox}>
                    <Text style={[styles.btn1, { backgroundColor: "#ECE7F7" }]}>
                      Very Conservative
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.infoBoxContainer}>
                  <Text style={styles.high}>
                    Conservative Investors wish to protect investments.They have
                    some concern on inflation & wish make atleast
                    inflation-beating returns.
                  </Text>
                  <TouchableOpacity style={styles.infoBox}>
                    <Text style={[styles.btn1, { backgroundColor: "#C6B6E6" }]}>
                      Conservative
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.infoBoxContainer}>
                  <Text style={styles.high}>
                    Moderate Investors seek to protect capital, while investing
                    in suitable growth opportunities
                  </Text>
                  <TouchableOpacity style={styles.infoBox}>
                    <Text style={[styles.btn, { backgroundColor: "#A086D5" }]}>
                      Moderate
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.infoBoxContainer}>
                  <Text style={styles.high}>
                    Aggressive investors are willing to take some risk for
                    additional growth in capital. they are willing to take some
                    short term volatility if it gives higher growth.
                  </Text>
                  <TouchableOpacity style={styles.infoBox}>
                    <Text style={[styles.btn, { backgroundColor: "#7A56C4" }]}>
                      Moderately aggressive
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.infoBoxContainer}>
                  <Text style={styles.high}>
                    Very aggressive investors wants to achieve the maximum
                    possible returns. They are not worried too much about losing
                    capital in short term.
                  </Text>
                  <TouchableOpacity style={styles.infoBox}>
                    <Text style={[styles.btn, { backgroundColor: "#410DAA" }]}>
                      Very Aggressive
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        )}
        <ScrollView style={styles.mainContainer}>
          <View style={styles.body}>
            <View style={styles.right}>
              <View style={styles.body_styl}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={styles.ris_prf}>Your Risk Profile</Text>
                  <TouchableOpacity onPress={onRevise}>
                    <Text style={styles.revise}>
                      <Ionicons name="reload" size={14} color="#410daa" />{" "}
                      Revise Assessment
                    </Text>
                  </TouchableOpacity>
                </View>
                {/* <View style={{flexDirection:"row", alignItems:"center", paddingBottom: 6, paddingTop: 20, justifyContent:"center"}}>
                    <FontAwesome
                      name="square"
                      size={12}
                      color={box_color}
                      style={{ paddingRight: 4 }}
                    />
                    <Text style={styles.cons}>
                      {name}
                    </Text>
                </View>
                
                <Text style={styles.taken}>Taken on: {modified}</Text> */}
                {risk_profile && (
                  <View style={{ alignItems: "center" }}>
                    <Image source={{ uri: img_url }} style={styles.image} />
                  </View>
                )}

                <Text style={[styles.con]}>
                  {/* <TouchableOpacity
                  mode="text"
                  uppercase={false}
                  onPress={this.handleModalOpen}
                >
                <Image
                source="https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/help+1.png"
                style={{
                  width: 19,
                  height: 17,
                  resizeMode: "contain",
                  marginLeft: 5,
                }}
              />
                  </TouchableOpacity> */}
                  <FontAwesome
                    name="square"
                    size={15}
                    color={box_color}
                    style={{ paddingRight: 6 }}
                  />
                  {name}
                  <TouchableOpacity
                    mode="text"
                    uppercase={false}
                    onPress={this.handleModalOpen}
                  >
                    <Image
                      source="https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/help+1.png"
                      style={{
                        width: 19,
                        height: 17,
                        resizeMode: "contain",
                        marginLeft: 6,
                      }}
                    />
                  </TouchableOpacity>{" "}
                  {"\n"}
                  <Text style={styles.taken}>Taken on: {modified}</Text>
                  {/* <TouchableOpacity
                  mode="text"
                  uppercase={false}
                  onPress={this.handleModalOpen}
                >
                <Image
                source="https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/help+1.png"
                style={{
                  width: 19,
                  height: 17,
                  resizeMode: "contain",
                  marginLeft: 5,
                }}
              />
                  </TouchableOpacity> */}
                </Text>
                <Text style={styles.consevation}>{description}</Text>
                <TouchableOpacity
                  onPress={() => {
                    this.handleContinue();
                  }}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 20.7,
                    marginBottom: 30,
                  }}
                >
                  <Text style={styles.next}>Continue</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1, backgroundColor: "#fff" },
  mainContainer: {},
  body: {
    backgroundColor: "#ffffff",
    justifyContent: "center",
    alignItems: "center",
  },
  body_styl: {
    borderWidth: md(width) ? 0.5 : 0,
    borderColor: "solid rgba(65, 13, 170, 0.1)",
    borderRadius: 12,
    paddingHorizontal: md(width) ? 28 : 17,
    paddingVertical: 10,
    shadowRadius: md(width) ? 1 : 0,
  },
  right: {
    paddingBottom: md(width) ? 50 : 120,
    paddingTop: 50,
    width: md(width) ? 688 : "100%",
  },
  left_img: {
    resizeMode: "contain",
    width: 378,
    height: 325,
  },
  lock: {
    resizeMode: "contain",
    width: 24.5,
    height: 34,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 22,
    lineHeight: 25,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
    paddingTop: 11,
    paddingBottom: 32,
    paddingRight: 155,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  revise: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 23,
    color: "#410DAA",
  },
  consevation: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 23,
    color: "#121212",
    textAlign: "center",
  },
  next: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    fontWeight: "bold",
    paddingVertical: 12,
    paddingHorizontal: 63,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  ris_prf: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#222225",
  },
  con: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 17,
    lineHeight: 21,
    textAlign: "center",
    paddingBottom: 40,
  },
  cons: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
  },
  taken: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 10,
    color: "#000000",
    paddingBottom: 20,
    textAlign: "center",
  },
  sig: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 18,
    color: "#121212",
  },
  btn: {
    borderRadius: 6,
    fontFamily: "Roboto",
    fontSize: isMobile ? 9 : 12,
    lineHeight: 14,
    fontWeight: "bold",
    color: "#ffffff",
    paddingVertical: 8.5,
    minWidth: isMobile ? 100 : 137,
    textAlign: "center",
  },
  btn1: {
    borderRadius: 6,
    fontFamily: "Roboto",
    fontSize: isMobile ? 9 : 12,
    lineHeight: 14,
    fontWeight: "bold",
    color: "#410daa",
    paddingVertical: 8.5,
    minWidth: isMobile ? 100 : 137,
    textAlign: "center",
  },
  high: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#464E5F",
    paddingRight: 55,
  },
  image: {
    width: 292,
    height: 292,
    marginBottom: -80,
    marginTop: 30,
  },
  infoBoxContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    paddingVertical: 14,
  },
  infoBox: {
    minWidth: isMobile ? 100 : 137,
    justifyContent: "center",
    alignItems: "center",
  },
});
