import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { md } from "../../../Responsive";
import { updatePaymentStatusOrderSummary } from "../../../api";
import Header from "../header_after_login";
import { ActivityIndicator } from "react-native-paper";
import { StackActions } from "@react-navigation/routers";
const { width } = Dimensions.get("window");

export default class succesful_onbording extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      showTimer: false,
      isLoading: true,
    };
  }

  async componentDidMount() {
    try {
      const response = await updatePaymentStatusOrderSummary();
      if (response && response.code == "100") {
        this.showButton();
      } else {
        this.setState(
          {
            isLoading: false,
            error: true,
            errorText:
              response && response.msg ? response.msg : "Something went wrong!",
            showTimer: false,
          },
          () => {
            this.showButton();
          }
        );
      }
    } catch (error) {
      this.setState(
        {
          isLoading: false,
          error: true,
          showTimer: false,
          errorText: "Something went wrong!",
        },
        () => {
          this.showButton();
        }
      );
    }
  }

  showButton = () => {
    this.interval = setInterval(
      () => this.setState((prevState) => ({ timer: prevState.timer - 1 })),
      1000
    );
  };

  componentDidUpdate() {
    if (this.state.timer === 0) {
      clearInterval(this.interval);
      if (Platform.OS == "web") {
        global.location.replace("/portfolio", "_self");
      } else {
        this.props.navigation.dispatch(
          StackActions.replace("App", {
            screen: "Dashboard",
            params: { screen: "Dashboard", params: {} },
          })
        );
      }
    }
    if (this.redirect) clearTimeout(this.redirect);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleContinue = async () => {
    try {
      global.location.replace("/portfolio", "_self");
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  render() {
    const { timer, showTimer, isLoading, errorText } = this.state;
    return (
      <>
        <Header navigation={this.props.navigation} route={this.props.route} />
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
            paddingBottom: md(width) ? 90 : 200,
            paddingHorizontal: md(width) ? 0 : 19,
          }}
        >
          <Text style={styles.you_have}>Failed completing your payment</Text>
          <Image
            source={{
              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/money_transfer_-2+1.png",
            }}
            style={styles.img}
          />
          <Text style={styles.errorText}>{errorText || " "}</Text>
          {timer != null && (
            <>
              {showTimer && (
                <Text style={styles.redirect}>
                  Redirecting to Dashboard in{" "}
                  <Text style={{ color: "lightblue", fontWeight: "bold" }}>
                    {timer} sec
                  </Text>
                </Text>
              )}
              <TouchableOpacity onPress={this.handleContinue}>
                <View>
                  <Text style={styles.cont}>
                    Continue to Dashboard 
                  </Text>
                </View>
              </TouchableOpacity>
            </>
          )}
          {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
        </View>
      </>
    );
  }
}
const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1 },
  img: {
    width: md(width) ? 474 : 252,
    height: md(width) ? 373 : 198,
  },
  you_have: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 20 : 15,
    lineHeight: 21,
    color: "#000000",
    fontWeight: "bold",
    paddingTop: md(width) ? 50 : 32,
    paddingBottom: 17,
    fontWeight: "bold",
    textAlign: md(width) ? "undefined" : "center",
  },
  completed: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 15 : 13,
    lineHeight: md(width) ? 17 : 21,
    color: "#121212",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: md(width) ? 38 : 32,
  },
  cont: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  redirect: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    textAlign: "center",
    color: "rgba(18, 18, 18, 0.4)",
    paddingBottom: 30,
  },
  errorText: {
    color: "red",
    fontSize: 15,
    marginVertical: 15,
  },
});
