import React from "react";
import { StyleSheet, ScrollView, View, Text, Dimensions } from "react-native";
import { Divider } from "react-native-paper";
import FieldValue from "../../components/FieldValue";
import DiscountText from "../../components/DiscountText";
import DotSeparator from "../../../../components/Common/DotSeparator";
import { isMobile, numberWithCommas } from "../../../../utils";
const { height } = Dimensions.get("window");

const BillHeadingItem = ({
  label,
  value,
  style = [],
  labelStyle = [],
  valueStyle = [],
}) => {
  return (
    <View style={[{ flexDirection: "row", alignItems: "center" }, ...style]}>
      <Text style={[...labelStyle]}>{label}</Text>
      <Text style={[...valueStyle]}>{value}</Text>
    </View>
  );
};

const BillHeading = ({ data }) => {
  return (
    <View style={styles.billBasicDetailsContainer}>
      <View>
        <View style={styles.majorHeadingContainer}>
          <Text style={styles.majorHeadingText}>
            Direct Equity Advisory- {data["product_text"]} Plan{" "}
            {data["plan_code"]}
          </Text>
        </View>
        <View style={styles.descriptionContainer}>
          <View>
            <BillHeadingItem
              label={"Plan No:"}
              value={data["plan_number"]}
              style={[{ marginRight: 0, paddingRight: 8 }]}
              labelStyle={[styles.labelStyle]}
              valueStyle={[styles.valueStyle]}
            />
          </View>
          <DotSeparator />
          <BillHeadingItem
            label={"Fee :"}
            style={[{ paddingHorizontal: 8 }]}
            value={parseFloat(data["discounted_fee_perc"]).toFixed(2) + "% p.a."}
            labelStyle={[styles.labelStyle]}
            valueStyle={[styles.valueStyle, styles.bold]}
          />
          <DotSeparator />
          <BillHeadingItem
            label={"Hurdle:"}
            value={(parseFloat(data["discounted_hurdle_perc"]).toFixed(2) || 0) + "% p.a."}
            style={[{ paddingHorizontal: 8 }]}
            labelStyle={[styles.labelStyle]}
            valueStyle={[styles.valueStyle, styles.bold]}
          />
          {/* <DotSeparator />
          <BillHeadingItem
            label={"Performance Fee Share:"}
            value={(parseFloat(data["discounted_perf_fee_perc"]).toFixed(2) || 0) + "% p.a."}
            style={[{ paddingLeft: 8 }]}
            labelStyle={[styles.labelStyle]}
            valueStyle={[styles.valueStyle, styles.bold]}
          /> */}
        </View>
        <View style={styles.descriptionContainer}>
          <View>
            <BillHeadingItem
              label={data["billing_frequency_text"]+":"}
              value={parseFloat(data["fee_perc"]).toFixed(2)+"%"}
              style={[{ marginRight: 0, paddingRight: 8 }]}
              labelStyle={[styles.labelStyle]}
              valueStyle={[styles.valueStyle]}
            />
          </View>
          <DotSeparator />
          <BillHeadingItem
            label={"Performance Fee Share:"}
            value={(parseFloat(data["discounted_perf_fee_perc"]).toFixed(2) || 0) + "% p.a."}
            style={[{ paddingLeft: 8 }]}
            labelStyle={[styles.labelStyle]}
            valueStyle={[styles.valueStyle, styles.bold]}
          />
          {/* <Text style={styles.feePercentageText}>
            {data["billing_frequency_text"]}:{" "}
            <Text style={styles.feePercentage}>{parseFloat(data["fee_perc"]).toFixed(2)}%</Text>
          </Text> */}
        </View>
      </View>
      <View>
        <View style={styles.majorHeadingContainer}>
          <Text style={styles.majorHeadingText}>
            {data["bill_type"]}
          </Text>
        </View>
        <View style={styles.descriptionContainer}>
          <Text style={styles.planNumber}>{data["bill_period_text"]}</Text>
        </View>
      </View>
      <View>
        {data["discount"] != 0 && !!Number(data["discount_perc"]) > 0 && (
          <DiscountText discount={data["discount_perc"]} />
        )}
      </View>
    </View>
  );
};

const OLD = ({ data = {}, ...props }) => {
  return (
    isMobile ? (
    <ScrollView
      style={styles.container}
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={true}
    >
      <View style={styles.boxContainer}>
        
        <BillHeading data={data} />

        <View style={styles.auaContainer}>
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={[styles.commitedAuaContainer]}>
              <Text style={styles.committedAua}>{data["box1_field_name"]}</Text>
              {/* <Text style={styles.subDescription}>
                ({data["plan_period_text"]})
              </Text> */}
            </View>
            <Text style={styles.feesAmount}>
              ₹{numberWithCommas(parseInt(data["committed_aua"]))}
            </Text>
          </View>
        </View>

        <View style={styles.detailsContainer}>
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View>
              <Text style={styles.advisoryHeading}>
                {data["bill_heading"] || "Advisory Fee"}
              </Text>
              {/* <Text style={styles.billingPeriod}>
                (Billing period {data["plan_period_text"]})
              </Text> */}
            </View>
            <View>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["bill"]))}
              </Text>
            </View>
          </View>
          <Divider />
          {data["discount"] != 0 && !!data["discount_perc"] > 0 && (
            <>
              <FieldValue
                label={`Discount @${data["discount_perc"]}%`}
                value={"₹" + numberWithCommas(parseInt(data["discount"]))}
                style={[styles.fieldItem]}
                labelStyle={[{  }]}
                valueStyle={[{  }]}
              />
              <Divider />
            </>
          )}
          {data["additional_discount"] != 0 && (
            <>
              <FieldValue
                label={`Additional Discount `}
                value={
                  "₹" + numberWithCommas(parseInt(data["additional_discount"]))
                }
                style={[styles.fieldItem]}
                labelStyle={[{  }]}
                valueStyle={[{  }]}
              />
              <Divider />
            </>
          )}
          {data["igst"] ? (
            <FieldValue
              label={"IGST(" + data["igst"] + "%)"}
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          ) : (
            <FieldValue
              label={
                "CGST(" + data["cgst"] + "%) + SGST(" + data["sgst"] + "%)"
              }
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          )}
          <Divider />
          <FieldValue
            label={"Final Fee including GST"}
            value={"₹" + numberWithCommas(parseInt(data["Total Payment"]))}
            style={[styles.fieldItem]}
            labelStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
            valueStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
          />
        </View>
      </View>
    </ScrollView>
    ) : (
      <ScrollView
        style={[styles.container, {height: height/2, paddingBottom: 100}]}
        // horizontal={true}
        // showsHorizontalScrollIndicator={false}
        // showsVerticalScrollIndicator={false}
      >
      <View style={styles.boxContainer}>
        
        <BillHeading data={data} />

        <View style={styles.auaContainer}>
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={[styles.commitedAuaContainer]}>
              <Text style={styles.committedAua}>{data["box1_field_name"]}</Text>
              {/* <Text style={styles.subDescription}>
                ({data["plan_period_text"]})
              </Text> */}
            </View>
            <Text style={styles.feesAmount}>
              ₹{numberWithCommas(parseInt(data["committed_aua"]))}
            </Text>
          </View>
        </View>

        <View style={styles.detailsContainer}>
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View>
              <Text style={styles.advisoryHeading}>
                {data["bill_heading"] || "Advisory Fee"}
              </Text>
              {/* <Text style={styles.billingPeriod}>
                (Billing period {data["plan_period_text"]})
              </Text> */}
            </View>
            <View>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["bill"]))}
              </Text>
            </View>
          </View>
          <Divider />
          {data["discount"] != 0 && !!data["discount_perc"] > 0 && (
            <>
              <FieldValue
                label={`Discount @${data["discount_perc"]}%`}
                value={"₹" + numberWithCommas(parseInt(data["discount"]))}
                style={[styles.fieldItem]}
                labelStyle={[{  }]}
                valueStyle={[{  }]}
              />
              <Divider />
            </>
          )}
          {data["additional_discount"] != 0 && (
            <>
              <FieldValue
                label={`Additional Discount `}
                value={
                  "₹" + numberWithCommas(parseInt(data["additional_discount"]))
                }
                style={[styles.fieldItem]}
                labelStyle={[{  }]}
                valueStyle={[{  }]}
              />
              <Divider />
            </>
          )}
          {data["igst"] ? (
            <FieldValue
              label={"IGST(" + data["igst"] + "%)"}
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          ) : (
            <FieldValue
              label={
                "CGST(" + data["cgst"] + "%) + SGST(" + data["sgst"] + "%)"
              }
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          )}
          <Divider />
          <FieldValue
            label={"Final Fee including GST"}
            value={"₹" + numberWithCommas(parseInt(data["Total Payment"]))}
            style={[styles.fieldItem]}
            labelStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
            valueStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
          />
        </View>
      </View>
    </ScrollView>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  boxContainer: {
    borderWidth: 0,
    borderColor: "#410DAA50",
    borderRadius: 15,
    marginVertical: isMobile ? 0 : 28,
    marginHorizontal: isMobile ? 25 : 35,
    paddingVertical: 27,
    paddingHorizontal: isMobile ? 0 : 39,
    minWidth: 800,
  },
  billBasicDetailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  majorHeadingContainer: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
  },
  majorHeadingText: {
    color: "#464E5F",
    fontSize: 17,
    fontWeight: "bold",
  },
  feePercentageText: {
    color: "#6C7293",
    fontSize: 13,
    marginLeft: isMobile ? 0 : 0,
    marginTop: isMobile ? 4 : 0,
  },
  feePercentage: {
    color: "#464E5F",
    fontSize: 13,
    fontWeight: "700",
    paddingLeft: 4,
  },
  descriptionContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 12,
  },
  descriptionText: {
    color: "#6C7293",
    fontSize: 12,
    marginTop: 14,
  },
  labelStyle: {
    fontSize: isMobile ? 11 : 13,
    color: "#4A4B68",
    paddingRight: 4,
  },
  valueStyle: { fontSize: isMobile ? 11 : 13, color: "#4A4B68" },
  fieldContainer: { justifyContent: "center" },
  planNumber: {
    color: "#4A4B68",
    fontSize: 11,
    marginLeft: 7,
  },
  auaContainer: {
    marginTop: 27,
    borderWidth: 1,
    borderColor: "#DBC0FF",
    borderRadius: 10,
  },
  detailsContainer: {
    marginTop: 21,
    borderWidth: 1,
    borderColor: "#DBC0FF",
    borderRadius: 10,
  },
  billContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 17.5,
  },
  advisoryHeading: {
    color: "#464E5F",
    fontSize: 15,
    fontWeight: "bold",
  },
  billingPeriod: {
    color: "#464E5F",
    fontSize: isMobile ? 11 : 15,
    fontWeight: "400",
    marginTop: 10,
  },
  feesAmount: {
    fontSize: 15,
    color: "#464E5F",
    fontWeight: "bold",
  },
  viewDetailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  viewDetails: {
    color: "#6C7293",
    fontSize: 14,
    marginRight: 10,
  },
  fieldItem: {
    paddingHorizontal: isMobile ? 16 : 75,
  },
  commitedAuaContainer: {
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
  },
  committedAua: {
    fontSize: 15,
    color: "#464E5F",
  },
  subDescription: {
    fontSize: isMobile ? 10 : 15,
    fontWeight: "400",
    marginTop: isMobile ? 4 : 0,
  },
  amountContainer: {
    alignItems: "flex-end",
  },
  feesAmount: {
    fontSize: 15,
    color: "#464E5F",
    fontWeight: "bold",
  },
  bold: { fontWeight: "bold" },
  committedAuaText: {
    fontSize: 15,
    color: "#464E5F",
  },
});

export default OLD;
