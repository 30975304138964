import React, { Component } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Platform,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
} from "react-native";
import AntIcon from "@expo/vector-icons/AntDesign";
import { connect } from "react-redux";
import { SideHeading } from "../../components/Common/sideHeading";
import { getPersonalisedFunds } from "../../api/invest";
import numberFormat from "../../utils/numberFormat";
import { EquityPieChart } from "../../components/Common/EquityPieChart";
import { isMobile, toFixed } from "../../utils";
import amplitude from "../../utils/amplitude";
import NavigationHeader from "../../components/Common/NavigationHeader";

const getVolatality = (status) => {
  const statuses = {
    LOW: "Low",
    MLW: "Moderately Low",
    MOD: "Moderate",
    MDH: "Moderate High",
    HGH: "High",
  };
  return status in statuses ? statuses[status] : "-";
};

const FundItem = ({ fund, navigation }) => {
  return (
    <View
      style={{
        margin: 16,
        borderWidth: 1,
        borderColor: "#410DAA1A",
        borderRadius: 8,
        padding: 16,
      }}
    >
      <Text style={{ fontWeight: "bold", fontSize: 14, color: "#121212" }}>
        {fund.amcfundscheme__name}
      </Text>
      <View
        style={{
          flexDirection: "row",
          marginTop: 8,
          marginBottom: 16,
          alignItems: "center",
        }}
      >
        <AntIcon
          name={numberFormat(fund.day_change, 2) > 0 ? "arrowup" : "arrowdown"}
          style={{
            fontSize: 10,
            color: numberFormat(fund.day_change, 2) > 0 ? "#009105" : "#EF5D12",
          }}
        />
        <Text
          style={{
            color: numberFormat(fund.day_change, 2) > 0 ? "#009105" : "#EF5D12",
            fontSize: 11,
          }}
        >
          {numberFormat(fund.day_change, 1)}%
        </Text>
        <Text
          style={{
            color: "#707070",
            fontStyle: "italic",
            marginLeft: 2,
            fontSize: 10,
          }}
        >
          1day
        </Text>
        <Text
          style={{
            paddingHorizontal: 8,
            paddingVertical: 2,
            marginLeft: 16,
            backgroundColor: "#EBEBEB",
            borderRadius: 10,
            overflow: "hidden",
            fontSize: 10,
            color: "#121212",
          }}
        >
          {fund.amcfundscheme__AMCFund__fund_class}
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          alignItems: "center",
        }}
      >
        <View style={{ flex: 0.33, alignSelf: "flex-start" }}>
          <Text style={{ color: "#410daa", fontWeight: "bold", fontSize: 13 }}>
            {numberFormat(toFixed(fund.amcfundscheme__returns_1_yr, 1))}%
          </Text>
          <Text style={{ fontSize: 12, color: "#707070", marginTop: 10 }}>
            Returns
          </Text>
        </View>
        <View style={{ flex: 0.33 }}>
          <View style={{ alignSelf: "center" }}>
            <Text style={{ fontSize: 13, color: "#121212" }}>
              &#8377;
              {numberFormat(
                toFixed(fund.amcfundscheme__assets_under_management / 100, 1)
              )}{" "}
              Cr
            </Text>
            <Text style={{ fontSize: 12, color: "#707070", marginTop: 10 }}>
              Fund Size
            </Text>
          </View>
        </View>
        <View style={{ flex: 0.33, alignItems: "center" }}>
          <View style={{ alignItems: "flex-start" }}>
            <Text style={{ color: "green", fontSize: 13 }}>
              {getVolatality(fund.amcfundscheme__AMCFund__risk_factor)}
            </Text>
            <Text style={{ fontSize: 12, color: "#707070", marginTop: 10 }}>
              Volatality
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            // console.log("Fund", fund)
            navigation.navigate("Explore", {
              screen: "FundDescription",
              params: {
                fund: fund.amcfundscheme__AMCFund,
                fund_amc_id: fund.amcfundscheme__amcfundscheme_id,
                fundType: fund.fund_type
              }
            });
          }}
          style={{
            backgroundColor: "#410DAA1A",
            borderRadius: 18,
            justifyContent: "center",
            alignItems: "center",
            width: 130,
            height: 35,
            marginTop: 30,
          }}
        >
          <Text style={{ fontSize: 14, color: "#410DAA" }}>Invest Now</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const FundsSuggestedForYou = ({ funds, navigation }) => {
  if (funds.length == 0) {
    return null;
  }
  return (
    <>
      <View
        style={{
          height: 48,
          backgroundColor: "#F6F6F6",
          justifyContent: "flex-end",
          fontSize: 20,
          paddingHorizontal: 16,
        }}
      >
        <Text style={{ fontSize: 17, color: "#121212", marginBottom: 10 }}>
          Funds suggested for you
        </Text>
      </View>
      <View style={{ height: 12 }}></View>
      <View style={{ maxWidth: 800 }}>
        {funds.map((fundCat) => {
          return (
            <View key={fundCat.title}>
              <SideHeading
                headingTitleStyle={{
                  alignItems: "center",
                  fontSize: isMobile ? 14 : 16,
                }}
              >
                {fundCat.title}
              </SideHeading>
              <FlatList
                scrollEnabled={false}
                data={fundCat.children}
                renderItem={({ item }) => {
                  return <FundItem fund={item} navigation={navigation} />;
                }}
              />
            </View>
          );
        })}
      </View>
    </>
  );
};

class PersonalisedFunds extends Component {
  state = {
    current: {},
    suggested: {},
    funds: [],
    loading: true,
  };

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("PersonalisedFunds", { path: global.location.href })
      }
      else {
        amplitude.logEvent("PersonalisedFunds", { path: this.props.navigation.getState().routeName });
      }
      const response = await getPersonalisedFunds();
      if (response && response.results) {
        const {
          portfolio_distribution: { current, suggested },
          results,
        } = response;
        this.setState({
          current,
          suggested,
          funds: results,
          loading: false,
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        error: "Something went wrong. Try again.",
      });
      return;
    }
    this.setState({
      loading: false,
    });
  }

  handleBack = () => {
    const canGoBack = this.props.navigation.canGoBack();
    if (canGoBack) {
      this.props.navigation.goBack();
    }
  };

  render() {
    const { current, suggested, loading, funds, error } = this.state;
    if (loading || error) {
      return (
        <View style={styles.loaderContainer}>
          {loading && <ActivityIndicator size="large" color="#410DAA" />}
          {error && <Text>{error}</Text>}
        </View>
      );
    }

    const currentData = [
      {
        "title": "Equity",
        "value": current.perc[0] || 0,
        "color": "#6FE3D4",
        "legendFontColor": "#6FE3D4",
        "legendFontSize": 15,
        "titleValue": "0.0%",
        "total": 0
      },
      {
        "title": "Non Equity",
        "value": current.perc[1] || 0,
        "color": "#876CDD",
        "legendFontColor": "#A98DFF",
        "legendFontSize": 15,
        "titleValue": "0.0%",
        "total": 0
      }
    ];

    const suggestedData = [
      {
        "title": "Equity",
        "value": suggested.perc[0] || 0,
        "color": "#6FE3D4",
        "legendFontColor": "#6FE3D4",
        "legendFontSize": 15,
        "titleValue": "0.0%",
        "total": 0
      },
      {
        "title": "Non Equity",
        "value": suggested.perc[1] || 0,
        "color": "#876CDD",
        "legendFontColor": "#876CDD",
        "legendFontSize": 15,
        "titleValue": "0.0%",
        "total": 0
      }
    ];

    return (
      <SafeAreaView style={styles.container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Personalised Funds"]}
          onPressBack={this.handleBack}
        />
        <ScrollView style={{ flexGrow: 1 }}>
          {!loading && (
            <View style={{ flex: 1 }}>
              <SideHeading
                headingTitleStyle={{ fontWeight: "bold", fontSize: 17 }}
              >
                Portfolio Distribution
              </SideHeading>
              <View style={styles.chartsContainer}>
                <EquityPieChart
                  title="Current"
                  title_description=" "
                  equity_title="Equity"
                  non_equity_title="Non-Equity"
                  perc={current.perc}
                  colors={current.colors}
                  equity_val={current.perc[0]}
                  non_equity_val={current.perc[1]}
                  style={{ flex: 0.5 }}
                  pieChartStyle={{ padding: 10 }}
                  data={currentData}
                  width={isMobile ? 90 : 150}
                  height={isMobile ? 90 : 150}
                />
                <EquityPieChart
                  title="Suggested"
                  title_description="as per risk assessment"
                  equity_title="Equity"
                  non_equity_title="Non-Equity"
                  perc={suggested.perc}
                  colors={suggested.colors}
                  equity_val={suggested.perc[0]}
                  non_equity_val={suggested.perc[1]}
                  style={{ flex: 0.5 }}
                  pieChartStyle={{ padding: 10 }}
                  width={isMobile ? 90 : 150}
                  height={isMobile ? 90 : 150}
                  data={suggestedData}
                />
              </View>

              <FundsSuggestedForYou
                funds={funds}
                navigation={this.props.navigation}
              />
            </View>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  scrollViewContainer: {
    flexGrow: 1,
  },
  sideHeading: {
    fontSize: 17,
    fontWeight: "bold",
    marginBottom: 16,
    alignItems: "center",
  },
  chartsContainer: {
    flexDirection: "row",
    paddingHorizontal: 10,
    maxWidth: 800,
    marginTop: 20,
  },
});

export default connect(null)(PersonalisedFunds);
