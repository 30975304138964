import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Text, TextInput } from "react-native-paper";
import CustButton from "../../Common/Buttons/CustomButton";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { isMobile } from "../../../utils";

export const ImportFunction = ({
  title,
  description,
  sample,
  error,
  email = "",
  updateEmail,
  buttonTitle,
  buttonDescription,
  style,
  btnStyle = {},
  onPress,
  downloadSample,
  whyDoThis,
  emailInput = false,
}) => {
  return (
    <View style={[localStyles.form, { ...style }]}>
      <Text style={localStyles.inputLabel}>{title}</Text>
      <View
        style={{
          marginTop: 10,
          padding: 10,
          borderRadius: 10,
          borderWidth: 0.5,
          borderColor: "#BCBCBC",
        }}
      >
        {description.map((item) => {
          return (
            <Text
              style={{
                fontSize: 13,
                color: item.color || "#121212",
                paddingBottom: 5,
              }}
            >
              {item.text}
            </Text>
          );
        })}
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ flex: 0.5, alignItems: "flex-start" }}>
            {sample && (
              <Text
                style={{
                  color: "#410DAA",
                  fontWeight: "bold",
                  fontSize: 13,
                }}
                onPress={() => {
                  downloadSample();
                }}
              >
                Download Sample
              </Text>
            )}
          </View>
          <View style={{ flex: 0.5, alignItems: "flex-end" }}>
            {whyDoThis && (
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: -22,
                }}
                onPress={() => {
                  whyDoThis && whyDoThis();
                }}
              >
                <Text style={{ color: "#a8a8a8", fontSize: 14 }}>
                  How it works ?
                </Text>
                <ANTIcon
                  name="infocirlce"
                  color={"#a8a8a8"}
                  size={12}
                  style={{
                    paddingLeft: 10,
                  }}
                  onPress={() => {
                    whyDoThis && whyDoThis();
                  }}
                />
              </TouchableOpacity>
            )}
          </View>
        </View>
        {emailInput && (
          <View
            style={{
              width: "70%",
              display: "flex",
              height: 30,
              marginTop: 10,
              flexDirection: "row",
              marginBottom: -6,
            }}
          >
            <TextInput
              placeholder="user@gmail.com"
              value={`${email || ""}`}
              onChangeText={(email) => {
                updateEmail(email);
              }}
              style={{
                paddingLeft: 0,
                marginLeft: 0,
                color: "#121212",
                borderBottomColor: "#A8A8A8",
                borderBottomWidth: 0.5,
                justifyContent: "center",
                width: "100%",
              }}
            />
          </View>
        )}
        <CustButton
          bgColor={"#410DAA"}
          textColor={"#fff"}
          style={[{
            marginTop: 25,
            marginBottom: 5,
            paddingVertical: 8,
            width: "30%",
            marginLeft: "35%",
          },{...btnStyle}]}
          buttonText={buttonTitle}
          onPress={() => {
            onPress();
          }}
          borderColor={"transparent"}
          buttonTextStyle={{
            fontSize: 14,
          }}
        />
        {buttonDescription && (
          <Text
            style={{
              color: "#a8a8a8",
              fontWeight: "normal",
              fontSize: 13,
              textAlign: "center",
            }}
          >
            {buttonDescription}
          </Text>
        )}
      </View>
      <Text
        style={{
          ...localStyles.errorText,
          paddingVertical: 0,
          marginVertical: 0,
          paddingLeft: 2,
        }}
      >
        {error || ""}
      </Text>
    </View>
  );
};

const localStyles = StyleSheet.create({
  errorText: {
    color: "red",
    fontSize: 10,
    height: 16,
  },
  formItem: {
    borderColor: "#410DAA",
    padding: 0,
    margin: 0,
  },

  inputLabel: {
    color: "#121212",
    fontSize: 13,
    marginVertical: 0,
    paddingVertical: 0,
    fontWeight: "bold",
  },
});
