import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity, ScrollView, CheckBox} from 'react-native';
// import Checkbox from 'expo-checkbox';
import { formatDate, getUserDetails, isMobile, numberWithCommas } from '../../../utils';
import { checkCustomerPlanRenewal } from '../../../api';
import { StackActions } from "@react-navigation/native";
import ProgressBar from '../../../utils/progressBar';
import PlansTable from '../../../components/InvestmentOverview/NewDesign/PlansTable';

const plansData = require('./plans.json');

const PlansScreen = ({ pd, navigation, user_id, handleContinue, handleDashboardEvent, showViewMode }) => {
  let headerData = [];
  let mergePlans = {};
  let show_product = true;
  const productWisePlans = pd.results.product_wise_plans;
  const [isLoading, setIsLoading] = useState(false);
  const plansArray = Object.entries(productWisePlans).map(([product, plans]) => ({
    product,
    plans: plans.new_plans,
  }));
  React.useEffect(() => {
    console.log("Plans we getting is" + JSON.stringify(pd))
  })

  let check_plans_to_show_product = plansArray[0]?.plans
  check_plans_to_show_product?.forEach((item) => {
    if (item.show_plan_renewal_for_corporate) {
      show_product = false;
    }
  })

  const keysData = [
    {
      label: "S No.",
      key: "id"
    },
    {
      label: "Name",
      key: "name",
    }
  ];

  const sendConfirmAndContinue = async () => {
    setIsLoading(true);
    const { customer_id } = getUserDetails();
    const sampleData = {
      "cust_pk": customer_id,
      "cip_dict": {}
    };
    Object.entries(productWisePlans).forEach(([product, plans]) => {
      sampleData.cip_dict[product] = {}
      let prod = product
      Object.entries(plans).forEach(([plan, data]) => {
        let planType;
        if (plan.length > 0 && plan === "new_plans" && data.length > 0) {
          planType = "new"
          sampleData.cip_dict[prod][planType] = {}
        }
        if (plan.length > 0 && plan === "sip_plans_pending_renewal" && data.length > 0) {
          planType = "renew"
          console.log("DEBUG : " + JSON.stringify(sampleData.cip_dict[prod][planType]))
          if (sampleData.cip_dict[prod][planType] == undefined) {
            sampleData.cip_dict[prod][planType] = {}
          }
        }

        if (data && sampleData.cip_dict[prod][planType] != undefined) {
          let cip_pk = "";
          let cip_status = "";
          data.forEach((key) => {
            cip_pk += key.customerinvestmentplan_id + ","
            cip_status = key.status
          })
          sampleData.cip_dict[prod][planType]["cip_pk"] = cip_pk
          sampleData.cip_dict[prod][planType]["cip_status"] = cip_status
        }
        if (mergePlans[prod] && plan === "lumpsum_plans_pending_renewal" && data.length > 0) {
          planType = "merge_and_renew"
          sampleData.cip_dict[prod][planType] = {}
          if (data) {
            let cip_pk = "";
            let cip_status = "";
            data.forEach((key) => {
              cip_pk += key.customerinvestmentplan_id + ","
              cip_status = key.status
            })

            sampleData.cip_dict[prod][planType]["cip_pk"] = cip_pk
            sampleData.cip_dict[prod][planType]["cip_status"] = cip_status
          }
        } else {
          if (plan.length > 0 && plan === "lumpsum_plans_pending_renewal" && data.length > 0) {
            planType = "renew"
            let flag = false
            if (sampleData.cip_dict[prod][planType] === undefined) {
              sampleData.cip_dict[prod][planType] = {}
            } else {
              flag = true
            }
            if (data) {
              let cip_pk = "";
              let cip_status = "";
              data.forEach((key) => {
                cip_pk += key.customerinvestmentplan_id + ","
                cip_status = key.status
              })
              if (flag) {
                sampleData.cip_dict[prod][planType]["cip_pk"] += cip_pk
              } else {
                sampleData.cip_dict[prod][planType]["cip_pk"] = cip_pk
              }
              sampleData.cip_dict[prod][planType]["cip_status"] = cip_status
            }
          }
        }
      })
    });
    console.log(sampleData);
    let res = await checkCustomerPlanRenewal(sampleData);
    console.log(res)
    setIsLoading(false);
    if (res.code === '100' && res.results[0].redirect_to === "dashboard") {
      // console.log("Navigating to dashboard");
      navigation.dispatch(
        StackActions.replace("App", {
          screen: "Dashboard",
          params: {
            screen: "Dashboard",
            params: {
              screen: "Home",
              params: {
                showToast: "true",
              },
            },
          },
        })
      );
    }
  }

  keysData.forEach((headerItem) => {
    headerData.push({
      label: headerItem.label,
      style: [styles.tableHeader],
      headerItemContainerStyle: [styles.headerItemContainerStyle],
      sortOption: true,
      key: headerItem.key
    });
  });

  const RenderNewPlans = ({ plan }) => {

    let local = []
    productWisePlans[plan].new_plans.forEach((value) => {
      local.push([
        {
          valueToDisplay: local.length + 1,
          value: "id" || "",
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.show_plan_renewal_for_corporate ? value.plans_text : value.plan_text_for_display,
          value: "plan_name" || "",
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ]);
    })
    return local.length > 0 ? (isMobile ? (<View>
      <Text style={{
        fontSize: 18,
        fontWeight: '500',
        marginTop: 15,
        marginLeft: 10
      }}>New Plan</Text>
      <View style={styles.methodContainer} >
        <ScrollView horizontal={true} >
          <PlansTable
            header={headerData}
            gridConfig={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
            data={local}
            pagination={false}
            sortOption={false}
          />
        </ScrollView>
      </View>
    </View>
    ) : (<View>
      <Text style={{
        fontSize: 18,
        fontWeight: '500',
        marginTop: 15,
        marginLeft: 10
      }}>New Plan</Text>
      <View style={styles.methodContainer} >
        <ScrollView horizontal={true}>
        <PlansTable
          style={[{width: '100%'}]}
          header={headerData}
          gridConfig={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
          data={local}
          pagination={false}
        />
        </ScrollView>
      </View>
    </View>
    )
    ) : (<View></View>);
  };

  const RenderRenewalSIPPlans = ({ plan }) => {
    let local = []
    productWisePlans[plan].sip_plans_pending_renewal.forEach((value) => {
      local.push([
        {
          valueToDisplay: local.length + 1,
          value: "id" || "",
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.show_plan_renewal_for_corporate ? value.plans_text : value.plan_text_for_display,
          value: "plan_name" || "",
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ]);
    })
    return local.length > 0 ? (isMobile ? (<View>
      <Text style={{
        fontSize: 18,
        fontWeight: '500',
        marginTop: 15,
        marginLeft: 10
      }}>Pending SIP Plan For Renewal</Text>
      <View style={styles.methodContainer}>
        <ScrollView horizontal={true}>
          <PlansTable
            header={headerData}
            gridConfig={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
            data={local}
            pagination={false}
            sortOption={false}
          />
        </ScrollView>
      </View>
    </View>
    ) : (<View>
      <Text style={{
        fontSize: 18,
        fontWeight: '500',
        marginTop: 15,
        marginLeft: 10
      }}>Pending SIP Plan For Renewal</Text>
      <View style={styles.methodContainer} >
      <ScrollView horizontal={true}>
        <PlansTable
          header={headerData}
          gridConfig={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
          data={local}
          pagination={false}
          sortOption={false}
        />
        </ScrollView>
      </View>
    </View>
    )) : (<View></View>);
  };

  const RenderRenewalLumpsumPlans = ({ plan }) => {
    const [mergePlan, setMergePlan] = useState(false);
    let local = []
    productWisePlans[plan].lumpsum_plans_pending_renewal.forEach((value) => {
      local.push([
        {
          valueToDisplay: local.length + 1,
          value: "id" || "",
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.show_plan_renewal_for_corporate ? value.plans_text : value.plan_text_for_display,
          value: "plan_name" || "",
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ]);
    })
    return local.length > 0 ? (
      <View>
        <Text style={{
          fontSize: 18,
          fontWeight: '500',
          marginTop: 15,
          marginLeft: 10
        }}>Pending LumpSum Plan For Renewal</Text>
        {isMobile ? (
          <View style={styles.methodContainer}>
            <ScrollView horizontal={true} >
              <PlansTable
                header={headerData}
                gridConfig={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
                data={local}
                pagination={false}
              />
            </ScrollView>
          </View>) : (<View>
            <View style={styles.methodContainer} >
            <ScrollView horizontal={true}>
              <PlansTable
                header={headerData}
                gridConfig={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
                data={local}
                pagination={false}
              />
              </ScrollView>
            </View>
          </View>
        )
        }
        {!user_id && (productWisePlans[plan].lumpsum_plans_pending_renewal.length >= 2) && (<View style={styles.checkboxView}>
          <CheckBox value={mergePlan} onValueChange={(value) => {
            setMergePlan(value);
            mergePlans[plan] = value;
            // console.log("Merge Plans Data is " + JSON.stringify(value) + " and status is " + JSON.stringify(mergePlans));
          }} />
          <Text style={{ marginHorizontal: 10 }}>Consolidate renewal into single plan for easy tracking</Text>
        </View>
        )}
        {mergePlan && (
          <Text>{productWisePlans[plan].plan_merge_text}</Text>
        )}
      </View>
    ) : (<View></View>);
  };

  const renderSeparator = () => <View style={styles.separator} />;


  return isLoading ? (<ProgressBar msg={"Processing..."} />) : (
    <View style={styles.container}>
      {!plansArray[0] && (
        <Text style={{
          marginBottom: 50,
          fontWeight: "700"
        }}>No Pending plans</Text>
      )}
      {plansData && plansData.length > 0 ? (
        <FlatList
          data={plansArray}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <View style={styles.productContainer}>
              {
                show_product ? (
                  <Text style={{ fontWeight: '500', fontSize: 22 }}>Product: {item.product}</Text>
                ) : (null)
              }
              <RenderNewPlans plan={item.product} />
              <RenderRenewalSIPPlans plan={item.product} />
              <RenderRenewalLumpsumPlans plan={item.product} />
            </View>
          )}
          ItemSeparatorComponent={renderSeparator}
        />
      ) : (
        <Text>No plans data available.</Text>
      )}
      {user_id ? (showViewMode ? (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <TouchableOpacity
            onPress={handleContinue}
          >
            <Text style={styles.viewModeText}>
              Next {">>>"}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={handleDashboardEvent}
          >
            <Text style={styles.viewModeText}>
              Go to Dashboard {">>>"}
            </Text>
          </TouchableOpacity>
        </View>) : (<View />)
      ) : (
        <View>
          {plansArray[0] != null ? (<View>
          {!pd.results.show_digio_esign && (
            <View style={styles.btnView}>
              <TouchableOpacity style={styles.buttonContainer} onPress={sendConfirmAndContinue}>
                <Text style={styles.buttonText}>Confirm and Continue</Text>
              </TouchableOpacity>
            </View>
          )}
          </View>): null}
          </View>)}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  pageTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  productContainer: {
    marginBottom: 24,
  },
  productName: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  methodContainer: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    width: '90%',
    paddingHorizontal: 12,
    paddingVertical: 8,
    margin: 10
  },
  checkboxView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  planContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  checkboxText: {
    marginLeft: 8,
  },
  mergerText: {
    marginLeft: 16,
  },
  separator: {
    height: 1,
    backgroundColor: '#ccc',
    marginVertical: 12,
  },
  btnView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    fontWeight: "bold",
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: "#410DAA",
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  rowItem: {
    textAlign: "left",
  },
  rowItemContainerStyle: {
    justifyContent: "flex-start",
    paddingLeft: 10,
  },
  headerItemContainerStyle: {
    justifyContent: "flex-start",
  },
});

export default PlansScreen;