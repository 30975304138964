import React from "react";
import { View, StyleSheet, Text, TextInput } from "react-native";
import { isMobile } from "../../../utils";

const TextInputField = ({
  label,
  value,
  prefix = null,
  postfix = null,
  onChange,
  style = [],
  placeholder,
  numberOfLines = 1,
  multiline = false,
  required = false,
  inputStyle = [],
  ...props
}) => {
  return (
    <View style={[textStyle.container, ...style]}>
      {label && (
        <Text style={textStyle.label}>
          {label} <Text style={textStyle.required}>{required ? "*" : ""}</Text>
        </Text>
      )}
      <View style={textStyle.inputBoxContainer}>
        {prefix && <Text style={textStyle.prefix}>{prefix}</Text>}
        <TextInput
          value={value}
          style={[
            textStyle.input,
            !props.editable && textStyle.notEditable,
            prefix && textStyle.textInputPrefix,
            ...inputStyle,
          ]}
          placeholder={placeholder}
          onChangeText={(text) => {
            onChange && onChange(text);
          }}
          multiline={multiline}
          numberOfLines={numberOfLines}
          {...props}
        />
        {postfix && <Text style={textStyle.postfix}>{postfix}</Text>}
      </View>
    </View>
  );
};

const textStyle = StyleSheet.create({
  container: {
    width: isMobile ? "100%" : 300,
  },
  label: {
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: "700",
    color: "#464E5F",
    marginBottom: 11,
    zIndex: -1,
  },
  input: {
    width: "100%",
    borderWidth: 1,
    borderColor: "#767676",
    backgroundColor: "#fff",
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 11,
    zIndex: -1,
  },
  notEditable: {
    backgroundColor: "#EDF4F6",
  },
  required: {
    color: "red",
  },
  inputBoxContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  prefix: {
    fontSize: 15,
    color: "#121212",
    position: "absolute",
    paddingLeft: 6,
  },
  postfix: {
    fontSize: 15,
    color: "#121212",
    paddingLeft: 6,
  },
  textInputPrefix: {
    paddingLeft: 15,
  },
});

export default TextInputField;
