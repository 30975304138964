import React, {Component} from "react";
import {View,Text,TouchableOpacity,StyleSheet, ActivityIndicator} from "react-native";

export const CustomButton = ({title,onPress,buttonStyle={},textStyle={},isLoading=false,loaderColor=null}) => {

        return(
            <TouchableOpacity onPress={()=>{onPress&&onPress()}} style={[styles.buttonStyle,{...buttonStyle}]}>
                {
                    isLoading ? (
                            <ActivityIndicator size="small" color={loaderColor||"#fff"}/>
                        ):(
                            <Text style={[styles.textStyle,{...textStyle}]}>{title}</Text>
                    )
                }
            </TouchableOpacity>
        )
        
}

export default class CustButton extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
  
    render() {
        const { container, textStyle } = styles;
        const {
            bgColor,
            textColor,
            buttonText,
            style,
            onPress,
            borderColor,
            buttonTextStyle,
            loaderColor,
            isLoading
        } = this.props;
        return (
            <TouchableOpacity
            style={[
                container,
                {
                backgroundColor: bgColor,
                borderWidth: 1,
                borderColor: borderColor
                },
                style
            ]}
            onPress={() => {
                !isLoading && onPress && onPress();
            }}
            >
            {isLoading ? (
                <ActivityIndicator size="small" color={loaderColor || "#fff"} />
            ) : (
                <Text style={[textStyle, { color: textColor }, buttonTextStyle]}>
                {" "}
                {buttonText || this.props.children}{" "}
                </Text>
            )}
            </TouchableOpacity>
        );
    }
}

const styles = StyleSheet.create({
    buttonStyle:{
        backgroundColor:"#410DAA",
        borderColor:"#410DAA",
        borderWidth:1,
        borderRadius:6,
        paddingHorizontal:34,
        paddingVertical:9,
        justifyContent:"center",
        alignItems:"center"
    },
    textStyle:{
        color:"#fff",
        fontWeight:"700",
    },
    container: {
        alignItems: "center",
        padding: 12,
        borderRadius: 4
    },
      textStyle: {
        fontSize: 16,
    }
})

CustButton.defaultProps = {
    bgColor: "#410DAA",
    textColor: "#fff",
    borderColor: "#410DAA"
  };