import React from "react";
import {
  View,
  TouchableOpacity,
  Animated,
  StyleSheet
} from "react-native";
import { Text, Button } from "react-native-paper";
import numberFormat from "../../../utils/numberFormat";

export const TaggedFundStrip = ({
  title,
  sideValue,
  fundType,
  fundId,
  style,
  onPressDetach,
  rightContentStyle,
  detachStyle,
  SlideInLeft,
  slided,
  onEditPress,
  onCancelPress,
  id,
  activeId,
  isLoading,
  action
}) => {
  return (
    <View style={{ ...style }}>
      <Animated.View
        style={[
          id == activeId
            ? {
                transform: [
                  {
                    translateX: SlideInLeft.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 1]
                    })
                  }
                ]
              }
            : {},
          { backgroundColor: "#fff", paddingBottom: 12 }
        ]}
      >
        <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
          <View style={{ flex: 0.7 }}>
            <Text style={{ color: "#121212", fontSize: 13 }}>{title}</Text>
          </View>
          <View
            style={{
              flex: 0.3,
              alignItems: "flex-end",
              ...rightContentStyle
            }}
          >
            <Text style={{ color: "#121212", fontSize: 14 }}>
              {sideValue && !isNaN(sideValue)
                ? `₹ ${numberFormat(sideValue.toFixed(0))}`
                : null}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 10
          }}
        >
          <View
            style={{ flex: 0.7, flexDirection: "row", alignItems: "center" }}
          >
            <Text style={{ color: "#939393", fontSize: 13 }}>#{fundId}</Text>
            <Text
              style={{
                color: "#121212",
                fontSize: 10,
                backgroundColor: "#F2F2F2",
                padding: 4,
                paddingHorizontal: 16,
                borderRadius: 10,
                marginLeft: 20
              }}
            >
              {fundType}
            </Text>
          </View>
          <View
            style={{
              flex: 0.3,
              alignItems: "flex-end",
              ...rightContentStyle
            }}
          >
            <TouchableOpacity
              onPress={() => {
                // console.log("#id", id);
                if (!slided) {
                  onEditPress();
                } else {
                  onCancelPress();
                }
              }}
              style={{
                borderColor: "#410DAA",
                borderRadius: 6,
                borderWidth: 1,
                backgroundColor: id == activeId ? "#410DAA" : "#fff"
              }}
            >
              <Text
                style={{
                  color: id == activeId ? "#fff" : "#410DAA",
                  fontSize: 10,
                  paddingVertical: 2,
                  paddingHorizontal: 15
                }}
              >
                {id != activeId ? "Edit" : "Cancel"}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Animated.View>
      <Button
          uppercase={false}
          mode={"outlined"}
          onPress={() => {
            onPressDetach();
          }}
          style={{
            position: "absolute",
            right: 16,
            height: 40,
            zIndex: -1,
            backgroundColor: "#EF5D12",
            justifyContent: "center",
            alignItems: "flex-end",
            paddingTop: 16,
            paddingBottom: 16,
            borderColor: "transparent",
            ...detachStyle
          }}
          loading={isLoading}
          color={"#EF5D12"}
      >
          <Text style={styles.next}>{action == "attach" ? "Attach" : "Detach"}{" "}</Text>
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  next: {
    color: "#fff",
    fontSize: 15,
  },
})
