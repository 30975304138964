import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { addPanNumber } from "../../../api";
import { FontAwesome5 } from "@expo/vector-icons";

export default class payment_methods extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  handleContinue = async () => {
    try {
      const response = await addPanNumber();
      if (response.code == 100) {
        this.props.navigation.navigate("uploadpaymentrecipt", {});
        let { data } = response.result;
      } else {
        this.setState({
          error: true,
          errorText: response && response.msg ? response.msg : null,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  render() {
    const { data } = this.state;
    return (
      <View style={styles.container}>
        {/* <Header  navigation={this.props.navigation} /> */}
        <ScrollView style={styles.mainContainer}>
          <View style={styles.body}>
            <View style={styles.left}>
              <Text style={styles.terms}>Terms and Conditions</Text>
              <Text style={styles.please}>
                Please agree to following terms and conditions
              </Text>
              <Image
                source={
                  "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/Group+20618.png"
                }
                style={styles.left_img}
              />
              <View
                style={{
                  flexDirection: "row",
                  width: 430,
                  borderWidth: 1,
                  borderColor: "rgba(65, 13, 170, 0.2)",
                  backgroundColor: "#ffffff",
                  paddingVertical: 21,
                  paddingLeft: 20,
                  paddingRight: 6,
                  marginTop: 33,
                  marginBottom: 22,
                }}
              >
                <Image
                  source={
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/Vector+(1).png"
                  }
                  style={styles.lock}
                />
                <Text style={styles.jam_we}>
                  Maxiom Wealth is a wealth advisory platform requires few
                  mandatory details.The given information is stored securely. It
                  is never shared with anyone else.
                </Text>
              </View>
              <Text style={styles.any}>
                Any Queries?{" "}
                <TouchableOpacity
                  onPress={() => {
                    this.props.navigation.navigate("App", {
                      screen: "Dashboard",
                      params: {
                        screen: "Dashboard",
                        params: {
                          screen: "GetHelp",
                          params: {},
                        },
                      },
                    });
                  }}
                >
                  <Text style={{ color: "#410DAA" }}>Get Help</Text>
                </TouchableOpacity>
              </Text>
            </View>
            <View style={styles.right}>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 50,
                  paddingHorizontal: 25,
                  paddingVertical: 14,
                  justifyContent: "space-between",
                  backgroundColor: "rgba(247, 244, 251, 0.7)",
                }}
              >
                <Text style={styles.amt_pbl}>Amount Payable</Text>
                <Text style={styles.amont}>₹201,600</Text>
              </View>
              <Text>
                <Text style={{ fontWeight: "bold" }}>Note:</Text> Please ensure
                the payments to be made from your own bank account. Tranfers
                from any other banks will be declined and refunded in 2-5
                business days
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  flex: 1,
                  paddingLeft: 45,
                  paddingTop: 21,
                }}
              >
                <View style={{ flex: 0.5 }}>
                  <Text style={styles.bnk_trn}>Bank Transfer</Text>
                  <Text style={styles.neft}>(NEFT/RTGS)</Text>
                </View>
                <View style={{ flex: 0.19 }}>
                  <Text style={styles.acc_hdr}>Account Holder:</Text>
                  <Text style={styles.acc_hdr}>Account Number:</Text>
                  <Text style={styles.acc_hdr}>IFSC Code:</Text>
                  <Text style={styles.acc_hdr}>Bank Name:</Text>
                  <Text style={styles.acc_hdr}>Bank Branch:</Text>
                </View>
                <View style={{ flex: 0.31 }}>
                  <Text style={styles.simp_grw}>Simply Grow Technologies</Text>
                  <Text style={styles.simp_grw}>
                    236405500225{" "}
                    <FontAwesome5 name="file-alt" size={13} color="#410DAA" />
                  </Text>
                  <Text style={styles.simp_grw}>
                    ICIC0002364{" "}
                    <FontAwesome5 name="file-alt" size={13} color="#410DAA" />
                  </Text>
                  <Text style={styles.simp_grw}>ICICI Bank</Text>
                  <Text style={styles.simp_grw}>Langar Houz, Hyderabad</Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderTopWidth: 1,
                  borderBottomWidth: 1,
                  borderTopColor: "#E5EAEE",
                  borderBottomColor: "#E5EAEE",
                  borderTopRadius: 6,
                  //borderBottomRadius:6,
                  paddingVertical: 11,
                  paddingRight: 10,
                  paddingLeft: 50,
                }}
              >
                <Text style={styles.pay_by}>Pay by UPI </Text>
                <View style={{ alignItems: "center" }}>
                  <Image
                    source={
                      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/1200px-QR_code_for_mobile_English_Wikipedia+1.png"
                    }
                    style={styles.barcode}
                  />
                  <Text style={styles.scan}>Scan the QR Code & Pay</Text>
                </View>
              </View>
              <View
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingVertical: 31,
                  borderBottomWidth: 1,
                  borderBottomColor: "#E5EAEE",
                  //borderBottomRadius:6
                }}
              >
                <Text style={styles.pay_by}>Pay by net banking</Text>
                <Text>Search Bank Name</Text>
              </View>
              <TouchableOpacity
                onPress={() => {
                  this.handleContinue();
                }}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: 40,
                }}
              >
                <Text style={styles.adhaar}>Upload Payment Receipt</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1 },
  mainContainer: {},
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: 102,
    paddingRight: 92,
    paddingBottom: 60,
  },
  left: {
    flex: 0.45,

    paddingTop: 55,
  },
  right: {
    flex: 0.55,
  },
  left_img: {
    resizeMode: "contain",
    width: 378,
    height: 325,
  },
  lock: {
    resizeMode: "contain",
    width: 24.5,
    height: 34,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 22,
    lineHeight: 25,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
    paddingTop: 11,
    paddingBottom: 32,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },

  adhaar: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    fontWeight: "bold",
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  amt_pbl: {
    fontFamily: "Roboto",
    fontSize: 17,
    lineHeight: 20,
    color: "#2F3044",
    fontWeight: "bold",
  },
  amont: {
    fontFamily: "Roboto",
    fontSize: 17,
    lineHeight: 20,
    color: "#2F3044",
    fontWeight: "bold",
    letterSpacing: 0.5,
  },
  note: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 12,
    lineHeight: 14,
    paddingTop: 7,
  },
  bnk_trn: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    fontWeight: "bold",
    letterSpacing: 0.5,
    color: "#464E5F",
  },
  neft: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    letterSpacing: 0.5,
    color: "#464E5F",
    paddingTop: 7,
  },
  acc_hdr: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 15,
    letterSpacing: 0.32,
    color: "#464E5F",
    fontWeight: "bold",
    paddingTop: 11,
  },
  simp_grw: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.5,
    color: "#000000",
    paddingTop: 11,
  },
  barcode: {
    width: 88,
    height: 88,
    resizeMode: "contain",
  },
  pay_by: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    letterSpacing: 0.5,
    color: "#464E5F",
    fontWeight: "bold",
  },
  scan: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.5,
    color: "#000000",
    paddingTop: 5,
  },
});
