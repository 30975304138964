import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { ProductTabs } from "../../UpcomingInvestments/ProductTabs";
import LandingPage from "./invest";
import ExplorePage from "./explore";
import { useRoute } from "@react-navigation/core";

import NavigationHeader from "../../Common/NavigationHeader";
import { isMobile } from "../../../utils";
import Store from "../../../store";

const InvestExplore = (props) => {
  const { userData } = Store.getState();
  let tabsToShow = [];

  // if (userData.userProfileData.is_equitycustomer || true) {
  //   tabsToShow = [{ label: "Explore", value: 1 }];
  // } else {
    tabsToShow = [
      { label: "Invest", value: 0 },
      { label: "Explore", value: 1 },
    ];
  // }

  const route = useRoute();
  const [activeIndex, setActiveIndex] = useState(() => {
    let tabIndex = 0;
    const { params = {} } = route;
    if (params) {
      tabIndex = params.index || 0;
    }
    return tabIndex;
  });

  const _onSelectTab = (index) => {
    setActiveIndex(index);
  };

  return (
    <View style={styles.container}>
      {tabsToShow.length == 1 ? (
        <>
          {!isMobile && (
            <NavigationHeader
              navigation={props.navigation}
              navigationHeader={["Explore"]}
              onPressBack={() => {
                props.navigation.goBack();
              }}
            />
          )}
          <ExplorePage {...props} />
        </>
      ) : (
        <>
          <ProductTabs
            tabs={tabsToShow}
            onSelectTab={_onSelectTab}
            data={[]}
            activeTab={activeIndex}
            style={[
              {
                paddingTop: 10,
              },
            ]}
          />

          {activeIndex == 0 && <LandingPage {...props} />}

          {activeIndex == 1 && <ExplorePage {...props} />}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});

export default InvestExplore;
