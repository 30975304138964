import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  ScrollView,
} from "react-native";
import Table from "../../../../components/Common/Table";
import NavigationHeader from "../../../../components/Common/NavigationHeader";
import amplitude from "../../../../utils/amplitude";
import { capitalizeFirstLetter, formatDate, getUserDetails, isMobile, numberWithCommas } from "../../../../utils";
import { getDividendYields } from "../../../../api/billing";
import { ActivityIndicator } from "react-native-paper";
import SearchBar from "../../../../components/Common/SearchBar";
import { getCustomerDetails } from "../../../../api";
import Store from "../../../../store";
import HoldingsTable from "../../HoldingsTable";

// import ReactExport from "react-export-excel";
import moment from "moment";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const response = {
  code: 100,
  msg: "Fetched",
  results: {
    amount: 123456,
    dividend_period: "1 Jan 2020 - 06 Jun 2022",
    stocks: [
      {
        name: "Procter & Gamble Hygiene and Health care Limited",
        date: "30 Sep 2021",
        units: 100,
        dividend_per_share: 160,
        dividend_amount: 123454
      },
      {
        name: "Procter & Gamble Hygiene and Health care Limited",
        date: "30 Sep 2021",
        units: 100,
        dividend_per_share: 160,
        dividend_amount: 123454
      },
      {
        name: "Procter & Gamble Hygiene and Health care Limited",
        date: "30 Sep 2021",
        units: 100,
        dividend_per_share: 160,
        dividend_amount: 123454
      }
    ]
  }
};
class DividendYield extends React.Component {
  constructor(props) {
    super(props);
    let bill_id, user_id;
    const { params } = this.props.route;
    if(params){
      bill_id = params.bill_id || null;
      user_id = params.user_id || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      bill_id,
      isLoading: true,
      user_id
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("DividendYield",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("DividendYield", { path: this.props.navigation.getState().routeName });
      }
      const { bill_id } = this.state;
      const response = await getDividendYields(bill_id);
      if(response && response.code == 100){
        const { results } = response;
        const { dividend:amount, plan_period_text:dividend_period, dividend_list:stocks } = results;
        const data = {
          amount,
          dividend_period,
          stocks: [...stocks].reverse()
        }
        this.setState({ isLoading: false, data, dataBackup:data});

        let userData = {};
        if (this.state.user_id) {
          userData = await getCustomerDetails(this.state.user_id);
        } else {
          userData = await getUserDetails();
        }
        let { first_name } = userData;
        let names = first_name ? first_name.split(/\s+/) : [];
        let name = "";
        if (names.length >= 2 && names.length > 0) {
          name =
            capitalizeFirstLetter(names[0]) +
            " " +
            capitalizeFirstLetter(names[1]);
        } else {
          if (names.length == 0) {
            name = "User";
          } else {
            name = names[0];
          }
        }
        this.setState({ userName: name });

      }else{
        this.setState({ error: "Error loading dividend yields ", isLoading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ error: "Error loading dividend yields ", isLoading: false });
    }
  }

  navigateBack = () => {
    const { navigation } = this.props;
    if (navigation) {
      navigation.goBack();
    }
  };

  compare = (a, b, field, sort) => {
    if (a[field] < b[field]) {
      return sort ? -1 : 1;
    }
    if (a[field] > b[field]) {
      return sort ? 1 : -1;
    }
    return 0;
  };

  handleSort = (field, sort, start, end) => {
    this.setState(
      {
        sorting_field: field,
        sorting_value: !sort,
      },
      () => {
        const { data } = this.state;
        const stocks_data = [...data["stocks"]];
        const sortedData = [].concat(
          stocks_data.slice(0, start),
          stocks_data
            .slice(start, end)
            .sort((a, b) => this.compare(a, b, field, !sort)),
            stocks_data.slice(end, data.length)
        );
        this.setState({ data: { ...data, ["stocks"]:sortedData} });
      }
    );
  };

  handleSearch = (searchText) => {
    let searchResults = [];
    let searchData = this.state.data.stocks;
    if (searchText) {
      searchResults = searchData.filter((item) => {
        return item["ms.name"].toLowerCase().startsWith(searchText);
      });
    } else {
      searchResults = this.state.dataBackup.stocks;
    }

    if (searchResults.length) {
      // const data = {
      //   transactions: searchResults,
      //   transactions_as_on: this.state.data.transactions_as_on,
      // };
      const amount = searchResults.reduce((c, p) => c + p.net_amount, 0)
      const data = {
        amount: amount,
        dividend_period: this.state.data.dividend_period,
        stocks: [...searchResults].reverse()
      }
      this.setState({
        data,
        searchText,
        searchResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  getTableData = (data = null) => {
    if(!data || data && !data.hasOwnProperty("stocks")) return {};
    const { sorting_field, sorting_value } = this.state;
    const headerData = [
      {
        label: "Stock Name",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "ms.name",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Date",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "exe_date",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Units",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "bal_units",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Dividend per Share",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "net_amount",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Dividend Amount",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "dividend_amount",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
    ];
    let { values = [] } = this.generateDataForTable(data["stocks"]);
    return { headerData, values };
  };

  generateDataForTable = (data) => {
    let values = [];
    data.forEach((item, index) => {
      let { "ms.name": name, exe_date, bal_units: units, net_amount: dividend_per_share, dividend_amount } = item;
      dividend_amount = numberWithCommas(parseInt(dividend_amount));
      values.push([
        {
          valueToDisplay: name,
          value: name,
          style: [styles.rowItem, styles.stockName],
        },
        {
          valueToDisplay: formatDate(exe_date),
          value: exe_date,
          style: [styles.rowItem, styles.date],
        },
        {
          valueToDisplay: parseInt(units),
          value: units,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: parseInt(dividend_per_share),
          value: dividend_per_share,
          style: [styles.rowItem],
        },
        {
            valueToDisplay: "₹" + dividend_amount,
            value: dividend_amount,
            style: [styles.rowItem],
        },
      ]);
    });
    return { values };
  };

  render() {
    const { navigation } = this.props;
    const { data = null, isLoading, error, searchText } = this.state;

    const {
      headerData = [],
      values = [],
    } = this.getTableData(data);

    let excelBillingDetails = [];
    if (this.state.data) {
      this.state.data.stocks.forEach((item) => {
        let dividend_amount = "₹" + numberWithCommas(parseInt(item.dividend_amount));

        excelBillingDetails.push({
          stock_name: item["ms.name"],
          date: item.exe_date,
          units: item.bal_units,
          net_amount: item.net_amount,
          dividend_amount: dividend_amount
        });
      });
    }

    return (
      <View style={styles.container}>
        <NavigationHeader
          navigation={navigation}
          navigationHeader={[ "Bills", "View Bills", "View Details"]}
          onPressBack={this.navigateBack}
        />
        {
          (isLoading|| error) ? (
            <View style={styles.loadingContainer}>
              {
                isLoading && <ActivityIndicator size="large" color="#410DAA" />
              }
              {
                error && <Text style={styles.error}>{error}</Text>
              }
            </View>
          ): (
            <ScrollView
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={true}
              horizontal={isMobile ? true : false}
              style={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#1212121A",
                  borderRadius: 10,
                  marginHorizontal: isMobile ? 16 : 20,
                  width: isMobile ? 700 : '80%',
                  marginVertical: 20,
                }}
              >
                <View
                  style={{
                    paddingHorizontal: isMobile ? 16 : 19,
                    paddingVertical: 19,
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text style={styles.header}>Dividend Yeild</Text>
                    <Text style={styles.headerBillingPeriod}>
                      (Period: {data["dividend_period"]})
                    </Text>
                  </View>
                  <Text style={styles.billTotalAmount}>₹{numberWithCommas(parseInt(data["amount"]))}</Text>
                </View>
                <View style={styles.headingContainer}>
                  <SearchBar value={searchText} onChange={this.handleSearch}  />
                  <View
                    style={{
                      cursor: "pointer",
                      justifyContent: "center",
                      flexDirection: "row",
                      marginRight: 20,
                    }}
                  >
                    {/* <ExcelFile
                      filename={
                        this.state.userName + moment().format("DD-MM-YYYY hh-mm-ss A")
                      }
                      element={
                        <Text
                          style={{
                            color: "#410DAA",
                            fontWeight: "bold",
                            cursor: "pointer",
                            fontSize: 15,
                            textDecorationLine: "underline",
                          }}
                        >
                          Download As Excel
                        </Text>
                      }
                    >
                      <ExcelSheet data={excelBillingDetails} name="Invoices">
                        <ExcelColumn label="Stock name" value="stock_name" />
                        <ExcelColumn label="Date" value="date" />
                        <ExcelColumn label="Units" value="units" />
                        <ExcelColumn label="Dividend per share" value="net_amount" />
                        <ExcelColumn label="Dividend Amount" value="dividend_amount" />
                      </ExcelSheet>
                    </ExcelFile> */}
                  </View>
                </View>
                <HoldingsTable
                  header={headerData}
                  gridConfig={[0.25, 0.25, 0.2, 0.2, 0.1]}
                  data={values}
                  navigation={this.props.navigation}
                />
              </View>
            </ScrollView>
          )
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: isMobile ? 16 : 36,
    marginVertical: 30,
  },
  borderColor: {
    borderColor: "#DBC0FF",
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 10 : 40,
    paddingVertical: 19,
    flexDirection: "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "space-between",
  },
  header: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "700",
  },
  headerBillingPeriod: {
    fontSize: 15,
    fontFamily: "Roboto",
    color: "#464E5F",
    marginTop: 10,
  },
  stockName: {
    textAlign: "left",
    paddingLeft: 5
  },
  date: {
    fontSize: isMobile ? 9 : 12
  },
  billTotalAmount: {
    fontSize: 15,
    color: "#464E5F",
    fontFamily: "Roboto",
    fontWeight: "700",
    marginRight: 50,
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: isMobile ? 10 : 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  valuationDate: { fontSize: 11 },
  valuationDateContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  active: { color: "#410DAA", fontWeight: "700" },
  bold: { fontWeight: "bold", marginLeft: 12 },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default DividendYield;
