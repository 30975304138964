import React from "react";
import amplitude from "../../../utils/amplitude";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  Image,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from "react-native";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { md } from "../../../Responsive";
import { getExploreScreenFunds, getTransactionPopUp } from "../../../api";
import {
  CapitalizeWordsInSentence,
  getScreenConfigForCustomer,
  isMobile,
} from "../../../utils";
const { width } = Dimensions.get("window");
const isWeb = md(width);
import AntIcon from "@expo/vector-icons/AntDesign";
import Icon from "@expo/vector-icons/AntDesign";
import { StackActions } from "@react-navigation/routers";
import ExploreStocksPortfolio from "./exploreStocksPortfolio";
import CustomModal from "../../Common/Modals/CustomModal";
import { Button } from "react-native-paper";
import TextInputField from "../../Common/Inputs/InputField";
import { usePlaceTopup } from "../../../utils/hooks";
import { PopUp } from "../../Common/PopUp";
import { usePlaceMFOrder } from "../../../utils/hooks/usePlaceMFOrder";
import { connect } from "react-redux";

import GrowWealthStrip from "../../../screens/dashboardHome/growWealthStrip";
import { ShowToast } from "../../Common/Toast";
import Store from "../../../store";

const ExploreCardItem = ({ active, onPress, label, description }) => {
  return (
    <TouchableOpacity
      style={[
        exploreStyles.clickableContainer,
        { backgroundColor: active ? "#410DAA1A" : "" },
      ]}
      onPress={onPress}
    >
      <>
        <View style={exploreStyles.imageContainer}>
          <Image
            source={{
              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/equity.png",
            }}
            resizeMode="contain"
            style={exploreStyles.image}
          />
          <Text style={exploreStyles.label}>{label}</Text>
        </View>
        <View style={exploreStyles.descriptionContainer}>
          <Text style={exploreStyles.description}>{description}</Text>
        </View>
      </>
    </TouchableOpacity>
  );
};

const exploreStyles = StyleSheet.create({
  imageContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  image: {
    height: 28,
    width: 28,
    alignSelf: "flex-start",
    marginRight: 10,
  },
  label: {
    fontSize: 14,
    color: "#000000",
    fontWeight: "500",
    lineHeight: 20,
    marginRight: 10,
  },
  descriptionContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    marginLeft: 38,
  },
  description: {
    fontWeight: "500",
    fontFamily: "Roboto",
    color: "#6C7293",
    fontSize: 12,
  },
  clickableContainer: {
    borderWidth: 1,
    borderColor: "#410DAA33",
    borderRadius: 15,
    paddingHorizontal: 20,
    paddingVertical: 15,
    height: 100,
    marginRight: 32,
    width: isMobile ? "85%" : "25%",
  },
});

const FundField = ({ style, name, value, tooltip, colorValue }) => {
  return (
    <View style={{ ...style }}>
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: "#6C7293",
            fontWeight: "600",
          }}
        >
          {name}
        </Text>
        {tooltip && (
          <ANTIcon
            name="infocirlce"
            color={"#a8a8a8"}
            size={12}
            style={{
              paddingLeft: 10,
            }}
          />
        )}
      </View>
      <Text
        style={{
          fontSize: 12,
          color: colorValue ? colorValue : "#464E5F",
          fontWeight: "700",
          paddingTop: 5,
        }}
      >
        {value}
      </Text>
    </View>
  );
};

const YearPerformanceItem = ({ item, index, active, onClick, sortOrder }) => {
  return (
    <TouchableOpacity
      style={{
        backgroundColor: active ? "#410DAA" : "#fff",
        paddingHorizontal: 8,
        paddingVertical: 12,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
      }}
      onPress={() => {
        onClick(index);
      }}
    >
      <Text style={{ fontSize: 14, color: active ? "#fff" : "#410DAA" }}>
        {item}{" "}
        {active ? (
          sortOrder ? (
            <Icon name="arrowup" color={"#fff"} />
          ) : (
            <Icon name="arrowdown" color={"#fff"} />
          )
        ) : null}
      </Text>
    </TouchableOpacity>
  );
};

const PerformaceFilter = ({ data, active, sortOrder, onClick }) => {
  return (
    <>
      <FlatList
        data={data}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item, index }) => {
          return (
            <YearPerformanceItem
              item={item}
              index={index}
              active={index == active}
              sortOrder={sortOrder}
              onClick={onClick}
            />
          );
        }}
        keyExtractor={(item, index) => index}
        contentContainerStyle={{
          paddingBottom: 0,
        }}
      />
    </>
  );
};

const FilterItem = ({ item, onPress, backgroundColor, color }) => {
  return (
    <TouchableOpacity
      style={{
        borderWidth: 0.5,
        borderColor: "#BCBCBC",
        borderRadius: 18,
        backgroundColor,
        marginRight: 16,
        paddingLeft: 20,
        paddingRight: 20,
        height: 36,
        alignItems: "center",
        justifyContent: "center",
      }}
      onPress={onPress}
    >
      <Text style={{ fontSize: 14, color }}>{item}</Text>
    </TouchableOpacity>
  );
};

const ProductInvestNowStrip = ({ code, productName, isStocks, pk }) => {
  const { loading, error, placeTopUp } = usePlaceTopup();
  const {
    loading: mfLoading,
    error: mfError,
    placeMFOrder,
  } = usePlaceMFOrder();
  const [amount, setAmount] = React.useState(0);
  const handleChangeAmount = (value) => {
    if (!isNaN(value)) setAmount(value);
  };

  const handleInvestNow = () => {
    if (isStocks) {
      const payload = {
        amount,
        product: code,
      };
      placeTopUp(payload);
    } else {
      const payload = {
        amount,
        pk,
      };
      placeMFOrder(payload);
    }
  };

  return (
    <View style={productInvestStyles.container}>
      <Text style={productInvestStyles.heading}>Invest in Product</Text>
      <View style={productInvestStyles.formItem}>
        <TextInputField
          label="Product Name"
          value={productName}
          onChange={(text) => {
            this.setState({
              name: text,
            });
          }}
          style={[productInvestStyles.input]}
          editable={false}
        />
      </View>
      <View style={productInvestStyles.formItem}>
        <TextInputField
          label="Amount"
          value={amount}
          onChange={handleChangeAmount}
          style={[productInvestStyles.input]}
          editable={true}
        />
      </View>
      <Text style={productInvestStyles.error}>
        {isStocks ? error : mfError || "  "}
      </Text>
      <Button
        uppercase={false}
        mode={"contained"}
        onPress={handleInvestNow}
        style={productInvestStyles.button}
        color={"#410DAA"}
        loading={isStocks ? loading : mfLoading}
      >
        <Text style={{ color: "#fff" }}>Invest Now</Text>
      </Button>
    </View>
  );
};

const productInvestStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  heading: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#121212",
    marginBottom: 8,
  },
  formItem: {
    marginTop: 10,
    marginBottom: 10,
  },
  button: {
    width: 120,
    height: 35,
    borderRadius: 20,
    backgroundColor: "#410DAA",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 20,
  },
  error: {
    paddingVertical: 4,
    color: "red",
    fontSize: 12,
  },
});
class MFExplore extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let userId = null;
    let from = null;
    let activeFilter = this.props.demoMode
      ? "STOCKS_PORTFOLIO"
      : getScreenConfigForCustomer().show_explore_stocks
      ? "DIRECT_EQUITY"
      : "EQUITY";
    let activeFundType = "";
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      from = params.from || null;
      activeFilter = params.filter ? params.filter.toUpperCase() : activeFilter;
      activeFundType = params.type
        ? CapitalizeWordsInSentence(params.type)
        : "";
    }

    this.state = {
      userId,
      from,
      activeTabIndex: 0,
      isLoading: true,
      filters: [],
      mfDetails: [],
      mfDetailsMobileView: [],
      isSwitchOn: false,
      activeFilter: activeFilter,
      activeFundType,
      activeSubFilters: [],
      ["EQUITY"]: [],
      ["DEBT"]: [],
      ["HYBRID"]: [],
      ["GOLD"]: [],
      yearFilter: 1,
      activeTabData: [],
      activeTabDataWeb: [],
      showModal: false,
      sortOrder: false,
      updatedData: {},
      showLoader: false,
      showLoaderNew: false,
      showErrorToast: false,
    };
  }

  closePopUp = async () => {
    this.setState({ showPopUp: false });
  };

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("mfexplore", { path: global.location.href });
      } else {
        amplitude.logEvent("mfexplore", {
          path: this.props.navigation.state.routeName,
        });
      }
      let transactionPopUpResponse = [];
      let exploreResp = [];

      if (
        Store.getState().explore &&
        Store.getState().explore.transactionPopUpResponse &&
        Store.getState().explore.exploreResp
      ) {
        transactionPopUpResponse =
          Store.getState().explore.transactionPopUpResponse;
        exploreResp = Store.getState().explore.exploreResp;
      } else {
        transactionPopUpResponse = await getTransactionPopUp();
        exploreResp = await getExploreScreenFunds();
        try {
          Store.dispatch({
            type: "GET_EXPLORE",
            payload: {
              transactionPopUpResponse,
              exploreResp,
            },
          });
        } catch (error) {
          console.log("error", error);
        }
      }
      let actionItemID, showPopUp, modalData;
      if (transactionPopUpResponse.code == 100) {
        if (transactionPopUpResponse.result.Show_pop_up) {
          let { cai_pk, modal_data } = transactionPopUpResponse.result;
          actionItemID = cai_pk;
          showPopUp = true;
          modalData = modal_data;
        }
      }

      if (exploreResp && exploreResp.code && exploreResp.code == "100") {
        const riskValues = {
          HGH: 0,
          MDH: 1,
          MOD: 2,
          MDL: 3,
          LOW: 4,
        };
        const data = exploreResp.results[0];
        const updatedData = Object.keys(data).reduce((prev, curr) => {
          if (prev[curr]) {
            return prev;
          } else {
            return {
              ...prev,
              [curr]: data[curr].map((fund) => ({
                ...fund,
                riskValue: riskValues[fund.AMCFund__risk_factor],
              })),
            };
          }
        }, {});

        this.setState(
          { actionItemID, showPopUp, modalData, updatedData, isLoading: false },
          () => {
            this.setFiltersAndData(updatedData);
          }
        );
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText:
            exploreResp && exploreResp.msg
              ? exploreResp.msg
              : "Something went wrong!",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  getSubFilter = (filter) => {
    if (filter == "EQUITY") {
      return "Index";
    }
    if (filter == "DEBT") {
      return "Liquid";
    }
    if (filter == "HYBRID") {
      return "Aggressive";
    }
    if (filter == "GOLD") {
      return "Funds";
    }
    return null;
  };

  setFiltersAndData = (data) => {
    let activeFilter = this.state.activeFilter;
    let activeFundType =
      this.state.activeFundType == ""
        ? this.getSubFilter(activeFilter)
        : this.state.activeFundType;

    if (activeFundType == "Elss") {
      activeFundType = "ELSS";
    }

    this.setState({ activeFilter, activeFundType }, () => {
      this.formatResponseData(data, activeFilter, activeFundType);
    });
  };

  formatResponseData = (data, filter, sub_filter) => {
    const { debt_schemes, hybrid_schemes, equity_schemes, gold_schemes } = data;
    this.setState(
      {
        ["DEBT"]: debt_schemes,
        ["EQUITY"]: equity_schemes,
        ["HYBRID"]: hybrid_schemes,
        ["GOLD"]: gold_schemes,
      },
      () => {
        this.getActiveTabData(filter, sub_filter);
      }
    );
  };

  sortDataPerformanceBased = async (filterIndex) => {
    let newArr = [];
    const activeTabData = await this.sortDataPerformanceBasedHelper(
      filterIndex,
      this.state.activeTabData
    );
    for (let i = 0; i < activeTabData.length; i += 2) {
      newArr.push(activeTabData.slice(i, i + 2));
    }

    this.setState({ activeTabData, activeTabDataWeb: newArr });
  };

  _renderYearPerformanceFilter = () => {
    const { yearFilter } = this.state;
    const DATA = ["A-Z", "3M", "6M", "1Y", "3Y", "5Y", "10Y", "Size", "Risk"];
    return (
      <PerformaceFilter
        data={DATA}
        sortOrder={this.state.sortOrder}
        active={yearFilter}
        onClick={(yearFilter) => {
          this.sortDataPerformanceBased(yearFilter);
          this.setState({ yearFilter }, () => {});
        }}
      />
    );
  };

  fundTypes = (tab) => {
    if (tab == "EQUITY") {
      return {
        Index: [],
        "Multi Cap": [],
        "Large Cap": [],
        "Large And Midcap": [],
        Midcap: [],
        "Small Cap": [],
        International: [],
        Sectoral: [],
        "Value Oriented": [],
      };
    }

    if (tab == "DEBT") {
      return {
        Liquid: [],
        "Over Night": [],
        "Money Market": [],
        "Ultra Short Term": [],
      };
    }

    if (tab == "HYBRID") {
      return {
        Aggressive: [],
        "Dynamic Asset Allocation": [],
      };
    }

    if (tab == "GOLD") {
      return {
        Funds: [],
      };
    }
  };

  getActiveTabData = (filter, sub_filter) => {
    let tab;
    switch (filter) {
      case "EQUITY":
        tab = ["EQUITY"];
        break;
      case "DEBT":
        tab = ["DEBT"];
        break;
      case "HYBRID":
        tab = ["HYBRID"];
        break;
      case "GOLD":
        tab = ["GOLD"];
        break;
      default:
        tab = ["EQUITY"];
        break;
    }

    let data = this.state[tab];
    let fundSubTypes = this.fundTypes(tab);
    if (!data) return;

    data.forEach((item) => {
      let fund_type = CapitalizeWordsInSentence(item.fund_type);
      fund_type = this.mapFundSubType(item.fund_type);
      if (fund_type in fundSubTypes) {
        const id = item.AMCFund__amcfund_id;
        let tempValue = fundSubTypes[fund_type].filter(
          (item) => item.AMCFund__amcfund_id == id
        );

        if (tempValue) {
          if (sub_filter == "Overnight Fund") {
            if (fundSubTypes) {
              fundSubTypes[fund_type] = [{ ...item }];
            }
          } else {
            fundSubTypes[fund_type].push(item);
          }
        }
      } else {
        if (fundSubTypes) {
          fundSubTypes[fund_type] = [{ ...item }];
        }
      }
    });

    const subFilters = Object.keys(fundSubTypes);
    subFilters.forEach((fund_type) => {
      if (fundSubTypes[fund_type].length == 0) {
        delete fundSubTypes[fund_type];
      }
    });

    let activeSubFilters = Object.keys(fundSubTypes);
    activeSubFilters = activeSubFilters.filter((element) => element);

    sub_filter = sub_filter != "" ? sub_filter : null;
    sub_filter = sub_filter || activeSubFilters[0];

    let activeTabData = fundSubTypes[sub_filter];

    activeTabData = this.sortDataPerformanceBasedHelper(
      this.state.yearFilter,
      activeTabData
    );

    let newArr = [];

    for (let i = 0; i < activeTabData.length; i += 2) {
      newArr.push(activeTabData.slice(i, i + 2));
    }

    let modifiedActiveSubFilters = [];

    activeSubFilters.forEach((item) => {
      modifiedActiveSubFilters.push(item.replace("_", ""));
    });

    this.setState({
      activeTabData,
      activeSubFilters: modifiedActiveSubFilters,
      activeTabDataWeb: newArr,
      activeFundType: sub_filter,
      yearFilter: 1,
    });
  };

  mapFundSubType = (type) => {
    const map = {
      "Linked Savings Scheme": "ELSS",
    };

    return type in map ? map[type] : type;
  };

  sortDataPerformanceBasedHelper = (filterIndex, data) => {
    let filter;
    switch (filterIndex) {
      case 0:
        filter = "name";
        break;
      case 1:
        filter = "returns_3_mth";
        break;
      case 2:
        filter = "returns_6_mth";
        break;
      case 3:
        filter = "returns_1_yr";
        break;
      case 4:
        filter = "returns_3_yr";
        break;
      case 5:
        filter = "returns_5_yr";
        break;
      case 6:
        filter = "returns_10_yr";
        break;
      case 7:
        filter = "assets_under_management";
        break;
      case 8:
        filter = "riskValue";
        break;
      default:
        filter = "returns_3_mth";
    }

    if (filterIndex == this.state.yearFilter) {
      if (this.state.sortOrder) {
        if (filter == "name") {
          data && data.sort((a, b) => b[filter].localeCompare(a[filter]));
        } else {
          data &&
            data.sort((a, b) => {
              return a[filter] - b[filter];
            });
        }
        this.setState({ sortOrder: false });
      } else {
        if (filter == "name") {
          data && data.sort((a, b) => a[filter].localeCompare(b[filter]));
        } else {
          data &&
            data.sort((a, b) => {
              return b[filter] - a[filter];
            });
        }
        this.setState({ sortOrder: true });
      }
    } else {
      this.setState({ sortOrder: !this.state.sortOrder }, () => {
        if (this.state.sortOrder) {
          if (filter == "name") {
            data && data.sort((a, b) => b[filter].localeCompare(a[filter]));
          } else {
            data &&
              data.sort((a, b) => {
                return a[filter] - b[filter];
              });
          }
          this.setState({ sortOrder: false });
        } else {
          if (filter == "name") {
            data && data.sort((a, b) => a[filter].localeCompare(b[filter]));
          } else {
            data &&
              data.sort((a, b) => {
                return b[filter] - a[filter];
              });
          }
          this.setState({ sortOrder: true });
        }
      });
    }
    return data || [];
  };

  setModalMethod = (method) => {
    this.openOrCloseModal = method;
  };

  getVolatality = (status) => {
    const statuses = {
      LOW: "Low",
      Low: "Low",
      "Moderately Low": "Moderately Low",
      Moderate: "Moderate",
      "Moderate High": "Moderate High",
      High: "High",
      MLW: "Moderately Low",
      MOD: "Moderate",
      MDH: "Moderate High",
      HGH: "High",
      "MODERATELY HIGH": "Moderate High",
      "MODERATELY LOW": "Moderate Low",
      HIGH: "High",
    };
    status = status in statuses ? statuses[status] : "-";

    return status;
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  handleProductInvestContinue = () => {};

  render() {
    const { error, errorText, isLoading, showPopUp, modalData } = this.state;
    let return_percentages = [
      "returns_3_mth",
      "returns_3_mth",
      "returns_6_mth",
      "returns_1_yr",
      "returns_3_yr",
      "returns_5_yr",
      "returns_10_yr",
    ];

    let return_percentage_short_forms = {
      returns_3_mth: "3M",
      returns_3_mth: "3M",
      returns_6_mth: "6M",
      returns_1_yr: "1Y",
      returns_3_yr: "3Y",
      returns_5_yr: "5Y",
      returns_10_yr: "10Y",
    };

    const returns_key =
      return_percentages[this.state.yearFilter] || return_percentages[0];

    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        {/* {this.state.showErrorToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title="Error while loading BSE url for nominee authentication. kindly contact your advisor."
            duration="10"
          />
        ) : null} */}
        <View style={{ flex: 1, maxWidth: 1500 }}>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <ActivityIndicator size="large" color="#410DAA" />
            ) : error ? (
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText || "Something went wrong!"}
              </Text>
            ) : this.state.showLoader ? (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator size={25} color="#410DAA" />
                <Text
                  style={{ fontSize: 16, fontWeight: "bold", marginTop: 10 }}
                >
                  You are being redirected to BSE nominee authentication page
                </Text>
              </View>
            ) : (
              <>
                {/* Transaction status pop-up */}
                {showPopUp && modalData && (
                  <PopUp modal_data={modalData} onPressDone={this.closePopUp} />
                )}
                <ScrollView style={[styles.mainContainer]}>
                  <View
                    style={{
                      paddingBottom: 40,
                      marginTop: isMobile ? -3 : -17,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: 10,
                        marginBottom: 30,
                        marginLeft: isMobile ? 20 : 0,
                        marginRight: isMobile ? 20 : 0,
                      }}
                    >
                      <ScrollView
                        horizontal={true}
                        showsHorizontalScrollIndicator={true}
                        contentContainerStyle={{
                          flexDirection: "row",
                          padding: 10,
                        }}
                      >
                        {this.props.demoMode && (
                          <>
                            <ExploreCardItem
                              active={
                                this.state.activeFilter == "STOCKS_PORTFOLIO"
                              }
                              onPress={() => {
                                this.setState({
                                  activeFilter: "STOCKS_PORTFOLIO",
                                });
                              }}
                              label={"Curated Stock Portfolios"}
                              description={
                                "Best for your long term wealth growth"
                              }
                            />
                            <ExploreCardItem
                              active={
                                this.state.activeFilter == "FUNDS_PORTFOLIO"
                              }
                              onPress={() => {
                                this.setState({
                                  activeFilter: "FUNDS_PORTFOLIO",
                                });
                              }}
                              label={"Curated Fund Portfolios"}
                              description={
                                "Best for your long term wealth growth"
                              }
                            />
                          </>
                        )}
                        {getScreenConfigForCustomer().show_explore_stocks && (
                          <>
                            <TouchableOpacity
                              style={{
                                borderWidth: 1,
                                backgroundColor:
                                  this.state.activeFilter == "DIRECT_EQUITY"
                                    ? "#410DAA1A"
                                    : "white",
                                borderColor: "#410DAA33",
                                borderRadius: 15,
                                paddingHorizontal: 20,
                                paddingVertical: 15,
                                height: 100,
                                marginRight: 16,
                                width: isMobile ? width * 0.55 : "20%",
                              }}
                              onPress={() => {
                                this.setState({
                                  activeFilter: "DIRECT_EQUITY",
                                });
                              }}
                            >
                              <>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/direct-equity.png",
                                    }}
                                    resizeMode="contain"
                                    style={{
                                      height: 28,
                                      width: 28,
                                      alignSelf: "flex-start",
                                      marginRight: 10,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 14,
                                      color: "#000000",
                                      fontWeight: "500",
                                      lineHeight: 20,
                                      marginRight: 10,
                                    }}
                                  >
                                    Direct Equity (Stocks)
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 10,
                                    marginLeft: 38,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontWeight: "500",
                                      fontFamily: "Roboto",
                                      color: "#6C7293",
                                      fontSize: 12,
                                    }}
                                  >
                                    Create wealth with quality stock portfolios
                                  </Text>
                                </View>
                              </>
                            </TouchableOpacity>
                          </>
                        )}
                        {getScreenConfigForCustomer().show_explore_mf && (
                          <>
                            <TouchableOpacity
                              style={{
                                borderWidth: 1,
                                backgroundColor:
                                  this.state.activeFilter == "EQUITY"
                                    ? "#410DAA1A"
                                    : "white",
                                borderColor: "#410DAA33",
                                borderRadius: 15,
                                paddingHorizontal: 20,
                                paddingVertical: 15,
                                height: 100,
                                marginRight: 16,
                                width: isMobile ? width * 0.55 : "20%",
                              }}
                              onPress={() => {
                                this.setState(
                                  {
                                    activeFilter: "EQUITY",
                                    activeFundType: "",
                                    sortOrder: false,
                                  },
                                  () => {
                                    this.setFiltersAndData(
                                      this.state.updatedData
                                    );
                                  }
                                );
                              }}
                            >
                              <>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/equity.png",
                                    }}
                                    resizeMode="contain"
                                    style={{
                                      height: 28,
                                      width: 28,
                                      alignSelf: "flex-start",
                                      marginRight: 10,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 14,
                                      color: "#000000",
                                      fontWeight: "500",
                                      lineHeight: 20,
                                      marginRight: 10,
                                    }}
                                  >
                                    Equity Mutual Funds
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 10,
                                    marginLeft: 38,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontWeight: "500",
                                      fontFamily: "Roboto",
                                      color: "#6C7293",
                                      fontSize: 12,
                                    }}
                                  >
                                    For long term growth of investments
                                  </Text>
                                </View>
                              </>
                            </TouchableOpacity>

                            <TouchableOpacity
                              style={{
                                borderWidth: 1,
                                backgroundColor:
                                  this.state.activeFilter == "DEBT"
                                    ? "#410DAA1A"
                                    : "white",
                                borderColor: "#410DAA33",
                                borderRadius: 15,
                                paddingHorizontal: 20,
                                paddingVertical: 15,
                                height: 100,
                                marginRight: 16,
                                width: isMobile ? width * 0.55 : "20%",
                              }}
                              onPress={() => {
                                this.setState(
                                  {
                                    activeFilter: "DEBT",
                                    activeFundType: "",
                                    sortOrder: false,
                                  },
                                  () => {
                                    this.setFiltersAndData(
                                      this.state.updatedData
                                    );
                                  }
                                );
                              }}
                            >
                              <>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/debt.png",
                                    }}
                                    resizeMode="contain"
                                    style={{
                                      height: 28,
                                      width: 28,
                                      alignSelf: "flex-start",
                                      marginRight: 10,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 14,
                                      color: "#000000",
                                      fontWeight: "500",
                                      lineHeight: 20,
                                      marginRight: 10,
                                    }}
                                  >
                                    Debt Mutual Funds
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 10,
                                    marginLeft: 38,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontWeight: "500",
                                      fontFamily: "Roboto",
                                      color: "#6C7293",
                                      fontSize: 12,
                                    }}
                                  >
                                    Build corpus for short/mid term needs
                                  </Text>
                                </View>
                              </>
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={{
                                borderWidth: 1,
                                backgroundColor:
                                  this.state.activeFilter == "HYBRID"
                                    ? "#410DAA1A"
                                    : "white",
                                borderColor: "#410DAA33",
                                borderRadius: 15,
                                paddingHorizontal: 20,
                                paddingVertical: 15,
                                height: 100,
                                marginRight: 16,
                                width: isMobile ? width * 0.55 : "20%",
                              }}
                              onPress={() => {
                                this.setState(
                                  {
                                    activeFilter: "HYBRID",
                                    activeFundType: "",
                                    sortOrder: false,
                                  },
                                  () => {
                                    this.setFiltersAndData(
                                      this.state.updatedData
                                    );
                                  }
                                );
                              }}
                            >
                              <>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/hybrid.png",
                                    }}
                                    resizeMode="contain"
                                    style={{
                                      height: 28,
                                      width: 28,
                                      alignSelf: "flex-start",
                                      marginRight: 10,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 14,
                                      color: "#000000",
                                      fontWeight: "500",
                                      lineHeight: 20,
                                      marginRight: 10,
                                    }}
                                  >
                                    Hybrid Mutual Funds
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 10,
                                    marginLeft: 38,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontWeight: "500",
                                      fontFamily: "Roboto",
                                      color: "#6C7293",
                                      fontSize: 12,
                                    }}
                                  >
                                    Balance between equity and debt funds
                                  </Text>
                                </View>
                              </>
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={{
                                borderWidth: 1,
                                backgroundColor:
                                  this.state.activeFilter == "GOLD"
                                    ? "#410DAA1A"
                                    : "white",
                                borderColor: "#410DAA33",
                                borderRadius: 15,
                                paddingHorizontal: 20,
                                paddingVertical: 15,
                                height: 100,
                                marginRight: 16,
                                width: isMobile ? width * 0.55 : "20%",
                              }}
                              onPress={() => {
                                this.setState(
                                  {
                                    activeFilter: "GOLD",
                                    activeFundType: "",
                                    sortOrder: false,
                                  },
                                  () => {
                                    this.setFiltersAndData(
                                      this.state.updatedData
                                    );
                                  }
                                );
                              }}
                            >
                              <>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/gold.png",
                                    }}
                                    resizeMode="contain"
                                    style={{
                                      height: 28,
                                      width: 28,
                                      alignSelf: "flex-start",
                                      marginRight: 10,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 14,
                                      color: "#000000",
                                      fontWeight: "500",
                                      lineHeight: 20,
                                      marginRight: 10,
                                    }}
                                  >
                                    Gold Mutual Funds
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 10,
                                    marginLeft: 38,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontWeight: "500",
                                      fontFamily: "Roboto",
                                      color: "#6C7293",
                                      fontSize: 12,
                                    }}
                                  >
                                    Hedge investments against global crisis
                                  </Text>
                                </View>
                              </>
                            </TouchableOpacity>
                          </>
                        )}
                      </ScrollView>
                    </View>
                    <View style={{ zIndex: 10 }}>
                      {this.state.showInvestModal && (
                        <CustomModal
                          visible={this.state.showInvestModal}
                          showCloseButton={true}
                          closeModal={() => {
                            this.setState({ showInvestModal: false });
                          }}
                          style={[
                            {
                              marginTop: 100,
                            },
                          ]}
                        >
                          <ProductInvestNowStrip
                            onContinue={this.handleProductInvestContinue}
                            code={this.state.activeProductInvestCode}
                            productName={this.state.activeProductInvestName}
                            isStocks={this.state.isStocks}
                            pk={this.state.apmPk}
                          />
                        </CustomModal>
                      )}
                    </View>
                    {this.state.activeFilter == "DIRECT_EQUITY" ? (
                      <GrowWealthStrip
                        navigation={this.props.navigation}
                        dontShowFunds={true}
                        style={{
                          paddingHorizontal: 16,
                        }}
                      />
                    ) : (
                      <>
                        {this.state.activeFilter == "STOCKS_PORTFOLIO" ||
                        this.state.activeFilter == "FUNDS_PORTFOLIO" ? (
                          <ExploreStocksPortfolio
                            isStocks={
                              this.state.activeFilter == "STOCKS_PORTFOLIO"
                            }
                            onPressItem={(code, product_name, pk) => {
                              this.setState({
                                showInvestModal: true,
                                activeProductInvestCode: code,
                                activeProductInvestName: product_name,
                                isStocks:
                                  this.state.activeFilter == "STOCKS_PORTFOLIO",
                                apmPk: pk,
                              });
                            }}
                          />
                        ) : (
                          <>
                            <ScrollView
                              style={{
                                marginLeft: isMobile ? 20 : 0,
                                marginRight: isMobile ? 5 : 0,
                              }}
                            >
                              <FlatList
                                data={this.state.activeSubFilters}
                                extraData={this.state.activeFundType}
                                horizontal={true}
                                showsHorizontalScrollIndicator={false}
                                renderItem={({ item }) => {
                                  let backgroundColor;
                                  let color;
                                  if (item == this.state.activeFundType) {
                                    backgroundColor = "#410DAA";
                                    color = "#fff";
                                  } else {
                                    backgroundColor = "#fff";
                                    color = "#121212";
                                  }

                                  return (
                                    <FilterItem
                                      item={item}
                                      backgroundColor={backgroundColor}
                                      color={color}
                                      onPress={() =>
                                        this.setState(
                                          {
                                            activeFundType: item,
                                            sortOrder: false,
                                          },
                                          () => {
                                            this.setFiltersAndData(
                                              this.state.updatedData
                                            );
                                          }
                                        )
                                      }
                                    />
                                  );
                                }}
                                keyExtractor={(item, index) => index}
                              />
                            </ScrollView>
                            {isMobile &&
                              this.state.activeTabData &&
                              this.state.activeTabData.map((item, index) => {
                                return (
                                  <View
                                    key={index}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      marginTop: 17,
                                      width: "90%",
                                      marginLeft: "5%",
                                      marginRight: "5%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        flex: 10,
                                        borderRadius: 10,
                                        borderWidth: 1,
                                        borderColor: "#410DAA1A",
                                      }}
                                    >
                                      <View
                                        style={{
                                          padding: 10,
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <View style={{ flex: 0.05 }}>
                                          <Image
                                            source={{ uri: item.amc_logo }}
                                            resizeMode="contain"
                                            style={{
                                              height: 30,
                                              width: 30,
                                              alignSelf: "flex-start",
                                              borderWidth: 1,
                                              borderColor: "#f2f2f2",
                                              borderRadius: 5,
                                            }}
                                          />
                                        </View>
                                        <View
                                          style={{
                                            flex: isMobile ? 0.05 : 0.01,
                                          }}
                                        ></View>
                                        <View
                                          style={{
                                            flex: 0.84,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 15,
                                              color: "#464E5F",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {item.name}
                                          </Text>
                                        </View>
                                        <View style={{ flex: 0.1 }}></View>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          paddingHorizontal: 10,
                                          paddingBottom: 10,
                                          paddingTop: 6,
                                        }}
                                      >
                                        <View
                                          style={{
                                            marginLeft: isMobile ? 9 : 15,
                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: isMobile ? 9 : 13,
                                              paddingVertical: isMobile ? 5 : 6,
                                              paddingHorizontal: isMobile
                                                ? 14
                                                : 2,
                                              marginLeft: -20,
                                              fontWeight: "bold",
                                              color:
                                                item.day_change > 0
                                                  ? "#009105"
                                                  : "#EF5D12",
                                            }}
                                          >
                                            <AntIcon
                                              name={
                                                item.day_change > 0
                                                  ? "arrowup"
                                                  : "arrowdown"
                                              }
                                              style={{
                                                fontSize: 10,
                                                color:
                                                  item.day_change > 0
                                                    ? "#009105"
                                                    : "#EF5D12",
                                              }}
                                            />
                                            {item.day_change != null &&
                                            item.day_change != "-" &&
                                            !isNaN(item.day_change)
                                              ? parseFloat(
                                                  item.day_change
                                                ).toFixed(2)
                                              : "-"}
                                            <Text
                                              style={{
                                                fontStyle: "italic",
                                                color: "gray",
                                                fontWeight: "normal",
                                                marginLeft: 8,
                                              }}
                                            >
                                              1 day
                                            </Text>
                                          </Text>
                                        </View>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          paddingHorizontal: 10,
                                          paddingBottom: 10,
                                          paddingTop: 6,
                                        }}
                                      >
                                        <FundField
                                          style={{
                                            flex: 0.33,
                                            alignItems: "flex-start",
                                          }}
                                          name={
                                            return_percentage_short_forms[
                                              returns_key
                                            ]
                                              ? `Return (${return_percentage_short_forms[returns_key]})`
                                              : ""
                                          }
                                          colorValue="#410DAA"
                                          value={
                                            item[returns_key] != null &&
                                            item[returns_key] != "-" &&
                                            !isNaN(item[returns_key])
                                              ? parseFloat(
                                                  item[returns_key]
                                                ).toFixed(1) + "%"
                                              : "-"
                                          }
                                        />
                                        <FundField
                                          style={{
                                            flex: 0.33,
                                            alignItems: "center",
                                          }}
                                          name="Fund Size"
                                          value={
                                            item.fund_size != null &&
                                            item.fund_size != "-" &&
                                            !isNaN(item.fund_size)
                                              ? "₹" +
                                                parseFloat(
                                                  item.fund_size
                                                ).toFixed(2) +
                                                " Cr"
                                              : "-"
                                          }
                                        />
                                        <FundField
                                          style={{
                                            flex: 0.34,
                                            alignItems: "flex-end",
                                          }}
                                          name="Volatality"
                                          colorValue="#009105"
                                          value={
                                            this.getVolatality(
                                              item.AMCFund__risk_factor
                                            ) !== "-"
                                              ? this.getVolatality(
                                                  item.AMCFund__risk_factor
                                                )
                                              : item.AMCFund__risk_factor
                                              ? item.AMCFund__risk_factor
                                              : "-"
                                          }
                                        />
                                      </View>
                                      <View
                                        style={{
                                          paddingHorizontal: 10,
                                          paddingVertical: 4,
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            paddingVertical: 9,
                                            justifyContent: "center",
                                          }}
                                        >
                                          <View
                                            style={{
                                              flex: 0.33,
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <TouchableOpacity
                                              style={{
                                                width: 120,
                                                height: 35,
                                                borderRadius: 20,
                                                backgroundColor: "#410DAA1A",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                              onPress={() => {
                                                this.props.navigation.navigate(
                                                  "App",
                                                  {
                                                    screen: "Dashboard",
                                                    params: {
                                                      screen: "Explore",
                                                      params: {
                                                        screen:
                                                          "FundDescription",
                                                        params: {
                                                          fund: item.AMCFund__amcfund_id,
                                                          from: this.state
                                                            .activeFilter,
                                                          fundType:
                                                            this.state
                                                              .activeFundType,
                                                          fund_amc_id:
                                                            item.amcfundscheme_id,
                                                        },
                                                      },
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 12,
                                                  fontWeight: "500",
                                                  color: "#410DAA",
                                                }}
                                              >
                                                Invest Now
                                              </Text>
                                            </TouchableOpacity>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                );
                              })}
                            {isMobile && (
                              <View style={{ marginBottom: 66 }}></View>
                            )}
                            {!isMobile &&
                              this.state.activeTabDataWeb.map((item, index) => {
                                return (
                                  <View
                                    key={index}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      marginTop: 20,
                                    }}
                                  >
                                    <View
                                      style={{
                                        flex: 10,
                                        borderRadius: 10,
                                        borderWidth: 1,
                                        borderColor: "#410DAA1A",
                                      }}
                                    >
                                      <View
                                        style={{
                                          padding: 10,
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <View style={{ flex: 0.05 }}>
                                          <Image
                                            source={{ uri: item[0].amc_logo }}
                                            resizeMode="contain"
                                            style={{
                                              height: 30,
                                              width: 30,
                                              alignSelf: "flex-start",
                                              borderWidth: 1,
                                              borderColor: "#f2f2f2",
                                              borderRadius: 5,
                                            }}
                                          />
                                        </View>
                                        <View
                                          style={{
                                            flex: isMobile ? 0.05 : 0.01,
                                          }}
                                        ></View>
                                        <View
                                          style={{
                                            flex: 0.84,
                                            paddingLeft: 35,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#464E5F",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {item[0].name}
                                          </Text>
                                        </View>
                                        <View style={{ flex: 0.1 }}></View>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          paddingHorizontal: 10,
                                          paddingBottom: 10,
                                          paddingTop: 6,
                                        }}
                                      >
                                        <View
                                          style={{
                                            marginLeft: isMobile ? 9 : 15,
                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: isMobile ? 9 : 13,
                                              paddingVertical: isMobile ? 5 : 6,
                                              paddingHorizontal: isMobile
                                                ? 14
                                                : 2,
                                              marginLeft: -20,
                                              fontWeight: "bold",
                                              color:
                                                item[0].day_change > 0
                                                  ? "#009105"
                                                  : "#EF5D12",
                                            }}
                                          >
                                            <AntIcon
                                              name={
                                                item[0].day_change > 0
                                                  ? "arrowup"
                                                  : "arrowdown"
                                              }
                                              style={{
                                                fontSize: 10,
                                                color:
                                                  item[0].day_change > 0
                                                    ? "#009105"
                                                    : "#EF5D12",
                                              }}
                                            />
                                            {item[0].day_change != null &&
                                            item[0].day_change != "-" &&
                                            !isNaN(item[0].day_change)
                                              ? parseFloat(
                                                  item[0].day_change
                                                ).toFixed(2)
                                              : "-"}
                                            <Text
                                              style={{
                                                fontStyle: "italic",
                                                color: "gray",
                                                fontWeight: "normal",
                                                marginLeft: 8,
                                              }}
                                            >
                                              1 day
                                            </Text>
                                          </Text>
                                        </View>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          paddingHorizontal: 10,
                                          paddingBottom: 10,
                                          paddingTop: 6,
                                        }}
                                      >
                                        <FundField
                                          style={{
                                            flex: 0.33,
                                            alignItems: "flex-start",
                                          }}
                                          name={
                                            return_percentage_short_forms[
                                              returns_key
                                            ]
                                              ? `Return (${return_percentage_short_forms[returns_key]})`
                                              : ""
                                          }
                                          colorValue="#410DAA"
                                          value={
                                            item[0][returns_key] != null &&
                                            item[0][returns_key] != "-" &&
                                            !isNaN(item[0][returns_key])
                                              ? parseFloat(
                                                  item[0][returns_key]
                                                ).toFixed(1) + "%"
                                              : "-"
                                          }
                                        />
                                        <FundField
                                          style={{
                                            flex: 0.33,
                                            alignItems: "center",
                                          }}
                                          name="Fund Size"
                                          value={
                                            item[0].fund_size != null &&
                                            item[0].fund_size != "-" &&
                                            !isNaN(item[0].fund_size)
                                              ? "₹" +
                                                parseFloat(
                                                  item[0].fund_size
                                                ).toFixed(2) +
                                                " Cr"
                                              : "-"
                                          }
                                        />
                                        <FundField
                                          style={{
                                            flex: 0.34,
                                            alignItems: "flex-end",
                                          }}
                                          name="Volatality"
                                          colorValue="#009105"
                                          value={
                                            this.getVolatality(
                                              item[0].AMCFund__risk_factor
                                            ) !== "-"
                                              ? this.getVolatality(
                                                  item[0].AMCFund__risk_factor
                                                )
                                              : item[0].AMCFund__risk_factor
                                              ? item[0].AMCFund__risk_factor
                                              : "-"
                                          }
                                        />
                                      </View>
                                      <View
                                        style={{
                                          paddingHorizontal: 10,
                                          paddingVertical: 4,
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            paddingVertical: 9,
                                            justifyContent: "center",
                                          }}
                                        >
                                          <View
                                            style={{
                                              flex: 0.33,
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <TouchableOpacity
                                              style={{
                                                width: 120,
                                                height: 35,
                                                borderRadius: 20,
                                                backgroundColor: "#410DAA1A",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                              onPress={async () => {
                                                this.props.navigation.replace(
                                                  "App",
                                                  {
                                                    screen: "Dashboard",
                                                    params: {
                                                      screen: "Explore",
                                                      params: {
                                                        screen:
                                                          "FundDescription",
                                                        params: {
                                                          fund: item[0]
                                                            .AMCFund__amcfund_id,
                                                          from: this.state
                                                            .activeFilter,
                                                          fundType:
                                                            this.state
                                                              .activeFundType,
                                                          fund_amc_id:
                                                            item[0]
                                                              .amcfundscheme_id,
                                                        },
                                                      },
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 12,
                                                  fontWeight: "500",
                                                  color: "#410DAA",
                                                }}
                                              >
                                                Invest Now
                                              </Text>
                                            </TouchableOpacity>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                    <View
                                      style={{
                                        flex: 0.5,
                                      }}
                                    ></View>

                                    {item[1] && item[1].amc_logo ? (
                                      <View
                                        style={{
                                          flex: 10,
                                          borderRadius: 10,
                                          borderWidth: 1,
                                          borderColor: "#410DAA1A",
                                        }}
                                      >
                                        <View
                                          style={{
                                            padding: 10,
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <View style={{ flex: 0.05 }}>
                                            <Image
                                              source={{ uri: item[1].amc_logo }}
                                              resizeMode="contain"
                                              style={{
                                                height: 30,
                                                width: 30,
                                                alignSelf: "flex-start",
                                                borderWidth: 1,
                                                borderColor: "#f2f2f2",
                                                borderRadius: 5,
                                              }}
                                            />
                                          </View>
                                          <View
                                            style={{
                                              flex: isMobile ? 0.05 : 0.01,
                                            }}
                                          ></View>
                                          <View
                                            style={{
                                              flex: 0.84,
                                              paddingLeft: 35,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#464E5F",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {item[1].name}
                                            </Text>
                                          </View>
                                          <View style={{ flex: 0.1 }}></View>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            paddingHorizontal: 10,
                                            paddingBottom: 10,
                                            paddingTop: 6,
                                          }}
                                        >
                                          <View
                                            style={{
                                              marginLeft: isMobile ? 9 : 15,
                                              backgroundColor: "transparent",
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: isMobile ? 9 : 13,
                                                paddingVertical: isMobile
                                                  ? 5
                                                  : 6,
                                                paddingHorizontal: isMobile
                                                  ? 14
                                                  : 2,
                                                marginLeft: -20,
                                                fontWeight: "bold",
                                                color:
                                                  item[1].day_change > 0
                                                    ? "#009105"
                                                    : "#EF5D12",
                                              }}
                                            >
                                              <AntIcon
                                                name={
                                                  item[1].day_change > 0
                                                    ? "arrowup"
                                                    : "arrowdown"
                                                }
                                                style={{
                                                  fontSize: 10,
                                                  color:
                                                    item[1].day_change > 0
                                                      ? "#009105"
                                                      : "#EF5D12",
                                                }}
                                              />
                                              {item[1].day_change != null &&
                                              item[1].day_change != "-" &&
                                              !isNaN(item[1].day_change)
                                                ? parseFloat(
                                                    item[1].day_change
                                                  ).toFixed(2)
                                                : "-"}
                                              <Text
                                                style={{
                                                  fontStyle: "italic",
                                                  color: "gray",
                                                  fontWeight: "normal",
                                                  marginLeft: 8,
                                                }}
                                              >
                                                1 day
                                              </Text>
                                            </Text>
                                          </View>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            paddingHorizontal: 10,
                                            paddingBottom: 10,
                                            paddingTop: 6,
                                          }}
                                        >
                                          <FundField
                                            style={{
                                              flex: 0.33,
                                              alignItems: "flex-start",
                                            }}
                                            name={
                                              return_percentage_short_forms[
                                                returns_key
                                              ]
                                                ? `Return (${return_percentage_short_forms[returns_key]})`
                                                : ""
                                            }
                                            colorValue="#410DAA"
                                            value={
                                              item[1][returns_key] != null &&
                                              item[1][returns_key] != "-" &&
                                              !isNaN(item[1][returns_key])
                                                ? parseFloat(
                                                    item[1][returns_key]
                                                  ).toFixed(1) + "%"
                                                : "-"
                                            }
                                          />
                                          <FundField
                                            style={{
                                              flex: 0.33,
                                              alignItems: "center",
                                            }}
                                            name="Fund Size"
                                            value={
                                              item[1].fund_size != null &&
                                              item[1].fund_size != "-" &&
                                              !isNaN(item[1].fund_size)
                                                ? "₹" +
                                                  parseFloat(
                                                    item[1].fund_size
                                                  ).toFixed(2) +
                                                  " Cr"
                                                : "-"
                                            }
                                          />
                                          <FundField
                                            style={{
                                              flex: 0.34,
                                              alignItems: "flex-end",
                                            }}
                                            colorValue="#009105"
                                            name="Volatality"
                                            value={
                                              this.getVolatality(
                                                item[1].AMCFund__risk_factor
                                              ) !== "-"
                                                ? this.getVolatality(
                                                    item[1].AMCFund__risk_factor
                                                  )
                                                : item[1].AMCFund__risk_factor
                                                ? item[1].AMCFund__risk_factor
                                                : "-"
                                            }
                                          />
                                        </View>
                                        <View
                                          style={{
                                            paddingHorizontal: 10,
                                            paddingVertical: 4,
                                          }}
                                        >
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              paddingVertical: 9,
                                              justifyContent: "center",
                                            }}
                                          >
                                            <View
                                              style={{
                                                flex: 0.33,
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <TouchableOpacity
                                                style={{
                                                  width: 120,
                                                  height: 35,
                                                  borderRadius: 20,
                                                  backgroundColor: "#410DAA1A",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                                onPress={async () => {
                                                  this.props.navigation.replace(
                                                    "App",
                                                    {
                                                      screen: "Dashboard",
                                                      params: {
                                                        screen: "Explore",
                                                        params: {
                                                          screen:
                                                            "FundDescription",
                                                          params: {
                                                            fund: item[1]
                                                              .AMCFund__amcfund_id,
                                                            from: this.state
                                                              .activeFilter,
                                                            fundType:
                                                              this.state
                                                                .activeFundType,
                                                            fund_amc_id:
                                                              item[1]
                                                                .amcfundscheme_id,
                                                          },
                                                        },
                                                      },
                                                    }
                                                  );
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: 12,
                                                    fontWeight: "500",
                                                    color: "#410DAA",
                                                  }}
                                                >
                                                  Invest Now
                                                </Text>
                                              </TouchableOpacity>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    ) : (
                                      <View
                                        style={{
                                          flex: 10,
                                        }}
                                      ></View>
                                    )}
                                  </View>
                                );
                              })}
                            {!isMobile && (
                              <View style={{ marginTop: 90 }}></View>
                            )}
                            {isMobile && (
                              <View style={{ marginTop: 35 }}></View>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </View>
                </ScrollView>
                {this.state.activeFilter != "STOCKS_PORTFOLIO" &&
                this.state.activeFilter != "FUNDS_PORTFOLIO" &&
                this.state.activeFilter != "DIRECT_EQUITY" ? (
                  <View
                    style={{
                      position: "absolute",
                      width: "100%",
                      left: 0,
                      right: 0,
                      bottom: width < 1200 ? 66 : 0,
                      shadowOffset: { width: 10, height: 10 },
                      shadowColor: "black",
                      shadowOpacity: 1,
                      elevation: 10,
                      backgroundColor: "#fff",
                      alignItems: "center",
                    }}
                  >
                    {this._renderYearPerformanceFilter()}
                  </View>
                ) : null}
              </>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    paddingBottom: 10,
  },
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: isWeb ? 32 : 0,
    paddingRight: isWeb ? 32 : 0,
    paddingTop: isWeb ? 30 : 10,
    width: "100%",
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
  filterText: {
    fontSize: 15,
    color: "gray",
    fontWeight: "200",
  },
  filtersTitle: {
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 6,
    marginLeft: isMobile ? 16 : 0,
  },
});

const mapStateToProps = (state) => ({
  demoMode: state.testConfig.demoMode,
});

export default connect(mapStateToProps)(MFExplore);
