import React from "react";
import {
  View,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  TouchableOpacity,
  Linking,
  Platform,
} from "react-native";
import { Text, TextInput } from "react-native-paper";
import NavigationHeader from "../../components/Common/NavigationHeader";
import CustButton from "../../components/Common/Button";
import MaterialIcon from "@expo/vector-icons/MaterialIcons";
import Divider from "../../components/Common/divider";
import ANTIcon from "@expo/vector-icons/AntDesign";
import moment from "moment";
import { StackActions } from "@react-navigation/native";
import { ImportedPortfolioStatus } from "../../components/Common/ImportedPortfolioStatus";
import {
  camsPdfUploadUsingEmail,
  updateCamsEmailCustomer,
  updateEmailCustomer,
} from "../../api";
import { getUserDetails } from "../../utils";
import { CustomModal } from "../../components/Common/CustomModal";
import { validateInput } from "../../utils/validateInput";
import { SideHeading } from "../../components/Common/sideHeading";

const EmailInput = ({ email, clearEmail, setEmail }) => {
  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <TextInput
        placeholder="user@gmail.com"
        value={`${email || ""}`}
        onChangeText={(email) => {
          setEmail(email);
        }}
        onSubmitEditing={() => {}}
        style={{
          ...localStyles.inputText,
          paddingLeft: 0,
          marginLeft: 0,
          color: "#121212",
          borderBottomColor: "#A8A8A8",
          borderBottomWidth: 0.5,
          width: "100%",
        }}
      />
      {email != null && email != "" && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingVertical: 5,
          }}
        >
          <ANTIcon
            name="closecircle"
            color={"#EF5D12"}
            size={12}
            style={{}}
            onPress={() => {
              clearEmail();
            }}
          />
        </View>
      )}
    </View>
  );
};

export default class ImportMFPortfolio extends React.Component {
  constructor(props) {
    super(props);
    let email = null;
    let from = null;
    let showHelp = false;
    const customer_email = getUserDetails().email;
    const { params } = this.props.route;
    if (params) {
      email = params.email || null;
      from = params.from || null;
      showHelp = params.showHelp || false;
    }
    email = email || customer_email;

    this.state = {
      data: [],
      isOTPValid: null,
      email: email || "",
      showHelp,
      from,
      showModal: false,
    };
  }

  static navigationOptions = () => ({
    tabBarVisible: false,
  });

  _updateEmail = async () => {
    const { email } = this.state;
    const emails = email.split(",");

    let emailResponseDict = {};

    Promise.all(
      emails.map(async (email) => {
        const resp = await camsPdfUploadUsingEmail({ email });
        emailResponseDict[email] = resp;
        return resp && resp ? resp.code : "101";
      })
    )
      .then((respValues) => {
        if (respValues.indexOf("100") != -1) {
          this.setState({ isLoading: false, showHelp: true });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  done = () => {
    this.setState({ isLoading: true });
    const valid = this._validateEmail(this.state.email);
    if (valid) {
      this.setState(
        {
          emailError: null,
        },
        async () => {
          await this.getImport();
          this._updateEmail();
        }
      );
    } else {
      this.setState({
        isLoading: false,
        emailError: "Please enter valid email",
      });
    }
  };

  _header() {
    return (
      <NavigationHeader
        navigation={this.props.navigation}
        navigationHeader={["Back"]}
        onPressBack={() => {
          this.props.navigation.goBack();
        }}
      />
    );
  }

  _renderItem = ({ item, index }) => {
    const date = moment(item.created).format("DD[-]MMM[-]YYYY h:mm a");
    return (
      <ImportedPortfolioStatus
        file={item.email}
        status={item.cas_status}
        type={date}
        key={index}
      />
    );
  };

  _listHeader = () => {
    return (
      <View style={{ flexDirection: "row", paddingBottom: 5 }}>
        <Text
          style={{
            flex: 0.5,
            textAlign: "left",
            color: "#707070",
            fontSize: 14,
          }}
        >
          Recent Uploads
        </Text>
        <Text
          style={{
            flex: 0.45,
            color: "#707070",
            fontSize: 14,
            textAlign: "right",
          }}
        >
          Status
        </Text>
      </View>
    );
  };

  _validateEmail = () => {
    const { email } = this.state;
    let emails = email.split(",");
    if (emails.length > 1) return false;
    let valid = true;
    emails.map((email) => {
      valid = valid && validateInput("email", email.trim());
    });
    return valid;
  };

  _clearEmail = () => {
    this.setState({ email: "", emailError: "" });
  };

  _setEmail = (email) => {
    this.setState({
      email,
    });
  };

  _renderLine = (from, value) => {
    return (
      <View
        style={{
          flexDirection: "row",
          backgroundColor: "#F3F3F3",
          paddingVertical: 12,
          paddingHorizontal: 8,
        }}
      >
        <Text
          style={{
            flex: 0.1,
            fontSize: 10,
            color: "#707070",
            textAlign: "right",
          }}
        >
          {from}:
        </Text>
        <Text
          style={{
            flex: 0.9,
            fontSize: 10,
            color: "#121212",
            paddingLeft: 10,
            fontWeight: "bold",
          }}
        >
          {value}
        </Text>
      </View>
    );
  };

  _renderPDFLine = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          backgroundColor: "#fff",
          paddingVertical: 12,
          paddingHorizontal: 8,
          alignItems: "center",
        }}
      >
        <View
          style={{
            flex: 0.1,
            alignItems: "flex-end",
          }}
        >
          <MaterialIcon
            name="attach-file"
            color={"#000000"}
            size={12}
            style={{}}
            onPress={() => {}}
          />
        </View>
        <Text
          style={{
            flex: 0.9,
            fontSize: 10,
            color: "#707070",
            paddingLeft: 10,
            fontWeight: "bold",
          }}
        >
          Consolidated Account Statement.pdf
        </Text>
      </View>
    );
  };

  _renderPlaceHolder = () => {
    return (
      <View
        style={{
          flexDirection: "column",
          paddingHorizontal: "10%",
          marginVertical: 30,
        }}
      >
        <>
          <View
            style={{
              width: "70%",
              height: 8,
              backgroundColor: "#F6F6F6",
              marginVertical: 3,
            }}
          ></View>
          <View
            style={{
              width: "45%",
              height: 8,
              backgroundColor: "#F6F6F6",
              marginVertical: 3,
            }}
          ></View>
          <View
            style={{
              width: "65%",
              height: 8,
              backgroundColor: "#F6F6F6",
              marginVertical: 3,
            }}
          ></View>
        </>
      </View>
    );
  };

  _renderImportDone = () => {
    const { email } = this.state;
    const emails = email.split(",");
    const multipleEmails = emails.length > 1;
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          paddingHorizontal: 16,
          paddingTop: 30,
        }}
      >
        <View
          style={{
            marginVertical: 20,
            marginHorizontal: 10,
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: "#707070",
              paddingBottom: 5,
            }}
          >
            In some time you will receive a mail
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "#707070",
            }}
          >
            from CAMS with your CAS statement on your mail ID
            {multipleEmails ? "s" : null}
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "#410DAA",
            }}
          >
            {this.state.email}
          </Text>
        </View>
        <View
          style={{
            width: "84%",
            borderRadius: 10,
            borderWidth: 0.5,
            borderColor: "#F3F3F3",
            margin: 50,
            overflow: "hidden",
          }}
        >
          {this._renderLine("From", "CAMS Mailback Request")}
          <Divider width={0.5} color="#A8A8A8" style={{}} />
          {this._renderLine("To", emails[0])}
          <Divider width={0.5} color="#A8A8A8" style={{}} />
          {this._renderPDFLine()}
          <Divider width={0.5} color="#A8A8A8" style={{}} />
          {this._renderPlaceHolder()}
        </View>
        <Text
          style={{
            fontSize: 12,
            color: "#707070",
            paddingTop: 40,
            paddingBottom: 10,
          }}
        >
          Please forward the E-mail to
        </Text>
        <TouchableOpacity onPress={()=>{
            if(Platform.OS === "web"){
              global.open("mailto:help@maxiom.in")
            }else{
              Linking.openURL("mailto:help@maxiom.in")
            }
          }}>
        <Text
          style={{
            fontSize: 17,
            color: "#121212",
            paddingBottom: 25,
            fontWeight: "bold",
          }}
        >
          help@maxiom.in
        </Text>
        </TouchableOpacity>
      </View>
    );
  };

  getImport() {
    var emailStore = getUserDetails().email;
    if (emailStore == "" || emailStore == null) {
      this.updateemail();
    } else {
      if (this.state.email == emailStore) {
        const payload = {
          email: this.state.email,
        };
        this.props.navigation.dispatch(
          StackActions.replace("WebScreenImport", payload)
        );

        this.setState({
          modalVisible: false,
          showWebView: true,
          isLoading: false,
        });
      } else {
        var CamsemailStore = getUserDetails().cams_mail;
        if (CamsemailStore == this.state.email) {
          const payload = {
            email: this.state.email,
          };
          this.props.navigation.dispatch(
            StackActions.replace("WebScreenImport", payload)
          );
          this.setState({
            modalVisible: false,
            showWebView: true,
            isLoading: false,
          });
        } else {
          this.updateCamsemail();
        }
      }
    }
  }

  updateemail() {
    this.setState({
      loading: true,
    });
    updateEmailCustomer(this.state.email).then((json) => {
      if (json.hasOwnProperty("username")) {
        const payload = {
          email: this.state.email,
        };
        this.props.navigation.dispatch(
          StackActions.replace("WebScreenImport", payload)
        );
        this.setState({
          loading: false,
          modalVisible: false,
          showWebView: true,
          isLoading: false,
        });
      }
    });
  }

  updateCamsemail() {
    this.setState({
      loading: true,
    });
    updateCamsEmailCustomer(this.state.email).then((json) => {
      if (json.hasOwnProperty("username")) {
        const payload = {
          email: this.state.email,
        };
        this.props.navigation.dispatch(
          StackActions.replace("WebScreenImport", payload)
        );
        this.setState({
          loading: false,
          modalVisible: false,
          showWebView: true,
          isLoading: false,
        });
      }
    });
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    const { isLoading, showHelp, email, emailError } = this.state;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {showHelp ? (
          <View style={{ width: "100%", height: "100%" }}>
            {this._renderImportDone()}
            <View
              style={{
                marginHorizontal: 16,
                alignItems: "center",
                position: "absolute",
                bottom: 10,
                left: 0,
                right: 0,
              }}
            >
              <CustButton
                bgColor={"#410DAA"}
                textColor={"#fff"}
                style={{
                  width: "100%",
                  paddingVertical: 15,
                }}
                onPress={() => {
                  this.props.navigation.dispatch(StackActions.replace("Home"));
                }}
                borderColor={"transparent"}
                buttonTextStyle={{
                  fontSize: 14,
                }}
              >
                Done
              </CustButton>
            </View>
          </View>
        ) : (
          <>
            {this._header()}
            <ScrollView
              contentContainerStyle={{ flexGrow: 1, paddingBottom: 100 }}
              showsVerticalScrollIndicator={true}
              keyboardShouldPersistTaps={"handled"}
            >
              <SideHeading
                title="Import Mutual Fund Portfolio"
                headingTitleStyle={{ alignItems: "center", fontSize: 20 }}
                style={{
                  alignItems: "center",
                }}
              />

              <View style={{ width: "100%", paddingBottom: 15 }}>
                <View style={{ paddingHorizontal: 16, paddingVertical: 10 }}>
                  <Text style={localStyles.inputLabel}>
                    Enter email id used for any mutual fund investment.
                  </Text>
                  <View style={{ flexDirection: "column" }}>
                    <EmailInput
                      email={email}
                      setEmail={this._setEmail}
                      clearEmail={this._clearEmail}
                    />
                  </View>
                  <Text
                    style={{
                      ...localStyles.errorText,
                      paddingVertical: 0,
                      marginVertical: 0,
                      paddingLeft: 2,
                    }}
                  >
                    {emailError || ""}
                  </Text>
                </View>

                <View
                  style={{
                    paddingHorizontal: 16,
                    width: "100%",
                  }}
                >
                  <CustButton
                    bgColor={"#410DAA"}
                    textColor={"#fff"}
                    style={{
                      marginVertical: 5,
                      width: "100%",
                      paddingVertical: 15,
                    }}
                    onPress={() => {
                      this.setState({ isLoading: true });
                      this.done();
                    }}
                    isLoading={isLoading}
                    borderColor={"transparent"}
                    buttonTextStyle={{
                      fontSize: 14,
                    }}
                  >
                    Request Import
                  </CustButton>
                </View>

                <View
                  style={{
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      this.setState({ dialogVisible: true, showModal: true });
                    }}
                  >
                    <Text style={{ color: "#121212", fontSize: 14 }}>
                      How it works ?
                    </Text>
                    <ANTIcon
                      name="infocirlce"
                      color={"#a8a8a8"}
                      size={12}
                      style={{
                        paddingLeft: 10,
                      }}
                      onPress={() => {
                        this.setState({ showModal: true });
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  height: 30,
                  backgroundColor: "#F6F6F6",
                }}
              ></View>
              <View style={{ paddingVertical: 5, paddingHorizontal: 16 }}>
                <Text
                  style={{
                    fontSize: 15,
                    color: "#121212",
                    fontWeight: "bold",
                    paddingBottom: 10,
                  }}
                >
                  Why do this?
                </Text>
                <Text
                  style={{ color: "#465964", paddingBottom: 5, fontSize: 13 }}
                >
                  <Text style={{ fontWeight: "bold", fontSize: 13 }}>1. </Text>
                  Track all mutual funds in one place. No matter which app you
                  invested with.
                </Text>
                <Text style={{ color: "#465964", fontSize: 13 }}>
                  <Text style={{ fontWeight: "bold", fontSize: 13 }}>
                    2. Get Expert Advice:
                  </Text>{" "}
                  Equity fund risk, Asset allocation, Credit risk on debt funds,
                  Concentration risk, Tax efficiency, Peer ranking and many more
                </Text>
              </View>
            </ScrollView>
          </>
        )}
        <CustomModal
          visible={this.state.showModal}
          showCloseButton={true}
          closeModal={() => {
            this.toggleModal();
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: "bold",
              textAlign: "left",
              marginBottom: 5,
              color: "#121212",
            }}
          >
            How it works
          </Text>
          <Text style={{ fontSize: 13, color: "#121212", marginVertical: 5 }}>
            1. We can't access your CAS. We can only request it to be sent to
            your email id seemly.
          </Text>
          <Text style={{ fontSize: 13, color: "#121212" }}>
            2. Once you get the CAS, please forward the mail to{" "}
            <TouchableOpacity onPress={()=>{
            if(Platform.OS === "web"){
              global.open("mailto:help@maxiom.in")
            }else{
              Linking.openURL("mailto:help@maxiom.in")
            }
          }}>
            <Text style={{ fontSize: 13, color: "#410DAA" }}>
              help@maxiom.in
            </Text>
            </TouchableOpacity>{" "}
            and we will import it.
          </Text>
          <Text style={{ fontSize: 13, color: "#121212", marginVertical: 5 }}>
            3. Track all funds & Get expert advice.
          </Text>
        </CustomModal>
      </SafeAreaView>
    );
  }
}

const localStyles = StyleSheet.create({
  form: {},
  errorText: {
    color: "red",
    fontSize: 10,
    height: 16,
  },
  formItem: {
    padding: 0,
    margin: 0,
  },
  inputContainer: {
    width: "100%",
    flexDirection: "column",
  },
  inputText: {
    color: "#EF5D12",
    padding: 0,
    margin: 0,
    fontSize: 16,
    width: "100%",
    height: 25,
  },
  inputLabel: {
    color: "#BCBCBC",
    fontSize: 13,
    marginVertical: 0,
    paddingVertical: 0,
  },
  underline: {
    borderBottomWidth: 0.5,
    borderBottomColor: "#410DAA",
  },
  borderStyleBase: {
    width: 30,
    height: 45,
  },
  borderStyleHighLighted: {
    borderColor: "#410DAA",
  },
  underlineStyleBase: {
    width: 30,
    height: 45,
    borderWidth: 0,
    borderBottomWidth: 1,
    fontSize: 15,
    marginHorizontal: 0,
    paddingHorizontal: 0,
  },
  notCompleted: {
    color: "#707070",
    borderColor: "#707070",
  },
  validCode: {
    color: "#009105",
    borderColor: "#009105",
  },
  inValidCode: {
    color: "#EF5D12",
    borderColor: "#EF5D12",
  },
  underlineStyleHighLighted: {
    borderColor: "#410DAA",
  },
});
