import React, { Component } from "react";
import {
  View,
  FlatList,
  Alert,
  Platform,
  SafeAreaView,
  ScrollView,
} from "react-native";
import { getFamilyApprovals } from "../../../api/alerts";
import FamilyPendingCard from "./FamilyPendingCard";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { isMobile } from "../../../utils";
import amplitude from "../../../utils/amplitude";

class FamilyPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      family: [],
      loading: false,
    };
  }
  componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("FamilyPending", { path: global.location.href });
    } else {
      amplitude.logEvent("FamilyPending", {
        path: this.props.navigation.getState().routeName,
      });
    }
    this._familyPending();
  }

  _familyPending = () => {
    try {
      this.setState({ loading: true });
      getFamilyApprovals().then((response) => {
        if (
          response &&
          response.code &&
          response.code == 100 &&
          response.results
        ) {
          this.setState({
            family: response.results,
            loading: false,
          });
        }
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  handleBackButton() {
    const that = this;
    Alert.alert(
      "Going back",
      "Are you sure you want to go back",
      [
        {
          text: "Yes, Go back and cancel the setup",
          onPress: () => that.props.navigation.goBack(),
        },
        {
          text: "Dont Cancel",
          onPress: () => {},
          style: "cancel",
        },
      ],
      { cancelable: false }
    );
  }

  _renderView = (items) => {
    return <FamilyPendingCard data={items.item} />;
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Family Request"]}
          // onPressBack={() => this.props.navigation.goBack(null)}
          onPressBack={() => {
            // this.props.navigation.navigate("Notifications");
            this.props.navigation.navigate("Portfolio", {
              screen: "Notifications",
              params: null,
            });
          }}
        />
        <ScrollView
          style={{
            paddingLeft: isMobile ? "5%" : "25%",
            paddingRight: isMobile ? "5%" : "25%",
            marginTop: 20,
          }}
        >
          {this.state.loading ? (
            <View style={{ padding: 16 }}></View>
          ) : (
            <View
              style={{
                paddingBottom: 200,
              }}
            >
              <FlatList
                data={this.state.family}
                renderItem={this._renderView}
                keyExtractor={(item, index) => index.toString()}
              />
            </View>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

export default FamilyPending;
