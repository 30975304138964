import React from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity } from "react-native";
import { isMobile } from "../../../../utils";
import BulletPoint from "./BulletPoint";

const InvestmentSchedule = ({
  data = {},
  style = [],
  is_ria = false,
  onClick,
}) => {
  const { title, summary = [], next_up, extra } = data;
  const handleClick = () => {
    onClick("UpcomingInvestments");
  };
  const ArrowIcon = require("../../../../assets/JamaInvest/Arrow-right2x.png");
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        width: isMobile ? "85%" : "32.8%",
      }}
    >
      <TouchableOpacity
        style={[strip.border, strip.container, ...style]}
        onPress={handleClick}
      >
        <>
          <View style={strip.titleContainer}>
            <Text style={strip.title}>
              {isMobile ? "Investment Schedule" : title}
            </Text>
            {true && (
              <Image
                source={ArrowIcon}
                style={[
                  { width: !isMobile ? 18 : 16, height: !isMobile ? 18 : 16 },
                ]}
              ></Image>
            )}
          </View>
          {summary.map((point) => {
            return (
              <View style={strip.pointsContainer}>
                <View style={strip.point}>
                  <BulletPoint
                    outerColor="#888C9F60"
                    innerColor="#888C9F"
                    style={[strip.bulletPoint]}
                  />
                  <Text style={strip.pointText}>{point}</Text>
                </View>
              </View>
            );
          })}
          {next_up && (
            <View style={strip.nextUpContainer}>
              <BulletPoint
                outerColor="#888C9F60"
                innerColor="#888C9F"
                style={[strip.bulletPoint]}
              />
              <View>
                <Text style={[strip.pointText, strip.nextUpTitle]}>
                  Next Up:
                  <Text style={strip.nextUp}> {next_up}</Text>
                </Text>
              </View>
            </View>
          )}
          {extra != "" && <Text style={strip.extra}>{extra}</Text>}
        </>
      </TouchableOpacity>
      {is_ria ? null : (
        <TouchableOpacity
          style={{
            width: isMobile ? "88%" : "91%",
            marginTop: 10,
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          onPress={() => {
            let newTab = window.open();
            newTab.location.href =
              "https://maxiomwealth.com/blog/how-to-invest-in-jamawealth-portfolio/";
          }}
        >
          <Image
            source={require("../../../../assets/how_to.png")}
            resizeMode="contain"
            style={{
              height: 18,
              width: 18,
              marginRight: 8,
            }}
          />
          <Text
            style={{
              fontSize: 14,
              color: "#410DAA",
              fontWeight: "700",
            }}
          >
            How to Invest
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const strip = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    height: 120,
    marginRight: 28,
  },
  border: {
    borderWidth: 1,
    borderColor: "#410DAA33",
    borderRadius: 15,
  },
  image: {
    width: 40,
    height: 40,
    position: "absolute",
    right: 25,
    top: 40,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: isMobile ? 14 : 17,
    color: "#494B69",
    fontWeight: "bold",
    lineHeight: 20,
    marginRight: 10,
  },
  pointsContainer: {
    marginTop: 10,
  },
  point: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  pointText: {
    fontWeight: "bold",
    fontFamily: "Roboto",
    color: "#494B69",
    fontSize: 12,
  },
  bulletPoint: {
    marginRight: 7,
  },
  nextUpContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  nextUpTitle: {
    fontStyle: "italic",
  },
  nextUp: {
    fontFamily: "Roboto",
    fontSize: 12,
    color: "#494B69",
    fontWeight: "normal",
    marginTop: 5,
  },
  extra: {
    fontFamily: "Roboto",
    fontSize: 12,
    color: "#494B69",
    fontStyle: "italic",
    marginLeft: 6,
    marginTop: 7,
  },
});

export default InvestmentSchedule;
