import { StackActions } from "@react-navigation/routers";
import { Platform } from "react-native";
import { getHashKey } from "../api";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../api/apiConfig";
import { MAIN_URL } from "../api/urls";
import { reverseString } from "./index";

const handleTopup = async (options, navigation) => {
  try {
    const { amount = null, product = null } = options;
    if (amount && product) {
      const payload = {
        cipamountstr: "topup_" + amount,
        product,
      };
      if (Platform.OS == "android" || Platform.OS == "ios") {
        payload["mobile"] = true;
      }

      const response = await getHashKey(payload);
      if (response.code == 100) {
        if (Platform.OS == "web") {
          let url = MAIN_URL + "/app/gwsc/placeorder/";
          const { hash_key } = response.result;
          let token = getTokenFromStore();
          if (hash_key && token) {
            token = reverseString(token);
            url =
              url +
              encodeURIComponent(hash_key + token + "/" + GATEWAY_ENVIRONMENT);
            global.open(url, "_self");
          }
        } else {
          navigation.dispatch(
            StackActions.replace("App", {
              screen: "Dashboard",
              params: {
                screen: "Dashboard",
                params: {
                  screen: "Gateway",
                  params: {
                    type: "PLACE_ORDER_TOPUP",
                    data: response.result,
                    screen: "TransactionStatus",
                  },
                },
              },
            })
          );
        }
      }
    }
  } catch (error) {}
};

export default handleTopup;
