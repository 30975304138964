import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Image,
  Text,
  Platform,
  FlatList,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer";
import { DrawerActions } from "@react-navigation/native";
import {
  capitalizeFirstLetter,
  getScreenConfigForCustomer,
  getUserDetails,
  isJama,
  isMobile,
  reverseString,
} from "../../utils";
import { StackActions } from "@react-navigation/routers";
import Store from "../../store";
import AddPanCardNav from "../../screens/mutualfunds/account/sidenav/account";
import Fontisto from "@expo/vector-icons/Fontisto";
import { getMFData } from "../../api";
import * as RootNavigation from "../../../RootNavigation.js";
const { width, height } = Dimensions.get("window");
const hostname = window.location.hostname;
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import Toast from "react-native-toast-message";

export const DrawerContent = (props) => {
  const [userDetails, setUserDetails] = useState({});
  const [familyList, setFamilyList] = useState([]);
  const [routeValue, setRouteValue] = useState("Home");
  let activeTab = props.state.index || 0;
  const { bannerData, is_ria, is_channel_partner, is_superuser } =
    getUserDetails();
  let is_preferred_circle = false;
  let is_club = false;
  let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
  if (hostname.includes("myadvisor")) {
    is_preferred_circle = true;
  }
  if (hostname.includes("club")) {
    is_club = true;
  }
  let cPartnerBool = false;
  if (hostname.includes("cpartner")) {
    cPartnerBool = true;
  }
  const [showCPartnerDrawer, setShowCPartnerDrawer] = useState(cPartnerBool);
  let currentRoute = RootNavigation.getCurrentRoute();
  // console.log(currentRoute);
  if (routeValue != currentRoute) {
    if (routeValue && currentRoute) {
      if (routeValue == "WhenRetire" || currentRoute == "WhenRetire") {
      } else {
        Toast.hide();
      }
      setRouteValue(currentRoute);
    }
  }

  if (
    !isMobile &&
    (currentRoute == "Explore" ||
      currentRoute == "MFExplore" ||
      currentRoute == "StartSIP" ||
      currentRoute == "ShowBankAccount" ||
      currentRoute == "InvestLumpsum" ||
      currentRoute == "FundDescription")
  ) {
    activeTab = 3;
  }
  // if (
  //   !isMobile &&
  //   (
  //     currentRoute == "MFExplore")
  // ) {
  //   activeTab = 4;
  // }

  if (
    !isMobile &&
    (currentRoute == "AddNewAsset" ||
      currentRoute == "InvestmentOverview" ||
      currentRoute == "AddNewAsset")
  ) {
    activeTab = 1;
  }

  if (
    (!isMobile && currentRoute == "Home") ||
    currentRoute == "FinAwareness" ||
    currentRoute == "FinWellness" ||
    currentRoute == "EmWellness" ||
    currentRoute == "EmotionalWellness" ||
    currentRoute == "QuizDiag" ||
    currentRoute == "Quizz" ||
    currentRoute == "QuizResult" ||
    currentRoute == "ContractNote" ||
    currentRoute == "ContractNoteAdmin" ||
    currentRoute == "ContrcatTransactionDetails" ||
    currentRoute == "EduHome" ||
    currentRoute == "ImportStocks" ||
    currentRoute == "ImportStocksAdmin" ||
    currentRoute == "ImportMF" ||
    currentRoute == "ImportMFAdmin"
  ) {
    activeTab = 0;
  }

  if (
    (!isMobile && currentRoute == "CountTrackerHome") ||
    currentRoute == "InvestForGoals"
  ) {
    activeTab = 2;
  }

  if (
    (!isMobile && currentRoute == "BasicDetails") ||
    currentRoute == "CreateFinplan" ||
    currentRoute == "Goals" ||
    currentRoute == "IncomeDetails" ||
    currentRoute == "WhenRetire" ||
    currentRoute == "MaritalStatus"
  ) {
    activeTab = 2;
  }

  if (
    currentRoute == "CorporateDashboard" ||
    currentRoute == "FinancialWellnessIndex" ||
    currentRoute == "EducationActivity" ||
    currentRoute == "FinancialPlanHealth"
  ) {
    activeTab = 6;
  }

  if (showCPartnerDrawer) {
    if (currentRoute == "CorporateDashboard") {
      activeTab = 0;
    }
    if (currentRoute == "FinancialWellnessIndex") {
      activeTab = 1;
    }
    if (currentRoute == "FinancialPlanHealth") {
      activeTab = 2;
    }
    if (currentRoute == "EducationActivity") {
      activeTab = 3;
    }
    if (global.location.search.includes("viewTeam")) {
      activeTab = 4;
    }
    if (currentRoute == "LeaderBoard") {
      activeTab = 7;
    }
  }

  if (!isMobile && currentRoute == "InsightsHome") {
    activeTab = 5;
  }

  const handleClick = (screen, subscreen, viewTeam) => {
    if (viewTeam) {
      props.navigation.push("App", {
        screen,
        params: {
          screen: subscreen,
          params: {
            viewTeam: true,
          },
        },
      });
    } else {
      props.navigation.push("App", {
        screen,
        params: {
          screen: subscreen,
          params: {},
        },
      });
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const { userData } = Store.getState();
        const {
          first_name,
          email,
          mobilenumber,
          country_mobile_code,
          is_corporate_admin,
        } = userData.userProfileData;
        let names = first_name ? first_name.split(/\s+/) : [];
        let name = "",
          initials = null;
        if (names.length >= 2 && names.length > 0) {
          name =
            capitalizeFirstLetter(names[0]) +
            " " +
            capitalizeFirstLetter(names[1]);
          initials = names[0][0].toUpperCase() + "" + names[1][0].toUpperCase();
        } else {
          if (names.length == 0) {
            name = "User";
          } else {
            name = names[0];
            initials = names[0][0].toUpperCase() + names[0][1].toUpperCase();
          }
        }

        setUserDetails({
          name: name,
          initials: initials,
          mobilenumber,
          email,
          country_mobile_code,
          is_corporate_admin,
        });

        if (isMobile) {
          let responseMF = await getMFData();
          setFamilyList(
            responseMF && responseMF.result && responseMF.result.children
              ? responseMF.result.children
              : []
          );
        } else {
          let arrValue = [{ name }];
          getUserDetails().family_list.forEach((item) => {
            let tempName = item.split(" ");
            arrValue.push({ name: tempName[0] });
          });
          setFamilyList(arrValue);
        }
      } catch (error) {}
    };
    loadData();
  }, []);

  let server_name = hostname.split(".")[0];

  return (
    <DrawerContentScrollView {...props}>
      {width < 1200 ? (
        <View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              {userDetails.initials ? (
                <View
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginTop: 25,
                    marginBottom: 35,
                    marginLeft: 10,
                  }}
                >
                  <View
                    style={{
                      backgroundColor: "#ffffff",
                      alignItems: "center",
                      justifyContent: "center",
                      borderWidth: isMobile ? 1 : 1,
                      borderColor: "#410DAA",
                      width: 70,
                      height: 70,
                      borderRadius: 50,
                    }}
                  >
                    <Text
                      style={{
                        color: "#410DAA",
                        fontSize: isMobile ? 17 : 20,
                        lineHeight: 23,
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      {userDetails.initials}
                    </Text>
                  </View>
                </View>
              ) : null}
            </View>
            <View style={{ flex: 3, marginLeft: 10, marginTop: 22 }}>
              <Text
                style={{
                  color: "#121212",
                  fontSize: isMobile ? 14 : 20,
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                {userDetails.name}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                <Image
                  source={{
                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/mail.png",
                  }}
                  style={{
                    width: 18,
                    height: 18,
                    resizeMode: "contain",
                  }}
                />
                <Text style={styles.ph_num}> {userDetails.email}</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 5,
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                <Image
                  source={{
                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/call.png",
                  }}
                  style={{
                    width: 18,
                    height: 18,
                    resizeMode: "contain",
                  }}
                />
                <Text style={styles.ph_num}>
                  {" "}
                  {userDetails.country_mobile_code} {userDetails.mobilenumber}
                </Text>
              </View>
            </View>
            <Fontisto
              name="close-a"
              size={15}
              onPress={() => {
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
              style={{ margin: 15 }}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: 19,
              marginTop: -15,
              marginBottom: -20,
              backgroundColor: "#ece7f7",
            }}
          >
            <View style={{ flex: 1, justifyContent: "flex-start" }}>
              <FlatList
                horizontal={true}
                keyExtractor={(item, index) => index.toString()}
                showsHorizontalScrollIndicator={false}
                data={[...familyList]}
                contentContainerStyle={{
                  alignItems: "flex-start",
                  paddingBottom: 15,
                  paddingLeft: 12,
                  paddingRight: 12,
                  marginBottom: 20,
                }}
                renderItem={({ item, index }) => {
                  if (item["name"] == "self") {
                    item["name"] = userDetails.name;
                  }

                  let names = item["name"] ? item["name"].split(/\s+/) : [];
                  let initials = null;
                  if (names.length >= 2 && names.length > 0) {
                    initials =
                      names[0][0].toUpperCase() +
                      "" +
                      names[1][0].toUpperCase();
                  } else {
                    if (names.length == 0) {
                    } else {
                      initials =
                        names[0][0].toUpperCase() + names[0][1].toUpperCase();
                    }
                  }

                  if (index < familyList.length && item["name"] != "all") {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          props.navigation.dispatch(
                            StackActions.replace("App", {
                              screen: "Dashboard",
                              params: {
                                screen: "Portfolio",
                                params: {
                                  screen: "InvestmentOverview",
                                  params: {
                                    user_id: item.pk,
                                    user_name: item.name,
                                  },
                                },
                              },
                            })
                          );
                        }}
                        style={{ marginLeft: -5 }}
                      >
                        <View
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 25,
                            justifyContent: "center",
                            alignItems: "center",
                            borderColor: "#410DAA",
                            borderWidth: 1.5,
                            backgroundColor: "white",
                            marginHorizontal: 10,
                          }}
                        >
                          <View
                            style={{
                              borderColor: "transparent",
                              borderWidth: 2,
                              borderRadius: 30,
                              width: 45,
                              height: 45,
                              justifyContent: "center",
                              overflow: "hidden",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                color: "#410DAA",
                                fontSize: isMobile ? 13 : 20,
                                lineHeight: 23,
                                fontFamily: "Roboto",
                                fontWeight: "bold",
                                alignSelf: "center",
                              }}
                            >
                              {initials}
                            </Text>
                          </View>
                        </View>
                        <Text
                          style={{
                            textAlign: "center",
                            fontSize: 13,
                            color: "#121212",
                            paddingTop: 5,
                            fontWeight: "500",
                          }}
                        >
                          {item["name"]
                            ? capitalizeFirstLetter(
                                item["name"].substring(0, 10)
                              )
                            : ""}
                        </Text>
                      </TouchableOpacity>
                    );
                  }
                }}
              />
            </View>
          </View>
          <AddPanCardNav {...props} />
        </View>
      ) : (
        <View style={{ height: height * 0.9 }}>
          <View style={styles.logoIconContainer}>
            <Image
              source={{
                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Maxiom+Wealth+Logo.png",
              }}
              style={styles.logoIcon}
            ></Image>
          </View>
          {!showCPartnerDrawer ? (
            <View style={styles.drawerItemContainer}>
              {
                <DrawerItem
                  label="Dashboard"
                  icon={() => (
                    <Image
                      source={{
                        uri:
                          activeTab == 0
                            ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/home_active.png"
                            : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/home.png",
                      }}
                      style={{
                        width: 20,
                        height: 20,
                        resizeMode: "contain",
                        color: activeTab == 0 ? "#410DAA" : "#929292",
                      }}
                    />
                  )}
                  onPress={() => {
                    handleClick("Dashboard", "Home");
                  }}
                  labelStyle={{
                    color: activeTab == 0 ? "#410DAA" : "#929292",
                    fontFamily: "Roboto",
                    fontWeight: activeTab == 0 ? "700" : "400",
                  }}
                  style={[styles.border, activeTab == 0 && styles.activeBorder]}
                />
              }
              {
                <DrawerItem
                  label="Portfolio"
                  icon={() => (
                    <Image
                      source={{
                        uri:
                          activeTab == 1
                            ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolio_active.png"
                            : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolio.png",
                      }}
                      style={{
                        width: 20,
                        height: 20,
                        resizeMode: "contain",
                        color: activeTab == 1 ? "#410DAA" : "#929292",
                      }}
                    />
                  )}
                  onPress={() => {
                    handleClick("Portfolio", "InvestmentOverview");
                  }}
                  labelStyle={{
                    color: activeTab == 1 ? "#410DAA" : "#929292",
                    fontFamily: "Roboto",
                    fontWeight: activeTab == 1 ? "700" : "400",
                  }}
                  style={[styles.border, activeTab == 1 && styles.activeBorder]}
                />
              }
              {getScreenConfigForCustomer().show_goals_fin_plan && (
                <DrawerItem
                  label="Goals"
                  icon={() => (
                    <Image
                      source={{
                        uri:
                          activeTab == 2
                            ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/goals_active.png"
                            : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/goals.png",
                      }}
                      style={{
                        width: 20,
                        height: 20,
                        resizeMode: "contain",
                        color: activeTab == 2 ? "#410DAA" : "#929292",
                      }}
                    />
                  )}
                  onPress={() => {
                    handleClick("Goals", "Goals");
                  }}
                  labelStyle={{
                    color: activeTab == 2 ? "#410DAA" : "#929292",
                    fontFamily: "Roboto",
                    fontWeight: activeTab == 2 ? "700" : "400",
                  }}
                  style={[styles.border, activeTab == 2 && styles.activeBorder]}
                />
              )}

              {getScreenConfigForCustomer().show_explore_mf &&
                hostname !== "docker.jamawealth.com" &&
                hostname !== "myadvisor.jamawealth.com" && (
                  <DrawerItem
                    label="Explore"
                    icon={() => (
                      <Image
                        source={{
                          uri:
                            activeTab == 3
                              ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/explore_active.png"
                              : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/explore.png",
                        }}
                        style={{
                          width: 20,
                          height: 20,
                          resizeMode: "contain",
                          color: activeTab == 3 ? "#410DAA" : "#929292",
                        }}
                      />
                    )}
                    onPress={() => {
                      handleClick("Explore", "MFExplore");
                    }}
                    labelStyle={{
                      color: activeTab == 3 ? "#410DAA" : "#929292",
                      fontFamily: "Roboto",
                      fontWeight: activeTab == 3 ? "700" : "400",
                    }}
                    style={[
                      styles.border,
                      activeTab == 3 && styles.activeBorder,
                    ]}
                  />
                )}
              <DrawerItem
                label="Plans"
                icon={() => (
                  <Image
                    source={{
                      uri:
                        activeTab == 4
                          ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/explore_active.png"
                          : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/explore.png",
                    }}
                    style={{
                      width: 20,
                      height: 20,
                      resizeMode: "contain",
                      color: activeTab == 4 ? "#410DAA" : "#929292",
                    }}
                  />
                )}
                onPress={() => {
                  handleClick("Plans", "PlansHome");
                }}
                labelStyle={{
                  color: activeTab == 4 ? "#410DAA" : "#929292",
                  fontFamily: "Roboto",
                  fontWeight: activeTab == 4 ? "700" : "400",
                }}
                style={[styles.border, activeTab == 4 && styles.activeBorder]}
              />
              <View
                style={{
                  height: 1,
                  width: "100%",
                  backgroundColor: "#92929250",
                }}
              />
              {is_ria ? null : (
                <DrawerItem
                  label="Insights"
                  icon={() => (
                    <MaterialIcons
                      name="lightbulb-outline"
                      size={20}
                      color={activeTab == 5 ? "#410DAA" : "#c1cfe0"}
                    />
                  )}
                  onPress={() => {
                    handleClick("Plans", "InsightsHome");
                  }}
                  labelStyle={{
                    color: activeTab == 5 ? "#410DAA" : "#929292",
                    fontFamily: "Roboto",
                    fontWeight: activeTab == 5 ? "700" : "400",
                  }}
                  style={[styles.border, activeTab == 5 && styles.activeBorder]}
                />
              )}
              {!isMobile &&
                userDetails.is_corporate_admin &&
                !(is_preferred_circle || is_club) && (
                  <DrawerItem
                    label="Corporate Wellness"
                    icon={() => (
                      <MaterialIcons
                        name="corporate-fare"
                        size={20}
                        color={activeTab == 6 ? "#410DAA" : "#c1cfe0"}
                      />
                    )}
                    onPress={() => {
                      handleClick("ViewMode", "CorporateDashboard");
                    }}
                    labelStyle={{
                      color: activeTab == 6 ? "#410DAA" : "#929292",
                      fontFamily: "Roboto",
                      fontWeight: activeTab == 6 ? "700" : "400",
                    }}
                    style={[
                      styles.border,
                      activeTab == 6 && styles.activeBorder,
                    ]}
                  />
                )}
            </View>
          ) : (
            <View style={styles.drawerItemContainer}>
              <DrawerItem
                label="Dashboard"
                icon={() => (
                  <Image
                    source={{
                      uri:
                        activeTab == 0
                          ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/home_active.png"
                          : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/home.png",
                    }}
                    style={{
                      width: 20,
                      height: 20,
                      resizeMode: "contain",
                      color: activeTab == 0 ? "#410DAA" : "#929292",
                    }}
                  />
                )}
                onPress={() => {
                  handleClick("ViewMode", "CorporateDashboard");
                }}
                labelStyle={{
                  color: activeTab == 0 ? "#410DAA" : "#929292",
                  fontFamily: "Roboto",
                  fontWeight: activeTab == 0 ? "700" : "400",
                }}
                style={[styles.border, activeTab == 0 && styles.activeBorder]}
              />
              <DrawerItem
                label="Financial Wellness"
                icon={() => (
                  <Image
                    source={{
                      uri:
                        activeTab == 1
                          ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolio_active.png"
                          : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolio.png",
                    }}
                    style={{
                      width: 20,
                      height: 20,
                      resizeMode: "contain",
                      color: activeTab == 1 ? "#410DAA" : "#929292",
                    }}
                  />
                )}
                onPress={() => {
                  handleClick("ViewMode", "FinancialWellnessIndex");
                }}
                labelStyle={{
                  color: activeTab == 1 ? "#410DAA" : "#929292",
                  fontFamily: "Roboto",
                  fontWeight: activeTab == 1 ? "700" : "400",
                }}
                style={[styles.border, activeTab == 1 && styles.activeBorder]}
              />
              <DrawerItem
                label="Financial Plan Health"
                icon={() => (
                  <Image
                    source={{
                      uri:
                        activeTab == 2
                          ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/goals_active.png"
                          : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/goals.png",
                    }}
                    style={{
                      width: 20,
                      height: 20,
                      resizeMode: "contain",
                      color: activeTab == 2 ? "#410DAA" : "#929292",
                    }}
                  />
                )}
                onPress={() => {
                  handleClick("ViewMode", "FinancialPlanHealth");
                }}
                labelStyle={{
                  color: activeTab == 2 ? "#410DAA" : "#929292",
                  fontFamily: "Roboto",
                  fontWeight: activeTab == 2 ? "700" : "400",
                }}
                style={[styles.border, activeTab == 2 && styles.activeBorder]}
              />
              <DrawerItem
                label="Financial Learning"
                icon={() => (
                  <Image
                    source={{
                      uri:
                        activeTab == 3
                          ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/explore_active.png"
                          : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/explore.png",
                    }}
                    style={{
                      width: 20,
                      height: 20,
                      resizeMode: "contain",
                      color: activeTab == 3 ? "#410DAA" : "#929292",
                    }}
                  />
                )}
                onPress={() => {
                  handleClick("ViewMode", "EducationActivity");
                }}
                labelStyle={{
                  color: activeTab == 3 ? "#410DAA" : "#929292",
                  fontFamily: "Roboto",
                  fontWeight: activeTab == 3 ? "700" : "400",
                }}
                style={[styles.border, activeTab == 3 && styles.activeBorder]}
              />
              <DrawerItem
                label="Teams"
                icon={() => (
                  <MaterialIcons
                    name="corporate-fare"
                    size={20}
                    color={activeTab == 4 ? "#410DAA" : "#c1cfe0"}
                  />
                )}
                onPress={() => {
                  handleClick("ViewMode", "CorporateDashboard", "viewTeam");
                }}
                labelStyle={{
                  color: activeTab == 4 ? "#410DAA" : "#929292",
                  fontFamily: "Roboto",
                  fontWeight: activeTab == 4 ? "700" : "400",
                }}
                style={[styles.border, activeTab == 4 && styles.activeBorder]}
              />
              <DrawerItem
                label="Leader Board"
                icon={() => (
                  <Image
                    source={{
                      uri:
                        activeTab == 7
                          ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolio_active.png"
                          : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolio.png",
                    }}
                    style={{
                      width: 20,
                      height: 20,
                      resizeMode: "contain",
                      color: activeTab == 7 ? "#410DAA" : "#929292",
                    }}
                  />
                )}
                onPress={() => {
                  handleClick("ViewMode", "LeaderBoard");
                }}
                labelStyle={{
                  color: activeTab == 7 ? "#410DAA" : "#929292",
                  fontFamily: "Roboto",
                  fontWeight: activeTab == 7 ? "700" : "400",
                }}
                style={[styles.border, activeTab == 7 && styles.activeBorder]}
              />
            </View>
          )}
          {!showCPartnerDrawer && (is_channel_partner || is_superuser) ? (
            <>
              <View
                style={{ position: "absolute", bottom: 50, paddingLeft: 50 }}
              >
                <TouchableOpacity
                  onPress={() => {
                    window.open(
                      "https://cpartner.jamawealth.com/dashboard/corporateDashboard",
                      "_blank"
                    );
                  }}
                >
                  {/* <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/cpartner.png",
                    }}
                    style={{
                      width: 150,
                      height: 30,
                      resizeMode: "contain",
                    }}
                  ></Image> */}
                  <Text
                    style={{
                      color: "#410DAA",
                      fontWeight: "bold",
                      fontSize: 15,
                      textDecorationLine: "underline",
                    }}
                  >
                    C Partner
                  </Text>
                </TouchableOpacity>
              </View>
              <View
                style={{ position: "absolute", bottom: 0, paddingLeft: 50 }}
              >
                <TouchableOpacity
                  onPress={() => {
                    let hostname = global.location.hostname;
                    let url = "https://";
                    let { token } = getUserDetails();
                    if (token) {
                      token = reverseString(token);
                    }
                    if (hostname.includes("simplygrow.in")) {
                      let server_name = hostname.split(".")[0];
                      url = url + server_name + "-partner.simplygrow.in";
                    } else {
                      url = url + "dpartner.jamawealth.com";
                    }
                    url = url + "/login/?unique=" + token;
                    window.open(url, "_blank");
                  }}
                >
                  {/* {hostname.includes("jama") || hostname.includes("local") ? (
                    <Image
                      source={{
                        uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/jama%20wealth%20beta%40channelPartner.png",
                      }}
                      style={{
                        width: 150,
                        height: 30,
                        resizeMode: "contain",
                      }}
                    ></Image>
                  ) : (
                    <Text
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: "#410DAA",
                      }}
                    >
                      {server_name} - partner
                    </Text>
                  )} */}
                  <Text
                    style={{
                      color: "#410DAA",
                      fontWeight: "bold",
                      fontSize: 15,
                      textDecorationLine: "underline",
                    }}
                  >
                    { isJama() ? "Jama" : "Maxiom" } Partner
                  </Text>
                </TouchableOpacity>
              </View>
            </>
          ) : null}
        </View>
      )}
    </DrawerContentScrollView>
  );
};

const styles = StyleSheet.create({
  logoIcon: {
    width: 150,
    height: 60,
    resizeMode: "contain",
    paddingLeft: 20,
  },
  logoIconContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginLeft: 26,
  },
  row: {
    marginTop: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
  },

  drawerSection: {
    marginTop: 15,
  },

  iconContainer: {
    minWidth: 30,
  },
  drawerItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
  },
  labelStyle: {
    fontSize: 14,
    color: "#929292",
    paddingLeft: 15,
    fontFamily: "Roboto",
  },
  border: {
    borderLeftColor: "transparent",
    borderLeftWidth: 4,
    marginLeft: 0,
  },
  activeBorder: {
    backgroundColor: "#e3dbf2",
    marginLeft: 0,
    borderRadius: 0,
  },
  imageStyle: { width: 20, height: 20, marginLeft: 20 },
  drawerItemContainer: { marginBottom: 32, marginTop: 55, width: "250%" },
  versionNumber: { position: "absolute", bottom: 10 },
  faqItem: {
    maxWidth: 200,
  },
  faqItemText: {
    color: "#929292",
    fontSize: 14,
    paddingVertical: 6,
    marginLeft: 15,
    marginRight: 10,
  },
  divider: {
    marginHorizontal: 16,
    height: 0.5,
    width: "100%",
    maxWidth: 180,
    marginVertical: 6,
    backgroundColor: "#92929250",
  },
  faqItemsContainer: {
    marginTop: 10,
  },
  ph_num: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 17,
    color: "#6b717f",
    flexWrap: "wrap",
  },
});
