import React from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import Octicons from "@expo/vector-icons/Octicons";
import { isMobile } from "../../../utils";
import { DatePickerModal } from "react-native-paper-dates";

class DatePickerField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  render() {
    const { open } = this.state;
    const {
      label,
      value,
      style = [],
      editable,
      required = false,
      inputStyle = [],
    } = this.props;
    return (
      <View style={[...style]}>
        {label && (
          <Text style={styles.label}>
            {label} <Text style={styles.required}>{required ? "*" : ""}</Text>
          </Text>
        )}
        <TouchableOpacity
          onPress={() => {
            editable && this.setState({ open: true });
          }}
          style={[styles.input, !editable && styles.notEditable, ...inputStyle]}
        >
          <View style={styles.iconContainer}>
            <Text style={styles.text}>{value}</Text>
            <Octicons
              name="calendar"
              size={15}
              color="#494359"
              style={styles.icon}
            />
          </View>
        </TouchableOpacity>
        {open && (
          <DatePickerModal
            mode="single"
            visible={open}
            onChange={(value) => {
              this.setState({ open: false }, () => {
                this.props.onSelect && this.props.onSelect(value);
              });
            }}
            onDismiss={() => {
              this.setState({ open: false });
            }}
            saveLabel="" // optional
            label={label} // optional
            {...this.props}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  iconContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  text: { color: "#000000", fontSize: 15 },
  icon: { paddingRight: 10 },
  label: {
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: "700",
    color: "#464E5F",
    marginBottom: 11,
  },
  input: {
    borderWidth: 1,
    borderColor: "#767676",
    backgroundColor: "#fff",
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 11,
    minWidth: isMobile ? 240 : 300,
  },
  notEditable: {
    backgroundColor: "#EDF4F6",
  },
  required: {
    color: "red",
  },
});

export default DatePickerField;
