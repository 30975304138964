import React from "react";
import { View, Text, StyleSheet } from "react-native";

const DiscountTextMemo = (props) => {
  return props.discount == 0 ? (
    <View />
  ) : (
    <View style={discount.container}>
      <Text style={discount.congratsHighlight}>
        Slab discount of{" "}
        <Text style={discount.discountPercentage}>
          {props.discount.toFixed(1)}%
        </Text>{" "}
        is applied
      </Text>
    </View>
  );
};

const discount = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "center" },
  image: { width: 21.5, height: 19.5 },
  congratsHighlight: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    paddingLeft: 6,
  },
  congratsHighlightText: { color: "#0BB783", fontWeight: "bold" },
  discountPercentage: {
    color: "#410DAA",
    fontWeight: "bold",
    fontStyle: "italic",
    color: "#410DAA",
  },
});

const DiscountText = React.memo(DiscountTextMemo);

export default DiscountText;
