import React from "react";
import amplitude from "../../../utils/amplitude";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  Image,
  ActivityIndicator,
} from "react-native";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { md } from "../../../Responsive";
import NavigationHeader from "../../Common/NavigationHeader";
import { getMFDetails } from "../../../api";
import { isMobile } from "../../../utils";
import { TouchableOpacity } from "react-native-web";
const { width } = Dimensions.get("window");
const isWeb = md(width);

const FundField = ({ style, name, value, tooltip }) => {
  return (
    <View style={{ ...style }}>
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: "#6C7293",
            fontWeight: "600",
          }}
        >
          {name}
        </Text>
        {tooltip && (
          <ANTIcon
            name="infocirlce"
            color={"#a8a8a8"}
            size={12}
            style={{
              paddingLeft: 10,
            }}
          />
        )}
      </View>
      <Text
        style={{
          fontSize: 12,
          color: "#464E5F",
          fontWeight: "700",
          paddingTop: 5,
        }}
      >
        {value}
      </Text>
    </View>
  );
};

const Divider = ({ color, width, style }) => {
  return (
    <View
      style={[
        {
          borderBottomColor: color ? color : "black",
          borderBottomWidth: width ? width : 1,
        },
        style,
      ]}
    />
  );
};

export default class mfhomepage extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let userId = null;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }

    this.state = {
      userId,
      activeTabIndex: 0,
      isLoading: true,
      filters: [],
      mfDetails: [],
      mfDetailsMobileView: [],
      isSwitchOn: false,
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("mfhomepage",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("mfhomepage", { path: this.props.navigation.getState().routeName });
    }
    try {
      let newArr = [];
      const response = await getMFDetails();

      if (
        response.code == "100" &&
        response.result &&
        response.result.mutual_funds
      ) {
        response.result.mutual_funds.forEach((details) => {
          if (details.tag_name == "In Jama") {
            for (let i = 0; i < details.results.length; i += 2) {
              newArr.push(details.results.slice(i, i + 2));
            }

            this.setState({
              mfDetails: newArr,
              mfDetailsMobileView: details.results,
              isLoading: false,
            });
          }
        });
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText:
            response && response.msg ? response.msg : "Something went wrong!",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  setModalMethod = (method) => {
    this.openOrCloseModal = method;
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  render() {
    const { error, errorText, isLoading } = this.state;
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View style={{ flex: 1, maxWidth: 1500 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Mutual Funds Overview", "Mutual Funds"]}
          />
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <ActivityIndicator size="large" color="#410DAA" />
            ) : error ? (
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText || "Something went wrong!"}
              </Text>
            ) : (
              <ScrollView style={[styles.mainContainer]}>
                <View
                  style={{ paddingBottom: 40, marginTop: isMobile ? -3 : -17 }}
                >
                  {isMobile &&
                    this.state.mfDetailsMobileView.map((item, index) => {
                      return (
                        <View
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 17,
                            width: "90%",
                            marginLeft: "5%",
                            marginRight: "5%",
                          }}
                        >
                          <View
                            style={{
                              flex: 10,
                              borderRadius: 10,
                              borderWidth: 1,
                              borderColor: "#410DAA1A",
                            }}
                          >
                            <View
                              style={{
                                padding: 10,
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <View style={{ flex: 0.05 }}>
                                <Image
                                  source={{ uri: item.amc_logo }}
                                  resizeMode="contain"
                                  style={{
                                    height: 30,
                                    width: 30,
                                    alignSelf: "flex-start",
                                    borderWidth: 1,
                                    borderColor: "#f2f2f2",
                                    borderRadius: 5,
                                  }}
                                />
                              </View>
                              <View
                                style={{ flex: isMobile ? 0.05 : 0.01 }}
                              ></View>
                              <View style={{ flex: 0.84, paddingLeft: 35 }}>
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.amcfundscheme__name}
                                </Text>
                              </View>
                              <View style={{ flex: 0.1 }}></View>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                paddingHorizontal: 10,
                                paddingBottom: 10,
                                paddingTop: 6,
                              }}
                            >
                              <FundField
                                style={{ flex: 0.33, alignItems: "flex-start" }}
                                name="Category"
                                value={
                                  item.amcfundscheme__AMCFund__fund_class
                                    ? item.amcfundscheme__AMCFund__fund_class.split(
                                        "-"
                                      )[0]
                                    : "-"
                                }
                              />
                              <FundField
                                style={{ flex: 0.33, alignItems: "center" }}
                                name="Units Held"
                                value={
                                  item.type == "Jama"
                                    ? item.unit_balance.toFixed(3)
                                    : item.imported_units.toFixed(3)
                                }
                              />
                              <FundField
                                style={{ flex: 0.34, alignItems: "flex-end" }}
                                name="Latest Value"
                                value={`₹${this.numberFormat(
                                  item.latest_value_at_nav.toFixed(0)
                                )}`}
                              />
                            </View>
                            <Divider color="#410DAA1A" width={0.5} />
                            <View
                              style={{
                                paddingHorizontal: 10,
                                paddingVertical: 4,
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingVertical: 9,
                                }}
                              >
                                <FundField
                                  style={{
                                    flex: 0.33,
                                    alignItems: "flex-start",
                                  }}
                                  name="Invested"
                                  value={"₹" + item.amount_at_cost.toFixed(0)}
                                />
                                <FundField
                                  style={{ flex: 0.33, alignItems: "center" }}
                                  name="Gain"
                                  value={"₹" + item.gain.toFixed(0)}
                                />
                                <FundField
                                  style={{ flex: 0.34, alignItems: "flex-end" }}
                                  name="XIRR %"
                                  value={item.latest_xirr.toFixed(1) + "%"}
                                />
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingVertical: 9,
                                }}
                              >
                                <FundField
                                  style={{
                                    flex: 0.33,
                                    alignItems: "flex-start",
                                  }}
                                  name="Abs. Gain%"
                                  value={item.absolute_gain.toFixed(1) + "%"}
                                />
                                <FundField
                                  style={{ flex: 0.33, alignItems: "center" }}
                                  name="Folio No."
                                  value={
                                    item.type == "Jama"
                                      ? item.folio_number
                                      : item.imported_folio_number
                                  }
                                />
                                <FundField
                                  style={{ flex: 0.34, alignItems: "flex-end" }}
                                  name="Avg. Duration"
                                  value={item.avg_duration}
                                />
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingVertical: 9,
                                  justifyContent: "center",
                                }}
                              >
                                <View
                                  style={{
                                    flex: 0.33,
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <TouchableOpacity
                                    style={{
                                      width: 93,
                                      height: 35,
                                      borderRadius: 20,
                                      backgroundColor: "#410DAA1A",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    onPress={() => {
                                      this.props.navigation.navigate(
                                        "MyMFInvestmentHistory",
                                        {
                                          fund: item.amcfundscheme__pk,
                                        }
                                      );
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 12,
                                        fontWeight: "500",
                                        color: "#410DAA",
                                      }}
                                    >
                                      More ...
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      );
                    })}

                  {!isMobile &&
                    this.state.mfDetails.map((item, index) => {
                      return (
                        <View
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 17,
                          }}
                        >
                          <View
                            style={{
                              flex: 10,
                              borderRadius: 10,
                              borderWidth: 1,
                              borderColor: "#410DAA1A",
                            }}
                          >
                            <View
                              style={{
                                padding: 10,
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <View style={{ flex: 0.05 }}>
                                <Image
                                  source={{ uri: item[0].amc_logo }}
                                  resizeMode="contain"
                                  style={{
                                    height: 30,
                                    width: 30,
                                    alignSelf: "flex-start",
                                    borderWidth: 1,
                                    borderColor: "#f2f2f2",
                                    borderRadius: 5,
                                  }}
                                />
                              </View>
                              <View
                                style={{ flex: isMobile ? 0.05 : 0.01 }}
                              ></View>
                              <View style={{ flex: 0.84, paddingLeft: 35 }}>
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#464E5F",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item[0].amcfundscheme__name}
                                </Text>
                              </View>
                              <View style={{ flex: 0.1 }}></View>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                paddingHorizontal: 10,
                                paddingBottom: 10,
                                paddingTop: 6,
                              }}
                            >
                              <FundField
                                style={{ flex: 0.33, alignItems: "flex-start" }}
                                name="Category"
                                value={
                                  item[0].amcfundscheme__AMCFund__fund_class
                                    ? item[0].amcfundscheme__AMCFund__fund_class.split(
                                        "-"
                                      )[0]
                                    : "-"
                                }
                              />
                              <FundField
                                style={{ flex: 0.33, alignItems: "center" }}
                                name="Units Held"
                                value={
                                  item[0].type == "Jama"
                                    ? item[0].unit_balance.toFixed(3)
                                    : item[0].imported_units.toFixed(3)
                                }
                              />
                              <FundField
                                style={{ flex: 0.34, alignItems: "flex-end" }}
                                name="Latest Value"
                                value={`₹${this.numberFormat(
                                  item[0].latest_value_at_nav.toFixed(0)
                                )}`}
                              />
                            </View>
                            <Divider color="#410DAA1A" width={0.5} />
                            <View
                              style={{
                                paddingHorizontal: 10,
                                paddingVertical: 4,
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingVertical: 9,
                                }}
                              >
                                <FundField
                                  style={{
                                    flex: 0.33,
                                    alignItems: "flex-start",
                                  }}
                                  name="Invested"
                                  value={
                                    "₹" + item[0].amount_at_cost.toFixed(0)
                                  }
                                />
                                <FundField
                                  style={{ flex: 0.33, alignItems: "center" }}
                                  name="Gain"
                                  value={"₹" + item[0].gain.toFixed(0)}
                                />
                                <FundField
                                  style={{ flex: 0.34, alignItems: "flex-end" }}
                                  name="XIRR %"
                                  value={item[0].latest_xirr.toFixed(1) + "%"}
                                />
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingVertical: 9,
                                }}
                              >
                                <FundField
                                  style={{
                                    flex: 0.33,
                                    alignItems: "flex-start",
                                  }}
                                  name="Abs. Gain%"
                                  value={item[0].absolute_gain.toFixed(1) + "%"}
                                />
                                <FundField
                                  style={{ flex: 0.33, alignItems: "center" }}
                                  name="Folio No."
                                  value={
                                    item[0].type == "Jama"
                                      ? item[0].folio_number
                                      : item[0].imported_folio_number
                                  }
                                />
                                <FundField
                                  style={{ flex: 0.34, alignItems: "flex-end" }}
                                  name="Avg. Duration"
                                  value={item[0].avg_duration}
                                />
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingVertical: 9,
                                  justifyContent: "center",
                                }}
                              >
                                <View
                                  style={{
                                    flex: 0.33,
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <TouchableOpacity
                                    style={{
                                      width: 93,
                                      height: 35,
                                      borderRadius: 20,
                                      backgroundColor: "#410DAA1A",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    onPress={() => {
                                      this.props.navigation.navigate(
                                        "MyMFInvestmentHistory",
                                        {
                                          fund: item[0].amcfundscheme__pk,
                                        }
                                      );
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 12,
                                        fontWeight: "500",
                                        color: "#410DAA",
                                      }}
                                    >
                                      More ...
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              flex: 0.5,
                            }}
                          ></View>

                          {item[1] && item[1].amc_logo ? (
                            <View
                              style={{
                                flex: 10,
                                borderRadius: 10,
                                borderWidth: 1,
                                borderColor: "#410DAA1A",
                              }}
                            >
                              <View
                                style={{
                                  padding: 10,
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <View style={{ flex: 0.05 }}>
                                  <Image
                                    source={{ uri: item[1].amc_logo }}
                                    resizeMode="contain"
                                    style={{
                                      height: 30,
                                      width: 30,
                                      alignSelf: "flex-start",
                                      borderWidth: 1,
                                      borderColor: "#f2f2f2",
                                      borderRadius: 5,
                                    }}
                                  />
                                </View>
                                <View
                                  style={{ flex: isMobile ? 0.05 : 0.01 }}
                                ></View>
                                <View style={{ flex: 0.84, paddingLeft: 35 }}>
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      color: "#464E5F",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item[1].amcfundscheme__name}
                                  </Text>
                                </View>
                                <View style={{ flex: 0.1 }}></View>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingHorizontal: 10,
                                  paddingBottom: 10,
                                  paddingTop: 6,
                                }}
                              >
                                <FundField
                                  style={{
                                    flex: 0.33,
                                    alignItems: "flex-start",
                                  }}
                                  name="Category"
                                  value={
                                    item[1].amcfundscheme__AMCFund__fund_class
                                      ? item[1].amcfundscheme__AMCFund__fund_class.split(
                                          "-"
                                        )[0]
                                      : "-"
                                  }
                                />
                                <FundField
                                  style={{ flex: 0.33, alignItems: "center" }}
                                  name="Units Held"
                                  value={
                                    item[1].type == "Jama"
                                      ? item[1].unit_balance.toFixed(3)
                                      : item[1].imported_units.toFixed(3)
                                  }
                                />
                                <FundField
                                  style={{ flex: 0.34, alignItems: "flex-end" }}
                                  name="Latest Value"
                                  value={`₹${this.numberFormat(
                                    item[1].latest_value_at_nav.toFixed(0)
                                  )}`}
                                />
                              </View>
                              <Divider color="#410DAA1A" width={0.5} />
                              <View
                                style={{
                                  paddingHorizontal: 10,
                                  paddingVertical: 4,
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    paddingVertical: 9,
                                  }}
                                >
                                  <FundField
                                    style={{
                                      flex: 0.33,
                                      alignItems: "flex-start",
                                    }}
                                    name="Invested"
                                    value={
                                      "₹" + item[1].amount_at_cost.toFixed(0)
                                    }
                                  />
                                  <FundField
                                    style={{ flex: 0.33, alignItems: "center" }}
                                    name="Gain"
                                    value={"₹" + item[1].gain.toFixed(0)}
                                  />
                                  <FundField
                                    style={{
                                      flex: 0.34,
                                      alignItems: "flex-end",
                                    }}
                                    name="XIRR %"
                                    value={item[1].latest_xirr.toFixed(1) + "%"}
                                  />
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    paddingVertical: 9,
                                  }}
                                >
                                  <FundField
                                    style={{
                                      flex: 0.33,
                                      alignItems: "flex-start",
                                    }}
                                    name="Abs. Gain%"
                                    value={
                                      item[1].absolute_gain.toFixed(1) + "%"
                                    }
                                  />
                                  <FundField
                                    style={{ flex: 0.33, alignItems: "center" }}
                                    name="Folio No."
                                    value={
                                      item[1].type == "Jama"
                                        ? item[1].folio_number
                                        : item[1].imported_folio_number
                                    }
                                  />
                                  <FundField
                                    style={{
                                      flex: 0.34,
                                      alignItems: "flex-end",
                                    }}
                                    name="Avg. Duration"
                                    value={item[1].avg_duration}
                                  />
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    paddingVertical: 9,
                                    justifyContent: "center",
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: 0.33,
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <TouchableOpacity
                                      style={{
                                        width: 93,
                                        height: 35,
                                        borderRadius: 20,
                                        backgroundColor: "#410DAA1A",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      onPress={() => {
                                        this.props.navigation.navigate(
                                          "MyMFInvestmentHistory",
                                          {
                                            fund: item[1].amcfundscheme__pk,
                                          }
                                        );
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 12,
                                          fontWeight: "500",
                                          color: "#410DAA",
                                        }}
                                      >
                                        More ...
                                      </Text>
                                    </TouchableOpacity>
                                  </View>
                                </View>
                              </View>
                            </View>
                          ) : (
                            <View
                              style={{
                                flex: 10,
                              }}
                            ></View>
                          )}
                        </View>
                      );
                    })}
                </View>
                {isMobile && <View style={{ marginTop: 35 }}></View>}
              </ScrollView>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: isWeb ? 32 : 0,
    paddingRight: isWeb ? 32 : 0,
    paddingTop: isWeb ? 30 : 10,
    width: "100%",
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
  filterText: {
    fontSize: 15,
    color: "gray",
    fontWeight: "200",
  },
  filtersTitle: {
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 6,
    marginLeft: isMobile ? 16 : 0,
  },
});
