import React from "react";
import {
  View,
  SafeAreaView,
  StyleSheet,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
} from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { connect } from "react-redux";
import { encryptToken, getUserDetails, isMobile } from "../../../utils";
import { getInvestNowStrips } from "../../../api/invest";
import { SideHeading } from "../../Common/sideHeading";
import { CardStripNavigation } from "../../Common/CardStripNavigation";
import Divider from "../../Common/divider";
import { MAIN_URL } from "../../../api/urls";
import Store from "../../../store";

const TabStripItem = ({
  image,
  heading,
  descriptions,
  navigatable,
  onPress,
  style,
}) => {
  return (
    <TouchableOpacity
      style={{ flexDirection: "row", alignItems: "center", ...style }}
      onPress={() => {
        navigatable && onPress && onPress();
      }}
    >
      <View style={{ flex: 0.15 }}>
        <Image
          source={image}
          style={{
            width: 40,
            height: 40,
            alignSelf: "center",
            borderRadius: 4,
          }}
          resizeMode={"contain"}
        />
      </View>
      <View
        style={{
          flex: 0.75,
          flexDirection: "column",
          paddingLeft: 10,
          //   justifyContent: "space-between"
        }}
      >
        <Text style={{ fontSize: 15, color: "#121212", fontWeight: "bold" }}>
          {heading}
        </Text>
        {descriptions.map((description) => {
          return (
            <Text style={{ fontSize: 12, color: "#A8A8A8" }}>
              {description}
            </Text>
          );
        })}
      </View>
      {navigatable && (
        <TouchableOpacity
          style={{
            justifyContent: "center",
            alignItems: "flex-end",
            flex: 0.1,
          }}
          onPress={() => {
            onPress();
          }}
        >
          <ANTIcon name={"right"} color={"#1553A5"} size={16} />
        </TouchableOpacity>
      )}
    </TouchableOpacity>
  );
};

const PersonalisedStrip = ({
  heading,
  title,
  descriptions,
  navigateTo,
  navigationParams,
  style,
  navigation,
  onPress,
}) => {
  return (
    <CardStripNavigation
      image={require("../../../assets/home/personalised.png")}
      heading={heading}
      title={title}
      description={descriptions[0]}
      onPress={() => {
        navigateTo &&
          navigation.navigate("App", {
            screen: navigateTo.stackName,
            params: {
              screen: navigateTo.routeName,
              params: {
                from: "Invest",
                params: { index: 0, ...navigationParams },
              },
            },
          });
        onPress && onPress();
      }}
      style={{ marginBottom: 10, marginTop: 10, ...style }}
    />
  );
};

const SectionalStrip = ({
  type,
  title,
  descriptions,
  noDivider,
  data,
  style,
  onClick,
  tipHeading,
  tipDescriptions,
}) => {
  return (
    <View style={{ ...style }}>
      <SideHeading
        title={title}
        descriptions={descriptions}
        style={{
          fontSize: 17,
          alignItems: "flex-start",
        }}
        tipHeading={tipHeading}
        tipDescriptions={tipDescriptions}
        headingTitleContainerStyle={{
          marginLeft: 10,
        }}
        headingTitleStyle={{
          marginBottom: 6,
        }}
      />
      {noDivider && noDivider.indexOf(type) == -1 && (
        <Divider
          width={0.5}
          color={"#BCBCBC"}
          style={{ flex: 1, marginHorizontal: 16 }}
        />
      )}
      {data &&
        data.map((item, index) => {
          if (item.title == "Jama Wealth Equity Long") {
            return <View></View>;
          }
          const navigatable = "navigation_props" in item;
          return (
            <TabStripItem
              image={{
                uri: item.icon,
              }}
              heading={item.title}
              descriptions={item.descriptions}
              navigatable={navigatable}
              onPress={() => {
                onClick && onClick(item);
                // navigatable &&
                //   navigation &&
                //   navigation.navigate(`${item["navigation_props"]}`, {
                //     fundType: item["navigation_props"]["fund_type"],
                //     fundSubType: item["navigation_props"]["fund_sub_type"]
                //   });
              }}
              style={{
                marginHorizontal: 16,
                marginVertical: 12,
              }}
            />
          );
        })}
    </View>
  );
};

const mapRouteToScreen = (route) => {
  let data = {};
  switch (route) {
    case "TagGoal":
    case "CreateFinplan":
      data = {
        screen: "CreateFinplan",
        stack: "Goals",
      };
      break;
    case "CreateGoalsIntro":
      data = {
        screen: "CreateGoalsIntro",
        stack: "Goals",
      };
      break;
    default:
      break;
  }
  return data;
};
class InvestNowNew extends React.Component {
  constructor(props) {
    super(props);
    // let index = this.props.navigation.getParam("index", 0);
    // index = parseInt(index);
    // let fund_type = this.props.navigation.getParam("fund_type", null);
    // let fund_sub_type = this.props.navigation.getParam("fund_sub_type", null);
    this.state = {
      // exploreScreenFilters: {
      //   fund_type,
      //   fund_sub_type
      // }
      isLoading: true,
    };

    // this.navigateToExploreTab({
    //   fundType: fund_type,
    //   fundSubType: fund_sub_type,
    //   activeTab: index
    // });
  }

  loadInvestStripData = async () => {
    try {
      const response = await getInvestNowStrips();
      if (response && response.invest) {
        Store.dispatch({
          type: "INVEST_STRIPS_DATA",
          payload: {
            investStripsData: response.invest,
          },
        });
        this.setState({
          investStripsData: response.invest,
        });
      }
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }
  };

  async componentDidMount() {
    this.loadInvestStripData();
  }

  static navigationOptions = ({ navigation }) => {
    const { state } = navigation;
    let tabBarVisible = true;
    if (state && state.params) {
      tabBarVisible = state.params.tabBarVisible;
    }
    return {
      tabBarVisible,
    };
  };

  swipeTab = (index, callback) => {
    try {
      if (this.swiper && this.swiper.scrollView) {
        if (Platform.OS === "android") {
          // this.swiper.scrollBy(index);
          // this.swiper.scrollView.setPage(index);
          this.swiper.scrollView.scrollTo({
            x: this.swiper.state.width * index,
          });
          callback && callback();
        } else {
          this.swiper.scrollView.scrollTo({
            x: this.swiper.state.width * index,
          });
          callback && callback();
        }
      }
    } catch (error) {}
  };

  navigateToExploreTab = (filters) => {
    try {
      this.props.navigation.push("App", {
        screen: "Dashboard",
        params: {
          screen: "Explore",
          params: {
            screen: "MFExplore",
            params: {
              index: 1,
              filter: filters.fundType,
              type: filters.fundSubType,
            },
          },
        },
      });
    } catch (error) {}
  };

  buildInvestScreen = (stripsData) => {
    if (stripsData) {
      return (
        <>
          {stripsData.map((strip, index) => {
            if (strip.type == "PERSONALISED_FOR_YOU") {
              const { current_subscription_plan } = getUserDetails();
              let navigateTo = {};
              if (!current_subscription_plan) return;
              if (current_subscription_plan == "BP") {
                navigateTo = null;
              } else {
                navigateTo["routeName"] = "PersonalisedFunds";
                navigateTo["stackName"] = "Account";
              }
              return (
                <PersonalisedStrip
                  heading={strip.title}
                  title={strip.items[0]["title"]}
                  descriptions={strip.items[0]["descriptions"]}
                  navigateTo={navigateTo}
                  navigationParams={
                    strip.items[0]["navigation_props"]["params"]
                  }
                  onPress={() => {
                    if (current_subscription_plan == "BP") {
                      let { token } = getUserDetails();
                      const encryptedToken = encryptToken(token);
                      const url =
                        MAIN_URL +
                        "/app/rnw_to_web_login?token=" +
                        encryptedToken +
                        "&is_rnw=1&redirection_url=/app/be-a-member/";
                      this.props.navigation.navigate("App", {
                        screen: "Dashboard",
                        params: {
                          screen: "Portfolio",
                          params: {
                            screen: "ShowWebView",
                            params: {
                              url,
                              showInWeb: isMobile ? false : true,
                            },
                          },
                        },
                      });
                    }
                  }}
                  style={{}}
                  navigation={this.props.navigation}
                />
              );
            } else if (strip.type == "EMPTY_SPACE") {
              return (
                <View
                  style={{
                    width: "100%",
                    height: 20,
                    backgroundColor: "#f2f2f2",
                  }}
                ></View>
              );
            } else {
              return (
                <SectionalStrip
                  type={strip.type}
                  title={strip.title}
                  noDivider={["WHY_CHOOSE_JAMA"]}
                  descriptions={
                    strip.type == "INVEST_FOR_A_GOAL"
                      ? ["Scientific calculators to estimate your needs."]
                      : strip.type == "INVEST_TO_SAFELY_PARK"
                      ? ["Earn more than bank deposits. Reduce tax on earning."]
                      : strip.descriptions
                  }
                  tipHeading={strip.tip_heading}
                  tipDescriptions={strip.tip_descriptions}
                  data={strip.items}
                  navigation={this.props.navigation}
                  onClick={(item) => {
                    if (
                      item &&
                      item.navigation_props &&
                      item.navigation_props.navigate_to &&
                      item.navigation_props.navigate_to == "ChildFuture"
                    ) {
                      this.props.navigation.replace("App", {
                        screen: "Dashboard",
                        params: {
                          screen: "Goals",
                          params: {
                            screen: "CreateFinplan",
                            params: {
                              type: "CHILDREN",
                              from: "Home",
                              stack: "Dashboard",
                            },
                          },
                        },
                      });
                    } else {
                      const params = {
                        fundType: item["navigation_props"]["fund_type"],
                        fundSubType: item["navigation_props"]["fund_sub_type"],
                      };
                      if (item["navigation_props"]["to"] == "Route") {
                        const { screen, stack } = mapRouteToScreen(
                          item["navigation_props"]["navigate_to"]
                        );
                        if (screen && stack) {
                          this.props.navigation.replace("App", {
                            screen: "Dashboard",
                            params: {
                              screen: stack,
                              params: {
                                screen: screen,
                                params: {
                                  ...item["navigation_props"]["params"],
                                  from: "InvestNowNew",
                                  index: 0,
                                },
                              },
                            },
                          });
                        }
                      } else {
                        this.navigateToExploreTab(params);
                      }
                    }
                  }}
                  style={{
                    marginBottom: 10,
                  }}
                />
              );
            }
          })}
        </>
      );
    }
    return null;
  };

  render() {
    const { isLoading, investStripsData = null } = this.state;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            maxWidth: 800,
            paddingBottom: 100,
          }}
          showsVerticalScrollIndicator={true}
          keyboardShouldPersistTaps={"handled"}
        >
          {isLoading ? (
            <View style={styles.loaderContainer}>
              <ActivityIndicator size="large" color="#410DAA" />
            </View>
          ) : (
            <>{this.buildInvestScreen(investStripsData)}</>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

const mapStateToProps = (state) => {
  return {};
};

export default connect(null)(InvestNowNew);
