import React from "react";
import {
  View,
  StyleSheet,
  Image,
  SafeAreaView,
  ScrollView,
  Platform,
} from "react-native";
import { Text } from "react-native-paper";
import FIcon from "@expo/vector-icons/FontAwesome";
import CustButton from "../../components/Common/Button";
import EmptyView from "../../components/Common/emptyView";

import JarsHeading from "../../assets/images/jarsHeading.png";
import JarsBanner from "../../assets/images/jarsBanner.png";
import jarsPerformance from "../../assets/images/jars/jarsPerformance.png";
import jarsPortfolio from "../../assets/images/jars/jarsPortfolio.png";
import jarsStatistics from "../../assets/images/jars/jarsStatistics.png";
import { isJama } from "../../utils";

const JARS_COLOR = "#ffc20e";

const cleanSafeData = [
  "We don't touch client money",
  "No lock-in, exit loads on service",
  "No conflict of interest",
  "No custody of stocks / units / cash",
  "No commission, no brokerage",
];

const JarsData = [
  {
    heading: "How we performed over the years",
    description:
      "Over the Financial years of <Text style={{color: 'red'}}>2010 - 2019</Text>, if you did investment of",
    image: jarsPerformance,
  },
  {
    heading: "Our model portfolio",
    description: "",
    image: jarsPortfolio,
  },
  {
    heading: "Key Statistics",
    description: "",
    image: jarsStatistics,
  },
];

export default class Jars extends React.Component {
  renderCleanAndSafe = () => {
    const { cleanSafe } = styles;
    return (
      <View style={cleanSafe}>
        <Text
          style={{
            width: "100%",
            padding: 16,
            textAlign: "center",
            backgroundColor: JARS_COLOR,
          }}
        >
          Clean & Safe Model
        </Text>

        {cleanSafeData.map((item) => (
          <Text
            key={item}
            style={{ paddingHorizontal: 16, color: "#fff", paddingVertical: 8 }}
          >
            <FIcon name={"circle"} color={"#fff"} size={10} /> &nbsp;{item}
          </Text>
        ))}
      </View>
    );
  };

  renderJarsdata = () => {
    return (
      <View>
        {JarsData.map((dataItem, index) => {
          if (index == 0) {
            return (
              <View style={{}} key={dataItem.heading}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View
                    style={{
                      width: 16,
                      backgroundColor: JARS_COLOR,
                      borderRadius: 8,
                      height: 64,
                      marginLeft: -10,
                    }}
                  ></View>
                  <Text
                    style={{
                      fontSize: 24,
                      textAlign: "center",
                      marginVertical: 16,
                      flex: 1,
                      fontWeight: "bold",
                    }}
                    allowFontScaling={false}
                  >
                    {dataItem.heading}
                  </Text>
                  <View
                    style={{
                      width: 16,
                      backgroundColor: JARS_COLOR,
                      borderRadius: 8,
                      height: 64,
                      marginRight: -10,
                    }}
                  ></View>
                </View>
                <Text style={{ margin: 16, color: "grey" }}>
                  Over the Financial years of &nbsp;
                  <Text style={{ fontWeight: "bold" }}>2010 - 2019</Text>, if
                  you did investment of{" "}
                  <Text style={{ color: "black", fontWeight: "bold" }}>
                    &#8377; 1 crore{" "}
                  </Text>{" "}
                  { isJama() ? "Jama" : "Maxiom" } returns would be as high as{" "}
                  <Text style={{ color: "black", fontWeight: "bold" }}>
                    &#8377; 13.29 crore{" "}
                  </Text>
                </Text>

                <Text
                  style={{ color: "blue", marginLeft: 16, fontWeight: "bold" }}
                >
                  JEWEL CAGR: <Text style={{ color: "navy" }}>29.53% p.a.</Text>
                </Text>
                <Image
                  source={dataItem.image}
                  style={{ width: "100%", height: 300, resizeMode: "contain" }}
                />
                <EmptyView />
              </View>
            );
          }
          return (
            <View style={{}} key={dataItem.heading}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View
                  style={{
                    width: 16,
                    backgroundColor: JARS_COLOR,
                    borderRadius: 8,
                    height: 64,
                    marginLeft: -10,
                  }}
                ></View>
                <Text
                  style={{
                    fontSize: 24,
                    textAlign: "center",
                    marginVertical: 16,
                    flex: 1,
                    fontWeight: "bold",
                  }}
                  allowFontScaling={false}
                >
                  {dataItem.heading}
                </Text>
                <View
                  style={{
                    width: 16,
                    backgroundColor: JARS_COLOR,
                    borderRadius: 8,
                    height: 64,
                    marginRight: -10,
                  }}
                ></View>
              </View>
              <Text style={{ margin: 16, color: "grey" }}>
                {dataItem.description}
              </Text>
              <Image
                source={dataItem.image}
                style={{ width: "100%", height: 300, resizeMode: "contain" }}
              />
              <EmptyView />
            </View>
          );
        })}
      </View>
    );
  };

  render() {
    const { container } = styles;
    return (
      <SafeAreaView style={container}>
        <ScrollView showsVerticalScrollIndicator={true}>
          <FIcon
            name={"close"}
            color={"#000"}
            size={30}
            onPress={() => {
              this.props.navigation.navigate("Home");
            }}
            style={{
              width: "100%",
              alignSelf: "flex-start",
              margin: 16,
              marginBottom: 0,
            }}
          />
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View
              style={{
                width: 16,
                backgroundColor: JARS_COLOR,
                borderRadius: 8,
                height: 80,
                marginLeft: -10,
              }}
            ></View>
            <Image
              source={JarsHeading}
              style={{
                width: "80%",
                height: 100,
                flex: 1,
                marginHorizontal: 16,
                resizeMode: "contain",
                alignSelf: "center",
              }}
            />
            <View
              style={{
                width: 16,
                backgroundColor: JARS_COLOR,
                borderRadius: 8,
                height: 80,
                marginRight: -10,
              }}
            ></View>
          </View>

          <Image
            source={JarsBanner}
            style={{
              width: 250,
              height: 300,
              resizeMode: "contain",
              alignSelf: "center",
            }}
          />
          <View style={{ marginHorizontal: 16 }}>
            <Text>
              We follow a simple Investment Philosophy for JEWEL called Roots &
              Wings. Roots aim to preserve wealth by selecting companies with
              low debt, consistent efficiency & promoter integrity. Wings aim to
              increase prosperity by identifying growing companies (sales /
              profit / cashflows).
            </Text>
          </View>
          <EmptyView />

          {this.renderCleanAndSafe()}
          <EmptyView />
          {this.renderJarsdata()}

          <View style={{ alignItems: "center" }}>
            <CustButton
              bgColor={"#ffc20e"}
              textColor={"#121212"}
              borderColor={"#ffc20e"}
              style={styles.buttonStyle}
              onPress={() => {
                // this.props.navigation.navigate("WebViewJama", {
                //   title: "Subscription",
                //   url: "/app/du"
                //   // mainUrl: "https://www.jama.co.in/app/android_to_web_login"
                // });

                this.props.navigation.navigate("ScheduleCall", {
                  topic: "DE",
                });
              }}
            >
              Contact Advisor
            </CustButton>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    color: JARS_COLOR,
    backgroundColor: "#fff",
    maxWidth: 800,
  },
  buttonStyle: {
    position: "absolute",
    width: "100%",
    padding: 16,
    bottom: 0,
    borderRadius: 5,
  },

  cleanSafe: {
    borderRadius: 8,
    backgroundColor: "darkblue",
    overflow: "hidden",
    paddingBottom: 8,
    marginHorizontal: 16,
  },
});
