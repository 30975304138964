import React, { useState } from "react";
import { StyleSheet, View, Image, Dimensions } from "react-native";
import { Text } from "react-native-paper";
import { sm, md, lg, xl, twoxl } from "../../Responsive";
import global from "./globalStyles";

const window = Dimensions.get("window");

export default function PlanAdvantages({ plan, previousPlanName }) {
    let bullet = null;
    switch (plan.name) {
        case "gold":
            bullet = require("../../assets/upgradePlan/Gold_Page/check.png");
            break;
        case "jewel":
            bullet = require("../../assets/upgradePlan/Jewel_Page/check.png");
            break;
        default:
            bullet = require("../../assets/upgradePlan/Jewel_Page/check.png");
    }
    return (
        <View style={styles.container}>
            <Text
            style={[styles.heading, global.arial]}
            >{`Why ${plan.displayName} is better than ${previousPlanName} Membership`}</Text>
            <View style={styles.advantagesContainer}>
            {plan.whyBetter.map(a => (
                <View style={{ display: "flex", flexDirection: "row" }}>
                <Image style={styles.bullet} source={bullet}></Image>
                <Text style={[styles.advantage, global.arial]}>{a}</Text>
                </View>
            ))}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        display: md(window.width) ? "flex" : "none",
        flexDirection: md(window.width) ? "column" : "row",
        maxWidth: md(window.width) ? 400 : undefined
    },
    heading: {
        fontWeight: "bold",
        marginTop: md(window.width) ? 60 : 40,
        fontSize: 20,
        textAlign: "center",
        color: "black"
    },
    advantage: {
        margin: 2,
        fontSize: 13,
        marginLeft: 11,
        marginRight: 56,
        color: "black"
    },
    advantagesContainer: {
        marginTop: 15
    },
    bullet: {
        width: 14,
        height: 14,
        resizeMode: "contain",
        marginLeft: md(window.width) ? undefined : 16,
        alignSelf: "center"
    }
});
  
