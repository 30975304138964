import React from "react";
import { View, StyleSheet } from "react-native";
import { TextInput } from "react-native-paper";
import AntDesign from "@expo/vector-icons/AntDesign";
import { isMobile } from "../../utils";

const SearchBar = (props) => {
  const handleTextChange = (text) => {
    props.onChange && props.onChange(text);
  };

  const { style = [], value, placeHolder } = props;
  return (
    <View style={[styles.container, ...style]}>
      <AntDesign
        name="search1"
        size={12}
        color="#DADADA"
        style={styles.searchIcon}
      />
      <TextInput
        placeholder={placeHolder || "Search"}
        value={value}
        onChangeText={handleTextChange}
        style={styles.textInput}
        mode="flat"
        underlineColor={"transparent"}
        theme={{
          colors: {
            primary: "transparent",
          },
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    borderColor: "#410DAA33",
    borderRadius: 5,
    borderWidth: 1,
    paddingHorizontal: 12,
    paddingVertical: 7,
    backgroundColor: "#fff",
    width: isMobile ? "80%" : 350,
  },
  textInput: {
    fontSize: 13,
    color: "#B5B5C3",
    fontFamily: "Roboto",
    backgroundColor: "#fff",
    height: 30,
    width: "100%",
  },
});

export default SearchBar;
