import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import React from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  Dimensions,
  Text,
  TouchableOpacity,
} from "react-native";
import { Divider } from "react-native-paper";
import FieldValue from "../../components/FieldValue";
import DotSeparator from "../../../../components/Common/DotSeparator";
import { isMobile, numberWithCommas } from "../../../../utils";
import Store from "../../../../store";
const { height } = Dimensions.get("window");

const BillHeadingItem = ({
  label,
  value,
  style = [],
  labelStyle = [],
  valueStyle = [],
}) => {
  return (
    <View style={[{ flexDirection: "row", alignItems: "center" }, ...style]}>
      <Text style={[...labelStyle]}>{label}</Text>
      <Text style={[...valueStyle]}>{value}</Text>
    </View>
  );
};

const BillHeading = ({ data }) => {
  return (
    <View style={styles.billBasicDetailsContainer}>
      <View style={styles.headingContainer}>
        <Text style={styles.majorHeadingText}>
          Direct Equity Advisory - {data["product_text"]} Plan{" "}
          {data["plan_code"]}
        </Text>
        <View style={styles.descriptionContainer}>
          <View style={styles.descriptionSubContainer}>
            <BillHeadingItem
              label={"Plan No:"}
              value={data["plan_number"]}
              style={[{ marginLeft: -4, paddingRight: 8 }]}
              labelStyle={[styles.labelStyle]}
              valueStyle={[styles.valueStyle]}
            />
            <DotSeparator />
            <BillHeadingItem
              label={"Advisory Fee :"}
              value={data["discounted_fee_perc"].toFixed(2) + "%"}
              style={[{ paddingHorizontal: 8 }]}
              labelStyle={[styles.labelStyle]}
              valueStyle={[styles.valueStyle, styles.bold]}
            />
          </View>
          {!isMobile && <DotSeparator />}
          {
            <View style={styles.descriptionSubContainer}>
              <BillHeadingItem
                label={"Hurdle:"}
                style={[{ paddingLeft: isMobile ? 0 : 8, paddingRight: 8 }]}
                value={
                  (data["discounted_hurdle_perc"].toFixed(2) || 0) + "% p.a."
                }
                labelStyle={[styles.labelStyle]}
                valueStyle={[styles.valueStyle, styles.bold]}
              />
              <DotSeparator />
              <BillHeadingItem
                label={"Performance Fee Share:"}
                value={(data["discounted_perf_fee_perc"].toFixed(2) || 0) + "% p.a."}
                style={[{ paddingLeft: 8 }]}
                labelStyle={[styles.labelStyle]}
                valueStyle={[styles.valueStyle, styles.bold]}
              />
            </View>
          }
        </View>
      </View>
      <View>
        <View style={styles.majorHeadingContainer}>
          <Text style={styles.majorHeadingText}>
            {data["bill_type"]}
          </Text>
        </View>
        <View style={styles.descriptionContainer}>
          <Text style={styles.planNumber}>{data["bill_period_text"]}</Text>
        </View>
      </View>
    </View>
  );
};

const PerformanceBill = ({ data = {}, ...props }) => {
  const navigateToDividendDetails = () => {
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      props.navigation.navigate("DividendYields", {
        bill_id: props.billId,
        user_id: Store.getState().dashboard.viewModeUserId.user_id,
      });
    } else {
      props.navigation.navigate("DividendYields", {
        bill_id: props.billId,
        user_id: props.userId,
      });
    }
  };

  const navigateToInvestedDetails = () => {
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      props.navigation.navigate("InvestAmountDetails", {
        bill_id: props.billId,
        user_id: Store.getState().dashboard.viewModeUserId.user_id,
      });
    } else {
      props.navigation.navigate("InvestAmountDetails", {
        bill_id: props.billId,
        user_id: props.userId,
      });
    }
  };

  const navigateToHoldingsDetails = () => {
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      props.navigation.navigate("HoldingsDetails", {
        bill_id: props.billId,
        user_id: Store.getState().dashboard.viewModeUserId.user_id,
        invoice_id: props.invoiceId,
      });
    } else {
      props.navigation.navigate("HoldingsDetails", {
        bill_id: props.billId,
        user_id: props.userId,
        invoice_id: props.invoiceId,
      });
    }
  };

  return (
    isMobile ? (
      <ScrollView horizontal={true}  style={{ height: height / 2 }}>
      <View style={styles.boxContainer}>
        <BillHeading data={data} />

        <View style={styles.auaContainer}>
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.committedAua}>{data["box1_field_name"]} </Text>
              <Text style={styles.subDescription}>
                ({data["plan_period_text"]})
              </Text>
            </View>
            {/* <Text style={styles.feesAmount}>
              ₹{numberWithCommas(parseInt(data["committed_aua"]))}
            </Text> */}
          </View>
          <Divider />
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.textHighlight}>Invested Amount </Text>
              {/* <Text style={[styles.textNormal, styles.subDescription]}>
                ({data["plan_period_text"]})
              </Text> */}
            </View>
            <View style={[styles.amountContainer]}>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["total_invested"]))}
              </Text>
              <TouchableOpacity
                style={styles.viewDetailsContainer}
                onPress={navigateToInvestedDetails}
              >
                <Text style={styles.viewDetails}>View Details</Text>
                <FontAwesome5
                  name="arrow-circle-right"
                  style={{ fontSize: 18, color: "#410DAA" }}
                />
              </TouchableOpacity>
            </View>
          </View>
          <Divider />
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.textHighlight}>Current AUA </Text>
              {/* <Text style={[styles.textNormal, styles.subDescription]}>
                ({data["plan_period_text"]})
              </Text> */}
            </View>
            <View style={[styles.amountContainer]}>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["current_aua"]))}
              </Text>
              <TouchableOpacity
                style={styles.viewDetailsContainer}
                onPress={navigateToHoldingsDetails}
              >
                <Text style={styles.viewDetails}>View Details</Text>
                <FontAwesome5
                  name="arrow-circle-right"
                  style={{ fontSize: 18, color: "#410DAA" }}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>

        <View style={styles.detailsContainer}>
          <View style={[styles.billContainer, styles.fieldItem]}>
            <Text style={styles.dividendYield}>Dividend Yeild</Text>
            <View style={[styles.amountContainer]}>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["dividend"]))}
              </Text>
              <TouchableOpacity
                style={styles.viewDetailsContainer}
                onPress={navigateToDividendDetails}
              >
                <Text style={styles.viewDetails}>View Details</Text>
                <FontAwesome5
                  name="arrow-circle-right"
                  style={{ fontSize: 18, color: "#410DAA" }}
                />
              </TouchableOpacity>
            </View>
          </View>
          <Divider />
          <FieldValue
            label={"Total Performance Gains"}
            value={
              "₹" +
              numberWithCommas(
                parseInt(data["current_aua"] - data["total_invested"])
              )
            }
            style={[styles.fieldItem]}
          />
          <Divider />
          <FieldValue
            label={
              "Hurdle to be achieved (" +
              data["discounted_hurdle_perc"].toFixed(2) +
              "% of ₹" +
              numberWithCommas(parseInt(data["total_invested"])) +
              ")"
            }
            value={
              "₹" +
              numberWithCommas(
                parseInt(data["calculated_hurdle_to_be_achieved"])
              )
            }
            style={[styles.fieldItem]}
            labelStyle={[{  }]}
            valueStyle={[{  }]}
          />
          <Divider />
          <FieldValue
            label={"Performance above Hurdle"}
            value={
              "₹" + numberWithCommas(parseInt(data["performance_over_hurdle"]))
            }
            style={[styles.fieldItem]}
          />
          <Divider />
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.textHighlight}>Performance Fees </Text>
              <Text style={styles.textNormal}>
                ({data["discounted_perf_fee_perc"].toFixed(2)}% of ₹
                {numberWithCommas(parseInt(data["performance_over_hurdle"]))})
              </Text>
            </View>
            <Text style={styles.feesAmount}>
              ₹{numberWithCommas(parseInt(data["calculated_performance_fee"]))}
            </Text>
          </View>
          <Divider />
          {data["igst"] ? (
            <FieldValue
              label={"IGST(" + data["igst"].toFixed(1) + "%)"}
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          ) : (
            <FieldValue
              label={
                "CGST(" +
                data["cgst"].toFixed(1) +
                "%) + SGST(" +
                data["sgst"].toFixed(1) +
                "%)"
              }
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          )}
          <Divider />
          <FieldValue
            label={"Final Fee including GST"}
            value={"₹" + numberWithCommas(parseInt(data["Total Payment"]))}
            style={[styles.fieldItem]}
            labelStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
            valueStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
          />
        </View>
        <Text style={styles.descriptionText}>
          Subsequent Advisory Fee has to be paid in six months from the Invoice
          date
        </Text>
      </View>
    </ScrollView>
    ) : (
    <ScrollView style={{ height: height / 2, paddingBottom: 100 }}>
      <View style={styles.boxContainer}>
        <BillHeading data={data} />

        <View style={styles.auaContainer}>
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.committedAua}>{data["box1_field_name"]} </Text>
              <Text style={styles.subDescription}>
                ({data["plan_period_text"]})
              </Text>
            </View>
            {/* <Text style={styles.feesAmount}>
              ₹{numberWithCommas(parseInt(data["committed_aua"]))}
            </Text> */}
          </View>
          <Divider />
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.textHighlight}>Invested Amount </Text>
              {/* <Text style={[styles.textNormal, styles.subDescription]}>
                ({data["plan_period_text"]})
              </Text> */}
            </View>
            <View style={[styles.amountContainer]}>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["total_invested"]))}
              </Text>
              <TouchableOpacity
                style={styles.viewDetailsContainer}
                onPress={navigateToInvestedDetails}
              >
                <Text style={styles.viewDetails}>View Details</Text>
                <FontAwesome5
                  name="arrow-circle-right"
                  style={{ fontSize: 18, color: "#410DAA" }}
                />
              </TouchableOpacity>
            </View>
          </View>
          <Divider />
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.textHighlight}>Current AUA </Text>
              {/* <Text style={[styles.textNormal, styles.subDescription]}>
                ({data["plan_period_text"]})
              </Text> */}
            </View>
            <View style={[styles.amountContainer]}>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["current_aua"]))}
              </Text>
              <TouchableOpacity
                style={styles.viewDetailsContainer}
                onPress={navigateToHoldingsDetails}
              >
                <Text style={styles.viewDetails}>View Details</Text>
                <FontAwesome5
                  name="arrow-circle-right"
                  style={{ fontSize: 18, color: "#410DAA" }}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>

        <View style={styles.detailsContainer}>
          <View style={[styles.billContainer, styles.fieldItem]}>
            <Text style={styles.dividendYield}>Dividend Yeild</Text>
            <View style={[styles.amountContainer]}>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["dividend"]))}
              </Text>
              <TouchableOpacity
                style={styles.viewDetailsContainer}
                onPress={navigateToDividendDetails}
              >
                <Text style={styles.viewDetails}>View Details</Text>
                <FontAwesome5
                  name="arrow-circle-right"
                  style={{ fontSize: 18, color: "#410DAA" }}
                />
              </TouchableOpacity>
            </View>
          </View>
          <Divider />
          <FieldValue
            label={"Total Performance Gains"}
            value={
              "₹" +
              numberWithCommas(
                parseInt(data["current_aua"] - data["total_invested"])
              )
            }
            style={[styles.fieldItem]}
          />
          <Divider />
          <FieldValue
            label={
              "Hurdle to be achieved (" +
              data["discounted_hurdle_perc"].toFixed(2) +
              "% of ₹" +
              numberWithCommas(parseInt(data["total_invested"])) +
              ")"
            }
            value={
              "₹" +
              numberWithCommas(
                parseInt(data["calculated_hurdle_to_be_achieved"])
              )
            }
            style={[styles.fieldItem]}
            labelStyle={[{  }]}
            valueStyle={[{  }]}
          />
          <Divider />
          <FieldValue
            label={"Performance above Hurdle"}
            value={
              "₹" + numberWithCommas(parseInt(data["performance_over_hurdle"]))
            }
            style={[styles.fieldItem]}
          />
          <Divider />
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.textHighlight}>Performance Fees </Text>
              <Text style={styles.textNormal}>
                ({data["discounted_perf_fee_perc"].toFixed(2)}% of ₹
                {numberWithCommas(parseInt(data["performance_over_hurdle"]))})
              </Text>
            </View>
            <Text style={styles.feesAmount}>
              ₹{numberWithCommas(parseInt(data["calculated_performance_fee"]))}
            </Text>
          </View>
          <Divider />
          {data["igst"] ? (
            <FieldValue
              label={"IGST(" + data["igst"].toFixed(1) + "%)"}
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          ) : (
            <FieldValue
              label={
                "CGST(" +
                data["cgst"].toFixed(1) +
                "%) + SGST(" +
                data["sgst"].toFixed(1) +
                "%)"
              }
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          )}
          <Divider />
          <FieldValue
            label={"Final Fee including GST"}
            value={"₹" + numberWithCommas(parseInt(data["Total Payment"]))}
            style={[styles.fieldItem]}
            labelStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
            valueStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
          />
        </View>
        <Text style={styles.descriptionText}>
          Subsequent Advisory Fee has to be paid in six months from the Invoice
          date
        </Text>
      </View>
    </ScrollView>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  boxContainer: {
    borderWidth: 0,
    borderColor: "#410DAA50",
    borderRadius: 15,
    marginVertical: isMobile ? 0 : 28,
    marginHorizontal: isMobile ? 25 : 35,
    paddingVertical: 27,
    paddingHorizontal: isMobile ? 0 : 39,
    minWidth: 800,
  },
  billBasicDetailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headingContainer: {},
  majorHeadingContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  majorHeadingText: {
    color: "#464E5F",
    fontSize: 17,
    fontWeight: "bold",
  },
  feePercentageText: {
    color: "#6C7293",
    fontSize: 13,
    marginLeft: 6,
  },
  feePercentage: {
    color: "#464E5F",
    fontSize: 13,
    fontWeight: "700",
    paddingLeft: 4,
  },
  descriptionContainer: {
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "flex-start",
    marginTop: 10,
    marginLeft: 4,
    flexWrap: "wrap",
  },
  descriptionText: {
    color: "#6C7293",
    fontSize: 12,
    marginTop: 14,
  },
  descriptionSubContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  labelStyle: {
    fontSize: isMobile ? 11 : 13,
    color: "#4A4B68",
    paddingRight: 4,
  },
  valueStyle: { fontSize: isMobile ? 11 : 13, color: "#4A4B68" },
  fieldContainer: { justifyContent: "center" },
  planNumber: {
    color: "#4A4B68",
    fontSize: 11,
    marginLeft: 7,
  },
  auaContainer: {
    marginTop: 27,
    borderWidth: 1,
    borderColor: "#DBC0FF",
    borderRadius: 10,
  },
  detailsContainer: {
    marginTop: 21,
    borderWidth: 1,
    borderColor: "#DBC0FF",
    borderRadius: 10,
  },
  billContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 17.5,
  },
  responsiveContainer: {
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
  },
  advisoryHeading: {
    color: "#464E5F",
    fontSize: 15,
    fontWeight: "bold",
  },
  billingPeriod: {
    color: "#464E5F",
    fontSize: 15,
    fontWeight: "400",
    marginTop: 10,
  },
  committedAua: {
    fontSize: 15,
    color: "#464E5F",
  },
  subDescription: {
    fontSize: 12,
    fontWeight: "400",
    marginTop: isMobile ? 4 : 0,
  },
  amountContainer: {
    alignItems: "flex-end",
  },
  feesAmount: {
    fontSize: 15,
    color: "#464E5F",
    fontWeight: "bold",
  },
  viewDetailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  viewDetails: {
    color: "#6C7293",
    fontSize: 14,
    marginRight: 10,
  },
  fieldItem: {
    paddingHorizontal: isMobile ? 16 : 75,
  },
  dividendYield: {
    fontSize: 14,
    color: "#464E5F",
  },
  textNormal: {
    fontSize: isMobile ? 12 : 15,
    color: "#464E5F",
    fontWeight: "400",
    marginTop: isMobile ? 4 : 0,
  },
  textHighlight: {
    fontWeight: "bold",
  },
  bold: {
    fontWeight: "bold",
  },
});

export default PerformanceBill;
