import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  FlatList,
  Platform,
} from "react-native";
import { md } from "../../../Responsive";
import {
  updatePaymentStatusOrderSummary,
  getOrderSummary,
  makePaymentOrderSummary,
} from "../../../api";
import Header from "../header_after_login";
import { ActivityIndicator } from "react-native-paper";
const { width } = Dimensions.get("window");
import Progressbar from "../progressbar";
import {
  completeMinDetails,
  isMobile,
  isWeb,
  numberWithCommas,
} from "../../../utils";
import Modal from "../../../components/Common/Modal";
import AntDesign from "@expo/vector-icons/AntDesign";
import { StackActions } from "@react-navigation/native";
import { getServerName } from "../../../api/urls";
import Store from "../../../store";

export default class succesful_onbording extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 5,
      data: null,
      showTimer: false,
      isLoading: true,
      showAlert: false,
    };
  }

  showButton = () => {
    this.interval = setInterval(
      () => this.setState((prevState) => ({ timer: prevState.timer - 1 })),
      1000
    );

    this.redirect = setTimeout(() => {
      this.moveToNextScreen();
    }, 2000);
  };

  componentDidUpdate() {
    if (this.state.timer === 0) {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    this.interval && clearInterval(this.interval);
    this.redirect && clearTimeout(this.redirect);
  }

  moveToNextScreen = async () => {
    try {
      this.setState({ isLoading: true });
      const minDetailsResponse = await completeMinDetails();
      const { needCompletion, error } = minDetailsResponse;

      if (!error) {
        const { redirect_screen } = minDetailsResponse;
        let options = {};
        if (this.props.route) {
          const { params } = this.props.route;
          if (params) {
            options = { ...params };
          }
          if (!needCompletion) {
            const { actions = {} } = Store.getState().transactions;
            const { actionId = null } = params;
            if (actionId in actions) {
              const {
                stack = null,
                screen = null,
                ...rest
              } = actions[actionId];
              if (stack && screen) {
                this.props.navigation.dispatch(
                  StackActions.replace("App", {
                    screen: "Dashboard",
                    params: {
                      screen: stack,
                      params: { screen: screen, params: { ...rest } },
                    },
                  })
                );
                return;
              }
            }
            if (params && params.nvg) {
              this.props.navigation.dispatch(StackActions.replace(params.nvg));
              return;
            }
          } else {
            this.props.navigation.dispatch(
              StackActions.replace("Onboarding", {
                screen: redirect_screen,
                params: { ...options },
              })
            );
            return;
          }
        }

        this.props.navigation.dispatch(
          StackActions.replace("App", {
            screen: "Dashboard",
            params: {
              screen: "Portfolio",
              params: {
                screen: redirect_screen,
                params: {},
              },
            },
          })
        );
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Something went wrong! Please contact help@maxiom.in",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  async componentDidMount() {
    try {
      const paymentRespnse = await updatePaymentStatusOrderSummary();
      if (
        (paymentRespnse && paymentRespnse.code == 100) ||
        (paymentRespnse.code == "101" &&
          paymentRespnse.msg == "Transaction Successful")
      ) {
        this.setState(
          {
            isLoading: false,
            showSuccessResponse: true,
            showTimer: true,
          },
          () => {
            this.showButton();
            return;
          }
        );
        return;
      }
      const response = await getOrderSummary();
      let orderSummary = {
        plansData: [],
      };

      if (response && response.code == 100) {
        const { results } = response;
        if (results && results.length > 0) {
          const {
            results: plans,
            igst_amount,
            amount_to_pay,
            total_amount_to_pay,
            igst,
            cgst_amount,
            sgst_amount,
            cgst,
            sgst,
          } = results[0];
          orderSummary["sgst_amount"] = sgst_amount;
          orderSummary["cgst_amount"] = cgst_amount;
          orderSummary["cgst"] = cgst;
          orderSummary["sgst"] = sgst;

          orderSummary["igst_amount"] = igst_amount;
          orderSummary["amount_to_pay"] = amount_to_pay;
          orderSummary["total_amount_to_pay"] = total_amount_to_pay;
          orderSummary["igst"] = igst;
          plans.forEach((item) => {
            let detail = {};
            const {
              results: plan_data,
              amount_paid,
              amount_due,
              invoice_number,
              invoice_date,
              amount,
            } = item;
            detail["amount"] = amount;
            detail["amount_paid"] = amount_paid;
            detail["amount_due"] = amount_due;
            detail["invoice_number"] = invoice_number;
            detail["invoice_date"] = invoice_date;

            const { customerinvestmentplan, plan_text, is_paid } = plan_data[0];
            detail["customerinvestmentplan"] = customerinvestmentplan;
            detail["plan_text"] = plan_text;
            detail["is_paid"] = is_paid;
            if (amount_due) {
              orderSummary["plansData"].push(detail);
            }
          });
        }
        this.setState({ orderSummary, isLoading: false, showAlert: true });
      } else {
        this.setState({
          error: true,
          errorText: "Something went wrong!",
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    }
  }

  handleContinue = async () => {
    try {
      this.setState({ gettingPaymentLink: true });
      const server_name = getServerName();
      const payload = {
        server_name,
      };
      const response = await makePaymentOrderSummary(payload);
      if (response && response.code == 100) {
        const { results } = response;
        if (results && results.length > 0) {
          const { paymentLink } = results[0];
          if (Platform.OS == "web") {
            global.open(paymentLink, "_self");
          } else {
            const { params } = this.props.route;
            let options = {};
            if (params) {
              options = { ...params };
            }
            this.props.navigation.navigate("App", {
              screen: "Dashboard",
              params: {
                screen: "Portfolio",
                params: {
                  screen: "ShowWebView",
                  params: {
                    url: paymentLink,
                    successURL: "onboarding/order/paymentsuccess",
                    onSuccess: function (navigation, results = {}) {
                      navigation.push("Onboarding", {
                        screen: "PaymentSuccess",
                        params: { ...options },
                      });
                    },
                    onError: function (navigation, results = {}) {
                      // navigation.push("App", { screen: "Dashboard", params: { screen: "Home", params: {} } });
                    },
                  },
                },
              },
            });
          }
        } else {
          this.setState({
            error: true,
            errorText: "Something went wrong!",
            gettingPaymentLink: false,
          });
        }
      } else {
        this.setState({
          error: true,
          errorText: "Something went wrong!",
          gettingPaymentLink: false,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        gettingPaymentLink: false,
      });
    }
  };

  renderPlansDetails = ({ item }) => {
    const isMobile = md(width);
    let { plan_text } = item;
    plan_text = plan_text.replace("<br>", "");
    return (
      <>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            backgroundColor: "#F3F6F9",
            borderRadius: 0,
            marginBottom: isMobile ? 0 : 30,
            paddingVertical: 12,
            paddingHorizontal: isMobile ? 15 : 10,
            justifyContent: isMobile ? "flex-start" : "center",
            marginTop: isMobile ? 0 : 30,
          }}
        >
          <View style={{ flex: 0.4 }}>
            <Text style={styles.invoice_number}>{item.invoice_number}</Text>
            <Text style={styles.invoice_date}>{item.invoice_date}</Text>
          </View>
          <Text
            style={[
              styles.plan,
              {
                flex: 0.2,
                textAlign: "center",
              },
            ]}
          >
            Total
          </Text>
          <Text
            style={[
              styles.plan,
              { flex: 0.2, textAlign: isMobile ? "center" : "center" },
            ]}
          >
            Paid
          </Text>
          <Text
            style={[
              styles.plan,
              { flex: 0.2, textAlign: isMobile ? "center" : "center" },
            ]}
          >
            Due
          </Text>
        </View>
        <View style={{ paddingVertical: isMobile ? 0 : 20 }}>
          <View
            style={{
              flex: 1,
              display: md(width) ? "flex" : "flex",
              flexDirection: "row",
              paddingBottom: isMobile ? 0 : 20,
              paddingTop: isMobile ? 0 : 20,
              paddingLeft: 5,
            }}
          >
            <View style={{ flex: 0.4 }}>
              <Text style={[styles.plan_item, { flex: 2 }]}>{plan_text}</Text>
            </View>
            <Text style={[styles.inv_amt, { flex: 0.2, textAlign: "center" }]}>
              ₹{numberWithCommas(item.amount)}
            </Text>
            <Text style={[styles.inv_amt, { flex: 0.2, textAlign: "center" }]}>
              ₹{numberWithCommas(item.amount_paid)}
            </Text>
            <Text style={[styles.inv_amt, { flex: 0.2, textAlign: "center" }]}>
              ₹{numberWithCommas(item.amount_due)}
            </Text>
          </View>
        </View>
      </>
    );
  };

  render() {
    const {
      timer,
      showTimer,
      isLoading,
      errorText,
      orderSummary,
      showAlert,
      showSuccessResponse,
    } = this.state;
    return (
      <View style={styles.container}>
        <Header navigation={this.props.navigation} route={this.props.route} />
        {showAlert && (
          <Modal style={[isMobile ? styles.modalMobile : styles.modalWeb]}>
            <View
              style={{
                paddingTop: 37,
                paddingBottom: 23,
                paddingHorizontal: 33,
                width: isMobile ? "98%" : 633,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={styles.payment}>Payment Failed !!</Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showAlert: false });
                  }}
                >
                  <AntDesign
                    name="close"
                    size={24}
                    color="rgba(0, 0, 0, 0.5)"
                  />
                </TouchableOpacity>
              </View>
              <Text style={styles.comp}>
                You have not completed your payment. Please try again (or) Get
                Help for more information
              </Text>
              <TouchableOpacity
                style={{ alignItems: "center", justifyContent: "center" }}
                onPress={this.handleContinue}
              >
                <Text style={styles.retry}>Retry Payment</Text>
              </TouchableOpacity>
            </View>
          </Modal>
        )}
        <Progressbar
          color2="#410DAA"
          color3="#410daa"
          color4="#410DAA"
          color5="#410DAA"
          color6="#410DAA"
          color7="#410DAA"
          color8="#410DAA"
          color9="#410DAA"
          color10="#410DAA"
          color11="#f6f6f6"
          color12="#f6f6f6"
          bcolor1="#410DAA"
          bcolor2="#410DAA"
          bcolor3="#410DAA"
          bcolor4="#410DAA"
          bcolor5="#410DAA"
          bagcolor1="#410DAA"
          bagcolor2="#410DAA"
          bagcolor3="#410DAA"
          bagcolor4="#410DAA"
          bagcolor5="#410DAA"
          markdisplay1="none"
          markdisplay2="none"
          markdisplay3="none"
          markdisplay4="none"
          markdisplay5="none"
          dotdisplay1="none"
          dotdisplay2="none"
          dotdisplay3="none"
          dotdisplay4="none"
          dotdisplay5="none"
        />
        {!isLoading ? (
          <ScrollView style={styles.mainContainer}>
            {showSuccessResponse ? (
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ffffff",
                  paddingBottom: md(width) ? 90 : 200,
                  paddingHorizontal: md(width) ? 0 : 19,
                }}
              >
                <Text style={styles.you_have}>
                  You have Successfully completed your Payment
                </Text>
                <Image
                  source={{
                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/money_transfer_-2+1.png",
                  }}
                  style={styles.img}
                />
                <Text style={styles.errorText}>{errorText || " "}</Text>
                {timer != null && (
                  <>
                    {showTimer && (
                      <Text style={styles.redirect}>
                        Redirecting in{" "}
                        <Text
                          style={{ color: "lightblue", fontWeight: "bold" }}
                        >
                          {timer} sec
                        </Text>
                      </Text>
                    )}
                    <TouchableOpacity onPress={this.moveToNextScreen}>
                      <View>
                        <Text style={styles.cont}>Continue</Text>
                      </View>
                    </TouchableOpacity>
                  </>
                )}
                {isLoading && (
                  <ActivityIndicator size="large" color="#410DAA" />
                )}
              </View>
            ) : (
              <View style={styles.body}>
                {isWeb && (
                  <View style={styles.left}>
                    <Text style={styles.terms}>Payment</Text>
                    <Text style={styles.please}>
                      Per SEBI regulation, payment must be made before advisor
                      provides service
                    </Text>
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-2.png"
                      }
                      style={styles.left_img}
                    />
                    <View
                      style={{
                        flexDirection: "row",
                        width: 430,
                        borderWidth: 1,
                        borderColor: "rgba(65, 13, 170, 0.2)",
                        backgroundColor: "#ffffff",
                        paddingVertical: md(width) ? 21 : 8.5,
                        paddingLeft: 20,
                        paddingRight: 6,
                        marginTop: 33,
                        marginBottom: 22,
                      }}
                    >
                      <Image
                        source={
                          "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/fluent_lock-shield-20-regular.png"
                        }
                        style={styles.lock}
                      />
                      <Text style={styles.jam_we}>
                        Information is encrypted using 256-bit keys and stored
                        securely
                      </Text>
                    </View>
                    <Text style={styles.any}>
                      Any Queries?{" "}
                      <TouchableOpacity
                        onPress={() => {
                          this.props.navigation.navigate("App", {
                            screen: "Dashboard",
                            params: {
                              screen: "Dashboard",
                              params: {
                                screen: "GetHelp",
                                params: {},
                              },
                            },
                          });
                        }}
                      >
                        <Text style={{ color: "#410DAA", fontWeight: "bold" }}>
                          Get Help
                        </Text>
                      </TouchableOpacity>
                    </Text>
                  </View>
                )}
                <View style={styles.right}>
                  {orderSummary ? (
                    <View style={{ flexDirection: "column" }}>
                      <Text style={styles.investment}>Order Summary </Text>
                      <FlatList
                        data={orderSummary.plansData}
                        renderItem={this.renderPlansDetails}
                        keyExtractor={(item, index) =>
                          `pending_payment_${index}`
                        }
                        ItemSeparatorComponent={() => {
                          return <View />;
                        }}
                      />
                      <View style={styles.tot_bot}>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text style={styles.tot_amt}>Total Amount</Text>
                          <Text style={styles.tot_amt1}>
                            ₹{orderSummary["amount_to_pay"]}
                          </Text>
                        </View>
                        {!!orderSummary["igst_amount"] && (
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text style={styles.tot_amt}>
                              IGST{" "}
                              {orderSummary["igst"]
                                ? `(${orderSummary["igst"].toFixed(1)}%)`
                                : ""}
                            </Text>
                            <Text style={styles.tot_amt1}>
                              ₹{orderSummary["igst_amount"] || 0}
                            </Text>
                          </View>
                        )}

                        {!!orderSummary["cgst_amount"] && (
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text style={styles.tot_amt}>
                              CGST{" "}
                              {orderSummary["cgst"]
                                ? `(${orderSummary["cgst"].toFixed(1)}%)`
                                : ""}
                            </Text>
                            <Text style={styles.tot_amt1}>
                              ₹{orderSummary["cgst_amount"] || 0}
                            </Text>
                          </View>
                        )}

                        {!!orderSummary["sgst_amount"] && (
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text style={styles.tot_amt}>
                              SGST{" "}
                              {orderSummary["sgst"]
                                ? `(${orderSummary["sgst"].toFixed(1)}%)`
                                : ""}
                            </Text>
                            <Text style={styles.tot_amt1}>
                              ₹{orderSummary["sgst_amount"] || 0}
                            </Text>
                          </View>
                        )}

                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text style={styles.gra_tot}>Grand total</Text>
                          <Text style={styles.gra_tot}>
                            ₹{orderSummary["total_amount_to_pay"]}
                          </Text>
                        </View>
                      </View>
                      <TouchableOpacity
                        onPress={() => {
                          this.handleContinue();
                        }}
                      >
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            paddingTop: 40,
                          }}
                        >
                          {this.state.gettingPaymentLink ? (
                            <ActivityIndicator size="large" color="#410DAA" />
                          ) : (
                            <Text style={styles.make_pay}>Make Payment</Text>
                          )}
                        </View>
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <View style={styles.noContent}>
                      <Text style={styles.nothingFound}>Nothing found!</Text>
                    </View>
                  )}
                </View>
              </View>
            )}
          </ScrollView>
        ) : (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1, backgroundColor: "#fff" },
  img: {
    width: md(width) ? 400 : 252,
    height: md(width) ? 350 : 198,
  },
  you_have: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 20 : 15,
    lineHeight: 21,
    color: "#000000",
    fontWeight: "bold",
    paddingTop: md(width) ? 50 : 32,
    paddingBottom: 17,
    fontWeight: "bold",
    textAlign: md(width) ? "undefined" : "center",
  },
  completed: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 15 : 13,
    lineHeight: md(width) ? 17 : 21,
    color: "#121212",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: md(width) ? 38 : 32,
  },
  cont: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  redirect: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    textAlign: "center",
    color: "rgba(18, 18, 18, 0.4)",
    paddingBottom: 30,
  },
  errorText: {
    color: "red",
    fontSize: 15,
    marginVertical: 15,
  },
  container: { width: "100%", flexGrow: 1 },
  mainContainer: {
    backgroundColor: "#fff",
  },
  loadingContainer: { flex: 1, justifyContent: "center", alignItems: "center" },
  noContent: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  nothingFound: {
    fontSize: 15,
    color: "#121212",
  },
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: !isMobile ? 102 : 13,
    paddingRight: !isMobile ? 88 : 13,
    paddingBottom: 60,
  },
  left: {
    flex: 0.45,
    paddingTop: 55,
    display: md(width) ? "none" : "none",
  },
  right: {
    flex: !isMobile ? 0.55 : 1,
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },
  lock: {
    resizeMode: "contain",
    width: 24.5,
    height: 34,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 22,
    lineHeight: 25,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
    paddingTop: 11,
    paddingBottom: 32,
    paddingRight: 160,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  investment: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    fontWeight: "bold",
    color: "#2F3044",
    textAlign: "center",
    paddingTop: 50,
    paddingBottom: 27,
  },

  plan: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 14 : 13,
    lineHeight: md(width) ? 16 : 15,
    color: "#464E5F",
  },
  plan_item: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    color: "#4A4B68",
    letterSpacing: 0.5,
    paddingRight: 25,
  },
  invoice_number: {
    fontSize: 14,
    color: "#8950FC",
    fontWeight: "bold",
  },
  invoice_date: {
    fontSize: 14,
    color: "#464E5F",
    fontWeight: "bold",
  },
  inv_amt: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#4A4B68",
    fontWeight: "normal",
  },
  inv_amt1: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#4A4B68",
    paddingTop: 9,
  },
  date: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#737178",
    letterSpacing: 0.5,
    borderBottomWidth: 1,
    //borderBottomRadius: 6,
    borderBottomColor: "#E5EAEE",
    paddingBottom: 10,
  },
  inv_num: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 14 : 13,
    lineHeight: md(width) ? 16 : 21,
    color: "#8950FC",
    letterSpacing: 0.5,
    fontWeight: "bold",
    paddingTop: 21,
    paddingBottom: 4,
  },
  tot_bot: {
    borderTopColor: "#E5EAEE",
    borderBottomColor: "#E5EAEE",
    borderRadius: 6,
    //borderBottomRadius: 6,
    borderTopWidth: md(width) ? 8 : 3,
    borderBottomWidth: md(width) ? 8 : 3,
    paddingVertical: 15,
    paddingHorizontal: md(width) ? 70 : 0,
    paddingLeft: md(width) ? 0 : 15,
    paddingRight: md(width) ? 0 : 43,
    marginTop: md(width) ? 20 : 0,
  },
  tot_amt: {
    fontFamily: "Roboto",
    fontWeight: md(width) ? "bold" : "400",
    lineHeight: 15,
    fontSize: md(width) ? 14 : 13,
    letterSpacing: 0.32,
    color: "#464E5F",
  },
  tot_amt1: {
    fontFamily: "Roboto",
    lineHeight: md(width) ? 16 : 15,
    fontSize: md(width) ? 15 : 13,
    letterSpacing: 0.32,
    color: "#464E5F",
    paddingTop: 11,
  },
  gra_tot: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: md(width) ? 16 : 15,
    fontSize: md(width) ? 15 : 13,
    letterSpacing: 0.32,
    color: "#000000",
    paddingTop: 14,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingHorizontal: 44,
    paddingVertical: 12,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  mob_plan: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    fontWeight: "bold",
    color: "#4A4B68",
  },
  mob_year: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#4A4B68",
    paddingTop: 9,
  },
  mob_top_pybl: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    fontWeight: "bold",
    letterSpacing: 0.32,

    textAlign: "center",
    flex: 1,
  },
  tt_amt: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 16,
    color: "#121212",
  },
  modalMobile: {
    position: "absolute",
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  modalWeb: {
    position: "absolute",
    // position: "absolute",
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  retry: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingHorizontal: 44,
    paddingVertical: 11,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  payment: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    color: "#F64E60",
    fontWeight: "bold",
  },
  comp: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 15 : 18,
    lineHeight: 21,
    color: "#121212",
    paddingTop: 17,
    paddingBottom: 26,
  },
});
