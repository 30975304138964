import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";

const TodayCost = ({ title, subtitle, value, style }) => {
    return (
      <View
        style={[
          {
            flexDirection: "row",
            marginTop: 15
          },
          style
        ]}
      >
        <Text
          style={{
            color: "#121212",
            textAlign: "left",
            flex: 0.6
          }}
        >
          {title} <Text style={{ fontSize: 10 }}>{subtitle || ""}</Text>
        </Text>
        <Text
          style={{
            fontSize: 14,
            textAlign: "right",
            flex: 0.4
          }}
        >
          {value}
        </Text>
      </View>
    );
};

export default TodayCost;