const validation_regex = {
    email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    text: /([^\s])/,
    phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
    number: /^\d+\.\d+$/,
    pan: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
    pincode: /^\d{6}$/,
    ifsc: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
    bankaccount: /^\d{9,18}$/
  };
  
  export const validateInput = (type, value) => {
    if (type == "select") return value != "SEL";
    return RegExp(validation_regex[type]).test(value);
  };
  
  export const getInputErrorText = type => {
    const errorTexts = {
      email: "Enter valid email id",
      phone: "Enter valid phone number",
      text: "Enter valid input",
      select: "Select valid option",
      pan: "Enter valid PAN",
      number: "Enter valid number",
      pincode: "Enter valid pincode",
      ifsc: "Enter valid code",
      bankaccount: "Enter valid account number"
    };
    return errorTexts[type] || "";
  };
  
  export const getKeyboardType = type => {
    const keyboardTypes = {
      email: "email-address",
      phone: "phone-pad",
      pincode: "phone-pad"
    };
    return type ? keyboardTypes[type] || "default" : "default";
  };
  