import { GET_EXPLORE, LOGOUT } from "../actions/types";

const initialState = {
  exploreResp: null,
  transactionPopUpResponse: null,
};

const exploreReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPLORE:
      return {
        ...state,
        exploreResp: {
          ...action.payload.exploreResp,
        },
        transactionPopUpResponse: {
          ...action.payload.transactionPopUpResponse,
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default exploreReducer;
