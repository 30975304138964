import React from "react";
import amplitude from "../../../utils/amplitude";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  TouchableHighlight,
  FlatList,
  Image,
  Platform,
  ActivityIndicator,
} from "react-native";
import { md } from "../../../Responsive";
import NavigationHeader from "../../Common/NavigationHeader";
import { Entypo, MaterialIcons, AntDesign } from "@expo/vector-icons";
import { PieChartDashboard } from "../../InvestmentOverview/PieChartDashboard";
import {
  getMyEquityInvestmentPlans,
  getStartNewPlanContern,
} from "../../../api";
import {
  formatDate,
  getProductName,
  isMobile,
  numberWithCommas,
  toFixed,
} from "../../../utils";
import ModalComponent from "../../Common/Modalize";
import { apiMainURL } from "../../../api/urls";
import Store from "../../../store";

const { width } = Dimensions.get("window");
const isWeb = md(width);

const PlanDetailsField = ({ fieldName, value, style = {} }) => {
  return (
    <View style={{ flexDirection: "row", ...style }}>
      {isWeb && <View style={styles.line}></View>}
      <View>
        <Text style={styles.invested_amount}>{value}</Text>
        <Text style={styles.invested}>{fieldName}</Text>
      </View>
    </View>
  );
};

const PlanDetails = ({
  item,
  showPieChart,
  isCompleted,
  navigation,
  userId = null,
}) => {
  const isWeb = md(width);
  const data = [
    {
      title: "Invested",
      value: item["invested_amount"],
      color: "#FEBE37",
      legendFontColor: "#FEBE37",
      legendFontSize: 15,
      titleValue: `₹ ${numberWithCommas(toFixed(item["invested_amount"], 2))}`,
    },
    {
      title: "Yet to Invest",
      value: item["amount_yet_to_invest"],
      color: "#E3E3E3",
      legendFontColor: "#E3E3E3",
      legendFontSize: 15,
      titleValue: `₹ ${numberWithCommas(
        toFixed(item["amount_yet_to_invest"], 2)
      )}`,
    },
  ];
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: isWeb ? 30 : 15,
        justifyContent: "space-between",
        flexWrap: "wrap",
        borderBottomWidth: isWeb ? 1 : 0,
        borderBottomColor: "#E5EAEE",
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {isWeb && (
          <View style={{ paddingLeft: isWeb ? 20 : 0, minWidth: 160 }}>
            <Text
              style={{
                fontSize: 13,
                fontFamily: "Roboto",
                color: "#464E5F",
                fontWeight: "bold",
              }}
            >
              {item.plan_code} -{" "}
              <Text style={{ fontWeight: "normal" }}>{item.plan_duration}</Text>
            </Text>
            <Text style={styles.amount}>
              ₹ {numberWithCommas(item.amount.toFixed(2))}{" "}
              {isCompleted && <Text style={styles.type}>{item.type}</Text>}
            </Text>
            {!isCompleted && <Text style={styles.type}>{item.type}</Text>}
          </View>
        )}
        {showPieChart && (
          <PieChartDashboard
            width={66}
            height={60}
            style={{}}
            data={data}
            chartConfig={chartConfig}
            accessor={"value"}
          />
        )}
      </View>
      {showPieChart && !isWeb && (
        <View
          style={{
            backgroundColor: "#00000010",
            width: "100%",
            height: 1,
            marginBottom: 15,
          }}
        ></View>
      )}
      {isCompleted ? (
        !isMobile ? (
          <>
            <PlanDetailsField
              fieldName={"Invested"}
              value={
                item.invested_amount
                  ? `₹${numberWithCommas(toFixed(item.invested_amount))}`
                  : "-"
              }
              style={{ paddingLeft: isWeb ? 0 : 15 }}
            />
            <PlanDetailsField
              fieldName={"Valuation"}
              value={
                item.valuation_amount
                  ? `₹${numberWithCommas(toFixed(item.valuation_amount, 2))}`
                  : "-"
              }
            />
            <PlanDetailsField
              fieldName={"Absolute Growth"}
              value={
                item.absolute_growth
                  ? `${toFixed(item.absolute_growth, 1)} %`
                  : "-"
              }
              style={{ paddingRight: isWeb ? 0 : 15 }}
            />
            <PlanDetailsField
              fieldName={"XIRR %"}
              value={item.plan_xirr ? `${toFixed(item.plan_xirr, 1)} %` : "-"}
              style={{ paddingRight: isWeb ? 0 : 15 }}
            />
          </>
        ) : (
          <View
            style={{ display: "flex", width: "100%", flexDirection: "column" }}
          >
            <View style={{ flexDirection: "row" }}>
              <View
                style={{ alignItems: "flex-start", flex: 6, marginLeft: 10 }}
              >
                <Text style={styles.invested_amount}>
                  {item.invested_amount
                    ? `₹${numberWithCommas(toFixed(item.invested_amount))}`
                    : "-"}
                </Text>
                <Text style={styles.invested}>Invested</Text>
              </View>
              <View
                style={{ alignItems: "flex-end", flex: 6, marginRight: 10 }}
              >
                <Text style={styles.invested_amount}>
                  {item.valuation_amount
                    ? `₹${numberWithCommas(toFixed(item.valuation_amount, 2))}`
                    : "-"}
                </Text>
                <Text style={styles.invested}>Valuation</Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <View
                style={{ alignItems: "flex-start", flex: 6, marginLeft: 10 }}
              >
                <Text style={styles.invested_amount}>
                  {item.absolute_growth
                    ? `${toFixed(item.absolute_growth, 1)} %`
                    : "-"}
                </Text>
                <Text style={styles.invested}>Absolute Growth</Text>
              </View>
              <View
                style={{ alignItems: "flex-end", flex: 6, marginRight: 10 }}
              >
                <Text style={styles.invested_amount}>
                  {item.plan_xirr ? `${toFixed(item.plan_xirr, 1)} %` : "-"}
                </Text>
                <Text style={styles.invested}>XIRR %</Text>
              </View>
            </View>
          </View>
        )
      ) : !isMobile ? (
        <>
          <PlanDetailsField
            fieldName={"Latest value"}
            value={
              item.valuation_amount
                ? `₹${numberWithCommas(toFixed(item.valuation_amount, 2))}`
                : "-"
            }
            style={{ paddingLeft: isWeb ? 0 : 15 }}
          />
          <PlanDetailsField
            fieldName={"Dividend"}
            value={
              item.dividend
                ? `₹${numberWithCommas(toFixed(item.dividend, 2))}`
                : "-"
            }
          />
          <PlanDetailsField
            fieldName={"Absolute Growth"}
            value={
              item.absolute_growth
                ? `${toFixed(item.absolute_growth, 1)} %`
                : "-"
            }
            style={{ paddingRight: isWeb ? 0 : 15 }}
          />
          <PlanDetailsField
            fieldName={"XIRR %"}
            value={item.plan_xirr ? `${toFixed(item.plan_xirr, 1)} %` : "-"}
            style={{ paddingRight: isWeb ? 0 : 15 }}
          />
        </>
      ) : (
        <View
          style={{ display: "flex", width: "100%", flexDirection: "column" }}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={{ alignItems: "flex-start", flex: 6, marginLeft: 10 }}>
              <Text style={styles.invested_amount}>
                {item.valuation_amount
                  ? `₹${numberWithCommas(toFixed(item.valuation_amount, 2))}`
                  : "-"}
              </Text>
              <Text style={styles.invested}>Latest value</Text>
            </View>
            <View style={{ alignItems: "flex-end", flex: 6, marginRight: 10 }}>
              <Text style={styles.invested_amount}>
                {item.dividend
                  ? `₹${numberWithCommas(toFixed(item.dividend, 2))}`
                  : "-"}
              </Text>
              <Text style={styles.invested}>Dividend</Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 20 }}>
            <View style={{ alignItems: "flex-start", flex: 6, marginLeft: 10 }}>
              <Text style={styles.invested_amount}>
                {item.absolute_growth
                  ? `${toFixed(item.absolute_growth, 1)} %`
                  : "-"}
              </Text>
              <Text style={styles.invested}>Absolute Growth</Text>
            </View>
            <View style={{ alignItems: "flex-end", flex: 6, marginRight: 10 }}>
              <Text style={styles.invested_amount}>
                {item.plan_xirr ? `${toFixed(item.plan_xirr, 1)} %` : "-"}
              </Text>
              <Text style={styles.invested}>XIRR %</Text>
            </View>
          </View>
        </View>
      )}

      {isWeb && (
        <View style={{}}>
          {item.next_installment_amount == null ||
          item.next_installment_date == null ? (
            <View style={{ marginRight: 15 }}>
              <Text style={styles.invest_of}>
                {item.no_of_installments
                  ? `${item.no_of_installments} installments done`
                  : ""}
              </Text>
              <TouchableHighlight
                onPress={() => {
                  navigation.navigate("ManageSchedule", {
                    id: item.cip_pk,
                    user_id: userId,
                  });
                }}
              >
                <Text
                  style={{
                    textDecorationLine: "none",
                    fontSize: 11,
                    color: "#410DAA",
                    marginTop: 5,
                  }}
                ></Text>
              </TouchableHighlight>
            </View>
          ) : (
            <View style={{ marginRight: 16 }}>
              {item.type == "Lumpsum" ? (
                <>
                  <Text style={styles.invest_of}>
                    Investment of{" "}
                    <Text style={styles.inve_amt}>
                      ₹
                      {item.invested_amount
                        ? numberWithCommas(item.invested_amount)
                        : "-"}
                    </Text>{" "}
                  </Text>
                  <Text style={styles.invest_of}>
                    made on{" "}
                    <Text style={styles.inve_amt}>
                      {formatDate(item.investment_made_on) || "-"}
                    </Text>
                  </Text>
                  <TouchableHighlight
                    onPress={() => {
                      navigation.navigate("ManageSchedule", {
                        id: item.cip_pk,
                      });
                    }}
                  >
                    <Text
                      style={{
                        textDecorationLine: "none",
                        fontSize: 11,
                        color: "#410DAA",
                        marginTop: 5,
                      }}
                    ></Text>
                  </TouchableHighlight>
                </>
              ) : (
                <>
                  <Text style={styles.invest_of}>
                    Next Installment of{" "}
                    <Text style={styles.inve_amt}>
                      ₹
                      {item.next_installment_amount
                        ? numberWithCommas(
                            item.next_installment_amount.toFixed(2)
                          )
                        : "-"}
                    </Text>{" "}
                  </Text>
                  <Text style={styles.invest_of}>
                    by{" "}
                    <Text style={styles.inve_amt}>
                      {formatDate(item.next_installment_date) || "-"}
                    </Text>
                  </Text>
                </>
              )}
            </View>
          )}
        </View>
      )}
    </View>
  );
};

const ToggleActiveCompletePlans = ({
  tabs,
  activeIndex,
  onChangeValue,
  style = {},
}) => {
  return (
    <View
      style={{
        flexDirection: "row",
        borderBottomColor: "#E5EAEE",
        borderBottomWidth: 1,
        marginBottom: 10,
        ...style,
      }}
    >
      {tabs.map((tab, index) => {
        return (
          <TouchableHighlight
            onPress={() => {
              onChangeValue && onChangeValue(index);
            }}
          >
            <Text
              style={{
                color: index == activeIndex ? "#121212" : "#888C9F",
                fontSize: 14,
                paddingHorizontal: 30,
                paddingVertical: 12,
                borderBottomWidth: 3,
                borderBottomColor:
                  activeIndex == index ? "#121212" : "transparent",
              }}
            >
              {tab}
            </Text>
          </TouchableHighlight>
        );
      })}
    </View>
  );
};

const chartConfig = {
  backgroundGradientFrom: "#1E2923",
  backgroundGradientFromOpacity: 0,
  backgroundGradientTo: "#08130D",
  backgroundGradientToOpacity: 0.5,
  color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
  strokeWidth: 2,
  barPercentage: 0.5,
  useShadowColorFromDataset: false,
};

export default class myclipscompleted extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let userId = null;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }

    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }

    this.state = {
      userId,
      data: null,
      activeTabIndex: 0,
      isLoading: true,
      filters: [],
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("myequityinvestmentplanflow", {
          path: global.location.href,
        });
      } else {
        amplitude.logEvent("myequityinvestmentplanflow", {
          path: this.props.navigation.getState().routeName,
        });
      }
      let { filters } = this.state;
      const { params } = this.props.route;
      if (params && params.filter) {
        const value = params.filter;
        const title = getProductName(value);
        filters = [...filters, { filterBy: "product", value, title }];
      }

      const { activeTabIndex, userId } = this.state;

      const responses = await Promise.all([
        getMyEquityInvestmentPlans(userId),
        getStartNewPlanContern(),
      ]);
      const response = responses[0];
      const startNewPlanResponse = responses[1];

      if (response.code == 100) {
        const { result } = response;
        this.setState(
          {
            filters,
            isLoading: false,
            data: {
              plans: result.plans,
            },
            filters,
            startNewPlanContent:
              startNewPlanResponse && startNewPlanResponse.value
                ? startNewPlanResponse.value
                : null,
          },
          () => {
            this.getFilterData(activeTabIndex);
          }
        );
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText:
            response && response.msg ? response.msg : "Something went wrong!",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  removeFilter = (value) => {
    let { filters, activeTabIndex } = this.state;
    const updatedFilters = filters.filter((filter) => filter.value != value);
    this.setState({ filters: updatedFilters }, () => {
      this.getFilterData(activeTabIndex);
    });
  };

  getFilterData = (index) => {
    let filteredData = [];
    const { data } = this.state;
    const filterBy = index == 1 ? ["ROL", "EXP", "CPR"] : ["ACT", "RLI", "DON"];
    filteredData = data.plans.filter((item) => {
      return filterBy.indexOf(item["status"]) != -1;
    });
    const { filters } = this.state;
    if (filters.length != 0) {
      filteredData = filteredData.filter((item) => {
        const exists =
          filters.findIndex((filter) => filter.value == item.product) != -1;
        return exists;
      });
    }

    this.setState({
      filteredData,
    });
  };

  handleAgreementSign = (item) => {
    if (item.agreement_doc_id == null || item.agreement_doc_id == " ") {
      global.open(apiMainURL + "/app/jewel/complete/profile?rnw=jwe", "_blank");
    } else {
      if (item.agreement_doc_url) {
        global.open(item.agreement_doc_url);
      }
    }
  };

  renderPendingPayment = ({ item }) => {
    const isWeb = md(width);
    const isCompleted = item.status == "ROL";
    return (
      <View
        style={{
          marginVertical: isWeb ? 17 : 10,
          borderWidth: 1,
          borderRadius: 4,
          borderColor: "#E5EAEE",
        }}
      >
        {isWeb ? (
          <View
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              paddingVertical: 10,
              paddingRight: 16,
            }}
          >
            <View style={{ position: "absolute", left: 0 }}>
              <Image
                source={require("../../../assets/product_tag.png")}
                style={{
                  width: 90,
                  height: 21,
                  resizeMode: "contain",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              />
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: "#3D1310",
                  zIndex: 10,
                  marginLeft: 15,
                  marginTop: 2,
                }}
              >
                {getProductName(item.product)}
              </Text>
            </View>
            <View>
              <View>
                {item && item.agreement_doc_url && (
                  <TouchableOpacity
                    onPress={() => this.handleAgreementSign(item)}
                    style={styles.viewAgreement}
                  >
                    <AntDesign name="pdffile1" size={12} color="#1155CC" />
                    <Text
                      style={[
                        styles.inv_no,
                        {
                          textDecorationLine:
                            item.agreement_doc_id != " " &&
                            item.agreement_doc_id != null
                              ? "none"
                              : "none",
                        },
                      ]}
                    >
                      {item.agreement_doc_id == null ||
                      item.agreement_doc_id == " " ? (
                        <Text
                          style={[
                            {
                              color: "red",
                              backgroundColor: "red",
                              color: "#fff",
                              fontWeight: "bold",
                              paddingHorizontal: 4,
                              paddingVertical: 1,
                              fontSize: 9,
                            },
                            Platform.OS == "web" && { cursor: "pointer" },
                          ]}
                        >
                          e-Sign now
                        </Text>
                      ) : (
                        <Text>View Agreement</Text>
                      )}
                    </Text>
                  </TouchableOpacity>
                )}

                {item.invoice_no != null && item.invoice_no != "" && (
                  <Text style={styles.inv_no}>
                    {" "}
                    Invoice No:{item.invoice_no}
                  </Text>
                )}
              </View>
            </View>
          </View>
        ) : (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingHorizontal: 8,
              paddingVertical: 9,
              borderBottomWidth: 1,
              borderBottomColor: "#00000010",
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text
                style={{ fontSize: 13, color: "#464E5F", fontWeight: "bold" }}
              >
                {item.plan_code} Plan -
              </Text>
              <Text
                style={{ fontSize: 13, color: "#1BC5BD", paddingHorizontal: 2 }}
              >
                {item.type}
              </Text>
              <Text style={{ fontSize: 10, color: "#888C9F" }}>
                Plan No: {item.cip_pk}
              </Text>
            </View>
            {/* NEED CHANGE */}
            {/* <Entypo
              name="dots-three-vertical"
              size={16}
              color="#410DAA"
              style={[Platform.OS == "web" && { cursor: "pointer" }]}
              onPress={() => {
                this.setState(
                  {
                    activeModalData: {
                      heading: `${item.plan_code} - ${item.type} (${formatDate(
                        item.start_date
                      )} - ${formatDate(item.end_date)})`,
                      invoiceNo: item.invoice_no,
                      agreementDocId: item.agreement_doc_id,
                      agreement_doc_id: item.agreement_doc_id,
                      agreement_doc_url: item.agreement_doc_url,
                    },
                  },
                  () => {
                    this.openOrCloseModal && this.openOrCloseModal(true);
                  }
                );
              }}
            /> */}
          </View>
        )}

        {/* product tag incase of mobile */}
        {isMobile && (
          <View style={{ marginTop: 5 }}>
            <Image
              source={require("../../../assets/product_tag.png")}
              style={{
                width: 80,
                height: 18,
                resizeMode: "contain",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            />
            <Text
              style={{
                fontSize: 12,
                fontWeight: "bold",
                color: "#3D1310",
                zIndex: 10,
                marginLeft: 15,
                marginTop: 2,
              }}
            >
              {getProductName(item.product)}
            </Text>
          </View>
        )}

        <PlanDetails
          item={item}
          showPieChart={!isCompleted}
          isCompleted={isCompleted}
          userId={this.state.userId}
          navigation={this.props.navigation}
        />

        <View
          style={{
            flexDirection: "row",
            justifyContent: isWeb ? "space-between" : "center",
            paddingHorizontal: 10,
            paddingVertical: 8,
          }}
        >
          {isWeb && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={styles.ad_fee}>Plan No: {item.cip_pk}</Text>
              <Text style={styles.dte}>
                {`   |   `}
                {formatDate(item.start_date) +
                  " - " +
                  formatDate(item.end_date)}
              </Text>
            </View>
          )}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {Store.getState().dashboard &&
              Store.getState().dashboard.viewModeUserId &&
              Store.getState().dashboard.viewModeUserId.user_id && (
                <View style={{ marginRight: 50 }}>
                  <Text
                    style={{
                      color: "#410DAA",
                      fontWeight: "bold",
                      textDecorationLine: "underline",
                    }}
                    onPress={() => {
                      if (Platform.OS === "web") {
                        global.open(
                          `https://maxiom.co.in/app/plan_stock_detail/${item.cip_pk}`,
                          "_blank"
                        );
                      } else {
                        Linking.openURL(
                          `https://maxiom.co.in/app/plan_stock_detail/${item.cip_pk}`
                        );
                      }
                    }}
                  >
                    Bill Calculation for Plan
                  </Text>
                </View>
              )}
            {
              <TouchableOpacity
                onPress={() => {
                  const { userId } = this.state;
                  let params = {};
                  if (userId) params["user_id"] = userId;
                  this.props.navigation.navigate("ManageSchedule", {
                    id: item.cip_pk,
                    product: item.product,
                    ...params,
                  });
                }}
              >
                <Text style={styles.manage_schedule_button}>
                  Manage Schedule
                </Text>
              </TouchableOpacity>
            }
            {!isCompleted && (
              <TouchableOpacity
                onPress={() => {
                  if (!isCompleted) {
                    this.props.navigation.navigate("Portfolio", {
                      screen: "InvestNow",
                      params: {
                        id: item.cip_pk,
                        product: item.product,
                        user_id: this.state.userId,
                      },
                    });
                  }
                }}
              >
                <Text style={styles.make_pay}>
                  {isCompleted ? "Invest In Similar Plan" : "Invest Now"}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    );
  };

  setModalMethod = (method) => {
    this.openOrCloseModal = method;
  };

  renderEmptyComponent = () => {
    const { activeTabIndex, filters } = this.state;
    let text = "";
    if (activeTabIndex == 0) {
      text =
        filters.length != 0 ? "No results found" : "There are no active plans";
    } else if (activeTabIndex == 1) {
      text =
        filters.length != 0 ? "No results found" : "There are no expired plans";
    }

    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 50,
        }}
      >
        <Text style={{ fontSize: 15, color: "#121212" }}>{text}</Text>
      </View>
    );
  };

  removeFilter = (value) => {
    let { filters, activeTabIndex } = this.state;
    const updatedFilters = filters.filter((filter) => filter.value != value);
    this.setState({ filters: updatedFilters }, () => {
      this.getFilterData(activeTabIndex);
    });
  };

  render() {
    const {
      data,
      filters,
      activeTabIndex,
      activeModalData,
      error,
      errorText,
      isLoading,
    } = this.state;
    const isWeb = md(width);

    if (error || isLoading) {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <View style={{ flex: 1, maxWidth: 1500 }}>
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={["Equity Plan", "My Equity Investment Plans"]}
            />
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoading && (
                <ActivityIndicator size="large" color="#410DAA" style={{}} />
              )}
              {error && (
                <Text style={{ fontSize: 16, color: "#121212" }}>
                  {errorText || "Something went wrong!"}
                </Text>
              )}
            </View>
          </View>
        </View>
      );
    }
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View style={[{ backgroundColor: "#fff" }, styles.container]}>
          <NavigationHeader
            onPressBack={() => {
              // this.props.navigation.pop();
              this.props.navigation.navigate("PlansHome", {
                user_id: this.state.userId,
              });
            }}
            navigation={this.props.navigation}
            navigationHeader={["Equity Plan", "My Equity Investment Plans"]}
            style={{ backgroundColor: "#F6F6F6" }}
          ></NavigationHeader>
          {data && (
            <ScrollView style={[styles.mainContainer]}>
              {/* {startNewPlanContent &&<StartNewPlan value={startNewPlanContent}/>} */}

              <ToggleActiveCompletePlans
                tabs={["Active", "Expired"]}
                activeIndex={activeTabIndex}
                onChangeValue={(selectedIndex) => {
                  if (selectedIndex != activeTabIndex) {
                    this.setState({ activeTabIndex: selectedIndex }, () => {
                      this.getFilterData(selectedIndex);
                    });
                  }
                }}
              />
              {/* {filters.length != 0 && (
                <View style={styles.filtersContainer}>
                  <Text style={styles.filtersTitle}>Filters:</Text>
                  {filters.map((filter, index) => {
                    const { title, value } = filter;
                    return (
                      <View key={index} style={styles.filter}>
                        <Text style={styles.filterText}>{title}</Text>
                        <AntDesign
                          name="close"
                          size={16}
                          color="#12121290"
                          style={styles.filterClose}
                          onPress={() => this.removeFilter(value)}
                        />
                      </View>
                    );
                  })}
                </View>
              )} */}
              <FlatList
                data={this.state.filteredData || []}
                ref={(ref) => {
                  this.pendingPaymentsTable = ref;
                }}
                renderItem={this.renderPendingPayment}
                keyExtractor={(item, index) => `pending_payment_${index}`}
                ItemSeparatorComponent={() => {
                  return (
                    <View
                      style={{
                        width: "100%",
                        background: "#E5EAEE",
                      }}
                    />
                  );
                }}
                ListEmptyComponent={this.renderEmptyComponent}
                style={{
                  paddingHorizontal: isWeb ? 0 : 16,
                }}
              />
            </ScrollView>
          )}
        </View>
        {activeModalData && (
          <ModalComponent
            ref={(el) => (this.modalRef = el)}
            setModalMethod={this.setModalMethod}
            isOpen={true}
            content={
              <View
                style={{
                  backgroundColor: "#F6F6F6",
                  borderTopLeftRadius: 24,
                  borderTopRightRadius: 24,
                  paddingHorizontal: 19,
                  paddingVertical: 17,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{ color: "#464E5F", fontSize: 12, marginBottom: 11 }}
                  >
                    {activeModalData.heading}
                  </Text>
                  {Platform.OS == "web" && (
                    <MaterialIcons
                      name="close"
                      size={24}
                      color="black"
                      onPress={() => this.openOrCloseModal(false)}
                    />
                  )}
                </View>
                <TouchableOpacity
                  style={{ marginBottom: 10 }}
                  onPress={() => this.handleAgreementSign(activeModalData)}
                >
                  <Text
                    style={{
                      color: "#1155CC",
                      fontSize: 12,
                      marginBottom: 11,
                      textDecorationLine:
                        activeModalData.agreement_doc_id != " " &&
                        activeModalData.agreement_doc_id != null
                          ? "underline"
                          : "none",
                    }}
                  >
                    Agreement:{activeModalData.agreementDocId}
                    {(activeModalData.agreementDocId == null ||
                      activeModalData.agreementDocId == " ") && (
                      <Text
                        style={[
                          {
                            color: "red",
                            backgroundColor: "red",
                            color: "#fff",
                            fontWeight: "bold",
                            paddingHorizontal: 4,
                            paddingVertical: 1,
                            fontSize: 9,
                          },
                          Platform.OS == "web" && { cursor: "pointer" },
                        ]}
                      >
                        e-Sign now
                      </Text>
                    )}
                  </Text>
                </TouchableOpacity>
                <View>
                  {activeModalData.invoiceNo && (
                    <Text
                      style={{
                        color: "#1155CC",
                        fontSize: 12,
                        marginBottom: 11,
                      }}
                    >
                      Invoice No:{activeModalData.invoiceNo}
                    </Text>
                  )}
                </View>
              </View>
            }
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: isWeb ? 32 : 0,
    paddingRight: isWeb ? 32 : 0,
    paddingTop: isWeb ? 30 : 10,
    maxWidth: 1400,
    marginBottom: isMobile ? 120 : "auto",
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
  filterText: {
    fontSize: 15,
    color: "gray",
    fontWeight: "200",
  },
  filtersTitle: {
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 6,
    marginLeft: isMobile ? 16 : 0,
  },
});
