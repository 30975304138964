import React, {Component} from "react";
import {Dimensions, View} from "react-native";
import { PieChart } from "react-native-chart-kit";
import { md } from "../../Responsive";
import PieChartLegend from "./PieChartLegend"

const { width } = Dimensions.get("window");


export const PieChartDashboard = ({width=150,height=150,style={},legendContainerStyle={},data,chartConfig,accessor,titleValue=""}) => {
    const totalValue = data.reduce((sum,next)=>{
        return {[accessor]:sum[accessor]+next[accessor]};
    })[accessor];

  
    return(
        <View style={{flex:1,flexDirection:"row",alignItems:"center",maxWidth:320,...style}}>
            <PieChart
                data={data}
                width={width||150}
                height={height|| 150}
                chartConfig={chartConfig}
                accessor={accessor}
                backgroundColor={"#fff"}
                center={[0.27*width , 0]}
                absolute
                radius={0.23*width||32}
                hasLegend={false}
            />
            <View style={{marginLeft:10,borderRightColor:"#888C9F40",borderRightWidth: md(width) ? 1: 0,marginVertical:8, paddingRight:15,...legendContainerStyle}}>
                {
                    data.map((item)=>{
                        return(
                            <PieChartLegend color={item.color} title={`${item.title} ${item.titleValue}`}/>
                        )
                    }
                    )
                }
            </View>
    </View>
    )
}
