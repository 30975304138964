import React from "react";

import {
  View,
  ScrollView,
  Image,
  SafeAreaView,
  Platform,
  StyleSheet,
  Dimensions,
} from "react-native";
import { Button, Text } from "react-native-paper";

import { connect } from "react-redux";
import {
  updateFinplanStatus,
  updateLastSeenScreen,
} from "../../../actions/finplan";
import {
  finplanSummarySave,
  getFinplanDetails,
  postWhenRetire,
} from "../../../api/finplan";
import { isMobile } from "../../../utils";
import { StackActions } from "@react-navigation/routers";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { ShowToast } from "../../../components/Common/Toast";
import { updateCustomerGoalValues } from "../../../api";
const { width } = Dimensions.get("window");
import amplitude from "../../../utils/amplitude";

class WhenRetire extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let type = null;
    if (params) {
      type = params.type || null;
    }
    this.state = {
      type,
      age: 50,
      showToast: false,
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("whenRetire",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("whenRetire", { path: this.props.navigation.getState().routeName });
      }
      const response = await getFinplanDetails();
      let age = 50;
      if (response.code == 100) {
        const { results } = response;
        const { retirement_age } = results;
        age = retirement_age;
      }
      const yearsData = this.generateData(35, 80);
      this.setState({ yearsData, age });
    } catch (error) {}
  }

  onPressNext = async () => {
    this.setState({ showToast: "true" });
    const { age = 0 } = this.state;
    if (age >= 19 && age <= 80) {
      this.setState({ error: null, isLoading: true });
      const payload = {
        age,
      };
      const response = await postWhenRetire(payload);
      if (response && response.code == 100) {
        this.setState({ isLoading: false });
        const options = {
          planning_for: "retirement",
        };
        const summary = await finplanSummarySave(options);
        if (summary.code == 100) {
          const { params = {} } = this.props.route;
          const { from: f = "Goals", type: t, stack, ...restParams } = params;
          const screen = this.state.from || "Goals";
          
          await updateCustomerGoalValues();
          if (params && params.from && params.from == "advice") {
            this.props.navigation.dispatch(
              StackActions.replace("App", {
                screen: "ViewMode",
                params: {
                  screen: "InvestForGoals",
                  params: {
                    showHeader: true,
                  },
                },
              })
            );
          } else {
            this.props.navigation.navigate("Goals");
          }
        } else {
          this.setState({ error: "Error creating finplan summary" });
        }
      }
    } else {
      this.validateYears(age);
    }
  };

  generateData = (min, max) => {
    let years = [];
    for (let i = min; i <= max; i++) {
      const num = i;
      years.push({
        label: num.toString(),
        value: num,
      });
    }
    return years;
  };

  validateAge = (age) => {
    let error = null;
    let data = { age };
    if (age <= this.state.user_age) {
      error = "Minimum retirement age is " + (this.state.user_age + 1);
    }
    if (age > 60) {
      error = "Maximum retirement age is 60";
      data = {};
    }
    this.setState({ ...data, error });
  };

  getYear = () => {
    const year = new Date().getFullYear();
    const { years } = this.state;
    return year + parseInt(years);
  };

  render() {
    const { yearsData = [], age = 50 } = this.state;
    return (
      <View style={[styles.page]}>
        <SafeAreaView style={{ flex: 1 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Retirement Details"]}
            onPressBack={() => {
              this.props.navigation.goBack();
            }}
          />
          {this.state.showToast == "true" ? (
            <ShowToast
              showToast={true}
              type="status"
              title="Goal created successfully"
              duration="10"
            />
          ) : null}
          <View style={ isMobile ? {justifyContent: 'center', alignItems: 'center'} : { marginLeft: "34%" }}>
            <ScrollView
              showsVerticalScrollIndicator={true}
              contentContainerStyle={{
                flexGrow: 1,
                paddingBottom: 100,
                paddingTop: 60,
                alignItems: isMobile ? "center" : "flex-start",
              }}
              keyboardShouldPersistTaps="handled"
            >
              <View style={{ flex: 1, paddingHorizontal: 16 }}>
                <View
                  style={{
                    flex: 0.3,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/retirement_finplan_image.png",
                    }}
                    style={{ width: 125, height: 125, alignSelf: "center" }}
                    resizeMode="contain"
                  />
                </View>
                <View style={{}}>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      marginTop: 40,
                      marginBottom: 30,
                      color: "#121212",
                      alignSelf: "center",
                    }}
                  >
                    When do you wish to retire?
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignSelf: "center",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <Text style={{ marginRight: 10 }}>At age </Text>
                    <PickerFieldSelect
                      data={yearsData}
                      value={age}
                      label={null}
                      onChange={(value) => {
                        this.setState({ age: value });
                      }}
                      pickerStyle={[{ width: 100 }]}
                      placeholder={{ value: "SEL", label: "Select" }}
                    />
                    <Text style={{ marginLeft: 10, marginLeft: -190 }}>
                      Year{age > 1 ? "s" : ""}
                    </Text>
                  </View>
                  <Text
                    style={[
                      styles.errorMessage,
                      { alignSelf: "center", paddingTop: 10 },
                    ]}
                  >
                    {this.state.error || ""}
                  </Text>
                </View>
              </View>
              <Button
                uppercase={false}
                mode={"contained"}
                loading={this.state.isLoading}
                onPress={this.onPressNext}
                style={styles.nextButton}
                color="#410DAA"
              >
                <Text style={styles.next}>Next</Text>
              </Button>
            </ScrollView>
          </View>
        </SafeAreaView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingBottom: 66,
    width: width,
    height: "100%",
  },
  formItem: {},
  inputItem: {
    width: isMobile ? "100%" : "auto",
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  nextButton: {
    width: width < 768 ? "96%" : "15%",
    marginLeft: isMobile ? 5 : "7%",
    marginTop: 30,
    marginBottom: 0,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  errorMessage: {
    color: "red",
    fontSize: 14,
    paddingVertical: 5,
    fontFamily: "Roboto",
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateFinplanStatus: (type, status) =>
      dispatch(updateFinplanStatus(type, status)),
    updateLastSeenScreen: (type, screen, basic, end) =>
      dispatch(updateLastSeenScreen(type, screen, basic, end)),
  };
};

export default connect(null, mapDispatchToProps)(WhenRetire);
