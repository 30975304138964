import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from "react-native";
import { TextInput } from "react-native-paper";
import { xs, lg } from "../../Responsive";
const window = Dimensions.get("window");
import RNPickerSelect from "react-native-picker-select";
import {
  getProductName,
  isMobile,
  reverseString,
  valueInWords,
} from "../../utils";
import { getHashKey } from "../../api";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../api/apiConfig";
import { MAIN_URL } from "../../api/urls";
import { useEffect } from "react";
const { width } = Dimensions.get("window");
import { Button } from "react-native-paper";
import { StackActions } from "@react-navigation/routers";
import PickerField from "../../components/Common/Pickers/PickerField";
import PickerFieldSelect from "../../components/Common/Pickers/PickerFieldSelect";
import amplitude from "../../utils/amplitude";

const Circles = ({ style = {} }) => {
  return (
    <View
      style={[
        {
          ...style,
          right: -30,
          top: -30,
        },
        Platform.OS == "web" && { transform: [{ rotateY: "-23deg" }] },
      ]}
    >
      <View
        style={{
          backgroundColor: "#A163F510",
          width: 76,
          height: 76,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 38,
        }}
      >
        <View
          style={{
            backgroundColor: "#A163F520",
            width: 46,
            height: 46,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 23,
          }}
        />
      </View>
    </View>
  );
};

export const TopupStrip = ({
  style = {},
  subscribed_products = [],
  onClick,
  noCompliance,
  viewMode = false,
  ...props
}) => {
  let topupInput = null;
  const [topupAmount, setTopUpAmount] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [errorText, setErrorText] = useState("");
  useEffect(() => {
    Platform.OS == "web" &&
      !xs(window.width) &&
      topupInput &&
      topupInput.focus();
  }, [subscribed_products]);

  const onChangeAmount = (amount) => {
    if (isNaN(amount)) return;
    setTopUpAmount(amount);
    errorText != "" && setErrorText("");
  };

  const validateTopUp = () => {
    return !isNaN(topupAmount) && topupAmount != null && topupAmount != 0;
  };

  const isValidData = () => {
    const isValid = validateTopUp();
    if (!selectedPlan) {
      setErrorText("Please select product");
      setIsLoading(false);
      return false;
    }
    if (!isValid) {
      setErrorText("Please enter amount");
      setIsLoading(false);
      return false;
    }

    return true;
  };

  const proceedToInvest = async () => {
    try {
      if (viewMode) {
        return;
      }

      setIsLoading(true);
      const isValid = isValidData();
      if (!isValid) return;
      let url = MAIN_URL + "/app/gwsc/placeorder/";
      let url_payload = "";

      if (topupAmount) {
        url_payload += "topup_" + topupAmount;
      }

      let payload = {
        cipamountstr: url_payload,
        product: selectedPlan,
      };

      if (Platform.OS == "android" || Platform.OS == "ios") {
        payload["mobile"] = true;
      }

      const response = await getHashKey(payload);
      if (response.code == 100) {
        const { hash_key } = response.result;
        let token = getTokenFromStore();
        token = reverseString(token);
        url = url + hash_key + token + "/" + GATEWAY_ENVIRONMENT;
        setErrorText("");
        if (Platform.OS == "web") {
          global.open(url, "_self");
        } else {
          props.navigation.dispatch(
            StackActions.replace("App", {
              screen: "Dashboard",
              params: {
                screen: "Dashboard",
                params: {
                  screen: "Gateway",
                  params: {
                    type: "PLACE_ORDER_TOPUP",
                    data: response.result,
                    screen: "TransactionStatus",
                  },
                },
              },
            })
          );
        }
      } else {
        let defaultErrorMessage = "Something went wrong!";
        if (response.code == 102) {
          defaultErrorMessage = "Enter valid amount";
        }
        setIsLoading(false);
        const text =
          response && response.msg ? response.msg : defaultErrorMessage;
        setErrorText(text);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorText("Something went wrong!");
    }
  };

  let selectProducts = [];
  // selectProducts.push({ label: "Select", value: null });
  if (subscribed_products.length != 0) {
    subscribed_products.forEach((key) => {
      const product = getProductName(key);
      selectProducts.push({ label: product, value: key });
    });
  }

  return (
    <View style={{ paddingTop: 15, paddingBottom: 15 }}>
      <View
        style={[style, styles.container, isMobile && styles.mobileContainer]}
      >
        <Circles style={{ position: "absolute", zIndex: 10 }} />
        <Text
          style={{
            color: "#410DAA",
            fontFamily: "Roboto",
            fontWeight: "bold",
            fontSize: 17,
          }}
        >
          Top Up
        </Text>
        <Text
          style={{
            color: "#464E5F",
            fontSize: isMobile ? 14 : 12,
            fontStyle: "italic",
            fontFamily: "Roboto",
            paddingTop: 5,
          }}
        >
          *Topup will be added, to your investment plan as per its terms*
        </Text>
        <PickerFieldSelect
          data={selectProducts}
          value={selectedPlan}
          label={"Select Product"}
          style={{ marginTop: 16 }}
          onChange={(itemValue) => setSelectedPlan(itemValue)}
          pickerStyle={[{ width: "90%" }]}
          type="select"
          placeholder={{
            label: "Select",
            value: null,
            color: "#9EA0A4",
          }}
        />
        <TextInput
          variant="outlined"
          value={topupAmount}
          placeholder="Enter Amount to Topup*"
          onChangeText={(amount) => {
            if (!isNaN(amount)) {
              setTopUpAmount(amount);
            }
          }}
          keyboardType={"number-pad"}
          underlineColor="#fff"
          active
          theme={{ colors: { primary: "white" } }}
          style={{
            fontSize: 13,
            backgroundColor: "white",
            marginTop: 15,
            height: 50,
            width: isMobile ? "100%" : "100%",
          }}
        />
        {errorText ? (
          <Text
            style={{
              marginTop: 5,
              fontSize: 12,
              color: "red",
              marginBottom: isMobile ? 30 : 0,
              fontStyle: "italic",
            }}
          >
            {errorText || " "}
          </Text>
        ) : (
          <Text
            style={{
              marginTop: 5,
              fontSize: 12,
              color: "#464E5F",
              marginBottom: topupAmount ? 3 : 0,
              fontStyle: "italic",
            }}
          >
            {valueInWords(topupAmount) || " "}
          </Text>
        )}

        <View
          style={[isMobile ? styles.mobileButtonStyle : styles.webButtonStyle]}
        >
          <TouchableOpacity
            onPress={() => {
              if (noCompliance) {
                const is_valid = isValidData();
                if (is_valid) {
                  onClick &&
                    onClick({
                      data: topupAmount,
                      product: selectedPlan,
                      topup: true,
                    });
                }
              } else {
                !isLoading && proceedToInvest();
              }
            }}
            style={{
              backgroundColor: "#F9F6FF",
              alignItems: "center",
              alignSelf: "center",
              padding: 10,
            }}
          >
            {isLoading ? (
              <ActivityIndicator size="small" color="#410DAA" />
            ) : (
              <Button
                uppercase={false}
                mode={"contained"}
                onPress={() => {
                  let eventProperties = {
                    top_up_amount: topupAmount,
                    selected_plan: selectedPlan,
                  };
                  amplitude.logEvent("Top_Up_Button_Clicked", eventProperties);
                  if (noCompliance) {
                    const is_valid = isValidData();
                    if (is_valid) {
                      onClick &&
                        onClick({
                          data: topupAmount,
                          product: selectedPlan,
                          topup: true,
                        });
                    }
                  } else {
                    !isLoading && proceedToInvest();
                  }
                }}
                style={{
                  width: 334,
                  alignSelf: "center",
                }}
                color={"#410DAA"}
              >
                <Text style={{ fontSize: 14 }}>Top Up</Text>
              </Button>
            )}
          </TouchableOpacity>
          <Text
            style={{
              fontSize: isMobile ? 11 : 9,
              color: "#464E5F",
              marginTop: 5,
              alignSelf: "center",
              textAlign: "center",
              fontFamily: "Roboto",
              fontStyle: "italic",
            }}
          >
            Charges applicable. Calculated as per our existing terms*
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    overflow: "hidden",
    height: "100%",
    backgroundColor: "#F9F6FF",
    borderRadius: 12,
    paddingBottom: 15,
    paddingTop: 13,
    paddingHorizontal: 20,
    width: isMobile ? "100%" : "100%",
  },
  mobileContainer: {
    flex: 1,
  },
  mobileButtonStyle: {
    position: "relative",
  },
});
