import React from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import { Text } from "react-native-paper";
import { isMobile, months_short_names, numberWithCommas } from "../../utils";
import { PieChartStrip } from "./pieChartStrip";
const { width } = Dimensions.get("window");
import { Entypo } from "@expo/vector-icons";

export default class PortfolioStrip extends React.Component {
  constructor(props) {
    super(props);
    const params = this.props;
    let dataValue = null;
    let value = null;
    let total_invested_value = null;
    let last_Updated_date = null;
    let oneday_return_value = null;
    let overall_return_value = null;
    let yearly_gain = null;
    if (params) {
      dataValue = params.data || null;
      value = params.value || null;
      total_invested_value = params.total_invested_value || null;
      last_Updated_date = params.last_Updated_date || null;
      oneday_return_value = params.oneday_return_value || null;
      overall_return_value = params.overall_return_value || null;
      yearly_gain = params.yearly_gain || null;
    }
    this.state = {
      dataValue,
      value,
      total_invested_value,
      last_Updated_date,
      oneday_return_value,
      overall_return_value,
      yearly_gain,
    };
  }

  render() {
    const {
      dataValue = [],
      value = "-",
      total_invested_value,
      last_Updated_date,
      overall_return_value,
    } = this.state;
    const initialPercentage = { percentage: 0 };
    const totalPercentage = dataValue
      ? dataValue.reduce((c, p) => {
        let percentage = 0;
        if ("stocks_perc" in p) {
          percentage = p["stocks_perc"];
        } else if ("mf_perc" in p) {
          percentage = p["mf_perc"];
        } else if ("assets_perc" in p) {
          percentage = p["assets_perc"];
        }
        return { percentage: c.percentage + percentage };
      }, initialPercentage)
      : initialPercentage;

    let date = "21 JUL 2022";
    if (last_Updated_date != null) {
      date = last_Updated_date.split("-");
      const month = months_short_names(parseInt(date[1]));
      date = date[2] + " " + month + " " + date[0];
    }

    let temp_val = value;
    if (temp_val == "-" || !temp_val) {
      temp_val = "0";
    }
    temp_val = temp_val.replace(/,/g, "");
    temp_val = temp_val.replace(/₹/g, "");
    let current_value = Math.abs(temp_val);
    if (current_value > 10000000) {
      current_value = (current_value / 10000000).toFixed(2) + " Cr";
    } else if (current_value > 100000) {
      current_value = (current_value / 100000).toFixed(2) + " L";
    } else {
      current_value = numberWithCommas(current_value);
    }

    return (
      <View
        style={{
          flex: 1,
          paddingTop: 15,
          paddingBottom: 25,
          paddingLeft: isMobile ? 5 : 0,
        }}
      >
        <View style={styles.Container}>
          <View
            style={{
              paddingRight: isMobile ? 15 : 10,
              flex: 1,
              alignItems: "center",
              marginTop: 5,
              width: isMobile ? '100%':'30%',
            }}
          >
            <View style={{ width: '100%', marginLeft: 40}}>
              <Text
                style={{
                  fontSize: 25,
                  fontWeight: "700",
                  color: "#464E5F",
                  fontFamily: "Roboto",
                }}
              >
                ₹{" "}
                {isNaN(current_value)
                  ? current_value
                  : numberWithCommas(current_value)}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#6C7293",
                  fontFamily: "Roboto",
                  marginTop: 10,
                }}
              >
                Curr. Value
                <Entypo name="dot-single" size={14} color="#6C7293" />
                {date}
              </Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'row',marginTop: 15,width: '100%', marginLeft: 20}}>
              <View style={{ marginHorizontal: 10 }}>
                <Text style={styles.innerValue}>
                  {total_invested_value || "-"}
                </Text>
                <Text style={styles.innerTitle}>Invested</Text>
              </View>
              <View style={{ marginHorizontal: 10 }}>
                <Text style={styles.innerValue}>
                  {overall_return_value || "-"}
                </Text>
                <Text style={[styles.innerTitle, { textAlign: "right" }]}>
                  Abs. Gain
                </Text>
              </View>
            </View>

          </View>
          {totalPercentage.percentage != 0 && (
            <>
              {/* <View style={styles.separator} /> */}
              <View style={styles.subContainerTwo}>
                <PieChartStrip
                  width={120}
                  height={120}
                  data={[
                    {
                      title: "Stocks",
                      value: parseInt(dataValue[0].stocks_perc),
                      color: "#6FE3D4",
                      legendFontColor: "#6FE3D4",
                      legendFontSize: 15,
                      titleValue: `${dataValue[0].stocks_perc.toFixed(1)}%`,
                      total:
                        dataValue[0].value && dataValue[0].value != "-"
                          ? dataValue[0].value
                          : 0,
                    },
                    {
                      title: "MF",
                      value: parseInt(dataValue[1].mf_perc),
                      color: "#876CDD",
                      legendFontColor: "#A98DFF",
                      legendFontSize: 15,
                      titleValue: `${dataValue[1].mf_perc.toFixed(1)}%`,
                      total:
                        dataValue[1].value && dataValue[1].value != "-"
                          ? dataValue[1].value
                          : 0,
                    },
                    {
                      title: "Assets",
                      value: parseInt(dataValue[2].assets_perc),
                      color: "#BAC7F2",
                      legendFontColor: "#FFD572",
                      legendFontSize: 15,
                      titleValue: `${dataValue[2].assets_perc.toFixed(1)}%`,
                      total:
                        dataValue[2].value && dataValue[2].value != "-"
                          ? dataValue[2].value
                          : 0,
                    },
                  ]}
                  chartConfig={{
                    backgroundGradientFrom: "#1E2923",
                    backgroundGradientFromOpacity: 0,
                    backgroundGradientTo: "#08130D",
                    backgroundGradientToOpacity: 0.5,
                    color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
                    strokeWidth: 2,
                    barPercentage: 0.5,
                    useShadowColorFromDataset: false,
                  }}
                  accessor={"value"}
                />
              </View>
            </>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  separator: {
    width: isMobile ? width * 0.8 : "100%",
    height: 1,
    backgroundColor: "#888C9F66",
  },
  Container: {
    flex: 1,
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 15,
    borderColor: "rgba(65, 13, 170, 0.2)",
    padding: isMobile ? 3 : 15,
    width: isMobile ? "95%" : "100%",
  },
  innerTitle: {
    color: "#6C7293",
    fontSize: 14,
    fontFamily: "Roboto",
  },
  innerValue: {
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: 14,
  },
  subContainerTwo: {
    justifyContent: "space-between",
    marginBottom: 10,
    height: 140,
    width: isMobile ? "90%" : "60%",
  },
});
