import React from "react";
import { View, Text, Dimensions } from "react-native";
import { md } from "../../../../Responsive";
const { width } = Dimensions.get("window");
const isWeb = md(width);

export default function PieChartInvestLegend({ color, title, style = {} }) {
  return (
    <View
      style={[
        { alignSelf: "flex-start", flexDirection: "row", marginVertical: 7.5 },
        style,
      ]}
    >
      <View
        style={{
          width: 15,
          height: 15,
          borderRadius: 2,
          backgroundColor: color,
          marginTop: 1,
        }}
      />
      <Text
        style={{
          fontSize: 14,
          color: "#464E5F",
          paddingLeft: 13,
          fontFamily: "Roboto",
        }}
      >
        {title}
      </Text>
    </View>
  );
}
