import { SAVE_USER_PROFILE_DATA,LOGOUT, CONNECTION_STATUS_CHANGE, LOGIN, SAVE_SCREEN_CONFIG, SHOW_CORPORATE_STICKER, IS_NEW_USER, IS_GOAL, DASHBOARD_PORTFOLIO_ANALYSIS_CLOSED, USER_IMPORT_FUNDS } from '../actions/types';

const initialState = {
    userProfileData: {},
    lastLoggedInTime: null,
    show_corporate_sticker: true,
    is_new_user: false,
    is_goal: false,
    show_dashboard_portfolio: true,
    user_import_funds: false,
    screenConfig: {
      show_goals_fin_plan : {
        show_screen : "Y"
      },
      show_fin_education : {
        show_screen : "Y"
      },
      show_fin_assessments : {
        show_screen : "Y"
      },
      show_explore_mf : {
        show_screen : "Y"
      },
      show_explore_stocks : {
        show_screen : "Y"
      },
      show_imports : {
        show_screen : "Y"
      },
      show_etfs : {
        show_screen : "Y"
      },
      show_bonds : {
        show_screen : "Y"
      }
    }
};

let time;
const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      time = action.payload.lastLoggedInTime
      return {
        ...state,
        lastLoggedInTime: time
      }
    case LOGOUT:
      return initialState;
    // reducer for saving user profile data
    case SAVE_USER_PROFILE_DATA:
      return {
        ...state,
        userProfileData: {
          ...state.userProfileData,
          ...action.payload
        },
      };
    case USER_IMPORT_FUNDS: 
      return {
        ...state,
        user_import_funds: action.payload.user_import_funds
      }
    case IS_NEW_USER: {
      return {
        ...state,
        is_new_user: action.payload.is_new_user
      }
    }
    case DASHBOARD_PORTFOLIO_ANALYSIS_CLOSED: {
      return {
        ...state,
        show_dashboard_portfolio: action.payload.show_dashboard_portfolio
      }
    }
    case IS_GOAL: {
      return {
        ...state,
        is_goal: action.payload.is_goal
      }
    }
    case SHOW_CORPORATE_STICKER: {
      return {
        ...state,
        show_corporate_sticker: action.payload.show_corporate_sticker
      }
    }
    case SAVE_SCREEN_CONFIG:
      return {
        ...state,
        screenConfig: action.payload,
      };
    // reducer for handling network connection change
    case CONNECTION_STATUS_CHANGE:
      return {
        ...state,
        isConnected: action.payload.isConnected,
      };

    default:
      return state;
  }
};

export default userDataReducer;