import React from "react";
import {
  View,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  Platform,
  ScrollView,
} from "react-native";
import { Button, HelperText, Text } from "react-native-paper";
import { isMobile, numberWithCommas } from "../../../utils";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import TextInputField from "../../../components/Common/Inputs/InputField";
import amplitude from "../../../utils/amplitude";
import DatePickerField from "../../../components/Common/Pickers/DatePickerField";
import moment from "moment";
import {
  getCorpusOrExpenseDetails,
  saveExpenseOrIncomeRecords,
} from "../../../api";

class AddExpense extends React.Component {
  constructor(props) {
    super(props);
    let goalId = null;
    let selectedExpense = null;
    let amount = null;
    let selectedFrequency = null;
    let inflation = null;
    let fromDate = null;
    let toDate = null;
    let selectedDayOfMonth = null;
    let selectedMonthOfYear = null;
    let selectedYearOfTxn = null;
    let entry_id = null;
    const { params } = this.props.route;
    if (params) {
      goalId = params.goalId || null;
      selectedExpense = params.selectedExpense || null;
      amount = params.amount || null;
      selectedFrequency = params.selectedFrequency || null;
      inflation = params.inflation || null;
      fromDate = params.fromDate || null;
      toDate = params.toDate || null;
      selectedDayOfMonth = params.selectedDayOfMonth || null;
      selectedMonthOfYear = params.selectedMonthOfYear || null;
      selectedYearOfTxn = params.selectedYearOfTxn || null;
      entry_id = params.entry_id || null;
    }
    let dayOfMonthList = [];
    let yearOfTxnList = [];
    for (let i = 1; i <= 31; i++) {
      dayOfMonthList.push({
        key: i,
        label: i.toString(),
        value: i.toString(),
      });
    }
    for (
      let i = new Date().getFullYear();
      i <= new Date().getFullYear() + 80;
      i++
    ) {
      yearOfTxnList.push({
        key: i,
        label: i.toString(),
        value: i.toString(),
      });
    }
    this.state = {
      goalId,
      entry_id,
      selectedExpenseType: "9",
      selectedExpense,
      selectedFrequency: selectedFrequency ? selectedFrequency : "MON",
      amount: amount ? amount : 0,
      inflation: inflation ? inflation : "",
      fromDate: fromDate ? fromDate : moment(new Date()).format("DD-MM-YYYY"),
      toDate: toDate ? toDate : "",
      selectedDayOfMonth: selectedDayOfMonth ? selectedDayOfMonth : "1",
      selectedMonthOfYear: selectedMonthOfYear ? selectedMonthOfYear : "1",
      selectedYearOfTxn: selectedYearOfTxn
        ? selectedYearOfTxn
        : new Date().getFullYear(),
      dayOfMonthList,
      yearOfTxnList,
      monthOfYearList: [
        {
          key: 1,
          label: "January",
          value: "1",
        },
        {
          key: 2,
          label: "February",
          value: "2",
        },
        {
          key: 3,
          label: "March",
          value: "3",
        },
        {
          key: 4,
          label: "April",
          value: "4",
        },
        {
          key: 5,
          label: "May",
          value: "5",
        },
        {
          key: 6,
          label: "June",
          value: "6",
        },
        {
          key: 7,
          label: "July",
          value: "7",
        },
        {
          key: 8,
          label: "August",
          value: "8",
        },
        {
          key: 9,
          label: "September",
          value: "9",
        },
        {
          key: 10,
          label: "October",
          value: "10",
        },
        {
          key: 11,
          label: "November",
          value: "11",
        },
        {
          key: 12,
          label: "December",
          value: "12",
        },
      ],
      amountError: "",
      inflationError: "",
      remark: "",
      remarkError: "",
      frequencyList: [
        {
          key: 1,
          label: "Monthly",
          value: "MON",
        },
        {
          key: 2,
          label: "Yearly",
          value: "ANN",
        },
        {
          key: 3,
          label: "One Type Event",
          value: "OTE",
        },
      ],
      expenseTypeList: [],
      selectedLifeExpectancy: "",
      lifeExpectancyError: "",
      lifeExpectancyList: [],
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("AddExpense",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("AddExpense", { path: this.props.navigation.getState().routeName });
    }
    let resp = await getCorpusOrExpenseDetails({
      head_code: "EXP",
    });
    let expenseTypeList = [];
    let index = 1;

    resp.result.cashflow_categories.forEach((item) => {
      item.descs.forEach((item2) => {
        expenseTypeList.push({
          key: index,
          label: `${item.name} - ${item2.description}`,
          value: item2.cashflowcategory_id,
        });
        index = index + 1;
      });
    });
    if (this.state.selectedExpense) {
      let data = expenseTypeList.find(
        (item) => item.label == this.state.selectedExpense
      );
      this.setState({ selectedExpenseType: data.value });
    }

    this.setState({ expenseTypeList });
  }

  handleContinue = async () => {
    let { amount, inflation } = this.state;
    if (amount == "") {
      this.setState({ amountError: "Please enter an amount" });
      return;
    }
    if (inflation == "") {
      this.setState({ inflationError: "Please enter inflation rate" });
      return;
    }
    let payload = {};

    if (this.state.entry_id) {
      payload = {
        category_id: this.state.selectedExpenseType,
        amount: amount,
        start_date: this.state.fromDate,
        rate: inflation,
        frequency: this.state.selectedFrequency,
        end_date: this.state.toDate,
        desc: this.state.remark,
        day_of_month: this.state.selectedDayOfMonth,
        month_of_year: this.state.selectedMonthOfYear,
        year_of_txn: this.state.selectedYearOfTxn,
        entry_id: this.state.entry_id,
      };
    } else {
      payload = {
        category_id: this.state.selectedExpenseType,
        amount: amount,
        start_date: this.state.fromDate,
        rate: inflation,
        frequency: this.state.selectedFrequency,
        end_date: this.state.toDate,
        desc: this.state.remark,
        day_of_month: this.state.selectedDayOfMonth,
        month_of_year: this.state.selectedMonthOfYear,
        year_of_txn: this.state.selectedYearOfTxn,
      };
    }
    let resp = await saveExpenseOrIncomeRecords(payload);
    if (resp && resp.code && resp.code == "100") {
      this.props.navigation.push("RetirementCorpus", {
        goalId: this.state.goalId,
      });
    }
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={[
            this.state.entry_id ? "Edit Expense" : "Add Expense",
          ]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          style={styles.cardContainer}
          showsVerticalScrollIndicator={false}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
              marginLeft: 40,
              width: 700,
            }}
          >
            <View style={[{ flex: 1 }, styles.itemMargin]}>
              <PickerFieldSelect
                data={this.state.expenseTypeList}
                value={this.state.selectedExpenseType}
                showSelect={false}
                label="Expense Type"
                onChange={(text) => {
                  this.setState({
                    selectedExpenseType: text,
                  });
                }}
                type="select"
              />
            </View>
            <View style={[{ flex: 1 }, styles.itemMargin]}>
              <TextInputField
                prefix="₹"
                label="Amount"
                value={numberWithCommas(this.state.amount)}
                onChange={(amount) => {
                  amount = amount.replaceAll(",", "");
                  if ((!isNaN(amount) && amount > 0) || amount == "") {
                    this.setState({ amount, amountError: "" });
                  }
                }}
                placeholder={"Enter an amount"}
                inputStyle={[{ paddingLeft: 20 }]}
                editable={true}
              />
              <HelperText type="error" visible={this.state.amountError != ""}>
                {this.state.amountError}
              </HelperText>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
              marginLeft: 40,
              width: 700,
            }}
          >
            <View style={[{ flex: 1 }, styles.itemMargin]}>
              <PickerFieldSelect
                data={this.state.frequencyList}
                value={this.state.selectedFrequency}
                showSelect={false}
                label="Frequency"
                onChange={(text) => {
                  this.setState({
                    selectedFrequency: text,
                  });
                }}
                type="select"
              />
            </View>
            <View style={[{ flex: 1 }, styles.itemMargin]}>
              <TextInputField
                label="Assumed Inflation %"
                value={this.state.inflation}
                onChange={(text) => {
                  this.setState({
                    inflation: text,
                    inflationError: "",
                  });
                }}
                placeholder={"Enter inflation rate"}
                inputStyle={[{ paddingLeft: 20 }]}
                editable={true}
              />
              <HelperText
                type="error"
                visible={this.state.inflationError != ""}
              >
                {this.state.inflationError}
              </HelperText>
            </View>
          </View>
          {this.state.selectedFrequency != "OTE" && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 20,
                marginLeft: 40,
                width: 700,
              }}
            >
              <View style={[{ flex: 1 }, styles.itemMargin]}>
                <DatePickerField
                  label="From Date"
                  value={this.state.fromDate}
                  onSelect={(value) => {
                    this.setState({
                      fromDate: moment(value.date).format("DD-MM-YYYY"),
                    });
                  }}
                  validRange={{
                    startDate: new Date(),
                  }}
                  editable={true}
                  style={[
                    {
                      width: isMobile ? "100%" : 300,
                    },
                  ]}
                />
              </View>
              <View style={[{ flex: 1 }, styles.itemMargin]}>
                <DatePickerField
                  label="To Date (optional)"
                  value={this.state.toDate}
                  onSelect={(value) => {
                    this.setState({
                      toDate: moment(value.date).format("DD-MM-YYYY"),
                    });
                  }}
                  validRange={{
                    startDate: new Date(),
                  }}
                  editable={true}
                  style={[
                    {
                      width: isMobile ? "100%" : 300,
                    },
                  ]}
                />
              </View>
            </View>
          )}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
              marginLeft: 40,
              width: 700,
            }}
          >
            {this.state.selectedFrequency == "MON" && (
              <View style={[{ flex: 1 }, styles.itemMargin]}>
                <PickerFieldSelect
                  data={this.state.dayOfMonthList}
                  value={this.state.selectedDayOfMonth}
                  showSelect={false}
                  label="Day Of Month"
                  onChange={(text) => {
                    this.setState({
                      selectedDayOfMonth: text,
                    });
                  }}
                  type="select"
                />
              </View>
            )}
            {this.state.selectedFrequency == "ANN" && (
              <View style={[{ flex: 1 }, styles.itemMargin]}>
                <PickerFieldSelect
                  data={this.state.monthOfYearList}
                  value={this.state.selectedMonthOfYear}
                  showSelect={false}
                  label="Month Of Year"
                  onChange={(text) => {
                    this.setState({
                      selectedMonthOfYear: text,
                    });
                  }}
                  type="select"
                />
              </View>
            )}
            {this.state.selectedFrequency == "OTE" && (
              <View style={[{ flex: 1 }, styles.itemMargin]}>
                <PickerFieldSelect
                  data={this.state.yearOfTxnList}
                  value={this.state.selectedYearOfTxn}
                  showSelect={false}
                  label="Year Of Transaction"
                  onChange={(text) => {
                    this.setState({
                      selectedYearOfTxn: text,
                    });
                  }}
                  type="select"
                />
              </View>
            )}
            <View style={[{ flex: 1 }, styles.itemMargin]}>
              <TextInputField
                label="Remarks (optional)"
                value={this.state.remark}
                onChange={(text) => {
                  this.setState({
                    remark: text,
                    remarkError: "",
                  });
                }}
                placeholder={"Enter remarks"}
                inputStyle={[{ paddingLeft: 20 }]}
                editable={true}
              />
              <HelperText type="error" visible={this.state.remarkError != ""}>
                {this.state.remarkError}
              </HelperText>
            </View>
          </View>
<View style={{paddingBottom: 15}}>
  <Button
    uppercase={false}
    mode={"contained"}
    onPress={this.handleContinue}
    style={styles.nextButton}
    color={"#410DAA"}
  >
    <Text style={styles.next}>Save</Text>
  </Button>
</View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  itemMargin: {
    paddingHorizontal: isMobile ? 16 : 0,
  },
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  nextButton: {
    width: "12%",
    marginLeft: 300,
    marginTop: 50,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
});

export default AddExpense;
