import { Feather } from "@expo/vector-icons";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  Dimensions,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Keyboard,
  Linking,
} from "react-native";
import {
  getScreenConfig,
  loginUser,
  addNotificationDevice,
  getLoginBannerDetails,
  skipCustomerLogin,
  createCrmdealsCipCbiForAccentureClients,
  getDatabaseOfUser,
} from "../../../api";
import MaterialIcon from "@expo/vector-icons/MaterialIcons";
import { md } from "../../../Responsive";
import { isMobile, reverseString, isWeb, isJama } from "../../../utils";
import Header from "../login_header";
const { width } = Dimensions.get("window");
import { saveUserProfileData, logIn, saveScreenConfig } from "../../../actions";
import { connect } from "react-redux";
import amplitude from "../../../utils/amplitude";
import DeviceInfo from "react-native-device-info";
import { StackActions } from "@react-navigation/routers";
import AsyncStorage from "@react-native-async-storage/async-storage";
import NetInfo from "@react-native-community/netinfo";
import ReCAPTCHA from "react-google-recaptcha";

class LoginUsingMobile extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let unique_key = null;
    let redirection_url = null;
    let temp = null;
    if (params) {
      unique_key = params.unique || "";
      redirection_url = params.redirect_url || "";
    }
    this.recaptchaRef = React.createRef();
    this.state = {
      data: null,
      isLoading: false,
      errorText: "",
      email: "",
      emailError: "",
      passwordError: "",
      captchaText: "",
      captchaError: "",
      randomCaptcha: 0,
      isConnected: true,
      capthaVal: null,
      bannerData: {
        logo_image_url: null,
        name: null,
        ria_code: null,
        subscriber_type: null,
        support_email: null,
        support_person_name: null,
        support_phone_number: null,
        is_jamawealth: null,
      },
      is_ria: false,
      hostname: Platform.OS == "web" ? global.location.hostname : "mobile",
      unique_key,
      redirection_url,
      showCaptcha: true,
      is_club: false,
    };
  }

  // onSubmit(event) {
  //   event.preventDefault();
  //   this.recaptchaRef.current.execute();
  // }


  loadBannerData = async () => {
    let { bannerData } = this.state;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    // const hostname = "sensage.simplygrow.in.com";
    // const payload = {
    //   ria_name: hostname,
    // };
    let response = null;
    let is_ria = false;
    let is_preferred_circle = false;
    if (hostname.includes("simplygrow.in")) {
      is_ria = true;
    }
    let is_club = false;
    if (hostname.includes("club")) {
      is_club = true;
    }
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    let showCaptcha = true;
    try {
      response = await getLoginBannerDetails();
    } catch (err) { }
    if (response && response.code == 100) {
      bannerData = response.result;
      this.setState({
        bannerData: bannerData,
        is_ria: is_ria,
        hostname: hostname,
        showCaptcha: showCaptcha,
        is_club: is_club,
        is_preferred_circle: is_preferred_circle,
      });
    } else {
      bannerData.logo_image_url =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/website/jam%C4%81_header.png";
      bannerData.name = isJama() ? "JamaWealth" : "MaxiomWealth";
      bannerData.ria_code = "INA 200015583";
      bannerData.subscriber_type = "RIAC";
      bannerData.support_email = "advisor@maxiomwealth.com";
      bannerData.support_person_name = "Ram Kalyan Medury";
      bannerData.support_phone_number = "9870264643";
      bannerData.is_jamawealth = true;
      this.setState({
        bannerData: bannerData,
        errorText: response && response.msg ? response.msg : "something went wrong",
        is_ria: is_ria,
        hostname: hostname,
        showCaptcha: showCaptcha,
        is_club: is_club,
        is_preferred_circle: is_preferred_circle,
      });
    }
  };

  skipLoginScreen = async () => {
    this.setState({ isLoading: true });
    let {
      unique_key,
      redirection_url,
      bannerData,
      is_ria,
      hostname,
      is_club,
      is_preferred_circle,
    } = this.state;
    let tokenValue = await AsyncStorage.getItem("tokenValue");
    let brand = DeviceInfo.getBrand();
    let deviceId = DeviceInfo.getDeviceId();
    let ip = await DeviceInfo.getIpAddress();

    let data = {
      token: unique_key,
    };

    if (Platform.OS == "web") {
      data = {
        ...data,
        operating_system: Platform.OS,
      };
    } else {
      data = {
        ...data,
        token: tokenValue,
        make: brand,
        model: deviceId,
        operating_system: Platform.OS,
        location: ip,
      };
    }

    const responseJson = await skipCustomerLogin(data);
    if (responseJson && responseJson.code == 100) {
      // await addNotificationDevice(data);
      amplitude.setUserId(responseJson.results.mobilenumber);

      const eventProperties = {
        username: responseJson.results.first_name,
        mobile: responseJson.results.mobilenumber,
        email: responseJson.results.email,
        customer_id: responseJson.results.customer_id,
      };

      amplitude.logEvent("Login_Successful", eventProperties);
      const { results = {} } = responseJson;
      if (results && results.token != null) {
        let data = { ...responseJson.results };
        const { token } = data;
        data = Object.assign(data, {
          is_ria: is_ria,
          bannerData: bannerData,
          is_club: is_club,
          is_preferred_circle: is_preferred_circle,
        });
        const loggedInTime = new Date().getTime();
        try {
          const screenConfigResponse = await getScreenConfig(
            { domain_name: hostname },
            { Authorization: "token " + token }
          );
          if (screenConfigResponse) {
            const { code, msg, result } = screenConfigResponse || {};
            this.props.saveScreenConfig(result);
          }
        } catch (error) { }
        this.props.logIn(loggedInTime);
        this.props.saveUserProfileData(data);
        this.setState({ isLoading: false });
        if (redirection_url) {
          let url = new URL(global.location.href);
          location.replace(url.origin + redirection_url);
        }
        await addNotificationDevice(data);
      } else {
        this.setState({
          errorText: "Error! Please contact help@maxiom.in",
          error: true,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        errorText:
          responseJson && responseJson.message
            ? responseJson.message
            : "Something went wrong!",
        error: true,
        isLoading: false,
      });
    }
  };

  // generateCaptcha = async () => {
  //   console.log("captcha generated")
  //   loadCaptchaEnginge(6, "#000", "#fff", "upper");
  // }

  componentDidMount = async () => {
    NetInfo.fetch().then((state) => {
      this.setState({ isConnected: state.isConnected });
    });
    const unsubscribe = NetInfo.addEventListener((state) => {
      this.setState({ isConnected: state.isConnected });
    });
    unsubscribe();
    // await this.generateCaptcha();
    await this.loadBannerData();
    const { unique_key, redirection_url } = this.state;
    if (unique_key) {
      try {
        await this.skipLoginScreen();
      } catch (err) { }
    }
  };

  // generateCaptcha = () => {
  //   var numberOne = Math.floor(Math.random() * 1000000) + 1;
  //   this.setState({ randomCaptcha: numberOne });
  // };


  getUserInfo = async () => {
    const { email } = this.state;
    try {
      let payload = {
        "username": email.toString()
      }
      // console.log("getUserInfo ", payload)
      let response = await getDatabaseOfUser(payload);
      // console.log("Response is = " + JSON.stringify(response));
      return response;
    } catch (e) {
      console.log(e)
      // console.log("Somthing went wrong ", e);
    }
  }

  handleContinue = async () => {
    const {
      email,
      password,
      captchaText,
      bannerData,
      is_ria,
      hostname,
      showCaptcha,
      is_club,
      is_preferred_circle,
    } = this.state;
    this.setState({ isLoading: true });
    let tokenValue = await AsyncStorage.getItem("tokenValue");
    let brand = DeviceInfo.getBrand();
    let deviceId = DeviceInfo.getDeviceId();
    let ip = await DeviceInfo.getIpAddress();

    let data = {
      username: email,
      password: password,
    };

    if (Platform.OS == "web") {
      data = {
        ...data,
        operating_system: Platform.OS,
      };
    } else {
      data = {
        ...data,
        token: tokenValue,
        make: brand,
        model: deviceId,
        operating_system: Platform.OS,
        location: ip,
      };
    }

    if (!email) {
      this.setState({
        emailError: "Please enter an email or mobile number",
        isLoading: false,
        passwordError: "",
        errorText: "",
        captchaError: "",
      });
      return;
    } else {
      this.setState({
        emailError: "",
      });
    }

    if (!password) {
      this.setState({
        passwordError: "Please enter a password",
        errorText: "",
        emailError: "",
        captchaError: "",
        isLoading: false,
      });
      return;
    } else {
      this.setState({
        passwordError: "",
      });
    }

    // if (!captchaText) {
    //   this.setState({
    //     captchaError: "Please Enter a Captcha",
    //     errorText: "",
    //     emailError: "",
    //     passwordError: "",
    //     isLoading: false,
    //   });
    //   return;
    // } else {
    //   this.setState({
    //     captchaError: "",
    //   });
    // }

    // if (validateCaptcha(captchaText) == true) {
    //   this.setState({
    //     captchaError: "",
    //   });
    // } else {
    //   this.setState({
    //     captchaError: "Please Enter Valid Captcha",
    //     errorText: "",
    //     emailError: "",
    //     passwordError: "",
    //     isLoading: false,
    //   });
    //   return;
    // } 
    try {
      const checkSignUpResponse = await this.getUserInfo();
      console.log("Check Sign UP " + JSON.stringify(checkSignUpResponse));
      if (
        checkSignUpResponse &&
        checkSignUpResponse.code &&
        checkSignUpResponse.code == "100"
      ) {
        if (checkSignUpResponse.results.user_exists_in_club === "FALSE" && checkSignUpResponse.results.user_exists_in_advisory === "FALSE") {
          // console.log("New User Found");
          this.setState({ isLoading: false, isNewUser: true });
        } else {
          let user_exists_in_advisory = checkSignUpResponse.results.user_exists_in_advisory;
          let db = "";
          if (user_exists_in_advisory === "TRUE") {
            db = "direct"

          } else {
            db = "regular"
          }
          if (checkSignUpResponse.results.user_exists_in_advisory == "TRUE" && checkSignUpResponse.results.user_exists_in_club == "TRUE") {
            this.setState({ dbError: true, dbErrorText: `Number Found in Both Database`, isLoading: false })
            return;
          }
          let hostname = global.location.hostname;
          if (!hostname.includes("localhost") && checkSignUpResponse.results.is_admin_user == false) {
            if (Platform.OS == "web" && !hostname.includes("cpartner")) {

              let topic = "";
              if ((hostname.includes("app") || hostname.includes("myadvisor")) && db == "regular") {
                if (hostname.includes("dapp") || hostname.includes("dmyadvisor")) {
                  global.location.href = `https://dclub.maxiomwealth.com/login/password?unique=${checkSignUpResponse.results.rToken}`
                } else {
                  global.location.href = `https://club.maxiomwealth.com/login/password?unique=${checkSignUpResponse.results.rToken}`
                }
                return;
              }
              if (hostname.includes("club") && db == "direct") {
                let urls = "";
                if (hostname.includes("dclub")) {
                  urls = "https://dapp.maxiomwealth.com"
                } else {
                  urls = "https://app.maxiomwealth.com"
                }
                if (checkSignUpResponse.results.database_host && checkSignUpResponse.results.database_host != "") {
                  if (hostname.includes("dclub")) {
                    urls = "https://dmyadvisor.maxiomwealth.com"
                  } else {
                    urls = "https://myadvisor.maxiomwealth.com"
                  }
                }
                global.location.href = `${urls}/login/password?unique=${checkSignUpResponse.results.rToken}`
                return;
              }
              if (db == "direct") {
                let urls = "";
                if (checkSignUpResponse.results.database_host && checkSignUpResponse.results.database_host != "" && checkSignUpResponse.results.database_host.includes("myadvisor")) {
                  if (hostname.includes("dmyadvisor")) {
                    urls = "https://dmyadvisor.maxiomwealth.com"
                  } else {
                    urls = "https://myadvisor.maxiomwealth.com"
                  }
                  global.location.href = `${urls}/login/password?unique=${checkSignUpResponse.results.rToken}`
                  return;
                } else {
                  if (!hostname.includes("app")) {
                    if (hostname.includes("dapp")) {
                      urls = "https://dapp.maxiomwealth.com"
                    } else {
                      urls = "https://app.maxiomwealth.com"
                    }
                    global.location.href = `${urls}/login/password?unique=${checkSignUpResponse.results.rToken}`
                    return;
                  }
                }
              }
            }
          }
          await AsyncStorage.setItem("database", db);
          // console.log("Data base is set to " + db);
        }
      }
    } catch (error) {
      this.setState({
        errorText: error,
        error: true,
        isLoading: false,
      });
    }



    const responseJson = await loginUser(data);
    try {
      // console.log("Login Jsons is ", responseJson);
      if (responseJson && responseJson.code == 100) {
        // console.log("login successful");
        // await addNotificationDevice(data);
        amplitude.setUserId(responseJson.results.mobilenumber);

        const eventProperties = {
          username: responseJson.results.first_name,
          mobile: responseJson.results.mobilenumber,
          email: responseJson.results.email,
          customer_id: responseJson.results.customer_id,
        };

        amplitude.logEvent("Login_Successful", eventProperties);
        const { results = {} } = responseJson;
        if (results && results.token != null) {
          let data = { ...responseJson.results };
          const {
            is_login_with_default_pwd,
            token,
            is_redirect_to_oldjama: is_corporate_user = false,
          } = data;
          data = Object.assign(data, {
            is_ria: is_ria,
            bannerData: bannerData,
            is_club: is_club,
            is_preferred_circle: is_preferred_circle,
          });
          if (!is_login_with_default_pwd) {
            const loggedInTime = new Date().getTime();
            try {
              const screenConfigResponse = await getScreenConfig(
                { domain_name: hostname },
                { Authorization: "token " + token }
              );
              if (screenConfigResponse) {
                const { code, msg, result } = screenConfigResponse || {};
                this.props.saveScreenConfig(result);
              }
            } catch (error) { }
            this.props.logIn(loggedInTime);
            this.props.saveUserProfileData(data);
            this.props.navigation.navigate("EquityPlans");
          } else {
            if (token) {
              const reversedToken =
                reverseString(token) + token.substring(0, 4);
              this.props.navigation.navigate("ChangeDefaultPassword", {
                k: reversedToken,
              });
            }
          }
          await addNotificationDevice(data);
          this.setState({ isLoading: false });
        } else {
          this.setState({
            errorText: "Error! Please contact help@maxiom.in",
            error: true,
            isLoading: false,
          });
        }
      } else if (responseJson && responseJson.code == '101') {
        // console.log("passssssss",responseJson.code)
        this.setState({
          passwordError: responseJson.message,
          error: true,
          isLoading: false,
        });
      } else if (responseJson && responseJson.code == '102') {
        // console.log("mobbbbbbbbbbbbbbbbbb")
        this.setState({
          errorText: "Please type a valid mobile number",
          error: true,
          isLoading: false,
        });
      } else if (responseJson && responseJson.results) {
        const { user_exists_in_club, user_exists_in_advisory } = responseJson.results;
        
        if (user_exists_in_club === "TRUE" && user_exists_in_advisory === "TRUE") {
          this.setState({
            errorText: "User exists in both databases.",
            error: true,
            isLoading: false,
          });
          return; // Exit the function early
        }
        this.setState({
          errorText:
            responseJson && responseJson.message
              ? responseJson.message
              : "Something went wrong!",
          error: true,
          isLoading: false,
        });
      }
    } catch (error) {
      if (this.state.isConnected) {
        this.setState({
          errorText: responseJson ?
            responseJson && responseJson.message
              ? responseJson.message
              : "Something went wrong!" : error,
          error: true,
          isLoading: false,
        });
      } else {
        this.setState({
          errorText: "Oh oh! Looks like your device is offline",
          error: true,
          isLoading: false,
        });
      }
    }
  };

  forgotPassword = () => {
    const url = "https://www.maxiomwealth.com/app/accounts/password/reset/";
    if (Platform.OS == "web") {
      global.open(url, "_blank");
    } else {
      Linking.openURL(url);
    }
  };

  handleReActivateAccount = () => {
    this.props.navigation.replace("Login", {
      reactive: true,
      user: this.state.email
    })
  }

  render() {
    const {
      isLoading,
      errorText,
      email,
      passwordError,
      emailError,
      captchaText,
      captchaError,
      bannerData,
      is_ria,
      showCaptcha,
    } = this.state;
    return (
      <View style={styles.container}>
        <Header
          navigation={this.props.navigation}
          route={this.props.route}
          bannerData={bannerData}
        />
        <ScrollView keyboardShouldPersistTaps="always">
          <View style={styles.body}>
            {isWeb && (
              <View style={styles.left}>
                <Text style={styles.best}>
                  Invest like the <Text style={{ color: "#410DAA" }}>Best</Text>
                </Text>
                <Image
                  source={
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Invest+like+the+best.png"
                  }
                  style={styles.left_logo}
                ></Image>
                <View style={styles.certicationsContainer}>
                  {bannerData.is_jamawealth ? (
                    <View style={styles.certication}>
                      <Image
                        source={
                          "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+11.png"
                        }
                        style={{ width: 18, height: 30 }}
                      />
                      <Text style={[styles.sebi, { paddingLeft: 10 }]}>
                        Goi{" "}
                        <Text style={{ fontWeight: "bold", color: "black" }}>
                          DIPP
                        </Text>{" "}
                        Registered
                      </Text>
                    </View>
                  ) : null}
                  {bannerData.is_jamawealth ? (
                    <View style={styles.certication}>
                      <Image
                        source={
                          "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+7.png"
                        }
                        style={{ width: 29, height: 25 }}
                      />
                      <Text style={[styles.sebi, { paddingLeft: 10.17 }]}>
                        ISO{" "}
                        <Text style={{ fontWeight: "bold", color: "black" }}>
                          9001
                        </Text>{" "}
                        Certified
                      </Text>
                    </View>
                  ) : null}
                  {bannerData.is_jamawealth ? (
                    <View style={styles.certication}>
                      <Image
                        source={
                          "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+7.png"
                        }
                        style={{ width: 29, height: 25 }}
                      />
                      <Text style={[styles.sebi, { paddingLeft: 10.17 }]}>
                        ISO{" "}
                        <Text style={{ fontWeight: "bold", color: "black" }}>
                          27001
                        </Text>{" "}
                        Certified
                      </Text>
                    </View>
                  ) : null}
                  <View style={styles.sebiContainer}>
                    <View style={{ paddingLeft: 0.4 }}>
                      <Text style={[styles.sebi]}>
                        SEBI Regd Investment Advisor
                      </Text>
                      <Text style={styles.ina}>{bannerData.ria_code}</Text>
                      <Text style={[styles.sebi_1]}>
                        Simply Grow Technologies Pvt Ltd.
                      </Text>
                    </View>
                  </View>
                  <View style={styles.sebiContainer}>
                    <View style={{ paddingLeft: 10.4 }}>
                      <Text style={[styles.sebi]}>
                        SEBI Registered Portfolio Manager
                      </Text>
                      <Text style={styles.ina}>INP000007881</Text>
                      <Text style={[styles.sebi_1]}>
                        {isJama() ? "Jama" : "Maxiom"} Wealth Asset Management Pvt Ltd.{" "}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
            <View style={styles.right}>
              <View style={[isMobile ? styles.mob_styl : styles.web_styl]}>
                <View style={{ alignItems: "center", marginTop: 32, width: '100%' }}>
                  {bannerData.is_jamawealth ? (
                    <Image
                      source={bannerData.logo_image_url}
                      style={{ width: '40%', height: 100, marginBottom: 25 }}
                      resizeMode={"contain"}
                    />
                  ) : null}
                </View>
                <View style={styles.inputsContainer}>
                  <View style={styles.inputContainer}>
                    <Text style={styles.mob_num}>
                      Email / Mobile / Username
                    </Text>
                    <TextInput
                      value={email}
                      style={styles.dmart_body_md}
                      placeholder={"Enter your Email / Mobile / Username"}
                      onFocus={() => {
                        this.setState({
                          emailError: "",
                          passwordError: "",
                          errorText: "",
                          captchaError: "",
                        });
                      }}
                      onChangeText={(text) => {
                        this.setState({ email: text });
                      }}
                      onKeyPress={(e) => {
                        if (e.nativeEvent.key == "Enter") {
                          this.handleContinue();
                        }
                      }}
                    />
                    {!!emailError && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {emailError || " "}
                      </Text>
                    )}
                  </View>

                  <View style={{ marginTop: -12 }}>
                    <Text style={styles.mob_num}>Password</Text>
                    <View>
                      <TextInput
                        value={this.state.password}
                        style={styles.dmart_body_md}
                        placeholder={"Enter your password"}
                        onFocus={() => {
                          this.setState({
                            emailError: "",
                            passwordError: "",
                            errorText: "",
                            captchaError: "",
                          });
                        }}
                        onChangeText={(text) => {
                          this.setState({ password: text });
                        }}
                        onKeyPress={(e) => {
                          if (e.nativeEvent.key == "Enter") {
                            this.handleContinue();
                          }
                        }}
                        secureTextEntry={!this.state.showPassword}
                      />
                      <Feather
                        name={!this.state.showPassword ? "eye-off" : "eye"}
                        color={!this.state.showPassword ? "gray" : "#410DAA"}
                        size={18}
                        style={{ position: "absolute", right: 5, bottom: 9 }}
                        onPress={() => {
                          this.setState({
                            showPassword: !this.state.showPassword,
                          });
                        }}
                      />
                    </View>
                    {!!passwordError && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {passwordError || " "}
                      </Text>
                    )}
                  </View>
                  <View style={{ marginTop: 40, marginBottom: 50 }}>
                    <ReCAPTCHA
                      ref={this.recaptchaRef}
                      size="normal"
                      onChange={(val) => this.setState({ capthaVal: val })}
                      sitekey="6Lf33eUpAAAAANArQRr2_yCDugV5TzaGBJCYTPgV"
                    />
                  </View>
                  {/* {showCaptcha ? (
                    <View style={[styles.inputContainer, { marginTop: 25 }]}>
                      <Text style={styles.mob_num}>Captcha Verification</Text>
                      <View style={styles.captchaContainer}>
                        <View
                          style={{
                            width: 160,
                            backgroundColor: "#888888",
                            padding: 6,
                          }}
                        >
                          <Text style={{ color: "#FFFFFF", fontSize: 19 }}>
                            {this.state.randomCaptcha}
                          </Text>
                        </View>
                        <TouchableOpacity onPress={this.generateCaptcha}>
                          <MaterialIcon
                            name={"refresh"}
                            style={{
                              fontSize: 32,
                              color: "#5A5A5A",
                              marginLeft: 20,
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                      <TextInput
                        value={captchaText}
                        style={styles.dmart_body_md}
                        placeholder={"Enter captcha"}
                        onFocus={() => {
                          this.setState({
                            emailError: "",
                            passwordError: "",
                            errorText: "",
                            captchaError: "",
                          });
                        }}
                        onChangeText={(text) => {
                          if (!isNaN(text)) {
                            this.setState({ captchaText: text });
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.nativeEvent.key == "Enter") {
                            this.handleContinue();
                          }
                        }}
                      />
                      {!!captchaError && (
                        <Text style={[styles.please, { color: "red" }]}>
                          {captchaError || " "}
                        </Text>
                      )}
                    </View>
                  ) : null} */}
                  {/* <View>
                        <Text style={[styles.mob_num, { paddingTop: 30 }]}>
                          Captcha
                        </Text>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <TextInput
                            value={captchaText}
                            maxLength={6}
                            style={[
                              styles.dmart_body_md,
                              { marginTop: -20, width: "50%" },
                            ]}
                            placeholder={"Enter Captcha"}
                            placeholderTextColor={"#00000030"}
                            onFocus={() => {
                              this.setState({
                                emailError: "",
                                passwordError: "",
                                errorText: "",
                                captchaError: "",
                              });
                            }}
                            onChangeText={(text) => {
                              this.setState({ captchaText: text });
                            }}
                            onKeyPress={(e) => {
                              if (e.nativeEvent.key == "Enter") {
                                this.handleContinue();
                              }
                            }}
                          />
                          <LoadCanvasTemplate reloadText="<span class='glyphicon' style='font-size:25px;color:black;padding:60px;'>&#x21bb;</span>" />
                        </View>
                        {!!captchaError && (
                          <Text style={[styles.please, { color: "red" }]}>
                            {captchaError || " "}
                          </Text>
                        )}
                      </View>


                  {!!errorText && (
                    <Text style={[styles.please, { color: "red", marginBottom: 10 }]}>
                      {errorText} {errorText.includes("User data exists") ? (<TouchableOpacity onPress={this.handleReActivateAccount}><Text style={{
                        color: "#410DAA", textDecorationLine: 'underline', fontWeight: '700'
                      }}>Click here</Text></TouchableOpacity>) : ""}
                    </Text>
                  )} */}
                </View>
                {/* {console.log("something is wrong here", this.state.capthaVal)} */}
                {isLoading ? (
                  <ActivityIndicator
                    size="large"
                    color="#410DAA"
                    style={{ paddingTop: 40 }}
                  />
                ) : (
                  <>
                    <TouchableOpacity
                      onPress={(event) => {
                        event.preventDefault();
                        Keyboard.dismiss();
                        this.handleContinue();
                      }}  
                      disabled={!this.state.capthaVal}
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: isMobile ? 40 : showCaptcha ? 0 : 40,
                      }}
                    >
                      <Text style={[styles.req_otp, { backgroundColor: !this.state.capthaVal ? "grey" : "#410DAA" }]}>Login</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        this.props.navigation.dispatch(
                          StackActions.replace("ForgotPassword")
                        );
                      }}
                    >
                      <Text style={styles.forgotPassword}>
                        Forgot Password?
                        <Text style={styles.clickHere}> Click Here </Text>
                      </Text>
                    </TouchableOpacity>
                    {is_ria ? null : (
                      <Text
                        style={[
                          styles.clickHere,
                          {
                            fontSize: 14,
                            paddingTop: 20,
                            textDecorationLine: "underline",
                          },
                        ]}
                        onPress={() =>
                          this.props.navigation.dispatch(
                            StackActions.replace("Login")
                          )
                          // this.props.navigation.navigate("Login", {})
                        }
                      >
                        Login with mobile OTP
                      </Text>
                    )}

                    {!isMobile && !is_ria && (
                      <Text
                        style={[styles.forgotPassword, { marginBottom: 50 }]}
                      >
                        Don't have an account?{" "}
                        <Text
                          style={styles.clickHere}
                          onPress={() => {
                            this.props.navigation.dispatch(
                              StackActions.replace("Login")
                            );
                          }}
                        >
                          Signup Here
                        </Text>
                      </Text>
                    )}
                  </>
                )}
              </View>
            </View>
          </View>
          {isMobile && (
            <Text style={[styles.forgotPassword, { marginBottom: 10 }]}>
              Don't have an account?{" "}
              <Text
                style={styles.clickHere}
                onPress={() => {
                  this.props.navigation.dispatch(
                    StackActions.replace("Login")
                  );
                }}
              >
                Signup Here
              </Text>
            </Text>
          )}
        </ScrollView>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexGrow: 1,
    height: "100%",
    backgroundColor: "#fff",
  },
  captchaContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: isMobile ? -10 : -20,
    marginBottom: 10,
  },
  body: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  left: {
    flex: md(width) ? 1 : 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#410DAA08",
    paddingVertical: md(width) ? 75 : 1,
  },
  right: {
    flex: 1,
  },
  mob_styl: {
    width: "80%",
    alignSelf: "center",
  },
  web_styl: {
    alignItems: "center",
  },
  best: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 33,
    lineHeight: 38,
    color: "#464E5F",
    paddingBottom: 100,
  },
  left_logo: {
    resizeMode: "contain",
    width: 403,
    height: 241,
    alignSelf: "center",
    marginBottom: 60,
  },
  mobile_verf: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 19 : 15,
    lineHeight: md(width) ? 22 : 17,
    textAlign: "center",
    color: "#121212",
    paddingTop: md(width) ? 0 : 50,
  },
  we_will: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 17 : 13,
    lineHeight: md(width) ? 20 : 18,
    textAlign: "justify",
    color: "#A7A8BB",
    paddingTop: md(width) ? 19 : 29,
  },
  mob_num: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 18,
    color: "#222225",
    paddingBottom: md(width) ? 23 : 10.5,
  },
  please: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3,
  },
  req_otp: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 47,
    borderRadius: 4,
  },
  one: {
    color: "#121212",
  },
  dmart_body_md: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "bold",
    color: "#121212",
    backgroundColor: "#f6f6f6",
    height: 41.02,
    paddingLeft: 20,
    borderRadius: 4,
  },
  sebi: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 14,
    color: "#000",
    paddingRight: 29,
  },
  sebi_1: {
    fontFamily: "Arial",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: 9,
    lineHeight: 14,
    color: "#51514E",
    paddingRight: 29,
  },
  ina: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 10,
    lineHeight: 14,
    color: "#410DAA",
  },
  img1: {
    width: 30,
    height: 28.6,
  },
  welcomeTitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    color: "#202020",
    fontWeight: "bold",
    marginBottom: 45,
    marginTop: 10,
    textAlign: "center",
  },
  inputsContainer: {
    minWidth: "75%",
  },
  inputContainer: {
    marginBottom: 37,
  },
  forgotPassword: {
    color: "#232425",
    fontSize: 15,
    fontFamily: "Roboto",
    marginTop: 18,
    textAlign: "center",
  },
  clickHere: {
    color: "#410DAA",
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 6,
    textAlign: "center",
  },
  certication: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 25,
  },
  sebiContainer: {
    flexDirection: "row",
    paddingTop: 25,
  },
  certicationsContainer: {
    flexDirection: "row",
    paddingLeft: 30.5,
    paddingTop: 65,
    flexWrap: "wrap",
    justifyContent: "center",
  },
});

const mapStateToProps = (state) => {
  return {
    userProfileData: state.userData.userProfileData,
    demoMode: state.testConfig.demoMode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveUserProfileData: (data) => dispatch(saveUserProfileData(data)),
  saveScreenConfig: (data) => dispatch(saveScreenConfig(data)),
  logIn: (time) => dispatch(logIn(time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginUsingMobile);
