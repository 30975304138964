import {
  getFolioDetails,
  getOutsideFolioDetails,
  getTaggedAssets,
  tagGoal,
  getJamaEquityStocks,
  getOutsideImportedStocks,
} from "../api/goals";
import { getCustomerIAFF } from "../api";
import { actionTypes } from "../reducers/goals";

export const detachOrAttachToGoal = (data, value, goalId, scheme) => {
  return (dispatch) => {
    const payload = {
      id: data.id,
      is_active: value.toString(),
      type: data.type,
      goal_id: goalId,
      scheme: scheme ? scheme : "",
    };

    const detach = tagGoal(payload)
      .then((json) => {
        if (json.code == 100) {
          dispatch({
            type: actionTypes.UPDATE_GOAL,
            payload: json,
          });
        }
        if (json.code == 0) {
        }
        if (json.code == 101) {
          return false;
        }
        return true;
      })
      .catch((err) => {
        return false;
      });

    return detach;
  };
};

const validateResponse = (type, response) => {
  let results = response.results;
  if (response && response.code == "100" && results && results.length > 0) {
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      results[i] = {
        ...result,
        name:
          type == "out_mf"
            ? result.amcfundscheme__name
            : result.investoraccountfund__amcfundscheme__name,
        value:
          type == "out_mf" ? result.imported_valuation : result.amount__sum,
        id:
          type == "out_mf" ? result.imported_folio_number : result.bse_folio_no,
        fundType: type,
      };
    }
  }

  return results;
};

const filterFunds = (funds, tagged = true, title) => {
  try {
    // funds =
    //   funds &&
    //   funds.filter((item, index) => {
    //     if (!tagged) {
    //       return (
    //         (item.is_mapped == tagged &&
    //           item.is_mapped_to_other_goal == tagged) ||
    //         item.header
    //       );
    //     }
    //     return item.is_mapped == tagged || item.header;
    //   });

    if (funds && funds.length > 0) {
      funds = [
        {
          header: true,
          heading: title,
          type: ''
        },
        ...funds,
      ];
    }
    return funds ? funds : [];
  } catch (error) {}
};

export const getStocks = async (goalid, tagged = true) => {
  try {
    const jama_response = [];
    // const jama_response = await getJamaEquityStocks({
    //   goal_id: goalid,
    // });
    const outside_response = await getOutsideImportedStocks({
      goal_id: goalid,
    });

    let jama_stocks = [],
      outside_stocks = [];
    if (jama_response && jama_response.code && jama_response.code == "100") {
      jama_stocks = jama_response.results;
    }
    if (
      outside_response &&
      outside_response.code &&
      outside_response.code == "100"
    ) {
      outside_stocks = outside_response.results;
    }
    let result = [];
    if (jama_stocks.length != 0) {
      jama_stocks.forEach((element) => {
        element["type"] = "jama_stocks";
      });
      result = [
        { header: true, heading: "Jama Wealth Equity", type: "jama_stocks" },
        ...jama_stocks,
      ];
    }
    if (outside_stocks.length != 0) {
      outside_stocks.forEach((element) => {
        element["type"] = "outside_stocks";
      });
      result = [
        ...result,
        {
          header: true,
          heading: "Demat Accounts",
          type: "outside_stocks",
        },
        ...outside_stocks,
      ];
    }

    return result;
  } catch (error) {
    return null;
  }
};

export const getMutualFunds = async (goal_id, tagged=true) => {
  try {
    const funds = await getCustomerIAFF({
      goal_id,
    });
    let result = [];
    if (funds && funds.code && funds.code == 100) {
      if (funds.results.length) {
        result = [
          { header: true, heading: "Fund Folios", type: "" },
          ...funds.results,
        ];
      }
    }

    const payload = {
      goal_id: goal_id,
    };
    const outsideFolios = await getOutsideFolioDetails(payload);

    let outsideFoliosResults = validateResponse("out_mf", outsideFolios);

    outsideFoliosResults = filterFunds(
      outsideFoliosResults,
      tagged,
      "Outside Funds"
    );

    return [...result, ...outsideFoliosResults];
  } catch (error) {
    return null;
  }
};

export const getTaggedMutualFunds = async (goalid, tagged = true) => {
  try {
    const payload = {
      goal_id: goalid,
    };
    const folios = await getFolioDetails(payload);
    const outsideFolios = await getOutsideFolioDetails(payload);

    let folioResults = validateResponse("jama_mf", folios);
    let outsideFoliosResults = validateResponse("out_mf", outsideFolios);

    folioResults = filterFunds(folioResults, tagged, "Inside Funds");
    outsideFoliosResults = filterFunds(
      outsideFoliosResults,
      tagged,
      "Outside Funds"
    );

    return [...folioResults, ...outsideFoliosResults];
  } catch (error) {
    return null;
  }
};

export const getAssets = async (goal_id) => {
  try {
    const assets = await getTaggedAssets({
      goal_id,
    });
    if (assets && assets.code && assets.code == 100) {
      let result = [];
      if (assets.results.length) {
        result = [
          { header: true, heading: "Assets", type: "" },
          ...assets.results,
        ];
      }
      return result;
    } else {
      return [];
    }
  } catch (error) {
    return null;
  }
};

export const getTaggedAssestsDetails = async (goalid, tagged = true) => {
  const payload = {
    goal_id: goalid,
  };
  const response = await getTaggedAssets(payload);
  if (response && response.code == 100) {
    const asset_types = {
      REA: "Apartment",
      REV: "House or Villa ",
      REL: "Land / Plot",
      EQS: "Stock",
      EQN: "National Pension Scheme - Equity",
      EQW: "Equity Mutual Fund",
      EGN: "General Equity Asset",
      DBN: "National Pension Scheme - Debt",
      DBE: "Employee Provident Fund",
      DBP: "Public Provident Fund",
      DBB: "Bond",
      DBF: "Bank FD",
      DBM: "Post Office Savings - Monthly Income Scheme",
      DBR: "Post Office Savings - Recurring Deposit Scheme",
      DBC: "Cash in Bank",
      DBL: "Cash Receivable",
      DBD: "Debt Mutual Fund",
      DGN: "General Assets",
      SSY: "Sukanya Samriddhi Yojana",
      INL: "Insurance Endowment Policy",
      INW: "Insurance Whole Life Policy",
      INU: "Unit Linked Insurance Plan",
      GDB: "Gold - Biscuits or Coins",
      GDS: "Gold - Sovereign Bonds",
      GDM: "Gold Mutual Fund",
    };

    const types = {
      RE: "Real Estate",
      EQ: "Equity",
      DB: "Debt",
      IN: "Insurance",
      SS: "Debt",
      IN: "Insurance",
      GD: "Gold",
      EG: "Equity",
      DG: "Debt",
    };

    let sections = [];
    let assets = response.results;

    assets = assets.filter((item, index) => {
      if (tagged == false) {
        return item.is_mapped == false && item.is_mapped_to_other == false;
      }
      return item.is_mapped == true;
    });

    assets = assets.filter((item, index) => {
      if (!tagged) {
        return item.is_mapped == tagged && item.is_mapped_to_other == tagged;
      }
      return item.is_mapped == tagged;
    });

    assets.sort((a, b) => a.asset_type.localeCompare(b.asset_type));

    for (let i = 0; i < assets.length; i++) {
      const id = types[assets[i].asset_type.slice(0, 2)];
      if (sections.indexOf(id) == -1) {
        sections.push(id);
        assets.splice(i, 0, {
          header: true,
          sectionTitle: id,
          id,
        });
      } else {
        assets[i]["header"] = false;
        assets[i]["asset_type_name"] = asset_types[assets[i].asset_type];
        assets[i]["id"] = id;
      }
    }

    assets.sort((a, b) => {
      return a.id - b.id;
    });

    return assets;
  } else {
    return null;
  }
};
