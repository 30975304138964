import React, { Component } from "react";
import {
  View,
  StyleSheet,
  SafeAreaView,
  Animated,
  Image,
  Platform,
  TouchableOpacity,
  ScrollView,
  Dimensions,
} from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import AntIcon from "@expo/vector-icons/AntDesign";
import { connect } from "react-redux";
import theme from "../../../static/theme";
import amplitude from "../../../utils/amplitude";
import Divider from "../../../components/Common/divider";
import Store from "../../../store";
import {
  jamaStockTagUntag,
  outsideStockTagUntag,
  getAdvisorDashboardDetials,
  getGoalDetails,
} from "../../../api/goals";
import { actionTypes } from "../../../reducers/goals";

import CustomModal from "../../../components/Common/Modals/CustomModal";
import { ModalContent } from "../components/modalContent";
import numberFormat from "../../../utils/numberFormat";
import { Tabs } from "../components/tabs";
import { NewHeading } from "../../../components/Common/newHeading";
import {
  detachOrAttachToGoal,
  getStocks,
  getMutualFunds,
  getAssets,
} from "../../../actions/goals";
import { TaggedStockStrip } from "../components/TaggedStockStrip";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import GoalTagMutualFunds from "./goalTagMF";
import moment from "moment";
import { encryptToken, getUserDetails, isMobile } from "../../../utils";
const { width } = Dimensions.get("window");
import { ShowToast } from "../../../components/Common/Toast";
import { MAIN_URL } from "../../../api/urls";
import { GoalCard } from "../components/goalCard";
import {
  getAdvisoryTracker,
  mapInvestorAccountEntityToGoal,
} from "../../../api";
import { TaggedMFStrip } from "../components/TaggedMFStrip";
import { TaggedAssetStrip } from "../components/TaggedAssetStrip";

const EmptyTaggings = ({ image, title, description, style }) => {
  return (
    <View
      style={[
        { justifyContent: "center", alignItems: "center", paddingBottom: 140 },
        style ? { ...style } : {},
      ]}
    >
      <Image
        source={image}
        style={{
          width: 80,
          height: 80,
          marginTop: 25,
          marginBottom: 20,
        }}
        resizeMode={"contain"}
      />

      <Text
        style={{
          fontSize: 15,
          color: "#121212",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {title}
      </Text>
      {description}
    </View>
  );
};

const JAMA_FOLIOS = "Jama Folios";
const OUTSIDE_FOLIOS = "Outside Folios";
const ASSETS = "Assets";
const STOCKS = "STOCKS";

class GoalDetails extends Component {
  constructor(props) {
    super(props);
    let goal_id = null;
    let goal_name = null;
    let activeIndex = 1;
    const { params } = this.props.route;
    if (params) {
      goal_id = params.goal_id || null;
      goal_name = params.goal_name || null;
      activeIndex = params.activeIndex || 1;
    }
    let is_preferred_circle = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    this.state = {
      enableScrollViewScroll: true,
      [JAMA_FOLIOS]: [],
      [OUTSIDE_FOLIOS]: [],
      [ASSETS]: [],
      [STOCKS]: [],
      sips: [],
      percentagecomplete: "",
      runrate: "",
      amountaccumulated: "",
      monthly: "",
      loading: true,
      investmentType: JAMA_FOLIOS,
      visibleModal: false,
      activeIndex,
      SlideInLeft: new Animated.Value(0),
      slided: false,
      scrollY: new Animated.Value(0),
      stickyHeaderIndices: [],
      settingStickyHeaders: false,
      goal_id,
      goal_name,
      showToast: "false",
      selectedIndex: 0,
      showAddBtn: true,
      showLoader: false,
      mfCount: "0",
      showErrorToast: false,
      is_plan_advises_unlimited: false,
      assetTypes: {
        REA: "Real Estate",
        REV: "Real Estate",
        REL: "Real Estate",
        EQS: "Equity",
        EQN: "Equity",
        EQW: "Equity",
        EGN: "Equity",
        DBN: "Debt",
        DBE: "Debt",
        DBP: "Debt",
        DBB: "Debt",
        DBF: "Debt",
        DBM: "Debt",
        DBR: "Debt",
        DBC: "Debt",
        DBL: "Debt",
        DBD: "Debt",
        DGN: "Debt",
        SSY: "Debt",
        INL: "Insurance",
        INW: "Insurance",
        INU: "Insurance",
        GDB: "Gold",
        GDS: "Gold",
        GDM: "Gold",
      },
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("goalDetails", { path: global.location.href });
    } else {
      amplitude.logEvent("goalDetails", {
        path: this.props.navigation.getState().routeName,
      });
    }
    await this.setData();
  }

  onEnableScroll = (value) => {
    this.setState({
      enableScrollViewScroll: value,
    });
  };

  setData = async () => {
    this.setState({ isDataLoading: true });
    let response = await getAdvisoryTracker();
    let userData = await getUserDetails();
    this.setState({
      is_plan_advises_unlimited: userData.is_plan_advises_unlimited,
    });
    if (response && response.code && response.code == "100") {
      this.setState({
        mfCount:
          response.result.mf_adv > 0 ? response.result.mf_adv.toString() : "0",
      });
    }
    const goal_id = await this.getGoalId();
    try {
      let resposne = null;
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        response = await getGoalDetails(
          goal_id,
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        response = await getGoalDetails(goal_id);
      }
      if (response && response.code == 100) {
        const { results = [] } = response;
        if (results.length) {
          const goal = results[0];
          this.setState(
            { goal, isDataLoading: false, loading: false },
            async () => {
              await this._loadData(JAMA_FOLIOS, goal_id);
              await this._loadData(ASSETS, goal_id);
              await this._loadData(STOCKS, goal_id);
            }
          );
          return;
        }
      }
      this.setState({ isDataLoading: false });
    } catch (error) {
      this.setState({ isDataLoading: false });
    }
  };

  detachStock = async (type, goal_id, perc_val, dematId) => {
    this.setState({ isDetaching: true, showToast: "false", toastTitle: "" });
    let resp;
    if (type == "jama_stocks") {
      resp = await jamaStockTagUntag({
        goal_id: `${goal_id}`,
        da_id: `${dematId}`,
        perc_val: `${perc_val}`,
      });
    } else {
      resp = await outsideStockTagUntag({
        goal_id: `${goal_id}`,
        da_id: `${dematId}`,
        perc_val: `${perc_val}`,
      });
    }

    if (resp && resp.code && resp.code == "100") {
      await this._loadData(STOCKS, goal_id);
      this.refreshGoals();
      this.setState({
        isDetaching: false,
        activeEditId: null,
        showToast: "true",
        toastTitle: `Stock detached from ${this.props.route.params.goal_name}.`,
      });
    } else {
      this.setState({ isDetaching: false, activeEditId: null });
    }
  };

  detachMF = async (goal_id, perc_val, entityId, fundType = null) => {
    this.setState({ isDetaching: true, showToast: "false", toastTitle: "" });

    let resp = await mapInvestorAccountEntityToGoal({
      investor_account_entity_type:
        fundType && fundType == "out_mf"
          ? "InvestorAccountFundImported"
          : "InvestorAccountFundFolio",
      investor_account_entity_id: entityId,
      old_perc_val: perc_val,
      perc_val: 0,
      goal_id: parseInt(goal_id),
    });

    if (resp && resp.code && resp.code == 100) {
      await this._loadData(JAMA_FOLIOS, goal_id);
      this.refreshGoals();
      this.setState({
        isDetaching: false,
        activeEditId: null,
        showToast: "true",
        toastTitle: `MF folio detached from ${this.props.route.params.goal_name}`,
      });
    } else {
      this.setState({ isDetaching: false, activeEditId: null });
    }
  };

  getGoalId = async () => {
    const { params } = this.props.route;
    let goal_id = null;
    if (params) {
      goal_id = params.goal_id || null;
    }
    return goal_id;
  };

  setActiveTab = () => {
    const {
      [JAMA_FOLIOS]: mf,
      [ASSETS]: assets,
      [STOCKS]: stocks,
    } = this.state;
    const all_items_length = stocks.length + mf.length + assets.length;
    if (all_items_length != 0) {
      const arr = [stocks.length, mf.length, assets.length];
      let activeIndex = arr.indexOf(Math.max(...arr));
      this.setState({ activeIndex: activeIndex + 1, isDataLoading: false });
    }
  };

  setGoalDataHelper = (goals, goal_id) => {
    const goal = goals.filter((goal) => {
      return goal.customergoal_id == goal_id;
    });
    if (goal.length != 0) {
      this.setState({ goal: goal[0], loading: false }, () => {});
    } else {
      this.setState({ loading: false });
    }
  };

  refreshGoals = async () => {
    const goal_id = await this.getGoalId();
    const json = await getAdvisorDashboardDetials();
    if (json) {
      Store.dispatch({
        type: "USER_DASHBOARD",
        payload: json,
      });
      if (json && json.goals) {
        Store.dispatch({
          type: actionTypes.ADD_GOALS,
          payload: json.goals,
        });
        this.setGoalDataHelper(json.goals, goal_id);
      }
      this.setState({ isDetaching: false });
    } else {
      this.setState({ isDetaching: false });
    }
  };

  handleScroll = (event) => {
    const { y } = event.nativeEvent.contentOffset;
    const { HEADER_SCROLL_DISTANCE } = this.state;
    try {
      if (y >= HEADER_SCROLL_DISTANCE) {
        this.setState({ showStatusIndicator: true });
      } else {
        this.setState({ showStatusIndicator: false });
      }
    } catch (error) {
      this.setState({ showStatusIndicator: false });
    }
  };

  scrollHideLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    const HEADER_MIN_HEIGHT = 66;
    const HEADER_SCROLL_DISTANCE = height - HEADER_MIN_HEIGHT;
    this.setState({
      HEADER_MAX_HEIGHT: height,
      HEADER_SCROLL_DISTANCE,
      calculatingHeight: false,
    });
  };

  _loadData = async (type, goalid) => {
    try {
      const currentData = await this.getActiveTabData();
      if ((currentData && currentData.length == 0) || !currentData) {
        this.setState({ isDataLoading: true });
      }
      let response;
      if (type == JAMA_FOLIOS) {
        response = await getMutualFunds(goalid, true);
      } else if (type == ASSETS) {
        response = await getAssets(goalid, true);
      } else if (type == STOCKS) {
        response = await getStocks(goalid, true);
      }

      this.setState({
        [type]: response,
        isDataLoading: false,
        isDetaching: false,
      });
    } catch (error) {
      this.setState({ isDataLoading: false, isDetaching: false });
    }
  };

  renderListItem = ({ item, index }) => {
    const { activeIndex } = this.state;
    switch (activeIndex) {
      case 1:
        return this._renderStocks(item, index);
      case 2:
        return this._renderMF(item, index);
      case 3:
        return this._renderAssets(item, index);
      default:
        return this._renderStocks(item, index);
    }
  };

  startAnimation = (value, id) => {
    const { slided } = this.state;
    let temp_state = {};
    if (value) {
      temp_state["activeEditId"] = id;
    }

    this.setState({ slided: !slided, ...temp_state }, () => {
      return Animated.timing(this.state.SlideInLeft, {
        toValue: !value ? 0 : -100,
        duration: 500,
        useNativeDriver: true,
      }).start(() => {
        if (!value) {
          this.setState({ activeEditId: null });
        }
      });
    });
  };

  _renderStocks = (stock, index) => {
    if (stock.header) {
      return (
        <NewHeading
          title={stock.heading}
          style={{
            fontSize: 15,
            marginTop: 15,
            marginBottom: -4,
            alignItems: "center",
          }}
        />
      );
    } else {
      return (
        <>
          <Text
            style={{
              fontSize: 13,
              color: "#121212",
              paddingLeft: 16,
              marginTop: 10,
            }}
          >
            {stock.account_details ? stock.account_details : "Demat Account"}
          </Text>
          <Text
            style={{
              fontSize: 13,
              color: "#121212",
              paddingLeft: 16,
              marginTop: 10,
            }}
          >
            Total: ₹
            {stock.total_value ? numberFormat(stock.total_value.toFixed(0)) : 0}
          </Text>
          <TaggedStockStrip
            type={stock.type}
            data={stock}
            percentage={stock.this_goal_perc}
            style={{
              paddingHorizontal: 16,
            }}
            SlideInLeft={this.state.SlideInLeft}
            slided={this.state.slided}
            startAnimation={this.startAnimation}
            onEditPress={(activeEditId) => {
              this.startAnimation(true, activeEditId);
            }}
            onCancelPress={() => {
              this.startAnimation(false);
            }}
            id={stock.account_details + "_" + index}
            activeId={this.state.activeEditId}
            isLoading={
              this.state.isDetaching && index == this.state.selectedIndex
            }
            index={index}
            detachStyle={{}}
            dematId={stock.pk}
            onPressDetach={(type, percentage, dematId, index) => {
              this.setState({ selectedIndex: index });
              this.detachStock(
                type,
                this.props.route.params.goal_id,
                0,
                dematId
              );
            }}
            onPressAttach={() =>
              this.props.navigation.replace("AttachExisting", {
                goal_id: this.props.route.params.goal_id,
                goal_name: this.props.route.params.goal_name,
                stock: stock,
              })
            }
          />
        </>
      );
    }
  };

  _renderMF = (mf, index) => {
    if (mf.header) {
      return (
        <NewHeading
          title={mf.heading}
          style={{
            fontSize: 15,
            marginTop: 15,
            marginBottom: -4,
            alignItems: "center",
          }}
        />
      );
    } else {
      return (
        <>
          <Text
            style={{
              fontSize: 13,
              color: "#121212",
              paddingLeft: 16,
              marginTop: 10,
            }}
          >
            {mf.fundType == "out_mf"
              ? mf.amcfundscheme__name
              : mf.investoraccountfund__amcfundscheme__name}
          </Text>
          <Text
            style={{
              fontSize: 13,
              color: "#121212",
              paddingLeft: 16,
              marginTop: 10,
            }}
          >
            Folio No:{" "}
            {mf.fundType == "out_mf"
              ? mf.imported_folio_number
              : mf.bse_folio_no}
            {",  "}Latest Value: ₹
            {numberFormat(mf.latest_value_at_nav.toFixed(0))}
          </Text>
          <TaggedMFStrip
            data={mf}
            percentage={mf.this_goal_perc}
            style={{
              paddingHorizontal: 16,
            }}
            SlideInLeft={this.state.SlideInLeft}
            slided={this.state.slided}
            startAnimation={this.startAnimation}
            onEditPress={(activeEditId) => {
              this.startAnimation(true, activeEditId);
            }}
            onCancelPress={() => {
              this.startAnimation(false);
            }}
            id={
              mf.fundType == "out_mf"
                ? mf.amcfundscheme__name
                : mf.investoraccountfund__amcfundscheme__name + "_" + index
            }
            activeId={this.state.activeEditId}
            isLoading={
              this.state.isDetaching && index == this.state.selectedIndex
            }
            index={index}
            detachStyle={{}}
            entityId={
              mf.fundType == "out_mf"
                ? mf.investoraccountfundimported_id
                : mf.investoraccountfundfolio_id
            }
            onPressDetach={(percentage, entityId, index) => {
              this.setState({ selectedIndex: index });
              this.detachMF(
                this.props.route.params.goal_id,
                percentage,
                entityId,
                mf.fundType
              );
            }}
            onPressAttach={() =>
              this.props.navigation.replace("AttachExisting", {
                goal_id: this.props.route.params.goal_id,
                active: this.state.activeIndex,
                goal_name: this.props.route.params.goal_name,
                mf: mf,
              })
            }
          />
        </>
      );
    }
  };

  _renderAssets = (asset, index) => {
    if (asset.header) {
      return (
        <NewHeading
          title={asset.heading}
          style={{
            fontSize: 15,
            marginTop: 15,
            marginBottom: -4,
            alignItems: "center",
          }}
        />
      );
    } else {
      return (
        <>
          <Text
            style={{
              fontSize: 13,
              color: "#121212",
              paddingLeft: 16,
              marginTop: 10,
            }}
          >
            {asset.name}
          </Text>
          <Text
            style={{
              fontSize: 13,
              color: "#121212",
              paddingLeft: 16,
              marginTop: 10,
            }}
          >
            Asset Type: {this.state.assetTypes[asset.asset_type]}
            {",  "}Latest Value: ₹{numberFormat(asset.latest_value.toFixed(0))}
          </Text>
          <TaggedAssetStrip
            data={asset}
            percentage={asset.this_goal_perc}
            style={{
              paddingHorizontal: 16,
            }}
            SlideInLeft={this.state.SlideInLeft}
            slided={this.state.slided}
            startAnimation={this.startAnimation}
            onEditPress={(activeEditId) => {
              this.startAnimation(true, activeEditId);
            }}
            onCancelPress={() => {
              this.startAnimation(false);
            }}
            id={asset.name + "_" + index}
            activeId={this.state.activeEditId}
            isLoading={
              this.state.isDetaching && index == this.state.selectedIndex
            }
            index={index}
            detachStyle={{}}
            entityId={asset.investoraccountasset_id}
            onPressDetach={(percentage, entityId, index) => {
              this.setState({ selectedIndex: index });
              this.detachAsset(
                this.props.route.params.goal_id,
                percentage,
                entityId
              );
            }}
            onPressAttach={() =>
              this.props.navigation.replace("AttachExisting", {
                goal_id: this.props.route.params.goal_id,
                active: this.state.activeIndex,
                goal_name: this.props.route.params.goal_name,
                asset: asset,
              })
            }
          />
        </>
      );
    }
  };

  _renderMutualFund = (item, index) => {
    if (item) {
      if (item.header) {
        return (
          <NewHeading
            title={item.sectionTitle}
            style={{ alignItems: "center" }}
            headingTitleContainerStyle={{
              marginLeft: 5,
            }}
          />
        );
      } else {
        return (
          <GoalTagMutualFunds
            data={item}
            index={index}
            SlideInLeft={this.state.SlideInLeft}
            slided={this.state.slided}
            startAnimation={this.startAnimation}
            activeFundId={this.state.activeEditId}
            onEditFund={(activeEditId) => {
              this.startAnimation(true, activeEditId);
            }}
            onDetachFund={() => {
              this.detachFund(item);
            }}
            onCancelPress={() => {
              this.startAnimation(false);
            }}
            isLoading={this.state.isDetaching}
          />
        );
      }
    }
  };

  detachAsset = async (goal_id, perc_val, entityId) => {
    this.setState({ isDetaching: true, showToast: "false", toastTitle: "" });

    let resp = await mapInvestorAccountEntityToGoal({
      investor_account_entity_type: "InvestorAccountAsset",
      investor_account_entity_id: entityId,
      old_perc_val: perc_val,
      perc_val: 0,
      goal_id: parseInt(goal_id),
    });

    if (resp && resp.code && resp.code == 100) {
      await this._loadData(ASSETS, goal_id);
      this.refreshGoals();
      this.setState({
        isDetaching: false,
        activeEditId: null,
        showToast: "true",
        toastTitle: `Asset detached from ${this.props.route.params.goal_name}.`,
      });
    } else {
      this.setState({ isDetaching: false, activeEditId: null });
    }
  };

  detachFund = (item) => {
    this.setState({ isDetaching: true, showToast: "false", toastTitle: "" });
    this.setState({ isDetaching: true });

    const config = {
      id:
        item.fundType == "out_mf"
          ? item.investoraccountfundimported_id
          : item.folio_id,
      type: item.type,
    };

    this.props
      .detachOrAttachToGoal(
        config,
        false,
        this.state.goal_id,
        item.fundType == "jama_mf"
          ? item.investoraccountfund__amcfundscheme__pk
          : ""
      )
      .then(async () => {
        await this._loadData(JAMA_FOLIOS, this.state.goal_id);
        this.setState({
          isDataLoading: false,
          isDetaching: false,
          showToast: "true",
          toastTitle: `Mutual fund detached from ${this.props.route.params.goal_name}`,
        });
      })
      .catch(() => {
        this.setState({ isDetaching: false });
      });
  };

  itemSeparator = (item) => {
    if (item.leadingItem.header) return null;
    else {
      return (
        <Divider width={1} color="#F2F2F2" style={{ marginHorizontal: 16 }} />
      );
    }
  };

  getActiveTabData = () => {
    const { activeIndex } = this.state;
    let activeTab;

    switch (activeIndex) {
      case 1:
        activeTab = STOCKS;
        break;
      case 2:
        activeTab = JAMA_FOLIOS;
        break;
      case 3:
        activeTab = ASSETS;
        break;
      default:
        activeTab = JAMA_FOLIOS;
    }

    const data = this.state[activeTab];
    return data && data.length ? data : [];
  };

  scrollToTop = () => {
    this._scrollView.getNode().scrollToOffset({ animated: true, offset: 0 });
  };

  renderHeader = () => {
    return (
      <View style={{}}>
        <Text
          style={{
            color: "#121212",
            fontSize: 16,
            paddingVertical: 15,
            paddingHorizontal: 16,
            backgroundColor: "#F2F2F2",
            zIndex: -5,
          }}
        >
          Money attached to this goal
        </Text>
        <Tabs
          activeIndex={this.state.activeIndex}
          onPressTab={(index) => {
            if (index != this.state.activeIndex) {
              this.setState({
                activeIndex: index,
                activeEditId: null,
                slided: false,
                isDetaching: false,
              });
            }
            this.scrollToTop();
          }}
          style={{ backgroundColor: "white" }}
        />
        <Divider width={1} color="#F2F2F2" style={{ marginHorizontal: 16 }} />
      </View>
    );
  };

  renderEmptyComponent = () => {
    const { isDataLoading, activeIndex } = this.state;

    if (isDataLoading) {
      return (
        <View
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="small" color="#410DAA" />
        </View>
      );
    }
    let image = "",
      flag = false,
      title = "",
      description = null;
    switch (activeIndex) {
      case 1:
        image = require("../../../assets/no_assets.png");
        title = "Can't see any stocks Attached?";
        description = (
          <Text
            style={{
              fontSize: 12,
              color: "#465964",
              textAlign: "center",
              marginTop: 5,
              paddingHorizontal: 26,
            }}
          >
            Press{" "}
            <Text
              style={{
                fontSize: 12,
                color: "#465964",
                fontWeight: "bold",
              }}
            >
              Act Now{" "}
            </Text>
            to make fresh Investments OR Import your existing stocks
          </Text>
        );
        flag = true;
        break;
      case 2:
        image = require("../../../assets/no_assets.png");
        title = "Can't see any funds Attached?";
        description = (
          <Text
            style={{
              fontSize: 12,
              color: "#465964",
              textAlign: "center",
              marginTop: 5,
              paddingHorizontal: 26,
            }}
          >
            Press{" "}
            <Text
              style={{
                fontSize: 12,
                color: "#465964",
                fontWeight: "bold",
              }}
            >
              Act Now{" "}
            </Text>
            to make fresh Investments OR Import your existing funds
          </Text>
        );
        flag = true;
        break;
      case 3:
        image = require("../../../assets/no_assets.png");
        title = "Can't see any assets Attached?";
        description = (
          <Text
            style={{
              fontSize: 12,
              color: "#465964",
              textAlign: "center",
              marginTop: 5,
              paddingHorizontal: 26,
            }}
          >
            Press{" "}
            <Text
              style={{
                fontSize: 12,
                color: "#465964",
                fontWeight: "bold",
              }}
            >
              Add Assets{" "}
            </Text>
            to attach them to your goals
          </Text>
        );
        flag = true;
        break;
    }
    if (flag)
      return (
        <EmptyTaggings image={image} title={title} description={description} />
      );
    return null;
  };

  handleClick = () => {
    // this.props.navigation.goBack();
    this.props.navigation.navigate("Goals");
  };

  handleEdit = () => {
    const { goal } = this.state;
    const { customergoal_id } = goal;
    if (customergoal_id) {
      this.props.navigation.navigate("AddGoal", {
        goal_id: customergoal_id,
        from: "MainGoalScreen",
      });
    }
  };

  openRetirementPlanner = async () => {
    const { token } = getUserDetails();
    const encryptedToken = encryptToken(token);
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    const url =
      MAIN_URL +
      "/app/rnw_to_web_login?token=" +
      encryptedToken +
      "&is_rnw=1&redirection_url=/app/retirement/age/";

    let popup_window = window.open(
      url,
      hostname,
      "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=yes,width=1200,height=700"
    );

    try {
      popup_window.focus();
    } catch (e) {
      alert(
        "Pop-up Blocker is enabled! Please add this site to your exception list."
      );
    }
  };

  render() {
    const { goal } = this.state;
    const { HEADER_MAX_HEIGHT, HEADER_SCROLL_DISTANCE } = this.state;

    const data = this.getActiveTabData();
    const isAtRisk = goal && goal.status_type == "AT_RISK";

    let scrollY, headerTranslate;
    if (HEADER_MAX_HEIGHT && HEADER_SCROLL_DISTANCE) {
      scrollY = Animated.add(this.state.scrollY, 0);
      headerTranslate = scrollY.interpolate({
        inputRange: [0, HEADER_SCROLL_DISTANCE],
        outputRange: [0, -HEADER_SCROLL_DISTANCE],
        extrapolate: "clamp",
      });
    }

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff", maxWidth: 800 }}>
        {this.state.loading ? (
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ActivityIndicator
              size="large"
              color={theme.colors.NEW_BRAND_COLOR}
            />
          </View>
        ) : (
          <>
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={["Goal Details"]}
              onPressBack={this.handleClick}
            >
              {this.state.showToast == "true" ? (
                <ShowToast
                  showToast={true}
                  type="status"
                  title={this.state.toastTitle}
                  duration="5"
                />
              ) : null}
              {this.state.showErrorToast == "true" ? (
                <ShowToast
                  showToast={true}
                  type="status"
                  title="Error while loading BSE url for nominee authentication. kindly contact your advisor."
                  duration="10"
                />
              ) : null}
              <TouchableOpacity
                style={{
                  position: "absolute",
                  right: 16,
                }}
                onPress={this.handleEdit}
              >
                <Image
                  source={require("../../../assets/edit.png")}
                  style={{
                    width: 19,
                    height: 19,
                    alignSelf: "flex-end",
                  }}
                  resizeMode={"contain"}
                />
              </TouchableOpacity>
            </NavigationHeader>
            {this.state.showLoader ? (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator size={25} color="#410DAA" />
                <Text
                  style={{ fontSize: 16, fontWeight: "bold", marginTop: 10 }}
                >
                  You are being redirected to BSE nominee authentication page
                </Text>
              </View>
            ) : (
              <ScrollView
                style={{ flex: 1 }}
                showsVerticalScrollIndicator={true}
              >
                {goal && (
                  <View style={{ flex: 1, zIndex: -1 }}>
                    <View
                      style={{
                        width: isMobile ? "92%" : "96%",
                        marginLeft: isMobile ? "4%" : "2%",
                        marginRight: isMobile ? "4%" : "2%",
                        marginTop: isMobile ? "4%" : "2%",
                        marginBottom: isMobile ? "4%" : "2%",
                      }}
                    >
                      <GoalCard
                        data={goal}
                        showActNow={false}
                        navigation={this.props.navigation}
                      />
                    </View>
                    <View style={{ zIndex: -100 }}>
                      <Animated.FlatList
                        data={data || []}
                        renderItem={this.renderListItem}
                        ListHeaderComponent={this.renderHeader}
                        ListEmptyComponent={this.renderEmptyComponent}
                        extraData={[this.state]}
                        keyExtractor={(item, index) => index.toString()}
                        ItemSeparatorComponent={this.itemSeparator}
                        style={{}}
                        showsVerticalScrollIndicator={true}
                        initialNumToRender={10}
                        stickyHeaderIndices={[0]}
                        contentContainerStyle={{
                          flexGrow: 1,
                          paddingTop: HEADER_MAX_HEIGHT,
                          paddingBottom: 200,
                        }}
                        ref={(c) => (this._scrollView = c)}
                        scrollEventThrottle={1}
                        onScroll={Animated.event(
                          [
                            {
                              nativeEvent: {
                                contentOffset: { y: this.state.scrollY },
                              },
                            },
                          ],
                          {
                            useNativeDriver: true,
                            listener: (event) => {
                              this.handleScroll(event);
                            },
                          }
                        )}
                      />
                    </View>
                  </View>
                )}
              </ScrollView>
            )}
            <CustomModal
              visible={this.state.visibleModal}
              showCloseButton={true}
              closeModal={() => {
                this.setState({ visibleModal: false, showAddBtn: true });
              }}
            >
              <ModalContent
                isAtRisk={isAtRisk}
                basicPlan={false}
                text1={
                  this.state.activeIndex == 1
                    ? "Buy stocks for this goal"
                    : this.state.activeIndex == 2
                    ? "Buy funds for this goal"
                    : "Add new asset to goal"
                }
                text2={
                  this.state.activeIndex == 1
                    ? "Attach stock holdings to goal"
                    : this.state.activeIndex == 2
                    ? "Attach existing funds to goal"
                    : "Attach existing asset to goal"
                }
                activeIndex={this.state.activeIndex}
                onTalkToAdvisor={() => {
                  this.setState(
                    { visibleModal: false, showAddBtn: true },
                    () => {
                      this.props.navigation.navigate("ScheduleCall", {});
                    }
                  );
                }}
                onMakeFresInvestment={async () => {
                  this.setState({ visibleModal: false, showAddBtn: true });
                  if (this.state.activeIndex == 1) {
                    if (
                      Store.getState().userData.userProfileData
                        .is_equitycustomer
                    ) {
                      this.props.navigation.navigate("App", {
                        screen: "Dashboard",
                        params: {
                          screen: "Portfolio",
                          params: {
                            screen: "UpcomingInvestments",
                            params: {
                              showTopupToast: "true",
                              customerGoalId: this.state.goal.customergoal_id,
                              customerGoalName: this.state.goal.name,
                            },
                          },
                        },
                      });
                    } else {
                      // this.props.navigation.navigate("App", {
                      //   screen: "Dashboard",
                      //   params: {
                      //     screen: "Dashboard",
                      //     params: {
                      //       screen: "ScheduleCall",
                      //       params: {
                      //         topic: "DE",
                      //       },
                      //     },
                      //   },
                      // });
                      this.props.navigation.navigate("App", {
                        screen: "Plans",
                        params: {
                          screen: "PlansHome",
                          params: {
                            activeTabIndex: 0,
                          },
                        },
                      });
                    }
                  } else {
                    if (this.state.is_plan_advises_unlimited) {
                      this.props.navigation.navigate("App", {
                        screen: "ViewMode",
                        params: {
                          screen: "InvestForGoals",
                          params: {
                            showHeader: true,
                            goal_id: this.state.goal.customergoal_id,
                          },
                        },
                      });
                    } else if (
                      this.state.mfCount == "0" &&
                      this.state.is_preferred_circle
                    ) {
                      this.setState({
                        showToast: "true",
                        toastTitle:
                          "No mutual funds advices remaining, kindly contact advisor.",
                      });
                    } else {
                      this.props.navigation.navigate("App", {
                        screen: "ViewMode",
                        params: {
                          screen: "InvestForGoals",
                          params: {
                            showHeader: true,
                            goal_id: this.state.goal.customergoal_id,
                          },
                        },
                      });
                    }
                  }
                }}
                onAttachExisting={() => {
                  this.setState(
                    { visibleModal: false, showAddBtn: true },
                    () => {
                      const { goal_id, goal_name } = this.state;
                      this.props.navigation.replace("AttachExisting", {
                        goal_id,
                        active: this.state.activeIndex,
                        goal_name,
                      });
                    }
                  );
                }}
              />
            </CustomModal>
            {this.state.showAddBtn && (
              <View>
                {isAtRisk && (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingHorizontal: 16,
                      backgroundColor: "white",
                      paddingVertical: 2,
                    }}
                  >
                    <AntIcon
                      type="AntDesign"
                      name="warning"
                      style={{ fontSize: 16, color: "red", paddingRight: 10 }}
                    />
                    <Text
                      style={{
                        fontSize: 13,
                        color: "#410DAA",
                        fontWeight: "500",
                      }}
                    >
                      You are at risk of not reaching this goal,{" "}
                      {this.state.activeIndex == 1
                        ? "Add stocks to reduce deficit."
                        : this.state.activeIndex == 2
                        ? "Add funds to reduce deficit."
                        : "Add assets to reduce deficit."}
                    </Text>
                  </View>
                )}
                <TouchableOpacity
                  onPress={() => {
                    if (this.state.activeIndex == 3) {
                      this.props.navigation.replace("AttachExisting", {
                        goal_id: this.state.goal_id,
                        active: 3,
                        goal_name: this.props.route.params.goal_name,
                      });
                    } else {
                      this.setState({ visibleModal: true, showAddBtn: false });
                    }
                  }}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: 40,
                    borderRadius: 6,
                    width: isMobile ? "100%" : "30%",
                    marginLeft: isMobile ? 0 : "35%",
                    marginRight: isMobile ? 0 : "35%",
                    paddingVertical: 12,
                    marginTop: 10,
                    backgroundColor: "#410DAA",
                  }}
                >
                  <Text style={{ color: "white", fontSize: 14 }}>
                    {this.state.activeIndex == 3
                      ? "Add assets to goal"
                      : isAtRisk
                      ? this.state.activeIndex == 1
                        ? "Add stocks to goal"
                        : "Add funds to goal"
                      : "Top-Up Goal "}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
            <View style={{ height: width < 1200 ? 67 : 15 }}></View>
          </>
        )}
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => ({
  goals: state.goals.goals,
});

const mapDispatchToProps = (dispatch) => {
  return {
    detachOrAttachToGoal: (data, value, id, scheme) =>
      dispatch(detachOrAttachToGoal(data, value, id, scheme)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalDetails);
