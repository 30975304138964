import React from "react";
import {
  StyleSheet,
  TextInput,
  Text,
  View,
  Image,
  ScrollView,
  Picker,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  StatusBar,
  SafeAreaView,
} from "react-native";
import { md } from "../../../Responsive";
const { width } = Dimensions.get("window");
import Header from "../header_after_login";
import {
  completeMinDetails,
  getUserDetails,
  getWindowSize,
  isMobile,
  isWeb,
  navigateToTransaction,
} from "../../../utils";
import RadioButton from "../../onboarding/Radiobutton";
import { financialInfo, getCustomerDetails } from "../../../api";
import Progressbar from "../progressbar";
import { saveUserProfileData } from "../../../actions";
import { connect } from "react-redux";
import { Popable } from "react-native-popable";
import Icon from "@expo/vector-icons/FontAwesome";
import { ShowToast } from "../../../components/Common/Toast";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
// import { SafeAreaView } from "react-native-safe-area-context";
import { validateInput } from "../../../utils/validateInput";
import Store from "../../../store";

const APPLICANTINCOMESLAB = [
  // {
  //   key: 0,
  //   label: "Select",
  //   value: null,
  // },
  {
    key: 1,
    label: "Below 1 Lakh",
    value: "31",
  },
  {
    key: 2,
    label: "> 1 <= 5 Lacs",
    value: "32",
  },
  {
    key: 3,
    label: ">5 <= 10 Lacs",
    value: "33",
  },
  {
    key: 4,
    label: ">10 <= 25 Lacs",
    value: "34",
  },
  {
    key: 5,
    label: ">25 <= 1 Crore",
    value: "35",
  },
  {
    key: 6,
    label: "Above 1 Crore",
    value: "36",
  },
];

const OCCUPATIONCODES = [
  // { label: "Select", value: null },
  { label: "Business", value: "01" },
  { label: "Service", value: "02" },
  { label: "Professional", value: "03" },
  { label: "Agriculturist", value: "04" },
  { label: "Retired", value: "05" },
  { label: "Housewife", value: "06" },
  { label: "Student", value: "07" },
  { label: "Others", value: "08" },
  { label: "Doctor", value: "09" },
  { label: "Private Sector service", value: "41" },
  { label: "Public Sector Service", value: "42" },
  { label: "Forex Dealer", value: "43" },
];

const sourceofwealth = [
  // {
  //   key: 0,
  //   label: "Select",
  //   value: null,
  // },
  {
    key: 1,
    label: "Salary",
    value: "01",
  },
  {
    key: 2,
    label: "Business Income",
    value: "02",
  },
  {
    key: 3,
    label: "Gift",
    value: "03",
  },
  {
    key: 4,
    label: "Ancestral Property",
    value: "04",
  },
  {
    key: 5,
    label: "Rental Income",
    value: "05",
  },
  {
    key: 6,
    label: "Prize Money",
    value: "06",
  },
  {
    key: 7,
    label: "Royalty",
    value: "07",
  },
  {
    key: 8,
    label: "Others",
    value: "08",
  },
];

const taxstatuses = [
  // {
  //   key: 1,
  //   label: "Select",
  //   value: null,
  // },
  {
    key: 2,
    label: "Individual",
    value: "01",
  },
  {
    key: 3,
    label: "NRE (NRI)",
    value: "21",
  },
  {
    key: 4,
    label: "NRO (NRI)",
    value: "24",
  },
];

const PROP = [
  {
    key: false,
    text: "No",
  },
  {
    key: true,
    text: "Yes",
  },
];

const COUNTRIES = [
  // { value: null, label: "Select" },
  { value: "IN", label: "India" },
  { value: "001", label: "Afghanistan" },
  { value: "002", label: "Aland Islands" },
  { value: "003", label: "Albania" },
  { value: "004", label: "Algeria" },
  { value: "005", label: "American Samoa" },
  { value: "006", label: "Andorra" },
  { value: "007", label: "Angola" },
  { value: "008", label: "Anguilla" },
  { value: "009", label: "Antarctica" },
  { value: "010", label: "Antigua And Barbuda" },
  { value: "011", label: "Argentina" },
  { value: "012", label: "Armenia" },
  { value: "013", label: "Aruba" },
  { value: "014", label: "Australia" },
  { value: "015", label: "Austria" },
  { value: "016", label: "Azerbaijan" },
  { value: "017", label: "Bahamas" },
  { value: "018", label: "Bahrain" },
  { value: "019", label: "Bangladesh" },
  { value: "020", label: "Barbados" },
  { value: "021", label: "Belarus" },
  { value: "022", label: "Belgium" },
  { value: "023", label: "Belize" },
  { value: "024", label: "Benin" },
  { value: "025", label: "Bermuda" },
  { value: "026", label: "Bhutan" },
  { value: "027", label: "Bolivia" },
  { value: "BQ", label: "Bonaire, Sint Eustatius And Saba" },
  { value: "028", label: "Bosnia And Herzegovina" },
  { value: "029", label: "Botswana" },
  { value: "030", label: "Bouvet Island" },
  { value: "031", label: "Brazil" },
  { value: "032", label: "British Indian Ocean Territory" },
  { value: "033", label: "Brunei Darussalam" },
  { value: "034", label: "Bulgaria" },
  { value: "035", label: "Burkina Faso" },
  { value: "036", label: "Burundi" },
  { value: "037", label: "Cambodia" },
  { value: "038", label: "Cameroon" },
  { value: "039", label: "Canada" },
  { value: "040", label: "Cape Verde" },
  { value: "041", label: "Cayman Islands" },
  { value: "042", label: "Central African Republic" },
  { value: "043", label: "Chad" },
  { value: "044", label: "Chile" },
  { value: "045", label: "China" },
  { value: "046", label: "Christmas Island" },
  { value: "047", label: "Cocos (Keeling) Islands" },
  { value: "048", label: "Colombia" },
  { value: "049", label: "Comoros" },
  { value: "050", label: "Congo" },
  { value: "051", label: "Congo, The Democratic Republic Of The" },
  { value: "052", label: "Cook Islands" },
  { value: "053", label: "Costa Rica" },
  { value: "054", label: "Cote D'ivoire" },
  { value: "055", label: "Croatia" },
  { value: "056", label: "Cuba" },
  { value: "CW", label: "Curacao" },
  { value: "057", label: "Cyprus" },
  { value: "058", label: "Czech Republic" },
  { value: "059", label: "Denmark" },
  { value: "060", label: "Djibouti" },
  { value: "061", label: "Dominica" },
  { value: "062", label: "Dominican Republic" },
  { value: "063", label: "Ecuador" },
  { value: "064", label: "Egypt" },
  { value: "065", label: "El Salvador" },
  { value: "066", label: "Equatorial Guinea" },
  { value: "067", label: "Eritrea" },
  { value: "068", label: "Estonia" },
  { value: "069", label: "Ethiopia" },
  { value: "070", label: "Falkland Islands (Malvinas)" },
  { value: "071", label: "Faroe Islands" },
  { value: "072", label: "Fiji" },
  { value: "073", label: "Finland" },
  { value: "074", label: "France" },
  { value: "075", label: "French Guiana" },
  { value: "076", label: "French Polynesia" },
  { value: "077", label: "French Southern Territories" },
  { value: "078", label: "Gabon" },
  { value: "079", label: "Gambia" },
  { value: "078", label: "Georgia" },
  { value: "081", label: "Germany" },
  { value: "082", label: "Ghana" },
  { value: "083", label: "Gibraltar" },
  { value: "084", label: "Greece" },
  { value: "085", label: "Greenland" },
  { value: "086", label: "Grenada" },
  { value: "087", label: "Guadeloupe" },
  { value: "088", label: "Guam" },
  { value: "089", label: "Guatemala" },
  { value: "090", label: "Guernsey" },
  { value: "091", label: "Guinea" },
  { value: "092", label: "Guinea-Bissau" },
  { value: "093", label: "Guyana" },
  { value: "094", label: "Haiti" },
  { value: "095", label: "Heard Island And McDonald Islands" },
  { value: "097", label: "Honduras" },
  { value: "098", label: "Hong Kong" },
  { value: "099", label: "Hungary" },
  { value: "100", label: "Iceland" },
  { value: "102", label: "Indonesia" },
  { value: "103", label: "Iran, Islamic Republic Of" },
  { value: "104", label: "Iraq" },
  { value: "105", label: "Ireland" },
  { value: "106", label: "Isle Of Man" },
  { value: "107", label: "Israel" },
  { value: "108", label: "Italy" },
  { value: "109", label: "Jamaica" },
  { value: "110", label: "Japan" },
  { value: "111", label: "Jersey" },
  { value: "112", label: "Jordan" },
  { value: "113", label: "Kazakhstan" },
  { value: "114", label: "Kenya" },
  { value: "115", label: "Kiribati" },
  { value: "116", label: "Korea, Democratic People's Republic Of" },
  { value: "117", label: "Korea, Republic Of" },
  { value: "118", label: "Kuwait" },
  { value: "119", label: "Kyrgyzstan" },
  { value: "120", label: "Lao People's Democratic Republic" },
  { value: "121", label: "Latvia" },
  { value: "122", label: "Lebanon" },
  { value: "123", label: "Lesotho" },
  { value: "124", label: "Liberia" },
  { value: "125", label: "Libyan Arab Jamahiriya" },
  { value: "126", label: "Liechtenstein" },
  { value: "127", label: "Lithuania" },
  { value: "128", label: "Luxembourg" },
  { value: "129", label: "Macao" },
  { value: "130", label: "Macedonia, The Former Yugoslav Republic Of" },
  { value: "131", label: "Madagascar" },
  { value: "132", label: "Malawi" },
  { value: "133", label: "Malaysia" },
  { value: "134", label: "Maldives" },
  { value: "135", label: "Mali" },
  { value: "136", label: "Malta" },
  { value: "137", label: "Marshall Islands" },
  { value: "138", label: "Martinique" },
  { value: "139", label: "Mauritania" },
  { value: "140", label: "Mauritius" },
  { value: "141", label: "Mayotte" },
  { value: "142", label: "Mexico" },
  { value: "143", label: "Micronesia, Federated States Of" },
  { value: "144", label: "Moldova, Republic Of" },
  { value: "145", label: "Monaco" },
  { value: "146", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "147", label: "Montserrat" },
  { value: "148", label: "Morocco" },
  { value: "149", label: "Mozambique" },
  { value: "150", label: "Myanmar" },
  { value: "151", label: "Namibia" },
  { value: "152", label: "Nauru" },
  { value: "153", label: "Nepal" },
  { value: "154", label: "Netherlands" },
  { value: "155", label: "Netherlands Antilles" },
  { value: "156", label: "New Caledonia" },
  { value: "157", label: "New Zealand" },
  { value: "158", label: "Nicaragua" },
  { value: "159", label: "Niger" },
  { value: "160", label: "Nigeria" },
  { value: "161", label: "Niue" },
  { value: "162", label: "Norfolk Island" },
  { value: "163", label: "Northern Mariana Islands" },
  { value: "164", label: "Norway" },
  { value: "XX", label: "Not categorised" },
  { value: "164", label: "Oman" },
  { value: "ZZ", label: "Others" },
  { value: "166", label: "Pakistan" },
  { value: "167", label: "Palau" },
  { value: "168", label: "Palestinian Territory, Occupied" },
  { value: "169", label: "Panama" },
  { value: "170", label: "Papua New Guinea" },
  { value: "171", label: "Paraguay" },
  { value: "172", label: "Peru" },
  { value: "173", label: "Philippines" },
  { value: "174", label: "Pitcairn" },
  { value: "175", label: "Poland" },
  { value: "176", label: "Portugal" },
  { value: "177", label: "Puerto Rico" },
  { value: "178", label: "Qatar" },
  { value: "179", label: "Reunion Island" },
  { value: "180", label: "Romania" },
  { value: "181", label: "Russian Federation" },
  { value: "182", label: "Rwanda" },
  { value: "BL", label: "Saint Barthelemy" },
  { value: "183", label: "Saint Helena, Ascension And Tristanda Cunha" },
  { value: "184", label: "Saint Kitts And Nevis" },
  { value: "185", label: "Saint Lucia" },
  { value: "MF", label: "Saint Martin" },
  { value: "186", label: "Saint Pierre And Miquelon" },
  { value: "187", label: "Saint Vincent And The Grenadines" },
  { value: "188", label: "Samoa" },
  { value: "189", label: "San Marino" },
  { value: "190", label: "Sao Tome And Principe" },
  { value: "191", label: "Saudi Arabia" },
  { value: "192", label: "Senegal" },
  { value: "193", label: "Serbia" },
  { value: "194", label: "Seychelles" },
  { value: "195", label: "Sierra Leone" },
  { value: "196", label: "Singapore" },
  { value: "SX", label: "Sint Maarten (Dutch Part)" },
  { value: "197", label: "Slovakia" },
  { value: "198", label: "Slovenia" },
  { value: "199", label: "Solomon Islands" },
  { value: "200", label: "Somalia" },
  { value: "201", label: "South Africa" },
  { value: "202", label: "South Georgia And The South Sandwich Islands" },
  { value: "SS", label: "South Sudan" },
  { value: "203", label: "Spain" },
  { value: "204", label: "Sri Lanka" },
  { value: "205", label: "Sudan" },
  { value: "206", label: "Suriname" },
  { value: "207", label: "Svalbard And Jan Mayen Islands" },
  { value: "208", label: "Swaziland" },
  { value: "209", label: "Sweden" },
  { value: "210", label: "Switzerland" },
  { value: "211", label: "Syrian Arab Republic" },
  { value: "212", label: "Taiwan, Province Of China" },
  { value: "213", label: "Tajikistan" },
  { value: "214", label: "Tanzania, United Republic Of" },
  { value: "215", label: "Thailand" },
  { value: "216", label: "Timor-Leste" },
  { value: "217", label: "Togo" },
  { value: "218", label: "Tokelau" },
  { value: "219", label: "Tonga" },
  { value: "220", label: "Trinidad And Tobago" },
  { value: "221", label: "Tunisia" },
  { value: "222", label: "Turlabel" },
  { value: "223", label: "Turkmenistan" },
  { value: "224", label: "Turks And Caicos Islands" },
  { value: "225", label: "Tuvalu" },
  { value: "225", label: "Uganda" },
  { value: "227", label: "Ukraine" },
  { value: "228", label: "United Arab Emirates" },
  { value: "229", label: "United Kingdom" },
  { value: "230", label: "United States of America" },
  { value: "231", label: "United States Minor Outlying Islands" },
  { value: "232", label: "Uruguay" },
  { value: "233", label: "Uzbekistan" },
  { value: "234", label: "Vanuatu" },
  { value: "VA", label: "Vatican City State" },
  { value: "235", label: "Venezuela, Bolivarian Republic Of" },
  { value: "236", label: "Viet Nam" },
  { value: "237", label: "Virgin Islands, British" },
  { value: "238", label: "Virgin Islands, U.S." },
  { value: "239", label: "Wallis And Futuna" },
  { value: "240", label: "Western Sahara" },
  { value: "241", label: "Yemen" },
  { value: "242", label: "Zambia" },
  { value: "243", label: "Zimbabwe" },
];

class financial_info extends React.Component {
  constructor(props) {
    super(props);
    let username = null;
    let cust_id = null;
    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      username = params.username;
      cust_id = params.customer_id;
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
    }
    this.state = {
      user_id,
      sourceofwealth,
      APPLICANTINCOMESLAB,
      taxstatuses,
      APPLICANT_INCOME_SLAB: null,
      source_of_wealth: null,
      tax_status: null,
      data: null,
      is_politically_exposed_person: null,
      tax_statusError: null,
      source_of_wealthError: null,
      APPLICANT_INCOME_SLABError: null,
      isLoading: false,
      country_of_birthError: null,
      country_of_birth: null,
      place_of_birth: null,
      place_of_birthError: null,
      isLoadingData: false,
    };
  }

  componentDidMount = async () => {
    try {
      this.setState({ isLoadingData: true });
      let userDetails = getUserDetails()
      const userIdToFetchDetails = Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
      ? Store.getState().dashboard.viewModeUserId.user_id
      : String(userDetails.customer_id);
      const response = await getCustomerDetails(userIdToFetchDetails);
      if (response && response.pk != null) {
        let {
          tax_status,
          place_of_birth,
          source_of_wealth,
          is_politically_exposed_person,
          occupation_code,
          annual_income_slab: APPLICANT_INCOME_SLAB,
        } = response;

        this.setState({
          tax_status,
          place_of_birth,
          source_of_wealth,
          occupation_code,
          is_politically_exposed_person,
          APPLICANT_INCOME_SLAB,
        });
        if (this.state.user_id == null) {
          this.props.saveUserProfileData(response);
        }
      }
      this.setState({ isLoadingData: false });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoadingData: true,
      });
    }
  };

  handleContinue = async () => {
    try {
      let {
        cust_id,
        tax_status,
        APPLICANT_INCOME_SLAB,
        source_of_wealth,
        username,
        occupation_code,
        is_politically_exposed_person,
        country_of_birth,
        place_of_birth,
      } = this.state;
      if (this.state.user_id) {
        let options = {};
        const { params } = this.props.route;
        if (params) {
          options = { ...params };
        }
        options["first"] = false;
        this.props.navigation.navigate("RiskProfile", { ...options });
        return;
      }

      if (!cust_id || !username) {
        const userData = getUserDetails();
        username = userData.username;
        cust_id = userData.customer_id;
      }

      if (
        !APPLICANT_INCOME_SLAB ||
        APPLICANT_INCOME_SLAB == "Select" ||
        APPLICANT_INCOME_SLAB == 0
      ) {
        this.setState({
          APPLICANT_INCOME_SLABError:
            "Please select your annual income slab from the list",
          isLoading: false,
        });
        return;
      }
      if (
        !source_of_wealth ||
        source_of_wealth == "Select" ||
        source_of_wealth == 0
      ) {
        this.setState({
          source_of_wealthError:
            "Please select your source of wealth from the list",
          isLoading: false,
        });
        return;
      }

      if (
        !occupation_code ||
        occupation_code == "Select" ||
        occupation_code == 0
      ) {
        this.setState({
          occupation_codeError: "Please select your occupation",
          isLoading: false,
        });
        return;
      }

      if (!tax_status || tax_status == "Select" || tax_status == 0) {
        this.setState({
          tax_statusError: "Please select your tax status from the list",
          isLoading: false,
        });
        return;
      }

      if (is_politically_exposed_person == null) {
        this.setState({
          is_politically_exposed_person_error:
            "Please select one of the options",
        });
        return;
      }
      if (
        !country_of_birth ||
        country_of_birth == "Select" ||
        country_of_birth == 0
      ) {
        this.setState({
          country_of_birthError: "Please select your Country of Birth",
          isLoading: false,
        });
        return;
      }
      if (!place_of_birth) {
        this.setState({
          place_of_birthError: "please enter your place of Birth",
          isLoading: false,
        });
        return;
      }

      const data = {
        username: username,
        tax_status: tax_status,
        annual_income_slab: APPLICANT_INCOME_SLAB,
        source_of_wealth: source_of_wealth,
        occupation_code: occupation_code,
        is_politically_exposed_person: is_politically_exposed_person,
        place_of_birth: place_of_birth,
        country_of_birth: country_of_birth,
      };

      this.setState({ isLoading: true });
      const response = await financialInfo(cust_id, data);
      if (response && response.pk != null) {
        this.setState({ isLoading: false });
        const minDetailsResponse = await completeMinDetails();
        const { needCompletion, error } = minDetailsResponse;

        if (!error) {
          const { redirect_screen } = minDetailsResponse;
          const { params } = this.props.route;
          let options = {};
          if (this.props.route) {
            const { params } = this.props.route;
            if (params) {
              options = { ...params };
            }
            if (!needCompletion) {
              if (options.transaction) {
                navigateToTransaction(
                  this.props.navigation,
                  options.transaction
                );
              } else if (options.action) {
                this.props.navigation.navigate("ComplianceSuccess", {
                  ...options,
                });
                return;
              } else {
                this.props.navigation.navigate("OnboardingSuccess", {
                  ...options,
                });
                return;
              }
            }
          }
          this.props.navigation.navigate(redirect_screen, { ...options });
        } else {
          this.setState({
            error: true,
            errorText: "Something went wrong! Please contact help@maxiom.in",
          });
        }
      } else {
        this.setState({
          error: true,
          errorText: response && response.msg ? response.msg : null,
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    }
  };

  handlePickerChange2 = (filed, value) => {
    this.setState({ APPLICANT_INCOME_SLAB: value });
  };

  handlePickerChange = (filed, value) => {
    this.setState({ source_of_wealth: value });
  };

  handlePickerChange3 = (filed, value) => {
    this.setState({ occupation_code: value, occupation_codeError: null });
  };

  handlePickerChange1 = (filed, value) => {
    this.setState({ tax_status: value });
  };

  handlePickerChange4 = (field, value) => {
    this.setState({ country_of_birth: value, country_of_birthError: null });
  };

  render() {
    const {
      APPLICANT_INCOME_SLAB,
      APPLICANT_INCOME_SLABError,
      tax_status,
      tax_statusError,
      source_of_wealth,
      source_of_wealthError,
      isLoading,
      occupation_code,
      occupation_codeError,
      is_politically_exposed_person,
      is_politically_exposed_person_error,
      country_of_birth,
      country_of_birthError,
      place_of_birth,
      place_of_birthError,
    } = this.state;
    return (
      <SafeAreaView style={styles.container}>
        <Header navigation={this.props.navigation} route={this.props.route} />
        <Progressbar
          color2="#410DAA"
          color3="#410daa"
          color4="#410DAA"
          color5="#f6f6f6"
          color6="#f6f6f6"
          color7="#f6f6f6"
          color8="#f6f6f6"
          color9="#f6f6f6"
          color10="#f6f6f6"
          color11="#f6f6f6"
          color12="#f6f6f6"
          bcolor1="#410DAA"
          bcolor2="#410DAA"
          bcolor3="#f6f6f6"
          bcolor4="#f6f6f6"
          bcolor5="#f6f6f6"
          bagcolor1="#410DAA"
          bagcolor2="#410DAA"
          bagcolor3="#f6f6f6"
          bagcolor4="#ffffff"
          bagcolor5="#ffffff"
          markdisplay1="none"
          markdisplay2="none"
          markdisplay3="none"
          markdisplay4="none"
          markdisplay5="none"
          dotdisplay1="none"
          dotdisplay2="none"
          dotdisplay3="none"
          dotdisplay4="none"
          dotdisplay5="none"
        />
        <StatusBar backgroundColor="#000" barStyle="dark-content" />
          {this.state.isLoadingData ? (
            <View style={styles.loaderContainer}>
              <ActivityIndicator
                size="large"
                color="#410DAA"
                style={{ paddingTop: 40 }}
              />
            </View>
          ) : (
        <ScrollView style={styles.mainContainer}>
          <View style={styles.body}>
            {isWeb && (
              <View style={styles.left}>
                {/* <Text style={styles.terms}>Financial Information</Text> */}
                <Text style={[styles.please, { paddingBottom: 70 }]}>
                  FATCA details are requried for investments in India to comply
                  with international regulations related to taxes.
                </Text>

                <Image
                  source={
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-1.png"
                  }
                  style={styles.left_img}
                />
                <View
                  style={{
                    flexDirection: "row",
                    width: 430,
                    borderWidth: 1,
                    borderColor: "rgba(65, 13, 170, 0.2)",
                    backgroundColor: "#ffffff",
                    paddingVertical: 21,
                    paddingLeft: 20,
                    paddingRight: 6,
                    marginTop: 33,
                    marginBottom: 22,
                  }}
                >
                  <Image
                    source={
                      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/fluent_lock-shield-20-regular.png"
                    }
                    style={styles.lock}
                  />
                  <Text style={styles.jam_we}>
                    Information is encrypted using 256-bit keys and stored
                    securely
                  </Text>
                </View>
                <Text style={styles.any}>
                  Any Queries?{" "}
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("App", {
                        screen: "Dashboard",
                        params: {
                          screen: "Dashboard",
                          params: {
                            screen: "GetHelp",
                            params: {},
                          },
                        },
                      });
                    }}
                  >
                    <Text style={{ color: "#410DAA", fontWeight: "bold" }}>
                      Get Help
                    </Text>
                  </TouchableOpacity>
                </Text>
              </View>
            )}
            <View style={styles.right}>
              <View style={{ display: md(width) ? "none" : "flex" }}>
                <Text style={styles.terms}>Financial Information</Text>
                <Text style={[styles.please, { paddingBottom: 12 }]}>
                  Required per FATCA regulation
                </Text>
              </View>
              <Text style={[styles.annual]}>Annual Income Slab*</Text>
              <View style={{ flexDirection: "row" }}>
                <PickerFieldSelect
                  data={APPLICANTINCOMESLAB}
                  value={this.state.APPLICANT_INCOME_SLAB}
                  style={{
                    borderColor: "none",
                    borderRadius: 4,
                    borderWidth: 0,
                    // backgroundColor: "#f6f6f6",
                    paddingVertical: isMobile ? 0 : 12,
                    paddingHorizontal: 6,
                    color: "#121212",
                    fontFamily: "Roboto",
                    fontSize: md(width) ? 12 : 13,
                    lineHeight: md(width) ? 14 : 15,
                    width: isMobile ? getWindowSize().width * 0.9 : 600,
                    paddingVertical: Platform.OS == "ios" ? 12 : 0,
                    paddingHorizontal: Platform.OS == "ios" ? 12 : 0,
                  }}
                  onChange={(itemValue) => {
                    this.handlePickerChange2(
                      "APPLICANT_INCOME_SLAB",
                      itemValue
                    );
                  }}
                  pickerStyle={[{}]}
                  type="select"
                  placeholder={{
                    label: "Select Annual Income Slab ",
                    value: null,
                    color: "#9EA0A4",
                  }}
                />
                {/* <Picker
                  selectedValue={this.state.APPLICANT_INCOME_SLAB}
                  onValueChange={(itemValue) => {
                    this.handlePickerChange2(
                      "APPLICANT_INCOME_SLAB",
                      itemValue
                    );
                  }}
                  style={{
                    borderColor: "none",
                    borderRadius: 4,
                    borderWidth: 0,
                    backgroundColor: "#f6f6f6",
                    paddingVertical: 12,
                    paddingHorizontal: 6,
                    color: "#121212",
                    fontFamily: "Roboto",
                    fontSize: md(width) ? 12 : 13,
                    lineHeight: md(width) ? 14 : 15,
                    width: 600,
                  }}
                >
                  {APPLICANTINCOMESLAB.map((state, key) => (
                    <Picker.Item
                      kay={key}
                      label={state.label}
                      value={state.value}
                    />
                  ))}
                </Picker> */}
                {/* <View
                  style={{ width: 20, height: 41, backgroundColor: "#F6F6F6" }}
                ></View> */}
              </View>
              <Text
                style={[
                  styles.select,
                  APPLICANT_INCOME_SLABError && styles.error,
                ]}
              >
                {APPLICANT_INCOME_SLAB ? "" : APPLICANT_INCOME_SLABError}
              </Text>
              <Text style={[styles.annual]}>Wealth Source*</Text>
              <View style={{ flexDirection: "row" }}>
                <PickerFieldSelect
                  data={sourceofwealth}
                  value={this.state.source_of_wealth}
                  style={{
                    borderColor: "none",
                    borderRadius: 4,
                    borderWidth: 0,
                    // backgroundColor: "#f6f6f6",
                    paddingVertical: isMobile ? 0 : 12,
                    paddingHorizontal: 6,
                    color: "#121212",
                    fontFamily: "Roboto",
                    fontSize: md(width) ? 12 : 13,
                    lineHeight: md(width) ? 14 : 15,
                    width: isMobile ? getWindowSize().width * 0.9 : 600,
                    paddingVertical: Platform.OS == "ios" ? 12 : 0,
                    paddingHorizontal: Platform.OS == "ios" ? 12 : 0,
                  }}
                  onChange={(itemValue) => {
                    this.handlePickerChange("sourse of wealth", itemValue);
                  }}
                  pickerStyle={[{}]}
                  type="select"
                  placeholder={{
                    label: "Select Source of Wealth",
                    value: null,
                  }}
                />
                {/* <Picker
                  selectedValue={this.state.source_of_wealth}
                  onValueChange={(itemValue) => {
                    this.handlePickerChange("sourse of wealth", itemValue);
                  }}
                  style={{
                    borderColor: "none",
                    borderRadius: 4,
                    borderWidth: 0,
                    backgroundColor: "#f6f6f6",
                    paddingVertical: 12,
                    paddingHorizontal: 6,
                    color: "#121212",
                    fontFamily: "Roboto",
                    fontSize: md(width) ? 12 : 13,
                    lineHeight: md(width) ? 14 : 15,
                    width: 600,
                  }}
                >
                  {sourceofwealth.map((state, key) => (
                    <Picker.Item
                      key={key}
                      label={state.label}
                      value={state.value}
                    />
                  ))}
                </Picker> */}
                {/* <View
                  style={{ width: 20, height: 41, backgroundColor: "#F6F6F6" }}
                ></View> */}
              </View>
              <Text
                style={[styles.select, source_of_wealthError && styles.error]}
              >
                {source_of_wealth ? "" : source_of_wealthError}
              </Text>
              <Text style={[styles.annual]}>Occupation*</Text>
              <View style={{ flexDirection: "row" }}>
                <PickerFieldSelect
                  data={OCCUPATIONCODES}
                  value={this.state.occupation_code}
                  style={{
                    borderColor: "none",
                    borderRadius: 4,
                    borderWidth: 0,
                    // backgroundColor: "#f6f6f6",
                    paddingVertical: isMobile ? 0 : 12,
                    paddingHorizontal: 6,
                    color: "#121212",
                    fontFamily: "Roboto",
                    fontSize: md(width) ? 12 : 13,
                    lineHeight: md(width) ? 14 : 15,
                    width: isMobile ? getWindowSize().width * 0.9 : 600,
                    paddingVertical: Platform.OS == "ios" ? 12 : 0,
                    paddingHorizontal: Platform.OS == "ios" ? 12 : 0,
                  }}
                  onChange={(itemValue) => {
                    this.handlePickerChange3("occupation_code", itemValue);
                  }}
                  pickerStyle={[{}]}
                  type="select"
                  placeholder={{
                    label: "Select Occupation",
                    value: null,
                  }}
                />
              </View>
              <Text
                style={[styles.select, occupation_codeError && styles.error]}
              >
                {occupation_code ? "" : occupation_codeError}
              </Text>

              <Text style={[styles.annual]}>Tax Status*</Text>
              <View style={{ flexDirection: "row" }}>
                <PickerFieldSelect
                  data={taxstatuses}
                  value={this.state.tax_status}
                  style={{
                    borderColor: "none",
                    borderRadius: 4,
                    borderWidth: 0,
                    // backgroundColor: "#f6f6f6",
                    paddingVertical: isMobile ? 0 : 12,
                    paddingHorizontal: 6,
                    color: "#121212",
                    fontFamily: "Roboto",
                    fontSize: md(width) ? 12 : 13,
                    lineHeight: md(width) ? 14 : 15,
                    width: isMobile ? getWindowSize().width * 0.9 : 600,
                    paddingVertical: Platform.OS == "ios" ? 12 : 0,
                    paddingHorizontal: Platform.OS == "ios" ? 12 : 0,
                  }}
                  onChange={(itemValue) => {
                    this.handlePickerChange1("tax status", itemValue);
                  }}
                  pickerStyle={[{}]}
                  type="select"
                  placeholder={{
                    label: "Select Tax Status ",
                    value: null,
                    color: "#9EA0A4",
                  }}
                />
                {/* <Picker
                  selectedValue={this.state.tax_status}
                  onValueChange={(itemValue) => {
                    this.handlePickerChange1("tax status", itemValue);
                  }}
                  style={{
                    borderColor: "none",
                    borderRadius: 4,
                    borderWidth: 0,
                    backgroundColor: "#f6f6f6",
                    paddingVertical: 12,
                    paddingHorizontal: 6,
                    color: "#121212",
                    fontFamily: "Roboto",
                    fontSize: md(width) ? 12 : 13,
                    lineHeight: md(width) ? 14 : 15,
                    width: 600,
                  }}
                >
                  {taxstatuses.map((state, key) => (
                    <Picker.Item
                      kay={key}
                      label={state.label}
                      value={state.value}
                    />
                  ))}
                </Picker> */}
                {/* <View
                  style={{ width: 20, height: 41, backgroundColor: "#F6F6F6" }}
                ></View> */}
              </View>
              <View style={[styles.rowItem, styles.marginRight]}>
                <Text style={styles.add1}>Country of Birth*</Text>
                <PickerFieldSelect
                  data={COUNTRIES}
                  value={this.state.country_of_birth}
                  style={{
                    // backgroundColor: "#f6f6f6",
                    width: isMobile ? getWindowSize().width * 0.92 : 677,
                    paddingVertical: Platform.OS == "ios" ? 12 : 0,
                    paddingHorizontal: Platform.OS == "ios" ? 12 : 0,
                  }}
                  onChange={(itemValue) => {
                    this.handlePickerChange4("country_of_birth", itemValue);
                  }}
                  pickerStyle={[
                    {
                      color: "#121212",
                    },
                  ]}
                  type="select"
                  placeholder={{
                    label: "Select",
                    value: null,
                    color: "#9EA0A4",
                  }}
                />
                {/* <Picker
                      selectedValue={this.state.country_of_birth}
                      onValueChange={(itemValue, itemIndex) => {
                        this.handlePickerChange1("country_of_birth", itemValue);
                      }}
                      style={{
                        borderColor: "none",
                        borderRadius: 4,
                        borderWidth: 0,
                        backgroundColor: "#f6f6f6",
                        paddingVertical: 12,
                        paddingHorizontal: 19,
                        color: "#121212",
                        fontFamily: "Roboto",
                        fontSize: md(width) ? 12 : 13,
                        lineHeight: md(width) ? 14 : 15,
                        width: "100%"
                      }}
                    >
                      {COUNTRIES.map((state, key) => (
                        <Picker.Item
                          key={key}
                          label={state.key}
                          value={state.value}
                        />
                      ))}
                    </Picker> */}
                <Text
                  style={[styles.max, country_of_birthError && styles.error]}
                >
                  {country_of_birthError}
                </Text>
              </View>

              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginRight: md(width) ? 29 : 0,
                }}
              >
                <Text style={styles.add1}>Place of Birth*</Text>
                <TextInput
                  value={this.state.place_of_birth}
                  placeholder={""}
                  style={styles.inptxt}
                  onChangeText={(text) => {
                    this.setState({ place_of_birth: text });
                  }}
                />
                <Text style={[styles.max, place_of_birthError && styles.error]}>
                  {place_of_birth ? "" : place_of_birthError}
                </Text>
              </View>
              <Popable
                position={"top"}
                action={isMobile ? "press" : "hover"}
                style={{ width: 300 }}
                content={
                  <View style={{ backgroundColor: "black" }}>
                    <Text
                      style={{
                        textAlign: "left",
                        color: "white",
                        padding: 10,
                      }}
                    >
                      Politically Exposed Person (PEP) status: Indicates if you
                      hold or have held a prominent public position, or have
                      close ties to someone who has. Check if this applies to
                      you.
                    </Text>
                  </View>
                }
              >
                <View
                  style={{
                    flexDirection: "column",
                    marginTop: 20,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        color: "#222225",
                        fontWeight: "bold",
                      }}
                    >
                      Are You Politically Exposed Person*{"  "}
                    </Text>
                    <Icon
                      style={{
                        marginTop: 2,
                      }}
                      name="info-circle"
                      size={14}
                      color={"black"}
                    />
                  </View>
                </View>
              </Popable>
              <View style={styles.radio}>
                <RadioButton
                  value={is_politically_exposed_person}
                  PROP={PROP}
                  onPress={(value) => {
                    this.setState({
                      is_politically_exposed_person: value,
                      is_politically_exposed_person_error: null,
                    });
                  }}
                />
              </View>
              <Text
                style={[
                  styles.select,
                  is_politically_exposed_person_error && styles.error,
                ]}
              >
                {is_politically_exposed_person
                  ? ""
                  : is_politically_exposed_person_error}
              </Text>
              {isLoading ? (
                <ActivityIndicator
                  size="large"
                  color="#410DAA"
                  style={{ paddingTop: 40 }}
                />
              ) : (Store.getState().dashboard &&
              Store.getState().dashboard.viewModeUserId &&
              Store.getState().dashboard.viewModeUserId.user_id) ? (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.handleContinue();
                    }}
                  >
                    <Text style={styles.viewModeText}>Next {">>>"}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      let url = new URL(global.location.href);
                      location.replace(
                        url.origin +
                          "/dashboard/home?user_id=" +
                          this.state.user_id
                      );
                    }}
                  >
                    <Text style={styles.viewModeText}>
                      Go to Dashboard {">>>"}
                    </Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    this.handleContinue();
                  }}
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Text style={styles.continue}>Continue</Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1, backgroundColor: "#fff" },
  loaderContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  mainContainer: {

  },
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: md(width) ? 130 : 13,
    paddingRight: md(width) ? 100 : 13,
    paddingBottom: 60,
    marginBottom: 50
  },
  left: {
    flex: 0.5,
    paddingTop: 55,
    // display: md(width) ? "none" : "none",
  },
  right: {
    flex: 0.5,
    paddingTop: md(width) ? 35 : 20,
    flex: md(width) ? 0.45 : 1,
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },
  lock: {
    resizeMode: "contain",
    width: 30,
    height: 30,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 18 : 16,
    lineHeight: md(width) ? 21 : 17,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 15 : 13,
    lineHeight: 17,
    color: "#464E5F",
    paddingTop: 12,
    paddingBottom: 32,
    paddingRight: md(width) ? 170 : 0,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  annual: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 16 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    fontWeight: "bold",
    paddingBottom: 6.4,
    paddingTop: md(width) ? 31 : 25,
  },
  below: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 14 : 13,
    lineHeight: md(width) ? 16 : 15,
    color: "#121212",
    paddingVertical: 13,
    paddingHorizontal: 6,
    backgroundColor: "#f6f6f6",
    borderRadius: 4,
  },
  select: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    fontStyle: "italic",
    paddingTop: 3.2,
    color: "#6C6D72",
    opacity: 0.8,
  },
  error: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    fontStyle: "italic",
    paddingTop: 3.2,
    color: "red",
  },
  radio: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 16,
    color: "#6C6D72",
    paddingTop: 11,
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 18,
    marginTop: 20,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  add1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 16 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    paddingBottom: 6.35,
    paddingTop: 20,
  },
  inptxt: {
    width: 300,
    fontFamily: "Roboto",
    fontSize: md(width) ? 12 : 13,
    lineHeight: md(width) ? 14 : 15,
    color: "#121212",
    paddingVertical: isMobile ? 8 : 13.5,
    paddingHorizontal: 18.75,
    backgroundColor: "#fffff",
    borderColor: "black",
    borderWidth: 1,
    borderRadius: 4,
  },
  continue: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    fontWeight: "bold",
    paddingVertical: 12,
    paddingHorizontal: 79,
    backgroundColor: "#410DAA",
    borderRadius: 4,
    marginTop: 20,
  },
});

const mapStateToProps = (state) => {
  return {
    userProfileData: state.userData.userProfileData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveUserProfileData: (data) => dispatch(saveUserProfileData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(financial_info);
