import React from "react";
import amplitude from "../../../../utils/amplitude";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  ActivityIndicator,
  Platform,
} from "react-native";
import { md } from "../../../../Responsive";
import NavigationHeader from "../../../Common/NavigationHeader";
import { isMobile } from "../../../../utils";
import { ShowToast } from "../../../Common/Toast";
import { addFamilyMember } from "../../../../api";
import { StackActions } from "@react-navigation/routers";
const { width } = Dimensions.get("window");
const isWeb = md(width);
import { Button, HelperText } from "react-native-paper";
import TextInputField from "../../../Common/Inputs/InputField";
import PickerField from "../../../Common/Pickers/PickerField";
import HorizontalRadio from "../../../Common/Radio/HorizontalRadio";
import PickerFieldSelect from "../../../Common/Pickers/PickerFieldSelect";

export default class addfamily extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let userId = null;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }

    this.state = {
      userId,
      isLoading: false,
      name_error: "",
      email_error: "",
      mobile_error: "",
      relation_error: "",
      memberName: "",
      memberEmail: "",
      dependent: "No",
      includeBilling: "No",
      loginAccess: "No",
      memberMobile: "",
      memberRelation: "SLF",
      error: false,
      errorText: "",
      relationships: [],
      showToast: "false",
    };
  }

  componentDidMount = () => {
    if (Platform.OS == "web") {
      amplitude.logEvent("AddFamily", { path: global.location.href });
    } else {
      amplitude.logEvent("AddFamily", {
        path: this.props.navigation.getState().routeName,
      });
    }
    const relationships = [
      {
        key: 1,
        label: "Self",
        value: "SLF",
      },
      {
        key: 2,
        label: "Husband",
        value: "HUS",
      },
      {
        key: 3,
        label: "Wife",
        value: "WIF",
      },
      {
        key: 4,
        label: "Father",
        value: "FAT",
      },
      {
        key: 5,
        label: "Mother",
        value: "MOT",
      },
      {
        key: 6,
        label: "Brother",
        value: "BRO",
      },
      {
        key: 7,
        label: "Sister",
        value: "SIS",
      },
      {
        key: 8,
        label: "Son",
        value: "SON",
      },
      {
        key: 9,
        label: "Father-in-Law",
        value: "FIL",
      },
      {
        key: 10,
        label: "Mother-in-Law",
        value: "MIL",
      },
      {
        key: 11,
        label: "Daughter-in-law",
        value: "DIL",
      },
      {
        key: 12,
        label: "Son-in-law",
        value: "SIL",
      },
      {
        key: 13,
        label: "Nephew",
        value: "NEP",
      },
      {
        key: 14,
        label: "Neice",
        value: "NEC",
      },
      {
        key: 15,
        label: "Cousin",
        value: "COS",
      },
      {
        key: 16,
        label: "Uncle",
        value: "UNC",
      },
      {
        key: 17,
        label: "Aunt",
        value: "ANT",
      },
      {
        key: 18,
        label: "Grand Father",
        value: "GRF",
      },
      {
        key: 19,
        label: "Grand Mother",
        value: "GRM",
      },
      {
        key: 20,
        label: "Guardian",
        value: "GRD",
      },
      {
        key: 21,
        label: "Daughter",
        value: "DAT",
      },
    ];

    this.setState({ relationships });
  };

  validateData = () => {
    const pattern =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (this.state.memberName == "") {
      this.setState({
        name_error: "Please enter a name",
      });
      return false;
    }

    if (this.state.memberRelation == "" || this.state.memberRelation == "0") {
      this.setState({
        relation_error: "Please select a relation",
      });
      return false;
    }

    if (this.state.memberMobile == "" || this.state.memberMobile.length != 10) {
      this.setState({
        mobile_error: "Please enter a valid 10 digit mobile number",
        relation_error: "",
      });
      return false;
    }

    if (this.state.memberEmail == "" || !pattern.test(this.state.memberEmail)) {
      this.setState({
        email_error: "Please enter a valid email id",
      });
      return false;
    }

    return true;
  };

  validateAndAddMember = async () => {
    if (!this.validateData()) {
      return;
    } else {
      this.setState({ isLoading: true });
      const addMemberResponse = await addFamilyMember({
        name: this.state.memberName,
        relationship_type: this.state.memberRelation,
        mobilenumber: this.state.memberMobile,
        email: this.state.memberEmail,
        dependent: this.state.dependent,
        include_billing: this.state.includeBilling,
        login_access: this.state.loginAccess,
      });

      if (
        addMemberResponse &&
        addMemberResponse.code &&
        addMemberResponse.code == 100
      ) {
        this.setState({ isLoading: false, showToast: "true" });
        this.props.navigation.dispatch(
          StackActions.replace("App", {
            screen: "Dashboard",
            params: {
              screen: "Portfolio",
            },
          })
        );
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Something went wrong!",
        });
      }
    }
  };

  render() {
    const { error, errorText, isLoading } = this.state;

    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View style={{ flex: 1, maxWidth: 1500 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Add Family"]}
            onPressBack={() => {
              if (this.props.navigation.canGoBack()) {
                this.props.navigation.goBack();
              } else {
                this.props.navigation.push("App");
              }
            }}
          />
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <ActivityIndicator size="large" color="#410DAA" />
            ) : error ? (
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText || "Something went wrong!"}
              </Text>
            ) : (
              <>
                {this.state.showToast == "true" ? (
                  <ShowToast
                    showToast={true}
                    type="status"
                    title={`Request sent, kindly remind ${this.state.memberName} to approve request.`}
                    duration="10"
                  />
                ) : null}
                <ScrollView style={[styles.mainContainer]}>
                  {!isMobile ? (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: -10,
                      }}
                    >
                      <View style={[{ flex: 1 }, styles.itemMargin]}>
                        <TextInputField
                          label="Full Name*"
                          value={this.state.memberName}
                          onChange={(text) => {
                            this.setState({
                              memberName: text,
                              name_error: "",
                            });
                          }}
                          inputStyle={[{ paddingLeft: 20 }]}
                          editable={true}
                        />
                        <HelperText
                          type="error"
                          visible={this.state.name_error != ""}
                        >
                          {this.state.name_error}
                        </HelperText>
                      </View>

                      <View style={[{ flex: 1 }, styles.itemMargin]}>
                        <PickerFieldSelect
                          data={this.state.relationships}
                          value={this.state.memberRelation}
                          label="Relationship*"
                          onChange={(text) => {
                            this.setState({
                              memberRelation: text,
                              relation_error: "",
                            });
                          }}
                          type="select"
                        />
                        <HelperText
                          type="error"
                          visible={this.state.relation_error != ""}
                        >
                          {this.state.relation_error}
                        </HelperText>
                      </View>
                    </View>
                  ) : (
                    <>
                      <View style={[styles.itemMargin]}>
                        <TextInputField
                          label="Full Name*"
                          value={this.state.memberName}
                          onChange={(text) => {
                            this.setState({
                              memberName: text,
                              name_error: "",
                            });
                          }}
                          inputStyle={[{ paddingLeft: 20 }]}
                          editable={true}
                        />
                        <HelperText
                          type="error"
                          visible={this.state.name_error != ""}
                        >
                          {this.state.name_error}
                        </HelperText>
                      </View>
                      <View style={[styles.itemMargin]}>
                        <PickerFieldSelect
                          data={this.state.relationships}
                          value={this.state.memberRelation}
                          label="Relationship*"
                          onChange={(text) => {
                            this.setState({
                              memberRelation: text,
                              relation_error: "",
                            });
                          }}
                          type="select"
                        />
                        <HelperText
                          type="error"
                          visible={this.state.relation_error != ""}
                        >
                          {this.state.relation_error}
                        </HelperText>
                      </View>
                    </>
                  )}

                  {!isMobile ? (
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View style={[{ flex: 1 }, styles.itemMargin]}>
                        <TextInputField
                          label="Mobile Number*"
                          value={this.state.memberMobile}
                          onChange={(text) => {
                            if (!isNaN(text)) {
                              if (text.length > 10) {
                                this.setState({
                                  memberMobile: text.substring(0, 10),
                                  mobile_error: "",
                                });
                              } else {
                                this.setState({
                                  memberMobile: text,
                                  mobile_error: "",
                                });
                              }
                            }
                          }}
                          inputStyle={[{ paddingLeft: 20 }]}
                          editable={true}
                        />
                        <HelperText
                          type="error"
                          visible={this.state.mobile_error != ""}
                        >
                          {this.state.mobile_error}
                        </HelperText>
                      </View>
                      <View style={[{ flex: 1 }, styles.itemMargin]}>
                        <TextInputField
                          label="Email Id*"
                          value={this.state.memberEmail}
                          onChange={(text) => {
                            this.setState({
                              memberEmail: text,
                              email_error: "",
                            });
                          }}
                          inputStyle={[{ paddingLeft: 20 }]}
                          editable={true}
                        />
                        <HelperText
                          type="error"
                          visible={this.state.email_error != ""}
                        >
                          {this.state.email_error}
                        </HelperText>
                      </View>
                    </View>
                  ) : (
                    <>
                      <View style={[styles.itemMargin]}>
                        <TextInputField
                          label="Mobile Number*"
                          value={this.state.memberMobile}
                          onChange={(text) => {
                            if (!isNaN(text)) {
                              if (text.length > 10) {
                                this.setState({
                                  memberMobile: text.substring(0, 10),
                                  mobile_error: "",
                                });
                              } else {
                                this.setState({
                                  memberMobile: text,
                                  mobile_error: "",
                                });
                              }
                            }
                          }}
                          inputStyle={[{ paddingLeft: 20 }]}
                          editable={true}
                        />
                        <HelperText
                          type="error"
                          visible={this.state.mobile_error != ""}
                        >
                          {this.state.mobile_error}
                        </HelperText>
                      </View>
                      <View style={[styles.itemMargin]}>
                        <TextInputField
                          label="Email Id*"
                          value={this.state.memberEmail}
                          onChange={(text) => {
                            this.setState({
                              memberEmail: text,
                              email_error: "",
                            });
                          }}
                          inputStyle={[{ paddingLeft: 20 }]}
                          editable={true}
                        />
                        <HelperText
                          type="error"
                          visible={this.state.email_error != ""}
                        >
                          {this.state.email_error}
                        </HelperText>
                      </View>
                    </>
                  )}
                  {!isMobile ? (
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View style={[{ flex: 1 }, styles.itemMargin]}>
                        <HorizontalRadio
                          title={"Is your family member dependent?*"}
                          style={{
                            paddingVertical: 0,
                            marginVertical: 0,
                          }}
                          selected={this.state.dependent}
                          radioButtonValues={["No", "Yes"]}
                          values={["No", "Yes"]}
                          onSelect={(index, value) => {
                            this.setState({
                              dependent: value,
                            });
                          }}
                        />
                      </View>
                    </View>
                  ) : (
                    <>
                      <View style={[styles.itemMargin]}>
                        <HorizontalRadio
                          title={"Is your family member dependent?*"}
                          style={{
                            paddingVertical: 0,
                            marginVertical: 0,
                          }}
                          selected={this.state.dependent}
                          radioButtonValues={["No", "Yes"]}
                          values={["No", "Yes"]}
                          onSelect={(index, value) => {
                            this.setState({
                              dependent: value,
                            });
                          }}
                        />
                      </View>
                    </>
                  )}

                  {!isMobile ? (
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View style={[{ flex: 1 }, styles.itemMargin]}>
                        <HorizontalRadio
                          title={
                            "You can view and pay bills for a dependent family member. Do you want to request access?*"
                          }
                          style={{
                            paddingVertical: 0,
                            marginVertical: 0,
                          }}
                          selected={this.state.includeBilling}
                          radioButtonValues={["No", "Yes"]}
                          values={["No", "Yes"]}
                          onSelect={(index, value) => {
                            this.setState({
                              includeBilling: value,
                            });
                          }}
                        />
                      </View>
                    </View>
                  ) : (
                    <>
                      <View style={[styles.itemMargin]}>
                        <HorizontalRadio
                          title={
                            "You can view and pay bills for a dependent family member. Do you want to request access?*"
                          }
                          style={{
                            paddingVertical: 0,
                            marginVertical: 0,
                          }}
                          selected={this.state.includeBilling}
                          radioButtonValues={["No", "Yes"]}
                          values={["No", "Yes"]}
                          onSelect={(index, value) => {
                            this.setState({
                              includeBilling: value,
                            });
                          }}
                        />
                      </View>
                    </>
                  )}

                  {!isMobile ? (
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View style={[{ flex: 1 }, styles.itemMargin]}>
                        <HorizontalRadio
                          title={
                            "You can login on behalf of a dependent family member. Do you want to request access?"
                          }
                          style={{
                            paddingVertical: 0,
                            marginVertical: 0,
                          }}
                          selected={this.state.loginAccess}
                          radioButtonValues={["No", "Yes"]}
                          values={["No", "Yes"]}
                          onSelect={(index, value) => {
                            this.setState({
                              loginAccess: value,
                            });
                          }}
                        />
                      </View>
                    </View>
                  ) : (
                    <>
                      <View style={[styles.itemMargin]}>
                        <HorizontalRadio
                          title={
                            "You can login on behalf of a dependent family member. Do you want to request access?"
                          }
                          style={{
                            paddingVertical: 0,
                            marginVertical: 0,
                          }}
                          selected={this.state.loginAccess}
                          radioButtonValues={["No", "Yes"]}
                          values={["No", "Yes"]}
                          onSelect={(index, value) => {
                            this.setState({
                              loginAccess: value,
                            });
                          }}
                        />
                      </View>
                    </>
                  )}

                  {!isMobile ? (
                    <>
                      <Button
                        uppercase={false}
                        mode={"contained"}
                        onPress={() => this.validateAndAddMember()}
                        style={{
                          width: 160,
                          left: "38%",
                          top: 50,
                          right: "40%",
                          marginBottom: 100,
                        }}
                        color={"#410DAA"}
                      >
                        <Text
                          style={{
                            color: "#fff",
                            fontSize: 14,
                          }}
                        >
                          Send Request
                        </Text>
                      </Button>
                    </>
                  ) : (
                    <Button
                      uppercase={false}
                      mode={"contained"}
                      onPress={() => this.validateAndAddMember()}
                      style={{
                        width: "40%",
                        marginLeft: "30%",
                      }}
                      color={"#410DAA"}
                    >
                      <Text
                        style={{
                          color: "#fff",
                          fontSize: 15,
                        }}
                      >
                        Send Request
                      </Text>
                    </Button>
                  )}
                  {isMobile && <View style={{ marginTop: 35 }}></View>}
                </ScrollView>
              </>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: isWeb ? 32 : 0,
    paddingRight: isWeb ? 32 : 0,
    paddingTop: isWeb ? 30 : 10,
    width: "100%",
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
    paddingHorizontal: isMobile ? 16 : 0,
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
  filterText: {
    fontSize: 15,
    color: "gray",
    fontWeight: "200",
  },
  filtersTitle: {
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 6,
    marginLeft: isMobile ? 16 : 0,
  },
});
