import React from "react";
import { SafeAreaView, ScrollView,Platform, StatusBar, Linking } from "react-native";
import { Text } from "react-native-paper";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { SideHeading } from "../../components/Common/sideHeading";
import AlertModal from "../../components/Common/Modals/AlertModal";
import amplitude from "../../utils/amplitude";
import { ImportStock } from "../../components/Common/importStock";
import { selectOneFile } from "../../utils";

export default class ImportOtherAssets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fundEmail: "",
    };
  }

  componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("importStockIntro",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("importStockIntro", { path: this.props.navigation.getState().routeName });
    }
    this.setState({});
  }

  static navigationOptions = ({ navigation }) => ({
    tabBarVisible: false,
  });

  importNew = () => {};

  _header() {
    return (
      <NavigationHeader
        navigation={this.props.navigation}
        navigationHeader={["Back"]}
      />
    );
  }

  _selectFile = (fileType) => {
    this.setState({ fileLoading: false });
    selectOneFile(fileType).then((res) => {
      if (res) {
        this.props.navigation.navigate("ImportStocksPortfolio", {
          file: res,
        });
      }
    });
  };

  _downloadSample = () => {};

  _navigateToEmail = () => {
    Linking.openURL("mailto:advisor@maxiomwealth.com");
    return;
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {this._header()}
        <ScrollView
          showsVerticalScrollIndicator={true}
          style={{ flex: 1 }}
          keyboardShouldPersistTaps="handled"
        >
          <SideHeading
            title="Import Stock Portfolio"
            headingTitleStyle={{
              fontSize: 20,
              alignItems: "center",
            }}
          />
          <ImportStock
            title="E-mail"
            description={[
              {
                text: "Simply forward your NSDL Consolidated Statement to upload@maxiom.in. Track in one place.",
              },
              {
                text: "Search email for subject 'Your NSDL CAS'.",
                color: "#a8a8a8",
              },
            ]}
            sample={true}
            error={""}
            buttonTitle="Open Email"
            buttonDescription="Forward to advisor@maxiomwealth.com"
            style={{ paddingHorizontal: 16, paddingVertical: 6 }}
            onPress={() => {
              this._navigateToEmail();
            }}
            downloadSample={() => {
              this._downloadSample();
            }}
            whyDoThis={() => {
              this.setState({ dialogVisible: !this.state.dialogVisible });
            }}
          />
          <ImportStock
            title="Direct Upload"
            description={[
              {
                text: "Upload your stock portfolio as per sample shared below.",
              },
            ]}
            sample={false}
            error={""}
            buttonTitle="Select File"
            onPress={() => {
              this._selectFile([
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ]);
            }}
            style={{ paddingHorizontal: 16, paddingVertical: 6 }}
          />
        </ScrollView>
        <AlertModal
          visible={this.state.dialogVisible}
          style={{}}
          closeModal={() => {
            this.setState({ dialogVisible: false });
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: "bold",
              textAlign: "left",
              marginBottom: 5,
              color: "#121212",
            }}
          >
            Why do this ?
          </Text>
          <Text style={{ fontSize: 13, color: "#121212", marginVertical: 5 }}>
            1. We assure of strict confidence and any information will be shared
            with you for portfolio advice and insights.
          </Text>

          <Text style={{ fontSize: 13, color: "#121212", marginVertical: 5 }}>
            2. Get a free health check. Your data will be kept confidential. We
            don't share your data or email with others.
          </Text>
        </AlertModal>
      </SafeAreaView>
    );
  }
}
