import React from "react";
import {
  ActivityIndicator,
  View,
  StyleSheet,
  ScrollView,
  Dimensions,
  Platform,
  TouchableOpacity,
} from "react-native";
import amplitude from "../../utils/amplitude";
import { Text, Divider } from "react-native-paper";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { ActionTabs } from "./actionTabs";
import { VersionNumber } from "../../components/Common/VersionNumber";
import AwaitingApprovalTable from "./AwaitingApprovalTable";
import CompletedTable from "./CompletedTable";
import RejectedTable from "./RejectedTable";
import {
  createOrderWithFirstInstallment,
  getCustomerActionItemDetails,
  getCustomerReport,
  getUrlForClient2FA,
  getUrlForSmallcaseOrders,
  redeemAmountForFund,
  rejectAdvisoryActionItems,
  runActionItemsToPlaceOrders,
} from "../../api";
import Modal from "../../components/Common/Modal";
import { ShowToast } from "../../components/Common/Toast";
import { AntDesign } from "@expo/vector-icons";
import { getUserDetails, isMobile, months_short_names } from "../../utils";
import { cancelSIPOrder } from "../../api/account";
import PlacePaymentAndOTP from "../../components/Common/PlacePaymentAndOTP";
// import * as XLSX from "xlsx";

// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import moment from "moment";
import SearchBar from "../../components/Common/SearchBar";
import Store from "../../store";
import { MAIN_URL } from "../../api/urls";
import { GATEWAY_ENVIRONMENT } from "../../api/apiConfig";
// import ToDOsTable from "./ToDOsTable";
import StockTable from "../../components/InvestmentOverview/NewDesign/StockTable";
import { getAdvisorDashboardDetials } from "../../api/goals";
import SSPDFTabel from "../mutualfunds/components/sspdftable";
const { width } = Dimensions.get("window");

export default class AdvisoryActionItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      isLoading: true,
      errorMsg: false,
      place_order: false,
      key: "",
      showLogModal: false,
      selectedActionItem: null,
      selectedActionTab: "AwaitingApproval",
      error: null,
      AwaitingApprovalArrItems: [],
      RejectedArrItems: [],
      CompletedArrItems: [],
      ItemLogArr: [],
      selectedType: "",
      selectedStocks: "",
      selectedSource: "",
      selectedStatus: "",
      selectedDueDate: "",
      selectedCustomer: "",
      enteredDescription: "",
      searchIcon: true,
      radioBtnValue: [],
      quantity: [],
      clientType: "",
      showToast: "false",
      message: "",
      isLoading2: true,
      isLoading3: false,
      isLoading4: false,
      btnText: "Open OTP Link",
      purchase_2FA_url: null,
      purchase_url: null,
      total_count: 0,
      recd_count: 0,
      toastText: "",
      mf_recommend_set_no: "",
      modalLoading: false,
      actionItemDetails: {},
      showPayment: false,
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("todos", { path: global.location.href });
      } else {
        amplitude.logEvent("todos", {
          path: this.props.navigation.getState().routeName,
        });
      }
      await this.fetchData();
    } catch (err) {
      this.setState({
        isLoading: false,
        error: err,
        errorText: "Something went wrong.",
      });
    }
  }

  async checkOTPStatus() {
    try {
      setTimeout(async () => {
        const payload = {
          previous_key: "purchase_2FA_check",
          param: this.state.mf_recommend_set_no,
          customer_id: this.state.userData.customer_id,
        };
        let total_count = 0;
        let recd_count = 0;
        let resp = await getCustomerReport(payload);
        if (resp && resp.code && resp.code == "100") {
          total_count = resp.result[0].total_count;
          recd_count = resp.result[0].recd_count;
          if (recd_count > 0) {
            this.setState({
              isLoading2: false,
              isLoading3: false,
              isLoading4: true,
              loaderText:
                total_count > recd_count
                  ? `Total Orders: ${total_count}, Approved Orders: ${recd_count}`
                  : "Congratulations! Orders approved via OTP!",
              btnText: "BSE Payment Link",
              purchase_2FA_url: this.state.purchase_2FA_url,
              purchase_url: this.state.purchase_url,
              total_count,
              recd_count,
            });
            this.checkOTPStatus();
          } else {
            this.checkOTPStatus();
          }
        }
      }, 5000);
    } catch (e) { }
  }

  async fetchData(message = "") {
    if (message != "") {
      this.setState({ showToast: "true", message });
    }
    this.setState({ isLoading: true, showToast: false });
    let AwaitingApprovalArrItems = [];
    let RejectedArrItems = [];
    let CompletedArrItems = [];

    let userData = await getUserDetails();

    let customer_id = null;
    if (
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      customer_id = Store.getState().dashboard.viewModeUserId.user_id;
    } else {
      customer_id = userData.customer_id;
    }
    let hostname = global.location.hostname;
    // hostname = "dapp.jamawealth.com";
    const payload = {
      previous_key: "partner_cai",
      rm_cust_id: customer_id,
      domain_name: hostname,
    };
    let customerActionItemResp = await getCustomerReport(payload);

    customerActionItemResp.result.forEach((item) => {
      if (item.type != "BSEP") {
        if (item.status == "OPN") {
          AwaitingApprovalArrItems.push(item);
        } else if (item.status == "COM") {
          CompletedArrItems.push(item);
        } else if (item.status == "REJ" || item.status == "CAN") {
          RejectedArrItems.push(item);
        }
      }
    });

    let { is_ria } = getUserDetails();

    let mfAwaitingAppSetCounts = {};
    const AwaitingApprovalFilteredData = [];
    AwaitingApprovalArrItems.forEach((item) => {
      const mfSetNo = item["mf_recommend_set_no"];
      mfAwaitingAppSetCounts[mfSetNo] =
        (mfAwaitingAppSetCounts[mfSetNo] || 0) + 1;
      if (mfAwaitingAppSetCounts[mfSetNo] === 1 || !mfSetNo) {
        AwaitingApprovalFilteredData.push(item);
      }
    });
    AwaitingApprovalFilteredData.forEach((item) => {
      item["ID"] = item["mf_recommend_set_no"] ? item["mf_recommend_set_no"] : item["customeractionitem_id"]
      const mfSetNo = item["mf_recommend_set_no"];
      item["is_mf_rec_set_no"] =
        mfAwaitingAppSetCounts[mfSetNo] > 1 &&
        mfSetNo !== "" &&
        mfSetNo !== null;
    });
    AwaitingApprovalFilteredData.sort(function (a, b) {
      return new Date(b.due_date) - new Date(a.due_date);
    });

    let mfRejectedSetCounts = {};
    const rejectedFilteredData = [];
    RejectedArrItems.forEach((item) => {
      const mfSetNo = item["mf_recommend_set_no"];
      mfRejectedSetCounts[mfSetNo] = (mfRejectedSetCounts[mfSetNo] || 0) + 1;
      if (mfRejectedSetCounts[mfSetNo] === 1 || !mfSetNo) {
        rejectedFilteredData.push(item);
      }
    });
    rejectedFilteredData.forEach((item) => {
      item["ID"] = item["mf_recommend_set_no"] ? item["mf_recommend_set_no"] : item["customeractionitem_id"]
      const mfSetNo = item["mf_recommend_set_no"];
      item["is_mf_rec_set_no"] =
        mfRejectedSetCounts[mfSetNo] > 1 && mfSetNo !== "" && mfSetNo !== null;
    });
    rejectedFilteredData.sort(function (a, b) {
      return new Date(b.due_date) - new Date(a.due_date);
    });

    let mfCompletedSetCounts = {};
    const completedFilteredData = [];
    CompletedArrItems.forEach((item) => {
      const mfSetNo = item["mf_recommend_set_no"];
      mfCompletedSetCounts[mfSetNo] = (mfCompletedSetCounts[mfSetNo] || 0) + 1;
      if (mfCompletedSetCounts[mfSetNo] === 1 || !mfSetNo) {
        completedFilteredData.push(item);
      }
    });
    completedFilteredData.forEach((item) => {
      item["ID"] = item["mf_recommend_set_no"] ? item["mf_recommend_set_no"] : item["customeractionitem_id"]
      const mfSetNo = item["mf_recommend_set_no"];
      item["is_mf_rec_set_no"] =
        mfCompletedSetCounts[mfSetNo] > 1 && mfSetNo !== "" && mfSetNo !== null;
    });
    completedFilteredData.sort(function (a, b) {
      return new Date(b.due_date) - new Date(a.due_date);
    });

    this.setState({
      isLoading: false,
      AwaitingApprovalArrItems: AwaitingApprovalFilteredData,
      RejectedArrItems: rejectedFilteredData,
      CompletedArrItems: completedFilteredData,
      filteredAwaitingData: AwaitingApprovalFilteredData,
      filteredRejectedData: rejectedFilteredData,
      filteredCompletedData: completedFilteredData,
      is_ria: is_ria,
      userData: userData,
    });
  }

  actionsTabList = [
    { label: "Awaiting approval", value: "AwaitingApproval" },
    { label: "Rejected", value: "Rejected" },
    { label: "Completed", value: "Completed" },
  ];

  filePathset(e) {
    e.stopPropagation();
    e.preventDefault();
    let file = e.target.files[0];
    let f = file;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      this.convertToJson(data);
    };
    reader.readAsBinaryString(f);
  }

  convertToJson(csv) {
    let lines = csv.split("\n");
    let result = [];
    let headers = lines[0].split(",");
    for (let i = 1; i < lines.length; i++) {
      let obj = {};
      let currentline = lines[i].split(",");
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
    return JSON.stringify(result);
  }

  onSelectActionTab = (selectedActionTab) => {
    this.setState({
      selectedActionTab: this.actionsTabList[selectedActionTab].value,
    });
  };

  handleAwaitingActionSearch = (searchAwaitingText) => {
    let filteredAwaitingData = [];
    const searchData = this.state.AwaitingApprovalArrItems;

    if (searchAwaitingText) {
      filteredAwaitingData = searchData.filter((action) => {

        let datavalue = [
          action.code_description,
          action.customer_name,
          action.description,
        ].join(" ");

        return datavalue
          .toLowerCase()
          .includes(searchAwaitingText.toLowerCase());
      });
    } else {
      filteredAwaitingData = this.state.AwaitingApprovalArrItems;
    }

    if (filteredAwaitingData.length === 0 && searchAwaitingText) {
      filteredAwaitingData = this.state.AwaitingApprovalArrItems; // Show all items if no matches found
    }


    this.setState({
      searchAwaitingText,
      filteredAwaitingData,
      sorting_field: null,
      sorting_value: null,
    });
  };

  handleRejectedActionSearch = (searchRejectedText) => {
    let filteredRejectedData = [];
    const searchData = this.state.RejectedArrItems;

    if (searchRejectedText) {
      filteredRejectedData = searchData.filter((action) => {

        let datavalue = [
          action.code_description,
          action.customer_name,
          action.description,
        ].join(" ");

        return datavalue
          .toLowerCase()
          .includes(searchRejectedText.toLowerCase());
      });
    } else {
      filteredRejectedData = this.state.RejectedArrItems; // Show all items when search text is empty
    }

    // Ensure the table is shown even if no matches are found
    if (filteredRejectedData.length === 0 && searchRejectedText) {
      filteredRejectedData = this.state.RejectedArrItems; // Show all items if no matches found
    }

    this.setState({
      searchRejectedText,
      filteredRejectedData,
      sorting_field: null,
      sorting_value: null,
    });
  };

  handleCompletedActionSearch = (searchCompletedText) => {
    let filteredCompletedData = [];
    const searchData = this.state.CompletedArrItems;

    if (searchCompletedText) {
      filteredCompletedData = searchData.filter((action) => {

        let datavalue = [
          action.code_description,
          action.customer_name,
          action.description,
        ].join(" ");

        return datavalue
          .toLowerCase()
          .includes(searchCompletedText.toLowerCase());
      });
    } else {
      filteredCompletedData = this.state.CompletedArrItems;
    }

    if (filteredCompletedData.length === 0 && searchCompletedText) {
      filteredCompletedData = this.state.CompletedArrItems; // Show all items if no matches found
    }
    this.setState({
      searchCompletedText,
      filteredCompletedData,
      sorting_field: null,
      sorting_value: null,
    });
  };

  compareStrings = (a, b) => {
    if (a && b) {
      const x = a.toLowerCase();
      const y = b.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    }
    return 0;
  };

  compareNumbers = (a, b) => {
    return a - b;
  };

  getSortedData = (data, sorting_field, sorting_value) => {
    if (!data || data.length === 0 || !sorting_field) {
      return data; // Return the original data if invalid
    }

    // Determine sorting type
    const sortingType = typeof data[0][sorting_field] === "string" ? "string" : "number";

    // Create a shallow copy of the data to avoid mutation
    const sortedData = [...data];

    // Sort the data
    sortedData.sort((a, b) => {
      const aValue = a[sorting_field];
      const bValue = b[sorting_field];

      // Handle null and undefined values
      const aIsNull = aValue === null || aValue === undefined || aValue === "";
      const bIsNull = bValue === null || bValue === undefined || bValue === "";

      // Null/empty values handling
      if (aIsNull || bIsNull) {
        if (aIsNull && bIsNull) return 0; // Both are null/empty
        return aIsNull ? (sorting_value ? -1 : 1) : (sorting_value ? 1 : -1); // Place null/empty at start or end
      }

      // String or number comparison
      return sortingType === "string"
        ? this.compareStrings(aValue, bValue) * (sorting_value ? 1 : -1)
        : this.compareNumbers(aValue, bValue) * (sorting_value ? 1 : -1);
    });

    return sortedData;
  };

  handleAwaitingSort = (field, sort) => {
    const sorting_field = field;
    const sorting_value = !sort; // Toggle sorting value
    const { filteredAwaitingData } = this.state;
    const sortedData = this.getSortedData(
      filteredAwaitingData,
      sorting_field,
      sorting_value
    );

    this.setState({
      sorting_field,
      sorting_value, // Update the sorting value in state
      filteredAwaitingData: sortedData,
    });
  };

  handleRejectedSort = (field, sort) => {
    const sorting_field = field;
    const sorting_value = !sort; // Toggle sorting value
    const { filteredRejectedData } = this.state;
    const sortedData = this.getSortedData(
      filteredRejectedData,
      sorting_field,
      sorting_value
    );

    this.setState({
      sorting_field,
      sorting_value, // Update the sorting value in state
      filteredRejectedData: sortedData,
    });
  };

  handleCompletedSort = (field, sort) => {
    const sorting_field = field;
    const sorting_value = !sort; // Toggle sorting value
    const { filteredCompletedData } = this.state;
    const sortedData = this.getSortedData(
      filteredCompletedData,
      sorting_field,
      sorting_value
    );

    this.setState({
      sorting_field,
      sorting_value, // Update the sorting value in state
      filteredCompletedData: sortedData,
    });
  };

  formatDate = (date) => {
    if (!date) return null;
    const format = "DD/MM/YYYY";
    date = new Date(date);
    return moment(date).format(format);
  };

  refreshContent = async (message) => {
    await this.fetchData(message);
  };

  handleStockAction = async (value, navigation) => {
    this.setState({ isLoading: true });
    let current_url = window.location.href;
    let url_path = current_url.split("/");
    let redirect_url = url_path[3] + "/" + url_path[4];
    const payload = {
      cai_pk: value.customeractionitem_id,
      redirection_url: redirect_url,
    };
    const response = await getUrlForSmallcaseOrders(payload);
    const { result } = response;
    this.setState({ isLoading: false });
    if (response.code == "100") {
      global.open(
        MAIN_URL + "/" + result.place_order_url + GATEWAY_ENVIRONMENT,
        "_self"
      );
    } else {
      this.refreshContent(response.msg);
    }
  };

  handleLumpsumAction = async (value, navigation) => {
    this.setState({
      showToast: "false",
      isLoading: true,
      isLoading2: true,
      loaderText: `Mutual Fund orders must be approved with OTP. Loading your SMS OTP approval page...`,
    });

    this.props.navigation.navigate("App", {
      screen: "Explore",
      params: {
        screen: "MfOtpAuthentication",
        params: {}
      }
    })

    // if (this.state.userData.customer_id == 39762) {
    //   this.props.navigation.navigate("App", {
    //     screen: "Explore",
    //     params: {
    //       screen: "MfOtpAuthentication",
    //       params: {}
    //     }
    //   })
    // } else {
    //   // this.setState({ isLoading: true });
    //   const key = moment().unix();
    //   const payload = {
    //     orders: [
    //       {
    //         scheme: value.amcfundscheme,
    //         amount: Number(value.amount_involved_in_activity),
    //         investmentType: "lumpsum",
    //         folio_number: value.outside_folio,
    //         cai_pk_string: value.customeractionitem_id.toString(),
    //       },
    //     ],
    //     redirection_url: global.location.origin + "/dashboard/process/" + key,
    //   };
    //   const response = await createOrderWithFirstInstallment(payload);
    //   const { result } = response;
    //   if (response && response.code && response.code == 100) {
    //     const { navigate_to, code, nav_props, ...restData } = response;
    //     Store.dispatch({
    //       type: "ADD_ACTION",
    //       payload: {
    //         key: key,
    //         data: {
    //           ...restData,
    //           ...nav_props,
    //         },
    //       },
    //     });
    //     if (response.success > 0) {
    //       const { purchase_2FA_url, mf_recommend_set_no } = response;
    //       const { url } = nav_props;
    //       this.setState({
    //         purchase_2FA_url,
    //         purchase_url: url,
    //         isLoading2: false,
    //         loaderText: `Mutual Fund orders must be approved with OTP. Continue to SMS OTP approval page.`,
    //         mf_recommend_set_no: mf_recommend_set_no,
    //         showPayment: true,
    //       });
    //     } else {
    //       this.setState({ isLoading2: false });
    //       this.props.navigation.navigate("App", {
    //         screen: "Dashboard",
    //         params: {
    //           screen: "Dashboard",
    //           params: { screen: "ProcessOrder", params: { action_id: key } },
    //         },
    //       });
    //     }
    //   } else {
    //     if (response.code == "101") {
    //       this.refreshContent(response.msg);
    //     }
    //     if (result[0].code == "101") {
    //       let message = result[0].msg.split(":");
    //       this.refreshContent(message[message.length - 1]);
    //     }
    //   }
    // }
    // if (response) {
    //   const { navigate_to, code, nav_props, ...restData } = response;
    //   // const key = moment().unix();
    //   Store.dispatch({
    //     type: "ADD_ACTION",
    //     payload: {
    //       key: key,
    //       data: {
    //         ...restData,
    //         ...nav_props,
    //       },
    //     },
    //   });
    //   if (response.code && response.code == 100) {
    //     if (response.success > 0) {
    //       if (response.navigate_to == "ShowBankAccount") {
    //         navigation.navigate("App", {
    //           screen: "Explore",
    //           params: {
    //             screen: "ShowBankAccount",
    //             params: {
    //               action: key,
    //               url: nav_props.url,
    //             },
    //           },
    //         });
    //         return;
    //       }
    //     }
    //   }
    //   navigation.navigate("App", {
    //     screen: "Dashboard",
    //     params: {
    //       screen: "ProcessOrder",
    //       params: { action_id: key },
    //     },
    //   });
    // }
  };

  handleSipAction = async (value, navigation) => {
    if (value.first_order_today_flag == 1) {
      this.setState({
        showToast: "false",
        isLoading: true,
        isLoading2: true,
        loaderText: `Mutual Fund orders must be approved with OTP. Loading your SMS OTP approval page...`,
      });

      this.props.navigation.navigate("App", {
        screen: "Explore",
        params: {
          screen: "MfOtpAuthentication",
          params: {}
        }
      })

      // if (this.state.userData.customer_id == 39762) {
      //   this.props.navigation.navigate("App", {
      //     screen: "Explore",
      //     params: {
      //       screen: "MfOtpAuthentication",
      //       params: {}
      //     }
      //   })
      // } else {
      //   // this.setState({ isLoading: true });
      //   const key = moment().unix();
      //   this.setState({ key });
      //   const payload = {
      //     orders: [
      //       {
      //         scheme: value.amcfundscheme,
      //         amount: Number(value.amount_involved_in_activity),
      //         sip_frequency_type: value.frequency,
      //         sip_start_date: value.start_date,
      //         sip_no_of_installments: Number(value.no_of_installments),
      //         investmentType: "sip",
      //         sip_first_order_flag: "Y",
      //         cai_pk_string: value.customeractionitem_id.toString(),
      //       },
      //     ],
      //     redirection_url: global.location.origin + "/dashboard/process/" + key,
      //   };
      //   const response = await createOrderWithFirstInstallment(payload);
      //   if (response && response.code && response.code == 100) {
      //     const { navigate_to, code, nav_props, ...restData } = response;
      //     Store.dispatch({
      //       type: "ADD_ACTION",
      //       payload: {
      //         key: key,
      //         data: {
      //           ...restData,
      //           ...nav_props,
      //         },
      //       },
      //     });
      //     if (response.success > 0) {
      //       const { purchase_2FA_url, mf_recommend_set_no } = response;
      //       const { url } = nav_props;
      //       this.setState({
      //         purchase_2FA_url,
      //         purchase_url: url,
      //         isLoading2: false,
      //         loaderText: `Mutual Fund orders must be approved with OTP. Continue to SMS OTP approval page.`,
      //         mf_recommend_set_no: mf_recommend_set_no,
      //       });
      //     } else {
      //       this.setState({ isLoading2: false });
      //       this.props.navigation.navigate("App", {
      //         screen: "Dashboard",
      //         params: {
      //           screen: "Dashboard",
      //           params: { screen: "ProcessOrder", params: { action_id: key } },
      //         },
      //       });
      //     }
      //   }
      // }
      // this.setState({ isLoading: false });
      // if (response) {
      //   const { navigate_to, code, nav_props, ...restData } = response;
      //   Store.dispatch({
      //     type: "ADD_ACTION",
      //     payload: {
      //       key: key,
      //       data: {
      //         ...restData,
      //         ...nav_props,
      //       },
      //     },
      //   });
      //   if (response.code && response.code == 100) {
      //     if (response.success > 0) {
      //       if (response.navigate_to == "ShowBankAccount") {
      //         navigation.navigate("App", {
      //           screen: "Dashboard",
      //           params: {
      //             screen: "Explore",
      //             params: {
      //               screen: "ShowBankAccount",
      //               params: {
      //                 action: key,
      //               },
      //             },
      //           },
      //         });
      //         return;
      //       }
      //       if (Platform.OS == "web") {
      //         global.open(url, "_self");
      //       } else {
      //         navigation.navigate("App", {
      //           screen: "Dashboard",
      //           params: {
      //             screen: "Portfolio",
      //             params: {
      //               screen: "ShowWebView",
      //               params: {
      //                 url: url,
      //                 successURL: "/payment/success",
      //                 onSuccess: function (navigation, results = {}) {
      //                   navigation.push("App", {
      //                     screen: "Dashboard",
      //                     params: {
      //                       screen: "ProcessOrder",
      //                       params: { action_id: key },
      //                     },
      //                   });
      //                 },
      //                 onError: function (navigation, results = {}) {
      //                   // navigation.push("App", { screen: "Dashboard", params: { screen: "Home", params: {} } });
      //                 },
      //               },
      //             },
      //           },
      //         });
      //       }
      //     } else {
      //       if (response.navigate_to == "ShowCreatedOrders") {
      //         navigation.navigate("App", {
      //           screen: "Dashboard",
      //           params: {
      //             screen: "Dashboard",
      //             params: {
      //               screen: "ProcessOrder",
      //               params: { action_id: key },
      //             },
      //           },
      //         });
      //       }
      //     }
      //   }
      // }
    } else {
      this.setState({ isLoading: true });
      let payload = {
        cai_id: value.customeractionitem_id,
        action_item_type: value.type,
      };
      let sipOrdResp = await getCustomerActionItemDetails(payload);
      if (sipOrdResp && sipOrdResp.code == 100) {
        payload = {
          scheme: value.amcfundscheme,
          amount: Number(value.amount_involved_in_activity),
          sip_frequency_type: value.frequency,
          sip_start_date: parseInt(
            value.start_date.substring(value.start_date.length - 2)
          ),
          sip_no_of_installments: Number(value.no_of_installments),
          scheme_name: sipOrdResp.results.scheme_name,
          amc_logo: sipOrdResp.results.amc_logo,
          cai_pk_string: value.customeractionitem_id.toString(),
        };
        this.setState({ isLoading2: false });
        navigation.navigate("App", {
          screen: "Explore",
          params: {
            screen: "SelectMandate",
            params: {
              fund: value.amcfundscheme,
              payload,
            },
          },
        });
      } else {
        this.refreshContent(sipOrdResp.msg);
      }
    }
  };

  handleRedemptionAction = async (value, navigation) => {
    this.setState({ isLoading: true });
    this.props.navigation.navigate("App", {
      screen: "Explore",
      params: {
        screen: "MfOtpAuthentication",
        params: {}
      }
    })
    // if (this.state.userData.customer_id == 39762) {
    //   this.props.navigation.navigate("App", {
    //     screen: "Explore",
    //     params: {
    //       screen: "MfOtpAuthentication",
    //       params: {}
    //     }
    //   })
    // } else {
    //   let payload = {
    //     cai_id: value.customeractionitem_id,
    //     action_item_type: value.type,
    //   };
    //   let redeemOrdResp = await getCustomerActionItemDetails(payload);
    //   if (redeemOrdResp && redeemOrdResp.code == 100) {
    //     payload = {
    //       amount: value.amount_involved_in_activity.toString(),
    //       redeem_full_amount_flag: value.all_units_flag ? "Y" : "N",
    //       investoraccountfund_id: redeemOrdResp.results.investoraccountfund_id,
    //       cai_id: value.customeractionitem_id,
    //       folio_number: value.outside_folio,
    //     };
    //     let response = await redeemAmountForFund(payload);
    //     this.setState({ isLoading: false });
    //     if (response && response.code && response.code == 100) {
    //       this.refreshContent(response.result);
    //     } else {
    //       this.refreshContent(response.result);
    //     }
    //   } else {
    //     this.refreshContent(redeemOrdResp.msg);
    //   }
    // }
  };

  handleCancelSipAction = async (value, navigation) => {
    this.setState({ isLoading: false });
    let payload = {
      cai_id: value.customeractionitem_id,
      action_item_type: value.type,
    };
    let sipOrdResp = await getCustomerActionItemDetails(payload);
    if (sipOrdResp && sipOrdResp.code == 100) {
      payload = {
        reg_id: sipOrdResp.results.bse_sip_reg_id,
        cai_id: value.customeractionitem_id,
      };
      let cancelSipResp = await cancelSIPOrder(payload);
      this.setState({ isLoading: false });
      if (cancelSipResp && cancelSipResp.code == 100) {
        this.refreshContent(cancelSipResp.msg);
      } else {
        this.refreshContent(cancelSipResp.error);
      }
    } else {
      this.refreshContent(sipOrdResp.msg);
    }
  };

  handleRedirectionToGoalFunds = async (value, navigation) => {
    try {
      let json = null;
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        json = await getAdvisorDashboardDetials(
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        json = await getAdvisorDashboardDetials();
      }
      let payload = {
        cai_id: value.customeractionitem_id,
      };
      if (value.is_mf_rec_set_no) {
        payload["mf_rec_set_no"] = value.mf_recommend_set_no;
      }
      const response = await getCustomerActionItemDetails(payload);
      // console.log(response);
      let keyValues = Object.keys(response.goal_mf_recommendation);
      let updateGoalList = json.goals.filter((item) => {
        if (keyValues.includes(`${item.customergoal_id}`)) {
          return item;
        }
      });
      updateGoalList.forEach((item) => {
        item["schemeDetails"] =
          response.goal_mf_recommendation[item.customergoal_id];
      });
      // console.log(updateGoalList);
      this.setState({ isLoading2: false });
      this.props.navigation.navigate("App", {
        screen: "ViewMode",
        params: {
          screen: "SuggestedFunds",
          params: {
            goalList: updateGoalList,
          },
        },
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        error: err,
        errorText: "Something went wrong.",
      });
    }
  };

  handleShowDetailsForActionItem = async (value, openModal) => {
    this.setState({
      showLogModal: openModal,
      modalLoading: true,
    });
    let payload = {
      cai_id: value.customeractionitem_id,
    };
    if (value.is_mf_rec_set_no) {
      payload["mf_rec_set_no"] = value.mf_recommend_set_no;
    }
    const response = await getCustomerActionItemDetails(payload);
    // console.log(response);
    let actionItemDetails = {};
    if (response && response.code == 100) {
      let { results } = response;
      let due_date_value = "";
      let date_due = value.due_date;
      if (date_due) {
        due_date_value = date_due.split("-");
        const month = months_short_names(parseInt(due_date_value[1]));
        due_date_value =
          due_date_value[2] + " " + month + " " + due_date_value[0];
      }
      if (results.action_item_type == "ASTO") {
        actionItemDetails["type"] = "stock_orders";
        actionItemDetails["item_id"] = value.customeractionitem_id;
        actionItemDetails["item_desc"] = value.description;
        actionItemDetails["item_due_date"] = due_date_value;
        actionItemDetails["item_type"] = results.action_item_desc;
        actionItemDetails["item_status"] = results.status;
        actionItemDetails["stock_table_data"] =
          results.result[0].stock_action_items;
        actionItemDetails["mf_table_data"] = [];
      } else {
        actionItemDetails["type"] = "mf_orders";
        actionItemDetails["item_id"] = value.is_mf_rec_set_no
          ? value.mf_recommend_set_no
          : value.customeractionitem_id;
        actionItemDetails["item_desc"] =
          results.action_item_type == "LMPI" ||
            results.action_item_type == "SIPS"
            ? "Mutual Fund Recommendation"
            : value.description;
        actionItemDetails["item_due_date"] = due_date_value;
        actionItemDetails["item_type"] = results.action_item_desc;
        actionItemDetails["item_status"] = results.status;
        actionItemDetails["stock_table_data"] = [];
        actionItemDetails["mf_table_data"] = results.result;
      }
    }
    let stockItemHeaders = null;
    let stockItemValues = null;
    let mfItemHeaders = null;
    let mfItemValues = null;
    let stockItems = [];
    let mfItems = [];
    if (
      actionItemDetails &&
      actionItemDetails.stock_table_data &&
      actionItemDetails.stock_table_data.length > 0
    ) {
      stockItems = this.getStockTableData(actionItemDetails.stock_table_data);
    }
    if (
      actionItemDetails &&
      actionItemDetails.mf_table_data &&
      actionItemDetails.mf_table_data.length > 0
    ) {
      mfItems = this.getMfTableData(actionItemDetails.mf_table_data);
    }
    if (stockItems && stockItems.length > 0) {
      stockItemHeaders = stockItems[0];
      stockItemValues = stockItems[1];
    }
    if (mfItems && mfItems.length > 0) {
      mfItemHeaders = mfItems[0];
      mfItemValues = mfItems[1];
    }
    // console.log("loading api data", actionItemDetails);
    this.setState({
      actionItemDetails: actionItemDetails,
      stockItemHeaders,
      stockItemValues,
      mfItemHeaders,
      mfItemValues,
      modalLoading: false,
    });
    return actionItemDetails;
  };

  getAwaitingHeaderData = (data, navigation) => {
    const { sorting_field, sorting_value } = this.state;
    const keysData = [
      {
        label: "ID",
        key: "ID",
      },
      {
        label: "Created Date",
        key: "created",
      },
      {
        label: "Due Date",
        key: "due_date",
      },
      {
        label: "Type",
        key: "code_description",
      },
      {
        label: "Description",
        key: "description",
      },
      {
        label: "Actions",
        key: "actions",
      },
    ];

    let headerAwaitingData = [];
    keysData.forEach((headerItem) => {
      headerAwaitingData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: headerItem.label == "Actions" ? false : true,
        onSort: this.handleAwaitingSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    let valuesAwaiting = [];
    // console.log(data);
    data.forEach((value) => {
      let date_created = this.formatDate(value.created);
      let date = "";
      if (date_created) {
        date = date_created.split("/");
        const month = months_short_names(parseInt(date[1]));
        date = date[0] + " " + month + " " + date[2];
      }

      let date_due = this.formatDate(value.due_date);
      let due_date_value = "";
      if (date_due) {
        due_date_value = date_due.split("/");
        const month = months_short_names(parseInt(due_date_value[1]));
        due_date_value =
          due_date_value[0] + " " + month + " " + due_date_value[2];
      }
      valuesAwaiting.push([
        {
          component: true,
          value: (
            <Text
              onPress={() => {
                this.handleShowDetailsForActionItem(value, true);
                // this.setState({
                //   showLogModal: true,
                //   selectedActionItem: value,
                // });
              }}
              style={{
                textDecorationLine: "underline",
                color: "#410DAA",
                textAlign: "center",
                fontSize: 12,
                fontWeight: "bold",
                fontFamily: "Roboto",
              }}
            >
              {value.ID}
              {/* {value.is_mf_rec_set_no
                ? value.mf_recommend_set_no
                : value.customeractionitem_id} */}
            </Text>
          ),
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: date,
          value: date,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: due_date_value,
          value: due_date_value,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.code_description,
          value: value.code_description,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.description,
          value: value.description,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          component: true,
          value:
            value.button_name == "Approve" ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 13,
                    textDecorationLine: "underline",
                    color: "#410DAA",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                  onPress={async () => {
                    try {
                      if (value.is_mf_rec_set_no) {
                        if (value.customergoal_id) {
                          // console.log("Suggested funds");
                          this.handleRedirectionToGoalFunds(value, navigation);
                        } else {
                          this.props.navigation.navigate("App", {
                            screen: "Explore",
                            params: {
                              screen: "MfOtpAuthentication",
                              params: {}
                            }
                          })
                          // if (this.state.userData.customer_id == 39762) {
                          //   this.props.navigation.navigate("App", {
                          //     screen: "Explore",
                          //     params: {
                          //       screen: "MfOtpAuthentication",
                          //       params: {}
                          //     }
                          //   })
                          // } else {
                          //   this.setState({
                          //     showToast: "false",
                          //     isLoading: true,
                          //     isLoading2: true,
                          //     loaderText: `Mutual Fund orders must be approved with OTP. Loading your SMS OTP approval page...`,
                          //   });
                          //   const actionItemDetails =
                          //     await this.handleShowDetailsForActionItem(
                          //       value,
                          //       false
                          //     );
                          //   if (actionItemDetails) {
                          //     const key = moment().unix();
                          //     let payload = {
                          //       cai_list: [],
                          //       redirect_url:
                          //         global.location.origin +
                          //         "/dashboard/process/" +
                          //         key,
                          //     };
                          //     actionItemDetails.mf_table_data.map((item) => {
                          //       payload.cai_list.push(item.cai_pk);
                          //       if (item.type == "LMPI") {
                          //       }
                          //       this.setState({ place_order: true });
                          //     });
                          //     const result = await runActionItemsToPlaceOrders(
                          //       payload
                          //     );
                          //     if (result && result.code == 100) {
                          //       Store.dispatch({
                          //         type: "ADD_ACTION",
                          //         payload: {
                          //           key: key,
                          //           data: {
                          //             ...result,
                          //           },
                          //         },
                          //       });
                          //       console.log(
                          //         "redirection urls is OTP ",
                          //         result.orders_auth_link
                          //       );
                          //       console.log(
                          //         "redirection urls is Payment ",
                          //         result.orders_payment_link
                          //       );
                          //       if (value.type == "LMPI") {
                          //         const {
                          //           orders_auth_link,
                          //           orders_payment_link,
                          //         } = result;
                          //         this.setState({
                          //           purchase_2FA_url: orders_auth_link,
                          //           purchase_url: orders_payment_link,
                          //           isLoading2: false,
                          //           loaderText: `Mutual Fund orders must be approved with OTP. Continue to SMS OTP approval page.`,
                          //           showPayment: true,
                          //         });
                          //       } else {
                          //         const {
                          //           orders_auth_link,
                          //           orders_payment_link,
                          //         } = result;
                          //         this.setState({
                          //           purchase_2FA_url: orders_auth_link,
                          //           purchase_url: orders_payment_link,
                          //           isLoading2: false,
                          //           loaderText: `Mutual Fund orders must be approved with OTP. Continue to SMS OTP approval page.`,
                          //           showPayment: false,
                          //         });
                          //       }
                          //     } else {
                          //       this.setState({ isLoading2: false });
                          //       this.props.navigation.navigate("App", {
                          //         screen: "Dashboard",
                          //         params: {
                          //           screen: "Dashboard",
                          //           params: {
                          //             screen: "ProcessOrder",
                          //             params: { action_id: key },
                          //           },
                          //         },
                          //       });
                          //     }
                          //   }
                          // }
                        }
                      } else {
                        if (value.type == "ASTO") {
                          this.handleStockAction(value, navigation);
                        } else if (value.type == "LMPI") {
                          this.handleLumpsumAction(value, navigation);
                        } else if (value.type == "SIPS") {
                          this.handleSipAction(value, navigation);
                        } else if (value.type == "REDM") {
                          this.handleRedemptionAction(value, navigation);
                        } else if (value.type == "SIPX") {
                          this.handleCancelSipAction(value, navigation);
                        }
                      }
                    } catch (err) {
                      console.log("loading api data", err);
                    }
                  }
                  }
                >
                  Approve
                </Text>
                <View style={{ padding: 10 }}></View>
                <Text
                  style={{
                    fontSize: 12,
                    textDecorationLine: "underline",
                    color: "#410DAA",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                  onPress={async () => {
                    let payload = {
                      actionitem_id: value.customeractionitem_id,
                    };
                    if (value.is_mf_rec_set_no) {
                      payload["mf_rec_set_no"] = value.mf_recommend_set_no;
                    }
                    rejectAdvisoryActionItems(payload).then(() => {
                      this.refreshContent("Action item rejected successfully");
                    });
                  }}
                >
                  Reject
                </Text>
              </View>
            ) : (
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 13,
                    color: "#C5C5C5",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Approve
                </Text>
                <View style={{ padding: 10 }}></View>
                <Text
                  style={{
                    fontSize: 12,
                    color: "#C5C5C5",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Reject
                </Text>
              </View>
            ),
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ]);
    });

    return { headerAwaitingData, valuesAwaiting };
  };

  getRejectedHeaderData = (data) => {
    const { sorting_field, sorting_value } = this.state;
    const keysData = [
      {
        label: "ID",
        key: "ID",
      },
      {
        label: "Type",
        key: "code_description",
      },
      {
        label: "Description",
        key: "description",
      },
      {
        label: "Created Date",
        key: "created",
      },
      {
        label: "Due Date",
        key: "due_date",
      },
      {
        label: "Rejected On",
        key: "completed_on",
      },
    ];

    let headerRejectedData = [];
    keysData.forEach((headerItem) => {
      headerRejectedData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: true,
        onSort: this.handleRejectedSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    let valuesRejected = [];
    data.forEach((value) => {
      let date_created = this.formatDate(value.created);
      let date = "";
      if (date_created) {
        date = date_created.split("/");
        const month = months_short_names(parseInt(date[1]));
        date = date[0] + " " + month + " " + date[2];
      }

      let date_due = this.formatDate(value.due_date);
      let due_date_value = "";
      if (date_due) {
        due_date_value = date_due.split("/");
        const month = months_short_names(parseInt(due_date_value[1]));
        due_date_value =
          due_date_value[0] + " " + month + " " + due_date_value[2];
      }

      let date_completed = this.formatDate(value.completed_on);
      let completed_date_value = "";
      if (date_completed) {
        completed_date_value = date_completed.split("/");
        const month = months_short_names(parseInt(completed_date_value[1]));
        completed_date_value =
          completed_date_value[0] + " " + month + " " + completed_date_value[2];
      }
      valuesRejected.push([
        {
          component: true,
          value: (
            <Text
              onPress={() => {
                this.handleShowDetailsForActionItem(value, true);
                // this.setState({
                //   showLogModal: true,
                //   selectedActionItem: value,
                // });
              }}
              style={{
                textDecorationLine: "underline",
                color: "#410DAA",
                textAlign: "center",
                fontSize: 12,
                fontWeight: "bold",
                fontFamily: "Roboto",
              }}
            >
              {value.ID}
              {/* {value.is_mf_rec_set_no
                ? value.mf_recommend_set_no
                : value.customeractionitem_id} */}
            </Text>
          ),
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.code_description,
          value: value.code_description,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.description,
          value: value.description,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: date,
          value: date,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: due_date_value,
          value: due_date_value,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: completed_date_value ? completed_date_value : "",
          value: value.completed_on ? value.completed_on : "",
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ]);
    });
    return { headerRejectedData, valuesRejected };
  };

  getCompletedHeaderData = (data) => {
    const { sorting_field, sorting_value } = this.state;
    const keysData = [
      {
        label: "ID",
        key: "ID",
      },
      {
        label: "Type",
        key: "code_description",
      },
      {
        label: "Description",
        key: "description",
      },
      {
        label: "Created Date",
        key: "created",
      },
      {
        label: "Due Date",
        key: "due_date",
      },
      {
        label: "Completed On",
        key: "completed_on",
      },
    ];

    let headerCompletedData = [];
    keysData.forEach((headerItem) => {
      headerCompletedData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: true,
        onSort: this.handleCompletedSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    let valuesCompleted = [];
    data.forEach((value) => {
      let date_created = this.formatDate(value.created);
      let date = "";
      if (date_created) {
        date = date_created.split("/");
        const month = months_short_names(parseInt(date[1]));
        date = date[0] + " " + month + " " + date[2];
      }

      let date_due = this.formatDate(value.due_date);
      let due_date_value = "";
      if (date_due) {
        due_date_value = date_due.split("/");
        const month = months_short_names(parseInt(due_date_value[1]));
        due_date_value =
          due_date_value[0] + " " + month + " " + due_date_value[2];
      }

      let date_completed = this.formatDate(value.completed_on);
      let completed_date_value = "";
      if (date_completed) {
        completed_date_value = date_completed.split("/");
        const month = months_short_names(parseInt(completed_date_value[1]));
        completed_date_value =
          completed_date_value[0] + " " + month + " " + completed_date_value[2];
      }
      valuesCompleted.push([
        {
          component: true,
          value: (
            <Text
              onPress={() => {
                this.handleShowDetailsForActionItem(value, true);
                // this.setState({
                //   showLogModal: true,
                //   selectedActionItem: value,
                // });
              }}
              style={{
                textDecorationLine: "underline",
                color: "#410DAA",
                textAlign: "center",
                fontSize: 12,
                fontWeight: "bold",
                fontFamily: "Roboto",
              }}
            >
              {value.ID}
              {/* {value.is_mf_rec_set_no
                ? value.mf_recommend_set_no
                : value.customeractionitem_id} */}
            </Text>
          ),
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.code_description,
          value: value.code_description,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.description,
          value: value.description,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: date,
          value: date,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: due_date_value,
          value: due_date_value,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: completed_date_value ? completed_date_value : "",
          value: value.completed_on ? value.completed_on : "",
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ]);
    });
    return { headerCompletedData, valuesCompleted };
  };

  handleOTPApproval = async () => {
    global.open(this.state.purchase_2FA_url, "_blank");
    this.setState({
      isLoading3: true,
      isLoading4: false,
      loaderText: "Waiting for SMS OTP approval of your orders...",
    });
    this.checkOTPStatus();
  };

  handleOTPPageNotOpen = async () => {
    let url_2fa = this.state.purchase_2FA_url;
    if (url_2fa.includes("http")) {
      global.open(url_2fa, "_blank");
    } else {
      const response = await getUrlForClient2FA();
      if (response && response.code == 100) {
        const url = response.result;
        global.open(url, "_blank");
      }
    }
  };

  handlePayment = async () => {
    global.open(this.state.purchase_url, "_self");
  };

  handleContinue = () => {
    if (this.state.recd_count === 0) {
      this.setState({
        errorMsg: true,
      });
    } else {
      this.setState({ isLoading2: false });
      this.props.navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Dashboard",
          params: {
            screen: "ProcessOrder",
            params: { action_id: this.state.key },
          },
        },
      });
    }
  };
  getStockTableData = (data) => {
    const keysData = [
      {
        label: "Id",
        key: "sai_pk",
      },
      {
        label: "Name",
        key: "ms_name",
      },
      {
        label: "Quantity",
        key: "quantity",
      },
      {
        label: "Transaction Type",
        key: "transaction_type",
      },
    ];

    let headerData = [];
    keysData.forEach((headerItem) => {
      headerData.push({
        label: headerItem.label,
        style: [styles.modalTableHeader],
        headerItemContainerStyle: [styles.modalHeaderItemContainerStyle],
        sortOption: false,
        key: headerItem.key,
      });
    });

    let values = [];
    data.forEach((value) => {
      values.push([
        {
          valueToDisplay: value["sai_pk"],
          value: value["sai_pk"] || "",
          style: [styles.modalRowItem],
          rowItemContainerStyle: [styles.modalRowItemContainerStyle],
        },
        {
          valueToDisplay: value["ms_name"],
          value: value["ms_name"] || "",
          style: [styles.modalRowItem],
          rowItemContainerStyle: [styles.modalRowItemContainerStyle],
        },
        {
          valueToDisplay: value["quantity"],
          value: value["quantity"] || 0,
          style: [styles.modalRowItem],
          rowItemContainerStyle: [styles.modalRowItemContainerStyle],
        },
        {
          valueToDisplay: value["transaction_type"],
          value: value["transaction_type"] || "",
          style: [styles.modalRowItem],
          rowItemContainerStyle: [styles.modalRowItemContainerStyle],
        },
      ]);
    });
    return [headerData, values];
  };

  getMfTableData = (data) => {
    const keysData = [
      {
        label: "Id",
        key: "cai_pk",
      },
      {
        label: "Name",
        key: "scheme_name",
      },
      {
        label: "Type",
        key: "type",
      },
      {
        label: "Amount",
        key: "amount",
      },
    ];

    let headerData = [];
    keysData.forEach((headerItem) => {
      headerData.push({
        label: headerItem.label,
        style: [styles.modalTableHeader],
        headerItemContainerStyle: [styles.modalHeaderItemContainerStyle],
        sortOption: false,
        key: headerItem.key,
      });
    });

    let values = [];
    data.forEach((value) => {
      values.push([
        {
          valueToDisplay: value["cai_pk"],
          value: value["cai_pk"] || "",
          style: [styles.modalRowItem],
          rowItemContainerStyle: [styles.modalRowItemContainerStyle],
        },
        {
          valueToDisplay: value["scheme_name"],
          value: value["scheme_name"] || "",
          style: [styles.modalRowItem],
          rowItemContainerStyle: [styles.modalRowItemContainerStyle],
        },
        {
          valueToDisplay: value["type"],
          value: value["type"] || 0,
          style: [styles.modalRowItem],
          rowItemContainerStyle: [styles.modalRowItemContainerStyle],
        },
        {
          valueToDisplay: value["amount"],
          value: value["amount"] || "",
          style: [styles.modalRowItem],
          rowItemContainerStyle: [styles.modalRowItemContainerStyle],
        },
      ]);
    });
    return [headerData, values];
  };

  render() {
    const {
      error,
      isLoading,
      errorText,
      selectedActionTab,
      is_ria,
      searchAwaitingText,
      searchRejectedText,
      searchCompletedText,
      selectedActionItem,
      filteredAwaitingData = [],
      filteredRejectedData = [],
      filteredCompletedData = [],
      actionItemDetails,
      stockItemHeaders = [],
      stockItemValues = [],
      mfItemHeaders = [],
      mfItemValues = [],
    } = this.state;

    const { headerAwaitingData, valuesAwaiting } = this.getAwaitingHeaderData(
      filteredAwaitingData,
      this.props.navigation
    );
    let excelAwaitingData = [];
    filteredAwaitingData.forEach((item) => {
      let date_created = this.formatDate(item.created);
      let date = "";
      if (date_created) {
        date = date_created.split("/");
        const month = months_short_names(parseInt(date[1]));
        date = date[0] + " " + month + " " + date[2];
      }

      let date_due = this.formatDate(item.due_date);
      let due_date_value = "";
      if (date_due) {
        due_date_value = date_due.split("/");
        const month = months_short_names(parseInt(due_date_value[1]));
        due_date_value =
          due_date_value[0] + " " + month + " " + due_date_value[2];
      }

      excelAwaitingData.push({
        customeractionitem_id: item.customeractionitem_id,
        customer_name: item.customer_name,
        created: date,
        due_date: due_date_value,
        code_description: item.code_description,
        description: item.description.substring(0, 20),
      });
    });

    const { headerRejectedData, valuesRejected } =
      this.getRejectedHeaderData(filteredRejectedData);
    let excelRejectedData = [];
    filteredRejectedData.forEach((item) => {
      let date_created = this.formatDate(item.created);
      let date = "";
      if (date_created) {
        date = date_created.split("/");
        const month = months_short_names(parseInt(date[1]));
        date = date[0] + " " + month + " " + date[2];
      }

      let date_due = this.formatDate(item.due_date);
      let due_date_value = "";
      if (date_due) {
        due_date_value = date_due.split("/");
        const month = months_short_names(parseInt(due_date_value[1]));
        due_date_value =
          due_date_value[0] + " " + month + " " + due_date_value[2];
      }

      let date_completed = this.formatDate(item.completed_on);
      let completed_date_value = "";
      if (date_completed) {
        completed_date_value = date_completed.split("/");
        const month = months_short_names(parseInt(completed_date_value[1]));
        completed_date_value =
          completed_date_value[0] + " " + month + " " + completed_date_value[2];
      }

      excelRejectedData.push({
        customeractionitem_id: item.customeractionitem_id,
        customer_name: item.customer_name,
        code_description: item.code_description,
        description: item.description.substring(0, 20),
        created: date,
        due_date: due_date_value,
        completed_on: completed_date_value,
      });
    });

    const { headerCompletedData, valuesCompleted } =
      this.getCompletedHeaderData(filteredCompletedData);
    let excelCompletedData = [];
    filteredCompletedData.forEach((item) => {
      let date_created = this.formatDate(item.created);
      let date = "";
      if (date_created) {
        date = date_created.split("/");
        const month = months_short_names(parseInt(date[1]));
        date = date[0] + " " + month + " " + date[2];
      }

      let date_due = this.formatDate(item.due_date);
      let due_date_value = "";
      if (date_due) {
        due_date_value = date_due.split("/");
        const month = months_short_names(parseInt(due_date_value[1]));
        due_date_value =
          due_date_value[0] + " " + month + " " + due_date_value[2];
      }

      let date_completed = this.formatDate(item.completed_on);
      let completed_date_value = "";
      if (date_completed) {
        completed_date_value = date_completed.split("/");
        const month = months_short_names(parseInt(completed_date_value[1]));
        completed_date_value =
          completed_date_value[0] + " " + month + " " + completed_date_value[2];
      }

      excelCompletedData.push({
        customeractionitem_id: item.customeractionitem_id,
        customer_name: item.customer_name,
        code_description: item.code_description,
        description: item.description.substring(0, 20),
        created: date,
        due_date: due_date_value,
        completed_on: completed_date_value,
      });
    });

    // console.log(stockItemHeaders,
    //   stockItemValues,
    //   mfItemHeaders,
    //   mfItemValues)

    return error ? (
      <View style={styles.loadingContainer}>
        {/* {isLoading && <ActivityIndicator size="large" color="#410DAA" />} */}
        {errorText && <Text style={styles.errorText}>{errorText}</Text>}
        <VersionNumber absolute={true} />
      </View>
    ) : (
      <View style={{ backgroundColor: "white", flex: 1 }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Advisory Action Items"]}
          onPressBack={() => {
            this.setState({ isLoading2: false });
            this.props.navigation.navigate("Notifications");
          }}
        />
        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title={this.state.message}
            duration="3"
          />
        ) : null}
        {this.state.isLoading ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.isLoading2 && (
              <>
                <ActivityIndicator size="large" color="#410DAA" />
                <Text
                  style={{ marginTop: 20, fontWeight: "bold", fontSize: 17 }}
                >
                  {this.state.loaderText}
                </Text>
              </>
            )}
            {!this.state.isLoading2 && (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {this.state.isLoading2 && (
                  <>
                    <ActivityIndicator size="large" color="#410DAA" />
                    <Text
                      style={{
                        marginTop: 20,
                        fontWeight: "bold",
                        fontSize: 17,
                      }}
                    >
                      {this.state.loaderText}
                    </Text>
                  </>
                )}
                {!this.state.isLoading2 && (
                  <View style={{ flex: 1, width: "100%" }}>
                    <PlacePaymentAndOTP
                      handleOTPApproval={this.handleOTPApproval}
                      otploading={this.state.isLoading3}
                      showPayment={this.state.showPayment}
                      error={this.state.errorMsg}
                      handleContinue={this.handleContinue}
                      handlePayment={this.handlePayment}
                      paymentLoading={this.state.isLoading4}
                      handleOTPPageNotOpen={this.handleOTPPageNotOpen}
                    />
                  </View>
                )}
              </View>
            )}
          </View>
        ) : (
          <>
            <ActionTabs
              tabs={this.actionsTabList}
              onSelectTab={(data) => this.onSelectActionTab(data)}
              data={{
                AwaitingApproval:
                  this.state.AwaitingApprovalArrItems.length >= 1
                    ? this.state.AwaitingApprovalArrItems.length
                    : "0",
                Rejected:
                  this.state.RejectedArrItems.length >= 1
                    ? this.state.RejectedArrItems.length
                    : "0",
                Completed:
                  this.state.CompletedArrItems.length >= 1
                    ? this.state.CompletedArrItems.length
                    : "0",
              }}
            />
            <Divider />

            {this.state.showLogModal && (
              <Modal
                style={{
                  top: 0,
                  position: "absolute",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                containerStyle={{
                  marginHorizontal: isMobile ? 16 : 0,
                  borderRadius: 5,
                }}
              >
                {this.state.modalLoading ? (
                  <ActivityIndicator size="small" color="#410DAA" />
                ) : (
                  <View
                    style={{
                      paddingTop: isMobile ? 16 : 24,
                      paddingBottom: isMobile ? 17 : 23,
                      paddingHorizontal: isMobile ? 5 : 33,
                      minWidth: isMobile ? 200 : 633,
                      marginHorizontal: isMobile ? 16 : 0,
                      maxWidth: 850,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontWeight: "700", fontSize: 15 }}>
                        Action Details
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          this.setState({ showLogModal: false });
                        }}
                      >
                        <AntDesign
                          name="close"
                          size={24}
                          color="rgba(0, 0, 0, 0.5)"
                        />
                      </TouchableOpacity>
                    </View>
                    <Text style={{ marginTop: 18 }}>
                      Item ID: {actionItemDetails.item_id}
                    </Text>
                    <Text style={{ marginTop: 13 }}>
                      Due Date: {actionItemDetails.item_due_date}
                    </Text>
                    <Text style={{ marginTop: 13 }}>
                      Action Type: {actionItemDetails.item_type}
                    </Text>
                    <Text style={{ marginTop: 13 }}>
                      Description: {actionItemDetails.item_desc}
                    </Text>
                    <Text style={{ marginTop: 13 }}>
                      Status: {actionItemDetails.item_status}
                    </Text>
                    <View
                      style={{
                        maxHeight: 350,
                        paddingTop: 20,
                      }}
                    >
                      {actionItemDetails.type == "stock_orders" ? (
                        stockItemValues && stockItemValues.length > 0 ? (
                          <ScrollView>
                            {/* <StockTable
                              header={stockItemHeaders}
                              gridConfig={[0.1, 0.4, 0.25, 0.25]}
                              data={stockItemValues}
                              pagination={
                                stockItemValues.length > 10 ? true : false
                              }
                            /> */}
                            <SSPDFTabel
                              header={stockItemHeaders}
                              data={stockItemValues}
                              navigation={this.props.navigation}
                              gridConfig={[0.1, 0.4, 0.25, 0.25]} />
                          </ScrollView>
                        ) : null
                      ) : mfItemValues && mfItemValues.length > 0 ? (
                        <ScrollView>
                          <StockTable
                            header={mfItemHeaders}
                            gridConfig={[0.1, 0.4, 0.25, 0.25]}
                            data={mfItemValues}
                            pagination={mfItemValues.length > 10 ? true : false}
                          />
                        </ScrollView>
                      ) : null}
                    </View>
                  </View>
                )}
              </Modal>
            )}

            {selectedActionTab == "AwaitingApproval" ? (
              valuesAwaiting.length > 0 ? (
                <ScrollView
                  showsVerticalScrollIndicator={true}
                  style={{
                    paddingBottom: 100,
                    marginBottom: 30,
                    marginRight: 30,
                    paddingLeft: 20,
                  }}
                >
                  <ScrollView
                    showsVerticalScrollIndicator={true}
                    horizontal={true}
                  >
                    <View
                      style={{
                        flex: 1,
                      }}
                    >
                      <View style={styles.detailsContainer}>
                        <View style={styles.headingContainer}>
                          <SearchBar
                            value={searchAwaitingText}
                            onChange={this.handleAwaitingActionSearch}
                          />
                          <View
                            style={{
                              cursor: "pointer",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                              marginRight: 20,
                            }}
                          >
                            {/* <ExcelFile
                              filename={
                                "Awaiting Approval " +
                                moment().format("DD-MM-YYYY hh-mm-ss A")
                              }
                              element={
                                <Text
                                  style={{
                                    color: "#410DAA",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    fontSize: 15,
                                    textDecorationLine: "underline",
                                  }}
                                >
                                  Download As Excel
                                </Text>
                              }
                            >
                              <ExcelSheet
                                data={excelAwaitingData}
                                name="Awaiting Approval"
                              >
                                <ExcelColumn
                                  label="Item ID"
                                  value="customeractionitem_id"
                                />
                                <ExcelColumn
                                  label="Created Date"
                                  value="created"
                                />
                                <ExcelColumn
                                  label="Due Date"
                                  value="due_date"
                                />
                                <ExcelColumn
                                  label="Type"
                                  value="code_description"
                                />
                                <ExcelColumn
                                  label="Description"
                                  value="description"
                                />
                              </ExcelSheet>
                            </ExcelFile> */}
                          </View>
                        </View>
                        <AwaitingApprovalTable
                          header={headerAwaitingData}
                          gridConfig={[1, 1, 1, 1, 1, 1]}
                          data={valuesAwaiting}
                          navigation={this.props.navigation}
                          pagination={valuesAwaiting.length > 10 ? true : false}
                        />
                      </View>
                    </View>
                  </ScrollView>
                </ScrollView>
              ) : (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{ fontSize: 14, color: "#121212", marginTop: 100 }}
                  >
                    No Awaiting Approval Action Items
                  </Text>
                </View>
              )
            ) : null}

            {selectedActionTab == "Rejected" ? (
              valuesRejected.length > 0 ? (
                <ScrollView
                  showsVerticalScrollIndicator={true}
                  style={{
                    paddingBottom: 100,
                    marginBottom: 30,
                    marginRight: 30,
                    paddingLeft: 20,
                  }}
                >
                  <ScrollView
                    showsVerticalScrollIndicator={true}
                    horizontal={true}
                  >
                    <View
                      style={{
                        flex: 1,
                      }}
                    >
                      <View style={styles.detailsContainer}>
                        <View style={styles.headingContainer}>
                          <SearchBar
                            value={searchRejectedText}
                            onChange={this.handleRejectedActionSearch}
                          />
                          <View
                            style={{
                              cursor: "pointer",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                              marginRight: 20,
                            }}
                          >
                            {/* <ExcelFile
                              filename={
                                "Rejected " +
                                moment().format("DD-MM-YYYY hh-mm-ss A")
                              }
                              element={
                                <Text
                                  style={{
                                    color: "#410DAA",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    fontSize: 15,
                                    textDecorationLine: "underline",
                                  }}
                                >
                                  Download As Excel
                                </Text>
                              }
                            >
                              <ExcelSheet
                                data={excelRejectedData}
                                name="Rejected"
                              >
                                <ExcelColumn
                                  label="Item ID"
                                  value="customeractionitem_id"
                                />
                                <ExcelColumn
                                  label="Type"
                                  value="code_description"
                                />
                                <ExcelColumn
                                  label="Description"
                                  value="description"
                                />
                                <ExcelColumn
                                  label="Created Date"
                                  value="created"
                                />
                                <ExcelColumn
                                  label="Due Date"
                                  value="due_date"
                                />
                                <ExcelColumn
                                  label="Rejected On"
                                  value="completed_on"
                                />
                              </ExcelSheet>
                            </ExcelFile> */}
                          </View>
                        </View>
                        <RejectedTable
                          header={headerRejectedData}
                          gridConfig={[1, 1, 1, 1, 1, 1]}
                          data={valuesRejected}
                          navigation={this.props.navigation}
                          pagination={valuesRejected.length > 10 ? true : false}
                        />
                      </View>
                    </View>
                  </ScrollView>
                </ScrollView>
              ) : (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{ fontSize: 14, color: "#121212", marginTop: 100 }}
                  >
                    No Rejected Action Items
                  </Text>
                </View>
              )
            ) : null}

            {selectedActionTab == "Completed" ? (
              valuesCompleted.length > 0 ? (
                <ScrollView
                  showsVerticalScrollIndicator={true}
                  style={{
                    paddingBottom: 100,
                    marginBottom: 30,
                    marginRight: 30,
                    paddingLeft: 20,
                  }}
                >
                  <ScrollView
                    showsVerticalScrollIndicator={true}
                    horizontal={true}
                  >
                    <View
                      style={{
                        flex: 1,
                      }}
                    >
                      <View style={styles.detailsContainer}>
                        <View style={styles.headingContainer}>
                          <SearchBar
                            value={searchCompletedText}
                            onChange={this.handleCompletedActionSearch}
                          />
                          <View
                            style={{
                              cursor: "pointer",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                              marginRight: 20,
                            }}
                          >
                            {/* <ExcelFile
                              filename={
                                "Completed " +
                                moment().format("DD-MM-YYYY hh-mm-ss A")
                              }
                              element={
                                <Text
                                  style={{
                                    color: "#410DAA",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    fontSize: 15,
                                    textDecorationLine: "underline",
                                  }}
                                >
                                  Download As Excel
                                </Text>
                              }
                            >
                              <ExcelSheet
                                data={excelCompletedData}
                                name="Completed"
                              >
                                <ExcelColumn
                                  label="Item ID"
                                  value="customeractionitem_id"
                                />
                                <ExcelColumn
                                  label="Type"
                                  value="code_description"
                                />
                                <ExcelColumn
                                  label="Description"
                                  value="description"
                                />
                                <ExcelColumn
                                  label="Created Date"
                                  value="created"
                                />
                                <ExcelColumn
                                  label="Due Date"
                                  value="due_date"
                                />
                                <ExcelColumn
                                  label="Completed On"
                                  value="completed_on"
                                />
                              </ExcelSheet>
                            </ExcelFile> */}
                          </View>
                        </View>
                        <CompletedTable
                          header={headerCompletedData}
                          gridConfig={[1, 1, 1, 1, 1, 1]}
                          data={valuesCompleted}
                          navigation={this.props.navigation}
                          pagination={
                            valuesCompleted.length > 10 ? true : false
                          }
                        />
                      </View>
                    </View>
                  </ScrollView>
                </ScrollView>
              ) : (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{ fontSize: 14, color: "#121212", marginTop: 100 }}
                  >
                    No Completed Action Items
                  </Text>
                </View>
              )
            ) : null}
          </>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  errorText: {
    fontSize: 16,
    color: "#121212",
  },
  header: {
    backgroundColor: "#F5F8FF",
    borderRadius: 10,
    paddingVertical: 20,
    flexDirection: "row",
    marginTop: 16,
    width: "88%",
    marginLeft: 30,
  },
  header_title: {
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 20,
    color: "#000000",
    textAlign: "center",
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginTop: 30,
    marginLeft: 16,
    marginBottom: 66,
    width: isMobile ? 1200 : width * 0.79,
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 16 : 19,
    paddingVertical: 19,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  item_ph: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 20,
    color: "#555555",
    textAlign: "center",
  },
  item_ph_old: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 20,
    color: "#555555",
  },
  // modalTableHeader: {
  //   color: "#464E5F",
  //   fontSize: 12,
  //   fontFamily: "Roboto",
  //   fontWeight: "700",
  // },
  // modalRowItem: {
  //   textAlign: "left",
  // },
  // modalRowItemContainerStyle: {
  //   justifyContent: "flex-start",
  //   paddingLeft: 10,
  // },
  // modalHeaderItemContainerStyle: {
  //   justifyContent: "flex-start",
  // },
});
