import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Keyboard,
  Platform,
  Linking,
} from "react-native";
import {
  checkCustomerSignup,
  createCrmdealsCipCbiForAccentureClients,
  createCrmdealsCipForRegular,
  generateCamsAggregatorLink,
  getLeadData,
  getLoginBannerDetails,
  mobileOtpVerificationBeforeSignup,
  onboardingSendOTP,
  SignUpUser,
} from "../../../api";
import { FontAwesome5 } from '@expo/vector-icons';
import { md } from "../../../Responsive";
import { completeMinDetails, getUserDetails, isMobile, isWeb } from "../../../utils";
import Header from "../header_after_login";
const { width } = Dimensions.get("window");
import { saveUserProfileData, logIn } from "../../../actions";
import { connect } from "react-redux";
import { StackActions } from "@react-navigation/routers";
import amplitude from "../../../utils/amplitude";
import { ShowToast } from "../../../components/Common/Toast";
import Toast from "react-native-toast-message";
import { Link } from "@react-navigation/native";
import { ImportFunction } from "../../../components/InvestmentOverview/NewDesign/ImportFunction";
import MiniOnBoardingProgressBar from "./MiniOnBoardingProgressBar";
import { saveCustomerProfileData } from "../../../api/account";
import Store from "../../../store";

class SignUpFinancialPlan extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let corp_code = "";
    let subscription_plan_code = "";
    let lead_id = "";
    let from = null;
    let goalType = "COMPLETE";
    let { is_goal } = Store.getState().userData;
    if (params) {
      corp_code = params.ccd || "";
      subscription_plan_code = params.pcd || "";
      lead_id = params.ldid || "";
      from = params.from || "";
      goalType = params.goalType || "COMPLETE";
    }
    let isCorpCode = false;
    if (corp_code != "") {
      isCorpCode = true;
    }
    this.state = {
      data: null,
      loadingSkip: false,
      goalType,
      isLoading: false,
      name: "",
      is_goal,
      email: "",
      mobile: "",
      password: "",
      repassword: "",
      step: 1,
      is_ria: false,
      showOTP: false,
      showResendOTP: false,
      mobileError: "",
      corp_code,
      isCorpCode,
      subscription_plan_code,
      show_corp_code_box: false,
      lead_id,
      is_correct_lead: false,
      errorText: "",
      nameError: "",
      emailError: "",
      mobileError: "",
      passwordError: "",
      repasswordError: "",
      captchaText: "",
      captchaError: "",
      is_club: false,
      from,
      bannerData: {
        logo_image_url: null,
        name: null,
        ria_code: null,
        subscriber_type: null,
        support_email: null,
        support_person_name: null,
        support_phone_number: null,
        is_jamawealth: null,
      },
    };
  }

  loadBannerData = async () => {
    let { bannerData, lead_id } = this.state;
    if (lead_id != "") {
      const lead_payload = {
        "leadpk": lead_id
      }
      let resp = await getLeadData(lead_payload);
      if (resp && resp.code && resp.code == "100") {
        this.setState({
          name: resp.result.name,
          email: resp.result.email,
          mobile: resp.result.phone_number,
          is_correct_lead: true,
        });
      }
    }
    let response = null;
    let hostname = global.location.hostname;
    let is_club = false;
    let is_preferred_circle = false;
    if (hostname.includes("club")) {
      is_club = true;
    }
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    try {
      response = await getLoginBannerDetails();
    } catch (err) { }
    if (response && response.code == 100) {
      bannerData = response.result;
      this.setState({
        bannerData: bannerData,
        is_club: is_club,
        is_preferred_circle: is_preferred_circle,
      });
    } else {
      bannerData.logo_image_url =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/website/jam%C4%81_header.png";
      bannerData.name = "Jama Wealth";
      bannerData.ria_code = "INA 200015583";
      bannerData.subscriber_type = "RIAC";
      bannerData.support_email = "advisor@jamawealth.com";
      bannerData.support_person_name = "Ram Kalyan Medury";
      bannerData.support_phone_number = "9870264643";
      bannerData.is_jamawealth = true;
      this.setState({
        bannerData: bannerData,
        errorText: response.msg,
        is_club: is_club,
        is_preferred_circle: is_preferred_circle,
      });
    }
  };

  handleSendOTP = async () => {
    const { name, email, mobile } = this.state;
    let regEx =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    try {
      this.setState({ isLoading: true });
      if (!name) {
        this.setState({
          nameError: "Please enter a name",
          isLoading: false,
          emailError: "",
          mobileError: "",
          passwordError: "",
          repasswordError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          nameError: "",
        });
      }
      if (!email || !regEx.test(email)) {
        this.setState({
          emailError: "Please enter a valid email",
          isLoading: false,
          nameError: "",
          mobileError: "",
          passwordError: "",
          repasswordError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          emailError: "",
        });
      }
      if (!mobile) {
        this.setState({
          mobileError: "Please enter a valid mobile number",
          isLoading: false,
          nameError: "",
          emailError: "",
          passwordError: "",
          repasswordError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          mobileError: "",
        });
      }
      const payload = {
        email: email.toString(),
        mobilenumber: mobile.toString(),
      };
      const checkSignUpResponse = await checkCustomerSignup(payload);
      if (checkSignUpResponse && checkSignUpResponse.code == 100) {
        let getOtpPayload = {
          target_mobile_number: mobile.toString(),
        };
        const mobileOtpResp = await mobileOtpVerificationBeforeSignup(
          getOtpPayload
        );
        if (
          mobileOtpResp &&
          mobileOtpResp.resp_code &&
          mobileOtpResp.resp_code == "100"
        ) {
          this.setState({ isLoading: false, showOTP: true });
        } else {
          this.setState({
            error: true,
            errorText: "Please enter a valid 10 digit mobile number",
            isLoading: false,
          });
        }
      } else {
        this.setState({
          error: true,
          errorText: checkSignUpResponse.results.user_check,
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        errorText:
          response && response.message
            ? response.message
            : "Something went wrong!",
        error: true,
        isLoading: false,
      });
    }
  };

  handleResend = async () => {
    this.setState({ showResendOTP: false });
    const { mobile } = this.state;
    const data = {
      username: mobile,
    };
    const response = await onboardingSendOTP(data);
    if (response && response.otp_generated_date) {
      this.setState({ showResendOTP: true });
    }
  };

  // handleVerifyOTP = async () => {
  //   const { mobile, otpNumber } = this.state;
  //   this.setState({ isLoading: true });
  //   try {
  //     if (!otpNumber) {
  //       this.setState({
  //         otpError: "Please enter a valid 6 digit otp",
  //         isLoading: false,
  //         errorText: "",
  //         passwordError: "",
  //         repasswordError: "",
  //       });
  //       return;
  //     } else {
  //       this.setState({
  //         otpError: "",
  //       });
  //     }

  //     let getOtpPayload = {
  //       target_mobile_number: mobile.toString(),
  //       otp_value: otpNumber.toString(),
  //     };

  //     const mobileOtpResp = await mobileOtpVerificationBeforeSignup(
  //       getOtpPayload
  //     );
  //     if (mobileOtpResp && mobileOtpResp.result) {
  //       this.handleContinue();
  //     } else {
  //       this.setState({
  //         error: true,
  //         errorText:
  //           mobileOtpResp && mobileOtpResp.message
  //             ? mobileOtpResp.message
  //             : "Please enter the correct otp",
  //         isLoading: false,
  //       });
  //     }
  //   } catch (error) {
  //     this.setState({
  //       error: true,
  //       errorText: "Something went wrong!",
  //       isLoading: false,
  //     });
  //   }
  // };

  // handleContinue = async () => {
  //   const {
  //     name,
  //     email,
  //     mobile,
  //     corp_code,
  //     subscription_plan_code,
  //     lead_id,
  //     is_club,
  //     is_preferred_circle,
  //     password,
  //     is_ria,
  //     otpNumber,
  //     bannerData,
  //   } = this.state;
  //   let regEx =
  //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //   try {
  //     this.setState({ isLoading: true });
  //     if (!name) {
  //       this.setState({
  //         nameError: "Please enter a name",
  //         isLoading: false,
  //         emailError: "",
  //         mobileError: "",
  //         passwordError: "",
  //         repasswordError: "",
  //         errorText: "",
  //       });
  //       return;
  //     } else {
  //       this.setState({
  //         nameError: "",
  //       });
  //     }
  //     if (!email || !regEx.test(email)) {
  //       this.setState({
  //         emailError: "Please enter a valid email",
  //         isLoading: false,
  //         nameError: "",
  //         mobileError: "",
  //         passwordError: "",
  //         repasswordError: "",
  //         errorText: "",
  //       });
  //       return;
  //     } else {
  //       this.setState({
  //         emailError: "",
  //       });
  //     }
  //     if (!mobile) {
  //       this.setState({
  //         mobileError: "Please enter a valid mobile number",
  //         isLoading: false,
  //         nameError: "",
  //         emailError: "",
  //         passwordError: "",
  //         repasswordError: "",
  //         errorText: "",
  //       });
  //       return;
  //     } else {
  //       this.setState({
  //         mobileError: "",
  //       });
  //     }
  //     let signupPayload = {
  //       first_name: name.toString(),
  //       username: mobile.toString(),
  //       email: email.toString(),
  //       mobilenumber: mobile.toString(),
  //       showPassword: false,
  //       showConfirmPassword: false,
  //       corp_code: corp_code.toString(),
  //       subscription_plan_code: subscription_plan_code.toString(),
  //       lead_id: lead_id.toString(),
  //       password: password.toString(),
  //       otp: otpNumber.toString(),
  //       campaign: global.location.hostname.replace(/\./g, "_"),
  //     };

  //     const response = await SignUpUser(signupPayload);
  //     amplitude.setUserId(mobile);
  //     const eventProperties = {
  //       username: response.first_name,
  //       mobile: mobile,
  //       email: response.email,
  //     };
  //     amplitude.logEvent("Signup_Successful", eventProperties);
  //     if (response && response.pk != null) {
  //       let data = { ...response };
  //       data = Object.assign(data, {
  //         is_ria: is_ria,
  //         bannerData: bannerData,
  //         is_club: is_club,
  //         is_preferred_circle: is_preferred_circle,
  //       });
  //       Toast.hide();
  //       const loggedInTime = new Date().getTime();
  //       this.props.logIn(loggedInTime);
  //       this.props.saveUserProfileData(data);
  //       if (is_preferred_circle) {
  //         let url = new URL(global.location.href);
  //         location.replace(url.origin + "/onboarding/handSignature");
  //       }
  //     }
  //   } catch (error) {
  //     this.setState({
  //       error: true,
  //       errorText: "Something went wrong!",
  //       isLoading: false,
  //     });
  //   }
  // };

  componentDidMount() {
    let is_ria = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (hostname.includes("simplygrow.in")) {
      is_ria = true;
    }
    this.setState({
      is_ria: is_ria,
    });
    this.generateCaptcha();
    this.loadBannerData();
  }

  generateCaptcha = () => {
    var numberOne = Math.floor(Math.random() * 1000000) + 1;
    this.setState({ randomCaptcha: numberOne });
  };

  render() {
    const {
      is_goal
    } = this.state;
    return (
      <View style={styles.container}>
        <Header
          navigation={this.props.navigation}
          route={this.props.route}
          showbackBtn={!is_goal}
        />
        {(this.state.is_preferred_circle || this.state.is_club) &&
          this.state.from ? (
          <ShowToast
            showToast={true}
            type="success"
            title={`Congratulations! You have successfully subscribed to our ${this.state.is_preferred_circle
              ? "Advisory Circle"
              : "Investor Club"
              } plan.`}
          />
        ) : null}
        {this.state.showOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent successfully."
          />
        ) : null}
        {this.state.showResendOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent successfully."
          />
        ) : null}
        {!is_goal ? (<MiniOnBoardingProgressBar
          color2="#410DAA"
          color3="#410daa"
          color4="#410DAA"
          color5="#f6f6f6"
          color6="#f6f6f6"
          color11="#f6f6f6"
          color12="#f6f6f6"
          bcolor1="#410DAA"
          bcolor2="#410DAA"
          bcolor3="#410DAA"
          bagcolor1="#410DAA"
          bagcolor2="#410DAA"
          bagcolor3="#410DAA"
          markdisplay1="none"
          markdisplay2="none"
          markdisplay3="none"
          dotdisplay1="none"
          dotdisplay2="none"
          dotdisplay3="none" />) :
          (
            <MiniOnBoardingProgressBar
              color2="#410DAA"
              color3="#f6f6f6"
              color4="#f6f6f6"
              color5="#f6f6f6"
              color6="#f6f6f6"
              color11="#f6f6f6"
              color12="#f6f6f6"
              bcolor1="#410DAA"
              bcolor2="#410DAA"
              bcolor3="#410DAA"
              bagcolor1="#410DAA"
              bagcolor2="#410DAA"
              bagcolor3="#410DAA"
              markdisplay1="none"
              markdisplay2="none"
              markdisplay3="none"
              dotdisplay1="none"
              dotdisplay2="none"
              dotdisplay3="none" />
          )}
        <ScrollView keyboardShouldPersistTaps="always">
          <View style={styles.body}>
            {isWeb && (
              <View style={styles.left}>
                <View style={{ paddingBottom: 50 }}>
                  <View style={{ opacity: 0 }}>
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-2.png"
                      }
                      style={styles.left_logo}
                    ></Image>
                  </View>
                  <View style={{ position: 'absolute', zIndex: 10 }}>
                    <View style={{ marginBottom: 25 }}>
                      <Text style={{ color: '#410DAA', fontSize: 32, fontWeight: '700' }}>Financial Plan Benefits</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 2 }}>
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>1. Know if goals are on track.</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 2 }}>
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '500', fontSize: 16 }}>{"(retirement, child's education, child's marriage etc)"}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>2. Al generated advice to improve goal success.</Text>
                    </View>
                  </View>
                </View>
                <Text style={{ textAlign: 'center', color: '#410DAA', fontWeight: '500', marginTop: 5, marginHorizontal: '10%' }}>
                  Note: The financial plan report is not investment advice. After your onboarding and riskprofiling and personalised advisory report shall be provided.
                </Text>
              </View>
            )}
            <View style={styles.right}>
              <View style={[isMobile ? styles.mob_styl : styles.web_styl]}>
                <View style={{ width: isMobile ? "100%" : '80%', marginTop: 20 ,marginBottom: 50}}>
                  { this.state.loadingSkip ? 
                  <ActivityIndicator size={"small"} color={"#410DAA"}/> : <Text style={{ color: '#410DAA', fontWeight: '500', textDecorationLine: 'underline', textAlign: 'right' }} onPress={async () => {
                    this.setState({loadingSkip: true})
                    try{
                    const { customer_id, customerprofile_id } = getUserDetails();
                    const body = {
                      customer: customer_id,
                      skip_onboarding_finplan: true
                    };
                    const { is_goal } = Store.getState().userData;
                    await saveCustomerProfileData(customerprofile_id, body);
                    const minDetailsResponse = await completeMinDetails(null,null,null,false);
                    console.log("Responce is ", minDetailsResponse);
                    if (is_goal) {
                      this.props.navigation.navigate("Onboarding", {
                        screen: "SignUpImportPortfolio",
                      });
                    } else {
                      // console.log("i'm clicked");
                      if (minDetailsResponse.needCompletion) {
                        this.props.navigation.navigate("Onboarding", {
                          screen: minDetailsResponse.redirect_screen
                        });
                      } else {
                        let hostname = global.location.hostname
                        if(hostname.includes("club")) {
                          await createCrmdealsCipForRegular();
                        }
                        if(hostname.includes("app") || hostname.includes("myadvisor")) {
                          await createCrmdealsCipCbiForAccentureClients();
                       }
                        this.props.navigation.navigate("App", {
                          screen: "Dashboard",
                          params: {
                            show_card_view: true
                          }
                        });
                      }
                    }
                  }catch(err){
                    this.setState({loadingSkip: false});
                    console.log("error us here", err)
                  }
                  }}>Skip</Text> }
                </View>
                <View style={styles.inputsContainer}>
                  { (this.state.goalType != "RETIREMENT" && this.state.goalType != "CHILDREN") && (<View style={styles.inputContainer}>
                    <TouchableOpacity
                      style={{
                        borderWidth: 1,
                        borderRadius: 6,
                        borderColor: "#00000029",
                        padding: 10,
                        marginHorizontal: isMobile ? 0 : 16,
                        width: "100%",
                        maxWidth: "100%",
                      }}
                      onPress={async () => {
                        this.props.navigation.navigate("FinPlansGoalsCollections")
                      }}
                    >
                      <Image
                        source={{
                          uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/finplan_landing_image.png",
                        }}
                        style={{ width: 125, height: 125, alignSelf: "center" }}
                        resizeMode="contain"
                      />
                      <Text
                        style={{
                          fontSize: 17,
                          fontWeight: "bold",
                          paddingVertical: 10,
                          textAlign: "center",
                        }}
                      >
                        Start your FREE financial plan
                      </Text>
                    </TouchableOpacity>
                  </View>)}
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginLeft: 18
                  }}>
                    {this.state.goalType == "RETIREMENT" && (<TouchableOpacity onPress={async () => {
                      this.props.navigation.navigate("FinPlansGoalsCollections", {
                        goalType: "RETIREMENT"
                      })
                    }} style={{ width: '100%', borderWidth: 1, padding: 20, borderColor: "#00000029", justifyContent: 'center', alignItems: 'center', marginRight: 20 }}>
                      <View>
                        <Image style={{
                          width: 100,
                          height: 100
                        }} source={{ uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/retirement_finplan_landing_image.png" }} />
                      </View>
                      <Text style={{
                        fontSize: 18,
                        fontWeight: '700',
                        marginTop: 20
                      }}>Retirement Goal</Text>
                    </TouchableOpacity>)}

                    { this.state.goalType == "CHILDREN" && (<TouchableOpacity onPress={async () => {
                      this.props.navigation.navigate("FinPlansGoalsCollections", {
                        goalType: "CHILDREN"
                      })
                    }} style={{ width: '100%', borderWidth: 1, padding: 20, borderColor: "#00000029", justifyContent: 'center', alignItems: 'center' }}>
                      <View>
                        <Image style={{
                          width: 100,
                          height: 100
                        }} source={{ uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/child_future_finplan_landing.png" }} />
                      </View>
                      <Text style={{
                        fontSize: 18,
                        fontWeight: '700',
                        marginTop: 20
                      }}>Child Goal</Text>
                    </TouchableOpacity>)}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexGrow: 1,
    height: "100%",
    backgroundColor: "#fff",
  },
  body: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
    justifyContent: 'center',
    alignItems: 'center'
  },
  left: {
    flex: md(width) ? 1 : 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    paddingVertical: md(width) ? 75 : 1,
  },
  right: {
    flex: 1,
  },
  mob_styl: {
    width: "90%",
    justifyContent: 'center',
    alignSelf: "center",
  },
  web_styl: {
    alignItems: "center",
  },
  captchaContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: isMobile ? -10 : -20,
    marginBottom: 10,
  },
  best: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 33,
    lineHeight: 38,
    color: "#464E5F",
    paddingBottom: 100,
  },
  left_logo: {
    resizeMode: "contain",
    width: 403,
    height: 241,
    alignSelf: "center",
    marginBottom: 60,
  },
  mobile_verf: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 19 : 15,
    lineHeight: md(width) ? 22 : 17,
    textAlign: "center",
    color: "#121212",
    paddingTop: md(width) ? 0 : 50,
  },
  we_will: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 17 : 13,
    lineHeight: md(width) ? 20 : 18,
    textAlign: "justify",
    color: "#A7A8BB",
    paddingTop: md(width) ? 19 : 29,
  },
  mob_num: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 18,
    color: "#222225",
    paddingBottom: md(width) ? 23 : 10.5,
  },
  please: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3,
  },
  req_otp: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 47,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  one: {
    color: "#121212",
  },
  dmart_body_md: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "bold",
    color: "#121212",
    backgroundColor: "#f6f6f6",
    height: 41.02,
    paddingLeft: 20,
    borderRadius: 4,
  },
  sebi: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 14,
    color: "#000",
    paddingRight: 29,
  },
  sebi_1: {
    fontFamily: "Arial",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: 9,
    lineHeight: 14,
    color: "#51514E",
    paddingRight: 29,
  },
  ina: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 10,
    lineHeight: 14,
    color: "#410DAA",
  },
  img1: {
    width: 30,
    height: 28.6,
  },
  welcomeTitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    color: "#202020",
    fontWeight: "bold",
    marginBottom: 45,
    marginTop: 10,
    textAlign: "center",
  },
  inputsContainer: {
    minWidth: "75%",
  },
  inputContainer: {
    marginBottom: 20,
  },
  forgotPassword: {
    color: "#232425",
    fontSize: 15,
    fontFamily: "Roboto",
    marginTop: 18,
    textAlign: "center",
  },
  clickHere: {
    color: "#410DAA",
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 6,
    textAlign: "center",
  },
  certication: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 25,
  },
  sebiContainer: {
    flexDirection: "row",
    paddingTop: 25,
  },
  certicationsContainer: {
    flexDirection: "row",
    paddingLeft: 30.5,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  popableContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
});


export default SignUpFinancialPlan;
