import React, { Component } from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Dimensions } from 'react-native';
import {sm, md, lg, xl, twoxl} from '../../Responsive';
const {width} = Dimensions.get("window");

export default class RadioButton extends Component {

	render() {
		const { PROP, value, onPress, style ={} } = this.props;

		return (
			<View>
				{PROP.map(res => {
					const active = value === res.key;
					return (
						<View key={res.key} style={[styles.container, active &&styles.active, {...style}]}>
                            <TouchableOpacity
								style={[styles.radioCircle, active&&styles.activeText]}
								onPress={() => {
									onPress&&onPress(res.key)
								}}>
                                  {active && <View style={styles.selectedRb} />}
							</TouchableOpacity>
							<Text
								style={[styles.radioText,active&&styles.activeText]}
								onPress={() => {
									onPress&&onPress(res.key)
								}}
							>
								{res.text}
								<Text style={styles.text1}>{res.text1}</Text>
								{res.text3}
							</Text>
						</View>
					);
				})}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
        paddingBottom: 20,
        alignItems: 'center',
        flexDirection: 'row',
		borderTopWidth:1,
        borderTopColor:"#E5EAEE",
		paddingTop:25,
	},
    radioText: {
       fontFamily:"Roboto",
       fontSize:md(width)?14:13,
       lineHeight:md(width)?16:15,
       color: '#464E5F',
       marginLeft: 19,
	},
	active:{
		backgroundColor: "#410DAA10"
	},
	activeText:{
		borderColor: "#410DAA",
		color: "#410DAA"
	},
    text1:{
        color:'#3699FF',
        textDecorationLine:'underline',
    },
	radioCircle: {
		height: 13,
		width: 13,
		borderRadius: 6.5,
		borderWidth: 1,
		borderColor: '#464E5F',
        backgroundColor:'#ffffff',
		alignItems: 'center',
		justifyContent: 'center',
        marginLeft:20
	},
	selectedRb: {
		width: md(width)?9:8,
		height: md(width)?9:8,
		borderRadius: 4.5,
		backgroundColor: '#410DAA',
        alignItems:'center',
        justifyContent:'center'
    },
    result: {
        marginTop: 20,
        color: 'white',
        fontWeight: '600',
        backgroundColor: '#F3FBFE',
    },
});