import React, { PureComponent } from "react";
import {
  View,
  ScrollView,
  SafeAreaView,
  StyleSheet,
  Platform,
  FlatList,
  Image,
} from "react-native";
import { connect } from "react-redux";
import { ActivityIndicator, Button, Text } from "react-native-paper";
import amplitude from "../../../utils/amplitude";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import {
  getSipMandate,
  getCustomerBankAccounts,
  createMandate,
} from "../../../api";
import PickerField from "../../../components/Common/Pickers/PickerField";
import theme from "../../../static/theme";
import { isMobile, months_short_names, numberWithCommas } from "../../../utils";
import TextInputField from "../../../components/Common/Inputs/InputField";
import moment from "moment";
import HorizontalRadio from "../../../components/Common/Radio/HorizontalRadio";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import { ShowToast } from "../../../components/Common/Toast";

class eMandate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      mandates: [],
      ifsc: "",
      accountNumber: "",
      confirmAccountNumber: "",
      addAccountClicked: false,
      bankList: [],
      selectedBank: {},
      txnAmount: "",
      mandateType: "N",
      errorText: "",
      showToast: false,
    };
  }

  static navigationOptions = {
    tabBarVisible: false,
  };

  validate = () => {
    const { ifsc, accountNumber, bankName } = this.state;
    if (ifsc.length != 11) return false;
    if (accountNumber.length < 9) return false;
    if (!bankName) return false;
    return true;
  };

  addMandate = async () => {
    const { txnAmount, selectedBank, mandateType } = this.state;

    if (!selectedBank || selectedBank == "SEL") {
      return;
    }

    if (txnAmount == "") {
      this.setState({ errorText: "Please, enter a valid amount" });return;
    }

    if (mandateType == "") {
      return;
    }

    let payload = {
      amount: txnAmount,
      bank_account_no: selectedBank.account_number,
      micr_code: selectedBank.micr_code ? selectedBank.micr_code : "",
      mandate_type: mandateType,
    };
    this.setState({ submitting: true, loading: true, fetching: true });

    const createMandateResp = await createMandate(payload);

    if (
      createMandateResp &&
      createMandateResp.code &&
      createMandateResp.code == "100"
    ) {
      this.setState({ showToast: "true" });
      const response = await getSipMandate({
        amount: "1",
        day_of_month: moment().format("DD"),
        is_aadhar_linked_to_mobile: "not_required",
      });
      this.setState({ mandates: response.mandates });

      this.setState({
        submitting: false,
        addAccountClicked: false,
        loading: false,
        fetching: false,
      });
    } else {
      this.setState({
        submitting: false,
        loading: false,
        fetching: false,
        errorText: createMandateResp.msg
          ? createMandateResp.msg
          : "Something went wrong!",
      });
    }
  };

  componentDidMount = async () => {
    if (Platform.OS == "web"){
      amplitude.logEvent("eMandate",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("eMandate", { path: this.props.navigation.getState().routeName });
    }
    await this.getUserMandates();
  };

  getUserMandates = async () => {
    this.setState({ fetching: true });

    const response = await getSipMandate({
      amount: "1",
      day_of_month: moment().format("DD"),
      is_aadhar_linked_to_mobile: "not_required",
    });

    const customerBankAccounts = await getCustomerBankAccounts();
    let bankList = [];
    if (customerBankAccounts && customerBankAccounts.results) {
      customerBankAccounts.results.forEach((account) => {
        if (account.bank_name && account.account_number) {
          bankList.push({
            label: account.bank_name + " - " + account.account_number,
            value: account,
          });
        }
      });

      this.setState({
        selectedBank: bankList[0] && bankList[0].value ? bankList[0].value : "",
        bankList,
      });
    }
    if (response && response.code && response.code === "100") {
      this.setState({ fetching: false, mandates: response.mandates });
    } else {
      this.setState({ fetching: false });
    }
  };

  renderBankRow = ({ item }) => {
    let from = null;
    let to = null;
    if (item.from_date != null) {
      from = item.from_date.split("-");
      const from_month = months_short_names(parseInt(from[1]));
      from = from[0] + " " + from_month + " " + from[2];
    } else {
      from = " ";
    }

    if (item.to_date != null) {
      to = item.to_date.split("-");
      const to_month = months_short_names(parseInt(to[1]));
      to = to[0] + " " + to_month + " " + to[2];
    } else {
      to = " ";
    }

    return (
      <View style={styles.account_box}>
        <View
          style={{
            paddingHorizontal: 30,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={styles.img_box}>
              <Image
                resizeMode="contain"
                source={{ uri: item.bank_logo }}
                style={styles.img}
              />
            </View>
            <View style={{ paddingLeft: 15 }}>
              <View style={{ paddingBottom: 10 }}>
                <Text style={styles.bank_name}>{item.bank_name}</Text>
              </View>
              <View style={styles.bank_details}>
                <Text style={[styles.text_style, { paddingRight: 16 }]}>
                  Account No:{" "}
                  <Text style={{ fontStyle: "italic" }}>
                    {item.bank_account_no}
                  </Text>
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View style={{ paddingBottom: 10, paddingRight: 50 }}>
              <Text style={styles.text_style}>Amount</Text>
            </View>
            <View style={styles.bank_name}>
              <Text
                style={[
                  styles.bank_name,
                  {
                    fontWeight: "600",
                    color: "#410DAA",
                    fontStyle: "italic",
                    marginTop: -4,
                  },
                ]}
              >
                ₹ {numberWithCommas(item.amount)}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: "rgba(65, 13, 170, 0.6)",
            marginTop: 15,
            marginBottom: 15,
            width: "100%",
            height: 0.5,
          }}
        ></View>

        <View
          style={{
            paddingHorizontal: 30,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                borderWidth: 1,
                borderColor: "white",
                borderRadius: 5,
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Image resizeMode="contain" source="" style={styles.img} />
            </View>
            <View style={{ paddingLeft: 15 }}>
              <View style={{ paddingBottom: 10 }}>
                <Text style={styles.text_style}>Mandate ID</Text>
              </View>
              <View style={styles.bank_details}>
                <Text style={[styles.bank_name, { paddingRight: 16 }]}>
                  {item.mandate_id}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View style={{ paddingBottom: 10 }}>
              <Text style={styles.text_style}>Frequency</Text>
            </View>
            <View style={styles.bank_name}>
              <Text
                style={[styles.bank_name, { fontWeight: "600", marginTop: -4 }]}
              >
                {item.frequency == "As & When presented"
                  ? "As Presented"
                  : item.frequency}
              </Text>
            </View>
          </View>
          <View>
            <View style={{ paddingBottom: 10 }}>
              <Text style={styles.text_style}> Status</Text>
            </View>
            <View style={styles.bank_name}>
              <Text
                style={[styles.bank_name, { fontWeight: "600", marginTop: -4 }]}
              >
                {" "}
                {item.status == "APR" ? "Approved" : "Pending"}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  render() {
    const { container } = styles;
    const { mandates = [] } = this.state;

    return !this.state.addAccountClicked ? (
      <SafeAreaView style={container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Investment Mandate - e mandate"]}
        />
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{ flex: 1 }}
          showsVerticalScrollIndicator={true}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <Text
              style={{
                marginLeft: 15,
                marginRight: 20,
                fontWeight: "400",
                fontSize: 14,
                color: "#464E5F",
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              Required for mutual fund SIP investments. Can also be used for
              lumpsums.
            </Text>
            {!isMobile && (
              <Button
                uppercase={false}
                mode={"contained"}
                onPress={() => {
                  this.setState({ addAccountClicked: true });
                }}
                style={{
                  alignItems: "center",
                  width: "18%",
                  marginRight: 15,
                }}
                color={"#410DAA"}
              >
                <Text style={styles.next}>Add Mandate</Text>
              </Button>
            )}
          </View>
          {isMobile && (
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={() => {
                this.setState({ addAccountClicked: true });
              }}
              style={{
                alignItems: "center",
                width: "40%",
                marginTop: 10,
                marginLeft: 15,
                height: 40,
              }}
              color={"#410DAA"}
            >
              <Text style={styles.next}>Add Mandate</Text>
            </Button>
          )}
          <FlatList
            data={[...mandates]}
            ListEmptyComponent={() => {
              if (this.state.fetching) return null;
              return (
                <View
                  style={{
                    flex: 1,
                    marginTop: 200,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text>No Mandates</Text>
                </View>
              );
            }}
            style={{ paddingHorizontal: 16, marginTop: 20, paddingBottom: 30 }}
            renderItem={this.renderBankRow}
            keyExtractor={(item) => item.id}
          />
        </ScrollView>
        {this.state.fetching && (
          <ActivityIndicator
            size="large"
            color="#410DAA"
            style={styles.loader}
          />
        )}
      </SafeAreaView>
    ) : (
      <SafeAreaView style={container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Add E Mandate"]}
          onPressBack={() => {
            this.setState({ addAccountClicked: false });
          }}
        />
        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title="Mandate has been created. Please check your registered email and click link to Authenticate (approve)."
            duration="5"
          />
        ) : null}
        {this.state.fetching ? (
          <ActivityIndicator
            size="large"
            color="#410DAA"
            style={styles.loader}
          />
        ) : (
          <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={true}>
            <View style={{ flex: 1, marginHorizontal: 16 }}>
              {!isMobile ? (
                <View
                  style={[
                    styles.formItem,
                    styles.itemMargin,
                    { marginTop: 30 },
                  ]}
                >
                  <PickerFieldSelect
                    data={this.state.bankList}
                    value={this.state.selectedBank}
                    label="Bank Account*"
                    onChange={(bank) => this.setState({ selectedBank: bank })}
                    pickerStyle={[{ ...styles.inputItem, width: "93.35%" }]}
                    type="select"
                    placeholder={{ value: "SEL", label: "Select" }}
                  />
                </View>
              ) : (
                <View style={[styles.formItem, styles.itemMargin]}>
                  <PickerFieldSelect
                    data={this.state.bankList}
                    value={this.state.selectedBank}
                    label="Bank Account*"
                    onChange={(bank) => this.setState({ selectedBank: bank })}
                    pickerStyle={[{ ...styles.inputItem }]}
                    type="select"
                    placeholder={{ value: "SEL", label: "Select Bank Account" }}
                  />
                </View>
              )}
              {!isMobile ? (
                <View
                  style={[
                    styles.formItem,
                    styles.itemMargin,
                    { marginTop: 30 },
                  ]}
                >
                  <TextInputField
                    label="Set Transaction amount*"
                    value={this.state.txnAmount}
                    onChange={(txnAmount) => {
                      if (!isNaN(txnAmount)) {
                        this.setState({ txnAmount });
                      }
                    }}
                    style={[{ ...styles.inputItem, width: "93.35%" }]}
                    editable={true}
                    type="text"
                    inputStyle={[{ paddingLeft: 24 }]}
                  />
                </View>
              ) : (
                <View style={[styles.formItem, styles.itemMargin]}>
                  <TextInputField
                    label="Set Transaction amount*"
                    value={this.state.txnAmount}
                    onChange={(txnAmount) => {
                      if (!isNaN(txnAmount)) {
                        this.setState({ txnAmount });
                      }
                    }}
                    style={[{ ...styles.inputItem }]}
                    editable={true}
                    type="text"
                    inputStyle={[{ paddingLeft: 24 }]}
                  />
                </View>
              )}
            </View>
            {!isMobile ? (
              <>
                <Button
                  uppercase={false}
                  mode={"contained"}
                  onPress={() => this.addMandate()}
                  style={{
                    width: "20%",
                    left: "40%",
                    top: 50,
                    right: "40%",
                  }}
                  color={"#410DAA"}
                >
                  <Text
                    style={{
                      color: "#fff",
                      fontSize: 13,
                    }}
                  >
                    Add Mandate
                  </Text>
                </Button>
                {this.state.errorText != "" && (
                  <Text
                    style={{
                      color: "red",
                      textAlign: "center",
                      marginTop: 80,
                    }}
                  >
                    {this.state.errorText}
                  </Text>
                )}
              </>
            ) : (
              <>
                <Button
                  uppercase={false}
                  mode={"contained"}
                  onPress={() => this.addMandate()}
                  style={{
                    width: "40%",
                    marginLeft: "30%",
                    marginTop: 30,
                  }}
                  color={"#410DAA"}
                >
                  <Text
                    style={{
                      color: "#fff",
                      fontSize: 13,
                    }}
                  >
                    Add Mandate
                  </Text>
                </Button>
                {this.state.errorText != "" && (
                  <Text
                    style={{
                      color: "red",
                      textAlign: "center",
                      marginTop: 80,
                    }}
                  >
                    {this.state.errorText}
                  </Text>
                )}
              </>
            )}
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.MAIN_BACKGROUND,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.3,
  },
  fileName: {
    fontSize: 15,
    marginTop: 5,
    color: "#410DAA",
    fontWeight: "bold",
  },
  row: {
    flex: 1,
    flexDirection: "row",
  },
  baseMargin: {
    marginTop: 16,
  },
  titleHeading: {
    color: "#707070",
  },
  support: {
    fontSize: 11,
    color: "#121212",
    fontStyle: "italic",
    marginTop: 4,
    fontWeight: "500",
  },
  next: {
    color: "#fff",
    fontSize: 13,
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  loader: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  account_box: {
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.6)",
    borderRadius: 6,
    paddingVertical: 15,
    marginVertical: 10,
  },
  img_box: {
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.2)",
    borderRadius: 5,
    alignContent: "center",
    justifyContent: "center",
  },
  img: {
    width: 40,
    height: 40,
  },
  bank_name: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 18,
    color: "#22242C",
    fontWeight: "600",
  },
  bank_details: {
    flexDirection: "row",
    marginTop: -3,
  },
  text_style: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 20,
    color: "#494B69",
  },
});

export default connect(null)(eMandate);
