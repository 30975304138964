import React from "react";
import { StyleSheet, View, Image, Dimensions, ActivityIndicator  } from "react-native";
import { Text } from "react-native-paper";
import { sm, md, lg, xl, twoxl } from "../../Responsive";
import global from "./globalStyles";
import theme from "../../static/theme/index.js";

const window = Dimensions.get("window");
const grayLogo = require("../../assets/upgradePlan/Landing/logo_icon.png");
const whiteLogo = require("../../assets/upgradePlan/Landing/Frame.png");

export default function CurrentPlanDetails({ plan, isLoading }) {
    let color = "#EEEEEE";
    if (plan === "silver") {
        color = "#EEEEEE";
    }
    let planText = "";
    if (plan === "silver") {
        planText = "You can do unlimited investments, plan your goals with our advanced financial planner and and track all your investments in one place, the silver plan gives you access to Jamā’s most used features.";
    } else if (plan === "gold") {
        color = "#F4AD21";
        planText = "You can do unlimited investments, plan your goals with our advanced financial planner and and track all your investments in one place, the silver plan gives you access to Jamā’s most used features.";
    } else if (plan === "platinum") {
        color = "#1CA098";
        planText = "You can do unlimited investments, plan your goals with our advanced financial planner and and track all your investments in one place, the silver plan gives you access to Jamā’s most used features.";
    } else if (plan === "jewel") {
        color = "#410DAA";
        planText = "You can do unlimited investments, plan your goals with our advanced financial planner and and track all your investments in one place, the silver plan gives you access to Jamā’s most used features.";
    }

    return (
        <View style={[{ backgroundColor: color }, styles.container]}>
            {isLoading && (
                <ActivityIndicator
                size="small"
                color={theme.colors.NEW_BRAND_COLOR}
                style={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                />
            )}
            {plan && (
                <>
                <Image
                    style={styles.imgLogo}
                    source={plan === "silver" ? grayLogo : whiteLogo}
                />
                <View style={styles.textContainer}>
                    <Text
                    style={[
                        styles.heading,
                        global.arial,
                        { color: plan === "silver" ? "#000" : "#FFF" }
                    ]}
                    >
                    You are subscribed to Jamā <Text>{plan.toUpperCase()}</Text> plan
                    </Text>
                    <Text
                    style={[
                        styles.details,
                        global.arial,
                        { color: plan === "silver" ? undefined : "#FFF" }
                    ]}
                    >
                    {planText}
                    </Text>
                </View>
                </>
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 21,
        borderRadius: 5,
        display: "flex",
        flexDirection: "row",
        height: 133
    },
    heading: {
        // fontWeight: "bold",
        fontSize: 13,
        textAlign: "left", // not working
        color: "black"
    },
    details: {
        fontSize: 13,
        opacity: 50,
        width: "100%",
        color: "black"
    },
    imgLogo: {
        width: md(window.width) ? 19 : 19,
        height: md(window.width) ? 21 : 21,
        alignSelf: "flex-start",
        resizeMode: "contain",
        marginRight: md(window.width) ? 11 : 11
    },
    textContainer: {
        flexShrink: 1
    }
});
  