import React from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { updateFinplanStatus, updateLastSeenScreen } from "../../../actions/finplan";
import theme from "../../../static/theme";
import { YesNo } from "../components/yesno";

class HigherStudies extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let type = null;
    if(params){
      type = params.type || null;
    }
    this.state = {
      type,
      isLoading: false
    };
  }

  planningHigherStudies = value => {
    try {
      const { type } = this.state;
      if (value) {
          this.props.navigation.navigate("PlaceToStudy", {
              type
          });
      } else {
          this.props.updateLastSeenScreen(type, "BuyHome");
          this.props.navigation.navigate("BuyHome", { type });
      }
    } catch (error) {
        
    }
  };

  render() {
    return (
      <>
        <View
          style={[
            styles.page,
            { flex: 1, paddingHorizontal: 16, paddingTop: 60 }
          ]}
        >

        <YesNo
            image={
              "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/studies_finplan.png"
            }
            imageStyle={{ alignSelf: "center" }}
            style={{}}
            onYes={() => this.planningHigherStudies(true)}
            onNo={() => this.planningHigherStudies(false)}
            title={"Are you planning for higher studies?"}
            titleStyle={{
              fontSize: 24
            }}
            selectedColor={theme.colors.NEW_BRAND_COLOR}
            notSelectedColor={"white"}
          />
        </View>
      </>
    );
  }
};

const styles = StyleSheet.create({
    page: {
        flex: 1,
        alignItems: "center",
        backgroundColor: "#fff",
        paddingBottom:66
    }
});

const mapDispatchToProps = dispatch => {
  return {
    updateFinplanStatus: (type, status) =>
      dispatch(updateFinplanStatus(type, status)),
    updateLastSeenScreen: (type, screen, basic, end) =>
      dispatch(updateLastSeenScreen(type, screen, basic, end))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(HigherStudies);
