import React from "react";
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  Image,
  Platform,
  TouchableOpacity,
  Picker,
} from "react-native";
import { md } from "../../../Responsive";

import NavigationHeader from "../../Common/NavigationHeader";

import { getCurrentHoldings, getHashFetchHoldings } from "../../../api";
import { Popable } from "react-native-popable";
import { Button } from "react-native-paper";
import { MAIN_URL, URLS } from "../../../api/urls";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../../api/apiConfig";
import { isJama, isMobile, reverseString } from "../../../utils";
import { AntDesign, FontAwesome5 } from "@expo/vector-icons";
import Checkbox from "expo-checkbox";
import Store from "../../../store";
import amplitude from "../../../utils/amplitude";

const window = Dimensions.get("window");
const { width } = window;
const isWeb = md(width);

const TableCell = ({
  value,
  subValue,
  style = [],
  textStyle = [],
  info,
  ...props
}) => {
  if (!value) {
    return (
      <View style={[{ paddingVertical: 7 }, ...style]}>{props.children}</View>
    );
  }
  let demat_units, demat_text, jama_units, jama_text;
  if (info) {
    demat_units = info[0];
    demat_text = info[1];
    jama_units = info[2];
    jama_text = info[3];
  }
  return (
    <View style={[{ height: "100%" }, ...style]}>
      <Text
        style={[
          {
            fontSize: 13,
            color: "#121212",
            paddingHorizontal: 16,
          },
          ...textStyle,
        ]}
      >
        {value}
        {info && (
          <Popable
            strictPosition={false}
            position={"top"}
            style={{ minWidth: 200, zIndex: 10 }}
            wrapperStyle={{ zIndex: 10 }}
            backgroundColor={"#F6F6F6"}
            action="hover"
            content={
              <View
                style={{
                  overflow: "hidden",
                  paddingHorizontal: 11,
                  paddingVertical: 5,
                  zIndex: 10,
                }}
              >
                <Text style={{ fontSize: 11 }}>
                  <Text style={[{ color: "#EF5D12", paddingRight: 4 }]}>
                    {demat_units}
                  </Text>
                  <Text style={[{ color: "#12121290", paddingRight: 0 }]}>
                    {demat_text}
                  </Text>
                </Text>
                <Text style={{ fontSize: 11, marginTop: 5 }}>
                  <Text style={[{ color: "#EF5D12", paddingRight: 4 }]}>
                    {jama_units}
                  </Text>
                  <Text style={[{ color: "#12121290", paddingRight: 0 }]}>
                    {jama_text}
                  </Text>
                </Text>
              </View>
            }
          >
            <FontAwesome5
              name="exclamation-circle"
              size={14}
              color="#EF5D12"
              style={{ paddingLeft: 4, zIndex: 1, paddingTop: 3 }}
            />
          </Popable>
        )}
      </Text>
      {subValue && (
        <Text
          style={[
            {
              fontSize: 11,
              color: "#12121280",
              paddingHorizontal: 16,
              textAlign: "center",
            },
          ]}
        >
          {subValue}
        </Text>
      )}
    </View>
  );
};

export default class CurrentHoldings extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    const status = this.props.status || null;
    const type = this.props.type || null;
    const { params } = props.route;
    let productName;
    if (params) {
      productName = params.product_name;
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      data: null,
      error: null,
      errorText: null,
      status,
      type,
      userId,
      productName,
      selected: "J",
      selectedSort: "*"
    };
  }
  handleSort = (sortBy) => {
    // console.log(sortBy);
    let dataall = this.state.data
    let datatosort = dataall.stocks
    if (sortBy == "name") {
      const sorted_stocks = datatosort.sort((a, b) =>
        a.stock_name > b.stock_name ? 1 : b.stock_name > a.stock_name ? -1 : 0
      );
      dataall["stocks"] = sorted_stocks
      this.setState({
        selectedSort: sortBy,
        data: dataall
      })
    }
    if (sortBy == "symbol") {
      const sorted_stocks = datatosort.sort((a, b) =>
        a.stock_symbol > b.stock_symbol ? 1 : b.stock_symbol > a.stock_symbol ? -1 : 0
      );
      dataall["stocks"] = sorted_stocks
      this.setState({
        selectedSort: sortBy,
        data: dataall
      })
    }
  };
  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("CurrentHoldings", { path: global.location.href });
      } else {
        amplitude.logEvent("CurrentHoldings", {
          path: this.props.navigation.getState().routeNames,
        });
      }
      const { productName } = this.state;
      if (!productName) {
        this.setState({
          error: true,
          errorText: "Product ID is not found!",
        });
        return;
      }
      let response = {};
      if (this.state.userId) {
        response = await getCurrentHoldings(productName, this.state.userId);
      } else {
        response = await getCurrentHoldings(productName);
      }
      if (response && response.code == 100) {
        let { result } = response;
        let {
          stocks,
          show_fetch_holdings_button,
          is_jama_and_demat_balance_same,
          common_rebalance_button,
          reb_jama_balance_button,
          reb_jama_balance_tooltip,
          reb_demat_balance_button,
          reb_demat_balance_tooltip,
          show_oh,
        } = result;
        const sorted_stocks = stocks.sort((a, b) =>
          a.stock_symbol > b.stock_symbol ? 1 : b.stock_symbol > a.stock_symbol ? -1 : 0
        );
        result["stocks"] = sorted_stocks;
        this.setState({
          data: result,
          show_fetch_holdings_button,
          is_jama_and_demat_balance_same,
          common_rebalance_button,
          reb_jama_balance_button,
          reb_jama_balance_tooltip,
          reb_demat_balance_button,
          reb_demat_balance_tooltip,
          selected: show_fetch_holdings_button ? "J" : "D",
          showOh: show_oh,
        });
      } else {
        this.setState({
          error: true,
          errorText: response && response.msg ? response.msg : null,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  handleContinueRebalance = () => {
    if (this.state.selected === "J") {
      const { productName, selected } = this.state;
      this.props.navigation.navigate("RebalanceRecommendations", {
        product: productName,
        units: selected,
      });
    }
  };

  renderTableHeader = () => {
    const { selected } = this.state;
    return (
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View
          style={[
            {
              flex: 1,
              flexDirection: "row",
              borderBottomColor: "#968E8E40",
              borderBottomWidth: 1,
              borderTopColor: "#968E8E40",
              borderTopWidth: 1,
              alignItems: "flex-start",
            },
          ]}
        >
          <TableCell
            value={"Stock Name"}
            style={[
              {
                flex: 0.6,
                borderRightWidth: 1,
                borderRightColor: "#E5EAEE",
                paddingVertical: 20,
              },
            ]}
            textStyle={[
              {
                fontWeight: "bold",
                color: "#222225",
                fontSize: 14,
                paddingVertical: 7,
              },
            ]}
          />
          <TableCell
            value={"Units as per MaxiomWealth"}
            style={[
              {
                flex: 0.4,
                borderRightWidth: 1,
                borderRightColor: "#E5EAEE",
                paddingVertical: 20,
                alignItems: "center",
              },
              selected == "J" && styles.activeBackground,
            ]}
            textStyle={[
              {
                fontWeight: "bold",
                color: "#222225",
                fontSize: 14,
                textAlign: "center",
                paddingVertical: 7,
              },
            ]}
          />
        </View>
      </ScrollView>
    );
  };

  // renderSuggestionItem = ({ item }) => {
  //   const { info } = item;
  //   const { selected } = this.state;
  //   return (
  //     <View>
  //       <View
  //         style={[
  //           {
  //             flex: 1,
  //             flexDirection: "row",
  //             alignItems: "center",
  //           },
  //         ]}
  //       >
  //         <TableCell
  //           style={[
  //             {
  //               flex: 0.6,
  //               borderRightWidth: 1,
  //               borderRightColor: "#E5EAEE",
  //             },
  //           ]}
  //         >
  //           <View style={{ flex: 1, paddingLeft: 16 }}>
  //             <Text
  //               style={{
  //                 fontSize: 14,
  //                 color: "#464E5F",
  //                 fontWeight: "bold",
  //                 marginBottom: 4,
  //                 minWidth: 100,
  //                 maxWidth: isMobile ? 100 : "100%",
  //               }}
  //             >
  //               {item.stock_unit > 0 ? item.stock_name : ""}
  //             </Text>
  //             <Text style={{ color: "#9FA2B4", fontSize: 12, paddingTop: 6 }}>
  //               {/* {item.stock_symbol} */}
  //               {item.stock_unit > 0 ? item.stock_symbol : ""}
  //             </Text>
  //           </View>
  //         </TableCell>


  //         {/* <TableCell
  //           value={item.stock_unit > 0}
  //           style={[
  //             {
  //               flex: 0.4,
  //               borderRightWidth: 1,
  //               borderRightColor: "#E5EAEE",
  //               alignItems: "center",
  //               justifyContent: "center",
  //             },
  //             selected == "J" && styles.activeBackground,
  //           ]}
  //           info={info}
  //         /> */}

  //         <TableCell
  //           value={item.stock_unit}  // Assuming you want to display the stock_unit value itself
  //           style={[
  //             {
  //               flex: 0.4,
  //               borderRightWidth: 1,
  //               borderRightColor: "#E5EAEE",
  //               alignItems: "center",
  //               justifyContent: "center",
  //             },
  //             selected === "J" && styles.activeBackground,
  //           ]}
  //           info={info}
  //         />
  //       </View>
  //     </View>
  //   );
  // };
  renderSuggestionItem = ({ item }) => {
    const { info } = item;
    const { selected } = this.state;

    // Check if stock_unit is greater than zero
    if (item.stock_unit <= 0) {
      return null; // Do not render anything if stock_unit is zero or less
    }

    return (
      <View>
        <View
          style={[
            {
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
            },
          ]}
        >
          <TableCell
            style={[
              {
                flex: 0.6,
                borderRightWidth: 1,
                borderRightColor: "#E5EAEE",
              },
            ]}
          >
            <View style={{ flex: 1, paddingLeft: 16 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#464E5F",
                  fontWeight: "bold",
                  marginBottom: 4,
                  minWidth: 100,
                  maxWidth: isMobile ? 100 : "100%",
                }}
              >
                {item.stock_name}
              </Text>
              <Text style={{ color: "#9FA2B4", fontSize: 12, paddingTop: 6 }}>
                {item.stock_symbol}
              </Text>
            </View>
          </TableCell>

          <TableCell
            value={`${item.stock_unit}`} // Display the stock_unit value as a string
            style={[
              {
                flex: 0.4,
                borderRightWidth: 1,
                borderRightColor: "#E5EAEE",
                alignItems: "center",
                justifyContent: "center",
              },
              selected === "J" && styles.activeBackground,
            ]}
            info={info}
          />
        </View>
      </View>
    );
  };


  renderEmptyStocks = () => {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: 100,
        }}
      >
        <Text style={{ fontSize: 14, color: "#12121270" }}>
          No Stock holdings{" "}
        </Text>
      </View>
    );
  };

  handleFetchHoldings = async () => {
    try {
      const { productName } = this.state;
      this.setState({ fetchingHoldings: true });
      const response = await getHashFetchHoldings(productName);
      if (response && response.code == 100) {
        const { hash_key } = response.result;
        let token = getTokenFromStore();
        token = reverseString(token);
        global.open(
          MAIN_URL +
          URLS.FETCH_HOLDINGS +
          (hash_key + token + "/" + GATEWAY_ENVIRONMENT),
          "_self"
        );
      } else {
        this.setState({
          error: true,
          errorText: "Something went wrong",
        });
      }
    } catch (error) {
      this.setState({
        fetchingHoldings: false,
        error: true,
        errorText: "Something went wrong",
      });
    }
  };

  render() {
    const { data, error, errorText, selectedSort } = this.state;
    if (error) {
      return (
        <View style={{ flexDirection: "column", height: "100%" }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Equity Plan", "Current Holding"]}
            onPress={() => {
              this.props.navigation.navigate("InvestmentOverview");
            }}
          />
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              maxWidth: 1300,
            }}
          >
            <Text style={{ fontSize: 16, color: "#121212" }}>
              {errorText || "Something went wrong!"}
            </Text>
          </View>
        </View>
      );
    }

    let videoLink = null;
    if (data) {
      if (data.stock_broker == "UPX") {
        videoLink = "https://maxiomwealth.com/blog/upstox/#Bill-Report";
      } else if (data.stock_broker == "ZRD") {
        videoLink = "https://maxiomwealth.com/blog/zerodha/#Tradebook";
      }
    }

    const { showOh, selected } = this.state;

    return (
      <View style={{ flex: 1, backgroundColor: "#ffffff" }}>
        <View>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={[
              "Equity Plan",
              md(window.width)
                ? `Portfolio ${this.state.productName == "JWL"
                  ? "JEWEL"
                  : this.state.productName == "SRK"
                    ? "SPARK"
                    : this.state.productName
                } Rebalance`
                : "Confirm Current Holdings",
            ]}
            onPressBack={() => {
              this.props.navigation.goBack();
            }}
          />
        </View>

        {data ? (
          <ScrollView style={[styles.mainContainer]}>
            {data && (
              <>
                {showOh && (
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      backgroundColor: "#FFF8F4",
                      borderRadius: 12,
                      paddingVertical: isMobile ? 16 : 18,
                      paddingHorizontal: isMobile ? 18 : 20,
                      marginBottom: 25,
                      alignItems: "flex-start",
                    }}
                  >
                    <AntDesign
                      name="close"
                      size={16}
                      color="#888C9F"
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 10,
                        //cursor: Platform.OS == "web" ? "pointer" : null,
                      }}
                      onPress={() => {
                        this.setState({ showOh: false });
                      }}
                    />
                    <Image
                      source={require("../../../assets/rebalancing/oh_warning.png")}
                      style={{
                        width: isMobile ? 15 : 21,
                        height: isMobile ? 14 : 21,
                        marginRight: isMobile ? 10 : 14,
                      }}
                    />
                    <View style={{ flex: 1, flexWrap: "wrap" }}>
                      <Text
                        style={{
                          fontSize: isMobile ? 12 : 15,
                          color: "#121212",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Some of your stock demat balances are not matching. You
                        can still complete your rebalancing.
                      </Text>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          backgroundColor: "#d0ffd160",
                          borderRadius: 4,
                          paddingVertical: 8,
                          paddingHorizontal: 12,
                          marginTop: 20,
                          alignItems: "center",
                        }}
                      >
                        <Image
                          source={require("../../../assets/rebalancing/oh_info.png")}
                          style={{
                            width: isMobile ? 18 : 25,
                            height: isMobile ? 18 : 25,
                            marginRight: 8,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: isMobile ? 10 : 12,
                            color: "#009105",
                            fontWeight: "bold",
                            flexWrap: "wrap",
                          }}
                        >
                          You can proceed with the rebalancing using the demat
                          unit balances. Fix the differences later by sharing
                          contract notes / bill details / trade book entries to{" "}
                          <a
                            href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=help@maxiomwealth.com"
                            target="_blank"
                            style={{ color: "#0000EE", textDecoration: "underline" }}
                          >
                            help@maxiomwealth.com
                          </a>
                        </Text>
                      </View>
                    </View>
                  </View>
                )}

                <View style={styles.border}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={styles.currentHoldings}>
                      <Text style={styles.amountTitle}>Current Holdings</Text>
                      <Picker selectedValue={selectedSort} onValueChange={(value) => this.handleSort(value)} style={styles.dropdown}>
                        <Picker.Item label="Select" value="*" style={styles.dropdownItem} />
                        <Picker.Item label="Sort by Name" value="name" style={styles.dropdownItem} />
                        <Picker.Item label="Sort by Symbol" value="symbol" style={styles.dropdownItem} />
                      </Picker>
                    </View>
                    <View style={{ flex: 1 }}>
                      <TouchableOpacity
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                        onPress={() => {
                          if (Platform.OS == "web") {
                            let newTab = global.open();
                            newTab.location.href =
                              "https://maxiomwealth.com/blog/how-to-rebalance-your-portfolio-on-jamawealth/";
                          } else {
                            this.props.navigation.navigate("App", {
                              screen: "Dashboard",
                              params: {
                                screen: "Portfolio",
                                params: {
                                  screen: "ShowWebView",
                                  params: {
                                    url: "https://maxiomwealth.com/blog/how-to-rebalance-your-portfolio-on-jamawealth/",
                                  },
                                },
                              },
                            });
                          }
                        }}
                      >
                        <Image
                          source={require("../../../assets/how_to.png")}
                          resizeMode="contain"
                          style={{
                            height: 18,
                            width: 18,
                            marginRight: 8,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#410DAA",
                            fontWeight: "700",
                            marginRight: 16,
                          }}
                        >
                          How to rebalance portfolio
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>

                  {/* table data */}
                  <View style={{ flex: 1 }}>
                    <FlatList
                      data={this.state.data.stocks}
                      ref={(ref) => {
                        this.suggestionsTable = ref;
                      }}
                      horizontal={false}
                      renderItem={this.renderSuggestionItem}
                      keyExtractor={(item, index) => `suggestion_${index}`}
                      ItemSeparatorComponent={() => (
                        <View
                          style={{
                            backgroundColor: "#E5EAEE",
                            width: "100%",
                            height: 1,
                            zIndex: -1,
                          }}
                        />
                      )}
                      ListHeaderComponent={this.renderTableHeader}
                      ListEmptyComponent={this.renderEmptyStocks}
                      style={{
                        minWidth: isMobile ? "100%" : 600,
                        zIndex: -10,
                      }}
                      contentContainerStyle={{
                        flexGrow: 1,
                        width: '100%'
                      }}
                    />
                  </View>

                  {/* Show this Footer Note incase fof WEB */}

                  {/* <View style={styles.footer}>
                    <View style={styles.confirmButton}>
                      <View style={{ flex: isMobile ? 0 : 0.6 }}></View>
                      <View
                        style={{
                          flex: isMobile ? 0.5 : 0.4,
                          alignItems: "center",
                          marginTop: isMobile ? 10 : 0,
                        }}
                      >
                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          <Checkbox
                            value={selected === "J" ? true : false}
                            status={selected === "J" ? "checked" : "unchecked"}
                            onValueChange={() => {
                              this.setState({ selected: selected === "J" ? "N" : "J" });
                            }}
                            style={styles.checkBox}
                            color={
                              selected === "J" ? "#410DAA" : "#cdcdcd"
                            }
                          />
                          {isMobile ? (
                            <View style={styles.mobileCheckboxTextContainer}>
                              <Text
                                style={[
                                  styles.mobileCheckboxText,
                                  selected === "J" && styles.activeCheckBox,
                                ]}
                              >
                                Rebalance with units as per units shared with MaxiomWealth
                              </Text>
                            </View>
                          ) : (
                            <Text
                              style={[
                                styles.checkBoxText,
                                selected === "J" && styles.activeCheckBox,
                              ]}
                            >
                              Rebalance with units as per contract notes / trade books shared with {isJama() ? "Jama" : "Maxiom"}Wealth
                            </Text>
                          )}
                        </View>
                      </View>
                    </View>
                  </View> */}
                </View>

                {/* Continue to Rebalance */}
                <View style={styles.continueRebalanceContainer}>
                  <View style={styles.container}>
                    <Text style={styles.infoText}>
                      Above units are aggregated as per contract notes shared. Please contact{' '}
                      <a
                        href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=help@maxiomwealth.com"
                        target="_blank"
                        style={{ color: "#0000EE", textDecoration: "underline" }}
                      >
                        help@maxiomwealth.com
                      </a>
                      {' '}in case of any differences.
                    </Text>
                  </View>
                  <View style={styles.continueRebalanceSpace} />
                  <View style={styles.continueRebalanceButton}>
                    <Button
                      uppercase={false}
                      color="#410DAA"
                      mode="contained"
                      style={styles.continueRebalance}
                      disabled={!(this.state.selected === "J")}
                      onPress={this.handleContinueRebalance}
                    >
                      Continue To Rebalance
                    </Button>
                  </View>
                  <View style={styles.continueRebalanceSpaceRem} />
                </View>
              </>
            )}
          </ScrollView>
        ) : (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    width: 550,
  },
  infoText: {
    marginBottom: 10,
    fontSize: 14,
    color: '#410DAA',
    flexWrap: 'wrap',
  },
  linkText: {
    color: 'blue',
    textDecorationLine: 'underline',
    lineHeight: 20,
    textAlignVertical: 'center',
  },
  mainContainer: {
    flexGrow: 1,
    paddingLeft: md(window.width) ? 52 : 16,
    paddingRight: md(window.width) ? 52 : 16,
    paddingTop: 30,
    maxWidth: 1300,
  },
  confirmButton: {
    paddingVertical: 6,
    borderRadius: 6,
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "center" : "flex-start",
    justifyContent: isMobile ? "center" : "flex-end",
  },
  confirmButton2: {
    paddingTop: 6,
    borderRadius: 6,
    width: "100%",
  },
  noText: {
    color: "#410DAA",
    fontSize: 15,
  },
  confirmButtonYes: {
    backgroundColor: "#410DAA",
  },
  yesText: {
    color: "#fff",
    fontSize: 15,
  },
  border: {
    borderColor: "#968E8E40",
    borderWidth: 2,
    borderRadius: 4,
    marginBottom: 20,
  },
  tableSummary: {
    paddingHorizontal: md(window.width) ? 25 : 8,
    paddingVertical: md(window.width) ? 12 : 8,
  },
  amountContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  amountTitle: {
    fontSize: md(window.width) ? 17 : 15,
    fontWeight: "700",
    color: "#000000",
    marginLeft: 16,
  },
  dropdown: {
    marginLeft: 10,
    height: 30,
    width: 125,
    color: '#410DAA',
    borderRadius: 5,
    paddingHorizontal: 10,
    justifyContent: "center",
    alignContent: "center",
    fontSize: 15,
  },
  dropdownItem: {
    // fontSize: 10,
  },
  amount: {
    fontSize: 17,
    fontWeight: "700",
    color: "#410DAA",
    fontStyle: "italic",
    paddingLeft: 5,
  },
  completeList: {
    paddingTop: 9,
    fontSize: md(window.width) ? 13 : 12,
  },
  footer: {
    width: "100%",
    paddingVertical: md(window.width) ? 0 : 8,
    backgroundColor: "#F3F6F9",
    overflow: "hidden",
    zIndex: 10,
  },
  footer2: {
    flexDirection: md(window.width) ? "row" : "column",
    justifyContent: "space-between",
    paddingHorizontal: md(window.width) ? 30 : 0,
    paddingTop: md(window.width) ? 0 : 8,
    backgroundColor: "#F3F6F9",
    overflow: "hidden",
    alignItems: "center",
    position: Platform.OS == "web" ? "fixed" : "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    marginBottom: -5,
    zIndex: 10,
  },
  note: {
    marginBottom: md(window.width) ? 20 : 8,
    width: isWeb ? "66%" : "100%",
  },
  note2: {
    marginBottom: md(window.width) ? 0 : 8,
    paddingHorizontal: 8,
  },
  noteText: {
    color: "#331100",
    fontSize: md(window.width) ? 12 : 9,
    fontWeight: "200",
    textAlign: md(window.width) ? "left" : "center",
  },
  noteTextBold: {
    fontWeight: "bold",
    paddingLeft: 5,
  },
  button: {
    alignItems: "center",
    backgroundColor: "#410DAA",
    marginVertical: 2,
    borderRadius: 6,
  },
  button2: {
    alignItems: "center",
    backgroundColor: "#410DAA",
    marginVertical: 2,
  },
  buttonText: {
    textAlign: "center",
    paddingHorizontal: 30,
    paddingVertical: 12,
    color: "white",
    fontWeight: "700",
    fontSize: 14,
  },
  proceedButton: {
    paddingVertical: 4,
    marginTop: isMobile ? 10 : 0,
  },
  checkBoxContainer: {
    alignItems: "center",
  },
  checkBoxText: {
    fontFamily: "Roboto",
    fontSize: 13,
    color: "#494B69",
    marginTop: 17,
    textAlign: "center",
    width: "70%",
    paddingBottom: 15,
    justifyContent: 'center'
  },
  activeCheckBox: {
    fontWeight: "bold",
    color: "#410DAA",
  },
  currentHoldings: {
    flexDirection: 'row',
    flex: 1,
    paddingVertical: 23,
  },
  jamaUnitsContainer: {
    flex: 0.3,
    alignItems: "center",
    paddingVertical: 23,
  },
  dematUnitsContainer: {
    flex: 0.3,
    alignItems: "center",
    height: "100%",
    paddingVertical: 23,
  },
  activeBackground: {
    backgroundColor: "#F9F6FF",
  },
  lastUpdated: {
    color: "#888C9F",
    fontSize: 13,
    fontStyle: "italic",
    fontFamily: "Roboto",
  },
  lastUpdatedTime: {
    color: "#410DAA",
  },
  dematUnitsHeadingContainer: {
    alignItems: "center",
    paddingTop: 15,
    paddingBottom: 20,
  },
  dematUnitsHeading: {
    color: "#222225",
    fontFamily: "Roboto",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 14,
    width: "80%",
  },
  dematUnitsUpdate: {
    marginTop: 11,
    borderRadius: 46,
    borderColor: "#410DAA",
  },
  dematUnitsUpdateLabel: {
    fontSize: 14,
  },
  continueRebalanceContainer: {
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 100,
  },
  continueRebalanceSpace: {
    flex: isMobile ? 0 : 0.6,
    backgroundColor: "red",
  },
  continueRebalanceButton: {
    flex: isMobile ? 1 : 1,
    alignItems: "center",
  },
  continueRebalance: {
    paddingVertical: 6,
    marginBottom: 20,
    width: "80%",
  },
  continueRebalanceSpaceRem: {
    flex: isMobile ? 0 : 0,
  },
  mobileCheckboxTextContainer: {
    flex: 1,
    paddingHorizontal: 10,
    justifyContent: 'center',
  },
  mobileCheckboxText: {
    fontFamily: "Roboto",
    fontSize: 13,
    color: "#494B69",
    textAlign: "center",
    lineHeight: 18,
  },
});
