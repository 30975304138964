import React, { Component } from "react";
import { View, SafeAreaView,Platform, StyleSheet } from "react-native";
import Store from "../../../store";
import { connect } from "react-redux";
import moment from "moment";
import { validateInput } from "../../../utils/validateInput";
import TextInputField from "../../../components/Common/Inputs/InputField";
import { SideHeading } from "../../../components/Common/sideHeading";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { Button, Text } from "react-native-paper";
import amplitude from "../../../utils/amplitude";
import PickerField from "../../../components/Common/Pickers/PickerField";
import DatePickerField from "../../../components/Common/Pickers/DatePickerField";
import theme from "../../../static/theme";
import {
  getUserDetails,
  isMobile,
  redirectToPendingScreen,
} from "../../../utils";
import { saveCustomerData } from "../../../api/account";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import { ShowToast } from "../../../components/Common/Toast";

const relations = [
  {
    key: 1,
    label: "AUNT",
    value: "01",
  },
  {
    key: 2,
    label: "BROTHER-IN-LAW",
    value: "02",
  },
  {
    key: 3,
    label: "BROTHER",
    value: "03",
  },
  {
    key: 4,
    label: "DAUGHTER",
    value: "04",
  },
  {
    key: 5,
    label: "DAUGHTER-IN-LAW",
    value: "05",
  },
  {
    key: 6,
    label: "FATHER",
    value: "06",
  },
  {
    key: 7,
    label: "FATHER-IN-LAW",
    value: "07",
  },
  {
    key: 8,
    label: "GRAND DAUGHTER",
    value: "08",
  },
  {
    key: 9,
    value: "GRAND FATHER",
    label: "09",
  },
  {
    key: 10,
    label: "GRAND MOTHER",
    value: "10",
  },
  {
    key: 11,
    label: "GRAND SON",
    value: "11",
  },
  {
    key: 12,
    label: "MOTHER-IN-LAW",
    value: "12",
  },
  {
    key: 13,
    label: "MOTHER",
    value: "13",
  },
  {
    key: 14,
    label: "NEPHEW",
    value: "14",
  },
  {
    key: 15,
    label: "NIECE",
    value: "15",
  },
  {
    key: 16,
    label: "SISTER",
    value: "16",
  },
  {
    key: 17,
    label: "SISTER-IN-LAW",
    value: "17",
  },
  {
    key: 18,
    label: "SON",
    value: "18",
  },
  {
    key: 19,
    label: "SON-IN-LAW",
    value: "19",
  },
  {
    key: 20,
    label: "SPOUSE",
    value: "20",
  },
  {
    key: 21,
    label: "UNCLE",
    value: "21",
  },
  {
    key: 22,
    label: "OTHERS",
    value: "22",
  },
];

class AddNominee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      mobile: "",
      nomineeDOB: "",
      showDate: false,
      nameError: false,
      showNomineeToast: "false",
      toastTitle: "",
      relation: "0",
    };
  }

  componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("AddNominee",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("AddNominee", { path: this.props.navigation.getState().routeName });
    }
    const customerDetails = getUserDetails();
    if (customerDetails) {
      const { customer_nominee_name, customer_nominee_relation } =
        customerDetails;
      // console.log(customer_nominee_name, customer_nominee_relation)
      const relationError = validateInput("select", customer_nominee_relation);
      this.setState({
        name: customer_nominee_name,
        relation: customer_nominee_relation.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()),
        relationError,
      });
    }
  }

  static navigationOptions = () => ({
    tabBarVisible: false,
  });

  handleChange = (value, type, inputType) => {
    // console.log(value);
    this.setState({ nameError: false });
    this.setState({
      [type]: value,
    });
  };

  formattedNumber = (number) => ("0" + number).slice(-2);

  _handleDatePicked = (date) => {
    const day = this.formattedNumber(date.getDate());
    const month = parseInt(date.getMonth()) + 1;
    const year = date.getFullYear();
    const dob = year + "-" + month + "-" + day;
    const nomineeDOB = moment(date).format("DD[/]MM[/]YYYY");

    this.setState({
      nomineeDOB,
    });

    this._hideDateTimePicker();
  };

  _hideDateTimePicker = () => this.setState({ showDate: false });

  addNominee = async () => {
    try {
      this.setState({ showNomineeToast: "false" });
      const { name, relation, isLoading } = this.state;
      if (!name || name == "") {
        this.setState({ nameError: true });
        this.setState({
          showNomineeToast: "true",
          toastTitle: "Please enter nominee name",
        });
        return;
      } else {
        this.setState({ nameError: false });
      }
      if (!relation || relation == "0" || relation =="03" || relation == "SEL") {
        this.setState({ relationError: true });
        this.setState({
          showNomineeToast: "true",
          toastTitle: "Please select relation",
        });
        return;
      } else {
        this.setState({ relationError: false });
      }
      const { username, customer_id } = getUserDetails();
      const payload = {
        username: username,
        customer_nominee_name: name,
        customer_nominee_relation: relation,
      };

      this.setState({ isLoading: true });

      const response = await saveCustomerData(customer_id, payload);
      Store.dispatch({
        type: "SAVE_PROFILE_DATA",
        payload: response,
      });
      this.setState({
        showNomineeToast: "true",
        toastTitle: "Nominee details updated.",
      });
      setTimeout(() => {
        this.props.navigation.goBack();
      }, 1000);
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  validate = () => {
    return this.state.nameError && this.state.relationError;
  };

  render() {
    const {
      nameError,
      name,
      email,
      nomineeDOB,
      mobile,
      relation,
      relationError,
    } = this.state;
    const from = true;

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        {!this.props.minDetailsFlow && (
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Nominee Details"]}
          />
        )}
        {!from && (
          <SideHeading
            title={"Add Nominee"}
            style={{ fontSize: 20, alignItems: "center" }}
          />
        )}
        {this.state.showNomineeToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title={this.state.toastTitle}
            duration="3"
          />
        ) : null}
        <View style={{ marginHorizontal: 16 }}>
          {!isMobile ? (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Nominee Name*"
                  value={name}
                  onChange={(name) => {
                    if (/^[a-zA-Z ]*$/.test(name) || name == "") {
                      this.handleChange(name, "name", "text");
                    }
                  }}
                  style={[{ ...styles.inputItem, width: "93.35%" }]}
                  editable={true}
                  type="text"
                  inputStyle={[{ paddingLeft: 24, width: 280 }]}
                />
              </View>

              <View style={[styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={relations}
                  value={relation}
                  label="Nominee Relation*"
                  onChange={(relation) =>
                    this.handleChange(relation, "relation", "select")
                  }
                  pickerStyle={[{ ...styles.inputItem, width: "93.35%" }]}
                  isValid={relationError}
                  type="select"
                  // placeholder={{ value: "SEL", label: "Select Relation" }}
                />
              </View>

              {/* <View style={[styles.formItem, styles.itemMargin]}>
                <DatePickerField
                  label="Nominee DOB*"
                  value={nomineeDOB}
                  onSelect={(value) => {
                    this._handleDatePicked(new Date(value.date));
                  }}
                  validRange={{
                    startDate: new Date(1920, 1, 1),
                    endDate: new Date(),
                  }}
                  editable={true}
                  inputStyle={[{ paddingLeft: 24 }]}
                  style={[{ ...styles.inputItem, width: "93.35%" }]}
                />
              </View> */}
            </>
          ) : (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Nominee Name*"
                  value={name}
                  onChange={(name) => {
                    if (/^[a-zA-Z ]*$/.test(name) || name == "") {
                      this.handleChange(name, "name", "text");
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                  inputStyle={[{ paddingLeft: 24 }]}
                  isValid={nameError}
                />
              </View>
              {nameError && (
                <Text style={[styles.please, { color: "red" }]}>
                  Please enter nominee name
                </Text>
              )}
              <View style={[styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={relations}
                  value={relation}
                  label="Nominee Relation*"
                  onChange={(relation) =>
                    this.handleChange(relation, "relation", "select")
                  }
                  pickerStyle={[{ ...styles.inputItem }]}
                  isValid={relationError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select Relation" }}
                />
              </View>

              {/* <View style={[styles.formItem, styles.itemMargin]}>
                <DatePickerField
                  label="Nominee DOB*"
                  value={nomineeDOB}
                  onSelect={(value) => {
                    this._handleDatePicked(new Date(value.date));
                  }}
                  validRange={{
                    startDate: new Date(1920, 1, 1),
                    endDate: new Date(),
                  }}
                  editable={true}
                  inputStyle={[{ paddingLeft: 24 }]}
                  style={[
                    {
                      width: "100%",
                    },
                  ]}
                />
              </View> */}
            </>
          )}
          {/* {!isMobile ? (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Email Id*"
                  value={email}
                  onChange={(text) => {
                    this.setState({
                      email: text,
                    });
                  }}
                  style={[{ ...styles.inputItem }]}
                  inputStyle={[{ paddingLeft: 24 }]}
                  editable={true}
                />
              </View>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Mobile Number*"
                  value={mobile}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        mobile: text,
                      });
                    }
                  }}
                  inputStyle={[{ paddingLeft: 24 }]}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                />
              </View>
            </View>
          ) : (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Email Id*"
                  value={email}
                  onChange={(text) => {
                    this.setState({
                      email: text,
                    });
                  }}
                  style={[{ ...styles.inputItem }]}
                  inputStyle={[{ paddingLeft: 24 }]}
                  editable={true}
                />
              </View>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Mobile Number*"
                  value={mobile}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        mobile: text,
                      });
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  inputStyle={[{ paddingLeft: 24 }]}
                  editable={true}
                />
              </View>
            </>
          )} */}
        </View>

        {!isMobile ? (
          <Button
            uppercase={false}
            mode={"contained"}
            onPress={this.addNominee}
            style={{
              marginTop: 50,
              width: "16%",
              left: "9%",
            }}
            loading={this.state.isLoading}
            color={"#410DAA"}
          >
            <Text style={styles.next}>Save</Text>
          </Button>
        ) : (
          <Button
            uppercase={false}
            mode={"contained"}
            onPress={this.addNominee}
            style={{
              marginTop: 30,
              width: "40%",
              marginLeft: "30%",
            }}
            loading={this.state.isLoading}
            color={"#410DAA"}
          >
            <Text style={styles.next}>Save</Text>
          </Button>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.MAIN_BACKGROUND,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  please: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3,
  },
});

export default connect(null)(AddNominee);
