import React, { useState } from "react";
import { View, Text, StyleSheet, Platform } from "react-native";
import { isMobile, numberWithCommas } from "../../../../utils";
import { Switch } from "react-native-paper";
import { Popable } from "react-native-popable";
import Icon from "@expo/vector-icons/FontAwesome";

const ToggleSummaryView = (props) => {
  const { value, onValueChange } = props;
  const handleValueChange = () => {
    onValueChange();
  };
  return (
    <View style={toggleStyles.container}>
      {!isMobile && (
        <Text
          style={[
            toggleStyles.item,
            !value ? toggleStyles.active : toggleStyles.inActive,
            { fontSize: 14 },
          ]}
        >
          Since inception{value}
        </Text>
      )}
      <Switch
        value={value}
        style={[StyleSheet.flatten([toggleStyles.switch])]}
        onValueChange={handleValueChange}
        color={"#410DAA"}
      />
      <Text
        style={[
          toggleStyles.item,
          value ? toggleStyles.active : toggleStyles.inActive,
          { fontSize: 14 },
        ]}
      >
        {isMobile
          ? value
            ? "Active plans"
            : "Since inception"
          : "Show only active plans"}
        {value}
      </Text>
    </View>
  );
};

const SummaryTable = ({ active, data = {} }) => {
  const { stocks_summary = {}, plan_data = {} } = data;

  let currentValue = active
    ? plan_data["plan_current_value"]
    : stocks_summary["stock_latest"];
  let originalInvested = active
    ? plan_data["plan_invested_value"]
    : stocks_summary["stock_inv"];
  let dividend = active
    ? plan_data["plan_dividend"]
    : stocks_summary["stock_dividend"];
  let avgHoldingPeriod = active
    ? plan_data["plan_holding_period_text"]
    : stocks_summary["stock_holding"];
  let cashInHand = active ? plan_data["plan_cash_in_hand"] : 0;
  let currentInvestment = active
    ? 0
    : stocks_summary["stock_inv_value_current"];
  let totalGainPerc = active
    ? plan_data["plan_abs_gain_perc"]
    : stocks_summary["stock_abs_gain"];

  let committedAUM = active ? plan_data["plan_committed_aum"] : 0;

  const unrealisedGainValue = active
    ? 0
    : stocks_summary["stock_unrealised_gain"];
  const realisedGainValue = active ? 0 : stocks_summary["stock_realised_gain"];

  let unrealisedGain = unrealisedGainValue;
  let realisedGain = realisedGainValue;

  let xirrPerc = active ? plan_data["plan_xirr"] : stocks_summary["stock_xirr"];
  let totalGain = active
    ? plan_data["plan_gain"]
    : stocks_summary["stock_gain"];

  // fix decimal values of all values
  currentValue = currentValue ? parseInt(currentValue) : 0;
  currentValue = "₹ " + numberWithCommas(currentValue);

  originalInvested = originalInvested ? parseInt(originalInvested) : 0;
  originalInvested = "₹ " + numberWithCommas(originalInvested);

  dividend = dividend ? parseInt(dividend) : 0;
  dividend = "₹ " + numberWithCommas(dividend);

  cashInHand = cashInHand ? parseInt(cashInHand) : 0;
  cashInHand = "₹ " + numberWithCommas(cashInHand);

  currentInvestment = currentInvestment ? parseInt(currentInvestment) : 0;
  currentInvestment = "₹ " + numberWithCommas(currentInvestment);

  totalGainPerc = totalGainPerc ? totalGainPerc.toFixed(2) : 0;

  unrealisedGain = unrealisedGain ? parseInt(unrealisedGain) : 0;
  unrealisedGain = "₹ " + numberWithCommas(unrealisedGain);

  realisedGain = realisedGain ? parseInt(realisedGain) : 0;
  realisedGain = "₹ " + numberWithCommas(realisedGain);

  xirrPerc = xirrPerc ? xirrPerc.toFixed(2) : 0;

  totalGain = totalGain ? parseInt(totalGain) : 0;
  totalGain = "₹ " + numberWithCommas(totalGain);

  committedAUM = committedAUM ? parseInt(committedAUM) : 0;
  committedAUM = "₹ " + numberWithCommas(committedAUM);

  if (!isMobile) {
    return (
      <View style={styles.summaryContainer}>
        <View style={styles.summaryRowContainer}>
          <View style={styles.summaryRow}>
            <PortfolioField label="Current Value" value={currentValue} />
            <PortfolioField
              label={!active ? "Original Inv. Amount" : "Invested Amount"}
              value={originalInvested}
            />
            {!active ? (
              <PortfolioField label="Dividend" value={dividend} />
            ) : (
              <PortfolioField label="Committed AUM" value={committedAUM} />
            )}
          </View>
        </View>
        <View style={styles.separator2} />
        <View style={styles.summaryRowContainer}>
          <View style={styles.summaryRow}>
            <PortfolioField
              label="Avg. Holding Period"
              value={avgHoldingPeriod || ""}
            />
            {!active ? (
              <PortfolioField
                label="Current Inv. Amount"
                value={currentInvestment}
              />
            ) : (
              <PortfolioField label="Cash in Hand" value={cashInHand} />
            )}
            {active ? (
              <PortfolioField label="Dividend" value={dividend} />
            ) : (
              <PortfolioField label="Total Gain" value={totalGain} />
            )}
          </View>
        </View>
        <View style={styles.separator2} />
        {!active && (
          <View style={styles.summaryRowContainer}>
            <View style={styles.summaryRow}>
              <PortfolioField
                label="Unrealised Gain/Loss"
                value={unrealisedGain}
                valueStyle={{
                  color: "#464E5F",
                }}
              />
              <PortfolioField
                label="Realised Gain/Loss"
                value={realisedGain}
                valueStyle={{
                  color: "#464E5F",
                }}
              />
            </View>
          </View>
        )}

        <View style={styles.summaryRowContainer}>
          <View style={styles.summaryRow}>
            {active && <PortfolioField label="Total Gain" value={totalGain} />}
            <PortfolioField
              label="Total Gain"
              value={totalGainPerc}
              isPercentage={true}
            />
            <PortfolioField label="XIRR" value={xirrPerc} isPercentage={true} />
          </View>
        </View>
      </View>
    );
  } else {
    return (
      <View style={summaryStyles.mobileContainer}>
        <View style={summaryStyles.mobileFirstColumn}>
          <PortfolioField label="Current Value" value={currentValue} />
          <PortfolioField
            label={!active ? "Original Inv. Amount" : "Planned Amount"}
            value={originalInvested}
          />
          <PortfolioField label="Dividend" value={dividend} />
          {!active && (
            <PortfolioField
              label="Unrealised Gain/Loss"
              value={unrealisedGain}
              valueStyle={{
                color: unrealisedGainValue >= 0 ? "#009105" : "#EF5D12",
              }}
            />
          )}
          <PortfolioField label="XIRR" value={xirrPerc} isPercentage={true} />
        </View>
        <View style={styles.separator2}></View>
        <View style={summaryStyles.mobileSecondColumn}>
          <PortfolioField
            label="Avg. Holding Period"
            value={avgHoldingPeriod || ""}
          />
          {!active ? (
            <PortfolioField
              label="Current Inv. Amount"
              value={currentInvestment}
            />
          ) : (
            <PortfolioField label="Cash in Hand" value={cashInHand} />
          )}
          <PortfolioField label="Total Gain" value={totalGain} />
          {!active && (
            <PortfolioField
              label="Realised Gain/Loss"
              value={realisedGain}
              valueStyle={{
                color: realisedGainValue >= 0 ? "#009105" : "#EF5D12",
              }}
            />
          )}
          <PortfolioField
            label="Total Gain"
            value={totalGainPerc}
            isPercentage={true}
          />
        </View>
      </View>
    );
  }
};

const summaryStyles = StyleSheet.create({
  mobileContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 21,
    paddingRight: 21,
    paddingTop: 16,
    paddingBottom: 16,
  },
});

const toggleStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginTop: 10,
    marginRight: isMobile ? 10 : 30,
    alignItems: "center",
  },
  item: {
    fontSize: isMobile ? 12 : 15,
    fontFamily: "Arial",
  },
  active: {
    color: "#410DAA",
  },
  inActive: {
    color: "#6C7293",
  },
  switch: {
    marginLeft: isMobile ? 4 : 10,
    marginRight: isMobile ? 4 : 10,
    marginTop: isMobile ? -8 : 0,
  },
});

const PortfolioField = (props) => {
  const {
    label,
    value,
    isPercentage = false,
    style = {},
    valueStyle = {},
  } = props;
  return (
    <View style={[fieldStyles.container, { ...style }]}>
      {label == "Current Inv. Amount" ? (
        <Popable
          action={isMobile ? "press" : "hover"}
          style={{ width: isMobile ? 200 : 500 }}
          content={
            <>
              <Text style={{ textAlign: "left", color: "white", padding: 10 }}>
                Current invested amount (CIA) may be different from the original
                investment amount (OIA).
              </Text>
              <Text style={{ textAlign: "left", color: "white", padding: 10 }}>
                This is because any realised gains (RG) are added to the
                original invited amount during rebalancing. For example,
                OIA=100, RG=8, then CIA=108
              </Text>
              <Text style={{ textAlign: "left", color: "white", padding: 10 }}>
                Demat stocks values would usually correspond to the Current
                invested Amount.
              </Text>
            </>
          }
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={fieldStyles.label}>
              {label}
              {isPercentage ? (
                <Text
                  style={[
                    value >= 0 ? fieldStyles.positive : fieldStyles.negative,
                  ]}
                >
                  (%)
                </Text>
              ) : null}
            </Text>
            <Icon
              style={{ marginLeft: 5, marginTop: -6 }}
              name="info-circle"
              size={14}
              color={"black"}
            />
          </View>
        </Popable>
      ) : (
        <Text style={fieldStyles.label}>
          {label}
          {isPercentage ? (
            <Text style={[fieldStyles.positive]}>(%)</Text>
          ) : null}
        </Text>
      )}

      <Text style={[fieldStyles.value, { ...valueStyle }]}>
        {value}
        {isPercentage ? " %" : ""}
      </Text>
    </View>
  );
};

const fieldStyles = StyleSheet.create({
  container: {
    marginVertical: isMobile ? 8 : 0,
  },
  label: {
    fontFamily: "Arial",
    fontSize: 13,
    color: "#6C7293",
    fontWeight: "400",
    marginBottom: 8,
  },
  value: {
    fontFamily: "Arial",
    fontSize: 15,
    color: "#464E5F",
    fontWeight: "700",
  },
  positive: {
    color: "#6C7293",
  },
});

const OldSummaryCard = (props) => {
  const [isSwitchOn, setIsSwitchOn] = useState(
    props.toggleStatus == "ACT" ? true : false
  );
  const onToggleSwitch = () => setIsSwitchOn((isSwitchOn) => !isSwitchOn);
  const [summaryData, setsummaryData] = useState(null);

  React.useEffect(() => {
    // by default - load first product
    setPieChartData();
  }, [props.stocks_summary]);

  const setPieChartData = () => {
    let { stocks_summary = {} } = props;

    const { data = {}, plan_data = {} } = stocks_summary;
    setsummaryData({
      stocks_summary: data,
      plan_data,
    });
  };

  return (
    <View style={styles.container}>
      {summaryData && (
        <View style={[styles.border, Platform.OS == "web" && webStyles.border]}>
          <View style={styles.headingContainer}>
            {/* Heading */}
            <Text style={styles.headingPortfolio}>Portfolio</Text>
            {props.toggleStatus == "NOT" ? null : (
              <ToggleSummaryView
                onValueChange={onToggleSwitch}
                value={isSwitchOn}
                color={"#410DAA"}
              />
            )}
          </View>
          <SummaryTable active={isSwitchOn} data={summaryData} />
        </View>
      )}
    </View>
  );
};

const webStyles = {
  border: {
    border: "1px solid #410DAA33",
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  border: {
    borderWidth: 1,
    borderColor: "#410DAA33",
    borderRadius: 20,
  },
  headingContainer: { flexDirection: "row", justifyContent: "space-between" },
  headingPortfolio: {
    color: "#494B69",
    fontSize: 16,
    backgroundColor: "#EEF4FF",
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingTop: 10,
    paddingBottom: 6,
    paddingLeft: 27,
    paddingRight: 48,
    alignSelf: "flex-start",
  },
  summaryRowContainer: {
    height: 200,
  },
  summaryContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 28,
    paddingRight: 28,
    paddingTop: 16,
    paddingBottom: 16,
  },
  summaryRow: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-around",
  },
  separator: {
    borderLeftWidth: 1,
    borderLeftColor: "#888C9F66",
  },
  separator2: {
    width: 1,
    height: "auto",
    backgroundColor: "#888C9F66",
  },
});

export default OldSummaryCard;
