import { View, Text, StyleSheet,Image, TouchableOpacity,
    ScrollView,
    ActivityIndicator, } from "react-native";
import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { Button } from "react-native-paper";
import { useFilePicker } from "use-file-picker";
import Toast from "react-native-toast-message";
import PickerFieldSelect from "./Pickers/PickerFieldSelect";
import { DataURIToBlob, isMobile, isWeb, selectOneFile } from "../../utils";
import { validateInput } from "../../utils/validateInput";
import { userFeedback } from "../../api/account";
import TextInputField from "./Inputs/InputField";
import Entypo from "@expo/vector-icons/Entypo";
  
  const data = [
    {
      label: "Select",
      value: "--",
    },
    {
      label: "App has a problem",
      value: "BUG",
    },
    {
      label: "Service Request",
      value: "SRV",
    },
    {
      label: "Could Not Place Order",
      value: "ORD",
    },
    {
      label: "Complaint",
      value: "CMP",
    },
    {
      label: "Fund Missing",
      value: "FMS",
    },
    {
      label: "Investment Transaction",
      value: "INV",
    },
    {
      label: "SIP Transaction",
      value: "SIP",
    },
    {
      label: "Withdrawal Transaction",
      value: "WDL",
    },
    {
      label: "Other",
      value: "OTH",
    },
  ];
  

  const SelectChequeLeaf = ({ onSelect, title, file, imageURL, showModal }) => {
    const { openFilePicker,filesContent, loading, errors } = useFilePicker({
      readAs: "DataURL",
      accept: [".png", ".jpg", ".jpeg", ".pdf"],
      multiple: true,
      limitFilesConfig: { max: 1 },
      maxFileSize: 0.5,
    });
  
    const openFile = (file) => {
      if (!file) return;
      imageURL(`https://jamaappprod.s3.amazonaws.com/media/${file}`);
      showModal(true);
    };
  
    useEffect(() => {
      onSelect(filesContent, errors);
    }, [filesContent]);
  
    if (loading) {
      return <Text>Loading</Text>;
    }
  
    const isFileSelected = () => {
      return filesContent.length != 0;
    };
  
    const checkSizeError = () => {
      if (errors && errors.length) {
        const error = errors[0];
        const { fileSizeToolarge } = error;
        return {
          fileSizeToolarge: true,
          error: true,
        };
      }
      return null;
    };
  
    const selectedFile = isFileSelected();
    const errorData = checkSizeError();
  
    const fileName = file ? file.slice(0, 50) : "  ";
    return (
      <View style={{ flexDirection: "column"}}>
        <Text style={{
          fontFamily: "Roboto",
          fontSize: 13,
          fontWeight: "700",
          color: "#464E5F",
          marginBottom: 11,
          zIndex: -1,
        }}>{title}</Text>
        <TouchableOpacity
          onPress={() => openFilePicker()}
          style={{
            alignItems: "center",
            paddingVertical: 10,
            backgroundColor: "#f6f6f6",
            borderRadius: 4,
            borderStyle: "dashed",
            borderWidth: 1,
            borderColor: "rgba(65, 13, 170, 0.5)",
          }}
        >
          <Text style={styles.drop}>
            <Entypo name="attachment" size={20} color="#13C37B" /> Click here to
            browse and attach files
          </Text>
        </TouchableOpacity>
        {selectedFile && (
          filesContent.map((file) => (
            <Text style={styles.fileName}>{file.name}</Text>
          ))
        ) }
        <Text
          style={{
            fontFamily: "Roboto",
            fontSize: 11,
            fontWeight: "500",
            color: "#464E5F",
            marginTop: 5,
            marginBottom: 11,
            zIndex: -1,
          }}
        >
          Supports PNG, JPG, JPEG.{" "}
          <Text style={[errorData && errorData.fileSizeToolarge && styles.error]}>
            Max File Size 500KB
          </Text>
        </Text>
      </View>
    );
  };
  
  
  class RateUs extends React.Component {
  
    constructor(props) {
      super(props);
      this.state = {
        defaultRating : 8,
        maxRating: [1,2,3,4,5,6,7,8,9,10],
        cornerStar: 'https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/star_corner.png',
        filledStar: 'https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/star_filled.png',
        selectedType: "--",
        summary: "",
        loading: false,
        file: null,
      }
    }
  
    onSubmitPress = async () => {
      const { selectedType, summary } = this.state;
      this.setState({
        loading: true,
      });
      try {
        const { file } = this.state;
        let payload;
        if (file != null || file != undefined) {
          const { content: imageURI, name: imageName } = this.state.file;
          
          let body = new FormData();
          const imageFile = DataURIToBlob(imageURI);
          body.append("file_type", "fund_imported");
          body.append("uploaded_excelname", imageName);
          body.append("uploaded_excel", imageFile, imageName);
          // console.log("Image Uploaded Data is ",body);
          payload = {
            feedback_type: selectedType,
            feedback_text: summary,
            feedback_rating: this.state.defaultRating,
            feedback_img: body,
          };
        }else{
          payload = {
            feedback_type: selectedType,
            feedback_text: summary,
            feedback_rating: this.state.defaultRating,
            feedback_img: "",
          };
        }
        const response = await userFeedback(payload);
        // console.log("response", response);
        if (response.code == 100) {
          Toast.show({
            text1: response.msg,
            topOffset: 7,
            visibilityTime: 900000,
            type: "success",
          });
        } else {
          Toast.show({
            text1: response.msg,
            topOffset: 7,
            visibilityTime: 900000,
            type: "success",
          });
        }
        this.props.navigation.navigate("App",{
          screen: "Portfolio",params: { 
            screen: "Notifications",
            params: {
              active: 2
            }
          }
        })
      } catch (e) {
      } finally {
        this.setState({
          loading: false,
        });
      }
    };
  
    handleChange = (value, type, inputType) => {
      this.setState({
        [type]: value,
        [type + "Error"]: validateInput(inputType, value),
        error: null,
      });
    };

   
    handleSkip = () => {
      this.props.navigation.navigate("App",{
        screen: "Portfolio",params: { 
          screen: "Notifications",
          params: {
            active: 2
          }
        }
      })
    }
    
    
    render() {
      const { selectedType, selectedTypeError, summary } = this.state;
      return (
        <View style={styles.container}>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <Text style={{
              fontFamily: "Roboto",
              fontSize: 18,
              fontWeight: "700",
              color: "#464E5F",
              marginBottom: 11,
              zIndex: -1,
              marginTop: 30,
              paddingLeft: 40,
            }}>Your mutual fund order payment have been updated</Text>
            <TouchableOpacity style={{paddingLeft: '50%',}} onPress={this.handleSkip}>
              <Text style={{
                fontFamily: "Roboto",
                fontSize: 18,
                fontWeight: "700",
                color: "#464E5F",
                marginBottom: 11,
                zIndex: -1,
                marginTop: 30,
              }}>Skip {">>>"}</Text>
            </TouchableOpacity>
          </View>
          <Text style={{
            fontFamily: "Roboto",
            fontSize: 13,
            fontWeight: "700",
            color: "#464E5F",
            marginBottom: 11,
            zIndex: -1,
            marginTop: 30,
            paddingLeft: 40,
          }}>Rate Your experience</Text>
          <View style={styles.CustomeRatingBar}>
            {
              this.state.maxRating.map((item,key)=> {
                return(
                  <TouchableOpacity activeOpacity={0.7} key={item} onPress={()=> this.setState({defaultRating: item})}>
                    <Image style={styles.styleStar} source={item <= this.state.defaultRating ? {uri: this.state.filledStar} : {uri: this.state.cornerStar}}/>
                  </TouchableOpacity>
                )})
            }
          </View>
          <ScrollView style={{width: '100%'}} contentContainerStyle={styles.wrapper}>
            {!isMobile ? (
              <>
                <View style={[styles.formItem, styles.itemMargin]}>
                  <TextInputField
                    numberOfLines={6}
                    label="Description (optional)"
                    value={summary}
                    style={[{ ...styles.inputItem, width: "50%",marginTop: 10 }]}
                    editable={true}
                    multiline={true}
                    onChange={(summary) =>
                      this.handleChange(summary, "summary", "text")
                    }
                  />
                </View>
                <View style={{marginTop: 10, display: 'flex', flexDirection: 'row'}}>
                  <View style={{margin: 10}}>
                  <PickerFieldSelect
                    data={data}
                    value={selectedType}
                    label={"Category (optional)"}
                    onChange={(selectedType) =>
                      this.handleChange(selectedType, "selectedType", "select")
                    }
                    pickerStyle={[{ ...styles.inputItem, width: 300, }]}
                    isValid={selectedTypeError}
                    type="select"
                    placeholder={{ value: "SEL", label: "Select" }}
                  />
                  </View>
                  <View style={{width: '25%', margin: 10}}>
                      <SelectChequeLeaf
                        imageURL={(imageURL) => {
                          let image = imageURL;
                          this.setState({ imageURL: image });
                        }}
                        showModal={() => {
                          this.setState({ showModal: true });
                        }}
                        onSelect={(filesContent) => {
                          if (filesContent && filesContent.length > 0) {
                            const { content: imageURI, name: imageName } =
                              filesContent[0];
                            this.setState({file: filesContent[0]});
                          }
                        }}
                        title={"Upload image (optional)"}
                        file={['image/jpeg', 'image/png', 'image/jpg']}
                      />
                      
                  </View>
                </View>
                
              </>
            ) : (
              <>
                <View style={[styles.formItem, styles.itemMargin]}>
                  <PickerFieldSelect
                    data={data}
                    value={selectedType}
                    label={"Issue Category"}
                    onChange={(selectedType) =>
                      this.handleChange(selectedType, "selectedType", "select")
                    }
                    pickerStyle={[{ ...styles.inputItem }]}
                    isValid={selectedTypeError}
                    type="select"
                    placeholder={{ value: "SEL", label: "Select" }}
                  />
                </View>
                <View style={[styles.formItem, styles.itemMargin]}>
                  <TextInputField
                    numberOfLines={6}
                    label="Issue Description"
                    value={summary}
                    style={[{ ...styles.inputItem }]}
                    editable={true}
                    multiline={true}
                    onChange={(summary) =>
                      this.handleChange(summary, "summary", "text")
                    }
                  />
                </View>
                <View>
                  <View style={{ padding: 10 }}></View>
                  <SelectChequeLeaf
                    imageURL={(imageURL) => {
                      let image = imageURL;
                      this.setState({ imageURL: image });
                    }}
                    showModal={() => {
                      this.setState({ showModal: true });
                    }}
                    onSelect={(filesContent) => {
                      if (filesContent && filesContent.length > 0) {
                        
                        this.setState({ file: filesContent[0] });
                      }
                    }}
                    title={"Upload image"}
                    file={['image/jpeg', 'image/png', 'image/jpg']}
                  />
                </View>
                <Text
                  style={{ color: "red", fontSize: 12, marginHorizontal: 16 }}
                >
                  {this.state.error ? `* ${this.state.error}` : null}
                </Text>
              </>
            )}
            {!this.state.loading ? (
              !isMobile ? (
                  <Button
                    uppercase={false}
                    mode={"contained"}
                    onPress={this.onSubmitPress}
                    style={{
                      marginTop: 50,
                      width: "20%",
                      marginHorizontal: 20
                    }}
                    color={"#410DAA"}
                  >
                    <Text style={styles.saveText}>Send Feedback</Text>
                  </Button>
              ) : (
                <Button
                  uppercase={false}
                  mode={"contained"}
                  onPress={this.onSubmitPress}
                  style={{
                    marginTop: 30,
                    width: "50%",
                    marginLeft: "25%",
                  }}
                  color={"#410DAA"}
                >
                  <Text style={styles.saveText}>Send Feedback</Text>
                </Button>
              )
            ) : (
              <ActivityIndicator size="large" color="#410DAA" />
            )}
          </ScrollView>
        </View>
      );
    }
  };
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: "#fff",
    },
    CustomeRatingBar: {
      flexDirection: 'row',
      marginTop: 10,
      paddingLeft: 30,
      marginBottom: 10
    },
    styleStar: {
      width: 40,
      height: 40,
      resizeMode: 'cover',
      margin: 5
    },
    createMandate: {
      fontSize: 12,
      color: "#410DAA",
      paddingVertical: 12,
    },
    selectMandate: {
      fontSize: 12,
    },
    wrapper: {
      marginHorizontal: 16,
      width: '100%',
      paddingLeft: 20
    },
    confirm: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: 18,
      color: "#121212",
      marginBottom: 11,
      marginLeft: 25,
      marginTop: 25,
    },
    sureContainer: {
      marginBottom: 44,
    },
    fileName: {
      fontSize: 12,
      marginTop: 5,
      color: "#410DAA",
      fontWeight: "bold",
    },
    sure: {
      fontFamily: "Roboto",
      fontSize: 15,
      color: "#121212",
    },
    image: { width: 19, height: 17, resizeMode: "contain", marginRight: 7 },
    mainContainer: {
      backgroundColor: "#FFFFFF",
      width: "100%",
    },
    total_pay: {
      fontFamily: "Roboto",
      fontSize: 16,
      lineHeight: 18,
      color: "#464E5F",
    },
    manage_schedule_button: {
      fontFamily: "Roboto",
      fontWeight: "normal",
      color: "#410DAA",
      backgroundColor: "#fff",
      borderColor: "#410DAA",
      borderWidth: 1,
      borderRadius: 4,
      paddingVertical: 10,
      paddingHorizontal: 30,
      marginRight: 11,
    },
    button_style: {
      padding: 2,
      borderRadius: 15,
      borderWidth: 1,
      borderColor: "#410DAA",
      marginRight: 6,
      overflow: "hidden",
    },
    make_pay: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: isWeb ? 15 : 13,
      color: "#FFFFFF",
      backgroundColor: "#410DAA",
      borderColor: "#410DAA",
      borderWidth: 1,
      borderRadius: 4,
      paddingVertical: 10,
      paddingHorizontal: 30,
    },
    make_pay1: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: 15,
      lineHeight: 17,
      color: "#FFFFFF",
      paddingVertical: 10,
    },
    graph_icon: {
      backgroundColor: "#1BC5BD",
      paddingVertical: 8,
      paddingHorizontal: 8,
      borderRadius: 20,
      marginRight: 10,
    },
    detailHeading: {
      color: "#121212",
      opacity: 0.5,
      fontStyle: "italic",
      fontSize: 11,
    },
    detailText: {
      color: "#121212",
      fontSize: 14,
      marginTop: 4,
      fontWeight: "bold",
    },
    clickHere: {
      color: "#410DAA",
      fontWeight: "bold",
      paddingLeft: 6,
      textAlign: "center",
    },
    grap: {
      flexDirection: isWeb ? "row" : "column",
      justifyContent: "space-between",
      backgroundColor: "rgba(226, 252, 249, 0.6)",
      borderRadius: isWeb ? 12 : 0,
      paddingLeft: isWeb ? 22 : 16,
      paddingRight: isWeb ? 22 : 16,
    },
    jew_port: {
      fontFamily: "Roboto",
      fontSize: isWeb ? 16 : 13,
      lineHeight: 21,
      color: "#464E5F",
      width: "94%",
      flexWrap: "wrap",
    },
    start: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: isWeb ? 15 : 14,
      lineHeight: 17,
      color: "#FFFFFF",
      textAlign: "center",
    },
    start_butt: {
      paddingVertical: 12,
      paddingHorizontal: 25,
      backgroundColor: "#16C4A9",
      borderRadius: 6,
      marginVertical: 17,
    },
    dte: {
      fontFamily: "Roboto",
      fontSize: 11,
      lineHeight: 13,
      color: "#888C9F",
    },
    inv_no: {
      fontFamily: "Roboto",
      fontSize: 12,
      lineHeight: 14,
      color: "#1155CC",
      textDecorationLine: "none",
      paddingLeft: 6,
    },
    plan_code: {
      fontFamily: "Roboto",
      fontSize: 13,
      lineHeight: 15,
      color: "#464E5F",
      fontFamily: "bold",
    },
    viewAgreement: {
      flexDirection: "row",
      alignItems: "center",
    },
    amount: {
      fontFamily: "Roboto",
      fontSize: 13,
      lineHeight: 15,
      color: "#121212",
      paddingTop: 10,
    },
    type: {
      color: "#1BC5BD",
      fontSize: 12,
      marginTop: 5,
    },
    invested_amount: {
      fontFamily: "Roboto",
      fontSize: 13,
      lineHeight: 15,
      color: "#121212",
    },
    invested: {
      fontFamily: "Roboto",
      fontSize: 12,
      lineHeight: 14,
      color: "#888C9F",
      paddingTop: 10,
    },
    invest_of: {
      fontFamily: "Roboto",
      fontSize: 13,
      lineHeight: 23,
      color: "#464E5F",
    },
    inve_amt: {
      fontWeight: "bold",
      color: "#121212",
      fontStyle: "italic",
    },
    line: {
      width: 5,
      height: 48,
      backgroundColor: "#6D39F5",
      borderRadius: 4,
      marginRight: 10,
    },
    ad_fee: {
      fontFamily: "Roboto",
      fontSize: 13,
      lineHeight: 14,
      color: "#888C9F",
    },
    dot: {
      height: 4,
      width: 4,
      backgroundColor: "#888C9F",
      borderRadius: 2,
      marginHorizontal: 6,
    },
  
    filtersContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    filter: {
      paddingHorizontal: 6,
      paddingVertical: 4,
      borderRadius: 4,
      borderWidth: 0.8,
      borderColor: "#f6f6f6",
      flexDirection: "row",
      alignItems: "center",
    },
    filterClose: {
      paddingLeft: 6,
    },
    filterText: {
      fontSize: 15,
      color: "gray",
      fontWeight: "200",
    },
    filtersTitle: {
      fontSize: 15,
      fontWeight: "bold",
      marginRight: 6,
      marginLeft: isMobile ? 16 : 0,
    },
  });
  
  export default RateUs;
  