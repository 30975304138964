import React, { Component } from "react";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";

export default class AlertCard extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { negativeButtonStyleContainer, positiveButtonStyleContainer } =
      this.props;

    return (
      <View
        style={[
          styles.modalContent,
          this.props.contentStyle ? { ...this.props.contentStyle } : {},
        ]}
      >
        <View style={{ marginBottom: 20 }}>{this.props.children}</View>
        {(this.props.negativeButtonPress || this.props.positiveButtonPress) && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 15,
            }}
          >
            <View
              style={[
                {
                  width: "50%",
                  alignItems: "flex-start",
                  justifyContent: "center",
                },
                negativeButtonStyleContainer
                  ? { ...negativeButtonStyleContainer }
                  : {},
              ]}
            >
              {this.props.negativeButtonPress && this.props.negativeButtonText && (
                <TouchableOpacity
                  onPress={() => {
                    this.props.negativeButtonPress();
                  }}
                  style={[
                    {
                      backgroundColor: "#410DAA",
                      width: 130,
                      paddingVertical: 8,
                      alignItems: "center",
                      justifyContent: "center",
                      borderWidth: 1,
                      borderColor: "#410DAA",
                      borderRadius: 5,
                    },
                    this.props.negativeButtonStyle
                      ? { ...this.props.negativeButtonStyle }
                      : {},
                  ]}
                >
                  <Text
                    style={{
                      color: "#fff",
                      fontSize: 16,
                      alignSelf: "center",
                      paddingHorizontal: 10,
                      textAlign: "center",
                    }}
                  >
                    {this.props.negativeButtonText || "No"}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
            <View
              style={[
                {
                  width: "50%",
                  marginLeft: 30,
                  alignItems: "flex-end",
                  justifyContent: "center",
                },
                positiveButtonStyleContainer
                  ? { ...positiveButtonStyleContainer }
                  : {},
              ]}
            >
              {this.props.positiveButtonPress && this.props.positiveButtonText && (
                <TouchableOpacity
                  onPress={() => {
                    this.props.positiveButtonPress();
                  }}
                  style={[
                    {
                      backgroundColor: "#fff",
                      paddingVertical: 8,
                      width: 130,
                      alignItems: "center",
                      justifyContent: "center",
                      borderWidth: 1,
                      borderColor: "#410DAA",
                      borderRadius: 5,
                    },
                    this.props.positiveButtonStyle
                      ? { ...this.props.positiveButtonStyle }
                      : {},
                  ]}
                >
                  <Text
                    style={{
                      color: "#410DAA",
                      fontSize: 16,
                      alignSelf: "center",
                      paddingHorizontal: 10,
                      textAlign: "center",
                    }}
                  >
                    {this.props.positiveButtonText || "Yes"}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  modalContent: {
    backgroundColor: "#fff",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)",
    margin: 16,
  },
});
