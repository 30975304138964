import React from "react";

import {
  View,
  ScrollView,
  SafeAreaView,
  Image,
  Platform,
  StyleSheet,
  StatusBar,
  Dimensions,
} from "react-native";
import amplitude from "../../../utils/amplitude";
import { Button, Text, RadioButton } from "react-native-paper";
import { connect } from "react-redux";
import { isMobile } from "../../../utils";
import { updateLastSeenScreen } from "../../../actions/finplan";
import theme from "../../../static/theme";
import { getFinplanDetails, postChildPG } from "../../../api/finplan";
import NavigationHeader from "../../../components/Common/NavigationHeader";
const { width } = Dimensions.get("window");

class childrenBachelors extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let total = null,
      type = null;
    if (params) {
      total = params.total || null;
      total = isNaN(total) ? 0 : Number(total);
      type = params.type || null;
    }
    this.state = {
      type,
      total,
      isLoading: false,
      childrenDetails: [],
      formErrors: {},
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("childrenPg",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("childrenPG", { path: this.props.navigation.getState().routeName });
      }
      const options = {
        type: "children",
      };
      let data = [];
      let childrenData = [];
      const response = await getFinplanDetails(options);
      if (response.code == 100) {
        const { results } = response;
        const { children = [] } = results;
        childrenData = [...children.splice(0, this.state.total)];
      } else {
        childrenData = [...Array(this.state.total)].fill(null);
      }
      childrenData.forEach((child, index) => {
        data.push({
          name: child
            ? child["name_of_mapped_family_member"]
            : "Child " + (index + 1),
          pg: child ? child["country_for_pg_studies"] : null,
        });
      });

      this.setState({
        childrenDetails: [...data],
      });
    } catch (error) {}
  }

  onPressNext = async () => {
    try {
      const { type } = this.state;
      const { childrenDetails = [], total = 0 } = this.state;
      let flag = false;
      let names = "",
        edu_values = "";
      const values = ["IND", "USC", "OTH", "NOP"];
      let formErrors = {};
      for (let i = 0; i < total; i++) {
        formErrors[`${i}`] = {};
        const children = childrenDetails[i];
        if (children && children.pg) {
          flag = false;
          names += `${children.name}`;
          if (i < total - 1) {
            names += ";";
          }
          edu_values += `${children.pg};`;
        } else {
          flag = true;
          formErrors[`${i}`]["error"] = "Select any one option";
        }
      }

      if (flag) {
        this.setState({ formErrors });
      } else {
        this.setState({ isLoading: true });
        const payload = {
          names,
          edu_values,
        };
        const response = await postChildPG(payload);
        if (response && response.code == 100) {
          this.setState({ isLoading: false, formErrors: {} });
          this.props.navigation.navigate("ChildrenWedding", {
            total,
            type,
          });
        } else {
          this.setState({ isLoading: false, formErrors: {} });
        }
      }
    } catch (error) {}
  };

  setRadioValue = (index, value) => {
    let childrenDetails = Object.assign([], this.state.childrenDetails);
    childrenDetails[index].pg = value;
    this.setState({ childrenDetails });
  };

  clearError = (index) => {
    let formErrors = { ...this.state.formErrors };
    if (!(index in formErrors)) {
      formErrors[`${index}`] = {};
    }
    formErrors[`${index}`]["error"] = null;
    this.setState({ formErrors });
  };

  render() {
    const { childrenDetails = [], formErrors } = this.state;
    return (
      <View style={styles.page}>
        <SafeAreaView style={{ flex: 1 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Children PG"]}
            onPressBack={() => {
              this.props.navigation.goBack();
            }}
          />
          <ScrollView>
            <View style={isMobile ? {justifyContent: 'center', alignItems: 'center'} :{ marginLeft: "30%" }}>
              <ScrollView
                showsVerticalScrollIndicator={true}
                contentContainerStyle={{
                  paddingBottom: 100,
                  marginTop: 50,
                  alignItems: isMobile ? "center" : "flex-start",
                }}
              >
                <View style={{ flex: 1, marginHorizontal: 16 }}>
                  <StatusBar
                    backgroundColor="#410DAA"
                    barStyle="light-content"
                  />
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/child_grad_finplan_image.png",
                    }}
                    style={{
                      width: 130,
                      height: 100,
                      alignSelf: "center",
                      marginTop: 20,
                    }}
                    resizeMode="contain"
                  />

                  <View
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      marginTop: 40,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        marginBottom: 20,
                        alignSelf: "center",
                        textAlign: "center",
                        color: "#121212",
                        fontWeight: "bold",
                      }}
                    >
                      How would you plan their Post Graduation?
                    </Text>
                    {childrenDetails.map((children, index) => {
                      return (
                        <View
                          key={index}
                          style={styles.childrenDetailsContainer}
                        >
                          <Text
                            style={{
                              fontSipaddingVertical: 10,
                              alignSelf: "center",
                              fontSize: 14,
                              color: "#121212",
                            }}
                          >
                            For{" "}
                            <Text
                              style={{
                                color: "#121212",
                                fontWeight: "bold",
                              }}
                            >
                              {children.name}
                            </Text>
                          </Text>
                          <View style={{ flexDirection: "column" }}>
                            <View style={styles.radioButtonContainer}>
                              <RadioButton.Android
                                onPress={() => {
                                  this.setRadioValue(index, "IND");
                                  this.clearError(index);
                                }}
                                uncheckedColor={"#183443"}
                                color={theme.colors.NEW_BRAND_COLOR}
                                status={
                                  this.state.childrenDetails[index].pg == "IND"
                                    ? "checked"
                                    : "unchecked"
                                }
                                style={{ marginRight: 20 }}
                              />
                              <Text
                                style={{
                                  textAlignVertical: "center",
                                  color:
                                    this.state.childrenDetails[index].pg ==
                                    "IND"
                                      ? theme.colors.NEW_BRAND_COLOR
                                      : "#121212",
                                  fontSize: 14,
                                }}
                                onPress={() => {
                                  this.setRadioValue(index, "IND");
                                  this.clearError(index);
                                }}
                              >
                                5 Lakhs/Year ( India )
                              </Text>
                            </View>
                            <View style={styles.radioButtonContainer}>
                              <RadioButton.Android
                                onPress={() => {
                                  this.setRadioValue(index, "OTH");
                                  this.clearError(index);
                                }}
                                uncheckedColor={"#183443"}
                                color={theme.colors.NEW_BRAND_COLOR}
                                status={
                                  this.state.childrenDetails[index].pg == "OTH"
                                    ? "checked"
                                    : "unchecked"
                                }
                                style={{ marginRight: 20 }}
                              />
                              <Text
                                style={{
                                  textAlignVertical: "center",
                                  color:
                                    this.state.childrenDetails[index].pg ==
                                    "OTH"
                                      ? theme.colors.NEW_BRAND_COLOR
                                      : "#121212",
                                  fontSize: 14,
                                }}
                                onPress={() => {
                                  this.setRadioValue(index, "OTH");
                                  this.clearError(index);
                                }}
                              >
                                15 Lakhs/Year ( Canada, Europe, Australia, NZ )
                              </Text>
                            </View>
                            <View style={styles.radioButtonContainer}>
                              <RadioButton.Android
                                onPress={() => {
                                  this.setRadioValue(index, "USC");
                                  this.clearError(index);
                                }}
                                uncheckedColor={"#183443"}
                                color={theme.colors.NEW_BRAND_COLOR}
                                status={
                                  this.state.childrenDetails[index].pg == "USC"
                                    ? "checked"
                                    : "unchecked"
                                }
                                style={{ marginRight: 20 }}
                              />
                              <Text
                                style={{
                                  textAlignVertical: "center",
                                  color:
                                    this.state.childrenDetails[index].pg ==
                                    "USC"
                                      ? theme.colors.NEW_BRAND_COLOR
                                      : "#121212",
                                  fontSize: 14,
                                }}
                                onPress={() => {
                                  this.setRadioValue(index, "USC");
                                  this.clearError(index);
                                }}
                              >
                                40 Lakhs/Year ( USA, Cities like London )
                              </Text>
                            </View>

                            <View style={styles.radioButtonContainer}>
                              <RadioButton.Android
                                onPress={() => {
                                  this.setRadioValue(index, "NOP");
                                  this.clearError(index);
                                }}
                                uncheckedColor={"#183443"}
                                color={theme.colors.NEW_BRAND_COLOR}
                                status={
                                  this.state.childrenDetails[index].pg == "NOP"
                                    ? "checked"
                                    : "unchecked"
                                }
                                style={{ marginRight: 20 }}
                              />
                              <Text
                                style={{
                                  textAlignVertical: "center",
                                  color:
                                    this.state.childrenDetails[index].pg ==
                                    "NOP"
                                      ? theme.colors.NEW_BRAND_COLOR
                                      : "#121212",
                                  fontSize: 14,
                                }}
                                onPress={() => {
                                  this.setRadioValue(index, "NOP");
                                  this.clearError(index);
                                }}
                              >
                                Not Planned
                              </Text>
                            </View>

                            <Text style={[styles.errorMessage]}>
                              {formErrors &&
                              formErrors[index] &&
                              formErrors[index].error
                                ? formErrors[index].error
                                : ""}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
                <Button
                  uppercase={false}
                  mode={"contained"}
                  loading={this.state.isLoading}
                  onPress={this.onPressNext}
                  style={styles.nextButton}
                  color="#410DAA"
                >
                  <Text style={styles.next}>Next</Text>
                </Button>
              </ScrollView>
            </View>
          </ScrollView>
        </SafeAreaView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingBottom: 66,
    width: width,
    height: "100%",
  },
  formItem: {},
  inputItem: {
    width: isMobile ? "100%" : "auto",
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  nextButton: {
    width: width < 768 ? "96%" : "15%",
    marginLeft: isMobile ? 5 : "7%",
    marginTop: 30,
    marginBottom: 0,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  radioButtonContainer: {
    flexDirection: "row",
    paddingVertical: 5,
    alignItems: "center",
  },
  childrenDetailsContainer: {
    marginBottom: 20,
  },
  errorMessage: {
    color: "red",
    fontSize: 14,
    paddingVertical: 5,
    fontFamily: "Roboto",
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateLastSeenScreen: (type, screen, basic, end) =>
      dispatch(updateLastSeenScreen(type, screen, basic, end)),
  };
};

export default connect(null, mapDispatchToProps)(childrenBachelors);
