import { StackActions } from "@react-navigation/routers";
import React from "react";
import { View, TouchableOpacity, Image } from "react-native";
import { Text } from "react-native-paper";
import AntIcon from "@expo/vector-icons/AntDesign";
import { connect } from "react-redux";
import theme from "../../static/theme/index.js";

const getVolatality = (status) => {
  const statuses = {
    LOW: "Low",
    Low: "Low",
    "Moderately Low": "Moderately Low",
    Moderate: "Moderate",
    "Moderate High": "Moderate High",
    High: "High",
    MLW: "Moderately Low",
    MOD: "Moderate",
    MDH: "Moderate High",
    HGH: "High",
    "MODERATELY HIGH": "Moderate High",
    "MODERATELY LOW": "Moderate Low",
    HIGH: "High",
  };
  return status in statuses ? statuses[status] : "-";
};

const Card = ({
  style,
  fund_name,
  growth_value,
  growth_duration,
  return_percentage,
  fund_size,
  volatality,
  amc_fund_id,
  is_jama_suggested,
  navigation,
  fundId,
  config,
  returnTitle,
}) => {
  const growth = growth_value > 0;

  return (
    <View
      style={{
        borderColor: "#410DAA1A",
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
        marginVertical: 10,
        ...style,
      }}
    >
      {is_jama_suggested && (
        <View
          style={{
            width: 15,
            height: 20,
            position: "absolute",
            top: -1,
            right: 35,
            backgroundColor: "#410DAA",
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            source={require("../../assets/jama_suggest_tag.png")}
            style={{
              width: 12,
              height: 16,
              alignSelf: "center",
              borderRadius: 4,
            }}
            resizeMode={"contain"}
          />
        </View>
      )}
      <TouchableOpacity
        style={{ width: "70%" }}
        onPress={() => {
          navigation.navigate("FundDescription", {
            fund: fundId,
            fund_amc_id: amc_fund_id
          });
        }}
      >
        <Text
          style={{
            fontSize: 14,
            color: "#121212",
            fontWeight: "bold",
          }}
        >
          {fund_name}
        </Text>
      </TouchableOpacity>
      {growth_value != null && growth_value != "-" && !isNaN(growth_value) ? (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 5,
          }}
        >
          <AntIcon
            name={growth ? "arrowup" : "arrowdown"}
            style={{ fontSize: 10, color: growth > 0 ? "green" : "#EF5D12" }}
          />
          <Text
            style={{
              paddingLeft: 0,
              fontSize: 11,
              color: growth > 0 ? "green" : "#EF5D12",
            }}
          >
            {growth_value != null && growth_value != "-" && !isNaN(growth_value)
              ? parseFloat(growth_value).toFixed(2)
              : "-"}
          </Text>
          <Text
            style={{
              paddingLeft: 5,
              fontSize: 10,
              fontStyle: "italic",
              color: "#707070",
            }}
          >
            {growth_duration}
          </Text>
        </View>
      ) : (
        <></>
      )}

      <View style={{ flexDirection: "row", paddingTop: 10 }}>
        <Text
          style={{
            flex: 0.33,
            fontSize: 12,
            color: theme.colors.NEW_BRAND_COLOR,
            fontWeight: "bold",
          }}
        >
          {return_percentage != null &&
          return_percentage != "-" &&
          !isNaN(return_percentage)
            ? parseFloat(return_percentage).toFixed(1) + "%"
            : "-"}
        </Text>
        <Text
          style={{
            flex: 0.33,
            fontSize: 12,
            color: "#121212",
            textAlign: "left",
          }}
        >
          {fund_size != null && fund_size != "-" && !isNaN(fund_size)
            ? "₹" + parseFloat(fund_size).toFixed(2) + " Cr"
            : "-"}
        </Text>
        <Text
          style={{
            flex: 0.33,
            fontSize: 12,
            color: "#009105",
            textAlign: "center",
          }}
        >
          {getVolatality(volatality) !== "-"
            ? getVolatality(volatality)
            : volatality
            ? volatality
            : "-"}
        </Text>
      </View>
      <View style={{ flexDirection: "row", paddingTop: 5 }}>
        <Text
          style={{
            flex: 0.33,
            fontSize: 12,
            color: "#707070",
          }}
        >
          Return
          {returnTitle && (
            <Text
              style={{
                fontSize: 9,
                color: "#707070",
              }}
            >
              {returnTitle ? ` (${returnTitle})` : ""}
            </Text>
          )}
        </Text>
        <Text
          style={{
            flex: 0.33,
            fontSize: 12,
            color: "#707070",
            textAlign: "left",
          }}
        >
          Fund Size
        </Text>
        <Text
          style={{
            flex: 0.33,
            fontSize: 12,
            color: "#707070",
            textAlign: "center",
          }}
        >
          Volatality
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          paddingTop: 10,
        }}
      >
        <TouchableOpacity
          rippleContainerBorderRadius={18}
          onPress={() => {
            if (config) {
            }
            // console.log("fund is %s and amc fund id is %s", fundId, amc_fund_id)
            navigation.replace("App", {
              screen: "Dashboard",
              params: {
                screen: "Explore",
                params: {
                  screen: "FundDescription",
                  params: {
                    fund: fundId,
                    fund_amc_id: amc_fund_id
                  },
                },
              },
            });
          }}
          style={{
            backgroundColor: "#410DAA1A",
            borderRadius: 18,
            justifyContent: "center",
            alignItems: "center",
            width: 130,
            height: 35,
          }}
        >
          <Text style={{ fontSize: 14, color: "#410DAA" }}>Invest Now</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export const FundCard = connect(null)(Card);
