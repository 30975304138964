import axiosApiInstance from "./apiConfig";
import { URLS } from "./urls";

export const checkPANKYCStatus = async (payload) => {
  const result = await axiosApiInstance.post(URLS.CHECK_PAN_KYC, payload);
  return result;
};

export const checkOrFetchPAN = async (payload) => {
  const url = URLS.GET_CUSTOMER_DATA_KYC;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const getBankData = async () => {
  const result = await axiosApiInstance.get(URLS.CUSTOMER_BANK_ACCOUNTS);
  return result;
};

export const setDefaultBankData = async (payload, bank_account_id) => {
  const url = URLS.CUSTOMER_BANK_ACCOUNTS + bank_account_id + "/";
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const unsetDefaultBankData = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.CUSTOMER_BANK_ACCOUNTS,
    payload
  );
  return result;
};

export const getDigioKycStatus = async () => {
  const result = await axiosApiInstance.get(URLS.DIGIO_KYC_STATUS);
  return result;
};

export const getRiskProfileQuestions = async () => {
  const result = await axiosApiInstance.get(URLS.RISKPROFILE_QNA);
  return result;
};

export const saveRiskProfileAnswers = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.RISKPROFILE_SAVE_ANSWERS,
    payload
  );
  return result;
};

export const saveCustomerData = async (customer_id, data) => {
  const url = URLS.GET_CUSTOMER_DETAILS + customer_id + "/";
  const result = await axiosApiInstance.patch(url, data);
  return result;
};

export const saveCustomerProfileData = async (customer_id, data) => {
  const url = URLS.GET_CUSTOMER_PROFILE_DETAILS + customer_id + "/";
  const result = await axiosApiInstance.patch(url, data);
  return result;
};

export const getKarvyUrl = async () => {
  const result = await axiosApiInstance.get(URLS.GET_KARVY_URL);
  return result;
};

export const getMySIPs = async (userId) => {
  const url = URLS.MY_SIPS_URL + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getSIPOrderDetails = async (userId, data) => {
  const url = URLS.GET_SIP_ORDER_DETAILS + (userId ? userId + "/" : "");
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const redeemSIP = async (data) => {
  const url = URLS.REDEEM_SIP;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const cancelSIPOrder = async (data) => {
  const url = URLS.BSE_SIP_CANCEL_ORDER_URL;
  const result = await axiosApiInstance.post(url, data);
  return result;
};

export const getPassbookDetails = async () => {
  const result = await axiosApiInstance.get(URLS.MY_PASSBOOK);
  return result;
};

export const userFeedback = async (payload) => {
  const result = await axiosApiInstance.post(URLS.FEEDBACK, payload);
  return result;
};

export const setPassword = async (payload) => {
  const result = await axiosApiInstance.post(URLS.SET_PASSWORD, payload);
  return result;
};

export const createKycRequest = async (payload) => {
  const result = await axiosApiInstance.post(URLS.CREATE_KYC_REQUEST, payload);
  return result;
};

export const updateKYCRequestStatus = async () => {
  const result = await axiosApiInstance.get(URLS.UPDATE_KYC_REQUEST_STATUS);
  return result;
};
