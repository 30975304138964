
import React from 'react';
import {View, Text, StyleSheet} from 'react-native';

const TransactionTypeComponent = ({ type }) => {
    return(
        <View style={[transaction.typeContainer, transaction.type, type == "B" ? transaction.buyContainer : transaction.sellContainer] }>
            <Text style={[transaction.type, type == "B" ? transaction.buy : transaction.sell ]}>{type == "B" ? "Buy" : "Sell"}</Text>
        </View>
    )
};

const transaction = StyleSheet.create({
    typeContainer: {
        paddingHorizontal: 14,
        paddingVertical: 4,
        borderRadius: 3
    },
    buyContainer: {
        backgroundColor: "#E8F9F8"
    },
    sellContainer: {
        backgroundColor: "#DA222D40"
    },
    type: {
        fontSize: 13,
        fontFamily: "Roboto",
    },
    buy: {
        color: "#1BC5BD",
    },
    sell: {
        color: "#DA222D",
    }
})

const TransactionType = React.memo(TransactionTypeComponent);

export default TransactionType;