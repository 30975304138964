import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Picker,
  Dimensions,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Platform,
  StatusBar,
} from "react-native";
import { md } from "../../../Responsive";
import { nomineeInfo, getCustomerDetails } from "../../../api";
import Header from "../header_after_login";
import {
  completeMinDetails,
  getUserDetails,
  getWindowSize,
  isMobile,
  isWeb,
  navigateToTransaction,
} from "../../../utils";
const { width } = Dimensions.get("window");
import Progressbar from "../progressbar";
import { ShowToast } from "../../../components/Common/Toast";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import { SafeAreaView } from "react-native-safe-area-context";
import Store from "../../../store";

const relations = [
  {
    key: 1,
    label: "AUNT",
    value: "01",
  },
  {
    key: 2,
    label: "BROTHER-IN-LAW",
    value: "02",
  },
  {
    key: 3,
    label: "BROTHER",
    value: "03",
  },
  {
    key: 4,
    label: "DAUGHTER",
    value: "04",
  },
  {
    key: 5,
    label: "DAUGHTER-IN-LAW",
    value: "05",
  },
  {
    key: 6,
    label: "FATHER",
    value: "06",
  },
  {
    key: 7,
    label: "FATHER-IN-LAW",
    value: "07",
  },
  {
    key: 8,
    label: "GRAND DAUGHTER",
    value: "08",
  },
  {
    key: 9,
    value: "GRAND FATHER",
    label: "09",
  },
  {
    key: 10,
    label: "GRAND MOTHER",
    value: "10",
  },
  {
    key: 11,
    label: "GRAND SON",
    value: "11",
  },
  {
    key: 12,
    label: "MOTHER-IN-LAW",
    value: "12",
  },
  {
    key: 13,
    label: "MOTHER",
    value: "13",
  },
  {
    key: 14,
    label: "NEPHEW",
    value: "14",
  },
  {
    key: 15,
    label: "NIECE",
    value: "15",
  },
  {
    key: 16,
    label: "SISTER",
    value: "16",
  },
  {
    key: 17,
    label: "SISTER-IN-LAW",
    value: "17",
  },
  {
    key: 18,
    label: "SON",
    value: "18",
  },
  {
    key: 19,
    label: "SON-IN-LAW",
    value: "19",
  },
  {
    key: 20,
    label: "SPOUSE",
    value: "20",
  },
  {
    key: 21,
    label: "UNCLE",
    value: "21",
  },
  {
    key: 22,
    label: "OTHERS",
    value: "22",
  },
];

export default class nominee_info extends React.Component {
  constructor(props) {
    super(props);
    let username = null;
    let cust_id = null;
    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      username = params.username;
      cust_id = params.customer_id;
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
    }
    this.state = {
      relations,
      data: null,
      user_id,
      customer_nominee_name: null,
      customer_nominee_relation: null,
      relation: null,
      nomineenameError: null,
      nomineerelationError: null,
      isLoading: false,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoadingData: true });
      let userDetails = getUserDetails()
      const userIdToFetchDetails = Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
      ? Store.getState().dashboard.viewModeUserId.user_id
      : String(userDetails.customer_id);
      const response = await getCustomerDetails(userIdToFetchDetails);
      if (response && response.pk != null) {
        let { customer_nominee_name, customer_nominee_relation } = response;

        this.setState({
          customer_nominee_name,
          selectedPicker: customer_nominee_relation,
          customer_nominee_relation,
        });
      }
      this.setState({ isLoadingData: false });
    } catch (error) {
      this.setState({
        isLoadingData: true,
      });
    }
  }
  handleContinue = async () => {
    let {
      customer_nominee_name,
      customer_nominee_relation,
      cust_id,
      username,
    } = this.state;
    try {
      if (this.state.user_id) {
        let options = {};
        const { params } = this.props.route;
        if (params) {
          options = { ...params };
        }
        options["first"] = false;
        this.props.navigation.navigate("FinancialInfo", { ...options });
        return;
      }

      this.setState({
        isLoading: true,
        nomineenameError: null,
        nomineerelationError: null,
      });
      if (!cust_id || !username) {
        const userData = getUserDetails();
        username = userData.username;
        cust_id = userData.customer_id;
      }
      if (!customer_nominee_name) {
        this.setState({
          nomineenameError: "Plesae enter your nominee name",
          isLoading: false,
        });
        return;
      }
      // const pattern = /^[a-zA-Z]+$/;
      // if (!pattern.test(customer_nominee_name)) {
      //   this.setState({
      //     nomineenameError: "Plesae enter valid nominee name",
      //     isLoading: false,
      //   });
      //   return;
      // }
      if (!customer_nominee_relation) {
        this.setState({
          nomineerelationError: "Please select your nominee Relation",
          isLoading: false,
        });
        return;
      }

      const data = {
        username: username,
        customer_nominee_name: customer_nominee_name,
        customer_nominee_relation: customer_nominee_relation,
      };

      const response = await nomineeInfo(cust_id, data);

      if (response && response.pk != null) {
        const minDetailsResponse = await completeMinDetails();
        const { needCompletion, error } = minDetailsResponse;

        if (!error) {
          const { redirect_screen } = minDetailsResponse;
          const { params } = this.props.route;
          let options = {};
          if (this.props.route) {
            const { params } = this.props.route;
            if (params) {
              options = { ...params };
            }
            if (!needCompletion) {
              if (options.transaction) {
                navigateToTransaction(
                  this.props.navigation,
                  options.transaction
                );
              } else if (options.action) {
                this.props.navigation.navigate("ComplianceSuccess", {
                  ...options,
                });
                return;
              } else {
                this.props.navigation.navigate("OnboardingSuccess", {
                  ...options,
                });
                return;
              }
            }
          }
          this.props.navigation.navigate(redirect_screen, { ...options });
          this.setState({ isLoading: false });
        } else {
          this.setState({
            error: true,
            errorText: "Something went wrong! Please contact help@maxiom.in",
          });
        }
      } else {
        this.setState({
          error: true,
          errorText: response && response.msg ? response.msg : null,
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    }
  };

  handlePickerChange = (field, value) => {
    this.setState({
      customer_nominee_relation: value,
      selectedPicker: value,
    });
  };

  render() {
    const {
      customer_nominee_relation,
      nomineenameError,
      nomineerelationError,
      isLoading,
    } = this.state;
    return (
      <SafeAreaView style={styles.container}>
        <StatusBar backgroundColor="#000" barStyle="dark-content" />
        <Header navigation={this.props.navigation} route={this.props.route} />
        <Progressbar
          color2="#410DAA"
          color3="#410daa"
          color4="#f6f6f6"
          color5="#f6f6f6"
          color6="#f6f6f6"
          color7="#f6f6f6"
          color8="#f6f6f6"
          color9="#f6f6f6"
          color10="#f6f6f6"
          color11="#f6f6f6"
          color12="#f6f6f6"
          bcolor1="#410DAA"
          bcolor2="#f6f6f6"
          bcolor3="#f6f6f6"
          bcolor4="#f6f6f6"
          bcolor5="#f6f6f6"
          bagcolor1="#410DAA"
          bagcolor2="#ffffff"
          bagcolor3="#ffffff"
          bagcolor4="#ffffff"
          bagcolor5="#ffffff"
          markdisplay1="none"
          markdisplay2="none"
          markdisplay3="none"
          markdisplay4="none"
          markdisplay5="none"
          dotdisplay1="none"
          dotdisplay2="none"
          dotdisplay3="none"
          dotdisplay4="none"
          dotdisplay5="none"
        />
        <ScrollView style={styles.mainContainer}>
          <View style={styles.body}>
            {isWeb && (
              <View style={styles.left}>
                <Text style={styles.terms}>Contact Information</Text>
                <Text style={styles.please}>
                  Required per regulation, for continuity of service
                </Text>
                <Image
                  source={
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3.png"
                  }
                  style={styles.left_img}
                />
                <View
                  style={{
                    flexDirection: "row",
                    width: 430,
                    borderWidth: 1,
                    borderColor: "rgba(65, 13, 170, 0.2)",
                    backgroundColor: "#ffffff",
                    paddingVertical: 21,
                    paddingLeft: 20,
                    paddingRight: 6,
                    marginTop: 33,
                    marginBottom: 22,
                  }}
                >
                  <Image
                    source={
                      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/fluent_lock-shield-20-regular.png"
                    }
                    style={styles.lock}
                  />
                  <Text style={styles.jam_we}>
                    Information is encrypted using 256-bit keys and stored
                    securely
                  </Text>
                </View>
                <Text style={styles.any}>
                  Any Queries?{" "}
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("App", {
                        screen: "Dashboard",
                        params: {
                          screen: "Dashboard",
                          params: {
                            screen: "GetHelp",
                            params: {},
                          },
                        },
                      });
                    }}
                  >
                    <Text style={{ color: "#410DAA", fontWeight: "bold" }}>
                      Get Help
                    </Text>
                  </TouchableOpacity>
                </Text>
              </View>
            )}
            <View style={styles.right}>
              <View style={styles.mob_styl}>
                <View style={{ display: md(width) ? "none" : "flex" }}>
                  <Text style={styles.terms}>Nominee Information</Text>
                  <Text style={styles.please}>
                    Required per regulation, for continuity of service
                  </Text>
                </View>
                <Text style={styles.nominee_dts}>Nominee Details</Text>
                <Text style={styles.name}>Nominee Name*</Text>
                <TextInput
                  value={this.state.customer_nominee_name}
                  placeholder={"Enter Nominee Name"}
                  //placeholderTextColor={"#00000030"}
                  style={styles.nam_inp}
                  onChangeText={(text) => {
                    this.setState({ customer_nominee_name: text });
                  }}
                />
                <Text
                  style={[styles.please1, !!nomineenameError && styles.error]}
                >
                  {nomineenameError}
                </Text>
                <Text style={[styles.name]}>Relation*</Text>
                <View style={{ flexDirection: "row" }}>
                  <PickerFieldSelect
                    data={relations}
                    value={this.state.selectedPicker}
                    style={{
                      borderColor: "none",
                      borderRadius: 4,
                      borderWidth: 0,
                      backgroundColor: "#f6f6f6",
                      paddingVertical: isMobile
                        ? Platform.OS == "ios"
                          ? 12
                          : 0
                        : 12,
                      paddingHorizontal: 19,
                      color: "#121212",
                      fontFamily: "Roboto",
                      fontSize: md(width) ? 12 : 13,
                      lineHeight: md(width) ? 14 : 15,
                      width: isMobile ? getWindowSize().width * 0.9 : 677,
                    }}
                    onChange={(itemValue) => {
                      this.handlePickerChange("relationship", itemValue);
                    }}
                    pickerStyle={[{}]}
                    type="select"
                    placeholder={{
                      label: "Select Relation",
                      value: null,
                      color: "#9EA0A4",
                    }}
                  />
                  <View
                    style={{
                      width: 10,
                      height: 40,
                      backgroundColor: "#f6f6f6",
                    }}
                  ></View>
                </View>
                <Text
                  style={[styles.please1, nomineerelationError && styles.error]}
                >
                  {customer_nominee_relation ? "" : nomineerelationError}
                </Text>
                {isLoading ? (
                  <ActivityIndicator
                    size="large"
                    color="#410DAA"
                    style={{ paddingTop: 40 }}
                  />
                ) : (Store.getState().dashboard &&
                Store.getState().dashboard.viewModeUserId &&
                Store.getState().dashboard.viewModeUserId.user_id) ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        this.handleContinue();
                      }}
                    >
                      <Text style={styles.viewModeText}>Next {">>>"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        let url = new URL(global.location.href);
                        location.replace(
                          url.origin +
                            "/dashboard/home?user_id=" +
                            this.state.user_id
                        );
                      }}
                    >
                      <Text style={styles.viewModeText}>
                        Go to Dashboard {">>>"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      this.handleContinue();
                    }}
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Text style={styles.continue}>Continue</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1, backgroundColor: "#fff" },
  mainContainer: {},
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: md(width) ? 130 : 16,
    paddingRight: md(width) ? 150 : 16,
    paddingBottom: 60,
  },
  left: {
    flex: 0.45,
    paddingTop: 55,
    // display: md(width) ? "none" : "none",
  },
  right: {
    flex: md(width) ? 0.55 : 1,
    paddingTop: md(width) ? 57 : 20,
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },

  lock: {
    resizeMode: "contain",
    width: 30,
    height: 30,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 18 : 16,
    lineHeight: md(width) ? 21 : 17,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 15 : 14,
    lineHeight: 17,
    color: "#464E5F",
    paddingTop: 12,
    paddingBottom: 32,
    paddingRight: md(width) ? 20 : 0,
    width: "80%",
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  nominee_dts: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 18 : 15,
    lineHeight: md(width) ? 21 : 17,
    color: "#2F3044",
    fontWeight: "bold",
    paddingBottom: 21,
    display: isMobile ? "none" : "block",
  },
  name: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 16 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    fontWeight: "bold",
    paddingBottom: 6.5,
  },
  nam_inp: {
    backgroundColor: "#f6f6f6",
    paddingVertical: isMobile ? 6 : 13.5,
    paddingHorizontal: 19.5,
    borderRadius: 4,
    fontFamily: "Roboto",
    fontSize: md(width) ? 14 : 13,
    lineHeight: md(width) ? 16 : 15,
    color: "#121212",
  },
  please1: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3.3,
    paddingBottom: 27,
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 18,
    marginTop: 20,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  continue: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    paddingVertical: 11.5,
    paddingHorizontal: 63.5,
    backgroundColor: "#410DAA",
    borderRadius: 4,
    marginTop: md(width) ? 30 : 20,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.3,
  },
});
