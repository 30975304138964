import React from "react";
import { View, Text, Dimensions } from "react-native";
import { md } from "../../Responsive";
import { colors } from "../Common/Theme";
const window = Dimensions.get("window");

export default function DashboardField({
  title,
  subTitle = null,
  value,
  change = null,
  border = true,
  style = {},
  containerStyle = {},
  toolTip = null,
}) {
  return (
    <View
      style={[
        {
          alignSelf: "flex-start",
          minWidth: md(window.width) ? 150 : null,
          paddingHorizontal: 5,
          paddingVertical: 10,
          marginVertical: 4,
        },
        border ? { borderLeftWidth: 1, borderLeftColor: "#888C9F40" } : {},
        style,
      ]}
    >
      <View style={{ paddingHorizontal: 20, ...containerStyle }}>
        <Text
          style={{
            fontSize: md(window.width) ? 14 : 12,
            color: "#6C7293",
            fontWeight: "normal",
            marginBottom: 6,
          }}
        >
          {title} {subTitle && <Text style={{ fontSize: 10 }}>{subTitle}</Text>}
          {toolTip}
        </Text>
        <Text
          style={{
            fontSize: md(window.width) ? 17 : 13,
            color: "#464E5F",
            fontWeight: "bold",
          }}
        >
          {value}
          {change != null && (
            <Text
              style={{
                paddingLeft: 4,
                fontSize: md(window.width) ? 14 : 11,
                color: change >= 0 ? colors.GREEN : colors.ORANGE,
                fontWeight: "normal",
              }}
            >
              ({change >= 0 ? "+" : ""}
              {change.toFixed(1)}%)
            </Text>
          )}
        </Text>
      </View>
    </View>
  );
}
