import React from "react";
import { View, Text, Dimensions, Image, TouchableOpacity } from "react-native";
import { md, lg } from "../../Responsive";
import { isLaptopOrIpadPro } from "../../utils";
const window = Dimensions.get("window");

const isWeb = lg(window.width);

export const NavigatableStrip = ({
  title,
  description,
  descriptionColor,
  icon,
  style = {},
  navigate = null,
  clickable = true,
  arrowIcon,
}) => {
  const ArrowIcon = require("../../assets/JamaInvest/Arrow-right2x.png");
  return (
    <View
      style={[
        {
          flexDirection: "row",
          alignItems: "flex-start",
          paddingHorizontal: 20,
          paddingVertical: 16,
          backgroundColor: "#fff",
          borderRadius: 12,
          borderColor: "#410DAA30",
          borderWidth: 1,
          marginRight: isWeb ? 63 : isLaptopOrIpadPro ? 25 : 0,
          ...style,
        },
      ]}
    >
      {icon && (
        <View style={{ flex: md(window.width) ? 1 : 1 }}>
          <Image
            source={icon}
            style={{
              width: md(window.width) ? 47 : 35,
              height: md(window.width) ? 47 : 35,
            }}
            resizeMode={"center"}
          ></Image>
        </View>
      )}
      <View style={{ flex: md(window.width) ? 7 : 7, marginLeft: 0 }}>
        <Text
          style={{
            fontWeight: "700",
            color: "#121212",
            fontSize: md(window.width) ? 16 : 14,
            marginBottom: 10,
          }}
          onPress={() => {
            clickable && navigate && navigate();
          }}
        >
          {title}
          {title == "Portfolio Rebalance" && clickable ? (
            <Image
              source={{
                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/new.gif",
              }}
              style={{
                width: 90,
                height: 20,
                resizeMode: "contain",
                position: "absolute",
                top: 2,
                left: isWeb ? 133 : 115,
                right: 0,
                bottom: 0,
              }}
            />
          ) : null}
        </Text>
        <Text
          style={{
            fontWeight: "normal",
            color: descriptionColor ? descriptionColor : "#6C7293",
            fontSize: 12,
            width: md(window.width) ? "100%" : "80%",
          }}
        >
          {description}
        </Text>
      </View>
      <TouchableOpacity
        onPress={() => {
          navigate && navigate();
        }}
        style={{
          flex: md(window.width) ? 2 : 0.5,
          alignItems: "flex-end",
          marginRight: 0,
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        {clickable && (
          <Image
            source={arrowIcon ? { uri: arrowIcon } : ArrowIcon}
            style={[
              arrowIcon
                ? { width: 40, height: 40 }
                : {
                    width: md(window.width) ? 32 : 16,
                    height: md(window.width) ? 32 : 16,
                  },
            ]}
          ></Image>
        )}
      </TouchableOpacity>
    </View>
  );
};
