import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from "react-native";
import { isMobile } from "../../../utils";
import Modal from "../../Common/Modal";
import { ActivityIndicator } from "react-native-paper";
import { ScrollView } from "react-native";

export const RebalancingIntro = ({ onContinue, isLoading, navigation }) => {
  const [timer, setTimer] = useState(4);

  useEffect(() => {
    if (!timer) return;
    const intervalId = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  return ( !isMobile ? (
    // <ScrollView>
    <Modal style={styles.container} containerStyle={styles.containerStyle}>
      <View
        style={{
          minWidth: isMobile ? 240 : 500,
          paddingBottom: isMobile ? 100 : 0,
        }}
      >
          <TouchableOpacity
            onPress={() => {
              navigation.goBack();
              // navigation.navigate("App", {
              //   screen: "Dashboard",
              //   params: { screen: "Dashboard" },
              // });
            }}
            style={{ alignSelf: "flex-end", paddingRight: 30, marginBottom: 5 }}
          >
            <Text style={{ color: "#00000050" }}>Close X</Text>
          </TouchableOpacity>
          <ScrollView>
          <Text style={styles.title}>
            Keep this in mind while Rebalancing :
          </Text>
          {/* <Text style={styles.description}>
            Rebalacing is a TWO STEP process due to SEBI Margin rules
          </Text> */}

          {/* <View style={styles.tableContainer}>
            <View style={styles.tableDetailsContainer}>
              <View>
                <Text style={styles.what}>{"  "}</Text>
                <Text style={styles.what}>What</Text>
                <Text style={styles.what}>When</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  borderLeftColor: "#00000050",
                  borderLeftWidth: 1,
                }}
              >
                <View style={styles.stepsContainer}>
                  <Text style={styles.step}>Step 1</Text>
                  <Text style={styles.step}>Step 2</Text>
                </View>
                <View style={styles.whatContainer}>
                  <Text
                    style={[
                      styles.stepPoint,
                      { borderRightWidth: 1, borderRightColor: "#00000050" },
                    ]}
                  >
                    Sell & Partial Buy
                  </Text>
                  <Text style={styles.stepPoint}>Buy Remaining</Text>
                </View>
                <View style={styles.whenContainer}>
                  <Text
                    style={[
                      styles.stepPoint,
                      { borderRightWidth: 1, borderRightColor: "#00000050" },
                    ]}
                  >
                    Day 1
                  </Text>
                  <Text style={styles.stepPoint}>Next Business Day *</Text>
                </View>
              </View>
            </View>
            <Text style={styles.note}>
              * If you have sufficient margin/balance, you may complete Step 2
              today itself
            </Text>
          </View> */}
          {/* <View style={styles.sebiContainer}>
            <Text style={styles.sebi}>
              SEBI Margin rules allow you to use upto 80% of the value today,
              for buying other stocks today.
            </Text>
          </View> */}
            <View style={{ paddingLeft: 25, paddingBottom: 15 }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                System will remind you to send a contract note for the
                transaction today. This is required to update the trade numbers
                and track the portfolio, in order to provide accurate investment
                advice. In case you do not transact, please mark the
                "Contract-Note-Expected" entry as "Transaction Not Done" in the{" "}
                <Text
                  style={{
                    color: "#410DAA",
                    fontWeight: 600,
                    textDecorationLine: "underline",
                  }}
                  onPress={() => {
                    navigation.navigate("ViewMode", {
                      screen: "ContractNote",
                    });
                  }}
                >
                  Contract note
                </Text>{" "}
                page.
              </Text>
            </View>
          <View style={styles.confirmButtonContainer}>
            {timer != 0 && (
              <View style={styles.waitContainer}>
                <Text style={styles.wait}>
                  Wait for{" "}
                  <Text style={styles.waitTime}>
                    {timer} second{timer > 1 ? "s" : timer == 0 ? "s" : ""}
                  </Text>
                </Text>
              </View>
            )}
            <TouchableOpacity
              onPress={() => {
                timer == 0 && onContinue && onContinue();
              }}
              underlayColor="white"
            >
              <View
                style={[
                  styles.confirmButton,
                  timer == 0 && styles.confirmButtonYes,
                ]}
              >
                {isLoading ? (
                  <ActivityIndicator
                    size="small"
                    color="#fff"
                    style={styles.loader}
                  />
                ) : (
                  <Text style={styles.yesText}>Continue</Text>
                )}
              </View>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    </Modal>) : (
    <ScrollView>
    <Modal style={styles.container} containerStyle={styles.containerStyle}>
      <View
        style={{
          minWidth: isMobile ? 240 : 500,
          paddingBottom: isMobile ? 100 : 0,
        }}
      >
          <TouchableOpacity
            onPress={() => {
              navigation.goBack();
              // navigation.navigate("App", {
              //   screen: "Dashboard",
              //   params: { screen: "Dashboard" },
              // });
            }}
            style={{ alignSelf: "flex-end", paddingRight: 30, marginBottom: 5 }}
          >
            <Text style={{ color: "#00000050" }}>Close X</Text>
          </TouchableOpacity>
          <ScrollView>
          <Text style={styles.title}>
            Keep this in mind while Rebalancing :
          </Text>
          <Text style={styles.description}>
            Rebalacing is a TWO STEP process due to SEBI Margin rules
          </Text>

          <View style={styles.tableContainer}>
            <View style={styles.tableDetailsContainer}>
              <View>
                <Text style={styles.what}>{"  "}</Text>
                <Text style={styles.what}>What</Text>
                <Text style={styles.what}>When</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  borderLeftColor: "#00000050",
                  borderLeftWidth: 1,
                }}
              >
                <View style={styles.stepsContainer}>
                  <Text style={styles.step}>Step 1</Text>
                  <Text style={styles.step}>Step 2</Text>
                </View>
                <View style={styles.whatContainer}>
                  <Text
                    style={[
                      styles.stepPoint,
                      { borderRightWidth: 1, borderRightColor: "#00000050" },
                    ]}
                  >
                    Sell & Partial Buy
                  </Text>
                  <Text style={styles.stepPoint}>Buy Remaining</Text>
                </View>
                <View style={styles.whenContainer}>
                  <Text
                    style={[
                      styles.stepPoint,
                      { borderRightWidth: 1, borderRightColor: "#00000050" },
                    ]}
                  >
                    Day 1
                  </Text>
                  <Text style={styles.stepPoint}>Next Business Day *</Text>
                </View>
              </View>
            </View>
            <Text style={styles.note}>
              * If you have sufficient margin/balance, you may complete Step 2
              today itself
            </Text>
          </View>
          <View style={styles.sebiContainer}>
            <Text style={styles.sebi}>
              SEBI Margin rules allow you to use upto 80% of the value today,
              for buying other stocks today.
            </Text>
          </View>
            <View style={{ paddingLeft: 25, paddingBottom: 15 }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                System will remind you to send a contract note for the
                transaction today. This is required to update the trade numbers
                and track the portfolio, in order to provide accurate investment
                advice. In case you do not transact, please mark the
                "Contract-Note-Expected" entry as "Transaction Not Done" in the{" "}
                <Text
                  style={{
                    color: "#410DAA",
                    fontWeight: 600,
                    textDecorationLine: "underline",
                  }}
                  onPress={() => {
                    navigation.navigate("ViewMode", {
                      screen: "ContractNote",
                    });
                  }}
                >
                  Contract note
                </Text>{" "}
                page.
              </Text>
            </View>
          <View style={styles.confirmButtonContainer}>
            {timer != 0 && (
              <View style={styles.waitContainer}>
                <Text style={styles.wait}>
                  Wait for{" "}
                  <Text style={styles.waitTime}>
                    {timer} second{timer > 1 ? "s" : timer == 0 ? "s" : ""}
                  </Text>
                </Text>
              </View>
            )}
            <TouchableOpacity
              onPress={() => {
                timer == 0 && onContinue && onContinue();
              }}
              underlayColor="white"
            >
              <View
                style={[
                  styles.confirmButton,
                  timer == 0 && styles.confirmButtonYes,
                ]}
              >
                {isLoading ? (
                  <ActivityIndicator
                    size="small"
                    color="#fff"
                    style={styles.loader}
                  />
                ) : (
                  <Text style={styles.yesText}>Continue</Text>
                )}
              </View>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    </Modal>
    </ScrollView>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: isMobile ? "flex-start" : "center",
    alignItems: "center",
  },
  containerStyle: {
    marginTop: isMobile ? 10 : 0,
    marginHorizontal: 8,
    paddingVertical: 25,
    borderRadius: 15,
    maxWidth: 600,
  },
  title: {
    fontSize: isMobile ? 15 : 20,
    color: "#121212",
    fontWeight: "bold",
    marginBottom: 10,
    paddingHorizontal: 30,
  },
  description: {
    fontSize: isMobile ? 12 : 14,
    fontWeight: "400",
    color: "#464E5F",
    marginBottom: isMobile ? 25 : 35,
    fontStyle: "italic",
    paddingHorizontal: 30,
  },
  tableContainer: {
    paddingHorizontal: 30,
  },
  tableDetailsContainer: {
    flexDirection: "row",
    borderColor: "#00000060",
    borderWidth: 1,
    borderRadius: 6,
  },
  stepsContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 11,
    borderBottomWidth: 1,
    borderBottomColor: "#00000050",
  },
  whatContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#00000050",
  },
  whenContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  step: {
    color: "#121212",
    fontStyle: "italic",
    flex: 0.5,
    textAlign: "center",
    fontSize: isMobile ? 13 : 15,
    fontWeight: "bold",
  },
  stepPoint: {
    color: "#410DAA",
    flex: 0.5,
    textAlign: "center",
    fontSize: isMobile ? 13 : 15,
    paddingVertical: 11,
  },
  what: {
    color: "#121212",
    textAlign: "center",
    fontSize: isMobile ? 13 : 15,
    paddingVertical: 11,
    fontStyle: "italic",
    paddingHorizontal: 20,
  },
  note: {
    fontSize: isMobile ? 12 : 13,
    color: "#464E5F",
    marginBottom: 28,
    fontStyle: "italic",
    marginTop: 10,
  },
  sebiContainer: {
    backgroundColor: "#F2F6FF",
    paddingHorizontal: 30,
    paddingVertical: 17,
    marginBottom: 20,
  },
  sebi: {
    fontSize: isMobile ? 12 : 13,
    color: "#384769",
    fontStyle: "italic",
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  waitContainer: {
    marginRight: 40,
  },
  wait: {
    fontSize: 14,
    color: "#464E5F",
    fontFamily: "Roboto",
  },
  waitTime: {
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  confirmButtonContainer: {
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingHorizontal: 30,
  },
  confirmButton: {
    borderRadius: 4,
    backgroundColor: "#B5B5C3",
    paddingHorizontal: 30,
    marginTop: isMobile ? 15 : 0,
  },
  confirmButtonYes: {
    backgroundColor: "#410DAA",
  },
  noText: {
    color: "#410DAA",
    fontSize: 15,
    paddingHorizontal: 35,
    paddingVertical: 10,
    fontWeight: "bold",
  },
  yesText: {
    color: "#fff",
    fontSize: 15,
    paddingHorizontal: 35,
    paddingVertical: 15,
    fontWeight: "bold",
  },
  loader: {
    paddingHorizontal: 35,
    paddingVertical: 15,
  },
});
