import React, { useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Modalize } from 'react-native-modalize';

export default class ModalComponent extends React.Component  {
    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.setModalMethod(this.onOpen);
    }

    onOpen = (value) => {
        if(value){
            this.modalizeRef.open();
        }else{
            this.modalizeRef.close();
        }
    };

    onClose = () =>{
        this.modalizeRef.close();
    }

    render(){
      const {content} = this.props;
      return (
          <Modalize 
            ref={ref=>(this.modalizeRef=ref)}
            modalHeight={160}
            onOverlayPress={this.onClose}
            rootStyle={{
                position:"fixed",
                top:0
            }}
          >
                {content}
            </Modalize>
        );
    }
};