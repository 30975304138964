import React, { Component } from "react";
import { View, Text, Alert, Image, TouchableOpacity } from "react-native";
import ANTIcon from "@expo/vector-icons/AntDesign";
import {
  grantFamilyAccess,
  withdrawOrIgnoreFamilyAccess,
} from "../../../api/alerts";
import { months_short_names } from "../../../utils";
import Toast from "react-native-toast-message";
export default class FamilyPendingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      granted: false,
      Pendingfamily: false,
    };
  }

  _handleWithdrawOrIgnore = (type) => {
    const familypending = this.props.data;

    withdrawOrIgnoreFamilyAccess({
      cm_id: familypending.pk,
    }).then((json) => {
      if (json.code == 100) {
        this.setState({
          loadingAction: true,
          granted: type == "WITHDRAW" ? "Withdrawn" : "Ignored",
        });
      }
      if (json.code == 0) {
        this.setState({ loadingAction: false, granted: null });
      }
      if (json.code == 101) {
        this.setState({ loadingAction: false, granted: null });
      }
    });
  };

  _handleApprove = () => {
    const familypending = this.props.data;

    grantFamilyAccess({
      cm_id: familypending.pk,
    }).then((json) => {
      if (json.code == 100) {
        this.setState({
          loadingAction: true,
          granted: "Accepted",
        });
      }
      if (json.code == 0) {
        this.setState({ loadingAction: false, granted: null });
        Toast.show({
          text1: json.msg,
          // topOffset: 7,
          visibilityTime: 2000,
          type: "success",
        });
        // Alert.alert(json.msg);
      }
      if (json.code == 101) {
        this.setState({ loadingAction: false, granted: null });
      }
    });
  };

  formatDate = (date) => {
    if (!date) return "-";
    date = date.split("<br>");
    if (date.length > 1) {
      date = date[0];
      const date_time = date.split(" ");
      let date_values = date_time[0];
      let time_values = date_time[1];
      const hr = parseInt(time_values.split(":")[0]);
      let am_pm = "a.m.";
      if (hr > 11) am_pm = "p.m.";

      date_values = date_values.split("-");
      const month = months_short_names(parseInt(date_values[1]));
      const date_formatted =
        date_values[2] + "-" + month + "-" + date_values[0];

      return date_formatted;
    }
    return "-";
  };

  render() {
    const pendingFamily = this.props.data;
    const requested_date = this.formatDate(pendingFamily.created);

    //true if accept
    const WithdrawOrAccept = pendingFamily.request_type == "Accept";
    const photo =
      pendingFamily.photo && pendingFamily.photo != ""
        ? pendingFamily.photo
        : null;
    return (
      <View
        style={{
          marginBottom: 15,
          borderColor: "#BCBCBC",
          borderWidth: 0.8,
          padding: 16,

          borderRadius: 8,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            paddingBottom: 8,
            alignItems: "flex-start",
          }}
        >
          <View style={{ flex: 0.1 }}>
            <View
              style={{
                width: 40,
                height: 40,
                alignItems: "center",
                justifyContent: "center",
                padding: 5,
                marginTop: 5,
              }}
            >
              <Image
                source={{
                  uri:
                    photo && photo != ""
                      ? photo
                      : "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/one+user.png",
                }}
                resizeMode="contain"
                style={{
                  height: 30,
                  width: 30,
                  alignSelf: "center",
                }}
              />
            </View>
          </View>
          <View style={{ flex: 0.9, paddingLeft: 15 }}>
            <Text
              style={{
                fontSize: 15,
                color: "#121212",
                fontWeight: "bold",
              }}
            >
              {pendingFamily.name}
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 10,
                  marginTop: 5,
                }}
              >
                <ANTIcon
                  name="mail"
                  color="#410DAA"
                  size={16}
                  style={{ marginRight: 5 }}
                />
                <Text style={{ fontSize: 12, color: "#00334E" }}>
                  {pendingFamily.email}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 5,
                }}
              >
                <ANTIcon
                  name="mobile1"
                  color="#410DAA"
                  size={16}
                  style={{ marginRight: 5 }}
                />
                <Text style={{ fontSize: 12, color: "#00334E" }}>
                  {pendingFamily.mobilenumber}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 5,
              }}
            >
              <Text style={{ fontSize: 13, color: "#a8a8a8" }}>
                {WithdrawOrAccept ? "Request" : "Sent"} Date:{" "}
              </Text>
              <Text style={{ fontSize: 13, color: "#121212" }}>
                {requested_date}
              </Text>
            </View>
          </View>
        </View>
        {this.state.granted ? (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            <ANTIcon
              name="check"
              color="#410DAA"
              size={16}
              style={{ marginRight: 5 }}
            />
            <Text style={{ fontSize: 13, color: "#00334E" }}>
              {this.state.granted}
            </Text>
          </View>
        ) : (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 10,
              justifyContent: "flex-end",
            }}
          >
            {WithdrawOrAccept && (
              <TouchableOpacity
                style={{
                  borderRadius: 8,
                  backgroundColor: "#fff",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingVertical: 4,
                  paddingRight: 20,
                }}
                onPress={() => {
                  this._handleWithdrawOrIgnore("IGNORE");
                }}
              >
                <Text style={{ fontSize: 12, color: "#a8a8a8" }}>Ignore</Text>
              </TouchableOpacity>
            )}
            <TouchableOpacity
              style={{
                borderRadius: 5,
                backgroundColor: WithdrawOrAccept ? "#410DAA" : "#EF5D12",
                justifyContent: "center",
                alignItems: "center",
                paddingVertical: 8,
                paddingHorizontal: 10,
                marginLeft: 10,
              }}
              onPress={() => {
                if (WithdrawOrAccept) {
                  this._handleApprove();
                } else {
                  this._handleWithdrawOrIgnore("WITHDRAW");
                }
              }}
            >
              <Text style={{ fontSize: 12, color: "#fff" }}>
                {WithdrawOrAccept ? "Accept Request" : "Withdraw Request"}
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}
