import React from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  TouchableOpacity,
} from "react-native";
import Table from "../../../../components/Common/Table";
import amplitude from "../../../../utils/amplitude";
import NavigationHeader from "../../../../components/Common/NavigationHeader";
import AntDesign from "@expo/vector-icons/AntDesign";
import { capitalizeFirstLetter, formatDate, getUserDetails, isMobile, numberWithCommas } from "../../../../utils";
import { getInvestedAmountDetails } from "../../../../api/billing";
import { ActivityIndicator } from "react-native-paper";
import moment from "moment";
import { getCustomerDetails } from "../../../../api";
// import ReactExport from "react-export-excel";
import HoldingsTable from "../../HoldingsTable";
import Store from "../../../../store";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const response = { 
  code: 100,
  msg: "Fetched",
  results: {
    bill_type: "",
    invested_amount: 112345,
    investment_period: "1 Jan 2020 - 32 Dec 2022",
    details: [
      {
        execution_date:  1575475,
        transaction_type: "Investment",
        amount: 1234567,
        no_of_days: 2
      }
    ]
  }
};
class InvestAmountDetails extends React.Component {
  constructor(props) {
    super(props);
    let bill_id, user_id;
    const { params } = this.props.route;
    if(params){
      bill_id = params.bill_id || null;
      user_id = params.user_id || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    }

    this.state = {
      bill_id,
      isLoading: true,
      userName: "",
      user_id
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("InvestedAmountDetails",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("InvestedAmountDetails", { path: this.props.navigation.getState().routeName });
      }
      const { bill_id } = this.state;
      let error = null;
      const response = await getInvestedAmountDetails(bill_id);
      if(response && response.code ==100){
        const { results = {} } = response;
        const { bill_type, invested_amount, investment_period, details=[] } = results;
        const data = {
          bill_type,
          invested_amount,
          investment_period,
          details: [...details].reverse()
        };
        this.setState({ data, dataBackup: data, isLoading: false });

        let userData = {};
        if (this.state.user_id) {
          userData = await getCustomerDetails(this.state.user_id);
        } else {
          userData = await getUserDetails();
        }
        let { first_name } = userData;
        let names = first_name ? first_name.split(/\s+/) : [];
        let name = "";
        if (names.length >= 2 && names.length > 0) {
          name =
            capitalizeFirstLetter(names[0]) +
            " " +
            capitalizeFirstLetter(names[1]);
        } else {
          if (names.length == 0) {
            name = "User";
          } else {
            name = names[0];
          }
        }
        this.setState({ userName: name });
        return;
      }else{
        error = "Error loading invested amount details";
      }
      this.setState({ error, isLoading: false });
    } catch (error) {
      console.log(error);
      this.setState({ error: "Error loading invested amount details", isLoading: false });
    }
  }

  navigateBack = () => {
    const { navigation } = this.props;
    if (navigation) {
      navigation.goBack();
    }
  };

  compare = (a, b, field, sort) => {
    if (a[field] < b[field]) {
      return sort ? -1 : 1;
    }
    if (a[field] > b[field]) {
      return sort ? 1 : -1;
    }
    return 0;
  };

  handleSort = (field, sort, start, end) => {
    this.setState(
      {
        sorting_field: field,
        sorting_value: !sort,
      },
      () => {
        const { data } = this.state;
        const details_data = [...data["details"]];
        const sortedData = [].concat(
          details_data.slice(0, start),
          details_data
            .slice(start, end)
            .sort((a, b) => this.compare(a, b, field, !sort)),
            details_data.slice(end, details_data.length)
        );
        this.setState({ data: {...data, ['details']: sortedData} });
      }
    );
  };

  getTableData = (data = null) => {
    if(!data || data && !data.hasOwnProperty("details")) return {};
    const { sorting_field, sorting_value } = this.state;
    const headerData = [
      {
        label: "Execution Date",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "execution_date",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Transaction Type",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "type",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Amount Executed",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "amount",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "No of Days",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "no_of_days",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "View Details",
        style: [styles.tableHeader],
        sortOption: false,
        onSort: this.handleSort,
        key: "",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
    ];
    let { values = [] } = this.generateDataForTable(data["details"]);
    return { headerData, values };
  };

  handleShowHoldings = async (id) => {
    const { bill_id } = this.state;
    let date = moment(id).format("DD MMM YYYY");
    date = date.split(" ").join("").toLowerCase();
    this.props.navigation.navigate("TransactionDetails", { bill_id, date });
  };

  generateDataForTable = (data) => {
    let values = [];
    data.forEach((item, index) => {
      let { execution_date, type, amount, no_of_days } = item;
      amount = numberWithCommas(amount);
      values.push([
        {
          valueToDisplay: execution_date,
          component: true,
          value: (
            <Text
              style={[ styles.valuationDate ]}
            >
              {formatDate(execution_date)}
            </Text>
          ),
          style: [{ marginLeft: isMobile ? 50 : 100}],
          rowItemContainerStyle: [{ justifyContent: "center" }],
        },
        {
          valueToDisplay: type,
          value: type,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + amount,
          value: amount,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: no_of_days,
          value: no_of_days,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "",
          component: true,
          value: (
            <TouchableOpacity
              style={styles.valuationDateContainer}
              onPress={() => this.handleShowHoldings(execution_date)}
            >
              <AntDesign
                name="arrowright"
                size={16}
                color={"#410DAA"}
                style={styles.bold}
              />
            </TouchableOpacity>
          ),
          style: [{}],
          rowItemContainerStyle: [{ justifyContent: "center" }],
        },
      ]);
    });
    return { values };
  };

  render() {
    const { navigation } = this.props;
    const { data = null, isLoading, error } = this.state;

    const {
      headerData,
      values,
    } = this.getTableData(data);

    let excelBillingDetails = [];
    if (this.state.data) {
      this.state.data.details.forEach((item) => {
        let amount_executed = "₹" + numberWithCommas(parseInt(item.amount));

        excelBillingDetails.push({
          execution_date: item.execution_date,
          type: item.type,
          amount_executed: amount_executed,
          no_of_days: item.no_of_days,
        });
      });
    }

    return (
      <View style={styles.container}>
        <NavigationHeader
          navigation={navigation}
          navigationHeader={[ "Bills", "View Bills", "View Details"]}
          onPressBack={this.navigateBack}
        />
        {
          (isLoading|| error) ? (
            <View style={styles.loadingContainer}>
              {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
              {error && <Text style={styles.error}>{error}</Text>}
            </View>
          ): (
            <ScrollView
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={true}
              horizontal={isMobile ? true : false}
              style={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#1212121A",
                  borderRadius: 10,
                  marginHorizontal: isMobile ? 16 : 20,
                  width: isMobile ? 700 : '80%',
                  marginVertical: 20,
                }}
              >
                <View
                  style={{
                    paddingHorizontal: isMobile ? 16 : 19,
                    paddingVertical: 19,
                    flexDirection: "row",
                    alignItems: isMobile ? "flex-start" : "center",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text style={styles.header}>Invested Amount</Text>
                    <Text style={styles.headerBillingPeriod}>
                      (Period: {data["investment_period"]})
                    </Text>
                  </View>
                  <Text style={styles.billTotalAmount}>₹{numberWithCommas(parseInt(data["invested_amount"]))}</Text>
                </View>
                <View style={styles.headingContainer}>
                  <View></View>
                  <View
                    style={{
                      cursor: "pointer",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      marginRight: 20,
                    }}
                  >
                    {/* <ExcelFile
                      filename={
                        this.state.userName + moment().format("DD-MM-YYYY hh-mm-ss A")
                      }
                      element={
                        <Text
                          style={{
                            color: "#410DAA",
                            fontWeight: "bold",
                            cursor: "pointer",
                            fontSize: 15,
                            textDecorationLine: "underline",
                          }}
                        >
                          Download As Excel
                        </Text>
                      }
                    >
                      <ExcelSheet data={excelBillingDetails} name="Invoices">
                        <ExcelColumn label="Execution Date" value="execution_date" />
                        <ExcelColumn label="Transaction Type" value="type" />
                        <ExcelColumn label="Amount Executed" value="amount_executed" />
                        <ExcelColumn label="No of Days" value="no_of_days" />
                      </ExcelSheet>
                    </ExcelFile> */}
                  </View>
                </View>
                <HoldingsTable
                  header={headerData}
                  gridConfig={[0.25, 0.25, 0.2, 0.2, 0.1]}
                  data={values}
                  navigation={this.props.navigation}
                />
              </View>
            </ScrollView>
          )
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: isMobile ? 16 : 36,
    marginVertical: 30,
  },
  borderColor: {
    borderColor: "#DBC0FF",
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 10 : 40,
    paddingVertical: 19,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "700",
  },
  headerBillingPeriod: {
    fontSize: isMobile ? 13 : 15,
    fontFamily: "Roboto",
    color: "#464E5F",
    marginTop: 10,
  },
  billTotalAmount: {
    fontSize: 15,
    color: "#464E5F",
    fontFamily: "Roboto",
    fontWeight: "700",
    marginRight: 50,
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  valuationDate: { fontSize: 11 },
  valuationDateContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  active: { color: "#410DAA", fontWeight: "700" },
  bold: { fontWeight: "bold", marginLeft: 12 },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default InvestAmountDetails;
