import React from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TextInput,
  TouchableOpacity,
  Dimensions,
  Image,
} from "react-native";
import { changePassword, getLoginBannerDetails } from "../../../api";
import Header from "../login_header";
import { Feather } from "@expo/vector-icons";
import { ActivityIndicator } from "react-native-paper";
import { isMobile, reverseString } from "../../../utils";
import { StackActions } from "@react-navigation/native";
import { md } from "../../../Responsive";
const { width } = Dimensions.get("window");

export default class ChangeDefaultPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      bannerData: {
        logo_image_url: null,
        name: null,
        ria_code: null,
        subscriber_type: null,
        support_email: null,
        support_person_name: null,
        support_phone_number: null,
        is_jamawealth: null,
      },
    };
  }

  componentDidMount = async () => {
    let { bannerData } = this.state;
    let response = null;
    try {
      response = await getLoginBannerDetails();
    } catch (err) {}
    if (response && response.code == 100) {
      bannerData = response.result;
      this.setState({ bannerData: bannerData });
    } else {
      bannerData.logo_image_url =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/website/jam%C4%81_header.png";
      bannerData.name = "Jama Wealth";
      bannerData.ria_code = "INA 200015583";
      bannerData.subscriber_type = "RIAC";
      bannerData.support_email = "advisor@jamawealth.com";
      bannerData.support_person_name = "Ram Kalyan Medury";
      bannerData.support_phone_number = "9870264643";
      bannerData.is_jamawealth = true;
      this.setState({
        bannerData: bannerData,
        errorText: response.msg,
      });
    }
  };

  handleContinue = async () => {
    try {
      let { password, confirmPassword } = this.state;
      const { route } = this.props;
      const { params } = route;
      let token;
      if (params && params.k) {
        token = params.k;
        const length = token.length;
        const reversedToken = token.substr(0, length - 4);
        token = reverseString(reversedToken);
      }
      const data = {
        password,
      };
      let headers = {};
      if (token) {
        headers["Authorization"] = `token ${token}`;
      } else {
        this.setState({
          isLoading: false,
          errorText: "Please login with default password again !",
        });
        return;
      }

      if (!password) {
        this.setState({ passwordError: "Please enter valid password" });
        return;
      }
      if (!confirmPassword) {
        this.setState({ confirmPasswordError: "Please re-enter password" });
        return;
      }
      if (confirmPassword && confirmPassword != password) {
        this.setState({
          confirmPasswordError: "Re-entered password does not match",
        });
        return;
      }

      this.setState({
        isLoading: true,
        passwordError: null,
        confirmPasswordError: null,
      });
      const response = await changePassword(data, headers);

      if (response && response.code == 100) {
        this.setState({ isLoading: false, errorText: null }, () => {
          this.props.navigation.dispatch(StackActions.replace("OnboardingLoginMobile"));
        });
      } else {
        this.setState({ isLoading: false, errorText: "Something went wrong!" });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        errorText: "Something went wrong!",
      });
    }
  };

  handlePassword = (text) => {
    this.setState({
      password: text,
      passwordError: !text ? "Please enter valid password" : null,
    });
  };

  handleConfirmPassword = (text) => {
    this.setState({
      confirmPassword: text,
    });
  };

  handlePasswordVisibility = (field) => {
    this.setState({
      ["show" + field]: !this.state["show" + field],
    });
  };

  render() {
    const {
      errorText = null,
      isLoading,
      password,
      passwordError,
      confirmPassword,
      confirmPasswordError,
      bannerData,
    } = this.state;
    return (
      <View style={styles.container}>
        <Header
          navigation={this.props.navigation}
          route={this.props.route}
          bannerData={bannerData}
        />
        <ScrollView>
          <View style={styles.body}>
            <View style={styles.left}>
              <Text style={styles.best}>
                Invest like the <Text style={{ color: "#410DAA" }}>Best</Text>
              </Text>
              <Image
                source={
                  "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Invest+like+the+best.png"
                }
                style={styles.left_logo}
              ></Image>
              <View style={styles.certicationsContainer}>
                {bannerData.is_jamawealth ? (
                  <View style={styles.certication}>
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+11.png"
                      }
                      style={{ width: 18, height: 30 }}
                    />
                    <Text style={[styles.sebi, { paddingLeft: 10 }]}>
                      Goi{" "}
                      <Text style={{ fontWeight: "bold", color: "black" }}>
                        DIPP
                      </Text>{" "}
                      Registered
                    </Text>
                  </View>
                ) : null}
                {bannerData.is_jamawealth ? (
                  <View style={styles.certication}>
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+7.png"
                      }
                      style={{ width: 29, height: 25 }}
                    />
                    <Text style={[styles.sebi, { paddingLeft: 10.17 }]}>
                      ISO{" "}
                      <Text style={{ fontWeight: "bold", color: "black" }}>
                        9001
                      </Text>{" "}
                      Certified
                    </Text>
                  </View>
                ) : null}
                {bannerData.is_jamawealth ? (
                  <View style={styles.certication}>
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+7.png"
                      }
                      style={{ width: 29, height: 25 }}
                    />
                    <Text style={[styles.sebi, { paddingLeft: 10.17 }]}>
                      ISO{" "}
                      <Text style={{ fontWeight: "bold", color: "black" }}>
                        27001
                      </Text>{" "}
                      Certified
                    </Text>
                  </View>
                ) : null}
                <View style={styles.sebiContainer}>
                  <View style={{ paddingLeft: 0.4 }}>
                    <Text style={[styles.sebi]}>
                      SEBI Regd Investment Advisor
                    </Text>
                    <Text style={styles.ina}>{bannerData.ria_code}</Text>
                    <Text style={[styles.sebi_1]}>
                      Simply Grow Technologies Pvt Ltd.
                    </Text>
                  </View>
                </View>
                <View style={styles.sebiContainer}>
                  <View style={{ paddingLeft: 10.4 }}>
                    <Text style={[styles.sebi]}>
                      SEBI Registered Portfolio Manager
                    </Text>
                    <Text style={styles.ina}>INP000007881</Text>
                    <Text style={[styles.sebi_1]}>
                      Jama Wealth Asset Management Pvt Ltd.{" "}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.right}>
              <View style={[isMobile ? styles.mob_styl : styles.web_styl]}>
                <Text style={styles.welcomeTitle}>
                  Reset Your Default Password
                </Text>
                <View style={styles.inputsContainer}>
                  <View style={styles.inputContainer}>
                    <Text style={styles.fieldLabel}>New Password</Text>
                    <View>
                      <TextInput
                        value={password}
                        style={styles.dmart_body_md}
                        placeholder={""}
                        //placeholderTextColor={"#00000030"}
                        onChangeText={this.handlePassword}
                        secureTextEntry={!this.state.showPassword}
                      />
                      <Feather
                        name={!this.state.showPassword ? "eye-off" : "eye"}
                        color={!this.state.showPassword ? "gray" : "#410DAA"}
                        size={18}
                        style={{ position: "absolute", right: 5, bottom: 9 }}
                        onPress={() =>
                          this.handlePasswordVisibility("Password")
                        }
                      />
                    </View>
                    {!!passwordError && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {passwordError || " "}
                      </Text>
                    )}
                  </View>

                  <View style={styles.inputContainer}>
                    <Text style={styles.fieldLabel}>Confirm Password</Text>
                    <View>
                      <TextInput
                        value={confirmPassword}
                        style={styles.dmart_body_md}
                        placeholder={""}
                        //placeholderTextColor={"#00000030"}
                        onChangeText={this.handleConfirmPassword}
                        secureTextEntry={!this.state.showConfirm}
                      />
                      <Feather
                        name={!this.state.showConfirm ? "eye-off" : "eye"}
                        color={!this.state.showConfirm ? "gray" : "#410DAA"}
                        size={18}
                        style={{ position: "absolute", right: 5, bottom: 9 }}
                        onPress={() => this.handlePasswordVisibility("Confirm")}
                      />
                    </View>
                    {!!confirmPasswordError && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {confirmPasswordError || " "}
                      </Text>
                    )}
                  </View>

                  {!!errorText && (
                    <Text style={[styles.please, { color: "red" }]}>
                      {errorText || " "}
                    </Text>
                  )}
                </View>
                {isLoading ? (
                  <ActivityIndicator
                    size="large"
                    color="#410DAA"
                    style={styles.loading}
                  />
                ) : (
                  <TouchableOpacity
                    onPress={this.handleContinue}
                    style={styles.submit}
                  >
                    <Text style={styles.req_otp}>Submit</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: "#fff",
  },
  body: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  left: {
    flex: md(width) ? 1 : 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#410DAA08",
    paddingVertical: md(width) ? 75 : 1,
    display: md(width) ? "undefined" : "none",
  },
  right: {
    flex: 1,
  },
  mob_styl: {
    minWidth: 250,
    alignSelf: "center",
    marginTop: "15%",
  },
  web_styl: {
    marginTop: "15%",
    alignItems: "center",
  },
  best: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 33,
    lineHeight: 38,
    color: "#464E5F",
    paddingBottom: 100,
  },
  left_logo: {
    resizeMode: "contain",
    width: 403,
    height: 241,
    alignSelf: "center",
    marginBottom: 60,
  },
  mobile_verf: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 19 : 15,
    lineHeight: md(width) ? 22 : 17,
    textAlign: "center",
    color: "#121212",
    paddingTop: md(width) ? "undefined" : 50,
  },
  we_will: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 17 : 13,
    lineHeight: md(width) ? 20 : 18,
    textAlign: "justify",
    color: "#A7A8BB",
    paddingTop: md(width) ? 19 : 29,
  },
  fieldLabel: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 18,
    color: "#222225",
    paddingBottom: 9,
  },
  please: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3,
  },
  req_otp: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 47,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  one: {
    color: "#121212",
  },
  dmart_body_md: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "bold",
    color: "#121212",
    backgroundColor: "#f6f6f6",
    height: 41.02,
    paddingLeft: 20,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#000",
  },
  sebi: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 14,
    color: "#000",
    paddingRight: 29,
  },
  sebi_1: {
    fontFamily: "Arial",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: 9,
    lineHeight: 14,
    color: "#51514E",
    paddingRight: 29,
  },
  ina: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 10,
    lineHeight: 14,
    color: "#410DAA",
  },
  img1: {
    width: 30,
    height: 28.6,
  },
  welcomeTitle: {
    fontFamily: "Roboto",
    fontSize: 22,
    color: "#202020",
    fontWeight: "bold",
    marginBottom: 45,
    textAlign: "center",
  },
  inputsContainer: {
    minWidth: "75%",
  },
  inputContainer: {
    marginBottom: 37,
  },
  forgotPassword: {
    color: "#232425",
    fontSize: 16,
    fontFamily: "Roboto",
    marginTop: 18,
  },
  clickHere: {
    color: "#410DAA",
    fontWeight: "bold",
    paddingLeft: 6,
  },
  certication: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 25,
  },
  sebiContainer: {
    flexDirection: "row",
    paddingTop: 25,
  },
  certicationsContainer: {
    flexDirection: "row",
    paddingLeft: 30.5,
    paddingTop: 65,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  submit: { alignItems: "center", justifyContent: "center", paddingTop: 40 },
  loading: { paddingTop: 40 },
});
