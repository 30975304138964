import React from "react";
import {
  View,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  Platform,
  Image,
  ActivityIndicator,
} from "react-native";
import amplitude from "../../utils/amplitude";
import { Text, Button } from "react-native-paper";
import { getAdvisoryTracker, getFinAwarenessData } from "../../api";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { getUserDetails, isJama, isMobile } from "../../utils";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;
import { ShowToast } from "../Common/Toast";
import moment from "moment";
import { getAdvisorDashboardDetials } from "../../api/goals";
import { StatusIndicator } from "../../components/Common/statusIndicator";
import Store from "../../store";
import ProgressBar from "../../utils/progressBar";
import { fr } from "react-native-paper-dates";

class CountTrackerHome extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let showToast = null;
    let showHeader = false;
    if (params) {
      showToast = params.showToast || null;
      showHeader = params.showHeader || false;
    }
    let is_preferred_circle = false;
    let is_club = false;
    let is_elite = false;
    let { user_database } = Store.getState().userData.userProfileData;
    let hostname =
      Platform.OS == "web" ? global.location.hostname : user_database;
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    if (hostname.includes("club")) {
      is_club = true;
    }
    if (
      hostname.includes("app.jamawealth") ||
      hostname.includes("dapp.jamawealth")
    ) {
      is_elite = true;
    }
    this.state = {
      showHeader,
      stockCount: "0",
      mfCount: "0",
      portfolioCount: "0",
      financialCount: "0",
      additionalCount: "0",
      endDate: "",
      availedCount: "0",
      totalCount: "0",
      showToast,
      showErrorToast: false,
      showLoader: true,
      loadingText: "",
      finData: [],
      is_preferred_circle,
      is_club,
      is_elite,
      is_plan_advises_unlimited: false,
      allow_stock_purchase: false,
      show_corporate_stock_msg: false,
      show_finplan_review: true,
      show_portfolio_review: true,
      allow_mf_purchase: false,
      show_corporate_mf_msg: false,
      redirect_to_goals: false,
    };
  }

  async componentDidMount() {
    const finResp = await getFinAwarenessData();
    if (finResp && finResp.code && finResp.code == 100) {
      this.setState({
        finData: finResp.financial_awareness,
      });
      if (Platform.OS == "web") {
        amplitude.logEvent("countTrackerHome", { path: global.location.href });
      } else {
        amplitude.logEvent("countTrackerHome", {
          path: this.props.navigation.getState().routeName,
        });
      }
    }
    let allow_stock_purchase = false;
    let allow_mf_purchase = false;
    let show_finplan_review = false;
    let show_portfolio_review = false;
    let show_corporate_stock_msg = false;
    let show_corporate_mf_msg = false;
    let redirect_to_goals = true;
    let {
      mf_latest_value,
      is_plan_advises_unlimited,
      current_subscription_plan,
      corporate_id,
    } = getUserDetails();
    if (
      is_plan_advises_unlimited &&
      corporate_id &&
      (current_subscription_plan == "PSQ" ||
        current_subscription_plan == "PSH" ||
        current_subscription_plan == "PSA")
    ) {
      allow_stock_purchase = true;
      this.setState({
        allow_stock_purchase: true,
      });
    } else if (
      is_plan_advises_unlimited &&
      corporate_id &&
      (current_subscription_plan != "PSQ" ||
        current_subscription_plan != "PSH" ||
        current_subscription_plan != "PSA")
    ) {
      show_corporate_stock_msg = true;
      this.setState({
        show_corporate_stock_msg: true,
      });
    }
    if (
      is_plan_advises_unlimited &&
      corporate_id &&
      (current_subscription_plan == "PMQ" ||
        current_subscription_plan == "PMH" ||
        current_subscription_plan == "PMA")
    ) {
      allow_mf_purchase = true;
      this.setState({
        allow_mf_purchase: true,
      });
    } else if (
      is_plan_advises_unlimited &&
      corporate_id &&
      (current_subscription_plan != "PMQ" ||
        current_subscription_plan != "PMH" ||
        current_subscription_plan != "PMA")
    ) {
      show_corporate_mf_msg = true;
      this.setState({
        show_corporate_mf_msg: true,
      });
    }

    if (
      is_plan_advises_unlimited &&
      corporate_id &&
      (current_subscription_plan == "PSQ" ||
        current_subscription_plan == "PSH" ||
        current_subscription_plan == "PSA" ||
        current_subscription_plan == "PMQ" ||
        current_subscription_plan == "PMH" ||
        current_subscription_plan == "PMA")
    ) {
      show_portfolio_review = true;
      this.setState({
        show_portfolio_review: true,
      });
    } else if (this.state.is_preferred_circle && !is_plan_advises_unlimited) {
      show_portfolio_review = true;
      this.setState({
        show_portfolio_review: true,
      });
    } else {
      show_portfolio_review = false;
      this.setState({
        show_portfolio_review: false,
      });
    }
    if (
      is_plan_advises_unlimited &&
      corporate_id &&
      (current_subscription_plan == "PP" ||
        current_subscription_plan == "PPH" ||
        current_subscription_plan == "PSQ" ||
        current_subscription_plan == "PSH" ||
        current_subscription_plan == "PSA" ||
        current_subscription_plan == "PMQ" ||
        current_subscription_plan == "PMH" ||
        current_subscription_plan == "PMA")
    ) {
      show_finplan_review = false;
      this.setState({
        show_finplan_review: false,
      });
    } else {
      show_finplan_review = true;
      this.setState({
        show_finplan_review: true,
      });
    }

    if (
      is_plan_advises_unlimited &&
      corporate_id &&
      (current_subscription_plan == "PLY" ||
        current_subscription_plan == "PLB" ||
        current_subscription_plan == "PLQ" ||
        current_subscription_plan == "PHA" ||
        current_subscription_plan == "PHH" ||
        current_subscription_plan == "PHQ")
    ) {
      redirect_to_goals = false;
      this.setState({
        redirect_to_goals: false,
      });
    } else {
      redirect_to_goals = true;
      this.setState({
        redirect_to_goals: true,
      });
    }
    this.setState({
      is_plan_advises_unlimited: is_plan_advises_unlimited,
    });
    // console.log(mf_latest_value);
    let response = null;
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      response = await getAdvisoryTracker(
        Store.getState().dashboard.viewModeUserId.user_id
      );
    } else {
      response = await getAdvisoryTracker();
    }
    if (response && response.code && response.code == "100") {
      this.setState({
        availedCount:
          response.result.total_advises_availed > 0
            ? response.result.total_advises_availed.toString()
            : "0",
        totalCount:
          response.result.total_advises > 0
            ? response.result.total_advises.toString()
            : "0",
        stockCount: is_plan_advises_unlimited
          ? allow_stock_purchase
            ? "max"
            : "0"
          : response.result.stock_adv > 0
          ? response.result.stock_adv.toString()
          : "0",
        mfCount: is_plan_advises_unlimited
          ? allow_mf_purchase
            ? "max"
            : "0"
          : response.result.mf_adv > 0
          ? response.result.mf_adv.toString()
          : "0",
        portfolioCount: is_plan_advises_unlimited
          ? "max"
          : response.result.portfolio_adv > 0
          ? response.result.portfolio_adv.toString()
          : "0",
        financialCount: is_plan_advises_unlimited
          ? "max"
          : response.result.fp_adv > 0
          ? response.result.fp_adv.toString()
          : "0",
        additionalCount: is_plan_advises_unlimited
          ? "max"
          : response.result.additonal_adv > 0
          ? response.result.additonal_adv.toString()
          : "0",
        endDate: response.result.plan_end_date,
        showLoader: false,
        mf_latest_value: mf_latest_value,
        current_subscription_plan: current_subscription_plan,
        corporate_id: corporate_id,
      });
    } else {
      this.setState({ showLoader: false });
    }
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {this.state.showHeader && (
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Plans"]}
            onPressBack={() => {
              this.props.navigation.goBack();
            }}
          />
        )}
        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title="Congratulations! Your onboarding is now complete."
            duration="10"
          />
        ) : null}
        {this.state.showErrorToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title="Error while loading BSE url for nominee authentication. kindly contact your advisor."
            duration="10"
          />
        ) : null}
        {this.state.showLoader ? (
          <ProgressBar msg={"Loading..."} />
        ) : (
          <ScrollView showsVerticalScrollIndicator={false}>
            <ScrollView
              contentContainerStyle={{ flexGrow: 1 }}
              style={styles.cardContainer}
              showsVerticalScrollIndicator={true}
            >
              {isMobile ? (
                <>
                  <View style={{ marginTop: 20, marginBottom: 40 }}>
                    {this.state.is_preferred_circle &&
                    !this.state.is_plan_advises_unlimited ? (
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View
                          style={{
                            marginLeft: "5%",
                            marginRight: "5%",
                            backgroundColor: "white",
                            width: "90%",
                            height: 130,
                            borderWidth: 1,
                            borderRadius: 12,
                            borderColor: "rgba(65, 13, 170, 0.3)",
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              marginLeft: "3%",
                              marginRight: "3%",
                              flexDirection: "row",
                              marginTop: "1%",
                              justifyContent: "space-between",
                            }}
                          >
                            <View
                              style={{
                                flex: 1,
                                flexDirection: "column",
                                marginTop: "4%",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginBottom: "4%",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 18,
                                    color: "#464E5F",
                                    fontWeight: "700",
                                  }}
                                >
                                  Advices Remaining{" "}
                                  {this.state.totalCount -
                                    this.state.availedCount <
                                  0
                                    ? 0
                                    : this.state.totalCount -
                                      this.state.availedCount}
                                  /{this.state.totalCount}
                                </Text>
                              </View>

                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#6b717f",
                                  fontWeight: "500",
                                }}
                              >
                                Pack Expires on:{" "}
                                <Text
                                  style={{
                                    fontSize: 16,
                                    color: "#8d6ecc",
                                    fontWeight: "500",
                                  }}
                                >
                                  {moment(this.state.endDate).format(
                                    "DD MMM YYYY"
                                  )}
                                </Text>
                              </Text>

                              <View
                                style={{
                                  flexDirection: "row",
                                  marginTop: "1%",
                                }}
                              >
                                <View
                                  style={[
                                    {
                                      alignSelf: "flex-start",
                                      flexDirection: "row",
                                      marginVertical: 7.5,
                                    },
                                  ]}
                                >
                                  <View
                                    style={{
                                      width: 15,
                                      height: 15,
                                      borderRadius: 2,
                                      backgroundColor: "#876CDD",
                                      marginTop: 1,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 14,
                                      color: "#464E5F",
                                      paddingLeft: 8,
                                      fontWeight: "700",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    Availed ({this.state.availedCount})
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    {
                                      alignSelf: "flex-start",
                                      flexDirection: "row",
                                      marginVertical: 7.5,
                                      marginLeft: 20,
                                    },
                                  ]}
                                >
                                  <View
                                    style={{
                                      width: 15,
                                      height: 15,
                                      borderRadius: 2,
                                      backgroundColor: "#BAC7F2",
                                      marginTop: 1,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 14,
                                      color: "#464E5F",
                                      fontWeight: "700",
                                      paddingLeft: 8,
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    Remaining{" "}
                                    {this.state.totalCount -
                                      this.state.availedCount}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{
                                flex: 1,
                                alignItems: "flex-end",
                                flexDirection: "column",
                                marginRight: "-7.5%",
                              }}
                            >
                              {Platform.OS == "web" ? (
                                <View
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Donut
                                    options={{
                                      dataLabels: {
                                        enabled: false,
                                      },
                                      legend: {
                                        show: false,
                                      },
                                      tooltip: { enabled: false },
                                      colors: ["#876CDD", "#BAC7F2"],
                                      plotOptions: {
                                        pie: {
                                          donut: {
                                            size: "60%",
                                          },
                                        },
                                      },
                                      stroke: {
                                        width: 1,
                                        colors: ["#876CDD", "#BAC7F2"],
                                      },
                                    }}
                                    series={[
                                      (this.state.availedCount /
                                        (this.state.availedCount +
                                          this.state.totalCount -
                                          this.state.availedCount)) *
                                        100,
                                      ((this.state.totalCount -
                                        this.state.availedCount) /
                                        (this.state.availedCount +
                                          this.state.totalCount -
                                          this.state.availedCount)) *
                                        100,
                                    ]}
                                    sliceColor={["#876CDD", "#BAC7F2"]}
                                    type="donut"
                                    width="170"
                                  />
                                </View>
                              ) : (
                                <Donut
                                  widthAndHeight={100}
                                  series={[
                                    (this.state.availedCount /
                                      (this.state.availedCount +
                                        this.state.totalCount -
                                        this.state.availedCount)) *
                                      100,
                                    ((this.state.totalCount -
                                      this.state.availedCount) /
                                      (this.state.availedCount +
                                        this.state.totalCount -
                                        this.state.availedCount)) *
                                      100,
                                  ]}
                                  sliceColor={["#876CDD", "#BAC7F2"]}
                                  doughnut={true}
                                  coverRadius={0.6}
                                  coverFill={"#FFF"}
                                />
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    ) : null}
                  </View>
                  <View style={{ marginBottom: 40 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View
                        style={{
                          marginLeft: "5%",
                          marginRight: "5%",
                          backgroundColor: "white",
                          width: "90%",
                          borderWidth: 1,
                          borderRadius: 12,
                          borderColor: "rgba(65, 13, 170, 0.3)",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            marginLeft: "3%",
                            marginRight: "3%",
                            flexDirection: "column",
                            marginTop: "6%",
                            justifyContent: "space-between",
                          }}
                        >
                          <View
                            style={{
                              width: "100%",
                              alignItems: "flex-start",
                              flexDirection: "column",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 18,
                                color: "#464E5F",
                                fontWeight: "600",
                              }}
                            >
                              Stock Recommendations
                            </Text>
                            <View
                              style={{
                                width: "100%",
                                flexDirection: "column",
                                marginTop: 15,
                              }}
                            >
                              <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/stock.png",
                                }}
                                style={{
                                  width: 100,
                                  height: 100,
                                  alignSelf: "center",
                                  borderRadius: 4,
                                }}
                                resizeMode={"contain"}
                              />
                            </View>
                            {this.state.is_preferred_circle ? (
                              <>
                                {this.state.is_plan_advises_unlimited ? null : (
                                  <Text
                                    style={{
                                      fontSize: 18,
                                      color: "#7b55c3",
                                      fontWeight: "600",
                                      marginTop: "2%",
                                    }}
                                  >
                                    {this.state.stockCount} Advices Remaining
                                  </Text>
                                )}
                                {this.state.stockCount != "0" ? (
                                  <Button
                                    uppercase={false}
                                    mode="contained"
                                    labelStyle={{
                                      color: "#410DAA",
                                      fontWeight: "600",
                                    }}
                                    onPress={() => {
                                      this.props.navigation.navigate("App", {
                                        screen: "ViewMode",
                                        params: {
                                          screen: "StockRecommendations",
                                          params: {},
                                        },
                                      });
                                    }}
                                    style={{
                                      marginTop: 20,
                                      borderColor: "#410DAA",
                                      borderWidth: 1,
                                      backgroundColor: "white",
                                      borderRadius: 6,
                                      marginBottom: 20,
                                      height: 37,
                                      width: 160,
                                    }}
                                  >
                                    Get Stock Advice
                                  </Button>
                                ) : (
                                  <>
                                    <Text
                                      style={{
                                        fontSize: 15,
                                        color: "#464E5F",
                                        marginTop: 77,
                                        fontWeight: "600",
                                      }}
                                    >
                                      {this.state.show_corporate_stock_msg
                                        ? "Visit your Corporate Benefit Portal"
                                        : "No advices remaining,"}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 15,
                                        color: "#464E5F",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {this.state.show_corporate_stock_msg
                                        ? "and choose any 'Stock Portfolio' plan"
                                        : "kindly contact advisor"}
                                    </Text>
                                  </>
                                )}
                              </>
                            ) : (
                              <View
                                style={{
                                  paddingTop: 20,
                                }}
                              >
                                <Text
                                  style={{ marginTop: 10, color: "#464E5F" }}
                                >
                                  To get Stock Advices,kindly click on Upgrade
                                </Text>
                                <Button
                                  uppercase={false}
                                  mode="contained"
                                  labelStyle={{
                                    color: "#410DAA",
                                    fontWeight: "600",
                                  }}
                                  onPress={async () => {
                                    this.props.navigation.navigate("App", {
                                      screen: "Plans",
                                      params: {
                                        screen: "SubscriptionPlanHome",
                                        params: {
                                          show_club: false,
                                          show_preferredcircle: true,
                                          show_elite: true,
                                          cip_pk_for_renewal: null,
                                        },
                                      },
                                    });
                                  }}
                                  style={{
                                    marginTop: 20,
                                    borderColor: "#410DAA",
                                    borderWidth: 1,
                                    backgroundColor: "white",
                                    marginBottom: 20,
                                    borderRadius: 6,
                                    height: 37,
                                    width: 160,
                                  }}
                                >
                                  Upgrade Now
                                </Button>
                              </View>
                            )}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginBottom: 40 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View
                        style={{
                          marginLeft: "5%",
                          marginRight: "5%",
                          backgroundColor: "white",
                          width: "90%",
                          padding: 15,
                          borderWidth: 1,
                          borderRadius: 12,
                          borderColor: "rgba(65, 13, 170, 0.3)",
                        }}
                      >
                        <View
                          style={{
                            flex: 1.5,
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#464E5F",
                              fontWeight: "600",
                            }}
                          >
                            Mutual Fund Packs
                          </Text>

                          <View
                            style={{
                              display: "flex",
                              marginLeft: "3%",
                              marginRight: "3%",
                              flexDirection: "column",
                              marginTop: "6%",
                            }}
                          >
                            <View
                              style={{
                                width: "100%",
                                flexDirection: "column",
                                marginTop: 15,
                              }}
                            >
                              <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/mf.png",
                                }}
                                style={{
                                  width: 100,
                                  height: 100,
                                  alignSelf: "center",
                                  borderRadius: 4,
                                }}
                                resizeMode={"contain"}
                              />
                            </View>
                            {this.state.is_preferred_circle &&
                            !this.state.is_plan_advises_unlimited ? (
                              <Text
                                style={{
                                  fontSize: 18,
                                  color: "#7b55c3",
                                  fontWeight: "600",
                                  marginTop: "2%",
                                }}
                              >
                                {this.state.mfCount} Advices Remaining
                              </Text>
                            ) : null}

                            {this.state.mfCount != "0" || this.state.is_club ? (
                              <Button
                                uppercase={false}
                                mode="contained"
                                labelStyle={{
                                  color: "#410DAA",
                                  fontWeight: "600",
                                }}
                                onPress={() => {
                                  this.props.navigation.navigate("App", {
                                    screen: "ViewMode",
                                    params: {
                                      screen: "InvestForGoals",
                                      params: {
                                        from: "Plans"
                                      },
                                    },
                                  });
                                }}
                                style={{
                                  marginTop: 20,
                                  borderColor: "#410DAA",
                                  borderWidth: 1,
                                  backgroundColor: "white",
                                  borderRadius: 6,
                                  height: 37,
                                  width: 160,
                                }}
                              >
                                Get Fund Advice
                              </Button>
                            ) : (
                              <>
                                <Text
                                  style={{
                                    fontSize: 15,
                                    marginTop: 20,
                                    color: "#464E5F",
                                    fontWeight: "600",
                                  }}
                                >
                                  {this.state.show_corporate_mf_msg
                                    ? "Visit your Corporate Benefit Portal"
                                    : "No advices remaining,"}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: 15,
                                    color: "#464E5F",
                                    fontWeight: "600",
                                  }}
                                >
                                  {this.state.show_corporate_mf_msg
                                    ? "and choose any 'MF Portfolio' plan"
                                    : "kindly contact advisor"}
                                </Text>
                              </>
                            )}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  {(this.state.is_preferred_circle &&
                    this.state.show_portfolio_review) ||
                  (this.state.is_club &&
                    this.state.mf_latest_value > 1000000) ? (
                    <View style={{ marginBottom: 40 }}>
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View
                          style={{
                            marginLeft: "5%",
                            marginRight: "5%",
                            backgroundColor: "white",
                            width: "90%",
                            height: 210,
                            borderWidth: 1,
                            borderRadius: 12,
                            borderColor: "rgba(65, 13, 170, 0.3)",
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              marginLeft: "3%",
                              marginRight: "3%",
                              flexDirection: "row",
                              marginTop: "6%",
                              justifyContent: "space-between",
                            }}
                          >
                            <View
                              style={{
                                flex: 1,
                                flexDirection: "column",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginBottom: "16%",
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                                  }}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                                <Text
                                  style={{
                                    fontSize: 15,
                                    textDecorationLine: "underline",
                                    color: "#410DAA",
                                    textAlign: "left",
                                    marginLeft: "1%",
                                  }}
                                  onPress={() => {}}
                                >
                                  How it works?
                                </Text>
                              </View>
                              <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolioValue.png",
                                }}
                                style={{
                                  width: 120,
                                  height: 120,
                                  alignSelf: "flex-start",
                                  borderRadius: 4,
                                }}
                                resizeMode={"contain"}
                              />
                            </View>
                            <View
                              style={{
                                flex: 1.5,
                                alignItems: "flex-end",
                                flexDirection: "column",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 18,
                                  color: "#464E5F",
                                  fontWeight: "600",
                                }}
                              >
                                Portfolio Review
                              </Text>
                              {this.state.is_plan_advises_unlimited ? null : (
                                <Text
                                  style={{
                                    fontSize: 18,
                                    color: "#7b55c3",
                                    fontWeight: "600",
                                    marginTop: "2%",
                                  }}
                                >
                                  {this.state.portfolioCount} Advices Remaining
                                </Text>
                              )}
                              {this.state.portfolioCount != "0" ? (
                                <Button
                                  uppercase={false}
                                  mode="contained"
                                  labelStyle={{
                                    color: "#410DAA",
                                    fontWeight: "600",
                                  }}
                                  onPress={() => {
                                    if (
                                      this.state.is_plan_advises_unlimited &&
                                      this.state.corporate_id
                                    ) {
                                      this.props.navigation.navigate("App", {
                                        screen: "ViewMode",
                                        params: {
                                          screen: "ImportStocks",
                                          params: {
                                            from: "CountTrackerHome",
                                          },
                                        },
                                      });
                                    } else {
                                      this.props.navigation.push("App", {
                                        screen: "Dashboard",
                                        params: {
                                          screen: "Dashboard",
                                          params: {
                                            screen: "ScheduleCall",
                                            params: {
                                              topic: "RPP",
                                            },
                                          },
                                        },
                                      });
                                    }
                                  }}
                                  style={{
                                    marginTop: 77,
                                    borderColor: "#410DAA",
                                    borderWidth: 1,
                                    backgroundColor: "white",
                                    borderRadius: 6,
                                    height: 37,
                                    width: 160,
                                  }}
                                >
                                  Schedule Review
                                </Button>
                              ) : (
                                <>
                                  <Text
                                    style={{
                                      fontSize: 15,
                                      color: "#464E5F",
                                      marginTop: 77,
                                      fontWeight: "600",
                                    }}
                                  >
                                    No advices remaining,
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 15,
                                      color: "#464E5F",
                                      fontWeight: "600",
                                    }}
                                  >
                                    kindly contact advisor
                                  </Text>
                                </>
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  ) : null}
                  {(this.state.is_preferred_circle &&
                    this.state.show_finplan_review) ||
                  (this.state.is_club &&
                    this.state.mf_latest_value > 1000000) ? (
                    <View style={{ marginBottom: 40 }}>
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View
                          style={{
                            marginLeft: "5%",
                            marginRight: "5%",
                            backgroundColor: "white",
                            width: "90%",
                            height: 210,
                            borderWidth: 1,
                            borderRadius: 12,
                            borderColor: "rgba(65, 13, 170, 0.3)",
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              marginLeft: "3%",
                              marginRight: "3%",
                              flexDirection: "row",
                              marginTop: "6%",
                              justifyContent: "space-between",
                            }}
                          >
                            <View
                              style={{
                                flex: 1,
                                flexDirection: "column",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginBottom: "20%",
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                                  }}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                                <Text
                                  style={{
                                    fontSize: 15,
                                    textDecorationLine: "underline",
                                    color: "#410DAA",
                                    textAlign: "left",
                                    marginLeft: "1%",
                                  }}
                                  onPress={() => {}}
                                >
                                  How it works?
                                </Text>
                              </View>
                              <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/finplan.png",
                                }}
                                style={{
                                  width: 140,
                                  height: 140,
                                  alignSelf: "flex-start",
                                  borderRadius: 4,
                                }}
                                resizeMode={"contain"}
                              />
                            </View>
                            <View
                              style={{
                                flex: 1.5,
                                alignItems: "flex-end",
                                flexDirection: "column",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 18,
                                  color: "#464E5F",
                                  fontWeight: "600",
                                }}
                              >
                                Financial Plan Review
                              </Text>
                              {this.state.is_plan_advises_unlimited ? null : (
                                <Text
                                  style={{
                                    fontSize: 18,
                                    color: "#7b55c3",
                                    fontWeight: "600",
                                    marginTop: "2%",
                                  }}
                                >
                                  {this.state.financialCount} Advices Remaining
                                </Text>
                              )}
                              {this.state.financialCount != "0" ? (
                                <Button
                                  uppercase={false}
                                  mode="contained"
                                  labelStyle={{
                                    color: "#410DAA",
                                    fontWeight: "600",
                                  }}
                                  onPress={() => {
                                    if (this.state.redirect_to_goals) {
                                      this.props.navigation.push("App", {
                                        screen: "Goals",
                                        params: {
                                          screen: "Goals",
                                          params: {},
                                        },
                                      });
                                    } else {
                                      let topic = "FP";
                                      if (
                                        this.state.is_plan_advises_unlimited &&
                                        (this.state.current_subscription_plan ==
                                          "PLY" ||
                                          this.state
                                            .current_subscription_plan ==
                                            "PLB" ||
                                          this.state
                                            .current_subscription_plan == "PLQ")
                                      ) {
                                        topic = "LIP";
                                      } else if (
                                        this.state.is_plan_advises_unlimited &&
                                        (this.state.current_subscription_plan ==
                                          "PHA" ||
                                          this.state
                                            .current_subscription_plan ==
                                            "PHB" ||
                                          this.state
                                            .current_subscription_plan == "PHQ")
                                      ) {
                                        topic = "HIP";
                                      } else {
                                        topic = "FP";
                                      }
                                      this.props.navigation.push("App", {
                                        screen: "Dashboard",
                                        params: {
                                          screen: "Dashboard",
                                          params: {
                                            screen: "ScheduleCall",
                                            params: {
                                              topic: topic,
                                            },
                                          },
                                        },
                                      });
                                    }
                                  }}
                                  style={{
                                    marginTop: 77,
                                    borderColor: "#410DAA",
                                    borderWidth: 1,
                                    backgroundColor: "white",
                                    borderRadius: 6,
                                    height: 37,
                                    width: 160,
                                  }}
                                >
                                  Schedule Review
                                </Button>
                              ) : (
                                <>
                                  <Text
                                    style={{
                                      fontSize: 15,
                                      color: "#464E5F",
                                      marginTop: 77,
                                      fontWeight: "600",
                                    }}
                                  >
                                    No advices remaining,
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 15,
                                      color: "#464E5F",
                                      fontWeight: "600",
                                    }}
                                  >
                                    kindly contact advisor
                                  </Text>
                                </>
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  ) : null}
                  {this.state.is_preferred_circle ? (
                    <View style={{ marginBottom: 80 }}>
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View
                          style={{
                            marginLeft: "5%",
                            marginRight: "5%",
                            backgroundColor: "white",
                            width: "90%",
                            height: 210,
                            borderWidth: 1,
                            borderRadius: 12,
                            borderColor: "rgba(65, 13, 170, 0.3)",
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              marginLeft: "3%",
                              marginRight: "3%",
                              flexDirection: "row",
                              marginTop: "6%",
                              justifyContent: "space-between",
                            }}
                          >
                            <View
                              style={{
                                flex: 1,
                                flexDirection: "column",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginBottom: "28%",
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                                  }}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                                <Text
                                  style={{
                                    fontSize: 15,
                                    textDecorationLine: "underline",
                                    color: "#410DAA",
                                    textAlign: "left",
                                    marginLeft: "1%",
                                  }}
                                  onPress={() => {}}
                                >
                                  How it works?
                                </Text>
                              </View>
                              <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/advice.png",
                                }}
                                style={{
                                  width: 120,
                                  height: 120,
                                  alignSelf: "flex-start",
                                  borderRadius: 4,
                                }}
                                resizeMode={"contain"}
                              />
                            </View>
                            <View
                              style={{
                                flex: 1.5,
                                alignItems: "flex-end",
                                flexDirection: "column",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 18,
                                  color: "#464E5F",
                                  fontWeight: "600",
                                }}
                              >
                                Ad-Hoc Advices
                              </Text>
                              {this.state.is_plan_advises_unlimited ? null : (
                                <Text
                                  style={{
                                    fontSize: 18,
                                    color: "#7b55c3",
                                    fontWeight: "600",
                                    marginTop: "2%",
                                  }}
                                >
                                  {this.state.additionalCount} Advices Remaining
                                </Text>
                              )}
                              {this.state.additionalCount != "0" ? (
                                <Button
                                  uppercase={false}
                                  mode="contained"
                                  labelStyle={{
                                    color: "#410DAA",
                                    fontWeight: "600",
                                  }}
                                  onPress={() => {
                                    this.props.navigation.push("App", {
                                      screen: "Dashboard",
                                      params: {
                                        screen: "Dashboard",
                                        params: {
                                          screen: "ScheduleCall",
                                          params: {
                                            topic: "OTH",
                                          },
                                        },
                                      },
                                    });
                                  }}
                                  style={{
                                    marginTop: 77,
                                    borderColor: "#410DAA",
                                    borderWidth: 1,
                                    backgroundColor: "white",
                                    borderRadius: 6,
                                    height: 37,
                                    width: 160,
                                  }}
                                >
                                  Get Advice
                                </Button>
                              ) : (
                                <>
                                  <Text
                                    style={{
                                      fontSize: 15,
                                      color: "#464E5F",
                                      marginTop: 77,
                                      fontWeight: "600",
                                    }}
                                  >
                                    No advices remaining,
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 15,
                                      color: "#464E5F",
                                      fontWeight: "600",
                                    }}
                                  >
                                    kindly contact advisor
                                  </Text>
                                </>
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  ) : null}
                  <View style={{ marginBottom: 80 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View
                        style={{
                          marginLeft: "5%",
                          marginRight: "5%",
                          backgroundColor: "white",
                          width: "90%",
                          height: 210,
                          borderWidth: 1,
                          borderRadius: 12,
                          borderColor: "rgba(65, 13, 170, 0.3)",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                            justifyContent: "space-between",
                          }}
                        >
                          <View>
                            <Text
                              style={{
                                fontFamily: "Roboto",
                                color: "#212121",
                                fontWeight: "bold",
                                fontSize: 17,
                                marginBottom: 20,
                              }}
                            >
                              Assess Financial Wellness
                            </Text>
                            {isMobile ? (
                              <View>
                                <View>
                                  <Text
                                    style={{
                                      color: "#464E5F",
                                      fontSize: 14,
                                      paddingBottom: 5,
                                    }}
                                  >
                                    Short quizzes to measure your Financial
                                  </Text>
                                </View>
                                <View>
                                  <Text
                                    style={{
                                      color: "#464E5F",
                                      fontSize: 14,
                                      paddingBottom: 10,
                                    }}
                                  >
                                    Wellness
                                  </Text>
                                </View>
                              </View>
                            ) : (
                              <View>
                                <Text
                                  style={{
                                    color: "#464E5F",
                                    fontSize: 14,
                                    paddingBottom: 10,
                                  }}
                                >
                                  Short quizzes to measure your Financial
                                  Wellness
                                </Text>
                              </View>
                            )}
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginTop: 2,
                                width: isMobile ? "103%" : "147%",
                              }}
                            >
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/assess_wellness_1.png",
                                  }}
                                  style={{
                                    width: 48,
                                    height: 48,
                                    resizeMode: "contain",
                                    flex: 1,
                                    alignItems: "center",
                                    marginLeft: -7,
                                  }}
                                />
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/assess_wellness_2.png",
                                  }}
                                  style={{
                                    width: 48,
                                    height: 48,
                                    resizeMode: "contain",
                                    flex: 1,
                                    alignItems: "center",
                                  }}
                                />
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/assess_wellness_3.png",
                                  }}
                                  style={{
                                    width: 48,
                                    height: 48,
                                    resizeMode: "contain",
                                    flex: 1,
                                    alignItems: "center",
                                  }}
                                />
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/assess_wellness_4.png",
                                  }}
                                  style={{
                                    width: 48,
                                    height: 48,
                                    resizeMode: "contain",
                                    flex: 1,
                                    alignItems: "center",
                                  }}
                                />
                              </View>
                              {/* <View style={{}}>
                                  <StatusIndicator
                                    percentage={this.state.finData.fa_score}
                                    heading={"Your overall score"}
                                  />
                                </View> */}
                              <View
                                style={{
                                  marginTop: -60,
                                  alignItems: "flex-start",
                                }}
                              >
                                <Button
                                  uppercase={false}
                                  mode="contained"
                                  labelStyle={{
                                    color: "#410DAA",
                                    fontWeight: "600",
                                  }}
                                  onPress={() => {
                                    this.props.navigation.navigate("App", {
                                      screen: "ViewMode",
                                      params: {
                                        screen: "FinAwareness",
                                        params: {
                                          from: "countTracker",
                                        },
                                      },
                                    });
                                  }}
                                  style={{
                                    marginTop: 77,
                                    borderColor: "#410DAA",
                                    borderWidth: 1,
                                    backgroundColor: "white",
                                    borderRadius: 6,
                                    height: 37,
                                    width: 160,
                                  }}
                                >
                                  Assess Now
                                </Button>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginBottom: 80 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View
                        style={{
                          marginLeft: "5%",
                          marginRight: "5%",
                          width: "90%",
                          borderWidth: 1,
                          borderRadius: 12,
                          borderColor: "rgba(65, 13, 170, 0.3)",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                          }}
                        >
                          <View>
                            <Text
                              style={{
                                fontFamily: "Roboto",
                                color: "#212121",
                                fontWeight: "bold",
                                fontSize: 17,
                                marginBottom: 40,
                              }}
                            >
                              {isJama() ? "Jama Academy" : "Maxiom Academy"}
                            </Text>
                            <View
                              style={{
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <View>
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/academy.png",
                                  }}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    marginTop: -20,
                                    marginRight: -5,
                                    resizeMode: "contain",
                                  }}
                                />
                              </View>
                              <View style={{ padding: 15 }}></View>
                              {isMobile ? (
                                <View>
                                  <View>
                                    <Text
                                      style={{
                                        color: "#464E5F",
                                        fontSize: 14,
                                        paddingBottom: 5,
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Investments, goal planning and
                                    </Text>
                                  </View>
                                  <View>
                                    <Text
                                      style={{
                                        color: "#464E5F",
                                        fontSize: 14,
                                        paddingBottom: 10,
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      taxes simplified
                                    </Text>
                                  </View>
                                  <View>
                                    <Text
                                      style={{
                                        color: "#410DAA",
                                        fontSize: 14,
                                        fontWeight: "700",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Learn and Test your knowledge
                                    </Text>
                                  </View>
                                </View>
                              ) : (
                                <View>
                                  <View>
                                    <Text
                                      style={{
                                        color: "#464E5F",
                                        fontSize: 14,
                                        paddingBottom: 10,
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Investments, goal planning and taxes
                                      simplified
                                    </Text>
                                  </View>
                                  <View>
                                    <Text
                                      style={{
                                        color: "#410DAA",
                                        fontSize: 14,
                                        fontWeight: "700",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Learn and Test your knowledge
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </View>
                          </View>
                          <View
                            style={{
                              alignItems: "flex-start",
                              flexDirection: "column",
                              marginBottom: 20,
                            }}
                          >
                            <Button
                              uppercase={false}
                              mode="contained"
                              labelStyle={{
                                color: "#410DAA",
                                fontWeight: "600",
                              }}
                              onPress={() => {
                                // this.props.navigation.navigate("App", {
                                //   screen: "Dashboard",
                                //   params: {
                                //     screen: "Dashboard",
                                //     params: {
                                //       screen: "EduHome",
                                //       params: {
                                //         from: "countTracker",
                                //       },
                                //     },
                                //   },
                                // });
                                this.props.navigation.navigate("App", {
                                  screen: "ViewMode",
                                  params: {
                                    screen: "EduHome",
                                    params: {},
                                  },
                                });
                              }}
                              style={{
                                marginTop: 20,
                                borderColor: "#410DAA",
                                borderWidth: 1,
                                backgroundColor: "white",
                                borderRadius: 6,
                                height: 37,
                                width: 160,
                              }}
                            >
                              Start Learning
                            </Button>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </>
              ) : (
                <>
                  <View
                    style={{
                      marginTop: this.state.is_preferred_circle ? 20 : 0,
                      marginBottom: isMobile
                        ? 80
                        : this.state.is_preferred_circle
                        ? 20
                        : 0,
                    }}
                  >
                    {this.state.is_preferred_circle &&
                    !this.state.is_plan_advises_unlimited ? (
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View
                          style={{
                            marginLeft: "3%",
                            marginRight: "3%",
                            backgroundColor: "white",
                            width: "94%",
                            height: 110,
                            borderWidth: 1,
                            borderRadius: 12,
                            borderColor: "rgba(65, 13, 170, 0.3)",
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              marginLeft: "1.9%",
                              marginRight: "1.9%",
                              flexDirection: "row",
                              marginTop: "0.35%",
                              justifyContent: "space-between",
                            }}
                          >
                            <View
                              style={{
                                flex: 1,
                                flexDirection: "column",
                                marginTop: "1.3%",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginBottom: "4%",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 20,
                                    color: "#464E5F",
                                    fontWeight: "700",
                                  }}
                                >
                                  Advices Remaining{" "}
                                  {this.state.totalCount -
                                    this.state.availedCount}
                                  /{this.state.totalCount}
                                </Text>
                              </View>

                              <Text
                                style={{
                                  fontSize: 18,
                                  color: "#6b717f",
                                  fontWeight: "500",
                                }}
                              >
                                Pack Expires on:{" "}
                                <Text
                                  style={{
                                    fontSize: 18,
                                    color: "#8d6ecc",
                                    fontWeight: "500",
                                  }}
                                >
                                  {moment(this.state.endDate).format(
                                    "DD MMM YYYY"
                                  )}
                                </Text>
                              </Text>
                            </View>
                            <View
                              style={{
                                flex: 1,
                                alignItems: "flex-end",
                                flexDirection: "column",
                                marginRight: "-2.5%",
                              }}
                            >
                              {Platform.OS == "web" ? (
                                <View
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "row",
                                  }}
                                >
                                  <View
                                    style={{
                                      flexDirection: "column",
                                      marginTop: "-2%",
                                    }}
                                  >
                                    <View
                                      style={[
                                        {
                                          alignSelf: "flex-start",
                                          flexDirection: "row",
                                          marginVertical: 7.5,
                                        },
                                      ]}
                                    >
                                      <View
                                        style={{
                                          width: 15,
                                          height: 15,
                                          borderRadius: 2,
                                          backgroundColor: "#876CDD",
                                          marginTop: 1,
                                        }}
                                      />
                                      <Text
                                        style={{
                                          fontSize: 14,
                                          color: "#464E5F",
                                          paddingLeft: 13,
                                          fontWeight: "700",
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        Availed {this.state.availedCount}
                                      </Text>
                                    </View>
                                    <View
                                      style={[
                                        {
                                          alignSelf: "flex-start",
                                          flexDirection: "row",
                                          marginVertical: 7.5,
                                        },
                                      ]}
                                    >
                                      <View
                                        style={{
                                          width: 15,
                                          height: 15,
                                          borderRadius: 2,
                                          backgroundColor: "#BAC7F2",
                                          marginTop: 1,
                                        }}
                                      />
                                      <Text
                                        style={{
                                          fontSize: 14,
                                          color: "#464E5F",
                                          fontWeight: "700",
                                          paddingLeft: 13,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        Remaining{" "}
                                        {this.state.totalCount -
                                          this.state.availedCount}
                                      </Text>
                                    </View>
                                  </View>
                                  <Donut
                                    options={{
                                      dataLabels: {
                                        enabled: false,
                                      },
                                      legend: {
                                        show: false,
                                      },
                                      tooltip: { enabled: false },
                                      colors: ["#876CDD", "#BAC7F2"],
                                      plotOptions: {
                                        pie: {
                                          donut: {
                                            size: "60%",
                                          },
                                        },
                                      },
                                      stroke: {
                                        width: 1,
                                        colors: ["#876CDD", "#BAC7F2"],
                                      },
                                    }}
                                    series={[
                                      (this.state.availedCount /
                                        (this.state.availedCount +
                                          this.state.totalCount -
                                          this.state.availedCount)) *
                                        100,
                                      ((this.state.totalCount -
                                        this.state.availedCount) /
                                        (this.state.availedCount +
                                          this.state.totalCount -
                                          this.state.availedCount)) *
                                        100,
                                    ]}
                                    sliceColor={["#876CDD", "#BAC7F2"]}
                                    type="donut"
                                    width="150"
                                  />
                                </View>
                              ) : (
                                <Donut
                                  widthAndHeight={100}
                                  series={[
                                    (this.state.availedCount /
                                      (this.state.availedCount +
                                        this.state.totalCount -
                                        this.state.availedCount)) *
                                      100,
                                    ((this.state.totalCount -
                                      this.state.availedCount) /
                                      (this.state.availedCount +
                                        this.state.totalCount -
                                        this.state.availedCount)) *
                                      100,
                                  ]}
                                  sliceColor={["#876CDD", "#BAC7F2"]}
                                  doughnut={true}
                                  coverRadius={0.6}
                                  coverFill={"#FFF"}
                                />
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    ) : null}
                  </View>
                  <View style={{ marginBottom: isMobile ? 80 : 0 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View
                        style={{
                          marginLeft: "3%",
                          marginRight: "1.5%",
                          backgroundColor: "white",
                          paddingTop: 8,
                          marginVertical: "1.5%",
                          width: "45.5%",
                          height: 210,
                          borderWidth: 1,
                          borderRadius: 12,
                          borderColor: "rgba(65, 13, 170, 0.3)",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                            justifyContent: "space-between",
                          }}
                        >
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "column",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                marginBottom: "5%",
                              }}
                            >
                              <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                                }}
                                style={{
                                  width: 20,
                                  height: 20,
                                  alignSelf: "flex-start",
                                  borderRadius: 4,
                                }}
                                resizeMode={"contain"}
                              />
                              <Text
                                style={{
                                  fontSize: 15,
                                  textDecorationLine: "underline",
                                  color: "#410DAA",
                                  textAlign: "left",
                                  marginLeft: "1%",
                                }}
                                onPress={() => {}}
                              >
                                How does it work?
                              </Text>
                            </View>
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/stock.png",
                              }}
                              style={{
                                width: 150,
                                height: 150,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                          </View>
                          <View
                            style={{
                              flex: 1,
                              alignItems: "flex-end",
                              flexDirection: "column",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 20,
                                color: "#464E5F",
                                fontWeight: "600",
                              }}
                            >
                              Stock Recommendations
                            </Text>
                            {this.state.is_preferred_circle ? (
                              <>
                                {this.state.is_plan_advises_unlimited ? null : (
                                  <Text
                                    style={{
                                      fontSize: 20,
                                      color: "#7b55c3",
                                      fontWeight: "600",
                                      marginTop: "5%",
                                    }}
                                  >
                                    {this.state.stockCount} Advices Remaining
                                  </Text>
                                )}
                                {this.state.stockCount != "0" ? (
                                  <Button
                                    uppercase={false}
                                    mode="contained"
                                    labelStyle={{
                                      color: "#410DAA",
                                      fontWeight: "600",
                                    }}
                                    onPress={() => {
                                      this.props.navigation.navigate("App", {
                                        screen: "ViewMode",
                                        params: {
                                          screen: "StockRecommendations",
                                          params: {},
                                        },
                                      });
                                    }}
                                    style={{
                                      marginTop: 77,
                                      borderColor: "#410DAA",
                                      borderWidth: 1,
                                      backgroundColor: "white",
                                      borderRadius: 6,
                                      height: 37,
                                      width: 160,
                                    }}
                                  >
                                    Get Stock Advice
                                  </Button>
                                ) : (
                                  <>
                                    <Text
                                      style={{
                                        marginTop: 37,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.show_corporate_stock_msg
                                        ? "Visit your Corporate Benefit Portal"
                                        : "No advices remaining,"}
                                    </Text>
                                    <Text style={{ fontWeight: "bold" }}>
                                      {this.state.show_corporate_stock_msg
                                        ? "and choose any 'Stock Portfolio' plan"
                                        : "kindly contact advisor"}
                                    </Text>
                                    {this.state
                                      .show_corporate_stock_msg ? null : (
                                      <Button
                                        uppercase={false}
                                        mode="contained"
                                        labelStyle={{
                                          color: "#410DAA",
                                          fontWeight: "600",
                                        }}
                                        onPress={async () => {
                                          this.setState({
                                            showLoader: false,
                                            showErrorToast: false,
                                          });
                                          this.props.navigation.push("App", {
                                            screen: "Dashboard",
                                            params: {
                                              screen: "Dashboard",
                                              params: {
                                                screen: "ScheduleCall",
                                                params: {
                                                  topic: "ANI",
                                                },
                                              },
                                            },
                                          });
                                        }}
                                        style={{
                                          marginTop: 10,
                                          borderColor: "#410DAA",
                                          borderWidth: 1,
                                          backgroundColor: "white",
                                          borderRadius: 6,
                                          height: 37,
                                          width: 160,
                                        }}
                                      >
                                        Contact Advisor
                                      </Button>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <View
                                style={{
                                  paddingTop: "14%",
                                }}
                              >
                                <Text
                                  style={{ marginTop: 37, fontWeight: "bold" }}
                                >
                                  To get Stock Advices,
                                </Text>
                                <Text style={{ fontWeight: "bold" }}>
                                  kindly click on Upgrade
                                </Text>
                                <Button
                                  uppercase={false}
                                  mode="contained"
                                  labelStyle={{
                                    color: "#410DAA",
                                    fontWeight: "600",
                                  }}
                                  onPress={async () => {
                                    this.props.navigation.navigate("App", {
                                      screen: "Plans",
                                      params: {
                                        screen: "SubscriptionPlanHome",
                                        params: {
                                          show_club: false,
                                          show_preferredcircle: true,
                                          show_elite: true,
                                          cip_pk_for_renewal: null,
                                        },
                                      },
                                    });
                                  }}
                                  style={{
                                    marginTop: 10,
                                    borderColor: "#410DAA",
                                    borderWidth: 1,
                                    backgroundColor: "white",
                                    borderRadius: 6,
                                    height: 37,
                                    width: 160,
                                  }}
                                >
                                  Upgrade Now
                                </Button>
                              </View>
                            )}
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          marginRight: "3%",
                          marginLeft: "1.5%",
                          backgroundColor: "white",
                          paddingTop: 8,
                          marginVertical: "1.5%",
                          width: "45.5%",
                          height: 210,
                          borderWidth: 1,
                          borderRadius: 12,
                          borderColor: "rgba(65, 13, 170, 0.3)",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                            justifyContent: "space-between",
                          }}
                        >
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "column",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                marginBottom: "5%",
                              }}
                            >
                              <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                                }}
                                style={{
                                  width: 20,
                                  height: 20,
                                  alignSelf: "flex-start",
                                  borderRadius: 4,
                                }}
                                resizeMode={"contain"}
                              />
                              <Text
                                style={{
                                  fontSize: 15,
                                  textDecorationLine: "underline",
                                  color: "#410DAA",
                                  textAlign: "left",
                                  marginLeft: "1%",
                                }}
                                onPress={() => {}}
                              >
                                How does it work?
                              </Text>
                            </View>
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/mf.png",
                              }}
                              style={{
                                width: 156,
                                height: 156,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                          </View>
                          <View
                            style={{
                              flex: 1,
                              alignItems: "flex-end",
                              flexDirection: "column",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 20,
                                color: "#464E5F",
                                fontWeight: "600",
                              }}
                            >
                              Mutual Fund Packs
                            </Text>
                            {this.state.is_preferred_circle &&
                            !this.state.is_plan_advises_unlimited ? (
                              <Text
                                style={{
                                  fontSize: 20,
                                  color: "#7b55c3",
                                  fontWeight: "600",
                                  marginTop: "5%",
                                }}
                              >
                                {this.state.mfCount} Advices Remaining
                              </Text>
                            ) : null}
                            {this.state.mfCount != "0" || this.state.is_club ? (
                              <Button
                                uppercase={false}
                                mode="contained"
                                labelStyle={{
                                  color: "#410DAA",
                                  fontWeight: "600",
                                }}
                                onPress={async () => {
                                  this.props.navigation.navigate("App", {
                                    screen: "ViewMode",
                                    params: {
                                      screen: "InvestForGoals",
                                      params: {
                                        from: "Plans"
                                      },
                                    },
                                  });
                                }}
                                style={{
                                  marginTop: this.state.is_preferred_circle
                                    ? 77
                                    : 114,
                                  borderColor: "#410DAA",
                                  borderWidth: 1,
                                  backgroundColor: "white",
                                  borderRadius: 6,
                                  height: 37,
                                  width: 160,
                                }}
                              >
                                Get Fund Advice
                              </Button>
                            ) : (
                              <>
                                <Text
                                  style={{
                                    marginTop: 40,
                                    fontWeight: "600",
                                  }}
                                >
                                  {this.state.show_corporate_mf_msg
                                    ? "Visit your Corporate Benefit Portal"
                                    : "No advices remaining,"}
                                </Text>
                                <Text
                                  style={{
                                    fontWeight: "600",
                                  }}
                                >
                                  {this.state.show_corporate_mf_msg
                                    ? "and choose any 'MF Portfolio' plan"
                                    : "kindly contact advisor"}
                                </Text>
                                {this.state.show_corporate_mf_msg ? null : (
                                  <Button
                                    uppercase={false}
                                    mode="contained"
                                    labelStyle={{
                                      color: "#410DAA",
                                      fontWeight: "600",
                                    }}
                                    onPress={async () => {
                                      this.setState({
                                        showLoader: false,
                                        showErrorToast: false,
                                      });
                                      this.props.navigation.push("App", {
                                        screen: "Dashboard",
                                        params: {
                                          screen: "Dashboard",
                                          params: {
                                            screen: "ScheduleCall",
                                            params: {
                                              topic: "ANI",
                                            },
                                          },
                                        },
                                      });
                                    }}
                                    style={{
                                      marginTop: 10,
                                      borderColor: "#410DAA",
                                      borderWidth: 1,
                                      backgroundColor: "white",
                                      borderRadius: 6,
                                      height: 37,
                                      width: 160,
                                    }}
                                  >
                                    Contact Advisor
                                  </Button>
                                )}
                              </>
                            )}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginBottom: isMobile ? 80 : 20 }}>
                    {this.state.is_preferred_circle ||
                    (this.state.is_club &&
                      this.state.mf_latest_value > 1000000) ? (
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        {this.state.show_portfolio_review ? (
                          <View
                            style={{
                              marginLeft: "3%",
                              marginRight: "1.5%",
                              backgroundColor: "white",
                              paddingTop: 8,
                              marginVertical: "1.5%",
                              width: "45.5%",
                              height: 210,
                              borderWidth: 1,
                              borderRadius: 12,
                              borderColor: "rgba(65, 13, 170, 0.3)",
                            }}
                          >
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "4%",
                                marginRight: "4%",
                                marginTop: "2%",
                                justifyContent: "space-between",
                              }}
                            >
                              <View
                                style={{
                                  flex: 1,
                                  flexDirection: "column",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    marginBottom: "5%",
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                                    }}
                                    style={{
                                      width: 20,
                                      height: 20,
                                      alignSelf: "flex-start",
                                      borderRadius: 4,
                                    }}
                                    resizeMode={"contain"}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 15,
                                      textDecorationLine: "underline",
                                      color: "#410DAA",
                                      textAlign: "left",
                                      marginLeft: "1%",
                                    }}
                                    onPress={() => {}}
                                  >
                                    How does it work?
                                  </Text>
                                </View>
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolioValue.png",
                                  }}
                                  style={{
                                    width: 144,
                                    height: 144,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                              </View>
                              <View
                                style={{
                                  flex: 1,
                                  alignItems: "flex-end",
                                  flexDirection: "column",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 20,
                                    color: "#464E5F",
                                    fontWeight: "600",
                                  }}
                                >
                                  Portfolio Review
                                </Text>
                                {this.state.is_plan_advises_unlimited ? null : (
                                  <Text
                                    style={{
                                      fontSize: 20,
                                      color: "#7b55c3",
                                      fontWeight: "600",
                                      marginTop: "5%",
                                    }}
                                  >
                                    {this.state.portfolioCount} Advices
                                    Remaining
                                  </Text>
                                )}
                                {this.state.portfolioCount != "0" ? (
                                  <Button
                                    uppercase={false}
                                    mode="contained"
                                    labelStyle={{
                                      color: "#410DAA",
                                      fontWeight: "600",
                                    }}
                                    onPress={() => {
                                      if (
                                        this.state.is_plan_advises_unlimited &&
                                        this.state.corporate_id
                                      ) {
                                        this.props.navigation.navigate("App", {
                                          screen: "ViewMode",
                                          params: {
                                            screen: "ImportStocks",
                                            params: {
                                              from: "CountTrackerHome",
                                            },
                                          },
                                        });
                                      } else {
                                        this.props.navigation.push("App", {
                                          screen: "Dashboard",
                                          params: {
                                            screen: "Dashboard",
                                            params: {
                                              screen: "ScheduleCall",
                                              params: {
                                                topic: "RPP",
                                              },
                                            },
                                          },
                                        });
                                      }
                                    }}
                                    style={{
                                      marginTop: 77,
                                      borderColor: "#410DAA",
                                      borderWidth: 1,
                                      backgroundColor: "white",
                                      borderRadius: 6,
                                      height: 37,
                                      width: 160,
                                    }}
                                  >
                                    Schedule Review
                                  </Button>
                                ) : (
                                  <>
                                    <Text
                                      style={{
                                        marginTop: 37,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      No advices remaining,
                                    </Text>
                                    <Text style={{ fontWeight: "bold" }}>
                                      Kindly contact advisor
                                    </Text>
                                    <Button
                                      uppercase={false}
                                      mode="contained"
                                      labelStyle={{
                                        color: "#410DAA",
                                        fontWeight: "600",
                                      }}
                                      onPress={async () => {
                                        this.setState({
                                          showLoader: false,
                                          showErrorToast: false,
                                        });
                                        this.props.navigation.push("App", {
                                          screen: "Dashboard",
                                          params: {
                                            screen: "Dashboard",
                                            params: {
                                              screen: "ScheduleCall",
                                              params: {
                                                topic: "RPP",
                                              },
                                            },
                                          },
                                        });
                                      }}
                                      style={{
                                        marginTop: 10,
                                        borderColor: "#410DAA",
                                        borderWidth: 1,
                                        backgroundColor: "white",
                                        borderRadius: 6,
                                        height: 37,
                                        width: 160,
                                      }}
                                    >
                                      Contact Advisor
                                    </Button>
                                  </>
                                )}
                              </View>
                            </View>
                          </View>
                        ) : null}
                        {this.state.show_finplan_review ? (
                          <View
                            style={{
                              marginRight: "3%",
                              marginLeft: "1.5%",
                              backgroundColor: "white",
                              paddingTop: 8,
                              marginVertical: "1.5%",
                              width: "45.5%",
                              height: 210,
                              borderWidth: 1,
                              borderRadius: 12,
                              borderColor: "rgba(65, 13, 170, 0.3)",
                            }}
                          >
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "4%",
                                marginRight: "4%",
                                marginTop: "2%",
                                justifyContent: "space-between",
                              }}
                            >
                              <View
                                style={{
                                  flex: 1,
                                  flexDirection: "column",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    marginBottom: "5%",
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                                    }}
                                    style={{
                                      width: 20,
                                      height: 20,
                                      alignSelf: "flex-start",
                                      borderRadius: 4,
                                    }}
                                    resizeMode={"contain"}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 15,
                                      textDecorationLine: "underline",
                                      color: "#410DAA",
                                      textAlign: "left",
                                      marginLeft: "1%",
                                    }}
                                    onPress={() => {}}
                                  >
                                    How does it work?
                                  </Text>
                                </View>
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/finplan.png",
                                  }}
                                  style={{
                                    width: 178,
                                    height: 178,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                              </View>
                              <View
                                style={{
                                  flex: 1,
                                  alignItems: "flex-end",
                                  flexDirection: "column",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 20,
                                    color: "#464E5F",
                                    fontWeight: "600",
                                  }}
                                >
                                  Financial Plan Review
                                </Text>
                                {this.state.is_plan_advises_unlimited ? null : (
                                  <Text
                                    style={{
                                      fontSize: 20,
                                      color: "#7b55c3",
                                      fontWeight: "600",
                                      marginTop: "5%",
                                    }}
                                  >
                                    {this.state.financialCount} Advices
                                    Remaining
                                  </Text>
                                )}
                                {this.state.financialCount != "0" ? (
                                  <Button
                                    uppercase={false}
                                    mode="contained"
                                    labelStyle={{
                                      color: "#410DAA",
                                      fontWeight: "600",
                                    }}
                                    onPress={() => {
                                      // if (this.state.redirect_to_goals) {
                                      //   this.props.navigation.push("App", {
                                      //     screen: "Goals",
                                      //     params: {
                                      //       screen: "Goals",
                                      //       params: {},
                                      //     },
                                      //   });
                                      // } else {
                                      let topic = "FP";
                                      if (
                                        this.state.is_plan_advises_unlimited &&
                                        (this.state.current_subscription_plan ==
                                          "PLY" ||
                                          this.state
                                            .current_subscription_plan ==
                                            "PLB" ||
                                          this.state
                                            .current_subscription_plan == "PLQ")
                                      ) {
                                        topic = "LIP";
                                      } else if (
                                        this.state.is_plan_advises_unlimited &&
                                        (this.state.current_subscription_plan ==
                                          "PHA" ||
                                          this.state
                                            .current_subscription_plan ==
                                            "PHB" ||
                                          this.state
                                            .current_subscription_plan == "PHQ")
                                      ) {
                                        topic = "HIP";
                                      } else {
                                        topic = "FP";
                                      }
                                      this.props.navigation.push("App", {
                                        screen: "Dashboard",
                                        params: {
                                          screen: "Dashboard",
                                          params: {
                                            screen: "ScheduleCall",
                                            params: {
                                              topic: topic,
                                            },
                                          },
                                        },
                                      });
                                      // }
                                    }}
                                    style={{
                                      marginTop: 77,
                                      borderColor: "#410DAA",
                                      borderWidth: 1,
                                      backgroundColor: "white",
                                      borderRadius: 6,
                                      height: 37,
                                      width: 160,
                                    }}
                                  >
                                    Schedule Review
                                  </Button>
                                ) : (
                                  <>
                                    <Text
                                      style={{
                                        marginTop: 37,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      No advices remaining,
                                    </Text>
                                    <Text style={{ fontWeight: "bold" }}>
                                      Kindly contact advisor
                                    </Text>
                                    <Button
                                      uppercase={false}
                                      mode="contained"
                                      labelStyle={{
                                        color: "#410DAA",
                                        fontWeight: "600",
                                      }}
                                      onPress={async () => {
                                        this.setState({
                                          showLoader: false,
                                          showErrorToast: false,
                                        });
                                        this.props.navigation.push("App", {
                                          screen: "Dashboard",
                                          params: {
                                            screen: "Dashboard",
                                            params: {
                                              screen: "ScheduleCall",
                                              params: {
                                                topic: "FP",
                                              },
                                            },
                                          },
                                        });
                                      }}
                                      style={{
                                        marginTop: 10,
                                        borderColor: "#410DAA",
                                        borderWidth: 1,
                                        backgroundColor: "white",
                                        borderRadius: 6,
                                        height: 37,
                                        width: 160,
                                      }}
                                    >
                                      Contact Advisor
                                    </Button>
                                  </>
                                )}
                              </View>
                            </View>
                          </View>
                        ) : null}
                      </View>
                    ) : null}
                  </View>
                  {this.state.is_preferred_circle ? (
                    <View style={{ marginBottom: isMobile ? 80 : 20 }}>
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View
                          style={{
                            marginLeft: "3%",
                            marginRight: "3%",
                            backgroundColor: "white",
                            paddingTop: 8,
                            width: "94%",
                            height: 210,
                            borderWidth: 1,
                            borderRadius: 12,
                            borderColor: "rgba(65, 13, 170, 0.3)",
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              marginLeft: "1.9%",
                              marginRight: "1.9%",
                              flexDirection: "row",
                              marginTop: "1%",
                              justifyContent: "space-between",
                            }}
                          >
                            <View
                              style={{
                                flex: 1,
                                flexDirection: "column",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginBottom: "2%",
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                                  }}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                                <Text
                                  style={{
                                    fontSize: 15,
                                    textDecorationLine: "underline",
                                    color: "#410DAA",
                                    textAlign: "left",
                                    marginLeft: "1%",
                                  }}
                                  onPress={() => {}}
                                >
                                  How does it work?
                                </Text>
                              </View>
                              <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/advice.png",
                                }}
                                style={{
                                  width: 170,
                                  height: 170,
                                  alignSelf: "flex-start",
                                  borderRadius: 4,
                                }}
                                resizeMode={"contain"}
                              />
                            </View>
                            <View
                              style={{
                                flex: 1,
                                alignItems: "flex-end",
                                flexDirection: "column",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 20,
                                  color: "#464E5F",
                                  fontWeight: "600",
                                }}
                              >
                                Ad-Hoc Advices
                              </Text>
                              {this.state.is_plan_advises_unlimited ? null : (
                                <Text
                                  style={{
                                    fontSize: 20,
                                    color: "#7b55c3",
                                    fontWeight: "600",
                                    marginTop: "2%",
                                  }}
                                >
                                  {this.state.additionalCount} Advices Remaining
                                </Text>
                              )}
                              {this.state.additionalCount != "0" ? (
                                <Button
                                  uppercase={false}
                                  mode="contained"
                                  labelStyle={{
                                    color: "#410DAA",
                                    fontWeight: "600",
                                  }}
                                  onPress={() => {
                                    this.props.navigation.push("App", {
                                      screen: "Dashboard",
                                      params: {
                                        screen: "Dashboard",
                                        params: {
                                          screen: "ScheduleCall",
                                          params: {
                                            topic: "OTH",
                                          },
                                        },
                                      },
                                    });
                                  }}
                                  style={{
                                    marginTop: 77,
                                    borderColor: "#410DAA",
                                    borderWidth: 1,
                                    backgroundColor: "white",
                                    borderRadius: 6,
                                    height: 37,
                                    width: 160,
                                  }}
                                >
                                  Get Advice
                                </Button>
                              ) : (
                                <>
                                  <Text
                                    style={{
                                      marginTop: 37,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    No advices remaining,
                                  </Text>
                                  <Text style={{ fontWeight: "bold" }}>
                                    Kindly contact advisor
                                  </Text>
                                  <Button
                                    uppercase={false}
                                    mode="contained"
                                    labelStyle={{
                                      color: "#410DAA",
                                      fontWeight: "600",
                                    }}
                                    onPress={async () => {
                                      this.setState({
                                        showLoader: false,
                                        showErrorToast: false,
                                      });
                                      this.props.navigation.push("App", {
                                        screen: "Dashboard",
                                        params: {
                                          screen: "Dashboard",
                                          params: {
                                            screen: "ScheduleCall",
                                            params: {
                                              topic: "OTH",
                                            },
                                          },
                                        },
                                      });
                                    }}
                                    style={{
                                      marginTop: 10,
                                      borderColor: "#410DAA",
                                      borderWidth: 1,
                                      backgroundColor: "white",
                                      borderRadius: 6,
                                      height: 37,
                                      width: 160,
                                    }}
                                  >
                                    Contact Advisor
                                  </Button>
                                </>
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  ) : null}
                  <View style={{ marginBottom: 80, marginTop: 20 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View
                        style={{
                          marginLeft: "3%",
                          marginRight: "1.5%",
                          backgroundColor: "white",
                          paddingTop: 8,
                          width: "45.5%",
                          height: 210,
                          borderWidth: 1,
                          borderRadius: 12,
                          borderColor: "rgba(65, 13, 170, 0.3)",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                            justifyContent: "space-between",
                          }}
                        >
                          <View>
                            <Text
                              style={{
                                fontFamily: "Roboto",
                                color: "#212121",
                                fontWeight: "bold",
                                fontSize: 17,
                                marginBottom: 20,
                              }}
                            >
                              Assess Financial Wellness
                            </Text>
                            {isMobile ? (
                              <View>
                                <View>
                                  <Text
                                    style={{
                                      color: "#464E5F",
                                      fontSize: 14,
                                      paddingBottom: 5,
                                    }}
                                  >
                                    Short quizzes to measure your Financial
                                  </Text>
                                </View>
                                <View>
                                  <Text
                                    style={{
                                      color: "#464E5F",
                                      fontSize: 14,
                                      paddingBottom: 10,
                                    }}
                                  >
                                    Wellness
                                  </Text>
                                </View>
                              </View>
                            ) : (
                              <View>
                                <Text
                                  style={{
                                    color: "#464E5F",
                                    fontSize: 14,
                                    paddingBottom: 10,
                                  }}
                                >
                                  Short quizzes to measure your Financial
                                  Wellness
                                </Text>
                              </View>
                            )}
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                marginTop: 2,
                                width: isMobile ? "103%" : "147%",
                              }}
                            >
                              <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/assess_wellness_1.png",
                                }}
                                style={{
                                  width: 48,
                                  height: 48,
                                  resizeMode: "contain",
                                  flex: 1,
                                  alignItems: "center",
                                  marginLeft: -7,
                                }}
                              />
                              <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/assess_wellness_2.png",
                                }}
                                style={{
                                  width: 48,
                                  height: 48,
                                  resizeMode: "contain",
                                  flex: 1,
                                  alignItems: "center",
                                }}
                              />
                              <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/assess_wellness_3.png",
                                }}
                                style={{
                                  width: 48,
                                  height: 48,
                                  resizeMode: "contain",
                                  flex: 1,
                                  alignItems: "center",
                                }}
                              />
                              <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/assess_wellness_4.png",
                                }}
                                style={{
                                  width: 48,
                                  height: 48,
                                  resizeMode: "contain",
                                  flex: 1,
                                  alignItems: "center",
                                }}
                              />
                              <View style={{ marginTop: -20 }}>
                                <StatusIndicator
                                  percentage={this.state.finData.fa_score}
                                  heading={"Your overall score"}
                                />
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              flex: 1,
                              marginTop: 60,
                              alignItems: "flex-end",
                              flexDirection: "column",
                            }}
                          >
                            <Button
                              uppercase={false}
                              mode="contained"
                              labelStyle={{
                                color: "#410DAA",
                                fontWeight: "600",
                              }}
                              onPress={() => {
                                this.props.navigation.navigate("App", {
                                  screen: "ViewMode",
                                  params: {
                                    screen: "FinAwareness",
                                    params: {
                                      from: "countTracker",
                                    },
                                  },
                                });
                              }}
                              style={{
                                marginTop: 77,
                                borderColor: "#410DAA",
                                borderWidth: 1,
                                backgroundColor: "white",
                                borderRadius: 6,
                                height: 37,
                                width: 160,
                              }}
                            >
                              Assess Now
                            </Button>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          marginRight: "3%",
                          marginLeft: "1.5%",
                          backgroundColor: "white",
                          paddingTop: 8,
                          width: "45.5%",
                          height: 210,
                          borderWidth: 1,
                          borderRadius: 12,
                          borderColor: "rgba(65, 13, 170, 0.3)",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                            justifyContent: "space-between",
                          }}
                        >
                          <View>
                            <Text
                              style={{
                                fontFamily: "Roboto",
                                color: "#212121",
                                fontWeight: "bold",
                                fontSize: 17,
                                marginBottom: 40,
                              }}
                            >
                              {isJama() ? "Jama Academy" : "Maxiom Academy"}
                            </Text>
                            <View
                              style={{
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <View>
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/academy.png",
                                  }}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    marginTop: -20,
                                    marginRight: -5,
                                    resizeMode: "contain",
                                  }}
                                />
                              </View>
                              <View style={{ padding: 15 }}></View>
                              {isMobile ? (
                                <View>
                                  <View>
                                    <Text
                                      style={{
                                        color: "#464E5F",
                                        fontSize: 14,
                                        paddingBottom: 5,
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Investments, goal planning and
                                    </Text>
                                  </View>
                                  <View>
                                    <Text
                                      style={{
                                        color: "#464E5F",
                                        fontSize: 14,
                                        paddingBottom: 10,
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      taxes simplified
                                    </Text>
                                  </View>
                                  <View>
                                    <Text
                                      style={{
                                        color: "#410DAA",
                                        fontSize: 14,
                                        fontWeight: "700",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Learn and Test your knowledge
                                    </Text>
                                  </View>
                                </View>
                              ) : (
                                <View>
                                  <View>
                                    <Text
                                      style={{
                                        color: "#464E5F",
                                        fontSize: 14,
                                        paddingBottom: 10,
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Investments, goal planning and taxes
                                      simplified
                                    </Text>
                                  </View>
                                  <View>
                                    <Text
                                      style={{
                                        color: "#410DAA",
                                        fontSize: 14,
                                        fontWeight: "700",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Learn and Test your knowledge
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </View>
                          </View>
                          <View
                            style={{
                              flex: 1,
                              marginTop: 60,
                              alignItems: "flex-end",
                              flexDirection: "column",
                            }}
                          >
                            <Button
                              uppercase={false}
                              mode="contained"
                              labelStyle={{
                                color: "#410DAA",
                                fontWeight: "600",
                              }}
                              onPress={() => {
                                // this.props.navigation.navigate("App", {
                                //   screen: "Dashboard",
                                //   params: {
                                //     screen: "Dashboard",
                                //     params: {
                                //       screen: "EduHome",
                                //       params: {
                                //         from: "countTracker",
                                //       },
                                //     },
                                //   },
                                // });
                                this.props.navigation.navigate("App", {
                                  screen: "ViewMode",
                                  params: {
                                    screen: "EduHome",
                                    params: { from: "countTrackerHome" },
                                  },
                                });
                              }}
                              style={{
                                marginTop: 77,
                                borderColor: "#410DAA",
                                borderWidth: 1,
                                backgroundColor: "white",
                                borderRadius: 6,
                                height: 37,
                                width: 160,
                              }}
                            >
                              Start Learning
                            </Button>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </>
              )}
            </ScrollView>
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    padding: 5,
  },
  modalContentContainer: {
    backgroundColor: "white",
    padding: 8,
    paddingRight: 0,
    borderRadius: 4,
    width: "100%",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default CountTrackerHome;
