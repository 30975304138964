import React from "react";
import {
  ScrollView,
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  Platform,
} from "react-native";
import { ActivityIndicator, Button, Divider } from "react-native-paper";
import { getCustomerInvoices } from "../../api/billing";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { ProductTabs } from "../../components/UpcomingInvestments/ProductTabs";
import { isMobile } from "../../utils";
import TotalDue from "./components/TotalDueBox";
import NoPendingBills from "./NoPendingBills";
import PendingInvoiceOverdue from "./PendingInvoiceOverdue";
import { EvilIcons } from "@expo/vector-icons";
import Modal from "../../components/Common/Modal";
import BillingLedger from "./plans/BillingLedger";
import Store from "../../store";
import amplitude from "../../utils/amplitude";

const PaymentFailedAlert = ({ onClose, onRetry }) => {
  const handleContact = () => {
    global.open("https://www.jamawealth.com/contact-us/");
  };

  return (
    <Modal style={alert.container}>
      <View style={alert.contentContainer}>
        <View style={alert.alertHeadingContainer}>
          <Text style={alert.failed}>Payment Failed !!</Text>
          <TouchableOpacity onPress={onClose}>
            <EvilIcons name="close" size={20} color="black" />
          </TouchableOpacity>
        </View>
        <Text style={alert.reason}>
          Payment failed : Please try again after sometime(or){" "}
          <Text style={alert.contact} onPress={handleContact}>
            Contact Us
          </Text>{" "}
          for more information.
        </Text>
      </View>
      <Button
        style={alert.make}
        uppercase={false}
        onPress={onRetry}
        mode="contained"
        color="#410DAA"
      >
        Retry Payment
      </Button>
    </Modal>
  );
};

const alert = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
  },
  contentContainer: {
    width: 633,
    paddingHorizontal: 42,
    paddingVertical: 28,
  },
  alertHeadingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  failed: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    color: "#F64E60",
  },
  reason: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 21,
    color: "#121212",
    paddingTop: 17,
    paddingBottom: 31,
  },
  make: {
    alignSelf: "center",
    flex: 1,
    marginBottom: 20,
  },
  contact: {
    color: "#410DAA",
  },
});

const tabsToShow = [
  {
    label: "Overdue Invoices",
    value: "OVERDUE",
  },
  {
    label: "Paid Invoices",
    value: "PAID",
  },
];

class PendingInvoices extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    let fromOnboard = props.fromOnboard ? props.fromOnboard : false;
    let moreDetails = null;
    let showPaymentAlert = null;
    const { params } = this.props.route;
    if (params) {
      userId = params.user_id || null;
      moreDetails = params.moreDetails || null;
      showPaymentAlert = params.a || null;
    }
    if (userId) {
      Store.dispatch({
        type: "GET_DASHBOARD",
        payload: {
          viewModeUserId: {
            user_id: userId,
          },
        },
      });
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      userId,
      moreDetails,
      fromOnboard,
      filterData: [],
      newFilterData: [],
      paymentDataFiltered: 0,
      data: [],
      active: 0,
      showPaymentAlert,
    };

    this.totalDueBox = React.createRef();
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("Pendinginvoices", { path: global.location.href });
      } else {
        amplitude.logEvent("Pendinginvoices", {
          path: this.props.navigation.getState().routeName,
        });
      }
      this.setState({ isLoading: true });
      if (this.state.moreDetails) {
        this.setState({ active: this.state.moreDetails });
      }
      const response = await getCustomerInvoices(this.state.userId);
      if (response && response.code) {
        const { results = {} } = response;
        let { invoices = [], ...remainingData } = results;
        invoices = [...invoices].reverse();
        let filterData = [];
        if (this.state.moreDetails) {
          filterData = invoices.filter(
            (invoice) => invoice.paid_status == "PAID"
          );
        } else {
          filterData = invoices.filter(
            (invoice) => invoice.paid_status != "PAID"
          );
        }
        this.setState({
          paymentData: remainingData,
          data: invoices,
          filterData,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
    }
  };

  selectedInvoiceData = (data) => {
    let total_amount_to_pay = 0;
    let newFilterData = [];
    data.forEach((item) => {
      if (item.toShow) {
        total_amount_to_pay = total_amount_to_pay + item.due_with_gst;
        newFilterData.push(item);
      }
    });
    this.setState({
      paymentDataFiltered: {
        total_amount_to_pay: parseInt(total_amount_to_pay),
      },
      newFilterData,
    });
  };

  onSelectTab = (tabIndex) => {
    try {
      const { data = [] } = this.state;
      let filtered_data = [];
      filtered_data = data.filter((item) => {
        const { paid_status } = item;
        return tabIndex == 1 ? paid_status == "PAID" : paid_status != "PAID";
      });
      this.setState({ active: tabIndex, filterData: filtered_data });
    } catch (error) {}
  };

  onSelectHeader = (tabHeaderIndex) => {
    try {
      this.setState({ activeHeader: tabHeaderIndex });
    } catch (error) {}
  };

  handleCloseAlert = () => {
    this.setState({ showPaymentAlert: false });
  };

  handleRetryPayment = () => {
    this.totalDueBox.current.retryPayment();
  };

  handlePlansLedgerClick = () => {
    this.setState({ active: 2 });
  };

  navigateToPlanLedger = () => {
    let url = "/billing/plans/ledger/";
    let options = {};
    if (this.state.userId) {
      options["user_id"] = this.state.userId;
      url += "?user_id=" + this.state.userId;
    }
    this.props.navigation.navigate("BillingLedger", { ...options });
  };

  render() {
    const {
      active,
      isLoading,
      filterData = [],
      paymentData = {},
      paymentDataFiltered,
      newFilterData,
      showPaymentAlert = false,
    } = this.state;
    const amount_due = filterData.reduce((accumulator, object) => {
      return accumulator + object.amount_due;
    }, 0);
    const amount_due_gst = filterData.reduce((accumulator, object) => {
      return accumulator + object.due_with_gst;
    }, 0);
    const amount_paid = filterData.reduce((accumulator, object) => {
      return accumulator + object.amount_paid;
    }, 0);
    const amount_paid_gst = filterData.reduce((accumulator, object) => {
      return accumulator + object.paid_with_gst;
    }, 0);
    const footer_data = {
      amount_due: amount_due,
      amount_due_gst: amount_due_gst,
      amount_paid: amount_paid,
      amount_paid_gst: amount_paid_gst,
    };
    return (
      <View style={styles.mainContainer}>
        {!this.state.fromOnboard && (
          <View style={styles.tabs}>
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={["Billing"]}
            />
            <Button
              mode={"contained"}
              uppercase={false}
              style={styles.billingLog}
              onPress={this.navigateToPlanLedger}
              color={"#410DAA"}
              labelStyle={{ color: "#fff", fontSize: 14 }}
            >
              Billing Ledger
            </Button>
          </View>
        )}
        {isLoading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        ) : (
          <>
            {!this.state.fromOnboard && (
              <View style={styles.row}>
                <ProductTabs
                  activeTab={active}
                  tabs={tabsToShow}
                  onSelectTab={this.onSelectTab}
                  data={[]}
                  color="#121212"
                  style={[
                    {
                      borderBottomColor: "transparent",
                      width: "auto",
                    },
                  ]}
                />
              </View>
            )}
            <Divider />
            {showPaymentAlert && (
              <PaymentFailedAlert
                onClose={this.handleCloseAlert}
                onRetry={this.handleRetryPayment}
              />
            )}
            {active == 2 ? (
              <ScrollView style={styles.invoicesContainer}>
                <BillingLedger
                  navigation={this.props.navigation}
                  style={[
                    { width: "100%", height: "100%", backgroundColor: "red" },
                  ]}
                  userId={this.state.userId}
                  showHeader={false}
                />
              </ScrollView>
            ) : (
              <>
                {filterData.length > 0 ? (
                  <ScrollView style={styles.invoicesContainer}>
                    <PendingInvoiceOverdue
                      data={filterData}
                      paymentData={paymentData}
                      navigation={this.props.navigation}
                      route={this.props.route}
                      footer_data={footer_data}
                      activeTab={active}
                      fromOnboard={this.state.fromOnboard}
                      selectedInvoiceData={this.selectedInvoiceData}
                    />
                  </ScrollView>
                ) : (
                  <ScrollView style={styles.invoicesContainer}>
                    <NoPendingBills
                      title={active == 0 ? "No Pending Bills" : "No Paid Bills"}
                    />
                  </ScrollView>
                )}
                {!!(active == 0 && filterData.length) && (
                  <View style={{ width: "100%" }}>
                    <TotalDue
                      ref={this.totalDueBox}
                      data={paymentDataFiltered}
                      invoicesData={newFilterData}
                    />
                  </View>
                )}
              </>
            )}
          </>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#ffffff",
  },
  topHeaderContainer: {
    backgroundColor: "#F6F6F6",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  tabs: {},
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  billingLog: {
    position: "absolute",
    right: 20,
    top: 10,
  },
  invoicesContainer: {
    flex: 1,
    paddingHorizontal: isMobile ? 16 : 31,
    paddingVertical: 28,
  },
  over: {
    fontFamily: "Roboto",
    fontSize: 15,
    color: "#888C9F",
    fontSize: 15,
    lineHeight: 17,
    marginHorizontal: 10,
  },
  plansLedgerContainer: {
    marginLeft: 20,
    borderBottomWidth: 3,
    borderBottomColor: "transparent",
  },
  active: {
    color: "#121212",
    borderBottomWidth: 3,
    borderBottomColor: "#121212",
  },
  plansLedgerText: {
    fontSize: 15,
    color: "#888C9F",
    paddingVertical: 12,
    paddingHorizontal: 30,
  },
  separator: {
    width: 1,
    height: 20,
    backgroundColor: "#888C9F",
    marginLeft: 20,
  },
});

export default PendingInvoices;
