import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  TouchableOpacity,
  Image,
} from "react-native";
import Icon from "@expo/vector-icons/AntDesign";
import Divider from "../../../components/Common/divider";
import {
  encryptToken,
  getUserDetails,
  isMobile,
  removeString,
} from "../../../utils";
import numberFormat from "../../../utils/numberFormat";
import GaugeChart from "react-gauge-chart";
import { MAIN_URL } from "../../../api/urls";
import EIcon from "@expo/vector-icons/Entypo";
import Store from "../../../store";
import moment from "moment";
import { StackActions } from "@react-navigation/native";
import { Popable } from "react-native-popable";
// import Icon from "@expo/vector-icons/FontAwesome";

export const GoalCard = ({
  handleClick,
  handle3dotsClick,
  data,
  selected3DotItem,
  mfAction,
  showActNow = true,
  navigation,
}) => {
  let goalName = data["name"];
  let date = data["target_date_text"];
  let projected = Math.round(data["projected_value"]);
  let achieved = Math.round(data["achieved_value"]);
  let monthlyask = Math.round(data["calculated_monthly_installment"]);
  let current = Math.round(data["current_sip_contibution"]);
  let total = Math.round(data["target_amount_initially_calculated"]);
  let image = data["goal_avatar"];

  let is_preferred_circle = false;
  let is_club = false;
  let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
  if (hostname.includes("myadvisor")) {
    is_preferred_circle = true;
  }
  if (hostname.includes("club")) {
    is_club = true;
  }
  let totalNew = total;
  if (totalNew >= 10000000) {
    totalNew = (totalNew / 10000000).toFixed(1) + " Cr";
  }
  if (totalNew >= 100000) {
    totalNew = (totalNew / 100000).toFixed(1) + " L";
  }
  if (totalNew >= 1000) {
    totalNew = (totalNew / 1000).toFixed(1) + " K";
  }

  let monthlyAskNew = monthlyask;
  if (monthlyAskNew >= 10000000) {
    monthlyAskNew = (monthlyAskNew / 10000000).toFixed(1) + " Cr";
  }
  if (monthlyAskNew >= 100000) {
    monthlyAskNew = (monthlyAskNew / 100000).toFixed(1) + " L";
  }
  if (monthlyAskNew >= 1000) {
    monthlyAskNew = (monthlyAskNew / 1000).toFixed(1) + " K";
  }

  let currentNew = current;
  if (currentNew >= 10000000) {
    currentNew = (currentNew / 10000000).toFixed(1) + " Cr";
  }
  if (currentNew >= 100000) {
    currentNew = (currentNew / 100000).toFixed(1) + " L";
  }
  if (currentNew >= 1000) {
    currentNew = (currentNew / 1000).toFixed(1) + " K";
  }

  let achievedNew = achieved;
  if (achievedNew >= 10000000) {
    achievedNew = (achievedNew / 10000000).toFixed(1) + " Cr";
  }
  if (achievedNew >= 100000) {
    achievedNew = (achievedNew / 100000).toFixed(1) + " L";
  }
  if (achievedNew >= 1000) {
    achievedNew = (achievedNew / 1000).toFixed(1) + " K";
  }

  let projectedNew = projected;
  if (projectedNew >= 10000000) {
    projectedNew = (projectedNew / 10000000).toFixed(1) + " Cr";
  }
  if (projectedNew >= 100000) {
    projectedNew = (projectedNew / 100000).toFixed(1) + " L";
  }
  if (projectedNew >= 1000) {
    projectedNew = (projectedNew / 1000).toFixed(1) + " K";
  }
  const isDeficit = total > projected;
  const deficitOrSurplus = isDeficit ? total - projected : projected - total;
  let deficitOrSurplusNew = deficitOrSurplus;
  if (deficitOrSurplusNew >= 10000000) {
    deficitOrSurplusNew = (deficitOrSurplusNew / 10000000).toFixed(1) + " Cr";
  }
  if (deficitOrSurplusNew >= 100000) {
    deficitOrSurplusNew = (deficitOrSurplusNew / 100000).toFixed(1) + " L";
  }
  if (deficitOrSurplusNew >= 1000) {
    deficitOrSurplusNew = (deficitOrSurplusNew / 1000).toFixed(1) + " K";
  }
  let currentRatioPer = 0;
  let monthlyRatioPer = 0;
  if (current == 0 && monthlyask == 0) {
    currentRatioPer = 0;
    monthlyRatioPer = 1;
  } else {
    currentRatioPer = current / (current + monthlyask);
    monthlyRatioPer = monthlyask / (current + monthlyask);
  }
  currentRatioPer = parseFloat(currentRatioPer.toFixed(2));
  monthlyRatioPer = parseFloat(monthlyRatioPer.toFixed(2));

  const openRetirementPlanner = async () => {
    const { token } = getUserDetails();
    const encryptedToken = encryptToken(token);
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    const url =
      MAIN_URL +
      "/app/rnw_to_web_login?token=" +
      encryptedToken +
      "&is_rnw=1&redirection_url=/app/retirement/age/";

    if (isMobile) {
      navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Portfolio",
          params: {
            screen: "ShowWebView",
            params: {
              url,
              showInWeb: isMobile ? false : true,
            },
          },
        },
      });
    } else {
      let popup_window = window.open(
        url,
        hostname,
        "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=yes,width=1200,height=700"
      );

      try {
        popup_window.focus();
      } catch (e) {
        alert(
          "Pop-up Blocker is enabled! Please add this site to your exception list."
        );
      }
    }
  };

  return (
    <>
      {!showActNow && (
        <View
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            marginBottom: 20,
          }}
        >
          {goalName.toLowerCase().includes("retire") && (
            <Text
              style={{
                color: "#410DAA",
                fontWeight: "bold",
                textAlign: "center",
                fontSize: 13,
                textDecorationLine: "underline",
              }}
              onPress={() => {
                navigation.push("AdvancedRetirementPlanner", {
                  goalId: data.customergoal_id,
                });
              }}
            >
              Advanced Retirement Planner
            </Text>
          )}
        </View>
      )}
      <View
        style={{
          borderColor: "#410DAA33",
          borderWidth: 1,
          borderRadius: 6,
          paddingTop: 5,
          paddingBottom: 13,
          ...styles.container,
        }}
        onPress={() => {
          handleClick ? handleClick() : null;
        }}
      >
        <View style={styles.basicDetails}>
          <View style={styles.goalNameContainer}>
            <View style={styles.goalName}>
              <Image
                style={styles.goalIcon}
                source={{
                  uri: image,
                }}
              />
            </View>
          </View>
          <View style={styles.fundContainer}>
            <Text style={styles.fund}>
              {removeString(goalName, "Goal", "goal")}
              {"\n"}
              <Text style={{ fontWeight: "400", fontSize: 14 }}>
                Due {date}
              </Text>
            </Text>
            {data["status_type"] == "AT_RISK" && (
              <Icon type="AntDesign" name="warning" style={styles.fundIcon} />
            )}
            <Text style={styles.date}></Text>
          </View>
          <View style={styles.dateContainer}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View style={{ flex: 2, marginRight: 20 }}>
                <Text
                  style={{
                    fontSize: 14,
                    color: "#121212",
                  }}
                >
                  Required when due:{" "}
                  <Text style={{ fontWeight: "bold" }}>
                    ₹ {numberFormat(totalNew)}
                  </Text>
                </Text>
              </View>
              {showActNow && (
                <View style={{ flex: 0.2 }}>
                  <EIcon
                    name="dots-three-vertical"
                    color="#9AA3A8"
                    size={24}
                    onPress={() => {
                      handle3dotsClick ? handle3dotsClick() : null;
                    }}
                  />
                </View>
              )}
            </View>
          </View>
        </View>
        <Divider width={1} color="#410DAA33" style={{ marginVertical: 7 }} />
        {data.customergoal_id != selected3DotItem && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: 10,
              width: "100%",
              paddingHorizontal: 15,
              marginBottom: 2,
              justifyContent: "space-between",
            }}
          >
            <View style={{ flexDirection: "column" }}>
              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    width: 15,
                    height: 15,
                    borderRadius: 2,
                    backgroundColor: "#045607",
                    marginTop: isMobile ? 3 : 1,
                  }}
                />
                <Text> Achieved</Text>
              </View>
              <Text
                style={{ fontWeight: "700", color: "black", marginTop: 10 }}
              >
                ₹ {numberFormat(achievedNew)}
              </Text>
            </View>
            <View style={{ flexDirection: "column" }}>
              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    width: 15,
                    height: 15,
                    borderRadius: 2,
                    backgroundColor: "#7DE92D",
                    marginTop: isMobile ? 3 : 1,
                  }}
                />
                <Text> Projected</Text>
              </View>
              <Text
                style={{ fontWeight: "700", color: "black", marginTop: 10 }}
              >
                ₹ {numberFormat(projectedNew)}
              </Text>
            </View>
            <View style={{ flexDirection: "column" }}>
              <View
                style={{ flexDirection: "row", justifyContent: "flex-end" }}
              >
                <View
                  style={{
                    width: 15,
                    height: 15,
                    borderRadius: 2,
                    backgroundColor: isDeficit ? "#fdc6c1" : "#00C107",
                    marginTop: isMobile ? 3 : 1,
                  }}
                />
                <Text> {isDeficit ? " Deficit" : " Surplus"}</Text>
              </View>
              <Text
                style={{
                  fontWeight: "700",
                  color: "black",
                  marginTop: 10,
                  textAlign: "right",
                }}
              >
                ₹ {numberFormat(deficitOrSurplusNew)}
              </Text>
            </View>
          </View>
        )}
        {data.customergoal_id == selected3DotItem && (
          <>
            <View style={{ flexDirection: "row" }}>
              <View
                style={{
                  flex: 2,
                  flexDirection: "row",
                  paddingTop: 10,
                  paddingHorizontal: 15,
                  marginBottom: 2,
                }}
              >
                <View style={{ flexDirection: "column", width: "100%" }}>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ flexDirection: "column", width: "40%" }}>
                      <View style={{ flexDirection: "row" }}>
                        <View
                          style={{
                            width: 15,
                            height: 15,
                            borderRadius: 2,
                            backgroundColor: "#045607",
                            marginTop: isMobile ? 3 : 1,
                          }}
                        />
                        <Text> Achieved</Text>
                      </View>
                      <Text
                        style={{
                          fontWeight: "700",
                          color: "black",
                          marginTop: 10,
                        }}
                      >
                        ₹ {numberFormat(achievedNew)}
                      </Text>
                    </View>
                    <View style={{ flexDirection: "column", width: "28%" }}>
                      <View style={{ flexDirection: "row" }}>
                        <View
                          style={{
                            width: 15,
                            height: 15,
                            borderRadius: 2,
                            backgroundColor: "#7DE92D",
                            marginTop: isMobile ? 3 : 1,
                          }}
                        />
                        <Text> Projected</Text>
                      </View>
                      <Text
                        style={{
                          fontWeight: "700",
                          color: "black",
                          marginTop: 10,
                        }}
                      >
                        ₹ {numberFormat(projectedNew)}
                      </Text>
                    </View>
                    <View style={{ flexDirection: "column", width: "28%" }}>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <View
                          style={{
                            width: 15,
                            height: 15,
                            borderRadius: 2,
                            backgroundColor: isDeficit ? "#fdc6c1" : "#00C107",
                            marginTop: isMobile ? 3 : 1,
                          }}
                        />
                        <Text> {isDeficit ? " Deficit" : " Surplus"}</Text>
                      </View>
                      <Text
                        style={{
                          fontWeight: "700",
                          color: "black",
                          marginTop: 10,
                          textAlign: "right",
                        }}
                      >
                        ₹ {numberFormat(deficitOrSurplusNew)}
                      </Text>
                    </View>
                  </View>
                  <View>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        paddingTop: 10,
                        marginHorizontal: 16,
                      }}
                    >
                      <View style={{ flex: achieved }}>
                        <Text
                          style={{
                            textAlign: "left",
                            color: "#707070",
                            fontSize: 10,
                            paddingBottom: 5,
                          }}
                        ></Text>
                        <View
                          style={{
                            width: "100%",
                            height: 5,
                            backgroundColor: "#045607",
                          }}
                        ></View>
                      </View>
                      <View style={{ flex: projected }}>
                        <Text
                          style={{
                            textAlign: "left",
                            color: "#707070",
                            fontSize: 10,
                            paddingBottom: 5,
                          }}
                        ></Text>
                        <View
                          style={{
                            width: "100%",
                            height: 5,
                            backgroundColor: "#7DE92D",
                          }}
                        ></View>
                      </View>
                      <View style={{ flex: deficitOrSurplus }}>
                        <Text
                          style={{
                            textAlign: "left",
                            color: "#707070",
                            fontSize: 10,
                            paddingBottom: 5,
                          }}
                        ></Text>
                        <View
                          style={{
                            width: "100%",
                            height: 5,
                            backgroundColor: isDeficit ? "#fdc6c1" : "#00C107",
                          }}
                        ></View>
                      </View>
                    </View>
                    {data && data.status_type == "AT_RISK" && (
                      <>
                        {!isMobile ? (
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              marginBottom: 10,
                              marginTop: -10,
                            }}
                          >
                            <View
                              style={{
                                flex: isMobile ? 2.2 : 3,
                                marginTop: -3,
                                marginLeft: 53,
                              }}
                            >
                              {!isMobile && (
                                <GaugeChart
                                  id="gauge-chart3"
                                  nrOfLevels={2}
                                  colors={["#8950FC", "#D4BEFF"]}
                                  percent={currentRatioPer}
                                  hideText={true}
                                  arcsLength={[
                                    currentRatioPer,
                                    monthlyRatioPer,
                                  ]}
                                  style={{ width: 115 }}
                                  needleColor={"black"}
                                  needleBaseColor={"blue"}
                                  arcPadding={-5}
                                  arcWidth={0.2}
                                  cornerRadius={0}
                                />
                              )}
                            </View>
                            {showActNow ? (
                              <>
                                <View
                                  style={{
                                    flex: 1.5,
                                    marginRight: 8,
                                    marginTop: 8,
                                  }}
                                >
                                  <TouchableOpacity
                                    onPress={() => {
                                      handleClick ? handleClick() : null;
                                    }}
                                    style={{
                                      backgroundColor: "#410DAA",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 6,
                                      flexDirection: "row",
                                      paddingHorizontal: 30,
                                      paddingVertical: 12,
                                    }}
                                  >
                                    <Text
                                      style={{ fontSize: 13, color: "#fff" }}
                                    >
                                      Act Now
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                                <View style={{ flex: 0.08 }}></View>
                              </>
                            ) : (
                              <View
                                style={{
                                  flex: 2,
                                  marginTop: 35,
                                }}
                              ></View>
                            )}
                          </View>
                        ) : (
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              marginBottom: 10,
                              marginTop: -10,
                            }}
                          >
                            <View
                              style={{
                                flex: isMobile ? 2.2 : 4,
                                marginTop: -3,
                                marginLeft: 53,
                              }}
                            >
                              {!isMobile && (
                                <GaugeChart
                                  id="gauge-chart3"
                                  nrOfLevels={2}
                                  colors={["#8950FC", "#D4BEFF"]}
                                  percent={currentRatioPer}
                                  hideText={true}
                                  arcsLength={[currentRatioPer, monthlyRatioPer]}
                                  style={{ width: 115 }}
                                  needleColor={"black"}
                                  needleBaseColor={"blue"}
                                  arcPadding={-5}
                                  arcWidth={0.2}
                                  cornerRadius={0}
                                />
                              )}
                            </View>
                            {showActNow ? (
                              <View style={{ flex: 1 }}>
                                <TouchableOpacity
                                  onPress={() => {
                                    handleClick ? handleClick() : null;
                                  }}
                                  style={{
                                    backgroundColor: "#410DAA",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: 40,
                                    borderRadius: 6,
                                    marginRight: 15,
                                    marginTop: isMobile ? 45 : 25,
                                  }}
                                >
                                  <Text style={{ fontSize: 13, color: "#fff" }}>
                                    Act Now
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            ) : (
                              <View
                                style={{
                                  flex: 2,
                                  marginTop: 35,
                                }}
                              ></View>
                            )}
                          </View>
                        )}
                        {isMobile ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              paddingLeft: 15,
                              paddingRight: 13,
                              marginBottom: 2,
                              marginTop: isMobile
                                ? showActNow
                                  ? -60
                                  : -20
                                : -40,
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flex: 1, flexDirection: "column" }}>
                              <View style={{ flexDirection: "row" }}>
                                {Platform.OS == "web" && (
                                  <View
                                    style={{
                                      width: 10,
                                      height: 10,
                                      borderRadius: 2,
                                      backgroundColor: "#915BFD",
                                      marginTop: isMobile ? 6 : 3,
                                    }}
                                  />
                                )}
                                <Text> Current</Text>
                              </View>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  color: "black",
                                  marginTop: 10,
                                }}
                              >
                                {"  "}₹ {numberFormat(currentNew)}
                              </Text>
                            </View>
                            <View style={{ flex: 1, flexDirection: "column" }}>
                              <View style={{ flexDirection: "row" }}>
                                <Text> Monthly Ask</Text>
                                {Platform.OS == "web" && (
                                  <View
                                    style={{
                                      width: 10,
                                      height: 10,
                                      marginLeft: 5,
                                      borderRadius: 2,
                                      backgroundColor: "#D4BEFF",
                                      marginTop: isMobile ? 7 : 4,
                                    }}
                                  />
                                )}
                              </View>
                              <Popable
                                position={"top"}
                                action={"press"}
                                style={{ width: 220 }}
                                content={
                                  <View style={{ backgroundColor: "black" }}>
                                    <Text
                                      style={{
                                        textAlign: "left",
                                        color: "white",
                                        paddingLeft: 5,
                                      }}
                                    >
                                      Assumes Average Return:{" "}
                                      {data["risk_profile"]
                                        ? Math.round(
                                            data["weighted_avg_growth_return"]
                                          ) + "%"
                                        : "12%"}
                                    </Text>
                                    <Text
                                      style={{
                                        textAlign: "left",
                                        color: "white",
                                        paddingLeft: 5,
                                      }}
                                    >
                                      Risk Profile:{" "}
                                      {data["risk_profile"]
                                        ? data["risk_profile"]
                                        : "Unknown"}
                                    </Text>
                                    {data["risk_profile"] ? null : (
                                      <TouchableOpacity
                                        style={{
                                          paddingBottom: 10,
                                        }}
                                        onPress={() => {
                                          navigation.replace("Onboarding", {
                                            screen:
                                              "MiniOnboardingRiskProfileQA",
                                            params: {
                                              from_page: "Goals",
                                            },
                                          });
                                        }}
                                      >
                                        <Text
                                          style={{
                                            textAlign: "left",
                                            color: "white",
                                            paddingLeft: 5,
                                            textDecorationLine: "underline",
                                          }}
                                        >
                                          Get Better Estimate
                                        </Text>
                                      </TouchableOpacity>
                                    )}
                                  </View>
                                }
                              >
                                <View
                                  style={{
                                    flexDirection: "column",
                                    width: 120,
                                    alignItems: isMobile ? "center" : "left",
                                  }}
                                >
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "700",
                                        color: "black",
                                        marginTop: 10,
                                        marginLeft: 5,
                                      }}
                                    >
                                      ₹ {numberFormat(monthlyAskNew)}
                                    </Text>
                                    <View style={{ paddingLeft: 5 }}></View>
                                    <Icon
                                      style={{
                                        marginTop: 6,
                                      }}
                                      name="infocirlce"
                                      size={14}
                                      color={"black"}
                                    />
                                  </View>
                                </View>
                              </Popable>
                            </View>
                            <View
                              style={{ flex: 1.5, flexDirection: "column" }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Text
                                  style={{
                                    fontWeight: "700",
                                    fontSize: 12,
                                    textAlign: "right",
                                    marginTop: 28,
                                    color: "#410DAA",
                                  }}
                                ></Text>
                              </View>
                            </View>
                          </View>
                        ) : (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              paddingLeft: 15,
                              paddingRight: 13,
                              marginBottom: 2,
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flexDirection: "column" }}>
                              <View style={{ flexDirection: "row" }}>
                                <View
                                  style={{
                                    width: 10,
                                    height: 10,
                                    borderRadius: 2,
                                    backgroundColor: "#915BFD",
                                    marginTop: 3,
                                  }}
                                />
                                <Text> Current</Text>
                              </View>
                              <Text
                                style={{
                                  fontWeight: "700",
                                  color: "black",
                                  marginTop: 10,
                                }}
                              >
                                ₹ {numberFormat(currentNew)}
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "column",
                                marginLeft: 60,
                              }}
                            >
                              <View style={{ flexDirection: "row" }}>
                                <Text> Monthly Ask</Text>
                                <View
                                  style={{
                                    width: 10,
                                    height: 10,
                                    marginLeft: 5,
                                    borderRadius: 2,
                                    backgroundColor: "#D4BEFF",
                                    marginTop: 4,
                                  }}
                                />
                              </View>
                              <Popable
                                position={"top"}
                                action={"press"}
                                style={{ width: 220 }}
                                content={
                                  <View style={{ backgroundColor: "black" }}>
                                    <Text
                                      style={{
                                        textAlign: "left",
                                        color: "white",
                                        paddingLeft: 5,
                                      }}
                                    >
                                      Assumes Average Return:{" "}
                                      {data["risk_profile"]
                                        ? Math.round(
                                            data["weighted_avg_growth_return"]
                                          ) + "%"
                                        : "12%"}
                                    </Text>
                                    <Text
                                      style={{
                                        textAlign: "left",
                                        color: "white",
                                        paddingLeft: 5,
                                      }}
                                    >
                                      Risk Profile:{" "}
                                      {data["risk_profile"]
                                        ? data["risk_profile"]
                                        : "Unknown"}
                                    </Text>
                                    {data["risk_profile"] ? null : (
                                      <TouchableOpacity
                                        style={{
                                          paddingBottom: 10,
                                        }}
                                        onPress={() => {
                                          navigation.replace("Onboarding", {
                                            screen:
                                              "MiniOnboardingRiskProfileQA",
                                            params: {
                                              from_page: "Goals",
                                            },
                                          });
                                        }}
                                      >
                                        <Text
                                          style={{
                                            textAlign: "left",
                                            color: "white",
                                            paddingLeft: 5,
                                            textDecorationLine: "underline",
                                          }}
                                        >
                                          Get Better Estimate
                                        </Text>
                                      </TouchableOpacity>
                                    )}
                                  </View>
                                }
                              >
                                <View
                                  style={{
                                    flexDirection: "column",
                                    width: 120,
                                    alignItems: isMobile ? "center" : "left",
                                  }}
                                >
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "700",
                                        color: "black",
                                        marginTop: 10,
                                        marginLeft: 5,
                                      }}
                                    >
                                      ₹ {numberFormat(monthlyAskNew)}
                                    </Text>
                                    <View style={{ paddingLeft: 5 }}></View>
                                    <Icon
                                      style={{
                                        marginTop: 6,
                                      }}
                                      name="infocirlce"
                                      size={14}
                                      color={"black"}
                                    />
                                  </View>
                                </View>
                              </Popable>
                              {/* <Text
                                style={{
                                  fontWeight: "700",
                                  color: "black",
                                  marginTop: 10,
                                  marginLeft: 5,
                                }}
                              >
                                ₹ {numberFormat(monthlyAskNew)}
                              </Text> */}
                            </View>
                            <View style={{ flex: 1, flexDirection: "column" }}>
                              {showActNow && (
                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontWeight: "700",
                                      fontSize: 12,
                                      textAlign: "right",
                                      marginTop: 8,
                                      marginRight: 12,
                                      color: "#410DAA",
                                    }}
                                  ></Text>
                                </View>
                              )}
                            </View>
                          </View>
                        )}
                      </>
                    )}
                  </View>
                </View>
              </View>
              <View style={styles.mainWrapper}>
                {!is_club ? (
                  <TouchableOpacity
                    onPress={() => {
                      if (
                        Store.getState().userData.userProfileData
                          .is_equitycustomer
                      ) {
                        navigation.navigate("App", {
                          screen: "Dashboard",
                          params: {
                            screen: "Portfolio",
                            params: {
                              screen: "UpcomingInvestments",
                              params: {
                                showTopupToast: "true",
                                customerGoalId: data.customergoal_id,
                                customerGoalName: "My Retirement Goal",
                              },
                            },
                          },
                        });
                      } else {
                        // navigation.navigate("App", {
                        //   screen: "Dashboard",
                        //   params: {
                        //     screen: "Dashboard",
                        //     params: {
                        //       screen: "ScheduleCall",
                        //       params: {
                        //         topic: "DE",
                        //       },
                        //     },
                        //   },
                        // });
                        navigation.navigate("App", {
                          screen: "Plans",
                          params: {
                            screen: "PlansHome",
                            params: {
                              activeTabIndex: 0,
                            },
                          },
                        });
                      }
                    }}
                    style={{ paddingVertical: 7 }}
                  >
                    <Text style={styles.linkItems}>Buy stocks for goal</Text>
                  </TouchableOpacity>
                ) : (
                  <View style={{ marginBottom: 8 }}></View>
                )}
                <TouchableOpacity
                  onPress={() => {
                    mfAction(is_preferred_circle);
                  }}
                  style={{ paddingVertical: 7 }}
                >
                  <Text style={styles.linkItems}>
                    Buy mutual funds for goal
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("AttachExisting", {
                      goal_id: data.customergoal_id,
                      active: 1,
                      goal_name: data.name,
                    });
                  }}
                  style={{ paddingVertical: 7 }}
                >
                  <Text style={styles.linkItems}>
                    Attach demat holdings to goal
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("AttachExisting", {
                      goal_id: data.customergoal_id,
                      active: 2,
                      goal_name: data.name,
                    });
                  }}
                  style={{ paddingVertical: 7 }}
                >
                  <Text style={styles.linkItems}>
                    Attach fund folios to goal
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("AttachExisting", {
                      goal_id: data.customergoal_id,
                      active: 3,
                      goal_name: data.name,
                    });
                  }}
                  style={{ paddingVertical: 7 }}
                >
                  <Text style={styles.linkItems}>
                    Attach other assets to goal
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </>
        )}
        {data.customergoal_id != selected3DotItem && (
          <>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                paddingTop: 17,
                marginHorizontal: 16,
              }}
            >
              <View style={{ flex: achieved }}>
                <Text
                  style={{
                    textAlign: "left",
                    color: "#707070",
                    fontSize: 10,
                    paddingBottom: 5,
                  }}
                ></Text>
                <View
                  style={{
                    width: "100%",
                    height: 5,
                    backgroundColor: "#045607",
                  }}
                ></View>
              </View>
              <View style={{ flex: projected }}>
                <Text
                  style={{
                    textAlign: "left",
                    color: "#707070",
                    fontSize: 10,
                    paddingBottom: 5,
                  }}
                ></Text>
                <View
                  style={{
                    width: "100%",
                    height: 5,
                    backgroundColor: "#7DE92D",
                  }}
                ></View>
              </View>
              <View style={{ flex: deficitOrSurplus }}>
                <Text
                  style={{
                    textAlign: "left",
                    color: "#707070",
                    fontSize: 10,
                    paddingBottom: 5,
                  }}
                ></Text>
                <View
                  style={{
                    width: "100%",
                    height: 5,
                    backgroundColor: isDeficit ? "#fdc6c1" : "#00C107",
                  }}
                ></View>
              </View>
            </View>
            {data && data.status_type == "AT_RISK" && (
              <>
                {!isMobile ? (
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      marginBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        flex: isMobile ? 2.2 : 3,
                        marginTop: -3,
                        marginLeft: 53,
                      }}
                    >
                      {!isMobile && (
                        <GaugeChart
                          id="gauge-chart3"
                          nrOfLevels={2}
                          colors={["#8950FC", "#D4BEFF"]}
                          percent={currentRatioPer}
                          hideText={true}
                          arcsLength={[currentRatioPer, monthlyRatioPer]}
                          style={{ width: 115 }}
                          needleColor={"black"}
                          needleBaseColor={"blue"}
                          arcPadding={-5}
                          arcWidth={0.2}
                          cornerRadius={0}
                        />
                      )}
                    </View>
                    {showActNow ? (
                      <>
                        <View style={{ flex: 0.67 }}>
                          <TouchableOpacity
                            onPress={() => {
                              handleClick ? handleClick() : null;
                            }}
                            style={{
                              backgroundColor: "#410DAA",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 6,
                              flexDirection: "row",
                              paddingHorizontal: 30,
                              paddingVertical: 12,
                            }}
                          >
                            <Text style={{ fontSize: 13, color: "#fff" }}>
                              Act Now
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ flex: 0.08 }}></View>
                      </>
                    ) : (
                      <View
                        style={{
                          flex: 2,
                          marginTop: 35,
                        }}
                      ></View>
                    )}
                  </View>
                ) : (
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      marginBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        flex: isMobile ? 2.2 : 4,
                        marginTop: -3,
                        marginLeft: 53,
                      }}
                    >
                      {!isMobile && (
                        <GaugeChart
                          id="gauge-chart3"
                          nrOfLevels={2}
                          colors={["#8950FC", "#D4BEFF"]}
                          percent={currentRatioPer}
                          hideText={true}
                          arcsLength={[currentRatioPer, monthlyRatioPer]}
                          style={{ width: 115 }}
                          needleColor={"black"}
                          needleBaseColor={"blue"}
                          arcPadding={-5}
                          arcWidth={0.2}
                          cornerRadius={0}
                        />
                      )}
                    </View>
                    {showActNow ? (
                      <View style={{ flex: 1 }}>
                        <TouchableOpacity
                          onPress={() => {
                            handleClick ? handleClick() : null;
                          }}
                          style={{
                            backgroundColor: "#410DAA",
                            alignItems: "center",
                            justifyContent: "center",
                            height: 40,
                            borderRadius: 6,
                            marginRight: 15,
                            marginTop: isMobile ? 20 : 25,
                            marginBottom: isMobile ? 50 : 0,
                          }}
                        >
                          <Text style={{ fontSize: 13, color: "#fff" }}>
                            Act Now
                          </Text>
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <View
                        style={{
                          flex: 2,
                          marginTop: 35,
                        }}
                      ></View>
                    )}
                  </View>
                )}
                {isMobile ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      paddingLeft: 15,
                      paddingRight: 13,
                      marginBottom: 2,
                      marginTop: isMobile ? (showActNow ? -60 : -20) : -40,
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ flex: 1, flexDirection: "column" }}>
                      <View style={{ flexDirection: "row" }}>
                        {!isMobile && (
                          <View
                            style={{
                              width: 10,
                              height: 10,
                              borderRadius: 2,
                              backgroundColor: "#915BFD",
                              marginTop: isMobile ? 6 : 3,
                            }}
                          />
                        )}
                        <Text> Current</Text>
                      </View>
                      <Text
                        style={{
                          fontWeight: "700",
                          color: "black",
                          marginTop: 10,
                        }}
                      >
                        {"  "}₹ {numberFormat(currentNew)}
                      </Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: "column" }}>
                      <View style={{ flexDirection: "row" }}>
                        <Text> Monthly Ask</Text>
                        {!isMobile && (
                          <View
                            style={{
                              width: 10,
                              height: 10,
                              marginLeft: 5,
                              borderRadius: 2,
                              backgroundColor: "#D4BEFF",
                              marginTop: isMobile ? 7 : 4,
                            }}
                          />
                        )}
                      </View>
                      <Popable
                        position={"top"}
                        action={"press"}
                        style={{ width: 220 }}
                        content={
                          <View style={{ backgroundColor: "black" }}>
                            <Text
                              style={{
                                textAlign: "left",
                                color: "white",
                                paddingLeft: 5,
                              }}
                            >
                              Assumes Average Return:{" "}
                              {data["risk_profile"]
                                ? Math.round(
                                    data["weighted_avg_growth_return"]
                                  ) + "%"
                                : "12%"}
                            </Text>
                            <Text
                              style={{
                                textAlign: "left",
                                color: "white",
                                paddingLeft: 5,
                              }}
                            >
                              Risk Profile:{" "}
                              {data["risk_profile"]
                                ? data["risk_profile"]
                                : "Unknown"}
                            </Text>
                            {data["risk_profile"] ? null : (
                              <TouchableOpacity
                                style={{
                                  paddingBottom: 10,
                                }}
                                onPress={() => {
                                  navigation.replace("Onboarding", {
                                    screen: "MiniOnboardingRiskProfileQA",
                                    params: {
                                      from_page: "Goals",
                                    },
                                  });
                                }}
                              >
                                <Text
                                  style={{
                                    textAlign: "left",
                                    color: "white",
                                    paddingLeft: 5,
                                    textDecorationLine: "underline",
                                  }}
                                >
                                  Get Better Estimate
                                </Text>
                              </TouchableOpacity>
                            )}
                          </View>
                        }
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            width: 120,
                            alignItems: isMobile ? "center" : "left",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: "700",
                                color: "black",
                                marginTop: 10,
                                marginLeft: 5,
                              }}
                            >
                              ₹ {numberFormat(monthlyAskNew)}
                            </Text>
                            <View style={{ paddingLeft: 5 }}></View>
                            <Icon
                              style={{
                                marginTop: 6,
                              }}
                              name="infocirlce"
                              size={14}
                              color={"black"}
                            />
                          </View>
                        </View>
                      </Popable>
                      {/* <Text
                        style={{
                          fontWeight: "700",
                          color: "black",
                          marginTop: 10,
                          marginLeft: 5,
                        }}
                      >
                        ₹ {numberFormat(monthlyAskNew)}
                      </Text> */}
                    </View>
                    <View style={{ flex: 1.5, flexDirection: "column" }}>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: "700",
                            fontSize: 12,
                            textAlign: "right",
                            marginTop: 28,
                            color: "#410DAA",
                          }}
                        ></Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      paddingLeft: 15,
                      paddingRight: 13,
                      marginBottom: 2,
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ flexDirection: "column" }}>
                      <View style={{ flexDirection: "row" }}>
                        <View
                          style={{
                            width: 10,
                            height: 10,
                            borderRadius: 2,
                            backgroundColor: "#915BFD",
                            marginTop: 3,
                          }}
                        />
                        <Text> Current</Text>
                      </View>
                      <Text
                        style={{
                          fontWeight: "700",
                          color: "black",
                          marginTop: 10,
                        }}
                      >
                        ₹ {numberFormat(currentNew)}
                      </Text>
                    </View>
                    <View style={{ flexDirection: "column", marginLeft: 60 }}>
                      <View style={{ flexDirection: "row" }}>
                        <Text> Monthly Ask</Text>
                        <View
                          style={{
                            width: 10,
                            height: 10,
                            marginLeft: 5,
                            borderRadius: 2,
                            backgroundColor: "#D4BEFF",
                            marginTop: 4,
                          }}
                        />
                      </View>
                      <Popable
                        position={"top"}
                        action={"press"}
                        style={{ width: 220 }}
                        content={
                          <View style={{ backgroundColor: "black" }}>
                            <Text
                              style={{
                                textAlign: "left",
                                color: "white",
                                paddingLeft: 5,
                              }}
                            >
                              Assumes Average Return:{" "}
                              {data["risk_profile"]
                                ? Math.round(
                                    data["weighted_avg_growth_return"]
                                  ) + "%"
                                : "12%"}
                            </Text>
                            <Text
                              style={{
                                textAlign: "left",
                                color: "white",
                                paddingLeft: 5,
                              }}
                            >
                              Risk Profile:{" "}
                              {data["risk_profile"]
                                ? data["risk_profile"]
                                : "Unknown"}
                            </Text>
                            {data["risk_profile"] ? null : (
                              <TouchableOpacity
                                style={{
                                  paddingBottom: 10,
                                }}
                                onPress={() => {
                                  navigation.replace("Onboarding", {
                                    screen: "MiniOnboardingRiskProfileQA",
                                    params: {
                                      from_page: "Goals",
                                    },
                                  });
                                }}
                              >
                                <Text
                                  style={{
                                    textAlign: "left",
                                    color: "white",
                                    paddingLeft: 5,
                                    textDecorationLine: "underline",
                                  }}
                                >
                                  Get Better Estimate
                                </Text>
                              </TouchableOpacity>
                            )}
                          </View>
                        }
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            width: 120,
                            alignItems: isMobile ? "center" : "left",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: "700",
                                color: "black",
                                marginTop: 10,
                                marginLeft: 5,
                              }}
                            >
                              ₹ {numberFormat(monthlyAskNew)}
                            </Text>
                            <View style={{ paddingLeft: 5 }}></View>
                            <Icon
                              style={{
                                marginTop: 6,
                              }}
                              name="infocirlce"
                              size={14}
                              color={"black"}
                            />
                          </View>
                        </View>
                      </Popable>
                      {/* <Text
                        style={{
                          fontWeight: "700",
                          color: "black",
                          marginTop: 10,
                          marginLeft: 5,
                        }}
                      >
                        ₹ {numberFormat(monthlyAskNew)}
                      </Text> */}
                    </View>
                    <View style={{ flex: 1, flexDirection: "column" }}>
                      {showActNow && (
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: 12,
                              textAlign: "right",
                              marginTop: 8,
                              marginRight: 12,
                              color: "#410DAA",
                            }}
                          ></Text>
                        </View>
                      )}
                    </View>
                  </View>
                )}
              </>
            )}
          </>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  basicDetails: {
    flexDirection: "row",
    paddingLeft: isMobile ? 8 : 16,
    paddingRight: 13,
    alignItems: "center",
  },
  goalName: {
    width: 38,
    height: 38,
    borderRadius: 19,
    backgroundColor: "#f2f2f2",
    justifyContent: "center",
    alignItems: "center",
  },
  goalNameText: { alignSelf: "center" },
  goalNameContainer: {
    flex: 0.15,
    maxWidth: 60,
    paddingTop: isMobile ? 0 : 8,
    paddingBottom: isMobile ? 0 : 8,
  },
  fundContainer: { flex: 0.55, flexDirection: "row", alignItems: "center" },
  fund: { color: "#121212", fontWeight: "bold", fontSize: 15, paddingLeft: 5 },
  fundIcon: { paddingLeft: 10, color: "#EF5D12", fontSize: 14 },
  dateContainer: {
    flex: 0.4,
    flexDirection: "column",
    alignItems: "flex-end",
    paddingRight: isMobile ? 0 : 6,
  },
  date: { color: "black", fontSize: 14 },
  secondRowContainer: {
    flexDirection: "row",
    paddingHorizontal: 13,
    paddingBottom: 13,
  },
  projectedLabel: {
    width: 7,
    height: 7,
    backgroundColor: "#00C107",
    marginLeft: 10,
  },
  projectedText: { color: "#121212", fontSize: 14 },
  projected: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: isMobile ? 0 : 8,
  },
  projectedAmount: { color: "#121212", fontSize: 14 },
  projectedContainer: { flex: 0.5, flexDirection: "column" },

  deficitLabel: {
    width: 7,
    height: 7,
    backgroundColor: "#DB7657",
    marginLeft: 10,
  },
  deficitText: { color: "#121212", fontSize: 14 },
  deficit: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: isMobile ? 0 : 8,
  },
  deficitAmount: { color: "#121212", fontSize: 14 },
  deficitContainer: { flex: 0.5, flexDirection: "column" },

  thirdContainer: {
    flexDirection: "row",
    paddingHorizontal: 15,
    marginVertical: 10,
  },
  mainWrapper: {
    flex: 1,
    backgroundColor: "#ffffff",
    shadowColor: "gray",
    shadowRadius: 5,
    borderRadius: 6,
    paddingHorizontal: isMobile ? 14 : 20,
    paddingTop: 10,
    paddingBottom: 10,
    marginRight: 20,
    marginTop: 10,
    marginBottom: 5,
  },
  linkItems: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#465964",
  },
  progressBar: {
    flex: 1,
    height: 5,
    flexDirection: "row",
    borderRadius: 6,
  },
  goalIcon: { width: 23, height: 23, resizeMode: "contain" },
});
