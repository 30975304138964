import React, { useState } from "react";
import { View, Text, StyleSheet, Dimensions, Platform, TouchableOpacity, ActivityIndicator } from "react-native";
import { isMobile, months_short_names, numberWithCommas } from "../../../../utils";
import { Switch } from "react-native-paper";
import { Popable } from "react-native-popable";
import Icon from "@expo/vector-icons/FontAwesome";
import { Picker } from "@react-native-picker/picker";
import PickerFieldSelect from "../../../Common/Pickers/PickerFieldSelect";
import Store from "../../../../store";
import { md } from "../../../../Responsive";
const { width } = Dimensions.get("window");

const selectData = [
  {
    label: "Since Inception",
    value: "INC",
  },
  {
    label: "Active Plans",
    value: "ACT",
  },
];

let is_preferred_circle = false;
let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
if (hostname.includes("myadvisor")) {
  is_preferred_circle = true;
}

const isWeb = md(width);

const SelectProduct = ({
  reportLoading,
  viewReport
}) => {
  let hostname = global.location.hostname;
  let is_club = false;
  if (hostname.includes("club")) {
    is_club = true;
  }
  return (
    <View style={select.container}>
      <View style={select.body}>
        {!isMobile &&
          Dimensions.get("window").width / Dimensions.get("window").height >
          2.1 && !is_club && (
            <View
              style={{
                flex: 1,
                justifyContent: "flex-end",
                flexDirection: "row",
                // marginRight: 20,
              }}
            >
              {reportLoading ? (
                <View style={select.loadingConainer}>
                  <ActivityIndicator size="large" color="#410DAA" />
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    viewReport();
                  }}
                >
                  <Text
                    style={{
                      color: "#410DAA",
                      fontWeight: "bold",
                      fontSize: 15,
                      textAlign: "right",
                      // marginRight: isMobile ? "auto" : 25,
                      textDecorationLine: "underline",
                    }}
                  >
                    Monthly Report
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          )}
      </View>
      {(isMobile ||
        Dimensions.get("window").width / Dimensions.get("window").height <=
        2.1) && !is_club && (
          <View
            style={{
              flex: 1,
              cursor: "pointer",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            {reportLoading ? (
              <View style={select.loadingConainer}>
                <ActivityIndicator size="large" color="#410DAA" />
              </View>
            ) : (
              <TouchableOpacity
                onPress={() => {
                  viewReport();
                }}
              >
                <Text
                  style={{
                    color: "#410DAA",
                    fontWeight: "bold",
                    fontSize: 15,
                    textAlign: "right",
                    // marginRight: isMobile ? "auto" : 25,
                    textDecorationLine: "underline",
                  }}
                >
                  Monthly Report
                </Text>
              </TouchableOpacity>
            )}
          </View>
        )}
    </View>
  );
};

const select = StyleSheet.create({
  container: {
    // marginTop: 10,
    // marginBottom: 20,
    // marginLeft: isMobile ? 16 : 20,
    // marginRight: isMobile ? 16 : 10,
  },
  product: {
    color: "#464E5F",
    fontSize: 14,
    fontFamily: "Roboto",
    marginRight: 20,
  },
  body: {
    // flexDirection: "row",
    // alignItems: "center",
    // display: "flex",
  },
  button: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 14 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  loadingConainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

const ToggleSummaryView = (props) => {
  const { value, onValueChange } = props;
  const handleValueChange = () => {
    onValueChange();
  };

  const [currentSelected, setCurrentSelected] = useState("INC");

  return (
    <View style={toggleStyles.container}>
      {isMobile ? (
        <View style={{ borderWidth: 1, borderColor: "#12121210" }}>
          <PickerFieldSelect
            data={selectData}
            value={currentSelected}
            label={null}
            onChange={(value) => {
              setCurrentSelected(value);
              handleValueChange(value);
            }}
            style={{
              width: 300,
            }}
            type="select"
          />
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: "flex-end",
            width: "100%",
            alignItems: "flex-end",
          }}
        >
          {!is_preferred_circle && (
            <Picker
              selectedValue={currentSelected}
              onValueChange={(value) => {
                setCurrentSelected(value);
                handleValueChange(value);
              }}
              style={{
                width: isMobile ? "100%" : 120,
                borderRadius: 5,
                borderColor: "white",
                height: 44,
              }}
              itemStyle={{ height: 44 }}
            >
              {selectData.map((item) => {
                return (
                  <Picker.Item
                    label={item.label}
                    value={item.value}
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: 14,
                      color: "#202020",
                    }}
                  />
                );
              })}
            </Picker>
          )}
        </View>
      )}
    </View>
  );
};

const SummaryTable = ({ active, data = {}, activeTab }) => {
  const { stocks_summary = {}, plan_data = {} } = data;

  let currentValue = active
    ? plan_data["plan_current_value"]
    : stocks_summary["stock_latest"];
  let originalInvested = active
    ? plan_data["plan_invested_value"]
    : stocks_summary["stock_inv"];
  let dividend = active
    ? plan_data["plan_dividend"]
    : stocks_summary["stock_dividend"];
  let avgHoldingPeriod = active
    ? plan_data["plan_holding_period_text"]
    : stocks_summary["stock_holding"];
  let cashInHand = active ? plan_data["plan_cash_in_hand"] : 0;
  let currentInvestment = active
    ? 0
    : stocks_summary["stock_inv_value_current"];
  let totalGainPerc = active
    ? plan_data["plan_abs_gain_perc"]
    : stocks_summary["stock_abs_gain"];

  let committedAUM = active ? plan_data["plan_committed_aum"] : 0;

  const unrealisedGainValue = active ? (plan_data["plan_unrealised_gain"] ? plan_data["plan_unrealised_gain"] : 0) : 0;
  const realisedGainValue = active ? (plan_data["plan_realised_gain"] ? plan_data["plan_realised_gain"] : 0) : 0;

  let unrealisedGain = unrealisedGainValue;
  let realisedGain = realisedGainValue;

  let xirrPerc = active ? plan_data["plan_xirr"] : stocks_summary["stock_xirr"];
  let totalGain = active
    ? plan_data["plan_gain"]
    : stocks_summary["stock_gain"];

  let first_cip_start_date = active ? plan_data["first_cip_start_date"] : stocks_summary["first_cip_start_date"]

  let portfolio_since_value = null;
  if (first_cip_start_date) {
    portfolio_since_value = first_cip_start_date.split("-");
    const month = months_short_names(parseInt(portfolio_since_value[1]));
    portfolio_since_value = month + " " + portfolio_since_value[0];
  }

  currentValue = currentValue ? parseInt(currentValue) : 0;
  currentValue = "₹" + numberWithCommas(currentValue);

  originalInvested = originalInvested ? parseInt(originalInvested) : 0;
  originalInvested = Math.abs(originalInvested);
  originalInvested = "₹" + numberWithCommas(originalInvested);

  dividend = dividend ? parseInt(dividend) : 0;
  dividend = "₹" + numberWithCommas(dividend);

  cashInHand = cashInHand ? parseInt(cashInHand) : 0;
  cashInHand = "₹" + numberWithCommas(cashInHand);

  currentInvestment = currentInvestment ? parseInt(currentInvestment) : 0;
  currentInvestment = "₹" + numberWithCommas(currentInvestment);

  totalGainPerc = totalGainPerc ? Number(totalGainPerc).toFixed(1) : 0;

  unrealisedGain = unrealisedGain ? parseInt(unrealisedGain) : 0;
  unrealisedGain = "₹" + numberWithCommas(Math.abs(unrealisedGain));

  realisedGain = realisedGain ? parseInt(realisedGain) : 0;
  realisedGain = Math.abs(realisedGain);
  realisedGain = "₹" + numberWithCommas(realisedGain);
  xirrPerc = xirrPerc ? Number(xirrPerc).toFixed(1) : 0;

  totalGain = totalGain ? parseInt(totalGain) : 0;
  totalGain = "₹" + numberWithCommas(totalGain);

  committedAUM = committedAUM ? parseInt(committedAUM) : 0;
  committedAUM = "₹" + numberWithCommas(committedAUM);
  // console.log(realisedGainValue, unrealisedGainValue)
  // let portfolio_since_text = ""
  // if (activeTab == "Inside Stocks") {
  //   if (active) {
  //     portfolio_since_text = "All stocks portfolio"
  //   } else {
  //     portfolio_since_text = "Active plans"
  //   }
  // } else if (activeTab == "JEWEL") {
  //   if (active) {
  //     portfolio_since_text = "Jewel portfolio"
  //   } else {
  //     portfolio_since_text = "Jewel active plans"
  //   }
  // } else if (activeTab == "SPARK") {
  //   if (active) {
  //     portfolio_since_text = "Spark portfolio"
  //   } else {
  //     portfolio_since_text = "Spark active plans"
  //   }
  // }
  // portfolio_since_text = portfolio_since_text + " since " + portfolio_since_value + ", Avg holding period " + avgHoldingPeriod
  return (
    active ? (
      <View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            paddingLeft: isMobile ? 12 : 20,
            alignItems: isMobile ? "flex-start" : "center",
          }}
        >
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {`Unrealised\nGain/Loss`}
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {unrealisedGainValue > 0
                  ? " "
                  : unrealisedGainValue == 0
                    ? " "
                    : "- "}
                {unrealisedGain}
              </Text>
            </View>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {`Total\nGain`}
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {totalGain}
              </Text>
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {`Realised\nGain/Loss`}
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {realisedGainValue > 0
                  ? " "
                  : realisedGainValue == 0
                    ? " "
                    : "- "}
                {realisedGain}
              </Text>
            </View>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {`Total\nGain%`}
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {totalGainPerc}%
              </Text>
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {`Dividend`}
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {dividend}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#fff",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                .
              </Text>
            </View>
            <View style={{ flexDirection: "column" }}>
              <Popable
                position={"top"}
                action={isMobile ? "press" : "hover"}
                style={{ width: 150 }}
                content={
                  <View style={{ backgroundColor: "black" }}>
                    <Text
                      style={{
                        textAlign: "left",
                        color: "white",
                        padding: 10,
                      }}
                    >
                      Yearly gain on units held.
                    </Text>
                  </View>
                }
              >
                <View
                  style={{
                    flexDirection: "column",
                    width: 120,
                    alignItems: isMobile ? "center" : "left"
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    <Text
                      style={{
                        paddingTop: 20,
                        fontSize: 14,
                        fontWeight: "400",
                        color: "#878EA8",
                        textAlign: isMobile ? "center" : "left",
                      }}
                    >
                      {`XIRR\n% p.a.`}
                    </Text>
                    <Icon
                      style={{
                        marginTop: 6,
                      }}
                      name="info-circle"
                      size={14}
                      color={"black"}
                    />
                  </View>
                </View>
              </Popable>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                {xirrPerc}%
              </Text>
            </View>
          </View>
        </View>
        {/* <Text
            style={{
              textAlign: "right",
              padding: 10,
              fontSize: 12,
              fontStyle: "italic",
              fontWeight: 400
            }}
          >{portfolio_since_text}</Text> */}
      </View>
    ) : (
      <View>
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: isMobile ? 10 : 0,
          }}
        >
          <View style={{ flex: 1 }}>
            <View
              style={{ flexDirection: "column", paddingTop: isMobile ? 15 : 0, alignSelf: "center" }}
            >
              <Text
                style={{
                  paddingTop: 6,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                  alignSelf: "center",
                }}
              >
                Gain/Loss %
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  alignSelf: "center",
                }}
              >
                {totalGainPerc}%
              </Text>
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View
              style={{ flexDirection: "column", paddingTop: isMobile ? 15 : 0, alignSelf: "center", }}
            >
              <Text
                style={{
                  paddingTop: 6,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                  alignSelf: "center",
                }}
              >
                Gain/Loss
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  alignSelf: "center",
                }}
              >
                {totalGain}
              </Text>
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View
              style={{ flexDirection: "column", paddingTop: isMobile ? 15 : 0 }}
            >
              <Popable
                position={"top"}
                action={isMobile ? "press" : "hover"}
                style={{ width: 150 }}
                content={
                  <View style={{ backgroundColor: "black" }}>
                    <Text
                      style={{
                        textAlign: "left",
                        color: "white",
                        padding: 10,
                      }}
                    >
                      Yearly gain on units held.
                    </Text>
                  </View>
                }
              >
                <View
                  style={{
                    flexDirection: "column",
                    width: 120,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignSelf: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text
                      style={{
                        paddingTop: 6,
                        fontSize: 14,
                        fontWeight: "400",
                        color: "#878EA8",
                        textAlign: "center",
                      }}
                    >
                      {`XIRR% p.a.`}
                    </Text>
                    <Icon
                      style={{
                        marginTop: 6,
                      }}
                      name="info-circle"
                      size={14}
                      color={"black"}
                    />
                  </View>
                </View>
              </Popable>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  alignSelf: "center",
                }}
              >
                {xirrPerc}%
              </Text>
            </View>
          </View>
        </View>
        {/* <Text
              style={{
                textAlign: "right",
                padding: 10,
                fontSize: 12,
                fontStyle: "italic",
                fontWeight: 400
              }}
            >{portfolio_since_text}</Text> */}
      </View>
    )
  );
};

const toggleStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginTop: 10,
    marginRight: isMobile ? 10 : 30,
    marginLeft: 10,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  item: {
    fontSize: isMobile ? 12 : 15,
    fontFamily: "Roboto",
  },
  active: {
    color: "#410DAA",
  },
  inActive: {
    color: "#6C7293",
  },
  switch: {
    marginLeft: isMobile ? 4 : 10,
    marginRight: isMobile ? 4 : 10,
    marginTop: isMobile ? -8 : 0,
  },
});

const SummaryCard = (props) => {
  const {
    onValueChange,
    activeTab,
    is_elite,
    navigation,
    userId,
    reportLoading,
    viewReport,
    mainTab,
    showMontlyReportAndViewPlans } = props;
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [summaryData, setsummaryData] = useState(null);

  const onToggleSwitch = () => {
    setIsSwitchOn((isSwitchOn) => !isSwitchOn);
    onValueChange();
  };

  React.useEffect(() => {
    setPieChartData();
  }, [props.stocks_summary]);

  const setPieChartData = () => {
    let { stocks_summary = {} } = props;

    const { data = {}, plan_data = {} } = stocks_summary;
    setsummaryData({
      stocks_summary: data,
      plan_data,
    });
  };

  let currentValue;
  let originalInvested;
  let currentInvestment;
  let first_cip_start_date;
  let portfolio_since_value = null;
  let avgHoldingPeriod;
  let portfolio_since_text = "";

  if (summaryData) {
    const { stocks_summary = {}, plan_data = {} } = summaryData;

    currentValue = isSwitchOn
      ? plan_data["plan_current_value"]
      : stocks_summary["stock_latest"];

    currentValue = currentValue ? parseInt(currentValue) : 0;
    currentValue = "₹" + numberWithCommas(currentValue);

    originalInvested = isSwitchOn
      ? plan_data["plan_invested_value"]
      : stocks_summary["stock_inv"];

    originalInvested = originalInvested ? parseInt(originalInvested) : 0;
    originalInvested = Math.abs(originalInvested);
    originalInvested = "₹" + numberWithCommas(originalInvested);

    currentInvestment = isSwitchOn
      ? plan_data["plan_current_investment"]
      : stocks_summary["stock_inv_value_current"];

    currentInvestment = currentInvestment ? parseInt(currentInvestment) : 0;
    currentInvestment = "₹" + numberWithCommas(currentInvestment);

    first_cip_start_date = isSwitchOn ? plan_data["first_cip_start_date"] : stocks_summary["first_cip_start_date"]

    if (first_cip_start_date) {
      portfolio_since_value = first_cip_start_date.split("-");
      const month = months_short_names(parseInt(portfolio_since_value[1]));
      portfolio_since_value = month + " " + portfolio_since_value[0];
    }

    avgHoldingPeriod = isSwitchOn
      ? plan_data["plan_holding_period_text"]
      : stocks_summary["stock_holding"];

    if (activeTab == "Inside Stocks") {
      if (isSwitchOn) {
        portfolio_since_text = "All stocks portfolio"
      } else {
        portfolio_since_text = "Active plans"
      }
    } else if (activeTab == "JEWEL") {
      if (isSwitchOn) {
        portfolio_since_text = "Jewel portfolio"
      } else {
        portfolio_since_text = "Jewel active plans"
      }
    } else if (activeTab == "SPARK") {
      if (isSwitchOn) {
        portfolio_since_text = "Spark portfolio"
      } else {
        portfolio_since_text = "Spark active plans"
      }
    }

    portfolio_since_text = portfolio_since_text + " since " + portfolio_since_value + ", Avg holding period " + avgHoldingPeriod

  }

  return (
    <View>
      {summaryData && (
        <View>
          {
            mainTab == "Summary" ? (null) : (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: width < 1200 ? "97%" : "55%",
                  marginBottom: 10
                }}
              >
                <View
                  style={{
                    paddingRight: 30,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      if (activeTab == "Inside Stocks") {
                        navigation.navigate("App", {
                          screen: "Plans",
                          params: {
                            screen: "PlansHome",
                            params: {
                              activeTabIndex: 0,
                              activeTab: mainTab
                            },
                          },
                        });
                      } else if (activeTab == "JEWEL") {
                        if (is_elite && showMontlyReportAndViewPlans) {
                          let user_id = null;
                          if (Store.getState().dashboard && Store.getState().dashboard.viewModeUserId &&
                            Store.getState().dashboard.viewModeUserId.user_id) {
                            user_id = Store.getState().dashboard.viewModeUserId.user_id
                          }
                          const filter = "JWL";
                          let options = {};
                          if (userId) {
                            options["user_id"] = userId;
                          }
                          navigation.push("App", {
                            screen: "Plans",
                            params: {
                              screen: "MyEquityInvestmentPlans",
                              params: { filter, ...options },
                            },
                          });
                        } else {
                          navigation.navigate("App", {
                            screen: "Plans",
                            params: {
                              screen: "PlansHome",
                              params: {
                                activeTabIndex: 0,
                                activeTab: mainTab
                              },
                            },
                          });
                        }
                      } else if (activeTab == "SPARK") {
                        if (is_elite && showMontlyReportAndViewPlans) {
                          let user_id = null;
                          if (Store.getState().dashboard && Store.getState().dashboard.viewModeUserId &&
                            Store.getState().dashboard.viewModeUserId.user_id) {
                            user_id = Store.getState().dashboard.viewModeUserId.user_id
                          }
                          const filter = "SRK";
                          let options = {};
                          if (userId) {
                            options["user_id"] = userId;
                          }
                          navigation.push("App", {
                            screen: "Plans",
                            params: {
                              screen: "MyEquityInvestmentPlans",
                              params: { filter, ...options },
                            },
                          });
                        } else {
                          navigation.navigate("App", {
                            screen: "Plans",
                            params: {
                              screen: "PlansHome",
                              params: {
                                activeTabIndex: 0,
                                activeTab: mainTab
                              },
                            },
                          });
                        }
                      }
                    }}
                  >
                    <Text
                      style={{
                        color: "#410DAA",
                        fontSize: 14,
                        fontWeight: 700,
                        textDecorationLine: "underline"
                      }}
                    >View Plans</Text>
                  </TouchableOpacity>
                </View>
                {
                  showMontlyReportAndViewPlans ? (
                    <View>
                      <SelectProduct
                        reportLoading={reportLoading}
                        viewReport={viewReport}
                      />
                    </View>
                  ) : (null)
                }
              </View>
            )
          }
          <View
            style={{
              borderWidth: 0.5,
              borderColor: "#cef1e6",
              borderRadius: 20,
              width: width < 1200 ? "97%" : "55%",
            }}
          >
            <View style={{ flexDirection: isMobile ? "column" : "row" }}>
              <View
                style={{
                  color: "#494B69",
                  fontSize: 16,
                  backgroundColor: "#f3fbf9",
                  borderRadius: 20,
                  height: isMobile ? 190 : 234,
                  // height: isMobile ? 180 : (isSwitchOn ? 250 : 244),
                  alignSelf: "flex-start",
                  width: isMobile ? "100%" : 310,
                }}
              >
                <Text
                  style={{
                    paddingTop: 20,
                    fontSize: 14,
                    fontWeight: "400",
                    color: "#878EA8",
                    paddingHorizontal: 20,
                  }}
                >
                  Current Value
                </Text>
                <Text
                  style={{
                    paddingTop: 5,
                    fontSize: 28,
                    fontWeight: "700",
                    color: "#464E5F",
                    paddingHorizontal: 20,
                  }}
                >
                  {currentValue}
                </Text>
                <View
                  style={{
                    height: 0.5,
                    marginTop: isMobile ? 20 : 40,
                    // marginBottom: isMobile ? 0 : (isSwitchOn ? 60 : 50),
                    marginBottom: isMobile ? 0 : 18,
                    width: "90%",
                    backgroundColor: "#92929250",
                    marginLeft: "5%",
                    marginRight: "5%",
                  }}
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 15,
                    paddingHorizontal: 20,
                    // justifyContent: "space-between",
                    paddingBottom: isMobile ? 10 : 0
                  }}
                >
                  <View style={{ flexDirection: "column" }}>
                    {isSwitchOn ? (
                      <Popable
                        position={"top"}
                        action={isMobile ? "press" : "hover"}
                        style={{ width: 200, marginLeft: 50 }}
                        content={
                          <View style={{ backgroundColor: "black" }}>
                            <Text
                              style={{
                                textAlign: "left",
                                color: "white",
                                padding: 10,
                              }}
                            >
                              Current invested amount (CIA) may be different from
                              the original investment amount (OIA).
                            </Text>
                            <Text
                              style={{
                                textAlign: "left",
                                color: "white",
                                padding: 10,
                              }}
                            >
                              This is because any realised gains (RG) are added to
                              the original investment amount during rebalancing.
                              For example, OIA=100, RG=8, then CIA=108
                            </Text>
                            <Text
                              style={{
                                textAlign: "left",
                                color: "white",
                                padding: 10,
                              }}
                            >
                              Demat stocks values would usually correspond to the
                              Current invested Amount.
                            </Text>
                          </View>
                        }
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            // justifyContent: "center",
                            // alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 14,
                              fontWeight: "400",
                              color: "#878EA8",
                              // paddingRight: isSwitchOn ? 0 : 33,
                            }}
                          >
                            {"Current\nInvested"}
                          </Text>
                          <Icon
                            style={{
                              marginLeft: 5,
                              marginTop: 6,
                              // paddingRight: 15,
                            }}
                            name="info-circle"
                            size={14}
                            color={"black"}
                          />
                        </View>
                      </Popable>
                    ) : (
                      <Popable
                        position={"top"}
                        action={isMobile ? "press" : "hover"}
                        style={{ width: 200, marginLeft: 50 }}
                        content={
                          <View style={{ backgroundColor: "black" }}>
                            <Text
                              style={{
                                textAlign: "left",
                                color: "white",
                                padding: 10,
                              }}
                            >
                              Shows pending cash to deploy as per planned schedule (click 'View Plans'). If 'cash in hand' shows as zero, you can top up to grow more with this portfolio especially when markets see corrections
                            </Text>
                          </View>
                        }
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 14,
                              fontWeight: "400",
                              color: "#878EA8",
                            }}
                          >
                            {"Cash\nin Hand"}
                          </Text>
                          <Icon
                            style={{
                              marginLeft: 5,
                              marginTop: 6,
                              paddingRight: 15,
                            }}
                            name="info-circle"
                            size={14}
                            color={"black"}
                          />
                        </View>
                      </Popable>
                    )}
                    <Text
                      style={{
                        paddingTop: 5,
                        fontSize: 14,
                        fontWeight: "700",
                        color: "#464E5F",
                      }}
                    >
                      {currentInvestment}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 1,
                      height: "auto",
                      marginRight: isSwitchOn ? "10%" : "5%",
                      marginLeft: isSwitchOn ? "25%" : "20%",
                      backgroundColor: "#888C9F66",
                    }}
                  />
                  <View style={{ flexDirection: "column" }}>
                    {isSwitchOn ? (
                      <Popable
                        position={"top"}
                        action={isMobile ? "press" : "hover"}
                        style={{ width: 150 }}
                        content={
                          <View style={{ backgroundColor: "black" }}>
                            <Text
                              style={{
                                textAlign: "left",
                                color: "white",
                                padding: 10,
                              }}
                            >
                              Originally invested amount not including any
                              realised gains or losses.
                            </Text>
                          </View>
                        }
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            width: 120,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              // justifyContent: "center",
                              // alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 14,
                                fontWeight: "400",
                                color: "#878EA8",
                                // paddingRight: isSwitchOn ? 0 : 33,
                              }}
                            >
                              {"Original\nInvested"}{" "}
                            </Text>
                            <Icon
                              style={{
                                marginTop: 6,
                              }}
                              name="info-circle"
                              size={14}
                              color={"black"}
                            />
                          </View>
                          <Text
                            style={{
                              paddingTop: 5,
                              fontSize: 14,
                              fontWeight: "700",
                              color: "#464E5F",
                              // textAlign: "center"
                            }}
                          >
                            {originalInvested}
                          </Text>
                        </View>
                      </Popable>
                    ) : (
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: "400",
                          color: "#878EA8",
                          paddingLeft: isSwitchOn ? 15 : 33,
                        }}
                      >
                        {"Invested\nAmount"}
                      </Text>
                    )}
                    {!isSwitchOn && (
                      <Text
                        style={{
                          paddingTop: 5,
                          fontSize: 14,
                          fontWeight: "700",
                          color: "#464E5F",
                          textAlign: "right",
                        }}
                      >
                        {originalInvested}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
              <View style={{ flex: 1, paddingTop: isMobile ? 12 : 0 }}>
                <ToggleSummaryView
                  onValueChange={onToggleSwitch}
                  value={isSwitchOn}
                  color={"#410DAA"}
                />
                <SummaryTable active={isSwitchOn} data={summaryData} activeTab={activeTab} />
              </View>
            </View>
          </View>
          <View
            style={{
              width: width < 1200 ? "97%" : "55%",
            }}
          >
            <Text
              style={{
                textAlign: "right",
                paddingTop: 5,
                fontSize: 12,
                fontStyle: "italic",
                fontWeight: 400,
                color: "#494B69"
              }}
            >{portfolio_since_text}</Text>
          </View>
        </View>
      )}
    </View>
  );
};

export default SummaryCard;
