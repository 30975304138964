import React from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import amplitude from "../../../utils/amplitude";
import {
  formatDate,
  getUserDetails,
  isMobile,
  numberWithCommas,
} from "../../../utils";
import { getBillingLedgerPlans } from "../../../api/billing";
import { ActivityIndicator } from "react-native-paper";
import HoldingsTable from "../HoldingsTable";
import Store from "../../../store";

const statusMappings = {
  CAN: "Cancelled",
  ACT: "Active",
  EXP: "Expired",
  DFT: "Draft",
  ROL: "Expired & Renewed",
  RLI: "Renewed",
};

const planTypeMappings = {
  LUM3: "Lumpsum",
  SIP3: "SIP",
  LUMP: "Lumpsum",
};

class BillingLedger extends React.Component {
  constructor(props) {
    super(props);
    let user_id;
    const { params } = this.props.route;
    if (params) {
      user_id = params.user_id || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    }

    this.state = {
      isLoading: true,
      user_id,
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("BillingLedger", { path: global.location.href });
      } else {
        amplitude.logEvent("BillingLedger", {
          path: this.props.navigation.getState().routeName,
        });
      }
      const { customer_id } = getUserDetails();
      const userIdToFetch =
        this.props.userId || this.state.user_id || customer_id;
      const response = await getBillingLedgerPlans(userIdToFetch);
      if (response && response.code == 100) {
        let { result = [] } = response;
        const plan_codes_to_filter = [
          "A1",
          "A2",
          "A3",
          "A4",
          "T3",
          "T4",
          "T5",
          "S3",
          "S4",
          "S5",
          "A0",
          "LP1",
          "SP1",
          "LP2",
          "SP2",
          "AA",
          "PLA",
        ];
        result = result.filter(
          (item) => plan_codes_to_filter.indexOf(item.plan_code) != -1
        );
        const total_due_amount_with_gst = result.reduce((acc, curr) => {
          return acc + curr.total_due_amount_with_gst;
        }, 0);

        this.setState({
          data: result,
          isLoading: false,
          total_due_amount_with_gst,
        });
        return;
      } else {
        this.setState({
          error: "Error loading transaction details",
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        error: "Error loading transaction details",
        isLoading: false,
      });
    }
  }

  navigateBack = () => {
    const { navigation } = this.props;
    if (navigation) {
      navigation.goBack();
    }
  };

  handleSearch = (searchText) => {
    this.setState({ searchText });
  };

  compare = (a, b, field, sort) => {
    if (a[field] < b[field]) {
      return sort ? -1 : 1;
    }
    if (a[field] > b[field]) {
      return sort ? 1 : -1;
    }
    return 0;
  };

  handleSort = (field, sort, start, end) => {
    this.setState(
      {
        sorting_field: field,
        sorting_value: !sort,
      },
      () => {
        const { data } = this.state;
        const transactions_data = [...data["transactions"]];
        const sortedData = [].concat(
          transactions_data.slice(0, start),
          transactions_data
            .slice(start, end)
            .sort((a, b) => this.compare(a, b, field, !sort)),
          transactions_data.slice(end, transactions_data.length)
        );
        this.setState({ data: { ...data, ["transactions"]: sortedData } });
      }
    );
  };

  getTableData = (data = []) => {
    const headerData = [
      {
        label: "Plan Details",
        style: [styles.tableHeader, { paddingLeft: 10 }],
        sortOption: false,
        key: "plan_code",
      },
      {
        label: "Status",
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        label: "AUA",
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        label: "Fee Amount",
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        label: "Discount",
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        label: "Net Payable",
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        component: true,
        label: (
          <View style={styles.labelComponentContainer}>
            <Text style={styles.lableComponent}>Net</Text>
            <Text style={styles.lableComponentDescription}>
              (including GST)
            </Text>
          </View>
        ),
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        component: true,
        label: (
          <View style={styles.labelComponentContainer}>
            <Text style={styles.lableComponent}>Paid</Text>
            <Text style={styles.lableComponentDescription}>
              (including GST)
            </Text>
          </View>
        ),
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        component: true,
        label: (
          <View style={styles.labelComponentContainer}>
            <Text style={styles.lableComponent}>Due</Text>
            <Text style={styles.lableComponentDescription}>
              (including GST)
            </Text>
          </View>
        ),
        style: [styles.tableHeader],
        sortOption: false,
      },
    ];

    let { values = [] } = this.generateDataForTable(data);
    return { headerData, values };
  };

  handleBillDetails = (bill_id) => {
    let params = {};
    if (this.state.user_id) {
      params["user_id"] = this.state.user_id;
    }
    this.props.navigation.navigate("BillingLedgerDetails", {
      bill_id,
      ...params,
    });
  };

  generateDataForTable = (data) => {
    let values = [];
    data.forEach((item) => {
      let {
        pk,
        plan_code,
        start_date,
        end_date,
        status,
        total_invested_amount,
        amount_without_discount_on_billed_fee: amount_without_discount,
        type,
        total_billed_fee,
        total_billed_fee_with_gst,
        total_paid_fee_with_gst,
        total_due_amount_with_gst,
      } = item;
      total_invested_amount = numberWithCommas(parseInt(total_invested_amount));
      const amount_without_discount_on_billed_fee = numberWithCommas(
        parseInt(amount_without_discount)
      );
      const discount = numberWithCommas(
        parseInt(amount_without_discount - total_billed_fee)
      );
      const net = numberWithCommas(parseInt(total_billed_fee));
      const net_gst = numberWithCommas(parseInt(total_billed_fee_with_gst));
      const paid = numberWithCommas(parseInt(total_paid_fee_with_gst));
      total_due_amount_with_gst = numberWithCommas(
        parseInt(total_due_amount_with_gst)
      );
      values.push([
        {
          component: true,
          value: (
            <TouchableOpacity onPress={() => this.handleBillDetails(pk)}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#12121270" }}
              >
                {plan_code} - {planTypeMappings[type]}{" "}
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#410DAA" }}
                >
                  Plan #{pk}
                </Text>
              </Text>
              <Text style={{ fontSize: 12, color: "#12121270", marginTop: 5 }}>
                ({formatDate(start_date)}-{formatDate(end_date)})
              </Text>
            </TouchableOpacity>
          ),
          style: [styles.rowItem, { paddingLeft: 20, textAlign: "left" }],
          rowItemContainerStyle: [{ justifyContent: "flex-start" }],
        },
        {
          valueToDisplay: statusMappings[status],
          value: status,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + total_invested_amount,
          value: total_invested_amount,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + amount_without_discount_on_billed_fee,
          value: amount_without_discount_on_billed_fee,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + discount,
          value: discount,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + net,
          value: net,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + net_gst,
          value: net_gst,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + paid,
          value: paid,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + total_due_amount_with_gst,
          value: total_due_amount_with_gst,
          style: [styles.rowItem],
        },
      ]);
    });
    return { values };
  };

  render() {
    const {
      data = [],
      isLoading,
      error,
      total_due_amount_with_gst = 0,
    } = this.state;

    const { headerData, values } = this.getTableData(data);
    return (
      <>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Billing Ledger"]}
          onPressBack={() => this.props.navigation.goBack()}
        />
        {isLoading || error ? (
          <View style={styles.loadingContainer}>
            {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
            {error && <Text style={styles.error}>{error}</Text>}
          </View>
        ) : (
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
            <ScrollView>
              <View style={styles.detailsContainer}>
                <View style={styles.headingContainer}>
                  <Text style={styles.header}>Plans</Text>
                  <Text style={styles.due}>
                    Balance due: ₹
                    {total_due_amount_with_gst
                      ? numberWithCommas(parseInt(total_due_amount_with_gst))
                      : 0}
                  </Text>
                </View>
                <HoldingsTable
                  header={headerData}
                  gridConfig={[2, 1, 1, 1, 1, 1, 1, 1, 1]}
                  data={values}
                  minWidth={1500}
                  pagination={values.length > 15}
                />
              </View>
            </ScrollView>
          </ScrollView>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    minHeight: Dimensions.get("window").height * 0.8,
    backgroundColor: "#fff",
  },
  scrollView: {},
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    backgroundColor: "white",
    marginHorizontal: isMobile ? 16 : 16,
    marginVertical: 30,
    width: 1200,
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 15 : 40,
    paddingVertical: 19,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "bold",
  },
  due: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "bold",
  },
  amountHeading: {
    fontSize: 15,
    fontWeight: "700",
    color: "#464E5F",
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  rowItem: {
    fontSize: 14,
  },
  labelComponentContainer: {
    alignItems: "center",
  },
  lableComponent: {
    fontSize: 14,
    textAlign: "center",
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  lableComponentDescription: {
    fontSize: 12,
    color: "#464E5F70",
  },
});

export default BillingLedger;
