import { useEffect, useState } from "react";
import { createOrderWithFirstInstallment } from "../../api";
import { getMFRecommendataionsBasedOnAmount } from "../../api/invest";

export const usePlaceMFOrder = (url) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  useEffect(() => {}, []);

  const validateTopUp = (amount) => {
    return !isNaN(amount) && amount != null && amount != 0;
  };

  const isValidData = (amount) => {
    const isValid = validateTopUp(amount);
    if (!isValid) {
      setErrorText("Please enter amount");
      setIsLoading(false);
      return false;
    }

    return true;
  };

  const placeMFOrder = async (data = {}) => {
    try {
      const { amount, pk } = data;
      setIsLoading(true);
      const isValid = isValidData(amount);
      if (!isValid) return;

      const recommendationsPayload = {
        investment_amount: Number(amount),
        apm_pk: pk,
      };

      let orders = [];
      const recommendationsResponse = await getMFRecommendataionsBasedOnAmount(
        recommendationsPayload
      );
      if (recommendationsResponse && recommendationsResponse.code == 100) {
        orders = [...recommendationsResponse.results];
      } else {
        orders = [
          { investmentType: "Lumpsum", amount: 2000.0, scheme: 5593 },
          { investmentType: "Lumpsum", amount: 4000.0, scheme: 6829 },
        ];
      }

      const payload = {
        orders: [...orders],
      };

      const response = await createOrderWithFirstInstallment(payload);
      if (response && response.code && response.code == 100) {
        if (response.success > 0) {
          const { url } = response.nav_props;
          global.open(url, "_self");
          return;
        }
      }

      setIsLoading(false);
      setErrorText("Error placing order!");
    } catch (error) {
      setIsLoading(false);
      setErrorText("Something went wrong!");
    }
  };

  return { loading: isLoading, error: errorText, placeMFOrder };
};
