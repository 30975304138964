import { LOGOUT } from "../actions/types";

const INITIAL_STATE = {
  completeFlowLastSeenScreen: "BasicDetails",
  childrenFlowLastSeenScreen: "BasicDetails",
  retirementFlowLastSeenScreen: "BasicDetails",
  completeFlowCompleted: false,
  retirementFlowCompleted: false,
  childrenFlowCompleted: false,
  finplanProcessing: false,
  goalFinplanModalClosed: false,
};

export const actionTypes = {
  UPDATE_LAST_SEEN_SCREEN: "UPDATE_LAST_SEEN_SCREEN",
  UPDATE_FINPLAN_STATUS: "UPDATE_FINPLAN_STATUS",
  CLEAR_GOALS: "CLEAR_GOALS",
  FINPLAN_PROCESSING: "FINPLAN_PROCESSING",
  RESET_FINPLAN: "RESET_FINPLAN",
  CLOSE_FINPLAN_MODAL: "CLOSE_FINPLAN_MODAL",
};

const getEndScreen = (type) =>
  type == "COMPLETE"
    ? "MaritalStatus"
    : type == "CHILDREN"
    ? "MaritalStatus"
    : "WhenRetire";

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LAST_SEEN_SCREEN:
      let temp = {};
      const { type, screen, basic, end } = action.payload;

      if (type == "CHILDREN") {
        temp["childrenFlowLastSeenScreen"] = screen;
        temp["completeFlowLastSeenScreen"] = screen;
      } else if (type == "RETIREMENT") {
        temp["retirementFlowLastSeenScreen"] = screen;
      } else if (type == "COMPLETE") {
        temp["completeFlowLastSeenScreen"] = screen;
      }

      if (basic) {
        temp["childrenFlowLastSeenScreen"] = screen;
        temp["retirementFlowLastSeenScreen"] = screen;
        temp["completeFlowLastSeenScreen"] = screen;
      }

      if (end) {
        temp["completeFlowLastSeenScreen"] = getEndScreen("COMPLETE");
        temp["childrenFlowLastSeenScreen"] = getEndScreen("CHILDREN");
        temp["retirementFlowLastSeenScreen"] = getEndScreen("RETIREMENT");
      }
      if (state.completeFlowCompleted) {
        temp = INITIAL_STATE;
      }

      return { ...state, ...temp };

    case actionTypes.UPDATE_FINPLAN_STATUS:
      temp = {};
      const { flowType, status } = action.payload;
      if (flowType == "COMPLETE") temp = INITIAL_STATE;
      if (flowType == "CHILDREN") temp["childrenFlowCompleted"] = status;
      if (flowType == "RETIREMENT") temp["retirementFlowCompleted"] = status;
      temp["finplanProcessing"] = false;
      return { ...state, ...temp };

    case actionTypes.CLOSE_FINPLAN_MODAL:
      return { ...state, goalFinplanModalClosed: true };

    case actionTypes.FINPLAN_PROCESSING:
      const { status: isProcessing } = action.payload;
      return { ...state, finPlanProcessing: isProcessing };

    case actionTypes.RESET_FINPLAN:
    case actionTypes.CLEAR_GOALS:
    case LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};
