import { StackActions } from "@react-navigation/native";
import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";
import global from "./globalStyles";

export default function ContactUs({ navigation }) {
    return (
        <View style={styles.container}>
            <Text style={[styles.title, global.arial]}>
                If you have any queries, feel free to
            </Text>
            <TouchableOpacity
                onPress={() =>
                    navigation.navigate("MySubscriptionContactPage", { for_query: true  })
                }
            >
                <Text style={[styles.link, global.arial]}>Contact Us</Text>
            </TouchableOpacity>
        </View>
    );
}
  
const styles = StyleSheet.create({
    container: {
        paddingTop: 15,
        paddingBottom: 15
    },
    title: {
        textAlign: "center",
        fontSize: 15,
        color: "black"
    },
    link: {
        textAlign: "center",
        color: "#276FC4",
        marginTop: 12
    }
});
  
