import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";

const _generateDays = () => {
    let result = [];
    for (let i = 0; i < 4; i++) {
        result.push([]);
        for (let j = 0; j < 7; j++) {
            result[i].push(i * 7 + j + 1);
        }
    }
    return result;
};

export const DayCell = ({ index, active, onPress }) => {
    return (
        <TouchableOpacity
            style={{
                width: 35,
                height: 35,
                margin: 6,
                backgroundColor: active ? "#410DAA" : "#f2f2f2",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 6
            }}
            onPress={index => onPress(index)}
        >
            <Text style={{ fontSize: 14, color: active ? "#fff" : "#121212" }}>
                {index}
            </Text>
        </TouchableOpacity>
    );
};

export const DateDays = ({ selected, onPress, style }) => {
    const dates = _generateDays();
    return (
        <View style={{ ...style }}>
            {dates.map((items, row) => {
                return (
                    <View style={{ flexDirection: "row" }}>
                        {items.map((item, column) => {
                            return (
                                <DayCell
                                    index={item}
                                    active={selected == item}
                                    onPress={() => {
                                        onPress(item);
                                    }}
                                />
                            );
                        })}
                    </View>
                );
            })}
        </View>
    );
};