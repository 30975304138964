import React, {Component} from "react";
import {View,Text, Platform, Linking} from "react-native";
import { isMobile } from "../../utils";
import { CustomButton } from "../Common/Button";
import NavigationHeader from '../Common/NavigationHeader';

export default class AuditReport extends React.Component {
    constructor(props){
        super(props);
        this.state={}
    }

    render(){
        return(
            <View 
                style={{flex:1,backgroundColor:"#fff"}}
            >
                <NavigationHeader navigation={this.props.navigation} navigationHeader={["Audit Performance Report"]} onPressBack={()=>this.props.navigation.navigate("InvestmentOverview")}/> 
                <View style={{flex:1,alignItems:"center",justifyContent:"flex-start",marginTop:100}}>

                    <Text style={{fontSize:20,color:"#464E5F",textAlign:"center",marginBottom:10,fontWeight:"bold"}}>Jama Wealth Rebalanced Audit Report (30 Sep 2021)</Text>
                    <Text style={{fontSize:15,color:"#464E5F"}}>The report is available now. Click below to open.</Text>

                    <CustomButton buttonStyle={{alignSelft:"center",marginTop:70,paddingVertical:12}} title="Open Report" onPress={()=>{
                        const reportURL = "https://docs.zohopublic.com/file/ped8p0c1068bd0b4a4f2b9c975c776cd5ca56";
                        if(Platform.OS=="web"){
                            global.open(reportURL,"_blank")
                        }else{
                            Linking.openURL(reportURL);
                        }
                    }}/>


                    {/* <Text style={{fontSize:13,color:"#464E5F",marginTop:20,textAlign:"center",paddingHorizontal:16}}><Text style={{fontWeight:"bold"}}>Note:</Text>  You may be asked for a password. Password is sent separately over email.</Text> */}
                    
                </View>
            </View>
        )
    }
}