import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  Dimensions,
} from "react-native";
import { isMobile } from "../../../utils";
const { width } = Dimensions.get("window");

export const AssetTabs = ({ tabs = [], onSelectTab, selectedTab }) => {
  const [tabIndex, setTabIndex] = useState(selectedTab);

  const handleSelectTab = (index) => {
    setTabIndex(index);
    onSelectTab(index);
  };

  return width < 1200 ? (
    <View
      style={{
        flexDirection: "row",
        borderBottomColor: "#E5E5E5",
        borderBottomWidth: 1,
        alignSelf: "flex-start",
        backgroundColor: "white",
        paddingRight: isMobile ? 20 : 0,
      }}
    >
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          flexDirection: "row",
          backgroundColor: "white",
        }}
      >
        {tabs.map((tab, index) => {
          return (
            <TouchableOpacity
              key={tab.value}
              onPress={() => {
                if (tabIndex != index) {
                  handleSelectTab(index);
                }
              }}
            >
              <Text
                style={[
                  styles.tabItem,
                  {
                    color: tabIndex == index ? "#410DAA" : "#888C9F",
                    borderBottomColor:
                      tabIndex == index ? "#410DAA" : "transparent",
                    fontWeight: tabIndex == index ? "700" : "400",
                  },
                ]}
              >
                {tab.label}
              </Text>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    </View>
  ) : (
    <View style={styles.container}>
      {tabs.map((tab, index) => {
        return (
          <TouchableOpacity
            key={tab.value}
            onPress={() => {
              if (tabIndex != index) {
                handleSelectTab(index);
              }
            }}
          >
            <Text
              style={[
                styles.tabItem,
                {
                  color: tabIndex == index ? "#410DAA" : "#888C9F",
                  borderBottomColor:
                    tabIndex == index ? "#410DAA" : "transparent",
                  fontWeight: tabIndex == index ? "700" : "400",
                },
              ]}
            >
              {tab.label}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    alignSelf: "flex-start",
    backgroundColor: "white",
    marginLeft: 15,
  },
  tabItem: {
    fontSize: 14,
    paddingHorizontal: isMobile ? 10 : 30,
    paddingVertical: 12,
    borderBottomWidth: 3,
    marginLeft: 5,
  },
});
