import React, { useEffect, useState } from "react";
import { StyleSheet, View, Image, SafeAreaView } from "react-native";
import Icon from "@expo/vector-icons/FontAwesome";
import { StackActions } from "@react-navigation/routers";
import { Button, Text, ActivityIndicator } from "react-native-paper";
import { getUserDetails } from "../../../utils";
import RiskProfileSummary from "../riskprofile/summary";


const RiskProfileHome = props => {
    const [loading, setLoading] = useState(true);
    const [ showSummary, setShowSummary] = useState(false);
    useEffect(()=>{
      const { risk_profile } = getUserDetails();
      if(risk_profile){
        // props.navigation.dispatch(
        //   StackActions.replace("RiskProfileSummary",  { from: "AddPanCardNav", stack: "Account" })
        // );
        setShowSummary(true)
        setLoading(false);
      }else{
        setLoading(false);
      }
    }, []);

    const handleTakeNow = () => {
        const action = StackActions.replace("MyRiskProfile");
        props.navigation.dispatch(action);
    }

    const handleGoBack = () => {
        props.navigation.goBack(null);
    }

  if(showSummary){
    return <RiskProfileSummary {...props}/>
  }

  if(loading){
    return(
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#410DAA"/>
      </View>
    )
  }
  return (
    <SafeAreaView style={styles.container}>
      <Icon
        name={"remove"}
        size={28}
        style={styles.icon}
        onPress={handleGoBack}
      />
      <View
        style={styles.mainContainer}
      >
        <Image
          style={styles.image}
          source={{
            uri:
              "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/risk+assessment.png"
          }}
        />
        <Text style={styles.title}>
          Take Your Risk Assessment
        </Text>
        <Text style={styles.contentOne}>
          Risk profile helps you find a suitable allocation and guides your
          investments across asset classes (eg: fixed income or debt, equity,
          gold).
        </Text>
        <Text style={styles.contentTwo}>
          Investing as per risk profile helps you preserve and grow your wealth.
        </Text>
      </View>
      <Button
        uppercase={false}
        mode={"contained"}
        onPress={handleTakeNow}
        color={"#410DAA"}
        style={styles.takeNow}
      >
        <Text style={styles.takeNowText}>Take Now</Text>
      </Button>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
    loadingContainer: { flex: 1, alignItems: 'center', justifyContent: 'center' },
    container: { flex: 1, paddingHorizontal: 16, backgroundColor: "#fff", paddingTop: 16, justifyContent: "center", alignItems: "center" },
    icon: {
        padding: 4,
        textAlign: "right",
        alignSelf: "flex-end",
        marginRight: 16
    },
    image: { width: 160, height: 160, marginVertical: 32 },
    mainContainer: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        width: "80%",
        alignSelf: "center"
    },
    title: { fontWeight: "bold", marginVertical: 16 },
    contentOne: { textAlign: "center" },
    contentTwo: { textAlign: "center", marginTop: 16 },
    takeNow: {
        margin: 16,
        maxWidth: 900,
    },
    takeNowText: {
        fontSize: 15,
        color: "#fff"
    }
});

export default RiskProfileHome;
