import {Modal} from "react-native-paper";
import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  SafeAreaView,
  Text,
  Image
} from "react-native";

export default class SuccessOrFailureModal extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const {
      status = true,
      title,
      visible,
      message,
      messageStyle,
      buttonOnPress,
      buttonTitle,
      navigation,
      buttons,
    } = this.props;
    return (
      <SafeAreaView style={{ ...this.props.style }}>
        <Modal
          isVisible={visible}
          style={[styles.bottomModal, { zIndex: 5 }]}
          onBackdropPress={() => {
            buttonOnPress && buttonOnPress();
          }}
          // backdropColor={""}
          // animationIn={"zoomInDown"}
          // animationOut={"zoomOutUp"}
          // animationInTiming={1000}
          // animationOutTiming={1000}
          // backdropTransitionInTiming={1000}
          // backdropTransitionOutTiming={1000}
        >
          <View style={styles.modalContent}>
            <Image
              source={
                status
                  ? require("../../../assets/success.png")
                  : require("../../../assets/failure.png")
              }
              style={{
                width: 140,
                height: 140,
                marginTop: 24,
                marginBottom: 20,
              }}
              resizeMode="contain"
            />
            <Text
              style={{
                fontSize: 18,
                color: "#121212",
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              {title || "Succesful"}
            </Text>
            {message && (
              <Text
                style={[
                  {
                    fontSize: 13,
                    color: "#707070",
                    textAlign: "center",
                    marginBottom: 100,
                  },
                  messageStyle ? { ...messageStyle } : {},
                ]}
              >
                {message}
              </Text>
            )}
            {this.props.children}
            {buttonTitle && buttonOnPress && (
              <TouchableOpacity
                rippleContainerBorderRadius={5}
                style={{
                  backgroundColor: "#410DAA",
                  width: "100%",
                  height: 50,
                  borderRadius: 5,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onPress={() => {
                  buttonOnPress && buttonOnPress();
                }}
              >
                <Text style={{ fontSize: 17, color: "#fff" }}>
                  {buttonTitle || "Done"}
                </Text>
              </TouchableOpacity>
            )}
            {buttons &&
              buttons.map((button, index) => {
                return (
                  <TouchableOpacity
                    rippleContainerBorderRadius={5}
                    style={[
                      {
                        backgroundColor: "#410DAA",
                        width: "100%",
                        height: 50,
                        borderRadius: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 10,
                      },
                      button.buttonStyle ? { ...button.buttonStyle } : {},
                    ]}
                    onPress={() => {
                      button.buttonOnPress && button.buttonOnPress();
                    }}
                  >
                    <Text
                      style={[
                        { fontSize: 17, color: "#fff" },
                        button.textStyle ? { ...button.textStyle } : {},
                      ]}
                    >
                      {button.title}
                    </Text>
                  </TouchableOpacity>
                );
              })}
          </View>
        </Modal>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  modalContent: {
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    borderColor: "rgba(0, 0, 0, 0.1)",
    padding: 16,
  },
});
