import React from "react";
import { View, Text, Dimensions, ScrollView, Image } from "react-native";
import { md } from "../../Responsive";
import NavigationHeader from "../Common/NavigationHeader";
import { isMobile } from "../../utils";
const { width } = Dimensions.get("window");
const isWeb = md(width);

export default class UpgradeSubscriptionHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Plan6MBg: "white",
      Plan6MColor: "#410DAA",
      Plan1YBg: "white",
      Plan1YColor: "#410DAA",
      Plan3YBg: "white",
      Plan3YColor: "#410DAA",
    };
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: "#ffffff",
            maxWidth: 1500,
          }}
        >
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Upgrade Subscription"]}
            onPressBack={() => {
              this.props.navigation.goBack();
            }}
            style={{ backgroundColor: "#F6F6F6" }}
          />
          <ScrollView
            style={{
              backgroundColor: "#FFFFFF",
              paddingLeft: isWeb ? 32 : 0,
              paddingRight: isWeb ? 32 : 0,
              paddingTop: isWeb ? 30 : 0,
              maxWidth: 1400,
              marginBottom: isMobile ? 0 : 20,
            }}
          >
            {isMobile ? (
              <ScrollView>
                <View
                  style={{
                    marginTop: 20,
                    width: isMobile ? "90%" : "100%",
                    marginLeft: isMobile ? "5%" : "0%",
                    marginRight: isMobile ? "5%" : "0%",
                    flexDirection: "column",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      borderColor: "#cdcdcb",
                      borderWidth: 1,
                      marginBottom: 100,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#a1a6af",
                        fontWeight: "600",
                        marginLeft: "10%",
                        marginTop: "10%",
                      }}
                    >
                      Current Plan
                    </Text>
                    <Text
                      style={{
                        fontSize: 34,
                        color: "#51514E",
                        fontWeight: "700",
                        marginLeft: "9.8%",
                        marginTop: "2%",
                      }}
                    >
                      Corporate Club
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#6b717f",
                        fontWeight: "500",
                        marginLeft: "10%",
                        marginTop: "1%",
                      }}
                    >
                      Pack Expires on:{" "}
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#8d6ecc",
                          fontWeight: "500",
                        }}
                      >
                        06/05/2025
                      </Text>
                    </Text>
                    <View
                      style={{
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        borderRadius: 10,
                        borderColor: "white",
                        borderWidth: 1,
                        backgroundColor: "#f9f5ff",
                        marginTop: "5%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          color: "#464E5F",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        Advices Included
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Mutual Fund Pack (4)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Portfolio Review (2)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Financial Plan Review (2)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Adhoc Advices (2)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        ></Text>
                      </View>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#8a6acc",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginBottom: "10%",
                          fontStyle: "italic",
                        }}
                      >
                        Learn More
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "white",
                        backgroundColor: "#410DAA",
                        borderColor: "#410DAA",
                        borderWidth: 1,
                        borderRadius: 4,
                        paddingVertical: 10,
                        textAlign: "center",
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        marginTop: "10%",
                        marginBottom: "10%",
                      }}
                    >
                      Upgrade to Advisory Circle
                    </Text>
                  </View>
                </View>
              </ScrollView>
            ) : (
              <View
                style={{
                  width: isMobile ? "90%" : "100%",
                  marginLeft: isMobile ? "5%" : "0%",
                  marginRight: isMobile ? "5%" : "0%",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginBottom: 60,
                }}
              >
                <View
                  style={{
                    width: "97%",
                    borderRadius: 10,
                    borderColor: "#cdcdcb",
                    borderWidth: 1,
                    paddingBottom: "5%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#a1a6af",
                      fontWeight: "600",
                      marginLeft: "5%",
                      marginTop: "3.4%",
                    }}
                  >
                    Current Plan
                  </Text>
                  <Text
                    style={{
                      fontSize: 34,
                      color: "#51514E",
                      fontWeight: "700",
                      marginLeft: "4.8%",
                      marginTop: "2%",
                    }}
                  >
                    Corporate Club
                  </Text>

                  <Text
                    style={{
                      fontSize: 14,
                      color: "#6b717f",
                      fontWeight: "500",
                      marginLeft: "5%",
                      marginTop: "1%",
                    }}
                  >
                    Pack Expires on:{" "}
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#8d6ecc",
                        fontWeight: "500",
                      }}
                    >
                      06/05/2025
                    </Text>
                  </Text>
                  <View
                    style={{
                      width: "90%",
                      marginLeft: "5%",
                      borderRadius: 10,
                      borderColor: "white",
                      borderWidth: 1,
                      backgroundColor: "#f9f5ff",
                      marginTop: "3%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#464E5F",
                        fontWeight: "700",
                        marginLeft: "5%",
                        marginTop: "4%",
                      }}
                    >
                      Advices Included
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "90%",
                        marginTop: "2%",
                        marginLeft: "5%",
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ flex: 1, flexDirection: "column" }}>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Mutual Fund Pack (4)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "6%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Financial Plan Review (2)
                          </Text>
                        </View>
                      </View>
                      <View style={{ flex: 1, flexDirection: "column" }}>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Portfolio Review (2)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "6%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Adhoc Advices (2)
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "column",
                          marginTop: "1%",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "bold",
                            fontSize: 15,
                            color: "white",
                            backgroundColor: "#410DAA",
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            borderRadius: 4,
                            paddingVertical: 10,
                            textAlign: "center",
                          }}
                        >
                          Upgrade to Advisory Circle
                        </Text>
                      </View>
                    </View>
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#8a6acc",
                        fontWeight: "700",
                        marginLeft: "5%",
                        marginTop: "2%",
                        marginBottom: "5%",
                        fontStyle: "italic",
                      }}
                    >
                      Learn More
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </ScrollView>
        </View>
      </View>
    );
  }
}
