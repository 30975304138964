import React from "react";
import {
  View,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  Platform,
  Image,
} from "react-native";
import { Text, Button } from "react-native-paper";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { isMobile } from "../../utils/index";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;

class CorpCountTracker extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Corporate Advice Count Tracker"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <ScrollView>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            style={styles.cardContainer}
          >
            {isMobile ? (
              <>
                <View style={{ marginTop: 20, marginBottom: 40 }}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View
                      style={{
                        marginLeft: "5%",
                        marginRight: "5%",
                        backgroundColor: "white",
                        width: "90%",
                        height: 130,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: "rgba(65, 13, 170, 0.3)",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          marginLeft: "3%",
                          marginRight: "3%",
                          flexDirection: "row",
                          marginTop: "1%",
                          justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "column",
                            marginTop: "4%",
                          }}
                        >
                          <View
                            style={{ flexDirection: "row", marginBottom: "4%" }}
                          >
                            <Text
                              style={{
                                fontSize: 18,
                                color: "#464E5F",
                                fontWeight: "700",
                              }}
                            >
                              Advices Remaining
                            </Text>
                          </View>

                          <Text
                            style={{
                              fontSize: 16,
                              color: "#6b717f",
                              fontWeight: "500",
                            }}
                          >
                            Pack Expires on:{" "}
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#8d6ecc",
                                fontWeight: "500",
                              }}
                            >
                              06/05/2025
                            </Text>
                          </Text>

                          <View
                            style={{
                              flexDirection: "row",
                              marginTop: "1%",
                            }}
                          >
                            <View
                              style={[
                                {
                                  alignSelf: "flex-start",
                                  flexDirection: "row",
                                  marginVertical: 7.5,
                                },
                              ]}
                            >
                              <View
                                style={{
                                  width: 15,
                                  height: 15,
                                  borderRadius: 2,
                                  backgroundColor: "#876CDD",
                                  marginTop: 1,
                                }}
                              />
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "#464E5F",
                                  paddingLeft: 8,
                                  fontWeight: "700",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Availed (14)
                              </Text>
                            </View>
                            <View
                              style={[
                                {
                                  alignSelf: "flex-start",
                                  flexDirection: "row",
                                  marginVertical: 7.5,
                                  marginLeft: 20,
                                },
                              ]}
                            >
                              <View
                                style={{
                                  width: 15,
                                  height: 15,
                                  borderRadius: 2,
                                  backgroundColor: "#BAC7F2",
                                  marginTop: 1,
                                }}
                              />
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "#464E5F",
                                  fontWeight: "700",
                                  paddingLeft: 8,
                                  fontFamily: "Roboto",
                                }}
                              >
                                Total (72)
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View
                          style={{
                            flex: 1,
                            alignItems: "flex-end",
                            flexDirection: "column",
                            marginRight: "-7.5%",
                          }}
                        >
                          {Platform.OS == "web" ? (
                            <View
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <Donut
                                options={{
                                  dataLabels: {
                                    enabled: false,
                                  },
                                  legend: {
                                    show: false,
                                  },
                                  tooltip: { enabled: false },
                                  colors: ["#876CDD", "#BAC7F2"],
                                  plotOptions: {
                                    pie: {
                                      donut: {
                                        size: "60%",
                                      },
                                    },
                                  },
                                  stroke: {
                                    width: 1,
                                    colors: ["#876CDD", "#BAC7F2"],
                                  },
                                }}
                                series={[14, 72]}
                                sliceColor={["#876CDD", "#BAC7F2"]}
                                type="donut"
                                width="170"
                              />
                            </View>
                          ) : (
                            <Donut
                              widthAndHeight={100}
                              series={[14, 72]}
                              sliceColor={["#876CDD", "#BAC7F2"]}
                              doughnut={true}
                              coverRadius={0.6}
                              coverFill={"#FFF"}
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ marginBottom: 40 }}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View
                      style={{
                        marginLeft: "5%",
                        marginRight: "5%",
                        backgroundColor: "white",
                        width: "90%",
                        height: 210,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: "rgba(65, 13, 170, 0.3)",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          marginLeft: "3%",
                          marginRight: "3%",
                          flexDirection: "row",
                          marginTop: "6%",
                          justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "column",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              marginBottom: "24%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                              }}
                              style={{
                                width: 20,
                                height: 20,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                            <Text
                              style={{
                                fontSize: 15,
                                textDecorationLine: "underline",
                                color: "#410DAA",
                                textAlign: "left",
                                marginLeft: "1%",
                              }}
                              onPress={() => {}}
                            >
                              How it works?
                            </Text>
                          </View>
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/mf.png",
                            }}
                            style={{
                              width: 120,
                              height: 120,
                              alignSelf: "flex-start",
                              borderRadius: 4,
                            }}
                            resizeMode={"contain"}
                          />
                        </View>
                        <View
                          style={{
                            flex: 1.5,
                            alignItems: "flex-end",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#464E5F",
                              fontWeight: "600",
                            }}
                          >
                            Mutual Fund Packs
                          </Text>
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#7b55c3",
                              fontWeight: "600",
                              marginTop: "2%",
                            }}
                          >
                            4 Remaining
                          </Text>
                          <Button
                            uppercase={false}
                            mode="contained"
                            labelStyle={{
                              color: "#410DAA",
                              fontWeight: "600",
                            }}
                            onPress={() => {}}
                            style={{
                              marginTop: 77,
                              borderColor: "#410DAA",
                              borderWidth: 1,
                              backgroundColor: "white",
                              borderRadius: 6,
                              height: 37,
                              width: 160,
                            }}
                          >
                            Request Advice
                          </Button>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ marginBottom: 40 }}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View
                      style={{
                        marginLeft: "5%",
                        marginRight: "5%",
                        backgroundColor: "white",
                        width: "90%",
                        height: 210,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: "rgba(65, 13, 170, 0.3)",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          marginLeft: "3%",
                          marginRight: "3%",
                          flexDirection: "row",
                          marginTop: "6%",
                          justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "column",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              marginBottom: "20%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                              }}
                              style={{
                                width: 20,
                                height: 20,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                            <Text
                              style={{
                                fontSize: 15,
                                textDecorationLine: "underline",
                                color: "#410DAA",
                                textAlign: "left",
                                marginLeft: "1%",
                              }}
                              onPress={() => {}}
                            >
                              How it works?
                            </Text>
                          </View>
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/finplan.png",
                            }}
                            style={{
                              width: 140,
                              height: 140,
                              alignSelf: "flex-start",
                              borderRadius: 4,
                            }}
                            resizeMode={"contain"}
                          />
                        </View>
                        <View
                          style={{
                            flex: 1.5,
                            alignItems: "flex-end",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#464E5F",
                              fontWeight: "600",
                            }}
                          >
                            Financial Plan Review
                          </Text>
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#7b55c3",
                              fontWeight: "600",
                              marginTop: "2%",
                            }}
                          >
                            2 Remaining
                          </Text>
                          <Button
                            uppercase={false}
                            mode="contained"
                            labelStyle={{
                              color: "#410DAA",
                              fontWeight: "600",
                            }}
                            onPress={() => {}}
                            style={{
                              marginTop: 77,
                              borderColor: "#410DAA",
                              borderWidth: 1,
                              backgroundColor: "white",
                              borderRadius: 6,
                              height: 37,
                              width: 160,
                            }}
                          >
                            Schedule Review
                          </Button>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ marginBottom: 40 }}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View
                      style={{
                        marginLeft: "5%",
                        marginRight: "5%",
                        backgroundColor: "white",
                        width: "90%",
                        height: 210,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: "rgba(65, 13, 170, 0.3)",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          marginLeft: "3%",
                          marginRight: "3%",
                          flexDirection: "row",
                          marginTop: "6%",
                          justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "column",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              marginBottom: "16%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                              }}
                              style={{
                                width: 20,
                                height: 20,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                            <Text
                              style={{
                                fontSize: 15,
                                textDecorationLine: "underline",
                                color: "#410DAA",
                                textAlign: "left",
                                marginLeft: "1%",
                              }}
                              onPress={() => {}}
                            >
                              How it works?
                            </Text>
                          </View>
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolioValue.png",
                            }}
                            style={{
                              width: 120,
                              height: 120,
                              alignSelf: "flex-start",
                              borderRadius: 4,
                            }}
                            resizeMode={"contain"}
                          />
                        </View>
                        <View
                          style={{
                            flex: 1.5,
                            alignItems: "flex-end",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#464E5F",
                              fontWeight: "600",
                            }}
                          >
                            Portfolio Review
                          </Text>
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#7b55c3",
                              fontWeight: "600",
                              marginTop: "2%",
                            }}
                          >
                            2 Remaining
                          </Text>
                          <Button
                            uppercase={false}
                            mode="contained"
                            labelStyle={{
                              color: "#410DAA",
                              fontWeight: "600",
                            }}
                            onPress={() => {}}
                            style={{
                              marginTop: 77,
                              borderColor: "#410DAA",
                              borderWidth: 1,
                              backgroundColor: "white",
                              borderRadius: 6,
                              height: 37,
                              width: 160,
                            }}
                          >
                            Schedule Review
                          </Button>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ marginBottom: 40 }}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View
                      style={{
                        marginLeft: "5%",
                        marginRight: "5%",
                        backgroundColor: "white",
                        width: "90%",
                        height: 210,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: "rgba(65, 13, 170, 0.3)",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          marginLeft: "3%",
                          marginRight: "3%",
                          flexDirection: "row",
                          marginTop: "6%",
                          justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "column",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              marginBottom: "28%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                              }}
                              style={{
                                width: 20,
                                height: 20,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                            <Text
                              style={{
                                fontSize: 15,
                                textDecorationLine: "underline",
                                color: "#410DAA",
                                textAlign: "left",
                                marginLeft: "1%",
                              }}
                              onPress={() => {}}
                            >
                              How it works?
                            </Text>
                          </View>
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/advice.png",
                            }}
                            style={{
                              width: 120,
                              height: 120,
                              alignSelf: "flex-start",
                              borderRadius: 4,
                            }}
                            resizeMode={"contain"}
                          />
                        </View>
                        <View
                          style={{
                            flex: 1.5,
                            alignItems: "flex-end",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#464E5F",
                              fontWeight: "600",
                            }}
                          >
                            Additional Advices
                          </Text>
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#7b55c3",
                              fontWeight: "600",
                              marginTop: "2%",
                            }}
                          >
                            2 Remaining
                          </Text>
                          <Button
                            uppercase={false}
                            mode="contained"
                            labelStyle={{
                              color: "#410DAA",
                              fontWeight: "600",
                            }}
                            onPress={() => {}}
                            style={{
                              marginTop: 77,
                              borderColor: "#410DAA",
                              borderWidth: 1,
                              backgroundColor: "white",
                              borderRadius: 6,
                              height: 37,
                              width: 160,
                            }}
                          >
                            Get Advice
                          </Button>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ marginBottom: 80 }}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View
                      style={{
                        marginLeft: "5%",
                        marginRight: "5%",
                        backgroundColor: "white",
                        width: "90%",
                        height: 150,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: "rgba(65, 13, 170, 0.3)",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          marginLeft: "3%",
                          marginRight: "3%",
                          flexDirection: "column",
                          marginTop: "6%",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 20,
                            color: "#464E5F",
                            fontWeight: "600",
                          }}
                        >
                          Need Advice on the Stocks!
                        </Text>
                        <Text
                          style={{
                            fontSize: 20,
                            color: "#464E5F",
                            fontWeight: "600",
                          }}
                        >
                          Click here to Upgrade.
                        </Text>
                        <Button
                          uppercase={false}
                          mode="contained"
                          labelStyle={{
                            color: "#410DAA",
                            fontWeight: "600",
                          }}
                          onPress={() => {}}
                          style={{
                            marginTop: 16,
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            backgroundColor: "white",
                            borderRadius: 6,
                            height: 37,
                            width: 160,
                          }}
                        >
                          Upgrade Plan
                        </Button>
                      </View>
                    </View>
                  </View>
                </View>
              </>
            ) : (
              <>
                <View
                  style={{ marginTop: 20, marginBottom: isMobile ? 80 : 20 }}
                >
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View
                      style={{
                        marginLeft: "3%",
                        marginRight: "3%",
                        backgroundColor: "white",
                        width: "94%",
                        height: 110,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: "rgba(65, 13, 170, 0.3)",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          marginLeft: "1.9%",
                          marginRight: "1.9%",
                          flexDirection: "row",
                          marginTop: "0.35%",
                          justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "column",
                            marginTop: "1.3%",
                          }}
                        >
                          <View
                            style={{ flexDirection: "row", marginBottom: "4%" }}
                          >
                            <Text
                              style={{
                                fontSize: 20,
                                color: "#464E5F",
                                fontWeight: "700",
                              }}
                            >
                              Advices Remaining
                            </Text>
                          </View>

                          <Text
                            style={{
                              fontSize: 18,
                              color: "#6b717f",
                              fontWeight: "500",
                            }}
                          >
                            Pack Expires on:{" "}
                            <Text
                              style={{
                                fontSize: 18,
                                color: "#8d6ecc",
                                fontWeight: "500",
                              }}
                            >
                              06/05/2025
                            </Text>
                          </Text>
                        </View>
                        <View
                          style={{
                            flex: 1,
                            alignItems: "flex-end",
                            flexDirection: "column",
                            marginRight: "-2.5%",
                          }}
                        >
                          {Platform.OS == "web" ? (
                            <View
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "column",
                                  marginTop: "-2%",
                                }}
                              >
                                <View
                                  style={[
                                    {
                                      alignSelf: "flex-start",
                                      flexDirection: "row",
                                      marginVertical: 7.5,
                                    },
                                  ]}
                                >
                                  <View
                                    style={{
                                      width: 15,
                                      height: 15,
                                      borderRadius: 2,
                                      backgroundColor: "#876CDD",
                                      marginTop: 1,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 14,
                                      color: "#464E5F",
                                      paddingLeft: 13,
                                      fontWeight: "700",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    Advice Availed (14)
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    {
                                      alignSelf: "flex-start",
                                      flexDirection: "row",
                                      marginVertical: 7.5,
                                    },
                                  ]}
                                >
                                  <View
                                    style={{
                                      width: 15,
                                      height: 15,
                                      borderRadius: 2,
                                      backgroundColor: "#BAC7F2",
                                      marginTop: 1,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 14,
                                      color: "#464E5F",
                                      fontWeight: "700",
                                      paddingLeft: 13,
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    Total Advices (72)
                                  </Text>
                                </View>
                              </View>
                              <Donut
                                options={{
                                  dataLabels: {
                                    enabled: false,
                                  },
                                  legend: {
                                    show: false,
                                  },
                                  tooltip: { enabled: false },
                                  colors: ["#876CDD", "#BAC7F2"],
                                  plotOptions: {
                                    pie: {
                                      donut: {
                                        size: "60%",
                                      },
                                    },
                                  },
                                  stroke: {
                                    width: 1,
                                    colors: ["#876CDD", "#BAC7F2"],
                                  },
                                }}
                                series={[14, 72]}
                                sliceColor={["#876CDD", "#BAC7F2"]}
                                type="donut"
                                width="150"
                              />
                            </View>
                          ) : (
                            <Donut
                              widthAndHeight={100}
                              series={[14, 72]}
                              sliceColor={["#876CDD", "#BAC7F2"]}
                              doughnut={true}
                              coverRadius={0.6}
                              coverFill={"#FFF"}
                            />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ marginBottom: isMobile ? 80 : 0 }}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View
                      style={{
                        marginLeft: "3%",
                        marginRight: "1.5%",
                        backgroundColor: "white",
                        paddingTop: 8,
                        marginVertical: "1.5%",
                        width: "45.5%",
                        height: 210,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: "rgba(65, 13, 170, 0.3)",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "4%",
                          marginRight: "4%",
                          marginTop: "2%",
                          justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "column",
                          }}
                        >
                          <View
                            style={{ flexDirection: "row", marginBottom: "5%" }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                              }}
                              style={{
                                width: 20,
                                height: 20,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                            <Text
                              style={{
                                fontSize: 15,
                                textDecorationLine: "underline",
                                color: "#410DAA",
                                textAlign: "left",
                                marginLeft: "1%",
                              }}
                              onPress={() => {}}
                            >
                              How does it work?
                            </Text>
                          </View>
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/mf.png",
                            }}
                            style={{
                              width: 156,
                              height: 156,
                              alignSelf: "flex-start",
                              borderRadius: 4,
                            }}
                            resizeMode={"contain"}
                          />
                        </View>
                        <View
                          style={{
                            flex: 1,
                            alignItems: "flex-end",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 20,
                              color: "#464E5F",
                              fontWeight: "600",
                            }}
                          >
                            Mutual Fund Packs
                          </Text>
                          <Text
                            style={{
                              fontSize: 20,
                              color: "#7b55c3",
                              fontWeight: "600",
                              marginTop: "5%",
                            }}
                          >
                            4 Remaining
                          </Text>
                          <Button
                            uppercase={false}
                            mode="contained"
                            labelStyle={{
                              color: "#410DAA",
                              fontWeight: "600",
                            }}
                            onPress={() => {}}
                            style={{
                              marginTop: 77,
                              borderColor: "#410DAA",
                              borderWidth: 1,
                              backgroundColor: "white",
                              borderRadius: 6,
                              height: 37,
                              width: 160,
                            }}
                          >
                            Request Advice
                          </Button>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        marginRight: "3%",
                        marginLeft: "1.5%",
                        backgroundColor: "white",
                        paddingTop: 8,
                        marginVertical: "1.5%",
                        width: "45.5%",
                        height: 210,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: "rgba(65, 13, 170, 0.3)",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "4%",
                          marginRight: "4%",
                          marginTop: "2%",
                          justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "column",
                          }}
                        >
                          <View
                            style={{ flexDirection: "row", marginBottom: "5%" }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                              }}
                              style={{
                                width: 20,
                                height: 20,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                            <Text
                              style={{
                                fontSize: 15,
                                textDecorationLine: "underline",
                                color: "#410DAA",
                                textAlign: "left",
                                marginLeft: "1%",
                              }}
                              onPress={() => {}}
                            >
                              How does it work?
                            </Text>
                          </View>
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/finplan.png",
                            }}
                            style={{
                              width: 178,
                              height: 178,
                              alignSelf: "flex-start",
                              borderRadius: 4,
                            }}
                            resizeMode={"contain"}
                          />
                        </View>
                        <View
                          style={{
                            flex: 1,
                            alignItems: "flex-end",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 20,
                              color: "#464E5F",
                              fontWeight: "600",
                            }}
                          >
                            Financial Plan Review
                          </Text>
                          <Text
                            style={{
                              fontSize: 20,
                              color: "#7b55c3",
                              fontWeight: "600",
                              marginTop: "5%",
                            }}
                          >
                            2 Remaining
                          </Text>
                          <Button
                            uppercase={false}
                            mode="contained"
                            labelStyle={{
                              color: "#410DAA",
                              fontWeight: "600",
                            }}
                            onPress={() => {}}
                            style={{
                              marginTop: 77,
                              borderColor: "#410DAA",
                              borderWidth: 1,
                              backgroundColor: "white",
                              borderRadius: 6,
                              height: 37,
                              width: 160,
                            }}
                          >
                            Schedule Review
                          </Button>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ marginBottom: isMobile ? 80 : 20 }}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View
                      style={{
                        marginLeft: "3%",
                        marginRight: "1.5%",
                        backgroundColor: "white",
                        paddingTop: 8,
                        marginVertical: "1.5%",
                        width: "45.5%",
                        height: 210,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: "rgba(65, 13, 170, 0.3)",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "4%",
                          marginRight: "4%",
                          marginTop: "2%",
                          justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "column",
                          }}
                        >
                          <View
                            style={{ flexDirection: "row", marginBottom: "5%" }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                              }}
                              style={{
                                width: 20,
                                height: 20,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                            <Text
                              style={{
                                fontSize: 15,
                                textDecorationLine: "underline",
                                color: "#410DAA",
                                textAlign: "left",
                                marginLeft: "1%",
                              }}
                              onPress={() => {}}
                            >
                              How does it work?
                            </Text>
                          </View>
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolioValue.png",
                            }}
                            style={{
                              width: 144,
                              height: 144,
                              alignSelf: "flex-start",
                              borderRadius: 4,
                            }}
                            resizeMode={"contain"}
                          />
                        </View>
                        <View
                          style={{
                            flex: 1,
                            alignItems: "flex-end",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 20,
                              color: "#464E5F",
                              fontWeight: "600",
                            }}
                          >
                            Portfolio Review
                          </Text>
                          <Text
                            style={{
                              fontSize: 20,
                              color: "#7b55c3",
                              fontWeight: "600",
                              marginTop: "5%",
                            }}
                          >
                            2 Remaining
                          </Text>
                          <Button
                            uppercase={false}
                            mode="contained"
                            labelStyle={{
                              color: "#410DAA",
                              fontWeight: "600",
                            }}
                            onPress={() => {}}
                            style={{
                              marginTop: 77,
                              borderColor: "#410DAA",
                              borderWidth: 1,
                              backgroundColor: "white",
                              borderRadius: 6,
                              height: 37,
                              width: 160,
                            }}
                          >
                            Schedule Review
                          </Button>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        marginRight: "3%",
                        marginLeft: "1.5%",
                        backgroundColor: "white",
                        paddingTop: 8,
                        marginVertical: "1.5%",
                        width: "45.5%",
                        height: 210,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: "rgba(65, 13, 170, 0.3)",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "4%",
                          marginRight: "4%",
                          marginTop: "2%",
                          justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "column",
                          }}
                        >
                          <View
                            style={{ flexDirection: "row", marginBottom: "5%" }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/question.png",
                              }}
                              style={{
                                width: 20,
                                height: 20,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                            <Text
                              style={{
                                fontSize: 15,
                                textDecorationLine: "underline",
                                color: "#410DAA",
                                textAlign: "left",
                                marginLeft: "1%",
                              }}
                              onPress={() => {}}
                            >
                              How does it work?
                            </Text>
                          </View>
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/advice.png",
                            }}
                            style={{
                              width: 170,
                              height: 170,
                              alignSelf: "flex-start",
                              borderRadius: 4,
                            }}
                            resizeMode={"contain"}
                          />
                        </View>
                        <View
                          style={{
                            flex: 1,
                            alignItems: "flex-end",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 20,
                              color: "#464E5F",
                              fontWeight: "600",
                            }}
                          >
                            Additional Advices
                          </Text>
                          <Text
                            style={{
                              fontSize: 20,
                              color: "#7b55c3",
                              fontWeight: "600",
                              marginTop: "5%",
                            }}
                          >
                            2 Remaining
                          </Text>
                          <Button
                            uppercase={false}
                            mode="contained"
                            labelStyle={{
                              color: "#410DAA",
                              fontWeight: "600",
                            }}
                            onPress={() => {}}
                            style={{
                              marginTop: 77,
                              borderColor: "#410DAA",
                              borderWidth: 1,
                              backgroundColor: "white",
                              borderRadius: 6,
                              height: 37,
                              width: 160,
                            }}
                          >
                            Get Advice
                          </Button>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ marginBottom: 80 }}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <View
                      style={{
                        marginLeft: "3%",
                        marginRight: "3%",
                        backgroundColor: "white",
                        paddingTop: 8,
                        width: "94%",
                        height: 110,
                        borderWidth: 1,
                        borderRadius: 12,
                        borderColor: "rgba(65, 13, 170, 0.3)",
                        justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          marginLeft: "1.9%",
                          marginRight: "1.9%",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 22,
                            color: "#464E5F",
                            fontWeight: "600",
                            marginTop: -2,
                          }}
                        >
                          Need Advice on the Stocks! Click here to Upgrade.
                        </Text>
                        <View style={{ marginTop: -6 }}>
                          <Button
                            uppercase={false}
                            mode="contained"
                            labelStyle={{
                              color: "#410DAA",
                              fontWeight: "600",
                            }}
                            onPress={() => {}}
                            style={{
                              borderColor: "#410DAA",
                              borderWidth: 1,
                              backgroundColor: "white",
                              borderRadius: 6,
                              width: 160,
                            }}
                          >
                            Upgrade Plan
                          </Button>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </>
            )}
          </ScrollView>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    padding: 5,
  },
  modalContentContainer: {
    backgroundColor: "white",
    padding: 8,
    paddingRight: 0,
    borderRadius: 4,
    width: "100%",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default CorpCountTracker;
