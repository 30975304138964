import React from 'react';
import { Text, View, StyleSheet, Image, ScrollView } from 'react-native';


const NoPendingBills = ({ title }) => {
    return (
        <ScrollView>
            <View style={styles.mainContainer}>
                <View style={{ alignItems: "center" }}>
                    <Text style={styles.no_pend}>{title || "No Pending Bills"}</Text>
                    <Image source={"https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/undraw_Receipt_re_fre3+(1)+1.png"} style={styles.image} />
                    <Text style={styles.have}>You don’t have any bills here</Text>
                    {/* <Button
                        uppercase={false}
                        labelStyle={styles.button}
                    >
                        View Planwise Billing log
                    </Button> */}
                </View>
            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "#ffffff",
        paddingBottom: 40
    },
    image: {
        width: 250,
        height: 260,
        resizeMode: "contain"
    },
    bills: {
        fontFamily: "Roboto",
        fontSize: 16,
        lineHeight: 18,
        color: "#434349",
        fontWeight: "bold"
    },
    bills_bag: {
        backgroundColor: "#f6f6f6",
        paddingVertical: 18,
        paddingLeft: 67
    },
    no_pend: {
        fontFamily: "Roboto",
        fontSize: 18,
        lineHeight: 21,
        fontWeight: "bold",
        color: "#000000",
        paddingVertical: 46
    },
    have: {
        fontFamily: "Roboto",
        fontSize: 15,
        lineHeight: 23,
        color: "#121212",
        paddingTop: 20,
        paddingBottom: 24,
    },
    button: {
        fontSize: 15,
        lineHeight: 17,
        fontFamily: "Roboto",
        color: "#ffffff",
        backgroundColor: "#410DAA",
        borderRadius: 4,
        paddingHorizontal: 81,
        paddingVertical: 13
    }
});

export default NoPendingBills;