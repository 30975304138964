import React, { useState } from "react";
import { Dimensions, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import ReviewCard from "./reviewCard";
import { sm, md, lg, xl, twoxl } from "../../Responsive";
import global from "./globalStyles";

const window = Dimensions.get("window");

export default function ClientReviews({ clients, title }) {
    const [showAll, setShowAll] = useState(false);
    let clientsToShow =
        showAll || clients.length <= 2
            ? clients
            : md(window.width)
            ? clients.slice(0, 3)
            : clients.slice(0, 2);
    return (
        <View style={styles.container}>
            <Text style={[styles.heading, global.arial]}>
                {title ? title : "What our clients say about us"}
            </Text>
            <View style={styles.reviewsContainer}>
                {clientsToShow.map((c, i) => (
                    <ReviewCard client={c} key={c.id} first={i === 0}></ReviewCard>
                ))}
            </View>
            {clients.length > clientsToShow.length && (
                <TouchableOpacity onPress={() => setShowAll(true)}>
                    <Text style={[styles.link, global.arial]}>
                        View More Testimonials
                    </Text>
                </TouchableOpacity>
            )}
        </View>
    );
}
  
const styles = StyleSheet.create({
    container: {
        paddingBottom: 40
    },
    heading: {
        fontWeight: "bold",
        marginTop: md(window.width) ? 60 : 40,
        fontSize: 20,
        textAlign: "center",
        color: "black"
    },
    link: {
        textAlign: "center",
        color: "#276FC4",
        marginTop: 12
    },
    reviewsContainer: {
        display: Platform.OS === "web" ? "flex" : undefined,
        flexDirection: Platform.OS === "web" ? "row" : undefined,
        flexWrap: Platform.OS === "web" ? "wrap" : undefined,
        justifyContent: Platform.OS === "web" ? "space-around" : undefined
    }
});
  

