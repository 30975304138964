import React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import { getJwlSrkReturns } from "../../api";
import SideHeading from "../../components/InvestmentOverview/NewDesign/components/SideHeading";
import { isMobile } from "../../utils";
import amplitude from "../../utils/amplitude";

export default class GrowWealthStrip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      returnsData: {
        JEWEL: {
          one_year: null,
          three_year: null,
          five_year: null,
          ten_year: null,
        },
        NIFTY: {
          one_year: null,
          three_year: null,
          five_year: null,
          ten_year: null,
        },
        SPARK: {
          one_year: null,
          three_year: null,
          five_year: null,
          ten_year: null,
        },
        BSE_SMALLCAP: {
          one_year: null,
          three_year: null,
          five_year: null,
          ten_year: null,
        },
      },
    };
  }

  async componentDidMount() {
    let returnsResponse = await getJwlSrkReturns();
    if (returnsResponse && returnsResponse.code == 100) {
      this.setState({
        returnsData: returnsResponse.result,
      });
    } else {
      let data = {
        JEWEL: {
          one_year: -3.87,
          three_year: 19.2,
          five_year: 15.17,
          ten_year: 22.81,
        },
        NIFTY: {
          one_year: 10.81,
          three_year: 15.68,
          five_year: 13.32,
          ten_year: 13.5,
        },
        SPARK: {
          one_year: 0.28,
          three_year: 50.53,
          five_year: 15.65,
          ten_year: 40.75,
        },
        BSE_SMALLCAP: {
          one_year: 6,
          three_year: 30.24,
          five_year: 9.6,
          ten_year: 14.97,
        },
      };
      this.setState({
        returnsData: data,
      });
    }
  }

  render() {
    const { style = {} } = this.props;
    const { returnsData } = this.state;
    return (
      <View style={{}}>
        <View style={[styles.subContainer, { ...style }]}>
          <View
            style={{
              marginLeft: this.props.dontShowFunds ? 0 : 0,
              marginTop: this.props.dontShowFunds ? -20 : isMobile ? 0 : 10,
            }}
          >
            <SideHeading
              title={
                this.props.dontShowFunds
                  ? "Direct Equity (Stocks)"
                  : "Curated Direct Equity (Stocks)"
              }
              textStyle={[styles.sideHeadingStyle]}
            />
          </View>
          <View style={styles.strips}>
            <TouchableOpacity
              style={styles.stripContainer}
              onPress={() => {
                amplitude.logEvent("JEWEL_Direct_Equity_Card_Clicked");
                this.props.navigation.navigate("App", {
                  screen: "Dashboard",
                  params: {
                    screen: "Dashboard",
                    params: {
                      screen: "ScheduleCall",
                      params: {
                        topic: "DE",
                        dontShowFunds: this.props.dontShowFunds,
                      },
                    },
                  },
                });
              }}
            >
              <Text
                style={[
                  styles.messageText,
                  {
                    color: "#00AE7A",
                    backgroundColor: "#FFF0E8",
                    borderColor: "#FFF0E8",
                  },
                ]}
              >
                Medium Volatility
              </Text>
              <View style={styles.stripSubContainer}>
                <View>
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/jewel.png",
                    }}
                    style={{
                      width: 48,
                      height: 48,
                      marginTop: 2,
                      resizeMode: "contain",
                    }}
                  />
                </View>
                <View style={{ paddingLeft: 15 }}>
                  <View>
                    <Text style={styles.stripTextTitle}>JEWEL</Text>
                  </View>
                  <View style={{ paddingTop: 8 }}>
                    <Text
                      style={[
                        styles.stripTextContainer,
                        { fontWeight: "700", color: "#464E5F" },
                      ]}
                    >
                      Flexicap Growth Strategy | Top 350
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.stripSubContainerTwo}>
                <Text style={styles.stripSubContainerText}>
                  High Return On Equity | High Return On Assets |
                </Text>
                <Text style={styles.stripSubContainerText}>
                  High Growth | Low Debt | Market Share | Management
                </Text>
              </View>
            </TouchableOpacity>
            <View style={{ padding: 15 }}></View>
            <TouchableOpacity
              style={styles.stripContainer}
              onPress={() => {
                amplitude.logEvent("SPARK_Direct_Equity_Card_Clicked");
                this.props.navigation.navigate("App", {
                  screen: "Dashboard",
                  params: {
                    screen: "Dashboard",
                    params: {
                      screen: "ScheduleCall",
                      params: {
                        topic: "DE",
                        dontShowFunds: this.props.dontShowFunds,
                      },
                    },
                  },
                });
              }}
            >
              <Text
                style={[
                  styles.messageText,
                  {
                    color: "#EF5D12",
                    backgroundColor: "#FFF0E8",
                    borderColor: "#FFF0E8",
                  },
                ]}
              >
                High Volatility
              </Text>
              <View style={styles.stripSubContainer}>
                <View>
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/spark.png",
                    }}
                    style={{
                      width: 48,
                      height: 48,
                      marginTop: 2,
                      resizeMode: "contain",
                    }}
                  />
                </View>
                <View style={{ paddingLeft: 15 }}>
                  <View>
                    <Text style={styles.stripTextTitle}>SPARK</Text>
                  </View>
                  <View style={{ paddingTop: 8 }}>
                    <Text
                      style={[
                        styles.stripTextContainer,
                        { fontWeight: "700", color: "#464E5F" },
                      ]}
                    >
                      Smallcap Growth Strategy | Top 650
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.stripSubContainerTwo}>
                <Text style={styles.stripSubContainerText}>
                  High Return On Equity | High Return On Assets |
                </Text>
                <Text style={styles.stripSubContainerText}>
                  High Growth | Low Debt | Market Share | Management
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        {this.props.dontShowFunds ? null : (
          <View
            style={{
              paddingBottom: 25,
              paddingVertical: isMobile ? 10 : 0,
              marginTop: -50,
            }}
          >
            <SideHeading
              title="Curated Funds Equity"
              textStyle={[styles.sideHeadingStyle]}
            />
            <View style={styles.strips}>
              <TouchableOpacity
                style={[
                  styles.stripContainerTwo,
                  { width: isMobile ? "100%" : 360 },
                ]}
                onPress={() => {
                  amplitude.logEvent("Equity_Index_Card_Clicked");
                  this.props.navigation.navigate("Explore", {
                    screen: "MFExplore",
                    params: { index: "1", filter: "equity", type: "Flexi Cap", from: "dashboard" },
                  });
                }}
              >
                {/* <Text
                  style={[
                    styles.messageText,
                    {
                      color: "#410DAA",
                      backgroundColor: "rgba(65, 13, 170, 0.1)",
                      borderColor: "rgba(65, 13, 170, 0.1)",
                    },
                  ]}
                >
                  Direct Plan
                </Text> */}
                <View style={styles.stripSubContainer}>
                  <View>
                    <Image
                      source={{
                        uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/media/home/index+funds.png",
                      }}
                      style={{
                        width: 42,
                        height: 42,
                        marginTop: 3,
                        resizeMode: "contain",
                      }}
                    />
                  </View>
                  <View style={{ paddingLeft: 15 }}>
                    <View>
                      <Text style={styles.stripTextTitle}>
                        Flexicap Funds
                      </Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
              <View style={{ padding: 15 }}></View>
              <TouchableOpacity
                style={[
                  styles.stripContainerTwo,
                  { width: isMobile ? "100%" : 360 },
                ]}
                onPress={() => {
                  amplitude.logEvent("Equity_Multicap_Card_Clicked");
                  this.props.navigation.navigate("Explore", {
                    screen: "MFExplore",
                    params: { index: "1", filter: "Hybrid", type: "Arbitrage", from: "dashboard" },
                  });
                }}
              >
                {/* <Text
                  style={[
                    styles.messageText,
                    {
                      color: "#410DAA",
                      backgroundColor: "rgba(65, 13, 170, 0.1)",
                      borderColor: "rgba(65, 13, 170, 0.1)",
                    },
                  ]}
                >
                  Direct Plan
                </Text> */}
                <View style={styles.stripSubContainer}>
                  <View>
                    <Image
                      source={{
                        uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/media/home/equity+multicap.png",
                      }}
                      style={{
                        width: 42,
                        height: 42,
                        marginTop: 3,
                        resizeMode: "contain",
                      }}
                    />
                  </View>
                  <View style={{ paddingLeft: 15 }}>
                    <View>
                      <Text style={styles.stripTextTitle}>
                        Aggressive Hybrid Funds
                      </Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  subContainer: {
    paddingVertical: 10,
    marginBottom: 66,
  },
  strips: {
    flex: 1,
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 8,
    paddingLeft: 0,
    paddingRight: 0,
  },
  stripSubContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  stripContainer: {
    borderWidth: 1,
    borderRadius: 15,
    borderColor: "rgba(65, 13, 170, 0.2)",
    height: 140,
    width: isMobile ? "100%" : 360,
    padding: 15,
    position: "relative",
  },
  stripContainerTwo: {
    borderWidth: 1,
    borderRadius: 15,
    borderColor: "rgba(65, 13, 170, 0.2)",
    height: 130,
    width: isMobile ? 340 : 360,
    padding: 15,
    position: "relative",
  },
  stripTextTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: "#464E5F",
    fontFamily: "Roboto",
  },
  stripTextContainer: {
    fontSize: 13,
    fontStyle: "italic",
    color: "#888C9F",
    fontFamily: "Roboto",
  },
  stripSubContainerTwo: {
    marginBottom: 15,
  },
  subStripSubContainerTwo: {
    marginBottom: 15,
  },
  stripSubContainerText: {
    fontSize: 12,
    color: "#410DAA",
    fontStyle: "italic",
    fontFamily: "Roboto",
  },
  messageText: {
    position: "absolute",
    top: 0,
    right: 0,
    fontSize: 9,
    fontWeight: "700",
    fontFamily: "Roboto",
    fontStyle: "italic",
    borderWidth: 1,
    borderTopRightRadius: 11,
    padding: 5,
  },
  sideHeadingStyle: {
    fontSize: 14,
  },
  tableContainer: {
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.1)",
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  tableHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  tableBody: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  tableHeaderText: {
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  textContentView: {
    padding: 5,
    height: 23,
  },
  seperator: {
    width: 1,
    height: 82,
    backgroundColor: "#888C9F66",
  },
  seperatorTwo: {
    width: "100%",
    height: 1,
    backgroundColor: "#888C9F66",
  },
  tableBodyText: {
    fontSize: 11,
    fontFamily: "Roboto",
    fontWeight: "600",
    color: "#464E5F",
  },
});
