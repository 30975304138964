import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { sm, md, lg, xl, twoxl } from "../../Responsive";
const window = Dimensions.get("window");

export default class NavigationHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      style = {},
      containerStyle = {},
      navigation,
      navigationHeader = [],
      onPressBack,
      children = null,
      rightSideComponent,
      showBackIcon = true,
    } = this.props;

    const ArrowBackIcon = require("../../assets/JamaInvest/Arrow2x.png");

    return (
      <View
        style={[
          {
            backgroundColor: "#F6F6F6",
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: 12,
            paddingTop: 18,
            paddingBottom: 22,
          },
          style,
        ]}
      >
        {showBackIcon && (
          <TouchableOpacity
            onPress={() => {
              if (onPressBack) {
                onPressBack();
              } else {
                navigation && navigation.pop();
              }
            }}
          >
            <Image source={ArrowBackIcon} style={styles.backIcon}></Image>
          </TouchableOpacity>
        )}
        {navigationHeader.length != 0 && (
          <>
            <Text style={styles.mainTitle}>
              {navigationHeader[navigationHeader.length - 1]}
            </Text>
            {md(window.width) &&
              navigationHeader.length > 1 &&
              navigationHeader.map((title, index) => {
                return (
                  <View style={styles.subtitleContainer}>
                    <Text style={styles.subTitle}>{title}</Text>
                    {index != navigationHeader.length - 1 && (
                      <View style={styles.dot}></View>
                    )}
                  </View>
                );
              })}
          </>
        )}
        {children}
        {rightSideComponent && <>{rightSideComponent}</>}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  mainTitle: {
    fontSize: md(window.width) ? 16 : 14,
    fontWeight: "bold",
    color: "#434349",
    paddingHorizontal: 5,
  },
  backIcon: {
    width: 25,
    height: 15,
    marginRight: 20,
  },
  subTitle: {
    fontSize: 13,
    color: "#959CB6",
    paddingHorizontal: 8,
  },
  subtitleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  dot: {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: "#959CB6",
  },
});
