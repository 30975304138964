import { useNavigation } from "@react-navigation/core";
import { useEffect, useState } from "react";
import { Platform } from "react-native";
import { reverseString } from ".";
import { getHashKey } from "../api";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../api/apiConfig";
import { MAIN_URL } from "../api/urls";

export const usePlaceTopup = (url) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  useEffect(() => {}, []);

  const validateTopUp = (amount) => {
    return !isNaN(amount) && amount != null && amount != 0;
  };

  const isValidData = (amount, product) => {
    const isValid = validateTopUp(amount);
    if (!product) {
      setErrorText("Please select product");
      setIsLoading(false);
      return false;
    }
    if (!isValid) {
      setErrorText("Please enter amount");
      setIsLoading(false);
      return false;
    }

    return true;
  };

  const placeTopUp = async (data = {}) => {
    try {
      const { amount, product } = data;
      setIsLoading(true);
      const isValid = isValidData(amount, product);
      if (!isValid) return;
      let url = MAIN_URL + "/app/gwsc/placeorder/";
      let url_payalod = "";

      if (amount) {
        url_payalod += "topup_" + amount;
      }

      const payload = {
        cipamountstr: url_payalod,
        product: product,
        redirection_url: "explore/mutualfunds",
      };

      const response = await getHashKey(payload);
      if (response.code == 100) {
        const { hash_key } = response.result;
        let token = getTokenFromStore();
        token = reverseString(token);
        url =
          url +
          encodeURIComponent(hash_key + token + "/" + GATEWAY_ENVIRONMENT);
        setErrorText("");
        global.open(url, "_self");
      } else {
        let defaultErrorMessage = "Something went wrong!";
        if (response.code == 102) {
          defaultErrorMessage = "Enter valid amount";
        }
        setIsLoading(false);
        const text =
          response && response.msg ? response.msg : defaultErrorMessage;
        setErrorText(text);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorText("Something went wrong!");
    }
  };

  return { loading: isLoading, error: errorText, placeTopUp };
};

export const useNavigateToUrl = (url) => {
  const [uri, setUri] = useState(null);
  const navigation = useNavigation();

  useEffect(() => {
    if (url) {
      setUri(url);
      open(url);
    }
  }, [url]);

  const open = (url) => {
    if (Platform.OS == "web") {
      global.open(url);
    } else {
      navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Dashboard",
          params: { screen: "WebViewGeneral", params: { url: url } },
        },
      });
    }
  };

  return open;
};
