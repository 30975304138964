import React from "react";
import { View, Text, Platform, StyleSheet, ScrollView } from "react-native";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import {
  encryptToken,
  formatDate,
  getUserDetails,
  isMobile,
  numberWithCommas,
} from "../../../utils";
import { getBillingLedgerPlanDetails } from "../../../api/billing";
import amplitude from "../../../utils/amplitude";
import { ActivityIndicator, Button } from "react-native-paper";
import HoldingsTable from "../HoldingsTable";
import { MAIN_URL } from "../../../api/urls";
import Store from "../../../store";

class BillingLedgerDetails extends React.Component {
  constructor(props) {
    super(props);
    let bill_id, date;
    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      bill_id = params.bill_id || null;
      user_id = params.user_id || null;
      date = params.date || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      bill_id,
      date,
      user_id,
      isLoading: true,
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("BillingLedgerDetails",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("BillingLedgerDetails", { path: this.props.navigation.getState().routeName });
      }
      const response = await getBillingLedgerPlanDetails(this.state.bill_id);
      if (response && response.code == 100) {
        const { result = [] } = response;
        const paid_types = ["PYM", "PJW", "ESP", "PPJ", "PMP"];
        const net_due_amount = result.reduce((acc, curr) => {
          const is_paid_entry = paid_types.indexOf(curr.bill_type) != -1;
          return is_paid_entry ? acc - curr.amount : acc + curr.amount;
        }, 0);

        this.setState({ data: result, isLoading: false, net_due_amount });
        return;
      }
    } catch (error) {
      this.setState({
        error: "Error loading transaction details",
        isLoading: false,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  navigateBack = () => {
    const { navigation } = this.props;
    if (navigation) {
      navigation.goBack();
    }
  };

  handleSearch = (searchText) => {
    this.setState({ searchText });
  };

  compare = (a, b, field, sort) => {
    if (a[field] < b[field]) {
      return sort ? -1 : 1;
    }
    if (a[field] > b[field]) {
      return sort ? 1 : -1;
    }
    return 0;
  };

  handleSort = (field, sort, start, end) => {
    this.setState(
      {
        sorting_field: field,
        sorting_value: !sort,
      },
      () => {
        const { data } = this.state;
        const transactions_data = [...data["transactions"]];
        const sortedData = [].concat(
          transactions_data.slice(0, start),
          transactions_data
            .slice(start, end)
            .sort((a, b) => this.compare(a, b, field, !sort)),
          transactions_data.slice(end, transactions_data.length)
        );
        this.setState({ data: { ...data, ["transactions"]: sortedData } });
      }
    );
  };

  getTableData = (data = []) => {
    const headerData = [
      {
        label: "Bill Details",
        style: [styles.tableHeader, { paddingLeft: 10 }],
        sortOption: false,
        key: "plan_code",
      },
      {
        label: "Inovice Id",
        style: [styles.tableHeader, { paddingLeft: 10 }],
        sortOption: false,
        key: "invoice_id",
      },
      {
        label: "Due Date",
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        label: "AUA",
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        label: "Fee Amount",
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        label: "Discount",
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        label: "Net",
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        component: true,
        label: (
          <View style={styles.labelComponentContainer}>
            <Text style={styles.lableComponent}>Net</Text>
            <Text style={styles.lableComponentDescription}>(with GST)</Text>
          </View>
        ),
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        component: true,
        label: (
          <View style={styles.labelComponentContainer}>
            <Text style={styles.lableComponent}>Paid</Text>
            <Text style={styles.lableComponentDescription}>(with GST)</Text>
          </View>
        ),
        style: [styles.tableHeader],
        sortOption: false,
      },
      {
        label: "",
        style: [styles.tableHeader],
        sortOption: false,
      },
    ];

    let { values = [] } = this.generateDataForTable(data);
    return { headerData, values };
  };

  getBillName = (type, plan) => {
    let bill_name = "";
    switch (type) {
      case "JWN":
        bill_name = "Advisory Fee (period start)";
        break;
      case "ESB":
        bill_name = "Advisory Fee Adjustment (period end)";
        break;
      case "JWP":
        bill_name = "Performance Fee";
        break;
      case "FLT":
        bill_name = "Flat Fee";
        break;
      default:
        bill_name = "Advisory Fee";
        break;
    }

    switch (plan) {
      case "LP2":
      case "SP2":
        bill_name = "Advisory Fee";
        break;
      default:
        break;
    }

    return bill_name;
  };

  generateDataForTable = (data) => {
    let values = [];
    data.forEach((item) => {
      let {
        customerbillingitem_id,
        due_date,
        bill_type,
        amount: net,
        bill_period_from_date,
        bill_period_to_date,
        aua_billed_for = 0,
        customerinvestmentplan__amount = 0,
        discount_perc_applied,
        amount_before_discount,
        additional_discount_on_bill,
        bill_amount_including_gst,
        customerinvoice__customerinvoice_id,
        customerinvestmentplan__plan_code: plan,
      } = item;
      const paid_types = ["PYM", "PJW", "ESP", "PPJ", "PMP"];
      const is_paid_entry = paid_types.indexOf(bill_type) != -1;
      const net_value = numberWithCommas(parseInt(net));
      const net_gst = numberWithCommas(parseInt(bill_amount_including_gst));
      aua_billed_for = numberWithCommas(
        parseInt(aua_billed_for || customerinvestmentplan__amount)
      );
      discount_perc_applied = numberWithCommas(parseInt(discount_perc_applied));
      additional_discount_on_bill = numberWithCommas(
        parseInt(additional_discount_on_bill)
      );
      const discount = numberWithCommas(parseInt(amount_before_discount - net));
      amount_before_discount = numberWithCommas(
        parseInt(amount_before_discount)
      );

      values.push([
        {
          component: true,
          value: (
            <>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#410DAA" }}
              >
                {this.getBillName(bill_type, plan)}
              </Text>
              <Text>{"\n"}</Text>
              <Text style={{ fontSize: 12, color: "#12121270", marginTop: 5 }}>
                ({formatDate(bill_period_from_date)}-
                {formatDate(bill_period_to_date)})
              </Text>
            </>
          ),
          style: [styles.rowItem, { paddingLeft: 20, textAlign: "left" }],
          rowItemContainerStyle: [{ justifyContent: "flex-start" }],
        },
        {
          valueToDisplay: customerinvoice__customerinvoice_id ? "#" + customerinvoice__customerinvoice_id : "",
          value: customerinvoice__customerinvoice_id,
          style: [styles.rowItem, {fontSize: 12, fontWeight: "bold"}], 
        },
        {
          valueToDisplay: formatDate(due_date),
          value: due_date,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + aua_billed_for,
          value: aua_billed_for,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: is_paid_entry ? "-" : "₹" + amount_before_discount,
          value: amount_before_discount,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: is_paid_entry ? "-" : "₹" + discount,
          value: discount,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: is_paid_entry ? "-" : "₹" + net_value,
          value: net_value,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: is_paid_entry ? "-" : "₹" + net_gst,
          value: net_gst,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: !is_paid_entry ? "-" : "₹" + net_gst,
          value: net_gst,
          style: [styles.rowItem],
        },
        {
          component: true,
          value: (
            <View style={{ alignItems: "center" }}>
              <Text style={styles.billId}>
                Billd ID: {customerbillingitem_id}
              </Text>
              <Button
                mode="text"
                uppercase={false}
                onPress={() =>
                  this.viewBillDetails(customerbillingitem_id, bill_type)
                }
                style={{}}
                color={"#410DAA"}
              >
                <Text style={styles.viewBill}>View Bill</Text>
              </Button>
            </View>
          ),
          style: [styles.rowItem],
        },
      ]);
    });
    return { values };
  };

  viewBillDetails = (cbi_pk, bill_code) => {
    let params = {
      bill_id: cbi_pk,
    };
    if (this.state.user_id) {
      params["user_id"] = this.state.user_id;
    }
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (bill_code == "ASV" || bill_code == "ACF") {
      const { token } = getUserDetails();
      const encryptedToken = encryptToken(token);
      const url =
        MAIN_URL +
        "/app/rnw_to_web_login?token=" +
        encryptedToken +
        "&is_rnw=1&redirection_url=/app/billdetail/" +
        bill_code +
        "/" +
        cbi_pk +
        "/?userid=" +
        this.state.user_id;
      let popup_window = window.open(
        url,
        hostname,
        "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=yes,width=670,height=700"
      );
      try {
        popup_window.focus();
      } catch (e) {
        alert(
          "Pop-up Blocker is enabled! Please add this site to your exception list."
        );
      }
    } else {
      this.props.navigation.navigate("ViewBill", { ...params });
    }
  };

  render() {
    const { data = [], isLoading, error, net_due_amount = 0 } = this.state;

    const { headerData, values } = this.getTableData(data);
    return (
      <View style={styles.container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={[
            "Plan Ledger",
            "Plan #" + this.state.bill_id,
            "Bills",
          ]}
        />
        <ScrollView
          style={{
            backgroundColor: "#FFFFFF",
          }}
        >
          <View
            style={{
              marginBottom: 90,
              paddingBottom: 50,
            }}
          >
            <ScrollView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
            >
              {isLoading || error ? (
                <View style={styles.loadingContainer}>
                  {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
                  {error && <Text style={styles.error}>{error}</Text>}
                </View>
              ) : (
                <View style={styles.detailsContainer}>
                  <View style={styles.headingContainer}>
                    <Text style={styles.header}>Bills</Text>
                    <Text style={styles.due}>
                      Balance due: ₹{numberWithCommas(parseInt(net_due_amount))}
                    </Text>
                  </View>
                  <HoldingsTable
                    header={headerData}
                    gridConfig={[3, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
                    data={values}
                    navigation={this.props.navigation}
                    pagination={values.length > 15}
                  />
                </View>
              )}
          </ScrollView>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
  },
  scrollView: {
    flexGrow: 1,
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: isMobile ? 16 : 36,
    marginVertical: 30,
    width: 1100,
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 15 : 40,
    paddingVertical: 19,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "700",
  },
  amountHeading: {
    fontSize: 15,
    fontWeight: "700",
    color: "#464E5F",
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  due: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "700",
  },
  viewBill: {
    fontSize: 13,
    color: "#410DAA",
    fontWeight: "700",
  },
  billId: {
    fontSize: 12,
    color: "#464E5F",
    paddingBottom: 4,
  },
  rowItem: {
    fontSize: 14,
  },
  labelComponentContainer: {
    alignItems: "center",
  },
  lableComponent: {
    fontSize: 14,
    textAlign: "center",
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  lableComponentDescription: {
    fontSize: 12,
    color: "#464E5F70",
  },
});

export default BillingLedgerDetails;
