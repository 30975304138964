import React from "react";
import { View, SafeAreaView, FlatList, StatusBar, ActivityIndicator, Platform,Image, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { connect } from "react-redux";
import SearchBar from "../../components/Common/SearchBar";
import { FundCard } from "../../components/Common/fundCard";
import { getcartItems } from "../../api";
import Store from "../../store";
import amplitude from "../../utils/amplitude";
import theme from "../../static/theme";

class WishList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            isLoading: true
        };
    }

    componentDidMount = async () => {
        try {
            if (Platform.OS == "web"){
                amplitude.logEvent("wishList",{ path: global.location.href })
              }
              else{
                amplitude.logEvent("wishList", { path: this.props.navigation.getState().routeName });
              }
            // this.generateData();
            this.setState({isLoading: true});
            const response = await getcartItems();
            if (response) {
                let wishlist = response.filter(item => item.status == "WL");
                let cart = response.filter(item => item.status == "CRT");
                wishlist = wishlist.filter(
                    (v, i, a) =>
                    a.findIndex(t => t.AMCFund__amcfund_id === v.AMCFund__amcfund_id) ===
                    i
                );
                Store.dispatch({
                    type: "UPDATE_WISHLIST",
                    payload: {
                        data: wishlist
                    }
                });
                this.setState({ wishlist: wishlist,cart_count: cart.length, isLoading: false, error: false });
            } else {
                this.setState({ isLoading: false, error: true });
            }
        } catch (err) {
            console.log(err);
            this.setState({isLoading: false});
        }
    }

    generateData = () => {
        const data = [];
        const { wishlist } = this.state;
        this.setState({ data: [...wishlist], isLoading: false });
    };

    getSearchData = (wishlist, searchText) => {
        if (!searchText || (searchText && searchText.trim() == "")) return wishlist;
        else {
            const { wishlist } = this.props;
            const filteredWishlist = wishlist.filter((item, index) => {
                return item.name.toLowerCase().indexOf(searchText.toLowerCase()) != -1;
            });
        
            return filteredWishlist;
        }
    };

    onTyping = searchText => {
        this.setState({ searchText });
    };
    
    addOrRemoveFromWishlist = (item, value) => {
        this.generateData();
    };

    renderFunds = ({ item, index }) => {
        return (
          <FundCard
                // wishlit={this.props.wishlist}
                data={item}
                navigation={this.props.navigation}
                fund_name={item.name}
                growth_value={item.day_change}
                growth_duration={"1day"}
                return_percentage={item.returns_1_yr || item.direct_returns_1_yr_regular}
                fund_size={item.assets_under_management}
                volatality={item.risk_factor || item.AMCFund__risk_factor}
                category_comparison_max={null}
                category_comparison_min={null}
                is_jama_suggested={item.AMCFund__is_jama_pick}
                // addOrRemoveFromWishlist={(item, value) => {
                // this.addOrRemoveFromWishlist(item, value);
                // }}
                fundId={item.AMCFund__amcfund_id}
                schemeId={item.amcfundscheme_id}
                index={index}
                style={{}}
                onClick={() => {}}
            />
        );
    };

    renderEmptyComponent = () => {
        if (this.state.isLoading) {
            // return null;
            return (
                <ActivityIndicator size="small" color={theme.colors.NEW_BRAND_COLOR} />
            );
        } else {
            return (
                <Text
                style={{
                    marginTop: 60,
                    fontSize: 16,
                    color: "#121212",
                    textAlign: "center",
                    color: "#A8A8A8",
                    alignSelf: "center"
                }}
                >
                {this.props.wishlist && this.props.wishlist.length == 0
                    ? "No funds added to wish list!"
                    : "No results found!"}
                </Text>
            );
        }
    };

    render() {
        const cartCount = this.state.cart_count;
        const searchData = this.getSearchData(
            this.state.wishlist,
            this.state.searchText
        );
        return (
            <SafeAreaView style={{ flex: 1, backgroundColor: "#fff", maxWidth: 800 }}>
                <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
                <NavigationHeader
                    navigation={this.props.navigation}
                    navigationHeader={["Your Wish List"]}
                    onPressBack={() => {
                        this.props.navigation.goBack();
                    }}
                    rightSideComponent={
                        <>
                            <TouchableOpacity
                                style={{flex: 1,
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",}}
                                onPress={() => {
                                this.props.navigation.navigate("ShowPlacedOrders");
                                }}
                            >
                                <Image
                                source={require("../../assets/home/shopping_cart.png")}
                                style={{
                                    width: 22,
                                    height: 22,
                                    alignSelf: "flex-end"
                                }}
                                resizeMode={"contain"}
                                />
                
                                {cartCount != 0 && (
                                <View
                                    style={{
                                    position: "absolute",
                                    backgroundColor: "#EF5D12",
                                    width: 7,
                                    height: 7,
                
                                    borderRadius: 3.5,
                                    justifyContent: "center",
                                    alignContent: "center",
                                    top: -1,
                                    right: -1
                                    }}
                                ></View>
                                )}
                            </TouchableOpacity>
                        </>
                    }
                />
                <View style={{width: '100%', padding: "20px"}}>
                <SearchBar
                    placeholder="Search wish list"
                    style={[{width:"100%"}]}
                    // style={{ marginHorizontal: 16, marginVertical: 10 }}
                    value={this.state.searchText}
                /></View>
                <FlatList
                    ref={"listview"}
                    //   keyExtractor={(item, index) => index.toString()}
                    extradata={this.state.wishlist}
                    showsVerticalScrollIndicator={true}
                    ListEmptyComponent={this.renderEmptyComponent}
                    data={searchData}
                    renderItem={this.renderFunds}
                    contentContainerStyle={{
                        marginHorizontal: 16
                    }}
                />
                {/* <Loader loading={this.state.isLoading} /> */}
            </SafeAreaView>
        )
    }
}

const mapStateToProps = state => ({
    cart_count: state.user.cart.length,
    wishlist: state.user.wishlist
});
  
  export default connect(null)(WishList);