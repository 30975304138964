import React, { Component } from "react";
import {
  View,
  StatusBar,
  FlatList,
  SafeAreaView,
  Platform,
  Dimensions,
  StyleSheet,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import { getPendingPayments } from "../../../api/alerts";
import PendingPayementsAction from "./PendingPayementsCard";
import amplitude from "../../../utils/amplitude";
import NavigationHeader from "../../../components/Common/NavigationHeader";
const { width, height } = Dimensions.get("window");
export default class PendingPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payements: [],
      showWebViewBool: false,
      showHTMLContent: "",
      loading: false,
    };
  }

  componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("PendingPayments",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("PendingPayments", { path: this.props.navigation.getState().routeName });
    }
    this._pendingPayments();
  }

  _pendingPayments = async () => {
    try {
      this.setState({ loading: true });
      const paymentsResponse = await getPendingPayments();
      let payements = [];
      if (
        paymentsResponse &&
        paymentsResponse.code &&
        paymentsResponse.code == "100"
      ) {
        payements = paymentsResponse.results;
        this.setState({
          payements,
          loading: false,
        });
      }
    } catch (error) {}
  };

  _renderView = (items) => {
    return (
      <PendingPayementsAction
        data={items.item}
        navigation={this.props.navigation}
        reloadPendingPayments={() => {
          this._pendingPayments();
        }}
      />
    );
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Pending Payments"]}
          onPressBack={() => {
            this.props.navigation.goBack(null);
          }}
        />
        <StatusBar
          backgroundColor={"#410DAA"}
          barStyle={Platform.OS == "android" ? "light-content" : "dark-content"}
        />

        <ScrollView style={{ width, height, padding: 10 }}>
          {this.state.loading ? (
            <View style={styles.loadingContainer}>
              <ActivityIndicator size="large" color="#410DAA" />
            </View>
          ) : (
            <View style={{
              paddingBottom: 200
            }}>
            <FlatList
              data={this.state.payements.reverse()}
              renderItem={this._renderView}
              keyExtractor={(item, index) => index.toString()}
              contentContainerStyle={{
                paddingBottom: 50,
                flex: 1,
              }}
            />
            </View>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    padding: 16,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
