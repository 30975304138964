import React from "react";
import { View, Image, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import theme from "../../../static/theme/index.js";
import { isMobile } from "../../../utils";

const JamaAcademy = ({ style, description, clickables, navigate }) => {
  return (
    <View style={{ ...style }}>
      <View style={styles.body}>
        <View style={styles.mainContainer}>
          <Image
            source={require("../../../assets/home/eduHat.png")}
            style={styles.image}
          />
          <Text
            style={styles.description}
          >
            {description}
          </Text>
        </View>
        <View style={styles.clickableContainer}>
          {clickables.map(clickable => (
            <Text
              style={[styles.clickableText, { color: clickable.color }]}
              onPress={() => {
                navigate(clickable.navigate_to);
              }}
            >
              {clickable.title}
            </Text>
          ))}
        </View>
      </View>
    </View>
  );
};


const styles = StyleSheet.create({
    body: {
        marginHorizontal: 16,
        marginVertical: 18,
        padding: isMobile ? 10 : 16,
        borderWidth: 0.5,
        borderRadius: 8,
        borderColor: "#410DAA33"
    },
    mainContainer: { flexDirection: "row", alignItems: "center" },
    iamge: {
        width: 28,
        height: 28,
        marginRight: 8,
        resizeMode: "contain"
    },
    description: {
        fontSize: isMobile ? 12 : 14,
        flex: 1,
        fontWeight: "300",
        color: theme.textColors.LIGHT_GRAY
    },
    clickableContainer: { flexDirection: "row", marginTop: 20 },
    clickableText: {
        fontSize: 13,
        fontWeight: "bold",
        marginRight: 16
    }
});

export default JamaAcademy;