import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { getUserDetails, isMobile } from "../../utils";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import Modal from "../../components/Common/Modal";
import { ComplianceGroups } from "./data";
import { StackActions } from "@react-navigation/native";
import Store from "../../store";
import moment from "moment";
import { getCipsAboutToExpire } from "../../api";

export default class NoPlanModal extends Component {
  constructor(props) {
    super(props);

    const { bannerData, customer_id, is_mfcustomer, is_equitycustomer } = getUserDetails();
    let isCompliant = false;
    let user_id = null;
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    } else {
      user_id = customer_id;
    }
    if (bannerData.name == "Sensage" && user_id > 1 && user_id < 140) {
      isCompliant = true;
    }
    let show_club_upgrade = true;
    if (is_mfcustomer || is_equitycustomer) {
        show_club_upgrade = false;
    }
    this.state = {
      isLoading: this.props.isLoading,
      pendingItems: {},
      isCompliant,
      loaded: false,
      show_choose_your_plan: false,
      show_club_upgrade: show_club_upgrade
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.loaded == true) {
      return false;
    }
    return true;
  }

  async componentDidMount() {
    try {
        this.setState({
            loaded: true,
            isLoading: false,
        });
    } catch (err) {
        console.log(err);
        this.setState({ error: true, loaded: true, isLoading: false });
    }

  }

  moveToNextScreen = async () => {
    this.props.navigation.navigate("App", {
        screen: "Plans",
        params: {
          screen: "SubscriptionPlanHome",
          params: {
            show_club: this.state.show_club_upgrade,
            show_preferredcircle: true,
            show_elite: true,
            cip_pk_for_renewal: null
          },
        },
    });
  };

  goToDashboard = () => {
    if (this.props.onClose) {
      this.props.onClose();
    } else {
      const redirect_screen = "InvestmentOverview";
      this.props.navigation.dispatch(StackActions.replace(redirect_screen));
    }
  };

  render() {
    const { pendingItems, needCompletion, isLoading, isCompliant } = this.state;
    if (isLoading || needCompletion == false || isCompliant) return null;
    return (
      <Modal style={[styles.modalWeb]} containerStyle={styles.containerStyle}>
        <View style={[styles.mainContaner]}>
          <Text style={styles.comp}>
          You do not have any active advisory plans. Choose a plan to get unbiased investment advice to growth your wealth and meet life goals.
          </Text>
          <View style={{padding: 20}}></View>
          <TouchableOpacity
            style={{ alignItems: "center", justifyContent: "center" }}
            onPress={this.moveToNextScreen}
          >
            <Text style={styles.comp_now}>
              Select Plan
            </Text>
          </TouchableOpacity>
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  mainContaner: {
    paddingTop: isMobile ? 16 : 37,
    paddingBottom: isMobile ? 17 : 50,
    paddingHorizontal: isMobile ? 5 : 33,
    minWidth: isMobile ? 200 : 633,
    marginHorizontal: isMobile ? 16 : 0,
  },
  containerStyle: {
    marginHorizontal: isMobile ? 16 : 0,
    borderRadius: 5,
  },
  comp_now: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isMobile ? 13 : 15,
    lineHeight: 17,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 84,
    backgroundColor: "#410DAA",
    borderRadius: 4,
    marginTop: 30,
  },
  kyc: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 11 : 13,
    color: "#F64E60",
    paddingHorizontal: 5,
  },
  take: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 12 : 15,
    lineHeight: 17,
    color: "rgba(18, 18, 18, 0.6)",
    textAlign: "center",
    paddingTop: 10,
  },
  modalMobile: {
    position: "absolute",
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  modalWeb: {
    position: "absolute",
    // paddingTop: 150,
    justifyContent: "center",
    alignItems: "center",

  },
  wel: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 21,
    color: "#F64E60",
  },
  comp: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 21,
    color: "#121212",
    paddingTop: 12,
    // paddingBottom: 21,
  },
  item: {
    paddingHorizontal: 5,
    fontSize: isMobile ? 11 : 13,
  },
  completed: { color: "#009105" },
  padding: {
    paddingVertical: isMobile ? 5 : 0,
  },
});
