import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Platform, Linking } from "react-native";
import { getUserDetails, isAboveIpad, isMobile } from "../../../utils";
import DetailsCard from "../../mutualfunds/mindetails/detailsCard";
import Header from "../header_after_login";
import { ActivityIndicator, Button, RadioButton } from "react-native-paper";
import { getCustomerDetails } from "../../../api";
import { createKycRequest } from "../../../api/account";
import { PRODUCTION_APP_HOSTNAME } from "../../../api/urls";
import { SafeAreaView } from "react-native-safe-area-context";

const static_data = {
  NONCOMP: {
    description:
      "As per regulation, KYC compliance is required to get investment advice or make investments. This will take same time as getting a coffee.",
  },
  COMP: {
    description:
      "As per regulation, we need to fetch KYC documents, selfie and signature for proof. This will take same time as getting a coffee.",
  },
};

const KYCCreateFlow = (props) => {
  const [selected, setSelected] = useState(props.selectedOption || "JDF");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleVerifyDocuments = async () => {
    try {
      setLoading(true);
      const payload = {
        is_aadhaar_linked: selected == "JDF" ? "Y" : "N",
        domain_name: Platform.OS == "web" ? global.location.hostname : "mobile",
      };
      if (Platform.OS == "android" || Platform.OS == "ios") {
        payload["mobile"] = true;
        payload["redirection_domain"] = encodeURIComponent(
          "https://" + PRODUCTION_APP_HOSTNAME
        );
      }
      if (payload["domain_name"].includes("localhost")) {
        payload["domain_name"] = "localhost:19006";
      }
      const response = await createKycRequest(payload);
      if (response && response.code == 100) {
        const { results = {} } = response;
        const { kyc_redirect_url = null } = results;
        if (kyc_redirect_url) {
          if (Platform.OS == "web") {
            global.open(kyc_redirect_url, "_self");
          } else {
            Linking.openURL(kyc_redirect_url);
            props.navigation.navigate("App", {
              screen: "Dashboard",
              params: {},
            });
          }
          return;
        }
      }
      setLoading(false);
      setError("Error redirecting kyc page");
    } catch (err) {
      setLoading(false);
      setError("Error generating redirection link");
    }
  };

  return (
    <SafeAreaView style={flow.container}>
      <Text style={flow.heading}>
        Your KYC status:{" "}
        {props.kycStatus == "COMP"
          ? "Compliant. Congratulations !"
          : "Not Compliant"}
      </Text>
      <Text style={flow.headingDescription}>
        {props.kycStatus ? static_data[props.kycStatus]["description"] : null}
      </Text>
      <View style={flow.formContainer}>
        <Text style={flow.formHeading}>To proceed</Text>
        <View style={flow.formItem}>
          <RadioButton.Android
            value="first"
            status={selected === "JDF" ? "checked" : "unchecked"}
            onPress={() => setSelected("JDF")}
            color={selected === "JDF" ? "#410DAA" : "gray"}
          />
          <View style={flow.formItemLabelContainer}>
            <Text style={flow.formItemLabel}>
              Use OTP on mobile linked to Aadhar
            </Text>
            <Text style={flow.recommended}>[Easy and quick, Recommended]</Text>
          </View>
        </View>
        <View style={flow.formItem}>
          <RadioButton.Android
            value="first"
            status={selected === "JNDF" ? "checked" : "unchecked"}
            onPress={() => setSelected("JNDF")}
            color={selected === "JNDF" ? "#410DAA" : "gray"}
          />
          <View style={flow.formItemLabelContainer}>
            <Text style={flow.formItemLabel}>
              Aadhar is not linked with Mobile
            </Text>
          </View>
        </View>
        <Text style={flow.formError}>{error}</Text>
        <Button
          uppercase={false}
          mode={"contained"}
          onPress={handleVerifyDocuments}
          loading={loading}
          color={"#410DAA"}
          style={flow.verifyButton}
        >
          <Text style={flow.verify}>
            {props.kycStatus == "COMP" ? "Verify Documents" : "Start KYC"}
          </Text>
        </Button>
      </View>
    </SafeAreaView>
  );
};

const flow = StyleSheet.create({
  container: {
    paddingTop: 55,
  },
  heading: {
    fontSize: 18,
    color: "#222226",
    fontWeight: "bold",
  },
  headingDescription: {
    fontSize: 15,
    color: "#222226",
    marginTop: 20,
  },
  formContainer: {
    marginTop: 40,
  },
  formHeading: {
    fontSize: 15,
    color: "#222226",
    fontWeight: "bold",
  },
  formItem: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 15,
  },
  formItemLabelContainer: {
    marginLeft: 20,
    marginTop: 10,
  },
  recommended: {
    marginTop: 10,
    color: "green",
    marginTop: 10,
    fontSize: 12,
  },
  formError: {
    fontSize: 14,
    color: "red",
    marginTop: 10,
    marginLeft: 14,
  },
  verifyButton: {
    alignSelf: "flex-start",
    marginTop: 30,
    paddingHorizontal: 20,
  },
  verify: {
    fontSize: 14,
    color: "#fff",
  },
});

const data = {
  title: "KYC",
  description: "Required for Know-Your-Customer (KYC) check",
  image:
    "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3.png",
};

const DigioKYC = (props) => {
  const [kycStatus, setKytStatus] = useState(null);
  const [fetchingKYC, setFetchingKYC] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const fetchSelectedOption = () => {
      const { route } = props;
      const { params } = route;
      if (params) {
        const option = params.option || null;
        if (!option) return;
        setSelectedOption(option == "JDF" || option == "JNDF" ? option : null);
      }
    };

    const fetchKycStatus = async () => {
      try {
        const { customer_id, first_name } = getUserDetails();
        const response = await getCustomerDetails(customer_id);
        if (response && response.kyc_status) {
          const { kyc_status } = response;
          setKytStatus(
            kyc_status == "REG" ||
              kyc_status == "UND" ||
              kyc_status.startsWith("EK")
              ? "COMP"
              : "NONCOMP"
          ); // COMP = compliance NON = Non compliance
        }
        setFetchingKYC(false);
      } catch (error) {
        setFetchingKYC(false);
      }
    };

    fetchKycStatus();
    fetchSelectedOption();
  });

  return (
    <View style={styles.container}>
      <Header
        showbackBtn={false}
        navigation={props.navigation}
        route={props.route}
      />
      <View style={styles.body}>
        {isAboveIpad && (
          <View style={styles.left}>
            <DetailsCard data={data} {...props} />
          </View>
        )}
        <View style={styles.right}>
          {fetchingKYC ? (
            <View style={styles.loaderContainer}>
              <ActivityIndicator size="large" color="#410DAA" />
              <Text style={styles.fetching}>Fetching KYC status</Text>
            </View>
          ) : (
            <KYCCreateFlow
              kycStatus={kycStatus}
              selectedOption={selectedOption}
              navigation={props.navigation}
            />
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  body: {
    flexDirection: "row",
    flex: 1,
    paddingLeft: !isMobile ? "5%" : 13,
    paddingRight: !isMobile ? "5%" : 13,
  },
  left: {
    flex: 0.5,
    paddingTop: 55,
  },
  right: {
    flex: !isAboveIpad ? 1 : 0.5,
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    flex: 1,
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    left: 0,
  },
});

export default DigioKYC;
