import React, { Component } from "react";
import { View, SafeAreaView, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { Button, Text } from "react-native-paper";
import TextInputField from "../../../components/Common/Inputs/InputField";
import theme from "../../../static/theme";
import {
  isMobile,
} from "../../../utils";

class AssetsAndLiabilities extends Component {
  constructor(props) {
    super(props);
    this.state = {loan: "", gold: "", equity: "", income: "", realestate: ""};
  }

  render() {
    const { loan, gold, equity, income, realestate } = this.state;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Assets And Liabilities"]}
        />
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            paddingBottom: 100,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          {!isMobile ? (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <TextInputField
                  prefix="₹"
                  label="Equities, Equity MFs, NPS Equity, eSOP Value*"
                  value={equity}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        equity: text,
                      });
                    } else {
                      this.setState({ equity: text.slice(0, -1) });
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                />
              </View>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <TextInputField
                  prefix="₹"
                  label="Fixed Income/Debt Investments/Bonds/EPF/PPF/FDs/Cash*"
                  value={income}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        income: text,
                      });
                    } else {
                      this.setState({ income: text.slice(0, -1) });
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                />
              </View>
            </View>
          ) : (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  prefix="₹"
                  label="Equities, Equity MFs, NPS Equity, eSOP Value*"
                  value={equity}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        equity: text,
                      });
                    } else {
                      this.setState({ equity: text.slice(0, -1) });
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                />
              </View>

              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  prefix="₹"
                  label="Fixed Income/Debt Investments/Bonds/EPF/PPF/FDs/Cash*"
                  value={income}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        income: text,
                      });
                    } else {
                      this.setState({ income: text.slice(0, -1) });
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                />
              </View>
            </>
          )}

          {!isMobile ? (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <TextInputField
                  prefix="₹"
                  label="Real Estate, Flats, Land, Plots(excl Primary residence)*"
                  value={realestate}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        realestate: text,
                      });
                    } else {
                      this.setState({ realestate: text.slice(0, -1) });
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                />
              </View>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <TextInputField
                  prefix="₹"
                  label="Gold Bonds/ Gold ETFs/ Gold MF(excl jewelery)*"
                  value={gold}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        gold: text,
                      });
                    } else {
                      this.setState({ gold: text.slice(0, -1) });
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                />
              </View>
            </View>
          ) : (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  prefix="₹"
                  label="Real Estate, Flats, Land, Plots(excl Primary residence)*"
                  value={realestate}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        realestate: text,
                      });
                    } else {
                      this.setState({ realestate: text.slice(0, -1) });
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                />
              </View>

              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  prefix="₹"
                  label="Gold Bonds/ Gold ETFs/ Gold MF(excl jewelery)*"
                  value={gold}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        gold: text,
                      });
                    } else {
                      this.setState({ gold: text.slice(0, -1) });
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                />
              </View>
            </>
          )}

          {!isMobile ? (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <TextInputField
                  prefix="₹"
                  label="Total Outstanding Loans*"
                  value={loan}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        loan: text,
                      });
                    } else {
                      this.setState({ loan: text.slice(0, -1) });
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                />
              </View>
            </View>
          ) : (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  prefix="₹"
                  label="Total Outstanding Loans*"
                  value={loan}
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        loan: text,
                      });
                    } else {
                      this.setState({ loan: text.slice(0, -1) });
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                />
              </View>
            </>
          )}
          {!isMobile ? (
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={() => {}}
              style={{
                marginTop: 50,
                width: "20%",
                left: "40%",
                right: "40%",
              }}
              loading={this.state.isLoading}
              color={"#410DAA"}
            >
              <Text style={styles.next}>Save</Text>
            </Button>
          ) : (
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={() => {}}
              style={{
                marginTop: 30,
                width: "40%",
                marginLeft: "30%",
              }}
              loading={this.state.isLoading}
              color={"#410DAA"}
            >
              <Text style={styles.next}>Save</Text>
            </Button>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.MAIN_BACKGROUND,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export default connect(null)(AssetsAndLiabilities);
