import React from 'react';
import { View, Text, StyleSheet, FlatList, Image } from 'react-native';
import { ActivityIndicator, Button } from 'react-native-paper';
import { useSelector } from 'react-redux';
import { getStocksPortfolioProducts } from '../../../api/invest';

const ExploreStocksPortfolio = ({ onPressItem, isStocks }) => {
    const [ data, setData ] = React.useState([]);
    const { demoMode } = useSelector(state=>state.testConfig);
    const [ loading, setLoading ] = React.useState(false);
    React.useEffect(() => {
        loadProducts()
    },[isStocks]);

    const loadProducts = async () => {
        try {
            setLoading(true);
            const response = await getStocksPortfolioProducts();
            if(response && response.length ){
                let filteredData = response.filter(item=> isStocks ? item.asset_type=="STK" : item.asset_type != "STK");
                if(demoMode){
                    filteredData = filteredData.filter(item=> ["JWL", "SRK"].indexOf(item.product_code)==-1 );
                }
                filteredData.sort((a, b) => a.sort_order - b.sort_order);
                setData([...filteredData]);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleOnclick = (product_code, product_name, pk) => {
        onPressItem(product_code, product_name, pk);
    }

  // "product_name": "SPARK",
  // "asset_type": "STK",
  // "is_active": true,
  // "created": "2022-07-26T12:30:05.869557",
  // "modified": "2022-07-26T15:51:16.425100",
  // "product_code": "SRK",
  // "headline": "Smallcap Growth Strategy | Top 650",
  // "subheadline": "High Return On Equity | High Return On Assets | High Growth | Low Debt | Market Share | Management",
  // "productvsbenchmark": "1y,4% | 3y,15%, 5y|20% - 1y,3% | 3y,11%, 5y|14%",

    const handleRenderItem = ({item, index}) => {
        return(
            <View style={{ flexGrow: 1, maxWidth: 300, borderWidth: 0.5, borderColor: '#410DAA60', padding: 15, marginRight: 16 }}>
                <View style={{ flexDirection: "row", alignItems: "center"}}>
                    {/* <Image style={{ width: 35, height: 35, resizeMode:"contain", marginRight: 10 }} source={{uri: item.image }}/> */}
                    <View style={{}}>
                        <Text style={{ fontSize: 15, fontWeight: "bold" }}>{item.product_name}</Text>
                        <Text style={{ fontSize: 12, marginTop: 10 }}>{item.headline}</Text>
                    </View>
                </View>
                <Text style={{ fontSize:13, fontStyle: "italic", color:"#410DAA", marginTop: 10, marginBottom: 6 }}>{item.subheadline}</Text>
                <Button
                    uppercase={false}
                    mode={"contained"}
                    onPress={()=> handleOnclick(item.product_code, item.product_name, item.advisorproductmaster_id) }
                    style={{
                        width: 120,
                        height: 35,
                        borderRadius: 20,
                        backgroundColor: "#410DAA1A",
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                        marginTop: 20
                    }}
                    color={"#410DAA"}
                >
                    <Text
                        style={{
                            fontSize: 12,
                            fontWeight: "500",
                            color: "#410DAA",
                        }}
                    >
                        Invest Now
                    </Text>
                </Button>
            </View>
        )
    }

    return(
        <View style={styles.container}>
            {
                loading ? (
                    <View style={styles.loadingContainer}>
                        <ActivityIndicator color="#410DAA" size={"large"}/>
                    </View>
                ): (
                    <FlatList
                        horizontal={true}
                        data={data}
                        renderItem={handleRenderItem}
                        keyExtractor={(item, index) => index.toString()}
                    />
                )
            }
        </View>
    )
};


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    }
});


export default ExploreStocksPortfolio;