import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  SafeAreaView,
} from "react-native";
import { md } from "../../../Responsive";
import { getAssetsAndLiabilities } from "../../../api";
import Header from "../header_after_login";
const { width } = Dimensions.get("window");
import Progressbar from "../progressbar";
import { completeMinDetails, isMobile, isWeb } from "../../../utils";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { ShowToast } from "../../../components/Common/Toast";
import Store from "../../../store";
// import { SafeAreaView } from "react-native-safe-area-context";

export default class assets_and_liabilities extends React.Component {
  constructor(props) {
    super(props);

    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
    }

    this.state = {
      user_id,
      data: null,
      equity: null,
      fixed_income: null,
      flats: null,
      gold_bonds: null,
      total_outstanding: null,
      total_outstanding_error: null,
      equityError: null,
      fixed_incomeError: null,
      flatsError: null,
      gold_bondsError: null,
      isLoadingData: true
    };
  }

  componentDidMount = async () => {
    try {
      this.setState({ isLoadingData: false });
    } catch (e) {
      console.log(e)
      this.setState({ isLoadingData: false });
    }
  }

  handleContinue = async () => {
    try {
      if (this.state.user_id) {
        let options = {};
        const { params } = this.props.route;
        if (params) {
          options = { ...params };
        }
        options["first"] = false;
        this.props.navigation.navigate("eSign", { ...options });
        return;
      }

      let { equity, fixed_income, flats, gold_bonds, total_outstanding } =
        this.state;
      if (equity == null) {
        this.setState({
          equityError: "Please enter this field",
        });
      }
      if (fixed_income == null) {
        this.setState({
          fixed_incomeError: "Please enter this field",
        });
      }
      if (flats == null) {
        this.setState({
          flatsError: "Please enter this field",
        });
      }
      if (gold_bonds == null) {
        this.setState({
          gold_bondsError: "Please enter this field",
        });
      }
      if (total_outstanding == null) {
        this.setState({
          total_outstanding_error: "Please enter this field",
        });
        return;
      }

      const payload = {
        equity_assets: equity,
        real_estate_assets: flats,
        other_assets: fixed_income,
        total_loan_amount: total_outstanding,
        gold_assets: gold_bonds,
      };
      this.setState({ isLoading: true });
      const response = await getAssetsAndLiabilities(payload);

      if (response && response.code == 100) {
        const minDetailsResponse = await completeMinDetails();
        const { needCompletion, error } = minDetailsResponse;
        if (!error) {
          const { redirect_screen } = minDetailsResponse;
          let options = {};
          if (this.props.route) {
            const { params } = this.props.route;
            if (params) {
              options = { ...params };
            }
            if (!needCompletion) {
              if (options.action) {
                this.props.navigation.navigate("ComplianceSuccess", {
                  ...options,
                });
                return;
              } else {
                this.props.navigation.navigate("OnboardingSuccess", {
                  ...options,
                });
                return;
              }
            }
          }
          this.props.navigation.navigate(redirect_screen, { ...options });
          this.setState({ isLoading: false });
        } else {
          this.setState({
            error: true,
            errorText: "Something went wrong! Please contact help@maxiom.in",
          });
        }
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: response && response.msg ? response.msg : null,
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  render() {
    const {
      equity,
      fixed_income,
      flats,
      gold_bonds,
      equityError,
      fixed_incomeError,
      flatsError,
      gold_bondsError,
      total_outstanding,
      total_outstanding_error,
      isLoading,
    } = this.state;
    return (
      <SafeAreaView style={styles.container}>
        <Header navigation={this.props.navigation} route={this.props.route} />
        <Progressbar
          color2="#410DAA"
          color3="#410daa"
          color4="#410DAA"
          color5="#410DAA"
          color6="#410DAA"
          color7="#f6f6f6"
          color8="#f6f6f6"
          color9="#f6f6f6"
          color10="#f6f6f6"
          color11="#f6f6f6"
          color12="#f6f6f6"
          bcolor1="#410DAA"
          bcolor2="#410DAA"
          bcolor3="#410DAA"
          bcolor4="#f6f6f6"
          bcolor5="#f6f6f6"
          bagcolor1="#410DAA"
          bagcolor2="#410DAA"
          bagcolor3="#410DAA"
          bagcolor4="#ffffff"
          bagcolor5="#ffffff"
          markdisplay1="none"
          markdisplay2="none"
          markdisplay3="none"
          markdisplay4="none"
          markdisplay5="none"
          dotdisplay1="none"
          dotdisplay2="none"
          dotdisplay3="none"
          dotdisplay4="none"
          dotdisplay5="none"
        />
        {this.state.isLoadingData ? (
          <View style={styles.loaderContainer}>
            <ActivityIndicator
              size="large"
              color="#410DAA"
              style={{ paddingTop: 40 }}
            />
          </View>
        ) : (
        <ScrollView style={styles.mainContainer}>
          <View style={styles.body}>
            {isWeb && (
              <View style={styles.left}>
                <Text style={styles.terms}>Your Assets and Liabilities</Text>
                <Text style={styles.please}>
                  Required per regulation to assure suitability of advice.conF
                </Text>
                <Image
                  source={
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-2.png"
                  }
                  style={styles.left_img}
                />
                <View
                  style={{
                    flexDirection: "row",
                    width: 430,
                    borderWidth: 1,
                    borderColor: "rgba(65, 13, 170, 0.2)",
                    backgroundColor: "#ffffff",
                    paddingVertical: 21,
                    paddingLeft: 20,
                    paddingRight: 6,
                    marginTop: 33,
                    marginBottom: 22,
                  }}
                >
                  <Image
                    source={
                      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/fluent_lock-shield-20-regular.png"
                    }
                    style={styles.lock}
                  />
                  <Text style={styles.jam_we}>
                    Information is encrypted using 256-bit keys and stored
                    securely
                  </Text>
                </View>
                <Text style={styles.any}>
                  Any Queries?{" "}
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("App", {
                        screen: "Dashboard",
                        params: {
                          screen: "Dashboard",
                          params: {
                            screen: "GetHelp",
                            params: {},
                          },
                        },
                      });
                    }}
                  >
                    <Text style={{ color: "#410DAA", fontWeight: "bold" }}>
                      Get Help
                    </Text>
                  </TouchableOpacity>
                </Text>
              </View>
            )}
            <View style={styles.right}>
              <View style={{ display: md(width) ? "none" : "flex" }}>
                <Text style={[styles.terms]}>Your Assets and Liabilities</Text>
                <Text style={styles.please}>
                  Required per regulation to assure suitability of advice
                </Text>
              </View>

              <Text
                style={[styles.equities, { paddingTop: md(width) ? 0 : 0 }]}
              >
                Equities, Equity MFs, NPS Equity, ESOP Value*{" "}
              </Text>
              <View>
                <FontAwesome
                  name="rupee"
                  size={14}
                  color="black"
                  style={styles.rupeeSymbol}
                />
                <TextInput
                  ref={(input) => {
                    this.equityInput = input;
                  }}
                  placeholder={" 0"}
                  style={styles.inptxt}
                  value={equity}
                  onChangeText={(text) => {
                    text = text.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, "");
                    let equityError = null;
                    if (isNaN(text)) {
                      equityError = "Please enter valid value";
                    }
                    this.setState({ equity: text, equityError });
                  }}
                  keyboardType={"number-pad"}
                />
              </View>
              <Text style={styles.error}>{equityError || " "}</Text>

              <Text style={[styles.equities]}>
                Fixed Income / Debt Investments / Bonds / EPF / PPF / FDs /
                Cash*{" "}
              </Text>
              <View>
                <FontAwesome
                  name="rupee"
                  size={14}
                  color="black"
                  style={styles.rupeeSymbol}
                />
                <TextInput
                  placeholder={" 0"}
                  style={styles.inptxt}
                  value={fixed_income}
                  onChangeText={(text) => {
                    text = text.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, "");
                    let fixed_incomeError = null;
                    if (isNaN(text)) {
                      fixed_incomeError = "Please enter valid value";
                    }
                    this.setState({ fixed_income: text, fixed_incomeError });
                  }}
                  keyboardType={"number-pad"}
                />
              </View>
              <Text style={styles.error}>{fixed_incomeError || " "}</Text>

              <Text style={styles.equities}>
                Real Estate, Flats, Land, Plots (excl primary residence)*
              </Text>
              <View>
                <FontAwesome
                  name="rupee"
                  size={14}
                  color="black"
                  style={styles.rupeeSymbol}
                />
                <TextInput
                  placeholder={" 0"}
                  style={styles.inptxt}
                  value={flats}
                  onChangeText={(text) => {
                    text = text.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, "");
                    let flatsError = null;
                    if (isNaN(text)) {
                      flatsError = "Please enter valid value";
                    }
                    this.setState({ flats: text, flatsError });
                  }}
                  keyboardType={"number-pad"}
                />
              </View>
              <Text style={styles.error}>{flatsError || " "}</Text>

              <Text style={styles.equities}>
                Gold Bonds / Gold ETFs / Gold MF(excl jewelery)*
              </Text>
              <View>
                <FontAwesome
                  name="rupee"
                  size={14}
                  color="black"
                  style={styles.rupeeSymbol}
                />
                <TextInput
                  placeholder={" 0"}
                  style={styles.inptxt}
                  value={gold_bonds}
                  onChangeText={(text) => {
                    text = text.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, "");
                    let gold_bondsError = null;
                    if (isNaN(text)) {
                      gold_bondsError = "Please enter valid value";
                    }
                    this.setState({ gold_bonds: text, gold_bondsError });
                  }}
                  keyboardType={"number-pad"}
                />
              </View>
              <Text style={styles.error}>{gold_bondsError || " "}</Text>

              <Text style={styles.equities}>Total Outstanding Loans*</Text>
              <View>
                <FontAwesome
                  name="rupee"
                  size={14}
                  color="black"
                  style={styles.rupeeSymbol}
                />
                <TextInput
                  placeholder={" 0"}
                  style={styles.inptxt1}
                  value={total_outstanding}
                  onChangeText={(text) => {
                    text = text.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, "");
                    let total_outstanding_error = null;
                    if (isNaN(text)) {
                      total_outstanding_error = "Please enter valid value";
                    }
                    this.setState({
                      total_outstanding: text,
                      total_outstanding_error,
                    });
                  }}
                  keyboardType={"number-pad"}
                />
              </View>
              <Text style={styles.error}>{total_outstanding_error || " "}</Text>
              {Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id ? (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.handleContinue();
                    }}
                  >
                    <Text style={styles.viewModeText}>Next {">>>"}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      let url = new URL(global.location.href);
                      location.replace(
                        url.origin +
                          "/dashboard/home?user_id=" +
                          this.state.user_id
                      );
                    }}
                  >
                    <Text style={styles.viewModeText}>
                      Go to Dashboard {">>>"}
                    </Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    !isLoading && this.handleContinue();
                  }}
                  style={[styles.buttonContainer]}
                >
                  {isLoading ? (
                    <ActivityIndicator
                      size="small"
                      color="#FFF"
                      style={{ alignSelf: "center" }}
                    />
                  ) : (
                    <Text style={styles.next}>Continue</Text>
                  )}
                </TouchableOpacity>
              )}
            </View>
          </View>
        </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1, backgroundColor: "#fff" },
  loaderContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  mainContainer: {},
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: md(width) ? 130 : 17,
    paddingRight: md(width) ? 150 : 16,
    paddingBottom: isMobile ? 120 : 60,
  },
  left: {
    flex: 0.5,
    paddingTop: 55,
    // display: md(width) ? "none" : "none",
  },
  right: {
    flex: md(width) ? 0.5 : 1,
    paddingTop: md(width) ? 51 : 28,
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },
  lock: {
    resizeMode: "contain",
    width: 30,
    height: 30,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 18 : 16,
    lineHeight: md(width) ? 21 : 17,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 15 : 13,
    lineHeight: 17,
    color: "#464E5F",
    paddingTop: 12,
    paddingBottom: 32,
    paddingRight: md(width) ? 170 : 0,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  equities: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 16 : 13,
    lineHeight: md(width) ? 18 : 16,
    paddingBottom: md(width) ? 6.29 : 10.54,
    color: "#464E5F",
    paddingTop: md(width) ? 40 : 20,
  },
  inptxt: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 15 : 14,
    lineHeight: 16,
    color: "#121212",
    paddingVertical: isMobile ? 6 : 13.3,
    paddingRight: 19,
    paddingLeft: 30,
    backgroundColor: "#f6f6f6",
    borderRadius: 4,
    opacity: 0.8,
  },
  inptxt1: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 15 : 14,
    lineHeight: 16,
    color: "#121212",
    paddingVertical: isMobile ? 6 : 13.3,
    paddingRight: 19,
    paddingLeft: 30,
    backgroundColor: "#f6f6f6",
    borderRadius: 4,
  },
  buttonContainer: {
    paddingVertical: 12,
    backgroundColor: "#410DAA",
    borderRadius: 4,
    marginTop: 51.42,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    width: isMobile ? "50%" : 220,
  },
  next: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    color: "#ffffff",
    fontSize: 15,
    lineHeight: 17,
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 20,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  rupeeSymbol: {
    color: "#121212",
    position: "absolute",
    left: 15,
    zIndex: 10,
    bottom: isMobile ? 12 : 14,
  },
});
