import React, {useRef, useState} from 'react';
import { StyleSheet, Text, View, Button, FlatList, ScrollView, Image, Platform, Dimensions, ActivityIndicator, TouchableOpacity } from 'react-native';
import {xs,sm, md, lg, xl, twoxl} from '../../../Responsive'
import Modal from '../../Common/Modal';
import NavigationHeader from '../../Common/NavigationHeader';
import amplitude from '../../../utils/amplitude';

import { capitalizeFirstLetter, formatDate, isMobile, numberWithCommas, reverseString } from '../../../utils';
import { AntDesign } from '@expo/vector-icons';
import { getRebalanceStatus, getRebalanceTransactionStatus, getTransactionPopUp } from '../../../api';
import { MAIN_URL, URLS } from '../../../api/urls';
import { PopUp } from '../../Common/PopUp';
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from '../../../api/apiConfig';

const window = Dimensions.get("window");
const {width} = window;


const TableHeader = ({value,containerStyle={}, style={}}) => {
    return(
        <View style={containerStyle}>
            <Text 
                style={[
                    {
                        fontSize:12, color:"#B5B5C3", paddingHorizontal: 0, fontWeight:"bold",textAlign:"center"
                    },
                    style
                ]}>
                    {value}
            </Text>
        </View>
    )
}

const TableCell = ({value=null,style={},children}) => {
    return(
        <View style={style}>

           {value!=null && <Text 
                style={[
                    {
                        fontSize:14, color:"#464E5F", paddingHorizontal: 16, textAlign:"center"
                    },
                    style
                ]}>
                    {value}
            </Text>}
            {
                children
            }
        </View>
    )
}


export default class TransactionStatus extends React.Component{
    constructor(props){
        super(props);
        this.state={
            data: null,
            tabIndex:0
        }
    }

    async componentDidMount(){
        try {
            if (Platform.OS == "web"){
                amplitude.logEvent("DownloadApp",{ path: global.location.href })
            }
            else{
                amplitude.logEvent("DownloadApp", { path: this.props.navigation.getState().routeName });
            }
            const responses = await Promise.all([getTransactionPopUp(),getRebalanceTransactionStatus()]);

            let transactionStatus,  actionItemID,showPopUp=false, modalData=null;

            const transactionPopUpResponse = responses[0];
            const transactionStatusResponse = responses[1];
            if(transactionPopUpResponse&&transactionPopUpResponse.code==100){
                if(transactionPopUpResponse.result.Show_pop_up){
                    let { cai_pk} = transactionPopUpResponse.result;
                    transactionStatus = true;
                    actionItemID = cai_pk;
                    showPopUp=true;
                }
            }



            if(transactionStatusResponse&&transactionStatusResponse.code==100){
                let {result} = transactionStatusResponse;
                const {modal_data: modalData } = result;
                if(result.orders_placed){
                    this.setState({
                        data: result,
                        transactionStatus,
                        actionItemID,
                        showPopUp,
                        modalData
                    },()=>{
                        this.getFilterData(this.state.tabIndex, true);
                    });
                }else{
                    this.setState({
                        error: true,
                        errorText: "No transaction Found"
                    })
                }

            }else{
                this.setState({
                    error: true,
                    errorText:  transactionStatusResponse &&  transactionStatusResponse.msg ? transactionStatusResponse.msg : "Something went wrong!"
                })
            }
        } catch (error) {
            this.setState({
                error: true,
                errorText:  "Something went wrong!"
            })
        }
    }

    getFilterData = (index, isFirstTimeLoad) => {
        let tabIndex = index;
        const isWeb = md(width);
        let filteredData=[];
        const {data} = this.state;
        if(isWeb){
            filteredData = [...data.stocks]
        }else{
            let filterBy = index == 0 ? "S" :"B";
            filteredData = data.stocks.filter((item,index)=>{
                return item["txn_type"] == filterBy;
            });
            if(isFirstTimeLoad){
                if(filteredData.length==0){
                    if(filterBy=="S") filterBy = "B";
                    filteredData = data.stocks.filter((item,index)=>{
                        return item["txn_type"] == filterBy;
                    });
                    if(filteredData.length!=0){
                        tabIndex = 1;
                    }
                }
            }
        }

        this.setState({
            filteredData,
            tabIndex
        })
        
    }


    confirmBuy = () => {
        this.setState({showAlert:true});
    }

    closePopUp = () => {
        this.setState({showPopUp:false});
    }

    renderTableHeader = () => {
        const isWeb = md(width);
        return(
            <View style={[
                {
                    // width: isWeb?"100%":width,
                    flexDirection:"row",
                    paddingVertical:11,
                    backgroundColor:"#F3F6F9",
                    borderRadius: isWeb ?6:0,
                    paddingLeft: isWeb ? 10: 5
                }
            ]}
            >
                <TableHeader value={ isWeb ? "Stock Name":"Stock Details"} containerStyle={{flex: isWeb? 0.25:0.5}} style={[{color:"#464E5F",textAlign:"left"}]}/>
                {isWeb&&(
                    <>
                        <TableHeader value={"Transaction Date"} containerStyle={{flex:0.15}}/>
                        <TableHeader value={"Transaction Type"} containerStyle={{flex:0.15}}/>
                        <TableHeader value={"Suggested Qty"} containerStyle={{flex:0.15}}/>
                    </>
                )}
                <TableHeader value={"Traded Qty"} containerStyle={{flex:isWeb? 0.1:0.3}} style={[{paddingHorizontal:isWeb?16:2}]}/>
                <TableHeader value={isWeb?"Pending Qty":"Pending"} containerStyle={{flex:isWeb? 0.1:0.2}} style={[{paddingHorizontal:isWeb?16:2}]}/>
                {isWeb&&<TableHeader value={"Status"} containerStyle={{flex: 0.1}}/>}
            </View>  
        )
    }

    renderSuggestionItem = ({item,index}) => {
        const isWeb = md(width);
        const {txn_type,status} = item;
        const isBuy = txn_type != "S";
        const transactionTypeText = isBuy ? "Buy":"Sell";
        const statusColor = status == "Fulfilled" ? "#009105":"#DA222D";
        const statusText = status == "Fulfilled" ? "COMPLETE": status=="Partial"?"PARTIAL":status;
        const txnDate = formatDate(item.txn_date);
        return(
                <View style={[
                    {
                        width: isWeb?"100%":width,
                        flexDirection:"row",
                        paddingVertical:15,  
                        paddingLeft: isWeb ? 10: 5
                    }
                ]}
                >
                    <TableCell  style={[{flex: isWeb? 0.25:0.5,flexWrap:"wrap"}]}>
                        <Text style={{fontSize:14,color:"#464E5F",fontWeight:"bold"}}>{item.name}</Text>
                        <Text style={{color:"#9FA2B4",fontSize:12,marginTop:4}}>{item.isin}</Text>
                    </TableCell>
                    {isWeb&&(
                        <>
                            <TableCell value={txnDate} style={[{flex:0.15}]}/>
                            <TableCell style={[{flex:0.15}]}>
                                <View style={{backgroundColor:isBuy?"#E8F9F8":"#FFE2E5",borderRadius:6,alignSelf:"center",paddingVertical:7,paddingHorizontal:15}}>
                                    <Text style={{color:isBuy?"#1BC5BD":"#F64E60",fontSize:12,fontWeight:"bold"}}>{transactionTypeText}</Text>
                                </View>
                            </TableCell>
                            <TableCell value={item.suggested_qty} style={[{flex:0.15}]}/>
                        </>
                    )}
                    <TableCell value={item.traded_qty} style={[{flex: isWeb? 0.1:0.3, fontWeight:isWeb?"normal":"bold"}]}/>
                    <TableCell value={item.pending_qty} style={[{flex: isWeb? 0.1:0.2, fontWeight:isWeb?"normal":"bold"}]}/>
                    {isWeb && <TableCell value={statusText} style={[{flex: 0.1, color:statusColor}]}/>}
                </View>  
        )
    }

    viewPlanwiseSellRecommendations = () => {

    }

    viewDetails = () => {
        this.setState({viewDetailsOpen: !this.state.viewDetailsOpen})
    }

    handleRetry = () => {
        const {cai_hash} = this.state.data;
        let token = getTokenFromStore();
        token = reverseString(token);
        global.open(MAIN_URL+"/app/gwsc/retryorder/"+(cai_hash+token)+"/"+GATEWAY_ENVIRONMENT,"_self");
    }


    render(){
        const { data, showAlert, showPopUp, error, errorText, tabIndex, modalData } = this.state;

        const isWeb = md(width);

        if(error){
            return(
                <View style={{flex:1,flexDirection:"row",justifyContent:"center",backgroundColor:"#fff"}}>
                    <View style={{ flex:1, maxWidth:1500 }}>
                        <NavigationHeader navigation={this.props.navigation} navigationHeader={["Equity Plan","Rebalance","Transaction Status"]} onPressBack={()=>{this.props.navigation.navigate("InvestmentOverview")}}/> 
                        <View style={{flex:1,justifyContent:"center",alignItems:"center"}}>
                            <Text style={{fontSize:16,color:"#121212"}}>{errorText||"Something went wrong!"}</Text>
                        </View>
                    </View>
                </View>
            )
        }

        const ArrowBackIcon = require('../../../assets/JamaInvest/web_transaction_status.png');


        return (
            <View style={{flex:1,alignItems:"center",backgroundColor:"#fff"}}>
        
                <NavigationHeader navigation={this.props.navigation} navigationHeader={["Transaction Status"]} onPressBack={()=>{this.props.navigation.navigate("InvestmentOverview")}} style={{ width:"100%",maxWidth:1500}}/>
                {
                    (showAlert) && (
                        <Modal style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
                            <View>
                                <Text style={{fontSize:17,fontWeight:"700",color:"#464E5F",marginBottom:14}}>Sell Orders</Text>
                                <Text style={{fontSize:14,fontWeight:"400",color:"#464E5F",marginBottom:35}}>Did you place your sell orders successfully?</Text>

                                <View style={styles.confirmButtonContainer}>
                                    <TouchableOpacity onPress={()=>{this.setState({showAlert:false,showSuccessAlert:false})}} underlayColor="white" style={{flex:0.5}}>
                                                <View style={[styles.confirmButton,styles.confirmButtonNo]}>
                                                    <Text style={styles.noText}>No</Text>
                                                </View>
                                    </TouchableOpacity>

                                    <TouchableOpacity onPress={()=>{this.setState({showAlert:false,showSuccessAlert:true})}} underlayColor="white" style={{flex:0.5}}>
                                                <View style={[styles.confirmButton,styles.confirmButtonYes]}>
                                                    <Text style={styles.yesText}>Yes</Text>
                                                </View>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </Modal>
                    )
                }

                {
                    showPopUp && modalData && (
                        <PopUp modal_data={modalData} onPressDone={this.closePopUp} onRetry={this.handleRetry} noButtonTitle="OK"/>
                    )
                }

                {
                    data ?
                <ScrollView style={[styles.mainContainer]}>
                    <View  style={[
                            {paddingHorizontal:isMobile?0:16,paddingVertical:33,borderRadius:isMobile?0:12},
                            Platform.OS=="android"?{
                                elevation: isMobile?0:1
                            }:Platform.OS=="ios"?{
                                shadowColor: isMobile?"transparent":"#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 1,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 2.22,
                            }:Platform.OS=="web"?{
                                boxSizing:"border-box",
                                border: isMobile ? "0px":"1px solid rgba(65, 13, 170, 0.1)",
                                // borderWidth:isMobile?0:1,
                                //boxShadow: isMobile? "0px 0px 0px": "0px 3px 10px rgba(0, 0, 0, 0.1)"
                            }:{}
                        ]}>
                        <View style={{marginBottom:31, paddingHorizontal:isWeb?0:16}}>
                            <Text style={styles.portfolioValueText}>Your Transaction: <Text style={styles.portfolioValueAmount}>₹ {numberWithCommas(Math.abs(data.total_transaction_amount))}</Text></Text>
                            <Text style={{fontSize: isWeb?12:11,color:"#B5B5C3",marginTop:5}}>Transactions made on {formatDate(data.transaction_date)}</Text>
                        </View>

                              {/* <View style={styles.border}> */}
                                {
                                    data.pending_transactions !=0 && (
                                        <View style={{flex:1,flexDirection: md(window.width) ?"row":"column",justifyContent:"space-between",alignItems:"center",backgroundColor:"#8950FC10",borderRadius:12,paddingHorizontal:12,marginBottom:40}}>
                                    
                                        <View style={styles.tableSummary}>
                                            <Image source={ArrowBackIcon} style={{width:isWeb?43:31,height:isWeb?37:27,marginRight:8}}></Image>
                                            {
                                                isWeb ? (
                                                        <View>
                                                            <Text style={styles.completeTitle}>Complete your pending Transactions</Text>
                                                            <Text style={styles.pendingStocksList}>
                                                                {data.pending_transactions} Stocks
                                                            </Text>
                                                        </View>
                                                    ):(
                                                        <View style={{flex:1,alignSelf:"flex-start"}}>
                                                            <View style={{flexWrap:"wrap"}}>
                                                            <Text style={{color:"#464E5F",fontSize:13,width:"85%"}}>Complete Your Pending Transactions as on {data.transaction_date}</Text> 
                                                            </View>
                                                            <TouchableOpacity onPress={()=>{}}>
                                                                <Text style={{fontSize:13,color:"#410DAA",fontWeight:"bold",marginTop:20}}>{`Retry ->`}</Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                    )
                                                }
                                        </View>
                                        {
                                            isWeb&& (
                                                <View style={styles.retryOrdersButtonContainer}>
                                                    <TouchableOpacity onPress={this.handleRetry} underlayColor="white" style={{marginRight:40}}>
                                                        <View style={styles.retryButton}>
                                                            <Text style={styles.retryButtonText}>Retry</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </View>
                                            )
                                        }
                                    </View>
                                    )
                                }


                                {
                                    !isWeb && (
                                        <>
                                        <View style={{flexDirection:"row",borderBottomColor:"#E5EAEE",borderBottomWidth:1,marginBottom:10}}>
                                            <TouchableOpacity onPress={()=>{
                                               if(this.state.tabIndex!=0){
                                                   this.setState({tabIndex:0},()=>{
                                                       this.getFilterData(0)
                                                   });
                                               }
                                            }}>
                                                <Text style={{color:tabIndex==0?"#121212":"#888C9F",fontSize:14,paddingHorizontal:30,paddingVertical:12,borderBottomWidth:3,borderBottomColor:tabIndex==0?"#121212":"transparent"}}>
                                                    Sell
                                                </Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={()=>{
                                                 if(this.state.tabIndex!=1){
                                                    this.setState({tabIndex:1},()=>{
                                                        this.getFilterData(1)
                                                    });
                                                 }
                                            }}>
                                                <Text style={{color:tabIndex==1?"#121212":"#888C9F",fontSize:14,paddingHorizontal:30,borderBottomWidth:3,borderBottomColor:tabIndex==1 ?"#121212":"transparent",paddingVertical:12}}>
                                                    Buy
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{flexDirection:"row",justifyContent:"space-between",paddingHorizontal:20,marginVertical:20}}>
                                            <View style={{flexDirection:"row"}}>
                                                <Text style={{fontSize:15,color:"#212121"}}>Action</Text>
                                                <View style={{backgroundColor:"#E8F9F8",paddingHorizontal:18,paddingVertical:6,marginLeft:10,borderRadius:12}}>
                                                    <Text style={{fontSize:11,color:"#1BC5BD"}}>{tabIndex==0?"Sell":"Buy"}</Text>
                                                </View>
                                            </View>
                                            <View style={{flexDirection:"row",alignItems:"center"}}>
                                                <AntDesign name="calendar" size={16} color="#888C9F" />
                                                <Text style={{fontSize:11,color:"#888C9F",marginLeft:5}}>
                                                    {formatDate(data.transaction_date)}
                                                </Text>
                                            </View>
                                        </View>
                                        </>
                                    )
                                }


                                <ScrollView horizontal={true} contentContainerStyle={{flexGrow:1}}>
                                    {/* table data */}

                                    <FlatList
                                        data={this.state.filteredData}
                                        // horizontal={true}
                                        ref={ref => { this.suggestionsTable = ref; }}
                                        renderItem={this.renderSuggestionItem}
                                        keyExtractor={(item, index) => `suggestion_${index}`}
                                        
                                        ListHeaderComponent={this.renderTableHeader}
                                        style={{
                                            paddingBottom:15,
                                            // minWidth: 800
                                        }}
                                        
                                    />

                                </ScrollView>    

                                <TouchableOpacity onPress={()=>{
                                    this.props.navigation.navigate("InvestmentOverview")
                                }} 
                                style={{alignSelf:"center",borderWidth:1,borderColor:"#410DAA",borderRadius:6,paddingHorizontal:52,paddingVertical:11,marginTop:50}}>
                                        <Text style={{fontSize:15,color:"#410DAA",fontWeight:"bold"}}>View Dashboard</Text>
                                </TouchableOpacity>
                    </View>
                            
                </ScrollView>
                 :(
                    <View style={{flex:1,justifyContent:"center",alignItems:"center"}}>
                        <ActivityIndicator size="large" color="#410DAA" />
                    </View>
                )}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    mainContainer: {
      backgroundColor:'#FFFFFF',
      paddingLeft: md(window.width) ? 52:0,
      paddingRight: md(window.width) ? 52:0,
      paddingTop: 30,
      maxWidth: 1400,
      width: "100%"
    },
    confirmButtonContainer:{
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"center",
        justifyContent:"space-around",
        flex:1
    },
    confirmButton:{
        paddingHorizontal:20,
        paddingVertical:6,
        borderRadius:6,
        alignItems:"center",
        flex:1,
        marginHorizontal:10
    },
    confirmButton2:{
        // paddingHorizontal:20,
        paddingTop:6,
        borderRadius:6,
        width:"100%"
    },
    confirmButtonNo:{
        backgroundColor:"#fff",
        borderColor:"#410DAA",
        borderWidth:1
    },
    noText:{
        color:"#410DAA",
        fontSize:15
    },
    confirmButtonYes:{
        backgroundColor:"#410DAA",
    },
    yesText:{
        color:"#fff",
        fontSize:15
    },
    border:{
        borderColor:"#968E8E40",
        borderWidth:1,
        borderRadius:4,
        overflow:"hidden",
        // marginBottom: md(window.width) ? 0: 0,
        borderTopColor:"#410DAA",
        borderTopWidth:4
    },
    retryOrdersButtonContainer:{
        paddingVertical: md(window.width) ? 0: 35
    },
    retryButton:{
        paddingHorizontal: md(window.width) ? 50 : 35,
        paddingVertical: md(window.width) ? 11: 9,
        // alignItems: 'center',
        backgroundColor: '#410DAA',
        marginVertical:2,
        borderRadius:6,
        width:"100%",
        alignSelf:"flex-end"
    },
    retryButtonText:{
        fontWeight:"bold",
        fontSize:16,
        color:"#fff",
        textAlign:"center"
    },
    tableSummary:{
        // paddingHorizontal: md(window.width) ? 25 : 8,
        flexDirection:"row",
        paddingVertical: md(window.width) ? 19: 8,
        flex: md(window.width) ? 0.7 : 1
    },
    amountContainer:{
        flexDirection: "row",
        alignItems: "flex-start",
        flexWrap:"wrap"
    },
    completeTitle:{
        fontSize: md(window.width) ? 16 : 15,
        fontWeight:"bold",
        color:"#212121"
    },
    amount:{
        fontSize:17,
        fontWeight:"700",
        color:"#410DAA",
        fontStyle:"italic",
        paddingLeft:5
    },
    pendingStocksList:{
        paddingTop:10,
        fontSize: md(window.width) ? 14 : 12,
        color:"#410DAA"
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#410DAA',
        marginVertical:2,
        borderRadius:6
      },
      button2: {
        alignItems: 'center',
        backgroundColor: '#410DAA',
        marginVertical:2,
        // borderRadius:6
      },
      buttonText: {
        textAlign: 'center',
        paddingHorizontal: 30,
        paddingVertical:12,
        color: 'white',
        fontWeight:"700",
        fontSize:14
      },

      portfolioValueText:{
          fontSize: md(width) ? 18:14,
          color:"#212121"
      },

      portfolioValueAmount:{
        fontSize:16,
        color:"#410DAA",
        fontStyle:"italic",
        paddingLeft:5,
        fontWeight:"700"
      },

      circle1: {
          width:16,
          height:16,
          borderRadius:8,
          borderWidth:1,
          borderColor:"#410DAA",
          alignItems:"center",
          justifyContent:"center",
          
      },
      circle2: {
        width:10,
        height:10,
        borderRadius:5,
        borderWidth:1,
        borderColor:"#410DAA"
    },
    step:{fontSize:16,color:"#212121", marginLeft:9,fontWeight:"bold"},
    expectedAmountContainer:{
        flexDirection:"row",
        justifyContent:"space-between",
        paddingHorizontal: md(window.width)?35:5,
        paddingVertical:10,
        backgroundColor:"#F3F6F9",
        alignItems:"center"
    },
    expectedAmount:{

    },
    expectedAmountText:{
        color:"#464E5F",
        fontSize:12,
        fontWeight:"bold"
    },
    planwiseSellText:{
        fontSize:12,
        color:"#410DAA"
    },

    notesContainer:{
        marginTop:15,
        marginBottom:50
    },
    note:{
        fontSize:12,
        color:"#12121290",
        fontStyle:"italic",
        
    },
    note1:{
        marginBottom:20,
    },
    note2:{
        marginBottom:20,
    },
    note3:{
        marginBottom:20,
        color:"#121212"
    },
    undeline:{
        textDecorationLine: "underline",
        color:"#121212"
    },
    notePoint:{
        marginTop:8
    }
  });