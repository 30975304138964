import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import Table from "../../../../components/Common/Table";
import NavigationHeader from "../../../../components/Common/NavigationHeader";
import amplitude from "../../../../utils/amplitude";
import AntDesign from "@expo/vector-icons/AntDesign";
import { capitalizeFirstLetter, formatDate, getUserDetails, isMobile, numberWithCommas } from "../../../../utils";
import { getAverageAUADetails } from "../../../../api/billing";
import { ActivityIndicator } from "react-native-paper";
import moment from "moment";
import Store from "../../../../store";
import SearchBar from "../../../../components/Common/SearchBar";
import { getCustomerDetails } from "../../../../api";
// import ReactExport from "react-export-excel";
import HoldingsTable from "../../HoldingsTable";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const response = {
  code: 100,
  msg: "Fetched",
  results: {
    bill_type: "",
    bill_period: "01 Jan 2020 - 02 Feb 2022",
    amount: 123454,
    details: [
      {
        date: 1344656, //unix timestamp
        value: 1243534,
        fee_perc: 1.25,
        amount: 1442,
      },
    ],
  },
};
class AumDetails extends React.Component {
  constructor(props) {
    super(props);
    let bill_id, user_id;
    const { params } = this.props.route;
    if (params) {
      bill_id = params.bill_id || null;
      user_id = params.user_id || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      bill_id,
      user_id,
      isLoading: true,
      userName: "",
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("AumDetails",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("AumDetails", { path: this.props.navigation.getState().routeName });
      }
      const { bill_id } = this.state;
      const response = await getAverageAUADetails(bill_id);
      if (response && response.code == 100) {
        const { results = {} } = response;
        const { detail_list = {} } = results[0];

        const {
          start_date,
          end_date,
          amount,
          bill_list: details = [],
        } = detail_list;
        const data = {
          bill_period: formatDate(start_date) + " - " + formatDate(end_date),
          amount,
          details,
        };
        this.setState({ data, dataBackup: data, isLoading: false });

        let userData = {};
        if (this.state.user_id) {
          userData = await getCustomerDetails(this.state.user_id);
        } else {
          userData = await getUserDetails();
        }
        let { first_name } = userData;
        let names = first_name ? first_name.split(/\s+/) : [];
        let name = "";
        if (names.length >= 2 && names.length > 0) {
          name =
            capitalizeFirstLetter(names[0]) +
            " " +
            capitalizeFirstLetter(names[1]);
        } else {
          if (names.length == 0) {
            name = "User";
          } else {
            name = names[0];
          }
        }
        this.setState({ userName: name });
      } else {
        this.setState({ error: "Error loading details", isLoading: false });
      }
    } catch (error) {
      console.log(error)
      this.setState({ error: "Error loading details", isLoading: false });
    }
  }

  navigateBack = () => {
    const { navigation } = this.props;
    if (navigation) {
      navigation.goBack();
    }
  };

  compare = (a, b, field, sort) => {
    if (a[field] < b[field]) {
      return sort ? -1 : 1;
    }
    if (a[field] > b[field]) {
      return sort ? 1 : -1;
    }
    return 0;
  };

  handleSort = (field, sort, start, end) => {
    this.setState(
      {
        sorting_field: field,
        sorting_value: !sort,
      },
      () => {
        const { data } = this.state;
        const details_data = [...data["details"]];
        const sortedData = [].concat(
          details_data.slice(0, start),
          details_data
            .slice(start, end)
            .sort((a, b) => this.compare(a, b, field, !sort)),
          details_data.slice(end, details_data.length)
        );
        this.setState({ data: { ...data, ['details']:sortedData} });
      }
    );
  };

  getTableData = (data = null) => {
    if (!data || (data && !data.hasOwnProperty("details"))) return {};
    const { sorting_field, sorting_value } = this.state;
    const headerData = [
      {
        label: "Valuation Date",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "as_on_date",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Value",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "stocks_aum",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Fee%",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "fee_percentage",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Bill",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "aum_bill",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "View Details",
        style: [styles.tableHeader],
        sortOption: false,
        onSort: this.handleSort,
        key: "", 
        currentSortField: sorting_field,
        sortValue: sorting_value,
      }
    ];
    let { values = [] } = this.generateDataForTable(data["details"]);
    return { headerData, values };
  };

  handleShowHoldings = async (date) => {
    let params = {};
    if (this.state.user_id) {
      params["user_id"] = this.state.user_id;
    }
    date = moment(date).format("DD MMM YYYY");
    date = date.split(" ").join("").toLowerCase();
    this.props.navigation.navigate("HoldingsDetails", {
      bill_id: this.state.bill_id,
      invoice_id: this.props.invoiceId,
      date,
      ...params,
    });
  };

  generateDataForTable = (data) => {
    let values = [];
    data.forEach((item, index) => {
      let {
        as_on_date: date,
        stocks_aum: value,
        fee_percentage: fee_perc,
        aum_bill: amount,
      } = item;
      value = numberWithCommas(parseInt(value));
      amount = numberWithCommas(parseInt(amount));
      fee_perc = parseFloat(fee_perc).toFixed(2);
      values.push([
        {
          valueToDisplay: date,
          component: true,
          value: (
            <Text style={[styles.valuationDate]}>{formatDate(date)}</Text>
          ),
          style: [{ marginLeft: isMobile ? 50 : 80}],
          rowItemContainerStyle: [{ justifyContent: "center" }],
        },
        {
          valueToDisplay: "₹" + value,
          value: value,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: fee_perc + "%",
          value: fee_perc,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + amount,
          value: amount,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "",
          component: true,
          value: (
            <TouchableOpacity
              style={styles.valuationDateContainer}
              onPress={() => this.handleShowHoldings(date)}
            >
              <AntDesign
                name="arrowright"
                size={16}
                color={"#410DAA"}
                style={styles.bold}
              />
            </TouchableOpacity>
          ),
          style: [{}],
          rowItemContainerStyle: [{ justifyContent: "center" }],
        },
      ]);
    });
    return { values };
  };

  handleSearch = (searchText) => {
    let searchResults = [];
    let searchData = this.state.data.details;
    if (searchText) {
      searchResults = searchData.filter((item) => {
        return item.as_on_date.toLowerCase().startsWith(searchText);
      });
    } else {
      searchResults = this.state.dataBackup.details;
    }

    if (searchResults.length) {
      const total_holdings_amount = searchResults.reduce((c, p) => c + p.aum_bill, 0);
      const data = {
        details: searchResults,
        total_holdings_amount,
      };
      this.setState({
        data,
        searchText,
        searchResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  render() {
    const { navigation } = this.props;
    const { data = null, isLoading, error, searchText } = this.state;

    let excelBillingDetails = [];
    if (this.state.data) {
      this.state.data.details.forEach((item) => {
        let stock_aum = "₹" + numberWithCommas(parseInt(item.stocks_aum));
        let bill = "₹" + numberWithCommas(parseInt(item.aum_bill));

        excelBillingDetails.push({
          valuation_date: item.as_on_date,
          value: item.stocks_aum,
          fee: stock_aum,
          bill: bill,
        });
      });
    }

    const { headerData, values } = this.getTableData(data);

    return (
      <View style={styles.container}>
        <NavigationHeader
          navigation={navigation}
          navigationHeader={["Bills", "View Bills", "View Details"]}
          onPressBack={this.navigateBack}
        />
        {isLoading || error ? (
          <View style={styles.loadingContainer}>
            {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
            {error && <Text style={styles.error}>{error}</Text>}
          </View>
        ) : (
          <ScrollView
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={true}
            horizontal={isMobile ? true : false}
            style={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <View 
              style={{
                borderWidth: 1,
                borderColor: "#1212121A",
                borderRadius: 10,
                marginHorizontal: isMobile ? 16 : 20,
                width: isMobile ? 700 : '80%',
                marginVertical: 20,
              }}
            >
              <View
                style={{
                  paddingHorizontal: isMobile ? 16 : 19,
                  paddingVertical: 19,
                  flexDirection: "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text style={styles.header}>
                    Advisory Fee Details
                  </Text>
                  <Text style={styles.headerBillingPeriod}>
                    (Period: {data["bill_period"]})
                  </Text>
                </View>
                <Text style={styles.billTotalAmount}>
                  Total:  ₹{numberWithCommas(parseInt(data["amount"]))}
                </Text>
              </View>
              <View style={styles.headingContainer}>
                {/* <SearchBar value={searchText} onChange={this.handleSearch} /> */}
                <View></View>
                <View
                  style={{
                    cursor: "pointer",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    marginRight: 20,
                  }}
                >
                  {/* <ExcelFile
                    filename={
                      this.state.userName + moment().format("DD-MM-YYYY hh-mm-ss A")
                    }
                    element={
                      <Text
                        style={{
                          color: "#410DAA",
                          fontWeight: "bold",
                          cursor: "pointer",
                          fontSize: 15,
                          textDecorationLine: "underline",
                        }}
                      >
                        Download As Excel
                      </Text>
                    }
                  >
                    <ExcelSheet data={excelBillingDetails} name="Invoices">
                      <ExcelColumn label="Valuation Date" value="valuation_date" />
                      <ExcelColumn label="Value" value="value" />
                      <ExcelColumn label="Fee%" value="fee" />
                      <ExcelColumn label="Bill" value="bill" />
                    </ExcelSheet>
                  </ExcelFile> */}
                </View>
              </View>
              <HoldingsTable
                header={headerData}
                gridConfig={[0.25, 0.25, 0.2, 0.2, 0.1]}
                data={values}
                navigation={this.props.navigation}
              />
              {/* <Table
                header={headerData}
                gridConfig={[0.25, 0.25, 0.2, 0.2, 0.1]}
                data={values}
                minWidth={1200}
              /> */}
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: isMobile ? 16 : 36,
    marginVertical: 30,
  },
  borderColor: {
    borderColor: "#DBC0FF",
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 10 : 40,
    paddingVertical: 19,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "700",
  },
  headerBillingPeriod: {
    fontSize: 15,
    fontFamily: "Roboto",
    color: "#464E5F",
    marginTop: 10,
  },
  billTotalAmount: {
    fontSize: 15,
    color: "#464E5F",
    fontFamily: "Roboto",
    fontWeight: "700",
    marginRight: 50,
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  valuationDate: { fontSize: 11 },
  valuationDateContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  active: { color: "#410DAA", fontWeight: "700" },
  bold: { fontWeight: "bold", marginLeft: isMobile ? 2 : 12 },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default AumDetails;
