import React from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import {
  updateFinplanStatus,
  updateLastSeenScreen,
} from "../../../actions/finplan";
import { YesNo } from "../components/yesno";
import NavigationHeader from "../../../components/Common/NavigationHeader";

class BuyBike extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let type = null;
    if (params) {
      type = params.type || null;
    }
    this.state = {
      type,
      isLoading: false,
      showBuildingModal: false,
    };
  }

  wantToBuyBike = async (value) => {
    const { params = {} } = this.props.route;
    const { ...restParams } = params;
    try {
      const { type } = this.state;
      if (value) {
        this.props.navigation.navigate("WhenBuyBike", { type, ...restParams });
      } else {
        this.props.navigation.navigate("WhenRetire", { type, ...restParams });
      }
    } catch (error) {}
  };

  render() {
    return (
      <>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Buy Bike"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <View
          style={[
            styles.page,
            { flex: 1, paddingHorizontal: 16, paddingTop: 60 },
          ]}
        >
          <YesNo
            image={
              "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/bike_finplan_image.png"
            }
            imageStyle={{ alignSelf: "center" }}
            style={{}}
            onYes={() => this.wantToBuyBike(true)}
            onNo={() => this.wantToBuyBike(false)}
            title={"Planning to buy a Bike?"}
            titleStyle={{
              fontSize: 24,
            }}
            selectedColor={"#00689f"}
            notSelectedColor={"white"}
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#fff",
    paddingBottom: 66,
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateFinplanStatus: (type, status) =>
      dispatch(updateFinplanStatus(type, status)),
    updateLastSeenScreen: (type, screen, basic, end) =>
      dispatch(updateLastSeenScreen(type, screen, basic, end)),
  };
};

export default connect(null, mapDispatchToProps)(BuyBike);
