import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  Platform,
  FlatList,
} from "react-native";
import {
  capitalizeFirstLetter,
  encryptToken,
  formatDate,
  getUserDetails,
  isMobile,
  months_short_names,
  numberWithCommas,
} from "../../utils";
import Checkbox from "expo-checkbox";
import amplitude from "../../utils/amplitude";

import BillingTable from "./BillingTable";
import SearchBar from "../../components/Common/SearchBar";
import { getCustomerDetails } from "../../api";
const { height } = Dimensions.get("window");
import moment from "moment";
// import ReactExport from "react-export-excel";
import { AntDesign } from "@expo/vector-icons";
import { Popable } from "react-native-popable";
import { FontAwesome5 } from "@expo/vector-icons";
import { MAIN_URL } from "../../api/urls";
import Store from "../../store";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class pendingInvoiceOverdue extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    let fromOnboard = props.fromOnboard ? props.fromOnboard : false;
    const { params } = this.props.route;
    if (params) {
      userId = params.user_id || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }
    let selectedCheckboxs = [];
    this.props.data.forEach((item) => {
      selectedCheckboxs.push({ ...item, toShow: true });
    });
    this.props.selectedInvoiceData(selectedCheckboxs);

    this.state = {
      fromOnboard,
      userId,
      isSelected: false,
      sorting_field: null,
      sorting_value: null,
      searchResults: this.props.data,
      sorting_field: null,
      sorting_value: null,
      activeTab: 0,
      userName: "",
      selectedCheckboxs,
      isAllCheckboxSelected: true,
    };
  }

  componentDidMount = async () => {
    if (Platform.OS == "web") {
      amplitude.logEvent("PendingInvoiceOverdue", {
        path: global.location.href,
      });
    } else {
      amplitude.logEvent("PendingInvoiceOverdue", {
        path: this.props.navigation.getState().routeName,
      });
    }
    let userData = {};
    if (this.state.userId) {
      userData = await getCustomerDetails(this.state.userId);
    } else {
      userData = await getUserDetails();
    }
    let { first_name } = userData;
    let names = first_name ? first_name.split(/\s+/) : [];
    let name = "";
    if (names.length >= 2 && names.length > 0) {
      name =
        capitalizeFirstLetter(names[0]) + " " + capitalizeFirstLetter(names[1]);
    } else {
      if (names.length == 0) {
        name = "User";
      } else {
        name = names[0];
      }
    }
    this.setState({ userName: name });
  };

  handleView = (pk) => {
    let url = "/billing/details/";
    let params = {};
    url += pk + "/";
    if (this.state.userId) {
      params["user_id"] = this.state.userId;
      url += "?user_id=" + this.state.userId;
    }
    this.props.navigation.navigate("InvoiceDetails", {
      invoice_id: pk,
      ...params,
    });
  };

  getColor = (status) => {
    return status == "PAID" ? "#00AB9A" : "#F64E60";
  };

  handleSearch = (searchText) => {
    let searchResults = [];
    let searchData = this.props.data;
    if (searchText) {
      searchResults = searchData.filter((item) => {
        return item.invoice_pk
          .toString()
          .toLowerCase()
          .startsWith(searchText.toLowerCase());
      });
    } else {
      searchResults = searchData;
    }

    if (searchResults.length) {
      this.setState({
        searchText,
        searchResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  compareStrings = (a, b) => {
    const x = a.toLowerCase();
    const y = b.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  compareNumbers = (a, b) => {
    return a - b;
  };

  getSortedData = (data, sorting_field, sorting_value, start, end) => {
    if (data.length != 0) {
      const fieldValue = data[0][sorting_field];
      const sortingType = isNaN(fieldValue) ? "string" : "number";
      data = data.slice(start, end);
      if (sortingType == "string") {
        data.sort((a, b) =>
          this.compareStrings(a[sorting_field], b[sorting_field])
        );
      } else {
        data.sort((a, b) =>
          this.compareNumbers(a[sorting_field], b[sorting_field])
        );
      }
      return sorting_value ? data : data.reverse();
    } else {
      return data;
    }
  };

  handleSort = (field, sort, start, end) => {
    const sorting_field = field;
    const sorting_value = !sort;
    const { searchResults } = this.state;
    const sortedData = [].concat(
      searchResults.slice(0, start),
      this.getSortedData(
        searchResults,
        sorting_field,
        sorting_value,
        start,
        end
      ),
      searchResults.slice(end, searchResults.length)
    );
    this.setState({ sorting_field, sorting_value, searchResults: sortedData });
  };

  showPopableRow = ({ item, index }) => {
    return item.customerinvestmentplan__customerinvestmentplan_id ? (
      <Popable
        action={isMobile ? "press" : "hover"}
        content={item.plan_decription}
        position="left"
        style={{ minWidth: 200 }}
      >
        <Text
          style={{
            marginLeft: 10,
          }}
        >
          #
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 12,
            }}
          >
            {item.customerinvestmentplan__customerinvestmentplan_id}
          </Text>
          <AntDesign
            name="infocirlce"
            size={12}
            color="#12121280"
            style={{ paddingLeft: 5, paddingTop: 0 }}
          />
        </Text>
      </Popable>
    ) : null;
  };

  handleDownloadInvoice = (invoice_pk) => {
    const { token } = getUserDetails();
    const encryptedToken = encryptToken(token);
    const url =
      MAIN_URL +
      "/app/rnw_to_web_login?token=" +
      encryptedToken +
      "&is_rnw=1&redirection_url=/app/download_invoice/" +
      String(invoice_pk);
    global.open(url, "_blank");
  };

  getHeaderTableData = (data, selectedCheckboxs) => {
    const { sorting_field, sorting_value, searchText } = this.state;
    let headerData = [];
    let values = [];

    let tempHeaderData = this.state.fromOnboard
      ? [
          {
            component: true,
            label: (
              <View style={{ marginLeft: -20 }}>
                <Checkbox
                  status={
                    this.state.isAllCheckboxSelected ? "checked" : "unchecked"
                  }
                  color={this.state.isAllCheckboxSelected ? "#410DAA" : "gray"}
                  value={this.state.isAllCheckboxSelected}
                  onValueChange={() => {
                    let checkbox = selectedCheckboxs;
                    if (this.state.isAllCheckboxSelected) {
                      checkbox.forEach((item) => {
                        item.toShow = false;
                      });
                    } else {
                      checkbox.forEach((item) => {
                        item.toShow = true;
                      });
                    }
                    this.props.selectedInvoiceData(checkbox);
                    this.setState({ selectedCheckboxs: checkbox });
                    this.setState({
                      isAllCheckboxSelected: !this.state.isAllCheckboxSelected,
                    });
                  }}
                  style={{
                    width: isMobile ? 13 : 14,
                    height: isMobile ? 13 : 14,
                    color: selectedCheckboxs ? "#410DAA" : "gray",
                  }}
                />
              </View>
            ),
          },
          { label: "Invoice Number", value: "invoice_pk" },
          { label: "Plan Id", value: "plan_ids" },
          { label: "Date", value: "invoice_date" },
          { label: `Total Fee\n(including GST)`, value: "amount_with_gst" },
          { label: `Due\n(including GST)`, value: "due_with_gst" },
        ]
      : ((Store.getState().dashboard &&
          Store.getState().dashboard.viewModeUserId &&
          Store.getState().dashboard.viewModeUserId.user_id) ||
          (this.props.route.params && this.props.route.params.user_id)) &&
        this.state.activeTab == 0
      ? [
          {
            component: true,
            label: (
              <View style={{ marginLeft: -20 }}>
                <Checkbox
                  status={
                    this.state.isAllCheckboxSelected ? "checked" : "unchecked"
                  }
                  color={this.state.isAllCheckboxSelected ? "#410DAA" : "gray"}
                  value={this.state.isAllCheckboxSelected}
                  onValueChange={() => {
                    let checkbox = selectedCheckboxs;
                    if (this.state.isAllCheckboxSelected) {
                      checkbox.forEach((item) => {
                        item.toShow = false;
                      });
                    } else {
                      checkbox.forEach((item) => {
                        item.toShow = true;
                      });
                    }
                    this.props.selectedInvoiceData(checkbox);
                    this.setState({ selectedCheckboxs: checkbox });
                    this.setState({
                      isAllCheckboxSelected: !this.state.isAllCheckboxSelected,
                    });
                  }}
                  style={{
                    width: isMobile ? 13 : 14,
                    height: isMobile ? 13 : 14,
                    color: selectedCheckboxs ? "#410DAA" : "gray",
                  }}
                />
              </View>
            ),
          },
          { label: "Invoice Number", value: "invoice_pk" },
          { label: "Plan Id", value: "plan_ids" },
          { label: "Date", value: "invoice_date" },
          { label: "Total Fee", value: "amount" },
          { label: `Total Fee\n(including GST)`, value: "amount_with_gst" },
          { label: "Paid", value: "amount_paid" },
          { label: `Paid\n(including GST)`, value: "paid_with_gst" },
          { label: "Due", value: "amount_due" },
          { label: `Due\n(including GST)`, value: "due_with_gst" },
          { label: `Download Invoice`, value: "download_invoice" },
        ]
      : [
          {
            component: true,
            label: (
              <View style={{ marginLeft: -20 }}>
                <Checkbox
                  status={
                    this.state.isAllCheckboxSelected ? "checked" : "unchecked"
                  }
                  color={this.state.isAllCheckboxSelected ? "#410DAA" : "gray"}
                  value={this.state.isAllCheckboxSelected}
                  onValueChange={() => {
                    let checkbox = selectedCheckboxs;
                    if (this.state.isAllCheckboxSelected) {
                      checkbox.forEach((item) => {
                        item.toShow = false;
                      });
                    } else {
                      checkbox.forEach((item) => {
                        item.toShow = true;
                      });
                    }
                    this.props.selectedInvoiceData(checkbox);
                    this.setState({ selectedCheckboxs: checkbox });
                    this.setState({
                      isAllCheckboxSelected: !this.state.isAllCheckboxSelected,
                    });
                  }}
                  style={{
                    width: isMobile ? 13 : 14,
                    height: isMobile ? 13 : 14,
                    color: selectedCheckboxs ? "#410DAA" : "gray",
                  }}
                />
              </View>
            ),
          },
          { label: "Invoice Number", value: "invoice_pk" },
          { label: "Plan Id", value: "plan_ids" },
          { label: "Date", value: "invoice_date" },
          { label: "Total Fee", value: "amount" },
          { label: `Total Fee\n(including GST)`, value: "amount_with_gst" },
          { label: "Paid", value: "amount_paid" },
          { label: `Paid\n(including GST)`, value: "paid_with_gst" },
          { label: "Due", value: "amount_due" },
          { label: `Due\n(including GST)`, value: "due_with_gst" },
        ];

    tempHeaderData.forEach((headerItem, index) => {
      headerData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: index == 0 || index == 2 ? false : true,
        onSort: this.handleSort,
        key: headerItem.value,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });
    data.forEach((value, index) => {
      if (this.state.fromOnboard) {
        values.push([
          {
            component: true,
            value: (
              <Checkbox
                status={
                  selectedCheckboxs[index].toShow ? "checked" : "unchecked"
                }
                color={selectedCheckboxs[index].toShow ? "#410DAA" : "gray"}
                value={selectedCheckboxs[index].toShow}
                onValueChange={() => {
                  let checkbox = selectedCheckboxs;
                  if (checkbox[index]) {
                    this.setState({ isAllCheckboxSelected: false });
                  }

                  checkbox[index].toShow = !checkbox[index].toShow;
                  this.props.selectedInvoiceData(checkbox);
                  this.setState({ selectedCheckboxs: checkbox });
                }}
              />
            ),
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.invoice_pk,
            value: value.invoice_pk || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            component: true,
            value: (
              <FlatList
                data={value.cip_plans}
                renderItem={this.showPopableRow}
                keyExtractor={(item, index) => index.toString()}
                numColumns={isMobile ? 1 : 2}
              />
            ),
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.invoice_date,
            value: value.invoice_date || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.amount_with_gst,
            value: value.amount_with_gst || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.due_with_gst,
            value: value.due_with_gst || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
        ]);
      } else {
        let dflt_value_data = [
          {
            component: true,
            value: (
              <Checkbox
                status={
                  selectedCheckboxs[index].toShow ? "checked" : "unchecked"
                }
                color={selectedCheckboxs[index].toShow ? "#410DAA" : "gray"}
                value={selectedCheckboxs[index].toShow}
                onValueChange={() => {
                  let checkbox = selectedCheckboxs;
                  if (checkbox[index]) {
                    this.setState({ isAllCheckboxSelected: false });
                  }

                  checkbox[index].toShow = !checkbox[index].toShow;
                  this.props.selectedInvoiceData(checkbox);
                  this.setState({ selectedCheckboxs: checkbox });
                }}
              />
            ),
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.invoice_pk,
            value: value.invoice_pk || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            component: true,
            value: (
              <FlatList
                data={value.cip_plans}
                renderItem={this.showPopableRow}
                keyExtractor={(item, index) => index.toString()}
                numColumns={isMobile ? 1 : 2}
              />
            ),
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.invoice_date,
            value: value.invoice_date || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.amount,
            value: value.amount || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.amount_with_gst,
            value: value.amount_with_gst || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.amount_paid,
            value: value.amount_paid || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.paid_with_gst,
            value: value.paid_with_gst || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.amount_due,
            value: value.amount_due || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.due_with_gst,
            value: value.due_with_gst || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
        ];

        if (
          ((Store.getState().dashboard &&
            Store.getState().dashboard.viewModeUserId &&
            Store.getState().dashboard.viewModeUserId.user_id) ||
            (this.props.route.params && this.props.route.params.user_id)) &&
          this.state.activeTab == 0
        ) {
          dflt_value_data.push({
            component: true,
            value: (
              <TouchableOpacity
                onPress={() => {
                  this.handleDownloadInvoice(value.invoice_pk);
                }}
              >
                <FontAwesome5 name="file-download" size={20} color="#410DAA" />
              </TouchableOpacity>
            ),
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          });
        }

        values.push(dflt_value_data);
      }
    });

    let excelBillingData = [];
    data.forEach((item) => {
      let invoice_date = item.invoice_date;
      let date = "";
      if (invoice_date) {
        date = invoice_date.split("-");
        const month = months_short_names(parseInt(date[1]));
        date = date[2] + " " + month + " " + date[0];
      }

      let Amount = "₹" + numberWithCommas(item.amount);
      let AmountWithGst = "₹" + numberWithCommas(item.amount_with_gst);
      let AmountPaid = "₹" + numberWithCommas(item.amount_paid);
      let PaidWithGst = "₹" + numberWithCommas(item.paid_with_gst);
      let AmountDue = "₹" + numberWithCommas(item.amount_due);
      let DueWithGst = "₹" + numberWithCommas(item.due_with_gst);

      if (this.state.fromOnboard) {
        excelBillingData.push({
          invoice_pk: item.invoice_pk,
          invoice_date: date,
          amount_with_gst: AmountWithGst,
          due_with_gst: DueWithGst,
        });
      } else {
        excelBillingData.push({
          invoice_pk: item.invoice_pk,
          invoice_date: date,
          amount: Amount,
          amount_with_gst: AmountWithGst,
          amount_paid: AmountPaid,
          paid_with_gst: PaidWithGst,
          amount_due: AmountDue,
          due_with_gst: DueWithGst,
        });
      }
    });
    return { headerData, values, excelBillingData };
  };

  render() {
    let data = this.state.searchResults;
    let selectedCheckboxs = this.state.selectedCheckboxs;
    if (this.props.activeTab !== this.state.activeTab) {
      data = this.props.data;
      selectedCheckboxs = [];
      this.props.data.forEach((item) => {
        selectedCheckboxs.push({ ...item, toShow: true });
      });
      this.setState({
        activeTab: this.props.activeTab,
        searchResults: this.props.data,
        selectedCheckboxs: selectedCheckboxs,
      });
    }
    const { sorting_field, sorting_value, searchText } = this.state;

    const { headerData, values, excelBillingData } = this.getHeaderTableData(
      data,
      selectedCheckboxs
    );

    let grid_Config = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    if (
      ((Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id) ||
        (this.props.route.params && this.props.route.params.user_id)) &&
      this.state.activeTab == 0
    ) {
      grid_Config.push(1);
    }

    return isMobile ? (
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
        <View style={styles.detailsContainer}>
          <View style={styles.headingContainer}>
            <SearchBar value={searchText} onChange={this.handleSearch} />
            <View
              style={{
                cursor: "pointer",
                justifyContent: "flex-end",
                flexDirection: "row",
                marginRight: 20,
              }}
            ></View>
          </View>
          <BillingTable
            header={headerData}
            gridConfig={grid_Config}
            data={values}
            navigation={this.props.navigation}
            pagination={values.length > 10 ? true : false}
          />
        </View>
      </ScrollView>
    ) : (
      <View style={styles.detailsContainer}>
        <View style={styles.headingContainer}>
          <SearchBar value={searchText} onChange={this.handleSearch} />
          <View
            style={{
              cursor: "pointer",
              justifyContent: "flex-end",
              flexDirection: "row",
              marginRight: 20,
            }}
          >
            {/* <ExcelFile
              filename={
                this.state.userName +
                " " +
                (this.props.activeTab == 0
                  ? "Overdue Invoices "
                  : "Paid Invoices ") +
                moment().format("DD-MM-YYYY hh-mm-ss A")
              }
              element={
                <Text
                  style={{
                    color: "#410DAA",
                    fontWeight: "bold",
                    cursor: "pointer",
                    fontSize: 15,
                    textDecorationLine: "underline",
                  }}
                >
                  Download As Excel
                </Text>
              }
            >
              {this.state.fromOnboard ? (
                <ExcelSheet data={excelBillingData} name="Invoices">
                  <ExcelColumn label="Invoice Number" value="invoice_pk" />
                  <ExcelColumn label="Date" value="invoice_date" />
                  <ExcelColumn
                    label="Total Fee (including GST)"
                    value="amount_with_gst"
                  />
                  <ExcelColumn
                    label="Due (including GST)"
                    value="due_with_gst"
                  />
                </ExcelSheet>
              ) : (
                <ExcelSheet data={excelBillingData} name="Invoices">
                  <ExcelColumn label="Invoice Number" value="invoice_pk" />
                  <ExcelColumn label="Date" value="invoice_date" />
                  <ExcelColumn label="Total Fee" value="amount" />
                  <ExcelColumn
                    label="Total Fee (including GST)"
                    value="amount_with_gst"
                  />
                  <ExcelColumn label="Paid" value="amount_paid" />
                  <ExcelColumn
                    label="Paid (including GST)"
                    value="paid_with_gst"
                  />
                  <ExcelColumn label="Due" value="amount_due" />
                  <ExcelColumn
                    label="Due (including GST)"
                    value="due_with_gst"
                  />
                </ExcelSheet>
              )}
            </ExcelFile> */}
          </View>
        </View>
        <BillingTable
          header={headerData}
          gridConfig={grid_Config}
          data={values}
          navigation={this.props.navigation}
          pagination={values.length > 10 ? true : false}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  table_head: {
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "#F3F6F9",
    paddingVertical: 12,
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22,
  },
  header_txt: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    color: "#464E5F",
    letterSpacing: 0.5,
    fontWeight: "bold",
  },
  border: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#DCC2FF",
    borderRadius: 22,
    overflow: "hidden",
  },
  flatList: {
    maxHeight: height * 0.6,
  },
  table_item1: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 11,
  },
  table_item: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 11,
    marginRight: 30,
    paddingRight: 80,
  },
  item: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    color: "#121212",
    textAlign: "left",
  },
  details: {
    fontFamily: "Roboto",
    textAlign: "center",
    fontSize: 14,
    lineHeight: 16,
    marginRight: 10,
    fontWeight: "bold",
    color: "#410DAA",
  },
  partial: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "#FFA800",
    paddingTop: 5,
    fontWeight: "700",
  },
  sub_tot: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 15,
    color: "#464E5F",
    letterSpacing: 0.32,
    paddingVertical: 5,
  },
  grand: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 16,
    color: "#000000",
    letterSpacing: 0.32,
    fontWeight: "bold",
    paddingVertical: 7,
  },
  direct: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  labelComponentContainer: {
    alignItems: "center",
  },
  lableComponent: {
    fontSize: 14,
    textAlign: "center",
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  rowItem: {
    textAlign: "left",
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  rowItemContainerStyle: {
    justifyContent: "flex-start",
    paddingLeft: 10,
  },
  headerItemContainerStyle: {
    justifyContent: "flex-start",
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: isMobile ? 16 : 20,
    marginBottom: 66,
    width: isMobile ? 1200 : "96%",
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 16 : 19,
    paddingVertical: 19,
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "space-between",
  },
  lableComponentDescription: {
    fontSize: 12,
    color: "#464E5F70",
  },
});
