import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { retrySwitch, cancelSwitch } from "../../../api/alerts";
import FIcon from "@expo/vector-icons/FontAwesome";
import { numberFormat, months_short_names, isMobile } from "../../../utils";
import AlertCard from "./AlertCard";
import SuccessOrFailureCard from "./SuccessOrFailureCard";
export default class pendingTransactionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Paidbool: false,
      loadingAction: false,
      deleted: false,
    };
  }

  handleOrder = () => {
    const PendingSwitches = this.props.data;
    if (this.state.loadingAction) return null;

    this.setState({ dialogVisible: false }, () => {
      setTimeout(() => {
        const all_units_flag = PendingSwitches.all_units_flag;
        if (!PendingSwitches.amcfundscheme__pk) {
          this.setState(
            { loadingAction: false, dialogVisible: false },
            () => {}
          );

          return;
        }

        if (!PendingSwitches.to_amcfundscheme__pk) {
          this.setState(
            { loadingAction: false, dialogVisible: false },
            () => {}
          );

          return;
        }

        if (
          all_units_flag == "N" &&
          !PendingSwitches.attempted_switch_units &&
          !PendingSwitches.attempted_switch_amount
        ) {
          this.setState(
            { loadingAction: false, dialogVisible: false },
            () => {}
          );

          return;
        }

        this.setState({ loadingAction: true });

        let data = {};
        if (all_units_flag == "N") {
          if (PendingSwitches.attempted_switch_units != 0) {
            data["units"] = PendingSwitches.attempted_switch_units;
          } else {
            data["amount"] = PendingSwitches.attempted_switch_amount;
          }
        }

        retrySwitch({
          from_scheme_id: PendingSwitches.amcfundscheme__pk,
          to_scheme_id: PendingSwitches.to_amcfundscheme__pk,
          folio_no: PendingSwitches.imported_folio_number,
          all_units_flag: all_units_flag,
          switch_id: PendingSwitches.customerswitchout_id,
          ...data,
        })
          .then((json) => {
            if (json && json.code && json.code == 100) {
              this.setState({
                dialogVisible: false,
                loadingAction: false,
                deleted: true,
              });
              this.props.deleteItem && this.props.deleteItem();

              let title = "";
              if (json.results && json.results.length > 0) {
                title = title =
                  "Switch has been placed. Order id: " +
                  json.results[0]["bse_order_number"] +
                  " Amount: " +
                  json.results[0]["amount"];
              } else if (json.msg) {
                title = json.msg;
              }
            } else {
              this.setState(
                { loadingAction: false, dialogVisible: false },
                () => {}
              );
            }
          })
          .catch(() => {});
      }, 1000);
    });
  };

  handleCancelOrder = () => {
    const PendingSwitches = this.props.data;
    this.setState({ loadingAction: true, dialogVisible: false });
    cancelSwitch(PendingSwitches.customerswitchout_id).then((json) => {
      if (json && json.code && json.code == 100) {
        this.setState({
          loadingAction: false,
          deleted: true,
          showSuccessOrErrorFailureModal: true,
          responseMessage: "Order has been cancelled successfully",
          responseStatus: true,
        });
        this.props.deleteItem && this.props.deleteItem();
      }
      if (json.code == 0) {
      }
      if (json.code == 101) {
      }
    });
  };

  _handleRetry() {
    this.setState({
      dialogVisible: true,
      alertPositiveButtonText: "Dismiss",
      alertNegativeButtonText: "Place Switch",
      alertPositiveButtonAction: () => {
        this.setState({ dialogVisible: false });
      },
      alertNegativeButtonAction: () => {
        this.handleOrder();
      },
      alertTitle: "Retry Your Switch",
    });
  }

  _handleCancel = () => {
    this.setState({
      dialogVisible: true,
      alertPositiveButtonText: "Take Me Back",
      alertNegativeButtonText: "Cancel Order",
      alertPositiveButtonAction: () => {
        this.setState({ dialogVisible: false });
      },
      alertNegativeButtonAction: () => {
        this.handleCancelOrder();
      },
      alertTitle: "Cancel Order From Switch List",
    });
  };

  formatDate = (date) => {
    date = date.split("-");
    const month = months_short_names(parseInt(date[1]));
    const date_formatted = date[2] + "-" + month + "-" + date[0];

    return date_formatted;
  };

  render() {
    const PendingSwitches = this.props.data;

    return this.state.loadingAction ? (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <ActivityIndicator size="large" color="#410DAA" />
      </View>
    ) : (
      <View>
        <View style={{ borderRadius: 5 }}>
          <View
            style={{
              flexDirection: "row",
              paddingHorizontal: 16,
              paddingVertical: 8,
              alignItems: "flex-start",
            }}
          >
            <View style={{ flex: 0.1 }}>
              <Image
                source={{ uri: PendingSwitches.from_amcfundscheme_logo }}
                resizeMode="contain"
                style={{
                  height: 30,
                  width: 30,
                  alignSelf: "flex-start",
                  borderWidth: 1,
                  borderColor: "#f6f6f6",
                  borderRadius: 5,
                  padding: 5,
                  overflow: "hidden",
                }}
              />
            </View>
            <View style={{ flex: 0.6, paddingLeft: 15 }}>
              <Text
                style={{ fontSize: 14, color: "#121212", fontWeight: "bold" }}
              >
                {PendingSwitches.amcfundscheme__name}
              </Text>
              <FIcon
                name="arrow-circle-o-down"
                style={{ alignSelf: "center", marginVertical: 6 }}
                size={16}
                color="#410DAA"
              />
            </View>
            <View style={{ flex: 0.3, alignItems: "flex-end" }}>
              <Text style={{ fontSize: 12, color: "#121212" }}>
                &#8377;
                {PendingSwitches.amount && PendingSwitches.amount != ""
                  ? numberFormat(PendingSwitches.amount)
                  : 0}
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: "gray",
                  fontStyle: "italic",
                  paddingTop: 5,
                }}
              >
                {PendingSwitches.imported_units &&
                PendingSwitches.imported_units != ""
                  ? PendingSwitches.imported_units.toFixed(2)
                  : 0}{" "}
                Units
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingHorizontal: 16,
              paddingVertical: 8,
              borderWidth: 0.8,
              borderColor: "#410DAA29",
              borderRadius: 8,
              marginHorizontal: 16,
              marginBottom: 10,
            }}
          >
            <View style={{ flex: 0.7, paddingLeft: 5 }}>
              <Text style={{ fontSize: 14, color: "#121212" }}>
                {PendingSwitches.to_amcfundscheme__name}
              </Text>
            </View>
            {/* <View style={{ flex: 0.3, alignItems: "flex-end" }}>
              <TouchableOpacity
                style={{
                  backgroundColor: "#00000014",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 10,
                }}
                onPress={() => {
                  this.props.propw.navigation.navigate("Switch", {
                    fund: PendingSwitches,
                    to_fund_name: PendingSwitches.to_amcfundscheme__name,
                    to_fund_id: PendingSwitches.to_amcfundscheme__pk,
                    name: PendingSwitches.amcfundscheme__name,
                  });
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    color: "#121212",
                    paddingHorizontal: 16,
                    paddingVertical: 5,
                  }}
                >
                  Change
                </Text>
              </TouchableOpacity>
            </View> */}
          </View>
          <View
            style={{ width: "100%", height: 1, backgroundColor: "#f2f2f2" }}
          ></View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingHorizontal: 16,
              paddingVertical: 4,
              paddingTop: 8,
            }}
          >
            <View
              style={{
                flex: 0.65,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {PendingSwitches.direct_savings != null &&
                PendingSwitches.amcfundscheme__is_direct_fund == false && (
                  <>
                    <Text style={{ fontSize: 13, color: "gray" }}>
                      Direct Savings 20 Yrs:{" "}
                    </Text>
                    <Text style={{ fontSize: 13, color: "#410DAA" }}>
                      {PendingSwitches.direct_savings != null
                        ? `₹ ${PendingSwitches.direct_savings.toFixed(2)}`
                        : "-"}
                    </Text>
                  </>
                )}
            </View>
            <View style={{ flex: 0.35, alignItems: "center" }}>
              <TouchableOpacity
                style={{
                  borderRadius: 8,
                  backgroundColor: "#410DAA",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  marginLeft: isMobile ? 0 : 10,
                  paddingVertical: 4,
                  paddingHorizontal: 15,
                }}
                onPress={() => {
                  this._handleRetry(PendingSwitches);
                }}
              >
                <Text style={{ fontSize: 12, color: "#fff" }}>Retry</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingHorizontal: 16,
              paddingVertical: 8,
            }}
          >
            <View
              style={{ flex: 0.7, flexDirection: "row", alignItems: "center" }}
            >
              <Text style={{ fontSize: 13, color: "gray" }}>Date : </Text>
              <Text style={{ fontSize: 13, color: "#121212" }}>
                {PendingSwitches.imported_date
                  ? this.formatDate(
                      PendingSwitches.imported_date.replace("T", " ")
                    )
                  : "-"}
              </Text>
            </View>
            <View style={{ flex: 0.3, alignItems: "center" }}>
              <TouchableOpacity
                style={{
                  borderRadius: 8,
                  backgroundColor: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
                onPress={() => this._handleCancel(PendingSwitches)}
              >
                <Text style={{ fontSize: 12, color: "gray" }}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        <SuccessOrFailureCard
          visible={this.state.showSuccessOrErrorFailureModal}
          status={this.state.responseStatus}
          title={this.state.responseStatus ? "Successful" : "Failure"}
          message={this.state.responseMessage}
          buttonOnPress={() => {
            this.setState({ showSuccessOrErrorFailureModal: false });
          }}
          buttonTitle="Done"
        />

        {this.state.dialogVisible && (
          <AlertCard
            visible={this.state.dialogVisible}
            close={false}
            positiveButtonText={this.state.alertPositiveButtonText}
            negativeButtonText={this.state.alertNegativeButtonText}
            positiveButtonPress={() => {
              this.state.alertPositiveButtonAction &&
                this.state.alertPositiveButtonAction();
            }}
            negativeButtonPress={() => {
              this.state.alertNegativeButtonAction &&
                this.state.alertNegativeButtonAction();
            }}
          >
            <Text
              style={{
                fontSize: 16,
                color: "#121212",
                fontWeight: "400",
                textAlign: "center",
                marginBottom: 20,
              }}
            >
              {this.state.alertTitle}
            </Text>
          </AlertCard>
        )}
      </View>
    );
  }
}
