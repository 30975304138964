import React, { useState, useEffect } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import { encryptToken, getUserDetails } from "../../../../utils";
import MySIPs from "../sips/mysips";
import MySubscription from "../../../upgradePlan/landing";
import RiskProfileHome from "../../riskprofile/home";
import AddAddress from "../AddAddress";
import AssetsAndLiabilities from "../AssetsAndLiabilities";
import EMandate from "../eMandate";
import NachMandate from "../NachMandate";
import ManageFamily from "../ManageFamily";
import InvestorCharter from "../InvestorCharter";
import InvestorComplaints from "../InvestorComplaints";
import ReportsHome from "../ReportsHome";
import AddNominee from "../AddNominee";
import AddPersonalDetails from "../AddPersonalDetails";
import AddProfessionalDetails from "../AddProfessionalDetails";
import BankAccounts from "../BankAccounts";
import Demat from "../../../Demat/LinkedDemat";
import RaiseTicket from "../RaiseTicket";
import ResetFinplan from "../ResetFinplan";
import SideNav from "./index";
import MyPassbook from "../passbook";
import ChangePassword from "../ChangePassword";
import Referrals from "../../../referrals";
import Membership from "../Membership";
import { MAIN_URL } from "../../../../api/urls";

import DeActivateAccount from "../DeActivateAccount";
import Store from "../../../../store";
const { width } = Dimensions.get("window");

const AddPanCardNav = (props) => {
  const [activeTab, setActiveTab] = useState("ManageFamily");
  const [url, setURL] = useState(null);

  useEffect(() => {
    const { params } = props.route;
    if (params) {
      const { active = "ManageFamily" } = params;
      setActiveTab(active);
    }

    let { token } = getUserDetails();
    const encryptedToken = encryptToken(token);
    let url =
      MAIN_URL +
      "/app/rnw_to_web_login?token=" +
      encryptedToken +
      "&is_rnw=1&redirection_url=/app/statements/";
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      url =
        MAIN_URL +
        "/app/rnw_to_web_login?token=" +
        encryptedToken +
        `&is_rnw=1&c_id=${
          Store.getState().dashboard.viewModeUserId.user_id
        }&redirection_url=/app/statements/`;
    }
    setURL(url);
  }, []);

  const handlePressTab = (
    screen,
    redirect = false,
    stack = "Account",
    moreDetails = null
  ) => {
    if (!redirect) {
      setActiveTab(screen);
    }

    if (width < 1200 || redirect) {
      props.navigation.push("App", {
        screen: stack,
        params: {
          screen: screen,
          params: {
            moreDetails,
            url,
          },
        },
      });
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.mainContainer}>
        <SideNav
          style={[styles.accountContainer]}
          onSelect={handlePressTab}
          activeTab={activeTab}
          navigation={props.navigation}
        />
        {/* {console.log("activated tab is", activeTab)} */}
        {width >= 1200 && (
          <View style={styles.screenContainer}>
            {activeTab == "AboutMe" && <AddPersonalDetails {...props} />}
            {activeTab == "AddProfessionalDetails" && (
              <AddProfessionalDetails {...props} />
            )}
            {activeTab == "AddNominee" && <AddNominee {...props} />}
            {activeTab == "AddAddress" && <AddAddress {...props} />}
            {activeTab == "AssetsAndLiabilities" && (
              <AssetsAndLiabilities {...props} />
            )}
            {activeTab == "eMandate" && <EMandate {...props} />}
            {activeTab == "NachMandate" && <NachMandate {...props} />}
            {activeTab == "ManageFamily" && <ManageFamily {...props} />}
            {activeTab == "BankAccounts" && <BankAccounts {...props} />}
            {activeTab == "Demat" && <Demat {...props} />}
            {activeTab == "RiskProfileHome" && <RiskProfileHome {...props} />}
            {activeTab == "ResetFinplan" && <ResetFinplan {...props} />}
            {activeTab == "MySubscription" && <MySubscription {...props} />}
            {activeTab == "MyPassbook" && <MyPassbook {...props} />}
            {activeTab == "MySIPs" && <MySIPs {...props} />}
            {activeTab == "Feedback" && <RaiseTicket {...props} />}
            {activeTab == "ChangePassword" && <ChangePassword {...props} />}
            {activeTab == "Referrals" && <Referrals {...props} />}
            {activeTab == "Membership" && <Membership {...props} />}
            {activeTab == "InvestorCharter" && <InvestorCharter {...props} />}
            {activeTab == "InvestorComplaints" && (
              <InvestorComplaints {...props} />
            )}
            {activeTab == "DeActivateAccount" && (
              <DeActivateAccount {...props} />
            )}
            {activeTab == "ReportsHome" && <ReportsHome url={url} {...props} />}
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  mainContainer: {
    flex: 1,
    flexDirection: "row",
  },
  accountContainer: {
    flex: 1,
    width: width < 1200 ? "100%" : "25%",
    maxWidth: width < 1200 ? "100%" : 350,
  },
  screenContainer: {
    flex: width < 1200 ? 0 : 1,
  },
});

export default AddPanCardNav;
