import { StackActions } from "@react-navigation/native";
import React, { useState } from "react";
import { StyleSheet, View, ScrollView, TouchableOpacity, Dimensions } from "react-native";
import { Text } from "react-native-paper";
import { sm, md, lg, xl, twoxl } from "../../Responsive";
import FormInputRow from "./formInputRow";

const window = Dimensions.get("window");

const coupon = {
    code: "MAX4022",
    price: 100
};

const nameErrorText = "enter valid name";
const numberErrorText = "enter valid phone number";
const emailErrorText = "enter valid email address";

export default function ContactForm(props,{ inModal }) {

    let forQuery = null;
    const params = props.props.route;
    if (params) {
        forQuery =params.params.for_query || false;
    }
    
    const [name, setName] = useState("");
    const [hasNameError, setHasNameError] = useState(false);
    const handleNameChange = input => {
        setName(input);
    };
    const isNameValid = text => {
        const regex = /^[a-zA-Z ]{2,40}$/;
        if (text.match(regex)) {
            return true;
        } else {
            return false;
        }
    };

    const [number, setNumber] = useState("");
    const [hasNumberError, setHasNumberError] = useState(false);
    const handleNumberChange = input => {
        setNumber(input);
    };
    const isNumberValid = number => {
        const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (number.match(phoneno)) {
            return true;
        } else {
            return false;
        }
    };

    const [email, setEmail] = useState("");
    const [hasEmailError, setHasEmailError] = useState(false);
    const handleEmailChange = input => {
        setEmail(input);
    };
    const isEmailValid = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const [message, setMessage] = useState("");
    const handleMessageChange = input => {
        setMessage(input);
    };

    const [showAllErrors, setShowAllErrors] = useState(false);

    const handleSubmit = () => {
        setShowAllErrors(true);
        if (
          !(
            hasNameError ||
            hasNumberError ||
            hasEmailError ||
            name.length === 0 ||
            number.length === 0 ||
            email.length === 0
          )
        ) {
            props.props.navigation.dispatch(
                StackActions.replace("MySubscriptionContactConformationPage", { for_query: forQuery })
            );
        }
    }; 

    return (
        <View
            style={[
            styles.container,
            { width: inModal ? 422 : undefined, height: inModal ? 498 : undefined }
            ]}
        >
            <View>
                <Text style={[styles.h2, { marginTop: inModal ? 0 : undefined }]}>
                    Contact Details
                </Text>
                <View
                    style={[
                    styles.formView,
                    {
                        marginTop: inModal ? 20 : undefined
                    }
                    ]}
                >
                    <FormInputRow
                        inModal={inModal}
                        label={"Name*"}
                        isMultiline={false}
                        type={"text"}
                        isValid={isNameValid}
                        errorText={nameErrorText}
                        value={name}
                        onChange={handleNameChange}
                        showAllErrors={showAllErrors}
                        hasError={hasNameError}
                        setHasError={setHasNameError}
                    ></FormInputRow>
                    <FormInputRow
                        inModal={inModal}
                        label={"Contact Number*"}
                        isMultiline={false}
                        type={"number"}
                        isValid={isNumberValid}
                        errorText={numberErrorText}
                        value={number}
                        onChange={handleNumberChange}
                        showAllErrors={showAllErrors}
                        hasError={hasNumberError}
                        setHasError={setHasNumberError}
                    ></FormInputRow>
                    <FormInputRow
                        inModal={inModal}
                        label={"Email Address*"}
                        isMultiline={false}
                        type={"email"}
                        isValid={isEmailValid}
                        errorText={emailErrorText}
                        value={email}
                        onChange={handleEmailChange}
                        showAllErrors={showAllErrors}
                        hasError={hasEmailError}
                        setHasError={setHasEmailError}
                    ></FormInputRow>
                    <FormInputRow
                        inModal={inModal}
                        label={"Your Message (optional)"}
                        isMultiline={true}
                        type={"text"}
                        errorText={""}
                        value={message}
                        onChange={handleMessageChange}
                        showAllErrors={showAllErrors}
                    ></FormInputRow>
                    <TouchableOpacity onPress={handleSubmit}>
                    <View
                        style={[
                        styles.buttonView,
                        { marginTop: inModal ? 0 : undefined }
                        ]}
                    >
                        <Text style={styles.buttonText}>Send</Text>
                    </View>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingLeft: md(window.width) ? 26 : 16,
        // marginTop: md(window.width) ? undefined: 40,
        paddingBottom: 30,
        paddingLeft: 16,
        paddingRight: 16
    },
    mainHeading: {
        marginTop: 30,
        marginBottom: 5,
        alignSelf: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 20,
        color: "#410DAA"
    },
    h2: {
        marginTop: 40,
        fontWeight: "bold",
        fontSize: 20,
        color: "black"
    },
    formView: {
        marginTop: 30
    },
    buttonText: {
        color: "white",
        fontWeight: "700",
        fontSize: 15,
        textAlign: "center"
    },
    buttonView: {
        backgroundColor: "#410DAA",
        paddingBottom: 17,
        paddingTop: 15,
        borderRadius: 5,
        marginTop: 10
    }
});
  
const imModalStyles = StyleSheet.create({});
  



