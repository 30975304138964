import React from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TextInput,
  TouchableOpacity,
  Dimensions,
  TouchableHighlight,
  Platform,
} from "react-native";
import { Contactus, getLoginBannerDetails } from "../../../api";
import Header from "../header_after_login";
import AntDesign from "@expo/vector-icons/AntDesign";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { ActivityIndicator } from "react-native-paper";
import { isJama, isMobile } from "../../../utils";
import Modal from "../../../components/Common/Modal";

const SuccessModal = ({ onPressDone }) => {
  return (
    <Modal
      style={[
        {
          justifyContent: "flex-start",
          alignItems: "center",
        },
      ]}
      containerStyle={[
        {
          marginTop: 200,
          marginHorizontal: 8,
          paddingHorizontal: 42,
          paddingRight: 20,
          paddingVertical: 15,
          alignSelf: "center",
        },
        Platform.OS == "web" && webStyles.webShadowBorder,
      ]}
    >
      <View style={{ maxWidth: isMobile ? 240 : 633 }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <AntDesign name="checkcircleo" size={18} color="#009105" />
          <Text
            style={{
              color: "#009105",
              fontSize: 18,
              marginLeft: 7,
              fontWeight: "bold",
            }}
          >
            Thank You
          </Text>
        </View>
        <Text style={{ color: "#121212", fontSize: 15, marginTop: 18 }}>
          Your query has been submitted successfully, our helpdesk team will get
          back to you shortly. Incase you experience any delay, please email us
          at help@maxiomwealth.com
        </Text>
        <TouchableOpacity
          onPress={() => {
            onPressDone && onPressDone();
          }}
          style={styles.buttonContainerSuccess}
        >
          <Text style={styles.com_nw}>Done</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

export default class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      email: null,
      mobile_number: null,
      name: null,
      message: null,
      nameError: null,
      emailError: null,
      mobile_numberError: null,
      bannerData: {
        logo_image_url: null,
        name: null,
        ria_code: null,
        subscriber_type: null,
        support_email: null,
        support_person_name: null,
        support_phone_number: null,
        is_jamawealth: null,
      },
    };
  }

  componentDidMount = async () => {
    let { bannerData } = this.state;
    let response = null;
    try {
      response = await getLoginBannerDetails();
    } catch (err) {}
    if (response && response.code == 100) {
      bannerData = response.result;
      this.setState({ bannerData: bannerData });
    } else {
      bannerData.logo_image_url =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/website/jam%C4%81_header.png";
      bannerData.name = "Jama Wealth";
      bannerData.ria_code = "INA 200015583";
      bannerData.subscriber_type = "RIAC";
      bannerData.support_email = "advisor@jamawealth.com";
      bannerData.support_person_name = "Ram Kalyan Medury";
      bannerData.support_phone_number = "9870264643";
      bannerData.is_jamawealth = true;
      this.setState({
        bannerData: bannerData,
        errorText: response.msg,
      });
    }
    this.props.navigation.setOptions({ headerShown: false });
    // this.props.navigation.closeDrawer();
    // this.props.navigation.getParent().setOptions({ drawerLockMode: 'locked-closed' });
  };

  handleContinue = async () => {
    try {
      let { name, email, mobile_number, message } = this.state;
      if (!name) {
        this.setState({ nameError: "Please Enter Your Name" });
        return;
      }
      if (!email) {
        this.setState({ emailError: "Please Enter Your Email Address" });
        return;
      }
      if (!mobile_number) {
        this.setState({
          mobile_numberError: "Please Enter Your Mobile Number",
        });
        return;
      }
      const data = {
        name: name,
        email: email,
        mobile_number: mobile_number,
        message,
        action: "contact_customer",
      };
      this.setState({ isLoading: true });
      const response = await Contactus(data);
      if (response && response.length != 0) {
        const data = response[0];
        if (data) {
          const { code } = data;
          if (code == 100) {
            this.setState({
              isLoading: false,
              error: false,
              showSuccess: true,
            });
          }
        }
        this.setState({ isLoading: false, error: true });
      } else {
        this.setState({ isLoading: false, error: true });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
      });
    }
  };

  clearIcon = () => {};

  render() {
    const {
      showSuccess,
      isLoading,
      name,
      email,
      mobile_number,
      message,
      nameError,
      emailError,
      mobile_numberError,
      bannerData,
    } = this.state;
    return (
      <ScrollView>
        <View style={styles.container}>
          <Header
            navigation={this.props.navigation}
            route={this.props.route}
            showDetails={false}
            bannerData={bannerData}
          />
          {showSuccess && (
            <SuccessModal
              onPressDone={() => {
                this.setState({ showSuccess: false });
              }}
            />
          )}
          <View style={{ alignItems: "center" }}>
            <View>
              <Text style={styles.help}>Help & Support</Text>
              <Text style={styles.stuck}>
                Stuck somewhere? We are always here to Help.
              </Text>
              <View style={styles.box}>
                <View style={styles.box_ins}>
                  <TextInput
                    value={name}
                    placeholder={"Name*"}
                    style={styles.inpt}
                    onChangeText={(text) => {
                      this.setState({ name: text });
                    }}
                  />
                  <Text style={styles.error}>{name ? "" : nameError}</Text>
                  <TextInput
                    value={email}
                    placeholder={"Email*"}
                    style={styles.inpt}
                    onChangeText={(text) => {
                      this.setState({ email: text });
                    }}
                  />
                  <Text style={styles.error}>{email ? "" : emailError}</Text>
                  <TextInput
                    value={mobile_number}
                    placeholder={"Contact No.*"}
                    style={styles.inpt}
                    onChangeText={(text) => {
                      this.setState({ mobile_number: text });
                    }}
                  />
                  <Text style={styles.error}>
                    {mobile_number ? "" : mobile_numberError}
                  </Text>
                  <TextInput
                    value={message}
                    placeholder={"Your Message (Optional)"}
                    style={[styles.inpt, { height: 120 }]}
                    multiline={true}
                    onChangeText={(text) => {
                      this.setState({ message: text });
                    }}
                  />
                  <View style={styles.buttonContainer}>
                    {isLoading ? (
                      <ActivityIndicator size="small" color="#410DAA" />
                    ) : (
                      <>
                        <TouchableOpacity
                          onPress={() => {
                            this.handleContinue();
                          }}
                          style={{ alignItems: "flex-start" }}
                        >
                          <Text style={styles.sub_now}>Submit Now</Text>
                        </TouchableOpacity>
                      </>
                    )}
                  </View>
                </View>
                <View style={styles.bottom}>
                  <TouchableOpacity style={{ flexDirection: "row" }}>
                    <MaterialIcons
                      name="headset"
                      size={18}
                      color="#410DAA"
                      style={styles.contactIcon}
                    />{" "}
                    <Text style={styles.contact}>
                      {bannerData.support_phone_number}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{ flexDirection: "row", paddingLeft: 15 }}
                  >
                    <MaterialIcons
                      name="mail-outline"
                      size={18}
                      color="#410DAA"
                      style={styles.contactIcon}
                    />{" "}
                    <Text style={styles.contact}>
                      {" "}
                      advisor@{isJama() ? "jamawealth" : "maxiomweatlh"}
                      {".com"}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }
}

const webStyles = {
  webShadowBorder: {},
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: "#ffffff",
    paddingBottom: 30,
  },
  help: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 22,
    lineHeight: 25,
    color: "#202020",
    letterSpacing: 0.16,
    paddingTop: !isMobile ? 55 : 20,
    textAlign: "left",
    paddingLeft: !isMobile ? 0 : 10,
  },
  stuck: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    color: "#202020",
    letterSpacing: 0.16,
    paddingTop: 15,
    paddingBottom: !isMobile ? 45 : 22,
    paddingLeft: !isMobile ? 0 : 10,
  },
  inpt: {
    borderWidth: 1,
    borderColor: "#E9E9E9",
    borderRadius: 4,
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#5A6671",
    paddingHorizontal: 10,
    paddingVertical: 13,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.3,
    marginBottom: 23,
  },
  box: {
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.1)",
    shadowRadius: 2,
    shadowColor: "rgba(56, 71, 109, 0.09)",
    borderRadius: 12,
    marginHorizontal: !isMobile ? 0 : 10,
    paddingTop: 32,
    width: !isMobile ? 688 : 0,
  },
  box_ins: {
    paddingHorizontal: !isMobile ? 121 : 10,
  },
  sub_now: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 34,
    paddingVertical: 6,
    paddingHorizontal: 31,
    backgroundColor: "#410DAA",
    borderRadius: 3,
    color: "#ffffff",
  },
  bottom: {
    flexDirection: "row",
    backgroundColor: "#F7F4FB",
    paddingVertical: 6,
    justifyContent: "center",
    alignItems: "center",
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  contact: {
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 24,
    color: "#202428",
  },
  contactIcon: {
    marginTop: 3,
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 86,
    marginTop: 23,
  },
  buttonContainerSuccess: {
    backgroundColor: "#410DAA",
    paddingHorizontal: isMobile ? 16 : 82,
    paddingVertical: isMobile ? 10 : 13,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 23,
    alignSelf: "center",
    width: isMobile ? "90%" : "auto",
  },
  com_nw: {
    fontFamily: "Roboto",
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: isMobile ? 12 : 15,
    textAlign: "center",
  },
});
