import axiosApiInstance from "./apiConfig";
import { URLS } from "./urls";

export const getHomeScreenData = async () => {
    const result = await axiosApiInstance.get(URLS.HOME_SCREEN_DATA);
    return result;
};

export const scheduleMeetingWithAdvisor = async (payload) => {
    const result = await axiosApiInstance.post(URLS.SCHEDULE_A_VISIT, payload);
    return result;
};