import { StackActions } from "@react-navigation/native";
import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import amplitude from "../utils/amplitude";
import { getCustomerDetails, showFamilyPopup } from "../api";
import { ShowToast } from "../components/Common/Toast";
import {
  completeMinDetails,
  getNavigationData,
  getUserDetails,
} from "../utils";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class EquityPlans extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    const { params } = this.props.route;
    // console.log("Paramater with sending viewmode : ",params)
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }
    this.state = {
      isLoading: true,
      userId,
      redirect: (params && params.redirect) ? params.redirect : false
    };
  }

  updateParams = (currentParams = {}, current) => {
    const { screen = null } = currentParams;
    if (screen == null) {
      return current;
    } else {
      currentParams["params"] = this.updateParams(
        currentParams["params"],
        current
      );
      return currentParams;
    }
  };

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("EquityPlans",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("EquityPlans", { path: this.props.navigation.getState().routeName });
      }
      let pushData = null;
      if (Platform.OS != "web") {
        pushData = await AsyncStorage.getItem("NAVIGATION");
      }
      let navRoot;
      let navStack;
      if (pushData) {
        const { name, params } = getNavigationData(pushData);
        navRoot = name;
        navStack = params;
      }
      let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
      let cPartnerBool = false;
      if (hostname.includes("cpartner")) {
        cPartnerBool = true;
      }

      const { userId } = this.state;
      const { customer_id } = await getUserDetails();
      let userIDToFetchData = userId ? userId : customer_id;
      const customerDetails = await getCustomerDetails(userIDToFetchData);
      const { is_first_time_agreement_signed } = customerDetails;
      const firstTimeUser = !is_first_time_agreement_signed;
      if (firstTimeUser) {
        const response = await completeMinDetails(false, userIDToFetchData, null, false);
        const { needCompletion, error } = response;
        if (!error) {
          const { redirect_screen } = response;
          let options = {};
          const { params } = this.props.route;
          if (!needCompletion) {
            let navigate_to = null;
            if (params) {
              if (params.nvg) {
                navigate_to = params.nvg;
              }
            }
            navigate_to =
              navigate_to || redirect_screen || "InvestmentOverview";
            if (params && params.redirect_url) {
              let url = new URL(global.location.href);
              // console.log(url, "1")
              location.replace(
                url.origin + params.redirect_url
              );
            }
            if (cPartnerBool) {
              this.props.navigation.dispatch(
                StackActions.replace("App", {
                  screen: "ViewMode",
                  params: {
                    screen: "CorporateDashboard",
                    params: {...this.props.route},
                  },
                })
              );
            } else {
              if (pushData) {
                AsyncStorage.removeItem("NAVIGATION");
                this.props.navigation.dispatch(
                  StackActions.replace(navRoot, navStack)
                );
              } else {
                this.props.navigation.navigate("App", {
                  screen: "Dashboard",
                  params: {
                    screen: "Dashboard",
                    params: { screen: "Home", params: { ...params, redirect: this.state.redirect } },
                  },
                });
              }
            }
            return;
          } else {
            options["first"] = true;
            options = { ...options, ...params };
          }
          if (params && params.redirect_url) {
            let url = new URL(global.location.href);
            // console.log(url, "2")
            location.replace(
              url.origin + params.redirect_url
            );
          }
          if (cPartnerBool) {
            this.props.navigation.dispatch(
              StackActions.replace("App", {
                screen: "ViewMode",
                params: {
                  screen: "CorporateDashboard",
                  params: {...this.props.route},
                },
              })
            );
          } else {
            if (pushData) {
              AsyncStorage.removeItem("NAVIGATION");
              this.props.navigation.dispatch(
                StackActions.replace(navRoot, navStack)
              );
            } else {
              this.props.navigation.dispatch(
                StackActions.replace("Onboarding", {
                  screen: redirect_screen,
                  params: { ...options },
                })
              );
            }
          }
        } else {
          this.setState({
            error: true,
            errorText: "Something went wrong! Please contact help@maxiom.in",
          });
        }
      } else {
        const { params } = this.props.route;
        const response = await showFamilyPopup();
        const options = {};
        if (response && response.code == 100) {
          const { show_pop_up } = response.result;
          if (show_pop_up) {
            options["f"] = show_pop_up;
          }
        }
        if (params && params.redirect_url) {
          let url = new URL(global.location.href);
          // console.log(url, "3")
          location.replace(
            url.origin + params.redirect_url
          );
        }
        if (cPartnerBool) {
          this.props.navigation.dispatch(
            StackActions.replace("App", {
              screen: "ViewMode",
              params: {
                screen: "CorporateDashboard",
                params: {...this.props.route},
              },
            })
          );
        } else {
          if (pushData) {
            AsyncStorage.removeItem("NAVIGATION");
            this.props.navigation.dispatch(
              StackActions.replace(navRoot, navStack)
            );
          } else {
            this.props.navigation.dispatch(
              StackActions.replace("App", {
                screen: "Dashboard",
                params: {
                  screen: "Dashboard",
                  params: {
                    screen: "Home",
                    params: { ...params, ...options, redirect: this.state.redirect },
                  },
                },
              })
              );
          }
        }
      }
    } catch (error) {
      console.log(error)
      this.setState({
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  render() {
    return (
      <View style={styles.mainContainer}>
        <ShowToast />
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#410DAA" />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
