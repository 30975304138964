import React from "react";
import { StyleSheet, View } from "react-native";
import { LOGOUT } from "../../../actions/types";
import LogoutLoading from "../../../components/Common/LogoutLoader";

import Header from "../login_header";
import Store from "../../../store";

const Logout = (props) => {
  // console.log("logout action", props);
  React.useEffect(() => {
    Store.dispatch({ type: LOGOUT });
  }, []);
  return (
    <View style={styles.container}>
      <Header navigation={props.navigation} route={props.route} />
      <View style={styles.body}>
        <LogoutLoading description="Logging out from broker session" />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#00000050",
  },
  body: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default Logout;
