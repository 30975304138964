import React from "react";
import {
  View,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  BackHandler,
  TouchableOpacity,
  FlatList,
  Dimensions,
  TextInput,
  Keyboard,
  ActivityIndicator,
  Platform,
} from "react-native";
import { Text, Card } from "react-native-paper";
import {
  SignUpUser,
  checkCustomerSignup,
  getQuizQnA,
  mobileOtpVerificationBeforeSignup,
  onboardingSendOTP,
  submitQuizQnA,
} from "../../../api";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { getUserDetails, isMobile } from "../../../utils";
import { StackActions } from "@react-navigation/native";
import { ShowToast } from "../../../components/Common/Toast";
const { width, height } = Dimensions.get("window");
import Toast from "react-native-toast-message";
import Store from "../../../store";

export default class NewQuizz extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let quiz_id = null;
    let retake = null;
    if (params) {
      quiz_id = params.quiz_id || null;
      retake = params.retake || null;
      quiz_id = isNaN(quiz_id) ? null : quiz_id;
    }
    this.state = {
      quiz_id,
      resultsArray: [],
      questionCount: -1,
      loading: true,
      answeredQnA: [],
      stage: 0,
      riskprofiletaken: false,
      riskprofile: {},
      submittingAnswer: false,
      data: [],
      showModal: false,
      showPersonalizedPicks: false,
      error: "",
      raw: "",
      answers: "",
      index: 0,
      showModalValue: retake ? false : true,
      name: "",
      email: "",
      mobile: "",
      otpError: "",
      otpNumber: "",
      nameError: "",
      emailError: "",
      mobileError: "",
      isLoading: false,
      errorText: "",
      showOTP: false,
      showResendOTP: false,
    };
  }

  async componentDidMount() {
    this.resetState();
    await this.getQuizQnA();
  }

  resetState = async () => {
    const { quiz_id = null } = this.state;
    this.setState(
      {
        quiz_id,
        resultsArray: [],
        questionCount: -1,
        loading: false,
        answeredQnA: [],
        stage: 0,
        riskprofiletaken: false,
        riskprofile: {},
        submittingAnswer: false,
        data: [],
        showModal: false,
        showPersonalizedPicks: false,
        error: "",
        raw: "",
        answers: "",
        index: 0,
      },
      () => {}
    );
  };

  getQuizQnA = async () => {
    this.setState({
      loading: false,
    });
    try {
      const { quiz_id = null } = this.state;
      if (quiz_id) {
        const payload = {
          quiz_id,
        };
        const response = await getQuizQnA(payload);
        this.setState({
          raw: response.quiz_data,
        });
        const data = [
          { isQuestion: true, content: response.quiz_data[0].question },
        ];
        this.setState({
          resultsArray: response.quiz_data,
          questionCount: response.quiz_data.length,
          loading: false,
          data: data,
          answeredQnA: [],
          stage: 0,
          error: "",
        });
      }
    } catch (err) {
      this.setState({
        loading: false,
        data: null,
      });
    }
    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      this.handleBackPress
    );
  };

  handleBackPress = () => {
    return true;
  };

  addToArray = (content, isQuestion) => {
    return [...this.state.data, { isQuestion, content }].filter((item) => item);
  };

  selectAnswer = async (option, q_index) => {
    try {
      if (this.state.index != q_index) {
        return;
      }
      let data = [
        ...this.state.data,
        {
          isQuestion: false,
          content: option.text,
        },
      ];

      data = [
        ...data,
        this.state.index + 1 < this.state.resultsArray.length && {
          isQuestion: true,
          content: this.state.resultsArray[this.state.index + 1].question,
        },
      ].filter((item) => item);

      let answers = this.state.answers + option.option_id + ",";

      if (this.state.index + 1 === this.state.resultsArray.length) {
        this.setState({
          loading: true,
          data,
        });
        answers = answers.split(",");
        answers.pop();
        const { quiz_id = null } = this.state;
        if (quiz_id) {
          const { customer_id } = getUserDetails();
          const payload = {
            quiz_id,
            options: answers.join(),
            cust_id: customer_id,
          };

          const response = await submitQuizQnA(payload);
          if (response && response.code == "100") {
            this.props.navigation.dispatch(
              StackActions.replace("Wellness", { quiz_id, show_toast: "true" })
            );
            this.resetState();
          } else {
            setTimeout(() => {
              this.setState({ submittingAnswer: false });
            }, 1000);
          }
          this.setState({ loading: false });
        }
      } else {
        this.setState({
          answers: answers,
          data,
          index:
            this.state.index + 1 < this.state.resultsArray.length
              ? this.state.index + 1
              : this.state.index,
          submittingAnswer: false,
        });
      }
    } catch (error) {}
  };

  goBacktoQuestion(questionNumber) {
    this.setState({ stage: questionNumber });
  }

  getQuestionsIndex = (content) => {
    const questions = this.state.data.filter((item) => item.isQuestion == true);
    const q_index = questions.findIndex(
      (item) => item.isQuestion === true && item.content == content
    );
    return q_index;
  };

  renderItem = ({ item }) => {
    if (item.isQuestion) {
      const q_index = this.getQuestionsIndex(item.content);
      return (
        <View style={styles.QnAContainer}>
          <View
            style={{
              backgroundColor: this.state.showModalValue ? "#b0b0b0" : "#fff",
              alignItems: "flex-start",
            }}
          >
            <Text style={styles.question}>{item.content}</Text>
          </View>
          {!this.state.submittingAnswer && (
            <>{this.renderOptions(item.content, q_index)}</>
          )}
        </View>
      );
    } else
      return (
        <View style={styles.answerContainer}>
          <Text style={styles.answer}>{item.content}</Text>
        </View>
      );
  };

  renderOptions = (q, q_index) => {
    const results = this.state.resultsArray.filter((item, index) => {
      return this.state.index == index;
    });
    if (results.length) {
      const { options, question, answer_name } = results[0];
      return (
        <>
          {q == question &&
            options &&
            options.map((ele, index) => {
              return this.state.showModalValue ? (
                <View
                  style={{
                    backgroundColor: this.state.showModalValue
                      ? "#b0b0b0"
                      : "#fff",
                    borderBottomWidth: 0.7,
                    borderBottomColor:
                      index < options.length - 1 ? "#cdcdcd" : "transparent",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: 10,
                  }}
                  onPress={() => {
                    this.selectAnswer(ele, q_index, 1);
                  }}
                >
                  <View
                    style={{
                      width: 16,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {answer_name == ele.text && (
                      <View
                        style={{
                          width: 4,
                          height: 4,
                          borderRadius: 2,
                          backgroundColor: "#1553A5",
                          marginTop: 8,
                        }}
                      ></View>
                    )}
                  </View>
                  <Text
                    style={{
                      color: answer_name == ele.text ? "#1553A5" : "#0077B6",
                      fontSize: 16,
                      flex: 0.9,
                    }}
                  >
                    {ele.text}
                  </Text>
                </View>
              ) : (
                <TouchableOpacity
                  style={{
                    backgroundColor: this.state.showModalValue
                      ? "#b0b0b0"
                      : "#fff",
                    borderBottomWidth: 0.7,
                    borderBottomColor:
                      index < options.length - 1 ? "#cdcdcd" : "transparent",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: 10,
                  }}
                  onPress={() => {
                    this.selectAnswer(ele, q_index, 1);
                  }}
                >
                  <View
                    style={{
                      width: 16,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {answer_name == ele.text && (
                      <View
                        style={{
                          width: 4,
                          height: 4,
                          borderRadius: 2,
                          backgroundColor: "#1553A5",
                          marginTop: 8,
                        }}
                      ></View>
                    )}
                  </View>
                  <Text
                    style={{
                      color: answer_name == ele.text ? "#1553A5" : "#0077B6",
                      fontSize: 16,
                      flex: 0.9,
                    }}
                  >
                    {ele.text}
                  </Text>
                </TouchableOpacity>
              );
            })}
        </>
      );
    } else {
      return null;
    }
  };

  static navigationOptions = () => ({
    tabBarVisible: false,
  });

  handleResend = async () => {
    this.setState({ showResendOTP: false });
    const { mobile } = this.state;
    const data = {
      username: mobile,
    };
    const response = await onboardingSendOTP(data);
    if (response && response.otp_generated_date) {
      this.setState({ showResendOTP: true });
    }
  };

  handleSendOTP = async () => {
    const { name, email, mobile } = this.state;
    let regEx =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    try {
      this.setState({ isLoading: true });
      if (!name) {
        this.setState({
          nameError: "Please enter a name",
          isLoading: false,
          emailError: "",
          mobileError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          nameError: "",
        });
      }
      if (!email || !regEx.test(email)) {
        this.setState({
          emailError: "Please enter a valid email",
          isLoading: false,
          nameError: "",
          mobileError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          emailError: "",
        });
      }
      if (!mobile) {
        this.setState({
          mobileError: "Please enter a valid mobile number",
          isLoading: false,
          nameError: "",
          emailError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          mobileError: "",
        });
      }

      let getOtpPayload = {
        target_mobile_number: mobile.toString(),
      };
      const mobileOtpResp = await mobileOtpVerificationBeforeSignup(
        getOtpPayload
      );
      if (
        mobileOtpResp &&
        mobileOtpResp.resp_code &&
        mobileOtpResp.resp_code == "100"
      ) {
        this.setState({ isLoading: false, showOTP: true });
      } else {
        this.setState({
          error: true,
          errorText: "Please enter a valid 10 digit mobile number",
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        errorText:
          response && response.message
            ? response.message
            : "Something went wrong!",
        error: true,
        isLoading: false,
      });
    }
  };

  handleVerifyOTP = async () => {
    const { mobile, otpNumber } = this.state;
    this.setState({ isLoading: true });
    try {
      if (!otpNumber) {
        this.setState({
          otpError: "Please enter a valid 6 digit otp",
          isLoading: false,
          errorText: "",
        });
        return;
      } else {
        this.setState({
          otpError: "",
        });
      }

      let getOtpPayload = {
        target_mobile_number: mobile.toString(),
        otp_value: otpNumber.toString(),
      };

      const mobileOtpResp = await mobileOtpVerificationBeforeSignup(
        getOtpPayload
      );
      if (mobileOtpResp && mobileOtpResp.result) {
        this.handleContinue();
      } else {
        this.setState({
          error: true,
          errorText:
            mobileOtpResp && mobileOtpResp.message
              ? mobileOtpResp.message
              : "Please enter the correct otp",
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    }
  };

  handleContinue = async () => {
    const { name, email, mobile, otpNumber } = this.state;
    let regEx =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    try {
      this.setState({ isLoading: true });
      if (!name) {
        this.setState({
          nameError: "Please enter a name",
          isLoading: false,
          emailError: "",
          mobileError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          nameError: "",
        });
      }
      if (!email || !regEx.test(email)) {
        this.setState({
          emailError: "Please enter a valid email",
          isLoading: false,
          nameError: "",
          mobileError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          emailError: "",
        });
      }
      if (!mobile) {
        this.setState({
          mobileError: "Please enter a valid mobile number",
          isLoading: false,
          nameError: "",
          emailError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          mobileError: "",
        });
      }

      const payload = {
        email: email.toString(),
        mobilenumber: mobile.toString(),
      };

      const checkSignUpResponse = await checkCustomerSignup(payload);
      let { user_database } = Store.getState().userData.userProfileData;
      if (checkSignUpResponse && checkSignUpResponse.code == 100) {
        let signupPayload = {
          first_name: name.toString(),
          username: mobile.toString(),
          email: email.toString(),
          mobilenumber: mobile.toString(),
          otp: otpNumber.toString(),
          database_host: Platform.OS === "web" ? global.location.hostname.replace(/\./g, "_") : user_database,
        };

        const response = await SignUpUser(signupPayload);
        if (response && response.pk != null) {
          let data = { ...response };
          Toast.hide();
          const loggedInTime = new Date().getTime();
          Store.dispatch({
            type: "GET_DASHBOARD",
            payload: {
              tokenResponse: {
                token: data.token,
              },
            },
          });
          this.setState({
            isLoading: false,
            showModalValue: false,
          });
        }
      } else {
        Toast.hide();
        Store.dispatch({
          type: "GET_DASHBOARD",
          payload: {
            tokenResponse: {
              token: checkSignUpResponse.results.token,
            },
          },
        });
        this.setState({
          isLoading: false,
          showModalValue: false,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    }
  };

  rednerOptions = () => {
    return this.state.resultsArray.map((item, index) => {
      if (this.state.index == index) {
        return (
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {item.options.map((ele, inde) => {
              return (
                <TouchableOpacity
                  style={[styles.QnAContainer]}
                  onPress={() => {
                    this.selectAnswer(ele, inde, 1);
                  }}
                >
                  <View
                    style={[
                      {
                        backgroundColor: this.state.showModalValue
                          ? "#b0b0b0"
                          : "#fff",
                        alignItems: "flex-start",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.question,
                        {
                          maxWidth: "100%",
                          marginRight: 8,
                          backgroundColor: "#d5d5d5",
                        },
                      ]}
                    >
                      {ele.text}
                    </Text>
                  </View>
                </TouchableOpacity>
              );
            })}
          </View>
        );
      }
    });
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  ConfirmAlert = () => {
    const {
      isLoading,
      errorText,
      name,
      email,
      mobile,
      nameError,
      emailError,
      mobileError,
      otpNumber,
      otpError,
    } = this.state;
    return (
      <View style={{ marginTop: 30 }}>
        <Card
          style={{
            paddingHorizontal: isMobile ? 16 : 42,
            paddingVertical: isMobile ? 16 : 24,
            flex: 1,
            alignSelf: "center",
            backgroundColor: "#fff",
            zIndex: 10,
            borderRadius: 10,
            borderRadius: 10,
            width: isMobile ? "90%" : "40%",
          }}
        >
          <View
            style={{
              marginBottom: 20,
            }}
          >
            <View
              style={{
                minWidth: "75%",
              }}
            >
              <View
                style={{
                  marginBottom: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: 18,
                    lineHeight: 18,
                    color: "#222225",
                    paddingBottom: 25,
                  }}
                >
                  To receive your assessment result, please fill this form
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: 14,
                    lineHeight: 18,
                    color: "#222225",
                    paddingBottom: 10.5,
                  }}
                >
                  Name
                </Text>
                <TextInput
                  value={name}
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    lineHeight: 16,
                    fontWeight: "bold",
                    color: "#121212",
                    backgroundColor: "#f6f6f6",
                    height: 41.02,
                    paddingLeft: 20,
                    borderRadius: 4,
                  }}
                  placeholder={"Enter your name"}
                  onFocus={() => {
                    this.setState({
                      nameError: "",
                      emailError: "",
                      mobileError: "",
                      errorText: "",
                    });
                  }}
                  onChangeText={(text) => {
                    if (text.length < 30) {
                      this.setState({ name: text });
                    } else {
                      this.setState({ name: text.substring(0, 30) });
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.nativeEvent.key == "Enter") {
                    }
                  }}
                />
                {!!nameError && (
                  <Text style={[styles.please, { color: "red" }]}>
                    {nameError || " "}
                  </Text>
                )}
              </View>
              <View
                style={{
                  marginBottom: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: 14,
                    lineHeight: 18,
                    color: "#222225",
                    paddingBottom: 10.5,
                  }}
                >
                  Email
                </Text>
                <TextInput
                  value={email}
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    lineHeight: 16,
                    fontWeight: "bold",
                    color: "#121212",
                    backgroundColor: "#f6f6f6",
                    height: 41.02,
                    paddingLeft: 20,
                    borderRadius: 4,
                  }}
                  placeholder={"Enter your email"}
                  onFocus={() => {
                    this.setState({
                      nameError: "",
                      emailError: "",
                      mobileError: "",
                      errorText: "",
                    });
                  }}
                  onChangeText={(text) => {
                    this.setState({ email: text });
                  }}
                  onKeyPress={(e) => {
                    if (e.nativeEvent.key == "Enter") {
                    }
                  }}
                />
                {!!emailError && (
                  <Text style={[styles.please, { color: "red" }]}>
                    {emailError || " "}
                  </Text>
                )}
              </View>
              <View
                style={{
                  marginBottom: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: 14,
                    lineHeight: 18,
                    color: "#222225",
                    paddingBottom: 10.5,
                  }}
                >
                  Mobile
                </Text>
                <TextInput
                  value={mobile}
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    lineHeight: 16,
                    fontWeight: "bold",
                    color: "#121212",
                    backgroundColor: "#f6f6f6",
                    height: 41.02,
                    paddingLeft: 20,
                    borderRadius: 4,
                  }}
                  placeholder={"Enter your mobile number"}
                  onFocus={() => {
                    this.setState({
                      nameError: "",
                      emailError: "",
                      mobileError: "",
                      errorText: "",
                    });
                  }}
                  onChangeText={(text) => {
                    if (!isNaN(text)) {
                      if (text.length < 16) {
                        this.setState({ mobile: text });
                      } else {
                        this.setState({ mobile: text.substring(0, 16) });
                      }
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.nativeEvent.key == "Enter") {
                    }
                  }}
                />
                {!!mobileError && (
                  <Text style={[styles.please, { color: "red" }]}>
                    {mobileError || " "}
                  </Text>
                )}
              </View>
              {this.state.mobile && this.state.showOTP && (
                <View
                  style={{
                    minWidth: "75%",
                  }}
                >
                  <View
                    style={{
                      marginBottom: 20,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                        fontSize: 14,
                        lineHeight: 18,
                        color: "#222225",
                        paddingBottom: 10.5,
                      }}
                    >
                      Enter OTP
                    </Text>
                    <TextInput
                      value={otpNumber}
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        lineHeight: 16,
                        fontWeight: "bold",
                        color: "#121212",
                        backgroundColor: "#f6f6f6",
                        height: 41.02,
                        paddingLeft: 20,
                        borderRadius: 4,
                      }}
                      placeholder={`Enter OTP sent on ${this.state.mobile}`}
                      onFocus={() => {
                        this.setState({
                          otpError: "",
                          errorText: "",
                        });
                      }}
                      onChangeText={(text) => {
                        const pattern = /^[0-9\b]+$/;
                        if (text.length <= 6 && pattern.test(text)) {
                          this.setState({ otpNumber: text });
                        } else {
                          text = text.slice(0, -1);
                          this.setState({ otpNumber: text });
                        }
                      }}
                      maxLength={6}
                      onKeyPress={(e) => {
                        if (e.nativeEvent.key == "Enter") {
                        }
                      }}
                    />
                    {!!otpError && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {otpError || " "}
                      </Text>
                    )}
                  </View>
                </View>
              )}
              {!!errorText && (
                <Text style={[styles.please, { color: "red" }]}>
                  {errorText || " "}
                </Text>
              )}
            </View>
            {isLoading ? (
              <ActivityIndicator
                size="large"
                color="#410DAA"
                style={{ paddingTop: 40 }}
              />
            ) : (
              <>
                {!this.state.showOTP ? (
                  <TouchableOpacity
                    onPress={() => {
                      Keyboard.dismiss();
                      this.handleSendOTP();
                    }}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: isMobile ? 20 : 0,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                        lineHeight: 17,
                        fontSize: 15,
                        color: "#ffffff",
                        paddingVertical: 12,
                        paddingHorizontal: 47,
                        backgroundColor: "#410DAA",
                        marginTop: 20,
                        borderRadius: 4,
                      }}
                    >
                      Send OTP
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <>
                    <TouchableOpacity
                      onPress={() => {
                        Keyboard.dismiss();
                        this.handleVerifyOTP();
                      }}
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 30,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "bold",
                          lineHeight: 17,
                          fontSize: 15,
                          color: "#ffffff",
                          paddingVertical: 12,
                          paddingHorizontal: 47,
                          backgroundColor: "#410DAA",
                          borderRadius: 4,
                          marginTop: -20,
                        }}
                      >
                        Verify OTP
                      </Text>
                    </TouchableOpacity>
                    <Text
                      style={{
                        color: "#232425",
                        fontSize: 15,
                        fontFamily: "Roboto",
                        marginTop: 18,
                        textAlign: "center",
                      }}
                    >
                      Did not receive an OTP?{" "}
                      <Text
                        style={{
                          color: "#410DAA",
                          fontWeight: "bold",
                          fontSize: 14,
                          paddingLeft: 6,
                          textAlign: "center",
                        }}
                        onPress={() => {
                          this.handleResend();
                        }}
                      >
                        RESEND OTP
                      </Text>
                    </Text>
                  </>
                )}
              </>
            )}
          </View>
        </Card>
      </View>
    );
  };

  _renderHeader = () => {
    return (
      <NavigationHeader
        navigation={this.props.navigation}
        navigationHeader={["Quiz"]}
        showBackIcon={false}
        onPressBack={() => {
          this.props.navigation.navigate("Login");
        }}
      />
    );
  };

  render() {
    return (
      <SafeAreaView style={styles.container}>
        {this._renderHeader()}
        {this.state.showOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent to your mobile number. Please verify in the box below"
          />
        ) : null}
        {this.state.showResendOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent to your mobile number. Please verify in the box below"
          />
        ) : null}
        {this.state.showModalValue && (
          <View
            style={{
              position: "absolute",
              width: width,
              height: height,
              width: "100%",
              top: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#00000050",
            }}
          >
            <this.ConfirmAlert />
          </View>
        )}
        <View style={{ maxWidth: 800 }}>
          {this.state.error.length > 0 && <Text>{this.state.error}</Text>}
          {this.state.resultsArray.length > 0 && !this.state.showModalValue && (
            <ScrollView>
              <View style={{ flex: 3, paddingBottom: 0 }}>
                <ScrollView
                  contentContainerStyle={{ flexGrow: 1, padding: 16 }}
                  ref={(ref) => (this.scrollView = ref)}
                  onContentSizeChange={() => {
                    this.scrollView.scrollToEnd({ animated: true });
                  }}
                >
                  <FlatList
                    data={this.state.data}
                    renderItem={this.renderItem}
                    extraData={this.state}
                  />
                </ScrollView>
                {this.state.submittingAnswer && (
                  <View style={styles.lottieVIewContainer}></View>
                )}
              </View>
            </ScrollView>
          )}
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    paddingBottom: 10,
  },
  QnAContainer: {
    marginVertical: 16,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: "#cdcdcd",
    maxWidth: "80%",
    overflow: "hidden",
  },
  question: {
    fontSize: 16,
    padding: 10,
  },

  answerContainer: {
    backgroundColor: "#0077B6",
    maxWidth: "80%",
    alignSelf: "flex-end",
    padding: 10,
    borderRadius: 8,
    borderBottomRightRadius: 0,
    marginBottom: 5,
  },
  answer: {
    color: "#fff",
    fontSize: 16,
  },
  lottieVIewContainer: {
    alignItems: "flex-start",
    marginBottom: 24,
    width: 100,
    height: 30,
    backgroundColor: "transparent",
  },
  optionsContainer: {
    flex: 2,
    borderTopWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  startOverText: {
    color: "#1c52a0",
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#1c52a0",
    paddingTop: 16,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    padding: 16,
  },
  modalContentContainer: {
    backgroundColor: "white",
    padding: 16,
    borderRadius: 4,
  },
});
