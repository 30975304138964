import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  Platform,
  Dimensions,
  TextInput,
  TouchableHighlight,
  SafeAreaView,
} from "react-native";
import { md } from "../../../Responsive";
import NavigationHeader from "../../Common/NavigationHeader";
import { addAnotherInstallment, getCIPInstallments } from "../../../api";
import { formatDate, isMobile, monthFullNames } from "../../../utils";
import { Octicons, FontAwesome } from "@expo/vector-icons";
import { DatePicker } from "../../Common/DatePicker";
import { ShowAlert } from "../../Common/ShowAlert";
import { RadioButton, Button } from "react-native-paper";
const { width } = Dimensions.get("window");
const isWeb = md(width);

const AddInstallment = (props) => {
  const [cipId, setCipId] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState(null);
  const [adding, setAdding] = useState(null);
  const [amount, setAmount] = useState(0);
  const [openPicker, setOpenPicker] = useState(false);
  const [date, setDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
   

  useEffect(() => {
    let { id } = props.route.params;
    id = id || null;
    setCipId(id);
    loadPlanData(id);
  }, []);

  const loadPlanData = async (id) => {
    try {
      const payload = {
        cip_pk: id,
      };
      const response = await getCIPInstallments(payload);
      if (response.code == 100) {
        let { result } = response;
        setData({
          plan: `${result.plan_name} - ${result.plan_type}`,
          plan_no: result.cip_pk,
          date: `${formatDate(result.plan_start_date)} - ${formatDate(
            result.plan_end_date
          )} (${result.plan_duration}Y)`,
        });
      } else {
        setError("Not able to load plan details!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Not able to load plan details!");
    }
  };

  const handleCancel = () => {
    setAlert(false);
  };

  const handleDone = () => {
    setAlert(false);
    props.navigation.navigate("ManageSchedule",{
      ...props.route.params
    });
  };

  const handleAmount = (amount) => {
    setErrorMsg(null);
    if (isNaN(amount)) return;
    setAmount(amount);
  };

  const handleOpenPicker = () => {
    setErrorMsg(null);
    setOpenPicker(true);
  };

  const validateValues = () => {
    if (!date) {
      setErrorMsg("Select a date for installment")
      return false;
    }
    if (!amount) {
      setErrorMsg("Enter amount for installment")
      return false;
    }
    if (amount <= 0) {
      setErrorMsg("Enter valid amount for installment")
      return false;
    }
    return true
  };

  const handleAddInstallment = async () => {
    try {
      const isValid = validateValues();
      if (!isValid) return;
      const data = {
        cip_pk: cipId,
        planned_date:
          date.getDate() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getFullYear(),
        planned_amount: Number(amount),
      };
      setAdding(true);
      const response = await addAnotherInstallment(data);
      if (response.code == 100) {
        setAdding(false);
        setAlert(true);
      } else {
        setAdding(false);
        setError("Something went wrong!");
      }
    } catch (error) {
      setAdding(false);
      setError("Something went wrong!");
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <NavigationHeader
        navigation={props.navigation}
        navigationHeader={[
          "Equity Plan",
          "My Equity Investment Plans",
          "Manage Schedule",
          "Add another Installment",
        ]}
        onPressBack={() => {
          props.navigation.navigate("MyEquityInvestmentPlans")
        }}
      />
      {loading && (
        <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color="#410DAA" />
        </View>
      )}
      {!!error && (
        <View style={styles.loaderContainer}>
          <Text style={{ fontSize: 16, color: "#121212" }}>{error}</Text>
        </View>
      )}
      {alert && (
        <ShowAlert
          title={"Success"}
          description={"Installment added successfully"}
          yesText={"Done"}
          noText={"Cancel"}
          onPressNo={handleCancel}
          onPressYes={handleDone}
          contentContainerStyle={{
            width: 300,
            height: 200,
            justifyContent: "center",
            paddingHorizontal: 30,
          }}
        />
      )}
      <View style={{ paddingLeft: 16, paddingRight: 16, maxWidth: 700 }}>
        {data && (
          <View>
            {openPicker && (
              <DatePicker
                setPlannedDate={(date) => {
                  if (date) {
                    date = new Date(date);
                    setDate(date);
                  }
                  setSelectedOption(1);
                  setOpenPicker(false);
                }}
                closedDatePicker={() => {
                  setSelectedOption(null);
                  setOpenPicker(false);
                }}
                validRange={{
                  startDate: new Date(),
                }}
                label={"Schedule Installment On"}
              />
            )}
            {isWeb && (
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  lineHeight: 18,
                  fontSize: 16,
                  color: "#121212",
                  paddingTop: 39,
                  paddingBottom: 17,
                }}
              >
                Add Installment in
              </Text>
            )}
            <View
              style={{
                backgroundColor: "rgba(249, 249, 249, 0.6)",
                borderWidth: 1,
                borderColor: "solid rgba(65, 13, 170, 0.2)",
                borderRadius: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                paddingVertical: isWeb ? 20 : 16,
                paddingHorizontal: isWeb ? 15 : 8,
                flexWrap: "wrap",
                marginTop: isWeb ? 0 : 20,
              }}
            >
              <View>
                <Text style={styles.pla}>{data.plan}</Text>
                <Text style={styles.dat}>({data.date})</Text>
              </View>
              <Text style={styles.plan_no}>Plan No: {data.plan_no}</Text>
            </View>
            <Text style={styles.how}>How much you would like to add?</Text>
            <View
              style={{
                borderBottomWidth: 1,
                borderBottomColor: "#D8D8D8",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text>₹ </Text>
                <TextInput
                  value={amount}
                  onChangeText={handleAmount}
                  style={[Platform.OS == "web" && { outline: "none" }]}
                  placeholder="Enter Amount"
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: isWeb ? "row" : "column",
                paddingTop: 20,
                flexWrap: "wrap",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: isWeb ? 45 : 0,
                  marginBottom: isWeb ? 0 : 20,
                }}
              >
                <RadioButton.Android
                  status={selectedOption == 0 ? "checked" : "unchecked"}
                  onPress={() => {
                    setErrorMsg(null);
                    setSelectedOption(0);
                    const date = new Date();
                    setDate(date);
                  }}
                  color={"#410DAA"}
                />
                <Text style={styles.place}>Place Installment today</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableHighlight
                  style={styles.radioButton}
                  onPress={() => {
                    setErrorMsg(null);
                    setSelectedOption(1);
                  }}
                >
                  <FontAwesome
                    name={selectedOption == 1 ? "check-circle" : "circle-o"}
                    size={18}
                    color={selectedOption == 1 ? "#410DAA" : "#C5C7CD"}
                  />
                </TouchableHighlight>
                <TouchableHighlight
                  onPress={handleOpenPicker}
                  style={{
                    minWidth: 150,
                    paddingBottom: 2,
                    paddingLeft: 10,
                    borderBottomColor: "#d8d8d8",
                    borderBottomWidth: 1,
                  }}
                >
                  <View
                    style={{ flexDirection: "row", alignItems: "flex-end" }}
                  >
                    <Octicons name="calendar" size={18} color="#410DAA" />
                    <Text
                      style={{
                        fontSize: 15,
                        color: "#494B69",
                        paddingLeft: 10,
                      }}
                    >
                      {selectedOption == 1
                        ? date
                          ? date.getDate() +
                            "-" +
                            monthFullNames[date.getMonth()]
                          : "Select Date"
                        : "Select Date"}
                    </Text>
                  </View>
                </TouchableHighlight>
              </View>
            </View>
            {!isMobile && (
              <Button
                mode="contained"
                uppercase={false}
                onPress={handleAddInstallment}
                color={"#410DAA"}
                style={{
                  width: 150,
                  marginTop: 30,
                }}
                loading={adding}
              >
                <Text style={{ fontSize: 14, color: "#fff" }}>
                  Add to Schedule
                </Text>
              </Button>
            )}
            {
              errorMsg ? (
                <Text style={{fontSize: 12, color: "red"}}>{errorMsg}</Text>
              ) : (null)
            }
          </View>
        )}
      </View>
      {isMobile && (
        <Button
          mode="contained"
          uppercase={false}
          onPress={handleAddInstallment}
          color={"#410DAA"}
          style={{
            width: "98%",
            marginLeft: "1%",
            position: "absolute",
            bottom: 66,
          }}
          loading={adding}
        >
          <Text style={{ fontSize: 14, color: "#fff" }}>Add to Schedule</Text>
        </Button>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  bodyContainer: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  pla: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 15,
    fontSize: 15,
    color: "#464E5F",
  },
  dat: {
    fontFamily: "Roboto",
    fontSize: 15,
    color: "#4A4B68",
    paddingTop: 13,
  },
  plan_no: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 14 : 12,
    color: "#888C9F",
  },
  box: {
    backgroundColor: "rgba(249, 249, 249, 0.6)",
    borderWidth: 1,
    borderColor: "solid rgba(65, 13, 170, 0.2)",
    borderRadius: 10,
    flexDirection: "row",
    paddingVertical: isWeb ? 20 : 16,
    paddingHorizontal: isWeb ? 15 : 8,
    marginTop: isWeb ? 0 : 20,
  },
  how: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 15 : 14,
    color: "#6C7293",
    paddingTop: 30,
    paddingBottom: 21,
  },
  text: {
    paddingLeft: 20,
  },
  place: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 14 : 13,
    color: "#252733",
    marginLeft: 10,
  },
});

export default AddInstallment;
