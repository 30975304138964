import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Animated,
} from "react-native";
import { isMobile } from "../../../utils";

export const TaggedMFStrip = ({
  percentage,
  style,
  slided,
  id,
  activeId,
  isLoading,
  index,
  onPressAttach,
  onPressDetach,
  error,
  SlideInLeft,
  data,
  entityId,
}) => {
  return (
    <View>
      <Animated.View
        style={[
          id == activeId
            ? {
                transform: [
                  {
                    translateX: SlideInLeft.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 1],
                    }),
                  },
                ],
              }
            : {},
          { backgroundColor: "#fff" },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingVertical: 5,
            ...style,
          }}
        >
          <Text style={{ color: "#121212", fontSize: 13, flex: 0.8 }}>
            {percentage} % is attached, {data.other_goal_perc}% attached to
            other goal(s).
          </Text>

          {!isMobile && (
            <View style={{ flex: 0.1, alignItems: "flex-end" }}>
              <TouchableOpacity
                onPress={() => {
                  onPressAttach();
                }}
                style={{
                  backgroundColor: slided ? "#410DAA" : "#fff",
                  borderColor: "#410DAA",
                  borderRadius: 6,
                  borderWidth: 1,
                }}
              >
                <View
                  style={{
                    paddingVertical: 5,
                    paddingHorizontal: 15,
                  }}
                >
                  <Text
                    style={{
                      color: slided ? "#fff" : "#410DAA",
                      fontSize: 10,
                    }}
                  >
                    Edit
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
          {!isMobile && (
            <View style={{ flex: 0.1, alignItems: "flex-end" }}>
              {isLoading ? (
                <ActivityIndicator
                  size="small"
                  color="#410DAA"
                  style={{ paddingRight: 15 }}
                />
              ) : percentage == "0" ? (
                <View
                  style={{
                    backgroundColor: "#fff",
                    borderColor: "gray",
                    borderRadius: 6,
                    borderWidth: 1,
                  }}
                >
                  <View
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 15,
                    }}
                  >
                    <Text
                      style={{
                        color: "gray",
                        fontSize: 10,
                      }}
                    >
                      Detach
                    </Text>
                  </View>
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    onPressDetach(percentage, entityId, index, data.fundType);
                  }}
                  style={{
                    backgroundColor: "#fff",
                    borderColor: "red",
                    borderRadius: 6,
                    borderWidth: 1,
                  }}
                >
                  <View
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 15,
                    }}
                  >
                    <Text
                      style={{
                        color: "red",
                        fontSize: 10,
                      }}
                    >
                      Detach
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          )}
        </View>
        {isMobile && (
          <View
            style={{
              alignItems: "flex-start",
              flexDirection: "row",
              marginLeft: 20,
            }}
          >
            <View>
              <TouchableOpacity
                onPress={() => {
                  onPressAttach();
                }}
                style={{
                  backgroundColor: slided ? "#410DAA" : "#fff",
                  borderColor: "#410DAA",
                  borderRadius: 6,
                  borderWidth: 1,
                }}
              >
                <View
                  style={{
                    paddingVertical: 5,
                    paddingHorizontal: 15,
                  }}
                >
                  <Text
                    style={{
                      color: slided ? "#fff" : "#410DAA",
                      fontSize: 10,
                    }}
                  >
                    Edit
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={{ marginLeft: 20 }}>
              {isLoading ? (
                <ActivityIndicator
                  size="small"
                  color="#410DAA"
                  style={{ paddingRight: 15 }}
                />
              ) : percentage == "0" ? (
                <View
                  style={{
                    backgroundColor: "#fff",
                    borderColor: "gray",
                    borderRadius: 6,
                    borderWidth: 1,
                  }}
                >
                  <View
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 15,
                    }}
                  >
                    <Text
                      style={{
                        color: "gray",
                        fontSize: 10,
                      }}
                    >
                      Detach
                    </Text>
                  </View>
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    onPressDetach(percentage, entityId, index, data.fundType);
                  }}
                  style={{
                    backgroundColor: "#fff",
                    borderColor: "red",
                    borderRadius: 6,
                    borderWidth: 1,
                  }}
                >
                  <View
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 15,
                    }}
                  >
                    <Text
                      style={{
                        color: "red",
                        fontSize: 10,
                      }}
                    >
                      Detach
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          </View>
        )}
        <Text style={{ fontSize: 12, color: "red", paddingHorizontal: 16 }}>
          {error || ""}
        </Text>
      </Animated.View>
    </View>
  );
};
