import React, { useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  RefreshControl,
  ScrollView,
  Image,
  Dimensions,
  Platform,
  Linking,
} from "react-native";
import { ActivityIndicator } from "react-native-paper";
import {
  clearNotification,
  getNotifications,
  clearAllNotifications,
  getTrackOrders,
  getAlertsCount,
} from "../../../api/alerts";
import theme from "../../../static/theme";
import {
  isMobile,
  months_short_names,
  formatDate,
  reverseString,
} from "../../../utils";
import EIcon from "@expo/vector-icons/Entypo";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { Button } from "react-native-paper";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import OrderCard from "./orderCard";
import { NotifTabs } from "./notifTabs";
import EmptyView from "../../../components/Common/emptyView";
import { md } from "../../../Responsive";
import { AntDesign } from "@expo/vector-icons";
import {
  getBsePaymentUrlForMfOrders,
  getDefaultTabToShowTransactionStatus,
  getInvestmentTransactionStatus,
  getRebalanceTransactionStatus,
  getUrlForClient2FA,
} from "../../../api";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../../api/apiConfig";
import { Picker } from "@react-native-picker/picker";
import { MAIN_URL } from "../../../api/urls";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import { ShowToast } from "../../../components/Common/Toast";
import moment from "moment";
import ProgressBar from "../../../utils/progressBar";

const window = Dimensions.get("window");
const { width, height } = window;

const TableHeader = ({ value, containerStyle = {}, style = {} }) => {
  return (
    <View style={containerStyle}>
      <Text
        style={[
          {
            fontSize: 12,
            color: "#B5B5C3",
            paddingHorizontal: 0,
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: 'nowrap',
          },
          style,
        ]}
      >
        {value}
      </Text>
    </View>
  );
};

const TableCell = ({ value = null, style = {}, children }) => {
  return (
    <View style={style}>
      {value != null && (
        <Text
          style={[
            {
              fontSize: 14,
              color: "#464E5F",
              paddingHorizontal: 16,
              textAlign: "center",
              whiteSpace: 'nowrap',
            },
            style,
          ]}
        >
          {value}
        </Text>
      )}
      {children}
    </View>
  );
};

const EmptyNotifComponent = () => {
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text style={{ fontSize: 15, color: "#929292", marginTop: 10 }}>
        No Notifications
      </Text>
    </View>
  );
};

const EmptyOrderComponent = () => {
  return (
    <View
      style={{
        width: "100%",
        height: height * 0.65,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text style={{ fontSize: 15, color: "#929292", marginTop: 10 }}>
        No Orders
      </Text>
    </View>
  );
};

const parseAmountRegEx = (text) => {
  let re = /Rs.\s*[+-]?\d+(\.\d+)?/g;
  let matchedAmount = text.match(re);
  let result_text = [
    {
      type: "normal",
      text,
    },
  ];

  if (matchedAmount && matchedAmount.length > 0) {
    result_text = [];
    let amount_old_format = matchedAmount[0];
    text = text.split(amount_old_format);
    result_text.push({
      type: "normal",
      text: text[0],
    });
    const amountReg = /[+-]?\d+(\.\d+)?/g;
    const amount = `${theme.variables.RUPEE_SYMBOL}${amount_old_format.match(
      amountReg
    )}`;

    result_text.push(
      {
        type: "bold",
        text: amount,
      },
      {
        type: "normal",
        text: text[1],
      }
    );
  }
  return result_text;
};

const parseStatus = (notification) => {
  let notification_unchange = notification;
  let notification_status = { icon: null, color: null };
  const STATUS_ICONS = {
    ATTENTION: { icon: "exclamationcircle", color: "#EFA512" },
    SUCCESS: { icon: "checkcircle", color: "green" },
    CANCEL: { icon: "closecircle", color: "#EF5D12" },
  };
  const STATUSES = [
    {
      text: "have been allotted",
      status: "ATTENTION",
    },
    {
      text: "is awaiting",
      status: "ATTENTION",
    },
    {
      text: "is pending",
      status: "ATTENTION",
    },
    {
      text: "Pl transfer",
      status: "ATTENTION",
    },
    {
      text: "Pay now",
      status: "ATTENTION",
    },
    {
      text: "Please pay",
      status: "ATTENTION",
    },
    {
      text: "working days to process",
      status: "ATTENTION",
    },
    {
      text: "pending payments",
      status: "ATTENTION",
    },
    {
      text: "Success!",
      status: "SUCCESS",
    },
    {
      text: "is cancelled successfully",
      status: "SUCCESS",
    },
    {
      text: "is cancelled at your request",
      status: "SUCCESS",
    },
    {
      text: "Pending Actions",
      status: "ATTENTION",
    },
  ];

  notification = notification.toLowerCase();

  STATUSES.forEach((status) => {
    let text = status["text"].toLowerCase();
    if (notification.indexOf(text) != -1) {
      const { color, icon } = STATUS_ICONS[status["status"]];
      notification_status["icon"] = icon;
      notification_status["color"] = color;
    }
  });
  notification_unchange = parseAmountRegEx(notification_unchange);
  return { ...notification_status, notification: notification_unchange };
};

const renderPendingComponent = (title, number, onPress) => {
  return (
    <TouchableOpacity
      onPress={() => {
        onPress && onPress();
      }}
    >
      <View
        style={{
          flexDirection: "row",
          margin: 16,
          justifyContent: "space-between",
        }}
      >
        <Text
          style={{
            margin: 10,
            fontSize: 13,
            fontWeight: "bold",
            color: "black",
          }}
        >
          {title}
        </Text>
        {number != 0 && (
          <Text
            style={{
              textAlign: "center",
              color: "black",
              fontSize: 13,
              fontWeight: "bold",
            }}
          >
            {number}
          </Text>
        )}
      </View>
      <EmptyView gap={0} />
    </TouchableOpacity>
  );
};

const NotificationItem = ({
  item,
  index,
  activeIndex,
  onPress,
  handleClearNotification,
}) => {
  if (item.mobile_app_status == "TBS" || item.mobile_app_status == "SNT") {
    let { created } = item;
    created = created.split("T");
    let date = created[0];
    let time = created[1];
    date = date.split("-");
    const year = date[0];
    const month = date[1];
    const day = date[2];
    const c_d = new Date();
    const c_month = c_d.getMonth() + 1;
    const c_year = c_d.getFullYear();
    let diff = c_d - day;
    if (c_year == year && month == c_month) {
      if (diff == 2) {
        date = " 2 days ago";
      } else if (diff == 1) {
        date = " 1 day ago";
      } else if ((diff = 0)) {
        const c_hour = c_d.getHours();
        const c_min = c_d.getMinutes();
        const c_sec = c_d.getSeconds();
        time = time.split(":");
        const hour = time[0];
        const min = time[1];
        const sec = time[2];
        if (hour == c_hour) {
          if (min == c_min) {
            date = `${c_sec - sec} seconds ago`;
          } else {
            date = `${c_min - min} minutes ago`;
          }
        } else {
          date = `${c_hour - hour} hours ago`;
        }
      } else {
        date = day + "-" + months_short_names(month) + "-" + year;
      }
    } else {
      date = day + "-" + months_short_names(month) + "-" + year;
    }

    let text = item.text;
    text = text.replace("b'", "");
    text = text.replace("'", "");
    text = text.replace("\\n", " ");
    text = text.replace("\n", " ");

    const { icon, color, notification } = parseStatus(text);

    return (
      <View style={{ flex: 1 }}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            padding: 8,
            alignItems: "flex-start",
          }}
        >
          <ANTIcon
            name={icon || "exclamationcircle"}
            color={color || "#EFA512"}
            size={16}
          />
          <View style={{ flex: 0.7, paddingLeft: 20 }}>
            <Text
              style={{
                marginBottom: 3,
                fontSize: 13,
                color: "#939393",
              }}
            >
              {notification.map((item) => {
                return (
                  <Text
                    style={[
                      item.type == "bold"
                        ? {
                          fontWeight: "bold",
                          color: "#121212",
                          fontSize: isMobile ? 14 : 13,
                        }
                        : { color: "gray", fontSize: isMobile ? 14 : 13 },
                    ]}
                  >
                    {item.text}
                  </Text>
                );
              })}
            </Text>
          </View>
          <View
            style={{
              flex: 0.3,
              alignItems: "flex-end",
              justifyContent: "flex-start",
              paddingRight: 8,
            }}
          >
            <EIcon
              name="dots-three-horizontal"
              color="#9AA3A8"
              size={24}
              onPress={() => {
                onPress(index);
              }}
            />
            {activeIndex == index && (
              <View
                style={{
                  position: "absolute",
                  right: 0,
                  borderWidth: 1,
                  borderColor: "gray",
                  top: 18,
                  zIndex: 10,
                  backgroundColor: "#fff",
                  width: 120,
                }}
              >
                <TouchableOpacity
                  style={{
                    backgroundColor: "#fff",
                    borderBottomColor: "gray",
                    borderBottomWidth: 0.7,
                  }}
                  onPress={handleClearNotification}
                >
                  <Text
                    style={{
                      fontSize: isMobile ? 12 : 13,
                      paddingVertical: 8,
                      paddingHorizontal: 25,
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    Clear
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ backgroundColor: "#fff" }}
                  onPress={() => {
                    onPress(null);
                  }}
                >
                  <Text
                    style={{
                      fontSize: isMobile ? 12 : 13,
                      paddingHorizontal: 25,
                      paddingVertical: 6,
                      textAlign: "center",
                      color: "#939393",
                    }}
                  >
                    Close
                  </Text>
                </TouchableOpacity>
              </View>
            )}
            <Text
              style={{
                color: "gray",
                fontSize: isMobile ? 14 : 13,
                marginTop: 10,
              }}
            >
              {date}
            </Text>
          </View>
        </View>
        <View
          style={{
            borderTopWidth: 0.4,
            marginVertical: 2,
            marginTop: isMobile ? 10 : 25,
            borderTopColor: theme.COLORAPP.DARKGRAY,
          }}
        />
      </View>
    );
  } else {
    return null;
  }
};

const Notifications = (props) => {
  const { params } = props.route;
  let userID = null;
  let activeTab = 1;

  if (params) {
    userID = params.user_id || null;
    activeTab = params.active ? params.active : 1;
    userID = isNaN(userID) ? null : userID;
    // console.log("Params is " + activeTab);
  }
  const ArrowBackIcon = require("../../../assets/JamaInvest/web_transaction_status.png");

  const [notifications, setNotifications] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [showToast, setShowToast] = React.useState("false");
  const [title, setTitle] = React.useState("");
  const [activeNotification, setActiveNotification] = React.useState(null);
  const [isRefreshing, setRefresh] = React.useState(false);
  const [orders, setOrders] = React.useState([]);
  const screenTabValue = "My Actions.";
  const [selectedTab, setSelectedTab] = React.useState(screenTabValue);
  const [alerts, setAlerts] = React.useState([]);
  const [data, setData] = React.useState(null);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [selectedPicker, setSelectedPicker] = React.useState("INV");
  const [userId] = React.useState(userID);
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState(null);
  const [filteredData, setFilteredData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showScreen, setShowScreen] = React.useState(false);
  const [club] = React.useState(true);

  if (params && params.showToast) {
    if (showToast != "true") {
      setShowToast(params.showToast);
      if (params.title) {
        setTitle(params.title);
      }
    }
  }

  const tabsToShow = [
    { label: "My Actions.", value: "My Actions." },
    { label: "Notifications", value: "Notif" },
  ];

  useEffect(async () => {
    setLoading(true);
    let loadResp = await getTrackOrders();
    let notifResp = await getNotifications();
    let alertsResp = await getAlertsCount();
    let txnResp = await getDefaultTabToShowTransactionStatus(userId);

    if (
      loadResp &&
      loadResp.code &&
      loadResp.code == 100 &&
      alertsResp &&
      alertsResp.code &&
      alertsResp.code == 100 &&
      txnResp &&
      txnResp.code &&
      txnResp.code == 100
    ) {
      const { results = [] } = loadResp;
      let defaultTabToShow;
      let transactionStatusResponse;
      const { type } = txnResp.result;
      defaultTabToShow = "INV";

      if (defaultTabToShow == "INV") {
        transactionStatusResponse = await getInvestmentTransactionStatus(
          userId
        );
      } else {
        transactionStatusResponse = await getRebalanceTransactionStatus(userId);
      }

      setSelectedPicker(defaultTabToShow);
      setDataValue(transactionStatusResponse);
      setOrders(results);
      setNotifications(notifResp);
      setAlerts(alertsResp);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
      getFilterData(tabIndex, true);
    }
  }, [data, tabIndex]);

  const handleRefresh = () => {
    setRefresh(true);
  };

  const setDataValue = (response) => {
    if (response && response.code && response.code == 100) {
      let { result } = response;
      if (result && result.orders_placed) {
        setData(result);
      } else {
        setError(true);
        setIsLoading(false);
        setErrorText("No transaction Found");
      }
    } else {
      setError(true);
      setIsLoading(false);
      setErrorText(
        response && response.msg ? response.msg : "Something went wrong!"
      );
    }
  };

  const getFilterData = (isFirstTimeLoad = false) => {
    let filteredData = [];
    if (data && data.stocks) {
      filteredData = [...data.stocks];
    }
    setFilteredData(filteredData);
    setTabIndex(0);
  };

  const renderTableHeader = () => {
    return (
      <View
        style={[
          {
            flexDirection: "row",
            paddingVertical: 11,
            backgroundColor: "#F3F6F9",
            borderRadius: 6,
            paddingLeft: 10,
            width: isMobile ? 1000 : "100%",
            flexWrap: "nowrap",
          },
        ]}
      >
        <TableHeader
          value={"Sock Name"}
          containerStyle={{ flex: 0.25, minWidth: 120 }}
          style={[{ color: "#464E5F", textAlign: "left",whiteSpace: 'nowrap' }]}
        />
        <TableHeader
          value={"Transaction Date"}
          containerStyle={{ flex: 0.15, minWidth: 120 }}
          style={[{ whiteSpace: 'nowrap' }]}
        />
        <TableHeader
          value={"Transaction Type"}
          containerStyle={{ flex: 0.15 , minWidth: 120 }}
          style={[{ whiteSpace: 'nowrap' }]}
        />
        <TableHeader value={"Suggested Qty"} containerStyle={{ flex: 0.15, minWidth: 100  }} style={[{ whiteSpace: 'nowrap' }]}/>
        <TableHeader
          value={"Traded Qty"}
          containerStyle={{ flex: 0.1, minWidth: 90 }}
          style={[{ paddingHorizontal: 16,whiteSpace: 'nowrap'}]}
        />
        <TableHeader
          value={"Pending Qty"}
          containerStyle={{ flex: 0.1, minWidth: 90 }}
          style={[{ paddingHorizontal: 16 ,whiteSpace: 'nowrap'}]}
        />
        <TableHeader value={"Status"} containerStyle={{ flex: 0.1, minWidth: 80}} style={[{ whiteSpace: 'nowrap' }]}/>
      </View>
    );
  };

  const renderSuggestionItem = ({ item }) => {
    const { txn_type, status } = item;
    const isBuy = txn_type != "S";
    const transactionTypeText = isBuy ? "Buy" : "Sell";
    const statusColor = status == "Fulfilled" ? "#009105" : "#DA222D";
    const statusText =
      status == "Fulfilled" ? "COMPLETE" : status.toUpperCase();
    const txnDate = formatDate(item.txn_date);
    return (
      <View
        style={[
          {
            width: isMobile ? 1000 : "100%",
            flexDirection: "row",
            paddingVertical: 15,
            paddingLeft: 10,
          },
        ]}
      >
        <TableCell style={[{ flex: 0.25 }]}>
          <Text
            style={{
              fontSize: 14,
              color: "#464E5F",
              fontWeight: "bold",
              marginBottom: 4,
            }}
          >
            {item.name}
          </Text>
          <Text style={{ color: "#9FA2B4", fontSize: 12, marginTop: 4 }}>
            {item.isin}
          </Text>
        </TableCell>
        <>
          <TableCell value={txnDate} style={[{ flex: 0.15 }]} />
          <TableCell style={[{ flex: 0.15 }]}>
            <View
              style={{
                backgroundColor: isBuy ? "#E8F9F8" : "#FFE2E5",
                borderRadius: 6,
                alignSelf: "center",
                paddingVertical: 7,
                paddingHorizontal: 15,
              }}
            >
              <Text
                style={{
                  color: isBuy ? "#1BC5BD" : "#F64E60",
                  fontSize: 12,
                  fontWeight: "bold",
                }}
              >
                {transactionTypeText}
              </Text>
            </View>
          </TableCell>
          <TableCell value={item.suggested_qty} style={[{ flex: 0.15 }]} />
        </>
        <TableCell
          value={item.traded_qty}
          style={[{ flex: 0.1, fontWeight: "normal" }]}
        />
        <TableCell
          value={item.pending_qty}
          style={[{ flex: 0.1, fontWeight: "normal" }]}
        />
        <TableCell
          value={statusText}
          style={[{ flex: 0.1, fontSize: 10, color: statusColor }]}
        />
      </View>
    );
  };

  const handleRetry = async () => {
    const { cai_hash } = data;
    let token = getTokenFromStore();
    token = reverseString(token);
    global.open(
      MAIN_URL +
      "/app/gwsc/retryorder/" +
      (cai_hash + token) +
      "/" +
      GATEWAY_ENVIRONMENT,
      "_self"
    );
  };

  const handlePickerChange = async (picker) => {
    setSelectedPicker(picker);
    setError(false);
    setIsLoading(true);
    setErrorText(null);

    let response;
    if (picker == "INV") {
      response = await getInvestmentTransactionStatus(userId);
    } else {
      response = await getRebalanceTransactionStatus(userId);
    }
    setDataValue(response);
  };

  const getIndianFormat = (str) => {
    str = str.split(".");
    return (
      str[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") +
      (str[1] ? "." + str[1] : "")
    );
  };

  const roundToDecimals = (value, decimal) => {
    return getIndianFormat(value.toFixed(decimal));
  };

  const handleClearNotification = async (pushnotification_id) => {
    try {
      const payload = {
        notification_id: pushnotification_id,
      };
      const response = await clearNotification(payload);
      if (response.code == 100) {
        const updated_notifs = [...notifications].filter(
          (i) => i.pushnotification_id != pushnotification_id
        );
        setNotifications(updated_notifs);
        setActiveNotification(null);
      }
    } catch (error) {}
  };

  const _renderOrderItem = ({ item }) => {
    return <OrderCard item={item} navigation={props.navigation} />;
  };

  const handlePressBack = () => {
    if (showScreen) {
      setSelectedTab("My Actions.");
      setShowScreen(false);
    } else {
      props.navigation.navigate("Home", {
        name: "Dashboard",
      });
    }
  };

  const _renderNotificationView = ({ item, index }) => {
    return (
      <NotificationItem
        item={item}
        index={index}
        activeIndex={activeNotification}
        onPress={(i) => {
          setActiveNotification(i);
        }}
        handleClearNotification={() =>
          handleClearNotification(item.pushnotification_id)
        }
      />
    );
  };

  const onSelectTab = (selectedTab) => {
    setSelectedTab(tabsToShow[selectedTab].label);
  };

  const handleClearAllNotifications = async () => {
    try {
      setLoading(true);
      const response = await clearAllNotifications();
      if (response && response.hasOwnProperty("is_change")) {
        setNotifications([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleMFOrderOTP = async () => {
    const response = await getUrlForClient2FA();
    if (response && response.code == 100) {
      const url = response.result;
      if (Platform.OS === "web") {
        global.open(url, "_blank");
      } else {
        Linking.openURL(url);
      }
    }
  };

  const handleMFOrderPayment = async () => {
    try {
      const key = moment().unix();
      const payload = {
        redirect_url:
          (Platform.OS == "web"
            ? global.location.origin
            : "https://jamawealth.page.link/Home") +
          "/dashboard/process/" +
          key,
      };
      const response = await getBsePaymentUrlForMfOrders(payload);
      if (response && response.code == 100) {
        const url = response.result;
        if (Platform.OS === "web") {
          global.open(url, "_blank");
        } else {
          Linking.openURL(url);
        }
      }
    } catch (e) {
      console.log("Error in handleMFOrderPayment", e);
    }
  };


  let is_club = false;
  // Get the hostname based on the platform
  const hostname = Platform.OS === 'web' ? global.location.hostname : 'mobile';
  // let hostname = "club.jamawealth.com";
  if (hostname.includes('club')) {
    is_club = true;
  }


  return (
    <>
      {showToast == "true" ? (
        <ShowToast showToast={true} type="status" title={title} />
      ) : null}
      {showScreen && selectedTab == "Mutual Funds Orders" && (
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "white",
            padding: 15,
          }}
        >
          <Text
            style={{
              width: "50%",
              color: "#410DAA",
              fontWeight: "500",
              textDecorationLine: "underline",
            }}
            onPress={handleMFOrderOTP}
          >
            Approve MF Order OTPs
          </Text>
          <Text
            style={{
              width: "50%",
              color: "#410DAA",
              fontWeight: "500",
              textDecorationLine: "underline",
            }}
            onPress={handleMFOrderPayment}
          >
            Make Payment for MF Orders
          </Text>
        </View>
      )}
      {!showScreen ? (
        <NavigationHeader
          navigation={props.navigation}
          navigationHeader={["Alerts"]}
          onPressBack={handlePressBack}
        />
      ) : (
        <NavigationHeader
          navigation={props.navigation}
          navigationHeader={[selectedTab]}
          onPressBack={handlePressBack}
        />
      )}
      {loading ? (
        <View style={styles.loadingContainer}>
          <ProgressBar msg={"Loading Alerts..."} />
        </View>
      ) : (
        <>
          {!showScreen && (
            <NotifTabs
              tabs={tabsToShow}
              active={Number(activeTab) - 1}
              onSelectTab={(data) => onSelectTab(data)}
              data={{
                Orders: orders.length >= 1 ? orders.length : "0",
                Pending:
                  alerts && alerts.results
                    ? alerts.results.pending.total_count
                    : "0",
                Notif: notifications.length >= 1 ? notifications.length : "0",
              }}
            />
          )}
          {selectedTab == "My Actions." && (
            <ScrollView
              style={[
                styles.container,
                {
                  paddingRight: isMobile ? "0%" : "20%",
                },
              ]}
            >
              {renderPendingComponent("Advisory Action Items", 0, () => {
                props.navigation.navigate("App", {
                  screen: "ViewMode",
                  params: {
                    screen: "ToDoHome",
                    params: {},
                  },
                });
              })}
              {!is_club && (
                renderPendingComponent("Recent Stock Transactions", 0, () => {
                  setSelectedTab("Stock Transactions");
                  setShowScreen(true);
                })
              )}
              {renderPendingComponent(
                "Recent Mutual Fund Transactions",
                orders.length >= 1 ? orders.length : "0",
                () => {
                  setSelectedTab("Mutual Funds Orders");
                  setShowScreen(true);
                }
              )}
              {alerts.results && alerts.results.pending && (
                <View>
                  {alerts.results.pending.pending_payments_count == 0
                    ? null
                    : renderPendingComponent(
                      "Complete Attempted Payments",
                      alerts.results.pending.pending_payments_count,
                      () => props.navigation.navigate("PendingPayments")
                    )}
                  {alerts.results.pending.mandates_count == 0
                    ? null
                    : renderPendingComponent(
                      "Complete Attempted Mandates",
                      alerts.results.pending.mandates_count,
                      () => props.navigation.navigate("MandatesPending")
                    )}
                  {alerts.results.pending.family_approval_count == 0
                    ? null
                    : renderPendingComponent(
                      "Manage Family Members",
                      alerts.results.pending.family_approval_count,
                      () => props.navigation.navigate("FamilyPending")
                    )}
                  {!is_club && (
                    alerts.results.pending.billing_items_count === 0 ? null : (
                      renderPendingComponent(
                        "Clear Pending Bills",
                        alerts.results.pending.billing_items_count,
                        () => {
                          props.navigation.navigate("Onboarding", {
                            screen: "Payment",
                            params: null,
                          });
                        }
                      )
                    )
                  )}

                  {alerts.results.pending.switchouts_count == 0
                    ? null
                    : renderPendingComponent(
                      "Complete Attempted Switches",
                      alerts.results.pending.switchouts_count,
                      () => props.navigation.navigate("PendingSwitches")
                    )}
                  {alerts.results.pending.attempted_orders_count == 0
                    ? null
                    : renderPendingComponent(
                      "Complete Attempted Investments",
                      alerts.results.pending.attempted_orders_count,
                      () => props.navigation.navigate("PendingInvestments")
                    )}
                </View>
              )}
            </ScrollView>
          )}
          {selectedTab == "Mutual Funds Orders" && (
            <View style={styles.container}>
              <View style={styles.mainContainer}>
                <FlatList
                  data={orders}
                  showsVerticalScrollIndicator={true}
                  renderItem={_renderOrderItem}
                  keyExtractor={(item, index) => index.toString()}
                  contentContainerStyle={{
                    paddingBottom: 50,
                  }}
                  ListEmptyComponent={<EmptyOrderComponent />}
                  refreshControl={
                    <RefreshControl
                      refreshing={isRefreshing}
                      onRefresh={handleRefresh}
                      colors={["#410DAA"]}
                    />
                  }
                  initialNumToRender={6}
                  maxToRenderPerBatch={10}
                  windowSize={10}
                />
              </View>
            </View>
          )}
          {(selectedTab == "Notif" || selectedTab == "Notifications") && (
            <View style={[styles.container, { paddingTop: 30 }]}>
              {loading ? (
                <View style={styles.loadingContainer}>
                  <ProgressBar msg={"Loading Notifications..."} />
                </View>
              ) : (
                <ScrollView>
                  <View
                    style={{
                      width: isMobile ? "90%" : "60%",
                      marginLeft: isMobile ? "5%" : "20%",
                      marginRight: isMobile ? "5%" : "20%",
                    }}
                  >
                    {!!notifications.length && (
                      <View style={styles.clearAllContainer}>
                        <Button
                          uppercase={false}
                          mode={"outline"}
                          onPress={handleClearAllNotifications}
                          style={{
                            width: 130,
                            alignItems: "flex-end",
                            marginTop: -10,
                          }}
                          color={"#410DAA"}
                        >
                          <Text style={styles.clear}>Clear All</Text>
                        </Button>
                      </View>
                    )}
                    <FlatList
                      data={notifications}
                      renderItem={_renderNotificationView}
                      keyExtractor={(item, index) => index.toString()}
                      ListEmptyComponent={<EmptyNotifComponent />}
                      contentContainerStyle={{
                        paddingBottom: 50,
                      }}
                      refreshControl={
                        <RefreshControl
                          refreshing={isRefreshing}
                          onRefresh={handleRefresh}
                          colors={["#410DAA"]}
                        />
                      }
                      initialNumToRender={6}
                      maxToRenderPerBatch={10}
                      windowSize={10}
                    />
                  </View>
                </ScrollView>
              )}
            </View>
          )}
          {selectedTab == "Stock Transactions" &&
            (error || isLoading ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                }}
              >
                <View style={{ flex: 1, maxWidth: 1500 }}>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      maxWidth: 1300,
                      alignItems: "center",
                    }}
                  >
                    {isLoading && (
                      <ProgressBar msg={"Loading Stock Transactions..."} />
                    )}
                    {!!errorText && (
                      <>
                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          {/* <Text>
                            {selectedPicker == "REB"
                              ? "Rebalancing"
                              : "Investment"}
                          </Text> */}
                          <PickerFieldSelect
                            data={[
                              {
                                label: "Rebalance",
                                value: "REB",
                                key: 1,
                              },
                              {
                                label: "Investment",
                                value: "INV",
                                key: 2,
                              },
                            ]}
                            showSelect={false}
                            value={selectedPicker}
                            label={null}
                            onChange={(itemValue) =>
                              handlePickerChange(itemValue)
                            }
                            type="select"
                            placeholder={{ value: "SEL", label: "Select" }}
                          />
                        </View>
                        {/* <Picker
                          selectedValue={selectedPicker}
                          onValueChange={(itemValue) => {
                            handlePickerChange(itemValue);
                          }}
                          style={{
                            borderColor: "#00000010",
                            borderRadius: 3,
                            borderWidth: 1,
                            color: "#888C9F",
                          }}
                        >
                          <Picker.Item label="Rebalance" value="REB" />
                          <Picker.Item label="Investment" value="INV" />
                        </Picker> */}
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#121212",
                            marginTop: 20,
                          }}
                        >
                          {errorText || "Something went wrong!"}
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#121212",
                            marginTop: 20,
                          }}
                        >
                          Recent transaction? Allow 30 minutes for reflection
                          here.
                        </Text>
                      </>
                    )}
                  </View>
                </View>
              </View>
            ) : (
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  backgroundColor: "#fff",
                }}
              >
                {data ? (
                  <ScrollView style={[styles.mainContainer2]}>
                    <View>
                      <View
                        style={{
                          flex: 1,
                          marginBottom: 31,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                      >
                        <View>
                          <Text style={styles.portfolioValueText}>
                            Your Transaction:{" "}
                            <Text style={styles.portfolioValueAmount}>
                              ₹{" "}
                              {roundToDecimals(
                                Math.abs(data.total_transaction_amount),
                                0
                              )}
                            </Text>
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginTop: 11,
                            }}
                          >
                            {!!data.suggested_transaction_amount && (
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  borderRightWidth: 1,
                                  borderRightColor: "#00000010",
                                  paddingRight: 11,
                                }}
                              >
                                <View
                                  style={{
                                    width: 9,
                                    height: 9,
                                    backgroundColor: "#FFA800",
                                    borderRadius: 2,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: isMobile ? 11 : 14,
                                    color: "#121212",
                                    fontFamily: "Roboto",
                                    marginLeft: 6,
                                  }}
                                >
                                  Planned
                                </Text>
                                <Text
                                  style={{
                                    fontSize: isMobile ? 11 : 14,
                                    color: "#410DAA",
                                    fontFamily: "Roboto",
                                    marginLeft: 6,
                                  }}
                                >
                                  ₹
                                  {roundToDecimals(
                                    data.suggested_transaction_amount,
                                    0
                                  )}
                                </Text>
                              </View>
                            )}
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginLeft: 14,
                              }}
                            >
                              <View
                                style={{
                                  width: 9,
                                  height: 9,
                                  backgroundColor: "#009105",
                                  borderRadius: 2,
                                }}
                              />
                              <Text
                                style={{
                                  fontSize: isMobile ? 11 : 14,
                                  color: "#121212",
                                  fontFamily: "Roboto",
                                  marginLeft: 6,
                                }}
                              >
                                Transacted
                              </Text>
                              <Text
                                style={{
                                  fontSize: isMobile ? 11 : 14,
                                  color: "#410DAA",
                                  fontFamily: "Roboto",
                                  marginLeft: 6,
                                }}
                              >
                                ₹
                                {roundToDecimals(
                                  Math.abs(data.total_transaction_amount),
                                  0
                                )}
                              </Text>
                            </View>
                          </View>
                        </View>
                        {!isMobile && (
                          <PickerFieldSelect
                            data={[
                              {
                                label: "Rebalance",
                                value: "REB",
                                key: 1,
                              },
                              {
                                label: "Investment",
                                value: "INV",
                                key: 2,
                              },
                            ]}
                            value={selectedPicker}
                            label={null}
                            onChange={(itemValue) =>
                              handlePickerChange(itemValue)
                            }
                            type="select"
                            placeholder={{ value: "SEL", label: "Select" }}
                          />
                        )}
                      </View>
                      {isMobile && (
                        <PickerFieldSelect
                          data={[
                            {
                              label: "Rebalance",
                              value: "REB",
                              key: 1,
                            },
                            {
                              label: "Investment",
                              value: "INV",
                              key: 2,
                            },
                          ]}
                          value={selectedPicker}
                          label={null}
                          onChange={(itemValue) =>
                            handlePickerChange(itemValue)
                          }
                          style={{ marginBottom: 20 }}
                          type="select"
                          placeholder={{ value: "SEL", label: "Select" }}
                        />
                      )}
                      {data.pending_transactions != 0 && (
                        <View
                          style={{
                            flex: 1,
                            flexDirection: md(window.width) ? "row" : "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "#8950FC10",
                            borderRadius: 12,
                            paddingHorizontal: 12,
                            marginBottom: 40,
                            paddingVertical: 8,
                          }}
                        >
                          <View style={styles.tableSummary}>
                            <Image
                              source={ArrowBackIcon}
                              style={{
                                width: 43,
                                height: 37,
                                marginRight: 8,
                              }}
                            ></Image>
                            <View>
                              <Text style={styles.completeTitle}>
                                Complete your pending Transactions
                              </Text>
                              <Text style={styles.pendingStocksList}>
                                {data.pending_transactions} Stocks
                              </Text>
                            </View>
                          </View>
                          <View style={styles.retryOrdersButtonContainer}>
                            <TouchableOpacity
                              onPress={handleRetry}
                              underlayColor="white"
                              style={{ marginRight: 40 }}
                            >
                              <View style={styles.retryButton}>
                                <Text style={styles.retryButtonText}>
                                  {data.transaction_button_text}
                                </Text>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      )}
                      <ScrollView
                        horizontal={true}
                        contentContainerStyle={{ flexGrow: 1 }}
                        alwaysBounceVertical={true}
                      // style={{marginBottom}}
                      >
                        <FlatList
                          data={filteredData}
                          renderItem={renderSuggestionItem}
                          keyExtractor={(item, index) => `suggestion_${index}`}
                          ListHeaderComponent={renderTableHeader}
                          style={{
                            paddingBottom: 15,
                            marginTop: 5,
                          }}
                        />
                      </ScrollView>
                    </View>
                  </ScrollView>
                ) : (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ProgressBar msg={"Loading..."} />
                  </View>
                )}
              </View>
            ))}
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  clearAllContainer: {
    alignItems: "flex-end",
  },
  clear: {
    fontSize: 15,
    color: "red",
    alignSelf: "flex-end",
  },
  mainContainer: {
    paddingLeft: isMobile ? 8 : 64,
    paddingRight: isMobile ? 8 : 64,
    paddingTop: 20,
    height: height,
    paddingBottom: isMobile ? 200 : 150,
  },
  mainContainer2: {
    backgroundColor: "#FFFFFF",
    paddingLeft: md(window.width) ? 52 : 0,
    paddingRight: md(window.width) ? 52 : 0,
    paddingTop: 30,
    maxWidth: 1300,
  },
  confirmButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    justifyContent: "space-around",
    flex: 1,
  },
  confirmButton: {
    paddingHorizontal: 20,
    paddingVertical: 6,
    borderRadius: 6,
    alignItems: "center",
    flex: 1,
    marginHorizontal: 10,
  },
  confirmButton2: {
    paddingTop: 6,
    borderRadius: 6,
    width: "100%",
  },
  confirmButtonNo: {
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
  },
  noText: {
    color: "#410DAA",
    fontSize: 15,
  },
  confirmButtonYes: {
    backgroundColor: "#410DAA",
  },
  yesText: {
    color: "#fff",
    fontSize: 15,
  },
  border: {
    borderColor: "#968E8E40",
    borderWidth: 1,
    borderRadius: 4,
    overflow: "hidden",
    borderTopColor: "#410DAA",
    borderTopWidth: 4,
  },
  retryOrdersButtonContainer: {
    paddingVertical: md(window.width) ? 0 : 35,
  },
  retryButton: {
    paddingHorizontal: md(window.width) ? 50 : 35,
    paddingVertical: md(window.width) ? 11 : 9,
    backgroundColor: "#410DAA",
    marginVertical: 2,
    borderRadius: 6,
    width: "100%",
    alignSelf: "flex-end",
  },
  retryButtonText: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#fff",
    textAlign: "center",
  },
  tableSummary: {
    flexDirection: "row",
    paddingVertical: md(window.width) ? 19 : 8,
    flex: md(window.width) ? 0.7 : 1,
  },
  amountContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  completeTitle: {
    fontSize: md(window.width) ? 16 : 15,
    fontWeight: "bold",
    color: "#212121",
  },
  amount: {
    fontSize: 17,
    fontWeight: "700",
    color: "#410DAA",
    fontStyle: "italic",
    paddingLeft: 5,
  },
  pendingStocksList: {
    paddingTop: 10,
    fontSize: md(window.width) ? 14 : 12,
    color: "#410DAA",
  },
  button: {
    alignItems: "center",
    backgroundColor: "#410DAA",
    marginVertical: 2,
    borderRadius: 6,
  },
  button2: {
    alignItems: "center",
    backgroundColor: "#410DAA",
    marginVertical: 2,
  },
  buttonText: {
    textAlign: "center",
    paddingHorizontal: 30,
    paddingVertical: 12,
    color: "white",
    fontWeight: "700",
    fontSize: 14,
  },

  portfolioValueText: {
    fontSize: md(width) ? 18 : 14,
    color: "#212121",
  },

  portfolioValueAmount: {
    fontSize: 16,
    color: "#410DAA",
    fontStyle: "italic",
    paddingLeft: 5,
    fontWeight: "700",
  },

  circle1: {
    width: 16,
    height: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#410DAA",
    alignItems: "center",
    justifyContent: "center",
  },
  circle2: {
    width: 10,
    height: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#410DAA",
  },
  step: { fontSize: 16, color: "#212121", marginLeft: 9, fontWeight: "bold" },
  expectedAmountContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: md(window.width) ? 35 : 5,
    paddingVertical: 10,
    backgroundColor: "#F3F6F9",
    alignItems: "center",
  },
  expectedAmountText: {
    color: "#464E5F",
    fontSize: 12,
    fontWeight: "bold",
  },
  planwiseSellText: {
    fontSize: 12,
    color: "#410DAA",
  },

  notesContainer: {
    marginTop: 15,
    marginBottom: 50,
  },
  note: {
    fontSize: 12,
    color: "#12121290",
    fontStyle: "italic",
  },
  note1: {
    marginBottom: 20,
  },
  note2: {
    marginBottom: 20,
  },
  note3: {
    marginBottom: 20,
    color: "#121212",
  },
  undeline: {
    textDecorationLine: "underline",
    color: "#121212",
  },
  notePoint: {
    marginTop: 8,
  },
});

export default Notifications;
