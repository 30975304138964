import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Linking,
} from "react-native";
import { isMobile } from "../../../../utils";
import { PieChartDashboard } from "../../../InvestmentOverview/PieChartDashboard";
import AntIcon from "@expo/vector-icons/AntDesign";

const Product = ({ style, content, buttonPress, active = false, mfData }) => {
  const {
    title,
    volatality,
    strategy,
    descriptions,
    navigateTo,
    cagr,
    three_year_return,
    total_returns_perc,
  } = content;

  const getHoldingPeriod = (avgtime) => {
    if (avgtime) {
      let avgtime_values = avgtime.split(" ");
      if (avgtime_values.length > 1) {
        return (
          parseFloat(avgtime_values[0]).toFixed(1) + " " + avgtime_values[1]
        );
      }
    }
  };

  return (
    <View
      style={[
        styles.productContainer,
        Platform.OS == "web" && styles.webShadowBorder,
        { ...style },
      ]}
    >
      <View style={styles.topContainer}>
        <View style={styles.details}>
          <View style={styles.titleContainer}>
            <View
              style={{
                width: 16,
                height: 16,
                borderRadius: 8,
                marginRight: 12,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#A98DFF",
              }}
            >
              <View
                style={{
                  width: 8,
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: "#CCBCFF",
                }}
              ></View>
            </View>
            <Text style={styles.title}>{title}</Text>
            <View
              style={[
                styles.volatalityContainer,
                { backgroundColor: volatality.backgroundColor },
              ]}
            >
              <Text style={[styles.volatality, { color: "#410DAA" }]}>
                <AntIcon
                  name={mfData.day_change_perc > 0 ? "arrowup" : "arrowdown"}
                  style={{
                    fontSize: isMobile ? 13 : 12,
                    paddingLeft: 5,
                    color: "#410DAA",
                  }}
                />
                {volatality.title}{" "}
                <Text
                  style={{
                    fontStyle: "italic",
                    color: "gray",
                    fontWeight: "normal",
                  }}
                >
                  1 day
                </Text>
              </Text>
            </View>
          </View>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              buttonPress && buttonPress(navigateTo);
            }}
          >
            <Text style={styles.buttonText}>View Funds</Text>
          </TouchableOpacity>
        </View>
        {active && <Text style={styles.strategy}>{strategy || ""}</Text>}
      </View>

      <PieChartDashboard
        width={66}
        height={60}
        data={[
          {
            title: "Equity",
            value: 34341206.9,
            color: "#A98DFF",
            legendFontColor: "#FEBE37",
            legendFontSize: 15,
            titleValue: `${mfData.equity_perc.toFixed(2)}%`,
          },
          {
            title: "Non-Equity",
            value: 658793,
            color: "#E3E3E3",
            legendFontColor: "#E3E3E3",
            legendFontSize: 15,
            titleValue: `${mfData.non_equity_perc_new.toFixed(2)}%`,
          },
        ]}
        chartConfig={{
          backgroundGradientFrom: "#1E2923",
          backgroundGradientFromOpacity: 0,
          backgroundGradientTo: "#08130D",
          backgroundGradientToOpacity: 0.5,
          color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
          strokeWidth: 2,
          barPercentage: 0.5,
          useShadowColorFromDataset: false,
        }}
        accessor={"value"}
      />
      {active ? (
        <View style={styles.activePlansInfoContainer}>
          <View style={styles.infoRow}>
            <View>
              <View style={styles.fieldContainer}>
                <Text style={styles.infoTitle}>Invested</Text>
                <Text style={styles.infoValue}>
                  {mfData.amount_at_cost_text}
                </Text>
              </View>
              <View style={[styles.fieldContainer, styles.marginTop]}>
                <Text style={styles.infoTitle}>Avg. Duration</Text>
                <Text style={styles.infoValue}>
                  {getHoldingPeriod(mfData.holding_period)}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.infoRow, styles.center]}>
            <View>
              <View style={styles.fieldContainer}>
                <Text style={styles.infoTitle}>Total Gain</Text>
                <Text style={styles.infoValue}>{mfData.gain}</Text>
              </View>
              <View style={[styles.fieldContainer, styles.marginTop]}>
                <Text style={styles.infoTitle}>Likely Savings</Text>
                <Text style={styles.infoValue}>{mfData.savings}</Text>
              </View>
            </View>
          </View>
          <View style={[styles.infoRow, styles.end]}>
            <View>
              <View style={styles.fieldContainer}>
                <Text style={styles.infoTitle}>Total Gain %</Text>
                {mfData.gain != "-" && mfData.absolute_gain != "-" && (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <AntIcon
                      name={mfData.absolute_gain > 0 ? "caretup" : "caretdown"}
                      style={{
                        fontSize: 12,
                        color: "#410DAA",

                        paddingRight: 2,
                      }}
                    />
                    <Text style={styles.infoValue}>
                      {Number(mfData.absolute_gain).toFixed(2)}%
                    </Text>
                  </View>
                )}
              </View>
              <View style={[styles.fieldContainer, styles.marginTop]}>
                <Text style={styles.infoTitle}>Yearly XIRR %</Text>
                <Text style={styles.infoValue}>{mfData.latest_xirr}</Text>
              </View>
            </View>
          </View>
        </View>
      ) : (
        <View style={styles.descriptionContainer}>
          {descriptions.map((description) => {
            return (
              <View style={styles.featureContainer}>
                <Text style={styles.featureBullet}>•</Text>
                <Text style={styles.feature}>{description}</Text>
              </View>
            );
          })}
          <View
            style={[
              styles.activePlansInfoContainer,
              styles.infoFieldsContainer,
            ]}
          >
            <View style={styles.infoRow}>
              <View>
                <Text style={styles.infoTitle}>CAGR</Text>
                <Text style={styles.infoField}>+{cagr}%</Text>
              </View>
            </View>
            <View style={[styles.infoRow, styles.center]}>
              <View>
                <Text style={styles.infoTitle}>3Y Return</Text>
                <Text style={styles.infoField}>+{three_year_return}%</Text>
              </View>
            </View>
            <View style={[styles.infoRow, styles.end]}>
              <View>
                <Text style={styles.infoTitle}>Total Returns</Text>
                <Text style={styles.infoField}>+{total_returns_perc}%</Text>
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

const staticData = {
  JEWEL: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Jewel.png",
    title: "JEWEL",
    strategy: "Selected from top 350/1000 companies",
    volatality: {
      title: "Medium Volatality",
      color: "#0BB783",
      backgroundColor: "#0BB78310",
    },
    descriptions: [
      "Top 350 Stocks, Marketcap > $1b",
      "Low Debt, High ROE, ROA, Growing companies",
    ],
    navigateTo: "MyEquityInvestmentPlans",
    value: "JWL",
    cagr: 26.5,
    three_year_return: 26.5,
    total_returns_perc: 26.5,
  },
};

export const MutualFundPlansOld = ({
  style,
  navigation,
  data,
  userId,
  isNew,
  mfData,
  mfDetails,
}) => {
  let products = Object.keys(data);
  products = products.filter((product) => product in staticData);
  const staticDataToUse = isNew
    ? {
        JWL: {
          image:
            "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Jewel.png",
          title: `${mfData.latest_value}`,
          strategy: "",
          volatality: {
            title: `${mfData.day_change_perc}%`,
            color: "#410DAA",
            backgroundColor: "transparent",
          },
          descriptions: [
            "Top 350 Stocks, Marketcap > $1b",
            "Low Debt, High ROE, ROA, Growing companies",
          ],
          navigateTo: "MyEquityInvestmentPlans",
          value: "JWL",
          cagr: 26.5,
          three_year_return: 26.5,
          total_returns_perc: 26.5,
        },
      }
    : staticData;
  const productKeys = Object.keys(staticDataToUse);
  return (
    <View style={[styles.container, { ...style }]}>
      {productKeys.map((product, index) => {
        let { invested_amount, current_value, total_returns_perc, is_exists } =
          data[product] || {};

        is_exists = isNew ? data[product] : is_exists;

        return (
          <Product
            style={{
              marginRight: index == 0 ? 10 : 0,
              width: isMobile ? "100%" : "50%",
            }}
            mfData={mfData}
            buttonPress={() => {
              navigation.navigate("Explore");
            }}
            content={staticDataToUse[product]}
            key={product}
            active={is_exists}
            data={is_exists ? data[product] : {}}
            currentValue={current_value}
            invested={invested_amount}
            totalReturns={total_returns_perc}
            navigation={navigation}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: isMobile ? 0 : 40,
    flexDirection: isMobile ? "column" : "row",
    paddingHorizontal: isMobile ? 0 : 0,
    marginRight: isMobile ? 0 : 0,
  },
  productContainer: {
    width: isMobile ? "100%" : "32.2%",
    borderColor: isMobile ? "transparent" : "#EEE5FF40",
    borderRadius: isMobile ? 0 : 18,
    paddingTop: 15,
    paddingBottom: isMobile ? 0 : 12,
    borderWidth: 1,
    marginBottom: isMobile ? 15 : 0,
  },
  webShadowBorder: {
  },
  volatalityContainer: {
    backgroundColor: "#FFF0E8",
    marginLeft: isMobile ? 9 : 15,
  },
  volatality: {
    fontSize: 13,
    color: "#EF5D12",
    paddingVertical: isMobile ? 5 : 6,
    paddingHorizontal: isMobile ? 14 : 18,
    marginLeft: -20,
    fontWeight: "bold",
  },
  topContainer: {
    paddingHorizontal: 15,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: isMobile ? 15 : 16,
    color: "#464E5F",
    fontWeight: "bold",
    fontFamily: "Arial",
    paddingTop: 3,
  },
  image: {
    width: 25,
    height: 24,
    marginRight: 6,
  },
  strategy: {
    fontSize: 13,
    color: "#888C9F",
    fontStyle: "italic",
    marginTop: 13,
  },
  activePlansInfoContainer: {
    marginTop: isMobile ? 20 : 10,
    backgroundColor: "#F6F6F660",
    paddingHorizontal: 15,
    paddingVertical: 6,
    flexDirection: "row",
  },
  infoContainer: {
    flex: 1,
    paddingVertical: 6,
    flexDirection: "row",
  },
  infoRow: {
    flex: 0.33,
    alignItems: "flex-start",
  },
  fieldContainer: {
    paddingVertical: 6,
  },
  marginTop: {
    marginTop: 16,
  },
  center: { alignItems: "center" },
  end: { alignItems: "flex-end" },
  infoFieldsContainer: {
    marginTop: 0,
    paddingTop: 4,
  },
  infoTitle: {
    fontSize: 12,
    color: "#6C7293",
    fontWeight: "600",
    marginBottom: 8,
  },
  infoField: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  infoValue: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  button: {
    alignSelf: "center",
  },
  buttonText: {
    fontSize: isMobile ? 13 : 15,
    color: "#410DAA",
    fontWeight: "bold",
    lineHeight: 23,
    textDecorationLine: isMobile ? "underline" : "none",
  },
  featureContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingHorizontal: 18,
    marginBottom: 12,
  },
  feature: {
    color: "#464E5F",
    fontFamily: "Arial",
    fontSize: 13,
  },
  featureBullet: {
    color: "#410DAA",
    fontFamily: "Arial",
    fontSize: 15,
    fontWeight: "bold",
    paddingRight: 5,
  },
  details: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  descriptionContainer: {
    marginTop: 12,
  },
});
