import React from "react";
import { View, Text, StyleSheet, Dimensions, Platform } from "react-native";

import { md } from "../../../Responsive";
const { width } = Dimensions.get("window");
import AntDesign from "@expo/vector-icons/AntDesign";
import { getUserDetails, isMobile, isWeb } from "../../../utils";

export default function MiniOnBoardingProgressBar({
  color2,
  color3,
  color4,
  color5,
  color6,
  color11,
  color12,
  bcolor1,
  bcolor2,
  bcolor3,
  bagcolor1,
  bagcolor2,
  bagcolor3,
  markdisplay1,
  markdisplay2,
  markdisplay3,
  dotdisplay1,
  dotdisplay2,
  dotdisplay3,
}) {
  if (!(isWeb && Platform.OS == "web")) {
    return <View />;
  }
  const { is_club } = getUserDetails();
  let is_preferred_circle = false;
  let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
  if (hostname.includes("myadvisor")) {
    is_preferred_circle = true;
  }
  return (
    <View style={styles.bod}>
      {!isMobile && (
        <View
          style={{
            flexDirection: "row",
            paddingBottom: 3,
          }}
        >
          <Text style={[{ flex: 0.5 }, styles.cont_info]}></Text>
          <Text style={[{ flex: 1 }, styles.cont_info]}>Step 1</Text>
          <Text style={[{ flex: 1 }, styles.cont_info]}>Step 2</Text>
          
          <Text style={[{ flex: 0.5 }, styles.cont_info]}></Text>
        </View>
      )}

      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={[styles.initial, { backgroundColor: "#410DAA" }]}></View>
        <View style={[styles.initial, { backgroundColor: color2 }]}></View>
        <View style={{}}>
          <View style={[styles.outercircle, { borderColor: bcolor1 }]}>
            <AntDesign
              name="checkcircle"
              size={14}
              style={{ color: bagcolor1, display: markdisplay1 }}
            />
            <View
              style={[
                styles.innercircle,
                { backgroundColor: bagcolor1, display: dotdisplay1 },
              ]}
            ></View>
          </View>
        </View>
        <View style={[styles.initial, { backgroundColor: color3 }]}></View>
        <View style={[styles.initial, { backgroundColor: color4 }]}></View>
        <View style={{}}>
          <View style={[styles.outercircle, { borderColor: bcolor2 }]}>
            <AntDesign
              name="checkcircle"
              size={14}
              style={{ color: bagcolor2, display: markdisplay2 }}
            />
            <View
              style={[
                styles.innercircle,
                { backgroundColor: bagcolor2, display: dotdisplay2 },
              ]}
            ></View>
          </View>
        </View>
        <View style={[styles.initial, { backgroundColor: color5 }]}></View>
        <View style={[styles.initial, { backgroundColor: color6 }]}></View>
        {/* <View style={{}}>
          <View style={[styles.outercircle, { borderColor: bcolor3 }]}>
            <AntDesign
              name="checkcircle"
              size={14}
              style={{ color: bagcolor3, display: markdisplay3 }}
            />
            <View
              style={[
                styles.innercircle,
                { backgroundColor: bagcolor3, display: dotdisplay3 },
              ]}
            ></View>
          </View>
        </View>
        <View style={[styles.initial, { backgroundColor: color11 }]}></View>
        <View style={[styles.initial, { backgroundColor: color12 }]}></View> */}
      </View>

			{/* {!isMobile && (
        <View
          style={{
            flexDirection: "row",
            paddingBottom: 3,
          }}
        >
          <Text style={[{ flex: 0.5 }, styles.cont_info]}></Text>
          <Text style={[{ flex: 1 }, styles.cont_info]}>
             1
          </Text>
          <Text style={[{ flex: 1 }, styles.cont_info]}>2</Text>
          
          <Text style={[{ flex: 0.5 }, styles.cont_info]}></Text>
        </View>
      )} */}
    </View>
  );
}

const styles = StyleSheet.create({
  bod: {
    backgroundColor: "#ffffff",
    display: "flex",
    paddingTop: md(width) ? 27 : 0,
  },
  circle1: {
    width: 15,
    height: 15,
    backgroundColor: "#ffffff",
    borderRadius: 7.5,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderColor: "#410DAA",
    zIndex: 1,
  },
  circle2: {
    width: 7,
    height: 7,
    backgroundColor: "#410DAA",
    borderRadius: 3.5,
  },
  circleContainer: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    top: 0,
  },
  line1: {
    flex: 1,
    height: 4,
    backgroundColor: "#410DAA",
  },
  line2: {
    flex: 1,
    height: 4,
    backgroundColor: "#E7E1E1",
  },
  activeLineColor: {
    backgroundColor: "#410DAA",
  },
  notActiveLineColor: {
    backgroundColor: "#E7E1E1",
  },
  endLineColor: {
    backgroundColor: "#410DAA",
  },
  activeCircle: {
    borderColor: "#410DAA",
  },
  notActiveCircle: {
    borderColor: "#E7E1E1",
    borderWidth: 1,
  },
  notActiveCircle2: {
    backgroundColor: "#FFF",
  },
  activeCircle2: {
    backgroundColor: "#410DAA",
  },
  initial: {
    height: md(width) ? 4 : 3,
    flex: 1,
  },
  cont_info: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isMobile ? 12 : 16,
    lineHeight: 18,
    color: "#464E5F",
    textAlign: "center",
  },
  outercircle: {
    width: md(width) ? 18 : 11,
    height: md(width) ? 18 : 11,
    backgroundColor: "#ffffff",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
  },
  innercircle: {
    width: md(width) ? 7 : 5,
    height: md(width) ? 7 : 5,
    borderRadius: 10,
  },
});
