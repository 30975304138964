import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { isMobile } from "../../../../utils";
import { PieChartInvest } from "./PieChartInvest";

const Product = ({ dataValue, value, portfolio_as_of_date }) => {
  const totalPercentage = dataValue.reduce(
    (c, p) => {
      let percentage = 0;
      if ("stocks_perc" in p) {
        percentage = p["stocks_perc"];
      } else if ("mf_perc" in p) {
        percentage = p["mf_perc"];
      } else if ("assets_perc" in p) {
        percentage = p["assets_perc"];
      } else if ("pms_perc" in p) {
        percentage = p["pms_perc"];
      }
      return { percentage: c.percentage + percentage };
    },
    { percentage: 0 }
  );

  return (
    <View>
      <View
        style={{
          marginTop: 15,
          marginLeft: 20,
          marginBottom: isMobile ? 10 : 10,
        }}
      >
        <PieChartInvest
          width={90}
          height={90}
          amount={value}
          portfolio_as_of_date={portfolio_as_of_date}
          data={[
            {
              title: "Stocks",
              value: parseInt(dataValue[0].stocks_perc),
              color: "#6FE3D4",
              legendFontColor: "#6FE3D4",
              legendFontSize: 15,
              titleValue: `${dataValue[0].stocks_perc.toFixed(1)}%`,
              total: dataValue[0].value,
            },
            {
              title: "MF",
              value: parseInt(dataValue[1].mf_perc),
              color: "#876CDD",
              legendFontColor: "#876CDD",
              legendFontSize: 15,
              titleValue: `${dataValue[1].mf_perc.toFixed(1)}%`,
              total: dataValue[1].value,
            },
            {
              title: "Assets",
              value: parseInt(dataValue[2].assets_perc),
              color: "#BAC7F2",
              legendFontColor: "#BAC7F2",
              legendFontSize: 15,
              titleValue: `${dataValue[2].assets_perc.toFixed(1)}%`,
              total: dataValue[2].value,
            },
            {
              title: "PMS",
              value: parseInt(dataValue[3].pms_perc),
              color: "#410DAA",
              legendFontColor: "#410DAA",
              legendFontSize: 15,
              titleValue: `${dataValue[3].pms_perc.toFixed(1)}%`,
              total: dataValue[3].value,
            },
          ]}
          chartConfig={{
            backgroundGradientFrom: "#1E2923",
            backgroundGradientFromOpacity: 0,
            backgroundGradientTo: "#08130D",
            backgroundGradientToOpacity: 0.5,
            color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
            strokeWidth: 2,
            barPercentage: 0.5,
            useShadowColorFromDataset: false,
          }}
          accessor={"value"}
        />
      </View>
      {isMobile ? (
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              marginTop: 5,
              paddingLeft: 30,
            }}
          >
            <View
              style={{
                alignSelf: "flex-start",
                flexDirection: "row",
                width: "100%",
                marginTop: 4,
                marginBottom: 7.5,
              }}
            >
              <View
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: 2,
                  backgroundColor: "#6FE3D4",
                }}
              />
              <Text
                style={{
                  fontSize: 13,
                  color: "#494B69",
                  paddingLeft: 13,
                }}
              >
                Stocks
              </Text>
            </View>
            <View
              style={{
                alignSelf: "flex-start",
                flexDirection: "row",
                width: "100%",
                marginTop: 4,
                marginBottom: 7.5,
              }}
            >
              <View
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: 2,
                  backgroundColor: "#876CDD",
                }}
              />
              <Text
                style={{
                  fontSize: 13,
                  color: "#494B69",
                  paddingLeft: 13,
                }}
              >
                M. Funds
              </Text>
            </View>
            <View
              style={{
                alignSelf: "flex-start",
                flexDirection: "row",
                width: "100%",
                marginTop: 4,
                marginBottom: 7.5,
              }}
            >
              <View
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: 2,
                  backgroundColor: "#BAC7F2",
                }}
              />
              <Text
                style={{
                  fontSize: 13,
                  color: "#494B69",
                  paddingLeft: 13,
                }}
              >
                Assets
              </Text>
            </View>
            <View
              style={{
                alignSelf: "flex-start",
                flexDirection: "row",
                width: "100%",
                marginTop: 4,
                marginBottom: 7.5,
              }}
            >
              <View
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: 2,
                  backgroundColor: "#410DAA",
                }}
              />
              <Text
                style={{
                  fontSize: 13,
                  color: "#494B69",
                  paddingLeft: 13,
                }}
              >
                PMS
              </Text>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              paddingTop: 7,
              paddingRight: 30,
              justifyContent: "flex-start",
            }}
          >
            <View
              style={{
                alignSelf: "flex-start",
                flexDirection: "row",
                marginTop: 4,
                marginBottom: 7.5,
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  color: "#494B69",
                  marginTop: -2,
                }}
              >
                <Text>{dataValue[0].value} </Text>
              </Text>
            </View>
            <View
              style={{
                alignSelf: "flex-start",
                flexDirection: "row",
                marginTop: 4,
                marginBottom: 7.5,
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  color: "#494B69",
                  marginTop: -2,
                }}
              >
                <Text>{dataValue[1].value} </Text>
              </Text>
            </View>
            <View
              style={{
                alignSelf: "flex-start",
                flexDirection: "row",
                marginTop: 4,
                marginBottom: 7.5,
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  color: "#494B69",
                  marginTop: -2,
                }}
              >
                <Text>{dataValue[2].value} </Text>
              </Text>
            </View>
            <View
              style={{
                alignSelf: "flex-start",
                flexDirection: "row",
                marginTop: 4,
                marginBottom: 7.5,
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  color: "#494B69",
                  marginTop: -2,
                }}
              >
                <Text>{dataValue[3].value} </Text>
              </Text>
            </View>
          </View>
        </View>
      ) : (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: 7,
            paddingHorizontal: 30,
          }}
        >
          <View
            style={{
              alignSelf: "flex-start",
              flexDirection: "row",
              width: "100%",
              marginTop: 4,
              marginBottom: 7.5,
            }}
          >
            <View
              style={{
                width: 16,
                height: 16,
                borderRadius: 2,
                backgroundColor: "#6FE3D4",
              }}
            />
            <Text
              style={{
                fontSize: 13,
                color: "#494B69",
                paddingLeft: 13,
              }}
            >
              Stocks
              <Text style={{ marginLeft: 79.5 }}>
                {dataValue[0].value}{" "}
                <Text style={{ fontWeight: "500" }}>
                  {dataValue[0].stocks_perc == 0
                    ? ""
                    : `(${dataValue[0].stocks_perc.toFixed(1)}%)`}
                </Text>
              </Text>
            </Text>
          </View>

          <View
            style={{
              alignSelf: "flex-start",
              flexDirection: "row",
              width: "100%",
              marginTop: 4,
              marginBottom: 7.5,
            }}
          >
            <View
              style={{
                width: 16,
                height: 16,
                borderRadius: 2,
                backgroundColor: "#876CDD",
              }}
            />
            <Text
              style={{
                fontSize: 13,
                color: "#494B69",
                paddingLeft: 13,
              }}
            >
              M. Funds
              <Text style={{ marginLeft: 63.5 }}>
                {dataValue[1].value}{" "}
                <Text style={{ fontWeight: "500" }}>
                  {dataValue[1].mf_perc == 0
                    ? ""
                    : `(${dataValue[1].mf_perc.toFixed(1)}%)`}
                </Text>
              </Text>
            </Text>
          </View>
          <View
            style={{
              alignSelf: "flex-start",
              flexDirection: "row",
              width: "100%",
              marginTop: 4,
              marginBottom: 7.5,
            }}
          >
            <View
              style={{
                width: 16,
                height: 16,
                borderRadius: 2,
                backgroundColor: "#BAC7F2",
              }}
            />
            <Text
              style={{
                fontSize: 13,
                color: "#494B69",
                paddingLeft: 13,
              }}
            >
              Assets
              <Text style={{ marginLeft: 80 }}>
                {dataValue[2].value}{" "}
                <Text style={{ fontWeight: "500" }}>
                  {dataValue[2].assets_perc == 0
                    ? ""
                    : `(${dataValue[2].assets_perc.toFixed(1)}%)`}
                </Text>
              </Text>
            </Text>
          </View>
          <View
            style={{
              alignSelf: "flex-start",
              flexDirection: "row",
              width: "100%",
              marginTop: 4,
              marginBottom: 7.5,
            }}
          >
            <View
              style={{
                width: 16,
                height: 16,
                borderRadius: 2,
                backgroundColor: "#410DAA",
              }}
            />
            <Text
              style={{
                fontSize: 13,
                color: "#494B69",
                paddingLeft: 13,
              }}
            >
              PMS
              <Text style={{ marginLeft: 90 }}>
                {dataValue[3].value}{" "}
                <Text style={{ fontWeight: "500" }}>
                  {dataValue[3].pms_perc == 0
                    ? ""
                    : `(${dataValue[3].pms_perc.toFixed(1)}%)`}
                </Text>
              </Text>
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};

export const NetworthCard = ({ style, data, value, portfolio_as_of_date }) => {
  return (
    <View style={[styles.container, { ...style }]}>
      <Product
        style={{
          width: "100%",
        }}
        value={value}
        dataValue={data}
        portfolio_as_of_date={portfolio_as_of_date}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  productContainer: {
    width: isMobile ? "100%" : "32.2%",
    height: isMobile ? 190 : 250,
    borderColor: isMobile ? "transparent" : "#EEE5FF40",
    borderRadius: isMobile ? 0 : 18,
    paddingTop: 15,
    paddingBottom: isMobile ? 0 : 12,
    borderWidth: 1,
    marginBottom: isMobile ? 15 : 0,
  },
  volatalityContainer: {
    backgroundColor: "#FFF0E8",
    marginLeft: isMobile ? 9 : 15,
  },
  volatality: {
    fontSize: 13,
    color: "#EF5D12",
    paddingVertical: isMobile ? 5 : 6,
    paddingHorizontal: isMobile ? 14 : 18,
    marginLeft: -20,
    fontWeight: "bold",
  },
  topContainer: {
    paddingHorizontal: 15,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: isMobile ? 15 : 16,
    color: "#464E5F",
    fontWeight: "bold",
    fontFamily: "Roboto",
    paddingTop: 3,
  },
  image: {
    width: 25,
    height: 24,
    resizeMode: "contain",
    marginRight: 6,
  },
  strategy: {
    fontSize: 13,
    color: "#888C9F",
    fontStyle: "italic",
    marginTop: 13,
  },
  activePlansInfoContainer: {
    marginTop: isMobile ? 20 : 10,
    backgroundColor: "#F6F6F660",
    paddingHorizontal: 15,
    paddingVertical: 6,
    flexDirection: "row",
  },
  infoContainer: {
    flex: 1,
    paddingVertical: 6,
    flexDirection: "row",
  },
  infoRow: {
    flex: 0.33,
    alignItems: "flex-start",
  },
  fieldContainer: {
    paddingVertical: 6,
  },
  marginTop: {
    marginTop: 16,
  },
  center: { alignItems: "center" },
  end: { alignItems: "flex-end" },
  infoFieldsContainer: {
    marginTop: 0,
    paddingTop: 4,
  },
  infoTitle: {
    fontSize: 12,
    color: "#6C7293",
    fontWeight: "600",
    marginBottom: 8,
  },
  infoField: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  infoValue: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  button: {
    alignSelf: "center",
  },
  buttonText: {
    fontSize: isMobile ? 13 : 15,
    color: "#410DAA",
    fontWeight: "bold",
    lineHeight: 23,
    textDecorationLine: isMobile ? "underline" : "none",
  },
  featureContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingHorizontal: 18,
    marginBottom: 12,
  },
  feature: {
    color: "#464E5F",
    fontFamily: "Roboto",
    fontSize: 13,
  },
  featureBullet: {
    color: "#410DAA",
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "bold",
    paddingRight: 5,
  },
  details: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  descriptionContainer: {
    marginTop: 12,
  },
});
