// code created by krishna gurram on 8 Nov 2018
import React, { Component } from "react";
import {
  View,
  FlatList,
  Text,
  TouchableOpacity,
  SafeAreaView,
  Image,
  Keyboard,
  StyleSheet,
  TextInput,
} from "react-native";
import FIcon from "@expo/vector-icons/FontAwesome";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { getSearchFunds } from "../../../api/invest";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { FundCard } from "../../../components/Common/fundCard";
import { ActivityIndicator } from "react-native-paper";

const riskStatuses = {
  LOW: "Low",
  Low: "Low",
  "Moderately Low": "Moderately Low",
  Moderate: "Moderate",
  "Moderate High": "Moderate High",
  High: "High",
  MLW: "Moderately Low",
  MOD: "Moderate",
  MDH: "Moderate High",
  HGH: "High",
  "MODERATELY HIGH": "Moderate High",
  "MODERATELY LOW": "Moderate Low",
  HIGH: "High",
};

export const SearchBar = ({ placeholder = "", style, value, onTyping }) => {
  return (
    <View style={searchStyles.searchContainer}>
      <ANTIcon name={"search1"} color={"#A8A8A8"} size={16} />
      <TextInput
        style={searchStyles.inputStyle}
        onChangeText={onTyping}
        value={value}
        placeholder={placeholder}
      />
    </View>
  );
};

const searchStyles = StyleSheet.create({
  searchContainer: {
    flexDirection: "row",
    marginHorizontal: 16,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#BCBCBC",
    paddingHorizontal: 6,
    alignItems: "center",
    height: 36,
    marginTop: 10,
  },
  inputStyle: {
    fontSize: 14,
    color: "#121212",
    padding: 0,
    paddingLeft: 5,
    margin: 0,
    height: 30,
    backgroundColor: "#fff",
    width: "100%",
  },
});

const debounce = (fn, time) => {
  let timeoutId;

  return function () {
    // Check for existing calls
    if (timeoutId) {
      // Reset timer
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      // Invoke fn
      fn.apply(this, arguments);
    }, time);
  };
};

const EmptyFunds = () => {
  return (
    <View style={styles.emptyFunds}>
      <Text>No results found</Text>
    </View>
  );
};

class SearchFunds extends Component {
  static navigationOptions = {
    tabBarVisible: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      searchFundResponse: [],
      response: [],
      originfalFundSearchResponse: [],
      searchText: "",
      time: "",
      refresh: false,
      isLoading: false, // state used to load data
      selected: null,
      AMCFund__AMC__name: [],
      AMCFund__fund_class: [],
      AMCFund__risk_factor: [],
      error: null,
      amcLogos: {},
    };
  }

  componentDidMount() {}

  getContentForFilters = (response, selected) => {
    let filterItems = response.reduce((prev, current) => {
      if (
        prev.includes(current[selected]) ||
        current[selected] == "-" ||
        current[selected] == "" ||
        !current[selected]
      ) {
        return prev;
      } else {
        return [...prev, current[selected]];
      }
    }, []);
    return filterItems;
  };

  showFilterContent = () => {
    const { selected, response } = this.state;
    if (selected !== null) {
      let filterItems = response.reduce((prev, current) => {
        if (
          prev.includes(current[selected]) ||
          current[selected] == "-" ||
          current[selected] == "" ||
          !current[selected]
        ) {
          return prev;
        } else {
          return [...prev, current[selected]];
        }
      }, []);
      return (
        <View style={{ backgroundColor: "#fff" }}>
          {filterItems.sort().map((item) => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: 8,
                marginVertical: 8,
              }}
              onPress={() => {
                if (this.state[selected].includes(item)) {
                  this.setState(
                    {
                      [selected]: [
                        ...this.state[selected].filter(
                          (filterItem) => filterItem !== item
                        ),
                      ],
                      selected: null,
                    },
                    () => this.filterSearchResults()
                  );
                } else {
                  this.setState(
                    {
                      [this.state.selected]: [
                        ...this.state[this.state.selected],
                        item,
                      ],
                      selected: null,
                    },
                    () => this.filterSearchResults()
                  );
                }
              }}
            >
              {this.state.selected == "AMCFund__AMC__name" ? (
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    style={{
                      width: 32,
                      height: 32,
                      borderRadius: 4,
                      resizeMode: "contain",
                      paddingHorizontal: 8,
                    }}
                    source={{ uri: this.state.amcLogos[item] || null }}
                  />
                  <Text style={{ marginLeft: 8, flex: 1 }}>{item}</Text>
                  {this.state[selected].includes(item) && (
                    <FIcon name={"check-circle"} color={"green"} size={16} />
                  )}
                </View>
              ) : this.state.selected == "AMCFund__risk_factor" ? (
                <>
                  <Text style={{ marginLeft: 16 }}>{riskStatuses[item]}</Text>
                  {this.state[selected].includes(item) && (
                    <FIcon name={"check-circle"} color={"green"} size={16} />
                  )}
                </>
              ) : (
                <>
                  <Text style={{ marginLeft: 16 }}>{item}</Text>
                  {this.state[selected].includes(item) && (
                    <FIcon name={"check-circle"} color={"green"} size={16} />
                  )}
                </>
              )}
            </TouchableOpacity>
          ))}
        </View>
      );
    }
    return null;
  };

  filterSearchResults = (filters) => {
    const {
      AMCs: AMCFund__AMC__names,
      "Fund Class": AMCFund__fund_class,
      // Volatality: AMCFund__risk_factor
    } = filters;

    const { searchFundResponse, response } = this.state;
    let filteredSearchItems = response || [];

    // let filteredSearchItems = response || [];
    Object.keys(filters).forEach(async (item, i) => {
      let filterItem, filterData;
      if (i == 0) {
        filterItem = "AMCFund__AMC__name";
        filterData = AMCFund__AMC__names;
      } else if (i == 1) {
        filterItem = "AMCFund__fund_class";
        filterData = AMCFund__fund_class;
      }
      //  else if (i == 2) {
      //   filterItem = "AMCFund__risk_factor";
      //   filterData = AMCFund__risk_factor;
      // }

      if (filterData && filterData.length > 0) {
        let filtered = [];
        filterData.forEach((value) => {
          const filtered_new = filteredSearchItems.filter((item) =>
            item[`${filterItem}`].toLowerCase().includes(value.toLowerCase())
          );
          filtered = [...filtered, ...filtered_new];
        });
        filteredSearchItems = filtered;
      }
    });

    filteredSearchItems = filteredSearchItems.sort(function (i, j) {
      return i.name.localeCompare(j.name);
    });

    this.setState({
      searchFundResponse: filteredSearchItems,
    });
  };

  onSortApply = (sortBy) => {
    const statuses = {
      LOW: 0,
      Low: 0,
      "Moderately Low": 1,
      Moderate: 2,
      "Moderate High": 3,
      High: 4,
      MLW: 1,
      MOD: 2,
      MDH: 3,
      HGH: 4,
      "MODERATELY HIGH": 3,
      "MODERATELY LOW": 1,
      HIGH: 4,
    };

    try {
      const { searchFundResponse } = this.state;
      searchFundResponse.sort((a, b) => {
        if (sortBy == "Volatality") {
          return statuses[a[sortBy]] < statuses[b[sortBy]];
        } else {
          if (sortBy) return a[sortBy] < b[sortBy];
          else return a[sortBy] > b[sortBy];
        }
      });
      this.setState({
        searchFundResponse,
      });
    } catch (error) {}
  };

  searchFunds = async () => {
    this.setState({ isLoading: true });
    const { searchText } = this.state;
    const payload = {
      search_str: searchText.toLowerCase(),
    };
    await getSearchFunds(payload)
    .then((resp) => {
      if (resp.results) {
        let searchFundResponse = Array.from(new Set(resp.results.map(fund => fund.name.toLowerCase())))
          .map(name => {
            return resp.results.find(fund => fund.name.toLowerCase() === name);
          })
          .filter(fund => fund.name.toLowerCase().includes(searchText.toLowerCase()))
          .sort((i, j) => i.name.localeCompare(j.name));
          this.setState({
            response: resp.results,
            searchFundResponse,
            isLoading: false,
            originfalFundSearchResponse: resp.results,
            refresh: !this.state.refresh,
            selected: null,
            AMCFund__AMC__name: [],
            AMCFund__fund_class: [],
            AMCFund__risk_factor: [],
            amcLogos: resp.amcs,
          });
        } else {
          this.setState({
            error: "Something went wrong. Please try again.",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: "Something went wrong. Please try again.",
          isLoading: false,
        });
      });
  };

  checkSearchStringFilterMore(text) {
    const tempArry = this.state.searchFundResponse.filter((e) =>
      e.name.toLowerCase().includes(text.toLowerCase())
    );
    this.setState({ searchFundResponse: tempArry });
  }

  checkSearchString(search_string) {
    const tempArry = this.state.originfalFundSearchResponse.filter((e) =>
      e.name.toLowerCase().includes(search_string.toLowerCase())
    );
    this.setState({ searchFundResponse: tempArry });
  }

  investSelectedFn = (data) => {
    // create new object which to parsed as below object
    const newObject = {
      AMCFund__AMC__amc_logo: data.amc_logo,
      AMCFund__fund_class: data.fund_class,
      AMCFund__name: data.name,
      AMCFund__pk: data.amcfund_id,
      bse_exit_load: data.bse_exit_load,
      bse_minimum_purchase_amount: data.bse_minimum_purchase_amount,
      bse_sip_minimum_installment_amount_monthly:
        data.bse_minimum_purchase_amount,
      pk: data.default_amcfundscheme_id,
      returns_1_yr: data.direct_returns_1_yr_regular,
      returns_3_yr: data.direct_returns_3_yr_regular,
      fulldata: data,
    };
    this.props.navigation.navigate("InvestNow", { data1: newObject });
  };

  _renderFlatListView = ({ item, index }) => {
    return (
      <View style={{ marginHorizontal: 16 }}>
        <FundCard
          data={item}
          fundId={item.AMCFund__amcfund_id}
          schemeId={item.amcfundscheme_id}
          navigation={this.props.navigation}
          fund_name={item.name}
          amc_fund_id={item.amcfundscheme_id}
          // growth_value={item.day_change}
          growth_value={item.day_change}
          growth_duration={"1day"}
          return_percentage={item.returns_1_yr}
          fund_size={item.fund_size}
          volatality={item.AMCFund__risk_factor}
          category_comparison_max={null}
          category_comparison_min={null}
          is_jama_suggested={item.is_jama_pick}
          index={index}
          style={{}}
          onClick={() => {}}
        />
      </View>
    );
  };

  debounceSearch = debounce(this.searchFunds, 500);

  handleBack = () => {
    const canGoBack = this.props.navigation.canGoBack();
    if (canGoBack) {
      this.props.navigation.goBack();
    } else {
      this.props.navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "InvestNowNew",
        },
      });
    }
  };

  render() {
    const { error, isLoading } = this.state;
    return (
      <>
        <SafeAreaView style={{ backgroundColor: "#fff", flex: 1 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Search Funds"]}
            onPressBack={this.handleBack}
          />
          <View
            style={{
              flex: 1,
              backgroundColor: "#fff",
              position: "relative",
              maxWidth: 800,
            }}
          >
            <SearchBar
              placeholder="search"
              style={{ marginHorizontal: 16, marginVertical: 10 }}
              value={this.state.searchText}
              onTyping={(text) => {
                this.setState({ searchText: text }, () => {
                  if (this.state.searchText.length > 0) this.searchFunds();
                });
              }}
              onSubmitEditing={() => Keyboard.dismiss()}
            />

            <View style={{ backgroundColor: "#fff", flex: 1 }}>
              {isLoading || error ? (
                <View style={styles.loaderContainer}>
                  {isLoading && (
                    <ActivityIndicator size="large" color="#0000ff" />
                  )}
                  {error && <Text>{error}</Text>}
                </View>
              ) : (
                <>
                  <FlatList
                    data={this.state.searchFundResponse}
                    showsVerticalScrollIndicator={true}
                    keyExtractor={(item, index) => item.AMCFund__amcfund_id}
                    renderItem={this._renderFlatListView}
                    ListEmptyComponent={
                      this.state.searchText.length > 0 && <EmptyFunds />
                    }
                  />
                  <View style={{ marginTop: 50, marginBottom: 50 }}></View>
                </>
              )}
            </View>
          </View>
        </SafeAreaView>
      </>
    );
  }
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 16,
  },
  emptyFunds: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
  },
});

export default SearchFunds;
