import React from "react";
import {
  ActivityIndicator,
  Animated,
  View,
  Text,
  Image,
  SafeAreaView,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
  FlatList,
  Platform,
  InteractionManager,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";
import AntIcon from "@expo/vector-icons/AntDesign";
import amplitude from "../../../utils/amplitude";
import FICon from "@expo/vector-icons/Feather";
import { getAdvisorDashboardDetials } from "../../../api/goals";
// import { Store, Store as store } from "../../../store";
import { actionTypes } from "../../../reducers/goals";
import moment from "moment";
import {
  encryptToken,
  getUserDetails,
  isMobile,
  numberFormat,
  removeString,
} from "../../../utils";
import { GoalCard } from "../components/goalCard";
import { StackActions } from "@react-navigation/routers";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import AlertModal from "../../../components/Common/Modals/AlertModal";
import { resetFinplan } from "../../../api/finplan";
import GaugeChart from "react-gauge-chart";
import { Entypo } from "@expo/vector-icons";
import { MAIN_URL } from "../../../api/urls";
import { getAdvisoryTracker, getCipsAboutToExpire } from "../../../api";
import { ShowToast } from "../../../components/Common/Toast";
import NoPlanModal from "../../../components/InvestmentOverview/noPlanModal";
import ProgressBar from "../../../utils/progressBar";
import Store from "../../../store";

const Heading = ({ title, type, style, rightComponent }) => {
  return (
    <View style={[styles.heading, style]}>
      <View style={{ flexDirection: "row", alignItems: "center", flex: 0.8 }}>
        <View>
          <Text style={styles.headingTitleText}>{title}</Text>
        </View>
      </View>
      {rightComponent && (
        <View style={{ flex: 0.2, alignItems: "flex-end" }}>
          {rightComponent}
        </View>
      )}
    </View>
  );
};

class GoalsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeGoalIndex: 1,
    };
    this.newButtonScale = new Animated.Value(1);
  }

  move = (c_index) => {
    if (Platform.OS === "web") return;
    let offset = {};
    if (c_index == 1) {
    }
    this._goalsList.scrollToIndex({
      animated: true,
      index: c_index,
      ...offset,
    });
    this.setState({ activeGoalIndex: c_index });
  };

  handlePressIn = () => {
    Animated.spring(this.newButtonScale, {
      toValue: 0.5,
    }).start();
  };

  handlePressOut = () => {
    Animated.spring(this.newButtonScale, {
      toValue: 1,
      friction: 3,
      tension: 40,
    }).start(() => {
      this.props.navigation.navigate("CreateGoalsIntro");
    });
  };

  render() {
    const { style } = this.props;
    const { activeGoalIndex } = this.state;

    let goals = this.props.goals.filter((item) => {
      return item.header != true;
    });
    let filteredGoal1 = goals.filter((goal) =>
      goal.name.toLowerCase().includes("retire")
    );
    let filteredGoal2 = goals.filter(
      (goal) => !goal.name.toLowerCase().includes("retire")
    );
    goals = [...filteredGoal1, ...filteredGoal2];

    return (
      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "flex-start",
            width: "100%",
          },
          style,
        ]}
      >
        <FlatList
          ref={(c) => (this._goalsList = c)}
          extraData={[this.props]}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          data={[0, ...goals]}
          contentContainerStyle={{
            alignItems: "flex-start",
          }}
          renderItem={({ item, index }) => {
            if (item.header) return null;
            if (index == 0) {
              return (
                <View style={{ alignItems: "center" }}>
                  <TouchableOpacity
                    style={[
                      styles.goalIconContainer,
                      {
                        backgroundColor: "#410DAA",
                        marginRight: 5,
                        overflow: "hidden",
                      },
                    ]}
                    onPress={this.handlePressOut}
                  >
                    <Animated.View
                      style={{
                        transform: [{ scale: this.newButtonScale }],
                      }}
                    >
                      <AntIcon
                        type="AntDesign"
                        name="plus"
                        style={{ fontSize: 20, color: "#fff" }}
                      />
                    </Animated.View>
                  </TouchableOpacity>
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#465964",
                      paddingTop: 5,
                      paddingRight: 5,
                      alignSelf: "center",
                    }}
                  >
                    New
                  </Text>
                </View>
              );
            } else {
              const ItemBorder =
                item["status_type"].trim() == "AT_RISK" ? "#EF5D12" : "#25AE88";
              return (
                <View
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <TouchableOpacity
                    style={[
                      styles.goalIconContainer,
                      {
                        borderColor: "#410DAA",
                        borderWidth: 1.5,
                        backgroundColor: "white",
                        marginHorizontal: 10,
                      },
                    ]}
                    onPress={() => {
                      this.props.navigation.navigate("GoalDetails", {
                        goal_id: item.customergoal_id,
                        goal_name: item.name,
                      });
                    }}
                  >
                    <View
                      style={{
                        borderColor:
                          activeGoalIndex == index ? "#410DAA" : "transparent",
                        borderWidth: 2,
                        borderRadius: 30,
                        width: 45,
                        height: 45,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        style={styles.goalIcon}
                        source={{
                          uri: item["goal_avatar"],
                        }}
                      />
                    </View>
                  </TouchableOpacity>
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 12,
                      color: activeGoalIndex == index ? "#121212" : "#465964",
                      paddingTop: 5,
                    }}
                  >
                    {item["name"]
                      ? removeString(
                          item["name"].substring(0, 10),
                          "Goal",
                          "goal"
                        )
                      : ""}
                  </Text>
                </View>
              );
            }
          }}
        />
      </View>
    );
  }
}

class AdvisorDashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    let is_elite = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (
      hostname.includes("app.jamawealth") ||
      hostname.includes("dapp.jamawealth") ||
      hostname.includes("club.jamawealth")
    ) {
      is_elite = true;
    }
    this.state = {
      scrollY: new Animated.Value(0),
      refreshing: false,
      activeGoalIndex: 0,
      calculatingHeight: true,
      resettingFinplan: false,
      stickyHeaderIndices: [],
      settingStickyHeaders: null,
      data: [],
      isShowActNow: false,
      sipBgColor: "white",
      lumpsumBgColor: "white",
      selected3DotItem: "",
      mfCount: "0",
      showLoader: false,
      showErrorToast: false,
      is_elite: is_elite,
      is_plan_advises_unlimited: false,
    };
    this.marginTop = new Animated.Value(0);
  }

  componentWillUnmount() {}

  refreshGoals = () => {
    this.generateStickyHeaders(this.props.goals);
  };

  async componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("goals", { path: global.location.href });
    } else {
      amplitude.logEvent("goals", {
        path: this.props.navigation.getState().routeName,
      });
    }
    InteractionManager.runAfterInteractions(() => {
      this._getAdvisorDashboardDetials();
    });
  }

  _getAdvisorDashboardDetials = async () => {
    try {
      let response = await getAdvisoryTracker();
      let userData = getUserDetails();
      this.setState({
        is_plan_advises_unlimited: userData.is_plan_advises_unlimited,
      });
      if (response && response.code && response.code == "100") {
        if (this.state.is_elite) {
          this.setState({
            mfCount: "99",
          });
        } else {
          this.setState({
            mfCount:
              response.result.mf_adv > 0
                ? response.result.mf_adv.toString()
                : "0",
          });
        }
      }
      this.setState({ settingStickyHeaders: true, marginTop: 0 });

      let json = null;
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        json = await getAdvisorDashboardDetials(
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        json = await getAdvisorDashboardDetials();
      }

      // const json = await getAdvisorDashboardDetials();
      if (json) {
        Store.dispatch({
          type: "USER_DASHBOARD",
          payload: json,
        });
        if (json && json.goals) {
          Store.dispatch({
            type: actionTypes.ADD_GOALS,
            payload: json.goals,
          });
          this.generateStickyHeaders(json.goals);
        }
        const { risky_goals = {}, goal_summary = {} } = json;
        this.setState({ risky_goals, goal_summary });
      } else {
        this.generateStickyHeaders(this.props.goals);
        this.setState({ settingStickyHeaders: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  generateStickyHeaders = async (current_goals) => {
    let arr = [];

    const finplan_goals_length = current_goals.reduce((a, b) => {
      return a + (b.is_for_fin_plan ? 1 : 0);
    }, 0);

    if (current_goals.length == 0) {
      this.displayFinplan();
      return;
    }

    let goals = [...current_goals];

    if (goals.length > 0) {
      goals.sort((a, b) => {
        return b.status_type.localeCompare(a.status_type);
      });
      goals.reverse();
      let filteredGoal1 = goals.filter((goal) =>
        goal.name.toLowerCase().includes("retire")
      );
      let filteredGoal2 = goals.filter(
        (goal) => !goal.name.toLowerCase().includes("retire")
      );
      goals = [...filteredGoal1, ...filteredGoal2];

      const sections = {
        AT_RISK: "At Risk",
        WELL_AHEAD: "Surplus",
        ON_TRACK: "On Track",
      };

      let plans_about_expire_resp = null;
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        plans_about_expire_resp = await getCipsAboutToExpire(
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        plans_about_expire_resp = await getCipsAboutToExpire();
      }

      let show_choose_your_plan = false;
      let result = [];
      if (plans_about_expire_resp && plans_about_expire_resp.code == 100) {
        result = plans_about_expire_resp.result;
        if (result.length > 0) {
          result.forEach((item) => {
            if (item["renewal_type"] == "no_plan_for_customer") {
              show_choose_your_plan = true;
            }
          });
        }
      }

      try {
        let headers = [];
        for (let i = 0; i < goals.length; i++) {
          if (headers.indexOf(goals[i].status_type) == -1) {
            arr.push(i);
            headers.push(goals[i].status_type);
            // goals.splice(i, 0, {
            //   header: true,
            //   sectionTitle: sections[goals[i].status_type],
            //   type: goals[i].status_type,
            // });
            goals = [
              ...goals.slice(0, i),
              {
                header: true,
                sectionTitle: sections[goals[i].status_type],
                type: goals[i].status_type,
              },
              ...goals.slice(i),
            ];
          } else {
            // goals[i]["header"] = false;
            goals[i] = { ...goals[i], header: false };
          }
        }
        this.setState({
          data: goals,
          settingStickyHeaders: false,
          stickyHeaderIndices: arr,
          is_loading: false,
          settingStickyHeaders: false,
          refreshing: false,
          show_choose_your_plan: show_choose_your_plan,
        });
      } catch (error) {
        this.setState({ refreshing: false });
      }
    } else {
      this.setState({
        data: [],
        settingStickyHeaders: false,
        stickyHeaderIndices: arr,
        is_loading: false,
        settingStickyHeaders: false,
        refreshing: false,
      });
    }
  };

  riskContainer = (goals_length) => {
    const { risky_goals = {} } = this.state;
    if (goals_length == 0 || !risky_goals.hasOwnProperty("count")) return;
    const riskyGoals = risky_goals && risky_goals.count > 0;
    let due_amount = risky_goals.due_amount;

    due_amount =
      due_amount && (due_amount != "" || due_amount != "-")
        ? due_amount.replace("-", "")
        : "-";
    return (
      <View
        style={[
          styles.riskContainer,
          { borderColor: "#c6b6e4", borderLeftColor: "#EF5D12", marginTop: 10 },
        ]}
      >
        <View
          style={{
            flex: 0.15,
            maxWidth: 60,
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          {riskyGoals ? (
            <AntIcon type="AntDesign" name="warning" style={styles.icon} />
          ) : (
            <FICon
              name="thumbs-up"
              style={[{ fontSize: 30, color: "green" }]}
            />
          )}
        </View>
        <View
          style={{
            flex: 0.85,
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: 10,
            marginLeft: isMobile ? -20 : -30,
          }}
        >
          {riskyGoals ? (
            <>
              <Text
                style={{
                  fontSize: 13,
                  color: "black",
                  marginBottom: 5,
                }}
              >
                {risky_goals.count} out of {goals_length} goals are at risk.
              </Text>
              <Text style={{ fontSize: 13, color: "black" }}>
                You are likely behind by{" "}
                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    color: "#2F2E41",
                    alignSelf: "center",
                  }}
                >
                  {due_amount}
                </Text>
              </Text>
            </>
          ) : (
            <>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: "200",
                  color: "#121212",
                  fontWeight: "bold",
                }}
              >
                Congratulations! All goals are on track.
              </Text>
            </>
          )}
        </View>
      </View>
    );
  };

  displayGoalCard = (goalData) => {
    try {
      let { data } = this.state;
      const item_index =
        data && data.findIndex((x) => x.name === goalData.name);

      this._scrollView.getNode().scrollToIndex({
        index: item_index,
        animated: true,
        viewOffset: 40,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleScroll = (event) => {
    const { y } = event.nativeEvent.contentOffset;
    const { HEADER_SCROLL_DISTANCE } = this.state;
    try {
      if (y >= HEADER_SCROLL_DISTANCE && y - HEADER_SCROLL_DISTANCE <= 80) {
      }
    } catch (error) {}
  };

  scrollHideLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    const HEADER_MIN_HEIGHT = 80;
    const HEADER_SCROLL_DISTANCE = height - HEADER_MIN_HEIGHT;

    this.setState({
      HEADER_MAX_HEIGHT: height,
      HEADER_SCROLL_DISTANCE,
      calculatingHeight: false,
    });
  };

  resetUserFinPlan = async () => {
    this.setState({
      resettingFinplan: true,
    });
    try {
      const response = await resetFinPlan();
      if (response.data.code == 100) {
        const reset = await this.props.resetFinplan();
        if (reset && response.goals) {
          Store.dispatch({
            type: actionTypes.ADD_GOALS,
            payload: response.goals,
          });
        }
        this.props.navigation.push("CreateFinplan", {
          closeModal: this.closeModal,
        });
      }
    } catch (e) {
      this.setState({
        resettingFinplan: false,
      });
    }
    this.setState({
      resettingFinplan: false,
    });
  };

  displayFinplan = async () => {
    this.props.navigation.dispatch(StackActions.replace("CreateFinplan"));
    this.setState({
      settingStickyHeaders: false,
      is_loading: false,
      resettingFinplan: false,
    });
  };

  renderGoalCard = ({ item }) => {
    if (item.header == true) {
      return (
        <View
          style={{
            backgroundColor: "white",
            paddingBottom: 5,
          }}
        >
          <Heading
            title={item.sectionTitle}
            type={item.type}
            style={{ marginTop: 10 }}
          />
        </View>
      );
    }
    return (
      <View
        style={{
          paddingVertical: 8,
        }}
      >
        <GoalCard
          data={item}
          handleClick={() => {
            this.props.navigation.push("GoalDetails", {
              goal_id: item.customergoal_id,
              goal_name: item.name,
            });
          }}
          mfAction={async (is_preferred_circle) => {
            const key = moment().unix();
            Store.dispatch({
              type: "UPDATE_ACTION",
              payload: {
                key: key,
                data: {
                  goal: item,
                  goal_id: item.customergoal_id,
                },
              },
            });
            // console.log("is_preferred_circle is " + is_preferred_circle);
            if (this.state.is_plan_advises_unlimited) {
              this.props.navigation.navigate("App", {
                screen: "ViewMode",
                params: {
                  screen: "InvestForGoals",
                  params: {
                    showHeader: true,
                    goal_id: item.customergoal_id,
                  },
                },
              });
            } else if (this.state.mfCount == "0" && is_preferred_circle) {
              this.setState({
                showToast: "true",
                toastTitle:
                  "No mutual funds advices remaining, kindly contact advisor.",
              });
            } else {
              this.props.navigation.navigate("App", {
                screen: "ViewMode",
                params: {
                  screen: "InvestForGoals",
                  params: {
                    showHeader: true,
                    goal_id: item.customergoal_id,
                  },
                },
              });
            }
          }}
          navigation={this.props.navigation}
          selected3DotItem={this.state.selected3DotItem}
          handle3dotsClick={() => {
            if (this.state.selected3DotItem == "") {
              this.setState({ selected3DotItem: item.customergoal_id });
            } else {
              this.setState({ selected3DotItem: "" });
            }
          }}
        />
      </View>
    );
  };

  onViewableItemsChanged = ({ viewableItems }) => {
    try {
      let top = viewableItems[0],
        id;
      if (top && top.item && top.item.header == true) {
        top = viewableItems[1];
      }
      id = top.item.name;

      let { data } = this.state;
      let goals = data.filter((item) => {
        return item.header != true;
      });

      let visibleIndex = goals.findIndex((item) => item.name == id);
      if (visibleIndex > goals.length - 1) visibleIndex = goals.length - 1;
      this._goalsList.move(visibleIndex + 1);
    } catch (error) {}
  };

  renderListHeader = (data, goals, n_visible, activeGoalIndex) => {
    if (data.length != 0) {
      return (
        <View style={{ backgroundColor: "#fff" }}>
          <View
            style={[
              { flex: 1, marginHorizontal: 10, height: 80 },
              styles.bar,
              {
                alignItems: goals.length <= n_visible ? "flex-start" : "center",
              },
            ]}
          >
            <GoalsList
              ref={(c) => (this._goalsList = c)}
              goals={this.state.data}
              activeGoalIndex={activeGoalIndex}
              style={{
                paddingBottom: 5,
                paddingLeft: 10,
              }}
              navigation={this.props.navigation}
            />
          </View>
        </View>
      );
    }
    return null;
  };

  refreshGoalsData = async () => {
    this.setState({ refreshing: true });
    let json = null;
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      json = await getAdvisorDashboardDetials(
        Store.getState().dashboard.viewModeUserId.user_id
      );
    } else {
      json = await getAdvisorDashboardDetials();
    }
    // const json = await getAdvisorDashboardDetials();
    if (json) {
      Store.dispatch({
        type: "USER_DASHBOARD",
        payload: json,
      });
      if (json && json.goals) {
        Store.dispatch({
          type: actionTypes.ADD_GOALS,
          payload: json.goals,
        });
        this.generateStickyHeaders(json.goals);
      } else {
        this.setState({ refreshing: false });
        alert(REF_FAIL_ERROR_MESSAGE);
      }
    } else {
      this.generateStickyHeaders(this.props.goals);
      this.setState({ refreshing: false });
    }
  };

  _handleRefresh = async () => {
    await this.refreshGoalsData();
  };

  handleResetButtonClick = () => {
    this.setState({ dialogVisible: true });
  };

  openSIP = async () => {
    const { token } = getUserDetails();
    const encryptedToken = encryptToken(token);
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    const url =
      MAIN_URL +
      "/app/rnw_to_web_login?token=" +
      encryptedToken +
      "&is_rnw=1&redirection_url=/app/finplan/summary/sip";

    if (Platform.OS === "web") {
      let popup_window = window.open(
        url,
        hostname,
        "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=yes,width=1200,height=700"
      );
      try {
        popup_window.focus();
      } catch (e) {
        alert(
          "Pop-up Blocker is enabled! Please add this site to your exception list."
        );
      }
    } else {
      this.props.navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Goals",
          params: {
            screen: "GoalsWebView",
            params: {
              url,
            },
          },
        },
      });
    }
  };

  openLumpsum = async () => {
    const { token } = getUserDetails();
    const encryptedToken = encryptToken(token);
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    const url =
      MAIN_URL +
      "/app/rnw_to_web_login?token=" +
      encryptedToken +
      "&is_rnw=1&redirection_url=/app/finplan/summary_invt/";

    if (Platform.OS === "web") {
      let popup_window = window.open(
        url,
        hostname,
        "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=yes,width=1200,height=700"
      );
      try {
        popup_window.focus();
      } catch (e) {
        alert(
          "Pop-up Blocker is enabled! Please add this site to your exception list."
        );
      }
    } else {
      this.props.navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Goals",
          params: {
            screen: "GoalsWebView",
            params: {
              url,
            },
          },
        },
      });
    }
  };

  resetFinplan = async () => {
    const reset_type = "all";
    const payload = {
      reset_type,
    };
    await resetFinplan(payload);
    this.props.navigation.dispatch(StackActions.replace("CreateFinplan"));
  };

  handleGoalMfRecommendations = async () => {
    let is_preferred_circle = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    if (this.state.is_plan_advises_unlimited) {
      this.props.navigation.navigate("App", {
        screen: "ViewMode",
        params: {
          screen: "InvestForGoals",
          params: {
            showHeader: true,
          },
        },
      });
    } else if (this.state.mfCount == "0" && is_preferred_circle) {
      this.setState({
        showToast: "true",
        toastTitle:
          "No mutual funds advices remaining, kindly contact advisor.",
      });
    } else {
      this.props.navigation.navigate("App", {
        screen: "ViewMode",
        params: {
          screen: "InvestForGoals",
          params: {
            showHeader: true,
          },
        },
      });
    }
  };

  render() {
    const {
      stickyHeaderIndices,
      activeGoalIndex,
      HEADER_MAX_HEIGHT,
      HEADER_SCROLL_DISTANCE,
      goal_summary,
    } = this.state;
    let achieved = 0;
    let monthlyask = 0;
    let current = 0;
    let projected = 0;
    let currentRatioPer = 0;
    let monthlyRatioPer = 0;
    let isDeficit = false;
    let deficitOrSurplus = 0;
    let achievedNew = 0;
    let projectedNew = 0;
    let currentNew = 0;
    let monthlyAskNew = 0;
    let deficitOrSurplusNew = 0;
    let costOfGoalsTodayNew = 0;

    if (goal_summary) {
      achieved = Math.round(goal_summary.achieved_today_new);
      achievedNew = Math.round(goal_summary.achieved_today_new);
      if (achievedNew >= 10000000) {
        achievedNew = (achievedNew / 10000000).toFixed(1) + " Cr";
      }
      if (achievedNew >= 100000) {
        achievedNew = (achievedNew / 100000).toFixed(1) + " L";
      }
      if (achievedNew >= 1000) {
        achievedNew = (achievedNew / 1000).toFixed(1) + " K";
      }

      current = Math.round(goal_summary.current_monthly_new);
      currentNew = Math.round(goal_summary.current_monthly_new);
      if (currentNew >= 10000000) {
        currentNew = (currentNew / 10000000).toFixed(1) + " Cr";
      }
      if (currentNew >= 100000) {
        currentNew = (currentNew / 100000).toFixed(1) + " L";
      }
      if (currentNew >= 1000) {
        currentNew = (currentNew / 1000).toFixed(1) + " K";
      }

      projected = Math.round(goal_summary.projected_new);
      projectedNew = Math.round(goal_summary.projected_new);
      if (projectedNew >= 10000000) {
        projectedNew = (projectedNew / 10000000).toFixed(1) + " Cr";
      }
      if (projectedNew >= 100000) {
        projectedNew = (projectedNew / 100000).toFixed(1) + " L";
      }
      if (projectedNew >= 1000) {
        projectedNew = (projectedNew / 1000).toFixed(1) + " K";
      }

      monthlyask = Math.round(goal_summary.monthly_ask_new);
      monthlyAskNew = Math.round(goal_summary.monthly_ask_new);
      if (monthlyAskNew >= 10000000) {
        monthlyAskNew = (monthlyAskNew / 10000000).toFixed(1) + " Cr";
      }
      if (monthlyAskNew >= 100000) {
        monthlyAskNew = (monthlyAskNew / 100000).toFixed(1) + " L";
      }
      if (monthlyAskNew >= 1000) {
        monthlyAskNew = (monthlyAskNew / 1000).toFixed(1) + " K";
      }

      if (current == 0 && monthlyask == 0) {
        currentRatioPer = 0;
        monthlyRatioPer = 1;
      } else {
        currentRatioPer = current / (current + monthlyask);
        monthlyRatioPer = monthlyask / (current + monthlyask);
      }
      currentRatioPer = parseFloat(currentRatioPer.toFixed(2));
      monthlyRatioPer = parseFloat(monthlyRatioPer.toFixed(2));

      isDeficit = goal_summary.target_amount_sum > projected;
      deficitOrSurplus = isDeficit
        ? Math.round(goal_summary.target_amount_sum - projected)
        : Math.round(projected - goal_summary.target_amount_sum);
      deficitOrSurplusNew = deficitOrSurplus;
      if (deficitOrSurplusNew >= 10000000) {
        deficitOrSurplusNew =
          (deficitOrSurplusNew / 10000000).toFixed(1) + " Cr";
      }
      if (deficitOrSurplusNew >= 100000) {
        deficitOrSurplusNew = (deficitOrSurplusNew / 100000).toFixed(1) + " L";
      }
      if (deficitOrSurplusNew >= 1000) {
        deficitOrSurplusNew = (deficitOrSurplusNew / 1000).toFixed(1) + " K";
      }

      costOfGoalsTodayNew = Math.round(goal_summary.cost_of_goals_today_new);
      if (costOfGoalsTodayNew >= 10000000) {
        costOfGoalsTodayNew =
          (costOfGoalsTodayNew / 10000000).toFixed(1) + " Cr";
      }
      if (costOfGoalsTodayNew >= 100000) {
        costOfGoalsTodayNew = (costOfGoalsTodayNew / 100000).toFixed(1) + " L";
      }
      if (costOfGoalsTodayNew >= 1000) {
        costOfGoalsTodayNew = (costOfGoalsTodayNew / 1000).toFixed(1) + " K";
      }
    }

    let scrollY, headerTranslate;
    let stickyHeaderInterpolate = null;
    if (HEADER_MAX_HEIGHT && HEADER_SCROLL_DISTANCE) {
      scrollY = Animated.add(this.state.scrollY, Platform.OS === "ios" ? 0 : 0);

      headerTranslate = scrollY.interpolate({
        inputRange: [0, HEADER_SCROLL_DISTANCE],
        outputRange: [0, -HEADER_SCROLL_DISTANCE],
        extrapolate: "clamp",
      });

      stickyHeaderInterpolate = scrollY.interpolate({
        inputRange: [HEADER_SCROLL_DISTANCE, HEADER_MAX_HEIGHT],
        outputRange: [0, HEADER_MAX_HEIGHT - HEADER_SCROLL_DISTANCE],
        extrapolate: "clamp",
      });
    }

    const { goals } = this.props;
    const { width } = Dimensions.get("window");
    const n_visible = Math.round(width / 70);

    return (
      <SafeAreaView style={styles.fill}>
        {/* <NavigationHeader
          navigation={this.props.navigation}  
          navigationHeader={["Goals"]}
          onPressBack={() => {
            if (this.props.navigation.canGoBack()) {
              this.props.navigation.goBack();
            } else {
              this.props.navigation.push("App");
            }
          }}
        /> */}
        {this.state.show_choose_your_plan ? (
          <NoPlanModal
            navigation={this.props.navigation}
            route={this.props.route}
          />
        ) : null}
        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title={this.state.toastTitle}
            duration="5"
          />
        ) : null}
        {this.state.showErrorToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title="Error while loading BSE url for nominee authentication. kindly contact your advisor."
            duration="10"
          />
        ) : null}
        {this.state.is_loading ||
        this.state.resettingFinplan ||
        this.state.settingStickyHeaders ? (
          <View style={styles.loaderContainer}>
            <ProgressBar msg={"Loading Goals"} />
          </View>
        ) : (
          <>
            <ScrollView
              style={{ flex: 1 }}
              contentContainerStyle={{
                paddingHorizontal: isMobile ? 20 : 40,
                marginTop: 10,
              }}
              showsVerticalScrollIndicator={true}
            >
              <View style={{ maxWidth: 800 }}>
                <View style={{ flex: 1, backgroundColor: "#fff" }}>
                  {goals.length > 0 && (
                    <Heading
                      title="Goal Health"
                      style={{ flex: 1, marginTop: 15 }}
                    />
                  )}
                  {this.riskContainer(goals.length)}
                </View>
                <View style={styles.goalContainer}>
                  {goals && goals.length != 0 && (
                    <>
                      {false &&
                      (this.state.calculatingHeight ||
                        this.state.resettingFinplan ||
                        this.state.settingStickyHeaders) ? (
                        <View style={{ paddingTop: 350 }}>
                          <ActivityIndicator size="small" color="#410DAA" />
                        </View>
                      ) : (
                        <Animated.View
                          style={
                            stickyHeaderInterpolate
                              ? {
                                  transform: [
                                    { translateY: stickyHeaderInterpolate },
                                  ],
                                }
                              : {}
                          }
                        >
                          <Animated.FlatList
                            extraData={[this.state]}
                            showsVerticalScrollIndicator={true}
                            data={this.state.data}
                            stickyHeaderIndices={
                              stickyHeaderIndices.length > 0
                                ? stickyHeaderIndices
                                : [0]
                            }
                            contentContainerStyle={{
                              flexGrow: 1,
                              paddingTop: HEADER_MAX_HEIGHT,
                              paddingBottom: 350,
                            }}
                            onViewableItemsChanged={this.onViewableItemsChanged}
                            viewabilityConfig={{
                              itemVisiblePercentThreshold: 35,
                            }}
                            ref={(c) => (this._scrollView = c)}
                            scrollEventThrottle={1}
                            onScroll={Animated.event(
                              [
                                {
                                  nativeEvent: {
                                    contentOffset: { y: this.state.scrollY },
                                  },
                                },
                              ],
                              {
                                useNativeDriver: true,
                                listener: (event) => {
                                  this.handleScroll(event);
                                },
                              }
                            )}
                            renderItem={this.renderGoalCard}
                            keyExtractor={(item, index) => `${index}`}
                            removeClippedSubviews={false}
                            ListFooterComponent={() => {
                              return null;
                            }}
                          ></Animated.FlatList>
                        </Animated.View>
                      )}
                      {goal_summary && (
                        <Animated.View
                          style={[
                            styles.header,
                            headerTranslate
                              ? {
                                  transform: [{ translateY: headerTranslate }],
                                }
                              : {},
                          ]}
                          onLayout={this.scrollHideLayout}
                        >
                          <View style={[styles.backgroundImage]}>
                            <Heading
                              title="Financial Plan Summary"
                              style={{ marginTop: 10 }}
                            />
                            <View
                              style={{
                                borderColor: "#fbdd98",
                                flexDirection: "row",
                                marginVertical: 20,
                                borderWidth: 1.3,
                                borderRadius: 8,
                                paddingVertical: 10,
                                marginTop: 10,
                                flexDirection: "column",
                                backgroundColor: "#fef9ec",
                              }}
                            >
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  paddingHorizontal: 15,
                                  marginBottom: 10,
                                }}
                              >
                                <Text>Cost of Goals</Text>
                                <Text
                                  style={{
                                    fontWeight: "700",
                                    color: "#410DAA",
                                  }}
                                >
                                  {costOfGoalsTodayNew}
                                </Text>
                              </View>
                              <View
                                style={{
                                  height: 1,
                                  backgroundColor: "#d9c9e0",
                                }}
                              ></View>
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  paddingTop: 10,
                                  width: "100%",
                                  paddingHorizontal: 15,
                                  marginBottom: 2,
                                  justifyContent: "space-between",
                                }}
                              >
                                <View style={{ flexDirection: "column" }}>
                                  <View style={{ flexDirection: "row" }}>
                                    <View
                                      style={{
                                        width: 15,
                                        height: 15,
                                        borderRadius: 2,
                                        backgroundColor: "#045607",
                                        marginTop: isMobile ? 3 : 1,
                                      }}
                                    />
                                    <Text> Achieved</Text>
                                  </View>
                                  <Text
                                    style={{
                                      fontWeight: "700",
                                      color: "black",
                                      marginTop: 10,
                                    }}
                                  >
                                    ₹ {numberFormat(achievedNew)}
                                  </Text>
                                </View>
                                <View style={{ flexDirection: "column" }}>
                                  <View style={{ flexDirection: "row" }}>
                                    <View
                                      style={{
                                        width: 15,
                                        height: 15,
                                        borderRadius: 2,
                                        backgroundColor: "#7DE92D",
                                        marginTop: isMobile ? 3 : 1,
                                      }}
                                    />
                                    <Text> Projected</Text>
                                  </View>
                                  <Text
                                    style={{
                                      fontWeight: "700",
                                      color: "black",
                                      marginTop: 10,
                                    }}
                                  >
                                    ₹ {numberFormat(projectedNew)}
                                  </Text>
                                </View>
                                <View style={{ flexDirection: "column" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: 15,
                                        height: 15,
                                        borderRadius: 2,
                                        backgroundColor: isDeficit
                                          ? "#fdc6c1"
                                          : "#00C107",
                                        marginTop: isMobile ? 3 : 1,
                                      }}
                                    />
                                    <Text>
                                      {" "}
                                      {isDeficit ? " Deficit" : " Surplus"}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      fontWeight: "700",
                                      color: "black",
                                      marginTop: 10,
                                      textAlign: "right",
                                    }}
                                  >
                                    ₹ {numberFormat(deficitOrSurplusNew)}
                                  </Text>
                                </View>
                              </View>
                              <View
                                style={{
                                  flex: 1,
                                  flexDirection: "row",
                                  paddingTop: 10,
                                  marginHorizontal: 16,
                                }}
                              >
                                <View style={{ flex: achieved }}>
                                  <Text
                                    style={{
                                      textAlign: "left",
                                      color: "#707070",
                                      fontSize: 10,
                                      paddingBottom: 5,
                                    }}
                                  ></Text>
                                  <View
                                    style={{
                                      width: "100%",
                                      height: 5,
                                      backgroundColor: "#045607",
                                    }}
                                  ></View>
                                </View>
                                <View style={{ flex: projected }}>
                                  <Text
                                    style={{
                                      textAlign: "left",
                                      color: "#707070",
                                      fontSize: 10,
                                      paddingBottom: 5,
                                    }}
                                  ></Text>
                                  <View
                                    style={{
                                      width: "100%",
                                      height: 5,
                                      backgroundColor: "#7DE92D",
                                    }}
                                  ></View>
                                </View>
                                <View style={{ flex: deficitOrSurplus }}>
                                  <Text
                                    style={{
                                      textAlign: "left",
                                      color: "#707070",
                                      fontSize: 10,
                                      paddingBottom: 5,
                                    }}
                                  ></Text>
                                  <View
                                    style={{
                                      width: "100%",
                                      height: 5,
                                      backgroundColor: isDeficit
                                        ? "#fdc6c1"
                                        : "#00C107",
                                    }}
                                  ></View>
                                </View>
                              </View>
                              {false ? (
                                <View
                                  style={{
                                    flex: 1,
                                    flexDirection: "row",
                                    marginTop: 15,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: isMobile ? 1.4 : 4,
                                      marginTop: -3,
                                      flexDirection: "row",
                                      paddingLeft: 11,
                                      paddingTop: 5,
                                    }}
                                  >
                                    <View
                                      style={{
                                        flex: 1,
                                        flexDirection: "column",
                                      }}
                                    >
                                      <View style={{ flexDirection: "row" }}>
                                        <Text> Current</Text>
                                      </View>
                                      <Text
                                        style={{
                                          fontWeight: "700",
                                          color: "black",
                                          marginTop: 10,
                                        }}
                                      >
                                        {"  "}₹ {numberFormat(currentNew)}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        flex: 1,
                                        flexDirection: "column",
                                      }}
                                    >
                                      <View style={{ flexDirection: "row" }}>
                                        <Text> Monthly Ask</Text>
                                      </View>
                                      <Text
                                        style={{
                                          fontWeight: "700",
                                          color: "black",
                                          marginTop: 10,
                                          marginLeft: 5,
                                        }}
                                      >
                                        ₹ {numberFormat(monthlyAskNew)}
                                      </Text>
                                    </View>
                                  </View>
                                  <View
                                    style={{
                                      flex: 1,
                                    }}
                                  >
                                    <TouchableOpacity
                                      onPress={() => {
                                        this.setState({
                                          isShowActNow:
                                            !this.state.isShowActNow,
                                        });
                                      }}
                                      style={{
                                        backgroundColor: "#410DAA",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: 40,
                                        borderRadius: 6,
                                        marginRight: 15,
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Text
                                        style={{ fontSize: 13, color: "#fff" }}
                                      >
                                        Act Now{" "}
                                      </Text>
                                      <Entypo
                                        name={
                                          this.state.isShowActNow
                                            ? "triangle-up"
                                            : "triangle-down"
                                        }
                                        size={16}
                                        style={{ paddingTop: 3 }}
                                        color="white"
                                      />
                                    </TouchableOpacity>
                                    {!this.state.isShowActNow && (
                                      <Text
                                        style={{
                                          fontWeight: "700",
                                          fontSize: 12,
                                          textAlign: "left",
                                          marginTop: 8,
                                          marginLeft: 10,
                                          color: "#410DAA",
                                        }}
                                      ></Text>
                                    )}
                                    {this.state.isShowActNow && (
                                      <View
                                        style={{
                                          shadowColor: "gray",
                                          shadowRadius: 5,
                                          borderRadius: 6,
                                          width: "90%",
                                          marginTop: 5,
                                        }}
                                      >
                                        <TouchableOpacity
                                          style={{
                                            padding: 10,
                                            backgroundColor:
                                              this.state.lumpsumBgColor,
                                            borderTopRightRadius: 6,
                                            borderTopLeftRadius: 6,
                                          }}
                                          onPress={() => {
                                            this.openLumpsum();
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontWeight: "700",
                                              fontSize: 12,
                                              color: "#410DAA",
                                            }}
                                          >
                                            Add Lumpsum
                                          </Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                          style={{
                                            padding: 10,
                                            backgroundColor:
                                              this.state.sipBgColor,
                                            borderBottomRightRadius: 6,
                                            borderBottomLeftRadius: 6,
                                          }}
                                          onPress={() => {
                                            this.openSIP();
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontWeight: "700",
                                              fontSize: 12,
                                              color: "#410DAA",
                                            }}
                                          >
                                            Add SIP
                                          </Text>
                                        </TouchableOpacity>
                                      </View>
                                    )}
                                  </View>
                                </View>
                              ) : (
                                <View
                                  style={{
                                    flex: 1,
                                    flexDirection: "row",
                                    marginTop: 15,
                                  }}
                                >
                                  <View
                                    style={{
                                      flex: isMobile ? 2.2 : 3,
                                      marginTop: -3,
                                      marginLeft: 53,
                                      flexDirection: "column",
                                    }}
                                  >
                                    {/* {
                                      console.log("Value", {
                                        currentRatioPer,
                                        monthlyRatioPer,
                                        currentRatioPer
                                      })
                                    } */}
                                    <View>
                                      {Platform.OS === "web" && (
                                        <GaugeChart
                                          id="gauge-chart3"
                                          nrOfLevels={2}
                                          colors={["#8950FC", "#D4BEFF"]}
                                          percent={currentRatioPer}
                                          hideText={true}
                                          arcsLength={[
                                            currentRatioPer,
                                            monthlyRatioPer,
                                          ]}
                                          style={{ width: 115 }}
                                          needleColor={"black"}
                                          needleBaseColor={"blue"}
                                          arcPadding={-5}
                                          arcWidth={0.2}
                                          cornerRadius={0}
                                        />
                                      )}
                                    </View>
                                    <View
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: 2,
                                        marginLeft: -38,
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <View style={{ flexDirection: "column" }}>
                                        <View style={{ flexDirection: "row" }}>
                                          <View
                                            style={{
                                              width: 10,
                                              height: 10,
                                              borderRadius: 2,
                                              backgroundColor: "#915BFD",
                                              marginTop: 3,
                                            }}
                                          />
                                          <Text> Current</Text>
                                        </View>
                                        <Text
                                          style={{
                                            fontWeight: "700",
                                            color: "black",
                                            marginTop: 10,
                                          }}
                                        >
                                          ₹ {numberFormat(currentNew)}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "column",
                                          marginLeft: 60,
                                        }}
                                      >
                                        <View style={{ flexDirection: "row" }}>
                                          <Text> Monthly Ask</Text>
                                          <View
                                            style={{
                                              width: 10,
                                              height: 10,
                                              marginLeft: 5,
                                              borderRadius: 2,
                                              backgroundColor: "#D4BEFF",
                                              marginTop: 4,
                                            }}
                                          />
                                        </View>
                                        <Text
                                          style={{
                                            fontWeight: "700",
                                            color: "black",
                                            marginTop: 10,
                                            marginLeft: 5,
                                          }}
                                        >
                                          ₹ {numberFormat(monthlyAskNew)}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flex: 1,
                                          flexDirection: "column",
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          {!this.state.isShowActNow && (
                                            <Text
                                              style={{
                                                fontWeight: "700",
                                                fontSize: 12,
                                                textAlign: "right",
                                                marginTop: 8,
                                                marginRight: 7,
                                                color: "#410DAA",
                                              }}
                                            ></Text>
                                          )}
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                  <View style={{ flex: 0.9, marginBottom: 20 }}>
                                    <>
                                      <TouchableOpacity
                                        style={{
                                          backgroundColor: "#410DAA",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: 6,
                                          flexDirection: "row",
                                          paddingHorizontal: 10,
                                          paddingVertical: 12,
                                          marginTop: 20,
                                        }}
                                        onPress={() => {
                                          this.handleGoalMfRecommendations();
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: "#fff",
                                          }}
                                        >
                                          Get Advice
                                        </Text>
                                      </TouchableOpacity>
                                    </>
                                    {this.state.isShowActNow && (
                                      <View
                                        style={{
                                          shadowColor: "gray",
                                          shadowRadius: 5,
                                          borderRadius: 6,
                                          width: "100%",
                                          marginTop: 5,
                                        }}
                                      >
                                        <TouchableOpacity
                                          style={{
                                            padding: 10,
                                            backgroundColor:
                                              this.state.lumpsumBgColor,
                                            borderTopRightRadius: 6,
                                            borderTopLeftRadius: 6,
                                          }}
                                          onPress={() => {
                                            this.openLumpsum();
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontWeight: "700",
                                              fontSize: 12,
                                              color: "#410DAA",
                                            }}
                                          >
                                            Add Lumpsum
                                          </Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                          style={{
                                            padding: 10,
                                            backgroundColor:
                                              this.state.sipBgColor,
                                            borderBottomRightRadius: 6,
                                            borderBottomLeftRadius: 6,
                                          }}
                                          onPress={() => {
                                            this.openSIP();
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontWeight: "700",
                                              fontSize: 12,
                                              color: "#410DAA",
                                            }}
                                          >
                                            Add SIP
                                          </Text>
                                        </TouchableOpacity>
                                      </View>
                                    )}
                                  </View>
                                  <View style={{ flex: 0.08 }}></View>
                                </View>
                              )}
                              {isMobile ? null : (
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    paddingLeft: 15,
                                    paddingRight: 13,
                                    marginBottom: 30,
                                    marginTop: -50,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <View style={{ flexDirection: "column" }}>
                                    <View
                                      style={{ flexDirection: "row" }}
                                    ></View>
                                    <Text
                                      style={{
                                        fontWeight: "700",
                                        color: "black",
                                        marginTop: 10,
                                      }}
                                    ></Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "column",
                                      marginLeft: 60,
                                    }}
                                  >
                                    <View style={{ flexDirection: "row" }}>
                                      <Text></Text>
                                    </View>
                                    <Text
                                      style={{
                                        fontWeight: "700",
                                        color: "black",
                                        marginTop: 10,
                                        marginLeft: 5,
                                      }}
                                    ></Text>
                                  </View>
                                  <View
                                    style={{ flex: 1, flexDirection: "column" }}
                                  >
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {!this.state.isShowActNow && (
                                        <Text
                                          style={{
                                            fontWeight: "700",
                                            fontSize: 12,
                                            textAlign: "right",
                                            marginTop: 8,
                                            marginRight: 12,
                                            color: "#410DAA",
                                          }}
                                        ></Text>
                                      )}
                                    </View>
                                  </View>
                                </View>
                              )}
                            </View>
                            <View
                              style={[
                                { flex: 1, marginHorizontal: 10, height: 80 },
                                styles.bar,
                                {
                                  alignItems:
                                    goals.length <= n_visible
                                      ? "flex-start"
                                      : "center",
                                },
                              ]}
                            >
                              <GoalsList
                                ref={(c) => (this._goalsList = c)}
                                goals={this.state.data}
                                activeGoalIndex={activeGoalIndex}
                                onPressGoal={(item, index) => {
                                  this.displayGoalCard(item, index);
                                }}
                                style={{
                                  paddingBottom: 5,
                                  paddingLeft: 10,
                                }}
                                navigation={this.props.navigation}
                              />
                            </View>
                          </View>
                        </Animated.View>
                      )}
                    </>
                  )}
                </View>
              </View>
            </ScrollView>
            <AlertModal
              visible={this.state.dialogVisible}
              style={{ flex: 1 }}
              closeModal={() => {
                this.setState({ dialogVisible: false });
              }}
              positiveButtonText="Reset"
              negativeButtonText="Don't Reset"
              positiveButtonPress={() => {
                this.setState({ dialogVisible: false }, () => {
                  this.resetFinplan();
                });
              }}
              negativeButtonPress={() => {
                this.setState({ dialogVisible: false });
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: 13,
                    color: "#121212",
                    fontFamily: "Roboto",
                  }}
                >
                  This is not reversible. Resetting financial plan removes all
                  your goals and their mappings to investments.
                </Text>

                <Text
                  style={{
                    fontSize: 13,
                    color: "#121212",
                    marginVertical: 5,
                    fontFamily: "Roboto",
                  }}
                >
                  You can always modify a specific goal or create a new one.
                </Text>

                <Text
                  style={{
                    fontSize: 13,
                    color: "#121212",
                    fontFamily: "Roboto",
                  }}
                >
                  Are you sure to reset ?
                </Text>
              </View>
            </AlertModal>
          </>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "white",
  },
  heading: {
    flexDirection: "row",
    alignItems: "center",
  },
  headingLegend: {
    width: 15,
    height: 20,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  headingTitle: {
    paddingLeft: 10,
  },
  headingTitleText: {
    fontSize: 18,
    fontWeight: "500",
    color: "#121212",
  },
  progressContainer: {
    flexDirection: "column",
    marginHorizontal: 10,
  },
  currentAmount: {
    fontSize: 16,
    fontWeight: "500",
  },
  indicator: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: 7,
    borderRightWidth: 7,
    borderBottomWidth: 7,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: "orange",
    marginLeft: "60%",
  },
  targetContainer: {
    flexDirection: "row",
    backgroundColor: "orange",
    paddingHorizontal: 10,
    paddingVertical: 20,
    borderRadius: 5,
  },
  targetText: {
    flex: 0.5,
    alignSelf: "flex-start",
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: "500",
  },
  targetAmountContainer: {
    alignItems: "flex-end",
    flex: 0.5,
  },
  targetAmount: {
    paddingRight: 10,
    fontSize: 16,
    fontWeight: "500",
  },
  riskContainer: {
    flexDirection: "row",
    marginVertical: 20,
    borderWidth: 1.3,
    borderLeftWidth: 3,
    borderRadius: 8,
    padding: 15,
  },
  icon: { color: "#EF5D12", fontSize: 24 },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  fill: {
    flex: 1,
    backgroundColor: "#fff",
  },
  header: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    overflow: "hidden",
    zIndex: 0,
  },
  backgroundImage: {},
  bar: {
    backgroundColor: "transparent",
    justifyContent: "center",
    left: 0,
    right: 0,
    bottom: 0,
  },
  title: {
    color: "white",
    fontSize: 18,
  },
  row: {
    height: 40,
    margin: 16,
    backgroundColor: "#D3D3D3",
    alignItems: "center",
    justifyContent: "center",
  },
  goalIconContainer: {
    width: 50,
    height: 50,
    borderRadius: 30,
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  goalIcon: { width: 23, height: 23 },
});

const mapStateToProps = (state) => {
  return {
    portfolio: {},
    goals: state.goals.goals,
    goalsLoading: state.goals.goalsLoading,
  };
};

export default connect(mapStateToProps, null)(AdvisorDashboard);
