import React from "react";
import { StyleSheet, ScrollView, View, Text, TouchableOpacity, Dimensions } from "react-native";
import { Divider } from "react-native-paper";
import DiscountText from "../components/DiscountText";
import FieldValue from "../components/FieldValue";
import { getUserDetails, isMobile, numberWithCommas } from "../../../utils";
import Store from "../../../store";
import { FontAwesome5 } from "@expo/vector-icons";
const { height } = Dimensions.get("window");

const BillHeading = ({ data }) => {
  const {is_ria} = getUserDetails()
  return (
    <View style={styles.billBasicDetailsContainer}>
      <View>
        {
          is_ria ? (null) : (
            <View style={styles.majorHeadingContainer}>
              <Text style={styles.majorHeadingText}>
                Direct Equity Advisory- {data["product_text"]} Plan{" "}
                {data["plan_code"]}
              </Text>
            </View>  
          )
        }
        <View style={styles.descriptionContainer}>
          <Text style={styles.planNumber}>Plan No: {data["plan_number"]}</Text>
          { is_ria ? (<View style={{padding:10}}></View>) : (null)}
          <Text style={styles.feePercentageText}>
            {data["billing_frequency_text"]}:{" "}
            <Text style={styles.feePercentage}>
              {data["fee_perc"].toFixed(2)}%
            </Text>
          </Text>
        </View>
      </View>
      <View>
        <View style={styles.majorHeadingContainer}>
          <Text style={styles.majorHeadingText}>
            {data["bill_type"]}
          </Text>
        </View>
        <View style={styles.descriptionContainer}>
          <Text style={styles.planNumber}>{data["bill_period_text"]}</Text>
        </View>
      </View>
      <View>
        {data["discount"] != 0 && (
          <DiscountText discount={data["discount_perc"]} />
        )}
      </View>
    </View>
  );
};

const ServiceBill = ({ data = {}, billId, ...props }) => {
  const navigateToViewDetails = () => {
    const screen =
      data["bill_view_type"] == "daily_aum_service"
        ? "AumDetails"
        : "ProratedDetails";
    let params = {};
    if (props.userId) {
      params["user_id"] = props.userId;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      params["user_id"] = Store.getState().dashboard.viewModeUserId.user_id;
    }
    props.navigation.navigate(screen, { bill_id: billId, ...params });
  };

  const {is_ria} = getUserDetails();
  // console.log(is_ria)
  return isMobile ? (
    <ScrollView
      style={styles.container}
      horizontal={true}
      showsVerticalScrollIndicator={true}
    >
      <View style={styles.boxContainer}>
        <BillHeading data={data} />

        <View style={styles.auaContainer}>
          {/*<View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.committedAua}>Committed AUA </Text>
              <Text style={styles.subDescription}>
                ({data["bill_period_text"]})
              </Text>
            </View>
            <View>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["committed_aua"]))}
              </Text>
              {data["has_stocks"] && (
                  <TouchableOpacity
                    style={[styles.viewDetailsContainer]}
                    onPress={navigateToViewDetails}
                  >
                    <Text style={styles.viewDetails}>View Details</Text>
                    <FontAwesome5
                      name="arrow-circle-right"
                      style={{ fontSize: 18, color: "#410DAA" }}
                    />
                  </TouchableOpacity>
                )}
            </View>
          </View>
          <Divider /> */}
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.committedAua}>{data["box1_field_name"]} </Text>
              {/* <Text style={styles.subDescription}>
                (as on {data["bill_period_to_date"]})
              </Text> */}
            </View>
            <View>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["Invested Amount"]))}
              </Text>
              {data["has_stocks"] && (
                <TouchableOpacity
                  style={[styles.viewDetailsContainer]}
                  onPress={navigateToViewDetails}
                >
                  <Text style={styles.viewDetails}>View Details</Text>
                  <FontAwesome5
                    name="arrow-circle-right"
                    style={{ fontSize: 18, color: "#410DAA" }}
                  />
                </TouchableOpacity>
              )}
            </View>
          </View>
          {/* <Divider />
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.committedAua}>Current AUA </Text>
              <Text style={styles.subDescription}>
                (as on{data["bill_period_to_date"]})
              </Text>
            </View>
            <Text style={styles.feesAmount}>
              ₹{numberWithCommas(parseInt(data["current_aua"]))}
            </Text>
          </View> */}
        </View>

        <View style={styles.detailsContainer}>
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View>
              <Text style={styles.advisoryHeading}>{data["bill_heading"]}</Text>
              {/* <Text style={styles.billingPeriod}>
                (Billing Period: {data["bill_period_text"]})
              </Text> */}
            </View>
            <View style={{ alignItems: "flex-end" }}>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["Actual Service Bill"]))}
              </Text>
            </View>
          </View>
          <Divider />
          {data["discount"] != 0 && !!data["discount_perc"] > 0 && (
            <>
              <FieldValue
                label={`Discount @${data["discount_perc"]} %`}
                value={
                  "₹" +
                  numberWithCommas(parseInt(data["Discount on Service Bill"]))
                }
                style={[styles.fieldItem]}
                labelStyle={[{  }]}
                valueStyle={[{  }]}
              />
              <Divider />
            </>
          )}
          {data["additional_discount"] != 0 && (
            <>
              <FieldValue
                label={`Additional Discount `}
                value={
                  "₹" + numberWithCommas(parseInt(data["additional_discount"]))
                }
                style={[styles.fieldItem]}
                labelStyle={[{  }]}
                valueStyle={[{  }]}
              />
              <Divider />
            </>
          )}
          {
           data["discount"] != 0 && !!data["discount_perc"] > 0 && (
            <>
              <FieldValue
                label={"Fee payable after discount"}
                value={
                  "₹" + numberWithCommas(parseInt(data["Actual Service Bill"]) - parseInt(data["Discount on Service Bill"]))
                }
                style={[styles.fieldItem]}
                labelStyle={[{  }]}
                valueStyle={[{  }]}
              />
              <Divider />
            </>
           ) 
          }
          <FieldValue
            label={"Fee paid at the start of period"}
            value={
              "₹" + numberWithCommas(parseInt(data["Discounted Advance Bill"]))
            }
            style={[styles.fieldItem]}
            labelStyle={[{  }]}
            valueStyle={[{  }]}
          />
          <Divider />
          <FieldValue
            label={"Net Fee Payable"}
            value={"₹" + numberWithCommas(parseInt(data["discounted_bill"]))}
            style={[styles.fieldItem]}
            labelStyle={[{ color: "#464E5F", fontWeight: "bold" }]}
            valueStyle={[{ color: "#464E5F", fontWeight: "bold" }]}
          />
          <Divider />
          {data["igst"] ? (
            <FieldValue
              label={"IGST(" + data["igst"] + "%)"}
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          ) : (
            <FieldValue
              label={
                "CGST(" + data["cgst"] + "%) + SGST(" + data["sgst"] + "%)"
              }
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          )}
          <Divider />
          <FieldValue
            label={"Final Fee including GST"}
            value={"₹" + numberWithCommas(parseInt(data["Total Payment"]))}
            style={[styles.fieldItem]}
            labelStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
            valueStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
          />
        </View>
        <Text style={styles.descriptionText}>
          Subsequent Advisory Fee has to be paid in six months from the Invoice
          date
        </Text>
      </View>
    </ScrollView>
  ) : (
    <ScrollView style={{height: height/2, paddingBottom: 100}}>
      <View style={styles.boxContainer}>
        <BillHeading data={data} />

        <View style={styles.auaContainer}>
          {/*<View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.committedAua}>Committed AUA </Text>
              <Text style={styles.subDescription}>
                ({data["bill_period_text"]})
              </Text>
            </View>
            <View>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["committed_aua"]))}
              </Text>
              {data["has_stocks"] && (
                  <TouchableOpacity
                    style={[styles.viewDetailsContainer]}
                    onPress={navigateToViewDetails}
                  >
                    <Text style={styles.viewDetails}>View Details</Text>
                    <FontAwesome5
                      name="arrow-circle-right"
                      style={{ fontSize: 18, color: "#410DAA" }}
                    />
                  </TouchableOpacity>
                )}
            </View>
          </View>
          <Divider /> */}
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.committedAua}>{data["box1_field_name"]} </Text>
              {/* <Text style={styles.subDescription}>
                (as on {data["bill_period_to_date"]})
              </Text> */}
            </View>
            <View>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["Invested Amount"]))}
              </Text>
              {data["has_stocks"] && (
                <TouchableOpacity
                  style={[styles.viewDetailsContainer]}
                  onPress={navigateToViewDetails}
                >
                  <Text style={styles.viewDetails}>View Details</Text>
                  <FontAwesome5
                    name="arrow-circle-right"
                    style={{ fontSize: 18, color: "#410DAA" }}
                  />
                </TouchableOpacity>
              )}
            </View>
          </View>
          {/* <Divider />
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View style={styles.responsiveContainer}>
              <Text style={styles.committedAua}>Current AUA </Text>
              <Text style={styles.subDescription}>
                (as on{data["bill_period_to_date"]})
              </Text>
            </View>
            <Text style={styles.feesAmount}>
              ₹{numberWithCommas(parseInt(data["current_aua"]))}
            </Text>
          </View> */}
        </View>

        <View style={styles.detailsContainer}>
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View>
              <Text style={styles.advisoryHeading}>{data["bill_heading"]}</Text>
              {/* <Text style={styles.billingPeriod}>
                (Billing Period: {data["bill_period_text"]})
              </Text> */}
            </View>
            <View style={{ alignItems: "flex-end" }}>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["Actual Service Bill"]))}
              </Text>
            </View>
          </View>
          <Divider />
          {data["discount"] != 0 && !!data["discount_perc"] > 0 && (
            <>
              <FieldValue
                label={`Discount @${data["discount_perc"]} %`}
                value={
                  "₹" +
                  numberWithCommas(parseInt(data["Discount on Service Bill"]))
                }
                style={[styles.fieldItem]}
                labelStyle={[{  }]}
                valueStyle={[{  }]}
              />
              <Divider />
            </>
          )}
          {data["additional_discount"] != 0 && (
            <>
              <FieldValue
                label={`Additional Discount `}
                value={
                  "₹" + numberWithCommas(parseInt(data["additional_discount"]))
                }
                style={[styles.fieldItem]}
                labelStyle={[{  }]}
                valueStyle={[{  }]}
              />
              <Divider />
            </>
          )}
          {
           data["discount"] != 0 && !!data["discount_perc"] > 0 && (
            <>
              <FieldValue
                label={"Fee payable after discount"}
                value={
                  "₹" + numberWithCommas(parseInt(data["Actual Service Bill"]) - parseInt(data["Discount on Service Bill"]))
                }
                style={[styles.fieldItem]}
                labelStyle={[{  }]}
                valueStyle={[{  }]}
              />
              <Divider />
            </>
           ) 
          }
          <FieldValue
            label={"Fee paid at the start of period"}
            value={
              "₹" + numberWithCommas(parseInt(data["Discounted Advance Bill"]))
            }
            style={[styles.fieldItem]}
            labelStyle={[{  }]}
            valueStyle={[{  }]}
          />
          <Divider />
          <FieldValue
            label={"Net Fee Payable"}
            value={"₹" + numberWithCommas(parseInt(data["discounted_bill"]))}
            style={[styles.fieldItem]}
            labelStyle={[{ color: "#464E5F", fontWeight: "bold" }]}
            valueStyle={[{ color: "#464E5F", fontWeight: "bold" }]}
          />
          <Divider />
          {data["igst"] ? (
            <FieldValue
              label={"IGST(" + data["igst"] + "%)"}
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          ) : (
            <FieldValue
              label={
                "CGST(" + data["cgst"] + "%) + SGST(" + data["sgst"] + "%)"
              }
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          )}
          <Divider />
          <FieldValue
            label={"Final Fee including GST"}
            value={"₹" + numberWithCommas(parseInt(data["Total Payment"]))}
            style={[styles.fieldItem]}
            labelStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
            valueStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
          />
        </View>
        <Text style={styles.descriptionText}>
          Subsequent Advisory Fee has to be paid in six months from the Invoice
          date
        </Text>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  boxContainer: {
    borderWidth: 0,
    borderColor: "#410DAA50",
    borderRadius: 15,
    marginVertical: 28,
    marginHorizontal: isMobile ? 16 : 35,
    paddingVertical: isMobile ? 15 : 27,
    paddingHorizontal: isMobile ? 12 : 39,
    minWidth: 800,
  },
  billBasicDetailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  majorHeadingContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  majorHeadingText: {
    color: "#464E5F",
    fontSize: 17,
    fontWeight: "bold",
  },
  feePercentageText: {
    color: "#6C7293",
    fontSize: 13,
    marginLeft: 6,
  },
  feePercentage: {
    color: "#464E5F",
    fontSize: 13,
    fontWeight: "700",
    paddingLeft: 4,
  },
  descriptionContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 11,
  },
  descriptionText: {
    color: "#6C7293",
    fontSize: 12,
    marginTop: 14,
  },
  planNumber: {
    color: "#4A4B68",
    fontSize: 11,
    marginLeft: 0,
  },
  auaContainer: {
    marginTop: 27,
    borderWidth: 1,
    borderColor: "#DBC0FF",
    borderRadius: 10,
  },
  detailsContainer: {
    marginTop: 21,
    borderWidth: 1,
    borderColor: "#DBC0FF",
    borderRadius: 10,
  },
  billContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 17.5,
  },
  advisoryHeading: {
    color: "#464E5F",
    fontSize: 15,
    fontWeight: "bold",
  },
  billingPeriod: {
    color: "#464E5F",
    fontSize: isMobile ? 10 : 15,
    fontWeight: "400",
    marginTop: 10,
  },
  feesAmount: {
    fontSize: 15,
    color: "#464E5F",
    fontWeight: "bold",
  },
  viewDetailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  viewDetails: {
    color: "#6C7293",
    fontSize: isMobile ? 12 : 14,
    marginRight: 10,
  },
  fieldItem: {
    paddingHorizontal: isMobile ? 16 : 75,
  },
  responsiveContainer: {
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
  },
  committedAua: {
    fontSize: 15,
    color: "#464E5F",
  },
  subDescription: {
    fontSize: 12,
    fontWeight: "400",
    marginTop: isMobile ? 4 : 0,
  },
  discountContainer: {
    marginTop: 6,
  },
});

export default ServiceBill;
