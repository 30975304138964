import React from "react";

import {
  View,
  ScrollView,
  Image,
  SafeAreaView,
  StyleSheet,
  Platform,
  Dimensions,
} from "react-native";
import { Button, Text } from "react-native-paper";

import { connect } from "react-redux";
import amplitude from "../../../utils/amplitude";
import {
  updateFinplanStatus,
  updateLastSeenScreen,
} from "../../../actions/finplan";
import { getFinplanDetails, postWhenCar } from "../../../api/finplan";
import { isMobile } from "../../../utils";
import PickerField from "../../../components/Common/Pickers/PickerField";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import NavigationHeader from "../../../components/Common/NavigationHeader";
const { width } = Dimensions.get("window");

class WhenBuyCar extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let type = null;
    if (params) {
      type = params.type || null;
    }
    this.state = {
      type,
      years: null,
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("whenBuyCar",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("whenBuyCar", { path: this.props.navigation.getState().routeName });
      }
      const response = await getFinplanDetails();
      let years = null;
      if (response.code == 100) {
        const { results } = response;
        const { years_to_own_car } = results;
        years = years_to_own_car;
      }
      const yearsData = this.generateData(1, 20);
      this.setState({ yearsData, years });
    } catch (error) {}
  }

  onPressNext = async () => {
    const { type, years } = this.state;
    const { params = {} } = this.props.route;
    const { ...restParams } = params;
    const is_valid_year = this.validateYears(years);
    if (is_valid_year) {
      this.setState({ error: null, isLoading: true });
      const payload = {
        years,
      };
      const response = await postWhenCar(payload);
      if (response && response.code == 100) {
        this.setState({ isLoading: false });
        this.props.navigation.navigate("BuyBike", {
          type,
          ...restParams,
        });
      }
    }
  };

  generateData = (min, max) => {
    let years = [];
    for (let i = min; i <= max; i++) {
      const num = i;
      years.push({
        label: num.toString(),
        value: num,
      });
    }
    return years;
  };

  validateYears = (years) => {
    let error = null;
    if (years > 100) {
      error = "Maximum number of years should be 100";
    } else if (years < 1) {
      error = "Minimum number of years should be 1";
    } else if (years == null) {
      error = "Enter valid number of years";
    }
    this.setState({ years, error });
    return error == null;
  };

  getYear = () => {
    const year = new Date().getFullYear();
    const { years } = this.state;
    return year + parseInt(years);
  };

  render() {
    const { yearsData = [], years = 1 } = this.state;
    return (
      <>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["When Buy Car"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <View style={[styles.page]}>
          <SafeAreaView style={{ flex: 1 }}>
            <ScrollView
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                flexGrow: 1,
                paddingBottom: 100,
                paddingTop: 60,
              }}
              keyboardShouldPersistTaps="handled"
            >
              <View style={{ paddingHorizontal: 16 }}>
                <View
                  style={{
                    flex: 0.3,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/car_finplan_image.png",
                    }}
                    style={{ width: 150, height: 150, alignSelf: "center" }}
                    resizeMode="contain"
                  />
                </View>
                <View style={{}}>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      marginBottom: 30,
                      marginTop: 30,
                      color: "#121212",
                      alignSelf: "center",
                    }}
                  >
                    When do you wish to buy a new car?
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignSelf: "center",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <Text style={{ marginRight: 10 }}>In </Text>
                    <PickerFieldSelect
                      data={yearsData}
                      value={years}
                      label={null}
                      onChange={(value) => {
                        this.setState({ years: value });
                      }}
                      pickerStyle={[{ width: isMobile ? 100 : 100 }]}
                      placeholder={{ value: "SEL", label: "Select" }}
                    />
                    <Text style={{ marginLeft: -190 }}>Years(as of now)</Text>
                  </View>

                  <Text
                    style={[
                      styles.errorMessage,
                      { alignSelf: "center", paddingTop: 10 },
                    ]}
                  >
                    {this.state.error || ""}
                  </Text>
                </View>
              </View>
              <Button
                uppercase={false}
                mode={"contained"}
                loading={this.state.isLoading}
                onPress={this.onPressNext}
                style={styles.nextButton}
                color="#410DAA"
              >
                <Text style={styles.next}>Next</Text>
              </Button>
            </ScrollView>
          </SafeAreaView>
        </View>
      </>
    );
  }
}
const styles = StyleSheet.create({
  page: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#fff",
    paddingBottom: 66,
  },
  formItem: {},
  inputItem: {
    width: isMobile ? "100%" : "auto",
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  nextButton: {
    width: width < 768 ? "96%" : 100,
    marginLeft: isMobile ? 5 : "7%",
    marginBottom: 0,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  errorMessage: {
    color: "red",
    fontSize: 14,
    paddingVertical: 5,
    fontFamily: "Roboto",
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateFinplanStatus: (type, status) =>
      dispatch(updateFinplanStatus(type, status)),
    updateLastSeenScreen: (type, screen, basic, end) =>
      dispatch(updateLastSeenScreen(type, screen, basic, end)),
  };
};

export default connect(null, mapDispatchToProps)(WhenBuyCar);
