import React, { useState, useEffect } from "react";
import { Route } from "./src/navigation/routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Linking,
  Platform,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import NetInfo from "@react-native-community/netinfo";
import Toast from "react-native-toast-message";
import { toastConfig } from "./src/components/Common/Toast";
import * as Font from "expo-font";
import { getNavigationData, isMobile } from "./src/utils";
import * as RootNavigation from "./RootNavigation.js";
const CodePush =
  Platform.OS == "web" ? null : require("react-native-code-push");
import AsyncStorage from "@react-native-async-storage/async-storage";
import { storeTokenData } from "./src/api";
import { clearNotification } from "./src/api/alerts";
// import { initAmplitudeInstance } from "./src/utils/amplitude";
const { width } = Dimensions.get("window");
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { Notifications } from "react-native-notifications";
import Store from "./src/store/index.js";
// if(Platform.OS != "web"){
//   const messaging = require("@react-native-firebase/messaging");
// }

let CodePushOptions = {};
if (CodePush) {
  CodePushOptions = {
    checkFrequency: CodePush.CheckFrequency.ON_APP_RESUME,
    mandatoryInstallMode: CodePush.InstallMode.IMMEDIATE,
  };
}

function App() {
  const [activeTab, setActiveTab] = useState(1);
  const [modalVisibility, setModalVisibility] = useState(false);
  // const [processText, setProcessText] = useState("Process Text");
  const [showBottomNav, setShowBottomNav] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    // NetInfo.addEventListener((state) =>
    //   handleConnectionChange(state.isConnected)
    // );
    const subscribe = Linking.addEventListener("url", handleUrl);
    loadFonts();
    Platform.OS != "web" && checkCodepushUpdate();
    Platform.OS != "web" && getDeviceToken();
    getNotificationPermission();
    // amplitudeInstance();
    // handleForegroundPush();

    Linking.getInitialURL()
      .then((url) => {
        if (url) {
          handleUrl({ url });
        }
      })
      .catch(() => {});

    return () => {
      subscribe && subscribe.remove();
      // NetInfo.removeEventListener();
    };
  }, []);

  const getNotificationPermission = async () => {
    try {
      let authorizationStatus = null;
      if (Platform.OS != "web") {
        // authorizationStatus = await messaging().requestPermission();
      }

      if (authorizationStatus) {
      }
    } catch (error) {}
  };

  // const handleForegroundPush = () => {
  //   messaging().onMessage(async (message) => {
  //     const { notification = null, data = null } = message;
  //     if (notification && data) {
  //       const { body = null, title = null } = notification;
  //       const { payload = null, pushnotification_id = null } = data;
  //       if (body && title) {
  //         Notifications.postLocalNotification({
  //           body: body,
  //           title: title,
  //           fireDate: new Date(),
  //         });
  //       }

  //       let promises = [];
  //       if(payload){
  //         promises.push(AsyncStorage.setItem("NAVIGATION", payload));
  //       }
  //       if (pushnotification_id) {
  //         promises.push(AsyncStorage.setItem("PUSHNOTIFID", pushnotification_id));
  //       }
  //       const result = await Promise.allSettled(promises);
  //       if(result){
  //         // handlePushData();
  //       }
  //     }
  //   });
  // };

  const amplitudeInstance = async () => {
    // await initAmplitudeInstance();
  };

  const handleUrl = ({ url }) => {
    try {
      Linking.canOpenURL(url).then((supported) => {
        if (supported) {
          const { error, ...rest } = getNavigationData(url);
          if (!error) {
            const { name = null, params = {} } = rest;
            if (name) {
              setTimeout(() => {
                RootNavigation.navigate(name, { ...params });
              }, 1000);
            }
          }
        } else {
        }
      });
    } catch (error) {}
  };

  const checkCodepushUpdate = async () => {
    try {
      const update = await CodePush.checkForUpdate();
      if (update) {
        setModalVisibility(true);
      }
      CodePush.sync(
        {
          updateDialog: false,
          mandatoryInstallMode: CodePush.InstallMode.IMMEDIATE,
        },
        (status) => {
          switch (status) {
            case CodePush.SyncStatus.DOWNLOADING_PACKAGE:
              // setProcessText("Downloading");
              // this.setState({showDownloadingModal: true, processText: "Downloading"});
              // this.refs.modal.open();
              break;
            case CodePush.SyncStatus.INSTALLING_UPDATE:
              // setProcessText("Installing");
              // this.setState({showInstalling: true, processText: "Installing"});
              break;
            case CodePush.SyncStatus.UPDATE_INSTALLED:
              setTimeout(() => {
                setModalVisibility(false);
                CodePush.restartApp();
              }, 300);
              // this.refs.modal.close();
              // this.setState({showDownloadingModal: false, processText: "Installed"});
              break;
          }
        },
        ({ receivedBytes, totalBytes }) => {
          // setProcessText("Downloading percentage: "+ receivedBytes / totalBytes * 100);
          // this.setState({downloadProgress: receivedBytes / totalBytes * 100, processText: "Downloading percentage: "+ receivedBytes / totalBytes * 100});
        }
      );
    } catch (error) {
      // setProcessText("Error Processing")
    }
  };

  const loadFonts = async () => {
    await Font.loadAsync({
      Roboto: require("./src/assets/fonts/Roboto-Regular.ttf"),
      "Open Sans": require("./src/assets/fonts/OpenSans-Regular.ttf"),
    });
    setFontsLoaded(true);
  };

  const checkAuth = (payload) => {
    // if (payload == "userAuthenticated") {
    //   setShowBottomNav(true);
    // } else {
    //   setShowBottomNav(false);
    //   setActiveTab(1);
    // }
  };

  const getDeviceToken = async () => {
    // await messaging().registerDeviceForRemoteMessages();

    // messaging().setAutoInitEnabled(true);
    // messaging().getInitialNotification(async (remoteMessage) => {
    //   if (remoteMessage && remoteMessage.data && remoteMessage.data.payload) {
    //     await AsyncStorage.setItem("NAVIGATION", remoteMessage.data.payload);
    //   }
    //   if (
    //     remoteMessage &&
    //     remoteMessage.data &&
    //     remoteMessage.data.pushnotification_id
    //   ) {
    //     await AsyncStorage.setItem(
    //       "PUSHNOTIFID",
    //       remoteMessage.data.pushnotification_id
    //     );
    //   }
    // });

    // messaging().onNotificationOpenedApp(async (remoteMessage) => {
    //   if (remoteMessage && remoteMessage.data && remoteMessage.data.payload) {
    //     await AsyncStorage.setItem("NAVIGATION", remoteMessage.data.payload);
    //   }
    //   if (
    //     remoteMessage &&
    //     remoteMessage.data &&
    //     remoteMessage.data.pushnotification_id
    //   ) {
    //     await AsyncStorage.setItem(
    //       "PUSHNOTIFID",
    //       remoteMessage.data.pushnotification_id
    //     );
    //   }
    //   handlePushData();

    //   let pushData = await AsyncStorage.getItem("NAVIGATION");
    //   let navRoot;
    //   let navStack;
    //   if (pushData) {
    //     const { name, params } = getNavigationData(pushData);
    //     navRoot = name;
    //     navStack = params;

    //     AsyncStorage.removeItem("NAVIGATION");
    //     RootNavigation.navigate(navRoot, navStack);
    //   }
    // });

    // const token = await messaging().getToken();
    // await AsyncStorage.setItem("tokenValue", token);

    // const payload = {
    //   device_token: token.toString(),
    // };
    // await storeTokenData(payload);

    // messaging().setBackgroundMessageHandler(async (remoteMessage) => {
    //   if (remoteMessage && remoteMessage.data && remoteMessage.data.payload) {
    //     await AsyncStorage.setItem("NAVIGATION", remoteMessage.data.payload);
    //   }
    //   if (
    //     remoteMessage &&
    //     remoteMessage.data &&
    //     remoteMessage.data.pushnotification_id
    //   ) {
    //     await AsyncStorage.setItem(
    //       "PUSHNOTIFID",
    //       remoteMessage.data.pushnotification_id
    //     );
    //   }
    // });

    handlePushData();
  };

  const handlePushData = async () => {
    let pushData = await AsyncStorage.getItem("NAVIGATION");
    let pushNotificationId = await AsyncStorage.getItem("PUSHNOTIFID");

    if (pushNotificationId) {
      const payload = {
        notification_id: pushNotificationId,
      };
      await clearNotification(payload);
    }

    if (pushData) {
      const [host, path] = pushData.split("://");
      if (path) {
        const [stack, screenName, ...rest] = path.split("/");
        if (screenName == "Dashboard") {
          setActiveTab(1);
        }
        if (screenName == "Portfolio") {
          setActiveTab(2);
        }
        if (screenName == "Plans") {
          setActiveTab(3);
        }
        if (screenName == "Goals") {
          setActiveTab(4);
        }
        if (screenName == "Explore") {
          setActiveTab(5);
        }
      }
    }
  };

  const handleConnectionChange = (isConnected) => {
    const { userData } = Store.getState();
    if (userData.isConnected == isConnected) return;
    else
      Store.dispatch({
        type: "CONNECTION_STATUS_CHANGE",
        payload: { isConnected },
      });
  };

  const handleClick = (screen, subscreen) => {
    RootNavigation.navigate("App", {
      screen: "Dashboard",
      params: {
        screen,
        params: {
          screen: subscreen,
          params: {},
        },
      },
    });
  };

  if (!fontsLoaded) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size={16} color="#410DAA" />
      </View>
    );
  }

  return (
    <Provider store={Store}>
      <StatusBar backgroundColor="#000" barStyle="light-content" />
      <View
        style={{
          width: "100%",
          height: width < 1200 ? "100.1%" : "100%",
          alignSelf: "center",
        }}
      >
        <Route modalVisibility={modalVisibility} checkAuth={checkAuth} />
      </View>
      {false && showBottomNav ? (
        <View
          style={{
            height: 66,
            backgroundColor: "white",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
          }}
        >
          <View
            style={{
              height: 0.5,
              width: "100%",
              backgroundColor: "#92929250",
            }}
          />
          <View
            style={{
              marginTop: 12,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <TouchableOpacity
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={() => {
                setActiveTab(1);
                handleClick("Dashboard", "Home");
              }}
            >
              <Image
                source={{
                  uri:
                    activeTab == 1
                      ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/home_active.png"
                      : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/home.png",
                }}
                style={{
                  width: 20,
                  height: 20,
                  resizeMode: "contain",
                  color: activeTab == 1 ? "#410DAA" : "#929292",
                }}
              />
              <Text style={{ color: activeTab == 1 ? "#410DAA" : "#929292" }}>
                Home
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={() => {
                setActiveTab(2);
                handleClick("Portfolio", "InvestmentOverview");
              }}
            >
              <Image
                source={{
                  uri:
                    activeTab == 2
                      ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolio_active.png"
                      : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolio.png",
                }}
                style={{
                  width: 20,
                  height: 20,
                  resizeMode: "contain",
                  color: activeTab == 2 ? "#410DAA" : "#929292",
                }}
              />
              <Text style={{ color: activeTab == 2 ? "#410DAA" : "#929292" }}>
                Portfolio
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={() => {
                setActiveTab(3);
                handleClick("Plans", "InsightsHome");
              }}
            >
              <MaterialIcons
                name="lightbulb-outline"
                size={20}
                color={activeTab == 3 ? "#410DAA" : "#c1cfe0"}
              />
              <Text style={{ color: activeTab == 3 ? "#410DAA" : "#929292" }}>
                Insights
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={() => {
                setActiveTab(4);
                handleClick("Goals", "Goals");
              }}
            >
              <Image
                source={{
                  uri:
                    activeTab == 4
                      ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/goals_active.png"
                      : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/goals.png",
                }}
                style={{
                  width: 20,
                  height: 20,
                  resizeMode: "contain",
                  color: activeTab == 4 ? "#410DAA" : "#929292",
                }}
              />
              <Text style={{ color: activeTab == 4 ? "#410DAA" : "#929292" }}>
                Goals
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={() => {
                setActiveTab(5);
                handleClick("Explore", "MFExplore");
              }}
            >
              <Image
                source={{
                  uri:
                    activeTab == 5
                      ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/explore_active.png"
                      : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/explore.png",
                }}
                style={{
                  width: 20,
                  height: 20,
                  resizeMode: "contain",
                  color: activeTab == 5 ? "#410DAA" : "#929292",
                }}
              />
              <Text style={{ color: activeTab == 5 ? "#410DAA" : "#929292" }}>
                Explore
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      ) : null}
      <Toast config={toastConfig} ref={(ref) => Toast.setRef(ref)} />
    </Provider>
  );
}

export default CodePush ? CodePush(CodePushOptions)(App) : App;
