import React, { useState, useEffect } from "react";
import {View, Text, TouchableOpacity, StyleSheet} from "react-native";
import { isMobile } from "../../utils";

export const ProductTabs = ({ tabs = [], onSelectTab, data = {}, activeTab=0, style = [], color="#410DAA" }) => {
    const [ tabIndex, setTabIndex ] = useState(activeTab);
    
    useEffect(() => {
        setTabIndex(activeTab);
    }, [activeTab]);
    
    const handleSelectTab = (index) => {
        setTabIndex(index);
        onSelectTab(index);
    }

    return(
        <View style={[styles.container, ...style]}>
            {
                tabs.map((tab,index)=>{
                    return(
                        <TouchableOpacity key={tab.value} onPress={()=>{
                            if(tabIndex!=index){
                                handleSelectTab(index)
                            }
                        }}>
                            <Text style={[styles.tabItem,{color:tabIndex== index ? color : "#888C9F", borderBottomColor: tabIndex==index ? color :"transparent", fontWeight: tabIndex== index ? "400": "400"}]}>
                                {tab.label}{data && data[tab.value] ? `(${data[tab.value].plans.length})` : null}
                            </Text>
                        </TouchableOpacity>
                    )
                })
            }
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        flexDirection:"row",
        borderBottomColor:"#E5E5E5",
        borderBottomWidth:1,
        alignSelf: "flex-start",
        paddingLeft: isMobile ? 0 : 10
    },
    tabItem: {
        fontSize:14,
        paddingHorizontal: isMobile ? 10 : 30,
        paddingVertical:12,
        borderBottomWidth:3,
        marginLeft: 30
    }
})