import React from "react";
import {
  ActivityIndicator,
  Dimensions,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Image,
  View,
  Linking,
  FlatList,
} from "react-native";
import { Text } from "react-native-paper";
import { Entypo, FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import SideHeading from "../../components/InvestmentOverview/NewDesign/components/SideHeading";
import {
  completeMinDetails,
  formatNumberToAbbreviation,
  getScreenConfigForCustomer,
  isJama,
  isMobile,
  reverseString,
} from "../../utils";
import { AttentionStrip } from "./attentionStrip";
import PortfolioStrip from "./portfolioStrip";
import { Octicons } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import {
  DashboardCountsGoadlsFunds,
  generateAgreementPdf,
  generateCamsAggregatorLink,
  getAccountAggregatorConsentStatus,
  getAdvisoryTracker,
  getCipsAboutToExpire,
  getCustomerReport,
  getDashboard,
  getMFData,
  getTransactionPopUp,
  planRenewalForClients,
  updateCamsWebhookResponseFromCai,
} from "../../api";
import { getAdvisorDashboardDetials } from "../../api/goals";
import { getUserDetails } from "../../utils";
import { TopupStrip } from "./topUpStrip";
import { BookAdvisorStrip } from "./bookAdvisorStrip";
import GetRicherStrip from "./getRicherStrip";
import GrowWealthStrip from "./growWealthStrip";
import InvestFutureStrip from "./investFutureStrip";
import JamaAcademyStrip from "./jamaAcademyStrip";
import AssessWellnessStrip from "./assessWellnessStrip";
import { PopUp } from "../../components/Common/PopUp";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../api/apiConfig";
import { MAIN_URL } from "../../api/urls";
import ComplianceModal from "../../components/InvestmentOverview/ComplianceModal";
import { connect } from "react-redux";
import handleTopup from "../../utils/handleTopup";
import amplitude from "../../utils/amplitude";
import { ShowToast } from "../../components/Common/Toast";
import EmotionalWellnessStrip from "./emotionalWellnessStrip";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;
const { width } = Dimensions.get("window");
import moment from "moment";
import DeviceInfo from "react-native-device-info";
import AdvisoryClubStrip from "./advisoryClubStrip";
import EliteCircleStrip from "./eliteCircleStrip";
import { AntDesign } from "@expo/vector-icons";
import NoPlanModal from "../../components/InvestmentOverview/noPlanModal";
import ProgressBar from "../../utils/progressBar";
import { SHOW_CORPORATE_STICKER, USER_IMPORT_FUNDS } from "../../actions/types";
import PortfolioCardView from "../../components/InvestmentOverview/NewDesign/PortfolioCardView";
import { saveCustomerProfileData } from "../../api/account";
import Modal from "../../components/Common/Modal";
import Store from "../../store";

const plans_Json = {
  BP: {
    What_is_involved: [
      "a. Assessments to measure Financial Wellness on Money management, Financial protection, Goal planning and retirement planning.",
      "b. eLearning Modules on Personal Finance, Budgeting, Investing, Goal Planning, Retirement Planning",
    ],
    Benefits: [
      "a. Understand current state and identify steps to impvove financial wellness ",
      "b. Improve knowledge on financial planning concepts.",
    ],
    msg: "Improve wellness, increase financial knowhow and do more!",
    redirection_url: "https://club.jamawealth.com",
    header:
      "Welcome! You have subscribed to  Financial Wellness Assessment & eLearning",
  },
  PPH: {
    What_is_involved: [
      "a. AI powered investment platform to make and track financial plan & investments ",
      "b. Investment in direct mutual funds - No commission, No brokerage, No hidden fees",
    ],
    Benefits: [
      "a. Cost Savings: Maximise your returns by saving on commission and brokerage fees.Investing in direct plans translates to better returns, as the savings from commission and fees add up over time.",
      "b. Transparency: What you see is what you get, with zero hidden charges, ensuring honest investment practices.",
      "c. Goal planning: Online goal planning helps tag all investments and assets to your goals. Enjoy peace of mind when you see goals on track.",
    ],
    msg: "Make a financial plan & invest for goals, Assess financial wellness, increase financial knowhow and do more!",
    redirection_url: "DashBoard",
    header:
      "Welcome! You have subscribed to Online Advisory for  Financial Planning & Implementation - Half Yearly",
  },
  PP: {
    What_is_involved: [
      "a. AI powered investment platform to make and track financial plan & investments",
      "b. Investment in direct mutual funds - No commission, No brokerage, No hidden fees.",
    ],
    Benefits: [
      "a. Cost Savings: Maximise your returns by saving on commission and brokerage fees.Investing in direct plans translates to better returns, as the savings from commission and fees add up over time.",
      "b. Transparency: What you see is what you get, with zero hidden charges, ensuring honest investment practices.",
      "c. Goal planning: Online goal planning helps tag all investments and assets to your goals. Enjoy peace of mind when you see goals on track.",
    ],
    msg: "Make a financial plan & invest for goals, Assess financial wellness, increase financial knowhow and do more!",
    redirection_url: "DashBoard",
    header:
      "Welcome! You have subscribed to Online Advisory for  Financial Planning & Implementation - Yearly",
  },
  PLQ: {
    What_is_involved: [
      "a. Analyze current life insurance and ULIPs vs Required Amount.",
      "b. Compare against market-leading products & performance metrics.",
      "c. Recommend adjustments or replacements.",
    ],
    Benefits: [
      "a. Ensures maximum returns on premiums and investments.",
      "b. Provides peace of mind with optimised coverage.",
      "c. Aligns insurance portfolio with expectations.",
    ],
    msg: "We will help with review of your life insurance poliices. To set context, pl create your financial plan.",
    redirection_url: "DashBoard",
    header:
      "Welcome! You have subscribed to Life Insurance Planning - Quarterly",
  },
  PLH: {
    What_is_involved: [
      "a. Personalised Financial Plan for life goals: Tailored financial planning designed to align with your unique life goals.",
      "b. Retirement Corpus Planning: Strategic planning to build a sufficient retirement fund, ensuring a secure and comfortable retirement.",
      "c. Children's future plan (education, marriage): Dedicated financial strategies to secure your children's education and marriage expenses.",
      "d. Other family goals: Customized planning for various family goals, be it buying a home, travel, or any other personal objectives.",
      "e. Investment plan with Mutual Fund Portfolio Allocation: A comprehensive investment strategy with diversified MF portfolio allocation to optimise returns.",
      "f. Quarterly review of portfolio's ability to meet goals: Regular assessments to ensure if you are on track to meet your life goals. This means two calls in the plan period.",
    ],
    Benefits: [
      "a. Personalised Approach: Tailored financial planning that caters to your specific life goals, ensuring a more effective and relevant investment strategy.",
      "b. Comprehensive Goal Coverage: From retirement to your children's future and other family aspirations, all your goals are comprehensively covered.",
      "c. Strategic MF Portfolio Allocation: Expertly crafted MF investment plan with a focus on portfolio diversification to manage risk and optimize returns.",
      "d. Regular Monitoring: Quarterly Review of your portfolio to adapt to any life changes or market fluctuations, ensuring your goals remain achievable.",
      "e. Peace of Mind: With a clear strategy and regular monitoring, you can enjoy peace of mind knowing your financial future is well planned.",
      "f. Cost Savings: Maximise your returns by saving on commission and brokerage fees.Investing in direct plans translates to better returns, as the savings from commission and fees add up over time.",
      "g. Transparency: What you see is what you get, with zero hidden charges, ensuring honest investment practices.",
    ],
    header:
      "Welcome! You have subscribed to Comprehensive Investment Planning For All Life Goals - Half Yearly",
    redirection_url: "DashBoard",
    msg: "Make a financial plan & invest for goals, Assess financial wellness, increase financial knowhow and do more!",
  },
  PLA: {
    What_is_involved: [
      "a. Personalised Financial Plan for life goals: Tailored financial planning designed to align with your unique life goals.",
      "b. Retirement Corpus Planning: Strategic planning to build a sufficient retirement fund, ensuring a secure and comfortable retirement.",
      "c. Children's future plan (education, marriage): Dedicated financial strategies to secure your children's education and marriage expenses.",
      "d. Other family goals: Customized planning for various family goals, be it buying a home, travel, or any other personal objectives.",
      "e. Investment plan with Mutual Fund Portfolio Allocation: A comprehensive investment strategy with diversified MF portfolio allocation to optimise returns.",
      "f. Quarterly review of portfolio's ability to meet goals: Regular assessments to ensure if you are on track to meet your life goals. This means four calls in the plan period.",
    ],
    Benefits: [
      "a. Personalised Approach: Tailored financial planning that caters to your specific life goals, ensuring a more effective and relevant investment strategy.",
      "b. Comprehensive Goal Coverage: From retirement to your children's future and other family aspirations, all your goals are comprehensively covered.",
      "c. Strategic MF Portfolio Allocation: Expertly crafted MF investment plan with a focus on portfolio diversification to manage risk and optimize returns.",
      "d. Regular Monitoring: Quarterly Review of your portfolio to adapt to any life changes or market fluctuations, ensuring your goals remain achievable.",
      "e. Peace of Mind: With a clear strategy and regular monitoring, you can enjoy peace of mind knowing your financial future is well planned.",
      "f. Cost Savings: Maximise your returns by saving on commission and brokerage fees.Investing in direct plans translates to better returns, as the savings from commission and fees add up over time.",
      "g. Transparency: What you see is what you get, with zero hidden charges, ensuring honest investment practices.",
    ],
    header:
      "Welcome! You have subscribed to Comprehensive Investment Planning For All Life Goals - Yearly",
    redirection_url: "DashBoard",
    msg: "Make a financial plan & invest for goals, Assess financial wellness, increase financial knowhow and do more!",
  },
  PHQ: {
    What_is_involved: [
      "a. Assess current health insurance policies for gaps or redundancies.",
      "b. Benchmark against prevailing health industry standards.",
      "c. Offer tailored recommendations for better coverage or cost-saving.",
    ],
    Benefits: [
      "a. Guarantees comprehensive medical coverage.",
      "b. Avoids overpaying for unnecessary features.",
      "c. Ensures family health emergencies are financially manageable.",
    ],
    msg: "We will help with review of your health insurance poliices. To set context, pl create your financial plan.",
    redirection_url: "DashBoard",
    header:
      "Welcome! You have subscribed to Health Insurance Planning - Quarterly",
  },
  PMQ: {
    What_is_involved: [
      "a. Evaluate the current composition and performance of mutual funds.",
      "b. Analyze market trends and sector performance.",
      "c. Suggest reallocations or new investment opportunities.",
    ],
    Benefits: [
      "a. Maximises investment returns.",
      "b. Diversifies portfolio to spread and mitigate risks.",
      "c. Aligns investments with market opportunities and expected returns basis risk profile.",
    ],
    msg: "We will help with review of your mutual fund portfolios. To get started, please import your portfolio.",
    redirection_url: "ImportStock",
    header:
      "Welcome! You have subscribed to Mutual Fund Portfolio Review -  Quarterly",
  },
  PRQ: {
    What_is_involved: [
      "a. Understand client's retirement goals, lifestyle desires, and financial status.",
      "b. Forecast future expenses, inflation rates, and savings growth.",
      "c. Devise a tailored retirement plan with savings and investment strategies.",
    ],
    Benefits: [
      "a. Ensures a comfortable post-retirement life.",
      "b. Mitigates the risk of outliving savings.",
      "c. Provides a clear financial roadmap for retirement preparation.",
    ],
    msg: "We will help with your retirement planning. Pleasel create your retirement goal now.",
    redirection_url: "RETIREMENT",
    header: "Welcome! You have subscribed to Retirement Planning - Quarterly",
  },

  PSQ: {
    What_is_involved: [
      "a. Analyse the current stock holdings and their market performance.",
      "b. Research industry trends and company fundamentals.",
      "c. Propose portfolio adjustments based on findings and client risk tolerance.",
    ],
    Benefits: [
      "a. Enhances portfolio returns.",
      "b. Ensures a balanced stock mix to optimise risk and reward.",
      "c. Keeps investments aligned with the latest market insights.",
    ],
    msg: "We will help with review of your stock portfolios. To get started, please import your portfolio.",
    redirection_url: "ImportStock",
    header:
      "Welcome! You have subscribed to Stock Portfolio Review - Quarterly",
  },

  PCQ: {
    What_is_involved: [
      "a. Discuss and understand specific goals for child's education (overseas or India) and/or marriage.",
      "b. Estimate future costs considering inflation and other variables.",
      "c. Design a savings and investment blueprint to meet the estimated costs.",
    ],
    Benefits: [
      "a. Improves financial readiness for key life events.",
      "b. Avoids last-minute financial crunches or loans.",
      "c. Allows peace of mind, knowing your child's key life events are financially secured.",
    ],
    msg: "We will help with your chikd goal planning. Please create your child goal now.",
    redirection_url: "CHILDREN",
    header: "Welcome! You have subscribed to Child Goal Planning: - Quarterly",
  },
  PLB: {
    What_is_involved: [
      "a. Analyze current life insurance and ULIPs vs Required Amount.",
      "b. Compare against market-leading products & performance metrics.",
      "c. Recommend adjustments or replacements.",
    ],
    Benefits: [
      "a. Ensures maximum returns on premiums and investments.",
      "b. Provides peace of mind with optimised coverage.",
      "c. Aligns insurance portfolio with expectations.",
    ],
    msg: "We will help with review of your life insurance poliices. To set context, pl create your financial plan.",
    redirection_url: "Dashboard",
    header:
      "Welcome! You have subscribed to Life Insurance Planning - Half Yearly",
  },

  PHH: {
    What_is_involved: [
      "a. Assess current health insurance policies for gaps or redundancies.",
      "b. Benchmark against prevailing health industry standards.",
      "c. Offer tailored recommendations for better coverage or cost-saving.",
    ],
    Benefits: [
      "a. Guarantees comprehensive medical coverage.",
      "b. Avoids overpaying for unnecessary features.",
      "c. Ensures family health emergencies are financially manageable.",
    ],
    msg: "We will help with review of your health insurance poliices. To set context, pl create your financial plan.",
    redirection_url: "Dashboard",
    header:
      "Welcome! You have subscribed to Health Insurance Planning - Half Yearly",
  },

  PMH: {
    What_is_involved: [
      "a. Evaluate the current composition and performance of mutual funds.",
      "b. Analyze market trends and sector performance.",
      "c. Suggest reallocations or new investment opportunities.",
    ],
    Benefits: [
      "a. Maximises investment returns.",
      "b. Diversifies portfolio to spread and mitigate risks.",
      "c. Aligns investments with market opportunities and expected returns basis risk profile.",
    ],
    msg: "We will help with review of your mutual fund portfolios. To get started, please import your portfolio",
    redirection_url: "ImportStock",
    header:
      "Welcome! You have subscribed to Mutual Fund Portfolio Review -  Half Yearly",
  },

  PRH: {
    What_is_involved: [
      "a. Understand client's retirement goals, lifestyle desires, and financial status.",
      "b. Forecast future expenses, inflation rates, and savings growth.",
      "c. Devise a tailored retirement plan with savings and investment strategies.",
    ],
    Benefits: [
      "a. Ensures a comfortable post-retirement life.",
      "b. Mitigates the risk of outliving savings.",
      "c. Provides a clear financial roadmap for retirement preparation.",
    ],
    msg: "We will help with your retirement planning. Pleasel create your retirement goal now.",
    redirection_url: "RETIREMENT",
    header: "Welcome! You have subscribed to Retirement Planning - Half Yearly",
  },

  PSH: {
    What_is_involved: [
      "a. Analyse the current stock holdings and their market performance.",
      "b. Research industry trends and company fundamentals.",
      "c. Propose portfolio adjustments based on findings and client risk tolerance.",
    ],
    Benefits: [
      "a. Enhances portfolio returns.",
      "b. Ensures a balanced stock mix to optimise risk and reward.",
      "c. Keeps investments aligned with the latest market insights.",
    ],
    msg: "We will help with review of your stock portfolios. To get started, please import your portfolio.",
    redirection_url: "ImportStock",
    header:
      "Welcome! You have subscribed to Stock Portfolio Review - Half Yearly",
  },

  PCH: {
    What_is_involved: [
      "a. Discuss and understand specific goals for child's education (overseas or India) and/or marriage.",
      "b. Estimate future costs considering inflation and other variables.",
      "c. Design a savings and investment blueprint to meet the estimated costs.",
    ],
    Benefits: [
      "a. Improves financial readiness for key life events.",
      "b. Avoids last-minute financial crunches or loans.",
      "c. Allows peace of mind, knowing your child's key life events are financially secured.",
    ],
    msg: "We will help with your chikd goal planning. Please create your child goal now.",
    redirection_url: "CHILDREN",
    header:
      "Welcome! You have subscribed to Child Goal Planning: - Half Yearly",
  },

  PLY: {
    What_is_involved: [
      "a. Analyze current life insurance and ULIPs vs Required Amount.",
      "b. Compare against market-leading products & performance metrics.",
      "c. Recommend adjustments or replacements.",
    ],
    Benefits: [
      "a. Ensures maximum returns on premiums and investments.",
      "b. Provides peace of mind with optimised coverage.",
      "c. Aligns insurance portfolio with expectations.",
    ],
    msg: "We will help with review of your life insurance poliices. To set context, pl create your financial plan.",
    redirection_url: "Dashboard",
    header: "Welcome! You have subscribed to Life Insurance Planning - Yearly",
  },

  PHA: {
    What_is_involved: [
      "a. Assess current health insurance policies for gaps or redundancies.",
      "b. Benchmark against prevailing health industry standards.",
      "c. Offer tailored recommendations for better coverage or cost-saving.",
    ],
    Benefits: [
      "a. Guarantees comprehensive medical coverage.",
      "b. Avoids overpaying for unnecessary features.",
      "c. Ensures family health emergencies are financially manageable.",
    ],
    msg: "We will help with review of your health insurance poliices. To set context, pl create your financial plan.",
    redirection_url: "Dashboard",
    header:
      "Welcome! You have subscribed to Health Insurance Planning - Yearly",
  },

  PMA: {
    What_is_involved: [
      "a. Evaluate the current composition and performance of mutual funds.",
      "b. Analyze market trends and sector performance.",
      "c. Suggest reallocations or new investment opportunities.",
    ],
    Benefits: [
      "a. Maximises investment returns.",
      "b. Diversifies portfolio to spread and mitigate risks.",
      "c. Aligns investments with market opportunities and expected returns basis risk profile.",
    ],
    msg: "We will help with review of your mutual fund portfolios. To get started, please import your portfolio.",
    redirection_url: "ImportStock",
    header:
      "Welcome! You have subscribed to Mutual Fund Portfolio Review - Yearly",
  },

  PRA: {
    What_is_involved: [
      "a. Understand client's retirement goals, lifestyle desires, and financial status.",
      "b. Forecast future expenses, inflation rates, and savings growth.",
      "c. Devise a tailored retirement plan with savings and investment strategies.",
    ],
    Benefits: [
      "a. Ensures a comfortable post-retirement life.",
      "b. Mitigates the risk of outliving savings.",
      "c. Provides a clear financial roadmap for retirement preparation.",
    ],
    msg: "We will help with your retirement planning. Pleasel create your retirement goal now.",
    redirection_url: "RETIREMENT",
    header: "Welcome! You have subscribed to Retirement Planning - Yearly",
  },
  PSA: {
    What_is_involved: [
      "a. Analyse the current stock holdings and their market performance.",
      "b. Research industry trends and company fundamentals.",
      "c. Propose portfolio adjustments based on findings and client risk tolerance.",
    ],
    Benefits: [
      "a. Enhances portfolio returns.",
      "b. Ensures a balanced stock mix to optimise risk and reward.",
      "c. Keeps investments aligned with the latest market insights.",
    ],
    msg: "We will help with review of your stock portfolios. To get started, please import your portfolio.",
    redirection_url: "ImportStock",
    header: "Welcome! You have subscribed to Stock Portfolio Review -  Yearly",
  },
  PCA: {
    What_is_involved: [
      "a. Discuss and understand specific goals for child's education (overseas or India) and/or marriage.",
      "b. Estimate future costs considering inflation and other variables.",
      "c. Design a savings and investment blueprint to meet the estimated costs.",
    ],
    Benefits: [
      "a. Improves financial readiness for key life events.",
      "b. Avoids last-minute financial crunches or loans.",
      "c. Allows peace of mind, knowing your child's key life events are financially secured.",
    ],
    msg: "We will help with your child goal planning. Please create your child goal now.",
    redirection_url: "CHILDREN",
    header: "Welcome! You have subscribed to Child Goal Planning - Yearly",
  },
};

class NewHomeDashboard extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let redirect = false;
    // console.log("params is ", params);
    if (params && params.hasOwnProperty("redirect")) {
      redirect = params.redirect;
    }

    let userId = null;
    let showToast = null;
    let showFailedToast = null;
    let showToastMessage = null;
    let toastMessage = null;
    let showAggregratorSuccess = false;
    if (params && params.ecres) {
      showAggregratorSuccess = true;
    }
    let show_card_view = false;
    if (params) {
      userId = params.user_id;
      showToast = params.showToast;
      showFailedToast = params.showFailedToast;
      showToastMessage = params.showToastMessage;
      toastMessage = params.toastMessage;
      show_card_view = params.show_card_view || false;
      if (show_card_view && Platform.OS == "web") {
        global.location.reload();
      }
    }
    if (
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }
    let is_preferred_circle = false;
    let is_club = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    if (hostname.includes("club")) {
      is_club = true;
    }
    if (localStorage.getItem("isShowAppDownloadCard") != "false") {
      localStorage.setItem("isShowAppDownloadCard", "true");
    }
    if (localStorage.getItem("showPlanRenewalCard") != "false") {
      localStorage.setItem("showPlanRenewalCard", "true");
    }
    let { dashboard_portfolio_analysis_closed } =
      Store.getState().userData.userProfileData;
    this.state = {
      isViewVisible: true,
      isLoading: true,
      error: null,
      show_corp_strip: false,
      redirect: redirect,
      dashboard_portfolio_analysis_closed,
      show_card_view,
      PortfolioSummaryData: null,
      showAggregratorSuccess,
      aggregatorResponse: "Pending",
      goalArray: [],
      stockArray: [],
      mfArray: [],
      goalsAmount: 0,
      stockTypes: {},
      mfTypes: {},
      userId,
      isShowAppDownloadCard: localStorage.getItem("isShowAppDownloadCard"),
      is_EquityUser: true,
      is_MfUser: true,
      cams_status: "",
      is_corporate_admin: false,
      totalEuities: 0,
      totalFunds: 0,
      completeMfData: null,
      timer: 0,
      showToast,
      showFailedToast,
      is_ria: false,
      equitiesArray: [],
      fundArray: [],
      current_subscription_plan: "",
      endDate: "",
      availedCount: "0",
      totalCount: "0",
      is_preferred_circle,
      is_club,
      is_goal_avl: false,
      show_corporate_sticker: false,
      userData: [],
      showPlanRenewalCard: localStorage.getItem("showPlanRenewalCard"),
      plan_renewal_text: "",
      plan_renewal_type: "",
      show_renew_now_btn: true,
      show_upgrade_btn: true,
      cip_pk_for_renewal: null,
      showToastMessage: showToastMessage,
      toastMessage: toastMessage,
      show_choose_your_plan: false,
      show_corp_plan_detail: false,
      secondsRemaining: 10,
      timerExecuted: false,
    };
  }

  async checkDashboardCountsGoadlsFunds(userId) {
    let {
      current_subscription_plan,
      customer_id,
      corporate_id,
      is_tagged_to_corporate,
      life_insurance_goal_exists,
      health_insurance_goal_exists,
      outside_mf_exists,
      retirement_goal_exists,
      outside_stocks_exists,
      child_related_goal_exits,
      finwellness_exists,
      jama_academy_progress_exists,
    } = Store.getState().userData.userProfileData;
    // const payload = {
    //   previous_key: "DASHBOARD_COUNTS_GOALS_FUNDS",
    //   customer_id: customer_id
    // };
    // const response = await getCustomerReport(payload);

    // console.log("IS_Corp_User is ", Store.getState().userData.userProfileData);
    const show_corporate_sticker =
      Store.getState().userData.show_corporate_sticker;
    // console.log("show_corporate_sticker ", show_corporate_sticker);
    // console.log(current_subscription_plan);
    if (current_subscription_plan) {
      this.setState({
        current_subscription_plan,
      });
    }
    // console.log(
    //   "value ",
    //   (current_subscription_plan == "PMQ" ||
    //     current_subscription_plan == "PMH" ||
    //     current_subscription_plan == "PMA") &&
    //     !outside_mf_exists
    //     ? "true"
    //     : "false"
    // );
    if (
      (corporate_id == 14 || is_tagged_to_corporate) &&
      plans_Json[current_subscription_plan] &&
      show_corporate_sticker
    ) {
      if (
        current_subscription_plan == "PLH" ||
        current_subscription_plan == "PLA" ||
        current_subscription_plan == "PPH" ||
        current_subscription_plan == "PP"
      ) {
        this.setState({
          show_corporate_sticker: true,
        });
      }
      if (
        (current_subscription_plan == "PLQ" ||
          current_subscription_plan == "PLB" ||
          current_subscription_plan == "PLY") &&
        !life_insurance_goal_exists
      ) {
        this.setState({
          show_corporate_sticker: true,
        });
      } else if (
        (current_subscription_plan == "PHQ" ||
          current_subscription_plan == "PHH" ||
          current_subscription_plan == "PHA") &&
        !health_insurance_goal_exists
      ) {
        this.setState({
          show_corporate_sticker: true,
        });
      } else if (
        (current_subscription_plan == "PMQ" ||
          current_subscription_plan == "PMH" ||
          current_subscription_plan == "PMA") &&
        !outside_mf_exists
      ) {
        // console.log("I'm Inside");
        this.setState({
          show_corporate_sticker: true,
        });
      } else if (
        (current_subscription_plan == "PSQ" ||
          current_subscription_plan == "PSH" ||
          current_subscription_plan == "PSA") &&
        !outside_stocks_exists
      ) {
        this.setState({
          show_corporate_sticker: true,
        });
      } else if (
        (current_subscription_plan == "PRQ" ||
          current_subscription_plan == "PRH" ||
          current_subscription_plan == "PRA") &&
        !retirement_goal_exists
      ) {
        this.setState({
          show_corporate_sticker: true,
        });
      } else if (
        (current_subscription_plan == "PCQ" ||
          current_subscription_plan == "PCH" ||
          current_subscription_plan == "PCA") &&
        !child_related_goal_exits
      ) {
        this.setState({
          show_corporate_sticker: true,
        });
      } else if (
        current_subscription_plan == "BP" &&
        !finwellness_exists &&
        !jama_academy_progress_exists
      ) {
        this.setState({
          show_corporate_sticker: true,
        });
      }
    }
  }

  startCounter = () => {
    this.timerInterval = setInterval(() => {
      if (this.state.timer > 0) {
        this.setState((prevState) => ({
          timer: prevState.timer - 1,
        }));
      } else {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  };

  startTimer = () => {
    if (!this.state.timerExecuted) {
      this.setState({ timerExecuted: true });

      const timerInterval = setInterval(() => {
        if (!this.state.isLoading) {
          clearInterval(timerInterval);
        }
        if (this.state.secondsRemaining > 0) {
          this.setState((prevState) => ({
            secondsRemaining: prevState.secondsRemaining - 1,
          }));
        } else {
          clearInterval(timerInterval);
          if (Platform.OS == "web") {
            window.location.reload();
          }
          // console.log("Timer finished!");
        }
      }, 1000);
    }
  };

  async componentDidMount() {
    try {
      // this function we use only for new customers
      if (Platform.OS == "web") {
        this.startTimer();
      }
      // console.log("Component did mount ", this.state.redirect);
      if (this.state.showAggregratorSuccess) {
        this.handleCamsResponse();
      }
      // this.props.navigation.navigate("Onboarding", { screen: "KYCCreate", params: {  }});
      // return;
      let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
      if (hostname.includes("myadvisor")) {
        await generateAgreementPdf();
      }
      amplitude.logEvent("Dashboard_Loaded");
      let userDataValue = [];
      if (this.state.is_preferred_circle || this.state.is_club) {
        let goalResp = await getAdvisorDashboardDetials();
        if (goalResp && goalResp.code && goalResp.code == "100") {
          if (goalResp.goals.length) {
          } else {
            this.setState({ is_goal_avl: true });
          }
        }
      }
      userDataValue = await getUserDetails();
      if (this.state.is_preferred_circle || this.state.is_club) {
        let advResp = await getAdvisoryTracker();
        if (advResp && advResp.code && advResp.code == "100") {
          this.setState({
            availedCount: advResp.result.total_advises_availed.toString(),
            totalCount: advResp.result.total_advises.toString(),
            endDate: advResp.result.plan_end_date,
          });
        }
      }
      let userDetails = getUserDetails();
      let cust_id =
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
          ? Store.getState().dashboard.viewModeUserId.user_id
          : String(userDetails.customer_id);
      let payload = {
        previous_key: "customer_imported_portfolio_analysis",
        customer_id: cust_id,
      };
      let response = await getCustomerReport(payload);
      // console.log("Response is ", response);

      if (response && response.code == "100") {
        let goalArray = [];
        let stockArray = [];
        let mfArray = [];
        let goalsAmount = 0;
        let resultData = response.result;

        resultData.forEach((item) => {
          if (item.type == "Goals") {
            goalsAmount += item.cost_price;
            goalArray.push(item);
          }
          if (item.type == "Imported Stocks") {
            stockArray.push(item);
          }
          if (item.type == "Imported Funds") {
            mfArray.push(item);
          }
        });
        // console.log("Stock array ", goalsAmount);
        let stockTypes = {
          "High Risk": {
            data: [],
            sum: 0,
            perc: 0,
            color: "#df623a",
          },
          Watch: {
            data: [],
            sum: 0,
            perc: 0,
            color: "#ecc075",
          },
          Hold: {
            data: [],
            sum: 0,
            perc: 0,
            color: "#6FE3D4",
          },
        };
        stockArray.forEach((item) => {
          let fieldName = item.VIEW;
          // if (!stockTypes.hasOwnProperty(fieldName)) {
          // 	stockTypes[fieldName] = {
          // 		data: [item],
          // 		sum: item.imported_valuation != "" ? Number(item.imported_valuation) : 0,
          // 		perc: (1 / stockArray.length) * 100,
          // 		color: item.VIEW_COLOR
          // 	}
          // } else {
          // 	stockTypes[fieldName].data.push(item);
          // 	stockTypes[fieldName].sum += item.imported_valuation != "" ? Number(item.imported_valuation) : 0;
          // 	stockTypes[fieldName].perc = ((stockTypes[fieldName].data.length) / stockArray.length) * 100;
          // }
          if (item.VIEW.includes("High Risk")) {
            stockTypes[fieldName].data.push(item);
            stockTypes[fieldName].sum +=
              item.imported_valuation != ""
                ? Number(item.imported_valuation)
                : 0;
            stockTypes[fieldName].perc =
              (stockTypes[fieldName].data.length / stockArray.length) * 100;
            // stockTypes[fieldName].color = item.VIEW_COLOR
          }
          if (item.VIEW.includes("Watch")) {
            stockTypes[fieldName].data.push(item);
            stockTypes[fieldName].sum +=
              item.imported_valuation != ""
                ? Number(item.imported_valuation)
                : 0;
            stockTypes[fieldName].perc =
              (stockTypes[fieldName].data.length / stockArray.length) * 100;
            // stockTypes[fieldName].color = item.VIEW_COLOR
          }
          if (item.VIEW.includes("Hold")) {
            stockTypes[fieldName].data.push(item);
            stockTypes[fieldName].sum +=
              item.imported_valuation != ""
                ? Number(item.imported_valuation)
                : 0;
            stockTypes[fieldName].perc =
              (stockTypes[fieldName].data.length / stockArray.length) * 100;
            // stockTypes[fieldName].color = item.VIEW_COLOR
          }
        });

        // console.log("Value is ", stockTypes);
        let mfTypes = {
          "High Risk": {
            data: [],
            sum: 0,
            perc: 0,
            color: "#df623a",
          },
          Watch: {
            data: [],
            sum: 0,
            perc: 0,
            color: "#ecc075",
          },
          Hold: {
            data: [],
            sum: 0,
            perc: 0,
            color: "#6FE3D4",
          },
        };
        mfArray.forEach((item) => {
          let fieldName = item.VIEW;
          // if (!mfTypes.hasOwnProperty(fieldName)) {
          // 	mfTypes[fieldName] = {
          // 		data: [item],
          // 		sum: item.imported_valuation != "" ? Number(item.imported_valuation) : 0,
          // 		perc: (1 / mfArray.length) * 100,
          // 		color: item.VIEW_COLOR
          // 	}
          // } else {
          // 	mfTypes[fieldName].data.push(item);
          // 	mfTypes[fieldName].sum += item.imported_valuation != "" ? Number(item.imported_valuation) : 0;
          // 	mfTypes[fieldName].perc = ((mfTypes[fieldName].data.length) / mfArray.length) * 100;
          // }
          if (item.VIEW.includes("High Risk")) {
            mfTypes[fieldName].data.push(item);
            mfTypes[fieldName].sum +=
              item.imported_valuation != ""
                ? Number(item.imported_valuation)
                : 0;
            mfTypes[fieldName].perc =
              (mfTypes[fieldName].data.length / mfArray.length) * 100;
            // stockTypes[fieldName].color = item.VIEW_COLOR
          }
          if (item.VIEW.includes("Watch")) {
            mfTypes[fieldName].data.push(item);
            mfTypes[fieldName].sum +=
              item.imported_valuation != ""
                ? Number(item.imported_valuation)
                : 0;
            mfTypes[fieldName].perc =
              (mfTypes[fieldName].data.length / mfArray.length) * 100;
            // stockTypes[fieldName].color = item.VIEW_COLOR
          }
          if (item.VIEW.includes("Hold")) {
            mfTypes[fieldName].data.push(item);
            mfTypes[fieldName].sum +=
              item.imported_valuation != ""
                ? Number(item.imported_valuation)
                : 0;
            mfTypes[fieldName].perc =
              (mfTypes[fieldName].data.length / mfArray.length) * 100;
            // stockTypes[fieldName].color = item.VIEW_COLOR
          }
        });
        // console.log("Value is ", mfTypes);

        this.setState({
          goalArray,
          stockArray,
          mfArray,
          goalsAmount,
          stockTypes,
          mfTypes,
          error: false,
          errorMsg: "",
        });
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorMsg: "there is some error in line 805",
        });
      }
      console.log(
        "Store.getState().userData.user_import_funds = ",
        Store.getState().userData.user_import_funds
      );
      console.log(
        "Store.getState().userData.user_import_funds = ",
        this.state.stockArray.length
      );
      console.log(
        "Store.getState().userData.user_import_funds = ",
        this.state.mfArray.length
      );
      this.getCamsApiData();

      const { params } = this.props.route;
      let topup, product, data;
      let user_id = null;
      if (params) {
        topup = params.topup || null;
        data = params.data || null;
        product = params.product || null;
        user_id = params.user_id || null;
      }

      if (user_id) {
        Store.dispatch({
          type: "GET_DASHBOARD",
          payload: {
            viewModeUserId: {
              user_id: null,
            },
          },
        });
      }

      if (
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        user_id = Store.getState().dashboard.viewModeUserId.user_id;
      }
      this.checkDashboardCountsGoadlsFunds(user_id);
      if (topup && data && product) {
        await handleTopup(
          {
            amount: data,
            product,
          },
          this.props.navigation
        );
        return;
      }

      this.setState({ isLoading: true });
      const { userId } = this.state;
      let summaryResponse = [];
      let userData = [];
      let landingPageResponse = [];

      if (user_id) {
        Store.dispatch({
          type: "GET_DASHBOARD",
          payload: {
            viewModeUserId: {
              user_id,
            },
          },
        });
        const responses = await Promise.all([
          getMFData(user_id),
          getDashboard(userId),
        ]);

        summaryResponse = responses[0];
        landingPageResponse = responses[1];
      } else {
        if (
          Store.getState().dashboard &&
          Store.getState().dashboard.viewModeUserId &&
          Store.getState().dashboard.viewModeUserId.user_id
        ) {
          summaryResponse = await getMFData(
            Store.getState().dashboard.viewModeUserId.user_id
          );
          landingPageResponse = await getDashboard(
            Store.getState().dashboard.viewModeUserId.user_id
          );
        } else {
          if (
            Store.getState().dashboard &&
            Store.getState().dashboard.summaryResponse &&
            Object.keys(Store.getState().dashboard.summaryResponse).length != 0
          ) {
            const responses = await Promise.all([getMFData(), getDashboard()]);
            summaryResponse = responses[0];
            landingPageResponse = responses[1];
            getDashboard(userId).then((response) => {
              const { strips, subscribed_products, is_ftu } = response.result;
              this.setState({ strips, subscribed_products, is_ftu });
            });
          } else {
            const responses = await Promise.all([
              getMFData(),
              getDashboard(userId),
            ]);
            summaryResponse = responses[0];
            landingPageResponse = responses[1];
            Store.dispatch({
              type: "GET_DASHBOARD",
              payload: {
                summaryResponse: responses,
              },
            });
          }
        }
      }
      if (this.state.redirect) {
        // console.log(this.state.userId);
        this.props.navigation.navigate(this.state.redirect, {
          user_id: this.state.userId,
        });
      }
      let actionItemID, showPopUp, modalData;
      getTransactionPopUp().then((transactionPopUpResponse) => {
        if (
          transactionPopUpResponse &&
          transactionPopUpResponse.code &&
          transactionPopUpResponse.code == 100
        ) {
          if (transactionPopUpResponse.result.Show_pop_up) {
            let { cai_pk, modal_data } = transactionPopUpResponse.result;
            actionItemID = cai_pk;
            showPopUp = true;
            modalData = modal_data;
            if (modal_data.status != "ERROR") {
              this.props.navigation.navigate("Portfolio", {
                screen: "Notifications",
                params: {
                  showToast: "true",
                  active: 1,
                  title: "Transaction successful",
                },
              });
            }
          }
        }
      });

      userData = await getUserDetails();
      this.setState({ userData });

      let customer_id = userData.customer_id;
      if (user_id) {
        customer_id = user_id;
      }

      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        customer_id = Store.getState().dashboard.viewModeUserId.user_id;
      }

      let plans_about_expire_resp = null;
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        plans_about_expire_resp = await getCipsAboutToExpire(
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        plans_about_expire_resp = await getCipsAboutToExpire();
      }

      if (
        summaryResponse.result &&
        summaryResponse.code == 100 &&
        landingPageResponse.code == 100 &&
        landingPageResponse.result &&
        plans_about_expire_resp.code == 100
      ) {
        const { strips, subscribed_products, is_ftu } =
          landingPageResponse.result;

        let showPlanRenewalCard = "false";
        let plan_renewal_text = "";
        let plan_renewal_type = "";
        let cip_pk_for_renewal = null;
        let result = plans_about_expire_resp.result;
        let show_club_upgrade = true;
        let show_choose_your_plan = false;
        let show_renew_now_btn = true;
        let show_upgrade_btn = true;
        if (result.length == 0) {
          showPlanRenewalCard = "false";
          localStorage.setItem("showPlanRenewalCard", "false");
        } else {
          result.forEach((item) => {
            plan_renewal_type = item["renewal_type"];
            plan_renewal_text = item["plan_text_for_display"];
            cip_pk_for_renewal = item["cip_pk"];
            if (item["renewal_type"] == "no_plan_for_customer") {
              show_choose_your_plan = true;
              show_renew_now_btn = false;
              show_upgrade_btn = false;
            } else {
              show_choose_your_plan = false;
              show_renew_now_btn = true;
              show_upgrade_btn = true;
              showPlanRenewalCard = "true";
              localStorage.setItem("showPlanRenewalCard", "true");
            }
          });
        }

        if (userData.is_mfcustomer || userData.is_equitycustomer) {
          show_club_upgrade = false;
        }

        summaryResponse.result.children.forEach((data) => {
          if (customer_id == data.pk) {
            const {
              children,
              value,
              invested_value,
              last_Updated_date,
              oneday_return_value,
              overall_return_value,
              yearly_value,
            } = data.summary_values;
            const total_percentage =
              children[0]["stocks_perc"] +
              children[1]["mf_perc"] +
              children[2]["assets_perc"];
            this.setState(
              {
                is_ftu,
                AllAssetsData: data.asset_values,
                investTotalPercentage: total_percentage,
                investTotalUser: children,
                investValue: value,
                total_invested_value: invested_value,
                last_Updated_date: last_Updated_date,
                oneday_return_value: oneday_return_value,
                overall_return_value: overall_return_value,
                yearly_gain: yearly_value,

                strips,
                subscribed_products,
                is_corporate_admin: userData.is_corporate_admin,
                is_EquityUser: userData.is_equitycustomer,
                is_MfUser: userData.is_mfcustomer,
                actionItemID,
                showPopUp,
                modalData,
                isLoading: false,
                is_ria: userData.is_ria,
                showPlanRenewalCard: showPlanRenewalCard,
                plan_renewal_text: plan_renewal_text,
                plan_renewal_type: plan_renewal_type,
                cip_pk_for_renewal: cip_pk_for_renewal,
                show_club_upgrade: show_club_upgrade,
                show_choose_your_plan: show_choose_your_plan,
                show_renew_now_btn: show_renew_now_btn,
                show_upgrade_btn: show_upgrade_btn,
              },
              async () => {
                await this.checkComplianceData();
              }
            );
          }
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: false, error: err });
    }
  }

  getCamsApiData = async () => {
    let userDetails = getUserDetails();
    let cust_id =
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
        ? Store.getState().dashboard.viewModeUserId.user_id
        : String(userDetails.customer_id);
    if (
      Store.getState().userData.user_import_funds &&
      this.state.stockArray.length <= 0 &&
      this.state.mfArray.length <= 0
    ) {
      // if (true) {
      // console.log("inside if check");
      let payload = {
        previous_key: "action_item_generated_for_cams_aa",
        customer_id: cust_id,
      };
      const res = await getCustomerReport(payload);
      if (res && res.code == "100") {
        const { result } = res;
        let resultData = result[0];
        try {
          let orderResponse = resultData.order_response;
          this.setState({ cams_status: resultData.status });
          let dataList = orderResponse?.split("|");

          // Define arrays to hold filtered data
          const equitiesArray = [];
          const fundArray = [];

          let totalEuities = 0;
          let totalFunds = 0;
          // Iterate over the dataList array
          dataList?.forEach((item) => {
            let data = JSON.parse(item);
            let account = data?.dataDetail?.jsonData?.Account;
            if (account && account.type === "equities") {
              let holdings = account?.Summary?.Investment?.Holdings?.Holding;
              if (holdings && holdings.length > 0) {
                holdings.forEach((val) => {
                  if (val.units != 0) {
                    let collection = {
                      issuerName: val.issuerName,
                      lastTradedPrice: val.lastTradedPrice,
                      units: val.units,
                    };
                    totalEuities += val.lastTradedPrice * val.units;
                    equitiesArray.push(collection);
                  }
                });
              }
            } else {
              let holdings = account?.Summary?.Investment?.Holdings?.Holding;
              if (holdings && holdings.length > 0) {
                holdings.forEach((val) => {
                  if (val.closingUnits != 0) {
                    let collection = {
                      schemeCode: val.schemeCode,
                      closingUnits: val.closingUnits,
                      nav: val.nav,
                    };
                    totalFunds += val.closingUnits * val.nav;
                    fundArray.push(collection);
                  }
                });
              }
            }
          });

          // Output the filtered arrays
          // console.log("Equities Array:", equitiesArray);
          // console.log("Fund Array:", fundArray);

          this.setState({ equitiesArray, fundArray, totalEuities, totalFunds });
        } catch (e) {
          console.log(e);
        }
      }
      // console.log("action_item_generated_for_cams_aa resp = ", res);
    }
  };

  handleDashboardChange = () => {
    this.props.navigation.navigate("OldHome", {
      showToast: "true",
    });
  };

  checkComplianceData = async () => {
    try {
      const customer_id = this.state.userId || null;
      const { params } = this.props.route;
      let skip_compliance = false;
      if (params) {
        skip_compliance = params.s || false;
      }
      completeMinDetails(true, customer_id, false).then(
        (minDetailsResponse) => {
          this.setState({ minDetailsResponse, gettingMinDetails: false });
        }
      );
    } catch (error) {}
  };

  handleTopupClick = (data = {}) => {
    if (!this.state.gettingMinDetails) {
      this.setState({
        showComplianceAlert: true,
        complianceNavigationOptions: { ...data },
      });
    }
  };

  closePopUp = async () => {
    this.setState({ showPopUp: false });
  };

  onRetry = (cai_hash) => {
    try {
      if (cai_hash) {
        let token = getTokenFromStore();
        token = reverseString(token);
        if (Platform.OS == "web") {
          global.open(
            MAIN_URL +
              "/app/gwsc/retryorder/" +
              (cai_hash + token) +
              "/" +
              GATEWAY_ENVIRONMENT,
            "_self"
          );
        } else {
          Linking.openURL(
            MAIN_URL +
              "/app/gwsc/retryorder/" +
              (cai_hash + token) +
              "/" +
              GATEWAY_ENVIRONMENT
          );
        }
      } else {
        this.setState({
          showToastMessage: true,
          toastMessage:
            "Could not generate url to place order. Kindly contact your advisor",
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        showToastMessage: true,
        toastMessage:
          "Could not generate url to place order. Kindly contact your advisor",
      });
    }
  };

  handlePlanRenewal = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let { plan_renewal_type, cip_pk_for_renewal } = this.state;
      const payload = {
        cip_pk: cip_pk_for_renewal,
        type_of_renewal: plan_renewal_type,
      };
      const response = await planRenewalForClients(payload);
      // console.log(response);
      if (response && response.code == 100) {
        this.setState({
          isLoading: false,
          showToastMessage: true,
          toastMessage: "Successfully Renewed Your Plan",
          showPlanRenewalCard: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        this.setState({
          isLoading: false,
          showToastMessage: true,
          toastMessage:
            "Failed to renew your plan, Kindly contact your advisor.",
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        showToastMessage: true,
        toastMessage: "Failed to renew your plan, Kindly contact your advisor.",
      });
    }
  };

  returnPercentageForDonut = (stockTypes) => {
    let percen = [];
    for (let key in stockTypes) {
      if (stockTypes.hasOwnProperty(key)) {
        percen.push(Number(Number(stockTypes[key].perc).toFixed(0)));
      }
    }
    // console.log("local check is ", percen);
    return percen;
  };

  returnColorForDonut = (stockTypes) => {
    let color = [];
    for (let key in stockTypes) {
      if (stockTypes.hasOwnProperty(key)) {
        color.push(stockTypes[key].color);
      }
    }
    return color;
  };

  handle_remove_show_corporate_sticker = async () => {
    Store.dispatch({
      type: "DASHBOARD_PORTFOLIO_ANALYSIS_CLOSED",
      payload: {
        show_dashboard_portfolio: false,
      },
    });
    const { customer_id, customerprofile_id } = getUserDetails();
    const body = {
      customer: customer_id,
      dashboard_portfolio_analysis_closed: true,
    };
    await saveCustomerProfileData(customerprofile_id, body);
  };

  handleCamsResponse = async () => {
    this.setState({ showAggregatorModal: true, timer: 60 });
    this.startCounter();
    let fail_count = 0;
    const interval = setInterval(async () => {
      // console.log("Inside Timeout function");
      let response = await getAccountAggregatorConsentStatus();
      // console.log("response is ", response);
      if (response && response.code == "100") {
        if (
          response.result == "Status Approved" ||
          response.result == "Status Rejected"
        ) {
          this.setState({ aggregatorResponse: response.result });
          return;
        } else {
          fail_count++;
        }
      } else {
        fail_count++;
      }
      if (fail_count >= 12 || this.state.timer == 0) {
        this.setState({ aggregatorResponse: "Something went wrong" });
        clearInterval(interval);
        return;
      }
    }, 5000);
    return () => clearInterval(interval);
  };

  handleAggregator = async () => {
    Store.dispatch({
      type: USER_IMPORT_FUNDS,
      payload: {
        user_import_funds: true,
      },
    });
    let userData = await getUserDetails();
    let payload = {
      mobilenumber: userData.mobilenumber,
      pannumber: userData.pan_number,
      useCaseid: "331",
      fipid: "CAMC,Cams Depository",
      redirect_url:
        Platform.OS == "web"
          ? `https://${global.location.hostname}/dashboard/home`
          : "https://app.jamawealth.com/home?ecres=true",
      cams_key: "_cams_key",
    };
    // console.log("current urls is ", payload.redirect_url);
    this.setState({ isLoading: true, aggregatorResponse: "Pending" });
    let response = await generateCamsAggregatorLink(payload);
    this.setState({ isLoading: false });
    if (Platform.OS === "web") {
      global.open(response.result.redirectionurl, "_self");
    } else {
      Linking.openURL(response.result.redirectionurl);
    }
  };

  renderItemStockAndEquities = ({ item }) => (
    <View style={styles.rowt}>
      <Text style={styles.cellt}>{item.issuerName}</Text>
      <Text style={styles.cellt}>{item.lastTradedPrice}</Text>
      <Text style={styles.cellt}>{item.units}</Text>
    </View>
  );

  renderItemStockAndFund = ({ item }) => (
    <View style={styles.rowt}>
      <Text style={styles.cellt}>{item.schemeCode}</Text>
      <Text style={styles.cellt}>{item.closingUnits}</Text>
      <Text style={styles.cellt}>{item.nav}</Text>
    </View>
  );

  render() {
    const {
      isViewVisible,
      isLoading,
      error,
      is_ftu,
      show_card_view,
      is_EquityUser,
      is_MfUser,
      dashboard_portfolio_analysis_closed,
      strips = [],
      subscribed_products = [],
      minDetailsResponse = {},
      gettingMinDetails,
      showPopUp,
      modalData,
      showComplianceAlert,
      complianceNavigationOptions = {},
      is_ria,
    } = this.state;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        {/* Transaction status pop-up */}
        {showPopUp && modalData && (
          <PopUp
            modal_data={modalData}
            onPressDone={this.closePopUp}
            onRetry={this.onRetry}
          />
        )}
        {showComplianceAlert && (
          <ComplianceModal
            forceLoad={true}
            navigation={this.props.navigation}
            route={this.props.route}
            isLoading={gettingMinDetails}
            from={"Dashboard"}
            minDetailsResponse={minDetailsResponse}
            redirectBackTo={"InvestmentOverview"}
            onClose={() => {
              this.setState({ showComplianceAlert: false });
            }}
            navigationOptions={complianceNavigationOptions}
            userId={this.state.userId}
          />
        )}
        {this.state.show_choose_your_plan && (
          <NoPlanModal
            navigation={this.props.navigation}
            route={this.props.route}
          />
        )}
        {isLoading || error ? (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <ProgressBar msg={"Loading Dashboard..."} />
            {error && <Text>Error Loading</Text>}
          </View>
        ) : (
          <>
            {this.state.showToast == "true" ? (
              <ShowToast
                showToast={true}
                type="status"
                title="Congratulations! Your Onboarding Is Now Complete."
                duration="10"
              />
            ) : null}
            {this.state.showFailedToast == "true" ? (
              <ShowToast
                showToast={true}
                type="status"
                title="Payment failed, please try again"
                duration="10"
              />
            ) : null}
            {this.state.showToastMessage ? (
              <ShowToast
                showToast={true}
                type="status"
                title={this.state.toastMessage}
                duration="10"
              />
            ) : null}
            {this.state.showAggregratorSuccess && (
              <Modal
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    padding: 50,
                  }}
                >
                  {this.state.aggregatorResponse == "Status Approved" ? (
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesome5 name="check" size={32} color="green" />
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontWeight: "700",
                          marginTop: 15,
                        }}
                      >
                        Yay! Your consent status is approved
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          fontWeight: "400",
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        The account aggregator will send your portfolio data
                        within ten minutes.
                      </Text>
                      {/* <Text style={{
                      color: '#1a1a1a',
                      fontSize: 14,
                      fontWeight: '400',
                      marginTop: 5
                    }} >We will email you once received.</Text> */}
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 30,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            backgroundColor: "#410DAA",
                            paddingHorizontal: 20,
                            paddingVertical: 10,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                          }}
                          onPress={async () => {
                            this.setState({
                              showAggregatorModal: false,
                              showAggregatorModal: false,
                            });

                            this.props.navigation.navigate("App", {
                              screen: "Dashboard",
                              params: {
                                show_card_view: true,
                              },
                            });
                            this.setState({
                              showAggregatorModal: false,
                              showAggregratorSuccess: false,
                            });
                          }}
                        >
                          <Text style={{ color: "#fff", textAlign: "center" }}>
                            Continue
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  ) : this.state.aggregatorResponse == "Status Rejected" ? (
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 20,
                      }}
                    >
                      <MaterialIcons name="cancel" size={32} color="red" />
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontWeight: "700",
                          marginTop: 15,
                        }}
                      >
                        Oh oh! Looks like you did not consent
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          fontWeight: "400",
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        Please note: your portfolio analysis is only to help
                        simply grow it (to import again click on Fetch
                        Portfolio)
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          textAlign: "center",
                          fontWeight: "400",
                          marginTop: 5,
                        }}
                      >
                        You may try other import options to pick and choose what
                        you wish to import
                      </Text>
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 30,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            backgroundColor: "#410DAA",
                            padding: 10,
                            width: 150,
                            marginRight: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                          }}
                          onPress={() => {
                            this.setState({
                              showAggregatorModal: false,
                              showAggregratorSuccess: false,
                            });
                          }}
                        >
                          <Text style={{ color: "#fff", textAlign: "center" }}>
                            Fetch Portfolio
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={{
                            backgroundColor: "#fff",
                            padding: 10,
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            width: 250,
                            marginRight: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                          }}
                          onPress={() => {
                            this.setState({
                              showAggregatorModal: false,
                              step: 2,
                              showAggregratorSuccess: false,
                            });
                          }}
                        >
                          <Text
                            style={{ color: "#410DAA", textAlign: "center" }}
                          >
                            Try Other Import Options
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  ) : this.state.aggregatorResponse == "Pending" ? (
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ActivityIndicator size={"large"} color={"#410DAA"} />
                      {this.state.timer > 40 ? (
                        <View>
                          <Text
                            style={{
                              color: "#1a1a1a",
                              fontWeight: "700",
                              marginTop: 15,
                            }}
                          >
                            Fetching consent status from Account Aggregator
                          </Text>
                          <Text
                            style={{
                              color: "#1a1a1a",
                              fontSize: 14,
                              fontWeight: "400",
                              marginTop: 8,
                            }}
                          >
                            This may take several minutes.
                          </Text>
                          <Text
                            style={{
                              color: "#1a1a1a",
                              fontSize: 14,
                              fontWeight: "400",
                              marginTop: 8,
                            }}
                          >
                            If it takes longer, we will notify you once the
                            analysis is ready
                          </Text>
                        </View>
                      ) : (
                        <View>
                          <Text
                            style={{
                              color: "#1a1a1a",
                              fontWeight: "700",
                              marginTop: 15,
                            }}
                          >
                            Oops, this is taking longer than expected.{" "}
                          </Text>
                          <Text
                            style={{
                              color: "#1a1a1a",
                              fontSize: 14,
                              fontWeight: "400",
                              marginTop: 8,
                            }}
                          >
                            Our team will get back to you once the data from the
                            AA is received.{" "}
                          </Text>
                          <Text
                            style={{
                              color: "#1a1a1a",
                              fontSize: 14,
                              fontWeight: "400",
                              marginTop: 8,
                            }}
                          >
                            You may log in later to see the status on the
                            dashboard.
                          </Text>
                          <View
                            style={{
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: 30,
                            }}
                          >
                            <TouchableOpacity
                              style={{
                                backgroundColor: "#410DAA",
                                paddingHorizontal: 20,
                                paddingVertical: 10,
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 5,
                              }}
                              onPress={async () => {
                                this.props.navigation.navigate("App", {
                                  screen: "Dashboard",
                                  params: {
                                    show_card_view: true,
                                  },
                                });
                                this.setState({
                                  showAggregatorModal: false,
                                  showAggregratorSuccess: false,
                                });
                              }}
                            >
                              <Text
                                style={{ color: "#fff", textAlign: "center" }}
                              >
                                Continue
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      )}
                    </View>
                  ) : (
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MaterialIcons
                        name="error-outline"
                        size={32}
                        color="red"
                      />
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontWeight: "700",
                          marginTop: 15,
                        }}
                      >
                        {" "}
                        Oops! Your consent status is still not received
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          fontWeight: "400",
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        We will check with the account aggregator and keep you
                        updated.
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          fontWeight: "400",
                          textAlign: "center",
                          marginTop: 5,
                        }}
                      >
                        you can try other options to import your portfolio
                      </Text>
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 30,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            backgroundColor: "#410DAA",
                            padding: 10,
                            justifyContent: "center",
                            width: 150,
                            marginLeft: 20,
                            alignItems: "center",
                            borderRadius: 5,
                          }}
                          onPress={() => {
                            this.setState({
                              showAggregatorModal: false,
                              step: 2,
                              showAggregratorSuccess: false,
                            });
                          }}
                        >
                          <Text style={{ color: "#fff", textAlign: "center" }}>
                            Continue
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                </View>
              </Modal>
            )}
            <ScrollView
              style={{ padding: 20, paddingLeft: "4%" }}
              showsVerticalScrollIndicator={true}
            >
              {this.state.show_corporate_sticker && (
                <View
                  style={{
                    width: "97%",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderWidth: 1,
                    borderColor: "rgba(65, 13, 170, 0.3)",
                    borderRadius: 10,
                    padding: 10,
                    marginBottom: 25,
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <View
                      style={{
                        width: "80%",
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 25,
                        marginBottom: 25,
                      }}
                    >
                      <Text
                        style={{
                          marginRight: 15,
                          fontSize: 18,
                          color: "#484848",
                          fontFamily: isMobile ? "System" : "Roboto",
                          fontWeight: "bold",
                          marginLeft: 5,
                        }}
                      >
                        {
                          plans_Json[this.state.current_subscription_plan]
                            .header
                        }
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "20%",
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 25,
                        marginBottom: 25,
                      }}
                    >
                      {this.state.show_corp_strip ? (
                        <Octicons
                          name="triangle-up"
                          size={24}
                          color="black"
                          onPress={() =>
                            this.setState({ show_corp_strip: false })
                          }
                        />
                      ) : (
                        <Octicons
                          name="triangle-down"
                          size={24}
                          color="black"
                          onPress={() =>
                            this.setState({ show_corp_strip: true })
                          }
                        />
                      )}
                      <Entypo
                        name="cross"
                        size={24}
                        style={{ marginLeft: 25 }}
                        color="black"
                        onPress={() => {
                          this.setState({ show_corporate_sticker: false });
                          Store.dispatch({
                            type: SHOW_CORPORATE_STICKER,
                            payload: {
                              show_corporate_sticker: false,
                            },
                          });
                        }}
                      />
                    </View>
                  </View>
                  {this.state.show_corp_strip && (
                    <View>
                      <View
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <View
                          style={{
                            width: "48%",
                            padding: 15,
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginBottom: 25,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 20,
                              color: "#484848",
                              fontWeight: "700",
                            }}
                          >
                            Features
                          </Text>
                          {plans_Json[
                            this.state.current_subscription_plan
                          ].What_is_involved.map((data) => {
                            return (
                              <Text style={{ color: "#484848", marginTop: 10 }}>
                                {data}
                              </Text>
                            );
                          })}
                        </View>
                        <View
                          style={{
                            width: "48%",
                            padding: 15,
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginBottom: 25,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 20,
                              color: "#484848",
                              fontWeight: "700",
                            }}
                          >
                            Benefits
                          </Text>
                          {plans_Json[
                            this.state.current_subscription_plan
                          ].Benefits.map((data) => {
                            return (
                              <Text style={{ color: "#484848", marginTop: 10 }}>
                                {data}
                              </Text>
                            );
                          })}
                        </View>
                      </View>
                      <View
                        style={{
                          width: "95%",
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 25,
                          marginBottom: 25,
                        }}
                      >
                        <Text
                          style={{
                            marginRight: 15,
                            color: "#484848",
                            fontWeight: "bold",
                            marginLeft: 5,
                          }}
                        >
                          {plans_Json[this.state.current_subscription_plan].msg}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            width: 200,
                            backgroundColor: "#410DAA",
                            borderRadius: 10,
                            padding: 10,
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: 25,
                          }}
                          onPress={() => {
                            this.setState({ show_corporate_sticker: false });
                            Store.dispatch({
                              type: SHOW_CORPORATE_STICKER,
                              payload: {
                                show_corporate_sticker: false,
                              },
                            });
                            if (
                              plans_Json[this.state.current_subscription_plan]
                                .redirection_url == "DashBaord"
                            ) {
                              this.setState({ show_corp_plan_detail });
                            } else if (
                              plans_Json[this.state.current_subscription_plan]
                                .redirection_url == "ImportStock"
                            ) {
                              this.props.navigation.navigate("App", {
                                screen: "ViewMode",
                                params: {
                                  screen: "ImportStocks",
                                  params: {
                                    from: this.props.from,
                                  },
                                },
                              });
                            } else if (
                              plans_Json[this.state.current_subscription_plan]
                                .redirection_url == "CHILDREN"
                            ) {
                              this.props.navigation.navigate("App", {
                                screen: "Goals",
                                params: {
                                  screen: "BasicDetails",
                                  params: {
                                    type: "COMPLETE",
                                  },
                                },
                              });
                            } else if (
                              plans_Json[this.state.current_subscription_plan]
                                .redirection_url == "RETIREMENT"
                            ) {
                              this.props.navigation.navigate("App", {
                                screen: "Goals",
                                params: {
                                  screen: "BasicDetails",
                                  params: {
                                    type: "COMPLETE",
                                  },
                                },
                              });
                            }
                          }}
                        >
                          <Text style={{ color: "white", textAlign: "center" }}>
                            Continue
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                </View>
              )}
              {/* {(!dashboard_portfolio_analysis_closed && Store.getState().userData.show_dashboard_portfolio) && (this.state.isViewVisible) && (<View style={{ */}
              {true && (
                <View
                  style={{
                    width: isMobile ? "100%" : "95%",
                    shadowColor: "#000",
                    borderRadius: 10,
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.3,
                    shadowRadius: 4,
                    elevation: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <View
                    style={{
                      width: "90%",
                      padding: 20,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <View style={{ width: isMobile ? "70%" : "50%" }}>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: isMobile ? 16 : 20,
                          fontWeight: "700",
                        }}
                      >
                        Imported Portfolio Analysis
                      </Text>
                    </View>
                    <View
                      style={{
                        width: isMobile ? "30%" : "50%",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      {show_card_view ? (
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <Octicons
                            name="triangle-up"
                            size={24}
                            color="black"
                            onPress={() =>
                              this.setState({ show_card_view: false })
                            }
                          />
                          {/* <Feather name="x" size={isMobile ? 20 : 24} style={{ marginLeft: 30 }} color="black" onPress={this.handle_remove_show_corporate_sticker} /> */}
                        </View>
                      ) : (
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <Octicons
                            name="triangle-down"
                            size={24}
                            color="black"
                            onPress={() =>
                              this.setState({ show_card_view: true })
                            }
                          />
                          {/* <Feather
                          name="x"
                          size={isMobile ? 20 : 24}
                          style={{ marginLeft: 30 }}
                          color="black"
                          onPress={() => this.setState({ isViewVisible: false })}
                        />                      */}
                        </View>
                      )}
                    </View>
                  </View>
                  {show_card_view && (
                    <View style={{ width: "90%", paddingTop: 20 }}>
                      {Store.getState().userData.user_import_funds &&
                      this.state.cams_status == "COM" &&
                      this.state.stockArray.length <= 0 &&
                      this.state.mfArray.length <= 0 ? (
                        <View>
                          <View
                            style={{
                              marginBottom: 20,
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text>
                              The imported portfolio so far{" "}
                              {this.state.totalEuities == 0
                                ? `has no stocks`
                                : `is ${formatNumberToAbbreviation(
                                    this.state.totalEuities.toString(),
                                    true
                                  )} in stocks`}{" "}
                              and{" "}
                              {this.state.totalFunds == 0
                                ? `has no mutual funds`
                                : `is ${formatNumberToAbbreviation(
                                    this.state.totalFunds.toString(),
                                    true
                                  )} in mutual funds`}{" "}
                              <Text
                                style={{
                                  color: "#410DAA",
                                  fontWeight: "500",
                                  textDecorationLine: "underline",
                                }}
                                onPress={async () => {
                                  this.setState({ isLoading: true });
                                  await this.getCamsApiData();
                                  this.setState({ isLoading: false });
                                }}
                              >
                                Refresh Imported Portfolio
                              </Text>
                            </Text>
                            <Text
                              style={{
                                color: "#410DAA",
                                fontWeight: "500",
                                textDecorationLine: "underline",
                              }}
                              onPress={async () => {
                                this.setState({ isLoading: true });
                                await getAccountAggregatorConsentStatus();
                                this.setState({ isLoading: false });
                              }}
                            >
                              Update this portfolio to system
                            </Text>
                          </View>
                          <View
                            style={{
                              marginBottom: 20,
                              width: "100%",
                              display: "flex",
                              flexDirection: isMobile ? "column" : "row",
                            }}
                          >
                            <View style={styles.containert}>
                              <View style={styles.headert}>
                                <Text style={styles.headerTextt}>
                                  Stock Name
                                </Text>
                                <Text style={styles.headerTextt}>
                                  Last Traded Price
                                </Text>
                                <Text style={styles.headerTextt}>Units</Text>
                              </View>
                              <View style={{ height: 300 }}>
                                <FlatList
                                  data={this.state.equitiesArray}
                                  renderItem={this.renderItemStockAndEquities}
                                  keyExtractor={(item, index) =>
                                    index.toString()
                                  }
                                  contentContainerStyle={styles.listContainer}
                                />
                              </View>
                            </View>
                            {!isMobile && (
                              <View
                                style={{
                                  borderLeftWidth: 0.5,
                                  borderColor: "#1a1a1a",
                                  height: "80%",
                                  marginVertical: 20,
                                  opacity: 0.4,
                                  marginHorizontal: 10,
                                }}
                              ></View>
                            )}
                            <View style={styles.containert}>
                              <View style={styles.headert}>
                                <Text style={styles.headerTextt}>
                                  Scheme Name
                                </Text>
                                <Text style={styles.headerTextt}>
                                  Closing Units
                                </Text>
                                <Text style={styles.headerTextt}>Nav</Text>
                              </View>
                              <View style={{ height: 300 }}>
                                <FlatList
                                  data={this.state.fundArray}
                                  renderItem={this.renderItemStockAndFund}
                                  keyExtractor={(item, index) =>
                                    index.toString()
                                  }
                                  contentContainerStyle={styles.listContainer}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      ) : (
                        <View
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                          }}
                        >
                          <PortfolioCardView
                            navigation={this.props.navigation}
                            itemName={"Stocks"}
                            itemCount={this.state.stockArray.length}
                            isCardView={false}
                            itemType={this.state.stockTypes}
                            returnColorForDonut={this.returnColorForDonut(
                              this.state.stockTypes
                            )}
                            returnPercentageForDonut={this.returnPercentageForDonut(
                              this.state.stockTypes
                            )}
                          />
                          {!isMobile && (
                            <View
                              style={{
                                borderLeftWidth: 0.5,
                                borderColor: "#1a1a1a",
                                height: "70%",
                                marginVertical: 20,
                                opacity: 0.4,
                              }}
                            ></View>
                          )}
                          <PortfolioCardView
                            navigation={this.props.navigation}
                            itemName={"Funds"}
                            itemCount={this.state.mfArray.length}
                            isCardView={false}
                            itemType={this.state.mfTypes}
                            returnColorForDonut={this.returnColorForDonut(
                              this.state.mfTypes
                            )}
                            returnPercentageForDonut={this.returnPercentageForDonut(
                              this.state.mfTypes
                            )}
                          />
                        </View>
                      )}

                      {false ? (
                        <View
                          style={{
                            marginBottom: 40,
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text>
                            {" "}
                            I am interested in customised solutions / PMS.{" "}
                          </Text>
                          <TouchableOpacity
                            onPress={() => {
                              this.props.navigation.navigate("ScheduleCall", {
                                selected: "DE",
                              });
                            }}
                          >
                            <Text
                              style={{
                                color: "#410DAA",
                                textDecorationLine: "underline",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              Schedule a call for me
                            </Text>
                          </TouchableOpacity>
                        </View>
                      ) : (
                        <View
                          style={{
                            marginBottom: 40,
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text>
                            For advice on how to optimise my portfolio,{" "}
                          </Text>
                          <TouchableOpacity
                            onPress={() => {
                              this.props.navigation.navigate("ScheduleCall", {
                                selected: "DE",
                              });
                            }}
                          >
                            <Text
                              style={{
                                color: "#410DAA",
                                textDecorationLine: "underline",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              please request a callback
                            </Text>
                          </TouchableOpacity>
                        </View>
                      )}
                    </View>
                  )}
                  {show_card_view && (
                    <View
                      style={{
                        borderBottomWidth: 0.5,
                        borderColor: "#1a1a1a",
                        width: isMobile ? "70%" : "50%",
                        opacity: 0.4,
                      }}
                    ></View>
                  )}
                  {show_card_view && (
                    <View style={{ width: "90%", paddingTop: 20 }}>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 20,
                          fontWeight: "700",
                        }}
                      >
                        Financial Plan Analysis
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <TouchableOpacity
                          style={styles.card}
                          onPress={() => {
                            this.props.navigation.navigate("Goals");
                          }}
                        >
                          <Text style={styles.subHeading}>Goals Created</Text>
                          <Text
                            style={[
                              styles.subHeading,
                              { textAlign: "center", marginTop: 10 },
                            ]}
                          >
                            {this.state.goalArray.length}
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={styles.card}
                          onPress={() => {
                            this.props.navigation.navigate("Goals");
                          }}
                        >
                          <Text style={styles.subHeading}>Goals Corpus*</Text>
                          <Text
                            style={[
                              styles.subHeading,
                              {
                                textAlign: "center",
                                color: "#410DAA",
                                marginTop: 10,
                              },
                            ]}
                          >
                            ₹{" "}
                            {formatNumberToAbbreviation(
                              this.state.goalsAmount.toString()
                            )}
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          marginLeft: 10,
                          marginBottom: 20,
                        }}
                      >
                        *Corpus = amount required when all goals are due
                      </Text>
                    </View>
                  )}
                </View>
              )}

              {
                <View style={{ marginBottom: 10 }}>
                  {isMobile ? (
                    is_ria ? null : (
                      <>
                        <TouchableOpacity
                          style={{
                            background:
                              "linear-gradient(70.62deg, #5197ED 0%,#0056f6 40%, #410DAA 100%)",
                            borderRadius: 6,
                            borderColor: "#1e56a4",
                            marginBottom: 20,
                          }}
                          onPress={async () => {
                            let os = await DeviceInfo.getBaseOs();
                            if (os == "Android") {
                              global.open(
                                "https://play.google.com/store/apps/details?id=in.jama.app",
                                "_self"
                              );
                            } else {
                              global.open(
                                "https://apps.apple.com/in/app/jama-wealth-advisory/id6443719443",
                                "_self"
                              );
                            }
                          }}
                        >
                          <Text
                            style={{
                              padding: 10,
                              fontSize: 16,
                              color: "white",
                              paddingLeft: 20,
                            }}
                          >
                            Download the Jama Wealth App for convenient, expert
                            investment advice to grow wealth.
                          </Text>
                        </TouchableOpacity>
                        <SideHeading title="For Your Attention" />
                      </>
                    )
                  ) : is_ria ? null : (
                    <>
                      {this.state.showPlanRenewalCard == "true" ? (
                        <View
                          style={{
                            backgroundColor: "white",
                            borderWidth: 1,
                            borderRadius: 12,
                            borderColor: "rgba(65, 13, 170, 0.3)",
                            marginBottom: 15,
                            marginTop: 10,
                            width: "96.8%",
                            height: 120,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "column",
                              }}
                            >
                              <Text
                                style={{
                                  paddingLeft: 20,
                                  paddingTop: 20,
                                  // paddingBottom: 10,
                                  fontSize: 16,
                                }}
                              >
                                {this.state.plan_renewal_text}
                              </Text>
                            </View>
                            <View
                              style={{
                                paddingRight: 20,
                                paddingTop: 10,
                                flexDirection: "row",
                                justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  this.setState({
                                    isShowAppDownloadCard: false,
                                    showPlanRenewalCard:false, // Changed to boolean
                                  });
                                  localStorage.setItem(
                                    "isShowAppDownloadCard",
                                    "false"
                                  );
                                }}
                              >
                                <AntDesign
                                  name="close"
                                  size={24}
                                  color="rgba(0, 0, 0, 0.5)"
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                          <View
                            style={{
                              flex: 1,
                              justifyContent: "center",
                              alignItems: "flex-end",
                              paddingRight: 80,
                            }}
                          >
                            <TouchableOpacity
                              style={{
                                backgroundColor: "#410DAA",
                                borderWidth: 1,
                                borderRadius: 4,
                                borderColor: "#410DAA",
                                width: 150,
                                padding: 10,
                                paddingLeft: 20,
                                paddingRight: 20,
                              }}
                              onPress={() => {
                                // console.log("Hell yeah")
                                this.props.navigation.navigate("Onboarding", {
                                  screen: "eSign",
                                  params: {
                                      cip_pk_for_renewal:
                                        this.state.cip_pk_for_renewal,
                                    },
                                });
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  color: "#ffffff",
                                }}
                              >
                                Renew Now
                              </Text>
                            </TouchableOpacity>
                          </View>
                          {this.state.userData.show_corporate_benifit_line ? (
                            <View
                              style={{
                                paddingRight: 7,
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "right",
                                  fontSize: 12,
                                  fontStyle: "italic",
                                  color: "#494B69",
                                }}
                              >
                                Please visit your corporate portal to avail any
                                corporate benefits.
                              </Text>
                              <Text
                                style={{
                                  textAlign: "right",
                                  fontSize: 12,
                                  fontStyle: "italic",
                                  color: "#494B69",
                                }}
                              >
                                Fees paid towards first six months of any plan
                                shall not be refunded as per our policy.
                              </Text>
                            </View>
                          ) : null}
                        </View>
                      ) : null}
                      {this.state.isShowAppDownloadCard == "true" &&
                        !this.state.userData.device_token && (
                          <View
                            style={{
                              backgroundColor: "white",
                              borderWidth: 1,
                              borderRadius: 12,
                              borderColor: "rgba(65, 13, 170, 0.3)",
                              marginBottom: 15,
                              marginTop: 10,
                              width: "96.8%",
                              height: 120,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "column",
                                }}
                              >
                                <Text
                                  style={{
                                    paddingLeft: 20,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    fontSize: 16,
                                  }}
                                >
                                  Download the Maxiom Wealth App for convenient,
                                  expert investment advice to grow wealth.
                                </Text>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    paddingLeft: 20,
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/android.png",
                                    }}
                                    style={{
                                      width: 40,
                                      height: 40,
                                    }}
                                    resizeMode="contain"
                                  />
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/ios.png",
                                    }}
                                    style={{
                                      width: 40,
                                      height: 40,
                                      marginLeft: "2%",
                                    }}
                                    resizeMode="contain"
                                  />
                                </View>
                              </View>
                              <View
                                style={{
                                  paddingRight: 20,
                                  paddingTop: 10,
                                  flexDirection: "row",
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/jama_qr.png",
                                  }}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    marginRight: this.state
                                      .isShowAppDownloadCard
                                      ? 30
                                      : 0,
                                  }}
                                  resizeMode="contain"
                                />
                                <TouchableOpacity
                                  onPress={() => {
                                    this.setState({
                                      isShowAppDownloadCard: "false",
                                    });
                                    localStorage.setItem(
                                      "isShowAppDownloadCard",
                                      "false"
                                    );
                                  }}
                                >
                                  <AntDesign
                                    name="close"
                                    size={24}
                                    color="rgba(0, 0, 0, 0.5)"
                                  />
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        )}
                      <View
                        style={{
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        {!this.state.is_club && (
                          <View
                            style={{
                              justifyContent: "flex-start",
                              marginTop: 10,
                            }}
                          >
                            <SideHeading title="For Your Attention" />
                          </View>
                        )}
                      </View>
                    </>
                  )}
                  {is_ria || this.state.is_club ? null : (
                    <AttentionStrip
                      {...this.props}
                      data={strips}
                      minDetailsResponse={this.state.minDetailsResponse}
                      userId={this.state.userId}
                      navigation={this.props.navigation}
                    />
                  )}
                </View>
              }
              {/* {console.log(
                "UserDetails check = ",
                Store.getState().userData.userProfileData
              )} */}
              {(this.state.is_preferred_circle || this.state.is_club) && (
                <>
                  <View style={styles.subContainer}>
                    <View style={{ width: isMobile ? "100%" : "70%" }}>
                      {this.state.is_preferred_circle &&
                        !this.state.userData.is_plan_advises_unlimited && (
                          <View
                            style={{
                              marginBottom: isMobile ? 80 : 20,
                            }}
                          >
                            <View
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <View
                                style={{
                                  marginLeft: "0%",
                                  backgroundColor: "white",
                                  width: "98.5%",
                                  height: 120,
                                  borderWidth: 1,
                                  borderRadius: 12,
                                  borderColor: "rgba(65, 13, 170, 0.3)",
                                }}
                              >
                                <TouchableOpacity
                                  onPress={async () => {
                                    this.props.navigation.push("App", {
                                      screen: "ViewMode",
                                      params: {
                                        screen: "CountTrackerHome",
                                        params: {
                                          showHeader: true,
                                        },
                                      },
                                    });
                                  }}
                                >
                                  <View
                                    style={{
                                      display: "flex",
                                      marginLeft: "1.9%",
                                      marginRight: "1.9%",
                                      flexDirection: "row",
                                      marginTop: "0.35%",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <View
                                      style={{
                                        flex: 1,
                                        flexDirection: "column",
                                        marginTop: this.state.is_club ? 20 : 30,
                                      }}
                                    >
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          marginBottom: "4%",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 20,
                                            color: "#464E5F",
                                            fontWeight: "700",
                                          }}
                                        >
                                          Advices Remaining{" "}
                                          {this.state.totalCount -
                                            this.state.availedCount}
                                          /{this.state.totalCount}
                                        </Text>
                                      </View>
                                      {this.state.is_club ? (
                                        <Text
                                          style={{
                                            fontSize: 18,
                                            color: "#6b717f",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 18,
                                              color: "#8d6ecc",
                                              fontWeight: "500",
                                            }}
                                          ></Text>
                                        </Text>
                                      ) : (
                                        <Text
                                          style={{
                                            fontSize: 18,
                                            color: "#6b717f",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Pack Expires on:{" "}
                                          <Text
                                            style={{
                                              fontSize: 18,
                                              color: "#8d6ecc",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {moment(this.state.endDate).format(
                                              "DD MMM YYYY"
                                            )}
                                          </Text>
                                        </Text>
                                      )}
                                      {/* {this.state.is_club ? (
                                      <>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                          }}
                                        >
                                          <Text
                                            onPress={() => { }}
                                            style={{
                                              fontSize: 14,
                                              color: "#410daa",
                                              fontWeight: "700",
                                              marginBottom: "2%",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            For 24 advices/yr - Upgrade to
                                            Advisory Circle{" "}
                                          </Text>
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                                            }}
                                            style={[
                                              {
                                                width: 10,
                                                height: 10,
                                                marginTop: 3,
                                              },
                                            ]}
                                          ></Image>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                          }}
                                        >
                                          <Text
                                            onPress={() => { }}
                                            style={{
                                              fontSize: 14,
                                              color: "#410daa",
                                              fontWeight: "700",
                                              marginBottom: "2%",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            For Unlimited advices - Upgrade to
                                            Elite Circle{" "}
                                          </Text>
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                                            }}
                                            style={[
                                              {
                                                width: 10,
                                                height: 10,
                                                marginTop: 3,
                                              },
                                            ]}
                                          ></Image>
                                        </View>
                                      </>
                                    ) : null} */}
                                    </View>
                                    <View
                                      style={{
                                        flex: 1,
                                        alignItems: "flex-end",
                                        flexDirection: "column",
                                        marginRight: "-2.5%",
                                        marginTop: 7,
                                      }}
                                    >
                                      {Platform.OS == "web" ? (
                                        <View
                                          style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <View
                                            style={{
                                              flexDirection: "column",
                                              marginTop: "-2%",
                                            }}
                                          >
                                            <View
                                              style={[
                                                {
                                                  alignSelf: "flex-start",
                                                  flexDirection: "row",
                                                  marginVertical: 7.5,
                                                },
                                              ]}
                                            >
                                              <View
                                                style={{
                                                  width: 15,
                                                  height: 15,
                                                  borderRadius: 2,
                                                  backgroundColor: "#876CDD",
                                                  marginTop: 1,
                                                }}
                                              />
                                              <Text
                                                style={{
                                                  fontSize: 14,
                                                  color: "#464E5F",
                                                  paddingLeft: 13,
                                                  fontWeight: "700",
                                                  fontFamily: "Roboto",
                                                }}
                                              >
                                                Availed{" "}
                                                {this.state.availedCount}
                                              </Text>
                                            </View>
                                            <View
                                              style={[
                                                {
                                                  alignSelf: "flex-start",
                                                  flexDirection: "row",
                                                  marginVertical: 7.5,
                                                },
                                              ]}
                                            >
                                              <View
                                                style={{
                                                  width: 15,
                                                  height: 15,
                                                  borderRadius: 2,
                                                  backgroundColor: "#BAC7F2",
                                                  marginTop: 1,
                                                }}
                                              />
                                              <Text
                                                style={{
                                                  fontSize: 14,
                                                  color: "#464E5F",
                                                  fontWeight: "700",
                                                  paddingLeft: 13,
                                                  fontFamily: "Roboto",
                                                }}
                                              >
                                                Remaining{" "}
                                                {this.state.totalCount -
                                                  this.state.availedCount}
                                              </Text>
                                            </View>
                                          </View>
                                          <Donut
                                            options={{
                                              dataLabels: {
                                                enabled: false,
                                              },
                                              legend: {
                                                show: false,
                                              },
                                              tooltip: { enabled: false },
                                              colors: ["#876CDD", "#BAC7F2"],
                                              plotOptions: {
                                                pie: {
                                                  donut: {
                                                    size: "60%",
                                                  },
                                                },
                                              },
                                              stroke: {
                                                width: 1,
                                                colors: ["#876CDD", "#BAC7F2"],
                                              },
                                            }}
                                            series={[
                                              (this.state.availedCount /
                                                (this.state.availedCount +
                                                  this.state.totalCount -
                                                  this.state.availedCount)) *
                                                100,
                                              ((this.state.totalCount -
                                                this.state.availedCount) /
                                                (this.state.availedCount +
                                                  this.state.totalCount -
                                                  this.state.availedCount)) *
                                                100,
                                            ]}
                                            sliceColor={["#876CDD", "#BAC7F2"]}
                                            type="donut"
                                            width="150"
                                          />
                                        </View>
                                      ) : (
                                        <Donut
                                          widthAndHeight={100}
                                          series={[
                                            (this.state.availedCount /
                                              (this.state.availedCount +
                                                this.state.totalCount -
                                                this.state.availedCount)) *
                                              100,
                                            ((this.state.totalCount -
                                              this.state.availedCount) /
                                              (this.state.availedCount +
                                                this.state.totalCount -
                                                this.state.availedCount)) *
                                              100,
                                          ]}
                                          sliceColor={["#876CDD", "#BAC7F2"]}
                                          doughnut={true}
                                          coverRadius={0.6}
                                          coverFill={"#FFF"}
                                        />
                                      )}
                                    </View>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        )}
                      {this.state.is_goal_avl && (
                        <View
                          style={{
                            marginTop: 20,
                            marginBottom: isMobile ? 80 : 20,
                            width: "100%",
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <View
                              style={{
                                marginLeft: "0%",
                                marginRight: "3%",
                                backgroundColor: "white",
                                width: "98.5%",
                                height: 130,
                                borderWidth: 1,
                                borderRadius: 12,
                                borderColor: "rgba(65, 13, 170, 0.3)",
                              }}
                            >
                              <TouchableOpacity
                                onPress={async () => {
                                  this.props.navigation.navigate("App", {
                                    screen: "Goals",
                                    params: {
                                      screen: "BasicDetails",
                                      params: {
                                        type: "COMPLETE",
                                      },
                                    },
                                  });
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    width: "100%",
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/finplan_landing_image.png",
                                    }}
                                    style={{
                                      width: 125,
                                      height: 125,
                                      alignSelf: "center",
                                      marginLeft: "2%",
                                    }}
                                    resizeMode="contain"
                                  />
                                  <View
                                    style={{
                                      flexDirection: "column",
                                      marginLeft: isMobile ? 0 : "5%",
                                      marginTop: "4%",
                                      width: "60%",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 17,
                                        fontWeight: "bold",
                                        paddingVertical: 10,
                                        textAlign: "center",
                                      }}
                                    >
                                      Start your financial plan
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        width: "100%",
                                        textAlign: "center",
                                        color: "#121212",
                                      }}
                                    >
                                      A financial plan is required to get
                                      personalised investment advice.
                                    </Text>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      )}
                    </View>
                    <View style={{ padding: isMobile ? 10 : "1.5%" }}></View>
                    <View
                      style={{
                        width: isMobile ? "100%" : "30%",
                        marginTop: -20,
                      }}
                    >
                      {is_EquityUser
                        ? is_ria ||
                          this.state.is_preferred_circle ||
                          this.state.is_club
                          ? null
                          : !(
                              this.props.route.params &&
                              this.props.route.params.user_id
                            ) &&
                            !(
                              Store.getState().dashboard.viewModeUserId &&
                              Store.getState().dashboard.viewModeUserId
                                .user_id &&
                              Store.getState().dashboard.viewModeUserId.user_id
                            ) && (
                              <View>
                                <TopupStrip
                                  noCompliance={
                                    !gettingMinDetails &&
                                    minDetailsResponse.needCompletion
                                  }
                                  minDetailsResponse={minDetailsResponse}
                                  style={{ flex: 1, height: "100%" }}
                                  navigation={this.props.navigation}
                                  subscribed_products={subscribed_products}
                                  onClick={this.handleTopupClick}
                                  viewMode={this.state.userId}
                                />
                              </View>
                            )
                        : null}
                      {is_MfUser ? (
                        is_ria ||
                        this.state.is_preferred_circle ||
                        this.state.is_club ? null : (
                          <View>
                            <BookAdvisorStrip
                              navigation={this.props.navigation}
                            />
                          </View>
                        )
                      ) : null}
                    </View>
                  </View>
                </>
              )}
              <View>
                <View style={styles.subContainer}>
                  <View style={{ width: isMobile ? "105%" : "63%" }}>
                    {!is_ftu && (
                      <View>
                        <SideHeading title="Your Portfolio" />
                        <PortfolioStrip
                          data={this.state.investTotalUser}
                          value={this.state.investValue}
                          total_invested_value={this.state.total_invested_value}
                          last_Updated_date={this.state.last_Updated_date}
                          oneday_return_value={this.state.oneday_return_value}
                          overall_return_value={this.state.overall_return_value}
                          yearly_gain={this.state.yearly_gain}
                        />
                      </View>
                    )}
                  </View>
                  <View style={{ padding: isMobile ? 10 : "2%" }}></View>
                  {!(this.state.is_preferred_circle || this.state.is_club) && (
                    <View
                      style={{
                        width: isMobile ? "100%" : "30%",
                        marginTop: isMobile ? 0 : 20,
                      }}
                    >
                      {is_EquityUser
                        ? is_ria
                          ? null
                          : !(
                              this.props.route.params &&
                              this.props.route.params.user_id
                            ) &&
                            !(
                              Store.getState().dashboard.viewModeUserId &&
                              Store.getState().dashboard.viewModeUserId
                                .user_id &&
                              Store.getState().dashboard.viewModeUserId.user_id
                            ) && (
                              <View>
                                <TopupStrip
                                  noCompliance={
                                    !gettingMinDetails &&
                                    minDetailsResponse.needCompletion
                                  }
                                  minDetailsResponse={minDetailsResponse}
                                  style={{ flex: 1, height: "100%" }}
                                  navigation={this.props.navigation}
                                  subscribed_products={subscribed_products}
                                  onClick={this.handleTopupClick}
                                  viewMode={this.state.userId}
                                />
                              </View>
                            )
                        : null}
                      {is_MfUser ? (
                        is_ria ||
                        this.state.is_preferred_circle ||
                        this.state.is_club ? null : (
                          <View>
                            <BookAdvisorStrip
                              navigation={this.props.navigation}
                            />
                          </View>
                        )
                      ) : null}
                    </View>
                  )}
                </View>
              </View>
              {!this.props.demoMode && is_MfUser ? (
                is_ria ? null : (
                  <View
                    style={{
                      marginTop: isMobile
                        ? 20
                        : !(
                            this.state.is_preferred_circle || this.state.is_club
                          )
                        ? !(
                            Store.getState().dashboard.viewModeUserId &&
                            Store.getState().dashboard.viewModeUserId.user_id &&
                            Store.getState().dashboard.viewModeUserId.user_id
                          )
                          ? -60
                          : 0
                        : 0,
                      width: isMobile ? "auto" : "60%",
                    }}
                  >
                    <SideHeading title="Grow Wealth" />
                    <GrowWealthStrip navigation={this.props.navigation} />
                  </View>
                )
              ) : null}
              <View style={{ marginBottom: 66, marginTop: 10 }}>
                {getScreenConfigForCustomer().show_fin_assessments && (
                  <View>
                    <SideHeading title="Invest for your Future" />
                    <InvestFutureStrip navigation={this.props.navigation} />
                  </View>
                )}
                <View
                  style={{
                    marginBottom: isMobile ? 66 : -20,
                  }}
                >
                  {/* {this.state.is_club && (
                    <View style={{
                      flexDirection: isMobile ? 'column' : 'row',
                    }}>
                      <View
                        style={{
                          marginTop: 0,
                          width: isMobile ? '100%' : '50%'
                        }}
                      >
                        <SideHeading title="Upgrade to Advisory Circle" />
                        <AdvisoryClubStrip navigation={this.props.navigation} />
                      </View>
                      <View
                        style={{
                          marginTop: 0,
                          width: isMobile ? '100%' : '50%'
                        }}
                      >
                        <SideHeading title="Upgrade to Elite Circle" />
                        <EliteCircleStrip navigation={this.props.navigation} />
                      </View>
                    </View>
                  )} */}
                  {getScreenConfigForCustomer().show_fin_education && (
                    <View
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <SideHeading
                        title={isJama() ? "Jama Academy" : "Maxiom Academy"}
                      />
                      <JamaAcademyStrip navigation={this.props.navigation} />
                    </View>
                  )}
                  {getScreenConfigForCustomer().show_fin_assessments && (
                    <View>
                      <SideHeading title="Assess Financial Wellness" />
                      <AssessWellnessStrip navigation={this.props.navigation} />
                    </View>
                  )}
                  {getScreenConfigForCustomer().show_fin_assessments &&
                    this.state.is_corporate_admin && (
                      <View style={{ marginTop: 20, marginBottom: 20 }}>
                        <SideHeading title="Emotional Wellness" />
                        <EmotionalWellnessStrip
                          navigation={this.props.navigation}
                        />
                      </View>
                    )}
                  {getScreenConfigForCustomer().show_imports && (
                    <>
                      <View style={{ marginTop: 10 }}>
                        <SideHeading title="Get FREE Portfolio Review" />
                        <GetRicherStrip
                          navigation={this.props.navigation}
                          from={"dashboard"}
                          handleAggregator={this.handleAggregator}
                        />
                      </View>
                      <Text
                        style={{
                          color: "#888C9F",
                          fontFamily: "Roboto",
                          marginTop: width < 1200 && width > 768 ? -40 : 10,
                          marginBottom: width < 1200 && width > 768 ? 40 : 0,
                          marginLeft: 5,
                        }}
                      >
                        Get detailed Analysis within 1-2 days
                      </Text>
                    </>
                  )}
                </View>
              </View>
            </ScrollView>
          </>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  subContainer: {
    flex: 1,
    flexDirection: isMobile ? "column" : "row",
    justifyContent: isMobile ? "flex-start" : "",
    alignItems: isMobile ? "flex-start" : "",
    paddingTop: isMobile ? 0 : 0,
  },
  sideHeadingStyle: {
    color: "#410DAA",
  },
  subHeading: {
    color: "#484848",
    fontSize: 18,
    fontWeight: "500",
  },
  rowDivision: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
  },
  halfContainer: {
    width: "45%",
    marginRight: 50,
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: 16,
    margin: isMobile ? 5 : 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    elevation: 5,
  },
  containert: {
    flex: 1,
    padding: 10,
    backgroundColor: "#f0f0f0",
    borderRadius: 10,
    marginBottom: 20,
  },
  headert: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    paddingBottom: 5,
    marginBottom: 10,
    backgroundColor: "rgba(65, 13, 170, 0.2)",
    borderRadius: 5,
  },
  headerTextt: {
    fontWeight: "bold",
    flex: 1,
    textAlign: "center",
    padding: 10,
  },
  rowt: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    backgroundColor: "#fff",
    borderRadius: 5,
  },
  cellt: {
    flex: 1,
    textAlign: "center",
    padding: 10,
  },
  listContainer: {
    flexGrow: 1,
  },
});

const mapStateToProps = (state) => ({
  demoMode: state.testConfig.demoMode,
});

export default connect(mapStateToProps)(NewHomeDashboard);
