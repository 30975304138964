// src/store.js

import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import user from "../reducers/user";
import finPlan from "../reducers/finplan";
import dashboard from "../reducers/dashboard";
import portfolio from "../reducers/portfolio";
import plans from "../reducers/plans";
import explore from "../reducers/explore";
import goals from "../reducers/goals";
import testConfig from "../reducers/testConfig";
import transactions from "../reducers/transactions";
import showGetStarted from "../reducers/showGetStarted";

// Combine the individual reducers into a single root reducer
const rootReducer = combineReducers({
  userData: user,
  finPlan,
  dashboard,
  portfolio,
  plans,
  explore,
  goals,
  testConfig,
  transactions,
  showGetStarted,
});

// Configure persist settings
const persistConfig = {
  key: 'root',
  storage,
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer and middleware
const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create a persistor
export const persistor = persistStore(Store);

// Export the configured store as the default export
export default Store;
