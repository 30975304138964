import React, { useState } from "react";
import { View, Text, TextInput, StyleSheet, Image } from "react-native";
import Divider from "../../../components/Common/divider";
import { isMobile, removeString, numberWithCommas } from "../../../utils";
import CommaTextField from "./commaTextField";
import moment from "moment";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";

export const SuggestedFundsCard = ({
  data,
  index,
  mandateList,
  sipArrToShow,
  lumpsumArrToShow,
  onSelectMandate,
  onSipDateChange,
  onFolioNumberChange,
  onSIPTextChange,
  onLumpsumTextChange,
}) => {
  let goalName = data["name"];
  let projected = data["projected_value"];
  let achieved = data["achieved_value"];
  let monthlyask = Math.round(data["calculated_monthly_installment"]);
  let current = Math.round(data["current_sip_contibution"]);
  let total = Math.round(data["target_amount_initially_calculated"]);
  let image = data["goal_avatar"];
  let dueDate = data["target_date_text"];
  let currentDate = moment();
  let targetDate = moment(data.target_date_text);
  let years = targetDate.diff(currentDate, "years");

  let totalNew = total;
  if (totalNew >= 10000000) {
    totalNew = (totalNew / 10000000).toFixed(1) + " Cr";
  }
  if (totalNew >= 100000) {
    totalNew = (totalNew / 100000).toFixed(1) + " L";
  }
  if (totalNew >= 1000) {
    totalNew = (totalNew / 1000).toFixed(1) + " K";
  }

  let monthlyAskNew = monthlyask;
  if (monthlyAskNew >= 10000000) {
    monthlyAskNew = (monthlyAskNew / 10000000).toFixed(1) + " Cr";
  }
  if (monthlyAskNew >= 100000) {
    monthlyAskNew = (monthlyAskNew / 100000).toFixed(1) + " L";
  }
  if (monthlyAskNew >= 1000) {
    monthlyAskNew = (monthlyAskNew / 1000).toFixed(1) + " K";
  }

  let currentNew = current;
  if (currentNew >= 10000000) {
    currentNew = (currentNew / 10000000).toFixed(1) + " Cr";
  }
  if (currentNew >= 100000) {
    currentNew = (currentNew / 100000).toFixed(1) + " L";
  }
  if (currentNew >= 1000) {
    currentNew = (currentNew / 1000).toFixed(1) + " K";
  }

  let achievedNew = achieved;
  if (achievedNew >= 10000000) {
    achievedNew = (achievedNew / 10000000).toFixed(1) + " Cr";
  }
  if (achievedNew >= 100000) {
    achievedNew = (achievedNew / 100000).toFixed(1) + " L";
  }
  if (achievedNew >= 1000) {
    achievedNew = (achievedNew / 1000).toFixed(1) + " K";
  }

  let projectedNew = projected;
  if (projectedNew >= 10000000) {
    projectedNew = (projectedNew / 10000000).toFixed(1) + " Cr";
  }
  if (projectedNew >= 100000) {
    projectedNew = (projectedNew / 100000).toFixed(1) + " L";
  }
  if (projectedNew >= 1000) {
    projectedNew = (projectedNew / 1000).toFixed(1) + " K";
  }
  const isDeficit = total > projected;
  const deficitOrSurplus = isDeficit ? total - projected : projected - total;
  let deficitOrSurplusNew = deficitOrSurplus;
  if (deficitOrSurplusNew >= 10000000) {
    deficitOrSurplusNew = (deficitOrSurplusNew / 10000000).toFixed(1) + " Cr";
  }
  if (deficitOrSurplusNew >= 100000) {
    deficitOrSurplusNew = (deficitOrSurplusNew / 100000).toFixed(1) + " L";
  }
  if (deficitOrSurplusNew >= 1000) {
    deficitOrSurplusNew = (deficitOrSurplusNew / 1000).toFixed(1) + " K";
  }
  let currentRatioPer = 0;
  let monthlyRatioPer = 0;
  if (current == 0 && monthlyask == 0) {
    currentRatioPer = 0.5;
    monthlyRatioPer = 0.5;
  } else {
    currentRatioPer = current / (current + monthlyask);
    monthlyRatioPer = monthlyask / (current + monthlyask);
  }
  currentRatioPer = parseFloat(currentRatioPer.toFixed(2));
  monthlyRatioPer = parseFloat(monthlyRatioPer.toFixed(2));

  const dateThValue = (d) => {
    d = parseInt(d);
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const getMandateStatusNames = (code) => {
    switch (code) {
      case "PND":
        return "Pending";
      case "SLF":
        return "Registered by user at bank";
      case "REC":
        return "Received by Exchange. Takes 3-4 working days to Approve";
      case "APR":
        return "Approved";
      case "REJ":
        return "Rejected";
      case "CAN":
        return "Cancelled by Investor";
      case "RNB":
        return "Rejection at NPCI prior to Destination Bank";
      case "RBB":
        return "Rejected by sponsor bank";
      default:
        return null;
    }
  };

  return (
    <>
      <View
        style={{
          borderColor: "#410DAA33",
          borderWidth: 1,
          borderRadius: 6,
          marginBottom: 40,
          ...styles.container,
        }}
      >
        <View style={styles.basicDetails}>
          <View style={styles.goalNameContainer}>
            <View style={styles.goalName}>
              <Image
                style={styles.goalIcon}
                source={{
                  uri: image,
                }}
              />
            </View>
          </View>
          <View style={styles.fundContainer}>
            <Text style={styles.fund}>
              {removeString(goalName, "Goal", "goal")}
            </Text>
          </View>
          <View style={styles.dateContainer}>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.date}>Due </Text>
              <Text
                style={{
                  fontSize: 13,
                  color: "#121212",
                  fontWeight: "bold",
                }}
              >
                {dueDate}
              </Text>
            </View>
          </View>
        </View>
        {data["schemeDetails"].map((item, index) => {
          let mandateListData = mandateList;
          let newMandateList = [];
          mandateListData.forEach((value) => {
            if (
              item.is_for_sip &&
              item.sip_amount_for_goal &&
              item.sip_amount_for_goal <= value.amount
            ) {
              newMandateList.push({
                label: `₹ ${numberWithCommas(
                  value.amount
                )}-${getMandateStatusNames(value.status)}-${
                  value.bank_name
                }-xxxx${value.bank_account_no ? value.bank_account_no.substring(
                  value.bank_account_no.length - 4,
                  4
                ) : ""}`,
                value: value.mandate_id,
              });
            }
          });
          newMandateList.push({
            label: "Create New Mandate",
            value: "create_new_mandate",
          });
          let default_sip_dates =
            "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28";
          let arr = null;
          if (
            item.bse_sip_monthly_dates &&
            item.bse_sip_monthly_dates.length > 1
          ) {
            arr = item.bse_sip_monthly_dates.split(",");
          } else {
            arr = default_sip_dates.split(",");
            // detail.bse_sip_monthly_dates = default_sip_dates;
          }
          // let arr = item.bse_sip_monthly_dates.split(",");
          let sipDates = [];
          let folioNumbersList = item.folio_list;
          let folioNumbers = [];
          folioNumbersList.forEach((item) => {
            folioNumbers.push({
              label: item.bse_folio_no,
              value: item.iaffolio_pk,
            });
          });
          folioNumbers.push({
            label: "New Folio",
            value: "newfolio",
          });
          let selectedFolio = item.selectedFolio;
          arr.forEach((item) => {
            let label = item + dateThValue(item);
            if (item.length == 1) {
              item = `0${item}`;
            }
            sipDates.push({
              label,
              value: item,
            });
          });
          let selectedMandate = "";
          if (item.selected_mandate_id) {
            selectedMandate = item.selected_mandate_id;
          }
          let newSipDate = "";
          if (item.sip_start_date) {
            newSipDate = item.sip_start_date ? item.sip_start_date.substring(8, 20) : "";
          }
          return (
            <>
              <Divider width={1} color="#410DAA33" />
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <View style={{ flex: 0.5, flexDirection: "column" }}>
                  <Text
                    style={{
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: 14,
                      marginLeft: 15,
                      marginTop: 10,
                    }}
                  >
                    {" "}
                    {item.name}
                  </Text>
                  <View
                    style={{
                      padding: 15,
                      paddingRight: 0,
                      flexDirection: "row",
                    }}
                  >
                    <View style={{ flex: 0.6, flexDirection: "column" }}>
                      <Text style={{ color: "#6b717f", fontSize: 15 }}>
                        Fund Class{"  "}
                      </Text>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 14,
                        }}
                      >
                        {item.AMCFund__fund_class}
                      </Text>
                    </View>
                    <View style={{ flex: 0.4, flexDirection: "column" }}>
                      <Text style={{ color: "#6b717f", fontSize: 15 }}>
                        5 Yr Returns{"  "}
                      </Text>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 14,
                        }}
                      >
                        {item.returns_5_yr
                          ? `${item.returns_5_yr.toFixed(2)}%`
                          : "N.A."}
                      </Text>
                    </View>
                    <View style={{ flex: 0.4, flexDirection: "column" }}>
                      <Text style={{ color: "#6b717f", fontSize: 15 }}>
                        3 Yr Returns{"  "}
                      </Text>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 14,
                        }}
                      >
                        {item.returns_3_yr
                          ? `${item.returns_3_yr.toFixed(2)}%`
                          : "N.A."}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flex: 0.2,
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Text style={{ color: "#6b717f", fontSize: 15 }}>
                    Folio No{"  "}
                  </Text>
                  <PickerFieldSelect
                    data={folioNumbers}
                    value={selectedFolio}
                    label={null}
                    showSelect={false}
                    onChange={(value) => {
                      onFolioNumberChange(value, item.AMCFund__pk);
                    }}
                    pickerStyle={[{ width: 150, borderColor: "#e7e7e7" }]}
                    type="select"
                  />
                </View>
                {/* <View
                  style={{
                    flex: 0.2,
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Text
                    style={{ color: "#6b717f", fontSize: 15, marginLeft: 10 }}
                  >
                    Lumpsum{"  "}
                  </Text>
                  <CommaTextField
                    value={
                      item.is_for_lumpsum && item.lumpsum_amount_for_goal
                        ? item.lumpsum_amount_for_goal.toFixed(0)
                        : "0"
                    }
                    noEdit={false}
                    onValueChange={(text) => {
                      if (!isNaN(text)) {
                        onLumpsumTextChange(text, item.AMCFund__pk);
                      }
                    }}
                    containerStyle={{
                      width: 150,
                    }}
                    textStyle={{
                      fontSize: 14,
                      paddingVertical: 10,
                      paddingHorizontal: 5,
                    }}
                  />
                </View> */}
                <View
                  style={{
                    flex: 0.2,
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Text
                    style={{ color: "#6b717f", fontSize: 15, marginLeft: 10 }}
                  >
                    {/* {console.log("lumpsum default value is ", item)} */}
                    Lumpsum{"  "}
                  </Text>
                  <TextInput
                    defaultValue={
                      item.lumpsum_amount_for_goal
                        ? `₹ ${numberWithCommas(Math.round(item.lumpsum_amount_for_goal))}`
                        : "₹  0"
                    }
                    style={{
                      width: 150,
                      fontSize: 14,
                      paddingVertical: 10,
                      paddingHorizontal: 5,
                      borderColor: "#e7e7e7",
                      borderRadius: 5,
                      borderWidth: 1, // You can adjust this value as needed
                    }}
                    keyboardType="numeric"
                    editable={false}
                  />
                </View>
                <View
                  style={{
                    flex: 0.4,
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginBottom: 20,
                  }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        style={{
                          color: "#6b717f",
                          fontSize: 15,
                          marginLeft: 10,
                        }}
                      >
                        SIP{"  "}
                      </Text>
                      <TextInput
                        defaultValue={
                          item.is_for_sip
                            ? `₹ ${numberWithCommas(item.sip_amount_for_goal.toFixed(0))}`
                            : "₹  0"
                        }
                        style={{
                          width: 150,
                          fontSize: 14,
                          paddingVertical: 10,
                          paddingHorizontal: 5,
                          borderColor: "#e7e7e7",
                          borderRadius: 5,
                          borderWidth: 1, 
                          marginLeft: 10
                        }}
                        keyboardType="numeric"
                        editable={false}
                      />
                      {/* <CommaTextField
                        value={
                          item.is_for_sip
                            ? item.sip_amount_for_goal.toFixed(0)
                            : "0"
                        }
                        noEdit={false}
                        onValueChange={(text) => {
                          if (!isNaN(text)) {
                            onSIPTextChange(text, item.AMCFund__pk);
                          }
                        }}
                        containerStyle={{
                          width: 150,
                        }}
                        textStyle={{
                          fontSize: 14,
                          paddingVertical: 10,
                        }}
                      /> */}
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        width: 100,
                        marginLeft: 30,
                      }}
                    >
                      {item.is_for_sip ? (
                        <>
                          <Text style={{ color: "#6b717f", fontSize: 14 }}>
                            Monthly{"  "}
                          </Text>
                          <PickerFieldSelect
                            data={sipDates}
                            value={newSipDate}
                            label={null}
                            onChange={(value) => {
                              let newValue =
                                moment().format("YYYY-MM-") + value;
                              onSipDateChange(newValue, item.AMCFund__pk);
                            }}
                            pickerStyle={[
                              {
                                width: 110,
                                borderColor: "#e7e7e7",
                                height: 40,
                              },
                            ]}
                            type="select"
                          />
                        </>
                      ) : (
                        <View>
                          <View style={{ height: 65 }}></View>
                        </View>
                      )}
                    </View>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{
                        flexDirection: "column",
                        marginLeft: 10,
                        marginTop: 5,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Text style={{ color: "#6b717f", fontSize: 14 }}>
                          Select SIP Mandate{"  "}
                        </Text>
                        <PickerFieldSelect
                          data={newMandateList}
                          value={selectedMandate}
                          label={null}
                          onChange={(value) => {
                            onSelectMandate(value, item.AMCFund__pk);
                          }}
                          pickerStyle={[{ width: 300, borderColor: "#e7e7e7" }]}
                          type="select"
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </>
          );
        })}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    backgroundColor: "white",
  },
  basicDetails: {
    flexDirection: "row",
    paddingLeft: isMobile ? 8 : 16,
    paddingRight: 13,
    alignItems: "center",
    backgroundColor: "#e3dbf2",
  },
  goalName: {
    width: 38,
    height: 38,
    borderRadius: 19,
    backgroundColor: "#f2f2f2",
    justifyContent: "center",
    alignItems: "center",
  },
  goalNameText: { alignSelf: "center" },
  goalNameContainer: {
    flex: 0.15,
    maxWidth: 80,
    paddingTop: isMobile ? 0 : 8,
    paddingBottom: isMobile ? 0 : 8,
    flexDirection: "row",
  },
  fundContainer: { flex: 0.55, flexDirection: "row", alignItems: "center" },
  fund: { color: "#121212", fontWeight: "bold", fontSize: 15, paddingLeft: 5 },
  fundIcon: { paddingLeft: 10, color: "#EF5D12", fontSize: 14 },
  dateContainer: {
    flex: 0.4,
    flexDirection: "column",
    alignItems: "flex-end",
    paddingRight: isMobile ? 0 : 6,
  },
  date: { color: "black", fontSize: 13 },
  secondRowContainer: {
    flexDirection: "row",
    paddingHorizontal: 13,
    paddingBottom: 13,
  },
  projectedLabel: {
    width: 7,
    height: 7,
    backgroundColor: "#00C107",
    marginLeft: 10,
  },
  projectedText: { color: "#121212", fontSize: 14 },
  projected: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: isMobile ? 0 : 8,
  },
  projectedAmount: { color: "#121212", fontSize: 14 },
  projectedContainer: { flex: 0.5, flexDirection: "column" },

  deficitLabel: {
    width: 7,
    height: 7,
    backgroundColor: "#DB7657",
    marginLeft: 10,
  },
  deficitText: { color: "#121212", fontSize: 14 },
  deficit: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: isMobile ? 0 : 8,
  },
  deficitAmount: { color: "#121212", fontSize: 14 },
  deficitContainer: { flex: 0.5, flexDirection: "column" },

  thirdContainer: {
    flexDirection: "row",
    paddingHorizontal: 15,
    marginVertical: 10,
  },
  progressBar: {
    flex: 1,
    height: 5,
    flexDirection: "row",
    borderRadius: 6,
  },
  goalIcon: { width: 23, height: 23, resizeMode: "contain" },
});
