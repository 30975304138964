import React from "react";
import {
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
  TouchableOpacity,
  Platform,
  Image,
  Dimensions,
} from "react-native";
import { Text } from "react-native-paper";
import { getUserDetails, isMobile } from "../../utils";
import BulletPoint from "../../components/InvestmentOverview/NewDesign/components/BulletPoint";
const Swiper =
  Platform.OS == "web" ? null : require("react-native-swiper").default;
import amplitude from "../../utils/amplitude";
import Store from "../../store";
const ArrowIcon = require("../../assets/JamaInvest/Arrow-right2x.png");
const { width } = Dimensions.get("window");

export const AttentionStrip = ({
  data = {},
  minDetailsResponse,
  userId,
  navigation,
  ...props
}) => {
  const { investment_schedule, rebalancing, compliance } = data;

  const { bannerData, customer_id } = getUserDetails();
  let is_preferred_circle = false;
  let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
  if (hostname.includes("myadvisor")) {
    is_preferred_circle = true;
  }
  let isCompliant = false;
  let user_id = null;
  if (
    Store.getState().dashboard &&
    Store.getState().dashboard.viewModeUserId &&
    Store.getState().dashboard.viewModeUserId.user_id
  ) {
    user_id = Store.getState().dashboard.viewModeUserId.user_id;
  } else {
    user_id = customer_id;
  }
  if (bannerData.name == "Sensage" && user_id > 1 && user_id < 140) {
    isCompliant = true;
  }

  const handleCompliance = async () => {
    try {
      amplitude.logEvent("Attention_Compliance_Clicked");
      const { needCompletion, error } = minDetailsResponse;
      if (!error) {
        const { redirect_screen } = minDetailsResponse;
        if (needCompletion) {
          const { params = {} } = props.route;
          navigation.navigate("Onboarding", {
            screen: redirect_screen,
            params: { nvg: "InvestmentOverview", action: true, ...params },
          });
        } else {
          const { results } = minDetailsResponse;
          const pendingItems =
            results && results.filter((item) => item.is_completed == false);
          if (pendingItems && pendingItems.length) {
            const { redirect_screen } = pendingItems[0];
            if (redirect_screen) {
              const { params = {} } = props.route;
              navigation.navigate("Onboarding", {
                screen: redirect_screen,
                params: { nvg: "InvestmentOverview", action: true, ...params },
              });
            }
          }
        }
      }
    } catch (error) {}
  };

  return !isMobile ? (
    <SafeAreaView style={{ flex: 1, paddingTop: 10 }}>
      <ScrollView
        horizontal={isMobile ? true : false}
        showsHorizontalScrollIndicator={false}
      >
        <View style={styles.container}>
          {is_preferred_circle ? null : (
            <TouchableOpacity
              style={[styles.containerStrip, styles.subContainer]}
              onPress={() => {
                navigation.navigate("UpcomingInvestments",{
                  from : "Dashboard"
                });
              }}
            >
              <>
                <View style={styles.titleContainer}>
                  <Text style={styles.title}>My Plans</Text>
                  {true && (
                    <Image
                      source={ArrowIcon}
                      style={[
                        {
                          width: !isMobile ? 18 : 16,
                          height: !isMobile ? 18 : 16,
                        },
                      ]}
                    ></Image>
                  )}
                </View>
                {investment_schedule != null &&
                  investment_schedule.summary != null &&
                  investment_schedule.summary.map((point) => {
                    return (
                      <View style={styles.pointsContainer}>
                        <View style={styles.point}>
                          <BulletPoint
                            outerColor="#888C9F60"
                            innerColor="#888C9F"
                            style={[styles.bulletPoint]}
                          />
                          <Text style={styles.pointText}>{point}</Text>
                        </View>
                      </View>
                    );
                  })}
                {investment_schedule != null &&
                  investment_schedule.next_up != null &&
                  investment_schedule.next_up && (
                    <View style={styles.pointsContainer}>
                      <View style={styles.point}>
                        <BulletPoint
                          outerColor="#888C9F60"
                          innerColor="#888C9F"
                          style={[styles.bulletPoint]}
                        />
                        <Text style={[styles.pointText, styles.nextUpTitle]}>
                          Next Up:
                          <Text style={styles.nextUp}>
                            {" "}
                            {investment_schedule.next_up}
                          </Text>
                        </Text>
                      </View>
                    </View>
                  )}
                {investment_schedule != null &&
                  investment_schedule.extra != "" && (
                    <Text style={styles.extra}>
                      {investment_schedule.extra}
                    </Text>
                  )}
              </>
            </TouchableOpacity>
          )}
          {is_preferred_circle ? (
            rebalancing.ssi_show_sell ? (
              <View style={[styles.containerStrip, styles.subContainer]}>
                <>
                  <View style={styles.titleContainer}>
                    <Text style={styles.title}>Portfolio Actions</Text>
                  </View>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate("App", {
                        screen: "ViewMode",
                        params: {
                          screen: "SellExitStocks",
                          params: {},
                        },
                      });
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <View style={styles.pointsContainer}>
                        <View style={styles.point}>
                          <BulletPoint
                            outerColor="#888C9F60"
                            innerColor="#888C9F"
                            style={[styles.bulletPoint]}
                          />
                          <Text style={styles.pointText}>
                            Stocks avaliable to sell
                          </Text>
                        </View>
                      </View>
                      <Image
                        source={ArrowIcon}
                        style={[
                          {
                            width: !isMobile ? 18 : 16,
                            height: !isMobile ? 18 : 16,
                          },
                        ]}
                      ></Image>
                    </View>
                  </TouchableOpacity>
                </>
              </View>
            ) : null
          ) : (
            <View style={[styles.containerStrip, styles.subContainer]}>
              <>
                <View style={styles.titleContainer}>
                  <Text style={styles.title}>Portfolio Actions</Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("Portfolio", {
                      screen: "RebalanceLandingPage",
                      params: {
                        from: "Dashboard"
                      }
                    });
                  }}
                >
                  {rebalancing != null &&
                    rebalancing.summary != null &&
                    rebalancing.summary.map((point) => {
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <View style={styles.pointsContainer}>
                            <View style={styles.point}>
                              <BulletPoint
                                outerColor="#888C9F60"
                                innerColor="#888C9F"
                                style={[styles.bulletPoint]}
                              />
                              <Text style={styles.pointText}>{point}</Text>
                            </View>
                          </View>
                          <Image
                            source={ArrowIcon}
                            style={[
                              {
                                width: !isMobile ? 18 : 16,
                                height: !isMobile ? 18 : 16,
                              },
                            ]}
                          ></Image>
                        </View>
                      );
                    })}
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  onPress={() => {
                    navigation.navigate("ViewMode", {
                      screen: "ContractNote",
                    });
                  }}
                >
                  {rebalancing != null &&
                  rebalancing.contract_note_due != null &&
                  rebalancing.contract_note_due.ccn_missing != null &&
                  rebalancing.contract_note_due.ccn_missing > 0 ? (
                    <View
                      style={[
                        styles.pointsContainer,
                        {
                          flexDirection: "row",
                          justifyContent: "space-between",
                        },
                      ]}
                    >
                      <View style={styles.point}>
                        <BulletPoint
                          outerColor="#888C9F60"
                          innerColor="#888C9F"
                          style={[styles.bulletPoint]}
                        />
                        <Text style={styles.pointText}>
                          Contract notes due:{" "}
                          {rebalancing.contract_note_due.ccn_missing}
                        </Text>
                      </View>
                      <Image
                        source={ArrowIcon}
                        style={[
                          {
                            width: !isMobile ? 18 : 16,
                            height: !isMobile ? 18 : 16,
                          },
                        ]}
                      ></Image>
                    </View>
                  ) : null}
                  {Store.getState().dashboard &&
                  Store.getState().dashboard.viewModeUserId &&
                  Store.getState().dashboard.viewModeUserId.user_id ? (
                    rebalancing != null &&
                    rebalancing.contract_note_due != null &&
                    rebalancing.contract_note_due.ccn_fail != null &&
                    rebalancing.contract_note_due.ccn_fail > 0 ? (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 8,
                        }}
                      >
                        <View style={[styles.point]}>
                          <BulletPoint
                            outerColor="#888C9F60"
                            innerColor="#888C9F"
                            style={[styles.bulletPoint]}
                          />
                          <Text style={styles.pointText}>
                            Contract note failed:{" "}
                            {rebalancing.contract_note_due.ccn_fail}
                          </Text>
                        </View>
                        <Image
                          source={ArrowIcon}
                          style={[
                            {
                              width: !isMobile ? 18 : 16,
                              height: !isMobile ? 18 : 16,
                            },
                          ]}
                        ></Image>
                      </View>
                    ) : null
                  ) : null}
                </TouchableOpacity>
              </>
            </View>
          )}
          <TouchableOpacity
            style={[styles.containerStrip, styles.subContainer]}
            onPress={handleCompliance}
          >
            <>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>Compliance</Text>
                {true &&
                  (compliance == null || isCompliant ? null : (
                    <Image
                      source={ArrowIcon}
                      style={[
                        {
                          width: !isMobile ? 18 : 16,
                          height: !isMobile ? 18 : 16,
                        },
                      ]}
                    ></Image>
                  ))}
              </View>
              {compliance == null || isCompliant ? (
                <View style={styles.pointsContainer}>
                  <View style={styles.point}>
                    <BulletPoint
                      outerColor="#888C9F60"
                      innerColor="#888C9F"
                      style={[styles.bulletPoint]}
                    />
                    <Text style={styles.complianceNullText}>
                      Your account is compliant
                    </Text>
                  </View>
                </View>
              ) : (
                compliance != null &&
                compliance.summary.map((point) => {
                  return (
                    <View style={styles.pointsContainer}>
                      <View style={styles.point}>
                        <BulletPoint
                          outerColor="#888C9F60"
                          innerColor="#888C9F"
                          style={[styles.bulletPoint]}
                        />
                        <Text style={styles.pointText}>{point}</Text>
                      </View>
                    </View>
                  );
                })
              )}
              {compliance != null && !isCompliant && compliance.extra != "" && (
                <Text style={styles.extra}>{compliance.extra}</Text>
              )}
            </>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView>
  ) : (
    <>
      {Platform.OS != "web" ? (
        <Swiper
          showsPagination={true}
          height={170}
          autoplay={false}
          loop={false}
          index={0}
          containerStyle={{
            marginTop: 10,
          }}
          dotStyle={{
            marginTop: 0,
            width: 6,
            height: 6,
          }}
          dotColor="#BCBCBC"
          activeDotStyle={{
            marginTop: 0,
            width: 6,
            height: 6,
          }}
          activeDotColor="#410DAA"
        >
          <TouchableOpacity
            style={[styles.containerStrip, styles.subContainer]}
            onPress={() => {
              amplitude.logEvent("Attention_My_Plans_Clicked");
              navigation.navigate("UpcomingInvestments",{
                from : "Dashboard"
              });
            }}
          >
            <>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>My Plans</Text>
                {true && (
                  <Image
                    source={ArrowIcon}
                    style={[
                      {
                        width: !isMobile ? 18 : 16,
                        height: !isMobile ? 18 : 16,
                      },
                    ]}
                  ></Image>
                )}
              </View>
              {investment_schedule != null &&
                investment_schedule.summary != null &&
                investment_schedule.summary.map((point) => {
                  return (
                    <View style={styles.pointsContainer}>
                      <View style={styles.point}>
                        <BulletPoint
                          outerColor="#888C9F60"
                          innerColor="#888C9F"
                          style={[styles.bulletPoint]}
                        />
                        <Text style={styles.pointText}>{point}</Text>
                      </View>
                    </View>
                  );
                })}
              {investment_schedule != null &&
                investment_schedule.next_up != null &&
                investment_schedule.next_up && (
                  <View style={styles.nextUpContainer}>
                    <BulletPoint
                      outerColor="#888C9F60"
                      innerColor="#888C9F"
                      style={[styles.bulletPoint]}
                    />
                    <View>
                      <Text style={[styles.pointText, styles.nextUpTitle]}>
                        Next Up:
                        <Text style={styles.nextUp}>
                          {" "}
                          {investment_schedule.next_up}
                        </Text>
                      </Text>
                    </View>
                  </View>
                )}
              {investment_schedule != null &&
                investment_schedule.extra != "" && (
                  <Text style={styles.extra}>{investment_schedule.extra}</Text>
                )}
            </>
          </TouchableOpacity>
          <View style={[styles.containerStrip, styles.subContainer]}>
            <>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>Portfolio Actions</Text>
              </View>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("Portfolio", {
                    screen: "RebalanceLandingPage",
                    params: {
                      from: "Dashboard"
                    }
                  });
                }}
              >
                {rebalancing != null &&
                  rebalancing.summary != null &&
                  rebalancing.summary.map((point) => {
                    return (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <View style={styles.pointsContainer}>
                          <View style={styles.point}>
                            <BulletPoint
                              outerColor="#888C9F60"
                              innerColor="#888C9F"
                              style={[styles.bulletPoint]}
                            />
                            <Text style={styles.pointText}>{point}</Text>
                          </View>
                        </View>
                        <Image
                          source={ArrowIcon}
                          style={[
                            {
                              width: !isMobile ? 18 : 16,
                              height: !isMobile ? 18 : 16,
                            },
                          ]}
                        ></Image>
                      </View>
                    );
                  })}
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                onPress={() => {
                  navigation.navigate("ViewMode", {
                    screen: "ContractNote",
                  });
                }}
              >
                {rebalancing != null &&
                rebalancing.contract_note_due != null &&
                rebalancing.contract_note_due.ccn_missing != null ? (
                  <View
                    style={[
                      styles.pointsContainer,
                      {
                        flexDirection: "row",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    <View style={styles.point}>
                      <BulletPoint
                        outerColor="#888C9F60"
                        innerColor="#888C9F"
                        style={[styles.bulletPoint]}
                      />
                      <Text style={styles.pointText}>
                        Contract note due:{" "}
                        {rebalancing.contract_note_due.ccn_missing}
                      </Text>
                    </View>
                    <Image
                      source={ArrowIcon}
                      style={[
                        {
                          width: !isMobile ? 18 : 16,
                          height: !isMobile ? 18 : 16,
                        },
                      ]}
                    ></Image>
                  </View>
                ) : null}
                {Store.getState().dashboard &&
                Store.getState().dashboard.viewModeUserId &&
                Store.getState().dashboard.viewModeUserId.user_id ? (
                  rebalancing != null &&
                  rebalancing.contract_note_due != null &&
                  rebalancing.contract_note_due.ccn_fail != null &&
                  rebalancing.contract_note_due.ccn_fail > 0 ? (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 8,
                      }}
                    >
                      <View style={[styles.point]}>
                        <BulletPoint
                          outerColor="#888C9F60"
                          innerColor="#888C9F"
                          style={[styles.bulletPoint]}
                        />
                        <Text style={styles.pointText}>
                          Contract note failed:{" "}
                          {rebalancing.contract_note_due.ccn_fail}
                        </Text>
                      </View>
                      <Image
                        source={ArrowIcon}
                        style={[
                          {
                            width: !isMobile ? 18 : 16,
                            height: !isMobile ? 18 : 16,
                          },
                        ]}
                      ></Image>
                    </View>
                  ) : null
                ) : null}
              </TouchableOpacity>
            </>
          </View>
          <TouchableOpacity
            style={[styles.containerStrip, styles.subContainer]}
            onPress={handleCompliance}
          >
            <>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>Compliance</Text>
                {true &&
                  (compliance == null || isCompliant ? null : (
                    <Image
                      source={ArrowIcon}
                      style={[
                        {
                          width: !isMobile ? 18 : 16,
                          height: !isMobile ? 18 : 16,
                        },
                      ]}
                    ></Image>
                  ))}
              </View>
              {compliance == null || isCompliant ? (
                <View style={styles.pointsContainer}>
                  <View style={styles.point}>
                    <BulletPoint
                      outerColor="#888C9F60"
                      innerColor="#888C9F"
                      style={[styles.bulletPoint]}
                    />
                    <Text style={styles.complianceNullText}>
                      Your account is compliant
                    </Text>
                  </View>
                </View>
              ) : (
                compliance != null &&
                compliance.summary.map((point) => {
                  return (
                    <View style={styles.pointsContainer}>
                      <View style={styles.point}>
                        <BulletPoint
                          outerColor="#888C9F60"
                          innerColor="#888C9F"
                          style={[styles.bulletPoint]}
                        />
                        <Text style={styles.pointText}>{point}</Text>
                      </View>
                    </View>
                  );
                })
              )}
              {compliance != null && !isCompliant && compliance.extra != "" && (
                <Text style={styles.extra}>{compliance.extra}</Text>
              )}
            </>
          </TouchableOpacity>
        </Swiper>
      ) : (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 35,
            marginTop: 10,
          }}
        >
          <ScrollView showsHorizontalScrollIndicator={false} horizontal={true}>
            <TouchableOpacity
              style={[
                styles.containerStrip,
                styles.subContainer,
                { width: width * 0.9 },
              ]}
              onPress={() => {
                amplitude.logEvent("Attention_My_Plans_Clicked");
                navigation.navigate("UpcomingInvestments",{
                  from : "Dashboard"
                });
              }}
            >
              <>
                <View style={styles.titleContainer}>
                  <Text style={styles.title}>My Plans</Text>
                  {true && (
                    <Image
                      source={ArrowIcon}
                      style={[
                        {
                          width: !isMobile ? 18 : 16,
                          height: !isMobile ? 18 : 16,
                        },
                      ]}
                    ></Image>
                  )}
                </View>
                {investment_schedule != null &&
                  investment_schedule.summary != null &&
                  investment_schedule.summary.map((point) => {
                    return (
                      <View style={styles.pointsContainer}>
                        <View style={styles.point}>
                          <BulletPoint
                            outerColor="#888C9F60"
                            innerColor="#888C9F"
                            style={[styles.bulletPoint]}
                          />
                          <Text style={styles.pointText}>{point}</Text>
                        </View>
                      </View>
                    );
                  })}
                {investment_schedule != null &&
                  investment_schedule.next_up != null &&
                  investment_schedule.next_up && (
                    <View style={styles.nextUpContainer}>
                      <BulletPoint
                        outerColor="#888C9F60"
                        innerColor="#888C9F"
                        style={[styles.bulletPoint]}
                      />
                      <View>
                        <Text style={[styles.pointText, styles.nextUpTitle]}>
                          Next Up:
                          <Text style={styles.nextUp}>
                            {" "}
                            {investment_schedule.next_up}
                          </Text>
                        </Text>
                      </View>
                    </View>
                  )}
                {investment_schedule != null &&
                  investment_schedule.extra != "" && (
                    <Text style={styles.extra}>
                      {investment_schedule.extra}
                    </Text>
                  )}
              </>
            </TouchableOpacity>
            <View style={[styles.containerStrip, styles.subContainer]}>
              <>
                <View style={styles.titleContainer}>
                  <Text style={styles.title}>Portfolio Actions</Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("Portfolio", {
                      screen: "RebalanceLandingPage",
                      params: {
                        from: "Dashboard"
                      }
                    });
                  }}
                >
                  {rebalancing != null &&
                    rebalancing.summary != null &&
                    rebalancing.summary.map((point) => {
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <View style={styles.pointsContainer}>
                            <View style={styles.point}>
                              <BulletPoint
                                outerColor="#888C9F60"
                                innerColor="#888C9F"
                                style={[styles.bulletPoint]}
                              />
                              <Text style={styles.pointText}>{point}</Text>
                            </View>
                          </View>
                          <Image
                            source={ArrowIcon}
                            style={[
                              {
                                width: !isMobile ? 18 : 16,
                                height: !isMobile ? 18 : 16,
                              },
                            ]}
                          ></Image>
                        </View>
                      );
                    })}
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  onPress={() => {
                    navigation.navigate("ViewMode", {
                      screen: "ContractNote",
                    });
                  }}
                >
                  {rebalancing != null &&
                  rebalancing.contract_note_due != null &&
                  rebalancing.contract_note_due.ccn_missing != null ? (
                    <View
                      style={[
                        styles.pointsContainer,
                        {
                          flexDirection: "row",
                          justifyContent: "space-between",
                        },
                      ]}
                    >
                      <View style={styles.point}>
                        <BulletPoint
                          outerColor="#888C9F60"
                          innerColor="#888C9F"
                          style={[styles.bulletPoint]}
                        />
                        <Text style={styles.pointText}>
                          Contract note due:{" "}
                          {rebalancing.contract_note_due.ccn_missing}
                        </Text>
                      </View>
                      <Image
                        source={ArrowIcon}
                        style={[
                          {
                            width: !isMobile ? 18 : 16,
                            height: !isMobile ? 18 : 16,
                          },
                        ]}
                      ></Image>
                    </View>
                  ) : null}
                  {Store.getState().dashboard &&
                  Store.getState().dashboard.viewModeUserId &&
                  Store.getState().dashboard.viewModeUserId.user_id ? (
                    rebalancing != null &&
                    rebalancing.contract_note_due != null &&
                    rebalancing.contract_note_due.ccn_fail != null &&
                    rebalancing.contract_note_due.ccn_fail > 0 ? (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 8,
                        }}
                      >
                        <View style={[styles.point]}>
                          <BulletPoint
                            outerColor="#888C9F60"
                            innerColor="#888C9F"
                            style={[styles.bulletPoint]}
                          />
                          <Text style={styles.pointText}>
                            Contract note failed:{" "}
                            {rebalancing.contract_note_due.ccn_fail}
                          </Text>
                        </View>
                        <Image
                          source={ArrowIcon}
                          style={[
                            {
                              width: !isMobile ? 18 : 16,
                              height: !isMobile ? 18 : 16,
                            },
                          ]}
                        ></Image>
                      </View>
                    ) : null
                  ) : null}
                </TouchableOpacity>
              </>
            </View>
            <TouchableOpacity
              style={[
                styles.containerStrip,
                styles.subContainer,
                { width: width * 0.9 },
              ]}
              onPress={handleCompliance}
            >
              <>
                <View style={styles.titleContainer}>
                  <Text style={styles.title}>Compliance</Text>
                  {true &&
                    (compliance == null || isCompliant ? null : (
                      <Image
                        source={ArrowIcon}
                        style={[
                          {
                            width: !isMobile ? 18 : 16,
                            height: !isMobile ? 18 : 16,
                          },
                        ]}
                      ></Image>
                    ))}
                </View>
                {compliance == null || isCompliant ? (
                  <View style={styles.pointsContainer}>
                    <View style={styles.point}>
                      <BulletPoint
                        outerColor="#888C9F60"
                        innerColor="#888C9F"
                        style={[styles.bulletPoint]}
                      />
                      <Text style={styles.complianceNullText}>
                        Your account is compliant
                      </Text>
                    </View>
                  </View>
                ) : (
                  compliance != null &&
                  compliance.summary.map((point) => {
                    return (
                      <View style={styles.pointsContainer}>
                        <View style={styles.point}>
                          <BulletPoint
                            outerColor="#888C9F60"
                            innerColor="#888C9F"
                            style={[styles.bulletPoint]}
                          />
                          <Text style={styles.pointText}>{point}</Text>
                        </View>
                      </View>
                    );
                  })
                )}
                {compliance != null &&
                  !isCompliant &&
                  compliance.extra != "" && (
                    <Text style={styles.extra}>{compliance.extra}</Text>
                  )}
              </>
            </TouchableOpacity>
          </ScrollView>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    paddingLeft: 0,
  },
  containerStrip: {
    width: width < 768 ? "100%" : "30%",
    borderWidth: 1,
    borderRadius: 15,
    borderColor: "rgba(65, 13, 170, 0.2)",
    backgroundColor: "#fafafa",
  },
  subContainer: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    height: "100%",
    marginRight: 28,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: isMobile ? 14 : 17,
    color: "#494B69",
    fontWeight: "bold",
    lineHeight: 20,
    marginRight: 10,
    fontFamily: "Roboto",
  },
  pointsContainer: {
    marginTop: 10,
  },
  point: {
    flexDirection: "row",
    alignItems: "center",
  },
  pointText: {
    fontWeight: "bold",
    fontFamily: "Roboto",
    color: "#494B69",
    fontSize: 12,
  },
  complianceNullText: {
    fontWeight: "bold",
    fontFamily: "Roboto",
    color: "green",
    fontSize: 12,
  },
  bulletPoint: {
    marginRight: 7,
  },
  nextUpContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  nextUpTitle: {
    fontStyle: "italic",
  },
  nextUp: {
    fontFamily: "Roboto",
    fontSize: 12,
    color: "#494B69",
    fontWeight: "normal",
    marginTop: 5,
  },
  extra: {
    fontFamily: "Roboto",
    fontSize: 12,
    color: "#494B69",
    fontStyle: "italic",
    marginLeft: 6,
  },
});
