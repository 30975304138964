export const ComplianceGroups = {
    "PanDetails": "Contact Info & KYC",
    "KYCCreate": "Contact Info & KYC",
    "AddAddress": "Contact Info & KYC",
    "ContactInfo": "Contact Info & KYC",
    "NomineeInfo": "Contact Info & KYC",
    "FinancialInfo": "Tax & FATCA",
    "RiskProfile": "Risk Profile",
    "Assets": "Risk Profile",
    "eSign": "Agreement e-Sign",
    "Payment": "Payment",
    "BankAccount": "Bank Account"
}

export const ComplianceButtonGroups = {
    "PanDetails": "Update KYC & Upload Documents",
    "KYCCreate": "Verify KYC Documents",
    "AddAddress": "Update KYC & Upload Documents",
    "ContactInfo": "Update KYC & Upload Documents",
    "NomineeInfo": "Update KYC & Upload Documents",
    "FinancialInfo": "Tax & FATCA",
    "RiskProfile": "Complete Risk Profile",
    "Assets": "Complete Risk Profile",
    "eSign": "e-Sign agreement",
    "Payment": "Pay Advisory Fees",
    "BankAccount": "Upload bank proof"
}