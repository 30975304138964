import { StackActions } from "@react-navigation/routers";
import React, { useState } from "react";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import SmallcaseGateway from "react-native-smallcase-gateway";
import {
  getGatewaySdkToken,
  handleGatewayError,
  handleGatewaySuccess,
  handleGatewayImportHoldingsWithWebhook,
} from "../api/gateway";

const Gateway = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [updatingResponse, setUpdatingResponse] = useState(null);

  React.useEffect(() => {
    async function loadGateway() {
      try {
        // get auth token
        const authResponse = await getGatewaySdkToken();
        if (authResponse && authResponse.code == 100) {
          const token = authResponse.result.auth_token;

          //configure gateway
          await SmallcaseGateway.setConfigEnvironment({
            isLeprechaun: false,
            isAmoEnabled: false,
            gatewayName: "jamawealth",
            environmentName: SmallcaseGateway.ENV.PROD,
            brokerList: [],
          });

          //initialize gateway
          await SmallcaseGateway.init(token);

          const { route } = props;
          const { params } = route;

          if (params) {
            const { type } = params;
            switch (type) {
              case "PLACE_ORDER":
                handlePlaceOrder(params.data);
                break;
              case "PLACE_ORDER_TOPUP":
                handlePlaceOrder(params.data);
                break;
              case "PLACE_REBALANCE_ORDER":
                handlePlaceOrder(params.data);
                break;
              case "IMPORT_HOLDINGS":
                handleImportHoldings(params);
                break;
              case "LOGOUT":
                handleGatewayLogout();
                break;
              default:
                setLoading(false);
                handleRedirection(true);
                break;
            }
          }
        } else {
          setLoading(false);
          handleRedirection(true);
        }
      } catch (error) {
        setLoading(false);
        handleRedirection(true);
      }
    }

    loadGateway();
  }, []);

  const handleImportHoldings = async (params) => {
    try {
      if (params) {
        const { data = {}, product_name } = params;
        const { broker, transaction_id: transactionId } = data;
        if (transactionId) {
          SmallcaseGateway.triggerTransaction(transactionId, null, broker)
            .then(async (res) => {
              let payload = {
                transactionId: transactionId,
              };

              setUpdatingResponse("Updating fetched funds data");
              checkForResponse(payload, product_name);
            })
            .catch(async (err) => {
              setLoading(false);
              handleRedirection(true);
              return;
            });
          return;
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
      handleRedirection(true);
    } catch (error) {
      setLoading(false);
      handleRedirection(true);
    }
  };

  const handlePlaceOrder = async (data) => {
    try {
      let errorResponse = {};
      if (data) {
        const { broker = [], transaction_id: transactionId } = data;
        if (transactionId) {
          try {
            const res = await SmallcaseGateway.triggerTransaction(
              transactionId,
              null,
              broker
            );

            const payload = {
              smallcase_suc_response: res.data,
              transactionId: transactionId,
              mobile: true,
            };

            const response = await handleGatewaySuccess(payload);
            if (response && response.code == 100) {
              setLoading(false);
              handleRedirection(false);
            } else {
              setLoading(false);
              handleRedirection(true);
            }
            return;
          } catch (err) {
            if (err && err.userInfo) {
              errorResponse = {
                error: {
                  ...err.userInfo,
                  message: err.message || null,
                },
              };
            } else {
              errorResponse = {
                error: err,
              };
            }

            const payload = {
              smallcase_response: errorResponse,
              transactionId: transactionId,
            };
            const response = await handleGatewayError(payload);
            if (response && response.code == 100) {
              setLoading(false);
              handleRedirection(true);
              return;
            } else {
              setLoading(false);
            }
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
      handleRedirection(true);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRedirection = (error) => {
    const { route } = props;
    const { params } = route;
    let screenToRedirectTo = "InvestmentOverview";
    let optionalParams = {};
    if (params) {
      const { type, screen = "InvestmentOverview", ...otherParams } = params;
      if (type == "PLACE_ORDER_TOPUP") {
        optionalParams = {};
        screenToRedirectTo = !error ? screen : "InvestmentOverview";
      } else if (type == "PLACE_ORDER") {
        optionalParams = {};
        screenToRedirectTo = !error ? screen : "InvestmentOverview";
      } else if (type == "PLACE_REBALANCE_ORDER") {
        optionalParams = {};
        screenToRedirectTo = !error ? screen : "InvestmentOverview";
      } else if (type == "IMPORT_HOLDINGS") {
        screenToRedirectTo = "CurrentHoldings";
      }
      optionalParams = { ...optionalParams, ...otherParams };
    }

    props.navigation.dispatch(
      StackActions.replace("App", {
        screen: "Dashboard",
        params: {
          screen: error ? "Dashboard" : "Portfolio",
          params: {
            screen: screenToRedirectTo,
            params: { ...optionalParams },
          },
        },
      })
    );
  };

  const handleGatewayLogout = async () => {
    try {
      await SmallcaseGateway.logoutUser();
      setLoading(false);
      setSuccess("Success !");
    } catch (error) {
      setLoading(false);
      setError("Gateway Error!");
    }
  };

  // check for response for every 30 seconds for 3 mins
  let loopFunction;
  let stopFunction;
  const checkForResponse = async (payload) => {
    try {
      const response = await getResponseFromWebhook(payload);
      if (!response) {
        loopFunction = setInterval(async () => {
          await getResponseFromWebhook(payload);
        }, 15 * 1000);

        // cancel after 2 min
        stopFunction = setTimeout(myStopFunction, 2 * 60 * 1000);

        // clears the loop
        function myStopFunction() {
          clearInterval(loopFunction);
          clearTimeout(stopFunction);
          handleRedirection(true);
        }
      }
    } catch (error) {
      handleRedirection(true);
    }
  };

  const getResponseFromWebhook = async (payload) => {
    try {
      const response = await handleGatewayImportHoldingsWithWebhook(payload);
      if (response && response.code == 100) {
        setLoading(false);
        setUpdatingResponse(false);
        handleRedirection(false);
        clearInterval(loopFunction);
        clearTimeout(stopFunction);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const handleClose = () => {
    props.navigation.dispatch(
      StackActions.replace("App", {
        screen: "Dashboard",
        params: { screen: "Dashboard" },
      })
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      {loading && (
        <View style={styles.loader}>
          <View>
            {updatingResponse ? (
              <Text>
                Processing transaction. Please do not close or press back.
              </Text>
            ) : loading ? (
              <Text>Loading gateway. Please do not close or press back.</Text>
            ) : null}
          </View>
          <ActivityIndicator size={"small"} color={"#410DAA"} />
        </View>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  loader: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
export default Gateway;
