import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { getUserDetails, isMobile } from "../../utils";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import Modal from "../../components/Common/Modal";
import { ComplianceGroups } from "./data";
import { StackActions } from "@react-navigation/native";

import moment from "moment";
import Store from "../../store";

export default class ComplianceModal extends Component {
  constructor(props) {
    super(props);

    const { bannerData, customer_id } = getUserDetails();
    let isCompliant = false;
    let user_id = null;
    let { params } = this.props.route;
    let from = null;
    // console.log("hhh", this.props)
    
    if(params){
      from = params.from || null;
    }
    if(this.props.from){
      from = this.props.from
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    } else {
      user_id = customer_id;
    }
    if (bannerData.name == "Sensage" && user_id > 1 && user_id < 140) {
      isCompliant = true;
    }

    this.state = {
      isLoading: this.props.isLoading,
      pendingItems: {},
      isCompliant,
      loaded: false,
      from
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.loaded == true) {
      return false;
    }
    return true;
  }

  async componentDidMount() {
    const { forceLoad } = this.props;
    if (forceLoad) {
      await this.getData();
    }
  }

  getData = async () => {
    try {
      const { minDetailsResponse } = this.props;
      let { needCompletion = "DONT", error } = minDetailsResponse;
      let pendingItems = { "Contact Info & KYC": [] };
      if (!error && needCompletion) {
        const { results } = minDetailsResponse;
        results.forEach((item) => {
          const { redirect_screen, is_completed } = item;
          const title = ComplianceGroups[redirect_screen] || "";
          if (title !== "") {
            if (title in pendingItems) {
              if (Array.isArray(pendingItems[title])) {
                pendingItems[title].push(is_completed);
              } else {
                pendingItems[title] = is_completed;
              }
            } else {
              pendingItems[title] = is_completed;
            }
          }
        });
        needCompletion = "SHOW";
      }
      if (pendingItems["Contact Info & KYC"].includes(false)) {
        pendingItems["Contact Info & KYC"] = false;
      } else {
        pendingItems["Contact Info & KYC"] = true;
      }
      this.setState({
        loaded: true,
        isLoading: false,
        minDetailsResponse,
        error,
        needCompletion,
        pendingItems,
      });
    } catch (error) {
      this.setState({ error: true, loaded: true, isLoading: false });
    }
  };

  async componentDidUpdate() {
    if (!this.props.isLoading && !this.state.loaded) {
      await this.getData();
    }
  }

  moveToNextScreen = async () => {
    try {
      const { minDetailsResponse } = this.state;
      const { needCompletion, error } = minDetailsResponse;
      if (!error) {
        const { redirect_link, redirect_screen } = minDetailsResponse;
        if (needCompletion) {
          let options = {};
          const { params, navigationOptions = {} } = this.props;
          if (params) {
            options = { ...options, ...params };
          }
          options = { ...options, ...navigationOptions };
          if (this.props.userId) {
            options["user_id"] = this.props.userId;
          }
          delete options["transaction"];
          const key = moment().unix();
          Store.dispatch({
            type: "ADD_ACTION",
            payload: {
              key: key,
              goal_id:this.props.route.params.goal_id,
              data: {
                ...options,
              },
            },
          });

          this.props.navigation.dispatch(
            StackActions.replace("Onboarding", {
              screen: redirect_screen,
              params: {
                from: this.props.route.params.from,
                nvg: this.props.redirectBackTo || "InvestmentOverview",
                action: true,
                goal_id:this.props.route.params.goal_id,
                first: true,
                actionId: key,
                ...options,
              },
            })
          );
        }
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Something went wrong! Please contact help@maxiom.in",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  goToDashboard = () => {
    if (this.props.onClose) {
      this.props.onClose();
    } else {
      const redirect_screen = "InvestmentOverview";
      this.props.navigation.dispatch(StackActions.replace(redirect_screen));
    }
  };

  render() {
    const { pendingItems, needCompletion, isLoading, isCompliant } = this.state;
    if (isLoading || needCompletion == false || isCompliant) return null;
    return (
      <Modal style={[styles.modalWeb]} containerStyle={styles.containerStyle}>
        <View style={[styles.mainContaner]}>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.wel}>Action Required for Compliance</Text>
            <TouchableOpacity onPress={() => {
              // console.log("k",this.state.from)
              if(this.state.from && this.state.from == "Dashboard"){
                this.props.navigation.dispatch(StackActions.replace("App", {
                  screen: "Dashboard"
                }))
              }
              if(this.state.from && this.state.from == "Plans"){
                this.props.navigation.dispatch(StackActions.replace("App", {
                  screen: "Plans"
                }))
              }
             this.props.navigation.goBack();
            }}>
              <AntDesign name="close" size={24} color="rgba(0, 0, 0, 0.5)" />
            </TouchableOpacity>
          </View>
          <Text style={styles.comp}>
            SEBI regulation mandates the following to be in Place
          </Text>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {Object.keys(pendingItems).map((title, index) => {
              const is_completed = pendingItems[title];
              const firstItem = index == 0;
              if (!is_completed) {
                return (
                  <Text style={[styles.kyc, styles.padding]} key={index}>
                    {title}
                    <Ionicons
                      name="alert-circle"
                      size={isMobile ? 8 : 14}
                      color="#F64E60"
                      style={{ paddingLeft: 3 }}
                    />
                  </Text>
                );
              }
              return (
                <Text
                  style={[
                    styles.item,
                    styles.completed,
                    styles.padding,
                    firstItem && { paddingLeft: 0 },
                  ]}
                  key={index}
                >
                  {title}
                  <Ionicons
                    name="checkmark-circle-sharp"
                    size={isMobile ? 8 : 10}
                    color="#009105"
                    style={{ paddingLeft: 3 }}
                  />
                </Text>
              );
            })}
          </View>
          <TouchableOpacity
            style={{ alignItems: "center", justifyContent: "center" }}
            onPress={this.moveToNextScreen}
          >
            <Text style={styles.comp_now}>
              {!this.props.userId ? "Continue to Comply" : "View Mode"}
            </Text>
          </TouchableOpacity>
          <Text style={styles.take}>Takes only a few minutes</Text>
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  mainContaner: {
    paddingTop: isMobile ? 16 : 37,
    paddingBottom: isMobile ? 17 : 23,
    paddingHorizontal: isMobile ? 5 : 33,
    minWidth: isMobile ? 200 : 633,
    marginHorizontal: isMobile ? 16 : 0,
  },
  containerStyle: {
    marginHorizontal: isMobile ? 16 : 0,
    borderRadius: 5,
  },
  comp_now: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isMobile ? 13 : 15,
    lineHeight: 17,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 84,
    backgroundColor: "#410DAA",
    borderRadius: 4,
    marginTop: 58,
  },
  kyc: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 11 : 13,
    color: "#F64E60",
    paddingHorizontal: 5,
  },
  take: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 12 : 15,
    lineHeight: 17,
    color: "rgba(18, 18, 18, 0.6)",
    textAlign: "center",
    paddingTop: 10,
  },
  modalMobile: {
    position: "absolute",
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  modalWeb: {
    position: "absolute",
    paddingTop: 150,
    justifyContent: "center",
    alignItems: "center",
  },
  wel: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 21,
    color: "#F64E60",
  },
  comp: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 21,
    color: "#121212",
    paddingTop: 12,
    paddingBottom: 31,
  },
  item: {
    paddingHorizontal: 5,
    fontSize: isMobile ? 11 : 13,
  },
  completed: { color: "#009105" },
  padding: {
    paddingVertical: isMobile ? 5 : 0,
  },
});
