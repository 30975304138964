import React, { Component } from "react";
import { TaggedFundStrip } from "../components/taggedFundStrip";

export default class GoalTagMutualFunds extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate() {
    return true;
  }

  render() {
    const {
      data,
      index,
      activeFundId,
      onDetachFund,
      onEditFund,
      onCancelPress,
      SlideInLeft,
      slided,
      isLoading,
      action
    } = this.props;
    return (
      <TaggedFundStrip
        title={data.name}
        sideValue={data.value}
        action={action}
        //TODO need to change this
        fundType={data.order_type}
        fundId={data.id}
        style={{
          paddingLeft: 16,
          paddingVertical: 10
        }}
        rightContentStyle={{
          paddingRight: 16
        }}
        onPressDetach={() => {
          onDetachFund();
        }}
        id={data.name + index}
        activeId={activeFundId}
        onEditPress={() => {
          onEditFund(data.name + index);
        }}
        onCancelPress={() => {
          onCancelPress();
        }}
        detachStyle={{
          marginTop: 10
        }}
        SlideInLeft={SlideInLeft}
        slided={slided}
        isLoading={isLoading}
      />
    );
  }
}
