import React from "react";
import { SafeAreaView } from "react-native";
import NavigationHeader from "../../../components/Common/NavigationHeader";
const WebView = require("react-native-webview").WebView;

class goalsWebView extends React.Component {
  render() {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "#fff",
        }}
      >
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={[""]}
        />
        <WebView
          originWhitelist={["*"]}
          source={{ uri: this.props.route.params.url }}
        />
      </SafeAreaView>
    );
  }
}

export default goalsWebView;
