import React, { Component, useState, useEffect } from "react";
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  Platform,
  Dimensions,
} from "react-native";
import { Button, Text } from "react-native-paper";
import MaterialIcon from "@expo/vector-icons/MaterialIcons";
import AntIcon from "@expo/vector-icons/AntDesign";
import Entypo from "@expo/vector-icons/Entypo";
import { connect } from "react-redux";
import moment from "moment";
import TextInputField from "../../../components/Common/Inputs/InputField";
import { Store as store } from "../../../store";
import { validateInput } from "../../../utils/validateInput";
import { checkOrFetchPAN, checkPANKYCStatus } from "../../../api/account";
import amplitude from "../../../utils/amplitude";
import {
  DataURIToBlob,
  getUserDetails,
  isMobile,
  redirectToPendingScreen,
} from "../../../utils";
import { DatePicker } from "../../../components/Common/DatePicker";
import CustomModal from "../../../components/Common/Modals/CustomModal";
import { addPanNumber, getCustomerDetails } from "../../../api";
import { useFilePicker } from "use-file-picker";
const { width } = Dimensions.get("window");

const validation_regex = {
  email:
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  text: /([^\s])/,
  phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  number: /^\d+\.\d+$/,
  pan: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  pincode: /^\d{6}$/,
  ifsc: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
  bankaccount: /^\d{9,18}$/,
};

export const validatePan = (value) => {
  return RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/).test(value);
};

const PAN_INFO_DATA = {
  findPAN: "where to find pan",
  whyPAN:
    "As per SEBI regulations, PAN is mandatory to invest in any Mutual Funds.",
};

const SelectChequeLeaf = ({ onSelect, panImage, viewSampleImage }) => {
  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: "DataURL",
    accept: [".png", ".jpg", ".jpeg", ".pdf"],
    multiple: true,
    limitFilesConfig: { max: 1 },
    maxFileSize: 0.5,
  });

  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    onSelect(filesContent, errors);
  }, [filesContent]);

  if (loading) {
    return <Text>Loading</Text>;
  }

  const isFileSelected = () => {
    return filesContent.length != 0;
  };

  const checkSizeError = () => {
    if (errors && errors.length) {
      const error = errors[0];
      const { fileSizeToolarge } = error;
      return {
        fileSizeToolarge: true,
        error: true,
      };
    }
    return null;
  };

  const selectedFile = isFileSelected();
  const errorData = checkSizeError();
  return (
    <View
      style={{
        flexDirection: "column",
        paddingTop: 31.2,
        marginHorizontal: 16,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text style={styles.res}>
          {panImage != null ? "Update" : "Upload"} Your PAN Photocopy*
        </Text>
        <TouchableOpacity
          onPress={() => {
            viewSampleImage(true);
          }}
        >
          <Text style={styles.view_sample}>View Sample Image</Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        onPress={() => openFilePicker()}
        style={{
          alignItems: "center",
          paddingVertical: 28,
          backgroundColor: "#f6f6f6",
          borderRadius: 4,
          borderStyle: "dashed",
          borderWidth: 1,
          borderColor: "rgba(65, 13, 170, 0.5)",
        }}
      >
        <Text style={styles.drop}>
          <Entypo name="attachment" size={20} color="#13C37B" /> Click here to
          browse and attach files or replace files
        </Text>
      </TouchableOpacity>
      {selectedFile ? (
        filesContent.map((file, index) => (
          <Text style={styles.fileName}>{file.name}</Text>
        ))
      ) : (
        <Text style={styles.fileName}></Text>
      )}
      <Text
        style={[
          styles.support,
          errorData &&
            errorData.error &&
            !errorData.fileSizeToolarge &&
            styles.error,
        ]}
      >
        Supports PNG, JPG, JPEG, PDF.{" "}
        <Text style={[errorData && errorData.fileSizeToolarge && styles.error]}>
          Max File Size 500KB
        </Text>
      </Text>
    </View>
  );
};

class AddPANCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      pan: "",
      kyc_status: "",
      showModal: false,
      selected: "findPAN",
    };
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("AddPanCard",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("AddPanCard", { path: this.props.navigation.getState().routeName });
      }
      this.setState({ isLoadingData: true });
      const { customer_id } = getUserDetails();
      const userIdToFetchDetails = this.state.user_id || customer_id;
      const response = await getCustomerDetails(userIdToFetchDetails);
      if (response && response.pk != null) {
        let { pan_number, date_of_birth, first_name, pan_image } = response;
        let formattedDOB = null;
        if (date_of_birth) {
          formattedDOB = date_of_birth.split("-");
          formattedDOB =
            formattedDOB[2] + "-" + formattedDOB[1] + "-" + formattedDOB[0];
        }
        this.setState({
          pan: pan_number,
          dob: formattedDOB,
          name: first_name,
          name_as_in_pan: first_name,
          formatted_dob: formattedDOB,
          pan_image: pan_image || null,
        });
      }
      this.setState({ isLoadingData: false });
    } catch (error) {
      this.setState({
        isLoadingData: true,
      });
    }
  };

  handlePAN = (pan) => {
    const panError = validatePan(pan);
    this.setState({
      pan: panError ? pan.toUpperCase() : pan,
      panError,
    });
  };

  onSubmitEnd = () => {
    this.setState({ pan: this.state.pan.toUpperCase() });
  };

  formattedNumber = (number) => ("0" + number).slice(-2);

  _handleDatePicked = (date) => {
    const dob =
      this.formattedNumber(date.getDate()) +
      "/" +
      this.formattedNumber(parseInt(date.getMonth()) + 1) +
      "/" +
      date.getFullYear();
    const formatted_dob = moment(date).format("YYYY-MM-DD");
    this.setState({
      dob,
      formatted_dob,
    });

    this._hideDateTimePicker();
  };

  _hideDateTimePicker = () => this.setState({ showDate: false });

  addPAN = async () => {
    try {
      const { pan, dob, formatted_dob, name, name_as_in_pan, isLoading } =
        this.state;
      const { customer_id, username } = getUserDetails();
      if (!this.state.pan_image && !this.state.imageURI) {
        this.setState({
          panImageError: "Please select PAN copy",
          isLoading: false,
        });
        return;
      }
      if (!this.validate() || isLoading) return;

      let dob_values = formatted_dob.split("-");

      let body = new FormData();
      body.append("username", username);
      body.append("pan_number", pan);
      body.append(
        "date_of_birth",
        dob_values[2] + "-" + dob_values[1] + "-" + dob_values[0]
      );
      body.append("first_name", name);
      body.append("name_as_in_pan", name_as_in_pan);

      if (this.state.imageURI) {
        const imageFile = DataURIToBlob(this.state.imageURI);
        body.append("pan_image", imageFile, this.state.imageName);
      }

      const payload = {
        username,
        pan_number: pan,
        date_of_birth:
          dob_values[2] + "-" + dob_values[1] + "-" + dob_values[0],
        first_name: name,
        name_as_in_pan: name_as_in_pan,
      };

      this.setState({ isLoading: true });
      const response = await addPanNumber(customer_id, body);
      if (response && response.hasOwnProperty("pk")) {
        store.dispatch({
          type: "SAVE_PROFILE_DATA",
          payload: response,
        });
        await this.verifyPanStatus();
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  async verifyPanStatus() {
    try {
      const payload = {
        pan_number: this.state.pan,
      };
      const response = await checkPANKYCStatus(payload);
      if (response && response.code == 100) {
        const { kyc_status = null } = response;
        if (
          kyc_status === "REG" ||
          kyc_status === "UND" ||
          kyc_status.startsWith("EK")
        ) {
          const { pan, name, dob, formatted_dob } = this.state;
          let dob_values = formatted_dob.split("-");
          const payload = {
            pan_number: pan,
            dob: dob_values[0] + "-" + dob_values[1] + "-" + dob_values[2],
            name_as_in_pan: name,
          };
          const fetchResponse = await checkOrFetchPAN(payload);
          if (fetchResponse && fetchResponse.code == 100) {
            const {
              results: [],
            } = fetchResponse;
          }
        }
      }
      if (this.props.minDetailsFlow) {
        const redirect = await redirectToPendingScreen(this.props);
        if (redirect && redirect.error) {
        }
      }
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  validate = () => {
    const { dob, name, pan } = this.state;
    const panRegEx = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    const panError = RegExp(panRegEx).test(pan);
    return panError && dob && name;
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  handleChange = (value, type, inputType) => {
    const error = validateInput(inputType, value);
    this.setState({
      [type]: value,
      [type + "Error"]: error,
    });
    return error;
  };

  render() {
    const {
      pan,
      panError,
      dob,
      nameError,
      name,
      name_as_in_panError,
      name_as_in_pan,
    } = this.state;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView
          style={{ height: "50%" }}
          showsVerticalScrollIndicator={true}
          contentContainerStyle={{
            paddingBottom: 100,
          }}
        >
          <View style={[styles.formItem, styles.itemMargin]}>
            <TextInputField
              label="Name"
              value={this.state.name}
              onChangeText={(name) => this.handleChange(name, "name", "text")}
              style={[{ marginHorizontal: 16, ...styles.inputItem }]}
              editable={true}
            />
          </View>

          <View style={[{ marginHorizontal: 16 }, styles.formItem]}>
            <TextInputField
              label="PAN Number"
              value={pan}
              onChangeText={this.handlePAN}
              style={[{ ...styles.inputItem }]}
              editable={true}
              onSubmitEnd={this.onSubmitEnd}
              type="pan"
            />
            {!panError && (
              <>
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                  onPress={() => {
                    this.setState({
                      selected: "whyPAN",
                      showModal: true,
                    });
                  }}
                >
                  <MaterialIcon
                    name={"info-outline"}
                    style={{
                      fontSize: 16,
                      color: "#707070",
                    }}
                  />
                  <Text
                    style={{
                      fontStyle: "italic",
                      fontSize: 12,
                      color: "#707070",
                      paddingTop: 0,
                      paddingLeft: 5,
                    }}
                  >
                    Why PAN is required?
                  </Text>
                </TouchableOpacity>
              </>
            )}
            <View style={[styles.formItem, styles.itemMargin]}>
              <TextInputField
                label="Name (as on PAN card)"
                value={name_as_in_pan}
                style={[{ ...styles.inputItem }]}
                onChangeText={(name) =>
                  this.handleChange(name, "name_as_in_pan", "text")
                }
                type="text"
                editable={true}
                validate={true}
              />
            </View>

            <Text
              style={{
                fontSize: 12,
                color: "#BCBCBC",
                marginVertical: 20,
              }}
            >
              Date Of Birth
            </Text>
            <TouchableOpacity
              onPress={() => {
                this.setState({
                  showDate: true,
                });
              }}
              style={{
                justifyContent: "center",
                width: isMobile ? "100%" : 300,
                borderBottomWidth: 1,
                borderBottomColor: "#ddd",
              }}
            >
              <>
                {dob ? (
                  <Text
                    style={{
                      color: "#121212",
                      width: "100%",
                      marginBottom: 4,
                      fontSize: 14,
                    }}
                  >
                    {dob}
                  </Text>
                ) : (
                  <Text
                    style={{
                      color: "#121212",
                      width: "100%",
                      marginBottom: 4,
                      color: "#707070",
                      fontSize: 14,
                    }}
                  >
                    DD/MM/YYYY
                  </Text>
                )}

                <AntIcon
                  name={"calendar"}
                  style={{
                    fontSize: 16,
                    color: "#707070",
                    position: "absolute",
                    right: 5,
                    bottom: 10,
                  }}
                  onPress={() => {}}
                />
              </>
            </TouchableOpacity>
            {this.state.showDate && (
              <DatePicker
                isActive={this.state.showDate}
                inititalDate={new Date(1980, 1, 1)}
                setPlannedDate={(date) => {
                  this._handleDatePicked(date);
                }}
                closedDatePicker={this._hideDateTimePicker}
                validRange={{
                  startDate: new Date(1920, 1, 1),
                  endDate: new Date(),
                }}
                label={"Select a date"}
              />
            )}
          </View>
          <SelectChequeLeaf
            onSelect={(filesContent, errors) => {
              if (filesContent && filesContent.length > 0) {
                const { content: imageURI, name: imageName } = filesContent[0];
                this.setState({
                  imageURI,
                  imageName,
                });
              }
            }}
            panImage={this.state.pan_image}
            viewSampleImage={() => {
              this.setState({ viewSampleImage: true });
            }}
          />
          <Text
            style={[styles.please1, this.state.panImageError && styles.error]}
          >
            {this.state.pan_image ? " " : this.state.panImageError}
          </Text>
        </ScrollView>

        {!this.state.showekyc ? (
          <Button
            uppercase={false}
            mode={"contained"}
            onPress={this.addPAN}
            style={{
              position: "absolute",
              left: 16,
              bottom: 10,
              width: isMobile ? "100%" : 300,
            }}
            loading={this.state.isLoading}
            color={"#410DAA"}
          >
            <Text style={styles.next}>Save</Text>
          </Button>
        ) : (
          <Button
            uppercase={false}
            mode={"contained"}
            onPress={() => {
              this.props.navigation.navigate("EKYC");
            }}
            style={{
              position: "absolute",
              left: 16,
              width: isMobile ? "100%" : 300,
            }}
            loading={this.state.isLoading}
            color={"#410DAA"}
          >
            <Text style={styles.next}>Start E-Kyc</Text>
          </Button>
        )}

        <CustomModal
          visible={this.state.showModal}
          showCloseButton={true}
          closeModal={this.toggleModal}
        >
          <View style={styles.modalContentContainer}>
            <Text>{PAN_INFO_DATA[this.state.selected]}</Text>
          </View>
        </CustomModal>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    padding: 16,
  },
  modalContentContainer: {
    backgroundColor: "white",
    padding: 16,
    borderRadius: 4,
    width: "90%",
  },
  page: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#fff",
  },
  formItem: {},
  inputItem: {
    width: isMobile ? "100%" : "auto",
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  nextButton: {
    width: width < 768 ? "96%" : "15%",
    maxWidth: 400,
    marginBottom: 15,
    paddingHorizontal: 16,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  res: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: !isMobile ? 18 : 16,
    color: "#20202050",
    paddingBottom: 13.29,
  },
  view_sample: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#8950FC",
    paddingBottom: !isMobile ? 5 : 10,
  },
  fileName: {
    fontSize: 15,
    marginTop: 5,
    color: "#410DAA",
    fontWeight: "bold",
  },
  support: {
    fontSize: 10,
    color: "#121212",
    fontStyle: "italic",
    marginTop: 4,
  },
  error: { color: "red" },
});

const mapStateToProps = null;

export default connect(mapStateToProps)(AddPANCard);
