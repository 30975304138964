import { Platform } from "react-native";
import { GATEWAY_ENVIRONMENT } from "./apiConfig";
import { MOBILE_API_URL, MOBILE_GATEWAY_URL } from "./env";

export const PRODUCTION_APP_HOSTNAME = "app.jamawealth.com";
export const DEVELOPMENT_APP_HOSTNAME = "dapp.jamawealth.com";

// const DOCKER_URL = "https://docker.jama.co.in";

const DOCKER_URL = "https://docker.maxiom.in";
const DOCKER2_URL = "https://docker2.maxiom.in";
// const DOCKER_URL = DOCKER2_URL;
const API_PRODUCTION_URL = "https://maxiom.in";
const GATEWAY_PRODUCTION_URL = "https://maxiom.in";
const REGULAR_PRODUCTION_URL = "https://regular.maxiom.in";
const PRANA_PRODUCTION_URL = "https://pranaai.jama.co.in";
const DOCKER_MAXIOM_URL = "https://docker.maxiom.in";
const PRODUCTION_MAXIOM_URL = "https://maxiom.in";
const REGULAR_MAXIOM_URL = "https://regular.maxiom.in";
// const DOCKER_REGULAR_MAXIOM_URL = "https://regular.maxiom.in";
const DOCKER_REGULAR_MAXIOM_URL = "https://regular.docker.jama.co.in";
const DOCKER2_MAXION_URL = "https://docker2.maxiom.in";

const getMainURL = () => {
  const hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
  // const hostname = "sensage.simplygrow.in.com";
  let url;
  switch (hostname) {
    case "localhost":
      url = DOCKER_URL;
      break;
    case "dapp.jama.co.in":
    case "www.jama.co.in":
    case "dapp.jamawealth.com":
    case "cpartner.jamawealth.com":
    case "dmyadvisor.jamawealth.com":
      url = DOCKER_URL;
      break;
    case "test.simplygrow.in":
    case "demo.simplygrow.in":
      url = DOCKER2_URL;
      break;
    case "app.jama.co.in":
    case "app.jamawealth.com":
    case "www.app.jama.com":
    case "www.app.jamawealth.co.in":
    case "myadvisor.jamawealth.com":
      url = GATEWAY_PRODUCTION_URL;
      break;
    case "club.jamawealth.com":
      url = REGULAR_PRODUCTION_URL;
      break;
    case "dclub.jamawealth.com":
      url = DOCKER_REGULAR_MAXIOM_URL;
      break;

    case "dapp.maxiomwealth.com":
    case "dmyadvisor.maxiomwealth.com":
    case "cpartner.maxiomwealth.com":
      url = DOCKER_MAXIOM_URL;
      break;
    case "app.maxiomwealth.com":
    case "myadvisor.maxiomwealth.com":
      url = PRODUCTION_MAXIOM_URL;
      break;
    case "club.maxiomwealth.com":
      url = REGULAR_MAXIOM_URL;
      break;
    case "dclub.maxiomwealth.com":
      url = DOCKER_REGULAR_MAXIOM_URL;
      break;
    case "demo.maxiomwealth.com":
      url = DOCKER2_MAXION_URL;
      break;

    case "pranaai.jamawealth.com":
      url = PRANA_PRODUCTION_URL;
      break;

    default:
      url = GATEWAY_PRODUCTION_URL;
      break;
  }
  if (hostname.includes("simplygrow.in")) {
    url = "https://" + hostname;
  }
  return url;
};

const getCurrentDomain = () => {
  if (Platform.OS == "web") {
    const hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    return hostname;
  }
};
const getGateway = () => {
  const hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
  // const hostname = "sensage.simplygrow.in.com";
  let gateway;
  switch (hostname) {
    case "localhost":
      gateway = "L";
      break;
    case "dapp.jama.co.in":
      gateway = "DJ";
      break;
    case "dapp.jamawealth.com":
    case "dapp.maxiomwealth.com":
      gateway = "DJW";
      break;
    case "myadvisor.jamawealth.com":
    case "myadvisor.maxiomwealth.com":
      gateway = "ADV";
      break;
    case "dmyadvisor.maxiomwealth.com":
    case "dmyadvisor.jamawealth.com":
      gateway = "DADV";
      break;
    case "app.jama.co.in":
      gateway = "AJ";
      break;
    case "app.jamawealth.com":
    case "app.maxiomwealth.com":
      gateway = "AJW";
      break;
    case "www.app.jama.com":
      gateway = "WAJ";
      break;
    case "www.app.jamawealth.co.in":
      gateway = "";
      break;
    case "club.jamawealth.com":
    case "dclub.jamawealth.com":
    case "club.maxiomwealth.com":
    case "dclub.maxiomwealth.com":
      gateway = "CLB";
      break;
    case "cpartner.jamawealth.com":
    case "cpartner.maxiomwealth.com":
      gateway = "CJW";
      break;
    case "demo.simplygrow.in":
    case "demo.maxiomwealth.com":
      gateway = "DEMO";
      break;
    case "pranaai.jamawealth.com":
      gateway = "PRANA";
      break;
    default:
      gateway = "";
      break;
  }
  if (hostname.includes("simplygrow.in")) {
    let host = hostname.split(".");
    gateway = host[0];
  }
  return gateway;
};

const getAPIMainURL = () => {
  const hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
  // const hostname = "sensage.simplygrow.in.com";
  let url;
  switch (hostname) {
    case "localhost":
      url = DOCKER_URL;
      break;
    case "dapp.jama.com":
    case "www.jama.co.in":
    case "dapp.jamawealth.com":
    case "cpartner.jamawealth.com":
    case "dmyadvisor.jamawealth.com":
      url = DOCKER_URL;
      break;
    case "test.simplygrow.in":
    case "demo.simplygrow.in":
      url = DOCKER2_URL;
      break;
    case "app.jama.co.in":
    case "app.jamawealth.com":
    case "www.app.jama.com":
    case "www.app.jamawealth.co.in":
    case "myadvisor.jamawealth.com":
      url = API_PRODUCTION_URL;
      break;

    case "club.jamawealth.com":
      url = REGULAR_PRODUCTION_URL;
      break;
    case "dclub.jamawealth.com":
      url = DOCKER_REGULAR_MAXIOM_URL;
      break;

    case "dapp.maxiomwealth.com":
    case "dmyadvisor.maxiomwealth.com":
    case "cpartner.maxiomwealth.com":
      url = DOCKER_MAXIOM_URL;
      break;
    case "app.maxiomwealth.com":
    case "myadvisor.maxiomwealth.com":
      url = PRODUCTION_MAXIOM_URL;
      break;
    case "club.maxiomwealth.com":
      url = REGULAR_MAXIOM_URL;
      break;
    case "dclub.maxiomwealth.com":
      url = DOCKER_REGULAR_MAXIOM_URL;
      break;
    case "demo.maxiomwealth.com":
      url = DOCKER2_MAXION_URL;
      break;

    case "pranaai.jamawealth.com":
      url = PRANA_PRODUCTION_URL;
      break;

    default:
      url = API_PRODUCTION_URL;
      break;
  }
  if (hostname.includes("simplygrow.in")) {
    url = "https://" + hostname;
  }
  return url;
};

export const getServerName = (is_digio = false) => {
  if (Platform.OS == "web") {
    const hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    // const hostname = "sensage.simplygrow.in.com";
    let serverName;
    if (is_digio) {
      return hostname;
    }
    switch (hostname) {
      case "localhost":
      case "dapp.jama.com":
      case "www.jama.co.in":
      case "dapp.jamawealth.com":
      case "dmyadvisor.jamawealth.com":
      case "dapp.maxiomwealth.com":
      case "dmyadvisor.maxiomwealth.com":
        serverName = "dapp";
        break;
      case "app.jama.co.in":
      case "app.jamawealth.com":
      case "www.app.jama.com":
      case "www.app.jamawealth.co.in":
      case "myadvisor.jamawealth.com":
      case "app.maxiomwealth.com":
      case "myadvisor.maxiomwealth.com":
        serverName = "app";
        break;
      case "club.jamawealth.com":
      case "dclub.jamawealth.com":
      case "club.maxiomwealth.com":
      case "dclub.maxiomwealth.com":
        serverName = "club";
        break;
      case "pranaai.jamawealth.com":
        serverName = "prana";
        break;
      case "cpartner.jamawealth.com":
      case "cpartner.maxiomwealth.com":
        serverName = "cpartner";
        break;
      case "demo.simplygrow.in":
        serverName = "demo";
        break;
      default:
        serverName = "app";
        break;
    }
    if (hostname.includes("simplygrow.in")) {
      serverName = hostname.replace(".simplygrow.in", "");
    }
    return serverName;
  } else {
    return "app"; // by default production
  }
};

// TODO combine all these functions into a single function - which returns json;

export const MAIN_URL =
  Platform.OS == "web" ? getMainURL() : MOBILE_GATEWAY_URL;
export const GATEWAY = Platform.OS == "web" ? getGateway() : "AJW/";
export const apiMainURL =
  Platform.OS == "web" ? getAPIMainURL() : MOBILE_API_URL;
export const Environement = Platform.OS == "web" ? GATEWAY_ENVIRONMENT : null;
export const CURRENT_DOMAIN = getCurrentDomain();
export const SECRET_KEY_HEX = "fa24bd2573919aff52897715b3e60c74";

const MF_HOME_DASHBOARD_URLS = {
  HOME_SCREEN_DATA: "/app/api/new_home/",
  JAMA_EQUITY_STOCKS: "/app/api/gjefg/",
  OUTSIDE_IMPORTED_FUNDS: "/app/api/goefg",
};

export const URLS = {
  //Jama Investment Overview - Landing page
  TRANSACTION_POP_UP: "/app/api/show_recent_gwsc_txn_popup/",
  CLOSE_TRANSACTION_POP_UP: "/app/api/update_gwsc_txn_popup/",
  LANDING_PAGE: "/app/api/jw_stocks_landing_page/",
  KYC_INSERT: "/app/api/digio/cvlkra_kyc_insert/",
  GENERATE_AGREEMENT_PDF: "/app/api/generate_agreement_pdf/",
  DASHBOARD: "/app/api/jw_stocks_landing_page_v2/",
  GET_CUSTOMER_PORTFOLIO_DATA: "/app/api/get_customer_portfolio_data/",
  UPDATE_CUSTOMER_PORTFOLIO_DATA:
    "/app/api/update_portfolio_values_of_customer/",
  COMPLETE_DASHBOARD: "/app/api/get_customer_portfolio_data/",
  ASSETS_DATA: "/app/api/assets/",
  RENEWAL: "/app/api/get_cips_for_renewal/",
  PLAN_RENEW: "/app/api/check_customer_plan_renewal/",
  ADD_ASSET: "/app/api/create_asset/",
  GET_RECENT_ASSETS: "/app/api/assets/",
  DELETE_ASSET: "/app/api/del_asset",
  ADD_FAMILY_MEMBER: "/app/api/add_family_member/",
  MUTUALFUND: "/app/api/v2/portdashbrd/",
  MFDETAILS: "/app/api/mobile/customer/portfolio/",

  GET_LEADERBOARD_DETAILS: "/app/api/get_leaderboard_details_team_wise/",
  GET_LEADERBOARD_DETAILS_USERS: "/app/api/get_leaderboard_details_user_wise/",

  INSIGHTDETAILS: "/app/api/fetch_custom_reports/",
  UPDATEINSIGHT: "/app/api/create_customerinsight_mapping_data/",
  REDEEM_AMOUNT: "/app/api/redeem_amount_for_fund/",
  USER_FUND_TRANSACTION: "/app/api/investoraccountfunds/",
  AMC_FUND_SCHEMES: "/app/api/amcfunds/",
  GET_FUND_DETAILS: "/app/api/get_amcfund_details/",
  GETALL_MANDATES_FOR_SIP: "/app/api/get_all_mandates_for_sip/",
  PLACE_ORDERS: "/app/api/mobplcordrs/",
  PLACE_ORDERS_V2: "/app/api/goal_based_v2_placeordrs/",
  NET_BANKING_PAYMENT_LINK: "/app/api/funds_transfer_url/",
  MF_RECOMMENDATION_BASED_ON_AMOUNT: "/app/api/product/mf_recommendation/",
  CREATE_BANK_MANDATE: "/app/api/create_mandate/",
  GET_CUSTOMER_BANK_ACCOUNTS: "/app/api/customerbankaccounts/",
  EXPLORE_FUNDS: "/app/api/mobile/explore/",
  INVEST_LANDING_PAGE: "/app/api/mobile/invest/",
  STOCKS_PORTFOLIO_PRODUCTS: "/app/api/advisorproductmaster/",
  VIEW_MONTHLY_REPORT: "/app/api/get_customer_holding_report_s3_link/",
  PERSONALISED_FUNDS: "/app/api/RiskProfileFundsSelection/new/",

  // Investment Flow
  INVESTMENT_TRANSACTION_STATUS: "/app/api/gwsc_get_fi_order_status/",
  UPCOMING_INVESTMENTS: "/app/api/sjw/upcoming_investments/",
  UPCOMING_INVESTMENTS_NEW: "/app/api/upcoming_investments_v2/",
  PLACE_INVESTMENT_ORDER: "/app/gwsc/placeorder/",
  GET_BSE_PAYMENT_LINK: "/app/api/bse_order_payment_link/",
  GET_BSE_PAYMENT_STATUS: "/app/api/get_bse_orders_payment_status/",
  INVESTMENT_HASH_KEY: "/app/api/show_fi_recommedation/",

  //Transaction status
  GET_DEFAULT_TAB_TRANSACTION_STATUS: "/app/api/get_default_stock_order_type/",

  //Rebalance flow
  REBALANCE_STRIPS_DATA: "/app/api/portfolio_rebalance_status/",
  REBALANCE_STATUS: "/app/api/show_rebalance_page_status/",
  CURRENT_HOLDINGS: "/app/api/show_current_stock_allocation/",
  REBALANCE_RECOMMENDATIONS_DAY1:
    "/app/api/show_complete_rebalance_recommedation/",
  REBALANCE_RECOMMENDATIONS_DAY2: "/app/api/show_rd_rebalance_recommedation/",
  REBLANCE_TRANSACTION_STATUS: "/app/api/gwsc_get_cr_order_status/",
  GET_CAIHASH_FETCH_HOLDINGS: "/app/api/get_cai_hash_to_fetch_holdings/",

  //topup
  SHOW_TOPUP_RECOMMENDATION: "/app/api/show_topup_recommedation/",

  RETRY_ORDER: "/app/gwsc/retryorder/",

  //My Equity Investment Plans
  MY_EQUITY_INVESTMENT_PLANS: "/app/api/clientinvestmentplans/",
  CIP_INSTALLMENTS: "/app/api/get_cip_details/", //plan_number
  UPDATE_CIPS_PLANNED_DATE: "/app/api/update_cips_planned_date/", // cips_pk and planned_date
  UPDATE_SIP_DATE_FREQUENCY: "/app/api/update_cips_sip_planned_date/",
  ADD_ANOTHER_INSTALLMENT: "/app/api/add_new_cip_schedule/", //plan_number
  GET_CIP_DETAILS_INVEST_NOW: "/app/api/get_cip_details/invest/",
  START_NEW_PLAN: "/app/api/messagebundles/1141/",

  // Authentication
  LOGIN_SEND_OTP: "/app/api/rest-auth/register/",
  LOGIN: "/app/api/login/",
  SIGNUP: "/app/api/rest-auth/register/",
  ADD_NOTIFICATION_DEVICE: "/app/api/add_notificationdevice/",
  LOGOUT_URL: "/app/gwsc/logout/",
  CHANGE_PASSWORD: "/app/api/set_password/",
  GET_SCREEN_CONFIG: "/app/api/get_screen_config/",

  // Passbook
  PASSBOOK: "/app/api/jw_passbook_page/",
  PASSBOOK_FOR_DATE: "/app/api/jw_passbook_for_a_date/",

  // Hash Key
  GET_HASH_KEY_VALUE: "/app/api/get_hash_key_to_logout_gateway/",

  GET_CUSTOMER_DETAILS: "/app/api/customers/",
  GET_CUSTOMER_DETAILS_V2: "/app/api/v2_customers_data/",
  GET_CUSTOMER_PROFILE_DETAILS: "/app/api/customerprofiles/",

  // Gateway URLs
  FETCH_HOLDINGS: "/app/gwsc/fetch_holdings/", //gwsc/fetch_holdings/caihashtoken/:gateway_environment

  //onboarding URLs
  GET_ONBOARDING_STATUS: "/app/api/jewel/profile/status/",
  ONBOARDING_SEND_OTP: "/app/api/rest-auth/register/",
  GET_LEAD_DATA: "/app/api/get_lead_data_of_customer/",
  STORE_TOKEN: "/app/api/notificationdevice/",
  CUSTOMER_DETAILS: "/app/api/customers/", //append customer_id
  AGREEMENT_E_SIGN: "/app/api/customerinvestmentplans/",
  CLIENT_INVESTMENT_PLANS_ADMIN: "/app/api/show_client_plans/",

  AADHAR_BASED_E_SIGN: "/app/api/check_pan_kyc_status/",
  RISKPROFIE_QUESTIONS: "/app/api/risk_profile_qna/",
  SAVE_RISKPROFILE_ANSWERS: "/app/api/save_risk_profile_ans_new/",
  ASSETS_AND_LIABILITIES: "/app/api/jewel/asset/details/",
  ORDER_SUMMARY: "api/jewel/pending/bills/",
  VERIFY_IFSC: "/app/api/get_bank_details/",
  CUSTOMER_BANK_ACCOUNTS: "/app/api/customerbankaccounts/",
  GET_CUST_MANDATE_AMT: "/app/api/get_cust_mandate_amt/",
  CREATE_CUST_MANDATE: "/app/api/create_customer_fee_mandate/",
  AGGREMENT_E_SIGN: "/app/api/customerinvestmentplans/",
  GET_ORDER_SUMMARY: "/app/api/jewel/pending/bills/",
  CONTACT_US: "/app/api/newsendy/",
  // Agreement e-Sign
  AGREEMENT_ESIGN: "/app/api/generate_digio_agreement/",
  // MAKE_PAYMENT_ORDER_SUMMARY: "/app/api/jgpl/",
  MAKE_PAYMENT_ORDER_SUMMARY: "/app/api/get_cf_payment_link/",

  UPDATE_DIGIO_AGREEMENT: "/app/api/update_digio_agreement/",
  UPDATE_PAYMENT_STATUS: "/app/api/get_cf_payment_status/",

  // DIGIO KYC
  CREATE_KYC_REQUEST: "/app/api/digio/create/request/",
  UPDATE_KYC_REQUEST_STATUS: "/app/api/digio/update/kyc/status/",

  SHOW_FAMILY_POPUP: "/app/api/show_broker_login_pop_up/",

  // Portfolio
  PORTFOLIO_STOCKS: "/app/api/show_jw_portfolio/",
  PORTFOLIO_STOCKS_WITH_DATE: "/app/api/show_equity_portfolio_as_on_date/",
  GET_INVESTOR_ACCOUNT_FUND_DETAILS:
    "/app/api/get_investor_account_fund_details/",
  BSE_PLACE_SWP_ORDER: "/app/api/bse_place_swp_order/",
  BSE_PLACE_STP_ORDER: "/app/api/bse_place_stp_order/",
  PLACE_DIRECT_SWITCH_ORDER: "/app/api/place_direct_switch_order/",

  // Demat account - opening
  DEMAT_ACCOUNT_OPENING: "/app/api/get_hash_key_to_open_demat_with_gateway/",

  // Billing
  PENDING_PAYMENTS: "/app/api/jama_wealth_pending_bills/",
  INVOICES: "/app/api/billing/invoices/",
  INVOICE_DETAILS: "/app/api/billing/invoice/billitems/",
  CBI_BILL_DETAILS: "/app/api/billing/invoice/billdetail/",
  GET_TRANSACTION_DETAILS: "/app/api/billing/plan_investments_on_a_date/",
  GET_INVESTED_AMOUNT_DETAILS: "/app/api/billing/cbi_investment_detail/",
  GET_CURRENT_AUA_DETAILS: "/app/api/billing/current_aua/",
  GET_DIVIDEND_YIELDS: "/app/api/billing/plan_dividend/",
  GET_ADVISORY_FEE_DETAILS: "/app/api/billing/performance_advisory_fee/",
  GET_PRORATED_DETAILS: "/app/api/billing/cbi_investment_detail/",
  GET_AVERAGE_AUA_DETAILS: "/app/api/billing/cbi_aum_bill_detail/",
  GET_HOLDINGS_DETAILS: "/app/api/billing/cbi_holdings_detail/",
  GET_INVOICES_PAYMENT_STATUS: "/app/api/update_invoices_payment_status/",
  GENERATE_PAYMENT_LINK: "/app/api/invoices_make_payment_link/",
  BILLING_LEDGER_PLANS: "/app/api/billing/allplans/",
  BILLING_LEDGER_PLAN_DETAILS: "/app/api/billing/allbillsforplan/",

  //Demat Screens
  CUSTOMER_DEMAT_ACCOUNT: "/app/api/customerdemataccount/",

  OPEN_DEMAT_ACCOUNT: "/app/api/demat_account_opening_page/",

  //Contract Notes
  VIEW_CONTRACTNOTE: "/app/api/client_contract_note_page/",

  VIEW_CONTRACTNOTE_DETAILS: "/app/api/get_list_of_txns_for_a_contract_note/",

  UPLOAD_CONTRACT_NOTE: "/app/api/upload_customer_contract_note/",

  ...MF_HOME_DASHBOARD_URLS,

  RUN_ACTION_ITEMS_TO_PLACE_ORDERS:
    "/app/api/run_action_items_to_place_orders/",

  // Goals
  ADVISORDASHBOARDDATA: "/app/api/advdshbrd/",
  MYGOALS_API: "/app/api/mygoalsapi",
  GOALS_ADD: "/app/api/create_goal/",
  GOALS_GETFOLIOS: "/app/api/gjffg",
  GOALS_GETOUTSIDEFOLIOS: "/app/api/goffg",
  GOALS_GETASSETS: "/app/api/gasfg/",
  ADD_ASSET: "/app/api/create_asset/",
  GOALS_GETSIP: "/app/api/gsifg",
  GOALS_TAGGOAL: "/app/api/map_txn_to_goal",
  GOALS_DELETEGOAL: "/app/api/rmgl/",
  GOAL_SUMMARY: "/app/api/mobile/goalsummary/",
  GET_GOAL_DETAILS: "/app/api/goal_details/",
  ADD_ORDERS_TO_CART_FROM_GOAL: "/app/api/mobile/goals_add_multiple_orders/",

  JAMA_SUGGESTED_AMOUNT: "/app/api/get_jama_suggested_amt/",
  OUTSIDE_STOCK_TAG_UNTAG: "/app/api/map_demat_account_to_goal/",
  JAMA_STOCK_TAG_UNTAG: "/app/api/map_jama_equity_to_goal/",
  SUGGESTED_FUNDS: "/app/api/pick_funds_based_on_rp_for_goal_v2/",

  // Home Dashboard - MF
  HOME_SCREEN_DATA: "/app/api/new_home/",
  JAMA_EQUITY_STOCKS: "/app/api/gjefg/",
  OUTSIDE_IMPORTED_FUNDS: "/app/api/goefg",
  REVISED_GOAL_PROJECTION: "/app/api/calculate_revised_projection_for_goal/",
  SCHEDULE_A_VISIT: "/app/api/schedule_cust_review/",

  // Referrals
  SEND_REFERRAL_INVITE: "/app/api/send_referral_invite/",
  CUSTOMER_REFERRALS: "/app/api/referrals/",
  SEND_REFERRAL_REMINDER: "/app/api/referandearn/",

  //Financial Wellness
  GET_FIN_AWARENESS: "/app/api/faz/",

  //Education Modules and Data
  GET_EDU_MODULES: "/app/api/edu_module/",
  GET_CHAPTERS_MODULE: "/app/api/edu_module_content/",
  TRACK_USER_ACTIVITY_EDU: "/app/api/edutrack/",
  USER_FEEDBACK_EDU: "/app/api/update_user_likes_in_module/",

  //Quiz questions and responses
  QUIZ_QUESTIONS: "/app/api/quizz/",
  QUIZ_DIAGNOSTICS: "/app/api/quiz_diagnostics/",
  SUBMIT_QUIZ_ANSWERS: "/app/api/process_quiz_responses",
  QUIZ_RESULT: "/app/api/quiz_result",

  //EDU MOdule DATA
  EDU_MODULE_DATA: "/app/api/edu_module1/",
  EDU_QUIZ_RESULT: "/app/api/get_education_quiz_response/",

  //Import Mutual Fund Portfolio
  CAMS_PDF_USING_EMAIL: "/app/api/request_cams_pdf",

  // Finplan
  BASIC_DETAILS: "/app/api/svnage/",
  CHILDREN_DETAILS: "/app/api/svchinms/",
  CHILDREN_BACHELORS: "/app/api/svchiedu/",
  CHILDREN_PG: "/app/api/svchiedu/",
  CHILDREN_WEDDING: "/app/api/svchimrg/",
  INCOME_DETAILS: "/app/api/svincexp/",
  MARITAL_STATUS: "/app/api/svmrtl/",
  PLACE_TO_STUDY: "/app/api/svcntfms/",
  RISK: "/app/api/svrskmf/",
  WHEN_BUY_BIKE: "/app/api/svbkeyr/",
  WHEN_BUY_CAR: "/app/api/svcaryr/",
  WHEN_BUY_HOME: "/app/api/svhmeyr/",
  WHEN_RETIRE: "/app/api/svretag/",
  FINPLAN_SUMMARY_SAVE: "/app/api/save_finplan_summary/",
  CHILDREN_BACHELORS: "/app/api/svchiedu1/",
  CHILDREN_PG: "/app/api/svchiedu2/",
  CHILDREN_WEDDING: "/app/api/svchimrg/",
  RESET_FINPLAN: "/app/api/reset_finplan/",
  FINPLAN_DETAILS: "/app/api/finplan_details/",
  MF_MIN_DETAILS: "/app/api/mf_min_details/",

  ALERTS_TRACKORDERS: "/app/api/v2/track_orders/",
  ALERTS_ORDER_DETAILS: "/app/api/v2/get_order_details/",
  ALERTS_COUNT: "/app/api/v2/get_alerts_count/",
  ALERTS_NOTIFICATIONS: "/app/api/customerpushnotifications/",
  ALERTS_CLEARSINGLENOTIFICATION: "/app/api/notification_seen/",
  ALERTS_CLEARALLNOTIFICATIONS: "/app/api/v2/all_nc_clear/",
  ALERTS_PENDINGMANDATES: "/app/api/v2/pending/mandates/",
  ALERTS_PENDINGPAYEMENTS: "/app/api/v2/pending/payments/",
  ALERTS_PENDINGPAYEMENTSACTION: "/app/api/v2/transaction_update/",
  ALERTS_PENDINGFAMILYAPPROVALS: "/app/api/v2/pending/family_new",
  ALERTS_FAMILYACCESS: "/app/api/v2/grant_family_access/",
  ALERTS_WITHDRAW_IGNORE_FAMILYACCESS: "/app/api/v2/withdraw_family_access/",
  ALERTS_PENDINGBILLS: "/app/api/v2/pending/bills/",
  ALERTS_PENDINGORDERS: "/app/api/v2/attempted/orders/",
  ALERTS_RETRYINVESTMENT: "/app/api/v2/handle_inj_orders/",
  ALERTS_PENDINGSWITCHES: "/app/api/v2/attempted/switches/",
  ALERTS_RETRYSWITCH: "/app/api/v2/retry_switch_order/",
  ALERTS_CANCELSWITCH: "/app/api/v2/cancel_switch_order/",

  // Account
  CHECK_PAN_KYC: "/app/api/check_pan_kyc_status/",
  GET_CUSTOMER_DATA_KYC: "/app/api/complete_invest_profile/",
  DIGIO_KYC_REQUEST: "/app/corporate/digio/kyc/redirect/", //web url
  DIGIO_KYC_STATUS: "/app/api/digio/update/kyc/status/",
  GET_KARVY_URL: "/app/api/get_karvy_kyc_url/",

  // Risk Profile
  RISKPROFILE_SAVE_ANSWERS: "/app/api/save_risk_profile_ans_new/",
  RISKPROFILE_QNA: "/app/api/risk_profile_qna/",

  // SIPS
  MY_SIPS_URL: "/app/api/get_all_sip_orders/",
  GET_SIP_ORDER_DETAILS: "/app/api/bse_sip_details/",
  REDEEM_SIP: "/app/api/bse_redeem/",
  BSE_SIP_CANCEL_ORDER_URL: "/app/api/bse_cancel_sip_order/",

  GET_DATABASE_OF_USER: "/app/api/get_database_of_user/",

  MY_PASSBOOK: "/app/api/investoraccountfundtransactions/passbook/",
  SEARCH_FUNDS: "/app/api/search_schemes/",
  FEEDBACK: "/app/api/add_feedback/",
  SET_PASSWORD: "/app/api/set_password/",

  UPLOAD_PDF: "/app/api/upload_portfolio_pdf/",
  UPLOAD_STOCK_MF: "/app/api/upload_master_data/",

  FUNDS_IN_CART_WISHLIST: "/app/api/customerwishlistandcart/",
  UPDATE_IN_CART_WISHLIST: "/app/api/update_woc/",
  GET_INVESTMENT_PLANS: "/app/api/get_active_cip_for_client/",

  // Gateway
  GET_AUTH_TOKEN: "/app/api/get_gateway_authtoken/",
  GATEWAY_SUCCESS: "/app/api/gwsc_success_response/",
  GATEWAY_ERROR: "/app/api/gwsc_error_response/",

  GATEWAY_IMPORT_HOLDINGS: "/app/api/gwsc_holdings_response/",
  GATEWAY_IMPORT_HOLDINGS_WEBHOOK: "/app/api/gwsc_holdings_webhook_response/",
  GATEWAY_RETRY_ORDER: "/app/api/show_gwtxn_id_for_retry_orders/",

  LOGIN_BANNER_DETAILS: "/app/api/get_simply_grow_subscriber_data/",
  FORGOT_PASSWORD: "/app/api/set_reset_password_using_username/",

  GET_CUSTOMER_ACTION_ITEMS: "/app/api/customeractionitems/",
  GET_URL_TO_PLACE_SMALLCASE_ORDERS:
    "/app/api/get_url_to_place_smallcase_orders/",
  REJECT_ACTION_ITEMS: "/app/api/reject_action_items/",

  CREATE_SENDY_ACTION_FOR_CUSTOMER:
    "/app/api/create_sendy_actions_for_customer/",
  GET_EMAIL_TEMPLATE_DATA: "/app/api/get_email_template_data/",
  // cams update api
  UPDATE_CAMS_WEBHOOK_RESPONSE_FROM_CAI:
    "/app/api/update_cams_webhook_response_from_cai/",

  VIEW_MF_MONTHLY_REPORT: "/app/api/get_customer_mf_report_s3_link/",
  GET_JWL_SRK_RETURNS: "/app/api/get_JWL_SRK_returns/",
  GET_ADVISORY_FEE_DETAILS: "/app/api/get_advisory_fee_details/",
  SKIP_CUSTOMER_LOGIN: "/app/api/skip_customer_login/",
  UPDATE_CUSTOMER_CORPORATE_MAPPTING:
    "/app/api/update_customer_corporate_mappings/",
  CHECK_CUSTOMER_SIGNUP: "/app/api/check_customer_signup/",
  CUSTOMER_REPORT: "/app/api/fetch_custom_reports/",
  FINANCIAL_HEALTH_PLAN:
    "/app/api/fetch_employee_finplan_health_data_corporate/",
  FINANCIAL_LEARNING_DATA_CORPORATE:
    "/app/api/fetch_employee_finlearning_data_corporate/",
  GET_CUSTOMER_ACTION_ITEM_DETAILS:
    "/app/api/get_customer_action_item_details/",
  CREATE_CUSTOMER_REFERRAL_CODE: "/app/api/create_customer_referral_code/",
  CREATE_CRMDEALS_CIP_CBI_FOR_ACCENTURE:
    "/app/api/create_crmdeals_cip_cbi_for_accenture_clients/",
  MOBILE_OTP_VERIFICATION_BEFORE_SIGNUP: "/app/api/signup_otp_verfication/",
  MARK_CONTRACT_NOTE_AS_NOT_TRANSACTED:
    "/app/api/mark_contract_note_as_not_transacted/",
  MARK_CONTRACT_NOTE_AS_ALREADY_SENT:
    "/app/api/mark_contract_note_already_sent/",
  UNMARK_CONTRACT_NOTE_AS_NOT_TRANSACTED:
    "/app/api/un_mark_contract_note_as_not_transacted/",
  CHECK_USER_EXISTS_BASED_ON_PAN: "/app/api/check_user_exists_based_on_pan/",
  CREATE_CRMDEALS_CIP_FOR_REGULAR:
    "/app/api/create_crmdeals_cip_for_regular_clients/",
  GET_ACCOUNT_AGGREGATOR_CONSENT_STATUS:
    "/app/api/get_account_aggregator_consent_status/",
  GET_ADVISORY_TRACKER: "/app/api/get_advise_tracker_count/",
  GET_CUSTOMER_IAFF: "/app/api/get_customer_IAFF/",
  MAP_INVESTOR_ACC_TO_GOAL: "/app/api/map_investor_account_entity_to_goal/",
  CREATE_CRMDEALS_CIP_CBI_FOR_ADVISORY:
    "/app/api/create_crmdeals_cip_cbi_for_advisor_clients/",
  GET_STOCK_RECOMMENDEDATIONS: "/app/api/npp/ssi/stock_reco/",
  SELL_EXIT_STOCK: "/app/api/npp/ssi/exit_stock/",
  SELL_MARK_COMPLETE: "/app/api/npp/ssi/sell_mark_complete/",
  STOCK_PLACE_ORDER: "/app/api/npp/ssi/place_order/",
  GET_PDF_BYTES_OBJECT_FROM_SERVER:
    "/app/api/get_pdf_bytes_object_from_server/",
  UCC_CODE_GENERATE: "/app/api/ucc_code_generation_for_clients/",
  GET_CORPORATE_DASHBOARD: "/app/api/get_corporate_dashboard/",
  GET_FINANCIAL_WELLNESS_INDEX: "/app/api/get_financial_wellness_index/",
  GET_FINANCIAL_LEARNING_PROGRESS: "/app/api/get_financial_learning_progress/",
  GET_FINANCIAL_PLAN_PROGRESS: "/app/api/get_financial_plan_execution_index/",
  GET_EMP_QUIZ_DATA: "/app/api/fetch_employee_quiz_data_corporate/",
  STORE_RETIREMENT_BASIC_DETAILS: "/app/api/store_retirement_basic_details/",
  GET_CORPUS_OR_EXPENSE_DETAILS: "/app/api/get_corpus_or_expense_details/",
  GET_NET_CORPUS_AT_RETIREMENT: "/app/api/get_net_corpus_at_retirement/",
  SAVE_RETIREMENT_REDUCING_FACTOR: "/app/api/svretredp/",
  SAVE_EXPENSE_OR_INCOME_RECORDS: "/app/api/retrmntexpns/",
  GENERATE_CAMS_AGGREGRATOR_LINK: "/app/api/generate_cams_aggregator_link/",
  GET_URL_FOR_CLIENT_2FA: "/app/api/get_url_for_client_2FA/",
  GET_BSE_PAYMENT_URL_FOR_MF_ORDERS:
    "/app/api/get_bse_payment_url_for_mf_orders/",
  SCHEDULE_CRON_JOB_WITH_API: "/app/api/schedule_cron_job_with_api/",
  GET_CIPS_ABOUT_TO_EXPIRE: "/app/api/get_cips_about_to_expire/",
  GET_BOOK_ADVISORY_COUNT_OF_CORPORATES:
    "/app/api/get_book_advisory_count_of_corporates/",
  PLAN_RENEWAL_FOR_CLIENTS: "/app/api/plan_renewal_for_clients/",
  SEND_MAIL_USING_HTML: "/app/api/send_mail_using_html/",
  MOVE_DATA_FROM_DIRECT_TO_REGULAR:
    "/app/api/move_data_from_direct_to_regular/",
  DELETE_PLANS_AND_INVOICES_FOR_PAYMENT_FAILED:
    "/app/api/delete_plans_and_invoices_for_payment_failed/",
  MOVE_DATA_FROM_REGULAR_TO_DIRECT:
    "/app/api/move_data_from_regular_to_direct/",
  CANCEL_ALL_SIP_ORDERS_FOR_A_CUSTOMER:
    "/app/api/cancel_all_sip_orders_for_a_customer/",
  UPDATE_CUSTOMER_GOAL_VALUES: "/app/api/update_customer_goal_values/",
  CREATE_PLAN_FOR_PLATFORM_SUBSCRIPTION:
    "/app/api/create_plan_for_platform_subscription/",
  UPDATE_LONG_DURATION_PLAN_DATA: "/app/api/update_long_duration_plan_data/",
  // LOG_ERROR_IN_SENTRY: "/app/api/log_app_eror_in_sentry/",
  CREATE_ACTION_ITEMS_FOR_MF_ORDERS: "/app/api/create_action_items_for_mf_orders/",
  GET_CLIENT_MF_ORDERS_PLACED_TODAY: "/app/api/get_mf_client_orders_placed_today/",
  OTP_AUTH_FOR_MF_ORDERS: "/app/api/otp_auth_for_mf_orders/"
};
