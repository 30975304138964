import React, { Component } from "react";
import {
  View,
  FlatList,
  Alert,
  StatusBar,
  ScrollView,
  SafeAreaView,
  Platform,
} from "react-native";
import { WebView } from "react-native-web-webview";
import { getPendingSwitches } from "../../../api/alerts";
import amplitude from "../../../utils/amplitude";
import PendingSwitchesCard from "./pendingSwitchesCard";
import { isMobile } from "../../../utils";
import NavigationHeader from "../../../components/Common/NavigationHeader";

export default class PendingSwitches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switches: [],
      showWebViewBool: false,
      showHTMLContent: "",
      loading: false,
    };
  }

  componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("PendingSwitches",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("PendingSwitches", { path: this.props.navigation.getState().routeName });
    }
    this._pendingSwitches();
  }

  _pendingSwitches = () => {
    this.setState({ loading: true });
    getPendingSwitches().then((response) => {
      this.setState({
        switches: response.results,
        loading: false,
      });
    });
  };

  handleBackButton() {
    const that = this;
    Alert.alert(
      "Going back",
      "Are you sure you want to go back",
      [
        {
          text: "Yes, Go back and cancel the setup",
          onPress: () => that.props.navigation.goBack(),
        },
        {
          text: "Dont Cancel",
          onPress: () => {},
          style: "cancel",
        },
      ],
      { cancelable: false }
    );
  }

  _deleteItem = () => {
    getPendingSwitches().then((response) => {
      this.setState({
        switches: response.data.results,
      });
    });
  };

  _renderView = (items) => {
    return (
      <PendingSwitchesCard
        data={items.item}
        propw={this.props}
        navigation={this.props.navigation}
        deleteItem={this._deleteItem}
      />
    );
  };

  render() {
    if (this.state.showWebViewBool) {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Complete Setup"]}
            onPressBack={() => {
              this.props.navigation.goBack(null);
            }}
          />
          <StatusBar
            backgroundColor={"#410DAA"}
            barStyle={
              Platform.OS == "android" ? "light-content" : "dark-content"
            }
          />

          <WebView source={{ uri: this.state.showHTMLContent }} />
        </SafeAreaView>
      );
    } else {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Attempted Switches"]}
            onPressBack={() => {
              this.props.navigation.goBack(null);
            }}
          />
          <StatusBar
            backgroundColor={"#410DAA"}
            barStyle={
              Platform.OS == "android" ? "light-content" : "dark-content"
            }
          />
          <ScrollView>
            <View
              style={{
                marginBottom: isMobile ? 20 : 0,
                paddingLeft: isMobile ? "5%" : "25%",
                paddingRight: isMobile ? "5%" : "25%",
                paddingTop: 10,
              }}
            >
              {this.state.loading ? (
                <View style={{ padding: 16 }}></View>
              ) : (
                <FlatList
                  data={this.state.switches}
                  renderItem={this._renderView}
                  keyExtractor={(item, index) => index.toString()}
                  contentContainerStyle={{
                    paddingBottom: 50,
                  }}
                />
              )}
            </View>
          </ScrollView>
        </SafeAreaView>
      );
    }
  }
}
