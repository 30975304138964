import React, { PureComponent, useEffect } from "react";
import {
  View,
  ScrollView,
  SafeAreaView,
  StyleSheet,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { useFilePicker } from "use-file-picker";
import { connect } from "react-redux";
import { validateInput } from "../../../utils/validateInput";
import theme from "../../../static/theme";
import { Button, Text } from "react-native-paper";
import PickerField from "../../../components/Common/Pickers/PickerField";
import TextInputField from "../../../components/Common/Inputs/InputField";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import {
  DataURIToBlob,
  getUserDetails,
  isMobile,
  redirectToPendingScreen,
} from "../../../utils";
import { addBankAccount, verifyIFSC } from "../../../api";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";

const accountTypes = [
  {
    key: 2,
    label: "Savings",
    value: "SB",
  },
  {
    key: 3,
    label: "Current",
    value: "CB",
  },
  {
    key: 3,
    label: "Non Resident (NRE)",
    value: "NE",
  },
  {
    key: 3,
    label: "Non Resident Ordinary (NRO)",
    value: "NO",
  },
];

const SelectChequeLeaf = ({ onSelect }) => {
  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: "DataURL",
    accept: [".png", ".jpg", ".jpeg"],
    multiple: true,
    maxFileSize: 1,
  });
  useEffect(() => {
    onSelect(filesContent, errors);
  }, [filesContent]);
  const isFileSelected = () => {
    return filesContent.length != 0;
  };
  if (loading) {
    return <Text>Loading</Text>;
  }
  const checkSizeError = () => {
    if (errors && errors.length) {
      const error = errors[0];
      return {
        fileSizeToolarge: true,
        error: true,
      };
    }
    return null;
  };
  const selectedFile = isFileSelected();
  const errorData = checkSizeError();
  return (
    <View>
      <TouchableOpacity
        rippleContainerBorderRadius={5}
        style={{
          backgroundColor: "#410DAA",
          borderRadius: 5,
          paddingVertical: 4,
          paddingHorizontal: 15,
          width: 100,
          marginBottom: 10,
        }}
        onPress={() => {
          openFilePicker();
        }}
      >
        <Text style={{ fontSize: 13, color: "#fff" }}>Select file</Text>
      </TouchableOpacity>
      {selectedFile ? (
        filesContent.map((file) => (
          <Text style={styles.fileName}>{file.name}</Text>
        ))
      ) : (
        <Text style={styles.fileName}></Text>
      )}
      <Text
        style={[
          styles.support,
          errorData &&
            errorData.error &&
            !errorData.fileSizeToolarge &&
            styles.error,
        ]}
      >
        Supports PNG, JPG, JPEG{" "}
        <Text style={[errorData && errorData.fileSizeToolarge && styles.error]}>
          Max File Size 1MB
        </Text>
      </Text>
    </View>
  );
};

class AddBank extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ifsc: "",
      accountNumber: "",
      confirmAccountNumber: "",
    };
  }

  static navigationOptions = {
    tabBarVisible: false,
  };

  inputValueUpdater = (key, value) => {
    if (key == "ifsc" && value.length == 11) {
      this.setState(
        {
          gettingIfsc: true,
          [key]: value,
        },
        () => this.verifyIfsc()
      );
    }

    this.setState({
      [key]: value,
      error: "",
    });
  };

  verifyIfsc = async () => {
    const { ifsc } = this.state;
    if (ifsc && ifsc.length <= 0) {
      this.setState({
        error: "Please enter IFSC to verify",
      });
      return;
    }

    this.setState({
      gettingIfsc: true,
    });

    const payload = {
      ifsc_code: ifsc,
    };

    this.setState({
      gettingIfsc: false,
    });
    const response = await verifyIFSC(payload);
    if (response && response.code == "100") {
      if (response.hasOwnProperty("bse_bank_code")) {
        const { code, ...bankDetails } = response;
        this.setState({
          bankDetails: bankDetails || {},
        });
      }
    }
  };

  validate = () => {
    const { ifsc, accountNumber, accountType } = this.state;
    if (ifsc.length != 11) return false;
    if (accountNumber.length < 9) return false;
    if (accountType == "SEL" || !accountType) return false;
    return true;
  };

  addBankAccount = async () => {
    const {
      ifsc,
      accountNumber,
      imageuri = null,
      imagename = null,
    } = this.state;
    if (ifsc.length != 11) {
      return;
    }

    if (accountNumber.length < 9) {
      return;
    }

    if (!imageuri || !imagename) {
      return;
    }

    this.setState({ submitting: true });

    const { customer_id } = getUserDetails();

    var body = new FormData();
    body.append("customer", customer_id);
    body.append("ifsc_code", this.state.ifsc);
    body.append("bank_name", this.state.bankDetails.bank_name);
    body.append("branch_name", this.state.bankDetails.branch_name);
    body.append("account_number", accountNumber);
    body.append("account_type", this.state.accountType);

    const imageFile = DataURIToBlob(imageuri);
    body.append("cheque_image", imageFile, imagename);

    if (this.props.bankDetails && this.props.bankDetails.length == 0) {
      body.append("is_default_customer_bank_account", true);
    }

    const response = await addBankAccount(body);

    this.setState({
      submitting: false,
    });

    if (response && response.bank_name) {
      if (this.props.minDetailsFlow) {
        const redirect = await redirectToPendingScreen(this.props);
        if (redirect && redirect.error) {
        }
      }
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  };

  handleChange = (value, type, inputType) => {
    this.setState({
      [type]: value,
      [type + "Error"]: validateInput(inputType, value),
    });
    if (type == "ifsc" && value.length == 11) {
      this.setState(
        {
          gettingIfsc: true,
        },
        () => this.verifyIfsc()
      );
    }
  };

  render() {
    const { container } = styles;
    const {
      ifsc,
      ifscError,
      accountNumber,
      accountNumberError,
      accountType,
      accountTypeError,
      bankDetails,
    } = this.state;

    return (
      <SafeAreaView style={container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Add Bank Account"]}
        />
        <ScrollView
          style={{ flex: 1 }}
          showsVerticalScrollIndicator={true}
          contentContainerStyle={{}}
        >
          <View style={{ flex: 1, marginHorizontal: 16 }}>
            <View style={[styles.formItem, styles.itemMargin]}>
              <TextInputField
                label="Your Bank Account Number*"
                value={accountNumber}
                onChange={(accountNumber) =>
                  this.handleChange(
                    accountNumber,
                    "accountNumber",
                    "bankaccount"
                  )
                }
                style={[{ ...styles.inputItem }]}
                editable={true}
                type="bankaccount"
                isValid={accountNumberError}
                validate={true}
              />
            </View>

            <View style={[styles.formItem, styles.itemMargin]}>
              <TextInputField
                label="Your Bank’s IFSC Code*"
                value={ifsc}
                onChange={(ifsc) => this.handleChange(ifsc, "ifsc", "ifsc")}
                style={[{ ...styles.inputItem }]}
                editable={true}
                isValid={ifscError}
                type="ifsc"
                validate={true}
              />
            </View>
            {this.state.gettingIfsc && (
              <TouchableOpacity
                style={{
                  marginBottom: 32,
                  alignSelf: "flex-end",
                }}
                onPress={this.verifyIfsc}
              >
                <ActivityIndicator color={theme.colors.NEW_BRAND_COLOR} />
              </TouchableOpacity>
            )}
            {bankDetails && (
              <View
                style={{
                  flexWrap: "wrap",
                  flexDirection: "row",
                  marginBottom: 25,
                  marginTop: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: 11,
                    color: "#707070",
                    fontStyle: "italic",
                  }}
                >
                  {bankDetails.bank_name},
                </Text>
                <Text
                  style={{
                    fontSize: 11,
                    color: "#707070",
                    fontStyle: "italic",
                  }}
                >
                  {bankDetails.branch_name},
                </Text>

                <Text
                  style={{
                    fontSize: 11,
                    color: "#707070",
                    fontStyle: "italic",
                  }}
                >
                  {bankDetails.bank_address},
                </Text>
              </View>
            )}
            <View style={[styles.formItem, styles.itemMargin]}>
              <PickerFieldSelect
                data={accountTypes}
                value={accountType}
                label="Account Type"
                onChange={(accountType) =>
                  this.handleChange(accountType, "accountType", "select")
                }
                pickerStyle={[{ ...styles.inputItem }]}
                isValid={accountTypeError}
                type="select"
                placeholder={{ value: "SEL", label: "Select Account Type" }}
              />
            </View>

            <Text
              style={{
                fontSize: 13,
                color: "#939393",
                marginTop: 30,
                marginBottom: 20,
              }}
            >
              Upload A Cancelled Cheque Leaf Image*
            </Text>

            <SelectChequeLeaf
              onSelect={(filesContent) => {
                if (filesContent && filesContent.length > 0) {
                  const { content: imageuri, name: imagename } =
                    filesContent[0];
                  this.setState({
                    imageuri,
                    imagename,
                    imageSize: DataURIToBlob(imageuri).size,
                  });
                }
              }}
            />
          </View>
        </ScrollView>
        <Button
          uppercase={false}
          mode={"contained"}
          onPress={this.addBankAccount}
          style={{
            position: "absolute",
            left: 16,
            bottom: 10,
            width: isMobile ? "90%" : 300,
          }}
          loading={this.state.submitting}
          color={"#410DAA"}
        >
          <Text style={styles.next}>Add Bank Account</Text>
        </Button>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.MAIN_BACKGROUND,
  },
  row: {
    flexDirection: "row",
  },
  baseMargin: {
    marginTop: 16,
  },
  titleHeading: {
    color: "#707070",
    fontSize: 12,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.3,
  },
  fileName: {
    fontSize: 15,
    marginTop: 5,
    color: "#410DAA",
    fontWeight: "bold",
  },
});

export default connect(null)(AddBank);
