import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  Platform,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import Icon from "@expo/vector-icons/FontAwesome";
import amplitude from "../../../utils/amplitude";
import EmptyView from "../../../components/Common/emptyView";
import { StackActions } from "@react-navigation/routers";
import { getRiskProfileQuestions } from "../../../api";
import { saveRiskProfileAnswers } from "../../../api/account";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { Button } from "react-native-paper";
import { isMobile } from "../../../utils";

const QuestionModule = (props) => {
  const { questionContainer, questionStyle, optionText } = styles;
  const {
    data,
    openQuestion,
    updateQuestion,
    optionsRecorded,
    optionsToSubmit,
    updateOptions,
    AddToOptionsToSubmit,
  } = props;

  return (
    <>
      {data.map((question, index) => (
        <View style={questionContainer}>
          <View style={{ flexDirection: "column" }}>
            <TouchableOpacity onPress={() => updateQuestion(index)}>
              <Text
                style={[
                  questionStyle,
                  { marginBottom: 8, marginTop: 0, fontWeight: "bold" },
                ]}
              >
                {index + 1}. {question.question}
              </Text>

              {optionsRecorded[index] !== null &&
                optionsRecorded[index] !== undefined && (
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={[
                        questionStyle,
                        {
                          marginBottom: 0,
                          marginTop: 0,
                          color: "#121212",
                          opacity: 0.5,
                          flex: 1,
                          marginRight: 4,
                        },
                      ]}
                    >
                      {optionsRecorded[index]}
                    </Text>
                    <Icon name={"check-circle"} color={"#009105"} size={20} />
                  </View>
                )}
            </TouchableOpacity>
            {openQuestion == index && (
              <>
                <EmptyView />
                {question.options.map((option, optionIndex) => (
                  <TouchableOpacity
                    style={{ flexDirection: "row" }}
                    key={option.option_id}
                    onPress={() => {
                      const updatedResponses = [...optionsRecorded];
                      updatedResponses[index] = option.text;
                      const nextQuestion =
                        index + 1 <= data.length ? index + 1 : index;
                      updateQuestion(nextQuestion);
                      updateOptions(updatedResponses);
                      AddToOptionsToSubmit(option.option_id, index);
                    }}
                  >
                    <View
                      style={{
                        width: 16,
                        height: 16,
                        borderRadius: 8,
                        justifyContent: "center",
                        alignItems: "center",
                        borderWidth: 1,
                        borderColor: "#000",
                        padding: 2,
                        marginRight: 16,
                        marginLeft: 4,
                        marginTop: 4,
                      }}
                    >
                      {optionsToSubmit[index] === option.option_id && (
                        <View style={styles.optionBubble}></View>
                      )}
                    </View>
                    <Text style={optionText}>{option.text}</Text>
                  </TouchableOpacity>
                ))}
              </>
            )}
          </View>
        </View>
      ))}
    </>
  );
};

class MyRiskProfile extends React.PureComponent {
  state = {
    openQuestion: 0,
    optionsRecorded: {},
    optionsToSubmit: [],
    questions: [],
    error: "",
    fetchingQuestions: false,
    submittingAnswer: false,
  };

  componentDidMount = async () => {
    if (Platform.OS == "web"){
      amplitude.logEvent("myRiskProfile",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("myRiskProfile", { path: this.props.navigation.getState().routeName });
    }
    this.setState({
      fetchingQuestions: true,
    });
    try {
      const response = await getRiskProfileQuestions();
      if (response && response.results) {
        this.setState({
          questions: response.results,
        });
        const optionsRecorded = [];
        const optionsToSubmit = [];
        const responseAnswers = [...response.results];
        for (let index = 0; index < response.results.length; index++) {
          optionsRecorded[index] = responseAnswers[index].answer_name || null;
          optionsToSubmit[index] = responseAnswers[index].answer || null;
        }
        this.setState({
          optionsRecorded,
          optionsToSubmit,
          fetchingQuestions: false,
        });
      } else {
        this.setState({
          error: " Something went wrong. Please try again.",
        });
      }
    } catch (e) {
      this.setState({
        fetchingQuestions: false,
        error: "Something went wrong. Please try again.",
      });
    }
  };

  updateOptions = (updatedResponses) => {
    this.setState({
      optionsRecorded: updatedResponses,
    });
  };

  AddToOptionsToSubmit = (optionsId, questionIndex) => {
    const optionsToSubmit = [...this.state.optionsToSubmit];
    optionsToSubmit[questionIndex] = optionsId;
    this.setState({
      optionsToSubmit,
    });
  };

  onOptionsSubmit = async () => {
    const { optionsToSubmit } = this.state;
    this.setState({
      submittingAnswer: true,
    });

    const payload = {
      option_ids: optionsToSubmit,
    };
    const response = await saveRiskProfileAnswers(payload);
    if (response && response.code != 101) {
      const { risk_profile, modified = null } = response;
      const action = StackActions.replace("RiskProfileSummary", {
        risk_profile,
        taken: modified,
      });
      this.props.navigation.dispatch(action);

      this.setState({
        submittingAnswer: false,
      });
    }
    this.setState({
      submittingAnswer: false,
    });
  };

  handleShowResults = () => {
    const { optionsToSubmit = [], questions = [] } = this.state;
    optionsToSubmit.filter((option) => option != null).length ==
      questions.length && this.onOptionsSubmit();
  };

  render() {
    const { container } = styles;
    const {
      openQuestion,
      optionsRecorded,
      questions,
      optionsToSubmit,
      error,
      fetchingQuestions,
    } = this.state;
    let Buttoncolor = "#410DAA";

    if (
      optionsToSubmit.filter((option) => option != null).length !=
      questions.length
    ) {
      Buttoncolor = "#707070";
    }

    if (error) {
      return (
        <SafeAreaView style={styles.loaderOrErrorContainer}>
          <Text>{error}</Text>
        </SafeAreaView>
      );
    }

    if (fetchingQuestions) {
      return (
        <SafeAreaView style={styles.loaderOrErrorContainer}>
          <ActivityIndicator size="large" color="#410DAA" />
        </SafeAreaView>
      );
    }

    return (
      <SafeAreaView style={container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Risk Assessment"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />

        {!this.state.fetchingQuestions ? (
          <>
            <ScrollView style={{ flex: 1 }}>
              <QuestionModule
                data={questions}
                optionsRecorded={optionsRecorded}
                optionsToSubmit={optionsToSubmit}
                openQuestion={openQuestion}
                updateOptions={this.updateOptions}
                AddToOptionsToSubmit={this.AddToOptionsToSubmit}
                updateQuestion={(openQuestion) =>
                  this.setState({
                    openQuestion,
                  })
                }
              />
            </ScrollView>
            <View style={styles.buttonContainer}>
              <Button
                uppercase={false}
                mode={"contained"}
                onPress={this.handleShowResults}
                color={Buttoncolor}
                style={styles.takeNow}
                loading={this.state.submittingAnswer}
              >
                <Text style={styles.takeNowText}>Show Results</Text>
              </Button>
            </View>
            {this.state.error !== "" && (
              <Text style={styles.error}>{this.state.error}</Text>
            )}
          </>
        ) : (
          <View style={styles.loader}>
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  questionContainer: {
    backgroundColor: "#F7F5FC",
    margin: 16,
    marginBottom: 0,
    borderRadius: 8,
    padding: 16,
  },
  questionStyle: {
    color: "#121212",
  },
  optionText: {
    paddingHorizontal: 16,
    marginBottom: 16,
  },
  optionBubble: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "#410DAA",
    marginHorizontal: 8,
  },
  error: { fontSize: 12, color: "red", marginBottom: 16 },
  loader: { flex: 1, justifyContent: "center", alignItems: "center" },
  loaderOrErrorContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
    backgroundColor: "#fff",
  },
  buttonContainer: {
    width: isMobile ? "60%" : "20%",
    marginLeft: !isMobile ? "40%" : "20%",
    marginRight: !isMobile ? "40%" : "20%",
    alignItems: "center",
  },
  takeNow: {
    width: "100%",
    margin: 16,
    maxWidth: 900,
  },
  takeNowText: {
    fontSize: 15,
    color: "#fff",
  },
});

export default MyRiskProfile;
