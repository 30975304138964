import React from 'react';
import { View, Text, StyleSheet,Platform, ScrollView, Dimensions } from 'react-native';
import NavigationHeader from '../../components/Common/NavigationHeader';
import Table from '../../components/Common/Table';
import SearchBar from '../../components/Common/SearchBar';
import { formatDate, getProductName, getUserDetails, isMobile, monthFullNames, month_names, numberWithCommas } from '../../utils';
import { getPassbookData } from '../../api';
import { Picker } from '@react-native-picker/picker';
import { ActivityIndicator } from 'react-native-paper';
import { useNavigation, useRoute } from '@react-navigation/core';
import amplitude from '../../utils/amplitude';
import TransactionType from './components/TransactionType';
import PickerFieldSelect from '../../components/Common/Pickers/PickerFieldSelect';


const FilterByDate = ({ value, onChange, data = [] }) => {
    return (
        <View style={select.container}>
            <PickerFieldSelect
                data={data}
                value={value}
                style={{
                    borderColor: "#0000001A",
                    color: "#464E5F",
                    backgroundColor: "#fff",
                    paddingVertical: 6,
                    paddingHorizontal: 15,
                }}
                onChange={(itemValue) => onChange(itemValue)}
                pickerStyle={[{  }]}
                type="select"
                placeholder={{
                    label: 'Latest',
                    value: null,
                    color: '#9EA0A4',
                }}
            />
            {/* <Picker
                selectedValue={value}
                onValueChange={(itemValue, itemIndex) => {
                    onChange(itemValue)
                }}
                style={{
                    borderColor: "#0000001A",
                    color: "#464E5F",
                    backgroundColor: "#fff",
                    paddingVertical: 6,
                    paddingHorizontal: 15,
                }}
            >
                <Picker.Item label={"Latest"} value={null} />
                {
                    data.map((item) => {
                        return (
                            <Picker.Item label={item.label} value={item.value} />
                        )
                    })
                }
            </Picker> */}
        </View>
    )
}

const select = StyleSheet.create({
    container: {

    },
})

const ViewDetailsComponent = ({ date, type, product, user_id }) => {
    const navigation = useNavigation();
    const route = useRoute();
    const handleClick = () => {
        const { params } = route;
        let options = {}
        if (params && params.user_id) {
            options["user_id"] = params.user_id;
        }
        navigation.navigate("TransactionDetails", { date, type, product, client_id: user_id, ...options });
    }

    return (
        <Text style={styles.viewDetails} onPress={handleClick}>View Details</Text>
    )
};

const ViewDetails = React.memo(ViewDetailsComponent);


class MyPassbook extends React.Component {
    constructor(props) {
        super(props);
        let userId = null;
        const { params } = this.props.route;
        if (params) {
            userId = params.user_id || null;
            userId = isNaN(userId) ? null : userId;
        }
        this.state = {
            showStocksOf: "JEWEL",
            userId
        }
    }

    componentDidMount = async () => {
        try {
            if (Platform.OS == "web"){
                amplitude.logEvent("passbook",{ path: global.location.href })
              }
              else{
                amplitude.logEvent("passbook", { path: this.props.navigation.getState().routeName });
              }
            let { userId } = this.state;
            let { customer_id } = getUserDetails();
            customer_id = userId || customer_id;
            this.setState({ loadingData: true });
            const response = await getPassbookData(customer_id);
            let data = [];
            if (response && response.code == 100) {
                const { result = [] } = response;
                data = [...result];
            }
            const filterResults = [...data];
            let filterDates = [];
            filterResults.forEach((item) => {
                const dateValues = item.date ? item.date.split("-") : [];
                if (dateValues.length) {
                    const year_month = monthFullNames[Number(dateValues[1]) - 1] + " " + dateValues[0];
                    const notExists = filterDates.findIndex(item => item.label == year_month) == -1;
                    if (notExists) {
                        filterDates.push({
                            value: item.date,
                            label: year_month
                        });
                    }
                }
            });
            this.setState({ data, filterResults, loadingData: false, filterDates });
        } catch (error) {
            this.setState({ loadingData: false, error: true });
        }
    }

    compareStrings = (a, b) => {
        const x = a.toLowerCase();
        const y = b.toLowerCase();
        if (x < y) {
            return -1;
        }
        if (x > y) {
            return 1;
        }
        return 0;
    }

    compareNumbers = (a, b) => {
        return a - b;
    }

    getSortedData = (data, sorting_field, sorting_value, start, end) => {
        if (data.length != 0) {
            const fieldValue = data[0][sorting_field];
            const sortingType = isNaN(fieldValue) ? "string" : "number";

            data = data.slice(start, end);
            if (sortingType == "string") {
                data.sort((a, b) => this.compareStrings(a[sorting_field], b[sorting_field]))
            } else {
                data.sort((a, b) => this.compareNumbers(a[sorting_field], b[sorting_field]));
            };

            return sorting_value ? data : data.reverse();
        } else {
            return data;
        }
    }

    handleSort = (field, sort, start, end) => {
        const sorting_field = field;
        const sorting_value = !sort;

        const { searchResults } = this.state;
        const sortedData = [].concat(
            searchResults.slice(0, start),
            this.getSortedData(searchResults, sorting_field, sorting_value, start, end),
            searchResults.slice(end, searchResults.length)
        );

        this.setState({ sorting_field, sorting_value, searchResults: sortedData });
    }

    getHeaderData = (data = []) => {

        // const { sorting_field, sorting_value } = this.state;
        let { userId } = this.state;
        let { customer_id } = getUserDetails();
        customer_id = userId || customer_id;
        const keysData = [
            {
                label: "Date",
                key: "date"
            },
            {
                label: "Product",
                key: "product"
            },
            {
                label: "Transaction Amount",
                key: "transaction_amount"
            },
            {
                label: "Transaction Type",
                key: "transaction_type"
            },
            {
                label: "",
                key: null
            }
        ]

        let headerData = [];
        keysData.forEach(headerItem => {
            headerData.push({
                label: headerItem.label,
                style: [styles.tableHeader],
                headerItemContainerStyle: [styles.headerItemContainerStyle],
                sortOption: false,
                // onSort: this.handleSort,
                key: headerItem.key,
                // currentSortField: sorting_field,
                // sortValue: sorting_value
            })
        });

        let values = [];

        data.forEach((value, index) => {
            const { date, product, transaction_amount, transaction_type } = value;
            const dateFormatted = formatDate(date);
            const productName = getProductName(product);
            const transactionAmount = transaction_amount != null ? "₹" + numberWithCommas(transaction_amount.toFixed(2)) : "-";
            const transactionType = transaction_type == 'B' ? 'Buy' : 'Sell';

            values.push([
                {
                    valueToDisplay: dateFormatted,
                    value: date,
                    style: [styles.rowItem],
                    rowItemContainerStyle: [styles.rowItemContainerStyle]
                },
                {
                    valueToDisplay: productName,
                    value: value['product'],
                    style: [styles.rowItem, styles.product],
                    rowItemContainerStyle: [styles.rowItemContainerStyle]
                },
                {
                    valueToDisplay: transactionAmount,
                    value: value['transaction_amount'],
                    style: [styles.rowItem],
                    rowItemContainerStyle: [styles.rowItemContainerStyle]
                },
                {
                    value: <TransactionType type={transaction_type} />,
                    component: true,
                    style: [styles.rowItem],
                    rowItemContainerStyle: [styles.rowItemContainerStyle]
                },
                {
                    value: <ViewDetails date={date} type={transaction_type} product={value["product"]} user_id={customer_id} />,
                    component: true,
                    style: [styles.rowItem],
                    rowItemContainerStyle: [styles.rowItemContainerStyle]
                }
            ])
        })

        return { headerData, values };
    };

    handleSearch = (searchText) => {
        let searchResults = [];
        const { data, showStocksOf } = this.state;
        const searchData = data[showStocksOf];
        if (searchText) {
            searchResults = searchData.filter((stock) => {
                return stock.stock_name.toLowerCase().startsWith(searchText.toLowerCase());
            })
        } else {
            searchResults = searchData;
        }
        this.setState({ searchText, searchResults, sorting_field: null, sorting_value: null });
    }

    handleProductChange = (value) => {
        const { data } = this.state;
        const searchResults = data[value];
        this.setState({ showStocksOf: value, searchResults, sorting_field: null, sorting_value: null });
    }

    handleFilterChange = (value) => {
        let filterResults;
        const { data = [] } = this.state;
        if (value == "Latest") {
            filterResults = [...data];
        } else {
            const dateValues = value ? value.split("-") : [];
            const selected_year_month = dateValues[0] + "-" + dateValues[1];
            filterResults = data.filter(item => {
                const itemDateValues = item.date ? item.date.split("-") : [];
                const item_year_month = itemDateValues[0] + "-" + itemDateValues[1];
                return item_year_month == selected_year_month;
            });
        }
        this.setState({ filterResults });
    }


    render() {
        const { filterResults = [], loadingData, filterDates = [] } = this.state;
        const { headerData, values } = this.getHeaderData(filterResults);
        return (
            <View style={styles.container}>
                <NavigationHeader
                    navigation={this.props.navigation}
                    navigationHeader={["JEWEL Plan", "My Passbook"]}
                    style={styles.headerStyle}
                    onPressBack={() => {
                        this.props.navigation.navigate("Portfolio", { screen: "InvestmentOverview" });
                    }}
                />
                {
                    loadingData ? (
                        <View style={styles.loadingConainer}>
                            <ActivityIndicator size="large" color="#410DAA" />
                        </View>
                    ) : (
                        <ScrollView style={styles.mainContainer}>
                            <ScrollView horizontal={true}>
                            <View style={styles.detailsContainer}>

                                <View style={styles.headingContainer}>
                                    <View>
                                        <Text style={styles.header}>My Passbook</Text>
                                        <Text style={styles.headerDescription}>View your Transaction details</Text>
                                    </View>
                                    {/* <SearchBar value={searchText} onChange={this.handleSearch}/> */}
                                    <FilterByDate value={null} onChange={this.handleFilterChange} data={filterDates} />
                                </View>

                                <Table
                                    header={headerData}
                                    tableHeaderStyle={[
                                        {
                                            paddingVertical: 17
                                        }
                                    ]}
                                    gridConfig={[
                                        0.2, 0.15, 0.25, 0.2, 0.2
                                    ]}
                                    data={values}
                                    minWidth={1500}
                                />
                            </View>
                        </ScrollView>
                        </ScrollView>
                    )
                }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: { flex: 1, flexDirection: "column", backgroundColor: "#fff" },
    headerStyle: { width: "100%", maxWidth: 1500 },
    loadingConainer: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    mainContainer: {
        backgroundColor: '#FFFFFF'
    },
    detailsContainer: {
        // borderWidth: 1,
        borderColor: "#1212121A",
        // borderRadius: 10,
        marginHorizontal: isMobile ? 16 : 36,
        marginBottom: 50,
        minWidth: isMobile ? 800 : Dimensions.get("window").width - (Dimensions.get("window").width / 4.8)
    },
    headingContainer: {
        paddingHorizontal: isMobile ? 16 : 0,
        paddingVertical: 26,
        flexDirection: isMobile ? "column" : "row",
        alignItems: isMobile ? "flex-start" : "center",
        justifyContent: "space-between"
    },
    header: {
        fontFamily: "Roboto",
        color: "#464E5F",
        fontSize: 16,
        fontWeight: "700",
        marginBottom: 10
    },
    headerDescription: {
        fontFamily: "Roboto",
        color: "#B5B5C3",
        fontSize: 12,
        marginBottom: isMobile ? 10 : 0
    },
    tableHeader: {
        color: "#464E5F",
        fontSize: 12,
        fontFamily: "Roboto",
        fontWeight: "700",
    },
    rowItem: {
        textAlign: "left",
        fontSize: 13
    },
    rowItemContainerStyle: {
        justifyContent: "flex-start",
        paddingLeft: 10
    },
    headerItemContainerStyle: {
        justifyContent: "flex-start",
    },
    product: {
        color: "#8950FC",
        // fontStyle: "italic"
    },
    viewDetails: {
        color: "#410DAA"
    }
});

export default MyPassbook;