import React, { useState } from "react";
import {
  StyleSheet,
  View,
  Linking,
  TouchableOpacity,
  Platform,
  Image,
  Dimensions,
} from "react-native";
import { Text } from "react-native-paper";
import { md } from "../../Responsive";
import global from "./globalStyles";
import { getUserDetails } from "../../utils";

const rnWindow = Dimensions.get("window");

const openURL = async (url) => {
  try {
    Linking.openURL(url);
  } catch (err) {
    console.log(err);
  }
};

export default function PlanDetails({
  plan,
  isLast,
  navigation,
  currentPlanName,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    name,
    tag,
    price,
    priceSubtitle,
    topFeatures,
    altPriceSubtitle,
    displayName,
  } = plan;
  let url = "";
  const { customer_id } = getUserDetails();

  if (name == "gold") {
    url = "https://www.maxiom.co.in/app/jfp/PP/" + customer_id;
  } else if (name == "platinum") {
    url = "https://www.maxiom.co.in/app/jfp/PLA/" + customer_id;
  }

  if (customer_id == null) {
    url = null;
  }

  return (
    <>
      {Platform.OS === "web" && <Text></Text>}
      <View style={styles.container}>
        <Text style={[styles.heading, global.arial]}>
          Jamā <Text>{displayName}</Text> Membership
        </Text>
        <Text
          style={[
            styles.subtitle,
            { color: plan.name === "jewel" ? "#410DAA" : "black" },
            global.arial,
          ]}
        >
          {tag}
        </Text>
        {price && (
          <Text style={[styles.price, global.arial]}>{"₹ " + price}</Text>
        )}
        <Text style={[styles.priceSubtitle, global.arial]}>
          {altPriceSubtitle ? altPriceSubtitle : priceSubtitle}
        </Text>
        <Text style={[styles.featureHeading, global.arial]}>Top Features</Text>
        <View style={styles.list}>
          {topFeatures.map((item, i) => {
            <View
              key={i}
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 4,
                alignItems: "center",
              }}
            >
              <Image
                source={require("../../assets/upgradePlan/Landing/check.png")}
                style={{ width: 9, height: 7, marginRight: 9 }}
              ></Image>
              <Text style={[styles.feature, global.arial]} key={i}>
                {item}
              </Text>
            </View>;
          })}
        </View>
        <View>
          {currentPlanName === name ? (
            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                Linking.openURL(url);
              }}
            >
              <Text style={[styles.buttonText, global.arial]}>
                Renew Your Membership
              </Text>
            </TouchableOpacity>
          ) : name === "jewel" ? (
            <TouchableOpacity
              style={[styles.button, styles.jewelButton]}
              onPress={() => {
                navigation.navigate("MySubscriptionContactPage", {
                  for_query: false,
                });
              }}
            >
              <Text style={[styles.jewelButtonText, global.arial]}>
                Contact us
              </Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                openURL(url);
              }}
            >
              <Text style={[styles.buttonText, global.arial]}>
                Buy {displayName} membership
              </Text>
            </TouchableOpacity>
          )}
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("MySubscriptionPlanPage", {
                plan: name,
              });
            }}
          >
            <Text style={[styles.learnMore, global.arial]}> Learn More </Text>
          </TouchableOpacity>
        </View>
      </View>
      {!isLast && <View style={styles.hr}></View>}
    </>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  container: {
    fontSize: 13,
    marginTop: md(rnWindow.width) ? 40 : undefined,
    marginBottom: md(rnWindow.width) ? 40 : undefined,
    paddingLeft: md(rnWindow.width) ? 20 : undefined,
    paddingRight: md(rnWindow.width) ? 20 : undefined,
    flexShrink: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: md(rnWindow.width) ? 300 : undefined,
  },
  heading: {
    fontWeight: "bold",
    fontSize: 20,
    marginTop: 40,
    textTransform: "capitalize",
    flexShrink: 1,
    color: "black",
  },
  subtitle: {
    fontSize: 13,
    marginTop: 10,
    color: "black",
  },
  price: {
    fontSize: 20,
    color: "#410DAA",
    marginTop: 13,
  },
  priceSubtitle: {
    opacity: 0.5,
    marginTop: 4,
    color: "black",
  },
  featureHeading: {
    fontWeight: "bold",
    marginTop: 30,
    color: "black",
  },
  list: {
    marginTop: 10,
    marginBottom: 38,
  },
  button: {
    backgroundColor: "#410DAA",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center",
    borderRadius: 5,
    paddingTop: 15,
    paddingBottom: 15,
    color: "black",
  },
  buttonText: {
    color: "white",
  },
  jewelButton: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "#410DAA",
  },
  jewelButtonText: {
    color: "#410DAA",
  },
  learnMore: {
    fontSize: 15,
    textAlign: "center",
    color: "#5197ED",
    marginTop: 15,
  },
  feature: {
    color: "black",
  },
  hr: {
    height: md(rnWindow.width) ? undefined : 1,
    backgroundColor: "#CCC",
    width: md(rnWindow.width) ? 1 : undefined,
    marginTop: md(rnWindow.width) ? 46 : 30,
    marginBottom: md(rnWindow.width) ? 40 : undefined,
  },
});
