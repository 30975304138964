import React, { useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  FlatList,
  Platform,
  Linking,
} from "react-native";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { Feather } from "@expo/vector-icons";

import Modal from "../../components/Common/Modal";
import { AntDesign } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { StackActions } from "@react-navigation/native";
import { ShowToast } from "../../components/Common/Toast";
import DocumentPicker, { types } from "expo-document-picker";
import {
  getUserDetails,
  monthFullNames,
  formatDate,
  numericDate,
  DataURIToBlob,
  isMobile,
  getWindowSize,
  isJama,
} from "../../utils";
import {
  getContractNote,
  markContractNoteAsAlreadySent,
  markContractNoteAsNotTransacted,
  unmarkContractNoteAsNotTransacted,
  uploadContrcatNote,
} from "../../api";
import PickerFieldSelect from "../../components/Common/Pickers/PickerFieldSelect";

import SearchBar from "../../components/Common/SearchBar";
import { ContractNoteTabs } from "./ContractNoteTabs";
import ContractNoteTable from "./ContractNoteTable";
// import ReactExport from "react-export-excel";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import moment from "moment";
import amplitude from "../../utils/amplitude";
import Store from "../../store";
import { useFilePicker } from "use-file-picker";

const SelectContractNote = ({ onSelect }) => {
  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: "DataURL",
    accept: [".xls", ".pdf", ".csv", ".xlsv", ".xlsx"],
    multiple: true,
  });
  useEffect(() => {
    onSelect(filesContent, errors);
  }, [filesContent]);
  const isFileSelected = () => {
    return filesContent.length != 0;
  };
  if (loading) {
    return <Text>Loading</Text>;
  }
  const checkSizeError = () => {
    if (errors && errors.length) {
      const error = errors[0];
      const { fileSizeToolarge } = error;
      return {
        fileSizeToolarge: true,
        error: true,
      };
    }
    return null;
  };
  const handleFileSelector = async () => {
    try {
      if (Platform.OS == "web") {
        openFilePicker();
      } else {
        const result = await DocumentPicker.pickSingle({
          allowMultiSelection: false,
          type: [types.pdf, types.xls, types.xlsx, types.csv],
        });
        onSelect([{ content: result.uri, name: result.name, ...result }]);
      }
    } catch (error) { }
  };

  const selectedFile = isFileSelected();
  const errorData = checkSizeError();
  return (
    <View>
      <TouchableOpacity style={styles.touch} onPress={handleFileSelector}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Feather name="upload" size={20} color="#410DAA" />
          <Text style={styles.file}>Upload Contract Note</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const FilterByDate = ({ value, onChange, data = [] }) => {
  return (
    <View>
      <PickerFieldSelect
        data={data}
        value={value}
        style={{}}
        onChange={(itemValue) => {
          onChange(itemValue);
        }}
        pickerStyle={[
          {
            borderColor: "#0000001A",
            color: "#464E5F",
            backgroundColor: "#fff",
            paddingVertical: 6,
            paddingHorizontal: 15,
            marginLeft: 23,
          },
        ]}
        type="select"
        placeholder={{
          label: "Latest",
          value: null,
          color: "#9EA0A4",
        }}
      />
    </View>
  );
};

const UploadedFileComponent = ({ file, s3Link }) => {
  const fileLength = file.length - 25;
  const subText = file.substring(fileLength);

  const handleClick = () => {
    if (s3Link) {
      if (Platform.OS == "web") {
        global.open(s3Link);
      } else {
        Linking.openURL(s3Link);
      }
    }
  };
  return (
    <Text
      style={[styles.subtext, !s3Link && { color: "#000000" }]}
      onPress={handleClick}
    >
      {subText}
    </Text>
  );
};
const UploadedFile = React.memo(UploadedFileComponent);

export default class contractNote extends React.Component {
  constructor(props) {
    super(props);
    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      user_id,
      isLoading: false,
      submitLoading: false,
      showModal: false,
      filesData: [],
      fileError: "",
      mainError: "",
      show_cn_missing_msg: false,
      buttonLoading: false,
      showToast: false,
      toastMsg: null,
      selectedContractNoteTab: 0,
      sorting_field: null,
      sorting_value: null,
      searchStatusList: [],
      selectedStatus: 0,
    };
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("ContractNotes", { path: global.location.href });
      } else {
        amplitude.logEvent("ContractNotes", {
          path: this.props.navigation.getState().routeName,
        });
      }
      this.setState({ isLoading: true });
      let { user_id } = this.state;
      const response = await getContractNote(user_id);
      let data = [];
      if (response && response.code == 100) {
        const { result = [] } = response;
        if (result && result.length) {
          let show_cn_missing_msg = false;
          result.forEach((item) => {
            if (item.status == "") {
              show_cn_missing_msg = true;
            }
          });
          let resultValue = null;
          if (
            Store.getState().dashboard &&
            Store.getState().dashboard.viewModeUserId &&
            Store.getState().dashboard.viewModeUserId.user_id
          ) {
            resultValue = result;
          } else {
            resultValue = result.filter((dataValue) => {
              return dataValue.status_code != "FAL";
            });
          }
          data = [...resultValue];
          this.setState({
            mainError: "",
            show_cn_missing_msg: show_cn_missing_msg,
          });
        } else {
          this.setState({ mainError: "No Contract Notes to display" });
          this.setState({
            mainError: (
              <span style={{ color: 'black' }}>
                No Contract Notes to display
              </span>
            )
          });
        }
      } else {
        this.setState({
          isLoading: false,
          mainError: "Unable to contact server. Please try after some time",
        });
      }

      const filterResults = [...data];
      let filterDates = [{ value: null, label: "All Months" }];
      filterResults.forEach((item) => {
        const dateValues = item.execution_date
          ? item.execution_date.split("-")
          : [];
        if (dateValues.length) {
          const year_month =
            monthFullNames[Number(dateValues[1]) - 1] + " " + dateValues[0];
          const notExists =
            filterDates.findIndex((item) => item.label == year_month) == -1;
          if (notExists) {
            filterDates.push({
              value: item.execution_date,
              label: year_month,
            });
          }
        }
      });
      let processedContractNotes = [];
      let notProcessedContractNotes = [];
      let notTransactedContractNotes = [];
      data.forEach((item) => {
        if (item.status == "Processed") {
          processedContractNotes.push(item);
        } else {
          if (item.status == "Not Transacted") {
            notTransactedContractNotes.push(item);
          } else {
            if (item.status) {
              notProcessedContractNotes.push(item);
            } else {
              item.status = "Pending";
              notProcessedContractNotes.push(item);
            }
          }
        }
      });
      let searchStatusList = [];
      notProcessedContractNotes.forEach((item, index) => {
        searchStatusList.push({
          key: index,
          label: item.status ? item.status.toString() : "Pending",
          value: item.status ? item.status : "Pending",
        });
      });
      searchStatusList = searchStatusList.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.value === value.value)
      );
      this.setState({
        data,
        searchStatusList,
        filterDates: filterDates,
        filterResults: notProcessedContractNotes,
        duplicateFilterResults: notProcessedContractNotes,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        mainError: "Unable to contact server. Please try after some time",
      });
    }
  };

  handleMarkAsNotTransacted = async (ccn_id) => {
    try {
      this.setState({
        showToast: false,
      });
      this.setState({ buttonLoading: false });
      const payload = {
        ccn_id: ccn_id,
      };
      const response = await markContractNoteAsNotTransacted(payload);
      if (response && response.code == 100) {
        this.setState({
          showToast: true,
          toastMsg: "Contract Note has been marked as 'Not Transacted'.",
          buttonLoading: false,
        });
        this.setState({ isLoading: false });
        let { user_id } = this.state;
        const response = await getContractNote(user_id);
        let data = [];
        if (response && response.code == 100) {
          this.setState({ isLoading: false });
          const { result = [] } = response;
          if (result && result.length) {
            let show_cn_missing_msg = false;
            result.forEach((item) => {
              if (item.status == "") {
                show_cn_missing_msg = true;
              }
            });
            let resultValue = null;
            if (
              Store.getState().dashboard &&
              Store.getState().dashboard.viewModeUserId &&
              Store.getState().dashboard.viewModeUserId.user_id
            ) {
              resultValue = result;
            } else {
              resultValue = result.filter((dataValue) => {
                return dataValue.status_code != "FAL";
              });
            }
            data = [...resultValue];
            this.setState({
              mainError: "",
              show_cn_missing_msg: show_cn_missing_msg,
            });
          } else {
            this.setState({ mainError: "No Contract Notes to display" });
          }
        } else {
          this.setState({
            isLoading: false,
            mainError: "Unable to contact server. Please try after some time",
          });
        }

        const filterResults = [...data];
        let filterDates = [{ value: null, label: "All Months" }];
        filterResults.forEach((item) => {
          const dateValues = item.execution_date
            ? item.execution_date.split("-")
            : [];
          if (dateValues.length) {
            const year_month =
              monthFullNames[Number(dateValues[1]) - 1] + " " + dateValues[0];
            const notExists =
              filterDates.findIndex((item) => item.label == year_month) == -1;
            if (notExists) {
              filterDates.push({
                value: item.execution_date,
                label: year_month,
              });
            }
          }
        });
        let processedContractNotes = [];
        let notProcessedContractNotes = [];
        let notTransactedContractNotes = [];
        data.forEach((item) => {
          if (item.status == "Processed") {
            processedContractNotes.push(item);
          } else {
            if (item.status == "Not Transacted") {
              notTransactedContractNotes.push(item);
            } else {
              if (item.status) {
                notProcessedContractNotes.push(item);
              } else {
                item.status = "Pending";
                notProcessedContractNotes.push(item);
              }
            }
          }
        });
        let searchStatusList = [];
        notProcessedContractNotes.forEach((item, index) => {
          searchStatusList.push({
            key: index,
            label: item.status ? item.status.toString() : "Pending",
            value: item.status ? item.status : "Pending",
          });
        });
        searchStatusList = searchStatusList.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.value === value.value)
        );
        this.setState({
          data,
          searchStatusList,
          filterDates: filterDates,
          filterResults: notProcessedContractNotes,
          duplicateFilterResults: notProcessedContractNotes,
        });
      } else {
        this.setState({
          showToast: true,
          toastMsg: response.msg,
          buttonLoading: false,
        });
      }
    } catch (err) {
      this.setState({
        buttonLoading: false,
      });
    }
  };

  handleMarkAsSentContractNote = async (ccn_id) => {
    try {
      this.setState({
        showToast: false,
      });
      this.setState({ buttonLoading: false });
      const payload = {
        ccn_id: ccn_id,
      };
      const response = await markContractNoteAsAlreadySent(payload);
      if (response && response.code == 100) {
        this.setState({
          showToast: true,
          toastMsg: "Contract Note has been marked as 'Sent Contract Note'.",
          buttonLoading: false,
        });
        this.setState({ isLoading: false });
        let { user_id } = this.state;
        const response = await getContractNote(user_id);
        let data = [];
        if (response && response.code == 100) {
          this.setState({ isLoading: false });
          const { result = [] } = response;
          if (result && result.length) {
            let show_cn_missing_msg = false;
            result.forEach((item) => {
              if (item.status == "") {
                show_cn_missing_msg = true;
              }
            });
            let resultValue = null;
            if (
              Store.getState().dashboard &&
              Store.getState().dashboard.viewModeUserId &&
              Store.getState().dashboard.viewModeUserId.user_id
            ) {
              resultValue = result;
            } else {
              resultValue = result.filter((dataValue) => {
                return dataValue.status_code != "FAL";
              });
            }
            data = [...resultValue];
            this.setState({
              mainError: "",
              show_cn_missing_msg: show_cn_missing_msg,
            });
          } else {
            this.setState({ mainError: "No Contract Notes to display" });
          }
        } else {
          this.setState({
            isLoading: false,
            mainError: "Unable to contact server. Please try after some time",
          });
        }

        const filterResults = [...data];
        let filterDates = [{ value: null, label: "All Months" }];
        filterResults.forEach((item) => {
          const dateValues = item.execution_date
            ? item.execution_date.split("-")
            : [];
          if (dateValues.length) {
            const year_month =
              monthFullNames[Number(dateValues[1]) - 1] + " " + dateValues[0];
            const notExists =
              filterDates.findIndex((item) => item.label == year_month) == -1;
            if (notExists) {
              filterDates.push({
                value: item.execution_date,
                label: year_month,
              });
            }
          }
        });
        let processedContractNotes = [];
        let notProcessedContractNotes = [];
        let notTransactedContractNotes = [];
        data.forEach((item) => {
          if (item.status == "Processed") {
            processedContractNotes.push(item);
          } else {
            if (item.status == "Not Transacted") {
              notTransactedContractNotes.push(item);
            } else {
              if (item.status) {
                notProcessedContractNotes.push(item);
              } else {
                item.status = "Pending";
                notProcessedContractNotes.push(item);
              }
            }
          }
        });
        let searchStatusList = [];
        notProcessedContractNotes.forEach((item, index) => {
          searchStatusList.push({
            key: index,
            label: item.status ? item.status.toString() : "Pending",
            value: item.status ? item.status : "Pending",
          });
        });
        searchStatusList = searchStatusList.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.value === value.value)
        );
        this.setState({
          data,
          searchStatusList,
          filterDates: filterDates,
          filterResults: notProcessedContractNotes,
          duplicateFilterResults: notProcessedContractNotes,
        });
      } else {
        this.setState({
          showToast: true,
          toastMsg: response.msg,
          buttonLoading: false,
        });
      }
    } catch (err) {
      this.setState({
        buttonLoading: false,
      });
    }
  };

  handleMarkAsTransacted = async (ccn_id) => {
    try {
      this.setState({
        showToast: false,
      });
      this.setState({ buttonLoading: false });
      const payload = {
        ccn_id: ccn_id,
      };
      const response = await unmarkContractNoteAsNotTransacted(payload);
      if (response && response.code == 100) {
        this.setState({
          showToast: true,
          toastMsg: "Contract Note has been marked as 'Transacted'.",
          buttonLoading: false,
        });
        this.setState({ isLoading: false });
        let { user_id } = this.state;
        const response = await getContractNote(user_id);
        let data = [];
        if (response && response.code == 100) {
          this.setState({ isLoading: false });
          const { result = [] } = response;
          if (result && result.length) {
            let show_cn_missing_msg = false;
            result.forEach((item) => {
              if (item.status == "") {
                show_cn_missing_msg = true;
              }
            });
            let resultValue = null;
            if (
              Store.getState().dashboard &&
              Store.getState().dashboard.viewModeUserId &&
              Store.getState().dashboard.viewModeUserId.user_id
            ) {
              resultValue = result;
            } else {
              resultValue = result.filter((dataValue) => {
                return dataValue.status_code != "FAL";
              });
            }
            data = [...resultValue];
            this.setState({
              mainError: "",
              show_cn_missing_msg: show_cn_missing_msg,
            });
          } else {
            this.setState({ mainError: "No Contract Notes to display" });
          }
        } else {
          this.setState({
            isLoading: false,
            mainError: "Unable to contact server. Please try after some time",
          });
        }

        const filterResults = [...data];
        let filterDates = [{ value: null, label: "All Months" }];
        filterResults.forEach((item) => {
          const dateValues = item.execution_date
            ? item.execution_date.split("-")
            : [];
          if (dateValues.length) {
            const year_month =
              monthFullNames[Number(dateValues[1]) - 1] + " " + dateValues[0];
            const notExists =
              filterDates.findIndex((item) => item.label == year_month) == -1;
            if (notExists) {
              filterDates.push({
                value: item.execution_date,
                label: year_month,
              });
            }
          }
        });
        let processedContractNotes = [];
        let notProcessedContractNotes = [];
        let notTransactedContractNotes = [];
        data.forEach((item) => {
          if (item.status == "Processed") {
            processedContractNotes.push(item);
          } else {
            if (item.status == "Not Transacted") {
              notTransactedContractNotes.push(item);
            } else {
              if (item.status) {
                notProcessedContractNotes.push(item);
              } else {
                item.status = "Pending";
                notProcessedContractNotes.push(item);
              }
            }
          }
        });
        let searchStatusList = [];
        notProcessedContractNotes.forEach((item, index) => {
          searchStatusList.push({
            key: index,
            label: item.status ? item.status.toString() : "Pending",
            value: item.status ? item.status : "Pending",
          });
        });
        searchStatusList = searchStatusList.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.value === value.value)
        );
        this.setState({
          data,
          searchStatusList,
          filterDates: filterDates,
          filterResults: notTransactedContractNotes,
          duplicateFilterResults: notTransactedContractNotes,
        });
      } else {
        this.setState({
          showToast: true,
          toastMsg: response.msg,
          buttonLoading: false,
        });
      }
    } catch (err) {
      this.setState({
        buttonLoading: false,
      });
    }
  };

  compareStrings = (a, b) => {
    const x = a.toLowerCase();
    const y = b.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  compareNumbers = (a, b) => {
    return a - b;
  };

  getSortedData = (data, sorting_field, sorting_value, start, end) => {
    if (data.length != 0) {
      const fieldValue = data[0][sorting_field];
      const sortingType = isNaN(fieldValue) ? "string" : "number";

      data = data.slice(start, end);
      if (sorting_field === "execution_date") {
        data.sort((a, b) => new Date(a[sorting_field]) - new Date(b[sorting_field]));
      } else if (sortingType == "string") {
        data.sort((a, b) =>
          this.compareStrings(a[sorting_field], b[sorting_field])
        );
      } else {
        data.sort((a, b) =>
          this.compareNumbers(a[sorting_field], b[sorting_field])
        );
      }
      return sorting_value ? data : data.reverse();
    } else {
      return data;
    }
  };

  handleContractNoteSort = (field, sort, start, end) => {
    const sorting_field = field;
    const sorting_value = !sort;
    const { filterResults } = this.state;
    const sortedData = [].concat(
      filterResults.slice(0, start),
      this.getSortedData(
        filterResults,
        sorting_field,
        sorting_value,
        start,
        end,
        "contractnote"
      ),
      filterResults.slice(end, filterResults.length)
    );
    this.setState({ sorting_field, sorting_value, filterResults: sortedData });
  };

  getHeaderData = (data = []) => {
    const { sorting_field, sorting_value } = this.state;
    let { user_id } = this.state;
    let { customer_id } = getUserDetails();
    customer_id = user_id || customer_id;
    const keysData = [
      {
        label: "Execution Date",
        key: "execution_date",
      },
      {
        label: "Document",
        key: "uploaded_filename",
      },
      {
        label: "Type",
        key: "contract_note_type",
      },
      {
        label: "Uploaded Date",
        key: "datetime_uploaded",
      },
      {
        label: "Current Status",
        key: "status",
      },
      {
        label: "Actions",
        key: null,
      },
    ];

    let headerData = [];
    keysData.forEach((headerItem, index) => {
      headerData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: index == 5 ? false : true,
        onSort: this.handleContractNoteSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    let values = [];
    let totalSearchData = [];
    data.forEach((value) => {
      const {
        execution_date,
        to_execution_date,
        uploaded_filename,
        contract_note_type,
        datetime_uploaded,
        status,
        s3_link,
      } = value;
      const fromdateFormatted = formatDate(execution_date);
      const todateFormatted = formatDate(to_execution_date);
      const upload_date_formatted = formatDate(datetime_uploaded);
      totalSearchData.push({
        execution_date: fromdateFormatted,
        to_execution_date: todateFormatted,
        uploaded_filename,
        contract_note_type,
        upload_date_formatted,
        status,
      });
      values.push([
        {
          component: true,
          valueToDisplay:
            fromdateFormatted == todateFormatted
              ? fromdateFormatted
              : fromdateFormatted + " - " + todateFormatted,
          value:
            fromdateFormatted == todateFormatted ? (
              <View style={{ textAlign: "center" }}>
                <Text>{todateFormatted}</Text>
              </View>
            ) : (
              <View style={{ textAlign: "center" }}>
                <Text>
                  {contract_note_type == "Bill Details"
                    ? "Trade Book"
                    : contract_note_type}{" "}
                  Period
                </Text>
                <Text>
                  {fromdateFormatted} - {todateFormatted}
                </Text>
              </View>
            ),
          style: [styles.rowItem, styles.product],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: (
            <UploadedFile file={uploaded_filename} s3Link={s3_link} />
          ),
          value: uploaded_filename,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay:
            contract_note_type == "Bill Details"
              ? "Trade Book"
              : contract_note_type,
          value:
            contract_note_type == "Bill Details"
              ? "Trade Book"
              : contract_note_type,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: upload_date_formatted,
          value: upload_date_formatted,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: status ? status : "Pending",
          value: status,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          value: this.state.buttonLoading ? (
            <ActivityIndicator
              size="small"
              color="#410DAA"
              style={{ alignSelf: "center" }}
            />
          ) : value.status_code == "PRO" ? (
            <TouchableOpacity
              onPress={() => {
                this.props.navigation.navigate("ContrcatTransactionDetails", {
                  ccn_id: value.ccn_id,
                });
              }}
            >
              <Text style={styles.viewDetails}>View Details</Text>
            </TouchableOpacity>
          ) : value.status_code == "" ? (
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.handleMarkAsNotTransacted(value.ccn_id);
                }}
              >
                <Text style={styles.viewDetails}>Mark as 'Not Transacted'</Text>
              </TouchableOpacity>
              <View style={{ padding: 5 }}></View>
              <TouchableOpacity
                onPress={() => {
                  this.handleMarkAsSentContractNote(value.ccn_id);
                }}
              >
                <Text style={styles.viewDetails}>
                  Mark as 'Sent Contract Note'
                </Text>
              </TouchableOpacity>
            </View>
          ) : value.status_code == "NTR" ? (
            <TouchableOpacity
              onPress={() => {
                this.handleMarkAsTransacted(value.ccn_id);
              }}
            >
              <Text style={styles.viewDetails}>Mark as 'Transacted'</Text>
            </TouchableOpacity>
          ) : value.status_code == "SEN" ? (
            <TouchableOpacity
              onPress={() => {
                this.handleMarkAsNotTransacted(value.ccn_id);
              }}
            >
              <Text style={styles.viewDetails}>Mark as 'Not Transacted'</Text>
            </TouchableOpacity>
          ) : null,
          component: true,
          style: [styles.rowItem, { paddingRight: 10 }],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ]);
    });

    return { headerData, values, totalSearchData };
  };

  handleFilterChange = (value) => {
    let filterResults;
    const { data = [] } = this.state;
    if (value == "Latest" || value == "All Months") {
      filterResults = [...data];
    } else {
      const dateValues = value ? value.split("-") : [];
      const selected_year_month = dateValues[0] + "-" + dateValues[1];
      filterResults = data.filter((item) => {
        const itemDateValues = item.execution_date
          ? item.execution_date.split("-")
          : [];
        const item_year_month = itemDateValues[0] + "-" + itemDateValues[1];
        return item_year_month == selected_year_month;
      });
    }
    this.setState({ filterResults });
  };

  handleSearch = (searchText) => {
    let filterResults = [];
    let searchData = [];
    let processedContractNotes = [];
    let notProcessedContractNotes = [];
    let notTransactedContractNotes = [];
    this.state.data.forEach((item) => {
      if (item.status == "Processed") {
        processedContractNotes.push(item);
      } else {
        if (item.status == "Not Transacted") {
          notTransactedContractNotes.push(item);
        } else {
          if (item.status) {
            notProcessedContractNotes.push(item);
          } else {
            item.status = "Pending";
            notProcessedContractNotes.push(item);
          }
        }
      }
    });
    if (this.state.selectedContractNoteTab == 0) {
      searchData = notProcessedContractNotes;
    }
    if (this.state.selectedContractNoteTab == 1) {
      searchData = processedContractNotes;
    }
    if (this.state.selectedContractNoteTab == 2) {
      searchData = notTransactedContractNotes;
    }
    if (searchText) {
      filterResults = searchData.filter((value) => {
        return value.status.toLowerCase().startsWith(searchText.toLowerCase());
      });
    } else {
      this.setState({ selectedStatus: 0 });
      filterResults = searchData;
    }
    if (filterResults.length) {
      this.setState({
        searchText,
        filterResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  handleFileUpload = async () => {
    const { filesData } = this.state;
    this.setState({ submitLoading: true });
    let { user_id } = this.state;
    if (!user_id) {
      const userData = getUserDetails();
      user_id = userData.customer_id;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    }
    let checkData = 0;
    const fileTypes = ["xls", "pdf", "csv", "xlsv"];
    filesData.forEach((item) => {
      if (item.imageSize > 1003000 || !fileTypes.includes(item.fileType)) {
        checkData = checkData + 1;
      }
    });
    let dataToApi = [];
    if (filesData && filesData.length > 0 && checkData == 0) {
      dataToApi = filesData.map((item) => {
        const { imageUrl, imageName } = item;
        let body = new FormData();
        var today = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
        var datetime = new Date();
        var time = datetime.getHours() + ":" + datetime.getMinutes();
        var formattedDate = today + " " + time;
        const imageFile = DataURIToBlob(imageUrl);
        body.append("customer", user_id);
        body.append("datetime_uploaded", formattedDate);
        body.append("status", "NPR");
        body.append("uploaded_filename", imageName);
        body.append("contract_note", imageFile, imageName);
        return body;
      });
    } else {
      this.setState({
        fileError: "There is an error with your file",
        submitLoading: false,
      });
    }
    if (dataToApi && dataToApi.length) {
      try {
        this.setState({
          showToast: false,
        });
        const responses = await Promise.all(
          dataToApi.map((item) => uploadContrcatNote(item))
        );
        this.setState({
          fileError: "",
          submitLoading: false,
          showToast: true,
          toastMsg: "Your contract note has been uploaded.",
        });
        this.props.navigation.dispatch(
          StackActions.replace("App", {
            screen: "ViewMode",
            params: { screen: "ContractNote", params: { first: true } },
          })
        );
      } catch (error) {
        this.setState({
          submitLoading: false,
          fileError: "Unable to contact server. Please try after some time",
        });
      }
    }
  };
  renderFilesUploaded = ({ item }) => {
    const removeFile = (imageName) => {
      const dataAfterFileRemoval = this.state.filesData.filter(
        (item) => item.imageName != imageName
      );
      this.setState({ filesData: dataAfterFileRemoval });
    };
    const fileTypes = ["xls", "pdf", "csv", "xlsv"];
    return (
      <View style={styles.flat}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingVertical: 3,
            flexWrap: "wrap",
          }}
        >
          <View style={{ flex: 0.1 }}>
            <AntDesign name="filetext1" size={16} color="#410DAA" />
          </View>
          <View style={{ padding: 5 }}></View>
          <View style={{ flex: 0.9 }}>
            <Text style={styles.imageName}>{item.imageName}</Text>
          </View>
        </View>
        {item.imageSize <= 1003000 && fileTypes.includes(item.fileType) ? (
          <Ionicons
            name="checkmark-circle-outline"
            size={20}
            color="#009105"
            style={{ marginRight: 10 }}
          />
        ) : (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginRight: 10,
            }}
          >
            {item.imageSize > 1003000 ? (
              <Text style={styles.filePlease}>Error: File Size Exceeded</Text>
            ) : (
              <Text style={styles.filePlease}>Error: Wrong Format</Text>
            )}
            <TouchableOpacity
              onPress={() => {
                removeFile(item.imageName);
              }}
            >
              <Ionicons name="close-circle-outline" size={20} color="#EF5D12" />
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  };

  onSelectContractNoteTab = (selectedContractNoteTab) => {
    let processedContractNotes = [];
    let notProcessedContractNotes = [];
    let notTransactedContractNotes = [];
    this.state.data.forEach((item) => {
      if (item.status == "Processed") {
        processedContractNotes.push(item);
      } else {
        if (item.status == "Not Transacted") {
          notTransactedContractNotes.push(item);
        } else {
          if (item.status) {
            notProcessedContractNotes.push(item);
          } else {
            item.status = "Pending";
            notProcessedContractNotes.push(item);
          }
        }
      }
    });

    if (selectedContractNoteTab == 0) {
      let searchStatusList = [];
      notProcessedContractNotes.forEach((item, index) => {
        searchStatusList.push({
          key: index,
          label: item.status ? item.status.toString() : "Pending",
          value: item.status ? item.status : "Pending",
        });
      });
      searchStatusList = searchStatusList.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.value === value.value)
      );
      this.setState({
        searchText: "",
        searchStatusList,
        selectedContractNoteTab,
        filterResults: notProcessedContractNotes,
        duplicateFilterResults: notProcessedContractNotes,
      });
    }
    if (selectedContractNoteTab == 1) {
      this.setState({
        searchText: "",
        selectedContractNoteTab,
        filterResults: processedContractNotes,
        duplicateFilterResults: processedContractNotes,
      });
    }
    if (selectedContractNoteTab == 2) {
      this.setState({
        searchText: "",
        selectedContractNoteTab,
        filterResults: notTransactedContractNotes,
        duplicateFilterResults: notTransactedContractNotes,
      });
    }
  };

  render() {
    const {
      filterDates = [],
      filterResults = [],
      isLoading,
      showModal,
      filesData,
      checkData,
      fileError,
      submitLoading,
      mainError,
      show_cn_missing_msg,
      searchText,
      showToast,
      toastMsg,
      data,
    } = this.state;
    const { headerData, values } = this.getHeaderData(filterResults);
    let excelContractNotesData = [];
    let processedContractNotes = [];
    let notProcessedContractNotes = [];
    let notTransactedContractNotes = [];
    if (data) {
      data.forEach((item) => {
        if (item.status == "Processed") {
          processedContractNotes.push(item);
        } else {
          if (item.status == "Not Transacted") {
            notTransactedContractNotes.push(item);
          } else {
            if (item.status) {
              notProcessedContractNotes.push(item);
            } else {
              item.status = "Pending";
              notProcessedContractNotes.push(item);
            }
          }
        }
      });
      if (this.state.selectedContractNoteTab == 0) {
        notProcessedContractNotes.forEach((item) => {
          excelContractNotesData.push({
            execution_date: item.execution_date
              ? moment(item.execution_date).format("DD MMM YYYY")
              : "",
            uploaded_filename: item.uploaded_filename,
            contract_note_type: item.contract_note_type,
            datetime_uploaded: item.datetime_uploaded
              ? moment(item.datetime_uploaded).format("DD MMM YYYY")
              : "",
            status: item.status ? item.status : "Pending",
          });
        });
      }
      if (this.state.selectedContractNoteTab == 1) {
        processedContractNotes.forEach((item) => {
          excelContractNotesData.push({
            execution_date: item.execution_date
              ? moment(item.execution_date).format("DD MMM YYYY")
              : "",
            uploaded_filename: item.uploaded_filename,
            contract_note_type: item.contract_note_type,
            datetime_uploaded: item.datetime_uploaded
              ? moment(item.datetime_uploaded).format("DD MMM YYYY")
              : "",
            status: item.status,
          });
        });
      }
      if (this.state.selectedContractNoteTab == 2) {
        notTransactedContractNotes.forEach((item) => {
          excelContractNotesData.push({
            execution_date: item.execution_date
              ? moment(item.execution_date).format("DD MMM YYYY")
              : "",
            uploaded_filename: item.uploaded_filename,
            contract_note_type: item.contract_note_type,
            datetime_uploaded: item.datetime_uploaded
              ? moment(item.datetime_uploaded).format("DD MMM YYYY")
              : "",
            status: item.status,
          });
        });
      }
    }

    return (
      <View style={styles.container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Contract Notes"]}
          style={styles.headerStyle}
          onPressBack={() => {
            if (this.props.navigation.canGoBack()) {
              this.props.navigation.goBack();
            } else {
              this.props.navigation.push("App");
            }
          }}
        />
        {showToast ? (
          <ShowToast showToast={true} type="status" title={toastMsg} />
        ) : null}
        {showModal && (
          <Modal style={[isMobile ? styles.modalMobile : styles.modalWeb]}>
            <View
              style={{
                flexDirection: "column",
                paddingTop: 37,
                paddingBottom: 23,
                paddingHorizontal: 33,
                width: isMobile ? getWindowSize().width * 0.9 : 628,
              }}
            >
              <Text style={styles.uploadMod}>Upload Contract Note</Text>
              <Text style={styles.select}>
                Select contract notes, trade bills for upload. Supported
                Formats: .xls, .xlsv, .pdf, .csv
              </Text>
              <View style={styles.border}>
                <FlatList
                  data={filesData}
                  keyExtractor={(item) => item.imageName}
                  renderItem={this.renderFilesUploaded}
                  style={{
                    flex: 1,
                  }}
                />
              </View>
              {checkData != 0 ? (
                <Text style={styles.filePlease}>{fileError}</Text>
              ) : (
                <Text></Text>
              )}
              <View style={styles.two_buttns}>
                <TouchableOpacity
                  style={styles.cancelTouch}
                  onPress={() => {
                    this.setState({
                      showModal: false,
                      filesData: [],
                      fileError: "",
                    });
                  }}
                >
                  <Text style={[styles.cancel, { color: "#410DAA" }]}>
                    Cancel
                  </Text>
                </TouchableOpacity>
                {submitLoading ? (
                  <ActivityIndicator
                    size="small"
                    color="Black"
                    style={{ alignSelf: "center", marginLeft: 20 }}
                  />
                ) : (
                  <TouchableOpacity
                    style={styles.submitTouch}
                    onPress={this.handleFileUpload}
                  >
                    <Text
                      style={[
                        styles.cancel,
                        { fontWeight: "bold", color: "#FFFFFF" },
                      ]}
                    >
                      Upload
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </Modal>
        )}
        <ScrollView>
          <ScrollView horizontal={true}>
            <View style={styles.inner}>
              {show_cn_missing_msg ? (
                <View
                  style={{
                    textAlign: "left",
                    paddingLeft: 10,
                    paddingBottom: 15,
                  }}
                >
                  <Text
                    style={{ fontSize: 15, fontWeight: 600, marginLeft: 10 }}
                  >
                    We expect your Contract Notes to keep your portfolio
                    updated. Unfortunately some of them are not received. Please
                    email them to upload@{isJama() ? "jama" : "maxiom"}.in OR
                    upload them here.{"\n"}
                    {"\n"}&#8226; If no transaction was done on the dates shown
                    below, please tap 'Mark as Not Transacted'.{"\n"}&#8226; If
                    transactions were done and you sent the contract note, then
                    mark it as 'Sent Contract Note' - this will stop any
                    reminders.{"\n"}
                    {"\n"}
                  </Text>
                </View>
              ) : null}
              <View style={styles.strip}>
                <View>
                  <Text style={styles.contract}></Text>
                  <Text style={styles.your}></Text>
                </View>
                <View style={styles.two_btn}>
                  <SelectContractNote
                    onSelect={(filesContent) => {
                      if (filesContent && filesContent.length > 0) {
                        filesContent.map((file) => {
                          let fileType = file.name.split(".");
                          filesData.push({
                            imageUrl: file.content,
                            imageName: file.name,
                            imageSize: DataURIToBlob(file.content).size,
                            fileType: fileType[fileType.length - 1],
                          });
                        });
                        this.setState({ filesData, showModal: true });
                      }
                    }}
                  />


                  <FilterByDate
                    value={null}
                    onChange={this.handleFilterChange}
                    data={filterDates}
                  />

                </View>
              </View>
              <View style={{ marginTop: isMobile ? 10 : -10 }}>
                <ContractNoteTabs
                  tabs={[
                    { label: "To Be Processed", value: "notprocessed" },
                    { label: "Processed", value: "processed"},
                    { label: "Not Transacted", value: "nottransacted" },
                  ]}
                  onSelectTab={(data) => this.onSelectContractNoteTab(data)}
                />
              </View>
              {!mainError ? (
                <>
                  {isLoading ? (
                    <ActivityIndicator
                      size="large"
                      color="Black"
                      style={{ alignSelf: "center" }}
                    />
                  ) : (
                    <View style={styles.detailsContainer}>
                      <View style={styles.headingContainer}>
                        <SearchBar
                          value={searchText}
                          onChange={this.handleSearch}
                          placeHolder={`Search by status`}
                        />
                        {this.state.selectedContractNoteTab == 0 && (
                          <PickerFieldSelect
                            data={this.state.searchStatusList}
                            placeholderValue={"Select current status"}
                            value={this.state.selectedStatus}
                            onChange={(text) => {
                              if (text != "0") {
                                this.handleSearch(text);
                              } else {
                                let processedContractNotes = [];
                                let notProcessedContractNotes = [];
                                let notTransactedContractNotes = [];
                                this.state.data.forEach((item) => {
                                  if (item.status == "Processed") {
                                    processedContractNotes.push(item);
                                  } else {
                                    if (item.status == "Not Transacted") {
                                      notTransactedContractNotes.push(item);
                                    } else {
                                      if (item.status) {
                                        notProcessedContractNotes.push(item);
                                      } else {
                                        item.status = "Pending";
                                        notProcessedContractNotes.push(item);
                                      }
                                    }
                                  }
                                });
                                this.setState({
                                  searchText: "",
                                  filterResults: notProcessedContractNotes,
                                  sorting_field: null,
                                  sorting_value: null,
                                });
                              }
                              this.setState({
                                selectedStatus: text,
                              });
                            }}
                            style={{ marginLeft: "8%" }}
                            width={210}
                            type="select"
                          />
                        )}
                        <View
                          style={{
                            cursor: "pointer",
                            justifyContent: "flex-end",
                            flexDirection: "row",
                            marginRight: 20,
                          }}
                        >
                          {/* <ExcelFile
                            filename={
                              "Contract Notes " +
                              moment().format("DD-MM-YYYY hh-mm-ss A")
                            }
                            element={
                              <Text
                                style={{
                                  color: "#410DAA",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                  fontSize: 15,
                                  textDecorationLine: "underline",
                                }}
                              >
                                Download As Excel
                              </Text>
                            }
                          >
                            <ExcelSheet
                              data={excelContractNotesData}
                              name="Contract Notes"
                            >
                              <ExcelColumn
                                label="Execution Date"
                                value="execution_date"
                              />
                              <ExcelColumn
                                label="Document"
                                value="uploaded_filename"
                              />
                              <ExcelColumn
                                label="Type"
                                value="contract_note_type"
                              />
                              <ExcelColumn
                                label="Uploaded Date"
                                value="datetime_uploaded"
                              />
                              <ExcelColumn
                                label="Current Status"
                                value="status"
                              />
                            </ExcelSheet>
                          </ExcelFile> */}
                        </View>
                      </View>
                      <ContractNoteTable
                        header={headerData}
                        gridConfig={[1, 1, 1, 1, 1, 1.5]}
                        data={values}
                        navigation={this.props.navigation}
                      />
                    </View>
                  )}
                </>
              ) : (
                <View
                  style={{
                    paddingTop: 100,
                  }}
                >
                  <Text style={styles.mainError}>{mainError}</Text>
                </View>
              )}
            </View>
          </ScrollView>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  headerStyle: { width: "100%", maxWidth: 1500 },
  inner: {
    width: isMobile
      ? 1400
      : Dimensions.get("window").width - Dimensions.get("window").width / 5.5,
    paddingHorizontal: isMobile ? 20 : 10,
    paddingVertical: 21,
  },
  strip: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  two_btn: {
    flexDirection: "row",
    alignItems: "center",
  },
  contract: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 21,
    color: "#212121",
  },
  your: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 18,
    color: "#B5B5C3",
  },
  upload: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#410DAA",
    paddingLeft: 7,
  },
  bord: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    paddingHorizontal: 15,
    paddingVertical: 7,
    marginRight: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  headerItemContainerStyle: {
    justifyContent: "center",
  },
  rowItem: {
    textAlign: "left",
    fontSize: 13,
  },
  rowItemContainerStyle: {
    justifyContent: "center",
    paddingLeft: 10,
  },
  linkStyle: {
    color: "#000000",
  },
  subtext: {
    color: "#8950FC",
    letterSpacing: 0.4,
    fontSize: 13,
    lineHeight: 15,
    fontFamily: "Roboto",
    fontStyle: "italic",
  },
  inptxt: {
    fontFamily: "Roboto",
    fontSize: 16,
  },
  inptBorder: {
    marginBottom: 15,
    paddingVertical: 10,
    paddingHorizontal: 25,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
  },
  touch: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
    paddingVertical: 6,
    paddingHorizontal: 15,
    borderRadius: 4,
  },
  file: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#410DAA",
    paddingLeft: 5,
  },
  support: {
    fontSize: 10,
    color: "#121212",
    fontStyle: "italic",
    marginTop: 4,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  viewDetails: {
    color: "#410DAA",
    fontSize: 13,
    fontWeight: "600",
    textDecorationLine: "underline",
  },
  modalMobile: {
    position: "absolute",
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  modalWeb: {
    position: "absolute",
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  border: {
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.3)",
    width: "100%",
    height: 200,
    paddingHorizontal: 14,
    paddingVertical: 10,
  },
  filePlease: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#EF5D12",
    paddingVertical: 5,
  },
  uploadMod: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    color: "#009105",
    fontWeight: "bold",
    paddingBottom: 10,
  },
  select: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 21,
    color: "#121212",
  },
  flat: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cancel: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
  },
  cancelTouch: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#410DAA",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    paddingHorizontal: 45,
  },
  submitTouch: {
    backgroundColor: "#410DAA",
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    paddingHorizontal: 45,
    borderRadius: 4,
    marginLeft: 22,
  },
  two_buttns: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  imageName: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "rgba(18, 18, 18, 0.5)",
    letterSpacing: 0.16,
    paddingLeft: 5,
  },
  mainError: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "black",
    textAlign: "center",
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginLeft: "2%",
    marginBottom: 66,
    width: "100%",
    marginTop: 30,
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 16 : 19,
    paddingVertical: 19,
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "space-between",
  },
});
