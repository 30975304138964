import React, { Fragment } from "react";
import {
  View,
  ScrollView,
  SafeAreaView,
  FlatList,
  StatusBar,
  Image,
  Platform,
  ActivityIndicator,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { Text, Modal } from "react-native-paper";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import TodayCost from "../components/todayCost";
import amplitude from "../../../utils/amplitude";
import { SectionHeading } from "../../../components/Common/Heading/SectionHeading";
import CurrencyFormatInput from "../../../components/Common/currencyFormatInput";
import ANTIcon from "@expo/vector-icons/AntDesign";
import Divider from "../../../components/Common/divider";
import { DateDays } from "../../../components/Common/dateDays";
import numberFormat from "../../../utils/numberFormat";
import {
  completeMinDetails,
  getDateEnd,
  getUserDetails,
  isMobile,
  months_short_names,
  numberWithCommas,
} from "../../../utils";
import CustButton from "../../../components/Common/Button";
import theme from "../../../static/theme";
import { StackActions } from "@react-navigation/native";
import { goalSummary } from "../../../api/goals";
import SuccessOrFailureModal from "../../../components/Common/Modals/SuccessOrFailureModal";
import Store from "../../../store";
import { createOrderWithFirstInstallment } from "../../../api";
import ComplianceModal from "../../../components/InvestmentOverview/ComplianceModal";

const AllocationStatus = ({ heading, equityPercent = "0" }) => {
  equityPercent = Number(equityPercent);
  const non_equity = (100 - equityPercent).toFixed(2);
  return (
    <View>
      <Text style={{ fontSize: 14, paddingTop: 15 }}>{heading}</Text>
      <View style={{ flexDirection: "row", paddingVertical: 5 }}>
        <Text style={{ alignSelf: "flex-start", flex: 0.5, fontSize: 10 }}>
          Equity:
          <Text style={{ fontWeight: "bold", fontSize: 10, paddingLeft: 3 }}>
            {equityPercent.toFixed(1)}%
          </Text>
        </Text>
        <View style={{ alignItems: "flex-end", flex: 0.5 }}>
          <Text style={{ fontSize: 10 }}>
            Non-Equity:
            <Text style={{ fontWeight: "bold", fontSize: 10, paddingLeft: 3 }}>
              {Number(non_equity).toFixed(1)}%
            </Text>
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            height: 5,
            backgroundColor: "#FFC20E",
            width: `${equityPercent.toFixed(1)}%`,
          }}
        ></View>
        <View
          style={{
            height: 5,
            backgroundColor: "#9385FF",
            width: `${100 - equityPercent.toFixed(1)}%`,
          }}
        ></View>
      </View>
    </View>
  );
};

const FundTypeInput = ({
  fundId,
  fundType,
  value,
  onValueChange,
  onDelete,
  style,
  toggleModal,
  sipDate,
}) => {
  return (
    <View style={{ marginVertical: 10, ...style }}>
      <Text style={{ fontSize: 13, marginBottom: 5 }}>{fundType}</Text>
      <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
        <CurrencyFormatInput
          prefix="₹"
          value={value}
          onValueChange={(amount, error) => {
            onValueChange(amount, error);
          }}
          containerStyle={{
            flex: 0.5,
            paddingTop: 6,
            paddingBottom: 6,
          }}
          textStyle={{
            fontSize: 14,
            paddingTop: 5,
            paddintBottom: 5,
          }}
          onSubmitEditing={() => {}}
        />
        <ANTIcon
          name="closecircle"
          color={"#EF5D12"}
          size={16}
          style={{
            paddingTop: 10,
            paddingLeft: 10,
          }}
          onPress={() => {
            onDelete();
          }}
        />
        {fundType == "SIP" && (
          <View style={{ flex: 0.4, paddingLeft: 10 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  toggleModal(fundId);
                }}
                style={{
                  width: 40,
                  height: 35,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f2f2f2",
                  borderRadius: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                  }}
                >
                  {sipDate}
                </Text>
                <Text
                  style={{
                    position: "absolute",
                    right: 4,
                    top: 3,
                    fontSize: 10,
                  }}
                >
                  {getDateEnd(sipDate)}
                </Text>
              </TouchableOpacity>
              <Text style={{ fontSize: 14, paddingLeft: 5 }}>Every month</Text>
            </View>
            <Text
              style={{ fontStyle: "italic", fontSize: 10, color: "#A8A8A8" }}
            >
              Next installment
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

const JamaMutualMF = ({
  data,
  fundId,
  orders,
  handleChange,
  lumpSumValue,
  sipValue,
  deleteSuggestion,
  toggleModal,
  getSelectedDate,
}) => {
  let lumpsum_order_data = orders.filter((item) => {
    return item.amcfundscheme_id == fundId && item.investment_type == "lumpsum";
  });
  let sip_order_data = orders.filter((item) => {
    return item.amcfundscheme_id == fundId && item.investment_type == "sip";
  });
  const date = getSelectedDate(fundId);
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          paddingVertical: 10,
        }}
      >
        <View style={{ flex: 0.8 }}>
          <Text style={{ fontSize: 13 }}>{data.name}</Text>
        </View>
        <View style={{ flex: 0.2, alignItems: "center" }}>
          <View style={{ flexDirection: "row" }}>
            <ANTIcon
              name={data.returns_5_yr > 0 ? "caretup" : "caretdown"}
              color={data.returns_5_yr > 0 ? "green" : "red"}
              size={12}
              style={{
                alignSelf: "center",
                paddingRight: 5,
              }}
            />
            <Text style={{ fontSize: 14 }}>
              {data.returns_5_yr && data.returns_5_yr != ""
                ? numberFormat(data.returns_5_yr.toFixed(1))
                : "-"}
              %
            </Text>
          </View>
          <Text style={{ fontStyle: "italic", fontSize: 10, color: "#A8A8A8" }}>
            Last 5Y
          </Text>
        </View>
      </View>
      {sipValue != 0 &&
        sip_order_data &&
        sip_order_data.length > 0 &&
        sip_order_data[0].amount != null && (
          <View>
            <FundTypeInput
              fundType={"SIP"}
              fundId={fundId}
              value={sip_order_data[0].amount}
              sipDate={date || 1}
              onValueChange={(amount, error) => {
                handleChange(fundId, "sip", "AMOUNT", amount, error);
              }}
              onDelete={() => {
                deleteSuggestion(fundId, "sip");
              }}
              toggleModal={(fundId) => toggleModal(fundId, "sip")}
              style={{}}
            />
            <Text style={styles.errorText}>
              {sip_order_data[0].error || " "}
            </Text>
          </View>
        )}

      {lumpSumValue != 0 &&
        lumpsum_order_data &&
        lumpsum_order_data.length > 0 &&
        lumpsum_order_data[0].amount != null && (
          <View>
            <FundTypeInput
              fundType={"Lumpsum"}
              value={`${lumpsum_order_data[0].amount}` || 0}
              onValueChange={(amount, error) => {
                handleChange(fundId, "lumpsum", "AMOUNT", amount, error);
              }}
              onDelete={() => {
                deleteSuggestion(fundId, "lumpsum");
              }}
              style={{}}
            />
            <Text style={styles.errorText}>
              {lumpsum_order_data[0].error || " "}
            </Text>
          </View>
        )}
    </View>
  );
};

export default class InvestGoalSummary extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let actionId = null;
    if (params) {
      actionId = params.action || null;
    }

    this.state = {
      visibleModal: false,
      isLoading: true,
      actionId,
      userid: null,
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("investGoalSummary",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("investGoalSummary", { path: this.props.navigation.getState().routeName });
      }
      this.setState({ isLoading: true });
      const { params } = this.props.route;
      let skip_compliance = false;

      let mfMinDetailsData = {};
      if (params) {
        skip_compliance = params.s || false;
      }

      const mfMinDetailsResponse = await completeMinDetails(
        true,
        this.state.userId,
        skip_compliance
      );

      if (mfMinDetailsResponse && !mfMinDetailsResponse.error) {
        mfMinDetailsData = mfMinDetailsResponse;
      }

      const { actions = {} } = Store.getState().transactions;
      const { actionId } = this.state;
      if (actions.hasOwnProperty(actionId)) {
        const { goal, goal_id, amount } = actions[actionId];
        const payload = { goal_id: goal.customergoal_id, fresh_invt: amount };
        const response = await goalSummary(payload);
        if (response && response.code == "100") {
          let data = [];
          const { portfolio_data, equity_fund1, debt_fund1, ...remainingData } =
            response.results;
          equity_fund1["fund_id"] = equity_fund1.amcfundscheme_id;
          debt_fund1["fund_id"] = debt_fund1.amcfundscheme_id;

          equity_fund1["type"] = "equity";
          debt_fund1["type"] = "debt";

          const equity_lumpsum = equity_fund1.amount_for_goal || 0;
          const equity_sip = equity_fund1.sip_amount_for_goal || 0;

          const debt_lumpsum = debt_fund1.amount_for_goal || 0;
          const debt_sip = debt_fund1.sip_amount_for_goal || 0;

          let orders = [];

          if (equity_lumpsum) {
            orders.push({
              amcfundscheme_id: equity_fund1.amcfundscheme_id,
              investment_type: "lumpsum",
              amount: equity_fund1.amount_for_goal.toFixed(0),
              type: "CRT",
              goal_id: this.state.goal_id,
              bse_minimum_purchase_amount: parseInt(
                equity_fund1.bse_minimum_purchase_amount
              ),
              bse_maximum_purchase_amount: parseInt(
                equity_fund1.bse_maximum_purchase_amount
              ),
              name: equity_fund1.AMCFund__name,
            });
          }

          if (equity_sip) {
            orders.push({
              amount: equity_fund1.sip_amount_for_goal.toFixed(0),
              investment_type: "sip",
              type: "CRT",
              amcfundscheme_id: equity_fund1.amcfundscheme_id,
              sip_type: "",
              sip_frequency_type: "monthly",
              sip_start_date: equity_fund1.sip_start_date,
              sip_no_of_installments: equity_fund1.no_of_installments,
              sip_first_order_flag: "Y",
              sip_mandate: null,
              goal_id: this.state.goal_id,
              bse_sip_minimum_installment_amount:
                equity_fund1.bse_sip_minimum_installment_amount,
              name: equity_fund1.AMCFund__name,
            });
          }

          if (debt_lumpsum) {
            orders.push({
              amcfundscheme_id: debt_fund1.amcfundscheme_id,
              investment_type: "lumpsum",
              amount: debt_fund1.amount_for_goal.toFixed(0),
              type: "CRT",
              goal_id: this.state.goal_id,
              bse_minimum_purchase_amount: parseInt(
                debt_fund1.bse_minimum_purchase_amount
              ),
              bse_maximum_purchase_amount: parseInt(
                debt_fund1.bse_maximum_purchase_amount
              ),
              name: debt_fund1.AMCFund__name,
            });
          }

          if (debt_sip) {
            orders.push({
              amount: debt_fund1.sip_amount_for_goal.toFixed(0),
              investment_type: "sip",
              type: "CRT",
              amcfundscheme_id: debt_fund1.amcfundscheme_id,
              sip_type: "",
              sip_frequency_type: "monthly",
              sip_start_date: debt_fund1.sip_start_date,
              sip_no_of_installments: debt_fund1.no_of_installments,
              sip_first_order_flag: "Y",
              sip_mandate: null,
              goal_id: this.state.goal_id,
              bse_sip_minimum_installment_amount:
                debt_fund1.bse_sip_minimum_installment_amount,
              name: debt_fund1.AMCFund__name,
            });
          }

          data.push(equity_fund1);
          data.push(debt_fund1);
          this.setState({
            data,
            orders,
            isLoading: false,
            portfolio_data,
            ...remainingData,
            error: false,
            equity_lumpsum,
            equity_sip,
            debt_lumpsum,
            debt_sip,
            goal,
            goal_id,
            amount,
            mfMinDetailsData: mfMinDetailsData || {},
            showComplianceAlert: mfMinDetailsData != null,
            gettingMinDetails: false,
          });
          return;
        }
      }
      this.setState({ error: true, isLoading: false });
    } catch (error) {
      this.setState({ error: true, isLoading: false });
    }
  }

  deleteSuggestion = (fundId, investmentType) => {
    try {
      let { orders } = this.state;
      const current_type_orders = orders.filter((item) => {
        return item.amcfundscheme_id == fundId;
      });

      if (current_type_orders && current_type_orders.length == 1) {
        this.setState({
          error: true,
          errorText: "Atleast one order should be present",
        });
        return;
      }

      const remainingOrders = orders.filter((item) => {
        return !(
          item.amcfundscheme_id == fundId &&
          item.investment_type == investmentType
        );
      });
      this.setState({ orders: remainingOrders });
    } catch (error) {
      this.setState({ error: true, errorText: "Error while deleting fund!" });
    }
  };

  updateState = (type, index, amount, error) => {
    let { data } = this.state;
    if (error) alert(error);

    if (type == "SIP") key = "sip_value";
    else key = "lumpsum_value";
    data[index][key] = error ? 0 : amount;
    this.setState({ data });
  };

  _toggleModal = (fundId) => {
    this.setState({
      visibleModal: !this.state.visibleModal,
      activeFundId: fundId,
    });
  };

  handleChange = (fundId, investmentType, changeType, amount) => {
    let { orders } = this.state;
    const index = orders.findIndex((item) => {
      return (
        item.amcfundscheme_id == fundId &&
        item.investment_type == investmentType
      );
    });
    if (changeType == "AMOUNT") {
      orders[index]["amount"] = amount;
      orders[index]["error"] = null;
    }
    this.setState({ orders });
  };

  _renderFund = (item, index, orders) => {
    if (item) {
      return (
        <JamaMutualMF
          data={item}
          fundId={item.fund_id}
          orders={orders}
          type={item.type}
          handleChange={(fundId, investmentType, actionType, amount, error) => {
            this.handleChange(
              fundId,
              investmentType,
              actionType,
              amount,
              error
            );
          }}
          getSelectedDate={(fundId) => {
            return this.getSelectedDate(fundId);
          }}
          lumpSumValue={
            item.type == "equity"
              ? this.state.equity_lumpsum || 0
              : this.state.debt_lumpsum || 0
          }
          sipValue={
            item.type == "equity"
              ? this.state.equity_sip || 0
              : this.state.debt_sip || 0
          }
          index={index}
          onSIPChange={(index, amount, error) => {
            this.updateState("SIP", index, amount, error);
          }}
          onLumpsumChange={(index, amount, error) => {
            this.updateState("Lumpsum", index, amount, error);
          }}
          deleteSuggestion={this.deleteSuggestion}
          toggleModal={this._toggleModal}
        />
      );
    }
    return null;
  };

  itemSeparator = () => {
    return <Divider width={1} color="#F2F2F2" style={{ marginVertical: 10 }} />;
  };

  _setNextInstallmentData = (value, fundId) => {
    let { orders } = this.state;
    const index = orders.findIndex((item) => {
      return item.amcfundscheme_id == fundId && item.investment_type == "sip";
    });
    if (index != -1) {
      let date = orders[index]["sip_start_date"].toString();
      date = date.split("-");
      orders[index]["sip_start_date"] = date[0] + "-" + date[1] + "-" + value;
    }
    this.setState({ orders });
  };

  getSelectedDate = (fundId) => {
    let { orders } = this.state;
    const index = orders.findIndex((item) => {
      return item.amcfundscheme_id == fundId && item.investment_type == "sip";
    });
    if (index != -1) {
      let date = orders[index]["sip_start_date"].toString();
      date = date.split("-");
      let day = parseInt(date[2]);
      return day;
    }
    return -1;
  };

  _renderModalContent = () => {
    const { activeFundId } = this.state;

    if (activeFundId != undefined) {
      const selected = this.getSelectedDate(activeFundId);
      return (
        <View style={styles.modalContent}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                alignSelf: "flex-start",
                fontSize: 16,
                fontWeight: "bold",
                flex: 0.9,
              }}
            >
              Select Date for Next Installment
            </Text>
            <View
              style={{
                alignItems: "flex-end",
                flex: 0.1,
              }}
            >
              <ANTIcon
                name="closecircle"
                color={"#121212"}
                size={14}
                style={{}}
                onPress={() => {
                  this._toggleModal(null);
                }}
              />
            </View>
          </View>
          <Divider width={1} color="#F2F2F2" style={{ marginVertical: 10 }} />
          <DateDays
            selected={selected}
            onPress={(value) => {
              this._setNextInstallmentData(value, activeFundId);
            }}
            style={{
              alignSelf: "center",
            }}
          />
        </View>
      );
    }
    return null;
  };

  onPressNext = async () => {
    try {
      let { orders } = this.state;
      let flag = false;
      orders.forEach((order) => {
        if (
          order.investment_type == "sip" &&
          order.bse_sip_minimum_installment_amount &&
          order.bse_sip_minimum_installment_amount > parseInt(order.amount)
        ) {
          order["error"] =
            "Minimum SIP amount is " +
            order.bse_sip_minimum_installment_amount +
            " for " +
            order.name;
          flag = true;
          return;
        }
        if (order.investment_type == "lumpsum") {
          if (
            parseInt(order.bse_minimum_purchase_amount) &&
            parseInt(order.bse_minimum_purchase_amount) > order.amount
          ) {
            order["error"] =
              "Minimum amount to purchase is ₹" +
              numberWithCommas(order.bse_minimum_purchase_amount) +
              " for " +
              order.name;
            flag = true;
            return;
          }
          if (
            parseInt(order.bse_maximum_purchase_amount) > 0 &&
            parseInt(order.bse_maximum_purchase_amount) < order.amount
          ) {
            order["error"] =
              "Maximum amount to purchase is ₹" +
              numberWithCommas(order.bse_maximum_purchase_amount) +
              " for " +
              order.name;
            flag = true;
            return;
          }
        }
        if (order.amount == 0) {
          order["error"] = "Amount is empty!";
          flag = true;
          return;
        }

        order["error"] = null;
      });

      if (flag) {
        this.setState({ orders });
        return;
      }

      this.setState({ loading: true, orders });

      const ordersData = orders
        .map((item) => {
          if (item.investment_type == "lumpsum") {
            return {
              scheme: item.amcfundscheme_id,
              amount: Number(item.amount),
              investmentType: "lumpsum",
            };
          } else if (item.investment_type == "sip") {
            return {
              scheme: item.amcfundscheme_id,
              amount: Number(item.amount),
              sip_frequency_type: item.sip_frequency_type,
              sip_start_date: item.sip_start_date,
              sip_no_of_installments: Number(item.sip_no_of_installments),
              investmentType: "sip",
              sip_first_order_flag: "Y",
            };
          }

          return null;
        })
        .filter((order) => order);

      const payload = {
        orders: ordersData,
        redirection_url:
          (Platform.OS == "web"
            ? global.location.origin
            : "https://app.jamawealth.com") +
          "/dashboard/process/" +
          this.state.actionId,
      };

      const response = await createOrderWithFirstInstallment(payload);
      if (response) {
        const { navigate_to, code, nav_props, ...restData } = response;
        Store.dispatch({
          type: "UPDATE_ACTION",
          payload: {
            key: this.state.actionId,
            data: {
              ...restData,
              ...nav_props,
            },
          },
        });
        if (response.code && response.code == 100) {
          if (response.success > 0) {
            const { url } = nav_props;
            if (response.navigate_to == "ShowBankAccount") {
              this.props.navigation.dispatch(
                StackActions.replace("App", {
                  screen: "Dashboard",
                  params: {
                    screen: "Explore",
                    params: {
                      screen: "ShowBankAccount",
                      params: {
                        action: this.state.actionId,
                      },
                    },
                  },
                })
              );
              return;
            }
            if (Platform.OS == "web") {
              global.open(url, "_self");
            } else {
              this.setState({
                isLoading: false,
              });
              this.props.navigation.navigate("App", {
                screen: "Dashboard",
                params: {
                  screen: "Portfolio",
                  params: {
                    screen: "ShowWebView",
                    params: {
                      url: url,
                      successURL: "/payment/success",
                      onSuccess: function (navigation, results = {}) {
                        navigation.push("App", {
                          screen: "Dashboard",
                          params: {
                            screen: "ProcessOrder",
                            params: { action_id: this.state.actionId },
                          },
                        });
                      },
                      onError: function (navigation, results = {}) {
                        // navigation.push("App", { screen: "Dashboard", params: { screen: "Home", params: {} } });
                      },
                    },
                  },
                },
              });
            }
          } else {
            this.setState({
              isLoading: false,
            });
            if (response.navigate_to == "ShowCreatedOrders") {
              this.props.navigation.navigate("App", {
                screen: "Dashboard",
                params: {
                  screen: "Dashboard",
                  params: {
                    screen: "ProcessOrder",
                    params: { action_id: this.state.actionId },
                  },
                },
              });
            }
          }
        }
      }

      this.setState({ loading: false });
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  handleCloseComplianceAlert = () => {
    const { navigation, route = {} } = this.props;
    const { params } = route;
    let skipped_compliance = false;
    let fundId = null;
    if (params) {
      skipped_compliance = params.s || false;
      fundId = params.fund || null;
    }
    if (!skipped_compliance && navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.dispatch(
        StackActions.replace("App", {
          screen: "Dashboard",
          params: {
            screen: "Goals",
            params: {
              screen: "InvestGoal",
              params: {
                action: this.state.actionId,
              },
            },
          },
        })
      );
    }
  };

  render() {
    const { isLoading } = this.state;
    let { target_date, goal } = this.state;
    if (!isLoading && target_date) {
      target_date = target_date.split("T");
      target_date = target_date[0].split("-");
      const month = months_short_names(parseInt(target_date[1]), true);
      target_date = month + " " + target_date[0];
    } else {
      target_date = null;
    }
    return (
      <Fragment>
        {this.state.showComplianceAlert && (
          <ComplianceModal
            forceLoad={true}
            navigation={this.props.navigation}
            route={this.props.route}
            isLoading={this.state.gettingMinDetails}
            minDetailsResponse={this.state.mfMinDetailsData}
            onClose={this.handleCloseComplianceAlert}
            userId={this.state.userId}
            navigationOptions={{
              action: this.state.actionId,
              stack: "Goals",
              screen: "InvestGoal",
            }}
          />
        )}
        <SafeAreaView style={{}} />
        <Modal
          isVisible={this.state.visibleModal}
          style={styles.bottomModal}
          onBackdropPress={() => {
            this.setState({ visibleModal: false });
          }}
          animationInTiming={100}
          animationOutTiming={100}
          backdropTransitionInTiming={100}
          backdropTransitionOutTiming={100}
        >
          {this._renderModalContent()}
        </Modal>
        <SafeAreaView style={styles.container}>
          <ScrollView>
            <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={[goal ? goal.name : "Back"]}
              onPressBack={() => {
                this.props.navigation.goBack();
              }}
            />
            {!this.state.isLoading ? (
              <>
                {this.state.goal_id &&
                  (isMobile ? (
                    <>
                      <View
                        style={{
                          width: "98%",
                          marginLeft: "1%",
                          marginRight: "1%",
                          marginTop: "2%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            paddingHorizontal: 16,
                            paddingVertical: 10,
                          }}
                        >
                          <View style={{ flex: 0.7 }}>
                            <Image
                              style={{
                                width: 120,
                                height: 20,
                                marginBottom: 10,
                                alignSelf: "flex-start",
                              }}
                              resizeMode="contain"
                              source={require("../../../assets/jamalogo_home.png")}
                            />
                            <Text style={{ fontSize: 14, color: "#121212" }}>
                              Invest in Jamā Wealth Equity Portfolio.
                            </Text>
                          </View>
                          <View
                            style={{
                              flex: 0.3,
                              justifyContent: "flex-start",
                              alignItems: "flex-end",
                            }}
                          >
                            <Text
                              style={[
                                {
                                  color: "#410DAA",
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  textDecorationLine: "underline",
                                  paddingRight: "15%",
                                },
                              ]}
                              onPress={() => {
                                this.props.navigation.navigate("Account", {
                                  screen: "MySubscriptionPlanPage",
                                  params: { plan: "jewel" },
                                });
                              }}
                            >
                              Know More
                            </Text>
                          </View>
                        </View>

                        <SectionHeading
                          title={"Mutual Funds suggested by Jama"}
                          style={{
                            paddingHorizontal: 16,
                            backgroundColor: "white",
                          }}
                        />
                        <FlatList
                          data={this.state.data}
                          extraData={[]}
                          keyExtractor={(item, index) => index}
                          renderItem={({ item, index }) =>
                            this._renderFund(item, index, this.state.orders)
                          }
                          ItemSeparatorComponent={this.itemSeparator}
                          showsVerticalScrollIndicator={true}
                          contentContainerStyle={{
                            flex: 1,
                            paddingHorizontal: 16,
                          }}
                        />
                      </View>
                      <View
                        style={{
                          width: "98%",
                          marginLeft: "1%",
                          marginRight: "1%",
                          marginTop: "2%",
                        }}
                      >
                        <SectionHeading
                          title={"Summary"}
                          style={{
                            paddingHorizontal: 16,
                            marginVertical: 0,
                            paddingTop: -20,
                            backgroundColor: "white",
                          }}
                        />
                        <View
                          style={{
                            paddingHorizontal: 16,
                          }}
                        >
                          <TodayCost
                            title="Target"
                            subtitle={target_date ? "by " + target_date : ""}
                            value={
                              this.state.target
                                ? `${
                                    theme.variables.RUPEE_SYMBOL
                                  }${numberFormat(
                                    this.state.target.toFixed(0)
                                  )}`
                                : "-"
                            }
                            style={{
                              margin: 0,
                            }}
                          />
                          <TodayCost
                            title="Projected"
                            subtitle={target_date ? "by " + target_date : ""}
                            value={`${
                              theme.variables.RUPEE_SYMBOL
                            }${numberFormat(
                              this.state.projected_value.toFixed(0)
                            )}`}
                            style={{ margin: 0 }}
                          />
                          <TodayCost
                            title="Deficit"
                            subtitle={target_date ? "by " + target_date : ""}
                            value={`${
                              theme.variables.RUPEE_SYMBOL
                            }${numberFormat(this.state.deficit.toFixed(0))}`}
                            style={{ margin: 0, marginBottom: 15 }}
                          />
                        </View>
                        <SectionHeading
                          title={"Allocation"}
                          style={{
                            paddingHorizontal: 16,
                            backgroundColor: "white",
                          }}
                        />
                        <View style={{ paddingHorizontal: 16 }}>
                          <TodayCost
                            title="Fresh Investment"
                            value={`${
                              theme.variables.RUPEE_SYMBOL
                            }${numberFormat(this.state.amount)}`}
                            style={{}}
                          />
                          <TodayCost
                            title="Suggested SIP"
                            subtitle={target_date ? "till " + target_date : ""}
                            value={`${
                              theme.variables.RUPEE_SYMBOL
                            }${numberFormat(
                              this.state.monthly_installment_suggested.toFixed(
                                0
                              )
                            )}`}
                            style={{}}
                          />
                          <AllocationStatus
                            heading="Existing Allocation"
                            equityPercent={
                              this.state.portfolio_data.equity_perc > 100
                                ? 100
                                : this.state.portfolio_data.equity_perc.toFixed(
                                    2
                                  )
                            }
                            style={{}}
                          />
                          <AllocationStatus
                            heading="Allocation after Fresh Investments"
                            equityPercent={
                              this.state.portfolio_data.new_equity_perc > 100
                                ? 100
                                : this.state.portfolio_data.new_equity_perc.toFixed(
                                    2
                                  )
                            }
                            style={{ marginTop: 20 }}
                          />
                        </View>

                        <Text
                          style={{
                            fontSize: 11,
                            color: "#707070",
                            paddingHorizontal: 16,
                            paddingVertical: 10,
                            marginBottom: 5,
                          }}
                        >
                          Suggested as per your risk profile - Equity{" "}
                          <Text style={{ fontWeight: "500", color: "#121212" }}>
                            {this.state.portfolio_data.suggested_equity_perc.toFixed(
                              1
                            )}
                            %
                          </Text>{" "}
                          Non-Equity{" "}
                          <Text style={{ fontWeight: "500", color: "#121212" }}>
                            {this.state.portfolio_data.suggeted_non_equity_perc.toFixed(
                              1
                            )}
                            %
                          </Text>
                        </Text>
                      </View>
                    </>
                  ) : (
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View
                        style={{
                          width: "48%",
                          marginLeft: "2%",
                          marginTop: "2%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            paddingHorizontal: 16,
                            paddingVertical: 10,
                          }}
                        >
                          <View style={{ flex: 0.7 }}>
                            <Image
                              style={{
                                width: 120,
                                height: 20,
                                marginBottom: 10,
                                alignSelf: "flex-start",
                              }}
                              resizeMode="contain"
                              source={require("../../../assets/jamalogo_home.png")}
                            />
                            <Text style={{ fontSize: 14, color: "#121212" }}>
                              Invest in Jamā Wealth Equity Portfolio.
                            </Text>
                          </View>
                          <View
                            style={{
                              flex: 0.3,
                              justifyContent: "flex-start",
                              alignItems: "flex-end",
                            }}
                          >
                            <Text
                              style={[
                                {
                                  color: "#410DAA",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  fontSize: 14,
                                  textDecorationLine: "underline",
                                  paddingRight: "25%",
                                },
                              ]}
                              onPress={() => {
                                this.props.navigation.navigate("Account", {
                                  screen: "MySubscriptionPlanPage",
                                  params: { plan: "jewel" },
                                });
                              }}
                            >
                              Know More
                            </Text>
                          </View>
                        </View>

                        <SectionHeading
                          title={"Mutual Funds suggested by Jama"}
                          style={{
                            paddingHorizontal: 16,
                            backgroundColor: "white",
                          }}
                        />
                        <FlatList
                          data={this.state.data}
                          extraData={[]}
                          keyExtractor={(item, index) => index}
                          renderItem={({ item, index }) =>
                            this._renderFund(item, index, this.state.orders)
                          }
                          ItemSeparatorComponent={this.itemSeparator}
                          showsVerticalScrollIndicator={true}
                          contentContainerStyle={{
                            flex: 1,
                            paddingHorizontal: 16,
                          }}
                        />
                      </View>
                      <View
                        style={{
                          width: "48%",
                          marginRight: "2%",
                          marginTop: "1.4%",
                        }}
                      >
                        <SectionHeading
                          title={"Summary"}
                          style={{
                            paddingHorizontal: 16,
                            marginVertical: 0,
                            paddingTop: -20,
                            backgroundColor: "white",
                          }}
                        />
                        <View
                          style={{
                            paddingHorizontal: 16,
                          }}
                        >
                          <TodayCost
                            title="Target"
                            subtitle={target_date ? "by " + target_date : ""}
                            value={
                              this.state.target
                                ? `${
                                    theme.variables.RUPEE_SYMBOL
                                  }${numberFormat(
                                    this.state.target.toFixed(0)
                                  )}`
                                : "-"
                            }
                            style={{
                              margin: 0,
                            }}
                          />
                          <TodayCost
                            title="Projected"
                            subtitle={target_date ? "by " + target_date : ""}
                            value={`${
                              theme.variables.RUPEE_SYMBOL
                            }${numberFormat(
                              this.state.projected_value.toFixed(0)
                            )}`}
                            style={{ margin: 0 }}
                          />
                          <TodayCost
                            title="Deficit"
                            subtitle={target_date ? "by " + target_date : ""}
                            value={`${
                              theme.variables.RUPEE_SYMBOL
                            }${numberFormat(this.state.deficit.toFixed(0))}`}
                            style={{ margin: 0, marginBottom: 15 }}
                          />
                        </View>
                        <SectionHeading
                          title={"Allocation"}
                          style={{
                            paddingHorizontal: 16,
                            backgroundColor: "white",
                          }}
                        />
                        <View style={{ paddingHorizontal: 16 }}>
                          <TodayCost
                            title="Fresh Investment"
                            value={`${
                              theme.variables.RUPEE_SYMBOL
                            }${numberFormat(this.state.amount)}`}
                            style={{}}
                          />
                          <TodayCost
                            title="Suggested SIP"
                            subtitle={target_date ? "till " + target_date : ""}
                            value={`${
                              theme.variables.RUPEE_SYMBOL
                            }${numberFormat(
                              this.state.monthly_installment_suggested.toFixed(
                                0
                              )
                            )}`}
                            style={{}}
                          />
                          <AllocationStatus
                            heading="Existing Allocation"
                            equityPercent={
                              this.state.portfolio_data.equity_perc > 100
                                ? 100
                                : this.state.portfolio_data.equity_perc.toFixed(
                                    2
                                  )
                            }
                            style={{}}
                          />
                          <AllocationStatus
                            heading="Allocation after Fresh Investments"
                            equityPercent={
                              this.state.portfolio_data.new_equity_perc > 100
                                ? 100
                                : this.state.portfolio_data.new_equity_perc.toFixed(
                                    2
                                  )
                            }
                            style={{ marginTop: 20 }}
                          />
                        </View>

                        <Text
                          style={{
                            fontSize: 11,
                            color: "#707070",
                            paddingHorizontal: 16,
                            paddingVertical: 10,
                            marginBottom: 5,
                          }}
                        >
                          Suggested as per your risk profile - Equity{" "}
                          <Text style={{ fontWeight: "500", color: "#121212" }}>
                            {this.state.portfolio_data.suggested_equity_perc.toFixed(
                              1
                            )}
                            %
                          </Text>{" "}
                          Non-Equity{" "}
                          <Text style={{ fontWeight: "500", color: "#121212" }}>
                            {this.state.portfolio_data.suggeted_non_equity_perc.toFixed(
                              1
                            )}
                            %
                          </Text>
                        </Text>
                      </View>
                    </View>
                  ))}
              </>
            ) : (
              <ActivityIndicator size="large" color="#410DAA" />
            )}

            <Text style={[styles.errorText, { alignSelf: "center" }]}>
              {this.state.error
                ? this.state.errorText || "Something went wrong!"
                : " "}
            </Text>
            {this.state.goal_id && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "30%",
                  marginLeft: "10%",
                  marginRight: "60%",
                }}
              >
                {!isMobile && (
                  <CustButton
                    bgColor={"#410DAA"}
                    textColor={"#fff"}
                    style={{
                      marginVertical: 5,
                      flex: 0.45,
                      marginLeft: 15,
                      paddingVertical: 15,
                      alignSelf: "center",
                    }}
                    borderColor={"transparent"}
                    buttonTextStyle={{
                      fontSize: 14,
                    }}
                    isLoading={this.state.loading}
                    onPress={() => this.onPressNext(false)}
                  >
                    Buy Now
                  </CustButton>
                )}
              </View>
            )}
            <SuccessOrFailureModal
              visible={this.state.showSuccessOrErrorFailureModal}
              status={this.state.responseStatus}
              title={this.state.responseStatus ? "Success" : "Failed"}
              message={this.state.responseMessage}
              buttons={this.state.modal_buttons}
              messageStyle={{
                marginBottom: 50,
              }}
            />
          </ScrollView>
          {isMobile && (
            <CustButton
              bgColor={"#410DAA"}
              textColor={"#fff"}
              style={{
                marginBottom: 66,
                width: "98%",
                marginLeft: "1%",
                alignSelf: "center",
              }}
              borderColor={"transparent"}
              buttonTextStyle={{
                fontSize: 14,
              }}
              isLoading={this.state.loading}
              onPress={() => this.onPressNext(false)}
            >
              Buy Now
            </CustButton>
          )}
        </SafeAreaView>
      </Fragment>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    maxWidth: "100%",
  },
  bottomModal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: 22,
    justifyContent: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  errorText: {
    color: "red",
    fontSize: 14,
  },
});
