import axiosApiInstance from "./apiConfig";
import { URLS } from "./urls";

export const getTrackOrders = async (data) => {
  const result = await axiosApiInstance.get(URLS.ALERTS_TRACKORDERS);
  return result;
};

export const getOrderDetails = async (order_id) => {
  const url = URLS.ALERTS_ORDER_DETAILS + order_id;
  const result = await axiosApiInstance.get(url);
  return result;
};

export const getAlertsCount = async () => {
  const result = await axiosApiInstance.get(URLS.ALERTS_COUNT);
  return result;
};

export const getNotifications = async () => {
  const result = await axiosApiInstance.get(URLS.ALERTS_NOTIFICATIONS);
  return result;
};

export const clearAllNotifications = async () => {
  const result = await axiosApiInstance.get(URLS.ALERTS_CLEARALLNOTIFICATIONS);
  return result;
};

export const clearNotification = async (payload) => {
  const url = URLS.ALERTS_CLEARSINGLENOTIFICATION;
  const result = await axiosApiInstance.post(url, payload);
  return result;
};

export const getPendingMandates = async () => {
  const result = await axiosApiInstance.get(URLS.ALERTS_PENDINGMANDATES);
  return result;
};

export const getFamilyApprovals = async () => {
  const result = await axiosApiInstance.get(URLS.ALERTS_PENDINGFAMILYAPPROVALS);
  return result;
};

export const grantFamilyAccess = async (payload) => {
  const result = await axiosApiInstance.post(URLS.ALERTS_FAMILYACCESS, payload);
  return result;
};

export const withdrawOrIgnoreFamilyAccess = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.ALERTS_WITHDRAW_IGNORE_FAMILYACCESS,
    payload
  );
  return result;
};

export const getPendingBills = async () => {
  const result = await axiosApiInstance.get(URLS.ALERTS_PENDINGBILLS);
  return result;
};

export const getPendingAttemptedOrders = async () => {
  const result = await axiosApiInstance.get(URLS.ALERTS_PENDINGORDERS);
  return result;
};

export const retryInvestment = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.ALERTS_RETRYINVESTMENT,
    payload
  );
  return result;
};

export const cancelInvestment = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.ALERTS_RETRYINVESTMENT,
    payload
  );
  return result;
};

export const getPendingPayments = async () => {
  const result = await axiosApiInstance.get(URLS.ALERTS_PENDINGPAYEMENTS);
  return result;
};

export const pendingTransactionAction = async (payload) => {
  const result = await axiosApiInstance.post(
    URLS.ALERTS_PENDINGPAYEMENTSACTION,
    payload
  );
  return result;
};

export const getPendingSwitches = async () => {
  const result = await axiosApiInstance.get(URLS.ALERTS_PENDINGSWITCHES);
  return result;
};

export const retrySwitch = async (payload) => {
  const result = await axiosApiInstance.post(URLS.ALERTS_RETRYSWITCH, payload);
  return result;
};

export const cancelSwitch = async (payload) => {
  const result = await axiosApiInstance.post(URLS.ALERTS_CANCELSWITCH, payload);
  return result;
};
