
import React from 'react';
import { Text, StyleSheet} from 'react-native';
import { getProductName } from '../../../utils';

const Product = ({ product }) => {
    return(
        <Text style={styles.product}>{getProductName(product)}</Text>
    )
};

const styles = StyleSheet.create({
    product: {
        fontSize: 13,
        fontFamily: "Roboto",
        color: "#8950FC"
    }
})

const ProductName = React.memo(Product);

export default ProductName;