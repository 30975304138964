import React from "react";
import {
  View,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  Platform,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Text } from "react-native-paper";
import Checkbox from "expo-checkbox";
import amplitude from "../../utils/amplitude";
import { sellStocks, sellMarkComplete } from "../../api";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { isMobile } from "../../utils/index";
import moment from "moment";
import { ShowToast } from "../Common/Toast";

class SellExitStocks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stockList: "",
      showToast: "false",
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("sellExitStocks",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("sellExitStocks", { path: this.props.navigation.getState().routeName });
    }
    let sellStockResp = await sellStocks();
    if (sellStockResp && sellStockResp.code == "100") {
      let newData = [];
      if (sellStockResp.result != "No stocks to exit") {
        sellStockResp.result.forEach((item) => {
          newData.push({ ...item, toShow: false });
        });
      }
      this.setState({ stockList: newData });
    }
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Sell Stocks"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="success"
            title="Selected stocks marked as completed."
          />
        ) : null}
        <ScrollView>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            style={styles.cardContainer}
          >
            <>
              {this.state.stockList &&
                this.state.stockList.map((item, index) => {
                  return (
                    <View
                      style={{
                        marginTop: 20,
                        marginBottom: isMobile ? 80 : 20,
                      }}
                    >
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View
                          style={{
                            marginLeft: "5%",
                            marginRight: "5%",
                            backgroundColor: "white",
                            width: "90%",
                            height: 140,
                            borderWidth: 1,
                            borderRadius: 5,
                            borderColor: "rgba(65, 13, 170, 0.3)",
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              marginLeft: "1.9%",
                              marginRight: "1.9%",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              height: 30,
                              marginTop: 20,
                            }}
                          >
                            <View
                              style={{
                                flex: 1,
                                flexDirection: "column",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox
                                  status={item.toShow ? "checked" : "unchecked"}
                                  color={item.toShow ? "#410DAA" : "gray"}
                                  value={item.toShow}
                                  onValueChange={() => {
                                    let checkbox = this.state.stockList;
                                    checkbox[index].toShow =
                                      !checkbox[index].toShow;
                                    this.setState({ stockList: checkbox });
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 16,
                                    color: "#464E5F",
                                    fontWeight: "700",
                                  }}
                                >
                                  {item.marketscrip__name}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              marginLeft: "5.1%",
                              marginRight: "1.9%",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              height: 80,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "column",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#7e838f",
                                }}
                              >
                                Date
                              </Text>
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#454e60",
                                  fontWeight: "700",
                                }}
                              >
                                {moment().format("DD/MM/YYYY")}
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#7e838f",
                                }}
                              >
                                ISIN Number
                              </Text>
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#454e60",
                                  fontWeight: "700",
                                }}
                              >
                                {item.marketscrip__isin_number}
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#7e838f",
                                }}
                              >
                                Quantity
                              </Text>
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#454e60",
                                  fontWeight: "700",
                                }}
                              >
                                {item.number_of_units_suggested__sum}
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#7e838f",
                                }}
                              >
                                CMP
                              </Text>
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#454e60",
                                  fontWeight: "700",
                                }}
                              >
                                ₹ {item.marketscrip__latest_closing_price}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  );
                })}
              {this.state.stockList && this.state.stockList.length > 0 && (
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    marginRight: "5%",
                    marginTop: "1%",
                  }}
                >
                  <TouchableOpacity
                    onPress={async () => {
                      this.setState({
                        showToast: "false",
                      });
                      let list = [];
                      this.state.stockList.forEach((item) => {
                        if (item.toShow) {
                          list.push({
                            pk: item.marketscrip__pk,
                            quantity: item.number_of_units_suggested__sum,
                            transaction_type: "S",
                            limit_price: item.marketscrip__latest_closing_price,
                          });
                        }
                      });

                      let payload = {
                        reco_list: [...list],
                      };
                      await sellMarkComplete(payload);
                      let sellStockResp = await sellStocks();
                      if (sellStockResp && sellStockResp.code == "100") {
                        let newData = [];
                        if (sellStockResp.result != "No stocks to exit") {
                          sellStockResp.result.forEach((item) => {
                            newData.push({ ...item, toShow: false });
                          });
                        }
                        this.setState({
                          stockList: newData,
                          showToast: "true",
                        });
                      }
                    }}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "#ffffff",
                        paddingVertical: 12,
                        paddingHorizontal: 47,
                        backgroundColor: "#410DAA",
                        borderRadius: 4,
                      }}
                    >
                      Mark As Complete
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
            </>
            {this.state.stockList.length == 0 && (
              <Text
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: "20%",
                  fontWeight: "bold",
                  fontSize: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No Stocks Available To Sell
              </Text>
            )}
          </ScrollView>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    padding: 5,
  },
  modalContentContainer: {
    backgroundColor: "white",
    padding: 8,
    paddingRight: 0,
    borderRadius: 4,
    width: "100%",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default SellExitStocks;
