import React from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import { Text } from "react-native-paper";
import Card from "./card";
import { sm, md, lg, xl, twoxl } from "../../Responsive";
import global from "./globalStyles";

const window = Dimensions.get("window");

export default function StandsOut({ heading }) {
    return (
        <View style={styles.container}>
            <Text
                style={[
                {
                    fontSize: 25,
                    fontWeight: "bold",
                    textAlign: "center",
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 28,
                    color: "black"
                },
                global.arial
                ]}
            >
                {heading ? heading : "How Jamā stands out in the Industry"}
            </Text>
            <View style={styles.cardsContainer}>
                <Card
                imgSource="https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Upgrade/ISO-9001.png"
                sub1="ISO 9001 Certified"
                sub2={["Quality Management System"]}
                link={{
                    text: "View Certificate",
                    url:
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/Website/Certificate+ISO+9001.pdf"
                }}
                ></Card>
                <Card
                imgSource="https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Upgrade/ISO-27001.png"
                sub1="ISO 27001 Certified"
                sub2={["Information Security Management System"]}
                link={{
                    text: "View Certificate",
                    url:
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/Website/Certificate+ISO+27001.pdf"
                }}
                ></Card>
                <Card
                imgSource="https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Upgrade/govt.png"
                sub1="GoI DIPP Registered"
                sub2={[
                    "Department of Industrial Policy and Promotion",
                    "Ministry of Commerce and Industry",
                    "Government of India"
                ]}
                link={{
                    text: "View Registration",
                    url:
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/Website/DIPP27667_SIMPLY_GROW_TECHNOLOGIES_PRIVATE_LIMITED_Recognization.pdf"
                }}
                ></Card>
                <Card
                imgSource="https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Upgrade/SEBI.png"
                sub1="SEBI Registered Investment Advisors"
                sub2={["Securities and Exchange Board of India"]}
                link={{
                    text: "View Certificate",
                    url:
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/Website/simply_grow_technologies_corporate_ria_registration.pdf"
                }}
                isLast
                ></Card>
            </View>
        </View>
    );
}

  const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column"
    },
    cardsContainer: {
        marginTop: md(window.width) ? 81 : undefined,
        flex: md(window.width) ? 1 : undefined,
        flexDirection: md(window.width) ? "row" : undefined,
        justifyContent: md(window.width) ? "space-between" : undefined
    }
});
  





