import { Platform } from "react-native";
// import analytics from '@react-native-firebase/analytics';
import Store from "../store";
const API_KEY = "73b82b230721b66c19dd76253c5a68a9";

const getAmplitudeInstance = async () => {
  if (Platform.OS === "web") {
    return (await import("amplitude-js")).default.getInstance();
  }
  return (
    await import("@amplitude/react-native")
  ).Amplitude.getInstance();
};

export const initAmplitudeInstance = async () => {
  if (Platform.OS === "web") {
    return (await getAmplitudeInstance()).init(API_KEY, null, {});
  }

  return (await getAmplitudeInstance()).init(API_KEY);
};

const setUserId = async (id) => {
  (await getAmplitudeInstance()).setUserId(id);
};

const logEvent = async (eventType, eventProps = {}) => {
  try {
    if (
      Store &&
      Store.getState() &&
      Store.getState().userData &&
      Store.getState().userData.userProfileData &&
      Store.getState().userData.userProfileData.mobilenumber
    ) {
      eventProps["username"] =
        Store.getState().userData.userProfileData.first_name;
      eventProps["mobile"] =
        Store.getState().userData.userProfileData.mobilenumber;
      eventProps["email"] = Store.getState().userData.userProfileData.email;
      eventProps["customer_id"] =
        Store.getState().userData.userProfileData.customer_id;
    }
    (await getAmplitudeInstance()).logEvent(
      eventType,
      Object.assign(eventProps)
    );
    // await analytics().logEvent(eventType, Object.assign(eventProps));
  } catch (error) {
    console.log("there is issue", error);
  }
};

export default { setUserId, logEvent };
