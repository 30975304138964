import React from "react";
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  ScrollView,
  Platform,
  Image,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { md } from "../../Responsive";
import { formatDate, isMobile, reverseString } from "../../utils";
import { AntDesign } from "@expo/vector-icons";
import {
  getDefaultTabToShowTransactionStatus,
  getInvestmentTransactionStatus,
  getRebalanceTransactionStatus,
} from "../../api";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../api/apiConfig";
import { MAIN_URL } from "../../api/urls";
import PickerFieldSelect from "../Common/Pickers/PickerFieldSelect";
import NavigationHeader from "../Common/NavigationHeader";

import amplitude from "../../utils/amplitude";
import Store from "../../store";

const window = Dimensions.get("window");
const { width } = window;

const TableHeader = ({ value, containerStyle = {}, style = {} }) => {
  return (
    <View style={containerStyle}>
      <Text
        style={[
          {
            fontSize: 12,
            color: "#B5B5C3",
            paddingHorizontal: 0,
            fontWeight: "bold",
            textAlign: "center",
          },
          style,
        ]}
      >
        {value}
      </Text>
    </View>
  );
};

const TableCell = ({ value = null, style = {}, children }) => {
  return (
    <View style={style}>
      {value != null && (
        <Text
          style={[
            {
              fontSize: 14,
              color: "#464E5F",
              paddingHorizontal: 16,
              textAlign: "center",
            },
            style,
          ]}
        >
          {value}
        </Text>
      )}
      {children}
    </View>
  );
};

export default class TransactionStatus extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let userId = null;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      data: null,
      tabIndex: 0,
      selectedPicker: "REB",
      userId,
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("ViewTransactionStatus", {
          path: global.location.href,
        });
      } else {
        amplitude.logEvent("ViewTransactionStatus", {
          path: this.props.navigation.getState().routeName,
        });
      }
      let defaultTabToShow;
      const { userId } = this.state;
      const response = await getDefaultTabToShowTransactionStatus(userId);
      if (response && response.code == 100) {
        const { type } = response.result;
        defaultTabToShow = type;
      }

      let transactionStatusResponse;
      if (defaultTabToShow == "INV") {
        transactionStatusResponse = await getInvestmentTransactionStatus(
          userId
        );
      } else {
        transactionStatusResponse = await getRebalanceTransactionStatus(userId);
      }

      this.setState(
        {
          selectedPicker: defaultTabToShow,
        },
        () => {
          this.setData(transactionStatusResponse);
        }
      );
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong",
      });
    }
  }

  setData = (response) => {
    if (response.code == 100) {
      let { result } = response;
      if (result.orders_placed) {
        this.setState(
          {
            data: result,
            isLoading: false,
          },
          () => {
            this.getFilterData(this.state.tabIndex, true);
          }
        );
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "No transaction Found",
        });
      }
    } else {
      this.setState({
        isLoading: false,
        error: true,
        errorText:
          response && response.msg ? response.msg : "Something went wrong!",
      });
    }
  };

  getFilterData = (index, isFirstTimeLoad = false) => {
    const isWeb = md(width);
    let filteredData = [];
    let tabIndex = index;
    const { data } = this.state;
    if (isWeb) {
      filteredData = [...data.stocks];
    } else {
      let filterBy = index == 0 ? "S" : "B";
      filteredData = data.stocks.filter((item) => {
        return item["txn_type"] == filterBy;
      });
      if (isFirstTimeLoad) {
        if (filteredData.length == 0) {
          if (filterBy == "S") filterBy = "B";
          filteredData = data.stocks.filter((item) => {
            return item["txn_type"] == filterBy;
          });
          if (filteredData.length != 0) {
            tabIndex = 1;
          }
        }
      }
    }

    this.setState({
      filteredData,
      tabIndex,
    });
  };

  confirmBuy = () => {
    this.setState({ showAlert: true });
  };

  renderTableHeader = () => {
    const isWeb = md(width);
    return (
      <View
        style={[
          {
            flexDirection: "row",
            paddingVertical: 11,
            backgroundColor: "#F3F6F9",
            borderRadius: isWeb ? 6 : 0,
            paddingLeft: isWeb ? 10 : 16,
          },
        ]}
      >
        <TableHeader
          value={isWeb ? "Stock Name" : "Stock Details"}
          containerStyle={{ flex: isWeb ? 0.25 : 0.5 }}
          style={[{ color: "#464E5F", textAlign: "left" }]}
        />
        {isWeb && (
          <>
            <TableHeader
              value={"Transaction Date"}
              containerStyle={{ flex: 0.15 }}
            />
            <TableHeader
              value={"Transaction Type"}
              containerStyle={{ flex: 0.15 }}
            />
            <TableHeader
              value={"Suggested Qty"}
              containerStyle={{ flex: 0.15 }}
            />
          </>
        )}
        <TableHeader
          value={"Traded Qty"}
          containerStyle={{ flex: isWeb ? 0.1 : 0.3 }}
          style={[{ paddingHorizontal: isWeb ? 16 : 2 }]}
        />
        <TableHeader
          value={isWeb ? "Pending Qty" : "Pending"}
          containerStyle={{ flex: isWeb ? 0.1 : 0.2 }}
          style={[{ paddingHorizontal: isWeb ? 16 : 2 }]}
        />
        {isWeb && (
          <TableHeader value={"Status"} containerStyle={{ flex: 0.1 }} />
        )}
      </View>
    );
  };

  renderSuggestionItem = ({ item }) => {
    const isWeb = md(width);
    const { txn_type, status } = item;
    const isBuy = txn_type != "S";
    const transactionTypeText = isBuy ? "Buy" : "Sell";
    const statusColor = status == "Fulfilled" ? "#009105" : "#DA222D";
    const statusText =
      status == "Fulfilled" ? "COMPLETE" : status.toUpperCase();
    const txnDate = formatDate(item.txn_date);
    return (
      <View
        style={[
          {
            width: isWeb ? "100%" : width,
            flexDirection: "row",
            paddingVertical: 15,
            paddingLeft: isWeb ? 10 : 16,
          },
        ]}
      >
        <TableCell style={[{ flex: isWeb ? 0.25 : 0.5, flexWrap: "wrap" }]}>
          <Text
            style={{
              fontSize: 14,
              color: "#464E5F",
              fontWeight: "bold",
              marginBottom: 4,
            }}
          >
            {item.name}
          </Text>
          <Text style={{ color: "#9FA2B4", fontSize: 12, marginTop: 4 }}>
            {item.isin}
          </Text>
        </TableCell>
        {isWeb && (
          <>
            <TableCell value={txnDate} style={[{ flex: 0.15 }]} />
            <TableCell style={[{ flex: 0.15 }]}>
              <View
                style={{
                  backgroundColor: isBuy ? "#E8F9F8" : "#FFE2E5",
                  borderRadius: 6,
                  alignSelf: "center",
                  paddingVertical: 7,
                  paddingHorizontal: 15,
                }}
              >
                <Text
                  style={{
                    color: isBuy ? "#1BC5BD" : "#F64E60",
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  {transactionTypeText}
                </Text>
              </View>
            </TableCell>
            <TableCell value={item.suggested_qty} style={[{ flex: 0.15 }]} />
          </>
        )}
        <TableCell
          value={item.traded_qty}
          style={[
            { flex: isWeb ? 0.1 : 0.3, fontWeight: isWeb ? "normal" : "bold" },
          ]}
        />
        <TableCell
          value={item.pending_qty}
          style={[
            { flex: isWeb ? 0.1 : 0.2, fontWeight: isWeb ? "normal" : "bold" },
          ]}
        />
        {isWeb && (
          <TableCell
            value={statusText}
            style={[{ flex: 0.1, fontSize: 10, color: statusColor }]}
          />
        )}
      </View>
    );
  };

  viewDetails = () => {
    this.setState({ viewDetailsOpen: !this.state.viewDetailsOpen });
  };

  closeModal = () => {
    this.setState({ showPopUp: false });
  };

  handleRetry = async () => {
    const { cai_hash } = this.state.data;
    let token = getTokenFromStore();
    token = reverseString(token);
    global.open(
      MAIN_URL +
        "/app/gwsc/retryorder/" +
        (cai_hash + token) +
        "/" +
        GATEWAY_ENVIRONMENT,
      "_self"
    );
  };

  handlePickerChange = (picker) => {
    this.setState(
      {
        selectedPicker: picker,
        isLoading: true,
        error: false,
        errorText: null,
      },
      async () => {
        const { userId } = this.state;
        let response;
        if (picker == "INV") {
          response = await getInvestmentTransactionStatus(userId);
        } else {
          response = await getRebalanceTransactionStatus(userId);
        }
        this.setData(response);
      }
    );
  };

  getIndianFormat = (str) => {
    str = str.split(".");
    return (
      str[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") +
      (str[1] ? "." + str[1] : "")
    );
  };

  roundToDecimals = (value, decimal) => {
    return this.getIndianFormat(value.toFixed(decimal));
  };

  render() {
    const { data, error, errorText, tabIndex, isLoading } = this.state;

    const isWeb = md(width);

    if (error || isLoading) {
      return (
        <>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Transaction Status"]}
            style={styles.headerStyle}
          />
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <View style={{ flex: 1, maxWidth: 1500 }}>
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  backgroundColor: "#fff",
                  maxWidth: 1300,
                  alignItems: "center",
                }}
              >
                {isLoading && (
                  <ActivityIndicator size="large" color="#410DAA" />
                )}
                {!!errorText && (
                  <>
                    <PickerFieldSelect
                      data={[
                        { label: "Rebalance", value: "REB" },
                        { label: "Investment", value: "INV" },
                      ]}
                      value={this.state.selectedPicker}
                      style={{}}
                      onChange={(itemValue) => {
                        this.handlePickerChange(itemValue);
                      }}
                      pickerStyle={[
                        {
                          borderColor: "#00000010",
                          borderRadius: 3,
                          borderWidth: 1,
                          color: "#888C9F",
                        },
                      ]}
                      type="select"
                      placeholder={{
                        label: "Select",
                        value: null,
                        color: "#9EA0A4",
                      }}
                    />
                    {/* <Picker
                    selectedValue={this.state.selectedPicker}
                    onValueChange={(itemValue) => {
                      this.handlePickerChange(itemValue);
                    }}
                    style={{
                      borderColor: "#00000010",
                      borderRadius: 3,
                      borderWidth: 1,
                      color: "#888C9F",
                    }}
                  >
                    <Picker.Item label="Rebalance" value="REB" />
                    <Picker.Item label="Investment" value="INV" />
                  </Picker> */}
                    <Text
                      style={{ fontSize: 16, color: "#121212", marginTop: 20 }}
                    >
                      {errorText || "Something went wrong!"}
                    </Text>
                  </>
                )}
              </View>
            </View>
          </View>
        </>
      );
    }

    const ArrowBackIcon = require("../../assets/JamaInvest/web_transaction_status.png");

    return (
      <>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Transaction Status"]}
          style={styles.headerStyle}
        />
        <View
          style={{ flex: 1, flexDirection: "column", backgroundColor: "#fff" }}
        >
          {data ? (
            <ScrollView style={[styles.mainContainer]}>
              <View>
                <View
                  style={{
                    flex: 1,
                    marginBottom: 31,
                    paddingHorizontal: isWeb ? 0 : 16,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <View>
                    <Text style={styles.portfolioValueText}>
                      Your Transaction:{" "}
                      <Text style={styles.portfolioValueAmount}>
                        ₹{" "}
                        {this.roundToDecimals(
                          Math.abs(data.total_transaction_amount),
                          2
                        )}
                      </Text>
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 11,
                      }}
                    >
                      {!!data.suggested_transaction_amount && (
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            borderRightWidth: 1,
                            borderRightColor: "#00000010",
                            paddingRight: 11,
                          }}
                        >
                          <View
                            style={{
                              width: 9,
                              height: 9,
                              backgroundColor: "#FFA800",
                              borderRadius: 2,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: isMobile ? 11 : 14,
                              color: "#121212",
                              fontFamily: "Roboto",
                              marginLeft: 6,
                            }}
                          >
                            Planned
                          </Text>
                          <Text
                            style={{
                              fontSize: isMobile ? 11 : 14,
                              color: "#410DAA",
                              fontFamily: "Roboto",
                              marginLeft: 6,
                            }}
                          >
                            ₹
                            {this.roundToDecimals(
                              data.suggested_transaction_amount,
                              2
                            )}
                          </Text>
                        </View>
                      )}
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: 14,
                        }}
                      >
                        <View
                          style={{
                            width: 9,
                            height: 9,
                            backgroundColor: "#009105",
                            borderRadius: 2,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: isMobile ? 11 : 14,
                            color: "#121212",
                            fontFamily: "Roboto",
                            marginLeft: 6,
                          }}
                        >
                          Transacted
                        </Text>
                        <Text
                          style={{
                            fontSize: isMobile ? 11 : 14,
                            color: "#410DAA",
                            fontFamily: "Roboto",
                            marginLeft: 6,
                          }}
                        >
                          ₹
                          {this.roundToDecimals(
                            Math.abs(data.total_transaction_amount),
                            2
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <PickerFieldSelect
                    data={[
                      { label: "Rebalance", value: "REB" },
                      { label: "Investment", value: "INV" },
                    ]}
                    value={this.state.selectedPicker}
                    style={{}}
                    onChange={(itemValue) => {
                      this.handlePickerChange(itemValue);
                    }}
                    pickerStyle={[
                      {
                        borderColor: "#00000010",
                        borderRadius: 3,
                        borderWidth: 1,
                        color: "#888C9F",
                      },
                    ]}
                    type="select"
                    placeholder={{
                      label: "Select",
                      value: null,
                      color: "#9EA0A4",
                    }}
                  />
                  {/* <Picker
                  selectedValue={this.state.selectedPicker}
                  onValueChange={(itemValue) => {
                    this.handlePickerChange(itemValue);
                  }}
                  style={{
                    borderColor: "transparent",
                    color: "#888C9F",
                  }}
                >
                  <Picker.Item label="Rebalance" value="REB" />
                  <Picker.Item label="Investment" value="INV" />
                </Picker> */}
                </View>

                {data.pending_transactions != 0 && (
                  <View
                    style={{
                      flex: 1,
                      flexDirection: md(window.width) ? "row" : "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "#8950FC10",
                      borderRadius: 12,
                      paddingHorizontal: 12,
                      marginBottom: 40,
                      paddingVertical: 8,
                    }}
                  >
                    <View style={styles.tableSummary}>
                      <Image
                        source={ArrowBackIcon}
                        style={{
                          width: isWeb ? 43 : 31,
                          height: isWeb ? 37 : 27,
                          marginRight: 8,
                        }}
                      ></Image>
                      {isWeb ? (
                        <View>
                          <Text style={styles.completeTitle}>
                            Complete your pending Transactions
                          </Text>
                          <Text style={styles.pendingStocksList}>
                            {data.pending_transactions} Stocks
                          </Text>
                        </View>
                      ) : (
                        <View style={{ flex: 1, alignSelf: "flex-start" }}>
                          <View style={{ flexWrap: "wrap" }}>
                            <Text
                              style={{
                                color: "#464E5F",
                                fontSize: 13,
                                width: "85%",
                              }}
                            >
                              Complete Your Pending Transactions as on{" "}
                              {formatDate(data.transaction_date)}
                            </Text>
                          </View>
                          <TouchableOpacity onPress={this.handleRetry}>
                            <Text
                              style={{
                                fontSize: 13,
                                color: "#410DAA",
                                fontWeight: "bold",
                                marginTop: 20,
                              }}
                            >{`${this.state.data.transaction_button_text} ->`}</Text>
                          </TouchableOpacity>
                        </View>
                      )}
                    </View>
                    {isWeb && (
                      <View style={styles.retryOrdersButtonContainer}>
                        <TouchableOpacity
                          onPress={this.handleRetry}
                          underlayColor="white"
                          style={{ marginRight: 40 }}
                        >
                          <View style={styles.retryButton}>
                            <Text style={styles.retryButtonText}>
                              {this.state.data.transaction_button_text}
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                )}

                {!isWeb && (
                  <>
                    <View
                      style={{
                        flexDirection: "row",
                        borderBottomColor: "#E5EAEE",
                        borderBottomWidth: 1,
                        marginBottom: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          if (this.state.tabIndex != 0) {
                            this.setState({ tabIndex: 0 }, () => {
                              this.getFilterData(0);
                            });
                          }
                        }}
                      >
                        <Text
                          style={{
                            color: tabIndex == 0 ? "#121212" : "#888C9F",
                            fontSize: 14,
                            paddingHorizontal: 30,
                            paddingVertical: 12,
                            borderBottomWidth: 3,
                            borderBottomColor:
                              tabIndex == 0 ? "#121212" : "transparent",
                          }}
                        >
                          Sell
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          if (this.state.tabIndex != 1) {
                            this.setState({ tabIndex: 1 }, () => {
                              this.getFilterData(1);
                            });
                          }
                        }}
                      >
                        <Text
                          style={{
                            color: tabIndex == 1 ? "#121212" : "#888C9F",
                            fontSize: 14,
                            paddingHorizontal: 30,
                            borderBottomWidth: 3,
                            borderBottomColor:
                              tabIndex == 1 ? "#121212" : "transparent",
                            paddingVertical: 12,
                          }}
                        >
                          Buy
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingHorizontal: 20,
                        marginVertical: 20,
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <Text style={{ fontSize: 15, color: "#212121" }}>
                          Action
                        </Text>
                        <View
                          style={{
                            backgroundColor: "#E8F9F8",
                            paddingHorizontal: 18,
                            paddingVertical: 6,
                            marginLeft: 10,
                            borderRadius: 12,
                          }}
                        >
                          <Text style={{ fontSize: 11, color: "#1BC5BD" }}>
                            {tabIndex == 0 ? "Sell" : "Buy"}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <AntDesign name="calendar" size={16} color="#888C9F" />
                        <Text
                          style={{
                            fontSize: 11,
                            color: "#888C9F",
                            marginLeft: 5,
                          }}
                        >
                          {formatDate(data.transaction_date)}
                        </Text>
                      </View>
                    </View>
                  </>
                )}

                <ScrollView
                  horizontal={true}
                  contentContainerStyle={{ flexGrow: 1 }}
                >
                  <FlatList
                    data={this.state.filteredData}
                    ref={(ref) => {
                      this.suggestionsTable = ref;
                    }}
                    renderItem={this.renderSuggestionItem}
                    keyExtractor={(item, index) => `suggestion_${index}`}
                    ListHeaderComponent={this.renderTableHeader}
                    style={{
                      paddingBottom: 15,
                      marginTop: 5,
                    }}
                  />
                </ScrollView>

                <TouchableOpacity
                  onPress={() => {
                    this.props.navigation.navigate("InvestmentOverview");
                  }}
                  style={{
                    alignSelf: "center",
                    borderWidth: 1,
                    borderColor: "#410DAA",
                    borderRadius: 6,
                    paddingHorizontal: 52,
                    paddingVertical: 11,
                    marginTop: 50,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 15,
                      color: "#410DAA",
                      fontWeight: "bold",
                    }}
                  >
                    View Dashboard
                  </Text>
                </TouchableOpacity>
              </View>
            </ScrollView>
          ) : (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator size="large" color="#410DAA" />
            </View>
          )}
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: md(window.width) ? 52 : 0,
    paddingRight: md(window.width) ? 52 : 0,
    paddingTop: 30,
    maxWidth: 1300,
  },
  confirmButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    justifyContent: "space-around",
    flex: 1,
  },
  confirmButton: {
    paddingHorizontal: 20,
    paddingVertical: 6,
    borderRadius: 6,
    alignItems: "center",
    flex: 1,
    marginHorizontal: 10,
  },
  confirmButton2: {
    paddingTop: 6,
    borderRadius: 6,
    width: "100%",
  },
  confirmButtonNo: {
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
  },
  noText: {
    color: "#410DAA",
    fontSize: 15,
  },
  confirmButtonYes: {
    backgroundColor: "#410DAA",
  },
  yesText: {
    color: "#fff",
    fontSize: 15,
  },
  border: {
    borderColor: "#968E8E40",
    borderWidth: 1,
    borderRadius: 4,
    overflow: "hidden",
    borderTopColor: "#410DAA",
    borderTopWidth: 4,
  },
  retryOrdersButtonContainer: {
    paddingVertical: md(window.width) ? 0 : 35,
  },
  retryButton: {
    paddingHorizontal: md(window.width) ? 50 : 35,
    paddingVertical: md(window.width) ? 11 : 9,
    backgroundColor: "#410DAA",
    marginVertical: 2,
    borderRadius: 6,
    width: "100%",
    alignSelf: "flex-end",
  },
  retryButtonText: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#fff",
    textAlign: "center",
  },
  tableSummary: {
    flexDirection: "row",
    paddingVertical: md(window.width) ? 19 : 8,
    flex: md(window.width) ? 0.7 : 1,
  },
  amountContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  completeTitle: {
    fontSize: md(window.width) ? 16 : 15,
    fontWeight: "bold",
    color: "#212121",
  },
  amount: {
    fontSize: 17,
    fontWeight: "700",
    color: "#410DAA",
    fontStyle: "italic",
    paddingLeft: 5,
  },
  pendingStocksList: {
    paddingTop: 10,
    fontSize: md(window.width) ? 14 : 12,
    color: "#410DAA",
  },
  button: {
    alignItems: "center",
    backgroundColor: "#410DAA",
    marginVertical: 2,
    borderRadius: 6,
  },
  button2: {
    alignItems: "center",
    backgroundColor: "#410DAA",
    marginVertical: 2,
  },
  buttonText: {
    textAlign: "center",
    paddingHorizontal: 30,
    paddingVertical: 12,
    color: "white",
    fontWeight: "700",
    fontSize: 14,
  },

  portfolioValueText: {
    fontSize: md(width) ? 18 : 14,
    color: "#212121",
  },

  portfolioValueAmount: {
    fontSize: 16,
    color: "#410DAA",
    fontStyle: "italic",
    paddingLeft: 5,
    fontWeight: "700",
  },

  circle1: {
    width: 16,
    height: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#410DAA",
    alignItems: "center",
    justifyContent: "center",
  },
  circle2: {
    width: 10,
    height: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#410DAA",
  },
  step: { fontSize: 16, color: "#212121", marginLeft: 9, fontWeight: "bold" },
  expectedAmountContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: md(window.width) ? 35 : 5,
    paddingVertical: 10,
    backgroundColor: "#F3F6F9",
    alignItems: "center",
  },
  expectedAmount: {},
  expectedAmountText: {
    color: "#464E5F",
    fontSize: 12,
    fontWeight: "bold",
  },
  planwiseSellText: {
    fontSize: 12,
    color: "#410DAA",
  },

  notesContainer: {
    marginTop: 15,
    marginBottom: 50,
  },
  note: {
    fontSize: 12,
    color: "#12121290",
    fontStyle: "italic",
  },
  note1: {
    marginBottom: 20,
  },
  note2: {
    marginBottom: 20,
  },
  note3: {
    marginBottom: 20,
    color: "#121212",
  },
  undeline: {
    textDecorationLine: "underline",
    color: "#121212",
  },
  notePoint: {
    marginTop: 8,
  },
});
