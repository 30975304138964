import React from "react";
import { View, Text, StyleSheet, Platform,ScrollView } from "react-native";
import Table from "../../components/Common/Table";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { capitalizeFirstLetter, formatDate, getUserDetails, isMobile, numberWithCommas } from "../../utils";
import { getTransactionDetails } from "../../api/billing";
import { ActivityIndicator } from "react-native-paper";
import HoldingsTable from "./HoldingsTable";
// import ReactExport from "react-export-excel";
import moment from "moment";
import SearchBar from "../../components/Common/SearchBar";
import { getCustomerDetails } from "../../api";
import Store from "../../store";
import amplitude from "../../utils/amplitude";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class TransactionDetails extends React.Component {
  constructor(props) {
    super(props);
    let bill_id, date, user_id;
    const { params } = this.props.route;
    if (params) {
      bill_id = params.bill_id || null;
      date = params.date || null;
      user_id = params.user_id || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      bill_id,
      date,
      isLoading: true,
      user_id
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("TransactionDetails",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("TransactionDetails", { path: this.props.navigation.getState().routeName });
      }
      if (Platform.OS == "web"){
        amplitude.logEvent("paymentRedirection",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("paymentRedirection", { path: this.props.navigation.getState().routeName });
      }
      const response = await getTransactionDetails(
        this.state.bill_id,
        this.state.date
      );
      if (response && response.code == 100) {
        const { results = [] } = response;
        const { date: transactions_as_on } = results[0];
        const { transactions = [] } = results[1];
        const data = {
          transactions_as_on,
          transactions,
        };
        this.setState({ data, dataBackup: data, isLoading: false });

        let userData = {};
        if (this.state.user_id) {
          userData = await getCustomerDetails(this.state.user_id);
        } else {
          userData = await getUserDetails();
        }
        let { first_name } = userData;
        let names = first_name ? first_name.split(/\s+/) : [];
        let name = "";
        if (names.length >= 2 && names.length > 0) {
          name =
            capitalizeFirstLetter(names[0]) +
            " " +
            capitalizeFirstLetter(names[1]);
        } else {
          if (names.length == 0) {
            name = "User";
          } else {
            name = names[0];
          }
        }
        this.setState({ userName: name });
        return;
      } else {
        this.setState({
          error: "Error loading transaction details",
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        error: "Error loading transaction details",
        isLoading: false,
      });
    }
  }

  navigateBack = () => {
    const { navigation } = this.props;
    if (navigation) {
      navigation.goBack();
    }
  };

  handleSearch = (searchText) => {
    let searchResults = [];
    let searchData = this.state.data.transactions;
    if (searchText) {
      searchResults = searchData.filter((item) => {
        return item.investoraccountstocktransaction__investoraccountstock__marketscrip__name.toLowerCase().startsWith(searchText);
      });
    } else {
      searchResults = this.state.dataBackup.transactions;
    }

    if (searchResults.length) {
      const data = {
        transactions: searchResults,
        transactions_as_on: this.state.data.transactions_as_on,
      };
      this.setState({
        data,
        searchText,
        searchResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  compare = (a, b, field, sort) => {
    if (a[field] < b[field]) {
      return sort ? -1 : 1;
    }
    if (a[field] > b[field]) {
      return sort ? 1 : -1;
    }
    return 0;
  };

  handleSort = (field, sort, start, end) => {
    this.setState(
      {
        sorting_field: field,
        sorting_value: !sort,
      },
      () => {
        const { data } = this.state;
        const transactions_data = [...data["transactions"]];
        const sortedData = [].concat(
          transactions_data.slice(0, start),
          transactions_data
            .slice(start, end)
            .sort((a, b) => this.compare(a, b, field, !sort)),
          transactions_data.slice(end, transactions_data.length)
        );
        this.setState({ data: { ...data, ["transactions"]: sortedData } });
      }
    );
  };

  getTableData = (data = []) => {
    if (!data || (data && !data.hasOwnProperty("transactions"))) return {};
    const { sorting_field, sorting_value } = this.state;
    const headerData = [
      {
        label: "Stock Name",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "investoraccountstocktransaction__investoraccountstock__marketscrip__name",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Type",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "investoraccountstocktransaction__transaction_type",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Units",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "total_units",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Price",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "investoraccountstocktransaction__price",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
      {
        label: "Total Amount",
        style: [styles.tableHeader],
        sortOption: true,
        onSort: this.handleSort,
        key: "total_inv",
        currentSortField: sorting_field,
        sortValue: sorting_value,
      },
    ];

    let { values = [] } = this.generateDataForTable(data["transactions"]);
    return { headerData, values };
  };

  generateDataForTable = (data) => {
    let values = [];
    data.forEach((item) => {
      let {
        investoraccountstocktransaction__investoraccountstock__marketscrip__name:
          stock_name,
        total_units: units,
        total_inv: invested_value,
        investoraccountstocktransaction__transaction_type: type,
        investoraccountstocktransaction__price: price,
      } = item;
      price = numberWithCommas(parseInt(price));
      invested_value = numberWithCommas(parseInt(invested_value));
      type = type == "B" ? "BUY" : type == "S" ? "SELL" : "";
      values.push([
        {
          valueToDisplay: stock_name,
          value: stock_name,
          style: [{textAlign: "left"}],
          rowItemContainerStyle: [{ justifyContent: "flex-start" }],
        },
        {
          valueToDisplay: type,
          value: type,
          style: [styles.rowItem, {marginLeft: -20}],
        },
        {
          valueToDisplay: Math.round(units),
          value: units,
          style: [styles.rowItem, {marginLeft: -20}],
        },
        {
          valueToDisplay: "₹" + price,
          value: price,
          style: [styles.rowItem, {marginLeft: -20}],
        },
        {
          valueToDisplay: "₹" + invested_value,
          value: invested_value,
          style: [styles.rowItem, {marginLeft: -20}],
        },
      ]);
    });
    return { values };
  };

  render() {
    const { navigation } = this.props;
    const { data = null, isLoading, error, searchText } = this.state;

    const { headerData, values } = this.getTableData(data);

    let excelBillingDetails = [];
    if (this.state.data) {
      this.state.data.transactions.forEach((item) => {
        let price = "₹" + numberWithCommas(parseInt(item.investoraccountstocktransaction__price));
        let type = item.investoraccountstocktransaction__transaction_type == "B" ? "BUY" : investoraccountstocktransaction__transaction_type == "S" ? "SELL" : "";

        excelBillingDetails.push({
          stock_name: item.investoraccountstocktransaction__investoraccountstock__marketscrip__name,
          type: type,
          units: item.total_units,
          price: price,
          total_amount: item.total_inv
        });
      });
    }

    return (
      <View style={styles.container}>
        {/* Header */}
        <NavigationHeader
          navigation={navigation}
          navigationHeader={[
            "Bills",
            "View Bills",
            "View Details",
            "Transactions",
          ]}
          onPressBack={this.navigateBack}
        />
        <ScrollView contentContainerStyle={styles.scrollView}>
          {isLoading || error ? (
            <View style={styles.loadingContainer}>
              {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
              {error && <Text style={styles.error}>{error}</Text>}
            </View>
          ) : (
            <ScrollView
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={true}
              horizontal={isMobile ? true : false}
              style={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#1212121A",
                  borderRadius: 10,
                  marginHorizontal: isMobile ? 16 : 20,
                  width: isMobile ? 700 : '80%',
                  marginVertical: 20,
                }}
              >
                <View
                  style={{
                    paddingHorizontal: isMobile ? 16 : 19,
                    paddingVertical: 19,
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text style={styles.header}>
                      Transactions as on {formatDate(data["transactions_as_on"])}
                    </Text>
                  </View>
                </View>
                <View style={styles.headingContainer}>
                  <SearchBar value={searchText} onChange={this.handleSearch}  />
                  <View
                    style={{
                      cursor: "pointer",
                      justifyContent: "center",
                      flexDirection: "row",
                      marginRight: 20,
                    }}
                  >
                    {/* <ExcelFile
                      filename={
                        this.state.userName + moment().format("DD-MM-YYYY hh-mm-ss A")
                      }
                      element={
                        <Text
                          style={{
                            color: "#410DAA",
                            fontWeight: "bold",
                            cursor: "pointer",
                            fontSize: 15,
                            textDecorationLine: "underline",
                          }}
                        >
                          Download As Excel
                        </Text>
                      }
                    >
                      <ExcelSheet data={excelBillingDetails} name="Invoices">
                        <ExcelColumn label="Stock name" value="stock_name" />
                        <ExcelColumn label="Type" value="type" />
                        <ExcelColumn label="Units" value="units" />
                        <ExcelColumn label="Price" value="price" />
                        <ExcelColumn label="Total Amount" value="total_amount" />
                      </ExcelSheet>
                    </ExcelFile> */}
                  </View>
                </View>
                <HoldingsTable
                  header={headerData}
                  gridConfig={[0.25, 0.25, 0.2, 0.2, 0.1]}
                  data={values}
                  navigation={this.props.navigation}
                />
              </View>
            </ScrollView>
            // <View style={styles.detailsContainer}>
            //   <View style={styles.headingContainer}>
            //     <Text style={styles.header}>
            //       Transactions as on {formatDate(data["transactions_as_on"])}
            //     </Text>
            //   </View>
            //   <Table
            //     header={headerData}
            //     gridConfig={[0.2, 0.15, 0.15, 0.25, 0.25]}
            //     data={values}
            //   />
            // </View>
          )}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
  },
  scrollView: {
    flexGrow: 1,
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: isMobile ? 16 : 36,
    marginVertical: 30,
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 15 : 40,
    paddingVertical: 19,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "700",
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default TransactionDetails;
