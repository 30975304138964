import React from "react";
import {
	StyleSheet,
	Text,
	View,
	Dimensions,
	TouchableOpacity,
} from "react-native";
const { width } = Dimensions.get("window");

import Store from "../../../store";
import { completeMinDetails, getUserDetails } from "../../../utils";
import { saveCustomerProfileData } from "../../../api/account";
import Header from "../header_after_login";

class OnboardingIntro extends React.Component {
	constructor(props) {
		super(props);
		let { is_goal } = Store.getState().userData;
		this.state = {
			isLoading: false,
			is_goal
		}
	}

	handleContinue = async () => {
		const { is_goal } = Store.getState().userData;
		if (is_goal) {
			this.props.navigation.navigate("SignUpFinancialPlan")
		} else {
			this.props.navigation.navigate("SignUpImportPortfolio")
		}
	}

	render() {
		return (
			<View style={styles.container}>
				<Header
					navigation={this.props.navigation}
					route={this.props.route}
					showbackBtn={false} />
				<View style={styles.subContainer}>
					<View>
					<View>
						<Text style={{
							color: '#484848',
							fontSize: 28,
							fontWeight: '700',
							marginBottom: 50
						}}>Welcome to Maxiom Wealth - Your Smart Investment Companion!</Text>
					</View>
					<View>
						<Text style={{
							color: '#484848',
							fontSize: 24,
							fontWeight: '700',
							marginBottom: 20
						}}>Start with Two Simple Steps:</Text>
					</View>
					{this.state.is_goal ? (<View style={{ marginBottom: 80 }}>
						<View>
							<Text style={{
								fontSize: 18,
								color: '#484848',
								fontWeight: '700',
								marginTop: 20
							}} >1. Create a Financial Plan</Text>
							<Text style={{
								fontSize: 14,
								color: '#484848',
								fontWeight: '500',
								marginTop: 5
							}}>Align your investments with your goals.</Text>
						</View>
						<View>
							<Text style={{
								fontSize: 18,
								color: '#484848',
								fontWeight: '700',
								marginTop: 20
							}}>2. Free Portfolio Analysis</Text>
							<Text style={{
								fontSize: 14,
								color: '#484848',
								fontWeight: '500',
								marginTop: 5
							}}>Quick insights to optimize your investments.</Text>
						</View>
					</View>) : (
						<View style={{ marginBottom: 80 }}>
							<View>
								<Text style={{
									fontSize: 18,
									color: '#484848',
									fontWeight: '700',
									marginTop: 20
								}}>1. Free Portfolio Analysis</Text>
								<Text style={{
									fontSize: 14,
									color: '#484848',
									fontWeight: '500',
									marginTop: 5
								}}>Quick insights to optimize your investments.</Text>
							</View>
							<View>
								<Text style={{
									fontSize: 18,
									color: '#484848',
									fontWeight: '700',
									marginTop: 20
								}} >2. Create a Financial Plan</Text>
								<Text style={{
									fontSize: 14,
									color: '#484848',
									fontWeight: '500',
									marginTop: 5
								}}>Align your investments with your goals.</Text>
							</View>
						</View>
					)}
					<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
						<TouchableOpacity style={{
							backgroundColor: "#410DAA",
							paddingVertical: 20,
							width: 200,
							justifyContent: 'center',
							alignItems: 'center',
							borderRadius: 10
						}}
							onPress={this.handleContinue}
						>
							<Text style={{ color: '#fff', fontWeight: '500' }}>Start Now</Text>
						</TouchableOpacity>
					</View>
					</View>
				</View>
			</View>
		)
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	subContainer: {
		width: "100%",
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		backgroundColor: '#fff',
		justifyContent: 'center',
		padding: 30
	}
})


export default OnboardingIntro;
