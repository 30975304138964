const faqs = [
    {
        question: 'Which plan should I go for?',
        answer: 'If you have equity/stocks exposure above 10 lakhs, then JeWEL membership will help you. If you are total investments across funds and stocks are 25 lakhs, then go for Platinum or JeWEL plans.',
        id: '1'

    },
    {
        question: 'How is Jamā Wealth different from a PMS?',
        answer: ['Maxiom Wealth is contact less on your invested money; we never touch or hold it, because we do not access/operate your bank account or demat account. This makes it very safe.', 'There are no brokerages or other loads. What you see is what you get: zero hidden costs, zero brokerages. The fees are transparent and payable over the table. There is no lock in nor exit loads.'],
        id: '2'

    },
    {
        question: 'How do I review my holdings and will you help advice what to do?',
        answer: 'Yes, we provide a complimentary, no obligation health check on your portfolio. You are free to decide and act upon the recommendations we suggest based on rigorous quantitative models.',
        id: '3'

    },
    {
        question: 'What is the basis of your investment advice?',
        answer: 'We follow a roots and wings approach for identifying the best stocks. Roots identified low debt companies with High return on Equity run by Promoters with Skin in the game. Wings identifies companies with Growing sales and profits, Robust cash flows and Strong market share.',
        id: '4'

    },
    {
        question: 'How does technology help?',
        answer: 'We deploy the best in class technology to crunch the numbers. Machine learning helps fine tune selection parameters. Dynamic allocation and sizing helps portfolios grow better. Seamless execution updates portfolios while keeping emotions in check.',
        id: '5'

    },
    {
        question: 'Can I invest in any stock or fund through Jamā Wealth?',
        answer: ['We do not hold your stocks or funds or money at any time.', 'We do have seamless integration with Zerodha, Upstox and other leading demat accounts to facilitate execution of your stock transactions.', 'We do not receive any brokerage or commission on any transactions.'],
        id: '6'

    },
    {
        question: 'How do you monitor the performance of recommended portfolio?',
        answer: 'We use proprietary technology to review the portfolio. Stocks are grilled on fundamentals listed above and any slippage is promptly addressed. Funds are also reviewed at a granular level based on their constituent holdings.',
        id: '7'

    }
]

export default faqs