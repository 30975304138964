import React from "react";
import {
  View,
  ScrollView,
  StatusBar,
  Platform,
  SafeAreaView,
  TouchableOpacity,
} from "react-native";
import { Text, RadioButton } from "react-native-paper";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import CustButton from "../../../components/Common/Button";
import amplitude from "../../../utils/amplitude";
import theme from "../../../static/theme";
import CurrencyFormatInput from "../../../components/Common/currencyFormatInput";
import numberFormat from "../../../utils/numberFormat";
import { getJamaSuggestedAmount } from "../../../api";
import Store from "../../../store";

import { isMobile } from "../../../utils";

const InputOption = ({
  title,
  subtitle,
  selected,
  amount,
  isInput = true,
  editable,
  onPress,
  onChangeAmount,
  style,
  helpText,
  error,
}) => {
  return (
    <View style={{ flexDirection: "row", alignItems: "flex-start", ...style }}>
      <RadioButton.Android
        onPress={() => {
          onPress();
        }}
        uncheckedColor={"gray"}
        color={"#410DAA"}
        status={selected ? "checked" : "unchecked"}
        style={{ marginRight: 20 }}
      />
      <View style={{ flexDirection: "column" }}>
        <TouchableOpacity
          onPress={() => {
            onPress();
          }}
          style={{ marginBottom: 11 }}
        >
          <Text
            style={{ fontSize: 14, color: selected ? "#410DAA" : "#121212" }}
          >
            {title}
          </Text>
          {subtitle && (
            <Text style={{ fontSize: 12, marginTop: 5, color: "#707070" }}>
              {subtitle || ""}
            </Text>
          )}
        </TouchableOpacity>
        {editable == false && (
          <TouchableOpacity
            onPress={() => {
              onPress();
            }}
            style={{
              backgroundColor: "#F8F8F8",
              borderRadius: 25,
              paddingVertical: 15,
              paddingHorizontal: 10,
            }}
          >
            <Text style={{ fontSize: 18, color: "#121212" }}>
              {"₹ "}
              <Text style={{ paddingHorizontal: 15 }}>
                {numberFormat(amount)}
              </Text>
            </Text>
          </TouchableOpacity>
        )}
        {isInput && (
          <>
            <CurrencyFormatInput
              editable={editable}
              prefix="₹"
              value={`${amount}`}
              onValueChange={(amount, error) => {
                onChangeAmount(amount);
              }}
              containerStyle={{
                flex: 0.5,
              }}
              textStyle={{
                fontSize: 18,
                paddingVertical: 10,
                paddingHorizontal: 5,
              }}
            />
          </>
        )}

        {error ? (
          <Text
            style={{
              color: "red",
              fontSize: 10,
              paddingVertical: 0,
              paddingBottom: 4,
              marginVertical: 0,
            }}
          >
            {error || ""}
          </Text>
        ) : helpText && (amount == 0 || amount == "" || !amount) ? (
          <Text style={{ fontSize: 11, fontStyle: "italic", color: "#707070" }}>
            {helpText}
          </Text>
        ) : null}
      </View>
    </View>
  );
};

export default class InvestGoal extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let actionId = null;
    if (params) {
      actionId = params.action || null;
    }
    this.state = {
      customAmount: 0,
      option: "custom",
      isLoading: false,
      actionId,
    };
  }

  static navigationOptions = ({ navigation }) => ({
    tabBarVisible: false,
  });

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("investGoal",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("investGoal", { path: this.props.navigation.getState().routeName });
    }
    const { actions = {} } = Store.getState().transactions;
    const { actionId } = this.state;
    if (actions.hasOwnProperty(actionId)) {
      const { goal, goal_id } = actions[actionId];
      const payload = { goal_id: goal.customergoal_id };
      const response = await getJamaSuggestedAmount(payload);
      if (response && response.code == "100") {
        let jamaAmount = Math.round(response.jama_suggested_amount);
        this.setState({
          jamaAmount,
          isLoading: false,
          goal,
          goal_id,
          option: "jama",
        });
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  onPressNext = () => {
    try {
      const { jamaAmount, customAmount, option, actionId } = this.state;
      let amount;
      let valid = true;
      switch (option) {
        case "custom":
          if (customAmount == 0 || !customAmount || customAmount == "") {
            const customError = "Amount can not be empty";
            this.setState({ customError });
            valid = false;
          }
          amount = customAmount;
          break;
        case "jama":
          amount = jamaAmount;
          break;
        case "later":
          amount = 0;
          this.setState({ isLoading: false });
      }
      if (valid) {
        this.setState({ isLoading: false });
        Store.dispatch({
          type: "UPDATE_ACTION",
          payload: {
            key: actionId,
            data: {
              amount,
            },
          },
        });

        this.props.navigation.navigate("InvestGoalSummary", {
          action: actionId,
          goal_id: this.state.goal_id,
        });
      }
    } catch (error) {}
  };

  render() {
    const { option, customAmount, jamaAmount, isLoading, customError, goal } =
      this.state;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff", maxWidth: 800 }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={[goal ? goal.name : "Back"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <ScrollView style={{ padding: 16 }}>
          <Text style={{ fontSize: 14, color: "#121212", fontWeight: "bold" }}>
            How much would you invest now ?
          </Text>
          <View>
            {jamaAmount && (
              <InputOption
                selected={option == "jama"}
                editable={false}
                amount={jamaAmount}
                onPress={() => {
                  this.setState({ option: "jama" });
                }}
                onChangeAmount={(jamaAmount) => {
                  this.setState({ jamaAmount });
                }}
                title={"Jama Suggested Amount"}
                style={{
                  marginTop: 33,
                }}
                isInput={false}
              />
            )}
            <InputOption
              selected={option == "custom"}
              amount={customAmount}
              onPress={() => {
                this.setState({ option: "custom" });
              }}
              onChangeAmount={(customAmount) => {
                let customError;
                if (customAmount == 0 || customAmount == "") {
                  customError = "Amount can not be zero";
                }
                if (!isNaN(customAmount)) {
                  this.setState({ customAmount, customError });
                }
              }}
              title={"Custom Amount"}
              style={{
                marginVertical: 30,
              }}
              helpText="Enter amount you wish to invest now"
              error={customError}
            />
          </View>
        </ScrollView>
        <View
          style={{
            position: "absolute",
            bottom: isMobile ? 66 : 0,
            width: "100%",
            paddingHorizontal: 16,
          }}
        >
          <CustButton
            bgColor={"#410DAA"}
            textColor={"#fff"}
            style={{
              marginVertical: 5,
              width: isMobile ? "100%" : "30%",
              marginLeft: isMobile ? 0 : "35%",
              marginRight: isMobile ? 0 : "35%",
              paddingVertical: 15,
              marginTop: theme.margins.BASE * 2,
            }}
            isLoading={isLoading}
            borderColor={"transparent"}
            buttonTextStyle={{
              fontSize: 14,
            }}
            onPress={this.onPressNext}
          >
            Next
          </CustButton>
        </View>
      </SafeAreaView>
    );
  }
}
