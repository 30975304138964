import React from "react";
import {
  View,
  Platform,
  ScrollView,
  StyleSheet,
  ActivityIndicator,
  SafeAreaView,
  Animated,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { Button, Text } from "react-native-paper";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;
import ANTIcon from "@expo/vector-icons/AntDesign";

import { connect } from "react-redux";
import amplitude from "../../../utils/amplitude";

import { NewHeading } from "../../../components/Common/newHeading";
import { Tabs } from "../components/tabs";
import { StackActions } from "@react-navigation/routers";
import GoalTagMutualFunds from "./goalTagMF";
import { SectionHeading } from "../../../components/Common/Heading/SectionHeading";
// import Slider from "@react-native-community/slider";
import { Slider } from 'react-native-elements';
import {
  detachOrAttachToGoal,
  getStocks,
  getMutualFunds,
  getAssets,
} from "../../../actions/goals";
import MaterialIcon from "@expo/vector-icons/MaterialIcons";
import TextInputField from "../../../components/Common/Inputs/InputField";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import {
  jamaStockTagUntag,
  outsideStockTagUntag,
  tagGoal,
} from "../../../api/goals";
import AddAsset from "./addAsset";
import { Asset } from "./tabContent";
import { inWords, isMobile, numberFormat, selectOneFile } from "../../../utils";
import { ShowToast } from "../../../components/Common/Toast";
import { mapInvestorAccountEntityToGoal } from "../../../api";
import Divider from "../../../components/Common/divider";
const { width } = Dimensions.get("window");

var goalid = "";
const JAMA_FOLIOS = "Jama Folios";
const OUTSIDE_FOLIOS = "Outside Folios";
const ASSETS = "Assets";
const STOCKS = "Stocks";

class AvailableStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.attached || 0,
      correct: false,
      notCorrect: false,
      isLoading: false,
      error: null,
    };
  }

  handleInput = (value) => {
    let data = {};
    value = value.replace("%", "");
    
    if (value.trim() === "") {
        data = { value: "", error: null };
    } else {
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            if (parsedValue < 0) {
                data = { value: 0, error: "Value cannot be less than 0%" };
            } else if (parsedValue > 100) {
                data = { value: 100, error: "Value cannot exceed 100%" };
            } else {
                data = { value: parsedValue, error: null };
            }
        } else {
            data = { value: 0, error: "enter valid input" };
        }
    }

    this.setState({
        ...data,
        isLoading: false,
        correct: false,
        notCorrect: false,
    });
  };

  componentWillReceiveProps(props) {
    this.setState({ isLoading: props.isLoading });
  }

  attach = () => {
    let { value } = this.state;
    const { available, dematId, attached } = this.props;
    value = parseFloat(value);
    this.setState({ error: null });
    if (!isNaN(value)) {
      if (value <= available + attached) {
        this.props.onAttach(value, dematId, this.props.value);
        this.setState({
          correct: true,
          notCorrect: false,
          error: null,
        });
      } else {
        this.setState({
          isLoading: false,
          correct: false,
          notCorrect: false,
          error: "Number must be in specified range",
        });
      }
    } else {
      this.setState({ isLoading: false, error: "enter valid input" });
    }
  };

  render() {
    const { correct, notCorrect, error } = this.state;
    const { style, name, attached, value, available } = this.props;
    const attachedToOtherGoal = 100 - available - attached;
    const attachedToThisGoal = attached;
    let amount = null;
    if (value != null && !isNaN(value)) {
      amount = value.toFixed(0);
      amount = `₹${numberFormat(amount)}`;
    } else {
      amount = null;
    }
    let valueInWords = null;
    if (value && !isNaN(value)) {
      let value_in_string = value.toString();
      if (value_in_string.includes(".")) {
        value_in_string = value_in_string.split(".");
        valueInWords = inWords(parseInt(value_in_string[0]));
      } else {
        valueInWords = inWords(value);
      }
      valueInWords = valueInWords == "overflow" ? null : valueInWords;
    }
    return (
      <View style={{ marginVertical: 15, marginTop: -5, ...style }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: 25,
          }}
        >
          <View
            style={{
              width: 15,
              height: 20,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
              backgroundColor: "grey",
              marginRight: 10,
            }}
          ></View>
          <Text
            style={{
              fontSize: 14,
              color: "#121212",
              flex: amount != null ? 0.6 : 1,
            }}
          >
            {name ? name : "Demat Account"}
          </Text>
          {amount != null && (
            <View style={{ flex: 0.6, paddingTop: 20, alignItems: "flex-end" }}>
              <Text
                style={{
                  color: "#121212",
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                {amount}
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  color: "#707070",
                  fontStyle: "italic",
                  alignSelf: "flex-end",
                }}
              ></Text>
            </View>
          )}
        </View>
        <View
          style={{
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 15,
            paddingLeft: 20,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ flexDirection: "column" }}>
              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    width: 11,
                    height: 11,
                    backgroundColor: "grey",
                    marginHorizontal: 5,
                  }}
                ></View>
                <Text
                  style={{ color: "#121212", fontSize: 14, marginTop: -2.5 }}
                >
                  Attached to other Goals {attachedToOtherGoal}%
                </Text>
              </View>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View
                  style={{
                    width: 11,
                    height: 11,
                    backgroundColor: "#915BFD",
                    marginHorizontal: 5,
                  }}
                ></View>
                <Text
                  style={{ color: "#121212", fontSize: 14, marginTop: -2.5 }}
                >
                  Attached to this Goal {attachedToThisGoal}%
                </Text>
              </View>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View
                  style={{
                    width: 11,
                    height: 11,
                    backgroundColor: "#D4BEFF",
                    marginHorizontal: 5,
                  }}
                ></View>
                <Text
                  style={{ color: "#121212", fontSize: 14, marginTop: -2.5 }}
                >
                  Available {available}%
                </Text>
              </View>
            </View>
            <View style={{ marginLeft: 20 }}>
              {Platform.OS == "web" ? (
                <Donut
                  options={{
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: false,
                    },
                    tooltip: { enabled: false },
                    colors: ["#D4BEFF", "#915BFD", "grey"],
                    plotOptions: {
                      pie: {
                        donut: {
                          size: "60%",
                        },
                      },
                    },
                    stroke: {
                      width: 1,
                      colors: ["#D4BEFF", "#915BFD", "grey"],
                    },
                  }}
                  series={[available, attachedToThisGoal, attachedToOtherGoal]}
                  sliceColor={["#D4BEFF", "#915BFD", "grey"]}
                  type="donut"
                  width="160"
                />
              ) : (
                <Donut
                  widthAndHeight={100}
                  series={[available, attachedToThisGoal, attachedToOtherGoal]}
                  sliceColor={["#D4BEFF", "#915BFD", "grey"]}
                  doughnut={true}
                  coverRadius={0.6}
                  coverFill={"#FFF"}
                />
              )}
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: 250,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                backgroundColor: "#F8F8F8",
                paddingHorizontal: 10,
                paddingVertical: 10,
                alignItems: "center",
                width: 210,
              }}
            >
              <TextInputField
                label={`Attach to this Goal (0% to ${available + attachedToThisGoal
                  }%)`}
                value={`${this.state.value}%`}
                onChangeText={this.handleInput}
                onSubmitEditing={this.attach}
                style={[{ width: 190 }]}
              />
              <View style={{ width: 10, height: 10, justifyContent: "center" }}>
                {correct && (
                  <ANTIcon
                    name="checkcircle"
                    color={"#009105"}
                    size={10}
                    style={{}}
                  />
                )}
                {notCorrect && (
                  <ANTIcon
                    name="closecircle"
                    color={"red"}
                    size={10}
                    style={{}}
                  />
                )}
                {error != null && (
                  <MaterialIcon name="error" color={"#EF5D12"} size={10} />
                )}
              </View>
            </View>
            {error != null && (
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 10,
                }}
              >
                <MaterialIcon name="error" color={"#EF5D12"} size={12} />
                <Text
                  style={{
                    fontSize: 12,
                    color: "#707070",
                    fontStyle: "italic",
                  }}
                >
                  {error}
                </Text>
              </View>
            )}
            <View style={{ marginTop: 11, width: 180 }}>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ textAlign: "left" }}>
                  Current Attachment 1:{" "}
                  <Text
                    style={{
                      fontWeight: "bold",
                      color: "#410DAA",
                    }}
                  >
                    {this.state.value}%
                  </Text>
                </Text>
              </View>
              <Slider
                value={this.state.value}
                onValueChange={(value) => this.setState({ value })}
                minimumValue={0}
                maximumValue={available}
                step={1}
                thumbStyle={{height: 20, width: 20}}
                thumbTintColor={"#410daa"}
                minimumTrackTintColor={"#410daa"}
                maximumTrackTintColor={"#D8D8D8"}
              />
            </View>
          </View>
          {isMobile ? (
            <TouchableOpacity
              style={{
                borderRadius: 8,
                backgroundColor: "#410DAA",
                justifyContent: "center",
                alignItems: "center",
                paddingVertical: 10,
                paddingHorizontal: 15,
                marginTop: 20,
                width: "60%",
              }}
              onPress={() => {
                this.setState({ isLoading: true });
                this.attach();
              }}
            >
              <Text style={{ fontSize: 12, color: "#fff" }}>Update</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={{
                borderRadius: 8,
                backgroundColor: "#410DAA",
                justifyContent: "center",
                alignItems: "center",
                paddingVertical: 10,
                paddingHorizontal: 15,
                marginLeft: 25,
              }}
              onPress={() => {
                this.setState({ isLoading: true });
                this.attach();
              }}
            >
              <Text style={{ fontSize: 12, color: "#fff" }}>Update</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  }
}

class AvailableMF extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.attached,
      correct: false,
      notCorrect: false,
      isLoading: false,
      error: null,
    };
  }

  handleInput = (value) => {
    let data = {};
    value = value.replace("%", "");
    
    if (value.trim() === "") {
        data = { value: "", error: null };
    } else {
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            if (parsedValue < 0) {
                data = { value: 0, error: "Value cannot be less than 0%" };
            } else if (parsedValue > 100) {
                data = { value: 100, error: "Value cannot exceed 100%" };
            } else {
                data = { value: parsedValue, error: null };
            }
        } else {
            data = { value: 0, error: "enter valid input" };
        }
    }

    this.setState({
        ...data,
        isLoading: false,
        correct: false,
        notCorrect: false,
    });
  };

  componentWillReceiveProps(props) {
    this.setState({ isLoading: props.isLoading });
  }

  attach = () => {
    let { value } = this.state;
    const { available, entityId, attached } = this.props;
    value = parseFloat(value);
    this.setState({ error: null });
    if (!isNaN(value)) {
      if (value <= available + attached) {
        this.props.onAttach(value, entityId, this.props.value);
        this.setState({
          correct: true,
          notCorrect: false,
          error: null,
        });
      } else {
        this.setState({
          isLoading: false,
          correct: false,
          notCorrect: false,
          error: "Number must be in specified range",
        });
      }
    } else {
      this.setState({ isLoading: false, error: "enter valid input" });
    }
  };

  render() {
    const { correct, notCorrect, error } = this.state;
    const { style, name, attached, value, available, bse_folio_no } =
      this.props;
    const attachedToOtherGoal = 100 - available - attached;
    const attachedToThisGoal = attached;
    let amount = null;
    if (value != null && !isNaN(value)) {
      amount = value.toFixed(0);
      amount = `₹${numberFormat(amount)}`;
    } else {
      amount = null;
    }
    let valueInWords = null;
    if (value && !isNaN(value)) {
      let value_in_string = value.toString();
      if (value_in_string.includes(".")) {
        value_in_string = value_in_string.split(".");
        valueInWords = inWords(parseInt(value_in_string[0]));
      } else {
        valueInWords = inWords(value);
      }
      valueInWords = valueInWords == "overflow" ? null : valueInWords;
    }
    return (
      <View style={{ marginVertical: 15, marginTop: -5, ...style }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: 25,
          }}
        >
          <View
            style={{
              width: 15,
              height: 20,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
              backgroundColor: "grey",
              marginRight: 10,
            }}
          ></View>
          <Text
            style={{
              fontSize: 14,
              color: "#121212",
              flex: amount != null ? 0.6 : 1,
            }}
          >
            {name}
          </Text>
          {amount != null && (
            <View style={{ flex: 0.6, paddingTop: 20, alignItems: "flex-end" }}>
              <Text
                style={{
                  color: "#121212",
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                {amount}
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  color: "#707070",
                  fontStyle: "italic",
                  alignSelf: "flex-end",
                }}
              ></Text>
            </View>
          )}
        </View>
        <Text
          style={{
            fontSize: 14,
            color: "#121212",
            marginTop: -20,
          }}
        >
          Folio No: {bse_folio_no}
        </Text>
        <View
          style={{
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 15,
            paddingLeft: 20,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ flexDirection: "column" }}>
              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    width: 11,
                    height: 11,
                    backgroundColor: "grey",
                    marginHorizontal: 5,
                  }}
                ></View>
                <Text
                  style={{ color: "#121212", fontSize: 14, marginTop: -2.5 }}
                >
                  Attached to other Goals {attachedToOtherGoal}%
                </Text>
              </View>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View
                  style={{
                    width: 11,
                    height: 11,
                    backgroundColor: "#915BFD",
                    marginHorizontal: 5,
                  }}
                ></View>
                <Text
                  style={{ color: "#121212", fontSize: 14, marginTop: -2.5 }}
                >
                  Attached to this Goal {attachedToThisGoal}%
                </Text>
              </View>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View
                  style={{
                    width: 11,
                    height: 11,
                    backgroundColor: "#D4BEFF",
                    marginHorizontal: 5,
                  }}
                ></View>
                <Text
                  style={{ color: "#121212", fontSize: 14, marginTop: -2.5 }}
                >
                  Available {available}%
                </Text>
              </View>
            </View>
            <View style={{ marginLeft: 20 }}>
              {Platform.OS == "web" ? (
                <Donut
                  options={{
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: false,
                    },
                    tooltip: { enabled: false },
                    colors: ["#D4BEFF", "#915BFD", "grey"],
                    plotOptions: {
                      pie: {
                        donut: {
                          size: "60%",
                        },
                      },
                    },
                    stroke: {
                      width: 1,
                      colors: ["#D4BEFF", "#915BFD", "grey"],
                    },
                  }}
                  series={[available, attachedToThisGoal, attachedToOtherGoal]}
                  sliceColor={["#D4BEFF", "#915BFD", "grey"]}
                  type="donut"
                  width="160"
                />
              ) : (
                <Donut
                  widthAndHeight={100}
                  series={[available, attachedToThisGoal, attachedToOtherGoal]}
                  sliceColor={["#D4BEFF", "#915BFD", "grey"]}
                  doughnut={true}
                  coverRadius={0.6}
                  coverFill={"#FFF"}
                />
              )}
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: 250,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                backgroundColor: "#F8F8F8",
                paddingHorizontal: 10,
                paddingVertical: 10,
                alignItems: "center",
                width: 210,
              }}
            >
              <TextInputField
                label={`Attach to this Goal (0% to ${available + attachedToThisGoal
                  }%)`}
                value={`${this.state.value}%`}
                onChangeText={this.handleInput}
                onSubmitEditing={this.attach}
                style={[{ width: 190 }]}
              />
              <View style={{ width: 10, height: 10, justifyContent: "center" }}>
                {correct && (
                  <ANTIcon
                    name="checkcircle"
                    color={"#009105"}
                    size={10}
                    style={{}}
                  />
                )}
                {notCorrect && (
                  <ANTIcon
                    name="closecircle"
                    color={"red"}
                    size={10}
                    style={{}}
                  />
                )}
                {error != null && (
                  <MaterialIcon name="error" color={"#EF5D12"} size={10} />
                )}
              </View>
            </View>
            {error != null && (
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 10,
                }}
              >
                <MaterialIcon name="error" color={"#EF5D12"} size={12} />
                <Text
                  style={{
                    fontSize: 12,
                    color: "#707070",
                    fontStyle: "italic",
                  }}
                >
                  {error}
                </Text>
              </View>
            )}
            <View style={{ marginTop: 11, width: 180 }}>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ textAlign: "left" }}>
                  Current Attachment:{" "}
                  <Text
                    style={{
                      fontWeight: "bold",
                      color: "#410DAA",
                    }}
                  >
                    {this.state.value}%
                  </Text>
                </Text>
              </View>
              <Slider
                value={this.state.value}
                onValueChange={(value) => this.setState({ value })}
                minimumValue={0}
                maximumValue={available}
                step={1}
                thumbStyle={{height: 20, width: 20}}
                thumbTintColor={"#410daa"}
                minimumTrackTintColor={"#410daa"}
                maximumTrackTintColor={"#D8D8D8"}
              />
            </View>
          </View>
          {isMobile ? (
            <TouchableOpacity
              style={{
                borderRadius: 8,
                backgroundColor: "#410DAA",
                justifyContent: "center",
                alignItems: "center",
                paddingVertical: 10,
                paddingHorizontal: 15,
                marginTop: 20,
                width: "60%",
              }}
              onPress={() => {
                this.setState({ isLoading: true });
                this.attach();
              }}
            >
              <Text style={{ fontSize: 12, color: "#fff" }}>Update</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={{
                borderRadius: 8,
                backgroundColor: "#410DAA",
                justifyContent: "center",
                alignItems: "center",
                paddingVertical: 10,
                paddingHorizontal: 15,
                marginLeft: 25,
              }}
              onPress={() => {
                this.setState({ isLoading: true });
                this.attach();
              }}
            >
              <Text style={{ fontSize: 12, color: "#fff" }}>Update</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  }
}

class AvailableAsset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.attached,
      correct: false,
      notCorrect: false,
      isLoading: false,
      error: null,
      assetTypes: {
        REA: "Real Estate",
        REV: "Real Estate",
        REL: "Real Estate",
        EQS: "Equity",
        EQN: "Equity",
        EQW: "Equity",
        EGN: "Equity",
        DBN: "Debt",
        DBE: "Debt",
        DBP: "Debt",
        DBB: "Debt",
        DBF: "Debt",
        DBM: "Debt",
        DBR: "Debt",
        DBC: "Debt",
        DBL: "Debt",
        DBD: "Debt",
        DGN: "Debt",
        SSY: "Debt",
        INL: "Insurance",
        INW: "Insurance",
        INU: "Insurance",
        GDB: "Gold",
        GDS: "Gold",
        GDM: "Gold",
      },
    };
  }

  handleInput = (value) => {
    let data = {};
    value = value.replace("%", "");
    
    if (value.trim() === "") {
        data = { value: "", error: null };
    } else {
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            if (parsedValue < 0) {
                data = { value: 0, error: "Value cannot be less than 0%" };
            } else if (parsedValue > 100) {
                data = { value: 100, error: "Value cannot exceed 100%" };
            } else {
                data = { value: parsedValue, error: null };
            }
        } else {
            data = { value: 0, error: "enter valid input" };
        }
    }

    this.setState({
        ...data,
        isLoading: false,
        correct: false,
        notCorrect: false,
    });
  };

  componentWillReceiveProps(props) {
    this.setState({ isLoading: props.isLoading });
  }

  attach = () => {
    let { value } = this.state;
    const { available, entityId, attached } = this.props;
    value = parseFloat(value);
    this.setState({ error: null });
    if (!isNaN(value)) {
      if (value <= available + attached) {
        this.props.onAttach(value, entityId, this.props.value);
        this.setState({
          correct: true,
          notCorrect: false,
          error: null,
        });
      } else {
        this.setState({
          isLoading: false,
          correct: false,
          notCorrect: false,
          error: "Number must be in specified range",
        });
      }
    } else {
      this.setState({ isLoading: false, error: "enter valid input" });
    }
  };

  render() {
    const { correct, notCorrect, error } = this.state;
    const { style, name, attached, value, available, asset_type } = this.props;
    const attachedToOtherGoal = 100 - available - attached;
    const attachedToThisGoal = attached;
    let amount = null;
    if (value != null && !isNaN(value)) {
      amount = value.toFixed(0);
      amount = `₹${numberFormat(amount)}`;
    } else {
      amount = null;
    }
    let valueInWords = null;
    if (value && !isNaN(value)) {
      let value_in_string = value.toString();
      if (value_in_string.includes(".")) {
        value_in_string = value_in_string.split(".");
        valueInWords = inWords(parseInt(value_in_string[0]));
      } else {
        valueInWords = inWords(value);
      }
      valueInWords = valueInWords == "overflow" ? null : valueInWords;
    }

    return (
      <View style={{ marginVertical: 15, marginTop: -5, ...style }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: 25,
          }}
        >
          <View
            style={{
              width: 15,
              height: 20,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
              backgroundColor: "grey",
              marginRight: 10,
            }}
          ></View>
          <Text
            style={{
              fontSize: 14,
              color: "#121212",
              flex: amount != null ? 0.6 : 1,
            }}
          >
            {name}
          </Text>
          {amount != null && (
            <View style={{ flex: 0.6, paddingTop: 20, alignItems: "flex-end" }}>
              <Text
                style={{
                  color: "#121212",
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                {amount}
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  color: "#707070",
                  fontStyle: "italic",
                  alignSelf: "flex-end",
                }}
              ></Text>
            </View>
          )}
        </View>
        <Text
          style={{
            fontSize: 14,
            color: "#121212",
            marginTop: -20,
          }}
        >
          Asset Type: {this.state.assetTypes[asset_type]}
        </Text>
        <View
          style={{
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 15,
            paddingLeft: 20,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ flexDirection: "column" }}>
              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    width: 11,
                    height: 11,
                    backgroundColor: "grey",
                    marginHorizontal: 5,
                  }}
                ></View>
                <Text
                  style={{ color: "#121212", fontSize: 14, marginTop: -2.5 }}
                >
                  Attached to other Goals {attachedToOtherGoal}%
                </Text>
              </View>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View
                  style={{
                    width: 11,
                    height: 11,
                    backgroundColor: "#915BFD",
                    marginHorizontal: 5,
                  }}
                ></View>
                <Text
                  style={{ color: "#121212", fontSize: 14, marginTop: -2.5 }}
                >
                  Attached to this Goal {attachedToThisGoal}%
                </Text>
              </View>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View
                  style={{
                    width: 11,
                    height: 11,
                    backgroundColor: "#D4BEFF",
                    marginHorizontal: 5,
                  }}
                ></View>
                <Text
                  style={{ color: "#121212", fontSize: 14, marginTop: -2.5 }}
                >
                  Available {available}%
                </Text>
              </View>
            </View>
            <View style={{ marginLeft: 20 }}>
              {Platform.OS == "web" ? (
                <Donut
                  options={{
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: false,
                    },
                    tooltip: { enabled: false },
                    colors: ["#D4BEFF", "#915BFD", "grey"],
                    plotOptions: {
                      pie: {
                        donut: {
                          size: "60%",
                        },
                      },
                    },
                    stroke: {
                      width: 1,
                      colors: ["#D4BEFF", "#915BFD", "grey"],
                    },
                  }}
                  series={[available, attachedToThisGoal, attachedToOtherGoal]}
                  sliceColor={["#D4BEFF", "#915BFD", "grey"]}
                  type="donut"
                  width="160"
                />
              ) : (
                <Donut
                  widthAndHeight={100}
                  series={[available, attachedToThisGoal, attachedToOtherGoal]}
                  sliceColor={["#D4BEFF", "#915BFD", "grey"]}
                  doughnut={true}
                  coverRadius={0.6}
                  coverFill={"#FFF"}
                />
              )}
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: 250,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                backgroundColor: "#F8F8F8",
                paddingHorizontal: 10,
                paddingVertical: 10,
                alignItems: "center",
                width: 210,
              }}
            >
              <TextInputField
                label={`Attach to this Goal (0% to ${available + attachedToThisGoal
                  }%)`}
                value={`${this.state.value}%`}
                onChangeText={this.handleInput}
                onSubmitEditing={this.attach}
                style={[{ width: 190 }]}
              />
              <View style={{ width: 10, height: 10, justifyContent: "center" }}>
                {correct && (
                  <ANTIcon
                    name="checkcircle"
                    color={"#009105"}
                    size={10}
                    style={{}}
                  />
                )}
                {notCorrect && (
                  <ANTIcon
                    name="closecircle"
                    color={"red"}
                    size={10}
                    style={{}}
                  />
                )}
                {error != null && (
                  <MaterialIcon name="error" color={"#EF5D12"} size={10} />
                )}
              </View>
            </View>
            {error != null && (
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 10,
                }}
              >
                <MaterialIcon name="error" color={"#EF5D12"} size={12} />
                <Text
                  style={{
                    fontSize: 12,
                    color: "#707070",
                    fontStyle: "italic",
                  }}
                >
                  {error}
                </Text>
              </View>
            )}
            <View style={{ marginTop: 11, width: 180 }}>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ textAlign: "left" }}>
                  Current Attachment:{" "}
                  <Text
                    style={{
                      fontWeight: "bold",
                      color: "#410DAA",
                    }}
                  >
                    {this.state.value}%
                  </Text>
                </Text>
              </View>
              <Slider
                value={this.state.value}
                onValueChange={(value) => this.setState({ value })}
                minimumValue={0}
                maximumValue={available}
                step={1}
                thumbStyle={{height: 20, width: 20}}
                thumbTintColor={"#410daa"}
                minimumTrackTintColor={"#410daa"}
                maximumTrackTintColor={"#D8D8D8"}
              />
            </View>
          </View>
          {isMobile ? (
            <TouchableOpacity
              style={{
                borderRadius: 8,
                backgroundColor: "#410DAA",
                justifyContent: "center",
                alignItems: "center",
                paddingVertical: 10,
                paddingHorizontal: 15,
                marginTop: 20,
                width: "60%",
              }}
              onPress={() => {
                this.setState({ isLoading: true });
                this.attach();
              }}
            >
              <Text style={{ fontSize: 12, color: "#fff" }}>Update</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={{
                borderRadius: 8,
                backgroundColor: "#410DAA",
                justifyContent: "center",
                alignItems: "center",
                paddingVertical: 10,
                paddingHorizontal: 15,
                marginLeft: 25,
              }}
              onPress={() => {
                this.setState({ isLoading: true });
                this.attach();
              }}
            >
              <Text style={{ fontSize: 12, color: "#fff" }}>Update</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  }
}

class AttachExisting extends React.Component {
  constructor(props) {
    super(props);

    let active = 1,
      goal_id = null,
      goal_name = null;
    const { params } = this.props.route;
    if (params) {
      active = parseInt(params.active) || 1;
      goal_id = params.goal_id || null;
      goal_name = this.props.route.params.goal_name || null;
    }
    this.state = {
      activeIndex: active,
      activeMFIndex: 0,
      mfInsideData: [],
      mfOutsideData: [],
      activeAssetIndex: 0,
      realEstateData: [],
      equityData: [],
      debtData: [],
      insuranceData: [],
      goldData: [],
      goal_id,
      goal_name,
      [JAMA_FOLIOS]: [],
      [OUTSIDE_FOLIOS]: [],
      [ASSETS]: [],
      email: null,
      showRightButton: true,
      showToast: "false",
      SlideInLeft: new Animated.Value(0),
      slided: false,
      isLoading: true,
      scrollY: new Animated.Value(0),
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("AddPanCard", { path: global.location.href });
    } else {
      amplitude.logEvent("AddPanCard", {
        path: this.props.navigation.getState().routeName,
      });
    }
    const { goal_id } = this.state;
    if (goal_id) {
      await this._loadData(JAMA_FOLIOS, goal_id);
      await this._loadData(ASSETS, goal_id);
      await this._loadData(STOCKS, goal_id);
    }
  }

  setGoalDataHelper = (goals, goal_id) => {
    const goal = goals.filter((goal) => {
      return goal.customergoal_id == goal_id;
    });
    if (goal.length != 0) {
      this.setState({
        goal: goal[0],
        isDataLoading: false,
        isDataRefreshing: false,
      });
    }
    this.setState({ isDataRefreshing: false, isDataLoading: false });
  };

  refreshGoals = async () => {
    // this.setState({ isDataRefreshing: true });
    // const goal = this.props.navigation.getParam("goal", null);
    // const json = await getAdvisorDashboardDetials();
    // if (json) {
    //   store.dispatch({
    //     type: "USER_DASHBOARD",
    //     payload: json
    //   });
    //   if (json && json.goals) {
    //     store.dispatch({
    //       type: actionTypes,
    //       payload: json.goals
    //     });
    //     this.setGoalDataHelper(json.goals, goal.customergoal_id);
    //   } else {
    //     this.setState({ isDataRefreshing: false });
    //   }
    // } else {
    //   this.setState({ isDataRefreshing: false });
    // }
  };

  static navigationOptions = () => ({
    tabBarVisible: false,
  });

  onEmailChange = (email) => {
    this.setState({ email });
  };

  onImportPressed = () => {
    this.props.navigation.navigate("ViewMode", {
      screen: "ImportStocks",
      params: {
        from: "attachexisting",
        goal_name: this.state.goal_name,
        active: this.state.activeIndex,
      },
    });
  };

  itemSeparator = (item) => {
    if (item.leadingItem.header) return null;
    else {
      return (
        <Divider width={1} color="#F2F2F2" style={{ marginHorizontal: 16 }} />
      );
    }
  };

  clearEmail = () => {
    this.setState({ email: "" });
  };

  attachFund = (item) => {
    this.setState({ isAttaching: true, showToast: "false", toastTitle: "" });

    const config = {
      id:
        item.fundType == "out_mf"
          ? item.investoraccountfundimported_id
          : item.folio_id,
      type: item.type,
    };

    this.props
      .detachOrAttachToGoal(
        config,
        true,
        this.state.goal_id,
        item.fundType == "jama_mf"
          ? item.investoraccountfund__amcfundscheme__pk
          : ""
      )
      .then(() => {
        this.setState({
          showToast: "true",
          toastTitle: "Mutual fund attached to goal.",
        });
        const data = this.state[JAMA_FOLIOS].filter((item) => {
          return (
            config.id !=
            (item.fundType == "out_mf"
              ? item.investoraccountfundimported_id
              : item.folio_id) || item.header
          );
        });
        this.setState(
          {
            [JAMA_FOLIOS]: data,
            isAttaching: false,
            slided: false,
            activeEditId: null,
          },
          () => {
            this.refreshGoals();
          }
        );
      })
      .catch(() => {
        this.setState({
          isAttaching: false,
          activeEditId: null,
          slided: false,
          activeEditId: null,
        });
      });
  };

  startAnimation = (value, id) => {
    const { slided } = this.state;
    let temp_state = {};
    if (value) {
      temp_state["activeEditId"] = id;
    }

    this.setState({ slided: !slided, ...temp_state }, () => {
      return Animated.timing(this.state.SlideInLeft, {
        toValue: !value ? 0 : -100,
        duration: 500,
        useNativeDriver: true,
      }).start(() => {
        let data = {};
        if (!value) {
          data["activeEditId"] = null;
        }
        this.setState({ ...data });
      });
    });
  };

  _loadData = async (type, goalid, isAttach = false) => {
    try {
      const currentData = await this.getActiveTabData();
      if ((currentData && currentData.length == 0) || !currentData) {
        this.setState({ isDataLoading: true });
      }
      let response;
      if (type == JAMA_FOLIOS) {
        response = await getMutualFunds(goalid);
        if (this.props.route.params && this.props.route.params.mf) {
          if (
            this.props.route.params.mf.fundType &&
            this.props.route.params.mf.fundType == "out_mf"
          ) {
            response = response.filter(
              (item) =>
                item.investoraccountfundimported_id ==
                this.props.route.params.mf.investoraccountfundimported_id
            );
          } else {
            response = response.filter(
              (item) =>
                item.investoraccountfundfolio_id ==
                this.props.route.params.mf.investoraccountfundfolio_id
            );
          }
        }
      } else if (type == ASSETS) {
        response = await getAssets(goalid);
        if (this.props.route.params && this.props.route.params.asset) {
          response = response.filter(
            (item) =>
              item.investoraccountasset_id ==
              this.props.route.params.asset.investoraccountasset_id
          );
        }
      } else if (type == STOCKS) {
        response = await getStocks(goalid, isAttach);
        if (this.props.route.params && this.props.route.params.stock) {
          response = response.filter(
            (item) => item.dpid == this.props.route.params.stock.dpid
          );
        }
      }
      if (response) {
        this.setState({
          editIndex: null,
          [type]: response,
          isDataLoading: false,
          isAttaching: false,
          activeEditId: null,
        });
      }
    } catch (error) {
      this.setState({ isDataLoading: false, isAttaching: false });
    }
  };

  loadingIndicator = () => {
    return (
      <ActivityIndicator
        size="small"
        color={"#410DAA"}
        style={{ paddingTop: 100 }}
      />
    );
  };

  renderListItem = ({ item, index }) => {
    const { activeIndex } = this.state;
    switch (activeIndex) {
      case 1:
        return this._renderStocks(item, index);
      case 2:
        return this._renderMF(item, index);
      case 3:
        let a = this._renderAssets(item, index);
        return this._renderAssets(item, index);
    }
  };

  attachStock = async (type, goal_id, perc_val, dematId, value) => {
    this.setState({ showToast: "false", toastTitle: "", isLoading: true });
    let resp;
    if (type == "jama_stocks") {
      resp = await jamaStockTagUntag({
        goal_id: `${goal_id}`,
        da_id: `${dematId}`,
        perc_val: `${perc_val}`,
      });
    } else {
      resp = await outsideStockTagUntag({
        goal_id: `${goal_id}`,
        da_id: `${dematId}`,
        perc_val: `${perc_val}`,
      });
    }
    if (resp && resp.code && resp.code == "100") {
      await this._loadData(STOCKS, goal_id);
      let attachedValue = numberFormat(((value * perc_val) / 100).toFixed(0));
      this.setState({
        showToast: "true",
        toastTitle: isMobile
          ? `${perc_val}% of demat account attached to goal.`
          : `₹${attachedValue} i.e. ${perc_val}% of demat account has been attached to ${this.state.goal_name}.`,
        isLoading: false,
      });
    }
  };

  attachMF = async (
    old_perc_val,
    goal_id,
    perc_val,
    entityId,
    value,
    fundType = null
  ) => {
    this.setState({ showToast: "false", toastTitle: "", isLoading: true });
    let resp = await mapInvestorAccountEntityToGoal({
      investor_account_entity_type:
        fundType && fundType == "out_mf"
          ? "InvestorAccountFundImported"
          : "InvestorAccountFundFolio",
      investor_account_entity_id: entityId,
      old_perc_val: old_perc_val,
      perc_val: perc_val,
      goal_id: parseInt(goal_id),
    });
    if (resp && resp.code && resp.code == 100) {
      await this._loadData(JAMA_FOLIOS, goal_id);
      let attachedValue = numberFormat(((value * perc_val) / 100).toFixed(0));
      this.setState({
        showToast: "true",
        toastTitle: isMobile
          ? `${perc_val}% of fund folio attached to goal.`
          : `₹${attachedValue} i.e. ${perc_val}% of fund folio has been attached to ${this.state.goal_name}.`,
        isLoading: false,
      });
    }
  };

  attachAsset = async (old_perc_val, goal_id, perc_val, entityId, value) => {
    this.setState({ showToast: "false", toastTitle: "", isLoading: true });
    let resp = await mapInvestorAccountEntityToGoal({
      investor_account_entity_type: "InvestorAccountAsset",
      investor_account_entity_id: entityId,
      old_perc_val: old_perc_val,
      perc_val: perc_val,
      goal_id: parseInt(goal_id),
    });
    if (resp && resp.code && resp.code == 100) {
      await this._loadData(ASSETS, goal_id);
      let attachedValue = numberFormat(((value * perc_val) / 100).toFixed(0));
      this.setState({
        showToast: "true",
        toastTitle: isMobile
          ? `${perc_val}% of asset attached to goal.`
          : `₹${attachedValue} i.e. ${perc_val}% of asset has been attached to ${this.state.goal_name}.`,
        isLoading: false,
      });
    }
  };

  _renderStocks = (item) => {
    const { showImportStock, isImportingFunds, error } = this.state;
    if (item) {
      if (item.header) {
        return (
          <View style={{ flexDirection: "column" }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 30,
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontSize: 15,
                  fontWeight: "bold",
                  color: "#121212",
                  marginLeft: 16,
                }}
              >
                {item.heading}
              </Text>
              <Text
                style={[
                  {
                    color: "#410DAA",
                    fontWeight: "bold",
                    textAlign: "right",
                    marginRight: 16,
                    fontSize: 14,
                    textDecorationLine: "underline",
                  },
                ]}
                onPress={() => {
                  // this.setState({
                  //   showImportStock: !this.state.showImportStock,
                  // });
                  if (!showImportStock) {
                    this.props.navigation.navigate("ViewMode", {
                      screen: "ImportStocks",
                      params: {
                        from: "attachexisting",
                        goal_name: this.state.goal_name,
                        active: this.state.activeIndex,
                      },
                    });
                  }
                }}
              >
                {!showImportStock ? "Import Stocks" : "Hide Import Stocks"}
              </Text>
            </View>
            {showImportStock && (
              <View
                style={[
                  localStyles.form,
                  {
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 13,
                    fontWeight: "700",
                    color: "#464E5F",
                    marginBottom: 11,
                    zIndex: -1,
                  }}
                >
                  Upload your stock portfolio / NSDL statement in ' .xlsx, .pdf
                  ' format.
                </Text>
                <Text
                  style={{
                    ...localStyles.errorText,
                    paddingVertical: 0,
                    marginVertical: 0,
                    paddingLeft: 2,
                  }}
                >
                  {error || ""}
                </Text>
                <Button
                  uppercase={false}
                  mode={"contained"}
                  onPress={() => {
                    this._selectFile([
                      "application/pdf",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ]);
                  }}
                  loading={isImportingFunds}
                  color={"#410DAA"}
                  style={styles.nextButton}
                >
                  <Text style={styles.next}>Upload File</Text>
                </Button>
              </View>
            )}
          </View>
        );
      } else {
        return (
          <>
            <AvailableStock
              style={{ paddingHorizontal: 16 }}
              name={item.account_details}
              attached={item.this_goal_perc}
              available={item.perc_available}
              value={item.total_value}
              goalId={goalid}
              dematId={item.pk}
              isLoading={this.state.isLoading}
              onAttach={(percentage, dematId, value) => {
                this.attachStock(
                  item.type,
                  this.props.route.params.goal_id,
                  percentage,
                  dematId,
                  value
                );
              }}
            />
          </>
        );
      }
    }
    return null;
  };

  _renderMF = (item) => {
    const { showImportMF, isImportingFunds, email, emailError } = this.state;
    if (item) {
      if (item.header) {
        return (
          <View style={{ flexDirection: "column" }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 30,
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontSize: 15,
                  fontWeight: "bold",
                  color: "#121212",
                  marginLeft: 16,
                }}
              >
                {item.heading}
              </Text>
              <Text
                style={[
                  {
                    color: "#410DAA",
                    fontWeight: "bold",
                    textAlign: "right",
                    marginRight: 16,
                    fontSize: 14,
                    textDecorationLine: "underline",
                  },
                ]}
                onPress={() => {
                  // this.setState({
                  //   showImportMF: !this.state.showImportMF,
                  // });
                  if (!showImportMF) {
                    this.props.navigation.navigate("ViewMode", {
                      screen: "ImportStocks",
                      params: {
                        from: "attachexisting",
                        goal_name: this.state.goal_name,
                        active: this.state.activeIndex,
                      },
                    });
                  }
                }}
              >
                {!showImportMF
                  ? "Import Mutual Funds"
                  : "Hide Import Mutual Funds"}
              </Text>
            </View>
            {showImportMF && (
              <ScrollView
                style={localStyles.form}
                keyboardShouldPersistTaps={"always"}
                contentContainerStyle={{ alignItems: "center" }}
              >
                <View style={localStyles.formItem}>
                  <TextInputField
                    label="Enter the email id you used for mutual fund investment"
                    value={`${email || ""}`}
                    onChangeText={this.onEmailChange}
                    onSubmitEditing={this.onImportPressed}
                    style={[{ width: 320 }]}
                  />
                  {false && email != null && email != "" && (
                    <ANTIcon
                      name="closecircle"
                      color={"#EF5D12"}
                      size={12}
                      style={{
                        paddingVertical: 5,
                        paddingLeft: 5,
                      }}
                      onPress={() => {
                        this.clearEmail();
                      }}
                    />
                  )}
                </View>
                <Text
                  style={{
                    ...localStyles.errorText,
                    paddingVertical: 0,
                    marginVertical: 0,
                    paddingLeft: 2,
                  }}
                >
                  {emailError || ""}
                </Text>
                <Button
                  uppercase={false}
                  mode={"contained"}
                  onPress={this.onImportPressed}
                  loading={isImportingFunds}
                  color={"#410DAA"}
                  style={[styles.nextButton, { marginBottom: 20 }]}
                >
                  <Text style={styles.next}>Import</Text>
                </Button>
              </ScrollView>
            )}
          </View>
        );
      } else {
        return (
          <>
            <AvailableMF
              style={{ paddingHorizontal: 16 }}
              name={
                item.fundType == "out_mf"
                  ? item.amcfundscheme__name
                  : item.investoraccountfund__amcfundscheme__name
              }
              attached={item.this_goal_perc}
              available={item.perc_available}
              value={item.latest_value_at_nav}
              goalId={goalid}
              bse_folio_no={
                item.fundType == "out_mf"
                  ? item.imported_folio_number
                  : item.bse_folio_no
              }
              entityId={
                item.fundType == "out_mf"
                  ? item.investoraccountfundimported_id
                  : item.investoraccountfundfolio_id
              }
              isLoading={this.state.isLoading}
              onAttach={(percentage, entityId, value) => {
                this.attachMF(
                  item.this_goal_perc,
                  this.props.route.params.goal_id,
                  percentage,
                  entityId,
                  value,
                  item.fundType
                );
              }}
            />
          </>
        );
      }
    }
    return null;
  };

  _renderAssets = (item) => {
    const { showImportAsset, isImportingFunds, error } = this.state;
    if (item) {
      if (item.header) {
        return (
          <View style={{ flexDirection: "column" }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 30,
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontSize: 15,
                  fontWeight: "bold",
                  color: "#121212",
                  marginLeft: 16,
                }}
              >
                {item.heading}
              </Text>
              <Text
                style={[
                  {
                    color: "#410DAA",
                    fontWeight: "bold",
                    textAlign: "right",
                    marginRight: 16,
                    fontSize: 14,
                    textDecorationLine: "underline",
                  },
                ]}
                onPress={() => {
                  this.setState({
                    showImportAsset: !this.state.showImportAsset,
                  });
                }}
              >
                {!showImportAsset ? "Add Asset" : "Hide Add Asset"}
              </Text>
            </View>
            {showImportAsset && (
              <>
                <View style={{ backgroundColor: "white" }}>
                  <AddAsset
                    title={"Add New Asset"}
                    hideTitle={true}
                    headingStyle={{
                      paddingHorizontal: 16,
                    }}
                    style={{ marginBottom: 50 }}
                    onAddAsset={this.addAndOrTagAsset}
                  />
                </View>
              </>
            )}
          </View>
        );
      } else {
        return (
          <>
            <AvailableAsset
              style={{ paddingHorizontal: 16 }}
              name={item.name}
              attached={item.this_goal_perc}
              available={item.perc_available}
              value={item.latest_value}
              asset_type={item.asset_type}
              goalId={goalid}
              entityId={item.investoraccountasset_id}
              isLoading={this.state.isLoading}
              onAttach={(percentage, entityId, value) => {
                this.attachAsset(
                  item.this_goal_perc,
                  this.props.route.params.goal_id,
                  percentage,
                  entityId,
                  value
                );
              }}
            />
          </>
        );
      }
    }
    return null;
  };

  _renderMutualFund = (item, index) => {
    if (item) {
      if (item.header) {
        return (
          <View style={{ backgroundColor: "white", paddingVertical: 5 }}>
            <NewHeading
              title={item.sectionTitle}
              headingTitleContainerStyle={{ marginLeft: 5 }}
              style={{ alignItems: "center" }}
            />
          </View>
        );
      } else {
        return (
          <GoalTagMutualFunds
            data={item}
            index={index}
            action={"attach"}
            SlideInLeft={this.state.SlideInLeft}
            slided={this.state.slided}
            startAnimation={this.startAnimation}
            activeFundId={this.state.activeEditId}
            onEditFund={(activeEditId) => {
              this.startAnimation(true, activeEditId);
            }}
            onDetachFund={() => {
              this.attachFund(item);
            }}
            onCancelPress={() => {
              this.startAnimation(false);
            }}
            isLoading={this.state.isAttaching}
          />
        );
      }
    }
  };

  loadData = async (index) => {
    if (goalid) {
      switch (index) {
        case 1:
          // this._getfolios(goalid);
          break;
        case 2:
          this._getfolios(goalid);
          break;
        default:
          break;
      }
    }
  };

  getStickyHeaderIndices = (data) => {
    let result = [];
    if (data) {
      data.forEach((element, index) => {
        if (element.header) {
          result.push(index + 1);
        }
      });
    }

    return result;
  };

  getActiveTabData = () => {
    const { activeIndex } = this.state;
    let activeTab;
    switch (activeIndex) {
      case 1:
        activeTab = STOCKS;
        break;
      case 2:
        activeTab = JAMA_FOLIOS;
        break;
      case 3:
        activeTab = ASSETS;
        break;
      default:
        activeTab = STOCKS;
    }

    const data = this.state[activeTab];

    return data && data.length ? data : [];
  };

  getAssetData = () => {
    const data = this.state[ASSETS];
    let realEstateData = [];
    let equityData = [];
    let debtData = [];
    let insuranceData = [];
    let goldData = [];

    data &&
      data.forEach((item) => {
        if (item.asset_type && item.asset_type.slice(0, 2) == "RE") {
          realEstateData.push(item);
        }
        if (item.asset_type && item.asset_type.slice(0, 2) == "EQ") {
          equityData.push(item);
        }
        if (item.asset_type && item.asset_type.slice(0, 2) == "DB") {
          debtData.push(item);
        }
        if (item.asset_type && item.asset_type.slice(0, 2) == "IN") {
          insuranceData.push(item);
        }
        if (item.asset_type && item.asset_type.slice(0, 2) == "GD") {
          goldData.push(item);
        }
      });

    return [realEstateData, equityData, debtData, insuranceData, goldData];
  };

  _selectFile = (fileType) => {
    this.setState({ fileLoading: false });
    selectOneFile(fileType).then((res) => {
      if (res) {
        this.props.navigation.navigate("App", {
          screen: "Dashboard",
          params: {
            screen: "Portfolio",
            params: {
              screen: "ImportStocksDetails",
              params: {
                file: res,
              },
            },
          },
        });
      }
    });
  };

  renderHeader = () => {
    const { email, emailError, isImportingFunds, error } = this.state;
    if (this.state.activeIndex == 1) {
      return (
        <>
          <NewHeading
            title={"Import Stock Portfolio"}
            style={{
              backgroundColor: "#fff",
            }}
            headingTitleStyle={{
              fontSize: 15,
              fontWeight: 600,
              marginLeft: 15,
            }}
            headingTitleContainerStyle={{ flex: 0.6 }}
            sideTitleContainerStyle={{
              flex: 0.4,
            }}
          />
          <View
            style={[
              localStyles.form,
              { width: "100%", justifyContent: "center", alignItems: "center" },
            ]}
          >
            <Text
              style={{
                fontFamily: "Roboto",
                fontSize: 13,
                fontWeight: "700",
                color: "#464E5F",
                marginBottom: 11,
                zIndex: -1,
              }}
            >
              Upload your stock portfolio / NSDL statement in ' .xlsx, .pdf '
              format.
            </Text>
            <Text
              style={{
                ...localStyles.errorText,
                paddingVertical: 0,
                marginVertical: 0,
                paddingLeft: 2,
              }}
            >
              {error || ""}
            </Text>
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={() => {
                this.props.navigation.navigate("ViewMode", {
                  screen: "ImportStocks",
                  params: {
                    from: "attachexisting",
                    goal_name: this.state.goal_name,
                    active: this.state.activeIndex,
                  },
                });
              }}
              loading={isImportingFunds}
              color={"#410DAA"}
              style={styles.nextButton}
            >
              <Text style={styles.next}>Import Stocks</Text>
            </Button>
          </View>
        </>
      );
    }
    if (this.state.activeIndex == 2) {
      return (
        <>
          <NewHeading
            title={"Import Mutual Funds"}
            style={{
              backgroundColor: "#fff",
            }}
            headingTitleStyle={{
              fontSize: 15,
              fontWeight: 600,
              marginLeft: 15,
            }}
            headingTitleContainerStyle={{ flex: 0.6 }}
            sideTitleContainerStyle={{
              flex: 0.4,
            }}
          />
          <ScrollView
            style={localStyles.form}
            keyboardShouldPersistTaps={"always"}
            contentContainerStyle={{ alignItems: "center" }}
          >
            <View style={localStyles.formItem}>
              <TextInputField
                label="Enter the email id you used for mutual fund investment"
                value={`${email || ""}`}
                onChangeText={this.onEmailChange}
                onSubmitEditing={this.onImportPressed}
                style={[{ width: 320 }]}
              />
              {false && email != null && email != "" && (
                <ANTIcon
                  name="closecircle"
                  color={"#EF5D12"}
                  size={12}
                  style={{
                    paddingVertical: 5,
                    paddingLeft: 5,
                  }}
                  onPress={() => {
                    this.clearEmail();
                  }}
                />
              )}
            </View>
            <Text
              style={{
                ...localStyles.errorText,
                paddingVertical: 0,
                marginVertical: 0,
                paddingLeft: 2,
              }}
            >
              {emailError || ""}
            </Text>
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={this.onImportPressed}
              loading={isImportingFunds}
              color={"#410DAA"}
              style={styles.nextButton}
            >
              <Text style={styles.next}>Import</Text>
            </Button>
          </ScrollView>
        </>
      );
    }
    if (this.state.activeIndex == 3) {
      return (
        <>
          <NewHeading
            title={"Add New Asset"}
            style={{
              backgroundColor: "#fff",
            }}
            headingTitleStyle={{
              fontSize: 15,
              fontWeight: 600,
              marginLeft: 15,
            }}
            headingTitleContainerStyle={{ flex: 0.6 }}
            sideTitleContainerStyle={{
              flex: 0.4,
            }}
          />
          <View
            style={[
              localStyles.form,
              { width: "100%", justifyContent: "center", alignItems: "center" },
            ]}
          >
            {this.state.showRightButton && (
              <>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 13,
                    fontWeight: "700",
                    color: "#464E5F",
                    marginBottom: 11,
                    zIndex: -1,
                  }}
                >
                  Click on the button below to add new asset.
                </Text>
                <Button
                  uppercase={false}
                  mode={"contained"}
                  onPress={() => {
                    this.setState({
                      showRightButton: !this.state.showRightButton,
                    });
                  }}
                  loading={isImportingFunds}
                  color={"#410DAA"}
                  style={styles.nextButton}
                >
                  <Text style={styles.next}>
                    {this.state.showRightButton ? "Add New Asset" : ""}
                  </Text>
                </Button>
              </>
            )}
          </View>
          <View style={{ backgroundColor: "white" }}>
            {!this.state.showRightButton && (
              <AddAsset
                title={"Add New Asset"}
                hideTitle={true}
                headingStyle={{
                  paddingHorizontal: 16,
                }}
                style={{ marginBottom: 50 }}
                onAddAsset={this.addAndOrTagAsset}
              />
            )}
          </View>
        </>
      );
    }
    return null;
  };

  addAndOrTagAsset = async (data) => {
    this.setState({ showToast: "false" });
    const { assetType, attachToGoal, assetId } = data;
    const { goal_id } = this.state;

    if (attachToGoal) {
      try {
        const payload = {
          goal_id: goal_id,
          id: assetId,
          is_active: new String(attachToGoal),
          type: assetType,
          scheme: "",
        };

        this.setState({ assetAttaching: true });
        this.setState({ showRightButton: !this.state.showRightButton });
        const response = await tagGoal(payload);
        if (response && response.code == 100) {
          this._loadData([ASSETS], goal_id, false);
        }
      } finally {
        this.setState({ assetAttaching: false });
        this.setState({
          showToast: "true",
          toastTitle: "Asset added successfully",
        });
        this.forceUpdate();
      }
    }
  };

  scrollToTop = () => {
    this._scrollView &&
      this._scrollView.getNode().scrollToOffset({ animated: true, offset: 0 });
  };

  handleClick = () => {
    const { goal_id } = this.state;
    this.props.navigation.dispatch(
      StackActions.replace("GoalDetails", { goal_id })
    );
  };

  render() {
    const { isDataLoading } = this.state;
    let data = this.getActiveTabData();
    return (
      <SafeAreaView
        style={{ flex: 1, backgroundColor: "white", maxWidth: "80%" }}
      >
        <>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Attach Investments"]}
            onPressBack={() => {
              this.props.navigation.push("GoalDetails", {
                goal_id: this.state.goal_id,
                activeIndex: this.state.activeIndex,
              });
            }}
          />
          {this.state.showToast == "true" ? (
            <ShowToast
              showToast={true}
              type="status"
              title={this.state.toastTitle}
              duration="5"
            />
          ) : null}
          <Tabs
            activeIndex={this.state.activeIndex}
            onPressTab={(index) => {
              if (index != this.state.activeIndex) {
                this.setState({
                  activeIndex: index,
                  activeEditId: null,
                  isAttaching: false,
                });
              }
              this.scrollToTop();
            }}
          />
          {isDataLoading ? (
            <ActivityIndicator
              size="small"
              color={"#410DAA"}
              style={{ paddingTop: 100 }}
            />
          ) : (
            <>
              <Animated.FlatList
                data={data || []}
                renderItem={this.renderListItem}
                extraData={[this.state]}
                keyExtractor={(item, index) => index.toString()}
                removeClippedSubviews={false}
                contentContainerStyle={{
                  flexGrow: 1,
                  paddingBottom: 200,
                }}
                showsVerticalScrollIndicator={true}
                ref={(c) => (this._scrollView = c)}
                scrollEventThrottle={1}
                initialNumToRender={10}
                ListEmptyComponent={this.renderHeader()}
              />
            </>
          )}
          <View style={{ height: width < 1200 ? 67 : 0 }}></View>
        </>
      </SafeAreaView>
    );
  }
}

const localStyles = StyleSheet.create({
  form: { marginHorizontal: 16, paddingVertical: 10 },
  formItem: {
    borderColor: "#410DAA",
    padding: 0,
    margin: 0,
  },
  inputContainer: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    margin: 0,
    padding: 0,
    alignItems: "flex-end",
    justifyContent: "flex-start",
    marginBottom: 0,
    paddingBottom: 0,
    alignSelf: "flex-start",
  },
  inputLabel: {
    color: "#BCBCBC",
    fontSize: 13,
    marginVertical: 0,
    paddingVertical: 0,
  },
  errorText: {
    color: "red",
    fontSize: 10,
    height: 16,
  },
  inputText: {
    color: "#EF5D12",
    padding: 0,
    margin: 0,
    fontSize: 16,
    height: 25,
    alignSelf: "flex-end",
  },
  clearIcon: {
    alignItems: "flex-end",
    paddingBottom: 5,
  },
  goalStatusContainer: {
    backgroundColor: "#fff",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    paddingBottom: 10,
  },
  header: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    overflow: "hidden",
    zIndex: 0,
  },
  backgroundImage: {
    width: null,
  },
});

const styles = StyleSheet.create({
  nextButton: {
    paddingHorizontal: 16,
    marginTop: 10,
    width: isMobile ? "60%" : "20%",
    marginLeft: isMobile ? "20%" : "40%",
    marginRight: isMobile ? "20%" : "40%",
  },
  next: {
    color: "#fff",
    fontSize: 13,
  },
});

const mapStateToProps = (state) => ({
  goals: state.goals.goals,
});

const mapDispatchToProps = (dispatch) => {
  return {
    detachOrAttachToGoal: (data, value, id, scheme) =>
      dispatch(detachOrAttachToGoal(data, value, id, scheme)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachExisting);
