import { GET_PLANS, LOGOUT } from "../actions/types";

const initialState = {
  landingPageResponse: null,
  getMySIPsResponse: null,
};

const plansReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANS:
      return {
        ...state,
        landingPageResponse: {
          ...action.payload.landingPageResponse,
        },
        getMySIPsResponse: {
          ...action.payload.getMySIPsResponse,
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default plansReducer;
