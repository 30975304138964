import React, { useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  CheckBox,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
  FlatList,
  Dimensions,
  Pressable,
} from "react-native";
import { sm, md, lg, xl, twoxl } from "../../../Responsive";
import RadioButton from "../../onboarding/radio_risk";
import Header from "../header_after_login";
import Risk_profile from "../risk_profile";
import Progressbar from "../progressbar";
import { getRiskProfileQuestions, saveRiskProfile } from "../../../api";
import { getUserDetails, isMobile, isWeb } from "../../../utils";
import { AntDesign } from "@expo/vector-icons";
import { ShowToast } from "../../../components/Common/Toast";
import Store from "../../../store";
const { width } = Dimensions.get("window");

const PROP = [
  {
    key: 1,
    text: "option1",
  },
  {
    key: 2,
    text: "option2",
  },
];
export default class risk_profile1 extends React.Component {
  constructor(props) {
    super(props);

    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
    }
    this.state = {
      user_id,
      data: null,
      activeIndex: 0,
      isLoading: false,
      gettingQuestions: true,
      activeIndex: 0,
      answers: [],
    };
  }

  async componentDidMount() {
    try {
      let userDetails = getUserDetails();
      const userIdToFetchDetails =
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
          ? Store.getState().dashboard.viewModeUserId.user_id
          : String(userDetails.customer_id);
      const questions = await getRiskProfileQuestions(userIdToFetchDetails);
      if (questions && questions.results) {
        let answers = [];
        questions.results.forEach((item) => {
          answers.push(item.answer);
        });
        this.setState({
          data: { questions: questions.results },
          answers,
          gettingQuestions: false,
        });
      } else {
        this.setState({ gettingQuestions: false });
      }
    } catch (error) {
      this.setState({ gettingQuestions: false });
    }
  }

  handleContinue = async () => {
    try {
      if (this.state.user_id) {
        let options = {};
        const { params } = this.props.route;
        if (params) {
          options = { ...params };
        }
        options["first"] = false;
        this.props.navigation.navigate("Assets", { ...options });
        return;
      }

      const isValid = this.checkIfAllQuestionsAttempted();
      if (!isValid) {
        this.setState({
          error: true,
          errorText: "Please answer all questions",
        });
        return;
      } else {
        this.setState({ submittingAnswers: true, errorText: null });
      }

      const { answers } = this.state;
      const data = {
        option_ids: answers,
      };

      const response = await saveRiskProfile(data);

      if (response && response.code == 100) {
        this.setState({
          submittingAnswers: false,
          showRiskProfileResult: true,
          riskProfileResult: response,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        submittingAnswers: false,
      });
    }
  };

  renderRiskProfileQuestion = ({ item, index: questionIndex }) => {
    let options = [];
    item.options.forEach((option, index) => {
      options.push({ key: option.option_id, text: option.text });
    });

    const { answers, activeIndex } = this.state;
    const length = answers.length;
    if (activeIndex == questionIndex) {
      return (
        <Pressable
          style={{
            borderRadius: 12,
            marginBottom: 20,
            borderWidth: 1,
            borderColor: "rgba(65, 13, 170, 0.2)",
          }}
          onPress={() => {
            this.setState({ activeIndex: questionIndex });
          }}
        >
          <View>
            <Text style={styles.question}>
              {questionIndex + 1}. {item.question}
            </Text>
            <RadioButton
              PROP={options}
              value={answers[questionIndex]}
              onPress={(value) => {
                let updatedAnswers = [...answers];
                updatedAnswers[questionIndex] = value;
                this.setState({
                  answers: updatedAnswers,
                  activeIndex:
                    questionIndex < length - 1 ? questionIndex + 1 : null,
                });
              }}
              style={{
                paddingLeft: isMobile ? 12 : 45,
              }}
            />
          </View>
        </Pressable>
      );
    } else {
      const selectedOption = answers[questionIndex];
      const isAnswered = selectedOption != null;
      let choosenOptionText = null;
      if (isAnswered && !selectedOption) {
        choosenOptionText = item.options.filter(
          (option) => option.option_id == selectedOption
        )[0]["text"];
      }
      return (
        <TouchableOpacity
          style={[styles.notAnsweredContainer]}
          onPress={() => {
            this.setState({ activeIndex: questionIndex });
          }}
        >
          <>
            <View style={styles.notAnswered}>
              <Text style={styles.question}>
                {questionIndex + 1}. {item.question}
              </Text>
              <View style={styles.isAnswered}>
                {isAnswered && (
                  <AntDesign name="checkcircle" size={17} color="#009105" />
                )}
              </View>
            </View>
            <Text style={styles.choosenOptionText}>
              {choosenOptionText || " "}
            </Text>
          </>
        </TouchableOpacity>
      );
    }
  };

  handleRevise = () => {
    this.setState({
      showRiskProfileResult: false,
    });
  };

  checkIfAllQuestionsAttempted = () => {
    const { answers } = this.state;
    const notAttemptedAnswers = answers.filter((answer) => answer == null);
    return notAttemptedAnswers.length == 0;
  };

  render() {
    const {
      data,
      showRiskProfileResult,
      riskProfileResult,
      submittingAnswers,
      errorText,
    } = this.state;

    if (showRiskProfileResult) {
      return (
        <>
          <ShowToast
            showToast={true}
            type="status"
            title="Risk profile: Custom strategies for your goals & tolerance."
            duration="10"
          />
          <Risk_profile
            navigation={this.props.navigation}
            route={this.props.route}
            result={riskProfileResult}
            onRevise={this.handleRevise}
          />
        </>
      );
    }

    const { gettingQuestions } = this.state;
    const isValid = this.checkIfAllQuestionsAttempted();
    return (
      <View style={styles.container}>
        <Header
          navigation={this.props.navigation}
          route={this.props.route}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <Progressbar
          color2="#410DAA"
          color3="#410daa"
          color4="#410DAA"
          color5="#410DAA"
          color6="#410DAA"
          color7="#f6f6f6"
          color8="#f6f6f6"
          color9="#f6f6f6"
          color10="#f6f6f6"
          color11="#f6f6f6"
          color12="#f6f6f6"
          bcolor1="#410DAA"
          bcolor2="#410DAA"
          bcolor3="#410DAA"
          bcolor4="#f6f6f6"
          bcolor5="#f6f6f6"
          bagcolor1="#410DAA"
          bagcolor2="#410DAA"
          bagcolor3="#410DAA"
          bagcolor4="#ffffff"
          bagcolor5="#ffffff"
          markdisplay1="none"
          markdisplay2="none"
          markdisplay3="none"
          markdisplay4="none"
          markdisplay5="none"
          dotdisplay1="none"
          dotdisplay2="none"
          dotdisplay3="none"
          dotdisplay4="none"
          dotdisplay5="none"
        />
        {!gettingQuestions ? (
          <ScrollView style={styles.mainContainer}>
            <View style={styles.body}>
              {isWeb && (
                <View style={styles.left}>
                  <Text style={styles.terms}>Risk Assessment</Text>
                  <Text style={styles.please}>
                    Required per regulation to assure suitability of advice
                  </Text>
                  <Image
                    source={
                      // "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-2.png"
                      "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Invest+like+the+best.png"
                    }
                    style={styles.left_img}
                  />
                  <View
                    style={{
                      flexDirection: "row",
                      width: 430,
                      borderWidth: 1,
                      borderColor: "rgba(65, 13, 170, 0.2)",
                      backgroundColor: "#ffffff",
                      paddingVertical: 21,
                      paddingLeft: 20,
                      paddingRight: 6,
                      marginTop: 33,
                      marginBottom: 22,
                    }}
                  >
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/fluent_lock-shield-20-regular.png"
                      }
                      style={styles.lock}
                    />
                    <Text style={styles.jam_we}>
                      Information is encrypted using 256-bit keys and stored
                      securely
                    </Text>
                  </View>
                  <Text style={styles.any}>
                    Any Queries?{" "}
                    <TouchableOpacity
                      onPress={() => {
                        this.props.navigation.navigate("App", {
                          screen: "Dashboard",
                          params: {
                            screen: "Dashboard",
                            params: {
                              screen: "GetHelp",
                              params: {},
                            },
                          },
                        });
                      }}
                    >
                      <Text style={{ color: "#410DAA", fontWeight: "bold" }}>
                        Get Help
                      </Text>
                    </TouchableOpacity>
                  </Text>
                </View>
              )}

              <View style={styles.right}>
                {true && (
                  <View>
                    <Text style={styles.terms}>Risk Assessment</Text>
                    <Text style={styles.please}>
                      Required per regulation to assure suitability of advice
                    </Text>
                  </View>
                )}

                {data && (
                  <FlatList
                    data={data.questions}
                    ref={(ref) => {
                      this.pendingPaymentsTable = ref;
                    }}
                    renderItem={this.renderRiskProfileQuestion}
                    keyExtractor={(item, index) => `question_${index}`}
                    ItemSeparatorComponent={() => {
                      return <View />;
                    }}
                  />
                )}

                {!isValid && (
                  <Text style={styles.errorText}>{errorText || "  "}</Text>
                )}

                {submittingAnswers ? (
                  <ActivityIndicator
                    size={"large"}
                    color={"#410DAA"}
                    style={{ alignSelf: "center" }}
                  />
                ) : Store.getState().dashboard &&
                  Store.getState().dashboard.viewModeUserId &&
                  Store.getState().dashboard.viewModeUserId.user_id ? (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        this.handleContinue();
                      }}
                    >
                      <Text style={styles.viewModeText}>Next {">>>"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        let url = new URL(global.location.href);
                        location.replace(
                          url.origin +
                            "/dashboard/home?user_id=" +
                            this.state.user_id
                        );
                      }}
                    >
                      <Text style={styles.viewModeText}>
                        Go to Dashboard {">>>"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <TouchableOpacity
                    onPress={this.handleContinue}
                    style={[
                      styles.continueButton,
                      !isValid && styles.continueDisabled,
                    ]}
                  >
                    <Text style={styles.next}>Continue</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </ScrollView>
        ) : (
          <View style={styles.loaderContainer}>
            <ActivityIndicator
              size={"large"}
              color={"#410DAA"}
              style={{ alignSelf: "center" }}
            />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flex: 1 },
  mainContainer: {},
  body: {
    flexDirection: isMobile ? "column" : "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: md(width) ? 102 : 17,
    paddingRight: md(width) ? 95 : 17,
    paddingBottom: 60,
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  left: {
    flex: 0.45,
    paddingTop: 55,
  },
  right: {
    flex: md(width) ? 0.55 : 1,
    paddingTop: isMobile ? 20 : 50,
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },
  lock: {
    resizeMode: "contain",
    width: 30,
    height: 30,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 18 : 16,
    lineHeight: md(width) ? 21 : 17,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 15 : 13,
    lineHeight: md(width) ? 17 : 18,
    color: "#464E5F",
    paddingTop: 12,
    paddingBottom: 32,
    paddingRight: md(width) ? 155 : 0,
    letterSpacing: 0.16,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 21,
    fontWeight: "bold",
    color: "#464E5F",
  },
  border: {
    borderWidth: 1,
    borderColor: "solid rgba(65, 13, 170, 0.2)",
    borderRadius: 12,
    marginTop: 17,
    paddingVertical: 38,
    paddingHorizontal: 48,
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 18,
    marginTop: 20,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  next: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    fontWeight: "bold",
  },
  continueButton: {
    paddingVertical: 12,
    paddingHorizontal: 79,
    backgroundColor: "#410DAA",
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40.7,
    alignSelf: "center",
  },
  continueDisabled: {
    backgroundColor: "gray",
  },
  question: {
    paddingVertical: 11,
    marginLeft: !isMobile ? 22 : 22,
    fontWeight: "bold",
    fontSize: md(width) ? 14 : 13,
    lineHeight: 21,
    flex: 0.9,
    flexWrap: "wrap",
  },
  isAnswered: {
    flex: 0.1,
    alignItems: "center",
  },
  notAnsweredContainer: {
    // border:"1px solid rgba(65, 13, 170, 0.2)",
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.2)",
    borderRadius: 12,
    marginBottom: md(width) ? 20 : 15,
    paddingLeft: !isMobile ? 5 : 15,
    paddingRight: isMobile ? 16 : 22,
    paddingVertical: md(width) ? 38 : 15,
  },
  notAnswered: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  choosenOptionText: {
    paddingLeft: 36,
    color: "#410DAA",
    fontSize: 14,
  },
  errorText: {
    color: "red",
    fontSize: 15,
    marginBottom: 0,
    marginLeft: isMobile ? 0 : 40,
  },
});
