import { Platform } from "react-native";
import { MAIN_URL, GATEWAY, apiMainURL } from "./urls";
// import { encryptPayload, logSentryError } from "../utils";
import { encryptPayload } from "../utils";
import Store from "../store";

export const GATEWAY_ENVIRONMENT = GATEWAY;

export const getTokenFromStore = () => {
  const { userData, dashboard } = Store.getState();
  let { token } = userData.userProfileData;
  if (dashboard && dashboard.tokenResponse && dashboard.tokenResponse.token) {
    token = dashboard.tokenResponse.token;
  }
  return token // || "0a271c13253794ffa67725732d8f687c56a06711";
};

const setAuthorizationHeader = (url) => {
  const token = getTokenFromStore();
  const apis_with_out_token = [
    "/app/api/login/",
    "/app/api/skip_customer_login/",
    "/app/api/get_simply_grow_subscriber_data/",
    "/app/api/set_reset_password_using_username/",
    "/app/api/check_customer_signup/",
    "/app/api/signup_otp_verfication/",
    "/app/api/get_lead_data_of_customer/",
    "/app/api/get_database_of_user/",
    // "/app/api/log_app_eror_in_sentry/"
  ];
  const need_token = apis_with_out_token.indexOf(url) == -1;
  let headers = {};
  if (need_token) {
    headers["Authorization"] = `token ${token}`;
  }
  return headers;
};

const putFormData = async (url, data) => {
  try {
    let urlHeader = "";
    if (Platform.OS != "web") {
      let db = await AsyncStorage.getItem("database");
      // console.log("Database in Post", db);
      if (db != undefined) {
        if(db === "regular") {
          urlHeader = "https://regular.jama.co.in";
        }else{
          urlHeader = "https://www.jama.co.in";
        } 
      }else{
        urlHeader = "https://regular.jama.co.in";
      }
    }
    const URL = (Platform.OS === "web" ? apiMainURL : urlHeader ) + url;
    const headers = setAuthorizationHeader(url);
    if (Platform.OS !== "web") {
      headers["Content-Type"] = "multipart/form-data";
    }
    return await fetch(URL, {
      method: "PUT",
      headers: {
        ...headers,
      },
      body: data,
    }).then((res) => res.json())
    .catch((error) => {
      // logSentryError(error);
      let errorText = "Something went wrong!";
      return Promise.reject({
        error: error,
        errorText: errorText,
        errorCode: 0,
      });
    });
  } catch (error) {
    // logSentryError(error);
    return Promise.reject({
      error: true,
      errorText: "Something went wrong!",
      errorCode: 0,
    });
  }
};

const patchFormData = async (url, data) => {
  try {
    let urlHeader = "";
    if (Platform.OS != "web") {
      let db = await AsyncStorage.getItem("database");
      // console.log("Database in Post", db);
      if (db != undefined) {
        if(db === "regular") {
          urlHeader = "https://regular.jama.co.in";
        }else{
          urlHeader = "https://www.jama.co.in";
        } 
      }else{
        urlHeader = "https://regular.jama.co.in";
      }
    }
    const URL = (Platform.OS === "web" ? apiMainURL : urlHeader ) + url;
    const headers = setAuthorizationHeader(url);
    if (Platform.OS !== "web") {
      headers["Content-Type"] = "multipart/form-data";
    }
    return await fetch(URL, {
      method: "PATCH",
      headers: {
        ...headers,
      },
      body: data,
    }).then((res) => res.json())
    .catch((error) => {
      // logSentryError(error);
      let errorText = "Something went wrong!";
      return Promise.reject({
        error: error,
        errorText: errorText,
        errorCode: 0,
      });
    });
  } catch (error) {
    // logSentryError(error);
    return Promise.reject({
      error: true,
      errorText: "Something went wrong!",
      errorCode: 0,
    });
  }
};

const postFormData = async (url, data) => {
  try {
    let urlHeader = "";
    if (Platform.OS != "web") {
      let db = await AsyncStorage.getItem("database");
      // console.log("Database in Post", db);
      if (db != undefined) {
        if(db === "regular") {
          urlHeader = "https://regular.jama.co.in";
        }else{
          urlHeader = "https://www.jama.co.in";
        } 
      }else{
        urlHeader = "https://regular.jama.co.in";
      }
    }
    const URL = (Platform.OS === "web" ? apiMainURL : urlHeader ) + url;
    const headers = setAuthorizationHeader(url);
    return await fetch(URL, {
      method: "POST",
      headers: {
        ...headers,
      },
      body: data,
    }).then((res) => res.json())
    .catch((error) => {
      // logSentryError(error);
      let errorText = "Something went wrong!";
      return Promise.reject({
        error: error,
        errorText: errorText,
        errorCode: 0,
      });
    });
  } catch (error) {
    // logSentryError(error);
    return Promise.reject({
      error: true,
      errorText: "Something went wrong!",
      errorCode: 0,
    });
  }
};

const post = async (url, data, headerData = {}, encrypt_data=false) => {
  try {
    let urlHeader = "";
    if (Platform.OS != "web") {
      let db = await AsyncStorage.getItem("database");
      // console.log("Database in Post", db);
      if (db != undefined) {
        if(db === "regular") {
          urlHeader = "https://regular.jama.co.in";
        }else{
          urlHeader = "https://www.jama.co.in";
        } 
      }else{
        urlHeader = "https://regular.jama.co.in";
      }
    }
    const URL = (Platform.OS === "web" ? apiMainURL : urlHeader ) + url;
    const headers = setAuthorizationHeader(url);
    let encryptedData = null;
    if (encrypt_data) {
      encryptedData = encryptPayload(data);
    }
    return await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
        ...headerData,
      },
      body: JSON.stringify((encrypt_data && encryptedData) ? { payload: encryptedData } : data),
    }).then((res) => res.json())
    .catch((error) => {
      // logSentryError(error);
      let errorText = "Something went wrong!";
      return Promise.reject({
        error: error,
        errorText: errorText,
        errorCode: 0,
      });
    });
  } catch (error) {
    // logSentryError(error);
    return Promise.reject({
      error: true,
      errorText: "Something went wrong!",
      errorCode: 0,
    });
  }
};

const put = async (url, data) => {
  try {
    let urlHeader = "";
    if (Platform.OS != "web") {
      let db = await AsyncStorage.getItem("database");
      // console.log("Database in Post", db);
      if (db != undefined) {
        if(db === "regular") {
          urlHeader = "https://regular.jama.co.in";
        }else{
          urlHeader = "https://www.jama.co.in";
        } 
      }else{
        urlHeader = "https://regular.jama.co.in";
      }
    }
    const URL = (Platform.OS === "web" ? apiMainURL : urlHeader ) + url;
    const headers = setAuthorizationHeader(url);
    return await fetch(URL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
      body: JSON.stringify(data),
    }).then((res) => res.json())
    .catch((error) => {
      // logSentryError(error);
      let errorText = "Something went wrong!";
      return Promise.reject({
        error: error,
        errorText: errorText,
        errorCode: 0,
      });
    });
  } catch (error) {
    // logSentryError(error);
    return Promise.reject({
      error: true,
      errorText: "Something went wrong!",
      errorCode: 0,
    });
  }
};

const patch = async (url, data) => {
  try {
    let urlHeader = "";
    if (Platform.OS != "web") {
      let db = await AsyncStorage.getItem("database");
      // console.log("Database in Post", db);
      if (db != undefined) {
        if(db === "regular") {
          urlHeader = "https://regular.jama.co.in";
        }else{
          urlHeader = "https://www.jama.co.in";
        } 
      }else{
        urlHeader = "https://regular.jama.co.in";
      }
    }
    const URL = (Platform.OS === "web" ? apiMainURL : urlHeader ) + url;
    const headers = setAuthorizationHeader(url);
    return await fetch(URL, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
      body: JSON.stringify(data),
    }).then((res) => res.json())
    .catch((error) => {
      // logSentryError(error);
      let errorText = "Something went wrong!";
      return Promise.reject({
        error: error,
        errorText: errorText,
        errorCode: 0,
      });
    });
  } catch (error) {
    // logSentryError(error);
    return Promise.reject({
      error: true,
      errorText: "Something went wrong!",
      errorCode: 0,
    });
  }
};

const delete_service = async (url) => {
  try {
    let urlHeader = "";
    if (Platform.OS != "web") {
      let db = await AsyncStorage.getItem("database");
      // console.log("Database in Post", db);
      if (db != undefined) {
        if(db === "regular") {
          urlHeader = "https://regular.jama.co.in";
        }else{
          urlHeader = "https://www.jama.co.in";
        } 
      }else{
        urlHeader = "https://regular.jama.co.in";
      }
    }
    const URL = (Platform.OS === "web" ? apiMainURL : urlHeader ) + url;
    const headers = setAuthorizationHeader(url);
    return await fetch(URL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
    })
      .then((res) => res.json())
      .catch((error) => {
        // logSentryError(error);
        let errorText = "Something went wrong!";
        return Promise.reject({
          error: error,
          errorText: errorText,
          errorCode: 0,
        });
      });
  } catch (error) {
    // logSentryError(error);
    return Promise.reject({
      error: true,
      errorText: "Something went wrong!",
      errorCode: 0,
    });
  }
};

const get = async (url) => {
  try {
    let urlHeader = "";
    if (Platform.OS != "web") {
      let db = await AsyncStorage.getItem("database");
      // console.log("Database in Post", db);
      if (db != undefined) {
        if(db === "regular") {
          urlHeader = "https://regular.jama.co.in";
        }else{
          urlHeader = "https://www.jama.co.in";
        } 
      }else{
        urlHeader = "https://regular.jama.co.in";
      }
    }
    const URL = (Platform.OS === "web" ? apiMainURL : urlHeader ) + url;
    const headers = setAuthorizationHeader(url);
    return await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
    })
      .then((res) => res.json())
      .catch((error) => {
        // logSentryError(error);
        let errorText = "Something went wrong!";
        return Promise.reject({
          error: error,
          errorText: errorText,
          errorCode: 0,
        });
      });
  } catch (error) {
    // logSentryError(error);
    return Promise.reject({
      error: true,
      errorText: error ? error : "Something went wrong!",
      errorCode: 0,
    });
  }
};

export default {
  post,
  get,
  put,
  patch,
  patchFormData,
  delete: delete_service,
  postFormData,
  putFormData,
};
