import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Text, RadioButton } from "react-native-paper";
import theme from "../../../static/theme";

function areEqual(prevProps, nextProps) {
  return prevProps.selected == nextProps.selected;
}

const HorizontalRadio = ({ selected, ...props }) => {
  const handleButtonClick = (index) => {
    props.onSelect(index, props.values[index]);
  };

  return (
    <View style={[props.style, { flexDirection: "column", padding: 0 }]}>
      {props.title && (
        <View
          style={{
            paddingBottom: 10,
          }}
        >
          <Text
            style={[
              {
                fontFamily: "Roboto",
                fontSize: 13,
                fontWeight: "700",
                color: "#464E5F",
              },
              props.titleStyle,
            ]}
          >
            {props.title}
          </Text>
        </View>
      )}
      <View style={{ flexDirection: props.vertical ? "column" : "row" }}>
        {props.radioButtonValues.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={[
                { flexDirection: "row", alignItems: "center" },
                props.optionStyle ? { ...props.optionStyle } : {},
              ]}
              onPress={() => {
                handleButtonClick(index);
              }}
            >
              <RadioButton.Android
                onPress={() => {
                  handleButtonClick(index);
                }}
                uncheckedColor={"#183443"}
                color={theme.colors.NEW_BRAND_COLOR}
                status={
                  props.values[index] == selected ? "checked" : "unchecked"
                }
                style={{ alignSelf: "flex-start" }}
              />
              <Text
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  marginRight: 20,
                  color:
                    props.values[index] == selected
                      ? theme.colors.NEW_BRAND_COLOR
                      : "#121212",
                }}
              >
                {item}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

export default React.memo(HorizontalRadio, areEqual);
