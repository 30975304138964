import { LOGOUT } from "../actions/types";

const INITIAL_STATE = {
  goals: [],
  loadHome: false,
};

export const actionTypes = {
  ADD_GOALS: "ADD_GOALS",
  ADD_GOAL: "ADD_GOAL",
  UPDATE_GOAL: "UPDATE_GOAL",
  DELETE_GOAL: "DELETE_GOAL",
  CLEAR_GOALS: "CLEAR_GOALS",
  REMOVE_FIN_PLAN_GOALS: "REMOVE_FIN_PLAN_GOALS",
  GOALS_LOADING: "GOALS_LOADING",
  GOALS_LOADING_FINISHED: "GOALS_LOADING_FINISHED",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_GOALS:
      return { ...state, goals: [...action.payload] };

    case actionTypes.ADD_GOAL:
      let { goals } = state;
      return { ...state, goals: [...goals, ...action.payload] };

    case actionTypes.UPDATE_GOAL:
      const remaining_goals = state.goals.filter(
        (goal) => goal.customergoal_id != action.payload.customergoal_id
      );
      return { ...state };
    // return { ...state, goals: [...remaining_goals, ...action.payload] };
    case LOGOUT:
      return INITIAL_STATE;
    case actionTypes.DELETE_GOAL:
      const remainingGoals = state.goals.filter(
        (goal) => goal.customergoal_id != action.payload
      );
      return { ...state, goals: remainingGoals };

    case actionTypes.REMOVE_FIN_PLAN_GOALS:
      const withoutFinplanGoals = state.filter((goal) => !goal.is_for_fin_plan);
      return { ...state, goals: withoutFinplanGoals };

    case actionTypes.CLEAR_GOALS:
      return { ...state, goals: [] };

    case actionTypes.GOALS_LOADING:
      return { ...state, goalsLoading: true };

    case actionTypes.GOALS_LOADING_FINISHED:
      return { ...state, goalsLoading: false };

    default:
      return state;
  }
};
