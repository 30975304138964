import React from "react";
import { connect } from "react-redux";
import {
  View,
  Text,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { Button } from "react-native-paper";
import TextInputField from "../../../components/Common/Inputs/InputField";
import { userFeedback } from "../../../api/account";
import PickerField from "../../../components/Common/Pickers/PickerField";
import { validateInput } from "../../../utils/validateInput";
import Toast from "react-native-toast-message";
import { isMobile } from "../../../utils";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";

const data = [
  {
    label: "Select",
    value: "--",
  },
  {
    label: "App has a problem",
    value: "BUG",
  },
  // {
  //   label: "New Feature Request",
  //   value: "FTR",
  // },
  {
    label: "Service Request",
    value: "SRV",
  },
  {
    label: "Could Not Place Order",
    value: "ORD",
  },
  {
    label: "Complaint",
    value: "CMP",
  },
  {
    label: "Fund Missing",
    value: "FMS",
  },
  {
    label: "Investment Transaction",
    value: "INV",
  },
  {
    label: "SIP Transaction",
    value: "SIP",
  },
  {
    label: "Withdrawal Transaction",
    value: "WDL",
  },
  {
    label: "Other",
    value: "OTH",
  },
];

class Feedback extends React.Component {
  state = {
    selectedType: "--",
    summary: "",
    loading: false,
  };

  onSubmitPress = async () => {
    const { selectedType, summary } = this.state;
    if (!selectedType || selectedType == "--") {
      Toast.show({
        text1: "Please select issue category",
        topOffset: 7,
        visibilityTime: 900000,
        type: "success",
      });
      return;
    }
    if (!summary || summary.length == 0) {
      Toast.show({
        text1: "Please add issue description",
        topOffset: 7,
        visibilityTime: 900000,
        type: "success",
      });
      return;
    }
    this.setState({
      loading: true,
    });
    try {
      const payload = {
        feedback_type: selectedType,
        feedback_text: summary,
        feedback_img: "",
      };
      const response = await userFeedback(payload);
      // console.log("response", response);
      if (response.code == 100) {
        Toast.show({
          text1: response.msg,
          topOffset: 7,
          visibilityTime: 900000,
          type: "success",
        });
      } else {
        Toast.show({
          text1: response.msg,
          topOffset: 7,
          visibilityTime: 900000,
          type: "success",
        });
      }
    } catch (e) {
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleBack = () => {
    this.props.navigation.goBack();
  };

  handleChange = (value, type, inputType) => {
    this.setState({
      [type]: value,
      [type + "Error"]: validateInput(inputType, value),
      error: null,
    });
  };

  render() {
    const { selectedType, selectedTypeError, summary } = this.state;
    return (
      <SafeAreaView style={styles.container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Raise Ticket"]}
          onPressBack={this.handleBack}
        />
        <ScrollView contentContainerStyle={styles.wrapper}>
          {!isMobile ? (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={data}
                  value={selectedType}
                  label={"Issue Category"}
                  onChange={(selectedType) =>
                    this.handleChange(selectedType, "selectedType", "select")
                  }
                  pickerStyle={[{ ...styles.inputItem, width: 300 }]}
                  isValid={selectedTypeError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select" }}
                />
              </View>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  numberOfLines={6}
                  label="Issue Description"
                  value={summary}
                  style={[{ ...styles.inputItem, width: "50%" }]}
                  editable={true}
                  multiline={true}
                  onChange={(summary) =>
                    this.handleChange(summary, "summary", "text")
                  }
                />
              </View>
              <Text
                style={{ color: "red", fontSize: 12, marginHorizontal: 16 }}
              >
                {this.state.error ? `* ${this.state.error}` : null}
              </Text>
            </>
          ) : (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={data}
                  value={selectedType}
                  label={"Issue Category"}
                  onChange={(selectedType) =>
                    this.handleChange(selectedType, "selectedType", "select")
                  }
                  pickerStyle={[{ ...styles.inputItem }]}
                  isValid={selectedTypeError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select" }}
                />
              </View>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  numberOfLines={6}
                  label="Issue Description"
                  value={summary}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  multiline={true}
                  onChange={(summary) =>
                    this.handleChange(summary, "summary", "text")
                  }
                />
              </View>
              <Text
                style={{ color: "red", fontSize: 12, marginHorizontal: 16 }}
              >
                {this.state.error ? `* ${this.state.error}` : null}
              </Text>
            </>
          )}
          {!this.state.loading ? (
            !isMobile ? (
              <Button
                uppercase={false}
                mode={"contained"}
                onPress={this.onSubmitPress}
                style={{
                  marginTop: 50,
                  width: "20%",
                  left: "30%",
                  right: "50%",
                }}
                color={"#410DAA"}
              >
                <Text style={styles.saveText}>Submit</Text>
              </Button>
            ) : (
              <Button
                uppercase={false}
                mode={"contained"}
                onPress={this.onSubmitPress}
                style={{
                  marginTop: 30,
                  width: "50%",
                  marginLeft: "25%",
                }}
                color={"#410DAA"}
              >
                <Text style={styles.saveText}>Save</Text>
              </Button>
            )
          ) : (
            <ActivityIndicator size="large" color="#410DAA" />
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  wrapper: {
    marginHorizontal: 16,
  },
  saveButton: { marginHorizontal: 16, bottom: 10, maxWidth: 400 },
  saveText: { fontSize: 14, color: "#fff" },
});

export default connect(null)(Feedback);
