import React from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  TouchableOpacity,
} from "react-native";
import Table from "../../../../components/Common/Table";
import amplitude from "../../../../utils/amplitude";
import NavigationHeader from "../../../../components/Common/NavigationHeader";
import AntDesign from "@expo/vector-icons/AntDesign";
import { formatDate, numberWithCommas } from "../../../../utils";
import { getProratedDetails } from "../../../../api/billing";
import { ActivityIndicator } from "react-native-paper";
import moment from "moment";

const response = {
  code: 100,
  msg: "Fetched",
  results: {
    bill_type: "",
    bill_period: "01 Jan 2020 - 02 Feb 2022",
    amount: 123454,
    details: [
      {
        execution_date: 1344656, //unix timestamp
        transaction_type: "Investment",
        amount_executed: 12355,
        no_of_days: 12,
				prorated_fee_perc: 1.25, // seems like here we need amount 
        prorated_amount: 1234566,
				prorated_hurdle_amount: 123546
      }
    ]
  }
};
class ProratedDetails extends React.Component {
  constructor(props) {
    super(props);
    let bill_id;
    const { params } = this.props.route;
    if(params){
      bill_id = params.bill_id || null;
    }
    this.state = {
      bill_id,
      isLoading: true
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("ProratedDetails",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("ProratedDetails", { path: this.props.navigation.getState().routeName });
      }
      const { bill_id } = this.state;
      const response = await getProratedDetails(bill_id);
      if(response && response.code == 100){
        const { results = {} } = response;
        const { bill_type, investment_period: bill_period, invested_amount: amount, details = []  } = results;
        const data = {
          bill_type,
          bill_period,
          amount,
          details: [...details].reverse()
        };
        this.setState({ data, isLoading: false });
      }else{
        this.setState({ error: "Error loading details", isLoading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ error: "Error loading details", isLoading: false });
    }
  }

  navigateBack = () => {
    const { navigation } = this.props;
    if (navigation) {
      navigation.goBack();
    }
  };

  compare = (a, b, field, sort) => {
    if (a[field] < b[field]) {
      return sort ? -1 : 1;
    }
    if (a[field] > b[field]) {
      return sort ? 1 : -1;
    }
    return 0;
  };

  handleSort = (field, sort, start, end) => {
    this.setState(
      {
        sorting_field: field,
        sorting_value: !sort,
      },
      () => {
        const { data } = this.state;
        const sortedData = [].concat(
          data.slice(0, start),
          data
            .slice(start, end)
            .sort((a, b) => this.compare(a, b, field, !sort)),
          data.slice(end, data.length)
        );
        this.setState({ data: sortedData });
      }
    );
  };

  getTableData = (data = null) => {
    if(!data || data && !data.hasOwnProperty("details")) return {};

    const headerData = [
      {
        label: "Execution Date",
        style: [styles.tableHeader,  {paddingLeft: 30}],
        sortOption: false,
        value: "execution_date"
      },
      {
        label: "Transaction Type",
        style: [styles.tableHeader],
        sortOption: false,
        key: "transaction_type",
      },
      {
        label: "Amount Executed",
        style: [styles.tableHeader],
        sortOption: false,
        key: "amount_executed",
      },
      {
        label: "No of Days",
        style: [styles.tableHeader],
        sortOption: false,
        key: "no_of_days",
      },
      {
        label: "Prorated Fee",
        style: [styles.tableHeader],
        sortOption: false,
        key: "prorated_fee",
      },
      {
        label: "Prorated Discounted Fee",
        style: [styles.tableHeader],
        sortOption: false,
        key: "prorated_discounted_fee",
      },
      {
        label: "Bill",
        style: [styles.tableHeader],
        sortOption: false,
        key: "bill",
      },
      {
        label: "Discounted Bill",
        style: [styles.tableHeader],
        sortOption: false,
        key: "discounted_bill",
      },
    ];
    let { values = [] } = this.generateDataForTable(data["details"]);
    return { headerData, values };
  };

  handleShowHoldings = async (id) => {
    const { bill_id } = this.state;
    let date = moment(id).format("DD MMM YYYY");
    date = date.split(" ").join("").toLowerCase();
    this.props.navigation.navigate("TransactionDetails", { bill_id, date });
  };

  generateDataForTable = (data) => {
    let values = [];
    data.forEach((item, index) => {
      let { execution_date, type: transaction_type, amount: amount_executed,prorated_fee, no_of_days, prorated_discounted_fee, bill, discounted_bill } = item;
      amount_executed = numberWithCommas(amount_executed);
      bill = numberWithCommas(bill);
      discounted_bill = numberWithCommas(discounted_bill);
      values.push([
        {
          valueToDisplay: execution_date,
          component: true,
          value: (
            <TouchableOpacity
              style={styles.valuationDateContainer}
              onPress={() => this.handleShowHoldings(execution_date)}
            >
              <Text
                style={[ styles.valuationDate ]}
              >
                {formatDate(execution_date)}
              </Text>
              <AntDesign
                name="arrowright"
                size={16}
                color={"#410DAA"}
                style={styles.bold}
              />
            </TouchableOpacity>
          ),
          // style: [{ paddingLeft: 38}],
          // rowItemContainerStyle: [{justifyContent:"flex-start"}]
        },
        {
          valueToDisplay: transaction_type,
          value: transaction_type,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + amount_executed,
          value: amount_executed,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: no_of_days,
          value: no_of_days,
          style: [styles.rowItem],
        },
        {
            valueToDisplay: prorated_fee+"%",
            value: prorated_fee,
            style: [styles.rowItem],
        },
        {
          valueToDisplay: prorated_discounted_fee+"%",
          value: prorated_discounted_fee,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + bill,
          value: bill,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + discounted_bill,
          value: discounted_bill,
          style: [styles.rowItem],
        },
      ]);
    });
    return { values };
  };

  render() {
    const { navigation } = this.props;
    const { data = [], isLoading, error } = this.state;

    const {
      headerData,
      values,
    } = this.getTableData(data);
    return (
      <View style={styles.container}>
        <NavigationHeader
          navigation={navigation}
          navigationHeader={[ "Bills", "View Bills", "View Details"]}
          onPressBack={this.navigateBack}
        />
        {
          (isLoading|| error) ? (
            <View style={styles.loadingContainer}>
              {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
              {error && <Text style={styles.error}>{error}</Text>}
            </View>
          ): (
            <ScrollView>
              <View style={[styles.detailsContainer, styles.borderColor]}>
                <View style={styles.headingContainer}>
                  <View>
                    <Text style={styles.header}>Advisory Fees</Text>
                    <Text style={styles.headerBillingPeriod}>
                      (Billing Period: {data["bill_period"]})
                    </Text>
                  </View>
                  <Text style={styles.billTotalAmount}>₹{numberWithCommas(parseInt(data["amount"]))}</Text>
                </View>
                <Table
                  header={headerData}
                  // gridConfig={[0.15, 0.1, 0.1, 0.15, 0.15, 0.15, 0.2]}
                  gridConfig={[0.15, 0.1, 0.15, 0.1, 0.15, 0.15, 0.1, 0.1]}
                  data={values}
                  minWidth={1500}
                />
              </View>
            </ScrollView>
          )
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: 36,
    marginVertical: 30,
  },
  borderColor: {
    borderColor: "#DBC0FF",
  },
  headingContainer: {
    paddingHorizontal: 40,
    paddingVertical: 19,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "700",
  },
  headerBillingPeriod: {
    fontSize: 15,
    fontFamily: "Roboto",
    color: "#464E5F",
    marginTop: 10,
  },
  billTotalAmount: {
    fontSize: 15,
    color: "#464E5F",
    fontFamily: "Roboto",
    fontWeight: "700",
    marginRight: 50,
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  valuationDate: { fontSize: 11 },
  valuationDateContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  active: { color: "#410DAA", fontWeight: "700" },
  bold: { fontWeight: "bold", marginLeft: 12 },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ProratedDetails;
