import React from "react";
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  StatusBar,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
} from "react-native";
import {
  capitalizeFirstLetter,
  completeMinDetails,
  decryptString,
  encryptString,
  getUserDetails,
  goalDataToPipeCommaString,
  isMobile,
  numberWithCommas,
  pipeCommaStringToGoalData,
} from "../../../utils";
import Checkbox from "expo-checkbox";
import Store from "../../../store";
import GoalsTable from "./GoalsTable";
import amplitude from "../../../utils/amplitude";
import SearchBar from "../../../components/Common/SearchBar";
import moment from "moment";
import {
  addGoal,
  getAdvisorDashboardDetials,
  getSuggestedFunds,
} from "../../../api/goals";
import {
  getCustomerDetails,
  sendMailUsingTemplate,
  uccCodeGenerate,
} from "../../../api";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { Button } from "react-native-paper";
import Modal from "../../../components/Common/Modal";
import { AntDesign } from "@expo/vector-icons";
import TextInputField from "../../../components/Common/Inputs/InputField";
import CommaTextField from "./commaTextField";
import { ShowToast } from "../../../components/Common/Toast";
import { InvestGoalCard } from "./investGoalCard";
import Toast from "react-native-toast-message";
import { StackActions } from "@react-navigation/native";
import { saveCustomerProfileData } from "../../../api/account";
import ComplianceModal from "../../../components/InvestmentOverview/ComplianceModal";

export default class InvestForGoals extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    let goalId = null;
    let status = null;
    let ordersData = null;
    let from = null;
    const { params } = this.props.route;
    if (params) {
      userId = params.user_id || null;
      goalId = params.goal_id || null;
      status = params.STATUS || null;
      ordersData = params.orders_payload || null;
      from = params.from || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }
    let orders_payload = null;
    let decryptedOrders = null;
    if (ordersData) {
      decryptedOrders = decryptString(ordersData);
      orders_payload = pipeCommaStringToGoalData(decryptedOrders);
    }
    this.state = {
      userId,
      goalId,
      status,
      from,
      orders_payload,
      isSelected: false,
      sorting_field: null,
      sorting_value: null,
      searchResults: "",
      sorting_field: null,
      sorting_value: null,
      selectedCheckboxs: "",
      userName: "",
      isAllCheckboxSelected: false,
      json: "",
      showModal: false,
      goalName: "",
      goalAmount: "",
      goalYear: "",
      goalMonth: "",
      lumpsumArr: [],
      lumpsumArrToShow: [],
      sipArr: [],
      sipArrToShow: [],
      projectedArrToShow: [],
      revisedProjectionArr: [],
      showToast: "false",
      showToastMsg: null,
      toastText: "",
      sipRequest: [],
      lumpsumRequest: [],
      totalRequest: [],
      isLoading: true,
      showLoader: false,
      showErrorToast: false,
    };
  }

  componentDidMount = async () => {
    if (Platform.OS == "web") {
      amplitude.logEvent("goals", { path: global.location.href });
    } else {
      amplitude.logEvent("goals", {
        path: this.props.navigation.getState().routeName,
      });
    }
    if (this.state.status) {
      this.setState({ showToastMsg: "true" });
      const { customer_id, customerprofile_id } = getUserDetails();
      const body = {
        customer: customer_id,
        bse_nomination_authentication_date: moment()
          .utcOffset("+05:30")
          .format(),
      };
      await saveCustomerProfileData(customerprofile_id, body);
    }

    const { userId } = this.state;
    const { params } = this.props.route;
    let skip_compliance = false;
    const customer_id = userId || null;
    let mfMinDetailsData = {};
    if (params) {
      skip_compliance = params.s || false;
    }

    const mfMinDetailsResponse = await completeMinDetails(
      true,
      customer_id,
      skip_compliance
    );
    if (mfMinDetailsResponse && !mfMinDetailsResponse.error) {
      mfMinDetailsData = mfMinDetailsResponse;
    }

    let json = null;
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      json = await getAdvisorDashboardDetials(
        Store.getState().dashboard.viewModeUserId.user_id
      );
    } else {
      json = await getAdvisorDashboardDetials();
    }
    // const json = await getAdvisorDashboardDetials();
    // this.setState({ isLoading: false });
    let selectedCheckboxs = [];
    let goalArr = [];
    let emergencyGoalsArr = [];
    let retirementGoalsArr = [];
    let otherGoalsArr = [];
    if (json && json.goals) {
      emergencyGoalsArr = json.goals.filter((goal) =>
        goal.name.includes("Emergency")
      );
      retirementGoalsArr = json.goals.filter((goal) =>
        goal.name.includes("Retire")
      );
      otherGoalsArr = json.goals.filter(
        (goal) =>
          !(goal.name.includes("Emergency") || goal.name.includes("Retire"))
      );
      let sortedOtherArr = otherGoalsArr.sort((a, b) =>
        this.compareNumbers(a.target_year, b.target_year)
      );

      goalArr = [
        ...emergencyGoalsArr,
        ...retirementGoalsArr,
        ...sortedOtherArr,
      ];
      if (this.state.goalId) {
        goalArr = goalArr.filter(
          (goal) => goal.customergoal_id == this.state.goalId
        );
      }
      let deficitGoalsArr = goalArr.filter(
        (goal) => goal.target_amount_initially_calculated > goal.projected_value
      );
      let surplusGoalsArr = goalArr.filter(
        (goal) =>
          !(goal.target_amount_initially_calculated > goal.projected_value)
      );
      goalArr = [...deficitGoalsArr, ...surplusGoalsArr];

      let sipArr = this.state.sipArr;
      let lumpsumArr = this.state.lumpsumArr;
      json.goals = goalArr;
      json.goals.forEach((item, index) => {
        sipArr[index] = parseInt(item.sip_suggested);
        lumpsumArr[index] = parseInt(item.lumpsum_suggested);
        let isDeficit =
          item.target_amount_initially_calculated > item.projected_value;
        let deficitOrSurplus = isDeficit
          ? Math.round(
              item.target_amount_initially_calculated - item.projected_value
            )
          : "Surplus";
        selectedCheckboxs.push({ ...item, toShow: false, deficitOrSurplus });
      });
      this.setState({ sipArr, lumpsumArr });
      let userData = {};
      if (this.state.userId) {
        userData = await getCustomerDetails(this.state.userId);
      } else {
        userData = await getUserDetails();
      }
      let { first_name } = userData;
      let names = first_name ? first_name.split(/\s+/) : [];
      let name = "";
      if (names.length >= 2 && names.length > 0) {
        name =
          capitalizeFirstLetter(names[0]) +
          " " +
          capitalizeFirstLetter(names[1]);
      } else {
        if (names.length == 0) {
          name = "User";
        } else {
          name = names[0];
        }
      }
      let projectedArrToShow = [];
      let revisedProjectionArr = [];
      selectedCheckboxs.forEach((item) => {
        projectedArrToShow.push(item.projected_percentage);
        revisedProjectionArr.push(item.projected_percentage);
      });
      this.setState({ projectedArrToShow, revisedProjectionArr });
      this.setState({
        selectedCheckboxs,
        json: selectedCheckboxs,
        searchResults: selectedCheckboxs,
        userName: name,
        mfMinDetailsData: mfMinDetailsData || {},
        showComplianceAlert: mfMinDetailsData != null,
        gettingMinDetails: false,
      });
      if (this.state.orders_payload) {
        await this.handleFilledOrdersPayload();
      }
      this.setState({
        isLoading: false,
      });
    }
  };

  handleFilledOrdersPayload = async () => {
    let { orders_payload, json } = this.state;
    let lumpsumArrToShow = this.state.lumpsumArrToShow;
    let lumpsumRequest = this.state.lumpsumRequest;
    let sipArrToShow = this.state.sipArrToShow;
    let sipRequest = this.state.sipRequest;
    let checkbox = this.state.selectedCheckboxs;
    json.map((item, index) => {
      let selected_order = orders_payload.find((order) => {
        return order.goal_id == item.customergoal_id;
      });
      if (selected_order) {
        if (selected_order.lumpsum_invt > 0) {
          checkbox[index].toShow = true;
          lumpsumArrToShow[index] = selected_order.lumpsum_invt;
          let lump_req = {
            goal_id: selected_order.goal_id,
            fresh_invt: selected_order.lumpsum_invt,
          };
          lumpsumRequest.push(lump_req);
        }
        if (selected_order.sip_invt > 0) {
          checkbox[index].toShow = true;
          sipArrToShow[index] = selected_order.sip_invt;
          let sip_req = {
            goal_id: selected_order.goal_id,
            fresh_invt: selected_order.sip_invt,
            is_for_sip: true,
          };
          sipRequest.push(sip_req);
        }
        this.setState({
          lumpsumArrToShow: lumpsumArrToShow,
          lumpsumRequest: lumpsumRequest,
          sipArrToShow: sipArrToShow,
          sipRequest: sipRequest,
          totalRequest: orders_payload,
          selectedCheckboxs: checkbox,
        });
        this.calculateProjectedAmt(
          lumpsumArrToShow[index],
          sipArrToShow[index],
          item.weighted_avg_growth_return / 100,
          0,
          item.duration_for_sip_suggestion,
          index,
          item.target_amount_initially_calculated,
          item.projected_value
        );
      }
    });
  };

  handleSearch = (searchText) => {
    let searchResults = [];
    let searchData = this.state.json;
    if (searchText) {
      searchResults = searchData.filter((item) => {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
      });
    } else {
      searchResults = searchData;
    }

    if (searchResults.length) {
      this.setState({
        searchText,
        searchResults,
        selectedCheckboxs: searchResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  numberWithSymbols = (number, noSymbol = false) => {
    let UpdatedNumber = number;
    if (UpdatedNumber >= 10000000) {
      UpdatedNumber = (UpdatedNumber / 10000000).toFixed(1) + " Cr";
    }
    if (UpdatedNumber >= 100000) {
      UpdatedNumber = (UpdatedNumber / 100000).toFixed(1) + " L";
    }
    if (UpdatedNumber >= 1000) {
      UpdatedNumber = (UpdatedNumber / 1000).toFixed(1) + " K";
    }
    if (UpdatedNumber < 1000) {
      UpdatedNumber = parseInt(UpdatedNumber).toFixed(1);
    }
    return noSymbol ? UpdatedNumber : "₹ " + UpdatedNumber;
  };

  compareStrings = (a, b) => {
    if (a == "Surplus") {
      a = 0;
    }
    if (b == "Surplus") {
      b = 0;
    }
    const x = a.toLowerCase();
    const y = b.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  compareNumbers = (a, b) => {
    return a - b;
  };

  getSortedData = (data, sorting_field, sorting_value, start, end) => {
    if (data.length != 0) {
      let fieldValue = data[0][sorting_field];
      if (fieldValue == "Surplus") {
        fieldValue = 0;
      }
      const sortingType = isNaN(fieldValue) ? "string" : "number";
      data = data.slice(start, end);
      if (sortingType == "string") {
        data.sort((a, b) =>
          this.compareStrings(a[sorting_field], b[sorting_field])
        );
      } else {
        data.sort((a, b) =>
          this.compareNumbers(a[sorting_field], b[sorting_field])
        );
      }
      return sorting_value ? data : data.reverse();
    } else {
      return data;
    }
  };

  handleSort = (field, sort) => {
    const sorting_field = field;
    const sorting_value = !sort;
    const { searchResults } = this.state;
    const sortedData = this.getSortedData(
      searchResults,
      sorting_field,
      sorting_value
    );

    this.setState({
      sorting_field,
      sorting_value,
      searchResults: sortedData,
    });
  };

  handleGenerateLinkForCustomer = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let orders = this.state.totalRequest.filter((item) => item);
      let userData = null;
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        userData = await getCustomerDetails(
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        userData = getUserDetails();
      }
      let orders_payload = goalDataToPipeCommaString(orders);
      let encryptedOrders = encryptString(orders_payload);
      const payload = {
        stage_name: "link_for_mf_recommendations_with_goals",
        parameters: {
          Name: userData.hey_name ? userData.hey_name : userData.first_name,
          link:
            global.location.origin +
            "/dashboard/mfRecommendations/" +
            (encryptedOrders ? "?orders_payload=" + encryptedOrders : ""),
        },
        to_mail: userData.email,
        cc_mail: "",
        subj_params: {},
      };
      const response = await sendMailUsingTemplate(payload);
      this.setState({
        isLoading: false,
        showToast: "true",
        toastText: "Link generated successfully and sent mail to customer",
      });
    } catch (error) {
      // console.log(error);
      this.setState({
        isLoading: false,
        showToast: "true",
        toastText: error,
      });
    }
  };

  calculateProjectedAmt = (
    lumpsum,
    SIP,
    growthRate,
    inflationRate,
    months,
    index,
    required_when_due,
    projected
  ) => {
    let lumpsumVal = lumpsum * Math.pow(1 + growthRate, months / 12);
    let sipValue =
      SIP * ((Math.pow(1 + growthRate / 12, months) - 1) / (growthRate / 12));
    if (isNaN(lumpsumVal)) {
      lumpsumVal = 0;
    }
    if (isNaN(sipValue)) {
      sipValue = 0;
    }
    let projectedAmount = Math.ceil(
      ((lumpsumVal + sipValue + projected) / required_when_due) * 100
    );
    let projectedArrToShow = this.state.projectedArrToShow;
    projectedArrToShow[index] = projectedAmount;
    let revisedProjectionArr = this.state.revisedProjectionArr;
    revisedProjectionArr[index] = projectedAmount;
    this.setState({ projectedArrToShow, revisedProjectionArr });
  };

  getHeaderTableData = (data) => {
    const { sorting_field, sorting_value } = this.state;
    let headerData = [];
    let values = [];
    let tempHeaderData = [
      {
        component: true,
        label: (
          <View style={{ marginLeft: -20 }}>
            <Checkbox
              status={
                this.state.isAllCheckboxSelected ? "checked" : "unchecked"
              }
              color={this.state.isAllCheckboxSelected ? "#410DAA" : "gray"}
              value={this.state.isAllCheckboxSelected}
              onValueChange={() => {
                let checkbox = this.state.selectedCheckboxs;
                if (this.state.isAllCheckboxSelected) {
                  checkbox.forEach((item) => {
                    item.toShow = false;
                  });
                } else {
                  checkbox.forEach((item) => {
                    item.toShow = true;
                  });
                }
                this.setState({ selectedCheckboxs: checkbox });
                this.setState({
                  isAllCheckboxSelected: !this.state.isAllCheckboxSelected,
                });
              }}
              style={{
                width: isMobile ? 13 : 14,
                height: isMobile ? 13 : 14,
                color: this.state.selectedCheckboxs ? "#410DAA" : "gray",
              }}
            />
          </View>
        ),
      },
      { label: "Goal Name", value: "name" },
      { label: "Due Date", value: "target_year" },
      { label: "Amount When Due", value: "target_amount_initially_calculated" },
      { label: "Achieved", value: "achieved_value" },
      { label: "Projected", value: "projected_value" },
      { label: `Deficit On Due Date`, value: "deficitOrSurplus" },
      { label: `Lumpsum`, value: "lumpsum" },
      { label: `Monthly SIP`, value: "sip" },
      { label: `Projected`, value: "projected" },
    ];

    tempHeaderData.forEach((headerItem, index) => {
      headerData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption:
          index == 0 || index == 7 || index == 8 || index == 9 ? false : true,
        onSort: this.handleSort,
        key: headerItem.value,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });
    data &&
      data.forEach((value, index) => {
        let currentDate = moment();
        let targetDate = moment(value.target_date_text);
        let years = targetDate.diff(currentDate, "years");
        let data_value = [
          {
            component: true,
            value: (
              <Checkbox
                status={
                  this.state.selectedCheckboxs[index].toShow
                    ? "checked"
                    : "unchecked"
                }
                color={
                  this.state.selectedCheckboxs[index].toShow
                    ? "#410DAA"
                    : "gray"
                }
                value={this.state.selectedCheckboxs[index].toShow}
                onValueChange={() => {
                  let checkbox = this.state.selectedCheckboxs;
                  if (checkbox[index]) {
                    this.setState({ isAllCheckboxSelected: false });
                  }

                  checkbox[index].toShow = !checkbox[index].toShow;
                  this.setState({ selectedCheckboxs: checkbox });
                }}
              />
            ),
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.name,
            value: value.name || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.target_date_text,
            value: value.target_date_text || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: this.numberWithSymbols(
              value.target_amount_initially_calculated
            ),
            value: value.target_amount_initially_calculated || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: this.numberWithSymbols(
              value.achieved_value.toFixed(1)
            ),
            value: value.achieved_value_text || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: this.numberWithSymbols(
              value.projected_value.toFixed(1)
            ),
            value: value.projected_value_text || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay:
              value.deficitOrSurplus == "Surplus"
                ? "Surplus"
                : this.numberWithSymbols(value.deficitOrSurplus),
            value: value.deficitOrSurplus || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            component: true,
            value:
              value.deficitOrSurplus == "Surplus" ? (
                <Text>N.A.</Text>
              ) : (
                <>
                  {this.state.lumpsumArr[index] >= 0 ? (
                    <>
                      <CommaTextField
                        value={
                          this.state.lumpsumArrToShow[index]
                            ? this.state.lumpsumArrToShow[index]
                            : ""
                        }
                        onValueChange={(text) => {
                          if (!isNaN(text)) {
                            let lumpsumArrToShow = this.state.lumpsumArrToShow;
                            lumpsumArrToShow[index] = text;
                            let sipFutureValue =
                              value.target_amount_initially_calculated -
                              (value.achieved_value + text) *
                                Math.pow((1 + 0.05) / (1 + 0), years);
                            let monthlyInterestRate = 0.05 / 12;
                            let numberOfPayments = years * 12;
                            let monthlyPayment =
                              (sipFutureValue * monthlyInterestRate) /
                              (1 -
                                Math.pow(
                                  1 + monthlyInterestRate,
                                  -numberOfPayments
                                ));

                            if (!isFinite(monthlyPayment)) {
                              monthlyPayment = 0;
                            }
                            let sipArr = this.state.sipArr;
                            let sipArrToShow = this.state.sipArrToShow;
                            sipArr[index] = parseInt(monthlyPayment);
                            if (monthlyPayment < 0) {
                              sipArrToShow[index] = "";
                            }
                            this.calculateProjectedAmt(
                              lumpsumArrToShow[index],
                              this.state.sipArrToShow[index],
                              0.11,
                              0,
                              years,
                              index
                            );
                            let checkbox = this.state.selectedCheckboxs;
                            if (text !== "") {
                              checkbox[index].toShow = true;
                            } else {
                              checkbox[index].toShow = false;
                            }
                            this.setState({
                              lumpsumArrToShow,
                              selectedCheckboxs: checkbox,
                            });
                          }
                        }}
                        containerStyle={{
                          width: "90%",
                          marginLeft: "5%",
                          marginRight: "5%",
                        }}
                        textStyle={{
                          fontSize: 14,
                          paddingVertical: 10,
                          paddingHorizontal: 5,
                        }}
                      />
                      <TouchableOpacity
                        style={{
                          width: "80%",
                          marginLeft: "10%",
                          marginTop: "9%",
                          height: 40,
                        }}
                        onPress={() => {
                          this.setState(
                            {
                              showToast: "false",
                            },
                            () => {
                              let lumpsumArrToShow =
                                this.state.lumpsumArrToShow;
                              lumpsumArrToShow[index] =
                                this.state.lumpsumArr[index];
                              let sipFutureValue =
                                value.target_amount_initially_calculated -
                                (value.achieved_value +
                                  this.state.lumpsumArr[index]) *
                                  Math.pow((1 + 0.05) / (1 + 0), years);
                              let monthlyInterestRate = 0.05 / 12;
                              let numberOfPayments = years * 12;
                              let monthlyPayment =
                                (sipFutureValue * monthlyInterestRate) /
                                (1 -
                                  Math.pow(
                                    1 + monthlyInterestRate,
                                    -numberOfPayments
                                  ));

                              if (!isFinite(monthlyPayment)) {
                                monthlyPayment = 0;
                              }
                              let sipArr = this.state.sipArr;
                              let sipArrToShow = this.state.sipArrToShow;
                              sipArr[index] = parseInt(monthlyPayment);
                              if (monthlyPayment < 0) {
                                sipArrToShow[index] = "";
                              }
                              this.calculateProjectedAmt(
                                lumpsumArrToShow[index],
                                this.state.sipArrToShow[index],
                                0.11,
                                0,
                                years,
                                index
                              );
                              let checkbox = this.state.selectedCheckboxs;
                              checkbox[index].toShow = true;
                              this.setState({
                                lumpsumArrToShow,
                                selectedCheckboxs: checkbox,
                                showToast: "true",
                                toastText: `Lumpsum Applied: ₹ ${numberWithCommas(
                                  lumpsumArrToShow[index]
                                )}, Revised Projection: ${
                                  revisedProjectionArr[index]
                                }%`,
                              });
                            }
                          );
                        }}
                      >
                        <Text
                          style={{
                            color: "#121212",
                            cursor: "pointer",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          Suggested{" "}
                          {this.numberWithSymbols(this.state.lumpsumArr[index])}{" "}
                          lumpsum based on goal deficit
                        </Text>
                        <Text
                          style={{
                            color: "#410DAA",
                            cursor: "pointer",
                            fontSize: 14,
                            textAlign: "center",
                            textDecorationLine: "underline",
                            marginTop: 3,
                          }}
                        >
                          Apply
                        </Text>
                      </TouchableOpacity>
                    </>
                  ) : (
                    <View style={{ width: "80%", marginLeft: "10%" }}>
                      <Text
                        style={{
                          color: "#121212",
                          cursor: "pointer",
                          fontSize: 13,
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Goal target reached, no Lumpsum investment required.
                      </Text>
                    </View>
                  )}
                </>
              ),
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            component: true,
            value:
              value.deficitOrSurplus == "Surplus" ? (
                <Text>N.A.</Text>
              ) : (
                <>
                  {this.state.sipArr[index] >= 0 ? (
                    <>
                      <CommaTextField
                        value={
                          this.state.sipArrToShow[index]
                            ? this.state.sipArrToShow[index]
                            : ""
                        }
                        onValueChange={(text) => {
                          if (!isNaN(text)) {
                            let sipArrToShow = this.state.sipArrToShow;
                            sipArrToShow[index] = text;

                            this.calculateProjectedAmt(
                              this.state.lumpsumArrToShow[index],
                              sipArrToShow[index],
                              0.11,
                              0,
                              years,
                              index
                            );
                            let lumpsumFutureValue =
                              value.achieved_value *
                              Math.pow((1 + 0.05) / (1 + 0), years);
                            let requiredAmount =
                              value.target_amount_initially_calculated -
                              this.state.projectedArrToShow[index] -
                              lumpsumFutureValue;
                            let lumpsumArr = this.state.lumpsumArr;
                            let lumpsumArrToShow = this.state.lumpsumArrToShow;
                            lumpsumArr[index] = parseInt(requiredAmount);
                            if (requiredAmount < 0) {
                              lumpsumArrToShow[index] = "";
                            }
                            let checkbox = this.state.selectedCheckboxs;
                            if (text !== "") {
                              checkbox[index].toShow = true;
                            } else {
                              checkbox[index].toShow = false;
                            }
                            this.setState({
                              sipArrToShow,
                              selectedCheckboxs: checkbox,
                            });
                          }
                        }}
                        containerStyle={{
                          width: "90%",
                          marginLeft: "5%",
                          marginRight: "5%",
                        }}
                        textStyle={{
                          fontSize: 14,
                          paddingVertical: 10,
                          paddingHorizontal: 5,
                        }}
                      />
                      <TouchableOpacity
                        style={{
                          width: "80%",
                          marginLeft: "10%",
                          marginTop: "9%",
                          height: 40,
                        }}
                        onPress={() => {
                          this.setState(
                            {
                              showToast: "false",
                            },
                            () => {
                              let sipArrToShow = this.state.sipArrToShow;
                              sipArrToShow[index] = this.state.sipArr[index];
                              this.calculateProjectedAmt(
                                this.state.lumpsumArrToShow[index],
                                sipArrToShow[index],
                                0.11,
                                0,
                                years,
                                index
                              );
                              let lumpsumFutureValue =
                                value.achieved_value *
                                Math.pow((1 + 0.05) / (1 + 0), years);
                              let requiredAmount =
                                value.target_amount_initially_calculated -
                                this.state.projectedArrToShow[index] -
                                lumpsumFutureValue;
                              let lumpsumArr = this.state.lumpsumArr;
                              let lumpsumArrToShow =
                                this.state.lumpsumArrToShow;
                              lumpsumArr[index] = parseInt(requiredAmount);
                              if (requiredAmount < 0) {
                                lumpsumArrToShow[index] = "";
                              }
                              let checkbox = this.state.selectedCheckboxs;
                              checkbox[index].toShow = true;
                              this.setState({
                                sipArrToShow,
                                selectedCheckboxs: checkbox,
                                showToast: "true",
                                toastText: `SIP Applied: ₹ ${numberWithCommas(
                                  sipArrToShow[index]
                                )}, Revised Projection: ${
                                  revisedProjectionArr[index]
                                }%`,
                              });
                            }
                          );
                        }}
                      >
                        <Text
                          style={{
                            color: "#121212",
                            cursor: "pointer",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          Suggested{" "}
                          {this.numberWithSymbols(this.state.sipArr[index])} per
                          month SIP for {years} yrs
                        </Text>
                        <Text
                          style={{
                            color: "#410DAA",
                            cursor: "pointer",
                            fontSize: 14,
                            textAlign: "center",
                            textDecorationLine: "underline",
                            marginTop: 3,
                          }}
                        >
                          Apply
                        </Text>
                      </TouchableOpacity>
                    </>
                  ) : (
                    <View style={{ width: "80%", marginLeft: "10%" }}>
                      <Text
                        style={{
                          color: "#121212",
                          cursor: "pointer",
                          fontSize: 13,
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Goal target reached, no SIP investment required.
                      </Text>
                    </View>
                  )}
                </>
              ),
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            component: true,
            value:
              value.deficitOrSurplus == "Surplus" ? (
                <Text>N.A.</Text>
              ) : this.state.projectedArrToShow[index] &&
                this.state.projectedArrToShow[index] > 0 ? (
                <View
                  style={[
                    { width: "90%", marginLeft: "5%", marginRight: "5%" },
                  ]}
                >
                  <Text
                    style={{
                      color: "#121212",
                      cursor: "pointer",
                      fontSize: 13,
                      textAlign: "center",
                    }}
                  >
                    {this.numberWithSymbols(
                      this.state.projectedArrToShow[index].toFixed(1)
                    )}{" "}
                    {"\n"}
                    {"\n"}
                    Projected for{" "}
                    {this.state.lumpsumArrToShow[index]
                      ? this.numberWithSymbols(
                          this.state.lumpsumArrToShow[index]
                        )
                      : "₹ 0"}{" "}
                    lumpsum and{" "}
                    {this.state.sipArrToShow[index]
                      ? this.numberWithSymbols(this.state.sipArrToShow[index])
                      : "₹ 0"}{" "}
                    SIP by {value.target_date_text}. You will be over/under
                    target.
                  </Text>
                </View>
              ) : null,

            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
        ];

        values.push(data_value);
      });

    let excelBillingData = [];
    data &&
      data.forEach((item) => {
        excelBillingData.push({
          name: item.name,
          target_date_text: item.target_date_text,
          target_amount_initially_calculated: this.numberWithSymbols(
            item.target_amount_initially_calculated
          ),
          achieved_value_text: item.achieved_value_text,
          projected_value_text: item.projected_value_text,
          deficitOrSurplus:
            item.deficitOrSurplus == "Surplus"
              ? "Surplus"
              : this.numberWithSymbols(item.deficitOrSurplus),
          current_sip_contibution_formatted:
            item.current_sip_contibution_formatted,
          calculated_monthly_text: item.calculated_monthly_text,
        });
      });
    return { headerData, values, excelBillingData };
  };

  handlePlaceOrderByMfRecommendation = async () => {
    this.setState({ isLoading: true });
    let payload = [];
    let tempSIP = [];
    let tempLumpsum = [];

    tempSIP = this.state.sipRequest.filter((item) => item.fresh_invt != "");
    tempLumpsum = this.state.lumpsumRequest.filter(
      (item) => item.fresh_invt != ""
    );
    if (tempSIP.length > 0) {
      payload = [...tempSIP];
    }
    if (tempLumpsum.length > 0) {
      payload = [...tempLumpsum];
    }
    if (tempSIP.length > 0 && tempLumpsum.length > 0) {
      payload = [...tempSIP, ...tempLumpsum];
    }
    if (!this.state.totalRequest.length) {
      this.setState({
        showToast: "true",
        toastText: `Kindly select at least one goal to proceed further`,
        isLoading: false,
      });
      return;
    }
    let orders = this.state.totalRequest.filter((item) => item);
    let { user_database } = Store.getState().userData.userProfileData;
    let response = await getSuggestedFunds({
      goal_orders: orders,
      is_direct_fund: (
        Platform.OS == "web"
          ? global.location.hostname.includes("club")
          : user_database.includes("club")
      )
        ? false
        : true,
    });
    let keyValues = Object.keys(response.result);
    let updateGoalList = this.state.json.filter((item) => {
      if (keyValues.includes(`${item.customergoal_id}`)) {
        return item;
      }
    });
    updateGoalList.forEach((item) => {
      item["schemeDetails"] = response.result[item.customergoal_id];
    });
    Toast.hide();
    this.setState({ isLoading: false });
    this.props.navigation.navigate("SuggestedFunds", {
      goalList: updateGoalList,
    });
  };

  render() {
    const { searchText, searchResults, isLoading } = this.state;
    let totalCount = 0;
    if (this.state.selectedCheckboxs) {
      this.state.selectedCheckboxs.forEach((item) => {
        if (item.toShow) {
          totalCount = totalCount + 1;
        }
      });
    }

    let totalLumpsumCount = 0;
    let totalLumpsumAmount = 0;
    this.state.lumpsumArrToShow.forEach((item) => {
      totalLumpsumCount = totalLumpsumCount + 1;
      totalLumpsumAmount = totalLumpsumAmount + item;
      if (totalLumpsumAmount <= 0) {
        totalLumpsumCount = 0;
      }
    });

    let totalSIPCount = 0;
    let totalSIPAmount = 0;
    this.state.sipArrToShow.forEach((item) => {
      totalSIPCount = totalSIPCount + 1;
      totalSIPAmount = totalSIPAmount + item;
      if (totalSIPAmount <= 0) {
        totalSIPCount = 0;
      }
    });

    const { headerData, values, excelBillingData } =
      this.getHeaderTableData(searchResults);

    let grid_Config = [0.5, 2, 0.8, 0.8, 0.8, 0.8, 0.8, 1.5, 1.5, 1.5];
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        {this.state.showComplianceAlert && (
          <ComplianceModal
            forceLoad={true}
            navigation={this.props.navigation}
            route={this.props.route}
            isLoading={this.state.gettingMinDetails}
            minDetailsResponse={this.state.mfMinDetailsData}
            onClose={() => {
              if (this.state.goalId) {
                this.props.navigation.dispatch(
                  StackActions.replace("App", {
                    screen: "Dashboard",
                    params: {
                      screen: "Goals",
                      params: {},
                    },
                  })
                );
              } else {
                this.props.navigation.goBack();
              }
            }}
            userId={this.state.userId}
            navigationOptions={{
              stack: "ViewMode",
              screen: "InvestForGoals",
            }}
          />
        )}
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Get Advice for Goal"]}
          onPressBack={() => {
            if (this.state.goalId) {
              // console.log("kavya")
              this.props.navigation.dispatch(
                StackActions.replace("App", {
                  screen: "Goals",
                  params: {
                    screen: "Goal",
                    params: {},
                  },
                })
              );
            } else {
              // console.log("nhbh   from = ",this.state.from)
              if(this.state.from){
                if(this.state.from == "Plans") {
                  this.props.navigation.dispatch(
                    StackActions.replace("App", {
                      screen: "Plans",
                      params: {
                        screen: "PlansHome",
                        params: {
                          activeTabIndex: 0
                        },
                      },
                    })
                  );
                  return
                }
                this.props.navigation.dispatch(
                  StackActions.replace("App", {
                    screen: this.state.from,
                    params: { },
                  })
                ); 
              }
              // console.log("nhbh")
              // this.props.navigation.goBack();
              this.props.navigation.dispatch(
                StackActions.replace("App", {
                  screen: "Goals",
                  params: {
                    screen: "Goal",
                    params: {},
                  },
                })
              );
            }
          }}
        />
        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title={this.state.toastText}
          />
        ) : null}
        {this.state.showToastMsg == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title="Your nominee authentication was successfully completed."
            duration="10"
          />
        ) : null}
        {this.state.showErrorToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title="Error while loading BSE url for nominee authentication. kindly contact your advisor."
            duration="10"
          />
        ) : null}
        {isMobile ? (
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
            <View style={styles.detailsContainer}>
              <View style={styles.headingContainer}>
                <SearchBar value={searchText} onChange={this.handleSearch} />
                <View
                  style={{
                    cursor: "pointer",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    marginRight: 20,
                  }}
                ></View>
              </View>
              <GoalsTable
                header={headerData}
                gridConfig={grid_Config}
                data={values}
                navigation={this.props.navigation}
                pagination={values.length > 10 ? true : false}
              />
            </View>
          </ScrollView>
        ) : this.state.showLoader ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size={25} color="#410DAA" />
            <Text style={{ fontSize: 16, fontWeight: "bold", marginTop: 10 }}>
              {this.state.loadingText}
            </Text>
          </View>
        ) : isLoading ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        ) : this.state.json.length ? (
          <>
            <View style={{ flexDirection: "row" }}>
              {this.state.goalId ? (
                <View
                  style={{
                    flexDirection: "row",
                    width: "20%",
                    marginRight: "6%",
                    marginLeft: "2%",
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                ></View>
              ) : (
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    width: "20%",
                    marginRight: "6%",
                    marginLeft: "2%",
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                  onPress={() => {
                    this.setState({
                      showModal: true,
                      goalAmount: "",
                      goalName: "",
                      goalYear: "",
                      goalMonth: "",
                    });
                  }}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#410DAA",
                      paddingTop: 7,
                      paddingRight: 5,
                    }}
                  >
                    Add Goal
                  </Text>
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/useraccount/addmember.png",
                    }}
                    style={{
                      width: 32,
                      height: 32,
                      resizeMode: "contain",
                      marginHorizontal: 5,
                    }}
                  />
                </TouchableOpacity>
              )}
              <View
                style={{
                  flexDirection: "column",
                  width: "42%",
                  marginRight: "6%",
                  marginLeft: "2%",
                  marginTop: "2%",
                }}
              ></View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "2%",
                  marginRight: "2%",
                  width: "20%",
                  marginBottom: "2%",
                }}
              >
                {Store.getState().dashboard &&
                Store.getState().dashboard.viewModeUserId &&
                Store.getState().dashboard.viewModeUserId.user_id ? (
                  <TouchableOpacity
                    style={{
                      textAlign: "center",
                      padding: 10,
                    }}
                    onPress={() => {
                      this.handleGenerateLinkForCustomer();
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: "#410DAA",
                        textDecorationLine: "underline",
                      }}
                    >
                      Generate recommendation link to customer
                    </Text>
                  </TouchableOpacity>
                ) : null}
                <View style={{ padding: 20 }}></View>
                <Button
                  uppercase={false}
                  mode={"contained"}
                  onPress={async () => {
                    if (
                      this.state.selectedCheckboxs.some(
                        (checkbox) => checkbox.toShow
                      )
                    ) {
                      this.setState({
                        showLoader: false,
                        loadingText: null,
                        showErrorToast: false,
                      });
                      let payload = {
                        redirection_url:
                          global.location.origin +
                          "/dashboard/mfRecommendations",
                      };
                      let response = await uccCodeGenerate(payload);
                      if (response && response.code && response.code == 100) {
                        let result = response.result;
                        if (
                          result ==
                            "Bse ucc code and nominee authentication is completed" ||
                          result ==
                            "Nominee Authentication already completed" ||
                          result ==
                            "Bse ucc code generated and nominee authentication is not required"
                        ) {
                          this.handlePlaceOrderByMfRecommendation();
                        } else if (
                          result ==
                            "Nominee Authentication Link Generated Successfully" &&
                          response.return_url
                        ) {
                          this.setState({
                            showLoader: true,
                            loadingText:
                              "You are being redirected to BSE nominee authentication page",
                          });
                          setTimeout(() => {
                            global.open(response.return_url, "_self");
                          }, 5000);
                        }
                      } else {
                        this.setState({
                          showErrorToast: "true",
                        });
                      }
                    } else {
                      this.setState({
                        showToast: "true",
                        toastText: "Please select a card",
                      });
                    }
                  }}
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "2%",
                  }}
                  color={"#410DAA"}
                >
                  <Text style={{ fontSize: 14 }}>
                    Get MF Advice for Selected Goals
                  </Text>
                </Button>
              </View>
            </View>
            {totalCount > 0 && (
              <Text
                style={{
                  fontWeight: "600",
                  marginLeft: 20,
                  fontSize: 15,
                }}
              >
                Summary: {totalCount} Goals | Lumpsum ₹
                {numberWithCommas(parseInt(totalLumpsumAmount))} (
                {totalLumpsumCount} orders) | SIP ₹
                {numberWithCommas(parseInt(totalSIPAmount))} ({totalSIPCount}{" "}
                SIPs)
              </Text>
            )}
            <ScrollView showsVerticalScrollIndicator={true}>
              <View style={{ marginTop: 30 }}>
                <View
                  style={{
                    marginHorizontal: isMobile ? 16 : 20,
                    marginBottom: 40,
                    width: isMobile ? 1200 : "96%",
                  }}
                >
                  {this.state.json &&
                    this.state.json.map((items, index) => {
                      return (
                        <InvestGoalCard
                          data={items}
                          index={index}
                          sipArr={this.state.sipArr}
                          sipArrToShow={this.state.sipArrToShow}
                          lumpsumArr={this.state.lumpsumArr}
                          lumpsumArrToShow={this.state.lumpsumArrToShow}
                          revisedProjectionArr={this.state.revisedProjectionArr}
                          selectedCheckboxs={this.state.selectedCheckboxs}
                          projectedArrToShow={this.state.projectedArrToShow}
                          updateAllCheckboxs={(value) => {
                            this.setState({ isAllCheckboxSelected: value });
                          }}
                          updateSelectedCheckboxs={(value) => {
                            this.setState({ selectedCheckboxs: value });
                          }}
                          onSIPTextChange={async (text, years, months) => {
                            let sipArrToShow = this.state.sipArrToShow;
                            sipArrToShow[index] = text;
                            this.calculateProjectedAmt(
                              this.state.lumpsumArrToShow[index],
                              sipArrToShow[index],
                              items.weighted_avg_growth_return / 100,
                              0,
                              months,
                              index,
                              items.target_amount_initially_calculated,
                              items.projected_value
                            );
                            let checkbox = this.state.selectedCheckboxs;
                            if (
                              text !== "" ||
                              (this.state.lumpsumArrToShow[index] &&
                                this.state.lumpsumArrToShow[index] !== "")
                            ) {
                              checkbox[index].toShow = true;
                            } else {
                              checkbox[index].toShow = false;
                            }
                            let sipRequest = this.state.sipRequest;
                            let totalRequest = this.state.totalRequest;
                            totalRequest[index] = {
                              goal_id: items.customergoal_id,
                              lumpsum_invt:
                                this.state.lumpsumArrToShow[index] &&
                                this.state.lumpsumArrToShow[index] != ""
                                  ? this.state.lumpsumArrToShow[index]
                                  : 0,
                              sip_invt: sipArrToShow[index],
                            };
                            let sipRequest1 = sipRequest.filter(
                              (item) => item.goal_id == items.customergoal_id
                            );
                            let sipRequest2 = sipRequest.filter(
                              (item) => item.goal_id != items.customergoal_id
                            );
                            sipRequest1 = {
                              goal_id: items.customergoal_id,
                              fresh_invt: sipArrToShow[index],
                              is_for_sip: true,
                            };
                            if (sipRequest2.length > 0) {
                              sipRequest = [sipRequest1, ...sipRequest2];
                            } else {
                              sipRequest = [sipRequest1];
                            }
                            this.setState({
                              sipRequest,
                              sipArrToShow,
                              selectedCheckboxs: checkbox,
                            });
                          }}
                          onLumpsumTextChange={async (text, years, months) => {
                            let lumpsumArrToShow = this.state.lumpsumArrToShow;

                            for (var i = 0; i < lumpsumArrToShow.length; i++) {
                              if (!lumpsumArrToShow[i]) {
                                lumpsumArrToShow[i] = 0;
                              }
                            }

                            // Convert empty strings to 0 or null based on your requirement
                            lumpsumArrToShow[index] = text !== "" ? text : 0;
                            // console.log("index is ", index);
                            // Perform the calculation with updated values
                            this.calculateProjectedAmt(
                              lumpsumArrToShow[index],
                              this.state.sipArrToShow[index],
                              items.weighted_avg_growth_return / 100,
                              0,
                              months,
                              index,
                              items.target_amount_initially_calculated,
                              items.projected_value
                            );

                            // Update the checkbox visibility logic
                            let checkbox = this.state.selectedCheckboxs;
                            if (
                              text !== "" ||
                              (this.state.sipArrToShow[index] &&
                                this.state.sipArrToShow[index] !== "")
                            ) {
                              checkbox[index].toShow = true;
                            } else {
                              checkbox[index].toShow = false;
                            }
                            checkbox[index].toShow =
                              lumpsumArrToShow[index] !== 0 ||
                              this.state.sipArrToShow[index] !== null;

                            // Prepare and update the totalRequest object
                            // Filter and update lumpsumRequest
                            let lumpsumRequest = this.state.lumpsumRequest;
                            let totalRequest = this.state.totalRequest;
                            totalRequest[index] = {
                              goal_id: items.customergoal_id,
                              lumpsum_invt: lumpsumArrToShow[index],
                              sip_invt:
                                this.state.sipArrToShow[index] &&
                                this.state.sipArrToShow[index] !== ""
                                  ? this.state.sipArrToShow[index]
                                  : 0,
                            };

                            let lumpsumRequest1 = lumpsumRequest.filter(
                              (item) => item.goal_id == items.customergoal_id
                            );
                            let lumpsumRequest2 = lumpsumRequest.filter(
                              (item) => item.goal_id !== items.customergoal_id
                            );
                            lumpsumRequest1 = {
                              goal_id: items.customergoal_id,
                              fresh_invt: lumpsumArrToShow[index],
                            };
                            if(lumpsumRequest2.length > 0){
                            lumpsumRequest = [
                              lumpsumRequest1,
                              ...lumpsumRequest,
                            ];
                          }else{
                            lumpsumRequest = [lumpsumRequest1];
                          }
                            // Update the state with the modified arrays
                            this.setState({
                              lumpsumRequest,
                              lumpsumArrToShow,
                              selectedCheckboxs: checkbox,
                            });
                          }}
                          sipApply={(index, years, months) => {
                            this.setState(
                              {
                                showToast: "false",
                              },
                              async () => {
                                let sipArrToShow = this.state.sipArrToShow;
                                sipArrToShow[index] = this.state.sipArr[index];
                                this.calculateProjectedAmt(
                                  this.state.lumpsumArrToShow[index],
                                  sipArrToShow[index],
                                  items.weighted_avg_growth_return / 100,
                                  0,
                                  months,
                                  index,
                                  items.target_amount_initially_calculated,
                                  items.projected_value
                                );
                                let checkbox = this.state.selectedCheckboxs;
                                checkbox[index].toShow = true;
                                let sipRequest = this.state.sipRequest;
                                let sipRequest1 = sipRequest.filter(
                                  (item) =>
                                    item.goal_id == items.customergoal_id
                                );
                                let sipRequest2 = sipRequest.filter(
                                  (item) =>
                                    item.goal_id != items.customergoal_id
                                );
                                let totalRequest = this.state.totalRequest;
                                totalRequest[index] = {
                                  goal_id: items.customergoal_id,
                                  lumpsum_invt:
                                    this.state.lumpsumArrToShow[index] &&
                                    this.state.lumpsumArrToShow[index] != ""
                                      ? this.state.lumpsumArrToShow[index]
                                      : 0,
                                  sip_invt: sipArrToShow[index],
                                };
                                sipRequest1 = {
                                  goal_id: items.customergoal_id,
                                  fresh_invt: sipArrToShow[index],
                                  is_for_sip: true,
                                };
                                if (sipRequest2.length > 0) {
                                  sipRequest = [sipRequest1, ...sipRequest2];
                                } else {
                                  sipRequest = [sipRequest1];
                                }
                                let lumpsumArrToShow =
                                  this.state.lumpsumArrToShow;
                                this.setState({
                                  sipRequest,
                                  sipArrToShow,
                                  lumpsumArrToShow,
                                  selectedCheckboxs: checkbox,
                                  showToast: "true",
                                  toastText: `SIP Applied: ₹ ${numberWithCommas(
                                    sipArrToShow[index]
                                  )}, Revised Projection: ${
                                    this.state.revisedProjectionArr[index]
                                  }%`,
                                });
                              }
                            );
                          }}
                          lumpsumApply={(index, years, months) => {
                            this.setState(
                              {
                                showToast: "false",
                              },
                              async () => {
                                let lumpsumArrToShow =
                                  this.state.lumpsumArrToShow;
                                lumpsumArrToShow[index] =
                                  this.state.lumpsumArr[index];
                                this.calculateProjectedAmt(
                                  lumpsumArrToShow[index],
                                  this.state.sipArrToShow[index],
                                  items.weighted_avg_growth_return / 100,
                                  0,
                                  months,
                                  index,
                                  items.target_amount_initially_calculated,
                                  items.projected_value
                                );
                                let checkbox = this.state.selectedCheckboxs;
                                checkbox[index].toShow = true;
                                let lumpsumRequest = this.state.lumpsumRequest;
                                let lumpsumRequest1 = lumpsumRequest.filter(
                                  (item) =>
                                    item.goal_id == items.customergoal_id
                                );
                                let lumpsumRequest2 = lumpsumRequest.filter(
                                  (item) =>
                                    item.goal_id != items.customergoal_id
                                );
                                let totalRequest = this.state.totalRequest;
                                totalRequest[index] = {
                                  goal_id: items.customergoal_id,
                                  lumpsum_invt: lumpsumArrToShow[index],
                                  sip_invt:
                                    this.state.sipArrToShow[index] &&
                                    this.state.sipArrToShow[index] != ""
                                      ? this.state.sipArrToShow[index]
                                      : 0,
                                };
                                lumpsumRequest1 = {
                                  goal_id: items.customergoal_id,
                                  fresh_invt: lumpsumArrToShow[index],
                                };
                                if (lumpsumRequest2.length > 0) {
                                  lumpsumRequest = [
                                    lumpsumRequest1,
                                    ...lumpsumRequest2,
                                  ];
                                } else {
                                  lumpsumRequest = [lumpsumRequest1];
                                }
                                let sipArrToShow = this.state.sipArrToShow;
                                this.setState({
                                  lumpsumRequest,
                                  sipArrToShow,
                                  lumpsumArrToShow,
                                  selectedCheckboxs: checkbox,
                                  showToast: "true",
                                  toastText: `Lumpsum Applied: ₹ ${numberWithCommas(
                                    lumpsumArrToShow[index]
                                  )}, Revised Projection: ${
                                    this.state.revisedProjectionArr[index]
                                  }%`,
                                });
                              }
                            );
                          }}
                        />
                      );
                    })}
                </View>
              </View>
              {/* <Button
                uppercase={false}
                mode={"contained"}
                onPress={async () => {
                  this.setState({ isLoading: true });
                  let payload = [];
                  let tempSIP = [];
                  let tempLumpsum = [];

                  tempSIP = this.state.sipRequest.filter(
                    (item) => item.fresh_invt != ""
                  );
                  tempLumpsum = this.state.lumpsumRequest.filter(
                    (item) => item.fresh_invt != ""
                  );
                  if (tempSIP.length > 0) {
                    payload = [...tempSIP];
                  }
                  if (tempLumpsum.length > 0) {
                    payload = [...tempLumpsum];
                  }
                  if (tempSIP.length > 0 && tempLumpsum.length > 0) {
                    payload = [...tempSIP, ...tempLumpsum];
                  }
                  if (!this.state.totalRequest.length) {
                    this.setState({
                      showToast: "true",
                      toastText: `Kindly select at least one goal to proceed further`,
                      isLoading: false,
                    });
                    return;
                  }
                  let orders = this.state.totalRequest.filter((item) => item);
                  let response = await getSuggestedFunds({
                    goal_orders: orders,
                    is_direct_fund:
                      global.location.hostname.includes("club")
                        ? false
                        : true,
                  });
                  let keyValues = Object.keys(response.result);
                  let updateGoalList = this.state.json.filter((item) => {
                    if (keyValues.includes(`${item.customergoal_id}`)) {
                      return item;
                    }
                  });
                  updateGoalList.forEach((item) => {
                    item["schemeDetails"] =
                      response.result[item.customergoal_id];
                  });
                  Toast.hide();
                  this.setState({ isLoading: false });
                  this.props.navigation.navigate("SuggestedFunds", {
                    goalList: updateGoalList,
                  });
                }}
                style={{
                  alignSelf: "flex-end",
                  marginRight: "2.4%",
                  marginBottom: "8%",
                }}
                color={"#410DAA"}
              >
                <Text style={{ fontSize: 14 }}>
                  Get MF Advice for Selected Goals
                </Text>
              </Button> */}
            </ScrollView>
          </>
        ) : (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={{
                borderWidth: 1,
                borderRadius: 6,
                borderColor: "#00000029",
                padding: 10,
                marginHorizontal: 16,
                marginTop: 43,
                width: isMobile ? "100%" : "50%",
                maxWidth: isMobile ? "100%" : "50%",
              }}
              onPress={async () => {
                this.props.navigation.navigate("App", {
                  screen: "Goals",
                  params: {
                    screen: "BasicDetails",
                    params: {
                      type: "COMPLETE",
                      from: "advice",
                    },
                  },
                });
              }}
            >
              <Image
                source={{
                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/finplan_landing_image.png",
                }}
                style={{ width: 125, height: 125, alignSelf: "center" }}
                resizeMode="contain"
              />
              <Text
                style={{
                  fontSize: 17,
                  fontWeight: "bold",
                  paddingVertical: 10,
                  textAlign: "center",
                }}
              >
                Start your financial plan
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  textAlign: "center",
                  color: "#121212",
                }}
              >
                A financial plan is required to get personalised investment
                advice.
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {this.state.showModal && (
          <Modal
            style={{
              top: 0,
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
            }}
            containerStyle={{
              marginHorizontal: isMobile ? 16 : 0,
              borderRadius: 5,
            }}
          >
            <View
              style={{
                paddingTop: isMobile ? 16 : 24,
                paddingBottom: isMobile ? 17 : 23,
                paddingHorizontal: isMobile ? 5 : 33,
                minWidth: isMobile ? 200 : 633,
                marginHorizontal: isMobile ? 16 : 0,
                maxWidth: 850,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
              >
                <Text style={{ fontWeight: "700", fontSize: 15 }}>
                  Create Open Ended Goal
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showModal: false });
                  }}
                >
                  <AntDesign
                    name="close"
                    size={24}
                    color="rgba(0, 0, 0, 0.5)"
                  />
                </TouchableOpacity>
              </View>
              <Text style={{ fontWeight: "500", fontSize: 14 }}>
                Open ended goals help you invest for objectives without a
                definite timeline.
              </Text>
              <Text
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                  marginTop: 5,
                  marginBottom: 20,
                }}
              >
                Examples are wealth creation for long term (not for retirement),
                park short term funds etc.
              </Text>
              <TextInputField
                label="Goal Name"
                value={this.state.goalName}
                placeholder="Enter goal name"
                onChange={(text) => {
                  this.setState({
                    goalName: text,
                  });
                }}
                editable={true}
                inputStyle={[{ marginBottom: 20 }]}
              />
              <TextInputField
                label="Amount"
                value={this.state.goalAmount}
                placeholder="Enter goal amount"
                onChange={(text) => {
                  if (!isNaN(text)) {
                    this.setState({
                      goalAmount: text,
                    });
                  }
                }}
                editable={true}
              />
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontSize: 13,
                  fontWeight: "700",
                  color: "#464E5F",
                  marginBottom: 11,
                  marginTop: 20,
                }}
              >
                Timeframe of Investment
              </Text>
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <TextInputField
                  label="Years"
                  value={this.state.goalYear}
                  placeholder="Enter years"
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      if (text <= 50) {
                        this.setState({
                          goalYear: text,
                        });
                      }
                    }
                  }}
                  style={[{ width: "24%", marginRight: "5%" }]}
                  editable={true}
                />
                <TextInputField
                  label="Months"
                  value={this.state.goalMonth}
                  placeholder="Enter months"
                  onChange={(text) => {
                    if (!isNaN(text)) {
                      this.setState({
                        goalMonth: text,
                      });
                    }
                  }}
                  style={[{ width: "24%", marginRight: "5%" }]}
                  editable={true}
                />
              </View>
              <Button
                uppercase={false}
                mode={"contained"}
                onPress={async () => {
                  this.setState({ showToast: "false" });
                  const payload = {
                    goal_id: null,
                    name: this.state.goalName,
                    amount_at_current_cost: this.state.goalAmount,
                    type: "OTH",
                    years: this.state.goalYear,
                    months: this.state.goalMonth,
                    due_date: "",
                    expected_returns: "",
                    target_amount_initially_calculated: "",
                    calculated_monthly_installment: "",
                    inflation: "7",
                    is_open_ended: false,
                  };
                  if (this.state.goalYear == "" && this.state.goalMonth == "") {
                    this.setState({
                      showToast: "true",
                      toastText: "Please enter years and months of investment.",
                    });
                    return;
                  }
                  await addGoal(payload);
                  const json = await getAdvisorDashboardDetials();
                  this.setState({ isLoading: false });
                  let selectedCheckboxs = [];
                  let goalArr = [];
                  let emergencyGoalsArr = [];
                  let retirementGoalsArr = [];
                  let otherGoalsArr = [];
                  if (json && json.goals) {
                    emergencyGoalsArr = json.goals.filter((goal) =>
                      goal.name.includes("Emergency")
                    );
                    retirementGoalsArr = json.goals.filter((goal) =>
                      goal.name.includes("Retire")
                    );
                    otherGoalsArr = json.goals.filter(
                      (goal) =>
                        !(
                          goal.name.includes("Emergency") ||
                          goal.name.includes("Retire")
                        )
                    );
                    let sortedOtherArr = otherGoalsArr.sort((a, b) =>
                      this.compareNumbers(a.target_year, b.target_year)
                    );

                    goalArr = [
                      ...emergencyGoalsArr,
                      ...retirementGoalsArr,
                      ...sortedOtherArr,
                    ];
                    if (this.state.goalId) {
                      goalArr = goalArr.filter(
                        (goal) => goal.customergoal_id == this.state.goalId
                      );
                    }
                    let deficitGoalsArr = goalArr.filter(
                      (goal) =>
                        goal.target_amount_initially_calculated >
                        goal.projected_value
                    );
                    let surplusGoalsArr = goalArr.filter(
                      (goal) =>
                        !(
                          goal.target_amount_initially_calculated >
                          goal.projected_value
                        )
                    );
                    goalArr = [...deficitGoalsArr, ...surplusGoalsArr];

                    let sipArr = this.state.sipArr;
                    let lumpsumArr = this.state.lumpsumArr;
                    json.goals = goalArr;
                    json.goals.forEach((item, index) => {
                      sipArr[index] = parseInt(item.sip_suggested);
                      lumpsumArr[index] = parseInt(item.lumpsum_suggested);
                      let isDeficit =
                        item.target_amount_initially_calculated >
                        item.projected_value;
                      let deficitOrSurplus = isDeficit
                        ? Math.round(
                            item.target_amount_initially_calculated -
                              item.projected_value
                          )
                        : "Surplus";
                      selectedCheckboxs.push({
                        ...item,
                        toShow: false,
                        deficitOrSurplus,
                      });
                    });
                    this.setState({ sipArr, lumpsumArr });
                    let userData = {};
                    if (this.state.userId) {
                      userData = await getCustomerDetails(this.state.userId);
                    } else {
                      userData = await getUserDetails();
                    }
                    let { first_name } = userData;
                    let names = first_name ? first_name.split(/\s+/) : [];
                    let name = "";
                    if (names.length >= 2 && names.length > 0) {
                      name =
                        capitalizeFirstLetter(names[0]) +
                        " " +
                        capitalizeFirstLetter(names[1]);
                    } else {
                      if (names.length == 0) {
                        name = "User";
                      } else {
                        name = names[0];
                      }
                    }
                    let projectedArrToShow = [];
                    let revisedProjectionArr = [];
                    selectedCheckboxs.forEach((item) => {
                      projectedArrToShow.push(item.projected_percentage);
                      revisedProjectionArr.push(item.projected_percentage);
                    });
                    this.setState({ projectedArrToShow, revisedProjectionArr });
                    this.setState({
                      selectedCheckboxs,
                      json: selectedCheckboxs,
                      searchResults: selectedCheckboxs,
                      userName: name,
                      showModal: false,
                      showToast: "true",
                      toastText: "Goal Created Successfully.",
                    });
                  }
                }}
                style={{
                  width: 214,
                  alignSelf: "center",
                  marginBottom: "2%",
                  marginTop: "5%",
                }}
                color={"#410DAA"}
              >
                <Text style={{ fontSize: 14 }}>Create Goal</Text>
              </Button>
            </View>
          </Modal>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  rowItem: {
    textAlign: "left",
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  rowItemContainerStyle: {
    justifyContent: "flex-start",
    paddingLeft: 10,
  },
  headerItemContainerStyle: {
    justifyContent: "flex-start",
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: isMobile ? 16 : 20,
    marginBottom: 40,
    width: isMobile ? 1200 : "96%",
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 16 : 19,
    paddingVertical: 19,
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "space-between",
  },
});
