import React from "react";
import {
  View,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  FlatList,
  Platform,
  Dimensions,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Text } from "react-native-paper";
import Icon from "@expo/vector-icons/FontAwesome";
import CustButton from "../../../components/Common/Button";
import amplitude from "../../../utils/amplitude";
import { getQuizQnA, submitQuizQnA } from "../../../api";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { useState } from "react";
import { getUserDetails, isMobile } from "../../../utils";
import { StackActions } from "@react-navigation/native";
import ProgressBar from "../../../utils/progressBar";

const { width } = Dimensions.get("screen");

const QuestionCard = (props) => {
  const [selected, setSelected] = useState(null);
  const [selectedOption, setSelectedOPtion] = useState(null);
  const { onSelect, question: questionData } = props;
  const { questionCard, optionText, optionBubble, questionText } = styles;
  const { question, options, correct_answer: correctAnswer } = questionData;
  return (
    <View style={{ flex: 1, width: width - 32, marginRight: 16 }}>
      <View style={questionCard}>
        <Text style={questionText}>{question}</Text>
        <View style={{ marginBottom: 16 }}>
          {options.map((option, index) => {
            const backgroundColor =
              option.text == correctAnswer && selectedOption !== null
                ? "#C0F6C2"
                : "transparent";
            let radioColor = "#000";
            let bubbleColor = "transparent";
            if (
              selectedOption == correctAnswer &&
              option.text == selectedOption
            ) {
              radioColor = "green";
              bubbleColor = "green";
            } else if (
              selectedOption !== null &&
              selectedOption !== correctAnswer &&
              option.text == selectedOption
            ) {
              radioColor = "#EF5D12";
              bubbleColor = "#EF5D12";
            }
            return (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  backgroundColor,
                  padding: 16,
                  paddingVertical: 4,
                  marginBottom: 12,
                }}
                key={index}
                onPress={() => {
                  selected == null && onSelect(index, option.option_id);
                  selected == null && setSelectedOPtion(option.text);
                  selected == null && setSelected(index);
                }}
              >
                <View
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: 8,
                    alignItems: "center",
                    borderWidth: 1,
                    borderColor: radioColor,
                    padding: 2,
                    marginRight: 16,
                    marginLeft: 4,
                    marginTop: 3,
                  }}
                >
                  <View
                    style={[optionBubble, { backgroundColor: bubbleColor }]}
                  ></View>
                </View>
                <Text
                  style={[
                    optionText,
                    { color: radioColor == "#EF5D12" ? "#EF5D12" : "#000" },
                    { opacity: radioColor == "#EF5D12" ? 1 : 0.5 },
                  ]}
                >
                  {option.text}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
      {selected !== null && (
        <View style={{ marginVertical: 16 }}>
          {selectedOption == correctAnswer ? (
            <View style={{ marginVertical: 16 }}>
              <Text style={{ fontSize: 16 }}>
                <Icon name="check-circle" color={"#3FDE44"} size={18} /> Your
                Answer is correct.
              </Text>
            </View>
          ) : (
            <View style={{ marginVertical: 16 }}>
              <Text style={{ fontSize: 16 }}>
                <Icon name="times-circle" color={"#EF5D12"} size={18} /> Your
                Answer is incorrect.
              </Text>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default class EduQuiz extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let quiz_id = null;
    if (params) {
      quiz_id = params.quiz_id || null;
      quiz_id = isNaN(quiz_id) ? null : quiz_id;
    }
    this.state = {
      quiz_id,
      selected: null,
      currectIndex: 0,
      quizdata: [],
      loading: true,
      isLoading: false,
      blurButton: true,
      submittingAnswer: false,
      userResponses: [],
    };
    this.flatListRef = React.createRef();
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("eduQuiz", { path: global.location.href });
      } else {
        amplitude.logEvent("eduQuiz", {
          path: this.props.navigation.getState().routeName,
        });
      }
      const { quiz_id = null } = this.state;
      if (quiz_id) {
        const payload = { quiz_id };
        const response = await getQuizQnA(payload);
        this.setState({
          quizdata: response.quiz_data,
          loading: false,
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        error: "Something went wrong. Please try again.",
      });
    }
  }

  onNextPressed = async () => {
    const { currectIndex, quizdata, userResponses } = this.state;
    if (currectIndex + 1 < quizdata.length) {
      this.setState(
        {
          currectIndex: currectIndex + 1,
          blurButton: true,
        },
        () =>
          this.flatListRef.scrollToIndex({
            animated: true,
            index: this.state.currectIndex,
          })
      );
    } else {
      //Make an api call and pass the result to result screen
      try {
        this.setState({
          submitQuizQnA: true,
          isLoading: true,
        });
        setTimeout(async () => {
          this.setState({ isLoading: false });
          const { quiz_id = null } = this.state;
          if (quiz_id) {
            const { customer_id } = getUserDetails();
            const payload = {
              quiz_id,
              options: userResponses.join(),
              cust_id: customer_id,
            };
            const response = await submitQuizQnA(payload);
            if (response && response.code == "100") {
              this.props.navigation.navigate("EduQuizResult", { quiz_id });
            } else {
              this.setState({ submitQuizQnA: false });
            }
          }
        }, 2000);
      } catch (e) {
        this.setState({ submitQuizQnA: false });
      }
    }
  };

  onBackPressed = () => {
    const { currectIndex } = this.state;
    if (currectIndex - 1 >= 0) {
      this.setState(
        {
          currectIndex: currectIndex - 1,
        },
        () =>
          this.flatListRef.scrollToIndex({
            animated: true,
            index: this.state.currectIndex,
          })
      );
    }
  };

  updateUserResponses = (index, optionId) => {
    this.setState({
      selected: index,
      blurButton: false,
      userResponses: [...this.state.userResponses, optionId],
    });
  };

  render() {
    const { container } = styles;
    const { selected, currectIndex, quizdata, loading, error, blurButton } =
      this.state;
    let Buttoncolor = "#410DAA";
    if (blurButton) {
      Buttoncolor = "#707070";
    }

    if (error) {
      <SafeAreaView style={container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Quiz"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            marginHorizontal: 32,
          }}
        >
          <Text>{error}</Text>
        </View>
      </SafeAreaView>;
    }

    if (loading) {
      return (
        <SafeAreaView style={container}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Quiz"]}
            onPressBack={() => {
              this.props.navigation.goBack();
            }}
          />
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              marginHorizontal: 32,
            }}
          >
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        </SafeAreaView>
      );
    }

    return (
      <SafeAreaView style={container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Quiz"]}
          onPressBack={() => {
            this.props.navigation.navigate("EduHome")
          }}
        />
        <View
          style={{ marginHorizontal: 16 }}
          contentContainerStyle={{ flex: 1 }}
        >
          <View
            style={{
              position: "relative",
              flexDirection: "row",
              justifyContent: "center",
              marginVertical: 8,
            }}
          >
            <Text>
              Question {currectIndex + 1} of {quizdata.length}
            </Text>
            {currectIndex > 0 && (
              <Text
                style={{ position: "absolute", left: 0, top: 0, padding: 4 }}
                onPress={this.onBackPressed}
              >
                previous
              </Text>
            )}
          </View>
          <FlatList
            data={quizdata}
            renderItem={({ item }) => (
              <View style={{ flex: 1, height: "100%" }}>
                <QuestionCard
                  selected={selected}
                  onSelect={this.updateUserResponses}
                  correctAnswer={0}
                  question={item}
                />
              </View>
            )}
            horizontal
            showsHorizontalScrollIndicator={false}
            scrollEnabled={false}
            ref={(c) => (this.flatListRef = c)}
            keyExtractor={(item, index) => index}
          />
        </View>
        <CustButton
          style={{
            margin: 16,
            width: isMobile ? "98%" : "20%",
            marginLeft: isMobile ? "1%" : "40%",
            marginBottom: isMobile ? 66 : "30%",
          }}
          bgColor={Buttoncolor}
          borderColor={Buttoncolor}
          onPress={!this.state.blurButton && this.onNextPressed}
          isLoading={this.state.submitQuizQnA}
        >
          Next
        </CustButton>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
  },

  wrapper: {
    marginHorizontal: 16,
  },

  questionCard: {
    backgroundColor: "#F7F5FC",
  },
  optionText: {
    color: "#121212",
    opacity: 0.5,
    flex: 1,
  },
  optionBubble: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "#410DAA",
    marginHorizontal: 8,
  },
  questionText: {
    margin: 16,
    fontWeight: "bold",
  },
});
