import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import {  View, Text, Platform } from 'react-native';


export const VersionNumber = ({style={},absolute=false, font_size=10}) => {
    return(
        <View style={[ absolute && styles.absolute, !absolute &&styles.relative, { ...style}]}>
            <Text style={{fontSize: font_size ,color:"#12121280",textAlign:"center"}}>v 2.0.8</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    absolute:{
        position: Platform.OS=="web" ? "fixed" : "absolute", 
        // top:"97vh", 
        zIndex:1, 
        bottom:0,
        left: 0,
        right:0,
    },
    relative:{
        paddingTop:15,
        alignItems:"center"
    }
})