import React, { useState, useEffect } from "react";
import {
  TouchableOpacity,
  Dimensions,
  Text,
  View,
  Image,
  StyleSheet,
  Platform,
} from "react-native";
import { Appbar, Button, Card } from "react-native-paper";
import { MaterialIcons, Entypo } from "@expo/vector-icons";
import { StackActions } from "@react-navigation/routers";
import {
  capitalizeFirstLetter,
  getScreenConfigForCustomer,
  getUserDetails,
  isJama,
  isMobile,
  isWeb,
  reverseString,
} from "../../utils";
import {
  getCustomerDetails,
  getHashKeyValue,
  getUrlForClient2FA,
} from "../../api";
import flex from "../../styles/flex";
import LogoutLoading from "./LogoutLoader";
import { LOGOUT } from "../../actions/types";
const { width, height } = Dimensions.get("window");
import * as RootNavigation from "../../../RootNavigation.js";
import Fontisto from "@expo/vector-icons/Fontisto";
import AntDesign from "@expo/vector-icons/AntDesign";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../api/apiConfig";
import { MAIN_URL, URLS } from "../../api/urls";
import Store from "../../store/index.js";

const ConfirmAlert = (props) => {
  const { bannerData, customer_id } = getUserDetails();
  let modifiedText = false;
  let user_id = null;
  if (
    Store.getState().dashboard &&
    Store.getState().dashboard.viewModeUserId &&
    Store.getState().dashboard.viewModeUserId.user_id
  ) {
    user_id = Store.getState().dashboard.viewModeUserId.user_id;
  } else {
    user_id = customer_id;
  }
  if (bannerData.name == "Sensage" && user_id > 1 && user_id < 140) {
    modifiedText = true;
  }

  return (
    <View onDismiss={props.onDismiss} style={{}}>
      <Card style={alert.body}>
        <Text style={alert.confirm}>Confirm</Text>
        <View style={[flex.row, flex.center, alert.sureContainer]}>
          <Image
            source="https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Confirm+logout.png"
            style={alert.image}
          />
          <Text style={alert.sure}>Are you sure you want to logout?</Text>
        </View>
        <View style={alert.buttons}>
          <Button
            uppercase={false}
            mode="contained"
            labelStyle={alert.labelStyle}
            color="#E2E8F0"
            style={alert.buttonStyle}
            onPress={props.onDismiss}
          >
            No
          </Button>
          <Button
            uppercase={false}
            mode="contained"
            labelStyle={alert.labelStyle}
            color="#E2E8F0"
            style={alert.buttonStyle}
            onPress={props.onLogout}
          >
            Yes
          </Button>
        </View>
      </Card>
    </View>
  );
};

const alert = StyleSheet.create({
  body: {
    paddingHorizontal: isMobile ? 16 : 42,
    paddingVertical: isMobile ? 16 : 24,
    flex: 1,
    alignSelf: "center",
    backgroundColor: "#fff",
    zIndex: 10,
    borderRadius: 10,
    borderRadius: 10,
    width: isMobile ? "90%" : undefined,
  },
  containerStyle: {},
  confirm: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 18,
    color: "#121212",
    marginBottom: 11,
  },
  sureContainer: {
    marginBottom: 44,
  },
  sure: {
    fontFamily: "Roboto",
    fontSize: 15,
    color: "#121212",
  },
  buttons: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  labelStyle: {
    color: "#2D3748",
    fontSize: 14,
    fontWeight: "bold",
    paddingHorizontal: 40,
    paddingVertical: 5,
  },
  buttonStyle: {
    marginHorizontal: 12,
  },
  image: { width: 19, height: 17, resizeMode: "contain", marginRight: 7 },
});

export const Header = ({ navigation, showDetails = true, route }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showGetHelp, setShowGetHelp] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  let currentRouteValue = RootNavigation.getCurrentRoute();

  useEffect(() => {
    getUserData();
  }, [showDetails]);

  const handleGethelpClick = () => {
    navigation.navigate("App", {
      screen: "Dashboard",
      params: { screen: "Dashboard", params: { screen: "FAQ", params: {} } },
    });
  };

  const getUserData = async () => {
    try {
      const { params } = route;
      let userData = {};
      if (params && params.user_id) {
        userData = await getCustomerDetails(params.user_id);
      } else {
        if (
          Store.getState().dashboard &&
          Store.getState().dashboard.viewModeUserId &&
          Store.getState().dashboard.viewModeUserId.user_id
        ) {
          userData = await getCustomerDetails(
            Store.getState().dashboard.viewModeUserId.user_id
          );
        } else {
          userData = getUserDetails();
        }
      }

      const {
        first_name,
        email,
        mobilenumber,
        country_mobile_code,
        customer_id,
      } = userData;
      let is_preferred_circle = false;
      let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
      if (hostname.includes("myadvisor")) {
        is_preferred_circle = true;
      }
      let names = first_name ? first_name.split(/\s+/) : [];
      let name = "",
        initials = null;
      if (names.length >= 2 && names.length > 0) {
        name =
          capitalizeFirstLetter(names[0]) +
          " " +
          capitalizeFirstLetter(names[1]);
        initials = names[0][0].toUpperCase() + "" + names[1][0].toUpperCase();
      } else {
        if (names.length == 0) {
          name = "User";
        } else {
          name = names[0];
          initials = names[0][0].toUpperCase() + names[0][1].toUpperCase();
        }
      }

      setUserDetails({
        name: name,
        initials: initials,
        mobilenumber,
        email,
        customer_id,
        country_mobile_code,
        is_preferred_circle,
      });
    } catch (error) {}
  };

  const handleContinueToLogout = async () => {
    try {
      // console.log("header.js line no 222...........", MAIN_URL);
      localStorage.removeItem("isShowAppDownloadCard");
      localStorage.removeItem("showPlanRenewalCard");
      localStorage.removeItem("redirectAfterOnboardingSuccess");
      const response = await getHashKeyValue();
      if (response && response.code == 100) {
        const { hash_key } = response.result;
        let token = getTokenFromStore();
        token = reverseString(token);
        global.open(
          MAIN_URL +
            URLS.LOGOUT_URL +
            (hash_key + token + "/" + GATEWAY_ENVIRONMENT),
          "_self"
        );
      } else {
        setLoggingOut(false);
        Store.dispatch({ type: LOGOUT });
      }
    } catch (error) {
      setLoggingOut(false);
      Store.dispatch({ type: LOGOUT });
    }
  };

  const handleCancelLogout = () => {
    setShowLogoutConfirm(false);
  };

  const handleLogout = () => {
    setShowOptions(false);
    setShowGetHelp(false);
    setShowLogoutConfirm(true);
  };

  const moveToScreen = (screen) => {
    navigation.push("App", {
      screen,
      params: {},
    });
  };

  const moveToBillingScreen = (screen) => {
    navigation.push("App", {
      screen: screen,
      params: {},
    });
  };

  const moveToTxnScreen = (screen) => {
    navigation.push("App", {
      screen: "Portfolio",
      params: {
        screen,
        params: {},
      },
    });
  };

  const openBSE2FAScreen = async () => {
    const response = await getUrlForClient2FA();
    if (response && response.code == 100) {
      const url = response.result;
      global.open(url, "_blank");
    }
  };

  const moveToContractNoteScreen = (screen) => {
    navigation.push("App", {
      screen: "Dashboard",
      params: {
        screen: "Portfolio",
        params: {
          screen,
          params: {},
        },
      },
    });
  };

  const handleOpenDrawer = () => {
    navigation.openDrawer();
  };

  const { bannerData, is_ria } = getUserDetails();

  return (
    <>
      <Appbar.Header
        theme={{ colors: { primary: "#fff" } }}
        style={[
          {
            backgroundColor: "#fff",
            justifyContent: "center",
            elevation: 1,
            marginTop:
              Platform.OS == "web" ? 0 : Platform.OS == "android" ? 0 : -18,
          },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            maxWidth: 1500,
            justifyContent: "space-between",
          }}
        >
          {width >= 1200 ? (
            <View />
          ) : (
            <TouchableOpacity
              style={{ display: "flex", flexDirection: "row", marginLeft: 10 }}
              onPress={handleOpenDrawer}
            >
              <MaterialIcons name="menu" size={28} color="#121212" />
              {showDetails && width < 1200 && (
                <View style={{ marginLeft: 10 }}>
                  {width >= 1200 && (
                    <TouchableOpacity
                      onPress={() => {
                        setShowOptions(!showOptions);
                        setShowLogoutConfirm(false);
                      }}
                    >
                      <View style={styles.nameContainer}>
                        <Text style={styles.jane}>
                          {userDetails.name || "User"}
                        </Text>
                        <Entypo
                          name={!showOptions ? "triangle-down" : "triangle-up"}
                          size={20}
                          color="#410DAA"
                        />
                      </View>
                      {route.params && route.params.user_id && (
                        <Text style={styles.viewMode}>
                          View mode ({userDetails.customer_id})
                        </Text>
                      )}
                    </TouchableOpacity>
                  )}
                </View>
              )}
            </TouchableOpacity>
          )}
          {width < 1200 && (
            <Image
              source={{
                uri: bannerData.logo_image_url,
              }}
              style={{
                width: width / 2.1,
                height: 25,
                resizeMode: "contain",
                color: "#410DAA",
              }}
            />
          )}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginRight: isMobile ? 0 : 32,
            }}
          >
            {/* {console.log("userdata:", Store.getState().userData.userProfileData)} */}
            {getScreenConfigForCustomer().show_explore_mf &&
              !userDetails.is_preferred_circle && (
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate("App", {
                      screen: "Dashboard",
                      params: {
                        screen: "Explore",
                        params: {
                          screen: "SearchFunds",
                        },
                      },
                    })
                  }
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: isMobile ? 6 : 12,
                    marginRight: isMobile ? 20 : 25,
                  }}
                >
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/search.png",
                    }}
                    style={{
                      width: 22,
                      height: 22,
                      resizeMode: "contain",
                      color: "#410DAA",
                    }}
                  />
                </TouchableOpacity>
              )}
            {/* <TouchableOpacity
              onPress={() =>
                navigation.navigate("App", {
                  screen: "Dashboard",
                  params: {
                    screen: "Dashboard",
                    params: { screen: "ToDoHome", params: {} },
                  },
                })
              }
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginLeft: isMobile ? -5 : 12,
                marginRight: isMobile ? 18 : 25,
              }}
            >
              <Fontisto name="checkbox-active" size={18} color={"#410DAA"} />
            </TouchableOpacity> */}
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("Portfolio", { screen: "Notifications" })
              }
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginLeft: isMobile ? -5 : 12,
                marginRight: isMobile ? 18 : 25,
              }}
            >
              <Image
                source={{
                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/alert.png",
                }}
                style={{
                  width: 22,
                  height: 22,
                  resizeMode: "contain",
                  color: "#410DAA",
                }}
              />
            </TouchableOpacity>
            {is_ria ? null : (
              <TouchableOpacity
                onPress={handleGethelpClick}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: isMobile ? -5 : 10,
                  marginRight: isMobile ? 10 : 30,
                  marginTop: 8,
                }}
              >
                <Image
                  source={{
                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/help_new.png",
                  }}
                  style={{
                    width: 38,
                    height: 38,
                    resizeMode: "contain",
                    color: "#410DAA",
                  }}
                />
              </TouchableOpacity>
            )}
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {showDetails && width >= 1200 && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onMouseEnter={() => {
                    setShowOptions(!showOptions);
                  }}
                >
                  {userDetails.initials ? (
                    <TouchableOpacity
                      style={styles.initialsContainer}
                      onPress={() => {
                        setShowOptions(!showOptions);
                        setShowLogoutConfirm(false);
                      }}
                    >
                      <Text style={styles.initials}>
                        {userDetails.initials}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/boy.png"
                      }
                      style={styles.boy}
                    />
                  )}
                  {!isMobile && (
                    <View style={{ display: "flex", flexDirection: "column" }}>
                      <TouchableOpacity
                        onPress={() => {
                          setShowOptions(!showOptions);
                          setShowLogoutConfirm(false);
                        }}
                      >
                        <View style={styles.nameContainer}>
                          <Text style={styles.jane}>
                            {userDetails.name || "User"}
                          </Text>
                          <Entypo
                            name={
                              !showOptions ? "triangle-down" : "triangle-up"
                            }
                            size={20}
                            color="#410DAA"
                          />
                        </View>
                      </TouchableOpacity>
                      {/* {((Store.getState().dashboard &&
                        Store.getState().dashboard.viewModeUserId &&
                        Store.getState().dashboard.viewModeUserId.user_id) ||
                        (route.params && route.params.user_id)) && (
                        <TouchableOpacity
                          onPress={() => {
                            Store.dispatch({
                              type: "GET_DASHBOARD",
                              payload: {
                                viewModeUserId: {
                                  user_id: null,
                                },
                              },
                            });
                            let url = new URL(window.location.href);
                            location.replace(url.origin + "/Dashboard/Home");
                          }}
                        >
                          <View
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Text style={styles.viewMode}>
                              View mode ({userDetails.customer_id})
                            </Text>
                            <AntDesign
                              name="reload1"
                              size={15}
                              color="#410DAA"
                              style={{ marginLeft: 5 }}
                            />
                          </View>
                        </TouchableOpacity>
                      )} */}
                      {((Store.getState().dashboard &&
                        Store.getState().dashboard.viewModeUserId &&
                        Store.getState().dashboard.viewModeUserId.user_id) ||
                        route?.params?.user_id) && (
                        <TouchableOpacity
                          onPress={() => {
                            Store.dispatch({
                              type: "GET_DASHBOARD",
                              payload: {
                                viewModeUserId: {
                                  user_id: null,
                                },
                              },
                            });
                            if (typeof window !== "undefined") {
                              let url = new URL(window.location.href);
                              location.replace(url.origin + "/Dashboard/Home");
                            } else {
                              // Handle navigation in React Native
                              navigation.navigate("DashboardHome"); // use the appropriate navigation method
                            }
                          }}
                        >
                          <View
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Text style={styles.viewMode}>
                              View mode (
                              {Store.getState().dashboard.viewModeUserId
                                ?.user_id || route?.params?.user_id}
                              )
                            </Text>
                            <AntDesign
                              name="reload1"
                              size={15}
                              color="#410DAA"
                              style={{ marginLeft: 5 }}
                            />
                          </View>
                        </TouchableOpacity>
                      )}
                    </View>
                  )}
                </View>
              )}
              {showOptions &&
                (isMobile ? null : (
                  <View
                    style={{
                      zIndex: 100,
                      position: "absolute",
                      top: 45,
                      right: 0,
                      bottom: 0,
                    }}
                    onMouseLeave={() => {
                      setShowOptions(!showOptions);
                    }}
                  >
                    <View style={styles.log_mod}>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text style={styles.my_acc}>
                          {Store.getState().dashboard &&
                          Store.getState().dashboard.viewModeUserId &&
                          Store.getState().dashboard.viewModeUserId.user_id
                            ? "User Account"
                            : "My Account"}
                        </Text>
                        <Text
                          style={[
                            styles.logut,
                            Platform.OS == "web" && styles.cursor,
                          ]}
                          onPress={handleLogout}
                        >
                          Logout
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          paddingTop: 22,
                          alignItems: "center",
                        }}
                      >
                        <MaterialIcons
                          name="headset"
                          size={18}
                          color="#410DAA"
                        />
                        <Text style={styles.ph_num}>
                          {" "}
                          {userDetails.country_mobile_code}{" "}
                          {userDetails.mobilenumber}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          paddingTop: 22,
                          alignItems: "center",
                        }}
                      >
                        <MaterialIcons
                          name="mail-outline"
                          size={18}
                          color="#410DAA"
                        />
                        <Text style={styles.ph_num}> {userDetails.email}</Text>
                      </View>
                      <View style={styles.sebiRegulations}>
                        <TouchableOpacity
                          onPress={() => moveToScreen("Account")}
                          style={styles.sebiRegulationItem}
                        >
                          <Text style={styles.ph_num}>Manage Account</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => moveToBillingScreen("Billing")}
                          style={styles.sebiRegulationItem}
                        >
                          <Text style={styles.ph_num}>Billing</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() =>
                            moveToTxnScreen("ViewTransactionStatus")
                          }
                          style={styles.sebiRegulationItem}
                        >
                          <Text style={styles.ph_num}>Transaction Status</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            navigation.push("App", {
                              screen: "ViewMode",
                              params: {
                                screen: "ContractNote",
                                params: {},
                              },
                            });
                          }}
                          style={styles.sebiRegulationItem}
                        >
                          <Text style={styles.ph_num}>Contract Notes</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            openBSE2FAScreen();
                          }}
                          style={styles.sebiRegulationItem}
                        >
                          <Text style={styles.ph_num}>Approve Orders</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                ))}
            </View>
          </View>
        </View>
        {showLogoutConfirm &&
          (!isMobile ? (
            <View style={styles.logOutAlertContainer}>
              <ConfirmAlert
                onDismiss={handleCancelLogout}
                onLogout={handleContinueToLogout}
              />
            </View>
          ) : null)}
        {loggingOut &&
          (!isMobile ? (
            <View style={styles.logOutAlertContainer}>
              <LogoutLoading description={`Logging out from ${ isJama() ? "Jama" : "Maxiom" } Wealth`} />
            </View>
          ) : null)}
      </Appbar.Header>
      {showOptions &&
        (isMobile ? (
          <View
            style={{
              right: 0,
              bottom: 0,
              height: 210,
              padding: 10,
              borderRadius: 5,
              borderWidth: 1,
              borderColor: "rgba(0, 0, 0, 0.1)",
              backgroundColor: "white",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  fontSize: 18,
                  lineHeight: 21,
                  color: "#464E5F",
                  flex: 1,
                  textAlign: "left",
                }}
              >
                {Store.getState().dashboard &&
                Store.getState().dashboard.viewModeUserId &&
                Store.getState().dashboard.viewModeUserId.user_id
                  ? "User Account"
                  : "My Account"}
              </Text>
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontSize: 13,
                  lineHeight: 15,
                  color: "#6C7293",
                  alignItems: "center",
                  flex: 1,
                  textAlign: "right",
                }}
                onPress={handleLogout}
              >
                Logout
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                paddingTop: 22,
                alignItems: "center",
              }}
            >
              <MaterialIcons name="headset" size={18} color="#410DAA" />
              <Text style={styles.ph_num}>
                {" "}
                {userDetails.country_mobile_code} {userDetails.mobilenumber}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                paddingTop: 18,
                paddingBottom: 10,
                alignItems: "center",
              }}
            >
              <MaterialIcons name="mail-outline" size={18} color="#410DAA" />
              <Text style={styles.ph_num}> {userDetails.email}</Text>
            </View>
            <View style={styles.sebiRegulations}>
              <TouchableOpacity
                onPress={() => moveToScreen("Account")}
                style={styles.sebiRegulationItem}
              >
                <Text style={styles.ph_num}>Manage Account</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => moveToBillingScreen("Billing")}
                style={styles.sebiRegulationItem}
              >
                <Text style={styles.ph_num}>Billing</Text>
              </TouchableOpacity>
            </View>
          </View>
        ) : null)}
      {loggingOut &&
        (isMobile ? (
          <View
            style={[
              flex.row,
              flex.center,
              styles.logOutAlertContainer,
              { backgroundColor: "white" },
            ]}
          >
            <Image
              source={{
                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Broker+logout.png",
              }}
              style={{
                width: 28,
                height: 28,
                marginRight: 10,
              }}
            />
            <Text
              style={{
                fontFamily: "Roboto",
                fontSize: isMobile ? 15 : 18,
                color: "#121212",
              }}
            >
              Logging out from  {isJama() ? "Jama" : "maxiom"} Wealth
            </Text>
          </View>
        ) : null)}
      {showLogoutConfirm &&
        (isMobile ? (
          <View
            style={{
              right: 0,
              bottom: 0,
              height: 210,
              padding: 10,
              borderRadius: 5,
              borderWidth: 1,
              borderColor: "rgba(0, 0, 0, 0.1)",
              backgroundColor: "white",
            }}
          >
            <View onDismiss={handleCancelLogout}>
              <Text style={alert.confirm}>Confirm</Text>
              <View style={[flex.row, flex.center, alert.sureContainer]}>
                <Text style={alert.sure}>
                  Are you sure you want to logout from Jamā Wealth?
                </Text>
              </View>
              <View style={alert.buttons}>
                <Button
                  uppercase={false}
                  mode="contained"
                  labelStyle={alert.labelStyle}
                  color="#E2E8F0"
                  style={alert.buttonStyle}
                  onPress={handleCancelLogout}
                >
                  No
                </Button>
                <Button
                  uppercase={false}
                  mode="contained"
                  labelStyle={alert.labelStyle}
                  color="#E2E8F0"
                  style={alert.buttonStyle}
                  onPress={handleContinueToLogout}
                >
                  Yes
                </Button>
              </View>
            </View>
          </View>
        ) : null)}
      {showGetHelp &&
        (isMobile ? (
          <View
            style={{
              right: 0,
              bottom: 0,
              height: 210,
              padding: 10,
              borderRadius: 5,
              borderWidth: 1,
              borderColor: "rgba(0, 0, 0, 0.1)",
              backgroundColor: "white",
            }}
          >
            <Text style={styles.ph_num}>FAQs</Text>
            <TouchableOpacity
              onPress={() => {
                setShowGetHelp(false);
                navigation.navigate("App", {
                  screen: "Dashboard",
                  params: {
                    screen: "Portfolio",
                    params: {
                      screen: "ShowWebView",
                      params: {
                        url: `https://maxiom.co.in/app/acn_faqs/EQUINVREBAL`,
                      },
                    },
                  },
                });
              }}
              style={{ paddingTop: 10 }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text style={{ marginLeft: 20, color: "#410DAA" }}>
                  {"\u2022"}
                </Text>
                <Text
                  style={[
                    styles.ph_num,
                    {
                      textDecorationLine: "underline",
                      paddingLeft: 5,
                      color: "#410DAA",
                    },
                  ]}
                >
                  Equity Investing & Rebalancing
                </Text>
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                setShowGetHelp(false);
                navigation.navigate("App", {
                  screen: "Dashboard",
                  params: {
                    screen: "Portfolio",
                    params: {
                      screen: "ShowWebView",
                      params: {
                        url: `https://maxiom.co.in/app/acn_faqs/EQADV`,
                      },
                    },
                  },
                });
              }}
              style={{ paddingTop: 3 }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text style={{ marginLeft: 20, color: "#410DAA" }}>
                  {"\u2022"}
                </Text>
                <Text
                  style={[
                    styles.ph_num,
                    {
                      textDecorationLine: "underline",
                      paddingLeft: 5,
                      color: "#410DAA",
                    },
                  ]}
                >
                  Equity Advisory Account Related
                </Text>
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                setShowGetHelp(false);
                navigation.navigate("App", {
                  screen: "Dashboard",
                  params: {
                    screen: "Portfolio",
                    params: {
                      screen: "ShowWebView",
                      params: {
                        url: `https://maxiomwealth.com/blog/how-to-invest-in-jamawealth-portfolio/`,
                      },
                    },
                  },
                });
              }}
              style={{ paddingTop: 3 }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text style={{ marginLeft: 20, color: "#410DAA" }}>
                  {"\u2022"}
                </Text>
                <Text
                  style={[
                    styles.ph_num,
                    {
                      textDecorationLine: "underline",
                      paddingLeft: 5,
                      color: "#410DAA",
                    },
                  ]}
                >
                  How to invest?
                </Text>
              </View>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                setShowGetHelp(false);
                navigation.navigate("App", {
                  screen: "Dashboard",
                  params: {
                    screen: "Portfolio",
                    params: {
                      screen: "ShowWebView",
                      params: {
                        url: `https://maxiomwealth.com/blog/how-to-rebalance-your-portfolio-on-jamawealth/`,
                      },
                    },
                  },
                });
              }}
              style={{ paddingTop: 3 }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text style={{ marginLeft: 20, color: "#410DAA" }}>
                  {"\u2022"}
                </Text>
                <Text
                  style={[
                    styles.ph_num,
                    {
                      textDecorationLine: "underline",
                      paddingLeft: 5,
                      color: "#410DAA",
                    },
                  ]}
                >
                  How to rebalance portfolio?
                </Text>
              </View>
            </TouchableOpacity>
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={() => {
                setShowGetHelp(false);
                navigation.push("App", {
                  screen: "RaiseTicket",
                  params: {
                    screen: "Feedback",
                  },
                });
              }}
              style={{
                width: 150,
                alignSelf: "flex-start",
                marginTop: 17,
              }}
              color={"#410DAA"}
            >
              <Text style={{ fontSize: 12 }}>Raise a Ticket</Text>
            </Button>
          </View>
        ) : null)}
    </>
  );
};

const styles = StyleSheet.create({
  boy: {
    width: isMobile ? 30 : 50.4,
    height: isMobile ? 30 : 50.4,
    backgroundColor: "rgba(65, 13, 170, 0.1)",
    borderRadius: 6,
  },
  jane: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    color: "#464E5F",
    fontSize: 20,
    lineHeight: 23,
  },
  ph_num: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#465964",
    flexWrap: "wrap",
  },
  initialsContainer: {
    backgroundColor: "#ffffff",
    borderRadius: 6,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: isMobile ? 5.5 : 6,
    paddingVertical: isMobile ? 5.5 : 8,
    borderWidth: isMobile ? 0 : 1,
    borderColor: "#410DAA",
    borderRadius: 5,
  },
  initials: {
    color: "#410DAA",
    fontSize: isMobile ? 17 : 20,
    lineHeight: 23,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  nameContainer: {
    flexDirection: "row",
    paddingLeft: 20,
    justifyContent: "space-between",
    marginVertical: 4,
  },
  log_mod: {
    backgroundColor: "#ffffff",
    shadowColor: "gray",
    shadowRadius: 5,
    borderRadius: 6,
    paddingHorizontal: isMobile ? 14 : 20,
    paddingTop: 17,
    paddingBottom: 10,
    width: isMobile ? "100%" : 355,
  },
  my_acc: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 21,
    color: "#464E5F",
  },
  logut: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#6C7293",
    alignItems: "center",
  },
  cursor: {},
  viewMode: {
    color: "red",
    fontSize: 14,
    paddingLeft: 20,
  },
  logOutAlertContainer: {
    position: "absolute",
    width: width,
    height: height,
    width: "100%",
    top: 0,
    bottom: 0,
    justifyContent: "center",
    backgroundColor: "#00000050",
  },
  sebiRegulations: {
    marginTop: 8,
    paddingTop: 8,
    borderTopWidth: 1,
    borderTopColor: "#cdcdcd50",
  },
  sebiRegulationItem: {
    paddingVertical: 7,
  },
});
