import React from "react";
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  TextInput,
  Platform,
} from "react-native";
import amplitude from "../../utils/amplitude";
import { md, xs } from "../../Responsive";
const window = Dimensions.get("window");
import NavigationHeader from "../Common/NavigationHeader";
import {
  getCIPDetailsInvestNow,
  getCustomerDetails,
  getHashKey,
} from "../../api";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../api/apiConfig";
import {
  formatDate,
  getTodayDate,
  getUserDetails,
  isMobile,
  numberWithCommas,
  completeMinDetails,
  reverseString,
  valueInWords,
} from "../../utils";
import { MAIN_URL } from "../../api/urls";
import ComplianceModal from "../InvestmentOverview/ComplianceModal";
import { StackActions } from "@react-navigation/native";
import Checkbox from "expo-checkbox";

export default class InvestNow extends React.Component {
  constructor(props) {
    super(props);
    let { params } = this.props.route;
    let id = null;
    let user_id = null;
    if (params) {
      id = params.id || null;
      user_id = params.user_id || null;
    }
    this.state = {
      data: null,
      topup_amount: 0,
      topup_amount_summary: 0,
      plannedAmount: 0,
      numberOfPlans: 0,
      id,
      user_id,
    };
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("InvestNow", { path: global.location.href });
      } else {
        amplitude.logEvent("InvestNow", {
          path: this.props.navigation.getState().routeName,
        });
      }
      const { id, user_id } = this.state;
      const { navigation } = this.props;
      this.setState({ gettingComplianceData: true });
      let userData = {};
      if (user_id) {
        let details = await getCustomerDetails(user_id);
        if (details && details.pk) {
          userData = details;
        } else {
          userData = await getUserDetails();
        }
      } else {
        userData = await getUserDetails();
      }

      const { customer_id, skip_subscription } = userData;

      let skip_compliance = false;
      const { params } = this.props.route;
      if (params) {
        skip_compliance = params.s || false;
      }
      const minDetailsResponse = await completeMinDetails(
        true,
        customer_id,
        skip_compliance
      );
      if (minDetailsResponse.needCompletion) {
        this.setState({
          minDetailsResponse,
          gettingComplianceData: false,
          showComplianceAlert: true,
        });
      } else {
        if (id) {
          const data = { cip_pk: id };
          const response = await getCIPDetailsInvestNow(data);
          if (response.code == 100) {
            const { result } = response;
            const {
              installments,
              cip_pk,
              plan_start_date,
              plan_end_date,
              ...otherFields
            } = result;
            installments.map((item, index) => {
              installments[index]["investment_type"] = otherFields.plan_type;
              installments[index]["investment_name"] = otherFields.plan_name;
              installments[index]["total_installments"] =
                otherFields.total_installments;
              installments[index]["selected"] = true;
            });
            this.setState(
              {
                data: {
                  cip_pk,
                  plan_start_date,
                  plan_end_date,
                  investments: [...installments],
                },
                gettingComplianceData: false,
              },
              () => {
                this.setSummary();
              }
            );
          } else {
            this.setState({
              gettingComplianceData: false,
              error: true,
              errorText:
                response && response.msg
                  ? response.msg
                  : "Something went wrong!",
            });
          }
        } else {
          this.setState({
            gettingComplianceData: false,
            error: true,
            errorText: JSON.stringify(params) || "Something went wrong!",
          });
        }
      }
    } catch (error) {
      this.setState({
        gettingComplianceData: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  proceedToInvest = async () => {
    try {
      const isValid = this.validateProceedToInvest();
      if (!isValid) return;
      const { data, topup_amount_summary } = this.state;
      const investData = data.investments.filter((plan) => plan.selected);
      let url = MAIN_URL + "/app/gwsc/placeorder/";
      let url_payalod = "";
      const length = investData.length;
      investData.forEach((item, index) => {
        url_payalod +=
          "CIPS" + item.cips_pk + "_" + parseInt(item.planned_amount);
        if (index != length - 1) {
          url_payalod += "|";
        }
      });
      if (topup_amount_summary) {
        if (investData.length != 0) url_payalod += "|";
        url_payalod += "topup_" + topup_amount_summary;
      }
      let product = null;
      const { params } = this.props.route;
      if (params && params.product) {
        product = params.product;
      }
      if (!product) return;
      const payload = {
        cipamountstr: url_payalod,
        product: product,
      };
      if (Platform.OS == "android" || Platform.OS == "ios") {
        payload["mobile"] = true;
      }

      this.setState({ isLoading: true });
      const response = await getHashKey(payload);
      if (response.code == 100) {
        const { hash_key } = response.result;
        let token = getTokenFromStore();
        token = reverseString(token);
        url =
          url +
          encodeURIComponent(hash_key + token + "/" + GATEWAY_ENVIRONMENT);
        if (Platform.OS == "web") {
          global.open(url, "_self");
        } else {
          this.props.navigation.dispatch(
            StackActions.replace("App", {
              screen: "Dashboard",
              params: {
                screen: "Dashboard",
                params: {
                  screen: "Gateway",
                  params: {
                    type: "PLACE_ORDER_TOPUP",
                    data: response.result,
                    screen: "TransactionStatus",
                  },
                },
              },
            })
          );
        }
        this.setState({ isLoading: false });
      } else {
        if (response.code == 102) {
          this.setState({
            isLoading: false,
            topupErrorText:
              response && response.msg
                ? response.msg
                : "Please enter valid amount",
          });
        } else {
          this.setState({
            isLoading: false,
            error: true,
            errorText: "Something went wrong!",
          });
        }
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  validateProceedToInvest = () => {
    const { topup_amount_summary } = this.state;
    return (
      (this.state.data &&
        this.state.data.investments.filter((i) => i.selected == true).length !=
          0) ||
      (!isNaN(topup_amount_summary) && topup_amount_summary)
    );
  };

  toggleSelect = (index) => {
    const { data } = this.state;
    data.investments[index]["selected"] =
      !this.state.data.investments[index]["selected"];
    this.setState({ data }, () => {
      this.setSummary();
    });
  };

  handleTopupApply = () => {
    if (this.state.topup_amount < 1000) {
      this.setState({
        topupErrorText: "Please enter min of Rs.1000 for investment",
      });
    } else {
      this.setState({ topupErrorText: "" });
    }
    this.setState({
      applyLoading: false,
      topup_amount_summary: this.state.topup_amount,
    });
  };

  onChangeTopupAmount = (topup_amount) => {
    this.setState({ topup_amount });
  };

  formatAmount = (amount) => {
    if (!amount) return 0;
    amount = parseInt(amount);
    return amount;
  };

  setSummary = () => {
    let { data, plannedAmount, numberOfPlans } = this.state;
    if (data && data.investments && data.investments.length != 0) {
      plannedAmount = data.investments.reduce((total, plan) => {
        if (plan.selected) {
          total += plan.planned_amount;
        }
        return total;
      }, 0);
      numberOfPlans = data.investments.filter((plan) => plan.selected).length;
    }
    this.setState({
      plannedAmount,
      numberOfPlans,
    });
  };

  render() {
    const {
      gettingComplianceData,
      minDetailsResponse,
      showComplianceAlert,
      data,
      error,
      errorText = "Something went wrong!",
      topupErrorText,
      topup_amount,
      topup_amount_summary,
      summaryExpanded,
      plannedAmount,
      numberOfPlans,
      isLoading = false,
    } = this.state;
    const { params = {} } = this.props.route;
    if (error) {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <View style={{ flex: 1, maxWidth: 1500 }}>
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={[
                "Equity Plan",
                "My Equity Investment Plans",
                "Invest Now",
              ]}
              style={{ width: "100%", maxWidth: 1500 }}
            />
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText}
              </Text>
            </View>
          </View>
        </View>
      );
    }

    if (gettingComplianceData || showComplianceAlert) {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <View style={{ flex: 1, maxWidth: 1500 }}>
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={[
                "Equity Plan",
                "My Equity Investment Plans",
                "Invest Now",
              ]}
              onPressBack={() => {
                this.props.navigation.navigate("InvestmentOverview");
              }}
            />
            <ComplianceModal
              navigation={this.props.navigation}
              route={this.props.route}
              isLoading={gettingComplianceData}
              minDetailsResponse={minDetailsResponse}
              redirectBackTo={"MyEquityInvestmentPlans"}
              onClose={() => {
                if (isMobile) {
                  this.props.navigation.dispatch(
                    StackActions.replace("App", {
                      screen: "Dashboard",
                      params: {
                        screen: "Plans",
                        params: {
                          screen: "MyEquityInvestmentPlans",
                          params: {},
                        },
                      },
                    })
                  );
                } else {
                  this.props.navigation.dispatch(
                    StackActions.replace("App", {
                      screen: "Plans",
                      params: {
                        screen: "MyEquityInvestmentPlans",
                        params: {},
                      },
                    })
                  );
                }
              }}
              navigationOptions={{
                stack: "Portfolio",
                screen: "InvestNow",
                ...params,
              }}
            />
          </View>
        </View>
      );
    }

    return (
      <View style={{ flex: 1, alignItems: "center", backgroundColor: "#fff" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={[
            "Equity Plan",
            "My Equity Investment Plans",
            "Invest Now",
          ]}
          style={{ width: "100%", maxWidth: 1500 }}
          onPressBack={() => {
            // console.log("KK")
            this.props.navigation.navigate("Plans")
          }}
        />
        {data ? (
          <ScrollView style={styles.mainContainer}>
            {data && <Text style={styles.description}>Your Investment in</Text>}

            <View
              style={[
                {
                  flexDirection: md(window.width) ? "row" : "column",
                },
              ]}
            >
              <View
                style={{
                  flex: md(window.width) ? 0.65 : 1,
                }}
              >
                {data &&
                  data.investments &&
                  data.investments.length > 0 &&
                  data.investments.map((investment, index) => {
                    return (
                      <View
                        style={{
                          borderColor: "#410DAA30",
                          borderWidth: 1,
                          borderRadius: 4,
                          marginBottom: 21,
                        }}
                      >
                        <View
                          style={{
                            flexGrow: 1,
                            flexDirection: "row",
                            alignItems: "flex-start",
                            padding: md(window.width) ? 18 : 8,
                          }}
                        >
                          <View style={{ flex: 1, flexDirection: "row" }}>
                            <Checkbox
                              status={
                                investment.selected ? "checked" : "unchecked"
                              }
                              color={investment.selected ? "#410DAA" : "gray"}
                              value={investment.selected}
                              onValueChange={() => {
                                // !investment.is_due && this.toggleSelect(index)
                                this.toggleSelect(index);
                              }}
                              style={{
                                marginRight: md(window.width) ? 15 : 8,
                                width: isMobile ? 13 : 14,
                                height: isMobile ? 13 : 14,
                                color: investment.selected ? "#410DAA" : "gray",
                              }}
                            />
                            <View style={{ flex: 1 }}>
                              <View
                                style={{
                                  flex: 1,
                                  flexDirection:
                                    window.width > 320 ? "row" : "column",
                                  justifyContent: "space-between",
                                  alignItems: "flex-start",
                                  marginLeft: isMobile ? 10 : 0,
                                }}
                              >
                                <View>
                                  <Text
                                    style={{
                                      color: "#464E5F",
                                      fontSize: md(window.width) ? 15 : 13,
                                      // lineHeight:17.5,
                                      paddingBottom: 11,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {investment.investment_name} -
                                    <Text
                                      style={{
                                        fontSize: md(window.width) ? 14 : 13,
                                        color: "#1BC5BD",
                                        // lineHeight:16,
                                        fontWeight: "normal",
                                        paddingLeft: 5,
                                      }}
                                    >
                                      {investment.investment_type}
                                    </Text>
                                  </Text>

                                  <Text
                                    style={{
                                      fontSize: isMobile ? 11 : 12,
                                      color: "#4A4B68",
                                    }}
                                  >
                                    {investment.investment_type != "Lumpsum"
                                      ? investment.installment_number +
                                        "/" +
                                        investment.total_installments +
                                        " Installment"
                                      : "Investment"}
                                    -
                                    <Text
                                      style={{
                                        color: investment.is_due
                                          ? "#EF5D12"
                                          : "#4A4B68",
                                        paddingLeft: 5,
                                        fontStyle: "italic",
                                      }}
                                    >
                                      {investment.due_in}
                                    </Text>
                                  </Text>
                                </View>

                                {md(window.width) && (
                                  <View
                                    style={{
                                      marginTop: md(window.width) ? 0 : 15,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 13,
                                        color: "#4A4B68",
                                        lineHeight: 15,
                                        paddingBottom: 11,
                                      }}
                                    >
                                      Planned Date
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color: "#112211",
                                        lineHeight: 21,
                                      }}
                                    >
                                      {investment.planned_date}
                                    </Text>
                                  </View>
                                )}

                                <View
                                  style={{
                                    marginTop: window.width > 320 ? 0 : 15,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      color: "#4A4B68",
                                      lineHeight: 15,
                                      paddingBottom: md(window.width) ? 11 : 8,
                                    }}
                                  >
                                    Planned Amount
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: md(window.width) ? 14 : 13,
                                      color: "#112211",
                                      lineHeight: 21,
                                    }}
                                  >
                                    ₹{" "}
                                    {numberWithCommas(
                                      parseInt(investment.planned_amount)
                                    )}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                        <Text
                          style={{
                            fontSize: 11,
                            color: "#888C9F",
                            marginTop: isMobile ? 22 : 7,
                            marginLeft: 8,
                            marginBottom: 5,
                          }}
                        >
                          Plan No: {data.cip_pk} | (
                          {formatDate(data.plan_start_date)} -{" "}
                          {formatDate(data.plan_end_date)})
                        </Text>
                      </View>
                    );
                  })}

                {data && data.investments && data.investments.length > 0 && (
                  <View
                    style={{
                      width: "100%",
                      height: 2,
                      backgroundColor: "#00000010",
                      marginVertical: 24,
                    }}
                  ></View>
                )}
                {isMobile && (
                  <View
                    style={{
                      flex: 1,
                      marginTop: -20,
                      marginBottom: 20,
                    }}
                  >
                    <View
                      style={{
                        borderColor: "#410DAA40",
                        borderWidth: 1,
                        paddingVertical: 15,
                        paddingHorizontal: 19,
                        marginLeft: md(window.width) ? 40 : 0,
                        borderRadius: 12,
                        marginTop: md(window.width) ? 0 : 40,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          color: "#464E5F",
                          fontWeight: "700",
                          lineHeight: 20.7,
                          paddingBottom: 25,
                        }}
                      >
                        Summary :
                      </Text>
                      <View
                        style={{
                          flexDirection: md(window.width) ? "row" : "column",
                        }}
                      >
                        <View style={{}}>
                          <Text style={{ fontSize: 17, color: "#121212" }}>
                            Investment Total :
                          </Text>
                          {!!numberOfPlans && (
                            <Text style={{ fontSize: 15, color: "#121212" }}>
                              ({numberOfPlans}
                              {numberOfPlans > 1 ? " Plans" : " Plan"})
                            </Text>
                          )}
                        </View>
                        <Text
                          style={{
                            color: "#410DAA",
                            fontStyle: "italic",
                            fontWeight: "bold",
                            paddingLeft: md(window.width) ? 6 : 0,
                            marginTop: md(window.width) ? 0 : 8,
                          }}
                        >
                          ₹{" "}
                          {(
                            parseInt(plannedAmount) +
                            Number(topup_amount_summary)
                          ).toLocaleString()}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          paddingTop: 12,
                          paddingBottom: 8,
                        }}
                      >
                        <Text
                          style={{ width: 139, color: "#464E5F", fontSize: 15 }}
                        >
                          Planned Installment
                        </Text>
                        <Text style={{ color: "#464E5F", fontSize: 15 }}>
                          ₹ {parseInt(plannedAmount).toLocaleString()}
                        </Text>
                      </View>
                      <>
                        <View style={{ flexDirection: "row" }}>
                          <Text
                            style={{
                              width: 139,
                              color: "#464E5F",
                              fontSize: 15,
                            }}
                          >
                            Added Top-up
                          </Text>
                          <Text style={{ color: "#464E5F", fontSize: 15 }}>
                            ₹ {numberWithCommas(topup_amount_summary)}
                          </Text>
                        </View>
                        <Text
                          style={{
                            color: "#464E5F",
                            fontSize: 12,
                            fontStyle: "italic",
                            paddingTop: 45,
                          }}
                        >
                          *Topup to the chosen Plan, will be as per the terms of
                          the plan*
                        </Text>
                      </>
                    </View>
                  </View>
                )}
                <View
                  style={{
                    flexDirection: md(window.width) ? "row" : "column",
                    backgroundColor: "#EEE5FF90",
                    borderRadius: 12,
                    padding: 13,
                    marginTop: 6,
                    marginBottom: md(window.width) ? 0 : 200,
                  }}
                >
                  <View style={{ paddingRight: md(window.width) ? 33 : 0 }}>
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#8950FC",
                        paddingBottom: 13,
                        fontWeight: "700",
                      }}
                    >
                      Top up
                    </Text>
                    {md(window.width) && (
                      <Text style={{ fontSize: 15, color: "#4A4B68" }}>
                        Today - {getTodayDate()}
                      </Text>
                    )}
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          color: "#464E5F",
                          fontSize: 15,
                          paddingBottom: 13,
                          marginTop: md(window.width) ? 0 : 15,
                        }}
                      >
                        How much would you like to top up with?
                      </Text>
                      <View
                        style={{
                          flexDirection: isMobile ? "column" : "row",
                          alignItems: "flex-end",
                          width: md(window.width) ? "100%" : "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <View>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 15,
                                color: "#121212",
                                paddingRight: -15,
                              }}
                            >
                              ₹
                            </Text>
                            <TextInput
                              value={topup_amount}
                              onChangeText={this.onChangeTopupAmount}
                              keyboardType={"number-pad"}
                              style={{
                                width: "100%",
                                fontSize: 15,
                                lineHeight: 30,
                                paddingHorizontal: 3,
                                borderBottomColor: "#D8D8D8",
                                borderBottomWidth: 1,
                              }}
                            />
                          </View>
                          {!isMobile && (
                            <Text
                              style={{
                                marginTop: 5,
                                paddingLeft: 10,
                                fontSize: 13,
                                color: "#464E5F",
                              }}
                            >
                              {valueInWords(topup_amount)}
                            </Text>
                          )}
                        </View>
                        <TouchableOpacity
                          style={{
                            backgroundColor: "transparent",
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            borderRadius: 5,
                            paddingHorizontal: md(window.width) ? 25 : 15,
                            paddingVertical: 7,
                            marginLeft: md(window.width) ? 27 : 0,
                            marginTop: md(window.width) ? 0 : 15,
                            alignSelf: "flex-start",
                            marginLeft: isMobile ? 0 : 25,
                          }}
                          onPress={this.handleTopupApply}
                        >
                          {this.state.applyLoading ? (
                            <ActivityIndicator size="small" color="#410DAA" />
                          ) : (
                            <Text
                              style={{
                                color: "#410DAA",
                                fontSize: md(window.width) ? 15 : 13,
                              }}
                            >
                              Apply
                            </Text>
                          )}
                        </TouchableOpacity>
                      </View>
                      <Text
                        style={{ marginTop: 5, fontSize: 13, color: "red" }}
                      >
                        {topupErrorText || ""}
                      </Text>
                    </View>
                    <View style={{ justifyContent: "flex-end" }}>
                      {/* <TouchableOpacity
                                        style={{
                                            backgroundColor:"transparent",
                                            borderColor:"#410DAA",
                                            borderWidth:1,
                                            borderRadius:5,
                                            paddingHorizontal: md(window.width) ?25:15,
                                            paddingVertical:7,
                                            marginLeft: md(window.width) ? 27: 0,
                                            marginTop: md(window.width) ? 0 : 15,
                                            alignSelf:"flex-start"
                                        }}
                                    >
                                        <Text style={{
                                            color:"#410DAA",
                                            fontSize: md(window.width) ? 15 : 13
                                        }}>
                                            Apply
                                        </Text>
                            </TouchableOpacity> */}
                    </View>
                  </View>
                  {isMobile && (
                    <Text
                      style={{
                        marginTop: 5,
                        paddingLeft: 10,
                        fontSize: 13,
                        color: "#464E5F",
                      }}
                    >
                      {valueInWords(topup_amount)}
                    </Text>
                  )}
                </View>
                {md(window.width) && (
                  <TouchableOpacity
                    style={[
                      this.validateProceedToInvest()
                        ? styles.proceedToInvestEnabled
                        : styles.proceedToInvestDisabled,
                    ]}
                    onPress={this.proceedToInvest}
                  >
                    {isLoading ? (
                      <ActivityIndicator size="large" color="#fff" />
                    ) : (
                      <Text style={styles.proceedToInvestText}>
                        Continue to Invest
                      </Text>
                    )}
                  </TouchableOpacity>
                )}
              </View>

              {/* Summary - Show only on Web */}
              {md(window.width) && (
                <View
                  style={{
                    flex: md(window.width) ? 0.35 : 1,
                  }}
                >
                  <View
                    style={{
                      borderColor: "#410DAA40",
                      borderWidth: 1,
                      paddingVertical: 15,
                      paddingHorizontal: 19,
                      marginLeft: md(window.width) ? 40 : 0,
                      borderRadius: 12,
                      marginTop: md(window.width) ? 0 : 40,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#464E5F",
                        fontWeight: "700",
                        lineHeight: 20.7,
                        paddingBottom: 25,
                      }}
                    >
                      Summary :
                    </Text>
                    <View
                      style={{
                        flexDirection: md(window.width) ? "row" : "column",
                      }}
                    >
                      <View style={{}}>
                        <Text style={{ fontSize: 17, color: "#121212" }}>
                          Investment Total :
                        </Text>
                        {!!numberOfPlans && (
                          <Text style={{ fontSize: 15, color: "#121212" }}>
                            ({numberOfPlans}
                            {numberOfPlans > 1 ? " Plans" : " Plan"})
                          </Text>
                        )}
                      </View>
                      <Text
                        style={{
                          color: "#410DAA",
                          fontStyle: "italic",
                          fontWeight: "bold",
                          paddingLeft: md(window.width) ? 6 : 0,
                          marginTop: md(window.width) ? 0 : 8,
                        }}
                      >
                        ₹{" "}
                        {(
                          parseInt(plannedAmount) + Number(topup_amount_summary)
                        ).toLocaleString()}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        paddingTop: 12,
                        paddingBottom: 8,
                      }}
                    >
                      <Text
                        style={{ width: 139, color: "#464E5F", fontSize: 15 }}
                      >
                        Planned Installment
                      </Text>
                      <Text style={{ color: "#464E5F", fontSize: 15 }}>
                        ₹ {parseInt(plannedAmount).toLocaleString()}
                      </Text>
                    </View>
                    {/* {
                                            !!topup_amount_summary && ( */}
                    <>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={{ width: 139, color: "#464E5F", fontSize: 15 }}
                        >
                          Added Top-up
                        </Text>
                        <Text style={{ color: "#464E5F", fontSize: 15 }}>
                          ₹ {numberWithCommas(topup_amount_summary)}
                        </Text>
                      </View>
                      <Text
                        style={{
                          color: "#464E5F",
                          fontSize: 12,
                          fontStyle: "italic",
                          paddingTop: 45,
                        }}
                      >
                        *Topup to the chosen Plan, will be as per the terms of
                        the plan*
                      </Text>
                    </>
                    {/* )
                                        } */}
                  </View>
                </View>
              )}
            </View>
          </ScrollView>
        ) : (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        )}

        {data && xs(window.width) && (
          <View
            style={[
              styles.continueButtonFixedContainer,
              Platform.OS == "android"
                ? {
                    elevation: !isMobile ? 0 : 1,
                  }
                : Platform.OS == "ios"
                ? {
                    shadowColor: !isMobile ? "transparent" : "#000",
                    shadowOffset: {
                      width: 0,
                      height: 1,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 2.22,
                  }
                : Platform.OS == "web"
                ? {
                    boxSizing: "border-box",
                    border: !isMobile
                      ? "0px"
                      : "1px solid rgba(65, 13, 170, 0.1)",
                    // borderWidth:isMobile?0:1,
                    //boxShadow: !isMobile? "0px 0px 0px": "0px 3px 10px rgba(0, 0, 0, 0.1)"
                  }
                : {},
            ]}
          >
            <TouchableOpacity
              style={[
                this.validateProceedToInvest()
                  ? styles.proceedToInvestEnabled
                  : styles.proceedToInvestDisabled,
                styles.continueButtonFixed,
              ]}
              onPress={this.proceedToInvest}
            >
              {isLoading ? (
                <ActivityIndicator size="large" color="#fff" />
              ) : (
                <Text style={styles.proceedToInvestText}>
                  Continue to Invest
                </Text>
              )}
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    backgroundColor: "#fff",
    paddingLeft: md(window.width) ? 32 : 16,
    paddingRight: md(window.width) ? 32 : 16,
    maxWidth: 1400,
    //   height: "100%"
  },
  description: {
    fontSize: md(window.width) ? 17 : 15,
    lineHeight: 21,
    color: "#212121",
    marginTop: 40,
    marginBottom: 31,
  },
  proceedToInvestDisabled: {
    backgroundColor: "#888C9F",
    alignSelf: "flex-start",
    paddingVertical: 12,
    paddingHorizontal: 100,
    borderRadius: 6,
    marginTop: 35,
    marginBottom: 100,
  },
  proceedToInvestEnabled: {
    backgroundColor: "#410DAA",
    alignSelf: md(window.width) ? "flex-start" : "center",
    paddingVertical: 12,
    paddingHorizontal: md(window.width) ? 100 : 50,
    borderRadius: 6,
    marginTop: 35,
    marginBottom: 100,
  },
  proceedToInvestText: {
    color: "#fff",
    fontSize: 15,
    fontWeight: "bold",
  },
  continueButtonFixedContainer: {
    position: Platform.OS == "web" ? "fixed" : "absolute",
    bottom: isMobile ? 70 : 0,
    right: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 8,
  },
  continueButtonFixed: {
    marginBottom: 0,
    borderRadius: 0,
    marginBottom: -5,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
    width: "100%",
    marginTop: 14,
  },
});
