import React from "react";
import amplitude from "../../../utils/amplitude";
import {
  View,
  Text,
  ScrollView,
  TouchableHighlight,
  StyleSheet,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Switch,
  Platform,
} from "react-native";
import DashboardField from "../DashboardField";
import { NavigatableStrip } from "../navigatableStrip";
import { PieChartDashboard } from "../PieChartDashboard";
import {
  getTransactionPopUp,
  getLandingPageDetails,
  getCustomerDetails,
} from "../../../api";
import { PopUp } from "../../Common/PopUp";
import {
  completeMinDetails,
  getUserDetails,
  isMobile,
  numberWithCommas,
  reverseString,
  toFixed,
} from "../../../utils";
import { AntDesign } from "@expo/vector-icons";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../../api/apiConfig";
import { MAIN_URL } from "../../../api/urls";
import { TopupStrip } from "../../Common/TopUpStrip";
import { Popable } from "react-native-popable";
import { SparkTeaser } from "../SparkTeaser";
import ComplianceStrip from "../ComplianceStrip";
import ComplianceModal from "../ComplianceModal";
import { EquityPlans } from "../EquityPlans";
import FamilyPopUp from "../FamilyPopup";
import { Button } from "react-native-paper";
import { StackActions } from "@react-navigation/routers";
import handleTopup from "../../../utils/handleTopup";
import { ShowToast } from "../../../components/Common/Toast";

const CompleteUpcomingInvestments = ({
  title,
  description,
  descriptionColor,
  icon,
  style = {},
  amount,
  navigate,
}) => {
  const isMobile = isMobile;
  return (
    <View
      style={[
        {
          padding: 20,
          backgroundColor: "#fff",
          borderRadius: 12,
          borderColor: "#410DAA30",
          borderWidth: 1,
          marginRight: isMobile ? 63 : 0,
        },
        style,
      ]}
    >
      <View style={[{ flexDirection: "row", alignItems: "flex-start" }]}>
        <View style={{ flex: isMobile ? 1 : 1.5, alignItems: "center" }}>
          <Image
            source={icon}
            style={{ width: isMobile ? 47 : 32, height: isMobile ? 47 : 32 }}
            resizeMode={"center"}
          ></Image>
        </View>
        <View style={{ flex: isMobile ? 7 : 8 }}>
          <Text
            style={{
              fontWeight: "700",
              color: "#121212",
              fontSize: isMobile ? 16 : 14,
              marginBottom: 8,
            }}
          >
            {title}
          </Text>
          <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
            <Text
              style={{
                flex: 0.6,
                fontWeight: "normal",
                color: descriptionColor ? descriptionColor : "#6C7293",
                fontSize: 12,
                width: isMobile ? "100%" : "80%",
              }}
            >
              {description}
            </Text>
            <Text
              style={{
                flex: 0.4,
                fontSize: 13,
                color: "#410DAA",
                fontStyle: "italic",
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              {amount}
            </Text>
          </View>
        </View>
      </View>
      <TouchableOpacity
        onPress={() => {
          navigate && navigate();
        }}
        style={{
          alignSelf: "center",
          backgroundColor: "#410DAA",
          paddingVertical: 9,
          borderRadius: 6,
          width: "100%",
          marginTop: 30,
          alignItems: "center",
        }}
      >
        <Text style={{ fontWeight: "bold", fontSize: 15, color: "#fff" }}>
          Invest Now
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const chartConfig = {
  backgroundGradientFrom: "#1E2923",
  backgroundGradientFromOpacity: 0,
  backgroundGradientTo: "#08130D",
  backgroundGradientToOpacity: 0.5,
  color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
  strokeWidth: 2,
  barPercentage: 0.5,
  useShadowColorFromDataset: false,
};
export default class InvestOverviewMobile extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let userId = null;
    let showFamilyAlert = null;
    let showToast = null;
    if (params) {
      showToast = params.showToast || null;
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      showFamilyAlert = params.f;
    }
    this.state = {
      pending: [],
      rebalanceButtonClickable: false,
      isLoading: true,
      userId,
      gettingMinDetails: true,
      minDetailsResponse: {},
      showFamilyAlert,
      showToast,
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("InvestmentOverview",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("InvestmentOverview", { path: this.props.navigation.getState().routeName });
    }
    try {
      const { params } = this.props.route;
      let topup, product, data;
      if (params) {
        topup = params.topup || null;
        data = params.data || null;
        product = params.product || null;
      }

      if (topup && data && product) {
        await handleTopup({
          amount: data,
          product,
        });
        return;
      }
      const { userId } = this.state;
      const responses = await Promise.all([
        getTransactionPopUp(),
        getLandingPageDetails(userId),
      ]);

      let actionItemID,
        showPopUp = false,
        modalData = null;
      const transactionPopUpResponse = responses[0];
      const landingPageResponse = responses[1];

      if (transactionPopUpResponse && transactionPopUpResponse.code == 100) {
        if (transactionPopUpResponse.result.Show_pop_up) {
          let { cai_pk, modal_data } = transactionPopUpResponse.result;
          actionItemID = cai_pk;
          showPopUp = true;
          modalData = modal_data;
        }
      }

      if (landingPageResponse && landingPageResponse.code == 100) {
        const {
          piechart_data,
          portfolio_rebalance_data,
          upcoming_investments_data,
          stocks_summary,
          subscribed_products,
          equity_investment_plans,
        } = landingPageResponse.result;
        const {
          stock_inv_value_current,
          stock_latest,
          stock_gain,
          stock_abs_gain,
          committed_aum,
          stock_xirr,
          stock_dividend,
          customer_hey_name: first_name,
        } = stocks_summary.data;
        let showTeaserOf = null;
        if (subscribed_products.indexOf("SRK") == -1) {
          showTeaserOf = "SRK";
        } else {
          if (
            subscribed_products.indexOf("PRL") == -1 &&
            subscribed_products.indexOf("JWL") == -1
          ) {
            showTeaserOf = "JWL";
          }
        }
        this.setState(
          {
            showPopUp,
            modalData,
            piechart_data,
            portfolio_rebalance_data,
            upcoming_investments_data,
            current_investment: stock_inv_value_current,
            current_value: stock_latest,
            total_returns_value: stock_gain,
            total_returns_perc: stock_abs_gain,
            committed_aum,
            stock_xirr,
            stock_dividend,
            isLoading: false,
            first_name,
            subscribed_products,
            equity_investment_plans,
            showTeaserOf,
          },
          async () => {
            const customer_id = this.state.userId || null;
            let skipSubscription = false;
            if (this.state.userId) {
              const { skip_subscription } = await getCustomerDetails(
                this.state.userId
              );
              skipSubscription = skip_subscription;
            } else {
              const { skip_subscription } = getUserDetails();
              skipSubscription = skip_subscription;
            }
            const minDetailsResponse = await completeMinDetails(
              true,
              customer_id,
              false
            );
            this.setState({ minDetailsResponse, gettingMinDetails: false });
          }
        );
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Something went wrong!",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  handleTopupClick = (data = {}) => {
    if (!this.state.gettingMinDetails) {
      this.setState({
        showComplianceAlert: true,
        complianceNavigationOptions: { ...data },
      });
    }
  };

  handleDashboardChange = () => {
    this.props.navigation.dispatch(
      StackActions.replace("InvestmentOverview", {
        showToast: "true",
      })
    );
  };

  renderPendingItem = ({ item }) => {
    const ArrowBackIcon = require("../../../assets/JamaInvest/Button2x.png");

    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          padding: 20,
          backgroundColor: "#FFF8E085",
          borderRadius: 12,
          marginRight: 64,
        }}
      >
        <Image source={item.icon} style={{ width: 30, height: 40 }}></Image>
        <View style={{ marginLeft: 20, marginRight: 40 }}>
          <Text
            style={{
              fontWeight: "700",
              color: "#121212",
              fontSize: 16,
              marginBottom: 8,
            }}
          >
            {item.title}
          </Text>
          <Text
            style={{ fontWeight: "normal", color: "#6C7293", fontSize: 12 }}
          >
            {item.description}
          </Text>
        </View>
        <TouchableOpacity
          onPress={() => {
            this.props.navigation.push(item.route);
          }}
        >
          <Image
            source={ArrowBackIcon}
            style={{ width: 32, height: 32 }}
          ></Image>
        </TouchableOpacity>
      </View>
    );
  };

  closePopUp = async () => {
    this.setState({ showPopUp: false });
  };

  onRetry = (cai_hash) => {
    let token = getTokenFromStore();
    token = reverseString(token);
    global.open(
      MAIN_URL +
        "/app/gwsc/retryorder/" +
        (cai_hash + token) +
        "/" +
        GATEWAY_ENVIRONMENT,
      "_self"
    );
  };

  navigateToPortfolio = () => this.props.navigation.navigate("Holdings");

  render() {
    const {
      showPopUp,
      modalData,
      portfolio_rebalance_data,
      upcoming_investments_data,
      current_investment,
      committed_aum,
      stock_xirr,
      current_value,
      total_returns_value,
      total_returns_perc,
      error,
      errorText,
      isLoading,
      stock_dividend,
      subscribed_products,
      showComplianceAlert,
      minDetailsResponse,
      gettingMinDetails,
      equity_investment_plans,
      showTeaserOf,
      showFamilyAlert,
      complianceNavigationOptions = {},
    } = this.state;

    let { piechart_data } = this.state;

    piechart_data &&
      piechart_data.forEach((item) => {
        item["titleValue"] = `${item.value.toFixed(1)}%`;
      });

    if (error || isLoading) {
      return (
        <View style={{ flexDirection: "column", height: "100%" }}>
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
            {errorText && (
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText}
              </Text>
            )}
          </View>
        </View>
      );
    }
    let upcomingInvestmentsTitle = "Complete Upcoming Investments";
    if (upcoming_investments_data) {
      const { title } = upcoming_investments_data;
      upcomingInvestmentsTitle = title;
    }

    return (
      <View style={styles.mainContainer}>
        {showPopUp && modalData && (
          <PopUp
            modal_data={modalData}
            onPressDone={this.closePopUp}
            onRetry={this.onRetry}
          />
        )}

        {showComplianceAlert && (
          <ComplianceModal
            forceLoad={true}
            navigation={this.props.navigation}
            route={this.props.route}
            isLoading={gettingMinDetails}
            minDetailsResponse={minDetailsResponse}
            redirectBackTo={"InvestmentOverview"}
            onClose={() => {
              this.setState({ showComplianceAlert: false });
            }}
            userId={this.state.userId}
            navigationOptions={complianceNavigationOptions}
          />
        )}

        {showFamilyAlert && (
          <FamilyPopUp
            description="Family Popup"
            onDismiss={() => {
              this.setState({ showFamilyAlert: false });
            }}
          />
        )}

        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title="You switched to old dashboard."
            duration="10"
          />
        ) : null}

        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollViewContainerStyle}
        >
          <View
            style={{ backgroundColor: "#410DAA20", width: "100%", height: 1 }}
          />

          {showTeaserOf &&
            minDetailsResponse &&
            minDetailsResponse.needCompletion == false && (
              <SparkTeaser
                style={{ height: "auto", marginHorizontal: 0, marginTop: 0 }}
                onClose={() => {
                  this.setState({ showTeaserOf: false });
                }}
                product={showTeaserOf}
              />
            )}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
              paddingBottom: 20,
            }}
          >
            <View style={{ flex: 1, justifyContent: "flex-start" }}>
              <Text
                style={{
                  fontWeight: "700",
                  color: "#121212",
                  fontSize: 17,
                  marginLeft: 16,
                }}
              >
                Investments Overview
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-end",
                paddingTop: isMobile ? 0 : 10,
              }}
            >
              <Switch
                style={{ marginTop: 2 }}
                trackColor={{ false: "#BAC7F2", true: "#BAC7F2" }}
                thumbColor={"#410DAA"}
                activeThumbColor={"#410DAA"}
                onValueChange={this.handleDashboardChange}
                value={0}
              />
              <Text
                style={{
                  fontSize: 16,
                  color: "#121212",
                  fontWeight: "normal",
                  marginLeft: 16,
                  marginRight: 16,
                }}
              >
                {isMobile ? "New Dashboard" : "Switch to New Dashboard"}
              </Text>
            </View>
          </View>

          <ComplianceStrip
            navigation={this.props.navigation}
            route={this.props.route}
            isLoading={gettingMinDetails}
            minDetailsResponse={minDetailsResponse}
            userId={this.state.userId}
          />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 15,
              marginTop: 10,
            }}
          >
            {/* Overview */}
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Button
                uppercase={false}
                color="#410DAA"
                mode="text"
                style={styles.portfolioStocks}
                onPress={this.navigateToPortfolio}
              >
                Holdings
              </Button>
              <TouchableHighlight
                onPress={() => {
                  let options = {};
                  if (this.state.userId) {
                    options["user_id"] = this.state.userId;
                  }
                  this.props.navigation.navigate("ViewTransactionStatus", {
                    ...options,
                  });
                }}
              >
                <Image
                  source={require("../../../assets/transaction_status.png")}
                  style={{ width: 20, height: 20, marginRight: 16 }}
                />
              </TouchableHighlight>
            </View>
          </View>
          {/* Pie Chart && Dashboard */}
          {piechart_data && (
            <View
              style={{
                overflow: "hidden",
                backgroundColor: "#fff",
                borderRadius: 12,
                borderColor: "#410DAA30",
                borderWidth: 1,
                margin: 16,
                marginTop: isMobile ? 0 : 16,
                marginBottom: isMobile ? 25 : 30,
              }}
            >
              <PieChartDashboard
                style={{}}
                data={piechart_data}
                chartConfig={chartConfig}
                accessor={"value"}
              />
              <View
                style={{
                  backgroundColor: "#410DAA20",
                  width: "100%",
                  height: 1,
                }}
              />
              <View style={{ padding: 16, paddingTop: 8 }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: 25,
                  }}
                >
                  <DashboardField
                    title={"Planned"}
                    value={`₹${numberWithCommas(toFixed(committed_aum, 0))}`}
                    style={{
                      paddingHorizontal: 0,
                      marginRight: 0,
                      flex: 0.5,
                      alignItems: "flex-start",
                    }}
                    border={false}
                    containerStyle={{ paddingHorizontal: 5 }}
                  />
                  <DashboardField
                    title={"Invested"}
                    value={`₹${numberWithCommas(
                      toFixed(current_investment, 0)
                    )}`}
                    style={{
                      paddingHorizontal: 0,
                      flex: 0.5,
                      alignItems: "flex-start",
                    }}
                    border={false}
                    containerStyle={{ paddingHorizontal: 5 }}
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-end",
                    paddingLeft: 25,
                  }}
                >
                  <DashboardField
                    title={"Current Value"}
                    value={`₹${numberWithCommas(toFixed(current_value, 0))}`}
                    change={null}
                    style={{
                      paddingHorizontal: 0,
                      flex: 0.5,
                      alignItems: "flex-start",
                    }}
                    border={false}
                    containerStyle={{ paddingHorizontal: 5 }}
                  />
                  <DashboardField
                    title={"Total Returns"}
                    value={`₹${numberWithCommas(
                      toFixed(total_returns_value, 0)
                    )}`}
                    toolTip={
                      !!stock_dividend && (
                        <Popable
                          strictPosition={false}
                          position="top"
                          style={{ minWidth: 200 }}
                          content={
                            <View style={{ backgroundColor: "#12121270" }}>
                              <Text
                                style={{
                                  color: "#fff",
                                  fontSize: 13,
                                  fontFamily: "Roboto",
                                  padding: 6,
                                }}
                              >
                                Includes stock dividends of ₹
                                {numberWithCommas(toFixed(stock_dividend))}
                              </Text>
                            </View>
                          }
                        >
                          <AntDesign
                            name="infocirlce"
                            size={12}
                            color="#12121280"
                            style={{ paddingLeft: 5, paddingTop: 0 }}
                          />
                        </Popable>
                      )
                    }
                    change={total_returns_perc.toFixed(2)}
                    border={false}
                    style={{
                      paddingHorizontal: 0,
                      flex: 0.5,
                      alignItems: "flex-start",
                    }}
                    containerStyle={{ paddingHorizontal: 5 }}
                  />
                </View>
                {!!stock_xirr && (
                  <View style={{ flexDirection: "row", marginLeft: -2 }}>
                    <DashboardField
                      title={"Stock XIRR"}
                      value={`${stock_xirr.toFixed(1)}%`}
                      border={false}
                    />
                  </View>
                )}
              </View>
            </View>
          )}

          <View style={{ flexDirection: "column" }}>
            <View
              style={{
                paddingHorizontal: 16,
                paddingVertical: isMobile ? 0 : 16,
              }}
            >
              {upcoming_investments_data && (
                <CompleteUpcomingInvestments
                  title={upcomingInvestmentsTitle}
                  description={upcoming_investments_data.description}
                  amount={
                    upcoming_investments_data.amount != null
                      ? `₹${numberWithCommas(upcoming_investments_data.amount)}`
                      : null
                  }
                  icon={require("../../../assets/JamaInvest/upcoming2.png")}
                  iconStyle={{ width: 35, height: 35 }}
                  style={{
                    marginBottom: 25,
                    paddingHorizontal: 8,
                    paddingVertical: 12,
                  }}
                  navigate={() => {
                    let options = {};
                    if (this.state.userId) {
                      options["user_id"] = this.state.userId;
                    }
                    this.props.navigation.push("UpcomingInvestments", {
                      ...options,
                    });
                  }}
                />
              )}
              {portfolio_rebalance_data && (
                <NavigatableStrip
                  title={portfolio_rebalance_data.title}
                  clickable={portfolio_rebalance_data.navigatable}
                  description={portfolio_rebalance_data.description}
                  icon={require("../../../assets/JamaInvest/rebalance.png")}
                  descriptionColor={"#EF5D12"}
                  iconStyle={{ width: 35, height: 35 }}
                  style={{ marginBottom: 25, paddingHorizontal: 8 }}
                  navigate={() => {
                    let options = {};
                    if (this.state.userId) {
                      options["user_id"] = this.state.userId;
                    }
                    this.props.navigation.push("RebalanceLandingPage", {
                      ...options,
                    });
                  }}
                  arrowIcon={
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Arrow-right.png"
                  }
                />
              )}
            </View>
            <View
              style={{
                flex: 0.35,
                height: 250,
                minHeight: 250,
                marginHorizontal: isMobile ? 16 : 32,
              }}
            >
              <TopupStrip
                noCompliance={
                  !gettingMinDetails && minDetailsResponse.needCompletion
                }
                style={{ width: "100%", height: "100%" }}
                navigation={this.props.navigation}
                subscribed_products={subscribed_products}
                onClick={this.handleTopupClick}
                viewMode={this.state.userId}
              />
            </View>
          </View>

          {/* My Equity Investment Plans */}
          <Text
            style={{
              fontSize: 15,
              color: "#212121",
              marginBottom: 21,
              lineHeight: 20,
              fontWeight: "bold",
              marginLeft: 16,
              marginTop: 25,
            }}
          >
            My Equity Investment Plans
          </Text>
          <EquityPlans
            style={{ flex: 1 }}
            navigation={this.props.navigation}
            data={equity_investment_plans}
            activePlans={subscribed_products}
            userId={this.state.userId}
          />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#fff",
    flexGrow: 1,
  },
  scrollView: {
    flexGrow: 1,
  },
  scrollViewContainerStyle: {
    flexGrow: 1,
    justifyContent: "flex-start",
  },
  portfolioStocks: {
    marginRight: 10,
  },
});
