import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  FlatList,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  Alert
} from "react-native";
import { postRemainder } from "../../api";
import { CapitalizeWordsInSentence, isMobile } from "../../utils";
const { height } = Dimensions.get("window");
import Toast from "react-native-toast-message";
import { toastConfig } from "../../components/Common/Toast";

const MyReferrals = ({ referrals = [], isLoadingReferrals }) => {
  const renderFriendsDetails = ({ item }) => {
    let { name, created } = item;
    const initials = name ? name.split(" ") : "User";
    const firstLetters = initials[0][0].toUpperCase() ? " " : "user";
    return (
      <View style={style.referralContainer}>
        <View style={[style.referralDetailsContainer, { flex: 1.5 }]}>
          <View style={style.inin_bag}>
            <View style={style.inin_ring}>
              <Text style={style.initial}>{firstLetters}</Text>
            </View>
          </View>
          <View style={{ paddingLeft: 10 }}>
            <Text style={style.jane}>
              {CapitalizeWordsInSentence(item.name)}
            </Text>
            <Text style={style.email}>{maskedEmail(item.email)}</Text>
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={[style.status, { color: returnColor("Pending") }]}>
            {created}
            {!created && (
              <Ionicons
                name="checkmark-circle-sharp"
                size={15}
                color="#39B54A"
                style={styles.checkmark}
              />
            )}
          </Text>
          {created != true && (
            <Text style={style.remind} onPress={() => handleRemind(item)}>
              Remind{" "}
            </Text>
          )}
        </View>
      </View>
    );
  };

  const returnColor = (status) => {
    return status.toUpperCase() == "Pending".toUpperCase()
      ? "#464E5F"
      : "#464E5F";
  };

  const maskedEmail = (email) => {
    if (email) {
      const email_data = email.split("@");
      const email_name = email_data[0];
      const email_company = email_data[1];
      return "xxxx" + email_name.substring(0, 4) + "@" + email_company;
    }
    return null;
  };

  const handleRemind = async (item) => {
    try {
      let payload = {};
      if (item.mobilenumber) {
        payload["mobilenumbers"] = item.mobilenumber || null;
      }

      if (item.email) {
        payload["emails"] = item.email || null;
      }

      if (!payload["mobilenumbers"] && !!payload["emails"]) {
        return;
      }

      const response = await postRemainder(payload);

      let message = "Error sending reminder";
      if (response && response.code == 100) {
        message = "Reminder sent successfully !";
      }
      Toast.show({
        type: "success",
        text1: message,
        config: toastConfig,
        topOffset: isMobile ? 100 : 60,
      });
      // alert(message)
    } catch (error) {
      Toast.show({
        type: "error",
        text1: "Error sending reminder",
        config: toastConfig,
        topOffset: isMobile ? 100 : 60,
      });
      // alert("Error sending reminder")
    }
  };

  const emptyComponent = () => {
    return (
      <View style={style.noReferralContainer}>
        <Image
          source="https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/invite+a+friend.png"
          style={style.image2}
        />
        <Text style={style.show}>No referrals to show</Text>
      </View>
    );
  };

  return (
    <ScrollView>
      <View style={style.rightin}>
        <Text style={[style.myreferrals, { paddingTop: 6 }]}>My Referrals</Text>
      </View>
      {isLoadingReferrals ? (
        <ActivityIndicator
          size="large"
          color="#410DAA"
          style={{ paddingTop: 40 }}
        />
      ) : (
        <FlatList
          data={referrals}
          ListEmptyComponent={emptyComponent}
          renderItem={renderFriendsDetails}
          keyExtractor={(item) => item.name}
          style={{ height: isMobile ? "auto" : height * 0.55 }}
        />
      )}
    </ScrollView>
  );
};

const style = StyleSheet.create({
  myreferrals: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 18,
    color: "#202020",
    letterSpacing: 0.16,
  },
  image2: {
    width: 80,
    height: 80,
    marginTop: 60,
  },
  noReferralContainer: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  show: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#121212",
    opacity: 0.4,
    paddingTop: 11,
  },
  referralContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 20,
    paddingTop: 7,
    paddingRight: 10,
  },
  referralDetailsContainer: {
    flexDirection: "row",
  },
  inin_bag: {
    width: 36,
    height: 36,
    borderWidth: 2,
    borderColor: "#410DAA",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
  },
  inin_ring: {
    width: 30,
    height: 30,
    backgroundColor: "#E5E9FF",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  setRem: {
    fontFamily: "Roboto",
    fontSize: 10,
    lineHeight: 11,
    color: "#410DAA",
    paddingTop: 5,
  },
  checkmark: {
    marginLeft: 8,
  },
  remind: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#410DAA",
    marginTop: 6,
  },
  rightin: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
  },
  rem_all: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 13,
    lineHeight: 15,
    color: "#410DAA",
    letterSpacing: 0.4,
  },
  jane: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    fontWeight: "bold",
    color: "#464E5F",
    paddingBottom: 5,
  },
  email: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#464E5F",
    width: "60%",
  },
  status: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#0BB783",
    fontWeight: "bold",
  },
});

const MemoizedMyReferrals = React.memo(MyReferrals);
export default MemoizedMyReferrals;
