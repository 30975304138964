import React, { Component } from "react";
import {
  View,
  FlatList,
  StatusBar,
  Dimensions,
  Platform,
  ScrollView,
  PixelRatio,
  SafeAreaView,
} from "react-native";
import { getPendingAttemptedOrders } from "../../../api/alerts";
import PendingInvestmentsCard from "./PendingInvestmentsCard";
import NavigationHeader from "../../../components/Common/NavigationHeader";
const { width: SCREEN_WIDTH } = Dimensions.get("window");
const scale = SCREEN_WIDTH / 320;
import { isMobile } from "../../../utils";
import amplitude from "../../../utils/amplitude";

export default class PendingInvestments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investments: [],
      showWebViewBool: false,
      showHTMLContent: "",
      loading: false,
    };
  }

  componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("PendingInvestments",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("PendingInvestments", { path: this.props.navigation.getState().routeName });
    }
    this._pendingInvestments();
  }

  _pendingInvestments = () => {
    try {
      this.setState({ loading: true });
      getPendingAttemptedOrders()
        .then((response) => {
          let investments = response.results;
          investments = investments.filter((investment) => {
            return investment.amount != 0;
          });

          this.setState({
            investments,
            loading: false,
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  _renderView = (items) => {
    return (
      <PendingInvestmentsCard
        data={items.item}
        propw={this.props}
        navigation={this.props.navigation}
        fetchInvestments={() => this._pendingInvestments()}
      />
    );
  };

  normalize = (size) => {
    const newSize = size * scale;
    let fontSize;
    if (Platform.OS === "ios") {
      fontSize = Math.round(PixelRatio.roundToNearestPixel(newSize));
      return fontSize <= 17 ? fontSize : 17;
    } else {
      fontSize = Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
      return fontSize <= 17 ? fontSize : 17;
    }
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Attempted Investments"]}
          onPressBack={() => {
            this.props.navigation.goBack(null);
          }}
        />
        <StatusBar
          backgroundColor={"#410DAA"}
          barStyle={Platform.OS == "android" ? "light-content" : "dark-content"}
        />

        <ScrollView>
          <View
            style={{
              marginBottom: isMobile ? 20 : 0,
              paddingLeft: isMobile ? "5%" : "25%",
              paddingRight: isMobile ? "5%" : "25%",
              paddingTop: 20,
            }}
          >
            {this.state.loading ? (
              <View style={{ padding: 16 }}></View>
            ) : (
              <FlatList
                data={this.state.investments.reverse()}
                renderItem={this._renderView}
                keyExtractor={(item, index) => index.toString()}
                contentContainerStyle={{
                  paddingBottom: 50,
                }}
                initialNumToRender={10}
              />
            )}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
