import React from 'react';
import {View, Text, StyleSheet, ScrollView, FlatList} from 'react-native';
import SearchBar from '../../components/Common/SearchBar';
import Table from '../../components/Common/Table';
import NavigationHeader from '../../components/Common/NavigationHeader';
import { numberWithCommas } from '../../utils';

class PaymentDetails extends React.Component {
    constructor(props){
        super(props);
        this.state={}
    }

    navigateBack = () => {
        const { navigation } = this.props;
        if(navigation){
            navigation.goBack();
        }
    }

    handleSearch = (searchText) => {
        this.setState({searchText})
    }

    compare = ( a, b, field, sort ) => {
        if ( a[field] < b[field] ){
          return sort ? -1 : 1;
        }
        if ( a[field] > b[field] ){
          return sort ? 1 : -1;
        }
        return 0;
      }

    handleSort = (field, sort, start, end) => {
        this.setState({
            sorting_field : field,
            sorting_value: !sort
        }, () => {
            const { data } = this.state;
            const sortedData = [].concat(
                data.slice(0,start),
                data.slice(start,end).sort((a,b)=> this.compare(a,b,field,!sort)),
                data.slice(end,data.length)
            )
            this.setState({data: sortedData});
        })
    }

    getTableData = (data=[]) => {
        const headerData = [
            {
                label: "Payment Date",
                style: [styles.tableHeader],
                sortOption: false,
            },
            {
                label: "Transaction ID",
                style: [styles.tableHeader],
                sortOption: false,
            },
            {
                label: "Payment Type",
                style: [styles.tableHeader],
                sortOption: false,
            },
            {
                label: "Amount Paid",
                style: [styles.tableHeader],
                sortOption: false,
            },
            {
                label: "Amount Paid(including GST)",
                style: [styles.tableHeader],
                sortOption: false,
            }
        ];

        let { values = [] } = this.generateDataForTable(data);
        return {headerData, values};
    }

    generateDataForTable = (data) => {
        let values = [];
        data.forEach(item=>{
            let { paid_amount, paid_date, payment_id, payment_type, bill_amount_including_gst } = item;
            const paid_amount_formatted = "₹"+ numberWithCommas(parseInt(paid_amount));
            const paid_amount_gst_formatted = "₹"+ numberWithCommas(parseInt(bill_amount_including_gst));
            values.push([
                {
                    valueToDisplay: paid_date,
                    value: paid_date,
                    style: [styles.rowItem, ],
                    rowItemContainerStyle: [styles.paidDate]
                },
                {
                    valueToDisplay: payment_id,
                    value: payment_id,
                    style: [styles.rowItem]
                },
                {
                    valueToDisplay: payment_type,
                    value: payment_type,
                    style: [styles.rowItem]
                },
                {
                    valueToDisplay: paid_amount_formatted,
                    value: paid_amount,
                    style: [styles.rowItem]
                },
                {
                    valueToDisplay: paid_amount_gst_formatted,
                    value: bill_amount_including_gst,
                    style: [styles.rowItem]
                },
            ])
        })
        return { values };
    }
    
    render(){
        const { data = [] } = this.props;

        const {headerData, values } = this.getTableData(data);
        return(
            <View style={styles.container}>
                <ScrollView contentContainerStyle={styles.container} horizontal={true}>
                    <View style={styles.detailsContainer}>
                        <View style={styles.headingContainer}>
                            <Text style={styles.header}>Payment Details</Text>
                        </View>
                        <Table
                            header={headerData}
                            gridConfig={[
                                0.2, 0.2, 0.2, 0.2, 0.2
                            ]}
                            data={values}
                            tableHeaderStyle={[styles.tableHeaderStyle]}
                            pagination={false}
                            minWidth={1200}
                        />
                    </View>
                </ScrollView>   
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container:{
        backgroundColor: "#fff",
        flex: 1,
        minWidth: 800
    },
    paidDate: {
        justifyContent: "flex-start",
        paddingLeft: 32
    },
    detailsContainer: {
        flex: 1,
        borderWidth: 1,
        borderColor: "#DCC2FF",
        borderRadius: 22,
        marginHorizontal: 16,
        marginVertical: 30
    },
    headingContainer: {
        paddingHorizontal: 40,
        paddingVertical: 19,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    header:{
        fontFamily: "Roboto",
        color: "#410DAA",
        fontSize: 18,
        fontWeight: "700"
    },
    tableHeader: {
        color: "#464E5F",
        fontSize: 12,
        fontFamily: "Roboto",
        fontWeight: "700",
    },
    tableHeaderStyle: {
        paddingHorizontal: 32
    },
    rowItem: {
        textAlign: "left",
    }
});

export default PaymentDetails;