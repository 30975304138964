import React, { Component } from "react";
import { Text, StyleSheet } from "react-native";
import theme from "../../../static/theme";

const HeadingText = props => {
  return (
    <Text style={[styles.textStyle, props.style]}>
      {props.title || props.children}
    </Text>
  );
};

const styles = StyleSheet.create({
  textStyle: {
    fontSize: theme.fontsizes.HEADING,
    color: theme.textColors.HEADING_COLOR,
    fontWeight: "bold"
  }
});

export default HeadingText;
