import React from "react";
import {
  View,
  Text,
  Image,
  Platform,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { isMobile } from "../../../utils";
import { EquityPlans } from "../../../components/InvestmentOverview/NewDesign/components/EquityPlans";
import { getInvestmentPlans } from "../../../api";
import { Button } from "react-native-paper";
import moment from "moment";
import amplitude from "../../../utils/amplitude";
import HandSignature from "./HandSignature";
import AdvisoryPlan from "./AdvisoryPlan";
import Store from "../../../store";

export default class Membership extends React.Component {
  constructor(props) {
    super(props);
    let is_preferred_circle = false;
    let is_club = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    hostname = "myadvisor.jamawealth.com";
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    if (hostname.includes("club")) {
      is_club = true;
    }
    this.state = {
      isLoading: true,
      cipResp: [],
      showCIPs: true,
      is_preferred_circle,
      is_club,
      showEliteLearnMore: false,
      showEliteReadMore: false,
      showEliteBetter: false,
      showAdvisoryLearnMore: false,
      showAdvisoryReadMore: false,
      showAdvisoryBetter: false,
      showHandSignature: false,
      showAdvisoryPlan: false,
      amount: 0,
      plancode: "",
    };
  }

  componentDidMount = async () => {
    if (Platform.OS == "web") {
      amplitude.logEvent("Membership", { path: global.location.href });
    } else {
      amplitude.logEvent("Membership", {
        path: this.props.navigation.getState().routeName,
      });
    }
    let customerInvestmentPlans = null;
    if (
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      customerInvestmentPlans = await getInvestmentPlans(
        Store.getState().dashboard.viewModeUserId.user_id
      );
    } else {
      customerInvestmentPlans = await getInvestmentPlans();
    }
    if (customerInvestmentPlans && customerInvestmentPlans.code) {
      this.setState({
        cipResp: customerInvestmentPlans.results,
        isLoading: false,
      });
    }
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {!this.state.showHandSignature && !this.state.showAdvisoryPlan ? (
          <>
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={[
                this.state.showAdvisoryLearnMore ||
                this.state.showAdvisoryReadMore ||
                this.state.showAdvisoryBetter
                  ? "Advisory Circle"
                  : this.state.showEliteLearnMore ||
                    this.state.showEliteReadMore ||
                    this.state.showEliteBetter
                  ? "Elite Circle"
                  : "Membership",
              ]}
              onPressBack={() => {
                if (this.state.showAdvisoryLearnMore) {
                  this.setState({ showAdvisoryLearnMore: false });
                } else {
                  if (this.state.showAdvisoryReadMore) {
                    this.setState({
                      showAdvisoryLearnMore: true,
                      showAdvisoryReadMore: false,
                    });
                  } else {
                    if (this.state.showAdvisoryBetter) {
                      this.setState({
                        showAdvisoryBetter: false,
                      });
                    } else {
                      if (this.state.showEliteLearnMore) {
                        this.setState({ showEliteLearnMore: false });
                      } else {
                        if (this.state.showEliteReadMore) {
                          this.setState({
                            showEliteLearnMore: true,
                            showEliteReadMore: false,
                          });
                        } else {
                          if (this.state.showEliteBetter) {
                            this.setState({
                              showEliteBetter: false,
                            });
                          } else {
                            this.props.navigation.goBack();
                          }
                        }
                      }
                    }
                  }
                }
              }}
            />
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoading ? (
                <ActivityIndicator size="large" color="#410DAA" />
              ) : (
                <>
                  <ScrollView showsVerticalScrollIndicator={false}>
                    {!this.state.showAdvisoryLearnMore &&
                      !this.state.showAdvisoryReadMore &&
                      !this.state.showAdvisoryBetter &&
                      !this.state.showEliteLearnMore &&
                      !this.state.showEliteReadMore &&
                      !this.state.showEliteBetter && (
                        <View
                          style={{
                            marginTop: 20,
                            width: "100%",
                          }}
                        >
                          {!this.state.is_club && (
                            <View>
                              {this.state.cipResp.length > 0 && (
                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontWeight: "700",
                                      fontSize: 18,
                                      marginBottom: 10,
                                    }}
                                  >
                                    Active Investment Plans
                                  </Text>
                                  <Text
                                    style={{
                                      fontWeight: "700",
                                      fontSize: 15,
                                      marginBottom: 10,
                                      color: "#410DAA",
                                      textDecorationLine: "underline",
                                      paddingLeft: 120,
                                      paddingBottom: 5,
                                    }}
                                    onPress={() => {
                                      this.setState({
                                        showCIPs: !this.state.showCIPs,
                                      });
                                    }}
                                  >
                                    {this.state.showCIPs
                                      ? "Hide Plans"
                                      : "Show Plans"}
                                  </Text>
                                </View>
                              )}
                              {this.state.showCIPs &&
                                this.state.cipResp.map((item) => {
                                  return (
                                    <View
                                      style={{
                                        borderWidth: 1,
                                        borderColor: "#cdcdcb",
                                        borderRadius: 6,
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: 30,
                                        paddingBottom: 15,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontWeight: "700",
                                          fontSize: 15,
                                          marginLeft: 10,
                                          marginBottom: 20,
                                        }}
                                      >
                                        {item.plan_name}
                                      </Text>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          marginLeft: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <View
                                          style={{
                                            flex: 1,
                                            flexDirection: "column",
                                          }}
                                        >
                                          <Text style={{ marginBottom: 10 }}>
                                            CIP Plan ID: {item.cipplan_id}
                                          </Text>
                                          <Text style={{}}>
                                            Agreement Status:{" "}
                                            {item.agreement_status}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flex: 1,
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                          }}
                                        >
                                          <Text style={{ marginBottom: 10 }}>
                                            Start Date:{" "}
                                            {moment(item.start_date).format(
                                              "DD MMM YYYY"
                                            )}
                                          </Text>
                                          <Text style={{}}>
                                            End Date:{" "}
                                            {moment(item.end_date).format(
                                              "DD MMM YYYY"
                                            )}
                                          </Text>
                                        </View>
                                      </View>
                                      <View
                                        style={{
                                          alignItems: "flex-start",
                                          marginTop: 10,
                                          marginLeft: 10,
                                        }}
                                      >
                                        {item.agreement && (
                                          <TouchableOpacity
                                            onPress={() => {
                                              window.open(
                                                item.agreement,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            <Text style={{ color: "blue" }}>
                                              Download Agreement
                                            </Text>
                                          </TouchableOpacity>
                                        )}
                                      </View>
                                    </View>
                                  );
                                })}
                            </View>
                          )}
                          {this.state.is_club ||
                            (this.state.is_preferred_circle && (
                              <Text
                                style={{
                                  fontWeight: "700",
                                  fontSize: 18,
                                  marginBottom: 10,
                                }}
                              >
                                Upgrade Your Plan
                              </Text>
                            ))}
                          {this.state.is_club && (
                            <View style={styles.body}>
                              <View
                                style={{
                                  flex: 1,
                                }}
                              >
                                <View
                                  style={{
                                    width: isMobile ? "90%" : "100%",
                                    marginLeft: isMobile ? "5%" : "0%",
                                    marginRight: isMobile ? "5%" : "0%",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    marginBottom: 60,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "46%",
                                      borderRadius: 10,
                                      borderColor: "#cdcdcb",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 24,
                                        color: "#51514E",
                                        fontWeight: "700",
                                        marginLeft: "10%",
                                        marginTop: "12%",
                                      }}
                                    >
                                      Advisory Circle
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color: "#51514E",
                                        fontWeight: "600",
                                        marginLeft: "10%",
                                        marginTop: "2%",
                                      }}
                                    >
                                      Advisory Covering Stocks, Funds & Goals
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color: "#51514E",
                                        fontWeight: "600",
                                        marginLeft: "10%",
                                        marginTop: "0.8%",
                                      }}
                                    >
                                      {" "}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 20,
                                        color: "#027BFC",
                                        fontWeight: "700",
                                        marginLeft: "10%",
                                        marginTop: "7%",
                                        marginBottom: "5%",
                                      }}
                                    >
                                      24 ADVICES/YEAR
                                    </Text>
                                    <View
                                      style={{
                                        width: "82%",
                                        marginLeft: "9%",
                                        marginRight: "9%",
                                        borderRadius: 10,
                                        borderColor: "white",
                                        borderWidth: 1,
                                        backgroundColor: "#f9f5ff",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 18,
                                          color: "#464E5F",
                                          fontWeight: "700",
                                          marginLeft: "10%",
                                          marginTop: "7%",
                                        }}
                                      >
                                        Advices Included
                                      </Text>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "7%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Stock of the month (12)
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Mutual Fund Pack (4)
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Financial Plan Review (2)
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Portfolio Review (2)
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                          marginBottom: 17,
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Adhoc Advices (4)
                                        </Text>
                                      </View>
                                      <View style={{ flexDirection: "row" }}>
                                        <Text
                                          onPress={() => {
                                            this.setState({
                                              showAdvisoryLearnMore: true,
                                            });
                                          }}
                                          style={{
                                            fontSize: 14,
                                            color: "#410daa",
                                            cursor: "pointer",
                                            fontWeight: "700",
                                            marginLeft: "10%",
                                            marginBottom: "5%",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          Learn More{" "}
                                        </Text>
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                                          }}
                                          style={[
                                            {
                                              width: 10,
                                              height: 10,
                                              marginTop: 4,
                                            },
                                          ]}
                                        ></Image>
                                      </View>
                                    </View>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        marginTop: "6%",
                                      }}
                                    >
                                      <Text
                                        onPress={() => {
                                          this.setState({
                                            showAdvisoryBetter: true,
                                          });
                                        }}
                                        style={{
                                          fontSize: 12,
                                          color: "#410daa",
                                          fontWeight: "700",
                                          marginLeft: "10%",
                                          marginBottom: "2%",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Why Advisory Circle may be better for
                                        you{" "}
                                      </Text>
                                      <Image
                                        source={{
                                          uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                                        }}
                                        style={[
                                          {
                                            width: 10,
                                            height: 10,
                                            marginTop: 2,
                                          },
                                        ]}
                                      ></Image>
                                    </View>
                                    <Text
                                      onPress={() => {
                                        this.setState({
                                          showAdvisoryPlan: true,
                                        });
                                      }}
                                      style={{
                                        fontFamily: "Roboto",
                                        fontWeight: "bold",
                                        fontSize: 15,
                                        color: "white",
                                        backgroundColor: "#410DAA",
                                        borderColor: "#410DAA",
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        paddingVertical: 10,
                                        textAlign: "center",
                                        width: "82%",
                                        marginLeft: "9%",
                                        marginRight: "9%",
                                        marginTop: "5%",
                                        marginBottom: "10%",
                                      }}
                                    >
                                      Subscribe
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "6%",
                                    }}
                                  ></View>
                                  <View
                                    style={{
                                      width: "46%",
                                      marginLeft: "0.5%",
                                      borderRadius: 10,
                                      borderColor: "#cdcdcb",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 24,
                                        color: "#51514E",
                                        fontWeight: "700",
                                        marginLeft: "10%",
                                        marginTop: "12%",
                                      }}
                                    >
                                      Elite Circle
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color: "#51514E",
                                        fontWeight: "600",
                                        marginLeft: "10%",
                                        marginTop: "2%",
                                      }}
                                    >
                                      Managed Portfolio Advisory in Stocks,
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color: "#51514E",
                                        fontWeight: "600",
                                        marginLeft: "10%",
                                        marginTop: "2%",
                                      }}
                                    >
                                      Funds To Build Wealth
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 20,
                                        color: "#027BFC",
                                        fontWeight: "700",
                                        marginLeft: "10%",
                                        marginTop: "7%",
                                        marginBottom: "5%",
                                      }}
                                    >
                                      UNLIMITED ADVICES
                                    </Text>
                                    <View
                                      style={{
                                        width: "82%",
                                        marginLeft: "9%",
                                        marginRight: "9%",
                                        borderRadius: 10,
                                        borderColor: "white",
                                        borderWidth: 1,
                                        backgroundColor: "#f9f5ff",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 18,
                                          color: "#464E5F",
                                          fontWeight: "700",
                                          marginLeft: "10%",
                                          marginTop: "7%",
                                        }}
                                      >
                                        Advices Included
                                      </Text>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "7%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Stock of the month
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Mutual Fund Pack
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Financial Plan Review
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Portfolio Review
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                          marginBottom: 17,
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Adhoc Advices
                                        </Text>
                                      </View>
                                      <View style={{ flexDirection: "row" }}>
                                        <Text
                                          onPress={() => {
                                            this.setState({
                                              showEliteLearnMore: true,
                                            });
                                          }}
                                          style={{
                                            fontSize: 14,
                                            color: "#410daa",
                                            cursor: "pointer",
                                            fontWeight: "700",
                                            marginLeft: "10%",
                                            marginBottom: "5%",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          Learn More{" "}
                                        </Text>
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                                          }}
                                          style={[
                                            {
                                              width: 10,
                                              height: 10,
                                              marginTop: 4,
                                            },
                                          ]}
                                        ></Image>
                                      </View>
                                    </View>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        marginTop: "6%",
                                      }}
                                    >
                                      <Text
                                        onPress={() => {
                                          this.setState({
                                            showEliteBetter: true,
                                          });
                                        }}
                                        style={{
                                          fontSize: 12,
                                          color: "#410daa",
                                          fontWeight: "700",
                                          marginLeft: "10%",
                                          marginBottom: "2%",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Why Elite Circle may be best for you{" "}
                                      </Text>
                                      <Image
                                        source={{
                                          uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                                        }}
                                        style={[
                                          {
                                            width: 10,
                                            height: 10,
                                            marginTop: 2,
                                          },
                                        ]}
                                      ></Image>
                                    </View>
                                    <Text
                                      onPress={() => {
                                        global.open(
                                          "https://maxiomwealth.com/contact-us/",
                                          "_blank"
                                        );
                                      }}
                                      style={{
                                        fontFamily: "Roboto",
                                        fontWeight: "bold",
                                        fontSize: 15,
                                        color: "#410DAA",
                                        backgroundColor: "white",
                                        borderColor: "#410DAA",
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        paddingVertical: 10,
                                        textAlign: "center",
                                        width: "82%",
                                        marginLeft: "9%",
                                        marginRight: "9%",
                                        marginTop: "5%",
                                        marginBottom: "10%",
                                      }}
                                    >
                                      Contact Advisor
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          )}
                          {this.state.is_club && (
                            <EquityPlans
                              style={{
                                flex: 1,
                                marginTop: -20,
                                width: "99%",
                              }}
                              navigation={this.props.navigation}
                              data={{}}
                              activePlans={[]}
                              isNew={true}
                            />
                          )}
                          {this.state.is_preferred_circle && (
                            <View style={styles.body}>
                              <View
                                style={{
                                  flex: 2,
                                }}
                              >
                                <View
                                  style={{
                                    width: isMobile ? "90%" : "100%",
                                    marginLeft: isMobile ? "5%" : "0%",
                                    marginRight: isMobile ? "5%" : "0%",
                                    // flexDirection: "row",
                                    justifyContent: "flex-start",
                                    marginBottom: 60,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "46%",
                                      borderRadius: 10,
                                      borderColor: "#cdcdcb",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 24,
                                        color: "#51514E",
                                        fontWeight: "700",
                                        marginLeft: "10%",
                                        marginTop: "12%",
                                      }}
                                    >
                                      Advisory Circle
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color: "#51514E",
                                        fontWeight: "600",
                                        marginLeft: "10%",
                                        marginTop: "2%",
                                      }}
                                    >
                                      Advisory Covering Stocks, Funds & Goals
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color: "#51514E",
                                        fontWeight: "600",
                                        marginLeft: "10%",
                                        marginTop: "0.8%",
                                      }}
                                    >
                                      {" "}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 20,
                                        color: "#027BFC",
                                        fontWeight: "700",
                                        marginLeft: "10%",
                                        marginTop: "7%",
                                        marginBottom: "5%",
                                      }}
                                    >
                                      24 ADVICES/YEAR
                                    </Text>
                                    <View
                                      style={{
                                        width: "82%",
                                        marginLeft: "9%",
                                        marginRight: "9%",
                                        borderRadius: 10,
                                        borderColor: "white",
                                        borderWidth: 1,
                                        backgroundColor: "#f9f5ff",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 18,
                                          color: "#464E5F",
                                          fontWeight: "700",
                                          marginLeft: "10%",
                                          marginTop: "7%",
                                        }}
                                      >
                                        Advices Included
                                      </Text>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "7%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Stock of the month (12)
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Mutual Fund Pack (4)
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Financial Plan Review (2)
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Portfolio Review (2)
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                          marginBottom: 17,
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Adhoc Advices (4)
                                        </Text>
                                      </View>
                                      <View style={{ flexDirection: "row" }}>
                                        <Text
                                          onPress={() => {
                                            this.setState({
                                              showAdvisoryLearnMore: true,
                                            });
                                          }}
                                          style={{
                                            fontSize: 14,
                                            color: "#410daa",
                                            cursor: "pointer",
                                            fontWeight: "700",
                                            marginLeft: "10%",
                                            marginBottom: "5%",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          Learn More{" "}
                                        </Text>
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                                          }}
                                          style={[
                                            {
                                              width: 10,
                                              height: 10,
                                              marginTop: 4,
                                            },
                                          ]}
                                        ></Image>
                                      </View>
                                    </View>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        marginTop: "6%",
                                      }}
                                    >
                                      <Text
                                        onPress={() => {
                                          this.setState({
                                            showAdvisoryBetter: true,
                                          });
                                        }}
                                        style={{
                                          fontSize: 12,
                                          color: "#410daa",
                                          fontWeight: "700",
                                          marginLeft: "10%",
                                          marginBottom: "2%",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Why Advisory Circle may be better for
                                        you{" "}
                                      </Text>
                                      <Image
                                        source={{
                                          uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                                        }}
                                        style={[
                                          {
                                            width: 10,
                                            height: 10,
                                            marginTop: 2,
                                          },
                                        ]}
                                      ></Image>
                                    </View>
                                    <Text
                                      onPress={() => {
                                        this.setState({
                                          showAdvisoryPlan: true,
                                        });
                                      }}
                                      style={{
                                        fontFamily: "Roboto",
                                        fontWeight: "bold",
                                        fontSize: 15,
                                        color: "white",
                                        backgroundColor: "#410DAA",
                                        borderColor: "#410DAA",
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        paddingVertical: 10,
                                        textAlign: "center",
                                        width: "82%",
                                        marginLeft: "9%",
                                        marginRight: "9%",
                                        marginTop: "5%",
                                        marginBottom: "10%",
                                      }}
                                    >
                                      Subscribe
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "6%",
                                    }}
                                  ></View>
                                  <View
                                    style={{
                                      width: "46%",
                                      marginLeft: "0.5%",
                                      borderRadius: 10,
                                      borderColor: "#cdcdcb",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 24,
                                        color: "#51514E",
                                        fontWeight: "700",
                                        marginLeft: "10%",
                                        marginTop: "12%",
                                      }}
                                    >
                                      Elite Circle
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color: "#51514E",
                                        fontWeight: "600",
                                        marginLeft: "10%",
                                        marginTop: "2%",
                                      }}
                                    >
                                      Managed Portfolio Advisory in Stocks,
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        color: "#51514E",
                                        fontWeight: "600",
                                        marginLeft: "10%",
                                        marginTop: "2%",
                                      }}
                                    >
                                      Funds To Build Wealth
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 20,
                                        color: "#027BFC",
                                        fontWeight: "700",
                                        marginLeft: "10%",
                                        marginTop: "7%",
                                        marginBottom: "5%",
                                      }}
                                    >
                                      UNLIMITED ADVICES
                                    </Text>
                                    <View
                                      style={{
                                        width: "82%",
                                        marginLeft: "9%",
                                        marginRight: "9%",
                                        borderRadius: 10,
                                        borderColor: "white",
                                        borderWidth: 1,
                                        backgroundColor: "#f9f5ff",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 18,
                                          color: "#464E5F",
                                          fontWeight: "700",
                                          marginLeft: "10%",
                                          marginTop: "7%",
                                        }}
                                      >
                                        Advices Included
                                      </Text>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "7%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Stock of the month
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Mutual Fund Pack
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Financial Plan Review
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Portfolio Review
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "10%",
                                          marginTop: "3%",
                                          marginBottom: 17,
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                                          }}
                                          style={{
                                            width: 11,
                                            height: 11,
                                            resizeMode: "contain",
                                            marginRight: 10,
                                            marginTop: 5,
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#464E5F",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Adhoc Advices
                                        </Text>
                                      </View>
                                      <View style={{ flexDirection: "row" }}>
                                        <Text
                                          onPress={() => {
                                            this.setState({
                                              showEliteLearnMore: true,
                                            });
                                          }}
                                          style={{
                                            fontSize: 14,
                                            color: "#410daa",
                                            cursor: "pointer",
                                            fontWeight: "700",
                                            marginLeft: "10%",
                                            marginBottom: "5%",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          Learn More{" "}
                                        </Text>
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                                          }}
                                          style={[
                                            {
                                              width: 10,
                                              height: 10,
                                              marginTop: 4,
                                            },
                                          ]}
                                        ></Image>
                                      </View>
                                    </View>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        marginTop: "6%",
                                      }}
                                    >
                                      <Text
                                        onPress={() => {
                                          this.setState({
                                            showEliteBetter: true,
                                          });
                                        }}
                                        style={{
                                          fontSize: 12,
                                          color: "#410daa",
                                          fontWeight: "700",
                                          marginLeft: "10%",
                                          marginBottom: "2%",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Why Elite Circle may be best for you{" "}
                                      </Text>
                                      <Image
                                        source={{
                                          uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                                        }}
                                        style={[
                                          {
                                            width: 10,
                                            height: 10,
                                            marginTop: 2,
                                          },
                                        ]}
                                      ></Image>
                                    </View>
                                    <Text
                                      onPress={() => {
                                        global.open(
                                          "https://maxiomwealth.com/contact-us/",
                                          "_blank"
                                        );
                                      }}
                                      style={{
                                        fontFamily: "Roboto",
                                        fontWeight: "bold",
                                        fontSize: 15,
                                        color: "#410DAA",
                                        backgroundColor: "white",
                                        borderColor: "#410DAA",
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        paddingVertical: 10,
                                        textAlign: "center",
                                        width: "82%",
                                        marginLeft: "9%",
                                        marginRight: "9%",
                                        marginTop: "5%",
                                        marginBottom: "10%",
                                      }}
                                    >
                                      Contact Advisor
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          )}
                        </View>
                      )}
                    {this.state.showEliteLearnMore && (
                      <>
                        <View
                          style={{
                            width: "94%",
                            marginLeft: "3%",
                            display: "flex",
                            marginTop: 20,
                            flexDirection: isMobile ? "column" : "row",
                          }}
                        >
                          <View
                            style={{
                              marginRight: "1%",
                              backgroundColor: "white",
                              paddingTop: 1,
                              marginVertical: "0.5%",
                              width: "48.5%",
                              height: 210,
                              borderWidth: 1,
                              borderRadius: 12,
                              borderColor: "rgba(65, 13, 170, 0.3)",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                paddingHorizontal: 15,
                                paddingVertical: 5,
                                height: 90,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Exclusive.png",
                                  }}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                              </View>
                              <View
                                style={{
                                  flex: 2,
                                  marginTop: 8,
                                  marginLeft: 8,
                                }}
                              >
                                <Text
                                  style={{
                                    flex: 1,
                                    fontSize: 16,
                                    color: "#121212",
                                    fontWeight: "600",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  Exclusive & Comprehensive Financial Advisory
                                </Text>
                                <View
                                  style={{
                                    marginTop: 8,
                                    flexDirection: "column",
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Designed for personalized financial
                                      advisory service
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Achieve financial goals while building
                                      wealth over time
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              marginRight: "1%",
                              backgroundColor: "white",
                              paddingTop: 1,
                              marginVertical: "0.5%",
                              width: "48.5%",
                              height: 210,
                              borderWidth: 1,
                              borderRadius: 12,
                              borderColor: "rgba(65, 13, 170, 0.3)",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                paddingHorizontal: 15,
                                paddingVertical: 5,
                                height: 90,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/ManagedPortfolio.png",
                                  }}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                              </View>
                              <View
                                style={{
                                  flex: 2,
                                  marginTop: 8,
                                  marginLeft: 8,
                                }}
                              >
                                <Text
                                  style={{
                                    flex: 1,
                                    fontSize: 16,
                                    color: "#121212",
                                    fontWeight: "600",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  Managed Portfolio Advisory & Rebalancing
                                </Text>
                                <View
                                  style={{
                                    marginTop: 8,
                                    flexDirection: "column",
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      15-25 growth stocks for Flexicap and
                                      Smallcap Strategies
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Customized buy/sell advice and quarterly
                                      stock portfolio rebalancing
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                        <View
                          style={{
                            width: "94%",
                            marginLeft: "3%",
                            display: "flex",
                            marginTop: "0.6%",
                            flexDirection: isMobile ? "column" : "row",
                          }}
                        >
                          <View
                            style={{
                              marginRight: "1%",
                              backgroundColor: "white",
                              paddingTop: 1,
                              marginVertical: "0.5%",
                              width: "48.5%",
                              height: 210,
                              borderWidth: 1,
                              borderRadius: 12,
                              borderColor: "rgba(65, 13, 170, 0.3)",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                paddingHorizontal: 15,
                                paddingVertical: 5,
                                height: 90,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/CustomizedMutual.png",
                                  }}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                              </View>
                              <View
                                style={{
                                  flex: 2,
                                  marginTop: 8,
                                  marginLeft: 8,
                                }}
                              >
                                <Text
                                  style={{
                                    flex: 1,
                                    fontSize: 16,
                                    color: "#121212",
                                    fontWeight: "600",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  Customized Mutual Fund Advice
                                </Text>
                                <View
                                  style={{
                                    marginTop: 8,
                                    flexDirection: "column",
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Guidance on selecting direct plan mutual
                                      funds
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Emphasis on lower expense ratios and
                                      higher returns
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              marginRight: "1%",
                              backgroundColor: "white",
                              paddingTop: 1,
                              marginVertical: "0.5%",
                              width: "48.5%",
                              height: 210,
                              borderWidth: 1,
                              borderRadius: 12,
                              borderColor: "rgba(65, 13, 170, 0.3)",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                paddingHorizontal: 15,
                                paddingVertical: 5,
                                height: 90,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Unlimited+Goals.png",
                                  }}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                              </View>
                              <View
                                style={{
                                  flex: 2,
                                  marginTop: 8,
                                  marginLeft: 8,
                                }}
                              >
                                <Text
                                  style={{
                                    flex: 1,
                                    fontSize: 16,
                                    color: "#121212",
                                    fontWeight: "600",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  Unlimited Goals & Advisory Packs
                                </Text>
                                <View
                                  style={{
                                    marginTop: 8,
                                    flexDirection: "column",
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Advice on insurance, taxation, and
                                      retirement planning
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Quarterly personal financial plan reviews
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                        <Button
                          uppercase={false}
                          mode="contained"
                          labelStyle={{
                            color: "#410DAA",
                            fontWeight: "600",
                          }}
                          onPress={() => {
                            this.setState({
                              showEliteReadMore: true,
                              showEliteLearnMore: false,
                            });
                          }}
                          style={{
                            marginTop: 20,
                            marginHorizontal: 24,
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            backgroundColor: "white",
                            borderRadius: 6,
                            height: 37,
                            width: "24%",
                            marginLeft: "37%",
                          }}
                        >
                          Read More
                        </Button>
                      </>
                    )}
                    {this.state.showAdvisoryLearnMore && (
                      <>
                        <View
                          style={{
                            width: "94%",
                            marginLeft: "3%",
                            display: "flex",
                            marginTop: 20,
                            flexDirection: isMobile ? "column" : "row",
                          }}
                        >
                          <View
                            style={{
                              marginRight: "1%",
                              backgroundColor: "white",
                              paddingTop: 1,
                              marginVertical: "0.5%",
                              width: "48.5%",
                              height: 210,
                              borderWidth: 1,
                              borderRadius: 12,
                              borderColor: "rgba(65, 13, 170, 0.3)",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                paddingHorizontal: 15,
                                paddingVertical: 5,
                                height: 90,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/ExpertAdvice.png",
                                  }}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                              </View>
                              <View
                                style={{
                                  flex: 2,
                                  marginTop: 8,
                                  marginLeft: 8,
                                }}
                              >
                                <Text
                                  style={{
                                    flex: 1,
                                    fontSize: 16,
                                    color: "#121212",
                                    fontWeight: "600",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  Expert Advice on Diverse Financial Products
                                </Text>
                                <View
                                  style={{
                                    marginTop: 8,
                                    flexDirection: "column",
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Stocks, funds, and goals guidance
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Tailored recommendations for your specific
                                      financial objectives
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              marginRight: "1%",
                              backgroundColor: "white",
                              paddingTop: 1,
                              marginVertical: "0.5%",
                              width: "48.5%",
                              height: 210,
                              borderWidth: 1,
                              borderRadius: 12,
                              borderColor: "rgba(65, 13, 170, 0.3)",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                paddingHorizontal: 15,
                                paddingVertical: 5,
                                height: 90,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Timely+Investment.png",
                                  }}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                              </View>
                              <View
                                style={{
                                  flex: 2,
                                  marginTop: 8,
                                  marginLeft: 8,
                                }}
                              >
                                <Text
                                  style={{
                                    flex: 1,
                                    fontSize: 16,
                                    color: "#121212",
                                    fontWeight: "600",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  Timely Investment Advice & Direct Plan Access
                                </Text>
                                <View
                                  style={{
                                    marginTop: 8,
                                    flexDirection: "column",
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Up to 24 stock and fund advice per year
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Personalized mutual fund recommendations
                                      with access to direct plan mutual funds
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                        <View
                          style={{
                            width: "94%",
                            marginLeft: "3%",
                            display: "flex",
                            marginTop: "0.6%",
                            flexDirection: isMobile ? "column" : "row",
                          }}
                        >
                          <View
                            style={{
                              marginRight: "1%",
                              backgroundColor: "white",
                              paddingTop: 1,
                              marginVertical: "0.5%",
                              width: "48.5%",
                              height: 210,
                              borderWidth: 1,
                              borderRadius: 12,
                              borderColor: "rgba(65, 13, 170, 0.3)",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                paddingHorizontal: 15,
                                paddingVertical: 5,
                                height: 90,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/WhentoSell.png",
                                  }}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                              </View>
                              <View
                                style={{
                                  flex: 2,
                                  marginTop: 8,
                                  marginLeft: 8,
                                }}
                              >
                                <Text
                                  style={{
                                    flex: 1,
                                    fontSize: 16,
                                    color: "#121212",
                                    fontWeight: "600",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  'When to Sell' Advice & Regular Rebalancing
                                </Text>
                                <View
                                  style={{
                                    marginTop: 8,
                                    flexDirection: "column",
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Maximize returns with sell calls on stocks
                                      and mutual funds
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Quarterly stock portfolio rebalancing,
                                      half-yearly mutual fund rebalancing
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              marginRight: "1%",
                              backgroundColor: "white",
                              paddingTop: 1,
                              marginVertical: "0.5%",
                              width: "48.5%",
                              height: 210,
                              borderWidth: 1,
                              borderRadius: 12,
                              borderColor: "rgba(65, 13, 170, 0.3)",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                paddingHorizontal: 15,
                                paddingVertical: 5,
                                height: 90,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/ComprehensivePlan.png",
                                  }}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    alignSelf: "flex-start",
                                    borderRadius: 4,
                                  }}
                                  resizeMode={"contain"}
                                />
                              </View>
                              <View
                                style={{
                                  flex: 2,
                                  marginTop: 8,
                                  marginLeft: 8,
                                }}
                              >
                                <Text
                                  style={{
                                    flex: 1,
                                    fontSize: 16,
                                    color: "#121212",
                                    fontWeight: "600",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  Comprehensive Plan Features & Affordable
                                  Pricing
                                </Text>
                                <View
                                  style={{
                                    marginTop: 6,
                                    flexDirection: "column",
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 6,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      24 advice pack per year and personalized
                                      financial plan reviews every six months
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 8,
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        color: "#121212",
                                        marginTop: -8,
                                      }}
                                    >{`\u2022`}</Text>
                                    <Text
                                      style={{
                                        flex: 1,
                                        fontSize: 14,
                                        marginLeft: 5,
                                        color: "#121212",
                                        fontWeight: "600",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      Advisory fees starting at effectively Rs
                                      400 per month for a three-year plan
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                        <Button
                          uppercase={false}
                          mode="contained"
                          labelStyle={{
                            color: "#410DAA",
                            fontWeight: "600",
                          }}
                          onPress={() => {
                            this.setState({
                              showAdvisoryReadMore: true,
                              showAdvisoryLearnMore: false,
                            });
                          }}
                          style={{
                            marginTop: 20,
                            marginHorizontal: 24,
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            backgroundColor: "white",
                            borderRadius: 6,
                            height: 37,
                            width: "24%",
                            marginLeft: "37%",
                          }}
                        >
                          Read More
                        </Button>
                      </>
                    )}
                    {this.state.showEliteReadMore && (
                      <View
                        style={{
                          width: "90%",
                          marginLeft: "5%",
                          display: "flex",
                          marginTop: "0.6%",
                          flexDirection: "column",
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <View style={{ flex: 1 }}>
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Elite+Circle.png",
                              }}
                              style={{
                                width: 100,
                                height: 100,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                          </View>
                          <View style={{ flex: 5 }}>
                            <Text
                              style={{
                                flex: 1,
                                fontSize: 16,
                                color: "#121212",
                                fontWeight: "600",
                                flexWrap: "wrap",
                                marginTop: 10,
                              }}
                            >
                              Our Elite Circle plan is our most exclusive and
                              comprehensive financial advisory service offering.
                              This plan is specifically designed for clients who
                              are looking for a personalized financial advisory
                              service that will help them to achieve their
                              financial goals while building their wealth over
                              time.
                            </Text>
                          </View>
                        </View>
                        <Text
                          style={{
                            flex: 1,
                            fontSize: 14,
                            marginLeft: 5,
                            color: "#121212",
                            fontWeight: "600",
                            flexWrap: "wrap",
                            marginBottom: 50,
                          }}
                        >
                          With our Elite Circle plan, clients will receive
                          managed portfolio advisory services in stocks and
                          mutual funds to build their wealth over time. One of
                          the key benefits of this plan is that our experts will
                          work with clients to create a portfolio of 15-25
                          growth stocks for Flexicap and Smallcap Strategies,
                          providing them with customized advice on when to buy
                          and sell these stocks. Our experts will also provide
                          quarterly rebalancing of the overall stock portfolio,
                          ensuring that clients stay on track to achieve their
                          financial goals.
                          {"\n\n"}Another important aspect of our Elite Circle
                          plan is the customized advice that our experts provide
                          on mutual funds. Our experts will guide clients in
                          choosing the right mutual funds for their investment
                          portfolio, with an emphasis on direct plan mutual
                          funds. Direct plan mutual funds offer several
                          benefits, including lower expense ratios and higher
                          returns, which can make a significant difference in
                          building wealth over time. Our experts will provide
                          guidance on the most appropriate mutual funds for each
                          client's unique financial situation and goals.
                          {"\n\n"}Clients of our Elite Circle plan also receive
                          unlimited goals and advisory packs, allowing them to
                          seek our expert advice on a wide range of financial
                          matters, including insurance, taxation, and retirement
                          planning. With a minimum investment of Rs. 10 lakhs as
                          a lump sum or a monthly SIP of Rs. 30,000, our Elite
                          Circle plan is designed for clients who are serious
                          about building and growing their wealth with the help
                          of our expert financial advisors.
                          {"\n\n"}Additionally, clients can expect to receive
                          quarterly personal reviews of their financial plan,
                          ensuring that they stay on track to achieve their
                          financial goals. Our experts will provide detailed
                          feedback on each client's financial plan, making
                          recommendations for changes or adjustments as needed.
                          {"\n\n"}To sum up, our Elite Circle plan is the
                          ultimate financial advisory service for clients who
                          are looking for a comprehensive and personalised
                          approach to building their wealth and achieving their
                          financial goals. Our expert advisors will work closely
                          with each client to provide customized advice and
                          guidance on stocks, mutual funds, and a wide range of
                          financial matters, ensuring that clients stay on track
                          to achieve their financial objectives.
                        </Text>
                      </View>
                    )}
                    {this.state.showAdvisoryReadMore && (
                      <View
                        style={{
                          width: "90%",
                          marginLeft: "5%",
                          display: "flex",
                          marginTop: "0.6%",
                          flexDirection: "column",
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <View style={{ flex: 1 }}>
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Advisory+Club.png",
                              }}
                              style={{
                                width: 100,
                                height: 100,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                          </View>
                          <View style={{ flex: 5 }}>
                            <Text
                              style={{
                                flex: 1,
                                fontSize: 16,
                                color: "#121212",
                                fontWeight: "600",
                                flexWrap: "wrap",
                                marginTop: 30,
                              }}
                            >
                              Our Advisory Circle plan is designed for investors
                              who are looking for expert advice on a range of
                              financial products, including stocks, funds, and
                              goals.
                            </Text>
                          </View>
                        </View>
                        <Text
                          style={{
                            flex: 1,
                            fontSize: 14,
                            marginLeft: 5,
                            color: "#121212",
                            fontWeight: "600",
                            flexWrap: "wrap",
                            marginBottom: 50,
                          }}
                        >
                          Our experienced advisors will work closely with
                          clients to provide tailored recommendations that align
                          with their specific financial objectives.{"\n\n"}Under
                          our Advisory Club plan, clients receive customized
                          advice on stocks, funds, and goals. Our advisors
                          provide up to 24 stock and fund advice per year,
                          ensuring that clients are always up-to-date with the
                          latest market developments and investment trends. We
                          also provide personalised advice on mutual funds,
                          enabling clients to invest in funds that align with
                          their financial goals and risk tolerance. Access to
                          direct plan mutual funds is available which means no
                          commission or indirect charges on such mutual fund
                          schemes. {"\n\n"}One of the key benefits of our
                          Advisory Circle plan is the inclusion of 'when to
                          sell' advice. {"\n\n"}This ensures that clients are
                          not only getting advice on what to invest in, but also
                          when to sell their investments to maximise their
                          returns. Clients receive sell calls on stocks
                          recommended earlier and quarterly rebalancing of their
                          overall stocks portfolio. For mutual funds, we provide
                          half-yearly rebalancing and 'when to sell' advice to
                          ensure that clients are making the most of their
                          investments. {"\n\n"}Our clients can also benefit from
                          the "24 advice pack" per year, which they can use to
                          obtain advice on various aspects of their investments.
                          This ensures that clients have access to our expert
                          advisors whenever they need them and can receive
                          personalised advice on their investment strategies.
                          With our Advisory Circle plan, clients have access to
                          a range of features and benefits, including
                          personalised financial plan reviews every six months.
                          During these reviews, our expert advisors assess the
                          client's financial goals and investment strategy,
                          providing feedback and advice on how to optimize their
                          portfolio. {"\n\n"}Our Advisory Circle plan is
                          available for a range of investment amounts, making it
                          accessible to investors of all levels. The advisory
                          fee for this plan starts at effectively Rs 400 per
                          month for a three-year plan and goes up to Rs 800 per
                          month for a six-month plan. This fee structure ensures
                          that clients receive the expert advice they need at a
                          price that works for them. {"\n\n"}To sum up, Advisory
                          Club plan is ideal for investors who are looking for
                          personalised and expert advice on a range of financial
                          products. With our experienced advisors and
                          comprehensive range of features, we are confident that
                          our clients will achieve their financial goals and
                          build long-term wealth.
                        </Text>
                      </View>
                    )}
                    {this.state.showEliteBetter && (
                      <View
                        style={{
                          width: "90%",
                          marginLeft: "5%",
                          display: "flex",
                          marginTop: "2%",
                          flexDirection: "column",
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <View style={{ flex: 1 }}>
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/UpgradetoElite.png",
                              }}
                              style={{
                                width: 50,
                                height: 50,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                          </View>
                          <View style={{ flex: 8 }}>
                            <Text
                              style={{
                                flex: 1,
                                fontSize: 16,
                                color: "#121212",
                                fontWeight: "600",
                                flexWrap: "wrap",
                                marginTop: 20,
                              }}
                            >
                              Upgrade to Elite Circle: Experience the Ultimate
                              in Financial Advisory
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            marginTop: 20,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Premium Advisory Services: Enjoy a more exclusive,
                            comprehensive financial advisory service tailored to
                            your unique needs.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Managed Portfolio Expertise: Receive expertly
                            crafted portfolios of 15-25 growth stocks for
                            Flexicap and Smallcap Strategies, including buy and
                            sell recommendations.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Increased Trust: Stay on track to achieve your
                            financial goals with expert guidance on maintaining
                            a well-balanced stock portfolio.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Direct Plan Mutual Fund Focus: Gain access to
                            superior investment opportunities with lower expense
                            ratios and higher returns through direct plan mutual
                            funds.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Unlimited Access: Benefit from unlimited goals and
                            advisory packs, covering a wide range of financial
                            matters such as insurance, taxation, and retirement
                            planning.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Higher Investment Commitment: Demonstrate your
                            dedication to building and growing wealth with a
                            minimum investment of Rs. 10 lakhs lump sum or a
                            monthly SIP of Rs. 30,000.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Quarterly Financial Plan Reviews: Enjoy more
                            frequent, detailed feedback on your financial plan
                            to optimize your investment strategy.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Achieve Financial Excellence: Leverage the expertise
                            of our financial advisors to secure your financial
                            legacy and accomplish your long-term goals.
                          </Text>
                        </View>
                      </View>
                    )}
                    {this.state.showAdvisoryBetter && (
                      <View
                        style={{
                          width: "90%",
                          marginLeft: "5%",
                          display: "flex",
                          marginTop: "2%",
                          flexDirection: "column",
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <View style={{ flex: 1 }}>
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/UpgradetoAdvisory.png",
                              }}
                              style={{
                                width: 50,
                                height: 50,
                                alignSelf: "flex-start",
                                borderRadius: 4,
                              }}
                              resizeMode={"contain"}
                            />
                          </View>
                          <View style={{ flex: 8 }}>
                            <Text
                              style={{
                                flex: 1,
                                fontSize: 16,
                                color: "#121212",
                                fontWeight: "600",
                                flexWrap: "wrap",
                                marginTop: 20,
                              }}
                            >
                              Upgrade to Advisory Circle: Enhance Your
                              Investment Experience
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            marginTop: 20,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Personalised Expertise: Receive tailored advice on
                            stocks, funds, and goals to better align with your
                            financial objectives.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Transparent Investing: Experience the transparency
                            of direct plan mutual funds with no commission or
                            indirect charges.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Increased Trust: Build a stronger relationship with
                            your advisor through personalised attention and
                            customized advice.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            When to Sell Guidance: Maximise returns by knowing
                            not only what to invest in, but also when to sell
                            your investments.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Frequent Portfolio Reviews: Benefit from
                            personalised financial plan reviews every six months
                            to optimise your investment strategy.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Stay Ahead: Access to 24 advice packs per year to
                            ensure you're always up-to-date with the latest
                            market trends and developments.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Affordable Fee Structure: Advisory Circle fees start
                            at effectively Rs 400 per month, making expert
                            advice accessible at a reasonable cost.
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: 25,
                              color: "#121212",
                              marginTop: -8,
                            }}
                          >{`\u2022`}</Text>
                          <Text
                            style={{
                              flex: 1,
                              fontSize: 14,
                              marginLeft: 5,
                              color: "#121212",
                              fontWeight: "600",
                              flexWrap: "wrap",
                            }}
                          >
                            Secure Your Financial Future: Achieve your financial
                            goals and build long-term wealth with the help of
                            experienced advisors.
                          </Text>
                        </View>
                      </View>
                    )}
                  </ScrollView>
                </>
              )}
            </View>
          </>
        ) : this.state.showAdvisoryPlan ? (
          <AdvisoryPlan
            navigation={this.props.navigation}
            route={this.props.route}
            onPressBack={() => {
              this.setState({ showAdvisoryPlan: false });
            }}
            onPlanSelect={(amount, plancode) => {
              this.setState({
                plancode,
                amount,
                showAdvisoryPlan: false,
                showHandSignature: true,
              });
            }}
          />
        ) : (
          <HandSignature
            amount={this.state.amount}
            plancode={this.state.plancode}
            onPressBack={() => {
              this.setState({
                showHandSignature: false,
                showAdvisoryPlan: true,
                plancode: "",
                amount: 0,
              });
            }}
          />
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: isMobile ? 0 : 40,
    flexDirection: isMobile ? "column" : "row",
    paddingHorizontal: isMobile ? 0 : 0,
    marginRight: isMobile ? 0 : 0,
  },
  productContainer: {
    width: isMobile ? "100%" : "32.2%",
    borderColor: isMobile ? "transparent" : "#EEE5FF40",
    borderRadius: isMobile ? 0 : 18,
    paddingTop: 15,
    paddingBottom: isMobile ? 0 : 12,
    borderWidth: 1,
    marginBottom: isMobile ? 15 : 0,
  },
  volatalityContainer: {
    backgroundColor: "#FFF0E8",
    marginLeft: isMobile ? 9 : 15,
  },
  volatality: {
    fontSize: isMobile ? 9 : 10,
    color: "#EF5D12",
    fontStyle: "italic",
    paddingVertical: isMobile ? 5 : 6,
    paddingHorizontal: isMobile ? 14 : 18,
    fontWeight: "bold",
  },
  topContainer: {
    paddingHorizontal: 15,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: isMobile ? 15 : 16,
    color: "#464E5F",
    fontWeight: "bold",
    fontFamily: "Roboto",
    paddingTop: 3,
  },
  image: {
    width: 25,
    height: 24,
    resizeMode: "contain",
    marginRight: 6,
  },
  strategy: {
    fontSize: 13,
    color: "#888C9F",
    fontStyle: "italic",
    marginTop: 13,
  },
  infoContainer: {
    flex: 1,
    marginTop: isMobile ? 20 : 30,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 12,
    backgroundColor: "#F6F6F660",
    paddingHorizontal: 15,
  },
  infoFieldsContainer: {
    marginTop: 0,
    paddingTop: 4,
  },
  infoTitle: {
    fontSize: 12,
    color: "#6C7293",
    fontWeight: "600",
    marginBottom: 8,
  },
  infoField: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  infoValue: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  button: {
    alignSelf: "center",
  },
  buttonText: {
    fontSize: isMobile ? 13 : 15,
    color: "#410DAA",
    fontWeight: "bold",
    lineHeight: 23,
    textDecorationLine: isMobile ? "underline" : "none",
  },
  featureContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingHorizontal: 18,
    marginBottom: 12,
  },
  feature: {
    color: "#464E5F",
    fontFamily: "Roboto",
    fontSize: 13,
  },
  featureBullet: {
    color: "#410DAA",
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "bold",
    paddingRight: 5,
  },
  details: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  descriptionContainer: {
    marginTop: 12,
  },
});
