import React from "react";
import {
  View,
  Text,
  Dimensions,
  ScrollView,
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import amplitude from "../../utils/amplitude";
import { md } from "../../Responsive";
import { capitalizeFirstLetter, getUserDetails, isMobile, reverseString } from "../../utils";
import Header from "../../screens/onboarding/header_after_login";
import { getInvoicesPaymentLink } from "../../api/billing";
import { createCrmdealsCipForAdvisory, createCrmdealsCipForRegular, getCustomerDetails, getHashKeyValue, moveDataFromDirectToRegular } from "../../api";
import { ActivityIndicator } from "react-native";
import { ShowToast } from "../Common/Toast";
import Store from "../../store";
const { width, height } = Dimensions.get("window");
const isWeb = md(width);
import { FontAwesome5 } from "@expo/vector-icons";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../api/apiConfig";
import { MAIN_URL, URLS } from "../../api/urls";
import { LOGOUT } from "../../actions/types";

export default class AdvisoryPlanHome extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let userId = null;
    let firstLogin = null;
    if (params) {
      firstLogin = params.first || null;
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }
    this.state = {
      userId,
      firstLogin,
      Plan6MBg: "white",
      Plan6MColor: "#410DAA",
      Plan1YBg: "white",
      Plan1YColor: "#410DAA",
      Plan3YBg: "white",
      Plan3YColor: "#410DAA",
      isLoading: false,
      showToast: false,
      toastMsg: null,
      userName: "",
    };
  }

  async componentDidMount() {
    let userData = {};
    if (Platform.OS == "web"){
      amplitude.logEvent("advisoryPlanHome",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("advisoryPlanHome", { path: this.props.navigation.getState().routeName });
    }
    if (this.state.userId) {
      userData = await getCustomerDetails(params.user_id);
    } else {
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        userData = await getCustomerDetails(
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        userData = getUserDetails();
      }
    }
    
    
    const { first_name } = userData;
    let names = first_name ? first_name.split(/\s+/) : [];
    let name = "";
    if (names.length >= 2 && names.length > 0) {
      name =
        capitalizeFirstLetter(names[0]) + " " + capitalizeFirstLetter(names[1]);
    } else {
      if (names.length == 0) {
        name = "User";
      } else {
        name = names[0];
      }
    }
    this.setState({ userName: name });
  }

  handleMakePayment = async (amount, plan_code) => {
    try {
      this.setState({ isLoading: true });
      const crmcippayload = {
        plancode: plan_code,
      };
      const crm_sip_resp = await createCrmdealsCipForAdvisory(crmcippayload);
      if (crm_sip_resp && crm_sip_resp.code == 100) {
        const payload = {
          invoices: crm_sip_resp.cust_invoice_id.toString(),
          action: "advisory",
          amount: crm_sip_resp.amount,
          server_name:
            Platform.OS == "web"
              ? global.location.href.split("/")[2]
              : "mobile",
        };
        const response = await getInvoicesPaymentLink(payload);
        if (response && response.code && response.code == 100) {
          const { results = {} } = response;
          const { payment_link } = results;
          if (Platform.OS == "web") {
            global.open(payment_link, "_self");
          } else {
            this.props.navigation.navigate("App", {
              screen: "Dashboard",
              params: {
                screen: "Portfolio",
                params: {
                  screen: "ShowWebView",
                  params: {
                    url: payment_link,
                    successURL: "billing/payment/status/",
                    onSuccess: function (navigation, _results = {}) {
                      navigation.push("App", {
                        screen: "Billing",
                        params: {
                          screen: "InvoicesPaymentRedirection",
                          params: {},
                        },
                      });
                    },
                    onError: function (_results = {}) { },
                  },
                },
              },
            });
          }
        }
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        showToast: true,
        toastMsg: error,
      });
    }
  };

  handleContinueToLogout = async () => {
    try {
      localStorage.removeItem("isShowAppDownloadCard");
      localStorage.removeItem("showPlanRenewalCard");
      localStorage.removeItem("redirectAfterOnboardingSuccess");
      // const response = await getHashKeyValue();
      // if (response && response.code == 100) {
      //   const { hash_key } = response.result;
      //   let token = getTokenFromStore();
      //   token = reverseString(token);
      //   global.open(
      //     MAIN_URL +
      //       URLS.LOGOUT_URL +
      //       (hash_key + token + "/" + GATEWAY_ENVIRONMENT),
      //     "_self"
      //   );
      // } else {
        Store.dispatch({ type: LOGOUT });
      // }
    } catch (error) {
      Store.dispatch({ type: LOGOUT });
    }
  };

  handleScheduleMeeting = () => {
    this.props.navigation.navigate("ScheduleCall", {
      selected: "DE",
      from_onboarding: true
    });
  }

  handleDecideLater = async () => {
    this.setState({isLoading: true})
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    let userDetails = getUserDetails();
    if (hostname == "app.jamawealth.com" || hostname == "myadvisor.jamawealth.com" || hostname == "dapp.jamawealth.com" || hostname == "dmyadvisor.jamawealth.com" || hostname == "mobile" || hostname == "localhost") {
      // moveDataFromDirectToRegular
      const payload = {
        cust_pk : userDetails.pk
      }
      let response = await moveDataFromDirectToRegular(payload)
    
      if (response && response.code == 100) {
        // console.log("Redirection i jfvkjf ", response);
        this.setState({
          showToast: true,
          toastMessage: response.result,
          migrating_data: false,
        })
        let url = "https://dclub.jamawealth.com/login";
        if (response.token) {
          let reverseToken = reverseString(response.token);
          url = url + "?unique=" + reverseToken
        }
        setTimeout(() => {
          this.handleContinueToLogout();
          global.open(url, "_blank");
        }, 5000);
      } else {
        // console.log(response.msg);
        this.setState({
          isLoading: false,
          error: true,
          migrating_data: false
        })
      }
      this.setState({isLoading: false})
    } else {
      const crmcippayload = {
        plancode: plan_code,
      };
      let responce = await createCrmdealsCipForRegular();
      // if(responce && responce.code == 100) {
        this.props.navigation.navigate("App",{
          screen: "Dashboard",
          params: {
            screen: "Dashbaord",
            params: {  }
          }
        })
      // }
    }
  }

  render() {
    const { isLoading, showToast, toastMsg } = this.state;
    return isLoading ? (
      <ActivityIndicator
        size="large"
        color="#410DAA"
        style={{ paddingTop: 40 }}
      />
    ) : (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        {showToast ? (
          <ShowToast showToast={true} type="success" title={toastMsg} />
        ) : null}
        <View
          style={{
            flex: 1,
            backgroundColor: "#ffffff",
          }}
        >
          <Header navigation={this.props.navigation} route={this.props.route} />
          <ScrollView
            style={{
              paddingLeft: isWeb ? 32 : 0,
              paddingRight: isWeb ? 32 : 0,
              paddingTop: isWeb ? 30 : 0,
              marginBottom: isMobile ? 0 : 20,
              height: height,
            }}
            showsVerticalScrollIndicator={true}
          >
            {/* <View style={{ width: '100%', paddingBottom: 20, paddingRight: 20, justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex', flexDirection: 'row' }}>
              <TouchableOpacity onPress={this.handleScheduleMeeting} style={{marginRight: 50}}>
                <Text style={{ color: '#410DAA', textDecorationLine: 'underline', fontWeight: '500' }}>Schedule Call</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={this.handleDecideLater}>
                <Text style={{ color: '#410DAA', textDecorationLine: 'underline', fontWeight: '500' }}>Decide later</Text>
              </TouchableOpacity>
            </View> */}
            {isMobile ? (
              <ScrollView>
                <View
                  style={{
                    marginTop: 20,
                    width: isMobile ? "90%" : "100%",
                    marginLeft: isMobile ? "5%" : "0%",
                    marginRight: isMobile ? "5%" : "0%",
                    flexDirection: "column",
                  }}
                  showsVerticalScrollIndicator={true}
                >
                  <View
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      borderColor: "#cdcdcb",
                      borderWidth: 1,
                      marginBottom: "6%",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "12%",
                      }}
                    >
                      <View
                        style={{
                          width: "100%",
                          display: "flex",
                          backgroundColor: "#e4dbf2",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "50%",
                          borderRadius: 9,
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      >
                        <Text
                          style={{
                            color: "#410DAA",
                            fontSize: 14,
                            fontWeight: "500",
                            paddingRight: "20%",
                          }}
                        >
                          Most Popular
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          width: "20%",
                          borderRadius: 9,
                          borderColor: "#009105",
                          borderWidth: 1,
                          backgroundColor: "#009105",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 40,
                          borderBottomRightRadius: 40,
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "-20%",
                        }}
                      >
                        <Text
                          style={{
                            color: "white",
                            fontSize: 14,
                            fontWeight: "500",
                          }}
                        >
                          50%
                        </Text>
                        <Text
                          style={{
                            color: "white",
                            fontSize: 14,
                            fontWeight: "500",
                          }}
                        >
                          Off
                        </Text>
                      </View>
                    </View>
                    <Text
                      style={{
                        fontSize: 34,
                        color: "#51514E",
                        fontWeight: "700",
                        marginLeft: "10%",
                        marginTop: "-5%",
                      }}
                    >
                      ₹ 14,400 /-
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#a1a6af",
                        fontWeight: "600",
                        marginLeft: "10%",
                        marginTop: "2%",
                      }}
                    >
                      3 Year Validity
                    </Text>
                    <Text
                      style={{
                        fontSize: 20,
                        color: "#027BFC",
                        fontWeight: "700",
                        marginLeft: "10%",
                        marginTop: "7%",
                        marginBottom: "5%",
                      }}
                    >
                      PACK OF 72 ADVICES
                    </Text>
                    <View
                      style={{
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        borderRadius: 10,
                        borderColor: "white",
                        borderWidth: 1,
                        backgroundColor: "#f9f5ff",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          color: "#464E5F",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        Advices Included
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Stock of the month (36)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Mutual Fund Pack (12)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Financial Plan Review (6)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Portfolio Review (6)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Adhoc Advices (12)
                        </Text>
                      </View>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#8a6acc",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                          marginBottom: "10%",
                          fontStyle: "italic",
                        }}
                      >
                        View More
                      </Text>
                    </View>
                    <Text
                      onPress={() => {
                        this.handleMakePayment(4800, "AHY");
                      }}
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "white",
                        backgroundColor: "#410DAA",
                        borderColor: "#410DAA",
                        borderWidth: 1,
                        borderRadius: 4,
                        paddingVertical: 10,
                        textAlign: "center",
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        marginTop: "10%",
                        marginBottom: "20%",
                      }}
                    >
                      Subscribe
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      borderColor: "#cdcdcb",
                      borderWidth: 1,
                      marginBottom: "6%",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "12%",
                      }}
                    >
                      <View
                        style={{
                          width: "80%",
                        }}
                      ></View>
                      <View
                        style={{
                          display: "flex",
                          width: "20%",
                          borderRadius: 9,
                          borderColor: "#009105",
                          borderWidth: 1,
                          backgroundColor: "#009105",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 40,
                          borderBottomRightRadius: 40,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            color: "white",
                            fontSize: 14,
                            fontWeight: "500",
                          }}
                        >
                          25%
                        </Text>
                        <Text
                          style={{
                            color: "white",
                            fontSize: 14,
                            fontWeight: "500",
                          }}
                        >
                          Off
                        </Text>
                      </View>
                    </View>
                    <Text
                      style={{
                        fontSize: 34,
                        color: "#51514E",
                        fontWeight: "700",
                        marginLeft: "10%",
                        marginTop: "-5%",
                      }}
                    >
                      ₹ 7,200/-
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#a1a6af",
                        fontWeight: "600",
                        marginLeft: "10%",
                        marginTop: "2%",
                      }}
                    >
                      1 Year Validity
                    </Text>
                    <Text
                      style={{
                        fontSize: 20,
                        color: "#027BFC",
                        fontWeight: "700",
                        marginLeft: "10%",
                        marginTop: "7%",
                        marginBottom: "5%",
                      }}
                    >
                      PACK OF 24 ADVICES
                    </Text>
                    <View
                      style={{
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        borderRadius: 10,
                        borderColor: "white",
                        borderWidth: 1,
                        backgroundColor: "#f9f5ff",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          color: "#464E5F",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        Advices Included
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Stock of the month (12)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Mutual Fund Pack (4)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Financial Plan Review (2)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Portfolio Review (2)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Adhoc Advices (4)
                        </Text>
                      </View>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#8a6acc",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                          marginBottom: "10%",
                          fontStyle: "italic",
                        }}
                      >
                        View More
                      </Text>
                    </View>
                    <Text
                      onPress={() => {
                        this.handleMakePayment(7200, "A1Y");
                      }}
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "#410DAA",
                        backgroundColor: "white",
                        borderColor: "#410DAA",
                        borderWidth: 1,
                        borderRadius: 4,
                        paddingVertical: 10,
                        textAlign: "center",
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        marginTop: "10%",
                        marginBottom: "20%",
                      }}
                    >
                      Subscribe
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      borderColor: "#cdcdcb",
                      borderWidth: 1,
                      marginBottom: 100,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 34,
                        color: "#51514E",
                        fontWeight: "700",
                        marginLeft: "10%",
                        marginTop: "14.8%",
                      }}
                    >
                      ₹ 4,800/-
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#a1a6af",
                        fontWeight: "600",
                        marginLeft: "10%",
                        marginTop: "2%",
                      }}
                    >
                      6 Months Validity
                    </Text>
                    <Text
                      style={{
                        fontSize: 20,
                        color: "#027BFC",
                        fontWeight: "700",
                        marginLeft: "10%",
                        marginTop: "7%",
                        marginBottom: "5%",
                      }}
                    >
                      PACK OF 9 ADVICES
                    </Text>
                    <View
                      style={{
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        borderRadius: 10,
                        borderColor: "white",
                        borderWidth: 1,
                        backgroundColor: "#f9f5ff",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          color: "#464E5F",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        Advices Included
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Stock of the month (6)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Mutual Fund Pack (1)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Financial Plan Review (1)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Portfolio Review (1)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        ></Text>
                      </View>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#8a6acc",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                          marginBottom: "10%",
                          fontStyle: "italic",
                        }}
                      >
                        View More
                      </Text>
                    </View>
                    <Text
                      onPress={() => {
                        this.handleMakePayment(14400, "A3Y");
                      }}
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "#410DAA",
                        backgroundColor: "white",
                        borderColor: "#410DAA",
                        borderWidth: 1,
                        borderRadius: 4,
                        paddingVertical: 10,
                        textAlign: "center",
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        marginTop: "10%",
                        marginBottom: "20%",
                      }}
                    >
                      Subscribe
                    </Text>
                  </View>
                </View>
              </ScrollView>
            ) : (
              <View style={styles.body}>
                <View style={styles.left}>
                  {/* <Text style={styles.terms}>Choose Subscription</Text> */}
                  <Text style={styles.please}>
                    Regulations require Payment for advisory services. Get
                    honest advice that works for you.
                  </Text>
                  <Image
                    source={
                      "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3.png"
                    }
                    style={styles.left_img}
                  />
                  <View
                    style={{
                      flexDirection: "row",
                      width: 300,
                      borderWidth: 1,
                      borderColor: "rgba(65, 13, 170, 0.2)",
                      backgroundColor: "#ffffff",
                      paddingVertical: 21,
                      paddingLeft: 20,
                      paddingRight: 6,
                      marginTop: 33,
                      marginBottom: 22,
                      marginRight:20,
                    }}
                  >
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/fluent_lock-shield-20-regular.png"
                      }
                      style={styles.lock}
                    />
                    <Text style={styles.jam_we}>
                      Information is encrypted using 256-bit keys and stored
                      securely
                    </Text>
                  </View>
                  <Text style={styles.any}>
                    Any Queries?{" "}
                    <TouchableOpacity
                      onPress={() => {
                        this.props.navigation.navigate("App", {
                          screen: "Dashboard",
                          params: {
                            screen: "Dashboard",
                            params: {
                              screen: "GetHelp",
                              params: {},
                            },
                          },
                        });
                      }}
                    >
                      <Text style={{ color: "#410DAA", fontWeight: "bold" }}>
                        Get Help
                      </Text>
                    </TouchableOpacity>
                  </Text>
                </View>
                <View style={styles.right}>
                  {this.state.firstLogin ? (
                    <Text
                      style={{
                        textAlign: "left",
                        fontWeight: "700",
                        fontSize: 16,
                        fontFamily: "Roboto",
                        marginBottom: 20,
                        marginTop: -55,
                      }}
                    >
                      Welcome {this.state.userName}, we found you have not
                      selected any subscription plan for investment, kindly
                      choose any plan and proceed for payment.
                    </Text>
                  ) : (
                    <View style={{ flexDirection: "row", marginTop: -55 }}>
                      <Text
                        style={{
                          textAlign: "left",
                          fontWeight: "700",
                          fontSize: 16,
                          fontFamily: "Roboto",
                          marginBottom: 20,
                          paddingLeft: 30,
                        }}
                      >
                        Greetings {this.state.userName}, Welcome to the
                        Investment Advisor Circle! Please select a suitable
                        subscription plan for your membership
                      </Text>
                    </View>
                  )}
                  <View
                    style={{
                      width: isMobile ? "90%" : "100%",
                      marginLeft: isMobile ? "5%" : "0%",
                      marginRight: isMobile ? "5%" : "0%",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      marginBottom: 60,
                    }}
                  >
                    <View
                      style={{
                        width: "31%",
                        marginLeft: "0.5%",
                        borderRadius: 10,
                        borderColor: "#cdcdcb",
                        borderWidth: 1,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 34,
                          color: "#51514E",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "16%",
                        }}
                      >
                        ₹ 4,800/-
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#51514E",
                          fontWeight: "600",
                          marginLeft: "10%",
                          marginTop: "2%",
                        }}
                      >
                        (or) ₹800/- per month
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#a1a6af",
                          fontWeight: "600",
                          marginLeft: "10%",
                          marginTop: "2%",
                        }}
                      >
                        6 Months Validity
                      </Text>
                      <Text
                        style={{
                          fontSize: 20,
                          color: "#027BFC",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                          marginBottom: "5%",
                        }}
                      >
                        PACK OF 9 ADVICES
                      </Text>
                      <View
                        style={{
                          width: "82%",
                          marginLeft: "9%",
                          marginRight: "9%",
                          borderRadius: 10,
                          borderColor: "white",
                          borderWidth: 1,
                          backgroundColor: "#f9f5ff",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 18,
                            color: "#464E5F",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                          }}
                        >
                          Advices Included
                        </Text>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "7%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Stock of the month (6)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Mutual Fund Pack (1)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Financial Plan Review (1)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Portfolio Review (1)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            {" "}
                          </Text>
                        </View>
                        {/* <Text
                          style={{
                            fontSize: 14,
                            color: "#8a6acc",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                            marginBottom: "10%",
                            fontStyle: "italic",
                          }}
                        >
                          View More 
                        </Text> */}
                      </View>
                      <Text
                        onPress={() => {
                          this.handleMakePayment(4800, "AHY");
                        }}
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "bold",
                          fontSize: 15,
                          color: this.state.Plan6MColor,
                          backgroundColor: this.state.Plan6MBg,
                          borderColor: "#410DAA",
                          borderWidth: 1,
                          borderRadius: 4,
                          paddingVertical: 10,
                          textAlign: "center",
                          width: "82%",
                          marginLeft: "9%",
                          marginRight: "9%",
                          marginTop: "10%",
                          marginBottom: "10%",
                        }}
                        onMouseEnter={() => {
                          this.setState({
                            Plan6MColor: "white",
                            Plan6MBg: "#410DAA",
                          });
                        }}
                        onMouseLeave={() => {
                          this.setState({
                            Plan6MBg: "white",
                            Plan6MColor: "#410DAA",
                          });
                        }}
                      >
                        Subscribe
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "3%",
                      }}
                    ></View>
                    <View
                      style={{
                        width: "31%",
                        borderRadius: 10,
                        borderColor: "#cdcdcb",
                        borderWidth: 1,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: "12%",
                        }}
                      >
                        <View
                          style={{
                            width: "80%",
                          }}
                        ></View>
                        <View
                          style={{
                            display: "flex",
                            width: "20%",
                            borderRadius: 9,
                            borderColor: "#009105",
                            borderWidth: 1,
                            backgroundColor: "#009105",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 40,
                            borderBottomRightRadius: 40,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              color: "white",
                              fontSize: 14,
                              fontWeight: "500",
                            }}
                          >
                            25%
                          </Text>
                          <Text
                            style={{
                              color: "white",
                              fontSize: 14,
                              fontWeight: "500",
                            }}
                          >
                            Off
                          </Text>
                        </View>
                      </View>
                      <Text
                        style={{
                          fontSize: 34,
                          color: "#51514E",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "-5%",
                        }}
                      >
                        ₹ 7,200/-
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#51514E",
                          fontWeight: "600",
                          marginLeft: "10%",
                          marginTop: "2%",
                        }}
                      >
                        (or) ₹ 600/- per month
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#a1a6af",
                          fontWeight: "600",
                          marginLeft: "10%",
                          marginTop: "2%",
                        }}
                      >
                        1 Year Validity
                      </Text>
                      <Text
                        style={{
                          fontSize: 20,
                          color: "#027BFC",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                          marginBottom: "5%",
                        }}
                      >
                        PACK OF 24 ADVICES
                      </Text>
                      <View
                        style={{
                          width: "82%",
                          marginLeft: "9%",
                          marginRight: "9%",
                          borderRadius: 10,
                          borderColor: "white",
                          borderWidth: 1,
                          backgroundColor: "#f9f5ff",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 18,
                            color: "#464E5F",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                          }}
                        >
                          Advices Included
                        </Text>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "7%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Stock of the month (12)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Mutual Fund Pack (4)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Financial Plan Review (2)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Portfolio Review (2)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Adhoc Advices (4)
                          </Text>
                        </View>
                        {/* <Text
                          style={{
                            fontSize: 14,
                            color: "#8a6acc",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                            marginBottom: "10%",
                            fontStyle: "italic",
                          }}
                        >
                          View More 
                        </Text> */}
                      </View>
                      <Text
                        onPress={() => {
                          this.handleMakePayment(7200, "A1Y");
                        }}
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "bold",
                          fontSize: 15,
                          color: "white",
                          backgroundColor: "#410DAA",
                          borderColor: "#410DAA",
                          borderWidth: 1,
                          borderRadius: 4,
                          paddingVertical: 10,
                          textAlign: "center",
                          width: "82%",
                          marginLeft: "9%",
                          marginRight: "9%",
                          marginTop: "10%",
                          marginBottom: "10%",
                        }}
                        onMouseEnter={() => {
                          this.setState({
                            Plan1YColor: "white",
                            Plan1YBg: "#410DAA",
                          });
                        }}
                        onMouseLeave={() => {
                          this.setState({
                            Plan1YBg: "white",
                            Plan1YColor: "#410DAA",
                          });
                        }}
                      >
                        Subscribe
                      </Text>
                    </View>
                    <View
                      style={{
                        height: 300,
                        width: "3%",
                      }}
                    ></View>
                    <View
                      style={{
                        width: "31%",
                        borderRadius: 10,
                        borderColor: "#cdcdcb",
                        borderWidth: 1,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: "12%",
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            display: "flex",
                            backgroundColor: "#e4dbf2",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50%",
                            borderRadius: 9,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        >
                          <Text
                            style={{
                              color: "#410DAA",
                              fontSize: 14,
                              fontWeight: "500",
                              paddingRight: "20%",
                            }}
                          >
                            Most Popular
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            width: "20%",
                            borderRadius: 9,
                            borderColor: "#009105",
                            borderWidth: 1,
                            backgroundColor: "#009105",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 40,
                            borderBottomRightRadius: 40,
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "-20%",
                          }}
                        >
                          <Text
                            style={{
                              color: "white",
                              fontSize: 14,
                              fontWeight: "500",
                            }}
                          >
                            50%
                          </Text>
                          <Text
                            style={{
                              color: "white",
                              fontSize: 14,
                              fontWeight: "500",
                            }}
                          >
                            Off
                          </Text>
                        </View>
                      </View>
                      <Text
                        style={{
                          fontSize: 34,
                          color: "#51514E",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "-5%",
                        }}
                      >
                        ₹ 14,400/-
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#51514E",
                          fontWeight: "600",
                          marginLeft: "10%",
                          marginTop: "2%",
                        }}
                      >
                        (or) ₹ 400/- per month
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#a1a6af",
                          fontWeight: "600",
                          marginLeft: "10%",
                          marginTop: "2%",
                        }}
                      >
                        3 Year Validity
                      </Text>
                      <Text
                        style={{
                          fontSize: 20,
                          color: "#027BFC",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                          marginBottom: "5%",
                        }}
                      >
                        PACK OF 72 ADVICES
                      </Text>
                      <View
                        style={{
                          width: "82%",
                          marginLeft: "9%",
                          marginRight: "9%",
                          borderRadius: 10,
                          borderColor: "white",
                          borderWidth: 1,
                          backgroundColor: "#f9f5ff",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 18,
                            color: "#464E5F",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                          }}
                        >
                          Advices Included
                        </Text>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "7%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Stock of the month (36)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Mutual Fund Pack (12)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Financial Plan Review (6)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Portfolio Review (6)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Adhoc Advices (12)
                          </Text>
                        </View>
                        {/* <Text
                          style={{
                            fontSize: 14,
                            color: "#8a6acc",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                            marginBottom: "10%",
                            fontStyle: "italic",
                          }}
                        >
                          View More 
                        </Text> */}
                      </View>
                      <Text
                        onPress={() => {
                          this.handleMakePayment(14400, "A3Y");
                        }}
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "bold",
                          fontSize: 15,
                          color: this.state.Plan3YColor,
                          backgroundColor: this.state.Plan3YBg,
                          borderColor: "#410DAA",
                          borderWidth: 1,
                          borderRadius: 4,
                          paddingVertical: 10,
                          textAlign: "center",
                          width: "82%",
                          marginLeft: "9%",
                          marginRight: "9%",
                          marginTop: "10%",
                          marginBottom: "10%",
                        }}
                        onMouseEnter={() => {
                          this.setState({
                            Plan3YColor: "white",
                            Plan3YBg: "#410DAA",
                          });
                        }}
                        onMouseLeave={() => {
                          this.setState({
                            Plan3YBg: "white",
                            Plan3YColor: "#410DAA",
                          });
                        }}
                      >
                        Subscribe
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
          </ScrollView>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1 },
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 50,
  },
  left: {
    flex: 0.4,
  },
  right: {
    flex: 1,
  },
  left_img: {
    resizeMode: "contain",
    width: 250,
    height: 250,
    marginTop: -40,
  },

  lock: {
    resizeMode: "contain",
    width: 30,
    height: 30,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 18 : 15,
    lineHeight: md(width) ? 21 : 17,
    color: "#2F3044",
  },
  terms1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 22 : 18,
    lineHeight: md(width) ? 25 : 20,
    color: "#2F3044",
    display: md(width) ? "none" : "flex",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 15 : 12,
    lineHeight: 17,
    color: "#464E5F",
    paddingTop: 12,
    paddingBottom: md(width) ? 32 : 26,
    paddingRight: md(width) ? 150 : 11,
  },
  please1: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 16 : 12,
    lineHeight: 18,
    color: "#464E5F",
    paddingTop: isMobile ? 11 : 0,
    paddingBottom: md(width) ? 32 : 26,
    paddingRight: md(width) ? 170 : 11,
    display: md(width) ? "none" : "flex",
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 100,
  },
  add1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 14 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    paddingBottom: 6.35,
  },
  inptxt: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 12 : 13,
    lineHeight: md(width) ? 14 : 15,
    color: "#121212",
    paddingVertical: isMobile ? 8 : 13.5,
    paddingHorizontal: 18.75,
    backgroundColor: "#f6f6f6",
    borderRadius: 4,
  },
  max: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  res: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 16 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    paddingBottom: 13.29,
  },
  drop: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#121212",
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 18,
    marginTop: 20,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  next: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    fontWeight: "bold",
    paddingVertical: 12,
    paddingHorizontal: 50,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  view_sample: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#8950FC",
    paddingBottom: md(width) ? 5 : 10,
  },
  addressProof: {
    fontSize: 11,
    color: "#121212",
    marginBottom: 14,
    fontWeight: "700",
  },
  fileName: {
    fontSize: 12,
    marginTop: 5,
    color: "#410DAA",
    fontWeight: "bold",
  },
  support: {
    fontSize: 10,
    color: "#121212",
    fontStyle: "italic",
    marginTop: 4,
  },
  error: { color: "red" },
  rowContianer: {
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 28,
  },
  marginRight: {
    marginRight: isMobile ? 0 : 29,
  },
  rowItem: {
    flex: 0.5,
  },
});
