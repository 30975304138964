import React from "react";
import { View, Text, StyleSheet, TouchableOpacity, Image } from "react-native";
import { Card } from "react-native-paper";
import ANTIcon from "@expo/vector-icons/AntDesign";
import theme from "../../../static/theme";
import { months_short_names, isMobile } from "../../../utils";

class OrderCard extends React.Component {
  render() {
    const { item = {}, index } = this.props;

    let date_time = item.created;
    let order_date = date_time;
    if (date_time) {
      let date_time_values = date_time.split(" ");
      if (date_time_values.length > 0) {
        let date = date_time_values[0].split("/");
        const month = months_short_names(date[1]);
        order_date = date[0] + "-" + month + "-" + date[2];
      }
    }
    return (
      <Card mode="outlined" style={styles.cardContainer}>
        <Card.Content>
          <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
            <Image
              style={{
                width: 40,
                height: 40,
                resizeMode: "contain",
              }}
              source={{ uri: item.amc_logo }}
            />
            <View style={{ flex: 0.75 }}>
              <Text
                style={{
                  color: "black",
                  alignSelf: "flex-start",
                  fontSize: 15,
                  width: "80%",
                  paddingLeft: 10,
                }}
              >
                {item.name}
              </Text>
            </View>
            <View
              style={{ flex: 0.25, marginRight: 8, alignItems: "flex-end" }}
            >
              <View style={{ alignItems: "center", justifyContent: "center" }}>
                <Text
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 13,
                    textAlign: "center",
                  }}
                >
                  {item.amount_new}
                </Text>
                <Text
                  style={{
                    color: theme.colors.GREY,
                    fontSize: 11,
                    marginTop: 5,
                    textAlign: "center",
                  }}
                >
                  {item &&
                  item.fund_details &&
                  item.fund_details.transaction_type
                    ? item.fund_details.transaction_type
                    : item.transaction_type == "P"
                    ? "Investment"
                    : item.transaction_type == "R"
                    ? "Withdrawal"
                    : item.transaction_type == "X"
                    ? "SIP Investment"
                    : "Switch"}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 4,
              marginTop: 10,
            }}
          >
            <Text style={{ fontSize: 13, color: "#A8A8A8" }}>Order No. : </Text>
            <Text style={{ fontSize: 13, color: "#121212" }}>
              {item.bse_order_number}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 4,
            }}
          >
            <View
              style={{ flex: 0.6, flexDirection: "row", alignItems: "center" }}
            >
              <Text style={{ fontSize: 13, color: "#A8A8A8" }}>
                Order Date :{" "}
              </Text>
              <Text style={{ fontSize: 13, color: "#121212" }}>
                {order_date}
              </Text>
            </View>
            <View style={{ flex: 0.4, alignItems: "flex-end" }}>
              <TouchableOpacity
                rippleContainerBorderRadius={6}
                style={{
                  backgroundColor: theme.colors.NEW_BRAND_COLOR,
                  borderRadius: 6,
                  alignItems: "center",
                  justifyContent: "center",
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                }}
                onPress={() => {
                  this.props.navigation.navigate("OrderDetails", {
                    transaction_id: item.transaction_pk,
                  });
                }}
              >
                <ANTIcon
                  name={"downcircleo"}
                  style={{ fontSize: 14, color: "#fff" }}
                />
              </TouchableOpacity>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 4,
            }}
          >
            <Text style={{ fontSize: 13, color: "#A8A8A8" }}>Status : </Text>
            <Text style={{ fontSize: 13, color: theme.colors.NEW_BRAND_COLOR }}>
              {item.status_message}
            </Text>
          </View>
        </Card.Content>
      </Card>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    marginBottom: 10,
    marginTop: 10,
    width: isMobile ? "90%" : "50%",
    marginLeft: isMobile ? "5%" : "25%",
    marginRight: isMobile ? "5%" : "25%",
  },
});

export default OrderCard;
