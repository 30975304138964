import React from "react";
import { StyleSheet, TouchableOpacity, View, Image } from "react-native";
import { Text } from "react-native-paper";
import { isMobile } from "../../utils";
import amplitude from "../../utils/amplitude";

const ArrowIcon = require("../../assets/JamaInvest/Arrow-right2x.png");

export const BookAdvisorStrip = ({ style = {}, navigation }) => {
  return (
        <TouchableOpacity
          onPress={() => {
            amplitude.logEvent("Book_Advisor_Button_Clicked");
            navigation.navigate("Dashboard", {
              screen: "ScheduleCall",
              params: { fromDashboard: true },
            });
          }}
        >
          <View style={{ paddingTop: 15, paddingBottom: 15 }}>
          <View
            style={[style, styles.container, isMobile && styles.mobileContainer]}
          >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View>
              <Text
                style={{
                  color: "#410DAA",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Book Advisor Meeting
              </Text>
            </View>
            <View>
              <Image
                source={ArrowIcon}
                style={[
                  { width: !isMobile ? 18 : 16, height: !isMobile ? 18 : 16 },
                ]}
              ></Image>
            </View>
          </View>
          </View>
    </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    overflow: "hidden",
    height: "100%",
    backgroundColor: "white",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#410DAA",
    paddingBottom: 15,
    paddingTop: 13,
    paddingHorizontal: 20,
    width: isMobile ? "100%" : "100%",
  },
  mobileContainer: {
    flex: 1,
  },
  subText: {
    fontSize: 12,
    fontWeight: "400",
    paddingTop: 5,
    color: "#464E5F",
    fontFamily: "Roboto",
  },
});
