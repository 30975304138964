import React from "react";
import { StyleSheet, View, Image, Linking, TouchableOpacity, Dimensions } from "react-native";
import { Text } from "react-native-paper";
import { sm, md, lg, xl, twoxl } from "../../Responsive";
import global from "./globalStyles";

const window = Dimensions.get("window");

export default function Card({ imgSource, sub1, sub2, link, isLast }) {
    let img = null;
    if (sub1 === "ISO 9001 Certified") {
        img = require("../../assets/upgradePlan/Landing/iso9001.png");
    }
    if (sub1 === "ISO 27001 Certified") {
        img = require("../../assets/upgradePlan/Landing/iso27001.png");
    }
    if (sub1 === "GoI DIPP Registered") {
        img = require("../../assets/upgradePlan/Landing/DIPP.png");
    }
    if (sub1 === "SEBI Registered Investment Advisors") {
        img = require("../../assets/upgradePlan/Landing/SEBI.png");
    }
    return (
        <>
            <View style={styles.container}>
                <Image source={img} style={styles.img}></Image>
                <Text style={[styles.sub1, global.arial]}>{sub1}</Text>
                {sub2.map((s, i) => (
                    <Text style={[styles.sub2, global.arial]} key={i}>
                        {s}
                    </Text>
                ))}
                {link && (
                    <TouchableOpacity onPress={() => Linking.openURL(link.url)}>
                        <Text style={[styles.link, global.arial]}>{link.text}</Text>
                    </TouchableOpacity>
                )}
            </View>
            {!isLast && <View style={styles.hr}></View>}
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingTop: !sm(window.width) ? 40 : undefined,
        paddingBottom: !sm(window.width) ? 40 : undefined,
        paddingLeft: !sm(window.width) ? 40 : undefined,
        paddingRight: !sm(window.width) ? 40 : undefined,
        flexShrink: 1
    },
    img: {
        width: 140,
        height: 73,
        alignSelf: "center",
        marginBottom: 6.49,
        marginTop: 6.49,
        resizeMode: "contain"
    },
    sub1: {
        textAlign: "center",
        fontSize: 13,
        fontWeight: "bold",
        marginTop: 7.39,
        color: "black"
    },
    sub2: {
        textAlign: "center",
        fontSize: 13,
        marginTop: 5,
        color: "black"
    },
    link: {
        textAlign: "center",
        color: "#276FC4",
        marginTop: 12
    },
    hr: {
        height: md(window.width) ? undefined : 1,
        backgroundColor: "#CCC",
        width: md(window.width) ? 1 : undefined,
        marginTop: md(window.width) ? undefined : 30
    }
});


