import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  Image,
  Picker,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from "react-native";
import { md } from "../../../Responsive";
import amplitude from "../../../utils/amplitude";
import NavigationHeader from "../../Common/NavigationHeader";
import {
  getSipMandate,
  createOrderWithFirstInstallment,
  getCustomerReport,
  getUrlForClient2FA,
  createActionItemForMFOrders,
} from "../../../api";
import { getUserDetails, isMobile } from "../../../utils";
const { width, height } = Dimensions.get("window");
const isWeb = md(width);
import { Card, Button } from "react-native-paper";
import { ShowToast } from "../../Common/Toast";
import moment from "moment";
import PlacePaymentAndOTP from "../../Common/PlacePaymentAndOTP";
import Store from "../../../store";
import { StackActions } from "@react-navigation/routers";

const FundField = ({ style, name, value }) => {
  return (
    <View style={{ ...style }}>
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            fontSize: 13,
            marginTop: -10,
            color: "#6C7293",
            fontWeight: "600",
          }}
        >
          {name}{" "}
          <Text style={{ fontSize: 13, color: "#464E5F", fontWeight: "700" }}>
            {"\u20B9" + " " + value}
          </Text>
        </Text>
      </View>
    </View>
  );
};

export default class SelectMandate extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    // console.log(".....params", params);
    let userId = null;
    let fundPK = null;
    let showNewMandateToast = null;
    let payload = null;
    if (params) {
      showNewMandateToast = params.showToast || null;
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      fundPK = params.fund;
      payload = params.payload ? params.payload : null;
      // console.log(".......payload", payload);
    }

    this.state = {
      userId,
      fundPK,
      payload,
      errorMsg: false,
      fund: fundPK,
      key: "",
      activeTabIndex: 0,
      isLoading: true,
      filters: [],
      mfDetails: [],
      mfTxnDetails: [],
      mfFundTxnDetails: [],
      mfAMCFundsSchemes: [],
      isSwitchOn: false,
      selectedAction: "Start_SIP",
      value: "",
      amount_error: "",
      installment_error: "",
      date_error: "",
      selecteddate: "select",
      install: "",
      year: 5,
      selectedScheme: "",
      selectedSchemeDetails: {},
      selectedfrequency: "monthly",
      sipvalue: 500,
      sipyear: 5,
      options: [],
      autoDebitCheckBox: true,
      placefirstinstallmentbool: true,
      showToast: false,
      storedSIPDetails: {},
      mandates: [],
      mandates_count: 0,
      mandate_data: {},
      selected_mandate_id: "",
      pendingModalVisible: false,
      showNewMandateToast,
      isLoading2: true,
      isLoading3: false,
      isLoading4: false,
      btnText: "Open OTP Link",
      purchase_2FA_url: null,
      purchase_url: null,
      total_count: 0,
      recd_count: 0,
      toastText: "",
      mf_recommend_set_no: "",
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("selectmandate", { path: global.location.href });
      } else {
        amplitude.logEvent("selectmandate", {
          path: this.props.navigation.state.routeName,
        });
      }
      let storedSIPDetails = {};
      storedSIPDetails = this.state.payload;

      let userData = await getUserDetails();

      this.setState({ storedSIPDetails, userData: userData });
      this._get_mandate_for_sip(
        storedSIPDetails.amount,
        storedSIPDetails.sip_start_date
      );
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
        toastText: "Failed in getting mandates.",
      });
    }
  }

  async checkOTPStatus() {
    try {
      setTimeout(async () => {
        const payload = {
          previous_key: "purchase_2FA_check",
          param: this.state.mf_recommend_set_no,
          customer_id: this.state.userData.customer_id,
        };
        let total_count = 0;
        let recd_count = 0;
        let resp = await getCustomerReport(payload);
        if (resp && resp.code && resp.code == "100") {
          total_count = resp.result[0].total_count;
          recd_count = resp.result[0].recd_count;
          if (recd_count > 0) {
            this.setState({
              isLoading2: false,
              isLoading3: false,
              isLoading4: true,
              loaderText:
                total_count > recd_count
                  ? `Total Orders: ${total_count}, Approved Orders: ${recd_count}`
                  : "Congratulations! Orders approved via OTP!",
              btnText: "BSE Payment Link",
              purchase_2FA_url: this.state.purchase_2FA_url,
              purchase_url: this.state.purchase_url,
              total_count,
              recd_count,
            });
            this.checkOTPStatus();
          } else {
            this.checkOTPStatus();
          }
        }
      }, 5000);
    } catch (e) { }
  }

  setModalMethod = (method) => {
    this.openOrCloseModal = method;
  };

  async _get_mandate_for_sip(amount, day) {
    getSipMandate({
      amount: amount,
      day_of_month: day,
      is_aadhar_linked_to_mobile: "not_required",
    }).then((response) => {
      if (response && response.code && response.code == "100") {
        if (
          response.mandates.filter((mandate) => mandate.mandate_id).length > 0
        ) {
          let tempArr = response.mandates.filter(
            (mandate) => mandate.mandate_id
          );
          let tempArr1 = tempArr.filter((mandate) => mandate.status == "APR");
          let tempArr2 = tempArr.filter((mandate) => mandate.status != "APR");
          tempArr = [...tempArr1, ...tempArr2];
          this.setState({
            mandates: tempArr,
            mandates_count: response.mandates.filter(
              (mandate) => mandate.mandate_id
            ).length,
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      } else {
        this.setState({ isLoading: false, showToast: true });
      }
    });
  }

  amountValidation = () => {
    const { value } = this.state;
    let amount_error = "";

    if (
      this.state.mfFundTxnDetails &&
      this.state.mfFundTxnDetails.bse_sip_minimum_installment_amount_monthly
    ) {
      if (
        Number(value) <
        this.state.mfFundTxnDetails.bse_sip_minimum_installment_amount_monthly
      ) {
        amount_error = "Please enter minimum Amount ";
      }
    } else {
      if (Number(value) < 500) {
        amount_error = "Please enter minimum Amount ";
      }
    }
    this.setState({
      amount_error,
    });
  };

  installmentValidation = () => {
    if (isNaN(this.state.install)) {
      this.setState({
        installment_error: "Please enter valid number of installments",
      });
    }

    if (this.state.install < 12) {
      this.setState({
        installment_error: "Number of installment should be greater than 12",
      });
    }
  };

  validateData = () => {
    if (this.state.value == "") {
      this.setState({
        amount_error: "Please enter minimum Amount ",
      });
      return false;
    }

    if (
      this.state.mfFundTxnDetails &&
      this.state.mfFundTxnDetails.bse_sip_minimum_installment_amount_monthly
    ) {
      if (
        Number(this.state.value) <
        this.state.mfFundTxnDetails.bse_sip_minimum_installment_amount_monthly
      ) {
        this.setState({
          amount_error: "Please enter minimum Amount ",
        });
        return false;
      }
    } else {
      if (Number(this.state.value) < 500) {
        this.setState({
          amount_error: "Please enter minimum Amount ",
        });
        return false;
      }
    }

    if (this.state.selecteddate == "select") {
      this.setState({
        date_error: "* Please select a start date ",
      });
      return false;
    }

    if (isNaN(this.state.install)) {
      this.setState({
        installment_error: "Please enter valid number of installments",
      });
      return false;
    }

    if (this.state.install < 12) {
      this.setState({
        installment_error: "Number of installment should be greater than 12",
      });
      return false;
    }

    return true;
  };

  getStartDate = () => {
    let date = Number(this.state.storedSIPDetails.sip_start_date);

    if (date > parseInt(moment().format("DD"))) {
      date <= 9 ? (date = "0" + date.toString()) : null;
      return moment().format("YYYY") + "-" + moment().format("MM") + "-" + date;
    } else {
      date <= 9 ? (date = "0" + date.toString()) : null;
      if (moment().format("MM") == 12) {
        return 1 + parseInt(moment().format("YYYY")) + "-" + "01" + "-" + date;
      } else {
        return parseInt(moment().format("YYYY")) + "-" + "01" + "-" + date;
      }
    }
  };

  placeOrderWithFirstInstallment = async (mandate_data) => {
    try {
      this.setState({
        showToast: "false",
        isLoading: true,
        isLoading2: true,
        loaderText: `Mutual Fund orders must be approved with OTP. Loading your SMS OTP approval page...`,
      });
      const dateString = this.getStartDate();

      const payload = {
        "cai_type": "SIPS",
        "customer_id": this.state.userData.customer_id,
        "mf_orders": [
          {
            "pk": this.state.storedSIPDetails.scheme,
            "quantity": Number(this.state.storedSIPDetails.amount),
            "no_of_installments": this.state.storedSIPDetails.sip_no_of_installments,
            "selected_date": dateString,
            "sip_first_order_flag": "N",
            "selected_mandate": Number(mandate_data.mandate_id)
          }
        ],
      }

      const response = await createActionItemForMFOrders(payload);
      if (response && response.code == 100) {
        this.setState({
          isLoading: false
        })
        this.props.navigation.navigate("App", {
          screen: "Explore",
          params: {
            screen: "MfOtpAuthentication",
            params: {}
          }
        })
      }

      // if (this.state.userData.customer_id == 39762) {
      //   const payload = {
      //     "cai_type": "SIPS",
      //     "customer_id": this.state.userData.customer_id,
      //     "mf_orders": [
      //       {
      //         "pk": this.state.storedSIPDetails.scheme,
      //         "quantity": Number(this.state.storedSIPDetails.amount),
      //         "no_of_installments": this.state.storedSIPDetails.sip_no_of_installments,
      //         "selected_date": dateString,
      //         "sip_first_order_flag": "N",
      //         "selected_mandate": Number(mandate_data.mandate_id)
      //       }
      //     ],
      //   }

      //   const response = await createActionItemForMFOrders(payload);
      //   if (response && response.code == 100) {
      //     this.setState({
      //       isLoading: false
      //     })
      //     this.props.navigation.navigate("App", {
      //       screen: "Explore",
      //       params: {
      //         screen: "MfOtpAuthentication",
      //         params: {}
      //       }
      //     })
      //   }
      // } else {
      //   const key = moment().unix();
      //   this.setState({
      //     key,
      //   });
      //   const payload = {
      //     orders: [
      //       {
      //         scheme: this.state.storedSIPDetails.scheme,
      //         amount: Number(this.state.storedSIPDetails.amount),
      //         sip_frequency_type: this.state.storedSIPDetails.sip_frequency_type,
      //         sip_start_date: dateString,
      //         sip_no_of_installments:
      //           this.state.storedSIPDetails.sip_no_of_installments,
      //         investmentType: "sip",
      //         sip_first_order_flag: "N",
      //         sip_mandate: Number(mandate_data.mandate_id),
      //       },
      //     ],
      //     redirection_url:
      //       Platform.OS == "web"
      //         ? global.location.origin + "/dashboard/process/" + key
      //         : "",
      //   };

      //   // this.setState({
      //   //   isLoading: true,
      //   // });

      //   const response = await createOrderWithFirstInstallment(payload);
      //   if (response && response.code && response.code == 100) {
      //     const { navigate_to, code, nav_props, ...restData } = response;
      //     Store.dispatch({
      //       type: "ADD_ACTION",
      //       payload: {
      //         key: key,
      //         data: {
      //           ...restData,
      //           ...nav_props,
      //         },
      //       },
      //     });
      //     if (response.success > 0) {
      //       const { purchase_2FA_url, mf_recommend_set_no } = response;
      //       const { url } = nav_props;
      //       this.setState({
      //         purchase_2FA_url,
      //         purchase_url: url,
      //         isLoading2: false,
      //         loaderText: `Mutual Fund orders must be approved with OTP. Continue to SMS OTP approval page.`,
      //         mf_recommend_set_no: mf_recommend_set_no,
      //       });
      //     } else {
      //       this.props.navigation.navigate("App", {
      //         screen: "Dashboard",
      //         params: {
      //           screen: "Dashboard",
      //           params: { screen: "ProcessOrder", params: { action_id: key } },
      //         },
      //       });
      //     }
      //   } else {
      //     this.setState({
      //       isLoading2: false,
      //     });
      //     if (response.navigate_to == "ShowCreatedOrders") {
      //       this.props.navigation.navigate("App", {
      //         screen: "Dashboard",
      //         params: {
      //           screen: "Dashboard",
      //           params: {
      //             screen: "ProcessOrder",
      //             params: { action_id: key },
      //           },
      //         },
      //       });
      //     }
      //   }
      // }
      // if (response) {
      //   const { navigate_to, code, nav_props, ...restData } = response;
      //   Store.dispatch({
      //     type: "ADD_ACTION",
      //     payload: {
      //       key: key,
      //       data: {
      //         ...restData,
      //         ...nav_props,
      //       },
      //     },
      //   });
      //   if (response.code && response.code == 100) {
      //     if (response.success > 0) {
      //       if (response.navigate_to == "ShowBankAccount") {
      //         this.props.navigation.navigate("App", {
      //           screen: "Dashboard",
      //           params: {
      //             screen: "Explore",
      //             params: {
      //               screen: "ShowBankAccount",
      //               params: {
      //                 action: key,
      //               },
      //             },
      //           },
      //         });
      //         return;
      //       }
      //       if (Platform.OS == "web") {
      //         global.open(response.nav_props.url, "_self");
      //       } else {
      //         this.setState({
      //           isLoading: false,
      //         });
      //         this.props.navigation.navigate("App", {
      //           screen: "Dashboard",
      //           params: {
      //             screen: "Portfolio",
      //             params: {
      //               screen: "ShowWebView",
      //               params: {
      //                 url: response.nav_props.url,
      //                 successURL: "/payment/success",
      //                 onSuccess: function (navigation, results = {}) {
      //                   navigation.push("App", {
      //                     screen: "Dashboard",
      //                     params: {
      //                       screen: "ProcessOrder",
      //                       params: { action_id: key },
      //                     },
      //                   });
      //                 },
      //                 onError: function (navigation, results = {}) {
      //                   // navigation.push("App", { screen: "Dashboard", params: { screen: "Home", params: {} } });
      //                 },
      //               },
      //             },
      //           },
      //         });
      //       }
      //     } else {
      //       this.setState({
      //         isLoading: false,
      //       });
      //       if (response.navigate_to == "ShowCreatedOrders") {
      //         this.props.navigation.navigate("App", {
      //           screen: "Dashboard",
      //           params: {
      //             screen: "Dashboard",
      //             params: {
      //               screen: "ProcessOrder",
      //               params: { action_id: key },
      //             },
      //           },
      //         });
      //       }
      //     }
      //   }
      // }
    } catch (error) {
      this.setState({ isLoading: false, showToast: true });
    }
  };

  onSchemeChange = (value) => {
    this.setState({
      selectedScheme: value,
    });
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  getNumIndianFormat = (num) => {
    if (!num) {
      return "-";
    }
    var str = num.toString();
    var final = "";
    if (str.length > 3) {
      final = "," + str.substring(str.length - 3, str.length);
      str = str.substring(0, str.length - 3);
      while (str.length > 2) {
        final = "," + str.substring(str.length - 2, str.length) + final;
        str = str.substring(0, str.length - 2);
      }
      final = str + final;
    } else {
      return num.toString();
    }
    return final;
  };

  convert_crore_to_lac(num) {
    var crore = parseInt(1000000);
    var lakh = parseInt(100000);
    if (num > crore) {
      var value = num / lakh;
      value = parseFloat(value).toFixed(2) + " Lac";
      return "\u20B9 " + value;
    } else {
      var final_val = this.formatNumber(num);
      return "\u20B9 " + final_val;
    }
  }

  formatNumber(num) {
    var str = num.toString();
    var final = "";
    if (str.length > 3) {
      final = "," + str.substring(str.length - 3, str.length);
      str = str.substring(0, str.length - 3);
      while (str.length > 2) {
        final = "," + str.substring(str.length - 2, str.length) + final;
        str = str.substring(0, str.length - 2);
      }
      final = str + final;
    } else {
      return num.toString();
    }
    return final;
  }

  calculate_sip_future_value(
    installment_amount,
    number_of_installments,
    yearly_returns
  ) {
    var returns_assumed = yearly_returns / 12;
    var total = 0;
    var installment_amount = parseInt(installment_amount) || 0;
    var number_of_installments = parseInt(number_of_installments) || 0;
    for (var i = 0; i < number_of_installments; i++) {
      total = total + installment_amount;
      total = total * (1 + returns_assumed);
    }
    return total;
  }

  calculate_future_value(amount, no_of_years, returns) {
    if (returns) {
      var return_perc = 1 + returns / 100;
      var return_pow = Math.pow(return_perc, no_of_years);
      var final_value = amount * return_pow;
      final_value = Math.round(final_value);
      return final_value;
    } else {
      return 0;
    }
  }

  transactionStatus = (transactionType) => {
    let type = "";
    switch (transactionType) {
      case "P":
        type = "Purchase";
        break;

      case "R":
        type = "Redemption";
        break;

      case "SI":
        type = "Switch In";
        break;

      case "SO":
        type = "Switch Out";
        break;

      default:
        break;
    }
    return type;
  };

  months_full = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  months_short_names = (index, full = false) => {
    if (!full) {
      return index <= 12 ? this.months[index - 1] : null;
    } else return index <= 12 ? this.months_full[index - 1] : null;
  };

  dateFormat = (date, delimeter = "-") => {
    let values = date.split(`${delimeter}`);
    return (
      values[2] +
      "-" +
      this.months_short_names(parseInt(values[1])) +
      "-" +
      values[0]
    );
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  getMandateStatusNames = (code) => {
    switch (code) {
      case "PND":
        return "Pending registration with Bank";
      case "SLF":
        return "Registered by user at bank";
      case "REC":
        return "Received by Exchange. Takes 3-4 working days to Approve";
      case "APR":
        return "Approved";
      case "REJ":
        return "Rejected";
      case "CAN":
        return "Cancelled by Investor";
      case "RNB":
        return "Rejection at NPCI prior to Destination Bank";
      case "RBB":
        return "Rejected by sponsor bank";
      default:
        return null;
    }
  };

  _render_mandate_card = ({ item: mandate_data }) => {
    let bankAccount = mandate_data.bank_account_no;
    bankAccount = "XXXX" + bankAccount.slice(-4);

    return (
      <View
        style={{ marginVertical: 10, display: "flex", flexDirection: "row" }}
      >
        <TouchableOpacity
          onPress={() => {
            if (mandate_data.status != "APR") {
              this.setState({ mandate_data, pendingModalVisible: true });
            } else {
              this.placeOrderWithFirstInstallment(mandate_data);
            }
          }}
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-start",
            width: "50%",
            borderRadius: 10,
            borderWidth: 1,
            borderColor: "#410DAA1A",
            padding: 10,
          }}
        >
          <View
            style={{
              flex: 0.2,
              alignItems: "flex-start",
              // marginTop: -36,
              marginLeft: -6,
            }}
          >
            <Image
              source={{ uri: mandate_data.bank_logo }}
              style={{
                alignSelf: "center",
                width: 70,
                height: 70,
                resizeMode: "contain",
              }}
            />
          </View>
          <View style={{ flex: 0.8 }}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text
                style={{
                  flex: 1,
                  fontSize: 14,
                  fontWeight: "500",
                  color: "#121212",
                }}
              >
                {mandate_data.bank_name}
              </Text>
              <Text
                style={{ fontSize: 12, fontWeight: "500", color: "#939393" }}
              >
                AC/NO {bankAccount}
              </Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text
                style={{
                  // color: "#410DAA",
                  fontSize: 12,
                  paddingTop: 6,
                  flex: 1,
                  fontWeight: "500",
                }}
              >
                Mandate: {mandate_data.mandate_id}
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: "500",
                  color: "#121212",
                  fontWeight: "bold",
                }}
              >
                &#8377;{mandate_data.amount}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                fontWeight: "500",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  // color: "#410DAA",
                  fontSize: 12,
                  paddingTop: 6,
                  flex: 1,
                  fontWeight: "500",
                }}
              >
                Status:{" "}
                <Text style={{ color: "#121212", fontSize: 12 }}>
                  {" "}
                  {this.getMandateStatusNames(mandate_data.status)}
                </Text>
              </Text>
            </View>
          </View>
        </TouchableOpacity>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-end",
            width: "50%",
            padding: 10,
          }}
        ></View>
      </View>
    );
  };

  handleCreateMandateAndContinue = () => {
    this.props.navigation.navigate("CreateMandate", {
      fund: this.state.fundPK,
      payload: this.state.payload,
    });
  };

  handleContinue = () => {
    if (this.state.recd_count === 0) {
      this.setState({
        errorMsg: true,
      });
    } else {
      this.props.navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Dashboard",
          params: {
            screen: "ProcessOrder",
            params: { action_id: this.state.key },
          },
        },
      });
    }
  };

  renderFooterComponent = () => {
    if (this.state.mandates_count > 2) {
      return (
        <Button
          uppercase={false}
          mode={"outline"}
          onPress={this.handleCreateMandateAndContinue}
          style={{
            width: isMobile ? "90%" : "40%",
            marginTop: 6,
            marginHorizontal: 16,
            paddingVertical: 8,
            backgroundColor: "#410DAA1A",
            alignSelf: "center",
          }}
          color={"#fff"}
        >
          <Text style={styles.createMandate}>
            Create New Mandate & Continue
          </Text>
        </Button>
      );
    }

    return <View />;
  };

  _render_mandate_card_mobile = ({ item: mandate_data }) => {
    let bankAccount = mandate_data.bank_account_no;
    bankAccount = "XXXX" + bankAccount.slice(-4);

    return (
      <View
        style={{
          marginVertical: 10,
          width: "90%",
          marginLeft: "5%",
          marginRight: "5%",
          borderRadius: 10,
          borderWidth: 1,
          borderColor: "#410DAA1A",
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-start",
            width: "100%",
            padding: 10,
          }}
        >
          <View
            style={{
              flex: 0.2,
              alignItems: "flex-start",
              marginTop: -5,
              marginLeft: -13,
            }}
          >
            <Image
              source={{ uri: mandate_data.bank_logo }}
              style={{
                alignSelf: "center",
                width: 50,
                height: 50,
                resizeMode: "contain",
              }}
            />
          </View>
          <View style={{ flex: 0.8 }}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text
                style={{
                  flex: 1,
                  fontSize: 14,
                  fontWeight: "500",
                  color: "#121212",
                }}
              >
                {mandate_data.bank_name}
              </Text>
              <Text
                style={{ fontSize: 12, fontWeight: "500", color: "#939393" }}
              >
                AC/NO {bankAccount}
              </Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text
                style={{
                  // color: "#410DAA",
                  fontSize: 12,
                  paddingTop: 6,
                  flex: 1,
                  fontWeight: "500",
                }}
              >
                Mandate: {mandate_data.mandate_id}
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: "500",
                  color: "#121212",
                  fontWeight: "bold",
                }}
              >
                &#8377;{mandate_data.amount}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                fontWeight: "500",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  // color: "#410DAA",
                  fontSize: 12,
                  paddingTop: 6,
                  flex: 1,
                  fontWeight: "500",
                }}
              >
                Status:{" "}
                <Text style={{ color: "#121212", fontSize: 12 }}>
                  {" "}
                  {this.getMandateStatusNames(mandate_data.status)}
                </Text>
              </Text>
            </View>
          </View>
        </View>
        <TouchableOpacity
          onPress={() => this.placeOrderWithFirstInstallment(mandate_data)}
          style={{
            alignItems: "center",
            borderTopWidth: 0.8,
            borderTopColor: "#cdcdcd90",
            marginTop: 20,
            paddingVertical: 6,
          }}
        >
          <Text style={{ color: "#410DAA", fontSize: 14 }}>
            Select Mandate & Continue
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  handleOTPApproval = async () => {
    global.open(this.state.purchase_2FA_url, "_blank");
    this.setState({
      isLoading3: true,
      isLoading4: false,
      loaderText: "Waiting for SMS OTP approval of your orders...",
    });
    this.checkOTPStatus();
  };

  handleOTPPageNotOpen = async () => {
    let url_2fa = this.state.purchase_2FA_url;
    if (url_2fa.includes("http")) {
      global.open(url_2fa, "_blank");
    } else {
      const response = await getUrlForClient2FA();
      if (response && response.code == 100) {
        const url = response.result;
        global.open(url, "_blank");
      }
    }
  };

  handlePayment = async () => {
    global.open(this.state.purchase_url, "_self");
  };

  render() {
    const { error, errorText, isLoading } = this.state;

    var productList = [];
    let amount =
      this.state.mfFundTxnDetails &&
        this.state.mfFundTxnDetails.bse_sip_minimum_installment_amount_monthly
        ? this.getNumIndianFormat(
          this.state.mfFundTxnDetails
            .bse_sip_minimum_installment_amount_monthly
        )
        : 500;

    if (Object.keys(this.state.mfFundTxnDetails).length > 0) {
      if (this.state.mfFundTxnDetails && this.state.mfFundTxnDetails.schemes) {
        this.state.mfFundTxnDetails.schemes.map((item, index) => {
          if (item.is_direct_fund == true) {
            productList.push(
              <Picker.Item
                key={index}
                label={item.variant_name || item.bse_variant_name}
                value={item.amcfundscheme_id}
              />
            );
          }
        });
      }
    }

    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View style={{ flex: 1, maxWidth: 1500 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={[
              "Mutual Funds Overview",
              "Invest",
              "Select Mandate",
            ]}
            onPressBack={() => {
              this.props.navigation.replace("StartSIP", {
                fund: this.state.fundPK,
                selected_fund: this.state.payload.scheme,
              });
            }}
          />
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.showNewMandateToast == "true" ? (
              <ShowToast
                showToast={true}
                type="status"
                title="Please check your email id for completing the Bank Mandate Process."
                duration="10"
              />
            ) : null}
            {this.state.showToast && this.state.toastText ? (
              <ShowToast
                showToast={true}
                type="error"
                title={this.state.toastText}
              />
            ) : null}
            {
              // isLoading ? (
              //   <ActivityIndicator size="large" color="#410DAA" />
              // ) :
              error ? (
                <Text style={{ fontSize: 16, color: "#121212" }}>
                  {errorText || "Something went wrong!"}
                </Text>
              ) : this.state.isLoading ? (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {this.state.isLoading2 && (
                    <>
                      <ActivityIndicator size="large" color="#410DAA" />
                      <Text
                        style={{
                          marginTop: 20,
                          fontWeight: "bold",
                          fontSize: 17,
                        }}
                      >
                        {this.state.loaderText}
                      </Text>
                    </>
                  )}
                  {!this.state.isLoading2 && (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {this.state.isLoading2 && (
                        <>
                          <ActivityIndicator size="large" color="#410DAA" />
                          <Text
                            style={{
                              marginTop: 20,
                              fontWeight: "bold",
                              fontSize: 17,
                            }}
                          >
                            {this.state.loaderText}
                          </Text>
                        </>
                      )}
                      {!this.state.isLoading2 && (
                        <View style={{ flex: 1, width: "100%" }}>
                          <PlacePaymentAndOTP
                            handleOTPApproval={this.handleOTPApproval}
                            otploading={this.state.isLoading3}
                            showPayment={false}
                            error={this.state.errorMsg}
                            handleContinue={this.handleContinue}
                            handlePayment={this.handlePayment}
                            paymentLoading={this.state.isLoading4}
                            handleOTPPageNotOpen={this.handleOTPPageNotOpen}
                          />
                        </View>
                      )}
                    </View>
                  )}
                </View>
              ) : (
                <>
                  <ScrollView style={[styles.mainContainer]}>
                    {isMobile ? (
                      <>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "90%",
                            marginLeft: "5%",
                            marginRight: "5%",
                          }}
                        >
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              marginTop: 17,
                            }}
                          >
                            <View
                              style={{
                                flex: 1,
                                borderRadius: 10,
                                borderWidth: 1,
                                borderColor: "#410DAA1A",
                              }}
                            >
                              <View
                                style={{
                                  padding: 10,
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <View style={{ flex: 0.05 }}>
                                  <Image
                                    source={{
                                      uri: this.state.storedSIPDetails.amc_logo,
                                    }}
                                    resizeMode="contain"
                                    style={{
                                      height: 30,
                                      width: 30,
                                      alignSelf: "flex-start",
                                      borderWidth: 1,
                                      borderColor: "#f2f2f2",
                                      borderRadius: 5,
                                    }}
                                  />
                                </View>
                                <View
                                  style={{ flex: isMobile ? 0.05 : 0.01 }}
                                ></View>
                                <View style={{ flex: 0.84, paddingLeft: 35 }}>
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      color: "#464E5F",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {this.state.storedSIPDetails.scheme_name}
                                  </Text>
                                </View>
                                <View style={{ flex: 0.1 }}></View>
                              </View>
                              <View
                                style={{
                                  paddingHorizontal: 10,
                                  paddingVertical: 4,
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    paddingVertical: 9,
                                  }}
                                >
                                  <FundField
                                    style={{
                                      flex: 0.33,
                                      alignItems: "flex-start",
                                    }}
                                    name="Amount: "
                                    value={this.state.storedSIPDetails.amount}
                                  />
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>

                        {this.state.mandates_count > 0 ? (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: 20,
                              width: "90%",
                              marginLeft: "5%",
                              marginRight: "5%",
                            }}
                          >
                            <View style={{ flex: 2 }}>
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#464E5F",
                                  fontWeight: "bold",
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                {"Select Mandate"}
                              </Text>
                            </View>
                          </View>
                        ) : (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: 20,
                              width: "90%",
                              marginLeft: "5%",
                              marginRight: "5%",
                            }}
                          >
                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#464E5F",
                                  fontWeight: "bold",
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                No Mandate available for selected SIP day:{" "}
                                {this.state.storedSIPDetails.sip_start_date}
                              </Text>
                            </View>
                          </View>
                        )}
                        {this.state.mandates_count > 0 ? (
                          <>
                            <FlatList
                              data={this.state.mandates}
                              renderItem={this._render_mandate_card_mobile}
                              keyExtractor={(item, index) => index.toString()}
                              ListFooterComponent={this.renderFooterComponent}
                            />
                          </>
                        ) : (
                          <View
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                              marginTop: 25,
                              backgroundColor: "#ececec",
                              padding: 16,
                              borderRadius: 8,
                              width: isMobile ? "90%" : "50.2%",
                              marginRight: isMobile ? "5%" : "49.8%",
                              marginLeft: isMobile ? "5%" : "0%",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 14,
                                color: "#4A4A4F",
                                fontWeight: "bold",
                                textAlign: "center",
                                marginBottom: 20,
                              }}
                            >
                              Either
                              <Text
                                style={[
                                  styles.clickHere,
                                  {
                                    fontSize: 15,
                                    paddingTop: 20,
                                    textDecorationLine: "underline",
                                  },
                                ]}
                                onPress={() => {
                                  this.props.navigation.navigate("StartSIP", {
                                    fund: this.state.fundPK,
                                  });
                                }}
                              >
                                Click Here
                              </Text>{" "}
                              and select another day
                            </Text>

                            <Text
                              style={{
                                fontSize: 14,
                                color: "#4A4A4F",
                                fontWeight: "bold",
                                textAlign: "center",
                                marginBottom: 20,
                              }}
                            >
                              OR
                            </Text>
                            <TouchableOpacity
                              style={{
                                width: 230,
                                height: 35,
                                borderRadius: 20,
                                backgroundColor: "#410DAA1A",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onPress={() => {
                                this.props.navigation.navigate(
                                  "CreateMandate",
                                  {
                                    fund: this.state.fundPK,
                                    payload: this.state.storedSIPDetails,
                                  }
                                );
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 12,
                                  fontWeight: "500",
                                  color: "#410DAA",
                                }}
                              >
                                Create New Mandate & Continue
                              </Text>
                            </TouchableOpacity>
                          </View>
                        )}
                      </>
                    ) : null}
                    <View
                      style={{
                        paddingBottom: 40,
                        marginTop: isMobile ? -3 : -17,
                        paddingLeft: isWeb ? 32 : 0,
                        paddingRight: isWeb ? 32 : 0,
                        paddingTop: isWeb ? 30 : 10,
                      }}
                    >
                      {!isMobile ? (
                        <>
                          <View
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <View
                              style={{
                                flex: 1.01,
                                flexDirection: "row",
                                marginTop: 17,
                              }}
                            >
                              <View
                                style={{
                                  flex: 10,
                                  borderRadius: 10,
                                  borderWidth: 1,
                                  borderColor: "#410DAA1A",
                                }}
                              >
                                <View
                                  style={{
                                    padding: 10,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <View style={{ flex: 0.05 }}>
                                    <Image
                                      source={{
                                        uri: this.state.storedSIPDetails
                                          .amc_logo,
                                      }}
                                      resizeMode="contain"
                                      style={{
                                        height: 30,
                                        width: 30,
                                        alignSelf: "flex-start",
                                        borderWidth: 1,
                                        borderColor: "#f2f2f2",
                                        borderRadius: 5,
                                      }}
                                    />
                                  </View>
                                  <View
                                    style={{ flex: isMobile ? 0.05 : 0.01 }}
                                  ></View>
                                  <View style={{ flex: 0.84, paddingLeft: 35 }}>
                                    <Text
                                      style={{
                                        fontSize: 13,
                                        color: "#464E5F",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.storedSIPDetails.scheme_name}
                                    </Text>
                                  </View>
                                  <View style={{ flex: 0.1 }}></View>
                                </View>
                                <View
                                  style={{
                                    paddingHorizontal: 10,
                                    paddingVertical: 4,
                                  }}
                                >
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      paddingVertical: 9,
                                    }}
                                  >
                                    <FundField
                                      style={{
                                        flex: 0.33,
                                        alignItems: "flex-start",
                                      }}
                                      name="Amount: "
                                      value={this.state.storedSIPDetails.amount}
                                    />
                                  </View>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{
                                flex: 1,
                              }}
                            ></View>
                          </View>

                          {this.state.mandates_count > 0 ? (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 20,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Text
                                  style={{
                                    fontSize: 16,
                                    color: "#464E5F",
                                    fontWeight: "bold",
                                    marginTop: 10,
                                    marginBottom: 10,
                                    marginLeft: isMobile ? 20 : 0,
                                  }}
                                >
                                  {"Available Mandates (" +
                                    this.state.mandates_count +
                                    ")"}
                                </Text>
                              </View>
                              <View style={{ flex: 5.5, marginTop: 3 }}>
                                <TouchableOpacity
                                  style={{
                                    width: 230,
                                    height: 35,
                                    borderRadius: 20,
                                    backgroundColor: "#410DAA1A",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  onPress={() => {
                                    this.props.navigation.navigate(
                                      "CreateMandate",
                                      {
                                        fund: this.state.fundPK,
                                        payload: this.state.storedSIPDetails,
                                      }
                                    );
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 12,
                                      fontWeight: "500",
                                      color: "#410DAA",
                                    }}
                                  >
                                    Create New Mandate & Continue
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          ) : (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 20,
                              }}
                            >
                              <View style={{ flex: 1 }}>
                                <Text
                                  style={{
                                    fontSize: 16,
                                    color: "#464E5F",
                                    fontWeight: "bold",
                                    marginTop: 10,
                                    marginBottom: 10,
                                    marginLeft: isMobile ? 20 : 0,
                                  }}
                                >
                                  No Mandate available for selected SIP day:{" "}
                                  {this.state.storedSIPDetails.sip_start_date}
                                </Text>
                              </View>
                            </View>
                          )}

                          {this.state.mandates_count > 0 ? (
                            <Text
                              style={{
                                fontSize: 14,
                                color: "#121212",
                                paddingBottom: 4,
                                marginTop: 12,
                                fontWeight: "500",
                              }}
                            >
                              Select a mandate to continue
                            </Text>
                          ) : null}

                          {this.state.mandates_count > 0 ? (
                            <FlatList
                              data={this.state.mandates}
                              renderItem={this._render_mandate_card}
                              keyExtractor={(item, index) => index.toString()}
                            />
                          ) : (
                            <View
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                                marginTop: 25,
                                backgroundColor: "#ececec",
                                padding: 16,
                                borderRadius: 8,
                                width: "50.2%",
                                marginRight: "49.8%",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "#4A4A4F",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  marginBottom: 20,
                                }}
                              >
                                Either
                                <Text
                                  style={[
                                    styles.clickHere,
                                    {
                                      fontSize: 15,
                                      paddingTop: 20,
                                      textDecorationLine: "underline",
                                    },
                                  ]}
                                  onPress={() => {
                                    this.props.navigation.navigate("StartSIP", {
                                      fund: this.state.fundPK,
                                    });
                                  }}
                                >
                                  Click Here
                                </Text>{" "}
                                and select another day
                              </Text>

                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "#4A4A4F",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  marginBottom: 20,
                                }}
                              >
                                OR
                              </Text>
                              <TouchableOpacity
                                style={{
                                  width: 230,
                                  height: 35,
                                  borderRadius: 20,
                                  backgroundColor: "#410DAA1A",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onPress={() => {
                                  this.props.navigation.navigate(
                                    "CreateMandate",
                                    {
                                      fund: this.state.fundPK,
                                      payload: this.state.storedSIPDetails,
                                    }
                                  );
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "500",
                                    color: "#410DAA",
                                  }}
                                >
                                  Create New Mandate & Continue
                                </Text>
                              </TouchableOpacity>
                            </View>
                          )}
                        </>
                      ) : null}
                    </View>
                    {isMobile && <View style={{ marginTop: 35 }}></View>}
                  </ScrollView>
                  {this.state.pendingModalVisible ? (
                    <View
                      style={{
                        position: "absolute",
                        width: width,
                        height: height,
                        backgroundColor: "#00000050",
                        zIndex: 9999,
                        display: "flex",
                      }}
                    >
                      <Card
                        style={{
                          width: isMobile ? "90%" : "30%",
                          height: 250,
                          marginTop: 180,
                          marginLeft: isMobile ? "5%" : "27.5%",
                          marginRight: isMobile ? "5%" : "42.5%",
                          backgroundColor: "#fff",
                          borderRadius: 10,
                          borderRadius: 10,
                        }}
                      >
                        <Text style={styles.confirm}>Mandate Pending</Text>
                        <View
                          style={[
                            {
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: isMobile ? "7%" : 0,
                            },
                            styles.sureContainer,
                          ]}
                        >
                          <Text style={styles.sure}>
                            Please select APPROVED mandate to place an order.
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: isMobile ? -25 : 0,
                          }}
                        >
                          {/* <Button
                          uppercase={false}
                          mode="contained"
                          labelStyle={{
                            color: "#2D3748",
                            fontSize: 14,
                            fontWeight: "bold",
                            paddingHorizontal: isMobile ? 19 : 40,
                            paddingVertical: 5,
                          }}
                          color="#E2E8F0"
                          style={{ marginLeft: 12, marginRight: 12 }}
                          onPress={() => {
                            this.placeOrderWithFirstInstallment(
                              this.state.mandate_data
                            );
                          }}
                        >
                          Place Order
                        </Button> */}
                          <Button
                            uppercase={false}
                            mode="contained"
                            labelStyle={{
                              color: "#2D3748",
                              fontSize: 14,
                              fontWeight: "bold",
                              paddingHorizontal: isMobile ? 19 : 40,
                              paddingVertical: 5,
                            }}
                            color="#E2E8F0"
                            style={{ marginLeft: 12, marginRight: 12 }}
                            onPress={() => {
                              this.setState({ pendingModalVisible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </View>
                      </Card>
                    </View>
                  ) : null}
                </>
              )
            }
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  createMandate: {
    fontSize: 12,
    color: "#410DAA",
    paddingVertical: 12,
  },
  selectMandate: {
    fontSize: 12,
  },
  confirm: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 18,
    color: "#121212",
    marginBottom: 11,
    marginLeft: 25,
    marginTop: 25,
  },
  sureContainer: {
    marginBottom: 44,
  },
  sure: {
    fontFamily: "Roboto",
    fontSize: 15,
    color: "#121212",
  },
  image: { width: 19, height: 17, resizeMode: "contain", marginRight: 7 },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    width: "100%",
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  button_style: {
    padding: 2,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: "#410DAA",
    marginRight: 6,
    overflow: "hidden",
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  detailHeading: {
    color: "#121212",
    opacity: 0.5,
    fontStyle: "italic",
    fontSize: 11,
  },
  detailText: {
    color: "#121212",
    fontSize: 14,
    marginTop: 4,
    fontWeight: "bold",
  },
  clickHere: {
    color: "#410DAA",
    fontWeight: "bold",
    paddingLeft: 6,
    textAlign: "center",
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
  filterText: {
    fontSize: 15,
    color: "gray",
    fontWeight: "200",
  },
  filtersTitle: {
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 6,
    marginLeft: isMobile ? 16 : 0,
  },
});
