import React, {useRef, useState} from 'react';
import { StyleSheet, Text, View, Image, ScrollView, Platform, Dimensions,TouchableOpacity,TextInput,FlatList } from 'react-native';
import {sm, md, lg, xl, twoxl} from '../../../Responsive';
import { getUserDetails } from '../../../utils';
import Header from '../login_header';
const {width} = Dimensions.get("window");

export default class aadhar_based_e_sign extends React.Component {
    constructor(props){
        super(props);
        this.state={
            data: null,

        }
    }

    handleContinue = async () =>{
        if(!cust_id || !username){
            const userData = getUserDetails();
            username = userData.username;
            cust_id = userData.customer_id;
        }
        try {
            return;
            console.log("response",response);
            if(response){
                this.props.navigation.navigate("login",{})
                let {data} = response.result;
            }
            else{
                this.setState({
                    error: true,
                    errorText:  response  && response.msg ? response.msg : null
                }) 
            }
        } catch (error) {
           
            console.log("error",error)
            this.setState({
                error: true,
                errorText: "Something went wrong!"
            }) 
        }
        
       }
    render(){
         return (
                <>
                <Header navigation={this.props.navigation} route={this.props.route}/> 
                <ScrollView>
                 <View style={{justifyContent:"center", alignItems:"center",backgroundColor:"#ffffff",paddingBottom:md(width)?200:200,paddingHorizontal:md(width)?"undefined":19}}>
                
                 <Image source={{ uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/money_transfer_-2+1.png"}} style={styles.img} />
                 <Text style={styles.redirecting}>Redirecting to Digio to complete your Adhaar-based e-Sign process</Text>
                <TouchableOpacity >
                    <View>
                        <Text style={styles.cancel}>Cancel</Text>
                    </View>
                </TouchableOpacity>
                
               </View>
               </ScrollView>
                </>
             
         )
}
}
const styles = StyleSheet.create({
    container:{width: '100%', flexGrow: 1},
    img:{
        width:md(width)?474:252,
        height:md(width)?373:198,
    },
    redirecting:{
        fontFamily:"Roboto",
        fontSize:19,
        lineHeight:22,
        color:"#000000",
        paddingTop:39,
        paddingBottom:55
    },
    cancel:{
        fontFamily:"Roboto",
        fontSize:15,
        lineHeight:17,
        color:"#6C6D72"
    }
});