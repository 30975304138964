import React from "react";
import { View, Text, Dimensions } from "react-native";
import { md } from "../../Responsive";
const { width } = Dimensions.get("window");
const isWeb = md(width);
export default function PieChartLegend({ color, title, style = {} }) {
  return (
    <View
      style={[
        { alignSelf: "flex-start", flexDirection: "row", marginVertical: 7.5 },
        style,
      ]}
    >
      <View
        style={{
          width: 12,
          height: 12,
          borderRadius: 2,
          backgroundColor: color,
        }}
      />
      <Text
        style={{
          fontSize: isWeb ? 13 : 12,
          color: "#494B69",
          paddingLeft: 13,
          marginTop: -4,
        }}
      >
        {title}
      </Text>
    </View>
  );
}
