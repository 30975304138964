import React from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
} from "react-native";
import { Divider } from "react-native-paper";
import FieldValue from "../../components/FieldValue";
import DiscountText from "../../components/DiscountText";
import { isMobile, numberWithCommas } from "../../../../utils";

const BillHeading = ({ data = {} }) => {
  return (
    <View style={styles.billBasicDetailsContainer}>
      <View>
        <View style={styles.majorHeadingContainer}>
          <Text style={styles.majorHeadingText}>
            Direct Equity Advisory- {data["product_text"]} Plan{" "}
            {data["plan_code"]}
          </Text>
        </View>
        <View style={styles.descriptionContainer}>
          <Text style={styles.planNumber}>Plan No: {data["plan_number"]}</Text>
          <Text style={styles.feePercentageText}>
            {data["billing_frequency_text"]}:{" "}
            <Text style={styles.feePercentage}>
              {data["fee_perc"].toFixed(2)}%
            </Text>
          </Text>
        </View>
      </View>
      <View>
        <View style={styles.majorHeadingContainer}>
          <Text style={styles.majorHeadingText}>
            {data["bill_type"]}
          </Text>
        </View>
        <View style={styles.descriptionContainer}>
          <Text style={styles.planNumber}>{data["bill_period_text"]}</Text>
        </View>
      </View>
      <View>
        {data["discount"] != 0 && (
          <DiscountText discount={data["discount_perc"]} />
        )}
      </View>
    </View>
  );
};

const LP2 = ({ data = {}, ...props }) => {
  return (
    <ScrollView
      style={styles.container}
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={true}
    >
      <View style={styles.boxContainer}>
        <BillHeading data={data} />

        <View style={styles.auaContainer}>
          <FieldValue
            label={data["box1_field_name"]}
            value={"₹" + numberWithCommas(parseInt(data["committed_aua"]))}
            style={[styles.fieldItem]}
            valueStyle={[{ fontWeight: "bold" }]}
          />
        </View>

        <View style={styles.detailsContainer}>
          <View style={[styles.billContainer, styles.fieldItem]}>
            <View>
              <Text style={styles.advisoryHeading}>{data["bill_heading"]}</Text>
              {/* <Text style={styles.billingPeriod}>
                ({data["bill_period_text"]})
              </Text> */}
            </View>
            <View>
              <Text style={styles.feesAmount}>
                ₹{numberWithCommas(parseInt(data["bill"]))}
              </Text>
            </View>
          </View>
          <Divider />
          {data["igst"] ? (
            <FieldValue
              label={"IGST(" + data["igst"] + "%)"}
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          ) : (
            <FieldValue
              label={
                "CGST(" + data["cgst"] + "%) + SGST(" + data["sgst"] + "%)"
              }
              value={"₹" + numberWithCommas(parseInt(data["GST Amount"]))}
              style={[styles.fieldItem]}
            />
          )}
          <Divider />
          <FieldValue
            label={"Final Fee including GST"}
            value={"₹" + numberWithCommas(parseInt(data["Total Payment"]))}
            style={[styles.fieldItem]}
            labelStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
            valueStyle={[{ color: "#410DAA", fontWeight: "bold" }]}
          />
        </View>
        <Text style={styles.descriptionText}>
          Subsequent Advisory Fee has to be paid in six months from the Invoice
          date
        </Text>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  boxContainer: {
    borderWidth: 0,
    borderColor: "#410DAA50",
    borderRadius: 15,
    marginVertical: isMobile ? 0 : 28,
    marginHorizontal: isMobile ? 25 : 35,
    paddingVertical: 27,
    paddingHorizontal: isMobile ? 0 : 39,
    width: '100%',
    minWidth: 800,
  },
  billBasicDetailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  majorHeadingContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  majorHeadingText: {
    color: "#464E5F",
    fontSize: 17,
    fontWeight: "bold",
  },
  feePercentageText: {
    color: "#6C7293",
    fontSize: 13,
    marginLeft: 6,
  },
  feePercentage: {
    color: "#464E5F",
    fontSize: 13,
    fontWeight: "700",
    paddingLeft: 4,
  },
  descriptionContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 11,
  },
  descriptionText: {
    color: "#6C7293",
    fontSize: 12,
    marginTop: 14,
  },
  planNumber: {
    color: "#4A4B68",
    fontSize: 11,
    marginLeft: 0,
  },
  auaContainer: {
    marginTop: 27,
    borderWidth: 1,
    borderColor: "#DBC0FF",
    borderRadius: 10,
  },
  detailsContainer: {
    marginTop: 21,
    borderWidth: 1,
    borderColor: "#DBC0FF",
    borderRadius: 10,
  },
  billContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 17.5,
  },
  advisoryHeading: {
    color: "#464E5F",
    fontSize: 15,
    fontWeight: "bold",
  },
  billingPeriod: {
    color: "#464E5F",
    fontSize: 15,
    fontWeight: "400",
    marginTop: 10,
  },
  feesAmount: {
    fontSize: 15,
    color: "#464E5F",
    fontWeight: "bold",
  },
  viewDetailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  viewDetails: {
    color: "#6C7293",
    fontSize: 14,
    marginRight: 10,
  },
  fieldItem: {
    paddingHorizontal: 75,
  },
  committedAuaText: {
    fontSize: 15,
    color: "#464E5F",
  },
});

export default LP2;
