import React from "react";
import amplitude from "../../../utils/amplitude";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  Dimensions,
  ScrollView,
  Image,
  Picker,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { md } from "../../../Responsive";
import PlacePaymentAndOTP from "../../Common/PlacePaymentAndOTP";
import NavigationHeader from "../../Common/NavigationHeader";
import {
  getAMCFundSchemestaFn,
  getFundDetails,
  createOrderWithFirstInstallment,
  getCustomerReport,
  getUrlForClient2FA,
  uccCodeGenerate,
  createActionItemForMFOrders,
} from "../../../api";
import { completeMinDetails, getUserDetails, isMobile } from "../../../utils";
import { TextInput, HelperText, Button } from "react-native-paper";
import { ShowToast } from "../../../components/Common/Toast";
import moment from "moment";
import { StackActions } from "@react-navigation/routers";
import ComplianceModal from "../../InvestmentOverview/ComplianceModal";
import PickerFieldSelect from "../../Common/Pickers/PickerFieldSelect";
import { saveCustomerProfileData } from "../../../api/account";
import Store from "../../../store";

const { width } = Dimensions.get("window");
const isWeb = md(width);

const FundField = ({ style, name, value, tooltip }) => {
  return (
    <View style={{ ...style }}>
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: "#6C7293",
            fontWeight: "600",
          }}
        >
          {name}
        </Text>
        {tooltip && (
          <ANTIcon
            name="infocirlce"
            color={"#a8a8a8"}
            size={12}
            style={{
              paddingLeft: 10,
            }}
          />
        )}
      </View>
      <Text
        style={{
          fontSize: 12,
          color: "#464E5F",
          fontWeight: "700",
          paddingTop: 5,
        }}
      >
        {value}
      </Text>
    </View>
  );
};

export default class investlumpsum extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let userId = null;
    let fundPK = null;
    let add_invt = null;
    let selected_fund = null;
    let status = null;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      fundPK = params.fund;
      add_invt = params.add_invt || null;
      selected_fund = params.selected_fund;
      status = params.STATUS || null;
    }

    this.state = {
      userId,
      fundPK,
      status,
      default_selected_fund: undefined,
      selected_fund,
      add_invt,
      activeTabIndex: 0,
      isLoading: true,
      filters: [],
      mfFundTxnDetails: [],
      mfAMCFundsSchemes: [],
      isSwitchOn: false,
      value: "",
      amount_error: "",
      installment_error: "",
      frequencyarr: [],
      year: 5,
      selectedScheme: "",
      selectedSchemeDetails: {},
      selectedfrequency: "monthly",
      options: [],
      autoDebitCheckBox: true,
      placefirstinstallmentbool: true,
      showToast: false,
      showErrorToast: false,
      errorResponse: "",
      // isLoading: false,
      isLoading2: true,
      isLoading3: false,
      isLoading4: false,
      btnText: "Open OTP Link",
      purchase_2FA_url: null,
      purchase_url: null,
      total_count: 0,
      recd_count: 0,
      toastText: "",
      mf_recommend_set_no: "",
      showLoader: false,
      showBSESuccessToast: false,
      showBSEErrorToast: false,
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("investlumpsum", { path: global.location.href });
      } else {
        amplitude.logEvent("investlumpsum", {
          path: this.props.navigation.getState().routeName,
        });
      }
      if (this.state.status) {
        this.setState({ showBSESuccessToast: "true" });
        const { customer_id, customerprofile_id } = getUserDetails();
        const body = {
          customer: customer_id,
          bse_nomination_authentication_date: moment()
            .utcOffset("+05:30")
            .format(),
        };
        await saveCustomerProfileData(customerprofile_id, body);
      }
      let options = [];

      const { fundPK, userId } = this.state;
      const { params } = this.props.route;
      let skip_compliance = false;
      const customer_id = userId || null;
      let mfMinDetailsData = {};
      if (params) {
        skip_compliance = params.s || false;
      }

      const mfMinDetailsResponse = await completeMinDetails(
        true,
        customer_id,
        skip_compliance
      );
      if (mfMinDetailsResponse && !mfMinDetailsResponse.error) {
        mfMinDetailsData = mfMinDetailsResponse;
      }

      const fundTxnResponse = await getFundDetails({
        amcfund_id: fundPK,
      });
      const amcFundResponse = await getAMCFundSchemestaFn(fundPK);

      if (
        fundTxnResponse &&
        fundTxnResponse.results &&
        amcFundResponse &&
        amcFundResponse.results
      ) {
        const defaultScheme = amcFundResponse.results.filter((item) => {
          return (
            item.amcfundscheme_id ==
            fundTxnResponse.results.default_amcfundscheme_id
          );
        });

        const remainingSchemes = amcFundResponse.results.filter((item) => {
          return (
            item.amcfundscheme_id !==
            fundTxnResponse.results.default_amcfundscheme_id
          );
        });

        [...defaultScheme, ...remainingSchemes].forEach((scheme) => {
          options.push({
            value: scheme.amcfundscheme_id,
            label: scheme.name,
          });
        });

        let default_selected_fund;

        amcFundResponse.results.map((item) => {
          if (item.amcfundscheme_id == this.state.selected_fund) {
            default_selected_fund = item;
            this.setState({ default_selected_fund: item });
          }
        });

        let selectedScheme = {
          value: default_selected_fund.amcfundscheme_id,
          label: default_selected_fund.name,
        };

        this._getSchemeDetails(fundTxnResponse.results, fundPK);

        const { is_purchase_allowed, is_sip_allowed } = fundTxnResponse.results;

        let calculatorChosen = 0;
        if (is_purchase_allowed && is_sip_allowed) {
          calculatorChosen = 0;
        } else if (is_purchase_allowed && !is_sip_allowed) {
          calculatorChosen = 1;
        } else {
          calculatorChosen = 0;
        }

        let userData = await getUserDetails();

        this.setState({
          mfFundTxnDetails: fundTxnResponse.results,
          mfAMCFundsSchemes: amcFundResponse.results,
          is_purchase_allowed,
          is_sip_allowed,
          calculatorChosen,
          mfMinDetailsData: mfMinDetailsData || {},
          showComplianceAlert: mfMinDetailsData != null,
          gettingMinDetails: false,
          options,
          default_selected_fund,
          selectedScheme,
          isLoading: false,
          userData: userData,
        });
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Something went wrong!",
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  async checkOTPStatus() {
    try {
      setTimeout(async () => {
        const payload = {
          previous_key: "purchase_2FA_check",
          param: this.state.mf_recommend_set_no,
          customer_id: this.state.userData.customer_id,
        };
        let total_count = 0;
        let recd_count = 0;
        let resp = await getCustomerReport(payload);
        // console.log("getCustomerReport : " + JSON.stringify(resp));
        if (resp && resp.code && resp.code == "100") {
          total_count = resp.result[0].total_count;
          recd_count = resp.result[0].recd_count;
          if (recd_count > 0) {
            this.setState({
              isLoading2: false,
              isLoading3: false,
              isLoading4: true,
              loaderText:
                total_count > recd_count
                  ? `Total Orders: ${total_count}, Approved Orders: ${recd_count}`
                  : "Congratulations! Orders approved via OTP!",
              btnText: "BSE Payment Link",
              purchase_2FA_url: this.state.purchase_2FA_url,
              purchase_url: this.state.purchase_url,
              total_count,
              recd_count,
            });
            this.checkOTPStatus();
          } else {
            this.checkOTPStatus();
          }
        }
      }, 5000);
    } catch (e) { }
  }

  setModalMethod = (method) => {
    this.openOrCloseModal = method;
  };

  async _getSchemeDetails(data1, fundPK) {
    let schemes = null;
    if (data1 && data1.schemes) {
      schemes = data1.schemes;
    } else {
      const scheme_response = await getAMCFundSchemestaFn(fundPK);
      schemes =
        scheme_response && scheme_response.data && scheme_response.data.results
          ? scheme_response.data.results
          : null;
    }

    if (schemes) {
      const scheme = schemes.find(
        (item) => item.amcfundscheme_id == data1.default_amcfundscheme_id
      );

      let scheme_id;

      if (scheme) {
        scheme_id = scheme.amcfundscheme_id;
      } else {
        scheme_id = schemes.find((item) =>
          item.name.includes("Direct")
        ).amcfundscheme_id;
      }

      this.onSchemeChange(scheme_id);
    }
  }

  amountValidation = () => {
    const { value, add_invt } = this.state;
    let amount_error = "";
    // console.log(this.state.mfFundTxnDetails, add_invt);

    if (
      add_invt == "N" &&
      this.state.mfFundTxnDetails &&
      this.state.mfFundTxnDetails.bse_minimum_purchase_amount
    ) {
      if (
        Number(value) < this.state.mfFundTxnDetails.bse_minimum_purchase_amount
      ) {
        amount_error = "Please enter minimum Amount ";
      }
    } else if (
      add_invt == "Y" &&
      this.state.mfFundTxnDetails &&
      this.state.mfFundTxnDetails.bse_additional_purchase_amount_multiple
    ) {
      if (
        Number(value) <
        this.state.mfFundTxnDetails.bse_additional_purchase_amount_multiple
      ) {
        amount_error = "Please enter minimum additional Amount ";
      }
    } else {
      if (Number(value) < 500) {
        amount_error = "Please enter minimum Amount ";
      }
    }
    this.setState({
      amount_error,
    });
  };

  validateData = () => {
    if (this.state.value == "") {
      this.setState({
        amount_error: "Please enter minimum Amount ",
      });
      return false;
    }

    if (
      this.state.add_invt == "N" &&
      this.state.mfFundTxnDetails &&
      this.state.mfFundTxnDetails.bse_minimum_purchase_amount
    ) {
      if (
        Number(this.state.value) <
        this.state.mfFundTxnDetails.bse_minimum_purchase_amount
      ) {
        amount_error = "Please enter minimum Amount ";
      }
    } else if (
      this.state.add_invt == "Y" &&
      this.state.mfFundTxnDetails &&
      this.state.mfFundTxnDetails.bse_additional_purchase_amount_multiple
    ) {
      if (
        Number(this.state.value) <
        this.state.mfFundTxnDetails.bse_additional_purchase_amount_multiple
      ) {
        amount_error = "Please enter minimum additional Amount ";
      }
    } else {
      if (Number(this.state.value) < 500) {
        this.setState({
          amount_error: "Please enter minimum Amount ",
        });
        return false;
      }
    }

    return true;
  };

  getStartDate = () => {
    let date = Number(this.state.selecteddate);

    if (date > parseInt(moment().format("DD"))) {
      date <= 9 ? (date = "0" + date.toString()) : null;
      return moment().format("YYYY") + "-" + moment().format("MM") + "-" + date;
    } else {
      date <= 9 ? (date = "0" + date.toString()) : null;
      if (moment().format("MM") == 12) {
        return 1 + parseInt(moment().format("YYYY")) + "-" + "01" + "-" + date;
      } else {
        return parseInt(moment().format("YYYY")) + "-" + "01" + "-" + date;
      }
    }
  };

  navigateToMandate = async () => {
    // console.log(
    //   this.state.add_invt,
    //   this.state.value,
    //   this.state.mfFundTxnDetails,
    //   this.state.mfFundTxnDetails.bse_additional_purchase_amount_multiple
    // );
    if (
      this.state.add_invt == "Y" &&
      this.state.mfFundTxnDetails &&
      this.state.mfFundTxnDetails.bse_additional_purchase_amount_multiple
    ) {
      if (
        Number(this.state.value) <
        this.state.mfFundTxnDetails.bse_additional_purchase_amount_multiple
      ) {
        this.setState({ amount_error: "min.amount required" });
        return;
        // amount_error = "Please enter minimum additional Amount ";
      }
    } else {
      if (
        parseInt(this.state.value) <
        this.state.mfFundTxnDetails.bse_minimum_purchase_amount
      ) {
        this.setState({ amount_error: "min.amount required" });
        return;
      }
    }

    this.setState({
      showLoader: false,
      isLoading: true,
      showBSEErrorToast: false,
    });

    let payload = {
      redirection_url:
        global.location.origin +
        `/explore/mutualfunds/invest/lumpsum/?fund=${this.state.fundPK}&add_invt=${this.state.add_invt}&selected_fund=${this.state.selected_fund}`,
    };

    let response = await uccCodeGenerate(payload);

    if (response && response.code && response.code == 100) {
      let result = response.result;

      if (
        result == "Bse ucc code and nominee authentication is completed" ||
        result == "Nominee Authentication already completed" ||
        result ==
        "Bse ucc code generated and nominee authentication is not required"
      ) {
        if (!this.validateData()) {
          return;
        } else {
          this.placeOrderWithFirstInstallment();
        }
      } else if (
        result == "Nominee Authentication Link Generated Successfully" &&
        response.return_url
      ) {
        this.setState({
          showLoader: true,
          isLoading: false,
        });

        setTimeout(() => {
          global.open(response.return_url, "_self");
        }, 5000);
      }
    } else {
      this.setState({
        showBSEErrorToast: "true",
      });
    }
  };

  placeOrderWithFirstInstallment = async () => {
    try {
      this.setState({
        showErrorToast: "false",
        isLoading: true,
        isLoading2: true,
        loaderText: `Mutual Fund orders must be approved with OTP. Loading your SMS OTP approval page...`,
      });
      const selectedScheme = Number(
        this.state.selectedScheme && this.state.selectedScheme.value
          ? this.state.selectedScheme.value
          : this.state.selectedScheme
      );

      const payload = {
        "cai_type": "LMPI",
        "customer_id": this.state.userData.customer_id,
        "mf_orders": [
          {
            "pk": selectedScheme,
            "quantity": Number(this.state.value),
          }
        ],
      }

      const response = await createActionItemForMFOrders(payload);
      if (response && response.code == 100) {
        this.setState({
          isLoading: false
        })
        this.props.navigation.navigate("App", {
          screen: "Explore",
          params: {
            screen: "MfOtpAuthentication",
            params: {}
          }
        })
      }

      // if (this.state.userData.customer_id == 39762) {
      //   const payload = {
      //     "cai_type": "LMPI",
      //     "customer_id": this.state.userData.customer_id,
      //     "mf_orders": [
      //       {
      //         "pk": selectedScheme,
      //         "quantity": Number(this.state.value),
      //       }
      //     ],
      //   }

      //   const response = await createActionItemForMFOrders(payload);
      //   if (response && response.code == 100) {
      //     this.setState({
      //       isLoading: false
      //     })
      //     this.props.navigation.navigate("App", {
      //       screen: "Explore",
      //       params: {
      //         screen: "MfOtpAuthentication",
      //         params: {}
      //       }
      //     })
      //   }
      // } else {
      //   const key = moment().unix();
      //   const payload = {
      //     orders: [
      //       {
      //         scheme: selectedScheme,
      //         amount: Number(this.state.value),
      //         investmentType: "lumpsum",
      //       },
      //     ],
      //     redirection_url:
      //       (Platform.OS == "web"
      //         ? global.location.origin
      //         : "https://app.jamawealth.com") +
      //       "/dashboard/process/" +
      //       key,
      //   };

      //   // this.setState({
      //   //   isLoading: true,
      //   // });

      //   const response = await createOrderWithFirstInstallment(payload);
      //   // console.log("RES : ", response);
      //   if (response && response.code && response.code == 100) {
      //     const { navigate_to, code, nav_props, ...restData } = response;
      //     Store.dispatch({
      //       type: "ADD_ACTION",
      //       payload: {
      //         key: key,
      //         data: {
      //           ...restData,
      //           ...nav_props,
      //         },
      //       },
      //     });
      //     if (response.success > 0) {
      //       const { purchase_2FA_url, mf_recommend_set_no } = response;
      //       const { url } = nav_props;
      //       this.setState({
      //         purchase_2FA_url,
      //         purchase_url: url,
      //         isLoading2: false,
      //         loaderText: `Mutual Fund orders must be approved with OTP. Continue to SMS OTP approval page.`,
      //         mf_recommend_set_no: mf_recommend_set_no,
      //       });
      //     } else {
      //       this.props.navigation.navigate("App", {
      //         screen: "Dashboard",
      //         params: {
      //           screen: "Dashboard",
      //           params: { screen: "ProcessOrder", params: { action_id: key } },
      //         },
      //       });
      //     }
      //   } else {
      //     this.setState({
      //       isLoading2: false,
      //     });
      //     this.props.navigation.navigate("App", {
      //       screen: "Dashboard",
      //       params: {
      //         screen: "Dashboard",
      //         params: { screen: "ProcessOrder", params: { action_id: key } },
      //       },
      //     });
      //   }
      // }
      // if (response) {
      //   const { navigate_to, code, nav_props, ...restData } = response;
      //   Store.dispatch({
      //     type: "ADD_ACTION",
      //     payload: {
      //       key: key,
      //       data: {
      //         ...restData,
      //         ...nav_props,
      //       },
      //     },
      //   });
      //   if (response.code && response.code == 100) {
      //     if (response.success > 0) {
      //       this.setState({
      //         isLoading: false,
      //       });
      //       if (response.navigate_to == "ShowBankAccount") {
      //         this.props.navigation.navigate("App", {
      //           screen: "Dashboard",
      //           params: {
      //             screen: "Explore",
      //             params: {
      //               screen: "ShowBankAccount",
      //               params: {
      //                 action: key,
      //               },
      //             },
      //           },
      //         });
      //         return;
      //       }
      //     }
      //   }
      //   this.setState({
      //     isLoading: false,
      //   });
      //   this.props.navigation.navigate("App", {
      //     screen: "Dashboard",
      //     params: {
      //       screen: "Dashboard",
      //       params: { screen: "ProcessOrder", params: { action_id: key } },
      //     },
      //   });
      // }
    } catch (error) {
      console.log("DEBUG :", error)
      this.setState({
        isLoading: false,
        showErrorToast: true,
        errorResponse: "Something went wrong!",
        toastText: "Order Not Created.",
      });
    }
  };

  onSchemeChange = (value) => {
    this.setState({
      selectedScheme: value,
      errorResponse: "",
    });
    this.state.mfAMCFundsSchemes.forEach((item) => {
      if (item.amcfundscheme_id == value) {
        this.setState({ default_selected_fund: item });
      }
    });
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  getNumIndianFormat = (num) => {
    if (!num) {
      return "-";
    }
    var str = num.toString();
    var final = "";
    if (str.length > 3) {
      final = "," + str.substring(str.length - 3, str.length);
      str = str.substring(0, str.length - 3);
      while (str.length > 2) {
        final = "," + str.substring(str.length - 2, str.length) + final;
        str = str.substring(0, str.length - 2);
      }
      final = str + final;
    } else {
      return num.toString();
    }
    return final;
  };

  convert_crore_to_lac(num) {
    var crore = parseInt(1000000);
    var lakh = parseInt(100000);
    if (num > crore) {
      var value = num / lakh;
      value = parseFloat(value).toFixed(2) + " Lac";
      return "\u20B9 " + value;
    } else {
      var final_val = this.formatNumber(num);
      return "\u20B9 " + final_val;
    }
  }

  formatNumber(num) {
    var str = num.toString();
    var final = "";
    if (str.length > 3) {
      final = "," + str.substring(str.length - 3, str.length);
      str = str.substring(0, str.length - 3);
      while (str.length > 2) {
        final = "," + str.substring(str.length - 2, str.length) + final;
        str = str.substring(0, str.length - 2);
      }
      final = str + final;
    } else {
      return num.toString();
    }
    return final;
  }

  calculate_sip_future_value(
    installment_amount,
    number_of_installments,
    yearly_returns
  ) {
    var returns_assumed = yearly_returns / 12;
    var total = 0;
    var installment_amount = parseInt(installment_amount) || 0;
    var number_of_installments = parseInt(number_of_installments) || 0;
    for (var i = 0; i < number_of_installments; i++) {
      total = total + installment_amount;
      total = total * (1 + returns_assumed);
    }
    return total;
  }

  calculate_future_value(amount, no_of_years, returns) {
    if (returns) {
      var return_perc = 1 + returns / 100;
      var return_pow = Math.pow(return_perc, no_of_years);
      var final_value = amount * return_pow;
      final_value = Math.round(final_value);
      return final_value;
    } else {
      return 0;
    }
  }

  transactionStatus = (transactionType) => {
    let type = "";
    switch (transactionType) {
      case "P":
        type = "Purchase";
        break;

      case "R":
        type = "Redemption";
        break;

      case "SI":
        type = "Switch In";
        break;

      case "SO":
        type = "Switch Out";
        break;

      default:
        break;
    }
    return type;
  };

  months_full = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  months_short_names = (index, full = false) => {
    if (!full) {
      return index <= 12 ? this.months[index - 1] : null;
    } else return index <= 12 ? this.months_full[index - 1] : null;
  };

  dateFormat = (date, delimeter = "-") => {
    let values = date.split(`${delimeter}`);
    return (
      values[2] +
      "-" +
      this.months_short_names(parseInt(values[1])) +
      "-" +
      values[0]
    );
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  handleCloseComplianceAlert = () => {
    const { navigation, route = {} } = this.props;
    const { params } = route;
    let skipped_compliance = false;
    let fundId = null;
    let selected_fund = null;
    if (params) {
      skipped_compliance = params.s || false;
      fundId = params.fund || null;
      selected_fund = params.selected_fund || null;
    }
    if (!skipped_compliance && navigation.canGoBack()) {
      navigation.goBack();
    } else {
      let params = {};
      if (fundId) {
        params = {
          screen: "FundDescription",
          params: {
            fund: fundId,
            fund_amc_id: selected_fund
          },
        };
      }
      navigation.dispatch(
        StackActions.replace("App", {
          screen: "Dashboard",
          params: {
            screen: "Explore",
            params: {
              ...params,
            },
          },
        })
      );
    }
  };

  handleOTPApproval = async () => {
    global.open(this.state.purchase_2FA_url, "_blank");
    this.setState({
      isLoading3: true,
      isLoading4: false,
      loaderText: "Waiting for SMS OTP approval of your orders...",
    });
    this.checkOTPStatus();
  };

  handlePayment = async () => {
    global.open(this.state.purchase_url, "_self");
  };

  handleOTPPageNotOpen = async () => {
    // console.log("i'm called");
    let url_2fa = this.state.purchase_2FA_url;
    if (url_2fa.includes("http")) {
      global.open(url_2fa, "_blank");
    } else {
      const response = await getUrlForClient2FA();
      if (response && response.code == 100) {
        const url = response.result;
        global.open(url, "_blank");
      }
    }
  };

  render() {
    const { error, errorText, isLoading } = this.state;

    var productList = [];
    let dateslist = [];
    let yearlist = [];
    let amount =
      this.state.add_invt == "N" &&
        this.state.mfFundTxnDetails &&
        this.state.mfFundTxnDetails.bse_minimum_purchase_amount
        ? this.getNumIndianFormat(
          this.state.mfFundTxnDetails.bse_minimum_purchase_amount
        )
        : this.state.add_invt == "Y" &&
          this.state.mfFundTxnDetails &&
          this.state.mfFundTxnDetails.bse_additional_purchase_amount_multiple
          ? this.getNumIndianFormat(
            this.state.mfFundTxnDetails.bse_additional_purchase_amount_multiple
          )
          : 500;

    let label = "Enter Amount (Min \u20B9 " + amount + ")";

    if (Object.keys(this.state.mfFundTxnDetails).length > 0) {
      if (this.state.mfFundTxnDetails && this.state.mfFundTxnDetails.schemes) {
        this.state.mfFundTxnDetails.schemes.map((item, index) => {
          if (item.is_direct_fund == true) {
            productList.push(
              <Picker.Item
                key={index}
                label={item.name}
                value={item.amcfundscheme_id}
              />
            );
          }
        });
      }

      this.state.frequencyarr.map((item) => {
        dateslist.push(<Picker.Item label={item} value={item} />);
      });

      yearlist.push(<Picker.Item label={"1"} value={"1"} />);
      yearlist.push(<Picker.Item label={"2"} value={"2"} />);
      yearlist.push(<Picker.Item label={"3"} value={"3"} />);
      yearlist.push(<Picker.Item label={"4"} value={"4"} />);
      yearlist.push(<Picker.Item label={"5"} value={"5"} />);
    }

    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        {this.state.showComplianceAlert && (
          <ComplianceModal
            forceLoad={true}
            navigation={this.props.navigation}
            route={this.props.route}
            isLoading={this.state.gettingMinDetails}
            minDetailsResponse={this.state.mfMinDetailsData}
            onClose={this.handleCloseComplianceAlert}
            userId={this.state.userId}
            navigationOptions={{
              transaction: this.state.fundPK,
              fund: this.state.fundPK,
              add_invt: this.state.add_invt,
              selected_fund: this.state.selected_fund,
              stack: "Explore",
              screen: "InvestLumpsum",
            }}
          />
        )}
        <View style={{ flex: 1, maxWidth: 1500 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={[
              "Mutual Funds Overview",
              "Invest",
              "Invest Lumpsum",
            ]}
            onPressBack={this.handleCloseComplianceAlert}
          />
          {this.state.showErrorToast && this.state.toastText ? (
            <ShowToast
              showToast={true}
              type="error"
              title={this.state.toastText}
            />
          ) : null}
          {this.state.showToast && this.state.toastText ? (
            <ShowToast
              showToast={true}
              type="error"
              title={this.state.toastText}
            />
          ) : null}
          {this.state.showBSESuccessToast == "true" ? (
            <ShowToast
              showToast={true}
              type="status"
              title="Your nominee authentication was successfully completed."
              duration="10"
            />
          ) : null}
          {this.state.showBSEErrorToast == "true" ? (
            <ShowToast
              showToast={true}
              type="status"
              title="Error while loading BSE url for nominee authentication. kindly contact your advisor."
              duration="10"
            />
          ) : null}
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.showLoader ? (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator size={25} color="#410DAA" />
                <Text
                  style={{ fontSize: 16, fontWeight: "bold", marginTop: 10 }}
                >
                  You are being redirected to BSE nominee authentication page
                </Text>
              </View>
            ) : error ? (
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText || "Something went wrong!"}
              </Text>
            ) : this.state.isLoading ? (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {this.state.isLoading2 && (
                  <>
                    <ActivityIndicator size="large" color="#410DAA" />
                    <Text
                      style={{
                        marginTop: 20,
                        fontWeight: "bold",
                        fontSize: 17,
                      }}
                    >
                      {this.state.loaderText}
                    </Text>
                  </>
                )}
                {!this.state.isLoading2 && (
                  <View style={{ flex: 1, width: "100%" }}>
                    <PlacePaymentAndOTP
                      handleOTPApproval={this.handleOTPApproval}
                      otploading={this.state.isLoading3}
                      handlePayment={this.handlePayment}
                      showPayment={true}
                      paymentLoading={this.state.isLoading4}
                      handleOTPPageNotOpen={this.handleOTPPageNotOpen}
                    />
                  </View>
                )}
              </View>
            ) : (
              <ScrollView style={[styles.mainContainer]}>
                {isMobile ? (
                  <ScrollView>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 17,
                        width: "90%",
                        marginLeft: "5%",
                        marginRight: "5%",
                      }}
                    >
                      <View
                        style={{
                          flex: 10,
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: "#410DAA1A",
                        }}
                      >
                        <View
                          style={{
                            padding: 10,
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <View style={{ flex: 0.05 }}>
                            <Image
                              source={{
                                uri: this.state.mfFundTxnDetails.amc_logo,
                              }}
                              resizeMode="contain"
                              style={{
                                height: 30,
                                width: 30,
                                alignSelf: "flex-start",
                                borderWidth: 1,
                                borderColor: "#f2f2f2",
                                borderRadius: 5,
                              }}
                            />
                          </View>
                          <View style={{ flex: isMobile ? 0.05 : 0.01 }}></View>
                          <View style={{ flex: 0.84, paddingLeft: 35 }}>
                            <Text
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontWeight: "bold",
                              }}
                            >
                              {this.state.default_selected_fund.name}
                            </Text>
                          </View>
                          <View style={{ flex: 0.1 }}></View>
                        </View>
                        <View
                          style={{
                            paddingHorizontal: 10,
                            paddingVertical: 4,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              paddingVertical: 9,
                            }}
                          >
                            <FundField
                              style={{
                                flex: 0.33,
                                alignItems: "flex-start",
                              }}
                              name="Latest NAV"
                              value={
                                this.state.default_selected_fund.latest_nav.toFixed(
                                  2
                                ) || "NA"
                              }
                            />
                            {this.state.selected_fund.returns_3_yr ? <FundField
                              style={{ flex: 0.33, alignItems: "center" }}
                              name="3 Year Returns"
                              value={
                                this.state.selected_fund.returns_3_yr
                                  ? `${Number.parseFloat(
                                    this.state.selected_fund.returns_3_yr
                                  ).toPrecision(3)}%`
                                  : "-"
                              }
                            /> : this.state.selected_fund.returns_1_yr ? <FundField
                              style={{ flex: 0.33, alignItems: "center" }}
                              name="1 Year Returns"
                              value={
                                this.state.selected_fund.returns_1_yr
                                  ? `${Number.parseFloat(
                                    this.state.selected_fund.returns_1_yr
                                  ).toPrecision(3)}%`
                                  : "-"
                              }
                            /> : <FundField
                              style={{ flex: 0.33, alignItems: "center" }}
                              name="3 months Returns"
                              value={
                                this.state.selected_fund.returns_3_mth
                                  ? `${Number.parseFloat(
                                    this.state.selected_fund.returns_3_mth
                                  ).toPrecision(3)}%`
                                  : "-"
                              }
                            />}
                            <FundField
                              style={{ flex: 0.34, alignItems: "flex-end" }}
                              name="Fund Class"
                              value={
                                this.state.default_selected_fund
                                  .AMCFund__fund_class
                                  ? this.state.default_selected_fund.AMCFund__fund_class.trim()
                                  : "-"
                              }
                            />
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              paddingVertical: 9,
                            }}
                          >
                            <FundField
                              style={{
                                flex: 0.33,
                                alignItems: "flex-start",
                              }}
                              name="Min SIP"
                              value={
                                this.state.default_selected_fund.bse_sip_flag ==
                                  "Y"
                                  ? this.state.default_selected_fund
                                    .bse_sip_minimum_installment_amount_monthly
                                    ? "\u20B9" +
                                    this.state.default_selected_fund
                                      .bse_sip_minimum_installment_amount_monthly
                                    : "-"
                                  : "-"
                              }
                            />
                            <FundField
                              style={{ flex: 0.33, alignItems: "center" }}
                              name="Min Lumpsum"
                              value={
                                this.state.default_selected_fund
                                  .bse_purchase_allowed == "Y"
                                  ? this.state.default_selected_fund
                                    .bse_minimum_purchase_amount
                                    ? "\u20B9" +
                                    this.state.default_selected_fund
                                      .bse_minimum_purchase_amount
                                    : "-"
                                  : "-"
                              }
                            />
                            <FundField
                              style={{ flex: 0.34, alignItems: "flex-end" }}
                              name="Funds Managed"
                              value={
                                this.getNumIndianFormat(
                                  (
                                    Number(
                                      this.state.default_selected_fund
                                        .AMCFund__assets_under_management
                                    ) / 100
                                  ).toFixed(0)
                                ) + " Cr."
                              }
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        width: "90%",
                        marginLeft: "5%",
                        marginRight: "5%",
                        zIndex: 9999,
                        marginTop: 15,
                        marginBottom: 80,
                      }}
                    >
                      <TextInput
                        style={{
                          backgroundColor: "white",
                          borderRadius: 50,
                          marginTop: 12,
                        }}
                        mode="outlined"
                        onFocus={() => {
                          this.setState({ amount_error: "" });
                        }}
                        onBlur={() => {
                          this.amountValidation();
                        }}
                        keyboardType="numeric"
                        label={label}
                        onChangeText={(text) => {
                          const pattern = /^[0-9\b]+$/;
                          if (text.length <= 7 && pattern.test(text)) {
                            this.setState({ value: text });
                          } else {
                            text = text.slice(0, -1);
                            this.setState({ value: text });
                          }
                        }}
                        maxLength={7}
                        returnKeyType={"done"}
                        value={this.state.value}
                        error={this.state.amount_error}
                        tintColor="#410DAA"
                        titleTextStyle={{
                          color: "#410DAA",
                        }}
                      />

                      <HelperText
                        type="error"
                        visible={this.state.amount_error != ""}
                      >
                        {this.state.amount_error}
                      </HelperText>

                      <View style={{ flexDirection: "row" }}>
                        <TouchableOpacity
                          onPress={() =>
                            this.setState(
                              {
                                value: (
                                  Number(this.state.value) + 500
                                ).toString(),
                              },
                              () => {
                                this.amountValidation();
                              }
                            )
                          }
                        >
                          <View
                            style={{
                              padding: 2,
                              borderRadius: 15,
                              borderWidth: 1,
                              borderColor: "#410DAA",
                              marginRight: 6,
                              overflow: "hidden",
                            }}
                          >
                            <Text style={{ color: "#410DAA" }}>+ 500 </Text>
                          </View>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() =>
                            this.setState(
                              {
                                value: (
                                  Number(this.state.value) + 1000
                                ).toString(),
                              },
                              () => {
                                this.amountValidation();
                              }
                            )
                          }
                        >
                          <View
                            style={{
                              padding: 2,
                              borderRadius: 15,
                              borderWidth: 1,
                              borderColor: "#410DAA",
                              marginRight: 6,
                              overflow: "hidden",
                            }}
                          >
                            <Text style={{ color: "#410DAA" }}>+ 1000 </Text>
                          </View>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() =>
                            this.setState(
                              {
                                value: (
                                  Number(this.state.value) + 1500
                                ).toString(),
                              },
                              () => {
                                this.amountValidation();
                              }
                            )
                          }
                        >
                          <View
                            style={{
                              padding: 2,
                              borderRadius: 15,
                              borderWidth: 1,
                              borderColor: "#410DAA",
                              marginRight: 6,
                              overflow: "hidden",
                            }}
                          >
                            <Text style={{ color: "#410DAA" }}>+ 1500 </Text>
                          </View>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() =>
                            this.setState(
                              {
                                value: (
                                  Number(this.state.value) + 3000
                                ).toString(),
                              },
                              () => {
                                this.amountValidation();
                              }
                            )
                          }
                        >
                          <View
                            style={{
                              padding: 2,
                              borderRadius: 15,
                              borderWidth: 1,
                              borderColor: "#410DAA",
                              marginRight: 6,
                              overflow: "hidden",
                            }}
                          >
                            <Text style={{ color: "#410DAA" }}>+ 3000 </Text>
                          </View>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() =>
                            this.setState(
                              {
                                value: (
                                  Number(this.state.value) + 5000
                                ).toString(),
                              },
                              () => {
                                this.amountValidation();
                              }
                            )
                          }
                        >
                          <View
                            style={{
                              padding: 2,
                              borderRadius: 15,
                              borderWidth: 1,
                              borderColor: "#410DAA",
                              marginRight: 6,
                              overflow: "hidden",
                            }}
                          >
                            <Text style={{ color: "#410DAA" }}>+ 5000 </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginTop: 32, zIndex: 9999 }}>
                        <PickerFieldSelect
                          data={this.state.options}
                          value={this.state.selectedScheme.value}
                          showSelect={false}
                          label="Select scheme"
                          onChange={(value) => this.onSchemeChange(value)}
                          pickerStyle={[styles.inputItem]}
                          type="select"
                          placeholder={{ value: "SEL", label: "Select Scheme" }}
                        />
                      </View>
                      <View style={{ marginTop: 46 }}>
                        <Button
                          uppercase={false}
                          mode={"contained"}
                          onPress={() => { this.navigateToMandate(); }}
                          style={{
                            width: "100%",
                            marginTop: 6,
                            marginHorizontal: 16,
                            alignSelf: "center",
                          }}
                          color={"#410DAA"}
                        >
                          <Text style={styles.createMandate}>Place Order</Text>
                        </Button>
                      </View>
                    </View>
                  </ScrollView>
                ) : (
                  <View
                    style={{
                      paddingBottom: 40,
                      marginTop: isMobile ? -3 : -17,
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        zIndex: 9999,
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                        }}
                      >
                        <TextInput
                          style={{
                            backgroundColor: "white",
                            borderRadius: 50,
                            marginTop: 12,
                          }}
                          mode="outlined"
                          onFocus={() => {
                            this.setState({ amount_error: "" });
                          }}
                          onBlur={() => {
                            this.amountValidation();
                          }}
                          keyboardType="numeric"
                          label={label}
                          onChangeText={(text) => {
                            const pattern = /^[0-9\b]+$/;
                            if (text.length <= 7 && pattern.test(text)) {
                              this.setState({ value: text });
                            } else {
                              text = text.slice(0, -1);
                              this.setState({ value: text });
                            }
                          }}
                          maxLength={7}
                          returnKeyType={"done"}
                          value={this.state.value}
                          error={this.state.amount_error}
                          tintColor="#410DAA"
                          titleTextStyle={{
                            color: "#410DAA",
                          }}
                        />

                        <HelperText
                          type="error"
                          visible={this.state.amount_error != ""}
                        >
                          {this.state.amount_error}
                        </HelperText>

                        <View style={{ flexDirection: "row" }}>
                          <TouchableOpacity
                            onPress={() =>
                              this.setState(
                                {
                                  value: (
                                    Number(this.state.value) + 500
                                  ).toString(),
                                },
                                () => {
                                  this.amountValidation();
                                }
                              )
                            }
                          >
                            <View
                              style={{
                                padding: 2,
                                borderRadius: 15,
                                borderWidth: 1,
                                borderColor: "#410DAA",
                                marginRight: 6,
                                overflow: "hidden",
                              }}
                            >
                              <Text style={{ color: "#410DAA" }}>+ 500 </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() =>
                              this.setState(
                                {
                                  value: (
                                    Number(this.state.value) + 1000
                                  ).toString(),
                                },
                                () => {
                                  this.amountValidation();
                                }
                              )
                            }
                          >
                            <View
                              style={{
                                padding: 2,
                                borderRadius: 15,
                                borderWidth: 1,
                                borderColor: "#410DAA",
                                marginRight: 6,
                                overflow: "hidden",
                              }}
                            >
                              <Text style={{ color: "#410DAA" }}>+ 1000 </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() =>
                              this.setState(
                                {
                                  value: (
                                    Number(this.state.value) + 1500
                                  ).toString(),
                                },
                                () => {
                                  this.amountValidation();
                                }
                              )
                            }
                          >
                            <View
                              style={{
                                padding: 2,
                                borderRadius: 15,
                                borderWidth: 1,
                                borderColor: "#410DAA",
                                marginRight: 6,
                                overflow: "hidden",
                              }}
                            >
                              <Text style={{ color: "#410DAA" }}>+ 1500 </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() =>
                              this.setState(
                                {
                                  value: (
                                    Number(this.state.value) + 3000
                                  ).toString(),
                                },
                                () => {
                                  this.amountValidation();
                                }
                              )
                            }
                          >
                            <View
                              style={{
                                padding: 2,
                                borderRadius: 15,
                                borderWidth: 1,
                                borderColor: "#410DAA",
                                marginRight: 6,
                                overflow: "hidden",
                              }}
                            >
                              <Text style={{ color: "#410DAA" }}>+ 3000 </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() =>
                              this.setState(
                                {
                                  value: (
                                    Number(this.state.value) + 5000
                                  ).toString(),
                                },
                                () => {
                                  this.amountValidation();
                                }
                              )
                            }
                          >
                            <View
                              style={{
                                padding: 2,
                                borderRadius: 15,
                                borderWidth: 1,
                                borderColor: "#410DAA",
                                marginRight: 6,
                                overflow: "hidden",
                              }}
                            >
                              <Text style={{ color: "#410DAA" }}>+ 5000 </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginTop: 20, zIndex: 9999 }}>
                          <PickerFieldSelect
                            data={this.state.options}
                            value={this.state.selectedScheme.value}
                            label="Select your scheme"
                            showSelect={false}
                            width={"100%"}
                            height={55}
                            onChange={(value) => this.onSchemeChange(value)}
                            pickerStyle={[styles.inputItem]}
                            type="select"
                          />
                        </View>
                        <View style={{ marginTop: 20 }}>
                          <TouchableOpacity
                            onPress={() => {
                              this.navigateToMandate();
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "bold",
                                fontSize: isWeb ? 15 : 13,
                                color: "#FFFFFF",
                                backgroundColor: "#410DAA",
                                borderColor: "#410DAA",
                                borderWidth: 1,
                                borderRadius: 4,
                                paddingVertical: 9,
                                textAlign: "center",
                                width: "28%",
                                marginLeft: "72%",
                              }}
                            >
                              Place Order
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>

                      <View
                        style={{
                          flex: 0.05,
                          flexDirection: "row",
                        }}
                      ></View>

                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          marginTop: 17,
                        }}
                      >
                        <View
                          style={{
                            flex: 10,
                            borderRadius: 10,
                            borderWidth: 1,
                            borderColor: "#410DAA1A",
                          }}
                        >
                          <View
                            style={{
                              padding: 10,
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <View style={{ flex: 0.05 }}>
                              <Image
                                source={{
                                  uri: this.state.mfFundTxnDetails.amc_logo,
                                }}
                                resizeMode="contain"
                                style={{
                                  height: 30,
                                  width: 30,
                                  alignSelf: "flex-start",
                                  borderWidth: 1,
                                  borderColor: "#f2f2f2",
                                  borderRadius: 5,
                                }}
                              />
                            </View>
                            <View
                              style={{ flex: isMobile ? 0.05 : 0.01 }}
                            ></View>
                            <View style={{ flex: 0.84, paddingLeft: 35 }}>
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.state.default_selected_fund.name}
                              </Text>
                            </View>
                            <View style={{ flex: 0.1 }}></View>
                          </View>
                          <View
                            style={{
                              paddingHorizontal: 10,
                              paddingVertical: 4,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                paddingVertical: 9,
                              }}
                            >
                              <FundField
                                style={{
                                  flex: 0.33,
                                  alignItems: "flex-start",
                                }}
                                name="Latest NAV"
                                value={
                                  this.state.default_selected_fund.latest_nav.toFixed(
                                    2
                                  ) || "NA"
                                }
                              />
                              {/* {console.log("console",this.state.default_selected_fund )} */}
                              {this.state.default_selected_fund.returns_3_yr ? <FundField
                                style={{ flex: 0.33, alignItems: "center" }}
                                name="3 Year Returns"
                                value={
                                  this.state.default_selected_fund.returns_3_yr
                                    ? `${Number.parseFloat(
                                      this.state.default_selected_fund.returns_3_yr
                                    ).toPrecision(3)}%`
                                    : "-"
                                }
                              /> : this.state.default_selected_fund.returns_1_yr ? <FundField
                                style={{ flex: 0.33, alignItems: "center" }}
                                name="1 Year Returns"
                                value={
                                  this.state.default_selected_fund.returns_1_yr
                                    ? `${Number.parseFloat(
                                      this.state.default_selected_fund.returns_1_yr
                                    ).toPrecision(3)}%`
                                    : "-"
                                }
                              /> : <FundField
                                style={{ flex: 0.33, alignItems: "center" }}
                                name="3 months Returns"
                                value={
                                  this.state.default_selected_fund.returns_3_mth
                                    ? `${Number.parseFloat(
                                      this.state.default_selected_fund.returns_3_mth
                                    ).toPrecision(3)}%`
                                    : "-"
                                }
                              />}
                              <FundField
                                style={{ flex: 0.34, alignItems: "flex-end" }}
                                name="Fund Class"
                                value={
                                  this.state.default_selected_fund
                                    .AMCFund__fund_class
                                    ? this.state.default_selected_fund.AMCFund__fund_class.trim()
                                    : "-"
                                }
                              />
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                paddingVertical: 9,
                              }}
                            >
                              <FundField
                                style={{
                                  flex: 0.33,
                                  alignItems: "flex-start",
                                }}
                                name="Min SIP"
                                value={
                                  this.state.default_selected_fund
                                    .bse_sip_flag == "Y"
                                    ? this.state.default_selected_fund
                                      .bse_sip_minimum_installment_amount_monthly
                                      ? "\u20B9" +
                                      this.state.default_selected_fund
                                        .bse_sip_minimum_installment_amount_monthly
                                      : "-"
                                    : "-"
                                }
                              />
                              <FundField
                                style={{ flex: 0.33, alignItems: "center" }}
                                name="Min Lumpsum"
                                value={
                                  this.state.default_selected_fund
                                    .bse_purchase_allowed == "Y"
                                    ? this.state.default_selected_fund
                                      .bse_minimum_purchase_amount
                                      ? "\u20B9" +
                                      this.state.default_selected_fund
                                        .bse_minimum_purchase_amount
                                      : "-"
                                    : "-"
                                }
                              />
                              <FundField
                                style={{ flex: 0.34, alignItems: "flex-end" }}
                                name="Funds Managed"
                                value={
                                  this.getNumIndianFormat(
                                    (
                                      Number(
                                        this.state.default_selected_fund
                                          .AMCFund__assets_under_management
                                      ) / 100
                                    ).toFixed(0)
                                  ) + " Cr."
                                }
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 18,
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                        }}
                      >
                        <HelperText
                          type="error"
                          visible={this.state.showErrorToast}
                        >
                          {this.state.errorResponse}
                        </HelperText>
                      </View>
                      <View
                        style={{
                          flex: 0.05,
                          flexDirection: "row",
                        }}
                      ></View>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          marginTop: 17,
                        }}
                      ></View>
                    </View>
                  </View>
                )}
                {isMobile && <View style={{ marginTop: 35 }}></View>}
              </ScrollView>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: isWeb ? 32 : 0,
    paddingRight: isWeb ? 32 : 0,
    paddingTop: isWeb ? 30 : 10,
    width: "100%",
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  button_style: {
    padding: 2,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: "#410DAA",
    marginRight: 6,
    overflow: "hidden",
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  detailHeading: {
    color: "#121212",
    opacity: 0.5,
    fontStyle: "italic",
    fontSize: 11,
  },
  detailText: {
    color: "#121212",
    fontSize: 14,
    marginTop: 4,
    fontWeight: "bold",
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
  filterText: {
    fontSize: 15,
    color: "gray",
    fontWeight: "200",
  },
  filtersTitle: {
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 6,
    marginLeft: isMobile ? 16 : 0,
  },
});
