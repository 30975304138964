import React from "react";
import {
  View,
  FlatList,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Image,
} from "react-native";
import { Text } from "react-native-paper";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { isMobile } from "../../utils/index";
import TextInputField from "../Common/Inputs/InputField";
import PickerFieldSelect from "../Common/Pickers/PickerFieldSelect";

const InvestIn = [
  {
    label: "Lumpsum",
    value: "lumpsum",
  },
  {
    label: "SIP",
    value: "sip",
  },
];

const Period = [
  {
    label: "1 Year",
    value: "1",
  },
  {
    label: "2 Years",
    value: "2",
  },
  {
    label: "3 Years",
    value: "3",
  },
  {
    label: "4 Years",
    value: "4",
  },
  {
    label: "5 Years",
    value: "5",
  },
];

const SelectTabDetails = ({ item, onPress, borderColor, color }) => {
  return (
    <TouchableOpacity
      style={{
        borderColor,
        borderRadius: 18,
        borderWidth: 1,
        backgroundColor: "white",
        marginRight: 16,
        paddingLeft: 20,
        paddingRight: 20,
        height: 36,
        alignItems: "center",
        justifyContent: "center",
      }}
      onPress={onPress}
    >
      <Text
        style={{
          fontSize: 14,
          fontWeight: borderColor == "#410DAA" ? 600 : 400,
          color,
        }}
      >
        {item}
      </Text>
    </TouchableOpacity>
  );
};

class MFRecommendations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      investIn: "lumpsum",
      period: "2",
      activeTab: "Advice",
    };
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Mutual Fund Recommendations"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <ScrollView>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            style={styles.cardContainer}
          >
            <>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 30,
                }}
              >
                <Image
                  source={{
                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/congrats.png",
                  }}
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 4,
                  }}
                  resizeMode={"contain"}
                />
                <Text style={{ textAlign: "center", fontSize: 22 }}>
                  <Text style={{ color: "#0BB783", fontWeight: 700 }}>
                    {" "}
                    Congratulations!
                  </Text>{" "}
                  You are eligible for Mutual Fund Packs
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  marginTop: 40,
                  marginLeft: "5%",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 20,
                    justifyContent: "space-between",
                    width: "55%",
                  }}
                >
                  <TextInputField
                    label="Investment Amount*"
                    placeholder={"Enter Amount"}
                    value={this.state.amount}
                    inputStyle={[{ height: 44 }]}
                    onChange={(text) => {
                      if (!isNaN(text)) {
                        if (text.length > 10) {
                          this.setState({
                            amount: text.substring(0, 10),
                            mobile_error: "",
                          });
                        } else {
                          this.setState({
                            amount: text,
                          });
                        }
                      }
                    }}
                    editable={true}
                  />
                  <PickerFieldSelect
                    data={Period}
                    borderColor={"#E8E8E8"}
                    value={this.state.period}
                    label="Period*"
                    onChange={(text) => {
                      this.setState({ period: text });
                    }}
                    pickerStyle={[{ ...styles.inputItem }]}
                    type="select"
                  />
                </View>
                <PickerFieldSelect
                  data={InvestIn}
                  borderColor={"#E8E8E8"}
                  value={this.state.investIn}
                  label="Invest In*"
                  onChange={(text) => {
                    this.setState({ investIn: text });
                  }}
                  pickerStyle={[{ ...styles.inputItem }]}
                  type="select"
                />
                <Text
                  style={{
                    color: "#464E5F",
                    fontWeight: 700,
                    fontSize: 18,
                    marginTop: 30,
                  }}
                >
                  Advice
                </Text>
                <FlatList
                  data={["Advice", "Pending", "Scheduled"]}
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={{
                    alignItems: "flex-start",
                    marginTop: 20,
                  }}
                  renderItem={({ item }) => {
                    let color;
                    let borderColor;
                    if (item == this.state.activeTab) {
                      color = "#410DAA";
                      borderColor = "#410DAA";
                    } else {
                      color = "#464E5F";
                      borderColor = "#cbcfe1";
                    }
                    return (
                      <SelectTabDetails
                        item={item}
                        borderColor={borderColor}
                        color={color}
                        onPress={() => {
                          this.setState({
                            activeTab: item,
                          });
                        }}
                      />
                    );
                  }}
                  keyExtractor={(item, index) => index}
                />
                <Text
                  style={{
                    color: "#464E5F",
                    fontWeight: 700,
                    fontSize: 18,
                    marginTop: 30,
                  }}
                >
                  Mutual Fund Recommendations
                </Text>
              </View>
              <View style={{ marginTop: 20, marginBottom: isMobile ? 80 : 20 }}>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <View
                    style={{
                      marginLeft: "5%",
                      marginRight: "5%",
                      backgroundColor: "white",
                      width: "90%",
                      height: 140,
                      borderWidth: 1,
                      borderRadius: 5,
                      borderColor: "rgba(65, 13, 170, 0.3)",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        marginLeft: "1.9%",
                        marginRight: "1.9%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 30,
                        marginTop: 20,
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 5,
                              borderWidth: 1,
                              borderColor: "#410DAA1A",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.amazonaws.com/media/kbprofiles/amc/ICICI.png",
                              }}
                              resizeMode="contain"
                              style={{ height: 20, width: 20 }}
                            />
                          </View>
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "700",
                              marginLeft: 20,
                            }}
                          >
                            HDFC Bank Limited
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        marginLeft: "1.9%",
                        marginRight: "1.9%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: 80,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#7e838f",
                          }}
                        >
                          Date
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#454e60",
                            fontWeight: "700",
                          }}
                        >
                          17/02/2023
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#7e838f",
                          }}
                        >
                          Return (1M)
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#454e60",
                            fontWeight: "700",
                          }}
                        >
                          1.0%
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#7e838f",
                          }}
                        >
                          Amount
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#454e60",
                            fontWeight: "700",
                          }}
                        >
                          ₹ 500/-
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#7e838f",
                          }}
                        >
                          Action
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#454e60",
                            fontWeight: "700",
                          }}
                        >
                          SIP
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {}}
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "bold",
                              fontSize: 15,
                              color: "#ffffff",
                              paddingVertical: 12,
                              paddingHorizontal: 47,
                              backgroundColor: "#410DAA",
                              borderRadius: 4,
                            }}
                          >
                            Buy
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ marginTop: 20, marginBottom: isMobile ? 80 : 20 }}>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <View
                    style={{
                      marginLeft: "5%",
                      marginRight: "5%",
                      backgroundColor: "white",
                      width: "90%",
                      height: 140,
                      borderWidth: 1,
                      borderRadius: 5,
                      borderColor: "rgba(65, 13, 170, 0.3)",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        marginLeft: "1.9%",
                        marginRight: "1.9%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 30,
                        marginTop: 20,
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 5,
                              borderWidth: 1,
                              borderColor: "#410DAA1A",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.amazonaws.com/media/kbprofiles/amc/ICICI.png",
                              }}
                              resizeMode="contain"
                              style={{ height: 20, width: 20 }}
                            />
                          </View>
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "700",
                              marginLeft: 20,
                            }}
                          >
                            HDFC Bank Limited
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        marginLeft: "1.9%",
                        marginRight: "1.9%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: 80,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#7e838f",
                          }}
                        >
                          Date
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#454e60",
                            fontWeight: "700",
                          }}
                        >
                          17/02/2023
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#7e838f",
                          }}
                        >
                          Return (1M)
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#454e60",
                            fontWeight: "700",
                          }}
                        >
                          1.3%
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#7e838f",
                          }}
                        >
                          Amount
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#454e60",
                            fontWeight: "700",
                          }}
                        >
                          ₹ 500/-
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#7e838f",
                          }}
                        >
                          Action
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#454e60",
                            fontWeight: "700",
                          }}
                        >
                          SIP
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {}}
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "bold",
                              fontSize: 15,
                              color: "#ffffff",
                              paddingVertical: 12,
                              paddingHorizontal: 47,
                              backgroundColor: "#410DAA",
                              borderRadius: 4,
                            }}
                          >
                            Buy
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ marginTop: 20, marginBottom: 80 }}>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <View
                    style={{
                      marginLeft: "5%",
                      marginRight: "5%",
                      backgroundColor: "white",
                      width: "90%",
                      height: 140,
                      borderWidth: 1,
                      borderRadius: 5,
                      borderColor: "rgba(65, 13, 170, 0.3)",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        marginLeft: "1.9%",
                        marginRight: "1.9%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 30,
                        marginTop: 20,
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 5,
                              borderWidth: 1,
                              borderColor: "#410DAA1A",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.amazonaws.com/media/kbprofiles/amc/ICICI.png",
                              }}
                              resizeMode="contain"
                              style={{ height: 20, width: 20 }}
                            />
                          </View>
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "700",
                              marginLeft: 20,
                            }}
                          >
                            HDFC Bank Limited
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        marginLeft: "1.9%",
                        marginRight: "1.9%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: 80,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#7e838f",
                          }}
                        >
                          Date
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#454e60",
                            fontWeight: "700",
                          }}
                        >
                          17/02/2023
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#7e838f",
                          }}
                        >
                          Return (1M)
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#454e60",
                            fontWeight: "700",
                          }}
                        >
                          1.6%
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#7e838f",
                          }}
                        >
                          Amount
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#454e60",
                            fontWeight: "700",
                          }}
                        >
                          ₹ 500/-
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#7e838f",
                          }}
                        >
                          Action
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#454e60",
                            fontWeight: "700",
                          }}
                        >
                          SIP
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {}}
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Roboto",
                              fontWeight: "bold",
                              fontSize: 15,
                              color: "#ffffff",
                              paddingVertical: 12,
                              paddingHorizontal: 47,
                              backgroundColor: "#410DAA",
                              borderRadius: 4,
                            }}
                          >
                            Buy
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </>
          </ScrollView>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    padding: 5,
  },
  modalContentContainer: {
    backgroundColor: "white",
    padding: 8,
    paddingRight: 0,
    borderRadius: 4,
    width: "100%",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default MFRecommendations;
