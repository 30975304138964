import React from "react";
import {
  View,
  Text,
  SafeAreaView,
  StyleSheet,
  Platform,
  TouchableOpacity,
  Image,
  ScrollView,
  Dimensions,
} from "react-native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { connect } from "react-redux";
import amplitude from "../../../utils/amplitude";
import theme from "../../../static/theme";
import { StackActions } from "@react-navigation/routers";
import { getUserDetails, isMobile } from "../../../utils";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import Modal from "../../onboarding/modal";
import AntDesign from "@expo/vector-icons/AntDesign";
import { md } from "../../../Responsive";
import { getCustomerDetails } from "../../../api";

const { width } = Dimensions.get("window");

const RISK_PROFILE_DATA = {
  VCN: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23977.svg",
    color: "#ECE7F7",
    name: "Very Conservative",
    description:
      "Highy conservative investors want to avoid loss of money.They do not wish to take any risk to earn higher returns.",
  },
  CON: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23968.svg",
    color: "#C6B6E6",
    name: "Conservative",
    description:
      "Consevative Investors wish to protect Investments.They have some Concern on Inflation and wish make atleast inflation beating returns.",
  },
  MOD: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23969.svg",
    color: "#A086D5",
    name: "Moderate",
    description:
      "Moderate Investors seek to protect capital, while investing in suitable growth opportunities.",
  },
  MAG: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23970.svg",
    color: "#7A56C4",
    name: "Moderately aggressive",
    description:
      "Aggressive investors are willing to take some risk for additional growth in capital. they are willing to take some short term volatility if it gives higher growth.",
  },
  VAG: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23971.svg",
    color: "#410DAA",
    name: "Very Aggressive",
    description:
      "Very aggressive investors wants to achieve the maximum possible returns. They are not worried too much about losing capital in short term.",
  },
};

class RiskProfileSummary extends React.Component {
  constructor(props) {
    super(props);
    let taken = null,
      risk_profile = null;
    const { params } = this.props.route;
    if (params) {
      taken = params.taken || null;
      risk_profile = params.risk_profile || null;
    }
    const {
      current_subscription_plan = null,
      risk_profile: risk_profile_previous = null,
    } = getUserDetails();
    this.state = {
      taken,
      risk_profile: risk_profile || risk_profile_previous,
      current_subscription_plan,
      showAlert: false,
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("summary",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("summary", { path: this.props.navigation.getState().routeName });
    }
    try {
      let { risk_profile = null } = this.state;
      let userDetails = await getUserDetails();
      let data = await getCustomerDetails(userDetails.customer_id);
      if (risk_profile && risk_profile in RISK_PROFILE_DATA) {
        const { image, color, description, name } =
          RISK_PROFILE_DATA[data.risk_profile];
        this.setState({ image, color, description, name });
      }
    } catch (error) {}
  }

  handleGoToSuggestedFunds = () => {
    const { current_subscription_plan = null } = this.state;
    let routeName = "PersonalisedFunds";
    if (!current_subscription_plan) return;
    if (current_subscription_plan == "BP") {
      routeName = "MySubscription";
      this.props.navigation.navigate(`${routeName}`);
    } else {
      this.props.navigation.dispatch(
        StackActions.replace("App", {
          screen: "Dashboard",
          params: {
            screen: "Account",
            params: {
              screen: routeName,
            },
          },
        })
      );
    }
  };

  handleGoBack = () => {
    try {
      const { params = {} } = this.props.route;
      let from = "InvestmentOverview",
        stack = "Portfolio";
      if (params) {
        from = params.from || from;
        stack = params.stack || stack;
      }
      const { from: f, stack: s, ...restParams } = params;
      if (stack) {
        this.props.navigation.dispatch(
          StackActions.replace("App", {
            screen: "Dashboard",
            params: {
              screen: stack,
              params: { screen: from, params: { ...restParams } },
            },
          })
        );
      }
    } catch (error) {}
  };

  handleModalOpen = () => {
    this.setState({ showAlert: true });
  };

  render() {
    const { container } = styles;
    let {
      taken = null,
      image,
      color,
      description,
      name,
      showAlert,
    } = this.state;

    if (!name) return <View />;
    const modified_date = taken ? taken.split() : null;
    return (
      <SafeAreaView style={container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Risk Assesment"]}
        />
        {showAlert && (
          <Modal
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ paddingBottom: 3, width: md(width) ? 691 : "100%" }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingHorizontal: 10,
                  paddingVertical: 10,
                  backgroundColor: "rgba(65, 13, 170, 0.1)",
                }}
              >
                <Text style={styles.sig}>Risk Profile & Significance</Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showAlert: false });
                  }}
                >
                  <AntDesign name="close" size={24} color="black" />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  paddingHorizontal: 44,
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <View style={styles.infoBoxContainer}>
                  <Text style={styles.high}>
                    Highy conservative investors want to avoid loss of
                    money.They do not wish to take any risk to earn higher
                    returns.
                  </Text>
                  <TouchableOpacity style={styles.infoBox}>
                    <Text style={[styles.btn1, { backgroundColor: "#ECE7F7" }]}>
                      Very Conservative
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.infoBoxContainer}>
                  <Text style={styles.high}>
                    Conservative Investors wish to protect investments.They have
                    some concern on inflation & wish make atleast
                    inflation-beating returns.
                  </Text>
                  <TouchableOpacity style={styles.infoBox}>
                    <Text style={[styles.btn1, { backgroundColor: "#C6B6E6" }]}>
                      Conservative
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.infoBoxContainer}>
                  <Text style={styles.high}>
                    Moderate Investors seek to protect capital, while investing
                    in suitable growth opportunities
                  </Text>
                  <TouchableOpacity style={styles.infoBox}>
                    <Text style={[styles.btn, { backgroundColor: "#A086D5" }]}>
                      Moderate
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.infoBoxContainer}>
                  <Text style={styles.high}>
                    Aggressive investors are willing to take some risk for
                    additional growth in capital. they are willing to take some
                    short term volatility if it gives higher growth.
                  </Text>
                  <TouchableOpacity style={styles.infoBox}>
                    <Text style={[styles.btn, { backgroundColor: "#7A56C4" }]}>
                      Moderately aggressive
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.infoBoxContainer}>
                  <Text style={styles.high}>
                    Very aggressive investors wants to achieve the maximum
                    possible returns. They are not worried too much about losing
                    capital in short term.
                  </Text>
                  <TouchableOpacity style={styles.infoBox}>
                    <Text style={[styles.btn, { backgroundColor: "#410DAA" }]}>
                      Very Aggressive
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        )}
        <ScrollView>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: 8,
              marginTop: 64,
            }}
          >
            <View
              style={{
                width: 16,
                height: 16,
                borderRadius: 8,
                marginRight: 16,
                backgroundColor: color,
              }}
            />
            <Text>{name}</Text>
          </View>
          {modified_date && (
            <Text
              style={{
                color: "#121212",
                fontSize: 11,
                textAlign: "center",
                marginBottom: 16,
              }}
            >
              taken on: {modified_date[0]} &nbsp;&nbsp;&nbsp;&nbsp;
              {modified_date[1]}
            </Text>
          )}

          <View style={{ flex: 1, marginVertical: 16 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <View style={{ alignItems: "center" }}>
                <Image source={{ uri: image }} style={styles.image} />
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: -40,
                marginBottom: 20,
                justifyContent: "center",
              }}
            >
              <Text style={[styles.con, { color: color }]}>
                {name}
                <TouchableOpacity
                  mode="text"
                  uppercase={false}
                  onPress={this.handleModalOpen}
                >
                  <Image
                    source="https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/help+1.png"
                    style={{
                      width: 19,
                      height: 17,
                      resizeMode: "contain",
                      marginLeft: 5,
                    }}
                  />
                </TouchableOpacity>
              </Text>
            </View>
            <Text
              style={{ textAlign: "center", padding: 16, marginBottom: 32 }}
            >
              {description}
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 16,
                marginTop: 20,
              }}
            >
              <TouchableOpacity
                style={{
                  width: isMobile ? "100%" : "30%",
                  backgroundColor: theme.colors.NEW_BRAND_COLOR,
                  borderRadius: 6,
                  alignItems: "center",
                  justifyContent: "center",
                  paddingVertical: 12,
                  paddingHorizontal: 30,
                  marginLeft: 15,
                }}
                onPress={() => {
                  this.props.navigation.navigate("MyRiskProfile");
                }}
              >
                <Text style={{ fontSize: 15, color: "#fff" }}>
                  Revise Assessment
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  image: {
    width: 200,
    height: 200,
  },
  sig: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 18,
    color: "#121212",
  },
  high: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#464E5F",
    paddingRight: 55,
  },
  infoBoxContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    paddingVertical: 14,
  },
  infoBox: {
    minWidth: isMobile ? 100 : 137,
    justifyContent: "center",
    alignItems: "center",
  },
  btn: {
    borderRadius: 6,
    fontFamily: "Roboto",
    fontSize: isMobile ? 9 : 12,
    lineHeight: 14,
    fontWeight: "bold",
    color: "#ffffff",
    paddingVertical: 8.5,
    minWidth: isMobile ? 100 : 137,
    textAlign: "center",
  },
  btn1: {
    borderRadius: 6,
    fontFamily: "Roboto",
    fontSize: isMobile ? 9 : 12,
    lineHeight: 14,
    fontWeight: "bold",
    color: "#410daa",
    paddingVertical: 8.5,
    minWidth: isMobile ? 100 : 137,
    textAlign: "center",
  },
});

export default connect(null)(RiskProfileSummary);
