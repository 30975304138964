import React from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Text, Button } from "react-native-paper";
import { isMobile } from "../../utils";
import amplitude from "../../utils/amplitude";

export default class EmotionalWellnessStrip extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { navigation } = this.props;
    return (
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ width: "100%" }}
      >
        <View
          style={{
            padding: 8,
            paddingLeft: 0,
            width: isMobile ? "100%" : "70%",
          }}
        >
          <TouchableOpacity
            style={styles.stripContainer}
            onPress={() => {
              amplitude.logEvent("Assess_Wellness_Card_Clicked");
              navigation.navigate("App", {
                screen: "ViewMode",
                params: {
                  screen: "EmotionalWellness",
                  params:{}
                }
              })
              // navigation.navigate("EmotionalWellness");
            }}
          >
            <View style={styles.subContainer}>
              <View>
                {isMobile ? (
                  <View>
                    <View>
                      <Text
                        style={{
                          color: "#464E5F",
                          fontSize: 14,
                          paddingBottom: 5,
                        }}
                      >
                        Short quizzes to measure your Financial
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "#464E5F",
                          fontSize: 14,
                          paddingBottom: 10,
                        }}
                      >
                        Wellness
                      </Text>
                    </View>
                  </View>
                ) : (
                  <View>
                    <Text
                      style={{
                        color: "#464E5F",
                        fontSize: 14,
                        paddingBottom: 10,
                      }}
                    >
                      Short quizzes to measure your Emotional Wellness
                    </Text>
                  </View>
                )}
                <View style={styles.subContainerOne}>
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/EmotionalWellness.png",
                    }}
                    style={{
                      width: 48,
                      height: 48,
                      resizeMode: "contain",
                      flex: 1,
                      alignItems: "center",
                      marginLeft: -7,
                    }}
                  />
                  <Image
                    source={{
                      uri: "",
                    }}
                    style={{
                      width: 48,
                      height: 48,
                      resizeMode: "contain",
                      flex: 1,
                      alignItems: "center",
                    }}
                  />
                  <Image
                    source={{
                      uri: "",
                    }}
                    style={{
                      width: 48,
                      height: 48,
                      resizeMode: "contain",
                      flex: 1,
                      alignItems: "center",
                    }}
                  />
                  <Image
                    source={{
                      uri: "",
                    }}
                    style={{
                      width: 48,
                      height: 48,
                      resizeMode: "contain",
                      flex: 1,
                      alignItems: "center",
                    }}
                  />
                </View>
              </View>
              {!isMobile && (
                <View>
                  <Button
                    uppercase={false}
                    mode="contained"
                    labelStyle={{
                      color: "#410DAA",
                      fontWeight: "600",
                    }}
                    onPress={() => {
                      amplitude.logEvent("Emotional_Wellness_Card_Clicked");
                      navigation.navigate("App", {
                        screen: "ViewMode",
                        params: {
                          screen: "EmotionalWellness",
                          params:{}
                        }
                      })
                    }}
                    style={{
                      marginTop: 1,
                      marginRight: 7,
                      borderColor: "#410DAA",
                      borderWidth: 1,
                      backgroundColor: "white",
                      borderRadius: 6,
                      height: 37,
                      width: 140,
                    }}
                  >
                    Assess Now
                  </Button>
                </View>
              )}
            </View>
            {isMobile && (
              <View>
                <Button
                  uppercase={false}
                  mode="contained"
                  labelStyle={{
                    color: "#410DAA",
                    fontWeight: "600",
                  }}
                  onPress={() => {
                    amplitude.logEvent("Emotional_Wellness_Card_Clicked");
                    navigation.navigate("App", {
                      screen: "ViewMode",
                      params: {
                        screen: "EmotionalWellness",
                        params:{}
                      }
                    })
                  }}
                  style={{
                    marginTop: 20,
                    marginBottom: 5,
                    borderColor: "#410DAA",
                    borderWidth: 1,
                    backgroundColor: "white",
                    borderRadius: 6,
                    width: 150,
                  }}
                >
                  Assess Now
                </Button>
              </View>
            )}
          </TouchableOpacity>
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  subContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subContainerOne: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 2,
    width: isMobile ? "103%" : "100%",
  },
  stripContainer: {
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "rgba(65, 13, 170, 0.3)",
    height: isMobile ? 200 : 115,
    width: isMobile ? "102.3%" : "92%",
    padding: 15,
  },
});
