import { GET_DASHBOARD, LOGOUT } from "../actions/types";

const initialState = {
  summaryResponse: null,
  viewModeUserId: null,
  landingPageResponse: null,
  tokenResponse: null,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return {
        ...state,
        summaryResponse: {
          ...action.payload.summaryResponse,
        },
        viewModeUserId: {
          ...action.payload.viewModeUserId,
        },
        tokenResponse: {
          ...action.payload.tokenResponse,
        },
        landingPageResponse: {
          ...action.payload.landingPageResponse,
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default dashboardReducer;
