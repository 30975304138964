import React from "react";
import {
  View,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  Image,
} from "react-native";
import { Text } from "react-native-paper";
import amplitude from "../../utils/amplitude";
import { getStockRecommendations, stocksPlaceOrder } from "../../api";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { isMobile } from "../../utils/index";
import TextInputField from "../Common/Inputs/InputField";
import moment from "moment";
import { MAIN_URL } from "../../api/urls";
import { GATEWAY_ENVIRONMENT } from "../../api/apiConfig";
import CommaTextField from "../../screens/mutualfunds/goals/commaTextField";
import { ShowToast } from "../Common/Toast";

class StockRecommendations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      stockList: "",
      showToast: false,
      toastTitle: "",
      loading: false,
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("stockRecommendations", {
        path: global.location.href,
      });
    } else {
      amplitude.logEvent("stockRecommendations", {
        path: this.props.navigation.getState().routeName,
      });
    }
    let stockResp = await getStockRecommendations();
    if (stockResp && stockResp.code == "100") {
      this.setState({ stockList: stockResp.result });
    }
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Stock Recommendations"]}
          onPressBack={() => {
            this.props.navigation.navigate("Plans");
          }}
        />
        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title={this.state.toastTitle}
            duration="10"
          />
        ) : null}
        <ScrollView>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            style={styles.cardContainer}
          >
            <>
              {this.state.stockList &&
                this.state.stockList.stocks.map((item) => {
                  return (
                    <View
                      style={{
                        marginTop: 20,
                        marginBottom: isMobile ? 80 : 20,
                      }}
                    >
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View
                          style={{
                            marginLeft: "5%",
                            marginRight: "5%",
                            backgroundColor: "white",
                            width: "90%",
                            height: 140,
                            borderWidth: 1,
                            borderRadius: 5,
                            borderColor: "rgba(65, 13, 170, 0.3)",
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              marginLeft: "1.9%",
                              marginRight: "1.9%",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              height: 30,
                              marginTop: 20,
                            }}
                          >
                            <View
                              style={{
                                flex: 1,
                                flexDirection: "column",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 16,
                                    color: "#464E5F",
                                    fontWeight: "700",
                                  }}
                                >
                                  {item.marketscrip__name}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              marginLeft: "1.9%",
                              marginRight: "1.9%",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              height: 80,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "column",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#7e838f",
                                }}
                              >
                                Date
                              </Text>
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#454e60",
                                  fontWeight: "700",
                                }}
                              >
                                {moment().format("DD/MM/YYYY")}
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#7e838f",
                                }}
                              >
                                ISIN Number
                              </Text>
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#454e60",
                                  fontWeight: "700",
                                }}
                              >
                                {item.marketscrip__isin_number}
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#7e838f",
                                }}
                              >
                                CMP
                              </Text>
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#454e60",
                                  fontWeight: "700",
                                }}
                              >
                                {item.marketscrip__latest_closing_price}
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <CommaTextField
                                value={this.state.amount}
                                noEdit={false}
                                onValueChange={(text) => {
                                  this.setState({ amount: text });
                                  const numericText = text.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  this.setState({ amount: numericText });
                                }}
                                placeHolder={"Enter Amount"}
                                containerStyle={{
                                  width: 150,
                                }}
                                textStyle={{
                                  fontSize: 14,
                                  paddingVertical: 10,
                                  paddingHorizontal: 5,
                                }}
                              />
                            </View>
                            <View
                              style={{
                                flexDirection: "column",
                              }}
                            >
                              <TouchableOpacity
                                onPress={async () => {
                                  this.setState({
                                    showToast: false,
                                    toastTitle: "",
                                    loading: true,
                                  });
                                  let current_url = window.location.href;
                                  let url_path = current_url.split("/");
                                  let redirection_url =
                                    url_path[3] + "/" + url_path[4];

                                  let payload = {
                                    reco_list: [
                                      {
                                        pk: item.marketscrip__pk,
                                        quantity: parseInt(
                                          this.state.amount /
                                          item.marketscrip__latest_closing_price
                                        ),
                                        transaction_type: "B",
                                        limit_price: null,
                                      },
                                    ],
                                    redirection_url,
                                  };
                                  if (
                                    parseInt(
                                      parseFloat(this.state.amount) /
                                      item.marketscrip__latest_closing_price
                                    ) <= 0
                                  ) {
                                    this.setState({
                                      showToast: true,
                                      toastTitle: `Minimum Invest Amount: ${item.marketscrip__latest_closing_price}`,
                                      loading: false,
                                    });
                                    return;
                                  }
                                  let placeOrderResp = await stocksPlaceOrder(
                                    payload
                                  );
                                  global.open(
                                    MAIN_URL +
                                    "/" +
                                    placeOrderResp.result.place_order_url +
                                    GATEWAY_ENVIRONMENT,
                                    "_self"
                                  );
                                }}
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >

                                {this.state.loading ? (
                                  <ActivityIndicator size="small" color="#410daa" />
                                ) : (
                                  <Text
                                    style={{
                                      fontFamily: "Roboto",
                                      fontWeight: "bold",
                                      fontSize: 15,
                                      color: "#ffffff",
                                      paddingVertical: 12,
                                      paddingHorizontal: 47,
                                      backgroundColor: "#410DAA",
                                      borderRadius: 4,
                                    }}
                                  >
                                    Buy
                                  </Text>
                                )}
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  );
                })}
            </>
          </ScrollView>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    padding: 5,
  },
  modalContentContainer: {
    backgroundColor: "white",
    padding: 8,
    paddingRight: 0,
    borderRadius: 4,
    width: "100%",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default StockRecommendations;
