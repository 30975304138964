import React from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import { Text } from "react-native-paper";
import { sm, md, lg, xl, twoxl } from "../../Responsive";
import global from "./globalStyles";

const window = Dimensions.get("window");

const getTableRows = (previousPlan, currentPlan) => {
    const rows = []
    for(let i =0 ; i<previousPlan.properties.length; i++) {
        rows.push(
            <View style={[styles.tableRow, {borderBottomWidth: i === (previousPlan.properties.length - 1) ? 0 : undefined}]}>
                    <View style={styles.tableLeftValues}>
                        <Text style={[styles.text, global.arial]}>{previousPlan.properties[i].name}</Text>
                        {(previousPlan.properties[i].additional )&& (<Text style={[styles.tableSub, global.arial]}>{previousPlan.properties[i].additional}</Text>)}
                    </View>
                    <View style={styles.tableRightValues}>
                        <Text style={[styles.text, global.arial, {width: 87.5}]}>{previousPlan.properties[i].value}</Text>
                        <Text style={[styles.text, global.arial, {width: 87.5}]}>{currentPlan.properties[i].value}</Text>
                    </View>
            </View>
        )
    }
    return rows
}

export default function AUA ({previousPlan, currentPlan}) {
    return (
        <View style={styles.container}>
            <Text style={[styles.heading, global.arial]}>Compare AUA (Amount under Advisory)</Text>
            <View style={styles.table}>
                <View style={[styles.tableRow, styles.tableHeader]}>
                    <Text style={[styles.text, global.arial]}>What you get</Text>
                    <View style={styles.tableRightValues}>
                        <Text style={[styles.text,global.arial, {marginLeft: 8, flexGrow: 1, width: 87.5}]}>{previousPlan.name}</Text>
                        <Text style={[styles.text, {marginLeft: 8, flexGrow: 1, width: 87.5}, global.arial]}>{currentPlan.name}</Text>
                    </View>
                    
                </View>
                {getTableRows(previousPlan, currentPlan)}
            </View>
            <Text style={[styles.sub, global.arial]}>* Fee effective monthly when billing is done yearly</Text>
            <Text style={[styles.sub, global.arial]}>As per SEBI rules, advisory services cannot be provided under
                free plans. Distributors are not allowed to provide any
                investment advice. Jamā Wealth brings the best
                SEBI individual RIA’s for you service.
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {},
    text: {
        color: 'black',
        fontSize: 12,
        textTransform: 'capitalize'
    },
    heading: {
        fontWeight: 'bold',
        fontSize: 20,
        textAlign :'center',
        paddingTop: 30,
        paddingBottom: 30,
        width: 252,
        alignSelf: 'center',
        color: 'black'
    },
    table: {
        backgroundColor: '#E0F4FF',
        padding: 17,
        justifyContent: 'center'
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#DDD',
        justifyContent: 'space-between',
        paddingTop: 10.5,
        paddingBottom: 10.5,
    },
    tableHeader: {
        borderColor: 'black'
    },
    tableRightValues: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: 170,
    },
    tableLeftValues: {
        display: 'flex',
        alignSelf: 'stretch',
        width: 166,
        flexShrink: 1
    },
    tableSub: {
        fontSize: 12,
        fontStyle: 'italic',
        opacity: 0.5,
        color: 'black'
    },
    sub: {
        fontSize: 12,
        fontStyle: 'italic',
        opacity: 0.5,
        marginTop: 6,
        paddingLeft: 16,
        paddingRight: 16,
        maxWidth: md(window.width) ? 400: undefined,
        color: 'black'
    }
})