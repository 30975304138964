import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Keyboard,
  Platform,
} from "react-native";
import {
  checkCustomerSignup,
  getLeadData,
  getLoginBannerDetails,
  mobileOtpVerificationBeforeSignup,
  onboardingSendOTP,
  SignUpUser,
} from "../../../api";
import { md } from "../../../Responsive";
import { isMobile, isWeb } from "../../../utils";
import Header from "../login_header";
const { width } = Dimensions.get("window");
import { saveUserProfileData, logIn } from "../../../actions";
import { connect } from "react-redux";
import { StackActions } from "@react-navigation/routers";
import amplitude from "../../../utils/amplitude";
import { ShowToast } from "../../../components/Common/Toast";
import Toast from "react-native-toast-message";

class QuizLogin extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let corp_code = "";
    let subscription_plan_code = "";
    let lead_id = "";
    let quiz_id = null;
    let from = null;
    if (params) {
      corp_code = params.ccd || "";
      quiz_id = params.quiz_id || null;
      quiz_id = isNaN(quiz_id) ? null : quiz_id;
      subscription_plan_code = params.pcd || "";
      lead_id = params.ldid || "";
      from = params.from || "";
    }
    let leftText = "";
    let RightText = "";
    let isQuiz = false;
    let isFinplan = false;
    let isRetirementPlan = false;
    let isChildEducationPlan = false;
    let isEmergencyFund = false;
    let isRiskProfileData = false;
    let isImportStock = false;
    let isImportMF = false;
    let isJamaAcademy = false;
    let hostname = Platform.OS == "web" ? global.location.href : "mobile";
    if (hostname.includes("assess")) {
      isQuiz = true;
      leftText = "Know & Improve Your Financial Wellness Score";
      RightText = "Kindly fill this form to receive your assessment score";
    }
    if (hostname.includes("finplan")) {
      isFinplan = true;
      leftText = "Unveil Your Personalised Financial Plan";
      RightText =
        "Complete this form to get your tailor-made financial blueprint";
    }
    if (hostname.includes("retirement")) {
      isRetirementPlan = true;
      leftText = "Estimate Your Comfortable Retirement";
      RightText =
        "Fill out this form to access your unique retirement strategy";
    }
    if (hostname.includes("edu")) {
      isChildEducationPlan = true;
      leftText = "Secure Your Child's Academic Future";
      RightText = "Complete this form to explore your child's education plan";
    }
    if (hostname.includes("emergencyfund")) {
      isEmergencyFund = true;
      leftText = "Gauge Your Emergency Preparedness";
      RightText = "Submit this form to evaluate your emergency fund strategy";
    }
    if (hostname.includes("riskprofile")) {
      isRiskProfileData = true;
      leftText = "Personalise Your Investing With Your Risk Assessment";
      RightText = "Fill out this form to assess your risk profile";
    }
    if (hostname.includes("importstocks")) {
      isImportStock = true;
      leftText = "Import/Track Your Stock Portfolio";
      RightText =
        "Complete this form for a health check on your stock portfolio";
    }
    if (hostname.includes("importmf")) {
      isImportMF = true;
      leftText = "Import/Track Your Mutual Fund Investments";
      RightText =
        "Complete this form for a health check on your mutual fund portfolio";
    }
    if (hostname.includes("academy")) {
      isJamaAcademy = true;
      leftText = "Experience Your Learning Journey";
      RightText = "Complete this form to dive into our knowledge academy";
    }
    let isCorpCode = false;
    if (corp_code != "") {
      isCorpCode = true;
    }
    this.state = {
      quiz_id,
      data: null,
      isLoading: false,
      name: "",
      isQuiz,
      isFinplan,
      isRetirementPlan,
      isChildEducationPlan,
      isEmergencyFund,
      isRiskProfileData,
      isImportStock,
      isImportMF,
      isJamaAcademy,
      leftText,
      RightText,
      email: "",
      mobile: "",
      password: "",
      repassword: "",
      is_ria: false,
      showOTP: false,
      showResendOTP: false,
      mobileError: "",
      corp_code,
      isCorpCode,
      subscription_plan_code,
      lead_id,
      is_correct_lead: false,
      errorText: "",
      nameError: "",
      emailError: "",
      mobileError: "",
      passwordError: "",
      repasswordError: "",
      captchaText: "",
      captchaError: "",
      is_club: false,
      from,
      bannerData: {
        logo_image_url: null,
        name: null,
        ria_code: null,
        subscriber_type: null,
        support_email: null,
        support_person_name: null,
        support_phone_number: null,
        is_jamawealth: null,
      },
    };
  }

  loadBannerData = async () => {
    let { bannerData, lead_id } = this.state;
    if (lead_id != "") {
      const lead_payload = {
        "leadpk": lead_id
      }
      let resp = await getLeadData(lead_payload);
      if (resp && resp.code && resp.code == "100") {
        this.setState({
          name: resp.result.name,
          email: resp.result.email,
          mobile: resp.result.phone_number,
          is_correct_lead: true,
        });
      }
    }
    let response = null;
    let hostname = global.location.hostname;
    let is_club = false;
    let is_preferred_circle = false;
    if (hostname.includes("club")) {
      is_club = true;
    }
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    try {
      response = await getLoginBannerDetails();
    } catch (err) {}
    if (response && response.code == 100) {
      bannerData = response.result;
      this.setState({
        bannerData: bannerData,
        is_club: is_club,
        is_preferred_circle: is_preferred_circle,
      });
    } else {
      bannerData.logo_image_url =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/website/jam%C4%81_header.png";
      bannerData.name = "Jama Wealth";
      bannerData.ria_code = "INA 200015583";
      bannerData.subscriber_type = "RIAC";
      bannerData.support_email = "advisor@jamawealth.com";
      bannerData.support_person_name = "Ram Kalyan Medury";
      bannerData.support_phone_number = "9870264643";
      bannerData.is_jamawealth = true;
      this.setState({
        bannerData: bannerData,
        errorText: response.msg,
        is_club: is_club,
        is_preferred_circle: is_preferred_circle,
      });
    }
  };

  handleSendOTP = async () => {
    const { name, email, mobile } = this.state;
    let regEx =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    try {
      this.setState({ isLoading: true });
      if (!name) {
        this.setState({
          nameError: "Please enter a name",
          isLoading: false,
          emailError: "",
          mobileError: "",
          passwordError: "",
          repasswordError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          nameError: "",
        });
      }
      if (!email || !regEx.test(email)) {
        this.setState({
          emailError: "Please enter a valid email",
          isLoading: false,
          nameError: "",
          mobileError: "",
          passwordError: "",
          repasswordError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          emailError: "",
        });
      }
      if (!mobile) {
        this.setState({
          mobileError: "Please enter a valid mobile number",
          isLoading: false,
          nameError: "",
          emailError: "",
          passwordError: "",
          repasswordError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          mobileError: "",
        });
      }

      let getOtpPayload = {
        target_mobile_number: mobile.toString(),
      };
      const mobileOtpResp = await mobileOtpVerificationBeforeSignup(
        getOtpPayload
      );
      if (
        mobileOtpResp &&
        mobileOtpResp.resp_code &&
        mobileOtpResp.resp_code == "100"
      ) {
        this.setState({ isLoading: false, showOTP: true });
      } else {
        this.setState({
          error: true,
          errorText: "Please enter a valid 10 digit mobile number",
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        errorText:
          response && response.message
            ? response.message
            : "Something went wrong!",
        error: true,
        isLoading: false,
      });
    }
  };

  handleResend = async () => {
    this.setState({ showResendOTP: false });
    const { mobile } = this.state;
    const data = {
      username: mobile,
    };
    const response = await onboardingSendOTP(data);
    if (response && response.otp_generated_date) {
      this.setState({ showResendOTP: true });
    }
  };

  handleVerifyOTP = async () => {
    const { mobile, otpNumber } = this.state;
    this.setState({ isLoading: true });
    try {
      if (!otpNumber) {
        this.setState({
          otpError: "Please enter a valid 6 digit otp",
          isLoading: false,
          errorText: "",
          passwordError: "",
          repasswordError: "",
        });
        return;
      } else {
        this.setState({
          otpError: "",
        });
      }

      let getOtpPayload = {
        target_mobile_number: mobile.toString(),
        otp_value: otpNumber.toString(),
      };

      const mobileOtpResp = await mobileOtpVerificationBeforeSignup(
        getOtpPayload
      );
      if (mobileOtpResp && mobileOtpResp.result) {
        this.handleContinue();
      } else {
        this.setState({
          error: true,
          errorText:
            mobileOtpResp && mobileOtpResp.message
              ? mobileOtpResp.message
              : "Please enter the correct otp",
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    }
  };

  handleContinue = async () => {
    const {
      name,
      email,
      mobile,
      corp_code,
      subscription_plan_code,
      lead_id,
      is_club,
      is_preferred_circle,
      password,
      is_ria,
      otpNumber,
      bannerData,
    } = this.state;
    let regEx =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    try {
      this.setState({ isLoading: true });
      if (!name) {
        this.setState({
          nameError: "Please enter a name",
          isLoading: false,
          emailError: "",
          mobileError: "",
          passwordError: "",
          repasswordError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          nameError: "",
        });
      }
      if (!email || !regEx.test(email)) {
        this.setState({
          emailError: "Please enter a valid email",
          isLoading: false,
          nameError: "",
          mobileError: "",
          passwordError: "",
          repasswordError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          emailError: "",
        });
      }
      if (!mobile) {
        this.setState({
          mobileError: "Please enter a valid mobile number",
          isLoading: false,
          nameError: "",
          emailError: "",
          passwordError: "",
          repasswordError: "",
          errorText: "",
        });
        return;
      } else {
        this.setState({
          mobileError: "",
        });
      }

      const payload = {
        email: email.toString(),
        mobilenumber: mobile.toString(),
      };

      const checkSignUpResponse = await checkCustomerSignup(payload);
      if (checkSignUpResponse && checkSignUpResponse.code == 100) {
        let signupPayload = {
          first_name: name.toString(),
          username: mobile.toString(),
          email: email.toString(),
          mobilenumber: mobile.toString(),
          showPassword: false,
          showConfirmPassword: false,
          corp_code: corp_code.toString(),
          subscription_plan_code: subscription_plan_code.toString(),
          lead_id: lead_id.toString(),
          password: password.toString(),
          otp: otpNumber.toString(),
          database_host: global.location.hostname.replace(/\./g, "_"),
        };

        const response = await SignUpUser(signupPayload);
        amplitude.setUserId(mobile);
        const eventProperties = {
          username: response.first_name,
          mobile: mobile,
          email: response.email,
        };
        amplitude.logEvent("Signup_Successful", eventProperties);
        if (response && response.pk != null) {
          let data = { ...response };
          data = Object.assign(data, {
            is_ria: is_ria,
            bannerData: bannerData,
            is_club: is_club,
            is_preferred_circle: is_preferred_circle,
          });
          Toast.hide();
          const loggedInTime = new Date().getTime();
          this.props.logIn(loggedInTime);
          this.props.saveUserProfileData(data);
          let url = new URL(global.location.href);
          if (this.state.isQuiz) {
            location.replace(
              url.origin +
                `/dashboard/finawareness/quiz/${this.state.quiz_id}?from=fi`
            );
          }
          if (this.state.isFinplan) {
            location.replace(url.origin + `/account/finplan`);
          }
          if (this.state.isRetirementPlan) {
            location.replace(
              url.origin +
                `/goals/finplan/basic?from=Home&stack=Dashboard&type=RETIREMENT`
            );
          }
          if (this.state.isChildEducationPlan) {
            location.replace(
              url.origin +
                `/goals/finplan/basic?from=Home&stack=Dashboard&type=CHILDREN`
            );
          }
          if (this.state.isEmergencyFund) {
            location.replace(
              url.origin +
                `/goals/finplan/basic?from=Home&stack=Dashboard&type=EMF`
            );
          }
          if (this.state.isRiskProfileData) {
            location.replace(url.origin + `/account/riskprofile`);
          }
          if (this.state.isImportStock) {
            location.replace(
              url.origin + `/dashboard/importstocks?from=dashboard`
            );
          }
          if (this.state.isImportMF) {
            location.replace(
              url.origin + `/dashboard/importmutualfunds?from=dashboard`
            );
          }
          if (this.state.isJamaAcademy) {
            location.replace(url.origin + `/dashboard/edu_home`);
          }
        }
      } else {
        let response = checkSignUpResponse.results.user_response;
        let data = { ...response };
        data = Object.assign(data, {
          is_ria: is_ria,
          bannerData: bannerData,
        });
        Toast.hide();
        const loggedInTime = new Date().getTime();
        this.props.logIn(loggedInTime);
        this.props.saveUserProfileData(data);
        let url = new URL(global.location.href);
        if (this.state.isQuiz) {
          location.replace(
            url.origin +
              `/dashboard/finawareness/quiz/${this.state.quiz_id}?from=fi`
          );
        }
        if (this.state.isFinplan) {
          location.replace(url.origin + `/account/finplan`);
        }
        if (this.state.isRetirementPlan) {
          location.replace(
            url.origin +
              `/goals/finplan/basic?from=Home&stack=Dashboard&type=RETIREMENT`
          );
        }
        if (this.state.isChildEducationPlan) {
          location.replace(
            url.origin +
              `/goals/finplan/basic?from=Home&stack=Dashboard&type=CHILDREN`
          );
        }
        if (this.state.isEmergencyFund) {
          location.replace(
            url.origin +
              `/goals/finplan/basic?from=Home&stack=Dashboard&type=EMF`
          );
        }
        if (this.state.isRiskProfileData) {
          location.replace(url.origin + `/account/riskprofile`);
        }
        if (this.state.isImportStock) {
          location.replace(
            url.origin + `/dashboard/importstocks?from=dashboard`
          );
        }
        if (this.state.isImportMF) {
          location.replace(
            url.origin + `/dashboard/importmutualfunds?from=dashboard`
          );
        }
        if (this.state.isJamaAcademy) {
          location.replace(url.origin + `/dashboard/edu_home`);
        }
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    }
  };

  componentDidMount() {
    let is_ria = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (hostname.includes("simplygrow.in")) {
      is_ria = true;
    }
    this.setState({
      is_ria: is_ria,
    });
    this.generateCaptcha();
    this.loadBannerData();
  }

  generateCaptcha = () => {
    var numberOne = Math.floor(Math.random() * 1000000) + 1;
    this.setState({ randomCaptcha: numberOne });
  };

  render() {
    const {
      isLoading,
      errorText,
      name,
      email,
      mobile,
      nameError,
      emailError,
      mobileError,
      otpNumber,
      otpError,
      bannerData,
      corp_code,
    } = this.state;
    return (
      <View style={styles.container}>
        <Header
          navigation={this.props.navigation}
          route={this.props.route}
          bannerData={bannerData}
        />
        {(this.state.is_preferred_circle || this.state.is_club) &&
        this.state.from ? (
          <ShowToast
            showToast={true}
            type="success"
            title={`Congratulations! You have successfully subscribed to our ${
              this.state.is_preferred_circle
                ? "Advisory Circle"
                : "Investor Club"
            } plan.`}
          />
        ) : null}
        {this.state.showOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent to your mobile number. Please verify in the box below"
          />
        ) : null}
        {this.state.showResendOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent to your mobile number. Please verify in the box below"
          />
        ) : null}
        <ScrollView keyboardShouldPersistTaps="always">
          <View style={styles.body}>
            {isWeb && (
              <View style={styles.left}>
                <Text style={styles.best}>{this.state.leftText}</Text>
                <Image
                  source={
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-2.png"
                  }
                  style={styles.left_logo}
                ></Image>
                <View style={styles.certicationsContainer}>
                  {bannerData.is_jamawealth ? (
                    <View style={styles.certication}>
                      <Image
                        source={
                          "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+11.png"
                        }
                        style={{ width: 18, height: 30 }}
                      />
                      <Text style={[styles.sebi, { paddingLeft: 10 }]}>
                        Goi{" "}
                        <Text style={{ fontWeight: "bold", color: "black" }}>
                          DIPP
                        </Text>{" "}
                        Registered
                      </Text>
                    </View>
                  ) : null}
                  {bannerData.is_jamawealth ? (
                    <View style={styles.certication}>
                      <Image
                        source={
                          "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+7.png"
                        }
                        style={{ width: 29, height: 25 }}
                      />
                      <Text style={[styles.sebi, { paddingLeft: 10.17 }]}>
                        ISO{" "}
                        <Text style={{ fontWeight: "bold", color: "black" }}>
                          9001
                        </Text>{" "}
                        Certified
                      </Text>
                    </View>
                  ) : null}
                  {bannerData.is_jamawealth ? (
                    <View style={styles.certication}>
                      <Image
                        source={
                          "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+7.png"
                        }
                        style={{ width: 29, height: 25 }}
                      />
                      <Text style={[styles.sebi, { paddingLeft: 10.17 }]}>
                        ISO{" "}
                        <Text style={{ fontWeight: "bold", color: "black" }}>
                          27001
                        </Text>{" "}
                        Certified
                      </Text>
                    </View>
                  ) : null}
                  <View style={styles.sebiContainer}>
                    <View style={{ paddingLeft: 0.4 }}>
                      <Text style={[styles.sebi]}>
                        SEBI Regd Investment Advisor
                      </Text>
                      <Text style={styles.ina}>{bannerData.ria_code}</Text>
                      <Text style={[styles.sebi_1]}>
                        Simply Grow Technologies Pvt Ltd.
                      </Text>
                    </View>
                  </View>
                  <View style={styles.sebiContainer}>
                    <View style={{ paddingLeft: 10.4 }}>
                      <Text style={[styles.sebi]}>
                        SEBI Registered Portfolio Manager
                      </Text>
                      <Text style={styles.ina}>INP000007881</Text>
                      <Text style={[styles.sebi_1]}>
                        Jama Wealth Asset Management Pvt Ltd.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
            <View style={styles.right}>
              <View style={[isMobile ? styles.mob_styl : styles.web_styl]}>
                <View style={{ alignItems: "center", marginTop: 32 }}>
                  {bannerData.is_jamawealth ? (
                    <Image
                      source={require("../../../assets/jama_logo.png")}
                      style={{ width: 60, height: 60 }}
                      resizeMode={"contain"}
                    />
                  ) : null}
                  <Text style={styles.welcomeTitle}>
                    Welcome to{" "}
                    {this.props.demoMode ? "SimplyGrow" : bannerData.name}
                  </Text>
                </View>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: 16,
                    lineHeight: 18,
                    color: "#222225",
                    paddingBottom: 30,
                    marginTop: -20,
                  }}
                >
                  {this.state.RightText}
                </Text>
                <View style={styles.inputsContainer}>
                  <View style={styles.inputContainer}>
                    <Text style={styles.mob_num}>Name</Text>
                    <TextInput
                      value={name}
                      style={styles.dmart_body_md}
                      placeholder={"Enter your name"}
                      editable={!this.state.is_correct_lead}
                      onFocus={() => {
                        this.setState({
                          nameError: "",
                          emailError: "",
                          mobileError: "",
                          passwordError: "",
                          repasswordError: "",
                          errorText: "",
                        });
                      }}
                      onChangeText={(text) => {
                        if (text.length < 30) {
                          this.setState({ name: text });
                        } else {
                          this.setState({ name: text.substring(0, 30) });
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.nativeEvent.key == "Enter") {
                        }
                      }}
                    />
                    {!!nameError && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {nameError || " "}
                      </Text>
                    )}
                  </View>
                  <View style={styles.inputContainer}>
                    <Text style={styles.mob_num}>Email</Text>
                    <TextInput
                      value={email}
                      style={styles.dmart_body_md}
                      placeholder={"Enter your email"}
                      editable={!this.state.is_correct_lead}
                      onFocus={() => {
                        this.setState({
                          nameError: "",
                          emailError: "",
                          mobileError: "",
                          passwordError: "",
                          repasswordError: "",
                          errorText: "",
                        });
                      }}
                      onChangeText={(text) => {
                        this.setState({ email: text });
                      }}
                      onKeyPress={(e) => {
                        if (e.nativeEvent.key == "Enter") {
                        }
                      }}
                    />
                    {!!emailError && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {emailError || " "}
                      </Text>
                    )}
                  </View>
                  <View style={styles.inputContainer}>
                    <Text style={styles.mob_num}>Mobile</Text>
                    <TextInput
                      value={mobile}
                      style={styles.dmart_body_md}
                      editable={!this.state.is_correct_lead}
                      placeholder={"Enter your mobile number"}
                      onFocus={() => {
                        this.setState({
                          nameError: "",
                          emailError: "",
                          mobileError: "",
                          passwordError: "",
                          repasswordError: "",
                          errorText: "",
                        });
                      }}
                      onChangeText={(text) => {
                        if (!isNaN(text)) {
                          if (text.length < 16) {
                            this.setState({ mobile: text });
                          } else {
                            this.setState({ mobile: text.substring(0, 16) });
                          }
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.nativeEvent.key == "Enter") {
                        }
                      }}
                    />
                    {!!mobileError && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {mobileError || " "}
                      </Text>
                    )}
                  </View>
                  {corp_code && (
                    <View style={styles.inputContainer}>
                      <Text style={styles.mob_num}>Corporate Code</Text>
                      <TextInput
                        value={corp_code}
                        style={styles.dmart_body_md}
                        placeholder={"Enter your corporate code"}
                        editable={!this.state.isCorpCode}
                        onFocus={() => {
                          this.setState({
                            nameError: "",
                            emailError: "",
                            mobileError: "",
                            passwordError: "",
                            repasswordError: "",
                            errorText: "",
                          });
                        }}
                        onChangeText={(text) => {
                          if (text.length < 20) {
                            this.setState({ corp_code: text });
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.nativeEvent.key == "Enter") {
                          }
                        }}
                      />
                    </View>
                  )}
                  {this.state.mobile && this.state.showOTP && (
                    <View style={styles.inputsContainer}>
                      <View style={styles.inputContainer}>
                        <Text style={styles.mob_num}>Enter OTP</Text>
                        <TextInput
                          value={otpNumber}
                          style={styles.dmart_body_md}
                          placeholder={`Enter OTP sent on ${this.state.mobile}`}
                          onFocus={() => {
                            this.setState({
                              otpError: "",
                              errorText: "",
                            });
                          }}
                          onChangeText={(text) => {
                            const pattern = /^[0-9\b]+$/;
                            if (text.length <= 6 && pattern.test(text)) {
                              this.setState({ otpNumber: text });
                            } else {
                              text = text.slice(0, -1);
                              this.setState({ otpNumber: text });
                            }
                          }}
                          maxLength={6}
                          onKeyPress={(e) => {
                            if (e.nativeEvent.key == "Enter") {
                            }
                          }}
                        />
                        {!!otpError && (
                          <Text style={[styles.please, { color: "red" }]}>
                            {otpError || " "}
                          </Text>
                        )}
                      </View>
                    </View>
                  )}
                  {!!errorText && (
                    <Text style={[styles.please, { color: "red" }]}>
                      {errorText || " "}
                    </Text>
                  )}
                </View>
                {isLoading ? (
                  <ActivityIndicator
                    size="large"
                    color="#410DAA"
                    style={{ paddingTop: 40 }}
                  />
                ) : (
                  <>
                    {!this.state.showOTP ? (
                      <TouchableOpacity
                        onPress={() => {
                          Keyboard.dismiss();
                          this.handleSendOTP();
                        }}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: isMobile ? 20 : 0,
                        }}
                      >
                        <Text style={styles.req_otp}>Send OTP</Text>
                      </TouchableOpacity>
                    ) : (
                      <>
                        <TouchableOpacity
                          onPress={() => {
                            Keyboard.dismiss();
                            this.handleVerifyOTP();
                          }}
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            paddingTop: 30,
                          }}
                        >
                          <Text style={styles.req_otp}>Verify OTP</Text>
                        </TouchableOpacity>
                        <Text style={[styles.forgotPassword]}>
                          Did not receive an OTP?{" "}
                          <Text
                            style={styles.clickHere}
                            onPress={() => {
                              this.handleResend();
                            }}
                          >
                            RESEND OTP
                          </Text>
                        </Text>
                      </>
                    )}
                  </>
                )}
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexGrow: 1,
    height: "100%",
    backgroundColor: "#fff",
  },
  body: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  left: {
    flex: md(width) ? 1 : 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#410DAA08",
    paddingVertical: md(width) ? 75 : 1,
  },
  right: {
    flex: 1,
  },
  mob_styl: {
    width: "80%",
    alignSelf: "center",
  },
  web_styl: {
    alignItems: "center",
  },
  captchaContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: isMobile ? -10 : -20,
    marginBottom: 10,
  },
  best: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 33,
    lineHeight: 38,
    color: "#464E5F",
    paddingBottom: 100,
  },
  left_logo: {
    resizeMode: "contain",
    width: 403,
    height: 241,
    alignSelf: "center",
    marginBottom: 60,
  },
  mobile_verf: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 19 : 15,
    lineHeight: md(width) ? 22 : 17,
    textAlign: "center",
    color: "#121212",
    paddingTop: md(width) ? 0 : 50,
  },
  we_will: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 17 : 13,
    lineHeight: md(width) ? 20 : 18,
    textAlign: "justify",
    color: "#A7A8BB",
    paddingTop: md(width) ? 19 : 29,
  },
  mob_num: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 18,
    color: "#222225",
    paddingBottom: md(width) ? 23 : 10.5,
  },
  please: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3,
  },
  req_otp: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 47,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  one: {
    color: "#121212",
  },
  dmart_body_md: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "bold",
    color: "#121212",
    backgroundColor: "#f6f6f6",
    height: 41.02,
    paddingLeft: 20,
    borderRadius: 4,
  },
  sebi: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 14,
    color: "#000",
    paddingRight: 29,
  },
  sebi_1: {
    fontFamily: "Arial",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: 9,
    lineHeight: 14,
    color: "#51514E",
    paddingRight: 29,
  },
  ina: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 10,
    lineHeight: 14,
    color: "#410DAA",
  },
  img1: {
    width: 30,
    height: 28.6,
  },
  welcomeTitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    color: "#202020",
    fontWeight: "bold",
    marginBottom: 45,
    marginTop: 10,
    textAlign: "center",
  },
  inputsContainer: {
    minWidth: "75%",
  },
  inputContainer: {
    marginBottom: 20,
  },
  forgotPassword: {
    color: "#232425",
    fontSize: 15,
    fontFamily: "Roboto",
    marginTop: 18,
    textAlign: "center",
  },
  clickHere: {
    color: "#410DAA",
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 6,
    textAlign: "center",
  },
  certication: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 25,
  },
  sebiContainer: {
    flexDirection: "row",
    paddingTop: 25,
  },
  certicationsContainer: {
    flexDirection: "row",
    paddingLeft: 30.5,
    paddingTop: 65,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  popableContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
});

const mapStateToProps = (state) => {
  return {
    userProfileData: state.userData.userProfileData,
    demoMode: state.testConfig.demoMode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveUserProfileData: (data) => dispatch(saveUserProfileData(data)),
  logIn: (time) => dispatch(logIn(time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizLogin);
