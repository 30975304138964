import React, { Component } from "react";
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  ScrollView,
  Image,
  View,
  Platform,
  SafeAreaView,
  Dimensions,
} from "react-native";
import numberFormat from "../../../../utils/numberFormat";
import { isMobile, months_short_names, toFixed } from "../../../../utils";
import { getPassbookDetails } from "../../../../api/account";
import theme from "../../../../static/theme/index.js";
import NavigationHeader from "../../../../components/Common/NavigationHeader";
import { Text, Card } from "react-native-paper";
import { ProductTabs } from "../../../../components/UpcomingInvestments/ProductTabs";
const { height } = Dimensions.get("window");
import amplitude from "../../../../utils/amplitude";

const EmptyResults = () => {
  return (
    <View
      style={{
        width: "100%",
        height: height / 2,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text style={{ fontSize: 15 }}>Nothing to show</Text>
    </View>
  );
};

class MyPassbook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoading: true,
      data: [],
      invdatasource: [],
      reddatasource: [],
      isPageLoading: true,
    };
  }

  formatDate = (date) => {
    if (date) {
      date = date.split("-");
      const month = months_short_names(parseInt(date[1]));
      const date_formatted = date[2] + "-" + month + "-" + date[0];
      return date_formatted;
    }
    return "-";
  };

  getDate = (execution_date, created_date) => {
    if (!execution_date || execution_date == "-") {
      created_date = created_date.split("T");
      return this.formatDate(created_date[0]);
    } else {
      return this.formatDate(execution_date);
    }
  };

  renderRow = (amc) => {
    let number_of_units = amc.item.number_of_units;
    number_of_units =
      number_of_units && number_of_units != "" && !isNaN(number_of_units)
        ? number_of_units.toFixed(3)
        : "-";

    return (
      <Card
        onPress={() => {}}
        mode="contained"
        style={styles.cardContainer}
        elevation={2}
      >
        <Card.Content>
          <View
            style={{ flex: 1, flexDirection: "row", alignItems: "flex-start" }}
          >
            <Image
              source={{
                uri:
                  "https://jamaappprod.s3.amazonaws.com/media/" +
                  amc.item.amc_logo,
              }}
              resizeMode="contain"
              style={{
                height: 35,
                width: 35,
                resizeMode: "contain",
                marginRight: 16,
              }}
            />
            <View style={{ flex: 0.6 }}>
              <Text style={{ color: "black", fontSize: 15 }}>
                {amc.item.fund_name}
              </Text>
              <Text note style={{ color: "black", marginTop: 5, fontSize: 13 }}>
                {amc.item.scheme_variant_name}
              </Text>
              <Text note style={{ color: "black", marginTop: 5 }}>
                Folio: {amc.item.bse_folio_no}
              </Text>
              <View>
                <Text note style={{ color: "black", marginTop: 5 }}>
                  NAV:{" "}
                  {amc.item.fund_nav &&
                  amc.item.fund_nav != "" &&
                  !isNaN(amc.item.fund_nav)
                    ? toFixed(amc.item.fund_nav, 4)
                    : amc.item.fund_nav}
                </Text>
              </View>
            </View>
            <View style={{ flex: 0.4, alignItems: "flex-end" }}>
              <Text
                onPress={() => navigate("Myfunddetails")}
                style={{ fontWeight: "bold", fontSize: 13 }}
              >
                {theme.variables.RUPEE_SYMBOL}
                {amc.item.amount && amc.item.amount != ""
                  ? numberFormat(
                      amc.item.amount.toFixed(2).toString().replace(/[-]/g, "")
                    )
                  : "0"}
              </Text>
              <Text note style={{ color: "black", marginTop: 5 }}>
                Units: {number_of_units}
              </Text>
              <Text note style={{ color: "black", marginTop: 5 }}>
                {this.getDate(amc.item.execution_date, amc.item.created)}
              </Text>
            </View>
          </View>
        </Card.Content>
      </Card>
    );
  };

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("passbook",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("passbook", { path: this.props.navigation.getState().routeName });
    }
    let data = [];
    let filterData = [];
    try {
      const response = await getPassbookDetails();
      if (response && response.results) {
        let results = [];
        response.results.forEach((item) => {
          if (item.code == 100) {
            if (item.results.length != 0) {
              results.push(item.results[0]);
            }
          }
        });
        data = [...results];
        filterData = [...results];
      }
    } catch (error) {
      this.setState({ error: true });
    } finally {
      this.setState({ isPageLoading: false, data, filterData });
    }
  }

  handleBack = () => {
    const canGoBack = this.props.navigation.canGoBack();
    if (canGoBack) {
      this.props.navigation.goBack();
    } else {
      this.props.navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Account",
        },
      });
    }
  };

  onSelectTab = (index) => {
    try {
      const { data = [] } = this.state;
      let filter_value = null,
        filtered_data = [];
      if (index == 1) {
        filter_value = "P";
      } else if (index == 2) {
        filter_value = "R";
      }
      if (filter_value) {
        filtered_data = data.filter((item) => {
          return item.transaction_type == filter_value;
        });
      } else {
        filtered_data = [...data];
      }
      this.setState({ filterData: filtered_data });
    } catch (error) {}
  };

  render() {
    const { isPageLoading, error, filterData = [] } = this.state;

    const tabsToShow = [
      {
        label: "All",
        value: "ALL",
      },
      {
        label: "Investments",
        value: "INVEST",
      },
      {
        label: "Withdrawals",
        value: "WITHDRAW",
      },
    ];

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["My Passbook"]}
          onPressBack={this.handleBack}
        />
        <ProductTabs
          tabs={tabsToShow}
          onSelectTab={this.onSelectTab}
          data={[]}
        />
        {isPageLoading || error ? (
          <View style={styles.loadingContainer}>
            {isPageLoading && (
              <ActivityIndicator size="large" color="#410DAA" />
            )}
            {error && <Text>Error loading Passbook !</Text>}
          </View>
        ) : (
          <View style={styles.itemsContainer}>
            <ScrollView
              style={{ flex: 1, marginBottom: isMobile ? 70 : 30 }}
              contentContainerStyle={{ flex: 1 }}
              showsVerticalScrollIndicator={true}
            >
              <FlatList
                data={filterData}
                ListEmptyComponent={<EmptyResults />}
                renderItem={this.renderRow}
                keyExtractor={(item, index) => index.toString()}
                showsVerticalScrollIndicator={true}
              />
            </ScrollView>
          </View>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  itemsContainer: {
    flex: 1,
    marginLeft: isMobile ? 16 : 32,
    marginRight: isMobile ? 16 : 32,
    marginTop: 20,
  },
  cardContainer: {
    padding: 6,
    borderWidth: 0.5,
    borderColor: "#410DAA50",
    marginTop: 5,
    marginBottom: 6,
  },
});

export default MyPassbook;
