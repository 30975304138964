import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  FlatList,
  StatusBar,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
  SafeAreaView,
} from "react-native";
import { md } from "../../../Responsive";
const { width } = Dimensions.get("window");
import Header from "../header_after_login";
import {
  getOrderSummary,
  makePaymentOrderSummary,
  updatePaymentStatusOrderSummary,
  getCustomerDetails,
} from "../../../api";
import Progressbar from "../progressbar";
import {
  completeMinDetails,
  getUserDetails,
  isJama,
  isMobile,
  isWeb,
  navigateToTransaction,
  numberWithCommas,
} from "../../../utils";
import { ShowToast } from "../../../components/Common/Toast";
import { StackActions } from "@react-navigation/native";
import { getServerName } from "../../../api/urls";
import { Button } from "react-native-paper";
import { AntDesign } from "@expo/vector-icons";
import PendingInvoices from "../../billing/invoices";

export default class ordersummary extends React.Component {
  constructor(props) {
    super(props);
    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
    }
    this.state = {
      user_id,
      data: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    try {
      const userIdToFetchDetails = this.state.user_id;
      const paymentRespnse = await updatePaymentStatusOrderSummary(
        userIdToFetchDetails
      );
      if (paymentRespnse && paymentRespnse.code == "100") {
        this.moveToNextScreen();
        return;
      }
      const response = await getOrderSummary(userIdToFetchDetails);
      let orderSummary = {
        plansData: [],
      };
      if (response && response.code == 100) {
        const { results } = response;
        if (results && results.length > 0) {
          const {
            results: plans,
            igst_amount,
            amount_to_pay,
            total_amount_to_pay,
            igst,
            cgst_amount,
            sgst_amount,
            cgst,
            sgst,
          } = results[0];
          orderSummary["sgst_amount"] = sgst_amount;
          orderSummary["cgst_amount"] = cgst_amount;
          orderSummary["cgst"] = cgst;
          orderSummary["sgst"] = sgst;

          orderSummary["igst_amount"] = igst_amount;
          orderSummary["amount_to_pay"] = amount_to_pay;
          orderSummary["total_amount_to_pay"] = total_amount_to_pay;
          orderSummary["igst"] = igst;
          plans.forEach((item) => {
            let detail = {};
            const {
              results: plan_data,
              amount_paid,
              amount_due,
              invoice_number,
              invoice_date,
              amount,
            } = item;
            detail["amount"] = amount;
            detail["amount_paid"] = amount_paid;
            detail["amount_due"] = amount_due;
            detail["invoice_number"] = invoice_number;
            detail["invoice_date"] = invoice_date;

            const { customerinvestmentplan, plan_text, is_paid } = plan_data[0];
            detail["customerinvestmentplan"] = customerinvestmentplan;
            detail["plan_text"] = plan_text;
            detail["is_paid"] = is_paid;
            if (amount_due) {
              orderSummary["plansData"].push(detail);
            }
          });
        }
        let skipSubscription = false;
        let userIdToFetch = null;
        if (!this.state.user_id) {
          const { customer_id } = await getUserDetails();
          userIdToFetch = customer_id;
        } else {
          userIdToFetch = this.state.user_id;
        }

        const { skip_subscription, is_cust_has_paid_bill } =
          await getCustomerDetails(userIdToFetch);
        skipSubscription = skip_subscription;

        this.setState({
          orderSummary,
          isLoading: false,
          skipSubscription,
          is_cust_has_paid_bill,
        });
      } else {
        this.setState({
          error: true,
          errorText: "Something went wrong!",
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    }
  }

  moveToNextScreen = async (skip_subscription) => {
    try {
      const minDetailsResponse = await completeMinDetails(
        false,
        null,
        skip_subscription
      );
      const { needCompletion, error } = minDetailsResponse;
      if (!error) {
        const { redirect_screen, skipSubscription } = minDetailsResponse;
        let options = {};
        if (this.props.route) {
          const { params } = this.props.route;

          if (params) {
            options = { ...params };
          }
          if (!needCompletion) {
            if (skipSubscription) {
              const { stack = null, screen = null } = params;
              if (stack && screen) {
                if (stack == "ViewMode") {
                  this.props.navigation.dispatch(
                    StackActions.replace("App", {
                      screen: stack,

                      params: { screen: screen, params: { s: 1, ...options } },
                    })
                  );
                  return;
                }
                this.props.navigation.dispatch(
                  StackActions.replace("App", {
                    screen: "Dashboard",
                    params: {
                      screen: stack,
                      params: { screen: screen, params: { s: 1, ...options } },
                    },
                  })
                );
                return;
              } else {
                // console.log("4");
                const screenName =
                  params && params.nvg ? params.nvg : "InvestmentOverview";
                this.props.navigation.dispatch(
                  StackActions.replace("App", {
                    screen: "Dashboard",
                    params: {
                      screen: "Dashboard",
                      params: {
                        screen: screenName,
                        params: { s: 1, ...options },
                      },
                    },
                  })
                );
                return;
              }
            }
            if (options.transaction) {
              navigateToTransaction(this.props.navigation, options.transaction);
            } else if (options.action) {
              this.props.navigation.navigate("Onboarding", {
                screen: "ComplianceSuccess",
                params: { ...options },
              });
              return;
            } else {
              this.props.navigation.navigate("Onboarding", {
                screen: "OnboardingSuccess",
                params: { ...options },
              });
              return;
            }
          }
        }
        this.props.navigation.navigate("App", {
          screen: "Dashboard",
          params: {
            screen: "Portfolio",
            params: { screen: redirect_screen, params: { ...options } },
          },
        });
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Something went wrong! Please contact help@maxiom.in",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  handleContinue = async () => {
    try {
      if (this.state.user_id) {
        let options = {};
        const { params } = this.props.route;
        if (params) {
          options = { ...params };
        }
        options["first"] = false;
        this.props.navigation.navigate("BankAccount", { ...options });
        return;
      }
      this.setState({ gettingPaymentLink: true });
      const server_name = getServerName();
      const payload = {
        server_name,
      };
      const response = await makePaymentOrderSummary(payload);
      if (response && response.code == 100) {
        const { results } = response;
        if (results && results.length > 0) {
          const { paymentLink } = results[0];
          if (Platform.OS == "web") {
            global.open(paymentLink, "_self");
          } else {
            const { params } = this.props.route;
            let options = {};
            if (params) {
              options = { ...params };
            }
            this.props.navigation.navigate("App", {
              screen: "Dashboard",
              params: {
                screen: "Portfolio",
                params: {
                  screen: "ShowWebView",
                  params: {
                    url: paymentLink,
                    successURL: "onboarding/order/paymentsuccess",
                    onSuccess: function (navigation, results = {}) {
                      navigation.push("Onboarding", {
                        screen: "PaymentSuccess",
                        params: { ...options },
                      });
                    },
                    onError: function (navigation, results = {}) {
                      // navigation.push("App", { screen: "Dashboard", params: { screen: "Home", params: {} } });
                    },
                  },
                },
              },
            });
          }
          this.setState({ gettingPaymentLink: false });
        } else {
          this.setState({
            error: true,
            errorText: "Something went wrong!",
            gettingPaymentLink: false,
          });
        }
      } else {
        this.setState({
          error: true,
          errorText: "Something went wrong!",
          gettingPaymentLink: false,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        gettingPaymentLink: false,
      });
    }
  };

  renderPlansDetails = ({ item }) => {
    const isMobile = md(width);
    let { plan_text } = item;
    plan_text = plan_text.replace("<br>", "");

    return (
      <>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            backgroundColor: "#F3F6F9",
            borderRadius: 0,
            marginBottom: isMobile ? "undefined" : 30,
            paddingVertical: 12,
            paddingHorizontal: isMobile ? 15 : 10,
            justifyContent: isMobile ? "undefined" : "center",
            marginTop: isMobile ? "undefined" : 30,
          }}
        >
          <View style={{ flex: 0.4 }}>
            <Text style={styles.invoice_number}>{item.invoice_number}</Text>
            <Text style={styles.invoice_date}>{item.invoice_date}</Text>
          </View>
          <Text style={[styles.plan, { flex: 0.2, textAlign: "center" }]}>
            Total
          </Text>
          <Text
            style={[
              styles.plan,
              { flex: 0.2, textAlign: isMobile ? "center" : "center" },
            ]}
          >
            Paid
          </Text>
          <Text
            style={[
              styles.plan,
              { flex: 0.2, textAlign: isMobile ? "center" : "center" },
            ]}
          >
            Due
          </Text>
        </View>
        <View style={{ paddingVertical: isMobile ? 0 : 20 }}>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              paddingBottom: isMobile ? 0 : 20,
              paddingTop: isMobile ? 0 : 20,
              paddingLeft: 5,
            }}
          >
            <View style={{ flex: 0.4 }}>
              <Text style={[styles.plan_item, { flex: 2 }]}>{plan_text}</Text>
            </View>
            <Text style={[styles.inv_amt, { flex: 0.2, textAlign: "center" }]}>
              ₹{numberWithCommas(item.amount)}
            </Text>
            <Text style={[styles.inv_amt, { flex: 0.2, textAlign: "center" }]}>
              ₹{numberWithCommas(item.amount_paid)}
            </Text>
            <Text style={[styles.inv_amt, { flex: 0.2, textAlign: "center" }]}>
              ₹{numberWithCommas(item.amount_due)}
            </Text>
          </View>
        </View>
      </>
    );
  };

  handleSkip = async () => {
    try {
      if (this.state.user_id) {
        let options = {};
        const { params } = this.props.route;
        if (params) {
          options = { ...params };
        }
        options["first"] = false;
        this.props.navigation.dispatch(
          StackActions.replace("App", {
            screen: "Dashboard",
            params: {
              screen: "Portfolio",
              params: {
                screen: params.nvg || "InvestmentOverview",
                params: { s: 1, ...options },
              },
            },
          })
        );
        return;
      }
      this.moveToNextScreen(true);
    } catch (error) {}
  };

  closeDescription = () => {
    this.setState({ showDescription: !this.state.showDescription });
  };

  render() {
    const {
      orderSummary,
      isLoading,
      skipSubscription,
      is_cust_has_paid_bill = false,
      showDescription = true,
    } = this.state;
    return (
      <SafeAreaView style={styles.container}>
        <Header
          navigation={this.props.navigation}
          route={this.props.route}
          onPressBack={() => {
            const { params } = this.props.route;
            if (params && params.screen) {
              this.props.navigation.navigate("App", {
                screen: params.stack,
                params: {
                  screen: params.screen || "StartSIP",
                  params: {
                    selected_fund: params.selected_fund,
                    fund: params.fund,
                  },
                },
              });
            } else {
              this.props.navigation.goBack();
            }
          }}
        />
        {/* <Progressbar
          color2="#410DAA"
          color3="#410daa"
          color4="#410DAA"
          color5="#410DAA"
          color6="#410DAA"
          color7="#410DAA"
          color8="#410DAA"
          color9="#410DAA"
          color10="#410DAA"
          color11="#f6f6f6"
          color12="#f6f6f6"
          bcolor1="#410DAA"
          bcolor2="#410DAA"
          bcolor3="#410DAA"
          bcolor4="#410DAA"
          bcolor5="#410DAA"
          bagcolor1="#410DAA"
          bagcolor2="#410DAA"
          bagcolor3="#410DAA"
          bagcolor4="#410DAA"
          bagcolor5="#410DAA"
          markdisplay1="none"
          markdisplay2="none"
          markdisplay3="none"
          markdisplay4="none"
          markdisplay5="none"
          dotdisplay1="none"
          dotdisplay2="none"
          dotdisplay3="none"
          dotdisplay4="none"
          dotdisplay5="none"
        /> */}
        <StatusBar backgroundColor="#000" barStyle="dark-content" />
        {!isLoading ? (
          <ScrollView style={styles.mainContainer}>
            <View style={styles.body}>
              {isWeb && (
                <View style={styles.left}>
                  <Text style={styles.terms}>Payment</Text>
                  <Text style={styles.please}>
                    Per SEBI regulation, payment must be made before advisor
                    provides service
                  </Text>
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-2.png",
                    }}
                    style={styles.left_img}
                  />
                  <View
                    style={{
                      flexDirection: "row",
                      width: 430,
                      borderWidth: 1,
                      borderColor: "rgba(65, 13, 170, 0.2)",
                      backgroundColor: "#ffffff",
                      paddingVertical: md(width) ? 21 : 8.5,
                      paddingLeft: 20,
                      paddingRight: 6,
                      marginTop: 33,
                      marginBottom: 22,
                    }}
                  >
                    <Image
                      source={{
                        uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/fluent_lock-shield-20-regular.png",
                      }}
                      style={styles.lock}
                    />
                    <Text style={styles.jam_we}>
                      Information is encrypted using 256-bit keys and stored
                      securely
                    </Text>
                  </View>
                  <Text style={styles.any}>
                    Any Queries?{" "}
                    <TouchableOpacity
                      onPress={() => {
                        this.props.navigation.navigate("App", {
                          screen: "Dashboard",
                          params: {
                            screen: "Dashboard",
                            params: {
                              screen: "GetHelp",
                              params: {},
                            },
                          },
                        });
                      }}
                    >
                      <Text style={{ color: "#410DAA", fontWeight: "bold" }}>
                        Get Help
                      </Text>
                    </TouchableOpacity>
                  </Text>
                </View>
              )}
              <View style={styles.right}>
                {orderSummary ? (
                  <View style={{ flexDirection: "column" }}>
                    {showDescription && is_cust_has_paid_bill && (
                      <View style={styles.descriptionContainer}>
                        <Text style={styles.clarification}>
                          If any bills need clarification, or previous payments
                          are not reflected, please contact your Relationship
                          Manager or write to us at help@
                          {isJama() ? "jamawealth" : "maxiomwealth"}.com We will
                          update our records or share any supporting data you
                          may require.
                        </Text>
                        <AntDesign
                          name="close"
                          size={19}
                          color="#12121250"
                          style={{}}
                          onPress={this.closeDescription}
                        />
                      </View>
                    )}
                    <View style={styles.orderSummaryContainer}>
                      <Text style={styles.investment}>Pending Bills </Text>
                      {skipSubscription && (
                        <TouchableOpacity onPress={() => this.handleSkip()}>
                          <Text style={styles.skipSubsc}>Pay Later {">>"}</Text>
                        </TouchableOpacity>
                      )}
                    </View>
                    <PendingInvoices fromOnboard={true} {...this.props} />
                    {this.state.user_id ? (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            this.handleContinue();
                          }}
                        >
                          <Text style={styles.viewModeText}>Next {">>>"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            let url = new URL(global.location.href);
                            location.replace(
                              url.origin +
                                "/dashboard/home?user_id=" +
                                this.state.user_id
                            );
                          }}
                          style={{ marginLeft: 30 }}
                        >
                          <Text style={styles.viewModeText}>
                            Go to Dashboard {">>>"}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    ) : null}
                  </View>
                ) : (
                  <View style={styles.noContent}>
                    <Text style={styles.nothingFound}>Nothing found!</Text>
                    {this.state.user_id && (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            this.handleContinue();
                          }}
                        >
                          <Text style={styles.viewModeText}>Next {">>>"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            let url = new URL(global.location.href);
                            location.replace(
                              url.origin +
                                "/dashboard/home?user_id=" +
                                this.state.user_id
                            );
                          }}
                        >
                          <Text style={styles.viewModeText}>
                            Go to Dashboard {">>>"}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                )}
              </View>
            </View>
          </ScrollView>
        ) : (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1, backgroundColor: "#fff" },
  mainContainer: {},
  loadingContainer: { flex: 1, justifyContent: "center", alignItems: "center" },
  descriptionContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingTop: 50,
    paddingRight: 16,
  },
  noContent: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  nothingFound: {
    fontSize: 15,
    color: "#121212",
  },
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: !isMobile ? 102 : 13,
    paddingRight: !isMobile ? 88 : 13,
    paddingBottom: isMobile ? 120 : 0,
  },
  left: {
    flex: 0.45,
    paddingTop: 55,
    // display: md(width) ? "undefined" : "none",
  },
  right: {
    flex: !isMobile ? 0.55 : 1,
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },
  lock: {
    resizeMode: "contain",
    width: 24.5,
    height: 34,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 22,
    lineHeight: 25,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
    paddingTop: 11,
    paddingBottom: 32,
    paddingRight: 160,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  orderSummaryContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 50,
    marginBottom: 27,
  },
  investment: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    fontWeight: "bold",
    color: "#2F3044",
    textAlign: "center",
    // display: md(width) ? "none" : "none",
  },
  clarification: {
    fontSize: 14,
  },
  skipSubsc: { color: "#464E5F", fontSize: 14, fontWeight: "bold" },
  skipSubscContainer: {},

  plan: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 14 : 13,
    lineHeight: md(width) ? 16 : 15,
    color: "#464E5F",
  },
  plan_item: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    color: "#4A4B68",
    letterSpacing: 0.5,
    paddingRight: 25,
  },
  invoice_number: {
    fontSize: 14,
    color: "#8950FC",
    fontWeight: "bold",
  },
  invoice_date: {
    fontSize: 14,
    color: "#464E5F",
    fontWeight: "bold",
  },
  inv_amt: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#4A4B68",
    fontWeight: "normal",
  },
  inv_amt1: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#4A4B68",
    paddingTop: 9,
  },
  date: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#737178",
    letterSpacing: 0.5,
    borderBottomWidth: 1,
    borderBottomColor: "#E5EAEE",
    paddingBottom: 10,
  },
  inv_num: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 14 : 13,
    lineHeight: md(width) ? 16 : 21,
    color: "#8950FC",
    letterSpacing: 0.5,
    fontWeight: "bold",
    paddingTop: 21,
    paddingBottom: 4,
  },
  tot_bot: {
    borderTopColor: "#E5EAEE",
    borderBottomColor: "#E5EAEE",
    borderRadius: 6,
    borderTopWidth: md(width) ? 8 : 3,
    borderBottomWidth: md(width) ? 8 : 3,
    paddingVertical: 15,
    paddingHorizontal: md(width) ? 70 : 0,
    paddingLeft: md(width) ? 0 : 15,
    paddingRight: md(width) ? 0 : 43,
    marginTop: md(width) ? 20 : 0,
  },
  tot_amt: {
    fontFamily: "Roboto",
    fontWeight: md(width) ? "bold" : "400",
    lineHeight: 15,
    fontSize: md(width) ? 14 : 13,
    letterSpacing: 0.32,
    color: "#464E5F",
  },
  tot_amt1: {
    fontFamily: "Roboto",
    lineHeight: md(width) ? 16 : 15,
    fontSize: md(width) ? 15 : 13,
    letterSpacing: 0.32,
    color: "#464E5F",
    paddingTop: 11,
  },
  gra_tot: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: md(width) ? 16 : 15,
    fontSize: md(width) ? 15 : 13,
    letterSpacing: 0.32,
    color: "#000000",
    paddingTop: 14,
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 18,
    marginTop: 20,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingHorizontal: 44,
    paddingVertical: 12,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  mob_plan: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    fontWeight: "bold",
    color: "#4A4B68",
  },
  mob_year: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#4A4B68",
    paddingTop: 9,
  },
  mob_top_pybl: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    fontWeight: "bold",
    letterSpacing: 0.32,

    textAlign: "center",
    flex: 1,
  },
  tt_amt: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 16,
    color: "#121212",
  },
});
