import * as React from 'react';
import { DatePickerModal } from 'react-native-paper-dates';

export const DatePicker = ({ isActive, initialDate, setPlannedDate, closedDatePicker, label, validRange={} }) => {
  const [date, setDate] = React.useState(initialDate, new Date());
  const [open, setOpen] = React.useState(isActive, false);

  React.useEffect(() => {
    setOpen(isActive);
  }, [isActive])
  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
    closedDatePicker && closedDatePicker()
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
      setPlannedDate && setPlannedDate(params.date)
    },
    [setOpen, setDate]
  );

  return (
    <DatePickerModal
      // locale={'en'} optional, default: automatic
      mode="single"
      visible={open}
      onDismiss={onDismissSingle}
      date={date}
      onConfirm={onConfirmSingle}
      // validRange={{
      //   startDate: new Date()
      // }}
      validRange={{...validRange}}
      // onChange={} // same props as onConfirm but triggered without confirmed by user
      // saveLabel="Save" // optional
      label={label || "Select date"} // optional
    // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
    />
  );
}
