import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import Table from "../../../../components/Common/Table";
import amplitude from "../../../../utils/amplitude";
import NavigationHeader from "../../../../components/Common/NavigationHeader";
import AntDesign from "@expo/vector-icons/AntDesign";
import { numberWithCommas } from "../../../../utils";

class AdvisoryFeeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("AdvisoryFeeDetails",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("AdvisoryFeeDetails", { path: this.props.navigation.getState().routeName });
      }
      const data = [
        {
          date: "1 Apr 2021",
          value: 235654,
          fee_percentage: "2.25%",
          bill_amount: 2255,
          id: 1
        },
        {
          date: "1 Apr 2021",
          value: 786347,
          fee_percentage: "2.25%",
          bill_amount: 7235,
          id: 2
        },
      ];

      this.setState({ data });
    } catch (error) {}
  }

  navigateBack = () => {
    const { navigation } = this.props;
    if (navigation) {
      navigation.goBack();
    }
  };

  compare = (a, b, field, sort) => {
    if (a[field] < b[field]) {
      return sort ? -1 : 1;
    }
    if (a[field] > b[field]) {
      return sort ? 1 : -1;
    }
    return 0;
  };

  handleSort = (field, sort, start, end) => {
    this.setState(
      {
        sorting_field: field,
        sorting_value: !sort,
      },
      () => {
        const { data } = this.state;
        const sortedData = [].concat(
          data.slice(0, start),
          data
            .slice(start, end)
            .sort((a, b) => this.compare(a, b, field, !sort)),
          data.slice(end, data.length)
        );
        this.setState({ data: sortedData });
      }
    );
  };

  getTableData = (data = []) => {
    const headerData = [
      {
        label: "Execution Date",
        style: [styles.tableHeader,  {paddingLeft: 30}],
        sortOption: false
      },
      {
        label: "Transaction Type",
        style: [styles.tableHeader],
        sortOption: false,
        key: "value",
      },
      {
        label: "Amount Executed",
        style: [styles.tableHeader],
        sortOption: false,
        key: "fee_percentage",
      },
      {
        label: "No of Days",
        style: [styles.tableHeader],
        sortOption: false,
        key: "bill_amount",
      },
      {
        label: "Advisory Fee",
        style: [styles.tableHeader],
        sortOption: false,
        key: "bill_amount",
      },
      {
        label: "Prorated Amount",
        style: [styles.tableHeader],
        sortOption: false,
        key: "bill_amount",
      },

    ];
    let { values = [] } = this.generateDataForTable(data);
    return { headerData, values };
  };

  handleShowHoldings = async (id) => {
    this.props.navigation.navigate("HoldingsDetails", { bill_id: this.props.billId, invoice_id: this.props.invoiceId});
  };

  generateDataForTable = (data) => {
    let values = [];
    data.forEach((item, index) => {
      let { date, value, fee_percentage, bill_amount, id } = item;
      value = numberWithCommas(value);
      bill_amount = numberWithCommas(bill_amount);
      values.push([
        {
          valueToDisplay: date,
          component: true,
          value: (
            <TouchableOpacity
              style={styles.valuationDateContainer}
              onPress={() => this.handleShowHoldings(id)}
            >
              <Text
                style={[ styles.valuationDate ]}
              >
                {date}
              </Text>
              <AntDesign
                name="arrowright"
                size={16}
                color={"#410DAA"}
                style={styles.bold}
              />
            </TouchableOpacity>
          ),
          style: [{ paddingLeft: 38}],
          rowItemContainerStyle: [{justifyContent:"flex-start"}]
        },
        {
          valueToDisplay: "₹" + value,
          value: value,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: fee_percentage,
          value: fee_percentage,
          style: [styles.rowItem],
        },
        {
          valueToDisplay: "₹" + bill_amount,
          value: bill_amount,
          style: [styles.rowItem],
        },
        {
            valueToDisplay: fee_percentage,
            value: fee_percentage,
            style: [styles.rowItem],
        },
        {
            valueToDisplay: "₹" + bill_amount,
            value: bill_amount,
            style: [styles.rowItem],
        }
      ]);
    });
    return { values };
  };

  render() {
    const { navigation } = this.props;
    const { data = [] } = this.state;

    const {
      headerData,
      values,
    } = this.getTableData(data);
    return (
      <View style={styles.container}>
        <NavigationHeader
          navigation={navigation}
          navigationHeader={[ "Bills", "View Bills", "View Details"]}
          onPressBack={this.navigateBack}
        />

        <ScrollView>
          <View style={[styles.detailsContainer, styles.borderColor]}>
            <View style={styles.headingContainer}>
              <View>
                <Text style={styles.header}>Advisory Fee Details</Text>
                <Text style={styles.headerBillingPeriod}>
                  (Period: 1 Jan 2021 - 30 June 2021)
                </Text>
              </View>
              <Text style={styles.billTotalAmount}>₹35,979.00</Text>
            </View>
            <Table
              header={headerData}
              gridConfig={[0.2, 0.2, 0.2, 0.1, 0.1, 0.2]}
              data={values}
              minWidth={1500}
            />
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: 36,
    marginVertical: 30,
  },
  borderColor: {
    borderColor: "#DBC0FF",
  },
  headingContainer: {
    paddingHorizontal: 40,
    paddingVertical: 19,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "700",
  },
  headerBillingPeriod: {
    fontSize: 15,
    fontFamily: "Roboto",
    color: "#464E5F",
    marginTop: 10,
  },
  billTotalAmount: {
    fontSize: 15,
    color: "#464E5F",
    fontFamily: "Roboto",
    fontWeight: "700",
    marginRight: 50,
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  valuationDate: { fontSize: 11 },
  valuationDateContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  active: { color: "#410DAA", fontWeight: "700" },
  bold: { fontWeight: "bold", marginLeft: 12 },
});

export default AdvisoryFeeDetails;
