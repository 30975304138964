import { StackActions } from "@react-navigation/native";
import React from "react";
import { View, Platform,StyleSheet } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import amplitude from "../../utils/amplitude";
import { ShowToast } from "../../components/Common/Toast";
import {
  completeMinDetails,
  getUserDetails,
} from "../../utils";

export default class Compliance extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    const { params } = this.props.route;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }
    this.state = {
      isLoading: true,
      userId,
    };
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("compliance",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("compliance", { path: this.props.navigation.getState().routeName });
      }
      const { userId } = this.state;
      const { customer_id } = await getUserDetails();
      let userIDToFetchData = userId ? userId : customer_id;

      const response = await completeMinDetails(false, userIDToFetchData);
      const { needCompletion, error } = response;
      if (!error) {
        const { redirect_screen } = response;
        const { params } = this.props.route;
        let options = params ? { ...params } : {};
        if (!needCompletion) {
          if (params) {
            if (params.nvg) {
              this.props.navigation.navigate(params.nvg, { ...options });
              return;
            }
          }
        } else {
          options["first"] = true;
          options = { ...options };
        }
        this.props.navigation.dispatch(
          StackActions.replace(redirect_screen, { ...options })
        );
      } else {
        this.setState({
          error: true,
          errorText: "Something went wrong! Please contact help@maxiom.in",
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  render() {
    return (
      <View style={styles.mainContainer}>
        <ShowToast />
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#410DAA" />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
