
import React  from 'react';
import { View, Image, StyleSheet, Text } from 'react-native';
import { Card } from 'react-native-paper';
import { isMobile } from '../../utils';
import flex from '../../styles/flex';

const LogoutLoading = (props) => {
    return(
        <View
            style={logout.container}
        >
            <Card
                style={[
                    logout.body,
                    {
                        //boxShadow: "8px 9px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: 10
                    }
                ]}

            >
                <View style={[flex.row, flex.center]}>
                    <Image
                        source="https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Broker+logout.png"
                        style={logout.image}
                    />
                    <Text style={logout.broker}>
                        {props.description}
                    </Text>
                </View>
            </Card>
        </View>
    )
};

const logout = StyleSheet.create({
    container:{
        alignItems: "center",
    },
    body: {
        paddingHorizontal: isMobile ? 50: 160,
        paddingVertical: isMobile ? 45 : 56,
        marginHorizontal: isMobile ? 16 : 0
    },
    broker: {
        fontFamily: "Roboto",
        fontSize: isMobile ? 14 : 18,
        color: "#121212"
    },
    image: { width: isMobile ? 22 : 28, height: isMobile ? 22 : 28, resizeMode: "contain", marginRight: isMobile ? 10 : 16}
});

export default LogoutLoading;