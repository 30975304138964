import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Image,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import { Text } from "react-native-paper";
import { getUserDetails, isMobile } from "../../utils";
import amplitude from "../../utils/amplitude";
import { generateCamsAggregatorLink } from "../../api";
import Toast from "react-native-toast-message";
import Store from "../../store";
const { width } = Dimensions.get("window");

export default class GetRicherStrip extends React.Component {
  constructor(props) {
    super(props);
    let { block_feature_list } = Store.getState().userData.userProfileData;
    this.state = {
      loadingUrl: false,
      block_feature_list
    };
  }

  render() {
    return (
      <View
        style={{
          padding: 8,
          paddingLeft: 0,
          paddingBottom: isMobile ? 0 : 20,
          width: isMobile
            ? "105%"
            : this.props.width
            ? this.props.width
            : "65.3%",
          marginBottom: width < 1200 && width > 768 ? 40 : 0,
        }}
      >
        {this.state.loadingUrl ? (
          <ActivityIndicator size="large" color="#410DAA" />
        ) : (
          <View>
            <View style={styles.subContainerOne}>
              {isMobile ? null : (
                <View>
                  <Text style={{ fontFamily: "Roboto" }}>
                    {this.props.title
                      ? this.props.title
                      : "We will analyze your portfolio and schedule a free review with all our suggestions."}
                  </Text>
                </View>
              )}
              <View style={{ padding: isMobile ? 3 : 7 }}></View>
              {isMobile || this.props.title ? null : (
                <View>
                  <Text style={{ fontFamily: "Roboto" }}>
                    <Text style={{ color: "#410DAA", marginLeft: -10 }}>
                      Bonus:
                    </Text>{" "}
                    Track all investments for free
                  </Text>
                </View>
              )}
            </View>
            <View>
              <View style={styles.subContainerTwo}>
                <TouchableOpacity
                  style={styles.strip}
                  onPress={() => {
                    amplitude.logEvent("Import_Stocks_Clicked");
                    if (this.props.from == "dashboard") {
                      this.props.navigation.navigate("App", {
                        screen: "ViewMode",
                        params: {
                          screen: "ImportStocks",
                          params: {
                            from: this.props.from,
                          },
                        },
                      });
                    } else {
                      if (isMobile) {
                        this.props.navigation.navigate("App", {
                          screen: "ViewMode",
                          params: {
                            screen: "ImportStocks",
                            params: {
                              from: this.props.from,
                            },
                          },
                        });
                      } else {
                        this.props.navigation.navigate("App", {
                          screen: "ViewMode",
                          params: {
                            screen: "ImportStocks",
                            params: {
                              from: this.props.from,
                            },
                          },
                        });
                      }
                    }
                  }}
                >
                  <View style={styles.stripContainer}>
                    <View style={styles.imageStrip}>
                      <Image
                        source={{
                          uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/import_MF.png",
                        }}
                        style={{
                          width: 28,
                          height: 28,
                          resizeMode: "contain",
                          borderRadius: 5,
                          flex: 1,
                          alignItems: "center",
                        }}
                      />
                    </View>
                    <View style={styles.contentStrip}>
                      <View>
                        <Text
                          style={{
                            color: "#464E5F",
                            fontWeight: "700",
                            fontFamily: "Roboto",
                          }}
                        >
                          Import Stocks and Mutual Funds
                        </Text>
                      </View>
                      <View style={{ paddingTop: 15 }}>
                        <Text
                          style={{ color: "#888C9F", fontFamily: "Roboto" }}
                        >
                          Account aggregator, eCAS (NSDL/CDSL),{"\n"}CAS by
                          CAMS, xls ..
                        </Text>
                      </View>
                    </View>
                  </View>
                </TouchableOpacity>
                <View style={{ padding: 10 }}></View>
                {!this.state.block_feature_list === "CAMS_AA" &&  <TouchableOpacity
                  style={styles.strip}
                  onPress={this.props.handleAggregator}
                >
                  <View style={styles.stripContainer}>
                    <View style={styles.imageStrip}>
                      <Image
                        source={{
                          uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/import_stocks.png",
                        }}
                        style={{
                          width: 28,
                          height: 28,
                          resizeMode: "contain",
                          borderRadius: 5,
                          flex: 1,
                          alignItems: "center",
                        }}
                      />
                    </View>
                    <View style={styles.contentStrip}>
                      <View>
                        <Text
                          style={{
                            color: "#464E5F",
                            fontWeight: "700",
                            fontFamily: "Roboto",
                          }}
                        >
                          Import 360° Aggregator Data
                        </Text>
                      </View>
                      <View style={{ paddingTop: 15 }}>
                        <Text
                          style={{ color: "#888C9F", fontFamily: "Roboto" }}
                        >
                          Stock, Mutual Funds, Bank, Insurance,
                          {"\n"}NPS using account aggregator
                        </Text>
                      </View>
                    </View>
                  </View>
                </TouchableOpacity>}
              </View>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  subContainerOne: {
    fontSize: 12,
    paddingTop: 5,
    paddingBottom: 15,
    flex: 1,
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "flex-start",
    alignItems: isMobile ? "flex-start" : "center",
  },
  subContainerTwo: {
    flex: 1,
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "flex-start",
    alignItems: isMobile ? "flex-start" : "center",
    padding: isMobile ? 2 : 5,
    marginTop: isMobile ? -13 : 0,
  },
  stripContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  strip: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "rgba(65, 13, 170, 0.2)",
    height: 100,
    marginLeft: -5,
    width: isMobile ? "99%" : "49%",
  },
  imageStrip: {
    backgroundColor: "#ECE7F7",
    alignItems: "center",
    height: "100%",
    width: "16%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    padding: 25,
  },
  contentStrip: {
    padding: 10,
    fontSize: 12,
  },
});
