import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  ScrollView,
  Platform,
} from "react-native";
import { Button } from "react-native-paper";
import Modal from "../../components/Common/Modal";
import amplitude from "../../utils/amplitude";
import AntDesign from "@expo/vector-icons/AntDesign";
import * as Clipboard from "expo-clipboard";
import NavigationHeader from "../../components/Common/NavigationHeader";
import {
  postReferralInvite,
  getCustomerReferrals,
  createCustomerReferralCode,
} from "../../api";
import {
  getUserDetails,
  isJama,
  isMobile,
  numberWithCommas,
} from "../../utils";
import MyReferrals from "./my_referrals";
import Toast from "react-native-toast-message";
import { ShowToast, toastConfig } from "../../components/Common/Toast";

const Topbar = ({ credits = 0 }) => {
  return (
    <View style={topbarStyles.container}>
      <View style={topbarStyles.detailsContainer}>
        <Image
          source={{
            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Group+23094.png",
          }}
          style={style.image1}
        />
        <View
          style={{ flex: 1, paddingLeft: isMobile ? 10 : 30, flexWrap: "wrap" }}
        >
          <Text style={style.refer}>Refer and Earn</Text>
          <Text style={style.invite}>
            Invite your friends & family to {isJama() ? "Jama" : "Maxiom"}{" "}
            Wealth
          </Text>
        </View>
      </View>
      <View style={style.moneybox}>
        <Text style={style.money}>₹{numberWithCommas(credits)}</Text>
        <Text style={style.earned}>Credits Earned</Text>
      </View>
    </View>
  );
};

const topbarStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: isMobile ? "column" : "row",
    justifyContent: isMobile ? "center" : "space-between",
    maxWidth: 1500,
  },
  detailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: isMobile ? 16 : 0,
  },
});

export default class Referrals extends React.Component {
  constructor(props) {
    super(props);
    let username = null;
    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      username = params.username;
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
    }
    this.state = {
      user_id,
      showModal: false,
      pendingReferrals: true,
      referralsShow: false,
      isLoadingReferrals: true,
      isLoadingReferralInvite: false,
      friendName: "",
      mobileNumber: "",
      emailId: "",
      friendNameError: null,
      mobileNumberError: null,
      emailIdError: null,
      showToast: false,

      referrals: [],
      personalReferralCode: `https://www.${
        isJama() ? "jama" : "maxiom"
      }.co.in/app/accounts/signup/?ref=`,
      howitworksData: [
        {
          title: isMobile ? "  Receive invite" : "       Receive invite",
          imageUrl:
            "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Group+23010.png",
        },
        {
          title: isMobile ? "Registers" : "Friend Registers",
          imageUrl:
            "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Group+23087.png",
        },
        {
          title: "You get Credits",
          imageUrl:
            "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Group+23088.png",
        },
      ],
    };
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("Referrals", { path: global.location.href });
      } else {
        amplitude.logEvent("Referrals", {
          path: this.props.navigation.getState().routeName,
        });
      }
      await this.loadReferrals();
    } catch (error) {}
  };

  loadReferrals = async () => {
    const { user_id } = this.state;
    this.setState({ isLoadingReferrals: true });
    const response = await getCustomerReferrals(user_id);
    if (response && response.code == 100) {
      const referrals = response["result"];
      this.setState({
        referrals,
        referralsShow: true,
        isLoadingReferrals: false,
      });
    } else {
      this.setState({ isLoadingReferrals: false });
    }
  };

  handleSendInvite = async () => {
    try {
      const { friendName, mobileNumber, emailId, isLoadingReferralInvite } =
        this.state;
      if (isLoadingReferralInvite) return;
      try {
        let email_regex =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let mobile_regex = /^[0-9]{10}$/;
        this.setState({ isLoadingReferralInvite: true });
        if (!friendName) {
          this.setState({
            friendNameError: "Please Enter Your Referral Name",
            isLoadingReferralInvite: false,
          });
          return;
        }
        if (!mobileNumber || !mobile_regex.test(mobileNumber)) {
          this.setState({
            mobileNumberError: "Please Enter Valid Mobile Number",
            isLoadingReferralInvite: false,
          });
          return;
        }
        if (!emailId || !email_regex.test(emailId)) {
          this.setState({
            emailIdError: "Please Enter Valid Email Id",
            isLoadingReferralInvite: false,
          });
          return;
        }

        const data = {
          name: friendName,
          mobile: mobileNumber,
          email: emailId,
        };
        const response = await postReferralInvite(data);
        let success = false;
        if (response) {
          success = true;
          await this.loadReferrals();
        }
        this.setState({
          isLoadingReferralInvite: false,
          friendName: "",
          mobileNumber: "",
          emailId: "",
        });
        this.setState({
          showToast: true,
          personalReferralCodeError: success
            ? "Successfully sent invite !"
            : "Error sending invite",
        });
      } catch (error) {
        this.setState({
          isLoadingReferralInvite: false,
          friendName: "",
          mobileNumber: "",
          emailId: "",
        });
        this.setState({
          showToast: true,
          personalReferralCodeError: "Error sending invite !",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleCreateReferral = async () => {
    const userData = getUserDetails();
    const { referral_code = null } = userData;
    if (referral_code) {
      this.setState({
        showModal: true,
        personalReferralCode: this.state.personalReferralCode + referral_code,
      });
    } else {
      const response = await createCustomerReferralCode();
      if (response && response.code == 100) {
        const { results } = response;
        if (results && results.length > 0) {
          this.setState({
            showModal: true,
            personalReferralCode: this.state.personalReferralCode.includes(
              results
            )
              ? this.state.personalReferralCode
              : this.state.personalReferralCode + results,
          });
        } else {
          this.setState({
            showModal: true,
            personalReferralCodeError:
              "No referral code generated for your account.",
          });
        }
      } else {
        this.setState({
          showToast: true,
          personalReferralCodeError: response.msg,
        });
      }
    }
  };

  renderHowitworks = ({ item }) => {
    return (
      <View
        style={{
          alignItems: "center",
        }}
      >
        <View style={style.msg_img_bag}>
          <Image source={{ uri: item.imageUrl }} style={style.msg_img} />
        </View>
        <Text style={style.receive}>{item.title}</Text>
      </View>
    );
  };

  handleCopyLink = () => {
    const { personalReferralCode = null } = this.state;
    this.setState({
      showToast: "true",
      personalReferralCodeError: "Referral Link Copied Successfully",
    });
    if (personalReferralCode) {
      Clipboard.setStringAsync(personalReferralCode);
    }
  };

  render() {
    const {
      showModal,
      friendName,
      mobileNumber,
      referrals,
      friendNameError,
      mobileNumberError,
      emailId,
      emailIdError,

      isLoadingReferrals,
      isLoadingReferralInvite,
      personalReferralCode,
    } = this.state;
    return (
      <View style={{ flex: 1, backgroundColor: "#ffffff" }}>
        {this.state.showToast ? (
          <ShowToast
            showToast={true}
            type="status"
            title={this.state.personalReferralCodeError}
            duration="3"
          />
        ) : null}
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Invite Friends"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <ScrollView style={style.mainbody}>
          {showModal && (
            <Modal
              style={{
                alignItems: "center",
                justifyContent: "center",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                position: "absolute",
              }}
            >
              <View style={style.modalbody}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={style.referralLink}>Referral Link</Text>
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ showModal: false });
                    }}
                  >
                    <AntDesign name="close" size={22} color="#888C9F" />
                  </TouchableOpacity>
                </View>
                <View style={style.link}>
                  <Text style={style.linkName}>{personalReferralCode}</Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingTop: 32,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ showModal: false });
                    }}
                  >
                    <Text style={style.cancel}>Cancel</Text>
                  </TouchableOpacity>
                  <Button
                    uppercase={false}
                    labelStyle={style.modalbtn}
                    onPress={this.handleCopyLink}
                  >
                    Copy link
                  </Button>
                </View>
              </View>
            </Modal>
          )}
          <View style={style.topbar}>
            <Topbar />
          </View>
          <View style={{ flexDirection: isMobile ? "column" : "row" }}>
            <View style={style.leftbox}>
              <View>
                <Text style={style.howit}>How it Works?</Text>
              </View>
              <View style={style.dashed_bor}>
                <FlatList
                  contentContainerStyle={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginLeft: -21,
                  }}
                  data={this.state.howitworksData}
                  renderItem={this.renderHowitworks}
                />
              </View>
              <Text style={style.which_fri}>
                Which friend would you Refer ?
              </Text>
              <TextInput
                value={friendName}
                placeholder="Friends Name*"
                style={style.inputtxt}
                onChangeText={(text) => {
                  this.setState({ friendName: text });
                }}
              />
              {!friendName && (
                <Text style={style.error}>{friendNameError}</Text>
              )}
              <TextInput
                value={mobileNumber}
                placeholder="Mobile Number*"
                style={style.inputtxt}
                onChangeText={(text) => {
                  if (!isNaN(text)) {
                    this.setState({
                      mobileNumber: text,
                      mobileNumberError: null,
                    });
                  }
                }}
              />
              {mobileNumberError && (
                <Text style={style.error}>{mobileNumberError}</Text>
              )}
              <TextInput
                value={emailId}
                placeholder="Email Id"
                style={style.inputtxt}
                onChangeText={(text) => {
                  this.setState({ emailId: text, emailIdError: null });
                }}
              />
              <View style={{ marginBottom: 10 }}>
                <View style={{ flexDirection: "row" }}>
                  <View>
                    {emailIdError && (
                      <Text style={[style.error]}>{emailIdError}</Text>
                    )}
                  </View>
                </View>
              </View>
              <View style={style.buttonsContainer}>
                <Button
                  uppercase={false}
                  labelStyle={style.planwise}
                  onPress={() => {
                    this.handleSendInvite();
                  }}
                  mode="contained"
                  style={style.sendReferralButton}
                >
                  Send Referral
                </Button>
                <Button
                  uppercase={false}
                  labelStyle={style.planwise1}
                  onPress={() => {
                    this.handleCreateReferral();
                  }}
                >
                  Create referral link
                </Button>
              </View>
            </View>

            <View style={style.rightbox}>
              <MyReferrals
                referrals={referrals}
                isLoadingReferrals={isLoadingReferrals}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const style = StyleSheet.create({
  mainbody: {
    flex: 1,
    paddingLeft: isMobile ? 16 : 32,
    paddingRight: isMobile ? 16 : 64,
    backgroundColor: "#ffffff",
    paddingVertical: 10,
    maxWidth: 1500,
  },
  topbar: {
    backgroundColor: "#9767F8",
    borderRadius: 4,
    paddingHorizontal: 21,
    marginTop: 8,
    marginBottom: 21,
    maxWidth: 1500,
  },
  image1: {
    width: isMobile ? 45 : 59,
    height: isMobile ? 45 : 60,
  },
  refer: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isMobile ? 15 : 20,
    lineHeight: 23,
    color: "#FFFFFF",
  },
  invite: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 13 : 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingTop: isMobile ? 6 : 12,
    flexWrap: "wrap",
  },
  moneybox: {
    paddingHorizontal: 35,
    paddingVertical: 7,
    backgroundColor: "#410DAA",
    borderRadius: 4,
    marginVertical: 10,
    alignItems: "center",
  },
  money: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: 23,
    color: "#FFD200",
    letterSpacing: 0.5,
  },
  earned: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFD200",
    paddingTop: 9,
  },
  leftbox: {
    paddingHorizontal: 24,
    paddingTop: 20,
    paddingBottom: 5,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    width: isMobile ? "100%" : "52%",
  },
  howit: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    fontWeight: "bold",
    color: "#121212",
  },
  msg_img: {
    width: 28,
    height: 28,
  },
  msg_img_bag: {
    backgroundColor: "#F7F5FC",

    borderRadius: 30,
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "center",
  },
  receive: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 16,
    color: "#202020",
    paddingTop: 24,
  },
  dashed_bor: {
    borderBottomWidth: 1,
    borderBottomColor: "rgba(0, 0, 0, 0.15)",
    borderStyle: "dashed",
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingVertical: 18,
  },
  which_fri: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 18,
    color: "#202020",
    paddingTop: 20,
    paddingBottom: 16,
  },
  input: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 0.16,
    color: "rgba(0, 0, 0, 0.5)",
  },
  buttonsContainer: {
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    marginTop: isMobile ? 16 : 0,
    marginBottom: isMobile ? 16 : 43,
  },
  sendReferralButton: {
    paddingVertical: 6,
    paddingHorizontal: 10,
    backgroundColor: "#410DAA",
  },
  planwise: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    color: "#ffffff",
  },
  planwise1: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    letterSpacing: 0,
    paddingLeft: 10,
  },
  rightbox: {
    paddingHorizontal: 14,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    marginLeft: isMobile ? 0 : 32,
    marginTop: isMobile ? 20 : 0,
    width: isMobile ? "100%" : "44.2%",
    marginBottom: isMobile ? 50 : "auto",
  },
  myreferrals: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 18,
    color: "#202020",
    letterSpacing: 0.16,
  },
  image2: {
    width: 80,
    height: 80,
    marginTop: 60,
  },
  noReferralContainer: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  show: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#121212",
    opacity: 0.4,
    paddingTop: 11,
  },
  modalbody: {
    width: "100%",
    paddingVertical: 20,
    paddingHorizontal: 23,
  },
  referralLink: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 18,
    color: "#202020",
  },
  link: {
    borderRadius: 20,
    backgroundColor: "#F7F5FC",
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginTop: 18,
  },
  linkName: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
    textDecorationLine: "underline",
  },
  modalbtn: {
    fontSize: 15,
    lineHeight: 17,
    fontFamily: "Roboto",
    fontWeight: "bold",
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 28,
    borderRadius: 4,
    backgroundColor: "#410DAA",
  },
  cancel: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#000000",
    opacity: 0.5,
  },
  pend_ref: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#410DAA",
    fontWeight: "bold",
    letterSpacing: 0.4,
    paddingVertical: 6,
    paddingHorizontal: 13,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.2)",
    marginRight: 0,
  },
  initial: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.16,
    color: "#410DAA",
  },
  inputtxt: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: 20,
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "rgba(0, 0, 0, 0.5)",
    letterSpacing: 0.16,
    paddingVertical: 13,
    paddingHorizontal: 21,
    marginBottom: 10,
  },
  inpboxes: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: 20,
    paddingHorizontal: 21,
    marginTop: 15,
    flexDirection: "row",
  },
  inpt1: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "rgba(0, 0, 0, 0.5)",
    letterSpacing: 0.16,
    paddingVertical: 13,
    flex: 0.5,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.3,
  },
});
