import React from "react";
import amplitude from "../../utils/amplitude";
import { Platform } from "react-native";
export default class DeeplinkRedirection extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    global.open("jamaapp://App/Dashboard/Portfolio/Jewel_Stocks");
    if (Platform.OS == "web"){
      amplitude.logEvent("DeepLinkRedirection",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("DeepLinkRedirection", { path: this.props.navigation.getState().routeName });
    }
  }

  render() {
    return <></>;
  }
}
