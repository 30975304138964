import React from "react";
import {
  View,
  Image,
  FlatList,
  Platform,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Text } from "react-native-paper";
import Checkbox from "expo-checkbox";
import { connect } from "react-redux";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import AlertModal from "../../../components/Common/Modals/AlertModal";
import { SideHeading } from "../../../components/Common/sideHeading";
import amplitude from "../../../utils/amplitude";
import { resetFinplan as resetFinPlanAction } from "../../../actions/finplan";
import { resetFinplan } from "../../../api/finplan";
import theme from "../../../static/theme";
import { getAdvisorDashboardDetials } from "../../../api/goals";
import { StackActions } from "@react-navigation/routers";

const removeString = (text, string) => {
  return text.replace(`${string}`, "");
};

const GoalOption = ({ source, title, onPress }) => {
  return (
    <View
      style={{
        flexDirection: "column",
        alignItems: "center",
        margin: 10,
        alignSelf: "center",
      }}
    >
      <TouchableOpacity
        style={{
          borderColor: "#410DAA1A",
          borderRadius: 10,
          borderWidth: 1,
          padding: 25,
        }}
        onPress={() => {
          onPress();
        }}
      >
        <Image
          source={{
            uri: source,
          }}
          style={{ width: 100, height: 100, alignSelf: "center" }}
          resizeMode="contain"
        />
      </TouchableOpacity>
      <Text
        style={{
          fontSize: 14,
          color: "#121212",
          fontWeight: "bold",
          paddingTop: 5,
        }}
      >
        {removeString(title, "Goal")}
      </Text>
    </View>
  );
};

const data = [
  {
    name: "Marriage",
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/marriage_finplan_image.png",
    type: "CMR",
  },
  {
    name: "Education",
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/child_grad_finplan_image.png",
    type: "CED",
  },
  {
    name: "Car",
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/car_finplan_image.png",
    type: "CAR",
  },
  {
    name: "Bike",
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/bike_finplan_image.png",
    type: "BK",
  },
  {
    name: "House",
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/buying_home_fin_plan.png",
    type: "HME",
  },
  {
    name: "Retirement",
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/retirement_finplan_image.png",
    type: "RET",
  },
  {
    name: "Other",
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/other_goals2.png",
    type: "OTH",
  },
  {
    name: "Emergency",
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/other_goals2.png",
    type: "EMF",
  },
];

class ResetFinplan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removeallgoals: false,
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("ResetFinplan", { path: global.location.href });
      } else {
        amplitude.logEvent("ResetFinplan", {
          path: this.props.navigation.getState().routeName,
        });
      }
      this.setState({ isLoading: true });

      const response = await getAdvisorDashboardDetials();
      if (response && response.goals && response.goals.length) {
        const { goals = [] } = response;
        // const finplan_goals_length = goals.reduce((a, b) => {
        //   return a + (b.is_for_fin_plan ? 1 : 0);
        // }, 0);
        if (goals.length > 0) {
          let my_goals = [];
          goals.forEach((goal) => {
            // if (!goal.is_for_fin_plan) return;
            const index = data.findIndex((x) => x.type === goal.type);
            if (index != -1) {
              let image;
              if (goal["name"].toLowerCase().includes("bike")) {
                image =
                  "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/bike_finplan_image.png";
              } else {
                image = data[index]["image"];
              }
              goal["image"] = image;
              my_goals.push(goal);
            } else {
              let image = data[6].image;
              goal["image"] = image;
              my_goals.push(goal);
            }
          });
          let create_goals = [];
          if (my_goals.length != 0) {
            create_goals = data.filter((goal) => {
              return my_goals.findIndex((x) => x.type == goal.type) == -1;
            });
          }
          this.setState({ my_goals, create_goals, isLoading: false });
          return;
        }
      }
      this.goToFinplanIntro();
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  goToFinplanIntro = () => {
    const { params } = this.props.route;
    let options = {
      active: "ResetFinplan",
    };
    this.props.navigation.navigate("Goals", {
      screen: "CreateFinplan",
      params: { from: "Account", stack: "Account", ...options },
    });
    this.setState({ isLoading: false });
  };

  _renderItem = (item, index, type) => {
    const options =
      type == "create_goals"
        ? {
            goal_type: item.type,
          }
        : {
            goal_id: item.customergoal_id,
          };
    return (
      <GoalOption
        title={item.name}
        source={item.image}
        onPress={() => {
          this.props.navigation.replace("App", {
            screen: "Dashboard",
            params: {
              screen: "Goals",
              params: {
                screen: "AddGoal",
                params: { ...options },
              },
            },
          });
        }}
      />
    );
  };

  resetFinplan = async () => {
    this.setState({ isLoading: true });
    const reset_type = this.state.removeallgoals ? "all" : "finplan";
    const payload = {
      reset_type,
    };
    const response = await resetFinplan(payload);
    if (response && response.code == 100) {
      const { goals = [] } = response;
      const finplan_goals_length = goals.reduce((a, b) => {
        return a + (b.is_for_fin_plan ? 1 : 0);
      }, 0);
      if (finplan_goals_length == 0) {
        this.goToFinplanIntro();
      } else {
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleResetButtonClick = () => {
    this.setState({ dialogVisible: true });
  };

  render() {
    const { goals = [] } = this.props;
    const fin_plan_goals = goals.filter((goal) => {
      return goal.is_for_fin_plan == true;
    });
    const remaining_goals = goals.length - fin_plan_goals.length;
    return (
      <View style={{ flex: 1, backgroundColor: "white" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Financial Plan"]}
          onPressBack={() => {
            this.props.navigation.navigate("Dashboard", {
              screen: "Home",
            });
            // this.props.navigation.goBack();
          }}
        >
          <TouchableOpacity
            onPress={this.handleResetButtonClick}
            style={{
              backgroundColor: "#EF5D12",
              borderRadius: 3,
              alignItems: "center",
              position: "absolute",
              right: 16,
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: 12,
                paddingVertical: 2,
                paddingHorizontal: 6,
              }}
            >
              Reset
            </Text>
          </TouchableOpacity>
        </NavigationHeader>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
          showsVerticalScrollIndicator={true}
        >
          {this.state.isLoading ? (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator
                size="small"
                color={theme.colors.NEW_BRAND_COLOR}
              />
            </View>
          ) : (
            <>
              <SideHeading
                title="My Goals"
                style={{ fontSize: 20, alignItems: "center" }}
              />
              <View
                style={{
                  flexDirection: "row",
                  marginVertical: 15,
                  marginTop: 5,
                  marginHorizontal: 16,
                }}
              >
                <ScrollView
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                >
                  <FlatList
                    data={this.state.my_goals}
                    keyExtractor={(item, index) => index}
                    renderItem={({ item, index }) =>
                      this._renderItem(item, index, "my_goals")
                    }
                    horizontal={false}
                    numColumns={4}
                  />
                </ScrollView>
              </View>
              <SideHeading
                title="Create Goals"
                style={{ fontSize: 20, alignItems: "center" }}
              />
              <View
                style={{
                  flexDirection: "row",
                  marginVertical: 15,
                  marginTop: 5,
                  marginHorizontal: 16,
                }}
              >
                <ScrollView
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                >
                  <FlatList
                    data={this.state.create_goals}
                    keyExtractor={(item, index) => index}
                    renderItem={({ item, index }) =>
                      this._renderItem(item, index, "create_goals")
                    }
                    horizontal={false}
                    numColumns={4}
                  />
                </ScrollView>
              </View>
            </>
          )}
        </ScrollView>

        <AlertModal
          visible={this.state.dialogVisible}
          style={{ flex: 1 }}
          closeModal={() => {
            this.setState({ dialogVisible: false });
          }}
          positiveButtonText="Reset"
          negativeButtonText="Don't Reset"
          positiveButtonPress={() => {
            this.setState({ dialogVisible: false }, () => {
              this.resetFinplan();
            });
          }}
          negativeButtonPress={() => {
            this.setState({ dialogVisible: false });
          }}
        >
          <View>
            <Text
              style={{
                fontSize: 14,
                color: "#121212",
                fontFamily: "Roboto",
              }}
            >
              This is not reversible. Resetting financial plan removes all your
              goals and their mappings to investments.
            </Text>

            <Text
              style={{
                fontSize: 14,
                color: "#121212",
                marginVertical: 5,
                fontFamily: "Roboto",
              }}
            >
              You can always modify a specific goal or create a new one.
            </Text>

            <Text
              style={{
                fontSize: 14,
                color: "#121212",
                fontFamily: "Roboto",
              }}
            >
              Are you sure to reset ?
            </Text>
            {remaining_goals > 0 && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: 10,
                }}
              >
                <Checkbox
                  status={this.state.removeallgoals ? "checked" : "unchecked"}
                  onValueChange={() => {
                    let event;
                    if (this.state.removeallgoals) {
                      event = "RESETFINPLAN_ALL_GOALS";
                    } else {
                      event = "RESETFINPLAN_FINPLAN_GOALS_ONLY";
                    }
                    this.setState({
                      removeallgoals: !this.state.removeallgoals,
                    });
                  }}
                  style={{
                    margin: 0,
                    padding: 0,
                    marginLeft: -8,
                    color: "#a8a8a8",
                  }}
                  color={theme.colors.NEW_BRAND_COLOR}
                />
                <Text
                  style={{ fontSize: 14, color: "#121212", paddingLeft: 15 }}
                  onPress={() =>
                    this.setState({
                      removeallgoals: !this.state.removeallgoals,
                    })
                  }
                >
                  Remove {remaining_goals} other goal
                  {remaining_goals > 1 ? "s" : ""} created separately
                </Text>
              </View>
            )}
          </View>
        </AlertModal>
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetFinplan: () => dispatch(resetFinPlanAction()),
  };
};

export default connect(null, mapDispatchToProps)(ResetFinplan);
