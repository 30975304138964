import React from "react";

import {
  View,
  Image,
  SafeAreaView,
  ScrollView,
  Platform,
  StyleSheet,
  Dimensions,
} from "react-native";
import { Button, Text } from "react-native-paper";
import { connect } from "react-redux";
import { updateLastSeenScreen } from "../../../actions/finplan";
import amplitude from "../../../utils/amplitude";
import { getFinplanDetails, postPlaceToStudy } from "../../../api/finplan";
import HorizontalRadio from "../../../components/Common/Radio/HorizontalRadio";
import { isMobile } from "../../../utils";
const { width } = Dimensions.get("window");

class PlaceToStudy extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let type = null;
    if (params) {
      type = params.type || null;
    }
    this.state = {
      type,
      isLoading: false,
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("placeToStudy",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("placeToStudy", { path: this.props.navigation.getState().routeName });
      }
      const response = await getFinplanDetails();
      let years = null;
      if (response.code == 100) {
        const { results } = response;
        const { country_for_higher_studies } = results;
        this.setState({ country_for_ms: country_for_higher_studies });
      }
    } catch (error) {}
  }

  onPressNext = async () => {
    const { type } = this.state;
    const { country_for_ms = null } = this.state;
    if (country_for_ms != null) {
      const data = {
        country_for_ms: country_for_ms,
      };
      this.setState({ isLoading: true, error: null });
      const response = await postPlaceToStudy(data);
      if (response.code == 100) {
        this.setState({ isLoading: false, error: null });
        this.props.navigation.navigate("BuyHome", {
          type,
        });
      } else {
        this.setState({ isLoading: false, error: null });
      }
    } else {
      this.setState({ error: "Select one option" });
    }
  };

  render() {
    return (
      <View style={[styles.page]}>
        <SafeAreaView style={{ flex: 1 }}>
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              paddingBottom: 100,
              paddingTop: 50,
            }}
            showsVerticalScrollIndicator={true}
          >
            <View style={{ flex: 1, paddingHorizontal: 16 }}>
              <View
                style={{
                  flex: 0.3,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  source={{
                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/studies_finplan.png",
                  }}
                  style={{ width: 150, height: 150, alignSelf: "center" }}
                  resizeMode="contain"
                />
              </View>
              <View style={{}}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    marginTop: 30,
                    marginBottom: 30,
                    color: "#121212",
                    alignSelf: "center",
                  }}
                >
                  Where do you plan to study?
                </Text>
                <HorizontalRadio
                  vertical={true}
                  titleStyle={{
                    fontSize: 18,
                    marginBottom: 20,
                    alignItems: "center",
                  }}
                  style={{ marginTop: 20 }}
                  selected={this.state.country_for_ms}
                  values={["USC", "IND", "OTH"]}
                  radioButtonValues={[
                    "India (5 Lakhs/Year)",
                    "US/Canada (40 Lakhs/Year)",
                    "Others (15 Lakhs/Year)",
                  ]}
                  optionStyle={{ marginVertical: 5 }}
                  onSelect={(index, value) => {
                    if (value)
                      this.setState({ country_for_ms: value, error: null });
                  }}
                />

                <Text style={[styles.errorMessage]}>
                  {this.state.error || ""}
                </Text>
              </View>
            </View>
          </ScrollView>
        </SafeAreaView>
        <Button
          uppercase={false}
          mode={"contained"}
          loading={this.state.isLoading}
          onPress={this.onPressNext}
          style={styles.nextButton}
          color="#410DAA"
        >
          <Text style={styles.next}>Next</Text>
        </Button>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#fff",
    paddingBottom: 66,
  },
  formItem: {},
  inputItem: {
    width: isMobile ? "100%" : "auto",
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  nextButton: {
    width: width < 768 ? "96%" : "15%",
    marginBottom: width < 768 ? 66 : 0,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  errorMessage: {
    color: "red",
    fontSize: 14,
    paddingVertical: 5,
    fontFamily: "Roboto",
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateLastSeenScreen: (type, screen, basic, end) =>
      dispatch(updateLastSeenScreen(type, screen, basic, end)),
  };
};

export default connect(null, mapDispatchToProps)(PlaceToStudy);
