import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { Button, Switch } from "react-native-paper";
import { addAsset } from "../../../api/goals";
import { SectionHeading } from "../../../components/Common/Heading/SectionHeading";
import TextInputField from "../../../components/Common/Inputs/InputField";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import styles from "../../../styles/flex";

export const assetTypes = [
  //first two characters tell us about the asset class
  //Group Name : Real Estate 7%
  { value: null, label: "Select" },
  { value: "REA", label: "Apartment" },
  { value: "REV", label: "House or Villa " },
  { value: "REL", label: "Land / Plot" },
  // Group Name : Equity 11%
  { value: "EQS", label: "Stock" },
  { value: "EQN", label: "National Pension Scheme - Equity" },
  { value: "EQW", label: "Equity Mutual Fund" },
  { value: "EGN", label: "General Equity Asset" },
  // Group Name : Debt  7%
  { value: "DBN", label: "National Pension Scheme - Debt" },
  { value: "DBE", label: "Employee Provident Fund" },
  { value: "DBP", label: "Public Provident Fund" },
  { value: "DBB", label: "Bond" },
  { value: "DBF", label: "Bank FD" },
  { value: "DBM", label: "Post Office Savings - Monthly Income Scheme" },
  { value: "DBR", label: "Post Office Savings - Recurring Deposit Scheme" },
  { value: "DBC", label: "Cash in Bank" },
  { value: "DBL", label: "Cash Receivable" },
  { value: "DBD", label: "Debt Mutual Fund" },
  { value: "DGN", label: "General Assets" },
  { value: "SSY", label: "Sukanya Samriddhi Yojana" },
  // Group Name : Insurance 6%
  { value: "INL", label: "Insurance Endowment Policy" },
  { value: "INW", label: "Insurance Whole Life Policy" },
  { value: "INU", label: "Unit Linked Insurance Plan" },
  //8%   // Group Name : Gold 7%
  { value: "GDB", label: "Gold - Biscuits or Coins" },
  { value: "GDS", label: "Gold - Sovereign Bonds" },
  { value: "GDM", label: "Gold Mutual Fund" },
];

export default class AddAsset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachToGoal: true,
      assetName: this.props.assetName || "",
      assetType: this.props.assetType || "SEL",
      assetValue: this.props.assetValue || 0,
      valueError: null,
      typeError: null,
      nameError: null,
      hideTitle: this.props.hideTitle || false,
    };
  }

  assetNameChange = (assetName) => {
    this.setState({
      assetName,
    });
  };

  assetTypeChange = (assetType, itemIndex) => {
    this.setState({ assetType });
  };

  assetValueChange = (assetValue) => {
    this.setState({ assetValue });
  };

  componentDidMount() {}

  addNewAsset = async () => {
    try {
      const { assetName, assetType, assetValue, attachToGoal } = this.state;
      if (assetName == "" || assetName == null) {
        this.setState({ nameError: "Please enter Asset Name" });
        return;
      } else if (assetType == "SEL" || assetType == "") {
        this.setState({ typeError: "Please select asset type" });
        return;
      } else if (
        assetValue == null ||
        assetValue == "" ||
        assetValue == 0 ||
        (assetValue && isNaN(assetValue))
      ) {
        this.setState({
          typeError: null,
          valueError: "Please enter a valid amount",
        });
        return;
      } else {
        this.setState({
          loading: true,
          nameError: null,
          typeError: null,
          valueError: null,
        });
        const payload = {
          name: assetName,
          type: assetType,
          latest_value: Number(assetValue),
          date: "",
          unique_no: "",
          amount_at_cost: "",
          asset_id: this.props.id || "",
        };
        const response = await addAsset(payload);
        if (response) {
          if (response.code == 100) {
            const tagasset = await this.props.onAddAsset({
              assetName,
              assetType: this.props.data
                ? this.props.data.type
                : response.results.type,
              assetValue,
              attachToGoal,
              assetId: response.results.investoraccountasset_id,
            });

            if (tagasset) {
              this.props.rightButtonOnPress();
            } else {
              this.setState({
                loading: false,
                showRightButton: true,
                attachToGoal: true,
                assetName: "",
                assetType: "",
                assetValue: 0,
              });
            }
          } else {
          }
        }
      }
    } catch (error) {}
  };

  render() {
    const {
      title,
      rightButtontTitle,
      showRightButton,
      rightButtonOnPress,
      rightButtonStyle,
      headingStyle,
      style,
      isLoading,
    } = this.props;

    const {
      assetName,
      assetValue,
      assetType,
      attachToGoal,
      nameError,
      typeError,
      valueError,
    } = this.state;

    return (
      <View style={{ ...style }}>
        {!this.state.hideTitle && (
          <SectionHeading
            title={title}
            style={{
              paddingHorizontal: 16,
              ...headingStyle,
            }}
            rightButtontTitle={rightButtontTitle}
            showRightButton={showRightButton}
            rightButtonOnPress={() => {
              rightButtonOnPress();
            }}
            rightButtonStyle={{ ...rightButtonStyle }}
          />
        )}
        <View style={localStyles.form}>
          <View style={[styles.formItem, styles.itemMargin]}>
            <TextInputField
              label="Asset Name"
              value={assetName}
              onChangeText={this.assetNameChange}
              style={[{ ...styles.inputItem }]}
              editable={true}
              keyboardType={"name-phone-pad"}
            />

            <Text
              style={[
                localStyles.errorText,
                {
                  paddingVertical: 0,
                  marginVertical: 0,
                  paddingLeft: 2,
                },
              ]}
            >
              {nameError || ""}
            </Text>
          </View>

          <View style={[localStyles.itemMargin]}>
            <PickerFieldSelect
              data={assetTypes}
              value={assetType}
              label="Asset Type"
              onChange={this.assetTypeChange}
              pickerStyle={[{ ...localStyles.inputItem }]}
              isValid={typeError}
              type="select"
              placeholder={{ value: "SEL", label: "Select" }}
            />

            <Text
              style={[
                localStyles.errorText,
                {
                  paddingVertical: 0,
                  marginVertical: 0,
                  paddingLeft: 2,
                },
              ]}
            >
              {typeError || ""}
            </Text>
          </View>

          <View
            style={[localStyles.itemMargin]}
          >
            <View style={{ flex: 0.7 }}>
              <TextInputField
                prefix="₹"
                label="Current Asset Value"
                value={assetValue.toString()}
                onChangeText={this.assetValueChange}
                style={[{ ...styles.inputItem }]}
                editable={true}
                keyboardType={"name-phone-pad"}
              />
            </View>
            {/* <View
              style={{
                flex: 0.3,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "#BCBCBC",
                  paddingBottom: 5,
                }}
              >
                Attach to Goal
              </Text>
              <Switch
                style={{ marginTop: 2, width: 25, height: 25 }}
                color={"#410DAA"}
                onValueChange={(value) =>
                  this.setState({ attachToGoal: value })
                }
                value={attachToGoal}
              />
            </View> */}
          </View>

          <Text
            style={[
              localStyles.errorText,
              {
                paddingVertical: 0,
                marginVertical: 0,
                paddingLeft: 2,
              },
            ]}
          >
            {valueError || ""}
          </Text>
          <View style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Button
            uppercase={false}
            mode={"contained"}
            onPress={this.addNewAsset}
            style={{
              marginTop: 15,
              width: "35%",
            }}
            loading={this.state.loading || isLoading}
            color={"#410DAA"}
          >
            <Text style={localStyles.next}>Add & attach to  goal</Text>
          </Button>
          </View>
        </View>
      </View>
    );
  }
}

const localStyles = StyleSheet.create({
  form: { marginHorizontal: 16, paddingVertical: 10 },
  formItem: {
    borderColor: "#F8F8F8",
    padding: 0,
    margin: 0,
    width: "100%",
    height: 40,
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
    margin: 0,
    padding: 0,
    alignItems: "flex-end",
    justifyContent: "flex-start",
    marginBottom: 0,
    paddingBottom: 0,
    alignSelf: "flex-start",
  },
  inputLabel: {
    color: "#BCBCBC",
    fontSize: 13,
    marginVertical: 0,
    paddingVertical: 0,
  },
  errorText: {
    color: "red",
    fontSize: 10,
    height: 16,
  },
});
