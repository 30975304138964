import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Modal, Text } from 'react-native-paper';
import ANTIcon from "@expo/vector-icons/AntDesign";


export const CustomModal = ({ visible = false, showCloseButton = true, closeModal = null, children = null, style = null, ...rest }) => {
  const [visibility, setVisibility] = React.useState(visible);

  const showModal = () => setVisibility(true);
  const hideModal = () => setVisibility(false);

  const handleClosePress = () => {
    hideModal()
    closeModal && closeModal();
  }

  return (
        <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={[styles.containerStyle, {...style}]}>
          {
              showCloseButton && (
                <ANTIcon
                    name="close"
                    color={"#12121280"}
                    size={20}
                    style={{
                        position: "absolute",
                        right: 6,
                        top: 6,
                        zIndex: 100
                    }}
                    onPress={handleClosePress}
                />
              )
          }
          {
            children
          }
        </Modal>
  );
};

const styles =  StyleSheet.create({
    containerStyle : {
        backgroundColor: '#fff', padding: 20, paddingTop: 50, paddingBottom: 40, height: "auto", maxWidth: 600, minWidth: 220,
        alignSelf: "center"
    }
});