import React from "react";
import { View, Text, StyleSheet, TouchableOpacity, Image } from "react-native";
import { isMobile } from "../../../../utils";
import BulletPoint from "./BulletPoint";

const Rebalancing = ({ data = {}, style = [], is_ria = false, onClick }) => {
  const { title, summary = [], is_clickable } = data;

  const handleClick = () => {
    is_clickable && onClick("RebalanceLandingPage");
  };

  const ArrowIcon = require("../../../../assets/JamaInvest/Arrow-right2x.png");

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        width: isMobile ? "96%" : "32.8%",
      }}
    >
      <TouchableOpacity
        style={[strip.border, strip.container, ...style]}
        onPress={handleClick}
      >
        <>
          <View style={strip.titleContainer}>
            <Text style={strip.title}>
              {title}
              {is_clickable ? (
                <Image
                  source={{
                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/new.gif",
                  }}
                  style={{
                    width: 90,
                    height: 20,
                    resizeMode: "contain",
                    position: "absolute",
                    top: 2,
                    left: 80,
                    right: 0,
                    bottom: 0,
                  }}
                />
              ) : null}
            </Text>
            {is_clickable && (
              <Image
                source={ArrowIcon}
                style={[
                  { width: !isMobile ? 18 : 8, height: !isMobile ? 18 : 8 },
                ]}
              ></Image>
            )}
          </View>
          <View style={strip.pointsContainer}>
            {summary.map((point) => {
              return (
                <View style={strip.point}>
                  <BulletPoint
                    outerColor="#888C9F60"
                    innerColor="#888C9F"
                    style={[strip.bulletPoint]}
                  />
                  <Text style={strip.pointText}>{point}</Text>
                </View>
              );
            })}
          </View>
        </>
      </TouchableOpacity>
      {is_ria ? null : (
        <TouchableOpacity
          style={{
            width: isMobile ? "88%" : "91%",
            marginTop: 10,
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          onPress={() => {
            let newTab = window.open();
            newTab.location.href =
              "https://maxiomwealth.com/blog/how-to-rebalance-your-portfolio-on-jamawealth/";
          }}
        >
          <Image
            source={require("../../../../assets/how_to.png")}
            resizeMode="contain"
            style={{
              height: 18,
              width: 18,
              marginRight: 8,
            }}
          />
          <Text
            style={{
              fontSize: 14,
              color: "#410DAA",
              fontWeight: "700",
            }}
          >
            How to rebalance portfolio
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const strip = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    height: 120,
    marginRight: 32,
  },
  border: {
    borderWidth: 1,
    borderColor: "#410DAA33",
    borderRadius: 15,
  },
  image: {
    width: 40,
    height: 40,
    position: "absolute",
    right: 25,
    top: 40,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 17,
    color: "#494B69",
    fontWeight: "bold",
    lineHeight: 20,
    marginRight: 10,
  },
  pointsContainer: {
    marginTop: 15,
  },
  point: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  pointText: {
    fontWeight: "bold",
    fontFamily: "Roboto",
    color: "#494B69",
    fontSize: 12,
  },
  bulletPoint: {
    marginRight: 7,
  },
});

export default Rebalancing;
