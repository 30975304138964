import React, { Component } from "react";
import {
  View,
  FlatList,
  Text,
  SafeAreaView,
  ActivityIndicator,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  Platform,
} from "react-native";
import { getPendingBills } from "../../../api/alerts";
import { getInvoicesPaymentLink } from "../../../api/billing";
import amplitude from "../../../utils/amplitude";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { isMobile } from "../../../utils";

export default class PendingBills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bills: {},
      showHTMLContent: "",
      loading: false,
    };
  }

  componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("PendingBills",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("PendingBills", { path: this.props.navigation.getState().routeName });
    }
    this.pendingBills();
  }

  pendingBills = () => {
    try {
      this.setState({ loading: true });
      getPendingBills().then((response) => {
        this.setState({
          bills: response.results,
          loading: false,
        });
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  handleMakePayment = async () => {
    try {
      if(parseInt(this.state.bills.total_amount.toFixed(0)) == 0){
        // console.log("amount is zero", this.state.bills.total_amount.toFixed(0))
        return;
      }
      if (isMobile) {
        const payload = {
          invoices: "9192",
          amount: parseInt(this.state.bills.total_amount.toFixed(0)),
          server_name: "mobile",
        };

        this.setState({ loading: true });
        const response = await getInvoicesPaymentLink(payload);
        if (response && response.code == 100) {
          const { results = {} } = response;
          const { payment_link } = results;
          this.props.navigation.navigate("App", {
            screen: "Dashboard",
            params: {
              screen: "Portfolio",
              params: {
                screen: "ShowWebView",
                params: {
                  url: payment_link,
                  successURL: "billing/payment/status/",
                  onSuccess: function (navigation, _results = {}) {
                    navigation.push("App", {
                      screen: "Billing",
                      params: {
                        screen: "InvoicesPaymentRedirection",
                        params: {},
                      },
                    });
                  },
                  onError: function (_results = {}) {},
                },
              },
            },
          });
          this.setState({ loading: false });
        }
      } else {
        let server_name = global.location.href.split("/");
        const payload = {
          invoices: "9192",
          amount: parseInt(this.state.bills.total_amount.toFixed(0)),
          server_name: Platform.OS == "web" ? server_name[2] : "mobile",
        };
        this.setState({ loading: true });
        const response = await getInvoicesPaymentLink(payload);
        if (response && response.code == 100) {
          const { results = {} } = response;
          const { payment_link } = results;
          if (Platform.OS == "web") {
            global.open(payment_link, "_self");
          } else {
            this.props.navigation.navigate("App", {
              screen: "Dashboard",
              params: {
                screen: "Portfolio",
                params: {
                  screen: "ShowWebView",
                  params: {
                    url: payment_link,
                    successURL: "billing/payment/status/",
                    onSuccess: function (navigation, _results = {}) {
                      navigation.push("App", {
                        screen: "Billing",
                        params: {
                          screen: "InvoicesPaymentRedirection",
                          params: {},
                        },
                      });
                    },
                    onError: function (_results = {}) {},
                  },
                },
              },
            });
          }
          this.setState({ loading: false });
        }
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  _renderView = (items) => {
    return (
      <View
        style={{
          padding: 8,
          borderRadius: 5,
          borderWidth: 1,
          borderColor: "#410DAA1A",
          marginBottom: 20,
        }}
      >
        <View>
          <Text
            style={{ color: "black", alignSelf: "flex-start", fontSize: 13 }}
          >
            <Text style={{ fontWeight: "bold" }}>Date: </Text>
            {items.item.created.replace("<br>", " ")}
            {isMobile ? "\n" : "\n\n"}
          </Text>
          <Text
            style={{ color: "black", alignSelf: "flex-start", fontSize: 13 }}
          >
            <Text style={{ fontWeight: "bold" }}>Product Type: </Text>{" "}
            {items.item.product_type}
            {"\n\n"}
            <Text style={{ fontWeight: "bold" }}>Amount:</Text>{" "}
            {items.item.amount}
            {"\n\n"}
            <Text style={{ fontWeight: "bold" }}>Description:</Text>{" "}
            {items.item.description}
            {"\n\n"}
            <Text style={{ fontWeight: "bold" }}>Due Date:</Text>{" "}
            {items.item.due_date.replace("<br>", " ")}
          </Text>
        </View>
      </View>
    );
  };

  render() {
    var count = Object.keys(this.state.bills).length;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Pending Bills"]}
          onPressBack={() => this.props.navigation.navigate("Notifications")}
        />
        {this.state.loading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        ) : (
          <ScrollView
            style={{
              borderRadius: 5,
              marginBottom: 20,
              paddingLeft: isMobile ? "5%" : "30%",
              paddingRight: isMobile ? "5%" : "30%",
              marginTop: 20,
            }}
          >
            {count != 0 ? (
              <View>
                <FlatList
                  data={this.state.bills.bills}
                  renderItem={this._renderView}
                  keyExtractor={(item, index) => index.toString()}
                />
                <View style={{ alignItems: "center", marginTop: 8 }}>
                  <Text style={{ color: "black", fontSize: 13 }}>
                    <Text style={{ fontWeight: "bold" }}>
                      Total Pending Billing Amount:{" "}
                    </Text>
                    &#8377; {this.state.bills.billing_amount.toFixed(0)}
                    {"\n\n"}
                    <Text style={{ fontWeight: "bold" }}>GST at 18% :</Text>
                    &#8377; {this.state.bills.gst_amount.toFixed(0)}
                    {"\n\n"}
                    <Text style={{ fontWeight: "bold" }}>
                      Total Payable with GST :
                    </Text>
                    &#8377; {this.state.bills.total_amount.toFixed(0)}
                    {"\n"}
                  </Text>

                  <TouchableOpacity
                    style={{
                      padding: 5,
                      backgroundColor: "#410DAA",
                      justifyContent: "center",
                      marginBottom: 15,
                      marginTop: 30,
                    }}
                    onPress={() => {
                      this.handleMakePayment();
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginHorizontal: 5,
                        fontSize: 13,
                      }}
                    >
                      PAY NOW
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            ) : null}
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    padding: 16,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
