import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity,
} from "react-native";
import Entypo from "@expo/vector-icons/Entypo";
import { isMobile, numberWithCommas } from "../../../utils";

const TableRow = ({
  data: rowData,
  gridConfig,
  navigation,
  selectedAssetSubTab,
}) => {
  return (
    <View style={styles.tableRow}>
      {rowData.map((rowItem, rowItemIndex) => {
        const {
          value,
          style = [],
          component = false,
          valueToDisplay,
          rowItemContainerStyle = [],
        } = rowItem;
        return rowItemIndex == 5 ? (
          <View
            style={{
              flex: gridConfig[rowItemIndex],
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("ModifyAsset", {
                  assetName: rowData[0].value.props.children[0],
                  type: rowData[5].valueToDisplay[1].toString(),
                  value: rowData[3].valueToDisplay.toString(),
                  id: rowData[5].valueToDisplay[0].toString(),
                  selectedAssetSubTab: selectedAssetSubTab,
                });
              }}
            >
              <Text
                style={{
                  color: "#410DAA",
                  fontWeight: "bold",
                  fontSize: 13,
                  textDecorationLine: "underline",
                }}
              >
                Edit
              </Text>
            </TouchableOpacity>
          </View>
        ) : (
          <View
            style={{
              flex: gridConfig[rowItemIndex],
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {component ? (
              <Text style={[styles.rowItem, ...style]}>{value}</Text>
            ) : (
              <Text style={[styles.rowItem, ...style]}>
                {rowItemIndex == 3 || rowItemIndex == 4
                  ? "₹" + numberWithCommas(parseInt(valueToDisplay))
                  : valueToDisplay}
              </Text>
            )}
          </View>
        );
      })}
    </View>
  );
};

const TableHeader = ({
  data = [],
  gridConfig,
  pageIndex,
  numberOfEntries = 0,
  tableHeaderStyle = [],
}) => {
  const { start, end } = getStartEnd(numberOfEntries, pageIndex);
  return (
    <View style={[styles.tableHeader, ...tableHeaderStyle]}>
      <View style={{ flexDirection: "row", marginRight: 10, width: "100%" }}>
        {data.map((headingItem, headingIndex) => {
          const {
            component = false,
            label,
            sortValue,
            currentSortField,
            sortOption,
            onSort,
            key,
            headerItemContainerStyle = [],
            style = [],
          } = headingItem;
          const activeSortKey = currentSortField === key && sortValue;
          if (component) {
            return (
              <View
                style={{
                  flex: gridConfig[headingIndex],
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {label}
              </View>
            );
          }
          return (
            <View
              style={{
                flex: gridConfig[headingIndex],
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Text style={[styles.headingItem, ...style]}>{label}</Text>
              {sortOption && (
                <TouchableOpacity
                  onPress={() => onSort(key, sortValue, start, end)}
                  style={styles.sortContainer}
                >
                  {activeSortKey ? (
                    <Entypo
                      name="triangle-up"
                      size={15}
                      color={activeSortKey ? "#464E5F" : "#464E5F"}
                      style={styles.sortIconDown}
                    />
                  ) : (
                    <Entypo
                      name="triangle-down"
                      size={15}
                      color={activeSortKey ? "#464E5F" : "#464E5F"}
                      style={styles.sortIconUp}
                    />
                  )}
                </TouchableOpacity>
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
};

const Pagination = ({ length, pageIndex, onChange }) => {
  const { start, end, total } = getStartEnd(length, pageIndex);
  return (
    <View style={pagination.container}>
      <View style={pagination.body}>
        <Text style={pagination.current}>
          Showing results {start + 1} - {end} of {length}
        </Text>
        <View style={pagination.buttonsContainer}>
          <TouchableOpacity
            style={pagination.iconContainer}
            onPress={() => {
              if (pageIndex != 0) {
                onChange(pageIndex - 1);
              }
            }}
          >
            <Entypo
              name="chevron-thin-left"
              size={12}
              color={"#464E5F"}
              style={pagination.icon}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={pagination.iconContainer}
            onPress={() => {
              onChange(pageIndex);
            }}
          >
            <Text style={pagination.currentIndex}>{pageIndex + 1}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={pagination.iconContainer}
            onPress={() => {
              if (pageIndex + 1 < total) {
                onChange(pageIndex + 1);
              }
            }}
          >
            <Entypo
              name="chevron-thin-right"
              size={12}
              color={"#464E5F"}
              style={pagination.icon}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const getStartEnd = (length, pageIndex) => {
  const numberOfRowsToSkip = pageIndex * ROWS_TO_SHOW;
  let start = 0,
    end = length;
  if (numberOfRowsToSkip <= length) {
    start = numberOfRowsToSkip;
    if (start + ROWS_TO_SHOW <= length) {
      end = start + ROWS_TO_SHOW;
    } else {
      const diff = length - start;
      end = start + diff;
    }
  }

  const total = Math.ceil(length / ROWS_TO_SHOW);
  return { start, end, total };
};

const ROWS_TO_SHOW = 30;
const AssetTable = ({
  header,
  gridConfig,
  selectedAssetSubTab,
  data = [],
  style = [],
  pagination = true,
  tableHeaderStyle = [],
  navigation = {},
}) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [dataToDisplay, setDataToDisplay] = useState([]);

  const renderRow = ({ item, index }) => {
    return (
      <TableRow
        data={item}
        key={index}
        gridConfig={gridConfig}
        navigation={navigation}
        selectedAssetSubTab={selectedAssetSubTab}
      />
    );
  };

  React.useEffect(() => {
    const d = getDataToDisplay(data, currentPageIndex);
    setDataToDisplay(d);
  }, [data, currentPageIndex]);

  const getDataToDisplay = (data, pageIndex) => {
    const length = data.length;
    const { start, end } = getStartEnd(length, pageIndex);
    return data.slice(start, end);
  };

  return (
    <View style={[styles.container, ...style]}>
      <TableHeader
        data={header}
        numberOfEntries={dataToDisplay.length}
        gridConfig={gridConfig}
        pageIndex={currentPageIndex}
        tableHeaderStyle={tableHeaderStyle}
      />
      <FlatList
        data={dataToDisplay}
        renderItem={renderRow}
        keyExtractor={(item, index) => index.toString()}
        style={styles.flatList}
      />
      {!!data.length && pagination && (
        <Pagination
          length={data.length}
          pageIndex={currentPageIndex}
          onChange={(index) => setCurrentPageIndex(index)}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    overflow: "hidden",
    paddingBottom: 20,
  },
  tableHeader: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F3F6F9",
    paddingVertical: 11,
  },
  firstHeaderTitle: {
    justifyContent: "flex-start",
  },
  headingItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  headingItem: {
    textAlign: "center",
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  sortContainer: {
    marginLeft: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  sortIconUp: {
    paddingVertical: 0,
    marginVertical: 0,
  },
  sortIconDown: {
    marginLeft: 0,
  },
  rowItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  rowItem: {
    textAlign: "center",
    color: "#121212",
    fontSize: 11,
    fontFamily: "Roboto",
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    flex: 1,
    paddingVertical: 13,
  },
  flatList: {},
});

const pagination = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: isMobile ? "flex-start" : "flex-end",
  },
  body: {
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 36,
  },
  current: {
    color: "#464E5F",
    fontFamily: "Roboto",
    fontSize: 13,
    marginRight: 12,
    marginLeft: isMobile ? 12 : 0,
  },
  iconContainer: {
    paddingVertical: 5,
    paddingHorizontal: 8,
    backgroundColor: "#F3F6F9",
    marginRight: 9,
  },
  buttonsContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  currentIndex: {
    fontSize: 13,
    color: "#464E5F",
    fontFamily: "Roboto",
  },
});

export default AssetTable;
