import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { isMobile } from "../../../utils";

const PickerField = ({
  label,
  data,
  value,
  onChange,
  width,
  borderColor,
  height,
  style = {},
  showSelect = true,
  placeholderValue = null,
  editable = true,
  required = false,
  pickerStyle = [],
}) => {
  return (
    <View style={[{ ...style }]}>
      {!!label && (
        <Text style={pickerStyles.label}>
          {label}
          <Text style={pickerStyles.required}>{required ? "*" : ""}</Text>
        </Text>
      )}
      <View style={[pickerStyles.pickerContainer, {width: width ? width : 300} ]}>
        <Picker
          selectedValue={value}
          onValueChange={(itemValue, itemIndex) =>
            editable && onChange && onChange(itemValue)
          }
          style={[
            pickerStyles.picker,
            !editable && pickerStyles.notEditable,
            {
              height: height ? height : 44,
              width: width ? width : 300,
              borderColor: borderColor ? borderColor : "auto",
            },
            ...pickerStyle,
          ]}
          itemStyle={pickerStyles.pickerItemStyle}
        >
          {showSelect && (
            <Picker.Item
              label={placeholderValue ? placeholderValue : "Select"}
              value="0"
            />
          )}
          {data.map((item) => {
            return (
              <Picker.Item
                label={item.label}
                value={item.value}
                style={{
                  fontFamily: "Open Sans",
                  fontSize: 14,
                  color: "#202020",
                }}
              />
            );
          })}
        </Picker>
      </View>
    </View>
  );
};

const pickerStyles = StyleSheet.create({
  label: {
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: "700",
    color: "#464E5F",
    marginBottom: 11,
  },
  pickerContainer: {
    width: isMobile ? "100%" : 300,
  },
  picker: {
    width: isMobile ? "100%" : 300,
    paddingHorizontal: 20,
    paddingVertical: 11,
    borderRadius: 5,
  },
  pickerItemStyle: { height: 44 },
  notEditable: {
    backgroundColor: "#EDF4F6",
  },
  required: {
    color: "red",
  },
});

export default PickerField;
