import React, {Component} from "react";
import {View,Text, TouchableOpacity, Image} from "react-native";

export default class NotFound extends React.Component {
    constructor(props){
        super(props);
        this.state={}
    }

    render(){
        const {style={}, containerStyle={}} = this.props;
        const OOPSImage = require('../../assets/oops.png');
        return(
            <View 
                style={{flex:1,justifyContent:"center",alignItems:"center"}}
            >
                <Image source={OOPSImage} style={{width:270,height:270, marginBottom:84}}></Image>
                <Text style={{fontSize:18, color:"#5A6671"}}>The page you’re looking could not be found !</Text>
                <TouchableOpacity 
                    onPress={()=>{
                        this.props.navigation.navigate("InvestmentOverview");
                    }}
                    style={{
                        backgroundColor:"#410DAA",
                        paddingHorizontal:64,
                        paddingVertical:10,
                        marginTop:20,
                        borderRadius:6
                    }}
                >
                    <Text style={{color:"#fff",fontSize:14}}>Back</Text>
                </TouchableOpacity>
            </View>
        )
    }
}