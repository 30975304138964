import React, { PureComponent, useEffect } from "react";
import {
  View,
  ScrollView,
  SafeAreaView,
  StyleSheet,
  FlatList,
  Image,
  Platform,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import { ActivityIndicator, Button, Text } from "react-native-paper";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { getBankData, setDefaultBankData } from "../../../api/account";
import theme from "../../../static/theme";
import { isMobile, isWeb } from "../../../utils";
import { useFilePicker } from "use-file-picker";
import { validateInput } from "../../../utils/validateInput";
import TextInputField from "../../../components/Common/Inputs/InputField";
import amplitude from "../../../utils/amplitude";
import { DataURIToBlob, getUserDetails } from "../../../utils";
import { addBankAccount, verifyIFSC } from "../../../api";
import Entypo from "@expo/vector-icons/Entypo";
import { ShowToast } from "../../../components/Common/Toast";
import DocumentPicker, { types } from "expo-document-picker";
import { md } from "../../../Responsive";
const { width } = Dimensions.get("window");

const SelectChequeLeaf = ({
  onSelect,
  selectedImageName = "",
  viewSampleImage,
}) => {
  const { openFilePicker,filesContent, loading, errors } = useFilePicker({
    readAs: "DataURL",
    accept: [".png", ".jpg", ".jpeg", ".pdf"],
    multiple: true,
    limitFilesConfig: { max: 1 },
    maxFileSize: 0.5,
  });

  useEffect(() => {
    onSelect(filesContent, errors);
  }, [filesContent]);
  const isFileSelected = () => {
    return filesContent.length != 0;
  };
  if (loading) {
    return <Text>Loading</Text>;
  }
  const checkSizeError = () => {
    if (errors && errors.length) {
      return {
        fileSizeToolarge: true,
        error: true,
      };
    }
    return null;
  };

  const handleFileSelector = async () => {
    try {
      if (Platform.OS == "web") {
        openFilePicker();
      } else {
        const result = await DocumentPicker.pickSingle({
          allowMultiSelection: false,
          type: [types.images],
        });
        onSelect([{ content: result.uri, name: result.name, ...result }]);
      }
    } catch (error) {}
  };

  const selectedFile = isFileSelected();
  const errorData = checkSizeError();

  return (
    <View
      style={{ flexDirection: "column", paddingTop: md(width) ? 31.2 : 25 }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 13.29,
        }}
      >
        <Text style={styles.upl_check}>
          Upload A Cancelled Cheque Leaf Image*
        </Text>
        <TouchableOpacity
          style={{ display: md(width) ? "none" : "none" }}
          onPress={() => {
            viewSampleImage && viewSampleImage();
          }}
        >
          <Text style={styles.view_sample}>View Sample Image</Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        onPress={() => handleFileSelector()}
        style={{
          alignItems: "center",
          paddingVertical: 28,
          backgroundColor: "#f6f6f6",
          borderRadius: 4,
          borderStyle: "dashed",
          borderWidth: 1,
          borderColor: "rgba(65, 13, 170, 0.5)",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Entypo name="attachment" size={20} color="#13C37B" />
          <Text style={styles.drop}> Click here to browse and attach file</Text>
        </View>
      </TouchableOpacity>
      {!isMobile &&
        selectedFile &&
        filesContent.map((file, index) => (
          <Text style={styles.fileName}>{file.name}</Text>
        ))}
      {isMobile && selectedImageName != "" && (
        <Text style={styles.fileName}>{selectedImageName}</Text>
      )}
      <Text
        style={[
          styles.support,
          errorData &&
            errorData.error &&
            !errorData.fileSizeToolarge &&
            styles.error,
        ]}
      >
        Supports PNG, JPG, JPEG, PDF.{" "}
        <Text style={[errorData && errorData.fileSizeToolarge && styles.error]}>
          Max File Size 500KB
        </Text>
      </Text>
    </View>
  );
};

class UserBankAccounts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      bankAccounts: [],
      ifsc: "",
      accountNumber: "",
      confirmAccountNumber: "",
      addAccountClicked: false,
      IFSCError: false,
      AccNoError: false,
      showAddAccountToast: "false",
    };
  }

  static navigationOptions = {
    tabBarVisible: false,
  };

  inputValueUpdater = (key, value) => {
    if (key == "ifsc" && value.length == 11) {
      this.setState(
        {
          gettingIfsc: true,
          [key]: value,
        },
        () => this.verifyIfsc()
      );
    }

    this.setState({
      [key]: value,
      error: "",
    });
  };

  verifyIfsc = async () => {
    const { ifsc } = this.state;
    if (ifsc && ifsc.length <= 0) {
      this.setState({
        error: "Please enter IFSC to verify",
      });
      return;
    }

    this.setState({
      gettingIfsc: true,
    });

    const payload = {
      ifsc_code: ifsc,
    };

    this.setState({
      gettingIfsc: false,
    });
    const response = await verifyIFSC(payload);
    if (response && response.code == "100") {
      if (response.hasOwnProperty("bse_bank_code")) {
        const { code, ...bankDetails } = response;
        this.setState({
          bankDetails: bankDetails || {},
          bankName: bankDetails.bank_name,
        });
      }
    }
  };

  validate = () => {
    const { ifsc, accountNumber, bankName } = this.state;
    if (ifsc.length != 11) return false;
    if (accountNumber.length < 9) return false;
    if (!bankName) return false;
    return true;
  };

  addBankAccountFn = async () => {
    const {
      ifsc,
      accountNumber,
      imageuri = null,
      imagename = null,
    } = this.state;
    if (!ifsc || ifsc == "" || ifsc.length != 11) {
      this.setState({ IFSCError: true });
      return;
    } else {
      this.setState({ IFSCError: false });
    }

    if (!accountNumber || accountNumber == "" || accountNumber.length < 9) {
      this.setState({ AccNoError: true });
      return;
    } else {
      this.setState({ AccNoError: false });
    }

    this.setState({ submitting: true });

    const { customer_id } = getUserDetails();

    var body = new FormData();
    body.append("customer", customer_id);
    body.append("ifsc_code", this.state.ifsc);
    body.append("bank_name", this.state.bankDetails.bank_name);
    body.append(
      "branch_name",
      this.state.bankDetails.branch_name.substring(0, 49)
    );
    body.append("account_number", accountNumber);
    body.append("account_type", "SB");
    const imageData = DataURIToBlob(imageuri);
    body.append("cheque_image", imageData, imagename);

    if (this.props.bankDetails && this.props.bankDetails.length == 0) {
      body.append("is_default_customer_bank_account", true);
    }

    const response = await addBankAccount(body);
    if (response && response.bank_name) {
      this.setState({
        submitting: false,
        addAccountClicked: false,
        loading: false,
      });
      this.setState({ showAddAccountToast: "true" });
      setTimeout(() => {
        this.props.navigation.goBack();
      }, 3000);
    } else {
      this.setState({
        submitting: false,
        loading: false,
      });
    }
  };

  handleChange = (value, type, inputType) => {
    if (type == "ifsc") {
      this.setState({ IFSCError: false });
    }
    if (type == "accountNumber") {
      this.setState({ AccNoError: false });
    }
    this.setState({
      [type]: value,
    });
    if (type == "ifsc" && value.length == 11) {
      this.setState(
        {
          gettingIfsc: true,
        },
        () => this.verifyIfsc()
      );
    }
  };

  componentDidMount = async () => {
    if (Platform.OS == "web") {
      amplitude.logEvent("BankAccounts", { path: global.location.href });
    } else {
      amplitude.logEvent("BankAccounts", {
        path: this.props.navigation.getState().routeName,
      });
    }
    await this.getUserBankAccounts();
  };

  getUserBankAccounts = async () => {
    this.setState({ fetching: true });

    const response = await getBankData();
    if (response && response.results) {
      let tempArr = [];
      response.results.forEach((item) => {
        if (item.ifsc_code) {
          tempArr.push(item);
        }
      });

      this.setState({ fetching: false, bankAccounts: tempArr });
    } else {
      this.setState({ fetching: false });
    }
  };

  unsetDefaultBankAccount = async (customer) => {
    let requests = [];
    this.props.bankAccounts.forEach(async (account) => {
      if (account.is_default_customer_bank_account == true) {
        const payload = {
          customer,
          is_default_customer_bank_account: false,
        };
        requests.push(setDefaultBankData(payload, account.id));
      }
    });

    return Promise.all(requests)
      .then(() => {
        return true;
      })
      .catch(() => {
        return true;
      });
  };

  makeDefault = async (customer, bank_account_id) => {
    this.setState({ fetching: true });
    const payload = {
      customer,
      is_default_customer_bank_account: true,
    };
    const response = await setDefaultBankData(payload, bank_account_id);

    if (response && response.id != null) {
      const value = await this.unsetDefaultBankAccount(customer);
      if (value) {
        this.getUserBankAccounts();
      }
      this.setState({ activeIndex: null });
    } else {
      this.setState({ fetching: false });
    }
  };

  renderBankRow = ({ item }) => {
    return (
      <View style={styles.account_box}>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.img_box}>
            <Image
              resizeMode="contain"
              source={{ uri: item.bank_logo }}
              style={styles.img}
            />
          </View>
          <View style={{ paddingLeft: 15 }}>
            <View style={{ paddingBottom: 10 }}>
              <Text style={styles.bank_name}>{item.bank_name}</Text>
            </View>
            <View style={styles.bank_details}>
              <Text style={[styles.text_style, { paddingRight: 16 }]}>
                IFSC Code:{" "}
                <Text style={{ fontStyle: "italic" }}>{item.ifsc_code}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View>
          <View style={{ paddingBottom: 10 }}>
            <Text style={styles.text_style}>Account No</Text>
          </View>
          <View style={styles.bank_name}>
            <Text
              style={[styles.bank_name, { fontWeight: "600", marginTop: -4 }]}
            >
              {item.account_number}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  render() {
    const { container } = styles;
    const { bankAccounts = [] } = this.state;
    const {
      ifsc,
      ifscError,
      accountNumber,
      accountNumberError,
      bankName,
      bankNameError,
      bankDetails,
    } = this.state;

    return !this.state.addAccountClicked ? (
      <SafeAreaView style={container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Bank Account"]}
        />
        {this.state.showAddAccountToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title="Bank account added successfully."
            duration="3"
          />
        ) : null}
        <ScrollView
          style={{ flex: 1, marginBottom: isMobile ? 70 : 0 }}
          contentContainerStyle={{ flex: 1 }}
          showsVerticalScrollIndicator={true}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <Text
              style={{
                textAlign: isMobile ? "center" : "left",
                marginTop: 10,
                marginLeft: isMobile ? 0 : 20,
                width: "100%",
                fontWeight: "400",
                fontSize: 14,
                color: "#464E5F",
              }}
            >
              Link your bank account to make purchases
            </Text>
            {!isMobile && (
              <Button
                uppercase={false}
                mode={"contained"}
                onPress={() => {
                  this.setState({ addAccountClicked: true });
                }}
                style={{
                  alignItems: "center",
                  width: "18%",
                  marginRight: 30,
                }}
                color={"#410DAA"}
              >
                <Text style={styles.next}>Add Bank Account</Text>
              </Button>
            )}
          </View>
          {isMobile && (
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={() => {
                this.setState({ addAccountClicked: true });
              }}
              style={{
                alignItems: "center",
                width: "60%",
                marginTop: 20,
                marginLeft: "20%",
              }}
              color={"#410DAA"}
            >
              <Text style={styles.next}>Add Bank Account</Text>
            </Button>
          )}
          <FlatList
            data={[...this.state.bankAccounts]}
            ListEmptyComponent={() => {
              if (this.state.fetching) return null;
              return (
                <Text
                  style={{
                    marginTop: "20%",
                    marginLeft: "20%",
                    marginRight: "20%",
                    width: "60%",
                    textAlign: "center",
                  }}
                >
                  No Bank Accounts
                </Text>
              );
            }}
            style={{ paddingHorizontal: 16, marginTop: 20, paddingBottom: 30 }}
            renderItem={this.renderBankRow}
            keyExtractor={(item) => item.id}
          />
        </ScrollView>
        {this.state.fetching && (
          <ActivityIndicator
            size="large"
            color="#410DAA"
            style={styles.loader}
          />
        )}
      </SafeAreaView>
    ) : (
      <SafeAreaView style={container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Add Bank Account"]}
          onPressBack={() => {
            this.setState({ addAccountClicked: false });
          }}
        />
        <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={true}>
          <View style={{ flex: 1, marginHorizontal: 16 }}>
            {!isMobile ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View style={[{ flex: 1 }, styles.itemMargin]}>
                  <TextInputField
                    label="Your Bank’s IFSC Code*"
                    value={ifsc}
                    onChange={(ifsc) => this.handleChange(ifsc, "ifsc", "text")}
                    style={[{ ...styles.inputItem }]}
                    editable={true}
                    isValid={ifscError}
                    type="text"
                    validate={true}
                    inputStyle={[{ paddingLeft: 20 }]}
                  />
                  {this.state.IFSCError && (
                    <Text style={[styles.please, { color: "red" }]}>
                      Please enter valid bank's IFSC code
                    </Text>
                  )}
                </View>
                <View
                  style={[{ flex: 1, marginLeft: "5%" }, styles.itemMargin]}
                >
                  <TextInputField
                    label="Bank Name*"
                    value={bankName}
                    style={[{ ...styles.inputItem }]}
                    editable={false}
                    isValid={bankNameError}
                    type="text"
                    validate={true}
                    inputStyle={[{ paddingLeft: 20 }]}
                  />
                </View>
              </View>
            ) : (
              <>
                <View style={[styles.itemMargin]}>
                  <TextInputField
                    label="Your Bank’s IFSC Code*"
                    value={ifsc}
                    onChange={(ifsc) => this.handleChange(ifsc, "ifsc", "text")}
                    style={[{ ...styles.inputItem }]}
                    editable={true}
                    isValid={ifscError}
                    type="text"
                    validate={true}
                    inputStyle={[{ paddingLeft: 20 }]}
                  />
                </View>
                {this.state.IFSCError && (
                  <Text style={[styles.please, { color: "red" }]}>
                    Please enter valid bank's IFSC code
                  </Text>
                )}
                <View style={[styles.itemMargin]}>
                  <TextInputField
                    label="Bank Name*"
                    value={bankName}
                    style={[{ ...styles.inputItem }]}
                    editable={false}
                    isValid={bankNameError}
                    type="text"
                    validate={true}
                    inputStyle={[{ paddingLeft: 20 }]}
                  />
                </View>
              </>
            )}
            {!isMobile ? (
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Your Bank Account Number*"
                  value={accountNumber}
                  onChange={(accountNumber) =>
                    this.handleChange(
                      accountNumber,
                      "accountNumber",
                      "bankaccount"
                    )
                  }
                  style={[{ ...styles.inputItem, width: "88%" }]}
                  editable={true}
                  type="bankaccount"
                  inputStyle={[{ paddingLeft: 24 }]}
                  isValid={accountNumberError}
                  validate={true}
                />
                {this.state.AccNoError && (
                  <Text style={[styles.please, { color: "red" }]}>
                    Please enter valid bank account number
                  </Text>
                )}
              </View>
            ) : (
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Your Bank Account Number*"
                  value={accountNumber}
                  onChange={(accountNumber) =>
                    this.handleChange(
                      accountNumber,
                      "accountNumber",
                      "bankaccount"
                    )
                  }
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="bankaccount"
                  inputStyle={[{ paddingLeft: 24 }]}
                  isValid={accountNumberError}
                  validate={true}
                />
              </View>
            )}
            {this.state.AccNoError && (
              <Text style={[styles.please, { color: "red" }]}>
                Please enter valid bank account number
              </Text>
            )}
            {this.state.gettingIfsc && (
              <TouchableOpacity
                style={{
                  marginBottom: 32,
                  alignSelf: "flex-end",
                }}
                onPress={this.verifyIfsc}
              >
                <ActivityIndicator color={theme.colors.NEW_BRAND_COLOR} />
              </TouchableOpacity>
            )}
            {bankDetails && (
              <View
                style={{
                  flexWrap: "wrap",
                  flexDirection: "row",
                  marginBottom: 25,
                  marginTop: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: 11,
                    color: "#707070",
                    fontStyle: "italic",
                  }}
                >
                  {bankDetails.bank_name},
                </Text>
                <Text
                  style={{
                    fontSize: 11,
                    color: "#707070",
                    fontStyle: "italic",
                  }}
                >
                  {bankDetails.branch_name},
                </Text>

                <Text
                  style={{
                    fontSize: 11,
                    color: "#707070",
                    fontStyle: "italic",
                  }}
                >
                  {bankDetails.bank_address},
                </Text>
              </View>
            )}
            <SelectChequeLeaf
              onSelect={(filesContent) => {
                if (filesContent && filesContent.length > 0) {
                  const {
                    content: imageuri,
                    name: imagename,
                    type: imageType,
                  } = filesContent[0];
                  this.setState({
                    imageuri,
                    imagename,
                    imageType,
                    imageSize: DataURIToBlob(imageuri).size,
                  });
                }
              }}
              selectedImageName={this.state.imagename}
            />
          </View>
          {!isMobile ? (
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={() => this.addBankAccountFn()}
              style={{
                width: isMobile ? "100%" : "30%",
                left: "38%",
                top: 50,
                right: "40%",
              }}
              color={"#410DAA"}
            >
              <Text
                style={{
                  color: "#fff",
                  fontSize: 13,
                }}
              >
                Add Bank Account
              </Text>
            </Button>
          ) : (
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={() => {
                this.addBankAccountFn();
              }}
              style={{
                width: "100%",
                // marginLeft: "20%",
                marginTop: 30,
              }}
              color={"#410DAA"}
            >
              <Text
                style={{
                  color: "#fff",
                  fontSize: 13,
                }}
              >
                Add Bank Account
              </Text>
            </Button>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.MAIN_BACKGROUND,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.3,
  },
  fileName: {
    fontSize: 15,
    marginTop: 5,
    color: "#410DAA",
    fontWeight: "bold",
  },
  row: {
    flex: 1,
    flexDirection: "row",
  },
  baseMargin: {
    marginTop: 16,
  },
  titleHeading: {
    color: "#707070",
  },
  support: {
    fontSize: 11,
    color: "#121212",
    fontStyle: "italic",
    marginTop: 4,
    fontWeight: "500",
  },
  please: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3,
  },
  next: {
    color: "#fff",
    fontSize: 13,
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  loader: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  account_box: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.6)",
    borderRadius: 6,
    paddingVertical: 15,
    paddingHorizontal: 10,
    marginVertical: 10,
    paddingHorizontal: isMobile ? 10 : 30,
  },
  img_box: {
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.2)",
    borderRadius: 5,
    alignContent: "center",
    justifyContent: "center",
  },
  img: {
    width: 40,
    height: 40,
  },
  bank_name: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 12 : 13,
    lineHeight: 18,
    color: "#22242C",
    fontWeight: "600",
  },
  bank_details: {
    flexDirection: "row",
    marginTop: -3,
  },
  text_style: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 12 : 13,
    lineHeight: 20,
    color: "#494B69",
  },
});

export default connect(null)(UserBankAccounts);
