import React from "react";
import { TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";

export const SectionHeading = ({
  title,
  rightButtontTitle,
  showRightButton,
  rightButtonOnPress,
  rightButtonStyle,
  rightButtonTitleStyle,
  style
}) => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        backgroundColor: "#F2F2F2",
        paddingTop: 15,
        alignItems: "center",
        ...style
      }}
    >
      <Text
        style={{
          flex: 0.8,
          color: "#121212",
          fontSize: 16,
          paddingVertical: 10
        }}
      >
        {title}
      </Text>
      <View style={{ flex: 0.2 }}>
        {showRightButton && rightButtontTitle && (
          <TouchableOpacity
            onPress={() => (rightButtonOnPress ? rightButtonOnPress() : null)}
            style={{
              backgroundColor: "#EF5D12",
              borderRadius: 3,
              alignItems: "center",
              alignSelf: "flex-end",
              ...rightButtonStyle
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: 10,
                paddingVertical: 2,
                paddingHorizontal: 6,
                ...rightButtonTitleStyle
              }}
            >
              {rightButtontTitle}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};
