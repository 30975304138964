import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  Linking,
} from "react-native";
import amplitude from "../../../utils/amplitude";
import Modal from "../../Common/Modal";
import moment from "moment";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { md } from "../../../Responsive";
import NavigationHeader from "../../Common/NavigationHeader";
import { getInvestorAccountFundDetails, getMFDetails, getSTPOrder, getSWPOrder, getSwitchOrder, getUrlForClient2FA, redeemAmountForFund } from "../../../api";
import { isMobile } from "../../../utils";
const { width } = Dimensions.get("window");
const isWeb = md(width);
import TextInputField from "../../Common/Inputs/InputField";
import { FontAwesome5 } from '@expo/vector-icons';
import { HelperText } from "react-native-paper";
import Checkbox from "expo-checkbox";
import { ShowToast } from "../../Common/Toast";
import Store from "../../../store";
import PickerFieldSelect from "../../Common/Pickers/PickerFieldSelect";
import { Entypo } from '@expo/vector-icons';
import { validateInput } from "../../../utils/validateInput";

const FundField = ({ style, name, value, tooltip }) => {
  return (
    <View style={{ ...style }}>
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: "#6C7293",
            fontWeight: "600",
          }}
        >
          {name}
        </Text>
        {tooltip && (
          <ANTIcon
            name="infocirlce"
            color={"#a8a8a8"}
            size={12}
            style={{
              paddingLeft: 10,
            }}
          />
        )}
      </View>
      <Text
        style={{
          fontSize: 12,
          color: "#464E5F",
          fontWeight: "700",
          paddingTop: 5,
        }}
      >
        {value}
      </Text>
    </View>
  );
};

const Divider = ({ color, width, style }) => {
  return (
    <View
      style={[
        {
          borderBottomColor: color ? color : "black",
          borderBottomWidth: width ? width : 1,
        },
        style,
      ]}
    />
  );
};

export default class Redeem extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let userId = null;
    let fundPK = null;
    let type = "redeem";
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      fundPK = params.fund;
      type = params.type;
    }

    

    this.state = {
      userId,
      fundPK,
      type,
      textToshowInModel: "",
      isOrderSuccess: false,
      isLoading: false,
      activeTabIndex: 0,
      isLoading: true,
      unit_amount_value: 0,
      selectedType: "MONTHLY",
      switch_to_scheme_id: "",
      stp_to_scheme_id: "",
      days: 1,
      isRedeemLoading: false,
      filters: [],
      isJamaMF: true,
      showModel: false,
      mfTxnDetails: [],
      mfSelectedFund: [],
      amount_error: "",
      isSwitchOn: false,
      enteredAmount: "",
      numOfWidthrawl: "",
      amountAvaliable: "",
      amount_error: "",
      minWithdrawals: "",
      redeemFullCheckBox: false,
      showToast: "false",
      selectedAction: "Start_SIP",
      messageToShow: "",
      selected_switch_fileter: "",
      amcfs_switch_filter: [],
      amcfs_stp_filter: [],
      selected_switch_by: "Amount",
      isSuccess: false,
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("redeem",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("redeem", { path: this.props.navigation.getState().routeName });
    }
    try {
      let newArr = [];
      let newArrTxn = [];
      let userId =
        Store.getState().dashboard &&
          Store.getState().dashboard.viewModeUserId &&
          Store.getState().dashboard.viewModeUserId.user_id
          ? Store.getState().dashboard.viewModeUserId.user_id
          : null;
      const { fundPK } = this.state;
      let response = null;
      let payload = {
        iaf_id: fundPK
      }
      response = await getInvestorAccountFundDetails(payload);

      // console.log("response is ", response);
      // const response = await getMFDetails();
      if (
        response.code == "100" &&
        response.result
      ) {
        let switch_data = [];
        response.result.amcfs_switch_filter.map((value) => {
          switch_data.push({
            label: value.name,
            value: value.amcfundscheme_id
          });
        })

        let stpData = [];
        response.result.amcfs_stp_filter.map((value) => {
          stpData.push({
            label: value.name,
            value: value.amcfundscheme_id
          });
        })


        let selectedFund = [response.result]
        this.setState({
          mfSelectedFund: selectedFund,
          amcfs_stp_filter: stpData,
          fundPK: selectedFund[0].amcfundscheme__AMCFund__pk,
          amountAvaliable: selectedFund[0].latest_value_at_nav,
          amcfs_switch_filter: switch_data,
          isJamaMF: true,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText:
            response && response.msg ? response.msg : "Something went wrong!",
        });
      }
    } catch (error) {
      console.log("Error is ", error)
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  setModalMethod = (method) => {
    this.openOrCloseModal = method;
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  transactionStatus = (transactionType) => {
    let type = "";
    switch (transactionType) {
      case "P":
        type = "Purchase";
        break;

      case "R":
        type = "Redemption";
        break;

      case "SI":
        type = "Switch In";
        break;

      case "SO":
        type = "Switch Out";
        break;

      default:
        break;
    }
    return type;
  };

  months_full = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  months_short_names = (index, full = false) => {
    if (!full) {
      return index <= 12 ? this.months[index - 1] : null;
    } else return index <= 12 ? this.months_full[index - 1] : null;
  };

  dateFormat = (date, delimeter = "-") => {
    let values = date.split(`${delimeter}`);
    return (
      values[2] +
      "-" +
      this.months_short_names(parseInt(values[1])) +
      "-" +
      values[0]
    );
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  data = [
    {
      label: "MONTHLY",
      value: "MONTHLY",
    },
    {
      label: "QUERTERLY",
      value: "QUERTERLY",
    }
  ]

  daysType = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    // { label: '29', value: '29' },
    // { label: '30', value: '30' }
  ]

  getStartDate = () => {
    let date = Number(this.state.days);

    if (date > parseInt(moment().format("DD"))) {
      date <= 9 ? (date = "0" + date.toString()) : null;
      return moment().format("YYYY") + "-" + moment().format("MM") + "-" + date;
    } else {
      date <= 9 ? (date = "0" + date.toString()) : null;
      if (moment().format("MM") == 12) {
        return 1 + parseInt(moment().format("YYYY")) + "-" + "01" + "-" + date;
      } else {
        return parseInt(moment().format("YYYY")) + "-" + "01" + "-" + date;
      }
    }
  };

  handleStartSWP = async () => {
    this.setState({isLoading: true});
    const payload = {
      iaf_id: this.state.mfSelectedFund[0].investoraccountfund_id,
      amount: this.state.enteredAmount,
      frequency_type: this.state.selectedType,
      start_date: this.getStartDate(),
      number_of_installments: this.state.minWithdrawals, // minimum 1
      bse_folio_no: this.state.mfSelectedFund[0].folio_number,
      generate_first_order_today: this.state.redeemFullCheckBox ? "Y" : "N"
    }
    try {
      const response = await getSWPOrder(payload);
      // console.log("SWP RESP", response);
      if (response) {
        let flag = 0;
        if(response.code != '100'){
          flag = 1;
        }
        this.setState({ showModel: true, textToshowInModel: response.result, isOrderSuccess: flag == 0 ? true : false });
      }
    } catch (err) {
      console.log(err);
      this.setState({ err: err })
      this.setState({ showModel: true, textToshowInModel: "Something went wrong", isOrderSuccess: false });
    }
    this.setState({isLoading: false});
  }

  handleStartSTP = async () => {
    this.setState({isLoading: true});
    const payload = {
      from_scheme_id: this.state.mfSelectedFund[0].amcfundscheme__pk,
      to_scheme_id: this.state.stp_to_scheme_id,
      amount: this.state.enteredAmount,
      number_of_installments: this.state.minWithdrawals,  // minimum 1
      frequency_type: this.state.selectedType, // MONTHLY,WEEKLY, QUERTERLY
      bse_stp_start_date: this.getStartDate(),
      generate_first_order_today: this.state.redeemFullCheckBox ? "Y" : "N", // first order should in "Y" or "N"
      folio_number: this.state.mfSelectedFund[0].folio_number
    }
    try {
      const response = await getSTPOrder(payload);
      // console.log("STP RESP", response);
      if (response) {
        let flag = 0;
        if(response.code != '100'){
          flag = 1;
        }
        this.setState({ showModel: true, textToshowInModel: response.result, isOrderSuccess: flag == 0 ? true : false });
      }
    } catch (err) {
      this.setState({ showModel: true, textToshowInModel: "Something went wrong", isOrderSuccess: false });
    }
    this.setState({isLoading: false});
  }


  handleStartSwitch = async () => {
    this.setState({isLoading: true});
    let payload;
    if (this.state.selected_switch_by == "All") {
      payload = {
        from_scheme_id: this.state.mfSelectedFund[0].amcfundscheme__pk,
        to_scheme_id: this.state.switch_to_scheme_id,
        folio_number: this.state.mfSelectedFund[0].folio_number,
        all_units_switch_flag: "Y", // Y / N ; if its N then we go down
      }
    } else {
      if (this.state.selected_switch_by == "Amount") {
        payload = {
          from_scheme_id: this.state.mfSelectedFund[0].amcfundscheme__pk,
          to_scheme_id: this.state.switch_to_scheme_id,
          folio_number: this.state.mfSelectedFund[0].folio_number,
          all_units_switch_flag: "N", // Y / N ; if its N then we go down
          amount_to_switch: this.state.unit_amount_value// # only if all_units_switch_flag be N
        }
      } else {
        payload = {
          from_scheme_id: this.state.mfSelectedFund[0].amcfundscheme__pk,
          to_scheme_id: this.state.switch_to_scheme_id,
          folio_number: this.state.mfSelectedFund[0].folio_number,
          all_units_switch_flag: "N", // Y / N ; if its N then we go down
          no_of_units_to_switch: this.state.unit_amount_value
        }
      }
    }
    try {
      const response = await getSwitchOrder(payload);
      // console.log("STP RESP", response);
      if (response) {
        let flag = 0;
        if(response.code != '100'){
          flag = 1;
        }
        this.setState({ showModel: true, textToshowInModel: response.result.description, isOrderSuccess: flag == 0 ? true : false });
      }
    } catch (err) {
      this.setState({ showModel: true, textToshowInModel: "Something went wrong", isOrderSuccess: false });
    }
    this.setState({isLoading: false})
  }

  openBSE2FAScreen = async () => {
    const response = await getUrlForClient2FA();
    if (response && response.code == 100) {
      const url = response.result;
      if(Platform.OS === "web"){
        global.open(url,"_blank");
      }else{
        Linking.openURL(url);
      }
    }
  }

  handleContinue = () => {
    if(this.state.isOrderSuccess){
      this.openBSE2FAScreen();
    }
    this.props.navigation.navigate("App",{
      screen: "Portfolio",
      params: {
        screen: "Redeem"
      }
    })
    this.setState({ showModel: false })
  }




  render() {
    const { error, errorText, isLoading, selectedType, days, minWithdrawals, selectedTypeError, switch_to_scheme_id, stp_to_scheme_id, unit_amount_value, type } = this.state;
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View style={{ flex: 1, maxWidth: 1500 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={[
              "Mutual Funds Overview",
              "Mutual Funds",
              type == "redeem" ? "Redeem" : type == "switch" ? "Switch" : type == "swp" ? "SWP" : type == "stp" ? "STP" : "Redeem"
            ]}
            onPressBack={() => {
              this.props.navigation.goBack();
            }}
          />
          {this.state.showToast == "true" ? (
            <ShowToast
              showToast={true}
              type="status"
              title="Amount redeemed."
              duration="3"
            />
          ) : null}
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <ActivityIndicator size="large" color="#410DAA" />
            ) : error ? (
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText || "Something went wrong!"}
              </Text>
            ) : (
              <ScrollView style={[styles.mainContainer]}>
                <View
                  style={{ paddingBottom: 40, marginTop: isMobile ? -3 : -17 }}
                >
                  {this.state.mfSelectedFund.map((item, index) => {
                    return (
                      <View
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 17,
                          width: isMobile ? "90%" : "40%",
                          marginLeft: isMobile ? "5%" : "30%",
                          marginRight: isMobile ? "5%" : "30%",
                        }}
                      >
                        <View
                          style={{
                            flex: 10,
                            borderRadius: 10,
                            borderWidth: 1,
                            borderColor: "#410DAA1A",
                          }}
                        >
                          <View
                            style={{
                              padding: 10,
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <View style={{ flex: 0.05 }}>
                              <Image
                                source={{ uri: item.amc_logo }}
                                resizeMode="contain"
                                style={{
                                  height: 30,
                                  width: 30,
                                  alignSelf: "flex-start",
                                  borderWidth: 1,
                                  borderColor: "#f2f2f2",
                                  borderRadius: 5,
                                }}
                              />
                            </View>
                            <View
                              style={{ flex: isMobile ? 0.05 : 0.01 }}
                            ></View>
                            <View style={{ flex: 0.84, paddingLeft: 35 }}>
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.amcfundscheme__name}
                              </Text>
                            </View>
                            <View style={{ flex: 0.1 }}></View>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              paddingHorizontal: 10,
                              paddingBottom: 10,
                              paddingTop: 6,
                            }}
                          >
                            <FundField
                              style={{ flex: 0.33, alignItems: "flex-start" }}
                              name="Category"
                              value={
                                item.amcfundscheme__AMCFund__fund_class
                                  ? item.amcfundscheme__AMCFund__fund_class.split(
                                    "-"
                                  )[0]
                                  : "-"
                              }
                            />
                            <FundField
                              style={{ flex: 0.33, alignItems: "center" }}
                              name="Units Held"
                              value={
                                item.type == "Jama"
                                  ? item.unit_balance.toFixed(3)
                                  : item.imported_units.toFixed(3)
                              }
                            />
                            <FundField
                              style={{ flex: 0.34, alignItems: "flex-end" }}
                              name="Amount Available"
                              value={`₹ ${item.latest_value_at_nav}`}
                            />
                          </View>
                          <Divider color="#410DAA1A" width={0.5} />
                          <View
                            style={{
                              paddingHorizontal: 10,
                              paddingVertical: 4,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                paddingVertical: 9,
                              }}
                            >
                              <FundField
                                style={{
                                  flex: 0.33,
                                  alignItems: "flex-start",
                                }}
                                name="Invested"
                                value={"₹" + item.amount_at_cost.toFixed(0)}
                              />
                              <FundField
                                style={{ flex: 0.33, alignItems: "center" }}
                                name="Gain"
                                value={"₹" + item.gain.toFixed(0)}
                              />
                              <FundField
                                style={{ flex: 0.34, alignItems: "flex-end" }}
                                name="Min. Redemption"
                                value={`₹ ${Number(item.amcfundscheme__bse_redemption_amount_minimum).toFixed(2)}`}
                              />
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                paddingVertical: 9,
                              }}
                            >
                              <FundField
                                style={{
                                  flex: 0.33,
                                  alignItems: "flex-start",
                                }}
                                name="Abs. Gain%"
                                value={item.absolute_gain + "%"}
                              />
                              <FundField
                                style={{ flex: 0.33, alignItems: "center" }}
                                name="Folio No."
                                value={
                                  item.type == "Jama"
                                    ? item.folio_number
                                    : item.imported_folio_number
                                }
                              />
                              {item.redeem_under_process_amount > 1 ? (
                                <FundField
                                  style={{ flex: 0.34, alignItems: "flex-end" }}
                                  name="Under Process"
                                  value={`₹ ${item.redeem_under_process_amount}`}
                                />
                              ) : (
                                <FundField
                                  style={{ flex: 0.34, alignItems: "flex-end" }}
                                  name="Avg. Duration"
                                  value={item.avg_duration}
                                />
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    );
                  })}
                  {/* {console.log("Value type is ", this.state.type)} */}
                  {this.state.type == "redeem" && (<View>
                    <View
                      style={{
                        marginTop: isMobile ? 20 : 40,
                        paddingHorizontal: isMobile ? 16 : 0,
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TextInputField
                        label="Amount*"
                        value={
                          this.state.redeemFullCheckBox
                            ? this.state.amountAvaliable.toString()
                            : this.state.enteredAmount
                        }
                        onChange={(text) => {
                          if (!isNaN(text)) {
                            this.setState({
                              enteredAmount: text,
                              amount_error: "",
                            });
                          }
                        }}
                        inputStyle={[{ paddingLeft: isMobile ? 20 : 10 }]}
                        editable={this.state.redeemFullCheckBox ? false : true}
                      />
                      <HelperText
                        type="error"
                        visible={this.state.amount_error != ""}
                      >
                        {this.state.amount_error}
                      </HelperText>
                    </View>
                    <View
                      style={{
                        marginTop: isMobile ? 0 : 20,
                        paddingHorizontal: isMobile ? 16 : 0,
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          flexDirection: "row",
                        }}
                        onPress={() =>
                          this.setState({
                            redeemFullCheckBox: !this.state.redeemFullCheckBox,
                          })
                        }
                      >
                        <Checkbox
                          value={true || this.state.redeemFullCheckBox}
                          onValueChange={() => {
                            this.setState({
                              redeemFullCheckBox: !this.state.redeemFullCheckBox,
                            });
                          }}
                          color={
                            this.state.redeemFullCheckBox ? "#410DAA" : "#cdcdcd"
                          }
                          status={
                            this.state.redeemFullCheckBox
                              ? "checked"
                              : "unchecked"
                          }
                        />
                        <Text
                          style={{
                            flex: 1,
                            color: "black",
                            marginLeft: 5,
                            marginTop: isMobile ? 7 : 9,
                          }}
                        >
                          Redeem full amount?
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        zIndex: 9999,
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                        }}
                      >
                        {!this.state.isSuccess &&
                          (this.state.isRedeemLoading ? (
                            <ActivityIndicator size="large" color="#410DAA" />
                          ) : (
                            <TouchableOpacity
                              onPress={async () => {
                                if (
                                  this.state.enteredAmount == "" &&
                                  !this.state.redeemFullCheckBox
                                ) {
                                  this.setState({
                                    amount_error: "Please enter an amount",
                                  });
                                  return;
                                }
                                if (
                                  this.state.enteredAmount >
                                  this.state.amountAvaliable
                                ) {
                                  this.setState({
                                    amount_error:
                                      "Entered amount should be less than amount available",
                                  });
                                  return;
                                }

                                let payload = {
                                  amount: this.state.redeemFullCheckBox
                                    ? this.state.amountAvaliable.toString()
                                    : this.state.enteredAmount,
                                  redeem_full_amount_flag: this.state
                                    .redeemFullCheckBox
                                    ? "Y"
                                    : "N",
                                  investoraccountfund_id:
                                    this.state.mfSelectedFund[0]
                                      .investoraccountfund_id,
                                };

                                this.setState({ isRedeemLoading: true });
                                let response = await redeemAmountForFund(payload);
                                if (
                                  response &&
                                  response.code &&
                                  response.code == 100
                                ) {
                                  this.setState({ showModel: true, textToshowInModel: response.result, isOrderSuccess: true, isRedeemLoading: false });
                                } else {
                                  this.setState({ showModel: true, textToshowInModel: response.result, isOrderSuccess: false, isRedeemLoading: false });
                                }
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "bold",
                                  fontSize: isWeb ? 15 : 13,
                                  color: "#FFFFFF",
                                  backgroundColor: "#410DAA",
                                  borderColor: "#410DAA",
                                  borderWidth: 1,
                                  borderRadius: 4,
                                  paddingVertical: 9,
                                  textAlign: "center",
                                  marginTop: 25,
                                  width: isMobile ? "30%" : "14%",
                                  marginLeft: isMobile ? "35%" : "43%",
                                  marginRight: isMobile ? "35%" : "43%",
                                }}
                              >
                                Redeem
                              </Text>
                            </TouchableOpacity>
                          ))}
                      </View>
                    </View>
                    {this.state.messageToShow != "" && (
                      <Text
                        style={{
                          flex: 1,
                          color: this.state.isSuccess ? "green" : "red",
                          marginTop: 20,
                          textAlign: "center",
                        }}
                      >
                        {this.state.messageToShow}{" "}
                        {this.state.isSuccess && (
                          <Text
                            style={{
                              textDecorationLine: "underline",
                              color: "#410DAA",
                              fontWeight: "bold",
                            }}
                            onPress={() => {
                              this.props.navigation.goBack();
                            }}
                          >
                            Go Back
                          </Text>
                        )}
                      </Text>
                    )}
                  </View>)}
                  {this.state.type == "swp" && (<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <View style={{ width: isMobile ? '80%' : '40%', marginTop: 50 }}>
                      <TextInputField
                        label="Amount*"
                        value={this.state.enteredAmount}
                        onChange={(text) => {
                          if (!isNaN(text)) {
                            this.setState({
                              enteredAmount: text,
                              amount_error: "",
                            });
                          }
                        }}
                        style={[{ width: '100%' }]}
                        inputStyle={[{ paddingLeft: isMobile ? 20 : 10 }]}
                        editable={true}
                      />
                      <Text style={{ textAlign: 'center', color: "#666666", fontWeight: '300', marginTop: 5 }}>Min Withdrawal = ₹ 0.001</Text>
                      <View style={{ marginTop: 20 }}>
                        <PickerFieldSelect
                          data={this.data}
                          width={'100%'}
                          value={selectedType}
                          label={"Choose frequency*"}
                          onChange={(selectedType) =>
                            this.setState({ selectedType: selectedType })
                          }
                          showSelect={false}
                          pickerStyle={[{ width: '100%' }]}
                          isValid={selectedTypeError}
                          type="select"
                        />
                      </View>
                      <View style={{ marginTop: 20 }}>
                        <PickerFieldSelect
                          data={this.daysType}
                          width={'100%'}
                          value={days}
                          label={"Select day of the month to start SWP*"}
                          onChange={(selectedType) => {
                            // Get current date
                            const today = new Date();
                            const currentDay = today.getDate();
                            const selectedDay = parseInt(selectedType);
                            
                            // If selected day has passed in current month, set for next month
                            if (selectedDay <= currentDay) {
                              // Create date for next month
                              const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, selectedDay);
                              this.setState({ 
                                days: selectedType,
                                effectiveDate: nextMonth // Store the effective date if needed
                              });
                            } else {
                              // Use current month's date
                              const thisMonth = new Date(today.getFullYear(), today.getMonth(), selectedDay);
                              this.setState({ 
                                days: selectedType,
                                effectiveDate: thisMonth // Store the effective date if needed
                              });
                            }
                          }}
                          showSelect={false}
                          pickerStyle={[{ width: '100%' }]}
                          isValid={selectedTypeError}
                          type="select"
                        />
                      </View>
                      <TextInputField
                        label="Number of Withdrawals*"
                        value={this.state.minWithdrawals}
                        onChange={(text) => {
                          if (!isNaN(text)) {
                            this.setState({
                              minWithdrawals: text,
                              amount_error: "",
                            });
                          }
                        }}
                        style={[{ width: '100%', marginTop: 20 }]}
                        inputStyle={[{ paddingLeft: isMobile ? 20 : 10 }]}
                        editable={true}
                      />
                      <TouchableOpacity
                        style={{
                          flexDirection: "row",
                        }}
                        onPress={() =>
                          this.setState({
                            redeemFullCheckBox: !this.state.redeemFullCheckBox,
                          })
                        }
                      >
                        <Checkbox
                          value={true || this.state.redeemFullCheckBox}
                          onValueChange={() => {
                            this.setState({
                              redeemFullCheckBox: !this.state.redeemFullCheckBox,
                            });
                          }}
                          color={
                            this.state.redeemFullCheckBox ? "#410DAA" : "#cdcdcd"
                          }
                          status={
                            this.state.redeemFullCheckBox
                              ? "checked"
                              : "unchecked"
                          }
                        />
                        <Text
                          style={{
                            flex: 1,
                            color: "#666666",
                            fontWeight: '500',
                            marginLeft: 5,
                            marginTop: isMobile ? 7 : 9,
                          }}
                        >
                          Place first installment of SWP today
                        </Text>
                      </TouchableOpacity>
                      <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
                        { !this.state.isLoading ? (<TouchableOpacity style={{ backgroundColor: '#410DAA', borderRadius: 5 }} onPress={this.handleStartSWP}>
                          <Text style={{ paddingHorizontal: 30, paddingVertical: 10, color: 'white', fontWeight: '500' }}>Start SWP</Text>
                        </TouchableOpacity>) : (<ActivityIndicator size={'large'} color={"#410DAA"} />)}
                      </View>
                    </View>
                  </View>)}
                  {this.state.type == "switch" && (<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <View style={{ width: isMobile ? '80%' : '40%', marginTop: 50 }}>
                      <View style={{ marginTop: 20 }}>
                        <PickerFieldSelect
                          data={this.state.amcfs_switch_filter}
                          width={'100%'}
                          value={switch_to_scheme_id}
                          label={"To Scheme*"}
                          onChange={(selectedType) =>
                            this.setState({ switch_to_scheme_id: selectedType })
                          }
                          showSelect={true}
                          pickerStyle={[{ width: '100%' }]}
                          isValid={selectedTypeError}
                          type="select"
                        />
                      </View>
                      <View style={{ marginTop: 20 }}>
                        <TextInputField
                          label="Select folio number"
                          value={this.state.mfSelectedFund[0].folio_number}
                          onChange={(text) => {
                            if (!isNaN(text)) {
                              this.setState({
                                enteredAmount: text,
                                amount_error: "",
                              });
                            }
                          }}
                          style={[{ width: '100%' }]}
                          inputStyle={[{ paddingLeft: isMobile ? 20 : 10 }]}
                          editable={false}
                        />
                      </View>
                      <View style={{ marginTop: 20 }}>
                        <Text style={{ fontSize: 13, color: '#666666', fontWeight: 'bold' }}>Switch By*</Text>
                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>


                          <TouchableOpacity style={{ marginRight: 50, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                            this.setState({
                              selected_switch_by: "Amount",
                            });
                          }}>
                            {this.state.selected_switch_by == "Amount" ? (
                              <FontAwesome5 name="dot-circle" size={24} color="#410DAA" />
                            ) : (<Entypo name="circle" size={24} color="#666666" />)}
                            <Text style={{ marginLeft: 5, color: "#666666" }}>Amount</Text>
                          </TouchableOpacity>

                          <TouchableOpacity style={{ marginRight: 50, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                            this.setState({
                              selected_switch_by: "Unit",
                            });
                          }}>
                            {this.state.selected_switch_by == "Unit" ? (
                              <FontAwesome5 name="dot-circle" size={24} color="#410DAA" />
                            ) : (<Entypo name="circle" size={24} color="#666666" />)}
                            <Text style={{ marginLeft: 5, color: "#666666" }}>Unit</Text>
                          </TouchableOpacity>

                          <TouchableOpacity style={{ marginRight: 50, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                            this.setState({
                              selected_switch_by: "All",
                            });
                          }}>
                            {this.state.selected_switch_by == "All" ? (
                              <FontAwesome5 name="dot-circle" size={24} color="#410DAA" />
                            ) : (<Entypo name="circle" size={24} color="#666666" />)}
                            <Text style={{ marginLeft: 5, color: "#666666" }}>All</Text>
                          </TouchableOpacity>
                        </View>
                        {this.state.selected_switch_by != "All" && <TextInputField
                          label="Value"
                          value={this.state.unit_amount_value}
                          onChange={(text) => {
                            if (!isNaN(text)) {
                              if (this.state.selected_switch_by == "Amount") {
                                if (text > this.state.mfSelectedFund[0].latest_value_at_nav) {
                                  this.setState({
                                    unit_amount_value: this.state.mfSelectedFund[0].latest_value_at_nav,
                                    amount_error: `Please enter amount less than ₹ ${this.state.mfSelectedFund[0].latest_value_at_nav}`,
                                  });
                                } else {
                                  this.setState({
                                    unit_amount_value: text,
                                    amount_error: "",
                                  });
                                }
                              } else {
                                if (text > this.state.mfSelectedFund[0].unit_balance) {
                                  this.setState({
                                    unit_amount_value: this.state.mfSelectedFund[0].unit_balance,
                                    amount_error: `Please enter Unit less than ₹ ${this.state.mfSelectedFund[0].unit_balance}`,
                                  });
                                } else {
                                  this.setState({
                                    unit_amount_value: text,
                                    amount_error: "",
                                  });
                                }
                              }

                            }
                          }}
                          style={[{ width: '100%', marginTop: 10 }]}
                          inputStyle={[{ paddingLeft: isMobile ? 20 : 10 }]}
                          editable={true}
                        />}
                        {this.state.amount_error != "" && <Text style={{ textAlign: 'center', color: 'red' }}>{this.state.amount_error}</Text>}
                        <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
                          {!this.state.isLoading ? (<TouchableOpacity style={{ backgroundColor: '#410DAA', borderRadius: 5 }} onPress={this.handleStartSwitch}>
                            <Text style={{ paddingHorizontal: 30, paddingVertical: 10, color: 'white', fontWeight: '500' }}>Switch</Text>
                          </TouchableOpacity>) : (<ActivityIndicator size={'large'} color={"#410DAA"} />)}
                        </View>
                      </View>
                    </View>
                  </View>)}
                  {this.state.type == "stp" && (<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <View style={{ width: isMobile ? '80%' : '40%', marginTop: 50 }}>
                      <View style={{ marginTop: 20 }}>
                        <PickerFieldSelect
                          data={this.state.amcfs_stp_filter}
                          width={'100%'}
                          value={stp_to_scheme_id}
                          label={"To Scheme*"}
                          onChange={(selectedType) =>
                            this.setState({ stp_to_scheme_id: selectedType })
                          }
                          showSelect={true}
                          pickerStyle={[{ width: '100%' }]}
                          isValid={selectedTypeError}
                          type="select"
                        />
                      </View>
                      <View style={{ marginTop: 20 }}>
                        <TextInputField
                          label="Select folio number"
                          value={this.state.mfSelectedFund[0].folio_number}
                          onChange={(text) => {
                            if (!isNaN(text)) {
                              this.setState({
                                enteredAmount: text,
                                amount_error: "",
                              });
                            }
                          }}
                          style={[{ width: '100%' }]}
                          inputStyle={[{ paddingLeft: isMobile ? 20 : 10 }]}
                          editable={false}
                        />
                      </View>
                      <View style={{ marginTop: 20 }}>
                        <TextInputField
                          label="Amount*"
                          value={this.state.enteredAmount}
                          onChange={(text) => {
                            if (!isNaN(text)) {
                              this.setState({
                                enteredAmount: text,
                                amount_error: "",
                              });
                            }
                          }}
                          style={[{ width: '100%' }]}
                          inputStyle={[{ paddingLeft: isMobile ? 20 : 10 }]}
                          editable={true}
                        />
                      </View>
                      <View style={{ marginTop: 20 }}>
                        <PickerFieldSelect
                          data={this.data}
                          width={'100%'}
                          value={selectedType}
                          label={"Choose frequency*"}
                          onChange={(selectedType) =>
                            this.setState({ selectedType: selectedType })
                          }
                          showSelect={false}
                          pickerStyle={[{ width: '100%' }]}
                          isValid={selectedTypeError}
                          type="select"
                        />
                      </View>
                      <View style={{ marginTop: 20 }}>
                        <PickerFieldSelect
                          data={this.daysType}
                          width={'100%'}
                          value={days}
                          label={"Select day of the month to start SWP*"}
                          onChange={(selectedType) => {
                            // Get current date
                            const today = new Date();
                            const currentDay = today.getDate();
                            const selectedDay = parseInt(selectedType);
                            
                            // If selected day has passed in current month, set for next month
                            if (selectedDay <= currentDay) {
                              // Create date for next month
                              const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, selectedDay);
                              this.setState({ 
                                days: selectedType,
                                effectiveDate: nextMonth // Store the effective date if needed
                              });
                            } else {
                              // Use current month's date
                              const thisMonth = new Date(today.getFullYear(), today.getMonth(), selectedDay);
                              this.setState({ 
                                days: selectedType,
                                effectiveDate: thisMonth // Store the effective date if needed
                              });
                            }
                          }}
                          showSelect={false}
                          pickerStyle={[{ width: '100%' }]}
                          isValid={selectedTypeError}
                          type="select"
                        />
                      </View>
                      <View style={{ marginTop: 20 }}>
                        <TextInputField
                          label="Number of Withdrawals*"
                          value={this.state.minWithdrawals}
                          onChange={(text) => {
                            if (!isNaN(text)) {
                              this.setState({
                                minWithdrawals: text,
                                amount_error: "",
                              });
                            }
                          }}
                          style={[{ width: '100%', marginTop: 20 }]}
                          inputStyle={[{ paddingLeft: isMobile ? 20 : 10 }]}
                          editable={true}
                        />
                      </View>
                      <TouchableOpacity
                        style={{
                          flexDirection: "row",
                        }}
                        onPress={() =>
                          this.setState({
                            redeemFullCheckBox: !this.state.redeemFullCheckBox,
                          })
                        }
                      >
                        <Checkbox
                          value={true || this.state.redeemFullCheckBox}
                          onValueChange={() => {
                            this.setState({
                              redeemFullCheckBox: !this.state.redeemFullCheckBox,
                            });
                          }}
                          color={
                            this.state.redeemFullCheckBox ? "#410DAA" : "#cdcdcd"
                          }
                          status={
                            this.state.redeemFullCheckBox
                              ? "checked"
                              : "unchecked"
                          }
                        />
                        <Text
                          style={{
                            flex: 1,
                            color: "#666666",
                            fontWeight: '500',
                            marginLeft: 5,
                            marginTop: isMobile ? 7 : 9,
                          }}
                        >
                          Place first installment of STP today
                        </Text>
                      </TouchableOpacity>
                      <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
                        { !this.state.isLoading ? (<TouchableOpacity style={{ backgroundColor: '#410DAA', borderRadius: 5 }} onPress={this.handleStartSTP}>
                          <Text style={{ paddingHorizontal: 30, paddingVertical: 10, color: 'white', fontWeight: '500' }}>Start STP</Text>
                        </TouchableOpacity>) : (<ActivityIndicator size={'large'} color={"#410DAA"} />)}
                      </View>
                    </View>
                  </View>)}
                </View>
                {isMobile && <View style={{ marginTop: 35 }}></View>}
              </ScrollView>
            )}
          </View>

          {this.state.showModel && <Modal style={styles.modalContainer} containerStyle={{ marginHorizontal: 8 }}>
            <View style={{ padding: 50 }}>
            <Text style={{ fontSize: 18, fontWeight: "700", color: this.state.isOrderSuccess ? "green" : "red", marginBottom: 31, maxWidth: 600, textAlign: 'center' }}>
                {this.state.isOrderSuccess ? "Your Order Place Successfully" : "Your Order Not Placed"}
              </Text>
              <Text style={{ fontSize: 16, fontWeight: "500", color: "#121212", marginBottom: 31, maxWidth: 600, }}>
                Status: {this.state.textToshowInModel}
              </Text>
              {this.state.isOrderSuccess && <Text style={{ fontSize: 14, fontWeight: "400", color: "#666666", marginBottom: 21, maxWidth: 600, }}>
                Please click on continue to authenticate your order
              </Text>}
              <View style={styles.confirmButtonContainer}>
                <TouchableOpacity onPress={this.handleContinue} underlayColor="white">
                  <View style={[styles.confirmButton, styles.confirmButtonYes, { paddingHorizontal: 30 }]}>
                    <Text style={styles.yesText}>Continue</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: isWeb ? 32 : 0,
    paddingRight: isWeb ? 32 : 0,
    paddingTop: isWeb ? 30 : 10,
    width: "100%",
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
  filterText: {
    fontSize: 15,
    color: "gray",
    fontWeight: "200",
  },
  filtersTitle: {
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 6,
    marginLeft: isMobile ? 16 : 0,
  },
  modalContainer: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' },
  title: { fontSize: 17, fontWeight: "700", color: "#464E5F", marginBottom: 14 },
  description: { fontSize: 14, fontWeight: "400", color: "#464E5F", marginBottom: 35 },
  confirmButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    justifyContent: "space-around"
  },
  confirmButton: {
    paddingHorizontal: 20,
    paddingVertical: 6,
    borderRadius: 6
  },
  confirmButtonNo: {
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1
  },
  noText: {
    color: "#410DAA",
    fontSize: 15
  },
  confirmButtonYes: {
    backgroundColor: "#410DAA",
  },
  yesText: {
    color: "#fff",
    fontSize: 15
  },
});
