import React, { useCallback, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
} from "react-native";

import amplitude from "../../../utils/amplitude";
import { md } from "../../../Responsive";
import NavigationHeader from "../../Common/NavigationHeader";
import { Octicons } from "@expo/vector-icons";
import { formatDate, isMobile, nth, numberWithCommas } from "../../../utils";
import {
  getCIPInstallments,
  updateCIPPlannedDate,
  updateSIPDateAndFrequency,
} from "../../../api";
import { DatePickerModal } from "react-native-paper-dates";
import { DatePicker } from "../../Common/DatePicker";
import { validateInput } from "../../../utils/validateInput";
import PickerFieldSelect from "../../Common/Pickers/PickerFieldSelect";

const { width } = Dimensions.get("window");
const isWeb = md(width);

const getFormattedDate = (date) => {
  const d = new Date(date);
  const day = d.getDate();
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const formattedDate = day + "-" + month + "-" + year;
  return formattedDate;
};

const FilterByTypes = [
  {
    key: 1,
    label: "Installments",
    value: "INV",
  },
  {
    key: 2,
    label: "Rebalancing",
    value: "REB",
  },
];

const PlanDetails = ({
  data,
  selectedFrequency,
  onFrequencyOrDayChange,
  selectedSIPDate,
}) => {
  let startDate, endDate, dateToShowPicker;
  if (data) {
    const d = new Date();
    const month = d.getMonth() + 1;
    const next_month = d.getMonth() + 2;
    const year = d.getFullYear();
    const dateToShow = next_month + "-" + data.cips_start_date + "-" + year;
    dateToShowPicker = new Date(dateToShow);
    startDate = next_month + "-" + 1 + "-" + year;
    startDate = new Date(startDate);
    endDate = next_month + "-" + 28 + "-" + year;
    endDate = new Date(endDate);
  }

  const [date, setDate] = useState(dateToShowPicker);
  const [open, setOpen] = useState(false);

  const onDismissSingle = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
      const formattedDate = getFormattedDate(params.date);
      onFrequencyOrDayChange(selectedFrequency, data.cip_pk, formattedDate);
    },
    [setOpen, setDate]
  );

  return (
    <View style={[styles.plan_detail, { flexWrap: "wrap" }]}>
      <View
        style={{
          flexDirection: "row",
          minWidth: isWeb ? 150 : 300,
          marginBottom: isWeb ? 0 : 27,
        }}
      >
        <View style={styles.circle1}>
          <View style={styles.circle2}></View>
        </View>
        <View style={{ marginLeft: 10 }}>
          <Text style={styles.plan_nm}>
            {data.plan_name} -{" "}
            <Text style={styles.plan_type}>{data.plan_type}</Text>
          </Text>
          <Text style={styles.date}>
            {formatDate(data.plan_start_date)} -{" "}
            {formatDate(data.plan_end_date)} ({data.plan_duration}Y)
          </Text>
        </View>
      </View>
      <PlanDetailsField
        title={"Planned"}
        value={data.total_investment}
        installmentsContent={`${data.total_installments} Installments`}
        showInstallmentsCount={data.plan_type != "Lumpsum"}
      />
      <PlanDetailsField
        title={"Invested"}
        value={data.current_invested_amount}
        installmentsContent={`${data.completed_installments} Installments ${
          isWeb ? "completed" : ""
        }`}
        showInstallmentsCount={data.plan_type != "Lumpsum"}
      />
      {data.plan_type != "Lumpsum" && (
        <PlanDetailsField
          title={"Pending"}
          value={data.pending_investment_amount}
          installmentsContent={`${data.pending_installments} Installments ${
            isWeb ? "pending" : ""
          }`}
          showInstallmentsCount={data.plan_type != "Lumpsum"}
        />
      )}

      {isWeb && (
        <View style={{ minWidth: isWeb ? 50 : 0 }}>
          {data.plan_type == "SIP" ? (
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{ fontWeight: "bold", fontSize: 14, color: "#464E5F" }}
              >
                Select Installment Day
              </Text>
              <View
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "#d8d8d8",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 5,
                }}
              >
                <TouchableOpacity onPress={() => setOpen(true)}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-end",
                      justifyContent: "flex-start",
                    }}
                  >
                    {selectedFrequency === "MONTHLY" && (
                       <Octicons
                      name="calendar"
                      size={15}
                      color="#410DAA"
                      style={{ paddingRight: 10 }}
                    /> 
                  )}
                    <Text style={{ color: "#464E5F", fontSize: 13 }}>
                      {data.cips_start_date}
                      {nth(data.cips_start_date)}
                    </Text>
                  </View>
                </TouchableOpacity>
                <DatePickerModal
                  // locale={'en'} optional, default: automatic
                  mode="single"
                  visible={open}
                  onDismiss={onDismissSingle}
                  date={date}
                  onConfirm={onConfirmSingle}
                  validRange={{
                    startDate: startDate, // optional
                    endDate: endDate, // optional
                  }}
                  // onChange={} // same props as onConfirm but triggered without confirmed by user
                  saveLabel="Save" // optional
                  label="Select Installment Day" // optional
                  // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
                />
                {/* <View
                  style={[
                    {
                      flexDirection: "row",
                      alignItems: "flex-end",
                      paddingLeft: 15,
                    },
                    Platform.OS == "web" && { cursor: "pointer" },
                  ]}
                >
                  <PickerFieldSelect
                    data={[
                      { label: "Every Month", value: "MONTHLY" },
                      { label: "Every Week", value: "WEEKLY" },
                      { label: "Every Fortnight", value: "FORTNIGHT" },
                      { label: "Quarterly", value: "QUARTERLY" },
                      { label: "Bimonthly", value: "BIMONTHLY" },
                    ]}
                    value={selectedFrequency}
                    style={{}}
                    onChange={(itemValue) => {
                      const formattedDate = getFormattedDate(date);
                      onFrequencyOrDayChange(
                        itemValue,
                        data.cip_pk,
                        formattedDate
                      );
                    }}
                    pickerStyle={[
                      {
                        borderColor: "transparent",
                        color: "#888C9F",
                        width: 200,
                      },
                    ]}
                    type="select"
                    placeholder={{
                      label: "Select",
                      value: null,
                      color: "#9EA0A4",
                    }}
                  />
                   <Picker
                                            selectedValue={selectedFrequency}
                                            onValueChange={(itemValue, itemIndex) =>{
                                                const formattedDate= getFormattedDate(date);
                                                onFrequencyOrDayChange(itemValue,data.cip_pk,formattedDate);
                                            }}
                                            style={{
                                                borderColor:"transparent",
                                                color:"#888C9F",
                                                width: 200
                                            }}
                                            itemStyle={{
                                                width: 200
                                            }}
                                         >
                                            <Picker.Item label="Every Month" value="MONTHLY" />
                                            <Picker.Item label="Every Week" value="WEEKLY" />
                                            <Picker.Item label="Every Fortnight" value="FORTNIGHT" />
                                            <Picker.Item label="Quarterly" value="QUARTERLY" />
                                            <Picker.Item label="Bimonthly" value="BIMONTHLY" />
                                        </Picker>
                </View> */}
              </View>
            </View>
          ) : (
            data.plan_type != "Lumpsum" && (
              <>
                <Text style={styles.up_coming}>
                  {data.upcoming_installment_amount != null && (
                    <>
                      <Text>Upcoming Installment of ₹</Text>
                      <Text style={styles.by_date}>
                        {numberWithCommas(
                          data.upcoming_installment_amount.toFixed(0)
                        )}{" "}
                      </Text>
                    </>
                  )}
                </Text>
                {data.upcoming_installment_date && (
                  <Text style={[styles.up_coming, { paddingTop: 10 }]}>
                    by{" "}
                    <Text style={styles.by_date}>
                      {formatDate(data.upcoming_installment_date)}
                    </Text>
                  </Text>
                )}
              </>
            )
          )}
        </View>
      )}
    </View>
  );
};

const PlanDetailsField = ({
  title,
  value,
  installmentsContent,
  showInstallmentsCount,
}) => {
  return (
    <View>
      <Text style={{ fontSize: 13, color: "#6C7293", paddingBottom: 10 }}>
        {title}
      </Text>
      <Text style={styles.plan_nm}>₹{numberWithCommas(parseInt(value))}</Text>
      {showInstallmentsCount && (
        <Text style={styles.tot_insts}>({installmentsContent})</Text>
      )}
    </View>
  );
};

const InstallmentField = ({ value, title }) => {
  return (
    <View>
      <Text style={styles.amt_paid}>{value}</Text>
      <Text style={styles.planned_amt}>{title}</Text>
    </View>
  );
};

const ToggleActiveCompletePlans = ({
  tabs,
  activeIndex,
  onChangeValue,
  style = {},
}) => {
  return (
    <View style={{ flexDirection: "row", ...style }}>
      {tabs.map((tab, index) => {
        return (
          <TouchableOpacity
            onPress={() => {
              onChangeValue && onChangeValue(index);
            }}
          >
            <Text
              style={{
                color: index == activeIndex ? "#410DAA" : "#888C9F",
                fontSize: 14,
                paddingHorizontal: 10,
                paddingVertical: 12,
                borderBottomWidth: 3,
                borderBottomColor:
                  activeIndex == index ? "#410DAA" : "transparent",
              }}
            >
              {tab}
              {/* {index==activeIndex?"Installments":""} */}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default class pendinginstallments extends React.Component {
  constructor(props) {
    super(props);
    let { id, actv } = this.props.route.params;
    id = id || null;
    actv = actv || 0;
    const { params } = this.props.route;
    let userId = null;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }
    this.state = {
      userId,
      data: null,
      activeTabIndex: actv,
      id,
    };
  }

  loadData = async (filterBy = "INV") => {
    try {
      let { actv } = this.props.route.params;
      let { id, userId } = this.state;
      const activeTabIndex = actv || 0;
      if (id) {
        let payload = {
          cip_pk: id,
        };
        if (userId) payload["client_pk"] = userId;
        const response = await getCIPInstallments(payload);
        if (response.code == 100) {
          let { result } = response;
          this.setState({ data: result, activeTabIndex }, () => {
            this.getFilterData(activeTabIndex);
          });
        } else {
          this.setState({
            error: true,
            errorText:
              response && response.msg ? response.msg : "Something went wrong!",
          });
        }
      } else {
        this.setState({
          error: true,
          errorText: "Something went wrong!",
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("myequityinvestmentplanflow",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("myequityinvestmentplanflow", { path: this.props.navigation.getState().routeName });
    }
    this.navigationWillFocusListener = this.props.navigation.addListener(
      "focus",
      () => {
        this.loadData();
      }
    );
  }

  componentWillUnmount() {
    this.navigationWillFocusListener &&
      this.navigationWillFocusListener.remove &&
      this.navigationWillFocusListener.remove();
  }

  reloadData = async () => {
    try {
      let { id, userId } = this.state;
      if (id) {
        let payload = {
          cip_pk: id,
        };
        if (userId) payload["client_pk"] = userId;

        const response = await getCIPInstallments(payload);
        if (response.code == 100) {
          let { result } = response;
          this.setState({ data: result, isLoading: false }, () => {
            this.getFilterData(this.state.activeTabIndex);
          });
        } else {
          this.setState({
            isLoading: false,
            error: true,
            errorText: response && response.msg ? response.msg : null,
          });
        }
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Something went wrong!",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  handleEditSIPDate = async (cip_pk, date, frequency) => {
    try {
      const data = {
        cip_pk,
        planned_date: date,
        frequency,
      };

      this.setState({ isLoading: true });
      const response = await updateSIPDateAndFrequency(data);
      if (response.code == 100) {
        this.reloadData();
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  handleEditDate = async (cips_pk, date) => {
    try {
      const formattedDate = getFormattedDate(date);
      const data = {
        cips_pk,
        planned_date: formattedDate,
      };
      this.setState({ isLoading: true });
      const response = await updateCIPPlannedDate(data);
      if (response.code == 100) {
        this.setState({ activeEditPlanID: null }, () => {
          this.reloadData();
        });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {}
  };

  getScheduleType = (type) => {
    const types = {
      INV: "Investment",
      REB: "Rebalancing",
      WTH: "Withdrawal",
      CAR: "Carry Forward",
    };

    return types[type] || "-";
  };

  renderinstallmentdetails = ({ item }) => {
    const isWeb = md(width);
    const { data } = this.state;
    const date = formatDate(item.planned_date);
    const isCompleted = item.status == "Done";
    const executionDate = !!item.execution_date
      ? formatDate(item.execution_date)
      : "-";
    return (
      <View style={styles.inst_dts_paid}>
        {data.plan_type != "Lumpsum" && (
          <View
            style={{
              flexDirection: "row",
              alignItems: isWeb ? "center" : "flex-start",
              minWidth: isWeb ? 150 : 100,
            }}
          >
            {item.schedule_type == "INV" ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: isMobile ? "flex-start" : "center",
                }}
              >
                <View
                  style={[
                    styles.circle,
                    {
                      marginTop: 5,
                      borderColor: isCompleted ? "#00AB9A" : "#EC615B",
                    },
                  ]}
                />
                {isWeb ? (
                  <Text style={styles.installments}>
                    {item.installment_number}/{data.total_installments}{" "}
                    Installment
                  </Text>
                ) : (
                  <View>
                    <Text
                      style={[styles.installments, styles.installmentCount]}
                    >
                      {item.installment_number}/{data.total_installments}
                    </Text>
                    <Text
                      style={[styles.installments, styles.installmentField]}
                    >
                      Installment
                    </Text>
                  </View>
                )}
              </View>
            ) : (
              <Text
                style={{ fontSize: 14, color: "#121212", textAlign: "center" }}
              >
                -
              </Text>
            )}
          </View>
        )}

        {((isMobile && !isCompleted) || isWeb) && (
          <>
            <InstallmentField title={"Planned Date"} value={date} />
            <InstallmentField
              title={"Planned Amount"}
              value={`₹${numberWithCommas(parseInt(item.planned_amount))}`}
            />
          </>
        )}
        {(isWeb || isMobile) && isCompleted && (
          <>
            <InstallmentField title={"Execution Date"} value={executionDate} />
            <InstallmentField
              title={"Execution Amount"}
              value={`₹${numberWithCommas(parseInt(item.execution_amount))}`}
            />
          </>
        )}
        {isWeb && (
          <InstallmentField
            title={"Schedule Type"}
            value={this.getScheduleType(item.schedule_type)}
          />
        )}

        <View
          style={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isWeb && !isCompleted && (
            <TouchableOpacity
              onPress={() => {
                this.setState({
                  activeEditPlanID: item.cips_pk,
                  plannedDate: item.planned_date,
                });
              }}
            >
              <Text style={styles.edit_plan}>
                <Octicons
                  name="calendar"
                  size={15}
                  color="#410DAA"
                  style={{ paddingRight: 10 }}
                />{" "}
                Edit Planned Date
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  };

  getFilterData = (index) => {
    let filteredData = [];
    const { data, filterByType = "INV" } = this.state;
    const noPendingInstallments =
      data.installments.filter((item) => item["status"] == "Not Done").length ==
      0;
    // if (noPendingInstallments) {
    //   index = 1;
    // }
    const filterBy = index == 0 ? "Not Done" : "Done";
    filteredData = data.installments.filter((item, index) => {
      return item["status"] == filterBy;
    });
    // console.log("------filterby type----",filterByType)
    filteredData = filteredData.filter(
      (item) => item.schedule_type == filterByType || item.schedule_type ==="CAR"
    );

    const completedInstallmentsCount = data.installments.filter(                     

      (item) => item["status"] == "Done" && item["schedule_type"] != "REB"
    ).length;
    const pendingInstallmentsCount = data.installments.filter(
      (item) => item["status"] == "Not Done" && item["schedule_type"] != "REB"
    ).length;

    this.setState({
      filteredData,
      activeTabIndex: index,
      completedInstallmentsCount,
      pendingInstallmentsCount,
    });
  };

  handleChangeFilterBy = (value, type, inputType) => {
    this.setState(
      {
        [type]: value,
        [type + "Error"]: validateInput(inputType, value),
      },
      () => {
        this.getFilterData(this.state.activeTabIndex, value);
      }
    );
  };

  render() {
    const {
      data,
      error,
      errorText,
      filteredData,
      activeTabIndex,
      filterByType,
      filterByTypeError,
    } = this.state;
    if (error) {
      return (
        <View style={{ flexDirection: "column", height: "100%" }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={[
              "Equity Plan",
              "My Equity Investment Plans",
              "Manage Schedule",
            ]}
          />
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              maxWidth: 1300,
            }}
          >
            <Text style={{ fontSize: 16, color: "#121212" }}>{errorText}</Text>
          </View>
        </View>
      );
    }

    return (
      <View style={{ flex: 1, backgroundColor: "#ffffff" }}>
        <View>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={[
              "Equity Plan",
              "My Equity Investment Plans",
              "Manage Schedule",
            ]}
          />
        </View>

        {data ? (
          <ScrollView style={{ maxWidth: 1300 }}>
            {this.state.activeEditPlanID && (
              <DatePicker
                isActive={this.state.activeEditPlanID}
                inititalDate={this.state.plannedDate}
                setPlannedDate={(date) => {
                  this.handleEditDate(this.state.activeEditPlanID, date);
                }}
                closedDatePicker={() => {
                  this.setState({ activeEditPlanID: null });
                }}
                validRange={{
                  startDate: new Date(), // optional
                }}
                label={"Edit Planned Date"}
              />
            )}
            {/* Loading bar */}
            {this.state.isLoading && (
              <View
                style={{
                  backgroundColor: "transparent",
                  padding: 30,
                  borderRadius: 12,
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  bottom: 0,
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                }}
              >
                {/* <Text style={{marginBottom:15,fontSize:15,fontFamily:"Roboto",color:"#000"}}>Updating</Text> */}
                <ActivityIndicator size="large" color="#410DAA" style={{}} />
              </View>
            )}

            <View style={styles.body}>
              <Text style={styles.your_plan}>
                Your Investment Schedule{" "}
                <Text style={styles.plan_num}>(Plan No: {data.cip_pk})</Text>
              </Text>

              <PlanDetails
                data={data}
                selectedFrequency={data.frequency}
                onFrequencyOrDayChange={(selectedFrequency, cip_pk, date) => {
                  this.setState({ selectedFrequency }, () => {
                    this.handleEditSIPDate(cip_pk, date, selectedFrequency);
                  });
                }}
              />
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 30,
                  borderBottomColor: "#E5EAEE",
                  borderBottomWidth: 1,
                  marginBottom: 10,
                }}
              >
                <ToggleActiveCompletePlans
                  
                  tabs={[
                    `Pending (${this.state.pendingInstallmentsCount})`,
                    `Completed (${this.state.completedInstallmentsCount})`,
                  ]}

                  activeIndex={activeTabIndex}
                  onChangeValue={(selectedIndex) => {
                    // console.log("------------completed installment count-----------------",this.state.completedInstallmentsCount);
                    // console.log("Completed Installments Data:",this.state.data.installments)
                    if (selectedIndex != activeTabIndex) {
                      this.setState({ activeTabIndex: selectedIndex }, () => {
                        this.getFilterData(selectedIndex);
                      });
                    }
                  }}
                  style={{}}
                />
                <View>
                  <TouchableOpacity
                    style={{
                      backgroundColor: "#EEE5FF",
                      borderRadius: 6,
                      paddingHorizontal: 8,
                      paddingVertical: 9,
                      marginRight: 16,
                    }}
                    onPress={() => {
                      this.props.navigation.navigate("App", {
                        screen: "Dashboard",
                        params: {
                          screen: "Portfolio",
                          params: {
                            screen: "AddInstallment",
                            params: {
                              id: this.state.id,
                            },
                          },
                        },
                      });
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 15,
                        color: "#8950FC",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      + Add {isWeb ? " installment" : ""}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              <View style={styles.filterByMainContainer}>
                <View style={styles.filterByContainer}>
                  <Text style={styles.filterBy}>Filter By:</Text>
                  <PickerFieldSelect
                    data={FilterByTypes}
                    value={filterByType}
                    onChange={(filterByType) => {
                      this.handleChangeFilterBy(
                        filterByType,
                        "filterByType",
                        "select"
                      );
                    }}
                    pickerStyle={[{ width: 200 }]}
                    isValid={filterByTypeError}
                    type="select"
                  />
                </View>
              </View>
              <View
                style={[
                  styles.paid_installment_dts,
                  {
                    borderWidth:
                      isWeb && filteredData && filteredData.length ? 1 : 0,
                  },
                ]}
              >
                {/* {console.log("filteredData-------------",filteredData)} */}
                <FlatList
                  data={filteredData || []}
                  ref={(ref) => {
                    this.pendingPaymentsTable = ref;
                  }}
                  renderItem={this.renderinstallmentdetails}
                  keyExtractor={(item, index) => `pending_payment_${index}`}
                  ItemSeparatorComponent={() => {
                    return (
                      <View
                        style={{
                          width: "100%",
                          background: "#E5EAEE",
                        }}
                      />
                    );
                  }}
                  ListEmptyComponent={
                    <View
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Text
                        style={{
                          fontSize: 13,
                          color: "#121212",
                          marginTop: 50,
                        }}
                      >
                        No{activeTabIndex == 0 ? " Active " : " Completed "}{" "}
                        Installments
                      </Text>
                    </View>
                  }
                />
              </View>
            </View>
          </ScrollView>
        ) : (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        )}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  body: {
    marginLeft: isWeb ? 30 : 0,
    marginRight: isWeb ? 50 : 0,
  },
  plan_detail: {
    backgroundColor: "rgba(249, 249, 249, 0.6)",
    borderWidth: isWeb ? 1 : 0,
    borderColor: "solid rgba(65, 13, 170, 0.2)",
    paddingHorizontal: isWeb ? 25 : 16,
    paddingVertical: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    borderRadius: 12,
  },
  paid_installment_dts: {
    borderWidth: 1,
    borderColor: "solid rgba(65, 13, 170, 0.1)",
    borderRadius: 12,
    marginTop: isMobile ? 10 : 20,
  },
  inst_dts_paid: {
    borderBottomWidth: 1,
    borderBottomColor: "solid rgba(65, 13, 170, 0.1)",
    paddingVertical: isWeb ? 40 : 15,
    paddingHorizontal: isWeb ? 40 : 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  your_plan: {
    fontFamily: "Roboto",
    fontWeight: isWeb ? "bold" : "normal",
    fontSize: isWeb ? 17 : 13,
    lineHeight: 21,
    color: "#000000",
    paddingVertical: 20,
    paddingHorizontal: 16,
  },
  plan_num: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
  },
  plan_nm: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#121212",
  },
  plan_type: {
    fontWeight: "normal",
    color: "#00AB9A",
  },
  tot_invs: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#6C7293",
  },
  date: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#4A4B68",
    paddingTop: 10,
  },
  tot_insts: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "rgba(108, 114, 147, 0.8)",
    paddingTop: 10,
  },
  installments: {
    fontFamily: "Roboto",
    fontWeight: isWeb ? "bold" : "normal",
    fontSize: isMobile ? 13 : 15,
    lineHeight: 20,
    color: "#121212",
    letterSpacing: 0.2,
    paddingLeft: isWeb ? 10 : 8,
  },
  installmentCount: {
    marginTop: isMobile ? 3 : 0,
  },
  installmentField: {
    color: "#ACAFBC",
    fontSize: isMobile ? 13 : 15,
  },
  amt_paid: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 13 : 14,
    lineHeight: 21,
    color: "#121212",
  },
  planned_amt: {
    fontFamily: "Roboto",
    fontSize: isMobile ? 12 : 13,
    color: "#ACAFBC",
    letterSpacing: 0.1,
    paddingTop: 7,
  },
  edit_plan: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 15,
    color: "#410DAA",
  },
  circle1: {
    width: 14,
    height: 14,
    borderRadius: 7,
    borderWidth: 1,
    borderColor: "#410DAA",
    alignItems: "center",
    justifyContent: "center",
  },
  circle2: {
    width: 8,
    height: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#410DAA",
  },
  circle: {
    width: 13,
    height: 13,
    borderWidth: 3,
    borderRadius: 6.5,
  },
  up_coming: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 21,
    color: "#494B69",
  },
  by_date: {
    fontWeight: "bold",
  },
  filterByMainContainer: {
    alignItems: isMobile ? "flex-start" : "flex-end",
    marginTop: 15,
  },
  filterByContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filterBy: {
    fontSize: 14,
    color: "#121212",
    marginRight: 15,
    fontWeight: "700",
    marginLeft: isMobile ? 16 : 0,
  },
});
