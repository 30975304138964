import React from "react";
import amplitude from "../../../utils/amplitude";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  Image,
  Picker,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from "react-native";
import { StackActions } from "@react-navigation/routers";
import { md } from "../../../Responsive";
import NavigationHeader from "../../Common/NavigationHeader";
import {
  getCustomerBankAccounts,
  createMandate,
  createOrderWithFirstInstallment,
} from "../../../api";
import { isMobile } from "../../../utils";
const { width, height } = Dimensions.get("window");
const isWeb = md(width);
import {
  TextInput,
  HelperText,
  Card,
  Button,
  RadioButton,
} from "react-native-paper";
import { ShowToast } from "../../Common/Toast";
import moment from "moment";
import PickerField from "../../Common/Pickers/PickerField";
import Store from "../../../store";
import PickerFieldSelect from "../../Common/Pickers/PickerFieldSelect";

export default class CreateMandate extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let userId = null;
    let fundPK = null;
    let payload = null;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      fundPK = params.fund;
      payload = params.payload ? params.payload : null;
    }

    this.state = {
      userId,
      fundPK,
      payload,
      activeTabIndex: 0,
      isLoading: true,
      filters: [],
      mfDetails: [],
      mfTxnDetails: [],
      mfFundTxnDetails: [],
      mfAMCFundsSchemes: [],
      isSwitchOn: false,
      selectedAccount: "",
      selectedAccountError: "",
      value: "",
      date_error: "",
      selecteddate: "select",
      amount: "",
      amountError: "",
      year: 5,
      selectedScheme: "",
      selectedSchemeDetails: {},
      selectedfrequency: "monthly",
      sipvalue: 500,
      sipyear: 5,
      options: [],
      autoDebitCheckBox: true,
      placefirstinstallmentbool: true,
      errorToast: false,
      storedSIPDetails: {},
      mandates: [],
      mandates_count: 0,
      mandate_data: {},
      storedSIPDetails: {},
      selected_mandate_id: "",
      pendingModalVisible: false,
      mandateType: "",
      mandateTypeError: "",
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("createmandate",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("createmandate", { path: this.props.navigation.getState().routeName });
      }
      let options = [];
      let storedSIPDetails = {};
      storedSIPDetails = this.state.payload;

      this.setState({ storedSIPDetails });

      const customerBankAccounts = await getCustomerBankAccounts();

      if (customerBankAccounts && customerBankAccounts.results) {
        // options.push({
        //   label: "Select Bank Account",
        //   value: "SEL"
        // });
        customerBankAccounts.results.forEach((account) => {
          if (account.account_number) {
            options.push({
              label: account.bank_name + " - " + account.account_number,
              value: account.account_number,
              account: account,
            });
          }
        });

        this.setState({
          options,
          selectedAccount: options[0].value,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  setModalMethod = (method) => {
    this.openOrCloseModal = method;
  };

  amountValidation = () => {
    if (this.state.amount == "" || this.state.amount == 0) {
      this.setState({
        amountError: "Please enter valid amount",
      });
    }
  };

  validateData = () => {
    if (this.state.value == "") {
      this.setState({
        amountError: "Please enter minimum Amount ",
      });
      return false;
    }

    if (
      this.state.mfFundTxnDetails &&
      this.state.mfFundTxnDetails.bse_sip_minimum_installment_amount_monthly
    ) {
      if (
        Number(this.state.value) <
        this.state.mfFundTxnDetails.bse_sip_minimum_installment_amount_monthly
      ) {
        this.setState({
          amountError: "Please enter minimum Amount ",
        });
        return false;
      }
    } else {
      if (Number(this.state.value) < 500) {
        this.setState({
          amountError: "Please enter minimum Amount ",
        });
        return false;
      }
    }

    if (this.state.selecteddate == "select") {
      this.setState({
        date_error: "* Please select a start date ",
      });
      return false;
    }

    if (isNaN(this.state.amount)) {
      this.setState({
        amountError: "Please enter valid number of installments",
      });
      return false;
    }

    if (this.state.amount < 12) {
      this.setState({
        amountError: "Number of installment should be greater than 12",
      });
      return false;
    }

    return true;
  };

  createNewMandate = async () => {
    try {
      const { selectedAccount, amount, mandateType } = this.state;

      if (!selectedAccount || selectedAccount == "SEL") {
        this.setState({
          selectedAccountError: "Please select a bank account",
        });
        return false;
      }

      if (amount == "" || amount == 0) {
        this.setState({
          amountError: "Please enter valid amount",
        });
        return false;
      }

      if (amount > 100000) {
        this.setState({
          amountError: "Maximum mandate amount is ₹1,00,000",
        });
        return false;
      }

      if (mandateType == "") {
        this.setState({
          mandateTypeError: "Please select mandate type",
        });
        return false;
      }
      this.setState({ isLoading: true });

      const selectedBankAccountDetails = this.state.options.find(
        (account) => account.value == selectedAccount
      );
      let payload = {
        amount: amount,
        bank_account_no: selectedBankAccountDetails.account.account_number,
        micr_code: selectedBankAccountDetails.account.micr_code
          ? selectedBankAccountDetails.account.micr_code
          : "",
        mandate_type: mandateType,
      };

      const createMandateResp = await createMandate(payload);
      if (
        createMandateResp &&
        createMandateResp.code &&
        createMandateResp.code == "100"
      ) {
        // this.props.navigation.dispatch(
        //   StackActions.replace("SelectMandate", {
        //     fund: this.state.fundPK,
        //     showToast: "true",
        //     payload: this.state.storedSIPDetails,
        //   })
        // );
        this.placeOrderWithFirstInstallment(createMandateResp);
      } else {
        this.setState({ errorToast: true });
      }
    } catch (e) {
      this.setState({ errorToast: true });
    }
  };

  placeOrderWithFirstInstallment = async (mandate_data) => {
    try {
      const dateString = this.getStartDate();
      const key = moment().unix();
      const payload = {
        orders: [
          {
            scheme: this.state.storedSIPDetails.scheme,
            amount: Number(this.state.storedSIPDetails.amount),
            sip_frequency_type: this.state.storedSIPDetails.sip_frequency_type,
            sip_start_date: dateString,
            sip_no_of_installments:
              this.state.storedSIPDetails.sip_no_of_installments,
            investmentType: "sip",
            sip_first_order_flag: "N",
            sip_mandate: Number(mandate_data.mandate_id),
          },
        ],
        redirection_url:
          (Platform.OS == "web"
            ? global.location.origin
            : "https://app.jamawealth.com") +
          "/dashboard/process/" +
          key,
      };

      this.setState({
        isLoading: true,
      });
      const response = await createOrderWithFirstInstallment(payload);
      if (response) {
        const { navigate_to, code, nav_props, ...restData } = response;
        Store.dispatch({
          type: "ADD_ACTION",
          payload: {
            key: key,
            data: {
              ...restData,
              ...nav_props,
            },
          },
        });

        if (response.code && response.code == 100) {
          if (response.success > 0) {
            if (response.navigate_to == "ShowBankAccount") {
              this.props.navigation.navigate("App", {
                screen: "Dashboard",
                params: {
                  screen: "Explore",
                  params: {
                    screen: "ShowBankAccount",
                    params: {
                      action: key,
                    },
                  },
                },
              });
              return;
            }
            if (Platform.OS == "web") {
              global.open(url, "_self");
            } else {
              this.setState({
                isLoading: false,
              });
              this.props.navigation.navigate("App", {
                screen: "Dashboard",
                params: {
                  screen: "Portfolio",
                  params: {
                    screen: "ShowWebView",
                    params: {
                      url: url,
                      successURL: "/payment/success",
                      onSuccess: function (navigation, results = {}) {
                        navigation.push("App", {
                          screen: "Dashboard",
                          params: {
                            screen: "ProcessOrder",
                            params: { action_id: key },
                          },
                        });
                      },
                      onError: function (navigation, results = {}) {
                        // navigation.push("App", { screen: "Dashboard", params: { screen: "Home", params: {} } });
                      },
                    },
                  },
                },
              });
            }
          } else {
            this.setState({
              isLoading: false,
            });
            if (response.navigate_to == "ShowCreatedOrders") {
              this.props.navigation.navigate("App", {
                screen: "Dashboard",
                params: {
                  screen: "Dashboard",
                  params: {
                    screen: "ProcessOrder",
                    params: { action_id: key },
                  },
                },
              });
            }
          }
        }
      }
    } catch (error) {
      this.setState({ isLoading: false, showToast: true });
    }
  };

  getStartDate = () => {
    let date = Number(this.state.storedSIPDetails.sip_start_date);

    if (date > parseInt(moment().format("DD"))) {
      date <= 9 ? (date = "0" + date.toString()) : null;
      return moment().format("YYYY") + "-" + moment().format("MM") + "-" + date;
    } else {
      date <= 9 ? (date = "0" + date.toString()) : null;
      if (moment().format("MM") == 12) {
        return 1 + parseInt(moment().format("YYYY")) + "-" + "01" + "-" + date;
      } else {
        return parseInt(moment().format("YYYY")) + "-" + "01" + "-" + date;
      }
    }
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  getNumIndianFormat = (num) => {
    if (!num) {
      return "-";
    }
    var str = num.toString();
    var final = "";
    if (str.length > 3) {
      final = "," + str.substring(str.length - 3, str.length);
      str = str.substring(0, str.length - 3);
      while (str.length > 2) {
        final = "," + str.substring(str.length - 2, str.length) + final;
        str = str.substring(0, str.length - 2);
      }
      final = str + final;
    } else {
      return num.toString();
    }
    return final;
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  render() {
    const { error, errorText, isLoading } = this.state;

    var productList = [];
    let amount =
      this.state.mfFundTxnDetails &&
      this.state.mfFundTxnDetails.bse_sip_minimum_installment_amount_monthly
        ? this.getNumIndianFormat(
            this.state.mfFundTxnDetails
              .bse_sip_minimum_installment_amount_monthly
          )
        : 500;

    if (Object.keys(this.state.mfFundTxnDetails).length > 0) {
      if (this.state.mfFundTxnDetails && this.state.mfFundTxnDetails.schemes) {
        this.state.mfFundTxnDetails.schemes.map((item, index) => {
          if (item.is_direct_fund == true) {
            productList.push(
              <Picker.Item
                key={index}
                label={item.variant_name || item.bse_variant_name}
                value={item.amcfundscheme_id}
              />
            );
          }
        });
      }
    }

    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View style={{ flex: 1, maxWidth: 1500 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={[
              "Mutual Funds Overview",
              "Invest",
              "Create Mandate",
            ]}
          />
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.errorToast ? (
              <ShowToast
                showToast={true}
                type="error"
                title="Error creating new mandate, Please try again."
                duration="7"
              />
            ) : null}
            {isLoading ? (
              <ActivityIndicator size="large" color="#410DAA" />
            ) : error ? (
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText || "Something went wrong!"}
              </Text>
            ) : (
              <ScrollView style={[styles.mainContainer]}>
                <View
                  style={{
                    paddingBottom: 40,
                    marginTop: isMobile ? -3 : -30,
                    paddingLeft: isWeb ? 32 : 0,
                    paddingRight: isWeb ? 32 : 0,
                    paddingTop: isWeb ? 30 : 10,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 20,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 16,
                          color: "#464E5F",
                          fontWeight: "bold",
                          marginBottom: 10,
                          marginLeft: isMobile ? 20 : 0,
                        }}
                      >
                        Add New Bank Mandate
                      </Text>
                    </View>
                  </View>

                  <Text
                    style={{
                      fontSize: isMobile ? 14 : 13,
                      color: "#939399",
                      paddingBottom: 4,
                      marginTop: 12,
                      marginLeft: isMobile ? "5%" : "0%",
                      fontWeight: "500",
                    }}
                  >
                    Mandate helps timely investment in your SIP
                  </Text>

                  <View
                    style={{
                      marginRight: isMobile ? "5%" : "60%",
                      width: isMobile ? "90%" : "40%",
                      marginLeft: isMobile ? "5%" : "0%",
                      marginBottom: 10,
                      marginTop: 25,
                      zIndex: 9999,
                    }}
                  >
                    <PickerFieldSelect
                      data={this.state.options}
                      value={this.state.selectedAccount}
                      label="Select Bank"
                      onChange={(value) => {
                        this.setState({
                          selectedAccount: value,
                          selectedAccountError: "",
                        });
                      }}
                      pickerStyle={[styles.inputItem]}
                      type="select"
                      placeholder={{
                        value: "SEL",
                        label: "Select Bank Account",
                      }}
                    />
                  </View>
                  <HelperText
                    style={{ marginTop: -10, marginBottom: 10 }}
                    type="error"
                    visible={this.state.selectedAccountError != ""}
                  >
                    {this.state.selectedAccountError}
                  </HelperText>
                  <View
                    style={{
                      marginBottom: 6,
                      marginRight: isMobile ? "5%" : "60%",
                      width: isMobile ? "90%" : "40%",
                      marginLeft: isMobile ? "5%" : "0%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 13,
                        color: "#939399",
                        paddingBottom: 4,
                        marginTop: 15,
                        fontWeight: "500",
                      }}
                    >
                      Set Daily Transaction Amount Limit
                    </Text>
                    <TextInput
                      style={{
                        backgroundColor: "white",
                        borderRadius: 50,
                        marginTop: 5,
                      }}
                      mode="outlined"
                      onFocus={() => {
                        this.setState({ amountError: "" });
                      }}
                      onBlur={() => {
                        this.amountValidation();
                      }}
                      keyboardType="numeric"
                      label="Enter Amount"
                      onChangeText={(text) => {
                        const pattern = /^[0-9\b]+$/;
                        if (pattern.test(text)) {
                          this.setState({ amount: text });
                        } else {
                          text = text.slice(0, -1);
                          this.setState({ amount: text });
                        }
                      }}
                      returnKeyType={"done"}
                      value={this.state.amount}
                      error={this.state.amountError}
                      tintColor="#410DAA"
                      titleTextStyle={{
                        color: "#410DAA",
                      }}
                    />

                    <HelperText
                      type="error"
                      visible={this.state.amountError != ""}
                    >
                      {this.state.amountError}
                    </HelperText>
                  </View>
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#939399",
                      paddingBottom: 4,
                      marginTop: 15,
                      fontWeight: "500",
                      marginLeft: isMobile ? "5%" : "0%",
                    }}
                  >
                    Mandate Type
                  </Text>
                  <View
                    style={{
                      width: isMobile ? "90%" : "22%",
                      marginLeft: isMobile ? -5 : -25,
                    }}
                  >
                    <RadioButton.Group
                      onValueChange={(value) =>
                        this.setState({
                          mandateType: value,
                          mandateTypeError: "",
                        })
                      }
                      value={this.state.mandateType}
                    >
                      <RadioButton.Item
                        label="E-Mandate With Net Banking"
                        value="N"
                        labelStyle={{ fontSize: 13 }}
                        color="#410daa"
                        style={{
                          flexDirection: "row-reverse",
                          alignSelf: "flex-start",
                        }}
                      />
                      <RadioButton.Item
                        label="With Signed NACH Form"
                        value="X"
                        labelStyle={{ fontSize: 13 }}
                        color="#410daa"
                        style={{
                          flexDirection: "row-reverse",
                          alignSelf: "flex-start",
                          marginTop: -15,
                        }}
                      />
                    </RadioButton.Group>
                  </View>
                  <HelperText
                    style={{ marginTop: -10, marginBottom: 10 }}
                    type="error"
                    visible={this.state.mandateTypeError != ""}
                  >
                    {this.state.mandateTypeError}
                  </HelperText>
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#939399",
                      paddingBottom: 4,
                      marginTop: -5,
                      fontWeight: "500",
                      marginLeft: isMobile ? "5%" : "0%",
                    }}
                  >
                    Get form on E-mail instantly, scan & send it to
                    help@maxiom.in
                  </Text>

                  <TouchableOpacity
                    onPress={() => {
                      this.createNewMandate();
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                        fontSize: isWeb ? 15 : 13,
                        color: "#FFFFFF",
                        backgroundColor: "#410DAA",
                        borderColor: "#410DAA",
                        borderWidth: 1,
                        borderRadius: 4,
                        paddingVertical: 9,
                        marginTop: 20,
                        textAlign: "center",
                        width: isMobile ? "30%" : "13%",
                        marginLeft: isMobile ? "35%" : "27%",
                        marginRight: isMobile ? "35%" : "70%",
                      }}
                    >
                      Add Mandate
                    </Text>
                  </TouchableOpacity>
                </View>
                {isMobile && <View style={{ marginTop: 35 }}></View>}
              </ScrollView>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  confirm: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 18,
    color: "#121212",
    marginBottom: 11,
    marginLeft: 42,
    marginTop: 25,
  },
  sureContainer: {
    marginBottom: 44,
  },
  sure: {
    fontFamily: "Roboto",
    fontSize: 15,
    color: "#121212",
  },
  image: { width: 19, height: 17, resizeMode: "contain", marginRight: 7 },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    width: "100%",
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  button_style: {
    padding: 2,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: "#410DAA",
    marginRight: 6,
    overflow: "hidden",
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  detailHeading: {
    color: "#121212",
    opacity: 0.5,
    fontStyle: "italic",
    fontSize: 11,
  },
  detailText: {
    color: "#121212",
    fontSize: 14,
    marginTop: 4,
    fontWeight: "bold",
  },
  clickHere: {
    color: "#410DAA",
    fontWeight: "bold",
    paddingLeft: 6,
    textAlign: "center",
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
  filterText: {
    fontSize: 15,
    color: "gray",
    fontWeight: "200",
  },
  filtersTitle: {
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 6,
    marginLeft: isMobile ? 16 : 0,
  },
});
