import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Switch } from 'react-native-paper';
// import PickerField from '../../components/Common/Pickers/PickerField';
import { isMobile } from '../../utils';
import { useDispatch, useSelector } from 'react-redux'

const TestConfig = () => {

    const { demoMode } = useSelector((state) => state.testConfig)
    const dispatch = useDispatch()

    const onToggleSwitch =() => {
        dispatch({ type: "UPDATE_TEST_CONFIG", payload: { demoMode: !demoMode } });
    };

    return(
        <View style={styles.container}>
            <View style={[styles.mainContainer]}>
                <Text style={styles.heading}>Configuration</Text>
                {/* <View style={[ styles.formItem, styles.itemMargin]}>
                    <PickerField
                        data={APPLICANT_INCOME_SLAB}
                        value={null}
                        label="Salary Range*"
                        onChange={(incomeSlab) =>{}}
                        pickerStyle={[{ }]}
                        isValid={true}
                        type="select"
                    />
              </View> */}
              <View style={[ styles.formItem, styles.itemMargin]}>
                <Text style={styles.demoModeLabel}>Demo mode</Text>
                <Switch value={demoMode} onValueChange={onToggleSwitch} style={styles.demoSwitch} color={"#410DAA"}/>
              </View>
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    border: {
        borderWidth: 1,
        borderColor: "#DCC2FF",
        borderRadius: 22,
    },
    mainContainer: {
        overflow: "scroll",
        marginHorizontal: isMobile ? 16 : 32,
        marginVertical: isMobile ? 16 : 32,
        flex: 1,
        minWidth: 800,
        padding: 16
    },
    heading: {
        fontSize: 16,
        fontWeight: "bold",
        color: "#121212",
    },
    itemMargin: {
        marginTop: 10,
        marginBottom: 10,
    },
    demoModeLabel: {
        fontFamily: "Roboto",
        fontSize: 15,
        fontWeight: "700",
        color: "#464E5F",
        marginBottom: 11,
        marginTop: 20
    },
    demoSwitch: {
        width: 30,
        height: 30,
    }
});


export default TestConfig;
