import React from "react";
import {
  View,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  Platform,
  BackHandler,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { Text } from "react-native-paper";
import { getQuizQnA, submitQuizQnA } from "../../../api";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { getUserDetails } from "../../../utils";
import { StackActions } from "@react-navigation/native";
import amplitude from "../../../utils/amplitude";

export default class Quizz extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    this.scrollViewRef = React.createRef()
    let quiz_id = null;
    let from = null;
    if (params) {
      quiz_id = params.quiz_id || null;
      from = params.from || null;
      quiz_id = isNaN(quiz_id) ? null : quiz_id;
    }
    this.state = {
      quiz_id,
      resultsArray: [], //store the risk profile questions and anssers
      questionCount: -1,
      loading: true,
      answeredQnA: [], //store the answered questions and anwsers along with selected options
      stage: 0, // used to identify which question is user currently answering.
      riskprofiletaken: false,
      riskprofile: {},
      quizName: "",
      submittingAnswer: false,
      data: [],
      showModal: false,
      showPersonalizedPicks: false,
      error: "",
      raw: "",
      answers: "",
      from,
      index: 0,
    };
  }

  scrollToBottom = () => {
    if (this.scrollViewRef.current) {
      this.scrollViewRef.current.scrollToEnd({ animated: true });
    }
  };

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("quiz",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("quiz", { path: this.props.navigation.getState().routeName });
    }
    this.resetState();
    await this.getQuizQnA();
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  resetState = async () => {
    const { quiz_id = null } = this.state;
    this.setState(
      {
        quiz_id,
        resultsArray: [],
        quizName: "",
        questionCount: -1,
        loading: false,
        answeredQnA: [],
        stage: 0,
        riskprofiletaken: false,
        riskprofile: {},
        submittingAnswer: false,
        data: [],
        showModal: false,
        showPersonalizedPicks: false,
        error: "",
        raw: "",
        answers: "",
        index: 0,
      },
      () => {}
    );
  };

  getQuizQnA = async () => {
    this.setState({
      loading: false,
    });
    try {
      const { quiz_id = null } = this.state;
      if (quiz_id) {
        const payload = {
          quiz_id,
        };
        const response = await getQuizQnA(payload);
        this.setState({
          raw: response.quiz_data,
        });
        const data = [
          { isQuestion: true, content: response.quiz_data[0].question },
        ];
        this.setState({
          resultsArray: response.quiz_data,
          quizName: response.quiz_name,
          questionCount: response.quiz_data.length,
          loading: false,
          data: data,
          answeredQnA: [],
          stage: 0,
          error: "",
        });
      }
    } catch (err) {
      this.setState({
        loading: false,
        data: null,
      });
    }
    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      this.handleBackPress
    );
  };

  handleBackPress = () => {
    return true;
  };

  addToArray = (content, isQuestion) => {
    return [...this.state.data, { isQuestion, content }].filter((item) => item);
  };

  selectAnswer = async (option, q_index) => {
    try {
      if (this.state.index != q_index) {
        return;
      }
      let data = [
        ...this.state.data,
        {
          isQuestion: false,
          content: option.text,
        },
      ];

      data = [
        ...data,
        this.state.index + 1 < this.state.resultsArray.length && {
          isQuestion: true,
          content: this.state.resultsArray[this.state.index + 1].question,
        },
      ].filter((item) => item);

      let answers = this.state.answers + option.option_id + ",";

      if (this.state.index + 1 === this.state.resultsArray.length) {
        this.setState({
          loading: true,
          data,
        });
        answers = answers.split(",");
        answers.pop();
        const { quiz_id = null } = this.state;
        if (quiz_id) {
          const { customer_id } = getUserDetails();
          const payload = {
            quiz_id,
            options: answers.join(),
            cust_id: customer_id,
          };

          const response = await submitQuizQnA(payload);
          if (response && response.code == "100") {
            if (this.state.from == "fi") {
              this.props.navigation.dispatch(
                StackActions.replace("FinWellness", {
                  quiz_id,
                  show_toast: "true",
                })
              );
            } else {
              this.props.navigation.dispatch(
                StackActions.replace("EmWellness", { quiz_id })
              );
            }
            this.resetState();
          } else {
            setTimeout(() => {
              this.setState({ submittingAnswer: false });
            }, 1000);
          }
          this.setState({ loading: false });
        }
      } else {
        this.setState({
          answers: answers,
          data,
          index:
            this.state.index + 1 < this.state.resultsArray.length
              ? this.state.index + 1
              : this.state.index,
          submittingAnswer: false,
        });
      }
    } catch (error) {}
  };

  goBacktoQuestion(questionNumber) {
    this.setState({ stage: questionNumber });
  }

  getQuestionsIndex = (content) => {
    const questions = this.state.data.filter((item) => item.isQuestion == true);
    const q_index = questions.findIndex(
      (item) => item.isQuestion === true && item.content == content
    );
    return q_index;
  };

  renderItem = ({ item }) => {
    if (item.isQuestion) {
      const q_index = this.getQuestionsIndex(item.content);
      return (
        <View style={styles.QnAContainer}>
          <View
            style={{
              backgroundColor: "#fff",
              alignItems: "flex-start",
            }}
          >
            <Text style={styles.question}>{item.content}</Text>
          </View>
          {!this.state.submittingAnswer && (
            <>{this.renderOptions(item.content, q_index)}</>
          )}
        </View>
      );
    } else
      return (
        <View style={styles.answerContainer}>
          <Text style={styles.answer}>{item.content}</Text>
        </View>
      );
  };

  renderOptions = (q, q_index) => {
    const results = this.state.resultsArray.filter((item, index) => {
      return this.state.index == index;
    });
    if (results.length) {
      const { options, question, answer_name } = results[0];
      return (
        <>
          {q == question &&
            options &&
            options.map((ele, index) => {
              return (
                <TouchableOpacity
                  style={{
                    backgroundColor: "#fff",
                    borderBottomWidth: 0.7,
                    borderBottomColor:
                      index < options.length - 1 ? "#cdcdcd" : "transparent",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: 10,
                  }}
                  onPress={() => {
                    this.selectAnswer(ele, q_index, 1);
                  }}
                >
                  <View
                    style={{
                      width: 16,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {answer_name == ele.text && (
                      <View
                        style={{
                          width: 4,
                          height: 4,
                          borderRadius: 2,
                          backgroundColor: "#1553A5",
                          marginTop: 8,
                        }}
                      ></View>
                    )}
                  </View>
                  <Text
                    style={{
                      color: answer_name == ele.text ? "#1553A5" : "#0077B6",
                      fontSize: 16,
                      flex: 0.9,
                    }}
                  >
                    {ele.text}
                  </Text>
                </TouchableOpacity>
              );
            })}
        </>
      );
    } else {
      return null;
    }
  };

  static navigationOptions = () => ({
    tabBarVisible: false,
  });

  rednerOptions = () => {
    return this.state.resultsArray.map((item, index) => {
      if (this.state.index == index) {
        return (
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {item.options.map((ele, inde) => {
              return (
                <TouchableOpacity
                  style={[styles.QnAContainer]}
                  onPress={() => {
                    this.selectAnswer(ele, inde, 1);
                  }}
                >
                  <View
                    style={[
                      {
                        backgroundColor: "#fff",
                        alignItems: "flex-start",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.question,
                        {
                          maxWidth: "100%",
                          marginRight: 8,
                          backgroundColor: "#d5d5d5",
                        },
                      ]}
                    >
                      {ele.text}
                    </Text>
                  </View>
                </TouchableOpacity>
              );
            })}
          </View>
        );
      }
    });
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  _renderHeader = () => {
    return (
      <NavigationHeader
        navigation={this.props.navigation}
        navigationHeader={["Quiz"]}
        onPressBack={() => {
          this.props.navigation.goBack();
        }}
      />
    );
  };

  render() {
    return (
      <SafeAreaView style={styles.container}>
        {this._renderHeader()}
        {this.state.error.length > 0 && <Text>{this.state.error}</Text>}
        {this.state.resultsArray.length > 0 && (
          <ScrollView
            ref={this.scrollViewRef}
            inverted
            onContentSizeChange={this.scrollToBottom} // Automatically scrolls when content size changes
            onLayout={this.scrollToBottom} // Automatically scrolls when layout changes
          >
            <View style={{ flex: 3, paddingBottom: 0, maxWidth: 800 }}>
              <Text
                style={{
                  fontSize: 18,
                  fontWeight: "700",
                  marginLeft: 20,
                  marginTop: 25,
                  marginBottom: -10,
                }}
              >
                {this.state.quizName}
              </Text>
              <ScrollView
                contentContainerStyle={{ flexGrow: 1, padding: 16 }}
                ref={(ref) => (this.scrollView = ref)}
                onContentSizeChange={() => {
                  this.scrollView.scrollToEnd({ animated: true });
                }}
              >
                <FlatList
                  data={this.state.data}
                  renderItem={this.renderItem}
                  extraData={this.state}
                />
              </ScrollView>
              {this.state.submittingAnswer && (
                <View style={styles.lottieVIewContainer}></View>
              )}
            </View>
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    paddingBottom: 10,
  },
  QnAContainer: {
    marginVertical: 16,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: "#cdcdcd",
    maxWidth: "80%",
    overflow: "hidden",
  },
  question: {
    fontSize: 16,
    padding: 10,
  },

  answerContainer: {
    backgroundColor: "#0077B6",
    maxWidth: "80%",
    alignSelf: "flex-end",
    padding: 10,
    borderRadius: 8,
    borderBottomRightRadius: 0,
    marginBottom: 5,
  },
  answer: {
    color: "#fff",
    fontSize: 16,
  },
  lottieVIewContainer: {
    alignItems: "flex-start",
    marginBottom: 24,
    width: 100,
    height: 30,
    backgroundColor: "transparent",
  },
  optionsContainer: {
    flex: 2,
    borderTopWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  startOverText: {
    color: "#1c52a0",
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#1c52a0",
    paddingTop: 16,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    padding: 16,
  },
  modalContentContainer: {
    backgroundColor: "white",
    padding: 16,
    borderRadius: 4,
  },
});
