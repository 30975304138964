import React, { Component } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from "react-native";
import moment from "moment";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { Button, Text } from "react-native-paper";
import TextInputField from "../../../components/Common/Inputs/InputField";
import DatePickerField from "../../../components/Common/Pickers/DatePickerField";
import { getUserDetails, isMobile, reverseString } from "../../../utils";
import PickerField from "../../../components/Common/Pickers/PickerField";
import Toast from "react-native-toast-message";
import { scheduleMeetingWithAdvisor } from "../../../api/home";
import theme from "../../../static/theme/index.js";
import TimePickerField from "../../../components/Common/Pickers/TimePickerField";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import {
  createCrmdealsCipForAdvisory,
  createCrmdealsCipForRegular,
  getBookAdvisoryCountOfCorporates,
  getCipsAboutToExpire,
  moveDataFromDirectToRegular,
} from "../../../api";

import { CustomModal } from "../../../components/Common/CustomModal";
import { LOGOUT } from "../../../actions/types";
import amplitude from "../../../utils/amplitude";
import Store from "../../../store/index.js";

const topics = [
  {
    label: "Financial Planning",
    value: "FP",
  },
  {
    label: "Equity Advisory",
    value: "DE",
  },
  {
    label: "Review Portfolio Performance",
    value: "RPP",
  },
  {
    label: "Portfolio Management System",
    value: "PMS",
  },
  {
    label: "Schedule Periodic Reviews",
    value: "SPR",
  },
  {
    label: "Advice on New Investment",
    value: "ANI",
  },
  {
    label: "Advice on Redemption",
    value: "AR",
  },
  {
    label: "Pricing",
    value: "PRC",
  },
  {
    label: "Life Insurance Planning",
    value: "LIP",
  },
  {
    label: "Health Insurance Planning",
    value: "HIP",
  },
  {
    label: "Retirement Planning",
    value: "RP",
  },
  {
    label: "Children Education Planning",
    value: "CEP",
  },
  {
    label: "Others",
    value: "OTH",
  },
];

export default class ScheduleScreen extends Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    let selected = "FP";
    let is_elite = false;
    let from_onboarding = false;
    if (hostname.includes("app.jamawealth.com") || hostname.includes("dapp")) {
      is_elite = true;
    }
    let from_risk = false;
    if (params) {
      selected = params.selected || "FP";
      from_risk = params.from_risk || false;
      from_onboarding = params.from_onboarding || false;
    }
    this.state = {
      selected,
      from_onboarding,
      selectedDate: "",
      selectedTime: "",
      remarks: "",
      from_risk,
      btnLoading: false,
      isDateTimePickerVisible: false,
      error: null,
      submitting: false,
      dateError: false,
      timeError: false,
      allow_advisor_meeting: true,
      text_to_display: "",
      go_to_plan_renewal: false,
      showModal: false,
      is_elite: is_elite,
      cip_pk_for_renewal: null,
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("scheduleCall", { path: global.location.href });
    } else {
      amplitude.logEvent("scheduleCall", {
        path: this.props.navigation.getState().routeName,
      });
    }
    let topic;
    const { params } = this.props.route;
    if (params) {
      topic = params.topic || null;
    }
    let { is_elite } = this.state;
    let text_to_display = "";
    let allow_advisor_meeting = true;
    let showModal = false;
    let cip_pk_for_renewal = null;
    // let user_id =
    //   Store.getState().dashboard.viewModeUserId &&
    //   Store.getState().dashboard.viewModeUserId.user_id &&
    //   Store.getState().dashboard.viewModeUserId.user_id
    //     ? Store.getState().dashboard.viewModeUserId.user_id
    //     : null;
    let user_id = Store.getState().userData.userProfileData.customer_id;

    let response = await getCipsAboutToExpire(user_id);
    if (response && response.code == "100") {
      let { result } = response;
      result.forEach((item) => {
        if (item["renewal_type"] == "expired_renewal") {
          text_to_display += item["plan_text_for_display"];
          text_to_display += "\n";
          allow_advisor_meeting = false;
          showModal = true;
          cip_pk_for_renewal = item["cip_pk"];
        }
      });
    }
    if (!this.state.from_onboarding && topic) {
      this.setState({
        selected: topic,
      });
    }
    this.setState({
      allow_advisor_meeting: allow_advisor_meeting,
      text_to_display: text_to_display,
      showModal: showModal,
      cip_pk_for_renewal: cip_pk_for_renewal,
    });
  }

  showDateTimePicker = () => {
    this.setState({ isDateTimePickerVisible: true });
  };

  hideDateTimePicker = () => {
    this.setState({ isDateTimePickerVisible: false });
  };

  handleDatePicked = (value, timeMode = false) => {
    this.setState({ dateError: false, timeError: false });
    if (!timeMode) {
      const formattedDate = moment(value).format("MM/DD/YYYY");
      const selectedDate = moment(value).format("DD MMM YYYY");
      this.setState({
        selectedDate,
        formattedDate,
        error: null,
      });
    } else {
      const formattedTime = moment(value).format("HH/mm/ss");
      const selectedTime = moment(value).format("hh:mm a");
      this.setState({
        selectedTime,
        formattedTime,
        error: null,
      });
    }
    this.hideDateTimePicker();
  };

  onValueChange = (value) => {
    this.setState({
      selected: value,
      error: null,
    });
  };

  handleContinueToLogout = async () => {
    try {
      localStorage.removeItem("isShowAppDownloadCard");
      localStorage.removeItem("showPlanRenewalCard");
      localStorage.removeItem("redirectAfterOnboardingSuccess");

      Store.dispatch({ type: LOGOUT });
      // }
    } catch (error) {
      Store.dispatch({ type: LOGOUT });
    }
  };

  moveUserToClub = async () => {
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    let userDetails = getUserDetails();
    if (
      hostname == "app.jamawealth.com" ||
      hostname == "myadvisor.jamawealth.com" ||
      hostname == "dapp.jamawealth.com" ||
      hostname == "dmyadvisor.jamawealth.com" ||
      hostname == "mobile" ||
      hostname == "localhost"
    ) {
      // moveDataFromDirectToRegular
      const payload = {
        cust_pk: userDetails.pk,
      };
      let response = await moveDataFromDirectToRegular(payload);

      if (response && response.code == 100) {
        // console.log("Redirection i jfvkjf ", response);
        this.setState({
          showToast: true,
          toastMessage: response.result,
          migrating_data: false,
        });
        let url = "https://dclub.jamawealth.com/login";
        if (response.token) {
          let reverseToken = reverseString(response.token);
          url = url + "?unique=" + reverseToken;
        }
        setTimeout(() => {
          this.handleContinueToLogout();
          global.open(url, "_blank");
        }, 5000);
      } else {
        // console.log(response.msg);
        this.setState({
          isLoading: false,
          error: true,
          migrating_data: false,
        });
      }
      this.setState({ isLoading: false });
    } else {
      let responce = await createCrmdealsCipForRegular();
      // if(responce && responce.code == 100) {
      this.props.navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Dashbaord",
          params: {},
        },
      });
      // }
    }
  };

  // onSubmitPress = async () => {
  //   try {
  //     this.setState({ btnLoading: true });
  //     const {
  //       selected,
  //       selectedDate,
  //       selectedTime,
  //       formattedDate,
  //       formattedTime,
  //       remarks,
  //       allow_advisor_meeting,
  //     } = this.state;
  //     if (allow_advisor_meeting) {
  //       if (selectedDate.length <= 0) {
  //         this.setState({
  //           dateError: true,
  //         });
  //         return;
  //       } else {
  //         this.setState({ dateError: false });
  //       }

  //       if (selectedTime.length <= 0) {
  //         this.setState({
  //           timeError: true,
  //         });
  //         return;
  //       } else {
  //         this.setState({ timeError: false });
  //       }
  //       this.setState({
  //         submitting: true,
  //         error: null,
  //       });

  //       let userData = getUserDetails();
  //       let customer_id = userData.customer_id;
  //       if (
  //         Store.getState().dashboard.viewModeUserId &&
  //         Store.getState().dashboard.viewModeUserId.user_id &&
  //         Store.getState().dashboard.viewModeUserId.user_id
  //       ) {
  //         customer_id = Store.getState().dashboard.viewModeUserId.user_id;
  //       }
  //       let check_meeting_response = null;
  //       if (!this.state.from_risk) {
  //         check_meeting_response = await getBookAdvisoryCountOfCorporates({
  //           cust_pk: customer_id,
  //         });
  //       }
  //       if (
  //         (check_meeting_response && check_meeting_response.code == "100") ||
  //         this.state.from_risk
  //       ) {
  //         console.log("label", this.state.selected);
  //         const payload = {
  //           prop_date: formattedDate,
  //           alt_date: formattedDate,
  //           prop_time: formattedTime,
  //           topic_desc: topics.find((item) => item.value === selected).label,
  //           remarks,
  //           call_type: "",
  //           topic: selected,
  //         };

  //         const response = await scheduleMeetingWithAdvisor(payload);

  //         if (response && response.code == 100) {
  //           Toast.show({
  //             text1: `Your meeting has been scheduled on ${selectedDate} at ${selectedTime}.`,
  //             visibilityTime: 2 * 1000,
  //             topOffset: 45,
  //             type: "success",
  //           });

  //           this.setState({
  //             selected: "FP",
  //             selectedDate: "",
  //             selectedTime: "",
  //             remarks: "",
  //             submitting: false,
  //             btnLoading: false,
  //           });
  //           if (this.state.from_onboarding) {
  //             this.moveUserToClub();
  //           }
  //           if (this.state.from_risk) {
  //             this.props.navigation.goBack();
  //           }
  //         } else {
  //           Toast.show({
  //             text1: `Something went wrong. Try again.`,
  //             visibilityTime: 2 * 1000,
  //             topOffset: 145,
  //             type: "success",
  //           });

  //           this.setState({
  //             submitting: false,
  //             btnLoading: false,
  //             error: "Something went wrong. Try again.",
  //           });
  //         }
  //       } else {
  //         Toast.show({
  //           text1: check_meeting_response.msg,
  //           visibilityTime: 2 * 1000,
  //           topOffset: 60,
  //           type: "success",
  //         });

  //         this.setState({
  //           submitting: false,
  //           btnLoading: false,
  //           error: check_meeting_response.msg,
  //         });
  //       }
  //     } else {
  //       this.setState({
  //         showModal: !this.state.showModal,
  //         btnLoading: false,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     Toast.show({
  //       text1: `Something went wrong. Try again.`,
  //       visibilityTime: 2 * 1000,
  //       topOffset: 145,
  //       type: "success",
  //     });
  //     this.setState({
  //       submitting: false,
  //       btnLoading: false,
  //       error: "Something went wrong. Try again.",
  //     });
  //   }
  // };
  onSubmitPress = async () => {
    try {
      const {
        selected,
        selectedDate,
        selectedTime,
        formattedDate,
        formattedTime,
        remarks,
        allow_advisor_meeting,
      } = this.state;

      // Check if advisor meeting is allowed
      if (allow_advisor_meeting) {
        // Validate selectedDate
        if (selectedDate.length <= 0) {
          this.setState({ dateError: true });
          return;
        } else {
          this.setState({ dateError: false });
        }

        // Validate selectedTime
        if (selectedTime.length <= 0) {
          this.setState({ timeError: true });
          return;
        } else {
          this.setState({ timeError: false });
        }

        this.setState({
          submitting: true,
          btnLoading: true,
          error: null,
        });

        let userData = getUserDetails();
        let customer_id = userData.customer_id;
        const viewModeUserId = Store.getState().dashboard.viewModeUserId;
        if (viewModeUserId && viewModeUserId.user_id) {
          customer_id = viewModeUserId.user_id;
        }

        let check_meeting_response = null;
        if (!this.state.from_risk) {
          check_meeting_response = await getBookAdvisoryCountOfCorporates({
            cust_pk: customer_id,
          });
        }

        if (
          (check_meeting_response && check_meeting_response.code == "100") ||
          this.state.from_risk
        ) {
          // console.log("label", this.state.selected);
          const payload = {
            prop_date: formattedDate,
            alt_date: formattedDate,
            prop_time: formattedTime,
            topic_desc: topics.find((item) => item.value === selected).label,
            remarks,
            call_type: "",
            topic: selected,
          };

          const response = await scheduleMeetingWithAdvisor(payload);

          if (response && response.code == 100) {
            Toast.show({
              text1: `Your meeting has been scheduled on ${selectedDate} at ${selectedTime}.`,
              visibilityTime: 4000, // Increased visibility time for success messages
              topOffset: 100,
              type: "success",
            });

            this.setState({
              selected: "FP",
              selectedDate: "",
              selectedTime: "",
              remarks: "",
              submitting: false,
              btnLoading: false,
            });

            if (this.state.from_onboarding) {
              this.moveUserToClub();
            }
            if (this.state.from_risk) {
              this.props.navigation.goBack();
            }
          } else {
            Toast.show({
              text1: `Something went wrong. Try again.`,
              visibilityTime: 4000, // Increased visibility time for error messages
              topOffset: 145,
              type: "error", // Changed to error type
            });

            this.setState({
              submitting: false,
              btnLoading: false,
              error: "Something went wrong. Try again.",
            });
          }
        } else {
          Toast.show({
            text1: check_meeting_response.msg,
            visibilityTime: 4000, // Increased visibility time for error messages
            topOffset: 60,
            type: "error", // Changed to error type
          });

          this.setState({
            submitting: false,
            btnLoading: false,
            error: check_meeting_response.msg,
          });
        }
      } else {
        this.setState({
          showModal: !this.state.showModal,
          btnLoading: false,
        });
      }
    } catch (error) {
      // console.log(error);
      Toast.show({
        text1: `Something went wrong. Try again.`,
        visibilityTime: 4000, // Increased visibility time for error messages
        topOffset: 145,
        type: "error", // Changed to error type
      });
      this.setState({
        submitting: false,
        btnLoading: false,
        error: "Something went wrong. Try again.",
      });
    }
  };

  renderHeader = (title) => {
    return (
      <Text style={{ marginVertical: 8, color: "#121212", fontSize: 13 }}>
        {title}
      </Text>
    );
  };

  onSubmitRenewal = () => {
    this.props.navigation.navigate("App", {
      screen: "Account",
      params: {
        screen: "AdvisoryPlan",
        params: {
          cip_pk_for_renewal: this.state.cip_pk_for_renewal,
        },
      },
    });
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  renderModalContent = () => {
    const { text_to_display } = this.state;
    return (
      <View
        style={{
          padding: 15,
          flex: 1,
          alignItems: "center",
        }}
      >
        <Text>{text_to_display}</Text>
        <Button
          uppercase={false}
          mode={"contained"}
          onPress={this.onSubmitRenewal}
          style={{
            marginTop: 30,
            width: isMobile ? "100%" : "40%",
          }}
          loading={this.state.submitting}
          color={"#410DAA"}
        >
          <Text style={styles.next}>Renew Now</Text>
        </Button>
      </View>
    );
  };

  render() {
    const { container } = styles;
    const {
      selectedTime,
      selectedDate,
      dob_formatted,
      remarks,
      allow_advisor_meeting,
      text_to_display,
    } = this.state;
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Schedule Call"]}
          onPressBack={() => {
            if (this.state.from_risk) {
              this.props.navigation.navigate("MiniOnboardingRiskProfileQA");
            }
            if (
              this.props.route.params.topic &&
              !this.props.route.params.fromDashboard &&
              this.props.route.params.dontShowFunds
            ) {
              this.props.navigation.navigate("Explore", {
                screen: "MFExplore",
              });
            } else {
              // console.log("KK")
              this.props.navigation.navigate("App", {
                screen: "Portfolio",
                params: {
                  activeTabIndex: 1,
                  activeTab: this.state.activeTab,
                },
              }

              );
            }
          }}
        />
        <ScrollView style={container} keyboardShouldPersistTaps={"never"}>
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            {this.state.error > 0 && (
              <Text style={{ color: "red", fontSize: theme.fontsizes.XXSMALL }}>
                {" "}
                * {this.state.error}
              </Text>
            )}

            <View
              style={[{ width: isMobile ? "100%" : 300, marginBottom: 20 }]}
            >
              <PickerFieldSelect
                data={topics}
                value={this.state.selected}
                label="Choose Your Topic"
                width={isMobile ? "100%" : 300}
                onChange={(topic) => this.onValueChange(topic)}
                pickerStyle={[{ ...styles.inputItem }]}
                isValid={true}
                type="select"
                placeholder={{ value: "--", label: "Select" }}
              />
            </View>
            <View
              style={[{ width: isMobile ? "100%" : 300, marginBottom: 20 }]}
            >
              <DatePickerField
                label="Choose a suitable date"
                value={selectedDate}
                onSelect={(value) => {
                  const date = new Date(value.date);
                  this.handleDatePicked(date);
                }}
                validRange={{
                  startDate: new Date(),
                }}
                editable={true}
                style={[
                  {
                    width: isMobile ? "100%" : 300,
                  },
                ]}
              />
            </View>
            {this.state.dateError && (
              <Text style={[styles.please, { color: "red" }]}>
                Please select a date
              </Text>
            )}
            <View
              style={[{ width: isMobile ? "100%" : 300, marginBottom: 20 }]}
            >
              <TimePickerField
                label="Choose a suitable time"
                value={selectedTime}
                onSelect={(value) => {
                  if (value) {
                    const d = new Date();
                    d.setHours(value.hours);
                    d.setMinutes(value.minutes);
                    this.handleDatePicked(d, true);
                  }
                }}
                validRange={{
                  startDate: new Date(1920, 1, 1),
                  endDate: new Date(),
                }}
                editable={true}
                style={[
                  {
                    width: isMobile ? "100%" : 300,
                  },
                ]}
              />
            </View>
            {this.state.timeError && (
              <Text style={[styles.please, { color: "red" }]}>
                Please select a time
              </Text>
            )}
            <View style={[{ width: isMobile ? "100%" : 300 }]}>
              <TextInputField
                label="Remarks"
                value={remarks}
                onChange={(remarks) => {
                  this.setState({
                    remarks,
                  });
                }}
                style={[{ ...styles.inputItem }]}
                editable={true}
              />
            </View>

            <TouchableOpacity
              onPress={this.onSubmitPress}
              style={{
                backgroundColor: "#410DAA",
                paddingHorizontal: 20,
                paddingVertical: 10,
                width: isMobile ? "100%" : 150,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 65,
                marginBottom: isMobile ? 500 : 0,
              }}
            >
              {this.state.btnLoading ? (
                <ActivityIndicator size={"small"} color={"#fff"} />
              ) : (
                <Text style={{ color: "#fff", width: "auto" }}>Continue</Text>
              )}
            </TouchableOpacity>
            {/* <Button
            uppercase={false}
            mode={"contained"}
            onPress={this.onSubmitPress}
            style={{
              marginLeft: isMobile ? "0%" : "0%",
              marginTop: 30,
              width: isMobile ? "100%" : "13.7%",
            }}
            loading={this.state.submitting}
            color={"#410DAA"}
          >
            <Text style={styles.next}>Confirm</Text>
          </Button> */}
          </View>
        </ScrollView>
        <CustomModal
          visible={this.state.showModal}
          showCloseButton={true}
          closeModal={() => {
            this.toggleModal();
          }}
        >
          {this.renderModalContent()}
        </CustomModal>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.MAIN_BACKGROUND,
    padding: 16,
  },
  borderContainer: {
    borderColor: "#ddd",
    borderWidth: 1,
    borderRadius: 4,
    height: 48,
    justifyContent: "center",
    marginBottom: 16,
    paddingHorizontal: 16,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  please: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3,
  },
});
