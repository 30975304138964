import React, { useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TextInput,
  TouchableOpacity,
  Platform,
  StatusBar,
} from "react-native";
import { md } from "../../../Responsive";
const { width } = Dimensions.get("window");
import {
  completeMinDetails,
  DataURIToBlob,
  getUserDetails,
  getWindowSize,
  isMobile,
  isWeb,
  navigateToTransaction,
} from "../../../utils";
import Header from "../header_after_login";
import Entypo from "@expo/vector-icons/Entypo";
import Progressbar from "../progressbar";
import {
  addBankAccount,
  getCustMandateAmt,
  createCustMandate,
  getCustomerInvestmentPlans,
  getCustomerInvestmentPlansAdmin,
  verifyIFSC,
  generateAgreementPdf,
} from "../../../api";
import { useFilePicker } from "use-file-picker";
import { ShowToast } from "../../../components/Common/Toast";
import ViewSampleImage from "../../../components/Common/ViewSampleImage";
import { SafeAreaView } from "react-native-safe-area-context";
import Checkbox from "expo-checkbox";
import Store from "../../../store";
import moment from "moment";
import axios from "axios";
import { CustomModal } from "../../../components/Common/CustomModal";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";

const ACCOUNT_TYPES = [
  {
    key: 1,
    label: "Savings",
    value: "SB",
  },
  {
    key: 2,
    label: "Current",
    value: "CB",
  },
  {
    key: 3,
    label: "Non Resident (NRE)",
    value: "NE",
  },
  {
    key: 4,
    label: "Non Resident Ordinary (NRO)",
    value: "NO",
  },
  {
    key: 5,
    label: "NRE SB",
    value: "NES",
  },
  {
    key: 6,
    label: "NRO SB",
    value: "NOS",
  },
];

const SelectChequeLeaf = ({ onSelect, viewSampleImage, file = "" }) => {
  const { openFilePicker, filesContent, loading, errors }= useFilePicker({
    readAs: "DataURL",
    accept: [".png", ".jpg", ".jpeg", ".pdf"],
    multiple: true,
    limitFilesConfig: { max: 1 },
    maxFileSize: 0.5,
  });

  useEffect(() => {
    onSelect(filesContent, errors);
  }, [filesContent]);

  if (loading) {
    return <Text>Loading</Text>;
  }

  const isFileSelected = () => {
    return filesContent.length != 0;
  };

  const checkSizeError = () => {
    if (errors && errors.length) {
      const error = errors[0];
      const { fileSizeToolarge } = error;
      return {
        fileSizeToolarge: true,
        error: true,
      };
    }
    return null;
  };

  const selectedFile = isFileSelected();
  const errorData = checkSizeError();

  return (
    <View
      style={{ flexDirection: "column", paddingTop: md(width) ? 31.2 : 25 }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 13.29,
        }}
      >
        <Text style={styles.upl_check}>
          Upload A Cancelled Cheque Leaf Image*
        </Text>
        <TouchableOpacity
          style={{ display: md(width) ? "none" : "none" }}
          onPress={() => {
            viewSampleImage && viewSampleImage();
          }}
        >
          <Text style={styles.view_sample}>View Sample Image</Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        onPress={() => openFilePicker()}
        style={{
          alignItems: "center",
          paddingVertical: 28,
          backgroundColor: "#f6f6f6",
          borderRadius: 4,
          // transform: [matrix(1, 0, 0, 1, 0, 0)],
          borderStyle: "dashed",
          borderWidth: 1,
          borderColor: "rgba(65, 13, 170, 0.5)",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Entypo name="attachment" size={20} color="#13C37B" />
          <Text style={styles.drop}>
            Click here to browse and attach files or replace files
          </Text>
        </View>
      </TouchableOpacity>
      {selectedFile ? (
        filesContent.map((file, index) => (
          <Text style={styles.fileName}>{file.name}</Text>
        ))
      ) : (
        <TouchableOpacity onPress={() => file && openFile(file)}>
          <Text style={styles.fileName}>{file || ""}</Text>
        </TouchableOpacity>
      )}
      <Text
        style={[
          styles.support,
          errorData &&
            errorData.error &&
            !errorData.fileSizeToolarge &&
            styles.error,
        ]}
      >
        Supports PNG, JPG, JPEG, PDF.{" "}
        <Text style={[errorData && errorData.fileSizeToolarge && styles.error]}>
          Max File Size 500KB
        </Text>
      </Text>
    </View>
  );
};

export default class bank_acc_info extends React.Component {
  constructor(props) {
    super(props);
    let user_id = null;
    const { params } = this.props.route;
    if (params) {
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    }
    let is_club = true;
    let { user_database } = Store.getState().userData.userProfileData;
    let hostname = Platform.OS == "web" ? global.location.hostname : user_database;
    if (hostname.includes("club")) {
      is_club = true;
    }
    this.state = {
      user_id,
      is_club,
      data: null,
      ifsc_code: null,
      email: null,
      account_number: null,
      account_type: null,
      ifsc_codeError: null,
      account_numberError: null,
      account_typeError: null,
      plan_code: null,
      plan_amount: null,
      is_mandate_approved: false,
      bank_acc_declaration: false,
      showModal: false,
    };
  }

  componentDidMount = async () => {
    let response = [];
    if (this.state.user_id != null) {
      response = await getCustomerInvestmentPlansAdmin(this.state.user_id);
    } else {
      response = await getCustomerInvestmentPlans();
    }

    if (
      response &&
      response[0] &&
      response[0].plan_code &&
      response[0].plan_amount
    ) {
      this.setState({
        plan_code: response[0].plan_code,
        plan_amount: response[0].plan_amount.toString(),
      });
    }
  };

  handleContinue = async () => {
    try {
      this.setState({ showToast: "false" });
      if (this.state.user_id) {
        let options = {};
        const { params } = this.props.route;
        if (params) {
          options = { ...params };
        }
        options["first"] = false;
        let url = new URL(global.location.href);
        location.replace(
          url.origin + "/dashboard/home?user_id=" + this.state.user_id
        );
        return;
      }
      let {
        username,
        email,
        cust_id,
        ifsc_code,
        bank_name,
        account_number,
        account_type,
        is_mandate_approved,
        bank_acc_declaration,
        plan_code,
        plan_amount,
        token,
      } = this.state;
      if (!cust_id || !username || !token) {
        const userData = getUserDetails();
        username = userData.username;
        cust_id = userData.customer_id;
        token = userData.token;
        email = userData.email;

        let fullEmail = email.split("@");
        let filteredEmail = fullEmail[0];
        let tempString = "";
        for (let i in filteredEmail) {
          if (i > 2 && i < filteredEmail.length - 1) {
            tempString += "*";
          } else {
            tempString += filteredEmail[i];
          }
        }

        email = tempString + "@" + fullEmail[1];
        this.setState({ email });
      }
      if (ifsc_code == null) {
        this.setState({
          ifsc_codeError: "Please enter IFSC Code for Your bank account",
        });
        return;
      }
      if (account_number == null) {
        this.setState({
          account_numberError: "Please enter your bank account number",
        });
        return;
      }
      if (account_type == null) {
        this.setState({
          account_typeError: "Please enter your bank account type",
        });
        return;
      }
      if (!this.state.imageURI) {
        this.setState({
          chequeLeafError: "Please select cheque leaf",
        });
        return;
      }

      let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
      // if (hostname.includes("myadvisor")) {
      //   await generateAgreementPdf();
      // }
      let body = new FormData();
      body.append("customer", cust_id);
      body.append("is_mandate_approved", is_mandate_approved);
      body.append("bank_acc_declaration", bank_acc_declaration);
      body.append("plan_code", plan_code);
      body.append("plan_amount", plan_amount);
      body.append("timestamp", moment().format("DD MMM YYYY hh:mm A"));
      body.append("ifsc_code", ifsc_code);
      body.append("bank_name", bank_name);
      body.append("account_number", account_number);
      body.append("account_type", account_type);
      const imageFile = DataURIToBlob(this.state.imageURI);
      body.append("cheque_image", imageFile, this.state.imageName);
      const response = await addBankAccount(body);
      if (response && response.bank_name) {
        if (is_mandate_approved) {
          let response = await getCustMandateAmt({
            previous_key: "customer_mandate",
            custpk: cust_id,
          });
          if (
            response &&
            response.result &&
            response.result[0] &&
            response.result[0].mandate_amount
          ) {
            let payload = {
              amount: response.result[0].mandate_amount,
              account_number: account_number,
              customer_id: cust_id,
            };

            const createMandateResp = await createCustMandate(payload);
            if (
              createMandateResp &&
              createMandateResp.code &&
              createMandateResp.code == "100"
            ) {
              this.setState({ showToast: "true" });
            }
          }
        }
        const { results } = response;
        const minDetailsResponse = await completeMinDetails();
        this.setState({ showModal: false });
        const { needCompletion, error } = minDetailsResponse;

        if (!error) {
          const { redirect_screen } = minDetailsResponse;
          let options = {};
          if (this.props.route) {
            const { params } = this.props.route;
            if (params) {
              options = { ...params };
            }
            if (!needCompletion) {
              if (options.transaction) {
                navigateToTransaction(
                  this.props.navigation,
                  options.transaction
                );
              } else if (options.action) {
                this.props.navigation.navigate("ComplianceSuccess", {
                  ...options,
                });
                return;
              } else {
                this.props.navigation.navigate("OnboardingSuccess", {
                  ...options,
                });
                return;
              }
            }
          }
          this.props.navigation.navigate(redirect_screen, { ...options });
        } else {
          this.setState({
            isLoading: false,
            error: true,
            errorText: "Something went wrong! Please contact help@maxiom.in",
          });
        }
      } else {
        this.setState({
          error: true,
          errorText: "Something went wrong!",
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  handleIFSCcode = (ifsc) => {
    // if (!ifsc || (ifsc && ifsc.length < 11)) return;
    try {
      this.setState(
        {
          ifsc_code: ifsc ? ifsc.toUpperCase() : ifsc,
        },
        async () => {
          if (ifsc && ifsc.length > 10) {
            const payload = {
              ifsc_code: ifsc,
            };
            const response = await verifyIFSC(payload);
            if (response && response.code == "100") {
              const { code, ...bankDetails } = response;
              this.setState({ ...bankDetails });
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  checkIfAllFieldsFilled = () => {
    let {
      ifsc_code,
      bank_name,
      account_number,
      account_type,
      bank_acc_declaration,
      imageURI,
    } = this.state;

    return (
      ifsc_code &&
      bank_name &&
      account_number &&
      account_type &&
      bank_acc_declaration &&
      imageURI
    );
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    const {
      viewSampleImage,
      bank_name,
      ifsc_code,
      email,
      account_number,
      account_type,
      bank_acc_declaration,
      ifsc_codeError,
      account_numberError,
      account_typeError,
      is_mandate_approved,
      chequeLeafError,
    } = this.state;
    const isValid = this.checkIfAllFieldsFilled();
    return (
      <SafeAreaView style={styles.container}>
        <Header navigation={this.props.navigation} route={this.props.route} />
        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title={`NACH mandate has been created for bill payments. Kindly approve by opening your registered email ${email}.`}
            duration="10"
          />
        ) : null}
        <Progressbar
          color2="#410DAA"
          color3="#410daa"
          color4="#410DAA"
          color5="#410DAA"
          color6="#410DAA"
          color7="#410DAA"
          color8="#410DAA"
          color9="#410DAA"
          color10="#410DAA"
          color11="#410DAA"
          color12="#f6f6f6"
          bcolor1="#410DAA"
          bcolor2="#410DAA"
          bcolor3="#410DAA"
          bcolor4="#410DAA"
          bcolor5="#410DAA"
          bagcolor1="#410DAA"
          bagcolor2="#410DAA"
          bagcolor3="#410DAA"
          bagcolor4="#410DAA"
          bagcolor5="#410DAA"
          markdisplay1="none"
          markdisplay2="none"
          markdisplay3="none"
          markdisplay4="none"
          markdisplay5="none"
          dotdisplay1="none"
          dotdisplay2="none"
          dotdisplay3="none"
          dotdisplay4="none"
          dotdisplay5="none"
        />
        <StatusBar backgroundColor="#000" barStyle="dark-content" />
        {viewSampleImage && (
          <ViewSampleImage
            uri="https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Cancelled+Cheque+Leaf+Sample.png"
            title="View Sample PAN image"
            onclose={() => {
              this.setState({ viewSampleImage: false });
            }}
          />
        )}
        <ScrollView style={styles.mainContainer}>
          <View style={styles.body}>
            {isWeb && (
              <View style={styles.left}>
                {/* <Text style={styles.terms}>Payment</Text> */}
                <Text style={[styles.please]}>
                  Regulation requires proof of fee payment using own bank
                  account.
                </Text>

                <Image
                  source={
                    "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-2.png"
                  }
                  style={styles.left_img}
                />
                <View
                  style={{
                    flexDirection: "row",
                    width: 430,
                    borderWidth: 1,
                    borderColor: "rgba(65, 13, 170, 0.2)",
                    backgroundColor: "#ffffff",
                    paddingVertical: 21,
                    paddingLeft: 20,
                    paddingRight: 6,
                    marginTop: 33,
                    marginBottom: 22,
                  }}
                >
                  <Image
                    source={
                      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/fluent_lock-shield-20-regular.png"
                    }
                    style={styles.lock}
                  />
                  <Text style={styles.jam_we}>
                    Information is encrypted using 256-bit keys and stored
                    securely
                  </Text>
                </View>
                <Text style={styles.any}>
                  Any Queries?{" "}
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("App", {
                        screen: "Dashboard",
                        params: {
                          screen: "Dashboard",
                          params: {
                            screen: "GetHelp",
                            params: {},
                          },
                        },
                      });
                    }}
                  >
                    <Text style={{ color: "#410DAA", fontWeight: "bold" }}>
                      Get Help
                    </Text>
                  </TouchableOpacity>
                </Text>
              </View>
            )}
            <View style={styles.right}>
              <Text style={styles.ban_acc}>Bank Account Details</Text>
              <Text
                style={{
                  fontSize: 14,
                  marginTop: -20,
                  textDecorationLine: "underline",
                  color: "#410DAA",
                  fontWeight: "bold",
                  fontSize: 14,
                  paddingLeft: 6,
                  textAlign: "right",
                  marginBottom: 10,
                }}
                onPress={() => {
                  this.props.navigation.navigate("OnboardingSuccess");
                }}
              >
                Skip Bank Details
              </Text>
              <View style={{ flexDirection: md(width) ? "row" : "column" }}>
                <View style={{ flex: 1, marginRight: md(width) ? 25 : 0 }}>
                  <Text style={styles.bank}>Your Bank’s IFSC Code*</Text>
                  <TextInput
                    placeholder={""}
                    value={ifsc_code}
                    style={styles.inp_txt}
                    onChangeText={this.handleIFSCcode}
                  />
                  <Text
                    style={[styles.isfc_bank, ifsc_codeError && styles.error]}
                  >
                    {ifsc_code ? "" : ifsc_codeError}
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.bank}>Bank Name*</Text>
                  <TextInput
                    placeholder={"Bank Name"}
                    //placeholderTextColor="#00000030"
                    value={bank_name}
                    style={styles.inp_txt}
                    editable={false}
                  />
                </View>
              </View>
              <View
                style={{
                  flexDirection: md(width) ? "row" : "column",
                  marginTop: 30,
                }}
              >
                <View style={{ flex: 1, marginRight: md(width) ? 25 : 0 }}>
                  <Text style={styles.bank}>Your Bank Account Number*</Text>
                  <TextInput
                    placeholder={"Enter Bank Account Number"}
                    style={styles.inp_txt}
                    value={account_number}
                    onChangeText={(text) =>
                      this.setState({ account_number: text })
                    }
                  />
                  <Text
                    style={[
                      styles.isfc_bank,
                      account_numberError && styles.error,
                    ]}
                  >
                    {account_number ? "" : account_numberError}
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.bank}>Account Type*</Text>
                  <PickerFieldSelect
                    data={ACCOUNT_TYPES}
                    value={account_type}
                    style={{
                      width: isMobile ? getWindowSize().width * 0.92 : 677,
                      paddingVertical: Platform.OS == "ios" ? 12 : 0,
                      paddingHorizontal: Platform.OS == "ios" ? 12 : 0,
                    }}
                    onChange={(itemValue) => {
                      this.setState({ account_type: itemValue });
                    }}
                    pickerStyle={[
                      {
                        color: "#121212",
                      },
                    ]}
                    type="select"
                    placeholder={{
                      label: "Select",
                      value: null,
                      color: "#9EA0A4",
                    }}
                  />
                  <Text
                    style={[
                      styles.isfc_bank,
                      account_typeError && styles.error,
                    ]}
                  >
                    {account_type ? "" : account_typeError}
                  </Text>
                </View>
              </View>
              <SelectChequeLeaf
                onSelect={(filesContent) => {
                  if (filesContent && filesContent.length > 0) {
                    const { content: imageURI, name: imageName } =
                      filesContent[0];
                    this.setState({
                      imageURI,
                      imageName,
                    });
                  }
                }}
                viewSampleImage={() => {
                  this.setState({ viewSampleImage: true });
                }}
                file={this.state.imageName}
              />
              <Text style={[styles.isfc_bank, chequeLeafError && styles.error]}>
                {this.state.imageURI ? "" : chequeLeafError}
              </Text>

              <View style={{ flexDirection: "row", paddingTop: 19.71 }}>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({
                      bank_acc_declaration: !this.state.bank_acc_declaration,
                    });
                  }}
                >
                  <Checkbox
                    value={bank_acc_declaration}
                    status={bank_acc_declaration ? "checked" : "unchecked"}
                    color={"#410DAA"}
                  />
                </TouchableOpacity>
                <Text style={{ flex: 0.95 }}>
                  In line with SEBI Investment Advisory Regulations 2013, I
                  hereby declare that given bank account details are my own.
                </Text>
              </View>
              {this.state.is_club ? null : (
                <>
                  <View style={{ flexDirection: "row", paddingTop: 19.71 }}>
                    <TouchableOpacity
                      onPress={() => {
                        this.setState({
                          is_mandate_approved: !this.state.is_mandate_approved,
                        });
                      }}
                    >
                      <Checkbox
                        value={is_mandate_approved}
                        status={is_mandate_approved ? "checked" : "unchecked"}
                        color={"#410DAA"}
                      />
                    </TouchableOpacity>
                    <Text style={{ flex: 0.95 }}>
                      I wish to create a NACH mandate for bill payments. Mandate
                      shall be for an amount not exceeding the bills as per the
                      agreement. I understand that i can cancel the mandate at
                      any time by emailing help@maxiomwealth.com or by contacting
                      my bank.
                    </Text>
                  </View>
                </>
              )}
              {this.state.user_id ? (
                <TouchableOpacity
                  onPress={() => {
                    this.handleContinue();
                  }}
                >
                  <Text style={styles.viewModeText}>
                    Go to Dashboard {">>>"}
                  </Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    if (this.state.bank_acc_declaration) {
                      if (
                        !this.state.is_mandate_approved &&
                        !this.state.is_club
                      ) {
                        this.setState({ showModal: true });
                      } else {
                        isValid && this.handleContinue();
                      }
                    }
                  }}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 40.7,
                  }}
                >
                  <Text
                    style={[
                      styles.next,
                      { backgroundColor: !isValid ? "#CDCDCD" : "#410DAA" },
                    ]}
                  >
                    Continue
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </ScrollView>
        <CustomModal
          visible={this.state.showModal}
          showCloseButton={true}
          closeModal={() => {
            this.toggleModal();
          }}
        >
          <View style={{ padding: 50, paddingTop: 20 }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: 15,
                color: "#121212",
              }}
            >
              You have not opted for NACH Mandate for bill payment.{"\n"}
              Mandate makes fee payment simple. you can cancel anytime.
            </Text>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.setState({ is_mandate_approved: true });
                  isValid && this.handleContinue();
                }}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 15,
                    lineHeight: 17,
                    color: "#ffffff",
                    fontWeight: "bold",
                    paddingVertical: 12,
                    paddingHorizontal: 19,
                    backgroundColor: "#410DAA",
                    borderRadius: 4,
                    marginBottom: 20,
                    marginTop: 10,
                  }}
                >
                  Create Mandate & Continue
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  isValid && this.handleContinue();
                }}
              >
                <Text
                  style={{
                    color: "#410DAA",
                    fontSize: 15,
                    textDecorationLine: "underline",
                    fontWeight: 700,
                  }}
                >
                  Continue Without Mandate
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </CustomModal>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1 },
  mainContainer: {},
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: md(width) ? 130 : 17,
    paddingRight: md(width) ? 150 : 14,
    paddingBottom: isMobile ? 120 : 60,
  },
  left: {
    flex: 0.45,
    paddingTop: 55,
    // display: md(width) ? "none" : "none",
  },
  right: {
    marginTop: 50,
    flex: md(width) ? 0.55 : 1,
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },
  lock: {
    resizeMode: "contain",
    width: 30,
    height: 30,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 21,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 15 : 11,
    lineHeight: 18,
    color: "#464E5F",
    paddingTop: 12,
    paddingBottom: md(width) ? 70 : 10,
    paddingRight: md(width) ? 160 : 0,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  ban_acc: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 21,
    color: "#2F3044",
    paddingTop: 16,
    paddingBottom: 21,
    display: md(width) ? "none" : "flex",
  },
  ban_acc1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#2F3044",
    paddingTop: 45,
    paddingBottom: 9,
    display: md(width) ? "none" : "flex",
  },
  bank: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 16 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    paddingBottom: md(width) ? 6.3 : 10.5,
    fontWeight: "bold",
    paddingTop: md(width) ? 0 : 25,
  },
  inp_txt: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    color: "#121212",
    paddingVertical: 13.5,
    paddingHorizontal: 19,
    backgroundColor: "#F6F6F6",
    borderRadius: 4,
  },
  isfc_bank: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  acc_num: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#222225",
    fontWeight: "bold",
    paddingBottom: 6.29,
    paddingTop: 31,
  },
  view_sample: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#8950FC",
  },
  drop: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#121212",
    marginLeft: 6,
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 20,
    marginTop: 20,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
    paddingHorizontal: 20,
  },
  next: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    fontWeight: "bold",
    paddingVertical: 12,
    paddingHorizontal: 79,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  upl_check: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 16 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    fontWeight: "bold",
  },
  fileName: {
    fontSize: 15,
    marginTop: 5,
    color: "#410DAA",
    fontWeight: "bold",
  },
  support: {
    fontSize: 10,
    color: "#121212",
    fontStyle: "italic",
    marginTop: 4,
  },
  error: { color: "red" },
});
