import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { isAboveIpad, isMobile } from '../../../utils';
import Header from '../../onboarding/header_after_login';
import Progressbar from '../../onboarding/progressbar';
import { ProgressbarSTU } from '../../onboarding/ProgressBarSTU';
import AddProfessionalDetails from '../account/AddProfessionalDetails';
import DetailsCard from './detailsCard';

const data = {
    title: "Financial Information",
    description: "Required per FATCA regulation",
    image: "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-1.png",
};

const MinDetailsProfessional = (props) => {

    useEffect(() => {

    },[])

    return(
        <View style={styles.container}>
            <Header navigation={props.navigation} route={props.route} />
            <ProgressbarSTU currentScreen="MinDetailsProfessional" flow={"MF"}/>
            <View style={styles.body}>
                { isAboveIpad &&(
                    <View style={styles.left}>
                        <DetailsCard data={data}/>
                    </View>
                )}
                <View style={styles.right}>
                    <AddProfessionalDetails {...props} minDetailsFlow={true}/>
                </View>
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff"
    },
    body: {
        flexDirection: "row",
        flex: 1,
        paddingLeft: !isMobile ? "5%" : 13,
        paddingRight: !isMobile ? "5%" : 13,
    },
    left: {
        flex: 0.5,
        paddingTop: 55,
    },
    right: {
        flex: !isAboveIpad ? 1 : 0.5
    }
});

export default MinDetailsProfessional;