import React, { PureComponent } from "react";
import {
  View,
  StyleSheet,
  SafeAreaView,
  Platform,
  ScrollView,
  StatusBar,
} from "react-native";
import FIcon from "@expo/vector-icons/FontAwesome";
import { connect } from "react-redux";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import moment from "moment";
import TextInputField from "../../../components/Common/Inputs/InputField";
import HorizontalRadio from "../../../components/Common/Radio/HorizontalRadio";
import { Button, Text } from "react-native-paper";
import Store  from "../../../store";
import theme from "../../../static/theme";
import {
  getUserDetails,
  isMobile,
  months_short_names,
  redirectToPendingScreen,
} from "../../../utils";
import DatePickerField from "../../../components/Common/Pickers/DatePickerField";
import {
  saveCustomerData,
  saveCustomerProfileData,
} from "../../../api/account";
import { validateInput } from "../../../utils/validateInput";
import Toast from "react-native-toast-message";
import amplitude from "../../../utils/amplitude";

class AddPersonal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      dob: "",
      email: "",
      mobile: "",
      nationality: "",
      gender: null,
      maritalStatus: null,
      nomineeName: "",
      nomineeRelation: "",
      showDate: false,
      saving: false,
    };
  }

  renderAsterisk = () => (
    <FIcon
      name={"asterisk"}
      size={8}
      color={theme.colors.NEW_BRAND_COLOR}
      style={{
        textAlignVertical: "top",
        paddingVertical: 8,
      }}
    />
  );

  inputValueUpdater = (key, value) => {
    this.setState({
      [key]: value,
      error: "",
    });
  };

  onSubmitPress = async () => {
    const { name, dob, email, mobile, gender, maritalStatus } = this.state;

    if (!name || (name && name.length <= 0)) {
      Toast.show({
        text1: "Please enter your name",
        visibilityTime: 2 * 1000,
        topOffset: 7,
        type: "success",
      });
      return;
    }
    if (!dob || (dob && dob.length <= 0)) {
      Toast.show({
        text1: "Please select your Date of birth",
        visibilityTime: 2 * 1000,
        topOffset: 7,
        type: "success",
      });
      return;
    }
    if (!mobile || (mobile && mobile.length <= 0)) {
      Toast.show({
        text1: "Please enter your mobile number",
        visibilityTime: 2 * 1000,
        topOffset: 7,
        type: "success",
      });
      return;
    }

    if (!email || (email && !validateInput("email", email))) {
      Toast.show({
        text1: "Please enter valid email address",
        visibilityTime: 2 * 1000,
        topOffset: 7,
        type: "success",
      });
      return;
    }
    if (gender == null) {
      Toast.show({
        text1: "Please select your gender",
        visibilityTime: 2 * 1000,
        topOffset: 7,
        type: "success",
      });
      return;
    }

    if (maritalStatus == null) {
      Toast.show({
        text1: "Please select your marital status",
        visibilityTime: 2 * 1000,
        topOffset: 7,
        type: "success",
      });
      return;
    }

    this.setState({ saving: true, error: null });

    try {
      const { customer_id, username, customerprofile_id } = getUserDetails();
      const payload = {
        username: username,
        gender: gender,
        first_name: name,
        date_of_birth: dob,
        mobilenumber: mobile,
        email: email,
      };
      const payload_profile = {
        customer: customer_id,
        is_married: maritalStatus == "02" ? false : true,
      };
      const userDta = getUserDetails();
      const response = await saveCustomerData(customer_id, payload);
      try {
        await saveCustomerProfileData(customerprofile_id, payload_profile);
      } catch (err) {}
      if (response && response.hasOwnProperty("username")) {
        Store.dispatch({
          type: "SAVE_PROFILE_DATA",
          payload: response,
        });
        if (this.props.minDetailsFlow) {
          const redirect = await redirectToPendingScreen(this.props);
          if (redirect && redirect.error) {
          }
        } else {
          Toast.show({
            text1: "Saved Successfully",
            visibilityTime: 2 * 1000,
            topOffset: 7,
            type: "success",
          });
          setTimeout(() => {
            this.props.navigation.goBack();
          }, 1000);
        }
      }

      this.setState({ saving: false });
    } catch (error) {
      this.setState({ saving: false });
    }
  };

  componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("AddPersonalDetails", {
          path: global.location.href,
        });
      } else {
        amplitude.logEvent("AddPersonalDetails", {
          path: this.props.navigation.getState().routeName,
        });
      }
      const customerDetails = { ...getUserDetails() };
      if (customerDetails) {
        const dob_formatted = this.formatDoB(customerDetails.date_of_birth);
        this.setState({
          name: customerDetails.first_name,
          dob: customerDetails.date_of_birth,
          dob_formatted,
          email: customerDetails.email,
          gender: customerDetails.gender,
          maritalStatus:
            customerDetails.marital_status == "married" ||
            customerDetails.marital_status == "01"
              ? "01"
              : "02",
          nomineeName: customerDetails.customer_nominee_name,
          nomineeRelation: customerDetails.customer_nominee_relation,
          mobile: customerDetails.mobilenumber,
        });
      }
    } catch (error) {}
  }

  formattedNumber = (number) => ("0" + number).slice(-2);

  _handleDatePicked = (date) => {
    const day = this.formattedNumber(date.getDate());
    const month = parseInt(date.getMonth()) + 1;
    const year = date.getFullYear();
    const dob = year + "-" + month + "-" + day;
    const dob_formatted = moment(date).format("DD[/]MM[/]YYYY");

    this.setState({
      dob,
      dob_formatted,
    });

    this._hideDateTimePicker();
  };

  formatDoB = (dob) => {
    if (dob) {
      const dob_values = dob.split("-");
      dob = dob_values[2] + "/" + dob_values[1] + "/" + dob_values[0];
    }
    return dob;
  };

  _hideDateTimePicker = () => this.setState({ showDate: false });

  render() {
    const { container } = styles;
    const { name, mobile, gender, dob_formatted, email, maritalStatus } =
      this.state;

    return (
      <SafeAreaView style={container}>
        {!this.props.minDetailsFlow && (
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Personal Details"]}
          />
        )}
        <StatusBar backgroundColor={"#410DAA"} barStyle="light-content" />
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, paddingBottom: 50 }}
          showsVerticalScrollIndicator={true}
        >
          <View style={{ marginHorizontal: 16, marginTop: 20 }}>
            {!isMobile ? (
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                  <TextInputField
                    label="Full Name*"
                    value={name}
                    onChange={(text) => {
                      this.setState({
                        name: text,
                      });
                    }}
                    style={[{ ...styles.inputItem }]}
                    inputStyle={[{ paddingLeft: 20 }]}
                    editable={true}
                  />
                </View>
                <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                  <DatePickerField
                    label="Date of Birth(DD/MM/YYYY)*"
                    value={dob_formatted}
                    onSelect={(value) => {
                      this._handleDatePicked(new Date(value.date));
                    }}
                    validRange={{
                      startDate: new Date(1920, 1, 1),
                      endDate: new Date(),
                    }}
                    editable={true}
                    style={[
                      {
                        width: isMobile ? "100%" : 300,
                      },
                    ]}
                  />
                </View>
              </View>
            ) : (
              <>
                <View style={[styles.formItem, styles.itemMargin]}>
                  <Text
                    style={{ color: "red", marginLeft: 10, marginTop: -35 }}
                  >
                    {this.state.error || " "}
                  </Text>
                  <TextInputField
                    label="Full Name*"
                    value={name}
                    onChange={(text) => {
                      this.setState({
                        name: text,
                      });
                    }}
                    style={[{ ...styles.inputItem }]}
                    editable={true}
                  />
                </View>
                <View style={[styles.formItem, styles.itemMargin]}>
                  <DatePickerField
                    label="Date of Birth(DD/MM/YYYY)*"
                    value={dob_formatted}
                    onSelect={(value) => {
                      this._handleDatePicked(new Date(value.date));
                    }}
                    validRange={{
                      startDate: new Date(1920, 1, 1),
                      endDate: new Date(),
                    }}
                    editable={true}
                    style={[
                      {
                        width: isMobile ? "100%" : 300,
                      },
                    ]}
                  />
                </View>
              </>
            )}
            {!isMobile ? (
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                  <TextInputField
                    label="Email Id*"
                    value={email}
                    onChange={(text) => {
                      this.setState({
                        email: text,
                      });
                    }}
                    style={[{ ...styles.inputItem }]}
                    inputStyle={[{ paddingLeft: 20 }]}
                    editable={false}
                  />
                </View>
                <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                  <TextInputField
                    label="Mobile Number*"
                    value={mobile}
                    onChange={(text) => {
                      if (!isNaN(text)) {
                        this.setState({
                          mobile: text,
                        });
                      }
                    }}
                    style={[{ ...styles.inputItem }]}
                    editable={false}
                  />
                </View>
              </View>
            ) : (
              <>
                <View style={[styles.formItem, styles.itemMargin]}>
                  <TextInputField
                    label="Email Id*"
                    value={email}
                    onChange={(text) => {
                      this.setState({
                        email: text,
                      });
                    }}
                    style={[{ ...styles.inputItem }]}
                    inputStyle={[{ paddingLeft: 20 }]}
                    editable={false}
                  />
                </View>
                <View style={[styles.formItem, styles.itemMargin]}>
                  <TextInputField
                    label="Mobile Number*"
                    value={mobile}
                    onChange={(text) => {
                      if (!isNaN(text)) {
                        this.setState({
                          mobile: text,
                        });
                      }
                    }}
                    style={[{ ...styles.inputItem }]}
                    editable={false}
                  />
                </View>
              </>
            )}

            <View style={[styles.formItem, styles.itemMargin]}>
              <HorizontalRadio
                title={"Gender*"}
                style={{
                  paddingVertical: 0,
                  marginVertical: 0,
                }}
                selected={gender}
                radioButtonValues={["Male", "Female"]}
                values={["M", "F"]}
                onSelect={(index, value) => {
                  this.setState({
                    gender: value,
                  });
                }}
              />
            </View>

            <View style={[styles.formItem, styles.itemMargin]}>
              <HorizontalRadio
                title={"Marital Status*"}
                style={{
                  paddingVertical: 0,
                  marginVertical: 0,
                }}
                selected={maritalStatus}
                radioButtonValues={["Single", "Married"]}
                values={["02", "01"]}
                onSelect={(index, value) => {
                  this.setState({
                    maritalStatus: value,
                  });
                }}
              />
            </View>
          </View>
          {!isMobile ? (
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={this.onSubmitPress}
              style={{
                width: "20%",
                left: "40%",
                right: "40%",
              }}
              loading={this.state.saving}
              color={"#410DAA"}
            >
              <Text style={styles.next}>Save</Text>
            </Button>
          ) : (
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={this.onSubmitPress}
              style={{
                width: "40%",
                marginLeft: "30%",
              }}
              loading={this.state.saving}
              color={"#410DAA"}
            >
              <Text style={styles.next}>Save</Text>
            </Button>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.MAIN_BACKGROUND,
  },
  nextButton: {
    width: "96%",
    maxWidth: 400,
    marginBottom: 15,
    paddingHorizontal: 16,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export default connect(null)(AddPersonal);
