import React from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { PieChart } from "react-native-chart-kit";
import Divider from "../../../components/Common/divider";
import { isMobile } from "../../../utils";
import EvilIcon from "@expo/vector-icons/EvilIcons";

const NetworthItem = ({ title, percentage, value, color, style }) => {
  return (
    <View style={{ flexDirection: "row", ...style, alignItems: "center" }}>
      <View style={[networthStyles.body, { backgroundColor: color }]}>
        <Text style={networthStyles.title}>{title}</Text>
        <Text style={networthStyles.percentage}>{percentage.toFixed(1)}%</Text>
      </View>
      <Text style={networthStyles.value}>{value}</Text>
    </View>
  );
};

const networthStyles = StyleSheet.create({
  body: {
    flex: 0.48,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 6,
    paddingVertical: 3,
    marginLeft: 4,
    borderRadius: 5,
  },
  title: {
    fontSize: 12,
    flex: 1,
    color: "#121212",
    alignSelf: "flex-start",
  },
  percentage: {
    fontSize: 11,
    color: "#121212",
    alignSelf: "center",
  },
  value: {
    flex: 0.52,
    fontSize: 15,
    color: "#121212",
    paddingLeft: 12,
    textAlign: "right",
  },
});

const chartConfig = {
  backgroundGradientFrom: "#1E2923",
  backgroundGradientFromOpacity: 0,
  backgroundGradientTo: "#08130D",
  backgroundGradientToOpacity: 0.5,
  color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
  strokeWidth: 2,
  barPercentage: 0.5,
  useShadowColorFromDataset: false,
};

const PortfolioSummaryCard = ({
  portfolio: { data = {}, value },
  navigation,
}) => {
  const handlePress = () => {
    navigation.navigate("Portfolio", { from: "Home" });
  };

  const { perc = [], colors = [] } = data;
  const chartData = perc.map((value, index) => {
    return {
      value: value,
      color: colors[index],
      legendFontColor: colors[index],
      legendFontSize: 15,
    };
  });

  return (
    <TouchableOpacity style={styles.container} onPress={handlePress}>
      <View style={styles.body}>
        <View style={{ flex: 0.8, flexDirection: "row" }}>
          <View style={styles.headingContainer}>
            <View style={styles.headingLegend} />
          </View>
          <Text style={styles.value}>{value}</Text>
        </View>
        <View
          style={{
            flex: 0.2,
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <EvilIcon
            name="arrow-right"
            style={{ fontSize: 35, color: "#410DAA" }}
          />
        </View>
      </View>
      <Divider width={0.5} color="#410DAA1A" />
      <View style={styles.pieChartContainer}>
        <View style={styles.pieChart}>
          <PieChart
            data={chartData}
            width={!isMobile ? 150 : 95}
            height={!isMobile ? 150 : 95}
            chartConfig={chartConfig}
            accessor={"value"}
            backgroundColor={"#fff"}
            center={[0.27 * 95, 0]}
            absolute
            radius={0.23 * 95 || 32}
            hasLegend={false}
          />
        </View>
        <View style={{ flex: 1 }}>
          {data.title.map((title, index) => (
            <NetworthItem
              key={title}
              title={title}
              value={data.values[index]}
              percentage={data.perc[index]}
              style={{ marginVertical: 4 }}
              color={data.colors[index]}
            />
          ))}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 16,
    borderColor: "#410DAA1A",
    borderWidth: 0.5,
    borderRadius: 10,
    marginVertical: 0,
  },
  body: {
    flexDirection: "row",
    paddingVertical: 12,
    paddingHorizontal: 15,
    alignItems: "center",
  },
  headingContainer: {
    width: 16,
    height: 16,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#410DAA",
  },
  headingLegend: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: "#200752",
  },
  value: { paddingLeft: 10, fontSize: 18, color: "#121212", marginTop: -4 },
  pieChartContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 15,
    paddingLeft: 8,
    paddingRight: 8,
  },
  pieChart: {
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8,
  },
});

export default PortfolioSummaryCard;
