import React from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Linking,
} from "react-native";
import { isMobile, numberWithCommas, toFixed } from "../../../../utils";
import { Popable } from "react-native-popable";
import Icon from "@expo/vector-icons/FontAwesome";

const Product = ({
  style,
  content,
  buttonPress,
  active = false,
  totalReturns,
  data,
}) => {
  const {
    image = null,
    title,
    volatality,
    strategy,
    descriptions,
    navigateTo,
    cagr,
    three_year_return,
    total_returns_perc,
  } = content;

  const {
    current_value,
    current_investment,
    dividend,
    cash_in_hand,
    total_returns_amount,
    product_xirr,
  } = data;

  return (
    <View
      style={[
        styles.productContainer,
        Platform.OS == "web" && webStyles.webShadowBorder,
        { ...style },
      ]}
    >
      <View style={styles.topContainer}>
        <View style={styles.details}>
          <View style={styles.titleContainer}>
            <Image
              source={{
                uri:
                  image ||
                  "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Jewel.png",
              }}
              style={styles.image}
            />
            <Text style={styles.title}>{title}</Text>
            <View
              style={[
                styles.volatalityContainer,
                { backgroundColor: volatality.backgroundColor },
              ]}
            >
              <Text style={[styles.volatality, { color: volatality.color }]}>
                {volatality.title}
              </Text>
            </View>
          </View>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              buttonPress && buttonPress(navigateTo);
            }}
          >
            <Text style={styles.buttonText}>
              {active ? "View Plans" : "Start New Plan"}
            </Text>
          </TouchableOpacity>
        </View>
        {active && <Text style={styles.strategy}>{strategy || ""}</Text>}
      </View>
      {active ? (
        <View style={styles.activePlansInfoContainer}>
          <View style={styles.infoRow}>
            <View>
              <View style={styles.fieldContainer}>
                <Text style={styles.infoTitle}>Current Value</Text>
                <Text style={styles.infoValue}>
                  ₹{numberWithCommas(toFixed(current_value, 0))}
                </Text>
              </View>
              <View style={[styles.fieldContainer, styles.marginTop]}>
                <Popable
                  action={isMobile ? "press" : "hover"}
                  content={`Gain amount of ₹${numberWithCommas(
                    toFixed(total_returns_amount - dividend, 0)
                  )} plus dividend of ₹${numberWithCommas(
                    toFixed(dividend, 0)
                  )}`}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#6C7293",
                        fontWeight: "600",
                        marginBottom: 2,
                      }}
                    >
                      Total Gain{" "}
                    </Text>
                    <Icon name="info-circle" size={14} color={"black"} />
                  </View>
                </Popable>
                <Text style={styles.infoValue}>
                  ₹{numberWithCommas(toFixed(total_returns_amount, 0))}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.infoRow, styles.center]}>
            <View>
              <View style={styles.fieldContainer}>
                <Text style={styles.infoTitle}>Invested</Text>
                <Text style={styles.infoValue}>
                  ₹{numberWithCommas(toFixed(current_investment, 0))}
                </Text>
              </View>
              <View style={[styles.fieldContainer, styles.marginTop]}>
                <Text style={styles.infoTitle}>Total Gain %</Text>
                <Text style={[styles.infoValue, { color: "#464E5F" }]}>
                  {totalReturns.toFixed(1)}%
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.infoRow, styles.end]}>
            <View>
              <View style={styles.fieldContainer}>
                <Popable
                  action={isMobile ? "press" : "hover"}
                  content={`The difference between planned amount vs the amount invested so far.`}
                  style={{ minWidth: 160 }}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#6C7293",
                        fontWeight: "600",
                        marginBottom: 2,
                      }}
                    >
                      Cash in Hand{" "}
                    </Text>
                    <Icon name="info-circle" size={14} color={"black"} />
                  </View>
                </Popable>
                <Text style={styles.infoValue}>
                  ₹{numberWithCommas(toFixed(cash_in_hand, 0))}
                </Text>
              </View>
              <View style={[styles.fieldContainer, styles.marginTop]}>
                <Text style={styles.infoTitle}>Yearly XIRR %</Text>
                <Text style={styles.infoValue}>{product_xirr.toFixed(1)}%</Text>
              </View>
            </View>
          </View>
        </View>
      ) : (
        <View style={styles.descriptionContainer}>
          {descriptions.map((description) => {
            return (
              <View style={styles.featureContainer}>
                <Text style={styles.featureBullet}>•</Text>
                <Text style={styles.feature}>{description}</Text>
              </View>
            );
          })}
          <View
            style={[
              styles.activePlansInfoContainer,
              styles.infoFieldsContainer,
            ]}
          >
            <View style={styles.infoRow}>
              <View>
                <Text style={styles.infoTitle}>CAGR</Text>
                <Text style={styles.infoField}>+{cagr.toFixed(1)}%</Text>
              </View>
            </View>
            <View style={[styles.infoRow, styles.center]}>
              <View>
                <Text style={styles.infoTitle}>3Y Return</Text>
                <Text style={styles.infoField}>
                  +{three_year_return.toFixed(1)}%
                </Text>
              </View>
            </View>
            <View style={[styles.infoRow, styles.end]}>
              <View>
                <Text style={styles.infoTitle}>Total Returns</Text>
                <Text style={styles.infoField}>
                  +{total_returns_perc.toFixed(1)}%
                </Text>
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

const staticData = {
  JEWEL: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Jewel.png",
    title: "JEWEL",
    strategy: "Selected from top 350/1000 companies",
    volatality: {
      title: "Medium Volatality",
      color: "#0BB783",
      backgroundColor: "#0BB78310",
    },
    descriptions: [
      "Top 350 Stocks, Marketcap > $1b",
      "Low Debt, High ROE, ROA, Growing companies",
    ],
    navigateTo: "MyEquityInvestmentPlans",
    value: "JWL",
    cagr: 26.5,
    three_year_return: 26.5,
    total_returns_perc: 26.5,
  },
  PEARL: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/pearl.png",
    title: "PEARL",
    strategy: "LargeCap Growth Strategy",
    volatality: {
      title: "Low Volatality",
      color: "#0BB783",
      backgroundColor: "#0BB78310",
    },
    descriptions: [
      "Top 100 Companies",
      "Momentum play, Decent fundamentals (ROE, Growth, Management)",
    ],
    navigateTo: null,
    value: "PRL",
  },
  SPARK: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Spark+(2).png",
    title: "SPARK",
    strategy: "Selected from next 650/1000 companies",
    volatality: {
      title: "High Volatility",
      color: "#EF5D12",
      backgroundColor: "#FFF5F1",
    },
    descriptions: [
      "Smallcaps with marketcap 500cr to 8000cr",
      "Low debt, High ROE, Growing Companies with runway",
    ],
    navigateTo: null,
    value: "SRK",
    cagr: 26.5,
    three_year_return: 26.5,
    total_returns_perc: 26.5,
  },
};

const staticDataNew = {
  JWL: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Jewel.png",
    title: "JEWEL",
    strategy: "Selected from top 350/1000 companies",
    volatality: {
      title: "Medium Volatality",
      color: "#0BB783",
      backgroundColor: "#0BB78310",
    },
    descriptions: [
      "Top 350 Stocks, Marketcap > $1b",
      "Low Debt, High ROE, ROA, Growing companies",
    ],
    navigateTo: "MyEquityInvestmentPlans",
    value: "JWL",
    cagr: 32.63,
    three_year_return: 40.93,
    total_returns_perc: 22.48,
  },
  PRL: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/pearl.png",
    title: "PEARL",
    strategy: "LargeCap Growth Strategy",
    volatality: {
      title: "Low Volatality",
      color: "#0BB783",
      backgroundColor: "#0BB78310",
    },
    descriptions: [
      "Top 100 Companies",
      "Momentum play, Decent fundamentals (ROE, Growth, Management)",
    ],
    navigateTo: null,
    value: "PRL",
  },
  SRK: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Spark+(2).png",
    title: "SPARK",
    strategy: "Selected from next 650/1000 companies",
    volatality: {
      title: "High Volatility",
      color: "#EF5D12",
      backgroundColor: "#FFF5F1",
    },
    descriptions: [
      "Smallcaps with marketcap 500cr to 8000cr",
      "Low debt, High ROE, Growing Companies with runway",
    ],
    navigateTo: null,
    value: "SRK",
    cagr: 32.63,
    three_year_return: 40.93,
    total_returns_perc: 32.63,
  },
};

export const EquityPlans = ({
  style,
  navigation,
  data,
  userId,
  isNew,
  showInVertical = false,
}) => {
  let products = Object.keys(data);
  const staticDataToUse = isNew ? staticDataNew : staticData;
  let productsToShow = [];
  if (products.indexOf("PRL") != -1) {
    productsToShow = ["PRL", "SRK"];
  } else {
    productsToShow = ["JWL", "SRK"];
  }
  return (
    <View
      style={[
        styles.container,
        { flexDirection: showInVertical ? "column" : "row", ...style },
      ]}
    >
      {productsToShow.map((product, index) => {
        let { invested_amount, current_value, total_returns_perc } =
          data[product] || {};

        let is_exists = product in data;

        return (
          <Product
            style={{
              marginRight: index == 0 ? 10 : 0,
              width: isMobile ? "100%" : showInVertical ? "100%" : "50%",
              marginBottom: showInVertical && product == "JWL" ? 81 : 0,
              flex: 1,
            }}
            buttonPress={() => {
              if (!is_exists) {
                if (Platform.OS == "web") {
                  global.open("https://maxiomwealth.com/contact-us/", "_blank");
                } else {
                  Linking.openURL("https://maxiomwealth.com/contact-us/");
                }
              } else {
                const filter = staticDataToUse[product].value;
                let options = {};
                if (userId) {
                  options["user_id"] = userId;
                }
                if (isMobile) {
                  navigation.push("App", {
                    screen: "Dashboard",
                    params: {
                      screen: "Plans",
                      params: {
                        screen: "MyEquityInvestmentPlans",
                        params: { filter, ...options },
                      },
                    },
                  });
                } else {
                  navigation.push("App", {
                    screen: "Plans",
                    params: {
                      screen: "MyEquityInvestmentPlans",
                      params: { filter, ...options },
                    },
                  });
                }
              }
            }}
            content={staticDataToUse[product]}
            key={product}
            active={is_exists}
            data={is_exists ? data[product] : {}}
            currentValue={current_value}
            invested={invested_amount}
            totalReturns={total_returns_perc}
            navigation={navigation}
          />
        );
      })}
    </View>
  );
};

const webStyles = {
  webShadowBorder: {},
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: isMobile ? 0 : 40,
    flexDirection: isMobile ? "column" : "row",
    paddingHorizontal: isMobile ? 0 : 0,
    marginRight: isMobile ? 0 : 0,
    paddingTop: isMobile ? 20 : 0,
  },
  productContainer: {
    width: isMobile ? "100%" : "32.2%",
    borderColor: isMobile ? "#EEE5FF" : "#cdcdcb",
    borderRadius: isMobile ? 12 : 18,
    paddingTop: 15,
    paddingBottom: isMobile ? 0 : 12,
    borderWidth: 1,
    marginBottom: isMobile ? 15 : 0,
  },
  volatalityContainer: {
    backgroundColor: "#FFF0E8",
    marginLeft: isMobile ? 9 : 15,
  },
  volatality: {
    fontSize: isMobile ? 9 : 10,
    color: "#EF5D12",
    fontStyle: "italic",
    paddingVertical: isMobile ? 5 : 6,
    paddingHorizontal: isMobile ? 14 : 18,
    fontWeight: "bold",
  },
  topContainer: {
    paddingHorizontal: 15,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: isMobile ? 15 : 16,
    color: "#464E5F",
    fontWeight: "bold",
    fontFamily: "Roboto",
    paddingTop: 3,
  },
  image: {
    width: 25,
    height: 24,
    resizeMode: "contain",
    marginRight: 6,
  },
  strategy: {
    fontSize: 13,
    color: "#888C9F",
    fontStyle: "italic",
    marginTop: 13,
  },
  activePlansInfoContainer: {
    marginTop: isMobile ? 20 : 30,
    backgroundColor: "#F6F6F660",
    paddingHorizontal: 15,
    paddingVertical: 6,
    flexDirection: "row",
  },
  infoContainer: {
    flex: 1,
    paddingVertical: 6,
    flexDirection: "row",
  },
  infoRow: {
    flex: 0.33,
    alignItems: "flex-start",
  },
  fieldContainer: {
    paddingVertical: 6,
  },
  marginTop: {
    marginTop: 16,
  },
  center: { alignItems: "center" },
  end: { alignItems: "flex-end" },
  infoFieldsContainer: {
    marginTop: 0,
    paddingTop: 4,
  },
  infoTitle: {
    fontSize: 12,
    color: "#6C7293",
    fontWeight: "600",
    marginBottom: 8,
  },
  infoField: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  infoValue: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  button: {
    alignSelf: "center",
  },
  buttonText: {
    fontSize: isMobile ? 13 : 15,
    color: "#410DAA",
    fontWeight: "bold",
    lineHeight: 23,
    textDecorationLine: isMobile ? "underline" : "none",
  },
  featureContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingHorizontal: 18,
    marginBottom: 12,
  },
  feature: {
    color: "#464E5F",
    fontFamily: "Roboto",
    fontSize: 13,
  },
  featureBullet: {
    color: "#410DAA",
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "bold",
    paddingRight: 5,
  },
  details: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  descriptionContainer: {
    marginTop: 12,
  },
});
