import React from "react";
import { View, StyleSheet } from "react-native";
import { Text, Button } from "react-native-paper";

export const ModalContent = ({
  onMakeFresInvestment,
  onAttachExisting,
  isAtRisk,
  text1,
  text2,
}) => {
  return (
    <View
      style={{
        ...styles.modalContent,
      }}
    >
      <Text
        style={{
          color: "#121212",
          fontWeight: "500",
          fontSize: 14,
          paddingBottom: 10,
        }}
      >
        {isAtRisk ? "De-Risk your Goal Now" : "Top-Up your Goal Now"}
      </Text>
      <Text
        style={{
          color: "#121212",
          fontSize: 12,
          paddingBottom: 20,
        }}
      >
        {isAtRisk
          ? "With current investments & inflows, you may miss your goal. No worries, bring it back on track now"
          : "You seem to be on track to meet your goal. To be sure, you can always top it up!"}
      </Text>
      <Button
        uppercase={false}
        mode="contained"
        color={"#410DAA"}
        style={{ marginTop: 20, width: "100%" }}
        onPress={() => {
          onMakeFresInvestment && onMakeFresInvestment();
        }}
      >
        <Text style={{ color: "#fff", fontSize: 14 }}>{text1}</Text>
      </Button>
      <Button
        uppercase={false}
        mode="outlined"
        color={"#410DAA"}
        style={{
          marginTop: 20,
          width: "100%",
          borderWidth: 1,
          borderColor: "#410DAA",
        }}
        onPress={() => {
          onAttachExisting();
        }}
      >
        <Text style={{ color: "#410DAA", fontSize: 14 }}>{text2}</Text>
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  modalContent: {
    backgroundColor: "#fff",
    padding: 16,
    justifyContent: "center",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
});
