import React from "react";
import amplitude from "../../../utils/amplitude";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
} from "react-native";
import { md } from "../../../Responsive";
import NavigationHeader from "../../Common/NavigationHeader";
import { isMobile } from "../../../utils";
import { addAsset, getCustomerPortfolioData } from "../../../api";
import { StackActions } from "@react-navigation/routers";
const { width } = Dimensions.get("window");
const isWeb = md(width);
import { TextInput, HelperText } from "react-native-paper";
import PickerField from "../../Common/Pickers/PickerField";
import TextInputField from "../../Common/Inputs/InputField";
import PickerFieldSelect from "../../Common/Pickers/PickerFieldSelect";
import Store from "../../../store";
// import Select from "react-select";

export default class addnewasset extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let userId = null;
    let selectedAssetSubTab = 0;
    if (params) {
      userId = params.user_id || null;
      selectedAssetSubTab = params.selectedAssetSubTab || 0;
      userId = isNaN(userId) ? null : userId;
    }

    this.state = {
      userId,
      isLoading: false,
      asset_name_error: "",
      asset_type_error: "",
      asset_value_error: "",
      assetName: "",
      error: false,
      errorText: "",
      assetValue: "",
      options: [],
      selectedAssetType: "REA",
      selectedAssetSubTab,
    };
  }

  componentDidMount = () => {
    if (Platform.OS == "web") {
      amplitude.logEvent("addnewasset", { path: global.location.href });
    } else {
      amplitude.logEvent("addnewasset", {
        path: this.props.navigation.getState().routeName,
      });
    }
    let options = [
      { value: "REA", label: "Apartment" },
      { value: "REV", label: "House or Villa" },
      { value: "REL", label: "Land / Plot" },
      { value: "EQS", label: "Stock" },
      { value: "EQN", label: "National Pension Scheme - Equity" },
      { value: "EQW", label: "Equity Mutual Fund" },
      { value: "EGN", label: "General Equity Asset" },
      { value: "DBN", label: "National Pension Scheme - Debt" },
      { value: "DBE", label: "Employee Provident Fund" },
      { value: "DBP", label: "Public Provident Fund" },
      { value: "DBB", label: "Bond" },
      { value: "DBF", label: "Bank FD" },
      { value: "DBM", label: "Post Office Savings - Monthly Income Scheme" },
      { value: "DBR", label: "Post Office Savings - Recurring Deposit Scheme" },
      { value: "DBC", label: "Cash in Bank" },
      { value: "DBL", label: "Cash Receivable" },
      { value: "DBD", label: "Debt Mutual Fund" },
      { value: "DGN", label: "General Assets" },
      { value: "SSY", label: "Sukanya Samriddhi Yojana" },
      { value: "INL", label: "Insurance Endowment Policy" },
      { value: "INW", label: "Insurance Whole Life Policy" },
      { value: "INU", label: "Unit Linked Insurance Plan" },
      { value: "GDB", label: "Gold - Biscuits or Coins" },
      { value: "GDS", label: "Gold - Sovereign Bonds" },
      { value: "GDM", label: "Gold Mutual Fund" },
    ];

    this.setState({ options });
  };

  assetNameValidation = () => {
    const { assetName } = this.state;

    if (assetName == "" || assetName == null) {
      let asset_name_error = "Please enter an asset name";
      this.setState({
        asset_name_error,
      });
    }
  };

  validateData = () => {
    if (this.state.assetName == "") {
      this.setState({
        asset_name_error: "Please enter an asset name",
      });
      return false;
    }

    if (
      this.state.selectedAssetType == "" ||
      this.state.selectedAssetType == "SEL"
    ) {
      this.setState({
        asset_type_error: "Please select an asset type",
      });
      return false;
    }

    if (this.state.assetValue == "" || this.state.assetValue == 0) {
      this.setState({
        asset_value_error: "Please enter a valid amount",
      });
      return false;
    }

    return true;
  };

  validateAndAddAsset = async () => {
    if (!this.validateData()) {
      return;
    } else {
      this.setState({ isLoading: true });
      const addAssetResponse = await addAsset({
        name: this.state.assetName,
        type: this.state.selectedAssetType,
        latest_value: this.state.assetValue,
        date: "",
        unique_no: "",
        amount_at_cost: "",
        asset_id: "",
      });
      if (
        addAssetResponse &&
        addAssetResponse.code &&
        addAssetResponse.code == 100
      ) {
        let dataResponse = await getCustomerPortfolioData();
        Store.dispatch({
          type: "GET_PORTFOLIO",
          payload: {
            landingPageResponse: dataResponse,
          },
        });

        this.props.navigation.dispatch(
          StackActions.replace("App", {
            screen: "Dashboard",
            params: {
              screen: "Portfolio",
              params: {
                screen: "InvestmentOverview",
                params: {
                  activeTab: "Other Assets",
                  selectedAssetSubTab: this.state.selectedAssetSubTab,
                },
              },
            },
          })
        );
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Something went wrong!",
        });
      }
    }
  };

  assetValueValidation = () => {
    const { assetValue } = this.state;

    if (assetValue == "" || assetValue == null) {
      let asset_value_error = "Please enter a valid amount";
      this.setState({
        asset_value_error,
      });
    }
  };

  render() {
    const { error, errorText, isLoading } = this.state;

    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View style={{ flex: 1, maxWidth: 1500 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Add New Asset"]}
            onPressBack={() => {
              this.props.navigation.navigate("InvestmentOverview");
            }}
          />
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <ActivityIndicator size="large" color="#410DAA" />
            ) : error ? (
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText || "Something went wrong!"}
              </Text>
            ) : (
              <ScrollView style={[styles.mainContainer]}>
                <View
                  style={{ paddingBottom: 40, marginTop: isMobile ? -3 : -17 }}
                >
                  <View
                    style={{
                      flex: 1,
                      marginRight: isMobile ? "5%" : "0%",
                      marginLeft: isMobile ? "5%" : "0%",
                      width: isMobile ? "90%" : "49%",
                    }}
                  >
                    <TextInputField
                      label="Asset Name"
                      value={this.state.assetName}
                      onChange={(text) => {
                        this.setState({
                          assetName: text,
                          asset_name_error: null,
                        });
                      }}
                      style={[{ ...styles.inputItem }]}
                      editable={true}
                    />

                    <HelperText
                      type="error"
                      visible={this.state.asset_name_error != ""}
                    >
                      {this.state.asset_name_error}
                    </HelperText>
                    <View style={{ marginTop: 15, zIndex: 9999 }}>
                      <PickerFieldSelect
                        data={this.state.options}
                        value={this.state.selectedAssetType}
                        label="Select Asset Type"
                        width={300}
                        onChange={(value) => {
                          this.setState({
                            selectedAssetType: value,
                            asset_type_error: "",
                          });
                        }}
                        pickerStyle={[styles.inputItem]}
                        type="select"
                        placeholder={{
                          value: "SEL",
                          label: "Select Asset Type",
                        }}
                      />
                      <HelperText
                        type="error"
                        visible={this.state.asset_type_error != ""}
                      >
                        {this.state.asset_type_error}
                      </HelperText>
                    </View>
                    <TextInputField
                      label="Current Asset Value"
                      value={this.state.assetValue}
                      onChange={(text) => {
                        const pattern = /^[0-9\b]+$/;
                        if (pattern.test(text)) {
                          this.setState({
                            assetValue: text,
                            asset_value_error: null,
                          });
                        } else {
                          text = text.slice(0, -1);
                          this.setState({
                            assetValue: text,
                            asset_value_error: null,
                          });
                        }
                      }}
                      style={[{ marginTop: 12, ...styles.inputItem }]}
                      editable={true}
                    />
                    <HelperText
                      type="error"
                      visible={this.state.asset_value_error != ""}
                    >
                      {this.state.asset_value_error}
                    </HelperText>
                    <View style={{ marginTop: 30 }}>
                      <TouchableOpacity
                        onPress={() => {
                          this.validateAndAddAsset();
                        }}
                        style={{
                          width: isMobile ? "30%" : "25%",
                          marginLeft: isMobile ? "35%" : 80,
                          marginRight: isMobile ? "35%" : 0,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "bold",
                            fontSize: isWeb ? 14 : 13,
                            color: "#FFFFFF",
                            backgroundColor: "#410DAA",
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            borderRadius: 4,
                            paddingVertical: 9,
                            textAlign: "center",
                            width: "99%",
                          }}
                        >
                          Add Asset
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                {isMobile && <View style={{ marginTop: 35 }}></View>}
              </ScrollView>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: isWeb ? 32 : 0,
    paddingRight: isWeb ? 32 : 0,
    paddingTop: isWeb ? 30 : 10,
    width: "100%",
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
  filterText: {
    fontSize: 15,
    color: "gray",
    fontWeight: "200",
  },
  filtersTitle: {
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 6,
    marginLeft: isMobile ? 16 : 0,
  },
});
