import React, { Component } from "react";
import { View, StyleSheet, SafeAreaView, Text, TouchableOpacity } from "react-native";
import ANTIcon from "@expo/vector-icons/AntDesign";
import theme from "../../../static/theme";
import CustomModal from "./CustomModal";

export default class AlertModal extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const {
      close = true,
      negativeButtonStyle,
      positiveButtonStyle,
      negativeButtonStyleContainer,
      positiveButtonStyleContainer
    } = this.props;


    return (
      <CustomModal
        visible={this.props.visible}
        showCloseButton={close}
        closeModal={() => {
          this.props.closeModal();
        }}
        //   onBackdropPress={() => {
        //     this.props.closeModal();
        //   }}
        // backdropColor={""}
        // animationIn={"zoomInDown"}
        // animationOut={"zoomOutUp"}
        // animationInTiming={1000}
        // animationOutTiming={1000}
        // backdropTransitionInTiming={1000}
        // backdropTransitionOutTiming={1000}
      >
        <View
          style={[
            styles.modalContent,
            this.props.contentStyle ? { ...this.props.contentStyle } : {}
          ]}
        >
          <View style={{ marginBottom: 20 }}>{this.props.children}</View>
          {(this.props.negativeButtonPress ||
            this.props.positiveButtonPress) && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15
              }}
            >
              <View
                style={[
                  {
                    width: "50%",
                    alignItems: "flex-start",
                    justifyContent: "center"
                  },
                  negativeButtonStyleContainer
                    ? { ...negativeButtonStyleContainer }
                    : {}
                ]}
              >
                {this.props.negativeButtonPress &&
                  this.props.negativeButtonText && (
                    <TouchableOpacity
                      onPress={() => {
                        this.props.negativeButtonPress();
                      }}
                      style={[
                        {
                          backgroundColor: theme.colors.NEW_BRAND_COLOR,
                          width: 120,
                          // height: 35,
                          // paddingHorizontal: 30,
                          paddingVertical: 8,
                          alignItems: "center",
                          justifyContent: "center",
                          borderWidth: 1,
                          borderColor: theme.colors.NEW_BRAND_COLOR,
                          borderRadius: 5
                        },
                        this.props.negativeButtonStyle
                          ? { ...this.props.negativeButtonStyle }
                          : {}
                      ]}
                    >
                      <Text
                        style={{
                          color: "#fff",
                          fontSize: 16,
                          alignSelf: "center",
                          paddingHorizontal: 10,
                          // paddingVertical: 8,
                          textAlign: "center"
                        }}
                      >
                        {this.props.negativeButtonText || "No"}
                      </Text>
                    </TouchableOpacity>
                  )}
              </View>
              <View
                style={[
                  {
                    width: "50%",
                    marginLeft: 30,
                    alignItems: "flex-end",
                    justifyContent: "center"
                  },
                  positiveButtonStyleContainer
                    ? { ...positiveButtonStyleContainer }
                    : {}
                ]}
              >
                {this.props.positiveButtonPress &&
                  this.props.positiveButtonText && (
                    <TouchableOpacity
                      onPress={() => {
                        this.props.positiveButtonPress();
                      }}
                      style={[
                        {
                          backgroundColor: theme.colors.WHITE,
                          paddingVertical: 8,
                          width: 120,
                          marginRight:30,
                          alignItems: "center",
                          justifyContent: "center",
                          borderWidth: 1,
                          borderColor: theme.colors.NEW_BRAND_COLOR,
                          borderRadius: 5
                        },
                        this.props.positiveButtonStyle
                          ? { ...this.props.positiveButtonStyle }
                          : {}
                      ]}
                    >
                      <Text
                        style={{
                          color: theme.colors.NEW_BRAND_COLOR,
                          fontSize: 16,
                          alignSelf: "center",
                          paddingHorizontal: 10,
                          // paddingVertical: 8,
                          textAlign: "center"
                        }}
                      >
                        {this.props.positiveButtonText || "Yes"}
                      </Text>
                    </TouchableOpacity>
                  )}
              </View>
            </View>
          )}
        </View>
      </CustomModal>
    );
  }
}

const styles = StyleSheet.create({
  bottomModal: {
    // justifyContent: "center",
    // margin: 0
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)",
    margin: 16
  }
});
