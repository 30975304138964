import React from "react";
import { View, Platform } from "react-native";
import { Text } from "react-native-paper";
// const Donut =
//   Platform.OS == "web"
//     ? null
//     : require("react-native-pie-chart").default;

const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;

const formatValue = (value) => {
  return value ? value.toFixed(1) : 0;
};

const chartConfig = {
  backgroundGradientFrom: "#1E2923",
  backgroundGradientFromOpacity: 0,
  backgroundGradientTo: "#08130D",
  backgroundGradientToOpacity: 0.5,
  color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
  strokeWidth: 2,
  barPercentage: 0.5,
  useShadowColorFromDataset: false,
};

export const EquityPieChart = ({
  equity_val,
  non_equity_val,
  equity_title,
  non_equity_title,
  style,
  title,
  title_description,
  pieChartStyle,
  description,
  width = 150,
  data = [],
}) => {
  let colorArr = [];
  let valueArr = [];
  let pieChartData = [];

  data.forEach((item) => {
    if (item && item.title && item.value) {
      colorArr.push(item.color);
      valueArr.push(item.value);
      pieChartData.push({ name: item.title, data: item.value });
    }
  });

  return (
    <View style={{ ...style }}>
      {title && (
        <>
          <Text style={{ color: "#BCBCBC", fontSize: 13, textAlign: "center" }}>
            {title}
          </Text>
          <Text style={{ color: "#BCBCBC", fontSize: 10, textAlign: "center" }}>
            {title_description}
          </Text>
        </>
      )}
      <Text style={{ color: "#BCBCBC", fontSize: 10, textAlign: "center" }}>
        {description}
      </Text>
      <View style={{ ...pieChartStyle }}>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
            paddingBottom: 20,
          }}
        >
          {Platform.OS == "web" ? (
            <View
              style={{
                height: 220,
                overflow: "hidden",
              }}
            >
              <Donut
                options={{
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: false,
                  },
                  tooltip: { enabled: false },
                  colors: colorArr,
                  plotOptions: {
                    pie: {
                      donut: {
                        size: "60%",
                      },
                    },
                  },
                  stroke: {
                    width: 1,
                    colors: colorArr,
                  },
                }}
                series={valueArr}
                sliceColor={colorArr}
                type="donut"
                width="170"
              />
            </View>
          ) : (
            <Donut
              widthAndHeight={100}
              series={valueArr}
              sliceColor={colorArr}
              doughnut={true}
              coverRadius={0.6}
              coverFill={"#FFF"}
              style={{ marginRight: 8, marginTop: 5, marginBottom: 3 }}
            />
          )}
        </View>
        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <View style={{ flex: 0.5, alignItems: "center", overflow: "hidden" }}>
            <Text
              style={{
                backgroundColor: "#6FE3D4",
                fontSize: 11,
                paddingHorizontal: 8,
                paddingVertical: 4,
                borderRadius: 5,
                textAlign: "center",
                overflow: "hidden",
              }}
            >
              {formatValue(equity_val)}%
            </Text>
            <Text
              style={{
                fontSize: 11,
                marginTop: 5,
                color: "#121212",
                opacity: 0.5,
              }}
            >
              {equity_title || "Equity"}
            </Text>
          </View>
          <View style={{ flex: 0.5, alignItems: "center" }}>
            <Text
              style={{
                backgroundColor: "#876CDD",
                fontSize: 11,
                paddingHorizontal: 8,
                paddingVertical: 4,
                borderRadius: 5,
                textAlign: "center",
                overflow: "hidden",
                color: "#fff",
              }}
            >
              {formatValue(non_equity_val)}%
            </Text>
            <Text
              style={{
                fontSize: 11,
                marginTop: 5,
                color: "#121212",
                opacity: 0.5,
              }}
            >
              {non_equity_title || "Debt"}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
