import React from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import PlanFeatureCard from "./planFeatureCard";
import { sm, md, lg, xl, twoxl } from "../../Responsive";

const window = Dimensions.get("window");

export default function PlanFeatures ({features, planName}) {

    return (
        <View style={styles.container}>
            <View style={styles.featuresContainer}>
                {features.map((c,i) => (
                    <>
                    <PlanFeatureCard feature={c} key={i} length={features.length} id= {i+1} planName={planName}></PlanFeatureCard>
                    {(i === 2 || i== 5 || !xl(window.width)) && (i !== features.length - 1) && (<View style={styles.hr}></View>)}
                    </>
                ))}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        marginLeft: md(window.width) ? 0: undefined,
        marginRight: md(window.width) ? 0: undefined,
        paddingLeft: md(window.width) ? 100: undefined,
        paddingRight: md(window.width) ? 100: undefined,
        alignSelf: md(window.width) ? 'stretch': undefined,
        backgroundColor: md(window.width)? 'white': undefined,
        paddingBottom: md(window.width)? 80: 60
    },
    heading: {
        fontWeight: 'bold',
        marginTop: md(window.width) ? 60: 40,
        fontSize: 20,
        textAlign: 'center'
    },
    link: {
        textAlign: 'center',
        color: '#276FC4',
        marginTop: 12
    },
    featuresContainer: {
        display: xl(window.width) ? 'flex': undefined,
        flexDirection: xl(window.width)? 'row': undefined,
        flexWrap: xl(window.width) ? 'wrap': undefined,
        justifyContent: xl(window.width)? 'center': undefined,
    },
    hr: {
        backgroundColor: '#CCC',
        width: "100%",
        height: 1
    }
})