import React from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Text } from "react-native-paper";
import { isMobile } from "../../utils";
import amplitude from "../../utils/amplitude";

export default class InvestFutureStrip extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { navigation } = this.props;
    return (
      <View style={{ paddingBottom: 20, paddingTop: 10 }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: isMobile ? "auto" : "63.5%",
          }}
        >
          <TouchableOpacity
            style={styles.stripContainer}
            onPress={() => {
              amplitude.logEvent("Retire_Well_Card_Clicked");
              navigation.navigate("Goals", {
                screen: "BasicDetails",
                params: {
                  type: "RETIREMENT",
                  from: "Home",
                  stack: "Dashboard",
                },
              });
            }}
          >
            <Image
              source={{
                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/retire.png",
              }}
              style={{
                width: 35,
                height: 35,
                resizeMode: "contain",
                borderRadius: 4,
                flex: 1,
                alignItems: "center",
              }}
            />
            <Text style={{ marginBottom: 10 }}>Retire Well</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.stripContainer, { marginLeft: isMobile ? 15 : 35 }]}
            onPress={() => {
              amplitude.logEvent("Child_Future_Card_Clicked");
              navigation.navigate("Goals", {
                screen: "BasicDetails",
                params: {
                  type: "CHILDREN",
                  from: "Home",
                  stack: "Dashboard",
                },
              });
            }}
          >
            <Image
              source={{
                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/child.png",
              }}
              style={{
                width: 35,
                height: 35,
                resizeMode: "contain",
                borderRadius: 4,
                flex: 1,
                alignItems: "center",
              }}
            />
            <Text style={{ marginBottom: 10 }}>Child’s Bright Future</Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 15,
            marginBottom: 15,
            width: isMobile ? "auto" : "63.5%",
          }}
        >
          <TouchableOpacity
            style={styles.stripContainer}
            onPress={() => {
              amplitude.logEvent("Custom_Goal_Card_Clicked");
              navigation.navigate("App", {
                screen: "Goals",
                params: {
                  screen: "AddGoal",
                  params: { goal_type: "EMF" },
                },
              });
            }}
          >
            <Image
              source={{
                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/emergency.png",
              }}
              style={{
                width: 35,
                height: 35,
                resizeMode: "contain",
                borderRadius: 4,
                flex: 1,
                alignItems: "center",
              }}
            />
            <Text style={{ marginBottom: 10 }}>Emergency Fund</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.stripContainer, { marginLeft: isMobile ? 15 : 35 }]}
            onPress={() => {
              amplitude.logEvent("Custom_Goal_Card_Clicked");
              navigation.navigate("App", {
                screen: "Goals",
                params: {
                  screen: "AddGoal",
                  params: { goal_type: "OTH" },
                },
              });
            }}
          >
            <Image
              source={{
                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/custom.png",
              }}
              style={{
                width: 35,
                height: 35,
                resizeMode: "contain",
                borderRadius: 4,
                flex: 1,
                alignItems: "center",
              }}
            />
            <Text style={{ marginBottom: 10 }}>Custom Goal</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  subContainerOne: {
    fontSize: 12,
    paddingBottom: isMobile ? 6 : 0,
    flex: 1,
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "flex-start",
  },
  subContainerTwo: {
    flex: 1,
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    paddingTop: isMobile ? 20 : 0,
    paddingBottom: 20,
  },
  stripContainer: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "rgba(65, 13, 170, 0.3)",
    height: 120,
    padding: 10,
    flex: 1,
    alignItems: "center",
  },
  textStyle: {
    fontFamily: "Roboto",
  },
  subContainerTwoStrips: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "95%",
    marginRight: "5%",
  },
});
