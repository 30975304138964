import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Platform, View, Text, StyleSheet } from 'react-native';
import { WebView } from 'react-native-webview';
import NavigationHeader from '../NavigationHeader';

const WebViewGeneral = (props) => {
    const [ uri, setUri ] = useState(null);

    useEffect(()=>{
        const { route = {} } = props;
        const { params = {} } = route;
        if(params){
            const { url = null, options } = params;
            if(url){
                setUri(url);
                // console.log("url", url);
            }
        }
    });

    return (
        <View style={styles.container}>
          <WebView
            ref={(ref) => {
                this.webviewReload = ref;
            }}
            onLoad={(syntheticEvent) => { const { nativeEvent } = syntheticEvent; if (nativeEvent.title == "") { this.webviewReload.current.reload(); } }}
            source={{uri: 'https://www.maxiom.co.in/app'}}
            style={styles.video}
          />
          <WebView
            source={{uri: 'https://www.youtube.com/embed/PGUMRVowdv8'}}
            style={styles.video}
          />
        </View>
      );

    // if(uri){
    //     return(
    //         <View style={{ width: "100%", height: "100%" }}>
    //             <NavigationHeader
    //                 navigation={props.navigation}
    //                 navigationHeader={["Import Funds"]}
    //                 onPressBack={() => {
    //                     props.navigation.navigate("ImportMF");
    //                 }}
    //             />
    //             <Text>{uri}</Text>
    //             <WebView
    //                 originWhitelist={["*"]}
    //                 source={{
    //                     uri: uri,
    //                     method: "GET"
    //                 }}
    //                 javaScriptEnabled
    //                 domStorageEnabled
    //                 setSupportMultipleWindows
    //                 renderError={()=><Text>Error</Text>}
    //                 style={{flex: 1 }}
    //                 containerStyle={{flex: 1 }}
    //                 renderError={(errorName) => {
    //                     console.log(errorName);
    //                     return <Text>{errorName}</Text>
    //                 }}
    //             />
    //         </View>
    //     )
    // }

    // return <View />
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // alignItems: 'center',
        // justifyContent: 'space-between',
      },
      video: {
        marginTop: 20,
        maxHeight: 200,
        width: 320,
        flex: 1
      }
})

export default WebViewGeneral;