import React from "react";
import {
	Platform,
	StyleSheet,
	View,
	Text,
	TouchableOpacity
} from 'react-native';
const Donut =
	Platform.OS == "web"
		? require("react-apexcharts").default
		: require("react-native-pie-chart").default;
import { Entypo } from '@expo/vector-icons';
import { isMobile, numberWithCommas } from "../../../utils";

const PortfolioCardView = ({
	itemName,
	itemCount,
	navigation,
	itemType,
	isCardView = true,
	returnColorForDonut,
	returnPercentageForDonut
}) => {
	return (
		<View style={styles(isCardView).container}>
			<View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
				<View style={{ width: '50%' }}>
					<Text style={styles(isCardView).subHeading}>{itemName}</Text>
				</View>
				<View style={{ width: '50%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
					<Text style={[{ fontWeight: "400", textAlign: 'right' }]}>{itemCount == 0 ? "Zero" : itemCount} {itemName} analysed</Text>
				</View>
			</View>
			{Platform.OS == "web" ? (
				<View
					style={{
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "row",
						marginTop: 20
					}}
				>
					<Donut
						options={{
							dataLabels: {
								enabled: true,
								formatter: function (val) {
									if (itemCount == 0) {
										return 0 + "%"
									}
									return Number(val).toFixed(0).toString() + "%"
								},
							},
							legend: {
								show: false,
							},
							tooltip: { enabled: false },
							colors: itemCount == 0 ? ["#C7C7C7"] : returnColorForDonut,
							plotOptions: {
								pie: {
									donut: {
										size: "40%",
									},
								},
							},
							stroke: {
								width: 1,
								colors: itemCount == 0 ? ["#C7C7C7"] : returnColorForDonut,
							},
						}}
						series={itemCount == 0 ? [100] : returnPercentageForDonut}
						sliceColor={itemCount == 0 ? ["#C7C7C7"] : returnColorForDonut}
						type="donut"
						width="170"
					/>
				</View>
			) : (
				<Donut
					widthAndHeight={100}
					series={itemCount == 0 ? [100] : returnPercentageForDonut}
					sliceColor={itemCount == 0 ? ["#C7C7C7"] : returnColorForDonut}
					doughnut={true}
					coverRadius={0.6}
					coverFill={"#FFF"}
				/>
			)}
			<View style={{ marginTop: 30, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
				<View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
					<View style={{ width: '20%' }}></View>
					{Object.entries(itemType).map(([key, value]) => {
						return (<View key={key} style={{ width: '30%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
							<Entypo name="dot-single" size={30} color={value.color} />
							<Text style={{ textAlign: 'center', color: "#1a1a1a", fontWeight: '600' }}>{key}</Text>
						</View>)
					})}
				</View>
				<View style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10 }}>
					<View style={{ width: '20%' }}><Text style={{ color: "#1a1a1a", fontWeight: '600' }}>Value</Text></View>
					{Object.entries(itemType).map(([key, value]) => {
						return (<View key={key} style={{ width: '30%', alignItems: 'center', justifyContent: 'center' }}>
							<Text style={{ textAlign: 'center' }}>₹ {numberWithCommas(value.sum.toFixed(0).toString())}</Text>
						</View>)
					})}
				</View>
				<View style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10 }}>
					<View style={{ width: '20%' }}><Text style={{ color: "#1a1a1a", fontWeight: '600' }}>{itemName}</Text></View>
					{Object.entries(itemType).map(([key, value]) => {
						return (<View key={key} style={{ width: '30%', alignItems: 'center', justifyContent: 'center' }}>
							<Text style={{ textAlign: 'center' }}>{value.data.length}</Text>
						</View>)
					})}
				</View>
			</View>
			{itemCount == 0 && (
				<View style={{
					marginTop: 50,
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'row'
				}}>
					<Text>To avail a free portfolio analysis, click here.{" "}</Text>
					<TouchableOpacity
						onPress={() => {
							navigation.navigate("App", {
								screen: "ViewMode",
								params: {
									screen: "ImportStocks",
									params: {
										from: 'dashboard',
									},
								},
							});
						}}>
						<Text style={{ color: '#410DAA', textDecorationLine: 'underline', fontWeight: '500', textAlign: 'center' }}>Import Now</Text>
					</TouchableOpacity>
				</View>
			)}
		</View>
	)
}


const styles = (isCardView) => StyleSheet.create({
	container: !isCardView ? {
		width: isMobile ? '98.5%' : '47.5%',
		padding: isMobile ? 5 : 20,
		backgroundColor: '#fff',
		margin: isMobile ? 5 : 16,
	} : {
		width: '50%',
		padding: 20,
		backgroundColor: '#fff',
		borderRadius: 8,
		margin: 16,
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.3,
		shadowRadius: 4,
		elevation: 5,
	},
	subHeading: {
		color: "#484848",
		fontSize: 20,
		fontWeight: '500'
	},
});

export default PortfolioCardView;