import React, { Component } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  SafeAreaView,
  ActivityIndicator,
  TouchableOpacity,
  FlatList,
  Platform,
} from "react-native";
import { Text } from "react-native-paper";
import Icon from "@expo/vector-icons/FontAwesome";
import SimpleLineIcons from "@expo/vector-icons/SimpleLineIcons";
import MIcon from "@expo/vector-icons/MaterialCommunityIcons";
import amplitude from "../../../utils/amplitude";
import { getEduModules } from "../../../api";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import CustButton from "../../../components/Common/Button";
import EmptyView from "../../../components/Common/emptyView";
import { SideHeading } from "../../../components/Common/sideHeading";
import { StackActions } from "@react-navigation/native";
import { isJama } from "../../../utils";

const LastRead = (props) => {
  const { lastReadContainer, lastReadHeading, lastReadBubble } = styles;
  const { current, modules } = props;
  let chapterItems = [];
  let is_video = false;
  let modulePosition = null;
  let moduleData = {};

  for (let moduleIndex = 0; moduleIndex < modules.length; moduleIndex++) {
    const element = modules[moduleIndex];
    let found = false;
    for (let cardIndex = 0; cardIndex < element.cards.length; cardIndex++) {
      const card = element.cards[cardIndex];
      if (card.messagebundle_id == current.card_id) {
        found = true;
        moduleData = element;
        chapterItems = element.cards;
        modulePosition = moduleIndex;
        break;
      }
    }
    if (!found) {
      for (
        let videoIndex = 0;
        videoIndex < element.videos.length;
        videoIndex++
      ) {
        const video = element.videos[videoIndex];
        if (video.messagebundle_id == current.card_id) {
          found = true;
          moduleData = element;
          chapterItems = element.videos;
          modulePosition = moduleIndex;
          is_video = true;
          break;
        }
      }
    }
  }

  const cardsOfType =
    moduleData.cards &&
    moduleData.cards
      .filter((card) => {
        let cardType = card.is_advanced ? "Advanced" : "Standard";
        return cardType == current.type;
      })
      .map((card) => ({ ...card, is_video: false }));

  const videosOfType =
    moduleData.videos &&
    moduleData.videos
      .filter((video) => {
        let videoType = video.is_advanced ? "Advanced" : "Standard";
        return videoType == current.type;
      })
      .map((video) => ({ ...video, is_video: true }));

  let selectedCardItems = [...cardsOfType, ...videosOfType];
  let index = 0;
  let detailsOfCards = false;
  for (let i = 0; i < selectedCardItems.length; i++) {
    const element = selectedCardItems[i];
    if (element.messagebundle_id == current.card_id) {
      detailsOfCards = element;
      index = i;
      break;
    }
  }

  let card_type = true;
  if (current.type == "Standard") {
    card_type = false;
  }

  // console.log(index);

  if (current.type && current.chapter && current.content && current.card_id) {
    return (
      <TouchableOpacity
        style={lastReadContainer}
        onPress={() => {
          const payload = {
            key: current.chapter,
            is_advanced: card_type,
            selectedIndex: index,
            is_video: is_video,
            quiz_id: moduleData.quiz_id,
          };
          props.navigation.push("App", {
            screen: "Dashboard",
            params: {
              screen: "Dashboard",
              params: {
                screen: "DetailedView",
                params: {
                  payload,
                },
              },
            },
          });
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
          <Text style={lastReadHeading}>{modulePosition + 1}. </Text>
          <View style={{ flex: 1 }}>
            <Text style={[lastReadHeading, { marginLeft: 0, marginBottom: 0 }]}>
              {moduleData.name}
            </Text>
            {current.chapter && (
              <>
                <EmptyView />
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 16,
                  }}
                >
                  <View style={lastReadBubble}></View>
                  <Text
                    numberOfLines={1}
                    ellipsizeMode={"tail"}
                    style={{ color: "#121212" }}
                  >
                    <Text style={{ marginRight: 8 }}>
                      {modulePosition + 1}. {index + 1} &nbsp;
                    </Text>
                    {current.card_name}
                  </Text>
                </View>
              </>
            )}
          </View>
          <View
            style={{
              borderBottomLeftRadius: 8,
              borderTopRightRadius: 8,
              overflow: "hidden",
            }}
          >
            <Text
              style={{
                color: "#fff",
                backgroundColor: "#410DAA",
                paddingHorizontal: 8,
                paddingVertical: 8,
                marginRight: 1,
              }}
            >
              {current.type}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
  return null;
};

const RenderPlanHeader = ({ selected, updateSelected, updateTab }) => {
  const { planText, plansHeaderContainer } = styles;

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FlatList
        data={["Standard", "Advanced"]}
        extraData={selected}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          alignItems: "flex-start",
          paddingHorizontal: 18,
        }}
        renderItem={({ item }) => {
          let backgroundColor;
          let color;
          if (item == selected) {
            backgroundColor = "#410DAA";
            color = "#fff";
          } else {
            backgroundColor = "#fff";
            color = "#121212";
          }
          return (
            <SelectTabDetails
              item={item}
              backgroundColor={backgroundColor}
              color={color}
              onPress={() => {
                // console.log("we cliecked  ");
                if (selected === "Standard") {
                  updateSelected(0);
                } else {
                  updateSelected(1);
                }
                updateTab(item);
              }}
            />
          );
        }}
        keyExtractor={(item, index) => index}
      />
    </View>
  );
};

const SelectTabDetails = ({ item, onPress, backgroundColor, color }) => {
  return (
    <TouchableOpacity
      style={{
        borderWidth: 1,
        borderColor: "#BCBCBC",
        borderRadius: 18,
        backgroundColor,
        marginRight: 16,
        paddingLeft: 20,
        paddingRight: 20,
        height: 36,
        alignItems: "center",
        justifyContent: "center",
      }}
      onPress={onPress}
    >
      <Text style={{ fontSize: 14, color, fontWeight: "700" }}>{item}</Text>
    </TouchableOpacity>
  );
};

const LearningModule = (props) => {
  const { learningContainer, learningHeading } = styles;
  const { data, openCard, updateOpenCard, type } = props;
  return (
    <View style={{ marginBottom: 120 }}>
      {data.map((moduleItem, index) => {
        const cardsOfType = moduleItem.cards
          .filter((card) => card.is_advanced == type)
          .map((card) => ({ ...card, is_video: false }));

        const videosOfType = moduleItem.videos
          .filter((video) => video.is_advanced == type)
          .map((video) => ({ ...video, is_video: true }));
        return (
          <View
            style={[learningContainer, { position: "relative" }]}
            key={moduleItem.name}
          >
            <TouchableOpacity onPress={() => updateOpenCard(index)}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: 16,
                  marginBottom: 4,
                }}
              >
                <Text style={{ color: "#121212", fontSize: 12 }}>
                  chapter {index + 1}
                  {"   "}
                  {(moduleItem.status == "Completed" ||
                    [...cardsOfType, ...videosOfType].filter(
                      (item) => item.status == "Completed"
                    ).length == [...cardsOfType, ...videosOfType].length) && (
                    <Icon
                      name={"check-circle"}
                      color={"#009105"}
                      size={12}
                      style={{ marginLeft: 20 }}
                    />
                  )}
                </Text>
              </View>

              <Text
                style={[learningHeading, { marginBottom: 0, marginTop: 0 }]}
              >
                {moduleItem.name}
              </Text>
            </TouchableOpacity>

            {moduleItem.total_quiz_questions ||
            moduleItem.correct_quiz_ans_count ? (
              <Text
                style={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  fontSize: 12,
                }}
              >
                <Text style={{ fontWeight: "bold", fontSize: 17 }}>
                  {moduleItem.correct_quiz_ans_count}{" "}
                </Text>
                /{moduleItem.total_quiz_questions}
              </Text>
            ) : null}

            <EmptyView />
            <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
              <View style={{ flex: 1 }}>
                {index !== openCard && (
                  <>
                    <View style={{ flexDirection: "row", marginBottom: 16 }}>
                      <View
                        style={{
                          flexDirection: "row",
                          marginHorizontal: 16,
                          alignItems: "center",
                        }}
                      >
                        <SimpleLineIcons name="book-open" />
                        <Text style={{ marginHorizontal: 8 }}>
                          {/* {item.cards.length} cards */}
                          {cardsOfType.length} cards
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          marginHorizontal: 16,
                          alignItems: "center",
                        }}
                      >
                        <Icon name="video-camera" />
                        <Text style={{ marginHorizontal: 8 }}>
                          {/* {item.videos.length} Videos */}
                          {videosOfType.length} videos
                        </Text>
                      </View>
                      {moduleItem.quiz_id !== "" && (
                        <View
                          style={{
                            flexDirection: "row",
                            marginHorizontal: 16,
                            alignItems: "center",
                          }}
                        >
                          <MIcon name="brain" />
                          <Text style={{ marginHorizontal: 8 }}>Quiz</Text>
                        </View>
                      )}
                    </View>
                  </>
                )}

                {index === openCard &&
                  [...cardsOfType, ...videosOfType].map((item, index) => {
                    // console.log(index);
                    if (!item.is_video) {
                      const card = item;
                      const key = card.previous_key;
                      const payload = {
                        key,
                        is_advanced: type,
                        selectedIndex: index,
                        is_video: false,
                        quiz_id: moduleItem.quiz_id,
                      };
                      return (
                        <TouchableOpacity
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginHorizontal: 16,
                            marginBottom: 16,
                          }}
                          onPress={() => {
                            props.navigation.push("App", {
                              screen: "Dashboard",
                              params: {
                                screen: "Dashboard",
                                params: {
                                  screen: "DetailedView",
                                  params: {
                                    payload,
                                  },
                                },
                              },
                            });
                          }}
                        >
                          {card.status == "Completed" && (
                            <Icon
                              name="check"
                              color="#009105"
                              style={{ width: 20 }}
                            />
                          )}
                          {card.status == "" && (
                            <View style={styles.lastReadBubble}></View>
                          )}
                          {card.status == "Not started" && (
                            <View style={{ width: 20 }} />
                          )}
                          <Text
                            numberOfLines={1}
                            ellipsizeMode={"tail"}
                            style={{
                              color: "#121212",
                              marginHorizontal: 16,
                              flex: 1,
                            }}
                          >
                            {card.key}
                          </Text>
                          <SimpleLineIcons name="book-open" />
                        </TouchableOpacity>
                      );
                    } else {
                      const video = item;
                      const key = video.previous_key;
                      const payload = {
                        key,
                        is_advanced: type,
                        selectedIndex: index,
                        is_video: true,
                        quiz_id: moduleItem.quiz_id,
                      };
                      return (
                        <TouchableOpacity
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginHorizontal: 16,
                            marginBottom: 16,
                          }}
                          onPress={() => {
                            props.navigation.push("App", {
                              screen: "Dashboard",
                              params: {
                                screen: "Dashboard",
                                params: {
                                  screen: "DetailedView",
                                  params: {
                                    payload,
                                  },
                                },
                              },
                            });
                          }}
                        >
                          {video.status == "Completed" && (
                            <Icon
                              name="check"
                              color="#009105"
                              style={{ width: 20 }}
                            />
                          )}

                          {video.status == "" && (
                            <View style={styles.lastReadBubble}></View>
                          )}
                          {video.status == "Not started" && (
                            <View style={{ width: 20 }} />
                          )}
                          <Text
                            numberOfLines={1}
                            ellipsizeMode={"tail"}
                            style={{
                              color: "#121212",
                              marginHorizontal: 16,
                              flex: 1,
                            }}
                          >
                            {video.key}
                          </Text>
                          <Icon name="video-camera" />
                        </TouchableOpacity>
                      );
                    }
                  })}

                {index == openCard && moduleItem.quiz_id !== "" && (
                  <>
                    <CustButton
                      style={{
                        alignSelf: "flex-start",
                        margin: 16,
                        marginTop: 0,
                      }}
                      bgColor={"#fff"}
                      borderColo={"#410DAA"}
                      textColor={"#410DAA"}
                      onPress={() =>
                        props.navigation.navigate("App", {
                          screen: "Dashboard",
                          params: {
                            screen: "Dashboard",
                            params: {
                              screen: "EduQuiz",
                              params: {
                                quiz_id: moduleItem.quiz_id,
                              },
                            },
                          },
                        })
                      }
                    >
                      Start Chapter Assessment Quiz
                    </CustButton>
                  </>
                )}
              </View>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default class EduHome extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let from = null;
    if (params) {
      from = params.from || null;
    }
    this.state = {
      from,
      selected: 0,
      openCard: null,
      modules: [],
      current: {},
      raw: "",
      activeTab: "Standard",
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("eduHome", { path: global.location.href });
    } else {
      amplitude.logEvent("eduHome", {
        path: this.props.navigation.getState().routeName,
      });
    }
    this.setState({
      fetching: true,
    });
    this.getData();
  }

  getData = async () => {
    try {
      const modules = await getEduModules();
      if (modules && modules.chapters) {
        this.setState({
          modules: modules.chapters,
          current: modules.last_read,
          fetching: false,
          raw: JSON.stringify(modules),
        });
      } else {
        this.setState({
          error: "Something went wrong. Please try again later",
        });
      }
    } catch (err) {
      this.setState({
        error: "Something went wrong. Please try again later",
        fetching: false,
      });
    }
  };

  render() {
    const { container } = styles;
    const { modules, current, fetching, error } = this.state;

    if (error) {
      return (
        <SafeAreaView
          style={{
            flex: 1,
            padding: 16,
            backgroundColor: "#fff",
            maxWidth: 800,
          }}
        >
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={[isJama() ? "Jama Academy" : "Maxiom Academy"]}
            onPressBack={() => {
              if (this.state.from) {
                this.props.navigation.navigate("App", {
                  screen: "ViewMode",
                  params: {
                    screen: "CountTrackerHome",
                    params: {},
                  },
                });
              } else {
                this.props.navigation.pop();
              }
            }}
          />
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <Text>{error}</Text>
          </View>
        </SafeAreaView>
      );
    }

    if (fetching) {
      return (
        <SafeAreaView
          style={{
            flex: 1,
            padding: 16,
            backgroundColor: "#fff",
          }}
        >
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={[isJama() ? "Jama Academy" : "Maxiom Academy"]}
            onPressBack={() => {
              if (this.state.from) {
                this.props.navigation.navigate("App", {
                  screen: "ViewMode",
                  params: {
                    screen: "CountTrackerHome",
                    params: {},
                  },
                });
              } else {
                this.props.navigation.navigate("Home");
              }
            }}
          />
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        </SafeAreaView>
      );
    }

    return (
      <SafeAreaView style={container}>
        <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={[isJama() ? "Jama Academy" : "Maxiom Academy"]}
            onPressBack={() => {
              if (
                this.props.route &&
                this.props.route.params &&
                this.props.route.params.from
              ) {
                this.props.navigation.navigate("App", {
                  screen: "Plans",
                  params: {
                    screen: "PlansHome",
                    params: {},
                  },
                });
              } else if (this.props.navigation.canGoBack()) {
                this.props.navigation.goBack();
              } else {
                this.props.navigation.push("App");
              }
            }}
          />
          {Object.keys(current).length > 0 &&
          current.card_id &&
          current.card_name &&
          current.content ? (
            <>
              <SideHeading
                title={"Continue Reading"}
                headingTitleStyle={{
                  fontSize: 20,
                  fontWeight: "bold",
                  alignItems: "center",
                }}
              />

              <LastRead
                current={current}
                navigation={this.props.navigation}
                modules={modules}
              />
            </>
          ) : null}
          <SideHeading
            title={"Learning Modules"}
            headingTitleStyle={{
              fontSize: 20,
              fontWeight: "bold",
              alignItems: "center",
            }}
          />
          {/*<RenderPlanHeader
            selected={this.state.activeTab}
            updateSelected={(selected) => this.setState({ selected })}
            updateTab={(activeTab) => this.setState({ activeTab: activeTab})}
          /> */}
          <LearningModule
            data={modules}
            openCard={this.state.openCard}
            type={this.state.selected ? true : false}
            updateOpenCard={(openCard) => {
              if (openCard == this.state.openCard) {
                this.setState({
                  openCard: null,
                });
              } else {
                this.setState({
                  openCard,
                });
              }
            }}
            {...this.props}
          />
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
  },

  lastReadContainer: {
    backgroundColor: "#ECFCED",
    margin: 16,
    borderRadius: 8,
  },
  lastReadHeading: {
    fontSize: 18,
    color: "#121212",
    margin: 8,
    fontWeight: "bold",
  },
  lastReadBubble: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: "#410DAA",
    marginRight: 8,
  },
  plansHeaderContainer: {
    width: "60%",
    flexDirection: "row",
    marginHorizontal: 32,
    borderColor: "#410DAA",
    borderWidth: 2,
    borderRadius: 4,
    marginVertical: 16,
  },
  planText: {
    flex: 1,
    textAlign: "center",
    height: "100%",
    paddingVertical: 8,
    color: "#410DAA",
    backgroundColor: "#fff",
    fontSize: 12,
  },
  learningContainer: {
    backgroundColor: "#F7F5FC",
    margin: 16,
    borderRadius: 8,
  },
  learningHeading: {
    fontSize: 18,
    color: "#121212",
    margin: 16,
    fontWeight: "bold",
  },
  learningText: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: "#410DAA",
    marginHorizontal: 8,
  },
});
