import React from "react";
import { View, Image, StyleSheet, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";
import theme from "../../../static/theme/index.js";
import { isMobile } from "../../../utils";

const AssessWellnessStrip = ({ onPress, style, description, items }) => {

  const handlePress = () => {
    onPress();
  }

  return (
    <View style={{ ...style }}>
      <TouchableOpacity
        style={styles.body}
        onPress={handlePress}
      >
        <Text
          style={styles.description}
        >
          {description}
        </Text>
        <View style={styles.imagesContainer}>
          {items.map((item, index) => (
            <Image
              source={{
                uri: item.icon,
              }}
              style={styles.image}
              resizeMode={"contain"}
              key={item.icon}
            />
          ))}
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
    body: {
        marginHorizontal: 16,
        padding: 10,
        paddingHorizontal: isMobile ? 10: 16,
        paddingVertical: isMobile ? 10 : 16,
        borderWidth: 0.5,
        borderRadius: 8,
        borderColor: "#410DAA33",
    },
    description: {
        fontSize: isMobile ? 12 : 14,
        fontWeight: "300",
        color: theme.textColors.LIGHT_GRAY,
        marginBottom: isMobile ? 0 : 10
    },
    image: {
        width: isMobile ? 35 : 60,
        height: isMobile ? 35 : 60,
        alignSelf: "center",
        borderRadius: 4,
        marginHorizontal: 5,
    },
    imagesContainer: { flexDirection: "row", marginTop: 8 }
});

export default AssessWellnessStrip;
