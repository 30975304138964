import React from "react";
import amplitude from "../../../utils/amplitude";
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Linking,
  ActivityIndicator,
  View,
  Platform,
  TouchableOpacity,
} from "react-native";
import { Text } from "react-native-paper";
import NavigationHeader from "../../Common/NavigationHeader";
import AlertModal from "../../Common/Modals/AlertModal";
import { FontAwesome5 } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { ImportFunction } from "./ImportFunction";
import {
  getUserDetails,
  isJama,
  isMobile,
  selectOneFile,
} from "../../../utils";
import { StackActions } from "@react-navigation/routers";
import { saveAs } from "file-saver";
import {
  camsPdfUploadUsingEmail,
  generateCamsAggregatorLink,
  getAccountAggregatorConsentStatus,
  updateCamsEmailCustomer,
  updateEmailCustomer,
} from "../../../api";
import { Button } from "react-native-paper";
import Modal from "../../Common/Modal";
import { saveCustomerProfileData } from "../../../api/account";
import Store from "../../../store";

export default class ImportStocks extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let showAggregratorSuccess = false;
    let cams_key = null;
    if (params) {
      cams_key = params.cams_key || null;
    }
    if (params && params.ecres) {
      showAggregratorSuccess = true;
    }
    let { block_feature_list } = Store.getState().userData.userProfileData;
    this.state = {
      data: [],
      fundEmail: "",
      cams_key,
      timer: 0,
      isLoading: false,
      isLoading3: false,
      aggregatorResponse: "Pending",
      showAggregatorModal: false,
      showAggregratorSuccess,
      email: getUserDetails().email,
      block_feature_list
    };
  }

  componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("ImportStocks", { path: global.location.href });
    } else {
      amplitude.logEvent("ImportStocks", {
        path: this.props.navigation.getState().routeName,
      });
    }
    const { showAggregratorSuccess } = this.state;
    // console.log("handle Cams Resp = ", showAggregratorSuccess);
    if (showAggregratorSuccess) {
      this.handleCamsResponse();
    }
  }

  static navigationOptions = () => ({
    tabBarVisible: false,
  });

  downloadSampleFile = () => {
    saveAs(
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/media/sample_uploaders/stock_imported_sample_file.xlsx",
      "stock_imported_sample_file.xlsx"
    );
  };

  downloadSampleFileMF = () => {
    saveAs(
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/media/sample_uploaders/fund_imported_sample_file.xlsx",
      "fund_imported_sample_file.xlsx"
    );
  };

  _header() {
    return (
      <NavigationHeader
        navigation={this.props.navigation}
        navigationHeader={["Import Stock and Mutual Funds"]}
        onPressBack={() => {
          if (
            this.props.route.params &&
            this.props.route.params.from == "attachexisting"
          ) {
            this.props.navigation.navigate("Goals", {
              screen: "AttachExisting",
              params: {
                active: this.props.route.params.active,
                goal_name: this.props.route.params.goal_name,
              },
            });
          }

          // this.props.navigation.replace("AttachExisting", {
          //   goal_id,
          //   active: this.state.activeIndex,
          //   goal_name,
          // });

          // if (this.props.route.params && this.props.route.params.from) {
          //   if (this.props.route.params.from == "dashboard") {
          //     this.props.navigation.dispatch(
          //       StackActions.replace("App", {
          //         screen: "Dashboard",
          //         params: {
          //           screen: "Dashboard",
          //           params: {
          //             screen: "Home",
          //             params: {},
          //           },
          //         },
          //       })
          //     );
          //   }
          //   if (this.props.route.params.from == "portfolio") {
          //     this.props.navigation.dispatch(
          //       StackActions.replace("App", {
          //         screen: "Dashboard",
          //         params: {
          //           screen: "Portfolio",
          //           params: {
          //             screen: "InvestmentOverview",
          //             params: {},
          //           },
          //         },
          //       })
          //     );
          //   }
          // } else {
          //   this.props.navigation.dispatch(
          //     StackActions.replace("App", {
          //       screen: "Dashboard",
          //       params: {
          //         screen: "Dashboard",
          //         params: {
          //           screen: "Home",
          //           params: {},
          //         },
          //       },
          //     })
          //   );
          // }
          else {
            this.props.navigation.dispatch(
                    StackActions.replace("App", {
                      screen: "Dashboard",
                      params: {
                        screen: "Portfolio",
                        params: {
                          screen: "InvestmentOverview",
                          params: {},
                        },
                      },
                    })
                  );
          }
        }}
      />
    );
  }

  _selectFile = (fileType) => {
    this.setState({ fileLoading: false });
    selectOneFile(fileType).then((res) => {
      if (res) {
        this.props.navigation.navigate("App", {
          screen: "Dashboard",
          params: {
            screen: "Portfolio",
            params: {
              screen: "ImportStocksDetails",
              params: {
                file: res,
              },
            },
          },
        });
      }
    });
  };

  _selectFileMF = (fileType) => {
    this.setState({ fileLoading: false });
    selectOneFile(fileType).then((res) => {
      if (res) {
        this.props.navigation.navigate("App", {
          screen: "Dashboard",
          params: {
            screen: "Portfolio",
            params: {
              screen: "ImportMFDetails",
              params: {
                file: res,
              },
            },
          },
        });
      }
    });
  };

  _navigateToEmail = () => {
    if(isJama()){
      Linking.openURL("mailto:advisor@jamawealth.com");
    }else{
      Linking.openURL("mailto:advisor@maxiomwealth.com");
    }
    return;
  };

  _updateEmail = async () => {
    const { email } = this.state;
    const emails = email.split(",");
    let emailResponseDict = {};
    Promise.all(
      emails.map(async (email) => {
        const resp = await camsPdfUploadUsingEmail({ email });
        emailResponseDict[email] = resp;
        return resp && resp ? resp.code : "101";
      })
    )
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  updateemail() {
    updateEmailCustomer(this.state.email).then((json) => {
      if (json.hasOwnProperty("username")) {
        this.props.navigation.push("App", {
          screen: "Dashboard",
          params: {
            screen: "Portfolio",
            params: {
              screen: "WebImport",
              params: {
                email: this.state.email,
              },
            },
          },
        });
      }
    });
  }

  getImport() {
    var emailStore = getUserDetails().email;
    if (emailStore == "" || emailStore == null) {
      this.updateemail();
    } else {
      if (this.state.email == emailStore) {
        this.props.navigation.push("App", {
          screen: "Dashboard",
          params: {
            screen: "Portfolio",
            params: {
              screen: "WebImport",
              params: {
                email: this.state.email,
              },
            },
          },
        });
      } else {
        var CamsemailStore = getUserDetails().cams_mail;
        if (CamsemailStore == this.state.email) {
          this.props.navigation.push("App", {
            screen: "Dashboard",
            params: {
              screen: "Portfolio",
              params: {
                screen: "WebImport",
                params: {
                  email: this.state.email,
                },
              },
            },
          });
        } else {
          this.updateCamsemail();
        }
      }
    }
  }
  startCounter = () => {
    this.timerInterval = setInterval(() => {
      if (this.state.timer > 0) {
        this.setState((prevState) => ({
          timer: prevState.timer - 1,
        }));
      } else {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  };

  handleCamsResponse = async () => {
    this.setState({ showAggregatorModal: true, timer: 60 });
    this.startCounter();
    let fail_count = 0;
    const interval = setInterval(async () => {
      // console.log("Inside Timeout function");
      let response = await getAccountAggregatorConsentStatus();
      // console.log("response is ", response);
      if (response && response.code == "100") {
        if (
          response.result == "Status Approved" ||
          response.result == "Status Rejected"
        ) {
          this.setState({ aggregatorResponse: response.result });
          return;
        } else {
          fail_count++;
        }
      } else {
        fail_count++;
      }
      if (fail_count >= 12 || this.state.timer == 0) {
        this.setState({ aggregatorResponse: "Something went wrong" });
        clearInterval(interval);
        return;
      }
    }, 5000);
    return () => clearInterval(interval);
  };

  handleAggregator = async () => {
    let userData = await getUserDetails();
    let payload = {
      mobilenumber: userData.mobilenumber,
      pannumber: userData.pan_number,
      useCaseid: "331",
      fipid: "CAMC,Cams Depository",
      redirect_url:
        Platform.OS == "web"
          ? `https://${global.location.hostname}/dashboard/home`
          : "https://app.jamawealth.com/home?ecres=true",
      cams_key: "_cams_key",
    };
    // console.log("current urls is ", payload.redirect_url);
    this.setState({ isLoading: true, aggregatorResponse: "Pending" });
    let response = await generateCamsAggregatorLink(payload);
    this.setState({ isLoading: false });
    if (Platform.OS === "web") {
      global.open(response.result.redirectionurl, "_self");
    } else {
      Linking.openURL(response.result.redirectionurl);
    }
  };

  updateCamsemail() {
    let cust_id = getUserDetails().customer_id;
    let username = getUserDetails().username;
    updateCamsEmailCustomer(cust_id, {
      username: username,
      cams_mail: this.state.email,
    }).then((json) => {
      if (json.hasOwnProperty("username")) {
        this.props.navigation.push("App", {
          screen: "Dashboard",
          params: {
            screen: "Portfolio",
            params: {
              screen: "WebImport",
              params: {
                email: this.state.email,
              },
            },
          },
        });
      }
    });
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {this._header()}
        {this.state.isLoading ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" color="#410DAA" />
            <Text style={{ marginTop: 20, fontWeight: "bold", fontSize: 17 }}>
              Fetching details, please wait...
            </Text>
          </View>
        ) : (
          <>
            {this.state.showAggregratorSuccess && (
              <Modal
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    padding: 50,
                  }}
                >
                  {this.state.aggregatorResponse == "Status Approved" ? (
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesome5 name="check" size={32} color="green" />
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontWeight: "700",
                          marginTop: 15,
                        }}
                      >
                        Yay! Your consent status is approved
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          fontWeight: "400",
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        The account aggregator will send your portfolio data
                        within ten minutes.
                      </Text>
                      {/* <Text style={{
                      color: '#1a1a1a',
                      fontSize: 14,
                      fontWeight: '400',
                      marginTop: 5
                    }} >We will email you once received.</Text> */}
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 30,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            backgroundColor: "#410DAA",
                            paddingHorizontal: 20,
                            paddingVertical: 10,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                          }}
                          onPress={async () => {
                            this.props.navigation.navigate("App", {
                              screen: "Dashboard",
                              params: {
                                show_card_view: true,
                              },
                            });
                            this.setState({
                              showAggregatorModal: false,
                              showAggregratorSuccess: false,
                            });
                          }}
                        >
                          <Text style={{ color: "#fff", textAlign: "center" }}>
                            Continue
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  ) : this.state.aggregatorResponse == "Status Rejected" ? (
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 20,
                      }}
                    >
                      <MaterialIcons name="cancel" size={32} color="red" />
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontWeight: "700",
                          marginTop: 15,
                        }}
                      >
                        Oh oh! Looks like you did not consent
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          fontWeight: "400",
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        Please note: your portfolio analysis is only to help
                        simply grow it (to import again click on Fetch
                        Portfolio)
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          textAlign: "center",
                          fontWeight: "400",
                          marginTop: 5,
                        }}
                      >
                        You may try other import options to pick and choose what
                        you wish to import
                      </Text>
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 30,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            backgroundColor: "#410DAA",
                            padding: 10,
                            width: 150,
                            marginRight: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                          }}
                          onPress={() => {
                            this.setState({ showAggregatorModal: false });
                          }}
                        >
                          <Text style={{ color: "#fff", textAlign: "center" }}>
                            Fetch Portfolio
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={{
                            backgroundColor: "#fff",
                            padding: 10,
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            width: 250,
                            marginRight: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                          }}
                          onPress={() => {
                            this.setState({
                              showAggregatorModal: false,
                              step: 2,
                            });
                          }}
                        >
                          <Text
                            style={{ color: "#410DAA", textAlign: "center" }}
                          >
                            Try Other Import Options
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  ) : this.state.aggregatorResponse == "Pending" ? (
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ActivityIndicator size={"large"} color={"#410DAA"} />
                      {this.state.timer > 40 ? (
                        <View>
                          <Text
                            style={{
                              color: "#1a1a1a",
                              fontWeight: "700",
                              marginTop: 15,
                            }}
                          >
                            Fetching consent status from Account Aggregator
                          </Text>
                          <Text
                            style={{
                              color: "#1a1a1a",
                              fontSize: 14,
                              fontWeight: "400",
                              marginTop: 8,
                            }}
                          >
                            This may take several minutes.
                          </Text>
                          <Text
                            style={{
                              color: "#1a1a1a",
                              fontSize: 14,
                              fontWeight: "400",
                              marginTop: 8,
                            }}
                          >
                            If it takes longer, we will notify you once the
                            analysis is ready
                          </Text>
                        </View>
                      ) : (
                        <View>
                          <Text
                            style={{
                              color: "#1a1a1a",
                              fontWeight: "700",
                              marginTop: 15,
                            }}
                          >
                            Oops, this is taking longer than expected.{" "}
                          </Text>
                          <Text
                            style={{
                              color: "#1a1a1a",
                              fontSize: 14,
                              fontWeight: "400",
                              marginTop: 8,
                            }}
                          >
                            Our team will get back to you once the data from the
                            AA is received.{" "}
                          </Text>
                          <Text
                            style={{
                              color: "#1a1a1a",
                              fontSize: 14,
                              fontWeight: "400",
                              marginTop: 8,
                            }}
                          >
                            You may log in later to see the status on the
                            dashboard.
                          </Text>
                          <View
                            style={{
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: 30,
                            }}
                          >
                            <TouchableOpacity
                              style={{
                                backgroundColor: "#410DAA",
                                paddingHorizontal: 20,
                                paddingVertical: 10,
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 5,
                              }}
                              onPress={async () => {
                                this.props.navigation.navigate("App", {
                                  screen: "Dashboard",
                                  params: {
                                    show_card_view: true,
                                  },
                                });
                                this.setState({
                                  showAggregatorModal: false,
                                  showAggregratorSuccess: false,
                                });
                              }}
                            >
                              <Text
                                style={{ color: "#fff", textAlign: "center" }}
                              >
                                Continue
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      )}
                    </View>
                  ) : (
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MaterialIcons
                        name="error-outline"
                        size={32}
                        color="red"
                      />
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontWeight: "700",
                          marginTop: 15,
                        }}
                      >
                        {" "}
                        Oops! Your consent status is still not received
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          fontWeight: "400",
                          textAlign: "center",
                          marginTop: 10,
                        }}
                      >
                        We will check with the account aggregator and keep you
                        updated.
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          fontWeight: "400",
                          textAlign: "center",
                          marginTop: 5,
                        }}
                      >
                        you can try other options to import your portfolio
                      </Text>
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 30,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            backgroundColor: "#410DAA",
                            padding: 10,
                            justifyContent: "center",
                            width: 150,
                            marginLeft: 20,
                            alignItems: "center",
                            borderRadius: 5,
                          }}
                          onPress={() => {
                            this.setState({
                              showAggregatorModal: false,
                              step: 2,
                            });
                          }}
                        >
                          <Text style={{ color: "#fff", textAlign: "center" }}>
                            Continue
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                </View>
              </Modal>
            )}
            <ScrollView showsVerticalScrollIndicator={false}>
              {!this.state.block_feature_list == "CAMS_AA" && <ImportFunction
                title="Account Aggregrator Fetch Stock and Mutual Funds Detail"
                description={[
                  {
                    text: "Kindly provide consent to access stock and mutual funds detail",
                  },
                ]}
                sample={false}
                error={""}
                buttonTitle="Provide Consent To Fetch Details"
                onPress={this.handleAggregator}
                btnStyle={
                  isMobile
                    ? {
                        marginLeft: "0%",
                        width: "90%",
                      }
                    : {}
                }
                style={{
                  paddingHorizontal: 16,
                  width: "84%",
                  marginTop: 30,
                }}
              />}
              <View
                showsVerticalScrollIndicator={true}
                style={{ flex: 1, flexDirection: "row" }}
                keyboardShouldPersistTaps="handled"
              >
                <View style={{ flexDirection: "row" }}>
                  <ImportFunction
                    title="Direct Stock Upload"
                    description={[
                      {
                        text: "Upload your stock portfolio as per sample shared below.",
                      },
                      {
                        text: "Search email for subject 'Your NSDL CAS'.",
                        color: "#a8a8a8",
                      },
                    ]}
                    sample={true}
                    error={""}
                    buttonTitle="Select File"
                    onPress={() => {
                      this._selectFile([
                        "application/pdf",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      ]);
                    }}
                    downloadSample={() => {
                      this.downloadSampleFile();
                    }}
                    style={{
                      paddingHorizontal: 16,
                      paddingVertical: 30,
                      width: "60%",
                    }}
                  />
                  <ImportFunction
                    title="E-mail (Stocks)"
                    description={[
                      {
                        text: "Simply forward your NSDL Consolidated Statement to upload@maxiom.in.",
                      },
                      {
                        text: "Search email for subject 'Your NSDL CAS'.",
                        color: "#a8a8a8",
                      },
                    ]}
                    sample={true}
                    error={""}
                    buttonTitle="Open Email"
                    style={{
                      paddingHorizontal: 16,
                      paddingVertical: 30,
                      width: "60%",
                    }}
                    onPress={() => {
                      this._navigateToEmail();
                    }}
                    downloadSample={() => {
                      this.downloadSampleFile();
                    }}
                  />
                </View>
              </View>
              <View
                showsVerticalScrollIndicator={true}
                style={{
                  flex: 1,
                  flexDirection: "row",
                  marginTop: -40,
                }}
                keyboardShouldPersistTaps="handled"
              >
                <View style={{ flexDirection: "row" }}>
                  <ImportFunction
                    title="Direct Mutual Funds Upload"
                    description={[
                      {
                        text: "Upload your MF portfolio as per sample shared below.",
                      },
                      {
                        text: "Addition details related to the functionality.",
                        color: "#a8a8a8",
                      },
                    ]}
                    sample={true}
                    error={""}
                    buttonTitle="Select File"
                    onPress={() => {
                      this._selectFileMF([
                        "application/pdf",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      ]);
                    }}
                    downloadSample={() => {
                      this.downloadSampleFileMF();
                    }}
                    style={{
                      paddingHorizontal: 16,
                      paddingVertical: 30,
                      width: "72%",
                    }}
                  />
                  <ImportFunction
                    title="E-mail (Mutual Funds)"
                    description={[
                      {
                        text: "Enter email id used for any mutual fund investment.",
                      },
                      // {
                      //   text: "If you have CAS, please forward it to help@maxiom.in ",
                      //   color: "#a8a8a8"
                      // },
                      // {
                      //   text: "and we will import it, else click on Request Import",
                      //   color: "#a8a8a8"
                      // }
                    ]}
                    sample={false}
                    error={""}
                    buttonTitle="Request Import"
                    style={{
                      paddingHorizontal: 16,
                      paddingVertical: 30,
                      width: "72%",
                    }}
                    emailInput={true}
                    whyDoThis={() => {
                      this.setState({
                        dialogVisible: !this.state.dialogVisible,
                      });
                    }}
                    email={this.state.email}
                    updateEmail={(email) => {
                      this.setState({ email });
                    }}
                    onPress={() => {
                      this.getImport();
                      this._updateEmail();
                    }}
                    downloadSample={() => {
                      this.downloadSampleFile();
                    }}
                  />
                </View>
              </View>
              <View
                style={{
                  marginLeft: 16,
                  marginBottom: 100,
                  width: "75%",
                  alignItems: "flex-end",
                }}
              >
                <Text style={{ color: "#a8a8a8" }}>
                  If you have CAS, please forward it to
                  <TouchableOpacity
                    onPress={() => {
                      if (Platform.OS === "web") {
                        global.open(
                          `mailto:help@${isJama() ? "jama.co.in" : "maxiom.in"}`
                        );
                      } else {
                        Linking.openURL(
                          `mailto:help@${isJama() ? "jama.co.in" : "maxiom.in"}`
                        );
                      }
                    }}
                  >
                    <Text style={{ color: "#410DAA", marginHorizontal: 3 }}>
                      help@{isJama() ? "jama.co.in" : "maxiom.in"}
                    </Text>
                  </TouchableOpacity>
                  and we will import it, else click on Request Import
                </Text>
              </View>
            </ScrollView>
          </>
        )}
        <AlertModal
          visible={this.state.showAggregratorSuccess}
          style={{}}
          closeModal={() => {
            this.setState({ showAggregratorSuccess: false });
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: "bold",
              textAlign: "left",
              marginBottom: 5,
              color: "#121212",
            }}
          >
            Stock and Mutual Funds authentication successful.
          </Text>
          <Text
            style={{
              fontSize: 16,
              fontWeight: "bold",
              textAlign: "left",
              marginBottom: 5,
              color: "#121212",
            }}
          >
            Once we receive data, our sales team will connect with you.
          </Text>
        </AlertModal>
        <AlertModal
          visible={this.state.dialogVisible}
          style={{}}
          closeModal={() => {
            this.setState({ dialogVisible: false });
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: "bold",
              textAlign: "left",
              marginBottom: 5,
              color: "#121212",
            }}
          >
            How it works
          </Text>
          <Text style={{ fontSize: 13, color: "#121212", marginVertical: 5 }}>
            1. We can't access your CAS. We can only request it to be sent to
            your email id seemly.
          </Text>
          <Text style={{ fontSize: 13, color: "#121212" }}>
            2. Once you get the CAS, please forward the mail to{" "}
            <TouchableOpacity
              onPress={() => {
                if (Platform.OS === "web") {
                  global.open("mailto:help@maxiom.in");
                } else {
                  Linking.openURL("mailto:help@maxiom.in");
                }
              }}
            >
              <Text style={{ fontSize: 13, color: "#410DAA" }}>
                help@maxiom.in
              </Text>
            </TouchableOpacity>{" "}
            and we will import it.
          </Text>
          <Text style={{ fontSize: 13, color: "#121212", marginVertical: 5 }}>
            3. Track all funds & Get expert advice.
          </Text>
        </AlertModal>
      </SafeAreaView>
    );
  }
}
