import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import {View, Text, StyleSheet, TouchableOpacity, Image} from 'react-native';
import { isMobile } from '../../../../utils';
import BulletPoint from './BulletPoint';

const Compliance = ({ data = {}, style = [], onClick }) => {
    const { title, summary=[], extra } = data;
    
    const handleClick = () => {
        onClick();
    };
    
    const ArrowIcon = require('../../../../assets/JamaInvest/Arrow-right2x.png');

    return(
        <TouchableOpacity style={[strip.border, strip.container, ...style]} onPress={handleClick}>
            <>
                <View style={strip.titleContainer}>
                    <Text style={strip.title}>{title}</Text>
                    {
                        true && (
                            <Image source={ArrowIcon} style={[ {width: !isMobile? 18: 8,height: !isMobile? 18: 8}]}></Image>
                        )
                    }
                </View>
                <View style={strip.pointsContainer}>
                    {
                        summary.map((point)=>{
                            return(
                                    <View style={strip.point}>
                                        <BulletPoint outerColor="#888C9F60" innerColor="#888C9F" style={[strip.bulletPoint]}/>
                                        <Text style={strip.pointText}>{point}</Text>
                                    </View>
                            )
                        })
                    }
                </View>
                {
                    extra && (
                        <View style={strip.extraContainer}>
                            <Text style={strip.extra}>{extra}</Text>
                        </View>
                    )
                }

            </>
        </TouchableOpacity>
    )
}

const strip = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        paddingTop: 15,
        paddingBottom: 8,
        height: 120,
    },
    border: {
        borderWidth: 1,
        // borderColor: "#F53A5C",
        borderColor:"#410DAA33",
        borderRadius: 15
    },
    image: {
        width: 40,
        height: 40,
        position: "absolute",
        right: 25,
        top: 40
    },
    titleContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    title: {
        fontSize: 17,
        color: "#494B69",
        fontWeight: "bold",
        lineHeight: 20,
        marginRight: 10
    },
    pointsContainer: {
        marginTop: 15
    },
    point: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 10
    },
    pointText: {
        fontWeight: "bold",
        fontFamily: "Roboto",
        color: "#494B69",
        fontSize: 12,
    },
    bulletPoint: {
        marginRight: 7
    },
    extraContainer: {
        flexDirection: "row",
        alignItems: "flex-start"
    },
    extra: {
        fontFamily: "Roboto",
        fontStyle: "italic",
        fontSize: 12,
        color: "#494B69",
        marginLeft: 16
    }
});

export default Compliance;