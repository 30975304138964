import React, { Children } from 'react';
import {
	ScrollView,
	View,
	Image,
	StyleSheet,
	Text,
	Dimensions,
	Button,
	TouchableOpacity,
	StatusBar,
	FlatList
} from 'react-native';
import { completeMinDetails, isMobile, isWeb } from '../../../utils';
const { width } = Dimensions.get("window");
import { md } from '../../../Responsive';
import MiniOnBoardingProgressBar from './MiniOnBoardingProgressBar';
import Store from '../../../store';
import TextInputField from '../../../components/Common/Inputs/InputField';
import PickerFieldSelect from '../../../components/Common/Pickers/PickerFieldSelect';
import HorizontalRadio from '../../../components/Common/Radio/HorizontalRadio';
import { finplanSummarySave, getFinplanDetails, postBasicDetails, postChildBachelors, postChildDetails, postChildPG, postChildWedding, postIncomeDetails, postMaritalStatus, postWhenBike, postWhenCar, postWhenHome, postWhenRetire } from '../../../api/finplan';
import { RadioButton } from 'react-native-paper';
import theme from '../../../static/theme';
import Header from '../header_after_login';
import { validateInput } from '../../../utils/validateInput';
import { YesNo } from '../../mutualfunds/components/yesno';
import ProgressBar from '../../../utils/progressBar';
import { createCrmdealsCipCbiForAccentureClients, createCrmdealsCipForRegular, updateCustomerGoalValues } from '../../../api';

const RadioOption = ({ onSelect, selected, title }) => {
	return (
		<TouchableOpacity
			onPress={() => {
				onSelect();
			}}
			style={{
				flexDirection: "row",
				alignItems: "center",
				marginVertical: 5,
			}}
		>
			<RadioButton.Android
				onPress={() => {
					onSelect();
				}}
				uncheckedColor={"gray"}
				color={theme.colors.NEW_BRAND_COLOR}
				status={selected ? "checked" : "unchecked"}
				style={{ alignSelf: "flex-start" }}
			/>
			<Text
				style={{
					color: selected ? theme.colors.NEW_BRAND_COLOR : "black",
					paddingLeft: 20,
				}}
			>
				{title}
			</Text>
		</TouchableOpacity>
	);
};

class FinPlansGoalsCollections extends React.Component {
	constructor(props) {
		super(props);
		const { params } = this.props.route;
		let goalType = "BASIC"
		let type = "BASIC"
		let basic_goals_stacks = ["BASIC", "IncomeDetails", "MaritalStatus", "BuyHome", "WhenBuyHome", "BuyCar", "WhenBuyCar", "BuyBike", "WhenBuyBike", "WhenRetire"];
		let retirement_goal_stack = ["BASIC", "IncomeDetails", "WhenRetire"];
		let children_goal_stack = ["BASIC", "IncomeDetails", "MaritalStatus", "ChildrenDetails", "ChildrenBachelors"]
		let { is_goal } = Store.getState().userData;
		if (params) {
			type = params.goalType || "BASIC";
		}
		this.state = {
			type,
			goalType,
			is_goal,
			isLoading: false,
			name: "",
			nameError: "",
			age: "",
			ages: [],
			ageError: "",
			gender: "",
			genderError: "",
			monthlyIncome: 0,
			monthlyExpense: 0,
			incomeError: "",
			expenseError: "",
			maritalStatus: "M",
			no_of_children: 0,
			childrens: [],
			childrenDetails: [],
			formErrors: {},
			numberOfChildren: null,
			maritalStatusError: null,
			numberOfChildrenError: null,
			years: null,
			yearsData: null,
		}
	}

	generateAgeData = (min, max) => {
		let ages = [];
		for (let i = min; i <= max; i++) {
			const num = i;
			ages.push({
				label: num.toString(),
				value: num,
			});
		}
		return ages;
	};

	async componentDidMount() {
		const ages = this.generateAgeData(18, 80);
		try {
			const ages = this.generateAgeData(18, 80);
			const response = await getFinplanDetails();
			let name = null,
				age = null,
				gender = null;
			if (response.code == 100) {
				const { results } = response;
				age = results.age;
				name = results.name;
				gender = results.gender;
			}
			this.setState({
				isLoading: false,
				name,
				age,
				gender,
				ages,
			});
		} catch (error) { }
		this.setState({ ages })
	}

	handleBasicPlanContinue = async () => {
		if (this.state.isLoading) return;
		this.setState({ nameError: null, ageError: null, genderError: null });
		let { name, age, gender } = this.state;
		if (!name || (name && name.trim() == "")) {
			this.setState({ nameError: "Name cannot be empty" });
			return;
		}
		if (!age || age == "Select") {
			this.setState({ ageError: "Age cannot be empty" });
			return;
		}
		if (!gender) {
			this.setState({ genderError: "Gender cannot be empty" });
			return;
		}

		this.setState({ isLoading: true, visibleModal: false });
		const payload = {
			name,
			age: parseInt(age),
			gender,
		};
		const response = await postBasicDetails(payload);
		if (response.code == 100) {
			try {
				const response = await getFinplanDetails();
				if (response.code == 100) {
					const { results } = response;
					const { income, expense } = results;
					this.setState({
						monthlyIncome: income.toString(),
						monthlyExpense: expense.toString(),
					});
				}
			} catch (error) { }
			this.setState({ isLoading: false });
			this.setState({ goalType: "IncomeDetails" })
		}
	}


	handleChildrenPostGraduationContinue = async () => {
		try {
			// console.log("Hello")
			this.setState({isLoading: true})
			const { type } = this.state;
			const { childrenDetails = [], numberOfChildren = 0 } = this.state;
			let total = numberOfChildren;
			let flag = false;
			let names = "",
				edu_values = "";
			const values = ["IND", "USC", "OTH", "NOP"];
			let formErrors = {};
			// console.log("handleChildrenPostGraduationContinue =", total);
			for (let i = 0; i < total; i++) {
				formErrors[`${i}`] = {};
				const children = childrenDetails[i];
				// console.log("handleChildrenPostGraduationContinue =", children);
				if (children && children.pg) {
					flag = false;
					names += `${children.name}`;
					if (i < total - 1) {
						names += ";";
					}
					edu_values += `${children.pg};`;
				} else {
					flag = true;
					formErrors[`${i}`]["error"] = "Select any one option";
				}
			}
			if (flag) {
				this.setState({ formErrors });
			} else {
				const payload = {
					names,
					edu_values,
				};
				const response = await postChildPG(payload);
				if (response && response.code == 100) {
					const options = {
						type: "children",
					};
					let data = [];
					let childrenData = [];
					const response = await getFinplanDetails(options);
					if (response.code == 100) {
						const { results } = response;
						const { children = [] } = results;
						childrenData = [...children.splice(0, this.state.numberOfChildren)];
					} else {
						childrenData = [...Array(this.state.total)].fill(null);
					}
					childrenData.forEach((child, index) => {
						data.push({
							name: child
								? child["name_of_mapped_family_member"]
								: "Child " + (index + 1),
							wedding: child ? child["wedding_plan"] : null,
						});
					});

					this.setState({
						childrenDetails: [...data],
					});
					// console.log("Children details is 247 ", this.state.childrenDetails)
					this.setState({ isLoading: false, formErrors: {}, goalType: "ChildrenWedding" });

				} else {
					this.setState({ isLoading: false, formErrors: {} });
				}
			}
		} catch (error) {
			this.setState({ isLoading: false, formErrors: {} });
		}
	}

	generateData = (min, max) => {
		let childrens = [];
		for (let i = min; i <= max; i++) {
			const num = i;
			if (i == "0") {
				childrens.push({
					label: num.toString(),
					value: "zero",
				});
			} else {
				childrens.push({
					label: num.toString(),
					value: num,
				});
			}
		}
		this.setState({ childrens });
	};

	handleIncomeDetailsContinue = async () => {
		const { type } = this.state;
		if (this.state.isLoading) return;
		let { monthlyIncome, monthlyExpense, incomeError, expenseError } =
			this.state;
		if (monthlyIncome < 10000 || monthlyIncome > 2000000) {
			incomeError = "Income should be in range 10K - 20L";
		}
		if (monthlyExpense < 10000 || monthlyExpense > 2000000) {
			expenseError = "Expense should be in range 10K - 20L";
		}

		if (!incomeError && !expenseError) {
			this.setState({ isLoading: true, expenseError: null });

			const payload = {
				income: monthlyIncome,
				expense: monthlyExpense,
			};
			let screen = "MaritalStatus";
			if (type == "RETIREMENT") {
				screen = "WhenRetire";
			}

			const response = await postIncomeDetails(payload);

			if (response.code == 100) {
				this.setState({ isLoading: false });
				const { params = {} } = this.props.route;
				const { from, type, ...restParams } = params;
				try {
					const response = await getFinplanDetails();
					if (response.code == 100) {
						const { results } = response;
						const { is_married } = results;
						this.setState({
							maritalStatus: is_married ? "M" : "S",
							numberOfChildren: "0",
						});
					}
					this.generateData(0, 10);
				} catch (error) { }
				console.log("Screen name is ", screen);

				if (screen == "WhenRetire") {
					// console.log("Screen name is ", type);
					const response = await getFinplanDetails();
					let age = 50;
					if (response.code == 100) {
						const { results } = response;
						const { retirement_age } = results;
						age = retirement_age;
					}
					const yearsData = this.generateDataYear(35, 80);
					this.setState({ yearsData, age });
					this.setState({ isLoading: false });
				}
				this.setState({ goalType: screen })
			}
		}
	}

	handleMaritalStatusContinue = async () => {
		const { numberOfChildren = 0, maritalStatus, type } = this.state;
		let number = numberOfChildren ? parseInt(numberOfChildren) : 0;
		if (numberOfChildren == "zero") {
			number = 0;
		}

		if (maritalStatus == null) {
			this.setState({ maritalStatusError: "Select one option!" });
			return;
		}
		if (
			(numberOfChildren == null ||
				(numberOfChildren == "0" && numberOfChildren != "zero")) &&
			maritalStatus != "S"
		) {
			this.setState({ numberOfChildrenError: "Select the number of children" });
			return;
		}

		this.setState({ isLoading: true });
		const payload = {
			is_single: maritalStatus == "S" ? "true" : "false",
			no_of_kids: number,
		};
		const { params = {} } = this.props.route;
		const { ...restParams } = params;
		const response = await postMaritalStatus(payload);
		if (response.code == 100) {
			try {
				const response = await getFinplanDetails();
				let years = null;
				if (response.code == 100) {
					const { results } = response;
					const { years_to_own_house } = results;
					years = years_to_own_house;
				}
				const yearsData = this.generateDataYear(1, 20);
				this.setState({ yearsData, years });
			} catch (error) { }
			if (number == 0 || maritalStatus == "S") {
				let screen = "BuyHome";
				if (type == "CHILDREN") {
					const ages = this.generateAgeData(0, 50);
					const options = {
						type: "children",
					};
					let formErrors = [];
					let childrenDetails = [];
					const response = await getFinplanDetails(options);
					if (response.code == 100) {
						const { results } = response;
						const { children = [] } = results;
						const numberOfChildrenStored = children.length;
						let { numberOfChildren = 0 } = this.state;
						for (let i = 0; i < numberOfChildren; i++) {
							formErrors.push({
								name:
									i < numberOfChildrenStored
										? children[i]["name_of_mapped_family_member"]
											? null
											: "Name can not be empty!"
										: null,
								age:
									i < numberOfChildrenStored
										? children[i]["age"]
											? null
											: "Age can not be empty!"
										: null,
							});
							childrenDetails.push({
								name:
									i < numberOfChildrenStored
										? children[i]["name_of_mapped_family_member"]
										: null,
								age: i < numberOfChildrenStored ? children[i]["age"] : null,
							});
						}
					}
					this.setState({ formErrors, ages, childrenDetails });
					screen = "ChildernDetails";
				} else if (type == "COMPLETE") {
					screen = "BuyHome";
				}
				this.setState({ goalType: screen })
			} else {
				// this.props.navigation.navigate("ChildrenDetails", {
				// 	no_of_children: number,
				// 	type,
				// 	...restParams,
				// });
				const ages = this.generateAgeData(0, 50);
				const options = {
					type: "children",
				};
				let formErrors = [];
				let childrenDetails = [];
				const response = await getFinplanDetails(options);
				if (response.code == 100) {
					const { results } = response;
					const { children = [] } = results;
					const numberOfChildrenStored = children.length;
					let { numberOfChildren = 0 } = this.state;
					for (let i = 0; i < numberOfChildren; i++) {
						formErrors.push({
							name:
								i < numberOfChildrenStored
									? children[i]["name_of_mapped_family_member"]
										? null
										: "Name can not be empty!"
									: null,
							age:
								i < numberOfChildrenStored
									? children[i]["age"]
										? null
										: "Age can not be empty!"
									: null,
						});
						childrenDetails.push({
							name:
								i < numberOfChildrenStored
									? children[i]["name_of_mapped_family_member"]
									: null,
							age: i < numberOfChildrenStored ? children[i]["age"] : null,
						});
					}
				}
				// console.log("Children details is ", childrenDetails);
				this.setState({ formErrors, ages, childrenDetails });
				this.setState({ goalType: 'ChildernDetails' })
			}
		}
		this.setState({ isLoading: false });
	}

	handleBuyHomeContinue = async () => {
		const { years } = this.state;
		const is_valid_year = this.validateYears(years);
		if (is_valid_year) {
			this.setState({ error: null, isLoading: true });
			const payload = {
				house_years: years,
			};
			const response = await postWhenHome(payload);
			if (response && response.code == 100) {
				const response = await getFinplanDetails();
				let years = null;
				if (response.code == 100) {
					const { results } = response;
					const { years_to_own_car } = results;
					years = years_to_own_car;
				}
				const yearsData = this.generateDataYear(1, 20);
				this.setState({ yearsData, years, isLoading: false });
				this.setState({ goalType: "BuyCar" });
			}
		} else {
		}
	}

	handleBuyCarContinue = async () => {
		const { years } = this.state;
		const is_valid_year = this.validateYears(years);
		if (is_valid_year) {
			this.setState({ error: null, isLoading: true });
			const payload = {
				years,
			};
			const response = await postWhenCar(payload);
			if (response && response.code == 100) {
				const response = await getFinplanDetails();
				let years = null;
				if (response.code == 100) {
					const { results } = response;
					const { years_to_own_bike } = results;
					years = years_to_own_bike;
				}
				const yearsData = this.generateDataYear(1, 20);
				this.setState({ yearsData, years });
				this.setState({ isLoading: false });
				this.setState({ goalType: "BuyBike" });
			}
		}
	}

	handleBuyBikeContinue = async () => {
		const { years, error = null } = this.state;
		if (years > 0 && error == null) {
			this.setState({ error: null, isLoading: true });
			const payload = {
				years,
			};
			const response = await postWhenBike(payload);
			if (response && response.code == 100) {
				const response = await getFinplanDetails();
				let age = 50;
				if (response.code == 100) {
					const { results } = response;
					const { retirement_age } = results;
					age = retirement_age;
				}
				const yearsData = this.generateDataYear(35, 80);
				this.setState({ yearsData, age });
				this.setState({ isLoading: false });
				this.setState({ goalType: "WhenRetire" })
				// const summary =  await finplanSummarySave(options);
			}
		} else {
			this.validateYears(this.state.years);
		}
	}


	handleRetirementContinue = async () => {
		this.setState({ showToast: "true" });
		const { age = 0 } = this.state;
		if (age >= 19 && age <= 80) {
			this.setState({ error: null, isLoading: true });
			const payload = {
				age,
			};
			const response = await postWhenRetire(payload);
			if (response && response.code == 100) {
				const options = {
					planning_for: "retirement",
				};
				const summary = await finplanSummarySave(options);
				if (summary.code == 100) {
					// navigate to next screen
					// this.props.navigation.navigate("AdvisoryPlanHome")
					const minDetailsResponse = await completeMinDetails(null, null, null, false);
					if (minDetailsResponse.needCompletion) {
						this.props.navigation.navigate("Onboarding", {
							screen: minDetailsResponse.redirect_screen
						});
					} else {
						let hostname = global.location.hostname
						if(hostname.includes("app") || hostname.includes("myadvisor")) {
							await createCrmdealsCipCbiForAccentureClients();
						 }
                        if(hostname.includes("club")) {
                          await createCrmdealsCipForRegular();
                        }
						await updateCustomerGoalValues();
						this.props.navigation.navigate("App", {
							screen: "Dashboard",
							params: {
								show_card_view: true
							  }
						});
					}
				} else {
					this.setState({ error: "Error creating finplan summary" });
					this.props.navigation.navigate("App", {
						screen: "Dashboard",
						params: {
							show_card_view: true
						}
					});
				}
				this.setState({ isLoading: false });
			}
		} else {
			this.validateYears(age);
		}
		this.setState({ isLoading: false });
	}

	planningCondition = async (goal, boolVal) => {
		if (goal == "Home") {
			if (boolVal) {
				const response = await getFinplanDetails();
				let years = null;
				if (response.code == 100) {
					const { results } = response;
					const { years_to_own_house } = results;
					years = years_to_own_house;
				}
				const yearsData = this.generateDataYear(1, 20);
				this.setState({ yearsData, years });
				this.setState({ goalType: "WhenBuyHome" })
			} else {
				this.setState({ goalType: "BuyCar" })
			}
		}
		if (goal == "Car") {
			if (boolVal) {
				const response = await getFinplanDetails();
				let years = null;
				if (response.code == 100) {
					const { results } = response;
					const { years_to_own_car } = results;
					years = years_to_own_car;
				}
				const yearsData = this.generateDataYear(1, 20);
				this.setState({ yearsData, years });
				this.setState({ goalType: "WhenBuyCar" })
			} else {
				this.setState({ goalType: "BuyBike" })
			}
		}
		if (goal == "Bike") {
			if (boolVal) {
				const response = await getFinplanDetails();
				let years = null;
				if (response.code == 100) {
					const { results } = response;
					const { years_to_own_bike } = results;
					years = years_to_own_bike;
				}
				const yearsData = this.generateDataYear(1, 20);
				this.setState({ yearsData, years });
				this.setState({ goalType: "WhenBuyBike" })
			} else {
				const response = await getFinplanDetails();
				let age = 50;
				if (response.code == 100) {
					const { results } = response;
					const { retirement_age } = results;
					age = retirement_age;
				}
				const yearsData = this.generateDataYear(35, 80);
				this.setState({ yearsData, age });
				this.setState({ goalType: "WhenRetire" })
			}
		}
	}

	handleChildrenDetailsContinue = async () => {
		const { is_valid, names, ages } = this.validate();
		if (is_valid) {
			this.setState({ isLoading: true });
			const payload = {
				names,
				ages,
			};
			const response = await postChildDetails(payload);
			if (response.code == 100) {
				// this.props.navigation.navigate("ChildrenBachelors", {
				//   total: this.state.no_of_children,
				//   type,
				// });
				let { numberOfChildren = 0 } = this.state;
				const options = {
					type: "children",
				};
				let data = [];
				let childrenData = [];
				const response = await getFinplanDetails(options);
				if (response.code == 100) {
					const { results } = response;
					const { children = [] } = results;
					childrenData = [...children.splice(0, numberOfChildren)];
				} else {
					childrenData = [...Array(numberOfChildren)].fill(null);
				}

				childrenData.forEach((child, index) => {
					data.push({
						name: child
							? child["name_of_mapped_family_member"]
							: "Child " + (index + 1),
						bachelors: child ? child["country_for_higher_studies"] : null,
					});
				});

				this.setState({
					childrenDetails: [...data],
				});
				this.setState({ goalType: 'ChildrenBachelors', isLoading: false })
			}
			this.setState({isLoading: false})
		}
	}


	handleWeddingContinue = async () => {
		const { type } = this.state;
		const { childrenDetails = [], numberOfChildren = 0 } = this.state;
		let total = numberOfChildren;
		let flag = false;
		this.setState({isLoading: true})
		let names = "",
			mar_values = "";
		let formErrors = {};
		for (let i = 0; i < total; i++) {
			formErrors[`${i}`] = {};
			const children = childrenDetails[i];
			if (children && children.wedding) {
				flag = false;
				names += `${children.name}`;
				if (i < total - 1) {
					names += ";";
				}
				mar_values += `${children.wedding};`;
			} else {
				flag = true;
				formErrors[`${i}`]["error"] = "Select any one option";
			}
		}

		if (flag) {
			this.setState({ formErrors });
		} else {
			const payload = {
				names,
				mar_values,
			};
			const response = await postChildWedding(payload);
			if (response && response.code == 100) {
				this.setState({ isLoading: false, formErrors: {} });
				let screen = "CreateFinplan";
				if (type == "RETIREMENT") {
					screen = "WhenRetire";
				} else if (type == "COMPLETE") {
					screen = "BuyHome";
				} else if (type == "CHILDREN") {
					screen = "Goals";
				} else if (type == "EMF") {
					screen = "Goals";
				}

				if (type == "CHILDREN") {
					// const options = {
					// 	planning_for: "all",
					// };
					// const summary = await finplanSummarySave(options);
					// if (summary.code == 100) {
						this.setState({  goalType: "WhenRetire" });
						this.setState({isLoading: false})
					// }
				} else {
					this.setState({goalType: "WhenRetire"})
					this.setState({isLoading: false})
				}
				const response = await getFinplanDetails();
				let age = 50;
				if (response.code == 100) {
					const { results } = response;
					const { retirement_age } = results;
					age = retirement_age;
				}
				const yearsData = this.generateDataYear(35, 80);
				this.setState({ yearsData, age });
				this.setState({ isLoading: false });
				this.setState({isLoading: false})
			}
			this.setState({isLoading: false})
		}
		this.setState({isLoading: false})
	}

	handleChildrenBachelorsContinue = async () => {
		const { childrenDetails = [], numberOfChildren } = this.state;
		let total = numberOfChildren;
		this.setState({isLoading: true})
		let flag = false;
		let names = "",
			edu_values = "";
		let formErrors = {};
		for (let i = 0; i < total; i++) {
			formErrors[`${i}`] = {};
			const children = childrenDetails[i];
			if (children && children.bachelors) {
				flag = false;
				names += `${children.name}`;
				if (i < total - 1) {
					names += ";";
				}
				edu_values += `${children.bachelors};`;
			} else {
				flag = true;
				formErrors[`${i}`]["error"] = "Select any one option";
			}
		}

		if (flag) {
			this.setState({ formErrors });
		} else {
			// this.setState({ isLoading: true });
			const payload = {
				names,
				edu_values,
			};
			const response = await postChildBachelors(payload);
			if (response && response.code == 100) {

				// this.props.navigation.navigate("ChildrenPG", {
				//   total,
				//   type,
				// });
				const options = {
					type: "children",
				};
				let data = [];
				let childrenData = [];
				let { numberOfChildren = 0 } = this.state;
				const response = await getFinplanDetails(options);
				if (response.code == 100) {
					const { results } = response;
					const { children = [] } = results;
					childrenData = [...children.splice(0, numberOfChildren)];
				} else {
					childrenData = [...Array(numberOfChildren)].fill(null);
				}
				childrenData.forEach((child, index) => {
					data.push({
						name: child
							? child["name_of_mapped_family_member"]
							: "Child " + (index + 1),
						pg: child ? child["country_for_pg_studies"] : null,
					});
				});

				this.setState({
					childrenDetails: [...data],
				});
				this.setState({ goalType: "ChildrenPG" })
				this.setState({isLoading: false})
			}
			this.setState({isLoading: false})
		}
		this.setState({isLoading: false})
	}

	setRadioValue = (index, value, callType = "Marriage") => {
		if (callType == "UG") {
			let childrenDetails = Object.assign([], this.state.childrenDetails);
			childrenDetails[index].bachelors = value;
			this.setState({ childrenDetails });
		} else if (callType == "PG") {
			let childrenDetails = Object.assign([], this.state.childrenDetails);
			childrenDetails[index].pg = value;
			this.setState({ childrenDetails });
		} else {
			let childrenDetails = Object.assign([], this.state.childrenDetails);
			childrenDetails[index].wedding = value;
			this.setState({ childrenDetails });
		}
	};

	clearError = (index) => {
		let formErrors = { ...this.state.formErrors };
		if (!(index in formErrors)) {
			formErrors[`${index}`] = {};
		}
		formErrors[`${index}`]["error"] = null;
		this.setState({ formErrors });
	};

	generateDataYear = (min, max) => {
		let years = [];
		for (let i = min; i <= max; i++) {
			const num = i;
			years.push({
				label: num.toString(),
				value: num,
			});
		}
		return years;
	};

	handleChange = (value, type, inputType) => {
		const error = validateInput(inputType, value);
		this.setState({
			[type]: value,
			[type + "Error"]: error,
		});
		return error;
	};

	validateYears = (years) => {
		let error = null;
		if (years > 100) {
			error = "Maximum number of years should be 100";
		} else if (years < 1) {
			error = "Minimum number of years should be 1";
		}
		this.setState({ years, error });
		return error == null;
	};

	getYear = () => {
		const year = new Date().getFullYear();
		const { years } = this.state;
		return year + parseInt(years);
	};

	setData = (key, value, index, error) => {
		let { childrenDetails: details, formErrors } = this.state;
		details = JSON.parse(JSON.stringify(details));
		details[index][`${key}`] = value;
		formErrors[index][key] = error;
		this.setState({ childrenDetails: details, formErrors });
	};

	validate = () => {
		let { childrenDetails, formErrors } = this.state;
		let flag = true,
			names = "",
			ages = "";
		let formErrorsUpdated = JSON.parse(JSON.stringify(formErrors));
		childrenDetails.forEach((item, index) => {
			if (!item["name"] || item["name"].trim() == "") {
				flag = false;
				formErrorsUpdated[index]["name"] = "Name can not be empty!";
			} else {
				names += `${item["name"]};`;
			}
			if (!item["age"] || item["age"] == 0) {
				flag = false;
				formErrorsUpdated[index]["age"] = "Age can not be empty!";
			} else {
				ages += `${item["age"]};`;
			}
		});
		this.setState({ formErrors: formErrorsUpdated });
		return {
			is_valid: flag,
			names,
			ages,
		};
	};

	renderDetails = ({ item, index }) => {
		const { formErrors } = this.state;
		return (
			<View
				style={{
					paddingLeft: 0,
					paddingBottom: 20,
				}}
				key={"#" + index}
			>
				<TextInputField
					label={`Child-${index + 1} Name`}
					value={item.name}
					onChange={(value) => {
						let nameError;
						if (value.trim() == "") {
							nameError = "Name can not be empty";
						}
						this.setData("name", value, index, nameError);
					}}
					style={[{ width: "90%", marginLeft: 15}]}
					editable={true}
				/>
				<Text style={[styles.errorMessage, { paddingVertical: 0 }]}>
					{formErrors[index]["name"] || " "}
				</Text>
				<PickerFieldSelect
					data={this.state.ages}
					value={item.age}
					label={`Child-${index + 1} Age ( in years )`}
					onChange={(value) => {
						this.setData("age", value, index, null);
					}}
					pickerStyle={[{width: "90%", marginLeft: 15}]}
					placeholder={{ value: "SEL", label: "Select" }}
				/>
				<Text style={[styles.errorMessage, { paddingVertical: 0 }]}>
					{formErrors[index]["age"] || " "}
				</Text>
			</View>
		);
	};

	render() {
		const {
			is_goal,
			goalType,
			name,
			nameError,
			age,
			ages,
			ageError,
			gender,
			genderError,
			monthlyIncome,
			monthlyExpense,
			incomeError,
			expenseError,
			maritalStatus,
			childrens,
			numberOfChildrenError,
			numberOfChildren,
			maritalStatusError,
			years,
			yearsData,
			childrenDetails,
			formErrors
		} = this.state;
		return this.state.isLoading ? (
			<View style={{flex: 1}}>
				<ProgressBar msg={"loading..."} />
			</View>
		) : (
			<View style={styles.container}>
				<Header
					navigation={this.props.navigation}
					route={this.props.route}
					onPressBack={() => {
						let newGoalType = this.state.goalType;
						
						switch(this.state.goalType) {
							case "IncomeDetails":
								newGoalType = "BASIC";
								break;
							case "MaritalStatus":
								newGoalType = "IncomeDetails";
								break;
							case "BuyHome":
								newGoalType = "MaritalStatus";
								break;
							case "WhenBuyHome":
								newGoalType = "BuyHome";
								break;
							case "BuyCar":
								newGoalType = "WhenBuyHome";
								break;
							case "WhenBuyCar":
								newGoalType = "BuyCar";
								break;
							case "BuyBike":
								newGoalType = "WhenBuyCar";
								break;
							case "WhenBuyBike":
								newGoalType = "BuyBike";
								break;
							case "WhenRetire":
								newGoalType = "WhenBuyBike";
								break;
						}
						
						this.setState({ goalType: newGoalType });
					}}
				/>
				{!is_goal ? (<MiniOnBoardingProgressBar
					color2="#410DAA"
					color3="#410daa"
					color4="#410DAA"
					color5="#f6f6f6"
					color6="#f6f6f6"
					color11="#f6f6f6"
					color12="#f6f6f6"
					bcolor1="#410DAA"
					bcolor2="#410DAA"
					bcolor3="#410DAA"
					bagcolor1="#410DAA"
					bagcolor2="#410DAA"
					bagcolor3="#410DAA"
					markdisplay1="none"
					markdisplay2="none"
					markdisplay3="none"
					dotdisplay1="none"
					dotdisplay2="none"
					dotdisplay3="none" />) :
					(
						<MiniOnBoardingProgressBar
							color2="#410DAA"
							color3="#f6f6f6"
							color4="#f6f6f6"
							color5="#f6f6f6"
							color6="#f6f6f6"
							color11="#f6f6f6"
							color12="#f6f6f6"
							bcolor1="#410DAA"
							bcolor2="#410DAA"
							bcolor3="#410DAA"
							bagcolor1="#410DAA"
							bagcolor2="#410DAA"
							bagcolor3="#410DAA"
							markdisplay1="none"
							markdisplay2="none"
							markdisplay3="none"
							dotdisplay1="none"
							dotdisplay2="none"
							dotdisplay3="none" />
					)}
				<ScrollView
					showsVerticalScrollIndicator={false}
					keyboardShouldPersistTaps="handled"
					style={{
						paddingHorizontal: 16,
					}}
				>
					<View style={styles.body}>
						{isWeb && (
							<View style={styles.left}>
								<View style={{ paddingBottom: 50 }}>
									<View style={{ opacity: 0 }}>
										<Image
											source={
												"https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-2.png"
											}
											style={styles.left_logo}
										></Image>
									</View>
									<View style={{ position: 'absolute', zIndex: 10 }}>
										<View style={{ marginBottom: 25 }}>
											<Text style={{ color: '#410DAA', fontSize: 32, fontWeight: '700' }}>Financial Plan Benefits</Text>
										</View>
										<View style={{ display: 'flex', flexDirection: 'row', marginVertical: 2 }}>
											<Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>1. Know if goals are on track.</Text>
										</View>
										<View style={{ display: 'flex', flexDirection: 'row', marginVertical: 2 }}>
											<Text style={{ color: "#666666", marginLeft: 10, fontWeight: '500', fontSize: 16 }}>{"(retirement, child's education, child's marriage etc)"}</Text>
										</View>
										<View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
											<Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>2. Al generated advice to improve goal success.</Text>
										</View>
									</View>
								</View>
								<Text style={{ textAlign: 'center', color: '#410DAA', fontWeight: '500', marginTop: 5, marginHorizontal: '10%' }}>
									Note: The financial plan report is not investment advice. After your onboarding and riskprofiling and personalised advisory report shall be provided.
								</Text>
							</View>
						)}
						<View style={styles.right}>
							<View style={[isMobile ? styles.mob_styl : styles.web_styl]}>
								{goalType == "BASIC" && (
									<View style={{ flex: 1}}>
										<View style={[styles.formItem, styles.itemMargin]}>
											<TextInputField
												label="Name"
												value={name}
												onChange={(value) => {
													this.handleChange(value, "name", "text");
												}}
												style={[{ ...styles.inputItem, width: '90%' }]}
											// editable={false}
											/>
										</View>
										<Text
											style={[
												styles.errorMessage,
												{ paddingVertical: 0, marginVertical: 0 },
											]}
										>
											{nameError || ""}
										</Text>
										<View style={[styles.formItem, styles.itemMargin, {marginTop: 20}]}>
											<PickerFieldSelect
												data={ages}
												value={age}
												label="Your Age"
												onChange={(value) => {
													this.handleChange(value, "age", "select");
												}}
												pickerStyle={[{ ...styles.inputItem, width: '87.5%' }]}
												placeholder={{ value: null, label: "Select" }}
											/>
										</View>
										<Text
											style={[
												styles.errorMessage,
												{ paddingTop: 0, paddingBottom: 4, marginVertical: 0 },
											]}
										>
											{this.state.ageError || ""}
										</Text>

										<HorizontalRadio
											title={"Gender"}
											style={{
												paddingVertical: 0,
												marginVertical: 0,
												marginTop: 20
											}}
											selected={this.state.gender}
											radioButtonValues={["Male", "Female", "Other"]}
											values={["M", "F", "O"]}
											onSelect={(index, value) => {
												if (value) {
													this.setState({ genderError: null });
												}
												this.setState({ gender: value });
											}}
										/>

										<Text
											style={[
												styles.errorMessage,
												{ paddingVertical: 4, marginVertical: 0 },
											]}
										>
											{this.state.genderError || ""}
										</Text>
										<TouchableOpacity
											onPress={this.handleBasicPlanContinue}
											style={{
												backgroundColor: '#410DAA',
												paddingHorizontal: 20,
												paddingVertical: 10,
												justifyContent: 'center',
												alignItems: 'center',
												marginTop: 50
											}}>
											<Text style={{ color: 'white' }}>Next</Text>
										</TouchableOpacity>
									</View>
								)}
								{goalType == "IncomeDetails" && (
									<View style={{ width: '100%', justifyContent:'center', alignItems: 'center' }}>
											<View style={[styles.formItem, styles.itemMargin]}>
												<TextInputField
													prefix="₹ "
													label="Monthly Income"
													value={monthlyIncome}
													onChange={(amount) => {
														let incomeError;
														if (
															!(amount != "" && amount >= 10000 && amount <= 2000000)
														) {
															incomeError =
																"Income should be in range 10 thousand - 20 lakhs rupees";
														}
														this.setState({
															monthlyIncome: amount,
															incomeError,
														});
													}}
													style={[{ ...styles.inputItem }]}
													editable={true}
												/>
											</View>
											{incomeError != "" && <Text
												style={[
													styles.errorMessage,
													{ paddingVertical: 0, marginVertical: 0 },
												]}
											>
												{incomeError}
											</Text>}

											<View style={[styles.formItem, styles.itemMargin, { marginTop: 20 }]}>
												<TextInputField
													prefix="₹ "
													label="Monthly Expense"
													value={monthlyExpense}
													onChange={(amount) => {
														let expenseError;
														if (
															!(amount != "" && amount >= 10000 && amount <= 2000000)
														) {
															expenseError =
																"Income should be in range 10 thousand - 20 lakhs rupees";
														}
														this.setState({
															monthlyExpense: amount,
															expenseError,
														});
													}}
													style={[{ ...styles.inputItem }]}
													editable={true}
												/>
											</View>
											{expenseError != "" && <Text
												style={[
													styles.errorMessage,
													{ paddingVertical: 0, marginVertical: 0 },
												]}
											>
												{expenseError || ""}
											</Text>}
										<TouchableOpacity
											onPress={this.handleIncomeDetailsContinue}
											style={{
												backgroundColor: '#410DAA',
												paddingHorizontal: 20,
												paddingVertical: 10,
												width: 150,
												justifyContent: 'center',
												alignItems: 'center',
												marginTop: 50
											}}>
											<Text style={{ color: 'white' }}>Next</Text>
										</TouchableOpacity>
									</View>
								)}
								{goalType == "MaritalStatus" && (
									<View style={{ width: '100%' }}>
										<ScrollView style={{ width: '100%', paddingHorizontal: 16 }}>
											<StatusBar backgroundColor="#410DAA" barStyle="light-content" />
											<View
												style={{
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<Image
													source={{
														uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/marriage_finplan_image.png",
													}}
													style={{ width: 150, height: 150, alignSelf: "center" }}
													resizeMode="contain"
												/>
											</View>
											<View style={{ alignItems: "center", marginTop: 40 }}>
												<Text
													style={{
														fontSize: 18,
														fontWeight: "bold",
														marginBottom: 30,
														color: "#121212",
													}}
												>
													Your Marital Status ?
												</Text>
												<View
													style={{
														justifyContent: "center",
														marginVertical: 10,
													}}
												>
													<RadioOption
														title="Single (as of now)"
														onSelect={() => {
															this.setState({
																maritalStatusError: null,
																maritalStatus: "S",
															});
														}}
														selected={maritalStatus == "S"}
													/>
													<RadioOption
														title="Married"
														onSelect={() => {
															this.setState({
																maritalStatusError: null,
																maritalStatus: "M",
															});
														}}
														selected={maritalStatus == "M"}
													/>
												</View>
											</View>
											<Text style={[styles.errorMessage, { alignSelf: "center" }]}>
												{maritalStatusError || ""}
											</Text>

											{this.state.maritalStatus == "M" && (
												<View style={{ justifyContent: 'center', alignItems: 'center' }}>
													<Text
														style={{
															fontSize: 16,
															fontWeight: "bold",
															marginBottom: 10
														}}
													>
														Number of children ?
													</Text>
													<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
														<PickerFieldSelect
															data={childrens}
															value={
																numberOfChildren &&
																numberOfChildren.value
															}
															label={null}
															onChange={(value) => {
																this.setState({ numberOfChildren: value });
																this.setState({ numberOfChildrenError: "" });
															}}
															pickerStyle={[{ width: "90%", marginLeft: 15 }]}
															placeholder={{ value: "SEL", label: "Select" }}
														/>
													</View>
												</View>
											)}
											<Text style={[styles.errorMessage, { alignSelf: "center" }]}>
												{numberOfChildrenError || ""}
											</Text>
											<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
												<TouchableOpacity
													onPress={this.handleMaritalStatusContinue}
													style={{
														backgroundColor: '#410DAA',
														paddingHorizontal: 20,
														paddingVertical: 10,
														justifyContent: 'center',
														width: 150,
														alignItems: 'center',
														marginTop: 50,
														marginBottom: 50
													}}>
													<Text style={{ color: 'white' }}>Next</Text>
												</TouchableOpacity>
											</View>
										</ScrollView>
									</View>
								)}
								{goalType == "BuyHome" && (
									<View style={{ width: '100%', height: 600, marginTop: 50}}>
											<YesNo
												image={
													"https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/buying_home_fin_plan.png"
												}
												imageStyle={{ alignSelf: "center" }}
												style={{}}
												onYes={() => this.planningCondition("Home", true)}
												onNo={() => this.planningCondition("Home", false)}
												title={"Planning to buy a Home ?"}
												titleStyle={{
													fontSize: 24,
												}}
												selectedColor={"#00689f"}
												notSelectedColor={"white"}
											/>
									</View>
								)}
								{goalType == "WhenBuyHome" && (
									<View style={{ flex: 1 }}>
										<View style={{ paddingHorizontal: 16 }}>
											<View
												style={{
													flex: 0.3,
													justifyContent: "center",
													alignItems: "center", marginTop: 50
												}}
											>
												<Image
													source={{
														uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/buying_home_fin_plan.png",
													}}
													style={{ width: 120, height: 120, alignSelf: "center" }}
													resizeMode="contain"
												/>
											</View>
											<View style={{}}>
												<Text
													style={{
														fontSize: 16,
														fontWeight: "bold",
														marginTop: 80,
														marginBottom: 30,
														color: "#121212",
														alignSelf: "center",
													}}
												>
													When do you wish to buy new house?
												</Text>
												<View
													style={{
														flexDirection: "row",
														alignSelf: "center",
														alignItems: "center",
														marginTop: 10,
													}}
												>
													<Text style={{ marginRight: 10 }}>In </Text>
													<PickerFieldSelect
														data={yearsData}
														value={years}
														label={null}
														onChange={(value) => {
															this.setState({ years: value });
														}}
														pickerStyle={[{ width: isMobile ? 100 : 100 }]}
														placeholder={{ value: "SEL", label: "Select" }}
													/>
													<Text style={{ marginLeft: -190 }}>Years(as of now)</Text>
												</View>

												<Text
													style={[
														styles.errorMessage,
														{ alignSelf: "center", paddingTop: 10 },
													]}
												>
													{this.state.error || ""}
												</Text>
											</View>
										</View>
										<TouchableOpacity
											onPress={this.handleBuyHomeContinue}
											style={{
												backgroundColor: '#410DAA',
												paddingHorizontal: 20,
												paddingVertical: 10,
												justifyContent: 'center',
												alignItems: 'center',
												marginTop: 0
											}}>
											<Text style={{ color: 'white' }}>Next</Text>
										</TouchableOpacity>
									</View>
								)}
								{goalType == "BuyCar" && (
									<View
										style={{ width: '100%', height: 600, marginTop: 50}}
									>
										<YesNo
											image={
												"https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/car_finplan_image.png"
											}
											imageStyle={{ alignSelf: "center" }}
											style={{}}
											onYes={() => this.planningCondition("Car", true)}
											onNo={() => this.planningCondition("Car", false)}
											title={"Planning to buy a Car?"}
											titleStyle={{
												fontSize: 24,
											}}
											selectedColor={"#00689f"}
											notSelectedColor={"white"}
										/>
									</View>
								)}
								{goalType == "WhenBuyCar" && (
									<View style={{ flex: 1 }}>
										<View style={{ paddingHorizontal: 16 }}>
											<View
												style={{
													flex: 0.3,
													justifyContent: "center",
													alignItems: "center",
												 marginTop: 50
												}}
											>
												<Image
													source={{
														uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/car_finplan_image.png",
													}}
													style={{ width: 150, height: 150, alignSelf: "center" }}
													resizeMode="contain"
												/>
											</View>
											<View style={{}}>
												<Text
													style={{
														fontSize: 16,
														fontWeight: "bold",
														marginBottom: 30,
														marginTop: 80,
														color: "#121212",
														alignSelf: "center",
													}}
												>
													When do you wish to buy a new car?
												</Text>
												<View
													style={{
														flexDirection: "row",
														alignSelf: "center",
														alignItems: "center",
														marginTop: 10,
													}}
												>
													<Text style={{ marginRight: 10 }}>In </Text>
													<PickerFieldSelect
														data={yearsData}
														value={years}
														label={null}
														onChange={(value) => {
															this.setState({ years: value });
														}}
														pickerStyle={[{ width: isMobile ? 100 : 100 }]}
														placeholder={{ value: "SEL", label: "Select" }}
													/>
													<Text style={{ marginLeft: -190 }}>Years(as of now)</Text>
												</View>

												<Text
													style={[
														styles.errorMessage,
														{ alignSelf: "center", paddingTop: 10 },
													]}
												>
													{this.state.error || ""}
												</Text>
											</View>
										</View>
										<TouchableOpacity
											onPress={this.handleBuyCarContinue}
											style={{
												backgroundColor: '#410DAA',
												paddingHorizontal: 20,
												paddingVertical: 10,
												justifyContent: 'center',
												alignItems: 'center',
												marginTop: 0
											}}>
											<Text style={{ color: 'white' }}>Next</Text>
										</TouchableOpacity>
									</View>
								)}
								{goalType == "BuyBike" && (
									<View
									style={{ width: '100%', height: 600, marginTop: 50}}
									>
										<YesNo
											image={
												"https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/bike_finplan_image.png"
											}
											imageStyle={{ alignSelf: "center" }}
											style={{}}
											onYes={() => this.planningCondition("Bike", true)}
											onNo={() => this.planningCondition("Bike", false)}
											title={"Planning to buy a Bike?"}
											titleStyle={{
												fontSize: 24,
											}}
											selectedColor={"#00689f"}
											notSelectedColor={"white"}
										/>
									</View>
								)}
								{goalType == "WhenBuyBike" && (
									<View style={{ flex: 1 }}>
										<View style={{ paddingHorizontal: 16 }}>
											<View
												style={{
													flex: 0.3,
													justifyContent: "center",
													alignItems: "center",
													marginTop: 50
												}}
											>
												<Image
													source={{
														uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/bike_finplan_image.png",
													}}
													style={{ width: 150, height: 150, alignSelf: "center" }}
													resizeMode="contain"
												/>
											</View>
											<View style={{}}>
												<Text
													style={{
														fontSize: 16,
														fontWeight: "bold",
														marginBottom: 30,
														marginTop: 80,
														color: "#121212",
														alignSelf: "center",
													}}
												>
													When do you wish to buy a new bike?
												</Text>
												<View
													style={{
														flexDirection: "row",
														alignSelf: "center",
														alignItems: "center",
														marginTop: 10,
													}}
												>
													<Text style={{ marginRight: 10 }}>In </Text>
													<PickerFieldSelect
														data={yearsData}
														value={years}
														label={null}
														onChange={(value) => {
															this.setState({ years: value });
														}}
														pickerStyle={[{ width: isMobile ? 100 : 100 }]}
														placeholder={{ value: "SEL", label: "Select" }}
													/>
													<Text style={{ marginLeft: -190 }}>Years(as of now)</Text>
												</View>

												<Text
													style={[
														styles.errorMessage,
														{ alignSelf: "center", paddingTop: 10 },
													]}
												>
													{this.state.error || ""}
												</Text>
											</View>
										</View>
										<TouchableOpacity
											onPress={this.handleBuyBikeContinue}
											style={{
												backgroundColor: '#410DAA',
												paddingHorizontal: 20,
												paddingVertical: 10,
												justifyContent: 'center',
												alignItems: 'center',
												marginTop: 0
											}}>
											<Text style={{ color: 'white' }}>Next</Text>
										</TouchableOpacity>
									</View>
								)}
								{goalType == "WhenRetire" && (
									<View style={{ flex: 1 }}>
										<View style={{ flex: 1, paddingHorizontal: 16 }}>
											<View
												style={{
													flex: 0.3,
													justifyContent: "center",
													alignItems: "center",
													marginTop: 50
												}}
											>
												<Image
													source={{
														uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/retirement_finplan_image.png",
													}}
													style={{ width: 125, height: 125, alignSelf: "center" }}
													resizeMode="contain"
												/>
											</View>
											<View style={{}}>
												<Text
													style={{
														fontSize: 16,
														fontWeight: "bold",
														marginTop: 40,
														marginBottom: 30,
														color: "#121212",
														alignSelf: "center",
													}}
												>
													When do you wish to retire?
												</Text>
												<View
													style={{
														flexDirection: "row",
														alignSelf: "center",
														alignItems: "center",
														marginTop: 10,
													}}
												>
													<Text style={{ marginRight: 10 }}>At age </Text>
													<PickerFieldSelect
														data={yearsData}
														value={age}
														label={null}
														onChange={(value) => {
															this.setState({ age: value });
														}}
														pickerStyle={[{ width: 100 }]}
														placeholder={{ value: "SEL", label: "Select" }}
													/>
													<Text style={{ marginLeft: 10, marginLeft: -190 }}>
														Year{age > 1 ? "s" : ""}
													</Text>
												</View>
												<Text
													style={[
														styles.errorMessage,
														{ alignSelf: "center", paddingTop: 10 },
													]}
												>
													{this.state.error || ""}
												</Text>
											</View>
										</View>
										<TouchableOpacity
											onPress={this.handleRetirementContinue}
											style={{
												backgroundColor: '#410DAA',
												paddingHorizontal: 20,
												paddingVertical: 10,
												justifyContent: 'center',
												alignItems: 'center',
												marginTop: 50
											}}>
											<Text style={{ color: 'white' }}>Next</Text>
										</TouchableOpacity>
									</View>
								)}
								{goalType == "ChildernDetails" && (
									<View>
										<ScrollView style={{ flex: 1, paddingHorizontal: 16 }}>
											<StatusBar backgroundColor="#410DAA" barStyle="light-content" />
											<View
												style={{
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<Image
													source={{
														uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/children_finplan_image.png",
													}}
													style={{ width: 150, height: 150, alignSelf: "center" }}
													resizeMode="contain"
												/>
											</View>
											<View
												style={{
													alignItems: "center",
													flexGrow: 1,
												}}
											>
												<Text
													style={{
														fontSize: 18,
														fontWeight: "bold",
														marginBottom: 30,
													}}
												>
													Tell us about your children
												</Text>
												<View style={{ flex: 1 }}>
													{childrenDetails && childrenDetails.length != 0 && (
														<FlatList
															data={childrenDetails}
															renderItem={this.renderDetails}
															keyExtractor={(item, index) => index.toString()}
															extraData={this.state}
															style={{ paddingLeft: 0, marginLeft: 0 }}
														/>
													)}
												</View>
											</View>
											<TouchableOpacity
												onPress={this.handleChildrenDetailsContinue}
												style={{
													backgroundColor: '#410DAA',
													paddingHorizontal: 20,
													paddingVertical: 10,
													justifyContent: 'center',
													alignItems: 'center',
													marginBottom: 50
												}}>
												<Text style={{ color: 'white' }}>Next</Text>
											</TouchableOpacity>
										</ScrollView>
									</View>
								)}
								{goalType == "ChildrenBachelors" && (
									<View style={{width: '100%', paddingBottom: 50, justifyContent: 'center', alignItems: 'center' }} >
										<View style={{ marginHorizontal: 16 }}>
											<Image
												source={{
													uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/child_grad_finplan_image.png",
												}}
												style={{
													width: 130,
													height: 100,
													alignSelf: "center",
													marginTop: 20,
												}}
												resizeMode="contain"
											/>
											<View
												style={{
													alignItems: "flex-start",
													justifyContent: "flex-start",
													marginTop: 40,
												}}
											>
												<Text
													style={{
														fontSize: 16,
														marginBottom: 20,
														alignSelf: "center",
														textAlign: "center",
														color: "#121212",
														fontWeight: "bold",
													}}
												>
													How would you plan their Graduation?
												</Text>
												{childrenDetails.map((children, index) => {
													return (
														<View
															key={index}
															style={styles.childrenDetailsContainer}
														>
															<Text
																style={{
																	fontSipaddingVertical: 10,
																	alignSelf: "center",
																	fontSize: 14,
																	color: "#121212",
																}}
															>
																For{" "}
																<Text
																	style={{
																		color: "#121212",
																		fontWeight: "bold",
																	}}
																>
																	{children.name}
																</Text>
															</Text>
															<View style={{ flexDirection: "column" }}>
																<View style={styles.radioButtonContainer}>
																	<RadioButton.Android
																		onPress={() => {
																			this.setRadioValue(index, "IND", "UG");
																			this.clearError(index);
																		}}
																		uncheckedColor={"#183443"}
																		color={theme.colors.NEW_BRAND_COLOR}
																		status={
																			this.state.childrenDetails[index].bachelors ==
																				"IND"
																				? "checked"
																				: "unchecked"
																		}
																		style={{ marginRight: 20 }}
																	/>
																	<Text
																		style={{
																			textAlignVertical: "center",
																			color:
																				this.state.childrenDetails[index]
																					.bachelors == "IND"
																					? theme.colors.NEW_BRAND_COLOR
																					: "#121212",
																			fontSize: 14,
																		}}
																		onPress={() => {
																			this.setRadioValue(index, "IND", "UG");
																			this.clearError(index);
																		}}
																	>
																		5 Lakhs/Year ( India )
																	</Text>
																</View>
																<View style={styles.radioButtonContainer}>
																	<RadioButton.Android
																		onPress={() => {
																			this.setRadioValue(index, "OTH", "UG");
																			this.clearError(index);
																		}}
																		uncheckedColor={"#183443"}
																		color={theme.colors.NEW_BRAND_COLOR}
																		status={
																			this.state.childrenDetails[index].bachelors ==
																				"OTH"
																				? "checked"
																				: "unchecked"
																		}
																		style={{ marginRight: 20 }}
																	/>
																	<Text
																		style={{
																			textAlignVertical: "center",
																			color:
																				this.state.childrenDetails[index]
																					.bachelors == "OTH"
																					? theme.colors.NEW_BRAND_COLOR
																					: "#121212",
																			fontSize: 14,
																		}}
																		onPress={() => {
																			this.setRadioValue(index, "OTH", "UG");
																			this.clearError(index);
																		}}
																	>
																		15 Lakhs/Year ( Canada, Europe, Australia, NZ )
																	</Text>
																</View>
																<View style={styles.radioButtonContainer}>
																	<RadioButton.Android
																		onPress={() => {
																			this.setRadioValue(index, "USC", "UG");
																			this.clearError(index);
																		}}
																		uncheckedColor={"#183443"}
																		color={theme.colors.NEW_BRAND_COLOR}
																		status={
																			this.state.childrenDetails[index].bachelors ==
																				"USC"
																				? "checked"
																				: "unchecked"
																		}
																		style={{ marginRight: 20 }}
																	/>
																	<Text
																		style={{
																			textAlignVertical: "center",
																			color:
																				this.state.childrenDetails[index]
																					.bachelors == "USC"
																					? theme.colors.NEW_BRAND_COLOR
																					: "#121212",
																			fontSize: 14,
																		}}
																		onPress={() => {
																			this.setRadioValue(index, "USC", "UG");
																			this.clearError(index);
																		}}
																	>
																		40 Lakhs/Year ( USA, Cities like London )
																	</Text>
																</View>

																<View style={styles.radioButtonContainer}>
																	<RadioButton.Android
																		onPress={() => {
																			this.setRadioValue(index, "NOP", "UG");
																			this.clearError(index);
																		}}
																		uncheckedColor={"#183443"}
																		color={theme.colors.NEW_BRAND_COLOR}
																		status={
																			this.state.childrenDetails[index].bachelors ==
																				"NOP"
																				? "checked"
																				: "unchecked"
																		}
																		style={{ marginRight: 20 }}
																	/>
																	<Text
																		style={{
																			textAlignVertical: "center",
																			color:
																				this.state.childrenDetails[index]
																					.bachelors == "NOP"
																					? theme.colors.NEW_BRAND_COLOR
																					: "#121212",
																			fontSize: 14,
																		}}
																		onPress={() => {
																			this.setRadioValue(index, "NOP", "UG");
																			this.clearError(index);
																		}}
																	>
																		Not Planned
																	</Text>
																</View>

																<Text style={[styles.errorMessage]}>
																	{formErrors &&
																		formErrors[index] &&
																		formErrors[index].error
																		? formErrors[index].error
																		: ""}
																</Text>
															</View>
														</View>
													);
												})}
											</View>
										</View>
										<TouchableOpacity
											onPress={this.handleChildrenBachelorsContinue}
											style={{
												backgroundColor: '#410DAA',
												paddingHorizontal: 20,
												paddingVertical: 10,
												width: 150,
												marginTop: 20,
												justifyContent: 'center',
												alignItems: 'center'
											}}>
											<Text style={{ color: 'white' }}>Next</Text>
										</TouchableOpacity>
									</View>
								)}
								{goalType == "ChildrenPG" && (
									<View style={{ width: "100%", justifyContent: 'center', alignItems: 'center', paddingBottom: 50}}>
										<View style={{ marginHorizontal: 16 }}>
											<Image
												source={{
													uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/child_grad_finplan_image.png",
												}}
												style={{
													width: 130,
													height: 100,
													alignSelf: "center",
													marginTop: 20,
												}}
												resizeMode="contain"
											/>

											<View
												style={{
													alignItems: "flex-start",
													justifyContent: "flex-start",
													marginTop: 40,
												}}
											>
												<Text
													style={{
														fontSize: 16,
														marginBottom: 20,
														alignSelf: "center",
														textAlign: "center",
														color: "#121212",
														fontWeight: "bold",
													}}
												>
													How would you plan their Post Graduation?
												</Text>
												{childrenDetails.map((children, index) => {
													return (
														<View
															key={index}
															style={styles.childrenDetailsContainer}
														>
															<Text
																style={{
																	fontSipaddingVertical: 10,
																	alignSelf: "center",
																	fontSize: 14,
																	color: "#121212",
																}}
															>
																For{" "}
																<Text
																	style={{
																		color: "#121212",
																		fontWeight: "bold",
																	}}
																>
																	{children.name}
																</Text>
															</Text>
															<View style={{ flexDirection: "column" }}>
																<View style={styles.radioButtonContainer}>
																	<RadioButton.Android
																		onPress={() => {
																			this.setRadioValue(index, "IND", "PG");
																			this.clearError(index);
																		}}
																		uncheckedColor={"#183443"}
																		color={theme.colors.NEW_BRAND_COLOR}
																		status={
																			this.state.childrenDetails[index].pg == "IND"
																				? "checked"
																				: "unchecked"
																		}
																		style={{ marginRight: 20 }}
																	/>
																	<Text
																		style={{
																			textAlignVertical: "center",
																			color:
																				this.state.childrenDetails[index].pg ==
																					"IND"
																					? theme.colors.NEW_BRAND_COLOR
																					: "#121212",
																			fontSize: 14,
																		}}
																		onPress={() => {
																			this.setRadioValue(index, "IND", "PG");
																			this.clearError(index);
																		}}
																	>
																		5 Lakhs/Year ( India )
																	</Text>
																</View>
																<View style={styles.radioButtonContainer}>
																	<RadioButton.Android
																		onPress={() => {
																			this.setRadioValue(index, "OTH", "PG");
																			this.clearError(index);
																		}}
																		uncheckedColor={"#183443"}
																		color={theme.colors.NEW_BRAND_COLOR}
																		status={
																			this.state.childrenDetails[index].pg == "OTH"
																				? "checked"
																				: "unchecked"
																		}
																		style={{ marginRight: 20 }}
																	/>
																	<Text
																		style={{
																			textAlignVertical: "center",
																			color:
																				this.state.childrenDetails[index].pg ==
																					"OTH"
																					? theme.colors.NEW_BRAND_COLOR
																					: "#121212",
																			fontSize: 14,
																		}}
																		onPress={() => {
																			this.setRadioValue(index, "OTH", "PG");
																			this.clearError(index);
																		}}
																	>
																		15 Lakhs/Year ( Canada, Europe, Australia, NZ )
																	</Text>
																</View>
																<View style={styles.radioButtonContainer}>
																	<RadioButton.Android
																		onPress={() => {
																			this.setRadioValue(index, "USC", "PG");
																			this.clearError(index);
																		}}
																		uncheckedColor={"#183443"}
																		color={theme.colors.NEW_BRAND_COLOR}
																		status={
																			this.state.childrenDetails[index].pg == "USC"
																				? "checked"
																				: "unchecked"
																		}
																		style={{ marginRight: 20 }}
																	/>
																	<Text
																		style={{
																			textAlignVertical: "center",
																			color:
																				this.state.childrenDetails[index].pg ==
																					"USC"
																					? theme.colors.NEW_BRAND_COLOR
																					: "#121212",
																			fontSize: 14,
																		}}
																		onPress={() => {
																			this.setRadioValue(index, "USC", "PG");
																			this.clearError(index);
																		}}
																	>
																		40 Lakhs/Year ( USA, Cities like London )
																	</Text>
																</View>

																<View style={styles.radioButtonContainer}>
																	<RadioButton.Android
																		onPress={() => {
																			this.setRadioValue(index, "NOP", "PG");
																			this.clearError(index);
																		}}
																		uncheckedColor={"#183443"}
																		color={theme.colors.NEW_BRAND_COLOR}
																		status={
																			this.state.childrenDetails[index].pg == "NOP"
																				? "checked"
																				: "unchecked"
																		}
																		style={{ marginRight: 20 }}
																	/>
																	<Text
																		style={{
																			textAlignVertical: "center",
																			color:
																				this.state.childrenDetails[index].pg ==
																					"NOP"
																					? theme.colors.NEW_BRAND_COLOR
																					: "#121212",
																			fontSize: 14,
																		}}
																		onPress={() => {
																			this.setRadioValue(index, "NOP", "PG");
																			this.clearError(index);
																		}}
																	>
																		Not Planned
																	</Text>
																</View>

																<Text style={[styles.errorMessage]}>
																	{formErrors &&
																		formErrors[index] &&
																		formErrors[index].error
																		? formErrors[index].error
																		: ""}
																</Text>
															</View>
														</View>
													);
												})}
											</View>
										</View>
										<TouchableOpacity
											onPress={this.handleChildrenPostGraduationContinue}
											style={{
												backgroundColor: '#410DAA',
												paddingHorizontal: 20,
												paddingVertical: 10,
												width: 150,
												marginTop: 50,
												justifyContent: 'center',
												alignItems: 'center',
												marginBottom: 50
											}}>
											<Text style={{ color: 'white' }}>Next</Text>
										</TouchableOpacity>
									</View>
								)}
								{goalType == "ChildrenWedding" && (
									<View style={{ flex: 1, width: '100%' }}>

										<View style={{ width: '100%', marginHorizontal: 16, justifyContent: 'center', alignItems: 'center' }}>
											<ScrollView>
												<Image
													source={{
														uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/marriage_finplan_image.png",
													}}
													style={{
														width: 140,
														height: 100,
														alignSelf: "center",
														marginTop: 20,
													}}
													resizeMode="contain"
												/>

												<View
													style={{
														alignItems: "flex-start",
														justifyContent: "flex-start",
														marginTop: 40,
													}}
												>
													<Text
														style={{
															fontSize: 16,
															marginBottom: 20,
															alignSelf: "center",
															textAlign: "center",
															color: "#121212",
															fontWeight: "bold",
														}}
													>
														Planning for children's wedding?
													</Text>
													{childrenDetails.map((children, index) => {
														return (
															<View
																key={index}
																style={styles.childrenDetailsContainer}
															>
																<Text
																	style={{
																		fontSipaddingVertical: 10,
																		alignSelf: "center",
																		fontSize: 14,
																		color: "#121212",
																	}}
																>
																	For{" "}
																	<Text
																		style={{
																			color: "#121212",
																			fontWeight: "bold",
																		}}
																	>
																		{children.name}
																	</Text>
																</Text>
																<View style={{ flexDirection: "column" }}>
																	<View style={styles.radioButtonContainer}>
																		<RadioButton.Android
																			onPress={() => {
																				this.setRadioValue(index, "SIM");
																				this.clearError(index);
																			}}
																			uncheckedColor={"#183443"}
																			color={theme.colors.NEW_BRAND_COLOR}
																			status={
																				this.state.childrenDetails[index].wedding ==
																					"SIM"
																					? "checked"
																					: "unchecked"
																			}
																			style={{ marginRight: 20 }}
																		/>
																		<Text
																			style={{
																				textAlignVertical: "center",
																				color:
																					this.state.childrenDetails[index].wedding ==
																						"SIM"
																						? theme.colors.NEW_BRAND_COLOR
																						: "#121212",
																				fontSize: 14,
																			}}
																			onPress={() => {
																				this.setRadioValue(index, "SIM");
																				this.clearError(index);
																			}}
																		>
																			Simple (5 Lakhs)
																		</Text>
																	</View>
																	<View style={styles.radioButtonContainer}>
																		<RadioButton.Android
																			onPress={() => {
																				this.setRadioValue(index, "MOD");
																				this.clearError(index);
																			}}
																			uncheckedColor={"#183443"}
																			color={theme.colors.NEW_BRAND_COLOR}
																			status={
																				this.state.childrenDetails[index].wedding ==
																					"MOD"
																					? "checked"
																					: "unchecked"
																			}
																			style={{ marginRight: 20 }}
																		/>
																		<Text
																			style={{
																				textAlignVertical: "center",
																				color:
																					this.state.childrenDetails[index].wedding ==
																						"MOD"
																						? theme.colors.NEW_BRAND_COLOR
																						: "#121212",
																				fontSize: 14,
																			}}
																			onPress={() => {
																				this.setRadioValue(index, "MOD");
																				this.clearError(index);
																			}}
																		>
																			Moderate (15 Lakhs)
																		</Text>
																	</View>
																	<View style={styles.radioButtonContainer}>
																		<RadioButton.Android
																			onPress={() => {
																				this.setRadioValue(index, "LUX");
																				this.clearError(index);
																			}}
																			uncheckedColor={"#183443"}
																			color={theme.colors.NEW_BRAND_COLOR}
																			status={
																				this.state.childrenDetails[index].wedding ==
																					"LUX"
																					? "checked"
																					: "unchecked"
																			}
																			style={{ marginRight: 20 }}
																		/>
																		<Text
																			style={{
																				textAlignVertical: "center",
																				color:
																					this.state.childrenDetails[index].wedding ==
																						"LUX"
																						? theme.colors.NEW_BRAND_COLOR
																						: "#121212",
																				fontSize: 14,
																			}}
																			onPress={() => {
																				this.setRadioValue(index, "LUX");
																				this.clearError(index);
																			}}
																		>
																			Lavish (50 Lakhs)
																		</Text>
																	</View>

																	<View style={styles.radioButtonContainer}>
																		<RadioButton.Android
																			onPress={() => {
																				this.setRadioValue(index, "NOP");
																				this.clearError(index);
																			}}
																			uncheckedColor={"#183443"}
																			color={theme.colors.NEW_BRAND_COLOR}
																			status={
																				this.state.childrenDetails[index].wedding ==
																					"NOP"
																					? "checked"
																					: "unchecked"
																			}
																			style={{ marginRight: 20 }}
																		/>
																		<Text
																			style={{
																				textAlignVertical: "center",
																				color:
																					this.state.childrenDetails[index].wedding ==
																						"NOP"
																						? theme.colors.NEW_BRAND_COLOR
																						: "#121212",
																				fontSize: 14,
																			}}
																			onPress={() => {
																				this.setRadioValue(index, "NOP");
																				this.clearError(index);
																			}}
																		>
																			Not Planned
																		</Text>
																	</View>

																	<Text style={[styles.errorMessage]}>
																		{formErrors &&
																			formErrors[index] &&
																			formErrors[index].error
																			? formErrors[index].error
																			: ""}
																	</Text>
																</View>
															</View>
														);
													})}
												</View>
												<TouchableOpacity
													onPress={this.handleWeddingContinue}
													style={{
														backgroundColor: '#410DAA',
														paddingHorizontal: 20,
														paddingVertical: 10,
														justifyContent: 'center',
														alignItems: 'center',
														marginBottom: 50
													}}>
													<Text style={{ color: 'white' }}>Next</Text>
												</TouchableOpacity>

											</ScrollView>
										</View>
									</View>
								)}
							</View>
						</View>
					</View>
				</ScrollView>
			</View>
		)
	}
}

const styles = StyleSheet.create({
	container: {
		width: "100%",
		flexGrow: 1,
		height: "100%",
		backgroundColor: "#fff",
	},
	body: {
		flexDirection: "row",
		backgroundColor: "#ffffff",
		justifyContent: 'center',
		alignItems: 'center'
	},
	left: {
		flex: md(width) ? 1 : 0,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#fff",
		paddingVertical: md(width) ? 75 : 1,
	},
	left_logo: {
		resizeMode: "contain",
		width: 403,
		height: 241,
		alignSelf: "center",
		marginBottom: 60,
	},
	right: {
		flex: 1,
		height: 400,
	},
	errorMessage: {
		color: 'red'
	},
	mob_styl: {
		width: "100%",
		alignSelf: "center",
	},
	web_styl: {
		alignItems: "center",
		flex: 1,
	},
	radioButtonContainer: {
		flexDirection: "row",
		paddingVertical: 5,
		alignItems: "center",
	},
});

export default FinPlansGoalsCollections;