import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import Icon from "@expo/vector-icons/FontAwesome";
import EIcon from "@expo/vector-icons/Entypo";
import { SideHeading } from "../../../components/Common/sideHeading";

const { width } = Dimensions.get("window");

const WealthProfile = ({ data: userData, navigation }) => {
  const [showDetailedView, setShowDetailedView] = useState(false);
  const UserActionItem = ({ item }) => {
    const {
      actionItemContainer,
      actionItemText,
      actionItemDescription,
      actionItemMinutes,
    } = styles;

    const descriptionColor =
      item.percentageFinished == 100
        ? { color: "green", opacity: 1, fontWeight: "bold" }
        : {};
    return (
      <TouchableOpacity
        style={actionItemContainer}
        onPress={() => {
          navigation.navigate(item.navigate_to || " ");
        }}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text style={actionItemText}>
            {item.title} -{" "}
            <Text style={actionItemMinutes}>{item.timeToComplete}</Text>
          </Text>
          <View>
            {item.percentageFinished == 100 ? (
              <Icon
                name={"check-circle"}
                size={18}
                color={"green"}
                style={{ marginBottom: 4 }}
              />
            ) : item.percentageFinished == 0 ? (
              <Icon
                name={"exclamation-circle"}
                size={18}
                color={"red"}
                style={{ marginBottom: 4, opacity: 0.6 }}
              />
            ) : (
              <Text style={{ color: "#000", marginBottom: 4, marginLeft: 8 }}>
                {item.percentageFinished.toFixed(1)}%
              </Text>
            )}
          </View>
        </View>
        <Text style={[actionItemDescription, descriptionColor]}>
          {item.description}
        </Text>
        {item.stages && item.stages.length > 0 && (
          <FlatList
            style={{ marginTop: 8 }}
            data={item.stages
              .sort((a, b) => b.completed - a.completed)
              .filter((item) => item.completed !== -1)}
            renderItem={({ item, index }) => {
              const color =
                item.completed == 1
                  ? "#009105"
                  : item.completed == "0"
                  ? "#EF5D12"
                  : "#A8A8A8";
              return <Text style={{ fontSize: 11, color }}>{item.name}</Text>;
            }}
            keyExtractor={(item, index) => item.name + index}
            ItemSeparatorComponent={() => (
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginHorizontal: 6,
                }}
              >
                <View
                  style={{
                    width: 2,
                    height: 2,
                    borderRadius: 1,
                    backgroundColor: "#555",
                  }}
                ></View>
              </View>
            )}
            horizontal
          />
        )}
      </TouchableOpacity>
    );
  };

  const InitialView = () => {
    const itemWidth = (width - 32) / 4;
    return (
      <FlatList
        data={userData}
        renderItem={({ item, index }) => {
          return (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate(item.navigate_to || " ");
              }}
              style={{ width: itemWidth, alignItems: "center", padding: 4 }}
            >
              <View style={{ height: 24 }}>
                {item.percentageFinished == 100 ? (
                  <Icon
                    name={"check-circle"}
                    size={20}
                    color={"green"}
                    style={{ marginBottom: 4 }}
                  />
                ) : (
                  <Icon
                    name={"exclamation-circle"}
                    size={20}
                    color={"red"}
                    style={{ marginBottom: 4, opacity: 0.6 }}
                  />
                )}
              </View>
              <Text
                style={{ fontSize: 12, textAlign: "center", color: "#121212" }}
              >
                {item.title2}
              </Text>
            </TouchableOpacity>
          );
        }}
        style={{ marginHorizontal: 16, marginBottom: 16 }}
        horizontal
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item, index) => item.title2}
      />
    );
  };

  return (
    <>
      <View style={{ position: "relative" }}>
        <SideHeading
          title={"Wealth Profile"}
          style={{ fontSize: 20, alignItems: "center" }}
        />

        {!showDetailedView ? (
          <EIcon
            name={"chevron-with-circle-down"}
            color={"#410daa"}
            size={20}
            style={{
              position: "absolute",
              right: 16,
              top: 16,
              paddingHorizontal: 4,
            }}
            onPress={() => setShowDetailedView(true)}
          />
        ) : (
          <EIcon
            name={"chevron-with-circle-up"}
            color={"#410daa"}
            size={20}
            style={{
              position: "absolute",
              right: 16,
              top: 16,
              paddingHorizontal: 4,
            }}
            onPress={() => setShowDetailedView(false)}
          />
        )}
      </View>

      <Text
        style={{
          color: "#707070",
          marginBottom: 8,
          marginHorizontal: 16,
          fontSize: 11,
        }}
      >
        Complete your profile and get exclusive benefits
      </Text>

      {showDetailedView ? (
        <FlatList
          data={userData}
          renderItem={({ item, index }) => {
            return <UserActionItem item={item} />;
          }}
          style={{ marginHorizontal: 16 }}
          keyExtractor={(item, index) => item.title}
        />
      ) : (
        <InitialView />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  actionItemContainer: {
    padding: 16,
    borderWidth: 2,
    borderColor: "#410DAA1A",
    borderRadius: 8,
    marginBottom: 16,
  },
  actionItemText: { fontSize: 13 },
  actionItemMinutes: {
    fontSize: 9,
    color: "#555",
    fontStyle: "italic",
    flex: 1,
  },
  actionItemDescription: { fontSize: 12, color: "#121212", opacity: 0.5 },
  actionItemStatus: { fontSize: 12, color: "#A8A8A8" },
});

export default WealthProfile;
