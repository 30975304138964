import React from "react";
import { View, StyleSheet,ScrollView, Text } from "react-native";
import StockTable from "../../components/InvestmentOverview/NewDesign/StockTable";

const EmployeeTable = ({arr}) => {
	const headerData = [
		{
			label: "Employee Name",
			key: "name"
		},
		{
			label: "Employee Email",
			key: "email",
		},
	]
	let local = [];
	arr.forEach((value) => {
		if (value.toShow) {
		local.push([
			{
				valueToDisplay: value.name ? value.name : "User",
				value: "name" || "",
				style: [styles.rowItem],
				rowItemContainerStyle: [styles.rowItemContainerStyle],
			},
			{
					valueToDisplay: value.email,
					value: "email" || "",
					style: [styles.rowItem],
					rowItemContainerStyle: [styles.rowItemContainerStyle],
				},
			])
		}
	})
  return local.length > 0 ? (
    <View style={styles.methodContainer}>
      <ScrollView style={{flex: 1}}>
				<View style={{maxHeight: 200}}>
        	<StockTable
        	  header={headerData}
        	  gridConfig={[1, 1,1]}
        	  data={local}
        	  pagination={false}
        	/>
				</View>
      </ScrollView>
    </View>
  ) : (
		<View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
			<Text>No User Selected</Text>
		</View>
	)
}

const styles = StyleSheet.create({
	methodContainer: {
		flex : 1
	},
	rowItem: {
    textAlign: "left",
  },
  rowItemContainerStyle: {
    justifyContent: "flex-start",
    paddingLeft: 10,
  },
})

export default EmployeeTable;