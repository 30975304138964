import axiosApiInstance from "./apiConfig";
import { URLS } from "./urls";

export const getBillingLedgerPlans = async (userId) => {
    const url = URLS.BILLING_LEDGER_PLANS + (userId ? userId + "/" : "") ;
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getBillingLedgerPlanDetails = async (bill_id) => {
    const url = URLS.BILLING_LEDGER_PLAN_DETAILS + bill_id + "/";
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getCustomerInvoices = async (userId) => {
    const url = URLS.INVOICES + (userId ? userId + "/" : "") ;
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getCustomerInvoiceDetails = async (invoice_id, userId) => {
    const url = URLS.INVOICE_DETAILS + invoice_id + "/";
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getBillDetails = async (bill_id) => {
    const url = URLS.CBI_BILL_DETAILS + bill_id + "/";
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getTransactionDetails = async (billId, date) => {
    const url = URLS.GET_TRANSACTION_DETAILS + "" +billId + "/" + date;
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getInvestedAmountDetails = async (billId) => {
    const url = URLS.GET_INVESTED_AMOUNT_DETAILS + ""+billId+"/";
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getCurrentAUADetails = async (billId) => {
    const url = URLS.GET_CURRENT_AUA_DETAILS + ""+billId+"/";;
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getDividendYields = async (billId) => {
    const url = URLS.GET_DIVIDEND_YIELDS + ""+billId+"/";
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getPerformanceAdvisoryFeeDetails = async (billId) => {
    const url = URLS.GET_ADVISORY_FEE_DETAILS + "?bill_id=" +billId;
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getProratedDetails = async (billId) => {
    const url = URLS.GET_PRORATED_DETAILS + "" +billId+"/";
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getAverageAUADetails = async (billId) => {
    const url = URLS.GET_AVERAGE_AUA_DETAILS + "" +billId+"/";
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getHoldingsDetails = async (billId, date) => {
    const url = URLS.GET_HOLDINGS_DETAILS + ""+billId+"/"+(date ? date+"/" : "/");
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getInvoicesPaymentStatus = async () => {
    const url = URLS.GET_INVOICES_PAYMENT_STATUS;
    const result = await axiosApiInstance.get(url);
    return result;
};

export const getInvoicesPaymentLink = async (payload) => {
    const url = URLS.GENERATE_PAYMENT_LINK;
    const result = await axiosApiInstance.post(url, payload);
    return result;
};
