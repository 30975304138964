import React, { Component } from "react";
import {
  View,
  Text,
  Alert,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
} from "react-native";
import { retryInvestment, cancelInvestment } from "../../../api/alerts";
import {
  months_short_names,
  capitalizeFirstLetter,
  isMobile,
} from "../../../utils";
import Divider from "../../../components/Common/divider";
import AlertCard from "./AlertCard";
import { ShowToast } from "../../../components/Common/Toast";
import moment from "moment";
import Store from "../../../store";
import { StackActions } from "@react-navigation/routers";

export default class pendingTransactionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Paidbool: false,
      loadingAction: false,
      deleted: false,
      showToast: "false",
      toastMsg: "",
    };
  }

  handleOrder = () => {
    const PendingInvestment = this.props.data;

    setTimeout(() => {
      this.setState({ loadingAction: true }, () => {
        retryInvestment({
          cp_id: PendingInvestment.customerpayment__pk,
          iaft_id: PendingInvestment.pk,
          cp_type: "retry",
        }).then((json) => {
          if (json && json.code && json.code == 100) {
            this.setState({
              showAlertModal: true,
              loadingAction: false,
              title: "Success",
              message: "Order has been placed",
              alertPositiveButtonText: "Cancel",
              alertNegativeButtonText: "Proceed to Pay",
              alertPositiveButtonPress: () => {
                this.setState({ showAlertModal: false }, () => {});
              },
              alertNegativeButtonPress: () => {
                this.setState({ showAlertModal: false }, () => {
                  if (json && json.code && json.code == 100) {
                    if (isMobile) {
                      this.props.navigation.navigate("App", {
                        screen: "Dashboard",
                        params: {
                          screen: "Portfolio",
                          params: {
                            screen: "ShowWebView",
                            params: {
                              url: json.msg,
                              showInWeb: isMobile ? false : true,
                            },
                          },
                        },
                      });
                    } else {
                      global.open(json.msg, "_blank");
                    }
                  } else {
                    this.setState({ loadingAction: false });
                  }
                });
              },
            });

            this.setState({
              loadingAction: false,
              deleted: true,
            });
          }
          if (json.code == 0) {
            this.setState({
              loadingAction: false,
              showToast: "true",
              toastMsg: json.msg,
            });
            // Alert.alert(json.msg);
          }
          if (json.code == 101) {
            this.setState({
              loadingAction: false,
              showToast: "true",
              toastMsg: json.msg,
            });
            // Alert.alert(json.msg);
          }
        });
      });
    }, 1000);
  };

  handleCancelOrder = () => {
    const PendingInvestment = this.props.data;

    setTimeout(() => {
      this.setState({ loadingAction: true }, () => {
        cancelInvestment({
          cp_id: PendingInvestment.customerpayment__pk,
          iaft_id: PendingInvestment.pk,
          cp_type: "cancel",
        }).then((json) => {
          if (json.code == 100) {
            this.setState({ loadingAction: false }, () => {
              this.setState({
                showAlertModal: true,
                title: "Success",
                message: "Order has been removed successfully",
                alertPositiveButtonText: "Dismiss",
                alertNegativeButtonText: null,
                alertNegativeButtonPress: null,
                alertPositiveButtonPress: () => {
                  this.setState({
                    showAlertModal: false,
                    loadingAction: false,
                    deleted: true,
                  });
                  this.props.fetchInvestments();
                },
              });
            });
          }

          if (json.code == 0) {
            this.setState({ loadingAction: false });
            this.setState({
              showAlertModal: true,
              title: "Error!",
              message: json.msg,
              alertPositiveButtonText: "Dismiss",
              alertPositiveButtonPress: () => {
                this.setState({
                  showAlertModal: false,
                  loadingAction: false,
                });
              },
              alertNegativeButtonText: null,
              alertNegativeButtonPress: null,
            });
          }
          if (json.code == 101) {
            this.setState({ loadingAction: false });
          }
        });
      });
    }, 1000);
  };

  _handleRetry = (items) => {
    this.setState({
      showAlertModal: true,
      title: "Retry Your Order",
      message:
        "Just to confirm, we will place an order for " +
        items.amount_raw +
        " in " +
        items.name,
      alertPositiveButtonText: "Dismiss",
      alertNegativeButtonText: "Place Order",
      alertPositiveButtonPress: () => {
        this.setState({ showAlertModal: false });
      },
      alertNegativeButtonPress: () => {
        this.setState({ showAlertModal: false }, () => {
          this.handleOrder();
        });
      },
    });
  };

  _handleCancel = (items) => {
    this.setState({
      showAlertModal: true,
      title: "Remove this Order",
      message:
        "Just to confirm, we will remove this order for " +
        items.amount_raw +
        " in " +
        items.name +
        " from the list. ",
      alertPositiveButtonText: "Keep in list",
      alertNegativeButtonText: "Remove",
      alertPositiveButtonPress: () => {
        this.setState({ showAlertModal: false });
      },
      alertNegativeButtonPress: () => {
        this.setState({ showAlertModal: false }, () => {
          this.handleCancelOrder();
        });
      },
    });
  };

  numberFormat = (x) => {
    const values = x ? x.split("-") : x;
    let isNegative = false;
    if (values.length > 1) {
      x = values[1];
      isNegative = true;
    }
    return x && x.toString().split(".")[0].length > 3
      ? `${isNegative ? "-" : ""}${
          x
            .toString()
            .substring(0, x.toString().split(".")[0].length - 3)
            .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
        }`
      : `${isNegative ? "-" : ""}${x.toString()}`;
  };

  formatDate = (date) => {
    if (!date) return "-";
    date = date.split("<br>");
    if (date.length > 1) {
      date = date[0];
      const date_time = date.split(" ");
      let date_values = date_time[0];
      let time_values = date_time[1];
      const hr = parseInt(time_values.split(":")[0]);
      let am_pm = "a.m.";
      if (hr > 11) am_pm = "p.m.";
      const time = time_values + " " + am_pm;

      date_values = date_values.split("-");
      const month = months_short_names(parseInt(date_values[1]));
      const date_formatted =
        date_values[2] + "-" + month + "-" + date_values[0];

      return date_formatted + " " + time;
    }
    return "-";
  };

  render() {
    const PendingInvestment = this.props.data;
    return this.state.loadingAction ? (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <ActivityIndicator size="large" color="#410DAA" />
      </View>
    ) : (
      <View style={{ marginBottom: 20 }}>
        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title={this.state.toastMsg}
            duration="10"
          />
        ) : null}
        {this.state.showAlertModal && (
          <View>
            <AlertCard
              visible={this.state.showAlertModal}
              style={{ flex: 1 }}
              contentStyle={{
                alignItems: "flex-start",
              }}
              close={false}
              positiveButtonText={this.state.alertPositiveButtonText}
              negativeButtonText={this.state.alertNegativeButtonText}
              positiveButtonPress={() => {
                this.state.alertPositiveButtonPress &&
                  this.state.alertPositiveButtonPress();
              }}
              negativeButtonPress={() => {
                this.state.alertNegativeButtonPress &&
                  this.state.alertNegativeButtonPress();
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  color: "#121212",
                  textAlign: "left",
                  fontWeight: "bold",
                  marginBottom: 8,
                }}
              >
                {this.state.title}
              </Text>
              <Text
                style={{
                  fontSize: isMobile ? 14 : 13,
                  color: "#121212",
                  textAlign: "left",
                }}
              >
                {this.state.message}
              </Text>
            </AlertCard>
          </View>
        )}

        <View
          style={{
            paddingVertical: 8,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: "#410DAA1A",
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                paddingHorizontal: 8,
                alignItems: "flex-start",
              }}
            >
              <View
                style={{
                  flex: 0.15,
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              >
                <Image
                  style={{
                    width: 40,
                    height: 40,
                    resizeMode: "contain",
                    borderWidth: 1,
                    borderColor: "#f6f6f6",
                    borderRadius: 12,
                  }}
                  source={{ uri: PendingInvestment.amc_logo }}
                />
              </View>
              <View style={{ flex: 0.7 }}>
                <Text
                  style={{
                    color: "black",
                    alignSelf: "flex-start",
                    fontSize: 14,
                    width: "90%",
                    paddingLeft: 10,
                  }}
                >
                  {PendingInvestment.name}
                </Text>
              </View>
              <View
                style={{ flex: 0.3, marginRight: 8, alignItems: "flex-end" }}
              >
                <View style={{ alignItems: "center" }}>
                  <Text
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: isMobile ? 14 : 13,
                    }}
                  >
                    {PendingInvestment.amount_raw}
                  </Text>
                  <Text
                    style={{
                      color: "#410DAA",
                      fontSize: isMobile ? 13 : 11,
                      marginTop: 5,
                    }}
                  >
                    {capitalizeFirstLetter(PendingInvestment.investmentType)}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginVertical: 4,
                paddingHorizontal: 8,
              }}
            >
              <Text style={{ fontSize: isMobile ? 14 : 13, color: "gray" }}>
                Status :{" "}
              </Text>
              <Text style={{ fontSize: isMobile ? 14 : 13, color: "#410DAA" }}>
                {PendingInvestment.status}
              </Text>
            </View>
            <Divider
              color={"#f6f6f6"}
              width={0.5}
              style={{ marginVertical: 10 }}
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginVertical: 4,
                paddingHorizontal: 8,
              }}
            >
              <View
                style={{
                  flex: 0.65,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: isMobile ? 14 : 13, color: "gray" }}>
                  Direct Savings 20 Yrs:{" "}
                </Text>
                <Text
                  style={{ fontSize: isMobile ? 14 : 13, color: "#410DAA" }}
                >
                  {PendingInvestment.jama_savings}
                </Text>
              </View>
              <View style={{ flex: 0.45, alignItems: "center" }}>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#410DAA",
                    borderRadius: 6,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                  }}
                  onPress={() => this._handleRetry(PendingInvestment)}
                >
                  <Text
                    style={{
                      fontSize: isMobile ? 13 : 12,
                      color: "#fff",
                      textAlign: "center",
                      alignSelf: "center",
                    }}
                  >
                    Complete {Platform.OS == "web" ? "Investment" : ""}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginVertical: 4,
                paddingHorizontal: 8,
              }}
            >
              <View
                style={{
                  flex: 0.6,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: isMobile ? 14 : 13, color: "gray" }}>
                  Date :{" "}
                </Text>
                <Text
                  style={{ fontSize: isMobile ? 14 : 13, color: "#121212" }}
                >
                  {this.formatDate(PendingInvestment.created)}
                </Text>
              </View>
              <View style={{ flex: 0.4, alignItems: "center" }}>
                <TouchableOpacity
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 6,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                  }}
                  onPress={() => this._handleCancel(PendingInvestment)}
                >
                  <Text
                    style={{
                      fontSize: isMobile ? 14 : 12,
                      color: "gray",
                      textAlign: "center",
                    }}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
