import React from 'react';
import { View, StyleSheet } from 'react-native';

const DotSeparator = ({ size = 4, color = "#959CB6" }) => {
    return (
        <View style={{width: size, height: size, backgroundColor: color, borderRadius: size/2 }} />
    )
};

const styles = StyleSheet.create({
    container: {

    }
});

export default DotSeparator;
