import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
} from "react-native";
import { md } from "../../../Responsive";
import Header from "../login_header";
import { ShowToast } from "../../../components/Common/Toast";
import { isJama, isMobile } from "../../../utils";
import { StackActions } from "@react-navigation/routers";
import { forgotPasswordReset, getLoginBannerDetails } from "../../../api";
const { width } = Dimensions.get("window");

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showToastMsg: false,
      errorText: null,
      usernameValue: null,
      bannerData: {
        logo_image_url: null,
        name: null,
        ria_code: null,
        subscriber_type: null,
        support_email: null,
        support_person_name: null,
        support_phone_number: null,
        is_jamawealth: null,
      },
    };
  }

  componentDidMount = async () => {
    let { bannerData } = this.state;
    let response = null;
    try {
      response = await getLoginBannerDetails();
    } catch (err) {}
    if (response && response.code == 100) {
      bannerData = response.result;
      this.setState({ bannerData: bannerData });
    } else {
      bannerData.logo_image_url =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/website/jam%C4%81_header.png";
      bannerData.name = isJama() ? "JamaWealth" : "MaxiomWealth";
      bannerData.ria_code = "INA 200015583";
      bannerData.subscriber_type = "RIAC";
      bannerData.support_email = "advisor@jamawealth.com";
      bannerData.support_person_name = "Ram Kalyan Medury";
      bannerData.support_phone_number = "9870264643";
      bannerData.is_jamawealth = true;
      this.setState({
        bannerData: bannerData,
        errorText: response.msg,
      });
    }
  };

  handleContinue = async () => {
    this.setState({ isLoading: true });
    const { usernameValue } = this.state;
    const payload = {
      username: usernameValue,
    };
    if (!usernameValue) {
      this.setState({
        errorText: "Please enter Email / Mobile / Username",
        isLoading: false,
      });
      return;
    }
    try {
      let response = await forgotPasswordReset(payload);
      if (response && response.code && response.code == 100) {
        this.setState({ isLoading: false, showToastMsg: true });
        this.props.navigation.dispatch(
          StackActions.replace("OnboardingLoginMobile")
        );
      } else {
        this.setState({ errorText: response.result, isLoading: false });
      }
    } catch (e) {
      this.setState({ errorText: e, isLoading: false });
    }
  };

  render() {
    const { isLoading, bannerData, showToastMsg, errorText } = this.state;
    return (
      <View style={styles.container}>
        <Header
          navigation={this.props.navigation}
          route={this.props.route}
          bannerData={bannerData}
        />
        {showToastMsg ? (
          <ShowToast
            showToast={true}
            type="success"
            duration="10"
            title="Your password has been reset successfully, please check your registered email ID for the next steps."
          />
        ) : null}
        <ScrollView style={{}}>
          <View style={styles.body}>
            <View style={styles.left}>
              <Text style={styles.best}>
                Invest like the <Text style={{ color: "#410DAA" }}>Best</Text>
              </Text>
              <Image
                source={
                  "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/Invest+like+the+best.png"
                }
                style={styles.left_logo}
              ></Image>
              <View style={styles.certicationsContainer}>
                {bannerData.is_jamawealth ? (
                  <View style={styles.certication}>
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+11.png"
                      }
                      style={{ width: 18, height: 30 }}
                    />
                    <Text style={[styles.sebi, { paddingLeft: 10 }]}>
                      Goi{" "}
                      <Text style={{ fontWeight: "bold", color: "black" }}>
                        DIPP
                      </Text>{" "}
                      Registered
                    </Text>
                  </View>
                ) : null}
                {bannerData.is_jamawealth ? (
                  <View style={styles.certication}>
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+7.png"
                      }
                      style={{ width: 29, height: 25 }}
                    />
                    <Text style={[styles.sebi, { paddingLeft: 10.17 }]}>
                      ISO{" "}
                      <Text style={{ fontWeight: "bold", color: "black" }}>
                        9001
                      </Text>{" "}
                      Certified
                    </Text>
                  </View>
                ) : null}
                {bannerData.is_jamawealth ? (
                  <View style={styles.certication}>
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+7.png"
                      }
                      style={{ width: 29, height: 25 }}
                    />
                    <Text style={[styles.sebi, { paddingLeft: 10.17 }]}>
                      ISO{" "}
                      <Text style={{ fontWeight: "bold", color: "black" }}>
                        27001
                      </Text>{" "}
                      Certified
                    </Text>
                  </View>
                ) : null}
                <View style={styles.sebiContainer}>
                  <View style={{ paddingLeft: 0.4 }}>
                    <Text style={[styles.sebi]}>
                      SEBI Regd Investment Advisor
                    </Text>
                    <Text style={styles.ina}>{bannerData.ria_code}</Text>
                    <Text style={[styles.sebi_1]}>
                      Simply Grow Technologies Pvt Ltd.
                    </Text>
                  </View>
                </View>
                <View style={styles.sebiContainer}>
                  <View style={{ paddingLeft: 10.4 }}>
                    <Text style={[styles.sebi]}>
                      SEBI Registered Portfolio Manager
                    </Text>
                    <Text style={styles.ina}>INP000007881</Text>
                    <Text style={[styles.sebi_1]}>
                    { isJama() ? "Jama" : "Maxiom" } Wealth Asset Management Pvt Ltd.{" "}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.right}>
              <View style={[isMobile ? styles.mob_styl : styles.web_styl]}>
                {bannerData.is_jamawealth ? (
                  <Image
                    source={bannerData.logo_image_url}
                    style={{ width: '100%', height: 100, marginBottom: 25 }}
                    resizeMode={"contain"}
                  />
                ) : null}
                <Text style={styles.welcomeTitle}>
                  Welcome to {bannerData.name}
                </Text>
                <View style={styles.inputsContainer}>
                  <View style={styles.inputContainer}>
                    <Text style={styles.email_field}>
                      Email
                    </Text>
                    <View>
                      <TextInput
                        style={styles.dmart_body_md}
                        placeholder={"Enter Email"}
                        placeholderTextColor={"#00000030"}
                        value={this.state.usernameValue}
                        onChangeText={(text) => {
                          this.setState({
                            usernameValue: text,
                            errorText: null,
                          });
                        }}
                        onKeyPress={(e) => {
                          if (e.nativeEvent.key == "Enter") {
                            this.handleContinue();
                          }
                        }}
                      />
                    </View>
                    {!!errorText && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {errorText || " "}
                      </Text>
                    )}
                  </View>
                </View>
                {isLoading ? (
                  <ActivityIndicator
                    size="large"
                    color="#410DAA"
                    style={{ paddingTop: 40 }}
                  />
                ) : (
                  <>
                    <TouchableOpacity
                      onPress={() => {
                        this.handleContinue();
                      }}
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 10,
                      }}
                    >
                      <Text style={styles.req_otp}>
                        Send Password Reset Mail
                      </Text>
                    </TouchableOpacity>
                    <Text
                      style={[
                        styles.clickHere,
                        {
                          fontSize: 15,
                          paddingTop: 40,
                          textDecorationLine: "underline",
                          textAlign: "center",
                        },
                      ]}
                      onPress={() => {
                        this.props.navigation.dispatch(
                          StackActions.replace("Login")
                        );
                      }}
                    >
                      Login With OTP
                    </Text>
                  </>
                )}
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: "#fff",
  },
  clickHere: {
    color: "#410DAA",
    fontWeight: "bold",
    textAlign: "center",
  },
  body: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  left: {
    flex: md(width) ? 1 : 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#410DAA08",
    paddingVertical: md(width) ? 75 : 1,
    display: md(width) ? "undefined" : "none",
  },
  right: {
    flex: 1,
  },
  mob_styl: {
    minWidth: 250,
    justifyContent: 'center',
    alignSelf: "center",
    marginTop: "15%",
  },
  web_styl: {
    marginTop: "15%",
    alignItems: "center",
  },
  best: {
    fontFamily: "arial",
    fontWeight: "bold",
    fontSize: 33,
    lineHeight: 38,
    color: "#464E5F",
    paddingBottom: 100,
  },
  left_logo: {
    resizeMode: "contain",
    width: 403,
    height: 241,
    alignSelf: "center",
    marginBottom: 60,
  },
  mobile_verf: {
    fontFamily: "arial",
    fontWeight: "bold",
    fontSize: md(width) ? 19 : 15,
    lineHeight: md(width) ? 22 : 17,
    textAlign: "center",
    color: "#121212",
    paddingTop: md(width) ? "undefined" : 50,
  },
  email_field: {
    fontFamily: "arial",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 18,
    color: "#222225",
    paddingBottom: md(width) ? 23 : 10.5,
  },
  please: {
    fontFamily: "arial",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3,
  },
  req_otp: {
    fontFamily: "arial",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 47,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  one: {
    color: "#121212",
  },
  dmart_body_md: {
    fontFamily: "Arial",
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "bold",
    color: "#121212",
    backgroundColor: "#f6f6f6",
    height: 41.02,
    paddingLeft: 45,
    borderRadius: 4,
  },
  sebi: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 14,
    color: "#000",
    paddingRight: 29,
  },
  sebi_1: {
    fontFamily: "Arial",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: 9,
    lineHeight: 14,
    color: "#51514E",
    paddingRight: 29,
  },
  ina: {
    fontFamily: "arial",
    fontWeight: "bold",
    fontSize: 10,
    lineHeight: 14,
    color: "#410DAA",
  },
  img1: {
    width: 30,
    height: 28.6,
  },
  welcomeTitle: {
    fontFamily: "Arial",
    fontSize: 22,
    color: "#202020",
    fontWeight: "bold",
    marginBottom: 45,
    textAlign: "center",
  },
  inputsContainer: {
    minWidth: "75%",
  },
  inputContainer: {
    marginBottom: 37,
  },
  forgotPassword: {
    color: "#232425",
    fontSize: 16,
    fontFamily: "Arial",
    marginTop: 18,
  },
  clickHere: {
    color: "#410DAA",
    fontWeight: "bold",
    paddingLeft: 6,
  },
  certication: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 25,
  },
  sebiContainer: {
    flexDirection: "row",
    paddingTop: 25,
  },
  certicationsContainer: {
    flexDirection: "row",
    paddingLeft: 30.5,
    paddingTop: 65,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  nine: {
    color: "#121212",
    position: "absolute",
    left: 15,
    bottom: isMobile ? 12 : 13,
    fontFamily: "Arial",
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "bold",
  },
});
