import React from "react";
import {
  View,
  Image,
  SafeAreaView,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  StatusBar,
  Platform,
  Text,
} from "react-native";
import EIcon from "@expo/vector-icons/EvilIcons";
import amplitude from "../../../utils/amplitude";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { StackActions } from "@react-navigation/native";
import { connect } from "react-redux";
import { isMobile } from "../../../utils";

const Goal = ({
  heading,
  description,
  imageSource,
  onPress,
  isCompleted = false,
  style,
}) => {
  return (
    <TouchableOpacity
      style={{
        borderWidth: 1,
        borderRadius: 6,
        borderColor: "#00000029",
        padding: 15,
        marginHorizontal: 16,
        flexDirection: "row",
        ...style,
      }}
      onPress={() => !isCompleted && onPress()}
    >
      {isCompleted && (
        <View
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(255,255,255,0.7)",
            zIndex: 10,
          }}
        ></View>
      )}

      <View style={{ flex: 0.8 }}>
        <Text
          style={{
            fontSize: 17,
            fontWeight: "bold",
            paddingBottom: 10,
            textAlign: "left",
          }}
        >
          {heading}
        </Text>
        <Text
          style={{
            fontSize: 14,
            textAlign: "left",
            color: "#121212",
            width: "95%",
          }}
        >
          {description}
        </Text>
      </View>
      <View
        style={{
          flex: 0.2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={{ uri: imageSource }}
          style={{ width: 50, height: 50 }}
          resizeMode="contain"
        />
        {isCompleted && (
          <View
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              justifyContent: "center",
              alignItems: "center",
              zIndex: 20,
            }}
          >
            <ANTIcon
              name="checkcircle"
              color={"#009105"}
              size={25}
              style={{
                backgroundColor: "white",
              }}
            />
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

class CreateFinplan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    // this.navigationWillFocusListener = props.navigation.addListener(
    //   "willFocus",
    //   () => {
    //     this.setData();
    //   }
    // );
  }

  componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("createFinplan",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("createFinplan", { path: this.props.navigation.getState().routeName });
    }
    this.setData();
  }

  setData = () => {
    try {
      const { params } = this.props.route;
      let from = null,
        type = null,
        goal_type = null;
      if (params) {
        from = params.from || null;
        type = params.type || null;
        goal_type = params.goal_type || null;
      }
      type = type || goal_type;
      if (type) {
        this.navigateToScreen(type, from);
      } else {
        this.setState({
          from,
          loading: false,
        });
      }
    } catch (error) {}
  };

  // componentWillUnmount() {
  //   this.navigationWillFocusListener.remove();
  // }

  navigateToScreen(type, from) {
    try {
      let screen = "";
      switch (type) {
        case "COMPLETE":
          screen = "BasicDetails";
          break;
        case "CHILDREN":
        case "RET":
        case "RETIREMENT":
          screen = "BasicDetails";
          break;
        case "OTH":
          let fromScreen = null,
            stack;
          const { params } = this.props.route;
          if (params) {
            fromScreen = params.from || null;
            stack = params.stack || null;
          }
          this.props.navigation.dispatch(
            StackActions.replace("App", {
              screen: "Goals",
              params: {
                screen: "CreateGoalsIntro",
                params: {
                  from: fromScreen || "CreateFinplan",
                  stack: stack || "Goals",
                },
              },
            })
          );
          return;
        default:
          break;
      }

      const { params: route_params = {} } = this.props.route;
      let title = null,
        from = null;
      if (route_params) {
        title = route_params.title || null;
        from = route_params.from || null;
      }
      const { title: t, from: f, ...restParams } = route_params;
      this.props.navigation.push(`${screen}`, {
        type,
        from,
        ...restParams,
      });
      this.setState({ loading: false, from });
    } catch (error) {
      this.setState({ loading: false, from });
    }
  }

  getPlanDetails = () => {
    const details = [
      {
        type: "RETIREMENT",
        heading: "Retirement Plan",
        description:
          "Plan a long and peaceful retired life using our award winning retirement solution.",
        imageSource:
          "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/retirement_finplan_landing_image.png",
        isCompleted: false,
      },
      {
        type: "CHILDREN",
        heading: "Child Financial Plan",
        description:
          "Secure a bright future for your child with a financial plan for their higher education and marriage.",
        imageSource:
          "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/child_future_finplan_landing.png",
        isCompleted: false,
      },
      {
        type: "OTH",
        heading: "Create Your Goal",
        description:
          "Custom goals for a rich life: that car, bike, vacation etc.",
        imageSource:
          "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/create_goal.png",
      },
    ];

    details.sort((i, j) => i.isCompleted - j.isCompleted);
    return details;
  };

  static navigationOptions = ({ navigation }) => ({
    tabBarVisible: false,
  });

  render() {
    const { navigation } = this.props;
    const planDetails = this.getPlanDetails();
    const { loading } = this.state;
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "white",
          marginBottom: isMobile ? 66 : 0,
        }}
      >
        <StatusBar
          backgroundColor={"#410DAA"}
          barStyle={Platform.OS == "ios" ? "dark-content" : "light-content"}
        />
        {loading ? (
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        ) : (
          <ScrollView showsVerticalScrollIndicator={true}>
            {/* <Modal
            isVisible={modalVisible}
            style={{
              margin: 0
            }}
            onBackdropPress={() => {
              closeModal();
            }}
            backdropColor={"rgba(0,0,0,0.1)"}
            // animationIn={"zoomInDown"}
            // animationOut={"zoomOutUp"}
            // animationInTiming={1000}
            // animationOutTiming={1000}
            // backdropTransitionInTiming={1000}
            // backdropTransitionOutTiming={1000}
          > */}
            <View
              style={{
                flex: 1,
                backgroundColor: "white",
                alignItems: "center",
                paddingHorizontal: isMobile ? 16 : 32,
              }}
            >
              <TouchableOpacity
                onPress={async () => {
                  const { params } = this.props.route;
                  let fromProfile = null,
                    from = null,
                    stack = null;

                  if (params) {
                    fromProfile = params.fromProfile || null;
                    from = params.from || null;
                    stack = params.stack || null;
                  }

                  if (fromProfile) {
                    navigation.goBack();
                  } else {
                    if (from) {
                      const { from, stack, ...restOptions } = params;
                      this.props.navigation.dispatch(
                        StackActions.replace("App", {
                          screen: "Dashboard",
                          params: { screen: stack, params: { screen: from } },
                        })
                      );
                    } else {
                      // this.props.navigation.navigate("InvestmentOverview");
                      this.props.navigation.dispatch(
                        StackActions.replace("App", {
                          screen: "Dashboard",
                          // params: { screen: stack, params: { screen: from } },
                        })
                      );
                    }
                  }
                }}
                style={{
                  position: "absolute",
                  right: 15,
                  top: 15,
                }}
              >
                <EIcon name="close" color="#121212" size={35} />
              </TouchableOpacity>
              <View
                style={{
                  alignItems: "center",
                  marginTop: 60,
                  paddingHorizontal: 20,
                }}
              >
                <Text
                  style={{
                    fontSize: 17,
                    fontWeight: "bold",
                    paddingVertical: 10,
                    textAlign: "center",
                  }}
                >
                  Why have a Financial Plan?
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    textAlign: "center",
                    color: "#121212",
                  }}
                >
                  A rich life needs to be funded. We help plan with this all the
                  numbers and real time tracking of goals.
                </Text>
              </View>

              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: "#00000029",
                  padding: 10,
                  marginHorizontal: 16,
                  marginTop: 43,
                  width: isMobile ? "100%" : "50%",
                  maxWidth: isMobile ? "100%" : "50%",
                }}
                onPress={async () => {
                  this.navigateToScreen("COMPLETE");
                }}
              >
                <Image
                  source={{
                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/finplan_landing_image.png",
                  }}
                  style={{ width: 125, height: 125, alignSelf: "center" }}
                  resizeMode="contain"
                />
                <Text
                  style={{
                    fontSize: 17,
                    fontWeight: "bold",
                    paddingVertical: 10,
                    textAlign: "center",
                  }}
                >
                  Start your financial plan
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    textAlign: "center",
                    color: "#121212",
                  }}
                >
                  A financial plan is required to get personalised investment
                  advice.
                </Text>
              </TouchableOpacity>

              {planDetails &&
                planDetails.map((item, index) => {
                  return (
                    <Goal
                      key={index}
                      style={{
                        marginTop: 15,
                        marginBottom: planDetails.length - 1 == index ? 15 : 0,
                        width: isMobile ? "100%" : "50%",
                        maxWidth: isMobile ? "100%" : "50%",
                      }}
                      heading={item.heading}
                      description={item.description}
                      onPress={() => {
                        this.navigateToScreen(item.type);
                      }}
                      imageSource={item.imageSource}
                      isCompleted={item.isCompleted}
                    />
                  );
                })}
            </View>
            {/* </Modal> */}
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

export default connect(null, null)(CreateFinplan);
