import React from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Dimensions,
  Linking,
} from "react-native";
import { md } from "../../Responsive";
import { Text } from "react-native-paper";
import global from "./globalStyles";

const rnWindow = Dimensions.get("window");

export default function PlanHeader({
  plan,
  navigation,
}) {
  let color = "#EEE";
  switch (plan.name) {
    case "gold":
      color = "#F3A915";
      break;
    case "platinum":
      color = "#1CA098";
      break;
    case "jewel":
      color = "#410DAA";
      break;
    default:
      color = "#EEE";
      break;
  }

  return (
    <>
      <View style={[styles.container, { backgroundColor: color }]}>
        <Text style={[styles.sub, global.arial]}>Welcome to</Text>
        <Text style={[styles.title, global.arial]}>
          {plan.name === "jewel"
            ? `${plan.displayName} Membership`
            : `Jamā ${plan.displayName}`}
        </Text>
        <Text style={[styles.tag, global.arial]}>{plan.tag}</Text>
        <TouchableOpacity
          onPress={() => {
            if (plan.name === "jewel") {
              navigation.navigate("MySubscriptionContactPage", {
                for_query: false,
              });
            } else {
              if (plan.name === "gold") {
                Linking.openURL("https://www.maxiom.co.in/app/jfp/PP/");
              } else if (plan.name === "platinum") {
                Linking.openURL("https://www.maxiom.co.in/app/jfp/PLA/");
              }
            }
          }}
        >
          <Text style={[styles.link, { color: color }, global.arial]}>
            {plan.callToAction}
          </Text>
        </TouchableOpacity>
        <Text style={[styles.priceSubtitle, global.arial]}>
          {" "}
          {plan.price && (
            <Text style={[styles.price, global.arial]}>{"₹" + plan.price}</Text>
          )}{" "}
          {plan.priceSubtitle}
        </Text>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: md(rnWindow.width) ? 40 : 30,
    paddingBottom: md(rnWindow.width) ? 40 : 30,
  },
  title: {
    textAlign: "center",
    fontSize: md(rnWindow.width) ? 35 : 25,
    color: "white",
    fontWeight: "bold",
    width: 211,
    alignSelf: "center",
    marginTop: 13,
  },
  tag: {
    alignSelf: "center",
    color: "white",
    fontSize: md(rnWindow.width) ? 20 : 15,
    marginTop: md(rnWindow.width) ? 13 : 11,
    textAlign: "center",
  },
  sub: {
    fontSize: md(rnWindow.width) ? 20 : 15,
    textAlign: "center",
    color: "white",
  },
  link: {
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 15,
    backgroundColor: "white",
    borderRadius: 5,
    alignSelf: "center",
    fontWeight: "bold",
    marginTop: 20,
    alignSelf: md(rnWindow.width) ? "center" : "stretch",
    marginLeft: md(rnWindow.width) ? undefined : 16,
    marginRight: md(rnWindow.width) ? undefined : 16,
    paddingLeft: md(rnWindow.width) ? 60 : undefined,
    paddingRight: md(rnWindow.width) ? 60 : undefined,
    textAlign: "center",
    maxWidth: md(rnWindow.width) ? 300 : undefined,
  },
  price: {
    fontSize: md(rnWindow.width) ? 20 : 15,
    color: "white",
    marginTop: md(rnWindow.width) ? 15 : 10,
    fontWeight: "bold",
    textAlign: md(rnWindow.width) ? "center" : "left",
  },
  priceSubtitle: {
    color: "white",
    textAlign: "center",
    alignSelf: "center",
    fontSize: md(rnWindow.width) ? 15 : 11,
    opacity: 0.8,
    marginTop: md(rnWindow.width) ? 19 : 14,
    textAlign: md(rnWindow.width) ? "center" : "left",
    alignSelf: md(rnWindow.width) ? "center" : "flex-start",
    marginLeft: md(rnWindow.width) ? undefined : 16,
  },
});
