import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native";
import { ActivityIndicator } from "react-native-paper";
import amplitude from "../../utils/amplitude";
import { StackActions } from "@react-navigation/native";
import { isWeb, isMobile, getUserDetails } from "../../utils";
import { getServerName } from "../../api/urls";
import { deletePlansAndInvoicesForPaymentFailed, makePaymentOrderSummary, moveDataFromRegularToDirect, sendMailUsingTemplate, updateLongDurationPlanData } from "../../api";
import { getInvoicesPaymentStatus } from "../../api/billing";
import { ShowToast } from "../Common/Toast";
import Store from "../../store";
import { LOGOUT } from "../../actions/types";
import { saveCustomerData } from "../../api/account";

export default class UpgradeAdvisoryPlanRedirection extends React.Component {
  constructor(props) {
    super(props);
    let is_preferred_circle = false;
    let is_club = false;
    let is_elite = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (hostname.includes("myadvisor") || hostname.includes("dmyadvisor")) {
        is_preferred_circle = true;
    }
    if (hostname.includes("club") || hostname.includes("dclub")) {
        is_club = true;
    }
    if (hostname.includes("app") || hostname.includes("dapp") || hostname.includes("localhost")) {
        is_elite = true;
    }
    this.state = {
      timer: 3,
      data: null,
      showTimer: false,
      isLoading: true,
      showAlert: false,
      is_preferred_circle,
      is_club,
      is_elite,
      showToast: false,
      toastMessage: null,
      plan_code: "",
      myadvisorToken: null
    };
  }

  handleContinueToLogout = async () => {
    try {
      localStorage.removeItem("isShowAppDownloadCard");
      localStorage.removeItem("showPlanRenewalCard");
      localStorage.removeItem("redirectAfterOnboardingSuccess");
      Store.dispatch({ type: LOGOUT });
    } catch (error) {
      Store.dispatch({ type: LOGOUT });
    }
  };

  showButton = () => {
    this.interval = setInterval(
      () => this.setState((prevState) => ({ timer: prevState.timer - 1 })),
      1000
    );
    let { is_preferred_circle, is_club, is_elite} = this.state;
    this.redirect = setTimeout(() => {
        if (is_preferred_circle) {
            this.props.navigation.dispatch(
                StackActions.replace("App", {
                    screen: "Dashboard",
                    params: {
                        screen : "Home",
                        params: {
                            showToastMessage: true,
                            toastMessage: "Succesfully upgraded your plan."
                        }
                    },
                })
            );
        } else if (is_club) {
            // this.setState({
            //   showToast: true,
            //   toastMessage: "Kindly wait, You will be redirect to myadvisor to login."
            // })
            let { myadvisorToken } = this.state;
            let reverseToken = myadvisorToken.split("").reverse().join("");
            this.handleContinueToLogout();
            global.open("https://dmyadvisor.jamawealth.com/login/?unique="+reverseToken, "_blank");
        } else if (is_elite && this.state.plan_taken=="PSF") {
            this.props.navigation.dispatch(
              StackActions.replace("App", {
                  screen: "Dashboard",
                  params: {
                      screen : "Home",
                      params: {
                          showToastMessage: true,
                          toastMessage: "Succesfully upgraded your plan."
                      }
                  },
              })
          );
        } else if (is_elite && this.state.plan_taken!="PSF") {
            // this.setState({
            //   showToast: true,
            //   toastMessage: "Kindly wait, You will be redirect to myadvisor to login."
            // })
            let { userData } = this.state;
            let token = userData.token;
            let reverseToken = token.split("").reverse().join("");
            this.handleContinueToLogout();
            global.open("https://dmyadvisor.jamawealth.com/login/?unique="+reverseToken, "_blank");
        }
    }, 3000);
  };

  componentDidUpdate() {
    if (this.state.timer === 0) {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    this.interval && clearInterval(this.interval);
    this.redirect && clearTimeout(this.redirect);
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("upgradeAdvisoryPlanRedirection",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("upgradeAdvisoryPlanRedirection", { path: this.props.navigation.getState().routeName });
      }
      let is_club = false;
      let is_elite = false;
      let { user_database } = Store.getState().userData.userProfileData;
      let hostname = Platform.OS == "web" ? global.location.hostname : user_database;
      if(hostname.includes('app')) {
        is_elite = true;
      }
      if(hostname.includes('club')) {
        is_club = true;
      }
      let userData = getUserDetails();
      let paymentRespnse = null;
      // if (is_club && userData.customer_id == 3) {
      //   paymentRespnse = { 'code': 100, 'msg': "Payment success", 'results': {}, "invoices_data": "", "plan_code": "AHY" }
      // } else {
      paymentRespnse = await getInvoicesPaymentStatus();
      // }
      if (paymentRespnse && paymentRespnse.code == 100) {
        const { results = {} } = paymentRespnse;
        const { status } = results;
        this.setState({
            userData,
            plan_taken: paymentRespnse.plan_code
        })
        if (paymentRespnse.msg == "Payment success") {
          const payload = {
            stage_name: "upgrade_plan_payment",
            parameters: {
                "Name" : userData.first_name,
                "link" : "https://maxiom.co.in/app/jaI_Ho_28_07_6102/admin/kbapp/customerinvestmentplan/?q="+userData.first_name
            },
            to_mail: "ops@maxiom.in",
            cc_mail: "",
            subj_params: {}
          }
          const response = await sendMailUsingTemplate(payload);
          if (paymentRespnse.plan_code == "PSF") {
            // console.log("Platform Subscription")
            const plan_resp = await updateLongDurationPlanData();
          } else {
            if (is_club) {
              const payload = {
                cust_pk : userData.customer_id,
                plan_code: paymentRespnse.plan_code
              }
              // console.log(payload);
              const response = await moveDataFromRegularToDirect(payload);
              // console.log(response);
              if (response && response.code == 100) {
                this.setState({
                  myadvisorToken: response.token
                })
              }
            }
            if (is_elite) {
              const payload = {
                username: userData.username,
                database_host: "myadvisor.jamawealth.com"
              };      
              const response = await saveCustomerData(userData.customer_id, payload);
              // console.log("Reposnce updated is 2 ", response)
            }
            if (is_club || is_elite) {
              this.setState({
                showToast: true,
                toastMessage: "Kindly wait, You will be redirect to myadvisor to login."
              })
            }
          }
          this.setState(
            {
              isLoading: false,
              showSuccessResponse: true,
              showTimer: true,
            },
            () => {
              this.showButton();
              return;
            }
          );
          return;
        } else {
            // console.log("Payment failed");
            // console.log(paymentRespnse);
            let invoices_payload = {
              "invoices_data": paymentRespnse.invoices_data
            }
            const response = await deletePlansAndInvoicesForPaymentFailed(invoices_payload);
            // console.log(response);
            if (paymentRespnse.plan_code == "PSF") {
              let show_club_upgrade = true;
              const { is_mfcustomer, is_equitycustomer } = getUserDetails();
              if (is_mfcustomer || is_equitycustomer) {
                  show_club_upgrade = false;
              }
              this.props.navigation.dispatch(
                StackActions.replace("App", {
                  screen: "Plans",
                  params: {
                      screen: "SubscriptionPlanHome",
                      params: {
                        show_club: show_club_upgrade,
                        show_preferredcircle: true,
                        show_elite: true,
                        cip_pk_for_renewal: null,
                        showToast: true,
                        toastMessage: "Payment Failed, Please Try Again."
                      }
                  },
                  })
              )
            } else {
              this.props.navigation.dispatch(
                StackActions.replace("App", {
                screen: "Account",
                params: {
                    screen: "AdvisoryPlan",
                    params: {
                        payment_failed: true
                    }
                },
                })
              );
            }
            return;
        }
      }
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    } catch (error) {
      console.log(error)
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    }
  }

  handleContinue = async () => {
    try {
      this.setState({ gettingPaymentLink: true });
      const server_name = getServerName();
      const payload = {
        server_name, // NEED TO CHANGE
      };
      const response = await makePaymentOrderSummary(payload);
      if (response && response.code == 100) {
        const { results } = response;
        if (results && results.length > 0) {
          const { paymentLink } = results[0];
          // global.open(paymentLink, "_self");
          this.props.navigation.navigate("App", {
            screen: "Dashboard",
            params: {
              screen: "Portfolio",
              params: {
                screen: "ShowWebView",
                params: {
                  url: paymentLink,
                  successURL: "onboarding/order/paymentsuccess",
                  onSuccess: function (navigation, results = {}) {
                    navigation.push("Onboarding", {
                      screen: "PaymentSuccess",
                      params: {},
                    });
                  },
                  onError: function (results = {}) {
                    // navigation.push("App", { screen: "Dashboard", params: { screen: "Home", params: {} } });
                  },
                },
              },
            },
          });
        } else {
          this.setState({
            error: true,
            errorText: "Something went wrong!",
            gettingPaymentLink: false,
          });
        }
      } else {
        this.setState({
          error: true,
          errorText: response.msg || "Something went wrong!",
          gettingPaymentLink: false,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        gettingPaymentLink: false,
      });
    }
  };

  moveToNextScreen = () => {
    if (is_preferred_circle) {
      this.props.navigation.dispatch(
          StackActions.replace("App", {
              screen: "Dashboard",
              params: {
                  screen : "Home",
                  params: {
                      showToastMessage: true,
                      toastMessage: "Succesfully upgraded your plan."
                  }
              },
          })
      );
  } else if (is_club) {
      this.setState({
        showToast: true,
        toastMessage: "Kindly wait, You will be redirect to myadvisor to login."
      })
      // console.log("MyAdvisor Redirection")
      let { myadvisorToken } = this.state;
      let reverseToken = myadvisorToken.split("").reverse().join("");
      this.handleContinueToLogout();
      global.open("https://dmyadvisor.jamawealth.com/login/?unique="+reverseToken, "_blank");
  } else if (is_elite) {
      this.setState({
        showToast: true,
        toastMessage: "Kindly wait, You will be redirect to myadvisor to login."
      })
      let {userData} = this.state;
      let token = userData.token;
      let reverseToken = token.split("").reverse().join("");
      this.handleContinueToLogout();
      global.open("https://dmyadvisor.jamawealth.com/login/?unique="+reverseToken, "_blank");
  }
  };

  render() {
    const { timer, showTimer, isLoading, error, showToast, toastMessage } = this.state;
    return (
      <View style={styles.container}>
        {showToast ? (
          <ShowToast
            showToast={true}
            type="status"
            title={toastMessage}
            duration="10"
          />
        ) : null}
        {isLoading || error ? (
          <View style={styles.loadingContainer}>
            {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
            {error && (
              <Text>Something went wrong ! Error getting payment status </Text>
            )}
          </View>
        ) : (
          <ScrollView style={styles.mainContainer}>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ffffff",
                paddingBottom: isWeb ? 90 : 200,
                paddingHorizontal: isWeb ? "undefined" : 19,
              }}
            >
              <Text style={styles.you_have}>
                Your Payment has been completed
              </Text>
              <Text
                style={{
                  fontFamily: "Roboto",
                  fontSize: isWeb ? 16 : 15,
                  lineHeight: 21,
                  color: "#000000",
                  fontWeight: "bold",
                  paddingTop: 17,
                  paddingBottom: 17,
                  fontWeight: "bold",
                  textAlign: isWeb ? "undefined" : "center",
                }}
              >
                You will receive a receipt shortly over email
              </Text>
              <Image
                source={
                  "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/money_transfer_-2+1.png"
                }
                style={styles.img}
              />
              {timer != null && (
                <>
                  {showTimer && (
                    <Text style={styles.redirect}>
                      Redirecting in{" "}
                      <Text style={{ color: "lightblue", fontWeight: "bold" }}>
                        {timer} sec
                      </Text>
                    </Text>
                  )}
                  <TouchableOpacity onPress={this.moveToNextScreen}>
                    <View>
                      <Text style={styles.cont}>Continue</Text>
                    </View>
                  </TouchableOpacity>
                </>
              )}
            </View>
          </ScrollView>
        )}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  img: {
    width: isWeb ? 400 : 252,
    height: isWeb ? 350 : 198,
  },
  you_have: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 20 : 15,
    lineHeight: 21,
    color: "#000000",
    fontWeight: "bold",
    paddingTop: isWeb ? 50 : 32,
    paddingBottom: 17,
    fontWeight: "bold",
    textAlign: isWeb ? "undefined" : "center",
  },
  completed: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 15 : 13,
    lineHeight: isWeb ? 17 : 21,
    color: "#121212",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: isWeb ? 38 : 32,
  },
  cont: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    paddingVertical: 15,
    paddingHorizontal: 74,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  redirect: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    textAlign: "center",
    color: "rgba(18, 18, 18, 0.4)",
    paddingBottom: 30,
  },
  errorText: {
    color: "red",
    fontSize: 15,
    marginVertical: 15,
  },
  container: { flex: 1 },
  mainContainer: {
    flexGrow: 1,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  noContent: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  nothingFound: {
    fontSize: 15,
    color: "#121212",
  },
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: !isMobile ? 102 : 13,
    paddingRight: !isMobile ? 88 : 13,
    paddingBottom: 60,
  },
  left: {
    flex: 0.45,
    paddingTop: 55,
    display: isWeb ? "undefined" : "none",
  },
  right: {
    flex: !isMobile ? 0.55 : 1,
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },
  lock: {
    resizeMode: "contain",
    width: 24.5,
    height: 34,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 22,
    lineHeight: 25,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
    paddingTop: 11,
    paddingBottom: 32,
    paddingRight: 160,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  investment: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    fontWeight: "bold",
    color: "#2F3044",
    textAlign: "center",
    paddingTop: 50,
    paddingBottom: 27,
    display: isWeb ? "undefined" : "none",
  },

  plan: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 14 : 13,
    lineHeight: isWeb ? 16 : 15,
    color: "#464E5F",
  },
  plan_item: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    color: "#4A4B68",
    letterSpacing: 0.5,
    paddingRight: 25,
  },
  invoice_number: {
    fontSize: 14,
    color: "#8950FC",
    fontWeight: "bold",
  },
  invoice_date: {
    fontSize: 14,
    color: "#464E5F",
    fontWeight: "bold",
  },
  inv_amt: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#4A4B68",
    fontWeight: "normal",
  },
  inv_amt1: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#4A4B68",
    paddingTop: 9,
  },
  date: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#737178",
    letterSpacing: 0.5,
    borderBottomWidth: 1,
    //borderBottomRadius:6,
    borderBottomColor: "#E5EAEE",
    paddingBottom: 10,
  },
  inv_num: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 14 : 13,
    lineHeight: isWeb ? 16 : 21,
    color: "#8950FC",
    letterSpacing: 0.5,
    fontWeight: "bold",
    paddingTop: 21,
    paddingBottom: 4,
  },
  tot_bot: {
    borderTopColor: "#E5EAEE",
    borderBottomColor: "#E5EAEE",
    borderRadius: 6,
    //borderBottomRadius:6,
    borderTopWidth: isWeb ? 8 : 3,
    borderBottomWidth: isWeb ? 8 : 3,
    paddingVertical: 15,
    paddingHorizontal: isWeb ? 70 : "undefined",
    paddingLeft: isWeb ? "undefined" : 15,
    paddingRight: isWeb ? "undefined" : 43,
    marginTop: isWeb ? 20 : "undefined",
  },
  tot_amt: {
    fontFamily: "Roboto",
    fontWeight: isWeb ? "bold" : "400",
    lineHeight: 15,
    fontSize: isWeb ? 14 : 13,
    letterSpacing: 0.32,
    color: "#464E5F",
  },
  tot_amt1: {
    fontFamily: "Roboto",
    lineHeight: isWeb ? 16 : 15,
    fontSize: isWeb ? 15 : 13,
    letterSpacing: 0.32,
    color: "#464E5F",
    paddingTop: 11,
  },
  gra_tot: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: isWeb ? 16 : 15,
    fontSize: isWeb ? 15 : 13,
    letterSpacing: 0.32,
    color: "#000000",
    paddingTop: 14,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingHorizontal: 44,
    paddingVertical: 12,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  mob_plan: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    fontWeight: "bold",
    color: "#4A4B68",
  },
  mob_year: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#4A4B68",
    paddingTop: 9,
  },
  mob_top_pybl: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    fontWeight: "bold",
    letterSpacing: 0.32,

    textAlign: "center",
    flex: 1,
  },
  tt_amt: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 16,
    color: "#121212",
  },
  modalMobile: {
    position: "absolute",
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  modalWeb: {
    //    position:"fixed",
    // position: "absolute",
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  retry: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingHorizontal: 44,
    paddingVertical: 11,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  payment: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    color: "#F64E60",
    fontWeight: "bold",
  },
  comp: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    color: "#121212",
    paddingTop: 17,
    paddingBottom: 26,
  },
});
