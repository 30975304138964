import React from "react";
import {
  View,
  Text,
  SafeAreaView,
  StatusBar,
  FlatList,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity
} from 'react-native';
import Store from "../../store";
import amplitude from "../../utils/amplitude";
import ProgressBar from "../../utils/progressBar";
import { getUserDetails, isMobile } from "../../utils";
import { AntDesign } from "@expo/vector-icons";
import TakenTable from "../FinancialWellnessIndex/TakenTable";
import { getCustomerDetails, getLeaderBoardDetails, getLeaderBoardUsersDetails } from "../../api";
import Modal from "../../components/Common/Modal";


class LeaderBoard extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    const { params } = this.props.route;
    if (params) {
      userId = params.user_id || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }

    this.state = {
      userId,
      isLoading: false,
      activeTab: "Teams",
      showModal: false,
      modalTabName: "Overall",
      valuesForModalToShow: [],
      finLiteracyCompletion: [],
      finLiteracyQuizScores: [],
      finPlanHealth: [],
      financialWellness: [],
      finLiteracyCompletionUsers: [],
      finLiteracyQuizScoresUsers: [],
      finPlanHealthUsers: [],
      financialWellnessUsers: [],
      sorting_field: null,
      sorting_value: null,
      headerforAll: [],
      headerforAllUsers: [],
      valuesfinLiteracyCompletion: [],
      valuesfinLiteracyQuizScores: [],
      valuesfinPlanHealth: [],
      valuesfinancialWellness: [],
      valuesfinLiteracyCompletionUsers: [],
      valuesfinLiteracyQuizScoresUsers: [],
      valuesfinPlanHealthUsers: [], 
      valuesfinancialWellnessUsers: [],
      overAllTeam: [],
      overAllIndividuals: [],
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("LeaderBoard",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("LeaderBoard", { path: this.props.navigation.getState().routeName });
    }
    let userData = {};
    this.setState({ isLoading: true })
    if (this.state.userId) {
      userData = await getCustomerDetails(
        this.state.userId
      );
    } else {
      userData = await getUserDetails();
    }
    let payload = {
      "corp_id": userData.corporate_id,
      "manager_id": ""
    }
    let res = await getLeaderBoardDetails(payload);

    let res_users = await getLeaderBoardUsersDetails(payload);

    for (const [index, value] of Object.entries(res.result)) {
      if (value.dashboard_type === "Fin Literacy Completion") {
        this.state.finLiteracyCompletion.push(value);
      } else if (value.dashboard_type === "Fin Literacy Quiz Scores") {
        this.state.finLiteracyQuizScores.push(value);
      } else if (value.dashboard_type === "Fin Plan Health") {
        this.state.finPlanHealth.push(value);
      } else if (value.dashboard_type === "Financial Wellness") {
        this.state.financialWellness.push(value);
      }
    }
    for (const [index, value] of Object.entries(res_users.result)) {
      if (value.dashboard_type === "Fin Literacy Completion") {
        this.state.finLiteracyCompletionUsers.push(value);
      } else if (value.dashboard_type === "Fin Literacy Quiz Scores") {
        this.state.finLiteracyQuizScoresUsers.push(value);
      } else if (value.dashboard_type === "Fin Plan Health") {
        this.state.finPlanHealthUsers.push(value);
      } else if (value.dashboard_type === "Financial Wellness") {
        this.state.financialWellnessUsers.push(value);
      }
    }

    const { sorting_field, sorting_value } = this.state;

    const keysDataUsers = [
      {
        label: "Person",
        key: "name",
      },
      {
        label: "Team",
        key: "team"
      },
      {
        label: "Score",
        key: "score",
      },
    ];


    const keysData = [
      {
        label: "Team",
        key: "team",
      },
      {
        label: "Score",
        key: "score",
      },
    ];

    let headerforAll = [];
    let headerforAllUsers = [];
    keysData.forEach((headerItem) => {
      headerforAll.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: false,
        // onSort: this.handleAssessmentTakenSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });
    keysDataUsers.forEach((headerItem) => {
      headerforAllUsers.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: false,
        // onSort: this.handleAssessmentTakenSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });
    this.getTopTeamsData(this.state.finLiteracyCompletion, this.state.finLiteracyQuizScores, this.state.finPlanHealth, this.state.financialWellness);
    this.getTopUsersData(this.state.finLiteracyCompletionUsers, this.state.finLiteracyQuizScoresUsers, this.state.finPlanHealthUsers, this.state.financialWellnessUsers);
    this.setState({ headerforAll, headerforAllUsers, isLoading: false })

  }

  sortByCompositeScore(data, ascending = true) {
    // Convert the object to an array of key-value pairs
    const dataArray = Object.entries(data);

    // Sort the array based on the "compositeScore" attribute
    dataArray.sort((a, b) => {
      if (ascending) {
        return a[1].compositeScore - b[1].compositeScore;
      } else {
        return b[1].compositeScore - a[1].compositeScore;
      }
    });

    return dataArray;
  }

  SelectTabDetails = ({ item, onPress, backgroundColor, color }) => {
    return (
      <TouchableOpacity
        style={{
          borderWidth: 1,
          borderColor: "#BCBCBC",
          borderRadius: 18,
          backgroundColor,
          marginRight: 16,
          paddingLeft: 20,
          paddingRight: 20,
          height: 36,
          alignItems: "center",
          justifyContent: "center",
        }}
        onPress={onPress}
      >
        <Text style={{ fontSize: 14, color, fontWeight: "700" }}>{item}</Text>
      </TouchableOpacity>
    );
  };

  getTopTeamsData = (finLiteracyCompletion, finLiteracyQuizScores, finPlanHealth, financialWellness) => {

    let countFinLitComp = {};
    let countFinLitQuiz = {};
    let countPlanHealth = {};
    let countWellNess = {};

    for (const item of financialWellness) {
      const key = item.team_or_department_name;

      if (key !== null) {
        if (countWellNess[key]) {
          countWellNess[key].count += 1;
          countWellNess[key].additionalAttribute += item.AVG_QUIZ_AVG / item.TEAM_COUNT;
          countWellNess[key].compositeScore = parseInt(countWellNess[key].additionalAttribute / countWellNess[key].count)
        } else {
          countWellNess[key] = {
            count: 1,
            additionalAttribute: (item.AVG_QUIZ_AVG / item.TEAM_COUNT),
            compositeScore: parseInt(item.AVG_QUIZ_AVG)
          };
        }
      }
    }



    for (const item of finLiteracyCompletion) {
      const key = item.team_or_department_name;

      if (key !== null) {
        if (countFinLitComp[key]) {
          countFinLitComp[key].count += 1;
          countFinLitComp[key].additionalAttribute += item.COUNT_OF_USERS_COMPLETED / item.TEAM_COUNT;
          countFinLitComp[key].compositeScore = parseInt(countFinLitComp[key].additionalAttribute / countFinLitComp[key].count)
        } else {
          countFinLitComp[key] = {
            count: 1,
            additionalAttribute: (item.COUNT_OF_USERS_COMPLETED / item.TEAM_COUNT),
            compositeScore: parseInt(item.COUNT_OF_USERS_COMPLETED)
          };
        }
      }
    }

    for (const item of finLiteracyQuizScores) {
      const key = item.team_or_department_name;

      if (key !== null) {
        if (countFinLitQuiz[key]) {
          countFinLitQuiz[key].count += 1;
          countFinLitQuiz[key].additionalAttribute += item.AVG_QUIZ_AVG / item.TEAM_COUNT;
          countFinLitQuiz[key].compositeScore = parseInt(countFinLitQuiz[key].additionalAttribute / countFinLitQuiz[key].count)
        } else {
          countFinLitQuiz[key] = {
            count: 1,
            additionalAttribute: (item.AVG_QUIZ_AVG / item.TEAM_COUNT),
            compositeScore: parseInt(item.AVG_QUIZ_AVG)
          };
        }
      }
    }



    for (const item of finPlanHealth) {
      const key = item.team_or_department_name;

      if (key !== null) {
        if (countPlanHealth[key]) {
          countPlanHealth[key].count += 1;
          countPlanHealth[key].additionalAttribute += item.COUNT_OF_USERS_COMPLETED / item.TEAM_COUNT;
          countPlanHealth[key].compositeScore = parseInt(countPlanHealth[key].additionalAttribute / countPlanHealth[key].count)
        } else {
          countPlanHealth[key] = {
            count: 1,
            additionalAttribute: (item.COUNT_OF_USERS_COMPLETED / item.TEAM_COUNT),
            compositeScore: parseInt(item.COUNT_OF_USERS_COMPLETED)
          };
        }
      }
    }

    countFinLitComp = this.sortByCompositeScore(countFinLitComp, false);
    countFinLitQuiz = this.sortByCompositeScore(countFinLitQuiz, false);
    countPlanHealth = this.sortByCompositeScore(countPlanHealth, false);
    countWellNess = this.sortByCompositeScore(countWellNess, false);

    let mergedtopData = this.mergeAndSumArrays(countFinLitComp, countFinLitQuiz, countPlanHealth, countWellNess);

    mergedtopData.sort((a, b) => b[1].compositeScore - a[1].compositeScore);
    // console.log("mergedtopData " + JSON.stringify(mergedtopData));

    let overAllTeam = [];

    mergedtopData.forEach(([key, value]) => {
      if (value.additionalAttribute !== null) {
        overAllTeam.push([
          {
            valueToDisplay: key,
            value: key,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.compositeScore,
            value: value.additionalAttribute,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          }
        ]);
      }
    });


    let valuesfinLiteracyCompletion = [];
    let valuesfinLiteracyQuizScores = [];
    let valuesfinPlanHealth = [];
    let valuesfinancialWellness = [];

    countWellNess.forEach(([key, value]) => {
      if (value.additionalAttribute !== null) {
        valuesfinancialWellness.push([
          {
            valueToDisplay: key,
            value: key,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.compositeScore,
            value: value.additionalAttribute,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          }
        ]);
      }
    });
    countFinLitComp.forEach(([key, value]) => {
      if (value.additionalAttribute !== null) {
        valuesfinLiteracyCompletion.push([
          {
            valueToDisplay: key,
            value: key,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.compositeScore,
            value: value.additionalAttribute,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          }
        ]);
      }
    });
    countFinLitQuiz.forEach(([key, value]) => {
      if (value.additionalAttribute !== null) {
        valuesfinLiteracyQuizScores.push([
          {
            valueToDisplay: key,
            value: key,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.compositeScore,
            value: value.additionalAttribute,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          }
        ]);
      }
    });
    countPlanHealth.forEach(([key, value]) => {
      if (value.additionalAttribute !== null) {
        valuesfinPlanHealth.push([
          {
            valueToDisplay: key,
            value: key,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.compositeScore,
            value: value.additionalAttribute,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          }
        ]);
      }
    });
    this.setState({
      overAllTeam: overAllTeam,
      valuesfinLiteracyCompletion: valuesfinLiteracyCompletion,
      valuesfinLiteracyQuizScores: valuesfinLiteracyQuizScores,
      valuesfinPlanHealth: valuesfinPlanHealth,
      valuesfinancialWellness: valuesfinancialWellness
    })
  }

  getTopUsersData = (finLiteracyCompletionUsers, finLiteracyQuizScoresUsers, finPlanHealthUsers, financialWellnessUsers) => {

    let countFinLitComp = {};
    let countFinLitQuiz = {};
    let countPlanHealth = {};
    let countWellNess = {};
    for (const item of financialWellnessUsers) {
      const key = item.customer_id;

      if (key !== null && (item.first_name != null && item.first_name != "" )) {
        if (countWellNess[key]) {
          countWellNess[key].count += 1;
          countWellNess[key].additionalAttribute += item.AVG_QUIZ_AVG;
          countWellNess[key].compositeScore = parseInt(countWellNess[key].additionalAttribute / countWellNess[key].count)
        } else {
          countWellNess[key] = {
            count: 1,
            additionalAttribute: item.AVG_QUIZ_AVG,
            name: item.first_name,
            compositeScore: parseInt(item.AVG_QUIZ_AVG),
            teamName: item.team_or_department_name
          };
        }
      }
    }
    for (const item of finLiteracyCompletionUsers) {
      const key = item.customer_id;

      if (key !== null && (item.first_name != null && item.first_name != "" )) {
        if (countFinLitComp[key]) {
          countFinLitComp[key].count += 1;
          countFinLitComp[key].additionalAttribute += item.COUNT_OF_USERS_COMPLETED;
          countFinLitComp[key].compositeScore = parseInt(countFinLitComp[key].additionalAttribute / countFinLitComp[key].count)
        } else {
          countFinLitComp[key] = {
            count: 1,
            additionalAttribute: item.COUNT_OF_USERS_COMPLETED,
            name: item.first_name,
            compositeScore: parseInt(item.COUNT_OF_USERS_COMPLETED),
            teamName: item.team_or_department_name
          };
        }
      }
    }
    for (const item of finLiteracyQuizScoresUsers) {
      const key = item.customer_id;

      if (key !== null && (item.first_name != null && item.first_name != "" )) {
        if (countFinLitQuiz[key]) {
          countFinLitQuiz[key].count += 1;
          countFinLitQuiz[key].additionalAttribute += item.AVG_QUIZ_AVG;
          countFinLitQuiz[key].compositeScore = parseInt(countFinLitQuiz[key].additionalAttribute / countFinLitQuiz[key].count)
        } else {
          countFinLitQuiz[key] = {
            count: 1,
            additionalAttribute: item.AVG_QUIZ_AVG,
            name: item.first_name,
            compositeScore: parseInt(item.AVG_QUIZ_AVG),
            teamName: item.team_or_department_name
          };
        }
      }
    }

    for (const item of finPlanHealthUsers) {
      const key = item.customer_id;

      if (key !== null && (item.first_name != null && item.first_name != "" )) {
        if (countPlanHealth[key]) {
          countPlanHealth[key].count += 1;
          countPlanHealth[key].additionalAttribute += item.COUNT_OF_USERS_COMPLETED;
          countPlanHealth[key].compositeScore = parseInt(countPlanHealth[key].additionalAttribute / countPlanHealth[key].count)
        } else {
          countPlanHealth[key] = {
            count: 1,
            additionalAttribute: item.COUNT_OF_USERS_COMPLETED,
            name: item.first_name,
            compositeScore: parseInt(item.COUNT_OF_USERS_COMPLETED),
            teamName: item.team_or_department_name
          };
        }
      }
    }


    countFinLitComp = this.sortByCompositeScore(countFinLitComp, false);
    countFinLitQuiz = this.sortByCompositeScore(countFinLitQuiz, false);
    countPlanHealth = this.sortByCompositeScore(countPlanHealth, false);
    countWellNess = this.sortByCompositeScore(countWellNess, false);

    // console.log("mergedtopData before sum "+JSON.stringify(countFinLitComp));
    // OverAll Top Team
    let mergedtopData = this.mergeAndSumArrays(countFinLitComp, countFinLitQuiz, countPlanHealth, countWellNess);

    mergedtopData.sort((a, b) => b[1].compositeScore - a[1].compositeScore);
    // console.log("mergedtopData " + JSON.stringify(mergedtopData));

    let overAllIndividuals = [];

    mergedtopData.forEach(([key, value]) => {
      if (value.additionalAttribute !== null) {
        overAllIndividuals.push([
          {
            valueToDisplay: value.name,
            value: value.name,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.teamName,
            value: value.teamName,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.compositeScore,
            value: value.additionalAttribute,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          }
        ]);
      }
    });

    let valuesfinLiteracyCompletion = [];
    let valuesfinLiteracyQuizScores = [];
    let valuesfinPlanHealth = [];
    let valuesfinancialWellness = [];
    // console.log("countPlanHealth " + JSON.stringify(countWellNess));
    countWellNess.forEach(([key, value]) => {
      if (value.additionalAttribute !== null) {
        valuesfinancialWellness.push([
          {
            valueToDisplay: value.name,
            value: value.name,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.teamName,
            value: value.teamName,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.compositeScore,
            value: value.additionalAttribute,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          }
        ]);
      }
    });
    countFinLitComp.forEach(([key, value]) => {
      if (value.additionalAttribute !== null) {
        valuesfinLiteracyCompletion.push([
          {
            valueToDisplay: value.name,
            value: value.name,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.teamName,
            value: value.teamName,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.compositeScore,
            value: value.additionalAttribute,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          }
        ]);
      }
    });
    countFinLitQuiz.forEach(([key, value]) => {
      if (value.additionalAttribute !== null) {
        valuesfinLiteracyQuizScores.push([
          {
            valueToDisplay: value.name,
            value: value.name,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.teamName,
            value: value.teamName,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.compositeScore,
            value: value.additionalAttribute,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          }
        ]);
      }
    });

    countPlanHealth.forEach(([key, value]) => {
      if (value.additionalAttribute !== null) {
        valuesfinPlanHealth.push([
          {
            valueToDisplay: value.name,
            value: value.name,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.teamName,
            value: value.teamName,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value.compositeScore,
            value: value.additionalAttribute,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          }
        ]);
      }
    });






    this.setState({
      overAllIndividuals: overAllIndividuals,
      valuesfinLiteracyCompletionUsers: valuesfinLiteracyCompletion,
      valuesfinLiteracyQuizScoresUsers: valuesfinLiteracyQuizScores,
      valuesfinPlanHealthUsers: valuesfinPlanHealth,
      valuesfinancialWellnessUsers: valuesfinancialWellness
    })
  }

  mergeAndSumArrays(...arrays) {
    const mergedData = {};

    arrays.forEach((array) => {
      array.forEach(([key, data]) => {
        if (mergedData[key]) {
          // If the key already exists in the mergedData, add the count and additionalAttribute values
          mergedData[key].count += data.count;
          mergedData[key].additionalAttribute += data.additionalAttribute;
          mergedData[key].compositeScore += data.compositeScore;
        } else {
          // If the key doesn't exist in the mergedData, add it
          mergedData[key] = { ...data };
        }
      });
    });

    // Convert the mergedData object back to an array
    const mergedArray = Object.entries(mergedData);

    return mergedArray;
  }

  render() {
    return (this.state.isLoading ? <ProgressBar msg={"Loading LeaderBoard..."} /> : (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          style={styles.cardContainer}
          showsVerticalScrollIndicator={false}
        >
          <View
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              marginBottom: 20,
              marginLeft: 20,
            }}
          >
            <View style={{ flex: 2, marginTop: 5 }}>
              <FlatList
                data={["Teams", "Individuals"]}
                extraData={this.state.activeTab}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{
                  alignItems: "flex-start",
                  paddingHorizontal: 18,
                }}
                renderItem={({ item }) => {
                  let backgroundColor;
                  let color;
                  if (item == this.state.activeTab) {
                    backgroundColor = "#410DAA";
                    color = "#fff";
                  } else {
                    backgroundColor = "#fff";
                    color = "#121212";
                  }
                  return (
                    <this.SelectTabDetails
                      item={item}
                      backgroundColor={backgroundColor}
                      color={color}
                      onPress={() => {
                        this.setState({
                          activeTab: item,
                        });
                      }}
                    />
                  );
                }}
                keyExtractor={(item, index) => index}
              />
            </View>

          </View>

          <View>
            <View style={styles.compositeView}>
              <Text style={styles.header}>
                {this.state.activeTab === "Teams" ? "Top Teams - Overall" : "Top Individuals - Overall"}
              </Text>
              <View style={{ width: '50%', marginLeft: '3%' }}>
                <View style={styles.detailsContainer}>
                  {this.state.activeTab === "Teams" && (<TakenTable
                    header={this.state.headerforAll}
                    gridConfig={[0.5, 0.5, 1, 1, 1, 1]}
                    data={this.state.overAllTeam.slice(0,5)}
                    navigation={this.props.navigation}
                    pagination={false}
                  />)}
                  {this.state.activeTab === "Individuals" && (<TakenTable
                    header={this.state.headerforAllUsers}
                    gridConfig={[0.5, 0.5, 1, 1, 1, 1]}
                    data={this.state.overAllIndividuals.slice(0,5)}
                    navigation={this.props.navigation}
                    pagination={false}
                  />)}
                </View>
                <Text style={{
                  color: '#410DAA',
                  fontWeight: '500',
                  textDecorationLine: 'underline',
                  marginTop: 10,
                  width: '100%',
                  textAlign: 'right'
                }} onPress={() => { this.setState({ showModal: true, modalTabName: "Overall", valuesForModalToShow: this.state.activeTab === "Teams" ? this.state.overAllTeam :this.state. overAllIndividuals }) }}>Show full list</Text>
              </View>
            </View>
            <View style={styles.compositeView}>
              <Text style={styles.header}>
                {this.state.activeTab === "Teams" ? "Top Teams - By Area" : "Top Individuals - By Area"}
              </Text>
              <View style={styles.rowAlign}>
                <View style={{ width: '50%' }}>
                  <Text style={styles.labelText}>Financial Wellness</Text>
                  <View style={styles.detailsContainer}>
                    {this.state.activeTab === "Teams" && (<TakenTable
                      header={this.state.headerforAll}
                      gridConfig={[0.5, 0.5, 1, 1, 1, 1]}
                      data={this.state.valuesfinancialWellness.slice(0,5)}
                      navigation={this.props.navigation}
                      pagination={false}
                    />)}
                    {this.state.activeTab === "Individuals" && (<TakenTable
                      header={this.state.headerforAllUsers}
                      gridConfig={[0.5, 0.5, 1, 1, 1, 1]}
                      data={this.state.valuesfinancialWellnessUsers.slice(0,5)}
                      navigation={this.props.navigation}
                      pagination={false}
                    />)}
                  </View>
                  <Text style={{
                    color: '#410DAA',
                    fontWeight: '500',
                    textDecorationLine: 'underline',
                    marginTop: 10,
                    width: '100%',
                    textAlign: 'right'
                  }} onPress={() => { this.setState({ showModal: true, modalTabName: "Financial Wellness", valuesForModalToShow: this.state.activeTab === "Teams" ? this.state.valuesfinancialWellness : this.state.valuesfinancialWellnessUsers }) }}>Show full list</Text>
                </View>
                <View style={{ width: '50%' }}>
                  <Text style={styles.labelText}>Financial Planning</Text>
                  <View style={styles.detailsContainer}>
                    {this.state.activeTab === "Teams" && (<TakenTable
                      header={this.state.headerforAll}
                      gridConfig={[0.5, 0.5, 1, 1, 1, 1]}
                      data={this.state.valuesfinPlanHealth.slice(0,5)}
                      navigation={this.props.navigation}
                      pagination={false}
                    />)}
                    {this.state.activeTab === "Individuals" && (<TakenTable
                      header={this.state.headerforAllUsers}
                      gridConfig={[0.5, 0.5, 1, 1, 1, 1]}
                      data={this.state.valuesfinPlanHealthUsers.slice(0,5)}
                      navigation={this.props.navigation}
                      pagination={false}
                    />)}
                  </View>
                  <Text style={{
                    color: '#410DAA',
                    fontWeight: '500',
                    textDecorationLine: 'underline',
                    marginTop: 10,
                    width: '100%',
                    textAlign: 'right'
                  }} onPress={() => { this.setState({ showModal: true, modalTabName: "Financial Planning", valuesForModalToShow: this.state.activeTab === "Teams" ? this.state.valuesfinPlanHealth : this.state.valuesfinPlanHealthUsers }) }}>Show full list</Text>
                </View>
              </View>
              <View style={styles.rowAlign}>
                <View style={{ width: '50%' }}>
                  <Text style={styles.labelText}>Financial Learning Quiz</Text>
                  <View style={styles.detailsContainer}>
                    {this.state.activeTab === "Teams" && (<TakenTable
                      header={this.state.headerforAll}
                      gridConfig={[0.5, 0.5, 1, 1, 1, 1]}
                      data={this.state.valuesfinLiteracyQuizScores.slice(0,5)}
                      navigation={this.props.navigation}
                      pagination={false}
                    />)}
                    {this.state.activeTab === "Individuals" && (<TakenTable
                      header={this.state.headerforAllUsers}
                      gridConfig={[0.5, 0.5, 1, 1, 1, 1]}
                      data={this.state.valuesfinLiteracyQuizScoresUsers.slice(0,5)}
                      navigation={this.props.navigation}
                      pagination={false}
                    />)}
                  </View>
                  <Text style={{
                    color: '#410DAA',
                    fontWeight: '500',
                    textDecorationLine: 'underline',
                    marginTop: 10,
                    width: '100%',
                    textAlign: 'right'
                  }} onPress={() => { this.setState({ showModal: true, modalTabName: "Financial Learning Quiz", valuesForModalToShow: this.state.activeTab === "Teams" ? this.state.valuesfinLiteracyQuizScores : this.state.valuesfinLiteracyQuizScoresUsers }) }}>Show full list</Text>
                </View>
                <View style={{ width: '50%' }}>
                  <Text style={styles.labelText}>Financial Learning Completion</Text>
                  <View style={styles.detailsContainer}>
                    {this.state.activeTab === "Teams" && (<TakenTable
                      header={this.state.headerforAll}
                      gridConfig={[0.5, 0.5, 1, 1, 1, 1]}
                      data={this.state.valuesfinLiteracyCompletion.slice(0,5)}
                      navigation={this.props.navigation}
                      pagination={false}
                    />)}
                    {this.state.activeTab === "Individuals" && (<TakenTable
                      header={this.state.headerforAllUsers}
                      gridConfig={[0.5, 0.5, 1, 1, 1, 1]}
                      data={this.state.valuesfinLiteracyCompletionUsers.slice(0,5)}
                      navigation={this.props.navigation}
                      pagination={false}
                    />)}
                  </View>
                  <Text style={{
                    color: '#410DAA',
                    fontWeight: '500',
                    textDecorationLine: 'underline',
                    marginTop: 10,
                    width: '100%',
                    textAlign: 'right'
                  }} onPress={() => { this.setState({ showModal: true, modalTabName: "Financial Learning Completion", valuesForModalToShow: this.state.activeTab === "Teams" ? this.state.valuesfinLiteracyCompletion : this.state.valuesfinLiteracyCompletionUsers }) }}>Show full list</Text>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        {this.state.showModal && (
          <Modal
            style={{
              top: 0,
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              width: "100%"
            }}
            containerStyle={{
              marginHorizontal: isMobile ? 16 : 0,
              borderRadius: 5,
            }}
          >
            <View
              style={{
                paddingTop: isMobile ? 16 : 24,
                paddingBottom: isMobile ? 17 : 23,
                paddingHorizontal: isMobile ? 5 : 33,
                minWidth: isMobile ? 200 : 850,
                minHeight: isMobile ? 200 : 400,
                marginHorizontal: isMobile ? 16 : 0,
              }}
            >
              <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontWeight: "700", fontSize: 15, width: '95%' }}>
                  {this.state.modalTabName}
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showModal: false });
                  }}
                >
                  <AntDesign
                    name="close"
                    size={24}
                    color="rgba(0, 0, 0, 0.5)"
                  />
                </TouchableOpacity>
              </View>
              <View style={styles.detailsContainer}>
                <ScrollView>
                  <View style={{maxHeight: 300}}>
                  <TakenTable
                    header={ this.state.activeTab === "Teams" ? this.state.headerforAll : this.state.headerforAllUsers}
                    gridConfig={[0.5, 0.5, 1, 1, 1, 1]}
                    data={this.state.valuesForModalToShow}
                    navigation={this.props.navigation}
                    pagination={false}
                  />
                  </View>
                </ScrollView>
              </View>
            </View>
          </Modal>)}
      </SafeAreaView>
    ))
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  compositeView: {
    width: '94%',
    marginBottom: 60
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginLeft: "3%",
    marginTop: 20,
  },
  rowAlign: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '3%',
    marginTop: 20
  },
  header: {
    fontFamily: 'Charter',
    fontSize: 28,
    fontWeight: "700",
    lineHeight: 32,
    marginLeft: '3%'
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  headerItemContainerStyle: {
    justifyContent: "flex-start",
  },
  rowItem: {
    textAlign: "left",
  },
  rowItemContainerStyle: {
    justifyContent: "flex-start",
    paddingLeft: 10,
  },
  labelText: {
    fontFamily: 'Arial',
    fontSize: 20,
    lineHeight: 27,
    fontWeight: '500',
    marginLeft: '3%'
  }
})

export default LeaderBoard;