export default {
    fontsizes: {
      XXLARGE: 32,
      XLARGE: 28,
      LARGE: 24,
      MEDIUM: 20,
      SMALL: 16,
      XSMALL: 14,
      XXSMALL: 12,
  
      HEADING: 28,
      DESCRIPTION: 16,
      SUBHEADING: 20,
      BASIC: 16,
      H1: 28,
      H2: 24,
      H3: 20,
      H4: 16,
      H5: 14,
      H6: 12,
      BODY: 16,
      ERROR: 12,
      MAIN_CONTENT: 28
    },
    textColors: {
      BRAND_COLOR: "#410DAA",
      PRIMARY_FONT: "#121212",
      GREEN: "#4db6ac",
      RED: "#e57373",
      SECONDARY_FONT: "#fff",
      HEADING_COLOR: "#4A4A4F",
      BORDER_COLOR: "#ddd",
      HYPERLINK: "#5197ED",
      TEXT_MAIN: "#0D092E",
      GREY_TEXT: "#9F9F9F",
      BLACK_TEXT: "#121212",
      LIGHT_GRAY: "#707070"
    },
    colors: {
      BORDER_COLOR: "#E6E6E6",
      NEW_BRAND_COLOR: "#410DAA",
      NEW_HEADER_COLOR: "#121212",
      PURPLE: "#2F2E41",
      MAIN_BACKGROUND: "#fff",
      RED: "#ED5151",
      LIGHTRED: "#FFEBEE",
      UNDERLINE: "#f2f2f2",
  
      GREEN: "#66BB6A",
      DARKGREEN: "#1B5E20",
  
      WARNING: "#ffca28",
      BRAND_COLOR: "#00689f",
      // BRAND_COLOR: "#000",
      PRIMARY_BUTTON_COLOR: "#5197ED",
      // PRIMARY_BUTTON_COLOR: '#000',
      HEADER_COLOR: "#fff",
      // HEADER_COLOR:'#000',
      BRAND_ACCENT: "#6EBCEB",
      WHITE: "#fff",
      GREY: "#808080",
  
      DARK_BLUE: "#023550",
      LIGHT_BLUE: "#EAF8FF",
      TRANSPARENT: "transparent",
      ORANGE_COLOR: "#EF5D12",
      GREEN_COLOR: "#009105"
    },
    margins: {
      BASE: 16,
      MAIN_HORIZONTAL_MARGIN: 16,
      MARGIN: 16,
      MAIN_MARGIN_BOTTOM: 24,
      MAIN_MARGIN_TOP: 24
    },
    paddings: {
      BASE: 16
    },
    variables: {
      RUPEE_SYMBOL: "₹"
    },
    COLORAPP: {
        BLUE: "#1c52a0",
        OLDBLUE: "#00B9F5",
        YELLOWBUTTON: "#F4BF68",
        DARKBLUE: "#0F3274",
        LIGHTBLUE: "#6EA8DA",
        JAMAGREEN: "#33ac99",
        TORQUOISE: "#1C52A0",
        DARKGRAY: "#999",
        WHITE: "#FFE",
        YELLOW: "#fff59d",
        JAMAORANGE: "#f1c40f",
        APPBLUE: "#007aff",
        SUNFLOWER: "#f1c40f",
        EMARALD: "#2ecc71",
        MOONSHADOW: "#8e44ad",
        DEEPLILAC: "#9b59b6",
        AMYTHEIST: "#9b59b6",
        WESTERIA: "#8e44ad",
        EMARALD: "#2ecc71",
        TEAL: "#009688",
        SILVER: "#bdc3c7",
        DARKGREY: "#3c3c3c",
        ALIZARIN: "#e74c3c",
        WHITE: "#fff",
        BLACK: "#000",
        CLOUDS: "#eee",
        CONCRETE: "#95a5a6",
        ASBESTOS: "#7f8c8d",
        PETERRIVER: "#3498db",
        DASHBOARD_ICONS: "#5c5c5c",
        ANDROID_APP_BAR_COLOR: "#1c52a0",
        ANDROID_APP_BAR_TAB_COLOR: "#7190c1",
        PRIMARY_BUTTON_COLOR: "#1C52A0",
        ACCENT_BUTTON_COLOR: "#5197ED",
        GREY_BUTTON_COLOR: "#eeeeee",
        PETERRIVER: "#3498db",
        JAMAORANGEMAIN: "#F49611",
        JAMAORAGNETINT1: "#F4BF68",
        JAMAORAGNETINT2: "#F6D49E"
      },
  };
  