import { StackActions } from "@react-navigation/native";
import React from "react";
import { View, StyleSheet, Image, Platform } from "react-native";
import { Button } from "react-native-paper";
import { sm, md, lg, xl, twoxl } from "../../Responsive";

export default function MyHeader(props,{ showLogo }) {

    return (
        <View
            style={[
            {
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 16,
                paddingRight: 16,
                paddingVertical: 5,
                backgroundColor: "#fff",
                borderBottomColor: "#888",
                borderBottomWidth: 0.5
            }
            ]}
        >
            <View
            style={{
                alignSelf: "flex-start",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                flex: 1,
                marginLeft: 0,
                paddingLeft: 0
            }}
            >
            <Button
                transparent
                onPress={() => {
                if (showLogo) {
                    props.navigation.popToTop();
                } else {
                    if (Platform.OS === "web") {
                        const checkNavigationStack = props.navigation.canGoBack();
                        // console.log(checkNavigationStack);
                        if (checkNavigationStack) {
                            props.navigation.goBack();
                        }
                        else {
                            props.navigation.navigate("InvestmentOverview");
                        }
                    } else {
                        let from = null;
                        const params = props.route;
                        if (params) {
                            from = params.params.from || null;
                        }
                        
                        if (from) {
                            props.navigation.navigate(`${from}`, { ...props });
                        } else {
                            props.navigation.goBack();
                        }
                    }
                }
                }}
            >
                {showLogo ? (
                <Image
                    source={require("../../assets/upgradePlan/Landing/logo_header.png")}
                    style={styles.imgLogo}
                ></Image>
                ) : (
                <Image
                    source={require("../../assets/upgradePlan/Gold_Page/back_arrow.png")}
                    style={styles.imgClose}
                ></Image>
                )}
            </Button>
            </View>
            <View
            style={{
                alignSelf: "flex-end"
            }}
            >
            <Button
                transparent
                onPress={() => {
                    props.navigation.dispatch(
                        StackActions.replace("InvestmentOverview")
                    );
                }}
            >
                <Image
                source={require("../../assets/upgradePlan/Landing/close.png")}
                style={styles.imgClose}
                ></Image>
            </Button>
            </View>
        </View>
    );
}
  
const styles = StyleSheet.create({
    imgLogo: {
        width: md(window.width) ? 73 : 73,
        height: md(window.width) ? 25 : 25,
        resizeMode: "contain"
    },
    imgClose: {
        width: md(window.width) ? 14 : 14,
        height: md(window.width) ? 14 : 14,
        resizeMode: "contain"
    }
});
  


