import React, { useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, RefreshControl } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { getTrackOrders } from '../../../api/alerts';
import NavigationHeader from '../../../components/Common/NavigationHeader';
import { getUserDetails, isMobile } from '../../../utils';
import OrderCard from './orderCard';

const TrackOrders = (props) => {

    const [orders, setOrders] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [isRefreshing, setRefresh] = React.useState(false);

    useEffect(()=>{
        const { token } = getUserDetails();
        
        // let theWindow = global.open('http://127.0.0.1:8000/app/android_to_web_login', "_blank",);
        // let theDoc = theWindow.document;
        // console.log(theDoc);
        // let theScript = global.document.createElement('script');
        // function injectThis() {
        //     console.log("Heyy ");
        // }
        // theScript.innerHTML = 'window.onload = function(){ console.log("hello");alert("hello");};';
        // theDoc.body.appendChild(theScript);
        loadTrackOrders();
    },[]);

    const loadTrackOrders = async () => {
        try {
            setLoading(true);
            const response = await getTrackOrders();
            // console.log(response);
            if(response && response.code==100) {
                const { results = [] } = response;
                setOrders(results);
                setLoading(false);
            }else{
                setLoading(false);
            }
        }catch(err){
            console.log(err);
            setLoading(false)
        }
    }

    const handleRefresh = () => {
        setRefresh(true);
    }

    const _renderOrderItem = ({ item, index}) => {
        // console.log(item);
        return (
            <OrderCard
              item={item}
              navigation={props.navigation}
            />
        );
    }

    const handlePressBack = () => {
        props.navigation.navigate("InvestmentOverview");
    }


    return(
        <View style={styles.container}>
            <NavigationHeader
                navigation={props.navigation}
                navigationHeader={["Orders"]}
                onPressBack={handlePressBack}
            />
            <View style={styles.mainContainer}>
            {
                loading ? (
                    <View style={styles.loadingContainer}>
                        <ActivityIndicator size={"large"} color={"#410DAA"}/>
                    </View>
                ): (
                    <FlatList
                        data={orders}
                        renderItem={_renderOrderItem}
                        keyExtractor={(item, index) => index.toString()}
                        // ItemSeparatorComponent={this._renderSeparator}
                        contentContainerStyle={{
                            paddingBottom: 50
                        }}
                        refreshControl={
                            <RefreshControl
                                refreshing={isRefreshing}
                                onRefresh={handleRefresh}
                                colors={["#410DAA"]}
                            />
                        }
                        initialNumToRender={6}
                        maxToRenderPerBatch={10}
                        windowSize={10}
                    />
                )
            }
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff",
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    mainContainer: {
        paddingLeft: isMobile ? 16 : 64,
        paddingRight: isMobile ? 16 : 64,
        paddingTop: 20
    }
});


export default TrackOrders;