import React from "react";
import {
  View,
  Text,
  Dimensions,
  ScrollView,
  Image,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from "react-native";
import { md } from "../../Responsive";
import NavigationHeader from "../Common/NavigationHeader";
import { getUserDetails, isMobile, reverseString } from "../../utils";
import { CustomModal } from "../Common/CustomModal";
import { ShowToast } from "../Common/Toast";
import {
  createPlanforPlatformSubscription,
  getHashKeyValue,
  moveDataFromDirectToRegular,
} from "../../api";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../api/apiConfig";
import { MAIN_URL, URLS } from "../../api/urls";
import Store from "../../store";
import { LOGOUT } from "../../actions/types";
import { getInvoicesPaymentLink } from "../../api/billing";
const { width } = Dimensions.get("window");
const isWeb = md(width);

export default class SubscriptionPlanHome extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    // console.log(params);
    let show_club = true;
    let show_preferredcircle = true;
    let show_elite = true;
    let cip_pk_for_renewal = null;
    let showToast = false;
    let toastMessage = null;
    if (params) {
      show_club = params.show_club || null;
      show_preferredcircle = params.show_preferredcircle || true;
      show_elite = params.show_elite || true;
      cip_pk_for_renewal = params.cip_pk_for_renewal || null;
      showToast = params.showToast || false;
      toastMessage = params.toastMessage || null;
    }
    let userData = getUserDetails();
    // console.log(show_club);
    let is_elite = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (
      hostname.includes("app") ||
      hostname.includes("dapp") ||
      hostname.includes("localhost")
    ) {
      is_elite = true;
    }
    this.state = {
      Plan6MBg: "white",
      Plan6MColor: "#410DAA",
      Plan1YBg: "white",
      Plan1YColor: "#410DAA",
      Plan3YBg: "white",
      Plan3YColor: "#410DAA",
      show_club,
      show_preferredcircle,
      show_elite,
      cip_pk_for_renewal,
      showModal: false,
      isLoading: false,
      migrating_data: false,
      userData,
      is_elite,
      showToast: showToast,
      toastMessage: toastMessage,
    };
  }

  async componentDidMount() {
    try {
      // console.log(this.state);
    } catch (err) {
      console.log(err);
    }
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  handleContinueToLogout = async () => {
    try {
      localStorage.removeItem("isShowAppDownloadCard");
      localStorage.removeItem("showPlanRenewalCard");
      localStorage.removeItem("redirectAfterOnboardingSuccess");
      Store.dispatch({ type: LOGOUT });
    } catch (error) {
      Store.dispatch({ type: LOGOUT });
    }
  };

  handleClubMigration = async () => {
    try {
      this.setState({
        isLoading: true,
        migrating_data: true,
      });
      let { userData } = this.state;
      const payload = {
        cust_pk: userData.customer_id,
      };
      // console.log(payload);
      const response = await moveDataFromDirectToRegular(payload);
      if (response && response.code == 100) {
        this.setState({
          showToast: true,
          toastMessage: response.result,
          migrating_data: false,
        });
        let url = "https://dclub.jamawealth.com/login";
        if (response.token) {
          let reverseToken = reverseString(response.token);
          url = url + "?unique=" + reverseToken;
        }
        setTimeout(() => {
          this.handleContinueToLogout();
          global.open(url, "_blank");
        }, 5000);
      } else {
        // console.log(response.msg);
        this.setState({
          isLoading: false,
          error: true,
          migrating_data: false,
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        error: true,
        migrating_data: false,
      });
    }
  };

  renderModalContent = () => {
    return (
      <View
        style={{
          padding: 15,
          flex: 1,
          alignItems: "center",
        }}
      >
        <Text>
          You selected new plan as 'Investor Club'. Your previous plan is
          'Advisory Circle'. As per regulations, you can only invest in regular
          plan mutual funds and cannot avail advice on stocks.
        </Text>
        <View style={{ padding: 10 }}></View>
        <Text>Are you sure you wish to continue?</Text>
        <View style={{ padding: 10 }}></View>
        <TouchableOpacity
          style={{
            backgroundColor: "#410DAA",
            borderWidth: 1,
            borderRadius: 4,
            borderColor: "#410DAA",
            // padding: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
          }}
          onPress={() => {
            this.handleClubMigration();
          }}
        >
          <Text
            style={{
              textAlign: "center",
              color: "#ffffff",
            }}
          >
            Continue
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  handlePlatformSubscription = async () => {
    try {
      this.setState({ isLoading: true });
      let payload = {};
      if (this.state.cip_pk_for_renewal) {
        payload = {
          cip_pk_for_renewal: this.state.cip_pk_for_renewal,
        };
      } else {
        payload = {};
      }
      const resp = await createPlanforPlatformSubscription(payload);
      if (resp && resp.code == 100) {
        const payload = {
          invoices: resp.cust_invoice_id.toString(),
          action: "advisory_plan_upgrade", //switch_to_preferred_circle
          amount: resp.amount,
          server_name:
            Platform.OS == "web"
              ? global.location.href.split("/")[2]
              : "mobile",
        };
        const response = await getInvoicesPaymentLink(payload);
        if (response && response.code && response.code == 100) {
          const { results = {} } = response;
          const { payment_link } = results;
          if (Platform.OS == "web") {
            global.open(payment_link, "_self");
          } else {
            this.props.navigation.navigate("App", {
              screen: "Dashboard",
              params: {
                screen: "Portfolio",
                params: {
                  screen: "ShowWebView",
                  params: {
                    url: payment_link,
                    successURL: "account/upgrade_advisory_plan/",
                    onSuccess: function (navigation, _results = {}) {
                      navigation.push("App", {
                        screen: "Account",
                        params: {
                          screen: "UpgradeAdvisoryPlanRedirection",
                          params: {},
                        },
                      });
                    },
                    onError: function (_results = {}) {},
                  },
                },
              },
            });
          }
        }
      } else {
        this.setState({
          isLoading: false,
          showToast: true,
          toastMsg: crm_sip_resp.msg,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    let { isLoading, showToast, toastMessage, error, migrating_data } =
      this.state;

    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        {showToast ? (
          <ShowToast
            showToast={true}
            type="status"
            title={toastMessage}
            duration="10"
          />
        ) : null}
        {isLoading || error ? (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
            {migrating_data && (
              <Text>
                Your data is being migrated. Kindly do not leave or close this
                page until it is finished.
              </Text>
            )}
            {error && <Text>Error Loading</Text>}
          </View>
        ) : (
          <View
            style={{
              flex: 1,
              backgroundColor: "#ffffff",
              maxWidth: 1500,
            }}
          >
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={["Subscription Plan"]}
              onPressBack={() => {
                this.props.navigation.goBack();
              }}
              style={{ backgroundColor: "#F6F6F6" }}
            />
            <ScrollView
              style={{
                backgroundColor: "#FFFFFF",
                paddingLeft: isWeb ? 32 : 0,
                paddingRight: isWeb ? 32 : 0,
                paddingTop: isWeb ? 30 : 0,
                maxWidth: 1400,
                marginBottom: isMobile ? 0 : 20,
              }}
            >
              <View style={styles.body}>
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  {this.state.userData.show_corporate_benifit_line ? (
                    <View
                      style={{
                        paddingRight: 7,
                        paddingBottom: 20,
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: 12,
                          fontStyle: "italic",
                          color: "#494B69",
                        }}
                      >
                        Please visit your corporate portal to avail any
                        corporate benefits.
                      </Text>
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: 12,
                          fontStyle: "italic",
                          color: "#494B69",
                        }}
                      >
                        Fees paid towards first six months of any plan shall not
                        be refunded as per our policy.
                      </Text>
                    </View>
                  ) : null}
                  <View
                    style={{
                      width: isMobile ? "90%" : "100%",
                      marginLeft: isMobile ? "5%" : "0%",
                      marginRight: isMobile ? "5%" : "0%",
                      flexDirection: isMobile ? "column" : "row",
                      justifyContent:
                        this.state.show_club || this.state.show_club == "true"
                          ? "space-around"
                          : "flex-start",
                      alignItems:
                        this.state.show_club || this.state.show_club == "true"
                          ? "center"
                          : "flex-start",
                      marginBottom: 60,
                      paddingTop: isMobile ? 20 : 0,
                    }}
                  >
                    {this.state.show_club || this.state.show_club == "true" ? (
                      <View
                        style={{
                          width: isMobile ? "90%" : "30%",
                          marginLeft: "0.5%",
                          borderRadius: 10,
                          borderColor: "#cdcdcb",
                          borderWidth: 1,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 24,
                            color: "#51514E",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "12%",
                          }}
                        >
                          Investor Club
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#51514E",
                            fontWeight: "600",
                            marginLeft: "10%",
                            marginTop: "2%",
                          }}
                        >
                          Zero fee plan covering Funds & Goals.
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#51514E",
                            fontWeight: "600",
                            marginLeft: "10%",
                            marginTop: "2%",
                          }}
                        >
                          No stocks.
                        </Text>
                        <Text
                          style={{
                            fontSize: 20,
                            color: "#027BFC",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                            marginBottom: "5%",
                          }}
                        >
                          {" "}
                        </Text>
                        <View
                          style={{
                            width: "82%",
                            marginLeft: "9%",
                            marginRight: "9%",
                            borderRadius: 10,
                            borderColor: "white",
                            borderWidth: 1,
                            backgroundColor: "#f9f5ff",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#464E5F",
                              fontWeight: "700",
                              marginLeft: "10%",
                              marginTop: "7%",
                            }}
                          >
                            Services Included
                          </Text>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "7%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Invest in any Regular Mutual Fund
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Advice on funds to buy or sell
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Clear guidance on Financial Planning
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Goal based investing
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                              marginBottom: 25,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              {" "}
                            </Text>
                          </View>
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              // onPress={() => {
                              //   this.setState({
                              //     showEliteLearnMore: true,
                              //   });
                              // }}
                              style={{
                                fontSize: 14,
                                color: "#410daa",
                                cursor: "pointer",
                                fontWeight: "700",
                                marginLeft: "10%",
                                marginBottom: "5%",
                                fontStyle: "italic",
                              }}
                            >
                              {/* Learn More{" "} */}{" "}
                            </Text>
                            {/* <Image
                                source={{
                                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                                }}
                                style={[
                                  {
                                    width: 10,
                                    height: 10,
                                    marginTop: 4,
                                  },
                                ]}
                              ></Image> */}
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: "6%",
                          }}
                        >
                          <Text
                            onPress={() => {
                              this.setState({
                                showEliteBetter: true,
                              });
                            }}
                            style={{
                              fontSize: 12,
                              color: "#410daa",
                              fontWeight: "700",
                              marginLeft: "10%",
                              marginBottom: "2%",
                              fontStyle: "italic",
                            }}
                          >
                            {" "}
                          </Text>
                        </View>
                        <Text
                          onPress={() => {
                            this.toggleModal();
                          }}
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "bold",
                            fontSize: 15,
                            color: "#410DAA",
                            backgroundColor: "white",
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            borderRadius: 4,
                            paddingVertical: 10,
                            textAlign: "center",
                            width: "82%",
                            marginLeft: "9%",
                            marginRight: "9%",
                            marginTop: "5%",
                            marginBottom: "10%",
                          }}
                        >
                          Subscribe
                        </Text>
                      </View>
                    ) : null}
                    <View
                      style={{
                        padding: isMobile ? "3%" : "1%",
                      }}
                    ></View>
                    <View
                      style={{
                        width: isMobile ? "90%" : "30%",
                        borderRadius: 10,
                        borderColor: "#cdcdcb",
                        borderWidth: 1,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 24,
                          color: "#51514E",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "12%",
                        }}
                      >
                        Advisory Circle
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#51514E",
                          fontWeight: "600",
                          marginLeft: "10%",
                          marginTop: "2%",
                        }}
                      >
                        Advisory Covering Stocks, Funds & Goals
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#51514E",
                          fontWeight: "600",
                          marginLeft: "10%",
                          marginTop: "13%",
                        }}
                      >
                        {" "}
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#51514E",
                          marginLeft: "10%",
                          marginTop: "7%",
                          marginBottom: "5%",
                        }}
                      >
                        Starting from ₹400/month based on plan tenure
                      </Text>
                      <View
                        style={{
                          width: "82%",
                          marginLeft: "9%",
                          marginRight: "9%",
                          borderRadius: 10,
                          borderColor: "white",
                          borderWidth: 1,
                          backgroundColor: "#f9f5ff",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 18,
                            color: "#464E5F",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                          }}
                        >
                          Advices Included
                        </Text>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "7%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Stock of the month (12)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Mutual Fund Pack (4)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Financial Plan Review (2)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Portfolio Review (2)
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: "10%",
                            marginTop: "3%",
                            marginBottom: 17,
                          }}
                        >
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                            }}
                            style={{
                              width: 11,
                              height: 11,
                              resizeMode: "contain",
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#464E5F",
                              fontWeight: "400",
                            }}
                          >
                            Adhoc Advices (4)
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row" }}>
                          <Text
                            onPress={() => {
                              this.setState({
                                showAdvisoryLearnMore: true,
                              });
                            }}
                            style={{
                              fontSize: 14,
                              color: "#410daa",
                              cursor: "pointer",
                              fontWeight: "700",
                              marginLeft: "10%",
                              marginBottom: "5%",
                              fontStyle: "italic",
                            }}
                          >
                            {/* Learn More{" "} */}{" "}
                          </Text>
                          {/* <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                              }}
                              style={[
                                {
                                  width: 10,
                                  height: 10,
                                  marginTop: 4,
                                },
                              ]}
                            ></Image> */}
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: "6%",
                        }}
                      >
                        <Text
                          onPress={() => {
                            this.setState({
                              showAdvisoryBetter: true,
                            });
                          }}
                          style={{
                            fontSize: 12,
                            color: "#410daa",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginBottom: "2%",
                            fontStyle: "italic",
                          }}
                        >
                          {" "}
                          {/* Why Advisory Circle may be better for
                            you{" "} */}
                        </Text>
                        {/* <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                            }}
                            style={[
                              {
                                width: 10,
                                height: 10,
                                marginTop: 2,
                              },
                            ]}
                          ></Image> */}
                      </View>
                      <Text
                        onPress={() => {
                          // this.setState({
                          //   showAdvisoryPlan: true,
                          // });
                          this.props.navigation.navigate("App", {
                            screen: "Account",
                            params: {
                              screen: "AdvisoryPlan",
                              params: {
                                cip_pk_for_renewal:
                                  this.state.cip_pk_for_renewal,
                              },
                            },
                          });
                        }}
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "bold",
                          fontSize: 15,
                          color: "white",
                          backgroundColor: "#410DAA",
                          borderColor: "#410DAA",
                          borderWidth: 1,
                          borderRadius: 4,
                          paddingVertical: 10,
                          textAlign: "center",
                          width: "82%",
                          marginLeft: "9%",
                          marginRight: "9%",
                          marginTop: "5%",
                          marginBottom: "10%",
                        }}
                      >
                        Subscribe
                      </Text>
                    </View>
                    <View
                      style={{
                        padding: isMobile ? "3%" : "1%",
                      }}
                    ></View>
                    {this.state.is_elite ? (
                      <View
                        style={{
                          width: isMobile ? "90%" : "30%",
                          borderRadius: 10,
                          borderColor: "#cdcdcb",
                          borderWidth: 1,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 24,
                            color: "#51514E",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "12%",
                          }}
                        >
                          Mutual Fund Platform
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#51514E",
                            fontWeight: "600",
                            marginLeft: "10%",
                            marginTop: "2%",
                          }}
                        >
                          {" "}
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#51514E",
                            fontWeight: "600",
                            marginLeft: "10%",
                            marginTop: "13%",
                          }}
                        >
                          {" "}
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#51514E",
                            marginLeft: "10%",
                            marginTop: "7%",
                            marginBottom: "5%",
                          }}
                        >
                          ₹499/year
                        </Text>
                        <View
                          style={{
                            width: "82%",
                            marginLeft: "9%",
                            marginRight: "9%",
                            borderRadius: 10,
                            borderColor: "white",
                            borderWidth: 1,
                            backgroundColor: "#f9f5ff",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#464E5F",
                              fontWeight: "700",
                              marginLeft: "10%",
                              marginTop: "7%",
                            }}
                          >
                            Services Included
                          </Text>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Invest or SIP in any Direct Mutual Fund
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Redeem from any folios
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Track investment portfolios
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Plan STP (systematic transfers)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Plan SWP (systematic withdrawals)
                            </Text>
                          </View>
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              onPress={() => {
                                this.setState({
                                  showAdvisoryLearnMore: true,
                                });
                              }}
                              style={{
                                fontSize: 14,
                                color: "#410daa",
                                cursor: "pointer",
                                fontWeight: "700",
                                marginLeft: "10%",
                                marginBottom: "5%",
                                fontStyle: "italic",
                              }}
                            >
                              {" "}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: "6%",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              color: "#410daa",
                              fontWeight: "700",
                              marginLeft: "10%",
                              marginBottom: "2%",
                              fontStyle: "italic",
                            }}
                          >
                            {" "}
                          </Text>
                        </View>
                        <Text
                          onPress={() => {
                            this.handlePlatformSubscription();
                          }}
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "bold",
                            fontSize: 15,
                            color: "white",
                            backgroundColor: "#410DAA",
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            borderRadius: 4,
                            paddingVertical: 10,
                            textAlign: "center",
                            width: "82%",
                            marginLeft: "9%",
                            marginRight: "9%",
                            marginTop: "5%",
                            marginBottom: "10%",
                          }}
                        >
                          Subscribe
                        </Text>
                      </View>
                    ) : null}
                    {/* <View
                        style={{
                          width: isMobile ? "90%" : "30%",
                          marginLeft: "0.5%",
                          borderRadius: 10,
                          borderColor: "#cdcdcb",
                          borderWidth: 1,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 24,
                            color: "#51514E",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "12%",
                          }}
                        >
                          Elite Circle
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#51514E",
                            fontWeight: "600",
                            marginLeft: "10%",
                            marginTop: "2%",
                          }}
                        >
                          Managed Portfolio Advisory in Stocks,
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#51514E",
                            fontWeight: "600",
                            marginLeft: "10%",
                            marginTop: "2%",
                          }}
                        >
                          Funds To Build Wealth
                        </Text>
                        <Text
                          style={{
                            fontSize: 20,
                            color: "#027BFC",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                            marginBottom: "5%",
                          }}
                        >
                          UNLIMITED ADVICES
                        </Text>
                        <View
                          style={{
                            width: "82%",
                            marginLeft: "9%",
                            marginRight: "9%",
                            borderRadius: 10,
                            borderColor: "white",
                            borderWidth: 1,
                            backgroundColor: "#f9f5ff",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#464E5F",
                              fontWeight: "700",
                              marginLeft: "10%",
                              marginTop: "7%",
                            }}
                          >
                            Advices Included
                          </Text>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "7%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Stock of the month (36)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Mutual Fund Pack (12)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Financial Plan Review (6)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Portfolio Review (6)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                              marginBottom: 17,
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Adhoc Advices (12)
                            </Text>
                          </View>
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              onPress={() => {
                                this.setState({
                                  showEliteLearnMore: true,
                                });
                              }}
                              style={{
                                fontSize: 14,
                                color: "#410daa",
                                cursor: "pointer",
                                fontWeight: "700",
                                marginLeft: "10%",
                                marginBottom: "5%",
                                fontStyle: "italic",
                              }}
                            >
                              Learn More{" "}
                            </Text>
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                              }}
                              style={[
                                {
                                  width: 10,
                                  height: 10,
                                  marginTop: 4,
                                },
                              ]}
                            ></Image>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: "6%",
                          }}
                        >
                          <Text
                            onPress={() => {
                              this.setState({
                                showEliteBetter: true,
                              });
                            }}
                            style={{
                              fontSize: 12,
                              color: "#410daa",
                              fontWeight: "700",
                              marginLeft: "10%",
                              marginBottom: "2%",
                              fontStyle: "italic",
                            }}
                          >
                            Why Elite Circle may be best for you{" "}
                          </Text>
                          <Image
                            source={{
                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/newarrow.png",
                            }}
                            style={[
                              {
                                width: 10,
                                height: 10,
                                marginTop: 2,
                              },
                            ]}
                          ></Image>
                        </View>
                        <Text
                          onPress={() => {
                            global.open("https://maxiomwealth.com/contact-us/", "_blank")
                          }}
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "bold",
                            fontSize: 15,
                            color: "#410DAA",
                            backgroundColor: "white",
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            borderRadius: 4,
                            paddingVertical: 10,
                            textAlign: "center",
                            width: "82%",
                            marginLeft: "9%",
                            marginRight: "9%",
                            marginTop: "5%",
                            marginBottom: "10%",
                          }}
                        >
                          Contact Advisor
                        </Text>
                      </View> */}
                  </View>
                </View>
              </View>
            </ScrollView>
            <CustomModal
              visible={this.state.showModal}
              showCloseButton={true}
              closeModal={() => {
                this.toggleModal();
              }}
            >
              {this.renderModalContent()}
            </CustomModal>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: isMobile ? 0 : 40,
    flexDirection: isMobile ? "column" : "row",
    paddingHorizontal: isMobile ? 0 : 0,
    marginRight: isMobile ? 0 : 0,
  },
  productContainer: {
    width: isMobile ? "100%" : "32.2%",
    borderColor: isMobile ? "transparent" : "#EEE5FF40",
    borderRadius: isMobile ? 0 : 18,
    paddingTop: 15,
    paddingBottom: isMobile ? 0 : 12,
    borderWidth: 1,
    marginBottom: isMobile ? 15 : 0,
  },
  volatalityContainer: {
    backgroundColor: "#FFF0E8",
    marginLeft: isMobile ? 9 : 15,
  },
  volatality: {
    fontSize: isMobile ? 9 : 10,
    color: "#EF5D12",
    fontStyle: "italic",
    paddingVertical: isMobile ? 5 : 6,
    paddingHorizontal: isMobile ? 14 : 18,
    fontWeight: "bold",
  },
  topContainer: {
    paddingHorizontal: 15,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: isMobile ? 15 : 16,
    color: "#464E5F",
    fontWeight: "bold",
    fontFamily: "Roboto",
    paddingTop: 3,
  },
  image: {
    width: 25,
    height: 24,
    resizeMode: "contain",
    marginRight: 6,
  },
  strategy: {
    fontSize: 13,
    color: "#888C9F",
    fontStyle: "italic",
    marginTop: 13,
  },
  infoContainer: {
    flex: 1,
    marginTop: isMobile ? 20 : 30,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 12,
    backgroundColor: "#F6F6F660",
    paddingHorizontal: 15,
  },
  infoFieldsContainer: {
    marginTop: 0,
    paddingTop: 4,
  },
  infoTitle: {
    fontSize: 12,
    color: "#6C7293",
    fontWeight: "600",
    marginBottom: 8,
  },
  infoField: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  infoValue: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  button: {
    alignSelf: "center",
  },
  buttonText: {
    fontSize: isMobile ? 13 : 15,
    color: "#410DAA",
    fontWeight: "bold",
    lineHeight: 23,
    textDecorationLine: isMobile ? "underline" : "none",
  },
  featureContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingHorizontal: 18,
    marginBottom: 12,
  },
  feature: {
    color: "#464E5F",
    fontFamily: "Roboto",
    fontSize: 13,
  },
  featureBullet: {
    color: "#410DAA",
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "bold",
    paddingRight: 5,
  },
  details: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  descriptionContainer: {
    marginTop: 12,
  },
});
