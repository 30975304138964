import React from "react";
import {
  View,
  ScrollView,
  Image,
  FlatList,
  RefreshControl,
  Platform,
  TouchableOpacity,
  SafeAreaView,
  StyleSheet,
} from "react-native";
import EvilIcon from "@expo/vector-icons/EvilIcons";
import { connect } from "react-redux";
import { SideHeading } from "../../../components/Common/sideHeading";
import { CardStripNavigation } from "../../../components/Common/CardStripNavigation";
import JamaAcademy from "./jamaAcademy";
import { getHomeScreenData } from "../../../api/home";
import WealthProfile from "./wealthProfile";
import amplitude from "../../../utils/amplitude";
import AssessWellnessStrip from "./assessWellness";
import { Text, ActivityIndicator } from "react-native-paper";
import BookAdvisorStrip from "./bookAdvisorStrip";
import PortfolioSummaryCard from "./portfolioSummaryCard";
import { getUserDetails, isMobile, mapRouteToScreen } from "../../../utils";
import { StackActions } from "@react-navigation/routers";
import Store from "../../../store";

const SIDE_HEADING = "SIDE_HEADING";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderAssessWellness = ({ description, items }) => {
    return (
      <AssessWellnessStrip
        description={description}
        items={items}
        onPress={() => {
          this.props.navigation.navigate("App", {
            screen: "ViewMode",
            params: {
              screen: "FinAwareness",
              params: {},
            },
          });
        }}
        style={{}}
      />
    );
  };

  renderJamaAcademy = ({ description, clickables }) => {
    return (
      <JamaAcademy
        style={{}}
        description={description}
        clickables={clickables}
        navigate={this.props.navigation.navigate}
      />
    );
  };

  renderEmptyView = () => {
    return (
      <View
        style={{ backgroundColor: "#F6F6F6", width: "100%", height: 20 }}
      ></View>
    );
  };

  renderBookAdvisor = ({ title, description, button_text, navigate_to }) => {
    return (
      <BookAdvisorStrip
        title={title}
        description={description}
        button_text={button_text}
        style={{ paddingVertical: 15 }}
        onPress={() => {
          this.props.navigation.navigate(navigate_to);
        }}
      />
    );
  };

  renderInvestForGoal = ({ decription, tip, items }) => {
    return (
      <>
        <Text
          style={{
            fontSize: 12,
            fontWeight: "300",
            color: "#707070",
            paddingHorizontal: 16,
            width: "80%",
          }}
        >
          {decription}
        </Text>

        <Text
          style={{
            color: "#707070",
            fontSize: 12,
            fontWeight: "300",
            paddingHorizontal: 16,
            width: "80%",
          }}
        >
          {tip.toLowerCase().includes("bonus") ? (
            <>
              <Text
                style={{
                  color: "#410daa",
                  fontSize: 12,
                  fontWeight: "300",
                  paddingHorizontal: 16,
                  width: "80%",
                }}
              >
                Bonus
              </Text>
              {tip.split("Bonus")}
            </>
          ) : (
            tip
          )}
        </Text>
        <FlatList
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          data={items}
          renderItem={this.renderGoalItem}
          ItemSeparatorComponent={this.itemSeparator}
          contentContainerStyle={{
            width: "100%",
            alignItems: "flex-start",
            padding: 16,
          }}
        />
      </>
    );
  };

  renderInvestToBuildWealth = (data) => {
    return (
      <>
        <FlatList
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          data={data}
          renderItem={this.renderInvestItem}
          ItemSeparatorComponent={this.itemSeparator}
          contentContainerStyle={{
            alignItems: "flex-start",
            padding: 16,
          }}
        />
      </>
    );
  };

  renderInvestItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        key={index}
        style={{
          width: isMobile ? 100 : 150,
          height: isMobile ? 100 : 150,
          padding: 10,
          borderWidth: 0.5,
          borderRadius: 8,
          borderColor: "#410DAA33",
          justifyContent: "flex-start",
          paddingTop: 10,
        }}
        onPress={() => {
          const { stack, screen } = mapRouteToScreen(item.navigate_to);
          this.props.navigation.push("App", {
            screen: stack,
            params: {
              screen: screen,
              params: {
                index: 1,
                filter: item.navProps.fund_type,
                type: item.navProps.fund_sub_type,
              },
            },
          });
        }}
      >
        {item.label.length > 0 && (
          <View
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              backgroundColor: "#D9CEEE",
              paddingVertical: 3,
              paddingHorizontal: 7,
              borderTopRightRadius: 8,
              borderBottomLeftRadius: 8,
            }}
          >
            <Text style={{ fontSize: 10, color: "#410DAA" }}>{item.label}</Text>
          </View>
        )}

        <Image
          source={{ uri: item.icon }}
          style={{
            width: 32,
            height: 32,
            borderRadius: 4,
            marginHorizontal: 5,
          }}
          resizeMode={"contain"}
        />

        <Text
          style={{
            fontSize: 13,
            fontWeight: "300",
            color: "#121212",
            paddingTop: 10,
            height: 46,
          }}
          numberOfLines={2}
        >
          {item.title}
        </Text>
        <Text
          style={{
            fontSize: 10,
            fontWeight: "300",
            color: "#410DAA",
            fontStyle: "italic",
            paddingTop: 4,
          }}
        >
          {item.description}
        </Text>
        <Text
          style={{
            fontSize: 10,
            fontWeight: "300",
            color: "#707070",
            paddingTop: 2,
            fontStyle: "italic",
          }}
        >
          {item.disclaimer}
        </Text>
      </TouchableOpacity>
    );
  };

  renderGoalItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        key={index}
        style={{
          width: isMobile ? 100 : 150,
          height: isMobile ? 100 : 150,
          paddingHorizontal: 13,
          borderWidth: 0.5,
          borderRadius: 8,
          borderColor: "#410DAA33",
          alignItems: "center",
          justifyContent: "center",
          paddingVertical: 10,
        }}
        onPress={() => {
          let { label } = item;
          label = label.replace("ss+", "");
          label = label.toUpperCase();

          this.props.navigation.push("App", {
            screen: "Goals",
            params: {
              screen: "CreateFinplan",
              params: {
                type: item.navProps.goal_type,
                from: "Home",
                stack: "Dashboard",
              },
            },
          });
        }}
      >
        <Image
          source={{ uri: item.icon }}
          style={{
            width: 30,
            height: 30,
            alignSelf: "center",
            borderRadius: 4,
            marginHorizontal: 5,
          }}
          resizeMode={"contain"}
        />
        <Text
          style={{
            fontSize: 12,
            fontWeight: "300",
            color: "#121212",
            textAlign: "center",
            paddingTop: 9,
            flexWrap: "wrap",
            paddingHorizontal: index == 0 ? 8 : 0,
          }}
        >
          {item.label}
        </Text>
      </TouchableOpacity>
    );
  };

  renderPortfolioItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        key={index}
        style={{
          width: item.icon ? 210 : 190,
          height: 112,
          padding: 10,
          borderWidth: 0.5,
          borderRadius: 8,
          borderColor: "#410DAA33",
          flexDirection: "row",
        }}
        onPress={() => {
          let { title } = item;
          title = title.replace("ss+", "");
          title = title.toUpperCase();

          this.props.navigation.navigate(item.navigate_to, { from: "Home" });
        }}
      >
        {item.icon && (
          <Image
            source={{
              uri:
                item.icon ||
                "https://jamaappprod.s3.ap-south-1.amazonaws.com/media/home/Jamawealth.png",
            }}
            style={{
              width: 28,
              height: 28,
              resizeMode: "contain",
              borderRadius: 4,
              marginRight: 10,
              marginLeft: 2,
            }}
          />
        )}
        <View style={item.icon ? { flex: 1 } : {}}>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
              marginBottom: 10,
            }}
          >
            {item.title}
          </Text>
          <Text
            style={{
              fontSize: 11,
              fontWeight: "300",
              color: "#707070",
            }}
          >
            {item.description || item.decription}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  itemSeparator = () => {
    return <View style={{ width: 10, height: 10 }}></View>;
  };

  renderPortfolios = ({ tip, description, items }) => {
    const data = [
      {
        heading: "Import Stock Portfolio",
        description: "Get started with honest investing.",
      },
      {
        heading: "Import Mutual Funds Portfolio",
        description: "Get started with honest investing.",
      },
      {
        heading: "Add Assets",
        description: "Add Assets to enrich your portfolio.",
      },
    ];
    return (
      <>
        <Text
          style={{
            fontSize: 12,
            fontWeight: "300",
            color: "#707070",
            paddingHorizontal: 16,
          }}
        >
          {description}
        </Text>
        <Text
          style={{
            fontSize: 12,
            fontWeight: "300",
            color: "#707070",
            paddingHorizontal: 16,
            width: "80%",
          }}
        >
          {tip.toLowerCase().includes("bonus") ? (
            <>
              <Text
                style={{
                  color: "#410daa",
                  fontSize: 12,
                  fontWeight: "300",
                  paddingHorizontal: 16,
                  width: "80%",
                }}
              >
                Bonus
              </Text>
              {tip.split("Bonus")}
            </>
          ) : (
            tip
          )}
        </Text>
        <FlatList
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          data={items}
          renderItem={this.renderPortfolioItem}
          ItemSeparatorComponent={this.itemSeparator}
          contentContainerStyle={{
            alignItems: "flex-start",
            padding: 16,
          }}
        />
      </>
    );
  };

  renderPersonalisedForYou = (item) => {
    return (
      <CardStripNavigation
        image={{ uri: item.image }}
        title={item.title}
        description={item.description}
        onPress={() => {
          const { current_subscription_plan } = getUserDetails();
          let routeName = item.navigate_to;
          if (routeName == "PersonalisedFunds") {
            if (!current_subscription_plan) return;
            if (current_subscription_plan == "BP") {
              routeName = "MySubscription";
            } else {
              routeName = "PersonalisedFunds";
            }
          }
          routeName &&
            this.props.navigation.navigate(`${routeName}`, {
              from: "Home",
            });
        }}
        style={{}}
        clickableContainerStyle={{
          paddingVertical: 10,
        }}
      />
    );
  };

  static navigationOptions = {
    header: null,
  };

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("home",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("home", { path: this.props.navigation.getState().routeName });
    }
    const { userData } = Store.getState();
    this.setState({ isMFCustomer: userData.userProfileData.is_mfcustomer });
    await this.homeScreenData();
  }

  homeScreenData = async (refreshingAction) => {
    if (refreshingAction) this.setState({ refreshing: true });
    else this.setState({ isLoading: true });
    const resp = await getHomeScreenData();
    if (resp && resp.response) {
      this.setState({
        userHomeScreenData: resp.response,
        isLoading: false,
        refreshing: false,
      });
    } else {
      this.setState({
        error: "Error Fetching Data",
        refreshing: false,
        isLoading: false,
      });
    }
  };

  renderFinPlanComplete = (item) => {
    return (
      <TouchableOpacity
        style={{
          marginHorizontal: 16,
          marginBottom: 15,
          padding: 10,
          borderWidth: 0.5,
          borderRadius: 8,
          borderColor: "#410DAA33",
          flexDirection: "row",
        }}
        onPress={() => {
          this.props.navigation.navigate("App", {
            screen: "Dashboard",
            params: {
              screen: "Goals",
              params: {
                screen: "CreateFinplan",
                params: {
                  from: "Home",
                  type: "COMPLETE",
                  stack: "Dashboard",
                },
              },
            },
          });
        }}
      >
        <Image
          source={{
            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/complete+plan.png",
          }}
          style={{
            width: 25,
            height: 25,
            marginRight: 6,
            marginTop: 4,
            justifyContent: "flex-start",
          }}
          resizeMode={"contain"}
        />
        <View style={{ flex: 1 }}>
          <Text style={{ fontSize: 13, fontWeight: "bold", marginBottom: 10 }}>
            {item.title}
          </Text>
          <Text style={{ fontSize: 12, fontWeight: "300", color: "#707070" }}>
            {item.description.split(".")[0]}
          </Text>
          <Text style={{ fontSize: 12, fontWeight: "300", color: "#707070" }}>
            {item.description.split(".")[1].toLowerCase().includes("bonus") ? (
              <>
                <Text
                  style={{
                    color: "#410daa",
                    fontSize: 12,
                    fontWeight: "300",
                    paddingHorizontal: 16,
                    width: "80%",
                  }}
                >
                  Bonus
                </Text>
                {item.description.split(".")[1].split("Bonus")}
              </>
            ) : (
              item.description
            )}
          </Text>
        </View>
        <View style={{ alignItems: "flex-end", justifyContent: "center" }}>
          <EvilIcon
            name="arrow-right"
            style={{ fontSize: 30, color: "#410DAA" }}
          />
        </View>
      </TouchableOpacity>
    );
  };

  buildComponents = ({ item, index }) => {
    if (item.type == SIDE_HEADING) {
      if (
        !this.state.isMFCustomer &&
        (item.title == "Personalised for You" ||
          item.title == "Grow your wealth with us" ||
          item.title == "Invest for your Future")
      ) {
        return;
      } else {
        return (
          <SideHeading
            key={`${item.type}-${index}`}
            title={item.title}
            style={{
              fontSize: 20,
              alignItems: "center",
            }}
          />
        );
      }
    } else if (item.type == "PORTFOLIO") {
      return (
        <PortfolioSummaryCard
          portfolio={item}
          navigation={this.props.navigation}
        />
      );
    } else if (item.type == "PERSONALISED_FUNDS") {
      if (this.state.isMFCustomer) {
        return this.renderPersonalisedForYou(item);
      }
    } else if (item.type == "INVEST_GROW") {
      if (this.state.isMFCustomer) {
        return (
          <>
            <Text
              style={{
                fontSize: 12,
                fontWeight: "300",
                color: "#707070",
                marginHorizontal: 16,
              }}
            >
              {item.description}
            </Text>
            {this.renderInvestToBuildWealth(item.items)}
          </>
        );
      }
    } else if (item.type == "INVEST_GOAL") {
      if (this.state.isMFCustomer) {
        return <>{this.renderInvestForGoal(item)}</>;
      }
    } else if (item.type == "FINPLAN_COMPLETE") {
      return (
        <>
          {this.renderFinPlanComplete(item)}
          {this.renderEmptyView()}
        </>
      );
    } else if (item.type == "GET_RICHER") {
      return (
        <>
          {this.renderPortfolios(item)}
          {this.renderEmptyView()}
        </>
      );
    } else if (item.type == "BOOK_ADVISOR") {
      return (
        <>
          {this.renderBookAdvisor(item)}
          {this.renderEmptyView()}
        </>
      );
    } else if (item.type == "ACADEMY_CARD") {
      return this.renderJamaAcademy(item);
    } else if (item.type == "WELLNESS_ASSESS") {
      return this.renderAssessWellness(item);
    } else if (item.type == "WEALTH_PROFILE") {
      return (
        <>
          <WealthProfile
            data={item.results}
            navigation={this.props.navigation}
          />
          {this.renderEmptyView()}
        </>
      );
    } else if (item.type == "BANNER") {
      return null;
    }
  };

  _handleRefresh = async () => {
    await this.homeScreenData(true);
  };

  handleDashboardChange = () => {
    this.props.navigation.dispatch(
      StackActions.replace("InvestmentOverview", {
        showToast: "true",
      })
    );
  };

  render() {
    const { error, userHomeScreenData, isLoading } = this.state;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        {isLoading || error ? (
          <View style={styles.loadingContainer}>
            {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
            {error && <Text>Error loading !</Text>}
          </View>
        ) : (
          <>
            <ScrollView
              contentContainerStyle={{
                paddingBottom: 50,
                maxWidth: 800,
              }}
              showsVerticalScrollIndicator={true}
              refreshControl={
                <RefreshControl
                  refreshing={this.state.refreshing}
                  onRefresh={this._handleRefresh}
                  colors={["#410DAA"]}
                />
              }
            >
              <FlatList
                data={userHomeScreenData}
                renderItem={this.buildComponents}
                keyExtractor={(item, index) => `${item.type}-${index}`}
              />
            </ScrollView>
          </>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

const mapStateToProps = (state) => ({
  eduActivity: state.eduActivity,
});

export default connect(null)(Home);
