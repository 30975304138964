import React from "react";
import { View, StyleSheet } from "react-native";
import { Text, Button } from "react-native-paper";
import theme from "../../../static/theme/index.js";

const BookAdvisorStrip = ({
  onPress,
  style,
  title,
  button_text,
  description,
}) => {

    const handlePress = ()=>{
        onPress();
    }

    return (
        <View style={{ alignItems: "center", ...style }}>
            <Text style={styles.title}>
                {title}
            </Text>
            <Text style={styles.description}>
                {description}
            </Text>
            <Button
                uppercase={false}
                mode={"contained"}
                onPress={handlePress}
                color={"#410DAA"}
                style={styles.button}
            >
                <Text style={styles.buttonText}>{button_text}</Text>
            </Button>
        </View>
  );
};

const styles = StyleSheet.create({
    button: {
        marginTop: 5,
        width: "95%",
        paddingVertical: 6,
        alignSelf: "center",
    },
    buttonText: {
        fontSize: 14,
        color: "#fff"
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
        color: theme.textColors.BLACK_TEXT,
        marginBottom: 5,
    },
    description: {
        fontSize: 12,
        fontWeight: "300",
        textAlign: "center",
        color: theme.textColors.LIGHT_GRAY,
        marginVertical: 10,
        marginHorizontal: 16,
    }
});

export default BookAdvisorStrip;
