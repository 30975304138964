//  Gateway
import axiosApiInstance from "./apiConfig";
import { URLS } from "./urls";

export const getGatewaySdkToken = async () => {
  const url = URLS.GET_AUTH_TOKEN+"/";
  const result = await axiosApiInstance.get(url);
  return result;
}

export const handleGatewaySuccess = async (data) => {
  const url = URLS.GATEWAY_SUCCESS + "/";
  const result = await axiosApiInstance.post(url, data);
  return result;
}

export const handleGatewayError = async (data) => {
  const url = URLS.GATEWAY_ERROR + "/";
  const result = await axiosApiInstance.post(url, data);
  return result;
}

export const handleGatewayImportHoldings = async (data) => {
  const url = URLS.GATEWAY_IMPORT_HOLDINGS + "/";
  const result = await axiosApiInstance.post(url, data);
  return result;
}

export const handleGatewayImportHoldingsWithWebhook = async (data) => {
  const url = URLS.GATEWAY_IMPORT_HOLDINGS_WEBHOOK + "/";
  const result = await axiosApiInstance.post(url, data);
  return result;
}

export const handleGatewayRetry = async (data) => {
  const url = URLS.GATEWAY_RETRY_ORDER;
  const result = await axiosApiInstance.post(url, data);
  return result;
}