import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity,
} from "react-native";
import Entypo from "@expo/vector-icons/Entypo";
import { isMobile } from "../../../utils";
import { TextInput } from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import SearchBar from "../../Common/SearchBar";
import AntDesign from "@expo/vector-icons/AntDesign";

const TableRow = ({ data: rowData, gridConfig }) => {
  return (
    <View style={styles.tableRow}>
      {rowData.map((rowItem, rowItemIndex) => {
        const {
          value,
          style = [],
          component = false,
          valueToDisplay,
          rowItemContainerStyle = [],
        } = rowItem;
        return (
          <View
            style={{
              flex: gridConfig[rowItemIndex],
              justifyContent: "center",
              alignItems: rowItemIndex == 0 ? "flex-start" : "center",
            }}
          >
            {component ? (
              <Text style={[styles.rowItem, ...style]}>{value}</Text>
            ) : rowItemIndex == 0 ? (
              <Text style={[styles.rowItem, ...style, { width: '100%', textAlign: 'center' }]}>
                {valueToDisplay}
              </Text>
            ) : (
              <Text style={[styles.rowItem, ...style]}>{valueToDisplay}</Text>
            )}
          </View>
        );
      })}
    </View>
  );
};

export const TableHeader = ({
  data = [],
  gridConfig,
  pageIndex,
  numberOfEntries = 0,
  tableHeaderStyle = [],
}) => {
  const { start, end } = getStartEnd(numberOfEntries, pageIndex);
  return (
    <View style={[styles.tableHeader, ...tableHeaderStyle]}>
      <View style={{ flexDirection: "row", marginRight: 10, width: "100%" }}>
        {data.map((headingItem, headingIndex) => {
          const {
            component = false,
            label,
            sortValue,
            currentSortField,
            sortOption,
            onSort,
            key,
            headerItemContainerStyle = [],
            style = [],
          } = headingItem;
          const activeSortKey = currentSortField === key && sortValue;
          if (component) {
            return (
              <View
                style={{
                  flex: gridConfig[headingIndex],
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {label}
              </View>
            );
          }
          return (
            <View
              style={{
                flex: gridConfig[headingIndex],
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Text style={[styles.headingItem, ...style]}>{label}</Text>
              {sortOption && (
                <TouchableOpacity
                  onPress={() => onSort(key, sortValue, start, end)}
                  style={styles.sortContainer}
                >
                  {activeSortKey ? (
                    <Entypo
                      name="triangle-up"
                      size={15}
                      color={activeSortKey ? "#464E5F" : "#464E5F"}
                      style={styles.sortIconDown}
                    />
                  ) : (
                    <Entypo
                      name="triangle-down"
                      size={15}
                      color={activeSortKey ? "#464E5F" : "#464E5F"}
                      style={styles.sortIconUp}
                    />
                  )}
                </TouchableOpacity>
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
};

const Pagination = ({ length, pageIndex, onChange }) => {
  const { start, end, total } = getStartEnd(length, pageIndex);
  return (
    <View style={pagination.container}>
      <View style={pagination.body}>
        <Text style={pagination.current}>
          Showing results {start + 1} - {end} of {length}
        </Text>
        <View style={pagination.buttonsContainer}>
          <TouchableOpacity
            style={pagination.iconContainer}
            onPress={() => {
              if (pageIndex != 0) {
                onChange(pageIndex - 1);
              }
            }}
          >
            <Entypo
              name="chevron-thin-left"
              size={12}
              color={"#464E5F"}
              style={pagination.icon}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={pagination.iconContainer}
            onPress={() => {
              onChange(pageIndex);
            }}
          >
            <Text style={pagination.currentIndex}>{pageIndex + 1}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={pagination.iconContainer}
            onPress={() => {
              if (pageIndex + 1 < total) {
                onChange(pageIndex + 1);
              }
            }}
          >
            <Entypo
              name="chevron-thin-right"
              size={12}
              color={"#464E5F"}
              style={pagination.icon}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const getStartEnd = (length, pageIndex) => {
  const numberOfRowsToSkip = pageIndex * ROWS_TO_SHOW;
  let start = 0,
    end = length;
  if (numberOfRowsToSkip <= length) {
    start = numberOfRowsToSkip;
    if (start + ROWS_TO_SHOW <= length) {
      end = start + ROWS_TO_SHOW;
    } else {
      const diff = length - start;
      end = start + diff;
    }
  }

  const total = Math.ceil(length / ROWS_TO_SHOW);
  return { start, end, total };
};

const ROWS_TO_SHOW = 50;
const StockTable = ({
  header,
  gridConfig,
  data = [],
  style = [],
  pagination = true,
  tableHeaderStyle = [],
  navigation = {},
  isMutualFundTable = false,
  showSearch = true,
  showFilter = false,
  filterlabel = [],
  filterKeys = []
}) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [ dataToDisplay, setDataToDisplay] = useState([]);
  const [searchText, setSearchtext] = useState("");
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectProducts, setSelectProducts] = useState([]);


  function filterUnique(data) {
    const flattenedArray = data.flat(); // Flatten the array
    return flattenedArray.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  }

  function separateArrays(dataValue) {
    const separatedArrays = [];
    // console.log("dataValue:",dataValue);
    for (let i = 0; i < dataValue[0].length; i++) {
      const arrayAtIndex = [{ label: "select", value: "select" }];
      dataValue.forEach(subArray => {
        arrayAtIndex.push({ label: subArray[i], value: subArray[i] });
      });
      separatedArrays.push(arrayAtIndex);
    }
    return separatedArrays;
  }
React.useEffect(() => {
  let temp = new Set();
  data.forEach(innerArray => {
    let t = [];
    filterKeys.forEach((filterKey) => {
      if (filterKey < innerArray.length) {
        t.push(innerArray[1].value);
      }
    });
    t = filterUnique(t);
    temp.add(JSON.stringify(t));
  });
  let filteredData = Array.from(temp, JSON.parse);
  filteredData = separateArrays(filteredData);
  setSelectProducts(filteredData);
}, [data]);

  const renderRow = ({ item, index }) => {
    return (
      <TableRow
        data={item}
        key={index}
        gridConfig={gridConfig}
        navigation={navigation}
        isMutualFundTable={isMutualFundTable}
      />
    );
  };

  function filterRows(data, index, filterValue) {
    if (filterValue == "select") {
      setDataToDisplay(data);
      return;
    }
    let filteredRows = [];
    for (let i = 0; i < data.length; i++) {
      let columnValue = data[i][1].value;
      if (columnValue === filterValue) {
        filteredRows.push(data[i]);
      }
    }
    setDataToDisplay(filteredRows);
  }
  function filterRowsBySearch(data, searchValue) {
    if(searchText == ""){
      setDataToDisplay(data);
      return;
    }
    let filteredRows = [];
    for (let i = 0; i < data.length; i++) {
      let row = data[i];
      let matchFound = false;
      for (let j = 0; j < row.length; j++) {
        let columnValue = row[j].valueToDisplay;
        if (columnValue.toString().toLowerCase().includes(searchValue.toLowerCase())) {
          matchFound = true;
          break;
        }
      }
      if (matchFound) {
        filteredRows.push(row);
      }
    }
    setDataToDisplay(filteredRows);
  }

  React.useEffect(() => {
    const d = getDataToDisplay(data, currentPageIndex);
    setDataToDisplay(d);
  }, [data, currentPageIndex]);

  const getDataToDisplay = (data, pageIndex) => {
    const length = data.length;
    const { start, end } = getStartEnd(length, pageIndex);
    return data.slice(start, end);
  };

  return (
    <View style={[styles.container, ...style]}>
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        { showSearch && <View style={[styles.Searchcontainer, ...style]}>
          <AntDesign
            name="search1"
            size={12}
            color="#DADADA"
            style={styles.searchIcon}
          />
          <TextInput
            placeholder={"Search"}
            value={searchText}
            onChangeText={(value) => {
              setSearchtext(value);
              filterRowsBySearch(data, searchText);
            }}
            style={styles.textInput}
            mode="flat"
            underlineColor={"transparent"}
            theme={{
              colors: {
                primary: "transparent",
              },
            }}
          />
        </View>}
        {showFilter && selectProducts.map((items, indx) => <View style={{marginTop: 10}}>
          <Text style={{
            marginHorizontal: 22,
            marginBottom: 5,
            fontSize: 12
          }}>{filterlabel[indx]}</Text>
          <Picker
            selectedValue={selectedPlan}
            onValueChange={(itemValue, itemIndex) => {
              filterRows(data, indx, itemValue)
              setSelectedPlan(itemValue)
            }}
            style={{
              borderColor: "transparent",
              color: "#464E5F",
              marginBottom: 20,
              marginHorizontal: 20,
              width: 150,
              backgroundColor: "#F9F6FF",
              borderBottomColor: "#D8D8D8",
              borderBottomWidth: 1,
              paddingBottom: 5
            }}
          >
            {items.map((item, index) => (
              <Picker.Item key={index} label={item.label} value={item.value} />
            ))}
          </Picker>
        </View>)}
      </View>
      <TableHeader
        data={header}
        numberOfEntries={dataToDisplay.length}
        gridConfig={gridConfig}
        pageIndex={currentPageIndex}
        tableHeaderStyle={tableHeaderStyle}
      />
      <FlatList
        data={dataToDisplay}
        renderItem={renderRow}
        keyExtractor={(item, index) => index.toString()}
        style={styles.flatList}
      />
      {!!data.length && pagination && (
        <Pagination
          length={data.length}
          pageIndex={currentPageIndex}
          onChange={(index) => setCurrentPageIndex(index)}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    overflow: "hidden",
    paddingBottom: 20,
  },
  tableHeader: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F3F6F9",
    paddingVertical: 11,
  },
  firstHeaderTitle: {
    justifyContent: "flex-start",
  },
  headingItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }, 
  headingItem: {
    textAlign: "center",
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  sortContainer: {
    marginLeft: 5,
    flexDirection: "row",
    alignItems: "center", 
  },
  sortIconUp: {
    paddingVertical: 0,
    marginVertical: 0,
  },
  sortIconDown: {
    marginLeft: 0,
  },
  rowItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  rowItem: {
    textAlign: "center",
    color: "#121212",
    fontSize: 11,
    fontFamily: "Roboto",
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    flex: 1,
    paddingVertical: 13,
  },
  flatList: {},
  Searchcontainer: {
    flexDirection: "row",
    alignItems: "center",
    borderColor: "#410DAA33",
    borderRadius: 5,
    borderWidth: 1,
    paddingHorizontal: 12,
    marginHorizontal: isMobile ? 5 : 20,
    marginVertical: 10, 
    paddingVertical: 7,
    backgroundColor: "#fff",
    width: isMobile ? "80%" : 350,
  },
  textInput: {
    fontSize: 13,
    color: "#B5B5C3",
    fontFamily: "Roboto",
    backgroundColor: "#fff",
    height: 30,
    width: "100%",
  }
});

const pagination = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: isMobile ? "flex-start" : "flex-end",
  },
  body: {
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 36,
  },
  current: {
    color: "#464E5F",
    fontFamily: "Roboto",
    fontSize: 13,
    marginRight: 12,
    marginLeft: isMobile ? 12 : 0,
  },
  iconContainer: {
    paddingVertical: 5,
    paddingHorizontal: 8,
    backgroundColor: "#F3F6F9",
    marginRight: 9,
  },
  buttonsContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  currentIndex: {
    fontSize: 13,
    color: "#464E5F",
    fontFamily: "Roboto",
  },
});

export default StockTable;
