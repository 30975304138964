import React from "react";
import {
  View,
  Text,
  Dimensions,
  ScrollView,
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { md } from "../../../Responsive";
import {
  capitalizeFirstLetter,
  getUserDetails,
  isMobile,
} from "../../../utils";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { getInvoicesPaymentLink } from "../../../api/billing";
import {
  cancelAllSipOrdersForACustomer,
  createCrmdealsCipForAdvisory,
  getCustomerDetails,
} from "../../../api";
import { ActivityIndicator } from "react-native";
import amplitude from "../../../utils/amplitude";
import Store from "../../../store";
const { width, height } = Dimensions.get("window");
const isWeb = md(width);
import { ShowToast } from "../../../components/Common/Toast";
import { CustomModal } from "../../../components/Common/CustomModal";
import { getMySIPs } from "../../../api/account";

export default class AdvisoryPlan extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    let firstLogin = null;
    const { params } = this.props.route;
    let payment_failed = false;
    let cip_pk_for_renewal = null;
    if (params) {
      payment_failed = params.payment_failed || null;
      cip_pk_for_renewal = params.cip_pk_for_renewal || null;
    }
    let showToast = false;
    let toastMsg = null;
    if (payment_failed) {
      showToast = true;
      toastMsg = "Payment Failed, Please Try Again.";
    }
    let is_club = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    
    if (hostname.includes("club")) {
      is_club = true;
    }
    this.state = {
      userId,
      firstLogin,
      Plan6MBg: "white",
      Plan6MColor: "#410DAA",
      Plan1YBg: "white",
      Plan1YColor: "#410DAA",
      Plan3YBg: "white",
      Plan3YColor: "#410DAA",
      isLoading: false,
      showToast: showToast,
      toastMsg: toastMsg,
      userName: "",
      payment_failed,
      cip_pk_for_renewal,
      userData: null,
      is_club,
      amount: null,
      plan_code: null,
      showModal: false,
      modalLoading: false,
      loadingMessage: null,
      modalError: null,
      show_wish_to_continue: true,
      showSipText: false,
      continueUpgradeToAdvisory: false,
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("AdvisoryPlan", { path: global.location.href });
    } else {
      amplitude.logEvent("AdvisoryPlan", {
        path: this.props.navigation.getState().routeName,
      });
    }
    let userData = {};
    // console.log(global.location.href);
    // console.log(global.location.href.split("/")[2]);
    if (this.state.userId) {
      userData = await getCustomerDetails(params.user_id);
    } else {
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        userData = await getCustomerDetails(
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        userData = getUserDetails();
      }
    }
    // console.log(userData);
    const { first_name } = userData;
    let names = first_name ? first_name.split(/\s+/) : [];
    let name = "";
    if (names.length >= 2 && names.length > 0) {
      name =
        capitalizeFirstLetter(names[0]) + " " + capitalizeFirstLetter(names[1]);
    } else {
      if (names.length == 0) {
        name = "User";
      } else {
        name = names[0];
      }
    }
    // console.log(this.state.userData);
    this.setState({ userName: name, userData: userData });
  }

  handleAdvisoryRedirect = async (amount, plan_code) => {
    let { is_club } = this.state;
    if (is_club) {
      // console.log("You are switching from club to advisory");
      this.setState({
        showModal: true,
        amount,
        plan_code,
      });
    } else {
      this.handleMakePayment(amount, plan_code);
    }
  };

  handleMakePayment = async (amount, plan_code) => {
    try {
      this.setState({ isLoading: true });
      const crmcippayload = {
        plancode: plan_code,
        cip_pk_for_renewal: this.state.cip_pk_for_renewal,
      };
      const crm_sip_resp = await createCrmdealsCipForAdvisory(crmcippayload);
      if (crm_sip_resp && crm_sip_resp.code == 100) {
        const payload = {
          invoices: crm_sip_resp.cust_invoice_id.toString(),
          action: "advisory_plan_upgrade", //switch_to_preferred_circle
          amount: crm_sip_resp.amount,
          server_name:
            Platform.OS == "web"
              ? global.location.href.split("/")[2]
              : "mobile",
        };
        const response = await getInvoicesPaymentLink(payload);
        if (response && response.code && response.code == 100) {
          const { results = {} } = response;
          const { payment_link } = results;
          if (Platform.OS == "web") {
            global.open(payment_link, "_self");
          } else {
            this.props.navigation.navigate("App", {
              screen: "Dashboard",
              params: {
                screen: "Portfolio",
                params: {
                  screen: "ShowWebView",
                  params: {
                    url: payment_link,
                    successURL: "account/upgrade_advisory_plan/",
                    onSuccess: function (navigation, _results = {}) {
                      navigation.push("App", {
                        screen: "Account",
                        params: {
                          screen: "UpgradeAdvisoryPlanRedirection",
                          params: {},
                        },
                      });
                    },
                    onError: function (_results = {}) {},
                  },
                },
              },
            });
          }
        }
      } else {
        this.setState({
          isLoading: false,
          showToast: true,
          toastMsg: crm_sip_resp.msg,
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        showToast: true,
        toastMsg: error,
      });
    }
  };

  checkSipsPresentForCustomerToCancel = async () => {
    try {
      this.setState({
        show_wish_to_continue: false,
        modalLoading: true,
        loadingMessage: "Checking SIPs present for you.",
      });
      let { userData, is_club } = this.state;
      // if (is_club && userData.customer_id == 3) {
      //   this.setState({
      //     continueUpgradeToAdvisory: true,
      //     modalLoading: false,
      //     loadingMessage: null
      //   });
      // } else {
      let getMySIPsResponse = await getMySIPs();
      const sipData = getMySIPsResponse.filter(
        (item) => item.bse_trans_code == "NEW" && item.bse_sip_reg_id != "0"
      );
      // console.log(sipData);

      if (sipData.length > 0) {
        this.setState({
          showSipText:
            "You have " + sipData.length + " SIP's present in our system.",
          modalLoading: false,
          loadingMessage: null,
        });
      } else {
        this.setState({
          continueUpgradeToAdvisory: true,
          modalLoading: false,
          loadingMessage: null,
        });
      }
      // }
    } catch (err) {
      console.log(err);
      this.setState({
        show_wish_to_continue: false,
        modalLoading: false,
        loadingMessage: null,
        modalError: "Error while loading SIPs. Kindly contact your advisor.",
      });
    }
  };

  cancelAllSips = async () => {
    try {
      this.setState({
        modalLoading: true,
        loadingMessage:
          "Cancelling SIPs present for you. kindly do not close the page or pop up before it completes.",
        showSipText: false,
      });
      const response = await cancelAllSipOrdersForACustomer();
      // console.log(response);
      if (response && response.code == 100) {
        this.setState({
          continueUpgradeToAdvisory: true,
          modalLoading: false,
          loadingMessage: null,
        });
      } else {
        this.setState({
          modalLoading: false,
          loadingMessage: null,
          modalError: response.msg,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        modalLoading: false,
        loadingMessage: null,
        modalError: "Error while cancelling SIPs. Kindly contact your advisor.",
      });
    }
  };

  renderModalContent = () => {
    let { amount, plan_code, modalLoading, loadingMessage, modalError } =
      this.state;
    return modalLoading || modalError ? (
      <View
        style={{
          padding: 15,
          flex: 1,
          alignItems: "center",
        }}
      >
        {modalLoading && <ActivityIndicator size="small" color="#410DAA" />}
        {loadingMessage && (
          <Text style={{ fontSize: 12 }}>{loadingMessage}</Text>
        )}
        {modalError && <Text style={{ fontSize: 12 }}>{modalError}</Text>}
      </View>
    ) : (
      <View
        style={{
          padding: 15,
          flex: 1,
          alignItems: "center",
        }}
      >
        {this.state.show_wish_to_continue ? (
          <>
            <Text>
              You selected new plan as 'Advisory Plan'. Your previous plan is
              'Investor Club'. As per regulations, you can invest in direct plan
              mutual funds or avail advice on stocks but cannot make further
              investments in regular plan mutual funds.
            </Text>
            <View style={{ padding: 10 }}></View>
            <Text>Are you sure you wish to continue?</Text>
            <View style={{ padding: 10 }}></View>
            <TouchableOpacity
              style={{
                backgroundColor: "#410DAA",
                borderWidth: 1,
                borderRadius: 4,
                borderColor: "#410DAA",
                // padding: 20,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
                paddingBottom: 10,
              }}
              onPress={() => {
                this.checkSipsPresentForCustomerToCancel();
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Continue
              </Text>
            </TouchableOpacity>
          </>
        ) : this.state.showSipText ? (
          <>
            <Text
              style={{
                fontSize: 14,
              }}
            >
              {this.state.showSipText}{" "}
              <Text
                style={{
                  color: "#410DAA",
                  textDecorationLine: "underline",
                }}
                onPress={() => {
                  this.toggleModal();
                  this.props.navigation.navigate("App", {
                    screen: "Account",
                    params: {
                      screen: "MySIPs",
                      params: {
                        activeTabIndex: 1,
                      },
                    },
                  });
                }}
              >
                Click here
              </Text>{" "}
              to view active SIP's
            </Text>
            <View style={{ padding: 10 }}></View>
            <Text>
              Kindly click below to cancel all SIP's present. You cannot switch
              to Advisory Plan with SIP's present in regular plan mutual funds.
            </Text>
            <View style={{ padding: 10 }}></View>
            <Text>Are you sure you wish to cancel?</Text>
            <View style={{ padding: 10 }}></View>
            <TouchableOpacity
              style={{
                backgroundColor: "#410DAA",
                borderWidth: 1,
                borderRadius: 4,
                borderColor: "#410DAA",
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
                paddingBottom: 10,
              }}
              onPress={() => {
                this.cancelAllSips();
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Cancel All SIPs
              </Text>
            </TouchableOpacity>
          </>
        ) : (
          this.state.continueUpgradeToAdvisory && (
            <>
              <Text>
                You have no active SIPs present in our system. Click on continue
                to switch to Advisory Plan.
              </Text>
              <View style={{ padding: 10 }}></View>
              <TouchableOpacity
                style={{
                  backgroundColor: "#410DAA",
                  borderWidth: 1,
                  borderRadius: 4,
                  borderColor: "#410DAA",
                  // padding: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                onPress={() => {
                  // alert("Migrating to advisory" + amount + plan_code)
                  this.toggleModal();
                  this.handleMakePayment(amount, plan_code);
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    color: "#ffffff",
                  }}
                >
                  Continue
                </Text>
              </TouchableOpacity>
            </>
          )
        )}
      </View>
    );
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
      modalLoading: false,
      loadingMessage: null,
      modalError: null,
      show_wish_to_continue: true,
      showSipText: false,
      continueUpgradeToAdvisory: false,
    });
  };

  render() {
    const { isLoading, showToast, toastMsg } = this.state;
    // console.log(showToast, toastMsg);
    return isLoading ? (
      <ActivityIndicator
        size="large"
        color="#410DAA"
        style={{ paddingTop: 40 }}
      />
    ) : (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: "#ffffff",
          }}
        >
          {showToast ? (
            <ShowToast
              showToast={true}
              type="status"
              title={this.state.toastMsg}
              duration="10"
            />
          ) : null}
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Advisory Circle Plans"]}
            onPressBack={() => {
              this.props.navigation.navigate("Plans");
            }}
          />
          <ScrollView
            style={{
              paddingLeft: isWeb ? 32 : 0,
              paddingRight: isWeb ? 32 : 0,
              paddingTop: isWeb ? 30 : 0,
              marginBottom: isMobile ? 0 : 20,
              height: height,
            }}
          >
            {isMobile ? (
              <ScrollView>
                <View
                  style={{
                    marginTop: 20,
                    width: isMobile ? "90%" : "100%",
                    marginLeft: isMobile ? "5%" : "0%",
                    marginRight: isMobile ? "5%" : "0%",
                    flexDirection: "column",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      borderColor: "#cdcdcb",
                      borderWidth: 1,
                      marginBottom: "6%",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "12%",
                      }}
                    >
                      <View
                        style={{
                          width: "100%",
                          display: "flex",
                          backgroundColor: "#e4dbf2",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "50%",
                          borderRadius: 9,
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      >
                        <Text
                          style={{
                            color: "#410DAA",
                            fontSize: 14,
                            fontWeight: "500",
                            paddingRight: "20%",
                          }}
                        >
                          Most Popular
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          width: "20%",
                          borderRadius: 9,
                          borderColor: "#009105",
                          borderWidth: 1,
                          backgroundColor: "#009105",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 40,
                          borderBottomRightRadius: 40,
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "-20%",
                        }}
                      >
                        <Text
                          style={{
                            color: "white",
                            fontSize: 14,
                            fontWeight: "500",
                          }}
                        >
                          50%
                        </Text>
                        <Text
                          style={{
                            color: "white",
                            fontSize: 14,
                            fontWeight: "500",
                          }}
                        >
                          Off
                        </Text>
                      </View>
                    </View>
                    <Text
                      style={{
                        fontSize: 34,
                        color: "#51514E",
                        fontWeight: "700",
                        marginLeft: "10%",
                        marginTop: "-5%",
                      }}
                    >
                      ₹ 14,400 /-
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#a1a6af",
                        fontWeight: "600",
                        marginLeft: "10%",
                        marginTop: "2%",
                      }}
                    >
                      3 Year Validity
                    </Text>
                    <Text
                      style={{
                        fontSize: 20,
                        color: "#027BFC",
                        fontWeight: "700",
                        marginLeft: "10%",
                        marginTop: "7%",
                        marginBottom: "5%",
                      }}
                    >
                      PACK OF 72 ADVICES
                    </Text>
                    <View
                      style={{
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        borderRadius: 10,
                        borderColor: "white",
                        borderWidth: 1,
                        backgroundColor: "#f9f5ff",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          color: "#464E5F",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        Advices Included
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Stock of the month (36)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Mutual Fund Pack (12)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Financial Plan Review (6)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Portfolio Review (6)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Adhoc Advices (12)
                        </Text>
                      </View>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#8a6acc",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                          marginBottom: "10%",
                          fontStyle: "italic",
                        }}
                      >
                        View More
                      </Text>
                    </View>
                    <Text
                      onPress={() => {
                        this.handleAdvisoryRedirect(4800, "AHY");
                      }}
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "white",
                        backgroundColor: "#410DAA",
                        borderColor: "#410DAA",
                        borderWidth: 1,
                        borderRadius: 4,
                        paddingVertical: 10,
                        textAlign: "center",
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        marginTop: "10%",
                        marginBottom: "20%",
                      }}
                    >
                      Subscribe
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      borderColor: "#cdcdcb",
                      borderWidth: 1,
                      marginBottom: "6%",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "12%",
                      }}
                    >
                      <View
                        style={{
                          width: "80%",
                        }}
                      ></View>
                      <View
                        style={{
                          display: "flex",
                          width: "20%",
                          borderRadius: 9,
                          borderColor: "#009105",
                          borderWidth: 1,
                          backgroundColor: "#009105",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 40,
                          borderBottomRightRadius: 40,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            color: "white",
                            fontSize: 14,
                            fontWeight: "500",
                          }}
                        >
                          25%
                        </Text>
                        <Text
                          style={{
                            color: "white",
                            fontSize: 14,
                            fontWeight: "500",
                          }}
                        >
                          Off
                        </Text>
                      </View>
                    </View>
                    <Text
                      style={{
                        fontSize: 34,
                        color: "#51514E",
                        fontWeight: "700",
                        marginLeft: "10%",
                        marginTop: "16%",
                      }}
                    >
                      ₹ 7,200/-
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#a1a6af",
                        fontWeight: "600",
                        marginLeft: "10%",
                        marginTop: "2%",
                      }}
                    >
                      1 Year Validity
                    </Text>
                    <Text
                      style={{
                        fontSize: 20,
                        color: "#027BFC",
                        fontWeight: "700",
                        marginLeft: "10%",
                        marginTop: "7%",
                        marginBottom: "5%",
                      }}
                    >
                      PACK OF 24 ADVICES
                    </Text>
                    <View
                      style={{
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        borderRadius: 10,
                        borderColor: "white",
                        borderWidth: 1,
                        backgroundColor: "#f9f5ff",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          color: "#464E5F",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        Advices Included
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Stock of the month (12)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Mutual Fund Pack (4)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Financial Plan Review (2)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Portfolio Review (2)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Adhoc Advices (4)
                        </Text>
                      </View>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#8a6acc",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                          marginBottom: "10%",
                          fontStyle: "italic",
                        }}
                      >
                        View More
                      </Text>
                    </View>
                    <Text
                      onPress={() => {
                        this.handleAdvisoryRedirect(7200, "A1Y");
                      }}
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "#410DAA",
                        backgroundColor: "white",
                        borderColor: "#410DAA",
                        borderWidth: 1,
                        borderRadius: 4,
                        paddingVertical: 10,
                        textAlign: "center",
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        marginTop: "10%",
                        marginBottom: "20%",
                      }}
                    >
                      Subscribe
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      borderColor: "#cdcdcb",
                      borderWidth: 1,
                      marginBottom: 100,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 34,
                        color: "#51514E",
                        fontWeight: "700",
                        marginLeft: "10%",
                        marginTop: "14.8%",
                      }}
                    >
                      ₹ 4,800/-
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        color: "#a1a6af",
                        fontWeight: "600",
                        marginLeft: "10%",
                        marginTop: "2%",
                      }}
                    >
                      6 Months Validity
                    </Text>
                    <Text
                      style={{
                        fontSize: 20,
                        color: "#027BFC",
                        fontWeight: "700",
                        marginLeft: "10%",
                        marginTop: "7%",
                        marginBottom: "5%",
                      }}
                    >
                      PACK OF 9 ADVICES
                    </Text>
                    <View
                      style={{
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        borderRadius: 10,
                        borderColor: "white",
                        borderWidth: 1,
                        backgroundColor: "#f9f5ff",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          color: "#464E5F",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        Advices Included
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Stock of the month (6)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Mutual Fund Pack (1)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Financial Plan Review (1)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        >
                          Portfolio Review (1)
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: "3%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "",
                          }}
                          style={{
                            width: 11,
                            height: 11,
                            resizeMode: "contain",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: "#464E5F",
                            fontWeight: "400",
                          }}
                        ></Text>
                      </View>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#8a6acc",
                          fontWeight: "700",
                          marginLeft: "10%",
                          marginTop: "7%",
                          marginBottom: "10%",
                          fontStyle: "italic",
                        }}
                      >
                        View More
                      </Text>
                    </View>
                    <Text
                      onPress={() => {
                        this.handleAdvisoryRedirect(14400, "A3Y");
                      }}
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "#410DAA",
                        backgroundColor: "white",
                        borderColor: "#410DAA",
                        borderWidth: 1,
                        borderRadius: 4,
                        paddingVertical: 10,
                        textAlign: "center",
                        width: "82%",
                        marginLeft: "9%",
                        marginRight: "9%",
                        marginTop: "10%",
                        marginBottom: "20%",
                      }}
                    >
                      Subscribe
                    </Text>
                  </View>
                </View>
              </ScrollView>
            ) : (
              <View>
                {this.state.userData &&
                this.state.userData.show_corporate_benifit_line ? (
                  <View
                    style={{
                      paddingRight: 7,
                      paddingBottom: 20,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        fontStyle: "italic",
                        color: "#494B69",
                      }}
                    >
                      Please visit your corporate portal to avail any corporate
                      benefits.
                    </Text>
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        fontStyle: "italic",
                        color: "#494B69",
                      }}
                    >
                      Fees paid towards first six months of any plan shall not
                      be refunded as per our policy.
                    </Text>
                  </View>
                ) : null}
                <View style={styles.body}>
                  <View style={styles.right}>
                    {this.state.firstLogin ? (
                      <Text
                        style={{
                          textAlign: "left",
                          fontWeight: "700",
                          fontSize: 16,
                          fontFamily: "Roboto",
                          marginBottom: 20,
                          marginTop: -55,
                        }}
                      >
                        Welcome {this.state.userName}, we found you have not
                        selected any subscription plan for investment, kindly
                        choose any plan and proceed for payment.
                      </Text>
                    ) : (
                      <View style={{ flexDirection: "row", marginTop: -55 }}>
                        <Text
                          style={{
                            textAlign: "left",
                            fontWeight: "700",
                            fontSize: 16,
                            fontFamily: "Roboto",
                            marginBottom: 20,
                            paddingLeft: 30,
                          }}
                        >
                          Greetings {this.state.userName}, Welcome to the
                          Investment Advisory Circle! Please select a suitable
                          subscription plan for your membership
                        </Text>
                      </View>
                    )}
                    <View
                      style={{
                        width: isMobile ? "90%" : "100%",
                        marginLeft: isMobile ? "5%" : "0%",
                        marginRight: isMobile ? "5%" : "0%",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        marginBottom: 60,
                      }}
                    >
                      <View
                        style={{
                          width: "31%",
                          marginLeft: "0.5%",
                          borderRadius: 10,
                          borderColor: "#cdcdcb",
                          borderWidth: 1,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 34,
                            color: "#51514E",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "16%",
                          }}
                        >
                          ₹ 4,800/-
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#51514E",
                            fontWeight: "600",
                            marginLeft: "10%",
                            marginTop: "2%",
                          }}
                        >
                          (or) ₹800/- per month
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#a1a6af",
                            fontWeight: "600",
                            marginLeft: "10%",
                            marginTop: "2%",
                          }}
                        >
                          6 Months Validity
                        </Text>
                        <Text
                          style={{
                            fontSize: 20,
                            color: "#027BFC",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                            marginBottom: "5%",
                          }}
                        >
                          PACK OF 9 ADVICES
                        </Text>
                        <View
                          style={{
                            width: "82%",
                            marginLeft: "9%",
                            marginRight: "9%",
                            borderRadius: 10,
                            borderColor: "white",
                            borderWidth: 1,
                            backgroundColor: "#f9f5ff",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#464E5F",
                              fontWeight: "700",
                              marginLeft: "10%",
                              marginTop: "7%",
                            }}
                          >
                            Advices Included
                          </Text>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "7%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Stock of the month (6)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Mutual Fund Pack (1)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Financial Plan Review (1)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                                marginBottom: 10,
                              }}
                            >
                              Portfolio Review (1)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              {" "}
                            </Text>
                          </View>
                          {/* <Text
                          style={{
                            fontSize: 14,
                            color: "#8a6acc",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                            marginBottom: "10%",
                            fontStyle: "italic",
                          }}
                        >
                          View More 
                        </Text> */}
                        </View>
                        <Text
                          onPress={() => {
                            this.handleAdvisoryRedirect(4800, "AHY");
                          }}
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "bold",
                            fontSize: 15,
                            color: this.state.Plan6MColor,
                            backgroundColor: this.state.Plan6MBg,
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            borderRadius: 4,
                            paddingVertical: 10,
                            textAlign: "center",
                            width: "82%",
                            marginLeft: "9%",
                            marginRight: "9%",
                            marginTop: "10%",
                            marginBottom: "10%",
                          }}
                          onMouseEnter={() => {
                            this.setState({
                              Plan6MColor: "white",
                              Plan6MBg: "#410DAA",
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({
                              Plan6MBg: "white",
                              Plan6MColor: "#410DAA",
                            });
                          }}
                        >
                          Subscribe
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "3%",
                        }}
                      ></View>
                      <View
                        style={{
                          width: "31%",
                          borderRadius: 10,
                          borderColor: "#cdcdcb",
                          borderWidth: 1,
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            height: "12%",
                          }}
                        >
                          <View
                            style={{
                              width: "80%",
                            }}
                          ></View>
                          <View
                            style={{
                              display: "flex",
                              width: "20%",
                              height: "60%",
                              borderRadius: 9,
                              borderColor: "#009105",
                              borderWidth: 1,
                              backgroundColor: "#009105",
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 40,
                              borderBottomRightRadius: 40,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                color: "white",
                                fontSize: 12,
                                fontWeight: "500",
                              }}
                            >
                              25%
                            </Text>
                            <Text
                              style={{
                                color: "white",
                                fontSize: 14,
                                fontWeight: "500",
                              }}
                            >
                              Off
                            </Text>
                          </View>
                        </View>
                        <Text
                          style={{
                            fontSize: 34,
                            color: "#51514E",
                            fontWeight: "700",
                            marginLeft: "10%",
                            
                          }}
                        >
                          ₹ 7,200/-
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#51514E",
                            fontWeight: "600",
                            marginLeft: "10%",
                            marginTop: "2%",
                          }}
                        >
                          (or) ₹ 600/- per month
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#a1a6af",
                            fontWeight: "600",
                            marginLeft: "10%",
                            marginTop: "2%",
                          }}
                        >
                          1 Year Validity
                        </Text>
                        <Text
                          style={{
                            fontSize: 20,
                            color: "#027BFC",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                            marginBottom: "5%",
                          }}
                        >
                          PACK OF 24 ADVICES
                        </Text>
                        <View
                          style={{
                            width: "82%",
                            marginLeft: "9%",
                            marginRight: "9%",
                            borderRadius: 10,
                            borderColor: "white",
                            borderWidth: 1,
                            backgroundColor: "#f9f5ff",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#464E5F",
                              fontWeight: "700",
                              marginLeft: "10%",
                              marginTop: "7%",
                            }}
                          >
                            Advices Included
                          </Text>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "7%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Stock of the month (12)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Mutual Fund Pack (4)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Financial Plan Review (2)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Portfolio Review (2)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Adhoc Advices (4)
                            </Text>
                          </View>
                          {/* <Text
                          style={{
                            fontSize: 14,
                            color: "#8a6acc",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                            marginBottom: "10%",
                            fontStyle: "italic",
                          }}
                        >
                          View More 
                        </Text> */}
                        </View>
                        <Text
                          onPress={() => {
                            this.handleAdvisoryRedirect(7200, "A1Y");
                          }}
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "bold",
                            fontSize: 15,
                            color: "white",
                            backgroundColor: "#410DAA",
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            borderRadius: 4,
                            paddingVertical: 10,
                            textAlign: "center",
                            width: "82%",
                            marginLeft: "9%",
                            marginRight: "9%",
                            marginTop: "10%",
                            marginBottom: "10%",
                          }}
                          onMouseEnter={() => {
                            this.setState({
                              Plan1YColor: "white",
                              Plan1YBg: "#410DAA",
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({
                              Plan1YBg: "white",
                              Plan1YColor: "#410DAA",
                            });
                          }}
                        >
                          Subscribe
                        </Text>
                      </View>
                      <View
                        style={{
                          height: 300,
                          width: "3%",
                        }}
                      ></View>
                      <View
                        style={{
                          width: "31%",
                          borderRadius: 10,
                          borderColor: "#cdcdcb",
                          borderWidth: 1,
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            height: "12%",
                          }}
                        >
                          <View
                            style={{
                              width: "100%",
                              display: "flex",
                              backgroundColor: "#e4dbf2",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "40%",
                              borderRadius: 9,
                              borderBottomLeftRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                          >
                            <Text
                              style={{
                                color: "#410DAA",
                                fontSize: 14,
                                fontWeight: "500",
                                paddingRight: "20%",
                              }}
                            >
                              Most Popular
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              width: "20%",
                              height: "65%",
                              borderRadius: 9,
                              borderColor: "#009105",
                              borderWidth: 1,
                              backgroundColor: "#009105",
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 40,
                              borderBottomRightRadius: 40,
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: "-20%",
                            }}
                          >
                            <Text
                              style={{
                                color: "white",
                                fontSize: 12,
                                fontWeight: "500",
                              }}
                            >
                              50%
                            </Text>
                            <Text
                              style={{
                                color: "white",
                                fontSize: 14,
                                fontWeight: "500",
                              }}
                            >
                              Off
                            </Text>
                          </View>
                        </View>
                        <Text
                          style={{
                            fontSize: 34,
                            color: "#51514E",
                            fontWeight: "700",
                            marginLeft: "10%",
                            
                          }}
                        >
                          ₹ 14,400/-
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#51514E",
                            fontWeight: "600",
                            marginLeft: "10%",
                            marginTop: "2%",
                          }}
                        >
                          (or) ₹ 400/- per month
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#a1a6af",
                            fontWeight: "600",
                            marginLeft: "10%",
                            marginTop: "2%",
                          }}
                        >
                          3 Year Validity
                        </Text>
                        <Text
                          style={{
                            fontSize: 20,
                            color: "#027BFC",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                            marginBottom: "5%",
                          }}
                        >
                          PACK OF 72 ADVICES
                        </Text>
                        <View
                          style={{
                            width: "82%",
                            marginLeft: "9%",
                            marginRight: "9%",
                            borderRadius: 10,
                            borderColor: "white",
                            borderWidth: 1,
                            backgroundColor: "#f9f5ff",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#464E5F",
                              fontWeight: "700",
                              marginLeft: "10%",
                              marginTop: "7%",
                            }}
                          >
                            Advices Included
                          </Text>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "7%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Stock of the month (36)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Mutual Fund Pack (12)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Financial Plan Review (6)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Portfolio Review (6)
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "10%",
                              marginTop: "3%",
                            }}
                          >
                            <Image
                              source={{
                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/tick.png",
                              }}
                              style={{
                                width: 11,
                                height: 11,
                                resizeMode: "contain",
                                marginRight: 10,
                                marginTop: 5,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#464E5F",
                                fontWeight: "400",
                              }}
                            >
                              Adhoc Advices (12)
                            </Text>
                          </View>
                          {/* <Text
                          style={{
                            fontSize: 14,
                            color: "#8a6acc",
                            fontWeight: "700",
                            marginLeft: "10%",
                            marginTop: "7%",
                            marginBottom: "10%",
                            fontStyle: "italic",
                          }}
                        >
                          View More 
                        </Text> */}
                        </View>
                        <Text
                          onPress={() => {
                            this.handleAdvisoryRedirect(14400, "A3Y");
                          }}
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "bold",
                            fontSize: 15,
                            color: this.state.Plan3YColor,
                            backgroundColor: this.state.Plan3YBg,
                            borderColor: "#410DAA",
                            borderWidth: 1,
                            borderRadius: 4,
                            paddingVertical: 10,
                            textAlign: "center",
                            width: "82%",
                            marginLeft: "9%",
                            marginRight: "9%",
                            marginTop: "10%",
                            marginBottom: "10%",
                          }}
                          onMouseEnter={() => {
                            this.setState({
                              Plan3YColor: "white",
                              Plan3YBg: "#410DAA",
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({
                              Plan3YBg: "white",
                              Plan3YColor: "#410DAA",
                            });
                          }}
                        >
                          Subscribe
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )}
          </ScrollView>
          <CustomModal
            visible={this.state.showModal}
            showCloseButton={true}
            closeModal={() => {
              this.toggleModal();
            }}
          >
            {this.renderModalContent()}
          </CustomModal>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1 },
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 50,
  },
  left: {
    flex: 0.4,
  },
  right: {
    flex: 1,
  },
  left_img: {
    resizeMode: "contain",
    width: 250,
    height: 250,
    marginTop: -40,
  },

  lock: {
    resizeMode: "contain",
    width: 30,
    height: 30,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 18 : 15,
    lineHeight: md(width) ? 21 : 17,
    color: "#2F3044",
  },
  terms1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 22 : 18,
    lineHeight: md(width) ? 25 : 20,
    color: "#2F3044",
    display: md(width) ? "none" : "flex",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 15 : 12,
    lineHeight: 17,
    color: "#464E5F",
    paddingTop: 12,
    paddingBottom: md(width) ? 32 : 26,
    paddingRight: md(width) ? 150 : 11,
  },
  please1: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 16 : 12,
    lineHeight: 18,
    color: "#464E5F",
    paddingTop: isMobile ? 11 : 0,
    paddingBottom: md(width) ? 32 : 26,
    paddingRight: md(width) ? 170 : 11,
    display: md(width) ? "none" : "flex",
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 100,
  },
  add1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 14 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    paddingBottom: 6.35,
  },
  inptxt: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 12 : 13,
    lineHeight: md(width) ? 14 : 15,
    color: "#121212",
    paddingVertical: isMobile ? 8 : 13.5,
    paddingHorizontal: 18.75,
    backgroundColor: "#f6f6f6",
    borderRadius: 4,
  },
  max: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  res: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 16 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    paddingBottom: 13.29,
  },
  drop: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#121212",
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 18,
    marginTop: 20,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  next: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    fontWeight: "bold",
    paddingVertical: 12,
    paddingHorizontal: 50,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  view_sample: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#8950FC",
    paddingBottom: md(width) ? 5 : 10,
  },
  addressProof: {
    fontSize: 11,
    color: "#121212",
    marginBottom: 14,
    fontWeight: "700",
  },
  fileName: {
    fontSize: 12,
    marginTop: 5,
    color: "#410DAA",
    fontWeight: "bold",
  },
  support: {
    fontSize: 10,
    color: "#121212",
    fontStyle: "italic",
    marginTop: 4,
  },
  error: { color: "red" },
  rowContianer: {
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 28,
  },
  marginRight: {
    marginRight: isMobile ? 0 : 29,
  },
  rowItem: {
    flex: 0.5,
  },
});
