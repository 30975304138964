import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { ActivityIndicator, Text } from 'react-native-paper';
import { checkPANKYCStatus, getKarvyUrl } from '../../../api/account';
import { getUserDetails, isMobile, redirectToPendingScreen } from '../../../utils';
import { Ionicons } from '@expo/vector-icons';


const EKYC = (props) => {
    const [fetchingKYC, setFetchingKYC] = useState(true);
    const [error, setError] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [kycStatus, setKytStatus] = useState(null);
    const [kycRedirection, setKYCRedirection] = useState(null);
    useEffect(() => {
        const fetchKycStatus = async () =>{
            try {
                const { pan_number } = getUserDetails();
                const payload ={
                    pan_number
                }
                const response = await checkPANKYCStatus(payload);
                if(response && response.code == 100){
                    const { kyc_status } = response;
                    const compliance = (kyc_status == "REG" || kyc_status == "UND" || kyc_status.startsWith("EK") ) ? "COMP" : "NONCOMP"// COMP = compliance NON = Non compliance
                    setKytStatus(compliance);
                    if(compliance == "NONCOMP"){
                        await handleStartKYC();
                    }else{
                        const redirect = await redirectToPendingScreen(this.props);
                    }
                }else{
                    await handleStartKYC();
                }
                setFetchingKYC(false);
            } catch (error) {
                setFetchingKYC(false);
            }
        }
        fetchKycStatus()
    },[]);


    const handleStartKYC = async () => {
        try {
            setKYCRedirection(true);
            const response = await getKarvyUrl();
            if(response && response.code == 100){
                const { karvy_redirect_url } = response;
                if(karvy_redirect_url){
                    global.open(karvy_redirect_url, "_blank");
                    return;
                }
            }
            setKYCRedirection(false);
        } catch (error) {
            console.log(error)
            setKYCRedirection(false);
        }
    }

    return (
        <View style={styles.container}>
            {/* <NavigationHeader navigation={props.navigation} navigationHeader={["EKYC"]} /> */}
            <ScrollView contentContainerStyle={styles.scrollView}>
                {
                    fetchingKYC ? (
                        <View style={styles.loaderContainer}>
                            <ActivityIndicator size="large" color="#410DAA"/>
                            <Text style={styles.fetching}>Fetching KYC status</Text>
                        </View>
                    ): (
                        <View style={styles.mainContainer}>
                            {
                                kycRedirection && <Text style={styles.description}>Redirecting to Karvy to complete KYC process</Text>
                            }
                            {
                                kycStatus == "COMP" && (
                                    <View style={styles.complianceContainer}>
                                        <Text style={styles.description}>Your KYC is Compliant</Text>
                                        <Ionicons name="checkmark-circle-sharp" size={18} color="#009105" style={{paddingLeft: 10}} />
                                    </View>
                                )
                            }
                        </View>
                    )
                }
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff"
    },
    scrollView: {
        flex: 1,
        paddingTop: 20,
        paddingHorizontal: isMobile ? 16 : 32
    },
    loaderContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
    },
    loader: {
        flex:1,
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        left: 0,
    },
    mainContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    fetching: {
        fontSize: 14,
        color: "#12121250",
        marginTop: 20
    },
    yourKycStatus: {
        fontSize: 18,
        fontWeight: "600",
        color: "#121212"
    },
    description: {
        fontSize: 18,
        color: "#12121295",
    },
    complianceContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20
    },
    proccesHeading: {
        color: "#121212",
        fontSize: 15,
        fontWeight: "600",
        marginTop: 30,
        marginBottom: 15
    },
    easyQuick: {
        fontSize: 12,
        color: "green",
        marginTop: 6,
        marginLeft: 4
    }
});


export default EKYC;