import React from "react";
import { StackActions } from "@react-navigation/native";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import Header from "../header_after_login";
import Store from "../../../store";
import { generateAgreementPdf, insertKYC } from "../../../api";

export default class success extends React.Component {
  constructor(props) {
    super(props);
    let showRedirection = true;
    const { params } = props.route;

    let raoc = localStorage.getItem("redirectAfterOnboardingSuccess");
    if (params) {
      if (params.nvg) {
        showRedirection = params.nvg == "Home";
      }
    }
    this.state = {
      timer: 3,
      showRedirection,
      raoc,
      redirectTo: params && params.nvg ? params.nvg : "Home",
    };
  }

  componentDidMount = async () => {
    try {
      insertKYC({});
    } catch (error) {}
    this.interval = setInterval(
      () => this.setState((prevState) => ({ timer: prevState.timer - 1 })),
      1000
    );
  };

  componentDidUpdate() {
    if (this.state.timer === 0) {
      clearInterval(this.interval);
      const { actions = {} } = Store.getState().transactions;
      const { params = {} } = this.props.route;
      const { actionId = null } = params;
      if (actionId in actions) {
        const { stack = null, screen = null, ...rest } = actions[actionId];
        if (stack && screen) {
          if (Platform.OS == "web") {
            if (this.state.raoc) {
              localStorage.removeItem("redirectAfterOnboardingSuccess");
              this.props.navigation.dispatch(
                StackActions.replace("App", {
                  screen: this.state.raoc,
                  params: {},
                })
              );
              return;
            }
            this.props.navigation.dispatch(
              StackActions.replace("App", {
                screen: "Dashboard",
                params: {
                  screen: "Dashboard",
                  params: {
                    screen: "Home",
                    params: {
                      showToast: "true",
                    },
                  },
                },
              })
            );
          } else {
            if (this.state.raoc) {
              localStorage.removeItem("redirectAfterOnboardingSuccess");
              this.props.navigation.dispatch(
                StackActions.replace("App", {
                  screen: this.state.raoc,
                  params: {},
                })
              );
              return;
            }
            this.props.navigation.dispatch(
              StackActions.replace("App", {
                screen: "Dashboard",
                params: {
                  screen: stack,
                  params: { screen: screen, params: { ...rest } },
                },
              })
            );
          }
          return;
        }
      }
      if (this.state.showRedirection) {
        if (Platform.OS == "web") {
          if (this.state.raoc) {
            localStorage.removeItem("redirectAfterOnboardingSuccess");
            this.props.navigation.dispatch(
              StackActions.replace("App", {
                screen: this.state.raoc,
                params: {},
              })
            );
            return;
          }
          this.props.navigation.dispatch(
            StackActions.replace("App", {
              screen: "Dashboard",
              params: {
                screen: "Dashboard",
                params: {
                  screen: "Home",
                  params: {
                    showToast: "true",
                  },
                },
              },
            })
          );
        } else {
          if (this.state.raoc) {
            localStorage.removeItem("redirectAfterOnboardingSuccess");
            this.props.navigation.dispatch(
              StackActions.replace("App", {
                screen: this.state.raoc,
                params: {},
              })
            );
            return;
          }
          this.props.navigation.dispatch(
            StackActions.replace("App", {
              screen: "Dashboard",
              params: { screen: "Dashboard", params: {} },
            })
          );
        }
      } else {
        if (this.state.raoc) {
          localStorage.removeItem("redirectAfterOnboardingSuccess");
          this.props.navigation.dispatch(
            StackActions.replace("App", {
              screen: this.state.raoc,
              params: {},
            })
          );
          return;
        }
        this.props.navigation.dispatch(
          StackActions.replace("App", {
            screen: "Dashboard",
            params: {
              screen: "Dashboard",
              params: { screen: this.state.redirectTo },
            },
          })
        );
      }
    }
  }

  redirectToScreen = () => {
    if (this.state.showRedirection) {
      const { actions = {} } = Store.getState().transactions;
      const { params = {} } = this.props.route;
      const { actionId = null } = params;
      if (actionId in actions) {
        const { stack = null, screen = null, ...rest } = actions[actionId];
        if (stack && screen) {
          if (this.state.raoc) {
            localStorage.removeItem("redirectAfterOnboardingSuccess");
            this.props.navigation.dispatch(
              StackActions.replace("App", {
                screen: this.state.raoc,
                params: {},
              })
            );
            return;
          }
          this.props.navigation.dispatch(
            StackActions.replace("App", {
              screen: "Dashboard",
              params: {
                screen: "Dashboard",
                params: {
                  screen: "Home",
                  params: {
                    showToast: "true",
                  },
                },
              },
            })
          );
          return;
        }
      }
    }
    if (this.state.raoc) {
      localStorage.removeItem("redirectAfterOnboardingSuccess");
      this.props.navigation.dispatch(
        StackActions.replace("App", {
          screen: this.state.raoc,
          params: {},
        })
      );
      return;
    }
    this.props.navigation.dispatch(
      StackActions.replace("App", {
        screen: "Dashboard",
        params: {
          screen: "Dashboard",
          params: {
            screen: "Home",
            params: {
              showToast: "true",
            },
          },
        },
      })
    );
  };

  render() {
    return (
      <>
        <Header navigation={this.props.navigation} route={this.props.route} />
        <View style={styles.container}>
          <Text style={styles.you_hv}>
            Congratulations! Your onboarding is now complete.
          </Text>
          <Image
            source={{
              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/money_transfer_-2+1.png",
            }}
            style={styles.img}
          />
          <Text style={styles.redirecting}>
            Redirecting to Dashboard in{" "}
            <Text style={{ color: "lightblue", fontWeight: "bold" }}>
              {this.state.timer} sec
            </Text>
          </Text>
          <TouchableOpacity onPress={this.redirectToScreen}>
            <Text style={styles.continue}>Continue to Dashboard</Text>
          </TouchableOpacity>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#ffffff",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 50,
  },
  you_hv: {
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: 29,
    fontWeight: "bold",
    color: "#000000",
    textAlign: "center",
    paddingTop: 50,
    paddingBottom: 65,
  },
  img: {
    width: 341,
    height: 257,
  },
  redirecting: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "rgba(18, 18, 18, 0.4)",
    paddingTop: 54,
    paddingBottom: 30,
  },
  continue: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    backgroundColor: "#410DAA",
    borderRadius: 4,
    paddingVertical: 16,
    paddingHorizontal: 22,
  },
});
