import React from "react";
import {
	View,
	ScrollView,
	StyleSheet,
	Text,
	ActivityIndicator,
	Platform,
	Linking,
	TouchableOpacity,
	TextInput
} from 'react-native';
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { getUserDetails, isMobile } from '../../../utils'
import { saveCustomerData } from "../../../api/account";
import moment from "moment/moment";
import { LOGOUT } from "../../../actions/types";
import Store from "../../../store";

class DeActivateAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			text: "",
		};
	}

	handleBack = () => {
		this.props.navigation.goBack();
	};

	handleContinueToLogout = async () => {
		try {
			localStorage.removeItem("isShowAppDownloadCard");
			localStorage.removeItem("showPlanRenewalCard");
			localStorage.removeItem("redirectAfterOnboardingSuccess");
			Store.dispatch({ type: LOGOUT });
		} catch (error) {
			Store.dispatch({ type: LOGOUT });
		}
	};

	handleProceed = async () => {
		const currentDate = new Date();
		const formattedDate = currentDate.toISOString().slice(0, 19).replace('T', ' ');
		const { username, customer_id } = getUserDetails();
		const payload = {
			username: username,
			is_active: false,
			account_deactivated_on: formattedDate
		}

		const response = await saveCustomerData(customer_id, payload);
		if (response) {
			this.handleContinueToLogout();
		}
	}

	render() {
		const {
			isloading
		} = this.state;
		return isloading ? (
			<View style={styles.container}>
				<ActivityIndicator color={"#410DAA"} size={"large"} />
			</View>
		) : (
			<View style={styles.container}>
				<NavigationHeader
					navigation={this.props.navigation}
					navigationHeader={["Deactivate Account"]}
					onPressBack={this.handleBack}
				/>
				<ScrollView contentContainerStyle={styles.scrollViewContainer}>
					<View style={{ padding: 20 }}>
						<Text style={{ color: "#1a1a1a", fontWeight: '500' }}>
							Under Indian investment rules set by SEBI, advisors must keep client information for five years.
							This includes the investment advice you received, why it was given, and any actions taken. These
							records are kept to make sure everything is open, responsible, and to protect your interests. They
							must be stored so they can be easily found and checked if needed by the government authorities.
						</Text>
						<Text style={{ color: "#1a1a1a", fontWeight: '500', marginTop: 10 }}>
							By choosing to close your account, you won't be able to access the app anymore. We'll hold onto
							your transaction records for five years to meet these rules. After that, we'll delete them. Note
							that you can activate account again by writing to {" "}
							<Text style={{ color: "#410DAA", fontWeight: '500' }} onPress={() => {
								if (Platform.OS == "web") {
									global.open("mailto:help@maxiom.in", "_blank")
								} else {
									Linking.openURL("mailto:help@maxiom.in");
								}
							}}>help@maxiom.in</Text> OR attempting to login again
							with an OTP verification and clicking on 're-activate my account'.
						</Text>
						<View style={{ marginTop: 25 }}>
							<Text style={{ color: "#1a1a1a", fontWeight: '700' }}>Are you sure you want to close your account? If yes, please type "PROCEED" in the box below.</Text>
							<TextInput
								style={styles.input}
								onChangeText={(val) => this.setState({ text: val })}
								value={this.state.text}
								placeholder="Type here..."
							/>
						</View>
						<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
							<TouchableOpacity
								style={this.state.text === "PROCEED" ? styles.Activatedbtn : styles.deActivatedbtn}
								onPress={this.handleProceed}
								disabled={this.state.text === "PROCEED" ? false : true}
							>
								<Text style={{ color: '#fff', fontWeight: '500', textAlign: 'center' }}>Deactivate</Text>
							</TouchableOpacity>
						</View>
					</View>
				</ScrollView>
			</View>
		)
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
	},
	scrollViewContainer: {
		flexGrow: 1,
		paddingHorizontal: 16,
		paddingTop: 16
	},
	input: {
		width: !isMobile ? "50%" : "100%",
		height: 40,
		borderColor: "rgba(65, 13, 170, 0.3)",
		borderWidth: 1,
		padding: 10,
		marginTop: 15,
		fontWeight: '700'
	},
	Activatedbtn: {
		backgroundColor: '#410DAA',
		width: 150,
		paddingVertical: 10,
		marginTop: 50,
		borderRadius: 5,
		justifyContent: 'center',
		alignItems: 'center'
	},
	deActivatedbtn: {
		backgroundColor: 'gray',
		width: 150,
		paddingVertical: 10,
		marginTop: 50,
		borderRadius: 5,
		justifyContent: 'center',
		alignItems: 'center'
	}
})

export default DeActivateAccount;