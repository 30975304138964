import React from 'react';
import { View, ScrollView, SafeAreaView, ActivityIndicator, Platform, StyleSheet, StatusBar } from 'react-native';
import { Text, TextInput } from 'react-native-paper';
import {Picker} from '@react-native-picker/picker';
import { SideHeading } from '../../components/Common/sideHeading';
import CustButton from '../../components/Common/Button';
import NavigationHeader from '../../components/Common/NavigationHeader';
import {ViewSeparator} from "../../components/Common/viewSeperator";
import amplitude from '../../utils/amplitude';
import {assetTypes} from "../../components/Constants/constants";
import CurrencyFormatInput from "../../components/Common/currencyFormatInput";
import { addAsset, getRecentAssets } from '../../api';
import { Snackbar } from 'react-native-paper';
import PickerFieldSelect from '../../components/Common/Pickers/PickerFieldSelect';

const data = {
    code: "100",
    results: {
        all: [
        {
            asset_type_text: "Property",
            name: "Royal Roof Apartments",
            created: "2018-08-31T15:25:39",
            tag_color: "#2a9d8f",
            latest_value_text: "₹31,23,100",
            amount_at_cost_of_purchase: 31231,
            date_of_purchase_formatted: "21 Apr 2020",
            asset_folio_number: "213456",
            pk: 36,
            date_of_purchase: "2020-04-21",
            latest_value: 3123100,
            asset_type: "REA",
            amount_at_cost_text: "₹31,231"
        },
        {
            asset_type_text: "Property",
            name: "Test Asset",
            created: "2019-03-18T13:44:12",
            tag_color: "#2a9d8f",
            latest_value_text: "₹1,23,000",
            amount_at_cost_of_purchase: 12388,
            date_of_purchase_formatted: "14 Apr 2020",
            asset_folio_number: "Gajsbz",
            pk: 177,
            date_of_purchase: "2020-04-14",
            latest_value: 123000,
            asset_type: "REA",
            amount_at_cost_text: "₹12,388"
        },
        {
            asset_type_text: "Stocks",
            name: "Test",
            created: "2019-06-06T21:31:02",
            tag_color: "#e76f51",
            latest_value_text: "₹48,188",
            amount_at_cost_of_purchase: 48188,
            date_of_purchase_formatted: "06 Jun 2019",
            asset_folio_number: "",
            pk: 236,
            date_of_purchase: "2019-06-06",
            latest_value: 48188,
            asset_type: "EQS",
            amount_at_cost_text: "₹48,188"
        },
        {
            asset_type_text: "Property",
            name: "This is New asset",
            created: "2020-02-28T11:44:36",
            tag_color: "#2a9d8f",
            latest_value_text: "₹500",
            amount_at_cost_of_purchase: 500,
            date_of_purchase_formatted: "28 Feb 2020",
            asset_folio_number: "",
            pk: 658,
            date_of_purchase: "2020-02-28",
            latest_value: 500,
            asset_type: "REV",
            amount_at_cost_text: "₹500"
        },
        {
            asset_type_text: "Debt",
            name: "Hello Assetyy",
            created: "2020-02-28T13:55:21",
            tag_color: "#264653",
            latest_value_text: "₹50,000",
            amount_at_cost_of_purchase: 50000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 659,
            date_of_purchase: "2020-04-25",
            latest_value: 50000,
            asset_type: "DBB",
            amount_at_cost_text: "₹50,000"
        },
        {
            asset_type_text: "Gold",
            name: "Hello asset",
            created: "2020-02-28T14:07:21",
            tag_color: "#f4a261",
            latest_value_text: "₹2,500",
            amount_at_cost_of_purchase: 2500,
            date_of_purchase_formatted: "28 Feb 2020",
            asset_folio_number: "",
            pk: 660,
            date_of_purchase: "2020-02-28",
            latest_value: 2500,
            asset_type: "GDM",
            amount_at_cost_text: "₹2,500"
        },
        {
            asset_type_text: "Gold",
            name: "Hello asset",
            created: "2020-02-28T14:17:10",
            tag_color: "#f4a261",
            latest_value_text: "₹500",
            amount_at_cost_of_purchase: 500,
            date_of_purchase_formatted: "28 Feb 2020",
            asset_folio_number: "",
            pk: 661,
            date_of_purchase: "2020-02-28",
            latest_value: 500,
            asset_type: "GDB",
            amount_at_cost_text: "₹500"
        },
        {
            asset_type_text: "Debt",
            name: "Hello Assettuu",
            created: "2020-02-28T14:18:27",
            tag_color: "#264653",
            latest_value_text: "₹500",
            amount_at_cost_of_purchase: 500,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 662,
            date_of_purchase: "2020-04-25",
            latest_value: 500,
            asset_type: "DBB",
            amount_at_cost_text: "₹500"
        },
        {
            asset_type_text: "Property",
            name: "Hedddd",
            created: "2020-03-11T15:40:26",
            tag_color: "#2a9d8f",
            latest_value_text: "₹15,555",
            amount_at_cost_of_purchase: 15555,
            date_of_purchase_formatted: "11 Mar 2020",
            asset_folio_number: "",
            pk: 669,
            date_of_purchase: "2020-03-11",
            latest_value: 15555,
            asset_type: "REV",
            amount_at_cost_text: "₹15,555"
        },
        {
            asset_type_text: "Property",
            name: "Na assetttttt",
            created: "2020-03-11T16:27:58",
            tag_color: "#2a9d8f",
            latest_value_text: "₹1,00,000",
            amount_at_cost_of_purchase: 100000,
            date_of_purchase_formatted: "11 Mar 2020",
            asset_folio_number: "",
            pk: 670,
            date_of_purchase: "2020-03-11",
            latest_value: 100000,
            asset_type: "REV",
            amount_at_cost_text: "₹1,00,000"
        },
        {
            asset_type_text: "Property",
            name: "As",
            created: "2020-03-11T16:30:36",
            tag_color: "#2a9d8f",
            latest_value_text: "₹10,000",
            amount_at_cost_of_purchase: 10000,
            date_of_purchase_formatted: "11 Mar 2020",
            asset_folio_number: "",
            pk: 671,
            date_of_purchase: "2020-03-11",
            latest_value: 10000,
            asset_type: "REV",
            amount_at_cost_text: "₹10,000"
        },
        {
            asset_type_text: "Property",
            name: "Hihi",
            created: "2020-03-11T16:33:30",
            tag_color: "#2a9d8f",
            latest_value_text: "₹15,000",
            amount_at_cost_of_purchase: 15000,
            date_of_purchase_formatted: "11 Mar 2020",
            asset_folio_number: "",
            pk: 672,
            date_of_purchase: "2020-03-11",
            latest_value: 15000,
            asset_type: "REV",
            amount_at_cost_text: "₹15,000"
        },
        {
            asset_type_text: "Property",
            name: "Hahahk",
            created: "2020-03-11T16:41:46",
            tag_color: "#2a9d8f",
            latest_value_text: "₹1,000",
            amount_at_cost_of_purchase: 1000,
            date_of_purchase_formatted: "11 Mar 2020",
            asset_folio_number: "",
            pk: 675,
            date_of_purchase: "2020-03-11",
            latest_value: 1000,
            asset_type: "REV",
            amount_at_cost_text: "₹1,000"
        },
        {
            asset_type_text: "Stocks",
            name: "Gati",
            created: "2020-03-11T16:44:24",
            tag_color: "#e76f51",
            latest_value_text: "₹1,000",
            amount_at_cost_of_purchase: 1000,
            date_of_purchase_formatted: "11 Mar 2020",
            asset_folio_number: "",
            pk: 676,
            date_of_purchase: "2020-03-11",
            latest_value: 1000,
            asset_type: "EQN",
            amount_at_cost_text: "₹1,000"
        },
        {
            asset_type_text: "Stocks",
            name: "Hahai",
            created: "2020-03-11T16:46:37",
            tag_color: "#e76f51",
            latest_value_text: "₹1,00,100",
            amount_at_cost_of_purchase: 100,
            date_of_purchase_formatted: "23 Apr 2020",
            asset_folio_number: "",
            pk: 677,
            date_of_purchase: "2020-04-23",
            latest_value: 100100,
            asset_type: "EQS",
            amount_at_cost_text: "₹100"
        },
        {
            asset_type_text: "Gold",
            name: "Gold",
            created: "2020-03-16T12:42:43",
            tag_color: "#f4a261",
            latest_value_text: "₹3,50,000",
            amount_at_cost_of_purchase: 350000,
            date_of_purchase_formatted: "16 Mar 2020",
            asset_folio_number: "",
            pk: 681,
            date_of_purchase: "2020-03-16",
            latest_value: 350000,
            asset_type: "GDB",
            amount_at_cost_text: "₹3,50,000"
        },
        {
            asset_type_text: "Debt",
            name: "Cash",
            created: "2020-03-26T12:38:43",
            tag_color: "#264653",
            latest_value_text: "₹1,888",
            amount_at_cost_of_purchase: 1888,
            date_of_purchase_formatted: "26 Mar 2020",
            asset_folio_number: "",
            pk: 685,
            date_of_purchase: "2020-03-26",
            latest_value: 1888,
            asset_type: "DBC",
            amount_at_cost_text: "₹1,888"
        },
        {
            asset_type_text: "Debt",
            name: "Cash",
            created: "2020-03-26T12:44:12",
            tag_color: "#264653",
            latest_value_text: "₹1,500",
            amount_at_cost_of_purchase: 1500,
            date_of_purchase_formatted: "26 Mar 2020",
            asset_folio_number: "",
            pk: 686,
            date_of_purchase: "2020-03-26",
            latest_value: 1500,
            asset_type: "DBC",
            amount_at_cost_text: "₹1,500"
        },
        {
            asset_type_text: "Debt",
            name: "Cash",
            created: "2020-04-03T14:26:19",
            tag_color: "#264653",
            latest_value_text: "₹7,50,000",
            amount_at_cost_of_purchase: 750000,
            date_of_purchase_formatted: "03 Apr 2020",
            asset_folio_number: "",
            pk: 687,
            date_of_purchase: "2020-04-03",
            latest_value: 750000,
            asset_type: "DBC",
            amount_at_cost_text: "₹7,50,000"
        },
        {
            asset_type_text: "Property",
            name: "Dummy asset1",
            created: "2020-04-06T22:36:14",
            tag_color: "#2a9d8f",
            latest_value_text: "₹1,50,000",
            amount_at_cost_of_purchase: 150000,
            date_of_purchase_formatted: "06 Apr 2020",
            asset_folio_number: "",
            pk: 695,
            date_of_purchase: "2020-04-06",
            latest_value: 150000,
            asset_type: "REA",
            amount_at_cost_text: "₹1,50,000"
        },
        {
            asset_type_text: "Property",
            name: "Testin account",
            created: "2020-04-07T09:18:27",
            tag_color: "#2a9d8f",
            latest_value_text: "₹15,000",
            amount_at_cost_of_purchase: 15000,
            date_of_purchase_formatted: "01 Apr 2020",
            asset_folio_number: "718923",
            pk: 696,
            date_of_purchase: "2020-04-01",
            latest_value: 15000,
            asset_type: "REL",
            amount_at_cost_text: "₹15,000"
        },
        {
            asset_type_text: "Property",
            name: "Dummy asset for testing",
            created: "2020-04-07T15:30:06",
            tag_color: "#2a9d8f",
            latest_value_text: "₹7,100",
            amount_at_cost_of_purchase: 7100,
            date_of_purchase_formatted: "07 Apr 2020",
            asset_folio_number: "",
            pk: 698,
            date_of_purchase: "2020-04-07",
            latest_value: 7100,
            asset_type: "REV",
            amount_at_cost_text: "₹7,100"
        },
        {
            asset_type_text: "Property",
            name: "Land at Hyd",
            created: "2020-04-09T12:31:54",
            tag_color: "#2a9d8f",
            latest_value_text: "₹45,00,000",
            amount_at_cost_of_purchase: 3500000,
            date_of_purchase_formatted: "08 Apr 2020",
            asset_folio_number: "45667",
            pk: 699,
            date_of_purchase: "2020-04-08",
            latest_value: 4500000,
            asset_type: "REL",
            amount_at_cost_text: "₹35,00,000"
        },
        {
            asset_type_text: "Stocks",
            name: "Mutual Fund",
            created: "2020-04-09T12:33:29",
            tag_color: "#e76f51",
            latest_value_text: "₹2,50,000",
            amount_at_cost_of_purchase: 200000,
            date_of_purchase_formatted: "08 Apr 2020",
            asset_folio_number: "",
            pk: 700,
            date_of_purchase: "2020-04-08",
            latest_value: 250000,
            asset_type: "EQS",
            amount_at_cost_text: "₹2,00,000"
        },
        {
            asset_type_text: "Gold",
            name: "Gold",
            created: "2020-04-17T16:23:13",
            tag_color: "#f4a261",
            latest_value_text: "₹12,500",
            amount_at_cost_of_purchase: 12500,
            date_of_purchase_formatted: "17 Apr 2020",
            asset_folio_number: "",
            pk: 704,
            date_of_purchase: "2020-04-17",
            latest_value: 12500,
            asset_type: "GDB",
            amount_at_cost_text: "₹12,500"
        },
        {
            asset_type_text: "Property",
            name: "Land",
            created: "2020-04-21T16:39:29",
            tag_color: "#2a9d8f",
            latest_value_text: "₹15,28,000",
            amount_at_cost_of_purchase: 1528000,
            date_of_purchase_formatted: "21 Apr 2020",
            asset_folio_number: "",
            pk: 705,
            date_of_purchase: "2020-04-21",
            latest_value: 1528000,
            asset_type: "REL",
            amount_at_cost_text: "₹15,28,000"
        },
        {
            asset_type_text: "Debt",
            name: "Cash",
            created: "2020-04-23T09:48:41",
            tag_color: "#264653",
            latest_value_text: "₹15,000",
            amount_at_cost_of_purchase: 15000,
            date_of_purchase_formatted: "23 Apr 2020",
            asset_folio_number: "",
            pk: 706,
            date_of_purchase: "2020-04-23",
            latest_value: 15000,
            asset_type: "DBC",
            amount_at_cost_text: "₹15,000"
        },
        {
            asset_type_text: "Stocks",
            name: "Hello",
            created: "2020-04-25T22:58:29",
            tag_color: "#e76f51",
            latest_value_text: "₹1,00,000",
            amount_at_cost_of_purchase: 100000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 707,
            date_of_purchase: "2020-04-25",
            latest_value: 100000,
            asset_type: "EQS",
            amount_at_cost_text: "₹1,00,000"
        },
        {
            asset_type_text: "Debt",
            name: "Hahha",
            created: "2020-04-25T23:00:13",
            tag_color: "#264653",
            latest_value_text: "₹10,000",
            amount_at_cost_of_purchase: 10000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 708,
            date_of_purchase: "2020-04-25",
            latest_value: 10000,
            asset_type: "DBB",
            amount_at_cost_text: "₹10,000"
        },
        {
            asset_type_text: "Debt",
            name: "Yayy",
            created: "2020-04-25T23:04:44",
            tag_color: "#264653",
            latest_value_text: "₹1,00,000",
            amount_at_cost_of_purchase: 100000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 709,
            date_of_purchase: "2020-04-25",
            latest_value: 100000,
            asset_type: "DBP",
            amount_at_cost_text: "₹1,00,000"
        },
        {
            asset_type_text: "Stocks",
            name: "Yayay",
            created: "2020-04-25T23:08:19",
            tag_color: "#e76f51",
            latest_value_text: "₹10,000",
            amount_at_cost_of_purchase: 10000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 710,
            date_of_purchase: "2020-04-25",
            latest_value: 10000,
            asset_type: "EQW",
            amount_at_cost_text: "₹10,000"
        },
        {
            asset_type_text: "Stocks",
            name: "Dsggy",
            created: "2020-04-25T23:27:43",
            tag_color: "#e76f51",
            latest_value_text: "₹20,000",
            amount_at_cost_of_purchase: 20000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 711,
            date_of_purchase: "2020-04-25",
            latest_value: 20000,
            asset_type: "EQW",
            amount_at_cost_text: "₹20,000"
        },
        {
            asset_type_text: "Property",
            name: "Sgbv",
            created: "2020-04-25T23:29:29",
            tag_color: "#2a9d8f",
            latest_value_text: "₹25,000",
            amount_at_cost_of_purchase: 25000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 712,
            date_of_purchase: "2020-04-25",
            latest_value: 25000,
            asset_type: "REL",
            amount_at_cost_text: "₹25,000"
        },
        {
            asset_type_text: "Stocks",
            name: "Just adding",
            created: "2020-04-26T00:38:43",
            tag_color: "#e76f51",
            latest_value_text: "₹20,000",
            amount_at_cost_of_purchase: 20000,
            date_of_purchase_formatted: "26 Apr 2020",
            asset_folio_number: "",
            pk: 713,
            date_of_purchase: "2020-04-26",
            latest_value: 20000,
            asset_type: "EGN",
            amount_at_cost_text: "₹20,000"
        },
        {
            asset_type_text: "Property",
            name: "Ajajwwbw",
            created: "2020-04-26T00:55:28",
            tag_color: "#2a9d8f",
            latest_value_text: "₹2,13,616",
            amount_at_cost_of_purchase: 213616,
            date_of_purchase_formatted: "26 Apr 2020",
            asset_folio_number: "",
            pk: 714,
            date_of_purchase: "2020-04-26",
            latest_value: 213616,
            asset_type: "REA",
            amount_at_cost_text: "₹2,13,616"
        }
        ],
        stocks: [
        {
            asset_type_text: "Stocks",
            name: "Test",
            created: "2019-06-06T21:31:02",
            tag_color: "#e76f51",
            latest_value_text: "₹48,188",
            amount_at_cost_of_purchase: 48188,
            date_of_purchase_formatted: "06 Jun 2019",
            asset_folio_number: "",
            pk: 236,
            date_of_purchase: "2019-06-06",
            latest_value: 48188,
            asset_type: "EQS",
            amount_at_cost_text: "₹48,188"
        },
        {
            asset_type_text: "Stocks",
            name: "Gati",
            created: "2020-03-11T16:44:24",
            tag_color: "#e76f51",
            latest_value_text: "₹1,000",
            amount_at_cost_of_purchase: 1000,
            date_of_purchase_formatted: "11 Mar 2020",
            asset_folio_number: "",
            pk: 676,
            date_of_purchase: "2020-03-11",
            latest_value: 1000,
            asset_type: "EQN",
            amount_at_cost_text: "₹1,000"
        },
        {
            asset_type_text: "Stocks",
            name: "Hahai",
            created: "2020-03-11T16:46:37",
            tag_color: "#e76f51",
            latest_value_text: "₹1,00,100",
            amount_at_cost_of_purchase: 100,
            date_of_purchase_formatted: "23 Apr 2020",
            asset_folio_number: "",
            pk: 677,
            date_of_purchase: "2020-04-23",
            latest_value: 100100,
            asset_type: "EQS",
            amount_at_cost_text: "₹100"
        },
        {
            asset_type_text: "Stocks",
            name: "Mutual Fund",
            created: "2020-04-09T12:33:29",
            tag_color: "#e76f51",
            latest_value_text: "₹2,50,000",
            amount_at_cost_of_purchase: 200000,
            date_of_purchase_formatted: "08 Apr 2020",
            asset_folio_number: "",
            pk: 700,
            date_of_purchase: "2020-04-08",
            latest_value: 250000,
            asset_type: "EQS",
            amount_at_cost_text: "₹2,00,000"
        },
        {
            asset_type_text: "Stocks",
            name: "Hello",
            created: "2020-04-25T22:58:29",
            tag_color: "#e76f51",
            latest_value_text: "₹1,00,000",
            amount_at_cost_of_purchase: 100000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 707,
            date_of_purchase: "2020-04-25",
            latest_value: 100000,
            asset_type: "EQS",
            amount_at_cost_text: "₹1,00,000"
        },
        {
            asset_type_text: "Stocks",
            name: "Yayay",
            created: "2020-04-25T23:08:19",
            tag_color: "#e76f51",
            latest_value_text: "₹10,000",
            amount_at_cost_of_purchase: 10000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 710,
            date_of_purchase: "2020-04-25",
            latest_value: 10000,
            asset_type: "EQW",
            amount_at_cost_text: "₹10,000"
        },
        {
            asset_type_text: "Stocks",
            name: "Dsggy",
            created: "2020-04-25T23:27:43",
            tag_color: "#e76f51",
            latest_value_text: "₹20,000",
            amount_at_cost_of_purchase: 20000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 711,
            date_of_purchase: "2020-04-25",
            latest_value: 20000,
            asset_type: "EQW",
            amount_at_cost_text: "₹20,000"
        },
        {
            asset_type_text: "Stocks",
            name: "Just adding",
            created: "2020-04-26T00:38:43",
            tag_color: "#e76f51",
            latest_value_text: "₹20,000",
            amount_at_cost_of_purchase: 20000,
            date_of_purchase_formatted: "26 Apr 2020",
            asset_folio_number: "",
            pk: 713,
            date_of_purchase: "2020-04-26",
            latest_value: 20000,
            asset_type: "EGN",
            amount_at_cost_text: "₹20,000"
        }
        ],
        gold: [
        {
            asset_type_text: "Gold",
            name: "Hello asset",
            created: "2020-02-28T14:07:21",
            tag_color: "#f4a261",
            latest_value_text: "₹2,500",
            amount_at_cost_of_purchase: 2500,
            date_of_purchase_formatted: "28 Feb 2020",
            asset_folio_number: "",
            pk: 660,
            date_of_purchase: "2020-02-28",
            latest_value: 2500,
            asset_type: "GDM",
            amount_at_cost_text: "₹2,500"
        },
        {
            asset_type_text: "Gold",
            name: "Hello asset",
            created: "2020-02-28T14:17:10",
            tag_color: "#f4a261",
            latest_value_text: "₹500",
            amount_at_cost_of_purchase: 500,
            date_of_purchase_formatted: "28 Feb 2020",
            asset_folio_number: "",
            pk: 661,
            date_of_purchase: "2020-02-28",
            latest_value: 500,
            asset_type: "GDB",
            amount_at_cost_text: "₹500"
        },
        {
            asset_type_text: "Gold",
            name: "Gold",
            created: "2020-03-16T12:42:43",
            tag_color: "#f4a261",
            latest_value_text: "₹3,50,000",
            amount_at_cost_of_purchase: 350000,
            date_of_purchase_formatted: "16 Mar 2020",
            asset_folio_number: "",
            pk: 681,
            date_of_purchase: "2020-03-16",
            latest_value: 350000,
            asset_type: "GDB",
            amount_at_cost_text: "₹3,50,000"
        },
        {
            asset_type_text: "Gold",
            name: "Gold",
            created: "2020-04-17T16:23:13",
            tag_color: "#f4a261",
            latest_value_text: "₹12,500",
            amount_at_cost_of_purchase: 12500,
            date_of_purchase_formatted: "17 Apr 2020",
            asset_folio_number: "",
            pk: 704,
            date_of_purchase: "2020-04-17",
            latest_value: 12500,
            asset_type: "GDB",
            amount_at_cost_text: "₹12,500"
        }
        ],
        debt: [
        {
            asset_type_text: "Debt",
            name: "Hello Assetyy",
            created: "2020-02-28T13:55:21",
            tag_color: "#264653",
            latest_value_text: "₹50,000",
            amount_at_cost_of_purchase: 50000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 659,
            date_of_purchase: "2020-04-25",
            latest_value: 50000,
            asset_type: "DBB",
            amount_at_cost_text: "₹50,000"
        },
        {
            asset_type_text: "Debt",
            name: "Hello Assettuu",
            created: "2020-02-28T14:18:27",
            tag_color: "#264653",
            latest_value_text: "₹500",
            amount_at_cost_of_purchase: 500,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 662,
            date_of_purchase: "2020-04-25",
            latest_value: 500,
            asset_type: "DBB",
            amount_at_cost_text: "₹500"
        },
        {
            asset_type_text: "Debt",
            name: "Cash",
            created: "2020-03-26T12:38:43",
            tag_color: "#264653",
            latest_value_text: "₹1,888",
            amount_at_cost_of_purchase: 1888,
            date_of_purchase_formatted: "26 Mar 2020",
            asset_folio_number: "",
            pk: 685,
            date_of_purchase: "2020-03-26",
            latest_value: 1888,
            asset_type: "DBC",
            amount_at_cost_text: "₹1,888"
        },
        {
            asset_type_text: "Debt",
            name: "Cash",
            created: "2020-03-26T12:44:12",
            tag_color: "#264653",
            latest_value_text: "₹1,500",
            amount_at_cost_of_purchase: 1500,
            date_of_purchase_formatted: "26 Mar 2020",
            asset_folio_number: "",
            pk: 686,
            date_of_purchase: "2020-03-26",
            latest_value: 1500,
            asset_type: "DBC",
            amount_at_cost_text: "₹1,500"
        },
        {
            asset_type_text: "Debt",
            name: "Cash",
            created: "2020-04-03T14:26:19",
            tag_color: "#264653",
            latest_value_text: "₹7,50,000",
            amount_at_cost_of_purchase: 750000,
            date_of_purchase_formatted: "03 Apr 2020",
            asset_folio_number: "",
            pk: 687,
            date_of_purchase: "2020-04-03",
            latest_value: 750000,
            asset_type: "DBC",
            amount_at_cost_text: "₹7,50,000"
        },
        {
            asset_type_text: "Debt",
            name: "Cash",
            created: "2020-04-23T09:48:41",
            tag_color: "#264653",
            latest_value_text: "₹15,000",
            amount_at_cost_of_purchase: 15000,
            date_of_purchase_formatted: "23 Apr 2020",
            asset_folio_number: "",
            pk: 706,
            date_of_purchase: "2020-04-23",
            latest_value: 15000,
            asset_type: "DBC",
            amount_at_cost_text: "₹15,000"
        },
        {
            asset_type_text: "Debt",
            name: "Hahha",
            created: "2020-04-25T23:00:13",
            tag_color: "#264653",
            latest_value_text: "₹10,000",
            amount_at_cost_of_purchase: 10000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 708,
            date_of_purchase: "2020-04-25",
            latest_value: 10000,
            asset_type: "DBB",
            amount_at_cost_text: "₹10,000"
        },
        {
            asset_type_text: "Debt",
            name: "Yayy",
            created: "2020-04-25T23:04:44",
            tag_color: "#264653",
            latest_value_text: "₹1,00,000",
            amount_at_cost_of_purchase: 100000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 709,
            date_of_purchase: "2020-04-25",
            latest_value: 100000,
            asset_type: "DBP",
            amount_at_cost_text: "₹1,00,000"
        }
        ],
        property: [
        {
            asset_type_text: "Property",
            name: "Royal Roof Apartments",
            created: "2018-08-31T15:25:39",
            tag_color: "#2a9d8f",
            latest_value_text: "₹31,23,100",
            amount_at_cost_of_purchase: 31231,
            date_of_purchase_formatted: "21 Apr 2020",
            asset_folio_number: "213456",
            pk: 36,
            date_of_purchase: "2020-04-21",
            latest_value: 3123100,
            asset_type: "REA",
            amount_at_cost_text: "₹31,231"
        },
        {
            asset_type_text: "Property",
            name: "Test Asset",
            created: "2019-03-18T13:44:12",
            tag_color: "#2a9d8f",
            latest_value_text: "₹1,23,000",
            amount_at_cost_of_purchase: 12388,
            date_of_purchase_formatted: "14 Apr 2020",
            asset_folio_number: "Gajsbz",
            pk: 177,
            date_of_purchase: "2020-04-14",
            latest_value: 123000,
            asset_type: "REA",
            amount_at_cost_text: "₹12,388"
        },
        {
            asset_type_text: "Property",
            name: "This is New asset",
            created: "2020-02-28T11:44:36",
            tag_color: "#2a9d8f",
            latest_value_text: "₹500",
            amount_at_cost_of_purchase: 500,
            date_of_purchase_formatted: "28 Feb 2020",
            asset_folio_number: "",
            pk: 658,
            date_of_purchase: "2020-02-28",
            latest_value: 500,
            asset_type: "REV",
            amount_at_cost_text: "₹500"
        },
        {
            asset_type_text: "Property",
            name: "Hedddd",
            created: "2020-03-11T15:40:26",
            tag_color: "#2a9d8f",
            latest_value_text: "₹15,555",
            amount_at_cost_of_purchase: 15555,
            date_of_purchase_formatted: "11 Mar 2020",
            asset_folio_number: "",
            pk: 669,
            date_of_purchase: "2020-03-11",
            latest_value: 15555,
            asset_type: "REV",
            amount_at_cost_text: "₹15,555"
        },
        {
            asset_type_text: "Property",
            name: "Na assetttttt",
            created: "2020-03-11T16:27:58",
            tag_color: "#2a9d8f",
            latest_value_text: "₹1,00,000",
            amount_at_cost_of_purchase: 100000,
            date_of_purchase_formatted: "11 Mar 2020",
            asset_folio_number: "",
            pk: 670,
            date_of_purchase: "2020-03-11",
            latest_value: 100000,
            asset_type: "REV",
            amount_at_cost_text: "₹1,00,000"
        },
        {
            asset_type_text: "Property",
            name: "As",
            created: "2020-03-11T16:30:36",
            tag_color: "#2a9d8f",
            latest_value_text: "₹10,000",
            amount_at_cost_of_purchase: 10000,
            date_of_purchase_formatted: "11 Mar 2020",
            asset_folio_number: "",
            pk: 671,
            date_of_purchase: "2020-03-11",
            latest_value: 10000,
            asset_type: "REV",
            amount_at_cost_text: "₹10,000"
        },
        {
            asset_type_text: "Property",
            name: "Hihi",
            created: "2020-03-11T16:33:30",
            tag_color: "#2a9d8f",
            latest_value_text: "₹15,000",
            amount_at_cost_of_purchase: 15000,
            date_of_purchase_formatted: "11 Mar 2020",
            asset_folio_number: "",
            pk: 672,
            date_of_purchase: "2020-03-11",
            latest_value: 15000,
            asset_type: "REV",
            amount_at_cost_text: "₹15,000"
        },
        {
            asset_type_text: "Property",
            name: "Hahahk",
            created: "2020-03-11T16:41:46",
            tag_color: "#2a9d8f",
            latest_value_text: "₹1,000",
            amount_at_cost_of_purchase: 1000,
            date_of_purchase_formatted: "11 Mar 2020",
            asset_folio_number: "",
            pk: 675,
            date_of_purchase: "2020-03-11",
            latest_value: 1000,
            asset_type: "REV",
            amount_at_cost_text: "₹1,000"
        },
        {
            asset_type_text: "Property",
            name: "Dummy asset1",
            created: "2020-04-06T22:36:14",
            tag_color: "#2a9d8f",
            latest_value_text: "₹1,50,000",
            amount_at_cost_of_purchase: 150000,
            date_of_purchase_formatted: "06 Apr 2020",
            asset_folio_number: "",
            pk: 695,
            date_of_purchase: "2020-04-06",
            latest_value: 150000,
            asset_type: "REA",
            amount_at_cost_text: "₹1,50,000"
        },
        {
            asset_type_text: "Property",
            name: "Testin account",
            created: "2020-04-07T09:18:27",
            tag_color: "#2a9d8f",
            latest_value_text: "₹15,000",
            amount_at_cost_of_purchase: 15000,
            date_of_purchase_formatted: "01 Apr 2020",
            asset_folio_number: "718923",
            pk: 696,
            date_of_purchase: "2020-04-01",
            latest_value: 15000,
            asset_type: "REL",
            amount_at_cost_text: "₹15,000"
        },
        {
            asset_type_text: "Property",
            name: "Dummy asset for testing",
            created: "2020-04-07T15:30:06",
            tag_color: "#2a9d8f",
            latest_value_text: "₹7,100",
            amount_at_cost_of_purchase: 7100,
            date_of_purchase_formatted: "07 Apr 2020",
            asset_folio_number: "",
            pk: 698,
            date_of_purchase: "2020-04-07",
            latest_value: 7100,
            asset_type: "REV",
            amount_at_cost_text: "₹7,100"
        },
        {
            asset_type_text: "Property",
            name: "Land at Hyd",
            created: "2020-04-09T12:31:54",
            tag_color: "#2a9d8f",
            latest_value_text: "₹45,00,000",
            amount_at_cost_of_purchase: 3500000,
            date_of_purchase_formatted: "08 Apr 2020",
            asset_folio_number: "45667",
            pk: 699,
            date_of_purchase: "2020-04-08",
            latest_value: 4500000,
            asset_type: "REL",
            amount_at_cost_text: "₹35,00,000"
        },
        {
            asset_type_text: "Property",
            name: "Land",
            created: "2020-04-21T16:39:29",
            tag_color: "#2a9d8f",
            latest_value_text: "₹15,28,000",
            amount_at_cost_of_purchase: 1528000,
            date_of_purchase_formatted: "21 Apr 2020",
            asset_folio_number: "",
            pk: 705,
            date_of_purchase: "2020-04-21",
            latest_value: 1528000,
            asset_type: "REL",
            amount_at_cost_text: "₹15,28,000"
        },
        {
            asset_type_text: "Property",
            name: "Sgbv",
            created: "2020-04-25T23:29:29",
            tag_color: "#2a9d8f",
            latest_value_text: "₹25,000",
            amount_at_cost_of_purchase: 25000,
            date_of_purchase_formatted: "25 Apr 2020",
            asset_folio_number: "",
            pk: 712,
            date_of_purchase: "2020-04-25",
            latest_value: 25000,
            asset_type: "REL",
            amount_at_cost_text: "₹25,000"
        },
        {
            asset_type_text: "Property",
            name: "Ajajwwbw",
            created: "2020-04-26T00:55:28",
            tag_color: "#2a9d8f",
            latest_value_text: "₹2,13,616",
            amount_at_cost_of_purchase: 213616,
            date_of_purchase_formatted: "26 Apr 2020",
            asset_folio_number: "",
            pk: 714,
            date_of_purchase: "2020-04-26",
            latest_value: 213616,
            asset_type: "REA",
            amount_at_cost_text: "₹2,13,616"
        }
        ],
        insurance: []
    }
};

export default class RecentAssets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isAttaching: false,
            recentAssets: [],
            assetName: "",
            assetType: "SEL",
            assetValue: 0,
            valueError: null,
            typeError: null,
            nameError: null
        };
    }

    static navigationOptions = {
        tabBarVisible: false
    };
    
    componentDidMount() {
        if (Platform.OS == "web"){
            amplitude.logEvent("recentAssets",{ path: global.location.href })
          }
          else{
            amplitude.logEvent("recentAssets", { path: this.props.navigation.getState().routeName });
          }
        this._getRecentAssets();
    }

    _getRecentAssets = async () => {
        try{
            this.setState({ isLoading: true})
            const data = { time_period: "6M" }
            const response = await getRecentAssets(data);
            if (response.code == 100) {
                const { all } = response.results;
                all.sort((a, b) => {
                    return (
                        new Date(a.date_of_purchase).getTime() < new Date(b.date_of_purchase).getTime()
                    );
                });
                const recentAssets = all.reduce(function(h, obj) {
                    h[obj.date_of_purchase] = (h[obj.date_of_purchase] || []).concat(obj);
                    return h;
                }, {});
                this.setState({ isLoading: false, recentAssets });
            } else {
                this.setState({ isLoading: false });
            }
        } catch (err) {
            console.log(err)
            this.setState({ isLoading: false})
        }
    }

    assetNameChange = assetName => {
        this.setState({
            assetName
        });
    };
    
    assetTypeChange = (assetType, itemIndex) => {
        this.setState({ assetType });
    };
    
    assetValueChange = assetValue => {
        this.setState({ assetValue });
    };

    addAsset = async () => {
        const { assetName, assetType, assetValue, nameError, typeError, valueError } = this.state;
        // console.log(this.state);
        if (assetName == "" || assetName == null) {
            this.setState({nameError: "Please enter Asset Name"})
            // Snackbar.show({
            //     title: "Please enter Asset Name",
            //     duration: Snackbar.LENGTH_SHORT
            // });
        } else if (assetType == "SEL" || assetType == "") {
            this.setState({nameError: "Please select asset type"})
            // Snackbar.show({
            //     title: "Please select asset type",
            //     duration: Snackbar.LENGTH_SHORT
            // });
        } else if (assetValue == null || assetValue == "" || assetValue == 0) {
            this.setState({nameError: "Please enter a valid amount"})
            // Snackbar.show({
            //     title: "Please enter a valid amount",
            //     duration: Snackbar.LENGTH_SHORT
            // });
        } else {
            try {
                this.setState({ isAttaching: true });
                const data = {
                    name: assetName,
                    type: assetType,
                    latest_value: assetValue,
                    date: "",
                    unique_no: "",
                    amount_at_cost: "",
                    asset_id: ""
                };
                const response = await addAsset(data);
                if ((response.code = 100)) {
                    // logEvent(amplitudeEvents.ADD_ASSETS_ADD_SUCCESS);
                    this.setState({
                        isAttaching: false,
                        assetName: "",
                        assetType: "SEL",
                        assetValue: 0,
                        valueError: null,
                        typeError: null,
                        nameError: null
                    });
                    window.location.reload()
                    // Snackbar.show({
                    //     title: "Asset added successfully !",
                    //     duration: Snackbar.LENGTH_SHORT,
                    //     backgroundColor: "green"
                    // });
                } else {
                    // logEvent(amplitudeEvents.ADD_ASSETS_ADD_ERROR);
                    this.setState({ isAttaching: false });
                    // console.log("unable to add asset")
                    // Snackbar.show({
                    //     title: "Unable to add asset ",
                    //     duration: Snackbar.LENGTH_SHORT,
                    //     backgroundColor: "red"
                    // });
                }
            } catch (err) {
                console.log(err);
                this.setState({ isAttaching: false });
            }
        }
    }

    renderAddAssetForm = () => {
        const {
            assetName,
            assetType,
            assetValue,
            valueError,
            typeError,
            nameError,
            isAttaching
        } = this.state;
        return (
            <>
                <SideHeading
                    title="Add Assets"
                    style={{ fontSize: 20, alignItems: "center" }}
                />
                <View style={localStyles.form}>
                <View style={{ paddingBottom: "10px" }}>
                    <Text style={localStyles.inputLabel}>Asset Name</Text>
                    <TextInput
                        value={assetName}
                        onChangeText={this.assetNameChange}
                        onSubmitEditing={() => {}}
                        style={{
                            fontSize: 14,
                            color: "#121212",
                            padding: 0,
                            margin: 0,
                            backgroundColor: "#fff",
                            height: 40
                        }}
                        keyboardType={"name-phone-pad"}
                    />
                </View>
        
                <Text
                    style={{
                    paddingVertical: 0,
                    marginVertical: 0,
                    paddingLeft: 2,
                    color: "red",
                    fontSize: 10,
                    height: 16
                    }}
                >
                    {nameError || ""}
                </Text>
        
                <View
                    style={{
                    borderBottomWidth: 1,
                    borderColor: "#f8f8f8",
                    paddingBottom: "10px"
                    }}
                >
                    <PickerFieldSelect
                        data={assetTypes}
                        value={assetType}
                        label={"Asset Type"}
                        style={{
                            height: 40,
                            width: "100%",
                            marginLeft: 0,
                            paddingLeft: 0,
                            borderColor: "#fff",
                            borderBottomColor: "#aaa",
                            borderBottomWidth: 1,
                        }}
                        onChange={(itemValue) => this.assetTypeChange(itemValue)}
                        pickerStyle={[{  }]}
                        type="select"
                        placeholder={{
                            label: 'Select',
                            value: "SEL",
                            color: '#9EA0A4',
                        }}
                    />
                    {/* <Picker
                        selectedValue={assetType}
                        style={{
                            height: 40,
                            width: "100%",
                            marginLeft: 0,
                            paddingLeft: 0,
                            borderColor: "#fff",
                            borderBottomColor: "#aaa",
                            borderBottomWidth: 1,

                        }}
                        mode={"dropdown"}
                        onValueChange={this.assetTypeChange}
                    >
                        <Picker.Item label={"Select"} value={"SEL"} style={{}} />
                        {assetTypes &&
                            Object.keys(assetTypes).map(asset => (
                            <Picker.Item label={assetTypes[asset]} value={asset} />
                            ))} 
                    </Picker> */}
                </View>
        
                <Text
                    style={{
                    paddingVertical: 0,
                    marginVertical: 0,
                    paddingLeft: 2,
                    color: "red",
                    fontSize: 10,
                    height: 16
                    }}
                >
                    {typeError || ""}
                </Text>
        
                <View style={{ flexDirection: "row", paddingBottom: 15 }}>
                    <View style={{ flex: 0.7 }}>
                    <Text
                        style={{
                        fontSize: 12,
                        color: "#BCBCBC",
                        paddingBottom: 10
                        }}
                    >
                        Current Asset Value
                    </Text>
                    <View
                        style={{
                        backgroundColor: "#F8F8F8",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingHorizontal: 10,
                        width: "90%",
                        paddingVertical: 0,
                        height: 50
                        }}
                    >
                        <CurrencyFormatInput
                        prefix="₹"
                        value={`${assetValue}`}
                        onValueChange={this.assetValueChange}
                        containerStyle={{
                            lineHeight: 20,
                            width: '100%',
                        }}
                        textStyle={{
                            fontSize: 18,
                            color: "#121212",
                        }}
                        />
                    </View>
                    </View>
                    <View
                    style={{
                        flex: 0.3,
                        justifyContent: "flex-end"
                    }}
                    >
                    <CustButton
                        bgColor={"#410DAA"}
                        textColor={"#fff"}
                        style={{
                        width: "100%",
                        height: 50
                        }}
                        buttonText={"Add"}
                        onPress={this.addAsset}
                        borderColor={"transparent"}
                        buttonTextStyle={{
                        fontSize: 14
                        }}
                        isLoading={isAttaching}
                    />
                    </View>
                </View>
                <Text
                    style={{
                    paddingVertical: 0,
                    marginVertical: 0,
                    paddingLeft: 2,
                    color: "red",
                    fontSize: 10,
                    height: 16
                    }}
                >
                    {valueError || ""}
                </Text>
                </View>
            </>
        );
    };

    render() {
        const { isLoading, recentAssets } = this.state;
        return (
            <SafeAreaView style={{ flex: 1, backgroundColor: "#fff", maxWidth: 800 }}>
                <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
                <NavigationHeader navigation={this.props.navigation} navigationHeader={["Back"]} onPressBack={()=>{this.props.navigation.navigate("Home")}} />
                <ScrollView
                contentContainerStyle={{
                    // flex: 1,
                    flexGrow: 1
                }}
                showsVerticalScrollIndicator={true}
                keyboardShouldPersistTaps={"handled"}
                >
                {this.renderAddAssetForm()}
                <ViewSeparator></ViewSeparator>
                <View style={{ padding: 16 }}>
                    <Text style={{ fontSize: 14, color: "#707070", paddingBottom: 15 }}>
                    Recently Added
                    </Text>
                    {isLoading ? (
                    <ActivityIndicator size="small" color="#410DAA" />
                    ) : (
                    recentAssets &&
                    Object.keys(recentAssets).map((date, index) => {
                        return (
                        <View style={{ paddingVertical: 8 }} key={index}>
                            <Text style={{ fontSize: 12, color: "#707070" }}>
                            {date}
                            </Text>
                            <View
                            style={{
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "flex-start",
                                justifyContent: "flex-start"
                                // paddingTop: 10
                            }}
                            >
                            {recentAssets[date].map((item, i) => {
                                return (
                                <View
                                    style={{
                                    borderRadius: 15,
                                    borderColor: "#BCBCBC",
                                    borderWidth: 1,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingVertical: 9,
                                    paddingHorizontal: 13,
                                    marginRight: 10,
                                    marginTop: 10
                                    }}
                                >
                                    <Text style={{ color: "#121212", fontSize: 11 }}>
                                    {item.name}
                                    </Text>
                                </View>
                                );
                            })}
                            </View>
                        </View>
                        );
                    })
                    )}
                </View>
                </ScrollView>
            </SafeAreaView>
        )
    }
}

const localStyles = StyleSheet.create({
    form: { marginHorizontal: 16, paddingTop: 15, paddingBottom: 25 },
    formItem: {
        borderColor: "#F8F8F8",
        borderWidth: 1,
        padding: 0,
        margin: 0,
        width: "100%",
        height: 40
    },
    inputContainer: {
        flex: 1,
        flexDirection: "row",
        margin: 0,
        padding: 0,
        alignItems: "flex-end",
        justifyContent: "flex-start",
        marginBottom: 0,
        paddingBottom: 0,
        alignSelf: "flex-start"
    },
    inputLabel: {
        color: "#BCBCBC",
        fontSize: 13,
        marginVertical: 0,
        paddingVertical: 0
        // backgroundColor: "red"
    },
    errorText: {
        color: "red",
        fontSize: 10,
        height: 16
    }
});
  