import React from "react";
import {
    StyleSheet,
    Text,
    View,
    Image,
    ScrollView,
    Dimensions,
    TouchableOpacity,
    Platform,
    ActivityIndicator,
} from "react-native";
import AntDesign from "@expo/vector-icons/AntDesign";
import Ionicons from "@expo/vector-icons/Ionicons";
import amplitude from "../../utils/amplitude";
import FontAwesome from "@expo/vector-icons/FontAwesome";
const { width } = Dimensions.get("window");
import Toast from "react-native-toast-message";
import { md } from "../../Responsive";
import Header from "./header_after_login";
import {
    completeMinDetails, isMobile,
    navigateToTransaction,
} from "../../utils";
import Modal from "./modal";
import Store from "../../store";
import { createCrmdealsCipForRegular, updateCustomerGoalValues } from "../../api";
const Donut =
    Platform.OS == "web"
        ? require("react-apexcharts").default
        : require("react-native-pie-chart").default;

export default class MiniOnboardingRiskProfile extends React.Component {
    constructor(props) {
        super(props);
        const { result, from_page } = this.props;
        let pie_result = result;
        this.state = {
            data: null,
            img_url: "null",
            showAlert: false,
            pie_result,
            ...result,
            box_color: null,
            btnLoading: false,
            name: null,
            description: null,
            from_page
        };
    }

    async componentDidMount() {
        if (Platform.OS == "web"){
            amplitude.logEvent("MiniOnboardingRiskProfile",{ path: global.location.href })
          }
          else{
            amplitude.logEvent("MiniOnboardingRiskProfile", { path: this.props.navigation.getState().routeName });
          }
        let { risk_profile } = this.state;
        try {
            await updateCustomerGoalValues();
            let { user_database } = Store.getState().userData.userProfileData;
            let hostname = Platform.OS == "web" ? global.location.hostname : user_database;
            if (hostname.includes("club")) {
                await createCrmdealsCipForRegular();
            }
            if (risk_profile == "VCN") {
                this.setState({
                    img_url:
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23977.svg",
                    box_color: "ECE7F7",
                    name: "Very Conservative",
                    description:
                        "Highy conservative investors want to avoid loss of money.They do not wish to take any risk to earn higher returns.",
                });
            } else if (risk_profile == "CON") {
                this.setState({
                    img_url:
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23968.svg",
                    box_color: "#C6B6E6",
                    name: "Conservative",
                    description:
                        "Consevative Investors wish to protect Investments.They have some concern on inflation and wish to make atleast inflation beating returns.",
                });
            } else if (risk_profile == "MOD") {
                this.setState({
                    img_url:
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23969.svg",
                    box_color: "#A086D5",
                    name: "Moderate",
                    description:
                        "Moderate Investors seek to protect capital, while investing in suitable growth opportunities.",
                });
            } else if (risk_profile == "MAG") {
                this.setState({
                    img_url:
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23970.svg",
                    box_color: "#7A56C4",
                    name: "Moderately aggressive",
                    description:
                        "Aggressive investors are willing to take some risk for additional growth in capital. they are willing to take some short term volatility if it gives higher growth.",
                });
            } else if (risk_profile == "VAG") {
                this.setState({
                    img_url:
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/Group+23971.svg",
                    box_color: "#410DAA",
                    name: "Very Aggressive",
                    description:
                        "Very aggressive investors wants to achieve the maximum possible returns. They are not worried too much about losing capital in short term.",
                });
            }
        } catch (error) { }
    }

    handleContinue = async () => {
        try {
            this.setState({btnLoading: true})
            Toast.hide();
            this.setState({btnLoading: false})
            if (this.state.from_page == "Goals") {
                this.props.navigation.replace("App", {
                    screen: "Goals",
                    params: {
                        screen: "Goals",
                        params: {}
                    }
                });    

            } else {
                this.props.navigation.navigate("App", {
                    screen: "Dashboard",
                    params: {
                        show_card_view: true
                    }
                });    
            }
        } catch (error) { 
            console.log("error is ", error)
        }
    };

    getDescription = (key) => {
        if (key) key = key.trim();
        if (key == "Very Conservative") {
            return "Highy conservative investors want to avoid loss of money.They do not wish to take any risk to earn higher returns.";
        } else if (key == "Conservative") {
            return "Consevative Investors wish to protect Investments.They have some concern on inflation and wish to make atleast inflation beating returns.";
        } else if (key == "Moderate") {
            return "Moderate Investors seek to protect capital, while investing in suitable growth opportunities";
        } else if (key == "Moderately aggressive") {
            return "Aggressive investors are willing to take some risk for additional growth in capital. they are willing to take some short term volatility if it gives higher growth.";
        } else if (key == "Very Aggressive") {
            return "Very aggressive investors wants to achieve the maximum possible returns. They are not worried too much about losing capital in short term.";
        } else {
            return "";
        }
    };

    handleModalOpen = () => {
        this.setState({ showAlert: true });
    };
    render() {
        const {
            risk_profile,
            img_url,
            showAlert,
            modified,
            box_color,
            name,
            description,
            pie_result
        } = this.state;
        const { onRevise } = this.props;
        return (
            <View style={styles.container}>
                <Header navigation={this.props.navigation} route={this.props.route} showbackBtn={false}/>
                {showAlert && (
                    <Modal
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <View style={{ paddingBottom: 3, width: md(width) ? 691 : "100%" }}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    paddingHorizontal: 10,
                                    paddingVertical: 10,
                                    backgroundColor: "rgba(65, 13, 170, 0.1)",
                                }}
                            >
                                <Text style={styles.sig}>Risk Profile & Significance</Text>
                                <TouchableOpacity
                                    onPress={() => {
                                        this.setState({ showAlert: false });
                                    }}
                                >
                                    <AntDesign name="close" size={24} color="black" />
                                </TouchableOpacity>
                            </View>
                            <View
                                style={{
                                    paddingHorizontal: 44,
                                    flexDirection: "column",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <View style={styles.infoBoxContainer}>
                                    <Text style={styles.high}>
                                        Highy conservative investors want to avoid loss of
                                        money.They do not wish to take any risk to earn higher
                                        returns.
                                    </Text>
                                    <TouchableOpacity style={styles.infoBox}>
                                        <Text style={[styles.btn1, { backgroundColor: "#ECE7F7" }]}>
                                            Very Conservative
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={styles.infoBoxContainer}>
                                    <Text style={styles.high}>
                                        Conservative Investors wish to protect investments.They have
                                        some concern on inflation & wish make atleast
                                        inflation-beating returns.
                                    </Text>
                                    <TouchableOpacity style={styles.infoBox}>
                                        <Text style={[styles.btn1, { backgroundColor: "#C6B6E6" }]}>
                                            Conservative
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={styles.infoBoxContainer}>
                                    <Text style={styles.high}>
                                        Moderate Investors seek to protect capital, while investing
                                        in suitable growth opportunities
                                    </Text>
                                    <TouchableOpacity style={styles.infoBox}>
                                        <Text style={[styles.btn, { backgroundColor: "#A086D5" }]}>
                                            Moderate
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={styles.infoBoxContainer}>
                                    <Text style={styles.high}>
                                        Aggressive investors are willing to take some risk for
                                        additional growth in capital. they are willing to take some
                                        short term volatility if it gives higher growth.
                                    </Text>
                                    <TouchableOpacity style={styles.infoBox}>
                                        <Text style={[styles.btn, { backgroundColor: "#7A56C4" }]}>
                                            Moderately aggressive
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={styles.infoBoxContainer}>
                                    <Text style={styles.high}>
                                        Very aggressive investors wants to achieve the maximum
                                        possible returns. They are not worried too much about losing
                                        capital in short term.
                                    </Text>
                                    <TouchableOpacity style={styles.infoBox}>
                                        <Text style={[styles.btn, { backgroundColor: "#410DAA" }]}>
                                            Very Aggressive
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </Modal>
                )}
                <ScrollView style={styles.mainContainer}>
                    <View style={styles.body}>
                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: '70%',
                                marginTop: 20
                            }}
                        >
                            <Text style={styles.ris_prf}>Your Risk Profile</Text>
                            <TouchableOpacity onPress={onRevise}>
                                <Text style={styles.revise}>
                                    <Ionicons name="reload" size={14} color="#410daa" />{" "}
                                    Revise Assessment
                                </Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.right}>
                            <View style={styles.body_styl}>
                                {risk_profile && (
                                    <View style={{ alignItems: "center", justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                                        <View>
                                            <Image source={{ uri: img_url }} style={styles.image} />

                                            <Text style={[styles.con]}>
                                                <FontAwesome
                                                    name="square"
                                                    size={15}
                                                    color={box_color}
                                                    style={{ paddingRight: 6 }}
                                                />
                                                {name}
                                                <TouchableOpacity
                                                    mode="text"
                                                    uppercase={false}
                                                    onPress={this.handleModalOpen}
                                                >
                                                    <Image
                                                        source="https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/help+1.png"
                                                        style={{
                                                            width: 19,
                                                            height: 17,
                                                            resizeMode: "contain",
                                                            marginLeft: 6,
                                                        }}
                                                    />
                                                </TouchableOpacity>{" "}
                                                {"\n"}
                                                <Text style={styles.taken}>Taken on: {modified}</Text>
                                            </Text>
                                        </View>

                                    </View>
                                )}
                                <Text style={styles.consevation}>{description}</Text>
                            </View>
                            <View style={styles.body_styl}>
                                <View
                                    style={{
                                        alignItems: "flex-end",
                                        flexDirection: "column",
                                        marginBottom: 50,
                                        marginTop: 30
                                    }}
                                >
                                    {Platform.OS == "web" ? (
                                        <View
                                            style={{
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <Donut
                                                options={{
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    legend: {
                                                        show: false,
                                                    },
                                                    tooltip: { enabled: false },
                                                    colors: ["#876CDD", "#BAC7F2", "#6FE3D4", "#77ff33"],
                                                    plotOptions: {
                                                        pie: {
                                                            donut: {
                                                                size: "60%",
                                                            },
                                                        },
                                                    },
                                                    stroke: {
                                                        width: 1,
                                                        colors: ["#876CDD", "#BAC7F2", "#6FE3D4", "#77ff33"],
                                                    },
                                                }}
                                                series={[pie_result.balanced_percentage, pie_result.debt_percentage, pie_result.equity_percentage, pie_result.gold_percentage]}
                                                sliceColor={["#876CDD", "#BAC7F2", "#6FE3D4", "#77ff33"]}
                                                type="donut"
                                                width="210"
                                            />
                                        </View>
                                    ) : (
                                        <Donut
                                            widthAndHeight={100}
                                            series={[pie_result.balanced_percentage, pie_result.debt_percentage, pie_result.equity_percentage, pie_result.gold_percentage]}
                                            sliceColor={["#876CDD", "#BAC7F2", "#6FE3D4", "#77ff33"]}
                                            doughnut={true}
                                            coverRadius={0.6}
                                            coverFill={"#FFF"}
                                        />
                                    )}
                                </View>
                                <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <View style={{ width: '48.5%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                        <Text style={[styles.con]}>
                                            <FontAwesome
                                                name="square"
                                                size={15}
                                                color={"#876CDD"}
                                                style={{ paddingRight: 6 }}
                                            />
                                            Balanced : {pie_result.balanced_percentage} %
                                        </Text>
                                        <Text style={[styles.con]}>
                                            <FontAwesome
                                                name="square"
                                                size={15}
                                                color={"BAC7F2"}
                                                style={{ paddingRight: 6 }}
                                            />
                                            Debt : {pie_result.debt_percentage} %
                                        </Text>
                                    </View>
                                    <View style={{ width: '48.5%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                        <Text style={[styles.con]}>
                                            <FontAwesome
                                                name="square"
                                                size={15}
                                                color={"#6FE3D4"}
                                                style={{ paddingRight: 6 }}
                                            />
                                            Equity : {pie_result.equity_percentage} %
                                        </Text>
                                        <Text style={[styles.con]}>
                                            <FontAwesome
                                                name="square"
                                                size={15}
                                                color={"#77ff33"}
                                                style={{ paddingRight: 6 }}
                                            />
                                            Gold : {pie_result.gold_percentage} %
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={{ width: '90%', display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
                            <View style={{ width: '33%', justifyContent: 'center', alignItems: 'center' }}>
                                <TouchableOpacity onPress={() => this.props.navigation.navigate("SignUpImportPortfolio", { from: "mini_risk_profile" })}>
                                    <Text style={{ color: '#410DAA', fontWeight: '700', textDecorationLine: 'underline' }}>Get Free Portfolio Analysis</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={{ width: '33%', justifyContent: 'center', alignItems: 'center' }}>
                                <TouchableOpacity onPress={() => this.props.navigation.navigate("SignUpFinancialPlan")}>
                                    <Text style={{ color: '#410DAA', fontWeight: '700', textDecorationLine: 'underline' }}>Start Free Financial Plan</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={{ width: '33%', justifyContent: 'center', alignItems: 'center' }}>
                                <TouchableOpacity onPress={() => this.props.navigation.navigate("ScheduleCall", {
                                    from_risk: true
                                })}>
                                    <Text style={{ color: '#410DAA', fontWeight: '700', textDecorationLine: 'underline' }}>Book appointment with Advisor</Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <TouchableOpacity
                            onPress={() => {
                                this.handleContinue();
                            }}
                            style={{
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: 20.7,
                                marginBottom: isMobile ? 100 : 50,
                            }}
                        >
                            { this.state.btnLoading ? (<ActivityIndicator size={"small"} color={"#410DAA"} />) : (<Text style={styles.next}>Continue</Text>)}
                        </TouchableOpacity>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: { width: "100%", flexGrow: 1, backgroundColor: "#fff" },
    mainContainer: {
        flex: 1,
        width: '100%',
        maxHeight : Dimensions.get("window").height
    },
    body: {
        backgroundColor: "#ffffff",
        justifyContent: "center",
        alignItems: "center",
    },
    body_styl: {
        borderWidth: md(width) ? 0.5 : 0,
        borderColor: "solid rgba(65, 13, 170, 0.1)",
        borderRadius: 12,
        paddingHorizontal: md(width) ? 28 : 17,
        paddingVertical: 10,
        shadowRadius: md(width) ? 1 : 0,
        marginHorizontal: 25,
        width: isMobile ? '100%' : '60%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    right: {
        paddingBottom: md(width) ? 50 : 120,
        paddingTop: 50,
        width: md(width) ? 688 : "100%",
        display: 'flex',
        flexDirection:  isMobile ? "column" : 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    left_img: {
        resizeMode: "contain",
        width: 378,
        height: 325,
    },
    lock: {
        resizeMode: "contain",
        width: 24.5,
        height: 34,
        alignSelf: "center",
    },
    terms: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 22,
        lineHeight: 25,
        color: "#2F3044",
    },
    please: {
        fontFamily: "Roboto",
        fontSize: 16,
        lineHeight: 18,
        color: "#464E5F",
        paddingTop: 11,
        paddingBottom: 32,
        paddingRight: 155,
    },
    jam_we: {
        fontFamily: "Roboto",
        fontSize: 13,
        lineHeight: 15,
        color: "#464E5F",
        paddingLeft: 16,
    },
    any: {
        fontFamily: "Roboto",
        fontSize: 16,
        lineHeight: 18,
        color: "#000000",
        paddingLeft: 150,
    },
    revise: {
        fontFamily: "Roboto",
        fontSize: 14,
        lineHeight: 23,
        color: "#410DAA",
    },
    consevation: {
        fontFamily: "Roboto",
        fontSize: 14,
        lineHeight: 23,
        color: "#121212",
        textAlign: "center",
    },
    next: {
        fontFamily: "Roboto",
        fontSize: 15,
        lineHeight: 17,
        color: "#ffffff",
        fontWeight: "bold",
        paddingVertical: 12,
        paddingHorizontal: 63,
        backgroundColor: "#410DAA",
        borderRadius: 4,
    },
    ris_prf: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 15,
        lineHeight: 17,
        color: "#222225",
    },
    con: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 17,
        lineHeight: 21,
        textAlign: "center",
        paddingBottom: 40,
    },
    cons: {
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: 14,
        color: "#000000",
    },
    taken: {
        fontFamily: "Roboto",
        fontSize: 13,
        lineHeight: 10,
        color: "#000000",
        paddingBottom: 20,
        textAlign: "center",
    },
    sig: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 16,
        lineHeight: 18,
        color: "#121212",
    },
    btn: {
        borderRadius: 6,
        fontFamily: "Roboto",
        fontSize: isMobile ? 9 : 12,
        lineHeight: 14,
        fontWeight: "bold",
        color: "#ffffff",
        paddingVertical: 8.5,
        minWidth: isMobile ? 100 : 137,
        textAlign: "center",
    },
    btn1: {
        borderRadius: 6,
        fontFamily: "Roboto",
        fontSize: isMobile ? 9 : 12,
        lineHeight: 14,
        fontWeight: "bold",
        color: "#410daa",
        paddingVertical: 8.5,
        minWidth: isMobile ? 100 : 137,
        textAlign: "center",
    },
    high: {
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: 14,
        color: "#464E5F",
        paddingRight: 55,
    },
    image: {
        width: 292,
        height: 292,
        marginBottom: -80,
        marginTop: 30,
    },
    infoBoxContainer: {
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        paddingVertical: 14,
    },
    infoBox: {
        minWidth: isMobile ? 100 : 137,
        justifyContent: "center",
        alignItems: "center",
    },
});
