import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";

export const StatusIndicator = ({
  heading,
  percentage,
  activeColor = "#EFA512",
  height = 8,
  style,
}) => {
  percentage = Math.round(percentage);
  return (
    <View style={{ marginBottom: 20, paddingHorizontal: 16, ...style, justifyContent: 'center', alignItems: 'center' }}>
      {heading && (
        <Text
          style={{
            fontSize: 16,
            fontWeight: "700",
            paddingTop: 15,
            color: "#121212",
            textAlign: "left",
          }}
        >
          {heading}
        </Text>
      )}
      <View
        style={{
          flexDirection: "row",
          marginTop: 12,
          width: "100%",
          alignSelf: "center",
        }}
      >
        <View
          style={{
            width: `${percentage.toFixed(1)}%`,
            backgroundColor: activeColor,
            height,
            borderColor: activeColor,
            borderWidth: 1,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          }}
        ></View>
        <View
          style={{
            width: `${100 - percentage.toFixed(1)}%`,
            backgroundColor: "#ece7f6",
            height,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            borderColor: "#c9bce7",
            borderWidth: 1,
          }}
        ></View>
      </View>
      <View
        style={{
          width: "100%",
        }}
      ></View>
      <Text
        style={{
          fontWeight: "bold",
          fontSize: 16,
          left: -5,
          marginLeft: -20,
          marginTop: 5,
          color: "#410DAA",
        }}
      >
        {percentage.toFixed(1)}%
      </Text>
    </View>
  );
};
