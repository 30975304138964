import React from "react";
import amplitude from "../../../utils/amplitude";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { md } from "../../../Responsive";
import NavigationHeader from "../../Common/NavigationHeader";
import { getInvestorAccountFundDetails, getMFDetails, getUserFundTransaction } from "../../../api";
import { getUserDetails, isMobile } from "../../../utils";
import { Entypo } from '@expo/vector-icons';
const { width } = Dimensions.get("window");
const isWeb = md(width);
import { StackActions } from "@react-navigation/routers";
import Store from "../../../store";

const FundField = ({ style, name, value, tooltip }) => {
  return (
    <View style={{ ...style }}>
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            fontSize: 12,
            color: "#6C7293",
            fontWeight: "600",
          }}
        >
          {name}
        </Text>
        {tooltip && (
          <ANTIcon
            name="infocirlce"
            color={"#a8a8a8"}
            size={12}
            style={{
              paddingLeft: 10,
            }}
          />
        )}
      </View>
      <Text
        style={{
          fontSize: 12,
          color: "#464E5F",
          fontWeight: "700",
          paddingTop: 5,
        }}
      >
        {value}
      </Text>
    </View>
  );
};

const Divider = ({ color, width, style }) => {
  return (
    <View
      style={[
        {
          borderBottomColor: color ? color : "black",
          borderBottomWidth: width ? width : 1,
        },
        style,
      ]}
    />
  );
};

export default class pendinginstallments extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let userId = null;
    let fundPK = null;
    let fundType = null;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      fundPK = params.fund;
      fundType = params.fundType || null;
    }

    const { is_ria } = getUserDetails();
    this.state = {
      userId,
      fundPK,
      fundType,
      fund_amc_id: null,
      is_ria,
      activeTabIndex: 0,
      show_options: false,
      isLoading: true,
      filters: [],
      isJamaMF: true,
      mfTxnDetails: [],
      mfSelectedFund: [],
      isSwitchOn: false,
      pkValue: fundPK,
      selectedAction: "Start_SIP",
      amcfundscheme__bse_sip_flag: "Y",
      amcfundscheme__bse_stp_flag: "Y",
      amcfundscheme__bse_swp_flag: "Y",
      amcfundscheme__bse_switch_flag: "Y"
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("investmenthistory",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("investmenthistory", { path: this.props.navigation.getState().routeName });
      }
      let newArr = [];
      let newArrTxn = [];
      const { fundPK } = this.state;
      let userId =
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
          ? Store.getState().dashboard.viewModeUserId.user_id
          : null;
      let response = null;
      const payload = {
        iaf_id: fundPK,
      };
        response = await getInvestorAccountFundDetails(payload);
 

      if (
        response.code == "100" &&
        response.result
      ) {

        let selectedFund = response.result;

        this.setState({
          mfSelectedFund: [response.result],
          fundPK: response.result.amcfundscheme__AMCFund__pk,
          fund_amc_id: response.result.amcfundscheme__pk,
          isJamaMF: true,
          amcfundscheme__bse_sip_flag: selectedFund.amcfundscheme__bse_sip_flag,
          amcfundscheme__bse_stp_flag: selectedFund.amcfundscheme__bse_stp_flag,
          amcfundscheme__bse_swp_flag: selectedFund.amcfundscheme__bse_swp_flag,
          amcfundscheme__bse_switch_flag: selectedFund.amcfundscheme__bse_switch_flag
        });

        if (true) {
          const responseFundTransaction = await getUserFundTransaction(
            selectedFund.investoraccountfund_id
          );

          if (
            responseFundTransaction.code == "100" &&
            responseFundTransaction.results
          ) {
            for (
              let i = 0;
              i < responseFundTransaction.results.length;
              i += 2
            ) {
              newArrTxn.push(responseFundTransaction.results.slice(i, i + 2));
            }

            this.setState({
              mfTxnDetails: responseFundTransaction.results,
              isLoading: false,
            });
          }
        } else {
          this.setState({
            isLoading: false,
          });
        }
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText:
            response && response.msg ? response.msg : "Something went wrong!",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  setModalMethod = (method) => {
    this.openOrCloseModal = method;
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  transactionStatus = (transactionType) => {
    let type = "";
    switch (transactionType) {
      case "P":
        type = "Purchase";
        break;

      case "R":
        type = "Redemption";
        break;

      case "SI":
        type = "Switch In";
        break;

      case "SO":
        type = "Switch Out";
        break;

      default:
        break;
    }
    return type;
  };

  months_full = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  months_short_names = (index, full = false) => {
    if (!full) {
      return index <= 12 ? this.months[index - 1] : null;
    } else return index <= 12 ? this.months_full[index - 1] : null;
  };

  dateFormat = (date, delimeter = "-") => {
    let values = date.split(`${delimeter}`);
    return (
      values[2] +
      "-" +
      this.months_short_names(parseInt(values[1])) +
      "-" +
      values[0]
    );
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  render() {
    const { error, errorText, isLoading, is_ria } = this.state;
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View style={{ flex: 1, maxWidth: 1500 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={[
              "Mutual Funds Overview",
              "Mutual Funds",
              "Investment History",
            ]}
          />
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <ActivityIndicator size="large" color="#410DAA" />
            ) : error ? (
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText || "Something went wrong!"}
              </Text>
            ) : (
              <ScrollView style={[styles.mainContainer]}>
                <View
                  style={{ paddingBottom: 40, marginTop: isMobile ? -3 : -17 }}
                >
                  {this.state.mfSelectedFund.map((item, index) => {
                    return (
                      <View
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 17,
                          width: isMobile ? "90%" : "40%",
                          marginLeft: isMobile ? "5%" : "30%",
                          marginRight: isMobile ? "5%" : "30%",
                        }}
                      >
                        <View
                          style={{
                            flex: 10,
                            borderRadius: 10,
                            borderWidth: 1,
                            borderColor: "#410DAA1A",
                          }}
                        >
                          <View
                            style={{
                              padding: 10,
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <View style={{ flex: 0.05 }}>
                              <Image
                                source={{ uri: item.amc_logo }}
                                resizeMode="contain"
                                style={{
                                  height: 30,
                                  width: 30,
                                  alignSelf: "flex-start",
                                  borderWidth: 1,
                                  borderColor: "#f2f2f2",
                                  borderRadius: 5,
                                }}
                              />
                            </View>
                            <View
                              style={{ flex: isMobile ? 0.05 : 0.01 }}
                            ></View>
                            <View style={{ flex: 0.84, paddingLeft: 35 }}>
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.amcfundscheme__name}
                              </Text>
                            </View>
                            <View style={{ flex: 0.1 }}></View>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              paddingHorizontal: 10,
                              paddingBottom: 10,
                              paddingTop: 6,
                            }}
                          >
                            <FundField
                              style={{ flex: 0.33, alignItems: "flex-start" }}
                              name="Category"
                              value={
                                item.amcfundscheme__AMCFund__fund_class
                                  ? item.amcfundscheme__AMCFund__fund_class.split(
                                      "-"
                                    )[0]
                                  : "-"
                              }
                            />
                            <FundField
                              style={{ flex: 0.33, alignItems: "center" }}
                              name="Units Held"
                              value={
                                item.type == "Jama"
                                  ? item.unit_balance.toFixed(3)
                                  : item.imported_units.toFixed(3)
                              }
                            />
                            <FundField
                              style={{ flex: 0.34, alignItems: "flex-end" }}
                              name="Latest NAV"
                              value={`${item.amcfundscheme__latest_nav}`}
                            />
                          </View>
                          <Divider color="#410DAA1A" width={0.5} />
                          <View
                            style={{
                              paddingHorizontal: 10,
                              paddingVertical: 4,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                paddingVertical: 9,
                              }}
                            >
                              <FundField
                                style={{
                                  flex: 0.33,
                                  alignItems: "flex-start",
                                }}
                                name="Invested"
                                value={"₹" + item.amount_at_cost.toFixed(0)}
                              />
                              <FundField
                                style={{ flex: 0.33, alignItems: "center" }}
                                name="Gain"
                                value={"₹" + item.gain.toFixed(0)}
                              />
                              <FundField
                                style={{ flex: 0.34, alignItems: "flex-end" }}
                                name="Latest NAV Date"
                                value={`${item.amcfundscheme__latest_nav_date}`}
                              />
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                paddingVertical: 9,
                              }}
                            >
                              <FundField
                                style={{
                                  flex: 0.33,
                                  alignItems: "flex-start",
                                }}
                                name="Abs. Gain%"
                                value={item.absolute_gain + "%"}
                              />
                              <FundField
                                style={{ flex: 0.33, alignItems: "center" }}
                                name="Folio No."
                                value={
                                  item.type == "Jama"
                                    ? item.folio_number
                                    : item.imported_folio_number
                                }
                              />
                              <FundField
                                style={{ flex: 0.34, alignItems: "flex-end" }}
                                name="Avg. Duration"
                                value={item.avg_duration}
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    );
                  })}

                  {is_ria ? null : (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        zIndex: 9999,
                      }}
                    >
                      {this.state.fundType == "Jama" ? (
                        <>
                          <View
                            style={{
                              flex: 1,
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <TouchableOpacity
                            style={{
                              marginTop: 25,
                              width: isMobile ? "60%" : "14%",
                              marginLeft: isMobile ? "20%" : "52%",
                              marginRight: 30
                            }}
                              onPress={() => {
                                this.props.navigation.navigate("App", {
                                  screen: "Dashboard",
                                  params: {
                                    screen: "Explore",
                                    params: {
                                      screen: "FundDescription",
                                      params: {
                                        fund: this.state.fundPK,
                                        add_invt: "Y",
                                        fund_amc_id: this.state.fund_amc_id
                                      },
                                    },
                                  },
                                });
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "bold",
                                  fontSize: isWeb ? 15 : 13,
                                  color: "#FFFFFF",
                                  backgroundColor: "#410DAA",
                                  borderColor: "#410DAA",
                                  borderWidth: 1,
                                  borderRadius: 4,
                                  paddingVertical: 9,
                                  textAlign: "center",
                                }}
                              >
                                Invest Now
                              </Text>
                            </TouchableOpacity>
                            {this.state.show_options && (<View 
                            style={{
                              width: 150,
                              backgroundColor: "white",
                              position: 'absolute',
                              marginLeft: isMobile ? '50%' : '56%',
                              marginTop: 60,
                              zIndex: 100,
                              borderWidth: 0.5,
                              borderRadius: 5,
                              borderColor: '#410DAA1A'
                            }}>
                              <TouchableOpacity 
                                onPress={() => {
                                  this.setState({ show_options: false })
                                  this.props.navigation.navigate("Redeem", {
                                    fund: this.state.pkValue,
                                    type: "redeem"
                                  });
                                }}
                                style={{
                                  borderBottomWidth: 1,
                                  borderColor: '#410DAA1A',
                                  justifyContent: 'center', 
                                  padding: 10
                                }}>
                                <Text style={{color: '#666666', fontWeight: '500'}}>Redeem</Text>
                              </TouchableOpacity>
                              { this.state.amcfundscheme__bse_swp_flag == "Y" && (<TouchableOpacity 
                                onPress={() => {
                                  this.setState({ show_options: false })
                                  this.props.navigation.navigate("Redeem", {
                                    fund: this.state.pkValue,
                                    type: "swp"
                                  });
                                }}
                                style={{
                                  borderBottomWidth: 1,
                                  borderColor: '#410DAA1A', 
                                  justifyContent: 'center', 
                                  padding: 10
                                }}>
                                <Text style={{color: '#666666', fontWeight: '500'}}>SWP</Text>
                              </TouchableOpacity>)}
                              { this.state.amcfundscheme__bse_stp_flag == "Y" && (<TouchableOpacity 
                                onPress={() => {
                                  this.setState({ show_options: false })
                                  this.props.navigation.navigate("Redeem", {
                                    fund: this.state.pkValue,
                                    type: "stp"
                                  });
                                }}
                                style={{
                                  borderBottomWidth: 1,
                                  borderColor: '#410DAA1A', 
                                  justifyContent: 'center', 
                                  padding: 10
                                }}>
                                <Text style={{color: '#666666', fontWeight: '500'}}>STP</Text>
                              </TouchableOpacity>)}
                              { this.state.amcfundscheme__bse_switch_flag  == "Y" && (<TouchableOpacity 
                              onPress={() => {
                                this.setState({ show_options: false })
                                this.props.navigation.navigate("Redeem", {
                                  fund: this.state.pkValue,
                                  type: "switch"
                                });
                              }}
                                style={{
                                  borderBottomWidth: 1,
                                  borderColor: '#410DAA1A', 
                                  justifyContent: 'center', 
                                  padding: 10
                                }}>
                                <Text style={{color: '#666666', fontWeight: '500'}}>Switch</Text>
                              </TouchableOpacity>)}
                            </View>)}
                            <TouchableOpacity
                              onPress={()=>{
                                this.setState({show_options: !this.state.show_options})
                              }}
                              style={{
                                marginTop: 25,
                                paddingVertical: 9,
                              }}>
                              <Entypo name="dots-three-vertical" size={24} color="black" />
                            </TouchableOpacity>
                          </View>
                          {/* <View
                            style={{
                              flex: 1,
                            }}
                          >
                            <TouchableOpacity
                              onPress={() => {
                                this.props.navigation.navigate("Redeem", {
                                  fund: this.state.pkValue,
                                });
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "bold",
                                  fontSize: isWeb ? 15 : 13,
                                  color: "#FFFFFF",
                                  backgroundColor: "#410DAA",
                                  borderColor: "#410DAA",
                                  borderWidth: 1,
                                  borderRadius: 4,
                                  paddingVertical: 9,
                                  textAlign: "center",
                                  marginTop: 25,
                                  width: isMobile ? "60%" : "14%",
                                  marginLeft: isMobile ? "20%" : "26%",
                                  marginRight: isMobile ? "20%" : "60%",
                                }}
                              >
                                Redeem
                              </Text>
                            </TouchableOpacity>
                          </View> */}
                        </>
                      ) : (
                        <View
                          style={{
                            flex: 1,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              this.props.navigation.navigate("App", {
                                screen: "Dashboard",
                                params: {
                                  screen: "Explore",
                                  params: {
                                    screen: "FundDescription",
                                    params: {
                                      fund: this.state.fundPK,
                                    },
                                  },
                                },
                              });
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: "bold",
                                fontSize: isWeb ? 15 : 13,
                                color: "#FFFFFF",
                                backgroundColor: "#410DAA",
                                borderColor: "#410DAA",
                                borderWidth: 1,
                                borderRadius: 4,
                                paddingVertical: 9,
                                textAlign: "center",
                                marginTop: 25,
                                width: isMobile ? "30%" : "14%",
                                marginLeft: isMobile ? "35%" : "43%",
                                marginRight: isMobile ? "35%" : "43%",
                              }}
                            >
                              Invest
                            </Text>
                          </TouchableOpacity>
                        </View>
                      )}
                    </View>
                  )}

                  {this.state.isJamaMF && (
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#464E5F",
                        fontWeight: "bold",
                        marginTop: 40,
                        marginLeft: isMobile ? 20 : 0,
                      }}
                    >
                      All Transactions (
                      {this.state.mfTxnDetails
                        ? this.state.mfTxnDetails.length
                        : 0}
                      )
                    </Text>
                  )}

                  {isMobile &&
                    this.state.mfTxnDetails.map((item, index) => {
                      let transactionType = this.transactionStatus(
                        item.transaction_type
                      );

                      let current_value =
                        item.latest_value_at_nav &&
                        item.latest_value_at_nav != ""
                          ? item.latest_value_at_nav.toFixed(2)
                          : "-";

                      let nav =
                        item.fund_nav && item.fund_nav != ""
                          ? item.fund_nav.toFixed(2)
                          : "-";
                      let amount = item.amount;
                      let units = item.number_of_units;
                      let gain_or_loss =
                        item.latest_gain && item.latest_gain != ""
                          ? item.latest_gain.toFixed(2)
                          : "-";

                      let outIcon = ["Switch Out"];
                      let out = outIcon.indexOf(transactionType) != -1;

                      return (
                        <View
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 17,
                            width: "90%",
                            marginLeft: "5%",
                            marginRight: "5%",
                          }}
                        >
                          <View
                            style={{
                              flex: 10,
                              borderRadius: 10,
                              borderWidth: 1,
                              borderColor: "#410DAA1A",
                            }}
                          >
                            <View
                              style={{
                                padding: 10,
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <View
                                style={{ flex: 0.1, alignItems: "flex-start" }}
                              >
                                <Image
                                  source={
                                    out
                                      ? require("../../../assets/sold.png")
                                      : require("../../../assets/purchased.png")
                                  }
                                  resizeMode="contain"
                                  style={{
                                    height: 30,
                                    width: 30,
                                    alignSelf: "flex-start",
                                    borderWidth: 1,
                                    borderColor: "#f2f2f2",
                                    borderRadius: 5,
                                  }}
                                />
                              </View>
                              <FundField
                                style={{ flex: 0.4, alignItems: "flex-start" }}
                                name={transactionType}
                                value={this.dateFormat(item.trade_date, "-")}
                              />
                              <FundField
                                style={{ flex: 0.5, alignItems: "flex-end" }}
                                name={amount.toString()[0] == "-" ? `-₹${this.numberFormat(amount.toString().substring(1,amount.length))}` : `₹${this.numberFormat(amount)}`}
                                value={
                                  units && units != ""
                                    ? units == 0
                                      ? `${units} Units`
                                      : `${units.toFixed(3)} Units`
                                    : "- Units"
                                }
                              />
                            </View>
                            <Divider color="#410DAA1A" width={0.5} />
                            <View
                              style={{
                                paddingHorizontal: 10,
                                paddingVertical: 4,
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingVertical: 9,
                                }}
                              >
                                <FundField
                                  style={{
                                    flex: 0.33,
                                    alignItems: "flex-start",
                                  }}
                                  name="NAV"
                                  value={nav}
                                />
                                <FundField
                                  style={{ flex: 0.33, alignItems: "center" }}
                                  name="Current Value"
                                  value={
                                    current_value && current_value != "-"
                                      ? `₹${this.numberFormat(current_value)}`
                                      : "-"
                                  }
                                />
                                <FundField
                                  style={{ flex: 0.34, alignItems: "flex-end" }}
                                  name="Gain / Loss"
                                  value={
                                    gain_or_loss != "-"
                                      ? `₹${this.numberFormat(gain_or_loss)}`
                                      : "-"
                                  }
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      );
                    })}

                  {!isMobile &&
                    this.state.mfTxnDetails.map((item, index) => {
                      let transactionType = this.transactionStatus(
                        item.transaction_type
                      );

                      let current_value =
                        item.latest_value_at_nav &&
                        item.latest_value_at_nav != ""
                          ? item.latest_value_at_nav.toFixed(2)
                          : "-";

                      let nav =
                        item.fund_nav && item.fund_nav != ""
                          ? item.fund_nav.toFixed(2)
                          : "-";
                      let amount = item.amount;
                      let units = item.number_of_units;
                      let gain_or_loss =
                        item.latest_gain && item.latest_gain != ""
                          ? item.latest_gain.toFixed(2)
                          : "-";

                      let outIcon = ["Switch Out"];
                      let out = outIcon.indexOf(transactionType) != -1;

                      return (
                        <View
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 17,
                          }}
                        >
                          <View
                            style={{
                              flex: 10,
                              borderRadius: 10,
                              borderWidth: 1,
                              borderColor: "#410DAA1A",
                            }}
                          >
                            <View
                              style={{
                                padding: 10,
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <View
                                style={{ flex: 0.1, alignItems: "flex-start" }}
                              >
                                <Image
                                  source={
                                    out
                                      ? require("../../../assets/sold.png")
                                      : require("../../../assets/purchased.png")
                                  }
                                  resizeMode="contain"
                                  style={{
                                    height: 30,
                                    width: 30,
                                    alignSelf: "flex-start",
                                    borderWidth: 1,
                                    borderColor: "#f2f2f2",
                                    borderRadius: 5,
                                  }}
                                />
                              </View>
                              <FundField
                                style={{ flex: 1.8, alignItems: "center" }}
                                name={transactionType}
                                value={this.dateFormat(item.trade_date, "-")}
                              />
                              <FundField
                                style={{ flex: 1.8, alignItems: "flex-end" }}
                                name={amount.toString()[0] == "-" ? `-₹${this.numberFormat(amount.toString().substring(1,amount.length))}` : `₹${this.numberFormat(amount)}`}
                                value={
                                  units && units != ""
                                    ? units == 0
                                      ? `${units} Units`
                                      : `${units.toFixed(3)} Units`
                                    : "- Units"
                                }
                              />
                              <FundField
                                style={{
                                  flex: 1.8,
                                  alignItems: "flex-end",
                                }}
                                name="NAV"
                                value={nav}
                              />
                              <FundField
                                style={{ flex: 1.8, alignItems: "flex-end" }}
                                name="Current Value"
                                value={
                                  current_value && current_value != "-"
                                    ? `₹${this.numberFormat(current_value)}`
                                    : "-"
                                }
                              />
                              <FundField
                                style={{ flex: 1.8, alignItems: "flex-end" }}
                                name="Gain / Loss"
                                value={
                                  gain_or_loss != "-"
                                    ? `₹${this.numberFormat(gain_or_loss)}`
                                    : "-"
                                }
                              />
                            </View>
                          </View>
                        </View>
                      );
                    })}
                </View>
                {isMobile && <View style={{ marginTop: 35 }}></View>}
              </ScrollView>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: isWeb ? 32 : 0,
    paddingRight: isWeb ? 32 : 0,
    paddingTop: isWeb ? 30 : 10,
    width: "100%",
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
  filterText: {
    fontSize: 15,
    color: "gray",
    fontWeight: "200",
  },
  filtersTitle: {
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 6,
    marginLeft: isMobile ? 16 : 0,
  },
});
