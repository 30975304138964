import React from "react";
import { Dimensions, View, Text, Platform } from "react-native";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;
import { md } from "../../Responsive";
import PieChartInvestLegend from "../../components/InvestmentOverview/NewDesign/components/PieChartInvestLegend";
import { formatNumberToAbbreviation, isMobile, numberWithCommas } from "../../utils";

const { width } = Dimensions.get("window");
const isWeb = md(width);

export const PieChartStrip = ({ data }) => {
  let colorArr = [];
  let valueArr = [];
  let pieChartData = [];

  data.forEach((item) => {
    if (item && item.title && item.value) {
      colorArr.push(item.color);
      valueArr.push(item.value);
      pieChartData.push({ name: item.title, data: item.value });
    }
  });

  const options = {
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: { enabled: false },
    colors: colorArr,
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
        },
      },
    },
    stroke: {
      width: 1,
      colors: colorArr,
    },
  };

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginBottom: isMobile ? 0 : -14,
      }}
    >
      <View
        style={{ flex: isMobile ? 0.5 : 0.6, marginLeft: isMobile ? 0 : -35, justifyContent: 'center', alignItems: 'center'}}
      >
        {Platform.OS == "web" ? (
          <View
            style={{
              height: 220,
            }}
          >
            <View
              style={{
                marginTop: 47,
                width: 180,
                height: 120,
                overflow: "hidden",
              }}
            >
              <Donut
                options={options}
                series={valueArr}
                sliceColor={colorArr}
                type="donut"
                width={ isMobile ?  "140" : "180"}
              />
            </View>
          </View>
        ) : (
          <Donut
            widthAndHeight={100}
            series={valueArr}
            sliceColor={colorArr}
            doughnut={true}
            coverRadius={0.6}
            coverFill={"#FFF"}
            style={{ marginRight: 8, marginTop: 5, marginBottom: 3 }}
          />
        )}
      </View>
      <View style={{ flex: isMobile ?  0.3 : 0.2}}>
        {/* {console.log(JSON.stringify(data))} */}
        {data.map((item) => {
          return (
            <PieChartInvestLegend color={item.color} title={`${item.title}`} />
          );
        })}
      </View>
      <View>
        {data.map((item) => {
          return (
            <View
              style={[
                {
                  alignSelf: "flex-end",
                  flexDirection: "row",
                  marginVertical: 7.5,
                },
              ]}
            >
              {isMobile ? (<Text
                style={{
                  fontSize: isWeb ? 13 : 14,
                  color: "#000",
                  fontFamily: "Roboto",
                  fontWeight: '700'
                }}
              >
                {`${formatNumberToAbbreviation(item.total.toString(),true, true)}`} {`(${item.titleValue})`}
              </Text>) : (
                <Text
                style={{
                  fontSize: isWeb ? 13 : 14,
                  color: "#000",
                  paddingLeft: 13,
                  fontFamily: "Roboto",
                  fontWeight: '700'
                }}
              >
                {`${item.total}`} {isMobile ? "" : `(${item.titleValue})`}
              </Text>
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
};
