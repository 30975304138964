import React, { useRef, useState, useEffect } from "react";
import { Button, Dimensions, Modal, Platform, ScrollView } from "react-native";
import { Image } from "react-native";
import { StatusBar } from "react-native";
import { SafeAreaView } from "react-native";
import { TouchableOpacity } from "react-native";
import { StyleSheet } from "react-native";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { connect } from "react-redux";
import { saveUserProfileData } from "../../../actions";
import { GetPdfBytesObjectFromServer, contactInfo } from "../../../api";
import { ShowToast } from "../../../components/Common/Toast";
import { md } from "../../../Responsive";
import { DataURIToBlob, getUserDetails, isMobile, isWeb } from "../../../utils";
const { width } = Dimensions.get("window");
import SignatureCanvas from "react-signature-canvas";
// import { Document, Page, pdfjs } from "react-pdf";
import { CustomModal } from "../../../components/Common/CustomModal";
import NavigationHeader from "../../../components/Common/NavigationHeader";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SignaturePad = ({
  props,
  setIsLoading,
  setError,
  setErrorMsg,
  setShowModal,
}) => {
  const signatureRef = useRef();

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const handSignatureImage = signatureRef.current.toDataURL();
      const userData = getUserDetails();
      let username = userData.username;
      let cust_id = userData.customer_id;

      let body = new FormData();
      body.append("username", username);

      const imageFile = DataURIToBlob(handSignatureImage);
      body.append("hand_signature_pic", imageFile, username + "signature.png");

      const response = await contactInfo(cust_id, body);
      if (response && response.pk != null) {
        props.navigation.navigate("AdvisoryPlanHome", { ...props });
      } else {
        setIsLoading(false);
        setError(true);
        setErrorMsg(response && response.msg ? response.msg : null);
      }
    } catch (err) {
      setIsLoading(false);
      setError(true);
      setErrorMsg(error);
    }
  };

  const handleClear = () => {
    signatureRef.current.clear();
  };

  return (
    <View
      style={{
        paddingTop: 50,
      }}
    >
      <View
        style={{
          paddingBottom: 20,
        }}
      >
        <Text
          style={{
            fontSize: 16,
            fontWeight: 500,
            color: "gray",
          }}
        >
          Draw your signature below.
        </Text>
      </View>
      <View
        style={{
          borderColor: "#000",
          borderWidth: 1,
          borderRadius: 5,
        }}
      >
        <SignatureCanvas ref={signatureRef} clearOnResize={false} />
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 15,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            handleClear();
          }}
          style={{}}
        >
          <Text
            style={{
              color: "#410DAA",
              fontSize: 15,
              textDecorationLine: "underline",
              fontWeight: 700,
            }}
          >
            Clear Signature
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            handleSave();
          }}
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={styles.next}>Continue</Text>
        </TouchableOpacity>
      </View>
      <View
        style={{
          paddingTop: 15,
        }}
      >
        <Text
          style={{
            fontSize: 16,
          }}
        >
          By clicking the Continue button, I hereby agree and accept the terms
          and conditions, Read{" "}
          <Text
            style={{
              color: "#410DAA",
              fontSize: 18,
              fontWeight: 600,
              textDecorationLine: "underline",
            }}
            onPress={() => {
              setShowModal(true);
            }}
          >
            agreement
          </Text>
        </Text>
      </View>
    </View>
  );
};

const HandSignature = (props) => {
  const [signature, setSignature] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfUrl, setPdfUrl] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    const get_pdf_bytes = async () => {
      try {
        const response = await GetPdfBytesObjectFromServer();
        const pdfBase64 = response.result;
        const binaryData = atob(pdfBase64);
        const uint8Array = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "application/pdf" });
        const pdf_url = URL.createObjectURL(blob);
        setPdfUrl(pdf_url);
      } catch (err) {}
    };

    get_pdf_bytes();
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <NavigationHeader
        navigation={props.navigation}
        navigationHeader={["Hand Signature"]}
        onPressBack={() => {
          props.onPressBack();
        }}
      />
      <StatusBar backgroundColor="#000" barStyle="dark-content" />
      <ScrollView>
        <View style={styles.body}>
          <View style={styles.right}>
            <SignaturePad
              props={props}
              setIsLoading={setIsLoading}
              setError={setError}
              setErrorMsg={setErrorMsg}
              setShowModal={setShowModal}
            />
            {showModal ? (
              <View style={{ marginTop: 10 }}>
                <CustomModal
                  visible={showModal}
                  showCloseButton={true}
                  closeModal={() => {
                    toggleModal();
                  }}
                  style={{
                    maxHeight: 550,
                  }}
                >
                  <ScrollView showsVerticalScrollIndicator={false}>
                    <Document
                      file={pdfUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                    <View>
                      <View
                        style={{
                          fles: 1,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingBottom: 10,
                        }}
                      >
                        {pageNumber > 1 ? (
                          <TouchableOpacity
                            disabled={pageNumber <= 1}
                            onPress={() => {
                              goToPrevPage();
                            }}
                            labelStyle={{
                              color: "#410DAA",
                              fontWeight: "600",
                            }}
                            style={{
                              borderColor: "#410DAA",
                              borderWidth: 1,
                              backgroundColor: "white",
                              borderRadius: 6,
                              height: 25,
                              width: 80,
                              alignItems: "center",
                            }}
                          >
                            Previous
                          </TouchableOpacity>
                        ) : (
                          <TouchableOpacity
                            disabled={pageNumber <= 1}
                          ></TouchableOpacity>
                        )}

                        {pageNumber < numPages ? (
                          <TouchableOpacity
                            disabled={pageNumber >= numPages}
                            onPress={() => {
                              goToNextPage();
                            }}
                            labelStyle={{
                              color: "#410DAA",
                              fontWeight: "600",
                            }}
                            style={{
                              borderColor: "#410DAA",
                              borderWidth: 1,
                              backgroundColor: "white",
                              borderRadius: 6,
                              height: 25,
                              width: 80,
                              alignItems: "center",
                            }}
                          >
                            Next
                          </TouchableOpacity>
                        ) : (
                          <TouchableOpacity
                            disabled={pageNumber <= 1}
                          ></TouchableOpacity>
                        )}
                        {/* )
                        } */}
                      </View>
                    </View>
                  </ScrollView>
                </CustomModal>
              </View>
            ) : null}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1 },

  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: md(width) ? 110 : 17,
    paddingRight: md(width) ? 150 : 16,
    paddingBottom: 120,
  },
  left: {
    flex: 0.45,
    paddingTop: 55,
  },
  right: {
    flex: 1,
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },

  lock: {
    resizeMode: "contain",
    width: 30,
    height: 30,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 18 : 15,
    lineHeight: md(width) ? 21 : 17,
    color: "#2F3044",
  },
  terms1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 22 : 18,
    lineHeight: md(width) ? 25 : 20,
    color: "#2F3044",
    display: md(width) ? "none" : "flex",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 15 : 12,
    lineHeight: 17,
    color: "#464E5F",
    paddingTop: 12,
    paddingBottom: md(width) ? 32 : 26,
    paddingRight: md(width) ? 150 : 11,
  },
  please1: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 16 : 12,
    lineHeight: 18,
    color: "#464E5F",
    paddingTop: isMobile ? 11 : 0,
    paddingBottom: md(width) ? 32 : 26,
    paddingRight: md(width) ? 170 : 11,
    display: md(width) ? "none" : "flex",
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  add1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 14 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    paddingBottom: 6.35,
  },
  inptxt: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 12 : 13,
    lineHeight: md(width) ? 14 : 15,
    color: "#121212",
    paddingVertical: isMobile ? 8 : 13.5,
    paddingHorizontal: 18.75,
    backgroundColor: "#f6f6f6",
    borderRadius: 4,
  },
  max: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  error: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 11,
    lineHeight: 13,
    color: "red",
    opacity: 0.8,
    paddingTop: 3.42,
  },
  res: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 16 : 14,
    lineHeight: md(width) ? 18 : 16,
    color: "#222225",
    paddingBottom: 13.29,
  },
  drop: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#121212",
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 18,
    marginTop: 20,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  next: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    fontWeight: "bold",
    paddingVertical: 12,
    paddingHorizontal: 50,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  view_sample: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#8950FC",
    paddingBottom: md(width) ? 5 : 10,
  },
  addressProof: {
    fontSize: 11,
    color: "#121212",
    marginBottom: 14,
    fontWeight: "700",
  },
  fileName: {
    fontSize: 12,
    marginTop: 5,
    color: "#410DAA",
    fontWeight: "bold",
  },
  support: {
    fontSize: 10,
    color: "#121212",
    fontStyle: "italic",
    marginTop: 4,
  },
  error: { color: "red" },
  rowContianer: {
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 28,
  },
  marginRight: {
    marginRight: isMobile ? 0 : 29,
  },
  rowItem: {
    flex: 0.5,
  },
});

const mapStateToProps = (state) => {
  return {
    userProfileData: state.userData.userProfileData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveUserProfileData: (data) => dispatch(saveUserProfileData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HandSignature);
