import React, {useRef, useState} from 'react';
import { StyleSheet, Text, View, Button, ScrollView, Platform, Dimensions,TouchableOpacity, Image } from 'react-native';
import {sm, md, lg, xl, twoxl} from '../../Responsive'

const window = Dimensions.get('window');
export default function Header ({navigation,style={}}) {
    const handleLogoClick = () => {
        navigation.push('Login');
    }
    const pngLogo = require('../../assets/logo.png');
    const ArrowBackIcon = require('../../assets/JamaInvest/Arrow2x.png');

    return (
        <View style={[styles.bar,{...style}]}>
            <View style={styles.headerLeft}>
                <TouchableOpacity 
                    onPress={()=>{
                        navigation && navigation.goBack();
                    }}>
                    <Image source={ArrowBackIcon} style={styles.backIcon}></Image>
                </TouchableOpacity>
                {
                    md(window.width)&&( 
                        <TouchableOpacity onPress={handleLogoClick}>
                            <Image source={pngLogo} style={styles.logo}></Image>
                        </TouchableOpacity>
                  )
                }
            </View>
            {
                md(window.width)&&(
                    <View style={styles.headerRight}>
                        <Text style={{fontSize: md(window.width) ? 16: 13, color: 'black'}}>Any Queries?</Text>
                        <View>
                        <TouchableOpacity onPress={() => {console.log('clicked')}}>
                            <Text style={{fontSize: md(window.width) ? 16: 13, color: '#410DAA', fontWeight: 'bold', marginLeft: 5}}>Get Help</Text>
                        </TouchableOpacity>
                        </View>
                    </View>
             )
            }
        </View>
    )
}

const styles = StyleSheet.create({
    bar: {
        backgroundColor: '#F6F6F6',
        width: '100%',
        height: md(window.width) ? 65 : 70,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: md(window.width) ? 40: 16,
        paddingRight: md(window.width) ? 70: 16,
        // paddingTop: md(window.width) ? undefined: 19,
        paddingBottom: md(window.width) ? undefined: 0,
        // borderBottomColor: '#DDD',
        // borderBottomWidth: 1,
        position: md(window.width) && Platform.OS === 'web' ? "-webkit-sticky": "absolute",
        alignItems:"center",
        // position:"sticky",
        top: 0,
        left: 0
        // position: 'absolute',
        // top: 0,
        // left: 0
    },
    backIcon: {
        width:30,
        height:24,
        marginRight: md(window.width) ? 60: 5
    },
    logo: {
        resizeMode: 'contain',
        width: md(window.width) ? 140: 140,
        height: md(window.width) ? 27.41: 30,
        alignSelf: 'center',
        // marginTop: 4
    },
    headerLeft: {
        display: 'flex',
        alignItems: md(window.width) ? 'center': 'flex-end', 
        flexDirection: 'row',
    },
    headerRight: {
        display: 'flex', 
        alignItems: md(window.width) ? 'center': 'flex-end',
        flexDirection: 'row',
    }
})