import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  Image,
  TouchableOpacity,
} from "react-native";

export default class SuccessOrFailureCard extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const {
      status = true,
      title,
      visible,
      message,
      messageStyle,
      buttonOnPress,
      buttonTitle,
      buttons,
    } = this.props;
    return (
      <SafeAreaView style={{ ...this.props.style }}>
        {visible && (
          <View
            style={[styles.bottomModal, { zIndex: 5 }]}
            onBackdropPress={() => {
              buttonOnPress && buttonOnPress();
            }}
          >
            <View style={styles.modalContent}>
              <Image
                source={
                  status
                    ? require("../../../assets/success.png")
                    : require("../../../assets/failure.png")
                }
                style={{
                  width: 140,
                  height: 140,
                  marginTop: 24,
                  marginBottom: 20,
                }}
                resizeMode="contain"
              />
              <Text
                style={{
                  fontSize: 18,
                  color: "#121212",
                  textAlign: "center",
                  marginBottom: 10,
                }}
              >
                {title || "Succesful"}
              </Text>
              {message && (
                <Text
                  style={[
                    {
                      fontSize: 13,
                      color: "#707070",
                      textAlign: "center",
                      marginBottom: 100,
                    },
                    messageStyle ? { ...messageStyle } : {},
                  ]}
                >
                  {message}
                </Text>
              )}
              {this.props.children}
              {buttonTitle && buttonOnPress && (
                <TouchableOpacity
                  style={{
                    backgroundColor: "#410DAA",
                    width: "100%",
                    height: 50,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    buttonOnPress && buttonOnPress();
                  }}
                >
                  <Text style={{ fontSize: 17, color: "#fff" }}>
                    {buttonTitle || "Done"}
                  </Text>
                </TouchableOpacity>
              )}
              {buttons &&
                buttons.map((button) => {
                  return (
                    <TouchableOpacity
                      style={[
                        {
                          backgroundColor: "#410DAA",
                          width: "100%",
                          height: 50,
                          borderRadius: 5,
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 10,
                        },
                        button.buttonStyle ? { ...button.buttonStyle } : {},
                      ]}
                      onPress={() => {
                        button.buttonOnPress && button.buttonOnPress();
                      }}
                    >
                      <Text
                        style={[
                          { fontSize: 17, color: "#fff" },
                          button.textStyle ? { ...button.textStyle } : {},
                        ]}
                      >
                        {button.title}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
            </View>
          </View>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: "flex-end",
    margin: 0,
  },
  modalContent: {
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    borderColor: "rgba(0, 0, 0, 0.1)",
    padding: 16,
  },
});
