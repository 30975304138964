export const assetTypes = {
    //first two characters tell us about the asset class
    //Group Name : Real Estate 7%
    REA: "Apartment",
    REV: "House or Villa ",
    REL: "Land / Plot",
    // Group Name : Equity 11%
    EQS: "Stock",
    EQN: "National Pension Scheme - Equity",
    EQW: "Equity Mutual Fund",
    EGN: "General Equity Asset",
    // Group Name : Debt  7%
    DBN: "National Pension Scheme - Debt",
    DBE: "Employee Provident Fund",
    DBP: "Public Provident Fund",
    DBB: "Bond",
    DBF: "Bank FD",
    DBM: "Post Office Savings - Monthly Income Scheme",
    DBR: "Post Office Savings - Recurring Deposit Scheme",
    DBC: "Cash in Bank",
    DBL: "Cash Receivable",
    DBD: "Debt Mutual Fund",
    DGN: "General Assets",
    SSY: "Sukanya Samriddhi Yojana",
    // Group Name : Insurance 6%
    INL: "Insurance Endowment Policy",
    INW: "Insurance Whole Life Policy",
    INU: "Unit Linked Insurance Plan",
    //8%   // Group Name : Gold 7%
    GDB: "Gold - Biscuits or Coins",
    GDS: "Gold - Sovereign Bonds",
    GDM: "Gold Mutual Fund"
};