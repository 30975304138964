import { StackActions } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import {
  View,
  SafeAreaView,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { Text } from "react-native-paper";
import { getEduQuizResult } from "../../../api";
import CustButton from "../../../components/Common/Button";
import { isMobile } from "../../../utils";

function number_format(val, decimals) {
  val = parseFloat(val);
  return val.toFixed(1);
}

const EduQuizResult = ({ navigation, ...props }) => {
  const [quizData, setQuizData] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { resultContainer, rowStyle } = styles;

  const { params } = props.route;
  let quiz_id = null;
  if (params) {
    quiz_id = params.quiz_id || null;
    quiz_id = isNaN(quiz_id) ? null : quiz_id;
  }

  useEffect(() => {
    async function getQuizResult() {
      try {
        setLoading(true);
        if (quiz_id) {
          const payload = { quiz_id };
          const response = await getEduQuizResult(payload);
          // console.log(response);
          if (response && response.code == 100) {
            setQuizData(response);
            setError("");
          } else {
            setError("Something went wrong. Please try again later");
          }
          setLoading(false);
        }
      } catch (e) {
        setError("Something went wrong. Please try again later");
        setLoading(false);
      }
    }
    getQuizResult();
  }, []);

  const onDonePress = () => {
    navigation.dispatch(
      StackActions.replace("App", {
        screen: "ViewMode",
        params: {
          screen: "EduHome",
          params: {},
        },
      })
    );
    // navigation.navigate("EduHome")
  };

  if (quizData) {
    const {
      total_questions,
      correct_ans_count,
      status,
      pass_percentage,
      obtained_perc,
    } = quizData;
    return (
      <SafeAreaView
        style={{ flex: 1, backgroundColor: "#fff", padding: 16, maxWidth: 800 }}
      >
        <View>
          <Text
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 18,
              marginBottom: 32,
              marginTop: 20,
            }}
          >
            Quiz Completed
          </Text>
        </View>

        <View style={{ flex: 1 }}>
          <View style={resultContainer}>
            <View style={rowStyle}>
              <Text style={{ flex: 3 }}>Total no. of questions</Text>
              <Text style={{ flex: 1 }}>{total_questions}</Text>
            </View>
            <View style={rowStyle}>
              <Text style={{ flex: 3 }}>Correct Answers</Text>
              <Text style={{ flex: 1 }}>{correct_ans_count}</Text>
            </View>
            <View style={rowStyle}>
              <Text style={{ flex: 3 }}>Pass Percentage</Text>
              <Text style={{ flex: 1 }}>
                {number_format(pass_percentage.split("%")[0], 0)}%
              </Text>
            </View>
            <View style={rowStyle}>
              <Text style={{ flex: 3 }}>Obtained Percentage</Text>
              <Text style={{ flex: 1 }}>
                {number_format(obtained_perc.split("%")[0], 0)}%
              </Text>
            </View>
            <View style={rowStyle}>
              <Text style={{ flex: 3 }}>Status</Text>
              <Text
                style={{
                  flex: 1,
                  color: status !== "Fail" ? "#009105" : "red",
                }}
              >
                {status}
              </Text>
            </View>
          </View>
        </View>
        <CustButton
          bgColor={"#410DAA"}
          borderColor={"#410DAA"}
          style={{
            margin: 30,
            width: isMobile ? "100%" : "20%",
            marginBottom: isMobile ? "30%" : "30%",
            marginLeft: isMobile ? "auto" : "40%",
          }}
          onPress={onDonePress}
        >
          Done
        </CustButton>
      </SafeAreaView>
    );
  }

  if (loading) {
    return (
      <SafeAreaView
        style={{ flex: 1, backgroundColor: "#fff", padding: 16, maxWidth: 800 }}
      >
        <View>
          <Text
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 18,
              marginBottom: 32,
            }}
          >
            Quiz Completed
          </Text>
        </View>
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <ActivityIndicator size="large" color="#410DAA" />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: "#fff", padding: 16, maxWidth: 800 }}
    >
      <View>
        <Text
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 18,
            marginBottom: 32,
          }}
        >
          Quiz Completed
        </Text>
      </View>
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Text>{error}</Text>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  resultContainer: {
    backgroundColor: "#F7F5FC",
    borderRadius: 8,
    padding: 16,
    marginHorizontal: 16,
  },
  rowStyle: { marginVertical: 8, flexDirection: "row" },
});

export default EduQuizResult;
