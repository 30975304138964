import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, StatusBar } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { updateKYCRequestStatus } from '../../../api/account';
import { completeMFMinDetails, completeMinDetails } from '../../../utils';
import Header from '../header_after_login';

const KYCRedirection = (props) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(()=>{

        const fetchDigioKycReqeustStatus = async () => {
            try {
                const response = await updateKYCRequestStatus();
                if(response && response.code == 100){
                    moveToNextScreen();
                }else{
                    if(response.code == 102){
                        const { results } = response;
                        if(results){
                            const { workflow_name=null, kyc_status } = response.results;
                            if(kyc_status=="DAT"){
                                let options = {};
                                if(workflow_name){
                                    options["option"] = workflow_name == 'JAMĀ_NON_DIGI' ? 'JNDF' : 'JDF';
                                };
                                props.navigation.navigate("Onboarding", { screen: "KYCCreate", params: { ...options} });
                            } else {
                                moveToNextScreen();
                            }
                        }
                    }else{
                        setError("Error while fetching KYC Status!")
                    }
                }
            } catch (err) {
                console.log("error", error);
            }
        };

        fetchDigioKycReqeustStatus();
    }, []);

    const moveToNextScreen = async () => {
        try {
          const minDetailsResponse = await completeMinDetails();
          const { needCompletion, error } = minDetailsResponse;
    
          if (!error) {
            const { redirect_screen } = minDetailsResponse;
            let options = {};
            if (props.route) {
              const { params } = props.route;
              if (params) {
                options = { ...params };
              }
              if (!needCompletion) {
                props.navigation.navigate("Onboarding",
                    {
                        screen: options.action ? "ComplianceSuccess" : "OnboardingSuccess",
                        params: { ...options }
                    }
                );
                return;
              }
            }
            props.navigation.navigate("Onboarding", {screen: redirect_screen, params : { ...options }}
            );
          } else {
              setError("Something went wrong! Please contact help@maxiom.in");
          }
        } catch (error) {
            setError("Something went wrong! Please contact help@maxiom.in");
        }
      };

    return(
        <SafeAreaView style={styles.container}>
            <Header navigation={props.navigation} route={props.route} />
            <StatusBar backgroundColor="#000" barStyle="dark-content" />
            <View style={styles.loaderContainer}>
                {loading && <ActivityIndicator size="large" color="#410DAA"/> }
                <Text style={styles.fetching}>{ loading & !error ? "Fetching KYC status" : error }</Text>

            </View>
        </SafeAreaView>
    );
};


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    loaderContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
    },
    loader: {
        flex:1,
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        left: 0,
    },
    fetching: {
        fontSize: 14,
        color: "#12121250",
        marginTop: 20
    },
});

export default KYCRedirection;