import React from 'react';
import {View, StyleSheet } from 'react-native';

const BulletPoint = ({ outerColor, innerColor, style=[], outerSize = 9, innerSize = 5}) => {
    return(
        <View style={[point.outer, { backgroundColor: outerColor, width: outerSize, height: outerSize, borderRadius: outerSize/2}, ...style]}>
            <View style={[point.inner, { backgroundColor: innerColor, width: innerSize, height: innerSize, borderRadius: innerSize/2}]}></View>
        </View>
    )
};

const point = StyleSheet.create({
    outer: {
        width: 9,
        height: 9,
        borderRadius: 4.5,
        justifyContent: "center",
        alignItems: "center"
    },
    inner: {
        width: 5,
        height: 5,
        borderRadius: 2.5
    }
})

export default BulletPoint;