import React from "react";
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  FlatList,
  Image,
  Platform,
  Dimensions,
  ScrollView,
  SafeAreaView,
} from "react-native";
const { height, width } = Dimensions.get("window");
import { ActivityIndicator } from "react-native-paper";
import {
  encryptToken,
  formatDate,
  getProductName,
  getUserDetails,
  isMobile,
  numberWithCommas,
} from "../../utils";
import { Ionicons } from "@expo/vector-icons";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { getCustomerInvoiceDetails } from "../../api/billing";
import { TableHeader } from "./HoldingsTable";
import moment from "moment";
import PaymentDetails from "./PaymentDetails";
import { MAIN_URL } from "../../api/urls";
import Store from "../../store";
import Table from "../../components/Common/Table";
import amplitude from "../../utils/amplitude";

const FieldValue = ({ value = null, label = "" }) => {
  return (
    <View style={styles.left_bor}>
      <View style={styles.box}>
        <Text style={styles.date}>{value}</Text>
        <Text style={styles.inv_dte}>{label}</Text>
      </View>
    </View>
  );
};

const InvoiceData = ({ data, invoice_id }) => {
  return (
    <View style={styles.invoice_box}>
      <Text style={styles.invoice}>Invoice Details - #{invoice_id}</Text>
      <FieldValue
        value={formatDate(data.invoice_date)}
        label={"Invoice Date"}
      />
      <FieldValue
        value={`₹${numberWithCommas(parseInt(data.total_fee))}`}
        label={"Total Fee"}
      />
      <FieldValue
        value={`₹${numberWithCommas(parseInt(data.grand_total))}`}
        label={"Total Fee(including GST)"}
      />
      <FieldValue
        value={`₹${numberWithCommas(parseInt(data.paid))}`}
        label={"Paid"}
      />
      <FieldValue
        value={`₹${numberWithCommas(parseInt(data.paid_with_gst))}`}
        label={"Paid(including GST)"}
      />
      <FieldValue
        value={`₹${numberWithCommas(
          parseInt(data.due) < 0 ? 0 : parseInt(data.due)
        )}`}
        label={"Due"}
      />
      <FieldValue
        value={`₹${numberWithCommas(
          parseInt(data.amount_due_with_gst) < 0
            ? 0
            : parseInt(data.amount_due_with_gst)
        )}`}
        label={"Due(including GST)"}
      />
    </View>
  );
};

class InvoiceDetails extends React.Component {
  constructor(props) {
    super(props);
    let user_id = null,
      invoice_id = null;
    const { params } = this.props.route;
    if (params) {
      user_id = params.user_id;
      invoice_id = params.invoice_id;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      isLoading: true,
      data: null,
      user_id,
      invoice_id,
    };
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("InvoiceDetails",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("InvoiceDetails", { path: this.props.navigation.getState().routeName });
      }
      const { customer_id } = this.state.user_id || getUserDetails();
      const { invoice_id } = this.state;
      const response = await getCustomerInvoiceDetails(invoice_id, customer_id);
      let error = null;
      if (response && response.code == 100) {
        const { results = [] } = response;
        if (results.length) {
          const { invoice_details = null } = results[0];
          if (invoice_details) {
            const {
              paid_bills = [],
              plans = [],
              ...remainingData
            } = invoice_details;
            const data = {
              paid_bills: paid_bills,
              plans: plans,
              invoice_payment_data: remainingData,
            };
            const showDiscount =
              plans.filter((plan) => parseInt(plan.discount) > 0).length > 0;
            this.setState({ data, isLoading: false, showDiscount });
            return;
          }
        }
      } else {
        error = "Error loading invoice details";
      }
      this.setState({ isLoading: false, error: error });
    } catch (error) {
      this.setState({
        isLoading: false,
        error: "Error loading invoice details",
      });
    }
  };

  handleSearch = (searchText) => {
    this.setState({ searchText });
  };

  compare = (a, b, field, sort) => {
    if (a[field] < b[field]) {
      return sort ? -1 : 1;
    }
    if (a[field] > b[field]) {
      return sort ? 1 : -1;
    }
    return 0;
  };

  handleSort = (field, sort, start, end) => {
    this.setState(
      {
        sorting_field: field,
        sorting_value: !sort,
      },
      () => {
        const { data } = this.state;
        const sortedData = [].concat(
          data.slice(0, start),
          data
            .slice(start, end)
            .sort((a, b) => this.compare(a, b, field, !sort)),
          data.slice(end, data.length)
        );
        this.setState({ data: sortedData });
      }
    );
  };

  getTableData = (data = []) => {
    const headerData = [
      {
        label: "Payment Date",
        style: [styles.tableHeader2],
        sortOption: false,
      },
      {
        label: "Transaction ID",
        style: [styles.tableHeader2],
        sortOption: false,
      },
      {
        label: "Payment Type",
        style: [styles.tableHeader2],
        sortOption: false,
      },
      {
        label: "Amount Paid",
        style: [styles.tableHeader2],
        sortOption: false,
      },
      {
        label: "Amount Paid(including GST)",
        style: [styles.tableHeader2],
        sortOption: false,
      },
    ];

    let { values = [] } = this.generateDataForTable(data);
    return { headerData, values };
  };

  generateDataForTable = (data) => {
    let values = [];
    data.forEach((item) => {
      let {
        paid_amount,
        paid_date,
        payment_id,
        payment_type,
        bill_amount_including_gst,
      } = item;
      const paid_amount_formatted =
        "₹" + numberWithCommas(parseInt(paid_amount));
      const paid_amount_gst_formatted =
        "₹" + numberWithCommas(parseInt(bill_amount_including_gst));
      values.push([
        {
          valueToDisplay: paid_date,
          value: paid_date,
          style: [styles.rowItem2],
          rowItemContainerStyle: [styles.paidDate2],
        },
        {
          valueToDisplay: payment_id,
          value: payment_id,
          style: [styles.rowItem2],
        },
        {
          valueToDisplay: payment_type,
          value: payment_type,
          style: [styles.rowItem2],
        },
        {
          valueToDisplay: paid_amount_formatted,
          value: paid_amount,
          style: [styles.rowItem2],
        },
        {
          valueToDisplay: paid_amount_gst_formatted,
          value: bill_amount_including_gst,
          style: [styles.rowItem2],
        },
      ]);
    });
    return { values };
  };

  renderPlanDetails = ({ item }) => {
    var plan_start = moment.unix(item.plan_start_timestamp);
    var plan_end = moment.unix(item.plan_end_timestamp);
    const plan_duration =
      Math.round(plan_end.diff(plan_start) / (1000 * 60 * 60 * 24 * 365)) + "Y";

    const bill_start_date = moment
      .unix(item.bill_start_timestamp)
      .format("DD MMM YYYY");
    const bill_end_date = moment
      .unix(item.bill_end_timestamp)
      .format("DD MMM YYYY");
    const { showDiscount } = this.state;
    const { is_ria } = getUserDetails();
    let bill_type = item.bill_type;
    return (
      <View
        style={{ flexDirection: "row", alignItems: "center", paddingTop: 40 }}
      >
        {is_ria ? (
          <View style={{ flex: 0.2 }}>
            <Text style={{ textAlign: "center" }}>{item.cbi_pk}</Text>
          </View>
        ) : (
          <View style={{ flex: 0.2 }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Text style={styles.direct}>
                {(item.product == "MF" || item.product == "ADV" || item.product == "CLB") ? item.plan_description : item.plan_name}
              </Text>
              <View>
                <Image
                  source={{
                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jama_onbording/Rectangle+2755.png",
                  }}
                  style={{
                    width: 90,
                    height: 21,
                    marginLeft: 20,
                    marginTop: -6,
                  }}
                />
                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    color: "#464E5F",
                    zIndex: 10,
                    marginLeft: 35,
                    marginTop: -20,
                  }}
                >
                  {getProductName(item.product)}
                </Text>
              </View>
            </View>
            {item.product != "MF" && (
              <>
                {
                 (item.product != "ADV" && item.product != "CLB") ? (
                  <Text style={styles.invest}>
                    <Text style={styles.invest}>Investment Amount:</Text> ₹
                    {numberWithCommas(parseInt(item.investment_amount))}
                    <Text> Fee: </Text>
                    {item.fee_percentage}
                  </Text>
                 ) : (null) 
                }
                <Text style={[styles.plan_period]}>
                  Plan Period:{" "}
                  <Text style={{ color: "#121212" }}>
                    {plan_start.format("DD MMM YYYY")} -{" "}
                    {plan_end.format("DD MMM YYYY")} ({plan_duration})
                  </Text>
                </Text>
              </>
            )}
          </View>
        )}
        {!isMobile && (
          <View
            style={{ flex: showDiscount ? 0.02 : 0.02, alignItems: "center" }}
          ></View>
        )}
        <View style={{ flex: showDiscount ? 0.1 : 0.1, alignItems: "center" }}>
          <Text style={styles.tt_plan}>{bill_type}</Text>
        </View>
        <View style={{ flex: 0.2, alignItems: "center" }}>
          <Text style={styles.tt_plan}>
            {bill_start_date} - {bill_end_date}
          </Text>
        </View>
        <View
          style={{ flex: showDiscount ? 0.15 : 0.15, alignItems: "center" }}
        >
          <Text style={styles.tt_plan}>
            ₹{numberWithCommas(parseInt(item.amount_due))}
          </Text>
        </View>
        {showDiscount && (
          <View style={{ flex: showDiscount ? 0.15 : 0, alignItems: "center" }}>
            <Text style={[styles.tt_plan, item.discount]}>
              ₹{numberWithCommas(parseInt(item.discount))}{" "}
              {item.discount ? (
                <Ionicons
                  name="checkmark-circle-sharp"
                  size={15}
                  color="#0BB783"
                />
              ) : (
                <></>
              )}
            </Text>
          </View>
        )}
        <View
          style={{ flex: showDiscount ? 0.15 : 0.15, alignItems: "center" }}
        >
          <Text style={styles.tt_plan}>
            ₹{numberWithCommas(parseInt(item.bill_amount_including_gst))}
          </Text>
        </View>
        {(item.product != "MF" && item.product != "ADV" && item.product != "CLB") && (
          <View style={{ flex: 0.15, alignItems: "center", marginTop: -2 }}>
            <TouchableOpacity
              onPress={() => {
                this.viewBillDetails(
                  item.bill_type,
                  item.cbi_pk,
                  item.bill_type_code
                );
              }}
            >
              <Text style={styles.view_dtls}>View Bill</Text>
            </TouchableOpacity>
            {this.state.user_id && (
              <Text style={styles.billId}>Bill ID: {item.cbi_pk}</Text>
            )}
          </View>
        )}
      </View>
    );
  };

  viewPlanWiseBilling = () => {
    this.props.navigation.navigate("TransactionDetails");
  };

  viewBillDetails = (bill, cbi_pk, bill_code) => {
    let params = {
      bill_id: cbi_pk,
    };
    if (this.state.user_id) {
      params["user_id"] = this.state.user_id;
    }
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (bill_code == "ASV" || bill_code == "ACF") {
      const { token } = getUserDetails();
      const encryptedToken = encryptToken(token);
      const url =
        MAIN_URL +
        "/app/rnw_to_web_login?token=" +
        encryptedToken +
        "&is_rnw=1&redirection_url=/app/billdetail/" +
        bill_code +
        "/" +
        cbi_pk +
        "/?userid=" +
        this.state.user_id;
      let popup_window = window.open(
        url,
        hostname,
        "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=yes,width=670,height=700"
      );
      try {
        popup_window.focus();
      } catch (e) {
        alert(
          "Pop-up Blocker is enabled! Please add this site to your exception list."
        );
      }
    } else {
      this.props.navigation.navigate("ViewBill", {
        ...params,
        invoice_id: this.state.invoice_id,
      });
    }
  };

  navigateBack = () => {
    const { navigation } = this.props;
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("Invoices");
    }
  };
  render() {
    const {
      error,
      isLoading,
      invoice_id,
      data = null,
      showDiscount,
    } = this.state;
    const discountHeader = { label: "Discount", value: "amount_paid" };

    const { is_ria } = getUserDetails();

    let gridConfig = [
      showDiscount ? 0.2 : 0.25,
      0.1,
      showDiscount ? 0.15 : 0.2,
      0.15,
    ];
    let header = [
      { label: is_ria ? "Bill Id" : "Plan Description", value: "invoice_pk" },
      { label: "Bill Type", value: "invoice_date" },
      { label: "Bill Period", value: "amount" },
      { label: "Net Amount ", value: "amount_due" },
    ];
    if (showDiscount) {
      header.push(discountHeader);
      gridConfig.push(0.15);
    }
    header = [
      ...header,
      {
        label: "Net Amount\n(including GST) ",
        value: "bill_amount_including_gst",
      },
      { label: "", value: null },
    ];

    gridConfig = [...gridConfig, 0.15, showDiscount ? 0.1 : 0.15];

    let dataValue = [];
    let headerData = [];
    let values = [];

    if (data && data["paid_bills"].length > 0) {
      dataValue = data["paid_bills"];
      headerData = this.getTableData(dataValue).headerData;
      values = this.getTableData(dataValue).values;
    }

    return (
      <SafeAreaView style={styles.container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Invoice #" + invoice_id]}
          onPressBack={this.navigateBack}
        />
        {isLoading || error ? (
          <View style={styles.loadingContainer}>
            {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
            {error && <Text style={styles.error}>{error}</Text>}
          </View>
        ) : isMobile ? (
          <ScrollView horizontal={true}>
            <View style={{ height: "100%" }}>
              {data && (
                <View style={styles.body}>
                  <View style={styles.color_border}>
                    <InvoiceData
                      data={data["invoice_payment_data"]}
                      invoice_id={invoice_id}
                    />
                    <TableHeader
                      data={header}
                      gridConfig={
                        isMobile
                          ? [3.5, 2, 2.4, 0.7, 0.3, 1, 1]
                          : showDiscount
                          ? [1, 1, 1.3, 1, 1, 1, 1, 1]
                          : [1, 1, 1, 1, 1, 1, 1, 1]
                      }
                      tableHeaderStyle={[styles.tableHeaderStyle]}
                    />
                    <View
                      style={{
                        height:
                          data["plans"].length > 1 ? 0.38 * height : "auto",
                        paddingBottom: 30,
                      }}
                    >
                      <FlatList
                        data={data["plans"]}
                        renderItem={this.renderPlanDetails}
                        keyExtractor={(item) => item.plan}
                        style={{ paddingHorizontal: 17 }}
                      />
                    </View>
                  </View>
                  {data && data["paid_bills"].length > 0 && (
                    <View style={styles.detailsContainer}>
                      <View style={styles.headingContainer}>
                        <Text style={styles.header2}>Payment Details</Text>
                      </View>
                      <Table
                        header={headerData}
                        gridConfig={[0.2, 0.2, 0.2, 0.2, 0.2]}
                        data={values}
                        tableHeaderStyle={[styles.tableHeaderStyle2]}
                        pagination={false}
                        minWidth={1200}
                      />
                    </View>
                  )}
                </View>
              )}
            </View>
          </ScrollView>
        ) : (
          <ScrollView style={{height: height/2, paddingBottom: 100}}>
            <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} contentContainerStyle={{ flex: 1 }}>
              <View style={{ flex: 1 }}>
              {data && (
                <View style={styles.body}>
                  <View style={styles.color_border}>
                    <InvoiceData
                      data={data["invoice_payment_data"]}
                      invoice_id={invoice_id}
                    />
                    <TableHeader
                      data={header}
                      gridConfig={
                        isMobile
                          ? [3.5, 2, 2.4, 0.7, 0.3, 1, 1]
                          : showDiscount
                          ? [1, 1, 1.3, 1, 1, 1, 1, 1]
                          : [1, 1, 1, 1, 1, 1, 1, 1]
                      }
                      tableHeaderStyle={[styles.tableHeaderStyle]}
                    />
                    <View
                      style={{
                        // height:
                        //   data["plans"].length > 1 ? 0.38 * height : "auto",
                        paddingBottom: 30,
                      }}
                    >
                      <FlatList
                        data={data["plans"]}
                        renderItem={this.renderPlanDetails}
                        keyExtractor={(item) => item.plan}
                        style={{ paddingHorizontal: 17 }}
                      />
                    </View>
                  </View>
                  {data && data["paid_bills"].length > 0 && (
                    <View style={styles.detailsContainer}>
                      <View style={styles.headingContainer}>
                        <Text style={styles.header2}>Payment Details</Text>
                      </View>
                      <Table
                        header={headerData}
                        gridConfig={[0.2, 0.2, 0.2, 0.2, 0.2]}
                        data={values}
                        tableHeaderStyle={[styles.tableHeaderStyle2]}
                        pagination={false}
                        minWidth={1200}
                      />
                    </View>
                  )}
                </View>
              )}
              </View>
            </ScrollView>
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1, backgroundColor: "#fff" },
  body: {
    minWidth: 1200,
    // backgroundColor: "red",
    // width: isMobile ? 1200: "1
  },
  bills: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 18,
    color: "#434349",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  error: { fontSize: 15, color: "#12121280" },
  planwise: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#410DAA",
    fontWeight: "bold",
    paddingVertical: 10,
    paddingHorizontal: 43,
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.1)",
    borderRadius: 6,
    letterSpacing: 0.16,
    marginTop: 10,
  },
  plan_period: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#4A4B68",
  },
  tt_plan: {
    color: "#121212",
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
  },
  direct: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 15,
    marginLeft: -3,
    color: "#464E5F",
    letterSpacing: 0.32,
    fontWeight: "bold",
  },
  flat: {
    paddingTop: 40,
  },
  billId: {
    color: "#12121260",
    marginTop: 4,
  },
  view_dtls: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    fontWeight: "bold",
    color: "#410DAA",
  },
  left_bor: {
    borderLeftColor: "#6D39F5",
    borderLeftWidth: 4,
    marginLeft: 15,
  },
  box: {
    paddingVertical: 5,
    justifyContent: "center",
    marginLeft: 15,
  },
  invoice: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 21,
    color: "#410DAA",
    fontWeight: "bold",
    letterSpacing: 0.5,
  },
  date: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    color: "#434349",
    fontWeight: "bold",
  },
  inv_dte: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontSize: 13,
    lineHeight: 15,
    color: "#888C9F",
    paddingTop: 8,
  },
  invoice_box: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingBottom: 15,
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 15,
  },
  color_border: {
    borderWidth: 1,
    borderColor: "#DCC2FF",
    borderRadius: 22,
    marginVertical: 20,
    marginHorizontal: 18,
  },
  invest: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#4A4B68",
    paddingVertical: 12,
  },
  descr: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "bold",
    color: "#464E51",
    letterSpacing: 0.5,
  },
  strip: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#F3F6F9",
    paddingVertical: 12,
    paddingHorizontal: 37,
  },
  tableHeaderStyle: {
    paddingHorizontal: 32,
  },
  container2: {
    backgroundColor: "#fff",
    minWidth: 800,
  },
  paidDate2: {
    justifyContent: "flex-start",
    paddingLeft: 32,
  },
  detailsContainer: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#DCC2FF",
    borderRadius: 22,
    marginHorizontal: 16,
    marginVertical: 30,
  },
  headingContainer: {
    paddingHorizontal: 40,
    paddingVertical: 19,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header2: {
    fontFamily: "Roboto",
    color: "#410DAA",
    fontSize: 18,
    fontWeight: "700",
  },
  tableHeader2: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  tableHeaderStyle2: {
    paddingHorizontal: 32,
  },
  rowItem2: {
    textAlign: "left",
  },
});

export default InvoiceDetails;
