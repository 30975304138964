import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  Linking,
  FlatList,
} from "react-native";
import {
  createCrmdealsCipCbiForAccentureClients,
  createCrmdealsCipForRegular,
  generateCamsAggregatorLink,
  getAccountAggregatorConsentStatus,
  getLeadData,
  getLoginBannerDetails,
  uploadStockMF,
} from "../../../api";
import { Entypo } from '@expo/vector-icons';
import { md } from "../../../Responsive";
import { DataURIToBlob, completeMinDetails, getUserDetails, isMobile, isWeb, selectOneFile } from "../../../utils";
import Header from "../header_after_login";
import { FontAwesome5 } from '@expo/vector-icons';
const { width } = Dimensions.get("window");
import { MaterialIcons } from '@expo/vector-icons';
import { ShowToast } from "../../../components/Common/Toast";
import MiniOnBoardingProgressBar from "./MiniOnBoardingProgressBar";
import { saveCustomerProfileData } from "../../../api/account";
import Store from "../../../store";
import Modal from "../../../components/Common/Modal";
import { USER_IMPORT_FUNDS } from "../../../actions/types";
import amplitude from "../../../utils/amplitude";

class SignUpImportPortfolio extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let corp_code = "";
    let subscription_plan_code = "";
    let lead_id = "";
    let from = null;
    let step = 1;
    let ecres = null;
    let { is_goal } = Store.getState().userData;
    let { block_feature_list } = Store.getState().userData.userProfileData;
    if (block_feature_list == "CAMS_AA") {
      step = 2
    }
    if (params) {
      corp_code = params.ccd || "";
      subscription_plan_code = params.pcd || "";
      lead_id = params.ldid || "";
      from = params.from || "";
      step = params.step || 1
      ecres = params.ecres || null
    }
    let isCorpCode = false;
    if (corp_code != "") {
      isCorpCode = true;
    }
    this.state = {
      data: null,
      isLoading: false,
      ecres,
      name: "",
      from,
      file_upload_res: "Something went wrong",
      is_goal,
      email: "",
      mobile: "",
      password: "",
      repassword: "",
      showUploadToastError: false,
      showUploadToast: false,
      step,
      block_feature_list,
      is_ria: false,
      showOTP: false,
      loadingSkip: false,
      showResendOTP: false,
      timer: 0,
      fileLoading: false,
      aggregatorResponse: "Pending",
      showAggregatorModal: false,
      showModal: false,
      file_status: null,
      mobileError: "",
      corp_code,
      isCorpCode,
      subscription_plan_code,
      show_corp_code_box: false,
      lead_id,
      is_correct_lead: false,
      errorText: "",
      nameError: "",
      emailError: "",
      mobileError: "",
      passwordError: "",
      repasswordError: "",
      captchaText: "",
      captchaError: "",
      is_club: false,
      bannerData: {
        logo_image_url: null,
        name: null,
        ria_code: null,
        subscriber_type: null,
        support_email: null,
        support_person_name: null,
        support_phone_number: null,
        is_jamawealth: null,
      },
    };
  }

  loadBannerData = async () => {
    let { bannerData, lead_id } = this.state;
    if (lead_id != "") {
      const lead_payload = {
        "leadpk": lead_id
      }
      let resp = await getLeadData(lead_payload);
      if (resp && resp.code && resp.code == "100") {
        this.setState({
          name: resp.result.name,
          email: resp.result.email,
          mobile: resp.result.phone_number,
          is_correct_lead: true,
        });
      }
    }
    let response = null;
    let hostname = global.location.hostname;
    let is_club = false;
    let is_preferred_circle = false;
    if (hostname.includes("club")) {
      is_club = true;
    }
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    try {
      response = await getLoginBannerDetails();
    } catch (err) { }
    if (response && response.code == 100) {
      bannerData = response.result;
      this.setState({
        bannerData: bannerData,
        is_club: is_club,
        is_preferred_circle: is_preferred_circle,
      });
    } else {
      bannerData.logo_image_url =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/website/jam%C4%81_header.png";
      bannerData.name = "Jama Wealth";
      bannerData.ria_code = "INA 200015583";
      bannerData.subscriber_type = "RIAC";
      bannerData.support_email = "advisor@jamawealth.com";
      bannerData.support_person_name = "Ram Kalyan Medury";
      bannerData.support_phone_number = "9870264643";
      bannerData.is_jamawealth = true;
      this.setState({
        bannerData: bannerData,
        errorText: response.msg,
        is_club: is_club,
        is_preferred_circle: is_preferred_circle,
      });
    }
  };

  componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("SignUpImportPortfolio", { path: global.location.href })
    }
    else {
      amplitude.logEvent("SignUpImportPortfolio", { path: this.props.navigation.getState().routeName });
    }
    let is_ria = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (hostname.includes("simplygrow.in")) {
      is_ria = true;
    }
    this.setState({
      is_ria: is_ria,
    });
    this.generateCaptcha();
    this.loadBannerData();
    if (this.state.ecres) {
      this.handleCamsResponse();
    }
  }

  generateCaptcha = () => {
    var numberOne = Math.floor(Math.random() * 1000000) + 1;
    this.setState({ randomCaptcha: numberOne });
  };

  startCounter = () => {
    this.timerInterval = setInterval(() => {
      if (this.state.timer > 0) {
        this.setState(prevState => ({
          timer: prevState.timer - 1
        }));
      } else {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  handleCamsResponse = async () => {
    this.setState({ showAggregatorModal: true, timer: 60 })
    this.startCounter();
    let fail_count = 0;
    setTimeout(async () => {
      let response = await getAccountAggregatorConsentStatus();
      if (response && response.code == "100") {
        if (response.result == "Status Approved" || response.result == "Status Rejected") {
          this.setState({ aggregatorResponse: response.result })
          return;
        } else {
          fail_count++;
        }
      } else {
        fail_count++;
      }
      if (fail_count >= 6 || this.state.timer == 0) {
        this.setState({ aggregatorResponse: "Something went wrong" })
        return;
      }
    }, 5000)
  }


  handleAggregator = async () => {
    let userData = await getUserDetails();
    Store.dispatch({
      type: USER_IMPORT_FUNDS,
      payload: {
        user_import_funds: true
      }
    })
    let payload = {
      mobilenumber: userData.mobilenumber,
      pannumber: userData.pan_number,
      useCaseid: "331",
      fipid: "CAMC,Cams Depository",
      redirect_url: global.location.href,
    };
    // console.log("current urls is ", payload.redirect_url);
    this.setState({ isLoading: true, aggregatorResponse: "Pending" });
    let response = await generateCamsAggregatorLink(payload);
    this.setState({ isLoading: false });
    if (Platform.OS === "web") {
      global.open(response.result.redirectionurl, "_self");
    } else {
      Linking.openURL(response.result.redirectionurl);
    }
  }


  _selectFileMF = (fileType) => {

    selectOneFile(fileType).then(async (res) => {
      if (res) {
        this.setState({ fileLoading: true });
        this.setState({ isLoading: true });
        if (!res || res.assets === null) {
          this.setState({ fileLoading: false });
          return
        };
        const { assets } = res;
        const name = assets[0]["name"];
        const uri = assets[0]["uri"];
        const { username } = getUserDetails();
        let body = new FormData();
        const imageFile = DataURIToBlob(uri);
        body.append("file_type", "fund_imported");
        body.append("uploaded_excelname", name);
        body.append("uploaded_excel", imageFile, name);
        body.append("advisor", username);
        body.append("contract_note", null);
        body.append("password", "");
        body.append("subject", "");
        if (
          Store.getState().dashboard.viewModeUserId &&
          Store.getState().dashboard.viewModeUserId.user_id &&
          Store.getState().dashboard.viewModeUserId.user_id
        ) {
          body.append("cust_pk", Store.getState().dashboard.viewModeUserId.user_id);
        }
        await uploadStockMF(body)
          .then(async (resp) => {
            this.setState({ file_upload_res: resp })
            if (resp.code == 100) {
              this.setState({ showModal: true, file_status: true });
            } else {
              this.setState({
                showModal: true, file_status: false,
                error: resp.result && resp.result[0] && resp.result[0].Remarks,
              });
            }
            this.setState({ fileLoading: false });
          })
          .catch(() => {
            this.setState({ file_upload_res: "Something went wrong" })
            this.setState({ fileLoading: false });
            this.setState({ showModal: true, file_status: false, isLoading: false });
          });
      };
      this.setState({ fileLoading: false });
    });
  };

  _selectFile = (fileType) => {
    selectOneFile(fileType).then(async (res) => {
      if (res) {
        this.setState({ fileLoading: true });
        this.setState({ isLoading: true });
        // console.log("imageFile = ", res)
        if (!res || res.assets === null) {
          this.setState({ fileLoading: false });
          return
        };
        const { assets } = res;
        const name = assets[0]["name"];
        const uri = assets[0]["uri"];
        const { username } = getUserDetails();
        // console.log("imageFile = ", username)
        let body = new FormData();
        const imageFile = DataURIToBlob(uri);
        // console.log("imageFile = ", imageFile)
        body.append("file_type", "stock_imported");
        body.append("uploaded_excelname", name);
        body.append("uploaded_excel", imageFile, name);
        body.append("advisor", username);
        body.append("contract_note", null);
        body.append("password", "");
        body.append("subject", "");
        if (
          Store.getState().dashboard.viewModeUserId &&
          Store.getState().dashboard.viewModeUserId.user_id &&
          Store.getState().dashboard.viewModeUserId.user_id
        ) {
          body.append("cust_pk", Store.getState().dashboard.viewModeUserId.user_id);
        }
        await uploadStockMF(body)
          .then((resp) => {
            this.setState({ file_upload_res: resp })
            if (resp.code == 100) {
              this.setState({ showModal: true, file_status: true });
            } else {
              this.setState({
                showModal: true, file_status: false,
                error: resp.result && resp.result[0] && resp.result[0].Remarks,
              });
            }
            this.setState({ fileLoading: false });
            this.setState({ showModal: true, file_status: false });
          })
          .catch(() => {
            this.setState({ file_upload_res: "Something went wrong" })
            this.setState({ fileLoading: false });
            this.setState({ showModal: true, file_status: false });
          });
      };
      this.setState({ fileLoading: false });
    });

  };

  downloadSampleFile = () => {
    saveAs(
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/media/sample_uploaders/stock_imported_sample_file.xlsx",
      "stock_imported_sample_file.xlsx"
    );
  };

  downloadSampleFileMF = () => {
    saveAs(
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/media/sample_uploaders/fund_imported_sample_file.xlsx",
      "fund_imported_sample_file.xlsx"
    );
  };

  renderItemStocks = ({ item }) => (
    <View style={styles.row}>
      <Text style={styles.cell}>{item.marketscrip_name}</Text>
      <Text style={styles.cell}>{item["NSE Symbol / ISIN Code"]}</Text>
      <Text style={styles.cell}>{item.Quantity}</Text>
      <Text style={styles.cell}>{item.broker}</Text>
      <Text style={styles.cell}>{item['Date of Acquisition']}</Text>
      <Text style={styles.cell}>{item['Average Cost of Purchase']}</Text>
      <View style={styles.cell}>{item.Remarks == "" ? (<Entypo name="check" size={24} color="green" />) : (<Text style={{ color: 'red', fontWeight: '500' }}>{item.Remarks}</Text>)}</View>
    </View>
  );

  renderItemFunds = ({ item }) => (
    <View style={styles.row}>
      <Text style={styles.cell}>{item.fund_name}</Text>
      <Text style={styles.cell}>{item["ISIN Code"]}</Text>
      <Text style={styles.cell}>{item.Units}</Text>
      <Text style={styles.cell}>{item['Date of Acquisition']}</Text>
      <Text style={styles.cell}>{item['Average Purchase NAV']}</Text>
      <View style={styles.cell}>{item.Remarks == "" ? (<Entypo name="check" size={24} color="green" />) : (<Text style={{ color: 'red', fontWeight: '500' }}>{item.Remarks}</Text>)}</View>
    </View>
  );

  render() {
    const {
      isLoading,
      errorText,
      name,
      email,
      showUploadToast,
      showUploadToastError,
      fileLoading,
      mobile,
      nameError,
      emailError,
      mobileError,
      otpNumber,
      otpError,
      bannerData,
      corp_code,
      is_goal
    } = this.state;
    return (
      <View style={styles.container}>
        <Header
          navigation={this.props.navigation}
          route={this.props.route}
          showbackBtn={this.state.from == "mini_risk_profile" ? true : (this.state.step == 1 || this.state.step == 2 ? false : true)}
          onPressBack={() => {
            if (this.state.from == "mini_risk_profile") {
              this.props.navigation.goBack();
            }
            if (this.state.step == 2) {
              if (this.state.block_feature_list != "CAMS_AA")
                this.setState({ step: 1 })
            } else if (this.state.step == 3) {
              this.setState({ step: 2 })
            }
          }}
        />
        <ShowToast
          duration={3000}
          type="success"
          description={"File uploaded successfully"}
          title={"File upload"}
          showToast={showUploadToast}
        />
        <ShowToast
          duration={3000}
          type="error"
          description={"Something went wrong"}
          title={"File upload"}
          showToast={showUploadToastError}
        />
        {/* {(this.state.is_preferred_circle || this.state.is_club) &&
          this.state.from ? (
          <ShowToast
            showToast={true}
            type="success"
            title={`Congratulations! You have successfully subscribed to our ${this.state.is_preferred_circle
              ? "Advisory Circle"
              : "Investor Club"
              } plan.`}
          />
        ) : null} */}
        {this.state.showOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent successfully."
          />
        ) : null}
        {this.state.showResendOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent successfully."
          />
        ) : null}
        {is_goal ? (
          <MiniOnBoardingProgressBar
            color2="#410DAA"
            color3="#410daa"
            color4="#410DAA"
            color5="#f6f6f6"
            color6="#f6f6f6"
            color11="#f6f6f6"
            color12="#f6f6f6"
            bcolor1="#410DAA"
            bcolor2="#410DAA"
            bcolor3="#410DAA"
            bagcolor1="#410DAA"
            bagcolor2="#410DAA"
            bagcolor3="#410DAA"
            markdisplay1="none"
            markdisplay2="none"
            markdisplay3="none"
            dotdisplay1="none"
            dotdisplay2="none"
            dotdisplay3="none" />
        ) : (
          <MiniOnBoardingProgressBar
            color2="#410DAA"
            color3="#f6f6f6"
            color4="#f6f6f6"
            color5="#f6f6f6"
            color6="#f6f6f6"
            color11="#f6f6f6"
            color12="#f6f6f6"
            bcolor1="#410DAA"
            bcolor2="#410DAA"
            bcolor3="#410DAA"
            bagcolor1="#410DAA"
            bagcolor2="#410DAA"
            bagcolor3="#410DAA"
            markdisplay1="none"
            markdisplay2="none"
            markdisplay3="none"
            dotdisplay1="none"
            dotdisplay2="none"
            dotdisplay3="none" />
        )}
        {/* {console.log("Value of file status ", this.state.file_status)}
        {console.log("Value of file resp ", this.state.file_upload_res)} */}

        {this.state.showAggregatorModal && (<Modal style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
          <View style={{
            padding: 50
          }}>
            {this.state.aggregatorResponse == "Status Approved" ? (
              <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <FontAwesome5 name="check" size={32} color="green" />
                <Text style={{
                  color: '#1a1a1a',
                  fontWeight: '700',
                  marginTop: 15
                }} >Yay! Your consent status is approved</Text>
                <Text style={{
                  color: '#1a1a1a',
                  fontSize: 14,
                  fontWeight: '400',
                  textAlign: 'center',
                  marginTop: 10
                }} >The account aggregator will send your portfolio data within ten minutes.</Text>
                {/* <Text style={{
                  color: '#1a1a1a',
                  fontSize: 14,
                  fontWeight: '400',
                  marginTop: 5
                }} >We will email you once received.</Text> */}
                <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                  <TouchableOpacity style={{
                    backgroundColor: '#410DAA',
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5
                  }} onPress={async () => {
                    const { customer_id, customerprofile_id } = getUserDetails();
                    const body = {
                      customer: customer_id,
                      skip_onboarding_import_portfolio: true
                    };
                    await saveCustomerProfileData(customerprofile_id, body);
                    let minDetailsResponse = await completeMinDetails(null, null, null, false);
                    if (minDetailsResponse.needCompletion) {
                      this.props.navigation.navigate("Onboarding", {
                        screen: minDetailsResponse.redirect_screen
                      });
                    } else {
                      let hostname = global.location.hostname
                      if (hostname.includes("app") || hostname.includes("myadvisor")) {
                        await createCrmdealsCipCbiForAccentureClients();
                      }
                      if (hostname.includes("club")) {
                        await createCrmdealsCipForRegular();
                      }

                      this.props.navigation.navigate("App", {
                        screen: "Dashboard",
                        params: {
                          show_card_view: true
                        }
                      });
                    }
                    this.setState({ showAggregatorModal: false })
                  }}>
                    <Text style={{ color: '#fff', textAlign: 'center' }}>Continue</Text>
                  </TouchableOpacity>
                </View>
              </View>
            ) : this.state.aggregatorResponse == "Status Rejected" ? (
              <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                <MaterialIcons name="cancel" size={32} color="red" />
                <Text style={{
                  color: '#1a1a1a',
                  fontWeight: '700',
                  marginTop: 15
                }} >Oh oh! Looks like you did not consent</Text>
                <Text style={{
                  color: '#1a1a1a',
                  fontSize: 14,
                  fontWeight: '400',
                  textAlign: 'center',
                  marginTop: 10
                }} >Please note: your portfolio analysis is only to help simply grow it (to import again click on Fetch Portfolio)</Text>
                <Text style={{
                  color: '#1a1a1a',
                  fontSize: 14,
                  textAlign: 'center',
                  fontWeight: '400',
                  marginTop: 5
                }} >You may try other import options to pick and choose what you wish to import</Text>
                <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 30 }}>
                  <TouchableOpacity style={{
                    backgroundColor: '#410DAA',
                    padding: 10,
                    width: 150,
                    marginRight: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5
                  }} onPress={() => {
                    this.setState({ showAggregatorModal: false })
                  }}>
                    <Text style={{ color: '#fff', textAlign: 'center' }}>Fetch Portfolio</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={{
                    backgroundColor: '#fff',
                    padding: 10,
                    borderColor: '#410DAA',
                    borderWidth: 1,
                    width: 250,
                    marginRight: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 5
                  }} onPress={() => {
                    this.setState({ showAggregatorModal: false, step: 2 })
                  }}>
                    <Text style={{ color: '#410DAA', textAlign: 'center' }}>Try Other Import Options</Text>
                  </TouchableOpacity>
                </View>
              </View>
            ) : this.state.aggregatorResponse == "Pending" ? (
              <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size={'large'} color={"#410DAA"} />
                {this.state.timer > 40 ? (
                  <View>
                    <Text style={{
                      color: '#1a1a1a',
                      fontWeight: '700',
                      marginTop: 15
                    }} >Fetching consent status from Account Aggregator</Text>
                    <Text style={{
                      color: '#1a1a1a',
                      fontSize: 14,
                      fontWeight: '400',
                      marginTop: 8
                    }} >This may take several minutes.</Text>
                    <Text style={{
                      color: '#1a1a1a',
                      fontSize: 14,
                      fontWeight: '400',
                      marginTop: 8
                    }}>
                      If it takes longer, we will notify you once the analysis is ready
                    </Text>
                  </View>
                ) : (
                  <View>
                    <Text style={{
                      color: '#1a1a1a',
                      fontWeight: '700',
                      marginTop: 15
                    }} >Oops, this is taking longer than expected. </Text>
                    <Text style={{
                      color: '#1a1a1a',
                      fontSize: 14,
                      fontWeight: '400',
                      marginTop: 8
                    }} >Our team will get back to you once the data from the AA is received. </Text>
                    <Text style={{
                      color: '#1a1a1a',
                      fontSize: 14,
                      fontWeight: '400',
                      marginTop: 8
                    }}>
                      You may log in later to see the status on the dashboard.
                    </Text>
                    <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                      <TouchableOpacity style={{
                        backgroundColor: '#410DAA',
                        paddingHorizontal: 20,
                        paddingVertical: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 5
                      }} onPress={async () => {
                        const { customer_id, customerprofile_id } = getUserDetails();
                        const body = {
                          customer: customer_id,
                          skip_onboarding_import_portfolio: true
                        };
                        await saveCustomerProfileData(customerprofile_id, body);
                        let minDetailsResponse = await completeMinDetails(null, null, null, false);
                        if (minDetailsResponse.needCompletion) {
                          this.props.navigation.navigate("Onboarding", {
                            screen: minDetailsResponse.redirect_screen
                          });
                        } else {
                          let hostname = global.location.hostname
                          if (hostname.includes("app") || hostname.includes("myadvisor")) {
                            await createCrmdealsCipCbiForAccentureClients();
                          }
                          if (hostname.includes("club")) {
                            await createCrmdealsCipForRegular();
                          }

                          this.props.navigation.navigate("App", {
                            screen: "Dashboard",
                            params: {
                              show_card_view: true
                            }
                          });
                        }
                        this.setState({ showAggregatorModal: false })
                      }}>
                        <Text style={{ color: '#fff', textAlign: 'center' }}>Continue</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
              </View>
            ) : (
              <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <MaterialIcons name="error-outline" size={32} color="red" />
                <Text style={{
                  color: '#1a1a1a',
                  fontWeight: '700',
                  marginTop: 15
                }} > Oops! Your consent status is still not received</Text>
                <Text style={{
                  color: '#1a1a1a',
                  fontSize: 14,
                  fontWeight: '400',
                  textAlign: 'center',
                  marginTop: 10
                }} >We will check with the account aggregator and keep you updated.</Text>
                <Text style={{
                  color: '#1a1a1a',
                  fontSize: 14,
                  fontWeight: '400',
                  textAlign: 'center',
                  marginTop: 5
                }} >you can try other options to import your portfolio</Text>
                <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 30 }}>
                  <TouchableOpacity style={{
                    backgroundColor: '#410DAA',
                    padding: 10,
                    justifyContent: 'center',
                    width: 150,
                    marginLeft: 20,
                    alignItems: 'center',
                    borderRadius: 5
                  }} onPress={() => {
                    this.setState({ showAggregatorModal: false, step: 2 })
                  }}>
                    <Text style={{ color: '#fff', textAlign: 'center' }}>Continue</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </View>
        </Modal>)}
        {/* this model is used to show wheather the file is uploaded is sucessfully or not   */}
        {this.state.showModal && (<Modal style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
          <View style={{
            paddingVertical: 20,
            paddingHorizontal: 50
          }}>
            <View style={{
              padding: 10,
              marginBottom: 20
            }}>
              <Text style={{
                fontSize: 20,
                fontWeight: '700',
                color: !(this.state.file_upload_res && this.state.file_upload_res.code == 100) ? "red" : "green"
              }}>{!(this.state.file_upload_res && this.state.file_upload_res.code == 100) ? "Could not upload file" : "File Uploaded Successfully"}</Text>
            </View>
            {this.state.file_upload_res == "Something went wrong" ? (<View style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Text style={{
                fontSize: 16,
                fontWeight: '400',
              }}>Something went wrong</Text>
            </View>) : (<View style={{
              paddingLeft: 20
            }}>
              {this.state.file_upload_res.code != 104 && <Text style={{
                color: '#1a1a1a',
                fontWeight: '500'
              }}>{this.state.file_upload_res && this.state.file_upload_res.result}</Text>}
              {this.state.file_upload_res.code == 104 && (<View style={{ maxHeight: 300 }}>
                <FlatList
                  data={this.state.file_upload_res.result}
                  keyExtractor={(item, index) => index.toString()}
                  renderItem={this.state.step == 2 ? this.renderItemStocks : this.renderItemFunds}
                  ListHeaderComponent={() => this.state.step == 2 ? (
                    <View style={styles.header}>
                      <Text style={styles.headerText}>Script Name</Text>
                      <Text style={styles.headerText}>Symbol/ISIN Code</Text>
                      <Text style={styles.headerText}>Quantity</Text>
                      <Text style={styles.headerText}>Broker</Text>
                      <Text style={styles.headerText}>Date of Acquisition</Text>
                      <Text style={styles.headerText}>Avg Cost of Purchase</Text>
                      <Text style={styles.headerText}>Remarks</Text>
                    </View>
                  ) : (
                    <View style={styles.header}>
                      <Text style={styles.headerText}>Fund Name</Text>
                      <Text style={styles.headerText}>ISIN Code</Text>
                      <Text style={styles.headerText}>Units</Text>
                      <Text style={styles.headerText}>Date of Acquisition</Text>
                      <Text style={styles.headerText}>Average Purchase NAV</Text>
                      <Text style={styles.headerText}>Remarks</Text>
                    </View>
                  )}
                  stickyHeaderIndices={[0]}
                />
              </View>)}
              {(this.state.file_upload_res.code != 100 && this.state.file_upload_res.code != 104) && (<Text>{this.state.file_upload_res && this.state.file_upload_res.msg}</Text>)}
            </View>)}
            <View style={{
              marginTop: 50,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <TouchableOpacity style={{
                backgroundColor: '#410DAA',
                padding: 15,
                width: 150,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 5
              }} onPress={async () => {
                if (this.state.file_upload_res && this.state.file_upload_res.code == 100) {
                  if (this.state.step == 3) {
                    const { customer_id, customerprofile_id } = getUserDetails();
                    const body = {
                      customer: customer_id,
                      skip_onboarding_import_portfolio: true
                    };
                    let { is_goal } = Store.getState().userData;
                    await saveCustomerProfileData(customerprofile_id, body);
                    const minDetailsResponse = await completeMinDetails(null, null, null, false);
                    let hostname = global.location.hostname
                    if (hostname.includes("app") || hostname.includes("myadvisor")) {
                      await createCrmdealsCipCbiForAccentureClients();
                    }
                    if (hostname.includes("club")) {
                      await createCrmdealsCipForRegular();
                    }
                    if (is_goal) {
                      // console.log("i'm clicked");
                      if (minDetailsResponse.needCompletion) {
                        this.props.navigation.navigate("Onboarding", {
                          screen: minDetailsResponse.redirect_screen
                        });
                      } else {

                        this.props.navigation.navigate("App", {
                          screen: "Dashboard",
                          params: {
                            show_card_view: true
                          }
                        });
                      }
                    } else {
                      this.props.navigation.navigate("Onboarding", {
                        screen: "SignUpFinancialPlan",
                      });
                    }
                  } else {
                    this.setState({ step: 3 })
                  }
                }
                this.setState({ showModal: false })
              }}>
                <Text style={{ color: 'white' }}>{(this.state.file_upload_res && this.state.file_upload_res.code == 100) ? "Continue" : "Try again"}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>)}
        <ScrollView keyboardShouldPersistTaps="always">

          <View style={styles.body}>
            {isWeb && (
              <View style={styles.left}>
                <View style={{ paddingBottom: 50 }}>
                  <View style={{ opacity: 0 }}>
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-2.png"
                      }
                      style={styles.left_logo}
                    ></Image>
                  </View>
                  <View style={{ position: 'absolute', zIndex: 10 }}>
                    <View style={{ marginBottom: 25 }}>
                      <Text style={{ color: '#410DAA', fontSize: 26, fontWeight: '700' }}>Portfolio Analysis Benefits</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>1. Know under performing stocks / funds.</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>2. Identify any risky investments.</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>3. Get ideas to invest better.</Text>
                    </View>
                  </View>
                </View>
                <Text style={{ textAlign: 'center', color: '#410DAA', fontWeight: '500', marginTop: 5, marginHorizontal: '10%' }}>
                  Note: This report is not investment advice. After your onboarding and riskprofiling and personalised advisory report shall be provided.
                </Text>
              </View>
            )}
            <View style={styles.right}>
              <View style={[isMobile ? styles.mob_styl : styles.web_styl]}>
                <View style={{ width: isMobile ? "100%" : '80%', marginBottom: 20 }}>
                  {this.state.loadingSkip ? 
                  <ActivityIndicator size={"small"} color={"#410DAA"} /> : <Text style={{ color: '#410DAA', fontWeight: '500', textDecorationLine: 'underline', textAlign: 'right' }} onPress={async () => {
                    if (this.state.step == 1) {
                      this.setState({ step: 2 })
                    } else if (this.state.step == 2) {
                      this.setState({ step: 3 })
                    }
                    else {
                      this.setState({ loadingSkip :  true})
                      try{
                      const { customer_id, customerprofile_id } = getUserDetails();
                      const body = {
                        customer: customer_id,
                        skip_onboarding_import_portfolio: true
                      };
                      let { is_goal } = Store.getState().userData;
                      await saveCustomerProfileData(customerprofile_id, body);
                      const minDetailsResponse = await completeMinDetails();
                      let hostname = global.location.hostname
                      if (hostname.includes("app") || hostname.includes("myadvisor")) {
                        await createCrmdealsCipCbiForAccentureClients();
                      }
                      if (hostname.includes("club")) {
                        await createCrmdealsCipForRegular();
                      }
                      if (is_goal) {
                        // console.log("i'm clicked");
                        if (minDetailsResponse.needCompletion) {
                          this.props.navigation.navigate("Onboarding", {
                            screen: minDetailsResponse.redirect_screen
                          });
                        } else {
                          let hostname = global.location.hostname
                          if (hostname.includes("app") || hostname.includes("myadvisor")) {
                            await createCrmdealsCipCbiForAccentureClients();
                          }
                          if (hostname.includes("club")) {
                            await createCrmdealsCipForRegular();
                          }
                          this.props.navigation.navigate("App", {
                            screen: "Dashboard",
                            params: {
                              show_card_view: true
                            }
                          });
                        }
                      } else {
                        this.props.navigation.navigate("Onboarding", {
                          screen: "SignUpFinancialPlan",
                        });
                      }
                    }catch(err){
                      this.setState({selected: false})
                      // console.log("signup portfolio onboarding error =", err)
                    }
                    }
                  }}>{this.state.step == 1 ? "Skip Fetch from Aggregator" : `Skip FREE ${this.state.step == 2 ? "Stock" : "Mutual Funds"} Portfolio Analysis`}</Text>}
                </View>
                {this.state.step == 1 ? (<View style={styles.inputsContainer}>
                  <View style={[styles.inputContainer, { marginTop: '10%' }]}>
                    <Text style={{ fontSize: 22, fontWeight: '700', marginBottom: 20 }}>Import Portfolio - Get Free Analysis Report</Text>
                    <TouchableOpacity
                      onPress={this.handleAggregator}
                      style={{
                        paddingHorizontal: 16,
                        width: "48%",
                        marginTop: 30,
                        backgroundColor: "#410DAA",
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 15,
                        borderRadius: 5
                      }} >
                      <Text style={{ color: 'white' }}>Continue</Text>
                    </TouchableOpacity>
                    <View style={{ marginTop: 80 }}>
                      <Text>In a few seconds, we can fetch your current holdings to enable FREE portfolio review.</Text>
                    </View>
                  </View>
                  {/* <Text style={styles.mob_num}>This will take you to the CAMS Account Aggregator import features to import mutual funds and  stocks.</Text> */}
                </View>) : (<View style={styles.inputsContainer}>
                  <View style={styles.inputContainer}>
                    {/* <Text style={[styles.mob_num, { marginHorizontal: 20, marginTop: 25 }]}>You can also upload existing portfolio statements (pdf, xls etc).</Text> */}
                    {this.state.step == 2 ? (<View style={{ flexDirection: "row" }}>
                      <View style={[styles.inputContainer, { marginTop: '0%' }]}>
                        <Text style={{ fontSize: 22, fontWeight: '700', marginBottom: 20, marginTop: 40 }}>Direct Stock Upload</Text>
                        <View style={{ marginTop: 10, marginBottom: 10 }}>
                          <Text>Import stocks. Get FREE preview of analysis instantly.</Text>
                        </View>
                        {/* <View style={{ marginTop: 10, marginBottom: 10 }}>
                          <Text style={{color: "#a8a8a8"}}>Search email for subject 'Your NSDL CAS'.</Text>
                        </View> */}
                        <View>
                          <TouchableOpacity onPress={() => {
                            this.downloadSampleFile();
                          }}>
                            <Text style={{
                              color: "#410DAA",
                              textDecorationLine: "underline",
                              fontSize: 14,
                              fontWeight: '700'
                            }}>Download Sample</Text>
                          </TouchableOpacity>
                        </View>
                        <TouchableOpacity
                          onPress={() => {
                            this._selectFile([
                              "application/pdf",
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            ]);
                          }}
                          style={{
                            paddingHorizontal: 16,
                            width: "70%",
                            marginTop: 30,
                            backgroundColor: "#410DAA",
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 15,
                            borderRadius: 5
                          }} >
                          {fileLoading ? (<ActivityIndicator size={"small"} color={"#ffffff"} />) : (<Text style={{ color: 'white' }}>Select File</Text>)}
                        </TouchableOpacity>
                      </View>
                      {/* <ImportFunction
                        title="Direct Stock Upload"
                        btnStyle={{ width: '70%', marginLeft: '15%' }}
                        description={[
                          {
                            text: "Upload your stock portfolio as per sample shared below.",
                          },
                          {
                            text: "Search email for subject 'Your NSDL CAS'.",
                            color: "#a8a8a8",
                          },
                        ]}
                        sample={true}
                        error={""}
                        buttonTitle="Stock Statement Upload"
                        downloadSample={}
                        style={{
                          paddingHorizontal: 16,
                          paddingVertical: 30,
                          width: "92%",
                        }}
                      /> */}
                    </View>) : (<View style={{ flexDirection: "row" }}>
                      <View style={[styles.inputContainer, { marginTop: '0%' }]}>
                        <Text style={{ fontSize: 22, fontWeight: '700', marginBottom: 20, marginTop: 40 }}>Direct Mutual Funds Upload</Text>
                        <View style={{ marginTop: 10, marginBottom: 10 }}>
                          <Text>Import funds. Get FREE preview of analysis instantly.</Text>
                        </View>
                        {/* <View style={{ marginTop: 10, marginBottom: 10 }}>
                          <Text style={{color: "#a8a8a8"}}>Addition details related to the functionality.</Text>
                        </View> */}
                        <View>
                          <TouchableOpacity onPress={() => {
                            this.downloadSampleFileMF();
                          }}>
                            <Text style={{
                              color: "#410DAA",
                              textDecorationLine: "underline",
                              fontSize: 14,
                              fontWeight: '700'
                            }}>Download Sample</Text>
                          </TouchableOpacity>
                        </View>
                        <TouchableOpacity
                          onPress={() => {
                            this._selectFileMF([
                              "application/pdf",
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            ]);
                          }}
                          style={{
                            paddingHorizontal: 16,
                            width: "70%",
                            marginTop: 30,
                            backgroundColor: "#410DAA",
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 15,
                            borderRadius: 5
                          }} >
                          {fileLoading ? (<ActivityIndicator size={"small"} color={"#ffffff"} />) : (<Text style={{ color: 'white' }}>Select File</Text>)}
                        </TouchableOpacity>
                      </View>
                      {/* <ImportFunction
                        title="Direct Mutual Funds Upload"
                        btnStyle={{ width: '70%', marginLeft: '15%' }}
                        description={[
                          {
                            text: "Upload your MF portfolio as per sample shared below.",
                          },
                          {
                            text: "Addition details related to the functionality.",
                            color: "#a8a8a8",
                          },
                        ]}
                        sample={true}
                        error={""}
                        buttonTitle="Mutual Fund Statement Upload"
                        onPress={() => {
                          this._selectFileMF([
                            "application/pdf",
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          ]);
                        }}
                        downloadSample={() => {
                          this.downloadSampleFileMF();
                        }}
                        style={{
                          paddingHorizontal: 16,
                          paddingVertical: 30,
                          width: "92%",
                        }}
                      /> */}
                    </View>)}
                  </View>
                </View>)}
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexGrow: 1,
    height: "100%",
    backgroundColor: "#fff",
  },
  body: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  left: {
    flex: md(width) ? 1 : 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    paddingVertical: md(width) ? 75 : 1,
  },
  right: {
    flex: 1,
  },
  mob_styl: {
    width: "90%",
    justifyContent: 'center',
    alignSelf: "center",
  },
  web_styl: {
    alignItems: "center",
  },
  captchaContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: isMobile ? -10 : -20,
    marginBottom: 10,
  },
  best: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 33,
    lineHeight: 38,
    color: "#464E5F",
    paddingBottom: 100,
  },
  left_logo: {
    resizeMode: "contain",
    width: 403,
    height: 241,
    alignSelf: "center",
    marginBottom: 60,
  },
  mobile_verf: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 19 : 15,
    lineHeight: md(width) ? 22 : 17,
    textAlign: "center",
    color: "#121212",
    paddingTop: md(width) ? 0 : 50,
  },
  we_will: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 17 : 13,
    lineHeight: md(width) ? 20 : 18,
    textAlign: "justify",
    color: "#A7A8BB",
    paddingTop: md(width) ? 19 : 29,
  },
  mob_num: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 18,
    color: "#222225",
    paddingBottom: md(width) ? 23 : 10.5,
  },
  please: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3,
  },
  req_otp: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 47,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  one: {
    color: "#121212",
  },
  dmart_body_md: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "bold",
    color: "#121212",
    backgroundColor: "#f6f6f6",
    height: 41.02,
    paddingLeft: 20,
    borderRadius: 4,
  },
  sebi: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 14,
    color: "#000",
    paddingRight: 29,
  },
  sebi_1: {
    fontFamily: "Arial",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: 9,
    lineHeight: 14,
    color: "#51514E",
    paddingRight: 29,
  },
  ina: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 10,
    lineHeight: 14,
    color: "#410DAA",
  },
  img1: {
    width: 30,
    height: 28.6,
  },
  welcomeTitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    color: "#202020",
    fontWeight: "bold",
    marginBottom: 45,
    marginTop: 10,
    textAlign: "center",
  },
  inputsContainer: {
    minWidth: "75%",
  },
  inputContainer: {
    marginBottom: 20,
  },
  forgotPassword: {
    color: "#232425",
    fontSize: 15,
    fontFamily: "Roboto",
    marginTop: 18,
    textAlign: "center",
  },
  clickHere: {
    color: "#410DAA",
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 6,
    textAlign: "center",
  },
  certication: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 25,
  },
  header: {
    flexDirection: 'row',
    backgroundColor: '#f2f2f2',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  headerText: {
    fontWeight: 'bold',
    textAlign: 'center',
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    padding: 10,
  },
  cell: {
    textAlign: 'center',
    flex: 1,
  },
  sebiContainer: {
    flexDirection: "row",
    paddingTop: 25,
  },
  certicationsContainer: {
    flexDirection: "row",
    paddingLeft: 30.5,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  popableContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
});



export default SignUpImportPortfolio;
