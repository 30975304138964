export const CONNECTION_STATUS_CHANGE = "CONNECTION_STATUS_CHANGE";
export const SAVE_USER_PROFILE_DATA = "SAVE_PROFILE_DATA";
export const SAVE_SCREEN_CONFIG = "SAVE_SCREEN_CONFIG";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const GET_PORTFOLIO = "GET_PORTFOLIO";
export const GET_PLANS = "GET_PLANS";
export const GET_EXPLORE = "GET_EXPLORE";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const SHOW_GET_STARTED = "SHOW_GET_STARTED";
export const SHOW_CORPORATE_STICKER = "SHOW_CORPORATE_STICKER";
export const IS_NEW_USER = "IS_NEW_USER";
export const IS_GOAL = "IS_GOAL";
export const DASHBOARD_PORTFOLIO_ANALYSIS_CLOSED = "DASHBOARD_PORTFOLIO_ANALYSIS_CLOSED";
export const USER_IMPORT_FUNDS = "USER_IMPORT_FUNDS";