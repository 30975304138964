import React from "react";
import { View, Text, StyleSheet,Platform } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { getCustomerDetails } from "../api";
import { MAIN_URL } from "../api/urls";
import { encryptToken } from "../utils";
import amplitude from "../utils/amplitude";

export const redirectToDjangoScreen = (token) => {
  const encryptedToken = encryptToken(token);
  const url =
    MAIN_URL +
    "/app/rnw_to_web_login?token=" +
    encryptedToken +
    "&redirection_url=/app/";
  global.open(url, "_self");
};
export default class AdminLogin extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    const { params } = this.props.route;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }
    this.state = {
      isLoading: true,
      userId,
    };
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("AdminLogin",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("AdminLogin", { path: this.props.navigation.getState().routeName });
      }
      const { userId } = this.state;
      let userIDToFetchData = userId;
      if (userIDToFetchData) {
        const customerDetails = await getCustomerDetails(userIDToFetchData);
        const { token } = customerDetails;
        redirectToDjangoScreen(token);
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Error redirecting to admin login page !",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  render() {
    return (
      <View style={styles.mainContainer}>
        <View style={styles.loadingContainer}>
          {this.state.isLoading && (
            <ActivityIndicator size="large" color="#410DAA" />
          )}
          {this.state.error && (
            <Text style={styles.error}>{this.state.errorText || " "}</Text>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  error: { fontSize: 14, color: "#121212" },
});
