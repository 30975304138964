import React, {Component} from "react";
import {View,Text} from "react-native";

export default class Modal extends React.Component {
    constructor(props){
        super(props);
        this.state={}
    }

    render(){
        const {style={}, containerStyle={}} = this.props;
        return(
            <View 
                style={[{
                    backgroundColor:"#00000050",zIndex: 100,
                    position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                },style]}
            >
                <View style={[{backgroundColor:"#fff"},containerStyle]}>
                    {this.props.children}
                </View>
            </View>
        )
    }
}