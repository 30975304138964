import React from 'react';
import { StyleSheet, View, Text, Dimensions } from 'react-native';
import { md } from '../../Responsive'
import InvestOverviewMobile from './Mobile';
import InvestOverviewWeb from './Web';
const window = Dimensions.get('window');

export default function InvestOverview ({ navigation, route }) {
    const pendingContractsCount = 0;
    return md(window.width) ? <InvestOverviewWeb navigation={navigation} pendingContractsCount={pendingContractsCount} route={route}/> : <InvestOverviewMobile navigation={navigation} pendingContractsCount={pendingContractsCount} route={route}/> ;
}