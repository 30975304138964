import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  Dimensions,
  ActivityIndicator,
} from "react-native";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;
import { isMobile } from "../../../../utils";
import { FontAwesome5 } from "@expo/vector-icons";
import { md } from "../../../../Responsive";
import { TouchableOpacity } from "react-native";
const { width } = Dimensions.get("window");
const isWeb = md(width);

const MFHoldingDetails = ({ selectedMFTab, mfReportLoading, viewMFReport }) => {
  let hostname = global.location.hostname;
  let is_club = false;
  if (hostname.includes("club")) {
    is_club = true;
  }
  return (
    <View style={select.container}>
      <View style={select.body}>
        {!isMobile &&
          Dimensions.get("window").width / Dimensions.get("window").height >
          2.1 && (
            <View
              style={{
                flex: 1,
                justifyContent: "flex-end",
                flexDirection: "row",
                // marginRight: 20,
              }}
            >
              {selectedMFTab == "Jama" && (!is_club) ? (
                mfReportLoading ? (
                  <View style={select.loadingConainer}>
                    <ActivityIndicator size="large" color="#410DAA" />
                  </View>
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      viewMFReport();
                    }}
                  >
                    <Text
                      style={{
                        color: "#410DAA",
                        fontWeight: "bold",
                        fontSize: 15,
                        textAlign: "right",
                        // marginRight: isMobile ? "auto" : 25,
                        textDecorationLine: "underline",
                      }}
                    >
                      Monthly Report
                    </Text>
                  </TouchableOpacity>
                )
              ) : null}
            </View>
          )}
      </View>
      {(isMobile ||
        Dimensions.get("window").width / Dimensions.get("window").height <=
        2.1) && (
          <View
            style={{
              flex: 1,
              cursor: "pointer",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            {selectedMFTab == "Jama" && (!is_club) ? (
              mfReportLoading ? (
                <View style={select.loadingConainer}>
                  <ActivityIndicator size="large" color="#410DAA" />
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    viewMFReport();
                  }}
                >
                  <Text
                    style={{
                      color: "#410DAA",
                      fontWeight: "bold",
                      fontSize: 15,
                      textAlign: "right",
                      // marginRight: isMobile ? "auto" : 25,
                      textDecorationLine: "underline",
                    }}
                  >
                    Monthly Report
                  </Text>
                </TouchableOpacity>
              )
            ) : null}
          </View>
        )}
    </View>
  );
};

const select = StyleSheet.create({
  container: {
    // marginTop: 10,
    // marginBottom: 20,
    // marginLeft: isMobile ? 16 : 20,
    // marginRight: isMobile ? 16 : 10,
  },
  product: {
    color: "#464E5F",
    fontSize: 14,
    fontFamily: "Roboto",
    marginRight: 20,
  },
  body: {
    // flexDirection: "row",
    // alignItems: "center",
    // display: "flex",
  },
  button: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 14 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  loadingConainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

const Product = ({ mfData = null }) => {
  let equity_perc = 0;
  let non_equity_perc_new = 0;
  let sliceColor = ["#8950FC", "#FEBE37"];
  if (mfData.equity_perc == "0" && mfData.non_equity_perc_new == "0") {
    sliceColor = ["#A9A9A9", "#A9A9A9"];
    equity_perc = 101;
    non_equity_perc_new = 0;
  } else {
    equity_perc = mfData.equity_perc;
    non_equity_perc_new = mfData.non_equity_perc_new;
  }
  let is_club = false;
  let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
  if (hostname.includes("club")) {
    is_club = true;
  }
  const getHoldingPeriod = (avgtime) => {
    if (avgtime) {
      let avgtime_values = avgtime.split(" ");
      if (avgtime_values.length > 1) {
        return (
          parseFloat(avgtime_values[0]).toFixed(1) + " " + avgtime_values[1]
        );
      } else {
        return parseFloat(avgtime).toFixed(1);
      }
    }
  };
  if (!mfData) return <View />;
  return (
    <View
      style={{
        borderWidth: 0.5,
        borderColor: "#cef1e6",
        borderRadius: 20,
        width: width < 1200 ? "97%" : "55%",
      }}
    >
      <View style={{ flexDirection: isMobile ? "column" : "row" }}>
        <View
          style={{
            color: "#494B69",
            fontSize: 16,
            backgroundColor: "#f3fbf9",
            borderRadius: 20,
            height: 232,
            alignSelf: "flex-start",
            width: isMobile ? "100%" : 310,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingHorizontal: 20,
            }}
          >
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Latest Value
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 28,
                  fontWeight: "700",
                  color: "#464E5F",
                }}
              >
                {mfData.latest_value}
              </Text>
            </View>
            <View style={{ flexDirection: "column" }}>
              <View style={{ flexDirection: "row" }}>
                {/* <FontAwesome5
                  name={mfData.day_change_perc >= 0 ? "caret-up" : "caret-down"}
                  size={24}
                  color={mfData.day_change_perc >= 0 ? "#009105" : "#EF5D12"}
                  style={{ marginTop: 52, paddingLeft: 8 }}
                /> */}
                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: "700",
                    color: "#464E5F",
                    color: mfData.day_change_perc >= 0 ? "#009105" : "#EF5D12",
                    textAlign: "left",
                    paddingTop: 55,
                    paddingLeft: 2,
                  }}
                >
                  {mfData.day_change_perc.toFixed(1)}%{" "}
                  <Text
                    style={{
                      paddingTop: 5,
                      fontSize: 13,
                      fontWeight: "400",
                      fontStyle: "italic",
                      color: "#878EA8",
                      paddingLeft: 5,
                    }}
                  >
                    1 day
                  </Text>
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              height: 0.5,
              marginTop: 20,
              backgroundColor: "#92929250",
              paddingHorizontal: 20,
              marginLeft: 20,
              marginRight: 20,
            }}
          />
          <View
            style={{ display: "flex", flexDirection: "row", marginTop: 12 }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {Platform.OS == "web" ? (
                <View
                  style={{
                    height: 220,
                  }}
                >
                  <View
                    style={{
                      width: 180,
                      height: 120,
                      marginLeft: 10,
                      overflow: "hidden",
                    }}
                  >
                    <Donut
                      options={{
                        dataLabels: {
                          enabled: false,
                        },
                        legend: {
                          show: false,
                        },
                        tooltip: { enabled: false },
                        colors: sliceColor,
                        plotOptions: {
                          pie: {
                            donut: {
                              size: "60%",
                            },
                          },
                        },
                        stroke: {
                          width: 1,
                          colors: sliceColor,
                        },
                      }}
                      series={[equity_perc, non_equity_perc_new]}
                      sliceColor={sliceColor}
                      type="donut"
                      width="160"
                    />
                  </View>
                </View>
              ) : (
                <Donut
                  widthAndHeight={100}
                  series={[equity_perc, non_equity_perc_new]}
                  sliceColor={sliceColor}
                  doughnut={true}
                  coverRadius={0.6}
                  coverFill={"#FFF"}
                />
              )}
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                paddingTop: isMobile ? 20 : 30,
                paddingHorizontal: 20,
              }}
            >
              <View
                style={{
                  alignSelf: "flex-start",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: 4,
                  marginBottom: 7.5,
                }}
              >
                <View
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: 2,
                    backgroundColor: "#8950FC",
                  }}
                />
                <Text
                  style={{
                    fontSize: 13,
                    color: "#494B69",
                    paddingLeft: 5,
                  }}
                >
                  Equity {equity_perc == "101" ? "0.0" : equity_perc.toFixed(1)}
                  %
                </Text>
              </View>
              <View
                style={{
                  alignSelf: "flex-start",
                  flexDirection: "row",
                  width: "100%",
                  marginVertical: 7.5,
                }}
              >
                <View
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: 2,
                    backgroundColor: "#FEBE37",
                  }}
                />
                <Text
                  style={{
                    fontSize: 13,
                    color: "#494B69",
                    paddingLeft: 5,
                  }}
                >
                  Non-Equity {non_equity_perc_new.toFixed(1)}%
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            paddingTop: isMobile ? 10 : 20,
            paddingHorizontal: 20,
            paddingBottom: isMobile ? 10 : 0,
          }}
        >
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Invested
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                }}
              >
                {mfData.amount_at_cost_text}
              </Text>
            </View>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 30,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Abs Gain
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                }}
              >
                {mfData.gain}
              </Text>
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Abs Gain %
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: "left",
                }}
              >
                {Number(mfData.absolute_gain).toFixed(1)}%
              </Text>
            </View>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 30,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                XIRR
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: "left",
                }}
              >
                {!isNaN(mfData.latest_xirr)
                  ? `${parseFloat(mfData.latest_xirr).toFixed(1)}%`
                  : "-"}
              </Text>
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Avg. Duration
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: "left",
                }}
              >
                {mfData.holding_period
                  ? getHoldingPeriod(mfData.holding_period)
                  : "-"}
              </Text>
            </View>

            {!is_club && (
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={{
                    paddingTop: 30,
                    fontSize: 14,
                    fontWeight: "400",
                    color: "#878EA8",
                  }}
                >
                  Savings
                </Text>
                <Text
                  style={{
                    paddingTop: 5,
                    fontSize: 14,
                    fontWeight: "700",
                    color: "#464E5F",
                    textAlign: "left",
                  }}
                >
                  {mfData.savings ? mfData.savings : "N.A"}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

const MutualFundPlans = ({
  style,
  mfData,
  navigation,
  mainTab,
  selectedMFTab,
  viewMFReport,
  mfReportLoading,
  showMonthlyReport,
}) => {
  return (
    <View>
      {mainTab == "Summary" ? null : (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: width < 1200 ? "97%" : "55%",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              paddingRight: 30,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("App", {
                  screen: "Plans",
                  params: {
                    screen: "PlansHome",
                    params: {
                      activeTabIndex: 1,
                      activeTab: mainTab,
                    },
                  },
                });
              }}
            >
              <Text
                style={{
                  color: "#410DAA",
                  fontSize: 14,
                  fontWeight: 700,
                  textDecorationLine: "underline",
                }}
              >
                View Plans
              </Text>
            </TouchableOpacity>
          </View>
          {showMonthlyReport ? (
            <View>
              <MFHoldingDetails
                selectedMFTab={selectedMFTab}
                viewMFReport={viewMFReport}
                mfReportLoading={mfReportLoading}
              />
            </View>
          ) : null}
        </View>
      )}
      <View style={[styles.container, { ...style }]}>
        <Product mfData={mfData} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: isMobile ? 0 : 40,
    flexDirection: isMobile ? "column" : "row",
    paddingHorizontal: isMobile ? 0 : 0,
    marginRight: isMobile ? 0 : 0,
  },
  productContainer: {
    width: isMobile ? "100%" : "32.2%",
    borderColor: isMobile ? "transparent" : "#EEE5FF40",
    borderRadius: isMobile ? 0 : 18,
    paddingTop: 15,
    paddingBottom: isMobile ? 0 : 12,
    borderWidth: 1,
    marginBottom: isMobile ? 15 : 0,
  },
  volatalityContainer: {
    backgroundColor: "#FFF0E8",
    marginLeft: isMobile ? 9 : 15,
  },
  topContainer: {
    paddingHorizontal: 15,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: isMobile ? 15 : 16,
    color: "#464E5F",
    fontWeight: "bold",
    fontFamily: "Roboto",
    paddingTop: 3,
  },
  image: {
    width: 25,
    height: 24,
    resizeMode: "contain",
    marginRight: 6,
  },
  strategy: {
    fontSize: 13,
    color: "#888C9F",
    fontStyle: "italic",
    marginTop: 13,
  },
  activePlansInfoContainer: {
    marginTop: isMobile ? 20 : 10,
    backgroundColor: "#F6F6F660",
    paddingHorizontal: 15,
    paddingVertical: 6,
    flexDirection: "row",
  },
  infoContainer: {
    flex: 1,
    paddingVertical: 6,
    flexDirection: "row",
  },
  infoRow: {
    flex: 0.33,
    alignItems: "flex-start",
  },
  fieldContainer: {
    paddingVertical: 6,
  },
  marginTop: {
    marginTop: 16,
  },
  center: { alignItems: "center" },
  end: { alignItems: "flex-end" },
  infoFieldsContainer: {
    marginTop: 0,
    paddingTop: 4,
  },
  infoTitle: {
    fontSize: 12,
    color: "#6C7293",
    fontWeight: "600",
    marginBottom: 8,
  },
  infoField: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  infoValue: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  button: {
    alignSelf: "center",
  },
  buttonText: {
    fontSize: isMobile ? 13 : 15,
    color: "#410DAA",
    fontWeight: "bold",
    lineHeight: 23,
    textDecorationLine: isMobile ? "underline" : "none",
  },
  featureContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingHorizontal: 18,
    marginBottom: 12,
  },
  feature: {
    color: "#464E5F",
    fontFamily: "Roboto",
    fontSize: 13,
  },
  featureBullet: {
    color: "#410DAA",
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "bold",
    paddingRight: 5,
  },
  details: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  descriptionContainer: {
    marginTop: 12,
  },
});

export default MutualFundPlans;
