import React from "react";
import { StyleSheet, View, Platform } from "react-native";
import { Text } from "react-native-paper";

export default function FeatureHeading ({ message }) {
    return (
        <View style={styles.container}>
            <Text style={[styles.text, global.arial]}>{message}</Text>
            <View style={styles.hr}></View>
        </View>
    ); 
}

const styles = StyleSheet.create({
    container: {
      backgroundColor: Platform.OS === "web" ? `#F8F8F8` : "#EEE",
      paddingTop: Platform.OS === "web" ? 80 : 42,
      paddingLeft: 38,
      paddingRight: 38,
      paddingBottom: Platform.OS === "web" ? 80 : 41
    },
    hr: {
      width: 25,
      height: 5,
      alignSelf: "center",
      backgroundColor: "black",
      marginTop: 10
    },
    text: {
      fontSize: 25,
      fontWeight: "bold",
      textAlign: "center",
      color: "black"
    }
});
  