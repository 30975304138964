import React, { Component } from "react";
import {
  FlatList,
  StyleSheet,
  Image,
  TouchableOpacity,
  View,
  Platform,
  ScrollView,
  Dimensions,
  SafeAreaView,
} from "react-native";
import { Button, Text } from "react-native-paper";
import { connect } from "react-redux";
import amplitude from "../../../../utils/amplitude";
var Image_url = "https://jamaappprod.s3.amazonaws.com/media/";
import theme from "../../../../static/theme/index.js";
import { isMobile, months_short_names } from "../../../../utils";
import { ActivityIndicator } from "react-native-paper";
import { getMySIPs } from "../../../../api/account";
import NavigationHeader from "../../../../components/Common/NavigationHeader";

const { height } = Dimensions.get("window");
const EmptySIPS = ({ navigation }) => {
  const handlePress = () => {
    navigation.navigate("App", {
      screen: "Dashboard",
      params: {
        screen: "Explore",
        params: {
          screen: "Explore",
          params: {
            index: 1,
          },
        },
      },
    });
  };

  return (
    <View
      style={{
        width: "100%",
        height: height - 100,
        paddingHorizontal: 16,
        justifyContent: "center",
      }}
    >
      <Text style={{ alignSelf: "center", textAlign: "center" }}>
        No SIPs to show! Why don't you try investing! We have SmartDeposit Funds
        - best for parking idle cash! Or choose TaxSavers, Or WealthBuilders!
      </Text>
      <Button
        uppercase={false}
        mode={"contained"}
        onPress={handlePress}
        color={"#410DAA"}
        style={styles.button}
      >
        <Text style={styles.buttonText}>INVEST NOW</Text>
      </Button>
    </View>
  );
};

class MySips extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      sipdata: [],
    };
  }

  renderRow = (amc) => {
    let urlamc = "";
    urlamc = Image_url + amc.item.amc_logo;

    const sip_per_month = amc.item.amount;
    let next_due = amc.item.sip_next_due_date.split("-");
    const month = months_short_names(parseInt(next_due[1]));

    next_due = next_due[0] + "-" + month + "-" + next_due[2];

    const frequency =
      amc.item.bse_sip_frequency_type && amc.item.bse_sip_frequency_type != ""
        ? amc.item.bse_sip_frequency_type.split("ly")[0].toString()
        : "month";

    return (
      <TouchableOpacity
        onPress={() => {
          this.props.navigation.navigate("SIPDetails", {
            amcfundscheme: amc.item.amcfundscheme,
          });
        }}
        style={{
          borderRadius: 10,
          borderColor: "#410DAA33",
          borderWidth: 1,
          marginVertical: 7.5,
          marginHorizontal: 15,
        }}
      >
        <View
          style={{
            paddingVertical: 12,
            paddingHorizontal: 10,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View
            style={{
              flex: 0.1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: 30,
                height: 30,
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#410DAA1A",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={{ uri: urlamc }}
                resizeMode="contain"
                style={{ height: 20, width: 20 }}
              />
            </View>
          </View>
          <View style={{ flex: 0.9 }}>
            <Text
              style={{
                fontSize: isMobile ? 12 : 14,
                color: "#121212",
                paddingLeft: 10,
              }}
            >
              {amc.item.scheme_name}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: 10,
            paddingBottom: 7.5,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: "#121212",
              paddingRight: 5,
              fontWeight: "bold",
            }}
          >
            {sip_per_month}
          </Text>
          <Text
            style={{
              fontSize: isMobile ? 10 : 12,
              color: "#121212",
              fontWeight: "bold",
            }}
          >
            / {frequency}
          </Text>
          <Text
            style={{
              fontSize: isMobile ? 10 : 12,
              color: "#465964",
              marginLeft: 10,
            }}
          >
            Next Due: {next_due}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("mysips",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("mysips", { path: this.props.navigation.getState().routeName });
      }
      const response = await getMySIPs();
      if (response && Array.isArray(response)) {
        const sipData = response.filter((item) => item.bse_trans_code == "NEW");
        this.setState({ sipData });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleBack = () => {
    const canGoBack = this.props.navigation.canGoBack();
    if (canGoBack) {
      this.props.navigation.goBack();
    } else {
      this.props.navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Account",
        },
      });
    }
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["My SIPs"]}
          onPressBack={this.handleBack}
        />

        {this.state.loading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        ) : (
          <ScrollView>
            <View
              style={{
                padding: 5,
                width: "100%",
                marginBottom: isMobile ? 70 : 30,
              }}
            >
              <FlatList
                data={this.state.sipData}
                ListEmptyComponent={
                  <EmptySIPS navigation={this.props.navigation} />
                }
                renderItem={this.renderRow}
                keyExtractor={(item, index) => index.toString()}
              />
            </View>
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: theme.colors.NEW_BRAND_COLOR,
    marginTop: 60,
    width: 200,
    alignSelf: "center",
    marginHorizontal: 16,
  },
  buttonText: { fontSize: 15, color: "white", fontWeight: "bold" },
});

export default connect(null)(MySips);
