import React from "react";
import {
  View,
  StatusBar,
  Platform,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  Image,
} from "react-native";
import { Text, Button } from "react-native-paper";
import { TouchableOpacity } from "react-native";
import { getFinAwarenessData } from "../../../api";
import { Store as store } from "../../../store/";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { ActivityIndicator } from "react-native-paper";
import amplitude from "../../../utils/amplitude";
import { getStatusColor, getWindowSize, isMobile } from "../../../utils/index";
import { StatusIndicator } from "../../../components/Common/statusIndicator";
import NotFound from "../../../components/NotFound";
import Divider from "../../../components/Common/divider";
import { CustomModal } from "../../../components/Common/CustomModal";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;
import { StackActions } from "@react-navigation/native";

class FaRowItem extends React.Component {
  render() {
    const {
      quiz_data,
      quiz_name,
      quiz_image,
      score,
      quiz_id,
      is_quiz_done,
      is_fetching,
    } = this.props;

    const descriptions =
      quiz_data && quiz_data.persona && quiz_data.persona.description
        ? quiz_data.persona.description.split("<br><br>")
        : [];

    return (
      <View style={{ marginBottom: 80 }}>
        <View
          style={{
            marginLeft: isMobile ? "6%" : "1.5%",
            marginRight: isMobile ? "0%" : "1.5%",
            backgroundColor: "white",
            width: isMobile ? "100%" : "65.5%",
            height: 190,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              paddingHorizontal: isMobile ? "auto" : 25,
              paddingRight: isMobile ? 20 : 0,
              paddingVertical: 20,
              height: 90,
            }}
          >
            <View
              style={{
                flex: isMobile ? 1 : 0.13,
                marginLeft: isMobile ? -10 : "auto",
              }}
            >
              <Image
                source={{ uri: quiz_image }}
                style={{
                  width: 60,
                  height: 60,
                  alignSelf: "flex-start",
                  borderRadius: 4,
                }}
                resizeMode={"contain"}
              />
            </View>
            <View
              style={{
                flex: isMobile ? 2 : 0.67,
                marginTop: 8,
                marginLeft: 8,
              }}
            >
              <Text
                style={{
                  flex: 1,
                  fontSize: 16,
                  color: "#121212",
                  fontWeight: "600",
                  flexWrap: "wrap",
                }}
              >
                {quiz_name}
              </Text>
              {is_quiz_done ? (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      color:
                        quiz_data.quiz_personas != "" &&
                        getStatusColor(
                          quiz_data.quiz_personas,
                          quiz_data.persona.persona_name
                        ),
                      fontWeight: "bold",
                    }}
                  >
                    {quiz_data.persona.persona_name}
                  </Text>
                  <ANTIcon
                    hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                    name="infocirlceo"
                    color={
                      quiz_data.quiz_personas != "" &&
                      getStatusColor(
                        quiz_data.quiz_personas,
                        quiz_data.persona.persona_name
                      )
                    }
                    size={16}
                    style={{
                      paddingLeft: 10,
                      marginTop: 2,
                    }}
                    onPress={() => {
                      this.props.toggleModal && this.props.toggleModal();
                    }}
                  />
                </View>
              ) : (
                <Text
                  style={{
                    fontStyle: "italic",
                    fontSize: 16,
                    color: "#707070",
                    fontWeight: "bold",
                  }}
                >
                  Incomplete
                </Text>
              )}
            </View>
            <View style={{ flex: isMobile ? 2 : 0.2, alignItems: "flex-end" }}>
              {is_quiz_done ? (
                Platform.OS == "web" ? (
                  <View
                    style={{
                      marginLeft: 10,
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <View>
                      <Image
                        source={quiz_data.persona.persona_image_url}
                        style={{
                          width: 100,
                          height: 100,
                          alignSelf: "flex-start",
                          borderRadius: 4,
                          marginBottom: 10,
                        }}
                        resizeMode={"contain"}
                      />
                    </View>
                    <View
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Donut
                        options={{
                          dataLabels: {
                            enabled: false,
                          },
                          legend: {
                            show: false,
                          },
                          tooltip: { enabled: false },
                          colors: ["#876CDD", "#BAC7F2"],
                          plotOptions: {
                            pie: {
                              donut: {
                                size: "60%",
                              },
                            },
                          },
                          stroke: {
                            width: 1,
                            colors: ["#876CDD", "#BAC7F2"],
                          },
                        }}
                        series={[Math.floor(score), 100 - Math.floor(score)]}
                        sliceColor={["#876CDD", "#BAC7F2"]}
                        type="donut"
                        width="160"
                      />
                      <Text
                        style={{
                          fontWeight: "600",
                          color: "#121212",
                          fontSize: 13,
                        }}
                      >
                        Score {Math.floor(score)} / 100
                      </Text>
                    </View>
                  </View>
                ) : (
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "120%",
                    }}
                  >
                    <View>
                      <Image
                        source={{ uri: quiz_data.persona.persona_image_url }}
                        style={{
                          width: 100,
                          height: 100,
                          marginTop: 50,
                          alignSelf: "flex-start",
                          borderRadius: 4,
                          marginBottom: 10,
                        }}
                        resizeMode={"contain"}
                      />
                    </View>
                    <Donut
                      widthAndHeight={100}
                      series={[Math.floor(score), 100 - Math.floor(score)]}
                      sliceColor={["#876CDD", "#BAC7F2"]}
                      doughnut={true}
                      coverRadius={0.6}
                      coverFill={"#FFF"}
                    />
                    <Text
                      style={{
                        fontWeight: "600",
                        color: "#121212",
                        fontSize: 13,
                      }}
                    >
                      Score {Math.floor(score)} / 100
                    </Text>
                  </View>
                )
              ) : (
                <Text
                  style={{ fontWeight: "500", color: "#121212", fontSize: 16 }}
                >
                  {this.props.time}
                  <Text style={{ fontSize: 12, fontWeight: "400" }}>
                    {this.props.time > 1 ? "mins" : "min"}
                  </Text>
                </Text>
              )}
            </View>
          </View>
          {is_fetching && (
            <>
              <ActivityIndicator
                size="small"
                color="#410DAA"
                style={{ alignSelf: "center", marginVertical: 15 }}
              />
            </>
          )}
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            marginTop: -30,
          }}
        >
          <View style={{ width: isMobile ? "50%" : "30%", marginLeft: "4%" }}>
            <Text
              style={{
                fontSize: 15,
                fontWeight: "700",
                color: "#464E5F",
                marginBottom: 10,
              }}
            >
              {quiz_data.persona.quiztext}
            </Text>
            <Text
              style={{
                fontSize: 14,
                color: "#464E5F",
                marginBottom: 50,
              }}
            >
              {descriptions}
            </Text>
          </View>
          {!isMobile && (
            <Button
              uppercase={false}
              mode="contained"
              labelStyle={{
                color: "#410DAA",
                fontWeight: "600",
              }}
              onPress={() => {
                this.props.navigation.navigate("Quizz", {
                  quiz_id,
                  from: "em",
                });
              }}
              style={{
                marginHorizontal: 24,
                borderColor: "#410DAA",
                borderWidth: 1,
                backgroundColor: "white",
                borderRadius: 6,
                height: isMobile ? "auto" : 37,
                width: isMobile ? "40%" : "13%",
                marginLeft: isMobile ? "2%" : "18%",
                marginBottom: "2%",
                marginTop: "10%",
              }}
            >
              Repeat Assessment
            </Button>
          )}
        </View>
        <View
          style={{
            width: "100%",
            marginLeft: "4%",
            marginTop: isMobile ? 0 : -50,
          }}
        >
          {isMobile && (
            <Button
              uppercase={false}
              mode="contained"
              labelStyle={{
                color: "#410DAA",
                fontWeight: "600",
              }}
              onPress={() => {
                this.props.navigation.navigate("Quizz", {
                  quiz_id,
                  from: "em",
                });
              }}
              style={{
                marginHorizontal: 24,
                borderColor: "#410DAA",
                borderWidth: 1,
                backgroundColor: "white",
                borderRadius: 6,
                width: "40%",
                marginLeft: "52%",
                marginBottom: "2%",
                marginTop: "-30%",
              }}
            >
              Repeat Assessment
            </Button>
          )}
          <Text
            style={{
              fontSize: 15,
              fontWeight: "700",
              color: "#464E5F",
              marginBottom: 10,
              marginTop: isMobile ? "15%" : "auto",
            }}
          >
            Legend:
          </Text>
          {quiz_data.quiz_personas.map((persona, index) => {
            return (
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: 14,
                    color: "#464E5F",
                    marginBottom: 10,
                    marginTop: 8,
                    marginLeft: isMobile ? 0 : 5,
                    width: isMobile ? "90%" : "60%",
                  }}
                >
                  Upto {persona.to_score}{" "}
                  <Text style={{ fontWeight: "bold" }}>
                    {persona.persona_name}.
                  </Text>{" "}
                  {persona.short_description}
                </Text>
              </View>
            );
          })}
        </View>
        <TouchableOpacity
          onPress={() => {
            this.props.navigation.navigate("QuizDiag", {
              quiz_id,
              image: quiz_data.persona.persona_image_url,
            });
          }}
          style={{
            alignItems: "flex-start",
            marginLeft: "3%",
            width: isMobile ? "100%" : "26%",
          }}
        >
          <Text
            style={{
              width:isMobile?"100%":"100%",
              fontFamily: "Roboto",
              lineHeight: 17,
              fontSize: 15,
              textAlign:"center",
              color: "#ffffff",
              paddingVertical: 12,
              paddingHorizontal: 47,
              backgroundColor: "#410DAA",
              borderRadius: 4,
              marginTop: 20,
              marginBottom: 50,
            }}
          >
            Get Personalized Report
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}

const QuizItem = ({ index, image, question, time, navigation, id }) => {
  return (
    <View
      index={index}
      style={{
        flexDirection: "row",
        paddingVertical: 10,
      }}
    >
      <View style={{ flex: 0.2 }}>
        <Image
          source={image}
          style={{
            width: 50,
            height: 50,
            alignSelf: "flex-start",
            borderRadius: 4,
          }}
          resizeMode={"contain"}
        />
      </View>
      <TouchableOpacity
        style={{
          flex: 0.7,
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        onPress={() => {
          navigation.navigate("Quizz", { quiz_id: id });
        }}
      >
        <Text style={{ fontSize: 16, color: "#121212" }}>{question}</Text>
        <View style={{ flexDirection: "row" }}>
          <ANTIcon name={"clockcircleo"} color={"#A8A8A8"} size={16} />
          <Text style={{ fontSize: 12, paddingLeft: 5 }}>{time} min</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        style={{ justifyContent: "center", alignItems: "flex-end", flex: 0.1 }}
        onPress={() => {
          navigation.navigate("Quizz", { quiz_id: id });
        }}
      >
        <ANTIcon name={"right"} color={"#A8A8A8"} size={16} />
      </TouchableOpacity>
    </View>
  );
};

class EmWellness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: [],
      fetching: false,
      showModal: false,
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("emWellness",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("emWellness", { path: this.props.navigation.getState().routeName });
    }
    this.getFinAwareness();
  }

  static navigationOptions = {
    tabBarVisible: false,
  };

  closeItem = () => {
    this.setState({ activeItem: null });
  };

  setActiveTab = async (quiz_id) => {
    this.setState({
      activeItem: quiz_id,
    });
  };

  getFinAwareness = async () => {
    this.setState({
      fetching: true,
    });
    try {
      const response = await getFinAwarenessData();
      if (response && response.code == 100) {
        store.dispatch({
          type: "UPDATE_FINAWARENESS_DATA",
          payload: response.emotional_wellness,
        });
      }
      this.setState({
        fetching: false,
        data: response.emotional_wellness,
        activeItem: this.props.route.params.quiz_id,
      });
    } catch (err) {
      this.setState({
        fetching: false,
        response: null,
        activeItem: null,
      });
    }
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  _header() {
    return (
      <NavigationHeader
        navigation={this.props.navigation}
        navigationHeader={["Emotional Wellness Quiz Results"]}
        onPressBack={() => {
          this.props.navigation.dispatch(
            StackActions.replace("EmotionalWellness")
          );
        }}
      />
    );
  }

  _render_rowItems = () => {
    let new_strips = [];
    let children = this.state.data.final_list;
    children = children.filter(
      (child) => child.quiz_id == this.props.route.params.quiz_id
    );
    if (children) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const quiz_name = child.quiz_name;
        const quiz_img = child.quiz_img;
        const score = child.score;
        new_strips.push(
          <FaRowItem
            quiz_data={child.quiz_data}
            quiz_name={quiz_name}
            quiz_image={quiz_img}
            score={score}
            quiz_id={child.quiz_id}
            navigationw={this.props.navigation}
            is_quiz_done={child.is_quiz_done}
            setActiveTab={(id) => this.setActiveTab(id)}
            is_fetching={this.state.fetchingQuizResults}
            activeItem={this.state.activeItem}
            closeItem={this.closeItem}
            toggleModal={this.toggleModal}
            navigation={this.props.navigation}
            time={child.read_time || 2}
          />
        );
      }
      return new_strips;
    }
  };

  _render_score_percentage = (percentage) => {
    return (
      this.any_quiz_taken() && (
        <View style={{ marginHorizontal: 20 }}>
          <StatusIndicator
            percentage={percentage}
            heading={"Your Overall Score"}
          />
        </View>
      )
    );
  };

  _render_screen() {
    return <View>{this._render_rowItems()}</View>;
  }

  any_quiz_taken = () => {
    const response = this.state.data;
    if (response && Object.keys(response).length != 0) {
      const { final_list } = response;
      if (final_list) {
        let taken = false;
        final_list.forEach((item) => {
          taken = taken || item.is_quiz_done;
        });
        return taken;
      }
    } else {
      return null;
    }
  };

  _render_not_completed_screen = () => {
    const response = this.state.data;
    const { final_list } = response;
    return (
      <View style={{ paddingHorizontal: 16, marginTop: 20 }}>
        {final_list &&
          final_list.map((item, index) => {
            return (
              <QuizItem
                index={index}
                id={item.quiz_id || null}
                question={item.quiz_name}
                image={{ uri: item.quiz_img }}
                time={item.read_time || 2}
                navigation={this.props.navigation}
              />
            );
          })}
      </View>
    );
  };

  getBackgroundColor = (index, percentage, length) => {
    if (index == 0) {
      return "#EF1212";
    } else if (index == 1 && length == 3) {
      return "#FBBC29";
    } else if (index == 1 && length == 4) {
      return "#EF7212";
    } else if (index == 2 && length == 3) {
      return "#009105";
    } else if (index == 2 && length == 4) {
      return "#FBBC29";
    } else if (index == 3 && length == 4) {
      return "#009105";
    }
  };

  renderModalContent = () => {
    const { activeItem } = this.state;
    const fa_data = this.state.data;
    if (!fa_data || (fa_data && Object.keys(fa_data).length == 0)) {
      return;
    }
    const { final_list } = fa_data;
    let activeData =
      final_list && final_list.filter((item) => activeItem == item.quiz_id);
    if (!activeData.length) {
      return;
    }
    activeData = activeData[0].quiz_data;
    let number_of_quiz_personas;
    if (
      activeData &&
      activeData.quiz_personas &&
      activeData.quiz_personas != ""
    ) {
      number_of_quiz_personas = activeData.quiz_personas.length;
    } else {
      return null;
    }
    return (
      <View
        style={{
          width: isMobile ? getWindowSize().width * 0.8 : "auto",
          height: "auto",
        }}
      >
        {activeData &&
          activeData.quiz_personas &&
          activeData.quiz_personas.map((persona, index) => {
            return (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginVertical: 8,
                }}
              >
                <View style={{ flex: 0.15 }}>
                  <Image
                    source={{ uri: persona.persona_image_url }}
                    style={{ width: 35, height: 35, alignSelf: "center" }}
                    resizeMode={"contain"}
                  />
                </View>
                <View style={{ flex: 0.6, paddingLeft: 10 }}>
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      color: "#121212",
                      flexWrap: "wrap",
                    }}
                  >
                    {persona.persona_name}
                  </Text>
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#121212",
                      flexWrap: "wrap",
                    }}
                  >
                    {persona.short_description}
                  </Text>
                </View>
                <View style={{ flex: 0.25, alignItems: "flex-end" }}>
                  <View
                    style={{
                      width: 70,
                      height: 34,
                      backgroundColor: this.getBackgroundColor(
                        index,
                        persona.to_score,
                        number_of_quiz_personas
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: 6,
                      borderBottomLeftRadius: 6,
                    }}
                  >
                    <Text style={{ fontSize: 11, color: "#fff" }}>
                      {index == 0
                        ? "<"
                        : Math.ceil(persona.from_score.toFixed(1)) + "%-"}
                      {Math.ceil(persona.to_score.toFixed(1)) + "%"}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}
      </View>
    );
  };

  render() {
    const header = this._header();
    const quiz_taken = this.any_quiz_taken();
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {header}
        <ScrollView>
          {
            <ScrollView
              contentContainerStyle={{ flexGrow: 1 }}
              style={styles.cardContainer}
            >
              {this.state.fetching || quiz_taken == null ? (
                <View style={styles.loaderContainer}>
                  <ActivityIndicator size="large" color="#410DAA" />
                </View>
              ) : this.state.data && Object.keys(this.state.data).length ? (
                quiz_taken == true ? (
                  this._render_screen()
                ) : (
                  this._render_not_completed_screen()
                )
              ) : (
                <NotFound />
              )}
            </ScrollView>
          }
        </ScrollView>
        <CustomModal
          visible={this.state.showModal}
          showCloseButton={true}
          closeModal={() => {
            this.toggleModal();
          }}
        >
          {this.renderModalContent()}
        </CustomModal>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    padding: 5,
  },
  modalContentContainer: {
    backgroundColor: "white",
    padding: 8,
    paddingRight: 0,
    borderRadius: 4,
    width: "100%",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default EmWellness;
