import { actionTypes } from "../reducers/finplan";

export const updateLastSeenScreen = (
  type,
  screen,
  basic = false,
  end = false
) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.UPDATE_LAST_SEEN_SCREEN,
      payload: {
        type,
        screen,
        basic,
        end,
      },
    });
  };
};

export const resetFinplan = () => {
  return (dispatch) => {
    const reset = dispatch({
      type: actionTypes.RESET_FINPLAN,
    });
    if (reset) return true;
    return false;
  };
};

export const updateFinplanStatus = (flowType, status = false) => {
  return (dispatch) => {
    const update = dispatch({
      type: actionTypes.UPDATE_FINPLAN_STATUS,
      payload: {
        flowType,
        status,
      },
    });
    if (update) return true;
    return false;
  };
};

export const closeFinplanModal = () => {
  return {
    type: actionTypes.CLOSE_FINPLAN_MODAL,
  };
};

export const finplanProcessing = (status = false) => {
  return (dispatch) => {
    const update = dispatch({
      type: actionTypes.FINPLAN_PROCESSING,
      payload: {
        status,
      },
    });
    if (update) return true;
    return false;
  };
};
