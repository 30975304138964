import React from "react";
import moment from "moment";
import { View, Text, StyleSheet,Platform, ScrollView, Switch } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import InvestmentSchedule from "../../components/InvestmentOverview/NewDesign/components/InvestmentSchedule";
import Rebalancing from "../../components/InvestmentOverview/NewDesign/components/Rebalancing";
import Compliance from "../../components/InvestmentOverview/NewDesign/components/Compliance";
import SideHeading from "../../components/InvestmentOverview/NewDesign/components/SideHeading";
import { EquityPlans } from "../../components/InvestmentOverview/NewDesign/components/EquityPlans";
import { MutualFundPlansOld } from "../../components/InvestmentOverview/NewDesign/components/MutualFundPlansOld";
import { SparkTeaser } from "../../components/InvestmentOverview/SparkTeaser";
import amplitude from "../../utils/amplitude";
import { TopupStrip } from "../../components/Common/TopUpStrip";
import {
  completeMinDetails,
  getUserDetails,
  isMobile,
  reverseString,
} from "../../utils";
import {
  getTransactionPopUp,
  getCustomerDetails,
  getDashboard,
  getMFData,
  getMFDetails,
} from "../../api";
import { VersionNumber } from "../../components/Common/VersionNumber";
import FamilyPopUp from "../../components/InvestmentOverview/FamilyPopup";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../api/apiConfig";
import { MAIN_URL } from "../../api/urls";
import { PopUp } from "../../components/Common/PopUp";
import OldSummaryCard from "../../components/InvestmentOverview/NewDesign/components/OldSummaryData";
import ComplianceModal from "../../components/InvestmentOverview/ComplianceModal";
import { StackActions } from "@react-navigation/routers";
import handleTopup from "../../utils/handleTopup";
import { ShowToast } from "../../components/Common/Toast";
import ProgressBar from "../../utils/progressBar";

const Strips = ({
  data = {},
  is_ria = false,
  navigation,
  userId,
  minDetailsResponse,
  ...props
}) => {
  const { investment_schedule, rebalancing, compliance } = data;
  const handleNavigation = (screen) => {
    let options = {};
    if (userId) {
      options["user_id"] = userId;
    }
    navigation.navigate("App", {
      screen: "Dashboard",
      params: {
        screen: "Portfolio",
        params: {
          screen,
          params: {
            ...options,
          },
        },
      },
    });
  };

  const handleCompliance = async () => {
    try {
      const { needCompletion, error } = minDetailsResponse;
      if (!error) {
        const { redirect_screen } = minDetailsResponse;
        if (needCompletion) {
          const { params = {} } = props.route;
          navigation.navigate("Onboarding", {
            screen: redirect_screen,
            params: { nvg: "InvestmentOverview", action: true, ...params },
          });
        } else {
          const { results } = minDetailsResponse;
          const pendingItems =
            results && results.filter((item) => item.is_completed == false);
          if (pendingItems.length) {
            const { redirect_screen } = pendingItems[0];
            if (redirect_screen) {
              const { params = {} } = props.route;
              navigation.navigate("Onboarding", {
                screen: redirect_screen,
                params: { nvg: "InvestmentOverview", action: true, ...params },
              });
            }
          }
        }
      }
    } catch (error) {}
  };
  return (
    <View style={strips.container}>
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ width: "96%", flexDirection: "row" }}
      >
        {investment_schedule && (
          <InvestmentSchedule
            data={investment_schedule}
            onClick={handleNavigation}
            is_ria={is_ria}
          />
        )}
        {rebalancing && (
          <Rebalancing
            data={rebalancing}
            onClick={handleNavigation}
            is_ria={is_ria}
          />
        )}
        {compliance && (
          <Compliance
            data={compliance}
            style={[{ width: isMobile ? "96%" : "32.3%" }]}
            onClick={handleCompliance}
          />
        )}
      </ScrollView>
    </View>
  );
};

const strips = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginBottom: 32,
    paddingHorizontal: isMobile ? 16 : 20,
  },
});

class OldHomeDashboard extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let userId = null;
    let showFamilyAlert = null;
    let showToast = null;
    if (params) {
      showToast = params.showToast || null;
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      showFamilyAlert = params.f;
    }
    this.state = {
      gettingMinDetails: true,
      userId,
      showFamilyAlert,
      isLoading: true,
      isTopupLoading: true,
      shouldShowFamilyPopUp: false,
      mfData: null,
      mfDetails: null,
      shouldShowMF: false,
      showToast,
      is_ria: false,
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("dashboardOld",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("dashboardOld;", { path: this.props.navigation.getState().routeName });
      }
      let url = new URL(window.location.href);
      let urlParam = url.href.split("/");

      if (
        urlParam[5] &&
        !isNaN(urlParam[5]) &&
        urlParam[4] == "holdings" &&
        localStorage.getItem("reloadDashboard") == "true"
      ) {
        localStorage.setItem("reload", "true");
        localStorage.setItem("reloadDashboard", "false");
        location.replace(url.origin + "/investments/overview/" + urlParam[5]);
      } else {
        if (urlParam[4] && !isNaN(urlParam[4])) {
          localStorage.setItem("userId", urlParam[4]);
          localStorage.setItem("reload", "true");
        } else {
          localStorage.removeItem("userId");
          localStorage.removeItem("reload");
          localStorage.removeItem("reloadDashboard");
        }
      }

      const { params } = this.props.route;
      let topup, product, data;
      if (params) {
        topup = params.topup || null;
        data = params.data || null;
        product = params.product || null;
      }

      // if topup data is present in url - handle topup
      if (topup && data && product) {
        await handleTopup({
          amount: data,
          product,
        });
        return;
      }
      let currentDate = moment(new Date()).format("YYYY-MM-DD");
      if (localStorage.getItem("family_popup_last_shown_date")) {
        let storedDate = localStorage.getItem("family_popup_last_shown_date");
        let dateDiff = moment().diff(moment(storedDate), "days");
        if (dateDiff > 7) {
          this.setState({ shouldShowFamilyPopUp: true });
          localStorage.setItem("family_popup_last_shown_date", currentDate);
        } else {
          this.setState({ shouldShowFamilyPopUp: false });
        }
      } else {
        this.setState({ shouldShowFamilyPopUp: true });
        localStorage.setItem("family_popup_last_shown_date", currentDate);
      }

      const { userId } = this.state;
      const responses = await Promise.all([
        getTransactionPopUp(),
        getDashboard(userId),
        getMFData(),
        getUserDetails(),
        getMFDetails(),
      ]);
      let actionItemID,
        showPopUp = false,
        modalData = null;
      const transactionPopUpResponse = responses[0];
      const landingPageResponse = responses[1];
      const mutualFundData = responses[2];
      const userData = responses[3];
      const mfDetails = responses[4];

      if (
        mutualFundData.code == 100 &&
        mutualFundData.result &&
        mutualFundData.result.children
      ) {
        this.setState({ shouldShowMF: true });
        mutualFundData.result.children.forEach((data) => {
          if (userData.customer_id == data.pk) {
            data.total_investment_details.forEach((invest) => {
              if (invest.tag_name == "In Jama") {
                this.setState({ mfData: invest });
              }
            });
          }
        });
      } else {
        this.setState({ shouldShowMF: false });
      }

      if (
        mfDetails.code == 100 &&
        mfDetails.result &&
        mfDetails.result.mutual_funds
      ) {
        mfDetails.result.mutual_funds.forEach((details) => {
          if (details.tag_name == "In Jama") {
            this.setState({ mfDetails: details.results });
          }
        });
      }

      if (transactionPopUpResponse.code == 100) {
        if (transactionPopUpResponse.result.Show_pop_up) {
          let { cai_pk, modal_data } = transactionPopUpResponse.result;
          actionItemID = cai_pk;
          showPopUp = true;
          modalData = modal_data;
        }
      }

      if (landingPageResponse.code == 100) {
        const {
          stocks_summary,
          piechart_data,
          show_product_strip,
          subscribed_products,
          equity_investment_plans,
          strips,
          default_portfolio_view,
        } = landingPageResponse.result;

        this.setState(
          {
            actionItemID,
            showPopUp,
            modalData,
            piechart_data,
            stocks_summary,
            show_product_strip,
            subscribed_products,
            equity_investment_plans,
            strips,
            isLoading: false,
            default_portfolio_view,
            showTeaser: show_product_strip != null,
            is_ria: userData.is_ria,
          },
          async () => {
            await this.checkComplianceData();
          }
        );
      }
    } catch (error) {}
  }

  checkComplianceData = async () => {
    try {
      const customer_id = this.state.userId || null;
      let skipSubscription = false;
      if (this.state.userId) {
        const { skip_subscription } = await getCustomerDetails(
          this.state.userId
        );
        skipSubscription = skip_subscription;
      } else {
        const { skip_subscription } = getUserDetails();
        skipSubscription = skip_subscription;
      }
      const { params } = this.props.route;
      let skip_compliance = false;
      if (params) {
        skip_compliance = params.s || false;
      }
      const minDetailsResponse = await completeMinDetails(
        true,
        customer_id,
        false,
        false
      );
      this.setState({ minDetailsResponse, gettingMinDetails: false });
    } catch (error) {}
  };

  handleCloseFamilyAlert = () => {
    this.setState({ showFamilyAlert: false });
  };

  closePopUp = async () => {
    this.setState({ showPopUp: false });
  };

  onRetry = (cai_hash) => {
    let token = getTokenFromStore();
    token = reverseString(token);
    global.open(
      MAIN_URL +
        "/app/gwsc/retryorder/" +
        (cai_hash + token) +
        "/" +
        GATEWAY_ENVIRONMENT,
      "_self"
    );
  };

  handleTopupClick = (data = {}) => {
    if (!this.state.gettingMinDetails) {
      this.setState({
        showComplianceAlert: true,
        complianceNavigationOptions: { ...data },
      });
    }
  };

  handleDashboardChange = () => {
    this.props.navigation.dispatch(
      StackActions.replace("Home", {
        showToast: "true",
      })
    );
  };

  render() {
    const {
      isLoading,
      equity_investment_plans = {},
      subscribed_products = [],
      isTopupLoading = true,
      topupHeight,
      minDetailsResponse = {},
      gettingMinDetails,
      error,
      errorText,
      showFamilyAlert,
      showPopUp,
      modalData,
      stocks_summary,
      piechart_data,
      show_product_strip,
      strips = [],
      showTeaser,
      shouldShowFamilyPopUp,
      showComplianceAlert = false,
      complianceNavigationOptions = {},
      is_ria,
    } = this.state;

    if (error || isLoading) {
      return (
        <View style={styles.loadingContainer}>
          {isLoading && <ProgressBar msg={"Loading Portfolio"}/>}
          {errorText && <Text style={styles.errorText}>{errorText}</Text>}
          <VersionNumber absolute={true} />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        {/* Family Alert */}
        {showFamilyAlert && shouldShowFamilyPopUp && (
          <FamilyPopUp
            description="Family Popup"
            onDismiss={this.handleCloseFamilyAlert}
          />
        )}

        {/* Transaction status pop-up */}
        {showPopUp && modalData && (
          <PopUp
            modal_data={modalData}
            onPressDone={this.closePopUp}
            onRetry={this.onRetry}
          />
        )}

        {showComplianceAlert && (
          <ComplianceModal
            forceLoad={true}
            navigation={this.props.navigation}
            route={this.props.route}
            isLoading={gettingMinDetails}
            minDetailsResponse={minDetailsResponse}
            redirectBackTo={"InvestmentOverview"}
            onClose={() => {
              this.setState({ showComplianceAlert: false });
            }}
            navigationOptions={complianceNavigationOptions}
            userId={this.state.userId}
          />
        )}

        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title="You switched to old dashboard"
            duration="5"
          />
        ) : null}

        <ScrollView contentContainerStyle={styles.scrollView}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 5,
              marginBottom: 10,
            }}
          >
            <View style={{ flex: 1, justifyContent: "flex-start" }}>
              {isMobile ? (
                <SideHeading title="For Your Attention" />
              ) : (
                <View
                  style={{
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{ justifyContent: "flex-start", marginLeft: 20 }}
                  >
                    <SideHeading title="For Your Attention" />
                  </View>
                </View>
              )}
            </View>
          </View>
          <Strips
            {...this.props}
            data={strips}
            userId={this.state.userId}
            navigation={this.props.navigation}
            minDetailsResponse={minDetailsResponse}
            is_ria={is_ria}
          />

          <SideHeading
            title="Direct Equity Investments Overview"
            style={[styles.portfolioHeading]}
          />
          <View style={styles.summaryContainer}>
            <View
              style={{ width: isMobile ? "100%" : "65%" }}
              onLayout={(event) => {
                var { height } = event.nativeEvent.layout;
                this.setState({ isTopupLoading: false, topupHeight: height });
              }}
            >
              <OldSummaryCard
                stocks_summary={stocks_summary}
                piechart_data={piechart_data}
                navigation={this.props.navigation}
                userId={this.state.userId}
                toggleStatus={this.state.default_portfolio_view}
              />
            </View>
            {isTopupLoading ? (
              <View
                style={{
                  flex: 0.35,
                  height: 200,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator
                  size="large"
                  color="#410DAA"
                  style={{ alignItems: "center" }}
                />
              </View>
            ) : (
              <View
                style={{
                  width: isMobile ? "100%" : "35%",
                  height: isMobile ? undefined : topupHeight,
                  paddingLeft: isMobile ? 0 : 32,
                  marginTop: isMobile ? 30 : 0,
                }}
              >
                {subscribed_products.length == 1 &&
                subscribed_products[0] == "AA" ? null : is_ria ? null : (
                  <TopupStrip
                    noCompliance={
                      !gettingMinDetails && minDetailsResponse.needCompletion
                    }
                    minDetailsResponse={minDetailsResponse}
                    style={{ flex: 1, height: "100%" }}
                    navigation={this.props.navigation}
                    subscribed_products={subscribed_products}
                    onClick={this.handleTopupClick}
                    viewMode={this.state.userId}
                  />
                )}
              </View>
            )}
          </View>

          {/* Spark Teaser */}
          {showTeaser &&
            (is_ria ? null : (
              <SparkTeaser
                style={{ width: "100%", height: "auto", marginTop: 32 }}
                onClose={() => {
                  this.setState({ showTeaser: false });
                }}
                product={show_product_strip}
              />
            ))}

          {/* Equity Plans */}
          {is_ria ? null : (
            <View style={styles.equityPlansContainer}>
              <SideHeading
                title="Equity Investment Plans"
                style={[styles.yourPlans, { marginTop: 35 }]}
              />
              <EquityPlans
                style={styles.equityPlans}
                navigation={this.props.navigation}
                data={equity_investment_plans}
                activePlans={subscribed_products}
                userId={this.state.userId}
                isNew={true}
              />
            </View>
          )}

          {/* Mutual Fund Plans */}
          {this.state.shouldShowMF &&
          this.state.mfData &&
          !this.state.userId ? (
            <View
              style={[
                styles.equityPlansContainer,
                { marginTop: isMobile ? 20 : 0 },
              ]}
            >
              <SideHeading
                title="Mutual Fund Investments Overview"
                style={[styles.yourPlans]}
              />
              <MutualFundPlansOld
                style={styles.equityPlans}
                navigation={this.props.navigation}
                data={equity_investment_plans}
                mfData={this.state.mfData}
                mfDetails={this.state.mfDetails}
                activePlans={subscribed_products}
                userId={this.state.userId}
                isNew={true}
              />
            </View>
          ) : null}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    paddingTop: 30,
    paddingBottom: isMobile ? 100 : 0,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  errorText: { fontSize: 16, color: "#121212" },
  scrollView: {
    flexGrow: 1,
  },
  portfolioHeading: {
    marginBottom: 20,
    marginLeft: 20,
  },
  summaryContainer: {
    flex: 1,
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    marginBottom: 0,
    marginHorizontal: 20,
  },
  equityPlansContainer: {
    marginHorizontal: 20,
  },
  yourPlans: {
    marginBottom: 20,
  },
  equityPlans: {
    flex: 1,
    paddingBottom: 100,
  },
});

export default OldHomeDashboard;
