import React from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  ActivityIndicator,
} from "react-native";
import NavigationHeader from "../../components/Common/NavigationHeader";
import { getContractNoteDetails } from "../../api";
import Table from "../../components/Common/Table";
import ProductName from "./Components/product";
import {
  formatDate,
  getUserDetails,
  isMobile,
  numberWithCommas,
} from "../../utils";
import HoldingsTable from "../billing/HoldingsTable";
import SearchBar from "../../components/Common/SearchBar";
import amplitude from "../../utils/amplitude";

export default class contractNotesTransactionDetails extends React.Component {
  constructor(props) {
    super(props);
    let userId = null,
      ccn_id;

    const { params } = this.props.route;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      ccn_id = params.ccn_id || null;
    }
    this.state = {
      userId,
      ccn_id,
      isLoading: false,
      data: [],
    };
  }

  componentDidMount = async () => {
    if (Platform.OS == "web"){
      amplitude.logEvent("ContractNotes",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("ContractNotes", { path: this.props.navigation.getState().routeName });
    }
    const { ccn_id } = this.state;
    try {
      const payload = {
        ccn_pk: ccn_id,
      };
      let data = [];
      const response = await getContractNoteDetails(payload);
      // console.log("cnn response", response);
      if (response && response.code == 100) {
        this.setState({ isLoading: false });
        const { result } = response;
        data = [...result];
      } else {
        this.setState({ isLoading: false });
      }
      const searchResults = data;
      this.setState({ data, searchResults, loadingData: false });
    } catch (error) {
      // console.log("error", error);
      this.setState({ isLoading: false });
    }
  };

  getHeaderData = (data) => {
    const { sorting_field, sorting_value } = this.state;

    const keysData = [
      {
        label: "Trade Date",
        key: "trade_date",
      },
      {
        label: "Transaction Type",
        key: "transaction_type",
      },
      {
        label: "Price",
        key: "price",
      },
      {
        label: "Units",
        key: "number_of_units",
      },
      {
        label: "Amount",
        key: "amount",
      },

      {
        label: "Advisory Strategy",
        key: "Advisory Strategy",
      },
    ];

    let headerData = [];
    keysData.forEach((headerItem) => {
      headerData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: false,
        // onSort: this.handleSort,
        key: headerItem.key,
        // currentSortField: sorting_field,
        // sortValue: sorting_value
      });
    });

    let values = [];

    data.forEach((value, index) => {
      let {
        transaction_type,
        price,
        number_of_units,
        amount,
        trade_date,
        product,
      } = value;
      const formattedDate = formatDate(trade_date);
      let priceFormatted =
        price != null ? "₹" + numberWithCommas(price.toFixed(2)) : "-";
      let amountFormatted =
        amount != null ? numberWithCommas(amount.toFixed(2)) : "-";
      let negativeValue =
        amount && amount < 0
          ? amountFormatted[0] + "₹" + amountFormatted.substring(1)
          : "₹" + amountFormatted;
      values.push([
        {
          valueToDisplay: formattedDate,
          value: trade_date,
          style: [styles.rowItem, {paddingLeft: isMobile ? 30: 15}],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: transaction_type,
          value: transaction_type,
          style: [styles.rowItem, styles.date],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: priceFormatted,
          value: price,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: number_of_units,
          value: number_of_units,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: negativeValue,
          value: amount,
          style: [styles.rowItem, styles.amount],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },

        {
          value: <ProductName product={product} />,
          component: true,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ]);
    });

    return { headerData, values };
  };

  render() {
    const {
      data,
      searchText,
      searchResults = [],
      showStocksOf,
      loadingData,
      isLoading,
    } = this.state;
    const { headerData, values } = this.getHeaderData(searchResults);
    return (
      <View style={styles.container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Passbook", "Contract Notes", "View Details"]}
          style={styles.headerStyle}
        />
        <ScrollView>
          <ScrollView horizontal={true}>
          <View style={styles.detailsContainer}>
            {isLoading ? (
              <ActivityIndicator
                size="large"
                color="Black"
                style={{ alignSelf: "center" }}
              />
            ) : (
              <View 
                style={{
                  borderWidth: 1,
                  borderColor: "#1212121A",
                  borderRadius: 10,
                  marginHorizontal: isMobile ? 16 : 20,
                  width: isMobile ? 700 : '100%',
                  marginVertical: 20,
                }}
              >
                <HoldingsTable
                  header={headerData}
                  gridConfig={[0.15, 0.15, 0.15, 0.25, 0.25, 0.15]}
                  data={values}
                  navigation={this.props.navigation}
                  style={[{minWidth: isMobile? 1400 : "100%"}]}
                />
              </View>
              // <View>
              //   {data.length ? (
              //     <Table
              //       header={headerData}
              //       tableHeaderStyle={[{}]}
              //       gridConfig={[0.15, 0.15, 0.15, 0.25, 0.25, 0.15]}
              //       data={values}
              //       minWidth={1500}
              //     />
              //   ) : (
              //     <Text style={styles.nodata}>No data to display</Text>
              //   )}
              // </View>
            )}
          </View>
          </ScrollView>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: "column", backgroundColor: "#ffffff" },

  headerStyle: { width: "100%", maxWidth: 1500 },
  detailsContainer: {
    marginHorizontal: isMobile ? 16 : 36,
    marginBottom: 50,
    minWidth: 1000,
    marginTop: 30,
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  rowItem: {
    textAlign: "left",
    fontSize: 13,
  },
  rowItemContainerStyle: {
    justifyContent: "center",
    // paddingLeft: 10,
  },
  headerItemContainerStyle: {
    justifyContent: "center",
  },
  amount: {
    fontWeight: "700",
  },
  date: {
    paddingLeft: 10,
  },
  nodata: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#121212",
    textAlign: "center",
  },
});
