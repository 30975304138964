import React from "react";
import {
  View,
  StatusBar,
  ScrollView,
  SafeAreaView,
  Platform,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Text } from "react-native-paper";
import NavigationHeader from "../../../../components/Common/NavigationHeader";
import { isMobile, months_short_names } from "../../../../utils";
import ANTIcon from "@expo/vector-icons/AntDesign";
import Divider from "../../../../components/Common/divider";
import theme from "../../../../static/theme";
import { SideHeading } from "../../../../components/Common/sideHeading";
import amplitude from "../../../../utils/amplitude";
import StatusModal from "../../../../components/Common/Modals/statusModal";
import {
  cancelSIPOrder,
  getMySIPs,
  getSIPOrderDetails,
  redeemSIP,
} from "../../../../api/account";
import Store from "../../../../store";
import EntypoIcon from "@expo/vector-icons/Entypo";
import { ShowToast } from "../../../../components/Common/Toast";
import { StackActions } from "@react-navigation/native";
import ProgressBar from "../../../../utils/progressBar";
var Image_url = "https://jamaappprod.s3.amazonaws.com/media/";

const FieldItem = ({ heading, value, valueStyle, style }) => {
  return (
    <View style={[style ? { ...style } : {}]}>
      <Text style={{ fontSize: 14, color: "#A8A8A8", marginBottom: 5 }}>
        {heading}
      </Text>
      <Text
        style={[
          { fontSize: 14, color: "#121212" },
          [valueStyle ? { ...valueStyle } : {}],
        ]}
      >
        {value}
      </Text>
    </View>
  );
};

export default class SIPDetails extends React.Component {
  constructor(props) {
    super(props);
    let data = null;
    let amcfundscheme = null;
    let index = null;
    let amcfund_id = null;
    let bse_sip_reg_id = null;
    let activeTab = null;
    const params = this.props.route;
    if (params) {
      data = params.params.data || null;
      amcfundscheme = params.params.amcfundscheme || null;
      index = params.params.index || 0;
      amcfund_id = params.params.amcfund_id || null;
      bse_sip_reg_id = params.params.bse_sip_reg_id || null;
      activeTab = params.params.activeTab || null;
    }
    this.state = {
      data,
      index,
      activeTab,
      amcfundscheme,
      amcfund_id,
      endingDate: ["-"],
      responseData: null,
      loading: false,
      bse_sip_reg_id,
      isLoading: true,
      showToast: false,
      toastTitle: null,
    };
  }

  redeem = async () => {
    this.setState({ isLoading: true });
    const { units, amcfund_scheme_code } = this.state.responseData;
    if (units && units != null && amcfund_scheme_code) {
      const data = {
        units,
        scheme_code: amcfund_scheme_code,
      };
      const response = await redeemSIP(data);
      if (response && response.code == "100") {
        this.setState({
          isLoading: false,
          showToast: true,
          toastTitle: "Redeem SIP successful!",
        });
        this.props.navigation.goBack();
        setTimeout(() => {
          this.props.navigation.dispatch(
            StackActions.replace("App", {
              screen: "Plans",
              params: {
                screen: "PlansHome",
                params: {
                  activeTabIndex: 1,
                },
              },
            })
          );
        }, 2000);
      } else if (response && response.code == "101" && response.msgs) {
        if (response.msgs.includes("Your redemption orderError")) {
          this.setState({
            isLoading: false,
            showToast: true,
            toastTitle: "SIP already redeemed",
          });
          setTimeout(() => {
            this.props.navigation.dispatch(
              StackActions.replace("App", {
                screen: "Plans",
                params: {
                  screen: "PlansHome",
                  params: {
                    activeTabIndex: 1,
                  },
                },
              })
            );
          }, 2000);
        }
        setTimeout(() => {
          this.props.navigation.dispatch(
            StackActions.replace("App", {
              screen: "Plans",
              params: {
                screen: "PlansHome",
                params: {
                  activeTabIndex: 1,
                },
              },
            })
          );
        }, 2000);
      } else {
        this.setState({
          isLoading: false,
          showToast: true,
          toastTitle: response.data.error || "Something went wrong!",
        });
        setTimeout(() => {}, 1000);
      }
    } else {
      this.setState({ isLoading: false });
    }
  };

  async componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("sipDetails", { path: global.location.href });
    } else {
      amplitude.logEvent("sipDetails", {
        path: this.props.navigation.getState().routeName,
      });
    }
    this.setState({ isLoading: true });

    const bse_sip_reg_id = this.state.bse_sip_reg_id;
    const payload = { reg_id: bse_sip_reg_id };

    let response = [];
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      response = await getSIPOrderDetails(
        Store.getState().dashboard.viewModeUserId.user_id,
        payload
      );
    } else {
      response = await getSIPOrderDetails(null, payload);
    }

    if (response && response.code == 100) {
      let { data } = this.state;
      const { results } = response;
      // console.log("result value is ", results);
      const endingDate = this.calculateEndingDate(
        results.sip_start_date.toString(),
        results.no_of_installments
      );
      // console.log("result value is", JSON.stringify(endingDate));
      this.setState({
        responseData: results,
        endingDate,
        isLoading: false,
        error: false,
      });
    } else {
      this.setState({ isLoading: false, error: true });
    }

    // let getSIPResp = [];
    // if (
    //   Store.getState().dashboard &&
    //   Store.getState().dashboard.viewModeUserId &&
    //   Store.getState().dashboard.viewModeUserId.user_id
    // ) {
    //   getSIPResp = await getMySIPs(
    //     Store.getState().dashboard.viewModeUserId.user_id
    //   );
    // } else {
    //   getSIPResp = await getMySIPs();
    // }          // Snackbar.show({
    //   title: response.data.error || "Something went wrong!",
    //   duration: Snackbar.LENGTH_SHORT,
    //   backgroundColor: "red",
    // });
    // getSIPResp.forEach((item) => {});
    // if (getSIPResp && Array.isArray(getSIPResp)) {
    //   const sipData = getSIPResp.filter(
    //     (item) =>
    //       item.bse_trans_code == "NEW" &&
    //       item.amcfundscheme == this.state.amcfundscheme
    //   );
    //   this.setState({ data: sipData[0] }, async () => {
    //     if (this.state.data.bse_sip_reg_id) {
    //       const bse_sip_reg_id = this.state.data.bse_sip_reg_id;
    //       const payload = { reg_id: bse_sip_reg_id };
    //       // const response = await getSIPOrderDetails(payload);

    //       let response = [];
    //       if (
    //         Store.getState().dashboard &&
    //         Store.getState().dashboard.viewModeUserId &&
    //         Store.getState().dashboard.viewModeUserId.user_id
    //       ) {
    //         response = await getSIPOrderDetails(
    //           Store.getState().dashboard.viewModeUserId.user_id,
    //           payload
    //         );
    //       } else {
    //         response = await getSIPOrderDetails(null, payload);
    //       }

    //       if (response && response.code == 100) {
    //         let { data } = this.state;
    //         const { results } = response;
    //         this.setState({
    //           responseData: results,
    //           isLoading: false,
    //           error: false,
    //         });
    //       } else {
    //         this.setState({ isLoading: false, error: true });
    //       }
    //     } else {
    //       this.setState({ isLoading: false, error: true });
    //     }
    //   });
    // }
  }

  pauseSIP = () => {
    const {
      amcfundscheme__bse_sip_pause_minimum_numbers,
      amcfundscheme__bse_sip_pause_maximum_numbers,
    } = this.state.responseData;
    this.props.navigation.navigate("PauseSIP", {
      data: {
        amcfundscheme__bse_sip_pause_minimum_numbers,
        amcfundscheme__bse_sip_pause_maximum_numbers,
      },
    });
  };

  cancelSIP = async () => {
    try {
      const sipRegiD = this.state.data.bse_sip_reg_id;
      if (!sipRegiD) return;
      // Snackbar.show({
      //   title: "Please wait while we cancel your SIP Order",
      //   duration: Snackbar.LENGTH_LONG,
      // });
      this.setState({ isLoading: true });
      const payload = { reg_id: sipRegiD };
      const fundResponse = await cancelSIPOrder(payload);
      if (fundResponse.hasOwnProperty("error")) {
        this.setState({
          isLoading: false,
          showToast: true,
          toastTitle: fundResponse.error || "Something went wrong!",
        });
        setTimeout(() => {
          // Snackbar.show({
          //   title: fundResponse.error,
          //   duration: Snackbar.LENGTH_LONG,
          //   backgroundColor: "red",
          // });
        }, 1000);
      } else {
        Store.dispatch({
          type: "GET_PLANS",
          payload: {
            landingPageResponse: null,
            getMySIPsResponse: null,
          },
        });
        this.props.navigation.dispatch(
          StackActions.replace("App", {
            screen: "Plans",
            params: {
              screen: "PlansHome",
              params: {
                activeTabIndex: 1,
              },
            },
          })
        );
        this.setState({
          isLoading: false,
          showToast: true,
          toastTitle:
            "Your SIP has been cancelled. You shall recieve email/SMS conforming the same.",
        });
        setTimeout(() => {
          // Snackbar.show({
          //   title:
          //     "Your SIP has been cancelled. You shall recieve email/SMS conforming the same.",
          //   duration: Snackbar.LENGTH_LONG,
          //   backgroundColor: "green",
          // });
        }, 1000);
      }
    } catch (error) {
      this.setState({
        loading: false,
        showToast: true,
        toastTitle: error,
      });
      setTimeout(() => {
        // Snackbar.show({
        //   title: "Something went wrong. contact help@maxiom.in",
        //   duration: Snackbar.LENGTH_LONG,
        //   backgroundColor: "red",
        // });
      }, 1000);
    }
  };

  calculateEndingDate = (startDate, totalInstallments) => {
    const endingDate = new Date(startDate);
    endingDate.setMonth(endingDate.getMonth() + totalInstallments);
    const year = endingDate.getFullYear();
    const month = String(endingDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so add 1
    const day = String(endingDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return [this.formatDate(formattedDate)];
  };
  formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = `${day}-${monthNames[monthIndex]}-${year}`;
    return formattedDate;
  }
  render() {
    if (this.state.isLoading) {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
          {this.state.showToast ? (
            <ShowToast
              showToast={true}
              type="status"
              title={this.state.toastTitle}
              duration="5"
            />
          ) : null}
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["My SIP Details"]}
            onPressBack={() => {
              // this.props.navigation.goBack();
              this.props.navigation.navigate("App", {
                screen: "Plans",
                params: {
                  screen: "PlansHome",
                  params: {
                    activeTabIndex: 1,
                    activeTab: this.state.activeTab,
                  },
                },
              });
            }}
          />
          <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
          <ProgressBar msg={"Loading..."} />
        </SafeAreaView>
      );
    }

    const { scheme_name, amc_logo, amount, sip_next_due_date } =
      this.state.data;
    const logo = Image_url + amc_logo;
    const postiveGrowth =
      this.state.one_day_gain && !isNaN(this.state.one_day_gain)
        ? parseInt(this.state.one_day_gain) > 0
        : null;

    const sip_per_month = this.state.data.amount;
    let next_due = sip_next_due_date.split("-");
    const month = months_short_names(parseInt(next_due[1]));

    next_due = next_due[0] + "-" + month + "-" + next_due[2];
    const latest_value = this.state.responseData.latest_value;

    if (!this.state.responseData) return null;

    let {
      mandate_id,
      bank_name,
      bank_acc_num,
      auto_debt_description,
      last_updated,
      holding_period,
      one_day_gain,
      xirr,
      avg_buy_nav,
      cur_nav,
      units,
      bse_folio_no,
      tagged_goal,
      exit_load,
      invested,
      gain,
      latest_amount,
      amcfundscheme__bse_sip_pause_flag,
    } = this.state.responseData;

    latest_amount = latest_amount != "" ? latest_amount : null;

    const unrealised_gain = gain;

    avg_buy_nav =
      avg_buy_nav && !isNaN(avg_buy_nav) ? avg_buy_nav.toFixed(4) : "-";
    const current_nav = cur_nav && !isNaN(cur_nav) ? cur_nav.toFixed(4) : "-";
    const units_held = units && !isNaN(units) ? units.toFixed(3) : "-";

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
        {this.state.showToast ? (
          <ShowToast
            showToast={true}
            type="status"
            title={this.state.toastTitle}
            duration="5"
          />
        ) : null}
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["My SIP Details"]}
          onPressBack={() => {
            // this.props.navigation.goBack();
            this.props.navigation.navigate("App", {
              screen: "Plans",
              params: {
                screen: "PlansHome",
                params: {
                  activeTabIndex: 1,
                  activeTab: this.state.activeTab,
                },
              },
            });
          }}
          rightSideComponent={
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
              onPress={() => {
                this.setState({ showStatusModal: true });
              }}
            >
              <EntypoIcon
                name={"dots-three-vertical"}
                color={"#121212"}
                size={20}
                style={{}}
              />
            </TouchableOpacity>
          }
        />
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />

        <ScrollView
          style={{
            width: "100%",
            height: "100%",
            marginBottom: this.state.showStatusModal ? 180 : 0,
          }}
          showsVerticalScrollIndicator={true}
        >
          {this.state.data && !this.state.error ? (
            <View style={{ width: "100%", height: "100%" }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 15,
                }}
              >
                <View style={{}}>
                  <View
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: 5,
                      borderWidth: 1,
                      borderColor: "#410DAA1A",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={{ uri: logo }}
                      resizeMode="contain"
                      style={{ height: 20, width: 20 }}
                    />
                  </View>
                </View>
                <View style={{ flex: 0.6 }}>
                  <Text
                    style={{ fontSize: 14, color: "#121212", paddingLeft: 10 }}
                  >
                    {scheme_name}
                  </Text>
                </View>
                <View style={{ flex: 0.3, alignItems: "flex-end" }}>
                  {latest_amount && (
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#121212",
                      }}
                    >
                      {theme.variables.RUPEE_SYMBOL}
                      {amount}
                    </Text>
                  )}
                  {postiveGrowth != null && (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <ANTIcon
                        name={postiveGrowth ? "caretup" : "caretdown"}
                        color={
                          postiveGrowth
                            ? theme.colors.GREEN_COLOR
                            : theme.colors.ORANGE_COLOR
                        }
                        size={10}
                        style={{ marginRight: 5 }}
                      />
                      <Text
                        style={{
                          fontSize: 14,
                          color: postiveGrowth
                            ? theme.colors.GREEN_COLOR
                            : theme.colors.ORANGE_COLOR,
                        }}
                      >
                        {theme.variables.RUPEE_SYMBOL}
                        {parseInt(this.state.one_day_gain)}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  paddingHorizontal: 16,
                  marginBottom: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    color: "#121212",
                    paddingRight: 5,
                    fontWeight: "bold",
                  }}
                >
                  {sip_per_month}
                </Text>
                <Text
                  style={{ fontSize: 14, color: "#121212", fontWeight: "bold" }}
                >
                  /month
                </Text>
                <Text
                  style={{ fontSize: 14, color: "#465964", marginLeft: 10 }}
                >
                  Next Due:{next_due}
                </Text>
              </View>
              <Divider
                width={0.8}
                style={{ marginVertical: 10 }}
                color="#00000014"
              />

              {/* Mandate and Bank details */}
              <View style={{ marginVertical: 15, marginHorizontal: 16 }}>
                <Text
                  style={{ fontSize: 14, color: "#876CDD", marginBottom: 5 }}
                >
                  Mandate ID:
                  <Text style={{ fontSize: 14, color: "#121212" }}>
                    {" "}
                    {mandate_id && mandate_id != "" ? mandate_id : "-"}
                    {bank_name && bank_name != ""
                      ? ` (${
                          bank_name +
                          (bank_acc_num && bank_acc_num != ""
                            ? " A/c " + "xx" + bank_acc_num.substr(-3)
                            : "")
                        })`
                      : ""}
                  </Text>
                </Text>
                {!!auto_debt_description && auto_debt_description != "" && (
                  <Text style={{ fontSize: 14, color: "#A8A8A8" }}>
                    {auto_debt_description}
                  </Text>
                )}
              </View>
              <Divider width={0.8} style={{}} color="#00000014" />

              {/* Fund stats */}
              <View style={{ marginVertical: 15 }}>
                <Text
                  style={{
                    fontSize: 14,
                    color: "#121212",
                    marginBottom: 16,
                    paddingHorizontal: 16,
                    paddingVertical: 5,
                    backgroundColor: "#F3F6FF",
                  }}
                >
                  Updated on {last_updated}
                </Text>
                <View style={{ marginHorizontal: 16 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: 20,
                    }}
                  >
                    <FieldItem
                      heading="Current Value"
                      value={latest_value}
                      valueStyle={{}}
                      style={{ flex: 0.33, alignItems: "flex-start" }}
                    />
                    <FieldItem
                      heading="Invested Value"
                      value={invested}
                      valueStyle={{}}
                      style={{ flex: 0.33, alignItems: "center" }}
                    />
                    <FieldItem
                      heading="Avg Hold Period"
                      value={holding_period}
                      valueStyle={{}}
                      style={{ flex: 0.33, alignItems: "flex-end" }}
                    />
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: 20,
                    }}
                  >
                    <FieldItem
                      heading="1 Day Gain"
                      value={one_day_gain}
                      valueStyle={{ color: theme.colors.GREEN_COLOR }}
                      style={{ flex: 0.33, alignItems: "flex-start" }}
                    />
                    <FieldItem
                      heading="Unrealised Gain"
                      value={unrealised_gain}
                      valueStyle={{ color: theme.colors.GREEN_COLOR }}
                      style={{ flex: 0.33, alignItems: "center" }}
                    />
                    <FieldItem
                      heading="XIRR"
                      value={xirr}
                      valueStyle={{}}
                      style={{ flex: 0.33, alignItems: "flex-end" }}
                    />
                  </View>
                  <View
                    style={{ flexDirection: "row", alignItems: "flex-start" }}
                  >
                    <FieldItem
                      heading="Avg. buy NAV"
                      value={avg_buy_nav}
                      valueStyle={{}}
                      style={{ flex: 0.33, alignItems: "flex-start" }}
                    />
                    <FieldItem
                      heading="Curr. NAV"
                      value={current_nav}
                      valueStyle={{}}
                      style={{ flex: 0.33, alignItems: "center" }}
                    />
                    <FieldItem
                      heading="Units Held"
                      value={units_held}
                      valueStyle={{}}
                      style={{ flex: 0.33, alignItems: "flex-end" }}
                    />
                  </View>
                </View>
              </View>

              {/* More Information */}
              <SideHeading
                title={"More Information"}
                style={{ alignItems: "center", fontSize: 15 }}
              />
              <View
                style={{
                  marginVertical: 15,
                  marginHorizontal: 16,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View style={{ width: "45%" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: 15,
                    }}
                  >
                    <Text
                      style={{ flex: 0.22, fontSize: 14, color: "#A8A8A8" }}
                    >
                      Started Date:
                    </Text>
                    <Text
                      style={{
                        flex: 0.78,
                        fontSize: 14,
                        color: "#121212",
                        paddingLeft: 8,
                      }}
                    >
                      {this.state.responseData != null
                        ? this.formatDate(
                            this.state.responseData.sip_start_date
                          )
                        : "-"}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: 15,
                    }}
                  >
                    <Text style={{ fontSize: 14, color: "#A8A8A8" }}>
                      Number of installments:
                    </Text>
                    <Text
                      style={{
                        flex: 0.78,
                        fontSize: 14,
                        color: "#121212",
                        paddingLeft: 8,
                      }}
                    >
                      {this.state.responseData != null
                        ? this.state.responseData.no_of_installments
                        : "0"}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: 15,
                    }}
                  >
                    <Text style={{ fontSize: 14, color: "#A8A8A8" }}>
                      Number of installments completed:
                    </Text>
                    <Text
                      style={{
                        flex: 0.78,
                        fontSize: 14,
                        color: "#121212",
                        paddingLeft: 8,
                      }}
                    >
                      {this.state.responseData != null
                        ? this.state.responseData.no_of_installments_executed
                        : "-"}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: 15,
                    }}
                  >
                    <Text
                      style={{ flex: 0.22, fontSize: 14, color: "#A8A8A8" }}
                    >
                      End Date:
                    </Text>
                    <Text
                      style={{
                        flex: 0.78,
                        fontSize: 14,
                        color: "#121212",
                        paddingLeft: 8,
                      }}
                    >
                      {this.state.responseData != null
                        ? this.state.responseData.sip_end_date != null
                          ? this.state.responseData.sip_end_date
                          : this.state.endingDate.map((item) => (
                              <Text>{item}</Text>
                            ))
                        : "-"}
                    </Text>
                  </View>
                </View>
                <View style={{ width: "45%" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: 15,
                    }}
                  >
                    <Text
                      style={{ flex: 0.22, fontSize: 14, color: "#A8A8A8" }}
                    >
                      Folio Number:
                    </Text>
                    <Text
                      style={{
                        flex: 0.78,
                        fontSize: 14,
                        color: "#121212",
                        paddingLeft: 8,
                      }}
                    >
                      {bse_folio_no}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: 15,
                    }}
                  >
                    <Text
                      style={{ flex: 0.22, fontSize: 14, color: "#A8A8A8" }}
                    >
                      Tagged Goal:
                    </Text>
                    <Text
                      style={{
                        flex: 0.78,
                        fontSize: 14,
                        color: "#121212",
                        paddingLeft: 8,
                      }}
                    >
                      {tagged_goal}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: 70,
                    }}
                  >
                    <Text
                      style={{ flex: 0.22, fontSize: 14, color: "#A8A8A8" }}
                    >
                      Exit Load:
                    </Text>
                    <Text
                      style={{
                        flex: 0.78,
                        fontSize: 14,
                        color: "#121212",
                        paddingLeft: 8,
                      }}
                    >
                      {exit_load}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: 15, color: "#121212" }}>
                Something went wrong!
              </Text>
            </View>
          )}
        </ScrollView>
        {this.state.data && (
          <View
            style={{
              position: "absolute",
              bottom: 10,
              left: 0,
              right: 0,
              // marginHorizontal: 16,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* {!!this.state.responseData.units &&
                this.state.responseData.units != "" && (
                  <>
                  <TouchableOpacity
                    onPress={() => {
                      this.redeem();
                    }}
                    style={{
                      // flex: 0.3,
                      borderWidth: 1,
                      borderColor: "#410DAA",
                      borderRadius: 5,
                      paddingVertical: 9,
                      paddingHorizontal: 5,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ fontSize: 17, color: "#410DAA" }}>
                      Redeem
                    </Text>
                  </TouchableOpacity>
                  <View style={{padding: 20}}></View>
                </>
                )} */}
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate("Explore", {
                    screen: "FundDescription",
                    params: {
                      fund: this.state.responseData.amcfund_id
                        ? this.state.responseData.amcfund_id
                        : this.state.amcfund_id,
                      fund_amc_id: this.state.amcfundscheme,
                      from: "Plans"
                    },
                  });
                }}
                style={{
                  borderWidth: 1,
                  borderColor: "#410DAA",
                  borderRadius: 5,
                  paddingVertical: 8,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#410DAA",
                  width: isMobile ? "60%" : "20%",
                  // marginLeft: isMobile ? "20%" : "40%",
                  // marginRight: isMobile ? "20%" : "40%",
                  // paddingBottom: isMobile ? 66 : 33,
                }}
              >
                <Text style={{ fontSize: 17, color: "#fff" }}>Invest More</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}

        <StatusModal
          visible={this.state.showStatusModal}
          buttons={[
            amcfundscheme__bse_sip_pause_flag && {
              title: "Pause SIP",
              buttonOnPress: () => {
                this.setState(
                  {
                    showStatusModal: false,
                  },
                  () => {
                    this.pauseSIP();
                  }
                );
              },
              style: {
                backgroundColor: "#fff",
                borderColor: "#EFA512",
                width: isMobile ? "60%" : "20%",
                marginLeft: isMobile ? "20%" : "40%",
                marginRight: isMobile ? "20%" : "40%",
              },
              color: "#EFA512",
            },

            {
              title: "Cancel SIP",
              buttonOnPress: () => {
                this.setState(
                  {
                    showStatusModal: false,
                  },
                  () => {
                    this.cancelSIP();
                  }
                );
              },
              style: {
                backgroundColor: "#fff",
                borderColor: "#EF5D12",
                width: isMobile ? "60%" : "20%",
                marginLeft: isMobile ? "20%" : "40%",
                marginRight: isMobile ? "20%" : "40%",
              },
              color: "#EF5D12",
            },
            {
              title: "Do Nothing",
              buttonOnPress: () => {
                this.setState({ showStatusModal: false });
              },
              style: {
                backgroundColor: "#410DAA",
                borderColor: "#410DAA",
                width: isMobile ? "60%" : "20%",
                marginLeft: isMobile ? "20%" : "40%",
                marginRight: isMobile ? "20%" : "40%",
              },
              color: "#fff",
            },
          ]}
        />
      </SafeAreaView>
    );
  }
}
