import React from "react";
import { StyleSheet, View } from "react-native";

const EmptyView = props => {
  return (
    <View
      style={[
        styles.emptyView,
        { marginVertical: props.gap, backgroundColor: props.backgroundColor },
        props.style
      ]}
    />
  );
};

const styles = StyleSheet.create({
  emptyView: {
    height: StyleSheet.hairlineWidth
  }
});

EmptyView.defaultProps = {
  gap: 16,
  backgroundColor: "#ccc"
};

export default EmptyView;
