import React from "react";
import { SafeAreaView, Platform } from "react-native";
const WebView =
  Platform.OS == "web"
    ? require("react-native-web-webview").WebView
    : require("react-native-webview").WebView;
import NavigationHeader from "../../../components/Common/NavigationHeader";

const PrivacyPolicy = (props) => {
  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: "#fff",
      }}
    >
      <NavigationHeader
        navigation={props.navigation}
        navigationHeader={["Privacy Policy"]}
        onPressBack={() => {
          props.navigation.goBack();
        }}
      />
      <WebView source={{ uri: props.route.params.url }} />
    </SafeAreaView>
  );
};

export default PrivacyPolicy;
