import React from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  Linking,
} from "react-native";
import { Button } from "react-native-paper";
import NavigationHeader from "../../components/Common/NavigationHeader";
import Entypo from "@expo/vector-icons/Entypo";
import { useNavigation } from "@react-navigation/core";
import { isJama, isMobile } from "../../utils";
const { width } = Dimensions.get("window");

const FAQItem = ({
  url,
  onPress = null,
  label = "",
  style = [],
  border = true,
}) => {
  const navigation = useNavigation();
  const handlePress = () => {
    if (onPress) onPress();
    if (url) {
      if (isMobile) {
        navigation.navigate("App", {
          screen: "Portfolio",
          params: {
            screen: "ShowWebView",
            params: { url },
          },
        });
      } else {
        Linking.openURL(url);
      }
    }
  };

  return (
    <TouchableOpacity style={[...style]} onPress={handlePress}>
      <View style={[styles.itemContainer]}>
        <Text style={styles.label}> {label} </Text>
        <Entypo
          name="chevron-right"
          color="#464e5f"
          size={18}
          style={{ marginRight: 10 }}
        />
      </View>
      {border && (
        <View
          style={{ width: "100%", height: 2, backgroundColor: "#464e5f25" }}
        />
      )}
    </TouchableOpacity>
  );
};
const FAQ = (props) => {
  const handleRaiseTicket = () => {
    props.navigation.push("App", {
      screen: "RaiseTicket",
      params: {
        screen: "Feedback",
      },
    });
  };

  return (
    <>
      <NavigationHeader
        navigation={props.navigation}
        navigationHeader={["FAQ"]}
        onPressBack={() => {
          props.navigation.goBack();
        }}
      />
      <View style={styles.mainContainer}>
        <View style={{ width: width < 1200 ? "100%" : "60%" }}>
          <FAQItem
            url={`https://${
              isJama() ? "jama.co" : "maxiom"
            }.in/app/acn_faqs/EQUINVREBAL`}
            label="Equity Investing & Rebalancing"
          />
          <FAQItem
            url={`https://${
              isJama() ? "jama.co" : "maxiom"
            }.in/app/acn_faqs/EQADV`}
            label="Equity Advisory Account Related"
          />
          <FAQItem
            url={`https://${
              isJama() ? "jamawealth" : "maxiomwealth"
            }.com/blog/how-to-invest-in-jamawealth-portfolio/`}
            label="How to invest?"
          />
          <FAQItem
            url={`https://${
              isJama() ? "jamawealth" : "maxiomwealth"
            }.com/blog/how-to-rebalance-your-portfolio-on-jamawealth/`}
            label="How to rebalance portfolio?"
            border={false}
          />

          <Button
            uppercase={false}
            mode={"contained"}
            onPress={handleRaiseTicket}
            style={{
              width: isMobile ? "100%" : "30%",
              alignSelf: "center",
              marginTop: 60,
              paddingVertical: 4,
            }}
            color={"#410DAA"}
          >
            <Text style={{ fontSize: 14 }}>Raise a Ticket</Text>
          </Button>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  mainContainer: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: "white",
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 14,
    backgroundColor: "white",
  },
  label: {
    fontSize: 15,
    color: "#464e5f",
    fontWeight: "600",
  },
});

export default FAQ;
