import React from "react";
import {
  View,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  Platform,
  BackHandler,
  TouchableOpacity,
  Image,
} from "react-native";
import { Text } from "react-native-paper";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import ANTIcon from "@expo/vector-icons/AntDesign";
import { getQuizResult } from "../../../api";
import amplitude from "../../../utils/amplitude";
import { getStatusColor, getWindowSize, isMobile } from "../../../utils";
import { CustomModal } from "../../../components/Common/CustomModal";
import CustButton from "../../../components/Common/Button";

export default class QuizResult extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let quiz_id = null;
    let from = null;
    if (params) {
      quiz_id = params.quiz_id || null;
      from = params.from || null;
      quiz_id = isNaN(quiz_id) ? null : quiz_id;
    }
    this.state = {
      quiz_id,
      from,
      result: {},
      fetching: false,
      showModal: false,
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("quizResult",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("quizResult", { path: this.props.navigation.getState().routeName });
    }
    this.getQuizResult();
    this.backHandler = BackHandler.addEventListener("hardwareBackPress", () => {
      this.props.navigation.navigate("App", {
        screen: "ViewMode",
        params: {
          screen: "FinAwareness",
          params: {},
        },
      });
    });
  }

  getQuizResult = async () => {
    this.setState({
      fetching: true,
    });
    try {
      const { quiz_id = null } = this.state;
      if (quiz_id) {
        const payload = {
          quiz_id,
        };
        const response = await getQuizResult(payload);
        this.setState({
          result: response.quiz_data,
          feyching: false,
        });
      }
    } catch (err) {
      this.setState({
        fetching: false,
      });
    }
  };

  static navigationOptions = {
    tabBarVisible: false,
  };

  renderHtml = (text) => {
    return <HTMLView value={text} />;
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  getBackgroundColor = (index, percentage, length) => {
    if (!length) return "#121212";
    if (index == 0) {
      return "#EF1212";
    } else if (index == 1 && length == 3) {
      return "#FBBC29";
    } else if (index == 1 && length == 4) {
      return "#EF7212";
    } else if (index == 2 && length == 3) {
      return "#009105";
    } else if (index == 2 && length == 4) {
      return "#FBBC29";
    } else if (index == 3 && length == 4) {
      return "#009105";
    }
  };

  renderModalContent = () => {
    const { result } = this.state;

    let number_of_quiz_personas;
    if (result && result.quiz_personas && result.quiz_personas != "") {
      number_of_quiz_personas = result.quiz_personas.length;
    } else {
      return null;
    }

    return (
      <View style={{ width: getWindowSize().width * 0.8, height: "auto" }}>
        {result &&
          Array.isArray(result.quiz_personas) &&
          result.quiz_personas.map((persona, index) => {
            return (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginVertical: 8,
                }}
              >
                <View style={{ flex: 0.15 }}>
                  <Image
                    source={{ uri: persona.persona_image_url }}
                    style={{ width: 35, height: 35, alignSelf: "center" }}
                    resizeMode={"contain"}
                  />
                </View>
                <View style={{ flex: 0.6, paddingLeft: 10 }}>
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      color: "#121212",
                      flexWrap: "wrap",
                    }}
                  >
                    {persona.persona_name}
                  </Text>
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#121212",
                      flexWrap: "wrap",
                    }}
                  >
                    {persona.short_description}
                  </Text>
                </View>
                <View style={{ flex: 0.25, alignItems: "flex-end" }}>
                  <View
                    style={{
                      width: 70,
                      height: 34,
                      backgroundColor: this.getBackgroundColor(
                        index,
                        persona.to_score,
                        number_of_quiz_personas
                      ),
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: 6,
                      borderBottomLeftRadius: 6,
                    }}
                  >
                    <Text style={{ fontSize: 11, color: "#fff" }}>
                      {index == 0
                        ? "<"
                        : Math.ceil(persona.from_score.toFixed(1)) + "%-"}
                      {Math.ceil(persona.to_score.toFixed(1)) + "%"}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}
      </View>
    );
  };

  _header() {
    return (
      <NavigationHeader
        navigation={this.props.navigation}
        navigationHeader={["Quiz Result"]}
        onPressBack={() => {
          this.props.navigation.goBack();
        }}
      />
    );
  }

  render() {
    const { quiz_id = null } = this.state;
    const { persona } = this.state.result;
    const descriptions =
      persona && persona.description
        ? persona.description.split("<br><br>")
        : [];

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff", maxWidth: 800 }}>
        {this._header()}
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <ScrollView
          style={{
            paddingHorizontal: 6,
          }}
          contentContainerStyle={{ flexGrow: 1, paddingTop: 50 }}
        >
          {persona && (
            <>
              <View style={{ flexDirection: "column", alignItems: "center" }}>
                <Text
                  style={{
                    fontWeight: "500",
                    color: "#121212",
                    fontSize: 45,
                    marginBottom: 20,
                  }}
                >
                  {Math.floor(persona.quiz_score || 0)}
                  <Text style={{ fontSize: 22, fontWeight: "400" }}> /100</Text>
                </Text>

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marignTop: 20,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      color: getStatusColor(
                        this.state.result.quiz_personas,
                        persona.persona_name
                      ),
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {persona.persona_name}
                  </Text>
                  <ANTIcon
                    hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                    name="infocirlceo"
                    color={"#410DAA"}
                    size={14}
                    style={{
                      paddingLeft: 10,
                    }}
                    onPress={() => {
                      this.toggleModal && this.toggleModal();
                    }}
                  />
                </View>
                <Text
                  style={{ fontSize: 12, color: "#BCBCBC", marginBottom: 50 }}
                >
                  Has the major risk areas covered
                </Text>

                <Text
                  style={{
                    fontSize: 17,
                    fontWeight: "bold",
                    color: "#121212",
                    marginBottom: 20,
                  }}
                >
                  {persona.quiztext}
                </Text>
                <View style={{ marginBottom: 37 }}>
                  {descriptions &&
                    descriptions.map((item) => {
                      return (
                        <Text
                          style={{
                            fontSize: 12,
                            color: "#707070",
                            paddingVertical: 5,
                            textAlign: "center",
                          }}
                        >
                          {item}
                        </Text>
                      );
                    })}
                </View>
                <TouchableOpacity
                  onPress={() => {
                    this.props.navigation.navigate("Quizz", { quiz_id });
                  }}
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#EFEFEF",
                    borderRadius: 10,
                    paddingHorizontal: 12,
                    paddingVertical: 4,
                    marginTop: 15,
                  }}
                >
                  <ANTIcon
                    name="reload1"
                    color={"#410DAA"}
                    size={14}
                    style={{}}
                  />
                  <Text
                    style={{ fontSize: 13, color: "#410DAA", paddingLeft: 10 }}
                  >
                    Revise Assessment
                  </Text>
                </TouchableOpacity>

                <CustButton
                  bgColor={"#fff"}
                  textColor={"#410DAA"}
                  style={{
                    width: "100%",
                    paddingVertical: 15,
                    marginBottom: 10,
                  }}
                  onPress={() => {
                    this.props.navigation.navigate("QuizDiag", { quiz_id });
                  }}
                  borderColor={"transparent"}
                  buttonTextStyle={{
                    fontSize: 14,
                  }}
                >
                  Diagnostic Report
                </CustButton>
                <View style={{ padding: 20 }}></View>
                <CustButton
                  bgColor={"#410DAA"}
                  textColor={"#fff"}
                  style={{
                    width: isMobile ? "100%" : "20%",

                    paddingVertical: 15,
                  }}
                  onPress={() => {
                    if (this.state.from == "fi") {
                      this.props.navigation.navigate("App", {
                        screen: "ViewMode",
                        params: {
                          screen: "FinAwareness",
                          params: {},
                        },
                      });
                    } else {
                      this.props.navigation.navigate("EmotionalWellness");
                    }
                  }}
                  borderColor={"transparent"}
                  buttonTextStyle={{
                    fontSize: 14,
                  }}
                >
                  Done
                </CustButton>
              </View>
              <View
                style={{ position: "absolute", bottom: 5, left: 0, right: 0 }}
              ></View>
              <CustomModal
                visible={this.state.showModal}
                showCloseButton={true}
                closeModal={() => {
                  this.toggleModal();
                }}
              >
                {this.renderModalContent()}
              </CustomModal>
            </>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}
