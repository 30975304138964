import React from "react";
import { StyleSheet, View, Image, Dimensions } from "react-native";
import { Text } from "react-native-paper";
import { sm, md, lg, xl, twoxl } from "../../Responsive";
import global from "./globalStyles";

const window = Dimensions.get("window");

const FeatureDesc = ({ description }) => {
    if (typeof description === "string") {
        return (
            <Text style={[styles.description, global.arial]}>{description}</Text>
        );
    } else if (typeof description === "object") {
        return (
            <View>
            {description.map((d, i) => {
                return (
                <View style={{ display: "flex", flexDirection: "row" }} key={i}>
                    <View style={styles.bullet}></View>
                    <Text style={[global.arial, styles.feature]}>{d}</Text>
                </View>
                );
            })}
            </View>
        );
    }
};

export default function PlanFeatureCard({ feature, id, length, planName }) {
    const { name, description } = feature;
    let src = require("../../assets/upgradePlan/Landing/check.png");
  
    let logo = null;
    switch (planName) {
        case "gold":
            {
            logo = require("../../assets/upgradePlan/Gold_Page/logo_ico_color.png");
            if (id === 1) {
                src = require("../../assets/upgradePlan/Gold_Page/Advice_on_Asset_Allocation.png");
            } else if (id == 2) {
                src = require("../../assets/upgradePlan/Gold_Page/Advice_on_Mutual_Fund_Selection.png");
            } else if (id == 3) {
                src = require("../../assets/upgradePlan/Gold_Page/Advice_on_Rebalancing.png");
            } else if (id == 4) {
                src = require("../../assets/upgradePlan/Gold_Page/Advice_on_Financial_Plan.png");
            } else if (id == 5) {
                src = require("../../assets/upgradePlan/Gold_Page/Advice_on_Insurance.png");
            }
            }
            break;
        case "platinum":
            {
            logo = require("../../assets/upgradePlan/Platinum_Page/logo_ico_color.png");
            if (id === 1) {
                src = require("../../assets/upgradePlan/Platinum_Page/Bespoke_Financial_Plan_with_a_certified_advisor.png");
            } else if (id == 2) {
                src = require("../../assets/upgradePlan/Platinum_Page/Quarterly_Advice_on_Fund_Performance.png");
            } else if (id == 3) {
                src = require("../../assets/upgradePlan/Platinum_Page/Analysis_of_Stock_portfolio.png");
            } else if (id == 4) {
                src = require("../../assets/upgradePlan/Platinum_Page/Advice_on_Expense_Planning.png");
            } else if (id == 5) {
                src = require("../../assets/upgradePlan/Platinum_Page/Advice_on_Insurance_Policies.png");
            } else if (id == 6) {
                src = require("../../assets/upgradePlan/Platinum_Page/Advice_on_Succession_Planning.png");
            } else if (id == 7) {
                src = require("../../assets/upgradePlan/Platinum_Page/Priority_Service_&_Preferred_Scheduling_of_Slots_with_Advisor.png");
            }
            }
            break;
        case "jewel":
            {
            logo = require("../../assets/upgradePlan/Jewel_Page/logo_ico_color.png");
            if (id === 1) {
                src = require("../../assets/upgradePlan/Jewel_Page/Advisory_That_Out-Performs.png");
            } else if (id == 2) {
                src = require("../../assets/upgradePlan/Jewel_Page/Advice_on_Equities_with_Strong_Roots.png");
            } else if (id == 3) {
                src = require("../../assets/upgradePlan/Jewel_Page/Advice_on_Equities_with_Powerful_Wings.png");
            } else if (id == 4) {
                src = require("../../assets/upgradePlan/Jewel_Page/India_Cleanest_&_Unbiased_Investing_Model.png");
            } else if (id == 5) {
                src = require("../../assets/upgradePlan/Jewel_Page/Powered_by_Best_in_Class_Technology.png");
            }
            }
            break;
        default:
            logo = require("../../assets/upgradePlan/Gold_Page/logo_ico_color.png");
            break;
    }
    return (
        <>
            <View
            style={[
                styles.container,
                {
                borderLeftWidth:
                    xl(window.width) && (id === 2 || (length === 7 && id === 5))
                    ? 1
                    : undefined,
                borderRightWidth:
                    xl(window.width) &&
                    (id === 2 ||
                    (length === 7 && id === 5) ||
                    (length === 5 && id === 4))
                    ? 1
                    : undefined
                // borderBottomWidth: ((id === length) || ((Platform.OS === 'web') && ((length === 5 && id>=4) ))) ? 0 : 1
                }
            ]}
            >
            <View style={{ display: "flex", flexDirection: "row" }}>
                <Image source={logo} style={styles.logo}></Image>
                <View style={{ flexShrink: 1, marginLeft: 10 }}>
                <Text style={styles.name}>{name}</Text>
                <FeatureDesc description={description}></FeatureDesc>
                {feature.sub && <Text style={styles.sub}>{feature.sub}</Text>}
                <Image source={src} style={styles.img}></Image>
                </View>
            </View>
            </View>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        maxWidth: xl(window.width) ? 320 : undefined,
        minWidth: xl(window.width) ? 200 : undefined,
        alignSelf: xl(window.width) ? "stretch" : "center",
        paddingLeft: xl(window.width) ? 40 : 45,
        paddingRight: xl(window.width) ? 40 : 45,
        marginTop: xl(window.width) ? 40 : 16,
        marginBottom: xl(window.width) ? 40 : 16,
        borderColor: "#CCC"
    },
    img: {
        width: 154,
        height: 109,
        resizeMode: "cover",
        alignSelf: "center",
        marginTop: 40
    },
    name: {
        fontSize: 20,
        fontWeight: "bold",
        lineHeight: 27,
        color: "black"
    },
    about: {
        fontSize: 13,
        marginTop: 5,
        opacity: 0.2,
        color: "black"
    },
    description: {
        fontSize: 13,
        lineHeight: 17.7,
        color: "black"
    },
    hr: {
        height: xl(window.width) ? undefined : 1,
        width: xl(window.width) ? 1 : undefined,
        marginTop: xl(window.width) ? undefined : 30
    },
    logo: {
        width: 14,
        height: 14
    },
    bullet: {
        backgroundColor: "#959CB6",
        padding: 2,
        borderRadius: 99,
        alignSelf: "center",
        marginRight: 10
    },
    sub: {
        fontSize: 12,
        fontStyle: "italic",
        opacity: 0.5,
        color: "black"
    },
    feature: {
        color: "black"
    }
});
  