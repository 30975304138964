import React from "react";
import { SafeAreaView, Platform } from "react-native";
import NavigationHeader from "../Common/NavigationHeader";
const WebView =
  Platform.OS == "web"
    ? require("react-native-web-webview").WebView
    : require("react-native-webview").WebView;

class InsightsWebView extends React.Component {
  render() {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "#fff",
        }}
      >
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={[""]}
        />
        {this.props.route.params.isvideo ? (
          this.props.route.params.url
        ) : (
          <WebView source={{ uri: this.props.route.params.url }} />
        )}
      </SafeAreaView>
    );
  }
}

export default InsightsWebView;
