import React from "react";
import { TextInput, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import { numberFormat } from "../../../utils";

export default class CommaTextField extends React.Component {
  constructor(props) {
    super(props);
    this.refInput = React.createRef();
  }

  render() {
    const {
      value,
      onValueChange,
      noEdit = true,
      editable = true,
      containerStyle,
      textStyle,
      placeHolder = null,
      onSubmitEditing,
      limit,
    } = this.props;

    return (
      <>
        <View
          style={[
            {
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: editable ? "white" : "#DCDCDC",
              borderColor: "#e7e7e7",
              borderWidth: 1,
              borderRadius: 5,
              width: 200,
              height: 40,
              marginHorizontal: 10,
              width: 100,
            },
            containerStyle,
          ]}
        >
          <Text
            style={{
              paddingHorizontal: 5,
              marginTop: 1,
              fontSize: 14,
              marginRight: -17,
              color: "#183443",
            }}
          >
            ₹
          </Text>
          <TextInput
            ref={this.refInput}
            editable={editable}
            placeholder={placeHolder}
            underlineColorAndroid="transparent"
            style={{
              color: "#183443",
              fontSize: 18,
              width: "100%",
              paddingVertical: 4,
              paddingLeft: 20,
              ...textStyle,
            }}
            onChangeText={(amount) => {
              let error = false;
              if (amount != "") {
                let removed_commas = amount.replace(/[,|-]/g, "");
                const parsedAmount = parseInt(removed_commas);
                if (isNaN(parsedAmount)) {
                  error = true;
                } else {
                  amount = parsedAmount;
                }
                
              } else {
                amount = "";
              }
              if (limit && amount.toString().length > limit) return;
              else onValueChange(amount, error);
            }}
            value={value != "" ? numberFormat(value) : ""}
            keyboardType="numeric"
            onSubmitEditing={() => {
              onSubmitEditing();
            }}
            returnKeyType="next"
          />
        </View>
        {noEdit && (
          <TouchableOpacity
            onPress={() => {
              this.refInput.current.focus();
            }}
          >
            <Text
              style={{
                color: "#410DAA",
                cursor: "pointer",
                fontSize: 14,
                textAlign: "center",
                textDecorationLine: "underline",
              }}
            >
              Edit
            </Text>
          </TouchableOpacity>
        )}
      </>
    );
  }
}
