import React from "react";
import { View, Text, StyleSheet, Dimensions } from "react-native";
import { isMobile } from "../../../utils";
const { width } = Dimensions.get("window");

const numberFormat = (x) => {
  return x && x.toString().split(".")[0].length > 3
    ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        x.toString().substring(x.toString().split(".")[0].length - 3)
    : x.toString();
};

const Asset = ({ allAssets }) => {
  let Equity = 0;
  let Debt = 0;
  let Gold = 0;
  let Insurance = 0;
  let RealEstate = 0;
  let Other = 0;

  allAssets.results.map((item) => {
    if (item.name == "Equity") {
      Equity = item.value_new;
    }
    if (item.name == "Debt") {
      Debt = item.value_new;
    }
    if (item.name == "Gold") {
      Gold = item.value_new;
    }
    if (item.name == "Insurance") {
      Insurance = item.value_new;
    }
    if (item.name == "Real Estate") {
      RealEstate = item.value_new;
    }
    if (item.name == "Other") {
      Other = item.value_new;
    }
  });

  return (
    <View
      style={{
        borderWidth: 0.5,
        borderColor: "#cef1e6",
        borderRadius: 20,
        width: width < 1200 ? "100%" : "65%",
      }}
    >
      <View style={{ flexDirection: isMobile ? "column" : "row" }}>
        <View
          style={{
            color: "#494B69",
            fontSize: 16,
            backgroundColor: "#f3fbf9",
            borderRadius: 20,
            height: 190,
            alignSelf: "flex-start",
            width: isMobile ? "100%" : 310,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingHorizontal: 20,
            }}
          >
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 25,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Latest Value
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 28,
                  fontWeight: "700",
                  color: "#464E5F",
                }}
              >
                ₹{numberFormat(allAssets.value_new)}
              </Text>
            </View>
          </View>
          <View
            style={{
              height: 0.5,
              marginTop: 20,
              backgroundColor: "#92929250",
              paddingHorizontal: 20,
              marginLeft: 20,
              marginRight: 20,
            }}
          />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingHorizontal: 20,
            }}
          >
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 25,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Initial Invested
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                }}
              >
                ₹{numberFormat(allAssets.invested_amount)}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            paddingTop: 20,
            paddingHorizontal: 20,
            paddingBottom: isMobile ? 10 : 0,
          }}
        >
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                }}
              >
                ₹{numberFormat(Equity)}
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Stocks
              </Text>
            </View>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 30,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                }}
              >
                ₹{numberFormat(RealEstate)}
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Property
              </Text>
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: "left",
                }}
              >
                ₹{numberFormat(Debt)}
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Bonds
              </Text>
            </View>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 30,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: "left",
                }}
              >
                ₹{numberFormat(Gold)}
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Gold
              </Text>
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 20,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: "left",
                }}
              >
                ₹{numberFormat(Insurance)}
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Insurance
              </Text>
            </View>

            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  paddingTop: 30,
                  fontSize: 14,
                  fontWeight: "700",
                  color: "#464E5F",
                  textAlign: "left",
                }}
              >
                ₹{numberFormat(Other)}
              </Text>
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#878EA8",
                }}
              >
                Other Assets
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export const AllAssetsCard = ({ style, allAssets }) => {
  return (
    <View style={[styles.container, { ...style }]}>
      <Asset allAssets={allAssets} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: isMobile ? 0 : 40,
    flexDirection: isMobile ? "column" : "row",
    paddingHorizontal: isMobile ? 0 : 0,
    marginRight: isMobile ? 0 : 0,
  },
  productContainer: {
    width: isMobile ? "100%" : "32.2%",
    borderColor: isMobile ? "transparent" : "#EEE5FF40",
    borderRadius: isMobile ? 0 : 18,
    paddingTop: 15,
    paddingBottom: isMobile ? 0 : 12,
    borderWidth: 1,
    marginBottom: isMobile ? 15 : 0,
  },
  volatalityContainer: {
    backgroundColor: "#FFF0E8",
    marginLeft: isMobile ? 9 : 15,
  },
  topContainer: {
    paddingHorizontal: 15,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: isMobile ? 15 : 16,
    color: "#464E5F",
    fontWeight: "bold",
    fontFamily: "Roboto",
    paddingTop: 3,
  },
  image: {
    width: 25,
    height: 24,
    resizeMode: "contain",
    marginRight: 6,
  },
  strategy: {
    fontSize: 13,
    color: "#888C9F",
    fontStyle: "italic",
    marginTop: 13,
  },
  activePlansInfoContainer: {
    marginTop: isMobile ? 20 : 10,
    backgroundColor: "#F6F6F660",
    paddingHorizontal: 15,
    paddingVertical: 6,
    flexDirection: "row",
  },
  infoContainer: {
    flex: 1,
    paddingVertical: 6,
    flexDirection: "row",
  },
  infoRow: {
    flex: 0.33,
    alignItems: "flex-start",
  },
  fieldContainer: {
    paddingVertical: 6,
  },
  marginTop: {
    marginTop: 16,
  },
  center: { alignItems: "center" },
  end: { alignItems: "flex-end" },
  infoFieldsContainer: {
    marginTop: 0,
    paddingTop: 4,
  },
  infoTitle: {
    fontSize: 12,
    color: "#6C7293",
    fontWeight: "600",
    marginBottom: 8,
  },
  infoField: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  infoValue: {
    fontSize: 12,
    color: "#464E5F",
    fontWeight: "700",
  },
  button: {
    alignSelf: "center",
  },
  buttonText: {
    fontSize: isMobile ? 13 : 15,
    color: "#410DAA",
    fontWeight: "bold",
    lineHeight: 23,
    textDecorationLine: isMobile ? "underline" : "none",
  },
  featureContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingHorizontal: 18,
    marginBottom: 12,
  },
  feature: {
    color: "#464E5F",
    fontFamily: "Roboto",
    fontSize: 13,
  },
  featureBullet: {
    color: "#410DAA",
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "bold",
    paddingRight: 5,
  },
  details: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  descriptionContainer: {
    marginTop: 12,
  },
});
