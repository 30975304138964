import React from "react";
import { Alert } from "react-native";
import amplitude from "../../utils/amplitude";
import {
  View,
  Text,
  Share,
  StyleSheet,
  Dimensions,
  ScrollView,
  Keyboard,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  TextInput as NativeTextInput,
  Platform,
} from "react-native";
import { md } from "../../Responsive";
import NavigationHeader from "../Common/NavigationHeader";
import { months_short_names } from "../../utils";
import { getInsightDetails, updateInsightDetails } from "../../api";
let Image_url = "https://jamaappprod.s3.amazonaws.com/media/";
import { getProductName, isMobile } from "../../utils";
const { width } = Dimensions.get("window");
const isWeb = md(width);

import moment from "moment";
import { TextInput } from "react-native-paper";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import Store from "../../store";

const ToggleActiveCompletePlans = ({
  tabs,
  activeIndex,
  onChangeValue,
  style = {},
}) => {
  return (
    <View
      style={{
        flexDirection: "row",
        borderBottomColor: "#E5EAEE",
        borderBottomWidth: 1,
        marginBottom: 10,
        paddingLeft: 32,
        ...style,
      }}
    >
      {tabs.map((tab, index) => {
        return (
          <TouchableOpacity
            onPress={() => {
              onChangeValue && onChangeValue(index);
            }}
          >
            <Text
              style={{
                color: index == activeIndex ? "#410DAA" : "#888C9F",
                fontSize: 14,
                paddingHorizontal: 30,
                paddingVertical: 12,
                borderBottomWidth: 3,
                fontWeight: index == activeIndex ? "700" : "400",
                borderBottomColor:
                  activeIndex == index ? "#410DAA" : "transparent",
              }}
            >
              {tab}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const shareForWeb = async (message) => {
  const eventProperties = {
    url: message.url,
  };
  amplitude.logEvent("Insight_Shared", eventProperties);
  await updateInsightDetails({
    insight_mapping_data: {
      contentinsight_id: message.contentinsight_id,
      action: "SHR",
      reply: message.url,
    },
  });
};

const WebShareOptions = ({ url }) => {
  return (
    <>
      <FacebookShareButton
        onClick={() => {
          shareForWeb(url);
        }}
        url={url.url}
      >
        <FontAwesome name="facebook" color={"#6b717f"} size={13} />
      </FacebookShareButton>
      <View style={{ marginLeft: 10 }}></View>
      <TwitterShareButton
        onClick={() => {
          shareForWeb(url);
        }}
        url={url.url}
      >
        <FontAwesome name="twitter" color={"#6b717f"} size={15} />
      </TwitterShareButton>
      <View style={{ marginLeft: 10 }}></View>
      <LinkedinShareButton
        onClick={() => {
          shareForWeb(url);
        }}
        url={url.url}
      >
        <FontAwesome name="linkedin" color={"#6b717f"} size={15} />
      </LinkedinShareButton>
      <View style={{ marginLeft: 10 }}></View>
    </>
  );
};

export default class InsightsHome extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    // console.log("line no 135................", params);
    let userId = null;
    let activeTab = null,
      activeTabIndex = 0;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      activeTab = params.activeTab || null;
      activeTabIndex = params.activeTabIndex || 0;
    }

    this.state = {
      userId,
      data: null,
      activeTabIndex,
      isLoading: true,
      filters: [],
      sipdata: [],
      tabsArray: [],
      activeTab,
      webPosts: [],
      webArticles: [],
      webVideos: [],
      webPostsData: [],
      webArticlesData: [],
      webVideosData: [],
      showComments: false,
      selectedItem: [],
      insightsCompleteData: [],
      commentsList: [],
      commentType: "Posts",
      comment: "",
      showError: false,
      statusArray: {
        Active: "ACT",
        Draft: "DFA",
        Archived: "ARC",
      },
      insightType: {
        Blog: "BLG",
        "Published Article": "ART",
        "Social Media Post": "PST",
        Video: "VID",
        Audio: "AUD",
        Podcasts: "POD",
      },
    };
  }

  componentWillReceiveProps = async () => {
    try {
      let getInsightsResp = await getInsightDetails({
        previous_key: "customerinsight",
      });

      let getInsightsCompleteResp = await getInsightDetails({
        previous_key: "customerinsightmappingdata",
      });
      let UpdatedInsightResp = getInsightsCompleteResp.result.filter(
        (item) => !(item.is_hidden == 1)
      );
      this.setState({ insightsCompleteData: UpdatedInsightResp });

      let totalLikesCount = UpdatedInsightResp.reduce(function (result, item) {
        if (item.action == "LIK") {
          result[item.contentinsight_id] =
            (result[item.contentinsight_id] || 0) + 1;
        }
        return result;
      }, {});

      let totalCommentsCount = UpdatedInsightResp.reduce(function (
        result,
        item
      ) {
        if (item.action == "COM") {
          result[item.contentinsight_id] =
            (result[item.contentinsight_id] || 0) + 1;
        }
        return result;
      },
      {});

      let userLikeCount = UpdatedInsightResp.reduce(function (result, item) {
        if (item.action == "LIK") {
          if (
            item.customer_id ==
            Store.getState().userData.userProfileData.customer_id
          ) {
            result[item.contentinsight_id] =
              (result[item.contentinsight_id] || 0) + 1;
          }
        }
        return result;
      }, {});

      let updatedInsightsResp = [];
      getInsightsResp.result.forEach((item) => {
        updatedInsightsResp.push({
          totalLikeCount: totalLikesCount[item.contentinsight_id]
            ? totalLikesCount[item.contentinsight_id]
            : 0,
          totalCommentsCount: totalCommentsCount[item.contentinsight_id]
            ? totalCommentsCount[item.contentinsight_id]
            : 0,
          userLikeCount: userLikeCount[item.contentinsight_id]
            ? userLikeCount[item.contentinsight_id]
            : 0,
          ...item,
        });
      });
      getInsightsResp.result = updatedInsightsResp;

      let webPostsData = [];
      let webArticlesData = [];
      let webVideosData = [];

      let webPosts = getInsightsResp.result.filter(
        (item) =>
          item.insight_type == "Social Media Post" && item.status == "Active"
      );

      let webArticles = getInsightsResp.result.filter(
        (item) =>
          item.insight_type == "Published Article" && item.status == "Active"
      );

      let webVideos = getInsightsResp.result.filter(
        (item) => item.insight_type == "Video" && item.status == "Active"
      );

      for (let i = 0; i < webPosts.length; i += 3) {
        webPostsData.push(webPosts.slice(i, i + 3));
      }

      for (let i = 0; i < webArticles.length; i += 3) {
        webArticlesData.push(webArticles.slice(i, i + 3));
      }

      for (let i = 0; i < webVideos.length; i += 3) {
        webVideosData.push(webVideos.slice(i, i + 3));
      }

      this.setState({
        webPostsData,
        webArticlesData,
        webVideosData,
        webPosts,
        webArticles,
        webVideos,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("insightsHome", { path: global.location.href });
      } else {
        amplitude.logEvent("insightsHome", {
          path: this.props.navigation.getState().routeName,
        });
      }
      const { params } = this.props.route;
      let activeTabIndex = 0;
      if (params) {
        activeTabIndex = params.activeTabIndex || 0;
      }
      const { userData } = Store.getState();

      if (userData.userProfileData.is_mfcustomer) {
        this.setState({
          tabsArray: ["Articles", "Videos", "Posts"],
          activeTabIndex,
        });
      } else {
        this.setState({
          tabsArray: ["Articles", "Videos", "Posts"],
          activeTabIndex,
        });
      }
      let { filters } = this.state;
      if (params && params.filter) {
        const value = params.filter;
        const title = getProductName(value);
        filters = [...filters, { filterBy: "product", value, title }];
      }

      const { userId } = this.state;

      let landingPageResponse = [];
      let getMySIPsResponse = [];

      let getInsightsResp = await getInsightDetails({
        previous_key: "customerinsight",
      });

      let getInsightsCompleteResp = await getInsightDetails({
        previous_key: "customerinsightmappingdata",
      });
      let UpdatedInsightResp = getInsightsCompleteResp.result.filter(
        (item) => !(item.is_hidden == 1)
      );
      this.setState({ insightsCompleteData: UpdatedInsightResp });

      let totalLikesCount = UpdatedInsightResp.reduce(function (result, item) {
        if (item.action == "LIK") {
          result[item.contentinsight_id] =
            (result[item.contentinsight_id] || 0) + 1;
        }
        return result;
      }, {});

      let totalCommentsCount = UpdatedInsightResp.reduce(function (
        result,
        item
      ) {
        if (item.action == "COM") {
          result[item.contentinsight_id] =
            (result[item.contentinsight_id] || 0) + 1;
        }
        return result;
      },
      {});

      let userLikeCount = UpdatedInsightResp.reduce(function (result, item) {
        if (item.action == "LIK") {
          if (
            item.customer_id ==
            Store.getState().userData.userProfileData.customer_id
          ) {
            result[item.contentinsight_id] =
              (result[item.contentinsight_id] || 0) + 1;
          }
        }
        return result;
      }, {});

      let updatedInsightsResp = [];
      getInsightsResp.result.forEach((item) => {
        updatedInsightsResp.push({
          totalLikeCount: totalLikesCount[item.contentinsight_id]
            ? totalLikesCount[item.contentinsight_id]
            : 0,
          totalCommentsCount: totalCommentsCount[item.contentinsight_id]
            ? totalCommentsCount[item.contentinsight_id]
            : 0,
          userLikeCount: userLikeCount[item.contentinsight_id]
            ? userLikeCount[item.contentinsight_id]
            : 0,
          ...item,
        });
      });
      getInsightsResp.result = updatedInsightsResp;

      let webPostsData = [];
      let webArticlesData = [];
      let webVideosData = [];

      let webPosts = getInsightsResp.result.filter(
        (item) =>
          item.insight_type == "Social Media Post" && item.status == "Active"
      );

      let webArticles = getInsightsResp.result.filter(
        (item) =>
          item.insight_type == "Published Article" && item.status == "Active"
      );

      let webVideos = getInsightsResp.result.filter(
        (item) => item.insight_type == "Video" && item.status == "Active"
      );

      for (let i = 0; i < webPosts.length; i += 3) {
        webPostsData.push(webPosts.slice(i, i + 3));
      }

      for (let i = 0; i < webArticles.length; i += 3) {
        webArticlesData.push(webArticles.slice(i, i + 3));
      }

      for (let i = 0; i < webVideos.length; i += 3) {
        webVideosData.push(webVideos.slice(i, i + 3));
      }

      this.setState({
        webPostsData,
        webArticlesData,
        webVideosData,
        webPosts,
        webArticles,
        webVideos,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  updateInsights = async (item, type, isSelected = false) => {
    if (type == "LIK") {
      const eventProperties = {
        url: item.url,
      };
      amplitude.logEvent("Insight_Liked", eventProperties);
    }

    if (type == "DIS") {
      const eventProperties = {
        url: item.url,
      };
      amplitude.logEvent("Insight_Disliked", eventProperties);
    }

    await updateInsightDetails({
      insight_mapping_data: {
        contentinsight_id: item.contentinsight_id,
        action: type,
        reply: "",
      },
    });

    let getInsightsResp = await getInsightDetails({
      previous_key: "customerinsight",
    });

    let getInsightsCompleteResp = await getInsightDetails({
      previous_key: "customerinsightmappingdata",
    });
    let UpdatedInsightResp = getInsightsCompleteResp.result.filter(
      (item) => !(item.is_hidden == 1)
    );
    this.setState({
      insightsCompleteData: UpdatedInsightResp,
    });

    let totalLikesCount = UpdatedInsightResp.reduce(function (result, item) {
      if (item.action == "LIK") {
        result[item.contentinsight_id] =
          (result[item.contentinsight_id] || 0) + 1;
      }
      return result;
    }, {});

    let totalCommentsCount = UpdatedInsightResp.reduce(function (result, item) {
      if (item.action == "COM") {
        result[item.contentinsight_id] =
          (result[item.contentinsight_id] || 0) + 1;
      }
      return result;
    }, {});

    let userLikeCount = UpdatedInsightResp.reduce(function (result, item) {
      if (item.action == "LIK") {
        if (
          item.customer_id ==
          Store.getState().userData.userProfileData.customer_id
        ) {
          result[item.contentinsight_id] =
            (result[item.contentinsight_id] || 0) + 1;
        }
      }
      return result;
    }, {});

    let updatedInsightsResp = [];
    getInsightsResp.result.forEach((item) => {
      updatedInsightsResp.push({
        totalLikeCount: totalLikesCount[item.contentinsight_id]
          ? totalLikesCount[item.contentinsight_id]
          : 0,
        totalCommentsCount: totalCommentsCount[item.contentinsight_id]
          ? totalCommentsCount[item.contentinsight_id]
          : 0,
        userLikeCount: userLikeCount[item.contentinsight_id]
          ? userLikeCount[item.contentinsight_id]
          : 0,
        ...item,
      });
    });
    getInsightsResp.result = updatedInsightsResp;

    let webPostsData = [];
    let webArticlesData = [];
    let webVideosData = [];

    let webPosts = getInsightsResp.result.filter(
      (item) =>
        item.insight_type == "Social Media Post" && item.status == "Active"
    );

    let webArticles = getInsightsResp.result.filter(
      (item) =>
        item.insight_type == "Published Article" && item.status == "Active"
    );

    let webVideos = getInsightsResp.result.filter(
      (item) => item.insight_type == "Video" && item.status == "Active"
    );

    for (let i = 0; i < webPosts.length; i += 3) {
      webPostsData.push(webPosts.slice(i, i + 3));
    }

    for (let i = 0; i < webArticles.length; i += 3) {
      webArticlesData.push(webArticles.slice(i, i + 3));
    }

    for (let i = 0; i < webVideos.length; i += 3) {
      webVideosData.push(webVideos.slice(i, i + 3));
    }

    if (isSelected) {
      let selectedItem = updatedInsightsResp.filter(
        (item) =>
          item.contentinsight_id == this.state.selectedItem.contentinsight_id
      );
      this.setState({ selectedItem: selectedItem[0] });
    }

    this.setState({
      webPostsData,
      webArticlesData,
      webVideosData,
      webPosts,
      webArticles,
      webVideos,
    });
  };

  onShare = async (message) => {
    try {
      const eventProperties = {
        url: message.url,
      };
      amplitude.logEvent("Insight_Shared", eventProperties);
      await updateInsightDetails({
        insight_mapping_data: {
          contentinsight_id: message.contentinsight_id,
          action: "SHR",
          reply: message.url,
        },
      });
      const result = await Share.share({ message: message.url });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
        } else {
        }
      } else if (result.action === Share.dismissedAction) {
      }
    } catch (error) {}
  };

  renderRow = (amc) => {
    let urlamc = "";
    urlamc = Image_url + amc.item.amc_logo;

    const sip_per_month = amc.item.amount;
    let next_due = amc.item.sip_next_due_date.split("-");
    const month = months_short_names(parseInt(next_due[1]));

    next_due = next_due[0] + "-" + month + "-" + next_due[2];

    const frequency =
      amc.item.bse_sip_frequency_type && amc.item.bse_sip_frequency_type != ""
        ? amc.item.bse_sip_frequency_type.split("ly")[0].toString()
        : "month";

    return (
      <TouchableOpacity
        onPress={() => {
          if (isMobile) {
            this.props.navigation.navigate("SIPDetails", {
              data: amc.item,
              amcfundscheme: amc.item.amcfundscheme,
              index: amc.index,
              amcfund_id: amc.item.amcfundscheme__AMCFund__amcfund_id,
            });
          } else {
            this.props.navigation.navigate("App", {
              screen: "Dashboard",
              params: {
                screen: "Portfolio",
                params: {
                  screen: "SIPDetails",
                  params: {
                    data: amc.item,
                    amcfundscheme: amc.item.amcfundscheme,
                    index: amc.index,
                    amcfund_id: amc.item.amcfundscheme__AMCFund__amcfund_id,
                  },
                },
              },
            });
          }
        }}
        style={{
          borderRadius: 10,
          borderColor: "#410DAA33",
          borderWidth: 1,
          marginVertical: 7.5,
          marginHorizontal: 15,
        }}
      >
        <View
          style={{
            paddingVertical: 12,
            paddingHorizontal: 10,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View
            style={{
              flex: 0.1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: 30,
                height: 30,
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#410DAA1A",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={{ uri: urlamc }}
                resizeMode="contain"
                style={{ height: 20, width: 20 }}
              />
            </View>
          </View>
          <View style={{ flex: 0.9 }}>
            <Text style={{ fontSize: 12, color: "#121212", paddingLeft: 10 }}>
              {amc.item.scheme_name}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: 10,
            paddingBottom: 7.5,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: "#121212",
              paddingRight: 5,
              fontWeight: "bold",
            }}
          >
            {sip_per_month}
          </Text>
          <Text style={{ fontSize: 10, color: "#121212", fontWeight: "bold" }}>
            / {frequency}
          </Text>
          <Text style={{ fontSize: 10, color: "#465964", marginLeft: 10 }}>
            Next Due:{next_due}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    const { activeTabIndex, error, errorText, isLoading } = this.state;

    if (error || isLoading) {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <View style={{ flex: 1, maxWidth: 1500 }}>
            {!isMobile && (
              <NavigationHeader
                navigation={this.props.navigation}
                navigationHeader={[
                  this.state.showComments ? "Insight Comments" : "Insights",
                ]}
                // onPressBack={() => {
                //   if (this.state.showComments) {
                //     this.setState({ showComments: false, comment: "" });
                //   } else {
                //     this.props.navigation.goBack();
                //   }
                // }}
                style={{ backgroundColor: "#F6F6F6" }}
                showBackIcon={false}
              />
            )}
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoading && (
                <ActivityIndicator size="large" color="#410DAA" style={{}} />
              )}
              {error && (
                <Text style={{ fontSize: 16, color: "#121212" }}>
                  {errorText || "Something went wrong!"}
                </Text>
              )}
            </View>
          </View>
        </View>
      );
    }
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
      >
        <View style={[{ backgroundColor: "#fff" }, styles.container]}>
          {!isMobile && (
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={[
                this.state.showComments ? "Insight Comments" : "Insights",
              ]}
              style={{ backgroundColor: "#F6F6F6" }}
              showBackIcon={false}
            />
          )}
          {this.state.showComments ? (
            <>
              <ScrollView
                keyboardShouldPersistTaps="always"
                style={[styles.mainContainer]}
                showsVerticalScrollIndicator={true}
              >
                {isMobile ? (
                  <>
                    <Text
                      style={{
                        fontSize: 14,
                        marginTop: 20,
                        width: isMobile ? "90%" : "100%",
                        marginLeft: isMobile ? "5%" : "0%",
                        marginRight: isMobile ? "5%" : "0%",
                        justifyContent: "flex-start",
                        textDecorationLine: "underline",
                        color: "#410DAA",
                        fontWeight: "bold",
                        fontSize: 14,
                        textAlign: "left",
                      }}
                      onPress={() => {
                        Keyboard.dismiss();
                        this.setState({ showComments: false, comment: "" });
                      }}
                    >
                      Back to Insights
                    </Text>
                    <View
                      style={{
                        marginTop: 20,
                        width: isMobile ? "90%" : "100%",
                        marginLeft: isMobile ? "5%" : "0%",
                        marginRight: isMobile ? "5%" : "0%",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        marginBottom: 10,
                      }}
                    >
                      {this.state.commentType == "Posts" && (
                        <View
                          style={{
                            height: 300,
                            width: "100%",
                            borderRadius: 10,
                            borderColor: "#d9cfee",
                            borderWidth: 1,
                          }}
                        >
                          <View style={{ height: 220 }}>
                            <View
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                paddingRight: 30,
                                paddingTop: 20,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "#464E5F",
                                  fontWeight: "600",
                                }}
                              >
                                {moment(
                                  this.state.selectedItem.published_datetime
                                ).format("hh:mm . DD MMM YY")}
                              </Text>
                            </View>
                            <TouchableOpacity
                              style={{
                                display: "flex",
                                width: "80%",
                                marginLeft: "10%",
                                marginTop: "7%",
                              }}
                              onPress={() => {
                                const eventProperties = {
                                  url: this.state.selectedItem.url,
                                };
                                amplitude.logEvent(
                                  "Insight_Viewed",
                                  eventProperties
                                );
                                if (isMobile) {
                                  this.props.navigation.navigate(
                                    "InsightsWebView",
                                    {
                                      url: this.state.selectedItem.url,
                                    }
                                  );
                                } else {
                                  global.open(
                                    this.state.selectedItem.url,
                                    "_blank"
                                  );
                                }
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 16,
                                  color: "#410DAA",
                                  fontWeight: "600",
                                }}
                              >
                                {this.state.selectedItem.insight_text}
                              </Text>
                            </TouchableOpacity>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              height: 30,
                            }}
                          >
                            <View
                              style={{
                                display: "flex",
                                width: "80%",
                                marginLeft: "10%",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "#464E5F",
                                  fontWeight: "600",
                                }}
                              >
                                # {this.state.selectedItem.authors}
                              </Text>
                            </View>
                          </View>
                          <View style={{ height: 50 }}>
                            <View
                              style={{
                                width: "100%",
                                height: 1,
                                backgroundColor: "#d9cfee",
                              }}
                            />
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              {this.state.selectedItem.userLikeCount < 1 ? (
                                <TouchableOpacity
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 15,
                                  }}
                                  onPress={() => {
                                    this.updateInsights(
                                      this.state.selectedItem,
                                      "LIK",
                                      true
                                    );
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                    }}
                                    style={{
                                      width: 16,
                                      height: 16,
                                      resizeMode: "contain",
                                      marginLeft: 20,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      color: "#6b717f",
                                      fontWeight: "600",
                                      marginTop: -2,
                                    }}
                                  >
                                    {" "}
                                    {this.state.selectedItem.totalLikeCount >
                                      0 &&
                                      this.state.selectedItem.totalLikeCount}
                                  </Text>
                                </TouchableOpacity>
                              ) : (
                                <TouchableOpacity
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 15,
                                  }}
                                  onPress={() => {
                                    this.updateInsights(
                                      this.state.selectedItem,
                                      "DIS",
                                      true
                                    );
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                    }}
                                    style={{
                                      width: 16,
                                      height: 16,
                                      resizeMode: "contain",
                                      marginLeft: 20,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      color: "#6b717f",
                                      fontWeight: "600",
                                      marginTop: -2,
                                    }}
                                  >
                                    {" "}
                                    {this.state.selectedItem.totalLikeCount >
                                      0 &&
                                      this.state.selectedItem.totalLikeCount}
                                  </Text>
                                </TouchableOpacity>
                              )}
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  marginTop: 15,
                                }}
                                onPress={() => {
                                  this.setState({
                                    showComments: true,
                                    selectedItem: item,
                                  });
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#6b717f",
                                    fontWeight: "600",
                                    marginTop: -2,
                                  }}
                                ></Text>
                              </TouchableOpacity>
                              {isMobile ? (
                                <TouchableOpacity
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 15,
                                  }}
                                  onPress={() =>
                                    this.onShare(this.state.selectedItem)
                                  }
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                    }}
                                    style={{
                                      width: 16,
                                      height: 16,
                                      resizeMode: "contain",
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      color: "#6b717f",
                                      fontWeight: "600",
                                      marginRight: 20,
                                      marginTop: -2,
                                    }}
                                  ></Text>
                                </TouchableOpacity>
                              ) : (
                                <View
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 12,
                                  }}
                                >
                                  <WebShareOptions
                                    url={this.state.selectedItem}
                                  />
                                </View>
                              )}
                            </View>
                          </View>
                          <View></View>
                        </View>
                      )}

                      {this.state.commentType == "Articles" && (
                        <View
                          style={{
                            height: 400,
                            width: "100%",
                            borderRadius: 10,
                            borderColor: "#d9cfee",
                            borderWidth: 1,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              const eventProperties = {
                                url: this.state.selectedItem.url,
                              };
                              amplitude.logEvent(
                                "Insight_Viewed",
                                eventProperties
                              );
                              if (isMobile) {
                                this.props.navigation.navigate(
                                  "InsightsWebView",
                                  {
                                    url: this.state.selectedItem.url,
                                  }
                                );
                              } else {
                                global.open(
                                  this.state.selectedItem.url,
                                  "_blank"
                                );
                              }
                            }}
                          >
                            <Image
                              source={{
                                uri: this.state.selectedItem.image_url,
                              }}
                              style={{
                                width: "100%",
                                height: 250,
                                resizeMode: "stretch",
                                borderTopLeftRadius: 9,
                                borderTopRightRadius: 9,
                              }}
                            />
                            <View
                              style={{
                                display: "flex",
                                width: "80%",
                                marginLeft: "10%",
                                marginTop: 10,
                                height: 60,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "#410DAA",
                                  fontWeight: "600",
                                }}
                              >
                                {this.state.selectedItem.insight_text}
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <View
                            style={{
                              display: "flex",
                              height: 30,
                            }}
                          >
                            <View
                              style={{
                                display: "flex",
                                width: "80%",
                                marginLeft: "10%",
                                flexDirection: "row",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: "#7e838f",
                                  fontStyle: "italic",
                                }}
                              >
                                {moment(
                                  this.state.selectedItem.published_datetime
                                ).format("hh:mm . DD MMM YY")}
                              </Text>
                            </View>
                          </View>
                          <View style={{ height: 50 }}>
                            <View
                              style={{
                                width: "100%",
                                height: 1,
                                backgroundColor: "#d9cfee",
                              }}
                            />
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              {this.state.selectedItem.userLikeCount < 1 ? (
                                <TouchableOpacity
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 15,
                                  }}
                                  onPress={() => {
                                    this.updateInsights(
                                      this.state.selectedItem,
                                      "LIK",
                                      true
                                    );
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                    }}
                                    style={{
                                      width: 16,
                                      height: 16,
                                      resizeMode: "contain",
                                      marginLeft: 20,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      color: "#6b717f",
                                      fontWeight: "600",
                                      marginTop: -2,
                                    }}
                                  >
                                    {" "}
                                    {this.state.selectedItem.totalLikeCount >
                                      0 &&
                                      this.state.selectedItem.totalLikeCount}
                                  </Text>
                                </TouchableOpacity>
                              ) : (
                                <TouchableOpacity
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 15,
                                  }}
                                  onPress={() => {
                                    this.updateInsights(
                                      this.state.selectedItem,
                                      "DIS",
                                      true
                                    );
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                    }}
                                    style={{
                                      width: 16,
                                      height: 16,
                                      resizeMode: "contain",
                                      marginLeft: 20,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      color: "#6b717f",
                                      fontWeight: "600",
                                      marginTop: -2,
                                    }}
                                  >
                                    {" "}
                                    {this.state.selectedItem.totalLikeCount >
                                      0 &&
                                      this.state.selectedItem.totalLikeCount}
                                  </Text>
                                </TouchableOpacity>
                              )}
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginTop: 15,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#6b717f",
                                    fontWeight: "600",
                                    marginTop: -2,
                                  }}
                                ></Text>
                              </View>
                              {isMobile ? (
                                <TouchableOpacity
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 15,
                                  }}
                                  onPress={() =>
                                    this.onShare(this.state.selectedItem)
                                  }
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                    }}
                                    style={{
                                      width: 16,
                                      height: 16,
                                      resizeMode: "contain",
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      color: "#6b717f",
                                      fontWeight: "600",
                                      marginRight: 20,
                                      marginTop: -2,
                                    }}
                                  ></Text>
                                </TouchableOpacity>
                              ) : (
                                <View
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 12,
                                  }}
                                >
                                  <WebShareOptions
                                    url={this.state.selectedItem}
                                  />
                                </View>
                              )}
                            </View>
                          </View>
                        </View>
                      )}

                      {this.state.commentType == "Videos" && (
                        <View
                          style={{
                            height: 400,
                            width: "100%",
                            borderRadius: 10,
                            borderColor: "#d9cfee",
                            borderWidth: 1,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              const eventProperties = {
                                url: this.state.selectedItem.url,
                              };
                              amplitude.logEvent(
                                "Insight_Viewed",
                                eventProperties
                              );
                              if (isMobile) {
                                this.props.navigation.navigate(
                                  "InsightsWebView",
                                  {
                                    url: this.state.selectedItem.url,
                                  }
                                );
                              } else {
                                global.open(
                                  this.state.selectedItem.url,
                                  "_blank"
                                );
                              }
                            }}
                          >
                            <Image
                              source={{
                                uri: this.state.selectedItem.image_url,
                              }}
                              style={{
                                width: "100%",
                                height: 250,
                                resizeMode: "stretch",
                                borderTopLeftRadius: 9,
                                borderTopRightRadius: 9,
                              }}
                            />
                            <View
                              style={{
                                display: "flex",
                                width: "80%",
                                marginLeft: "10%",
                                marginTop: 10,
                                height: 60,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "#410DAA",
                                  fontWeight: "600",
                                }}
                              >
                                {this.state.selectedItem.insight_text}
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <View
                            style={{
                              display: "flex",
                              height: 30,
                            }}
                          >
                            <View
                              style={{
                                display: "flex",
                                width: "80%",
                                marginLeft: "10%",
                                flexDirection: "row",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: "#7e838f",
                                  fontStyle: "italic",
                                }}
                              >
                                {moment(
                                  this.state.selectedItem.published_datetime
                                ).format("hh:mm . DD MMM YY")}
                              </Text>
                            </View>
                          </View>
                          <View style={{ height: 50 }}>
                            <View
                              style={{
                                width: "100%",
                                height: 1,
                                backgroundColor: "#d9cfee",
                              }}
                            />
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              {this.state.selectedItem.userLikeCount < 1 ? (
                                <TouchableOpacity
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 15,
                                  }}
                                  onPress={() => {
                                    this.updateInsights(
                                      this.state.selectedItem,
                                      "LIK",
                                      true
                                    );
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                    }}
                                    style={{
                                      width: 16,
                                      height: 16,
                                      resizeMode: "contain",
                                      marginLeft: 20,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      color: "#6b717f",
                                      fontWeight: "600",
                                      marginTop: -2,
                                    }}
                                  >
                                    {" "}
                                    {this.state.selectedItem.totalLikeCount >
                                      0 &&
                                      this.state.selectedItem.totalLikeCount}
                                  </Text>
                                </TouchableOpacity>
                              ) : (
                                <TouchableOpacity
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 15,
                                  }}
                                  onPress={() => {
                                    this.updateInsights(
                                      this.state.selectedItem,
                                      "DIS",
                                      true
                                    );
                                  }}
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                    }}
                                    style={{
                                      width: 16,
                                      height: 16,
                                      resizeMode: "contain",
                                      marginLeft: 20,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      color: "#6b717f",
                                      fontWeight: "600",
                                      marginTop: -2,
                                    }}
                                  >
                                    {" "}
                                    {this.state.selectedItem.totalLikeCount >
                                      0 &&
                                      this.state.selectedItem.totalLikeCount}
                                  </Text>
                                </TouchableOpacity>
                              )}
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginTop: 15,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#6b717f",
                                    fontWeight: "600",
                                    marginTop: -2,
                                  }}
                                ></Text>
                              </View>
                              {isMobile ? (
                                <TouchableOpacity
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 15,
                                  }}
                                  onPress={() =>
                                    this.onShare(this.state.selectedItem)
                                  }
                                >
                                  <Image
                                    source={{
                                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                    }}
                                    style={{
                                      width: 16,
                                      height: 16,
                                      resizeMode: "contain",
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      color: "#6b717f",
                                      fontWeight: "600",
                                      marginRight: 20,
                                      marginTop: -2,
                                    }}
                                  ></Text>
                                </TouchableOpacity>
                              ) : (
                                <View
                                  style={{
                                    flexDirection: "row",
                                    marginTop: 12,
                                  }}
                                >
                                  <WebShareOptions
                                    url={this.state.selectedItem}
                                  />
                                </View>
                              )}
                            </View>
                          </View>
                        </View>
                      )}
                    </View>
                    <View
                      style={{
                        marginTop: 20,
                        width: isMobile ? "90%" : "100%",
                        marginLeft: isMobile ? "5%" : "0%",
                        marginRight: isMobile ? "5%" : "0%",
                        justifyContent: "flex-start",
                        marginBottom: 100,
                      }}
                    >
                      <TextInput
                        value={this.state.comment}
                        autoFocus={true}
                        placeholder="Add Comment"
                        mode="outlined"
                        multiline
                        numberOfLines={5}
                        onChangeText={(value) =>
                          this.setState({ comment: value })
                        }
                        style={{
                          width: "100%",
                          height: 90,
                          fontFamily: "Roboto",
                          fontSize: 14,
                        }}
                        theme={{
                          roundness: 5,
                          colors: {
                            primary: "#c4c4c4",
                            background: "white",
                          },
                        }}
                        render={(innerProps) => {
                          return (
                            <NativeTextInput
                              {...innerProps}
                              editable={true}
                              style={[
                                innerProps.style,
                                {
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  height: 90,
                                },
                              ]}
                            />
                          );
                        }}
                      />
                      {this.state.showError && (
                        <Text style={{ color: "red", alignSelf: "center" }}>
                          Comment field cannot be empty
                        </Text>
                      )}
                      <TouchableOpacity
                        onPress={async () => {
                          if (this.state.comment.trim() === "") {
                            this.setState({ showError: true });
                            return;
                          }
                          this.setState({ showError: false });
                          Keyboard.dismiss();
                          const eventProperties = {
                            url: this.state.selectedItem.url,
                            comment: this.state.comment,
                          };
                          amplitude.logEvent(
                            "Insight_Comment_Added",
                            eventProperties
                          );
                          await updateInsightDetails({
                            insight_mapping_data: {
                              contentinsight_id:
                                this.state.selectedItem.contentinsight_id,
                              action: "COM",
                              reply: this.state.comment,
                            },
                          });
                          this.setState({ comment: "" });

                          let getInsightsResp = await getInsightDetails({
                            previous_key: "customerinsight",
                          });

                          let getInsightsCompleteResp = await getInsightDetails(
                            {
                              previous_key: "customerinsightmappingdata",
                            }
                          );
                          let UpdatedInsightResp =
                            getInsightsCompleteResp.result.filter(
                              (item) => !(item.is_hidden == 1)
                            );
                          this.setState({
                            insightsCompleteData: UpdatedInsightResp,
                          });

                          let totalLikesCount = UpdatedInsightResp.reduce(
                            function (result, item) {
                              if (item.action == "LIK") {
                                result[item.contentinsight_id] =
                                  (result[item.contentinsight_id] || 0) + 1;
                              }
                              return result;
                            },
                            {}
                          );

                          let totalCommentsCount = UpdatedInsightResp.reduce(
                            function (result, item) {
                              if (item.action == "COM") {
                                result[item.contentinsight_id] =
                                  (result[item.contentinsight_id] || 0) + 1;
                              }
                              return result;
                            },
                            {}
                          );

                          let userLikeCount = UpdatedInsightResp.reduce(
                            function (result, item) {
                              if (item.action == "LIK") {
                                if (
                                  item.customer_id ==
                                  Store.getState().userData.userProfileData
                                    .customer_id
                                ) {
                                  result[item.contentinsight_id] =
                                    (result[item.contentinsight_id] || 0) + 1;
                                }
                              }
                              return result;
                            },
                            {}
                          );

                          let updatedInsightsResp = [];
                          getInsightsResp.result.forEach((item) => {
                            updatedInsightsResp.push({
                              totalLikeCount: totalLikesCount[
                                item.contentinsight_id
                              ]
                                ? totalLikesCount[item.contentinsight_id]
                                : 0,
                              totalCommentsCount: totalCommentsCount[
                                item.contentinsight_id
                              ]
                                ? totalCommentsCount[item.contentinsight_id]
                                : 0,
                              userLikeCount: userLikeCount[
                                item.contentinsight_id
                              ]
                                ? userLikeCount[item.contentinsight_id]
                                : 0,
                              ...item,
                            });
                          });
                          getInsightsResp.result = updatedInsightsResp;

                          let webPostsData = [];
                          let webArticlesData = [];
                          let webVideosData = [];

                          let webPosts = getInsightsResp.result.filter(
                            (item) =>
                              item.insight_type == "Social Media Post" &&
                              item.status == "Active"
                          );

                          let webArticles = getInsightsResp.result.filter(
                            (item) =>
                              item.insight_type == "Published Article" &&
                              item.status == "Active"
                          );

                          let webVideos = getInsightsResp.result.filter(
                            (item) =>
                              item.insight_type == "Video" &&
                              item.status == "Active"
                          );

                          for (let i = 0; i < webPosts.length; i += 3) {
                            webPostsData.push(webPosts.slice(i, i + 3));
                          }

                          for (let i = 0; i < webArticles.length; i += 3) {
                            webArticlesData.push(webArticles.slice(i, i + 3));
                          }

                          for (let i = 0; i < webVideos.length; i += 3) {
                            webVideosData.push(webVideos.slice(i, i + 3));
                          }

                          this.setState({
                            webPostsData,
                            webArticlesData,
                            webVideosData,
                            webPosts,
                            webArticles,
                            webVideos,
                          });

                          let commentsList = [];
                          UpdatedInsightResp.filter((comment) => {
                            if (
                              comment.contentinsight_id ==
                                this.state.selectedItem.contentinsight_id &&
                              comment.reply &&
                              comment.action == "COM"
                            ) {
                              commentsList.push(comment);
                            }
                          });

                          this.setState({
                            commentsList: commentsList.reverse(),
                          });
                        }}
                        style={{
                          alignItems: "flex-end",
                          paddingTop: 20,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "bold",
                            lineHeight: 17,
                            fontSize: 15,
                            color: "#ffffff",
                            paddingVertical: 12,
                            paddingHorizontal: 25,
                            backgroundColor: "#410DAA",
                            borderRadius: 4,
                          }}
                        >
                          Add Comment
                        </Text>
                      </TouchableOpacity>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#464E5F",
                          fontWeight: "600",
                          textDecorationLine: "underline",
                        }}
                      >
                        {this.state.commentsList.length
                          ? "Comments " +
                            "(" +
                            this.state.commentsList.length +
                            ")"
                          : ""}
                      </Text>
                      {this.state.commentsList.map((item) => {
                        return (
                          <>
                            <Text
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontWeight: "600",
                                marginTop: 20,
                              }}
                            >
                              {item.first_name}{" "}
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontWeight: "400",
                                  fontStyle: "italic",
                                  marginTop: 5,
                                }}
                              >
                                {moment(item.modified).format(
                                  "hh:mm . DD MMM YY"
                                )}{" "}
                              </Text>
                            </Text>
                            <Text
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontWeight: "400",
                                marginTop: 5,
                              }}
                            >
                              {item.reply}
                            </Text>
                          </>
                        );
                      })}
                    </View>
                  </>
                ) : (
                  <View
                    style={{
                      width: isMobile ? "90%" : "100%",
                      marginLeft: isMobile ? "5%" : "0%",
                      marginRight: isMobile ? "5%" : "0%",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      marginBottom: 60,
                    }}
                  >
                    {this.state.commentType == "Posts" && (
                      <View
                        style={{
                          height: 300,
                          width: "31%",
                          borderRadius: 10,
                          borderColor: "#d9cfee",
                          borderWidth: 1,
                        }}
                      >
                        <View style={{ height: 220 }}>
                          <View
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              paddingRight: 30,
                              paddingTop: 20,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 14,
                                color: "#464E5F",
                                fontWeight: "600",
                              }}
                            >
                              {moment(
                                this.state.selectedItem.published_datetime
                              ).format("hh:mm . DD MMM YY")}
                            </Text>
                          </View>
                          <TouchableOpacity
                            style={{
                              display: "flex",
                              width: "80%",
                              marginLeft: "10%",
                              marginTop: "7%",
                            }}
                            onPress={() => {
                              const eventProperties = {
                                url: this.state.selectedItem.url,
                              };
                              amplitude.logEvent(
                                "Insight_Viewed",
                                eventProperties
                              );
                              if (isMobile) {
                                this.props.navigation.navigate(
                                  "InsightsWebView",
                                  {
                                    url: this.state.selectedItem.url,
                                  }
                                );
                              } else {
                                global.open(
                                  this.state.selectedItem.url,
                                  "_blank"
                                );
                              }
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#410DAA",
                                fontWeight: "600",
                              }}
                            >
                              {this.state.selectedItem.insight_text}
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            height: 30,
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              width: "80%",
                              marginLeft: "10%",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 14,
                                color: "#464E5F",
                                fontWeight: "600",
                              }}
                            >
                              # {this.state.selectedItem.authors}
                            </Text>
                          </View>
                        </View>
                        <View style={{ height: 50 }}>
                          <View
                            style={{
                              width: "100%",
                              height: 1,
                              backgroundColor: "#d9cfee",
                            }}
                          />
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            {this.state.selectedItem.userLikeCount < 1 ? (
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  marginTop: 15,
                                }}
                                onPress={() => {
                                  this.updateInsights(
                                    this.state.selectedItem,
                                    "LIK",
                                    true
                                  );
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                  }}
                                  style={{
                                    width: 16,
                                    height: 16,
                                    resizeMode: "contain",
                                    marginLeft: 20,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#6b717f",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  {this.state.selectedItem.totalLikeCount > 0 &&
                                    this.state.selectedItem.totalLikeCount}
                                </Text>
                              </TouchableOpacity>
                            ) : (
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  marginTop: 15,
                                }}
                                onPress={() => {
                                  this.updateInsights(
                                    this.state.selectedItem,
                                    "DIS",
                                    true
                                  );
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                  }}
                                  style={{
                                    width: 16,
                                    height: 16,
                                    resizeMode: "contain",
                                    marginLeft: 20,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#6b717f",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  {this.state.selectedItem.totalLikeCount > 0 &&
                                    this.state.selectedItem.totalLikeCount}
                                </Text>
                              </TouchableOpacity>
                            )}
                            <View
                              style={{
                                flexDirection: "row",
                                marginTop: 15,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: "#6b717f",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                              </Text>
                            </View>
                            {isMobile ? (
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  marginTop: 15,
                                }}
                                onPress={() =>
                                  this.onShare(this.state.selectedItem)
                                }
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                  }}
                                  style={{
                                    width: 16,
                                    height: 16,
                                    resizeMode: "contain",
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#6b717f",
                                    fontWeight: "600",
                                    marginRight: 20,
                                    marginTop: -2,
                                  }}
                                ></Text>
                              </TouchableOpacity>
                            ) : (
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginTop: 12,
                                }}
                              >
                                <WebShareOptions
                                  url={this.state.selectedItem}
                                />
                              </View>
                            )}
                          </View>
                        </View>
                        <View></View>
                      </View>
                    )}
                    {this.state.commentType == "Articles" && (
                      <View
                        style={{
                          height: 400,
                          width: "31%",
                          borderRadius: 10,
                          borderColor: "#d9cfee",
                          borderWidth: 1,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            const eventProperties = {
                              url: this.state.selectedItem.url,
                            };
                            amplitude.logEvent(
                              "Insight_Viewed",
                              eventProperties
                            );
                            if (isMobile) {
                              this.props.navigation.navigate(
                                "InsightsWebView",
                                {
                                  url: this.state.selectedItem.url,
                                }
                              );
                            } else {
                              global.open(
                                this.state.selectedItem.url,
                                "_blank"
                              );
                            }
                          }}
                        >
                          <Image
                            source={{
                              uri: this.state.selectedItem.image_url,
                            }}
                            style={{
                              width: "100%",
                              height: 250,
                              resizeMode: "stretch",
                              borderTopLeftRadius: 9,
                              borderTopRightRadius: 9,
                            }}
                          />
                          <View
                            style={{
                              display: "flex",
                              width: "80%",
                              marginLeft: "10%",
                              marginTop: 10,
                              height: 60,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 14,
                                color: "#410DAA",
                                fontWeight: "600",
                              }}
                            >
                              {this.state.selectedItem.insight_text}
                            </Text>
                          </View>
                        </TouchableOpacity>
                        <View
                          style={{
                            display: "flex",
                            height: 30,
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              width: "80%",
                              marginLeft: "10%",
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 13,
                                color: "#7e838f",
                                fontStyle: "italic",
                              }}
                            >
                              {moment(
                                this.state.selectedItem.published_datetime
                              ).format("hh:mm . DD MMM YY")}
                            </Text>
                          </View>
                        </View>
                        <View style={{ height: 50 }}>
                          <View
                            style={{
                              width: "100%",
                              height: 1,
                              backgroundColor: "#d9cfee",
                            }}
                          />
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            {this.state.selectedItem.userLikeCount < 1 ? (
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  marginTop: 15,
                                }}
                                onPress={() => {
                                  this.updateInsights(
                                    this.state.selectedItem,
                                    "LIK",
                                    true
                                  );
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                  }}
                                  style={{
                                    width: 16,
                                    height: 16,
                                    resizeMode: "contain",
                                    marginLeft: 20,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#6b717f",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  {this.state.selectedItem.totalLikeCount > 0 &&
                                    this.state.selectedItem.totalLikeCount}
                                </Text>
                              </TouchableOpacity>
                            ) : (
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  marginTop: 15,
                                }}
                                onPress={() => {
                                  this.updateInsights(
                                    this.state.selectedItem,
                                    "DIS",
                                    true
                                  );
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                  }}
                                  style={{
                                    width: 16,
                                    height: 16,
                                    resizeMode: "contain",
                                    marginLeft: 20,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#6b717f",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  {this.state.selectedItem.totalLikeCount > 0 &&
                                    this.state.selectedItem.totalLikeCount}
                                </Text>
                              </TouchableOpacity>
                            )}
                            <View
                              style={{
                                flexDirection: "row",
                                marginTop: 15,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: "#6b717f",
                                  fontWeight: "600",
                                }}
                              ></Text>
                            </View>
                            {isMobile ? (
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  marginTop: 15,
                                }}
                                onPress={() =>
                                  this.onShare(this.state.selectedItem)
                                }
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                  }}
                                  style={{
                                    width: 16,
                                    height: 16,
                                    resizeMode: "contain",
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#6b717f",
                                    fontWeight: "600",
                                    marginRight: 20,
                                    marginTop: -2,
                                  }}
                                ></Text>
                              </TouchableOpacity>
                            ) : (
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginTop: 12,
                                }}
                              >
                                <WebShareOptions
                                  url={this.state.selectedItem}
                                />
                              </View>
                            )}
                          </View>
                        </View>
                      </View>
                    )}
                    {this.state.commentType == "Videos" && (
                      <View
                        style={{
                          height: 400,
                          width: "31%",
                          borderRadius: 10,
                          borderColor: "#d9cfee",
                          borderWidth: 1,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            const eventProperties = {
                              url: this.state.selectedItem.url,
                            };
                            amplitude.logEvent(
                              "Insight_Viewed",
                              eventProperties
                            );
                            if (isMobile) {
                              this.props.navigation.navigate(
                                "InsightsWebView",
                                {
                                  url: (
                                    <iframe
                                      width="100%"
                                      height="100%"
                                      src={this.state.selectedItem.url}
                                      title="YouTube video player"
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                      allowfullscreen
                                    ></iframe>
                                  ),
                                  isvideo: true,
                                }
                              );
                            } else {
                              global.open(
                                this.state.selectedItem.url,
                                "_blank"
                              );
                            }
                          }}
                        >
                          <Image
                            source={{
                              uri: this.state.selectedItem.image_url,
                            }}
                            style={{
                              width: "100%",
                              height: 250,
                              resizeMode: "stretch",
                              borderTopLeftRadius: 9,
                              borderTopRightRadius: 9,
                            }}
                          />
                          <View
                            style={{
                              display: "flex",
                              width: "80%",
                              marginLeft: "10%",
                              marginTop: 10,
                              height: 60,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 14,
                                color: "#410DAA",
                                fontWeight: "600",
                              }}
                            >
                              {this.state.selectedItem.insight_text}
                            </Text>
                          </View>
                        </TouchableOpacity>
                        <View
                          style={{
                            display: "flex",
                            height: 30,
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              width: "80%",
                              marginLeft: "10%",
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 13,
                                color: "#7e838f",
                                fontStyle: "italic",
                              }}
                            >
                              {moment(
                                this.state.selectedItem.published_datetime
                              ).format("hh:mm . DD MMM YY")}
                            </Text>
                          </View>
                        </View>
                        <View style={{ height: 50 }}>
                          <View
                            style={{
                              width: "100%",
                              height: 1,
                              backgroundColor: "#d9cfee",
                            }}
                          />
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            {this.state.selectedItem.userLikeCount < 1 ? (
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  marginTop: 15,
                                }}
                                onPress={() => {
                                  this.updateInsights(
                                    this.state.selectedItem,
                                    "LIK",
                                    true
                                  );
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                  }}
                                  style={{
                                    width: 16,
                                    height: 16,
                                    resizeMode: "contain",
                                    marginLeft: 20,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#6b717f",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  {this.state.selectedItem.totalLikeCount > 0 &&
                                    this.state.selectedItem.totalLikeCount}
                                </Text>
                              </TouchableOpacity>
                            ) : (
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  marginTop: 15,
                                }}
                                onPress={() => {
                                  this.updateInsights(
                                    this.state.selectedItem,
                                    "DIS",
                                    true
                                  );
                                }}
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                  }}
                                  style={{
                                    width: 16,
                                    height: 16,
                                    resizeMode: "contain",
                                    marginLeft: 20,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#6b717f",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  {this.state.selectedItem.totalLikeCount > 0 &&
                                    this.state.selectedItem.totalLikeCount}
                                </Text>
                              </TouchableOpacity>
                            )}
                            <View
                              style={{
                                flexDirection: "row",
                                marginTop: 15,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: "#6b717f",
                                  fontWeight: "600",
                                }}
                              ></Text>
                            </View>
                            {isMobile ? (
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  marginTop: 15,
                                }}
                                onPress={() =>
                                  this.onShare(this.state.selectedItem)
                                }
                              >
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                  }}
                                  style={{
                                    width: 16,
                                    height: 16,
                                    resizeMode: "contain",
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 13,
                                    color: "#6b717f",
                                    fontWeight: "600",
                                    marginRight: 20,
                                    marginTop: -2,
                                  }}
                                ></Text>
                              </TouchableOpacity>
                            ) : (
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginTop: 12,
                                }}
                              >
                                <WebShareOptions
                                  url={this.state.selectedItem}
                                />
                              </View>
                            )}
                          </View>
                        </View>
                      </View>
                    )}

                    <View
                      style={{
                        height: 300,
                        width: "6%",
                      }}
                    ></View>
                    <View
                      style={{
                        width: "62%",
                        marginTop: -6,
                        height: "auto",
                      }}
                    >
                      <TextInput
                        value={this.state.comment}
                        autoFocus={true}
                        placeholder="Add Comment"
                        mode="outlined"
                        multiline
                        numberOfLines={5}
                        onChangeText={(value) =>
                          this.setState({ comment: value })
                        }
                        style={{
                          width: "100%",
                          height: 90,
                          fontFamily: "Roboto",
                          fontSize: 14,
                        }}
                        theme={{
                          roundness: 5,
                          colors: {
                            primary: "#c4c4c4",
                            background: "white",
                          },
                        }}
                        render={(innerProps) => {
                          return (
                            <NativeTextInput
                              {...innerProps}
                              editable={true}
                              style={[
                                innerProps.style,
                                {
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  height: 90,
                                },
                              ]}
                            />
                          );
                        }}
                      />
                      {this.state.showError && (
                        <Text style={{ color: "red", alignSelf: "center" }}>
                          Comment field cannot be empty
                        </Text>
                      )}
                      <TouchableOpacity
                        onPress={async () => {
                          if (this.state.comment.trim() === "") {
                            this.setState({ showError: true });
                            return;
                          }
                          this.setState({ showError: false });
                          Keyboard.dismiss();
                          const eventProperties = {
                            url: this.state.selectedItem.url,
                            comment: this.state.comment,
                          };
                          amplitude.logEvent(
                            "Insight_Comment_Added",
                            eventProperties
                          );
                          await updateInsightDetails({
                            insight_mapping_data: {
                              contentinsight_id:
                                this.state.selectedItem.contentinsight_id,
                              action: "COM",
                              reply: this.state.comment,
                            },
                          });
                          this.setState({ comment: "" });

                          let getInsightsResp = await getInsightDetails({
                            previous_key: "customerinsight",
                          });

                          let getInsightsCompleteResp = await getInsightDetails(
                            {
                              previous_key: "customerinsightmappingdata",
                            }
                          );
                          let UpdatedInsightResp =
                            getInsightsCompleteResp.result.filter(
                              (item) => !(item.is_hidden == 1)
                            );
                          this.setState({
                            insightsCompleteData: UpdatedInsightResp,
                          });

                          let totalLikesCount = UpdatedInsightResp.reduce(
                            function (result, item) {
                              if (item.action == "LIK") {
                                result[item.contentinsight_id] =
                                  (result[item.contentinsight_id] || 0) + 1;
                              }
                              return result;
                            },
                            {}
                          );

                          let totalCommentsCount = UpdatedInsightResp.reduce(
                            function (result, item) {
                              if (item.action == "COM") {
                                result[item.contentinsight_id] =
                                  (result[item.contentinsight_id] || 0) + 1;
                              }
                              return result;
                            },
                            {}
                          );

                          let userLikeCount = UpdatedInsightResp.reduce(
                            function (result, item) {
                              if (item.action == "LIK") {
                                if (
                                  item.customer_id ==
                                  Store.getState().userData.userProfileData
                                    .customer_id
                                ) {
                                  result[item.contentinsight_id] =
                                    (result[item.contentinsight_id] || 0) + 1;
                                }
                              }
                              return result;
                            },
                            {}
                          );

                          let updatedInsightsResp = [];
                          getInsightsResp.result.forEach((item) => {
                            updatedInsightsResp.push({
                              totalLikeCount: totalLikesCount[
                                item.contentinsight_id
                              ]
                                ? totalLikesCount[item.contentinsight_id]
                                : 0,
                              totalCommentsCount: totalCommentsCount[
                                item.contentinsight_id
                              ]
                                ? totalCommentsCount[item.contentinsight_id]
                                : 0,
                              userLikeCount: userLikeCount[
                                item.contentinsight_id
                              ]
                                ? userLikeCount[item.contentinsight_id]
                                : 0,
                              ...item,
                            });
                          });
                          getInsightsResp.result = updatedInsightsResp;

                          let webPostsData = [];
                          let webArticlesData = [];
                          let webVideosData = [];

                          let webPosts = getInsightsResp.result.filter(
                            (item) =>
                              item.insight_type == "Social Media Post" &&
                              item.status == "Active"
                          );

                          let webArticles = getInsightsResp.result.filter(
                            (item) =>
                              item.insight_type == "Published Article" &&
                              item.status == "Active"
                          );

                          let webVideos = getInsightsResp.result.filter(
                            (item) =>
                              item.insight_type == "Video" &&
                              item.status == "Active"
                          );

                          for (let i = 0; i < webPosts.length; i += 3) {
                            webPostsData.push(webPosts.slice(i, i + 3));
                          }

                          for (let i = 0; i < webArticles.length; i += 3) {
                            webArticlesData.push(webArticles.slice(i, i + 3));
                          }

                          for (let i = 0; i < webVideos.length; i += 3) {
                            webVideosData.push(webVideos.slice(i, i + 3));
                          }

                          this.setState({
                            webPostsData,
                            webArticlesData,
                            webVideosData,
                            webPosts,
                            webArticles,
                            webVideos,
                          });

                          let commentsList = [];
                          UpdatedInsightResp.filter((comment) => {
                            if (
                              comment.contentinsight_id ==
                                this.state.selectedItem.contentinsight_id &&
                              comment.reply &&
                              comment.action == "COM"
                            ) {
                              commentsList.push(comment);
                            }
                          });

                          this.setState({
                            commentsList: commentsList.reverse(),
                          });
                        }}
                        style={{
                          alignItems: "flex-end",
                          paddingTop: 20,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "bold",
                            lineHeight: 17,
                            fontSize: 15,
                            color: "#ffffff",
                            paddingVertical: 12,
                            paddingHorizontal: 25,
                            backgroundColor: "#410DAA",
                            borderRadius: 4,
                          }}
                        >
                          Add Comment
                        </Text>
                      </TouchableOpacity>
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#464E5F",
                          fontWeight: "600",
                          textDecorationLine: "underline",
                        }}
                      >
                        {this.state.commentsList.length
                          ? "Comments " +
                            "(" +
                            this.state.commentsList.length +
                            ")"
                          : ""}
                      </Text>
                      {this.state.commentsList.map((item) => {
                        return (
                          <>
                            <Text
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontWeight: "600",
                                marginTop: 20,
                              }}
                            >
                              {item.first_name}{" "}
                              <Text
                                style={{
                                  fontSize: 13,
                                  color: "#464E5F",
                                  fontWeight: "400",
                                  fontStyle: "italic",
                                  marginTop: 5,
                                }}
                              >
                                {moment(item.modified).format(
                                  "hh:mm . DD MMM YY"
                                )}{" "}
                              </Text>
                            </Text>
                            <Text
                              style={{
                                fontSize: 13,
                                color: "#464E5F",
                                fontWeight: "400",
                                marginTop: 5,
                              }}
                            >
                              {item.reply}
                            </Text>
                          </>
                        );
                      })}
                    </View>
                  </View>
                )}
              </ScrollView>
            </>
          ) : (
            <>
              <ToggleActiveCompletePlans
                tabs={this.state.tabsArray}
                activeIndex={activeTabIndex}
                onChangeValue={(selectedIndex) => {
                  if (selectedIndex != activeTabIndex) {
                    this.setState({ activeTabIndex: selectedIndex });
                  }
                }}
              />
              {
                <ScrollView
                  style={[styles.mainContainer]}
                  showsVerticalScrollIndicator={false}
                >
                  {this.state.activeTabIndex == 2 ? (
                    <>
                      {isMobile
                        ? this.state.webPosts.map((item) => {
                            return (
                              <ScrollView showsVerticalScrollIndicator={false}>
                                <View
                                  style={{
                                    marginTop: 20,
                                    width: isMobile ? "90%" : "100%",
                                    marginLeft: isMobile ? "5%" : "0%",
                                    marginRight: isMobile ? "5%" : "0%",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    marginBottom: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      height: 300,
                                      width: "100%",
                                      borderRadius: 10,
                                      borderColor: "#d9cfee",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <View style={{ height: 220 }}>
                                      <View
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-end",
                                          paddingRight: 30,
                                          paddingTop: 20,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            color: "#464E5F",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {moment(
                                            item.published_datetime
                                          ).format("hh:mm . DD MMM YY")}
                                        </Text>
                                      </View>
                                      <TouchableOpacity
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          marginTop: "7%",
                                        }}
                                        onPress={() => {
                                          const eventProperties = {
                                            url: item.url,
                                          };
                                          amplitude.logEvent(
                                            "Insight_Viewed",
                                            eventProperties
                                          );
                                          if (isMobile) {
                                            
                                            if(item.url !== ''){
                                            this.props.navigation.navigate(
                                              "InsightsWebView",
                                              {
                                                url: item.url,
                                              }
                                            );
                                          }
                                          } else {
                                            if(item.url !== ''){
                                            global.open(item.url, "_blank");
                                            }
                                          }
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#410DAA",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item.insight_text}
                                        </Text>
                                      </TouchableOpacity>
                                    </View>
                                    <View
                                      style={{
                                        display: "flex",
                                        height: 30,
                                      }}
                                    >
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            color: "#464E5F",
                                            fontWeight: "600",
                                          }}
                                        >
                                          # {item.authors}
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={{ height: 50 }}>
                                      <View
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          backgroundColor: "#d9cfee",
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {item.userLikeCount < 1 ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(item, "LIK");
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginTop: -2,
                                              }}
                                            >
                                              {" "}
                                              {item.totalLikeCount > 0 &&
                                                item.totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(item, "DIS");
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginTop: -2,
                                              }}
                                            >
                                              {" "}
                                              {item.totalLikeCount > 0 &&
                                                item.totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        )}
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            let commentsList = [];
                                            this.state.insightsCompleteData.filter(
                                              (comment) => {
                                                if (
                                                  comment.contentinsight_id ==
                                                    item.contentinsight_id &&
                                                  comment.reply &&
                                                  comment.action == "COM"
                                                ) {
                                                  commentsList.push(comment);
                                                }
                                              }
                                            );
                                            this.setState({
                                              commentsList:
                                                commentsList.reverse(),
                                              showComments: true,
                                              selectedItem: item,
                                              commentType: "Posts",
                                            });
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/chat.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                              marginTop: -2,
                                            }}
                                          >
                                            {" "}
                                            {item.totalCommentsCount > 0 &&
                                              item.totalCommentsCount}
                                          </Text>
                                        </TouchableOpacity>
                                        {isMobile ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => this.onShare(item)}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginRight: 20,
                                                marginTop: -2,
                                              }}
                                            ></Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 12,
                                            }}
                                          >
                                            <WebShareOptions url={item} />
                                          </View>
                                        )}
                                      </View>
                                    </View>
                                    <View></View>
                                  </View>
                                </View>
                              </ScrollView>
                            );
                          })
                        : this.state.webPostsData.map((item) => {
                            return (
                              <View
                                style={{
                                  marginTop: -10,
                                  width: isMobile ? "90%" : "100%",
                                  marginLeft: isMobile ? "5%" : "0%",
                                  marginRight: isMobile ? "5%" : "0%",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  marginBottom: 60,
                                }}
                              >
                                <View
                                  style={{
                                    height: 300,
                                    width: "31%",
                                    borderRadius: 10,
                                    borderColor: "#d9cfee",
                                    borderWidth: 1,
                                  }}
                                >
                                  <View style={{ height: 220 }}>
                                    <View
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        paddingRight: 30,
                                        paddingTop: 20,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 14,
                                          color: "#464E5F",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {moment(
                                          item[0].published_datetime
                                        ).format("hh:mm . DD MMM YY")}
                                      </Text>
                                    </View>
                                    <TouchableOpacity
                                      style={{
                                        display: "flex",
                                        width: "80%",
                                        marginLeft: "10%",
                                        marginTop: "7%",
                                      }}
                                      onPress={() => {
                                        const eventProperties = {
                                          url: item[0].url,
                                        };
                                        amplitude.logEvent(
                                          "Insight_Viewed",
                                          eventProperties
                                        );
                                        if (isMobile) {
                                          this.props.navigation.navigate(
                                            "InsightsWebView",
                                            {
                                              url: item[0].url,
                                            }
                                          );
                                        } else {
                                          if(item[0].url !== ''){
                                          global.open(item[0].url, "_blank");
                                          }
                                        }
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 16,
                                          color: "#410DAA",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item[0].insight_text}
                                      </Text>
                                    </TouchableOpacity>
                                  </View>
                                  <View
                                    style={{
                                      display: "flex",
                                      height: 30,
                                    }}
                                  >
                                    <View
                                      style={{
                                        display: "flex",
                                        width: "80%",
                                        marginLeft: "10%",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 14,
                                          color: "#464E5F",
                                          fontWeight: "600",
                                        }}
                                      >
                                        # {item[0].authors}
                                      </Text>
                                    </View>
                                  </View>
                                  <View style={{ height: 50 }}>
                                    <View
                                      style={{
                                        width: "100%",
                                        height: 1,
                                        backgroundColor: "#d9cfee",
                                      }}
                                    />
                                    <View
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {item[0].userLikeCount < 1 ? (
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            this.updateInsights(item[0], "LIK");
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                              marginLeft: 20,
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {" "}
                                            {item[0].totalLikeCount > 0 &&
                                              item[0].totalLikeCount}
                                          </Text>
                                        </TouchableOpacity>
                                      ) : (
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            this.updateInsights(item[0], "DIS");
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                              marginLeft: 20,
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {" "}
                                            {item[0].totalLikeCount > 0 &&
                                              item[0].totalLikeCount}
                                          </Text>
                                        </TouchableOpacity>
                                      )}
                                      <TouchableOpacity
                                        style={{
                                          flexDirection: "row",
                                          marginTop: 15,
                                        }}
                                        onPress={() => {
                                          let commentsList = [];
                                          this.state.insightsCompleteData.filter(
                                            (comment) => {
                                              if (
                                                comment.contentinsight_id ==
                                                  item[0].contentinsight_id &&
                                                comment.reply &&
                                                comment.action == "COM"
                                              ) {
                                                commentsList.push(comment);
                                              }
                                            }
                                          );
                                          this.setState({
                                            commentsList:
                                              commentsList.reverse(),
                                            showComments: true,
                                            selectedItem: item[0],
                                            commentType: "Posts",
                                          });
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/chat.png",
                                          }}
                                          style={{
                                            width: 16,
                                            height: 16,
                                            resizeMode: "contain",
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: "#6b717f",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {" "}
                                          {item[0].totalCommentsCount > 0 &&
                                            item[0].totalCommentsCount}
                                        </Text>
                                      </TouchableOpacity>
                                      {isMobile ? (
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => this.onShare(item[0])}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                              marginRight: 20,
                                              marginTop: -2,
                                            }}
                                          ></Text>
                                        </TouchableOpacity>
                                      ) : (
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 12,
                                          }}
                                        >
                                          <WebShareOptions url={item[0]} />
                                        </View>
                                      )}
                                    </View>
                                  </View>
                                  <View></View>
                                </View>
                                <View
                                  style={{
                                    height: 300,
                                    width: "3%",
                                  }}
                                ></View>
                                {item[1] && (
                                  <View
                                    style={{
                                      height: 300,
                                      width: "31%",
                                      borderRadius: 10,
                                      borderColor: "#d9cfee",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <View style={{ height: 220 }}>
                                      <View
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-end",
                                          paddingRight: 30,
                                          paddingTop: 20,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            color: "#464E5F",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {moment(
                                            item[1].published_datetime
                                          ).format("hh:mm . DD MMM YY")}
                                        </Text>
                                      </View>
                                      <TouchableOpacity
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          marginTop: "7%",
                                        }}
                                        onPress={() => {
                                          const eventProperties = {
                                            url: item[1].url,
                                          };
                                          amplitude.logEvent(
                                            "Insight_Viewed",
                                            eventProperties
                                          );
                                          if (isMobile) {
                                            this.props.navigation.navigate(
                                              "InsightsWebView",
                                              {
                                                url: item[1].url,
                                              }
                                            );
                                          } else {
                                            if(item[1].url !== ''){
                                            global.open(item[1].url, "_blank");
                                            }
                                          }
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#410DAA",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item[1].insight_text}
                                        </Text>
                                      </TouchableOpacity>
                                    </View>
                                    <View
                                      style={{
                                        display: "flex",
                                        height: 30,
                                      }}
                                    >
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            color: "#464E5F",
                                            fontWeight: "600",
                                          }}
                                        >
                                          # {item[1].authors}
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={{ height: 50 }}>
                                      <View
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          backgroundColor: "#d9cfee",
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {item[1].userLikeCount < 1 ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(
                                                item[1],
                                                "LIK"
                                              );
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {" "}
                                              {item[1].totalLikeCount > 0 &&
                                                item[1].totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(
                                                item[1],
                                                "DIS"
                                              );
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {" "}
                                              {item[1].totalLikeCount > 0 &&
                                                item[1].totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        )}
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            let commentsList = [];
                                            this.state.insightsCompleteData.filter(
                                              (comment) => {
                                                if (
                                                  comment.contentinsight_id ==
                                                    item[1].contentinsight_id &&
                                                  comment.reply &&
                                                  comment.action == "COM"
                                                ) {
                                                  commentsList.push(comment);
                                                }
                                              }
                                            );
                                            this.setState({
                                              commentsList:
                                                commentsList.reverse(),
                                              showComments: true,
                                              selectedItem: item[1],
                                              commentType: "Posts",
                                            });
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/chat.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {" "}
                                            {item[1].totalCommentsCount > 0 &&
                                              item[1].totalCommentsCount}
                                          </Text>
                                        </TouchableOpacity>
                                        {isMobile ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() =>
                                              this.onShare(item[1])
                                            }
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginRight: 20,
                                                marginTop: -2,
                                              }}
                                            ></Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 12,
                                            }}
                                          >
                                            <WebShareOptions url={item[1]} />
                                          </View>
                                        )}
                                      </View>
                                    </View>
                                    <View></View>
                                  </View>
                                )}
                                <View
                                  style={{
                                    height: 300,
                                    width: "3%",
                                  }}
                                ></View>
                                {item[2] && (
                                  <View
                                    style={{
                                      height: 300,
                                      width: "31%",
                                      borderRadius: 10,
                                      borderColor: "#d9cfee",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <View style={{ height: 220 }}>
                                      <View
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-end",
                                          paddingRight: 30,
                                          paddingTop: 20,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            color: "#464E5F",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {moment(
                                            item[2].published_datetime
                                          ).format("hh:mm . DD MMM YY")}
                                        </Text>
                                      </View>
                                      <TouchableOpacity
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          marginTop: "7%",
                                        }}
                                        onPress={() => {
                                          const eventProperties = {
                                            url: item[2].url,
                                          };
                                          amplitude.logEvent(
                                            "Insight_Viewed",
                                            eventProperties
                                          );
                                          if (isMobile) {
                                            this.props.navigation.navigate(
                                              "InsightsWebView",
                                              {
                                                url: item[2].url,
                                              }
                                            );
                                          } else {
                                            if(item[2].url !== ''){
                                            global.open(item[2].url, "_blank");
                                            }
                                          }
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: "#410DAA",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item[2].insight_text}
                                        </Text>
                                      </TouchableOpacity>
                                    </View>
                                    <View
                                      style={{
                                        display: "flex",
                                        height: 30,
                                      }}
                                    >
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            color: "#464E5F",
                                            fontWeight: "600",
                                          }}
                                        >
                                          # {item[2].authors}
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={{ height: 50 }}>
                                      <View
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          backgroundColor: "#d9cfee",
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {item[2].userLikeCount < 1 ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(
                                                item[2],
                                                "LIK"
                                              );
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {" "}
                                              {item[2].totalLikeCount > 0 &&
                                                item[2].totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(
                                                item[2],
                                                "DIS"
                                              );
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {" "}
                                              {item[2].totalLikeCount > 0 &&
                                                item[2].totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        )}
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            let commentsList = [];
                                            this.state.insightsCompleteData.filter(
                                              (comment) => {
                                                if (
                                                  comment.contentinsight_id ==
                                                    item[2].contentinsight_id &&
                                                  comment.reply &&
                                                  comment.action == "COM"
                                                ) {
                                                  commentsList.push(comment);
                                                }
                                              }
                                            );
                                            this.setState({
                                              commentsList:
                                                commentsList.reverse(),
                                              showComments: true,
                                              selectedItem: item[2],
                                              commentType: "Posts",
                                            });
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/chat.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {" "}
                                            {item[2].totalCommentsCount > 0 &&
                                              item[2].totalCommentsCount}
                                          </Text>
                                        </TouchableOpacity>
                                        {isMobile ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() =>
                                              this.onShare(item[2])
                                            }
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginRight: 20,
                                                marginTop: -2,
                                              }}
                                            ></Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 12,
                                            }}
                                          >
                                            <WebShareOptions url={item[2]} />
                                          </View>
                                        )}
                                      </View>
                                    </View>
                                    <View></View>
                                  </View>
                                )}
                              </View>
                            );
                          })}
                    </>
                  ) : null}

                  {this.state.activeTabIndex == 2 && isMobile && (
                    <View style={{ marginBottom: 100 }}></View>
                  )}

                  {this.state.activeTabIndex == 0 ? (
                    <>
                      {isMobile
                        ? this.state.webArticles.map((item) => {
                            return (
                              <ScrollView showsVerticalScrollIndicator={false}>
                                <View
                                  style={{
                                    marginTop: 20,
                                    width: isMobile ? "90%" : "100%",
                                    marginLeft: isMobile ? "5%" : "0%",
                                    marginRight: isMobile ? "5%" : "0%",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    marginBottom: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      height: 400,
                                      width: "100%",
                                      borderRadius: 10,
                                      borderColor: "#d9cfee",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <TouchableOpacity
                                      onPress={() => {
                                        const eventProperties = {
                                          url: item.url,
                                        };
                                        amplitude.logEvent(
                                          "Insight_Viewed",
                                          eventProperties
                                        );
                                        if (isMobile) {
                                          this.props.navigation.navigate(
                                            "InsightsWebView",
                                            {
                                              url: item.url,
                                            }
                                          );
                                        } else {
                                          global.open(item.url, "_blank");
                                        }
                                      }}
                                    >
                                      <Image
                                        source={{
                                          uri: item.image_url,
                                        }}
                                        style={{
                                          width: "100%",
                                          height: 250,
                                          resizeMode: "stretch",
                                          borderTopLeftRadius: 9,
                                          borderTopRightRadius: 9,
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          marginTop: 10,
                                          height: 60,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            color: "#410DAA",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item.insight_text}
                                        </Text>
                                      </View>
                                    </TouchableOpacity>
                                    <View
                                      style={{
                                        display: "flex",
                                        height: 30,
                                      }}
                                    >
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: "#7e838f",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          {moment(
                                            item.published_datetime
                                          ).format("hh:mm . DD MMM YY")}
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={{ height: 50 }}>
                                      <View
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          backgroundColor: "#d9cfee",
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {item.userLikeCount < 1 ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(item, "LIK");
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginTop: -2,
                                              }}
                                            >
                                              {" "}
                                              {item.totalLikeCount > 0 &&
                                                item.totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(item, "DIS");
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginTop: -2,
                                              }}
                                            >
                                              {" "}
                                              {item.totalLikeCount > 0 &&
                                                item.totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        )}
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            let commentsList = [];
                                            this.state.insightsCompleteData.filter(
                                              (comment) => {
                                                if (
                                                  comment.contentinsight_id ==
                                                    item.contentinsight_id &&
                                                  comment.reply &&
                                                  comment.action == "COM"
                                                ) {
                                                  commentsList.push(comment);
                                                }
                                              }
                                            );
                                            this.setState({
                                              commentsList:
                                                commentsList.reverse(),
                                              showComments: true,
                                              selectedItem: item,
                                              commentType: "Articles",
                                            });
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/chat.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                              marginTop: -2,
                                            }}
                                          >
                                            {" "}
                                            {item.totalCommentsCount > 0 &&
                                              item.totalCommentsCount}
                                          </Text>
                                        </TouchableOpacity>
                                        {isMobile ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => this.onShare(item)}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginRight: 20,
                                                marginTop: -2,
                                              }}
                                            ></Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 12,
                                            }}
                                          >
                                            <WebShareOptions url={item} />
                                          </View>
                                        )}
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </ScrollView>
                            );
                          })
                        : this.state.webArticlesData.map((item) => {
                            return (
                              <View
                                style={{
                                  marginTop: -10,
                                  width: isMobile ? "90%" : "100%",
                                  marginLeft: isMobile ? "5%" : "0%",
                                  marginRight: isMobile ? "5%" : "0%",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  marginBottom: 60,
                                }}
                              >
                                <View
                                  style={{
                                    height: 400,
                                    width: "31%",
                                    borderRadius: 10,
                                    borderColor: "#d9cfee",
                                    borderWidth: 1,
                                  }}
                                >
                                  <TouchableOpacity
                                    onPress={() => {
                                      const eventProperties = {
                                        url: item[0].url,
                                      };
                                      amplitude.logEvent(
                                        "Insight_Viewed",
                                        eventProperties
                                      );
                                      if (isMobile) {
                                        this.props.navigation.navigate(
                                          "InsightsWebView",
                                          {
                                            url: item[0].url,
                                          }
                                        );
                                      } else {
                                        global.open(item[0].url, "_blank");
                                      }
                                    }}
                                  >
                                    <Image
                                      source={{
                                        uri: item[0].image_url,
                                      }}
                                      style={{
                                        width: "100%",
                                        height: 250,
                                        resizeMode: "stretch",
                                        borderTopLeftRadius: 9,
                                        borderTopRightRadius: 9,
                                      }}
                                    />
                                    <View
                                      style={{
                                        display: "flex",
                                        width: "80%",
                                        marginLeft: "10%",
                                        marginTop: 10,
                                        height: 60,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 14,
                                          color: "#410DAA",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item[0].insight_text}
                                      </Text>
                                    </View>
                                  </TouchableOpacity>
                                  <View
                                    style={{
                                      display: "flex",
                                      height: 30,
                                    }}
                                  >
                                    <View
                                      style={{
                                        display: "flex",
                                        width: "80%",
                                        marginLeft: "10%",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 13,
                                          color: "#7e838f",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {moment(
                                          item[0].published_datetime
                                        ).format("hh:mm . DD MMM YY")}
                                      </Text>
                                    </View>
                                  </View>
                                  <View style={{ height: 50 }}>
                                    <View
                                      style={{
                                        width: "100%",
                                        height: 1,
                                        backgroundColor: "#d9cfee",
                                      }}
                                    />
                                    <View
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {item[0].userLikeCount < 1 ? (
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            this.updateInsights(item[0], "LIK");
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                              marginLeft: 20,
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {" "}
                                            {item[0].totalLikeCount > 0 &&
                                              item[0].totalLikeCount}
                                          </Text>
                                        </TouchableOpacity>
                                      ) : (
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            this.updateInsights(item[0], "DIS");
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                              marginLeft: 20,
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {" "}
                                            {item[0].totalLikeCount > 0 &&
                                              item[0].totalLikeCount}
                                          </Text>
                                        </TouchableOpacity>
                                      )}
                                      <TouchableOpacity
                                        style={{
                                          flexDirection: "row",
                                          marginTop: 15,
                                        }}
                                        onPress={() => {
                                          let commentsList = [];
                                          this.state.insightsCompleteData.filter(
                                            (comment) => {
                                              if (
                                                comment.contentinsight_id ==
                                                  item[0].contentinsight_id &&
                                                comment.reply &&
                                                comment.action == "COM"
                                              ) {
                                                commentsList.push(comment);
                                              }
                                            }
                                          );
                                          this.setState({
                                            commentsList:
                                              commentsList.reverse(),
                                            showComments: true,
                                            selectedItem: item[0],
                                            commentType: "Articles",
                                          });
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/chat.png",
                                          }}
                                          style={{
                                            width: 16,
                                            height: 16,
                                            resizeMode: "contain",
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: "#6b717f",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {" "}
                                          {item[0].totalCommentsCount > 0 &&
                                            item[0].totalCommentsCount}
                                        </Text>
                                      </TouchableOpacity>
                                      {isMobile ? (
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => this.onShare(item[0])}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                              marginRight: 20,
                                              marginTop: -2,
                                            }}
                                          ></Text>
                                        </TouchableOpacity>
                                      ) : (
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 12,
                                          }}
                                        >
                                          <WebShareOptions url={item[0]} />
                                        </View>
                                      )}
                                    </View>
                                  </View>
                                </View>
                                <View
                                  style={{
                                    height: 300,
                                    width: "3%",
                                  }}
                                ></View>
                                {item[1] && (
                                  <View
                                    style={{
                                      height: 400,
                                      width: "31%",
                                      borderRadius: 10,
                                      borderColor: "#d9cfee",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <TouchableOpacity
                                      onPress={() => {
                                        const eventProperties = {
                                          url: item[1].url,
                                        };
                                        amplitude.logEvent(
                                          "Insight_Viewed",
                                          eventProperties
                                        );
                                        if (isMobile) {
                                          this.props.navigation.navigate(
                                            "InsightsWebView",
                                            {
                                              url: item[1].url,
                                            }
                                          );
                                        } else {
                                          global.open(item[1].url, "_blank");
                                        }
                                      }}
                                    >
                                      <Image
                                        source={{
                                          uri: item[1].image_url,
                                        }}
                                        style={{
                                          width: "100%",
                                          height: 250,
                                          resizeMode: "stretch",
                                          borderTopLeftRadius: 9,
                                          borderTopRightRadius: 9,
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          marginTop: 10,
                                          height: 60,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            color: "#410DAA",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item[1].insight_text}
                                        </Text>
                                      </View>
                                    </TouchableOpacity>
                                    <View
                                      style={{
                                        display: "flex",
                                        height: 30,
                                      }}
                                    >
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: "#7e838f",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          {moment(
                                            item[1].published_datetime
                                          ).format("hh:mm . DD MMM YY")}
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={{ height: 50 }}>
                                      <View
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          backgroundColor: "#d9cfee",
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {item[1].userLikeCount < 1 ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(
                                                item[1],
                                                "LIK"
                                              );
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {" "}
                                              {item[1].totalLikeCount > 0 &&
                                                item[1].totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(
                                                item[1],
                                                "DIS"
                                              );
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {" "}
                                              {item[1].totalLikeCount > 0 &&
                                                item[1].totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        )}
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            let commentsList = [];
                                            this.state.insightsCompleteData.filter(
                                              (comment) => {
                                                if (
                                                  comment.contentinsight_id ==
                                                    item[1].contentinsight_id &&
                                                  comment.reply &&
                                                  comment.action == "COM"
                                                ) {
                                                  commentsList.push(comment);
                                                }
                                              }
                                            );
                                            this.setState({
                                              commentsList:
                                                commentsList.reverse(),
                                              showComments: true,
                                              selectedItem: item[1],
                                              commentType: "Articles",
                                            });
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/chat.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {" "}
                                            {item[1].totalCommentsCount > 0 &&
                                              item[1].totalCommentsCount}
                                          </Text>
                                        </TouchableOpacity>
                                        {isMobile ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() =>
                                              this.onShare(item[1])
                                            }
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginRight: 20,
                                                marginTop: -2,
                                              }}
                                            ></Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 12,
                                            }}
                                          >
                                            <WebShareOptions url={item[1]} />
                                          </View>
                                        )}
                                      </View>
                                    </View>
                                  </View>
                                )}
                                <View
                                  style={{
                                    height: 300,
                                    width: "3%",
                                  }}
                                ></View>
                                {item[2] && (
                                  <View
                                    style={{
                                      height: 400,
                                      width: "31%",
                                      borderRadius: 10,
                                      borderColor: "#d9cfee",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <TouchableOpacity
                                      onPress={() => {
                                        const eventProperties = {
                                          url: item[2].url,
                                        };
                                        amplitude.logEvent(
                                          "Insight_Viewed",
                                          eventProperties
                                        );
                                        if (isMobile) {
                                          this.props.navigation.navigate(
                                            "InsightsWebView",
                                            {
                                              url: item[2].url,
                                            }
                                          );
                                        } else {
                                          global.open(item[2].url, "_blank");
                                        }
                                      }}
                                    >
                                      <Image
                                        source={{
                                          uri: item[2].image_url,
                                        }}
                                        style={{
                                          width: "100%",
                                          height: 250,
                                          resizeMode: "stretch",
                                          borderTopLeftRadius: 9,
                                          borderTopRightRadius: 9,
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          marginTop: 10,
                                          height: 60,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            color: "#410DAA",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item[2].insight_text}
                                        </Text>
                                      </View>
                                    </TouchableOpacity>
                                    <View
                                      style={{
                                        display: "flex",
                                        height: 30,
                                      }}
                                    >
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: "#7e838f",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          {moment(
                                            item[2].published_datetime
                                          ).format("hh:mm . DD MMM YY")}
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={{ height: 50 }}>
                                      <View
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          backgroundColor: "#d9cfee",
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {item[2].userLikeCount < 1 ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(
                                                item[2],
                                                "LIK"
                                              );
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {" "}
                                              {item[2].totalLikeCount > 0 &&
                                                item[2].totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(
                                                item[2],
                                                "DIS"
                                              );
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {" "}
                                              {item[2].totalLikeCount > 0 &&
                                                item[2].totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        )}
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            let commentsList = [];
                                            this.state.insightsCompleteData.filter(
                                              (comment) => {
                                                if (
                                                  comment.contentinsight_id ==
                                                    item[2].contentinsight_id &&
                                                  comment.reply &&
                                                  comment.action == "COM"
                                                ) {
                                                  commentsList.push(comment);
                                                }
                                              }
                                            );
                                            this.setState({
                                              commentsList:
                                                commentsList.reverse(),
                                              showComments: true,
                                              selectedItem: item[2],
                                              commentType: "Articles",
                                            });
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/chat.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {" "}
                                            {item[2].totalCommentsCount > 0 &&
                                              item[2].totalCommentsCount}
                                          </Text>
                                        </TouchableOpacity>
                                        {isMobile ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() =>
                                              this.onShare(item[2])
                                            }
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginRight: 20,
                                                marginTop: -2,
                                              }}
                                            ></Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 12,
                                            }}
                                          >
                                            <WebShareOptions url={item[2]} />
                                          </View>
                                        )}
                                      </View>
                                    </View>
                                  </View>
                                )}
                              </View>
                            );
                          })}
                    </>
                  ) : null}

                  {this.state.activeTabIndex == 0 && isMobile && (
                    <View style={{ marginBottom: 100 }}></View>
                  )}

                  {this.state.activeTabIndex == 1 ? (
                    <>
                      {isMobile
                        ? this.state.webVideos.map((item) => {
                            return (
                              <ScrollView showsVerticalScrollIndicator={false}>
                                <View
                                  style={{
                                    marginTop: 20,
                                    width: isMobile ? "90%" : "100%",
                                    marginLeft: isMobile ? "5%" : "0%",
                                    marginRight: isMobile ? "5%" : "0%",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    marginBottom: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      height: 400,
                                      width: "100%",
                                      borderRadius: 10,
                                      borderColor: "#d9cfee",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <TouchableOpacity
                                      onPress={() => {
                                        const eventProperties = {
                                          url: item.url,
                                        };
                                        amplitude.logEvent(
                                          "Insight_Viewed",
                                          eventProperties
                                        );
                                        if (isMobile) {
                                          this.props.navigation.navigate(
                                            "InsightsWebView",
                                            {
                                              url: item.url,
                                            }
                                          );
                                        } else {
                                          global.open(item.url, "_blank");
                                        }
                                      }}
                                    >
                                      <Image
                                        source={{
                                          uri: item.image_url,
                                        }}
                                        style={{
                                          width: "100%",
                                          height: 250,
                                          resizeMode: "stretch",
                                          borderTopLeftRadius: 9,
                                          borderTopRightRadius: 9,
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          marginTop: 10,
                                          height: 60,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            color: "#410DAA",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item.insight_text}
                                        </Text>
                                      </View>
                                    </TouchableOpacity>
                                    <View
                                      style={{
                                        display: "flex",
                                        height: 30,
                                      }}
                                    >
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: "#7e838f",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          {moment(
                                            item.published_datetime
                                          ).format("hh:mm . DD MMM YY")}
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={{ height: 50 }}>
                                      <View
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          backgroundColor: "#d9cfee",
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {item.userLikeCount < 1 ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(item, "LIK");
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginTop: -2,
                                              }}
                                            >
                                              {" "}
                                              {item.totalLikeCount > 0 &&
                                                item.totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(item, "DIS");
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginTop: -2,
                                              }}
                                            >
                                              {" "}
                                              {item.totalLikeCount > 0 &&
                                                item.totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        )}
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            let commentsList = [];
                                            this.state.insightsCompleteData.filter(
                                              (comment) => {
                                                if (
                                                  comment.contentinsight_id ==
                                                    item.contentinsight_id &&
                                                  comment.reply &&
                                                  comment.action == "COM"
                                                ) {
                                                  commentsList.push(comment);
                                                }
                                              }
                                            );
                                            this.setState({
                                              commentsList:
                                                commentsList.reverse(),
                                              showComments: true,
                                              selectedItem: item,
                                              commentType: "Videos",
                                            });
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/chat.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                              marginTop: -2,
                                            }}
                                          >
                                            {" "}
                                            {item.totalCommentsCount > 0 &&
                                              item.totalCommentsCount}
                                          </Text>
                                        </TouchableOpacity>
                                        {isMobile ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => this.onShare(item)}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginRight: 20,
                                                marginTop: -2,
                                              }}
                                            ></Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 12,
                                            }}
                                          >
                                            <WebShareOptions url={item} />
                                          </View>
                                        )}
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </ScrollView>
                            );
                          })
                        : this.state.webVideosData.map((item) => {
                            return (
                              <View
                                style={{
                                  marginTop: -10,
                                  width: isMobile ? "90%" : "100%",
                                  marginLeft: isMobile ? "5%" : "0%",
                                  marginRight: isMobile ? "5%" : "0%",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  marginBottom: 60,
                                }}
                              >
                                <View
                                  style={{
                                    height: 400,
                                    width: "31%",
                                    borderRadius: 10,
                                    borderColor: "#d9cfee",
                                    borderWidth: 1,
                                  }}
                                >
                                  <TouchableOpacity
                                    onPress={() => {
                                      const eventProperties = {
                                        url: item[0].url,
                                      };
                                      amplitude.logEvent(
                                        "Insight_Viewed",
                                        eventProperties
                                      );
                                      if (isMobile) {
                                        this.props.navigation.navigate(
                                          "InsightsWebView",
                                          {
                                            url: (
                                              <iframe
                                                width="100%"
                                                height="100%"
                                                src={item[0].url}
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen
                                              ></iframe>
                                            ),
                                            isvideo: true,
                                          }
                                        );
                                      } else {
                                        global.open(item[0].url, "_blank");
                                      }
                                    }}
                                  >
                                    <Image
                                      source={{
                                        uri: item[0].image_url,
                                      }}
                                      style={{
                                        width: "100%",
                                        height: 250,
                                        resizeMode: "stretch",
                                        borderTopLeftRadius: 9,
                                        borderTopRightRadius: 9,
                                      }}
                                    />
                                    <View
                                      style={{
                                        display: "flex",
                                        width: "80%",
                                        marginLeft: "10%",
                                        marginTop: 10,
                                        height: 60,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 14,
                                          color: "#410DAA",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item[0].insight_text}
                                      </Text>
                                    </View>
                                  </TouchableOpacity>
                                  <View
                                    style={{
                                      display: "flex",
                                      height: 30,
                                    }}
                                  >
                                    <View
                                      style={{
                                        display: "flex",
                                        width: "80%",
                                        marginLeft: "10%",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 13,
                                          color: "#7e838f",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {moment(
                                          item[0].published_datetime
                                        ).format("hh:mm . DD MMM YY")}
                                      </Text>
                                    </View>
                                  </View>
                                  <View style={{ height: 50 }}>
                                    <View
                                      style={{
                                        width: "100%",
                                        height: 1,
                                        backgroundColor: "#d9cfee",
                                      }}
                                    />
                                    <View
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {item[0].userLikeCount < 1 ? (
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            this.updateInsights(item[0], "LIK");
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                              marginLeft: 20,
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {" "}
                                            {item[0].totalLikeCount > 0 &&
                                              item[0].totalLikeCount}
                                          </Text>
                                        </TouchableOpacity>
                                      ) : (
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            this.updateInsights(item[0], "DIS");
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                              marginLeft: 20,
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {" "}
                                            {item[0].totalLikeCount > 0 &&
                                              item[0].totalLikeCount}
                                          </Text>
                                        </TouchableOpacity>
                                      )}
                                      <TouchableOpacity
                                        style={{
                                          flexDirection: "row",
                                          marginTop: 15,
                                        }}
                                        onPress={() => {
                                          let commentsList = [];
                                          this.state.insightsCompleteData.filter(
                                            (comment) => {
                                              if (
                                                comment.contentinsight_id ==
                                                  item[0].contentinsight_id &&
                                                comment.reply &&
                                                comment.action == "COM"
                                              ) {
                                                commentsList.push(comment);
                                              }
                                            }
                                          );
                                          this.setState({
                                            commentsList:
                                              commentsList.reverse(),
                                            showComments: true,
                                            selectedItem: item[0],
                                            commentType: "Videos",
                                          });
                                        }}
                                      >
                                        <Image
                                          source={{
                                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/chat.png",
                                          }}
                                          style={{
                                            width: 16,
                                            height: 16,
                                            resizeMode: "contain",
                                          }}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: "#6b717f",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {" "}
                                          {item[0].totalCommentsCount > 0 &&
                                            item[0].totalCommentsCount}
                                        </Text>
                                      </TouchableOpacity>
                                      {isMobile ? (
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => this.onShare(item[0])}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                              marginRight: 20,
                                              marginTop: -2,
                                            }}
                                          ></Text>
                                        </TouchableOpacity>
                                      ) : (
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 12,
                                          }}
                                        >
                                          <WebShareOptions url={item[0]} />
                                        </View>
                                      )}
                                    </View>
                                  </View>
                                </View>
                                <View
                                  style={{
                                    height: 300,
                                    width: "3%",
                                  }}
                                ></View>
                                {item[1] && (
                                  <View
                                    style={{
                                      height: 400,
                                      width: "31%",
                                      borderRadius: 10,
                                      borderColor: "#d9cfee",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <TouchableOpacity
                                      onPress={() => {
                                        const eventProperties = {
                                          url: item[1].url,
                                        };
                                        amplitude.logEvent(
                                          "Insight_Viewed",
                                          eventProperties
                                        );
                                        if (isMobile) {
                                          this.props.navigation.navigate(
                                            "InsightsWebView",
                                            {
                                              url: (
                                                <iframe
                                                  width="100%"
                                                  height="100%"
                                                  src={item[1].url}
                                                  title="YouTube video player"
                                                  frameborder="0"
                                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                  allowfullscreen
                                                ></iframe>
                                              ),
                                              isvideo: true,
                                            }
                                          );
                                        } else {
                                          global.open(item[1].url, "_blank");
                                        }
                                      }}
                                    >
                                      <Image
                                        source={{
                                          uri: item[1].image_url,
                                        }}
                                        style={{
                                          width: "100%",
                                          height: 250,
                                          resizeMode: "stretch",
                                          borderTopLeftRadius: 9,
                                          borderTopRightRadius: 9,
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          marginTop: 10,
                                          height: 60,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            color: "#410DAA",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item[1].insight_text}
                                        </Text>
                                      </View>
                                    </TouchableOpacity>
                                    <View
                                      style={{
                                        display: "flex",
                                        height: 30,
                                      }}
                                    >
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: "#7e838f",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          {moment(
                                            item[1].published_datetime
                                          ).format("hh:mm . DD MMM YY")}
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={{ height: 50 }}>
                                      <View
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          backgroundColor: "#d9cfee",
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {item[1].userLikeCount < 1 ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(
                                                item[1],
                                                "LIK"
                                              );
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {" "}
                                              {item[1].totalLikeCount > 0 &&
                                                item[1].totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(
                                                item[1],
                                                "DIS"
                                              );
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {" "}
                                              {item[1].totalLikeCount > 0 &&
                                                item[1].totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        )}
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            let commentsList = [];
                                            this.state.insightsCompleteData.filter(
                                              (comment) => {
                                                if (
                                                  comment.contentinsight_id ==
                                                    item[1].contentinsight_id &&
                                                  comment.reply &&
                                                  comment.action == "COM"
                                                ) {
                                                  commentsList.push(comment);
                                                }
                                              }
                                            );
                                            this.setState({
                                              commentsList:
                                                commentsList.reverse(),
                                              showComments: true,
                                              selectedItem: item[1],
                                              commentType: "Videos",
                                            });
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/chat.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {" "}
                                            {item[1].totalCommentsCount > 0 &&
                                              item[1].totalCommentsCount}
                                          </Text>
                                        </TouchableOpacity>
                                        {isMobile ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() =>
                                              this.onShare(item[1])
                                            }
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginRight: 20,
                                                marginTop: -2,
                                              }}
                                            ></Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 12,
                                            }}
                                          >
                                            <WebShareOptions url={item[1]} />
                                          </View>
                                        )}
                                      </View>
                                    </View>
                                  </View>
                                )}
                                <View
                                  style={{
                                    height: 300,
                                    width: "3%",
                                  }}
                                ></View>
                                {item[2] && (
                                  <View
                                    style={{
                                      height: 400,
                                      width: "31%",
                                      borderRadius: 10,
                                      borderColor: "#d9cfee",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <TouchableOpacity
                                      onPress={() => {
                                        const eventProperties = {
                                          url: item[2].url,
                                        };
                                        amplitude.logEvent(
                                          "Insight_Viewed",
                                          eventProperties
                                        );
                                        if (isMobile) {
                                          this.props.navigation.navigate(
                                            "InsightsWebView",
                                            {
                                              url: (
                                                <iframe
                                                  width="100%"
                                                  height="100%"
                                                  src={item[2].url}
                                                  title="YouTube video player"
                                                  frameborder="0"
                                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                  allowfullscreen
                                                ></iframe>
                                              ),
                                              isvideo: true,
                                            }
                                          );
                                        } else {
                                          global.open(item[2].url, "_blank");
                                        }
                                      }}
                                    >
                                      <Image
                                        source={{
                                          uri: item[2].image_url,
                                        }}
                                        style={{
                                          width: "100%",
                                          height: 250,
                                          resizeMode: "stretch",
                                          borderTopLeftRadius: 9,
                                          borderTopRightRadius: 9,
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          marginTop: 10,
                                          height: 60,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 14,
                                            color: "#410DAA",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item[2].insight_text}
                                        </Text>
                                      </View>
                                    </TouchableOpacity>
                                    <View
                                      style={{
                                        display: "flex",
                                        height: 30,
                                      }}
                                    >
                                      <View
                                        style={{
                                          display: "flex",
                                          width: "80%",
                                          marginLeft: "10%",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: "#7e838f",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          {moment(
                                            item[2].published_datetime
                                          ).format("hh:mm . DD MMM YY")}
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={{ height: 50 }}>
                                      <View
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          backgroundColor: "#d9cfee",
                                        }}
                                      />
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {item[2].userLikeCount < 1 ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(
                                                item[2],
                                                "LIK"
                                              );
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {" "}
                                              {item[2].totalLikeCount > 0 &&
                                                item[2].totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() => {
                                              this.updateInsights(
                                                item[2],
                                                "DIS"
                                              );
                                            }}
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/like_active.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                                marginLeft: 20,
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {" "}
                                              {item[2].totalLikeCount > 0 &&
                                                item[2].totalLikeCount}
                                            </Text>
                                          </TouchableOpacity>
                                        )}
                                        <TouchableOpacity
                                          style={{
                                            flexDirection: "row",
                                            marginTop: 15,
                                          }}
                                          onPress={() => {
                                            let commentsList = [];
                                            this.state.insightsCompleteData.filter(
                                              (comment) => {
                                                if (
                                                  comment.contentinsight_id ==
                                                    item[2].contentinsight_id &&
                                                  comment.reply &&
                                                  comment.action == "COM"
                                                ) {
                                                  commentsList.push(comment);
                                                }
                                              }
                                            );
                                            this.setState({
                                              commentsList:
                                                commentsList.reverse(),
                                              showComments: true,
                                              selectedItem: item[2],
                                              commentType: "Videos",
                                            });
                                          }}
                                        >
                                          <Image
                                            source={{
                                              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/chat.png",
                                            }}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              resizeMode: "contain",
                                            }}
                                          />
                                          <Text
                                            style={{
                                              fontSize: 13,
                                              color: "#6b717f",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {" "}
                                            {item[2].totalCommentsCount > 0 &&
                                              item[2].totalCommentsCount}
                                          </Text>
                                        </TouchableOpacity>
                                        {isMobile ? (
                                          <TouchableOpacity
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 15,
                                            }}
                                            onPress={() =>
                                              this.onShare(item[2])
                                            }
                                          >
                                            <Image
                                              source={{
                                                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/share.png",
                                              }}
                                              style={{
                                                width: 16,
                                                height: 16,
                                                resizeMode: "contain",
                                              }}
                                            />
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                color: "#6b717f",
                                                fontWeight: "600",
                                                marginRight: 20,
                                                marginTop: -2,
                                              }}
                                            ></Text>
                                          </TouchableOpacity>
                                        ) : (
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              marginTop: 12,
                                            }}
                                          >
                                            <WebShareOptions url={item[2]} />
                                          </View>
                                        )}
                                      </View>
                                    </View>
                                  </View>
                                )}
                              </View>
                            );
                          })}
                    </>
                  ) : null}
                  {this.state.activeTabIndex == 1 && isMobile && (
                    <View style={{ marginBottom: 100 }}></View>
                  )}
                </ScrollView>
              }
            </>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 1500,
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    paddingLeft: isWeb ? 32 : 0,
    paddingRight: isWeb ? 32 : 0,
    paddingTop: isWeb ? 30 : 0,
    maxWidth: 1400,
    marginBottom: isMobile ? 0 : 20,
  },
  total_pay: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#464E5F",
  },
  manage_schedule_button: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: isWeb ? 15 : 13,
    color: "#410DAA",
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginRight: 11,
  },
  make_pay: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  make_pay1: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#FFFFFF",
    paddingVertical: 10,
  },
  graph_icon: {
    backgroundColor: "#1BC5BD",
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 20,
    marginRight: 10,
  },
  grap: {
    flexDirection: isWeb ? "row" : "column",
    justifyContent: "space-between",
    backgroundColor: "rgba(226, 252, 249, 0.6)",
    borderRadius: isWeb ? 12 : 0,
    paddingLeft: isWeb ? 22 : 16,
    paddingRight: isWeb ? 22 : 16,
  },
  jew_port: {
    fontFamily: "Roboto",
    fontSize: isWeb ? 16 : 13,
    lineHeight: 21,
    color: "#464E5F",
    width: "94%",
    flexWrap: "wrap",
  },
  start: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 15 : 14,
    lineHeight: 17,
    color: "#FFFFFF",
    textAlign: "center",
  },
  start_butt: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    backgroundColor: "#16C4A9",
    borderRadius: 6,
    marginVertical: 17,
  },
  dte: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#888C9F",
  },
  inv_no: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#1155CC",
    textDecorationLine: "none",
    paddingLeft: 6,
  },
  plan_code: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    fontFamily: "bold",
  },
  viewAgreement: {
    flexDirection: "row",
    alignItems: "center",
  },
  amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
    paddingTop: 10,
  },
  type: {
    color: "#1BC5BD",
    fontSize: 12,
    marginTop: 5,
  },
  invested_amount: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#121212",
  },
  invested: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#888C9F",
    paddingTop: 10,
  },
  invest_of: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 23,
    color: "#464E5F",
  },
  inve_amt: {
    fontWeight: "bold",
    color: "#121212",
    fontStyle: "italic",
  },
  line: {
    width: 5,
    height: 48,
    backgroundColor: "#6D39F5",
    borderRadius: 4,
    marginRight: 10,
  },
  ad_fee: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 14,
    color: "#888C9F",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: "#888C9F",
    borderRadius: 2,
    marginHorizontal: 6,
  },

  filtersContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  filter: {
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 0.8,
    borderColor: "#f6f6f6",
    flexDirection: "row",
    alignItems: "center",
  },
  filterClose: {
    paddingLeft: 6,
  },
});
