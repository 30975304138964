import { LOGOUT } from "../actions/types";

const INITIAL_STATE = {
  transactions: {},
  actions: {},
};

const transactionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ADD_TRANSACTION":
      const { key, data } = action.payload;
      let { transactions = {} } = state;
      transactions = {
        [key]: data,
      };
      return { ...state, transactions };
    case LOGOUT:
      return INITIAL_STATE;
    case "ADD_ACTION":
      const { key: actionKey, data: actionData } = action.payload;
      let { actions = {} } = state;
      actions = {
        [actionKey]: actionData,
      };
      return { ...state, actions };
    case "UPDATE_ACTION":
      const { key: actionKeyToUpdate, data: newData } = action.payload;
      let { actions: currentActions = {} } = state;
      let updatedActions = { ...currentActions };
      updatedActions[actionKeyToUpdate] = {
        ...updatedActions[actionKeyToUpdate],
        ...newData,
      };
      return { ...state, actions: updatedActions };
    default:
      return state;
  }
};

export default transactionsReducer;
