import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Switch } from "react-native-paper";
import { pendingTransactionAction } from "../../../api/alerts";
import amplitude from "../../../utils/amplitude";
import { months_short_names, isMobile } from "../../../utils";
const S3_IMAGE_URL = "https://jamaappprod.s3.amazonaws.com/media/";

export default class pendingTransactionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Paidbool: false,
      loadingAction: false,
    };
  }

  componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("PendingPaymentsCard",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("PendingPaymentsCard", { path: this.props.navigation.getState().routeName });
    }
    const pendingTransaction = this.props.data;
    this.setState({
      Paidbool: pendingTransaction.is_marked_as_paid,
    });
  }

  _handleAction = (bool) => {
    try {
      const foliodata = this.props.data;

      this.setState({ loadingAction: true });
      pendingTransactionAction({
        transaction_id: foliodata.transaction_id,
        is_marked_as_paid: new String(bool),
      })
        .then((json) => {
          if (json.code == 100) {
            setTimeout(() => {
              this.props.reloadPendingPayments();
            }, 1000);

            this.setState({
              Paidbool: !this.state.Paidbool,
              loadingAction: false,
            });
            return;
          }
          if (json.code == 0) {
            this.setState({ loadingAction: false });
            return;
          }
          if (json.code == 101) {
            this.setState({ loadingAction: false });
          } else {
          }
        })
        .catch(() => {
          this.setState({ loadingAction: false });
        });
    } catch (error) {
      this.setState({ loadingAction: false });
    }
  };

  showToggleOrMakePayment = (status) => {
    let toggle = false,
      makepaymentbutton = false;
    switch (status) {
      case "PIB":
        toggle = true;
        break;
      case "PCB":
        toggle = false;
        makepaymentbutton = false;
        break;
      case "AWP":
        toggle = false;
        makepaymentbutton = true;
        break;
      default:
        toggle = false;
        makepaymentbutton = false;
    }
    return { toggle, makepaymentbutton };
  };

  render() {
    const pendingTransaction = this.props.data;

    if (this.state.Paidbool == true) {
    }
    if (this.state.Paidbool == false) {
    }

    let order_date = pendingTransaction.created;
    order_date = order_date.split("<br>")[0];
    order_date = order_date.split("-");
    const month = months_short_names(parseInt(order_date[1]));
    order_date = order_date[2] + "-" + month + "-" + order_date[0];

    let amount_parsed = pendingTransaction.amount;
    amount_parsed = amount_parsed.split(".");
    amount_parsed = amount_parsed[0].replace("₹", "");
    amount_parsed = amount_parsed.replace(",", "");
    amount_parsed = Number(amount_parsed);

    const { toggle, makepaymentbutton } = this.showToggleOrMakePayment(
      pendingTransaction.transaction_status
    );
    return this.state.loadingAction ? (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <ActivityIndicator size="large" color="#410DAA" />
      </View>
    ) : (
      <View
        style={{
          paddingLeft: isMobile ? "5%" : "25%",
          paddingRight: isMobile ? "5%" : "40%",
          borderRadius: 5,
          padding: 8,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
          <View
            style={{
              flex: 0.15,
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <View
              style={{
                width: 45,
                height: 45,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                borderColor: "#410DAA1A",
                borderWidth: 0.7,
              }}
            >
              <Image
                style={{ width: 40, height: 40, resizeMode: "contain" }}
                source={{ uri: S3_IMAGE_URL + pendingTransaction.amc_logo }}
              />
            </View>
          </View>
          <View style={{ flex: 0.6 }}>
            <Text
              style={{
                color: "black",
                alignSelf: "flex-start",
                fontSize: 15,
                width: "80%",
                paddingLeft: 8,
              }}
            >
              {pendingTransaction.scheme_name}
            </Text>
          </View>
          <View
            style={{
              flex: 0.25,
              marginRight: 8,
              alignItems: "flex-end",
            }}
          >
            <View style={{ alignItems: "center" }}>
              <Text
                style={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: 13,
                }}
              >
                {pendingTransaction.amount}
              </Text>
              <Text
                style={{
                  color: "#876CDD",
                  paddingTop: 7,
                  fontSize: 11,
                }}
              >
                {pendingTransaction.txn_type}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            marginVertical: 4,
            marginTop: 10,
            marginHorizontal: 8,
          }}
        >
          <Text style={{ fontSize: 13, color: "#A8A8A8", flex: 0.3 }}>
            Order No
          </Text>
          <View
            style={{
              flexDirection: "row",
              flex: 0.7,
              alignItems: "flex-start",
            }}
          >
            <Text style={{ fontSize: 13, color: "#121212" }}>:</Text>
            <Text
              style={{
                fontSize: 13,
                color: "#121212",
                paddingLeft: 5,
              }}
            >
              {pendingTransaction.bse_order_number}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            marginVertical: 4,
            marginHorizontal: 8,
            flexWrap: "wrap",
          }}
        >
          <Text style={{ fontSize: 13, color: "#A8A8A8", flex: 0.3 }}>
            Order Date
          </Text>
          <View
            style={{
              flexDirection: "row",
              flex: 0.7,
              alignItems: "flex-start",
            }}
          >
            <Text style={{ fontSize: 13, color: "#121212" }}>:</Text>
            <Text
              style={{
                fontSize: 13,
                color: "#121212",
                paddingLeft: 5,
              }}
            >
              {order_date}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            marginVertical: 4,
            marginHorizontal: 8,
            flexWrap: "wrap",
          }}
        >
          <Text style={{ fontSize: 13, color: "#A8A8A8", flex: 0.3 }}>
            Status
          </Text>
          <View
            style={{
              flexDirection: "row",
              flex: 0.7,
              alignItems: "flex-start",
            }}
          >
            <Text style={{ fontSize: 13, color: "#121212" }}>:</Text>
            <Text
              style={{
                fontSize: 13,
                color: "#410DAA",
                paddingLeft: 5,
              }}
            >
              {pendingTransaction.status}
            </Text>
          </View>
        </View>
        {toggle ? (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              height: 30,
              marginHorizontal: 8,
              marginTop: 10,
            }}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 0.75 }}
            >
              <Text style={{ fontSize: 13, color: "#121212", marginRight: 10 }}>
                Did you pay?
              </Text>
              <Text style={{ fontSize: 11, color: "#a8a8a8", marginRight: 5 }}>
                Yes
              </Text>
              <Switch
                value={!this.state.Paidbool}
                trackColor={{
                  true: "#410DAA",
                  false: Platform.OS == "android" ? "#F8F8F8" : "#fbfbfb",
                }}
                thumbColor={[
                  Platform.OS == "ios"
                    ? "#FFFFFF"
                    : this.state.Paidbool
                    ? "#ffffff"
                    : "#ffffff",
                ]}
                ios_backgroundColor="#fbfbfb"
                style={{
                  transform: [
                    { scaleX: Platform.OS == "android" ? 1.5 : 1 },
                    { scaleY: Platform.OS == "android" ? 1.5 : 1 },
                  ],
                  marginHorizontal: 5,
                }}
                onValueChange={(value) => {
                  this.setState({ Paidbool: !value }, () => {
                    this._handleAction(!value);
                  });
                }}
              />
              <Text
                style={{
                  fontSize: 11,
                  color: !this.state.Paidbool ? "#410DAA" : "#a8a8a8",
                  fontWeight: !this.state.Paidbool ? "bold" : "normal",
                  paddingHorizontal: 2,
                  marginLeft: 10,
                }}
              >
                No
              </Text>
            </View>
            {/* {!this.state.Paidbool && (
              <TouchableOpacity
                style={{
                  borderRadius: 6,
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 5,
                  backgroundColor: "#410DAA",
                  alignSelf: "flex-end",
                  flex: 0.25,
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    color: "#fff",
                    paddingVertical: 10,
                    paddingHorizontal: 10,
                  }}
                >
                  Pay Now
                </Text>
              </TouchableOpacity>
            )} */}
          </View>
        ) : null}
      </View>
    );
  }
}
