import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  TextInput,
  FlatList,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Image,
  Linking,
} from "react-native";
import { Button } from "react-native-paper";
import Checkbox from "expo-checkbox";
import {
  getCustomerDetails,
  getHashKey,
  getUpcomingInvestmentsNew,
} from "../../api";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../api/apiConfig";
import { MAIN_URL } from "../../api/urls";
import {
  capitalizeFirstLetter,
  completeMinDetails,
  getProductName,
  getUserDetails,
  isMobile,
  isWeb,
  numberWithCommas,
  reverseString,
  toFixed,
  valueInWords,
} from "../../utils";
import { HorizontalLine } from "../Common/HorizontalLine";
import NavigationHeader from "../Common/NavigationHeader";
import ComplianceModal from "../InvestmentOverview/ComplianceModal";
import { StackActions } from "@react-navigation/native";
import { AntDesign } from "@expo/vector-icons";
import { ProductTabs } from "./ProductTabs";
import TextInputField from "../Common/Inputs/InputField";

import { ShowToast } from "../Common/Toast";
const { height } = Dimensions.get("window");
import amplitude from "../../utils/amplitude";
import Store from "../../store";

const Separator = ({ color, size }) => {
  return (
    <View
      style={{
        backgroundColor: color || "#C4C4C4",
        borderRadius: size / 2 || 2,
        width: size || 4,
        height: size || 4,
      }}
    ></View>
  );
};

const inputStyles = StyleSheet.create({
  installmentInputLabel: {
    fontSize: 14,
    color: "#212121",
    fontFamily: "Roboto",
  },
  installmentInput: {
    fontSize: 13,
    lineHeight: 30,
    paddingLeft: 12,
    width: 167,
    marginTop: 0,
    color: "#464E5F",
    fontWeight: "bold",
    maxWidth: 167,
    backgroundColor: "#F6F6F6",
  },
  installmentAmountRupeeSymbol: {
    color: "#464E5F",
    fontSize: 13,
    fontWeight: "bold",
    position: "absolute",
    left: 3,
    top: 7,
  },
  installmentAmountInputInWords: {
    color: "#464E5F90",
    fontStyle: "italic",
    fontSize: 12,
    fontFamily: "Roboto",
    marginTop: 6,
  },
  customAmountInputContainer: {
    alignSelf: "flex-end",
    alignItems: "flex-end",
    marginTop: 20,
  },
  maximumError: {
    color: "#F23F44",
    alignSelf: isMobile ? "flex-start" : "center",
    fontSize: 11,
    fontFamily: "Roboto",
    fontStyle: "italic",
    marginLeft: isMobile ? 0 : 6,
  },
});

const data = {
  upcoming_days: 745,
  investments: [
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4739,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 745 days",
      is_due: false,
      planned_date: "03 Jan 24",
      planned_date_formatted: "2024-01-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4738,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 714 days",
      is_due: false,
      planned_date: "03 Dec 23",
      planned_date_formatted: "2023-12-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4737,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 684 days",
      is_due: false,
      planned_date: "03 Nov 23",
      planned_date_formatted: "2023-11-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4736,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 653 days",
      is_due: false,
      planned_date: "03 Oct 23",
      planned_date_formatted: "2023-10-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4735,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 623 days",
      is_due: false,
      planned_date: "03 Sep 23",
      planned_date_formatted: "2023-09-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4734,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 592 days",
      is_due: false,
      planned_date: "03 Aug 23",
      planned_date_formatted: "2023-08-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4733,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 561 days",
      is_due: false,
      planned_date: "03 Jul 23",
      planned_date_formatted: "2023-07-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4732,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 531 days",
      is_due: false,
      planned_date: "03 Jun 23",
      planned_date_formatted: "2023-06-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4731,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 500 days",
      is_due: false,
      planned_date: "03 May 23",
      planned_date_formatted: "2023-05-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4730,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 470 days",
      is_due: false,
      planned_date: "03 Apr 23",
      planned_date_formatted: "2023-04-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4729,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 439 days",
      is_due: false,
      planned_date: "03 Mar 23",
      planned_date_formatted: "2023-03-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4728,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 411 days",
      is_due: false,
      planned_date: "03 Feb 23",
      planned_date_formatted: "2023-02-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4727,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 380 days",
      is_due: false,
      planned_date: "03 Jan 23",
      planned_date_formatted: "2023-01-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4726,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 349 days",
      is_due: false,
      planned_date: "03 Dec 22",
      planned_date_formatted: "2022-12-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4725,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 319 days",
      is_due: false,
      planned_date: "03 Nov 22",
      planned_date_formatted: "2022-11-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4724,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 288 days",
      is_due: false,
      planned_date: "03 Oct 22",
      planned_date_formatted: "2022-10-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4723,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 258 days",
      is_due: false,
      planned_date: "03 Sep 22",
      planned_date_formatted: "2022-09-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4722,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 227 days",
      is_due: false,
      planned_date: "03 Aug 22",
      planned_date_formatted: "2022-08-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4721,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 196 days",
      is_due: false,
      planned_date: "03 Jul 22",
      planned_date_formatted: "2022-07-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4720,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 166 days",
      is_due: false,
      planned_date: "03 Jun 22",
      planned_date_formatted: "2022-06-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4719,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 135 days",
      is_due: false,
      planned_date: "03 May 22",
      planned_date_formatted: "2022-05-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4718,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 105 days",
      is_due: false,
      planned_date: "03 Apr 22",
      planned_date_formatted: "2022-04-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4717,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 74 days",
      is_due: false,
      planned_date: "03 Mar 22",
      planned_date_formatted: "2022-03-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4716,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 46 days",
      is_due: false,
      planned_date: "03 Feb 22",
      planned_date_formatted: "2022-02-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4715,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "due in 15 days",
      is_due: false,
      planned_date: "03 Jan 22",
      planned_date_formatted: "2022-01-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4714,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "16 days overdue",
      is_due: true,
      planned_date: "03 Dec 21",
      planned_date_formatted: "2021-12-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4713,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "46 days overdue",
      is_due: true,
      planned_date: "03 Nov 21",
      planned_date_formatted: "2021-11-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4712,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "77 days overdue",
      is_due: true,
      planned_date: "03 Oct 21",
      planned_date_formatted: "2021-10-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4711,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "107 days overdue",
      is_due: true,
      planned_date: "03 Sep 21",
      planned_date_formatted: "2021-09-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4710,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "138 days overdue",
      is_due: true,
      planned_date: "03 Aug 21",
      planned_date_formatted: "2021-08-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 412,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 4709,
      investment_name: "S4 Plan",
      investment_type: "SIP",
      current_installment: 15,
      total_installment: 45,
      due_in: "169 days overdue",
      is_due: true,
      planned_date: "03 Jul 21",
      planned_date_formatted: "2021-07-03",
      planned_amount: 100000.0,
      product: "JWL",
      one_installment_amount: 100000.0,
      remaining_planned_amount: 3100000.0,
    },
    {
      cip_pk: 368,
      plan_start_date: "2021-02-03",
      plan_end_date: "2024-02-02",
      cipsid: 114430,
      investment_name: "T4 Plan",
      investment_type: "Staggered Lumpsum",
      current_installment: 34,
      total_installment: 34,
      due_in: "197 days overdue",
      is_due: true,
      planned_date: "05 Jun 21",
      planned_date_formatted: "2021-06-05",
      planned_amount: 197179.74999999994,
      product: "JWL",
      one_installment_amount: 100000,
      remaining_planned_amount: 197179.74999999994,
    },
  ],
  max_investment_amount: 3297179.75,
  min_investment_amount: 100000,
  demat_account: {
    JWL: "Zerodha",
  },
  product_min_max_amount: {
    JWL: {
      min_investment_amount: 100000,
      max_investment_amount: 3297179.75,
    },
    SRK: {
      min_investment_amount: 100000,
      max_investment_amount: 0,
    },
  },
};

const InstallmentCard = ({
  index,
  item,
  onSelectInstallment,
  selectedProductTab,
  selectedAmount,
  percentage,
  installmentAmount,
}) => {
  return (
    <TouchableOpacity
      style={[
        productStyles.installmentContainer,
        !!item.selectedForInvesting
          ? productStyles.activeBorder
          : productStyles.inActiveBorder,
        {
          backgroundColor: item.is_due ? "#FFF8E085" : "#F6F7FD",
          minWidth: isMobile ? 170 : 180,
          minHeight: isMobile ? 120 : 130,
        },
      ]}
      onPress={() => {
        onSelectInstallment(selectedProductTab, item.cipsid, item.cip_pk);
      }}
    >
      <>
        <View style={productStyles.installmentDetailsContainer}>
          <Text style={productStyles.currentInstallment}>
            <Text style={productStyles.installmentAmount}>
              ₹{installmentAmount}
            </Text>
          </Text>
          <Checkbox
            value={item.selectedForInvesting}
            onValueChange={() => {}}
            status={item.selectedForInvesting ? "checked" : "unchecked"}
            color={item.selectedForInvesting ? "#410DAA" : "gray"}
            style={{
              width: 15,
              height: 15,
            }}
          />
        </View>
        <Text
          style={[
            productStyles.plannedDate,
            { color: item.is_due ? "#EA3D2F" : "#410DAA" },
          ]}
        >
          <Text style={productStyles.installmentCount}>
            {item.current_installment}/{item.total_installment} -{" "}
          </Text>
          {item.planned_date}
        </Text>
        <View style={{ position: "absolute", bottom: 12, width: "90%" }}>
          <Text style={productStyles.selectedAmount}>+ ₹{selectedAmount}</Text>
          <View style={productStyles.amountIndicator}>
            <View
              style={[
                productStyles.activeAmountIndicator,
                {
                  width: `${percentage || 0}%`,
                },
              ]}
            />
          </View>
        </View>
      </>
    </TouchableOpacity>
  );
};

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
      toastTitle: "",
    };
  }

  getAllInstallments = (plans) => {
    const arrayOfIntallments = plans.map((plan) => {
      plan.installments.forEach((i) => {
        i.selectedForInvesting = false;
      });
      return plan.installments;
    });
    const flattenedArrayOfIntallments = this.flatten(arrayOfIntallments);
    return [...flattenedArrayOfIntallments];
  };

  flatten = (arr) =>
    arr.reduce((a, b) => a.concat(Array.isArray(b) ? this.flatten(b) : b), []);

  handleContinueToInvest = async (product) => {
    this.setState({
      showToast: false,
      toastTitle: "",
    });
    try {
      let url = MAIN_URL + "/app/gwsc/placeorder/";
      const { productwiseData: groupedData } = this.props;
      const productData = groupedData[product];
      let installmentsSelectedToInvest = [];
      productData.plans.map((plan, planIndex) => {
        plan.installments.forEach((installment) => {
          if (installment.selectedForInvesting) {
            installmentsSelectedToInvest.push({
              cipsid: installment.cipsid,
              planned_amount: installment.planned_amount,
              selectedAmount: parseInt(installment.selectedAmount),
            });
          }
        });
      });

      let urlPayload = "";
      const length = installmentsSelectedToInvest.length;
      installmentsSelectedToInvest.forEach((item, index) => {
        urlPayload += "CIPS" + item.cipsid + "_" + item.selectedAmount;
        if (index != length - 1) {
          urlPayload += "|";
        }
      });
      let payload = {
        cipamountstr: urlPayload,
        product,
        goal_id: this.props.customerGoalId,
      };

      if (Platform.OS == "android" || Platform.OS == "ios") {
        payload["mobile"] = true;
      }

      this.setState({ gettingHashKey: true });
      const response = await getHashKey(payload);
      if (response.code == 100) {
        const { hash_key } = response.result;
        let token = getTokenFromStore();
        token = reverseString(token);
        url =
          url +
          encodeURIComponent(hash_key + token + "/" + GATEWAY_ENVIRONMENT);
        if (Platform.OS == "web") {
          global.open(url, "_self");
        } else {
          this.props.navigation.dispatch(
            StackActions.replace("App", {
              screen: "Dashboard",
              params: {
                screen: "Dashboard",
                params: {
                  screen: "Gateway",
                  params: {
                    type: "PLACE_ORDER_TOPUP",
                    data: response.result,
                    screen: "TransactionStatus",
                  },
                },
              },
            })
          );
        }
      } else {
        if (response.code == 102) {
          this.setState({
            showToast: "true",
            toastTitle:
              response && response.msg
                ? response.msg
                : "Please enter valid amount.",
            gettingHashKey: false,
            productSelectedToInvest: null,
            topupErrorText:
              response && response.msg
                ? response.msg
                : "Please enter valid amount.",
          });
        } else {
          this.setState({
            showToast: "true",
            toastTitle:
              response && response.msg
                ? response.msg
                : "Please enter valid amount.",
            productSelectedToInvest: null,
            gettingHashKey: false,
            error: true,
            errorText:
              response && response.msg ? response.msg : "Something went wrong!",
          });
        }
      }
    } catch (error) {
      this.setState({
        gettingHashKey: false,
        error: true,
        errorText: "Something went wrong!",
        showToast: "true",
        toastTitle: "Something went wrong!",
      });
    }
  };

  handleSelectingInstallments = (product) => {
    const { productwiseData: groupedData, product_min_max_amount } = this.props;
    const productData = groupedData[product];
    if (productData) {
      let { plans } = productData;
      const allInstallments = this.getAllInstallments(plans);
      let sortedIntallments = sortInstallmentsInAscendingOrder(allInstallments);
      const installments = [...sortedIntallments];
      const { installmentAmountInput: enteredAmount } = productData;
      let amount = 0;
      const length = installments.length;

      const { min_investment_amount, max_investment_amount } =
        product_min_max_amount[product];
      let minAllowedAmount = min_investment_amount || 0;
      let maxAllowedAmount = max_investment_amount || 0;
      maxAllowedAmount = toFixed(maxAllowedAmount, 0);
      minAllowedAmount = toFixed(minAllowedAmount, 0);

      const validateMaxMin = true;

      if (
        (validateMaxMin &&
          maxAllowedAmount &&
          maxAllowedAmount < enteredAmount) ||
        (minAllowedAmount && minAllowedAmount > enteredAmount)
      ) {
        installments.every((installment, index) => {
          installment["selectedForInvesting"] = false;
          installment["selectedAmount"] = 0;
          return true;
        });
        productData["installmentAmountInput"] = 0;
        if (maxAllowedAmount && maxAllowedAmount < enteredAmount) {
          productData["error"] =
            "Your planned schedule allows a maximum of " +
            maxAllowedAmount +
            " only.\r\nUse Top-Up screen to invest ad-hoc or fresh investments outside this schedule";
        } else if (minAllowedAmount && minAllowedAmount > enteredAmount) {
          productData["error"] = "Minimum " + minAllowedAmount;
        }
      } else {
        installments.every((installment, index) => {
          let { selectedForInvesting } = installment;
          const prevAmount = amount;
          amount = amount + installment.planned_amount;
          if (parseInt(amount) < parseInt(enteredAmount)) {
            installment["selectedForInvesting"] = true;
            let selectedAmount = installment.planned_amount;
            if (index == length - 1) {
              const differenceInAmount = enteredAmount - prevAmount;
              selectedAmount = differenceInAmount;
            }
            installment["selectedAmount"] = selectedAmount;
          } else if (parseInt(amount) > parseInt(enteredAmount)) {
            if (parseInt(enteredAmount) > parseInt(prevAmount)) {
              const differenceInAmount = enteredAmount - prevAmount;
              installment["selectedForInvesting"] = differenceInAmount != 0;
              installment["selectedAmount"] = differenceInAmount || 0;
            } else {
              installment["selectedForInvesting"] = false;
              installment["selectedAmount"] = 0;
            }
          } else if (parseInt(amount) == parseInt(enteredAmount)) {
            installment["selectedForInvesting"] = true;
            installment["selectedAmount"] = installment.planned_amount;
          } else {
            installment["selectedForInvesting"] = false;
            installment["selectedAmount"] = 0;
          }

          return true;
        });
        productData["error"] = "  ";
      }

      const productwiseData = generateProductWiseDate(
        { investments: installments },
        true
      );
      this.props.setProductWiseData(productwiseData, product);
    }
  };

  handleInstallmentAmountInput = (amount, product) => {
    if (isNaN(amount)) return;
    amount = !isNaN(amount) ? Number(amount) : 0;
    const { productwiseData: groupedData = {}, product_min_max_amount } =
      this.props;
    if (groupedData[product]) {
      const productData = { ...groupedData[product] };
      if (productData) {
        const { min_investment_amount, max_investment_amount, installments } =
          product_min_max_amount[product];
        let minAllowedAmount = min_investment_amount || 0;
        let maxAllowedAmount = max_investment_amount || 0;
        maxAllowedAmount = toFixed(maxAllowedAmount, 0);
        minAllowedAmount = toFixed(minAllowedAmount, 0);
        const validateMaxMin = true;
        if (validateMaxMin && maxAllowedAmount && maxAllowedAmount < amount) {
          // productData["error"] = `Maximum ₹ ${numberWithCommas(
          //   maxAllowedAmount
          // )}`;
          productData[
            "error"
          ] = `Your planned schedule allows a maximum of ₹ ${numberWithCommas(
            maxAllowedAmount
          )} only.\r\nUse Top-Up screen to invest ad-hoc or fresh investments outside this schedule`;
        } else if (
          validateMaxMin &&
          minAllowedAmount &&
          amount < minAllowedAmount
        ) {
          productData["error"] = `Minimum ₹ ${numberWithCommas(
            minAllowedAmount
          )}`;
        } else {
          productData["error"] = "  ";
        }

        productData["installmentAmountInput"] = amount;
        groupedData[product] = productData;
        this.props.setProductWiseData(groupedData, product);
      }
    }
  };

  handleStartNewPlan = () => {
    global.open("https://maxiomwealth.com/contact-us/", "_blank");
  };

  selectInstallment = (product, cipsid, cip_pk) => {
    const { productwiseData: groupedData } = this.props;
    if (groupedData[product]) {
      let { plans } = groupedData[product];
      const planIndex = plans.findIndex((plan) => plan.cip_pk == cip_pk);
      if (planIndex == -1) return;
      const productData = { ...plans[planIndex] };
      if (productData) {
        const index = productData.installments.findIndex(
          (item) => item.cipsid == cipsid
        );
        if (index != -1) {
          const { selectedForInvesting, planned_amount, selectedAmount } =
            productData.installments[index];
          productData.installments[index]["selectedForInvesting"] =
            !selectedForInvesting;
          productData.installments[index]["selectedAmount"] =
            selectedForInvesting ? 0 : planned_amount || 0;
          groupedData[product]["error"] = "  ";
          groupedData[product]["plans"][planIndex] = productData;

          let installmentAmountInput = 0;
          let numberOfInstallmentsSelected = 0;
          groupedData[product]["plans"].forEach((plan, planIndex) => {
            plan.installments.forEach((installment) => {
              if (installment.selectedForInvesting) {
                installmentAmountInput += installment.selectedAmount;
                numberOfInstallmentsSelected += 1;
              }
            });
          });

          groupedData[product]["installmentAmountInput"] = toFixed(
            installmentAmountInput,
            0
          );
          groupedData[product]["numberOfInstallmentsSelected"] =
            numberOfInstallmentsSelected;
          this.props.setProductWiseData(groupedData, product);
        }
      }
    }
  };

  renderPlanInstallments = ({ item, index }) => {
    const installmentAmount = numberWithCommas(toFixed(item.planned_amount, 0));
    const selectedAmount = numberWithCommas(toFixed(item.selectedAmount, 0));
    let percentage = Math.ceil(
      (item.selectedAmount / item.planned_amount) * 100
    );
    percentage = percentage > 100 ? 100 : percentage;
    const { product } = this.props;
    return (
      <InstallmentCard
        index={index}
        item={item}
        onSelectInstallment={(product, cipsid, cip_pk) => {
          this.selectInstallment(product, item.cipsid, item.cip_pk);
        }}
        selectedProductTab={product}
        selectedAmount={selectedAmount}
        percentage={percentage}
        installmentAmount={installmentAmount}
      />
    );
  };

  render() {
    const { product, data: selectedProductData } = this.props;
    return product == this.props.selectedProduct &&
      selectedProductData &&
      selectedProductData.plans &&
      selectedProductData.plans.length >= 1 ? (
      <View
        style={[
          productStyles.productCard,
          Platform.OS == "web" && webStyles.border,
          Platform.OS != "web" && productStyles.border,
        ]}
      >
        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title={this.state.toastTitle}
          />
        ) : null}
        <View style={productStyles.basicDetailsContainer}>
          {/* Product and Number of plans */}
          <View style={productStyles.basicDetails}>
            <Text style={productStyles.product}>{getProductName(product)}</Text>
            {selectedProductData &&
              selectedProductData.plans &&
              selectedProductData.plans.length && (
                <>
                  <Separator color="#C4C4C4" size={4} />
                  <View
                    style={{
                      display: "flex",
                      width: isMobile ? "80%" : "90%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text style={productStyles.numberOfPlans}>
                        {selectedProductData.plans.length +
                          " Active Plan" +
                          (selectedProductData.plans.length > 1 ||
                          selectedProductData.plans.length == 0
                            ? "s"
                            : "")}
                      </Text>
                    </View>
                    {!isMobile && (
                      <TouchableOpacity
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                        onPress={() => {
                          if (Platform.OS == "web") {
                            let newTab = window.open();
                            newTab.location.href =
                              "https://maxiomwealth.com/blog/how-to-invest-in-jamawealth-portfolio/";
                          }
                        }}
                      >
                        <Image
                          source={require("../../assets/how_to.png")}
                          resizeMode="contain"
                          style={{
                            height: 18,
                            width: 18,
                            marginRight: 8,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#410DAA",
                            fontWeight: "700",
                          }}
                        >
                          How to Invest
                        </Text>
                      </TouchableOpacity>
                    )}
                  </View>
                </>
              )}
          </View>
          {isMobile && (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                marginBottom: 10,
              }}
              onPress={() => {
                if (Platform.OS == "web") {
                  let newTab = window.open();
                  newTab.location.href =
                    "https://maxiomwealth.com/blog/how-to-invest-in-jamawealth-portfolio/";
                } else {
                  this.props.navigation.navigate("App", {
                    screen: "Dashboard",
                    params: {
                      screen: "Portfolio",
                      params: {
                        screen: "ShowWebView",
                        params: {
                          url: `https://maxiomwealth.com/blog/how-to-invest-in-jamawealth-portfolio/`,
                        },
                      },
                    },
                  });
                }
              }}
            >
              <Image
                source={require("../../assets/how_to.png")}
                resizeMode="contain"
                style={{
                  height: 18,
                  width: 18,
                  marginRight: 8,
                }}
              />
              <Text
                style={{
                  fontSize: 14,
                  color: "#410DAA",
                  fontWeight: "700",
                }}
              >
                How to Invest
              </Text>
            </TouchableOpacity>
          )}
          {/* Custom installment amount input */}
          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: "#E5EAEE",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start" : "center",
              paddingHorizontal: isMobile ? 12 : 38,
              paddingBottom: isMobile ? 0 : 10,
            }}
          >
            <Text style={inputStyles.installmentInputLabel}>
              Click on cards below to select installments (or) enter custom
              investment amount here
            </Text>
            <View
              style={{
                flexDirection: isMobile ? "column" : "row",
                alignSelf: "flex-start",
                alignItems: isMobile ? "flex-start" : "center",
                paddingLeft: isMobile ? 0 : 6,
                paddingTop: isMobile ? 6 : 0,
              }}
            >
              <View>
                {!isMobile && <Text>{"  "}</Text>}
                <TextInputField
                  label={null}
                  prefix="₹"
                  value={
                    selectedProductData["installmentAmountInput"].toString() ||
                    0
                  }
                  onChange={(amount) => {
                    this.handleInstallmentAmountInput(amount, product);
                  }}
                  style={[{ width: 167 }]}
                  editable={true}
                  onBlur={() => {
                    this.handleSelectingInstallments(product);
                  }}
                  keyboardType={"number-pad"}
                />
                <Text style={inputStyles.installmentAmountInputInWords}>
                  {selectedProductData["installmentAmountInput"]
                    ? "Rupees " +
                      capitalizeFirstLetter(
                        valueInWords(
                          parseInt(
                            selectedProductData["installmentAmountInput"]
                          )
                        )
                      ) +
                      " only"
                    : "  "}
                </Text>
              </View>
              <Text style={inputStyles.maximumError}>
                {selectedProductData.error || "  "}
              </Text>
            </View>
          </View>
        </View>
        {selectedProductData &&
          selectedProductData.plans.map((item, index) => {
            return (
              <View style={productStyles.planContainer}>
                {/* Plan Header */}
                <View style={productStyles.planHeader}>
                  <View>
                    <Text
                      style={{
                        color: "#464E5F",
                        fontSize: !isMobile ? 14 : 13,
                        fontWeight: "bold",
                      }}
                    >
                      Plan #{item.cip_pk} - {item.investment_name} -
                      <Text
                        style={{
                          fontSize: !isMobile ? 14 : 13,
                          color: "#464E5F",
                          fontWeight: "normal",
                          paddingLeft: 5,
                        }}
                      >
                        {item.investment_type}
                      </Text>
                    </Text>
                  </View>
                </View>
                {/* Installments List */}
                <FlatList
                  horizontal={true}
                  data={item.installments || []}
                  renderItem={this.renderPlanInstallments}
                  keyExtractor={(item, index) =>
                    `product_${product}_plan${index}`
                  }
                  contentContainerStyle={{
                    paddingBottom: 17,
                  }}
                  persistentScrollbar
                />
                <HorizontalLine />
              </View>
            );
          })}

        <HorizontalLine />
        <View style={productStyles.continueToInvestContainer}>
          <View style={productStyles.totalInvestmentContainer}>
            {selectedProductData && (
              <>
                <Text style={productStyles.totalInvestment}>
                  Total Investment Amount :{" "}
                </Text>
                <View style={{ flexWrap: "wrap" }}>
                  <Text style={productStyles.totalInvestmentAmount}>
                    {" "}
                    ₹
                    {numberWithCommas(
                      selectedProductData.installmentAmountInput
                    )}{" "}
                    {!!selectedProductData.numberOfInstallmentsSelected && (
                      <Text style={productStyles.selectedInstallments}>
                        towards{" "}
                        {selectedProductData.numberOfInstallmentsSelected}{" "}
                        instalment
                        {selectedProductData.numberOfInstallmentsSelected > 1
                          ? "s"
                          : ""}
                        .
                      </Text>
                    )}
                  </Text>
                  <Text style={productStyles.valueInWords}>
                    {selectedProductData &&
                    selectedProductData.installmentAmountInput
                      ? "Rupees " +
                        capitalizeFirstLetter(
                          valueInWords(
                            parseInt(
                              selectedProductData["installmentAmountInput"]
                            )
                          )
                        ) +
                        " only"
                      : "  "}
                  </Text>
                </View>
              </>
            )}
          </View>
          <Button
            mode="contained"
            color="#410DAA"
            uppercase={false}
            style={productStyles.continueToInvestButton}
            disabled={
              !(
                selectedProductData &&
                selectedProductData.installmentAmountInput
              )
            }
            onPress={() => this.handleContinueToInvest(this.props.product)}
            loading={this.state.gettingHashKey}
          >
            Continue to Invest in {getProductName(product)}
            <AntDesign
              name="arrowright"
              color="#fff"
              size={16}
              style={{ marginLeft: 16 }}
            />
          </Button>
        </View>
      </View>
    ) : (
      product == this.props.selectedProduct && (
        <View
          style={[
            productStyles.productCard,
            Platform.OS == "web" && webStyles.border,
            Platform.OS != "web" && productStyles.border,
          ]}
        >
          <View style={productStyles.basicDetailsContainer}>
            <Text
              style={{
                fontSize: 15,
                fontFamily: "Roboto",
                fontWeight: "600",
                textAlign: "center",
                marginTop: isMobile ? 10 : 5,
              }}
            >
              No pending schedules.
            </Text>
            <Text
              style={{
                fontSize: 15,
                fontFamily: "Roboto",
                fontWeight: "600",
                textAlign: "center",
                marginTop: 20,
                marginBottom: 30,
              }}
            >
              <u
                style={{
                  color: "#410daa",
                }}
                onClick={() =>
                  global.open("https://maxiomwealth.com/contact-us/", "_blank")
                }
              >
                Click here
              </u>{" "}
              to open a new Plan in {getProductName(this.props.selectedProduct)}
              .
            </Text>
          </View>
        </View>
      )
    );
  }
}

const webStyles = StyleSheet.create({
  border: {
    borderWidth: 1,
    borderColor: "rgba(65, 13, 170, 0.1)",
    // boxSizing: "border-box",
    // //boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  },
});

const productStyles = StyleSheet.create({
  productCard: {
    borderRadius: 12,
    maxWidth: 1200,
    marginBottom: 32,
    flexGrow: 1,
  },
  border: {
    borderWidth: 1,
    borderColor: "#0000001A",
    // elevation: 1,
    // shadowColor: "#0000001A",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // borderWidth: 1,
    // borderColor: "rgba(65, 13, 170, 0.1)",
  },
  basicDetailsContainer: {
    width: "100%",
    height: "auto",
    paddingTop: isMobile ? 16 : 26,
  },
  basicDetails: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 13,
    paddingHorizontal: isMobile ? 12 : 38,
  },
  plansContainer: {
    flexGrow: 1,
  },
  product: {
    color: "#464E5F",
    fontSize: 24,
    fontWeight: "700",
    marginRight: 8,
  },
  numberOfPlans: {
    color: "#410DAA",
    marginLeft: 8,
    fontSize: 15,
    fontWeight: "700",
  },

  installmentContainer: {
    borderRadius: 2,
    padding: 8,
    marginRight: 15,
    backgroundColor: "#F6F7FD",
    width: 191,
  },
  activeBorder: {
    borderWidth: 1,
    borderColor: "#410DAA",
  },
  inActiveBorder: {
    borderWidth: 1,
    borderColor: "transparent",
  },
  installmentDetailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  currentInstallment: {
    fontFamily: "Roboto",
    marginRight: 35,
    color: "#4A4B68",
    fontSize: 15,
  },
  installmentAmount: {
    paddingLeft: 2,
    fontWeight: "bold",
  },
  plannedDate: {
    fontFamily: "Roboto",
    color: "#410DAA",
    fontStyle: "italic",
    fontSize: 13,
    marginBottom: 30,
  },
  installmentCount: {
    color: "#4A4B68",
  },
  selectedAmount: {
    color: "#121212",
    fontSize: 13,
    marginBottom: 4,
    fontFamily: "Roboto",
  },
  amountIndicator: {
    backgroundColor: "#410DAA20",
    borderRadius: 5,
    height: 2,
  },
  activeAmountIndicator: {
    backgroundColor: "#410DAA",
    height: 2,
  },
  nothingFoundContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 50,
  },
  startNewPlanContainer: {
    backgroundColor: "#410DAA",
    paddingHorizontal: 30,
    paddingVertical: 12,
    marginTop: 25,
  },
  startNewPlanText: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
  },

  planContainer: {
    marginTop: isMobile ? 15 : 24,
    marginHorizontal: isMobile ? 16 : 35,
  },
  planHeader: {
    marginBottom: isMobile ? 5 : 17,
    flexDirection: isMobile ? "column" : "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  continueToInvestContainer: {
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    paddingVertical: isMobile ? 15 : 24,
    paddingHorizontal: isMobile ? 16 : 35,
    justifyContent: isMobile ? "flex-start" : "space-between",
  },
  totalInvestmentContainer: {
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "flex-start",
  },
  totalInvestment: {
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "700",
    fontFamily: "Roboto",
  },
  totalInvestmentAmount: {
    color: "#410DAA",
    fontWeight: "700",
    fontStyle: "italic",
  },
  valueInWords: {
    fontSize: 13,
    color: "#464E5F80",
    fontFamily: "Roboto",
    marginTop: 10,
    flexWrap: "wrap",
    width: "80%",
  },
  plannedAmount: {
    marginTop: 10,
    color: "#464E5F70",
    fontSize: 13,
    fontFamily: "Roboto",
  },
  continueToInvestButton: {
    paddingHorizontal: isMobile ? 10 : 20,
    marginTop: isMobile ? 16 : 0,
  },
  selectedInstallments: {
    fontStyle: "italic",
    fontSize: 14,
    color: "#410DAA",
    paddingLeft: 8,
    fontWeight: "400",
  },
});

const generateProductWiseDate = (data, customInput = false) => {
  let productwiseData = {};
  let sortedIntallments = sortInstallmentsInAscendingOrder(data.investments);
  const productWisePlans = sortedIntallments.reduce((acc, item) => {
    acc[item.product] = acc[item.product] || [];
    const planIndex = acc[item.product].findIndex(
      (plan) => plan.cip_pk == item.cip_pk
    );
    const currentItemData = {
      current_installment: item.current_installment,
      total_installment: item.total_installment,
      planned_amount: item.planned_amount,
      planned_date: item.planned_date,
      selectedForInvesting: customInput
        ? item.selectedForInvesting
        : item.is_due,
      is_due: item.is_due,
      selectedAmount: customInput
        ? item.selectedAmount
        : item.is_due
        ? item.planned_amount
        : 0,
      ...item,
    };

    if (planIndex != -1) {
      const { installments = [] } = acc[item.product][planIndex];
      acc[item.product][planIndex]["installments"] = [
        ...installments,
        { ...currentItemData },
      ];
    } else {
      acc[item.product].push({
        cip_pk: item.cip_pk,
        plan_start_date: item.plan_start_date,
        plan_end_date: item.plan_end_date,
        investment_name: item.investment_name,
        investment_type: item.investment_type,
        installments: [currentItemData],
      });
    }

    return acc;
  }, {});

  const products = Object.keys(productWisePlans);

  products.forEach((product, index) => {
    let otherParams = { numberOfInstallmentsSelected: 0 };
    const plans = [...productWisePlans[product]];
    let installmentAmountInput = 0;
    let customInstallmentAmountInput = 0;
    plans.forEach((plan, index) => {
      let { installments } = plan;
      const length = installments ? installments.length : 0;
      if (!length) return;
      const lastInstallment = installments[0];
      if (!lastInstallment) return;
      const firstActiveInstallmentNumber =
        lastInstallment["current_installment"];
      let counter = 0;
      installments.forEach((installment, i) => {
        installment["current_installment"] =
          firstActiveInstallmentNumber + counter;
        counter += 1;
      });

      if (!customInput) {
        installmentAmountInput += installments.reduce((accum, item) => {
          return accum + (item.is_due ? item.planned_amount : 0);
        }, 0);
        const selectedInstallments = [
          ...installments.filter((item) => item.is_due),
        ];
        otherParams["numberOfInstallmentsSelected"] +=
          selectedInstallments.length;
      } else {
        const selectedInstallments = [
          ...installments.filter((item) => item.selectedForInvesting),
        ];
        otherParams["numberOfInstallmentsSelected"] +=
          selectedInstallments.length;
        selectedInstallments.forEach((installment, index) => {
          installmentAmountInput += installment.selectedAmount;
        });
      }
    });

    otherParams["installmentAmountInput"] = toFixed(installmentAmountInput, 0);

    //generate product wise data
    productwiseData[product] = {
      ...otherParams,
      plans,
    };
  });

  return productwiseData;
};

const sortInstallmentsInAscendingOrder = (installments) => {
  return installments.sort((a, b) => {
    const due_time1 = new Date(a.planned_date_formatted).getTime();
    const due_time2 = new Date(b.planned_date_formatted).getTime();
    return due_time1 - due_time2;
  });
};

class UpcomingInvestments extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let userId = null;
    let showTopupToast = null;
    let customerGoalId = null;
    let customerGoalName = null;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      showTopupToast = params.showTopupToast ? params.showTopupToast : null;
      customerGoalId = params.customerGoalId ? params.customerGoalId : null;
      customerGoalName = params.customerGoalName
        ? params.customerGoalName
        : null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      userId,
      data: null,
      selectedProduct: "JWL",
      showTopupToast,
      customerGoalId,
      customerGoalName,
    };
    this.scrollView = React.createRef();
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("UpcomingInvestments", {
          path: global.location.href,
        });
      } else {
        amplitude.logEvent("UpcomingInvestments", {
          path: this.props.navigation.getState().routeName,
        });
      }
      this.setState({ gettingComplianceData: true });
      const { userId } = this.state;
      let userData = {};
      if (userId) {
        let details = await getCustomerDetails(userId);
        if (details && details.pk) {
          userData = details;
        } else {
          userData = await getUserDetails();
        }
      } else {
        userData = await getUserDetails();
      }

      const { skip_subscription } = userData;
      const { params } = this.props.route;
      let skip_compliance = false;
      if (params) {
        skip_compliance = params.s || false;
      }
      const minDetailsResponse = await completeMinDetails(
        true,
        userData.customer_id,
        skip_compliance
      );
      if (!minDetailsResponse.needCompletion) {
        await this.loadUpcomingInvestments();
      } else {
        if (minDetailsResponse.error) {
          this.setState({
            error: true,
            errorText: "Something went wrong!",
          });
        } else {
          this.setState({
            minDetailsResponse,
            gettingComplianceData: false,
            showComplianceAlert: true,
          });
        }
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  loadUpcomingInvestments = async () => {
    try {
      const { userId } = this.state;
      const { first_name } = getUserDetails();
      const response = await getUpcomingInvestmentsNew(userId);
      if (response.code == 100) {
        let { data } = response.result;
        const productwiseData = generateProductWiseDate(data);
        const {
          demat_account,
          product_min_max_amount,
          product_list: tabsToShow,
        } = data;

        this.setState({
          first_name: first_name || " ",
          gettingComplianceData: false,
          productwiseData,
          demat_account,
          product_min_max_amount,
          tabsToShow,
          selectedProduct: tabsToShow && tabsToShow[0] && tabsToShow[0].value,
        });
      } else {
        this.setState({
          gettingComplianceData: false,
          error: true,
          errorText: response && response.msg ? response.msg : null,
        });
      }
    } catch (error) {
      this.setState({
        gettingComplianceData: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  setProductWiseData = (data, product) => {
    this.setState({
      productwiseData: {
        ...this.state.productwiseData,
        [product]: data[product],
      },
    });
  };

  switchToOldView = () => {
    this.props.navigation.navigate("UpcomingInvestmentsNew");
  };

  onSelectTab = (selectedTab) => {
    const { tabsToShow = [] } = this.state;
    let selectedProduct;

    switch (selectedTab) {
      case 0:
        if (tabsToShow.length) {
          selectedProduct = tabsToShow[0].value;
        }
        break;
      case 1:
        if (tabsToShow.length) {
          selectedProduct = tabsToShow[1].value;
        }
        break;
      case 2:
        if (tabsToShow.length) {
          selectedProduct = tabsToShow[2].value;
        }
        break;
    }
    this.setState({ selectedProduct });
    if (selectedProduct && Platform.OS == "web") {
      window.scrollTo({
        top: this.state[selectedProduct + "_top_distance"] - 30,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  handleScroll = ({
    nativeEvent: {
      layout: { x, y, width, height },
    },
  }) => {};

  render() {
    const {
      gettingComplianceData,
      showComplianceAlert,
      minDetailsResponse,
      error,
      errorText = "Something went wrong!",
      tabsToShow = [],
    } = this.state;

    if (error) {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <View style={{ flex: 1, maxWidth: 1500 }}>
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={[
                "Equity Plan",
                "Ongoing SIP and Lumpsum Investments",
              ]}
              onPressBack={() =>
                this.props.navigation.navigate("App", {
                  screen: "Dashboard",
                  params: {
                    screen: "Dashboard",
                    params: {
                      screen: "Home",
                      params: {},
                    },
                  },
                })
              }
            />
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText}
              </Text>
            </View>
          </View>
        </View>
      );
    }

    if (gettingComplianceData || showComplianceAlert) {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          {gettingComplianceData ? (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator size="large" color="#410DAA" />
            </View>
          ) : (
            <View style={{ flex: 1, maxWidth: 1500 }}>
              <NavigationHeader
                navigation={this.props.navigation}
                navigationHeader={[
                  "Equity Plan",
                  "Ongoing SIP and Lumpsum Investments",
                ]}
                onPressBack={() => {
                  this.props.navigation.navigate("InvestmentOverview");
                }}
              />
              <ComplianceModal
                forceLoad={true}
                navigation={this.props.navigation}
                route={this.props.route}
                isLoading={gettingComplianceData}
                minDetailsResponse={minDetailsResponse}
                redirectBackTo={"UpcomingInvestments"}
                onClose={() => {
                  this.props.navigation.dispatch(
                    StackActions.replace("App", {
                      screen: "Dashboard",
                      params: {
                        screen: "Dashboard",
                        params: { screen: "Home", params: {} },
                      },
                    })
                  );
                }}
                userId={this.state.userId}
                navigationOptions={{
                  stack: "Portfolio",
                  screen: "UpcomingInvestments",
                }}
              />
            </View>
          )}
        </View>
      );
    }
    const { productwiseData = {}, product_min_max_amount = {} } = this.state;
    if (!productwiseData) return;
    return (
      <View style={styles.container}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={[
            "Equity Plan",
            "Ongoing SIP and Lumpsum Investments",
          ]}
          style={{ width: "100%", maxWidth: 1500 }}
          onPressBack={() => {
            if(this.props.route.params.customerGoalName === "My Retirement Goal"){
              this.props.navigation.navigate("App", {
                  screen: "Goals",
              });

            } else {
            this.props.navigation.navigate("App", {
              screen: "Dashboard",
              params: {
                screen: "Dashboard",
                params: { screen: "Home", params: {} },
              },
            });
          }
            // this.props.navigation.navigate("App", {
            //   screen: "Dashboard",
            //   params: {
            //     screen: "Dashboard",
            //     params: { screen: "Home", params: {} },
            //   },
            // });
          }}
        ></NavigationHeader>
        {this.state.showTopupToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title={`Selected investment will be attached to ${this.state.customerGoalName}.`}
          />
        ) : null}
        {this.state.selectedProduct ? (
          <>
            <ProductTabs
              tabs={tabsToShow}
              onSelectTab={this.onSelectTab}
              data={productwiseData}
            />
            <FlatList
              ref={(c) => (this.scrollView = c)}
              extraData={[productwiseData]}
              data={tabsToShow}
              contentContainerStyle={styles.body}
              style={{ flexGrow: 1 }}
              keyExtractor={(item) => item.value}
              renderItem={({ item: product, index }) => {
                return (
                  <ProductCard
                    product={product.value}
                    customerGoalId={this.state.customerGoalId}
                    productwiseData={productwiseData}
                    selectedProduct={this.state.selectedProduct}
                    data={productwiseData[product.value]}
                    product_min_max_amount={product_min_max_amount}
                    setProductWiseData={this.setProductWiseData}
                    navigation={this.props.navigation}
                  />
                );
              }}
            />
          </>
        ) : (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              height: "80%",
              alignItems: "center",
              marginLeft: "5%",
              marginRight: "5%",
            }}
          >
            <Text>
              There are no plans available, please{" "}
              <Text
                style={{
                  textDecorationLine: "underline",
                  color: "#410DAA",
                  fontWeight: "bold",
                }}
                onPress={() => {
                  if (Platform.OS == "web") {
                    global.open(
                      "https://maxiomwealth.com/contact-us/",
                      "_blank"
                    );
                  } else {
                    Linking.openURL("https://maxiomwealth.com/contact-us/");
                  }
                }}
              >
                contact us
              </Text>{" "}
              to start a new plan.
            </Text>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 1500,
    backgroundColor: "#fff",
  },
  body: {
    paddingHorizontal: isMobile ? 16 : 39,
    paddingVertical: isMobile ? 16 : 32,
    backgroundColor: "#fff",
    height: height,
  },
  switcToText: {
    fontSize: 15,
    fontWeight: "700",
    fontFamily: "Roboto",
    color: "#464E5F99",
  },
  switchToView: {
    position: "absolute",
    right: 16,
  },
});

export default UpcomingInvestments;
