import React, {useRef, useState} from 'react';
import { StyleSheet, Text, View, Button, ScrollView, Platform, Dimensions,TouchableOpacity, Image } from 'react-native';
import {sm, md, lg, xl, twoxl} from '../../../Responsive'
import Step from './Step'
const window = Dimensions.get('window');
import { AntDesign } from '@expo/vector-icons'; 

export default function HowItWorks ({closeModal}) {
    return (
        <View style={styles.container}>
            <Text style={styles.heading}>How it works?</Text>
            
            <View style={styles.stepsContainer}>
                <Step number={1} name={'Select Investment Strategy'} color={'#663259'}></Step>
                <Step number={2} name={'Select Plan to add the Topup'} color={"#1BC5BD"}></Step>

                <Step number={3} name={'Enter additional Investment amount to Topup'} color={'#8950FC'} showLine={false}></Step>

            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        // backgroundColor: 'blue',
        marginTop: md(window.width) ? undefined: 8

    },

    heading: {
        color: '#212121',
        fontSize: 15,
        lineHeight:17,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingBottom:10
        
    },
    stepsContainer: {
        paddingTop: 34,
        paddingBottom: 34,
        paddingLeft: 21,
        borderRadius: 12,
        borderColor: '#B88FF5',
        borderWidth: 1,
        marginTop: 10
    }
})