import React from "react";
import {
  View,
  Text,
  ActivityIndicator,
  StyleSheet,
  Image,
  Platform,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import amplitude from "../../utils/amplitude";
import { getCustomerDetails, getRebalanceStripsData } from "../../api";
import { logOut } from "../../actions";
import { connect } from "react-redux";
import { completeMinDetails, getUserDetails, isMobile } from "../../utils";
import NavigationHeader from "../Common/NavigationHeader";
import ComplianceModal from "../InvestmentOverview/ComplianceModal";
import { StackActions } from "@react-navigation/native";
import { RebalancingIntro } from "./RebalancingIntro";
import { Button } from "react-native-paper";
import { AntDesign, Fontisto } from "@expo/vector-icons";
import Store from "../../store";
import { color } from "react-native-elements/dist/helpers";

const productImages = {
  JWL: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/jwl_rebalance.png",
  SRK: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Spark+(1).png",
  PRL: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/pearl.png",
};

class RebalanceStrips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("RebalanceStrips", { path: global.location.href });
      } else {
        amplitude.logEvent("RebalanceStrips", {
          path: this.props.navigation.getState().routeName,
        });
      }
      const { rebalanceResp } = this.props;
      const response = rebalanceResp;
      if (response && response.code == 100) {
        const { result } = response;
        this.setState({
          data: result,
        });
      }
    } catch (error) {
      this.setState({
        error: true,
      });
    }
  }

  handleIntroContinue = async (product) => {
    try {
      this.props.navigation.navigate("App", {
        screen: "Dashboard",
        params: {
          screen: "Portfolio",
          params: {
            screen: "CurrentHoldings",
            params: { product_name: product },
          },
        },
      });
    } catch (error) {}
  };

  render() {
    const { data } = this.state;
    let products = [];
    if (data) {
      products = Object.keys(data);
    }
    return (
      <View style={stripStyles.stripsContainer}>
          <View style={styles.stickyContainer}>
            <Text style={styles.stickyText}>
              Great news! On October 8, 2024, SEBI introduced a new rule allowing investors to access 100% of their sale proceeds on the same day, making rebalancing a one-step process!
            </Text>
          </View>
        {products.map((product) => {
          const {
            title,
            description,
            rebalance_scheduled,
            complete_percentage,
          } = data[product];
          const dayOnePending = complete_percentage == 0;
          return (
            <View
              style={[
                stripStyles.stripContainer,
                Platform.OS == "web" && webStyles.boxShadow,
              ]}
            >
              <View style={stripStyles.strip}>
                <View>
                  <Image
                    source={{ uri: productImages[product] }}
                    style={stripStyles.logo}
                    resizeMode="center"
                  />
                </View>
                <View style={stripStyles.detailsContainer}>
                  <Text style={stripStyles.title}>{title}</Text>
                  <Text
                    style={[
                      stripStyles.description,
                      !rebalance_scheduled && stripStyles.noRebalance,
                    ]}
                  >
                    {rebalance_scheduled
                      ? description
                      : "No Rebalance Scheduled"}
                  </Text>
                </View>
              </View>
              {/* {rebalance_scheduled && (
                <View style={stripStyles.indicatorContainer}>
                  <View style={stripStyles.indicator}>
                    <View
                      style={[
                        stripStyles.eightyPerc,
                        dayOnePending
                          ? stripStyles.pending
                          : stripStyles.complete,
                      ]}
                    >
                      <View style={stripStyles.circle} />
                    </View>
                    <View
                      style={[
                        stripStyles.twentyPerc,
                        dayOnePending ? stripStyles.next : stripStyles.pending,
                      ]}
                    >
                      <View style={stripStyles.circle} />
                    </View>
                  </View>
                  <View style={stripStyles.arrowsContainer}>
                    <View style={stripStyles.arrow1Container}>
                      <Fontisto
                        name="arrow-down-l"
                        size={24}
                        color="#0000004D"
                        style={stripStyles.arrow1}
                      />
                    </View>
                    <View style={stripStyles.arrow2Container}>
                      <Fontisto
                        name="arrow-down-l"
                        size={24}
                        color="#0000004D"
                        style={stripStyles.arrow1}
                      />
                    </View>
                  </View>
                  <View style={stripStyles.describeContainer}>
                    <View style={stripStyles.describeContainer1}>
                      <Text style={stripStyles.describe1}>
                        Rebalance {dayOnePending ? "Pending" : "Completed"} -{" "}
                        {dayOnePending ? 80 : complete_percentage.toFixed(1)}%
                        <AntDesign
                          name={
                            dayOnePending ? "exclamationcircle" : "checkcircle"
                          }
                          size={14}
                          color={dayOnePending ? "#EF6327" : "#58CDBE"}
                          style={stripStyles.infoIcon}
                        />
                      </Text>
                      <Text style={stripStyles.describe2}>Step 1</Text>
                    </View>
                    <View style={stripStyles.describeContainer2}>
                      <Text style={stripStyles.describe1}>
                        Rebalance {dayOnePending ? "Next Day" : "Pending"}-{" "}
                        {dayOnePending
                          ? 20
                          : 100 - complete_percentage.toFixed(1)}
                        %
                      </Text>
                      <Text style={stripStyles.describe2}>Step 2</Text>
                    </View>
                  </View>
                </View>
              )} */}
              <Button
                onPress={() =>
                  rebalance_scheduled && this.handleIntroContinue(product)
                }
                loading={this.state[product + "Loading"]}
                uppercase={false}
                style={[
                  stripStyles.button,
                  
                  rebalance_scheduled
                    ? stripStyles.active
                    : stripStyles.disabled,
                ]}
                labelStyle={{
                  fontSize: 15,
                  color: "#fff"
                }}
              >
                Rebalance Now
              </Button>
              <TouchableOpacity
                style={{
                  width: "100%",
                  marginTop: 20,
                  marginBottom: isMobile ? 10 : 0,
                  flexDirection: "row",
                  justifyContent: "center",
                }}
                onPress={() => {
                  if (Platform.OS == "web") {
                    let newTab = window.open();
                    newTab.location.href =
                      "https://maxiomwealth.com/blog/how-to-rebalance-your-portfolio-on-jamawealth/";
                  } else {
                    this.props.navigation.navigate("App", {
                      screen: "Dashboard",
                      params: {
                        screen: "Portfolio",
                        params: {
                          screen: "ShowWebView",
                          params: {
                            url: `https://maxiomwealth.com/blog/how-to-rebalance-your-portfolio-on-jamawealth/`,
                          },
                        },
                      },
                    });
                  }
                }}
              >
                <Image
                  source={require("../../assets/how_to.png")}
                  resizeMode="contain"
                  style={{
                    height: 18,
                    width: 18,
                    marginRight: 8,
                  }}
                />
                <Text
                  style={{
                    fontSize: 14,
                    color: "#410DAA",
                    fontWeight: "700",
                  }}
                >
                  How to rebalance portfolio
                </Text>
              </TouchableOpacity>
            </View>
          );
        })}
      </View>
    );
  }
}

const webStyles = {
  boxShadow: {},
};

const stripStyles = StyleSheet.create({
  stripsContainer: {
    alignSelf: "center",
    backgroundColor: "#fff",
    padding: isMobile ? 16 : 31,
    minWidth: isMobile ? "100%" : 650,
    maxWidth: isMobile ? "100%" : 650
  },
  stripContainer: {
    paddingHorizontal: isMobile ? 15 : 17,
    paddingVertical: isMobile ? 9 : 27,
    borderColor: "#410DAA1A",
    borderWidth: 1,
    borderRadius: 12,
    marginBottom: 31,
  },
  strip: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  logo: {
    width: isMobile ? 40 : 49,
    height: isMobile ? 40 : 49,
    resizeMode: "contain",
  },
  detailsContainer: {
    marginLeft: 22,
  },
  title: {
    color: "#464E5F",
    fontSize: isMobile ? 13 : 20,
    fontFamily: "Roboto",
    fontWeight: "bold",
    marginBottom: isMobile ? 8 : 9,
  },
  noRebalance: {
    color: "#464E5F",
  },
  description: {
    color: "#EF5D12",
    fontSize: isMobile ? 11 : 14,
    fontFamily: "Roboto",
  },
  button: {
    paddingVertical: isMobile ? 5 : 9,
    paddingHorizontal: isMobile ? 20 : 100,
    marginTop: 45,
    alignSelf: "center",
    width: isMobile ? "80%" : "80%",
    color:"#fff"
  },
  buttonText: {
    fontSize: 15,
  },
  active: {
    backgroundColor: "#410DAA",
    color: "#fff",
  },
  disabled: {
    backgroundColor: "#888C9F99",
    color: "#fff",
  },
  eightyPerc: {
    width: isMobile ? "65%" : "80%",
    height: isMobile ? 11 : 17,
    justifyContent: "center",
  },
  twentyPerc: {
    width: isMobile ? "35%" : "20%",
    height: isMobile ? 11 : 17,
    justifyContent: "center",
  },
  circle: {
    width: 14,
    height: 14,
    borderRadius: 7,
    backgroundColor: "#fff",
    marginLeft: 45,
  },
  indicatorContainer: {
    marginTop: isMobile ? 40 : 50,
  },
  indicator: {
    width: "100%",
    borderRadius: 12,
    flexDirection: "row",
    alignItems: "center",
    height: 17,
    borderRadius: 12,
    overflow: "hidden",
  },
  pending: {
    backgroundColor: "#EF6327",
  },
  next: {
    backgroundColor: "#FEE7DD",
  },
  complete: {
    backgroundColor: "#58CDBE",
  },
  describeContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  describeContainer1: {
    width: isMobile ? "55%" : "65%",
  },
  describeContainer2: {
    width: isMobile ? "45%" : "35%",
  },
  describe1: {
    fontSize: isMobile ? 11 : 14,
    fontFamily: "Roboto",
    color: "#464E5F",
    fontWeight: "bold",
    width: isMobile ? "90%" : "100%",
    marginBottom: isMobile ? 8 : 10,
  },
  describe2: {
    fontSize: isMobile ? 11 : 12,
    fontFamily: "Roboto",
    color: "#464E5F",
  },
  arrowsContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  arrow1Container: {
    width: isMobile ? "65%" : "80%",
  },
  arrow2Container: {
    width: isMobile ? "35%" : "20%",
  },
  arrow1: {
    marginLeft: 40,
  },
  arrow2: {
    marginLeft: 20,
  },
  infoIcon: {
    paddingLeft: 7,
  },
});

class RebalancingFlow extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let userId = null;
    if (params) {
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      userId,
      loading: true,
      redirectToScreen: null,
      rebalanceResp: null,
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("RebalancingFlows", { path: global.location.href });
      } else {
        amplitude.logEvent("RebalancingFlows", {
          path: this.props.navigation.getState().routeName,
        });
      }
      const { userId } = this.state;
      let userData = {};
      if (userId) {
        let details = await getCustomerDetails(userId);
        if (details && details.pk) {
          userData = details;
        } else {
          userData = getUserDetails();
        }
      } else {
        userData = getUserDetails();
      }

      const { customer_id } = userData;

      const { params } = this.props.route;
      let skip_compliance = false;
      if (params) {
        skip_compliance = params.s || null;
      }
      const minDetailsResponse = await completeMinDetails(
        true,
        customer_id,
        skip_compliance
      );
      const rebalanceResp = await getRebalanceStripsData(userId);
      if (!minDetailsResponse.needCompletion) {
        this.setState({
          loading: false,
          rebalanceResp,
          showRebalanceIntro: true,
        });
      } else {
        this.setState({ minDetailsResponse, showComplianceAlert: true });
      }
    } catch (error) {
      if (error && error.errorCode == 401) {
        this.props.logOut();
      }
    }
  }

  handleIntroContinue = () => {
    this.setState({
      showStrips: true,
    });
  };

  render() {
    const {
      loading,
      showStrips = false,
      showRebalanceIntro,
      error,
      errorText,
      gettingComplianceData,
      showComplianceAlert,
      minDetailsResponse,
    } = this.state;

    if (showComplianceAlert) {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <View style={{ flex: 1, maxWidth: 1500 }}>
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={["Rebalancing"]}
              onPressBack={() => {
                this.props.navigation.navigate("App", {
                  screen: "Dashboard",
                  params: {
                    screen: "Dashboard",
                    params: {
                      screen: "Home",
                      params: {},
                    },
                  },
                });
              }}
            />
            <ComplianceModal
              forceLoad={true}
              navigation={this.props.navigation}
              route={this.props.route}
              isLoading={gettingComplianceData}
              minDetailsResponse={minDetailsResponse}
              redirectBackTo={"RebalanceLandingPage"}
              onClose={() => {
                this.props.navigation.dispatch(
                  StackActions.replace("App", {
                    screen: "Dashboard",
                    params: {
                      screen: "Dashboard",
                      params: { screen: "Home", params: {} },
                    },
                  })
                );
              }}
              userId={this.state.userId}
              navigationOptions={{
                stack: "Portfolio",
                screen: "RebalanceLandingPage",
              }}
            />
          </View>
        </View>
      );
    }
    if (error) {
      return (
        <View style={styles.container}>
          <Text style={{ fontSize: 15, color: "#121212" }}>{errorText}</Text>
        </View>
      );
    }
    if (loading) {
      return (
        <View style={styles.container}>
          <ActivityIndicator size="large" color="#410DAA" />
        </View>
      );
    }

    if (showRebalanceIntro) {
      return (
        <View style={styles.stripsContainer}>
          {/* {showStrips ? ( */}
            <>
              <NavigationHeader
                navigation={this.props.navigation}
                navigationHeader={["Porfolio Rebalance"]}
                onPressBack={() => {
                  this.props.navigation.navigate("App", {
                    screen: "Dashboard",
                    params: {
                      screen: "Dashboard",
                      params: {
                        screen: "Home",
                        params: {},
                      },
                    },
                  });
                }}
              />
              <ScrollView
                showsVerticalScrollIndicator={true}
                contentContainerStyle={styles.scrollView}
              >
                <RebalanceStrips
                  userId={this.state.userId}
                  navigation={this.props.navigation}
                  rebalanceResp={this.state.rebalanceResp}
                />
              </ScrollView>
            </>
          {/* ) : (
            <RebalancingIntro
              onContinue={this.handleIntroContinue}
              isLoading={false}
              navigation={this.props.navigation}
            />
          )} */}
        </View>
      );
    }

    return <View style={styles.container}></View>;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  stripsContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  scrollView: {
    flexGrow: 1,
  },
  stickyContainer: {
    backgroundColor: '#E8F5E9', // Light green background
    padding: 12,
    borderRadius: 8,
    marginBottom: 16,
    borderLeftWidth: 4,
    borderLeftColor: '#4CAF50', // Green accent
  },
  stickyText: {
    fontSize: 14,
    lineHeight: 20,
    color: '#1B5E20', // Dark green text
  },
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
});

export default connect(null, mapDispatchToProps)(RebalancingFlow);
