import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";

const Tab = ({ title, active, onPressTab }) => {
  return (
    <TouchableOpacity
      onPress={() => {
        onPressTab();
      }}
      style={{
        backgroundColor: active ? "#410DAA" : "#fff",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 16,
      }}
    >
      <Text style={{ fontSize: 12, color: active ? "white" : "#121212" }}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};
export const Tabs = ({ activeIndex, onPressTab, style }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-evenly",
        paddingVertical: 15,
        ...style,
      }}
    >
      <Tab
        title="Stocks"
        active={activeIndex == 1}
        onPressTab={() => {
          onPressTab(1);
        }}
      />
      <Tab
        title="Mutual Funds"
        active={activeIndex == 2}
        onPressTab={() => {
          onPressTab(2);
        }}
      />
      <Tab
        title="Assets"
        active={activeIndex == 3}
        onPressTab={() => {
          onPressTab(3);
        }}
      />
    </View>
  );
};
