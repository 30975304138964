import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  StatusBar,
} from "react-native";
import { md } from "../../../Responsive";
import Header from "../header_after_login";
import {
  completeMinDetails,
  getUserDetails,
  isMobile,
  isWeb,
  navigateToTransaction,
} from "../../../utils";
import RadioButton from "../../onboarding/Radiobutton";
const { width } = Dimensions.get("window");
import Progressbar from "../progressbar";
import {
  agreementEsign,
  getAdvisoryFeeDetails,
  getCipForRenewal,
  getCustomerDetails,
  getCustomerInvestmentPlans,
  getCustomerInvestmentPlansAdmin,
  updateDigioAgreementStatus,
} from "../../../api";
import { ShowToast } from "../../../components/Common/Toast";
import { getServerName } from "../../../api/urls";
import { StackActions } from "@react-navigation/routers";
import { SafeAreaView } from "react-native-safe-area-context";
import PlansScreen from "./plansScreen";
import Store from "../../../store";

export default class agreement_e_sign extends React.Component {
  constructor(props) {
    super(props);
    let user_id = null,
      actionId = null;
    const { params } = this.props.route;
    if (params) {
      user_id = params.user_id;
      user_id = isNaN(user_id) ? null : user_id;
      actionId = params.actionId || null;
    }
    this.state = {
      user_id,
      data: null,
      isLoading: true,
      actionId,
      ria_plan_details: [],
      show_eSignButton: true,
      plans_data: null,
    };
  }
  async componentDidMount() {
    let userId = Store.getState().dashboard &&
    Store.getState().dashboard.viewModeUserId &&
    Store.getState().dashboard.viewModeUserId.user_id
      ? Store.getState().dashboard.viewModeUserId.user_id
      : this.state.user_id
    this.setState({
      user_id: userId
    })
    const userIdToFetchDetails = this.state.user_id;
    
    const digioStatus = await updateDigioAgreementStatus(userIdToFetchDetails);
    if (digioStatus && digioStatus.code == "100") {
      this.setState({ isLoading: false }, () => {
        this.moveToNextScreen();
        return;
      });
    } 
    
      
    const getcipforRenewalRes = await getCipForRenewal(userId);
    // console.log("Value of is "+JSON.stringify(getcipforRenewalRes))
    this.setState({
      plans_data: getcipforRenewalRes
    })
    let response;
    if (this.state.user_id != null) {
      response = await getCustomerInvestmentPlansAdmin(this.state.user_id);
    } else {
      response = await getCustomerInvestmentPlans();
    }
    let plan_details = [];
    if (response && response.length > 0) {
      plan_details = [...response];
    }
    let userData = {};
    if (this.state.user_id) {
      let details = await getCustomerDetails(this.state.user_id);
      if (details && details.pk) {
        userData = details;
      } else {
        userData = getUserDetails();
      }
    } else {
      userData = getUserDetails();
    }
    const { email, mobilenumber, is_ria } = userData;
    const PROP = [
      {
        key: "EMAIL",
        text: "To my email ( ",
        text1: `${email}`,
        text3: " ), it is linked to my aadhar",
      },
      {
        key: "MOBILE",
        text: "To my mobile ( ",
        text1: `+91 ${mobilenumber}`,
        text3: " ), it is linked to my aadhar",
      },

      {
        key: "SMS",
        text: "To my mobile as SMS with link to sign",
      },
    ];

    let ria_plan_details = [];
    if (is_ria) {
      let feeResponse = await getAdvisoryFeeDetails();
      if (feeResponse && feeResponse.code == 100 && feeResponse.result) {
        ria_plan_details = feeResponse.result;
      }
    }

    let show_eSignButton = true;
    plan_details.forEach((item) => {
      if (item.plan_fee_perc == 0.0) {
        show_eSignButton = false;
      }
    });

    this.setState({
      isLoading: false,
      data: {
        radioOptions: PROP,
        plan_details,
      },
      ria_plan_details: ria_plan_details,
      is_ria,
      show_eSignButton: show_eSignButton,
    });
  }

  handleContinue = async () => {
    const { show_eSignButton } = this.state;
    if (show_eSignButton) {
      try {
        if (this.state.user_id) {
          let options = {};
          const { params } = this.props.route;
          if (params) {
            options = { ...params };
          }
          options["first"] = false;
          this.props.navigation.navigate("Payment", {
            ...options,
            actionId: this.state.actionId,
          });
          return;
        }
        const { sendMode } = this.state;
        if (!sendMode) {
          this.setState({
            error: true,
            errorText: "Please select one of the options",
          });
          return;
        }

        this.setState({ redirecting: true });
        const sign_type = sendMode == "SMS" ? "electronic" : "aadhaar";
        const sign_identifier_type =
          sendMode == "SMS"
            ? "mobile"
            : sendMode == "MOBILE"
            ? "mobilenumber"
            : "email";
        let is_digio = true;
        const server_name = getServerName(is_digio);
        const payload = {
          sign_type: sign_type,
          sign_identifier_type: sign_identifier_type,
          server_name,
        };

        const response = await agreementEsign(payload);
        if (response && response.code == 100) {
          const { results } = response;
          if (results && results.length > 0) {
            const { redirect_url } = results[0];
            if (redirect_url) {
              this.setState({ redirecting: false }, () => {
                if (Platform.OS == "web") {
                  global.open(redirect_url, "_self");
                } else {
                  const { params } = this.props.route;
                  let options = {};
                  if (params) {
                    options = { ...params };
                  }
                  this.props.navigation.navigate("App", {
                    screen: "Dashboard",
                    params: {
                      screen: "Portfolio",
                      params: {
                        screen: "ShowWebView",
                        params: {
                          url: redirect_url,
                          successURL: "https://app.jamawealth.com/digioredir",
                          userAgent: true,
                          onSuccess: function (navigation, results = {}) {
                            navigation.dispatch(
                              StackActions.replace("Onboarding", {
                                screen: "DigioRedirection",
                                params: { ...options },
                              })
                            );
                          },
                          onError: function (navigation, results = {}) {
                            // navigation.push("App", { screen: "Dashboard", params: { screen: "Home", params: {} } });
                          },
                        },
                      },
                    },
                  });
                }
              });
            } else {
              this.setState({
                redirecting: false,
                error: true,
                errorText: "Something went wrong",
              });
            }
          } else {
            this.setState({
              redirecting: false,
              error: true,
              errorText: "Something went wrong!",
            });
          }
        } else {
          this.setState({
            redirecting: false,
            error: true,
            errorText:
              response && response.msg ? response.msg : "Something went wrong!",
          });
        }
      } catch (error) {
        this.setState({
          error: true,
          redirecting: false,
          errorText: "Something went wrong!",
        });
      }
    }
  };

  handleDashBoardView = () => {
    let url = new URL(global.location.href);
    location.replace(
      url.origin +
        "/dashboard/home?user_id=" +
        this.state.user_id
    );
  }

  moveToNextScreen = async () => {
    try {
      this.setState({ isLoading: true });
      const minDetailsResponse = await completeMinDetails(null, null, null, false);
      const { needCompletion, error } = minDetailsResponse;

      if (!error) {
        const { redirect_screen } = minDetailsResponse;
        let options = {};
        if (this.props.route) {
          const { params } = this.props.route;
          if (params) {
            options = { ...params };
          }
          if (!needCompletion) {
            if (options.transaction) {
              navigateToTransaction(this.props.navigation, options.transaction);
            } else if (options.action) {
              this.props.navigation.navigate("ComplianceSuccess", {
                ...options,
              });
              return;
            } else {
              this.props.navigation.navigate("OnboardingSuccess", {
                ...options,
              });
              return;
            }
          }
        }
        this.props.navigation.navigate(redirect_screen, { ...options });
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Something went wrong! Please contact help@maxiom.in",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  };

  renderPlanDetails = (item) => {
    if (!item.plans_text) return <></>;
    return (
      <View>
        <Text
          style={{
            fontSize: 14,
            color: "#00000090",
            fontSize: 14,
            paddingHorizontal: 10,
            paddingVertical: 6,
          }}
        >
          {item.plans_text}
        </Text>
      </View>
    );
  };

  renderRiaFeeDetails = (item) => {
    return (
      <View>
        <Text
          style={{
            fontSize: 14,
            color: "#000",
            fontSize: 14,
            paddingHorizontal: 10,
            paddingVertical: 6,
          }}
        >
          {item.perc} - {item.desc}
        </Text>
      </View>
    );
  };

  render() {
    const {
      data,
      isLoading,
      sendMode,
      redirecting,
      errorText,
      ria_plan_details,
      is_ria,
      show_eSignButton,
    } = this.state;
    return (
      <SafeAreaView style={styles.container}>
        <Header navigation={this.props.navigation} route={this.props.route} />
        <StatusBar backgroundColor="#000" barStyle="dark-content" />
        <Progressbar
          color2="#410DAA"
          color3="#410daa"
          color4="#410DAA"
          color5="#410DAA"
          color6="#410DAA"
          color7="#410DAA"
          color8="#410DAA"
          color9="#f6f6f6"
          color10="#f6f6f6"
          color11="#f6f6f6"
          color12="#f6f6f6"
          bcolor1="#410DAA"
          bcolor2="#410DAA"
          bcolor3="#410DAA"
          bcolor4="#410DAA"
          bcolor5="#f6f6f6"
          bagcolor1="#410DAA"
          bagcolor2="#410DAA"
          bagcolor3="#410DAA"
          bagcolor4="#410DAA"
          bagcolor5="#ffffff"
          markdisplay1="none"
          markdisplay2="none"
          markdisplay3="none"
          markdisplay4="none"
          markdisplay5="none"
          dotdisplay1="none"
          dotdisplay2="none"
          dotdisplay3="none"
          dotdisplay4="none"
          dotdisplay5="none"
        />
        {!isLoading ? (
          <ScrollView >
            <View style={styles.body}>
              {isWeb && (
                <View style={styles.left}>
                  <Text style={styles.terms}> {this.state.plans_data.show_digio_esign ? "Agreement e-Sign" : "Confirm Investment Plans" }</Text>
                  
                  <Text style={styles.please}>
                    { this.state.plans_data.results.show_digio_esign ? "Per SEBI regulation, agreement must be signed before advisor provides service" : "Investment plans give you flexibility to invest in different strategies, schedule installments and track plan performance. " }
                  </Text>
                  <Image
                    source={
                      "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-2.png"
                    }
                    style={styles.left_img}
                  />
                  <View
                    style={{
                      flexDirection: "row",
                      width: 430,
                      borderWidth: 1,
                      borderColor: "rgba(65, 13, 170, 0.2)",
                      backgroundColor: "#ffffff",
                      paddingVertical: 21,
                      paddingLeft: 20,
                      paddingRight: 6,
                      marginTop: 33,
                      marginBottom: 22,
                    }}
                  >
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/fluent_lock-shield-20-regular.png"
                      }
                      style={styles.lock}
                    />
                    <Text style={styles.jam_we}>
                      Information is encrypted using 256-bit keys and stored
                      securely
                    </Text>
                  </View>
                  <Text style={styles.any}>
                    Any Queries?{" "}
                    <TouchableOpacity
                      onPress={() => {
                        this.props.navigation.navigate("App", {
                          screen: "Dashboard",
                          params: {
                            screen: "Dashboard",
                            params: {
                              screen: "GetHelp",
                              params: {},
                            },
                          },
                        });
                      }}
                    >
                      <Text style={{ color: "#410DAA", fontWeight: "bold" }}>
                        Get Help
                      </Text>
                    </TouchableOpacity>
                  </Text>
                </View>
              )}
              <View style={styles.right}>
                <View style={{ flexDirection: "column" }}>
                  <View style={styles.pend}>
                    <Text
                      style={{
                        fontSize: 24,
                        fontWeight: '700',
                      }}
                    >
                      {is_ria
                        ? ""
                        : "Investment Plan"}
                    </Text>
                  </View>
                  <PlansScreen 
                    pd={this.state.plans_data} 
                    navigation={this.props.navigation} 
                    user_id={this.state.user_id}
                    handleContinue={this.handleContinue}
                    handleDashboardEvent={this.handleDashBoardView}
                    showViewMode = { !(this.state.plans_data.results.show_digio_esign && this.state.user_id) }
                  />
                  {(this.state.plans_data.results.show_digio_esign) && (
                    <>
                    <Text style={styles.pls_con}>
                      Send the agreement for my e-Signature:
                    </Text>
                    <View style={styles.radio}>
                      <RadioButton
                        PROP={this.state.data.radioOptions}
                        onPress={(value) => {
                          this.setState({ sendMode: value });
                        }}
                        value={sendMode}
                      />
                    </View>
                    <Text style={styles.errorText}>{errorText || "  "}</Text>
                    {(Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id) ? (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            this.handleContinue();
                          }}
                        >
                          <Text style={styles.viewModeText}>
                            Next {">>>"}
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={this.handleDashBoardView}
                        >
                          <Text style={styles.viewModeText}>
                            Go to Dashboard {">>>"}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          this.handleContinue();
                        }}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: 40,
                        }}
                      >
                        {redirecting ? (
                          <ActivityIndicator size="large" color="#410DAA" />
                        ) : (
                          <>
                            {!show_eSignButton ? (
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontWeight: 600,
                                  paddingBottom: 10,
                                }}
                              >
                                Investment Plan Fee details are not updated by
                                your advisor yet. Kindly contact the advisor.
                              </Text>
                            ) : null}
                            <Text
                              style={[
                                styles.adhaar,
                                {
                                  backgroundColor: show_eSignButton
                                    ? "#410DAA"
                                    : "#aaaaaa",
                                },
                              ]}
                            >
                              Review & e-Sign Agreement
                            </Text>
                          </>
                        )}
                      </TouchableOpacity>
                    )}
                  </>
                  )}
                </View>
                
              </View>
            </View>
          </ScrollView>
        ) : (
          <View style={styles.loaderContainer}>
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: { width: "100%", flexGrow: 1, backgroundColor: "#fff" },
  mainContainer: {
    flex: 1
  },
  loaderContainer: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    flexDirection: "row",
    flex: 1,
    backgroundColor: "#ffffff",
    paddingLeft: md(width) ? 130 : 17,
    paddingRight: md(width) ? 100 : 17,
    paddingBottom: 60,
  },
  left: {
    // display: md(width) ? "none" : "none",
    flex: md(width) ? 0.45 : 0,
    paddingTop: md(width) ? 55 : 38,
  },
  right: {
    flex: md(width) ? 0.60 : 1,
  },
  left_img: {
    resizeMode: "contain",
    width: 375,
    height: 305,
  },
  lock: {
    resizeMode: "contain",
    width: 24.5,
    height: 34,
    alignSelf: "center",
  },
  terms: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 21,
    color: "#2F3044",
  },
  please: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#464E5F",
    paddingTop: 12,
    paddingBottom: 32,
    paddingRight: 100,
  },
  jam_we: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    paddingLeft: 16,
  },
  any: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 18,
    color: "#000000",
    paddingLeft: 150,
  },
  investment: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 19 : 15,
    lineHeight: md(width) ? 22 : 17,
    fontWeight: "bold",
    color: "#2F3044",
  },
  aggrement: {
    fontFamily: "Roboto",
    fontSize: md(width) ? 14 : 13,
    lineHeight: md(width) ? 16 : 21,
    fontWeight: "bold",
    color: "#8950FC",
    letterSpacing: 0.5,
    paddingTop: md(width) ? 0 : 31,
  },
  date: {
    fontFamily: "Roboto",
    fontSize: 11,
    lineHeight: 13,
    color: "#737178",
    letterSpacing: 0.5,
    paddingTop: 4,
  },
  viewModeText: {
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: 17,
    color: "#410DAA",
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  adhaar: {
    fontFamily: "Roboto",
    fontSize: 15,
    lineHeight: 17,
    color: "#ffffff",
    fontWeight: "bold",
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  plan: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 16,
    color: "#464E5F",
    letterSpacing: 0.5,
  },
  plan_item: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    color: "#000000",
    letterSpacing: 0.5,
  },
  inv_amt: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#464E5F",
    letterSpacing: 0.5,
    fontWeight: "bold",
  },
  inv_amt1: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 14,
    color: "#4A4B68",
    paddingTop: 9,
  },
  pls_con: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 15,
    lineHeight: 17,
    color: "#464E5F",
    paddingTop: 50,
    paddingBottom: 21,
  },
  radio: {
    paddingLeft: md(width) ? 40 : 0,
  },
  mob_plan: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    color: "#4A4B68",
    fontWeight: "bold",
  },
  mob_per: {
    fontFamily: "Roboto",
    color: "#4A4B68",
    fontSize: 12,
    lineHeight: 14,
    paddingTop: 9,
  },
  mob_man: {
    fontFamily: "Roboto",
    fontSize: 13,
    lineHeight: 15,
    fontWeight: "bold",
    color: "#121212",
  },
  pend: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 20.7,
    color: "#2F3044",
    paddingTop: isMobile ? 20 : 60,
    paddingBottom: 15,
  },
  errorText: {
    color: "red",
    fontSize: 15,
    marginBottom: 0,
    marginLeft: isMobile ? 0 : 40,
  },
});
