import React, { Component } from "react";
import {
  View,
  SafeAreaView,
  ScrollView,
  Platform,
  StyleSheet,
} from "react-native";
import { connect } from "react-redux";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { Button, Text } from "react-native-paper";
import PickerField from "../../../components/Common/Pickers/PickerField";
import TextInputField from "../../../components/Common/Inputs/InputField";

import amplitude from "../../../utils/amplitude";
import { validateInput } from "../../../utils/validateInput";
import theme from "../../../static/theme";
import {
  getUserDetails,
  isMobile,
  redirectToPendingScreen,
} from "../../../utils";
import { saveCustomerData } from "../../../api/account";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import Toast from "react-native-toast-message";
import Store from "../../../store";

const nationalities = [
  { value: "IN", label: "India" },
  { value: "001", label: "Afghanistan" },
  { value: "002", label: "Aland Islands" },
  { value: "003", label: "Albania" },
  { value: "004", label: "Algeria" },
  { value: "005", label: "American Samoa" },
  { value: "006", label: "Andorra" },
  { value: "007", label: "Angola" },
  { value: "008", label: "Anguilla" },
  { value: "009", label: "Antarctica" },
  { value: "010", label: "Antigua And Barbuda" },
  { value: "011", label: "Argentina" },
  { value: "012", label: "Armenia" },
  { value: "013", label: "Aruba" },
  { value: "014", label: "Australia" },
  { value: "015", label: "Austria" },
  { value: "016", label: "Azerbaijan" },
  { value: "017", label: "Bahamas" },
  { value: "018", label: "Bahrain" },
  { value: "019", label: "Bangladesh" },
  { value: "020", label: "Barbados" },
  { value: "021", label: "Belarus" },
  { value: "022", label: "Belgium" },
  { value: "023", label: "Belize" },
  { value: "024", label: "Benin" },
  { value: "025", label: "Bermuda" },
  { value: "026", label: "Bhutan" },
  { value: "027", label: "Bolivia" },
  { value: "BQ", label: "Bonaire, Sint Eustatius And Saba" },
  { value: "028", label: "Bosnia And Herzegovina" },
  { value: "029", label: "Botswana" },
  { value: "030", label: "Bouvet Island" },
  { value: "031", label: "Brazil" },
  { value: "032", label: "British Indian Ocean Territory" },
  { value: "033", label: "Brunei Darussalam" },
  { value: "034", label: "Bulgaria" },
  { value: "035", label: "Burkina Faso" },
  { value: "036", label: "Burundi" },
  { value: "037", label: "Cambodia" },
  { value: "038", label: "Cameroon" },
  { value: "039", label: "Canada" },
  { value: "040", label: "Cape Verde" },
  { value: "041", label: "Cayman Islands" },
  { value: "042", label: "Central African Republic" },
  { value: "043", label: "Chad" },
  { value: "044", label: "Chile" },
  { value: "045", label: "China" },
  { value: "046", label: "Christmas Island" },
  { value: "047", label: "Cocos (Keeling) Islands" },
  { value: "048", label: "Colombia" },
  { value: "049", label: "Comoros" },
  { value: "050", label: "Congo" },
  { value: "051", label: "Congo, The Democratic Republic Of The" },
  { value: "052", label: "Cook Islands" },
  { value: "053", label: "Costa Rica" },
  { value: "054", label: "Cote D'ivoire" },
  { value: "055", label: "Croatia" },
  { value: "056", label: "Cuba" },
  { value: "CW", label: "Curacao" },
  { value: "057", label: "Cyprus" },
  { value: "058", label: "Czech Republic" },
  { value: "059", label: "Denmark" },
  { value: "060", label: "Djibouti" },
  { value: "061", label: "Dominica" },
  { value: "062", label: "Dominican Republic" },
  { value: "063", label: "Ecuador" },
  { value: "064", label: "Egypt" },
  { value: "065", label: "El Salvador" },
  { value: "066", label: "Equatorial Guinea" },
  { value: "067", label: "Eritrea" },
  { value: "068", label: "Estonia" },
  { value: "069", label: "Ethiopia" },
  { value: "070", label: "Falkland Islands (Malvinas)" },
  { value: "071", label: "Faroe Islands" },
  { value: "072", label: "Fiji" },
  { value: "073", label: "Finland" },
  { value: "074", label: "France" },
  { value: "075", label: "French Guiana" },
  { value: "076", label: "French Polynesia" },
  { value: "077", label: "French Southern Territories" },
  { value: "078", label: "Gabon" },
  { value: "079", label: "Gambia" },
  { value: "078", label: "Georgia" },
  { value: "081", label: "Germany" },
  { value: "082", label: "Ghana" },
  { value: "083", label: "Gibraltar" },
  { value: "084", label: "Greece" },
  { value: "085", label: "Greenland" },
  { value: "086", label: "Grenada" },
  { value: "087", label: "Guadeloupe" },
  { value: "088", label: "Guam" },
  { value: "089", label: "Guatemala" },
  { value: "090", label: "Guernsey" },
  { value: "091", label: "Guinea" },
  { value: "092", label: "Guinea-Bissau" },
  { value: "093", label: "Guyana" },
  { value: "094", label: "Haiti" },
  { value: "095", label: "Heard Island And McDonald Islands" },
  { value: "097", label: "Honduras" },
  { value: "098", label: "Hong Kong" },
  { value: "099", label: "Hungary" },
  { value: "100", label: "Iceland" },
  { value: "102", label: "Indonesia" },
  { value: "103", label: "Iran, Islamic Republic Of" },
  { value: "104", label: "Iraq" },
  { value: "105", label: "Ireland" },
  { value: "106", label: "Isle Of Man" },
  { value: "107", label: "Israel" },
  { value: "108", label: "Italy" },
  { value: "109", label: "Jamaica" },
  { value: "110", label: "Japan" },
  { value: "111", label: "Jersey" },
  { value: "112", label: "Jordan" },
  { value: "113", label: "Kazakhstan" },
  { value: "114", label: "Kenya" },
  { value: "115", label: "Kiribati" },
  { value: "116", label: "Korea, Democratic People's Republic Of" },
  { value: "117", label: "Korea, Republic Of" },
  { value: "118", label: "Kuwait" },
  { value: "119", label: "Kyrgyzstan" },
  { value: "120", label: "Lao People's Democratic Republic" },
  { value: "121", label: "Latvia" },
  { value: "122", label: "Lebanon" },
  { value: "123", label: "Lesotho" },
  { value: "124", label: "Liberia" },
  { value: "125", label: "Libyan Arab Jamahiriya" },
  { value: "126", label: "Liechtenstein" },
  { value: "127", label: "Lithuania" },
  { value: "128", label: "Luxembourg" },
  { value: "129", label: "Macao" },
  { value: "130", label: "Macedonia, The Former Yugoslav Republic Of" },
  { value: "131", label: "Madagascar" },
  { value: "132", label: "Malawi" },
  { value: "133", label: "Malaysia" },
  { value: "134", label: "Maldives" },
  { value: "135", label: "Mali" },
  { value: "136", label: "Malta" },
  { value: "137", label: "Marshall Islands" },
  { value: "138", label: "Martinique" },
  { value: "139", label: "Mauritania" },
  { value: "140", label: "Mauritius" },
  { value: "141", label: "Mayotte" },
  { value: "142", label: "Mexico" },
  { value: "143", label: "Micronesia, Federated States Of" },
  { value: "144", label: "Moldova, Republic Of" },
  { value: "145", label: "Monaco" },
  { value: "146", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "147", label: "Montserrat" },
  { value: "148", label: "Morocco" },
  { value: "149", label: "Mozambique" },
  { value: "150", label: "Myanmar" },
  { value: "151", label: "Namibia" },
  { value: "152", label: "Nauru" },
  { value: "153", label: "Nepal" },
  { value: "154", label: "Netherlands" },
  { value: "155", label: "Netherlands Antilles" },
  { value: "156", label: "New Caledonia" },
  { value: "157", label: "New Zealand" },
  { value: "158", label: "Nicaragua" },
  { value: "159", label: "Niger" },
  { value: "160", label: "Nigeria" },
  { value: "161", label: "Niue" },
  { value: "162", label: "Norfolk Island" },
  { value: "163", label: "Northern Mariana Islands" },
  { value: "164", label: "Norway" },
  { value: "XX", label: "Not categorised" },
  { value: "164", label: "Oman" },
  { value: "ZZ", label: "Others" },
  { value: "166", label: "Pakistan" },
  { value: "167", label: "Palau" },
  { value: "168", label: "Palestinian Territory, Occupied" },
  { value: "169", label: "Panama" },
  { value: "170", label: "Papua New Guinea" },
  { value: "171", label: "Paraguay" },
  { value: "172", label: "Peru" },
  { value: "173", label: "Philippines" },
  { value: "174", label: "Pitcairn" },
  { value: "175", label: "Poland" },
  { value: "176", label: "Portugal" },
  { value: "177", label: "Puerto Rico" },
  { value: "178", label: "Qatar" },
  { value: "179", label: "Reunion Island" },
  { value: "180", label: "Romania" },
  { value: "181", label: "Russian Federation" },
  { value: "182", label: "Rwanda" },
  { value: "BL", label: "Saint Barthelemy" },
  { value: "183", label: "Saint Helena, Ascension And Tristanda Cunha" },
  { value: "184", label: "Saint Kitts And Nevis" },
  { value: "185", label: "Saint Lucia" },
  { value: "MF", label: "Saint Martin" },
  { value: "186", label: "Saint Pierre And Miquelon" },
  { value: "187", label: "Saint Vincent And The Grenadines" },
  { value: "188", label: "Samoa" },
  { value: "189", label: "San Marino" },
  { value: "190", label: "Sao Tome And Principe" },
  { value: "191", label: "Saudi Arabia" },
  { value: "192", label: "Senegal" },
  { value: "193", label: "Serbia" },
  { value: "194", label: "Seychelles" },
  { value: "195", label: "Sierra Leone" },
  { value: "196", label: "Singapore" },
  { value: "SX", label: "Sint Maarten (Dutch Part)" },
  { value: "197", label: "Slovakia" },
  { value: "198", label: "Slovenia" },
  { value: "199", label: "Solomon Islands" },
  { value: "200", label: "Somalia" },
  { value: "201", label: "South Africa" },
  { value: "202", label: "South Georgia And The South Sandwich Islands" },
  { value: "SS", label: "South Sudan" },
  { value: "203", label: "Spain" },
  { value: "204", label: "Sri Lanka" },
  { value: "205", label: "Sudan" },
  { value: "206", label: "Suriname" },
  { value: "207", label: "Svalbard And Jan Mayen Islands" },
  { value: "208", label: "Swaziland" },
  { value: "209", label: "Sweden" },
  { value: "210", label: "Switzerland" },
  { value: "211", label: "Syrian Arab Republic" },
  { value: "212", label: "Taiwan, Province Of China" },
  { value: "213", label: "Tajikistan" },
  { value: "214", label: "Tanzania, United Republic Of" },
  { value: "215", label: "Thailand" },
  { value: "216", label: "Timor-Leste" },
  { value: "217", label: "Togo" },
  { value: "218", label: "Tokelau" },
  { value: "219", label: "Tonga" },
  { value: "220", label: "Trinidad And Tobago" },
  { value: "221", label: "Tunisia" },
  { value: "222", label: "Turkey" },
  { value: "223", label: "Turkmenistan" },
  { value: "224", label: "Turks And Caicos Islands" },
  { value: "225", label: "Tuvalu" },
  { value: "225", label: "Uganda" },
  { value: "227", label: "Ukraine" },
  { value: "228", label: "United Arab Emirates" },
  { value: "229", label: "United Kingdom" },
  { value: "230", label: "United States of America" },
  { value: "231", label: "United States Minor Outlying Islands" },
  { value: "232", label: "Uruguay" },
  { value: "233", label: "Uzbekistan" },
  { value: "234", label: "Vanuatu" },
  { value: "VA", label: "Vatican City State" },
  { value: "235", label: "Venezuela, Bolivarian Republic Of" },
  { value: "236", label: "Viet Nam" },
  { value: "237", label: "Virgin Islands, British" },
  { value: "238", label: "Virgin Islands, U.S." },
  { value: "239", label: "Wallis And Futuna" },
  { value: "240", label: "Western Sahara" },
  { value: "241", label: "Yemen" },
  { value: "242", label: "Zambia" },
  { value: "243", label: "Zimbabwe" },
];

const address_options = [
  { value: "DL", label: "Driving License" },
  { value: "PPT", label: "Passport" },
  { value: "AAC", label: "Aadhaar Card" },
  { value: "VOT", label: "Voter ID" },
];

const states = [
  {
    label: "Andaman & Nicobar Islands",
    value: "AN",
  },
  {
    label: "Andhra Pradesh",
    value: "AP",
  },
  {
    label: "Arunachal Pradesh",
    value: "AR",
  },
  {
    label: "Assam",
    value: "AS",
  },
  {
    label: "Bihar",
    value: "BH",
  },
  {
    label: "chattisgarh",
    value: "CG",
  },
  {
    label: "Chandigarh",
    value: "CH",
  },
  {
    label: "Daman & Diu",
    value: "DD",
  },
  {
    label: "Delhi",
    value: "DL",
  },
  {
    label: "Dadra & Nagar Haveli",
    value: "DN",
  },
  {
    label: "Goa",
    value: "GO",
  },
  {
    label: "Gujarat",
    value: "GU",
  },
  {
    label: "Himachal Pradesh",
    value: "HP",
  },
  {
    label: "Haryana",
    value: "HA",
  },
  {
    label: "Jharkhand",
    value: "JK",
  },
  {
    label: "Jammu & Kashmir",
    value: "JM",
  },
  {
    label: "Karnataka",
    value: "KA",
  },
  {
    label: "Kerala",
    value: "KE",
  },
  {
    label: "Lakshadweep",
    value: "LD",
  },
  {
    label: "Maharashtra",
    value: "MA",
  },
  {
    label: "Meghalaya",
    value: "ME",
  },
  {
    label: "Manipur",
    value: "MN",
  },
  {
    label: "Madhya Pradesh",
    value: "MP",
  },
  {
    label: "Mizoram",
    value: "MI",
  },
  {
    label: "Nagaland",
    value: "NA",
  },
  {
    label: "New Delhi",
    value: "ND",
  },
  {
    label: "Odisha",
    value: "OR",
  },
  {
    label: "Punjab",
    value: "PU",
  },
  {
    label: "Puducherry",
    value: "PO",
  },
  {
    label: "Rajastan",
    value: "RA",
  },
  {
    label: "Sikkim",
    value: "SI",
  },
  {
    label: "Tamil Nadu",
    value: "TN",
  },
  {
    label: "Telangana",
    value: "TG",
  },
  {
    label: "Tripura",
    value: "TR",
  },
  {
    label: "Uttrakhand",
    value: "UC",
  },
  {
    label: "Uttar Pradesh",
    value: "UP",
  },
  {
    label: "West Bengal",
    value: "WB",
  },
  {
    label: "Others",
    value: "OH",
  },
];

class AddAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (Platform.OS == "web") {
      amplitude.logEvent("AddAddress", { path: global.location.href });
    } else {
      amplitude.logEvent("AddAddress", {
        path: this.props.navigation.getState().routeName,
      });
    }
    const customerDetails = getUserDetails();
    if (customerDetails) {
      const { country_of_birth, city, state, pin_code, type_of_address_proof } =
        customerDetails;

      const nationalityError = validateInput("select", country_of_birth);
      const stateError = validateInput("select", state);
      const cityError = validateInput("text", city);
      const pincodeError = validateInput("pincode", pin_code);
      const addressProofTypeError = validateInput(
        "text",
        type_of_address_proof
      );

      this.setState({
        nationality: country_of_birth,
        state,
        city,
        pincode: pin_code,
        nationalityError,
        stateError,
        cityError,
        pincodeError,
        addressProofTypeError,
        addressProofType: type_of_address_proof,
      });
    }
  }

  static navigationOptions = () => ({
    tabBarVisible: false,
  });

  handleChange = (value, type, inputType) => {
    const error = validateInput(inputType, value);
    this.setState({
      [type]: value,
      [type + "Error"]: error,
    });
    return error;
  };

  addAddress = async () => {
    try {
      const { nationality, state, city, pincode, addressProofType, isLoading } = this.state;
      // console.log("Current state:", { nationality, state, city, pincode, addressProofType });

      if (isLoading) return;

      if (!this.validate()) {
        // console.log("Validation failed");
        this.setState({ error: "Please enter all fields" });
        return;
      }

      if (nationality === "0" || 
          state === "0" || 
          !city || city.trim() === "" || 
          !pincode || pincode.trim() === "" || 
           addressProofType === "0") {
        // console.log("Empty fields detected");
        this.setState({ error: "Please enter all fields" });
        return;
      }

      // console.log("All fields are filled, proceeding with submission");

      const { username, customer_id } = getUserDetails();
      const payload = {
        username: username,
        city,
        pin_code: pincode,
        state,
        country_of_birth: nationality,
        nationality,
        type_of_address_proof: addressProofType,
      };

      this.setState({ isLoading: true, error: "" });
      const response = await saveCustomerData(customer_id, payload);
      if (response && response.hasOwnProperty("username")) {
        Toast.show({
          text1: "Saved Successfully",
          visibilityTime: 2 * 1000,
          topOffset: 7,
          type: "success",
        });
        Store.dispatch({
          type: "SAVE_PROFILE_DATA",
          payload: response,
        });
        if (this.props.minDetailsFlow) {
          const redirect = await redirectToPendingScreen(this.props);
          if (redirect && redirect.error) {
          }
        }
      }
      this.setState({ isLoading: false });
    } catch (error) {
      console.error("Error in addAddress:", error);
      this.setState({ isLoading: false, error: "An error occurred while saving the data" });
    }
  };

  validate = () => {
    const {
      nationalityError,
      stateError,
      cityError,
      pincodeError,
      addressProofTypeError,
    } = this.state;
    
    return (
      nationalityError &&
      stateError &&
      cityError &&
      pincodeError &&
      addressProofTypeError
    );
  };

  render() {
    const {
      nationality,
      state,
      city,
      pincode,
      nationalityError,
      stateError,
      cityError,
      pincodeError,
      addressProofType,
      addressProofTypeError,
    } = this.state;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        {!this.props.minDetailsFlow && (
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Address"]}
          />
        )}
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            paddingBottom: 100,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <View style={[styles.formItem, styles.itemMargin]}>
            <PickerFieldSelect
              data={address_options}
              value={addressProofType}
              label={"Address Proof Type*"}
              onChange={(addressProofType) =>
                this.handleChange(
                  addressProofType,
                  "addressProofType",
                  "select"
                )
              }
              pickerStyle={[
                { ...styles.inputItem, width: isMobile ? "100%" : "240.0%" },
              ]}
              isValid={addressProofTypeError}
              type="select"
            />
          </View>
          {!isMobile ? (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="City*"
                  value={city}
                  onChange={(city) => {
                    this.handleChange(city, "city", "text");
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                  inputStyle={[{ paddingLeft: 24 }]}
                  isValid={cityError}
                />
              </View>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={states}
                  value={state}
                  label={"State*"}
                  onChange={(state) =>
                    this.handleChange(state, "state", "select")
                  }
                  pickerStyle={[{ ...styles.inputItem }]}
                  isValid={stateError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select State" }}
                />
              </View>
            </View>
          ) : (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="City*"
                  value={city}
                  onChange={(city) => {
                    this.handleChange(city, "city", "text");
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="text"
                  inputStyle={[{ paddingLeft: 24 }]}
                  isValid={cityError}
                />
              </View>

              <View style={[styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={states}
                  value={state}
                  label={"State*"}
                  onChange={(state) =>
                    this.handleChange(state, "state", "select")
                  }
                  pickerStyle={[{ ...styles.inputItem }]}
                  isValid={stateError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select State" }}
                />
              </View>
            </>
          )}

          {!isMobile ? (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={nationalities}
                  value={nationality}
                  label={"Country*"}
                  onChange={(nationality) =>
                    this.handleChange(nationality, "nationality", "select")
                  }
                  pickerStyle={[{ ...styles.inputItem }]}
                  isValid={nationalityError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select Country" }}
                />
              </View>
              <View style={[{ flex: 1 }, styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Pincode*"
                  value={pincode}
                  onChange={(pincode) => {
                    if (!isNaN(pincode)) {
                      this.handleChange(pincode, "pincode", "pincode");
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="pincode"
                  inputStyle={[{ paddingLeft: 24 }]}
                  isValid={pincodeError}
                />
              </View>
            </View>
          ) : (
            <>
              <View style={[styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={nationalities}
                  value={nationality}
                  label={"Country*"}
                  onChange={(nationality) =>
                    this.handleChange(nationality, "nationality", "select")
                  }
                  pickerStyle={[{ ...styles.inputItem }]}
                  isValid={nationalityError}
                  type="select"
                  placeholder={{ value: "SEL", label: "Select Country" }}
                />
              </View>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Pincode*"
                  value={pincode}
                  onChange={(pincode) => {
                    if (!isNaN(pincode)) {
                      this.handleChange(pincode, "pincode", "pincode");
                    }
                  }}
                  style={[{ ...styles.inputItem }]}
                  editable={true}
                  type="pincode"
                  inputStyle={[{ paddingLeft: 24 }]}
                  isValid={pincodeError}
                />
              </View>
            </>
          )}
          <Text style={{ color: "red", fontSize: 15 }}>{this.state.error}</Text>
          {!isMobile ? (
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={this.addAddress}
              style={{
                marginTop: 50,
                width: "20%",
                left: "33%",
                right: "40%",
              }}
              loading={this.state.isLoading}
              color={"#410DAA"}
            >
              <Text style={styles.next}>Save</Text>
            </Button>
          ) : (
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={this.addAddress}
              style={{
                marginTop: 30,
                width: isMobile ? "100%" : "40%",
              }}
              loading={this.state.isLoading}
              color={"#410DAA"}
            >
              <Text style={styles.next}>Save</Text>
            </Button>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.MAIN_BACKGROUND,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export default connect(null)(AddAddress);