
import React from 'react';
import { View, Text, StyleSheet} from 'react-native';

const StockNameComponent = ({ isin, stock_name }) => {
    return(
        <View>
            <Text style={styles.stock_name}>{stock_name}</Text>
            <Text style={styles.isin}>{isin}</Text>
        </View>
    )
};

const styles = StyleSheet.create({
    stock_name: {
        fontSize: 13,
        fontFamily: "Roboto",
        color: "#464E5F",
        marginBottom: 4,
        fontWeight: "bold"
    },
    isin: {
        fontSize: 13,
        fontFamily: "Roboto",
        color: "#9FA2B4"
    }
})

const StockName = React.memo(StockNameComponent);

export default StockName;