import React, {Component} from "react";
import {View, Text, StyleSheet, TouchableOpacity, ActivityIndicator} from "react-native";
import { completeMinDetails, getUserDetails, isMobile } from "../../utils";
import { Ionicons } from '@expo/vector-icons';
import { ComplianceGroups, ComplianceButtonGroups } from "./data";


export default class ComplianceStrip extends Component {
    constructor(props){
        super(props);
        this.state={
            isLoading: this.props.isLoading,
            pendingItems: {},
            loaded: false
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(this.state.loaded == true ) {
            return false;
        }
        return true;
    }

    getData = async () => {
        try {
            const {minDetailsResponse} = this.props;
            let { needCompletion = "DONT", error } = minDetailsResponse;
            let pendingItems = {};
            let complianceButtonText = null;
             if(!error && needCompletion || !needCompletion){
                const { results } = minDetailsResponse;
                const pendingItemsExist = results && results.filter(item=> item.is_completed == false ).length != 0;
                if(pendingItemsExist){
                    results.forEach((item, index)=>{
                        const { redirect_screen, is_completed } = item;
                        const title = ComplianceGroups[redirect_screen] || "";
                        if(title in pendingItems){
                            pendingItems[title] = pendingItems[title] && is_completed;
                        } else{
                            pendingItems[title] = is_completed;
                            if(!is_completed && !complianceButtonText ){
                                complianceButtonText = ComplianceButtonGroups[redirect_screen];
                            }
                        }
                    } );
                    needCompletion = "SHOW";
                }
            }
            this.setState({ loaded: true, isLoading: false, minDetailsResponse, error, needCompletion, pendingItems, complianceButtonText })
        } catch (error) {
            this.setState({ error: true, loaded: true, isLoading: false });
        }
    }

    async componentDidUpdate(){
        if(!this.props.isLoading && !this.state.loaded){
            await this.getData();
        }
    }

    moveToNextScreen = async () => {
        try {
            const { minDetailsResponse } = this.state;
            const { needCompletion, error } = minDetailsResponse;
            if(!error){
                const { redirect_link, redirect_screen } = minDetailsResponse;
                if(needCompletion){
                    const { params = {} } = this.props.route;
                    this.props.navigation.navigate("Onboarding", { screen: redirect_screen, params: { nvg: "InvestmentOverview", action: true, ...params } });
                }else{
                    const { results } = minDetailsResponse;
                    const pendingItems = results && results.filter(item=>item.is_completed==false);
                    if(pendingItems.length){
                        const { redirect_screen } = pendingItems[0];
                        if(redirect_screen){
                            const { params = {} } = this.props.route;
                            this.props.navigation.navigate("Onboarding", { screen: redirect_screen, params: { nvg: "InvestmentOverview", action: true, ...params }});
                        }
                    }
                }
            } else {
                this.setState({
                    isLoading: false,
                    error: true,
                    errorText: "Something went wrong! Please contact help@maxiom.in"
                })
            }
        } catch (error) {
            this.setState({
                isLoading: false,
                error: true,
                errorText: "Something went wrong!"
            })
        }
    }

    render(){
        const { isLoading, error, needCompletion, pendingItems, complianceButtonText } = this.state;
        if(isLoading){
            return(
                <View style={styles.loaderContainer}>
                    <ActivityIndicator size="small" color="#410DAA"/>
                </View>
            )
        }

        if( error || needCompletion == false ) return null;
        const keys = Object.keys(pendingItems).filter(key=>{
            return !pendingItems[key];
        });
        const length = keys.length;
        return(
            <View style={styles.mainContainer}>
                <View>
                    <Text style={styles.act_req}>Actions Required for compliance</Text>
                    <View style={styles.itemsContainer}>
                        {
                            keys.map((title, index)=> {
                                const is_completed = pendingItems[title];
                                const showDot = index != 0;
                                if(!is_completed) {
                                    return(
                                        <Text style={[styles.item,  index==0 && { paddingLeft: 0} ]}>{ showDot ? ". ": ""}{title}</Text>
                                    )
                                }
                                return null;
                                // return(
                                //     <Text style={[styles.item, styles.completed, firstItem && { paddingLeft: 0}]}>
                                //         <Text style={styles.dotColor}>{!firstItem ? ".  ": ""}</Text>
                                //         {title}
                                //         <Ionicons name="checkmark-circle-sharp" size={10} color="#009105" style={{paddingLeft: 3}} />
                                //     </Text>
                                // )
                            })
                        }
                    </View>
                </View>
                <View>
                    <TouchableOpacity onPress={this.moveToNextScreen} style={styles.buttonContainer}>
                        <Text style={styles.com_nw}>{this.props.userId ? "View Mode" : ( complianceButtonText || "Continue to Comply")}</Text>
                    </TouchableOpacity>
                    <Text style={styles.sebiRequirement}>As per SEBI Regulation</Text>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    mainContainer: {flexDirection: isMobile ? "column" : "row",backgroundColor:"rgba(203, 189, 249, 0.3)",justifyContent:"space-between",paddingVertical:13,paddingHorizontal: isMobile ? 16 : 38, marginBottom: isMobile ? 10 : 20, marginTop: isMobile ? 0 : 14, borderRadius: 6},
    loaderContainer:{
        flex:1,
        alignItems:"center",
        justifyContent:"center",
    },
    act_req:{
        fontFamily:"Roboto",
        fontWeight:"bold",
        fontSize: isMobile ? 11 : 13,
        lineHeight:15,
        // color:"#464E5F",
        color: "#F64E60",
        paddingBottom:8
    },
    itemsContainer: {
        flexDirection: 'row',
        alignItems: "center",
        flexWrap: "wrap"
    },
    item:{
        fontFamily:"Roboto",
        fontStyle:"italic",
        fontSize: isMobile ? 10 : 12,
        lineHeight:23,
        color:"#464E5F",
        flexWrap: "wrap",
        paddingHorizontal: 5,
        
    },
    dotColor:{
        color:"#464E5F"
    },
    buttonContainer:{
        backgroundColor:"#410DAA",
        paddingHorizontal: isMobile ? 16 : 82,
        paddingVertical: isMobile ? 10 : 13,
        borderRadius:4,
        justifyContent:"center",
        alignItems:"center",
        marginTop: isMobile ? 15 : 0,
        alignSelf: "center",
        width: isMobile ? "90%" : "auto"
    },
    com_nw:{
        fontFamily:"Roboto",
        color:"#ffffff",
        fontWeight:"bold",
        fontSize: isMobile ? 12: 15,
        textAlign: "center"
    },
    sebiRequirement: {
        fontSize: 10,
        color: "#F64E60",
        fontFamily: "Roboto",
        textAlign: "center",
        marginTop: 3
    },
    completed: {color:"#009105"}
})