import React from "react";
import {
  View,
  StatusBar,
  ScrollView,
  SafeAreaView,
  Image,
  Platform,
  FlatList,
  StyleSheet,
  Dimensions,
} from "react-native";
import { Button, Text } from "react-native-paper";
import { connect } from "react-redux";
import { getFinplanDetails, postChildDetails } from "../../../api/finplan";
import { updateLastSeenScreen } from "../../../actions/finplan";
import { isMobile } from "../../../utils";
import amplitude from "../../../utils/amplitude";
import TextInputField from "../../../components/Common/Inputs/InputField";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import NavigationHeader from "../../../components/Common/NavigationHeader";
const { width } = Dimensions.get("window");

class ChildrenDetails extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let no_of_children = null,
      type = null;
    if (params) {
      no_of_children = params.no_of_children || null;
      no_of_children = isNaN(no_of_children) ? 1 : no_of_children;
      type = params.type || null;
    }
    this.state = {
      type,
      no_of_children,
      isLoading: false,
      formErrors: [],
      childrenDetails: [],
    };
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("childrenDetails", { path: global.location.href });
      } else {
        amplitude.logEvent("childrenDetails", {
          path: this.props.navigation.getState().routeName,
        });
      }
      const ages = this.generateAgeData(1, 50);
      const options = {
        type: "children",
      };
      let formErrors = [];
      let childrenDetails = [];
      const response = await getFinplanDetails(options);
      if (response.code == 100) {
        const { results } = response;
        const { children = [] } = results;
        const numberOfChildrenStored = children.length;
        let { no_of_children = 0 } = this.state;
        for (let i = 0; i < no_of_children; i++) {
          formErrors.push({
            name:
              i < numberOfChildrenStored
                ? children[i]["name_of_mapped_family_member"]
                  ? null
                  : "Name can not be empty!"
                : null,
            age:
              i < numberOfChildrenStored
                ? children[i]["age"]
                  ? null
                  : "Age can not be empty!"
                : null,
          });
          childrenDetails.push({
            name:
              i < numberOfChildrenStored
                ? children[i]["name_of_mapped_family_member"]
                : null,
            age: i < numberOfChildrenStored ? children[i]["age"] : null,
          });
        }
      }
      this.setState({ formErrors, ages, childrenDetails });
    } catch (error) {}
  };

  generateAgeData = (min, max) => {
    let data = [];
    for (let i = min; i <= max; i++) {
      const num = i;
      data.push({
        label: num.toString(),
        value: num,
      });
    }
    return data;
  };

  validate = () => {
    let { childrenDetails, formErrors } = this.state;
    let flag = true,
      names = "",
      ages = "";
    let formErrorsUpdated = JSON.parse(JSON.stringify(formErrors));
    childrenDetails.forEach((item, index) => {
      if (!item["name"] || item["name"].trim() == "") {
        flag = false;
        formErrorsUpdated[index]["name"] = "Name can not be empty!";
      } else {
        names += `${item["name"]};`;
      }
      if (!item["age"] || item["age"] == 0) {
        flag = false;
        formErrorsUpdated[index]["age"] = "Age can not be empty!";
      } else {
        ages += `${item["age"]};`;
      }
    });
    this.setState({ formErrors: formErrorsUpdated });
    return {
      is_valid: flag,
      names,
      ages,
    };
  };

  onPressNext = async () => {
    const { is_valid, names, ages } = this.validate();
    if (is_valid) {
      const { type } = this.state;
      this.setState({ isLoading: true });
      const payload = {
        names,
        ages,
      };
      const response = await postChildDetails(payload);
      if (response.code == 100) {
        this.props.navigation.navigate("ChildrenBachelors", {
          total: this.state.no_of_children,
          type,
        });
      }
    }
  };

  setData = (key, value, index, error) => {
    let { childrenDetails: details, formErrors } = this.state;
    details = JSON.parse(JSON.stringify(details));
    details[index][`${key}`] = value;
    formErrors[index][key] = error;
    this.setState({ childrenDetails: details, formErrors });
  };

  renderDetails = ({ item, index }) => {
    const { formErrors } = this.state;
    return (
      <View
        style={{
          paddingLeft: 0,
          paddingBottom: 20,
        }}
        key={"#" + index}
      >
        <TextInputField
          label={`Child-${index + 1} Name`}
          value={item.name}
          onChange={(value) => {
            let nameError;
            if (value.trim() == "") {
              nameError = "Name can not be empty";
            }
            this.setData("name", value, index, nameError);
          }}
          style={[{ ...styles.inputItem }]}
          editable={true}
        />
        <Text style={[styles.errorMessage, { paddingVertical: 0 }]}>
          {formErrors[index]["name"] || " "}
        </Text>
        <PickerFieldSelect
          data={this.state.ages}
          value={item.age}
          label={`Child-${index + 1} Age ( in years )`}
          onChange={(value) => {
            this.setData("age", value, index, null);
          }}
          pickerStyle={[{ ...styles.inputItem }]}
          placeholder={{ value: "SEL", label: "Select" }}
        />
        <Text style={[styles.errorMessage, { paddingVertical: 0 }]}>
          {formErrors[index]["age"] || " "}
        </Text>
      </View>
    );
  };

  render() {
    const { childrenDetails } = this.state;
    return (
      <View style={[styles.page]}>
        <SafeAreaView style={{ flex: 1 }}>
          <NavigationHeader
            navigation={this.props.navigation}
            navigationHeader={["Children Details"]}
            onPressBack={() => {
              this.props.navigation.goBack();
            }}
          />
          <ScrollView
            contentContainerStyle={{
              paddingBottom: 200,
              marginTop: 50,
              alignItems: isMobile ? "center" : "flex-start",
            }}
            showsVerticalScrollIndicator={true}
            keyboardShouldPersistTaps="handled"
          >
            <View
              style={
                isMobile
                  ? { justifyContent: "center", alignItems: "center" }
                  : { marginLeft: "30%" }
              }
            >
              <View style={{ flex: 1, paddingHorizontal: 16 }}>
                <StatusBar backgroundColor="#410DAA" barStyle="light-content" />
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/static/finplan_new/children_finplan_image.png",
                    }}
                    style={{ width: 150, height: 150, alignSelf: "center" }}
                    resizeMode="contain"
                  />
                </View>
                <View
                  style={{
                    alignItems: "center",
                    flexGrow: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      marginBottom: 30,
                    }}
                  >
                    Tell us about your children
                  </Text>
                  <View style={{ flex: 1 }}>
                    {childrenDetails && childrenDetails.length != 0 && (
                      <FlatList
                        data={childrenDetails}
                        renderItem={this.renderDetails}
                        keyExtractor={(item, index) => index.toString()}
                        extraData={this.state}
                        style={{ paddingLeft: 0, marginLeft: 0 }}
                      />
                    )}
                  </View>
                </View>
              </View>
              <Button
                uppercase={false}
                mode={"contained"}
                loading={this.state.isLoading}
                onPress={this.onPressNext}
                style={styles.nextButton}
                color="#410DAA"
              >
                <Text style={styles.next}>Next</Text>
              </Button>
            </View>
          </ScrollView>
        </SafeAreaView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingBottom: 66,
    width: width,
    height: "100%",
  },
  formItem: {},
  inputItem: {
    width: isMobile ? "100%" : "auto",
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  nextButton: {
    width: width < 768 ? "96%" : "25%",
    marginLeft: isMobile ? 5 : "7%",
    marginTop: 30,
    marginBottom: 0,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  radioButtonContainer: {
    flexDirection: "row",
    paddingVertical: 5,
    alignItems: "center",
  },
  childrenDetailsContainer: {
    marginBottom: 20,
  },
  errorMessage: {
    color: "red",
    fontSize: 14,
    paddingVertical: 5,
    fontFamily: "Roboto",
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateLastSeenScreen: (type, screen, basic, end) =>
      dispatch(updateLastSeenScreen(type, screen, basic, end)),
  };
};

export default connect(null, mapDispatchToProps)(ChildrenDetails);
