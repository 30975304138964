import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  Dimensions,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Keyboard,
} from "react-native";
import {
  getScreenConfig,
  addNotificationDevice,
  getLoginBannerDetails,
  skipCustomerLogin,
  getDatabaseOfUser,
  onboardingSendOTP,
  checkCustomerSignup,
  mobileOtpVerificationBeforeSignup,
  getLeadData,
} from "../../../api";
import { md } from "../../../Responsive";
import { FontAwesome5 } from '@expo/vector-icons';
import { checkIfValidIndianMobileNumber, getUserDetails, isJama, isMobile, isWeb } from "../../../utils";
import Header from "../login_header";
const { width } = Dimensions.get("window");
import { ShowToast } from "../../../components/Common/Toast";
import { saveUserProfileData, logIn, saveScreenConfig } from "../../../actions";
import { connect } from "react-redux";
import amplitude from "../../../utils/amplitude";
// import DeviceInfo from "react-native-device-info";
import { StackActions } from "@react-navigation/routers";
import AsyncStorage from "@react-native-async-storage/async-storage";
import NetInfo from "@react-native-community/netinfo";
import Toast from "react-native-toast-message";
import { saveCustomerData } from "../../../api/account";
import ReCAPTCHA from "react-google-recaptcha";
import Store from "../../../store";

class LoginUsingUsername extends React.Component {
  constructor(props) {
    super(props);
    this.refInput = React.createRef();
    const { params } = this.props.route;
    let unique_key = null;
    let redirection_url = null;
    let corp_code = "";
    let subscription_plan_code = "";
    let lead_id = "";
    let user = ""
    let reactive = false
    /**
     * here actually we hardcode string for incoming URL
     * 
     * regular plan = 03e198c3-08b4-42c1-ae94-cd996bcb7156
     * 
     basically by default this flow is design for direct User so if user comes from 
     above regular plan Link then we will divert this design according to regular user.

     to pass regular Plan string alway make param with "userType" key and then pass string as value.
     */

    let is_imp = "TRUE";
    let goalType = "COMPLETE";
    let is_direct = "TRUE";
    let mobileNumber = "";

    // campaign params
    let next = ""
    let corp_x = ""
    let source = ""


    let autoCall = false;
    if (params) {
      unique_key = params.unique || "";
      redirection_url = params.redirect_url || "";
      mobileNumber = params.mobileNumber || "";
      next = params.next || ""
      corp_x = params.corp_x || ""
      source = params.source || ""
      if (params.mobileNumber) {
        autoCall = true;
      }
      reactive = params.reactive || false
      if (reactive) {
        user = params.user
      }
      corp_code = params.ccd || "";
      subscription_plan_code = params.pcd || "";
      lead_id = params.ldid || "";
      goalType = params.goalType || "COMPLETE";
      if (params.userType) {
        if (params.userType == "03e198c3-08b4-42c1-ae94-cd996bcb7156") {
          is_direct = "FALSE";
        }
      }
    }
    this.recaptchaRef = React.createRef();
    this.state = {
      data: null,
      is_imp,
      mobileNumber,
      user,
      reactive,
      autoCall,
      source,
      corp_x,
      next,
      goalType,
      capthaVal: null,
      is_direct,
      corp_code,
      lead_id,
      subscription_plan_code,
      isLoading: false,
      showOTP: false,
      showResendOTP: false,
      showNoUser: false,
      mobileError: "",
      errorText: "",
      emailError: "",
      dbError: false,
      dbErrorText: "",
      passwordError: "",
      captchaText: "",
      isNewUser: false,
      captchaError: "",
      randomCaptcha: 0,
      isConnected: true,
      bannerData: {
        logo_image_url: null,
        name: null,
        ria_code: null,
        subscriber_type: null,
        support_email: null,
        support_person_name: null,
        support_phone_number: null,
        is_jamawealth: null,
      },
      is_ria: false,
      hostname: Platform.OS == "web" ? global.location.hostname : "mobile",
      unique_key,
      redirection_url,
      showCaptcha: true,
      is_club: false,
    };
  }

  loadBannerData = async () => {
    let { bannerData, lead_id } = this.state;
    if (lead_id != "") {
      const lead_payload = {
        "leadpk": lead_id
      }
      let resp = await getLeadData(lead_payload);
      if (resp && resp.code && resp.code == "100") {
        this.setState({
          mobileNumber: resp.result.phone_number,
          is_correct_lead: true,
        });
      }
    }
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    const { user_database } = Store.getState().userData.userProfileData
    if (hostname === "mobile") {
      if (user_database != "" && user_database != undefined && user_database != null) {
        hostname = user_database
      } else {
        hostname = "dapp"
      }
    }
    let response = null;
    let is_ria = false;
    let is_preferred_circle = false;
    if (hostname.includes("simplygrow.in")) {
      is_ria = true;
    }
    let is_club = false;
    if (hostname.includes("club")) {
      is_club = true;
    }
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    let showCaptcha = true;
    if (hostname == "dapp.jamawealth.com" || hostname == "demo.simplygrow.in") {
      showCaptcha = false;
    }
    try {
      response = await getLoginBannerDetails();
      // console.log("respose is ", response);
    } catch (err) { }
    if (response && response.code == 100) {
      bannerData = response.result;
      this.setState({
        bannerData: bannerData,
        is_ria: is_ria,
        hostname: hostname,
        showCaptcha: showCaptcha,
        is_club: is_club,
        is_preferred_circle: is_preferred_circle,
      });
    } else {
      bannerData.logo_image_url =
        "https://jamaappprod.s3.ap-south-1.amazonaws.com/jamawealth/website/jam%C4%81_header.png";
      bannerData.name = isJama() ? "JamaWealth" : "MaxiomWealth" ;
      bannerData.ria_code = "INA 200015583";
      bannerData.subscriber_type = "RIAC";
      bannerData.support_email = "advisor@jamawealth.com";
      bannerData.support_person_name = "Ram Kalyan Medury";
      bannerData.support_phone_number = "9870264643";
      bannerData.is_jamawealth = true;
      this.setState({
        bannerData: bannerData,
        errorText: response && response.msg ? response.msg : "something went wrong",
        is_ria: is_ria,
        hostname: hostname,
        showCaptcha: showCaptcha,
        is_club: is_club,
        is_preferred_circle: is_preferred_circle,
      });
    }
  };

  skipLoginScreen = async (unique_key) => {
    this.setState({ isLoading: true });
    // let tokenValue = await AsyncStorage.getItem("tokenValue");
    // let brand = DeviceInfo.getBrand();
    // let deviceId = DeviceInfo.getDeviceId();
    // let ip = await DeviceInfo.getIpAddress();
    let {
      redirection_url,
      bannerData,
      is_ria,
      hostname,
      is_club,
      is_preferred_circle,
    } = this.state;
    let tokenValue = await AsyncStorage.getItem("tokenValue");

    let data = {
      token: unique_key,
    };

    if (Platform.OS == "web") {
      data = {
        token: unique_key,
        operating_system: Platform.OS,
      };
    } else {
      data = {
        token: unique_key,
        operating_system: Platform.OS,
      };
    }
    let username = await AsyncStorage.getItem("username");
    this.setState({ mobileNumber: username })
    const checkSignUpResponse = await this.getUserInfo();
    // console.log("Check Sign UP " + JSON.stringify(checkSignUpResponse));
    if (
      checkSignUpResponse &&
      checkSignUpResponse.code &&
      checkSignUpResponse.code == "100"
    ) {
      if (checkSignUpResponse.results.user_exists_in_club === "FALSE" && checkSignUpResponse.results.user_exists_in_advisory === "FALSE") {
        // console.log("New User Found");
        this.setState({ isLoading: false, isNewUser: true });
      } else {
        let user_exists_in_advisory = checkSignUpResponse.results.user_exists_in_advisory;
        let db = "";
        if (user_exists_in_advisory === "TRUE") {
          db = "direct"
        } else {
          db = "regular"
        }
        await AsyncStorage.setItem("database", db);
        // console.log("Data base is set to " + db);
      }
    }
    const responseJson = await skipCustomerLogin(data);
    if (responseJson && responseJson.code == 100) {
      // await addNotificationDevice(data);
      amplitude.setUserId(responseJson.results.mobilenumber);

      const eventProperties = {
        username: responseJson.results.first_name,
        mobile: responseJson.results.mobilenumber,
        email: responseJson.results.email,
        customer_id: responseJson.results.customer_id,
      };
      AsyncStorage.removeItem("autoLoginToken");
      amplitude.logEvent("Login_Successful", eventProperties);
      const { results = {} } = responseJson;
      if (results && results.token != null) {
        let data = { ...responseJson.results };
        const { token } = data;
        data = Object.assign(data, {
          is_ria: is_ria,
          bannerData: bannerData,
          is_club: is_club,
          is_preferred_circle: is_preferred_circle,
        });
        const loggedInTime = new Date().getTime();
        try {
          const screenConfigResponse = await getScreenConfig(
            { domain_name: hostname },
            { Authorization: "token " + token }
          );
          if (screenConfigResponse) {
            const { result } = screenConfigResponse || {};
            this.props.saveScreenConfig(result);
          }
        } catch (error) { }
        this.props.logIn(loggedInTime);
        this.props.saveUserProfileData(data);
        this.setState({ isLoading: false });
        if (redirection_url) {
          let url = new URL(global.location.href);
          location.replace(url.origin + redirection_url);
        }
      } else {
        this.setState({
          errorText: "Error! Please contact help@maxiom.in",
          error: true,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        errorText:
          responseJson && responseJson.message
            ? responseJson.message
            : "Something went wrong!",
        error: true,
        isLoading: false,
      });

      // console.log("EErro ", this.state.errorText);
    }
  };


  // generateCaptcha = async () => {
  //   console.log("captcha generated")
  //   loadCaptchaEnginge(6, "#000", "#fff", "upper");
  // }

  componentDidMount = async () => {
    // console.log("Value of DB is ", this.state.is_imp)
    NetInfo.fetch().then((state) => {
      this.setState({ isConnected: state.isConnected });
    });
    const unsubscribe = NetInfo.addEventListener((state) => {
      this.setState({ isConnected: state.isConnected });
    });
    let loginToken = await AsyncStorage.getItem("autoLoginToken");
    // console.log("login Token", loginToken)
    if (loginToken) {
      this.skipLoginScreen(loginToken);
    }
    if (this.state.autoCall) {
      this.handleContinue();
    }
    // console.log("reactive", this.state.reactive);
    if (this.state.reactive) {
      this.setState({ mobileNumber: this.state.user })
      this.handleContinue();
    }
    unsubscribe();
    // await this.generateCaptcha();
    await this.loadBannerData();
    const { unique_key } = this.state;
    if (unique_key) {
      try {
        await this.skipLoginScreen(unique_key);
      } catch (err) { }
    }
  };

  // generateCaptcha = () => {
  //   var numberOne = Math.floor(Math.random() * 1000000) + 1;
  //   this.setState({ randomCaptcha: numberOne });
  // };

  getUserInfo = async () => {
    const { mobileNumber } = this.state;
    try {
      let payload = {};
      if (this.state.source != "") {
        payload = {
          "username": mobileNumber.toString(),
          "source": this.state.source
        }
      } else {
        payload = {
          "username": mobileNumber.toString(),
        }
      }
      // console.log("getUserInfo ", payload)
      let response = await getDatabaseOfUser(payload);
      // console.log("Response is = " + JSON.stringify(response));
      return response;
    } catch (e) {
      console.log(e)
      // console.log("Somthing went wrong ", e);
    }
  }

  handleContinue = async () => {
    const {
      email,
      password,
      captchaText,
      bannerData,
      is_ria,
      hostname,
      showCaptcha,
    } = this.state;
    if(!this.state.capthaVal){
      return;
    }
    this.setState({ isLoading: true, dbError: false });
    // let tokenValue = await AsyncStorage.getItem("tokenValue");
    // let brand = DeviceInfo.getBrand();
    // let deviceId = DeviceInfo.getDeviceId();
    // let ip = await DeviceInfo.getIpAddress();
    // console.log("inside try condition");
    let data = {
      username: email,
      password: password,
    };

    if (Platform.OS == "web") {
      data = {
        ...data,
        operating_system: Platform.OS,
      };
    } else {
      data = {
        ...data,
        // token: tokenValue,
        // make: brand,
        // model: deviceId,
        operating_system: Platform.OS,
        // location: ip,
      };
    }
    // console.log("Authenticating data is ", data);

    const { mobileNumber } = this.state;

    this.setState({ isLoading: true, showNoUser: false });
    try {
      if (!checkIfValidIndianMobileNumber(mobileNumber.toString())) {
        // console.log("here inside")
        this.setState({
          mobileError: "Please enter a valid 10 digit mobile number",
          isLoading: false,
          errorText: "",
          captchaError: "",
        });
        return;
      } else {
        this.setState({
          mobileError: "",
          captchaError: "",
        });
      }

      // if (!captchaText) {
      //   this.setState({
      //     captchaError: "Please Enter a Captcha",
      //     errorText: "",
      //     emailError: "",
      //     passwordError: "",
      //     isLoading: false,
      //     mobileError: "",
      //   });
      //   return;
      // } else {
      //   this.setState({
      //     captchaError: "",
      //     mobileError: "",
      //   });
      // }
  
      // if (validateCaptcha(captchaText) == true) {
      //   this.setState({
      //     captchaError: "",
      //     mobileError: "",
      //   });
      // } else {
      //   this.setState({
      //     captchaError: "Please Enter Valid Captcha",
      //     errorText: "",
      //     emailError: "",
      //     passwordError: "",
      //     mobileError: "",
      //     isLoading: false,
      //   });
      //   return;
      // }  

      const payload = {
        mobilenumber: mobileNumber.toString(),
      };
      const checkSignUpResponse = await this.getUserInfo();
      let new_user = false;
      // console.log("Check Sign UP " + JSON.stringify(checkSignUpResponse));
      if (
        checkSignUpResponse &&
        checkSignUpResponse.code &&
        checkSignUpResponse.code == "100"
      ) {
        if (checkSignUpResponse.results.user_exists_in_club === "FALSE" && checkSignUpResponse.results.user_exists_in_advisory === "FALSE") {
          // console.log("New User Found");
          new_user = true;
          this.setState({ isLoading: false, isNewUser: true });
        } else {
          let user_exists_in_advisory = checkSignUpResponse.results.user_exists_in_advisory;
          let db = "";
          if (user_exists_in_advisory === "TRUE") {
            db = "direct"

          } else {
            db = "regular"
          }
          if (checkSignUpResponse.results.user_exists_in_advisory == "TRUE" && checkSignUpResponse.results.user_exists_in_club == "TRUE") {
            this.setState({ dbError: true, dbErrorText: `Number Found in Both Database`, isLoading: false })
            return;
          }
          let hostname = global.location.hostname;
          if (!hostname.includes("localhost") && checkSignUpResponse.results.is_admin_user == false) {
            if (Platform.OS == "web" && !hostname.includes("cpartner")) {

              let topic = "";
              if ((hostname.includes("app") || hostname.includes("myadvisor")) && db == "regular") {
                if (hostname.includes("dapp") || hostname.includes("dmyadvisor")) {
                  global.location.href = `https://dclub.maxiomwealth.com/login?mobileNumber=${mobileNumber}`
                } else {
                  global.location.href = `https://club.maxiomwealth.com/login?mobileNumber=${mobileNumber}`
                }
                return;
              }
              if (hostname.includes("club") && db == "direct") {
                let urls = "";
                if (hostname.includes("dclub")) {
                  urls = "https://dapp.maxiomwealth.com"
                } else {
                  urls = "https://app.maxiomwealth.com"
                }
                if (checkSignUpResponse.results.database_host && checkSignUpResponse.results.database_host != "") {
                  if (hostname.includes("dclub")) {
                    urls = "https://dmyadvisor.maxiomwealth.com"
                  } else {
                    urls = "https://myadvisor.maxiomwealth.com"
                  }
                }
                global.location.href = `${urls}/login?mobileNumber=${mobileNumber}`
                return;
              }
              // if (db == "direct") {
              //   let urls = "";
              //   if (checkSignUpResponse.results.database_host && checkSignUpResponse.results.database_host != "" && checkSignUpResponse.results.database_host.includes("myadvisor")) {
              //     if (hostname.includes("dmyadvisor")) {
              //       urls = "https://dmyadvisor.jamawealth.com"
              //     } else {
              //       urls = "https://myadvisor.jamawealth.com"
              //     }
              //     global.location.href = `${urls}/login/password?unique=${checkSignUpResponse.results.rToken}`
              //     return;
              //   } else {
              //     if (!hostname.includes("app")) {
              //       if (hostname.includes("dapp")) {
              //         urls = "https://dapp.jamawealth.com"
              //       } else {
              //         urls = "https://app.jamawealth.com"
              //       }
              //       global.location.href = `${urls}/login/password?unique=${checkSignUpResponse.results.rToken}`
              //       return;
              //     }
              //   }
              // }
            }
          }
          await AsyncStorage.setItem("database", db);
          // console.log("Data base is set to " + db);
        }
      } else {
        this.setState({
          error: true,
          errorText: "Something went wrong",
          isLoading: false,
        });
        return;
      }
      let response;
      console.log("loging ", {
        new : new_user,
        activr: this.state.reactive
      })
      if (this.state.reactive) {
        new_user = false
        this.setState({ isNewUser: false })
      }
      console.log("loging ", {
        new : new_user,
        activr: this.state.reactive
      })
      if (this.state.isNewUser || this.state.reactive || new_user) {
        console.log("new number found", { target_mobile_number: mobileNumber.toString() })
        response = await mobileOtpVerificationBeforeSignup({
          target_mobile_number: mobileNumber.toString()
        })
        if (response && response.resp_code === "100") {
          this.setState({ isLoading: false, showOTP: true });
        } else {
          this.setState({
            error: true,
            errorText: "Please enter a valid 10 digit mobile number",
            isLoading: false,
          });
        }
      } else {
        response = await onboardingSendOTP({
          username: mobileNumber.toString(),
        });
        if (response && response.otp_generated_date) {
          this.setState({ isLoading: false, showOTP: true });
        } else {
          this.setState({
            error: true,
            errorText: "Please enter a valid 10 digit mobile number",
            isLoading: false,
          });
        }
      }
      // console.log("Send OTP response is " + JSON.stringify(response));

    } catch (error) {
      console.log("erorr is", error);
      if (this.state.isConnected) {
        this.setState({
          errorText:
            responseJson && responseJson.message
              ? responseJson.message
              : "Something went wrong!",
          error: true,
          isLoading: false,
        });
      } else {
        this.setState({
          errorText: "Oh oh! Looks like your device is offline",
          error: true,
          isLoading: false,
        });
      }
      this.setState({ isLoading: true });
    }
  };

  handleContinue2 = async (is_otp_verified = false) => {
    const { mobileNumber, otpNumber, bannerData, is_ria, hostname } = this.state;
    this.setState({ isLoading: true });
    try {
      if (!otpNumber) {
        this.setState({
          otpError: "Please enter a valid 6 digit otp",
          isLoading: false,
          errorText: "",
          passwordError: "",
          repasswordError: "",
        });
        return;
      } else {
        this.setState({
          otpError: "",
        });
      }

      let response;
      if (this.state.isNewUser || this.state.reactive) {
        response = await mobileOtpVerificationBeforeSignup({
          target_mobile_number: mobileNumber.toString(),
          otp_value: otpNumber.toString(),
        });
      } else {
        response = await onboardingSendOTP({
          username: mobileNumber.toString(),
          otp: otpNumber.toString(),
          source: "IOS",
          is_otp_verified
        });
      }
      // console.log("usingUsername verify OTP response is " + JSON.stringify(response));
      if (response && (response.pk != null || response.message === "OTP verified")) {
        // console.log("New user is created with ", this.state.isNewUser);
        if (this.state.isNewUser) {
          this.props.navigation.navigate("SignupPage", {
            mobileNumber: mobileNumber.toString(),
            otp: otpNumber.toString(),
            pcd: this.state.subscription_plan_code,
            ldid: this.state.lead_id,
            ccd: this.state.corp_code,
            goalType: this.state.goalType,
            next: this.state.next,
            source: this.state.source,
            corp_x: this.state.corp_x
          });
        } else {
          let data = { ...response };
          data = Object.assign(data, {
            is_ria: is_ria,
            bannerData: bannerData,
          });
          const loggedInTime = new Date().getTime();
          this.props.logIn(loggedInTime);
          this.props.saveUserProfileData(data);
          if (this.state.reactive) {
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().slice(0, 19).replace('T', ' ');
            console.log("res", response)
            let username = response.username;
            const payload = {
              username,
              is_active: true,
              account_deactivated_on: null
            }
            await saveCustomerData(response.cust_pk, payload);
            this.setState({ reactive: false, isNewUser: false })
            this.handleContinue2(true);
          }
          this.setState({ isLoading: false }, () => {
            Toast.hide();
            this.props.navigation.navigate("EquityPlans");
          });
        }
      } else {
        let error = null;
        if (response.length) {
          error = response[0];
        }
        this.setState({
          error: true,
          errorText:
            response && response.msg
              ? response.msg
              : error || "Please enter the correct otp",
          isLoading: false,
        });
      }
    } catch (error) {
      console.log("error is", error);
      this.setState({
        error: true,
        errorText: "Something went wrong!",
        isLoading: false,
      });
    }
  };

  handleResend = async () => {
    this.setState({ showResendOTP: false });
    const { mobileNumber } = this.state;
    const data = {
      username: mobileNumber,
    };
    let response;
    if (this.state.isNewUser) {
      response = await mobileOtpVerificationBeforeSignup({
        target_mobile_number: mobileNumber.toString()
      })
    } else {
      response = await onboardingSendOTP(data);
    }
    if (response && (response.otp_generated_date || response.resp_code === "100")) {
      this.setState({ showResendOTP: true });
    }
  };

  render() {
    const {
      isLoading,
      errorText,
      mobileNumber,
      mobileError,
      otpNumber,
      otpError,
      bannerData,
      captchaText,
      captchaError,
      is_ria,
    } = this.state;
    // if (this.refInput && this.refInput.current && !this.state.captchaText && !this.state.showOTP) {
    //   this.refInput.current.focus();
    // }
    return (
      <View style={styles.container}>
        <Header
          navigation={this.props.navigation}
          route={this.props.route}
          bannerData={bannerData}
        />
        {this.state.showOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent successfully."
          />
        ) : null}
        {this.state.showResendOTP ? (
          <ShowToast
            showToast={true}
            type="success"
            title="OTP sent successfully."
          />
        ) : null}
        {this.state.showNoUser ? (
          <ShowToast
            showToast={true}
            type="success"
            title={`No user available with mobile no:${this.state.mobileNumber}, kindly signup first.`}
          />
        ) : null}
        <ScrollView keyboardShouldPersistTaps="always">
          <View style={styles.body}>
            {isWeb && (
              <View style={styles.left}>
                <View style={{ paddingBottom: 50 }}>
                  <View style={{ opacity: 0 }}>
                    <Image
                      source={
                        "https://jamaappprod.s3.ap-south-1.amazonaws.com/metronic/Onboard/money_motivation__monochromatic+3-2.png"
                      }
                      style={styles.left_logo}
                    ></Image>
                  </View>
                  <View style={{ position: 'absolute', zIndex: 10 }}>
                    <View style={{ marginBottom: 25 }}>
                      <Text style={{ color: '#410DAA', fontSize: 32, fontWeight: '700' }}>Benefits</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <FontAwesome5 name="chart-line" size={24} color="#666666" />
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>Maximize Returns, Minimize Risks</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <FontAwesome5 name="user-tie" size={24} color="#666666" />
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>Expert Planning for Financial Goals</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <FontAwesome5 name="chart-pie" size={24} color="#666666" />
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>Tailored Portfolios for Unique Needs</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <FontAwesome5 name="shield-alt" size={24} color="#666666" />
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>Secure Future with Strategic Investments</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <FontAwesome5 name="search-dollar" size={24} color="#666666" />
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>Insightful Advice, Superior Market Performance</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
                      <FontAwesome5 name="microscope" size={24} color="#666666" />
                      <Text style={{ color: "#666666", marginLeft: 10, fontWeight: '700', fontSize: 18 }}>Free Portfolio Analysis & Health Check</Text>
                    </View>
                  </View>
                </View>
                <TouchableOpacity onPress={() => {
                  // console.log("working")
                  if (Platform.OS === "web") {
                    global.open("https://maxiomwealth.com/investor-center/#tab7_nav2", "_self")
                  } else {
                    Linking.openURL("https://maxiomwealth.com/investor-center/#tab7_nav2")
                  }
                }}>
                  <Text style={{ textAlign: 'center', color: '#410DAA', fontWeight: '500', marginTop: 20, textDecorationLine: 'underline' }}>See client testimonials</Text>
                </TouchableOpacity>
                <View>
                  <View style={[styles.certicationsContainer, { paddingTop: 35 }]}>
                    {bannerData.is_jamawealth ? (
                      <View style={styles.certication}>
                        <Image
                          source={
                            "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+11.png"
                          }
                          style={{ width: 18, height: 30 }}
                        />
                        <Text style={[styles.sebi, { paddingLeft: 10 }]}>
                          Goi{" "}
                          <Text style={{ fontWeight: "bold", color: "black" }}>
                            DIPP
                          </Text>{" "}
                          Registered
                        </Text>
                      </View>
                    ) : null}
                    {bannerData.is_jamawealth ? (
                      <View style={styles.certication}>
                        <Image
                          source={
                            "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+7.png"
                          }
                          style={{ width: 29, height: 25 }}
                        />
                        <Text style={[styles.sebi, { paddingLeft: 10.17 }]}>
                          ISO{" "}
                          <Text style={{ fontWeight: "bold", color: "black" }}>
                            9001
                          </Text>{" "}
                          Certified
                        </Text>
                      </View>
                    ) : null}
                    {bannerData.is_jamawealth ? (
                      <View style={styles.certication}>
                        <Image
                          source={
                            "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/image+7.png"
                          }
                          style={{ width: 29, height: 25 }}
                        />
                        <Text style={[styles.sebi, { paddingLeft: 10.17 }]}>
                          ISO{" "}
                          <Text style={{ fontWeight: "bold", color: "black" }}>
                            27001
                          </Text>{" "}
                          Certified
                        </Text>
                      </View>
                    ) : null}
                  </View>
                  <View style={styles.certicationsContainer}>
                    <View style={styles.sebiContainer}>
                      <View style={{ paddingLeft: 0.4 }}>
                        <Text style={[styles.sebi]}>
                          SEBI Regd Investment Advisor
                        </Text>
                        <Text style={styles.ina}>{bannerData.ria_code}</Text>
                        <Text style={[styles.sebi_1]}>
                          Simply Grow Technologies Pvt Ltd.
                        </Text>
                      </View>
                    </View>
                    <View style={styles.sebiContainer}>
                      <View style={{ paddingLeft: 10.4 }}>
                        <Text style={[styles.sebi]}>
                          SEBI Registered Portfolio Manager
                        </Text>
                        <Text style={styles.ina}>INP000007881</Text>
                        <Text style={[styles.sebi_1]}>
                        { isJama() ? "Jama" : "Maxiom" } Wealth Asset Management Pvt Ltd.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )}
            <View style={styles.right}>
              <View style={[isMobile ? styles.mob_styl : styles.web_styl]}>
                <View style={{ alignItems: "center", marginTop: 32, marginBottom: 30, width: '100%' }}>
                  {bannerData.is_jamawealth ? (
                    <Image
                      source={bannerData.logo_image_url}
                      style={{ width: '40%', height: 100 }}
                      resizeMode={"contain"}
                    />
                  ) : null}
                </View>
                <View style={styles.inputsContainer}>
                  <View style={styles.inputContainer}>
                    <Text style={styles.mob_num}>Mobile Number</Text>
                    <TextInput
                      // ref={this.refInput}
                      value={mobileNumber}
                      style={styles.dmart_body_md}
                      placeholder={"Enter registered mobile number"}
                      onFocus={() => {
                        this.setState({
                          mobileError: "",
                          errorText: "",
                          dbError: false
                        });
                      }}
                      onChangeText={(text) => {
                        const pattern = /^[0-9\b]+$/;
                        if (text.length <= 10 && pattern.test(text)) {
                          this.setState({ mobileNumber: text });
                        } else {
                          text = text.slice(0, -1);
                          this.setState({ mobileNumber: text });
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.nativeEvent.key == "Enter") {
                          Keyboard.dismiss();
                          this.handleContinue();
                        }
                      }}
                    />
                    {/* {console.log("value of mobile is ", mobileError)} */}
                    {mobileError != "" && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {mobileError || " "}
                      </Text>
                    )}
                  </View>
                  {!!errorText && !this.state.showOTP && (
                    <Text style={[styles.please, { color: "red" }]}>
                      {errorText || " "}
                    </Text>
                  )}
                </View>
                {/* <View style={styles.inputsContainer}>
                  <View style={styles.inputContainer}>
                    <Text style={styles.mob_num}>Captcha</Text>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: ""
                      }}
                    >
                      <TextInput
                        value={captchaText}
                        maxLength={6}
                        style={[
                          styles.dmart_body_md,
                          { marginTop: -20, width: "90%"},
                        ]}
                        placeholder={"Enter Captcha"}
                        placeholderTextColor={"#00000030"}
                        onFocus={() => {
                          this.setState({
                            mobileError: "",
                            captchaError: "",
                          });
                        }}
                        onChangeText={(text) => {
                          this.setState({ captchaText: text });
                        }}
                        onKeyPress={(e) => {
                          if (e.nativeEvent.key == "Enter") {
                            this.handleContinue();
                          }
                        }}
                      />
                      <LoadCanvasTemplate reloadText="<span class='glyphicon' style='font-size:25px;color:black;padding:60px;'>&#x21bb;</span>" />
                    </View>
                    {!!captchaError && (
                    <Text style={[styles.please, { color: "red" }]}>
                      {captchaError || " "}
                    </Text>
                  )}
                  </View>
                </View> */}
                {!!(this.state.mobileNumber && this.state.showOTP) && (
                  <View style={styles.inputsContainer}>
                    <View style={styles.inputContainer}>
                      <Text style={styles.mob_num}>Enter OTP</Text>
                      <TextInput
                        autoFocus={this.state.showOTP}
                        value={otpNumber}
                        style={styles.dmart_body_md}
                        placeholder={`Enter OTP sent on ${this.state.mobileNumber}`}
                        onFocus={() => {
                          this.setState({
                            otpError: "",
                            errorText: "",
                          });
                        }}
                        onChangeText={(text) => {
                          const pattern = /^[0-9\b]+$/;
                          if (text.length <= 6 && pattern.test(text)) {
                            this.setState({ otpNumber: text });
                          } else {
                            text = text.slice(0, -1);
                            this.setState({ otpNumber: text });
                          }
                        }}
                        maxLength={6}
                        onKeyPress={(e) => {
                          if (e.nativeEvent.key == "Enter") {
                            Keyboard.dismiss();
                            this.handleContinue2();
                          }
                        }}
                      />
                      {!!otpError && (
                        <Text style={[styles.please, { color: "red" }]}>
                          {otpError || " "}
                        </Text>
                      )}
                    </View>
                    {!!errorText && (
                      <Text style={[styles.please, { color: "red" }]}>
                        {errorText || " "}
                      </Text>
                    )}
                  </View>
                )}
                {!this.state.showOTP && <View style={{marginTop: 40, marginBottom: 50}}>
                  <ReCAPTCHA
                    ref={this.recaptchaRef}
                    size="normal"
                    onChange={(val)=> this.setState({ capthaVal : val})}
                    sitekey="6Lf33eUpAAAAANArQRr2_yCDugV5TzaGBJCYTPgV"
                  />
                  </View>}
                {this.state.dbError && (
                  <View>
                    <Text
                      style={{
                        color: 'red',
                        marginTop: 10,
                        fontWeight: '500'
                      }}>{this.state.dbErrorText}</Text>
                  </View>
                )}
                {isLoading ? (
                  <ActivityIndicator
                    size="large"
                    color="#410DAA"
                    style={{ paddingTop: 40 }}
                  />
                ) : (
                  <>
                    {!this.state.showOTP ? (
                      <TouchableOpacity
                        onPress={(event) => {
                          event.preventDefault()
                          Keyboard.dismiss();
                          this.handleContinue();
                        }}
                        disabled={!this.state.capthaVal}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: isMobile ? 20 : 0,
                        }}
                      >
                        <Text style={[styles.req_otp, { backgroundColor: !this.state.capthaVal ? "grey" : "#410DAA" }]}>Send OTP</Text>
                      </TouchableOpacity>
                    ) : (
                      <>
                        <TouchableOpacity
                          onPress={() => {
                            Keyboard.dismiss();
                            this.handleContinue2();
                          }}
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            paddingTop: 30,
                          }}
                        >
                          <Text style={[styles.req_otp, { backgroundColor: "#410DAA" }]}>Verify OTP</Text>
                        </TouchableOpacity>
                        <Text style={[styles.forgotPassword]}>
                          Did not receive an OTP?{" "}
                          <Text
                            style={styles.clickHere}
                            onPress={() => {
                              this.handleResend();
                            }}
                          >
                            RESEND OTP
                          </Text>
                        </Text>
                      </>
                    )}
                    <Text
                      style={[
                        styles.clickHere,
                        {
                          fontSize: 14,
                          paddingTop: 20,
                          textDecorationLine: "underline",
                        },
                      ]}
                      onPress={() =>
                        this.props.navigation.dispatch(
                          StackActions.replace("OnboardingLoginMobile", {})
                        )
                        // this.props.navigation.navigate(
                        //   "OnboardingLoginMobile",
                        //   {}
                        // )
                      }
                    >
                      Login with Password
                    </Text>
                    {/* {!isMobile && !is_ria && (
                      <Text
                        style={[styles.forgotPassword, { marginBottom: 50 }]}
                      >
                        Don't have an account?{" "}
                        <Text
                          style={styles.clickHere}
                          onPress={() => {
                            this.props.navigation.dispatch(
                              StackActions.replace("SignupPage")
                            );
                          }}
                        >
                          Signup Here
                        </Text>
                      </Text>
                    )} */}
                  </>
                )}
              </View>
            </View>
          </View>
          {/* {isMobile && (
            <Text style={[styles.forgotPassword, { marginBottom: 10 }]}>
              Don't have an account?{" "}
              <Text
                style={styles.clickHere}
                onPress={() => {
                  this.props.navigation.dispatch(
                    StackActions.replace("SignupPage")
                  );
                }}
              >
                Signup Here
              </Text>
            </Text>
          )} */}
        </ScrollView>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexGrow: 1,
    height: "100%",
    backgroundColor: "#fff",
  },
  captchaContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: isMobile ? -10 : -20,
    marginBottom: 10,
  },
  body: {
    flexDirection: "row",
    backgroundColor: "#ffffff"
  },
  left: {
    flex: md(width) ? 1 : 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#410DAA08",
    paddingVertical: md(width) ? 75 : 1,
  },
  right: {
    flex: 1
  },
  mob_styl: {
    width: "80%",
    justifyContent: 'center',
    alignSelf: "center",
  },
  web_styl: {
    alignItems: "center",
  },
  best: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 33,
    lineHeight: 38,
    color: "#464E5F",
    paddingBottom: 100,
  },
  left_logo: {
    resizeMode: "contain",
    width: 403,
    height: 241,
    alignSelf: "center",
    marginBottom: 60,
  },
  mobile_verf: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 19 : 15,
    lineHeight: md(width) ? 22 : 17,
    textAlign: "center",
    color: "#121212",
    paddingTop: md(width) ? 0 : 50,
  },
  we_will: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: md(width) ? 17 : 13,
    lineHeight: md(width) ? 20 : 18,
    textAlign: "justify",
    color: "#A7A8BB",
    paddingTop: md(width) ? 19 : 29,
  },
  mob_num: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 18,
    color: "#222225",
    paddingBottom: md(width) ? 23 : 10.5,
  },
  please: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: 13,
    color: "#6C6D72",
    opacity: 0.8,
    paddingTop: 3,
  },
  req_otp: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: 17,
    fontSize: 15,
    color: "#ffffff",
    paddingVertical: 12,
    paddingHorizontal: 47,
    // backgroundColor: "#410DAA",
    borderRadius: 4,
  },
  one: {
    color: "#121212",
  },
  dmart_body_md: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "bold",
    color: "#121212",
    backgroundColor: "#f6f6f6",
    height: 41.02,
    paddingLeft: 20,
    borderRadius: 4,
  },
  sebi: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 14,
    color: "#000",
    paddingRight: 29,
  },
  sebi_1: {
    fontFamily: "Arial",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: 9,
    lineHeight: 14,
    color: "#51514E",
    paddingRight: 29,
  },
  ina: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 10,
    lineHeight: 14,
    color: "#410DAA",
  },
  img1: {
    width: 30,
    height: 28.6,
  },
  welcomeTitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    color: "#202020",
    fontWeight: "bold",
    marginBottom: 45,
    marginTop: 10,
    textAlign: "center",
  },
  inputsContainer: {
    minWidth: "75%",
  },
  inputContainer: {
    marginBottom: 37,
  },
  forgotPassword: {
    color: "#232425",
    fontSize: 15,
    fontFamily: "Roboto",
    marginTop: 18,
    textAlign: "center",
  },
  clickHere: {
    color: "#410DAA",
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 6,
    textAlign: "center",
  },
  certication: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 25,
  },
  sebiContainer: {
    flexDirection: "row",
    paddingTop: 25,
  },
  certicationsContainer: {
    flexDirection: "row",
    paddingLeft: 30.5,
    paddingTop: 65,
    flexWrap: "wrap",
    justifyContent: "center",
  },
});

const mapStateToProps = (state) => {
  return {
    userProfileData: state.userData.userProfileData,
    demoMode: state.testConfig.demoMode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveUserProfileData: (data) => dispatch(saveUserProfileData(data)),
  saveScreenConfig: (data) => dispatch(saveScreenConfig(data)),
  logIn: (time) => dispatch(logIn(time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginUsingUsername);
