import { StackActions } from "@react-navigation/native";
import React from "react";
import { View, StyleSheet, Platform, Image, TouchableOpacity, Dimensions } from "react-native";
import { Text } from "react-native-paper";
import { sm, md, lg, xl, twoxl } from "../../Responsive";
import global from "./globalStyles";
import MyHeader from "./upgradePlanHeader";

const rnWindow = Dimensions.get("window");

export default function MySubscriptionContactConformationPage(props) {

    let forQuery = null;
    const params = props.route;
    if (params) {
        forQuery = params.params.for_query || false;
    }

    return (
        <View style={styles.mainContainer}>
            <View style={{ position: "absolute", top: 0, flex: 1, width: "100%" }}>
                <MyHeader navigation={navigation} />
            </View>
            <View style={styles.container}>
                <Image
                style={styles.img}
                source={require("../../assets/upgradePlan/conformation.png")}
                ></Image>
                <View style={styles.messageContainer}>
                {!forQuery ? (
                    <>
                    <Text style={[styles.message, global.arial]}>
                        Thank you for showing interest in{" "}
                    </Text>
                    <Text style={[styles.message, { color: "#410DAA" }]}>
                        Jewel Membership
                    </Text>
                    </>
                ) : (
                    <Text style={[styles.message, global.arial]}>
                    We have recieved your query
                    </Text>
                )}
                </View>
                <Text style={[styles.sub, global.arial]}>
                Our {!forQuery ? "Sales" : "Support"} team will reach out to you in a
                short while
                </Text>
                <TouchableOpacity
                onPress={() => {
                    if (Platform.OS === "web") {
                    props.navigation.navigate("MySubscription");
                    } else {
                    props.navigation.navigate("MySubscription");
                    }
                }}
                >
                <View style={styles.homeButton}>
                    <Text style={styles.homeButtonText}>Home</Text>
                </View>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: md(rnWindow.width) ? "center" : "stretch",
        justifyContent: "center",
        flexGrow: 1,
        backgroundColor: md(rnWindow.width) ? "#E5E5E5" : "#FFF"
    },
    container: {
        paddingLeft: md(rnWindow.width) ? undefined : 16,
        paddingRight: md(rnWindow.width) ? undefined : 16
    },
    img: {
        resizeMode: "contain",
        width: md(rnWindow.width) ? 302.69 : 198.14,
        height: md(rnWindow.width) ? 272.9 : 178.64,
        alignSelf: "center"
    },
    messageContainer: {
        display: "flex",
        flexDirection: md(rnWindow.width) ? "row" : "column",
        marginTop: md(rnWindow.width) ? 51 : 46,
        alignSelf: "center"
    },
    message: {
        color: "#334D6E",
        fontSize: md(rnWindow.width) ? 30 : 20,
        fontWeight: "bold",
        // alignSelf: 'center',
        textAlign: "center",
        alignSelf: "center"
    },
    sub: {
        fontSize: 15,
        marginTop: 20,
        color: "#3F3D56",
        textAlign: "center",
        width: md(rnWindow.width) ? undefined : 264,
        alignSelf: "center"
    },
    homeButton: {
        backgroundColor: "#410DAA",
        width: md(rnWindow.width) ? 342 : undefined,
        height: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        alignSelf: md(rnWindow.width) ? undefined : "stretch",
        marginTop: md(rnWindow.width) ? 60 : 50
    },
    homeButtonText: {
        color: "#FFF",
        fontSize: 15
    }
});