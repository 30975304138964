import React, { useState } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Image,
  Dimensions,
  Platform,
} from "react-native";
import { Text } from "react-native-paper";
import theme from "../../../../static/theme";
import { getUserDetails, isMobile, reverseString } from "../../../../utils";
import { Button } from "react-native-paper";
import { getHashKeyValue } from "../../../../api";
import { getTokenFromStore } from "../../../../api/apiConfig";

import { LOGOUT } from "../../../../actions/types";
import Store from "../../../../store";
const { width, height } = Dimensions.get("window");

const profileStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 15,
    paddingBottom: 15,
  },
  image: {
    width: 24,
    height: 24,
    resizeMode: "contain",
    marginHorizontal: 16,
  },
  labelContainer: { flex: 1 },
  activeBorder: {
    backgroundColor: "#410DAA08",
  },
  divider: {
    width: "100%",
    height: 0.5,
    backgroundColor: "#ccc",
  },
});

const SideNav = ({ style = [], onSelect, ...props }) => {
  let selectedAccTab;
  const [active, setActive] = React.useState("ManageFamily");
  const [showProfileSubMenu, setShowProfileSubMenu] = React.useState(
    width < 1200 ? false : selectedAccTab == "AboutMe" ? true : false
  );
  const [showBankSubMenu, setShowBankSubMenu] = React.useState(
    selectedAccTab == "BankAccounts" ? true : false
  );
  const [showFinPlanSubMenu, setShowFinPlanSubMenu] = React.useState(
    selectedAccTab == "RiskProfileHome" ? true : false
  );
  const [showSettingSubMenu, setShowSettingSubMenu] = React.useState(
    selectedAccTab == "ChangePassword" ? true : false
  );
  const [showAboutSubMenu, setShowAboutSubMenu] = React.useState(
    selectedAccTab == "InvestorCharter" ? true : false
  );
  const [showBillingSubMenu, setShowBillingSubMenu] = React.useState(
    selectedAccTab == "Overdue" ? true : false
  );
  const [selectedProfileTab, setSelectedProfileTab] =
    React.useState("Personal Details");
  const [selectedBankTab, setSelectedBankTab] = React.useState("Bank Accounts");
  const [selectedFinPlanTab, setSelectedFinPlanTab] =
    React.useState("Risk Profile");
  const [selectedSettingTab, setSelectedSettingTab] =
    React.useState("Change Password");
  const [selectedAboutTab, setSelectedAboutTab] =
    React.useState("Investor Charter");
  const [selectedBillingTab, setSelectedBillingTab] =
    React.useState("Overdue Invoices");
  const [showOptions, setShowOptions] = useState(true);
  const [loggingOut, setLoggingOut] = useState(false);

  const ProfileSubMenu = ({ label, onPress }) => {
    return (
      <TouchableOpacity onPress={onPress} style={styles.subMenuItem}>
        <Text
          style={[
            styles.subMenuItemText,
            { color: selectedProfileTab == label ? "#410DAA" : "#929292" },
          ]}
        >
          {label}
        </Text>
      </TouchableOpacity>
    );
  };

  const BankSubMenu = ({ label, onPress }) => {
    return (
      <TouchableOpacity onPress={onPress} style={styles.subMenuItem}>
        <Text
          style={[
            styles.subMenuItemText,
            { color: selectedBankTab == label ? "#410DAA" : "#929292" },
          ]}
        >
          {label}
        </Text>
      </TouchableOpacity>
    );
  };

  const FinPlanSubMenu = ({ label, onPress }) => {
    return (
      <TouchableOpacity onPress={onPress} style={styles.subMenuItem}>
        <Text
          style={[
            styles.subMenuItemText,
            { color: selectedFinPlanTab == label ? "#410DAA" : "#929292" },
          ]}
        >
          {label}
        </Text>
      </TouchableOpacity>
    );
  };

  const SettingSubMenu = ({ label, onPress }) => {
    return (
      <TouchableOpacity onPress={onPress} style={styles.subMenuItem}>
        <Text
          style={[
            styles.subMenuItemText,
            { color: selectedSettingTab == label ? "#410DAA" : "#929292" },
          ]}
        >
          {label}
        </Text>
      </TouchableOpacity>
    );
  };

  const AboutSubMenu = ({ label, onPress }) => {
    return (
      <TouchableOpacity onPress={onPress} style={styles.subMenuItem}>
        <Text
          style={[
            styles.subMenuItemText,
            { color: selectedAboutTab == label ? "#410DAA" : "#929292" },
          ]}
        >
          {label}
        </Text>
      </TouchableOpacity>
    );
  };

  const BillingSubMenu = ({ label, onPress }) => {
    return (
      <TouchableOpacity onPress={onPress} style={styles.subMenuItem}>
        <Text
          style={[
            styles.subMenuItemText,
            { color: selectedBillingTab == label ? "#410DAA" : "#929292" },
          ]}
        >
          {label}
        </Text>
      </TouchableOpacity>
    );
  };

  const ProfileRowItem = ({
    icon,
    name,
    onPress,
    active,
    value,
    rightComponent = null,
  }) => {
    const handlePress = () => {
      onPress(value);
    };

    return (
      <View>
        <View style={profileStyles.divider} />
        <TouchableOpacity style={profileStyles.container} onPress={handlePress}>
          <Image source={{ uri: icon }} style={profileStyles.image} />
          <View style={profileStyles.labelContainer}>
            <Text
              style={{
                color: active ? "#410DAA" : theme.textColors.PRIMARY_FONT,
                fontSize: 13,
              }}
            >
              {name}
            </Text>
          </View>
          {rightComponent && (
            <View style={styles.rightComponent}>{rightComponent}</View>
          )}
        </TouchableOpacity>
      </View>
    );
  };

  const handleProfileSubMenu = (label, key, redirect = false) => {
    setSelectedProfileTab(label);
    onSelect(key, redirect);
  };

  const handleBankSubMenu = (label, key, redirect = false) => {
    setSelectedBankTab(label);
    onSelect(key, redirect);
  };

  const handleFinPlanSubMenu = (label, key, redirect = false) => {
    setSelectedFinPlanTab(label);
    onSelect(key, redirect);
  };

  const handleSettingSubMenu = (label, key, redirect = false) => {
    setSelectedSettingTab(label);
    onSelect(key, redirect);
  };

  const handleAboutSubMenu = (label, key, redirect = false) => {
    setSelectedAboutTab(label);
    onSelect(key, redirect);
  };

  const handleBillingSubMenu = (label, key, moreDetails, redirect = false) => {
    setSelectedBillingTab(label);
    onSelect(key, redirect, "Billing", moreDetails);
  };

  const handlePress = (key, redirect = false) => {
    setActive(key);
    (key == "AboutMe" ||
      key == "BankAccounts" ||
      key == "RiskProfileHome" ||
      key == "Overdue" ||
      key == "ChangePassword" ||
      key == "InvestorCharter") &&
    width < 1200
      ? null
      : onSelect(key, redirect, "Account");

    if (key == "AboutMe") {
      setShowProfileSubMenu(!showProfileSubMenu);
      setShowBankSubMenu(false);
      setShowFinPlanSubMenu(false);
      setShowSettingSubMenu(false);
      setShowAboutSubMenu(false);
      setShowBillingSubMenu(false);
      setSelectedBankTab("Bank Accounts");
      setSelectedFinPlanTab("Risk Profile");
      setSelectedSettingTab("Change Password");
      setSelectedAboutTab("Investor Charter");
      setSelectedBillingTab("Overdue Invoices");
      return;
    }
    if (key == "BankAccounts") {
      setShowBankSubMenu(!showBankSubMenu);
      setShowFinPlanSubMenu(false);
      setShowSettingSubMenu(false);
      setShowAboutSubMenu(false);
      setShowBillingSubMenu(false);
      setShowProfileSubMenu(false);
      setSelectedProfileTab("Personal Details");
      setSelectedSettingTab("Change Password");
      setSelectedAboutTab("Investor Charter");
      setSelectedBillingTab("Overdue Invoices");
      setSelectedFinPlanTab("Risk Profile");
      return;
    }
    if (key == "RiskProfileHome") {
      setShowFinPlanSubMenu(!showFinPlanSubMenu);
      setShowSettingSubMenu(false);
      setShowAboutSubMenu(false);
      setShowBankSubMenu(false);
      setShowProfileSubMenu(false);
      setShowBillingSubMenu(false);
      setSelectedBankTab("Bank Accounts");
      setSelectedProfileTab("Personal Details");
      setSelectedSettingTab("Change Password");
      setSelectedAboutTab("Investor Charter");
      setSelectedBillingTab("Overdue Invoices");
      return;
    }
    if (key == "ChangePassword") {
      setShowSettingSubMenu(!showSettingSubMenu);
      setShowAboutSubMenu(false);
      setShowFinPlanSubMenu(false);
      setShowBankSubMenu(false);
      setShowProfileSubMenu(false);
      setShowBillingSubMenu(false);
      setSelectedBankTab("Bank Accounts");
      setSelectedProfileTab("Personal Details");
      setSelectedFinPlanTab("Risk Profile");
      setSelectedAboutTab("Investor Charter");
      setSelectedBillingTab("Overdue Invoices");
      return;
    }
    if (key == "InvestorCharter") {
      setShowAboutSubMenu(!showAboutSubMenu);
      setShowSettingSubMenu(false);
      setShowFinPlanSubMenu(false);
      setShowBankSubMenu(false);
      setShowProfileSubMenu(false);
      setShowBillingSubMenu(false);
      setSelectedBankTab("Bank Accounts");
      setSelectedProfileTab("Personal Details");
      setSelectedFinPlanTab("Risk Profile");
      setSelectedSettingTab("Change Password");
      setSelectedBillingTab("Overdue Invoices");
      return;
    }
    if (key == "Overdue") {
      setShowBillingSubMenu(!showBillingSubMenu);
      setShowFinPlanSubMenu(false);
      setShowSettingSubMenu(false);
      setShowAboutSubMenu(false);
      setShowBankSubMenu(false);
      setShowProfileSubMenu(false);
      setSelectedBankTab("Bank Accounts");
      setSelectedProfileTab("Personal Details");
      setSelectedFinPlanTab("Risk Profile");
      setSelectedSettingTab("Change Password");
      setSelectedAboutTab("Investor Charter");
      return;
    }
    setSelectedBankTab("Bank Accounts");
    setSelectedProfileTab("Personal Details");
    setSelectedFinPlanTab("Risk Profile");
    setSelectedSettingTab("Change Password");
    setSelectedAboutTab("Investor Charter");
    setSelectedBillingTab("Overdue Invoices");
    setShowProfileSubMenu(false);
    setShowBankSubMenu(false);
    setShowFinPlanSubMenu(false);
    setShowSettingSubMenu(false);
    setShowAboutSubMenu(false);
    setShowBillingSubMenu(false);
  };

  const handleLogout = () => {
    setShowOptions(false);
  };

  const handleDeActivateAccount = (label, key, redirect = false) => {
    setSelectedSettingTab("DeActivate Account");
    onSelect(key, redirect);
  };

  const handleCancelLogout = () => {
    setShowOptions(true);
  };

  const handleContinueToLogout = async () => {
    try {
      if (isMobile) {
        Store.dispatch({ type: LOGOUT });
      } else {
        setLoggingOut(true);
        const response = await getHashKeyValue();
        if (response && response.code == 100) {
          Store.dispatch({ type: LOGOUT });
          const { hash_key } = response.result;
          let token = getTokenFromStore();
          token = reverseString(token);
          if (width < 1200) {
            props.navigation.dispatch(StackActions.replace("Login"));
          } else {
            global.open(
              MAIN_URL +
                URLS.LOGOUT_URL +
                (hash_key + token + "/" + GATEWAY_ENVIRONMENT),
              "_self"
            );
          }
        } else {
          setLoggingOut(false);
          Store.dispatch({ type: LOGOUT });
        }
      }
    } catch (error) {
      setLoggingOut(false);
      Store.dispatch({ type: LOGOUT });
    }
  };

  let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
  let is_club = false;
  if (hostname.includes("club")) {
    is_club = true;
  }

  let { is_ria } = getUserDetails();
  return showOptions ? (
    <ScrollView
      style={[styles.container, ...style]}
      showsVerticalScrollIndicator={true}
    >
      <ProfileRowItem
        icon={
          active == "ManageFamily"
            ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/member-active.png"
            : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/member.png"
        }
        name={"Add Family"}
        onPress={handlePress}
        active={active == "ManageFamily"}
        value={"ManageFamily"}
        rightComponent={
          <Image
            source={{
              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-right.png",
            }}
            style={{
              width: 10,
              height: 10,
              resizeMode: "contain",
            }}
          />
        }
      />
      <ProfileRowItem
        icon={
          active == "AboutMe"
            ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/profile-active.png"
            : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/profile.png"
        }
        name={"Profile"}
        onPress={handlePress}
        active={active == "AboutMe"}
        value={"AboutMe"}
        rightComponent={
          <Image
            source={{
              uri: !showProfileSubMenu
                ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-right.png"
                : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-up.png",
            }}
            style={{
              width: 10,
              height: 10,
              resizeMode: "contain",
            }}
          />
        }
      />
      {showProfileSubMenu && (
        <View style={styles.subMenuContainer}>
          <ProfileSubMenu
            label="Personal Details"
            onPress={() => handleProfileSubMenu("Personal Details", "AboutMe")}
          />
          <ProfileSubMenu
            label="Professional Details"
            onPress={() =>
              handleProfileSubMenu(
                "Professional Details",
                "AddProfessionalDetails"
              )
            }
          />
          <ProfileSubMenu
            label="Nominee Details"
            onPress={() =>
              handleProfileSubMenu("Nominee Details", "AddNominee")
            }
          />
          <ProfileSubMenu
            label="Address"
            onPress={() => handleProfileSubMenu("Address", "AddAddress")}
          />
        </View>
      )}
      <ProfileRowItem
        icon={
          active == "BankAccounts"
            ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/account-active.png"
            : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/account.png"
        }
        name={"Accounts"}
        onPress={handlePress}
        active={active == "BankAccounts"}
        value={"BankAccounts"}
        rightComponent={
          <Image
            source={{
              uri: !showBankSubMenu
                ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-right.png"
                : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-up.png",
            }}
            style={{
              width: 10,
              height: 10,
              resizeMode: "contain",
            }}
          />
        }
      />
      {showBankSubMenu && (
        <View style={styles.subMenuContainer}>
          <BankSubMenu
            label="Bank Accounts"
            onPress={() => handleBankSubMenu("Bank Accounts", "BankAccounts")}
          />
          <BankSubMenu
            label="Bank Mandates to make investments"
            onPress={() =>
              handleBankSubMenu("Bank Mandates to make investments", "eMandate")
            }
          />
          <BankSubMenu
            label="Bill Payment Mandates"
            onPress={() =>
              handleBankSubMenu("Bill Payment Mandates", "NachMandate")
            }
          />
          {is_club ? null : (
            <BankSubMenu
              label="Demat Accounts"
              onPress={() => handleBankSubMenu("Demat Accounts", "Demat")}
            />
          )}
        </View>
      )}
      <ProfileRowItem
        icon={
          active == "RiskProfileHome"
            ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/risk-active.png"
            : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/risk.png"
        }
        name={"Risk Profile & Financial Plan"}
        onPress={handlePress}
        active={active == "RiskProfileHome"}
        value={"RiskProfileHome"}
        rightComponent={
          <Image
            source={{
              uri: !showFinPlanSubMenu
                ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-right.png"
                : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-up.png",
            }}
            style={{
              width: 10,
              height: 10,
              resizeMode: "contain",
            }}
          />
        }
      />
      {showFinPlanSubMenu && (
        <View style={styles.subMenuContainer}>
          <FinPlanSubMenu
            label="Risk Profile"
            onPress={() =>
              handleFinPlanSubMenu("Risk Profile", "RiskProfileHome")
            }
          />
          <FinPlanSubMenu
            label="Financial Plan"
            onPress={() =>
              handleFinPlanSubMenu("Financial Plan", "ResetFinplan")
            }
          />
        </View>
      )}
      {Platform.OS != "web" ? (
        <ProfileRowItem
          icon={
            active == "Overdue"
              ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/billing-active.png"
              : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/billing.png"
          }
          name={"Billing"}
          onPress={handlePress}
          active={active == "Overdue"}
          value={"Overdue"}
          rightComponent={
            <Image
              source={{
                uri: !showBillingSubMenu
                  ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-right.png"
                  : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-up.png",
              }}
              style={{
                width: 10,
                height: 10,
                resizeMode: "contain",
              }}
            />
          }
        />
      ) : null}
      {showBillingSubMenu && (
        <View style={styles.subMenuContainer}>
          <BillingSubMenu
            label="Overdue Invoices"
            onPress={() =>
              handleBillingSubMenu("Overdue Invoices", "Invoices", 0)
            }
          />
          <BillingSubMenu
            label="Paid Invoices"
            onPress={() => handleBillingSubMenu("Paid Invoices", "Invoices", 1)}
          />
          <BillingSubMenu
            label="Investment Plan Wise Ledger"
            onPress={() =>
              handleBillingSubMenu(
                "Investment Plan Wise Ledger",
                "BillingLedger",
                2
              )
            }
          />
          {/* <BillingSubMenu
            label="Setup bill payment mandate"
            onPress={() => {}}
          /> */}
        </View>
      )}
      {is_ria ? null : (
        <ProfileRowItem
          icon={
            active == "Referrals"
              ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/refer-active.png"
              : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/refer.png"
          }
          name={"Refer & Earn Credits"}
          onPress={handlePress}
          active={active == "Referrals"}
          value={"Referrals"}
          rightComponent={
            <Image
              source={{
                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-right.png",
              }}
              style={{
                width: 10,
                height: 10,
                resizeMode: "contain",
              }}
            />
          }
        />
      )}
      {!is_club && (
        <ProfileRowItem
          icon={
            active == "Membership"
              ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/member-active.png"
              : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/member.png"
          }
          name={"Membership"}
          onPress={handlePress}
          active={active == "Membership"}
          value={"Membership"}
          rightComponent={
            <Image
              source={{
                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-right.png",
              }}
              style={{
                width: 10,
                height: 10,
                resizeMode: "contain",
              }}
            />
          }
        />
      )}
      <ProfileRowItem
        icon={
          active == "ChangePassword"
            ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/setting-active.png"
            : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/setting.png"
        }
        name={"Settings"}
        onPress={handlePress}
        active={active == "ChangePassword"}
        value={"ChangePassword"}
        rightComponent={
          <Image
            source={{
              uri: !showSettingSubMenu
                ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-right.png"
                : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-up.png",
            }}
            style={{
              width: 10,
              height: 10,
              resizeMode: "contain",
            }}
          />
        }
      />
      {showSettingSubMenu && (
        <View style={styles.subMenuContainer}>
          <SettingSubMenu
            label="Change Password"
            onPress={() =>
              handleSettingSubMenu("Change Password", "ChangePassword")
            }
          />
          {/* <SettingSubMenu label="Set Mobile PIN" onPress={() => {}} /> */}
          <SettingSubMenu label="Logout" onPress={handleLogout} />
          {Platform.OS == "ios" && (
            <SettingSubMenu
              label="DeActivate Account"
              onPress={() =>
                handleDeActivateAccount(
                  "DeActivate Account",
                  "DeActivateAccount"
                )
              }
            />
          )}
        </View>
      )}
      {is_ria ? null : (
        <ProfileRowItem
          icon={
            active == "ReportsHome"
              ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/report-active.png"
              : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/report.png"
          }
          name={"Reports"}
          onPress={handlePress}
          active={active == "ReportsHome"}
          value={"ReportsHome"}
          rightComponent={
            <Image
              source={{
                uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-right.png",
              }}
              style={{
                width: 10,
                height: 10,
                resizeMode: "contain",
              }}
            />
          }
        />
      )}
      {is_ria ? null : (
        <ProfileRowItem
          icon={
            active == "InvestorCharter"
              ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/about-active.png"
              : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/about.png"
          }
          name={"About"}
          onPress={handlePress}
          active={active == "InvestorCharter"}
          value={"InvestorCharter"}
          rightComponent={
            <Image
              source={{
                uri: !showAboutSubMenu
                  ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-right.png"
                  : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/arrow-up.png",
              }}
              style={{
                width: 10,
                height: 10,
                resizeMode: "contain",
              }}
            />
          }
        />
      )}
      {showAboutSubMenu && (
        <View style={styles.subMenuContainer}>
          <AboutSubMenu
            label="Investor Charter"
            onPress={() =>
              handleAboutSubMenu("Investor Charter", "InvestorCharter")
            }
          />
          <AboutSubMenu
            label="Investor Complaints"
            onPress={() =>
              handleAboutSubMenu("Investor Complaints", "InvestorComplaints")
            }
          />
          <AboutSubMenu label={`Version: 2.0.8`} onPress={() => {}} />
        </View>
      )}
      <View style={profileStyles.divider} />
    </ScrollView>
  ) : loggingOut && width < 1200 ? (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: width,
        height: height / 2,
        backgroundColor: "white",
      }}
    >
      <Image
        source={{
          uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Broker+logout.png",
        }}
        style={{
          width: 28,
          height: 28,
          marginRight: 10,
        }}
      />
      <Text
        style={{
          fontFamily: "Roboto",
          fontSize: width < 1200 ? 15 : 18,
          color: "#121212",
        }}
      >
        Logging out from Jamā Wealth
      </Text>
    </View>
  ) : (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: width < 1200 ? width : "100%",
        height: height / 1.5,
        backgroundColor: "white",
      }}
    >
      <View
        style={{
          height: 180,
          width: 420,
          padding: 30,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: "rgba(0, 0, 0, 0.1)",
          backgroundColor: "white",
        }}
      >
        <View onDismiss={handleCancelLogout}>
          <View
            style={{
              flexDirection: "row",
              marginLeft: 60,
              marginBottom: 44,
              marginTop: 10,
            }}
          >
            <Text style={alert.sure}>Are you sure you want to Logout?</Text>
          </View>
          <View style={alert.buttons}>
            <Button
              uppercase={false}
              onPress={handleCancelLogout}
              style={{
                alignSelf: "center",
                backgroundColor: "white",
              }}
            >
              <Text
                style={{ fontSize: 14, fontWeight: "700", color: "#410DAA" }}
              >
                Cancel
              </Text>
            </Button>
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={handleContinueToLogout}
              style={{
                alignSelf: "center",
                marginRight: 40,
              }}
            >
              <Text style={{ fontSize: 14, fontWeight: "700", color: "white" }}>
                Logout
              </Text>
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
    borderRightWidth: 1,
    borderRightColor: "#ccc",
    paddingBottom: 20,
    marginBottom: 86,
    paddingHorizontal: 20,
  },
  rightComponent: {
    marginRight: 5,
  },
  subMenuContainer: {
    marginTop: 10,
    marginLeft: 41,
  },
  subMenuItem: {
    maxWidth: width * 0.9,
  },
  subMenuItemText: {
    fontSize: 14,
    paddingVertical: 6,
    marginLeft: 15,
    marginRight: 10,
  },
});

const alert = StyleSheet.create({
  body: {
    paddingHorizontal: width < 1200 ? 16 : 42,
    paddingVertical: width < 1200 ? 16 : 24,
    flex: 1,
    alignSelf: "center",
    backgroundColor: "#fff",
    zIndex: 10,
    borderRadius: 10,
    borderRadius: 10,
    width: width < 1200 ? "90%" : undefined,
  },
  containerStyle: {},
  confirm: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 18,
    color: "#121212",
    marginBottom: 11,
  },
  sureContainer: {
    marginBottom: 44,
  },
  sure: {
    fontFamily: "Roboto",
    fontSize: 16,
    color: "#121212",
  },
  buttons: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 23,
    marginLeft: 50,
  },
  labelStyle: {
    color: "#2D3748",
    fontSize: 14,
    fontWeight: "bold",
    paddingHorizontal: 40,
    paddingVertical: 5,
  },
  buttonStyle: {
    marginHorizontal: 12,
  },
  image: { width: 19, height: 17, resizeMode: "contain", marginRight: 7 },
});

export default SideNav;
