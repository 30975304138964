import React from "react";
import Icon from "@expo/vector-icons/FontAwesome";
import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  Button as RNButton,
  Linking,
} from "react-native";
import { Button, Text } from "react-native-paper";
import Checkbox from "expo-checkbox";
import {
  generateCamsAggregatorLink,
  getAccountAggregatorConsentStatus,
  getCipsAboutToExpire,
  getCustomerDetails,
  getCustomerPortfolioData,
  getCustomerReport,
  getDashboard,
  scheduleCronJobWithApi,
  updateCustomerPortfolioData,
  viewMFMonthlyReport,
  viewMonthlyReport,
} from "../../../api";
import {
  capitalizeFirstLetter,
  completeMinDetails,
  getScreenConfigForCustomer,
  getUserDetails,
  holdingPeriodText,
  isMobile,
  months_short_names,
  numberWithCommas,
} from "../../../utils";
import { md } from "../../../Responsive";
import { NetworthCard } from "./components/netWorthCard";
import SideHeading from "./components/SideHeading";
import SummaryCard from "./components/SummaryData";
import MutualFundPlans from "./components/MutualFundPlans";
import GetRicherStrip from "../../../screens/dashboardHome/getRicherStrip";
import { StockTabs } from "./StockTabs";
import { FontAwesome5 } from "@expo/vector-icons";
import { AllAssetsCard } from "./AllAssetsCard";
import { AssetTabs } from "./AssetTabs";
import SearchBar from "../../Common/SearchBar";
import AssetTable from "./AssetTable";
import { MFTabs } from "./MFTabs";
import moment from "moment";
import StockTable from "./StockTable";
import MFTable from "./MFTable";
import Table from "../../Common/Table";
import PlanIndiCard from "./components/planIndData";
import { SparkTeaser } from "../SparkTeaser";
import { ShowToast } from "../../Common/Toast";
import axios from "axios";
import { StackActions } from "@react-navigation/native";
// import ReactExport from "react-export-excel";
// const ExcelFile = ReactExport.ExcelFile;
import * as XLSX from 'xlsx';
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import amplitude from "../../../utils/amplitude";
import BulletPoint from "./components/BulletPoint";
// import RNFetchBlob from "rn-fetch-blob";
import AntDesign from "@expo/vector-icons/AntDesign";
import EmployeeTable from "../../../screens/FinancialWellnessIndex/EmployeeTable";
import NoPlanModal from "../noPlanModal";
import ProgressBar from "../../../utils/progressBar";
import { Entypo } from "@expo/vector-icons";
import Modal from "../../Common/Modal.js";

// import {
//   Menu,
//   MenuOptions,
//   MenuOption,
//   MenuTrigger,
//   MenuProvider,
// } from 'react-native-popup-menu';

const { width } = Dimensions.get("window");
const isWeb = md(width);
const ArrowIcon = require("../../../assets/JamaInvest/Arrow-right2x.png");
import { ProductSummary } from "./components/productSummary";
import Store from "../../../store/index.js";

const SelectProduct = ({
  reportLoading,
  viewReport,
  navigation,
  showStocksOf,
  excelText,
  excelData,
  is_ria,
  props,
}) => {
  return (
    <View style={select.container}>
      {/* <View style={select.body}>
        {!isMobile &&
          Dimensions.get("window").width / Dimensions.get("window").height >
            2.1 && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                justifyContent: "space-between",
                marginTop: 21,
                width: Dimensions.get("window").width * 0.82,
              }}
            >
              <View style={{ display: "flex" }}>
                <Text
                  style={{
                    color: "#410DAA",
                    fontWeight: "bold",
                    fontSize: 15,
                    textAlign: "left",
                    textDecorationLine: "underline",
                  }}
                ></Text>
              </View>
              <View
                style={{
                  flex: 8,
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  marginRight: 20,
                }}
              ></View>
              <View
                style={{
                  flex: 1.7,
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  marginRight: 20,
                }}
              >
                {reportLoading ? (
                  <View style={styles.loadingConainer}>
                    <ActivityIndicator size="large" color="#410DAA" />
                  </View>
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      viewReport();
                    }}
                  >
                    <Text
                      style={{
                        color: "#410DAA",
                        fontWeight: "bold",
                        fontSize: 15,
                        textAlign: "right",
                        marginRight: isMobile ? "auto" : 25,
                        textDecorationLine: "underline",
                      }}
                    >
                      Monthly Report
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          )}
      </View>
      {(isMobile ||
        Dimensions.get("window").width / Dimensions.get("window").height <=
          2.1) && (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 10,
            justifyContent: "space-between",
            marginTop: 21,
          }}
        >
          <View
            style={{
              flex: 1,
              cursor: "pointer",
              justifyContent: "flex-end",
              flexDirection: "row",
              marginRight: 20,
            }}
          ></View>
          <View
            style={{
              flex: 1,
              cursor: "pointer",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            {reportLoading ? (
              <View style={styles.loadingConainer}>
                <ActivityIndicator size="large" color="#410DAA" />
              </View>
            ) : (
              <TouchableOpacity
                onPress={() => {
                  viewReport();
                }}
              >
                <Text
                  style={{
                    color: "#410DAA",
                    fontWeight: "bold",
                    fontSize: 15,
                    textAlign: "right",
                    marginRight: isMobile ? "auto" : 25,
                    textDecorationLine: "underline",
                  }}
                >
                  Monthly Report
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      )} */}
      {/* <View style={{ flex: 8.6, flexDirection: "row" }}>
        <Text style={[select.product, { marginTop: 8, fontWeight: 'bold' }]}>
          {showStocksOf == "Outside Stocks"
            ? "Outside"
            : showStocksOf == "Inside Stocks"
              ? "Inside"
              : showStocksOf}{" "}
          Stock Holdings
        </Text>
      </View> */}
    </View>
  );
};

const MFHoldingDetails = ({
  selectedMFTab,
  mfReportLoading,
  viewMFReport,
  excelText,
  excelData,
}) => {
  return (
    <View style={select.container}>
      {/* <View style={select.body}>
        {!isMobile &&
          Dimensions.get("window").width / Dimensions.get("window").height >
            2.1 && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                justifyContent: "space-between",
                marginTop: 21,
                width: Dimensions.get("window").width * 0.82,
              }}
            >
              <View style={{ display: "flex" }}>
                <Text
                  style={{
                    color: "#410DAA",
                    fontWeight: "bold",
                    fontSize: 15,
                    textAlign: "left",
                    textDecorationLine: "underline",
                  }}
                ></Text>
              </View>
              <View
                style={{
                  flex: 8,
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  marginRight: 20,
                }}
              ></View>
              <View
                style={{
                  flex: 1.7,
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  marginRight: 20,
                }}
              >
                {selectedMFTab == "Jama" ? (
                  mfReportLoading ? (
                    <View style={styles.loadingConainer}>
                      <ActivityIndicator size="large" color="#410DAA" />
                    </View>
                  ) : (
                    <TouchableOpacity
                      onPress={() => {
                        viewMFReport();
                      }}
                    >
                      <Text
                        style={{
                          color: "#410DAA",
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "right",
                          marginRight: isMobile ? "auto" : 25,
                          textDecorationLine: "underline",
                        }}
                      >
                        Monthly Report
                      </Text>
                    </TouchableOpacity>
                  )
                ) : null}
              </View>
            </View>
          )}
      </View>
      {(isMobile ||
        Dimensions.get("window").width / Dimensions.get("window").height <=
          2.1) && (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 10,
            justifyContent: "space-between",
            marginTop: isMobile ? 60 : 21,
          }}
        >
          <View
            style={{
              flex: 1,
              cursor: "pointer",
              justifyContent: "flex-end",
              flexDirection: "row",
              marginRight: 20,
            }}
          ></View>
          <View
            style={{
              flex: 1,
              cursor: "pointer",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            {selectedMFTab == "Jama" ? (
              mfReportLoading ? (
                <View style={styles.loadingConainer}>
                  <ActivityIndicator size="large" color="#410DAA" />
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    viewMFReport();
                  }}
                >
                  <Text
                    style={{
                      color: "#410DAA",
                      fontWeight: "bold",
                      fontSize: 15,
                      textAlign: "right",
                      marginRight: isMobile ? "auto" : 25,
                      textDecorationLine: "underline",
                    }}
                  >
                    Monthly Report
                  </Text>
                </TouchableOpacity>
              )
            ) : null}
          </View>
        </View>
      )} */}
      <View style={{ flex: 8.6, flexDirection: "row" }}>
        <Text style={[select.product, { marginTop: 8 }]}>
          {selectedMFTab == "Jama"
            ? "Inside"
            : selectedMFTab == "Regular"
              ? "Regular"
              : "Outside"}{" "}
          Fund Holdings
        </Text>
      </View>
    </View>
  );
};

const select = StyleSheet.create({
  container: {
    marginTop: 10,
    marginBottom: 20,
    marginLeft: isMobile ? 16 : 20,
    marginRight: isMobile ? 16 : 10,
  },
  product: {
    color: "#464E5F",
    fontSize: 14,
    fontFamily: "Roboto",
    marginRight: 20,
  },
  body: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  button: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: isWeb ? 14 : 13,
    color: "#FFFFFF",
    backgroundColor: "#410DAA",
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
});

const SelectTabDetails = ({ item, onPress, backgroundColor, color }) => {
  return (
    <TouchableOpacity
      style={{
        borderWidth: 0.5,
        borderColor: "#BCBCBC",
        borderRadius: 18,
        backgroundColor,
        marginRight: 16,
        paddingLeft: 20,
        paddingRight: 20,
        height: 36,
        alignItems: "center",
        justifyContent: "center",
      }}
      onPress={onPress}
    >
      <Text style={{ fontSize: 14, color }}>{item}</Text>
    </TouchableOpacity>
  );
};

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let activeTab = "Summary";
    let userId = null;
    let user_name = null;
    let selectedAssetSubTab = 0;
    if (params) {
      activeTab = params.activeTab || "Summary";
      userId = params.user_id || null;
      selectedAssetSubTab = params.selectedAssetSubTab || 0;
      user_name = params.user_name || null;
      userId = isNaN(userId) ? null : userId;
    }
    let is_preferred_circle = false;
    let is_elite = false;
    let is_club = false;
    let hostname = Platform.OS == "web" ? global.location.hostname : "mobile";
    if (hostname.includes("myadvisor")) {
      is_preferred_circle = true;
    }
    if (
      hostname.includes("app.jamawealth") ||
      hostname.includes("dapp.jamawealth")
    ) {
      is_elite = true;
    }
    if (hostname.includes("club")) {
      is_club = true;
    }
    this.state = {
      savedState: null,
      isModalVisible: false,
      resp: null,
      isLoading: true,
      isPopupLoading: false,
      excelTextValue: [],
      excelDataValue: [],
      show_options: false,
      local_id: null,
      errorText: null,
      dataToShow: null,
      is_ftu: false,
      familyTabsList: [],
      selectedFamilyTab: 1,
      activeTab: activeTab,
      userId,
      user_name,
      timer: 0,
      is_preferred_circle,
      is_elite,
      is_club,
      investTotalUser: null,
      investValue: null,
      investValueWithStockMF: null,
      investValueWithAsset: null,
      investValueFull: null,
      assetCheckbox: false,
      stockMFCheckbox: false,
      summary: null,
      is_pms_client: false,
      data_values: null,
      investedValue: null,
      investedValueWithStockMF: null,
      investedValueWithAsset: null,
      investedValueFull: null,
      gainValue: null,
      gainValueWithStockMF: null,
      gainValueWithAsset: null,
      gainValueFull: null,
      gainPercentValue: null,
      gainPercentValueWithStockMF: null,
      gainPercentValueWithAsset: null,
      gainPercentValueFull: null,
      overallReturnValue: null,
      overallReturnPercent: null,
      selectedAssetSubTab,
      avg_holding_period: null,
      isSwitchOn: true,
      stocks_summary: null,
      show_zero_holding: false,
      zeroHoldingResp: {},
      selectedMFTab: "Jama",
      mfInJamaData: null,
      mfOutsideData: null,
      AllAssetsData: null,
      assetDataValue: null,
      filteredAsset: null,
      current_user: null,
      selectedAssetTab: "Stocks",
      sorting_field: null,
      sorting_value: null,
      shouldShowMF: false,
      selectedStockTab: "Inside Stocks",
      showStocksOf: "Inside Stocks",
      holdingsData: {},
      showTeaser: null,
      reportLoading: false,
      reportLoaded: false,
      loadingData: false,
      userName: "",
      is_ria: false,
      mfReportLoading: false,
      outsideLatestAmount: 0,
      outsideInvestedAmount: 0,
      strips: null,
      redoTaggingLoader: false,
      jobScheduled: false,
      selectedIndex: null,
    };
  }

  viewReport = async () => {
    this.setState({ reportLoading: true, reportLoaded: false });
    const monthlyReportResp = await viewMonthlyReport(this.state.current_user);
    if (monthlyReportResp && monthlyReportResp.code == 100) {
      this.setState({ reportLoading: false, reportLoaded: true });
      if (isMobile) {
        const { config, fs } = RNFetchBlob;
        let options = {
          fileCache: true,
          addAndroidDownloads: {
            useDownloadManager: true,
            notification: true,
            description: "",
          },
        };
        config(options)
          .fetch("GET", monthlyReportResp.results[0].document_s3_url)
          .then((res) => { });
      } else {
        axios({
          url: monthlyReportResp.results[0].document_s3_url,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.state.userName} - Equity Portfolio (${moment().format(
              "DD-MM-YYYY hh-mm A"
            )}).pdf`
          );
          document.body.appendChild(link);
          link.click();
        });
      }
    } else {
      this.setState({ reportLoading: false });
    }
  };

  viewMFReport = async () => {
    try {
      this.setState({ mfReportLoading: true, reportLoaded: false });
      let mfReportResp = await viewMFMonthlyReport(this.state.current_user);
      if (mfReportResp && mfReportResp.code == 100) {
        this.setState({ mfReportLoading: false, reportLoaded: true });
        if (isMobile) {
          const { config, fs } = RNFetchBlob;
          let options = {
            fileCache: true,
            addAndroidDownloads: {
              useDownloadManager: true,
              notification: true,
              description: "",
            },
          };
          config(options)
            .fetch("GET", mfReportResp.results[0].document_s3_url)
            .then((res) => { });
        } else {
          axios({
            url: mfReportResp.results[0].document_s3_url,
            method: "GET",
            responseType: "blob",
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${this.state.userName} - MF Portfolio (${moment().format(
                "DD-MM-YYYY hh-mm A"
              )}).pdf`
            );
            document.body.appendChild(link);
            link.click();
          });
        }
      }
    } catch (err) {
      this.setState({ mfReportLoading: false });
    }
  };

  onToggleSwitch = () => {
    this.setState({ isSwitchOn: !this.state.isSwitchOn });
  };

  numberFormat = (x) => {
    return x && x.toString().split(".")[0].length > 3
      ? x
        .toString()
        .substring(0, x.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  };

  async componentDidMount() {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("dashboard", { path: global.location.href });
      } else {
        amplitude.logEvent("dashboard", {
          path: this.props.navigation.getState().routeNames,
        });
      }
      let portfolio_updated_date = null;
      let { userId } = this.state;
      const userData = await getUserDetails();
      const { is_pms_client } = Store.getState().userData.userProfileData;
      this.setState({ is_pms_client });
      if (userId) {
        Store.dispatch({
          type: "GET_DASHBOARD",
          payload: {
            viewModeUserId: {
              user_id: userId,
            },
          },
        });
      }

      userId =
        Store.getState().dashboard &&
          Store.getState().dashboard.viewModeUserId &&
          Store.getState().dashboard.viewModeUserId.user_id
          ? Store.getState().dashboard.viewModeUserId.user_id
          : String(userData.customer_id);
      let customer_data = "";
      const payload = {
        previous_key: "client_zero_stock_holdings",
        customer_id: userId,
      };

      const res = await getCustomerReport(payload);

      // console.log("Response is ", res.result);
      this.setState({ zeroHoldingResp: res.result });
      this.setState({
        isLoading: true,
        dataToShow: userId ? userId : String(userData.customer_id),
      });

      let dataResponse = [];
      let attentionStripResp = null;

      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        customer_data = await getCustomerDetails(userId);
        dataResponse = await getCustomerPortfolioData(
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        if (this.state.userId) {
          customer_data = await getCustomerDetails(userId);
          dataResponse = await getCustomerPortfolioData(this.state.userId);
        } else {
          if (
            Store.getState().portfolio &&
            Store.getState().portfolio.landingPageResponse
          ) {
            dataResponse = Store.getState().portfolio.landingPageResponse;
          } else {
            dataResponse = await getCustomerPortfolioData();
            // dataResponse["results"]["portfolio_as_of_date"] = moment(new Date(1920, 1, 1)).format("DD MMM YYYY");
            Store.dispatch({
              type: "GET_PORTFOLIO",
              payload: {
                landingPageResponse: dataResponse,
              },
            });
          }
        }
      }

      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        attentionStripResp = await getDashboard(
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        attentionStripResp = await getDashboard();
      }
      let strips = null;
      if (attentionStripResp && attentionStripResp.code == 100) {
        strips = attentionStripResp.result.strips;
        let is_ftu = attentionStripResp.result.is_ftu;
        this.setState({ is_ftu });
      }

      const { results } = dataResponse;
      let { summary, data_values } = results;
      portfolio_updated_date = results.portfolio_as_of_date ? results.portfolio_as_of_date : null;
      this.setState({
        portfolio_as_of_date: portfolio_updated_date
      })
      let family_members = Object.keys(summary);
      let familyTabs = [
        {
          name: this.state.user_name ? this.state.user_name : "Self",
          value: userId ? userId : String(userData.customer_id),
        },
      ];
      if (family_members.length > 1) {
        familyTabs.unshift({ name: "Family", value: "all" });
        let i = 0;
        for (i = 0; i < family_members.length; i++) {
          if (userId) {
            if (family_members[i] != "all" && family_members[i] != userId) {
              let temp = {
                name: summary[family_members[i]]["hey_name"],
                value: family_members[i],
              };
              familyTabs.push(temp);
            }
          } else {
            if (family_members[i] != "all") {
              let temp = {
                name: summary[family_members[i]]["hey_name"],
                value: family_members[i],
              };
              familyTabs.push(temp);
            }
          }
        }
      }
      let { first_name } = customer_data != "" ? customer_data : userData;
      let names = first_name ? first_name.split(/\s+/) : [];
      let name = "";
      if (names.length >= 2 && names.length > 0) {
        name =
          capitalizeFirstLetter(names[0]) +
          " " +
          capitalizeFirstLetter(names[1]);
      } else {
        if (names.length == 0) {
          name = "User";
        } else {
          name = names[0];
        }
      }

      this.setState(
        {
          summary,
          data_values: data_values,
          current_user:
            Store.getState().dashboard &&
              Store.getState().dashboard.viewModeUserId &&
              Store.getState().dashboard.viewModeUserId.user_id
              ? Store.getState().dashboard.viewModeUserId.user_id
              : String(userData.customer_id),
          userName: name,
          is_ria: userData.is_ria,
          strips: strips,
        },
        async () => {
          await this.checkComplianceData();
        }
      );
      let user_id =
        Store.getState().dashboard &&
          Store.getState().dashboard.viewModeUserId &&
          Store.getState().dashboard.viewModeUserId.user_id
          ? Store.getState().dashboard.viewModeUserId.user_id
          : String(userData.customer_id);
      if (this.state.userId) {
        user_id = this.state.userId;
      }
      this.setState({ summary });
      // console.log(data_values,"first check")
      this.filterDataToShow(user_id, summary, data_values);
      let plans_about_expire_resp = null;
      if (
        Store.getState().dashboard &&
        Store.getState().dashboard.viewModeUserId &&
        Store.getState().dashboard.viewModeUserId.user_id
      ) {
        plans_about_expire_resp = await getCipsAboutToExpire(
          Store.getState().dashboard.viewModeUserId.user_id
        );
      } else {
        plans_about_expire_resp = await getCipsAboutToExpire();
      }

      let show_choose_your_plan = false;
      let result = [];
      if (plans_about_expire_resp && plans_about_expire_resp.code == 100) {
        result = plans_about_expire_resp.result;
        if (result.length > 0) {
          result.forEach((item) => {
            if (item["renewal_type"] == "no_plan_for_customer") {
              show_choose_your_plan = true;
            }
          });
        }
      }
      this.setState({
        isLoading: false,
        familyTabsList: familyTabs,
        show_choose_your_plan: show_choose_your_plan,
      });
      this.onSelectAssetTab(this.state.selectedAssetSubTab);
      if (userId) {
        await updateCustomerPortfolioData(userId);
        dataResponse = await getCustomerPortfolioData(userId);
        // dataResponse["results"]["portfolio_as_of_date"] = moment(new Date(1920, 1, 1)).format("DD MMM YYYY");
      } else {
        await updateCustomerPortfolioData(userData.customer_id);
        dataResponse = await getCustomerPortfolioData();
        // dataResponse["results"]["portfolio_as_of_date"] = moment(new Date(1920, 1, 1)).format("DD MMM YYYY");
        Store.dispatch({
          type: "GET_PORTFOLIO",
          payload: {
            landingPageResponse: dataResponse,
          },
        });
      }

      portfolio_updated_date = dataResponse.results.portfolio_as_of_date ? dataResponse.results.portfolio_as_of_date : null;
      this.setState({
        portfolio_as_of_date: portfolio_updated_date
      })

      family_members = Object.keys(dataResponse.results.summary);
      familyTabs = [
        {
          name: this.state.user_name ? this.state.user_name : "Self",
          value: userId ? userId : String(userData.customer_id),
        },
      ];
      if (family_members.length > 1) {
        familyTabs.unshift({ name: "Family", value: "all" });
        let i = 0;
        for (i = 0; i < family_members.length; i++) {
          if (userId) {
            if (family_members[i] != "all" && family_members[i] != userId) {
              let temp = {
                name: dataResponse.results.summary[family_members[i]][
                  "hey_name"
                ],
                value: family_members[i],
              };
              familyTabs.push(temp);
            }
          } else {
            if (family_members[i] != "all") {
              let temp = {
                name: dataResponse.results.summary[family_members[i]][
                  "hey_name"
                ],
                value: family_members[i],
              };
              familyTabs.push(temp);
            }
          }
        }
      }

      this.setState({
        summary: dataResponse.results.summary,
        data_values: dataResponse.results.data_values,
        current_user:
          Store.getState().dashboard &&
            Store.getState().dashboard.viewModeUserId &&
            Store.getState().dashboard.viewModeUserId.user_id
            ? Store.getState().dashboard.viewModeUserId.user_id
            : String(userData.customer_id),
        userName: name,
        is_ria: userData.is_ria,
      });
      // console.log(data_values,"second check")
      this.filterDataToShow(user_id, summary, data_values);
      this.setState({ isLoading: false, familyTabsList: familyTabs });
      this.onSelectAssetTab(this.state.selectedAssetSubTab);
    } catch (err) {
      console.log("Error is ", err);
      this.setState({
        isLoading: false,
        errorText: "Unable to contact server, please try after sometime",
      });
    }
  }

  checkComplianceData = async () => {
    try {
      const customer_id = this.state.userId || null;
      const { params } = this.props.route;
      let skip_compliance = false;
      if (params) {
        skip_compliance = params.s || false;
      }
      completeMinDetails(true, customer_id, false).then(
        (minDetailsResponse) => {
          this.setState({ minDetailsResponse, gettingMinDetails: false });
        }
      );
    } catch (error) { }
  };

  handleShowZeroHolding = async () => {
    this.setState({ show_zero_holding: !this.state.show_zero_holding });
  };
  handleDownloadStocks = async (data, exceltext) => {
    if (data.length === 0) {
      console.log("No data available to download");
      return; // Prevent download if data is empty
    }

    // Create worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(data); // Convert data to worksheet
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Holdings"); // Append worksheet to the workbook

    // Generate file name with proper format (replace spaces with underscores)
    const fileName = `${exceltext}.xlsx`;

    console.log("Generated file name:", fileName); // Log file name to verify

    // Trigger download
    XLSX.writeFile(workbook, fileName);
  };
  filterDataToShow = (id, summary, data_values) => {
    let { is_ria } = this.state;
    // console.log("data_values", data_values)
    // console.log("summary abhi", summary);
    this.setState({ local_id: id });
    // console.log("summary is ", id);
    let filtered_summary = summary[id];
    let investTotalUser = [];
    let investValue = parseInt(
      filtered_summary["total_summary_value"] -
      (filtered_summary["total_outside_stock_mf_value"] +
        filtered_summary["total_assets_value"])
    );
    let investValueFull = parseInt(filtered_summary["total_summary_value"]);
    let investValueWithStockMF = parseInt(
      filtered_summary["total_summary_value"] -
      filtered_summary["total_assets_value"]
    );
    let investValueWithAsset = parseInt(
      filtered_summary["total_summary_value"] -
      filtered_summary["total_outside_stock_mf_value"]
    );
    let sum_value =
      (this.state.assetCheckbox ? filtered_summary["total_assets_value"] : 0) +
      (this.state.stockMFCheckbox
        ? filtered_summary["total_mfunds_value"]
        : filtered_summary["total_mfunds_value"] -
        filtered_summary["total_outside_mf_value"]) +
      (this.state.stockMFCheckbox
        ? filtered_summary["total_stocks_value"]
        : filtered_summary["total_stocks_value"] -
        filtered_summary["total_outside_stock_value"]) +
      filtered_summary["total_pms_equity_value"] +
      filtered_summary["total_pms_cash_value"] +
      filtered_summary["total_pms_mf_value"];
    let stocks_perc =
      sum_value > 0
        ? ((this.state.stockMFCheckbox
          ? filtered_summary["total_stocks_value"]
          : filtered_summary["total_stocks_value"] -
          filtered_summary["total_outside_stock_value"]) /
          sum_value) *
        100
        : 0;
    let mf_perc =
      sum_value > 0
        ? ((this.state.stockMFCheckbox
          ? filtered_summary["total_mfunds_value"]
          : filtered_summary["total_mfunds_value"] -
          filtered_summary["total_outside_mf_value"]) /
          sum_value) *
        100
        : 0;
    let assets_perc =
      sum_value > 0
        ? ((this.state.assetCheckbox
          ? filtered_summary["total_assets_value"]
          : 0) /
          sum_value) *
        100
        : 0;
    let pms_perc =
      sum_value > 0
        ? ((filtered_summary["total_pms_equity_value"] +
          filtered_summary["total_pms_cash_value"] +
          filtered_summary["total_pms_mf_value"]) /
          sum_value) *
        100
        : 0;

    investTotalUser = [
      {
        title: "STOCKS",
        value: `₹${numberWithCommas(
          parseInt(
            this.state.stockMFCheckbox
              ? filtered_summary["total_stocks_value"]
              : filtered_summary["total_stocks_value"] -
              filtered_summary["total_outside_stock_value"]
          )
        )}`,
        stocks_perc: stocks_perc,
      },
      {
        title: "MUTUAL FUNDS",
        value: `₹${numberWithCommas(
          parseInt(
            this.state.stockMFCheckbox
              ? filtered_summary["total_mfunds_value"]
              : filtered_summary["total_mfunds_value"] -
              filtered_summary["total_outside_mf_value"]
          )
        )}`,
        mf_perc: mf_perc,
      },
      {
        title: "ASSETS",
        value: `₹${numberWithCommas(
          parseInt(
            this.state.assetCheckbox
              ? filtered_summary["total_assets_value"]
              : 0
          )
        )}`,
        assets_perc: assets_perc,
      },
      {
        title: "PMS",
        value: `₹${numberWithCommas(
          parseInt(
            filtered_summary["total_pms_equity_value"] +
            filtered_summary["total_pms_cash_value"] +
            filtered_summary["total_pms_mf_value"]
          )
        )}`,
        pms_perc: pms_perc,
      },
    ];
    let overallReturnValue = `₹${numberWithCommas(
      parseInt(filtered_summary["total_gain"])
    )}`;
    let overallReturnPercent = (
      parseFloat(filtered_summary["total_gain_perc"]) * 100
    ).toFixed(2);
    let avg_holding_period = holdingPeriodText(
      parseInt(filtered_summary["total_avg_duration"])
    );

    let stocks_summary = { data: {}, plan_data: {} };
    if (filtered_summary["total_stocks_active_values"]) {
      Object.entries(filtered_summary["total_stocks_active_values"]).forEach(
        ([key, value]) => {
          if (key == "LATEST_VAL") {
            stocks_summary.data["stock_latest"] = value;
          } else if (key == "COST_INVESTED") {
            stocks_summary.data["stock_inv"] = value;
          } else if (key == "GAIN") {
            stocks_summary.data["stock_gain"] = value;
          } else if (key == "GAIN_PER") {
            stocks_summary.data["stock_abs_gain"] = parseFloat(value * 100);
          } else if (key == "XIRR") {
            stocks_summary.data["stock_xirr"] = parseFloat(value);
          } else if (key == "WEIGHTED_AVG_DAYS") {
            stocks_summary.data["stock_holding"] = holdingPeriodText(
              parseInt(value)
            );
          } else if (key == "CASH_IN_HAND") {
            stocks_summary.data["stock_inv_value_current"] = numberWithCommas(
              parseInt(value)
            );
          } else if (key == "first_cip_start_date") {
            stocks_summary.data["first_cip_start_date"] = value;
          }
        }
      );
    }

    if (filtered_summary["total_stocks_inception_values"]) {
      Object.entries(filtered_summary["total_stocks_inception_values"]).forEach(
        ([key, value]) => {
          if (key == "LATEST_VAL") {
            stocks_summary.plan_data["plan_current_value"] = value;
          } else if (key == "COST_INVESTED") {
            stocks_summary.plan_data["plan_invested_value"] = value;
          } else if (key == "GAIN") {
            stocks_summary.plan_data["plan_gain"] = value;
          } else if (key == "GAIN_PER") {
            stocks_summary.plan_data["plan_abs_gain_perc"] = parseFloat(
              value * 100
            );
          } else if (key == "XIRR") {
            stocks_summary.plan_data["plan_xirr"] = parseFloat(value * 100);
          } else if (key == "WEIGHTED_AVG_DAYS") {
            stocks_summary.plan_data["plan_holding_period_text"] =
              holdingPeriodText(parseInt(value));
          } else if (key == "REALIZED_GAINLOSS") {
            stocks_summary.plan_data["plan_realised_gain"] = parseInt(value);
          } else if (key == "UNREALIZED_GAINLOSS") {
            stocks_summary.plan_data["plan_unrealised_gain"] = parseInt(value);
          } else if (key == "INITIAL_INVESTED_AMOUNT") {
            stocks_summary.plan_data["plan_current_investment"] = value;
          } else if (key == "DIVIDEND") {
            stocks_summary.plan_data["plan_dividend"] = value;
          } else if (key == "first_cip_start_date") {
            stocks_summary.plan_data["first_cip_start_date"] = value;
          }
        }
      );
    }

    let shouldShowMF = true;

    let mfInJamaData = {};
    let mfOutsideData = {};
    let mfRegularData = {};
    if (filtered_summary["inside_mutual_fund_values"]) {
      Object.entries(filtered_summary["inside_mutual_fund_values"]).forEach(
        ([key, value]) => {
          mfInJamaData["tag_name"] = "In Jama";
          if (key == "LATEST_VAL") {
            mfInJamaData["latest_value"] = `₹${numberWithCommas(
              parseInt(value)
            )}`;
          } else if (key == "DAY_CHANGE") {
            mfInJamaData["day_change_perc"] = parseFloat(value);
          } else if (key == "COST_INVESTED") {
            mfInJamaData["amount_at_cost_text"] = `₹${numberWithCommas(
              parseInt(value)
            )}`;
          } else if (key == "GAIN") {
            mfInJamaData["gain"] = `₹${numberWithCommas(parseInt(value))}`;
          } else if (key == "GAIN_PER") {
            mfInJamaData["absolute_gain"] = parseFloat(value * 100);
          } else if (key == "XIRR") {
            mfInJamaData["latest_xirr"] = parseFloat(value);
          } else if (key == "WEIGHTED_AVG_DAYS") {
            mfInJamaData["holding_period"] = holdingPeriodText(parseInt(value));
          } else if (key == "SAVINGS") {
            mfInJamaData["savings"] = `₹${numberWithCommas(parseInt(value))}`;
          } else if (key == "EQUITY_PERC") {
            mfInJamaData["equity_perc"] =
              value > 0 ? parseFloat(value * 100) : 0;
          } else if (key == "NONEQUITY_PERC") {
            let calculation = parseFloat((value - 1) * 100);
            mfInJamaData["non_equity_perc_new"] =
              calculation >= 0 ? calculation : parseFloat(value * 100);
          }
        }
      );
    }

    if (filtered_summary["outside_mutual_fund_values"]) {
      Object.entries(filtered_summary["outside_mutual_fund_values"]).forEach(
        ([key, value]) => {
          mfOutsideData["tag_name"] = "Outside Jama";
          if (key == "LATEST_VAL") {
            mfOutsideData["latest_value"] = `₹${numberWithCommas(
              parseInt(value)
            )}`;
          } else if (key == "DAY_CHANGE") {
            mfOutsideData["day_change_perc"] = parseFloat(value);
          } else if (key == "COST_INVESTED") {
            mfOutsideData["amount_at_cost_text"] = `₹${numberWithCommas(
              parseInt(value)
            )}`;
          } else if (key == "GAIN") {
            mfOutsideData["gain"] = `₹${numberWithCommas(parseInt(value))}`;
          } else if (key == "GAIN_PER") {
            mfOutsideData["absolute_gain"] = parseFloat(value * 100);
          } else if (key == "XIRR") {
            mfOutsideData["latest_xirr"] = parseFloat(value);
          } else if (key == "WEIGHTED_AVG_DAYS") {
            mfOutsideData["holding_period"] = holdingPeriodText(
              parseInt(value)
            );
          } else if (key == "SAVINGS") {
            mfOutsideData["savings"] = `₹${numberWithCommas(parseInt(value))}`;
          } else if (key == "EQUITY_PERC") {
            mfOutsideData["equity_perc"] =
              value > 0 ? parseFloat(value * 100) : 0;
          } else if (key == "NONEQUITY_PERC") {
            mfOutsideData["non_equity_perc_new"] =
              value > 0 ? parseFloat(value * 100) : 0;
          }
        }
      );
    }

    if (filtered_summary["regular_outside_mutual_fund_values"]) {
      Object.entries(
        filtered_summary["regular_outside_mutual_fund_values"]
      ).forEach(([key, value]) => {
        mfRegularData["tag_name"] = "Regular Jama";
        if (key == "LATEST_VAL") {
          mfRegularData["latest_value"] = `₹${numberWithCommas(
            parseInt(value)
          )}`;
        } else if (key == "DAY_CHANGE") {
          mfRegularData["day_change_perc"] = parseFloat(value);
        } else if (key == "COST_INVESTED") {
          mfRegularData["amount_at_cost_text"] = `₹${numberWithCommas(
            parseInt(value)
          )}`;
        } else if (key == "GAIN") {
          mfRegularData["gain"] = `₹${numberWithCommas(parseInt(value))}`;
        } else if (key == "GAIN_PER") {
          mfRegularData["absolute_gain"] = parseFloat(value * 100);
        } else if (key == "XIRR") {
          mfRegularData["latest_xirr"] = parseFloat(value);
        } else if (key == "WEIGHTED_AVG_DAYS") {
          mfRegularData["holding_period"] = holdingPeriodText(parseInt(value));
        } else if (key == "SAVINGS") {
          mfRegularData["savings"] = `₹${numberWithCommas(parseInt(value))}`;
        } else if (key == "EQUITY_PERC") {
          mfRegularData["equity_perc"] =
            value > 0 ? parseFloat(value * 100) : 0.1;
        } else if (key == "NONEQUITY_PERC") {
          mfRegularData["non_equity_perc_new"] =
            value > 0 ? parseFloat(value * 100) : 99.9;
        }
      });
    }

    let stocks_summary_prodcut_wise = {
      JWL: {
        data: {},
        plan_data: {},
      },
      SRK: {
        data: {},
        plan_data: {},
      },
    };

    if (filtered_summary["jewel_summary_values_since_inception"]) {
      Object.entries(
        filtered_summary["jewel_summary_values_since_inception"]
      ).forEach(([key, value]) => {
        if (key == "LATEST_VAL") {
          stocks_summary_prodcut_wise.JWL.plan_data["plan_current_value"] =
            value;
        } else if (key == "COST_INVESTED") {
          stocks_summary_prodcut_wise.JWL.plan_data["plan_invested_value"] =
            value;
        } else if (key == "GAIN") {
          stocks_summary_prodcut_wise.JWL.plan_data["plan_gain"] = value;
        } else if (key == "GAIN_PER") {
          stocks_summary_prodcut_wise.JWL.plan_data["plan_abs_gain_perc"] =
            parseFloat(value * 100);
        } else if (key == "XIRR") {
          stocks_summary_prodcut_wise.JWL.plan_data["plan_xirr"] = parseFloat(
            value * 100
          );
        } else if (key == "WEIGHTED_AVG_DAYS") {
          stocks_summary_prodcut_wise.JWL.plan_data[
            "plan_holding_period_text"
          ] = holdingPeriodText(parseInt(value));
        } else if (key == "REALIZED_GAINLOSS") {
          stocks_summary_prodcut_wise.JWL.plan_data["plan_realised_gain"] =
            parseInt(value);
        } else if (key == "UNREALIZED_GAINLOSS") {
          stocks_summary_prodcut_wise.JWL.plan_data["plan_unrealised_gain"] =
            parseInt(value);
        } else if (key == "INITIAL_INVESTED_AMOUNT") {
          stocks_summary_prodcut_wise.JWL.plan_data["plan_current_investment"] =
            value;
        } else if (key == "DIVIDEND") {
          stocks_summary_prodcut_wise.JWL.plan_data["plan_dividend"] = value;
        } else if (key == "first_cip_start_date") {
          stocks_summary_prodcut_wise.JWL.plan_data["first_cip_start_date"] =
            value;
        }
      });
    }

    if (filtered_summary["spark_summary_values_since_inception"]) {
      Object.entries(
        filtered_summary["spark_summary_values_since_inception"]
      ).forEach(([key, value]) => {
        if (key == "LATEST_VAL") {
          stocks_summary_prodcut_wise.SRK.plan_data["plan_current_value"] =
            value;
        } else if (key == "COST_INVESTED") {
          stocks_summary_prodcut_wise.SRK.plan_data["plan_invested_value"] =
            value;
        } else if (key == "GAIN") {
          stocks_summary_prodcut_wise.SRK.plan_data["plan_gain"] = value;
        } else if (key == "GAIN_PER") {
          stocks_summary_prodcut_wise.SRK.plan_data["plan_abs_gain_perc"] =
            parseFloat(value * 100);
        } else if (key == "XIRR") {
          stocks_summary_prodcut_wise.SRK.plan_data["plan_xirr"] = parseFloat(
            value * 100
          );
        } else if (key == "WEIGHTED_AVG_DAYS") {
          stocks_summary_prodcut_wise.SRK.plan_data[
            "plan_holding_period_text"
          ] = holdingPeriodText(parseInt(value));
        } else if (key == "REALIZED_GAINLOSS") {
          stocks_summary_prodcut_wise.SRK.plan_data["plan_realised_gain"] =
            parseInt(value);
        } else if (key == "UNREALIZED_GAINLOSS") {
          stocks_summary_prodcut_wise.SRK.plan_data["plan_unrealised_gain"] =
            parseInt(value);
        } else if (key == "INITIAL_INVESTED_AMOUNT") {
          stocks_summary_prodcut_wise.SRK.plan_data["plan_current_investment"] =
            value;
        } else if (key == "DIVIDEND") {
          stocks_summary_prodcut_wise.SRK.plan_data["plan_dividend"] = value;
        } else if (key == "first_cip_start_date") {
          stocks_summary_prodcut_wise.SRK.plan_data["first_cip_start_date"] =
            value;
        }
      });
    }

    if (filtered_summary["jewel_summary_values"]) {
      Object.entries(filtered_summary["jewel_summary_values"]).forEach(
        ([key, value]) => {
          if (key == "LATEST_VAL") {
            stocks_summary_prodcut_wise.JWL.data["stock_latest"] = value;
          } else if (key == "COST_INVESTED") {
            stocks_summary_prodcut_wise.JWL.data["stock_inv"] = value;
          } else if (key == "GAIN") {
            stocks_summary_prodcut_wise.JWL.data["stock_gain"] = value;
          } else if (key == "GAIN_PER") {
            stocks_summary_prodcut_wise.JWL.data["stock_abs_gain"] = parseFloat(
              value * 100
            );
          } else if (key == "XIRR") {
            stocks_summary_prodcut_wise.JWL.data["stock_xirr"] =
              parseFloat(value);
          } else if (key == "WEIGHTED_AVG_DAYS") {
            stocks_summary_prodcut_wise.JWL.data["stock_holding"] =
              holdingPeriodText(parseInt(value));
          } else if (key == "CASH_IN_HAND") {
            stocks_summary_prodcut_wise.JWL.data["stock_inv_value_current"] =
              numberWithCommas(parseInt(value));
          } else if (key == "first_cip_start_date") {
            stocks_summary_prodcut_wise.JWL.data["first_cip_start_date"] =
              value;
          }
        }
      );
    }

    if (filtered_summary["spark_summary_values"]) {
      Object.entries(filtered_summary["spark_summary_values"]).forEach(
        ([key, value]) => {
          if (key == "LATEST_VAL") {
            stocks_summary_prodcut_wise.SRK.data["stock_latest"] = value;
          } else if (key == "COST_INVESTED") {
            stocks_summary_prodcut_wise.SRK.data["stock_inv"] = value;
          } else if (key == "GAIN") {
            stocks_summary_prodcut_wise.SRK.data["stock_gain"] = value;
          } else if (key == "GAIN_PER") {
            stocks_summary_prodcut_wise.SRK.data["stock_abs_gain"] = parseFloat(
              value * 100
            );
          } else if (key == "XIRR") {
            stocks_summary_prodcut_wise.SRK.data["stock_xirr"] =
              parseFloat(value);
          } else if (key == "WEIGHTED_AVG_DAYS") {
            stocks_summary_prodcut_wise.SRK.data["stock_holding"] =
              holdingPeriodText(parseInt(value));
          } else if (key == "CASH_IN_HAND") {
            stocks_summary_prodcut_wise.SRK.data["stock_inv_value_current"] =
              numberWithCommas(parseInt(value));
          } else if (key == "first_cip_start_date") {
            stocks_summary_prodcut_wise.SRK.data["first_cip_start_date"] =
              value;
          }
        }
      );
    }

    // let equity_investment_plans = { JWL: {}, SRK: {} };
    // if (filtered_summary["jewel_summary_values"]) {
    //   Object.entries(filtered_summary["jewel_summary_values"]).forEach(
    //     ([key, value]) => {
    //       equity_investment_plans.JWL["cash_in_hand"] = 0;
    //       if (key == "LATEST_VAL") {
    //         equity_investment_plans.JWL["current_value"] = value;
    //       } else if (key == "COST_INVESTED") {
    //         equity_investment_plans.JWL["current_investment"] = value;
    //       } else if (key == "GAIN") {
    //         equity_investment_plans.JWL["total_returns_amount"] = value;
    //       } else if (key == "GAIN_PER") {
    //         equity_investment_plans.JWL["total_returns_perc"] = parseInt(
    //           parseFloat(value) * 100
    //         );
    //       } else if (key == "XIRR") {
    //         equity_investment_plans.JWL["product_xirr"] = parseFloat(value);
    //       }
    //     }
    //   );
    // }

    // if (filtered_summary["spark_summary_values"]) {
    //   Object.entries(filtered_summary["spark_summary_values"]).forEach(
    //     ([key, value]) => {
    //       equity_investment_plans.SRK["cash_in_hand"] = 0;
    //       if (key == "LATEST_VAL") {
    //         equity_investment_plans.SRK["current_value"] = value;
    //       } else if (key == "COST_INVESTED") {
    //         equity_investment_plans.SRK["current_investment"] = value;
    //       } else if (key == "GAIN") {
    //         equity_investment_plans.SRK["total_returns_amount"] = value;
    //       } else if (key == "GAIN_PER") {
    //         equity_investment_plans.SRK["total_returns_perc"] = parseInt(
    //           parseFloat(value) * 100
    //         );
    //       } else if (key == "XIRR") {
    //         equity_investment_plans.SRK["product_xirr"] = parseFloat(value);
    //       }
    //     }
    //   );
    // }

    let filterData = {};
    if (data_values && data_values[id]) {
      filterData = data_values[id];
    }

    let mutualFundInJamaDetails = [];
    let mutualFundOutsideDetails = [];
    let excelMFInJamaDataValue = [];
    let excelMFOutsideDataValue = [];
    let mutualFundRegularDetails = [];
    let excelMFRegularDetails = [];

    let MFTabsList = [
      { label: "Inside Funds", value: "Jama" },
      { label: "Outside Funds", value: "Outside" },
    ];

    if (filterData && filterData["inside_MF"]) {
      mutualFundInJamaDetails = filterData["inside_MF"];
      filterData["inside_MF"].forEach((item) => {
        excelMFInJamaDataValue.push({
          stock_name: item.name,
          units: parseInt(item.latest_value_at_nav).toFixed(0),
          avg_price: parseInt(item.invested_amount).toFixed(0),
          closing_price: parseFloat(item.UNIT_BAL).toFixed(3),
          invested: parseInt(item.weighted_avg_days),
          latest_value: item.gain,
          gain: item.latest_xirr ? parseFloat(item.latest_xirr).toFixed(1) : 0,
          gain_percent: parseFloat(item.gain_per * 100).toFixed(1),
          holding_period: item.weighted_avg_days,
          iaf_id: item.ASSET_ID,
          bse_swp_flag: item.bse_swp_flag,
          bse_stp_flag: item.bse_stp_flag,
          bse_switch_flag: item.bse_switch_flag,
          amcfundscheme_id: item.amcfundscheme_id,
          amcfund_id: item.amcfund_id,
        });
      });
    }

    if (filterData && filterData["outside_MF"]) {
      // mutualFundOutsideDetails = filterData["outside_MF"];
      filterData["outside_MF"].forEach((item) => {
        if (item["imported_from"] == "regular_database") {
          if (
            !MFTabsList.includes({ label: "Regular Funds", value: "Regular" })
          ) {
            MFTabsList.push({ label: "Regular Funds", value: "Regular" });
          }
          mutualFundRegularDetails.push(item);
          excelMFRegularDetails.push({
            stock_name: item.name,
            units: parseInt(item.latest_value_at_nav).toFixed(0),
            avg_price: parseInt(item.invested_amount).toFixed(0),
            closing_price: parseFloat(item.UNIT_BAL).toFixed(3),
            invested: parseInt(item.weighted_avg_days),
            latest_value: parseFloat(item.gain).toFixed(0),
            gain: item.latest_xirr
              ? parseFloat(item.latest_xirr).toFixed(1)
              : 0,
            gain_percent: parseFloat(item.gain_per * 100).toFixed(1),
            holding_period: item.ASSET_ID,
          });
        } else {
          mutualFundOutsideDetails.push(item);
          excelMFOutsideDataValue.push({
            stock_name: item.name,
            units: parseInt(item.latest_value_at_nav).toFixed(0),
            avg_price: parseInt(item.invested_amount).toFixed(0),
            closing_price: parseFloat(item.UNIT_BAL).toFixed(3),
            invested: parseInt(item.weighted_avg_days),
            latest_value: parseFloat(item.gain).toFixed(0),
            gain: item.latest_xirr
              ? parseFloat(item.latest_xirr).toFixed(1)
              : 0,
            gain_percent: parseFloat(item.gain_per * 100).toFixed(1),
            holding_period: item.ASSET_ID,
          });
        }
      });
    }

    let AllAssetsData = {
      value_new: filtered_summary["total_assets_value"],
      results: [
        { name: "Equity", value_new: 0 },
        { name: "Debt", value_new: 0 },
        { name: "Gold", value_new: 0 },
        { name: "Insurance", value_new: 0 },
        { name: "Real Estate", value_new: 0 },
        { name: "Other", value_new: 0 },
      ],
    };
    let assetList = {
      equity: [],
      debt: [],
      gold: [],
      insurance: [],
      realEstate: [],
      other: [],
    };

    if (filterData && filterData["other_assets"]) {
      filterData["other_assets"].forEach((item) => {
        if (item.fund_class[0] == "E") {
          AllAssetsData.results[0]["value_new"] += item.invested_amount;
          assetList.equity.push({
            stock_name: item["name"],
            units: item["date_of_purchase"],
            asset_type: "Stocks",
            closing_price: item["latest_value_at_nav"],
            invested: item["invested_amount"],
            latest_value: item["latest_nav"],
            goal_value: item["goal_name"],
            asset_id: item["ASSET_ID"],
            fund_class: item["fund_class"],
          });
        } else if (item.fund_class[0] == "D") {
          AllAssetsData.results[1]["value_new"] += item.invested_amount;
          assetList.debt.push({
            stock_name: item["name"],
            units: item["date_of_purchase"],
            asset_type: "Debt",
            closing_price: item["latest_value_at_nav"],
            invested: item["invested_amount"],
            latest_value: item["latest_nav"],
            goal_value: item["goal_name"],
            asset_id: item["ASSET_ID"],
            fund_class: item["fund_class"],
          });
        } else if (item.fund_class[0] == "G") {
          AllAssetsData.results[2]["value_new"] += item.invested_amount;
          assetList.gold.push({
            stock_name: item["name"],
            units: item["date_of_purchase"],
            asset_type: "Gold",
            closing_price: item["latest_value_at_nav"],
            invested: item["invested_amount"],
            latest_value: item["latest_nav"],
            goal_value: item["goal_name"],
            asset_id: item["ASSET_ID"],
            fund_class: item["fund_class"],
          });
        } else if (item.fund_class[0] == "I") {
          AllAssetsData.results[3]["value_new"] += item.invested_amount;
          assetList.insurance.push({
            stock_name: item["name"],
            units: item["date_of_purchase"],
            asset_type: "Insurance",
            closing_price: item["latest_value_at_nav"],
            invested: item["invested_amount"],
            latest_value: item["latest_nav"],
            goal_value: item["goal_name"],
            asset_id: item["ASSET_ID"],
            fund_class: item["fund_class"],
          });
        } else if (item.fund_class[0] == "R") {
          AllAssetsData.results[4]["value_new"] += item.invested_amount;
          assetList.realEstate.push({
            stock_name: item["name"],
            units: item["date_of_purchase"],
            asset_type: "Property",
            closing_price: item["latest_value_at_nav"],
            invested: item["invested_amount"],
            latest_value: item["latest_nav"],
            goal_value: item["goal_name"],
            asset_id: item["ASSET_ID"],
            fund_class: item["fund_class"],
          });
        } else {
          AllAssetsData.results[5]["value_new"] += item.invested_amount;
          assetList.other.push({
            stock_name: item["name"],
            units: item["date_of_purchase"],
            asset_type: "Others",
            closing_price: item["latest_value_at_nav"],
            invested: item["invested_amount"],
            latest_value: item["latest_nav"],
            goal_value: item["goal_name"],
            asset_id: item["ASSET_ID"],
            fund_class: item["fund_class"],
          });
        }
      });
    }

    let holdingsData = {};
    let searchResults = [];
    let stockTabsList = [];
    if (is_ria || this.state.is_preferred_circle || this.state.is_club) {
      holdingsData = { Inside: [], "Outside Stocks": [] };
      if (filterData && filterData["inside_stocks"]) {
        holdingsData["Inside"] = filterData["inside_stocks"];
        if (holdingsData["Inside"]) {
          searchResults.push(...holdingsData["Inside"]);
        }
      }
      stockTabsList = [
        { label: "Inside Stocks", value: "Inside" },
        { label: "Outside Stocks", value: "Outside" },
      ];
    } else {
      holdingsData = { JEWEL: [], SPARK: [], "Outside Stocks": [] };
      if (filterData["inside_stocks"]) {
        filterData["inside_stocks"].forEach((item) => {
          if (item.product == "JWL") {
            holdingsData["JEWEL"].push(item);
          } else if (item.product == "SRK") {
            holdingsData["SPARK"].push(item);
          }
        });
      }

      if (holdingsData["JEWEL"]) {
        searchResults.push(...holdingsData["JEWEL"]);
      }
      if (holdingsData["SPARK"]) {
        searchResults.push(...holdingsData["SPARK"]);
      }

      stockTabsList = [
        { label: "Inside Stocks", value: "Inside" },
        { label: "JEWEL", value: "JEWEL" },
        { label: "SPARK", value: "SPARK" },
        { label: "Outside Stocks", value: "Outside" },
      ];
    }

    // console.log("searchResults", searchResults, holdingsData, stockTabsList)

    if (filterData && filterData["outside_stocks"]) {
      holdingsData["Outside Stocks"] = filterData["outside_stocks"];
    }

    let invested_amount = 0;
    if (filterData["other_assets"]) {
      filterData["other_assets"].forEach((item) => {
        if (item.invested_amount) {
          invested_amount = invested_amount + item.invested_amount;
        }
      });
    }
    let outside_latest_amount = 0;
    let outside_invested_amount = 0;
    if (filterData["outside_stocks"]) {
      filterData["outside_stocks"].forEach((item) => {
        if (item.latest_value_at_nav) {
          outside_latest_amount =
            outside_latest_amount + item.latest_value_at_nav;
        }
        if (item.invested_amount) {
          outside_invested_amount =
            outside_invested_amount + item.invested_amount;
        }
      });
    }
    this.setState({
      outsideLatestAmount: outside_latest_amount,
      outsideInvestedAmount: outside_invested_amount,
    });

    let investedValue = parseInt(
      filtered_summary["total_invested"] -
      (filtered_summary["outside_mutual_fund_values"].COST_INVESTED +
        filtered_summary["regular_outside_mutual_fund_values"].COST_INVESTED +
        outside_invested_amount +
        filtered_summary["total_assets_value"])
    );
    let investedValueFull = parseInt(filtered_summary["total_invested"]);
    let investedValueWithStockMF = parseInt(
      filtered_summary["total_invested"] -
      filtered_summary["total_assets_value"]
    );
    let investedValueWithAsset = parseInt(
      filtered_summary["total_invested"] -
      (filtered_summary["outside_mutual_fund_values"].COST_INVESTED +
        filtered_summary["regular_outside_mutual_fund_values"].COST_INVESTED +
        outside_invested_amount)
    );

    let gainValue = parseInt(investValue - investedValue);
    let gainValueFull = parseInt(investValueFull - investedValueFull);
    let gainValueWithStockMF = parseInt(
      investValueWithStockMF - investedValueWithStockMF
    );
    let gainValueWithAsset = parseInt(
      investValueWithAsset - investedValueWithAsset
    );

    let gainPercentValue = ((gainValue / investedValue) * 100).toFixed(2);
    let gainPercentValueFull = (
      (gainValueFull / investedValueFull) *
      100
    ).toFixed(2);
    let gainPercentValueWithStockMF = (
      (gainValueWithStockMF / investedValueWithStockMF) *
      100
    ).toFixed(2);
    let gainPercentValueWithAsset = (
      (gainValueWithAsset / investedValueWithAsset) *
      100
    ).toFixed(2);

    investedValueFull = `₹${numberWithCommas(investedValueFull)}`;
    investedValue = `₹${numberWithCommas(investedValue)}`;
    investedValueWithStockMF = `₹${numberWithCommas(investedValueWithStockMF)}`;
    investedValueWithAsset = `₹${numberWithCommas(investedValueWithAsset)}`;
    investValueFull = `₹${numberWithCommas(investValueFull)}`;
    investValue = `₹${numberWithCommas(investValue)}`;
    investValueWithStockMF = `₹${numberWithCommas(investValueWithStockMF)}`;
    investValueWithAsset = `₹${numberWithCommas(investValueWithAsset)}`;
    gainValueFull = `₹${numberWithCommas(gainValueFull)}`;
    gainValue = `₹${numberWithCommas(gainValue)}`;
    gainValueWithStockMF = `₹${numberWithCommas(gainValueWithStockMF)}`;
    gainValueWithAsset = `₹${numberWithCommas(gainValueWithAsset)}`;

    AllAssetsData.invested_amount = invested_amount;
    // " + filtered_summary["total_pms_cash_value"]"

    let pms_data = {
      total_latest_value: 0,
      total_cash_value: 0,
      scheme_wise_values: [
        {
          title: "GEM PMS",
          value: `₹0`,
          perc: 0,
          color: "#6FE3D4",
          cash: `₹0`,
        },
        {
          title: "JEWEL PMS",
          value: `₹0`,
          perc: 0,
          color: "#876CDD",
          cash: `₹0`,
        },
        {
          title: "SPARK PMS",
          value: `₹0`,
          perc: 0,
          color: "#BAC7F2",
          cash: `₹0`,
        },
        // {
        //   title: "SAPPHIRE PMS",
        //   value: `₹0`,
        //   perc: 0,
        //   color: "#FEBE37",
        //   cash: `₹0`
        // }
      ],
    };
    let total_pms_value = 0;
    let total_cash_value = 0;
    if (this.state.is_pms_client) {
      let pms_entries = filtered_summary["pms_summary_values"];
      if (pms_entries && pms_entries.length > 0) {
        let colors = ["#6FE3D4", "#876CDD", "#BAC7F2", "#FEBE37"];
        pms_entries.forEach((item) => {
          total_pms_value =
            total_pms_value +
            item["scheme_latest_value"] +
            item["scheme_cash_value"];
          total_cash_value = total_cash_value + item["scheme_cash_value"];
        });

        pms_data["total_latest_value"] = total_pms_value;
        pms_data["total_cash_value"] = total_cash_value;

        pms_entries.forEach((item, index) => {
          if (item["scheme_code"] == "JLM") {
            pms_data["scheme_wise_values"][1] = {
              title: "JEWEL PMS",
              value: `₹${numberWithCommas(
                parseInt(item["scheme_latest_value"])
              )}`,
              perc: (item["scheme_latest_value"] / total_pms_value) * 100,
              color: colors[1],
              cash: `₹${numberWithCommas(parseInt(item["scheme_cash_value"]))}`,
            };
          } else if (item["scheme_code"] == "SSC") {
            pms_data["scheme_wise_values"][2] = {
              title: "SPARK PMS",
              value: `₹${numberWithCommas(
                parseInt(item["scheme_latest_value"])
              )}`,
              perc: (item["scheme_latest_value"] / total_pms_value) * 100,
              color: colors[2],
              cash: `₹${numberWithCommas(parseInt(item["scheme_cash_value"]))}`,
            };
          } else if (item["scheme_code"] == "GEM") {
            pms_data["scheme_wise_values"][0] = {
              title: "GEM PMS",
              value: `₹${numberWithCommas(
                parseInt(item["scheme_latest_value"])
              )}`,
              perc: (item["scheme_latest_value"] / total_pms_value) * 100,
              color: colors[0],
              cash: `₹${numberWithCommas(parseInt(item["scheme_cash_value"]))}`,
            };
          } else if (item["scheme_code"] == "SDP") {
            pms_data["scheme_wise_values"][3] = {
              title: "SAPPHIRE PMS",
              value: `₹${numberWithCommas(
                parseInt(item["scheme_latest_value"])
              )}`,
              perc: (item["scheme_latest_value"] / total_pms_value) * 100,
              color: colors[3],
              cash: `₹${numberWithCommas(parseInt(item["scheme_cash_value"]))}`,
            };
          }
        });

        pms_data["scheme_wise_values"].push({
          title: "Total Cash",
          value: `₹${numberWithCommas(parseInt(total_cash_value))}`,
          perc: (total_cash_value / total_pms_value) * 100,
          color: "#410DAA",
          cash: `₹${numberWithCommas(parseInt(total_cash_value))}`,
        });
      }
    }

    this.setState({
      investTotalUser: investTotalUser,
      investValue: investValue,
      investValueWithStockMF: investValueWithStockMF,
      investValueWithAsset: investValueWithAsset,
      investValueFull: investValueFull,
      investedValue: investedValue,
      investedValueWithStockMF: investedValueWithStockMF,
      investedValueWithAsset: investedValueWithAsset,
      investedValueFull: investedValueFull,
      gainValue: gainValue,
      gainValueWithStockMF: gainValueWithStockMF,
      gainValueWithAsset: gainValueWithAsset,
      gainValueFull: gainValueFull,
      gainPercentValue: gainPercentValue,
      gainPercentValueWithStockMF: gainPercentValueWithStockMF,
      gainPercentValueWithAsset: gainPercentValueWithAsset,
      gainPercentValueFull: gainPercentValueFull,
      overallReturnValue: overallReturnValue,
      overallReturnPercent: overallReturnPercent,
      avg_holding_period: avg_holding_period,
      stocks_summary: stocks_summary,
      mfInJamaData: mfInJamaData,
      mfOutsideData: mfOutsideData,
      AllAssetsData: AllAssetsData,
      filteredAsset: assetList.equity,
      assetDataValue: assetList.equity,
      searchAssetResults: assetList.equity,
      assetList: assetList,
      shouldShowMF: shouldShowMF,
      mutualFundInJamaDetails: mutualFundInJamaDetails,
      mutualFundOutsideDetails: mutualFundOutsideDetails,
      excelMFInJamaDataValue: excelMFInJamaDataValue,
      excelMFOutsideDataValue: excelMFOutsideDataValue,
      searchMFResults: excelMFInJamaDataValue,
      holdingsData: holdingsData,
      searchResults: searchResults,
      // equity_investment_plans: equity_investment_plans,
      stocks_summary_prodcut_wise: stocks_summary_prodcut_wise,
      stockTabsList: stockTabsList,
      MFTabsList: MFTabsList,
      mutualFundRegularDetails: mutualFundRegularDetails,
      excelMFRegularDetails: excelMFRegularDetails,
      mfRegularData: mfRegularData,
      pms_data: pms_data,
    });
  };

  assetTabsList = [
    { label: "Equity & PMS", value: "Stocks" },
    { label: "Bonds & Deposits", value: "Bonds" },
    { label: "ULIPs & Insurance", value: "Insurance" },
    { label: "Real Estate & Property", value: "Property" },
    { label: "Gold & Bullion", value: "Gold" },
    { label: "Misc", value: "Other" },
  ];

  // MFTabsList = [
  //   { label: "Inside Funds", value: "Jama" },
  //   { label: "Outside Funds", value: "Outside" },
  // ];

  handleProductChange = (value) => {
    const { holdingsData } = this.state;
    const searchResults = holdingsData[value];
    this.setState({
      showStocksOf: value,
      searchResults,
      sorting_field: null,
      sorting_value: null,
    });
  };

  onSelectAssetTab = (selectedAssetTab) => {
    let { data_values, current_user, assetList } = this.state;
    let filterData = data_values[current_user];

    if (filterData && filterData["other_assets"]) {
      if (selectedAssetTab == 0) {
        this.setState({
          filteredAsset: assetList.equity,
          assetDataValue: assetList.equity,
          searchAssetResults: assetList.equity,
        });
      } else if (selectedAssetTab == 1) {
        this.setState({
          filteredAsset: assetList.debt,
          assetDataValue: assetList.debt,
          searchAssetResults: assetList.debt,
        });
      } else if (selectedAssetTab == 2) {
        this.setState({
          filteredAsset: assetList.insurance,
          assetDataValue: assetList.insurance,
          searchAssetResults: assetList.insurance,
        });
      } else if (selectedAssetTab == 3) {
        this.setState({
          filteredAsset: assetList.realEstate,
          assetDataValue: assetList.realEstate,
          searchAssetResults: assetList.realEstate,
        });
      } else if (selectedAssetTab == 4) {
        this.setState({
          filteredAsset: assetList.gold,
          assetDataValue: assetList.gold,
          searchAssetResults: assetList.gold,
        });
      } else if (selectedAssetTab == 5) {
        this.setState({
          filteredAsset: assetList.other,
          assetDataValue: assetList.other,
          searchAssetResults: assetList.other,
        });
      }
    }

    let selectTabValue = this.assetTabsList[selectedAssetTab].value;
    this.setState({ selectedAssetTab: selectTabValue });
  };

  onSelectMFTab = (selectedMFTab) => {
    let selectTabValue = this.state.MFTabsList[selectedMFTab].value;
    let { current_user } = this.state;

    this.setState({
      selectedMFTab: selectTabValue,
      searchMFResults:
        selectTabValue == "Jama"
          ? this.state.excelMFInJamaDataValue
          : selectTabValue == "Regular"
            ? this.state.excelMFRegularDetails
            : this.state.excelMFOutsideDataValue,
    });
  };

  onSelectStockTab = (selectedStockTab) => {
    let { holdingsData, is_ria, stockTabsList } = this.state;
    let tempHoldingArray = [];

    let searchResults = holdingsData[stockTabsList[selectedStockTab].label];

    if (stockTabsList[selectedStockTab].label == "Inside Stocks") {
      if (is_ria || this.state.is_preferred_circle) {
        tempHoldingArray.push(...holdingsData["Inside"]);
      } else {
        if (holdingsData["JEWEL"])
          tempHoldingArray.push(...holdingsData["JEWEL"]);
        if (holdingsData["SPARK"])
          tempHoldingArray.push(...holdingsData["SPARK"]);
      }
      searchResults = tempHoldingArray;
    }
    this.setState({
      searchResults,
      showStocksOf: stockTabsList[selectedStockTab].label,
      selectedStockTab: stockTabsList[selectedStockTab].label,
    });
  };

  handleSearch = (searchText) => {
    let searchResults = [];
    const { holdingsData, showStocksOf } = this.state;
    let searchData = [];
    if (showStocksOf == "Inside Stocks") {
      let jewelData = holdingsData["JEWEL"];
      let sparkData = holdingsData["SPARK"];
      searchData.push(...jewelData);
      searchData.push(...sparkData);
    } else {
      searchData = holdingsData[showStocksOf];
      // console.log("entered :--", showStocksOf);
    }
    if (searchText) {
      searchResults = searchData.filter((stock) => {
        return stock.name.toLowerCase().startsWith(searchText.toLowerCase());
      });
    } else {
      searchResults = searchData;
    }
    if (searchResults.length) {
      this.setState({
        searchText,
        searchResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  handleMFSearch = (searchMFText) => {
    let searchMFResults = [];
    const searchData =
      this.state.selectedMFTab == "Jama"
        ? this.state.excelMFInJamaDataValue
        : this.state.selectedMFTab == "Regular"
          ? this.state.excelMFRegularDetails
          : this.state.excelMFOutsideDataValue;

    if (searchMFText) {
      searchMFResults = searchData.filter((mutualfund) => {
        return mutualfund.stock_name
          .toLowerCase()
          .startsWith(searchMFText.toLowerCase());
      });
    } else {
      searchMFResults = searchData;
    }
    if (searchMFResults.length) {
      this.setState({
        searchMFText,
        searchMFResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  handleAssetSearch = (searchAssetText) => {
    let searchAssetResults = [];
    const searchData = this.state.assetDataValue;

    if (searchAssetText) {
      searchAssetResults = searchData.filter((asset) => {
        return asset.stock_name
          .toLowerCase()
          .startsWith(searchAssetText.toLowerCase());
      });
    } else {
      searchAssetResults = searchData;
    }
    if (searchAssetResults.length) {
      this.setState({
        searchAssetText,
        searchAssetResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };
  handleDownloadAsset = async (data) => {
    if (data.length === 0) {
      console.log("No data available to download");
      return; // Prevent download if data is empty
    }

    // Create worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(data); // Convert data to worksheet
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Holdings"); // Append worksheet to the workbook

    // Generate file name with proper format (replace spaces with underscores)
    const fileName = `${this.state.userName}${this.assetTabsList[this.state.selectedAssetSubTab].label}${moment().format("DD-MM-YYYY hh-mm-ss A")}.xlsx`;

    console.log("Generated file name:", fileName); // Log file name to verify
    XLSX.writeFile(workbook, fileName);
  }
  handleDownloadMF = () => {
    // console.log("download"); // Ensure this is logged when clicking the download button

    let data = [];

    // Check if data is being set correctly based on selectedMFTab
    if (this.state.selectedMFTab === "Jama") {
      this.state.excelMFInJamaDataValue.forEach((item) => {
        data.push({
          "Name": item.stock_name,
          "Current Value": item.units,
          "Invested": item.avg_price,
          "Units": item.closing_price,
          "Gain": item.latest_value,
          "XIRR %": item.gain,
          "Gain %": item.gain_percent,
          "Avg. Duration": item.holding_period
        });
      });
    } else if (this.state.selectedMFTab === "Regular") {
      this.state.excelMFRegularDetails.forEach((item) => {
        data.push({
          "Name": item.stock_name,
          "Current Value": item.units,
          "Invested": item.avg_price,
          "Units": item.closing_price,
          "Avg. Duration": item.invested,
          "Gain": item.latest_value,
          "XIRR %": item.gain,
          "Gain %": item.gain_percent
        });
      })
    } else if (this.state.selectedMFTab === "Outside") {
      this.state.excelMFOutsideDataValue.forEach((item) => {
        data.push({
          "Name": item.stock_name,
          "Current Value": item.units,
          "Invested": item.avg_price,
          "Units": item.closing_price,
          "Avg. Duration": item.invested,
          "Gain": item.latest_value,
          "XIRR %": item.gain,
          "Gain %": item.gain_percent,
        });
      })
    }

    // console.log("Data to download:", data); // Log the data to ensure it's correct

    // Ensure that data is not empty
    if (data.length === 0) {
      alert("No data available to download");
      return; // Prevent download if data is empty
    }

    // Create worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(data); // Convert data to worksheet
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "MF Holdings"); // Append worksheet to the workbook

    // Generate file name with proper format (replace spaces with underscores)
    const fileName = `${this.state.userName} ${this.state.selectedMFTab} Mutual Fund Holdings ${moment().format("DD-MM-YYYY_HH-mm-ss")}.xlsx`;

    // console.log("Generated file name:", fileName); // Log file name to verify

    // Trigger download
    XLSX.writeFile(workbook, fileName);
  };


  compareStrings = (a, b) => {
    const x = a ? a.toLowerCase() : a;
    const y = b ? b.toLowerCase() : b;
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  compareNumbers = (a, b) => {
    return a - b;
  };

  getSortedData = (data, sorting_field, sorting_value, start, end, from) => {
    if (sorting_field == "stock_name") {
      sorting_field = from == "asset" || from == "mf" ? "stock_name" : "name";
    }
    if (sorting_field == "units") {
      sorting_field = from == "asset" || from == "mf" ? "units" : "UNIT_BAL";
    }
    if (sorting_field == "xirr_perc") {
      sorting_field = from == "stock" ? "latest_xirr" : "xirr_perc";
    }
    if (sorting_field == "closing_price") {
      sorting_field =
        from == "asset" || from == "mf" ? "closing_price" : "latest_nav";
    }
    if (sorting_field == "invested") {
      sorting_field =
        from == "asset" || from == "mf" ? "invested" : "invested_amount";
    }
    if (sorting_field == "latest_value") {
      sorting_field = from == "mf" ? "latest_value" : "latest_value_at_nav";
    }
    if (sorting_field == "holding_period") {
      sorting_field = "weighted_avg_days";
    }
    if (sorting_field == "gain_percent") {
      sorting_field = from == "mf" ? "gain_percent" : "gain_per";
    }
    if (sorting_field === "Transaction_Date") sorting_field = "trade_date";
    if (sorting_field === "units") sorting_field = "number_of_units";
    if (sorting_field === "Price") sorting_field = "price";
    if (sorting_field === "Amount") sorting_field = (item) =>
      parseFloat(item.price) * item.number_of_units;
    if (sorting_field === "Type") sorting_field = "transaction_type";

    if (data.length != 0) {
      const fieldValue = data[0][sorting_field];
      const sortingType = isNaN(fieldValue) ? "string" : "number";

      data = data.slice(start, end);
      if (sortingType == "string") {
        data.sort((a, b) =>
          this.compareStrings(a[sorting_field], b[sorting_field])
        );
      } else {
        data.sort((a, b) =>
          this.compareNumbers(a[sorting_field], b[sorting_field])
        );
      }

      return sorting_value ? data : data.reverse();
    } else {
      return data;
    }
  };

  handleSort = (field, sort, start, end) => {
    // console.log("sorting field is ", field);
    const sorting_field = field;
    const sorting_value = !sort;

    const { searchResults } = this.state;
    const sortedData = [].concat(
      searchResults.slice(0, start),
      this.getSortedData(
        searchResults,
        sorting_field,
        sorting_value,
        start,
        end,
        "stock"
      ),
      searchResults.slice(end, searchResults.length)
    );

    this.setState({ sorting_field, sorting_value, searchResults: sortedData });
  };

  handleMFSort = (field, sort, start, end) => {
    const sorting_field = field;
    const sorting_value = !sort;

    const { searchMFResults } = this.state;
    const sortedData = [].concat(
      searchMFResults.slice(0, start),
      this.getSortedData(
        searchMFResults,
        sorting_field,
        sorting_value,
        start,
        end,
        "mf"
      ),
      searchMFResults.slice(end, searchMFResults.length)
    );

    this.setState({
      sorting_field,
      sorting_value,
      searchMFResults: sortedData,
    });
  };

  handleAssetSort = (field, sort, start, end) => {
    const sorting_field = field;
    const sorting_value = !sort;

    const { searchAssetResults } = this.state;
    const sortedData = [].concat(
      searchAssetResults.slice(0, start),
      this.getSortedData(
        searchAssetResults,
        sorting_field,
        sorting_value,
        start,
        end,
        "asset"
      ),
      searchAssetResults.slice(end, searchAssetResults.length)
    );

    this.setState({
      sorting_field,
      sorting_value,
      searchAssetResults: sortedData,
    });
  };

  getZeroHoldingHeaderData = (data) => {
    const { sorting_field, sorting_value, userName } = this.state;
    const keysData = [
      { label: "Name", key: "name" },
      { label: "Product", key: "product" },
      { label: "Units Balance", key: "unit_balance" },
      { label: "Value at Cost", key: "value_at_cost" },
      { label: "Value at sell", key: "value_at_sell" },
      { label: "Realised Gain Loss", key: "realised_gain_loss" },
      { label: "Gain %", key: "gain_perc" },
      { label: "Wt Years", key: "weighted_avg_years" },
    ];

    let headerData = [];
    keysData.forEach((headerItem) => {
      headerData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: true,
        onSort: this.handleSort, // Ensure this is correctly set
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    // Sort the data based on the sorting_field and sorting_value
    if (sorting_field) {
      data.sort((a, b) => {
        const valueA = a[sorting_field];
        const valueB = b[sorting_field];

        // Handle null or undefined values
        if (valueA == null || valueB == null) {
          return sorting_value ? (valueA == null ? 1 : -1) : (valueB == null ? 1 : -1);
        }

        // Handle strings
        if (typeof valueA === "string" && typeof valueB === "string") {
          return sorting_value
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }

        // Handle numbers
        if (typeof valueA === "number" && typeof valueB === "number") {
          return sorting_value ? valueA - valueB : valueB - valueA;
        }

        // Handle objects (fallback logic)
        if (typeof valueA === "object" && typeof valueB === "object") {
          // console.log("Cannot sort on objects directly:", valueA, valueB);
          return 0; // Leave objects unsorted
        }

        // Default fallback for unsupported types
        return 0;
      });
    }

    let values = [];
    let excelTextValue = "";
    let excelDataValue = [];
    data.forEach((value) => {
      if (value.unit_balance != null) {
        values.push([
          {
            valueToDisplay: (
              <TouchableOpacity onPress={() => {
                this.handlePress(value.investoraccountstock_id);
              }}
                style={[styles.rowItemContainerStyle, { paddingLeft: 5 }]}>
                <Text
                  style={[styles.rowItem, {
                    color: "#410DAA", fontSize: 12, textDecorationLine: "underline",
                  },
                  ]}>{value.name}</Text>
              </TouchableOpacity>
            ),
            value: value.name,
          }, { valueToDisplay: value.product, value: value.product },
          { valueToDisplay: value.unit_balance, value: value.unit_balance },
          { valueToDisplay: value.value_at_cost.toFixed(2) || 0, value: value.value_at_cost },
          { valueToDisplay: value.value_at_sell.toFixed(2) || 0, value: value.value_at_sell },
          { valueToDisplay: value.realised_gain_loss.toFixed(2) || 0, value: value.realised_gain_loss },
          { valueToDisplay: value.gain_perc.toFixed(2) || 0, value: value.gain_perc },
          { valueToDisplay: value.weighted_avg_years.toFixed(2) || 0, value: value.weighted_avg_years },
        ]);
      }
      excelTextValue = userName + "ZeroHoldings" + moment().format("DD-MM-YYYY hh-mm-ss A");
      excelDataValue.push({
        stock_name: value.name,
        product: value.product,
        units_balance: parseInt(value.unit_balance) || 0,
        value_at_cost: value.value_at_cost,
        value_at_sell: value.value_at_sell,
        realised_gain_loss: value.realised_gain_loss,
        gain_perc: value.gain_perc,
        weighted_avg_years: value.weighted_avg_years,

      });
    });

    return { headerData, values, excelDataValue, excelTextValue };
  };

  handlePress = async (investoraccountstock_id) => {
    this.setState({ savedState: this.state });
    const payload = {
      previous_key: "client_transactions_for_stock",
      param: investoraccountstock_id,
      // "param": 19206
    };
    // console.log("param :", investoraccountstock_id);
    this.setState({ isPopupLoading: true });
    this.setState({ isModalVisible: true });
    let resp = await getCustomerReport(payload);
    this.setState({ resp, isPopupLoading: false });
    // console.log("Response is ", resp);
  };

  closeModal = () => {
    // Restore the state from the saved state
    if (this.state.savedState) {
      this.setState(this.state.savedState);
    }

    this.setState({ isModalVisible: false });
  };
  getHeaderData = (data, isSwitchOn) => {
    const { sorting_field, sorting_value, userName, showStocksOf } = this.state;
    // console.log("Is Switch on or not", isSwitchOn);
    let keysData = null;
    if (this.state.showStocksOf == "Outside Stocks") {
      keysData = [
        {
          label: "Name",
          key: "stock_name",
        },
        {
          label: "Quantity",
          key: "units",
        },
        {
          label: "Avg. Price",
          key: "avg_price",
        },
        {
          label: "Latest Price",
          key: "closing_price",
        },
        {
          label: "Invested",
          key: "invested",
        },
        {
          label: "Latest Value",
          key: "latest_value",
        },
        {
          label: "Abs.Gain",
          key: "gain",
        },
        {
          label: "Gain %",
          key: "gain_percent",
        },
        {
          label: "XIRR %",
          key: "xirr_perc",
        },
        {
          label: "Avg. Holding Period",
          key: "holding_period",
        },
      ];
    } else {
      keysData = [
        {
          label: "Name",
          key: "stock_name",
        },
        {
          label: "Product",
          key: "product",
        },
        {
          label: "Quantity",
          key: "units",
        },
        {
          label: "Avg. Price",
          key: "avg_price",
        },
        {
          label: "Latest Price",
          key: "closing_price",
        },
        {
          label: "Invested",
          key: "invested",
        },
        {
          label: "Latest Value",
          key: "latest_value",
        },
        {
          label: "Abs.Gain",
          key: "gain",
        },
        {
          label: "Gain %",
          key: "gain_percent",
        },
        {
          label: "XIRR %",
          key: "xirr_perc",
        },
        {
          label: "Avg. Holding Period",
          key: "holding_period",
        },
      ];
    }
    let headerData = [];
    keysData.forEach((headerItem) => {
      headerData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: true,
        onSort: this.handleSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    let values = [];
    let excelTextValue = "";
    let excelDataValue = [];

    if (isSwitchOn) {
      data.forEach((value) => {
        // console.log("inside value nZ", value);

        const plan_avg_price = value.avg_price;
        const closing_price = parseFloat(value.latest_nav);
        const plan_invested = value.invested_amount;
        const assetsId = value.ASSET_ID;
        const product = value.product;
        const latest_value = value.latest_value_at_nav;
        const plan_gain = value.gain;
        const plan_gain_percent = value.gain_per * 100;
        const plan_holding_period = value.weighted_avg_days;
        let xirr_perc = value.latest_xirr;
        let planAveragePrice =
          plan_avg_price != null
            ? "₹" + numberWithCommas(plan_avg_price.toFixed(2))
            : "-";
        let closingPrice =
          closing_price != null
            ? "₹" + numberWithCommas(closing_price.toFixed(2))
            : "-";
        let planInvestedValue =
          plan_invested != null
            ? "₹" + numberWithCommas(plan_invested.toFixed(2))
            : "-";
        let latestValue =
          latest_value != null
            ? "₹" + numberWithCommas(latest_value.toFixed(2))
            : "-";
        let planGainValue =
          plan_gain != null
            ? "₹" + numberWithCommas(plan_gain.toFixed(2))
            : "-";
        let planGainPercent =
          plan_gain_percent != null ? plan_gain_percent.toFixed(1) : "-";
        if (plan_holding_period < 365) {
          xirr_perc =
            plan_gain_percent != null ? plan_gain_percent.toFixed(1) : "-";
        } else {
          xirr_perc = value.latest_xirr
            ? (parseFloat(value.latest_xirr) * 100).toFixed(1)
            : "-";
        }
        let planHoldingPeriod = plan_holding_period / 30;
        if (planHoldingPeriod < 1) {
          planHoldingPeriod = plan_holding_period.toFixed(0) + " days";
        } else if (planHoldingPeriod < 12) {
          planHoldingPeriod = planHoldingPeriod.toFixed(2) + " months";
        } else {
          planHoldingPeriod = (planHoldingPeriod / 12).toFixed(2) + " years";
        }

        excelTextValue =
          userName +
          " " +
          showStocksOf +
          " Stock Holdings (Since Inception) " +
          moment().format("DD-MM-YYYY hh-mm-ss A");

        excelDataValue.push({
          "Name": value["name"] || "",
          "Product": product,
          "Units": parseInt(value["UNIT_BAL"]) || 0,
          "Avg. Price": planAveragePrice,
          "Latest Price": closingPrice,
          "Invested": planInvestedValue,
          "Latest Value": latestValue,
          "Abs. Gain": planGainValue,
          "Gain %": planGainPercent,
          "XIRR %":
            plan_holding_period < 365
              ? plan_gain_percent.toFixed(1)
              : xirr_perc,
          "Avg. Holding Period": plan_holding_period,
        });
        if (this.state.selectedStockTab == "Outside Stocks") {
          values.push([
            {
              valueToDisplay:
                showStocksOf === "Outside Stocks" ? (
                  value["name"]
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      this.handlePress(assetsId);
                    }}
                    style={{ paddingLeft: 5 }}
                  >
                    <Text
                      style={{
                        color: "#410DAA",
                        fontSize: 12,
                        textDecorationLine: "underline",
                      }}
                    >
                      {value["name"]}
                    </Text>
                  </TouchableOpacity>
                ),
              value: value["name"] || "",
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: parseInt(value["UNIT_BAL"]),
              value: parseInt(value["UNIT_BAL"]) || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: planAveragePrice,
              value: value["avg_price"] || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: closingPrice,
              value: parseFloat(value["latest_nav"]) || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: planInvestedValue,
              value: value["invested_amount"] || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: latestValue,
              value: value["latest_value_at_nav"] || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: planGainValue,
              value: value["gain"] || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: planGainPercent,
              value: value["gain_per"] * 100 || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: xirr_perc,
              value: xirr_perc,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: planHoldingPeriod,
              value: value["weighted_avg_days"] || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
          ]);
        } else {
          values.push([
            {
              valueToDisplay:
                this.state.selectedStockTab === "Outside Stocks" ? (
                  value["name"]
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      this.handlePress(assetsId);
                    }}
                    style={{ paddingLeft: 5 }}
                  >
                    <Text
                      style={{
                        color: "#410DAA",
                        fontSize: 12,
                        textDecorationLine: "underline",
                      }}
                    >
                      {value["name"]}
                    </Text>
                  </TouchableOpacity>
                ),
              value: value["name"] || "",
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: product,
              value: product,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },

            {
              valueToDisplay: parseInt(value["UNIT_BAL"]),
              value: parseInt(value["UNIT_BAL"]) || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: planAveragePrice,
              value: value["avg_price"] || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: closingPrice,
              value: parseFloat(value["latest_nav"]) || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: planInvestedValue,
              value: value["invested_amount"] || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: latestValue,
              value: value["latest_value_at_nav"] || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: planGainValue,
              value: value["gain"] || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: planGainPercent,
              value: value["gain_per"] * 100 || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: xirr_perc,
              value: xirr_perc,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
            {
              valueToDisplay: planHoldingPeriod,
              value: value["weighted_avg_days"] || 0,
              style: [styles.rowItem],
              rowItemContainerStyle: [styles.rowItemContainerStyle],
            },
          ]);
        }
      });
    } else {
      data.forEach((value) => {
        const avg_price = value.avg_price;
        const closing_price = parseFloat(value.latest_nav);
        const invested = value.invested_amount;
        const latest_value = value.latest_value_at_nav;
        const gain = value.gain;
        const gain_percent = value.gain_per * 100;
        const holding_period = value.weighted_avg_days;
        let xirr_perc = value.latest_xirr;
        let averagePrice =
          avg_price != null
            ? "₹" + numberWithCommas(avg_price.toFixed(2))
            : "-";
        let closingPrice =
          closing_price != null
            ? "₹" + numberWithCommas(closing_price.toFixed(2))
            : "-";
        let investedValue =
          invested != null ? "₹" + numberWithCommas(invested.toFixed(2)) : "-";
        let latestValue =
          latest_value != null
            ? "₹" + numberWithCommas(latest_value.toFixed(2))
            : "-";
        let gainValue =
          gain != null ? "₹" + numberWithCommas(gain.toFixed(2)) : "-";
        let gainPercent = gain_percent != null ? gain_percent.toFixed(2) : "-";

        if (holding_period < 365) {
          xirr_perc = gain_percent != null ? gain_percent.toFixed(1) : "-";
        } else {
          xirr_perc = value.latest_xirr
            ? (parseFloat(value.latest_xirr) * 100).toFixed(1)
            : "-";
        }
        let holdingPeriod = holding_period / 30;
        if (holdingPeriod < 1) {
          holdingPeriod = holding_period.toFixed(0) + " days";
        } else if (holdingPeriod < 12) {
          holdingPeriod = holdingPeriod.toFixed(2) + " months";
        } else {
          holdingPeriod = (holdingPeriod / 12).toFixed(2) + " years";
        }

        excelTextValue =
          userName +
          " " +
          showStocksOf +
          " Stock Holdings (Active Plans) " +
          moment().format("DD-MM-YYYY hh-mm-ss A");

        excelDataValue.push({
          "Name": value["name"] || "",
          "Units": parseInt(value["UNIT_BAL"]) || 0,
          "Avg. Price": averagePrice,
          "Latest Price": closingPrice,
          "Invested": investedValue,
          "Latest Value": latestValue,
          "Abs. Gain": gainValue,
          "Gain %": gainPercent,
          "XIRR %": holding_period < 365 ? gain_percent.toFixed(1) : xirr_perc,
          "Avg. Holding Period": holding_period,
        });

        values.push([
          {
            valueToDisplay: value["name"],
            value: value["name"] || "",
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: value["UNIT_BAL"],
            value: value["UNIT_BAL"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: averagePrice,
            value: value["avg_price"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: closingPrice,
            value: parseFloat(value["latest_nav"]) || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: investedValue,
            value: value["invested_amount"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: latestValue,
            value: value["latest_value_at_nav"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: gainValue,
            value: value["gain"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: gainPercent,
            value: value["gain_per"] * 100 || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: xirr_perc,
            value: xirr_perc,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
          {
            valueToDisplay: holdingPeriod,
            value: value["weighted_avg_days"] || 0,
            style: [styles.rowItem],
            rowItemContainerStyle: [styles.rowItemContainerStyle],
          },
        ]);
      });
    }
    return { headerData, values, excelTextValue, excelDataValue };
  };

  handleOnPress = () => {
    this.setState({ show_options: false });
  };

  handleToggle = (index) => {
    if (this.state.selectedIndex == index) {
      this.setState({
        show_options: !this.state.show_options,
        selectedIndex: index,
      });
    } else {
      this.setState({ show_options: true, selectedIndex: index });
    }
  };

  getMFHeaderData = (data) => {
    const { sorting_field, sorting_value, selectedMFTab } = this.state;

    const keysData = [
      {
        label: "Name",
        key: "stock_name",
      },
      {
        label: "Current Value",
        key: "units",
      },
      {
        label: "Invested",
        key: "avg_price",
      },
      {
        label: "Unit Balance",
        key: "closing_price",
      },
      {
        label: "Avg. Duration",
        key: "invested",
      },
      {
        label: "Gain",
        key: "latest_value",
      },
      {
        label: "XIRR %",
        key: "gain",
      },
      {
        label: "Abs Gain%",
        key: "gain_percent",
      },
    ];

    if (selectedMFTab == "Jama") {
      keysData.push({
        label: "Actions",
        key: "holding_period",
      });
    }

    let headerMFData = [];
    keysData.forEach((headerItem) => {
      headerMFData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: headerItem.label == "Actions" ? false : true,
        onSort: headerItem.label == "Actions" ? null : this.handleMFSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    let valuesMF = [];

    data.forEach((value, index) => {
      // console.log(value);
      let temp_valuesMF = [
        {
          valueToDisplay: `₹${this.numberFormat(
            parseInt(value.units).toFixed(0)
          )}`,
          value: value.units,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: `₹${this.numberFormat(
            parseInt(value.avg_price).toFixed(0)
          )}`,
          value: value.avg_price,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.closing_price,
          value: value.closing_price,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: holdingPeriodText(parseInt(value.invested)),
          value: value.invested,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: `₹${value.latest_value}`,
          value: value.latest_value,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay:
            value.invested >= 365 ? `${value.gain}%` : `${value.gain_percent}%`,
          value: value.invested >= 365 ? value.gain : value.gain_percent,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: `${value.gain_percent}%`,
          value: value.gain_percent,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ];

      if (selectedMFTab == "Jama") {
        temp_valuesMF.unshift({
          component: true,
          value: (
            <TouchableOpacity
              onPress={() => {
                this.props.navigation.navigate("MyMFInvestmentHistory", {
                  fund: value.iaf_id,
                  fundType: this.state.selectedMFTab,
                });
              }}
              style={{
                paddingLeft: 5,
              }}
            >
              <Text
                style={{
                  color: "#410DAA",
                  fontSize: 12,
                  textDecorationLine: "underline",
                }}
              >
                {value.stock_name}
              </Text>
            </TouchableOpacity>
          ),
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        });
        temp_valuesMF.push({
          component: true,
          value: (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                style={{
                  paddingRight: 10,
                }}
                onPress={() => {
                  this.props.navigation.navigate("App", {
                    screen: "Dashboard",
                    params: {
                      screen: "Explore",
                      params: {
                        screen: "FundDescription",
                        params: {
                          fund: value.amcfund_id,
                          add_invt: "Y",
                          fund_amc_id: value.amcfundscheme_id,
                        },
                      },
                    },
                  });
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 13,
                    color: "#FFFFFF",
                    backgroundColor: "#410DAA",
                    borderColor: "#410DAA",
                    borderWidth: 1,
                    borderRadius: 4,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  Invest
                </Text>
              </TouchableOpacity>
              {/* <MenuProvider 
                style={{
                  flexDirection: 'column',
                  padding: 50
                }}
              >
                <Menu 
                  opened={this.state.show_options && this.state.selectedIndex==index}
                  onBackdropPress={() => this.handleOnPress()}
                >
                  <MenuTrigger onPress={() => this.handleToggle(index)}>
                    <Entypo name="dots-three-vertical" size={20} color="#383c42" />
                  </MenuTrigger>
                    <MenuOptions customStyles={optionsStyles}>
                      <MenuOption 
                        onSelect={()=> {
                          this.handleOnPress();
                          this.props.navigation.navigate("Redeem", {
                            fund: value.iaf_id,
                          });
                        }} 
                        text="Redeem" 
                      />
                      {value.bse_swp_flag == "Y" && (
                        <MenuOption 
                          onSelect={()=> {
                            this.handleOnPress();
                            this.props.navigation.navigate("Redeem", {
                              fund: value.iaf_id,
                              type: "swp"
                            });
                          }} 
                          text="SWP" 
                        />
                      )}
                      {value.bse_stp_flag == "Y" && (
                        <MenuOption 
                          onSelect={()=> {
                            this.handleOnPress();
                            this.props.navigation.navigate("Redeem", {
                              fund: value.iaf_id,
                              type: "stp"
                            });
                          }} 
                          text="STP" 
                        />
                      )}
                      {value.bse_switch_flag == "Y" && (
                        <MenuOption 
                          onSelect={()=> {
                            this.handleOnPress();
                            this.props.navigation.navigate("Redeem", {
                              fund: value.iaf_id,
                              type: "switch"
                            });
                          }} 
                          text="Switch" 
                        />
                      )}
                    </MenuOptions>
                </Menu>
              </MenuProvider> */}
              <TouchableOpacity
                onPress={() => {
                  this.handleToggle(index);
                }}
                style={
                  {
                    // marginTop: 25,
                    // paddingVertical: 9,
                  }
                }
              >
                <Entypo name="dots-three-vertical" size={20} color="black" />
              </TouchableOpacity>
              {this.state.show_options && this.state.selectedIndex == index && (
                <View
                  style={{
                    width: 90,
                    backgroundColor: "white",
                    // position: 'absolute',
                    marginLeft: -115,
                    marginTop: 60,
                    zIndex: 100,
                    borderWidth: 0.5,
                    borderRadius: 5,
                    borderColor: "#410DAA1A",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.handleOnPress();
                      this.props.navigation.navigate("Redeem", {
                        fund: value.iaf_id,
                        type: "redeem",
                      });
                    }}
                    style={{
                      borderBottomWidth: 1,
                      borderColor: "#410DAA1A",
                      justifyContent: "center",
                      padding: 10,
                    }}
                  >
                    <Text style={{ color: "#666666", fontWeight: "500" }}>
                      Redeem
                    </Text>
                  </TouchableOpacity>
                  {value.bse_swp_flag == "Y" && (
                    <TouchableOpacity
                      onPress={() => {
                        this.handleOnPress();
                        this.props.navigation.navigate("Redeem", {
                          fund: value.iaf_id,
                          type: "swp",
                        });
                      }}
                      style={{
                        borderBottomWidth: 1,
                        borderColor: "#410DAA1A",
                        justifyContent: "center",
                        padding: 10,
                      }}
                    >
                      <Text style={{ color: "#666666", fontWeight: "500" }}>
                        SWP
                      </Text>
                    </TouchableOpacity>
                  )}
                  {value.bse_stp_flag == "Y" && (
                    <TouchableOpacity
                      onPress={() => {
                        this.handleOnPress();
                        this.props.navigation.navigate("Redeem", {
                          fund: value.iaf_id,
                          type: "stp",
                        });
                      }}
                      style={{
                        borderBottomWidth: 1,
                        borderColor: "#410DAA1A",
                        justifyContent: "center",
                        padding: 10,
                      }}
                    >
                      <Text style={{ color: "#666666", fontWeight: "500" }}>
                        STP
                      </Text>
                    </TouchableOpacity>
                  )}
                  {value.bse_switch_flag == "Y" && (
                    <TouchableOpacity
                      onPress={() => {
                        this.handleOnPress();
                        this.props.navigation.navigate("Redeem", {
                          fund: value.iaf_id,
                          type: "switch",
                        });
                      }}
                      style={{
                        borderBottomWidth: 1,
                        borderColor: "#410DAA1A",
                        justifyContent: "center",
                        padding: 10,
                      }}
                    >
                      <Text style={{ color: "#666666", fontWeight: "500" }}>
                        Switch
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
            </View>
          ),
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        });
      } else {
        temp_valuesMF.unshift({
          valueToDisplay: value.stock_name,
          value: value.stock_name,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        });
      }
      valuesMF.push(temp_valuesMF);
    });

    return { headerMFData, valuesMF };
  };

  getAssetHeaderData = (data) => {
    const { sorting_field, sorting_value, userName, showStocksOf } = this.state;

    const keysData = [
      {
        label: "Name",
        key: "stock_name",
      },
      {
        label: "Asset Type",
        key: "asset_type",
      },
      {
        label: "Date of Purchase",
        key: "units",
      },
      {
        label: "Amount at Cost",
        key: "closing_price",
      },
      {
        label: "Latest Value",
        key: "invested",
      },
      {
        label: "Action",
        key: "asset_id",
      },
    ];

    let headerAssetData = [];
    keysData.forEach((headerItem) => {
      headerAssetData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: true,
        onSort: this.handleAssetSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    let valuesAsset = [];
    data.forEach((value) => {
      let date_of_purchase = value.units;
      let date = "";
      if (date_of_purchase) {
        date = date_of_purchase.split("-");
        const month = months_short_names(parseInt(date[1]));
        date = date[2] + " " + month + " " + date[0];
      }
      valuesAsset.push([
        {
          component: true,
          value: (
            <Text>
              {value.stock_name}
              {"\n"}
              {value.goal_value && (
                <Text style={{ color: "#888C9F", marginLeft: 5, marginTop: 5 }}>
                  {value.goal_value}{" "}
                  {value.goal_value && (
                    <Feather name={"target"} size={10} color={"#410DAA"} />
                  )}
                </Text>
              )}
            </Text>
          ),
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.asset_type,
          value: value.asset_type,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: date,
          value: date,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.closing_price,
          value: value.closing_price,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.invested,
          value: value.invested,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: [value.asset_id, value.fund_class],
          value: [value.asset_id, value.fund_class],
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ]);
    });

    return { headerAssetData, valuesAsset };
  };

  handleImportStocks = () => {
    this.props.navigation.navigate("App", {
      screen: "ViewMode",
      params: {
        screen: "ImportStocks",
        params: {
          from: "portfolio",
        },
      },
    });
  };

  handleImportMF = () => {
    // this.props.navigation.navigate("App", {
    //   screen: "ViewMode",
    //   params: {
    //     screen: "ImportMF",
    //     params: {},
    //   },
    // });

    this.props.navigation.navigate("App", {
      screen: "ViewMode",
      params: {
        screen: "ImportStocks",
        params: {
          from: "portfolio",
        },
      },
    });
  };

  handleScheduleJob = async () => {
    try {
      this.setState({
        redoTaggingLoader: true,
        jobScheduled: false,
      });
      let user_id = Store.getState().dashboard.viewModeUserId.user_id;
      const payload = {
        shell_script:
          "#!/bin/sh\n\n# Enter your shell script code here\n/opt/djangostack-1.9.7-0/python/bin/python /opt/git/python/app/manage.py redo_and_tag_and_update_plan_values --client_ids " +
          user_id.toString(),
        event_name: "CIP Redo Tagging",
        category: "cjo3zx0pbjs",
      };
      const response = await scheduleCronJobWithApi(payload);
      if (response && response.code == 100) {
        this.setState({
          redoTaggingLoader: false,
          jobScheduled: true,
        });
      } else {
        this.setState({
          errorText: response.msg,
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        errorText: err,
      });
    }
  };

  startCounter = () => {
    this.timerInterval = setInterval(() => {
      if (this.state.timer > 0) {
        this.setState((prevState) => ({
          timer: prevState.timer - 1,
        }));
      } else {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  };

  handleCamsResponse = async () => {
    this.setState({ showAggregatorModal: true, timer: 60 });
    this.startCounter();
    let fail_count = 0;
    const interval = setInterval(async () => {
      // console.log("Inside Timeout function");
      let response = await getAccountAggregatorConsentStatus();
      // console.log("response is ", response);
      if (response && response.code == "100") {
        if (
          response.result == "Status Approved" ||
          response.result == "Status Rejected"
        ) {
          this.setState({ aggregatorResponse: response.result });
          return;
        } else {
          fail_count++;
        }
      } else {
        fail_count++;
      }
      if (fail_count >= 12 || this.state.timer == 0) {
        this.setState({ aggregatorResponse: "Something went wrong" });
        clearInterval(interval);
        return;
      }
    }, 5000);
    return () => clearInterval(interval);
  };

  handleAggregator = async () => {
    let userData = await getUserDetails();
    let payload = {
      mobilenumber: userData.mobilenumber,
      pannumber: userData.pan_number,
      useCaseid: "331",
      fipid: "CAMC,Cams Depository",
      redirect_url:
        Platform.OS == "web"
          ? `https://${global.location.hostname}/dashboard/home`
          : "https://jamawealth.page.link/Dashboard",
      cams_key: "_cams_key",
    };
    // console.log("current urls is ", payload.redirect_url);
    this.setState({ isLoading: true, aggregatorResponse: "Pending" });
    let response = await generateCamsAggregatorLink(payload);
    this.setState({ isLoading: false });
    if (Platform.OS === "web") {
      global.open(response.result.redirectionurl, "_self");
    } else {
      // const result = await WebBrowser.openAuthSessionAsync(response.result.redirectionurl, "https://jamawealth.page.link/Dashboard");
      // if (result.type === 'success') {
      //   console.log('Returned to app successfully!');
      // } else if (result.type === 'dismiss') {
      //   console.log('User dismissed the browser');
      // } else {
      //   console.log('Failed to return to app');
      // }
      Linking.openURL(response.result.redirectionurl)
    }
  };
  handleSortSingle = (field, sort, start, end) => {
    const sorting_field = field;
    const sorting_value = !sort;
    const { resp } = this.state;

    const sortedData = [].concat(
      resp.result.slice(0, start),
      this.getSortedData(
        resp.result,
        sorting_field,
        sorting_value,
        start,
        end
      ),
      resp.result.slice(end, resp.result.length)
    );

    this.setState({ sorting_field, sorting_value, resp: { ...resp, result: sortedData } });
  };

  render() {
    const {
      familyTabsList,
      selectedFamilyTab,
      isLoading,
      errorText,
      activeTab,
      userId,
      investTotalUser,
      investValue,
      investValueWithStockMF,
      investValueWithAsset,
      investValueFull,
      investedValue,
      investedValueWithStockMF,
      investedValueWithAsset,
      investedValueFull,
      gainValue,
      gainValueWithStockMF,
      gainValueWithAsset,
      gainValueFull,
      gainPercentValue,
      gainPercentValueWithStockMF,
      gainPercentValueWithAsset,
      gainPercentValueFull,
      overallReturnValue,
      overallReturnPercent,
      avg_holding_period,
      stocks_summary,
      selectedMFTab,
      mfInJamaData,
      mfOutsideData,
      AllAssetsData,
      selectedAssetTab,
      filteredAsset,
      searchAssetResults = [],
      searchMFResults = [],
      searchResults = [],
      searchAssetText,
      shouldShowMF,
      searchMFText,
      // equity_investment_plans,
      stocks_summary_prodcut_wise,
      showTeaser,
      loadingData,
      showStocksOf,
      searchText,
      strips,
      mfRegularData,
      resp,
    } = this.state;

    const { headerData, values, excelTextValue, excelDataValue } = !this.state
      .show_zero_holding
      ? this.getHeaderData(
        searchResults,
        this.state.isSwitchOn,
        this.state.excelTextValue,
        this.state.excelDataValue
      )
      : this.getZeroHoldingHeaderData(this.state.zeroHoldingResp);
    // console.log(headerData, values, excelTextValue, excelDataValue,"whole check")
    const { headerMFData, valuesMF } = this.getMFHeaderData(searchMFResults);
    const { headerAssetData, valuesAsset } =
      this.getAssetHeaderData(searchAssetResults);
    // console.log(headerAssetData,valuesAsset,"asset check")
    if (errorText || isLoading) {
      return (
        <View style={styles.loadingContainer}>
          {isLoading && <ProgressBar msg={"Loading Portfolio"} />}
          {errorText && <Text style={styles.errorText}>{errorText}</Text>}
        </View>
      );
    }

    let excelAssetData = [];
    searchAssetResults.forEach((item) => {
      let date_of_purchase = item.units;
      let date = "";
      if (date_of_purchase) {
        date = date_of_purchase.split("-");
        const month = months_short_names(parseInt(date[1]));
        date = date[2] + " " + month + " " + date[0];
      }
      let closingPrice = "₹" + numberWithCommas(item.closing_price);
      let investedValue = "₹" + numberWithCommas(item.invested);
      excelAssetData.push({
        stock_name: item.stock_name,
        gain_percent: item.gain_percent,
        invested: investedValue,
        closing_price: closingPrice,
        asset_type: item.asset_type,
        units: date,
      });
    });

    const { bannerData, customer_id } = getUserDetails();
    let isCompliant = false;
    let user_id = null;
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      user_id = Store.getState().dashboard.viewModeUserId.user_id;
    } else {
      user_id = customer_id;
    }
    if (bannerData.name == "Sensage" && user_id > 1 && user_id < 140) {
      isCompliant = true;
    }
    // const tableHead = ['Name', 'ISIN Number', 'Trade Date'];
    const tableData =
      this.state.resp &&
      this.state.resp.result &&
      this.state.resp.result.map((item) => [
        { valueToDisplay: item.trade_date }, // TRANSACTION DATE
        {
          valueToDisplay:
            item.transaction_type === "D" ? "-" : item.number_of_units,
        }, // UNITS
        {
          valueToDisplay:
            item.transaction_type === "D"
              ? "-"
              : `₹${Number(parseFloat(item.price).toFixed(2)).toLocaleString(
                "en-IN"
              )}`,
        }, // PRICE
        {
          valueToDisplay:
            item.transaction_type === "D"
              ? "-"
              : parseFloat(item.price) * item.number_of_units < 0
                ? `- ₹${Math.abs(parseFloat(item.price) * item.number_of_units)
                  .toFixed(2)
                  .toLocaleString("en-IN")}`
                : `₹${Number(
                  (parseFloat(item.price) * item.number_of_units).toFixed(2)
                ).toLocaleString("en-IN")}`,
        }, // AMOUNT
        {
          valueToDisplay:
            item.transaction_type === "B"
              ? "BUY"
              : item.transaction_type === "S"
                ? "SELL"
                : "DIVIDEND",
        }, // TYPE
      ]);
    let excelDataValue1 = [];
    if (this.state.resp && this.state.resp.result) {
      this.state.resp.result.forEach((item) => {
        excelDataValue1.push({
          trade_date: item.trade_date || "",
          units:
            item.transaction_type === "D"
              ? "-"
              : parseInt(item.number_of_units) || 0,
          price:
            item.transaction_type === "D"
              ? "-"
              : `₹${Number(parseFloat(item.price).toFixed(2)).toLocaleString(
                "en-IN"
              )}`,
          amount:
            item.transaction_type === "D"
              ? "-"
              : parseFloat(item.price) * item.number_of_units < 0
                ? `- ₹${Math.abs(parseFloat(item.price) * item.number_of_units)
                  .toFixed(2)
                  .toLocaleString("en-IN")}`
                : `₹${Number(
                  (parseFloat(item.price) * item.number_of_units).toFixed(2)
                ).toLocaleString("en-IN")}`,
          type:
            item.transaction_type === "B"
              ? "BUY"
              : item.transaction_type === "S"
                ? "SELL"
                : "DIVIDEND",
        });
      });
    }
    // console.log(excelDataValue1,"excelvalue")
    // console.log(tableData,"data check")
    const header1 = [
      {
        label: "TRADE DATE",
        key: "Transaction_Date",
        sortValue: "Transaction_Date",
        currentSortField: "Transaction_Date",
      },
      {
        label: "UNITS",
        key: "units",
        sortValue: "units",
        currentSortField: "units",
      },
      {
        label: "PRICE",
        key: "Price",
        sortValue: "Price",
        currentSortField: "Price",
      },
      {
        label: "AMOUNT",
        key: "Amount",
        sortValue: "Amount",
        currentSortField: "Amount",
      },
      {
        label: "TYPE",
        key: "Type",
        sortValue: "Type",
        currentSortField: "Type",
      },
    ];
    let trxnHeaderData = [];
    let { sorting_field, sorting_value } = this.state;
    header1.forEach((headerItem) => {
      trxnHeaderData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: true,
        onSort: this.handleSortSingle, // Ensure this is correctly set
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });
    // console.log("this.state.searchResults", this.state.searchResults)
    // console.log(trxnHeaderData,"trx check")
    return (
      <View style={styles.container}>
        {this.state.isModalVisible && (
          <Modal>
            <View style={{ height: screenHeight, width: screenWidth }}>
              {this.state.isPopupLoading ? (
                <View
                  style={[
                    styles.loadingConainer,
                    {
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: 150,
                    },
                  ]}
                >
                  <ProgressBar msg={"Loading Details.."} />
                </View>
              ) : (
                <ScrollView style={{ height: screenHeight }}>
                  {this.state.resp &&
                    this.state.resp.result &&
                    this.state.resp.result.length > 0 &&
                    (() => {
                      const firstItem = this.state.resp.result[0];
                      const name = firstItem.name;
                      const excelTextValue1 = `${name} trade details`;
                      const symbol = firstItem.symbol;
                      const isin_number = firstItem.isin_number;
                      const latest_closing_price =
                        firstItem.latest_closing_price;
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            margin: 10,
                          }}
                        >
                          <View
                            style={{
                              paddingLeft: 10,
                              paddingTop: 10,
                              marginLeft: 10,
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text>{`${name} (${symbol}/${isin_number})`}</Text>
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{ marginRight: 20, marginLeft: 20 }}
                              >{`Latest Closing Price: ₹${parseFloat(
                                latest_closing_price
                              ).toLocaleString("en-IN")}`}</Text>
                            </View>
                          </View>
                          <View style={{ marginRight: 10 }} />
                          <Icon
                            name="times"
                            size={30}
                            style={{ position: "absolute", right: 200 }}
                            onPress={this.closeModal}
                          />
                        </View>
                      );
                    })()}
                  <ScrollView
                    horizontal={true}
                    style={[styles.mainContainer, { flex: 1 }]}
                    showsVerticalScrollIndicator={false}
                  >
                    <View style={styles.detailsContainer}>
                      <StockTable
                        header={trxnHeaderData}
                        gridConfig={[1, 1, 1, 1, 1]}
                        data={tableData}
                        pagination={tableData.length > 10}
                        filterKeys={[0]}
                        showSearch={false}
                      />
                    </View>
                  </ScrollView>
                </ScrollView>
              )}
            </View>
          </Modal>
        )}
        {/* <View style={styles.container}> */}
        {this.state.show_choose_your_plan ? (
          <NoPlanModal
            navigation={this.props.navigation}
            route={this.props.route}
          />
        ) : null}
        {this.state.showAggregratorSuccess && (
          <Modal
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                padding: 50,
              }}
            >
              {this.state.aggregatorResponse == "Status Approved" ? (
                <View
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesome5 name="check" size={32} color="green" />
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "700",
                      marginTop: 15,
                    }}
                  >
                    Yay! Your consent status is approved
                  </Text>
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontSize: 14,
                      fontWeight: "400",
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    The account aggregator will send your portfolio data within
                    ten minutes.
                  </Text>
                  {/* <Text style={{
                  color: '#1a1a1a',
                  fontSize: 14,
                  fontWeight: '400',
                  marginTop: 5
                }} >We will email you once received.</Text> */}
                  <View
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 30,
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        backgroundColor: "#410DAA",
                        paddingHorizontal: 20,
                        paddingVertical: 10,
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                      }}
                      onPress={async () => {
                        this.props.navigation.navigate("App", {
                          screen: "Dashboard",
                          params: {
                            show_card_view: true,
                          },
                        });
                        this.setState({
                          showAggregatorModal: false,
                          showAggregratorSuccess: false,
                        });
                      }}
                    >
                      <Text style={{ color: "#fff", textAlign: "center" }}>
                        Continue
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              ) : this.state.aggregatorResponse == "Status Rejected" ? (
                <View
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 20,
                  }}
                >
                  <MaterialIcons name="cancel" size={32} color="red" />
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "700",
                      marginTop: 15,
                    }}
                  >
                    Oh oh! Looks like you did not consent
                  </Text>
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontSize: 14,
                      fontWeight: "400",
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    Please note: your portfolio analysis is only to help simply
                    grow it (to import again click on Fetch Portfolio)
                  </Text>
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontSize: 14,
                      textAlign: "center",
                      fontWeight: "400",
                      marginTop: 5,
                    }}
                  >
                    You may try other import options to pick and choose what you
                    wish to import
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 30,
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        backgroundColor: "#410DAA",
                        padding: 10,
                        width: 150,
                        marginRight: 20,
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                      }}
                      onPress={() => {
                        this.setState({
                          showAggregatorModal: false,
                          showAggregratorSuccess: false,
                        });
                      }}
                    >
                      <Text style={{ color: "#fff", textAlign: "center" }}>
                        Fetch Portfolio
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        backgroundColor: "#fff",
                        padding: 10,
                        borderColor: "#410DAA",
                        borderWidth: 1,
                        width: 250,
                        marginRight: 20,
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                      }}
                      onPress={() => {
                        this.setState({
                          showAggregatorModal: false,
                          step: 2,
                          showAggregratorSuccess: false,
                        });
                      }}
                    >
                      <Text style={{ color: "#410DAA", textAlign: "center" }}>
                        Try Other Import Options
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              ) : this.state.aggregatorResponse == "Pending" ? (
                <View
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ActivityIndicator size={"large"} color={"#410DAA"} />
                  {this.state.timer > 40 ? (
                    <View>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontWeight: "700",
                          marginTop: 15,
                        }}
                      >
                        Fetching consent status from Account Aggregator
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          fontWeight: "400",
                          marginTop: 8,
                        }}
                      >
                        This may take several minutes.
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          fontWeight: "400",
                          marginTop: 8,
                        }}
                      >
                        If it takes longer, we will notify you once the analysis
                        is ready
                      </Text>
                    </View>
                  ) : (
                    <View>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontWeight: "700",
                          marginTop: 15,
                        }}
                      >
                        Oops, this is taking longer than expected.{" "}
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          fontWeight: "400",
                          marginTop: 8,
                        }}
                      >
                        Our team will get back to you once the data from the AA
                        is received.{" "}
                      </Text>
                      <Text
                        style={{
                          color: "#1a1a1a",
                          fontSize: 14,
                          fontWeight: "400",
                          marginTop: 8,
                        }}
                      >
                        You may log in later to see the status on the dashboard.
                      </Text>
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 30,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            backgroundColor: "#410DAA",
                            paddingHorizontal: 20,
                            paddingVertical: 10,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                          }}
                          onPress={async () => {
                            this.props.navigation.navigate("App", {
                              screen: "Dashboard",
                              params: {
                                show_card_view: true,
                              },
                            });
                            this.setState({
                              showAggregatorModal: false,
                              showAggregratorSuccess: false,
                            });
                          }}
                        >
                          <Text style={{ color: "#fff", textAlign: "center" }}>
                            Continue
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <View
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MaterialIcons name="error-outline" size={32} color="red" />
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontWeight: "700",
                      marginTop: 15,
                    }}
                  >
                    {" "}
                    Oops! Your consent status is still not received
                  </Text>
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontSize: 14,
                      fontWeight: "400",
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    We will check with the account aggregator and keep you
                    updated.
                  </Text>
                  <Text
                    style={{
                      color: "#1a1a1a",
                      fontSize: 14,
                      fontWeight: "400",
                      textAlign: "center",
                      marginTop: 5,
                    }}
                  >
                    you can try other options to import your portfolio
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 30,
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        backgroundColor: "#410DAA",
                        padding: 10,
                        justifyContent: "center",
                        width: 150,
                        marginLeft: 20,
                        alignItems: "center",
                        borderRadius: 5,
                      }}
                      onPress={() => {
                        this.setState({ showAggregatorModal: false, step: 2 });
                      }}
                    >
                      <Text style={{ color: "#fff", textAlign: "center" }}>
                        Continue
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )}
            </View>
          </Modal>
        )}

        {isMobile ? (
          <View>
            <ScrollView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={true}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#F6F6F6",
                  minWidth: width,
                  justifyContent: "space-between",
                  paddingTop: 10,
                  height: 57,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <FlatList
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    data={familyTabsList}
                    contentContainerStyle={{
                      alignItems: "flex-start",
                    }}
                    renderItem={({ item, index }) => {
                      return (
                        <View>
                          <TouchableOpacity
                            style={styles.memberIconContainer}
                            onPress={async () => {
                              this.filterDataToShow(
                                item.value,
                                this.state.summary,
                                this.state.data_values
                              );
                              this.setState({
                                selectedFamilyTab: index,
                                activeTab: this.state.activeTab,
                                selectedMFTab: this.state.selectedMFTab,
                                selectedStockTab: this.state.selectedStockTab,
                                selectedAssetTab: this.state.selectedAssetTab,
                                current_user: item.value,
                              });
                            }}
                          >
                            <View
                              style={{
                                backgroundColor:
                                  selectedFamilyTab == index
                                    ? "#f6f2ff"
                                    : "transparent",
                                borderColor:
                                  selectedFamilyTab == index
                                    ? "#410DAA"
                                    : "transparent",
                                width: 100,
                                height: 37,
                                borderRadius: 10,
                                borderWidth: selectedFamilyTab == index ? 1 : 0,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: 14,
                                  color:
                                    selectedFamilyTab == index
                                      ? "#410DAA"
                                      : "#464E5F",
                                  marginTop: -2,
                                  fontWeight: "500",
                                }}
                              >
                                {item.name}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                      );
                    }}
                  />
                </View>
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    marginRight: 14,
                    marginTop: 2,
                  }}
                  onPress={() => {
                    this.props.navigation.navigate("AddFamily");
                  }}
                >
                  <Text
                    style={{
                      fontSize: 15,
                      color: "#410DAA",
                      paddingTop: 5,
                      paddingRight: 5,
                      fontWeight: "500",
                    }}
                  >
                    {isMobile ? "" : "Add Member"}
                  </Text>
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/useraccount/addmember.png",
                    }}
                    style={{
                      width: 32,
                      height: 32,
                      resizeMode: "contain",
                      marginHorizontal: 5,
                    }}
                  />
                </TouchableOpacity>
              </View>
            </ScrollView>
          </View>
        ) : (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#F6F6F6",
              justifyContent: "space-between",
              paddingTop: 20,
              height: 76,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <FlatList
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                data={familyTabsList}
                contentContainerStyle={{
                  alignItems: "flex-start",
                  paddingBottom: 15,
                  paddingHorizontal: 26,
                  marginBottom: 20,
                }}
                renderItem={({ item, index }) => {
                  return (
                    <View>
                      <TouchableOpacity
                        style={styles.memberIconContainer}
                        onPress={async () => {
                          this.filterDataToShow(item.value, this.state.summary, this.state.data_values);
                          this.setState({
                            selectedFamilyTab: index,
                            activeTab: this.state.activeTab,
                            selectedMFTab: this.state.selectedMFTab,
                            selectedStockTab: this.state.selectedStockTab,
                            selectedAssetTab: this.state.selectedAssetTab,
                            current_user: item.value,
                          });
                        }}
                      >
                        <View
                          style={{
                            backgroundColor:
                              selectedFamilyTab == index ||
                                familyTabsList.length == 1
                                ? "#f6f2ff"
                                : "transparent",
                            borderColor:
                              selectedFamilyTab == index ||
                                familyTabsList.length == 1
                                ? "#410DAA"
                                : "transparent",
                            width: 100,
                            height: 37,
                            borderRadius: 10,
                            borderWidth:
                              selectedFamilyTab == index ||
                                familyTabsList.length == 1
                                ? 1
                                : 0,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <View style={{ flexDirection: "row" }}>
                            {/* {item.name == "Self" && (
                                <Image
                                  source={{
                                    uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/member-active.png",
                                  }}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    resizeMode: "contain",
                                    marginHorizontal: 10,
                                    marginTop: -2,
                                  }}
                                />
                              )} */}
                            <Text
                              style={{
                                textAlign: "center",
                                fontSize: 14,
                                color:
                                  selectedFamilyTab == index
                                    ? "#410DAA"
                                    : "#464E5F",
                                fontWeight: "500",
                              }}
                            >
                              {item.name}
                            </Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>
                  );
                }}
              />
            </View>
            <TouchableOpacity
              style={{
                flexDirection: "row",
                marginRight: 14,
                marginTop: 2,
              }}
              onPress={() => {
                this.props.navigation.navigate("AddFamily");
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  color: "#410DAA",
                  paddingTop: 5,
                  paddingRight: 5,
                  fontWeight: "500",
                }}
              >
                {isMobile ? "" : "Add Member"}
              </Text>
              <Image
                source={{
                  uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/useraccount/addmember.png",
                }}
                style={{
                  width: 32,
                  height: 32,
                  resizeMode: "contain",
                  marginHorizontal: 5,
                }}
              />
            </TouchableOpacity>
          </View>
        )}
        <View
          style={{
            display: "flex",
            flexDirection: isMobile ? "row" : "row",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <View style={{ flex: 2 }}>
            <FlatList
              data={[
                "Summary",
                "PMS",
                "Stocks",
                "Mutual Funds",
                "Other Assets",
              ]}
              extraData={activeTab}
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={{
                alignItems: "flex-start",
                paddingHorizontal: 18,
              }}
              renderItem={({ item }) => {
                let backgroundColor;
                let color;
                if (item == this.state.activeTab) {
                  backgroundColor = "#410DAA";
                  color = "#fff";
                } else {
                  backgroundColor = "#fff";
                  color = "#121212";
                }
                return (
                  <SelectTabDetails
                    item={item}
                    backgroundColor={backgroundColor}
                    color={color}
                    onPress={() => {
                      // console.log("headerdata:", this.getHeaderData.headerData);
                      this.setState({
                        selectedMFTab: "Jama",
                        selectedStockTab: "Inside Stocks",
                        selectedAssetTab: "Stocks",
                        reportLoaded: false,
                        activeTab: item,
                      });
                      this.onSelectStockTab(0);
                      this.onSelectAssetTab(this.state.selectedAssetSubTab);
                    }}
                  />
                );
              }}
              keyExtractor={(item, index) => index}
            />
          </View>
          <View>
            {Store.getState().dashboard &&
              Store.getState().dashboard.viewModeUserId &&
              Store.getState().dashboard.viewModeUserId.user_id ? (
              this.state.redoTaggingLoader ? (
                <View style={styles.loadingConainer}>
                  <ProgressBar msg={"Loading Portfolio"} />
                </View>
              ) : (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: isMobile ? 20 : 0,
                  }}
                >
                  <Text
                    style={{
                      color: "#410DAA",
                      fontWeight: "bold",
                      textDecorationLine: "underline",
                      marginRight: isMobile ? 20 : 50,
                      textAlign: "center",
                      marginLeft: isMobile ? 10 : 0,
                    }}
                    onPress={() => {
                      let userId =
                        Store.getState().dashboard &&
                        Store.getState().dashboard.viewModeUserId &&
                        Store.getState().dashboard.viewModeUserId.user_id;
                      if (Platform.OS === "web") {
                        global.open(
                          `https://maxiom.co.in/app/investment_plan_detail/${userId}`,
                          "_blank"
                        );
                      } else {
                        Linking.openURL(
                          `https://maxiom.co.in/app/investment_plan_detail/${userId}`
                        );
                      }
                    }}
                  >
                    Plan wise Billing Summary
                  </Text>

                  <TouchableOpacity
                    onPress={() => this.handleScheduleJob()}
                    style={{
                      paddingRight: 30,
                    }}
                  >
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Text
                        style={{
                          color: "#410DAA",
                          fontWeight: "bold",
                          fontSize: 15,
                          textDecorationLine: "underline",
                        }}
                      >
                        Redo Tagging for{" "}
                        {Store.getState().dashboard.viewModeUserId.user_id}
                      </Text>
                      <AntDesign
                        name="reload1"
                        size={15}
                        color="#410DAA"
                        style={{ marginLeft: 5 }}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              )
            ) : null}
          </View>
        </View>
        <ScrollView
          contentContainerStyle={styles.scrollView}
          showsVerticalScrollIndicator={false}
        >
          {getScreenConfigForCustomer().show_imports &&
            this.state.is_ftu &&
            activeTab == "Summary" && (
              <View
                style={{
                  marginLeft: 20,
                  marginTop: 30,
                  marginBottom: 20,
                  width: isMobile ? "90%" : "98%",
                }}
              >
                <GetRicherStrip
                  navigation={this.props.navigation}
                  from={"portfolio"}
                  title={
                    "No investments till date. Import your holdings for a FREE portfolio review. You can track them here in one place."
                  }
                  handleAggregator={this.handleAggregator}
                />
              </View>
            )}
          {this.state.redoTaggingLoader ? (
            <ShowToast
              showToast={true}
              type="status"
              title="Job is being scheduled. Please Wait..."
            />
          ) : null}
          {activeTab == "Summary" ? (
            <View style={styles.equityPlansContainer}>
              <View
                style={{
                  flex: 1,
                  flexDirection: isMobile ? "column" : "row",
                  marginTop: 20,
                }}
              >
                <View
                  style={{
                    width: isMobile ? "100%" : 360,
                    borderRadius: 10,
                    borderColor: "#d9cfee",
                    borderWidth: 1,
                    marginRight: 40,
                  }}
                >
                  <NetworthCard
                    data={investTotalUser}
                    value={
                      this.state.stockMFCheckbox && this.state.assetCheckbox
                        ? investValueFull
                        : this.state.stockMFCheckbox &&
                          !this.state.assetCheckbox
                          ? investValueWithStockMF
                          : !this.state.stockMFCheckbox &&
                            this.state.assetCheckbox
                            ? investValueWithAsset
                            : investValue
                    }
                    portfolio_as_of_date={this.state.portfolio_as_of_date}
                  />
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    paddingTop: isMobile ? 20 : 0,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      marginBottom: isMobile ? 30 : 26,
                    }}
                  >
                    <View
                      style={{
                        height: isMobile ? 83 : 100,
                        width: isMobile ? "47%" : 180,
                        borderRadius: 10,
                        borderColor: "#d9cfee",
                        borderWidth: 1,
                        marginRight: 20,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/useraccount/invest.png",
                          }}
                          style={{
                            width: 22,
                            height: 22,
                            resizeMode: "contain",
                          }}
                        />
                        <Text
                          style={{
                            paddingTop: 3,
                            fontSize: 14,
                            fontWeight: "400",
                            color: "#878EA8",
                            marginHorizontal: 16,
                            marginBottom: 10,
                          }}
                        >
                          Initial Invested
                        </Text>
                      </View>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: "700",
                          color: "#464E5F",
                          marginHorizontal: 16,
                        }}
                      >
                        {this.state.stockMFCheckbox && this.state.assetCheckbox
                          ? investedValueFull
                          : this.state.stockMFCheckbox &&
                            !this.state.assetCheckbox
                            ? investedValueWithStockMF
                            : !this.state.stockMFCheckbox &&
                              this.state.assetCheckbox
                              ? investedValueWithAsset
                              : investedValue}
                      </Text>
                    </View>
                    <View
                      style={{
                        height: isMobile ? 83 : 100,
                        width: isMobile ? "48%" : 180,
                        borderRadius: 10,
                        borderColor: "#d9cfee",
                        borderWidth: 1,
                        marginRight: 20,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/useraccount/return.png",
                          }}
                          style={{
                            width: 22,
                            height: 22,
                            resizeMode: "contain",
                          }}
                        />
                        <Text
                          style={{
                            paddingTop: 3,
                            fontSize: 14,
                            fontWeight: "400",
                            color: "#878EA8",
                            marginHorizontal: 16,
                            marginBottom: 10,
                          }}
                        >
                          Overall Gain
                        </Text>
                      </View>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: "700",
                          color: "#464E5F",
                          marginHorizontal: 16,
                        }}
                      >
                        {this.state.stockMFCheckbox && this.state.assetCheckbox
                          ? gainValueFull
                          : this.state.stockMFCheckbox &&
                            !this.state.assetCheckbox
                            ? gainValueWithStockMF
                            : !this.state.stockMFCheckbox &&
                              this.state.assetCheckbox
                              ? gainValueWithAsset
                              : gainValue}
                      </Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row", marginTop: -10 }}>
                    <View
                      style={{
                        height: isMobile ? 83 : 100,
                        width: isMobile ? "47%" : 180,
                        borderRadius: 10,
                        borderColor: "#d9cfee",
                        borderWidth: 1,
                        marginRight: 20,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/useraccount/gain.png",
                          }}
                          style={{
                            width: 22,
                            height: 22,
                            resizeMode: "contain",
                          }}
                        />
                        <Text
                          style={{
                            paddingTop: 3,
                            fontSize: 14,
                            fontWeight: "400",
                            color: "#878EA8",
                            marginHorizontal: 16,
                            marginBottom: 10,
                          }}
                        >
                          Abs. Gain %
                        </Text>
                      </View>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: "700",
                          color: "#464E5F",
                          marginHorizontal: 16,
                        }}
                      >
                        {gainPercentValueFull == "NaN" ||
                          gainPercentValueWithStockMF == "NaN" ||
                          gainPercentValueWithAsset == "NaN" ||
                          gainPercentValue == "NaN"
                          ? "0"
                          : this.state.stockMFCheckbox &&
                            this.state.assetCheckbox
                            ? gainPercentValueFull
                            : this.state.stockMFCheckbox &&
                              !this.state.assetCheckbox
                              ? gainPercentValueWithStockMF
                              : !this.state.stockMFCheckbox &&
                                this.state.assetCheckbox
                                ? gainPercentValueWithAsset
                                : gainPercentValue}
                        %
                      </Text>
                    </View>
                    <View
                      style={{
                        height: isMobile ? 83 : 100,
                        width: isMobile ? "48%" : 180,
                        borderRadius: 10,
                        borderColor: "#d9cfee",
                        borderWidth: 1,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          marginLeft: "10%",
                          marginTop: "7%",
                        }}
                      >
                        <Image
                          source={{
                            uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/mobile/useraccount/duration.png",
                          }}
                          style={{
                            width: 22,
                            height: 22,
                            resizeMode: "contain",
                          }}
                        />
                        <Text
                          style={{
                            paddingTop: 3,
                            fontSize: 14,
                            fontWeight: "400",
                            color: "#878EA8",
                            marginHorizontal: 16,
                            marginBottom: 10,
                          }}
                        >
                          Avg. Duration
                        </Text>
                      </View>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: "700",
                          color: "#464E5F",
                          marginHorizontal: 16,
                        }}
                      >
                        {this.state.stockMFCheckbox && this.state.assetCheckbox
                          ? "N.A."
                          : this.state.stockMFCheckbox &&
                            !this.state.assetCheckbox
                            ? "N.A."
                            : !this.state.stockMFCheckbox &&
                              this.state.assetCheckbox
                              ? "N.A."
                              : avg_holding_period == "NaN years"
                                ? "0 years"
                                : avg_holding_period}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* {!this.state.is_preferred_circle && (
                  <View
                    style={{
                      flexDirection: "row",
                      paddingTop: isMobile ? 20 : 0,
                    }}
                  >
                    <View
                      style={[
                        stripStyles.containerStrip,
                        stripStyles.subContainer,
                      ]}
                    >
                      <>
                        <View style={stripStyles.titleContainer}>
                          <Text style={stripStyles.title}>
                            Portfolio Actions
                          </Text>
                        </View>
                        <TouchableOpacity
                          onPress={() => {
                            this.props.navigation.navigate("Portfolio", {
                              screen: "RebalanceLandingPage",
                            });
                          }}
                        >
                          {strips.rebalancing != null &&
                            strips.rebalancing.summary != null &&
                            strips.rebalancing.summary.map((point) => {
                              return (
                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <View style={stripStyles.pointsContainer}>
                                    <View style={stripStyles.point}>
                                      <BulletPoint
                                        outerColor="#888C9F60"
                                        innerColor="#888C9F"
                                        style={[stripStyles.bulletPoint]}
                                      />
                                      <Text style={stripStyles.pointText}>
                                        {point}
                                      </Text>
                                    </View>
                                  </View>
                                  <Image
                                    source={ArrowIcon}
                                    style={[
                                      {
                                        width: !isMobile ? 18 : 16,
                                        height: !isMobile ? 18 : 16,
                                      },
                                    ]}
                                  ></Image>
                                </View>
                              );
                            })}
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={{
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                          onPress={() => {
                            this.props.navigation.navigate("ViewMode", {
                              screen: "ContractNote",
                            });
                          }}
                        >
                          {strips.rebalancing != null &&
                            strips.rebalancing.contract_note_due != null &&
                            strips.rebalancing.contract_note_due.ccn_missing !=
                            null &&
                            strips.rebalancing.contract_note_due.ccn_missing >
                            0 ? (
                            <View
                              style={[
                                stripStyles.pointsContainer,
                                {
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                },
                              ]}
                            >
                              <View style={stripStyles.point}>
                                <BulletPoint
                                  outerColor="#888C9F60"
                                  innerColor="#888C9F"
                                  style={[stripStyles.bulletPoint]}
                                />
                                <Text style={stripStyles.pointText}>
                                  Contract note due:{" "}
                                  {
                                    strips.rebalancing.contract_note_due
                                      .ccn_missing
                                  }
                                </Text>
                              </View>
                              <Image
                                source={ArrowIcon}
                                style={[
                                  {
                                    width: !isMobile ? 18 : 16,
                                    height: !isMobile ? 18 : 16,
                                  },
                                ]}
                              ></Image>
                            </View>
                          ) : null}
                          {Store.getState().dashboard &&
                            Store.getState().dashboard.viewModeUserId &&
                            Store.getState().dashboard.viewModeUserId.user_id ? (
                            strips.rebalancing != null &&
                              strips.rebalancing.contract_note_due != null &&
                              strips.rebalancing.contract_note_due.ccn_fail !=
                              null &&
                              strips.rebalancing.contract_note_due.ccn_fail >
                              0 ? (
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  marginTop: 8,
                                }}
                              >
                                <View style={[stripStyles.point]}>
                                  <BulletPoint
                                    outerColor="#888C9F60"
                                    innerColor="#888C9F"
                                    style={[stripStyles.bulletPoint]}
                                  />
                                  <Text style={stripStyles.pointText}>
                                    Contract note failed:{" "}
                                    {
                                      strips.rebalancing.contract_note_due
                                        .ccn_fail
                                    }
                                  </Text>
                                </View>
                                <Image
                                  source={ArrowIcon}
                                  style={[
                                    {
                                      width: !isMobile ? 18 : 16,
                                      height: !isMobile ? 18 : 16,
                                    },
                                  ]}
                                ></Image>
                              </View>
                            ) : null
                          ) : null}
                        </TouchableOpacity>
                      </>
                    </View>
                  </View>
                )} */}

                {this.state.is_preferred_circle &&
                  strips.rebalancing.ssi_show_sell && (
                    <View
                      style={{
                        flexDirection: "row",
                        paddingTop: isMobile ? 20 : 0,
                      }}
                    >
                      <View
                        style={[
                          stripStyles.containerStrip,
                          stripStyles.subContainer,
                        ]}
                      >
                        <View style={stripStyles.titleContainer}>
                          <Text style={stripStyles.title}>
                            Portfolio Actions
                          </Text>
                        </View>
                        <TouchableOpacity
                          onPress={() => {
                            this.props.navigation.navigate("App", {
                              screen: "ViewMode",
                              params: {
                                screen: "SellExitStocks",
                                params: {},
                              },
                            });
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <View style={stripStyles.pointsContainer}>
                              <View style={stripStyles.point}>
                                <BulletPoint
                                  outerColor="#888C9F60"
                                  innerColor="#888C9F"
                                  style={[stripStyles.bulletPoint]}
                                />
                                <Text style={stripStyles.pointText}>
                                  Stocks avaliable to sell
                                </Text>
                              </View>
                            </View>
                            <Image
                              source={ArrowIcon}
                              style={[
                                {
                                  width: !isMobile ? 18 : 16,
                                  height: !isMobile ? 18 : 16,
                                },
                              ]}
                            ></Image>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
              </View>
              <View
                style={{ marginLeft: 7, display: "flex", flexDirection: "row" }}
              >
                {/* {console.log("Check value is ", this.state.stockMFCheckbox)} */}
                <Checkbox
                  status={this.state.stockMFCheckbox ? "checked" : "unchecked"}
                  color={"#C1C1C1"}
                  uncheckedColor={"#C1C1C1"}
                  value={this.state.stockMFCheckbox}
                  style={{
                    marginTop: 10,
                    marginRight: 10
                  }}
                  onValueChange={() => {
                    if (this.state.stockMFCheckbox) {
                      this.setState({
                        stockMFCheckbox: false,
                      });
                    } else {
                      this.setState({
                        stockMFCheckbox: true,
                      });
                    }
                    setTimeout(() => {
                      if (this.state.local_id) {
                        this.filterDataToShow(
                          this.state.local_id,
                          this.state.summary,
                          this.state.data_values
                        );
                      }
                    }, 1000);
                  }}
                />
                <Text
                  style={{
                    paddingTop: 5,
                    fontSize: 14,
                    marginTop: 5,
                    fontWeight: "400",
                    color: "#878EA8",
                    textAlign: isMobile ? "right" : "left",
                  }}
                >
                  outside stocks & funds
                </Text>
                <Checkbox
                  status={this.state.assetCheckbox ? "checked" : "unchecked"}
                  color={"#C1C1C1"}
                  uncheckedColor={"#C1C1C1"}
                  value={this.state.assetCheckbox}
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                    marginLeft: 10
                  }}
                  onValueChange={() => {
                    this.setState({
                      assetCheckbox: !this.state.assetCheckbox,
                    });
                    setTimeout(() => {
                      if (this.state.local_id) {
                        this.filterDataToShow(
                          this.state.local_id,
                          this.state.summary,
                          this.state.data_values
                        );
                      }
                    }, 1000);
                  }}
                />
                <Text
                  style={{
                    paddingTop: 5,
                    fontSize: 14,
                    fontWeight: "400",
                    marginTop: 5,
                    color: "#878EA8",
                    textAlign: isMobile ? "right" : "left",
                  }}
                >
                  other assets
                </Text>
              </View>
            </View>
          ) : null}

          {activeTab == "Summary" ? (
            <View style={{ marginLeft: 20, marginTop: isMobile ? 10 : 30 }}>
              <SideHeading
                title="Direct Equity Investments Overview"
                style={[styles.yourPlans]}
              />
              <SummaryCard
                stocks_summary={stocks_summary}
                onValueChange={this.onToggleSwitch}
                navigation={this.props.navigation}
                userId={userId}
                activeTab={this.state.selectedStockTab}
                is_elite={this.state.is_elite}
                reportLoading={this.state.reportLoading}
                viewReport={this.viewReport}
                mainTab={activeTab}
                showMontlyReportAndViewPlans={values.length > 0 ? true : false}
              />
            </View>
          ) : null}

          {activeTab == "Summary" ? (
            <View style={{ marginLeft: 20, marginTop: isMobile ? 10 : 30 }}>
              <SideHeading
                title="Mutual Fund Investments Overview"
                style={[styles.yourPlans]}
              />
              <MutualFundPlans
                mfData={selectedMFTab == "Jama" ? mfInJamaData : mfOutsideData}
                mainTab={activeTab}
                selectedMFTab={this.state.selectedMFTab}
                viewMFReport={this.viewMFReport}
                mfReportLoading={this.state.mfReportLoading}
                showMonthlyReport={
                  this.state.mutualFundInJamaDetails.length > 0 ||
                    this.state.mutualFundOutsideDetails.length > 0
                    ? true
                    : false
                }
              />
            </View>
          ) : null}

          {getScreenConfigForCustomer().show_imports &&
            !this.state.is_ftu &&
            activeTab == "Summary" && (
              <View
                style={{
                  marginLeft: 20,
                  marginTop: isMobile ? 10 : -30,
                  marginBottom: isMobile ? 96 : 40,
                  width: isMobile ? "90%" : "98%",
                }}
              >
                <SideHeading title="Get FREE Portfolio Review" />
                <GetRicherStrip
                  navigation={this.props.navigation}
                  from={"portfolio"}
                  handleAggregator={this.handleAggregator}
                />
              </View>
            )}

          {this.state.activeTab == "Stocks" && (
            <View style={{ marginTop: isMobile ? 10 : -30 }}>
              <StockTabs
                tabs={this.state.stockTabsList}
                onSelectTab={(data) => this.onSelectStockTab(data)}
              />
            </View>
          )}
          {/* {console.log(
            "Sleep %s and %s",
            this.state.activeTab,
            this.state.selectedStockTab
          )} */}
          {this.state.activeTab == "Stocks" &&
            (this.state.selectedStockTab == "Inside Stocks" ? (
              <View style={{ marginLeft: 20, marginTop: isMobile ? 10 : 5 }}>
                {/* {console.log("summary is ", stocks_summary)} */}
                <SummaryCard
                  stocks_summary={stocks_summary}
                  // piechart_data={piechart_data}
                  navigation={this.props.navigation}
                  userId={this.state.userId}
                  onValueChange={this.onToggleSwitch}
                  activeTab={this.state.selectedStockTab}
                  is_elite={this.state.is_elite}
                  reportLoading={this.state.reportLoading}
                  viewReport={this.viewReport}
                  mainTab={activeTab}
                  showMontlyReportAndViewPlans={
                    values.length > 0 ? true : false
                  }
                />
              </View>
            ) : null)}

          {this.state.activeTab == "Stocks" &&
            (this.state.selectedStockTab == "JEWEL" ? (
              // <View style={styles.summaryContainer}>
              <View style={{ marginLeft: 20, marginTop: isMobile ? 10 : 5 }}>
                {/* <View
                  style={{ width: isMobile ? "100%" : "50%" }}
                  onLayout={(event) => {
                    var { height } = event.nativeEvent.layout;
                    this.setState({
                      isTopupLoading: false,
                      topupHeight: height,
                    });
                  }}
                > */}
                <SummaryCard
                  stocks_summary={stocks_summary_prodcut_wise.JWL}
                  // piechart_data={piechart_data}
                  navigation={this.props.navigation}
                  userId={this.state.userId}
                  onValueChange={this.onToggleSwitch}
                  activeTab={this.state.selectedStockTab}
                  is_elite={this.state.is_elite}
                  reportLoading={this.state.reportLoading}
                  viewReport={this.viewReport}
                  mainTab={activeTab}
                  showMontlyReportAndViewPlans={
                    values.length > 0 ? true : false
                  }
                />
                {/* <PlanIndiCard
                    stocks_summary={equity_investment_plans}
                    // piechart_data={piechart_data}
                    navigation={this.props.navigation}
                    userId={this.state.userId}
                    onValueChange={this.onToggleSwitch}
                    activeTab={this.state.selectedStockTab}
                  /> */}
                {/* </View> */}
              </View>
            ) : null)}

          {this.state.activeTab == "Stocks" &&
            (this.state.selectedStockTab == "SPARK" ? (
              // <View style={styles.summaryContainer}>
              <View style={{ marginLeft: 20, marginTop: isMobile ? 10 : 5 }}>
                {/* <View
                  style={{ width: isMobile ? "100%" : "50%" }}
                  onLayout={(event) => {
                    var { height } = event.nativeEvent.layout;
                    this.setState({
                      isTopupLoading: false,
                      topupHeight: height,
                    });
                  }}
                > */}
                <SummaryCard
                  stocks_summary={stocks_summary_prodcut_wise.SRK}
                  // piechart_data={piechart_data}
                  navigation={this.props.navigation}
                  userId={this.state.userId}
                  onValueChange={this.onToggleSwitch}
                  activeTab={this.state.selectedStockTab}
                  is_elite={this.state.is_elite}
                  reportLoading={this.state.reportLoading}
                  viewReport={this.viewReport}
                  mainTab={activeTab}
                  showMontlyReportAndViewPlans={
                    values.length > 0 ? true : false
                  }
                />
                {/* <PlanIndiCard
                    stocks_summary={equity_investment_plans}
                    // piechart_data={piechart_data}
                    navigation={this.props.navigation}
                    userId={this.state.userId}
                    onValueChange={this.onToggleSwitch}
                    activeTab={this.state.selectedStockTab}
                  /> */}
                {/* </View> */}
              </View>
            ) : null)}
          {this.state.activeTab == "Stocks" &&
            (this.state.selectedStockTab == "Outside Stocks" ? (
              <View style={styles.summaryContainer}>
                <View
                  style={{
                    borderWidth: 0.5,
                    borderColor: "#cef1e6",
                    borderRadius: 20,
                    width: width < 1200 ? "100%" : 311,
                    marginTop: 20,
                  }}
                >
                  <View style={{ flexDirection: isMobile ? "column" : "row" }}>
                    <View
                      style={{
                        color: "#494B69",
                        fontSize: 16,
                        backgroundColor: "#f3fbf9",
                        borderRadius: 20,
                        height: 190,
                        alignSelf: "flex-start",
                        width: isMobile ? "100%" : 310,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingHorizontal: 20,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              paddingTop: 25,
                              fontSize: 14,
                              fontWeight: "400",
                              color: "#878EA8",
                            }}
                          >
                            Latest Value
                          </Text>
                          <Text
                            style={{
                              paddingTop: 5,
                              fontSize: 28,
                              fontWeight: "700",
                              color: "#464E5F",
                            }}
                          >
                            ₹{" "}
                            {this.numberFormat(
                              this.state.outsideLatestAmount.toFixed(0)
                            )}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{
                          height: 0.5,
                          marginTop: 20,
                          backgroundColor: "#92929250",
                          paddingHorizontal: 20,
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                      />
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingHorizontal: 20,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              paddingTop: 25,
                              fontSize: 14,
                              fontWeight: "400",
                              color: "#878EA8",
                            }}
                          >
                            Initial Invested
                          </Text>
                          <Text
                            style={{
                              paddingTop: 5,
                              fontSize: 14,
                              fontWeight: "700",
                              color: "#464E5F",
                            }}
                          >
                            ₹{" "}
                            {this.numberFormat(
                              this.state.outsideInvestedAmount.toFixed(0)
                            )}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            ) : null)}
          {showTeaser && this.state.activeTab == "Stocks" && (
            <SparkTeaser
              style={{ width: "100%", height: "auto", marginTop: 32 }}
              onClose={() => {
                this.setState({ showTeaser: false });
              }}
              product={show_product_strip}
            />
          )}

          {this.state.activeTab == "Stocks" && (
            <View>
              {this.state.reportLoading ? (
                <ShowToast
                  showToast={true}
                  type="status"
                  title="Generating Latest Report, Please Wait..."
                />
              ) : null}
              {this.state.reportLoaded ? (
                <ShowToast
                  showToast={true}
                  type="status"
                  title="Report Downloaded Successfully."
                />
              ) : null}
              {this.state.jobScheduled ? (
                <ShowToast
                  showToast={true}
                  type="status"
                  title="Job scheduled successfully."
                />
              ) : null}
              {loadingData ? (
                <View style={styles.loadingConainer}>
                  <ActivityIndicator size="large" color="#410DAA" />
                </View>
              ) : (
                <View>
                  <View style={{ width: "100%" }}>
                    <Text
                      style={{
                        color: "#464E5F",
                        fontSize: 15,
                        fontFamily: "Roboto",
                        marginLeft: 20,
                        marginTop: 34,
                      }}
                    >
                      {this.state.selectedStockTab == "Outside Stocks"
                        ? "Outside"
                        : this.state.selectedStockTab == "Inside Stocks"
                          ? "Inside"
                          : this.state.selectedStockTab}{" "}
                      Stock Holdings
                    </Text>
                    {this.state.selectedStockTab == "Inside Stocks" &&
                      this.state.is_preferred_circle &&
                      values.length == 0 && ( //shows only if no inside stocks exist
                        <>
                          <Text style={{ marginLeft: 24, marginTop: 20 }}>
                            There are no inside stocks. Get your first stock
                            advice now.
                          </Text>
                          <Button
                            uppercase={false}
                            mode={"contained"}
                            onPress={() => {
                              this.props.navigation.navigate("App", {
                                screen: "ViewMode",
                                params: {
                                  screen: "StockRecommendations",
                                  params: {},
                                },
                              });
                            }}
                            style={{
                              maxWidth: 250,
                              alignSelf: "flex-start",
                              marginLeft: 24,
                              marginTop: 10,
                            }}
                            color={"#410DAA"}
                          >
                            <Text style={styles.next}>Get Stock Advice</Text>
                          </Button>
                        </>
                      )}

                    {this.state.selectedStockTab == "Outside Stocks" && (
                      <>
                        {/* {console.log("Selected Stock Tab:", this.state.selectedStockTab)} */}


                        <Text style={{ marginLeft: 24, marginTop: 20 }}>
                          {!(values.length > 0)
                            ? "There are no inside stocks."
                            : ""}{" "}
                          Import your outside stocks and track them here for
                          free.
                        </Text>
                        <Button
                          uppercase={false}
                          mode={"contained"}
                          onPress={this.handleImportStocks}
                          style={{
                            maxWidth: 250,
                            alignSelf: "flex-start",
                            marginLeft: 24,
                            marginTop: 10,
                          }}
                          color={"#410DAA"}
                        >
                          <Text style={styles.next}>Import Outside Stocks</Text>
                        </Button>
                      </>
                    )}
                  </View>
                  {/* {values.length == 0 && (
                    <View style={styles.loadingConainer}>
                      <ActivityIndicator size="small" color="#410DAA" />
                    </View>
                  )} */}

                  {values.length > 0 && (
                    <ScrollView showsVerticalScrollIndicator={false}>
                      <SelectProduct
                        reportLoading={this.state.reportLoading}
                        viewReport={this.viewReport}
                        excelText={excelTextValue}
                        excelData={excelDataValue}
                        onChange={this.handleProductChange}
                        value={this.state.selectedStockTab}
                        isSwitchOn={this.state.isSwitchOn}
                        onValueChange={this.onToggleSwitch}
                        navigation={this.props.navigation}
                        showStocksOf={this.state.selectedStockTab}
                        props={this.props}
                        is_ria={this.state.is_ria}
                      />
                      <View style={styles.headingContainer}>
                        {this.state.selectedStockTab == "Inside Stocks" && this.state.selectedStockTab == "Inside Stocks" && (
                          <View
                            style={{
                              width: !isMobile ? "97%" : "45%",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <Text
                              style={{
                                color: "#410DAA",
                                cursor: "pointer",
                                fontSize: 15,
                                textDecorationLine: "underline",
                              }}
                              onPress={this.handleShowZeroHolding}
                            >
                              {!this.state.show_zero_holding
                                ? "Show with zero balances"
                                : "Show without zero balances"}
                            </Text>
                          </View>
                        )}
                      </View>
                      <ScrollView
                        horizontal={true}
                        style={styles.mainContainer}
                        showsVerticalScrollIndicator={false}
                      >
                        <View style={styles.detailsContainer}>
                          <View style={{ flexDirection: "row", justifyContent: "flex-end", padding: 10 }}>
                            <Text
                              onPress={() => this.handleDownloadStocks(excelDataValue, excelTextValue)}
                              style={{
                                color: "#410DAA",
                                fontWeight: "bold",
                                cursor: "pointer",
                                fontSize: 15,
                                textDecoration: "underline",
                              }}
                            >
                              Download As Excel
                            </Text>
                          </View>
                          <StockTable
                            header={headerData}
                            gridConfig={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
                            data={values}
                            pagination={values.length > 10 ? true : false}
                            showFilter={
                              this.state.selectedStockTab !== "Outside Stocks"
                            }
                            filterlabel={["Product     "]}
                            filterKeys={[0]}
                          />
                        </View>
                      </ScrollView>
                    </ScrollView>
                  )}
                </View>
              )}
            </View>
          )}

          {shouldShowMF &&
            activeTab == "Mutual Funds"
            ? (
              <View style={{ marginTop: isMobile ? 10 : -30 }}>
                <MFTabs
                  tabs={this.state.MFTabsList}
                  onSelectTab={(data) => this.onSelectMFTab(data)}
                />
              </View>
            ) : null}

          {shouldShowMF &&
            activeTab == "Mutual Funds" &&
            (mfInJamaData || mfOutsideData) ? (
            <View
              style={{
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              {(selectedMFTab == "Jama" && mfInJamaData) ||
                (selectedMFTab != "Jama" &&
                  selectedMFTab == "Outside" &&
                  mfOutsideData) ||
                (selectedMFTab != "Jama" &&
                  selectedMFTab == "Regular" &&
                  mfRegularData) ? (
                <MutualFundPlans
                  mfData={
                    selectedMFTab == "Jama"
                      ? mfInJamaData
                      : selectedMFTab == "Regular"
                        ? mfRegularData
                        : mfOutsideData
                  }
                  navigation={this.props.navigation}
                  mainTab={activeTab}
                  selectedMFTab={this.state.selectedMFTab}
                  viewMFReport={this.viewMFReport}
                  mfReportLoading={this.state.mfReportLoading}
                  showMonthlyReport={
                    this.state.mutualFundInJamaDetails.length > 0 ||
                      this.state.mutualFundOutsideDetails.length > 0
                      ? true
                      : false
                  }
                />
              ) : null}
            </View>
          ) : (
            <View style={{ marginBottom: 20 }}></View>
          )}

          {
            activeTab == "PMS" &&
            // <View style={{flex: 0.8,justifyContent: 'center', alignItems: 'center'}}>
            (this.state.is_pms_client ? (
              <View style={{ paddingLeft: 20 }}>
                {this.state.pms_data.total_latest_value > 0 ? (
                  <ProductSummary data={this.state.pms_data} />
                ) : null}
                <View></View>
                <View>
                  <Text style={{ fontSize: 18, color: "#666666" }}>
                    For more details, please visit the custodian PMS portal
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      window.open(
                        "https://onlinefa.icicibank.com/wealthspectrum/app/login",
                        "_self"
                      );
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#410DAA",
                        textDecorationLine: "underline",
                        marginTop: 5,
                      }}
                    >
                      https://onlinefa.icicibank.com/wealthspectrum/app/login
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            ) : (
              <View style={{ padding: isMobile ? 5 : 20, width: "100%" }}>
                <Text
                  style={{
                    color: "#666666",
                    fontWeight: "700",
                    marginLeft: isMobile ? "2%" : "25%",
                    fontSize: 22,
                    marginBottom: isMobile ? 20 : 10,
                  }}
                >
                  Opening a PMS has many benefits:
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    width: "100%",
                    marginBottom: isMobile ? 20 : 0,
                  }}
                >
                  <View style={{ padding: isMobile ? 5 : 50 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <FontAwesome5
                        name="user-cog"
                        size={18}
                        color="#666666"
                      />
                      <Text
                        style={{
                          color: "#666666",
                          marginLeft: 10,
                          fontWeight: "700",
                          fontSize: 16,
                          marginBottom: 5,
                        }}
                      >
                        Tailored strategies for individual goals.
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 10,
                      }}
                    >
                      <FontAwesome5 name="tasks" size={18} color="#666666" />
                      <Text
                        style={{
                          color: "#666666",
                          marginLeft: 10,
                          fontWeight: "700",
                          fontSize: 16,
                          marginBottom: 5,
                        }}
                      >
                        Managed service for hassle-free tracking.
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 10,
                      }}
                    >
                      <FontAwesome5 name="cogs" size={18} color="#666666" />
                      <Text
                        style={{
                          color: "#666666",
                          marginLeft: 10,
                          fontWeight: "700",
                          fontSize: 16,
                        }}
                      >
                        Streamlined administrative and operational support.
                      </Text>
                    </View>
                  </View>
                  <View style={{ padding: isMobile ? 5 : 50 }}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <FontAwesome5
                        name="chart-line"
                        size={18}
                        color="#666666"
                      />
                      <Text
                        style={{
                          color: "#666666",
                          marginLeft: 10,
                          fontWeight: "700",
                          fontSize: 16,
                          marginBottom: 5,
                        }}
                      >
                        Continuous portfolio monitoring and adjustment.
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 10,
                      }}
                    >
                      <FontAwesome5 name="gem" size={18} color="#666666" />
                      <Text
                        style={{
                          color: "#666666",
                          marginLeft: 10,
                          fontWeight: "700",
                          fontSize: 16,
                          marginBottom: 5,
                        }}
                      >
                        Access to exclusive investment opportunities (eg: GEM
                        portfolio)
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 10,
                      }}
                    >
                      <FontAwesome5
                        name="university"
                        size={18}
                        color="#666666"
                      />
                      <Text
                        style={{
                          color: "#666666",
                          marginLeft: 10,
                          fontWeight: "700",
                          fontSize: 16,
                          marginBottom: 5,
                        }}
                      >
                        Reputed bank ensures holdings' safety.
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: isMobile ? "100%" : "75%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("App", {
                        screen: "Dashboard",
                        params: {
                          screen: "Dashboard",
                          params: {
                            screen: "ScheduleCall",
                            params: { topic: "PMS" },
                          },
                        },
                      });
                    }}
                    style={{
                      backgroundColor: "#410DAA",
                      paddingHorizontal: 50,
                      paddingVertical: 15,
                      borderRadius: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to know more
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            ))
            // </View>
          }

          {activeTab == "Mutual Funds" && (
            <View style={{ marginTop: -39.5 }}>
              {this.state.mfReportLoading ? (
                <ShowToast
                  showToast={true}
                  type="status"
                  title="Generating Latest Report, Please Wait..."
                />
              ) : null}
              {this.state.reportLoaded ? (
                <ShowToast
                  showToast={true}
                  type="status"
                  title="Report Downloaded Successfully."
                />
              ) : null}
              {/* {this.state.mutualFundInJamaDetails.length == 0 && (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 100,
                  }}
                >
                  <ActivityIndicator size={16} color="#410DAA" />
                </View>
              )} */}
              {(this.state.mutualFundInJamaDetails.length >= 0 ||
                this.state.mutualFundOutsideDetails.length >= 0 ||
                this.state.mutualFundRegularDetails.length >= 0) && (
                  <ScrollView showsVerticalScrollIndicator={false}>
                    <MFHoldingDetails
                      excelText={
                        this.state.userName +
                        " " +
                        this.state.selectedMFTab +
                        " Mutual Fund Holdings " +
                        moment().format("DD-MM-YYYY hh-mm-ss A")
                      }
                      excelData={
                        this.state.selectedMFTab == "Jama"
                          ? this.state.excelMFInJamaDataValue
                          : this.state.selectedMFTab == "Regular"
                            ? this.state.excelMFRegularDetails
                            : this.state.excelMFOutsideDataValue
                      }
                      selectedMFTab={this.state.selectedMFTab}
                      viewMFReport={this.viewMFReport}
                      mfReportLoading={this.state.mfReportLoading}
                    />

                    {this.state.selectedMFTab != "Jama" &&
                      this.state.selectedMFTab != "Regular" && (
                        <Button
                          uppercase={false}
                          mode={"contained"}
                          onPress={this.handleImportMF}
                          style={{
                            width: 150,
                            alignSelf: "flex-start",
                            marginLeft: 20,
                            marginBottom: 20,
                          }}
                          color={"#410DAA"}
                        >
                          <Text style={{ fontSize: 13, color: "#fff" }}>
                            Import
                          </Text>
                        </Button>
                      )}
                    <ScrollView
                      horizontal={true}
                      style={styles.mainContainer}
                      showsVerticalScrollIndicator={true}
                    >
                      <View style={styles.detailsContainer}>
                        <View style={styles.headingContainer}>
                          <SearchBar
                            value={searchMFText}
                            onChange={this.handleMFSearch}
                          />
                          <View
                            style={{
                              cursor: "pointer",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                              marginRight: 20,
                            }}
                          >
                            <Text
                              onClick={this.handleDownloadMF}
                              style={{
                                color: "#410DAA",
                                fontWeight: "bold",
                                cursor: "pointer",
                                fontSize: 15,
                                textDecoration: "underline",
                              }}
                            >
                              Download As Excel
                            </Text>
                            {/* <ExcelFile
                              filename={
                                this.state.userName +
                                " " +
                                this.state.selectedMFTab +
                                " Mutual Fund Holdings " +
                                moment().format("DD-MM-YYYY hh-mm-ss A")
                              }
                              element={
                                <Text
                                  style={{
                                    color: "#410DAA",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    fontSize: 15,
                                    textDecorationLine: "underline",
                                  }}
                                >
                                  Download As Excel
                                </Text>
                              }
                            >
                              <ExcelSheet
                                data={
                                  this.state.selectedMFTab == "Jama"
                                    ? this.state.excelMFInJamaDataValue
                                    : (this.state.selectedMFTab == "Regular" ? this.state.excelMFRegularDetails : this.state.excelMFOutsideDataValue)
                                }
                                name="Stock Holdings"
                              >
                                <ExcelColumn label="Name" value="stock_name" />
                                <ExcelColumn
                                  label="Current Value"
                                  value="units"
                                />
                                <ExcelColumn label="Invested" value="avg_price" />
                                <ExcelColumn
                                  label="Unit Balance"
                                  value="closing_price"
                                />
                                <ExcelColumn
                                  label="Avg. Duration"
                                  value="invested"
                                />
                                <ExcelColumn label="Gain" value="latest_value" />
                                <ExcelColumn label="XIRR %" value="gain" />
                                <ExcelColumn
                                  label="Abs Gain %"
                                  value="gain_percent"
                                />
                              </ExcelSheet>
                            </ExcelFile> */}
                          </View>
                        </View>
                        <MFTable
                          header={headerMFData}
                          gridConfig={
                            selectedMFTab == "Jama"
                              ? [1, 1, 1, 1, 1, 1, 1, 1, 1]
                              : [1, 1, 1, 1, 1, 1, 1, 1]
                          }
                          data={valuesMF}
                          navigation={this.props.navigation}
                          isMutualFundTable={true}
                          pagination={valuesMF.length > 10 ? true : false}
                        />
                      </View>
                    </ScrollView>
                  </ScrollView>
                )}
            </View>
          )}

          {AllAssetsData &&
            activeTab == "Other Assets" &&
            AllAssetsData.value_new ? (
            <View
              style={[
                styles.equityPlansContainer,
                {
                  marginTop: isMobile ? -20 : -20,
                  marginBottom: isMobile ? 25 : -30,
                },
              ]}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "64%",
                }}
              >
                <SideHeading title="Other Assets" style={[styles.yourPlans]} />
                {/* <TouchableOpacity
                  onPress={() => {
                    this.props.navigation.navigate("AddNewAsset", {
                      selectedAssetSubTab: this.state.selectedAssetSubTab,
                    });
                  }}
                >
                  <Text
                    style={{
                      color: "#410DAA",
                      fontWeight: "bold",
                      fontSize: 15,
                      textDecorationLine: "underline",
                    }}
                  >
                    Add New Asset
                  </Text>
                </TouchableOpacity> */}
              </View>
              {activeTab == "Other Assets" && AllAssetsData ? (
                <View>
                  <AllAssetsCard allAssets={AllAssetsData} />
                </View>
              ) : null}
            </View>
          ) : null}
          {activeTab == "Other Assets" && (
            <>
              <TouchableOpacity
                style={{
                  alignItems: "flex-end",
                  padding: 12,
                }}
                onPress={() => {
                  this.props.navigation.navigate("AddNewAsset", {
                    selectedAssetSubTab: this.state.selectedAssetSubTab,
                  });
                }}
              >
                <Text
                  style={{
                    color: "#410DAA",
                    fontWeight: "bold",
                    fontSize: 15,
                    textDecorationLine: "underline",
                  }}
                >
                  Add New Asset
                </Text>
              </TouchableOpacity>
              <AssetTabs
                tabs={this.assetTabsList}
                selectedTab={this.state.selectedAssetSubTab}
                onSelectTab={(data) => {
                  this.onSelectAssetTab(data);
                  this.setState({ selectedAssetSubTab: data });
                }}
              />
            </>
          )}

          {activeTab == "Other Assets" ? (
            filteredAsset.length > 0 ? (
              <View style={{ marginTop: 20 }}>
                <ScrollView
                  horizontal={true}
                  style={styles.mainContainer}
                  showsVerticalScrollIndicator={false}
                >
                  <View style={styles.detailsContainer}>
                    <View style={styles.headingContainer}>
                      <SearchBar
                        value={searchAssetText}
                        onChange={this.handleAssetSearch}
                      />
                      <View
                        style={{
                          cursor: "pointer",
                          justifyContent: "flex-end",
                          flexDirection: "row",
                          marginRight: 20,
                        }}
                      >
                        <Text
                          onPress={() => this.handleDownloadAsset(excelAssetData)}
                          style={{
                            color: "#410DAA",
                            fontWeight: "bold",
                            cursor: "pointer",
                            fontSize: 15,
                            textDecoration: "underline",
                          }}
                        >
                          Download As Excel
                        </Text>
                        {/* <ExcelFile
                          filename={
                            this.state.userName +
                            " " +
                            this.assetTabsList[this.state.selectedAssetSubTab]
                              .label +
                            " (Asset) " +
                            moment().format("DD-MM-YYYY hh-mm-ss A")
                          }
                          element={
                            <Text
                              style={{
                                color: "#410DAA",
                                fontWeight: "bold",
                                cursor: "pointer",
                                fontSize: 15,
                                textDecorationLine: "underline",
                              }}
                            >
                              Download As Excel
                            </Text>
                          }
                        >
                          <ExcelSheet data={excelAssetData} name="Other Assets">
                            <ExcelColumn label="Name" value="stock_name" />
                            <ExcelColumn
                              label="Asset Type"
                              value="asset_type"
                            />
                            <ExcelColumn
                              label="Date of Purchase"
                              value="units"
                            />
                            <ExcelColumn
                              label="Amount at Cost"
                              value="closing_price"
                            />
                            <ExcelColumn
                              label="Latest Value"
                              value="invested"
                            />
                          </ExcelSheet>
                        </ExcelFile> */}
                      </View>
                    </View>
                    <AssetTable
                      header={headerAssetData}
                      selectedAssetSubTab={this.state.selectedAssetSubTab}
                      gridConfig={[1, 1, 1, 1, 1, 1]}
                      data={valuesAsset}
                      navigation={this.props.navigation}
                      pagination={valuesAsset.length > 10 ? true : false}
                    />
                  </View>
                </ScrollView>
              </View>
            ) : (
              <View style={{ flex: 1, paddingBottom: 50 }}>
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 15,
                    fontWeight: "500",
                    marginTop: 70,
                    paddingTop: isMobile ? 20 : 0,
                    paddingBottom: isMobile ? 50 : 0,
                  }}
                >
                  No{" "}
                  {selectedAssetTab == "Other"
                    ? "Miscellaneous"
                    : selectedAssetTab}{" "}
                  Asset Available
                </Text>
              </View>
            )
          ) : null}
        </ScrollView>
      </View>
      // </View>
    );
  }
}

const styles = StyleSheet.create({
  next: {
    color: "#fff",
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    paddingTop: 30,
    paddingBottom: isMobile ? 100 : 0,
  },
  memberIconContainer: {
    width: 100,
    height: 37,
    marginHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  errorText: { fontSize: 16, color: "#121212" },
  scrollView: {
    flexGrow: 1,
  },
  portfolioHeading: {
    marginBottom: 20,
    marginLeft: 20,
  },
  summaryContainer: {
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    marginBottom: 0,
    marginHorizontal: 20,
  },
  equityPlansContainer: {
    marginHorizontal: 20,
  },
  yourPlans: {
    marginBottom: 20,
    marginTop: isMobile ? 20 : 0,
  },
  equityPlans: {
    flex: 1,
    paddingBottom: 100,
  },
  importStockAndMFContainer: {
    marginHorizontal: 20,
  },
  container: { flex: 1, flexDirection: "column", backgroundColor: "#fff" },
  loadingConainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  mainContainer: {
    backgroundColor: "#FFFFFF",
    marginBottom: 20,
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginHorizontal: isMobile ? 16 : 20,
    marginBottom: 66,
    width: isMobile ? 1200 : width < 1200 ? width * 0.95 : width * 0.815,
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 16 : 19,
    paddingVertical: 19,
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "space-between",
  },
  header: {
    fontFamily: "Roboto",
    color: "#464E5F",
    fontSize: 16,
    fontWeight: "700",
    marginBottom: isMobile ? 10 : 0,
  },
  tableHeader: {
    color: "#464E5F",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  rowItem: {
    textAlign: "left",
  },
  rowItemContainerStyle: {
    justifyContent: "flex-start",
    paddingLeft: 10,
  },
  headerItemContainerStyle: {
    justifyContent: "flex-start",
  },
});

// const optionsStyles = {
//   optionsContainer: {
//     width: 90,
//     backgroundColor: "white",
//     // position: 'absolute',
//     marginLeft: -20,
//     marginTop: 20,
//     zIndex: 100,
//     borderWidth: 0.5,
//     borderRadius: 5,
//     borderColor: '#410DAA1A'
//     // backgroundColor: 'green',
//     // padding: 5,
//   }
// };

const stripStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
  containerStrip: {
    width: width < 768 ? "100%" : width > 1350 ? 300 : 210,
    borderWidth: 1,
    borderRadius: 15,
    borderColor: "rgba(65, 13, 170, 0.2)",
    backgroundColor: "#fafafa",
  },
  subContainer: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    height: "100%",
    marginRight: 28,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: isMobile ? 14 : 17,
    color: "#494B69",
    fontWeight: "bold",
    lineHeight: 20,
    marginRight: 10,
    fontFamily: "Roboto",
  },
  pointsContainer: {
    marginTop: 10,
  },
  point: {
    flexDirection: "row",
    alignItems: "center",
  },
  pointText: {
    fontWeight: "bold",
    fontFamily: "Roboto",
    color: "#494B69",
    fontSize: 12,
  },
  complianceNullText: {
    fontWeight: "bold",
    fontFamily: "Roboto",
    color: "green",
    fontSize: 12,
  },
  bulletPoint: {
    marginRight: 7,
  },
  nextUpContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  nextUpTitle: {
    fontStyle: "italic",
  },
  nextUp: {
    fontFamily: "Roboto",
    fontSize: 12,
    color: "#494B69",
    fontWeight: "normal",
    marginTop: 5,
  },
  extra: {
    fontFamily: "Roboto",
    fontSize: 12,
    color: "#494B69",
    fontStyle: "italic",
    marginLeft: 6,
  },
});
