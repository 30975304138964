import React from "react";
import { StyleSheet, View, Platform, Image, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";

const Answer = ({ answer }) => {
    if (typeof answer === "string") {
        return <Text style={[styles.answer, global.arial]}>{answer}</Text>;
    } else {
        return answer.map((a, i) => {
            return (
            <Text style={[styles.answer, global.arial]} key={i}>
                {a}
            </Text>
            );
        });
    }
};

export default function FAQComponent({ question, answer, id, isOpen, handlePress }) {
    return (
        <View style={styles.container}>
            <TouchableOpacity
            onPress={() => {
                handlePress(id);
            }}
            >
                <View style={styles.flex}>
                    <View style={{ flexShrink: 1 }}>
                        <Text style={([global.arial], styles.question)}>{question}</Text>
                        {isOpen && <Answer answer={answer} />}
                    </View>
                    <View
                    style={[
                        styles.box,
                        Platform.OS=="web" && {
                            transform: [{ rotate: isOpen ? "180deg" : "0deg" }]
                        }
                    ]}
                    >
                        <Image
                            source={require("../../assets/upgradePlan/Landing/down_arrow.png")}
                            style={[
                            styles.img,
                                Platform.OS=="web" && { transform: [{ rotateY: isOpen ? "180deg" : "0deg" }] }
                            ]}
                        ></Image>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        marginTop: 21,
        borderBottomWidth: Platform.OS === "web" ? 1 : undefined,
        borderBottomColor: Platform.OS === "web" ? "#EDEDED" : undefined,
        paddingBottom: 20
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    answer: {
        opacity: 0.5,
        marginTop: 11,
        color: "black"
    },
    question: {
        color: "black"
    },
    img: {
        width: Platform.OS === "web" ? 20 : 20,
        height: Platform.OS === "web" ? 20 : 20,
        resizeMode: "contain"
    },
    box: {
        alignSelf: "flex-start",
        marginRight: Platform.OS === "web" ? undefined : 20,
        marginLeft: 10
    }
});
  
