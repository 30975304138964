import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  Dimensions,
  TouchableOpacity,
  Image,
} from "react-native";
import { useSelector } from "react-redux";
import { md } from "../../../Responsive";

const window = Dimensions.get("window");
export default function Header({ navigation, bannerData = {}, style = {} }) {
  const { demoMode } = useSelector((state) => state.testConfig);
  const handleLogoClick = () => {
    navigation.navigate("Login");
  };

  return (
    <View style={[styles.bar, { ...style }]}>
      <View style={styles.headerLeft}>
        <TouchableOpacity onPress={handleLogoClick}>
          <Image
            source={{
              uri: demoMode
                ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/images/simplygrow_logo.png"
                : bannerData.logo_image_url,
            }}
            style={styles.logo}
          ></Image>
        </TouchableOpacity>
      </View>
      <View style={styles.headerRight}>
        <Text style={{ fontSize: md(window.width) ? 16 : 13, color: "black" }}>
          Any Queries?
        </Text>
        <View>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("GetHelp");
            }}
          >
            <Text
              style={{
                fontSize: md(window.width) ? 16 : 13,
                color: "#410DAA",
                fontWeight: "bold",
                marginLeft: 5,
              }}
            >
              Get Help
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bar: {
    backgroundColor: "white",
    width: "100%",
    height: Platform.OS === "web" ? 65 : 80,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: md(window.width) ? 131 : 16,
    paddingRight: md(window.width) ? 131 : 16,
    paddingBottom: md(window.width) ? undefined : 18,
    borderBottomColor: "#DDD",
    borderBottomWidth: 1,
    position: Platform.OS === "web" ? "-webkit-sticky" : "absolute",
    top: 0,
    left: 0,
  },
  logo: {
    resizeMode: "contain",
    width: md(window.width) ? 155 : 120,
    height: 27.41,
    alignSelf: "center",
  },
  headerLeft: {
    display: "flex",
    alignItems: md(window.width) ? "center" : "flex-end",
    flexDirection: "row",
  },
  headerRight: {
    display: "flex",
    alignItems: md(window.width) ? "center" : "flex-end",
    flexDirection: "row",
  },
});
