import { isJama } from "../../utils"

const plansData = [
    {
        name: 'silver',
        displayName: 'Silver',
        // tag: 'Your Trusted Online Financial Advisor',
        // price: 125,
        // priceSubtitle: 'per month on yearly billing.',
        // featureHeading: 'More features when you Upgrade',
        // topFeatures: [
        //   'Unlimited Investments',
        //   'Investment Advice on Mutual Funds upto 1 Crore',
        //   'Advice on Asset Allocation ',
        //   'Advice on Mutual Fund Selection',
        //   'Advice on Rebalancing',
        //   'Advice on Financial Plan',
        //   'Advice on Insurance',
        //   'Call center support and priority email'
        // ],
        // features: [
        //   {
        //     name: 'Advice on Asset Allocation',
        //     description: ['Advice on how much to invest and where(eg: Debt, Equity, Gold) basis your risk profile'],
        //     img: "Gold_Page/1x/Advice on Asset Allocation.png"
        //   },
        //   {
        //     name: 'Advice on Asset Allocation',
        //     description: 'Advice on how much to invest and where(eg: Debt, Equity, Gold) basis your risk profile',
        //     img: "Gold_Page/1x/Advice on Asset Allocation.png"
        //   },
            
        //   {
        //     name: 'Advice on Asset Allocation',
        //     description: 'Advice on how much to invest and where(eg: Debt, Equity, Gold) basis your risk profile',
        //     img: "Gold_Page/1x/Advice on Asset Allocation.png"
        //   },
            
        //   {
        //     name: 'Advice on Asset Allocation',
        //     description: 'Advice on how much to invest and where(eg: Debt, Equity, Gold) basis your risk profile',
        //     img: "Gold_Page/1x/Advice on Asset Allocation.png"
        //   },
            
        //   {
        //     name: 'Advice on Asset Allocation',
        //     description: 'Advice on how much to invest and where(eg: Debt, Equity, Gold) basis your risk profile',
        //     img: "Gold_Page/1x/Advice on Asset Allocation.png"
        //   }
        // ],
        // whyBetter: [
        //   'Get advice on investments.  Distributors are not authorised to provide advice.',
        //   'Get advice on investments.  Distributors are not authorised to provide advice.',
        //   'Get advice on investments.  Distributors are not authorised to provide advice.',
        //   'Get advice on investments.  Distributors are not authorised to provide advice.'
        // ],
        properties: [
            {
            name: 'Investments',
            additional: '(0% commission,0% Brokerage)',
            value: 'No Limit',
    
            },
            {
            name: 'Investment Advice on MF',
            value: 'N.A',
    
            },
            {
            name: 'Investment Advice on Stocks',
            value: 'N.A',
    
            },
            {
            name: 'Plan Fee',
            value: 'FREE',
    
            }
        ],
        // callToAction: 'Upgrade Now',
        // question: 'All set to upgrade for better features?'
    },
    {
      name: 'gold',
      displayName: 'Gold',
      tag: 'Your Trusted Online Financial Advisor',
      price: 125,
      priceSubtitle: 'per month on yearly billing.',
      featureHeading: 'More features when you Upgrade',
      topFeatures: [
        'Unlimited Investments',
        'Investment Advice on Mutual Funds upto 1 Crore',
        'Advice on Asset Allocation ',
        'Advice on Mutual Fund Selection',
        'Advice on Rebalancing',
        'Advice on Financial Plan',
        'Advice on Insurance',
        'Call center support and priority email'
      ],
      features: [
        {
          name: 'Advice on Asset Allocation',
          description: 'Advice on how much to invest and where(eg: Debt, Equity, Gold) basis your risk profile',
        },
        {
          name: 'Advice on Mutual Fund Selection',
          description: `Advice on which Mutual Funds to buy (${ isJama() ? "Jama" : "Maxiom" } Picks)`,
        },
        
        {
          name: 'Advice on Rebalancing',
          description: 'Advice on portfolio changes annually to re-balance as per above plan',
        },
        
        {
          name: 'Advice on Financial Plan',
          description: ['Advice on Goals', 'Live Tracking of Financial Plan'],
        },
        
        {
          name: 'Advice on Insurance',
          description: 'Advice on how much Term Life and Health Insurance to buy',
        }
      ],
      whyBetter: [
        'Get advice on investments.  Distributors are not authorised to provide advice.',
        'Know your Risk Profile',
        'Know how much to invest and in which funds, personalised for you',
        'Managing Family Portfolios',
        'Get advice on protection',
        'Fee is equal to just one coffee per month'
      ],
      properties: [
        {
          name: 'Investments',
          additional: '(0% commission,0% Brokerage)',
          value: 'No Limit',
  
        },
        {
          name: 'Investment Advice on MF',
          value: 'Upto 1 Cr',
  
        },
        {
          name: 'Investment Advice on Stocks',
          value: 'N.A',
  
        },
        {
          name: 'Plan Fee',
          value: '₹125/m*',
  
        }
      ],
      callToAction: 'Upgrade Now',
      question: 'All set to upgrade for better features?'
    },
    {
        name: 'platinum',
        displayName: 'Platinum',
        tag: 'Your Personal Wealth Advisor',
        price: 1250,
        priceSubtitle: 'per month on yearly billing of 15,000',
        featuresTitle: 'Features that are custom designed for YOU',
        featureHeading: 'Features that are custom designed for YOU',
        topFeatures: [
            'Unlimited Investments',
            'Investment Advice on Mutual Funds',
            'Investment Advice on Stocks upto 1 Crore',
            'Bespoke Financial Plan with a certified advisor',
            'Quarterly Advice on Fund Performance',
            'Analysis of Stock portfolio',
            'Advice on Financial Plan',
            'Advice on Expense Planning',
            'Advice on Insurance Policies',
            'Advice on Succession Planning',
            'Priority Service & Preferred Scheduling of Slots with Advisor'
        ],
        features: [
            {
            name: 'Bespoke Financial Plan with a certified advisor',
            description: 'A plan for a great life'
            },
            {
            name: 'Quarterly Advice on Fund Performance',
            sub: 'Portfolios above 25 lakh have performance linked fees',
            description: 'Advice on how much to invest and where(eg: Debt, Equity, Gold) basis your risk profile'
            },
            
            {
            name: 'Analysis of Stock portfolio',
            description: 'Performance Review of Stocks in portfolio using our Machine Learning algorithms'
            },
            
            {
            name: 'Advice on Expense Planning',
            description: 'Advice on Reducing Loans, Expenses & Investment related Taxes'
            },
            
            {
            name: 'Advice on Insurance Policies',
            description: 'Advice on cost benefit of existing Insurance policies'
            },
            {
            name: 'Advice on Succession Planning',
            description: 'One Will worth Rs 2,500 shall be drafted by a lawyer for Free'
            },
            {
            name: 'Priority Service & Preferred Scheduling of Slots with Advisor',
            description: ''
            }
        ],
        whyBetter: [
            'Dedicated investment advisor',
            'Get customized advice',
            'Coverage of both stocks and funds',
            'Earn more with advice on fund replacement'
        ],
        properties: [
            {
            name: 'Investments',
            additional: '(0% commission,0% Brokerage)',
            value: 'No Limit',
    
            },
            {
            name: 'Investment Advice on MF',
            value: 'No Limit',
    
            },
            {
            name: 'Investment Advice on Stocks',
            value: 'Limited',
    
            },
            {
            name: 'Plan Fee',
            value: '₹1250/m*',
    
            }
        ],
        callToAction: 'Buy Platinum Membership',
        question: 'All set to upgrade for better features?'
    },
    {
        name: 'jewel',
        displayName: 'JêWEL',
        tag: 'Expert Equity Investing with a Trusted Advisor',
        altPriceSubtitle: 'To get pricing for JêWEL Membership, please contact our sales',
        price: undefined,
        priceSubtitle: 'for membership pricing, contact our sales',
        featureHeading: 'More features when you Upgrade',
        topFeatures: [
            'Unlimited Investments on the Platform',
            'Unlimited Investment Advice on Mutual Funds',
            'Unlimited Investment Advice on Stocks ',
            'Advice on Equities with strong Roots & Powerful Wings',
            'India’s Cleanest & Unbiased Investing Model',
            'Powered by Best in Class Technology',
        ],
        features: [
            {
            name: 'Advisory That Out-Performs',
            description: [
                'CAGR 38% since launch (vs Nifty 10%)',
                'SIP returns 48% XIRR since launch',
                '10 year average CAGR 31.04% vs Nifty 10.18%',
                'Audited portfolio statement available at request',
                'Crafted by Top Experts & Individual SEBI Registered Investment Advisors'
            ],
            },
            {
            name: 'Advice on Equities with Strong Roots',
            description: [
                'Low debt companies',
                'High return on Equity',
                'Promoters with Skin in the game',
                'Large & mid cap focus'
            ],
            },
            {
            name: 'Advice on Equities with Powerful Wings',
            description: [
                'Growing sales and profits',
                'Robust cash flows',
                'Strong market share',
                'Resilient in the market'
            ],
            },
            {
            name: 'India’s Cleanest & Unbiased Investing Model',
            description: [
                'Contact less – no touch money',
                'No commissions / indirect fees',
                'No exit loads / lock in',
                'Client centric'
            ],
            },
            
            {
            name: 'Powered by Best in Class Technology',
            description: [
                'Machine Learning Algorithms',
                'Dynamic stock selection logic',
                'Integration with leading brokerages',
                'Portfolio updates keeping emotions in check'
            ],
            }
        ],
        whyBetter: [
            'Full access to Direct equity advisory (best vehicle to build serious wealth)',
            'Timely advice on stock replacement and rebalancing',
            'Managing Family Portfolios',
            'Priority Service',
            'Earn more with proven portfolio, Pay less than alternatives'
        ],
        properties: [
            {
            name: 'Investments',
            additional: '(0% commission,0% Brokerage)',
            value: 'No Limit',
    
            },
            {
            name: 'Investment Advice on MF',
            additional: '',
            value: 'No Limit',
    
            },
            {
            name: 'Investment Advice on Stocks',
            additional: '',
            value: 'No Limit',
    
            },
            {
            name: 'Plan Fee',
            additional: '',
            value: '₹1250/m*',
    
            }
        ],
        callToAction: 'Contact us for JeWEL Membership',
        question: 'Looking to build wealth with a Trusted Advisor?'
    }
]

export default plansData