import React from "react";
import { View, Text, Image, StyleSheet, TouchableOpacity } from "react-native";
import { isMobile } from "../../utils";
import { AntDesign } from "@expo/vector-icons";

const staticData = {
  JWL: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Jewel%401x+1.png",
    mobile_image: "",
    title: "JEWEL- Flexicap Growth Strategy",
    volatality: "Medium Volatility",
    description:
      "Top 350 cos | High ROE, ROA, Growth, Low Debt, Market Share, Management",
    cagr: 32.63,
    three_yr_return: 40.93,
    five_yr_return: 22.48,
    know_more: "https://maxiomwealth.com/contact-us/",
  },
  SRK: {
    image:
      "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/Spark%401x+4.png",
    mobile_image: "",
    title: "SPARK- Smallcap Growth Strategy",
    volatality: "High Volatility",
    description:
      "Smallcaps with marketcap 500cr to 8000cr | Low debt, High ROE, Growing Companies with runway",
    cagr: 32.63,
    three_yr_return: 40.93,
    five_yr_return: 22.48,
    know_more: "https://maxiomwealth.com/contact-us/",
  },
};

export const SparkTeaser = ({ style, onClose, product }) => {
  const {
    title,
    volatality,
    image,
    description,
    cagr,
    three_yr_return,
    five_yr_return,
    know_more,
  } = staticData[product];
  return (
    <View style={[styles.container, { ...style }]}>
      {!isMobile && (
        <View style={styles.logo}>
          <Image source={{ uri: image }} style={styles.logoStyle} />
        </View>
      )}
      <View style={styles.body}>
        <View style={styles.descriptionContainer}>
          {isMobile ? (
            <View style={styles.titleContainer}>
              <View style={styles.logo}>
                <Image source={{ uri: image }} style={styles.logoStyle} />
              </View>
              <View style={styles.mobileDescContainer}>
                <View style={styles.titleDescContainer}>
                  <Text style={styles.title}>{title}</Text>
                  <View style={[styles.volatalityContainer]}>
                    <Text style={styles.volatality}>{volatality}</Text>
                  </View>
                </View>
                <Text style={styles.mobileDescText}>{title}</Text>
              </View>
            </View>
          ) : (
            <View style={styles.titleDescContainer}>
              <Text style={styles.title}>{title}</Text>
              <View
                style={[
                  styles.volatalityContainer,
                  product == "SRK"
                    ? styles.highVolatalityContainer
                    : styles.mediumVolatalityContainer,
                ]}
              >
                <Text
                  style={[
                    styles.volatality,
                    product == "SRK"
                      ? styles.highVolatality
                      : styles.mediumVolatality,
                  ]}
                >
                  {volatality}
                </Text>
              </View>
            </View>
          )}
          <View style={styles.desc}>
            <View style={styles.box1}>
              {!isMobile && <Text style={styles.descText}>{description}</Text>}
              <View style={styles.infoContainer}>
                <View style={styles.infoBox}>
                  <Text style={styles.infoTitle}>CAGR</Text>
                  <Text style={styles.infoValue}>{cagr}%</Text>
                </View>
                <View style={styles.infoBox}>
                  <Text style={styles.infoTitle}>3Y Rolling Return</Text>
                  <Text style={styles.infoValue}>{three_yr_return}%</Text>
                </View>
                <View style={styles.infoBox}>
                  <Text style={styles.infoTitle}>5Y Rolling Return</Text>
                  <Text style={styles.infoValue}>{five_yr_return}%</Text>
                </View>
              </View>
            </View>
            <View
              style={[styles.box2, isMobile && { justifyContent: "center" }]}
            >
              <View style={{ flex: 1 }}>
                <TouchableOpacity
                  style={styles.investButton}
                  onPress={() => {
                    global.open(know_more, "_blank");
                  }}
                >
                  <Text style={styles.investText}>Know More</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
      <TouchableOpacity style={styles.closeIcon} onPress={onClose}>
        <AntDesign name="close" size={20} color="#888C9F" onPress={onClose} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderColor: "#1BC5BD20",
    borderWidth: 1,
    padding: 18,
    flexDirection: "row",
    marginBottom: 0,
    backgroundColor: "#C9F7F520",
    marginRight: isMobile ? 0 : 30,
  },
  logoStyle: {
    width: isMobile ? 31 : 50,
    height: isMobile ? 31 : 50,
    borderRadius: 6,
    resizeMode: "contain",
  },
  body: {
    flex: 1,
  },
  closeIcon: {
    position: "absolute",
    top: isMobile ? 6 : 18,
    right: isMobile ? 6 : 18,
  },
  logo: {
    width: isMobile ? 36 : 80,
    height: isMobile ? 36 : 80,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#1BC5BD20",
    marginRight: 21,
    alignItems: "center",
    justifyContent: "center",
  },
  volatalityContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    backgroundColor: "#FFF0E8",
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
  },
  volatality: {
    fontSize: isMobile ? 9 : 10,
    color: "#EF5D12",
    fontStyle: "italic",
    paddingVertical: 6,
    paddingHorizontal: 18,
    fontWeight: "bold",
  },
  titleContainer: {
    flexDirection: "row",
    borderBottomColor: "#00000010",
    borderBottomWidth: 1,
  },
  title: {
    fontSize: isMobile ? 12 : 20,
    color: "#464E5F",
    fontWeight: "bold",
  },
  mobileDescContainer: {
    flex: 1,
    flexDirection: "column",
  },
  desc: {
    width: "100%",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: isMobile ? "flex-start" : "space-between",
  },
  box1: { flex: 0.6 },
  box2: {
    flex: 0.4,
    flexDirection: isMobile ? "row" : "column",
    marginTop: isMobile ? 30 : 0,
  },
  mobileDescText: {
    fontSize: isMobile ? 11 : 13,
    color: "#6C7293",
    lineHeight: 20,
    paddingBottom: 10,
    textAlign: "left",
    flexWrap: "wrap",
  },
  descText: {
    fontSize: isMobile ? 11 : 11,
    color: "#6C7293",
    lineHeight: 20,
    opacity: 0.8,
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  minInvContainer: {
    alignItems: "center",
  },
  minInv: {
    fontSize: 11,
    color: "#6C7293",
    marginBottom: 8,
    fontWeight: "600",
  },
  minInvAmount: {
    fontSize: 15,
    color: "#0BB783",
    fontWeight: "500",
  },
  infoBox: {
    marginRight: 35,
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
  },
  infoContainer: {
    marginTop: isMobile ? 20 : 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: isMobile ? "space-between" : "flex-start",
  },
  infoTitle: {
    fontSize: isMobile ? 12 : 13,
    color: "#6C7293",
    fontWeight: "600",
    marginRight: 5,
  },

  infoValue: {
    fontSize: isMobile ? 13 : 14,
    color: "#464E5F",
    fontWeight: "600",
  },
  investButton: {
    width: isMobile ? "100%" : "auto",
    alignSelf: "center",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#16C3A9",
    padding: 12,
    borderRadius: 6,
    paddingHorizontal: isMobile ? 30 : 84,
  },
  investText: {
    color: "#16C3A9",
    fontWeight: "700",
    fontSize: isMobile ? 12 : 15,
    textAlign: "center",
  },
  titleDescContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: isMobile ? 8 : 12,
  },
  volatalityContainer: {
    marginLeft: isMobile ? 6 : 15,
    borderRadius: 4,
    marginTop: isMobile ? 1 : 0,
  },
  highVolatalityContainer: {
    backgroundColor: "#FFF0E8",
  },
  mediumVolatalityContainer: {
    backgroundColor: "#0BB7831A",
  },
  volatality: {
    fontSize: isMobile ? 6 : 10,
    fontStyle: "italic",
    paddingVertical: isMobile ? 3 : 6,
    paddingHorizontal: isMobile ? 9 : 18,
    fontWeight: "bold",
  },
  highVolatality: {
    color: "#EF5D12",
  },
  mediumVolatality: {
    color: "#0BB783",
  },
});
