import React from "react";
import {View, StyleSheet} from "react-native";

export const HorizontalLine = ({color, style={}}) => {
    return(
        <View style={[styles.line, { backgroundColor: color || "#00000010", ...style}]}/>
    )
}

const styles = StyleSheet.create({
    line: {
        height: 1,
        width: "100%",
    }
})