import React from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  SafeAreaView,
  ActivityIndicator,
  Dimensions,
  TouchableOpacity,
  Platform,
} from "react-native";
import { Text } from "react-native-paper";
import AIcon from "@expo/vector-icons/AntDesign";
import { StackActions } from "@react-navigation/native";
import amplitude from "../../../utils/amplitude";
import { ShowToast } from "../../../components/Common/Toast";
import { getModuleData, trackUserActivityEdu, userFeedbackOnChapter } from "../../../api";
import NavigationHeader from "../../../components/Common/NavigationHeader";
const WebView =
  Platform.OS == "web"
    ? require("react-native-web-webview").WebView
    : require("react-native-webview").WebView;
import { isMobile } from "../../../utils/index";
import Toast from "react-native-toast-message";
const { width } = Dimensions.get("window");

export default class DetailedView extends React.Component {
  constructor(props) {
    super(props);
    const params = this.props.route;
    let key = null;
    let is_advanced = null;
    let selectedIndex = null;
    let is_video = null;
    let quiz_id = null;
    if (params.params) {
      key = params.params.payload.key || null;
      is_advanced = params.params.payload.is_advanced || false;
      selectedIndex = params.params.payload.selectedIndex || 0;
      is_video = params.params.payload.is_video || false;
      quiz_id = params.params.payload.quiz_id || null;
    }
    // console.log(params);
    this.state = {
      fetching: false,
      index: 0,
      loading: true,
      chapterCards: [],
      selectedCard: null,
      liked: null,
      key,
      is_advanced,
      selectedIndex,
      is_video,
      quiz_id,
      showToast: false,
      toastTitle: "",
    };
  }

  async componentDidMount() {
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("detailedView",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("detailedView", { path: this.props.navigation.getState().routeName });
      }
      const { key, is_advanced, selectedIndex, is_video, quiz_id } = this.state;
      let advanced = null;
      if (is_advanced) {
        advanced = "true";
      } else {
        advanced = "false";
      }
      const payload = { key: key, is_advanced: advanced };
      const response = await getModuleData(payload);
      // console.log(response)
      let results = [];
      let chapterCards = null;
      let selectedCard = null;
      let card = null;
      if (response && response.code == 100) {
        results = response.pages.results[0].qna;
        results.sort((a, b) =>
          a.content_type > b.content_type
            ? 1
            : b.content_type > a.content_type
            ? -1
            : 0
        );
        chapterCards = results;
        selectedCard = selectedIndex;
        card = chapterCards[selectedCard];
      }
      const next =
        selectedCard + 1 < chapterCards.length
          ? chapterCards[selectedCard + 1].question
          : null;
      const previous =
        selectedCard - 1 >= 0 ? chapterCards[selectedCard - 1].question : null;
      this.setState(
        {
          chapterCards,
          selectedCard,
          card,
          next,
          previous,
          is_video,
          is_advanced,
          quiz_id,
        },
        () => {
          this.trackActivity(card.faq_id);
          this.setState({
            loading: false,
          });
        }
      );
    } catch (err) {
      this.setState({ loading: false });
    }
  }

  updateSelectedNextCard = () => {
    const { chapterCards, selectedCard, card } = this.state;
    if (selectedCard + 1 < chapterCards.length) {
      const stateObject = {
        selectedCard: selectedCard + 1,
        card: chapterCards[selectedCard + 1],
        next:
          selectedCard + 2 < chapterCards.length
            ? chapterCards[selectedCard + 2].question
            : null,
        previous: card.question,
      };

      this.setState(stateObject, () => {
        this.scrollview.scrollTo({ x: 0, y: 0, animated: true });
        this.trackActivity(this.state.card.faq_id);
      });
    }
  };

  updateSelectedPreviousCard = () => {
    const { chapterCards, selectedCard, card } = this.state;
    if (selectedCard - 1 >= 0) {
      const stateObject = {
        selectedCard: selectedCard - 1,
        card: chapterCards[selectedCard - 1],
        previous:
          selectedCard - 2 >= 0
            ? chapterCards[selectedCard - 2].question
            : null,
        next: card.question,
      };

      this.setState(stateObject, () => {
        this.scrollview.scrollTo({ x: 0, y: 0, animated: true });
        this.trackActivity(this.state.card.faq_id);
      });
    }
  };

  userFeedbackOnChapterMethod = async (is_helpful) => {
    const { card } = this.state;
    const payload = {
      module_id: card.faq_id,
      is_helpful: is_helpful,
    };
    const response = await userFeedbackOnChapter(payload);
    if (response && response.code == "100") {
      this.setState({ showToast: true, toastTitle: response.msg });
    }
  };

  trackActivity = async (bundleId) => {
    // console.log(bundleId)
    const { card } = this.state;
    const response = await trackUserActivityEdu({"mb_id":bundleId});
    // console.log(response);
  };

  renderHtmlView = (html) => {
    let answer = html.replace(/\\r?\\n?/g, "");
    const head = `<style>body{margin:0}</style><meta name="viewport" content="width=device-width, initial-scale=1">`;
    const htmlAnswer = `<!DOCTYPE html><html><head>${head}</head><body>${answer}</body></html>`;
    return (
      <ScrollView
        style={{ flex: 1, padding: 16, paddingBottom: 0, position: "relative" }}
      >
        <View style={{ flex: 1 }}>
          <WebView source={{ html: htmlAnswer }} style={{ height: 400 }} />
        </View>
        {/* <View
          style={{
            position: "absolute",
            bottom: -70,
            left: 0,
            right: 0,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.setState({
                showToast: false,
                toastTitle: null,
              });
              this.userFeedbackOnChapterMethod("true");
            }}
          >
            <AIcon
              name={"like2"}
              size={28}
              color={"green"}
              style={{ marginRight: 32 }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.setState({
                showToast: false,
                toastTitle: null,
              });
              this.userFeedbackOnChapterMethod("false");
            }}
          >
            <AIcon name={"dislike2"} size={28} color={"red"} />
          </TouchableOpacity>
        </View> */}
      </ScrollView>
    );
  };

  renderWebView = (data) => {
    let iframeHtml = data.replace(
      /(width=")\d+("\W+height=")\d+/,
      `$1${"100%"}$2${"350px"}`
    );
    const head = `<style>body{margin:0}</style><meta name="viewport" content="width=device-width, initial-scale=1">`;

    const width = width;
    const height = 400;
    return (
      <View style={{ flex: 1, padding: 16 }}>
        <WebView source={{ html: iframeHtml }} style={{ height: height }} />
      </View>
    );
  };
  handleGoBack = () => {
    this.props.navigation.dispatch(StackActions.replace("EduHome"));
  };

  render() {
    const { questionStyle } = styles;
    const { pages } = this.state;
    const { card, next, loading, previous, quiz_id } = this.state;
    if (loading) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" color="#410DAA" />
        </View>
      );
    }

    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "#fff",
        }}
      >
        {this.state.showToast ? (
          <ShowToast
            showToast={true}
            type="status"
            title={this.state.toastTitle}
            duration="5"
          />
        ) : null}

        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Modules"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />

        {this.state.showToast ? (
          <ShowToast
            showToast={true}
            type="status"
            title={this.state.toastTitle}
            duration="5"
          />
        ) : null}

        <View style={{ flex: 1 }}>
          <ScrollView
            showsVerticalScrollIndicator={true}
            style={{ flex: 1, paddingLeft: 16 }}
            contentContainerStyle={{ flex: 1 }}
            ref={(ref) => (this.scrollview = ref)}
            scrollEnabled={true}
          >
            <Text style={questionStyle}>{card.question}</Text>

            {card.content_type == "TXT"
              ? this.renderHtmlView(card.answer)
              : this.renderWebView(card.url)}

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {!!previous ? (
                <TouchableOpacity
                  onPress={() => {
                    Toast.hide();
                    this.updateSelectedPreviousCard();
                  }}
                  style={{
                    alignSelf: "flex-start",
                    marginBottom: isMobile ? 80 : 8,
                    marginLeft: 8,
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      textAlign: "left",
                      marginRight: 16,
                      marginTop: 8,
                    }}
                  >
                    Previous
                  </Text>
                </TouchableOpacity>
              ) : (
                <View style={{ flex: 1 }}></View>
              )}
              <View
                style={{
                  marginTop: 8,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  marginBottom: isMobile ? 70 : 8,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    this.setState({
                      showToast: false,
                      toastTitle: null,
                    });
                    this.userFeedbackOnChapterMethod("true");
                  }}
                >
                  <AIcon
                    name={"like2"}
                    size={28}
                    color={"green"}
                    style={{ marginRight: 32 }}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({
                      showToast: false,
                      toastTitle: null,
                    });
                    this.userFeedbackOnChapterMethod("false");
                  }}
                >
                  <AIcon name={"dislike2"} size={28} color={"red"} />
                </TouchableOpacity>
              </View>
              {!!next ? (
                <TouchableOpacity
                  onPress={() => {
                    Toast.hide();
                    this.updateSelectedNextCard();
                  }}
                  style={{
                    alignSelf: "flex-end",
                    marginBottom: isMobile ? 80 : 20,
                    marginRight: 8,
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      textAlign: "right",
                      marginRight: 16,
                      marginTop: 8,
                    }}
                  >
                    Next
                  </Text>
                </TouchableOpacity>
              ) : quiz_id ? (
                <>
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("EduQuiz", { quiz_id });
                    }}
                    style={{
                      alignSelf: "flex-end",
                      marginBottom: isMobile ? 80 : 20,
                      flex: 1,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginRight: 16,
                        textAlign: "right",
                      }}
                      numberOfLines={1}
                    >
                      Take Quiz
                    </Text>
                  </TouchableOpacity>
                </>
              ) : (
                <>
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.goBack();
                    }}
                    style={{
                      alignSelf: "flex-end",
                      marginBottom: 8,
                      flex: 1,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginRight: 16,
                        textAlign: "right",
                      }}
                      numberOfLines={1}
                    >
                      Done
                    </Text>
                  </TouchableOpacity>
                </>
              )}
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: 16,
  },
  questionStyle: {
    fontSize: 16,
    fontWeight: "bold",
    marginVertical: 8,
    color: "#000",
    padding: 16,
  },
  buttonsConatiner: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 8,
    backgroundColor: "#fff",
  },
  imageStyle: {
    width: width,
    height: 300,
  },
});
