import React from "react";
import {
  View,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  Platform,
  ScrollView,
} from "react-native";
import { Button, HelperText, Text } from "react-native-paper";
import amplitude from "../../../utils/amplitude";
import TextInputField from "../../../components/Common/Inputs/InputField";
import {
  capitalizeFirstLetter,
  getUserDetails,
  isMobile,
} from "../../../utils";
import SearchBar from "../../../components/Common/SearchBar";
// import ReactExport from "react-export-excel";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import moment from "moment";
import CorpusTable from "./CorpusTable";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import Store from "../../../store";
import {
  getCorpusOrExpenseDetails,
  getCustomerDetails,
  saveRetirementReducingFactor,
} from "../../../api";
import Slider from "@react-native-community/slider";

class RetirementCorpus extends React.Component {
  constructor(props) {
    super(props);
    let userId = null;
    let goalId = null;
    const { params } = this.props.route;
    if (params) {
      userId = params.user_id || null;
      goalId = params.goalId || null;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }

    this.state = {
      goalId,
      percentage: 0,
      percentage_error: "",
      sorting_field: null,
      sorting_value: null,
      userId,
      userType: "",
      yearsToRetire: "",
      retirementYear: "",
      corpusDataValue: [],
      searchCorpusResults: [],
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("RetirementCorus",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("RetirementCorpus", { path: this.props.navigation.getState().routeName });
    }
    let userData = {};
    if (this.state.userId) {
      userData = await getCustomerDetails(this.state.userId);
    } else {
      userData = await getUserDetails();
    }
    let resp = await getCorpusOrExpenseDetails({
      head_code: "EXP",
    });
    this.setState({
      retirementYear: moment(resp.result.retirement_date).format("YYYY"),
      yearsToRetire: resp.result.years_to_retire,
      percentage: resp.result.ret_red_perc,
    });
    let corpusDataValue = [];
    resp.result.table_rows.forEach((item) => {
      corpusDataValue.push({
        type: item[0],
        expense: item[1],
        remarks: item[2],
        currentCost: item[3],
        frequency: item[4],
        from: item[5],
        to: item[6],
        inflation: item[7],
        entry_id: item[8],
      });
    });
    this.setState({ corpusDataValue, searchCorpusResults: corpusDataValue });
    let { first_name } = userData;
    let names = first_name ? first_name.split(/\s+/) : [];
    let name = "";
    if (names.length >= 2 && names.length > 0) {
      name =
        capitalizeFirstLetter(names[0]) + " " + capitalizeFirstLetter(names[1]);
    } else {
      if (names.length == 0) {
        name = "User";
      } else {
        name = names[0];
      }
    }
    this.setState({ userType: name });
  }

  handleContinue = async () => {
    let { percentage } = this.state;
    if (percentage == "") {
      this.setState({ percentage_error: "Please enter a value." });
      return;
    }
    let response = await saveRetirementReducingFactor({
      ret_red_perc: percentage,
    });
    if (response && response.code && response.code == 100) {
      this.props.navigation.push("IncomePostRetirement", {
        goalId: this.state.goalId,
      });
    }
  };

  handleCorpusSort = (field, sort, start, end) => {
    const sorting_field = field;
    const sorting_value = !sort;

    const { searchCorpusResults } = this.state;
    const sortedData = [].concat(
      searchCorpusResults.slice(0, start),
      this.getSortedData(
        searchCorpusResults,
        sorting_field,
        sorting_value,
        start,
        end,
        "corpus"
      ),
      searchCorpusResults.slice(end, searchCorpusResults.length)
    );

    this.setState({
      sorting_field,
      sorting_value,
      searchCorpusResults: sortedData,
    });
  };

  handleCorpusSearch = (searchCorpusText) => {
    let searchCorpusResults = [];
    const searchData = this.state.corpusDataValue;
    if (searchCorpusText) {
      searchCorpusResults = searchData.filter((corpus) => {
        let searchText = corpus.type;
        return searchText
          .toLowerCase()
          .includes(searchCorpusText.toLowerCase());
      });
    } else {
      searchCorpusResults = searchData;
    }
    if (searchCorpusResults.length) {
      this.setState({
        searchCorpusText,
        searchCorpusResults,
        sorting_field: null,
        sorting_value: null,
      });
    }
  };

  compareStrings = (a, b) => {
    const x = a.toLowerCase();
    const y = b.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  compareNumbers = (a, b) => {
    return a - b;
  };

  getSortedData = (data, sorting_field, sorting_value) => {
    if (data.length === 0) return data;
   
    const compareWithMissingValues = (a, b, comparator) => {
      if (a === undefined || a === null) return 1; // Treat missing `a` as larger
      if (b === undefined || b === null) return -1; // Treat missing `b` as smaller
      return comparator(a, b);
    };
   
    const parseCurrency = (value) => {
      if (!value) return 0;
      return parseFloat(value.replace(/[^0-9.-]/g, '')); // Remove ₹, commas, and other non-numeric characters
    };
   
    const fieldValue = data.find(item => item[sorting_field] !== undefined && item[sorting_field] !== null)?.[sorting_field];
    let sortingType;
   
    if (fieldValue && moment(fieldValue, "DD-MM-YYYY", true).isValid()) {
      sortingType = "date";
    } else if (["currentCost", "inflation", "entry_id"].includes(sorting_field)) {
      sortingType = "number";
    } else if (sorting_field === "frequency") {
      sortingType = "string"; // Frequency treated as string for now
    } else {
      sortingType = "string";
    }
   
    switch (sortingType) {
      case "date":
        data.sort((a, b) =>
          compareWithMissingValues(a[sorting_field], b[sorting_field], (x, y) =>
            moment(x, "DD-MM-YYYY").toDate() - moment(y, "DD-MM-YYYY").toDate()
          )
        );
        break;
   
      case "number":
        data.sort((a, b) =>
          compareWithMissingValues(a[sorting_field], b[sorting_field], (x, y) => {
            const numX = sorting_field === "currentCost" ? parseCurrency(x) : parseFloat(x) || 0;
            const numY = sorting_field === "currentCost" ? parseCurrency(y) : parseFloat(y) || 0;
            return numX - numY;
          })
        );
        break;
   
      case "string":
        data.sort((a, b) =>
          compareWithMissingValues(a[sorting_field], b[sorting_field], (x, y) =>
            x.toString().localeCompare(y.toString())
          )
        );
        break;
   
      default:
        // Default to string sorting if type is unclear
        data.sort((a, b) =>
          compareWithMissingValues(a[sorting_field], b[sorting_field], (x, y) =>
            x.toString().localeCompare(y.toString())
          )
        );
    }
   
    return sorting_value ? data : data.reverse(); // Reverse if descending order
  };
 
  compareFrequency = (a, b) => {
    const monthOrder = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
    const [dayA, monthA] = a.toLowerCase().split("(");
    const [dayB, monthB] = b.toLowerCase().split("(");
    const monthIndexA = monthOrder.indexOf(monthA.replace(")", ""));
    const monthIndexB = monthOrder.indexOf(monthB.replace(")", ""));
 
    if (monthIndexA === monthIndexB) {
      return parseInt(dayA) - parseInt(dayB);
    }
    return monthIndexA - monthIndexB;
  }

  getCorpusHeaderData = (data) => {
    const { sorting_field, sorting_value } = this.state;

    const keysData = [
      {
        label: "Expense",
        key: "expense",
      },
      {
        label: "Type",
        key: "type",
      },
      {
        label: "Current Cost",
        key: "currentCost",
      },
      {
        label: "Frequency",
        key: "frequency",
      },
      {
        label: "From",
        key: "from",
      },
      {
        label: "To",
        key: "to",
      },
      {
        label: "Inflation",
        key: "inflation",
      },
      {
        label: "Action",
        key: "entry_id",
      },
    ];

    let headerCorpusData = [];
    keysData.forEach((headerItem) => {
      headerCorpusData.push({
        label: headerItem.label,
        style: [styles.tableHeader],
        headerItemContainerStyle: [styles.headerItemContainerStyle],
        sortOption: true,
        onSort: this.handleCorpusSort,
        key: headerItem.key,
        currentSortField: sorting_field,
        sortValue: sorting_value,
      });
    });

    let valuesCorpus = [];
    data.forEach((value) => {
      valuesCorpus.push([
        {
          valueToDisplay: value.expense,
          value: value.expense,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.type,
          value: value.type,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.currentCost,
          value: value.currentCost,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.frequency,
          value: value.frequency,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.from,
          value: value.from,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.to,
          value: value.to,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.inflation,
          value: value.inflation,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
        {
          valueToDisplay: value.entry_id,
          value: value.entry_id,
          style: [styles.rowItem],
          rowItemContainerStyle: [styles.rowItemContainerStyle],
        },
      ]);
    });
    return { headerCorpusData, valuesCorpus };
  };

  render() {
    const { headerCorpusData, valuesCorpus } = this.getCorpusHeaderData(
      this.state.searchCorpusResults
    );

    let excelCorpusData = [];
    this.state.searchCorpusResults.forEach((item) => {
      excelCorpusData.push({
        type: item.type,
        frequency: item.frequency,
        currentCost: item.currentCost,
        expense: item.expense,
        remarks: item.remarks,
        from: item.from,
        to: item.to,
        inflation: item.inflation,
      });
    });
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Retirement Corpus"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          style={styles.cardContainer}
          showsVerticalScrollIndicator={false}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                fontSize: 22,
                color: "#464E5F",
                fontWeight: "700",
                marginVertical: 10,
                marginLeft: 40,
              }}
            >
              Let's estimate your retirement corpus based on expenses
            </Text>
            <Text
              onPress={() => {
                this.props.navigation.push("AddExpense", {
                  goalId: this.state.goalId,
                });
              }}
              style={{
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: 15,
                color: "#410DAA",
                backgroundColor: "white",
                borderColor: "#410DAA",
                borderWidth: 1,
                borderRadius: 4,
                paddingTop: 12,
                textAlign: "center",
                width: 150,
                height: 45,
                marginRight: 40,
              }}
            >
              Add Expense
            </Text>
          </View>
          <Text
            style={{
              fontSize: 17,
              color: "#515151",
              marginTop: 30,
              marginLeft: 40,
            }}
          >
            Retirement year:{" "}
            <Text
              style={{ fontWeight: "bold", color: "#000000", fontSize: 16 }}
            >
              {this.state.retirementYear}
            </Text>
          </Text>
          <Text
            style={{
              fontSize: 17,
              color: "#515151",
              marginTop: 10,
              marginLeft: 40,
            }}
          >
            Years to retirement:{" "}
            <Text
              style={{ fontWeight: "bold", color: "#000000", fontSize: 16 }}
            >
              {this.state.yearsToRetire} years
            </Text>
          </Text>
          <View style={styles.detailsContainer}>
            <View style={styles.headingContainer}>
              <SearchBar
                value={this.state.searchCorpusText}
                onChange={this.handleCorpusSearch}
              />
              <View
                style={{
                  cursor: "pointer",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  marginRight: 20,
                }}
              >
                {/* <ExcelFile
                  filename={
                    this.state.userType +
                    " " +
                    "Retirement Corpus List " +
                    moment().format("DD-MM-YYYY hh-mm-ss A")
                  }
                  element={
                    <Text
                      style={{
                        color: "#410DAA",
                        fontWeight: "bold",
                        cursor: "pointer",
                        fontSize: 15,
                        textDecorationLine: "underline",
                      }}
                    >
                      Download As Excel
                    </Text>
                  }
                >
                  <ExcelSheet data={excelCorpusData} name="Retirement Corpus">
                    <ExcelColumn label="Expense" value="expense" />
                    <ExcelColumn label="Type" value="type" />
                    <ExcelColumn label="Current Cost" value="currentCost" />
                    <ExcelColumn label="Frequency" value="frequency" />
                    <ExcelColumn label="From" value="from" />
                    <ExcelColumn label="To" value="to" />
                    <ExcelColumn label="Inflation" value="inflation" />
                  </ExcelSheet>
                </ExcelFile> */}
              </View>
            </View>
            <CorpusTable
              header={headerCorpusData}
              gridConfig={[1, 1, 1, 1, 1, 1, 1, 1]}
              data={valuesCorpus}
              goalId={this.state.goalId}
              navigation={this.props.navigation}
              pagination={valuesCorpus.length > 10 ? true : false}
            />
          </View>
          <Text
            style={{
              fontSize: 17,
              color: "#515151",
              marginTop: 30,
              marginLeft: 40,
            }}
          >
            I expect any pre-retirement expenses that continue into retirement,
            to
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
              marginLeft: 40,
              width: "70%",
            }}
          >
            <View style={[{ flex: 1 }, styles.itemMargin]}>
              <TextInputField
                label="Enter Percentage"
                value={this.state.percentage ? `${this.state.percentage}%` : "0%"}
                onChange={(text) => {
                  text = text.replaceAll("%", "");
                  const percentageValue = Math.max(0, parseFloat(text) || 0);
                  this.setState({
                    percentage: percentageValue,
                    percentage_error: "",
                  });
                }}
                placeholder={"Enter Percentage"}
                inputStyle={[{ paddingLeft: 20, width: 180 }]}
                editable={true}
              />
              <View style={{ marginTop: 11, width: 180 }}>
                <Slider
                  value={this.state.percentage}
                  thumbTintColor={"#410daa"}
                  minimumValue={1}
                  step={1}
                  maximumValue={100}
                  maximumTrackTintColor={"#D8D8D8"}
                  minimumTrackTintColor={"#410daa"}
                  onValueChange={(percentage) => {
                    this.setState({ percentage: Math.max(1, percentage) });
                  }}
                />
              </View>
            </View>
          </View>
          <Text
            style={{
              fontSize: 14,
              fontStyle: "italic",
              color: "#673dbb",
              marginLeft: 40,
              marginTop: 10,
            }}
          >
            Reduce by Percentage:{" "}
            {this.state.percentage ? `${this.state.percentage}%` : "0%"}
          </Text>
          <Button
            uppercase={false}
            mode={"contained"}
            onPress={this.handleContinue}
            style={styles.nextButton}
            color={"#410DAA"}
          >
            <Text style={styles.next}>Continue</Text>
          </Button>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  itemMargin: {
    paddingHorizontal: isMobile ? 16 : 0,
  },
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  nextButton: {
    width: "12%",
    marginLeft: "4.5%",
    marginTop: 40,
    marginBottom: 100,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginLeft: "3%",
    marginTop: 20,
    width: "94%",
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 16 : 19,
    paddingVertical: 19,
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "space-between",
  },
});

export default RetirementCorpus;
