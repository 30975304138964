import React from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Text, Button } from "react-native-paper";
import { isMobile } from "../../utils";
import amplitude from "../../utils/amplitude";

export default class AdvisoryClubStrip extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ width: "100%" }}
      >
        <View
          style={{
            padding: 8,
            paddingLeft: 0,
            width: isMobile ? "100%" : "90%",
            paddingBottom: 20,
          }}
        >
          <TouchableOpacity
            style={styles.stripContainer}
            onPress={() => {
              this.props.navigation.navigate("App", {
                screen: "Account",
                params: {
                  screen: "AdvisoryPlan",
                  params: {
                    cip_pk_for_renewal: null
                  },
                },
              });
            }}
          >
            <View style={styles.subContainer}>
              <View style={styles.subContainerOne}>
                <View>
                  <Image
                    source={{
                      uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/upgrade_arrow.png",
                    }}
                    style={{
                      width: 40,
                      height: 40,
                      marginTop: -35,
                      marginLeft: 12,
                      marginRight: -5,
                      resizeMode: "contain",
                    }}
                  />
                </View>
                <View style={{ padding: 15 }}></View>
                {isMobile ? (
                  <View>
                    <View>
                      <Text
                        style={{
                          color: "#464E5F",
                          fontSize: 14,
                          paddingBottom: 5,
                          fontFamily: "Roboto",
                        }}
                      >
                        Upgrade to Advisory Circle
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "#464E5F",
                          fontSize: 14,
                          paddingBottom: 10,
                          fontFamily: "Roboto",
                        }}
                      >
                        Enhance your investment experience
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "#410DAA",
                          fontSize: 14,
                          fontWeight: "700",
                          fontFamily: "Roboto",
                        }}
                      >
                        Learn and Test your knowledge
                      </Text>
                    </View>
                  </View>
                ) : (
                  <View>
                    <View>
                      <Text
                        style={{
                          color: "#464E5F",
                          fontSize: 14,
                          paddingBottom: 10,
                          fontFamily: "Roboto",
                        }}
                      >
                        Upgrade to Advisory Circle
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "#410DAA",
                          fontSize: 14,
                          fontWeight: "700",
                          fontFamily: "Roboto",
                        }}
                      >
                        Enhance your investment experience
                      </Text>
                    </View>
                  </View>
                )}
              </View>
              {!isMobile && (
                <View>
                  <Button
                    uppercase={false}
                    mode="contained"
                    labelStyle={{
                      color: "#410DAA",
                      fontWeight: "600",
                    }}
                    onPress={() => {
                      this.props.navigation.navigate("App", {
                        screen: "Account",
                        params: {
                          screen: "AdvisoryPlan",
                          params: {
                            cip_pk_for_renewal: null
                          },
                        },
                      });
                    }}
                    style={{
                      marginTop: 1,
                      marginHorizontal: 12,
                      borderColor: "#410DAA",
                      borderWidth: 1,
                      backgroundColor: "white",
                      borderRadius: 6,
                      height: 37,
                      width: 140,
                    }}
                  >
                    Upgrade Now
                  </Button>
                </View>
              )}
            </View>
            {isMobile && (
              <View>
                <Button
                  uppercase={false}
                  mode="contained"
                  labelStyle={{
                    color: "#410DAA",
                    fontWeight: "600",
                  }}
                  onPress={() => {
                    this.props.navigation.navigate("App", {
                      screen: "Account",
                      params: {
                        screen: "AdvisoryPlan",
                        params: {
                          cip_pk_for_renewal: null
                        },
                      },
                    });
                  }}
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    borderColor: "#410DAA",
                    borderWidth: 1,
                    backgroundColor: "white",
                    borderRadius: 6,
                    marginLeft: 5,
                    width: 150,
                  }}
                >
                  Upgrade Now
                </Button>
              </View>
            )}
          </TouchableOpacity>
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  subContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: isMobile ? "flex-start" : "space-between",
    alignItems: "center",
  },
  subContainerOne: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  stripContainer: {
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "rgba(65, 13, 170, 0.3)",
    height: isMobile ? 180 : 100,
    width: isMobile ? "102.3%" : "107.5%",
    padding: 10,
  },
});
