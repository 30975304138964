import React, { useEffect, useState } from "react";
import {
    View,
    ActivityIndicator,
    Text,
    StyleSheet
} from 'react-native';
import { isMobile } from ".";

const investmentQuotes = [
    "Invest for the long haul; patience pays. – - Rakesh Jhunjhunwala",
    "Avoid herd mentality; think independently. – - Radhakishan Damani",
    "Investing: Where knowledge trumps luck. – - Ramesh Damani",
    "Buy when others fear, sell when others cheer. – - Warren Buffett",
    "Stocks: Your shares in real businesses. – - Peter Lynch",
    "Investing is art, not rocket science. – - Warren Buffett",
    "Market timing: A fool's errand. – - Benjamin Graham",
    "Risk management: The key to success. – - Raghuram Rajan",
    "Invest in what you understand deeply. – - Warren Buffett",
    "Diversify: Don't put all eggs in one basket. – - Dhirubhai Ambani",
    "In stocks, quality beats quantity. – - Rakesh Jhunjhunwala",
    "Fear and greed move markets, but wisdom prevails. – - Warren Buffett",
    "Stocks: Where business meets balance sheets. – - Ramesh Damani",
    "Invest in yourself first—knowledge pays the best dividends. – - Warren Buffett",
    "Market noise: Ignore, stay focused. – - Radhakishan Damani",
    "Investment success: Discipline over emotion. – - Rakesh Jhunjhunwala",
    "In stocks, compounding is the eighth wonder. – - Warren Buffett",
    "Risk is not knowing what you're doing. – - Warren Buffett",
    "Stocks: Where value outweighs price. – - Philip Fisher",
    "Stocks: Buy when you love the business, not just the price. – - Warren Buffett",
    "Successful investing: It's a marathon, not a sprint. – - Dhirubhai Ambani",
    "In the market, ego is your enemy. –  - Ramesh Damani",
    "Investing: The path from 'can't' to 'can'. –  -Warren Buffett",
    "Stocks: Buy what you'd be happy to hold forever. – - Warren Buffett",
    "Risk comes from not knowing what you're doing. – - Warren Buffett",
    "Investing: The intersection of opportunity and preparedness. – - Raghuram Rajan",
    "In stocks, think like an owner, not a speculator. – - Warren Buffett",
    "Stocks: Seek companies with economic moats. – - Warren Buffett",
    "Investing: Where you find freedom through financial intelligence. – - Robert Kiyosaki",
    "Investment: Understand risks before chasing rewards. – - Warren Buffett",
    "Stocks: Where money meets opportunity, and patience meets profits. – - Anonymous",
    "Investing is about planting seeds today to reap fruits tomorrow. – - Anonymous",
    "In stocks, wisdom is your guide, emotions your adversary. – - Peter Lynch",
    "Investing: Where time is your ally, not your enemy. – - Anonymous",
    "Markets fluctuate; discipline endures. – - John Bogle",
    "Investing is building wealth one decision at a time. – - Warren Buffett",
    "In the market, volatility creates opportunities for the patient. – - Anonymous",
    "Investing is like chess: Think moves ahead, not just today. – - Anonymous",
    "Success in stocks: Mindset matters more than the market. – - Anonymous",
    "Investing: Turning dreams into dividends. – - Robert Kiyosaki",
    "Stocks: The bridge between dreams and financial independence. – - Anonymous",
    "In investing, focus on what you can control: your decisions. – - Warren Buffett",
    "Investing is the art of turning risk into reward. – - Robert Kiyosaki",
    "Stocks: Where preparation meets opportunity. – - Seneca",
    "In the market, patience is the greatest virtue. – - Anonymous",
    "Investing is like a marathon; success requires endurance. – - Anonymous",
    "Investment: Be a lifelong learner; the market evolves constantly. – - Charlie Munger",
    "Stocks: Where knowledge compounds your wealth. – - Warren Buffett",
    "In investing, fear and greed are your constant companions. – - Warren Buffett",
    "Investing is a journey; stay the course despite the detours. – - Anonymous",
    "Stocks: Where you write the story of your financial future. – - Anonymous",
    "Investing: Building a bridge to your financial dreams. – - Anonymous",
    "In the market, there's always a story; focus on fundamentals. – - Peter Lynch",
    "Investing is like planting trees: Patience leads to a fruitful harvest. – - Anonymous",
    "Stocks: Where timing is less important than time in. – - Anonymous",
    "Investing: Where you create a better future, one choice at a time. – - Warren Buffett",
    "In the market, there's no substitute for knowledge and experience. – - Charlie Munger",
    "Investing is like a puzzle; every piece contributes to the picture. – - Anonymous",
    "Stocks: Where you write the script of your financial destiny. – - Anonymous",
    "Investing: The process of compounding knowledge and wealth. – - Anonymous"
  ];
  

const ProgressBar = ({msg}) => {
    const [quote, setQuote] = useState(investmentQuotes[Math.abs(Math.floor(Math.random() * (0 - 60 + 1)) + 0)].split("–")[0]);
    const [auth, setAuth] = useState(investmentQuotes[Math.abs(Math.floor(Math.random() * (0 - 60 + 1)) + 0)].split("–")[1]);
    useEffect(()=>{
        const timerInterval = setInterval(() =>{
        let val = Math.floor(Math.random() * (0 - 60 + 1)) + 0;
        // console.log(val);

        let data = investmentQuotes[Math.abs(val)].split("–");
        setQuote(data[0]);
        setAuth(data[1]);
        }, 5000);
        return () => clearInterval(timerInterval);
    })
    return (
        <View style={styles.container} >
            <ActivityIndicator size="large" color={"#410DAA"||"#fff"}/>
            <Text style={styles.textStyle}>{msg}</Text>
            <View style={{marginTop: 100,marginHorizontal: 40, justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                <Text style={{
                    fontWeight: "bold",
                    color: "#929292",
                }}>{quote}</Text>
                <Text style={{
                    fontWeight: "bold",
                    color: "#929292",
                    marginTop: 20
                }}>{auth}</Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    textStyle: {
        fontSize: 20,
        color: "#929292",
        fontWeight: "bold",
        marginTop: 30,
    }
})

export default ProgressBar;