import React from "react";

import {
  View,
  ScrollView,
  SafeAreaView,
  StyleSheet,
  Platform,
  Dimensions,
} from "react-native";
import { Button, Text } from "react-native-paper";

import { connect } from "react-redux";
import amplitude from "../../../utils/amplitude";
import { getFinplanDetails, postIncomeDetails } from "../../../api/finplan";
import TextInputField from "../../../components/Common/Inputs/InputField";
import { updateLastSeenScreen } from "../../../actions/finplan";
import { isMobile } from "../../../utils";
import NavigationHeader from "../../../components/Common/NavigationHeader";
const { width } = Dimensions.get("window");

class IncomeDetails extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let type = null;
    if (params) {
      type = params.type || null;
    }
    this.state = {
      type,
      isLoading: false,
      monthlyIncome: 0,
      monthlyExpense: 0,
    };
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("incomeDetails", { path: global.location.href });
      } else {
        amplitude.logEvent("incomeDetails", {
          path: this.props.navigation.getState().routeName,
        });
      }
      const response = await getFinplanDetails();
      if (response.code == 100) {
        const { results } = response;
        const { income, expense } = results;
        this.setState({
          monthlyIncome: income.toString(),
          monthlyExpense: expense.toString(),
        });
      }
    } catch (error) {}
  };

  handleContinue = async () => {
    const { type } = this.state;
    if (this.state.isLoading) return;
    let { monthlyIncome, monthlyExpense, incomeError, expenseError } =
      this.state;
    if (monthlyIncome < 10000 || monthlyIncome > 2000000) {
      incomeError = "Income should be in range 10K - 20L";
    }
    if (monthlyExpense < 10000 || monthlyExpense > 2000000) {
      expenseError = "Expense should be in range 10K - 20L";
    }

    if (!incomeError && !expenseError) {
      this.setState({ isLoading: true, expenseError: null });

      const payload = {
        income: monthlyIncome,
        expense: monthlyExpense,
      };
      let screen = "MaritalStatus";
      if (type == "RETIREMENT") {
        screen = "WhenRetire";
      }

      const response = await postIncomeDetails(payload);
      if (response.code == 100) {
        this.setState({ isLoading: false });
        const { params = {} } = this.props.route;
        const { from, type, ...restParams } = params;
        this.props.navigation.navigate(screen, {
          from,
          type,
          ...restParams,
        });
      }
    }
  };

  render() {
    const { monthlyExpense, expenseError, monthlyIncome, incomeError } =
      this.state;
    return (
      <SafeAreaView style={styles.page}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Income Details"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <View
          style={
            isMobile
              ? { justifyContent: "center", alignItems: "center" }
              : { marginLeft: "30%" }
          }
        >
          <ScrollView
            showsVerticalScrollIndicator={true}
            keyboardShouldPersistTaps="handled"
            style={{
              paddingHorizontal: 16,
            }}
          >
            <View style={styles.continaer}>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  prefix="₹ "
                  label="Monthly Income"
                  value={monthlyIncome}
                  onChange={(amount) => {
                    let incomeError;
                    if (
                      !(amount != "" && amount >= 10000 && amount <= 2000000)
                    ) {
                      incomeError =
                        "Income should be in range 10 thousand - 20 lakhs rupees";
                    }
                    this.setState({
                      monthlyIncome: amount,
                      incomeError,
                    });
                  }}
                  // style={[{ ...styles.inputItem }]}
                  editable={true}
                />
              </View>
              <Text
                style={[
                  styles.errorMessage,
                  { paddingVertical: 0, marginVertical: 0 },
                ]}
              >
                {incomeError || ""}
              </Text>

              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  prefix="₹ "
                  label="Monthly Expense"
                  value={monthlyExpense}
                  onChange={(amount) => {
                    let expenseError;
                    if (
                      !(amount != "" && amount >= 10000 && amount <= 2000000)
                    ) {
                      expenseError =
                        "Expenses should be in range 10 thousand - 20 lakhs rupees";
                    }
                    this.setState({
                      monthlyExpense: amount,
                      expenseError,
                    });
                  }}
                  // style={[{ ...styles.inputItem }]}
                  editable={true}
                />
              </View>
              <Text
                style={[
                  styles.errorMessage,
                  { paddingVertical: 0, marginVertical: 0 },
                ]}
              >
                {expenseError || ""}
              </Text>
            </View>
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={this.handleContinue}
              style={styles.nextButton}
              loading={this.state.isLoading}
              color={"#410DAA"}
            >
              <Text style={styles.next}>Next</Text>
            </Button>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingBottom: 66,
    width: width,
    height: "100%",
  },
  formItem: {},
  inputItem: {
    width: isMobile ? "100%" : "auto",
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  nextButton: {
    width: width < 768 ? "96%" : "15%",
    marginLeft: isMobile ? 5 : "7%",
    marginTop: 30,
    marginBottom: 0,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  errorMessage: {
    color: "red",
    fontSize: 14,
    paddingVertical: 5,
    fontFamily: "Roboto",
  },
});

const mapStateToProps = (state) => ({
  user: state.userData.userProfileData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateLastSeenScreen: (type, screen, basic, end) =>
      dispatch(updateLastSeenScreen(type, screen, basic, end)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomeDetails);
