import React from 'react';
import { TextInput, View } from 'react-native';
import { Text } from 'react-native-paper';
import numberFormat from '../../utils/numberFormat';

export default class CurrencyFormatInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getInnerRef = () => this.myInput;

    render() {
        const {
            prefix,
            value,
            onValueChange,
            containerStyle,
            textStyle,
            onSubmitEditing,
            editable,
            limit
        } = this.props;
        
        return (
            <View
                style={[
                {
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "#F8F8F8",
                    paddingHorizontal: 10,
                    width: 200
                },
                containerStyle
                ]}
            >
                <Text
                style={{
                    color: "#183443"
                }}
                >
                {prefix}
                </Text>
                <TextInput
                editable={editable && editable == false ? false : true}
                ref={c => (this.myInput = c)}
                underlineColorAndroid="transparent"
                style={{
                    color: "#183443",
                    fontSize: 18,
                    width: "100%",
                    paddingVertical: 4,
                    ...textStyle
                }}
                onChangeText={amount => {
                    let error = false;
                    if (amount != "") {
                    let removed_commas = amount.replace(/[,|-]/g, "");
                    if (isNaN(removed_commas)) {
                        error = true;
                    } else {
                        amount = parseInt(removed_commas);
                    }
                    } else {
                    amount = 0;
                    }
                    // console.log("limit", limit, amount);
                    if (limit && amount.toString().length > limit) return;
                    else onValueChange(amount, error);
                }}
                value={value != "" ? numberFormat(value) : 0}
                keyboardType="numeric"
                onSubmitEditing={() => {
                    onSubmitEditing();
                }}
                returnKeyType="next"
                />
            </View>
        );
    }
}