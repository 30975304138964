import axiosApiInstance from "./apiConfig";
import { URLS } from "./urls";

export const getFinplanDetails = async (data) => {
  const result = await axiosApiInstance.post(URLS.FINPLAN_DETAILS, data);
  return result;
};

export const postBasicDetails = async (data) => {
  const result = await axiosApiInstance.post(URLS.BASIC_DETAILS, data);
  return result;
};

export const postIncomeDetails = async (data) => {
  const result = await axiosApiInstance.post(URLS.INCOME_DETAILS, data);
  return result;
};

export const postMaritalStatus = async (data) => {
  const result = await axiosApiInstance.post(URLS.MARITAL_STATUS, data);
  return result;
};

export const postChildDetails = async (data) => {
  const result = await axiosApiInstance.post(URLS.CHILDREN_DETAILS, data);
  return result;
};

export const postChildBachelors = async (data) => {
  const result = await axiosApiInstance.post(URLS.CHILDREN_BACHELORS, data);
  return result;
};

export const postChildPG = async (data) => {
  const result = await axiosApiInstance.post(URLS.CHILDREN_PG, data);
  return result;
};

export const postChildWedding = async (data) => {
  const result = await axiosApiInstance.post(URLS.CHILDREN_WEDDING, data);
  return result;
};

export const postPlaceToStudy = async (data) => {
  const result = await axiosApiInstance.post(URLS.PLACE_TO_STUDY, data);
  return result;
};

export const postWhenBike = async (data) => {
  const result = await axiosApiInstance.post(URLS.WHEN_BUY_BIKE, data);
  return result;
};

export const postWhenCar = async (data) => {
  const result = await axiosApiInstance.post(URLS.WHEN_BUY_CAR, data);
  return result;
};

export const postWhenHome = async (data) => {
  const result = await axiosApiInstance.post(URLS.WHEN_BUY_HOME, data);
  return result;
};

export const postWhenRetire = async (data) => {
  const result = await axiosApiInstance.post(URLS.WHEN_RETIRE, data);
  return result;
};

export const finplanSummarySave = async (data) => {
  const result = await axiosApiInstance.post(URLS.FINPLAN_SUMMARY_SAVE, data);
  return result;
};

export const resetFinplan = async (data) => {
  const result = await axiosApiInstance.post(URLS.RESET_FINPLAN, data);
  return result;
};

export const getMFMinDetailsStatus = async (user_id) => {
  const url = URLS.MF_MIN_DETAILS + (user_id || "");
  const result = await axiosApiInstance.get(url);
  return result;
};
