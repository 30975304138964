import React from "react";
import {
  View,
  SafeAreaView,
  StatusBar,
  FlatList,
  StyleSheet,
  Platform,
  TouchableOpacity,
} from "react-native";
import { Text } from "react-native-paper";
import NavigationHeader from "../../components/Common/NavigationHeader";
import amplitude from "../../utils/amplitude";
import { SideHeading } from "../../components/Common/sideHeading";
import CustButton from "../../components/Common/Button";
import { ImportedPortfolioStatus } from "../../components/Common/ImportedPortfolioStatus";
import Icon from "@expo/vector-icons/Octicons";
import MIcon from "@expo/vector-icons/MaterialIcons";
import { uploadPDF } from "../../api";
import { selectOneFile } from "../../utils";

export default class ImportStocksPortfolio extends React.Component {
  constructor(props) {
    super(props);
    let file = null;
    const params = this.props.route;
    if (params) {
      file = params.params.file || null;
    }
    this.state = {
      data: [],
      isOTPValid: null,
      success: null,
      file,
    };
  }

  componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("importStocksPortfolio",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("importStocksPortfolio", { path: this.props.navigation.getState().routeName });
    }
    this.setState({});
  }

  static navigationOptions = () => ({
    tabBarVisible: false,
  });

  _uploadFile = async () => {
    this.setState({ isLoading: true });
    const { file } = this.state;
    if (!file) return;
    const name = file["name"];
    const type = file["type"];
    const uri = file["uri"];
    var body = new FormData();
    body.append("portfolio_type", "stocks");
    if (Platform.OS === "web") {
      body.append("portfolio_pdf", DataURIToBlob(uri), name);
    } else {
      body.append("portfolio_pdf", {
        uri,
        name,
        type,
      });
    }
    uploadPDF(body)
      .then((resp) => {
        if (resp.code == 100) {
          this.setState({ success: true });
        } else {
          this.setState({ success: false });
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  _header() {
    return (
      <NavigationHeader
        navigation={this.props.navigation}
        navigationHeader={["Import Stock Portfolio"]}
        onPressBack={() => {
          this.props.navigation.goBack();
        }}
      />
    );
  }

  _renderItem = ({ index }) => {
    return (
      <ImportedPortfolioStatus
        file="michaelscott@dundermifflin.com"
        status={"E-mail pending"}
        type={"12/12/2019"}
        key={index}
      />
    );
  };

  _listHeader = () => {
    return (
      <View style={{ flexDirection: "row", paddingBottom: 5 }}>
        <Text
          style={{
            flex: 0.5,
            textAlign: "left",
            color: "#707070",
            fontSize: 14,
          }}
        >
          Recent Uploads
        </Text>
        <Text
          style={{
            flex: 0.45,
            color: "#707070",
            fontSize: 14,
            textAlign: "right",
          }}
        >
          status
        </Text>
      </View>
    );
  };

  _isAlreadyExist = (emails, email) => {
    return (
      emails
        .map((item) => {
          return item["value"];
        })
        .indexOf(email) != -1
    );
  };

  _selectFile = (fileType) => {
    this.setState({ fileLoading: false });
    selectOneFile(fileType).then((res) => {
      if (res) {
        this.setState({ file: res });
      }
    });
  };

  _renderResultScreen = (status) => {
    const { file } = this.state;
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          paddingTop: 120,
        }}
      >
        <Text style={{ fontSize: 20, color: "#121212" }}>
          {status ? "Almost Done!" : "Oops!"}
        </Text>
        <Text style={{ fontSize: 14, color: "#121212", paddingTop: 10 }}>
          {status
            ? "Your Stock Portfolio will be updated in a short while."
            : "There seems to be a problem while uploading a file."}
        </Text>
        {file && file["name"] && (
          <View style={[localStyles.detailsContainer, { marginTop: 5 }]}>
            <Icon name="file" style={localStyles.fileIcon} />
            <Text style={localStyles.fileName}>
              {file["name"].substring(0, 20)}....
            </Text>
            <MIcon
              name={status ? "check-circle" : "error"}
              style={{
                fontSize: 16,
                paddingLeft: 15,
                color: status ? "#009105" : "#EF5D12",
              }}
            />
          </View>
        )}
        <View
          style={{
            width: "100%",
            paddingTop: 100,
            flex: 1,
            alignItems: "center",
          }}
        >
          <CustButton
            bgColor={"#410DAA"}
            textColor={"#fff"}
            style={{
              marginVertical: 5,
              width: "95%",
              paddingVertical: 15,
            }}
            onPress={() => {
              this.props.navigation.goBack();
            }}
            borderColor={"transparent"}
            buttonTextStyle={{
              fontSize: 14,
            }}
          >
            Done
          </CustButton>
        </View>
      </View>
    );
  };

  _downloadSample = () => {};

  _renderFile = () => {
    const { file } = this.state;
    return (
      <View style={{ paddingHorizontal: 16 }}>
        <View style={[localStyles.inputLabel, { width: "85%" }]}>
          <Text style={{ color: "grey  " }}>
            Upload your stock portfolio / NSDL statement in ' .xlsx, .pdf '
            format.
          </Text>
          <TouchableOpacity
            onPress={() => {
              this._downloadSample();
            }}
          >
            <Text
              style={{
                color: "#410DAA",
                fontWeight: "bold",
                fontSize: 13,
              }}
            >
              View Sample
            </Text>
          </TouchableOpacity>
        </View>
        {file && file["name"] && (
          <TouchableOpacity
            style={localStyles.detailsContainer}
            onPress={() =>
              this._selectFile([
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ])
            }
          >
            <Icon name="file" style={localStyles.fileIcon} />
            <Text style={localStyles.fileName}>
              {file["name"].substring(0, 20)}....
            </Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  render() {
    const { isLoading, success } = this.state;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff", maxWidth: 800 }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {success != null ? (
          <View style={{}}>{this._renderResultScreen(success)}</View>
        ) : (
          <View style={{ flex: 1 }}>
            {this._header()}
            <SideHeading
              title="Import Stock Portfolio"
              headingTitleStyle={{ fontSize: 20 }}
              style={{
                alignItems: "center",
              }}
            />
            {this._renderFile()}
            <View
              style={{ width: "100%", height: 30, backgroundColor: "#F6F6F6" }}
            ></View>

            <FlatList
              ref={(c) => (this._goalsList = c)}
              extraData={[]}
              ListHeaderComponent={this._listHeader}
              showsVerticalScrollIndicator={true}
              data={[]}
              contentContainerStyle={{
                paddingHorizontal: 16,
                paddingVertical: 10,
              }}
              renderItem={this._renderItem}
            />
            <View
              style={{
                position: "absolute",
                bottom: 10,
                paddingHorizontal: 16,
                width: "100%",
              }}
            >
              <CustButton
                bgColor={"#410DAA"}
                textColor={"#fff"}
                style={{
                  marginVertical: 5,
                  width: "100%",
                  paddingVertical: 15,
                }}
                onPress={() => {
                  this._uploadFile();
                }}
                isLoading={isLoading}
                borderColor={"transparent"}
                buttonTextStyle={{
                  fontSize: 14,
                }}
              >
                Upload File
              </CustButton>
            </View>
          </View>
        )}
      </SafeAreaView>
    );
  }
}

const localStyles = StyleSheet.create({
  detailsContainer: {
    flexDirection: "row",
    paddingHorizontal: 10,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 15,
  },
  fileIcon: { fontSize: 26, color: "#666666", alignSelf: "flex-start" },
  fileName: {
    borderBottomWidth: 1,
    borderBottomColor: "#666666",
    marginHorizontal: 10,
    fontSize: 11,
  },
  reUploadIcon: {
    fontSize: 20,
    color: "#666666",
    marginLeft: 10,
  },
  inputLabel: {
    color: "#BCBCBC",
    fontSize: 13,
    marginVertical: 0,
    paddingVertical: 0,
  },
  borderStyleBase: {
    width: 30,
    height: 45,
  },

  borderStyleHighLighted: {
    borderColor: "#410DAA",
  },

  underlineStyleBase: {
    width: 30,
    height: 45,
    borderWidth: 0,
    borderBottomWidth: 1,
    fontSize: 15,
    marginHorizontal: 0,
    paddingHorizontal: 0,
  },

  notCompleted: {
    color: "#707070",
    borderColor: "#707070",
  },

  validCode: {
    color: "#009105",
    borderColor: "#009105",
  },

  inValidCode: {
    color: "#EF5D12",
    borderColor: "#EF5D12",
  },

  underlineStyleHighLighted: {
    borderColor: "#410DAA",
  },
});
