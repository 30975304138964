import React from "react";
import { View, Text, StyleSheet } from "react-native";

const SideHeading = ({ title, style = [], textStyle = [] }) => {
  return (
    <View style={[styles.container, ...style]}>
      <Text style={[styles.title, ...textStyle]}>{title}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: "Roboto",
    color: "#212121",
    fontWeight: "bold",
    fontSize: 17,
  },
});

export default SideHeading;
