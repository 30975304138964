import React from "react";
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  ScrollView,
  Platform,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { md } from "../../../Responsive";
import Modal from "../../Common/Modal";
import NavigationHeader from "../../Common/NavigationHeader";
import Entypo from "@expo/vector-icons/Entypo";
import {
  getRebalanceStatus,
  getRebalanceRecommendationsDay1,
  getRebalanceRecommendationsDay2,
} from "../../../api";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../../api/apiConfig";
import { isJama, isMobile, numberWithCommas, reverseString } from "../../../utils";
import { MAIN_URL } from "../../../api/urls";
import { StackActions } from "@react-navigation/routers";
import Store from "../../../store";
const window = Dimensions.get("window");

const TableCell = ({
  value = null,
  subValue,
  style = {},
  children,
  info = [],
}) => {
  return (
    <View style={[{ zIndex: 1 }, ...style]}>
      {value != null ? (
        <>
          <Text
            style={[
              {
                flex: 1,
                fontSize: 14,
                color: "#121212",
                paddingHorizontal: 16,
                paddingRight: 16,
                zIndex: 1,
              },
              style,
            ]}
          >
            {value}
          </Text>
          {subValue && (
            <Text style={{ fontSize: 12, color: "#12121250", paddingLeft: 6 }}>
              {subValue}
            </Text>
          )}
        </>
      ) : (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Text
            style={[
              {
                flex: 1,
                fontSize: 14,
                color: "#464E5F",
                paddingHorizontal: 16,
                paddingRight: 16,
                zIndex: 1,
              },
              style,
            ]}
          >
            {value}
          </Text>
        </View>
      )}

      {children}
    </View>
  );
};

const BuyNextInfoModal = ({ onClose, data }) => {
  return (
    <View
      style={{
        backgroundColor: "#F6F6F6",
        paddingHorizontal: 11,
        paddingVertical: 9,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Text style={{ fontSize: 12, color: "#464E5F", fontWeight: "bold" }}>
          {data.sebi_reg_heading}
        </Text>
        <Entypo name="cross" size={17} color="black" onPress={onClose} />
      </View>
      <Text style={{ fontSize: 11, color: "#464E5F" }}>
        {data.sebi_reg_message}
        <Text style={{ fontStyle: "italic", color: "#410DAA" }}>
          {" "}
          {data.sebi_reg_date}
        </Text>
      </Text>
    </View>
  );
};
export default class InvestmentAdvisory extends React.Component {
  constructor(props) {
    super(props);
    const { params } = props.route;
    let productName, units;
    let userId = null;
    if (params) {
      productName = params.product;
      units = params.units;
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
    }
    if (
      Store.getState().dashboard &&
      Store.getState().dashboard.viewModeUserId &&
      Store.getState().dashboard.viewModeUserId.user_id
    ) {
      userId = Store.getState().dashboard.viewModeUserId.user_id;
    }
    this.state = {
      data: null,
      productName,
      units,
      userId,
    };
  }

  async componentDidMount() {
    try {
      const { productName = null, units } = this.state;
      if (!productName) {
        this.setState({ error: true, errorText: "Error! Invalid Product ID" });
        return;
      }
      if (!units) {
        this.setState({ error: true, errorText: "Error! Invalid Units ID" });
        return;
      }
      let response = {};
      if (this.state.userId) {
        response = await getRebalanceStatus(productName, this.state.userId);
      } else {
        response = await getRebalanceStatus(productName);
      }
      if (response && response.code == 100) {
        const { result } = response;
        let { status, type } = result;
        let responseData;
        let payload = {};
        if (Platform.OS == "android" || Platform.OS == "ios") {
          payload["mobile"] = true;
        }
        if (type == "CRDay1") {
          if (status == "DONE") {
            responseData = await getRebalanceRecommendationsDay2(
              productName,
              units,
              payload,
              this.state.userId
            );
          } else {
            responseData = await getRebalanceRecommendationsDay1(
              productName,
              units,
              payload,
              this.state.userId
            );
          }
        } else if (type == "CRDay2") {
          if ((status = "NOT DONE")) {
            responseData = await getRebalanceRecommendationsDay2(
              productName,
              units,
              payload,
              this.state.userId
            );
          }
        }

        if (responseData && responseData.code == 100) {
          let { result } = responseData;
          this.setState({
            data: {
              ...result,
            },
            status,
            type,
            showOh: result.show_oh,
          });
        } else {
          this.setState({
            error: true,
            errorText:
              responseData && responseData.msg
                ? responseData.msg
                : "Something went wrong!",
          });
        }
      }
    } catch (error) {
      this.setState({
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  confirmBuy = () => {
    this.setState({ showAlert: true });
  };

  renderTableHeader = () => {
    let { show_positions, Unit_balance } = this.state.data;
    return (
      <View
        style={[
          {
            flexDirection: "row",
            paddingVertical: 11,
          },
        ]}
      >
        <TableCell
          value={"Stock Name"}
          style={[
            {
              flex: 0.2,
              fontWeight: "bold",
              color: "#222225",
              color: "#222225",
              textAlign: "center",
            },
          ]}
        />
        <TableCell
          value={
            this.state.data.Unit_balance
              ? `Units provided to ${ isJama() ? "JamaWealth" : "MaxiomWealth" }`
              : "Latest Demat Units fetched from Broker"
          }
          style={[
            { flex: 0.25, fontWeight: "bold", color: "#222225" },
            { textAlign: "center", paddingLeft: Unit_balance ? 20 : 0 },
          ]}
        />
        {show_positions && (
          <TableCell
            value={
              <View style={{ paddingHorizontal: 16 }}>
                <Text
                  style={{
                    fontSize: 14,
                    color: "#464E5F",
                    textAlign: "center",
                  }}
                >
                  Positions
                </Text>
                {!isMobile && (
                  <Text style={{ fontSize: 9, color: "#464E5F" }}>
                    (orders placed today)
                  </Text>
                )}
              </View>
            }
            style={[
              {
                flex: md(window.width) ? 0.2 : 0.15,
                fontWeight: "bold",
                color: "#222225",
                alignItems: "center",
              },
            ]}
          />
        )}
        <TableCell
  style={[
    {
      flex: show_positions ? 0.15 : 0.2,
      fontWeight: "bold",
      color: "#222225",
    },
    { textAlign: "center" }, // Center alignment for the cell
  ]}
>
  <View
    style={{
      alignItems: "center", // Align both value and subValue horizontally
      justifyContent: "center", // Center them vertically
    }}
  >
    <Text style={{ fontWeight: "bold", color: "#222225" }}>
      Revised Qty
    </Text>
    <Text style={{ fontSize: 12, color: "#888888" }}>
      (after rebalancing)
    </Text>
  </View>
</TableCell>
        <TableCell
          value={"Action"}
          style={[
            {
              flex: show_positions ? 0.15 : 0.25,
              fontWeight: "bold",
              color: "#222225",
            },
            { textAlign: "center" },
          ]}
        />
      </View>
    );
  };

  renderSuggestionItem = ({ item, index }) => {
    let { show_positions } = this.state.data;

    return (
      <View>
        <View
          style={[
            {
              flexDirection: "row",
              paddingVertical: 7,
            },
          ]}
        >
          <TableCell style={[{ flex: 0.2 }]}>
            <View style={{ alignItems: "center" }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#464E5F",
                  fontWeight: "bold",
                  marginBottom: 4,
                  minWidth: 100,
                }}
              >
                {item.stock_symbol}
              </Text>
              <Text style={{ color: "#9FA2B4", fontSize: 12,alignItems:"center" }}>
                {item.isin}
              </Text>
            </View>
          </TableCell>
          <TableCell
            value={this.state.data.Unit_balance ? item.cur_qty : item.demat_qty}
            info={!this.state.data.Unit_balance ? item.show_info : []}
            style={[
              { flex: 0.25 },
              { textAlign: "center", alignItems: "center" },
            ]}
          />
          {show_positions && (
            <TableCell
              value={item.process_units}
              style={[
                { flex: md(window.width) ? 0.2 : 0.15 },
                { textAlign: "center" },
              ]}
            />
          )}
          <TableCell
            value={item.revised_qty}
            style={[
              { flex: show_positions ? 0.15 : 0.2 },
              { textAlign: "center" },
            ]}
          />
          <TableCell
            value={item.action}
            style={[
              { flex: show_positions ? 0.15 : 0.25 },
              { textAlign: "center" },
            ]}
          />
        </View>
      </View>
    );
  };

  viewPlanwiseSellRecommendations = () => {};

  viewDetails = () => {
    this.setState({ viewDetailsOpen: !this.state.viewDetailsOpen });
  };
  getIndianFormat = (str) => {
    str = str.split(".");
    return (
      str[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") +
      (str[1] ? "." + str[1] : "")
    );
  };

  roundToDecimals = (value, decimal) => {
    return this.getIndianFormat(value.toFixed(decimal));
  };

  render() {
    const { data, showAlert, showSuccessAlert, error, errorText, showOh } =
      this.state;
    const { productName } = this.state;

    const planName = productName === 'JWL' ? 'JEWEL Plan' : productName === 'SRK' ? 'SPARK Plan' : 'Plan';

    const isWeb = Platform.OS == "web";

    if (error) {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <View style={{ flex: 1, maxWidth: 1500 }}>
           
            <NavigationHeader
              navigation={this.props.navigation}
              navigationHeader={[planName, "Portfolio Rebalance"]}
              onPressBack={() =>
                this.props.navigation.navigate("InvestmentOverview")
              }
            />
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText || "Something went wrong!"}
              </Text>
            </View>
          </View>
        </View>
      );
    }

    return (
      <View style={{ flex: 1, alignItems: "center", backgroundColor: "#fff" }}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={[planName, "Portfolio Rebalance"]}
          onPressBack={() =>
            this.props.navigation.goBack()
          }
          style={{ width: "100%", maxWidth: 1500 }}
        />

        {showAlert && (
          <Modal
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View>
              <Text
                style={{
                  fontSize: 17,
                  fontWeight: "700",
                  color: "#464E5F",
                  marginBottom: 14,
                }}
              >
                Sell Orders
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#464E5F",
                  marginBottom: 35,
                }}
              >
                Did you place your sell orders successfully?
              </Text>

              <View style={styles.confirmButtonContainer}>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({
                      showAlert: false,
                      showSuccessAlert: false,
                    });
                  }}
                  underlayColor="white"
                  style={{ flex: 0.5 }}
                >
                  <View style={[styles.confirmButton, styles.confirmButtonNo]}>
                    <Text style={styles.noText}>No</Text>
                  </View>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showAlert: false, showSuccessAlert: true });
                  }}
                  underlayColor="white"
                  style={{ flex: 0.5 }}
                >
                  <View style={[styles.confirmButton, styles.confirmButtonYes]}>
                    <Text style={styles.yesText}>Yes</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        )}

        {showSuccessAlert && (
          <Modal
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
            containerStyle={{ marginHorizontal: 8 }}
          >
            <View>
              <Text
                style={{
                  fontSize: 18,
                  color: "#0BB783",
                  fontWeight: "bold",
                  marginBottom: 20,
                }}
              >
                80% Portfolio Rebalance Completed
              </Text>
              <Text
                style={{
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#121212",
                  maxWidth: 600,
                  marginBottom: 20,
                }}
              >
                Rebalance for 80% portfolio i.e.,{" "}
                <Text
                  style={{
                    color: "#121212",
                    fontWeight: "bold",
                    paddingLeft: 5,
                  }}
                >
                  ₹ 6,00,000
                </Text>{" "}
                completed successfully. Upload the Contract Notes to keep your
                portfolio updated.
              </Text>
              <Text
                style={{
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#121212",
                  marginBottom: 51,
                }}
              >
                Please execute remaining 20% portfolio rebalance tommorrow.
              </Text>

              <View style={styles.confirmButtonContainer}>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showSuccessAlert: false });
                  }}
                  underlayColor="white"
                >
                  <View
                    style={[
                      styles.confirmButton,
                      styles.confirmButtonYes,
                      { paddingHorizontal: 30 },
                    ]}
                  >
                    <Text style={styles.yesText}>Done</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        )}

        {data ? (
          <ScrollView style={[styles.mainContainer]}>
            {md(window.width) && (
              <View>
                <Text style={styles.portfolioValueText}>
                  Portfolio Value to Rebalance{" "}
                  <Text style={styles.portfolioValueAmount}>
                    ₹{" "}
                    {this.state.type == "CRDay1" &&
                    this.state.status == "NOT DONE"
                      ? numberWithCommas(data.sell_amount)
                      : numberWithCommas(data.buy_amount)}
                  </Text>
                </Text>
              </View>
            )}

            {/* <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 15,
              }}
            >
              <View style={styles.circle1}>
                <View style={styles.circle2}></View>
              </View>
              <Text style={styles.step}>
                {this.state.type == "CRDay1" && this.state.status == "NOT DONE"
                  ? "Step 1"
                  : "Step 2"}
              </Text>
            </View> */}
            <View style={styles.border}>
              <View
                style={{
                  flex: 1,
                  flexDirection: md(window.width) ? "row" : "column",
                  alignItems: "center",
                }}
              >
                {/* table summary */}
                <View style={styles.tableSummary}>
                  <View style={styles.amountContainer}>
                    <Text style={styles.amountTitle}>Rebalance Orders</Text>
                  </View>
                  <Text style={styles.completeList}>
                    Click the button to access your demat account and start
                    rebalancing
                  </Text>
                </View>
                <View style={styles.placeSellOrders}>
                  <TouchableOpacity
                    onPress={() => {
                      if (this.state.data.stocks.length == 0) return;
                      const { hash_key } = this.state.data;
                      if (hash_key) {
                        let token = getTokenFromStore();
                        token = reverseString(token);
                        const url =
                          MAIN_URL +
                          "/app/gwsc/placecrorder/" +
                          (hash_key + token + "/" + GATEWAY_ENVIRONMENT);
                        if (Platform.OS == "web") {
                          global.open(url, "_self");
                        } else {
                          this.props.navigation.dispatch(
                            StackActions.replace("App", {
                              screen: "Dashboard",
                              params: {
                                screen: "Dashboard",
                                params: {
                                  screen: "Gateway",
                                  params: {
                                    type: "PLACE_REBALANCE_ORDER",
                                    data: this.state.data,
                                    screen:
                                      "PortfolioRebalanceTransactionStatus",
                                  },
                                },
                              },
                            })
                          );
                        }
                      }
                    }}
                    underlayColor="white"
                    style={{}}
                  >
                    <View
                      style={[
                        styles.placeSellOrdersButton,
                        this.state.data.stocks.length == 0 && {
                          backgroundColor: "#888C9F",
                        },
                      ]}
                    >
                      <Text style={styles.placeSellOrdersButtonText}>
                        {this.state.type == "CRDay1"
                          ? this.state.data.Unit_balance
                            ? "Continue to Rebalance"
                            : "Continue to Rebalance"
                          : "Continue to Rebalance"}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>

              <View style={styles.expectedAmountContainer}>
                {md(window.width) ? (
                  <TouchableOpacity
                    style={styles.planwiseSellContainer}
                    onPress={this.viewPlanwiseSellRecommendations}
                  >
                    {/* <Text style={styles.planwiseSellText}>
                                                    {` View Planwise ${ this.state.type =="CRDay1" ?'Sell & ':""}Buy Recommendations >`}
                                                </Text> */}
                    <></>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    style={styles.planwiseSellContainer}
                    onPress={this.viewDetails}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Text style={styles.planwiseSellText}>View Details</Text>
                      <Entypo
                        name={
                          this.state.viewDetailsOpen
                            ? "chevron-up"
                            : "chevron-down"
                        }
                        size={18}
                        color="#410DAA"
                        style={{ paddingLeft: 6 }}
                      />
                    </View>
                  </TouchableOpacity>
                )}
              </View>
              <View>
                {(md(window.width) ||
                  (!md(window.width) && this.state.viewDetailsOpen)) && (
                  <ScrollView
                    horizontal={true}
                    contentContainerStyle={{ flexGrow: 1 }}
                  >
                    {/* table data */}

                    <FlatList
                      data={this.state.data.stocks}
                      // horizontal={true}
                      ref={(ref) => {
                        this.suggestionsTable = ref;
                      }}
                      renderItem={this.renderSuggestionItem}
                      keyExtractor={(item, index) => `suggestion_${index}`}
                      ListHeaderComponent={this.renderTableHeader}
                      style={{
                        paddingBottom: 15,
                        minWidth: 800,
                      }}
                    />
                  </ScrollView>
                )}
              </View>

              {((isMobile && this.state.viewDetailsOpen) ||
                md(window.width)) && (
                <View
                  style={{
                    backgroundColor: "#F3F6F9",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    paddingRight: isMobile ? 0 : 60,
                    marginTop: isMobile ? 5 : 0,
                    marginBottom: 30,
                    paddingVertical: 5,
                    paddingLeft: 16,
                  }}
                >
                  {!isMobile && (
                    <View style={{ flex: 0.75 }}>
                      <View style={styles.expectedAmount}>
                        {!!(data.sell_amount || data.buy_amount) && (
                          <Text style={styles.expectedAmountText}>
                            {data.sell_amount
                              ? `Estimated Sell ₹ ${this.roundToDecimals(
                                  data.sell_amount,
                                  2
                                )}    |    `
                              : ""}
                            {data.buy_amount
                              ? `Estimated Buy ₹ ${this.roundToDecimals(
                                  data.buy_amount,
                                  2
                                )}`
                              : ""}
                            {data.next_day_buy_value
                              ? "     |     Buy Next ₹" +
                                this.roundToDecimals(data.next_day_buy_value, 2)
                              : ""}
                            {data.next_day_buy_value && (
                              <Entypo
                                name={"info-with-circle"}
                                size={14}
                                color="#410DAA"
                                style={[
                                  { paddingLeft: 6 },
                                  Platform.OS == "web" && { cursor: "pointer" },
                                ]}
                                onPress={() => {
                                  this.setState({ openInfoModal: true });
                                }}
                              />
                            )}
                          </Text>
                        )}
                        {this.state.openInfoModal && (
                          <Modal
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <BuyNextInfoModal
                              data={this.state.data}
                              onClose={() => {
                                this.setState({ openInfoModal: false });
                              }}
                            />
                          </Modal>
                        )}
                      </View>
                    </View>
                  )}
                  {((isMobile && this.state.viewDetailsOpen) ||
                    md(window.width)) && (
                    <View style={{ flex: isMobile ? 1 : 0.25 }}>
                      <TouchableOpacity
                        onPress={() => {
                          if (this.state.data.stocks.length == 0) return;
                          const { hash_key } = this.state.data;
                          if (hash_key) {
                            let token = getTokenFromStore();
                            token = reverseString(token);
                            const url =
                              MAIN_URL +
                              "/app/gwsc/placecrorder/" +
                              (hash_key + token + "/" + GATEWAY_ENVIRONMENT);
                            if (Platform.OS == "web") {
                              global.open(url, "_self");
                            } else {
                              this.props.navigation.navigate("App", {
                                screen: "Dashboard",
                                params: {
                                  screen: "Portfolio",
                                  params: {
                                    screen: "ShowWebView",
                                    params: {
                                      url: url,
                                      onSuccess: function (
                                        navigation,
                                        results = {}
                                      ) {
                                        navigation.push("App", {
                                          screen: "Portfolio",
                                          params: {
                                            screen:
                                              "PortfolioRebalanceTransactionStatus",
                                            params: {},
                                          },
                                        });
                                      },
                                      onError: function (
                                        navigation,
                                        results = {}
                                      ) {
                                        navigation.push("App", {
                                          screen: "Dashboard",
                                          params: {
                                            screen: "Home",
                                            params: {},
                                          },
                                        });
                                      },
                                    },
                                  },
                                },
                              });
                            }
                          }
                        }}
                        underlayColor="white"
                      >
                        <View
                          style={[
                            styles.placeSellOrdersButton,
                            this.state.data.stocks.length == 0 && {
                              backgroundColor: "#888C9F",
                            },
                          ]}
                        >
                          <Text style={styles.placeSellOrdersButtonText}>
                            {this.state.type == "CRDay1"
                              ? this.state.data.Unit_balance
                                ? "Continue to Rebalance"
                                : "Continue to Rebalance"
                              : "Continue to Rebalance"}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              )}
            </View>

            {/* Notes */}
            <View style={styles.notesContainer}>
              {this.state.data.next_two_months_amount && (
                <Text style={[styles.note, styles.note1]}>
                  <Text style={styles.undeline}>Note1:</Text> Your scheduled
                  investments in next two months: ₹
                  {numberWithCommas(
                    this.state.data.next_two_months_amount.toFixed(2)
                  )}
                  . This will be factored to calculate the rebalancing sell
                  quantities, to avoid selling stocks we may buy in the near
                  future.
                </Text>
              )}
              {/* <View style={styles.note3}>
                <Text style={[styles.note]}>
                  <Text style={styles.undeline}>
                    Note{this.state.data.next_two_months_amount ? "2" : "1"}:
                  </Text>{" "}
                  Note on Rebalancing : As per SEBI regulations, when you make
                  sell transactions:
                </Text>
                <Text style={[styles.note, styles.notePoint]}>
                  1) 80% of the sell amount is available for purchases on the
                  same day.
                </Text>
                <Text style={[styles.note, styles.notePoint]}>
                  2) The remaining 20% will be available on the next working
                  day. You can still complete your rebalancing if you have other
                  funds available in your demat account
                </Text>
              </View> */}
              <Text style={[styles.note, styles.note2]}>
                <Text style={styles.undeline}>
                  {/* Note{this.state.data.next_two_months_amount ? "3" : "2"}: */}
                  Note2:
                </Text>{" "}
                You will receive a separate mail on the advisory view on the
                stocks. In case of any questions on advisory, please call your
                Relationship Manager only.
              </Text>

              {/* <TouchableOpacity onPress={()=>{}}>   
                                    <Text style={[styles.undeline,styles.learnmore]}>Learn More</Text>
                                </TouchableOpacity> */}
            </View>
          </ScrollView>
        ) : (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <ActivityIndicator size="large" color="#410DAA" />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    paddingLeft: md(window.width) ? 52 : 16,
    paddingRight: md(window.width) ? 52 : 16,
    paddingTop: 30,
    maxWidth: 1400,
  },
  confirmButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    justifyContent: "space-around",
    flex: 1,
  },
  confirmButton: {
    paddingHorizontal: 20,
    paddingVertical: 6,
    borderRadius: 6,
    alignItems: "center",
    flex: 1,
    marginHorizontal: 10,
  },
  confirmButton2: {
    // paddingHorizontal:20,
    paddingTop: 6,
    borderRadius: 6,
    width: "100%",
  },
  confirmButtonNo: {
    backgroundColor: "#fff",
    borderColor: "#410DAA",
    borderWidth: 1,
  },
  noText: {
    color: "#410DAA",
    fontSize: 15,
  },
  confirmButtonYes: {
    backgroundColor: "#410DAA",
  },
  yesText: {
    color: "#fff",
    fontSize: 15,
  },
  border: {
    borderColor: "#968E8E40",
    borderWidth: 1,
    borderRadius: 4,
    overflow: "hidden",
    // marginBottom: md(window.width) ? 0: 0,
    borderTopColor: "#410DAA",
    borderTopWidth: 4,
  },
  placeSellOrders: {
    flex: md(window.width) ? 0.25 : 1,
    justifyContent: "flex-start",
    paddingVertical: md(window.width) ? 0 : 35,
  },
  placeSellOrdersButton: {
    paddingHorizontal: md(window.width) ? 0 : 35,
    paddingVertical: md(window.width) ? 10 : 9,
    // alignItems: 'center',
    backgroundColor: "#410DAA",
    marginVertical: 2,
    borderRadius: 6,
    width: "100%",
    alignSelf: "flex-end",
  },
  placeSellOrdersButtonText: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#fff",
    textAlign: "center",
  },
  tableSummary: {
    paddingHorizontal: md(window.width) ? 25 : 8,
    paddingVertical: md(window.width) ? 19 : 8,
    flex: md(window.width) ? 0.7 : 1,
  },
  amountContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  amountTitle: {
    fontSize: md(window.width) ? 17 : 15,
    fontWeight: "700",
    color: "#000000",
  },
  amount: {
    fontSize: 17,
    fontWeight: "700",
    color: "#410DAA",
    fontStyle: "italic",
    paddingLeft: 5,
  },
  completeList: {
    paddingTop: 9,
    fontSize: md(window.width) ? 13 : 12,
  },
  button: {
    alignItems: "center",
    backgroundColor: "#410DAA",
    marginVertical: 2,
    borderRadius: 6,
  },
  button2: {
    alignItems: "center",
    backgroundColor: "#410DAA",
    marginVertical: 2,
    // borderRadius:6
  },
  buttonText: {
    textAlign: "center",
    paddingHorizontal: 30,
    paddingVertical: 12,
    color: "white",
    fontWeight: "700",
    fontSize: 14,
  },

  portfolioValueText: {
    fontSize: 16,
    color: "#212121",
    marginBottom: 28,
  },

  portfolioValueAmount: {
    fontSize: 16,
    color: "#410DAA",
    fontStyle: "italic",
    paddingLeft: 5,
    fontWeight: "700",
  },

  circle1: {
    width: 16,
    height: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#410DAA",
    alignItems: "center",
    justifyContent: "center",
  },
  circle2: {
    width: 10,
    height: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#410DAA",
  },
  step: { fontSize: 16, color: "#212121", marginLeft: 9, fontWeight: "bold" },
  expectedAmountContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: md(window.width) ? 35 : 5,
    paddingVertical: 10,
    backgroundColor: "#F3F6F9",
    alignItems: "center",
  },
  expectedAmount: {},
  expectedAmountText: {
    color: "#464E5F",
    fontSize: 12,
    fontWeight: "bold",
  },
  planwiseSellText: {
    fontSize: 12,
    color: "#410DAA",
  },

  notesContainer: {
    marginTop: 15,
    marginBottom: 50,
  },
  note: {
    fontSize: 12,
    color: "#12121290",
    fontStyle: "italic",
  },
  note1: {
    marginBottom: 20,
  },
  note2: {
    marginBottom: 20,
  },
  note3: {
    marginBottom: 20,
    color: "#121212",
  },
  undeline: {
    textDecorationLine: "underline",
    color: "#121212",
  },
  notePoint: {
    marginTop: 8,
  },
  planwiseSellContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
