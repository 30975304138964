import React from "react";
import { View, Text, Platform } from "react-native";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;
import { FontAwesome5 } from "@expo/vector-icons";
import moment from "moment";
import { isMobile } from "../../../../utils";

export const PieChartInvest = ({ height, data, amount, portfolio_as_of_date }) => {
  let colorArr = [];
  let valueArr = [];
  let pieChartData = [];

  data.forEach((item) => {
    if (item && item.title && item.value) {
      colorArr.push(item.color);
      valueArr.push(item.value);
      pieChartData.push({ name: item.title, data: item.value });
    }
  });
  if (!valueArr.length) {
    colorArr = ["#A9A9A9", "#A9A9A9", "#A9A9A9"];
    valueArr = [100, 0, 0];
  }

  const options = {
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: { enabled: false },
    colors: colorArr,
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
        },
      },
    },
    stroke: {
      width: 1,
      colors: colorArr,
    },
  };

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <View style={{ flex: 1, flexDirection: "row" }}>
        {Platform.OS == "web" ? (
          <View
            style={{
              flex: 1.1,
              overflow: "hidden",
              marginTop: -10,
              marginBottom: -25,
              marginLeft: -20,
            }}
          >
            <Donut
              options={options}
              series={valueArr}
              sliceColor={colorArr}
              type="donut"
              width="140"
            />
          </View>
        ) : (
          <View
            style={{
              flex: 1,
            }}
          >
            <Donut
              widthAndHeight={height || 150}
              series={valueArr}
              sliceColor={colorArr}
              doughnut={true}
              coverRadius={0.6}
              coverFill={"#FFF"}
              style={{ marginLeft: 6 }}
            />
          </View>
        )}
        <View
          style={{
            flex: 1.1,
            flexDirection: "column",
            paddingTop: 2,
            paddingLeft: isMobile ? 0 : "10%",
            paddingRight: isMobile ? "auto" : "10%",
          }}
        >
          <Text
            style={{
              fontSize: 18,
              fontWeight: "700",
              color: "#464E5F",
            }}
          >
            {amount}
          </Text>
          <View style={{ flexDirection: "row" }}>
            {/* <FontAwesome5
              name={"caret-up"}
              size={24}
              color={"#009105"}
              style={{ paddingTop: 3 }}
            /> */}
            <Text
              style={{
                fontSize: 13,
                fontWeight: "700",
                color: "#464E5F",
                color: "#009105",
                textAlign: "left",
                paddingTop: 6,
                paddingLeft: 2,
              }}
            >
              0.0%{" "}
              <Text
                style={{
                  paddingTop: 5,
                  fontSize: 13,
                  fontWeight: "400",
                  fontStyle: "italic",
                  color: "#878EA8",
                  paddingLeft: 5,
                }}
              >
                1 day
              </Text>
            </Text>
          </View>
          {
            portfolio_as_of_date ? (
              <View>
                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: "400",
                    color: "#878EA8",
                  }}
                >
                  {moment(new Date(portfolio_as_of_date)).format("DD MMM YYYY")}
                </Text>
              </View>  
            ) : (
              <View></View>
            )
          }
        </View>
      </View>
    </View>
  );
};
