import { isJama } from "../../utils"

const clients = [
    {
      image: '../../assets/upgradePlan/Landing/img1.png',
      name: 'Gautham Khanna',
      about: 'Vice President, IT MNC',
      review: `${ isJama() ? "Jama" : "Maxiom" }Maxiom direct mutual fund & zero commission/brokerage advisory model builds trust. It is refreshing.`,
      id: 1
    },
    {
      image: '../../assets/upgradePlan/Landing/img2.png',
      name: 'Nageswara Rao Vasireddy',
      about: 'Sales Leader, Pharma Industry',
      review: `${ isJama() ? "Jama" : "Maxiom" } financial advisors have made Financial Planning truly comprehensive and unbiased`,
      id: 2
    }
  
  ]
  export default clients