import * as React from "react";
import { StyleSheet } from "react-native";
import { Modal, Text } from "react-native-paper";
import ANTIcon from "@expo/vector-icons/AntDesign";

const CustomModal = ({
  visible = false,
  showCloseButton = true,
  closeModal = null,
  children = null,
  style = [],
  ...rest
}) => {
  const [visibility, setVisibility] = React.useState(visible);

  const showModal = () => setVisibility(true);
  const hideModal = () => setVisibility(false);

  const handleClosePress = () => {
    hideModal();
    closeModal && closeModal();
  };

  return (
    <Modal
      visible={visible}
      onDismiss={handleClosePress}
      contentContainerStyle={[styles.containerStyle]}
      style={[...style]}
    >
      {showCloseButton && (
        <ANTIcon
          name="close"
          color={"#12121280"}
          size={20}
          style={{
            position: "absolute",
            right: 6,
            top: 6,
            zIndex: 100,
          }}
          onPress={handleClosePress}
        />
      )}
      {children}
    </Modal>
  );
};

const styles = StyleSheet.create({
  containerStyle: {
    backgroundColor: "#fff",
    padding: 20,
    height: "auto",
    maxWidth: 600,
    minWidth: 220,
    borderRadius: 6,
    borderColor: "white",
    borderWidth: 1,
    alignSelf: "center",
  },
});
export default CustomModal;
