import React, { useState } from "react";
import { StyleSheet, View, Platform } from "react-native";
import { Text } from "react-native-paper";
import FAQComponent from "./faqComponent";
import global from "./globalStyles";
import faqs from "./faqData";

export default function FAQs() {
    const [openId, setOpenId] = useState("");
    
    function handlePress(id) {
        openId === id ? setOpenId("") : setOpenId(id);
    }

    return (
        <View style={styles.container}>
            <Text style={[styles.heading, global.arial]}>
                Frequently Asked Questions
            </Text>
            <View style={styles.faqsContainer}>
            {faqs.map(faq => (
                <FAQComponent
                    question={faq.question}
                    answer={faq.answer}
                    key={faq.id}
                    id={faq.id}
                    isOpen={faq.id === openId}
                    handlePress={handlePress}
                ></FAQComponent>
            ))}
            </View>
        </View>
    );
}
  
const styles = StyleSheet.create({
    container: {
        display: Platform.OS === "web" ? "flex" : undefined,
        flexDirection: Platform.OS === "web" ? "column" : undefined
    },
    heading: {
        fontWeight: "bold",
        marginTop: Platform.OS === "web" ? 60 : 40,
        fontSize: 20,
        textAlign: "center",
        color: "black"
    },
    faqsContainer: {
        marginTop: 15
    }
});  
