const { default: Modal } = require("./Modal");
import React from "react";
import {View,Text,TouchableOpacity, StyleSheet, Platform} from "react-native";
import { AntDesign } from '@expo/vector-icons';
import { isMobile } from "../../utils";

export const PopUp =({modal_data = {} ,onPressDone,onRetry, noButtonTitle=null, yesButtonTitle=null})=>{
    const { status ="ERROR",  title = "Error!", description = [], show_retry = false, cai_hash = null } = modal_data || {};
    return(
        <Modal style={[{justifyContent: 'flex-start', paddingTop: 200, alignItems: 'center'}]} containerStyle={{marginHorizontal:8,paddingHorizontal:42,paddingRight:20,paddingVertical:15}}>
            <View style={{minWidth: isMobile? 240: 500}}>
                {/* <AntDesign name="close" size={16} color="#00000050" style={{position:"absolute",top:0,right:0}} onPress={()=>{onPressDone()}}/> */}
                <Text style={{fontSize:18,color: status=="ERROR"?"#F64E60":"#0BB783",fontWeight:"bold", marginBottom:20}}>{title}</Text>
                <View style={{marginBottom:27}}>
                    {
                        description && description.length >0 && description.map((desc)=>{
                            return(
                                <Text style={{fontSize:15,fontWeight:"400",color:"#121212", maxWidth: 600, marginBottom:20 }}>
                                    {desc}
                                </Text>
                             )
                        })
                    } 
                </View>
                
                <View style={styles.confirmButtonContainer}>
                    {
                         (
                            <TouchableOpacity onPress={()=>onPressDone()} underlayColor="white">
                                <View style={[styles.confirmButton, styles.confirmButtonNo, !show_retry&&styles.confirmButtonYes, {paddingHorizontal:30}]}>
                                    <Text style={[styles.noText, !show_retry && styles.yesText]}>{ noButtonTitle || "Cancel"}</Text>
                                </View>
                            </TouchableOpacity>
                        )
                    }
                    {
                        (show_retry)&&(
                            <TouchableOpacity onPress={()=>onRetry(cai_hash)} underlayColor="white">
                                <View style={[styles.confirmButton,styles.confirmButtonYes,{paddingHorizontal:30}]}>
                                    <Text style={styles.yesText}>{ yesButtonTitle || "Retry"}</Text>
                                </View>
                            </TouchableOpacity>
                        )
                    }
                </View>
            </View>
        </Modal>        
    )
}

const styles = StyleSheet.create({
    modalContainer:{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'},
    mobileStyle: {
        position: Platform.OS =="web" ? "fixed" : "absolute",
        top:0
    },
    webStyle:{
        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0
    },
    title:{fontSize:17,fontWeight:"700",color:"#464E5F",marginBottom:14},
    description:{fontSize:14,fontWeight:"400",color:"#464E5F",marginBottom:35},
    confirmButtonContainer:{
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"center",
    },
    confirmButton:{
        borderRadius:4,
        borderColor:"#410DAA",
        borderWidth:1
    },
    confirmButtonNo:{
        backgroundColor:"#fff",
        marginRight:26
    },
    noText:{
        color:"#410DAA",
        fontSize:15,
        paddingHorizontal:35,
        paddingVertical:10,
        fontWeight:"bold"
    },
    confirmButtonYes:{
        backgroundColor:"#410DAA",
    },
    yesText:{
        color:"#fff",
        fontSize:15,
        paddingHorizontal:35,
        paddingVertical:10,
        fontWeight:"bold"
    },
})