import React from "react";
import { View, Text, StyleSheet, Platform, Dimensions, FlatList } from "react-native";
const Donut =
  Platform.OS == "web"
    ? require("react-apexcharts").default
    : require("react-native-pie-chart").default;
import { isMobile, numberWithCommas } from "../../../../utils";
import { FontAwesome5 } from "@expo/vector-icons";
import { md } from "../../../../Responsive";
const { width } = Dimensions.get("window");
const isWeb = md(width);

const Product = ({ data = null }) => {
    let percentage_list = [];
    let sliceColor = [];

    data["scheme_wise_values"].forEach((item) => {
        percentage_list.push(item.perc);
        sliceColor.push(item.color);
    })

    if (!data) return <View />;
    return (
        <View
            style={{
                borderWidth: 0.5,
                borderColor: "#cef1e6",
                borderRadius: 20,
                width: width < 1200 ? "97%" : "55%",
            }}
        >
            <View style={{ flexDirection: isMobile ? "column" : "row" }}>
                <View
                    style={{
                        color: "#494B69",
                        fontSize: 16,
                        backgroundColor: "#f3fbf9",
                        borderRadius: 20,
                        height: data["scheme_wise_values"].length > 4 ? 255 : 232,
                        alignSelf: "flex-start",
                        width: isMobile ? "100%" : 330,
                    }}
                >
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingHorizontal: 20,
                        }}
                    >
                        <View style={{ flexDirection: "column" }}>
                            <Text
                                style={{
                                    paddingTop: 20,
                                    fontSize: 14,
                                    fontWeight: "400",
                                    color: "#878EA8",
                                }}
                            >
                                Latest Value
                            </Text>
                            <Text
                                style={{
                                    paddingTop: 5,
                                    fontSize: 28,
                                    fontWeight: "700",
                                    color: "#464E5F",
                                }}
                            >
                                {`₹${numberWithCommas(parseInt(data.total_latest_value))}`}
                            </Text>
                        </View>
                    </View>
        
                    <View
                        style={{
                            height: 0.5,
                            marginTop: 20,
                            backgroundColor: "#92929250",
                            paddingHorizontal: 20,
                            marginLeft: 20,
                            marginRight: 20,
                        }}
                    />

                    <View
                        style={{ display: "flex", flexDirection: "row", marginTop: 12 }}
                    >
                        <View
                            style={{
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {Platform.OS == "web" ? (
                                <View
                                    style={{
                                        height: 220,
                                    }}
                                >
                                    <View
                                    style={{
                                        width: 180,
                                        height: 120,
                                        marginLeft: 10,
                                        overflow: "hidden",
                                    }}
                                    >
                                    <Donut
                                        options={{
                                        dataLabels: {
                                            enabled: false,
                                        },
                                        legend: {
                                            show: false,
                                        },
                                        tooltip: { enabled: false },
                                        colors: sliceColor,
                                        plotOptions: {
                                            pie: {
                                            donut: {
                                                size: "60%",
                                            },
                                            },
                                        },
                                        stroke: {
                                            width: 1,
                                            colors: sliceColor,
                                        },
                                        }}
                                        series={percentage_list}
                                        sliceColor={sliceColor}
                                        type="donut"
                                        width="160"
                                    />
                                    </View>
                                </View>
                            ) : (
                                <Donut
                                    widthAndHeight={100}
                                    series={percentage_list}
                                    sliceColor={sliceColor}
                                    doughnut={true}
                                    coverRadius={0.6}
                                    coverFill={"#FFF"}
                                />
                            )}
                        </View>
                        <View
                            style={{
                                flex: 1,
                                flexDirection: "column",
                                paddingTop: isMobile ? 20 : 0,
                                paddingHorizontal: 20,
                            }}
                        >
                            <FlatList
                                data={data["scheme_wise_values"]}
                                contentContainerStyle={{
                                alignItems: "flex-start",
                                }}
                                renderItem={({ item, index }) => {
                                return (
                                    <View
                                        style={{
                                            alignSelf: "flex-start",
                                            flexDirection: "row",
                                            width: "100%",
                                            marginTop: 4,
                                            marginBottom: 7.5,
                                        }}
                                        >
                                        <View
                                            style={{
                                            width: 16,
                                            height: 16,
                                            borderRadius: 2,
                                            backgroundColor: sliceColor[index],
                                            }}
                                        />
                                        <Text
                                            style={{
                                            fontSize: 13,
                                            color: "#494B69",
                                            paddingLeft: 5,
                                            }}
                                        >
                                            {item["title"]} {item["perc"].toFixed(1)}%
                                        </Text>
                                    </View>
                                );
                                }}
                            />
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        flex: 1,
                        flexDirection: "row",
                        paddingTop: isMobile ? 10 : 30,
                        paddingHorizontal: 20,
                        paddingBottom: isMobile ? 10 : 0,
                    }}
                >
                    <View style={{flex: 1}}>
                        <View style={{flexDirection: "row", justifyContent: "space-around"}}>
                            <View style={{ flexDirection: "column", alignItems: "center", }}>
                                <Text
                                    style={{
                                        paddingTop: 13,
                                        fontSize: 14,
                                        fontWeight: "400",
                                        color: "#878EA8",
                                    }}
                                >
                                    {data["scheme_wise_values"][1].title}
                                </Text>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >   
                                    <View style={{ flexDirection: "column", alignItems: "center", }}>
                                    <Text style={{fontSize: 10, color: "#878EA8"}}>Latest Value</Text>
                                    <Text
                                        style={{
                                            paddingTop: 5,
                                            fontSize: 14,
                                            fontWeight: "700",
                                            color: "#464E5F",
                                        }}
                                        >
                                            {data["scheme_wise_values"][1].value}
                                    </Text>
                                    </View>
                                    <View style={{paddingLeft: 20}}></View>
                                    <View style={{ flexDirection: "column", alignItems: "center", }}>
                                    <Text style={{fontSize: 10, color: "#878EA8"}}>Cash Value</Text>
                                    <Text
                                        style={{
                                            paddingTop: 5,
                                            fontSize: 14,
                                            fontWeight: "700",
                                            color: "#464E5F",
                                        }}
                                        >
                                            {data["scheme_wise_values"][1].cash}
                                    </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ flexDirection: "column", alignItems: "center", }}>
                                    <Text
                                        style={{
                                            paddingTop: 13,
                                            fontSize: 14,
                                            fontWeight: "400",
                                            color: "#878EA8",
                                        }}
                                    >
                                        {data["scheme_wise_values"][2].title}
                                    </Text>
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >   
                                        <View style={{ flexDirection: "column", alignItems: "center", }}>
                                        <Text style={{fontSize: 10, color: "#878EA8"}}>Latest Value</Text>
                                        <Text
                                            style={{
                                                paddingTop: 5,
                                                fontSize: 14,
                                                fontWeight: "700",
                                                color: "#464E5F",
                                            }}
                                            >
                                             {data["scheme_wise_values"][2].value}
                                        </Text>
                                        </View>
                                        <View style={{paddingLeft: 20}}></View>
                                        <View style={{ flexDirection: "column", alignItems: "center", }}>
                                        <Text style={{fontSize: 10, color: "#878EA8"}}>Cash Value</Text>
                                        <Text
                                            style={{
                                                paddingTop: 5,
                                                fontSize: 14,
                                                fontWeight: "700",
                                                color: "#464E5F",
                                            }}
                                            >
                                                {data["scheme_wise_values"][2].cash}
                                        </Text>
                                        </View>
                                    </View>
                                </View>
                        </View>
                        <View style={{flexDirection: "row", justifyContent: "space-around"}}>
                            <View style={{ flexDirection: "column", alignItems: "center", }}>
                                <Text
                                    style={{
                                        paddingTop: 13,
                                        fontSize: 14,
                                        fontWeight: "400",
                                        color: "#878EA8",
                                    }}
                                >
                                    {data["scheme_wise_values"][0].title}
                                </Text>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >   
                                    <View style={{ flexDirection: "column", alignItems: "center", }}>
                                    <Text style={{fontSize: 10, color: "#878EA8"}}>Latest Value</Text>
                                    <Text
                                        style={{
                                            paddingTop: 5,
                                            fontSize: 14,
                                            fontWeight: "700",
                                            color: "#464E5F",
                                        }}
                                        >
                                            {data["scheme_wise_values"][0].value}
                                    </Text>
                                    </View>
                                    <View style={{paddingLeft: 20}}></View>
                                    <View style={{ flexDirection: "column", alignItems: "center", }}>
                                    <Text style={{fontSize: 10, color: "#878EA8"}}>Cash Value</Text>
                                    <Text
                                        style={{
                                            paddingTop: 5,
                                            fontSize: 14,
                                            fontWeight: "700",
                                            color: "#464E5F",
                                        }}
                                        >
                                            {data["scheme_wise_values"][0].cash}
                                    </Text>
                                    </View>
                                </View>
                            </View>
                            {
                                data["scheme_wise_values"].length > 4 ? (
                                    <View style={{ flexDirection: "column", alignItems: "center", }}>
                                        <Text
                                            style={{
                                                paddingTop: 13,
                                                fontSize: 14,
                                                fontWeight: "400",
                                                color: "#878EA8",
                                            }}
                                        >
                                            {data["scheme_wise_values"][3].title}
                                        </Text>
                                        <View
                                            style={{
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >   
                                            <View style={{ flexDirection: "column", alignItems: "center", }}>
                                            <Text style={{fontSize: 10, color: "#878EA8"}}>Latest Value</Text>
                                            <Text
                                                style={{
                                                    paddingTop: 5,
                                                    fontSize: 14,
                                                    fontWeight: "700",
                                                    color: "#464E5F",
                                                }}
                                                >
                                                {data["scheme_wise_values"][3].value}
                                            </Text>
                                            </View>
                                            <View style={{paddingLeft: 20}}></View>
                                            <View style={{ flexDirection: "column", alignItems: "center", }}>
                                            <Text style={{fontSize: 10, color: "#878EA8"}}>Cash Value</Text>
                                            <Text
                                                style={{
                                                    paddingTop: 5,
                                                    fontSize: 14,
                                                    fontWeight: "700",
                                                    color: "#464E5F",
                                                }}
                                                >
                                                    {data["scheme_wise_values"][3].cash}
                                            </Text>
                                            </View>
                                        </View>
                                    </View>
                                ) : (null)
                            }
                        </View>
                    </View>
                    {/* <View style={{ flex: 1 }}>
                        <FlatList
                            data={data["scheme_wise_values"]}
                            contentContainerStyle={{
                            alignItems: "center",
                            }}
                            renderItem={({ item, index }) => {
                            if (item.title == "Total Cash") {
                                return null;
                            } 
                            return (
                                <View style={{ flexDirection: "column", alignItems: "center", }}>
                                    <Text
                                        style={{
                                            paddingTop: 13,
                                            fontSize: 14,
                                            fontWeight: "400",
                                            color: "#878EA8",
                                        }}
                                    >
                                        {item.title}
                                    </Text>
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >   
                                        <View style={{ flexDirection: "column", alignItems: "center", }}>
                                        <Text style={{fontSize: 10, color: "#878EA8"}}>Latest Value</Text>
                                        <Text
                                            style={{
                                                paddingTop: 5,
                                                fontSize: 14,
                                                fontWeight: "700",
                                                color: "#464E5F",
                                            }}
                                            >
                                             {item.value}
                                        </Text>
                                        </View>
                                        <View style={{paddingLeft: 20}}></View>
                                        <View style={{ flexDirection: "column", alignItems: "center", }}>
                                        <Text style={{fontSize: 10, color: "#878EA8"}}>Cash Value</Text>
                                        <Text
                                            style={{
                                                paddingTop: 5,
                                                fontSize: 14,
                                                fontWeight: "700",
                                                color: "#464E5F",
                                            }}
                                            >
                                                {item.cash}
                                        </Text>
                                        </View>
                                    </View>
                                </View>
                            );
                            }}
                        />
                    </View> */}
                </View>
            </View>
        </View>
    );
};


export const ProductSummary = ({ style, data, navigation}) => {
    // console.log(data);
    return (
      <View>
        <View style={[styles.container, { ...style }]}>
          <Product data={data} />
        </View>
      </View>
    );
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
      marginBottom: isMobile ? 0 : 40,
      flexDirection: isMobile ? "column" : "row",
      paddingHorizontal: isMobile ? 0 : 0,
      marginRight: isMobile ? 0 : 0,
    },
})