import React from "react";
import amplitude from "../../../utils/amplitude";
import {
  View,
  Text,
  ScrollView,
  ActivityIndicator,
  StyleSheet,
  Platform,
  Dimensions,
  Image,
  TouchableOpacity,
  TouchableHighlight,
  Switch,
} from "react-native";
import { md } from "../../../Responsive";
import DashboardField from "../DashboardField";
import { NavigatableStrip } from "../navigatableStrip";
const window = Dimensions.get("window");
import { PieChartDashboard } from "../PieChartDashboard";
import {
  getTransactionPopUp,
  getLandingPageDetails,
  getCustomerDetails,
} from "../../../api";
import { PopUp } from "../../Common/PopUp";
import {
  completeMinDetails,
  getUserDetails,
  isLaptopOrIpadPro,
  isMobile,
  numberWithCommas,
  reverseString,
  toFixed,
} from "../../../utils";
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from "../../../api/apiConfig";
import { MAIN_URL } from "../../../api/urls";
import { TopupStrip } from "../../Common/TopUpStrip";
import { Popable } from "react-native-popable";
import { SparkTeaser } from "../SparkTeaser";
import { AntDesign } from "@expo/vector-icons";
import ComplianceStrip from "../ComplianceStrip";
import ComplianceModal from "../ComplianceModal";
import { EquityPlans } from "../EquityPlans";
import FamilyPopUp from "../FamilyPopup";
import { StackActions } from "@react-navigation/routers";
import handleTopup from "../../../utils/handleTopup";
import { ShowToast } from "../../../components/Common/Toast";

const isWeb = md(window.width);

const chartConfig = {
  backgroundGradientFrom: "#1E2923",
  backgroundGradientFromOpacity: 0,
  backgroundGradientTo: "#08130D",
  backgroundGradientToOpacity: 0.5,
  color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
  strokeWidth: 2,
  barPercentage: 0.5,
  useShadowColorFromDataset: false,
};

export default class InvestOverviewWeb extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.route;
    let userId = null;
    let showFamilyAlert = null;
    let showToast = null;
    if (params) {
      showToast = params.showToast || null;
      userId = params.user_id || null;
      userId = isNaN(userId) ? null : userId;
      showFamilyAlert = params.f;
    }

    this.state = {
      pending: [],
      rebalanceButtonClickable: false,
      isLoading: true,
      isTopupLoading: true,
      userId,
      gettingMinDetails: true,
      minDetailsResponse: {},
      showFamilyAlert,
      showToast,
    };
  }

  async componentDidMount() {
    
    try {
      if (Platform.OS == "web"){
        amplitude.logEvent("InvestmentOverviewWeb",{ path: global.location.href })
      }
      else{
        amplitude.logEvent("InvestmentOverviewWeb", { path: this.props.navigation.getState().routeName });
      }
      const { params } = this.props.route;
      let topup, product, data;
      if (params) {
        topup = params.topup || null;
        data = params.data || null;
        product = params.product || null;
      }

      if (topup && data && product) {
        await handleTopup({
          amount: data,
          product,
        });
        return;
      }

      const { userId } = this.state;
      const responses = await Promise.all([
        getTransactionPopUp(),
        getLandingPageDetails(userId),
      ]);
      let actionItemID,
        showPopUp = false,
        modalData = null;
      const transactionPopUpResponse = responses[0];
      const landingPageResponse = responses[1];
      if (transactionPopUpResponse.code == 100) {
        if (transactionPopUpResponse.result.Show_pop_up) {
          let { cai_pk, modal_data } = transactionPopUpResponse.result;
          actionItemID = cai_pk;
          showPopUp = true;
          modalData = modal_data;
        }
      }

      if (landingPageResponse.code == 100) {
        const {
          piechart_data,
          portfolio_rebalance_data,
          upcoming_investments_data,
          stocks_summary,
          subscribed_products,
          equity_investment_plans,
        } = landingPageResponse.result;
        const {
          stock_inv,
          stock_latest,
          stock_gain,
          stock_abs_gain,
          committed_aum,
          stock_dividend,
          stock_xirr,
          customer_hey_name: first_name,
        } = stocks_summary.data;

        let showTeaserOf = null;
        if (subscribed_products.indexOf("SRK") == -1) {
          showTeaserOf = "SRK";
        } else {
          if (
            subscribed_products.indexOf("PRL") == -1 &&
            subscribed_products.indexOf("JWL") == -1
          ) {
            showTeaserOf = "JWL";
          }
        }

        this.setState(
          {
            showPopUp,
            modalData,
            piechart_data,
            portfolio_rebalance_data,
            upcoming_investments_data,
            current_investment: stock_inv,
            current_value: stock_latest,
            total_returns_value: stock_gain,
            total_returns_perc: stock_abs_gain,
            stock_xirr,
            committed_aum,
            stock_dividend,
            isLoading: false,
            first_name,
            subscribed_products,
            equity_investment_plans,
            showTeaserOf,
          },
          async () => {
            const customer_id = this.state.userId || null;
            let skipSubscription = false;
            if (this.state.userId) {
              const { skip_subscription } = await getCustomerDetails(
                this.state.userId
              );
              skipSubscription = skip_subscription;
            } else {
              const { skip_subscription } = getUserDetails();
              skipSubscription = skip_subscription;
            }
            const minDetailsResponse = await completeMinDetails(
              true,
              customer_id,
              false
            );
            this.setState({ minDetailsResponse, gettingMinDetails: false });
          }
        );
      } else {
        this.setState({
          isLoading: false,
          error: true,
          errorText: "Something went wrong!",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
        errorText: "Something went wrong!",
      });
    }
  }

  renderPendingItem = ({ item }) => {
    const ArrowBackIcon = require("../../../assets/JamaInvest/Button2x.png");

    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          padding: 20,
          backgroundColor: "#FFF8E085",
          borderRadius: 12,
          marginRight: 64,
        }}
      >
        <Image source={item.icon} style={{ width: 30, height: 40 }}></Image>
        <View style={{ marginLeft: 20, marginRight: 40 }}>
          <Text
            style={{
              fontWeight: "700",
              color: "#121212",
              fontSize: 16,
              marginBottom: 8,
            }}
          >
            {item.title}
          </Text>
          <Text
            style={{ fontWeight: "normal", color: "#6C7293", fontSize: 12 }}
          >
            {item.description}
          </Text>
        </View>
        <TouchableOpacity
          onPress={() => {
            this.props.navigation.push(item.route);
          }}
        >
          <Image
            source={ArrowBackIcon}
            style={{ width: 32, height: 32 }}
          ></Image>
        </TouchableOpacity>
      </View>
    );
  };

  closePopUp = async () => {
    this.setState({ showPopUp: false });
  };

  onRetry = (cai_hash) => {
    let token = getTokenFromStore();
    token = reverseString(token);
    global.open(
      MAIN_URL +
        "/app/gwsc/retryorder/" +
        (cai_hash + token) +
        "/" +
        GATEWAY_ENVIRONMENT,
      "_self"
    );
  };

  handleTopupClick = (data = {}) => {
    if (!this.state.gettingMinDetails) {
      this.setState({
        showComplianceAlert: true,
        complianceNavigationOptions: { ...data },
      });
    }
  };

  navigateToPortfolio = () => {
    let options = {};
    if (this.state.userId) {
      options["user_id"] = this.state.userId;
    }
    this.props.navigation.navigate("Holdings", { ...options });
  };

  handleDashboardChange = () => {
    this.props.navigation.dispatch(
      StackActions.replace("InvestmentOverview", {
        showToast: "true",
      })
    );
  };

  render() {
    const { navigation, pendingContractsCount } = this.props;
    const {
      showPopUp,
      modalData,
      portfolio_rebalance_data,
      upcoming_investments_data,
      current_investment,
      committed_aum,
      current_value,
      total_returns_value,
      total_returns_perc,
      error,
      errorText,
      isLoading,
      stock_dividend,
      topupHeight,
      isTopupLoading,
      subscribed_products,
      equity_investment_plans,
      showComplianceAlert,
      minDetailsResponse,
      gettingMinDetails,
      showTeaserOf,
      showFamilyAlert,
      stock_xirr,
      complianceNavigationOptions = {},
    } = this.state;

    let { piechart_data } = this.state;

    piechart_data &&
      piechart_data.forEach((item) => {
        item["titleValue"] = `${item.value ? item.value.toFixed(1) + "%" : ""}`;
      });

    if (error || isLoading) {
      return (
        <View style={{ flex: 1, flexDirection: "column", height: "100%" }}>
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            {isLoading && <ActivityIndicator size="large" color="#410DAA" />}
            {errorText && (
              <Text style={{ fontSize: 16, color: "#121212" }}>
                {errorText}
              </Text>
            )}
          </View>
        </View>
      );
    }

    return (
      <View style={styles.mainContainer}>
        {showPopUp && modalData && (
          <PopUp
            modal_data={modalData}
            onPressDone={this.closePopUp}
            onRetry={this.onRetry}
          />
        )}

        {showComplianceAlert && (
          <ComplianceModal
            forceLoad={true}
            navigation={this.props.navigation}
            route={this.props.route}
            isLoading={gettingMinDetails}
            minDetailsResponse={minDetailsResponse}
            redirectBackTo={"InvestmentOverview"}
            onClose={() => {
              this.setState({ showComplianceAlert: false });
            }}
            navigationOptions={complianceNavigationOptions}
            userId={this.state.userId}
          />
        )}

        {showFamilyAlert && (
          <FamilyPopUp
            description="Family Popup"
            onDismiss={() => {
              this.setState({ showFamilyAlert: false });
            }}
          />
        )}

        {this.state.showToast == "true" ? (
          <ShowToast
            showToast={true}
            type="status"
            title="You switched to old dashboard."
            duration="10"
          />
        ) : null}

        <ScrollView
          style={[styles.scrollView]}
          contentContainerStyle={styles.scrollViewContainerStyle}
        >
          {showTeaserOf &&
            minDetailsResponse &&
            minDetailsResponse.needCompletion == false && (
              <SparkTeaser
                style={{ width: "100%", height: "auto", marginBottom: 16 }}
                onClose={() => {
                  this.setState({ showTeaserOf: false });
                }}
                product={showTeaserOf}
              />
            )}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: isMobile ? 20 : -10,
              marginBottom: 21,
            }}
          >
            <View style={{ flex: 1, justifyContent: "flex-start" }}>
              <Text
                style={{
                  fontWeight: "700",
                  color: "#121212",
                  fontSize: 17,
                  marginTop: 6,
                }}
              >
                Direct Equity Overview
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-end",
                paddingTop: isMobile ? 0 : 10,
              }}
            >
              <Switch
                style={{ marginTop: 2 }}
                trackColor={{ false: "#BAC7F2", true: "#BAC7F2" }}
                thumbColor={"#410DAA"}
                activeThumbColor={"#410DAA"}
                onValueChange={this.handleDashboardChange}
                value={0}
              />
              <Text
                style={{
                  fontSize: 16,
                  color: "#121212",
                  fontWeight: "normal",
                  marginLeft: 16,
                  marginRight: isMobile ? 16 : 0,
                }}
              >
                {isMobile ? "New Dashboard" : "Switch to New Dashboard"}
              </Text>
            </View>
          </View>

          <ComplianceStrip
            navigation={this.props.navigation}
            route={this.props.route}
            isLoading={gettingMinDetails}
            minDetailsResponse={minDetailsResponse}
            userId={this.state.userId}
          />

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View style={styles.actionsContainer}>
              {/* View Transaction Status */}
              <TouchableHighlight
                onPress={() => {
                  let options = {};
                  if (this.state.userId) {
                    options["user_id"] = this.state.userId;
                  }
                  this.props.navigation.navigate("ViewTransactionStatus", {
                    ...options,
                  });
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    source={require("../../../assets/transaction_status.png")}
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 6,
                      resizeMode: "contain",
                    }}
                  />
                  <Text style={{ color: "#410DAA", fontSize: 15 }}>
                    View Transaction status
                  </Text>
                </View>
              </TouchableHighlight>
            </View>
          </View>

          {/* Pie Chart && Dashboard */}
          {piechart_data && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                overflow: "hidden",
                marginBottom: 30,
                backgroundColor: "#fff",
                borderRadius: 12,
                borderColor: "#410DAA30",
                borderWidth: 1,
              }}
            >
              <View style={{ flex: isLaptopOrIpadPro ? 0.33 : 0.25 }}>
                <PieChartDashboard
                  style={{ marginRight: 0, paddingRight: 0 }}
                  data={piechart_data}
                  chartConfig={chartConfig}
                  accessor={"value"}
                  legendContainerStyle={{ paddingRight: isWeb ? 0 : 15 }}
                />
              </View>
              <View
                style={{
                  flexDirection: isLaptopOrIpadPro ? "column" : "row",
                  flex: isLaptopOrIpadPro ? 0.67 : 0.75,
                  paddingLeft: isLaptopOrIpadPro ? 45 : 25,
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: isLaptopOrIpadPro ? 0.4 : 0.5 }}>
                    <DashboardField
                      title={"Planned"}
                      value={`₹${numberWithCommas(toFixed(committed_aum, 0))}`}
                      border={!isLaptopOrIpadPro}
                    />
                  </View>
                  <View style={{ flex: isLaptopOrIpadPro ? 0.6 : 0.5 }}>
                    <DashboardField
                      title={"Invested"}
                      value={`₹${numberWithCommas(
                        toFixed(current_investment, 0)
                      )}`}
                      border={!isLaptopOrIpadPro}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", flex: 1 }}>
                  <View style={{ flex: isLaptopOrIpadPro ? 0.4 : 0.4 }}>
                    <DashboardField
                      title={"Current Value"}
                      value={`₹${numberWithCommas(toFixed(current_value, 0))}`}
                      change={null}
                      border={!isLaptopOrIpadPro}
                    />
                  </View>
                  <View style={{ flex: isLaptopOrIpadPro ? 0.6 : 0.6 }}>
                    <DashboardField
                      title={"Total Returns"}
                      toolTip={
                        !!stock_dividend && (
                          <Popable
                            strictPosition={false}
                            position={isLaptopOrIpadPro ? "right" : "bottom"}
                            style={{ minWidth: 200 }}
                            content={
                              <View style={{ backgroundColor: "#12121270" }}>
                                <Text
                                  style={{
                                    color: "#fff",
                                    fontSize: 13,
                                    fontFamily: "Roboto",
                                    padding: 6,
                                  }}
                                >
                                  Includes stock dividends of ₹
                                  {numberWithCommas(toFixed(stock_dividend))}
                                </Text>
                              </View>
                            }
                          >
                            <AntDesign
                              name="infocirlce"
                              size={12}
                              color="#12121280"
                              style={{ paddingLeft: 5, paddingTop: 5 }}
                            />
                          </Popable>
                        )
                      }
                      value={`₹${numberWithCommas(
                        toFixed(total_returns_value, 0)
                      )}`}
                      change={total_returns_perc.toFixed(2)}
                      border={!isLaptopOrIpadPro}
                      containerStyle={{
                        paddingRight: 16,
                      }}
                    />
                  </View>
                </View>
                {!!stock_xirr && (
                  <View style={{ flexDirection: "row" }}>
                    <DashboardField
                      title={"XIRR"}
                      value={`${stock_xirr.toFixed(1)}%`}
                      border={!isLaptopOrIpadPro}
                    />
                  </View>
                )}
              </View>
            </View>
          )}

          {/* Upcoming investments, Rebalance strips and Top up strip */}
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              marginBottom: 31,
            }}
          >
            <View
              style={{ flex: 0.65 }}
              onLayout={(event) => {
                var { height } = event.nativeEvent.layout;
                this.setState({ isTopupLoading: false, topupHeight: height });
              }}
            >
              {upcoming_investments_data && (
                <NavigatableStrip
                  title={upcoming_investments_data.title}
                  description={
                    <Text>
                      {upcoming_investments_data.description}
                      <Text
                        style={{
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#410DAA",
                          fontStyle: "italic",
                        }}
                      >
                        {upcoming_investments_data.amount != null
                          ? ` ₹${numberWithCommas(
                              upcoming_investments_data.amount
                            )}`
                          : ""}
                      </Text>
                    </Text>
                  }
                  icon={require("../../../assets/JamaInvest/upcoming2.png")}
                  style={{ marginBottom: 31, paddingVertical: 34 }}
                  navigate={() => {
                    let options = {};
                    if (this.state.userId) {
                      options["user_id"] = this.state.userId;
                    }
                    this.props.navigation.push("UpcomingInvestments", {
                      ...options,
                    });
                  }}
                />
              )}
              {portfolio_rebalance_data && (
                <NavigatableStrip
                  title={portfolio_rebalance_data.title}
                  clickable={portfolio_rebalance_data.navigatable}
                  description={portfolio_rebalance_data.description}
                  icon={require("../../../assets/JamaInvest/rebalance.png")}
                  descriptionColor={"#EF5D12"}
                  style={{ paddingVertical: 34 }}
                  navigate={() => {
                    let options = {};
                    if (this.state.userId) {
                      options["user_id"] = this.state.userId;
                    }
                    this.props.navigation.push("RebalanceLandingPage", {
                      ...options,
                    });
                  }}
                />
              )}
            </View>
            {isTopupLoading ? (
              <View
                style={{
                  flex: 0.35,
                  height: 200,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator
                  size="large"
                  color="#410DAA"
                  style={{ alignItems: "center" }}
                />
              </View>
            ) : (
              <View style={{ flex: 0.35, height: topupHeight }}>
                <TopupStrip
                  noCompliance={
                    !gettingMinDetails && minDetailsResponse.needCompletion
                  }
                  minDetailsResponse={minDetailsResponse}
                  style={{ flex: 1, height: "100%" }}
                  navigation={this.props.navigation}
                  subscribed_products={subscribed_products}
                  onClick={this.handleTopupClick}
                  viewMode={this.state.userId}
                />
              </View>
            )}
          </View>

          {/* My Equity Investment Plans */}

          <Text
            style={{
              fontSize: 17,
              color: "#212121",
              marginBottom: 21,
              lineHeight: 20,
              fontWeight: "bold",
            }}
          >
            My Equity Investment Plans
          </Text>
          <EquityPlans
            style={{ flex: 1 }}
            navigation={this.props.navigation}
            data={equity_investment_plans}
            activePlans={subscribed_products}
            userId={this.state.userId}
          />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  scrollView: {
    flexGrow: 1,
    padding: md(window.width) ? 30 : 16,
    maxWidth: 1500,
  },
  scrollViewContainerStyle: {
    flexGrow: 1,
    justifyContent: "flex-start",
  },
  portfolioStocks: {
    marginRight: 20,
  },
  actionsContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 10,
  },
});
