import React, { useState } from 'react';
import { View, Text, StyleSheet, Dimensions, TouchableOpacity, TextInput, ActivityIndicator, Platform } from 'react-native';
import { md, xs, lg } from '../../Responsive';
const window = Dimensions.get("window");
const isWeb = lg(window.width);
import { Picker } from '@react-native-picker/picker';
import { getProductName, isMobile, reverseString, valueInWords, completeMinDetails, redirectToCompleteMinDetails } from '../../utils';
import { getHashFetchHoldingsForTopup, getHashKey } from '../../api';
import { GATEWAY_ENVIRONMENT, getTokenFromStore } from '../../api/apiConfig';
import { MAIN_URL, URLS } from '../../api/urls';
import { useEffect } from 'react';
import { StackActions } from '@react-navigation/routers';

const Circles = ({ style = {} }) => {
    return (
        <View style={[{ ...style, right: -30, top: -30 }, Platform.OS == "web" && { transform: [{ rotateY: '-23deg' }] }]}>
            <View style={{ backgroundColor: "#A163F510", width: 76, height: 76, alignItems: "center", justifyContent: "center", borderRadius: 38 }}>
                <View style={{ backgroundColor: "#A163F520", width: 46, height: 46, alignItems: "center", justifyContent: "center", borderRadius: 23 }} />
            </View>
        </View>
    )
}

export const TopupStrip = ({ style = {}, navigation, subscribed_products = [], onClick, noCompliance, viewMode = false }) => {
    let topupInput = null;
    const performanceIcon = require('../../assets/JamaInvest/performance2.png');
    const [topupAmount, setTopUpAmount] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [errorText, setErrorText] = useState("");
    useEffect(() => {
        Platform.OS == "web" && !xs(window.width) && topupInput && topupInput.focus();
    }, [subscribed_products]);

    const onChangeAmount = (amount) => {
        if (isNaN(amount)) return;
        setTopUpAmount(amount);
        errorText != "" && setErrorText("")
    }

    const validateTopUp = () => {
        return !isNaN(topupAmount) && topupAmount != null && topupAmount != 0;
    }

    const isValidData = () => {
        const isValid = validateTopUp();
        if (!selectedPlan) {
            setErrorText("Please select plan");
            setIsLoading(false);
            return false;
        };
        if (!isValid) {
            setErrorText("Please enter amount");
            setIsLoading(false);
            return false;
        }

        return true;
    }

    const proceedToInvest = async () => {
        try {
            if (viewMode) {
                return;
            }

            setIsLoading(true);
            const isValid = isValidData();
            if (!isValid) return;
            let url = MAIN_URL + "/app/gwsc/placeorder/";
            let url_payalod = "";

            if (topupAmount) {
                url_payalod += ("topup_" + topupAmount);
            }

            const payload = {
                cipamountstr: url_payalod,
                product: selectedPlan
            }

            const response = await getHashKey(payload);
            if (response.code == 100) {
                const { hash_key } = response.result;
                if (Platform.OS == "web") {
                    let token = getTokenFromStore();
                    token = reverseString(token);
                    url = url + encodeURIComponent(hash_key + token + "/" + GATEWAY_ENVIRONMENT);
                    setErrorText("")
                    global.open(url, "_self");
                } else {
                    navigation.dispatch(
                        StackActions.replace("App", {
                            screen: "Dashboard",
                            params: {
                                screen: "Dashboard",
                                params: {
                                    screen: "Gateway",
                                    params: {
                                        type: "PLACE_ORDER_TOPUP",
                                        data: response.result,
                                        screen: "TransactionStatus"
                                    }
                                }
                            },
                        }
                        ))
                }
                // setIsLoading(false);
            } else {
                let defaultErrorMessage = "Something went wrong!";
                if (response.code == 102) {
                    defaultErrorMessage = "Enter valid amount";
                }
                setIsLoading(false);
                const text = response && response.msg ? response.msg : defaultErrorMessage;
                setErrorText(text);
            }
        } catch (error) {
            setIsLoading(false);
            setErrorText("Something went wrong!");
        }
    }


    let selectProducts = [];
    selectProducts.push({ label: "Select Product*", value: "SEL" })
    if (subscribed_products.length != 0) {
        subscribed_products.forEach((key, index) => {
            const product = getProductName(key);
            selectProducts.push({ label: product, value: key });
        })
    }


    return (
        <View style={[style, styles.container, isMobile && styles.mobileContainer]}>
            <Circles style={{ position: "absolute", zIndex: 10 }} />

            <Text style={{ color: "#410DAA", fontSize: md(window.width) ? 16 : 14, fontWeight: "700", marginBottom: 9 }}>Top Up</Text>
            <Text style={{ color: "#464E5F", fontSize: 10, fontStyle: "italic", marginBottom: 20 }}>*Topup will be added, to your investment plan as per its terms*</Text>
            {/* <View>
                    <Text style={{fontSize:13, color:"#464E5F", marginBottom: 11}}>Select Product</Text>
                    <View style={{flexDirection:"row", alignItems:"center", justifyContent:"flex-start",marginBottom:0}}>
                       {
                           [1,2,3].map(item=>{
                               return(
                                <RadioButton
                                    PROP={[{key:"JEWEL",text:"JEWEL"}]}
                                    onPress={product=>{
                                        setSelectedProduct(product)
                                    }}
                                    value={selectedProduct}
                                    style={{}}
                                />
                               )
                           })
                       }

                    </View>
                </View> */}
            <Picker
                selectedValue={selectedPlan}
                onValueChange={(itemValue, itemIndex) => {
                    setSelectedPlan(itemValue)
                }}
                style={{
                    borderColor: "transparent",
                    color: "#464E5F",
                    marginBottom: 20,
                    backgroundColor: "#F9F6FF",
                    width: isMobile ? "100%" : "100%",
                    borderBottomColor: "#D8D8D8",
                    borderBottomWidth: 1,
                    paddingBottom: 5
                }}
            >
                {selectProducts.map(item => {
                    return (
                        <Picker.Item label={item.label} value={item.value} />
                    );
                })}
            </Picker>
            <TextInput
                value={topupAmount}
                placeholder="Enter Amount to Topup*"
                onChangeText={(amount) => { onChangeAmount(amount) }}
                keyboardType={"number-pad"}
                style={{ fontSize: 13, lineHeight: 30, paddingLeft: 10, color: "#464E5F", width: isMobile ? "100%" : "100%", borderBottomColor: "#D8D8D8", borderBottomWidth: 1 }}
            />
            {
                errorText ? (
                    <Text style={{ marginTop: 5, fontSize: 12, color: "red", marginBottom: isMobile ? 30 : 0, fontStyle: "italic" }}>{errorText || " "}</Text>
                ) : (
                    <Text style={{ marginTop: 5, fontSize: 12, color: "#464E5F", marginBottom: topupAmount ? 3 : 0, fontStyle: "italic" }}>{valueInWords(topupAmount) || " "}</Text>
                )
            }

            <View
                style={[isMobile ? styles.mobileButtonStyle : styles.webButtonStyle]}
            >
                <TouchableOpacity
                    onPress={() => {
                        if (noCompliance) {
                            const is_valid = isValidData();
                            if (is_valid) {
                                onClick && onClick({ data: topupAmount, product: selectedPlan, topup: true });
                            }
                        } else {
                            !isLoading && proceedToInvest()
                        }
                    }}
                    style={{
                        backgroundColor: "#fff",
                        paddingVertical: 11,
                        borderRadius: 6,
                        alignItems: "center",
                        alignSelf: "center",
                        borderColor: "#410DAA",
                        borderWidth: 1,
                        paddingHorizontal: isWeb ? 82 : 60
                    }}
                >
                    {
                        isLoading ?
                            <ActivityIndicator size="small" color="#410DAA" /> :
                            <Text style={{ fontWeight: "normal", fontSize: lg(window.width) ? 15 : 13, color: "#410DAA" }}>Topup Now</Text>
                    }
                </TouchableOpacity>
                <Text style={{ fontSize: 10, color: "#464E5F", marginTop: 5, alignSelf: "center", textAlign: "center" }}>For SIPs / Upcoming <Text style={[{ textDecorationLine: "underline", color: "#410DAA", paddingLeft: 6, fontWeight: "bold" }, Platform.OS == "web" && { cursor: "pointer" }]} onPress={() => { navigation.navigate("UpcomingInvestments") }}>Click Here</Text></Text>

            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        overflow: "hidden",
        height: "100%",
        backgroundColor: "#F9F6FF",
        borderRadius: 12, paddingBottom: 15, paddingTop: 13, paddingHorizontal: 20,
    },
    mobileContainer: {
        flex: 1
    },
    mobileButtonStyle: {
        position: "relative"
    },
    webButtonStyle: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 15
    }
})