import React from "react";
import {
  View,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  Platform,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Text, Button } from "react-native-paper";
import { isMobile } from "../../../utils";
import amplitude from "../../../utils/amplitude";
import moment from "moment";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { getNetCorpusAtRetirement } from "../../../api";

class NetCorpus extends React.Component {
  constructor(props) {
    super(props);
    let goalId = null;
    const { params } = this.props.route;
    if (params) {
      goalId = params.goalId || null;
    }
    this.state = {
      goalId,
      net_corpus: "",
      yearsToRetire: "",
      retirementYear: "",
      total_income_req_today: "",
      total_expense_req_today: "",
      total_income_req_at_ret: "",
      total_expense_req_at_ret: "",
      annual_income_cost_today: "",
      annual_expense_cost_today: "",
      annual_income_at_retirement: "",
      annual_expense_at_retirement: "",
    };
  }

  async componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("NetCorpus",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("NetCorpus", { path: this.props.navigation.getState().routeName });
    }
    let resp = await getNetCorpusAtRetirement();
    if (resp && resp.code && resp.code == "100") {
      this.setState({
        net_corpus: resp.result.net_corpus,
        yearsToRetire: resp.result.year_to_retire_txt,
        retirementYear: moment(resp.result.retirement_date_txt).format("YYYY"),
        total_income_req_today: resp.result.total_income_req_today.replace(
          " (today)",
          ""
        ),
        total_expense_req_today: resp.result.total_expense_req_today.replace(
          " (today)",
          ""
        ),
        total_income_req_at_ret: resp.result.total_income_req_at_ret.replace(
          " (at retirement)",
          ""
        ),
        total_expense_req_at_ret: resp.result.total_expense_req_at_ret.replace(
          " (at retirement)",
          ""
        ),
        annual_income_cost_today: resp.result.annual_income_cost_today.replace(
          " (today)",
          ""
        ),
        annual_expense_cost_today:
          resp.result.annual_expense_cost_today.replace(" (today)", ""),
        annual_income_at_retirement:
          resp.result.annual_income_at_retirement.replace(
            " (at retirement)",
            ""
          ),
        annual_expense_at_retirement:
          resp.result.annual_expense_at_retirement.replace(
            " (at retirement)",
            ""
          ),
      });
    }
  }

  handleContinue = async () => {
    this.props.navigation.push("GoalDetails", {
      goal_id: this.state.goalId,
    });
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Net Corpus"]}
          onPressBack={() => {
            this.props.navigation.goBack();
          }}
        />
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          style={styles.cardContainer}
          showsVerticalScrollIndicator={false}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                fontSize: 22,
                color: "#464E5F",
                fontWeight: "700",
                marginVertical: 10,
                marginLeft: 40,
              }}
            >
              Net corpus at time of retirement: {this.state.net_corpus}
            </Text>
          </View>
          <Text
            style={{
              fontSize: 17,
              color: "#515151",
              marginTop: 30,
              marginLeft: 40,
            }}
          >
            Retirement year:{" "}
            <Text
              style={{ fontWeight: "bold", color: "#000000", fontSize: 16 }}
            >
              {this.state.retirementYear}
            </Text>
          </Text>
          <Text
            style={{
              fontSize: 17,
              color: "#515151",
              marginTop: 10,
              marginLeft: 40,
            }}
          >
            Years to retirement:{" "}
            <Text
              style={{ fontWeight: "bold", color: "#000000", fontSize: 16 }}
            >
              {this.state.yearsToRetire}
            </Text>
          </Text>
          <View
            style={{ flexDirection: "row", marginLeft: "3%", marginTop: 40 }}
          >
            <View style={styles.tableContainer}>
              <View style={{ flex: 1, alignItems: "center" }}>
                <View
                  style={{
                    backgroundColor: "#f5f3fb",
                    width: "100%",
                    height: 40,
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Text style={styles.tableHeaderText}>Expenses</Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Text style={[styles.tableBodyText, { fontWeight: "700" }]}>
                    Expense Types
                  </Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Text style={styles.tableBodyText}>Annual expenses</Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Text style={styles.tableBodyText}>One time expense</Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Text style={styles.tableBodyText}>Gross expense corpus</Text>
                </View>
              </View>
              <View style={{ flex: 1, alignItems: "center" }}>
                <View
                  style={{
                    height: 33,
                    backgroundColor: "#f5f3fb",
                    width: "100%",
                    height: 40,
                    padding: 10,
                  }}
                >
                  <Text style={styles.tableHeaderText}></Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.tableBodyText, { fontWeight: "700" }]}>
                    Today
                  </Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.tableBodyText}>
                    {this.state.annual_expense_cost_today}
                  </Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.tableBodyText}>-</Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.tableBodyText}>
                    {this.state.total_expense_req_today}
                  </Text>
                </View>
              </View>
              <View style={{ flex: 1, alignItems: "center" }}>
                <View
                  style={{
                    height: 33,
                    backgroundColor: "#f5f3fb",
                    width: "100%",
                    height: 40,
                    padding: 10,
                    paddingTop: 13,
                    borderTopRightRadius: 10,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.push("RetirementCorpus");
                    }}
                  >
                    <Text
                      style={[
                        styles.tableHeaderText,
                        { color: "#410DAA", textAlign: "right" },
                      ]}
                    >
                      Manage
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    alignItems: "flex-end",
                  }}
                >
                  <Text style={[styles.tableBodyText, { fontWeight: "700" }]}>
                    At Retirement
                  </Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                    alignItems: "flex-end",
                  }}
                >
                  <Text style={styles.tableBodyText}>
                    {this.state.annual_expense_at_retirement}
                  </Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                    alignItems: "flex-end",
                  }}
                >
                  <Text style={styles.tableBodyText}>-</Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                    alignItems: "flex-end",
                  }}
                >
                  <Text style={styles.tableBodyText}>
                    {this.state.total_expense_req_at_ret}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.tableContainer}>
              <View style={{ flex: 1, alignItems: "center" }}>
                <View
                  style={{
                    backgroundColor: "#f5f3fb",
                    width: "100%",
                    height: 40,
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Text style={styles.tableHeaderText}>Income</Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Text style={[styles.tableBodyText, { fontWeight: "700" }]}>
                    Income Types
                  </Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Text style={styles.tableBodyText}>Annual income</Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Text style={styles.tableBodyText}>One time income</Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Text style={styles.tableBodyText}>Gross income</Text>
                </View>
              </View>
              <View style={{ flex: 1, alignItems: "center" }}>
                <View
                  style={{
                    height: 33,
                    backgroundColor: "#f5f3fb",
                    width: "100%",
                    height: 40,
                    padding: 10,
                  }}
                >
                  <Text style={styles.tableHeaderText}></Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.tableBodyText, { fontWeight: "700" }]}>
                    Today
                  </Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.tableBodyText}>
                    {this.state.annual_income_cost_today}
                  </Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.tableBodyText}>-</Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.tableBodyText}>
                    {this.state.total_income_req_today}
                  </Text>
                </View>
              </View>
              <View style={{ flex: 1, alignItems: "center" }}>
                <View
                  style={{
                    height: 33,
                    backgroundColor: "#f5f3fb",
                    width: "100%",
                    height: 40,
                    padding: 10,
                    paddingTop: 13,
                    borderTopRightRadius: 10,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.push("IncomePostRetirement");
                    }}
                  >
                    <Text
                      style={[
                        styles.tableHeaderText,
                        { color: "#410DAA", textAlign: "right" },
                      ]}
                    >
                      Manage
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    alignItems: "flex-end",
                  }}
                >
                  <Text style={[styles.tableBodyText, { fontWeight: "700" }]}>
                    At Retirement
                  </Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                    alignItems: "flex-end",
                  }}
                >
                  <Text style={styles.tableBodyText}>
                    {this.state.annual_income_at_retirement}
                  </Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                    alignItems: "flex-end",
                  }}
                >
                  <Text style={styles.tableBodyText}>-</Text>
                </View>
                <View style={styles.seperatorTwo}></View>
                <View
                  style={{
                    width: "100%",
                    padding: 10,
                    paddingTop: 13,
                    borderTopLeftRadius: 10,
                    alignItems: "flex-end",
                  }}
                >
                  <Text style={styles.tableBodyText}>
                    {this.state.total_income_req_at_ret}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <Button
            uppercase={false}
            mode={"contained"}
            onPress={this.handleContinue}
            style={styles.nextButton}
            color={"#410DAA"}
          >
            <Text style={styles.next}>Continue</Text>
          </Button>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  itemMargin: {
    paddingHorizontal: isMobile ? 16 : 0,
  },
  cardContainer: {
    flexDirection: "column",
    marginTop: 20,
  },
  nextButton: {
    width: "12%",
    marginTop: 50,
    marginLeft: 40,
    marginBottom: 100,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  detailsContainer: {
    borderWidth: 1,
    borderColor: "#1212121A",
    borderRadius: 10,
    marginLeft: "3%",
    marginTop: 20,
    width: "94%",
  },
  headingContainer: {
    paddingHorizontal: isMobile ? 16 : 19,
    paddingVertical: 19,
    flexDirection: isMobile ? "column" : "row",
    alignItems: isMobile ? "flex-start" : "center",
    justifyContent: "space-between",
  },
  tableContainer: {
    borderWidth: 1,
    borderColor: "#888C9F66",
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "47%",
    marginRight: "3%",
  },
  tableHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  tableHeaderText: {
    fontSize: 14,
    fontFamily: "Roboto",
    fontWeight: "700",
  },
  textContentView: {
    padding: 5,
    height: 23,
  },
  seperator: {
    width: 1,
    marginTop: 35,
    height: 111,
    backgroundColor: "#888C9F66",
  },
  seperatorTwo: {
    width: "100%",
    height: 1,
    backgroundColor: "#888C9F66",
  },
  tableBodyText: {
    fontSize: 14,
    fontFamily: "Roboto",
    fontWeight: "400",
    color: "#464E5F",
  },
});

export default NetCorpus;
