import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import CustButton from './Button';
import ANTIcon from "@expo/vector-icons/AntDesign";

export const ImportStock = ({
    title,
    description,
    sample,
    error,
    buttonTitle,
    buttonDescription,
    style,
    onPress,
    downloadSample,
    whyDoThis
}) => {
    return (
        <View style={[localStyles.form, { ...style }]}>
            <Text style={localStyles.inputLabel}>{title}</Text>
            <View
            style={{
                marginTop: 10,
                padding: 10,
                borderRadius: 10,
                borderWidth: 0.5,
                borderColor: "#BCBCBC"
            }}
            >
            {description.map(item => {
                return (
                <Text
                    style={{
                    fontSize: 13,
                    color: item.color || "#121212",
                    paddingBottom: 5
                    }}
                >
                    {item.text}
                </Text>
                );
            })}
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ flex: 0.5, alignItems: "flex-start" }}>
                {/* {sample && (
                    <Text
                    style={{
                        color: "#410DAA",
                        fontWeight: "bold",
                        fontSize: 13
                    }}
                    onPress={() => {
                        downloadSample();
                    }}
                    >
                    Download Sample
                    </Text>
                )} */}
                </View>
                <View style={{ flex: 0.5, alignItems: "flex-end" }}>
                {whyDoThis && (
                    <TouchableOpacity
                    style={{
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}
                    onPress={() => {
                        whyDoThis && whyDoThis();
                    }}
                    >
                    <Text style={{ color: "#a8a8a8", fontSize: 14 }}>
                        Why do this ?
                    </Text>
                    <ANTIcon
                        name="infocirlce"
                        color={"#a8a8a8"}
                        size={12}
                        style={{
                        paddingLeft: 10
                        }}
                        onPress={() => {
                        whyDoThis && whyDoThis();
                        }}
                    />
                    </TouchableOpacity>
                )}
                </View>
            </View>
            {/* </Label> */}
            <CustButton
                bgColor={"#410DAA"}
                textColor={"#fff"}
                style={{
                marginTop: 25,
                marginBottom: 5,
                width: "100%",
                paddingVertical: 15
                }}
                buttonText={buttonTitle}
                onPress={() => {
                onPress();
                }}
                borderColor={"transparent"}
                buttonTextStyle={{
                fontSize: 14
                }}
            />
            {buttonDescription && (
                <Text
                style={{
                    color: "#a8a8a8",
                    fontWeight: "normal",
                    fontSize: 13,
                    textAlign: "center"
                }}
                >
                {buttonDescription}
                </Text>
            )}
            </View>
            <Text
            style={{
                ...localStyles.errorText,
                paddingVertical: 0,
                marginVertical: 0,
                paddingLeft: 2
            }}
            >
            {error || ""}
            </Text>
        </View>
    )
}

const localStyles = StyleSheet.create({
    form: {},
    errorText: {
        color: "red",
        fontSize: 10,
        height: 16
    },
    formItem: {
        borderColor: "#410DAA",
        padding: 0,
        margin: 0
    },
  
    inputLabel: {
        color: "#121212",
        fontSize: 13,
        marginVertical: 0,
        paddingVertical: 0,
        fontWeight: "bold"
    }
});