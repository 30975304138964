import React from "react";

import {
  View,
  ScrollView,
  SafeAreaView,
  Platform,
  StyleSheet,
  Dimensions,
} from "react-native";
import { Button, Text } from "react-native-paper";

import { connect } from "react-redux";
import HorizontalRadio from "../../../components/Common/Radio/HorizontalRadio";
import { getFinplanDetails, postBasicDetails } from "../../../api/finplan";
import { validateInput } from "../../../utils/validateInput";
import TextInputField from "../../../components/Common/Inputs/InputField";
import { updateLastSeenScreen } from "../../../actions/finplan";
import { isMobile } from "../../../utils";
import amplitude from "../../../utils/amplitude";
import PickerFieldSelect from "../../../components/Common/Pickers/PickerFieldSelect";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import { StackActions } from "@react-navigation/native";
const { width } = Dimensions.get("window");

class BasicDetails extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props.route;
    let type;
    let from;
    let index;
    if (params) {
      type = params.type || null;
      from = params.from || null;
      index = params.index || null;
    }
    this.state = {
      type,
      isLoading: false,
      age: null,
      visibleModal: false,
      from,
      index
    };
  }

  componentDidMount = async () => {
    try {
      if (Platform.OS == "web") {
        amplitude.logEvent("basicDetails", { path: global.location.href });
      } else {
        amplitude.logEvent("basicDetails", {
          path: this.props.navigation.getState().routeName,
        });
      }
      const ages = this.generateAgeData(18, 80);
      const response = await getFinplanDetails();
      let name = null,
        age = null,
        gender = null;
      if (response.code == 100) {
        const { results } = response;
        age = results.age;
        name = results.name;
        gender = results.gender;
      }
      this.setState({
        isLoading: false,
        name,
        age,
        gender,
        ages,
      });
    } catch (error) {}
  };

  generateAgeData = (min, max) => {
    let ages = [];
    for (let i = min; i <= max; i++) {
      const num = i;
      ages.push({
        label: num.toString(),
        value: num,
      });
    }
    return ages;
  };

  handleContinue = async () => {
    if (this.state.isLoading) return;
    this.setState({ nameError: null, ageError: null, genderError: null });
    let { name, age, gender, type } = this.state;
    if (!name || (name && name.trim() == "")) {
      this.setState({ nameError: "Name cannot be empty" });
      return;
    }
    if (!age || age == "Select") {
      this.setState({ ageError: "Age cannot be empty" });
      return;
    }
    if (!gender) {
      this.setState({ genderError: "Gender cannot be empty" });
      return;
    }

    this.setState({ isLoading: true, visibleModal: false });
    const payload = {
      name,
      age: parseInt(age),
      gender,
    };
    const response = await postBasicDetails(payload);
    if (response.code == 100) {
      this.setState({ isLoading: false });
      const { params = {} } = this.props.route;
      const { from, type, ...restParams } = params;
      this.props.navigation.navigate("IncomeDetails", {
        from,
        type,
        ...restParams,
      });
    }
  };

  handleChange = (value, type, inputType) => {
    const error = validateInput(inputType, value);
    this.setState({
      [type]: value,
      [type + "Error"]: error,
    });
    return error;
  };

  render() {
    const { name, nameError, age = null, ageError, ages = [] } = this.state;

    return (
      <SafeAreaView style={styles.page}>
        <NavigationHeader
          navigation={this.props.navigation}
          navigationHeader={["Basic Details"]}
          onPressBack={() => {
            if(this.state.from) {
              // console.log("k")
              this.props.navigation.navigate("App", {
                screen: "Explore",
                // params: {}
              });
              this.props.navigation.goBack();
            } else if(this.state.from) {
              this.props.navigation.dispatch(StackActions.replace("App",{
                screen: "Explore",
                params: {
                  screen: this.state.from,
                  params: {
                    index: this.state.index
                  }
                }
              }))
            } else {
              this.props.navigation.goBack();
            //   this.props.navigation.navigate("App", {
            //   screen: "Dashboard",
            //   params: {
            //     screen: "Dashboard",
            //     params: {},
            //   },
            // });
          }
            // this.props.navigation.navigate("explore/mutualfunds");
          }}
        />
        <View
          style={
            isMobile
              ? { justifyContent: "center", alignItems: "center" }
              : { marginLeft: "30%" }
          }
        >
          <ScrollView
            showsVerticalScrollIndicator={true}
            keyboardShouldPersistTaps="handled"
            style={{
              paddingHorizontal: 16,
            }}
          >
            <View>
              <View style={[styles.formItem, styles.itemMargin]}>
                <TextInputField
                  label="Name"
                  value={name}
                  onChange={(value) => {
                    this.handleChange(value, "name", "text");
                  }}

                  // editable={false}
                />
              </View>
              <Text
                style={[
                  styles.errorMessage,
                  { paddingVertical: 0, marginVertical: 0 },
                ]}
              >
                {nameError || ""}
              </Text>
              <View style={[styles.formItem, styles.itemMargin]}>
                <PickerFieldSelect
                  data={ages}
                  value={age}
                  label="Your Age"
                  onChange={(value) => {
                    this.handleChange(value, "age", "select");
                  }}
                  pickerStyle={[{ ...styles.inputItem }]}
                  placeholder={{ value: null, label: "Select" }}
                />
              </View>
              <Text
                style={[
                  styles.errorMessage,
                  { paddingTop: 0, paddingBottom: 4, marginVertical: 0 },
                ]}
              >
                {this.state.ageError || ""}
              </Text>

              <HorizontalRadio
                title={"Gender"}
                style={{
                  paddingVertical: 0,
                  marginVertical: 0,
                }}
                selected={this.state.gender}
                radioButtonValues={["Male", "Female", "Other"]}
                values={["M", "F", "O"]}
                onSelect={(index, value) => {
                  if (value) {
                    this.setState({ genderError: null });
                  }
                  this.setState({ gender: value });
                }}
              />

              <Text
                style={[
                  styles.errorMessage,
                  { paddingVertical: 4, marginVertical: 0 },
                ]}
              >
                {this.state.genderError || ""}
              </Text>
            </View>
            <Button
              uppercase={false}
              mode={"contained"}
              onPress={this.handleContinue}
              style={styles.nextButton}
              loading={this.state.isLoading}
              color={"#410DAA"}
            >
              <Text style={styles.next}>Next</Text>
            </Button>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingBottom: 66,
    width: width,
    height: "100%",
  },
  formItem: {},
  inputItem: {
    width: isMobile ? "100%" : "auto",
  },
  itemMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  nextButton: {
    width: width < 768 ? "96%" : "15%",
    marginLeft: isMobile ? 5 : "7%",
    marginTop: 30,
    marginBottom: 0,
  },
  next: {
    color: "#fff",
    fontSize: 15,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  errorMessage: {
    color: "red",
    fontSize: 14,
    paddingVertical: 5,
    fontFamily: "Roboto",
  },
});

const mapStateToProps = (state) => ({
  user: state.userData.userProfileData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateLastSeenScreen: (type, screen, basic, end) =>
      dispatch(updateLastSeenScreen(type, screen, basic, end)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);
