import React, { Component } from "react";
import { View, TouchableOpacity, Text, StyleSheet } from "react-native";

export default class RadioButton extends Component {
  render() {
    const { PROP, onPress, value } = this.props;

    return (
      <View style={{ flexDirection: "column" }}>
        {PROP.map((res) => {
          const active = value === res.key;
          return (
            <View key={res.key} style={styles.container}>
              <TouchableOpacity
                style={[styles.radioCircle, active && styles.activeCircle]}
                onPress={() => {
                  onPress && onPress(res.key);
                }}
              >
                {active && <View style={styles.selectedRb} />}
              </TouchableOpacity>
              <Text style={styles.radioText}>
                {res.text} <Text style={styles.text1}>{res.text1}</Text>
                {res.text3}
              </Text>
            </View>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 15,
    alignItems: "center",
    flexDirection: "row",
    marginRight: 20,
  },
  radioText: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 16,
    color: "#464E5F",
    marginLeft: 5,
  },
  text1: {
    color: "#3699FF",
    textDecorationLine: "underline",
  },
  radioCircle: {
    height: 13,
    width: 13,
    borderRadius: 100,
    borderWidth: 2,
    borderColor: "#410DAA",
    backgroundColor: "#ffffff",
    alignItems: "center",
    justifyContent: "center",
  },
  activeCircle: {
    borderColor: "#410DAA",
  },
  selectedRb: {
    width: 5,
    height: 5,
    borderRadius: 100,
    backgroundColor: "#410DAA",
    alignItems: "center",
    justifyContent: "center",
  },
  result: {
    marginTop: 20,
    color: "white",
    fontWeight: "600",
    backgroundColor: "#F3FBFE",
  },
});
