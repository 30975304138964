import React, {useRef, useState} from 'react';
import { StyleSheet, Text, View, Button, ScrollView, Platform, Dimensions,TouchableOpacity, Image } from 'react-native';
import {sm, md, lg, xl, twoxl} from '../../../Responsive'
const window = Dimensions.get("window")


export default function Step({number, name, color, showLine=true}) {

    return (
        <View style={styles.container}>
            <View style={styles.bulletContainer}>
                <View style={[styles.circle, {borderColor: color}]}></View>

                {
                    showLine && (
                        <View style={styles.line}></View>
                    )
                }

            </View>
            <View style={styles.content}>
                <Text style={styles.name}>{name}</Text>
                <Text style={styles.number}> {`Step ${number}`}</Text>
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: md(window.width) ? 72: 16
    },
    content: {
        flexShrink: 1
    },
    bulletContainer: {
        marginRight: 18,
        display: 'flex',
        justifyContent:'flex-start',
        alignItems: 'center'
    },
    circle: {
        height: 16,
        width: 16,
        borderRadius: 99,
        borderColor: 'black',
        borderWidth: 3,
        marginTop: 2
    },
    line: {
        marginTop: 4,
        marginBottom: 3,
        width: 4,
        height: 70,
        backgroundColor: '#E5EAEE'
    },
    name: {
        fontFamily:"Roboto",
        fontWeight: 'bold',
        color: '#212121',
        fontSize: 14,
        lineHeight:20,
        color:"#18182B"

    },
    number: {
        fontFamily:"Roboto",
        color: '#212121',
        fontSize: 14,
        lineHeight:20,
        color:"#18182B",
        marginTop: 8
    }
})