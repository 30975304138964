import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, FlatList, TouchableOpacity, ScrollView } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { useSelector } from "react-redux";
import { getBSEOrdersPaymentStatus } from "../../../api/invest";
import FAIcon from "react-native-vector-icons/FontAwesome";
import NavigationHeader from "../../../components/Common/NavigationHeader";
import {
  CapitalizeWordsInSentence,
  isMobile,
  numberWithCommas,
} from "../../../utils";
import { ShowToast } from "../../../components/Common/Toast";

const SummaryField = ({ name, value, style }) => {
  return (
    <View style={[{ flex: 0.33 }, style]}>
      <Text
        style={{
          fontSize: 15,
          color: "#121212",
          fontWeight: "bold",
          marginBottom: 5,
        }}
      >
        {value}
      </Text>
      <Text
        style={{
          fontSize: 12,
          color: "#12121290",
          fontWeight: "200",
          fontStyle: "italic",
        }}
      >
        {name}
      </Text>
    </View>
  );
};

const FundSummaryField = ({ name, value, color }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "flex-start",
        marginVertical: 3,
      }}
    >
      <View>
        <Text
          style={{
            fontSize: 14,
            color: "#A8A8A8",
            paddingRight: 10,
          }}
        >
          {name}:
        </Text>
      </View>
      <Text
        style={{
          flexShrink: 1,
          fontSize: isMobile ? 12 : 14,
          color: color || "#121212",
        }}
      >
        {value}
      </Text>
    </View>
  );
};

const ProcessOrder = (props) => {
  const [loading, setLoading] = useState(true);
  const [ordersResponse, setOrdersReponse] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState(null);

  const { actions } = useSelector((state) => state.transactions);
  // console.log(actions);
  useEffect(() => {
    const getOrdersPaymentStatus = async () => {
      try {
        const { params } = props.route;
        if (params) {
          const { action_id } = params;
          if (actions.hasOwnProperty(action_id)) {
            const { orderdata, ...remainingData } = actions[action_id];
            // console.log(actions[action_id]);
            let orders = [];
            if (orderdata) {
              orders = orderdata.split("|").filter((order) => order);
            }
            // Calculate the total amount from all orders
            const totalAmount = remainingData.result.reduce((sum, order) => sum + parseFloat(order.amount), 0);

            if (orders.length) {
              const payload = {
                orders,
              };
              const response = await getBSEOrdersPaymentStatus(payload);
              if (
                response &&
                response.code == 100 &&
                response.results &&
                response.results.length != 0
              ) {
                let results = response.results.filter(
                  (item) => typeof item == "object"
                );
                results.forEach((order) => {
                  const index = remainingData.result.findIndex(
                    (item) => item.order_no == order.bse_order_number
                  );
                  if (index != -1) {
                    remainingData.result[index]["msg"] =
                      order["bse_payment_status"];
                  }
                });
              }
              if (response) {
                let toast_message = response.message + ". Please go to Orders tab in Notifications screen to view further details."
                setToastMsg(toast_message);
                setShowToast(true);
              }
            }
            const orderSummary = {
              total_items:
                remainingData.total +
                (" Order" + remainingData.total > 1 ? "s" : ""),
              amount: totalAmount.toFixed(2), // Use the calculated total amount
              order_placement: remainingData.order_placement,
            };
            setOrdersReponse({
              orderSummary,
              orders: [...remainingData.result],
            });
            setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getOrdersPaymentStatus();
  }, []);

  const handleOrderItem = ({ item: order, index }) => {
    return (
      <View
        style={[
          styles.fund,
          {
            borderColor: order.code == 100 ? "#a3a2a2" : "#EF5D12",
          },
        ]}
      >
        <View style={styles.amcDetails}>
          <View style={styles.amcImageColumn}>
            <View style={styles.amcImageContainer}>
              <FAIcon name={"institution"} color={"#A8A8A8"} size={20} />
            </View>
          </View>
          <View style={styles.amcTitleContainer}>
            <Text style={styles.amcTitle}>{order.scheme_name}</Text>
          </View>
          <View style={styles.investmentAmountAndType}>
            <View style={styles.investmentAmountAndTypeContainer}>
              <Text style={styles.investmentAmount}>₹{order.amount}</Text>
            </View>
          </View>
        </View>

        {order.code != 100 ? (
          <>
            <FundSummaryField
              name="Status   "
              value="Order Not Placed"
              color={"#EF5D12"}
            />
            <FundSummaryField
              name="Reason "
              value={CapitalizeWordsInSentence(order.msg)}
            />
          </>
        ) : (
          <>
            <FundSummaryField
              name="Status      "
              value={CapitalizeWordsInSentence(order.msg) || "-"}
              color={"#410DAA"}
            />
            <FundSummaryField name="Order No " value={order.order_no} />
          </>
        )}
      </View>
    );
  };

  const handleGoBack = () => {
    props.navigation.navigate("App", {
      screen: "Dashboard",
      params: { screen: "Explore" },
    });
  };

  return (
    <View style={styles.container}>
      <NavigationHeader
        navigation={props.navigation}
        navigationHeader={["Order Status"]}
        onPressBack={() => {
          props.navigation.goBack();
        }}
      />
      {
        showToast ? (
          <ShowToast
            showToast={true}
            type="status"
            title={toastMsg}
            duration="5"
          />
        ) : (null)
      }
      {loading ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#410DAA" />
        </View>
      ) : (
        <View style={styles.mainContainer}>
          {ordersResponse && (
            <ScrollView style={{marginBottom: 50}}>
              <View style={styles.orderSummary}>
                <SummaryField
                  name="Order Placement"
                  value={ordersResponse.orderSummary.order_placement}
                  style={{ alignItems: "flex-start" }}
                />
                <SummaryField
                  name="Total Items"
                  value={ordersResponse.orderSummary.total_items}
                  style={{ alignItems: "center" }}
                />
                <SummaryField
                  name="Amount Investing"
                  value={
                    "₹" +
                    numberWithCommas(
                      parseFloat(ordersResponse.orderSummary.amount).toFixed(2)
                    )
                  }
                  style={{ alignItems: "flex-end" }}
                />
              </View>
              <FlatList
                data={ordersResponse["orders"] || []}
                renderItem={handleOrderItem}
                keyExtractor={(item, index) => index.toString()}
                showsVerticalScrollIndicator={true}
                style={styles.flatList}
              />
              <View style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                {/* <View style={{marginTop: 10}}>
                  <Text>*If you have completed your payment, BSE will take some time to retrieve your payment status and update it.</Text>
                </View> */}
                <TouchableOpacity 
                  style={{
                    backgroundColor: '#410DAA',
                    width: 150,
                    padding: 15,
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 80,
                    borderRadius: 5
                  }}
                  onPress={()=> {
                    // console.log("I'm presses")
                    props.navigation.navigate("Dashboard",{
                        screen: "RateUs",
                        params: { action_id: "454" },
                    });
                  }}>
                  <Text style={{color: '#fff', fontWeight: '700'}}>Continue</Text>
                </TouchableOpacity>
              </View>
            </ScrollView>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  orderSummary: {
    flexDirection: "row",
    backgroundColor: "#fafafa",
    paddingHorizontal: 16,
    paddingVertical: 6,
    marginTop: 10,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  mainContainer: {
    flex: 1,
    // alignItems:  isMobile ? 'flex-start' : "center",
    paddingHorizontal: 16,
  },
  fund: {
    marginVertical: 10,
    padding: 8,
    borderColor: "#410DAA",
    borderWidth: 1,
    borderRadius: 5,
    maxWidth: 600,
    marginHorizontal: isMobile ? 0 : 16,
  },
  amcDetails: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 10,
  },
  amcImageColumn: {
    flex: 0.15,
    justifyContent: "center",
    alignSelf: "center",
  },
  amcImageContainer: {
    width: 45,
    height: 45,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    borderColor: "#410DAA1A",
    borderWidth: 0.7,
  },
  amcImage: {
    width: 40,
    height: 40,
    resizeMode: "contain",
  },
  amcTitleContainer: { flex: 0.65 },
  amcTitle: {
    color: "black",
    alignSelf: "flex-start",
    fontSize: isMobile ? 14 : 15,
    paddingLeft: 10,
  },
  investmentAmountAndType: {
    flex: 0.25,
    marginRight: 8,
    alignItems: "flex-end",
  },
  investmentAmountAndTypeContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  investmentAmount: {
    color: "black",
    fontWeight: "bold",
    fontSize: 13,
    textAlign: "center",
  },
  flatList: {
    marginTop: isMobile ? 15 : 30,
  },
});

export default ProcessOrder;
