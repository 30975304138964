
import React  from 'react';
import { View, Image, StyleSheet, Text, Dimensions, TouchableOpacity } from 'react-native';
import { Card } from 'react-native-paper';
import { isMobile } from '../../utils';
import flex from '../../styles/flex';
import styles from '../../styles/flex';
import { AntDesign } from '@expo/vector-icons';
const {width, height} = Dimensions.get("window");

const FamilyPopUp = (props) => {
    return(
        <View style={family.familyAlertContainer}>
            <View
                style={family.container}
            >
                <Card
                    style={[
                        family.body,
                        {
                            //boxShadow: "8px 9px 4px rgba(0, 0, 0, 0.25)",
                            borderRadius: 10
                        }
                    ]}

                >
                     <TouchableOpacity onPress={props.onDismiss} style={family.closeButton}>
                        <AntDesign size={16} color="#12121260" name="close"/>
                    </TouchableOpacity>
                    <View style={styles.mainContainer}>
                        <View style={family.headingContainer}>
                            <Image
                                source="https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/bi_exclamation-diamond-fill.png"
                                style={family.image}
                            />
                            <Text style={family.heading}>Tip for Family Accounts</Text>
                            
                        </View>
                        <Text style={family.description}>
                            Please ensure that the demat user logged in is for the correct family member. To avoid any conflict, Please use a different browser or device. 
                        </Text>
                        
                    </View>
                </Card>
            </View>
        </View>
    )
};


const family = StyleSheet.create({
    container:{
        maxWidth: isMobile ? "95%" : 633,
        flexWrap: "wrap",
    },
    closeButton: {
        position: "absolute",
        top: isMobile ? -4 : -5,
        right: isMobile ? 0 : -15,
    },
    body: {
        paddingHorizontal: isMobile ? 16: 32,
        paddingVertical: isMobile ? 16 : 22,
        paddingBottom: isMobile ? 16 : 28,
        width: "100%",
        height: "auto"
    },
    mainContainer:{ },
    headingContainer: {
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        maxWidth: isMobile ? "90%" : "100%",
        marginBottom: 13,
    },
    heading: {
        fontFamily: "Roboto",
        fontSize: 17,
        color: "#121212",
        fontWeight: "bold",
    },
    description: {
        fontFamily: "Roboto",
        fontSize: 15,
        color: "#121212",
        marginBottom: 5,
        marginLeft: 5
    },
    image: { width: isMobile ? 20 : 22, height: isMobile ? 20 : 22, resizeMode: "contain", marginRight: 6 },
    familyAlertContainer: {
        position: "absolute",
        width: width,
        height: height,
        width: "100%",
        top: 0,
        bottom: 0,
        justifyContent: 'center',
        backgroundColor: "#00000050",
        alignItems: "center",
        zIndex: 10,
    }  
});

export default FamilyPopUp;