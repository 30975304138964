import React from 'react';
import { View, Text, Platform,StyleSheet, ScrollView } from 'react-native';
import NavigationHeader from '../../components/Common/NavigationHeader';
import Table from '../../components/Common/Table';
import SearchBar from '../../components/Common/SearchBar';
import { formatDate, getUserDetails, isMobile, numberWithCommas } from '../../utils';
import { getPassbookForDate } from '../../api';
import { Picker } from '@react-native-picker/picker';
import { ActivityIndicator } from 'react-native-paper';
import amplitude from '../../utils/amplitude';
import TransactionType from './components/TransactionType';
import ProductName from './components/Product';
import StockName from './components/StockName';

class PassbookTransactionDetails extends React.Component {
    constructor(props){
        super(props);
        let userId = null, tradeDate, product, transactionType, clientId;
        const { params } = this.props.route;
        if(params){
            userId = params.user_id || null;
            userId = isNaN(userId) ? null : userId;

            clientId = params.client_id || null;
            clientId = isNaN(clientId) ? null : clientId;

            tradeDate = params.date || null;
            product = params.product || null;
            transactionType = params.type || null;
            
        }
        this.state={
            userId,
            clientId,
            tradeDate,
            product,
            transactionType
        }
    }

    componentDidMount = async () => {
        try {
            if (Platform.OS == "web"){
                amplitude.logEvent("transactionDetails",{ path: global.location.href })
              }
              else{
                amplitude.logEvent("transactionDetails", { path: this.props.navigation.getState().routeName });
              }
            let { 
                userId,
                clientId,
                tradeDate,
                product,
                transactionType
             } = this.state;
            let { customer_id } = getUserDetails();
            customer_id = userId || customer_id;
            this.setState({ loadingData: true });
            const payload = {
                "trade_date": tradeDate,
                "client_id": Number(customer_id),
                "product": product,
                "transaction_type": transactionType
            }
            console.log("payload", payload);
            let data = [];
            const response = await getPassbookForDate(payload);
            console.log(response);
            if(response && response.code == 100){
                const { result } = response;
                data = [ ...result ];
            }
            console.log(data);
            const searchResults = data;
            this.setState({ data , searchResults, loadingData: false });
        } catch (error) {
            console.log(error);
            this.setState({ loadingData: false });
        }
    }

    compareStrings = ( a, b) => {
        const x = a.toLowerCase();
        const y = b.toLowerCase();
        if (x < y) {
            return -1;
        }
        if (x > y) {
            return 1;
        }
        return 0;
    }

    compareNumbers = ( a, b) => {
        return a - b;
    }

    getSortedData = (data, sorting_field, sorting_value, start, end) => {
        if(data.length!=0){
            const fieldValue = data[0][sorting_field];
            const sortingType = isNaN(fieldValue) ? "string" : "number";

            data = data.slice(start,end);
            if(sortingType == "string"){
                data.sort((a,b)=>  this.compareStrings(a[sorting_field], b[sorting_field]))
            }  else{
                data.sort((a,b)=>  this.compareNumbers(a[sorting_field], b[sorting_field]));
            };

            return sorting_value ? data : data.reverse();
        } else {
            return data;
        }
    }

    handleSort = (field, sort, start, end) => {
        const sorting_field = field;
        const sorting_value = !sort;

        const { searchResults } = this.state;
        const sortedData = [].concat(
            searchResults.slice(0,start),
            this.getSortedData(searchResults, sorting_field, sorting_value, start, end ),
            searchResults.slice(end,searchResults.length)
        );

        console.log(field, sort, start, end);

        this.setState({sorting_field, sorting_value, searchResults: sortedData});
    }

    getHeaderData = (data) => {

        const { sorting_field, sorting_value } = this.state;
        
        const keysData = [
            {
                label: "Transaction Date",
                key: "trade_date"
            },
            {
                label: "Stock Name",
                key: "stock_name"
            },
            {
                label: "Product",
                key: "product"
            },
            {
                label: "Transaction Type",
                key: "transaction_type"
            },
            {
                label: "Quantity",
                key: "quantity"
            },
            {
                label: "Price",
                key: "price"
            },
            {
                label: "Amount",
                key: "amount"
            }
        ]

        let headerData = [];
        keysData.forEach( headerItem =>{
            headerData.push({
                label: headerItem.label,
                style: [styles.tableHeader],
                headerItemContainerStyle: [styles.headerItemContainerStyle],
                sortOption: false,
                // onSort: this.handleSort,
                key: headerItem.key,
                // currentSortField: sorting_field,
                // sortValue: sorting_value
            })
        });

        let values = [];

        data.forEach((value, index) => {
            let { 
                stock_name,
                symbol,
                isin,
                transaction_type,
                price,
                quantity,
                amount,
                trade_date,
                product
             } = value;
             const date = formatDate(trade_date);
             let priceFormatted = price != null ? "₹"+numberWithCommas(price.toFixed(2)) : "-";
             let amountFormatted = amount != null ? "₹"+numberWithCommas(amount.toFixed(2)) : "-";
            
            values.push([
                {
                    valueToDisplay: date,
                    value: trade_date,
                    style: [styles.rowItem, styles.date],
                    rowItemContainerStyle: [styles.rowItemContainerStyle]

                },
                {
                    value: <StockName stock_name={stock_name} isin={isin}/>,
                    component: true,
                    style: [styles.rowItem],
                    rowItemContainerStyle: [styles.rowItemContainerStyle]
                },
                {
                    value: <ProductName product={product}/>,
                    component: true,
                    style: [styles.rowItem],
                    rowItemContainerStyle: [styles.rowItemContainerStyle]
                },
                {
                    value: <TransactionType type={transaction_type}/>,
                    component: true,
                    style: [styles.rowItem],
                    rowItemContainerStyle: [styles.rowItemContainerStyle]
                },
                {
                    valueToDisplay: quantity,
                    value: quantity,
                    style: [styles.rowItem],
                    rowItemContainerStyle: [styles.rowItemContainerStyle]
                },
                {
                    valueToDisplay: priceFormatted,
                    value: price,
                    style: [styles.rowItem],
                    rowItemContainerStyle: [styles.rowItemContainerStyle]
                },
                {
                    valueToDisplay: amountFormatted,
                    value: amount,
                    style: [styles.rowItem, styles.amount],
                    rowItemContainerStyle: [styles.rowItemContainerStyle]
                },
            ])
        })

        return {headerData, values};
    };

    handleSearch = (searchText) => {
        let searchResults = [];
        const { data, showStocksOf } = this.state;
        const searchData = data[showStocksOf];
        if(searchText){
            searchResults = searchData.filter((stock)=>{
                return stock.stock_name.toLowerCase().startsWith(searchText.toLowerCase()) ;
            })
        } else {
            searchResults = searchData;
        }
        this.setState({searchText, searchResults, sorting_field: null, sorting_value: null});
    }

    handleProductChange = (value) => {
        const { data } = this.state;
        const searchResults = data[value];
        console.log(data, value);
        this.setState({ showStocksOf: value, searchResults, sorting_field: null, sorting_value: null });
    }

    render(){
        const { searchText, searchResults = [], showStocksOf, loadingData } = this.state;
        const {headerData, values} = this.getHeaderData(searchResults);
        return(
            <View style={styles.container}>
                <NavigationHeader
                    navigation={this.props.navigation}
                    navigationHeader={["My Passbook", "JEWEL Plan", "View Details"]}
                    style={styles.headerStyle}
                />
                {
                    loadingData ? (
                        <View style={styles.loadingConainer}>
                            <ActivityIndicator size="large" color="#410DAA"/>
                        </View>
                    ): (
                        <ScrollView style={styles.mainContainer}>
                            <View style={styles.detailsContainer}>

                                <View style={styles.headingContainer}>
                                    <View>
                                        <Text style={styles.header}>View Details</Text>
                                        <Text style={styles.headerDescription}>View your Transaction details</Text>
                                    </View>
                                </View>

                                <Table
                                    header={headerData}
                                    tableHeaderStyle={[
                                        {
                                            paddingVertical: 17
                                        }
                                    ]}
                                    gridConfig={[
                                        0.15, 0.25, 0.1, 0.15, 0.1, 0.1, 0.15
                                    ]}
                                    data={values}
                                    minWidth={1500}
                                />

                            </View>
                        </ScrollView>
                    )
                }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {flex:1,flexDirection:"column",backgroundColor:"#fff"},
    headerStyle:{ width:"100%",maxWidth:1500},
    loadingConainer:{
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    mainContainer: {
        backgroundColor:'#FFFFFF'
    },
    detailsContainer: {
        // borderWidth: 1,
        borderColor: "#1212121A",
        // borderRadius: 10,
        marginHorizontal: isMobile ? 16 : 36,
        marginBottom: 50,
        minWidth: 1000
    },
    headingContainer: {
        paddingHorizontal: isMobile ? 16 : 0,
        paddingVertical: 26,
        flexDirection: isMobile ? "column" : "row",
        alignItems: isMobile ? "flex-start" : "center",
        justifyContent: "space-between"
    },
    header:{
        fontFamily: "Roboto",
        color: "#464E5F",
        fontSize: 16,
        fontWeight: "700",
        marginBottom: 10
    },
    headerDescription: {
        fontFamily: "Roboto",
        color: "#B5B5C3",
        fontSize: 12,
        marginBottom: isMobile ? 10 : 0
    },
    tableHeader: {
        color: "#464E5F",
        fontSize: 12,
        fontFamily: "Roboto",
        fontWeight: "700",
    },
    rowItem: {
        textAlign: "left",
        fontSize: 13
    },
    rowItemContainerStyle: {
        justifyContent: "flex-start",
        // paddingLeft: 10,
    },
    headerItemContainerStyle: {
        justifyContent: "flex-start",
    },
    amount: {
        fontWeight: "700"
    },
    date: {
        paddingLeft: 10
    }
});

export default PassbookTransactionDetails;