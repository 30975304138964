import React from "react";
import {
  View,
  Text,
  useWindowDimensions,
  Platform,
  Image,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import "react-native-gesture-handler";
import { useSelector } from "react-redux";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import EquityPlans from "../screens/";
import InvestNow from "../components/InvestNow";
import UpcomingInvestments from "../components/UpcomingInvestments/v2";
import TransactionStatus from "../components/UpcomingInvestments/TransactionStatus";
import ViewTransactionStatus from "../components/ViewTransactionStatus/";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import OnboardingComplete from "../screens/onboarding/login/OnboardingComplete.js";

// Portfolio
import Portfolio from "../screens/portfolio/stocks";

import Dashboard from "../components/InvestmentOverview/NewDesign/dashboard";
import DashboardOld from "../components/InvestmentOverview/index";
import RebalanceLandingPage from "../components/RebalancingFlow";
import RebalanceRecommendations from "../components/RebalancingFlow/PortfolioRebalance/SellOrders";
import CurrentHoldings from "../components/RebalancingFlow/CurrentHoldings/index.js";
import PortfolioRebalanceTransactionStatus from "../components/RebalancingFlow/TransactionStatus";
import ImportStocks from "../components/InvestmentOverview/NewDesign/ImportStocks";
import ImportMF from "../components/InvestmentOverview/NewDesign/importMF.js";
import WebImport from "../components/InvestmentOverview/NewDesign/WebImport";
import ImportStocksDetails from "../components/InvestmentOverview/NewDesign/ImportStocksDetails";
import ImportMFDetails from "../components/InvestmentOverview/NewDesign/ImportMFDetails";
import MyEquityInvestmentPlans from "../components/myequityinvestmentplanflow/myclipscompleted/index.js";
import MyMFInvestmentPlans from "../components/mymfinvestmentplanflow/mfhomepage/index.js";
import AssetsPage from "../components/mymfinvestmentplanflow/assetspage/index.js";
import AddFamily from "../components/InvestmentOverview/NewDesign/components/AddFamily";
import AddNewAsset from "../components/mymfinvestmentplanflow/addnewasset/index.js";
import ModifyAsset from "../components/mymfinvestmentplanflow/modifyasset/index.js";
import MyMFInvestmentHistory from "../components/mymfinvestmentplanflow/investmenthistory/index.js";
import ShowWebView from "../components/Common/ShowWebView.js";
import FundDescription from "../components/mymfinvestmentplanflow/investflow/index.js";
import Redeem from "../components/mymfinvestmentplanflow/redeem/index.js";
import StartSIP from "../components/mymfinvestmentplanflow/startsip/index.js";
import InvestLumpsum from "../components/mymfinvestmentplanflow/investlumpsum/index.js";
import SelectMandate from "../components/mymfinvestmentplanflow/selectmandate/index.js";
import CreateMandate from "../components/mymfinvestmentplanflow/createmandate/index.js";
import MandatesPending from "../screens/mutualfunds/alerts/MandatesPending";
import PendingInvestments from "../screens/mutualfunds/alerts/PendingInvestments";
import PendingSwitches from "../screens/mutualfunds/alerts/PendingSwitches";
import FamilyPending from "../screens/mutualfunds/alerts/FamilyPending";
import PendingBills from "../screens/mutualfunds/alerts/PendingBills";
import MFExplore from "../components/mymfinvestmentplanflow/mfexplore/index.js";
import PlansHome from "../components/Plans/plansHome";
import InsightsHome from "../components/Insights/insightsHome";
import InsightsWebView from "../components/Insights/insightsWebView";
import AdvisoryPlanHome from "../components/AdvisoryPlan/advisoryPlanHome";
import AdvisoryRedirection from "../components/AdvisoryPlan/advisoryRedirection";
import UpgradeSubscriptionHome from "../components/UpgradeSubscription/upgradeSubscriptionHome";
import SubscriptionPlanHome from "../components/SubscriptionPlan/subscriptionPlanHome";
import CountTrackerHome from "../components/AdviceCountTracker/countTrackerHome";
import DownloadApp from "../components/DownloadApp/downloadApp";
import DeeplinkRedirection from "../components/DeeplinkRedirection/DeeplinkRedirection";
import StockRecommendations from "../components/Recommendations/stockRecommendations";
import SellExitStocks from "../components/Recommendations/sellExitStocks";
import MFRecommendations from "../components/Recommendations/mfRecommendations";
import CorpCountTracker from "../components/AdviceCountTrackerCorp/corpCountTracker";
import InvestNowNew from "../components/mymfinvestmentplanflow/mfexplore/invest.js";
import ManageSchedule from "../components/myequityinvestmentplanflow/pendinginstallment/index.js";
import AddInstallment from "../components/myequityinvestmentplanflow/addanotherinstallment/index.js";
import DownloadReport from "../components/Report";
import AuditReport from "../components/Report/auditedReport";
import BrochureSingle from "../components/Report/BrochureSingle";
import OnBoard from "../components/OnBoarding/index";

// Passbook
import Passbook from "../screens/passbook";
import PassbookTransactionDetails from "../screens/passbook/transactionDetails";

import { createDrawerNavigator } from "@react-navigation/drawer";
import { Header } from "../components/Common/Header";
import { DrawerContent } from "../components/Common/DrawerContent";

import { LOGOUT } from "../actions/types";
// onboarding
import pan_number from "../screens/onboarding/pan_number";
import OnboardingLogin from "../screens/onboarding/login/usingUsername";
import SignupPage from "../screens/onboarding/login/SignupPage";
import Pan from "../screens/onboarding/login/Pan.js";
import SignUpFinancialPlan from "../screens/onboarding/login/SignUpFinancialPlan.js";
import SignUpImportPortfolio from "../screens/onboarding/login/SignUpImportPortfolio.js";
import OnboardingIntro from "../screens/onboarding/login/OnboardingIntro.js";
import QuizLogin from "../screens/onboarding/login/QuizLogin";
import NewQuizz from "../screens/onboarding/login/NewQuizz";
import Wellness from "../screens/onboarding/login/Wellness";
import AssessmentHub from "../screens/onboarding/login/AssessmentHub";
import QuizReport from "../screens/onboarding/login/QuizReport";
import Terms from "../screens/onboarding/login/Terms";
import PrivacyPolicy from "../screens/onboarding/login/PrivacyPolicy";
import OnboardingLoginMobile from "../screens/onboarding/login/";
import Logout from "../screens/onboarding/logout";
import login_verification from "../screens/onboarding/login_verification";
import contact_info from "../screens/onboarding/contact_info";
import nominee_info from "../screens/onboarding/contact_info_nominee";
import financial_info from "../screens/onboarding/financial_info";
import risk_profile1 from "../screens/onboarding/risk_profile1";
import assets_and_liabilities from "../screens/onboarding/assets_&_liabilities";
import agreement_e_sign from "../screens/onboarding/agreement_e_sign/index.js";
import bank_acc_info from "../screens/onboarding/bank_acc_info";
import ordersummary from "../screens/onboarding/ordersummary14";
import payment_methods from "../screens/onboarding/payment_methods";
import succesful_onbording from "../screens/onboarding/succesful_onbording";
import PaymentFailure from "../screens/onboarding/succesful_onbording/failed";
import kyc_check from "../screens/onboarding/kyc_check";
import aadhar_based_e_sign from "../screens/onboarding/aadhar_based_e_sign";
import DigioRedirection from "../screens/onboarding/agreement_e_sign/redirection";
import ComplianceSuccess from "../screens/onboarding/success_second";
import success from "../screens/onboarding/succesful_onbording/success";
import ContactUs from "../screens/onboarding/contactUs";
import CustomerHandSignature from "../screens/onboarding/customer_hand_signature";

//TopUpFlow
import AddTopUp from "../screens/TopUpFlow/addTopUp/index.js";
import TopupHoldings from "../screens/TopUpFlow/holdings";
import Compliance from "../screens/compliance";
import ChangeDefaultPassword from "../screens/onboarding/login/changeDefaultPassword";

//Demat Screens
import linkedDemat from "../screens/Demat/LinkedDemat";
import linkExistingDemat from "../screens/Demat/LinkExistingDemat";

//Contract Notes
import contractNote from "../screens/ContractNotes";
import contractNotesTransactionDetails from "../screens/ContractNotes/transactionDetails";
import {
  getScreenConfigForCustomer,
  isWeb,
  isMobile,
  getUserDetails,
} from "../utils";

//Billing
import PlanDetails from "../screens/billing/plans/details";
import ViewBill from "../screens/billing/ViewBill";
import Invoices from "../screens/billing/invoices";
import TransactionDetails from "../screens/billing/TransactionDetails";
// Referrals
import Referrals from "../screens/referrals";

// Financial Wellness
import FinAwareness from "../screens/education/finawareness/index";
import CorporateDashboard from "../screens/CorporateDashboard/CorporateDashboard";
import FinancialWellnessIndex from "../screens/FinancialWellnessIndex/FinancialWellnessIndex";
import EducationActivity from "../screens/EducationActivity/EducationActivity";
import FinancialPlanHealth from "../screens/FinancialPlanHealth/FinancialPlanHealth";
import FinWellness from "../screens/education/finawareness/finWellness";
import EmWellness from "../screens/education/finawareness/emWellness";
import EmotionalWellness from "../screens/education/finawareness/emotionalWellness";

// Education Modules
import EduHome from "../screens/education/jamaAcademy/eduHome";
import DetailedView from "../screens/education/jamaAcademy/detailedView";
import EduQuiz from "../screens/education/jamaAcademy/eduQuiz";
import EduQuizResult from "../screens/education/jamaAcademy/eduQuizCompleted";

// Quiz
import QuizDiag from "../screens/education/quiz/quizDiagnostics";
import Quizz from "../screens/education/quiz/index";
import QuizResult from "../screens/education/quiz/quizResult";

// Import Mutual Fund Portfolio
import ImportMFPortfolio from "../screens/portfolio/importMFPortfolio";
import WebScreenImport from "../screens/WebScreens/webScreenImport";

// Membership or Upgrade Plan Screen
import MySubscription from "../screens/upgradePlan/landing";
import MySubscriptionContactPage from "../screens/upgradePlan/contactPage";
import MySubscriptionContactConformationPage from "../screens/upgradePlan/conformationPage";
import MySubscriptionPlanPage from "../screens/upgradePlan/planPage";
import MySIPs from "../screens/mutualfunds/account/sips/mysips";
import SIPDetails from "../screens/mutualfunds/account/sips/sipDetails";
import MyPassbook from "../screens/mutualfunds/account/passbook";

// Finplan
import CreateFinplan from "../screens/mutualfunds/finplan/createFinplan";
import BasicDetails from "../screens/mutualfunds/finplan/basicDetails";
import IncomeDetails from "../screens/mutualfunds/finplan/incomeDetails";
import MaritalStatus from "../screens/mutualfunds/finplan/maritalStatus";
import BuyBike from "../screens/mutualfunds/finplan/buyBike";
import BuyCar from "../screens/mutualfunds/finplan/buyCar";
import BuyHome from "../screens/mutualfunds/finplan/buyHome";
import WhenBuyBike from "../screens/mutualfunds/finplan/whenBuyBike";
import WhenBuyCar from "../screens/mutualfunds/finplan/whenBuyCar";
import WhenBuyHome from "../screens/mutualfunds/finplan/whenBuyHome";
import WhenRetire from "../screens/mutualfunds/finplan/whenRetire";
import PlaceToStudy from "../screens/mutualfunds/finplan/placeToStudy";
import HigherStudies from "../screens/mutualfunds/finplan/higherStudies";
import ChildrenBachelors from "../screens/mutualfunds/finplan/childrenBachelors";
import ChildrenDetails from "../screens/mutualfunds/finplan/childrenDetails";
import ChildrenPG from "../screens/mutualfunds/finplan/childrenPG";
import ChildrenWedding from "../screens/mutualfunds/finplan/childrenWedding";

// Goals
import Goals from "../screens/mutualfunds/goals";
import CreateGoalsIntro from "../screens/mutualfunds/goals/createGoalsIntro";
import InvestForGoals from "../screens/mutualfunds/goals/investForGoals";
import SuggestedFunds from "../screens/mutualfunds/goals/suggestedFunds";
import AddGoal from "../screens/mutualfunds/goals/addGoal";
import GoalDetails from "../screens/mutualfunds/goals/goalDetails";
import AdvancedRetirementPlanner from "../screens/mutualfunds/goals/AdvancedRetirementPlanner";
import RetirementCorpus from "../screens/mutualfunds/goals/RetirementCorpus";
import AddExpense from "../screens/mutualfunds/goals/AddExpense";
import AddIncome from "../screens/mutualfunds/goals/AddIncome";
import IncomePostRetirement from "../screens/mutualfunds/goals/IncomePostRetirement";
import NetCorpus from "../screens/mutualfunds/goals/NetCorpus";
import AttachExisting from "../screens/mutualfunds/goals/attachExisting";
import InvestGoal from "../screens/mutualfunds/goals/investGoal";
import GoalsWebView from "../screens/mutualfunds/goals/goalsWebView";
import InvestGoalSummary from "../screens/mutualfunds/goals/investGoalSummary";

// Alerts
import Notifications from "../screens/mutualfunds/alerts/notifications";
import TrackOrders from "../screens/mutualfunds/alerts/orders";
import OrderDetails from "../screens/mutualfunds/alerts/orderDetails";
import NotificationWebView from "../screens/mutualfunds/alerts/notificationWebView";
import AddPanCard from "../screens/mutualfunds/account/AddPanCard";
import AddPersonalDetails from "../screens/mutualfunds/account/AddPersonalDetails";
import AddAddress from "../screens/mutualfunds/account/AddAddress";
import AssetsAndLiabilities from "../screens/mutualfunds/account/AssetsAndLiabilities";
import eMandate from "../screens/mutualfunds/account/eMandate";
import NachMandate from "../screens/mutualfunds/account/NachMandate";
import ManageFamily from "../screens/mutualfunds/account/ManageFamily";
import Membership from "../screens/mutualfunds/account/Membership";
import SwitchToPreferredCircle from "../screens/mutualfunds/account/SwitchToPreferredCircle";
import HandSignature from "../screens/mutualfunds/account/HandSignature";
import AdvisoryPlan from "../screens/mutualfunds/account/AdvisoryPlan";
import InvestorCharter from "../screens/mutualfunds/account/InvestorCharter";
import InvestorComplaints from "../screens/mutualfunds/account/InvestorComplaints";
import ReportsHome from "../screens/mutualfunds/account/ReportsHome";
import AddNominee from "../screens/mutualfunds/account/AddNominee";
import AddProfessionalDetails from "../screens/mutualfunds/account/AddProfessionalDetails";
import AddBankAccount from "../screens/mutualfunds/account/AddBankAccount";
import BankAccounts from "../screens/mutualfunds/account/BankAccounts";
import EKYC from "../screens/mutualfunds/account/EKYC";
import EKYCRedirection from "../screens/mutualfunds/account/EKYCRedirection";

// Account section
import Account from "../screens/mutualfunds/account/sidenav/account";
import RaiseTicket from "../screens/mutualfunds/account/RaiseTicket";
import ChangePassword from "../screens/mutualfunds/account/ChangePassword";

// RiskProfie
import RiskProfileHome from "../screens/mutualfunds/riskprofile/home";
import MyRiskProfile from "../screens/mutualfunds/riskprofile/myRiskProfile";
import RiskProfileSummary from "../screens/mutualfunds/riskprofile/summary";
import ResetFinplan from "../screens/mutualfunds/account/ResetFinplan";

// MF Min Details
import MinDetailsPan from "../screens/mutualfunds/mindetails/pan";
import MinDetailsEKYC from "../screens/mutualfunds/mindetails/ekyc";
import MinDetailsPersonal from "../screens/mutualfunds/mindetails/personal";
import MinDetailsAddress from "../screens/mutualfunds/mindetails/address";
import MinDetailsNominee from "../screens/mutualfunds/mindetails/nominee";
import MinDetailsProfessional from "../screens/mutualfunds/mindetails/professional";
import MinDetailsBank from "../screens/mutualfunds/mindetails/bank";
import MinDetailsSignature from "../screens/mutualfunds/mindetails/signature";
import PersonalisedFunds from "../screens/mutualfunds/personalisedFunds";
import Home from "../screens/mutualfunds/home";
import SearchFunds from "../screens/mutualfunds/search/funds";
import ScheduleScreen from "../screens/mutualfunds/home/scheduleCall";

// Add Assets
import RecentAssets from "../screens/portfolio/recentAssets";

//Import Stock Portfolio
import ImportOtherAssets from "../screens/portfolio/importStockIntro";
import ImportStocksPortfolio from "../screens/portfolio/importStocksPortfolio";

//JARS
import Jars from "../screens/Jars/jars";

//Wish List
import WishList from "../screens/WishList/wishList";
import InvoiceDetails from "../screens/billing/invoiceDetails";
import pendingInvoiceOverdue from "../screens/billing/PendingInvoiceOverdue";
import HoldingsDetails from "../screens/billing/HoldingsDetails";
import DividendYield from "../screens/billing/ViewBill/PerformanceBill/DividendYield";
import InvestAmountDetails from "../screens/billing/ViewBill/PerformanceBill/InvestedAmountDetails";
import AdvisoryFeeDetails from "../screens/billing/ViewBill/PerformanceBill/AdvisoryFeeDetails";
import ProratedDetails from "../screens/billing/ViewBill/ServiceBill/ProratedDetails";
import AumDetails from "../screens/billing/ViewBill/ServiceBill/AumDetails";
import InvoicesPaymentRedirection from "../screens/billing/paymentRedirection";

//Dashboard New Screen
import NewHomeDashboard from "../screens/dashboardHome";
import OldHomeDashboard from "../screens/dashboardHome/dashboardOld";
import BillingLedger from "../screens/billing/plans/BillingLedger";
import BillingLedgerDetails from "../screens/billing/plans/BillingLedgerDetails";

//ToDo screens
import ToDoHome from "../screens/todos";
import ShowBankAccount from "../screens/mutualfunds/invest/showBankAccount";
import TestConfig from "../screens/misc/TestingConfig";
import DigioKYC from "../screens/onboarding/kyc/digio";
import KYCRedirection from "../screens/onboarding/kyc/redirection";
import AdminLogin from "../screens/AdminLogin";
import ProcessOrder from "../screens/mutualfunds/invest/showOrdersCreated";
import RateUs from "../components/Common/rateUs.js";
import WebViewGeneral from "../components/Common/WebViewGeneral";
import { navigationRef } from "../../RootNavigation";
import Gateway from "../gateway/index";
import FAQ from "../screens/faq/faq";
import LeaderBoard from "../screens/CorporateDashboard/LeaderBoard";
import ForgotPassword from "../screens/onboarding/login/forgotPassword";

import UpgradeAdvisoryPlanRedirection from "../components/SubscriptionPlan/upgradeAdvisoryPlanRedirection";
import DownloadMobileApp from "../components/Common/DownloadMobileApp";

// import NomineeAuthentication from "../components/Common/nomineeAuthentication";

import PendingPayments from "../screens/mutualfunds/alerts/PendingPayments";
import FinPlansGoalsCollections from "../screens/onboarding/login/FinPlansGoalsCollections.js";
import DeActivateAccount from "../screens/mutualfunds/account/DeActivateAccount.js";
import MiniOnboardingRiskProfileQA from "../screens/onboarding/MiniOnboardingRiskProfileQA.js";
import MiniOnboardingRiskProfile from "../screens/onboarding/MiniOnboardingRiskProfile.js";
import Store from "../store/index.js";
import MfOtpAuthentication from "../components/mymfinvestmentplanflow/mfotpauthentication/index.js";

const { width } = Dimensions.get("window");

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

export const OnboardingAuthScreens = (showGetStarted) => (
  <Stack.Navigator
    initialRouteName={
      showGetStarted.showGetStarted == "true" ? "OnBoard" : "Login"
    }
    screenOptions={{
      header: () => <></>,
    }}
  >
    <Stack.Screen name="OnBoard" component={OnBoard} />
    <Stack.Screen name="Login" component={OnboardingLogin} />
    <Stack.Screen name="SignupPage" component={SignupPage} />
    <Stack.Screen name="DownloadMobileApp" component={DownloadMobileApp} />
    <Stack.Screen name="QuizLogin" component={QuizLogin} />
    <Stack.Screen name="FinPlanData" component={QuizLogin} />
    <Stack.Screen name="RetirementPlan" component={QuizLogin} />
    <Stack.Screen name="ChildEducationPlan" component={QuizLogin} />
    <Stack.Screen name="EmergencyFund" component={QuizLogin} />
    <Stack.Screen name="RiskProfileData" component={QuizLogin} />
    <Stack.Screen name="ImportStockData" component={QuizLogin} />
    <Stack.Screen name="ImportMFData" component={QuizLogin} />
    <Stack.Screen name="JamaAcademy" component={QuizLogin} />
    <Stack.Screen name="NewQuizz" component={NewQuizz} />
    <Stack.Screen name="Wellness" component={Wellness} />
    <Stack.Screen name="AssessmentHub" component={AssessmentHub} />
    <Stack.Screen name="QuizReport" component={QuizReport} />
    <Stack.Screen name="DownloadApp" component={DownloadApp} />
    <Stack.Screen name="DeeplinkRedirection" component={DeeplinkRedirection} />
    <Stack.Screen name="Terms" component={Terms} />
    <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
    <Stack.Screen
      name="OnboardingLoginMobile"
      component={OnboardingLoginMobile}
    />
    <Stack.Screen name="VerifyOTP" component={login_verification} />
    <Stack.Screen name="GetHelp" component={ContactUs} />
    <Stack.Screen
      name="ChangeDefaultPassword"
      component={ChangeDefaultPassword}
    />
    <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
  </Stack.Navigator>
);

const do_not_include_header_for = ["digioredir", "onboarding", "gethelp"];

const linking = {
  prefixes: [
    "http://localhost:19006",
    "https://dapp.jamawealth.com",
    "https://app.jamawealth.com",
  ],
  // config: app_screens_config,
  config: {
    screens: {
      JamaWealth: {
        path: "/",
        screens: {
          EquityPlans: "/",
        },
      },
      App: {
        // path: "/",
        screens: {
          Portfolio: {
            screens: {
              EquityPlansAdmin: "/admin/:user_id",
              AdminLogin: "/adminlogin/:user_id",
              InvestmentOverview: "/portfolio",
              InvestmentOverviewAdmin: "/portfolio/:user_id",
              DashboardNew: "/dashboard_new",
              DashboardOld: "/dashboard/old",
              DashboardAdmin: "/dashboard_new/:user_id",
              Logout: "/logout",
              WebImport: "/webimport",
              ImportStocksDetails: "/importstocksdetails",
              ImportMFDetails: "/importmfdetails",
              AddFamily: "/addfamily",
              MyEquityInvestmentPlansAdmin: "/plans/:user_id",
              MyMFInvestmentHistory: "/mutualfunds/investment_history/",
              Redeem: "/mutualfunds/invest/redeem/",
              MyMFInvestmentHistoryAdmin:
                "/mutualfunds/investment_history/:user_id",
              AddInstallment: "/myeqplans/addins/",
              InvestNow: "/plans/investnow",
              WebView: "/urls",
              UpcomingInvestments: "/investments/upcoming",
              // UpcomingInvestmentsNew: "/investments/upcoming_new",
              // UpcomingInvestmentsNewAdmin: "/investments/upcoming_new/:user_id",
              NextUpcomingInvestments: "/next",
              Invest: "/invest",
              UpcomingInvestmentsAdmin: "/investments/upcoming/:user_id",

              TransactionStatus: "/investments/transaction_status",
              ViewTransactionStatus: "/investments/view_transaction_status",
              ViewStatus: "/investments/status",
              ViewStatusAdmin: "/investments/status/:user_id",
              ViewTransactionStatusAdmin:
                "/investments/view_transaction_status/:user_id",

              RebalanceLandingPage: "/reb",
              RebalanceLandingPageAdmin: "/rebadmin/:user_id",
              RebalanceRecommendations: "/reb/process",
              RebalanceRecommendationsProduct:
                "/reb/process/:product_name/:units",
              PortfolioRebalanceTransactionStatus: "/reb/transaction_status",
              CurrentHoldings: "/reb/holdings/:product_name",
              Rebalance: "/rebalance",

              DownloadReport: "/quaterly_report",
              AuditReport: "/audit_report",
              BrochureSingle: "/brochure",

              PlanDetails: "/plans/plan/details",

              // topup
              TopupHoldings: "/topup/holdings/:product/:hash",

              // Aliases
              Stocks: "/stocks",

              // Mutual Funds
              MyMFInvestmentPlans: "/mutualfunds",

              // Assets
              AssetsPage: "/assets",
              AddNewAsset: "/assets/addnew",
              ModifyAsset: "/assets/modify",

              // Alerts
              MandatesPending: "/alerts/pendingmandate",
              FamilyPending: "/alerts/pendingfamily",
              PendingBills: "/alerts/pendingbills",
              PendingInvestments: "/alerts/pendinginvestments",
              PendingPayments: "/alerts/pendingpayments",
              PendingSwitches: "/alerts/pendingswitches",

              // Alerts
              Notifications: "/notifications",
              TrackOrders: "/orders/",
              OrderDetails: "/orders/:transaction_id",

              RecentAssets: "/add_assets",

              //JARS
              Jars: "/JARS",

              //Wish List
              WishList: "/wish_list",
              Holdings: "/holdings/",
              HoldingsAdmin: "/holdings/:user_id",

              // Contract notes
            },
          },
          Dashboard: {
            screens: {
              Home: "/investments/overview",
              InvestmentOverview: "/home",
              ScheduleCall: "/schedule",
              DetailedView: "/edu_home/:key",
              EduQuiz: "edu_home/module/quiz/:quiz_id",
              EduQuizResult: "edu_home/module/quiz-result/:quiz_id",
              //Import Mutual Fund Portfolio
              ImportMFPortfolio: "/import_MF_portfolio",
              WebScreenImport: "/import_MF_portfolio/import_funds",
              //Import Stock Portfolio
              ImportOtherAssets: "/import_stock_details",
              ImportStocksPortfolio: "/import_stocks_portfolio",
              //New Dashboard
              NewHomeDashboard: "/Dashboard_Home",
              OldHomeDashboard: "/Old_Dashboard_Home",
              //TODOs
              ToDoHome: "/actions",
              TestingConfig: "/config",
              ProcessOrder: "/process/:action_id",
              RateUs: "/rateus/:action_id",
              WebViewGeneral: "/navigate",
              Gateway: "/gateway",
              GetHelp: "/help",
            },
          },
          Goals: {
            // Goals
            path: "/goals",
            screens: {
              Goals: "/",
              CreateGoalsIntro: "/intro",
              AddGoal: "/add/",
              EditGoal: "/add/:goal_id",
              GoalDetails: "/details/:goal_id",
              AdvancedRetirementPlanner: "/advancedRetirementPlanner",
              RetirementCorpus: "/retirementCorpus",
              AddExpense: "/addExpense",
              AddIncome: "/addIncome",
              IncomePostRetirement: "/incomePostRetirement",
              NetCorpus: "/netCorpus",
              AttachExisting: "/attach/:goal_id",
              InvestGoal: "/invest/:goal_id",
              InvestGoalSummary: "/invest/summary/:goal_id",
              // Finplan
              CreateFinplan: "/finplan/create",
              BasicDetails: "/finplan/basic",
              IncomeDetails: "/finplan/income",
              MaritalStatus: "/finplan/marital",
              ChildrenDetails: "/finplan/children/details",
              ChildrenBachelors: "/finplan/children/bachelors",
              ChildrenPG: "/finplan/children/pg",
              ChildrenWedding: "/finplan/children/wedding",
              BuyHome: "/finplan/home",
              BuyBike: "/finplan/bike",
              BuyCar: "/finplan/car",
              WhenBuyBike: "/finplan/whenbike",
              WhenBuyCar: "/finplan/whencar",
              WhenBuyHome: "/finplan/whenhome",
              WhenRetire: "finplan/retire",
              PlaceToStudy: "finplan/placetostudy",
              HigherStudies: "/finplan/study",
            },
          },
          Explore: {
            path: "/explore",
            screens: {
              InvestNowNew: "/invest",

              MFExplore: "/mutualfunds",
              MFExploreAdmin: "/mutualfunds/:user_id",
              FundDescription: "/mutualfunds/invest/fund_description/",
              FundDescriptionAdmin:
                "/mutualfunds/invest/fund_description/:user_id",
              StartSIP: "/mutualfunds/invest/startsip/",
              StartSIPAdmin: "/mutualfunds/invest/startsip/:user_id",
              InvestLumpsum: "/mutualfunds/invest/lumpsum/",
              InvestLumpsumAdmin: "/mutualfunds/invest/lumpsum/:user_id",
              SelectMandate: "/mutualfunds/invest/selectmandate/",
              SelectMandateAdmin: "/mutualfunds/invest/selectmandate/:user_id",
              CreateMandate: "/mutualfunds/invest/createmandate/",
              CreateMandateAdmin: "/mutualfunds/invest/createmandate/:user_id",
              SearchFunds: "/search",
              ShowBankAccount: "/bankaccounts/select",
              MfOtpAuthentication: "/mf_otp_auth"
            },
          },
          Plans: {
            path: "/smart",
            screens: {
              PlansHome: "/plans/home",
              InsightsHome: "/insights",
              InsightsWebView: "/insights/details",
              MFRecommendations: "/mfRecommendations",
              CorpCountTracker: "/corpCountTracker",
              UpgradeSubscriptionHome: "/upgradeSubscription",
              SubscriptionPlanHome: "/subscriptionPlan",
              SIPDetails: "/sips/details",
              PlansHomeSIPS: "/home/:activeTabIndex",
              MyEquityInvestmentPlans: "/myeqplans/",
              MyEquityInvestmentPlans: "/plans/",
              ManageSchedule: "/plans/manage/",
              ManageScheduleAdmin: "/plans/manage/:user_id",
            },
          },
          ViewMode: {
            path: "/dashboard",
            screens: {
              Home: "/home",
              CountTrackerHome: "/countTracker",
              SwitchToPreferredCircle: "/switchToPreferredCircle",
              StockRecommendations: "/stockRecommendations",
              InvestForGoals: "/mfRecommendations",
              SuggestedFunds: "/suggestedFunds",
              SellExitStocks: "/sellExitStocks",
              HomeAdmin: "/home/:user_id",
              FinAwareness: "/finawareness",
              CorporateDashboard: "/corporateDashboard",
              FinancialWellnessIndex: "/financialWellnessIndex",
              EducationActivity: "/educationActivity",
              FinancialPlanHealth: "/financialPlanHealth",
              LeaderBoard: "/leaderBoard",
              FinWellness: "/finwellness",
              EmWellness: "/emwellness",
              EmotionalWellness: "/emotionalwellness",
              QuizDiag: "/finawareness/quiz-diagnostics/:quiz_id",
              Quizz: "/finawareness/quiz/:quiz_id",
              QuizResult: "/finawareness/quiz-result/:quiz_id",
              ContractNote: "/contractnotehome",
              ContractNoteAdmin: "/contractnotehome/:user_id",
              ContrcatTransactionDetails:
                "/contractnotehome/transaction/details",
              EduHome: "/edu_home",
              ImportStocks: "/importstocks",
              ImportMF: "/importmutualfunds",
              ToDoHome: "/actions",
              ProcessOrder: "/process/:action_id",
              RateUs: "/rateus/:action_id",
            },
          },
          Passbook: {
            path: "/passbook",
            screens: {
              Passbook: "/",
              PassbookAdmin: "/:user_id",
              TransactionDetails: "/details/:transaction_id",
              TransactionDetailsAdmin: "/details/:transaction_id/:user_id",
            },
          },
          Account: {
            path: "/account",
            screens: {
              Account: "/",
              AddPanCard: "/pan",
              AboutMe: "/personal",
              AddAddress: "/address",
              AssetsAndLiabilities: "/assetsandliabilities",
              eMandate: "/eMandate",
              NachMandate: "/nachmandate",
              ManageFamily: "/managefamily",
              Membership: "/membership",
              HandSignature: "/hand_signature",
              AdvisoryPlan: "/advisory_plan",
              UpgradeAdvisoryPlanRedirection: "/upgrade_advisory_plan",
              InvestorCharter: "/investorcharter",
              InvestorComplaints: "/investorcomplaints",
              ReportsHome: "/reports",
              AddNominee: "/nominee",
              AddProfessionalDetails: "/professional",
              AddBankAccount: "/bank",
              BankAccounts: "/banks",
              DeActivateAccount: "/deactivate",
              PersonalisedFunds: "/personalised",
              EKYC: "/ekyc",
              EKYCRedirection: "/digkycredir",
              // Risk Profile
              RiskProfileHome: "/riskprofile",
              MyRiskProfile: "/riskprofile/assessment",
              RiskProfileSummary: "/riskprofile/summary",

              // Finplan
              ResetFinplan: "/finplan",

              // Demat Screens
              Demat: "/demat/",
              DematAdmin: "/demat/:user_id",
              linkExistingDemat: "/demat/link/existingdemat",

              //Membership or Upgrade Plan
              MySIPs: "/sips",
              MyPassbook: "/mypassbook",
              ChangePassword: "/change/password",
              // Referrals
              Referrals: "/referrals",
            },
          },
          RaiseTicket: {
            path: "/feedback",
            screens: {
              Feedback: "/",
            },
          },
          NomineeAuthentication: {
            path: "/nominee",
            screens: {
              Nominee: "/",
            },
          },
          Billing: {
            path: "/billing",
            screens: {
              Invoices: "/",
              InvoicesAdmin: "/:user_id",
              InvoiceDetails: "/details/:invoice_id",
              InvoiceDetailsAdmin: "/details/:invoice_id/:user_id",
              HoldingsDetails: "/bills/holdings/:bill_id",
              ViewBill: "/bills/:bill_id",
              DividendYields: "/bills/dividends/yields/:bill_id",
              InvestAmountDetails: "/bills/performance/invested/:bill_id",
              AumDetails: "/bills/service/aum/:bill_id",
              ProratedDetails: "/bills/service/prorated/:bill_id",
              TransactionDetails: "/bills/transactions/:bill_id/:date",
              InvoicesPaymentRedirection: "/payment/status/",
              BillingLedger: "/plans/ledger/",
              BillingLedgerAdmin: "/plans/ledger/:user_id",
              BillingLedgerDetails: "/plans/ledger/details/:bill_id/",
              BillingLedgerDetailsAdmin:
                "/plans/ledger/details/:bill_id/:user_id",
            },
          },
        },
      },
      Onboarding: {
        path: "/onboarding",
        screens: {
          AdvisoryPlanHome: "/advisoryPlan",
          ScheduleCall: "/schedule", // new screen
          OnboardingIntro: "/intro",
          FinPlansGoalsCollections: "/finplans/goalscollections",
          OnboardingComplete: "/complete",
          AdvisoryRedirection: "/advisoryRedirection/", // check the payment status
          PanDetails: "/pan",
          PanDetailsAdmin: "/pan/:user_id",
          ImportStocksDetails: "/importstocksdetails",
          ImportMFDetails: "/importmfdetails",
          KYCCreate: "/kyc/create",
          ContactInfo: "/contactinfo",
          ContactInfoAdmin: "/contactinfo/:user_id",
          NomineeInfo: "/nomineeinfo",
          NomineeInfoAdmin: "/nomineeinfo/:user_id",
          SignUpFinancialPlan: "/signupfinancialplan",
          Pan: "/kycinfo",
          SignUpImportPortfolio: "/signupimportportfolio",
          MiniOnboardingRiskProfileQA: "/mini_risk_profile_qa",
          MiniOnboardingRiskProfile: "mini_risk_profile",
          FinancialInfo: "/financialinfo",
          FinancialInfoAdmin: "/financialinfo/:user_id",
          RiskProfile: "/riskprf",
          RiskProfileAdmin: "/riskprf/:user_id",
          Assets: "/assets",
          AssetsAdmin: "/assets/:user_id",
          eSign: "/esign",
          eSignAdmin: "/esign/:user_id",
          DigioRedirection: "/digioredir",
          DigioKYCRedirection: "/kyc/status",
          BankAccount: "/bankaccinfo",
          BankAccountAdmin: "/bankaccinfo/:user_id",
          Payment: "/payment",
          PaymentMethods: "/payment_methods",
          UploadPaymentReceipt: "/uploadpaymentrecipt",
          PaymentSuccess: "/order/paymentsuccess",
          PaymentFailure: "/order/paymentfailure",
          OnboardingSuccess: "/success",
          CustomerHandSignature: "/handSignature",
        },
      },
      MinDetails: {
        path: "/mindetails",
        screens: {
          MinDetailsPan: "/pan",
          MinDetailsPersonal: "/personal",
          MinDetailsAddress: "/address",
          MinDetailsNominee: "/nominee",
          MinDetailsProfessional: "/professional",
          MinDetailsBank: "/bank",
          MinDetailsSignature: "/signature",
          MinDetailsEKYC: "/ekyc",
          EKYCRedirection: "/digkycredir",
        },
      },
      ComplianceSuccess: "/compliance/success",
      Login: "/login",
      DownloadMobileApp: "/mobile",
      SignupPage: "/signup",
      QuizLogin: "/assess/:quiz_id",
      FinPlanData: "/finplan",
      RetirementPlan: "/retirement",
      ChildEducationPlan: "/edu",
      EmergencyFund: "/emergencyfund",
      RiskProfileData: "/riskprofile",
      ImportStockData: "/importstocks",
      ImportMFData: "/importmf",
      JamaAcademy: "/academy",
      NewQuizz: "/quiz/:quiz_id",
      Wellness: "/wellness",
      AssessmentHub: "assessment-hub",
      QuizReport: "/quiz-report/:quiz_id",
      DownloadApp: "/download/JamaWealth/mobile/app",
      DeeplinkRedirection: "/deeplink",
      OnboardingLoginMobile: "/login/password",
      VerifyOTP: "/loginverif",
      GetHelp: "/gethelp",
      ChangeDefaultPassword: "/changedefault",
      ForgotPassword: "/forgotpassword",
    },
  },
};

const PortfolioStack = createStackNavigator();

export const PortfolioScreens = () => (
  <PortfolioStack.Navigator
    initialRouteName="InvestmentOverview"
    screenOptions={({ route, navigation }) => ({
      header: ({ previous }) => {
        const { name } = route;
        if (name === "EquityPlans") return null;
        let url = null;
        if (Platform.OS === "web") {
          url = global.location.href;
        }
        if (
          url &&
          do_not_include_header_for.findIndex((element) =>
            url.includes(element)
          ) !== -1
        )
          return null;
        return (
          <Header route={route} previous={previous} navigation={navigation} />
        );
      },
    })}

    // screenOptions={{
    //   header: ({ scene, previous, navigation }) => {

    //     const { route } = scene;
    //     const { name } = route;
    //     if (name == "EquityPlans") return null;
    //     let url = null;
    //     if (Platform.OS == "web") {
    //       url = global.location.href;
    //     }
    //     if (
    //       url &&
    //       do_not_include_header_for.findIndex((element) =>
    //         url.includes(element)
    //       ) != -1
    //     )
    //       return null;
    //     return (
    //       <Header
    //         scene={scene}
    //         previous={previous}
    //         navigation={navigation}
    //         route={route}
    //       />
    //     );
    //   },
    // }}
  >
    {/* Screen to know customer flow using customer id */}
    <Stack.Screen name="EquityPlansAdmin" component={EquityPlans} />
    <Stack.Screen name="AdminLogin" component={AdminLogin} />

    {/* Dashboard page  */}
    <Stack.Screen name="InvestmentOverview" component={Dashboard} />
    <Stack.Screen name="DashboardOld" component={DashboardOld} />

    {/* Dashboard page - admin - customer view */}
    <Stack.Screen name="InvestmentOverviewAdmin" component={Dashboard} />

    {/* Dashboard - alias to InvestmentOverview */}
    <Stack.Screen name="Dashboard" component={Dashboard} />

    {/* New Dashboard page  */}
    <Stack.Screen name="DashboardNew" component={Dashboard} />

    {/* New Dashboard page admin */}
    <Stack.Screen name="DashboardAdmin" component={Dashboard} />

    {/* My equity investment plans */}

    {/* Screen which shows all active/expired CIPS  */}
    <Stack.Screen name="MyMFInvestmentPlans" component={MyMFInvestmentPlans} />
    {/* Alias to above screen */}
    <Stack.Screen name="MF" component={MyMFInvestmentPlans} />

    {/* CIPS admin screen - customer view */}
    <Stack.Screen
      name="MyMFInvestmentPlansAdmin"
      component={MyMFInvestmentPlans}
    />
    {/* Import MF Page */}
    <Stack.Screen name="WebImport" component={WebImport} />
    {/* Import MF Page admin */}
    <Stack.Screen name="WebImportAdmin" component={WebImport} />
    {/* Import Stocks Details Page */}
    <Stack.Screen name="ImportStocksDetails" component={ImportStocksDetails} />
    <Stack.Screen name="ImportMFDetails" component={ImportMFDetails} />
    {/* Import Stocks Details Page admin */}
    <Stack.Screen
      name="ImportStocksDetailsAdmin"
      component={ImportStocksDetails}
    />
    {/* Add Family Page */}
    <Stack.Screen name="AddFamily" component={AddFamily} />
    {/* Add Family Page admin */}
    <Stack.Screen name="AddFamilyAdmin" component={AddFamily} />
    {/* Assets Page */}
    <Stack.Screen name="AssetsPage" component={AssetsPage} />
    {/* Assets Page admin */}
    <Stack.Screen name="AssetsPageAdmin" component={AssetsPage} />
    {/* Add New Asset Page */}
    <Stack.Screen name="AddNewAsset" component={AddNewAsset} />
    {/* Add New Asset Page admin */}
    <Stack.Screen name="AddNewAssetAdmin" component={AddNewAsset} />
    {/* Modify Asset Page */}
    <Stack.Screen name="ModifyAsset" component={ModifyAsset} />
    {/* Modify Asset Page admin */}
    <Stack.Screen name="ModifyAssetAdmin" component={ModifyAsset} />
    {/* Mutual funds investment history  */}
    <Stack.Screen
      name="MyMFInvestmentHistory"
      component={MyMFInvestmentHistory}
    />
    {/* Mutual funds investment history admin */}
    <Stack.Screen
      name="MyMFInvestmentHistoryAdmin"
      component={MyMFInvestmentHistory}
    />
    <Stack.Screen name="Redeem" component={Redeem} />
    <Stack.Screen name="ShowWebView" component={ShowWebView} />
    {/* Add a new installment against a CIP */}
    <Stack.Screen name="AddInstallment" component={AddInstallment} />
    {/* Invest in a CIP */}
    <Stack.Screen name="InvestNow" component={InvestNow} />

    {/* Add topup flow */}
    <Stack.Screen name="AddTopUp" component={AddTopUp} />

    {/* Profile flow of customer */}
    <Stack.Screen name="Profile" component={Compliance} />
    {/* Profile flow of customer - admin */}
    <Stack.Screen name="ProfileAdmin" component={Compliance} />

    {/* Upcoming investments */}
    <Stack.Screen name="UpcomingInvestments" component={UpcomingInvestments} />
    {/* Upcoming investments - alias */}
    <Stack.Screen name="Upcoming" component={UpcomingInvestments} />

    {/* Upcoming investments screen - new design */}
    {/* <Stack.Screen
      name="UpcomingInvestmentsNew"
      component={UpcomingInvestmentsNew}
    /> */}
    {/* Upcoming investments screen - new design - admin - customer view */}
    {/* <Stack.Screen
      name="UpcomingInvestmentsNewAdmin"
      component={UpcomingInvestmentsNew}
    /> */}

    {/* Upcoming investments - alias - next */}
    <Stack.Screen
      name="NextUpcomingInvestments"
      component={UpcomingInvestments}
    />
    <Stack.Screen name="Invest" component={UpcomingInvestments} />
    {/* Upcoming investments admin page  */}
    <Stack.Screen
      name="UpcomingInvestmentsAdmin"
      component={UpcomingInvestments}
    />

    {/* Investment transaction status - for upcoming flow / topup flow */}
    <Stack.Screen name="TransactionStatus" component={TransactionStatus} />

    {/* View tranaction status - button */}
    <Stack.Screen
      name="ViewTransactionStatus"
      component={ViewTransactionStatus}
    />
    <Stack.Screen
      name="ViewTransactionStatusAdmin"
      component={ViewTransactionStatus}
    />
    <Stack.Screen name="ViewStatus" component={ViewTransactionStatus} />
    <Stack.Screen name="ViewStatusAdmin" component={ViewTransactionStatus} />

    {/* Rebalancing flow */}
    <Stack.Screen
      name="RebalanceLandingPage"
      component={RebalanceLandingPage}
    />

    <Stack.Screen
      name="RebalanceLandingPageAdmin"
      component={RebalanceLandingPage}
    />

    <Stack.Screen name="Rebalance" component={RebalanceLandingPage} />

    <Stack.Screen name="CurrentHoldings" component={CurrentHoldings} />

    <Stack.Screen
      name="RebalanceRecommendations"
      component={RebalanceRecommendations}
    />

    <Stack.Screen
      name="RebalanceRecommendationsProduct"
      component={RebalanceRecommendations}
    />

    <Stack.Screen
      name="PortfolioRebalanceTransactionStatus"
      component={PortfolioRebalanceTransactionStatus}
    />

    {/* Misc */}
    <Stack.Screen name="DownloadReport" component={DownloadReport} />
    <Stack.Screen name="BrochureSingle" component={BrochureSingle} />
    <Stack.Screen name="AuditReport" component={AuditReport} />

    {/* Logout */}
    <Stack.Screen name="Logout" component={Logout} />

    <Stack.Screen name="TopupHoldings" component={TopupHoldings} />

    {/* Aliases */}
    <Stack.Screen name="Stocks" component={Portfolio} />

    {/* Alerts - mandates pending  */}
    <Stack.Screen name="MandatesPending" component={MandatesPending} />
    {/* Alerts - mandates pending admin */}
    <Stack.Screen name="MandatesPendingAdmin" component={MandatesPending} />

    {/* Alerts - payments pending  */}
    <Stack.Screen name="PendingPayments" component={PendingPayments} />
    {/* Alerts - payments pending admin */}
    <Stack.Screen name="PendingPaymentsAdmin" component={PendingPayments} />

    {/* Alerts - family pending  */}
    <Stack.Screen name="FamilyPending" component={FamilyPending} />
    {/* Alerts - family pending admin */}
    <Stack.Screen name="FamilyPendingAdmin" component={FamilyPending} />

    {/* Alerts - bills pending  */}
    <Stack.Screen name="PendingBills" component={PendingBills} />
    {/* Alerts - bills pending admin */}
    <Stack.Screen name="PendingBillsAdmin" component={PendingBills} />

    {/* Alerts - investments pending  */}
    <Stack.Screen name="PendingInvestments" component={PendingInvestments} />
    {/* Alerts - investments pending admin */}
    <Stack.Screen
      name="PendingInvestmentsAdmin"
      component={PendingInvestments}
    />

    {/* Alerts - switches pending  */}
    <Stack.Screen name="PendingSwitches" component={PendingSwitches} />
    {/* Alerts - switches pending admin */}
    <Stack.Screen name="PendingSwitchesAdmin" component={PendingSwitches} />

    {/* Alerts */}
    <Stack.Screen name="Notifications" component={Notifications} />
    <Stack.Screen name="TrackOrders" component={TrackOrders} />
    <Stack.Screen name="OrderDetails" component={OrderDetails} />
    <Stack.Screen name="NotificationWebView" component={NotificationWebView} />

    {/* Assets */}
    <Stack.Screen name="RecentAssets" component={RecentAssets} />

    {/* JARS */}
    <Stack.Screen name="Jars" component={Jars} />

    {/* Wish List */}
    <Stack.Screen name="WishList" component={WishList} />

    <Stack.Screen name="Holdings" component={Portfolio} />
    <Stack.Screen name="HoldingsAdmin" component={Portfolio} />
    <Stack.Screen name="PlansHomeSIPS" component={PlansHome} />
    <Stack.Screen name="SearchFunds" component={SearchFunds} />
    <Stack.Screen name="SIPDetails" component={SIPDetails} />
  </PortfolioStack.Navigator>
);

const MindetailsStack = createStackNavigator();

export const MindetailsScreens = () => (
  <MindetailsStack.Navigator
    initialRouteName="MinDetailsPan"
    screenOptions={{
      header: () => null,
    }}
  >
    <Stack.Screen name="MinDetailsPan" component={MinDetailsPan} />
    <Stack.Screen name="MinDetailsPersonal" component={MinDetailsPersonal} />
    <Stack.Screen name="MinDetailsAddress" component={MinDetailsAddress} />
    <Stack.Screen name="MinDetailsNominee" component={MinDetailsNominee} />
    <Stack.Screen
      name="MinDetailsProfessional"
      component={MinDetailsProfessional}
    />
    <Stack.Screen name="MinDetailsBank" component={MinDetailsBank} />
    <Stack.Screen name="MinDetailsSignature" component={MinDetailsSignature} />
    <Stack.Screen name="MinDetailsEKYC" component={MinDetailsEKYC} />
    <Stack.Screen name="EKYCRedirection" component={EKYCRedirection} />
    <Stack.Screen name="Logout" component={Logout} />
  </MindetailsStack.Navigator>
);

const OnboardingStack = createStackNavigator();

export const OnboardingScreens = () => (
  <OnboardingStack.Navigator
    screenOptions={{
      header: () => null,
    }}
  >
    {/* onboarding */}
    {/* Pan details */}
    <Stack.Screen name="AdvisoryPlanHome" component={AdvisoryPlanHome} />
    <Stack.Screen name="AdvisoryRedirection" component={AdvisoryRedirection} />
    <Stack.Screen name="OnboardingIntro" component={OnboardingIntro} />
    <Stack.Screen name="OnboardingComplete" component={OnboardingComplete} />
    <Stack.Screen name="PanDetails" component={pan_number} />
    <Stack.Screen name="ViewBill" component={ViewBill} />
    <Stack.Screen name="InvoiceDetails" component={InvoiceDetails} />
    <Stack.Screen name="ImportStocksDetails" component={ImportStocksDetails} />
    <Stack.Screen
      name="FinPlansGoalsCollections"
      component={FinPlansGoalsCollections}
    />
    <Stack.Screen name="ScheduleCall" component={ScheduleScreen} />
    <Stack.Screen name="ImportMFDetails" component={ImportMFDetails} />
    <Stack.Screen name="InvoiceDetailsAdmin" component={InvoiceDetails} />
    <Stack.Screen name="HoldingsDetails" component={HoldingsDetails} />

    <Stack.Screen name="Pan" component={Pan} />
    <Stack.Screen name="SignUpFinancialPlan" component={SignUpFinancialPlan} />
    <Stack.Screen
      name="SignUpImportPortfolio"
      component={SignUpImportPortfolio}
    />
    <Stack.Screen
      name="MiniOnboardingRiskProfile"
      component={MiniOnboardingRiskProfile}
    />
    <Stack.Screen
      name="MiniOnboardingRiskProfileQA"
      component={MiniOnboardingRiskProfileQA}
    />
    {/* Pan details admin */}
    <Stack.Screen name="PanDetailsAdmin" component={pan_number} />
    <Stack.Screen name="KYCCreate" component={DigioKYC} />

    <Stack.Screen name="ContactInfo" component={contact_info} />

    {/* Contact Info admin screen */}
    <Stack.Screen name="ContactInfoAdmin" component={contact_info} />

    <Stack.Screen name="NomineeInfo" component={nominee_info} />

    {/* Nominee Info Admin */}
    <Stack.Screen name="NomineeInfoAdmin" component={nominee_info} />

    <Stack.Screen name="FinancialInfo" component={financial_info} />

    {/* Financial Info Admin screen */}
    <Stack.Screen name="FinancialInfoAdmin" component={financial_info} />

    <Stack.Screen name="RiskProfile" component={risk_profile1} />

    {/* Risk Profile Admin */}
    <Stack.Screen name="RiskProfileAdmin" component={risk_profile1} />

    <Stack.Screen name="Assets" component={assets_and_liabilities} />

    {/* Assets admin screen */}
    <Stack.Screen name="AssetsAdmin" component={assets_and_liabilities} />

    <Stack.Screen name="eSign" component={agreement_e_sign} />

    {/* eSign Admin screen */}
    <Stack.Screen name="eSignAdmin" component={agreement_e_sign} />

    <Stack.Screen name="BankAccount" component={bank_acc_info} />

    {/* Bank account admin */}
    <Stack.Screen name="BankAccountAdmin" component={bank_acc_info} />

    <Stack.Screen name="Payment" component={ordersummary} />

    <Stack.Screen name="PaymentMethods" component={payment_methods} />

    <Stack.Screen name="PaymentSuccess" component={succesful_onbording} />

    <Stack.Screen name="PaymentFailure" component={PaymentFailure} />

    <Stack.Screen name="KYC" component={kyc_check} />

    <Stack.Screen name="AadharESign" component={aadhar_based_e_sign} />

    <Stack.Screen name="DigioRedirection" component={DigioRedirection} />
    <Stack.Screen name="DigioKYCRedirection" component={KYCRedirection} />

    <Stack.Screen name="ComplianceSuccess" component={ComplianceSuccess} />

    <Stack.Screen name="OnboardingSuccess" component={success} />
    <Stack.Screen
      name="CustomerHandSignature"
      component={CustomerHandSignature}
    />
  </OnboardingStack.Navigator>
);

const downloadMobileStack = createStackNavigator();

export const DownloadMobileScreens = () => (
  <downloadMobileStack.Navigator
    screenOptions={{
      header: () => null,
    }}
  >
    <Stack.Screen name="DownloadMobileApp" component={DownloadMobileApp} />
  </downloadMobileStack.Navigator>
);

const AccountStack = createStackNavigator();

export const AccountScreens = () => (
  <AccountStack.Navigator
    initialRouteName="Account"
    screenOptions={{
      header: ({ previous, navigation, route }) => {
        // const { route } = scene;
        // const { name, params } = route;
        return (
          <Header
            // scene={scene}
            previous={previous}
            navigation={navigation}
            route={route}
          />
        );
      },
    }}
  >
    {/* Portfolio */}
    <Stack.Screen name="Account" component={Account} />
    <Stack.Screen name="PersonalisedFunds" component={PersonalisedFunds} />
    {/* MF Min Details */}
    <Stack.Screen name="AddPanCard" component={AddPanCard} />
    <Stack.Screen name="AboutMe" component={AddPersonalDetails} />
    <Stack.Screen name="AddAddress" component={AddAddress} />
    <Stack.Screen
      name="AssetsAndLiabilities"
      component={AssetsAndLiabilities}
    />
    <Stack.Screen name="eMandate" component={eMandate} />
    <Stack.Screen name="NachMandate" component={NachMandate} />
    <Stack.Screen name="ManageFamily" component={ManageFamily} />
    <Stack.Screen name="Membership" component={Membership} />
    <Stack.Screen name="HandSignature" component={HandSignature} />
    <Stack.Screen name="AdvisoryPlan" component={AdvisoryPlan} />
    <Stack.Screen
      name="UpgradeAdvisoryPlanRedirection"
      component={UpgradeAdvisoryPlanRedirection}
    />
    <Stack.Screen name="InvestorCharter" component={InvestorCharter} />
    <Stack.Screen name="InvestorComplaints" component={InvestorComplaints} />
    <Stack.Screen name="ReportsHome" component={ReportsHome} />
    <Stack.Screen name="AddNominee" component={AddNominee} />
    <Stack.Screen
      name="AddProfessionalDetails"
      component={AddProfessionalDetails}
    />
    <Stack.Screen name="AddBankAccount" component={AddBankAccount} />
    <Stack.Screen name="BankAccounts" component={BankAccounts} />
    <Stack.Screen name="EKYC" component={EKYC} />
    <Stack.Screen name="DeActivateAccount" component={DeActivateAccount} />
    <Stack.Screen name="EKYCRedirection" component={EKYCRedirection} />
    {/* RiskProfile */}
    <Stack.Screen name="RiskProfileHome" component={RiskProfileHome} />
    <Stack.Screen name="MyRiskProfile" component={MyRiskProfile} />
    <Stack.Screen name="RiskProfileSummary" component={RiskProfileSummary} />
    {/* Demat Screens */}
    <Stack.Screen name="Demat" component={linkedDemat} />
    <Stack.Screen name="DematAdmin" component={linkedDemat} />
    <Stack.Screen name="linkExistingDemat" component={linkExistingDemat} />
    {/* Finplan  */}
    <Stack.Screen name="ResetFinplan" component={ResetFinplan} />
    {/* Membership o Upgrade Plan */}
    <Stack.Screen name="MySubscription" component={MySubscription} />
    <Stack.Screen
      name="MySubscriptionContactPage"
      component={MySubscriptionContactPage}
    />
    <Stack.Screen
      name="MySubscriptionContactConformationPage"
      component={MySubscriptionContactConformationPage}
    />
    <Stack.Screen
      name="MySubscriptionPlanPage"
      component={MySubscriptionPlanPage}
    />
    <Stack.Screen name="MySIPs" component={MySIPs} />
    <Stack.Screen name="MyPassbook" component={MyPassbook} />

    <Stack.Screen name="ChangePassword" component={ChangePassword} />
    {/* Referrals */}
    <Stack.Screen name="Referrals" component={Referrals} />

    {/* Logout */}
    <Stack.Screen name="Logout" component={Logout} />
  </AccountStack.Navigator>
);

const DashboardStack = createStackNavigator();

export const DashboardScreens = () => (
  <DashboardStack.Navigator
    initialRouteName="Home"
    screenOptions={{
      header: ({ route, navigation, previous }) => {
        // const { name, params } = route;
        return (
          <Header navigation={navigation} route={route} previous={previous} />
        );
      },
    }}
  >
    {/* Logout */}
    <Stack.Screen name="Home" component={NewHomeDashboard} />
    <Stack.Screen name="OldHome" component={OldHomeDashboard} />
    <Stack.Screen name="InvestmentOverview" component={NewHomeDashboard} />
    <Stack.Screen name="WebImport" component={WebImport} />
    <Stack.Screen name="ScheduleCall" component={ScheduleScreen} />
    {/* Education Modules */}
    <Stack.Screen name="DetailedView" component={DetailedView} />
    <Stack.Screen name="EduQuiz" component={EduQuiz} />
    <Stack.Screen name="EduQuizResult" component={EduQuizResult} />
    {/* Import Mutual Fund Portfolio */}
    <Stack.Screen name="ImportMFPortfolio" component={ImportMFPortfolio} />
    <Stack.Screen name="WebScreenImport" component={WebScreenImport} />
    {/* Import Stock Portfolio */}
    <Stack.Screen name="ImportOtherAssets" component={ImportOtherAssets} />
    {/* Import Stock Page */}
    <Stack.Screen
      name="ImportStocksPortfolio"
      component={ImportStocksPortfolio}
    />
    <Stack.Screen name="UpcomingInvestments" component={UpcomingInvestments} />
    <Stack.Screen name="NewHomeDashboard" component={Home} />
    <Stack.Screen name="ToDoHome" component={ToDoHome} />
    <Stack.Screen name="TestingConfig" component={TestConfig} />
    <Stack.Screen name="ProcessOrder" component={ProcessOrder} />
    <Stack.Screen name="RateUs" component={RateUs} />
    <Stack.Screen name="WebViewGeneral" component={WebViewGeneral} />
    <Stack.Screen name="Gateway" component={Gateway} />
    <Stack.Screen name="FAQ" component={FAQ} />
    <Stack.Screen name="Logout" component={Logout} />
    <Stack.Screen name="GetHelp" component={ContactUs} />
  </DashboardStack.Navigator>
);

const GoalsStack = createStackNavigator();

export const GoalsScreens = () => (
  <GoalsStack.Navigator
    initialRouteName="Goals"
    screenOptions={{
      header: ({ route, navigation }) => {
        const { name, params } = route;
        return (
          <Header
            // scene={scene}
            // previous={previous}
            navigation={navigation}
            route={route}
          />
        );
      },
    }}
  >
    {/* Goals  */}
    <Stack.Screen name="Goals" component={Goals} />
    <Stack.Screen name="GoalsWebView" component={GoalsWebView} />
    <Stack.Screen name="CreateGoalsIntro" component={CreateGoalsIntro} />
    <Stack.Screen name="AddGoal" component={AddGoal} />
    <Stack.Screen name="EditGoal" component={AddGoal} />
    <Stack.Screen name="GoalDetails" component={GoalDetails} />
    <Stack.Screen
      name="AdvancedRetirementPlanner"
      component={AdvancedRetirementPlanner}
    />
    <Stack.Screen name="RetirementCorpus" component={RetirementCorpus} />
    <Stack.Screen name="AddExpense" component={AddExpense} />
    <Stack.Screen name="AddIncome" component={AddIncome} />
    <Stack.Screen
      name="IncomePostRetirement"
      component={IncomePostRetirement}
    />
    <Stack.Screen name="NetCorpus" component={NetCorpus} />
    <Stack.Screen name="AttachExisting" component={AttachExisting} />
    <Stack.Screen name="InvestGoal" component={InvestGoal} />
    <Stack.Screen name="InvestGoalSummary" component={InvestGoalSummary} />

    {/* Finplan */}
    <Stack.Screen name="CreateFinplan" component={CreateFinplan} />
    <Stack.Screen name="BasicDetails" component={BasicDetails} />
    <Stack.Screen name="IncomeDetails" component={IncomeDetails} />
    <Stack.Screen name="MaritalStatus" component={MaritalStatus} />
    <Stack.Screen name="ChildrenDetails" component={ChildrenDetails} />
    <Stack.Screen name="ChildrenBachelors" component={ChildrenBachelors} />
    <Stack.Screen name="ChildrenPG" component={ChildrenPG} />
    <Stack.Screen name="ChildrenWedding" component={ChildrenWedding} />
    <Stack.Screen name="HigherStudies" component={HigherStudies} />
    <Stack.Screen name="BuyBike" component={BuyBike} />
    <Stack.Screen name="BuyCar" component={BuyCar} />
    <Stack.Screen name="BuyHome" component={BuyHome} />
    <Stack.Screen name="PlaceToStudy" component={PlaceToStudy} />
    <Stack.Screen name="WhenBuyBike" component={WhenBuyBike} />
    <Stack.Screen name="WhenBuyCar" component={WhenBuyCar} />
    <Stack.Screen name="WhenBuyHome" component={WhenBuyHome} />
    <Stack.Screen name="WhenRetire" component={WhenRetire} />
  </GoalsStack.Navigator>
);

const ExploreStack = createStackNavigator();

export const ExploreScreens = () => (
  <ExploreStack.Navigator
    initialRouteName="Explore"
    screenOptions={{
      header: ({ route, navigation }) => {
        const { name, params } = route;
        return (
          <Header
            // scene={scene}
            // previous={name}
            navigation={navigation}
            route={route}
          />
        );
      },
    }}
  >
    {/* Mutual funds - explore  */}
    <Stack.Screen name="MFExplore" component={MFExplore} />
    <Stack.Screen name="InvestNowNew" component={InvestNowNew} />
    {/* Mutual funds - explore admin */}
    <Stack.Screen name="MFExploreAdmin" component={MFExplore} />
    {/* Mutual funds - fund description  */}
    <Stack.Screen name="FundDescription" component={FundDescription} />
    {/* Mutual funds - fund description admin */}
    <Stack.Screen name="FundDescriptionAdmin" component={FundDescription} />
    {/* Mutual funds - start sip  */}
    <Stack.Screen name="StartSIP" component={StartSIP} />
    {/* Mutual funds - start sip admin */}
    <Stack.Screen name="StartSIPAdmin" component={StartSIP} />
    {/* Mutual funds - invest lumpsum  */}
    <Stack.Screen name="InvestLumpsum" component={InvestLumpsum} />
    {/* Mutual funds - invest lumpsum admin */}
    <Stack.Screen name="InvestLumpsumAdmin" component={InvestLumpsum} />
    {/* Mutual funds - select mandate  */}
    <Stack.Screen name="SelectMandate" component={SelectMandate} />
    {/* Mutual funds - select mandate admin */}
    <Stack.Screen name="SelectMandateAdmin" component={SelectMandate} />
    {/* Mutual funds - create mandate  */}
    <Stack.Screen name="CreateMandate" component={CreateMandate} />
    {/* Mutual funds - create mandate admin */}
    <Stack.Screen name="CreateMandateAdmin" component={CreateMandate} />
    {/* Search Funds */}
    <Stack.Screen name="SearchFunds" component={SearchFunds} />
    <Stack.Screen name="ShowBankAccount" component={ShowBankAccount} />
    {/* Logout */}
    <Stack.Screen name="Logout" component={Logout} />
    <Stack.Screen name="MfOtpAuthentication" component={MfOtpAuthentication} />
  </ExploreStack.Navigator>
);

const PlansStack = createStackNavigator();

export const PlansScreens = () => (
  <PlansStack.Navigator
    initialRouteName="Plans"
    // screenOptions={{
    //   header: ({ previous, navigation }) => {
    //     // const { route } = scene;
    //     return (
    //       <Header
    //         // scene={scene}
    //         previous={previous}
    //         navigation={navigation}
    //         // route={route}
    //       />
    //     );
    //   },
    // }}
    screenOptions={{
      header: ({ route, navigation }) => {
        const { name, params } = route;
        return (
          <Header
            // scene={scene}
            // previous={name}
            navigation={navigation}
            route={route}
          />
        );
      },
    }}
  >
    <Stack.Screen name="PlansHome" component={PlansHome} />
    <Stack.Screen
      name="MyEquityInvestmentPlans"
      component={MyEquityInvestmentPlans}
    />
    <Stack.Screen name="InsightsHome" component={InsightsHome} />
    <Stack.Screen name="InsightsWebView" component={InsightsWebView} />
    <Stack.Screen name="MFRecommendations" component={MFRecommendations} />
    <Stack.Screen name="CorpCountTracker" component={CorpCountTracker} />
    <Stack.Screen
      name="UpgradeSubscriptionHome"
      component={UpgradeSubscriptionHome}
    />
    <Stack.Screen
      name="SubscriptionPlanHome"
      component={SubscriptionPlanHome}
    />
    <Stack.Screen name="ManageSchedule" component={ManageSchedule} />
    <Stack.Screen name="ManageScheduleAdmin" component={ManageSchedule} />
    <Stack.Screen name="Logout" component={Logout} />
  </PlansStack.Navigator>
);

const RaiseTicketStack = createStackNavigator();

export const RaiseTicketScreens = () => (
  <RaiseTicketStack.Navigator
    initialRouteName="RaiseTicket"
    screenOptions={{
      header: ({ previous, navigation, route }) => {
        // const { route } = scene;
        return (
          <Header
            // scene={scene}
            previous={previous}
            navigation={navigation}
            route={route}
          />
        );
      },
    }}
  >
    {/* Raise a ticket home  */}
    <Stack.Screen name="Feedback" component={RaiseTicket} />
  </RaiseTicketStack.Navigator>
);

const NomineeStack = createStackNavigator();

export const NomineeScreens = () => (
  <NomineeStack.Navigator
    initialRouteName="Nominee"
    screenOptions={{
      header: ({ route, previous, navigation }) => {
        // const { route } = scene;
        return (
          <Header
            // scene={scene}
            previous={previous}
            navigation={navigation}
            route={route}
          />
        );
      },
    }}
  >
    <Stack.Screen name="Nominee" component={NomineeAuthentication} />
  </NomineeStack.Navigator>
);

const ViewModeStack = createStackNavigator();

export const ViewModeScreens = () => (
  <ViewModeStack.Navigator
    initialRouteName="Home"
    screenOptions={{
      header: ({ previous, navigation, route }) => {
        // const { route } = scene;
        return (
          <Header
            // scene={scene}
            previous={previous}
            navigation={navigation}
            route={route}
          />
        );
      },
    }}
  >
    <Stack.Screen name="Home" component={NewHomeDashboard} />
    <Stack.Screen name="CountTrackerHome" component={CountTrackerHome} />
    <Stack.Screen
      name="SwitchToPreferredCircle"
      component={SwitchToPreferredCircle}
    />
    <Stack.Screen
      name="StockRecommendations"
      component={StockRecommendations}
    />
    <Stack.Screen name="FinAwareness" component={FinAwareness} />
    <Stack.Screen name="CorporateDashboard" component={CorporateDashboard} />
    <Stack.Screen
      name="FinancialWellnessIndex"
      component={FinancialWellnessIndex}
    />
    <Stack.Screen name="EducationActivity" component={EducationActivity} />
    <Stack.Screen name="FinancialPlanHealth" component={FinancialPlanHealth} />
    <Stack.Screen name="LeaderBoard" component={LeaderBoard} />
    <Stack.Screen name="FinWellness" component={FinWellness} />
    <Stack.Screen name="EmWellness" component={EmWellness} />
    <Stack.Screen name="EmotionalWellness" component={EmotionalWellness} />
    <Stack.Screen name="QuizDiag" component={QuizDiag} />
    <Stack.Screen name="Quizz" component={Quizz} />
    <Stack.Screen name="QuizResult" component={QuizResult} />
    <Stack.Screen name="ContractNote" component={contractNote} />
    <Stack.Screen name="ContractNoteAdmin" component={contractNote} />
    <Stack.Screen
      name="ContrcatTransactionDetails"
      component={contractNotesTransactionDetails}
    />
    <Stack.Screen name="EduHome" component={EduHome} />
    <Stack.Screen name="ImportStocks" component={ImportStocks} />
    <Stack.Screen name="ImportStocksAdmin" component={ImportStocks} />
    <Stack.Screen name="ImportMF" component={ImportMF} />
    <Stack.Screen name="ImportMFAdmin" component={ImportMF} />
    <Stack.Screen name="InvestForGoals" component={InvestForGoals} />
    <Stack.Screen name="SuggestedFunds" component={SuggestedFunds} />
    <Stack.Screen name="SellExitStocks" component={SellExitStocks} />
    <Stack.Screen name="ToDoHome" component={ToDoHome} />
    <Stack.Screen name="ProcessOrder" component={ProcessOrder} />
    <Stack.Screen name="RateUs" component={RateUs} />
  </ViewModeStack.Navigator>
);

const PassbookStack = createStackNavigator();
export const PassbookScreens = () => (
  <PassbookStack.Navigator
    initialRouteName="Passbook"
    screenOptions={{
      header: ({ scene, previous, navigation }) => {
        // const { route } = scene;
        const { name } = route;
        if (name == "EquityPlans") return null;
        let url = null;
        if (Platform.OS == "web") {
          url = global.location.href;
        }
        if (
          url &&
          do_not_include_header_for.findIndex((element) =>
            url.includes(element)
          ) != -1
        )
          return null;
        return (
          <Header
            scene={scene}
            previous={previous}
            navigation={navigation}
            route={route}
          />
        );
      },
    }}
  >
    {/* Passbook flow     */}
    <Stack.Screen name="Passbook" component={Passbook} />
    <Stack.Screen name="PassbookAdmin" component={Passbook} />
    <Stack.Screen
      name="TransactionDetails"
      component={PassbookTransactionDetails}
    />
    <Stack.Screen
      name="TransactionDetailsAdmin"
      component={PassbookTransactionDetails}
    />

    {/* Logout */}
    <Stack.Screen name="Logout" component={Logout} />
  </PassbookStack.Navigator>
);

const BillingStack = createStackNavigator();
export const BillingScreens = () => (
  <BillingStack.Navigator
    initialRouteName="Invoices"
    screenOptions={{
      header: ({ previous, navigation, route }) => {
        // const { route } = scene;
        // const { name } = route;
        if (name == "EquityPlans") return null;
        let url = null;
        if (Platform.OS == "web") {
          url = global.location.href;
        }
        if (
          url &&
          do_not_include_header_for.findIndex((element) =>
            url.includes(element)
          ) != -1
        )
          return null;
        return (
          <Header
            // scene={scene}
            previous={previous}
            navigation={navigation}
            route={route}
          />
        );
      },
    }}
  >
    <Stack.Screen name="Invoices" component={Invoices} />
    <Stack.Screen name="InvoicesAdmin" component={Invoices} />
    <Stack.Screen name="InvoiceDetails" component={InvoiceDetails} />
    <Stack.Screen name="InvoiceDetailsAdmin" component={InvoiceDetails} />

    <Stack.Screen name="Bill" component={pendingInvoiceOverdue} />
    <Stack.Screen name="HoldingsDetails" component={HoldingsDetails} />

    <Stack.Screen name="ViewBill" component={ViewBill} />
    <Stack.Screen name="DividendYields" component={DividendYield} />
    <Stack.Screen name="InvestAmountDetails" component={InvestAmountDetails} />
    <Stack.Screen name="AdvisoryFeeDetails" component={AdvisoryFeeDetails} />
    <Stack.Screen name="ProratedDetails" component={ProratedDetails} />
    <Stack.Screen name="AumDetails" component={AumDetails} />
    <Stack.Screen name="PlanDetails" component={PlanDetails} />
    <Stack.Screen name="TransactionDetails" component={TransactionDetails} />
    <Stack.Screen
      name="InvoicesPaymentRedirection"
      component={InvoicesPaymentRedirection}
    />
    <Stack.Screen name="BillingLedger" component={BillingLedger} />
    <Stack.Screen name="BillingLedgerAdmin" component={BillingLedger} />
    <Stack.Screen
      name="BillingLedgerDetails"
      component={BillingLedgerDetails}
    />
    <Stack.Screen
      name="BillingLedgerDetailsAdmin"
      component={BillingLedgerDetails}
    />
  </BillingStack.Navigator>
);

const BottomTab = createBottomTabNavigator();

const BottomTabBar = ({ state, descriptors, navigation }) => {
  const dimensions = useWindowDimensions();
  return dimensions.width < 1200 ? (
    <View
      style={{
        height: 66,
        backgroundColor: "white",
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
      }}
    >
      <View
        style={{
          height: 0.5,
          width: "100%",
          backgroundColor: "#92929250",
        }}
      />
      <View
        style={{
          marginTop: 12,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;
          let uri;
          const isFocused = state.index === index;

          switch (route.name) {
            case "Dashboard":
              uri = isFocused
                ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/home_active.png"
                : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/home.png";
              break;
            case "Portfolio":
              uri = isFocused
                ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolio_active.png"
                : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/portfolio.png";
              break;
            case "Plans":
              uri = isFocused
                ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/insights_active.png"
                : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/insights.png";
              break;
            case "Goals":
              uri = isFocused
                ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/goals_active.png"
                : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/goals.png";
              break;
            case "Explore":
              uri = isFocused
                ? "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/explore_active.png"
                : "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/explore.png";
              break;
            default:
              uri =
                "https://jamaappprod.s3.ap-south-1.amazonaws.com/static/jewelbuild/images/home_active.png";
              break;
          }

          let screenValue;

          if (route.name == "Dashboard") {
            screenValue = "Home";
          }
          if (route.name == "Portfolio") {
            screenValue = "InvestmentOverview";
          }
          if (route.name == "Plans") {
            screenValue = "InsightsHome";
          }
          if (route.name == "Goals") {
            screenValue = "Goals";
          }
          if (route.name == "Explore") {
            screenValue = "MFExplore";
          }

          const onPress = () => {
            navigation.navigate("App", {
              screen: "Dashboard",
              params: {
                screen: route.name,
                params: {
                  screen: screenValue,
                  params: {},
                },
              },
            });
          };

          const onLongPress = () => {
            navigation.emit({
              type: "tabLongPress",
              target: route.key,
            });
          };

          return (
            <TouchableOpacity
              accessibilityRole="button"
              accessibilityState={isFocused ? { selected: true } : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={{ flex: 1, alignItems: "center" }}
            >
              {route.name != "Plans" ? (
                <Image
                  source={{
                    uri: uri,
                  }}
                  style={{
                    width: 20,
                    height: 20,
                    resizeMode: "contain",
                    color: isFocused ? "#410DAA" : "#929292",
                  }}
                />
              ) : (
                <MaterialIcons
                  name="lightbulb-outline"
                  size={20}
                  color={isFocused ? "#410DAA" : "#c1cfe0"}
                />
              )}
              <Text
                style={{
                  color: isFocused ? "#410DAA" : "#12121290",
                  fontSize: 13,
                  paddingTop: 4,
                }}
              >
                {label == "Dashboard"
                  ? "Home"
                  : label == "Plans"
                  ? "Insights"
                  : label}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  ) : (
    <View></View>
  );
};

const BottomTabNavigator = () => {
  let { show_goals_fin_plan, show_explore_mf, show_explore_stocks } =
    getScreenConfigForCustomer();
  const { is_ria } = getUserDetails();
  return (
    <BottomTab.Navigator screenOptions={{
      headerShown: false
    }} tabBar={(props) => <BottomTabBar {...props} />}>
      <BottomTab.Screen name="Dashboard" component={DashboardScreens} />
      <BottomTab.Screen name="Portfolio" component={PortfolioScreens} />
      {is_ria ? null : (
        <BottomTab.Screen name="Plans" component={PlansScreens} />
      )}
      {show_goals_fin_plan && (
        <BottomTab.Screen name="Goals" component={GoalsScreens} />
      )}
      {(show_explore_mf || show_explore_stocks) && (
        <BottomTab.Screen name="Explore" component={ExploreScreens} />
      )}
    </BottomTab.Navigator>
  );
};

const App = (navProps) => {
  const dimensions = useWindowDimensions();
  return (
    <Drawer.Navigator
      drawerContent={(props) => <DrawerContent {...props} {...navProps} />}
      openByDefault={false}
      screenOptions={{
        drawerType: dimensions.width >= 1200 ? "permanent" : "front",
        drawerPosition: "left",
        headerShown:false,
        drawerStyle: {
          backgroundColor: "#fff",
          width: width > 1200 ? "15%" : "100%",
          zIndex: 100,
        },
      }}
      initialRouteName="Dashboard"
    >
      <Drawer.Screen name="Dashboard" component={BottomTabNavigator} />
      <Drawer.Screen name="Portfolio" component={PortfolioScreens} />
      <Drawer.Screen name="Goals" component={GoalsScreens} />
      <Drawer.Screen name="Explore" component={ExploreScreens} />
      <Drawer.Screen name="Plans" component={PlansScreens} />
      <Drawer.Screen name="Account" component={AccountScreens} />
      <Drawer.Screen name="Billing" component={BillingScreens} />
      <Drawer.Screen name="Passbook" component={PassbookScreens} />
      <Drawer.Screen name="RaiseTicket" component={RaiseTicketScreens} />
      <Drawer.Screen name="NomineeAuthentication" component={NomineeScreens} />
      <Drawer.Screen name="ViewMode" component={ViewModeScreens} />
    </Drawer.Navigator>
  );
};

const JamaWealthLoading = () => {
  return (
    <Stack.Navigator
      initialRouteName="EquityPlans"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="EquityPlans" component={EquityPlans} />
    </Stack.Navigator>
  );
};

export const Route = (props) => {
  const authenticationNotNeeded = useSelector((state) => {
    const { token } = state.userData.userProfileData;
    const { lastLoggedInTime } = state.userData;
    let differenceInMinutes = null;
    if (lastLoggedInTime != null && !isNaN(lastLoggedInTime)) {
      const currentTime = new Date().getTime();
      const difference = currentTime - lastLoggedInTime;
      differenceInMinutes = Math.round(difference / 60000);
    }
    if (Platform.OS == "web") {
      return (
        token && (differenceInMinutes == null || differenceInMinutes < 600)
      );
    } else {
      return token;
    }
  });

  if (!authenticationNotNeeded) {
    Store.dispatch({ type: LOGOUT });
    props.checkAuth("noAuth");
  } else {
    props.checkAuth("userAuthenticated");
  }

  let queryParams = [];
  let isOnboarding = false;
  if (Platform.OS == "web") {
    const url = global.location.href;
    isOnboarding = url.includes("onboarding");
    const u = url ? url.split("?") : null;
    if (u && u.length > 1) {
      let qps = u[1];
      qps = qps.split("&&");
      queryParams = [...qps];
    }
  }

  let showGetStarted = "true";

  if (
    Store.getState().showGetStarted &&
    Store.getState().showGetStarted.shouldShow
  ) {
    showGetStarted = Store.getState().showGetStarted.shouldShow;
  }

  let showStarted = Platform.OS != "web" ? showGetStarted : "false";

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      fallback={<Text>Loading...</Text>}
    >
      {props.modalVisibility && (
        <View
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            source={{
              uri: "https://jamaappprod.s3.ap-south-1.amazonaws.com/Website/maxiom_wealth.jpg",
            }}
            style={{ width: 60, height: 60 }}
            resizeMode={"contain"}
          />
          <Text
            style={{
              fontFamily: "Roboto",
              fontSize: 15,
              color: "#202020",
              marginBottom: 45,
              marginTop: 35,
              textAlign: "center",
            }}
          >
            Upgrading your Jama Wealth experience
          </Text>
        </View>
      )}
      {!authenticationNotNeeded ? (
        <OnboardingAuthScreens showGetStarted={showStarted} />
      ) : (
        <Stack.Navigator
          initialRouteName="JamaWealth"
          screenOptions={{
            headerShown: false,
          }}
        >
          <Stack.Screen name="Onboarding" component={OnboardingScreens} />
          <Stack.Screen name="MinDetails" component={MindetailsScreens} />
          <Stack.Screen name="App" component={App} />
          <Stack.Screen name="JamaWealth" component={JamaWealthLoading} />
          <Stack.Screen
            name="DownloadMobileApp"
            component={DownloadMobileScreens}
          />
        </Stack.Navigator>
      )}
    </NavigationContainer>
  );
};
