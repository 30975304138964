import React, { useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import Toast, { BaseToast } from "react-native-toast-message";

export const toastConfig = {
  success: ({ text1, type }) => {
    return (
      <BaseToast
        style={{
          backgroundColor: "#c7d5e6",
          borderLeftColor: "#c7d5e6",
          height: 40,
          width: "68%",
        }}
        contentContainerStyle={{
          justifyContent: "center",
          alignItems: "center",
        }}
        text1={text1}
        text1Style={{
          color: "#1b52a0",
          fontSize: 15,
          fontWeight: "600",
          textAlign: "center",
        }}
        onTrailingIconPress={() => Toast.hide()}
      />
    );
  },
  error: ({ text1, type }) => {
    return (
<BaseToast
  style={{
    backgroundColor: "#c7d5e6",
    borderLeftColor: "#c7d5e6",
    height: 40,
    width: "68%",
  }}
  contentContainerStyle={{
    justifyContent: "center",
    alignItems: "center",
  }}
  text1={text1}
  text1Style={{
    color: "#1b52a0",
    fontSize: 15,
    fontWeight: "600",
    textAlign: "center",
  }}
  // trailingIcon={null}
  onTrailingIconPress={() => Toast.hide()}
/>
    );
  },
  status: ({ text1, type }) => {
    return (
<BaseToast
  style={{
    backgroundColor: "#c7d5e6",
    borderLeftColor: "#c7d5e6",
    height: 40,
    width: "68%",
  }}
  contentContainerStyle={{
    justifyContent: "center",
    alignItems: "center",
  }}
  text1={text1}
  text1Style={{
    color: "#1b52a0",
    fontSize: 15,
    fontWeight: "600",
    textAlign: "center",
  }}
  // trailingIcon={null}
  onTrailingIconPress={() => Toast.hide()}
/>
    );
  },
};

export const ShowToast = ({
  showToast = false,
  route = false,
  title,
  duration,
  description,
  type = "error",
}) => {
  useEffect(() => {
    const getToastVisibility = () => {
      if (route) {
        const { params } = route;
        return params && params.first;
      }
      return showToast;
    };

    const visibility = getToastVisibility();

    visibility &&
      Toast.show({
        text1: title || "",
        text2: description || "",
        visibilityTime: 5000,
        topOffset: 7,
        type,
      });
  }, []);

  return <View />;
};

const styles = StyleSheet.create({
  contentContainer: {},
  text: {
    color: "white",
    fontSize: 15,
    fontWeight: "600",
  },
});
