import React, { useRef, useEffect, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  Platform,
  Dimensions,
  SafeAreaView,
} from "react-native";
import { Text } from "react-native-paper";
import CurrentPlanDetails from "./currentPlanDetails";
import PlanDetails from "./planDetails";
import StandsOut from "./standOut";
import FAQs from "./faqs";
import ContactUs from "./contactUs";
import BackToTop from "./backToTop";
import clients from "./clients";
import plansData from "./plans";
import ClientReviews from "./clientReviews";
import { md } from "../../Responsive";
import { fetchCurrentSubscriptionPlan } from "../../api";
import { getUserDetails } from "../../utils";
import NavigationHeader from "../../components/Common/NavigationHeader";

const window = Dimensions.get("window");

export default function MySubscription(props) {
  const { customer_id } = getUserDetails();
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchCurrentSubscriptionPlan(customer_id)
        .then((data) => {
          setIsLoading(false);
          if (data && data.current_subscription_plan) {
            switch (data.current_subscription_plan) {
              case "PP":
                setCurrentPlan("gold");
                break;
              case "SP":
                setCurrentPlan("silver");
                break;
              case "BP":
                setCurrentPlan("silver");
                break;
              case "PLA":
                setCurrentPlan("platinum");
                break;
              case "PLAF":
                setCurrentPlan("platinum");
                break;
              default:
                setCurrentPlan("jewel");
            }
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    })();
  }, []);

  const scrollRef = useRef();
  const paidPlansData = plansData.slice(1);

  return (
    <SafeAreaView style={styles.sav}>
      <View
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          zIndex: 1,
        }}
      >
        <NavigationHeader
          navigation={props.navigation}
          navigationHeader={["Be A Member"]}
        />
      </View>
      <ScrollView style={styles.mainContainer} ref={scrollRef}>
        <View style={styles.container}>
          <CurrentPlanDetails
            plan={currentPlan}
            style={styles.container}
            isLoading={isLoading}
          />
          <Text
            style={[
              {
                fontSize: 25,
                fontWeight: "bold",
                textAlign: Platform.OS === "web" ? "center" : "justify",
                marginLeft: 4,
                marginRight: 4,
                marginTop: 28,
                color: "black",
              },
              global.arial,
            ]}
          >
            Choose a plan that suits best for your investment
          </Text>
        </View>
        <View style={styles.seperator}></View>
        <View style={[styles.container, styles.plansContainer]}>
          {paidPlansData.map((plan, i) => {
            return (
              <PlanDetails
                scrollRef={scrollRef}
                key={plan.name}
                currentPlanName={currentPlan}
                plan={plan}
                isLast={i === paidPlansData.length - 1}
                navigation={props.navigation}
              />
            );
          })}
        </View>
        <View style={[styles.standsOutContainer]}>
          <StandsOut></StandsOut>
        </View>
        <View style={[styles.container, styles.clientsContainer]}>
          <ClientReviews clients={clients}></ClientReviews>
        </View>
        <View style={[styles.container, styles.faqsContainer]}>
          <FAQs></FAQs>
        </View>
        <View style={[styles.contactContainer]}>
          <ContactUs navigation={props.navigation}></ContactUs>
        </View>
        <View style={styles.backToTopContainer}>
          <BackToTop target={scrollRef}></BackToTop>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  sav: {
    flex: 1,
    backgroundColor: "white",
  },
  mainContainer: {
    backgroundColor: Platform.OS === "web" ? "#f8f8f8" : "#fdfdfd",
    marginTop: 55,
    backgroundColor: "white",
  },
  container: {
    marginLeft: md(window.width) ? 120 : 16,
    marginRight: md(window.width) ? 120 : 16,
    marginTop: 40,
  },
  seperator: {
    alignSelf: "stretch",
    marginTop: 40,
    height: 15,
    backgroundColor: "#410DAA",
    marginLeft: md(window.width) ? 120 : 0,
    marginRight: md(window.width) ? 120 : 0,
    borderTopRightRadius: md(window.width) ? 5 : undefined,
    borderTopLeftRadius: md(window.width) ? 5 : undefined,
  },
  plansContainer: {
    flex: md(window.width) ? 1 : undefined,
    flexDirection: md(window.width) ? "row" : undefined,
    justifyContent: md(window.width) ? "space-around" : undefined,
    backgroundColor: md(window.width) ? "#fff" : undefined,
    borderBottomRightRadius: md(window.width) ? 5 : undefined,
    borderBottomLeftRadius: md(window.width) ? 5 : undefined,
    borderColor: md(window.width) ? "#000" : undefined,
    borderWidth: md(window.width) ? 1 : undefined,
    marginLeft: md(window.width) ? 120 : 16,
    marginRight: md(window.width) ? 120 : 16,
    marginTop: 0,
  },
  standsOutContainer: {
    marginLeft: md(window.width) ? 120 : undefined,
    marginRight: md(window.width) ? 120 : undefined,
    marginTop: 40,
    backgroundColor: md(window.width) ? undefined : "#eee",
    paddingBottom: md(window.width) ? 80 : 40,
  },
  clientsContainer: {
    backgroundColor: "#fdfdfdfd",
    marginLeft: md(window.width) ? 0 : undefined,
    marginRight: md(window.width) ? 0 : undefined,
    paddingLeft: md(window.width) ? 120 : undefined,
    paddingRight: md(window.width) ? 120 : undefined,
  },
  faqsContainer: {
    backgroundColor: "#fdfdfdfd",
    marginLeft: md(window.width) ? 0 : 16,
    marginRight: md(window.width) ? 0 : undefined,
    paddingLeft: md(window.width)
      ? 120
      : Platform.OS === "web"
      ? 20
      : undefined,
    paddingRight: md(window.width)
      ? 120
      : Platform.OS === "web"
      ? 20
      : undefined,
  },
  contactContainer: {
    backgroundColor: md(window.width) ? undefined : "#eee",
  },
  backToTopContainer: {
    backgroundColor: md(window.width) ? "#FDFDFDFD" : undefined,
    paddingTop: 40,
    paddingBottom: md(window.width) ? 40 : 80,
  },
});
