import React, { Component, useEffect } from "react";
import {
  View,
  ScrollView,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  Image,
  Platform,
  StatusBar
} from "react-native";

import { connect } from "react-redux";

import { Text, Button } from 'react-native-paper';
import { Store as store } from "../../../store";
import { DataURIToBlob, getUserDetails, isMobile } from "../../../utils";
import theme from "../../../static/theme";
import { useFilePicker } from "use-file-picker";
import { addPanNumber } from "../../../api";
import amplitude from "../../../utils/amplitude";


const SelectSignature = ({ onSelect, fileName=null }) => {
    const { openFilePicker, filesContent, loading, errors } = useFilePicker({
      readAs: "DataURL",
      accept: [".png", ".jpg", ".jpeg"],
      multiple: true,
      // limitFilesConfig: { max: 3 },
      maxFileSize: 1, // in MB
    });
    useEffect(() => {
      onSelect(filesContent, errors);
    }, [filesContent]);
    const isFileSelected = () => {
      return filesContent.length != 0;
    };
    if (loading) {
      return <Text>Loading</Text>;
    }
    const checkSizeError = () => {
      if (errors && errors.length) {
        const error = errors[0];
        const { fileSizeToolarge } = error;
        return {
          fileSizeToolarge: true,
          error: true,
        };
      }
      return null;
    };
    const selectedFile = isFileSelected();
    const errorData = checkSizeError();

    const openImage = () => {
        globalThis.open(fileName);
    }

    return(
        <View>
            <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderColor: theme.colors.NEW_BRAND_COLOR,
                  borderStyle: "dashed",
                  justifyContent: "flex-start",
                  padding: 12,
                  borderRadius: 10,
                  marginVertical: 32,
                  marginHorizontal: 16
                }}
                onPress={() => {
                    openFilePicker();
                }}
            >
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: theme.colors.NEW_BRAND_COLOR,
                    paddingVertical: 32,
                    borderRadius: 8,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Image
                    style={{
                      width: 76,
                      height: 67
                    }}
                    resizeMode={"contain"}
                    source={require("../../../assets/signature.png")}
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#939393",
                      textAlign: "center",
                      marginTop: 16
                    }}
                  >
                    Upload your signature specimen here
                  </Text>
                </View>
            </TouchableOpacity>
            <Text
                style={[
                styles.support,
                errorData &&
                    errorData.error &&
                    !errorData.fileSizeToolarge &&
                    styles.error,
                ]}
            >
                Supports PNG, JPG, JPEG{" "}
                <Text style={[errorData && errorData.fileSizeToolarge && styles.error]}>
                    Max File Size 1MB
                </Text>
            </Text>
            {selectedFile ? (
                filesContent.map((file, index) => (
                    <Text style={styles.fileName}>{file.name}</Text>
                ))
            ) : (
                <TouchableOpacity onPress={openImage}>
                    <Text style={styles.fileName}>View Current Signature</Text>
                </TouchableOpacity>
            )}
        </View>
    )
  };

class MinDetailsSignature extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (Platform.OS == "web"){
      amplitude.logEvent("AddSignature",{ path: global.location.href })
    }
    else{
      amplitude.logEvent("AddSignature", { path: this.props.navigation.getState().routeName });
    }
    const { hand_signature_pic } = getUserDetails();
    this.setState({
      hand_signature_pic,
      avatarSource: hand_signature_pic
    });
  }

  onSubmitPress = async () => {
    try {
        const { imageuri, imagename } = this.state;
        if (!imageuri || !imagename) {
        this.setState({
            error: "Please select an image"
        });
        return;
        }

        this.setState({ submitting: true });
        const { username, customer_id } = getUserDetails();
        var body = new FormData();
        body.append("username", username);

        const imageFile = DataURIToBlob(imageuri);
        body.append("hand_signature_pic", imageFile, imagename);

        const response = await addPanNumber(customer_id, body);
        if (response && response.pk != null) {
            store.dispatch({
                type: "SAVE_PROFILE_DATA",
                payload: response
            });
            if(this.props.minDetailsFlow){
                const redirect = await redirectToPendingScreen(this.props);
                if(redirect && redirect.error){

                }
            } 
            this.setState({ submitting: false });
        }else{
            // this.setState({ submitting: false });
        }
    } catch (error) {
        this.setState({ submitting: false });
    }

  };

  render() {
    const { container } = styles;
    const { avatarSource, imageName } = this.state;
    return (
      <SafeAreaView style={container}>
        <StatusBar backgroundColor={"#fff"} barStyle="dark-content" />
        {/* <SideHeading title="Add Signature" style={{ fontSize: 20 }} /> */}
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{ flex: 1 }}
          showsVerticalScrollIndicator={true}
        >

            <View style={{ flex: 1 }}>
                <SelectSignature
                    onSelect={(filesContent, errors) => {
                        if (filesContent && filesContent.length > 0) {
                            const { content: imageuri, name: imagename } = filesContent[0];
                            this.setState({
                                imageuri,
                                imagename
                            });
                        }}
                    }
                    fileName={avatarSource}
                />
                {/* <Text
                style={{
                    fontSize: 15,
                    color: "#410DAA",
                    textDecorationLine: "underline",
                    marginLeft: 16
                }}
                >
                VIEW SAMPLE UPLOADS
                </Text> */}
            </View>
        </ScrollView>
        <View style={styles.buttonContainer}>
            <Button
                uppercase={false}
                mode={"contained"}
                onPress={this.onSubmitPress}
                style={{
                    position: "absolute",
                    alignItems: "center",
                    bottom: 10,
                    width: isMobile ? "90%" : 400,
                }}
                loading={this.state.submitting}
                color={"#410DAA"}
            >
                <Text style={styles.next}>Save</Text>
            </Button>
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.MAIN_BACKGROUND
  },
  row: {
    flexDirection: "row"
  },
  baseMargin: {
    marginTop: 16
  },
  titleHeading: {
    color: "#707070"
  },
  nextButton: {
    width: "96%",
    maxWidth: 400,
    marginBottom: 15,
    paddingHorizontal: 16
  },
  next: {
      color: "#fff",
      fontSize: 15,
  },
  buttonContainer: {
      alignItems: "center"
  },
  fileName: {
    fontSize: 15,
    marginTop: 5,
    color: "#410DAA",
    fontWeight: "bold",
    marginHorizontal: 16
  },
  support: {
    fontSize: 10,
    color: "#121212",
    fontStyle: "italic",
    marginHorizontal: 16
  },
  error: { color: "red", marginHorizontal: 16 },
});


export default connect(null)(MinDetailsSignature);
